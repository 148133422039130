import { styled } from '@novaera/theme-provider';
import { SimpleSearchInput } from '.';
import { SimpleSearchInputProps } from './types';

export const DropdownSimpleSearchInput = styled(({ size = 'large', ...props }: SimpleSearchInputProps) => (
  <SimpleSearchInput {...props} size="large" fullWidth color="ghost" />
))`
  &.MuiTextField-root {
    .MuiInput-root {
      border: none;
      padding: 0 16px;
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      gap: 4px;
      border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
      border-radius: 0;

      &.Mui-focused {
        border-bottom: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
      }

      &:focus-within:not(.Mui-disabled),
      &:active:not(.Mui-disabled) {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
      }
      :hover:not(.Mui-disabled) {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }

      &.MuiInputBase-adornedStart {
        &.MuiInput-root {
          padding-left: 16px;
        }
      }

      .MuiInput-input {
        &.MuiInputBase-inputSizeLarge {
          line-height: 18px;
          font-size: 12px;
        }
      }

      .MuiInputAdornment-sizeLarge {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;
