import { useGetUserPermissions, USER_PERMISSION } from '@novaera/actioner-service';
import { useCallback } from 'react';

export const useUserPermissions = () => {
  const { data, isLoading } = useGetUserPermissions();

  return {
    checkPermission: useCallback(
      (permission: USER_PERMISSION) => Boolean(data?.permissions[permission]),
      [data?.permissions]
    ),
    isLoading,
  };
};
