import { NvFlex, NvSkeleton } from '@novaera/core';
import { FC } from 'react';

export interface InstallStepsLoadingProps {}

export const InstallStepsLoading: FC<InstallStepsLoadingProps> = () => {
  return (
    <NvFlex width="100%" gap="32px" alignItems="center" maxWidth="1024px" margin="0 auto">
      <NvSkeleton variant="rectangular" height="40px" width="50%" />
      <NvFlex gap="16px" width="100%">
        <NvFlex gap="4px">
          <NvSkeleton variant="rectangular" height="20px" width="100px" />
          <NvSkeleton variant="rectangular" height="16px" width="200px" />
        </NvFlex>

        <NvFlex width="100%" gap="8px">
          <NvSkeleton variant="rectangular" height="56px" />
          <NvSkeleton variant="rectangular" height="56px" />
        </NvFlex>
      </NvFlex>
    </NvFlex>
  );
};
