import { NvButton, NvCloseIcon } from '@novaera/core';
import { FC } from 'react';
import { PropertyPanelHeader } from '../../../../../../../../../components';
import { HTTPPropertiesSubPanelProps } from '../types';
import { HTTPKeyValueSubPanelMap } from './constants';
import { PropertyPanelKeyValueSection } from './property-panel-key-value-section';

export type KeyValueSubPanelType = 'queryParameters' | 'headers' | 'formParameters';

export type HTTPKeyValueSubPanelProps = Omit<HTTPPropertiesSubPanelProps, 'panelType'> & {
  keyValueSubPanelType: KeyValueSubPanelType;
};

export const HTTPKeyValueSubPanel: FC<HTTPKeyValueSubPanelProps> = ({ onCloseClicked, keyValueSubPanelType }) => {
  return (
    <>
      <PropertyPanelHeader
        title={HTTPKeyValueSubPanelMap[keyValueSubPanelType].title}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />
      <PropertyPanelKeyValueSection keyValueSubPanelType={keyValueSubPanelType} />
    </>
  );
};
