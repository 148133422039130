import { NvAxios } from '@novaera/core';
import { useQueries } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS } from '../keys';

import {
  GetWorkflowSourceOptionsParams,
  GetWorkflowSourceOptionsParamsForMultipleInputParameters,
  GetWorkflowSourceOptionsResponse,
} from './types';

const getWorkflowSourceOptions = async ({ appId, workflowId, payload }: GetWorkflowSourceOptionsParams) => {
  const result = await NvAxios.post<GetWorkflowSourceOptionsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/options`,
    payload
  );
  return result?.data;
};

export const useGetWorkflowSourceOptions = (params: GetWorkflowSourceOptionsParamsForMultipleInputParameters) => {
  const queries =
    params.requestParams.payload.inputParameterIds?.map((inputParameterId) => {
      const { inputParameterIds, inputParameters, searchAsYouTypeValues, ...rest } = params.requestParams.payload;

      let newInputParameters;

      if (params.calculateInputParameters) {
        newInputParameters = params.calculateInputParameters();
      } else {
        const inputParameterIndex = params.allInputParameterIdsWithOrder?.indexOf(inputParameterId);
        const inputParameterIdsToFilter = params.allInputParameterIdsWithOrder.slice(inputParameterIndex);
        const parentId = params.getDynamicInputParameterParent?.(inputParameterId);
        const dependencies = params.dependenciesOfParameters?.[parentId ?? inputParameterId];
        newInputParameters = inputParameters
          ?.filter((i) => !inputParameterIdsToFilter.includes(i.parameterId))
          .filter((i) => dependencies?.includes(i.parameterId));
      }

      const query = searchAsYouTypeValues?.[inputParameterId];
      const newParam: GetWorkflowSourceOptionsParams = {
        ...params.requestParams,
        payload: {
          inputParameterId,
          inputParameters: newInputParameters,
          ...(query ? { query } : {}),
          ...(params.requestParams.formId && { formId: params.requestParams.formId }),
          ...rest,
        },
      };
      return {
        queryKey: QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS.detail(newParam),
        queryFn: () => getWorkflowSourceOptions(newParam),
        ...params.options,
        enabled: params.enabledInputParameters.includes(inputParameterId),
      };
    }) ?? [];

  return useQueries({
    queries: queries,
  });
};
