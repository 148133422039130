import { useGetWorkflow } from '@novaera/actioner-service';
import { NvButton, NvConditionalRender, NvDivider, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { generatePath, useNavigate } from '@novaera/route';
import { useEffect } from 'react';
import { ROUTES } from '../common/routes';
import { ContactYourAdmin } from '../integrations/components/missing-connection/contact-your-admin-modal';
import { MissingConnectionPage } from '../integrations/components/missing-connection/page';
import { PROFILE_SETTINGS_TAB_PATHS } from '../profile-settings/constants';
import { useGetWorkflowQueryParams } from '../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { useCheckConnections } from '../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/controllers/use-check-connections';
import { PageFooter, StyledLink } from './styled';

export const AddConnection = () => {
  const navigate = useNavigate();
  const {
    getConnectionsByMutate,
    isLoading,
    data: connectionValidationCheckResult,
  } = useCheckConnections({
    isDraft: false,
    redirectIfNoMissingConnections: true,
  });

  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ workflowId, appId: userAppId });

  useEffect(() => {
    getConnectionsByMutate();
  }, [getConnectionsByMutate]);

  return (
    <ContactYourAdmin
      connectionValidationCheckResult={connectionValidationCheckResult}
      modal={false}
      workflowName={workflow?.name ?? ''}
      orElse={
        <>
          <NvConditionalRender
            when={
              !isLoading &&
              !!connectionValidationCheckResult &&
              connectionValidationCheckResult?.invalidNodeConnections.length > 0
            }
          >
            {workflow && (
              <MissingConnectionPage
                onConnectionsCompleted={() => {
                  getConnectionsByMutate();
                }}
                connectionValidationCheckResult={connectionValidationCheckResult}
                workflowName={workflow?.name}
              />
            )}
          </NvConditionalRender>
          <NvConditionalRender when={isLoading}>
            <NvFlex gap={'16px'}>
              <NvSkeleton variant="rectangular" width={'100px'} height={'24px'} />
              <NvSkeleton variant="rectangular" width={'350px'} height={'24px'} />
              <NvSkeleton variant="rectangular" width={'250px'} height={'24px'} />
              <NvFlex gap={'8px'}>
                <NvSkeleton variant="rectangular" width={'100%'} height={'56px'} />
                <NvSkeleton variant="rectangular" width={'100%'} height={'56px'} />
              </NvFlex>
            </NvFlex>
          </NvConditionalRender>
          <PageFooter>
            <NvButton
              size="small"
              color="secondary"
              onClick={() => {
                navigate(`${generatePath(ROUTES.UserApps)}/${userAppId}`);
              }}
            >
              Go to App
            </NvButton>
            <NvDivider orientation="vertical" sx={{ height: '10px' }} />
            <StyledLink to={`${ROUTES.ProfileSettings}${PROFILE_SETTINGS_TAB_PATHS.CONNECTIONS}`}>
              <NvTypography textColor="secondary">Manage your connections </NvTypography>
            </StyledLink>
          </PageFooter>
        </>
      }
    />
  );
};
