import { Variable } from '@novaera/actioner-service';
import { NvConditionalRender } from '@novaera/core';
import { FieldVariableActionProps } from '../types';
import { NvBasicTableWrapper } from './styled';
import { TableFieldBody } from './table-body';
import { FieldItemTableHeader } from './table-header';

export const RequestVariableBodyFieldsList = (
  props: {
    variables: Variable[];
  } & FieldVariableActionProps
) => {
  return (
    <NvConditionalRender when={() => props?.variables?.length > 0}>
      <NvBasicTableWrapper
        tableHeader={<FieldItemTableHeader />}
        tableBody={
          <TableFieldBody
            onUpdateFieldClicked={props.onUpdateFieldClicked}
            onRemoveFieldClicked={props.onRemoveFieldClicked}
            variables={props.variables}
          />
        }
      />
    </NvConditionalRender>
  );
};
