import {
  NvBox,
  NvField,
  NvFlex,
  NvHelpIcon,
  NvLooksOneRoundedIcon,
  NvSwitch,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { PropertyPanelSimpleSection } from '../../../../../../../../../../components';

export const OutputOrder = () => {
  const theme = useTheme();

  return (
    <PropertyPanelSimpleSection>
      <NvBox width={'100%'}>
        <NvField
          formControlStyle={{ width: '100%' }}
          name="order"
          type="checkbox"
          parse={(value) => {
            return value ? 1 : undefined;
          }}
          format={(value) => {
            return value ? true : false;
          }}
          component={({ onChange, checked, value }) => {
            return (
              <NvFlex flexDirection={'row'} alignItems={'center'}>
                <NvFlex direction={'row'} gap={'8px'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
                  <NvLooksOneRoundedIcon />
                  <NvTypography variant="body2">Primary output</NvTypography>
                  <NvTooltip
                    title={
                      'Turn this property on if you want this output to be displayed as the default selected one in the output dropdown.'
                    }
                  >
                    <NvHelpIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                      }}
                      htmlColor={theme.palette.nv_neutral[70]}
                    />
                  </NvTooltip>
                </NvFlex>
                <NvSwitch value={value} onChange={onChange} checked={checked} variant="compact" />
              </NvFlex>
            );
          }}
        />
      </NvBox>
    </PropertyPanelSimpleSection>
  );
};
