import { NvAxios, useMutation } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { GenerateFunctionParams } from './types';

const generateFunction = async ({
  appId,
  workflowId,
  nodeAlias,
  nodeName,
  userInput,
  currentFunction,
}: GenerateFunctionParams) => {
  const result = await NvAxios.post(`${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/ai/generate-function`, {
    nodeAlias,
    nodeName,
    userInput,
    ...(currentFunction ? { currentFunction } : {}),
  });
  return result?.data;
};

export const useGenerateFunction = () => {
  return useMutation(generateFunction);
};
