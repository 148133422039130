import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { FunctionPropertyPanel } from './panel';
import { FunctionPropertyPanelDrawerProps } from './types';

export const FunctionPropertyPanelDrawer: React.FC<React.PropsWithChildren<FunctionPropertyPanelDrawerProps>> = ({
  handleClose,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[FunctionPropertyPanelDrawerProps] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const { userAppId: appId, workflowId } = useParams();

  const { node: functionNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.function>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const {
    panelProps: [first],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        {isLoading ? (
          <PropertiesLoading />
        ) : (
          <NvFlex height={'100%'} flexDirection={'column'} justifyContent={'flex-start'}>
            <FunctionPropertyPanel onCloseClicked={handleClose} functionNode={functionNode} />
          </NvFlex>
        )}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
