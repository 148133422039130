import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ImageAndVideoCard = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 5px;
  gap: 4px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
  flex: 1 1 auto;
  min-width: 0;
`;
