import Dialog from '@mui/material/Dialog';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { NvBox } from '../box';
import { NvButton } from '../button';
import { NvFlex } from '../flex';
import { NvCloseIcon } from '../icons';
import { NvTypography } from '../typography';
import { FocusStateBody, FocusStateBodyFullWidth, FocusStateHeader } from './styled';
import {
  NvFocusStateProps,
  isFocusStateCustomHeaderProps,
  isFocusStateFullWidthVariantProps,
  isFocusStateTitleHeaderProps,
} from './types';

export const NvFocusState: React.FC<PropsWithChildren<NvFocusStateProps>> = (props) => {
  const customHeaderProps = isFocusStateCustomHeaderProps(props)
    ? {
        ...props,
      }
    : null;

  const fullWidthProps = isFocusStateFullWidthVariantProps(props)
    ? {
        ...props,
      }
    : null;

  const titleHeaderProps = isFocusStateTitleHeaderProps(props)
    ? {
        ...props,
      }
    : null;

  const { onClose, open, renderHeader, title, variant, className, headerLogo, maxWidth, children } = Object.assign(
    {},
    customHeaderProps,
    fullWidthProps,
    titleHeaderProps
  );

  return (
    <Dialog fullScreen open={open} onClose={onClose} transitionDuration={200} hideBackdrop className={className}>
      <NvFlex alignItems="center" className="NvFocusStateWrapper">
        <NvFlex
          className={classNames(FocusStateBody, variant === 'fullWidth' && FocusStateBodyFullWidth, 'NvFocusStateBody')}
          maxWidth={maxWidth}
        >
          {renderHeader !== undefined && title === undefined && renderHeader({ close: onClose })}

          {renderHeader === undefined && title !== undefined && (
            <FocusStateHeader>
              <NvFlex direction="row" alignItems="center" gap="16px" flex="1 1 auto">
                <NvBox width="40px" height="40px" display="flex" alignItems="center" justifyContent="center">
                  {headerLogo}
                </NvBox>

                <NvTypography variant="h1">{title}</NvTypography>
              </NvFlex>
              <NvButton
                color="secondary"
                onlyIcon
                onClick={() => {
                  onClose();
                }}
                sx={{ flex: '0 0 auto', minWidth: 0 }}
              >
                <NvCloseIcon />
              </NvButton>
            </FocusStateHeader>
          )}

          {children}
        </NvFlex>
      </NvFlex>
    </Dialog>
  );
};
