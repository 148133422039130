import { NvChevronRightIcon, NvImage } from '@novaera/core';
import { WorkspaceInitial } from '../../workspace-item/initial';
import { useWorkspaceMenuController } from '../controllers/use-workspace-menu-controller';
import { MenuItem } from '../menu-item';
import { WorkspacePopupMenuComponent } from './popup-menu-component';
import { WorkspaceMenuItemWrapper } from './styled';

export const WorkspaceMenu: React.FC<React.PropsWithChildren<{ onClick?: () => void }>> = ({ onClick }) => {
  const { selectedWorkspace, workspaces } = useWorkspaceMenuController();

  return (
    <MenuItem
      type="popup"
      onClick={onClick}
      labelProps={{
        type: 'typography',
        labelText: selectedWorkspace?.displayName,
        labelTypographyProps: { variant: 'h3' },
      }}
      key="workspace_menu"
      id="workspace_menu"
      icon={
        <NvImage
          FallBack={
            <WorkspaceInitial
              subdomain={(selectedWorkspace?.subdomain as string) ?? ''}
              displayName={(selectedWorkspace?.displayName as string) ?? ''}
              size="large"
            />
          }
          imageShape="square"
          size="large"
          src={selectedWorkspace?.logoUrl as string}
        />
      }
      isLoading={!selectedWorkspace}
      PopupMenuComponent={<WorkspacePopupMenuComponent workspaces={workspaces} selectedWorkspace={selectedWorkspace} />}
      indicator={<NvChevronRightIcon className="hidden-item" />}
      // MURAT
      MenuItemWrapperComponent={WorkspaceMenuItemWrapper}
    />
  );
};
