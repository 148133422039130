import { NvField, NvFlex, NvTypography } from '@novaera/core';
import { CronTimePicker } from '../../../../../components/cron-time-picker';
import { WEEKDAYS_CRON_EXPRESSION } from '../../../../../constants';

export const WeekdaysCronConfiguration: React.FC = () => (
  <NvField<string>
    name="recurrence.cron"
    component={({ value, onChange }) => {
      const cron = value ?? WEEKDAYS_CRON_EXPRESSION;

      return (
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvTypography>at</NvTypography>
          <CronTimePicker cron={cron} onChange={onChange} />
        </NvFlex>
      );
    }}
  />
);
