import { Operation } from '@novaera/actioner-service';

export const getConditionLabel = ({
  operator,
  negateResult,
}: {
  operator: Operation;
  negateResult?: boolean;
}): string => {
  switch (operator) {
    case Operation.EQUALS:
      if (negateResult) {
        return `does not equal`;
      } else {
        return `equals`;
      }
    case Operation.GREATER_THAN:
      return 'greater than';
    case Operation.GREATER_THAN_OR_EQUAL_TO:
      return 'greater than or equal to';
    case Operation.LESS_THAN:
      return 'less than';
    case Operation.LESS_THAN_OR_EQUAL_TO:
      return 'less than or equal to';
    case Operation.STARTS_WITH:
      if (negateResult) {
        return 'does not start with';
      } else {
        return 'starts with';
      }
    case Operation.ENDS_WITH:
      if (negateResult) {
        return 'does not end with';
      } else {
        return 'ends with';
      }
    case Operation.IS_ONE_OF:
      if (negateResult) {
        return 'is not one of';
      } else {
        return 'is one of';
      }
    case Operation.IS_EMPTY:
      if (negateResult) {
        return 'is not empty';
      } else {
        return 'is empty';
      }
    case Operation.EXISTS:
      if (negateResult) {
        return 'does not exist';
      } else {
        return 'exists';
      }
    case Operation.CONTAINS:
      if (negateResult) {
        return 'does not contain';
      } else {
        return 'contains';
      }
  }
};
