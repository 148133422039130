import { SchemaType } from '@novaera/ah-common';
import { FieldProps, isRequired, isValidJSON, NvField, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { isValid } from 'date-fns';
import { cloneElement, FC, useMemo } from 'react';

import { DataModelFieldSchemaTitle } from '../../../../../../../components/data-model-layout/right-content/header/data-model-field-schema-title';
import { findLogoFromFields } from '../../../../../../../components/data-model-layout/utils';
import { InputFieldWithSchema } from '../controller/types';
import { AUTOGENERATED_FIELD_NAMES, RECORD_SCHEMA_FORM_COMPONENT } from './constant';

export const FieldFactory: FC<
  React.PropsWithChildren<{ field: InputFieldWithSchema; isEdit: boolean; appId: string }>
> = ({ field: { name, schema, id }, isEdit, appId }) => {
  const theme = useTheme();
  const fieldProps = useMemo(() => {
    let baseProp: Partial<FieldProps> = {};
    if (schema?.mandatory && !AUTOGENERATED_FIELD_NAMES.includes(name)) {
      baseProp = { validators: [isRequired()], showErrorMessageOnlyWhenBlur: true, hasRequiredIndicator: true };
    }
    if (schema.type === SchemaType.dateTime) {
      baseProp = {
        ...baseProp,
        parse: (value: Date) => (isValid(value) ? value.getTime() : value),
        format: (value: string) => (value ? new Date(value) : value),
      };
    }
    if (schema.type === SchemaType.map) {
      baseProp = {
        ...baseProp,

        validators: [
          ...(baseProp.validators ? baseProp.validators : []),
          isValidJSON({
            valueExtractFunction: (value) => (typeof value === 'object' ? JSON.stringify(value, null, 2) : value),
          }),
        ],
        format: (value) => (typeof value === 'object' ? JSON.stringify(value, null, 2) : value),
        showErrorMessageOnlyWhenBlur: true,
      };
    }
    if (schema.type === SchemaType.array) {
      baseProp = {
        ...baseProp,
        isAutoComplete: true,
        format: (value: string[] | null) => (value ? value : []),
      };
    }
    if (schema.type === SchemaType.recordReference) {
      baseProp = {
        ...baseProp,
        isAutoComplete: true,
      };
    }

    return baseProp;
  }, [schema?.mandatory, schema.type, name]);

  const componentProps = useMemo(() => {
    if (schema.type === SchemaType.recordReference) {
      return { appId, recordReferenceSchema: schema };
    }
    if (schema.type === SchemaType.array) {
      return { appId, schema: schema };
    }
    if (schema.type === SchemaType.string && name === 'id' && isEdit) {
      return { disabled: true };
    }
    return {};
  }, [appId, isEdit, name, schema]);

  return (
    <NvFlex gap="4px">
      <NvFlex direction="row" justifyContent="space-between">
        <NvFlex direction="row" gap="2px" alignItems="center">
          <NvTypography variant="h5">{name}</NvTypography>
          {fieldProps.hasRequiredIndicator && (
            <NvTypography variant="h5" color={theme.palette.nv_error[40]}>
              *
            </NvTypography>
          )}
        </NvFlex>
        <NvFlex direction="row" gap="4px" alignItems="center">
          {cloneElement(findLogoFromFields(schema.type) ?? <></>, { sx: { width: '16px', height: '16px' } })}
          <DataModelFieldSchemaTitle textColor="secondary" variant="h5" schema={schema} />
        </NvFlex>
      </NvFlex>

      <NvField<unknown>
        key={`auto_generated_schema_field_${id}`}
        name={`${name}`}
        {...fieldProps}
        {...(schema.defaultValue ? { defaultValue: schema.defaultValue.value } : {})}
        component={cloneElement(RECORD_SCHEMA_FORM_COMPONENT[schema.type as SchemaType], componentProps)}
      />
    </NvFlex>
  );
};
