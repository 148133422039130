import React, { useMemo } from 'react';

export const NvConditionalRender: React.FC<React.PropsWithChildren<{ when: boolean | (() => boolean) }>> = ({
  when,
  children,
}) => {
  const shouldRender = useMemo(() => (typeof when === 'boolean' ? when : when()), [when]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return shouldRender ? <>{children}</> : null;
};
