import { APP_PERMISSION } from '@novaera/actioner-service';
import { NvButton, NvConditionalRender, NvDialogModal, NvUploadIcon, useNvDialogModalState } from '@novaera/core';
import { useParams } from '@novaera/route';
import { UserAppPermissionBoundary } from '../../../../../user-app/user-app-permission-boundary';
import { useUpdateAppButtonController } from './controller';
import { ManagedAppUpdateModalBody } from './update-modal-body';

export const UpdateAppButton = () => {
  const { userAppId } = useParams();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const {
    hasNewVersion,
    onUpdateClick,
    isUpdateInProgress,
    appSchemaDetail,
    isUpdateStarted,
    isCheckAvailableUpdateLoading,
  } = useUpdateAppButtonController();

  return (
    <>
      <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
        {!isCheckAvailableUpdateLoading && (
          <NvConditionalRender when={Boolean(hasNewVersion)}>
            <NvButton
              size="medium"
              startIcon={<NvUploadIcon />}
              isFitContent
              onClick={() => {
                onModalOpenClicked();
              }}
              loading={isUpdateInProgress}
            >
              Update available
            </NvButton>
          </NvConditionalRender>
        )}
      </UserAppPermissionBoundary>
      <NvDialogModal
        open={isOpened}
        isLoading={isUpdateStarted}
        onCloseButtonClicked={onModalCloseClicked}
        Header="Update app"
        modalIcon="appUpdate"
        primaryButtonText="Update"
        Body={<ManagedAppUpdateModalBody appSchemaDetail={appSchemaDetail} />}
        onFormSubmit={() => {
          onUpdateClick(() => onModalCloseClicked());
        }}
      />
    </>
  );
};
