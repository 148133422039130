import { NvAxios, useMutation } from '@novaera/core';
import { SLACK_USERS_ROOT_PATH } from '../../constants';
import { InviteUserParams } from './types';

const inviteSlackUsers = async (params: InviteUserParams) => {
  const result = await NvAxios.post<void>(SLACK_USERS_ROOT_PATH + '/invite', params);
  return result?.data;
};

export const useInviteSlackUsers = () => {
  return useMutation(inviteSlackUsers);
};
