import { NvFlex, NvSkeleton } from '@novaera/core';

import { useRecordParametersContext } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/records-parameter-mapping/provider';
import { ParameterMapperForm } from '../../parameter-mapper/parameter-mapper-form';
import { RecordAttributesUIComponentParams } from '../use-ui-component/types';

export const RecordAttributesComponent: React.FC<React.PropsWithChildren<RecordAttributesUIComponentParams>> = ({
  inputProps,
  context,
}) => {
  const { recordParameters, isRecordParametersLoading } = useRecordParametersContext();
  const { value, onChange } = inputProps;

  return isRecordParametersLoading ? (
    <NvFlex gap="16px">
      <NvSkeleton variant="rectangular" height="32px" />
      <NvSkeleton variant="rectangular" height="32px" />
      <NvSkeleton variant="rectangular" height="32px" />
    </NvFlex>
  ) : (
    <ParameterMapperForm
      dynamicInputParameters={[]}
      context={context}
      inputParameters={recordParameters.filter(({ name }) => name !== 'id')}
      initialParameterMappings={value}
      onParameterMappingsChanged={onChange}
    />
  );
};
