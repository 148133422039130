import { useGetTimezones } from '@novaera/actioner-service';
import { NvAccessTimeRounded, NvSkeleton } from '@novaera/core';
import { getClientTimezoneId } from '@novaera/utils';
import { useMemo } from 'react';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import { TimezonesField } from '../../../../../../../../jobs/save-job-modal/timezones';

export const JobTimezone: React.FC = () => {
  const { data, isInitialLoading: isGetTimezonesLoading } = useGetTimezones();
  const initialSelectedTimezoneId = getClientTimezoneId();
  const timezones = useMemo(() => data?.timezones ?? [], [data?.timezones]);
  const initialTimezone = useMemo(
    () => timezones.find((t) => t.id === initialSelectedTimezoneId)?.id,
    [initialSelectedTimezoneId, timezones]
  );
  return (
    <PropertyPanelSection title={'Timezone'} icon={<NvAccessTimeRounded />}>
      {isGetTimezonesLoading ? (
        <NvSkeleton width="100%" height="32px" variant="rectangular" />
      ) : (
        <TimezonesField
          timezones={timezones}
          name="operation.timezone"
          format={(v) => timezones.find((t) => t.id === v)}
          parse={(value) => value?.id}
          defaultValue={initialTimezone}
        />
      )}
    </PropertyPanelSection>
  );
};
