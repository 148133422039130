import { InputParameters, useGetDataModel } from '@novaera/actioner-service';
import { ValidatorFunction } from '@novaera/core';
import { UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  RECORD_FIELD_TYPE_UI_COMPONENT,
  TABLE_HIDDEN_FIELD_NAMES,
} from '../../../components/data-model-layout/constants';

export const isDataModelNameValid: ValidatorFunction = (param) => (value) => {
  const message =
    param?.message ??
    'Table name can only contain letters(a-zA-Z), numbers(0-9), space( ), hyphen(-) and underscore(_) characters';

  //create regex to validate "name" to be alphanumeric and can contain spaces and can contain _,(,),&,-,/
  const nameRegex = /^[a-zA-Z0-9_//\-/\s]+$/;

  const isValid = nameRegex.test(value);
  return isValid ? undefined : message;
};

export const useGenerateInputParametersFromRecordModel = ({
  appId,
  modelId,
  retry,
  onError,
}: {
  appId?: string;
  modelId?: string;
  retry: UseQueryOptions['retry'];
  onError?: (err: unknown) => void;
}) => {
  const { dataModel: model, isLoading: isModelLoading } = useGetDataModel({
    appId,
    modelId,
    retry,
    onError,
  });

  const inputParameters: InputParameters = useMemo(
    () =>
      model?.fields
        .filter((field) => !TABLE_HIDDEN_FIELD_NAMES.includes(field.id))
        .map((f) => ({
          ...f,
          uiComponent: RECORD_FIELD_TYPE_UI_COMPONENT({ label: f.name })[f.schema.type],
        })) ?? [],
    [model?.fields]
  );

  return {
    inputParameters,
    fields: model?.fields.filter((field) => !TABLE_HIDDEN_FIELD_NAMES.includes(field.id)) ?? [],
    isLoading: isModelLoading,
  };
};
