import { PrivateRoute } from '@novaera/route';
import { PropsWithChildren } from 'react';
import { AppLoading } from '../components/app-loading';
import { NavigationMenu } from '../components/navigation-menu';
import { UserPermissionProvider } from '../user-permission-boundary/provider';
import { ActionerPrivateRouteProps } from './types';

export const ActionerPrivateRoute: React.FC<PropsWithChildren<ActionerPrivateRouteProps>> = ({
  children,
  hasNoMenu,
}) => (
  <PrivateRoute LoadingComponent={<AppLoading />}>
    <UserPermissionProvider>
      {hasNoMenu ? children : <NavigationMenu>{children}</NavigationMenu>}
    </UserPermissionProvider>
  </PrivateRoute>
);
