import { useCreateDocument, useDeleteDocument, useListDocs } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { useCallback, useState } from 'react';
import { useIsAppFree } from '../../../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';

export const useDocumentListController = () => {
  const { userAppId } = useParams();

  const [searchKey, setSearchKey] = useState<string | undefined>();
  const { mutate: createDocument, isLoading: isCreateLoading } = useCreateDocument();
  const { mutate: deleteDocument } = useDeleteDocument();
  const { isAppFree } = useIsAppFree({ userAppId });

  const {
    data: docsData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useListDocs({
    appId: userAppId,
    queryParams: {
      namePrefix: searchKey,
    },
  });

  const showNoSearchResult = !docsData?.pages.flatMap((page) => page.documents).length && !!searchKey && !isLoading;
  const showEmptyState = !docsData?.pages.flatMap((page) => page.documents).length && !searchKey && !isLoading;

  const handleSearchKeyChange = useCallback((keyword?: string) => {
    setSearchKey(keyword);
  }, []);

  const handleShowMoreClicked = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return {
    userAppId,
    createDocument,
    docs: docsData?.pages.flatMap((page) => page.documents) ?? [],
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    deleteDocument,
    onSearchKeyChange: handleSearchKeyChange,
    showNoSearchResult,
    showEmptyState,
    isLoading,
    isCreateLoading,
    onShowMoreClicked: handleShowMoreClicked,
    isAppFree,
  };
};
