import { OutputNodeItem, OutputNodeSummary, OutputVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class OutputComponentFactory implements ComponentFactory<OutputNodeItem> {
  aliasForOutput: string;
  nameForOutput: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('output');
    this.aliasForOutput = newAlias;
    this.nameForOutput = newName;
  }

  get componentForGraph() {
    const outputRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForOutput,
      width: 40,
      height: 40,
      id: this.aliasForOutput,
      type: 'output',
      alias: this.aliasForOutput,
    };

    return { root: outputRoot };
  }

  componentForWorkflow(serverData: OutputNodeItem) {
    const newNodeUnion: OutputVertex = {
      type: 'output',
      alias: this.aliasForOutput,
    };

    const summary: OutputNodeSummary = {
      name: this.nameForOutput,
      alias: this.aliasForOutput,
      ...serverData,
    };

    return { root: newNodeUnion, nodeSummaries: { [this.aliasForOutput]: summary } };
  }
}
