import { NvAxios, useQuery } from '@novaera/core';
import { SUBSCRIPTIONS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_SUBSCRIPTION } from '../keys';
import { Subscription } from '../types';

const getSubscriptionStatus = async () => {
  const result = await NvAxios.get<Subscription>(`${SUBSCRIPTIONS_ROOT_PATH}`);
  return result?.data;
};

export const useGetSubscriptionStatus = () => {
  return useQuery<Subscription>(QUERY_KEYS_SUBSCRIPTION.all, () => getSubscriptionStatus());
};
