import { USER_PERMISSION, useGetUserPermissions } from '@novaera/actioner-service';
import { usePermission } from '../utils/hooks/use-permission';
import { UserPermissionBoundaryProps } from './types';

export const UserPermissionBoundary = <PERM extends USER_PERMISSION>(param: UserPermissionBoundaryProps<PERM>) => {
  const { data } = useGetUserPermissions();

  const result = usePermission({ permissions: data?.permissions ?? {}, ...param });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{result}</>;
};
