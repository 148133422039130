import { Connection } from '@novaera/actioner-service';
import { FormattedConnectionType } from '../connection-schema-modal/types';
import {
  OAuth2PromiseResultType,
  OAuth2PromiseSuccessResultType,
} from './oauth2-connection/controllers/use-oauth2-connection/types';

export type ConnectModalMode = 'Connect' | 'Test';
export type ConnectModalTest = 'connection-schema' | 'connection';

export type ConnectModalProps = {
  isOpened?: boolean;
  onModalCloseClicked: (params?: { connection?: Connection }) => void;
  mode: ConnectModalMode;
  connected?: boolean;
  connectionId?: string;
  // the prop to determine which test service will be used. either connection schema, or the connection
  test?: ConnectModalTest;
  connectionName?: string;
  integrationId?: string;
  schemaId?: string;
  modalTitle?: string;
  onFormSubmitFinished?: (result: Connection | OAuth2PromiseResultType | void) => void;
};

export type ConnectionModalForm = Record<string, string | undefined> & {
  name?: string;
  selectedSchema?: FormattedConnectionType;
};

export const isOAuth2SuccessPromiseResult = (
  connection: Connection | OAuth2PromiseResultType
): connection is OAuth2PromiseSuccessResultType => {
  return (connection as OAuth2PromiseSuccessResultType).type === 'success';
};

export const isOAuth2PromiseResult = (
  connection: Connection | OAuth2PromiseResultType
): connection is OAuth2PromiseResultType => {
  return (
    (connection as OAuth2PromiseResultType).type === 'success' ||
    (connection as OAuth2PromiseResultType).type === 'error'
  );
};
