import { NvAxios, useQuery } from '@novaera/core';
import { SetOAuthCodeParams } from '../types';
import { SetOAuthCodeResponse } from './types';

const setOAuthCode = async ({ code, state }: SetOAuthCodeParams) => {
  const result = await NvAxios.get<SetOAuthCodeResponse>(`/v1/oauth/code?code=${code}&state=${state}`);
  return result?.data;
};

export const useSetOAuthCode = ({ code, state }: SetOAuthCodeParams) => {
  return useQuery(['SetCode'], () => setOAuthCode({ code, state }), { retry: false });
};
