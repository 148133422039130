import { MenuItemNameWrapper, NvDeleteOutlineIcon, NvSelectMenuItem, NvStyledMenu } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { bindPopover, PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import { createContext, FC, useContext, useState } from 'react';
import { usePropertyPanelContext } from '../properties-panel/provider';

const NodeMoreContext = createContext<
  | {
      popupState: PopupState;
      setNodeMoreNodeId: (buttonContext: string) => void;
    }
  | undefined
>(undefined);

export const NodeMoreProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });
  const theme = useTheme();
  const [nodeMoreNodeId, setNodeMoreNodeId] = useState<string>();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();

  const value = { popupState, setNodeMoreNodeId };
  return (
    <NodeMoreContext.Provider value={value}>
      {children}
      <NvStyledMenu
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NvSelectMenuItem
          sx={{ width: '200px' }}
          onClick={(e) => {
            if (nodeMoreNodeId) {
              deleteNodeAndUpdateGraph({ nodeId: nodeMoreNodeId });
              popupState.close();
            }
          }}
        >
          <MenuItemNameWrapper>Delete</MenuItemNameWrapper>
          <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
        </NvSelectMenuItem>
      </NvStyledMenu>
    </NodeMoreContext.Provider>
  );
};

export const useNodeMoreContext = () => {
  const context = useContext(NodeMoreContext);
  assert(!!context, new Error(`useNodeMoreContext should be used within NodeMoreProvider`), 'ERROR');

  return context;
};
