import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCheckCircle({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.1799 9.73327C17.5849 9.35775 17.6088 8.72504 17.2333 8.32007C16.8577 7.91509 16.225 7.89122 15.8201 8.26674L10.0556 13.612L7.70711 11.2635C7.31658 10.873 6.68342 10.873 6.29289 11.2635C5.90237 11.654 5.90237 12.2872 6.29289 12.6777L9.3223 15.7071C9.70245 16.0873 10.3151 16.0988 10.7094 15.7333L17.1799 9.73327Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
