import { StaticYearlyJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { CronDayOfMonthPicker } from '../../../../../../../../../../../jobs/components/cron-day-of-month-picker';
import { CronMonthPicker } from '../../../../../../../../../../../jobs/components/cron-month-picker';
import { CronTimePicker } from '../../../../../../../../../../../jobs/components/cron-time-picker';
import { ANNUAL_CRON_EXPRESSION } from '../../../../../../../../../../../jobs/constants';
import { RecurrenceProps } from '../../types';

export const StaticYearlyRecurrence: React.FC<
  Omit<RecurrenceProps<StaticYearlyJobRecurrenceConfiguration>, 'context'>
> = ({ recurrenceConfiguration, onChange }) => {
  const cron = recurrenceConfiguration.cronExpression ?? ANNUAL_CRON_EXPRESSION;

  return (
    <NvFlex direction="column" gap="8px" width="100%">
      <CronMonthPicker
        cron={cron}
        onChange={(cron) => {
          onChange({
            ...recurrenceConfiguration,
            cronExpression: cron,
          });
        }}
      />

      <CronDayOfMonthPicker
        cron={cron}
        onChange={(cron) => {
          onChange({
            ...recurrenceConfiguration,
            cronExpression: cron,
          });
        }}
      />

      <NvFlex direction="row" alignItems="flex-start" gap="8px">
        <NvTypography padding="6px 0" flex="0 0 auto">
          At
        </NvTypography>
        <CronTimePicker
          cron={cron}
          onChange={(cron) => {
            onChange({
              ...recurrenceConfiguration,
              cronExpression: cron,
            });
          }}
        />
      </NvFlex>
    </NvFlex>
  );
};
