import {
  NvChevronRightIcon,
  NvCollapse,
  NvConditionalWrap,
  NvCustomHelpIcon,
  NvDragIndicatorIcon,
  NvFlex,
  NvKeyboardArrowDownIcon,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import React, { ReactElement, isValidElement, useState } from 'react';

import {
  PropertyPanelSectionBody,
  PropertyPanelSectionContainer,
  PropertyPanelSectionHeader,
  PropertyPanelSectionHeaderActions,
  PropertyPanelSectionHeaderCollapseIconBox,
  PropertyPanelSectionHeaderDragIconBox,
} from './styled';
import { PropertyPanelSectionProps } from './types';

export const PropertyPanelSection: React.FC<React.PropsWithChildren<PropertyPanelSectionProps>> = ({
  title,
  collapsible = false,
  draggable = false,
  dragHandleProps,
  icon,
  children,
  tooltip,
  actions,
  dragging,
  defaultCollapsed = false,
}) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultCollapsed);

  return (
    <PropertyPanelSectionContainer className={classNames({ 'dragging-class': dragging })}>
      <PropertyPanelSectionHeader>
        <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
          {draggable ? (
            <PropertyPanelSectionHeaderDragIconBox {...dragHandleProps}>
              <NvDragIndicatorIcon sx={{ width: '16px', height: '16px' }} />
            </PropertyPanelSectionHeaderDragIconBox>
          ) : null}
          <NvFlex
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap="4px"
            flex="0 1 auto"
            minWidth={0}
            {...(collapsible ? { onClick: () => setIsCollapsed(!isCollapsed), sx: { cursor: 'pointer' } } : {})}
          >
            {!collapsible && isValidElement(icon)
              ? React.cloneElement(icon as ReactElement, { sx: { width: '16px', height: '16px' } })
              : null}

            {collapsible && (
              <PropertyPanelSectionHeaderCollapseIconBox>
                {isCollapsed ? (
                  <NvChevronRightIcon sx={{ width: '16px', height: '16px' }} />
                ) : (
                  <NvKeyboardArrowDownIcon sx={{ width: '16px', height: '16px' }} />
                )}
              </PropertyPanelSectionHeaderCollapseIconBox>
            )}

            <NvTypography variant="h5" noWrap>
              {title}
            </NvTypography>
          </NvFlex>

          {tooltip &&
            (isValidElement(tooltip) ? (
              tooltip
            ) : (
              <NvTooltip title={tooltip}>
                <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
              </NvTooltip>
            ))}
        </NvFlex>
        {actions && <PropertyPanelSectionHeaderActions>{actions}</PropertyPanelSectionHeaderActions>}
      </PropertyPanelSectionHeader>

      <NvConditionalWrap
        condition={collapsible}
        wrap={(children) => (
          <NvCollapse in={!isCollapsed} mountOnEnter unmountOnExit>
            {children}
          </NvCollapse>
        )}
      >
        {children ? <PropertyPanelSectionBody>{children}</PropertyPanelSectionBody> : <></>}
      </NvConditionalWrap>
    </PropertyPanelSectionContainer>
  );
};
