import classNames from 'classnames';
import { FieldTitle } from '../field/title';
import { NvFlex } from '../flex';
import { ActionBox, CenterItem, GroupWrapper, SideGroupedWrapper, Title } from './styled';
import { SideGroupedInputLayoutProps } from './types';

export const NvSideGroupedInputLayout = (props: SideGroupedInputLayoutProps): JSX.Element => {
  const {
    formItem,
    title,
    leftAction,
    rightAction,
    variant = 'default',
    defaultDisplayValue,
    CustomGroupWrapper = GroupWrapper,
  } = props;

  return (
    <SideGroupedWrapper className={classNames({ 'has-border': variant === 'sub-group' })}>
      {variant === 'sub-group' ? (
        <>
          <NvFlex flexDirection="row">
            {title && title.labelText && (
              <Title>
                <FieldTitle direction="label-on-top" defaultDisplayValue={defaultDisplayValue} {...title} />
              </Title>
            )}
            {rightAction && <ActionBox>{rightAction}</ActionBox>}
          </NvFlex>
          <CustomGroupWrapper>
            {leftAction && <ActionBox>{leftAction}</ActionBox>}
            {<CenterItem>{formItem}</CenterItem>}
          </CustomGroupWrapper>
        </>
      ) : (
        <>
          {title && title.labelText && (
            <Title>
              <FieldTitle direction="label-on-top" {...title} defaultDisplayValue={defaultDisplayValue} />
            </Title>
          )}
          <CustomGroupWrapper>
            {leftAction && <ActionBox>{leftAction}</ActionBox>}
            {<CenterItem>{formItem}</CenterItem>}
            {rightAction && <ActionBox>{rightAction}</ActionBox>}
          </CustomGroupWrapper>
        </>
      )}
    </SideGroupedWrapper>
  );
};
