import { NvAxios } from '@novaera/core';
import { useQueries } from '@tanstack/react-query';
import { INTEGRATION_ACTION_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS } from '../constants';
import { QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS } from '../keys';

import {
  GetDynamicInputParametersParams,
  GetDynamicInputParametersResponse,
  GetDynamicInputParamsForMultipleInputParameters,
} from '../types';

const getDynamicInput = async ({ integrationId, actionId, payload, ...rest }: GetDynamicInputParametersParams) => {
  if (rest.type === 'in-workflow') {
    const result = await NvAxios.post<GetDynamicInputParametersResponse>(
      `/v2/apps/${rest.appId}/workflows/${rest.workflowId}/action-dynamic-input-parameters`,
      { ...payload, integrationId, actionId }
    );
    return result?.data;
  } else {
    const result = await NvAxios.post<GetDynamicInputParametersResponse>(
      INTEGRATION_ACTION_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS(integrationId, actionId),
      { ...payload, connectionId: rest.connectionId }
    );
    return result?.data;
  }
};

export const useGetDynamicInputParameters = (params: GetDynamicInputParamsForMultipleInputParameters) => {
  const queries =
    params.requestParams.payload.map((payload) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { requestParams, options, enabledInputParameters, allInputParameterIdsWithOrder, ...rest } = params;
      const newParam: GetDynamicInputParametersParams = {
        ...requestParams,
        payload,
        ...rest,
      };
      return {
        queryKey: QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS.detail(newParam),
        queryFn: () => getDynamicInput(newParam),
        ...options,
        enabled: enabledInputParameters.includes(payload.inputParameterId),
        staleTime: 0,
      };
    }) ?? [];

  return useQueries({
    queries: queries,
  });
};
