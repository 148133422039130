import { NvFlex, NvLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CreateWorkspaceButton = styled(NvLink)``;

export const ButtonFlex = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 12px;
`;
