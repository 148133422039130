import { DefaultNodeSummary, SendEmailNode, SendEmailVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class SendEmailComponentFactory implements ComponentFactory<SendEmailNode> {
  aliasForSendEmail: string;
  nameForSendEmail: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('sendEmail');
    this.aliasForSendEmail = newAlias;
    this.nameForSendEmail = newName;
  }

  get componentForGraph() {
    const outputRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForSendEmail,
      width: 40,
      height: 40,
      id: this.aliasForSendEmail,
      type: 'sendEmail',
      alias: this.aliasForSendEmail,
    };

    return { root: outputRoot };
  }

  componentForWorkflow(serverData: SendEmailNode) {
    const newNodeUnion: SendEmailVertex = {
      type: 'sendEmail',
      alias: this.aliasForSendEmail,
    };

    const summary: DefaultNodeSummary = {
      name: this.nameForSendEmail,
      alias: this.aliasForSendEmail,
      type: 'sendEmail',
    };

    return { root: newNodeUnion, nodeSummaries: { [this.aliasForSendEmail]: summary } };
  }
}
