import { NvFlex, NvMenuItem } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { PropertyPanelSimpleSection } from '../../../../components';

export const WorkflowHistoryListContainer = styled(NvFlex)`
  width: 280px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  overflow: auto;
  padding-bottom: 60px;
  flex: 0 0 auto;
`;

export const MenuItemWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 16px;
  margin-top: 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
`;

export const NvMenuItemWrapper = styled(NvMenuItem)`
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.nv_main[20]};
  }
`;

export const FilterTitleWrapper = styled(NvFlex)`
  padding-left: 16px;
  margin-bottom: 4px;
`;

export const WorkflowHistoryFilterSection = styled(PropertyPanelSimpleSection)`
  position: sticky;
  top: 32px;
  z-index: 10;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  flex: 0 0 auto;
`;
