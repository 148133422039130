import { useGetActionerEvent, useUpdateActionerEventPayload } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { useIsAppFree } from '../../../../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { EventDetailForm } from '../../../types';

export const useEventDetailBody = () => {
  const { userAppId, eventId } = useParams<{ userAppId: string; eventId: string }>();

  const { mutate: updateEventPayload } = useUpdateActionerEventPayload();
  const { data: event, isLoading: isEventLoading } = useGetActionerEvent({ appId: userAppId, id: eventId });
  const { isAppFree } = useIsAppFree({ userAppId });

  const handleFormSubmit = (values: EventDetailForm) => {
    if (!isAppFree) {
      updateEventPayload({
        appId: userAppId,
        id: eventId,
        payload: {
          examplePayload: values.examplePayload,
          payloadSchema: values.payloadSchema,
        },
      });
    }
  };

  return {
    onFormSubmit: handleFormSubmit,
    event,
    isEventLoading,
  };
};
