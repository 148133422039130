import { ConditionExecutionDetails } from '@novaera/actioner-service';
import {
  CodeInput,
  NvCustomCheckCircle,
  NvCustomConditionIcon,
  NvErrorIcon,
  NvFlex,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { PropertyPanelSection, PropertyPanelSimpleSection } from '../../../../../../components';
import { DEFAULT_CONDITION_TYPE_OPTIONS } from '../../../../../../components/match-event-section/constants';
import { getConditionLabel } from '../../../../../../components/match-event-section/utils';
import { ResponseHeader } from '../styled';
import { RuleKeyValue } from './rule-key-value';
import { isDynamicRuleValue, isJavascriptRuleEvaluationResult } from './utils';

export const ConditionResults: React.FC<{ executionDetails: ConditionExecutionDetails }> = ({ executionDetails }) => {
  const theme = useTheme();
  const conditionLabel = useMemo(() => {
    return DEFAULT_CONDITION_TYPE_OPTIONS.find((o) => o.value === executionDetails.conditionType)?.label;
  }, [executionDetails.conditionType]);

  return (
    <>
      <ResponseHeader
        title="conditions"
        actions={
          executionDetails.conditionMatched ? (
            <NvCustomCheckCircle htmlColor={theme.palette.nv_success[50]} sx={{ width: '16px', height: '16px' }} />
          ) : (
            <NvErrorIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
          )
        }
      />

      {executionDetails.conditionType !== 'javascript' && (
        <PropertyPanelSimpleSection>
          <NvFlex direction="row" alignItems="center" gap="4px">
            <NvCustomConditionIcon sx={{ width: '16px', height: '16px' }} />
            <NvTypography variant="h5">{conditionLabel}</NvTypography>
          </NvFlex>
        </PropertyPanelSimpleSection>
      )}

      {executionDetails.ruleEvaluationResults?.map((result, index) => (
        <PropertyPanelSection
          title={executionDetails.conditionType !== 'javascript' ? `Rule ${index + 1}` : 'Advanced mode'}
          collapsible
          actions={
            result.type === 'matched' ? (
              <NvCustomCheckCircle htmlColor={theme.palette.nv_success[50]} sx={{ width: '16px', height: '16px' }} />
            ) : (
              <NvErrorIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
            )
          }
        >
          {isJavascriptRuleEvaluationResult(result) ? (
            <CodeInput isDisabled value={result.function} context={{}} initialHeight={'178px'} growingHeight />
          ) : (
            <NvFlex gap="8px" alignItems="flex-start">
              <RuleKeyValue label="Key:" value={result.rule.keyExpression} />
              <RuleKeyValue
                label="Operator:"
                value={getConditionLabel({ operator: result.rule.operation, negateResult: result.rule.negateResult })}
              />
              {result.rule.value && (
                <RuleKeyValue
                  label="Value:"
                  value={`${result.valueObject} `}
                  {...(isDynamicRuleValue(result.rule.value)
                    ? {
                        secondaryValue: `(${result.rule.value.valueExpression})`,
                      }
                    : {})}
                />
              )}
              <RuleKeyValue
                label="Event value:"
                value={`${result.keyObject}`}
                isSuccess={!!(result.type === 'matched')}
              />
            </NvFlex>
          )}
        </PropertyPanelSection>
      ))}
    </>
  );
};
