import { Initial, NvConditionalRender, NvFlex, NvImage, NvPowerRoundedIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { useGetConnectionSchemaService } from '@novaera/actioner-service';
import { MyConnectionItemWrapper } from '../styled';
import { ConnectionSchemaProps } from './types';
import { UserConnectionInfo } from './user-connection-info';

export const ConnectionSchema = ({ userConnection }: ConnectionSchemaProps) => {
  const theme = useTheme();
  const { data: connectionSchema } = useGetConnectionSchemaService({
    integrationId: userConnection.integrationId,
    schemaId: userConnection.schemaId,
  });

  return (
    <MyConnectionItemWrapper>
      <NvFlex flexDirection={'row'} width="100%">
        <NvFlex flexDirection={'row'} flex={'1 1 auto'} minWidth="0" columnGap="10px" alignItems={'center'}>
          <NvImage
            FallBack={
              <Initial
                value={userConnection.schemaName || 'Connection'}
                size="small"
                color={theme.palette.nv_neutral[500]}
              />
            }
            src={userConnection.logoUrl}
            hasNoElevation
          />
          <NvFlex direction="column">
            <NvTypography variant="h3" noWrap>
              {userConnection.schemaName}
            </NvTypography>
            <NvTypography variant="c3" textColor="subtle">
              {connectionSchema?.authentication.baseUrl}
            </NvTypography>
          </NvFlex>
        </NvFlex>
      </NvFlex>

      <NvFlex gap="8px">
        {userConnection.connections.map((data) => (
          <UserConnectionInfo
            schemaId={userConnection.schemaId}
            key={data.id}
            connectionInfo={data}
            integrationId={userConnection.integrationId}
          />
        ))}
      </NvFlex>
      <NvConditionalRender when={() => userConnection.connections.length === 0}>
        <NvFlex gap={'8px'} flexDirection="row" alignItems={'center'}>
          <NvPowerRoundedIcon htmlColor={theme.palette.nv_neutral_alpha[60]} />
          <NvTypography variant="body2" textColor="subtle">
            There is no connection
          </NvTypography>
        </NvFlex>
      </NvConditionalRender>
    </MyConnectionItemWrapper>
  );
};
