import { InputParameter } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { NvButton, NvCloseIcon, NvField, NvFlex, NvTextField, useField } from '@novaera/core';
import { FC } from 'react';
import { StaticOptionItemProps } from './types';

export const StaticOptionItem: FC<React.PropsWithChildren<StaticOptionItemProps>> = ({ optionFieldName, onRemove }) => {
  const {
    input: { value: schema },
  } = useField<InputParameter['schema']>('schema');

  return (
    <NvFlex gap="8px" direction="row">
      <NvFlex gap="8px" width="100%">
        <NvField
          labelVariant="h6"
          labelText="Display"
          direction="label-on-side"
          component={<NvTextField size="small" />}
          name={`${optionFieldName}.displayValue`}
        />
        <NvField
          labelVariant="h6"
          labelText="Identifier"
          direction="label-on-side"
          component={({ onChange, ...props }) => {
            return (
              <NvTextField
                {...props}
                onChange={(e) => {
                  const targetValue = e.target.value;

                  let valueType;

                  if (schema?.type === SchemaType.array) {
                    valueType = schema.valueSchema.type;
                  } else {
                    valueType = schema?.type;
                  }

                  if (
                    valueType === SchemaType.string ||
                    valueType === SchemaType.email ||
                    valueType === SchemaType.uuid ||
                    valueType === SchemaType.url
                  ) {
                    onChange(targetValue);
                  } else if (valueType === SchemaType.float) {
                    const retVal = parseFloat(targetValue);
                    if (!isNaN(retVal)) {
                      onChange(retVal);
                    } else {
                      onChange(undefined);
                    }
                  } else if (valueType === SchemaType.integer) {
                    const retVal = parseInt(targetValue);
                    if (!isNaN(retVal)) {
                      onChange(retVal);
                    } else {
                      onChange(undefined);
                    }
                  } else {
                    onChange(e);
                  }
                }}
                size="small"
              />
            );
          }}
          name={`${optionFieldName}.key`}
        />
      </NvFlex>
      <NvButton onlyIcon color="ghost" size="small" onClick={onRemove}>
        <NvCloseIcon />
      </NvButton>
    </NvFlex>
  );
};
