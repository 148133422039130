import { USER_PERMISSION, useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { useMemo } from 'react';

import { useUserPermissions } from '../../../../../../../user-permission-boundary/use-user-permission';
import { useRequestContext } from '../../../../../../providers/request-provider';
import { useRequestActionMenu } from '../../../controllers/use-request-action-menu';

export const useRequestList = () => {
  const { selectedRequestId, setSelectedRequestId } = useRequestContext();
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const { actionId, integrationId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ actionId, integrationId, version: integration?.latestVersion.number });

  const httpRequestConfigurations = useMemo(() => data?.httpRequestConfigurations, [data?.httpRequestConfigurations]);

  const { actionButtons } = useRequestActionMenu();
  const handleItemClick = (id: string) => {
    setSelectedRequestId(id);
  };

  return {
    httpRequestConfigurations,
    selectedRequestId,
    handleItemClick,
    actionButtons,
    requestValidationResults: false,
    hasIntegrationUpdateRight,
  };
};
