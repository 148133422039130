import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const VersionHistoryItem = styled(NvFlex)`
  padding: 12px 12px 12px 16px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
  box-sizing: border-box;
  border-radius: 12px;
  gap: 8px;
`;
