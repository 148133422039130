import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const BorderedCard = styled(NvFlex)`
  flex-direction: row;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
`;
