import { FieldTitle, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const FieldTitleContent = styled(NvFlex)`
  flex-direction: row;
  width: 100%;
`;

export const FieldTitleWrapper = styled(FieldTitle)`
  flex: 1 1 auto;
`;

export const TestResult = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[10]};
  border-radius: 6px;
  padding: 16px;
  width: 100%;
`;
