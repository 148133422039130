import { NvAppBar, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

// ------------------------------------------------------------------------------------------------
// Main app wrapper styling

export const AppWrapper = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]}; ;
`;

export const AppWrapperContent = styled(NvFlex)`
  height: 100vh;
  padding-left: 64px;
  position: relative;
  z-index: 10;

  &.has-no-menu {
    padding-left: 0;
    width: 100vw;
  }
`;

// ------------------------------------------------------------------------------------------------
// Top Navigation styles

export const NavWrapper = styled(NvAppBar, { shouldForwardProp: (prop) => prop !== 'hideBorderBottom' })<{
  hideBorderBottom: boolean;
}>`
  background-color: transparent;
  height: 62px;
  flex: 0 0 auto;
  border-bottom: 1px solid
    ${({ theme, hideBorderBottom }) => (!hideBorderBottom ? theme.palette.nv_neutral_alpha[30] : 'transparent')};
  color: ${({ theme }) => theme.palette.nv_neutral[1000]};
`;

export const ContentWrapper = styled(NvFlex)``;

// ------------------------------------------------------------------------------------------------
// Side Navigation styles

export const SideNavWrapper = styled(NvFlex)`
  width: 400px;
  flex: 0 0 auto;
  padding-right: 24px;
  margin-right: 16px;
  overflow: auto;
  padding-left: 32px;
`;

export const SideNavWrapperHeaderStyle = styled(NvFlex)`
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 100;
`;

export const SideNavWrapperMenu = styled(NvFlex)``;

// ------------------------------------------------------------------------------------------------
// Left content styling

export const MainWrapper = styled(NvFlex)`
  flex: 1 1 auto;
  min-width: 0;
  height: 100%;
  transition: height 0.3s ease-in-out;
`;

export const MainWrapperHeader = styled(NvFlex)`
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(3)};
  height: 64px;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
`;

export const MainWrapperBody = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  height: 100%;
  min-height: 0;
  flex: 1 1 auto;
  border-radius: 24px 0 0 0;
  ${({ theme }) => theme.elevations.e300};
`;
