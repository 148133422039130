import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const Wrapper = styled(NvFlex)`
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 12px;
  overflow: hidden;
`;

export const HeaderWrapper = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
`;

export const BodyWrapper = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
`;

export const InfoWrapper = styled(NvFlex)`
  padding: 12px;
`;

export const FieldsWrapper = styled(NvFlex)`
  padding: 12px;
`;
