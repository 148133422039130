import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NavigationWrapper = styled(NvFlex)`
  padding: 16px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  width: 100%;
  height: 100%;
`;
