import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkflowPermissionInformationBox = styled(NvFlex)`
  position: absolute;
  bottom: 16px;
  left: calc(50% - 525px / 2 + 6.5px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;

  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  border-radius: 21px;
`;
