import { SchemaType } from '@novaera/ah-common';
import { NvBox, SectionMessage } from '@novaera/core';
import { FC, memo, useMemo } from 'react';

import { isCatalogEntityParameters } from '@novaera/actioner-service';
import { PropertyPanelSimpleSection } from '../../../property-panel';
import { FieldPropertiesMap } from './constant';
import { DataModelFieldProps } from './types';
import { isTableFieldProps } from './utils';

const DataModelFieldOriginal: FC<DataModelFieldProps> = (props) => {
  const { field, fieldName, selectedFieldIndex, modalType } = props;

  const {
    schema: { constraints, type: schemaType },
    schema,
  } = field;
  const { Base, DefaultValue, ValidationValues, VectorDBConfiguration, LabelProperty, DisplayValueProperty } =
    FieldPropertiesMap({
      schema,
      modalType,
    });

  const ItemValidationValues = useMemo(() => {
    return (
      'valueSchema' in schema &&
      schema.valueSchema &&
      FieldPropertiesMap({ schema: schema, modalType }).ValidationValues
    );
  }, [modalType, schema]);

  const isIdField = useMemo(() => selectedFieldIndex === 0 && modalType === 'table', [modalType, selectedFieldIndex]);
  const isManagedField = useMemo(() => isCatalogEntityParameters(field) && Boolean(field.managed), [field]);

  return (
    <>
      {isIdField && (
        <PropertyPanelSimpleSection>
          <NvBox maxWidth="700px">
            <SectionMessage
              message="id is automatically generated to identify this model. It cannot be edited or deleted."
              variant="info"
            />
          </NvBox>
        </PropertyPanelSimpleSection>
      )}
      <Base fieldName={fieldName} fieldType={schemaType} disabled={isIdField || isManagedField} />

      {LabelProperty && <LabelProperty fieldName={`${fieldName}.label`} disabled={isManagedField} />}

      {DefaultValue && !isIdField && (
        <DefaultValue key={fieldName} fieldName={fieldName} fieldType={schemaType} disabled={isManagedField} />
      )}
      {ValidationValues && !isIdField && (
        <ValidationValues
          fieldSchemaName={`${fieldName}.schema`}
          constraints={constraints}
          schemaType={schemaType}
          disabled={isManagedField}
          {...(schemaType === SchemaType.array ? { title: 'List validations' } : {})}
          {...(schemaType === SchemaType.map ? { title: 'Map validations' } : {})}
          {...(schemaType === SchemaType.object ? { title: 'Object validations' } : {})}
        />
      )}
      {'valueSchema' in schema && schema.valueSchema && ItemValidationValues && (
        <ItemValidationValues
          title="Item validations"
          schemaType={schema.valueSchema.type}
          fieldSchemaName={`${fieldName}.schema.valueSchema`}
          constraints={schema.valueSchema.constraints}
          disabled={isManagedField}
        />
      )}

      {DisplayValueProperty && (
        <DisplayValueProperty
          fieldId={field.id}
          fieldName={'selectedEntityType.displayParameterId'}
          disabled={isManagedField}
        />
      )}

      {!isIdField && VectorDBConfiguration && isTableFieldProps(props) && (
        <VectorDBConfiguration
          replicateToVectorDB={props.replicateToVectorDB}
          fieldName={`${fieldName}.vectorDBConfiguration`}
        />
      )}
    </>
  );
};

export const DataModelField = memo(DataModelFieldOriginal);
