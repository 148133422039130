import { NvAxios, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';

const createWorkspaceDeletionRequest = async (params: { reason: string }) => {
  const result = await NvAxios.post(`v1/workspaces/start-deletion`, params);
  return result?.data;
};

export const useCreateWorkspaceDeletionRequest = () => {
  const { addToast } = useToast();
  const client = useQueryClient();

  return useMutation(createWorkspaceDeletionRequest, {
    onSuccess: () => {
      client.invalidateQueries();
      addToast('Workspace delete request initiated', { variant: 'success' });
    },
  });
};
