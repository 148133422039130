import { InputParameter } from '@novaera/actioner-service';
import { NvCustomExecuteButtonIcon } from '@novaera/core';
import { useParams } from '@novaera/route';
import { ActionParameterMapper } from '../../../../../../../../../components';
import { RowItemCard } from '../../../common/row-item-card';
import { SimpleLabelLayout } from '../../../common/row-item-card/row-item-layouts';
import { useAppIdParameterMapping } from '../common/use-app-id-parameter-mapping';
import { JobTargetParameterMappingProps } from './types';

export const JobTargetParameterMapping = ({
  actionNode,
  onParameterMappingChanged,
  selectedAction,
  onJobTargetParameterSelected,
  context,
}: JobTargetParameterMappingProps) => {
  const { userAppId, workflowId } = useParams();

  const { appIdParameterMappings: replaceParameterMappingsForOptions } = useAppIdParameterMapping({
    userAppId,
    action: selectedAction,
  });
  const showTarget = selectedAction?.name !== 'Delete job';
  return (
    <>
      <ActionParameterMapper
        type="in-workflow"
        appId={userAppId}
        workflowId={workflowId}
        actionId={actionNode.actionId}
        integrationId={actionNode.integrationId}
        versionNumber={actionNode.version}
        onParameterMappingsChanged={onParameterMappingChanged}
        context={context}
        filterInputParameters={(input: InputParameter) => {
          return input.name !== 'appId' && input.name !== 'workflowId' && input.name !== 'target';
        }}
        initialParameterMappings={actionNode.parameterMappings}
        replaceParameterMappingsForOptions={replaceParameterMappingsForOptions}
      />
      {showTarget && (
        <RowItemCard
          key={`job-target-parameter-mapper-row-item-${actionNode.actionId}`}
          rowItemLeftContentProps={{ draggable: false, icon: <NvCustomExecuteButtonIcon /> }}
          rowItemContent={<SimpleLabelLayout simpleLabel="Job target" />}
          onClick={() => {
            onJobTargetParameterSelected({
              selectedAction,
            });
          }}
        />
      )}
    </>
  );
};
