import { assert } from '@novaera/utils';
import { useState } from 'react';
import { useCatalogUtil } from '../../../controllers/use-catalog-util';
import { useSelectedCatalogEntity } from '../../../controllers/use-selected-catalog-entity';
import { CatalogSwitchHeaderMode } from '../../types';

export const useCatalogPageMode = () => {
  const { selectedEntityType } = useSelectedCatalogEntity();

  const { navigateToCatalogEntityRecords, navigateToCatalogEntitySchema, isPageInCatalogRecords } = useCatalogUtil();

  const [mode, setMode] = useState<CatalogSwitchHeaderMode>(() => {
    if (isPageInCatalogRecords()) {
      return 'records';
    }
    return 'schema';
  });

  const switchMode = (mode: CatalogSwitchHeaderMode) => {
    assert(!!selectedEntityType, new Error(`selectedEntityType should be defined`), 'ERROR');

    setMode(mode);

    if (mode === 'schema') {
      navigateToCatalogEntitySchema(selectedEntityType.id);
    } else {
      navigateToCatalogEntityRecords(selectedEntityType.id);
    }
  };

  return {
    mode,
    switchMode: switchMode,
  };
};
