import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';

export const Wrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  align-self: stretch;
  flex: 0 0 auto;
  min-height: 100px;
`;

export const TopLine = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'customBackground' })<{
  customBackground?: string;
}>`
  position: relative;
  width: 2px;
  height: 18px;
  border-radius: 1px;
  flex: 0 0 auto;
  margin-bottom: 6px;
  background: ${({ customBackground }) => customBackground};
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 1px, rgba(255, 255, 255, 0.3) 100%);
    z-index: 2;
  }
`;

export const Icon = styled(NvBox)`
  position: relative;
  height: 24px;
  width: 24px;
  display: inline-block;
`;

export const BottomLine = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'customBackground' })<{
  customBackground?: string;
}>`
  position: relative;
  width: 2px;
  background: ${({ customBackground }) => customBackground};
  border-radius: 1px;
  flex: 1 1 auto;
  height: 100%;
  margin-top: 6px;
  margin-bottom: 16px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 1) 100%);
    z-index: 2;
  }
`;
