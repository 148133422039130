import { useGetWorkflowExecutionOutputResult } from '@novaera/actioner-service';
import { KeyValueTableRow, NvKeyValueTable, NvSkeleton, SectionMessage } from '@novaera/core';
import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from '../../../../../../../../../../error-boundary';
import { KeyValueTableOutputProps } from './types';

export const KeyValueTableOutput: FC<PropsWithChildren<KeyValueTableOutputProps>> = ({
  alias,
  executionIdentifier,
  userAppId,
  workflowId,
}) => {
  const { data, isLoading } = useGetWorkflowExecutionOutputResult({
    userAppId,
    workflowId,
    executionIdentifier,
    outputAlias: alias,
  });

  const rows =
    data?.type === 'key-value-table'
      ? data.rows.map((row, index) => {
          const newRow: KeyValueTableRow = {
            key: row.key,
            value: row.value,
            muiDataGridRowId: `${index}`,
          };
          return newRow;
        })
      : [];

  return isLoading ? (
    <NvSkeleton variant="rectangular" width="100%" height="305px" />
  ) : data?.type === 'failed-output' ? (
    <SectionMessage variant="error" message={data.error} />
  ) : data?.type === 'key-value-table' ? (
    <ErrorBoundary>
      <NvKeyValueTable rows={rows} />
    </ErrorBoundary>
  ) : (
    <SectionMessage variant="error" message={'Cannot draw key value table'} />
  );
};
