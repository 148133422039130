import { CatalogEntityParameters, CatalogEntityType } from '@novaera/actioner-service';
import { DateTimeFormat, SchemaType } from '@novaera/ah-common';

export const getPredefinedColumns = (entityType: CatalogEntityType) => {
  // custom properties is not a field which can be defined as a parameter in the entity type
  // it is a field which can be added in catalog action in workflow
  const customPropertiesField: CatalogEntityParameters = {
    id: `${entityType.id}.customProperties`,
    label: 'Custom Properties',
    name: 'customProperties',
    managed: false,
    schema: {
      type: SchemaType.map,
      constraints: [],
      mandatory: false,
      readOnly: false,
    },
  };

  const idField: CatalogEntityParameters = {
    id: `${entityType.id}.id`,
    label: 'Id',
    name: 'id',
    managed: true,
    schema: {
      type: SchemaType.string,
      constraints: [],
      mandatory: true,
      readOnly: true,
    },
  };

  const createdAtField: CatalogEntityParameters = {
    id: `${entityType.id}.createdAt`,
    label: 'Created At',
    name: 'createdAt',
    managed: true,
    schema: {
      type: SchemaType.dateTime,
      constraints: [],
      mandatory: true,
      readOnly: true,
      format: DateTimeFormat.utc,
    },
  };

  const updatedAtField: CatalogEntityParameters = {
    id: `${entityType.id}.updatedAt`,
    label: 'Updated At',
    name: 'updatedAt',
    managed: true,
    schema: {
      type: SchemaType.dateTime,
      constraints: [],
      mandatory: true,
      readOnly: true,
      format: DateTimeFormat.utc,
    },
  };

  const tagsField: CatalogEntityParameters = {
    id: `${entityType.id}.tags`,
    label: 'Tags',
    name: 'tags',
    managed: true,
    schema: {
      type: SchemaType.array,
      constraints: [],
      mandatory: true,
      readOnly: true,
      valueSchema: {
        type: SchemaType.string,
        constraints: [],
        mandatory: true,
        readOnly: true,
      },
    },
  };

  return {
    idField,
    createdAtField,
    updatedAtField,
    tagsField,
    customPropertiesField,
  };
};
