import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SlackIntegrationItem = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 12px;
  ${({ theme }) => theme.elevations.e100}
`;

export const SlackWorkspaceSwitchCard = styled(NvBox)`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;
