import { CodeInput } from '@novaera/core';
import { FC } from 'react';
import { useIntegrationCodeFieldContext } from '../../../../integration-code-field/controllers/use-integration-code-field-context';
import { FunctionCodeInputProps } from './types';

export const FunctionCodeInput: FC<React.PropsWithChildren<FunctionCodeInputProps>> = (props) => {
  const { codeFieldContext } = useIntegrationCodeFieldContext();
  return (
    <CodeInput context={codeFieldContext} initialHeight={'360px'} growingHeight mode="free-js" lineNumbers {...props} />
  );
};
