import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANTS } from '../keys';
import { Assistant, AssistantModel } from '../types';

const updateAssistantModel = async ({ appId, model }: { appId: string; model: AssistantModel }) => {
  const result = await NvAxios.patch<Assistant>(`${ASSISTANTS_ROOT_PATH(appId)}/model`, { model });
  return result?.data;
};

export const useUpdateAssistantModel = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAssistantModel, {
    onSuccess: (response, { appId }) => {
      queryClient.setQueryData(QUERY_KEYS_ASSISTANTS.detail({ appId }), response);
    },
  });
};
