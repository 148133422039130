import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANTS } from '../keys';
import { Assistant } from '../types';

const updateAssistantApiKey = async ({ appId, apiKey }: { appId: string; apiKey: string }) => {
  const result = await NvAxios.patch<Assistant>(`${ASSISTANTS_ROOT_PATH(appId)}/api-key`, { apiKey });
  return result?.data;
};

export const useUpdateAssistantApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAssistantApiKey, {
    onSuccess: (response, { appId }) => {
      queryClient.setQueryData(QUERY_KEYS_ASSISTANTS.detail({ appId }), response);
    },
  });
};
