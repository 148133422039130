import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvIncidentManagementIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_1713_921"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <rect width="26" height="26" fill="#FFC9AC" />
      </mask>
      <g mask="url(#mask0_1713_921)">
        <path
          d="M5 15.0605C5 13.2164 5.58397 11.4159 6.75191 9.65917C7.91986 7.90244 9.53293 6.3961 11.5911 5.14016C11.9342 4.94939 12.2813 4.95336 12.6325 5.15209C12.9837 5.35081 13.1593 5.6489 13.1593 6.04635V7.71564C13.1593 8.20848 13.3349 8.62183 13.6861 8.95568C14.0373 9.28954 14.4661 9.45647 14.9724 9.45647C15.2501 9.45647 15.5074 9.40083 15.7443 9.28954C15.9811 9.17826 16.1894 9.01133 16.3691 8.78876C16.4997 8.62978 16.6631 8.51849 16.8591 8.4549C17.0551 8.3913 17.2348 8.4072 17.3982 8.50259C18.5089 9.2498 19.3869 10.1997 20.0322 11.3523C20.6774 12.5049 21 13.741 21 15.0605C21 16.6026 20.5957 17.9897 19.7871 19.2218C18.9786 20.4539 17.9127 21.38 16.5896 22C16.9816 21.5867 17.2838 21.1137 17.4962 20.5811C17.7085 20.0485 17.8147 19.4961 17.8147 18.9237C17.8147 18.3037 17.6963 17.7155 17.4594 17.1591C17.2226 16.6026 16.8754 16.1018 16.4181 15.6567L12.9877 12.4135L9.60643 15.6567C9.11639 16.1018 8.75702 16.6026 8.52833 17.1591C8.29964 17.7155 8.1853 18.3037 8.1853 18.9237C8.1853 19.4961 8.28739 20.0485 8.49158 20.5811C8.69576 21.1137 9.00204 21.5867 9.41041 22C8.07096 21.38 7.00102 20.4539 6.20061 19.2218C5.4002 17.9897 5 16.6026 5 15.0605ZM12.9877 14.8459L15.144 16.8967C15.4216 17.167 15.6381 17.473 15.7933 17.8149C15.9484 18.1567 16.026 18.5263 16.026 18.9237C16.026 19.7186 15.7279 20.3983 15.1317 20.9627C14.5355 21.527 13.8208 21.8092 12.9877 21.8092C12.1383 21.8092 11.4237 21.527 10.8438 20.9627C10.2639 20.3983 9.97397 19.7186 9.97397 18.9237C9.97397 18.5263 10.0475 18.1567 10.1945 17.8149C10.3415 17.473 10.562 17.167 10.856 16.8967L12.9877 14.8459Z"
          fill="#FFC9AC"
        />
      </g>
    </SvgIcon>
  );
}
