import { NvTimePicker } from '@novaera/core';
import { getHours, getMinutes, set } from 'date-fns';
import { isUndefined } from 'lodash';
import React from 'react';
import { CronTimePickerProps } from './types';

export const CronTimePicker: React.FC<CronTimePickerProps> = ({ cron, onChange }) => {
  const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = cron.split(' ');
  return (
    <NvTimePicker
      value={set(new Date(), {
        hours: Number(hours),
        minutes: Number(minutes),
      })}
      onChange={(d) => {
        const date = d as unknown as Date;
        const newCronExpression = `${!isUndefined(getMinutes(date)) ? getMinutes(date) : '?'} ${
          !isUndefined(getHours(date)) ? getHours(date) : '?'
        } ${dayOfMonth} ${month} ${dayOfWeek} ${year}`;

        onChange(newCronExpression);
      }}
    />
  );
};
