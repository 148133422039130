import { NovaeraEdge } from '@novaera/core';
import { assert } from '@novaera/utils';
import React, { ReactElement, useCallback, useRef } from 'react';
import { useReactFlow } from 'reactflow';
import { userAppGraph } from '../../../graph-utils/user-app-graph';
import { useWorkflowDragAndDropProvider } from '../../../providers/workflow-drag-and-drop-provider';
import { createGhostImage } from '../ghost-image/utils';
import { MoveNodeDataTransfer } from '../utils';

export const DraggableItem: React.FC<
  React.PropsWithChildren<{
    nodeId: string;
  }>
> = ({ nodeId, children }) => {
  const { onDragStart, resetDroppedItem, draggable } = useWorkflowDragAndDropProvider();
  const { getNode } = useReactFlow();
  const nodeRef = useRef<HTMLDivElement>(null);

  const getBannedIds = useCallback(() => {
    const node = getNode(nodeId);
    const edges = userAppGraph.edges();

    assert(node !== undefined, new Error('node should be sent'), 'ERROR');
    const children = userAppGraph.getChildren(nodeId) ?? [];

    //since we want to deny drop for the incoming edge for root node
    children.push(nodeId);

    const result = [];
    for (const child of children) {
      const foundEdges = edges.filter((edge) => edge.targetId === child || edge.sourceId === child);
      result.push(...foundEdges);
    }
    return result;
  }, [getNode, nodeId]);

  const handleDragStart = useCallback(
    (e: DragEvent) => {
      assert(e.dataTransfer !== null, new Error('e.dataTransfer should be sent'), 'ERROR');

      const node = getNode(nodeId);
      assert(node !== undefined, new Error('node should be sent'), 'ERROR');

      const edges: NovaeraEdge<string>[] = getBannedIds();

      onDragStart?.(nodeId, edges);
      MoveNodeDataTransfer.setData(e, JSON.stringify(node));
      createGhostImage(e, nodeRef);
    },
    [getBannedIds, getNode, nodeId, onDragStart]
  );

  const handleDragEnd = useCallback(() => {
    resetDroppedItem?.();
    document.getElementById('ghost')?.remove();
  }, [resetDroppedItem]);

  assert(React.isValidElement(children), new Error('DraggableItem should have children'), 'ERROR');

  return React.cloneElement<{
    draggable: boolean;
    onDragStart: (e: DragEvent) => void;
    onDragEnd: () => void;
    ref: React.RefObject<HTMLDivElement>;
  }>(children as ReactElement, {
    draggable,
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    ref: nodeRef,
  });
};
