import { styled } from '@mui/material/styles';
import { NvBox } from '../box';

export const PaginationNumberWrapper = styled(NvBox)<{ selected?: boolean }>`
  cursor: pointer;
  min-width: 24px;
  height: 24px;
  padding: 4px 3px;
  text-align: center;
  border-radius: 6px;
  background: ${({ theme, selected }) =>
    selected ? theme.palette.nv_neutral_alpha[600] : theme.palette.nv_neutral_alpha[20]};
  color: ${({ theme, selected }) => (selected ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[1000])};
  transition: background-color 200ms ease;

  &:hover {
    background: ${({ theme, selected }) =>
      selected ? theme.palette.nv_neutral_alpha[700] : theme.palette.nv_neutral_alpha[30]};
  }
`;
