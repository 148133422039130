import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { DeleteDraftConfigParams } from './types';

const deleteDraftConfig = async ({ appId, configId }: DeleteDraftConfigParams) => {
  const result = await NvAxios.delete(`${CONFIG_ROOT_PATH(appId)}/${configId}/drafts`);
  return result?.data;
};

export const useDeleteDraftConfig = () => {
  const cache = useQueryClient();

  return useMutation(deleteDraftConfig, {
    onSuccess: (_, { appId, configId }) => {
      cache.invalidateQueries(QUERY_KEYS_CONFIGS.detail(appId, configId));
    },
  });
};
