import { APP_PERMISSION } from '@novaera/actioner-service';
import {
  NvButton,
  NvCustomDependencyIcon,
  NvDialogModal,
  NvFlex,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../user-app-permission-boundary/free-app-permission-boundary';
import { useGetWorkflowQueryParams } from '../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { AddDependency } from './add-dependency';
import { useAddAppDependency } from './controllers/use-add-app-dependency';
import { useAppDependencies } from './controllers/use-app-dependencies';
import { DependencyItems } from './dependency-items';
import { LoadingSkeleton } from './loading-skeleton';
import { DependencyForm } from './types';

export const AppDependencies = () => {
  const { userAppId } = useGetWorkflowQueryParams();

  const { isLoadingAppDependencies, hasNoDependency } = useAppDependencies();

  const { addAppDependency, isAddDependencyLoading } = useAddAppDependency();

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  return (
    <>
      <NvFlex gap={'16px'}>
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvCustomDependencyIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
          <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
            Dependencies
          </NvTypography>
        </NvFlex>
        {isLoadingAppDependencies && <LoadingSkeleton />}

        {hasNoDependency && (
          <EmptyState
            variant={EmptyStates.APP_DEPENDENCY}
            CustomButton={
              <FreeAppPermissionBoundary appId={userAppId}>
                <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                  <NvButton color="primary" onClick={onModalOpenClicked} size="small" sx={{ flex: '0 0 auto' }}>
                    Add dependency
                  </NvButton>
                </UserAppPermissionBoundary>
              </FreeAppPermissionBoundary>
            }
          />
        )}

        {!isLoadingAppDependencies && !hasNoDependency && (
          <DependencyItems onAddDependencyClicked={onModalOpenClicked} />
        )}
      </NvFlex>
      <NvDialogModal<DependencyForm>
        fullWidth
        maxWidth="sm"
        onCloseButtonClicked={onModalCloseClicked}
        open={isOpened}
        Header={'Add dependency'}
        primaryButtonText="Add"
        Body={<AddDependency />}
        onFormSubmit={(values) => {
          addAppDependency({
            appId: userAppId,
            payload: values,
          });
          onModalCloseClicked();
        }}
        isLoading={isAddDependencyLoading}
      />
    </>
  );
};
