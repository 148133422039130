import { captureMessage, SeverityLevel } from '@sentry/react';
import { format, isValid, parseISO } from 'date-fns';
import addYears from 'date-fns/addYears';
import Cookies from 'js-cookie';
import { includes, isArray, isEqualWith, isObject, omitBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export * from './is-value-filled';
export * from './number-formatter';
export * from './use-container-dimensions';
export * from './use-copy-to-clipboard';
export * from './use-is-overflow';
export * from './use-path-condition';
export * from './use-path-condition/types';
export * from './use-previous';
export * from './use-previous-persistent-with-matcher';
export * from './use-unmount';

const LAST_ACTIVE_WORKSPACE = 'LAST_ACTIVE_WORKSPACE';
//#region unique id generation
export const generateUniqueId = () => uuidv4();
//#endregion

//#region Types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Validator<V = any, T = any, K = any> = (
  value: V,
  formState?: T,
  fieldState?: K
) => string | undefined | Promise<string | undefined>;
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
//#endregion

//#region Utils
export const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeLocalStoreItem = (key: string) => localStorage.removeItem(key);

export const composeValidators =
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validators?: Validator<any>[]
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any, formState: any, fieldState: any): string | undefined | Promise<any> => {
    if (!validators) {
      return;
    }

    return validators.reduce((error: string | undefined | Promise<string | undefined>, validator: Validator) => {
      return error ?? validator(value, formState, fieldState);
    }, undefined);
  };

export type TAssert = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  (condition: boolean, err: Error, severity?: TSeverity, params?: {}): asserts condition;
};

export enum Severity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type TSeverity = keyof typeof Severity;

/**
 * Throws in test and development, send a notice on production
 * @param condition Condition to assert - can use type assertions thanks to ts's "asserts condition"
 * @param err A js error object
 * @param severity
 */
export const assert: TAssert = function (condition: boolean, err: Error, severity = 'ERROR') {
  if (!condition) {
    const sev = Severity[severity];
    if (process.env.NODE_ENV !== 'production') {
      console.log('Assertion Error: ' + err.message, severity);
      if (sev === Severity.ERROR) {
        throw err;
      }
    } else {
      if (sev === Severity.ERROR) {
        captureMessage(err.message, sev as unknown as SeverityLevel);
      }
    }
  }
};

export const noop = () => undefined;

export const capitalizeFirstLetter = (word: string) => {
  const [firstLetter, ...restOfWord] = word;
  return `${firstLetter.toUpperCase()}${Array.isArray(restOfWord) ? restOfWord.join('') : restOfWord}`;
};

const comparisonFunc = (a: unknown, b: unknown) => {
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!includes(a as any, undefined) && !includes(b as any, undefined)) return;

  // Call recursively, after filtering all undefined properties
  return isEqualWith(
    omitBy(a, (value) => value === undefined),
    omitBy(b, (value) => value === undefined),
    comparisonFunc
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nvIsEqual = (value: any, other: any) => isEqualWith(value, other, comparisonFunc);

export const shouldForwardPropForFormFields = (prop: PropertyKey) =>
  prop !== 'dataPanelIndex' &&
  prop !== 'minSize' &&
  prop !== 'collapsedSize' &&
  prop !== 'initialStyle' &&
  prop !== 'direction' &&
  prop !== 'isLastItem' &&
  prop !== 'isCollapsed' &&
  prop !== 'active' &&
  prop !== 'touched' &&
  prop !== 'visited' &&
  prop !== 'validating' &&
  prop !== 'valid' &&
  prop !== 'invalid' &&
  prop !== 'dirty' &&
  prop !== 'dirtySinceLastSubmit' &&
  prop !== 'submitSucceeded' &&
  prop !== 'submitFailed' &&
  prop !== 'pristine' &&
  prop !== 'submitError' &&
  prop !== 'modifiedSinceLastSubmit' &&
  prop !== 'modified' &&
  prop !== 'submitting' &&
  prop !== 'onlyIcon' &&
  prop !== 'isSelected' &&
  prop !== 'hasError' &&
  prop !== 'isSuccess' &&
  prop !== 'gradientColor' &&
  prop !== 'active' &&
  prop !== 'selectionFollowsFocus' &&
  prop !== 'textColor' &&
  prop !== 'indicator' &&
  prop !== 'index' &&
  prop !== 'hasDarkBackground' &&
  prop !== 'fieldLength' &&
  prop !== 'onFileSelected';

// It generates shortname from full name. Ismet Fatih Calis -> I
export const getInitials = (name: string | undefined) => {
  const initials = name?.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  const result = initials ? initials[0] : '?';
  return result.toUpperCase();
};

export const isDevEnv = () => process.env.NODE_ENV === 'development';
export const isProfileEnv = () => process.env.NODE_ENV === 'profile';

export const getSubdomain = () => {
  const parsedHost = window.location.host.split('.');
  if (parsedHost[0] === 'www' || parsedHost[0] === 'app') {
    // remove www from array
    parsedHost.shift();
  }

  const hostToBeChecked = parsedHost.slice(1);
  if (
    hostToBeChecked.join('.') === process.env.NX_FRONTEND_HOST ||
    hostToBeChecked.join('.') === process.env.NX_FRONTEND_OLD_HOST
  ) {
    const subdomain = parsedHost[0];
    if (subdomain) {
      return subdomain;
    }
    return '';
  }
  return '';
};
export const getRootAppDomain = () => {
  return process.env.NX_FRONTEND_HOST;
};

export const getBaseRootAppDomain = () => {
  return process.env.NX_FRONTEND_OLD_HOST;
};

/**
 *
 * @param subdomain subdomain to redirect, ex: subdomain1
 * @param path path param to redirect ex: /workspace/info
 */
export const switchToWorkspace = (subdomain: string, path?: string) => {
  let baseHost = process.env.NX_FRONTEND_OLD_HOST;
  if (isDevEnv()) {
    baseHost = process.env.NX_FRONTEND_OLD_HOST?.includes(':')
      ? process.env.NX_FRONTEND_OLD_HOST?.split(':')[0]
      : process.env.NX_FRONTEND_OLD_HOST;
  }
  setCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, subdomain, `${baseHost}`);
  window.location.href = `${window.location.protocol}//${subdomain}.${getRootAppDomain()}${path ? path : ''}`;
};

export const switchToWorkspaceWithDelay = (delay: number, subdomain: string, path?: string) => {
  setTimeout(() => {
    switchToWorkspace(subdomain, path);
  }, delay);
};

export const setCookieForSpecificDomain = (key: string, value: string, domain: string) =>
  Cookies.set(key, value, {
    domain,
    secure: true,
    sameSite: 'None',
    expires: addYears(new Date(), 10),
  });

export const removeCookieForSpecificDomain = (key: string, domain: string) =>
  Cookies.remove(key, {
    domain,
    secure: true,
    sameSite: 'None',
  });

export const isVisibleOnScrollableView = function (element: Element, container: Element) {
  const { bottom, height, top } = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
};

export const getClientTimezoneId = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isJsonValid = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch (error) {
    return false;
  }
};

export const formatDateInSafe = (date: Date | string | undefined, customFormat?: string) => {
  if (!date) {
    return '';
  }

  const parsedDate = typeof date === 'string' ? parseISO(date) : date;

  if (!isValid(parsedDate)) {
    return null;
  }

  const defaultFormat = 'MMM d, yyyy HH:mm';
  const formattedDate = format(parsedDate, customFormat || defaultFormat);

  return formattedDate;
};

export const getStringifiedValue = (value: unknown): string | undefined => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value);
  } else {
    return !value ? undefined : String(value);
  }
};

//#endregion
