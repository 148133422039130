import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { INTEGRATION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION } from '../keys';
import {
  QueryType,
  SearchIntegrationsParam,
  SearchIntegrationsResponse,
  SortParameterByType,
  SortParameterOrderType,
} from '../types';

export const searchIntegrations: (
  params?: SearchIntegrationsParam,
  pageParam?: string
) => Promise<SearchIntegrationsResponse> = async (params, pageParam) => {
  const result = await NvAxios.post<SearchIntegrationsResponse>(
    `${INTEGRATION_ROOT_PATH}/search`,
    {
      ...params,
      ...(params?.sortParameters
        ? { sortParameters: params.sortParameters }
        : { sortParameters: [{ order: SortParameterOrderType.ASC, by: SortParameterByType.NAME }] }),
      ...(params?.type ? { type: params.type } : { type: QueryType.KEYWORD }),
      ...(pageParam ? { next: pageParam } : {}),
    },
    { params: pageParam ? { next: pageParam } : {} }
  );
  return result?.data;
};

export const useSearchInfiniteIntegrations = (
  params?: SearchIntegrationsParam,
  { enabled }: { enabled: boolean } = { enabled: true }
) => {
  return useInfiniteQuery<SearchIntegrationsResponse>(
    QUERY_KEYS_INTEGRATION.list(params),
    ({ pageParam = 0 }) => searchIntegrations(params, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.next,
      enabled: enabled,
    }
  );
};
