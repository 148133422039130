import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomEventAdd({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6V12H19V10H5V20H12V22H5C3.9 22 3 21.1 3 20V6C3 4.9 3.9 4 5 4H6V3C6 2.45 6.45 2 7 2C7.55 2 8 2.45 8 3V4H16V3C16 2.45 16.45 2 17 2C17.55 2 18 2.45 18 3V4H19C20.1 4 21 4.9 21 6ZM18 14C18.5523 14 19 14.4477 19 15V17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H19V21C19 21.5523 18.5523 22 18 22C17.4477 22 17 21.5523 17 21V19H15C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17H17V15C17 14.4477 17.4477 14 18 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
