export interface AppConfigBase {
  id: string;
  createdAt: string;
}

export interface AppConfigListItem extends AppConfigBase {
  name: string;
  isDraft: boolean;
  valid?: boolean;
}

export interface AppConfigDetail extends AppConfigBase {
  name: string;
  username: string;
  logoUrl: string;
  properties: string;
  version: string;
  schemaId?: string;
  generationSource: AppConfigGenerationSource;
}

export type AppConfigDetailWithState = AppConfigDetail & { state: ConfigState };

export type AppConfigVersionListItem = Omit<AppConfigDetail, 'name' | 'properties'>;

export type AppConfigVersionDetail = AppConfigDetail;

export enum ConfigState {
  SAVED = 'saved',
  DRAFT = 'draft',
}

export type ValidateConfigResult = {
  valid: boolean;
  errorsByLocation?: Record<string, string[]>;
};

export enum AppConfigGenerationSourceType {
  FROM_SCRATCH = 'from_scratch',
  FROM_AI = 'from_ai',
}

export type AppConfigGenerationSourceFromScratch = { type: AppConfigGenerationSourceType.FROM_SCRATCH };

export type AppConfigGenerationSourceFromAI = { type: AppConfigGenerationSourceType.FROM_AI; docId: string };

export type AppConfigGenerationSource = AppConfigGenerationSourceFromScratch | AppConfigGenerationSourceFromAI;
