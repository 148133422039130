import { NvAxios, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';

import { UserRole } from '../use-batch-get-users/types';
import { QUERY_KEYS } from './keys';

const inviteUser = async (params: { email: string; roleId: UserRole }) => {
  const result = await NvAxios.post<void>('/v1/workspaces/users', params);
  return result?.data;
};

export const useInviteUser = () => {
  const cache = useQueryClient();
  const { addToast } = useToast();

  return useMutation(inviteUser, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS.lists());
    },
    onError: () => {
      addToast('The user could not be invited.', {
        variant: 'error',
      });
    },
  });
};
