import { StaticWeeklyJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { CronDayOfWeekPicker } from '../../../../../../../../../../../jobs/components/cron-day-of-week-picker';
import { CronTimePicker } from '../../../../../../../../../../../jobs/components/cron-time-picker';
import { WEEKLY_CRON_EXPRESSION } from '../../../../../../../../../../../jobs/constants';
import { RecurrenceProps } from '../../types';

export const StaticWeeklyRecurrence: React.FC<
  Omit<RecurrenceProps<StaticWeeklyJobRecurrenceConfiguration>, 'context'>
> = ({ recurrenceConfiguration, onChange }) => {
  const cron = recurrenceConfiguration.cronExpression ?? WEEKLY_CRON_EXPRESSION;

  return (
    <NvFlex direction="column" gap="8px" width="100%">
      <CronDayOfWeekPicker
        cron={cron}
        onChange={(cron) => {
          onChange({
            ...recurrenceConfiguration,
            cronExpression: cron,
          });
        }}
      />
      <NvFlex direction="row" alignItems="flex-start" gap="8px">
        <NvTypography padding="6px 0">At</NvTypography>
        <CronTimePicker
          cron={cron}
          onChange={(cron) => {
            onChange({
              ...recurrenceConfiguration,
              cronExpression: cron,
            });
          }}
        />
      </NvFlex>
    </NvFlex>
  );
};
