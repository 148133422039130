import {
  isJobTargetParameterMappingParam,
  JobTargetEventPayloadParam,
  JobTargetParameterMapping,
  JobTargetParameterMappingParam,
  ParameterTypes,
} from '@novaera/actioner-service';
import { useCallback } from 'react';

export const useJobTargetAdapter = () => {
  const format = useCallback((value?: JobTargetParameterMapping) => {
    return value;
  }, []);

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (value: JobTargetParameterMappingParam | JobTargetEventPayloadParam): JobTargetParameterMapping | undefined => {
        if (value) {
          if (isJobTargetParameterMappingParam(value)) {
            return { type: ParameterTypes.JOB_TARGET, parameterMappings: value.parameterMappings, parameterId: id };
          } else {
            return { type: ParameterTypes.JOB_TARGET, eventPayload: value.eventPayload, parameterId: id };
          }
        }
        return undefined;
      },
    []
  );

  return { format, parse };
};
