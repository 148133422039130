import { useCallback, useMemo } from 'react';

import {
  ColoredChipColor,
  NeutralChipColor,
  NvChip,
  NvFlex,
  NvTypography,
  NvVerifiedIcon,
  SemanticChipColor,
} from '@novaera/core';

import { Tag } from '@novaera/actioner-service';
import { AppDirectoryCardTags, MoreTagsTooltip } from './styled';
import { TagsProps } from './types';

export const Tags: React.FC<React.PropsWithChildren<TagsProps>> = ({ title, tags }) => {
  const hasMoreThanTwoItem = useMemo(() => tags.length > 2, [tags]);
  const getTagColor = useCallback((color: Tag['color']) => {
    switch (color) {
      case 'green':
        return ColoredChipColor.BLUE;
      case 'pink':
        return SemanticChipColor.NEW;
      case 'red':
        return ColoredChipColor.TEAL;
      default:
        return NeutralChipColor.LIGHT_ALPHA;
    }
  }, []);
  const hasCertifiedIcon = useCallback((label: string) => label === 'Certified' || label === 'Featured', []);

  return hasMoreThanTwoItem ? (
    <MoreTagsTooltip
      title={
        <NvFlex spacing="12px" alignItems="flex-start">
          {tags.map(({ label, color }, index) => (
            <NvChip
              key={`app-directory-card-tag-${title}-${index}`}
              color={getTagColor(color)}
              label={label}
              compact
              {...(hasCertifiedIcon(label) ? { icon: <NvVerifiedIcon /> } : {})}
            />
          ))}
        </NvFlex>
      }
      placement="bottom-start"
    >
      <AppDirectoryCardTags sx={{ cursor: 'pointer' }}>
        <NvTypography variant="h5">+{tags.length - 2}</NvTypography>
        {tags.slice(0, 2).map(({ label, color }, index) => (
          <NvChip
            key={`app-directory-card-tag-${title}-${index}`}
            color={getTagColor(color)}
            label={label}
            compact
            {...(hasCertifiedIcon(label) ? { icon: <NvVerifiedIcon /> } : {})}
          />
        ))}
      </AppDirectoryCardTags>
    </MoreTagsTooltip>
  ) : (
    <AppDirectoryCardTags>
      {tags.map(({ label, color }, index) => (
        <NvChip
          key={`app-directory-card-tag-${title}-${index}`}
          color={getTagColor(color)}
          label={label}
          compact
          {...(hasCertifiedIcon(label) ? { icon: <NvVerifiedIcon /> } : {})}
        />
      ))}
    </AppDirectoryCardTags>
  );
};
