import { DataModel } from '@novaera/actioner-service';
import { NvTypography, useConfirmDialog, useField, useForm } from '@novaera/core';
import { noop } from '@novaera/utils';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';

export const useTableDetail = () => {
  const { registerField } = useForm();

  const fieldsArr = useFieldArray('fields');

  useEffect(() => {
    registerField('fields', noop, { value: true, error: true });
    registerField('description', noop, { value: true, error: true });
    registerField('name', noop, { value: true, error: true });
  }, [registerField]);

  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number>(0);

  const {
    input: { value: fieldsValue },
  } = useField<DataModel['fields']>('fields');

  const { openConfirm } = useConfirmDialog();

  const handleDeleteField = useCallback(
    (id: string) => {
      const name = fieldsValue.find((f) => f.id === id)?.name;
      openConfirm({
        title: `Delete ${name} field?`,
        message: (
          <NvTypography>
            Deleting a field may cause problems if any requests have been configured for this table.
            <br />
            <br />
            Do you want to delete <strong>{name}</strong>?
          </NvTypography>
        ),
        onConfirm: () => {
          fieldsArr.fields.remove(fieldsValue.findIndex((f) => f.id === id));
          setSelectedFieldIndex(0);
        },
      });
    },
    [fieldsArr.fields, fieldsValue, openConfirm]
  );

  const fieldMenus = useCallback(
    (id: string) => {
      const deleteFieldIndex = fieldsValue.findIndex((f) => f.id === id);
      return [
        {
          name: 'Delete',
          onClick: () => handleDeleteField(id),
          ...(deleteFieldIndex === 0 ? { disabled: true, tooltip: 'id cannot be deleted' } : {}),
        },
      ];
    },
    [fieldsValue, handleDeleteField]
  );

  return {
    setSelectedFieldIndex,
    selectedFieldIndex,
    fieldMenus,
  };
};
