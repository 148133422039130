import {
  AppSchemasPayload,
  AppSchemasQueryOperation,
  AppSchemasQuerySortOption,
  AppSchemasQueryType,
  FetchProfileResponse,
  Tag,
} from '@novaera/actioner-service';
import { NvCustomAppsIcon, NvNotificationActiveIcon, NvPublishIcon, NvVerifiedIcon } from '@novaera/core';
import { AppDirectoryMenuItem } from '../app-directory-list/app-directory-menu/types';

type AppDirectoryMenuItemParams = { activeUser?: FetchProfileResponse };

export const APP_DIRECTORY_MENU_ITEMS = (param?: AppDirectoryMenuItemParams): AppDirectoryMenuItem[] => {
  const appDirectoryMenuItems = [
    {
      label: 'All',
      value: 'clear-filters',
      icon: (
        <NvCustomAppsIcon
          sx={{
            color: 'inherit',
            width: '24px',
            height: '24px',
          }}
        />
      ),
      queries: [
        {
          operation: AppSchemasQueryOperation.CONTAINS,
          type: AppSchemasQueryType.KEYWORD,
          value: '',
        },
      ],
    },
    {
      label: 'Staff picks',
      value: 'featured',
      icon: (
        <NvVerifiedIcon
          sx={{
            color: 'inherit',
            width: '24px',
            height: '24px',
          }}
        />
      ),
      query: {
        operation: AppSchemasQueryOperation.CONTAINS,
        type: AppSchemasQueryType.TAG,
        value: 'featured',
        displayValue: 'Staff picks',
      },
    },
    {
      label: 'Recently added',
      value: 'new',
      icon: (
        <NvNotificationActiveIcon
          sx={{
            color: 'inherit',
            width: '24px',
            height: '24px',
          }}
        />
      ),
      query: {
        operation: AppSchemasQueryOperation.CONTAINS,
        type: AppSchemasQueryType.TAG,
        value: 'new',
        displayValue: 'Recently added',
      },
    },
  ];

  return param?.activeUser
    ? [
        ...appDirectoryMenuItems,
        {
          label: 'My published apps',
          value: param?.activeUser?.userId ?? '',
          icon: (
            <NvPublishIcon
              sx={{
                color: 'inherit',
                width: '24px',
                height: '24px',
              }}
            />
          ),
          query: {
            operation: AppSchemasQueryOperation.CONTAINS,
            type: AppSchemasQueryType.OWNER,
            value: param?.activeUser?.userId ?? '',
            displayValue: 'My published apps',
          },
        },
      ]
    : [...appDirectoryMenuItems];
};

export const ALL_PACKAGES_SEARCH_SCHEMA: AppSchemasPayload = {
  queries: [
    {
      operation: AppSchemasQueryOperation.CONTAINS,
      type: AppSchemasQueryType.KEYWORD,
      value: '',
    },
  ],
  pagination: null,
  sortParams: {
    sortOption: AppSchemasQuerySortOption.BY_NAME,
    sortOrder: 'asc',
  },
};

export const INITIAL_SEARCH_SCHEMA_BODY = ALL_PACKAGES_SEARCH_SCHEMA;

export const COMING_SOON_APP_TAG: Tag[] = [{ label: 'Coming Soon' }];
