import { styled } from '@novaera/theme-provider';

export const StyledWorkspaceItem = styled('div')<{ isPreview?: boolean; isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding: 0 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  border: 1px solid transparent;
  border-radius: 12px;
  opacity: ${({ isDisabled }) => isDisabled && '0.5'};
  transition: background-color 0.4s ease, border-color 0.5s ease, box-shadow 0.6s ease;
  overflow: hidden;

  h3,
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${({ theme, isDisabled }) => !isDisabled && theme.palette.nv_neutral[10]};
    cursor: ${({ isPreview }) => !isPreview && 'pointer'};
    border: 1px solid ${({ theme }) => theme.palette.nv_main[30]};

    svg {
      color: ${({ theme }) => theme.palette.nv_main[30]} !important;
      transform: translateX(4px);
      opacity: 1;
    }
  }
  svg {
    transition: color 0.5s ease, transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    transform: translateX(-8px);
    color: ${({ theme }) => theme.palette.nv_neutral[1000]} !important;
    opacity: 0.1;
  }

  &.has-no-click {
    cursor: default;
  }

  &.is-preview {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[80]};
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    &:hover {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[80]};
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
