import { DefaultNodeSummary, DelayNode, DelayVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class DelayComponentFactory implements ComponentFactory<DelayNode> {
  aliasForDelay: string;
  nameForDelay: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('delay');
    this.aliasForDelay = newAlias;
    this.nameForDelay = newName;
  }

  get componentForGraph() {
    const delayRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForDelay,
      width: 40,
      height: 40,
      id: this.aliasForDelay,
      type: 'delay',
      alias: this.aliasForDelay,
    };

    return { root: delayRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: DelayNode) {
    const newNodeUnion: DelayVertex = {
      type: 'delay',
      alias: this.aliasForDelay,
    };
    const summary: DefaultNodeSummary = { name: this.nameForDelay, alias: this.aliasForDelay, type: 'delay' };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForDelay]: summary } };
  }
}
