import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { PropertyPanelListHeader } from '../../../../../components';

export const WorkflowHistoryNodeExecutionCard = styled(NvBox)<{ isPassiveMode?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  ${({ isPassiveMode }) => (isPassiveMode ? 'opacity:0.6' : null)}
`;

export const WorkflowHistoryNodeExecutionCardHeader = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px 0px 12px;
  gap: 8px;
  min-height: 64px;
  cursor: pointer;
`;

export const WorkflowHistoryNodeExecutionResultCard = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 12px 16px 16px;
  padding: 8px;
  gap: 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[10]};
  border-radius: 6px;
`;

export const ResponseHeader = styled(PropertyPanelListHeader)`
  top: 31px;
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;
