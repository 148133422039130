import { isRequired, isValidJSON, NvField, NvFlex, NvTextField } from '@novaera/core';
import { INVALID_JSON_MESSAGE } from '../../config-detail-body/constants';
import { ConfigJsonInputWrapper } from '../../config-detail-body/styled';
import { JsonInput } from '../../styled';

export const SchemaBody = () => {
  return (
    <NvFlex gap={'16px'} flex="1 1 0" minHeight={0} width={'100%'}>
      <NvField
        direction="label-on-top"
        name="name"
        labelText="Schema name"
        validators={[isRequired()]}
        hasRequiredIndicator
        isRequired
        component={
          <NvTextField
            sx={{
              maxWidth: '300px',
            }}
          />
        }
        showErrorMessageOnlyWhenBlur
      />

      <NvFlex flex="1 1 0" minHeight={0}>
        <ConfigJsonInputWrapper>
          <NvField
            formControlStyle={{
              minHeight: '0',
              height: '100%',
              width: '100%',
            }}
            name="schema"
            showErrorMessageOnlyWhenBlur
            validators={[isValidJSON(INVALID_JSON_MESSAGE)]}
            component={<JsonInput lineNumbers context={{}} expectedType="object" mode="json" disableExpandMode />}
          />
        </ConfigJsonInputWrapper>
      </NvFlex>
    </NvFlex>
  );
};
