import { ParameterTypes, RecordAttributesParameterMapping, SimpleParameterMapping } from '@novaera/actioner-service';
import { useCallback } from 'react';

export const useRecordAttributesAdapter = () => {
  const format = useCallback((value?: RecordAttributesParameterMapping) => {
    return value?.fields.map((f) => ({ type: ParameterTypes.SIMPLE, parameterId: f.id, value: f.value }));
  }, []);

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (value: SimpleParameterMapping[]): RecordAttributesParameterMapping | undefined => {
        if (value) {
          return {
            type: ParameterTypes.RECORD_ATTRIBUTES,
            fields: value.map((v) => {
              if (v.value) {
                return { id: v.parameterId, value: v.value };
              } else {
                return { id: v.parameterId };
              }
            }),
            parameterId: id,
          };
        }
        return undefined;
      },
    []
  );

  return { format, parse };
};
