import { WorkflowHistoryStatus, WorkflowHistoryTimeWindow } from '@novaera/actioner-service';
import { ColoredChipColor, NeutralChipColor, SemanticChipColor } from '@novaera/core';

export const TIME_WINDOW_FILTER_ITEMS = [
  {
    label: 'Last hour',
    value: WorkflowHistoryTimeWindow.LAST_HOUR,
  },
  {
    label: 'Last day',
    value: WorkflowHistoryTimeWindow.LAST_DAY,
  },
  {
    label: 'Last week',
    value: WorkflowHistoryTimeWindow.LAST_WEEK,
  },
  {
    label: 'Last month',
    value: WorkflowHistoryTimeWindow.LAST_MONTH,
  },
];

export const WORKFLOW_HISTORY_FILTER_ITEMS = [
  {
    label: 'In progress',
    value: WorkflowHistoryStatus.IN_PROGRESS,
    color: ColoredChipColor.BLUE,
  },
  {
    label: 'Successful',
    value: WorkflowHistoryStatus.SUCCESSFUL,
    color: SemanticChipColor.SUCCESS,
  },
  {
    label: 'Discarded',
    value: WorkflowHistoryStatus.DISCARDED,
    color: NeutralChipColor.DARK_ALPHA,
  },

  {
    label: 'Failed',
    value: WorkflowHistoryStatus.FAILED,
    color: SemanticChipColor.ERROR,
  },
];
