import { AccessType } from '@novaera/actioner-service';
import { NvBox, NvFlex, NvTypography } from '@novaera/core';
import { UserAndGroupImage } from '../../../../../../../../components/user-and-group-image';
import { WorkflowPermissionActions, WorkflowPermissionItemContainer, WorkflowPermissionUser } from './styled';
import { WorkflowPermissionItemProps } from './types';

export const WorkflowPermissionItem: React.FC<WorkflowPermissionItemProps> = ({
  workflowPermission,
  isAppAdmin = false,
  actions,
}) => {
  return (
    <WorkflowPermissionItemContainer disabled={isAppAdmin}>
      <WorkflowPermissionUser>
        <NvBox flex="0 0 auto" height="32px" padding="4px 0">
          {workflowPermission.type === AccessType.USER ? (
            <UserAndGroupImage src={workflowPermission.userDetail?.logoUrl} type={AccessType.USER} size="small" />
          ) : workflowPermission.type === AccessType.GROUP ? (
            <UserAndGroupImage
              src={workflowPermission.groupDetail?.logoUrl}
              id={workflowPermission.groupDetail?.id}
              type={AccessType.GROUP}
              size="small"
            />
          ) : null}
        </NvBox>

        <NvFlex flex="1 1 auto" minWidth="0">
          {workflowPermission.type === AccessType.USER ? (
            <>
              <NvTypography variant="h4">{workflowPermission.userDetail?.userName ?? 'Deleted user'}</NvTypography>
              {workflowPermission.userDetail?.email && (
                <NvTypography variant="body2" textColor="secondary">
                  {workflowPermission.userDetail?.email}
                </NvTypography>
              )}
            </>
          ) : workflowPermission.type === AccessType.GROUP ? (
            <NvTypography variant="h4">{workflowPermission.groupDetail?.name ?? 'Deleted group'}</NvTypography>
          ) : null}
        </NvFlex>
      </WorkflowPermissionUser>
      <WorkflowPermissionActions>{actions}</WorkflowPermissionActions>
    </WorkflowPermissionItemContainer>
  );
};
