import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { NvTypography } from '../../typography';
import { StepLabelEndIntersect } from './icons/step-label-end-intersect';
import { StepLabelStartIntersect } from './icons/step-label-start-intersect';
import { StepLabelCenterBox, StepLabelContainer } from './styled';
import { StepLabelProps, StepLabelState, StepLabelVariant } from './types';

export const StepLabel: React.FC<StepLabelProps> = ({ index, stepLength, label, variant, state, onStepClick }) => {
  const theme = useTheme();
  return (
    <StepLabelContainer index={index} stepLength={stepLength} state={state} onClick={onStepClick}>
      {variant !== StepLabelVariant.FIRST && <StepLabelStartIntersect sx={{ flex: '0 0 auto', minWidth: 0 }} />}
      <StepLabelCenterBox className="step-label-center-box" variant={variant} state={state}>
        <NvTypography
          variant="h4"
          {...(state === StepLabelState.NOT_COMPLETED
            ? { textColor: 'subtle' }
            : { color: theme.palette.nv_neutral[0] })}
          noWrap
          sx={{ transition: 'color 300ms ease' }}
        >
          {label}
        </NvTypography>
      </StepLabelCenterBox>

      {variant !== StepLabelVariant.END && <StepLabelEndIntersect sx={{ flex: '0 0 auto', minWidth: 0 }} />}
    </StepLabelContainer>
  );
};
