import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { useEventRuleCacheUpdateHelper } from '../utils';
import { PersistIntegrationEventRuleParams } from './types';

const persistIntegrationEventRule = async (params: PersistIntegrationEventRuleParams) => {
  const { integrationId, id } = params;
  const result = await NvAxios.post(`${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}/persist`);
  return result?.data;
};

export const usePersistIntegrationEventRule = () => {
  const { updatePersistedEventRuleCache, replaceEventRuleFromListCache } = useEventRuleCacheUpdateHelper();
  return useMutation(persistIntegrationEventRule, {
    onSuccess: (eventRule, { integrationId, id }) => {
      updatePersistedEventRuleCache({ eventRule, integrationId, id });
      replaceEventRuleFromListCache({ eventRule, integrationId });
    },
  });
};
