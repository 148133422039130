import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RowItemWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  min-height: 44px;
  align-items: center;
  padding: 6px 8px 6px 12px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    border-color: ${({ theme }) => theme.palette.nv_neutral[30]};
    &.has-error {
      border: 1px solid ${({ theme }) => theme.palette.nv_error[30]};
    }
  }
  &.selected-item {
    border-color: ${({ theme }) => theme.palette.nv_main[40]};
    ${({ theme }) => theme.mixins.focus};
    background-color: ${({ theme }) => theme.palette.nv_main[10]};
    &:hover {
      border-color: ${({ theme }) => theme.palette.nv_main[40]};
      ${({ theme }) => theme.mixins.focus};
      background-color: ${({ theme }) => theme.palette.nv_main[10]};
    }
  }
  &.has-error {
    border: 1px solid ${({ theme }) => theme.palette.nv_error[30]};
    &.selected-item {
      ${({ theme }) => theme.mixins.focus};
      border-color: ${({ theme }) => theme.palette.nv_main[40]};
    }
  }

  &.is-disabled {
    pointer-events: none;
  }
`;
