import { ConnectionSchema, useUpdateIntegrationPartial } from '@novaera/actioner-service';
import { useConfirmDialog } from '@novaera/core';
import { useGetIntegrationQueryParams } from '../../../../../../controllers/use-get-integration-query-params';

export const useGlobalSchemaDetailController = ({ connectionSchema }: { connectionSchema?: ConnectionSchema }) => {
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const { integrationId } = useGetIntegrationQueryParams();

  const { mutate, isLoading } = useUpdateIntegrationPartial();

  const handleDeleteSchemaClicked = () => {
    openConfirm({
      title: 'Remove Connection Schema',
      message: 'Are you sure you want to remove this connection schema?',
      onConfirm: () => {
        if (connectionSchema) {
          mutate({
            id: integrationId,
            connectionSchemaId: null,
          });
        }
      },
      onClose() {
        closeConfirm();
      },
    });
  };
  return {
    onDeleteSchemaClicked: handleDeleteSchemaClicked,
    isLoading,
  };
};
