import { NvAxios, useMutation } from '@novaera/core';
import { CheckWorkspaceAvailabilityResponse } from './types';

const checkWorkspaceAvailability = async (props: CheckWorkspaceAvailabilityResponse) => {
  const result = await NvAxios.post<{ subdomain: string }>('v1/workspaces/check-availability', {
    name: props.name,
  });
  return result.data;
};

export const useCheckWorkspaceAvailability = () => {
  return useMutation((props: CheckWorkspaceAvailabilityResponse) => checkWorkspaceAvailability(props));
};
