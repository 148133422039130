import { NvBox, NvSkeleton } from '@novaera/core';

export const SideMenuListItemsLoading = () => (
  <>
    <NvBox padding="12px 4px 12px 8px" width="100%">
      <NvSkeleton height="16px" width="60%" variant="rectangular" />
    </NvBox>
    <NvBox padding="12px 4px 12px 8px" width="100%">
      <NvSkeleton height="16px" width="60%" variant="rectangular" />
    </NvBox>
    <NvBox padding="12px 4px 12px 8px" width="100%">
      <NvSkeleton height="16px" width="60%" variant="rectangular" />
    </NvBox>
    <NvBox padding="12px 4px 12px 8px" width="100%">
      <NvSkeleton height="16px" width="60%" variant="rectangular" />
    </NvBox>
    <NvBox padding="12px 4px 12px 8px" width="100%">
      <NvSkeleton height="16px" width="60%" variant="rectangular" />
    </NvBox>
  </>
);
