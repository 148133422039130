import { css } from '@emotion/css';
import { Theme } from '@mui/material';

export const PlaceholderContainer = (theme: Theme) => css`
  display: flex !important;
  flex-direction: column !important;
  color: ${theme.palette.nv_neutral[60]};
  font-style: italic !important;
  width: max-content !important;
  pointer-events: all !important;
  user-select: none !important;
  cursor: text !important;
  visibility: visible !important;
  &.hidden {
    visibility: hidden !important;
  }
`;
