import { useCreateConfig } from '@novaera/actioner-service';
import {
  isRequired,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useMemo } from 'react';
import { USER_APP_CONFIG_DETAIL } from '../../constants';

import { AddNewConfigModalFormValues, AddNewConfigModalProps } from './types';

export const AddNewConfigModal: React.FC<React.PropsWithChildren<AddNewConfigModalProps>> = ({ isOpen, onClose }) => {
  const { userAppId } = useParams();
  const navigate = useNavigate();
  const initialFormValues = useMemo(() => ({ name: '' }), []);
  const { mutate: createConfig } = useCreateConfig();
  const handleOnSubmit = ({ name }: AddNewConfigModalFormValues) => {
    return new Promise<void>((resolve) => {
      createConfig(
        { appId: userAppId, name },
        {
          onSuccess: ({ id }) => {
            onClose();
            navigate(USER_APP_CONFIG_DETAIL(userAppId, id));
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };
  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <NvForm<AddNewConfigModalFormValues> onSubmit={handleOnSubmit} initialValues={initialFormValues}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Add config</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton
                onlyIcon
                size="small"
                color="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex gap="8px">
                <NvTypography variant="body1">
                  You can generate a JSON body to specify the configuration policy <br /> for your app.
                </NvTypography>
                <NvField
                  name="name"
                  labelText="Name"
                  labelVariant="h5"
                  direction="label-on-top"
                  showErrorMessageOnlyWhenBlur
                  component={<NvTextField size="medium" disabled={submitting} />}
                  validators={[isRequired()]}
                  hasRequiredIndicator
                />
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" loading={submitting} disabled={submitting}>
                Create
              </NvButton>
              <NvButton onClick={onClose} color="ghost">
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
