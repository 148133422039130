import { isRequired, NvField, NvTextArea, NvTextField } from '@novaera/core';

export const UpdateEventNameAndDescriptionModalBody = () => (
  <>
    <NvField
      name="name"
      direction="label-on-top"
      labelVariant="h5"
      labelText="Name"
      showErrorMessageOnlyWhenBlur
      isRequired
      validators={[isRequired()]}
      component={<NvTextField />}
      hasRequiredIndicator
    />
    <NvField
      name="description"
      direction="label-on-top"
      labelVariant="h5"
      labelText="Description"
      component={<NvTextArea rows={4} placeholder="Enter description" />}
    />
  </>
);
