import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_GROUPS } from '../../groups/keys';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPoliciesResponse } from '../use-get-permission-policies/types';
import { DeletePermissionPolicyParams } from './types';

const deletePermissionPolicy = async ({ id }: DeletePermissionPolicyParams) => {
  await NvAxios.delete(`${PERMISSION_POLICIES_ROOT_PATH}/policies/${id}`);
  return;
};

export const useDeletePermissionPolicy = () => {
  const cache = useQueryClient();

  return useMutation(deletePermissionPolicy, {
    onSuccess: (_, { id }) => {
      const permissionPolicyCaches = cache.getQueriesData<InfiniteData<GetPermissionPoliciesResponse>>(
        QUERY_KEYS_PERMISSION_POLICIES.list()
      );

      permissionPolicyCaches.forEach((nodeDetailCache) => {
        const [queryKey, _] = nodeDetailCache;

        cache.setQueryData<InfiniteData<GetPermissionPoliciesResponse>>(queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old?.pages.map((page) => ({
                ...page,
                permissionPolicies: page.permissionPolicies.filter((p) => p.id !== id),
              })),
            };
          }
          return old;
        });
      });

      cache.invalidateQueries(QUERY_KEYS_GROUPS.all);
    },
  });
};
