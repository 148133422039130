import { NvBox, NvSwitchButton } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PriceWrapper = styled(NvBox)`
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
`;

export const PriceRecurrenceWrapper = styled(NvBox)`
  font-size: 9px;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.nv_neutral[300]};
`;

export const BillingStyledSwitch = styled(NvSwitchButton)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
  gap: 4px;
  padding: 4px;
  .MuiToggleButton-root {
    color: inherit;
    border-radius: 4px !important;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
      color: ${({ theme }) => theme.palette.nv_neutral[0]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
      &.Mui-selected {
        background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
      }
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }
  }
`;
