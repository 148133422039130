import { NvAxios, useQuery } from '@novaera/core';
import { QUERY_KEYS } from './keys';
import { WorkspaceListResponse } from './types';

const fetchWorkspaces = async () => {
  const result = await NvAxios.get<WorkspaceListResponse>('/v1/users/workspaces');
  return result.data;
};

export const useFetchWorkspaceList = (isEnabled = true) => {
  return useQuery(QUERY_KEYS.all, () => fetchWorkspaces(), {
    enabled: isEnabled,
  });
};
