import { HTTPRequestConfiguration, useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  isRequired,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { FC, useCallback, useMemo } from 'react';
import { CommandRequestAutocomplete } from '../../../test-action/command-request-list/command-request-autocomplete';
import { CommandRequestMenuItem } from '../../../test-action/command-request-list/styled';
import { RequestOptionProducerItemProps } from './types';

export const RequestOptionProducerItem: FC<React.PropsWithChildren<RequestOptionProducerItemProps>> = ({
  producerFieldName,
  onRemove,
}) => {
  const { actionId, integrationId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ actionId, integrationId, version: integration?.latestVersion.number });

  const options = useMemo(() => {
    const requests = data?.httpRequestConfigurations ?? [];
    const requestOptions = requests.map<Pick<HTTPRequestConfiguration, 'id' | 'name'>>((req) => ({
      id: req.id,
      name: req.name,
    }));
    return requestOptions;
  }, [data?.httpRequestConfigurations]);

  const format = useCallback((value: string) => (value ? options.find((o) => o.id === value) : null), [options]);
  const parse = useCallback((value: { id: string; name: string }) => value.id, []);

  const theme = useTheme();

  return (
    <NvFlex gap="8px" direction="row">
      <NvFlex gap="8px" width="100%">
        <NvField
          allowNull
          name={`${producerFieldName}.requestDefinitionId`}
          format={format}
          parse={parse}
          isAutoComplete
          isRequired
          validators={[isRequired('You need to select a request')]}
          showErrorMessageOnlyWhenBlur
          component={
            <CommandRequestAutocomplete
              disableClearable
              options={options}
              getOptionLabel={(option) => option.name ?? ''}
              isOptionEqualToValue={(option, value) => {
                return value && option.id === value.id;
              }}
              renderInput={(params) => {
                return (
                  <NvTextField
                    {...params}
                    placeholder={'Select request'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    size="small"
                    autoComplete="off"
                    startIcon={
                      <NvTypography variant="c2" noWrap color={theme.palette.nv_accent[60]}>
                        API
                      </NvTypography>
                    }
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <CommandRequestMenuItem {...props} key={option.id}>
                    <NvFlex direction="row" alignItems="center" gap="8px">
                      <NvTypography variant="c2" noWrap color={theme.palette.nv_accent[60]}>
                        API
                      </NvTypography>
                      <NvTypography variant="body1" noWrap>
                        {option.name}
                      </NvTypography>
                    </NvFlex>
                  </CommandRequestMenuItem>
                );
              }}
              noOptionsText={options.length === 0 ? "This action doesn't have any requests yet." : 'No Options'}
              popupIcon={<NvExpandMoreIcon />}
            />
          }
        />
        <NvField
          infoText="Field should have at most 75 length."
          labelVariant="h6"
          labelText="Display"
          direction="label-on-side"
          component={<CodeInput context={{}} />}
          name={`${producerFieldName}.optionTemplatePair.displayValueTemplate`}
        />
        <NvField
          infoText="Field should have at most 75 length."
          labelVariant="h6"
          labelText="Identifier"
          direction="label-on-side"
          component={<CodeInput context={{}} />}
          name={`${producerFieldName}.optionTemplatePair.identifierTemplate`}
        />
      </NvFlex>
      <NvBox height="32px" padding="4px 0">
        <NvButton onlyIcon color="ghost" size="small" onClick={onRemove}>
          <NvCloseIcon />
        </NvButton>
      </NvBox>
    </NvFlex>
  );
};
