import { InternalAxiosRequestConfig } from '@novaera/core';
import { RequestInterceptor } from '../../../request-interceptor';
import { PUBLIC_URLS } from '../../../use-refresh-access-token/constants';

export const requestFulfilled = async (request: InternalAxiosRequestConfig) => {
  if (PUBLIC_URLS.includes(request.url as string)) {
    return Promise.resolve(new RequestInterceptor(request).addSubDomainHeader().getRequest());
  }

  return Promise.resolve(
    new RequestInterceptor(request).shouldSendRequest().addAuthHeader().addSubDomainHeader().getRequest()
  );
};
