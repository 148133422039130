import { NvDivider } from '@novaera/core';
import { Header, HeaderProps } from './header';
import { SearchResult, SearchResultProps } from './search-result';
import { SearchBar, SearchBarProps } from './searchbar';
import { NavigationWrapper } from './styled';

export type InPageSideMenuProps<ListItemType> = HeaderProps & SearchBarProps & SearchResultProps<ListItemType>;

export const InPageSideMenu = <ListItemType,>({
  icon,
  title,
  onSearchKeywordChanged,
  onAddClicked,
  onItemSelected,
  items,
  isLoading,
  selectedItem,
  renderCustomLogo,
  hasPermissionToAdd,
}: InPageSideMenuProps<ListItemType>) => {
  return (
    <NavigationWrapper>
      <Header icon={icon} title={title} />
      <SearchBar
        onSearchKeywordChanged={onSearchKeywordChanged}
        onAddClicked={onAddClicked}
        hasPermissionToAdd={hasPermissionToAdd}
      />
      <NvDivider
        orientation="horizontal"
        sx={{
          margin: '8px 0px',
        }}
      />
      <SearchResult
        isLoading={isLoading}
        onItemSelected={onItemSelected}
        items={items}
        selectedItem={selectedItem}
        renderCustomLogo={renderCustomLogo}
      />
    </NavigationWrapper>
  );
};
