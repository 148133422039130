import { HTTPNode, WorkflowWithState, useUpdateNode } from '@novaera/actioner-service';
import { NvFlex, NvForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { PPDrawerItem } from '../../../../../../../../components';
import { usePropertyPanelContext } from '../../../provider';
import { usePPDrawerItem } from '../../controllers/use-pp-drawer-item';
import { HTTPPropertiesMainPanel } from '../http-properties-main-panel';
import { SubPanelType } from '../http-properties-main-panel/types';
import { HTTPPropertiesSubPanel } from '../http-properties-sub-panel';

interface HTTPPropertiesFormProps {
  httpNode: HTTPNode;
  handleClose: () => void;
  workflow: WorkflowWithState;
}

export const HTTPPropertiesForm: FC<HTTPPropertiesFormProps> = ({ handleClose, httpNode, workflow }) => {
  const { userAppId, workflowId } = useParams();
  const { isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  const [secondPanel, setSecondPanel] = useState<{ isOpen: true; subPanelType: SubPanelType } | { isOpen: false }>({
    isOpen: false,
  });
  const { mutate: updateNodeDetail } = useUpdateNode();
  const initialHTTPNode = useMemo<HTTPNode>(
    () => ({
      ...httpNode,
    }),
    [httpNode]
  );

  const handleOnChange = useCallback(
    (values: HTTPNode) => {
      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: httpNode.alias,
        payload: { ...values },
      });
    },
    [updateNodeDetail, userAppId, httpNode, workflowId]
  );

  const {
    panelProps: [first, second],
    panelCloseFunctions: [, onSecondClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: secondPanel.isOpen,
        closeFn: () => {
          setSecondPanel({ isOpen: false });
        },
      },
    ],
  });
  return (
    <NvForm<HTTPNode>
      onSubmit={noop}
      onChange={({ values }) => {
        handleOnChange(values);
      }}
      initialValues={initialHTTPNode}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      mutators={{
        ...arrayMutators,
      }}
    >
      <NvFlex direction="row" height={'100%'}>
        <PPDrawerItem {...first}>
          <HTTPPropertiesMainPanel
            workflow={workflow}
            onCloseClicked={handleClose}
            httpNode={httpNode}
            onCloseSecondPanel={() => setSecondPanel({ isOpen: false })}
            onSectionSelected={({ subPanelType }) => {
              setSecondPanel({ isOpen: true, subPanelType });
            }}
            {...(secondPanel.isOpen ? { selectedSecondPanelType: secondPanel.subPanelType } : {})}
          />
        </PPDrawerItem>
        <PPDrawerItem {...second}>
          {secondPanel.isOpen && (
            <HTTPPropertiesSubPanel
              onCloseClicked={onSecondClose}
              httpNode={httpNode}
              panelType={secondPanel.subPanelType}
            />
          )}
        </PPDrawerItem>
      </NvFlex>
    </NvForm>
  );
};
