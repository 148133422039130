import { NvAxios, useMutation } from '@novaera/core';

const verifyInvitation = async (params: { token: string }) => {
  const result = await NvAxios.get<{
    accessToken: string;
  }>('/v1/workspaces/users/verification', { params });

  return result.data;
};

export const useVerifyInvitation = () => {
  return useMutation(verifyInvitation);
};
