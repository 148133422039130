import { NvAxios } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemasParams, GetAppSchemasResponse } from './types';

const getAppSchemas = async ({ payload, next }: GetAppSchemasParams) => {
  assert(!!payload, new Error('[getAppSchemas] - app schema parameters should be defined.'), 'ERROR');
  const { pagination, ...restOfPayload } = payload;

  const result = await NvAxios.post<GetAppSchemasResponse>(`${APP_DIRECTORY_ROOT_PATH}/schemas/search`, {
    ...restOfPayload,
    ...(next ? { pagination: { ...pagination, next } } : { pagination }),
  });
  return result?.data;
};

export const useGetAppSchemas = ({ payload }: GetAppSchemasParams) => {
  return useInfiniteQuery<GetAppSchemasResponse>(
    QUERY_KEYS_APP_DIRECTORY.lists(payload),
    ({ pageParam }) => getAppSchemas({ payload, next: pageParam }),
    {
      enabled: !!payload,
      getNextPageParam: (lastPage) => lastPage.result.pagination?.next,
    }
  );
};
