import {
  AccessType,
  Group,
  GroupUser,
  PermissionPolicy,
  UseUpdateGroupParams,
  useAttachPermissionPolicyToGroup,
  useDeleteGroup,
  useDeletePermissionPolicyFromGroup,
  useGetGroup,
  useGetUsersInGroup,
  useUpdateGroup,
  useUpdateUsersInGroup,
} from '@novaera/actioner-service';
import {
  EditButton,
  InlineEditWrapper,
  NvAddBoxIcon,
  NvArrowBackIcon,
  NvBasicTable,
  NvBox,
  NvButton,
  NvChip,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvEditIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvPersonIcon,
  NvSearchEmptyState,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { useMemo, useState } from 'react';
import { ROUTES } from '../../../../common/routes';
import { DetailLayoutBody } from '../../../../components/detail-layout/body';
import { UserAndGroupImage } from '../../../../components/user-and-group-image';
import { GroupOrPermissionEmptyState } from '../../components/group-or-permission-empty-state';
import { GroupOrPermissionPolicyCard } from '../../components/group-or-permission-policy-card';
import { SearchAndButtonBar } from '../../components/search-and-add-button';
import { TagAndDescriptionEmptyState } from '../../components/tag-and-description-empty-state';
import { USER_MANAGEMENT_TAB_PATHS } from '../../constants';
import { GroupsCreateUpdateModalBody } from '../groups-create-update-modal-body';
import { AttachPermissionPolicyModalBody } from './attach-permission-policy-modal-body';
import { GroupUserItem } from './group-user-item';
import { SelectUsersModalBody } from './select-user-modal-body';

export const GroupDetail = () => {
  const theme = useTheme();
  const { groupId } = useParams();
  const [isOpened, setIsOpened] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isOpenedSelectUsersModal, setIsOpenedSelectUsersModal] = useState(false);
  const [isAttachPermissionPolicyModalOpen, setIsAttachPermissionPolicyModalOpen] = useState(false);
  const { data: group, isInitialLoading: isGroupLoading } = useGetGroup({ id: groupId });
  const { mutate: updateGroup, isLoading: isUpdatingGroup } = useUpdateGroup();
  const { data: groupUsers, isInitialLoading: isGroupUsersLoading } = useGetUsersInGroup({ id: groupId });
  const { mutate: deletePermissionPolicyFromGroup } = useDeletePermissionPolicyFromGroup();
  const { mutate: attachPermissionPolicyToGroup } = useAttachPermissionPolicyToGroup();
  const { mutate: addUsersToGroup } = useUpdateUsersInGroup();
  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();
  const { mutate: deleteGroup } = useDeleteGroup();

  const usersInGroup = useMemo(
    () =>
      groupUsers?.users.filter(
        (user) =>
          user.email.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          user.userName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
      ) ?? [],
    [groupUsers, keyword]
  );

  const handleRemoveGroup = (group: Group) => {
    openConfirm({
      message: 'Are you sure?',
      title: `Delete ${group.name}?`,
      onConfirm: () => {
        deleteGroup(
          { id: group.id },
          {
            onSuccess: () => {
              navigate(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.GROUP}`);
            },
          }
        );
      },
    });
  };

  const handleOnSubmit = (values: UseUpdateGroupParams) => {
    updateGroup(values, {
      onSettled: () => {
        setIsOpened(false);
      },
    });
  };

  const handleUserSelect = ({ users }: { users: GroupUser[] }) => {
    addUsersToGroup(
      { id: groupId, users: users.map((user) => user.userId) },
      {
        onSettled: () => {
          setIsOpenedSelectUsersModal(false);
        },
      }
    );
  };

  const handleAttachPermissionPolicy = ({
    permissionPolicies,
  }: {
    permissionPolicies: Pick<PermissionPolicy, 'id' | 'name'>[];
  }) => {
    assert(!!group, new Error('[GroupDetail] - group should be defined to attach permission policy.'));
    attachPermissionPolicyToGroup(
      {
        group: { id: group.id, name: group.name, logoUrl: group.logoUrl },
        permissionPolicies,
      },
      {
        onSuccess: () => {
          setIsAttachPermissionPolicyModalOpen(false);
        },
      }
    );
  };

  const handleDeletePermissionPolicyFromGroup = (id: string) => {
    deletePermissionPolicyFromGroup({ groupId, policyId: id });
  };

  return (
    <DetailLayoutBody>
      <NvFlex marginTop={'24px'} flexDirection="column" gap="12px">
        <NvBox>
          <NvButton
            color="secondary"
            startIcon={<NvArrowBackIcon />}
            onClick={() => navigate(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.GROUP}`)}
            size="small"
          >
            Back
          </NvButton>
        </NvBox>

        {group ? (
          <>
            <NvFlex gap="4px">
              <NvFlex direction="row" gap="12px" alignItems="flex-start" justifyContent="space-between">
                <InlineEditWrapper className="view-mode" sx={{ width: 'auto' }}>
                  <NvFlex flexDirection="row" className="view-wrapper">
                    <NvFlex flex="1 1 auto" minWidth="0">
                      <NvFlex flexDirection="row" gap="12px" alignItems="center" flex="0 1 auto">
                        <UserAndGroupImage src={group.logoUrl} id={group.id} type={AccessType.GROUP} size="large" />
                        <NvTypography variant="h1">{group.name}</NvTypography>
                      </NvFlex>
                    </NvFlex>

                    <EditButton
                      className="edit-button"
                      color="ghost"
                      size="small"
                      onlyIcon
                      onClick={() => {
                        setIsOpened(true);
                      }}
                    >
                      <NvEditIcon />
                    </EditButton>
                  </NvFlex>
                </InlineEditWrapper>

                <NvMenuWithItems
                  triggerButton={{
                    content: <NvMoreHorizIcon />,
                    props: { onlyIcon: true, size: 'medium', color: 'secondary' },
                  }}
                  menuItems={[
                    {
                      name: 'Delete',
                      onClick: () => {
                        handleRemoveGroup(group);
                      },
                      icon: (
                        <NvDeleteOutlineIcon
                          htmlColor={theme.palette.nv_error[40]}
                          sx={{ width: '16px', height: '16px' }}
                        />
                      ),
                    },
                  ]}
                />
              </NvFlex>
              <InlineEditWrapper className="view-mode">
                <NvFlex flexDirection="row" className="view-wrapper">
                  <NvFlex flex="1 1 auto" minWidth="0" gap="8px">
                    {group.description || group.tags.length > 0 ? (
                      <>
                        {group.description ? (
                          <NvTypography variant="body1">{group.description}</NvTypography>
                        ) : (
                          <TagAndDescriptionEmptyState message="No descriptions available for this group yet." />
                        )}

                        {group.tags.length > 0 ? (
                          <NvFlex direction="row" alignItems="center" gap="4px" flexWrap="wrap">
                            {group.tags.map((tag, index) => (
                              <NvChip key={`${tag}-${index}`} label={tag} />
                            ))}
                          </NvFlex>
                        ) : (
                          <TagAndDescriptionEmptyState message="No tags available for this group yet." />
                        )}
                      </>
                    ) : (
                      <TagAndDescriptionEmptyState message="No descriptions and tags available for this group yet." />
                    )}
                  </NvFlex>

                  <EditButton
                    className="edit-button"
                    color="ghost"
                    size="small"
                    onlyIcon
                    onClick={() => {
                      setIsOpened(true);
                    }}
                  >
                    <NvEditIcon />
                  </EditButton>
                </NvFlex>
              </InlineEditWrapper>
            </NvFlex>
            <NvFlex gap="8px" alignItems="flex-start">
              <NvTypography variant="h3">Permission policies</NvTypography>
              <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
                {!group.permissionPolicies || group.permissionPolicies.length === 0 ? (
                  <GroupOrPermissionEmptyState message={'No permission polices have been added for this group.'} />
                ) : (
                  group.permissionPolicies.map((permissionPolicy) => (
                    <GroupOrPermissionPolicyCard
                      name={permissionPolicy.name}
                      onDelete={() => {
                        handleDeletePermissionPolicyFromGroup(permissionPolicy.id);
                      }}
                    />
                  ))
                )}
              </NvFlex>
              <NvButton
                size="small"
                color="secondary"
                startIcon={<NvAddBoxIcon />}
                onClick={() => setIsAttachPermissionPolicyModalOpen(true)}
              >
                Add permission policies
              </NvButton>
            </NvFlex>
          </>
        ) : isGroupLoading ? (
          <>
            <NvFlex gap="4px" width="100%">
              <NvFlex flexDirection="row" justifyContent="space-between">
                <NvFlex flexDirection="row" gap="12px" alignItems="center">
                  <NvSkeleton variant="rectangular" width="40px" height="40px" />
                  <NvSkeleton variant="rectangular" width="200px" height="32px" />
                </NvFlex>
                <NvSkeleton variant="rectangular" width="32px" height="32px" />
              </NvFlex>
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
            </NvFlex>
            <NvFlex gap="8px" width="100%">
              <NvSkeleton variant="rectangular" width="100%" height="24px" />
              <NvFlex direction="row" alignItems="center" gap="8px">
                <NvSkeleton variant="rectangular" width="150px" height="40px" />
                <NvSkeleton variant="rectangular" width="150px" height="40px" />
                <NvSkeleton variant="rectangular" width="150px" height="40px" />
              </NvFlex>
            </NvFlex>
          </>
        ) : null}

        <NvFlex gap="8px">
          <NvTypography variant="h3">Members</NvTypography>
          {groupUsers?.users && groupUsers.users.length > 0 ? (
            <>
              <SearchAndButtonBar
                buttonLabel="Select users"
                onButtonClick={() => {
                  setIsOpenedSelectUsersModal(true);
                }}
                AddButtonStartIcon={<NvPersonIcon />}
                setSearchKeyword={setKeyword}
              />
              <NvBasicTable
                tableBody={
                  usersInGroup.length > 0 ? (
                    usersInGroup.map((user) => (
                      <GroupUserItem key={`group_user_${user.userId}`} user={user} groupId={groupId} />
                    ))
                  ) : (
                    <NvSearchEmptyState text="No user found. Try different words or clear search bar." />
                  )
                }
              />
            </>
          ) : isGroupUsersLoading ? (
            <NvFlex gap="12px">
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvFlex gap="4px">
                <NvSkeleton variant="rectangular" width="100%" height="48px" />
                <NvSkeleton variant="rectangular" width="100%" height="48px" />
                <NvSkeleton variant="rectangular" width="100%" height="48px" />
              </NvFlex>
            </NvFlex>
          ) : (
            <NvFlex gap="8px" alignItems="flex-start">
              <GroupOrPermissionEmptyState message={'No users have been added for this group.'} />
              <NvButton
                size="small"
                color="secondary"
                startIcon={<NvPersonIcon />}
                onClick={() => setIsOpenedSelectUsersModal(true)}
              >
                Select users
              </NvButton>
            </NvFlex>
          )}
        </NvFlex>
      </NvFlex>

      {group && (
        <NvDialogModal<UseUpdateGroupParams>
          formProps={{
            initialValues: {
              description: group.description,
              id: group.id,
              name: group.name,
              tags: group.tags,
            },
          }}
          onFormSubmit={handleOnSubmit}
          maxWidth="sm"
          fullWidth
          open={isOpened}
          onCloseButtonClicked={() => setIsOpened(false)}
          Header="Edit group"
          modalIcon="edit"
          primaryButtonText="Save"
          Body={<GroupsCreateUpdateModalBody logoUrl={group.logoUrl} />}
          isLoading={isUpdatingGroup}
        />
      )}
      {group && (
        <NvDialogModal<{ permissionPolicies: Pick<PermissionPolicy, 'id' | 'name'>[] }>
          onCloseButtonClicked={() => setIsAttachPermissionPolicyModalOpen(false)}
          maxWidth="sm"
          fullWidth
          Header={`Add permission policies to ${group.name} group`}
          open={isAttachPermissionPolicyModalOpen}
          Body={<AttachPermissionPolicyModalBody currentPermissionPolicies={group.permissionPolicies} />}
          onFormSubmit={handleAttachPermissionPolicy}
        />
      )}

      {group && (
        <NvDialogModal<{ users: GroupUser[] }>
          onCloseButtonClicked={() => setIsOpenedSelectUsersModal(false)}
          maxWidth="sm"
          fullWidth
          Header={`Add user to ${group.name} group`}
          open={isOpenedSelectUsersModal}
          Body={<SelectUsersModalBody groupUsers={groupUsers?.users} />}
          onFormSubmit={handleUserSelect}
        />
      )}
    </DetailLayoutBody>
  );
};
