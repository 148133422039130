import { useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { createContext, FC, useContext, useState } from 'react';

const RequestContext = createContext<
  | {
      selectedRequestId?: string;
      setSelectedRequestId: (requestId?: string) => void;
    }
  | undefined
>(undefined);

export const RequestProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { integrationId, actionId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ integrationId, actionId, version: integration?.latestVersion.number });
  const requestId = data?.httpRequestConfigurations?.[0]?.id;
  const [selectedRequestId, setSelectedRequestId] = useState<string | undefined>(requestId);

  const value = { selectedRequestId, setSelectedRequestId };

  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
};

export const useRequestContext = () => {
  const context = useContext(RequestContext);
  assert(!!context, new Error(`useRequestContext should be used within RequestProvider`), 'ERROR');

  return context;
};
