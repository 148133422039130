import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const LinkConfigurationWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  padding: 4px 12px;
  border-radius: 8px;
`;
