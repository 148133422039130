import { Markdown, NvBox, NvErrorIcon, NvFlex, NvTag, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { useMemo } from 'react';
import { SmallMarkdownWrapper, StyledListItem, StyledListItemLeft, StyledListItemRight } from './styled';
import { BaseListItemProps } from './types';

export const BaseListItem = <T,>({
  description: originalDescription,
  isDescriptionMarkDown: originalIsDescriptionMarkDown,
  tags: originalTags,
  header: originalHeader,
  onClick,
  isSelected,
  hasError = false,
  RightContent,
  item,
  format,
  index = 0,
}: BaseListItemProps<T>) => {
  const { palette } = useTheme();

  const { header, description, isDescriptionMarkDown, tags } = useMemo(() => {
    if (!format || !item) {
      return {
        header: originalHeader,
        description: originalDescription,
        isDescriptionMarkDown: originalIsDescriptionMarkDown,
        tags: originalTags,
      };
    }
    return format(item, index);
  }, [format, index, item, originalDescription, originalHeader, originalIsDescriptionMarkDown, originalTags]);

  return (
    <StyledListItem
      direction="column"
      gap="8px"
      className={classNames({
        'selected-item': isSelected,
        'has-error': hasError,
      })}
      onClick={onClick}
    >
      {hasError && (
        <NvBox position="absolute" top="calc(50% - 8px)" left="-11px">
          <NvErrorIcon
            htmlColor={palette.nv_error[40]}
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
        </NvBox>
      )}
      <StyledListItemLeft>
        <NvFlex direction="row" alignItems="center" justifyContent="space-between" flex="1 1 auto" minWidth={0} gap={1}>
          {header}
        </NvFlex>
        {tags && tags.length > 0 && (
          <NvFlex direction={'row'} gap={0.5} flexWrap="wrap">
            {tags.map((tag) => (
              <NvTag key={`base_item_tag_${tag}`} label={tag}></NvTag>
            ))}
          </NvFlex>
        )}
        {description ? (
          isDescriptionMarkDown ? (
            <SmallMarkdownWrapper className="small-markdown-wrapper">
              <Markdown>{description}</Markdown>
            </SmallMarkdownWrapper>
          ) : (
            <NvTypography variant="body2">{description}</NvTypography>
          )
        ) : undefined}
      </StyledListItemLeft>
      <StyledListItemRight>
        <NvFlex flex="0 0 auto" flexDirection="row" gap="12px" alignItems="center">
          {RightContent?.(item)}
        </NvFlex>
      </StyledListItemRight>
    </StyledListItem>
  );
};
