import { NvField } from '@novaera/core';
import { FC } from 'react';
import { useUIComponents } from '../../use-ui-component';
import { DynamicInputParameterType } from './types';

export const DynamicInputParameter: FC<React.PropsWithChildren<DynamicInputParameterType>> = (props) => {
  const {
    getOptionsResponse,
    getDynamicInputResponse,
    setInputParameterIdsShowingOptions,
    onSearchAsYouTypeValueUpdate,
    inputParameter,
    dynamicInputNestedComponentProps,
    otherProps,
    context,
    getDynamicInputComponentState,
  } = props;

  const { componentFieldProps, getComponentForField } = useUIComponents({
    inputParameter,
    getOptionsResponse,
    getDynamicInputResponse,
    setInputParameterIdsShowingOptions,
    onSearchAsYouTypeValueUpdate,
    getDynamicInputComponentState,
  });

  return (
    <NvField
      {...componentFieldProps}
      labelVariant={dynamicInputNestedComponentProps?.labelVariant}
      direction="label-on-top"
      name={`${inputParameter.id}`}
      component={getComponentForField({ inputParameter, otherProps, context })}
    />
  );
};
