import { AuthenticationType } from '@novaera/actioner-service';

export const URL_AND_REQUEST_PARAMETERS_DESCRIPTION: Record<AuthenticationType, string> = {
  basic:
    'Add URL Params and/or Headers. To dynamically append the value entered in an authentication field, you can use ',
  'api-key':
    'Add URL Params and/or Headers. To dynamically append the value entered in an authentication field, you can use ',
  'aws-assume-role': '',
  oauth2: '',
};
