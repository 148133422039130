import { SchemaType } from '@novaera/ah-common';
import { isRequired, NvField, NvFlex, NvSwitch, NvTextField, NvTypography } from '@novaera/core';
import { FC } from 'react';

import { PropertyPanelSimpleSection } from '../../../../property-panel';
import { BasePropertiesProps } from '../types';
import { DateTimeFormatField } from './date-time-format-field';

export const BaseProperties: FC<React.PropsWithChildren<BasePropertiesProps>> = ({
  fieldName,
  fieldType,
  disabled = false,
}) => {
  return (
    <PropertyPanelSimpleSection>
      <NvField
        name={`${fieldName}.name`}
        direction="label-on-side"
        labelVariant="h5"
        labelText="Field name"
        isRequired
        validators={[isRequired()]}
        component={<NvTextField disabled={disabled} />}
        hasRequiredIndicator
        formControlStyle={{ width: '100%', maxWidth: 700 - 98 }}
        isDisabled={disabled}
      />

      {fieldType !== SchemaType.boolean && (
        <NvField
          isDisabled={disabled}
          direction="label-on-side"
          labelVariant="h5"
          labelText="Required"
          name={`${fieldName}.schema.mandatory`}
          type="checkbox"
          component={({ onChange, checked }) => (
            <NvFlex direction="row" gap="8px" padding="6px 0">
              <NvSwitch checked={checked} onChange={onChange} variant="compact" disabled={disabled} />

              <NvTypography variant="body2" padding="1px 0">
                Required fields must be provided when creating a record for the model.
              </NvTypography>
            </NvFlex>
          )}
          formControlStyle={{ width: '100%', maxWidth: 700 - 98 }}
        />
      )}

      <NvField
        isDisabled={disabled}
        direction="label-on-side"
        labelVariant="h5"
        labelText="Read-only"
        name={`${fieldName}.schema.readOnly`}
        type="checkbox"
        component={({ onChange, checked }) => {
          return (
            <NvFlex direction="row" gap="8px" padding="6px 0">
              <NvSwitch checked={checked} onChange={onChange} variant="compact" disabled={disabled} />

              <NvTypography variant="body2" padding="1px 0">
                Read-only fields cannot be modified once they have been set.
              </NvTypography>
            </NvFlex>
          );
        }}
        formControlStyle={{ width: '100%', maxWidth: 700 - 98 }}
      />

      {fieldType === SchemaType.dateTime && <DateTimeFormatField schemaFieldName={`${fieldName}.schema`} />}
    </PropertyPanelSimpleSection>
  );
};
