import { NvButton, NvCheckIcon, NvCloseIcon, NvConditionalRender, NvFlex } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { CardWrapper } from './styled';
import { FilterLayoutProps } from './types';

export const FilterLayout = <T,>({ children, onApplyClicked, onRemoveClicked, mode }: FilterLayoutProps<T>) => {
  assert(typeof children === 'function', new Error('children should be sent'), 'ERROR');

  const [selectedItem, setSelectedItem] = useState<T>();

  const handleValueChanged = (value: T) => {
    setSelectedItem(value);
  };

  return (
    <CardWrapper>
      <NvConditionalRender when={mode === 'edit'}>
        <NvFlex flex={'1 1 auto'} minWidth={'0'}>
          {children({
            onValueChanged: handleValueChanged,
          })}
        </NvFlex>
        <NvFlex gap={'4px'} flexDirection={'row'} flex={'0 0 auto'}>
          <NvButton onlyIcon size="small" color="secondary" onClick={onRemoveClicked}>
            <NvCloseIcon />
          </NvButton>
          <NvButton onlyIcon size="small" color="primary" onClick={() => onApplyClicked(selectedItem)}>
            <NvCheckIcon />
          </NvButton>
        </NvFlex>
      </NvConditionalRender>
      <NvConditionalRender when={mode === 'display'}>
        <NvFlex flex={'1 1 auto'} minWidth={'0'} id="murat" padding="8px 0 4px 8px">
          {children({
            onValueChanged: handleValueChanged,
          })}
        </NvFlex>
        <NvFlex gap={'4px'} flexDirection={'row'} flex={'0 0 auto'}>
          <NvButton onlyIcon size="small" color="ghost" onClick={onRemoveClicked}>
            <NvCloseIcon />
          </NvButton>
        </NvFlex>
      </NvConditionalRender>
    </CardWrapper>
  );
};
