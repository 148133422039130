import { useGetAppSchemaDetail } from '@novaera/actioner-service';
import { useMatch } from '@novaera/route';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../common/routes';
import { ActionerDocumentTitle } from '../../components/actioner-document-title';
import { DEFAULT_APP_DIRECTORY_META_DESCRIPTION, DEFAULT_APP_DIRECTORY_TITLE, META_UNITS_BY_ID } from './constants';
import { AppDirectoryHeadProps } from './types';

export const AppDirectoryHead: React.FC<React.PropsWithChildren<AppDirectoryHeadProps>> = ({ isAnonymous }) => {
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');

  const appDirectoryPageMatch = useMatch(`${ROUTES.AppDirectory}`);
  const appDirectoryDetailPageMatch = useMatch(`${ROUTES.AppDirectory}/:name/:storeSchemaId`);
  const { data: activePackageSchema } = useGetAppSchemaDetail({
    schemaId: appDirectoryDetailPageMatch?.params.storeSchemaId,
    version,
    isAnonymous,
  });

  const title = useMemo(() => {
    if (appDirectoryPageMatch) {
      return DEFAULT_APP_DIRECTORY_TITLE;
    } else if (appDirectoryDetailPageMatch) {
      return activePackageSchema
        ? META_UNITS_BY_ID[activePackageSchema.id]?.title || `Actioner app directory | ${activePackageSchema.name}`
        : DEFAULT_APP_DIRECTORY_TITLE;
    } else {
      return 'Actioner';
    }
  }, [appDirectoryPageMatch, appDirectoryDetailPageMatch, activePackageSchema]);

  const metaDescription = useMemo(() => {
    if (appDirectoryPageMatch) {
      return DEFAULT_APP_DIRECTORY_META_DESCRIPTION;
    } else if (appDirectoryDetailPageMatch) {
      return `${
        activePackageSchema
          ? META_UNITS_BY_ID[activePackageSchema.id]?.description ||
            activePackageSchema.shortDescription.substring(0, 155)
          : DEFAULT_APP_DIRECTORY_META_DESCRIPTION
      }`;
    } else {
      return null;
    }
  }, [activePackageSchema, appDirectoryDetailPageMatch, appDirectoryPageMatch]);

  return (
    <>
      <ActionerDocumentTitle title={title} />
      <Helmet>{metaDescription && <meta name="description" content={metaDescription}></meta>}</Helmet>
    </>
  );
};
