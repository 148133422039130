import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { NvInfoIcon } from '../../icons';
import { ErrorNvTypographyWrapper, FormHelperTextWrapper } from '../styled';

export const FormHelperWarningText: FC<React.PropsWithChildren<{ error: string }>> = ({ error }) => {
  const { palette } = useTheme();
  return (
    <FormHelperTextWrapper>
      <NvInfoIcon
        sx={{
          width: '12px',
          height: '12px',
          marginTop: '1px',
        }}
        htmlColor={palette.nv_main[40]}
      />
      <ErrorNvTypographyWrapper variant="body3" color={palette.nv_neutral[1000]}>
        {error}
      </ErrorNvTypographyWrapper>
    </FormHelperTextWrapper>
  );
};
