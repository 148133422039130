import { assert, noop } from '@novaera/utils';

import React from 'react';
import { NvConfirmDialog } from '..';

import { confirmDialogStateReducer } from './reducer';
import { ConfirmDialogContextAction, ConfirmDialogContextProviderProps, ConfirmDialogState, Dispatch } from './types';

const ConfirmDialogContext = React.createContext<{ state: ConfirmDialogState; dispatch: Dispatch } | undefined>(
  undefined
);

const ConfirmDialogContextProvider = ({ children }: ConfirmDialogContextProviderProps) => {
  const [state, dispatch] = React.useReducer(confirmDialogStateReducer, {
    isOpen: false,
    onConfirm: noop,
    onClose: noop,
  });
  const value = { state, dispatch };
  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      <NvConfirmDialog
        isOpen={state.isOpen}
        title={state.title}
        message={state.message}
        cancelButtonLabel={state.cancelButtonLabel}
        onCancel={() => {
          dispatch({ type: ConfirmDialogContextAction.Close });
          state.onClose();
        }}
        confirmButtonLabel={state.confirmButtonLabel}
        onConfirm={state.onConfirm}
        confirmButton={state.confirmButton}
        icon={state.icon}
      />
    </ConfirmDialogContext.Provider>
  );
};

const useConfirmDialogContext = () => {
  const context = React.useContext(ConfirmDialogContext);
  assert(!!context, new Error('useConfirmDialog must be used within a ConfirmDialogContextProvider'));
  return context;
};

export { ConfirmDialogContextAction, ConfirmDialogContextProvider, useConfirmDialogContext };
