import { NvDivider, NvFlex, NvImage, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { useAppContext } from '../../app-context';
import { WorkspaceInitial } from './initial';
import { WorkspaceItemProps } from './types';

export const WorkspaceItem: React.FC<React.PropsWithChildren<WorkspaceItemProps>> = ({
  workspace,
  hideWorkspaceSubdomain,
}) => {
  const theme = useTheme();
  const { state } = useAppContext();

  const logoUrl = useMemo(
    () => state.workspaces.find((ws) => ws.id === workspace.id)?.logoUrl,
    [state.workspaces, workspace.id]
  );

  return (
    <NvFlex direction="row" alignItems="center" spacing="8px" width="100%">
      <NvImage
        FallBack={
          <WorkspaceInitial size="medium" subdomain={workspace.subdomain} displayName={workspace.displayName} />
        }
        imageShape="square"
        style={{
          width: '32px',
          height: '32px',
        }}
        src={logoUrl}
      />

      <NvFlex direction="column" flex="1 1 auto" minWidth={0} gap="2px">
        <NvTypography variant="h3" noWrap>
          {workspace.displayName}
        </NvTypography>

        {!hideWorkspaceSubdomain && (
          <NvFlex direction="row" alignItems="center" gap="4px">
            <NvTypography variant="body3" noWrap textColor="subtle">
              <b>Domain:</b> {workspace.subdomain}
            </NvTypography>
            <NvDivider orientation="vertical" borderColor={theme.palette.nv_neutral[40]} sx={{ height: '8px' }} />
            <NvTypography variant="body3" noWrap textColor="subtle">
              <b>Id:</b> {workspace.id}
            </NvTypography>
          </NvFlex>
        )}
      </NvFlex>
    </NvFlex>
  );
};
