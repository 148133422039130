import { Handle, Position } from 'reactflow';

export const PORTS = {
  port1: (
    <Handle
      type="target"
      position={Position.Top}
      style={{ opacity: 0 }}
      onConnect={(params) => console.log('handle onConnect', params)}
      isConnectable={false}
    />
  ),
  port2: (
    <Handle
      type="source"
      position={Position.Bottom}
      style={{ opacity: 0 }}
      onConnect={(params) => console.log('handle onConnect', params)}
      isConnectable={false}
    ></Handle>
  ),
};
