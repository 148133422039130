import { NvFlex, NvTextField, NvTypography } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NvTextFieldWrapper = styled(NvTextField)`
  max-width: 400px;
`;

export const NvTypographyWrapper = styled(NvTypography)`
  margin-bottom: 8px;
`;

export const Separator = styled(NvFlex)`
  width: 100%;
  flex-direction: row;
`;

export const Line = styled(NvFlex)<{
  color?: string;
}>`
  height: 1px;
  flex: 1;
  background-color: ${({ theme, color }) => color ?? theme.palette.nv_neutral[30]};
  margin-top: 8px;
  margin-left: 10px;
`;
