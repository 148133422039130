import { ROUTE_DEFAULTS } from '@novaera/constants';
import { NvFlex, NvLink, NvTypography } from '@novaera/core';
import React, { useState } from 'react';
import { CustomDivider } from '../styled';
import { WorkspacePanelCreate } from './create';
import { WorkspaceList } from './list';

export const WorkspacePanel: React.FC<
  React.PropsWithChildren<{
    username: string;
  }>
> = ({ username }) => {
  const [isCreatePanelOpened, setIsCreatePanelOpened] = useState<boolean>();

  const handleCreatePanelOpenClicked = (isOpened: boolean) => {
    setIsCreatePanelOpened(isOpened);
  };

  return (
    <>
      {!isCreatePanelOpened && <WorkspaceList />}

      <WorkspacePanelCreate
        username={username}
        onCreatePanelOpenClicked={() => handleCreatePanelOpenClicked(true)}
        onCancelClicked={() => handleCreatePanelOpenClicked(false)}
      />

      {!isCreatePanelOpened && (
        <>
          <NvFlex sx={{ marginTop: 2 }}>
            <CustomDivider />
          </NvFlex>
          <NvTypography textColor="subtle" sx={{ marginTop: 2 }}>
            Can't see your workspace here?{' '}
            <NvLink linkVariant="ghost" href={ROUTE_DEFAULTS.SIGN_IN}>
              <b>Try a different email.</b>
            </NvLink>
          </NvTypography>
        </>
      )}
    </>
  );
};
