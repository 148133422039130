import { MediaTypes } from '@novaera/actioner-service';
import { CodeInput, NvButton, NvCloseIcon, NvField, NvSelect, useField } from '@novaera/core';
import { FC, useMemo } from 'react';
import {
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { PropertyPanelKeyValueSection } from '../http-key-value-sub-panel/property-panel-key-value-section';
import { HTTPPropertiesSubPanelProps } from '../types';
import { MEDIA_TYPE_LABELS } from './constants';

type HTTPBodySubPanelProps = Omit<HTTPPropertiesSubPanelProps, 'panelType'>;

export const HTTPBodySubPanel: FC<HTTPBodySubPanelProps> = ({ onCloseClicked }) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const bodyFormatOptions = useMemo(
    () =>
      Object.values(MediaTypes)
        .filter((m) => m !== MediaTypes.MULTIPART_FORM_DATA)
        .map((m) => ({ label: MEDIA_TYPE_LABELS[m], value: m })),
    []
  );

  const {
    input: { value: mediaType },
  } = useField<MediaTypes>('mediaType');

  return (
    <>
      <PropertyPanelHeader
        title={'Body'}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />
      <PropertyPanelSection title="Body type" tooltip={'Body type'}>
        <NvField
          name={`mediaType`}
          defaultValue={MediaTypes.APPLICATION_JSON}
          component={<NvSelect compact options={bodyFormatOptions} size="small" sx={{ minWidth: '100px' }} />}
        />
      </PropertyPanelSection>
      {mediaType === MediaTypes.APPLICATION_FORM_URLENCODED ? (
        <PropertyPanelKeyValueSection keyValueSubPanelType="formParameters" />
      ) : (
        <PropertyPanelSimpleSection>
          <NvField
            name={`bodyTemplate`}
            component={
              <CodeInput
                context={workflowCodeInputContext}
                initialHeight="243px"
                placeholder="{{ ... }}"
                growingHeight
              />
            }
          ></NvField>
        </PropertyPanelSimpleSection>
      )}
    </>
  );
};
