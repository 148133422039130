import { NvDivider, NvFlex, NvTypography, NvTypographyProps } from '@novaera/core';
import { PropsWithChildren, ReactNode } from 'react';
import { EmailAddressCardContainer } from './styled';

export const EmailAddressCard: React.FC<
  PropsWithChildren<{
    emailPrefix: string;
    emailPostfix: string;
    endSlot?: ReactNode;
    typographyProps?: NvTypographyProps;
  }>
> = ({ emailPrefix, emailPostfix, children, endSlot, typographyProps }) => {
  return (
    <EmailAddressCardContainer>
      <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
        <NvTypography
          flex="0 1 auto"
          minWidth={0}
          maxWidth="72px"
          noWrap
          variant="h6"
          textColor="secondary"
          sx={{ direction: 'rtl', textAlign: 'left' }}
          {...(typographyProps ?? {})}
        >
          .{emailPrefix}
        </NvTypography>
        {children}
        <NvTypography
          flex="0 0 auto"
          width="112px"
          noWrap
          variant="h6"
          textColor="secondary"
          {...(typographyProps ?? {})}
        >
          {emailPostfix}
        </NvTypography>
      </NvFlex>

      {endSlot && (
        <>
          <NvDivider orientation="vertical" sx={{ height: '16px', flex: '0 0 auto' }} />
          {endSlot}
        </>
      )}
    </EmailAddressCardContainer>
  );
};
