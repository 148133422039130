import { NvSwitchButtonOption } from '@novaera/core';
import { CatalogSwitchHeaderMode } from './types';

export const CatalogSwitchHeaderModes: NvSwitchButtonOption<CatalogSwitchHeaderMode>[] = [
  {
    label: 'Schema',
    value: 'schema',
  },
  {
    label: 'Records',
    value: 'records',
  },
];
