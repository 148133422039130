import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkflowHistoryNodeExecutionsWrapper = styled(NvFlex, {
  shouldForwardProp: (prop) => prop !== 'fullScreen',
})<{ fullScreen: boolean }>`
  width: ${({ fullScreen }) => (fullScreen ? '100%' : '480px')};
  height: 100%;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  overflow: auto;
  padding-bottom: 60px;
`;
