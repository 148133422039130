import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { AddNewAppDropdownMenuItemProps } from './types';

export const AddNewAppDropdownMenuItemBox = styled(NvBox)<Pick<AddNewAppDropdownMenuItemProps, 'color'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0px 12px;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;

  &.color-ghost {
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
    }
  }

  &.color-secondary {
    background-color: ${({ theme }) => theme.palette.nv_neutral[20]};

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral[40]};
    }
  }

  &.color-primary {
    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    background-color: ${({ theme }) => theme.palette.nv_main[40]};

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_main[50]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_main[60]};
    }
  }
`;
