import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { GetUserAppSetupResponse, SetupWorkflow } from '..';
import { QUERY_KEYS_USER_APP_SETUP } from '../keys';

export const useUpdateUserAppSetupStatus = ({ appId }: { appId: string }) => {
  const cache = useQueryClient();

  const updateUserAppSetupStatus = useCallback(
    ({ setupWorkflowId, status }: { setupWorkflowId: string; status: SetupWorkflow['status'] }) => {
      cache.setQueryData<GetUserAppSetupResponse>(QUERY_KEYS_USER_APP_SETUP.detail({ appId }), (old) => {
        if (old) {
          return {
            ...old,
            setupWorkflows: old.setupWorkflows.map((w) =>
              w.id === setupWorkflowId && w.status !== status ? { ...w, status } : w
            ),
          };
        } else {
          return undefined;
        }
      });
    },
    []
  );

  return { updateUserAppSetupStatus };
};
