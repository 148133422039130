import { KeyDisplayValue } from '@novaera/actioner-service';
import { FieldArray } from 'react-final-form-arrays';
import { PropertyPanelEmptySection, PropertyPanelSimpleSection } from '../../../property-panel';
import { StaticOptionItem } from '../static-option-item';

export const StaticDataSourceMapper = () => {
  return (
    <FieldArray<KeyDisplayValue> name={`uiComponent.dataSource.options`} defaultValue={[]}>
      {({ fields }) => {
        const keyDisplayValues = fields.value;
        return keyDisplayValues?.length === 0 ? (
          <PropertyPanelEmptySection emptyText="This field doesn’t have any options yet." />
        ) : (
          keyDisplayValues?.map((_keyDisplayValue, index) => {
            return (
              <PropertyPanelSimpleSection key={`${index}`}>
                <StaticOptionItem
                  optionFieldName={`uiComponent.dataSource.options[${index}]`}
                  onRemove={() => {
                    fields.remove(index);
                  }}
                />
              </PropertyPanelSimpleSection>
            );
          })
        );
      }}
    </FieldArray>
  );
};
