import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS } from '../keys';

export const useResetWorkflowSourceOptions = () => {
  const client = useQueryClient();

  const removeWorkflowSourceOptionsCacheForInputParameter = useCallback(
    ({
      appId,
      workflowId,
      draft,
      inputParameterId,
    }: {
      appId: string;
      workflowId: string;
      inputParameterId: string;
      draft: boolean;
    }) => {
      client.removeQueries(
        QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS.withParameterId({
          appId,
          workflowId,
          payload: { inputParameterId, draft },
        })
      );
    },
    [client]
  );

  const removeWorkflowSourceOptionsCacheForApp = useCallback(({ appId }: { appId: string }) => {
    client.removeQueries(QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS.withAppId({ appId }));
  }, []);

  return { removeWorkflowSourceOptionsCacheForInputParameter, removeWorkflowSourceOptionsCacheForApp };
};
