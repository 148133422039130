import { NvTextField, NvLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CodeInputWrapper = styled('div')<{
  hasError?: boolean;
}>``;
export const CodeInput = styled(NvTextField)`
  &.MuiTextField-root {
    height: 88px;

    & .MuiInput-root {
      height: 88px !important;
      width: 100%;

      & .MuiInput-input {
        width: auto;
        width: 250px;
        font-family: 'Fira Code';
        letter-spacing: 20px;
        font-size: 32px;
        margin-left: 31px;
      }
    }
  }
`;

export const NumberLineContainer = styled('div')`
  position: absolute;
  z-index: 1;
  left: calc(50% - 116px);
  top: 69px;
  display: flex;
  gap: 6px;
  width: 230px;
  height: 10px;
`;

export const NumberLine = styled('div')`
  width: 100%;
  flex: 1 1 auto;
  min-width: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
`;

export const TextLink = styled(NvLink)`
  color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
  text-decoration-color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
`;
