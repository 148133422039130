import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ADMIN_WORKSPACES_PREFIX } from '../constants';
import { QUERY_KEYS_ADMIN_WORKSPACES } from '../keys';
import { UpdateWorkspaceStatusParams } from '../types';

const updateWorkspaceStatus = async ({ id, status, productId }: UpdateWorkspaceStatusParams) => {
  const result = await NvAxios.patch(`${ADMIN_WORKSPACES_PREFIX}/${id}/status`, { status }, { params: { productId } });
  return result?.data;
};

export const useUpdateWorkspaceStatus = () => {
  const cache = useQueryClient();
  return useMutation(updateWorkspaceStatus, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS_ADMIN_WORKSPACES.all);
    },
  });
};
