import { MinutelyJobRecurrenceConfiguration, ValueTypes } from '@novaera/actioner-service';
import { NvFlex, NvNumberInput, NvTypography } from '@novaera/core';
import { RecurrenceProps } from '../../types';

export const StaticMinutelyRecurrence: React.FC<
  Omit<RecurrenceProps<MinutelyJobRecurrenceConfiguration>, 'context'>
> = ({ recurrenceConfiguration, onChange }) => {
  return (
    <NvFlex direction="row" alignItems="center" gap="4px">
      <NvNumberInput
        value={recurrenceConfiguration.repeatInterval.value}
        onChange={(e) => {
          onChange({
            ...recurrenceConfiguration,
            repeatInterval: {
              type: ValueTypes.NUMBER,
              displayValue: e.target.value,
              value: e.target.value ? Number(e.target.value) : undefined,
            },
          });
        }}
      />
      <NvTypography variant="body2">minutes</NvTypography>
    </NvFlex>
  );
};
