import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const EventListItem = styled(NvFlex)`
  flex-direction: row;
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  transition: background-color 150ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};

    .go-to-workflow-button {
      opacity: 1;
      width: auto;
      height: auto;
    }
  }

  .go-to-workflow-button {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export const EmptyState = styled(NvFlex)`
  flex-direction: row;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1 px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
`;
