import { useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { RootConditionProperties } from '../common/root-condition-properties';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { ActionerEventTriggerProperties } from './actioner-event-trigger-properties';
import { ActionerEventTriggerPropertyPanelDrawerProps } from './types';

export const ActionerEventTriggerPropertyPanelDrawer: React.FC<ActionerEventTriggerPropertyPanelDrawerProps> = ({
  workflow,
  handleClose,
}) => {
  const { isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  const [secondPanelOpen, setSecondPanelOpen] = useState(false);
  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  const {
    panelProps: [first, second],
    panelCloseFunctions: [onFirstClose, onSecondClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: secondPanelOpen,
        closeFn: () => {
          setSecondPanelOpen(false);
        },
      },
    ],
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        <ActionerEventTriggerProperties
          onRootConditionClicked={() => {
            setSecondPanelOpen(true);
          }}
          workflow={workflow}
          onCloseClicked={onFirstClose}
        />
      </PPDrawerItem>
      <PPDrawerItem {...second}>
        <RootConditionProperties workflow={workflow} onCloseClicked={onSecondClose} />
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
