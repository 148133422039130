import { useGetWorkflow } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { useOutputIcon } from '../../common/use-output-icon';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type OutputProps = BaseNodeProps;

export const Output: FC<React.PropsWithChildren<OutputProps>> = (props) => {
  const nodeId = props.id;
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const node = workflow?.nodeSummaries?.[nodeId];
  assert(
    node?.type === 'output',
    new Error(`Output node should have output type information in the workflow but type is:${node?.type}`),
    'ERROR'
  );

  const { outputIcon } = useOutputIcon({ outputNode: node });

  return (
    <DraggableItem nodeId={props.id}>
      <Node type={NodeType.OUTPUT} icon={outputIcon} {...props} />
    </DraggableItem>
  );
};
