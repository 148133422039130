import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const BillingCardWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
  border-radius: 12px;
`;
