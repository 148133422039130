import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const LayoutWrapper = styled(NvFlex)`
  overflow: auto;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
`;

export const ContentWrapper = styled(NvFlex)`
  &.center-content {
    align-items: center;
    padding: 0 40px;
  }
`;

export const ContentCard = styled(NvBox)`
  margin-top: 80px;
  padding-top: 42px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 60px;
  ${({ theme }) => theme.elevations.e500};
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
`;
