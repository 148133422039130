import { NvCustomAssistantStopIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type AssistantCancelRunProps = BaseNodeProps;

export const AssistantCancelRun: FC<AssistantCancelRunProps> = (props) => {
  const theme = useTheme();
  return (
    <DraggableItem nodeId={props.id}>
      <Node
        type={NodeType.ASSISTANT_CANCEL_RUN}
        {...props}
        icon={<NvCustomAssistantStopIcon htmlColor={theme.palette.nv_node['assistant_cancel_run']} />}
      />
    </DraggableItem>
  );
};
