import { SxProps, Theme } from '@mui/material';

export enum NeutralChipColor {
  LIGHT_ALPHA = 'light-alpha',
  LIGHT_SOLID = 'light-solid',
  DARK_ALPHA = 'dark-alpha',
  DARK_SOLID = 'dark-solid',
}

export enum SemanticChipColor {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  NEW = 'new',
}

export enum ColoredChipColor {
  BLUE = 'blue',
  PURPLE = 'purple',
  PINK = 'pink',
  GREEN = 'green',
  TEAL = 'teal',
  ACCENT = 'accent',
  LIGHT_BLUE_GRAY = 'light-blue-gray',
}

export type ChipColor = NeutralChipColor | SemanticChipColor | ColoredChipColor;

export type NvChipProps<T extends string | number = string> = T extends string
  ? NvChipPropString<T>
  : NvChipPropNumber<T>;

export interface NvChipPropBase<T extends string | number> {
  color?: NeutralChipColor | SemanticChipColor | ColoredChipColor;
  label: T | React.ReactNode;
  compact?: boolean;
  sx?: SxProps<Theme>;
  maxWidth?: number;
  className?: string;
}

export interface NvChipPropString<T extends string | number> extends NvChipPropBase<T> {
  icon?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (e?: any) => void;
}

export type NvChipPropNumber<T extends string | number> = NvChipPropBase<T>;
