import {
  CatalogRelationshipFilter,
  CatalogRelationshipFilterParameterMapping,
  ParameterTypes,
} from '@novaera/actioner-service';
import { useCallback } from 'react';

export const useCatalogRelationshipFilterAdapter = () => {
  const format = useCallback((value?: CatalogRelationshipFilterParameterMapping) => {
    return value?.filters;
  }, []);

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (value: CatalogRelationshipFilter[]): CatalogRelationshipFilterParameterMapping | undefined => {
        if (value) {
          return { type: ParameterTypes.CATALOG_RELATIONSHIP_FILTER, filters: value, parameterId: id };
        }
        return undefined;
      },
    []
  );

  return { format, parse };
};
