import { SaveScheduledJobOperation } from '@novaera/actioner-service';
import { useField } from '@novaera/core';
import { FC } from 'react';
import { SAVE_SCHEDULED_JOB_SAMPLE_RESPONSE } from '../../../../../common/constants';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { JobID } from '../common-fields/job-id';
import { JobName } from '../common-fields/job-name';
import { JobNodeTargetTrigger } from '../common-fields/job-node-target/trigger';
import { JobTimezone } from '../common-fields/job-timezone';
import { JobSampleResponse } from '../common-fields/sample-response';
import { ScheduledTimeConfiguration } from './time-configuration';
import { SaveScheduledJobFieldsProps } from './types';

export const SaveScheduledJobFields: FC<SaveScheduledJobFieldsProps> = ({
  isJobTargetPanelOpen,
  onJobTargetClicked,
}) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const {
    input: { value: operation },
  } = useField<SaveScheduledJobOperation>('operation');

  return (
    <>
      <JobName context={workflowCodeInputContext} />
      <JobID context={workflowCodeInputContext} />
      {!operation.dynamic && <JobTimezone />}
      <ScheduledTimeConfiguration context={workflowCodeInputContext} />
      <JobNodeTargetTrigger onClick={onJobTargetClicked} isSelected={isJobTargetPanelOpen} />
      <JobSampleResponse content={SAVE_SCHEDULED_JOB_SAMPLE_RESPONSE} />
    </>
  );
};
