import {
  GetDynamicInputParamsForMultipleInputParameters,
  InputParameters,
  useGetDynamicInputParameters,
  useResetDynamicInputParameters,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useCheckActionDynamicInputDependencyValue } from '../controller/use-check-action-dynamic-input-dependency-value';
import { DependencyInputComponentStateFN } from '../controller/use-check-dependency-value/types';
import { useGetDynamicInputParameterParent } from '../controller/use-get-dynamic-input-parameter-parent';
import {
  DynamicInputContextType,
  DynamicInputMapArray,
  DynamicInputProviderProps,
  GetDynamicInputResponseFunction,
} from './types';

const DynamicInputContext = createContext<DynamicInputContextType | undefined>(undefined);

export const DynamicInputProvider: FC<DynamicInputProviderProps> = ({
  children,
  actionId,
  integrationId,
  draft,
  versionNumber,
  inputParameterIds,
  initialInputParameterIdsShowingDynamicInput,
  inputParameterValues,
  allInputParameterIdsWithOrder,
  formId,
  storeDynamicFormParametersPermanently = false,
  ...rest
}) => {
  const { removeCacheForDynamicInputParameters } = useResetDynamicInputParameters();

  const { getDependencyInputComponentState, filterSuccessDynamicInputs, dependenciesOfParameters } =
    useCheckActionDynamicInputDependencyValue({
      actionId,
      draft: Boolean(draft),
      integrationId,
      version: versionNumber,
    });

  const [inputParameterIdsShowingDynamicInput, setInputParameterIdsShowingDynamicInput] = useState<string[]>(
    initialInputParameterIdsShowingDynamicInput
  );
  const dynamicInputRequestParams: GetDynamicInputParamsForMultipleInputParameters = useMemo(() => {
    return {
      ...rest,
      requestParams: {
        actionId,
        integrationId,
        payload: inputParameterIds.map((i) => ({
          formId,
          inputParameterId: i,
          draft,
          versionNumber: versionNumber,
          inputParameters: inputParameterValues?.filter((inputParameterValue) =>
            dependenciesOfParameters?.[i]?.includes(inputParameterValue.parameterId)
          ),
          storeDynamicFormParametersPermanently,
        })),
      },
      options: { keepPreviousData: true, staleTime: Infinity },
      enabledInputParameters: inputParameterIdsShowingDynamicInput.filter((inputParameterId) =>
        filterSuccessDynamicInputs(inputParameterId, inputParameterValues)
      ),
      allInputParameterIdsWithOrder: allInputParameterIdsWithOrder,
    };
  }, [
    rest,
    actionId,
    integrationId,
    inputParameterIds,
    inputParameterIdsShowingDynamicInput,
    allInputParameterIdsWithOrder,
    formId,
    draft,
    versionNumber,
    inputParameterValues,
    storeDynamicFormParametersPermanently,
    filterSuccessDynamicInputs,
    dependenciesOfParameters,
  ]);

  const results = useGetDynamicInputParameters(dynamicInputRequestParams);

  const dynamicInputParameters = useMemo(() => {
    const allInputParameters: InputParameters = [];
    results.forEach((r) => r.data?.parameters?.forEach((p) => allInputParameters.push(p)));
    return allInputParameters;
  }, [results]);

  const dynamicInputMap: DynamicInputMapArray = useMemo(() => {
    return inputParameterIds.map((inputParameter, index) => {
      return { inputParameterId: inputParameter, ...results[index] };
    });
  }, [inputParameterIds, results]);

  const { getDynamicInputParameterParent } = useGetDynamicInputParameterParent({ dynamicInputMap });

  const invalidate = useCallback(
    (inputParameterId: string) => {
      removeCacheForDynamicInputParameters({
        actionId,
        draft,
        inputParameterId,
        versionNumber,
        integrationId,
        formId,
        ...rest,
      });
    },
    [actionId, draft, formId, integrationId, removeCacheForDynamicInputParameters, rest, versionNumber]
  );

  const getDynamicInputParameters = useCallback<GetDynamicInputResponseFunction>(
    (inputParameterId: string) => {
      const result = dynamicInputMap.find((o) => o.inputParameterId === inputParameterId);
      const { parameters, ...data } = result?.data ?? { parameters: [] };

      return {
        ...result?.data,
        isLoading: result?.isInitialLoading || result?.isRefetching,
      };
    },
    [dynamicInputMap]
  );

  const handleGetDynamicInputComponentState = useCallback<DependencyInputComponentStateFN>(
    ({ inputParameterId, inputParameterValues }) => {
      return getDependencyInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
    [getDependencyInputComponentState]
  );

  const isBusy = useMemo(() => results.some((result) => result.isFetching), [results]);

  const value = {
    isBusy,
    setInputParameterIdsShowingDynamicInput,
    getDynamicInputParameters,
    getDynamicInputParameterParent,
    invalidate,
    dynamicInputParameters,
    handleGetDynamicInputComponentState,
    getDynamicInputComponentState: (inputParameterId: string) => {
      return handleGetDynamicInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
  };
  return (
    <DynamicInputContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </DynamicInputContext.Provider>
  );
};

export const useDynamicInputContext = () => {
  const context = useContext(DynamicInputContext);
  assert(!!context, new Error(`useDynamicInputContext should be used within DynamicInputProvider`), 'ERROR');

  return context;
};
