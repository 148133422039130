import { JobType, Timezone, useGetTimezones } from '@novaera/actioner-service';
import { NvEventRepeatIcon, NvTimerOutlinedIcon, TypeOfTab } from '@novaera/core';
import { useLocation, useNavigate, useParams, useQueryParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert, getClientTimezoneId, usePathCondition } from '@novaera/utils';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { USER_APP_JOBS_RECURRING, USER_APP_JOBS_SCHEDULED } from '../../../constants';
import { RecurringJobs } from '../../recurring-jobs';
import { ScheduledJobs } from '../../scheduled-jobs';

export const useJobs = () => {
  const { userAppId } = useParams();
  const { data, isLoading: isTimezonesLoading } = useGetTimezones();
  const initialSelectedTimezoneId = getClientTimezoneId();
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { getSearchParams, removeQueryParams, addQueryParam } = useQueryParams();
  const { jobId, jobType } = getSearchParams<{ jobId?: string; jobType?: JobType }>() || {};

  useEffect(() => {
    // set initial timezone if it is not selected.
    if (!selectedTimezone) {
      const initialTimezone = data?.timezones.find((t) => t.id === initialSelectedTimezoneId);
      initialTimezone && setSelectedTimezone(initialTimezone);
    }
  }, [data?.timezones, initialSelectedTimezoneId, selectedTimezone]);

  usePathCondition([
    {
      do: () => {
        assert(
          !!jobId && !!jobType,
          new Error('[useJobs/usePathCondition jobId and jobType can not be undefined]'),
          'ERROR'
        );
        handleJobEditClick({ id: jobId, type: jobType });
      },
      when: () => {
        return !!jobId && !!jobType;
      },
    },
  ]);

  const { palette } = useTheme();

  const addJobPaperProps = {
    style: {
      background: palette.nv_neutral[0],
      border: `1px solid ${palette.nv_neutral[0]}`,
      boxShadow: '0px 0px 0px 1px #EBEEF2, 0px 15px 25px -2px rgba(10, 45, 98, 0.08)',
      borderRadius: 12,
      marginTop: 8,
      padding: 6,
      width: 240,
    },
  };

  const handleJobEditClick = useCallback(
    ({ id, type }: { id: string; type: JobType }) => {
      addQueryParam('jobId', id);
      addQueryParam('jobType', type);
    },
    [addQueryParam]
  );

  const handleAddNewRecurringJobClick = useCallback(() => {
    addQueryParam('jobType', JobType.RECURRING);
  }, [addQueryParam]);

  const handleAddNewScheduledJobClick = useCallback(() => {
    addQueryParam('jobType', JobType.SCHEDULED);
  }, [addQueryParam]);

  const tabs = useMemo(
    () => [
      {
        label: 'Recurring jobs',
        value: 'recurring',
        icon: <NvEventRepeatIcon sx={{ width: '16px', height: '16px' }} />,
        iconPosition: 'start' as TypeOfTab['iconPosition'],
        content: (
          <RecurringJobs
            timezoneId={selectedTimezone?.id}
            handleAddClick={handleAddNewRecurringJobClick}
            handleEditClick={handleJobEditClick}
          />
        ),
        url: USER_APP_JOBS_RECURRING(userAppId),
      },
      {
        label: 'Scheduled jobs',
        value: 'scheduled',
        icon: <NvTimerOutlinedIcon sx={{ width: '16px', height: '16px' }} />,
        iconPosition: 'start' as TypeOfTab['iconPosition'],
        content: (
          <ScheduledJobs
            timezoneId={selectedTimezone?.id}
            handleAddClick={handleAddNewScheduledJobClick}
            handleEditClick={handleJobEditClick}
          />
        ),
        url: USER_APP_JOBS_SCHEDULED(userAppId),
      },
    ],
    [handleAddNewRecurringJobClick, handleAddNewScheduledJobClick, handleJobEditClick, selectedTimezone?.id, userAppId]
  );

  const urls = useMemo(() => {
    return tabs.map((tab) => tab.url);
  }, [tabs]);

  const urlMap = useMemo(() => {
    return urls.reduce<Record<string, number>>((previousValue, url, currentIndex) => {
      return { ...previousValue, [url]: currentIndex };
    }, {});
  }, [urls]);

  const handleOnTabsChange = (selectedTabIndex: number) => {
    const url = urls[selectedTabIndex];
    navigate(url ?? urls[0]);
  };

  const activeTabIndex = useMemo(() => {
    return urlMap[pathname];
  }, [pathname, urlMap]);

  const handleSaveJobModalClose = () => {
    removeQueryParams('jobId');
    removeQueryParams('jobType');
  };

  const handleTimezoneChange = (event: SyntheticEvent<Element, Event>, value: Timezone | null) => {
    value && setSelectedTimezone(value);
  };

  return {
    tabs,
    handleOnTabsChange,
    activeTabIndex,
    userAppId,
    timezones: data?.timezones ?? [],
    isTimezonesLoading,
    selectedTimezone,
    addJobPaperProps,
    handleAddNewRecurringJobClick,
    handleAddNewScheduledJobClick,
    handleSaveJobModalClose,
    jobId,
    jobType,
    handleTimezoneChange,
  };
};
