import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_JOBS } from '../keys';
import { JobType } from '../types';
import { GetRecurringJobsResponse } from '../use-get-recurring-jobs/types';
import { GetScheduledJobsResponse } from '../use-get-scheduled-jobs/types';

import { DeleteJobParams } from './types';

const deleteJob = async ({ appId, jobId }: DeleteJobParams) => {
  const result = await NvAxios.delete(`/v2/apps/${appId}/jobs/${jobId}`);
  return result?.data;
};

export const useDeleteJob = ({ timezoneId }: { timezoneId?: string }) => {
  const queryClient = useQueryClient();

  return useMutation(deleteJob, {
    onSuccess: (_, { jobId, appId, type }) => {
      if (type === JobType.RECURRING) {
        const cachedRecurringJobs = queryClient.getQueryData<GetRecurringJobsResponse>(
          QUERY_KEYS_JOBS.list(appId, type, timezoneId)
        );
        if (cachedRecurringJobs) {
          queryClient.setQueryData<GetRecurringJobsResponse>(QUERY_KEYS_JOBS.list(appId, type, timezoneId), {
            ...cachedRecurringJobs,
            jobs: cachedRecurringJobs.jobs.filter(({ id }) => id !== jobId),
          });
        } else {
          queryClient.invalidateQueries(QUERY_KEYS_JOBS.list(appId, type, timezoneId));
        }
      }

      if (type === JobType.SCHEDULED) {
        const cachedScheduledData = queryClient.getQueryData<InfiniteData<GetScheduledJobsResponse>>(
          QUERY_KEYS_JOBS.list(appId, type, timezoneId)
        );

        if (cachedScheduledData) {
          queryClient.setQueryData<InfiniteData<GetScheduledJobsResponse>>(
            QUERY_KEYS_JOBS.list(appId, type, timezoneId),
            {
              pages:
                cachedScheduledData.pages.map((page) => ({
                  ...page,
                  jobs: page.jobs.filter((j) => j.id !== jobId),
                })) ?? [],
              pageParams: cachedScheduledData.pageParams,
            }
          );
        } else {
          queryClient.invalidateQueries(QUERY_KEYS_JOBS.list(appId, type, timezoneId));
        }
      }
    },
  });
};
