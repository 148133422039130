import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_DOCS } from '../keys';
import { ListDocsResponse } from '../use-list-docs/types';

export const useUpdateInfiniteSearchCache = () => {
  const cache = useQueryClient();

  const updateCache = ({
    appId,
    updateFunction,
  }: {
    appId: string;
    updateFunction: (param: { old?: InfiniteData<ListDocsResponse> }) => ListDocsResponse[];
  }) => {
    cache.setQueryData<InfiniteData<ListDocsResponse>>(QUERY_KEYS_DOCS.list(appId), (old) => {
      if (old) {
        return {
          ...old,
          pages: updateFunction({
            old,
          }),
        };
      }
      return old;
    });
  };

  return {
    updateInfiniteSearchCache: updateCache,
  };
};
