import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomSortUpIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1425_116)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29153 17.7825V8.7725H8.09153C8.53153 8.7725 8.76153 8.2325 8.44153 7.9225L5.65153 5.1425C5.45153 4.9525 5.14153 4.9525 4.94153 5.1425L2.15153 7.9225C1.83153 8.2325 2.05153 8.7725 2.50153 8.7725H4.29153L4.29154 17.7825C4.29154 18.3325 4.74153 18.7825 5.29153 18.7825C5.84153 18.7825 6.29153 18.3325 6.29153 17.7825ZM20.0002 8H10.5075C10.4889 7.97298 10.467 7.94702 10.4417 7.9225L8.51225 6H20.0002C20.5502 6 21.0002 6.45 21.0002 7C21.0002 7.55 20.5502 8 20.0002 8ZM16.5002 13H8.29167V11H16.5002C17.0502 11 17.5002 11.45 17.5002 12C17.5002 12.55 17.0502 13 16.5002 13ZM8.29167 16L8.29167 17.7825C8.29167 17.8571 8.28338 17.9299 8.26769 18H13.0002C13.5502 18 14.0002 17.55 14.0002 17C14.0002 16.45 13.5502 16 13.0002 16H8.29167Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1425_116">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
