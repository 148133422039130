import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { QUERY_KEY_GET_SLACK_APP } from '../keys';

import { DeleteSlackAppParams, DeleteSlackAppResponse } from './types';

const deleteSlackApp = async ({ appId, deleteSlackAppRequestParams }: DeleteSlackAppParams) => {
  const result = await NvAxios.delete<DeleteSlackAppResponse>(SLACK_APP_ROOT_PATH(appId), {
    data: deleteSlackAppRequestParams,
  });
  return result?.data;
};

export const useDeleteSlackApp = (appId?: string) => {
  const cache = useQueryClient();
  return useMutation(deleteSlackApp, {
    onSuccess: () => {
      cache.removeQueries(QUERY_KEY_GET_SLACK_APP.detail(appId));
    },
  });
};
