import { SlackBlockTypes } from '@novaera/actioner-service';
import { Markdown, NvFlex, NvTypography } from '@novaera/core';
import { isUndefined } from 'lodash';
import { FC } from 'react';
import { ErrorBoundary } from '../../../../../../../../../../../error-boundary';
import { WorkflowReferenceButton } from '../../workflow-reference-button';
import { BlocksViewerProps } from './types';

export const BlocksViewer: FC<React.PropsWithChildren<BlocksViewerProps>> = ({ blocks, appId }) => {
  return (
    <ErrorBoundary>
      <NvFlex direction="column" rowGap="8px">
        {blocks.map((block, index) => {
          return block.type === SlackBlockTypes.MARKDOWN ? (
            !isUndefined(block.value.value) ? (
              <Markdown key={`block_key_${index}`}>{block.value.value}</Markdown>
            ) : (
              <NvTypography variant="c1">Cannot calculate value</NvTypography>
            )
          ) : (
            block.type === SlackBlockTypes.WORKFLOW_REFERENCES && (
              <NvFlex direction="row" columnGap="8px">
                {block.elements.map((element) => (
                  <WorkflowReferenceButton
                    workflowReference={element}
                    key={`block_key_${element.id}`}
                    callerAppId={appId}
                  />
                ))}
              </NvFlex>
            )
          );
        })}
      </NvFlex>
    </ErrorBoundary>
  );
};
