import { useUpdateAppDependency } from '@novaera/actioner-service';

export const useAddAppDependency = () => {
  const { mutate: addAppDependency, isLoading: isAddDependencyLoading } = useUpdateAppDependency();

  return {
    addAppDependency,
    isAddDependencyLoading,
  };
};
