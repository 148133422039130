import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const FeaturedSectionBox = styled(NvFlex)`
  position: absolute;
  right: 40px;
  bottom: 12px;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: 991px) {
    right: 32px;
  }

  @media screen and (max-width: 767px) {
    top: 20px;
    bottom: auto;
    right: 24px;
  }
`;
