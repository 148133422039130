import { isAxiosError, NvAxios, useQuery } from '@novaera/core';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';
import { Connection } from '../types';
import { UseGetConnectionType } from './types';

const getConnection = async ({ connectionId }: UseGetConnectionType) => {
  const result = await NvAxios.get<Connection>(`${CONNECTION_ROOT_PATH}/${connectionId}`);
  return result?.data;
};

export const useGetConnectionService = (params: UseGetConnectionType) => {
  return useQuery<Connection>(QUERY_KEYS_CONNECTION.detail(params.connectionId), () => getConnection(params), {
    enabled: !!params.connectionId,
    retry(failureCount, error) {
      const isAxios = isAxiosError(error);
      const isNotFound = isAxios && error.response?.status === 404;
      return !isNotFound;
    },
    onError: (error) => {
      const isAxios = isAxiosError(error);
      const isNotFound = isAxios && error.response?.status === 404;
      if (!isNotFound) {
        throw error;
      }
    },
  });
};
