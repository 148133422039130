import { NvErrorIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import cronstrue from 'cronstrue';
import { useMemo } from 'react';
import { CronDisplayProps } from './types';

export const CronDisplay: React.FC<React.PropsWithChildren<CronDisplayProps>> = ({
  cron,
  showErrors = false,
  errorCallback,
}) => {
  const theme = useTheme();
  const cronHelper = useMemo(() => {
    try {
      errorCallback?.(null);
      return {
        preview: cronstrue.toString(cron, {
          dayOfWeekStartIndexZero: false,
          use24HourTimeFormat: true,
          throwExceptionOnParseError: showErrors,
        }),
        error: null,
      };
    } catch (error) {
      const errorString = `${error}`;
      errorCallback?.(errorString);
      return { preview: null, error: errorString };
    }
  }, [cron, errorCallback, showErrors]);

  return (
    <>
      {cronHelper.preview && (
        <NvTypography variant="body2" textColor="secondary">
          {cronHelper.preview}
        </NvTypography>
      )}
      {cronHelper.error && (
        <NvFlex direction="row" gap="4px" alignItems="center">
          <NvErrorIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_error[40]} />
          <NvTypography variant="body2" textColor="secondary">
            {cronHelper.error}
          </NvTypography>
        </NvFlex>
      )}
    </>
  );
};
