import { useUpdateAssistantApiKey } from '@novaera/actioner-service';
import {
  FormHelperWarningText,
  NvAddBoxIcon,
  NvButton,
  NvDialogModal,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  SectionMessage,
  isRequired,
  useNvDialogModalState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import React from 'react';
import { AIAssistantSection } from '../section';
import { ApiKeyProps } from './types';

export const ApiKey: React.FC<ApiKeyProps> = ({ maskedApiKey, unsavedApiKeyExists }) => {
  const { userAppId } = useParams();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { mutate: updateAssistantApiKey } = useUpdateAssistantApiKey();
  const handleApiKeyUpdate = async (value: string) => {
    return new Promise<void>((resolve) => {
      updateAssistantApiKey(
        { appId: userAppId, apiKey: value },
        {
          onSuccess: () => {
            onModalCloseClicked();
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <AIAssistantSection
      title="Use your own OpenAI Key"
      infoText={
        <>
          By default, Actioner utilizes its own OpenAI instance. Alternatively, users have the option to integrate their
          personal OpenAI account within Actioner, with charges applied according to the selected language model. Visit
          the <a href="https://actioner.com/help/node-execution-and-credit-usage">OpenAI pricing page</a> for further
          details.
        </>
      }
    >
      {maskedApiKey && maskedApiKey.length > 0 ? (
        <NvFlex gap="4px" width="100%" maxWidth="650px">
          <NvFlex direction="row" gap="8px" alignItems="center" width="100%">
            <NvTextField disabled value={maskedApiKey} fullWidth />
            <NvButton color="secondary" onClick={onModalOpenClicked} size="small">
              Edit
            </NvButton>
            <NvButton color="secondary" onClick={() => handleApiKeyUpdate('')} size="small">
              Delete
            </NvButton>
          </NvFlex>
          {unsavedApiKeyExists && (
            <FormHelperWarningText
              error={'The OpenAI Key is saved, but you need to update the AI assistant to reflect this change.'}
            />
          )}
        </NvFlex>
      ) : (
        <NvFlex gap="12px">
          {unsavedApiKeyExists && (
            <SectionMessage
              message="The OpenAI Key is deleted, but you need to update the AI assistant to reflect this change."
              variant="info"
            />
          )}

          <NvButton color="secondary" onClick={onModalOpenClicked} size="small" startIcon={<NvAddBoxIcon />}>
            Add OpenAI key
          </NvButton>
        </NvFlex>
      )}
      <NvDialogModal<{ apiKey: string }>
        open={isOpened}
        formProps={{ initialValues: { apiKey: maskedApiKey ?? '' } }}
        onFormSubmit={({ apiKey }) => {
          handleApiKeyUpdate(apiKey);
        }}
        onCloseButtonClicked={onModalCloseClicked}
        Header="OpenAI API Key"
        maxWidth="md"
        modalIcon="add"
        primaryButtonText={maskedApiKey && maskedApiKey.length > 0 ? 'Edit' : 'Add'}
        Body={
          <NvFlex gap="8px">
            <NvTypography variant="body2">
              You can find your OpenAI API key by accessing your account settings on the OpenAI website from{' '}
              <a href="https://platform.openai.com/account/api-keys" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </NvTypography>
            <NvField
              name={'apiKey'}
              labelText="API key"
              direction="label-on-top"
              validators={[isRequired()]}
              hasRequiredIndicator
              showErrorMessageOnlyWhenBlur
              component={<NvTextField />}
            />
          </NvFlex>
        }
      />
    </AIAssistantSection>
  );
};
