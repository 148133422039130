import { NvTabs, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { BodyItem } from '../../item';

import { useUrlAndParameters } from './controllers/use-url-and-parameters';

export const UrlAndParameters = () => {
  const { parameterTabs, description } = useUrlAndParameters();

  const { palette } = useTheme();

  return (
    <BodyItem
      title="URL and Request parameters"
      description={
        <>
          {description}
          <NvTypography
            variant="c2"
            component="span"
            sx={{ background: palette.nv_neutral_alpha[20] }}
          >{`{{auth.field_key}}`}</NvTypography>
          .
        </>
      }
    >
      <NvTabs tabs={parameterTabs}></NvTabs>
    </BodyItem>
  );
};
