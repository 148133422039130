import { NvFlagger, NvOpenInNewIcon, NvTypography } from '@novaera/core';
import { useCloneFlaggerController } from './controllers/use-clone-controller';
import { CloneFlaggerBodyWrapper, CloneFlaggerButton, CloneFlaggerCheckIcon, CloneFlaggerLine } from './styled';
import { CloneFlaggerParams } from './types';

export const CloneFlagger = (params: CloneFlaggerParams) => {
  const { message, onFlaggerOpenClicked } = useCloneFlaggerController({
    type: params.type,
    clonedItem: params.clonedItem,
    onOpenClicked: params.onOpenClicked,
  });
  return (
    <NvFlagger in={params.opened} onExited={params.onFlaggerClosed}>
      <CloneFlaggerBodyWrapper>
        <CloneFlaggerCheckIcon />
        <NvTypography sx={{ minWidth: '110px', maxWidth: '150px' }} variant="h4" noWrap>
          {message}
        </NvTypography>
        <CloneFlaggerLine />
        <CloneFlaggerButton
          onClick={onFlaggerOpenClicked}
          color="inherit"
          startIcon={<NvOpenInNewIcon fontSize="small" sx={{ marginRight: '8px' }} />}
        >
          Open
        </CloneFlaggerButton>
      </CloneFlaggerBodyWrapper>
    </NvFlagger>
  );
};
