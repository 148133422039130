import { ValidatorFunction } from '@novaera/core';

export const isActionNameValid: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? 'Action name can only contain letters, numbers, space and "_,(,),&,-,/" characters';

  //create regex to validate "name" to be alphanumeric and can contain spaces and can contain _,(,),&,-,/
  const nameRegex = /^[a-zA-Z0-9\\._,&*()/\s//-]+$/;

  const isValid = nameRegex.test(value);
  return isValid ? undefined : message;
};
