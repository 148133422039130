import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';

export const InitialContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.smaller {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  &.small {
    width: 24px;
    height: 24px;
    border-radius: 6px;
  }
  &.medium {
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }

  &.large {
    width: 40px;
    height: 40px;
    border-radius: 16px;
  }
  &.xlarge {
    width: 60px;
    height: 60px;
    border-radius: 16px;
  }
  flex: 0 0 auto;
`;

const Gradient = styled('div', { shouldForwardProp: shouldForwardPropForFormFields })<{ gradientColor: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${({ gradientColor }) => gradientColor};

  border-radius: 8px;
`;

export const GradientMain = styled(Gradient)``;
export const GradientBlur = styled(Gradient)`
  z-index: 1;

  &.small {
    filter: blur(4px);
    opacity: 0.2;
  }

  &.medium {
    filter: blur(4px);
    opacity: 0.2;
  }

  &.large {
    filter: blur(8px);
    opacity: 0.4;
  }
`;

const Initial = styled('div')`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 600;

  line-height: 22px;
`;
export const InitialMain = styled(Initial)`
  left: 1px;
  z-index: 5;
`;
export const InitialOpacity = styled(Initial)`
  left: -1px;
  z-index: 4;
  opacity: 0.3;
`;
