import {
  LinkGeneratorConfiguration,
  LinkGeneratorNode,
  ParameterMappings,
  UIComponentType,
  useGetWorkflow,
  useUpdateNode,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvField,
  NvFlex,
  NvForm,
  NvPlayArrowIcon,
  NvSelect,
  NvSwitch,
  NvTypography,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { OutputItem } from '../../../../../../../../components/output-item';
import { FormIdentifierProvider } from '../../../../../../../../providers/form-identifier-provider';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetAppIdFromDependency } from '../../../../common/workflow-select-component/controllers/use-get-app-id-from-dependency';
import { WorkflowSelect } from '../../../../components/workflow-select';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { WorkflowInputs } from '../../action-node-property-panel-drawer/action-node-properties/workflow-inputs';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { LINK_GENERATOR_EXAMPLE_PAYLOAD } from './constants';
import { NodePropertiesProps } from './types';

export const NodeProperties: FC<NodePropertiesProps> = ({ onCloseClicked, node }) => {
  assert(
    !!node,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );
  const [formId, setFormId] = useState<string | undefined>();

  const { userAppId, workflowId } = useGetWorkflowQueryParams();

  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const { hasEditPermission } = useWorkflowPermission();

  const { mutate: updateNodeDetail } = useUpdateNode();

  const { getAppIdFromDependency } = useGetAppIdFromDependency({
    rootAppID: userAppId,
    mode: 'sync',
  });

  const initialNode = useMemo<LinkGeneratorNode>(
    () =>
      node.formConfiguration
        ? node
        : {
            ...node,
            errorHandlingStrategy: { type: 'simple', continueOnError: false },
            formConfiguration: {
              type: 'static',
              appId: userAppId,
              workflowId: '',
              blocking: true,
              parameterMappings: [],
            },
          },
    [userAppId, node]
  );

  const { savedWorkflow } = useGetWorkflow({
    appId:
      node.formConfiguration?.type === 'static'
        ? node.formConfiguration.appId
        : node.formConfiguration?.type === 'dependency'
        ? getAppIdFromDependency(node.formConfiguration.dependencyId)
        : userAppId,
    workflowId: node.formConfiguration?.type !== 'dynamic' ? node.formConfiguration?.workflowId : undefined,
  });

  const hasDynamicInputParameters = useMemo(
    () =>
      savedWorkflow?.trigger?.type === 'form' &&
      savedWorkflow.trigger.inputParameters.find(
        (inputParameter) => inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT
      ),
    [savedWorkflow?.trigger]
  );

  const handleSave = useCallback(
    (values: LinkGeneratorNode) => {
      if (!hasEditPermission) {
        return;
      }
      const { formConfiguration, errorHandlingStrategy, ...rest } = values;
      if (formConfiguration) {
        const { formConfiguration: prevformConfiguration } = node;
        const hasChangedWorkflowId =
          prevformConfiguration?.type !== 'dynamic' &&
          formConfiguration?.type !== 'dynamic' &&
          prevformConfiguration?.workflowId !== formConfiguration?.workflowId;

        const formConfigurationPayload: LinkGeneratorConfiguration =
          formConfiguration?.type === 'dynamic'
            ? {
                type: 'dynamic',
                appIdTemplate: formConfiguration.appIdTemplate,
                workflowIdTemplate: formConfiguration.workflowIdTemplate,
                inputTemplate: formConfiguration.inputTemplate,
                blocking: formConfiguration.blocking,
              }
            : {
                ...formConfiguration,
                blocking: formConfiguration.blocking,
                ...(savedWorkflow?.trigger?.type === 'form'
                  ? {
                      parameterMappings: hasChangedWorkflowId ? [] : formConfiguration.parameterMappings,
                      ...(hasDynamicInputParameters && { formId }),
                    }
                  : { inputTemplate: formConfiguration.inputTemplate }),
              };
        updateNodeDetail({
          appId: userAppId,
          workflowId,
          nodeAlias: node.alias,
          payload: {
            ...rest,
            formConfiguration: formConfigurationPayload,
            ...(formConfigurationPayload.blocking && { errorHandlingStrategy }),
          },
        });
      }
    },
    [
      hasEditPermission,
      node,
      userAppId,
      savedWorkflow?.trigger?.type,
      hasDynamicInputParameters,
      formId,
      updateNodeDetail,
      workflowId,
    ]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: node.alias,
          payload: { ...node, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(node.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [node, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvFlex width="100%">
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          title={node?.name}
          type={NodeType.LINK_GENERATOR}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={node?.name}
          type={NodeType.LINK_GENERATOR}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvForm
        initialValues={initialNode}
        onSubmit={noop}
        onChange={({ values }) => {
          handleSave(values);
        }}
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        <NvConditionalRender when={!hasEditPermission}>
          <PropertyPanelHeader
            title={node?.name}
            type={NodeType.LINK_GENERATOR}
            actions={
              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            }
          />
        </NvConditionalRender>

        <PropertyPanelSimpleSection>
          <WorkflowSelect
            hasMultipleAppSupport
            context={workflowCodeInputContext}
            isDynamic={node.formConfiguration?.type === 'dynamic'}
            typeFieldName="formConfiguration.type"
            dynamicFieldAppIdFieldName="formConfiguration.appIdTemplate"
            dynamicFieldWorkflowIdFieldName="formConfiguration.workflowIdTemplate"
            staticFieldWorkflowIdFieldName="formConfiguration.workflowId"
            staticFieldAppIdFieldName="formConfiguration.appId"
            staticFieldDependencyIdFieldName="formConfiguration.dependencyId"
          />
        </PropertyPanelSimpleSection>
        {node.formConfiguration?.type !== 'dynamic' &&
          node.formConfiguration?.workflowId &&
          savedWorkflow?.trigger?.type === 'form' && (
            <FormIdentifierProvider initialFormId={node.formConfiguration.formId}>
              <NvField<ParameterMappings>
                formControlStyle={{ width: '100%' }}
                name="formConfiguration.parameterMappings"
                component={({ value, onChange }) => {
                  return (
                    <WorkflowInputs
                      userAppId={savedWorkflow.appId}
                      workflowId={savedWorkflow.id}
                      initialParameterMappings={value || []}
                      onChange={({ parameterMappings, formId }) => {
                        setFormId(formId);
                        onChange(parameterMappings);
                      }}
                      context={workflowCodeInputContext}
                    />
                  );
                }}
              />
            </FormIdentifierProvider>
          )}
        {((node.formConfiguration?.type !== 'dynamic' &&
          node.formConfiguration?.workflowId &&
          savedWorkflow?.trigger?.type === 'genericWebhook') ||
          node.formConfiguration?.type === 'dynamic') && (
          <>
            <PropertyPanelListHeader
              title={savedWorkflow?.trigger?.type === 'genericWebhook' ? 'Workflow event' : 'Workflow input template'}
              tooltip={
                savedWorkflow?.trigger?.type === 'genericWebhook'
                  ? 'You need to provide the payload to be used as the workflow trigger payload during runtime.'
                  : 'You need to provide workflow input values in JSON format.'
              }
            />
            <PropertyPanelSimpleSection>
              <NvField
                formControlStyle={{ width: '100%' }}
                name="formConfiguration.inputTemplate"
                hint={
                  <NvTypography variant="body3" textColor="ghost">
                    Supports only JSON format.
                  </NvTypography>
                }
                hideLabel
                component={<CodeInput context={workflowCodeInputContext} growingHeight initialHeight="238px" />}
              />
            </PropertyPanelSimpleSection>
          </>
        )}
        {node.formConfiguration && (
          <>
            <PropertyPanelSimpleSection>
              <NvBox width={'100%'}>
                <NvField<boolean>
                  formControlStyle={{ width: '100%' }}
                  name="formConfiguration.executeImmediately"
                  component={({ onChange, checked, value }) => {
                    return (
                      <NvFlex flexDirection={'row'} alignItems={'center'}>
                        <NvFlex direction={'row'} gap={'8px'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
                          <NvPlayArrowIcon />
                          <NvTypography variant="body2">Run immediately</NvTypography>
                        </NvFlex>
                        <NvSwitch value={value} onChange={onChange} checked={value} variant="compact" />
                      </NvFlex>
                    );
                  }}
                />
              </NvBox>
            </PropertyPanelSimpleSection>
            <PropertyPanelSection
              collapsible
              title="Process type"
              tooltip="For running other nodes without waiting for the workflow result, select async option. For waiting and running other nodes after completion, choose sync option."
            >
              <NvField
                formControlStyle={{ width: '200px' }}
                name="formConfiguration.blocking"
                component={
                  <NvSelect<boolean>
                    renderValue={(value) => {
                      if (value) {
                        return (
                          <NvTypography variant="body2" component="div">
                            Synchronously{' '}
                            <NvTypography variant="body2" component="span" textColor="ghost">
                              (default)
                            </NvTypography>
                          </NvTypography>
                        );
                      } else {
                        return <NvTypography variant="body2">Asynchronously</NvTypography>;
                      }
                    }}
                    options={[
                      { label: 'Synchronously', value: true },
                      { label: 'Asynchronously', value: false },
                    ]}
                  />
                }
              />
            </PropertyPanelSection>
          </>
        )}

        <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <OutputItem content={LINK_GENERATOR_EXAMPLE_PAYLOAD} isTestResultFailed={false} />
        </PropertyPanelSimpleSection>

        <NvField name="errorHandlingStrategy" component={<ErrorHandlingStrategyPanel />} />
      </NvForm>
    </NvFlex>
  );
};
