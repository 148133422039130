import { NvFlex } from '@novaera/core';
import { FC } from 'react';
import { WorkflowCanvas } from './user-app-workflow-canvas';
import { AddNewNodeProvider } from './user-app-workflow-canvas/add-new-node-popper/provider';
import { NodeMoreProvider } from './user-app-workflow-canvas/node-more-popper';

export const WorkflowDesigner: FC<React.PropsWithChildren<{ isSaveProgress: boolean }>> = ({
  isSaveProgress = false,
}) => {
  return (
    <NvFlex width="100%" height="100%" position="relative" flex="1 1 auto" minHeight="0">
      <NodeMoreProvider>
        <AddNewNodeProvider>
          <WorkflowCanvas isSaveProgress={isSaveProgress} />
        </AddNewNodeProvider>
      </NodeMoreProvider>
    </NvFlex>
  );
};
