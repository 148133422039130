import { useGetConnectionSchemaService, useGetIntegration } from '@novaera/actioner-service';
import { useGetIntegrationQueryParams } from '../../../../../controllers/use-get-integration-query-params';

export const useGetConnectionController = () => {
  const { integrationId } = useGetIntegrationQueryParams();

  const { data } = useGetIntegration({ id: integrationId });

  const { data: connection, isInitialLoading: isGetConnectionLoading } = useGetConnectionSchemaService({
    integrationId,
    schemaId: data?.connectionSchemaId,
  });

  return {
    hasConnection: !!connection,
    connection,
    isGetConnectionLoading,
    isGlobalSchema: connection?.scope.type === 'global',
  };
};
