import { LAST_ACTIVE_WORKSPACE, LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { NvAxios } from '@novaera/core';
import { getSubdomain } from '@novaera/utils';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { BaseRoute } from '../base-route';
import { PrivateRouteProps } from './types';

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, LoadingComponent }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (NvAxios.defaults as any).isPublic = false;

  useEffect(() => {
    const subdomain = getSubdomain();
    const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);
    const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);
    if (subdomain.length === 0 && !!accessToken && !!lastActiveWorkspace) {
      const urlWithSubdomain = `${window.location.protocol}//${lastActiveWorkspace}.${window.location.host}${window.location.pathname}`;
      window.location.href = urlWithSubdomain;
    }
  }, []);

  return <BaseRoute children={children} LoadingComponent={LoadingComponent} />;
};
