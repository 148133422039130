import { DefaultDisplayValue, NvCheckbox, NvFlex, NvTypography } from '@novaera/core';
import { isUndefined } from 'lodash';
import { CheckBoxComponentParams } from '../use-ui-component/types';
import { CheckboxContainer } from './styled';

export const CheckboxComponent = ({ inputParameter: { uiComponent, schema }, inputProps }: CheckBoxComponentParams) => {
  const { hint, label } = uiComponent;

  return (
    <CheckboxContainer
      onClick={() => {
        inputProps.onChange(!inputProps.value);
      }}
    >
      <NvCheckbox {...inputProps} checked={inputProps.value} />
      <NvFlex gap="2px">
        <NvTypography variant="h5" sx={{ wordBreak: 'break-word' }}>
          {label}
        </NvTypography>
        {hint && <NvTypography variant="body3">{hint}</NvTypography>}
      </NvFlex>

      {!isUndefined(schema?.defaultValue?.value) && (
        <NvFlex flex="0 0 auto" alignItems="center" direction="row" gap="4px">
          <DefaultDisplayValue tooltipTitle={`Default value: ${schema?.defaultValue?.value}`} text="Default value" />
        </NvFlex>
      )}
    </CheckboxContainer>
  );
};
