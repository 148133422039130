/**
 * This message comes from backend when token is expired with 401 status code.
 * So, we will catch that message and send a req to get new token.
 */
export const TOKEN_EXPIRED_MESSAGE = 'Token expired';

export const ENDPOINT_DEFAULTS = {
  REFRESH_TOKEN: '/v1/auth/refresh-tokens',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).TokenState = {
  expired: false,
  isGettingNewToken: false,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TokenState = (window as any).TokenState;
export { TokenState };

export const AUTHORIZATION_HEADER = 'Authorization';
export const SUBDOMAIN_HEADER = 'Workspace';

export const REFETCH_INTERVAL = 240000;
