import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomConditionIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2201 13.04L15.6401 11.62C16.3201 12.31 16.9801 13.2 17.4301 14.56L15.4901 15.05C15.1701 14.16 14.7201 13.55 14.2201 13.04ZM13.8501 19.05L16.6501 21.85C16.8501 22.05 17.1601 22.05 17.3601 21.85L20.1501 19.05C20.4601 18.74 20.2401 18.2 19.7901 18.2L17.9801 18.2C17.9601 17.39 17.9001 16.66 17.7901 16.03L15.8501 16.52C15.9201 17 15.9701 17.57 15.9801 18.2L14.2001 18.2C13.7601 18.2 13.5401 18.74 13.8501 19.05ZM3.85006 19.05L6.64006 21.84C6.84006 22.04 7.15006 22.04 7.35006 21.84L10.1401 19.05C10.4601 18.74 10.2401 18.2 9.79006 18.2L8.01006 18.2C8.11006 14.52 9.29006 13.45 10.5501 12.32C11.0501 11.88 11.5601 11.4 12.0001 10.77C12.3401 11.26 12.7301 11.65 13.1301 12.01L14.5401 10.6C13.6101 9.75 13.0001 9.06 13.0001 7.2L13.0001 3.2C13.0001 2.65 12.5501 2.2 12.0001 2.2C11.4501 2.2 11.0001 2.65 11.0001 3.2L11.0001 7.2C11.0001 9.22 10.2901 9.86 9.21006 10.83C7.83006 12.07 6.13006 13.61 6.01006 18.2L4.21006 18.2C3.76006 18.2 3.54006 18.74 3.85006 19.05Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
