export const mixins = {
  toolbar: {
    '@media (min-width: 600px)': {
      minHeight: 60,
    },
  },
  focus: {
    //nv_main[30] 0.2 alpha
    boxShadow: '0px 0px 0px 4px rgba(157, 187, 250, 0.2)',
  },
  error: {
    //nv_error[30] 0.2 alpha
    boxShadow: '0px 0px 0px 4px rgba(255, 140, 115, 0.2)',
  },
  success: {
    //nv_success[30] 0.2 alpha
    boxShadow: '0px 0px 0px 4px rgba(153, 239, 192, 0.2)',
  },
  itemSelected: {
    //nv_main[30] 0.2 alpha
    boxShadow: '0px 0px 0px 8px rgba(157, 187, 250, 0.2)',
  },
};
