import {
  BlankVertex,
  BranchJunctionNodeSummary,
  BranchJunctionVertex,
  DefaultNodeSummary,
} from '@novaera/actioner-service';
import { EmptyInterface, NovaeraEdge, NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class BranchJunctionComponentFactory implements ComponentFactory<EmptyInterface> {
  aliasForBlank1: string;
  aliasForBlank2: string;
  rootAlias: string;
  rootName: string;

  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newAlias, newName } = graph.getNewAlias('branchJunction');
    const { newAlias: newAliasForBlank1 } = graph.getNewAlias('blank');
    const { newAlias: newAliasForBlank2 } = graph.getNewAlias('blank', [newAliasForBlank1]);

    this.rootAlias = newAlias;
    this.rootName = newName;
    this.aliasForBlank1 = newAliasForBlank1;
    this.aliasForBlank2 = newAliasForBlank2;
  }

  get componentForGraph() {
    const branchJunctionRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.rootName,
      width: 40,
      height: 40,
      id: this.rootAlias,
      type: 'branchJunction',
      alias: this.rootAlias,
    };

    const branchNode1: NovaeraNode<AppWorkflowNodeType> = {
      name: 'branch 1',
      width: 40,
      height: 40,
      id: this.aliasForBlank1,
      type: 'blank',
      alias: this.aliasForBlank1,
    };

    const branchNode2: NovaeraNode<AppWorkflowNodeType> = {
      name: 'branch 2',
      width: 40,
      height: 40,
      id: this.aliasForBlank2,
      type: 'blank',
      alias: this.aliasForBlank2,
    };

    const edge1: NovaeraEdge = {
      sourceId: this.rootAlias,
      targetId: branchNode1.alias,
      id: `${this.rootAlias}-${branchNode1.alias}`,
      extraData: { type: 'Edge', centerPosition: 'up', name: branchNode1.name },
    };

    const edge2: NovaeraEdge = {
      sourceId: this.rootAlias,
      targetId: branchNode2.alias,
      id: `${this.rootAlias}-${branchNode2.alias}`,
      extraData: { type: 'Edge', centerPosition: 'up', name: branchNode2.name },
    };

    return { root: branchJunctionRoot, branches: [branchNode1, branchNode2], edges: [edge1, edge2] };
  }

  componentForWorkflow() {
    const branch1Node: BlankVertex = {
      alias: this.aliasForBlank1,
      type: 'blank',
    };

    const branch2Node: BlankVertex = {
      alias: this.aliasForBlank2,
      type: 'blank',
    };

    const newNodeUnion: BranchJunctionVertex = {
      type: 'branchJunction',
      alias: this.rootAlias,
      firstInnerAliases: [branch1Node.alias, branch2Node.alias],
    };

    const branch1Summary: DefaultNodeSummary = {
      name: 'blank 1',
      alias: this.aliasForBlank1,
      type: 'blank',
    };
    const branch2Summary: DefaultNodeSummary = {
      name: `blank 2`,
      alias: this.aliasForBlank2,
      type: 'blank',
    };

    const rootSummary: BranchJunctionNodeSummary = {
      name: this.rootName,
      alias: this.rootAlias,
      type: 'branchJunction',
      branches: [{ name: 'branch 1' }, { name: 'branch 2' }],
    };
    const summaries = {
      [this.aliasForBlank1]: branch1Summary,
      [this.aliasForBlank2]: branch2Summary,
      [this.rootAlias]: rootSummary,
    };

    return { root: newNodeUnion, branches: [branch1Node, branch2Node], nodeSummaries: summaries };
  }
}
