import { NovaeraEdge } from '@novaera/core';
import { assert } from '@novaera/utils';
import { createContext, useCallback, useContext, useState } from 'react';
import { NodeProps } from '../../../../../../components/node/types';
import { DroppedOnNode, ItemOnDropped } from '../../utils/nodes-drag-and-drop/types';

type onDropFinished = (draggedNode: NodeProps, droppedTarget: ItemOnDropped) => void;

const WorkflowDragAndDropContext = createContext<
  | {
      draggedNode?: NodeProps;
      droppedTarget?: ItemOnDropped;
      isDragging: boolean;
      draggingItemProperties?: {
        nodeId: string;
        bannedDropItems: NovaeraEdge<string>[];
      };
      onDropFinished: onDropFinished;
      onDragStart?: (nodeId: string, bannedDropItems: NovaeraEdge<string>[]) => void;
      resetDroppedItem?: () => void;
      draggable?: boolean;
    }
  | undefined
>(undefined);

export const WorkflowDragAndDropProvider: React.FC<{
  onDropFinished: onDropFinished;
  draggable: boolean;
  children:
    | React.ReactNode
    | ((params: {
        draggedNode?: NodeProps;
        droppedTarget?: ItemOnDropped;
        onDropFinished: onDropFinished;
      }) => React.ReactNode);
}> = ({ children, onDropFinished, draggable }) => {
  const [draggedNode, setDraggedNode] = useState<NodeProps | undefined>(undefined);
  const [droppedTarget, setDroppedTarget] = useState<DroppedOnNode | undefined>(undefined);
  const [draggingItemProperties, setDraggingItemProperties] = useState<
    | {
        nodeId: string;
        bannedDropItems: NovaeraEdge<string>[];
      }
    | undefined
  >(undefined);
  const [isDragging, setIsDragging] = useState(false);

  const handleDropFinished = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (draggedNode: any, droppedTarget: any) => {
      onDropFinished(draggedNode, droppedTarget);
      setIsDragging(false);
    },
    [onDropFinished]
  );

  const handleDragStart = useCallback((nodeId: string, bannedDropItems: NovaeraEdge<string>[]) => {
    setIsDragging(true);
    setDraggingItemProperties({ nodeId, bannedDropItems });
  }, []);

  const resetDroppedItem = useCallback(() => {
    setDraggedNode(undefined);
    setDroppedTarget(undefined);
    setDraggingItemProperties(undefined);
    setIsDragging(false);
  }, []);

  return (
    <WorkflowDragAndDropContext.Provider
      value={{
        draggedNode,
        droppedTarget,
        onDropFinished: handleDropFinished,
        onDragStart: handleDragStart,
        isDragging,
        draggingItemProperties,
        resetDroppedItem,
        draggable,
      }}
    >
      {typeof children === 'function'
        ? children({ draggedNode, droppedTarget, onDropFinished: handleDropFinished })
        : children}
    </WorkflowDragAndDropContext.Provider>
  );
};

export const useWorkflowDragAndDropProvider = () => {
  const context = useContext(WorkflowDragAndDropContext);
  assert(
    !!context,
    new Error(`useWorkflowDragAndDropProvider should be used within WorkflowDragAndDropProvider`),
    'ERROR'
  );

  return context;
};
