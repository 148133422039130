import { NvArrowForwardIcon, NvButton, Step, StepWithNoButton, useField, useForm, useFormState } from '@novaera/core';
import { useMemo } from 'react';
import { CompleteStep } from '../../../complete-step';
import { ExportSlackAppFocusStateOption } from '../../types';
import { ExportSlackAppSubmitResolveProps } from '../../use-export-slack-app/types';
import { SlackAccessToken } from '../slack-access-token';
import { SlackAppDetail } from '../slack-app-detail';

export const useExportSlackAppBody = ({ type }: { type: ExportSlackAppFocusStateOption }) => {
  const { submit } = useForm<ExportSlackAppSubmitResolveProps>();
  const { hasValidationErrors: exportSlackAppFormHasValidationErrors, submitting } = useFormState();
  const {
    meta: { valid: slackConfigurationTokenValid },
  } = useField('slackConfigurationToken');

  const baseExportSlackAppSteps: Step[] = useMemo(
    () => [
      {
        label: 'Slack Access Token',
        content: <SlackAccessToken />,
        nextButton: ({ nextClick }) => {
          return (
            <NvButton
              disabled={!slackConfigurationTokenValid}
              endIcon={<NvArrowForwardIcon />}
              onClick={() => {
                nextClick();
              }}
            >
              Next
            </NvButton>
          );
        },
        showButtons: true,
      },
      {
        label: 'App details',
        content: <SlackAppDetail />,
        nextButton: ({ nextClick }) => {
          return (
            <NvButton
              disabled={exportSlackAppFormHasValidationErrors || submitting}
              endIcon={<NvArrowForwardIcon />}
              loading={submitting}
              onClick={async () => {
                const response = await submit();
                if (response?.status === 'Success') {
                  if (response?.type === ExportSlackAppFocusStateOption.UPDATE) {
                    nextClick();
                  }
                }
              }}
            >
              {type === ExportSlackAppFocusStateOption.UPDATE ? 'Update' : 'Create & install'}
            </NvButton>
          );
        },
        showButtons: true,
      },
    ],
    [exportSlackAppFormHasValidationErrors, slackConfigurationTokenValid, submit, submitting, type]
  );

  const exportSlackAppSteps: Step[] = useMemo(
    () => [
      ...baseExportSlackAppSteps,
      ...(type === ExportSlackAppFocusStateOption.UPDATE
        ? [
            {
              label: 'Complete update',
              content: () => <CompleteStep type={type} />,
              showButtons: false,
            } as StepWithNoButton,
          ]
        : []),
    ],
    [baseExportSlackAppSteps, type]
  );
  return { exportSlackAppSteps };
};
