import { useGetAppSchemaVersions } from '@novaera/actioner-service';
import {
  HorizontalLineWithIcon,
  NvButton,
  NvCloseIcon,
  NvDialog,
  NvDialogActions,
  NvDialogContent,
  NvDialogTitle,
  NvFlex,
  NvHistoryToggleOffIcon,
  NvTypography,
} from '@novaera/core';

import { VersionPublishedTime } from '../version-published-time';
import { VersionHistoryItem } from './styled';
import { AppVersionHistoryModalProps } from './types';

export const AppVersionHistoryModal: React.FC<React.PropsWithChildren<AppVersionHistoryModalProps>> = ({
  isOpen,
  onClose,
  schemaId,
}) => {
  const { data: schemaVersionList } = useGetAppSchemaVersions({ schemaId });

  return (
    <NvDialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <NvFlex direction="row">
        <HorizontalLineWithIcon icon={<NvHistoryToggleOffIcon />} />
        <NvFlex flex="1 1 auto" marginLeft="-24px">
          <NvDialogTitle>
            <NvTypography variant="h2" flex="1 1 auto">
              Version history
            </NvTypography>
            <NvButton onlyIcon size="small" color="secondary" onClick={onClose}>
              <NvCloseIcon />
            </NvButton>
          </NvDialogTitle>
          <NvDialogContent>
            <NvFlex spacing="12px">
              {schemaVersionList?.versions.map(({ version, publishedAt, changeLog }) => (
                <VersionHistoryItem key={version}>
                  <NvFlex direction="row" width="100%">
                    <NvTypography variant="h4" flex="1 1 auto">
                      v{version}
                    </NvTypography>
                    <VersionPublishedTime publishedAt={publishedAt} />
                  </NvFlex>
                  {changeLog && (
                    <NvTypography variant="body2" textColor="subtle">
                      {changeLog}
                    </NvTypography>
                  )}
                </VersionHistoryItem>
              ))}
            </NvFlex>
          </NvDialogContent>
          <NvDialogActions>
            <NvButton color="secondary" onClick={onClose}>
              Close
            </NvButton>
          </NvDialogActions>
        </NvFlex>
      </NvFlex>
    </NvDialog>
  );
};
