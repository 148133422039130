import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecordCellWrapper = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'isPopoverOpen' })<{
  isPopoverOpen?: boolean;
}>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${({ isPopoverOpen }) => (isPopoverOpen ? '.open-in-new-tab { opacity: 1; }' : null)}

  &:hover {
    .open-in-new-tab {
      opacity: 1;
    }
  }
`;

export const OpenInNewTabContainer = styled(NvBox)`
  position: absolute;
  top: 0px;
  right: -8px;
  height: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 100ms ease;
  flex-direction: row;
  padding: 8px 4px 8px 12px;
  background: linear-gradient(90deg, rgba(243, 243, 245, 0) 0, #f7f8f9 8px, #f7f8f9 100%);
`;

export const OpenInNewTabInnerContainer = styled(NvBox)`
  padding: 8px 4px 8px 12px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
`;

export const OpenNewTabGradient = styled(NvBox)`
  width: 8px;
  height: 32px;
  background: linear-gradient(270deg, #f7f8f9 0%, rgba(243, 243, 245, 0) 100%);
`;

export const OpenInNewTabBox = styled(NvBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.palette.nv_main[40]};
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 4px;
  cursor: pointer;
`;
