import { SkeletonProps } from '@mui/material/Skeleton';

export enum TextSkeletonSize {
  XSMALL = 'xs',
  SMALL = 's',
  MEDIUM = 'm',
  LARGE = 'l',
  XLARGE = 'xl',
}

export interface NvSkeletonProps extends SkeletonProps {
  size?: TextSkeletonSize;
}
