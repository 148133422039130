import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ROOT_PATH_SSO_PROVIDERS } from '../constants';
import { SSOProvider } from '../types';

type UpdateChangeSSOEnableStateParams = {
  body: {
    containerId: string;
    containerType: string;
    enabled: boolean;
  };
};

type UpdateChangeSSOEnableStateResponse = SSOProvider;

const changeSSOEnableState = async ({ body }: UpdateChangeSSOEnableStateParams) => {
  const result = await NvAxios.post<UpdateChangeSSOEnableStateResponse>(`${ROOT_PATH_SSO_PROVIDERS}/state`, {
    ...body,
  });
  return result?.data;
};

export const useChangeSSOEnableState = () => {
  const cache = useQueryClient();

  return useMutation(changeSSOEnableState, {
    onSuccess: () => {
      // Your onSuccess code here
    },
  });
};
