import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvSettingsIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.956 2.66602C17.9643 2.66602 18.8773 3.22602 19.3814 4.05268C19.6267 4.45268 19.7902 4.94602 19.7493 5.46602C19.7221 5.86602 19.8447 6.26602 20.0627 6.63935C20.7577 7.77268 22.2974 8.19935 23.4965 7.55935C24.8455 6.78602 26.5487 7.25268 27.3254 8.57268L28.2384 10.146C29.0287 11.466 28.5927 13.1593 27.2301 13.9193C26.0718 14.5993 25.6631 16.106 26.358 17.2527C26.576 17.6127 26.8213 17.9193 27.2028 18.106C27.6797 18.3593 28.0476 18.7593 28.3065 19.1593C28.8107 19.986 28.7698 20.9993 28.2793 21.8927L27.3254 23.4927C26.8213 24.346 25.8811 24.8793 24.9136 24.8793C24.4367 24.8793 23.9053 24.746 23.4692 24.4794C23.115 24.2527 22.7062 24.1727 22.2702 24.1727C20.9212 24.1727 19.7902 25.2794 19.7493 26.5993C19.7493 28.1327 18.4957 29.3327 16.9287 29.3327H15.0756C13.4949 29.3327 12.2413 28.1327 12.2413 26.5993C12.2141 25.2794 11.0831 24.1727 9.73414 24.1727C9.28448 24.1727 8.8757 24.2527 8.53505 24.4794C8.09901 24.746 7.55397 24.8793 7.09068 24.8793C6.1096 24.8793 5.1694 24.346 4.66523 23.4927L3.72503 21.8927C3.22087 21.026 3.19362 19.986 3.69778 19.1593C3.9158 18.7593 4.32458 18.3593 4.78787 18.106C5.1694 17.9193 5.41467 17.6127 5.64631 17.2527C6.32762 16.106 5.91884 14.5993 4.76062 13.9193C3.41163 13.1593 2.9756 11.466 3.75229 10.146L4.66523 8.57268C5.45555 7.25268 7.14518 6.78602 8.50779 7.55935C9.69326 8.19935 11.233 7.77268 11.9279 6.63935C12.146 6.26602 12.2686 5.86602 12.2413 5.46602C12.2141 4.94602 12.364 4.45268 12.6229 4.05268C13.127 3.22602 14.04 2.69268 15.0347 2.66602H16.956ZM16.0158 12.2393C13.8765 12.2393 12.146 13.9193 12.146 16.0127C12.146 18.106 13.8765 19.7727 16.0158 19.7727C18.1551 19.7727 19.8447 18.106 19.8447 16.0127C19.8447 13.9193 18.1551 12.2393 16.0158 12.2393Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
