import { NvAxios, useQuery } from '@novaera/core';
import { isAxiosError } from 'axios';
import { ROOT_PATH_SSO_PROVIDERS } from '../constants';
import { QUERY_KEYS_SSO_PROVIDERS } from '../keys';

type Providers = {
  type: string;
  display: string;
  documentationLink: string;
};

type GetGetSSOProvidersResponse = {
  providers: Providers[];
};

const getSSOProviders = async () => {
  const result = await NvAxios.get<GetGetSSOProvidersResponse>(`${ROOT_PATH_SSO_PROVIDERS}/providers`);
  return result?.data;
};

export const useGetSSOProviders = () =>
  useQuery<GetGetSSOProvidersResponse>(QUERY_KEYS_SSO_PROVIDERS.ssoProviders(), () => getSSOProviders(), {
    retry(failureCount, error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
  });
