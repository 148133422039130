export const QUERY_KEYS_PERMISSION_POLICIES = {
  all: ['permission-policies'] as const,
  list: (queryParams?: { namePrefix?: string; pageKey?: string }) =>
    [
      ...QUERY_KEYS_PERMISSION_POLICIES.all,
      'list',
      ...(queryParams?.namePrefix ? [queryParams.namePrefix] : []),
      ...(queryParams?.pageKey ? [queryParams.pageKey] : []),
    ] as const,
  detail: ({ policyId }: { policyId: string }) => [...QUERY_KEYS_PERMISSION_POLICIES.all, policyId] as const,
};

export const QUERY_KEYS_PERMISSION_DEFINITIONS = {
  all: ['permission-definitions'] as const,
};
