import { CatalogEntityType, useListCatalogEntityTypes } from '@novaera/actioner-service';
import { useMemo } from 'react';

export const useListEntityTypes = () => {
  const { data: entityTypesWithPaginated, isLoading } = useListCatalogEntityTypes();

  const entityTypes = useMemo(
    () =>
      entityTypesWithPaginated?.pages.reduce<CatalogEntityType[]>(
        (acc, page) => [...acc, ...(page.entityTypes ?? [])],
        []
      ) ?? [],
    [entityTypesWithPaginated]
  );

  const handleGetPreviousEntityTypeFromList = (id: string) => {
    const index = entityTypes.findIndex((item) => item.id === id);
    if (index > 0) {
      return entityTypes[index - 1];
    }
    return;
  };

  return {
    entityTypes,
    isLoading,
    getPreviousEntityTypeFromList: handleGetPreviousEntityTypeFromList,
  };
};
