import {
  SlackAppInstallationTrigger,
  useExportSlackApp,
  useGetDefaultManifest,
  useGetSlackApp,
  useStartSlackAppInstallation,
  useUpdateSlackApp,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { getSlackAppAuthUrl } from '../../utils';

import { ExportSlackAppFocusStateOption, ExportSlackAppFormValues } from '../types';
import { ExportSlackAppSubmitResolveProps } from './types';

export const useExportSlackAppController = ({ type }: { type: ExportSlackAppFocusStateOption }) => {
  const { userAppId } = useParams();
  const { data: defaultAppManifest, isInitialLoading: isGetDefaultManifestLoading } = useGetDefaultManifest({
    appId: userAppId,
    enabled: type === ExportSlackAppFocusStateOption.CREATE,
  });
  const { data: slackApp, isInitialLoading: isGetSlackAppLoading } = useGetSlackApp({
    appId: userAppId,
    enabled: type === ExportSlackAppFocusStateOption.UPDATE,
  });
  const { mutate: exportSlackApp } = useExportSlackApp();
  const { mutate: updateSlackApp } = useUpdateSlackApp(userAppId);
  const { mutate: startSlackAppInstallation } = useStartSlackAppInstallation();

  const handleExportSlackAppSubmit = (values: ExportSlackAppFormValues) => {
    const { slackConfigurationToken, appFeatures, displayInfo } = values;

    assert(
      !!slackConfigurationToken && !!appFeatures && !!displayInfo,
      new Error(
        '[useExportSlackAppController] - App features, display info or slackConfigurationToken cannot be undefined.'
      ),
      'ERROR'
    );

    const params = { slackConfigurationToken, appManifestDto: { appFeatures, displayInfo } };

    return new Promise<ExportSlackAppSubmitResolveProps>((resolve, reject) => {
      if (type === ExportSlackAppFocusStateOption.CREATE) {
        exportSlackApp(
          { appId: userAppId, exportSlackAppRequestParams: params },
          {
            onSuccess: ({ slackAuthorizationUrl }) => {
              startSlackAppInstallation(
                { appId: userAppId },
                {
                  onSuccess: ({ triggerId, slackAuthorizationUrl, slackClientId }) => {
                    const slackAuthUrl = getSlackAppAuthUrl({
                      triggerId,
                      slackAuthorizationUrl,
                      slackClientId,
                      installTrigger: SlackAppInstallationTrigger.CREATION,
                      packageId: userAppId,
                    });

                    resolve({ status: 'Success', type });
                    window.location.href = slackAuthUrl;
                  },
                  onError: () => {
                    resolve({ status: 'Error', type });
                  },
                }
              );
            },
            onError: () => {
              resolve({ status: 'Error', type });
            },
          }
        );
      } else if (type === ExportSlackAppFocusStateOption.UPDATE) {
        updateSlackApp(
          { appId: userAppId, updateSlackAppRequestParams: params },
          {
            onSuccess: () => {
              resolve({ status: 'Success', type });
            },
            onError: () => {
              resolve({ status: 'Error', type });
            },
          }
        );
      }
    });
  };

  const initialValues = useMemo(
    () => ({
      slackConfigurationToken: null,
      ...(type === ExportSlackAppFocusStateOption.CREATE ? defaultAppManifest : slackApp?.slackAppManifest),
    }),
    [defaultAppManifest, slackApp?.slackAppManifest, type]
  );

  return {
    handleExportSlackAppSubmit,
    initialValues,
    isInitialValuesLoading: isGetDefaultManifestLoading || isGetSlackAppLoading,
  };
};
