import { useGetIntegrationActions } from '@novaera/actioner-service';
import { NvField } from '@novaera/core';
import { ActionSearchAutoComplete } from './action-search-auto-complete';
import { ActionSearchFieldProps } from './types';

export const ActionSearchField: React.FC<React.PropsWithChildren<ActionSearchFieldProps>> = ({
  integrationId,
  fieldName,
  showError,
  textFieldOverrides,
  version,
  tag,
}) => {
  const { data, isLoading: isGettingIntegrationActions } = useGetIntegrationActions({
    integrationId,
    version,
    tag,
  });

  return (
    <NvField
      name={fieldName}
      isAutoComplete
      parse={(value) => (value ? value.id : '')}
      format={(value) => data?.actions.find((action) => action.id === value) ?? null}
      component={
        <ActionSearchAutoComplete
          options={data?.actions ?? []}
          isLoading={isGettingIntegrationActions}
          textFieldOverrides={textFieldOverrides}
        />
      }
      showError={showError}
    />
  );
};
