import { useGetSlackApp } from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvButton,
  NvCompletionState,
  NvEmoji,
  NvFlex,
  NvTypography,
  StepField,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import React, { PropsWithChildren } from 'react';
import { ExportSlackAppFocusStateOption } from '../export-slack-app/types';
import { CompletionActions, HelpWithBullets } from './styled';
import { CompleteStepProps } from './types';
import { UpdateLogoInfo } from './update-logo-info';

export const CompleteStep: React.FC<PropsWithChildren<CompleteStepProps>> = ({ type }) => {
  const { userAppId } = useParams();
  const { data: slackApp } = useGetSlackApp({ appId: userAppId });

  return (
    <NvFlex gap="32px" width="100%">
      <StepField
        help={{
          title: 'What is next?',
          description:
            type === ExportSlackAppFocusStateOption.CREATE ? (
              <HelpWithBullets>
                <li>
                  <NvTypography variant="body2" textColor="secondary">
                    If you haven't started the setup process for this app yet, you can initiate it from the App settings
                    menu.
                  </NvTypography>
                </li>
                <li>
                  <NvTypography variant="body2" textColor="secondary">
                    If you have already setup the app, you may need to update some workflows. Check out this
                    documentation to learn more.
                  </NvTypography>
                </li>
                {slackApp?.slackAppId && (
                  <li>
                    <UpdateLogoInfo slackAppId={slackApp.slackAppId} />
                  </li>
                )}
              </HelpWithBullets>
            ) : slackApp?.slackAppId ? (
              <UpdateLogoInfo slackAppId={slackApp?.slackAppId} />
            ) : (
              ''
            ),
        }}
      >
        <NvCompletionState
          primaryText={
            type === ExportSlackAppFocusStateOption.CREATE
              ? 'Your app for Slack is all set!'
              : type === ExportSlackAppFocusStateOption.INSTALL
              ? 'Your app for Slack is installed!'
              : 'Your app for Slack is updated!'
          }
          secondaryText={
            <NvTypography variant="body1" align={'left'}>
              We{' '}
              {type === ExportSlackAppFocusStateOption.CREATE
                ? 'created and installed'
                : type === ExportSlackAppFocusStateOption.INSTALL
                ? 'installed'
                : 'updated'}{' '}
              your app for Slack {slackApp?.slackTeamName ? 'to' : ''}
              {slackApp?.slackTeamName ? <strong> {slackApp.slackTeamName} workspace</strong> : ''} successfully.
            </NvTypography>
          }
          width="100%"
          alignItems="flex-start"
          icon={<NvEmoji symbol="🎉" label="completion-state" />}
        />
      </StepField>
      <CompletionActions>
        <NvButton
          endIcon={<NvArrowForwardIcon />}
          onClick={() => {
            window.open(slackApp?.slackAppHomeUrl, '_blank');
          }}
        >
          Go to your app for Slack
        </NvButton>
      </CompletionActions>
    </NvFlex>
  );
};
