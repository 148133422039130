import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { AppConfigDetail } from '../types';
import { GetConfigResponse } from '../use-get-config/types';
import { SaveDraftConfigParams } from './types';

const saveDraftConfig = async ({ appId, configId, ...params }: SaveDraftConfigParams) => {
  const result = await NvAxios.put<AppConfigDetail>(`${CONFIG_ROOT_PATH(appId)}/${configId}/drafts`, params);
  return result?.data;
};

export const useSaveDraftConfig = () => {
  const cache = useQueryClient();

  return useMutation(saveDraftConfig, {
    onSuccess: (response, { appId, configId }) => {
      cache.setQueryData<GetConfigResponse>(QUERY_KEYS_CONFIGS.detail(appId, configId), (old) =>
        old
          ? {
              saved: old?.saved,
              draft: response,
            }
          : undefined
      );
    },
  });
};
