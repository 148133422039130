import { NvAxios, useQuery } from '@novaera/core';
import { ACTIONER_EVENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS } from '../keys';
import { ActionerEvent } from '../types';
import { GetActionerEventParams } from './types';

const getActionerEvent = async ({ appId, id }: GetActionerEventParams) => {
  const result = await NvAxios.get<ActionerEvent>(`${ACTIONER_EVENTS_ROOT_PATH(appId)}/${id}`);
  return result?.data;
};

export const useGetActionerEvent = ({ appId, id }: GetActionerEventParams) => {
  return useQuery<ActionerEvent>(QUERY_KEYS_ACTIONER_EVENTS.detail(appId, id), () => getActionerEvent({ appId, id }), {
    enabled: !!appId && !!id,
  });
};
