import { CatalogWithoutProvider } from './catalog-without-provider';
import { useListEntityTypes } from './controllers/use-list-entity-types';
import { CatalogLoadingSkeleton } from './loading-skeleton';
import { CatalogContextProvider } from './provider/use-catalog-provider';

export const Catalog = () => {
  const { entityTypes, isLoading } = useListEntityTypes();

  if (isLoading) {
    return <CatalogLoadingSkeleton />;
  }

  return (
    <CatalogContextProvider initialSelectedEntityTypeId={entityTypes[0].id}>
      <CatalogWithoutProvider />
    </CatalogContextProvider>
  );
};
