import { useGetUserConnectionsService } from '@novaera/actioner-service';

export const useAppConnectionsController = () => {
  const { data, isFetching: isUserConnectionsLoading } = useGetUserConnectionsService();

  return {
    userConnections: data?.userConnections,
    isUserConnectionsLoading,
  };
};
