import { useFetchProfile } from '@novaera/actioner-service';
import { switchToWorkspace } from '@novaera/utils';
import { WorkspaceType } from '../../../components/workspace-item/types';
import { useGetWorkspaces } from '../services/use-get-workspaces';

export const useWorkspaceSettings = () => {
  const { data: workspaces, isLoading: isWorkspacesLoading } = useGetWorkspaces();
  const { data: profile } = useFetchProfile();
  const onSwitch = (workspace: WorkspaceType) => {
    switchToWorkspace(workspace.subdomain);
  };

  return { workspaces, isWorkspacesLoading, onSwitch, profile };
};
