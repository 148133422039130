import React from 'react';
import { useField } from 'react-final-form';
import { NvField } from '..';
import { CodeInput } from '../../code-input';
import { Context } from '../../code-input/types';
import { NvSettingsIcon } from '../../icons';
import { NvMenuWithItems } from '../../menu';
import { NvSideGroupedInputLayout } from '../../side-grouped-input-layout';
import { FieldComponentCommonProps, FieldProps } from '../types';
import { SettingsMenu } from './settings-menu';

type NvDynamicFieldProps<StaticValue, DynamicValue, FormatReturnType, ParseReturnType> = Omit<
  FieldProps<StaticValue | DynamicValue | undefined>,
  'format' | 'parse'
> & {
  component:
    | React.ReactElement<FieldComponentCommonProps<StaticValue | DynamicValue>>
    | ((props: FieldComponentCommonProps<StaticValue | DynamicValue>) => React.ReactElement);
  context: Context;
  format: (value: StaticValue | DynamicValue, isDynamicField?: boolean) => FormatReturnType | undefined;
  parse: (value: StaticValue | DynamicValue, isDynamicField?: boolean) => ParseReturnType;
  sideGroupedInputLayoutProps?: React.ComponentProps<typeof NvSideGroupedInputLayout>;
};

export const NvDynamicField = <StaticValue, DynamicValue, FormatReturnType, ParseReturnType>(
  props: NvDynamicFieldProps<StaticValue, DynamicValue, FormatReturnType, ParseReturnType>
) => {
  const { component: Component, context, format, parse, sideGroupedInputLayoutProps, ...fieldProps } = props;

  const { input } = useField(fieldProps.name);

  const [isDynamic, setIsDynamic] = React.useState(() => {
    return input.value && 'codeTemplate' in input.value;
  });

  return (
    <NvField<StaticValue | DynamicValue | undefined>
      formControlStyle={{
        width: '100%',
      }}
      format={(value) => {
        return format(value, isDynamic);
      }}
      parse={(value) => {
        return parse(value, isDynamic);
      }}
      {...fieldProps}
      component={({ value, onChange }) => {
        return (
          <NvSideGroupedInputLayout
            variant={'default'}
            {...sideGroupedInputLayoutProps}
            rightAction={
              <NvMenuWithItems
                triggerButton={{
                  content: <NvSettingsIcon />,
                  props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
                }}
                menuItems={
                  <SettingsMenu
                    hiddenConfig={{
                      show: false,
                    }}
                    dynamicStaticConfig={{
                      show: true,
                      isScripted: isDynamic,
                      onItemClicked: (type) => {
                        setIsDynamic(type === 'dynamic');
                      },
                    }}
                  />
                }
              />
            }
            formItem={
              isDynamic ? (
                <CodeInput
                  value={value && value.toString()}
                  onChange={(value) => {
                    onChange(value as StaticValue | DynamicValue);
                  }}
                  context={context}
                  placeholder={'{{...}}'}
                />
              ) : typeof Component === 'function' ? (
                Component({ value, onChange })
              ) : (
                React.cloneElement(Component, { value, onChange })
              )
            }
          />
        );
      }}
    />
  );
};
