import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RightContentHeader = styled(NvBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;
