import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { GetUserAppResponse } from '../types';
import { UpdateUserAppMediaFilesParams, UpdateUserAppMediaFilesResponse } from './types';

const updateUserAppMediaFiles = async ({ appId, mediaFiles }: UpdateUserAppMediaFilesParams) => {
  const result = await NvAxios.patch<UpdateUserAppMediaFilesResponse>(`${USER_APP_ROOT_PATH}/${appId}/media-files`, {
    mediaFiles,
  });
  return result?.data;
};

export const useUpdateUserAppMediaFiles = () => {
  const cache = useQueryClient();
  return useMutation(updateUserAppMediaFiles, {
    onSuccess: (response, { appId }) => {
      cache.setQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId), response);
    },
  });
};
