import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { JsonInput } from '../../../config/config-detail/styled';

export const Container = styled(NvFlex)`
  flex: 1 1 auto;
  min-height: 0;
  flex-direction: row;
  gap: 16px;
  margin: 16px 24px;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Card = styled(NvFlex)`
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  overflow: hidden;
`;

export const CodeInput = styled(JsonInput)`
  &.NvCodeInput {
    height: 100%;
    .CodeMirror {
      border: unset;
      background: transparent;
      &-focused {
        border: unset;
      }
      &:hover {
        border: unset;
      }
      &-hover {
        border: unset;
      }
    }
  }
`;

export const Title = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[10]};
  border-radius: 12px 12px 0 0;
  gap: 8px;
  .chevron-icon {
    display: none;
  }
  @media screen and (max-width: 950px) {
    cursor: pointer;
    padding: 8px 16px;
    .chevron-icon {
      display: flex;
    }
  }
`;

export const Body = styled(NvBox)`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 950px) {
    height: auto;
    transition: height 0.3s ease-in-out;

    &.collapsed {
      height: 0;
    }
  }
`;
