import { NvAxios, useMutation } from '@novaera/core';
import { SendVerificationProps } from './types';

type SSOLoginMethod = {
  loginMethod: 'sso';
  redirectUrl: string;
};

type OTPLoginMethod = {
  loginMethod: 'otp';
};

type InitiateV2Response = SSOLoginMethod | OTPLoginMethod;

const sendVerification = async (props: SendVerificationProps) => {
  const result = await NvAxios.post<InitiateV2Response>('/v1/users/signup/initiateV2', {
    ...props,
  });
  return result.data;
};

export const useSendVerification = () => {
  return useMutation((props: SendVerificationProps) => sendVerification(props), {
    onSuccess: (data) => {
      if (data.loginMethod === 'sso') {
        window.location.href = data.redirectUrl;
      }
    },
  });
};
