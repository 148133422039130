import { USER_PERMISSION, useUpdateIntegrationPartial } from '@novaera/actioner-service';
import { NvInlineEdit, NvMarkDownEditor, isMaxLength } from '@novaera/core';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { DescriptionView } from '../../../../components/description-view';
import { UserPermissionBoundary } from '../../../../user-permission-boundary';
import { IntegrationDescriptionProps } from './types';

export const IntegrationDescription: FC<React.PropsWithChildren<IntegrationDescriptionProps>> = ({ integration }) => {
  const { mutate: updateIntegrationPartial } = useUpdateIntegrationPartial();
  const [descriptionValue, setDescriptionValue] = useState(integration.description);
  const onConfirm = useCallback(
    async (v?: string) => {
      assert(!isUndefined(v), new Error('Integration description value should be defined!!'), 'ERROR');

      const newDescription = v;
      setDescriptionValue(v);

      return new Promise<void>((resolve) => {
        updateIntegrationPartial(
          { id: integration.id, description: newDescription, connectionSchemaId: integration.connectionSchemaId },
          {
            onError: () => {
              setDescriptionValue(integration.description);
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      });
    },
    [updateIntegrationPartial, integration.id, integration.connectionSchemaId, integration.description]
  );

  return (
    <UserPermissionBoundary
      permission={USER_PERMISSION.INTEGRATION_UPDATE}
      Fallback={<DescriptionView value={descriptionValue} />}
    >
      <NvInlineEdit
        viewComponent={<DescriptionView />}
        editComponent={<NvMarkDownEditor />}
        value={descriptionValue}
        validate={(value) => isMaxLength({ length: 3000 })(value)}
        onConfirm={onConfirm}
      />
    </UserPermissionBoundary>
  );
};
