import { NvButton, NvDeleteOutlineIcon, NvFlex, NvLockIcon, NvSearchEmptyState, NvSkeleton } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { BaseItem } from '../../../../src/components';
import { SearchAndButtonBar } from '../components/search-and-add-button';
import { usePermissionPoliciesController } from './controllers';
import { CreateOrUpdatePermissionPolicyModal } from './create-or-update-permission-policy-modal';

export const PermissionPolicies: React.FC = () => {
  const theme = useTheme();
  const {
    permissionPoliciesPages,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isEmpty,
    isCreatePermissionPolicyModalOpen,
    isCreatePermissionPolicyLoading,
    onCreatePermissionPolicyModalCloseClicked,
    setSearchPermissionPolicyKeyword,
    handleCreatePermissionPolicy,
    handleCreatePermissionPolicySubmit,
    handleDeletePermissionPolicy,
  } = usePermissionPoliciesController();

  return (
    <>
      <NvFlex gap="16px">
        <SearchAndButtonBar
          buttonLabel="Create permission policy"
          onButtonClick={handleCreatePermissionPolicy}
          setSearchKeyword={setSearchPermissionPolicyKeyword}
        />
        {isLoading ? (
          <>
            <NvSkeleton variant="rectangular" height="60px" width="100%" />
            <NvSkeleton variant="rectangular" height="60px" width="100%" />
            <NvSkeleton variant="rectangular" height="60px" width="100%" />
          </>
        ) : isEmpty ? (
          <NvSearchEmptyState text="No permission policy found. Try different words or clear search bar." />
        ) : (
          <NvFlex gap="8px">
            {permissionPoliciesPages?.map((permissionPolicyPage) =>
              permissionPolicyPage.permissionPolicies.map((permissionPolicy) => {
                const isAdmin = permissionPolicy.id === 'admin';
                return (
                  <BaseItem
                    key={`permission_policy_item_${permissionPolicy.id}`}
                    name={permissionPolicy.name}
                    menuItems={
                      isAdmin
                        ? []
                        : [
                            {
                              name: 'Delete',
                              onClick: () => handleDeletePermissionPolicy(permissionPolicy),
                              icon: (
                                <NvDeleteOutlineIcon
                                  htmlColor={theme.palette.nv_error[40]}
                                  sx={{ width: '16px', height: '16px' }}
                                />
                              ),
                            },
                          ]
                    }
                    actions={
                      isAdmin ? (
                        <NvLockIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[300]} />
                      ) : null
                    }
                    to={permissionPolicy.id}
                    description={permissionPolicy.description}
                    tags={permissionPolicy.tags}
                  />
                );
              })
            )}
            {hasNextPage && (
              <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
                <NvButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    fetchNextPage();
                  }}
                  loading={isFetchingNextPage}
                  disabled={isFetchingNextPage}
                >
                  Show more
                </NvButton>
              </NvFlex>
            )}
          </NvFlex>
        )}
      </NvFlex>
      <CreateOrUpdatePermissionPolicyModal
        open={isCreatePermissionPolicyModalOpen}
        onCloseButtonClicked={onCreatePermissionPolicyModalCloseClicked}
        onFormSubmit={handleCreatePermissionPolicySubmit}
        Header="Create permission policy"
        primaryButtonText="Create"
        modalIcon="add"
        isLoading={isCreatePermissionPolicyLoading}
      />
    </>
  );
};
