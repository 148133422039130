export function NvGeneralIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M76.3553 68.3685C76.1513 68.3685 75.9472 68.2608 75.8392 68.0693C75.6711 67.782 75.7671 67.4229 76.0552 67.2553L89.3676 59.5944C89.6557 59.4268 90.0158 59.5226 90.1839 59.8099C90.3519 60.0972 90.2559 60.4563 89.9678 60.6239L76.6554 68.2847C76.5594 68.3446 76.4514 68.3685 76.3553 68.3685Z"
        fill="#B1B6C0"
      />
      <path d="M44.1128 13.8565L30.8965 21.4575L44.1128 29.0705L57.3291 21.4575L44.1128 13.8565Z" fill="white" />
      <path
        d="M44.1128 29.669C44.0047 29.669 43.9087 29.6451 43.8127 29.5852L30.5964 21.9842C30.4163 21.8765 30.2963 21.685 30.2963 21.4695C30.2963 21.254 30.4163 21.0625 30.5964 20.9548L43.8127 13.3537C43.9927 13.246 44.2328 13.246 44.4129 13.3537L57.6292 20.9548C57.8092 21.0625 57.9293 21.254 57.9293 21.4695C57.9293 21.685 57.8092 21.8765 57.6292 21.9842L44.4129 29.5852C44.3168 29.6331 44.2208 29.669 44.1128 29.669ZM32.0969 21.4695L44.1128 28.3882L56.1287 21.4695L44.1128 14.5508L32.0969 21.4695Z"
        fill="#455068"
      />
      <path d="M44.1128 29.0705V62.1919L30.8965 54.5789V21.4575L44.1128 29.0705Z" fill="white" />
      <path
        d="M44.1128 62.7904C44.0047 62.7904 43.9087 62.7664 43.8127 62.7066L30.5964 55.1056C30.4163 54.9978 30.2963 54.8063 30.2963 54.5908V21.4575C30.2963 21.2421 30.4163 21.0505 30.5964 20.9428C30.7764 20.8351 31.0165 20.8351 31.1966 20.9428L44.4129 28.5438C44.5929 28.6516 44.713 28.8431 44.713 29.0586V62.1799C44.713 62.3954 44.5929 62.5869 44.4129 62.6946C44.3168 62.7425 44.2208 62.7784 44.1128 62.7784V62.7904ZM31.4967 54.2437L43.5126 61.1624V29.4057L31.4967 22.487V54.2317V54.2437Z"
        fill="#455068"
      />
      <path d="M57.3291 21.4575V54.5789L44.1128 62.1919V29.0705L57.3291 21.4575Z" fill="#455068" />
      <path
        d="M44.1128 62.7904C44.0047 62.7904 43.9087 62.7664 43.8127 62.7066C43.6326 62.5989 43.5126 62.4073 43.5126 62.1919V29.0705C43.5126 28.8551 43.6326 28.6635 43.8127 28.5558L57.029 20.9548C57.209 20.847 57.4491 20.847 57.6292 20.9548C57.8092 21.0625 57.9293 21.254 57.9293 21.4695V54.5908C57.9293 54.8063 57.8092 54.9978 57.6292 55.1056L44.4129 62.7066C44.3168 62.7545 44.2208 62.7904 44.1128 62.7904ZM44.713 29.4177V61.1624L56.7289 54.2437V22.4989L44.713 29.4177Z"
        fill="#455068"
      />
      <path
        d="M49.2145 74.2697V81.0687L36.3583 75.9336C33.6334 74.3655 31.9288 68.8472 33.2973 65.9983L41.0038 54.4831H41.0158L44.833 60.0971L43.0204 63.8916C41.8801 66.2617 43.2965 70.8582 45.5773 72.1749L49.2145 74.2697Z"
        fill="white"
      />
      <path
        d="M49.2145 81.6672C49.1424 81.6672 49.0584 81.6553 48.9864 81.6194L36.1302 76.4842C33.0812 74.7365 31.2566 68.8353 32.7451 65.735L40.4996 54.1479C40.5957 53.9923 40.7637 53.8965 40.9438 53.8846C41.1358 53.8726 41.3039 53.9325 41.4359 54.0641L45.3252 59.7499C45.4452 59.9295 45.4572 60.1569 45.3732 60.3484L43.5606 64.143C42.5643 66.2258 43.8847 70.4991 45.8774 71.6482L49.5146 73.743C49.6946 73.8507 49.8147 74.0423 49.8147 74.2577V81.0568C49.8147 81.2603 49.7186 81.4398 49.5506 81.5475C49.4545 81.6194 49.3345 81.6553 49.2145 81.6553V81.6672ZM41.0158 55.5484L33.8014 66.3215C32.6251 68.7874 34.2336 74.0064 36.6584 75.4069L48.6143 80.171V74.6049L45.2772 72.6777C42.7083 71.1934 41.1959 66.2856 42.4803 63.6163L44.1488 60.133L41.0158 55.5364V55.5484Z"
        fill="#455068"
      />
      <path
        d="M58.0614 52.508L46.2015 59.331L44.845 60.109L44.833 60.0971L41.0158 54.495L54.2201 46.894L58.0614 52.508Z"
        fill="white"
      />
      <path
        d="M44.845 60.7075C44.689 60.7075 44.5329 60.6477 44.4249 60.528C44.4009 60.5041 44.3649 60.4562 44.3409 60.4322L40.5236 54.8302C40.4276 54.6985 40.4036 54.519 40.4396 54.3634C40.4756 54.1958 40.5836 54.0641 40.7277 53.9803L53.932 46.3793C54.1961 46.2237 54.5562 46.3075 54.7243 46.5589L58.5655 52.1729C58.6616 52.3045 58.6976 52.4841 58.6495 52.6397C58.6015 52.7953 58.5055 52.9389 58.3615 53.0227L45.1451 60.6238C45.0491 60.6716 44.9531 60.7075 44.845 60.7075ZM41.8801 54.6866L45.0251 59.307L57.1971 52.3045L54.04 47.6841L41.8801 54.6866Z"
        fill="#455068"
      />
      <path
        d="M62.4428 66.6806L55.4565 70.6906L49.2145 74.2697L45.5773 72.1749C44.809 71.732 44.1368 70.9181 43.6447 69.9485L56.981 62.5509C57.4612 63.4367 58.0974 64.1669 58.8056 64.5739L62.4428 66.6806Z"
        fill="white"
      />
      <path
        d="M49.2145 74.8682C49.1064 74.8682 49.0104 74.8443 48.9144 74.7844L45.2772 72.6897C44.4489 72.2108 43.6807 71.3251 43.1045 70.2118C42.9604 69.9246 43.0685 69.5774 43.3446 69.4218L56.6809 62.0243C56.825 61.9524 56.981 61.9285 57.1371 61.9764C57.2931 62.0243 57.4252 62.12 57.4972 62.2637C57.9413 63.0776 58.5055 63.7121 59.0937 64.0472L62.7309 66.154C62.911 66.2617 63.031 66.4532 63.031 66.6687C63.031 66.8841 62.911 67.0757 62.7309 67.1834L49.5026 74.7725C49.4065 74.8203 49.3105 74.8562 49.2025 74.8562L49.2145 74.8682ZM44.4609 70.1759C44.8691 70.8463 45.3612 71.361 45.8774 71.6482L49.2145 73.5754L61.2424 66.6806L58.5055 65.0886C57.8573 64.7175 57.2691 64.119 56.7649 63.3529L44.4609 70.1759Z"
        fill="#455068"
      />
      <path
        d="M56.981 62.5509L43.6446 69.9485C42.6363 68.0213 42.2642 65.4717 43.0204 63.8916L44.833 60.0971H44.845L46.2015 59.331L58.0614 52.508L56.2368 56.3025C55.4565 57.9305 55.8886 60.6118 56.981 62.5509Z"
        fill="#455068"
      />
      <path
        d="M43.6446 70.547C43.6446 70.547 43.5246 70.547 43.4766 70.5231C43.3205 70.4752 43.1885 70.3674 43.1165 70.2238C41.9521 67.9974 41.688 65.2921 42.4803 63.6283L44.2929 59.8337C44.3649 59.6781 44.4969 59.5704 44.665 59.5225L57.7613 51.9933C57.9893 51.8616 58.2774 51.8975 58.4695 52.0771C58.6616 52.2566 58.7216 52.5439 58.6015 52.7714L56.7769 56.5659C56.1287 57.9185 56.4408 60.3724 57.4972 62.2637C57.5812 62.4073 57.5932 62.5629 57.5572 62.7185C57.5212 62.8741 57.4131 63.0058 57.2691 63.0776L43.9327 70.4752C43.8367 70.5231 43.7407 70.547 43.6446 70.547ZM45.2772 60.5519L43.5606 64.1549C42.9964 65.34 43.1525 67.351 43.9087 69.1226L56.1767 62.3115C55.2764 60.4083 54.8443 57.8108 55.6846 56.0512L56.6689 54.0043L45.2652 60.5639L45.2772 60.5519Z"
        fill="#455068"
      />
      <path
        d="M62.4428 66.6806V73.4797L49.2265 81.0807L49.2145 74.2697L55.4565 70.6906L62.4428 66.6806Z"
        fill="#455068"
      />
      <path
        d="M49.2265 81.6792C49.1184 81.6792 49.0224 81.6553 48.9264 81.5954C48.7463 81.4877 48.6263 81.2962 48.6263 81.0807V74.2697C48.6263 74.0542 48.7343 73.8508 48.9144 73.755L62.1427 66.1659C62.3348 66.0582 62.5628 66.0582 62.7429 66.1659C62.935 66.2737 63.043 66.4652 63.043 66.6806V73.4797C63.043 73.6951 62.9229 73.8867 62.7429 73.9944L49.5266 81.5954C49.4305 81.6433 49.3345 81.6792 49.2265 81.6792ZM49.8147 74.6168V80.0513L61.8426 73.1445V67.722L49.8147 74.6168Z"
        fill="#455068"
      />
      <path
        d="M67.2684 92.5601L67.1963 92.596C66.6081 92.9431 65.8759 92.9431 65.0356 92.4643L52.1794 82.7924V75.9814L55.8286 78.0762C58.0974 79.3929 59.5258 76.4363 58.3855 72.7495L56.5609 66.8602L60.4021 65.6752L62.3107 70.7026L68.1206 86.0723C69.0449 89.0887 68.5408 91.6982 67.2684 92.5601Z"
        fill="white"
      />
      <path
        d="M66.308 93.4458C65.8639 93.4458 65.3357 93.3261 64.7475 92.979L51.8313 83.2712C51.6753 83.1635 51.5912 82.984 51.5912 82.7924V75.9814C51.5912 75.766 51.6993 75.5744 51.8913 75.4667C52.0714 75.359 52.2995 75.359 52.4915 75.4667L56.1407 77.5615C56.6569 77.8607 57.089 77.8727 57.4251 77.6094C58.1454 77.0348 58.5295 75.2034 57.8213 72.929L55.9967 67.0397C55.9006 66.7285 56.0807 66.3934 56.3928 66.2976L60.2341 65.1126C60.5341 65.0168 60.8583 65.1724 60.9783 65.4717L68.6968 85.8688C69.6691 89.0408 69.225 91.9735 67.6165 93.0628C67.5925 93.0748 67.5684 93.0867 67.5444 93.0987L67.4724 93.1346C67.1963 93.3022 66.8002 93.4458 66.308 93.4458ZM52.7796 82.4932L65.3957 91.9855C65.9359 92.2847 66.4521 92.3326 66.8842 92.0813C66.8842 92.0813 66.9202 92.0573 66.9562 92.0453C67.9046 91.363 68.4207 89.1246 67.5444 86.2518L60.042 66.4173L57.3171 67.2552L58.9616 72.5699C59.7539 75.1315 59.4298 77.5375 58.1574 78.543C57.4251 79.1296 56.4648 79.1415 55.5285 78.5909L52.7796 77.0109V82.4932Z"
        fill="#455068"
      />
      <path d="M73.6184 58.0622L60.4021 65.6752L56.5609 66.8602L69.7772 59.2592L73.6184 58.0622Z" fill="white" />
      <path
        d="M56.5729 67.4587C56.3448 67.4587 56.1167 67.327 56.0207 67.0996C55.9006 66.8243 56.0087 66.4891 56.2728 66.3455L69.4891 58.7445C69.4891 58.7445 69.5731 58.7085 69.6091 58.6966L73.4504 57.4996C73.7505 57.4038 74.0506 57.5474 74.1826 57.8347C74.3026 58.11 74.1946 58.4452 73.9305 58.5888L60.7142 66.2018C60.7142 66.2018 60.6302 66.2378 60.5942 66.2497L56.7529 67.4348C56.7529 67.4348 56.6329 67.4587 56.5729 67.4587Z"
        fill="#455068"
      />
      <path
        d="M81.3249 78.4593L73.6184 58.0621L60.4021 65.6752L68.1206 86.0723C69.0449 89.0887 68.5408 91.6982 67.2684 92.5601L80.2326 85.0787L80.4487 84.959C81.7571 84.1211 82.2612 81.4997 81.3249 78.4593Z"
        fill="#455068"
      />
      <path
        d="M67.2683 93.1586C67.0643 93.1586 66.8722 93.0628 66.7642 92.8833C66.5961 92.6079 66.6682 92.2488 66.9322 92.0693C67.8926 91.4229 68.4327 89.1606 67.5444 86.2518L59.8379 65.8906C59.7299 65.6153 59.8379 65.3041 60.102 65.1604L73.3183 57.5474C73.4744 57.4636 73.6544 57.4397 73.8225 57.4996C73.9905 57.5594 74.1226 57.6911 74.1826 57.8467L81.8891 78.2438C81.8891 78.2438 81.8891 78.2677 81.8891 78.2797C82.8614 81.4518 82.4053 84.4084 80.7608 85.4618L80.5087 85.6054L67.5564 93.0868C67.4604 93.1466 67.3644 93.1705 67.2563 93.1705L67.2683 93.1586ZM61.1463 65.9385L68.6848 85.8568C69.261 87.7241 69.333 89.5077 68.9849 90.8723L80.1606 84.4204C81.1089 83.8099 81.661 81.5715 80.7608 78.6388L73.3063 58.924L61.1463 65.9265V65.9385Z"
        fill="#455068"
      />
      <path
        d="M55.8286 78.0762L52.1794 75.9814L58.3854 72.7495C59.5258 76.4363 58.0973 79.3929 55.8286 78.0762Z"
        fill="white"
      />
      <path
        d="M56.885 78.9859C56.4528 78.9859 55.9847 78.8543 55.5285 78.5909L51.8793 76.4962C51.6873 76.3884 51.5792 76.1849 51.5792 75.9695C51.5792 75.754 51.7113 75.5505 51.9033 75.4547L58.1094 72.2228C58.2654 72.139 58.4575 72.1271 58.6135 72.1989C58.7816 72.2707 58.9016 72.4024 58.9616 72.5699C59.7539 75.1316 59.4298 77.5376 58.1574 78.543C57.7852 78.8423 57.3411 78.9979 56.873 78.9979L56.885 78.9859ZM53.4278 76.0054L56.1287 77.5615C56.6449 77.8607 57.077 77.8727 57.4131 77.6094C58.0613 77.0947 58.4335 75.5744 58.0013 73.6353L53.4278 76.0173V76.0054Z"
        fill="#455068"
      />
      <path
        d="M52.0474 53.717L46.3215 57.0327L48.1461 53.2502C48.9864 51.5145 50.727 51.9334 52.0474 53.717Z"
        fill="#455068"
      />
      <path
        d="M46.3215 57.6312C46.1775 57.6312 46.0214 57.5714 45.9134 57.4756C45.7213 57.2961 45.6613 57.0088 45.7814 56.7813L47.606 52.9988C48.0141 52.1489 48.6743 51.6342 49.4546 51.5624C50.4869 51.4666 51.6033 52.125 52.5276 53.3699C52.6236 53.5015 52.6716 53.6811 52.6356 53.8487C52.5996 54.0162 52.4916 54.1599 52.3475 54.2437L46.6216 57.5594C46.5256 57.6193 46.4176 57.6432 46.3215 57.6432V57.6312ZM49.6586 52.7474C49.6586 52.7474 49.5986 52.7474 49.5746 52.7474C49.2145 52.7833 48.9144 53.0347 48.6863 53.5135L47.714 55.5365L51.1351 53.5614C50.6309 53.0467 50.1028 52.7474 49.6586 52.7474Z"
        fill="#455068"
      />
      <path
        d="M58.9257 60.8871L55.0844 62.0841L53.2718 56.1948C52.9597 55.2133 52.5396 54.3753 52.0474 53.717C50.727 51.9334 48.9864 51.5145 48.1461 53.2502L46.3215 57.0327L42.4923 51.4187L47.63 36.1688C47.858 35.7139 48.1221 35.3787 48.4342 35.1633L48.6863 35.0196C50.2948 34.2176 52.6836 36.1807 53.776 39.7L58.9257 60.8871Z"
        fill="white"
      />
      <path
        d="M55.0844 62.6826C54.9884 62.6826 54.8923 62.6587 54.8083 62.6108C54.6643 62.539 54.5562 62.4073 54.5202 62.2517L52.7076 56.3624C52.4195 55.4766 52.0354 54.6746 51.5793 54.0641C50.931 53.1903 50.1988 52.6876 49.5866 52.7354C49.2265 52.7714 48.9264 53.0227 48.6983 53.5015L46.8737 57.2841C46.7777 57.4756 46.5976 57.6073 46.3816 57.6193C46.1655 57.6432 45.9614 57.5355 45.8414 57.3559L42.0121 51.7419C41.9041 51.5863 41.8801 51.3948 41.9401 51.2152L47.0778 35.9653C47.3899 35.3309 47.714 34.9239 48.1101 34.6605L48.4102 34.493C49.0944 34.1578 49.8387 34.1578 50.6189 34.493C52.1795 35.1513 53.6199 37.0905 54.3762 39.5324L59.5379 60.7554C59.6099 61.0667 59.4298 61.3779 59.1297 61.4617L55.2885 62.6587C55.2885 62.6587 55.1684 62.6826 55.1084 62.6826H55.0844ZM49.6586 51.5504C50.6309 51.5504 51.6633 52.1968 52.5276 53.3699C53.0677 54.1 53.5119 54.9858 53.836 56.0272L55.4685 61.342L58.1934 60.4921L53.1758 39.8556C52.5396 37.8087 51.3272 36.1209 50.1028 35.6062C49.6586 35.4147 49.2625 35.4027 48.9384 35.5703L48.7103 35.7019C48.5303 35.8216 48.3262 36.085 48.1461 36.4561L43.1405 51.3469L46.2135 55.8596L47.582 53.0108C47.9901 52.1609 48.6503 51.6462 49.4306 51.5743C49.4906 51.5743 49.5626 51.5743 49.6346 51.5743L49.6586 51.5504Z"
        fill="#455068"
      />
      <path
        d="M72.142 53.2861L58.9257 60.8871L53.776 39.7C53.5359 38.9339 53.2358 38.2396 52.8997 37.6291L66.176 30.1478C66.4881 30.7224 66.7642 31.3807 66.9923 32.0989L72.142 53.2861Z"
        fill="#455068"
      />
      <path
        d="M58.9257 61.4856C58.8416 61.4856 58.7696 61.4736 58.6856 61.4377C58.5175 61.3659 58.3855 61.2103 58.3375 61.0307L53.1878 39.8436C52.9837 39.1853 52.6956 38.5149 52.3715 37.9164C52.2155 37.6291 52.3115 37.27 52.5996 37.1025L65.8759 29.6211C66.02 29.5373 66.188 29.5254 66.3321 29.5732C66.4761 29.6211 66.6202 29.7169 66.6922 29.8605C67.0283 30.471 67.3164 31.1653 67.5565 31.9194L72.7182 53.1424C72.7782 53.4058 72.6701 53.6691 72.4301 53.8008L59.2138 61.4018C59.1177 61.4497 59.0217 61.4856 58.9137 61.4856H58.9257ZM53.692 37.8685C53.944 38.3952 54.1601 38.9459 54.3402 39.5204L59.3098 59.9654L71.4457 52.9868L66.4041 32.2306C66.2601 31.7997 66.104 31.3688 65.9119 30.9738L53.692 37.8566V37.8685Z"
        fill="#455068"
      />
      <path
        d="M66.176 30.1478L52.8997 37.6291H52.8877C51.6753 35.4506 49.9467 34.3972 48.6863 35.0197L61.8906 27.4186C63.1751 26.7722 64.9516 27.8855 66.176 30.1478Z"
        fill="white"
      />
      <path
        d="M52.8997 38.2276C52.6836 38.2276 52.4676 38.1079 52.3595 37.9164C51.3392 36.073 49.9107 35.0795 48.9504 35.5583C48.6623 35.702 48.3142 35.5942 48.1581 35.3069C48.0021 35.0197 48.1101 34.6725 48.3862 34.5049L61.5905 26.9039C63.2231 26.078 65.3118 27.3109 66.7042 29.8725C66.8603 30.1598 66.7522 30.5189 66.4761 30.6745L53.1998 38.1558C53.1038 38.2037 53.0077 38.2276 52.8997 38.2276ZM50.703 34.5528C51.5553 34.9478 52.3955 35.7379 53.1038 36.8271L65.3478 29.9323C64.3394 28.3523 63.031 27.5264 62.1547 27.9573L50.703 34.5528Z"
        fill="#455068"
      />
      <path d="M52.8997 38.2277H52.8877V37.0306L52.8997 38.2277Z" fill="#455068" />
      <path d="M68.0366 80.0752L54.8323 87.6763L68.0366 95.2893L81.2529 87.6763L68.0366 80.0752Z" fill="white" />
      <path
        d="M68.0366 95.8878C67.9286 95.8878 67.8325 95.8638 67.7365 95.804L54.5202 88.203C54.3401 88.0952 54.2201 87.9037 54.2201 87.6882C54.2201 87.4728 54.3401 87.2813 54.5202 87.1735L67.7365 79.5725C67.9166 79.4648 68.1566 79.4648 68.3367 79.5725L81.553 87.1735C81.7331 87.2813 81.8531 87.4728 81.8531 87.6882C81.8531 87.9037 81.7331 88.0952 81.553 88.203L68.3367 95.804C68.2407 95.8519 68.1446 95.8878 68.0366 95.8878ZM56.0207 87.6882L68.0366 94.607L80.0525 87.6882L68.0366 80.7695L56.0207 87.6882Z"
        fill="#455068"
      />
      <path d="M68.0366 95.2893V109.45L54.8323 101.837V87.6763L68.0366 95.2893Z" fill="white" />
      <path
        d="M68.0366 110.048C67.9286 110.048 67.8325 110.025 67.7365 109.965C58.7256 104.778 54.2201 102.068 54.2201 101.837V87.6763C54.2201 87.4608 54.3401 87.2693 54.5202 87.1616C54.7002 87.0538 54.9403 87.0538 55.1204 87.1616L68.3367 94.7626C68.5168 94.8703 68.6368 95.0618 68.6368 95.2773V109.438C68.6368 109.653 68.5168 109.845 68.3367 109.953C68.2407 110.001 68.1446 110.036 68.0366 110.036V110.048ZM55.4205 101.502C57.041 102.435 64.7715 106.888 67.4364 108.421V95.6364L55.4205 88.7177V101.502Z"
        fill="#455068"
      />
      <path d="M81.2529 87.6763V101.837L68.0366 109.45V95.2893L81.2529 87.6763Z" fill="#455068" />
      <path
        d="M68.0366 110.048C67.9286 110.048 67.8325 110.025 67.7365 109.965C67.5564 109.857 67.4364 109.665 67.4364 109.45V95.2893C67.4364 95.0738 67.5564 94.8823 67.7365 94.7746L80.9528 87.1735C81.1329 87.0658 81.373 87.0658 81.553 87.1735C81.7331 87.2813 81.8531 87.4728 81.8531 87.6882V101.849C81.8531 102.064 81.7331 102.256 81.553 102.364L68.3367 109.965C68.2407 110.013 68.1446 110.048 68.0366 110.048ZM68.6368 95.6364V108.421L80.6527 101.502V88.7177L68.6368 95.6364Z"
        fill="#455068"
      />
      <path
        d="M13.5989 74.0783C13.3948 74.0783 13.1907 73.9705 13.0827 73.779C12.9146 73.4917 13.0107 73.1326 13.2988 72.965L30.8245 62.8742C31.1126 62.7066 31.4847 62.8024 31.6408 63.0897C31.8088 63.377 31.7128 63.7361 31.4247 63.9036L13.899 73.9945C13.8029 74.0543 13.6949 74.0783 13.5989 74.0783Z"
        fill="#B1B6C0"
      />
      <path
        d="M15.1954 88.3705C14.9914 88.3705 14.7873 88.2628 14.6793 88.0713C14.5112 87.784 14.6072 87.4249 14.8953 87.2573L31.1846 77.8727C31.4727 77.7052 31.8329 77.8009 32.0009 78.0882C32.169 78.3755 32.0729 78.7346 31.7848 78.9022L15.4955 88.2868C15.3995 88.3466 15.2915 88.3705 15.1954 88.3705Z"
        fill="#B1B6C0"
      />
      <path
        d="M36.0582 91.5666C35.8541 91.5666 35.6501 91.4589 35.542 91.2674C35.374 90.9801 35.47 90.621 35.7581 90.4534L43.8007 85.821C44.0888 85.6534 44.449 85.7492 44.617 86.0364C44.7851 86.3237 44.689 86.6828 44.4009 86.8504L36.3583 91.4829C36.2623 91.5427 36.1542 91.5666 36.0582 91.5666Z"
        fill="#B1B6C0"
      />
      <path
        d="M77.1356 52.6997C76.9315 52.6997 76.7275 52.592 76.6194 52.4004C76.4514 52.1132 76.5474 51.7541 76.8355 51.5865L83.4377 47.78C83.7258 47.6124 84.0859 47.7082 84.2539 47.9954C84.422 48.2827 84.326 48.6418 84.0379 48.8094L77.4357 52.6159C77.3397 52.6758 77.2316 52.6997 77.1356 52.6997Z"
        fill="#B1B6C0"
      />
      <path
        d="M87.8551 76.963C87.651 76.963 87.447 76.8553 87.3389 76.6638C87.1709 76.3765 87.2669 76.0174 87.555 75.8498L104.999 66C105.287 65.8324 105.647 65.9282 105.815 66.2155C105.983 66.5027 105.887 66.8619 105.599 67.0294L88.1552 76.8792C88.0592 76.9391 87.9511 76.963 87.8551 76.963Z"
        fill="#B1B6C0"
      />
      <path
        d="M96.9541 86.9342C96.75 86.9342 96.546 86.8264 96.4379 86.6349C96.2699 86.3476 96.3659 85.9885 96.654 85.821L105.5 80.5C105.788 80.3324 106.148 80.4282 106.316 80.7155C106.484 81.0028 106.388 81.3619 106.1 81.5294L97.2542 86.8504C97.1582 86.9102 97.0501 86.9342 96.9541 86.9342Z"
        fill="#B1B6C0"
      />
      <path
        d="M48.2182 114.992C48.0021 114.992 47.7981 114.884 47.69 114.681C47.534 114.394 47.642 114.023 47.9301 113.867L57.0291 108.923C57.3172 108.768 57.6893 108.875 57.8453 109.163C58.0014 109.45 57.8934 109.821 57.6053 109.977L48.5063 114.92C48.4103 114.968 48.3142 114.992 48.2182 114.992Z"
        fill="#B1B6C0"
      />
      <path
        d="M42.6244 102.998C42.4203 102.998 42.2162 102.89 42.1082 102.699C41.9402 102.412 42.0362 102.052 42.3243 101.885L50.4149 97.2285C50.703 97.0609 51.0631 97.1566 51.2312 97.4439C51.3992 97.7312 51.3032 98.0903 51.0151 98.2579L42.9245 102.914C42.8284 102.974 42.7204 102.998 42.6244 102.998Z"
        fill="#B1B6C0"
      />
      <path
        d="M50.715 17.2322C50.715 18.896 50.1508 20.0931 49.2505 20.7394L48.8424 20.9788L46.5376 22.2956C46.8617 22.0322 47.1498 21.6851 47.3779 21.2422C47.702 20.6317 47.87 19.8297 47.8821 18.8841C47.8821 17.8187 47.678 16.6816 47.2939 15.5683L50.2348 14.2277C50.5589 15.2452 50.739 16.2746 50.727 17.2442L50.715 17.2322Z"
        fill="#455068"
      />
      <path
        d="M46.5376 22.8821C46.3456 22.8821 46.1535 22.7863 46.0455 22.6188C45.8654 22.3674 45.9134 22.0083 46.1655 21.8168C46.4416 21.5893 46.6697 21.302 46.8377 20.9549C47.1258 20.4043 47.2699 19.698 47.2819 18.8601C47.2819 17.9025 47.1018 16.8492 46.7297 15.7359C46.6336 15.4487 46.7657 15.1255 47.0418 15.0058L49.9827 13.6651C50.1388 13.5933 50.3189 13.5933 50.4749 13.6651C50.631 13.7369 50.751 13.8686 50.799 14.0242C51.1591 15.1255 51.3392 16.2147 51.3272 17.2322C51.3272 19.0277 50.703 20.4402 49.6106 21.2183L46.8377 22.7983C46.7417 22.8462 46.6457 22.8821 46.5376 22.8821ZM48.0261 15.8796C48.3382 16.9329 48.4943 17.9384 48.4823 18.8841C48.4823 19.5185 48.3982 20.105 48.2542 20.6317L48.9624 20.2247C49.6826 19.71 50.1148 18.6327 50.1268 17.2322C50.1268 16.5379 50.0428 15.8078 49.8507 15.0536L48.0261 15.8915V15.8796Z"
        fill="#455068"
      />
      <path
        d="M50.2228 14.2157L47.2818 15.5564C46.3696 12.8631 44.4489 10.2776 42.2282 8.99675C41.4119 8.52991 40.6437 8.27854 39.9355 8.23066C39.3953 8.19475 38.9151 8.27854 38.495 8.48203L40.9918 7.03365L41.2439 6.89C42.2402 6.37529 43.5966 6.49499 45.0731 7.35684C47.3659 8.68552 49.3465 11.4147 50.2108 14.2038L50.2228 14.2157Z"
        fill="white"
      />
      <path
        d="M47.2819 16.1549C47.1978 16.1549 47.1258 16.1429 47.0418 16.107C46.8857 16.0352 46.7657 15.9155 46.7057 15.7479C45.8054 13.1145 43.9328 10.6726 41.9281 9.52343C41.2079 9.10448 40.5357 8.87705 39.9114 8.84114C39.4793 8.8172 39.0832 8.87705 38.7591 9.03266C38.471 9.16433 38.1229 9.0566 37.9788 8.76931C37.8348 8.48203 37.9308 8.1349 38.2069 7.97929L40.9438 6.38726C42.1922 5.74087 43.7647 5.90845 45.3852 6.85409C47.738 8.20672 49.8627 11.0317 50.799 14.0481C50.883 14.3354 50.751 14.6466 50.4749 14.7663L47.5339 16.107C47.4499 16.1429 47.3659 16.1669 47.2819 16.1669V16.1549ZM40.8958 7.78776C41.4359 7.93141 41.9761 8.15884 42.5403 8.48203C44.653 9.70298 46.5616 12.0611 47.63 14.7424L49.4906 13.8925C48.5783 11.3429 46.7657 9.02069 44.785 7.88352C43.5366 7.15335 42.3843 6.99774 41.532 7.44063L40.9078 7.79973L40.8958 7.78776Z"
        fill="#455068"
      />
      <path
        d="M47.87 18.8721C47.87 19.8177 47.69 20.6078 47.3659 21.2302C47.1498 21.6731 46.8617 22.0202 46.5256 22.2836C46.4416 22.3554 46.3455 22.4153 46.2735 22.4631C45.2292 23.1095 43.7767 23.0377 42.1682 22.0921C39.0832 20.2726 36.5984 15.9035 36.5984 12.3125C36.5984 10.6127 37.1385 9.39176 38.0508 8.75735L38.459 8.50597C38.459 8.50597 38.483 8.48203 38.495 8.48203C38.9151 8.29051 39.4073 8.20672 39.9354 8.23066C40.6317 8.26657 41.4119 8.51794 42.2282 8.99675C44.4489 10.2656 46.3575 12.8631 47.2818 15.5564C47.666 16.6816 47.882 17.8068 47.87 18.8721Z"
        fill="white"
      />
      <path
        d="M44.737 23.4926C43.8487 23.4926 42.8764 23.1933 41.8681 22.6068C38.579 20.6676 35.9982 16.1429 35.9982 12.3125C35.9982 10.481 36.5984 9.04463 37.7027 8.26657L38.1589 7.99126C38.1589 7.99126 38.2189 7.95535 38.2549 7.94338C38.7711 7.70398 39.3473 7.60822 39.9835 7.64413C40.7997 7.69201 41.664 7.97929 42.5403 8.48203C44.8211 9.78678 46.8497 12.4322 47.858 15.3649C48.2782 16.5978 48.4822 17.7828 48.4702 18.8841C48.4702 19.9135 48.2662 20.7873 47.8941 21.5055C47.654 21.9963 47.3179 22.4153 46.9097 22.7504C46.8137 22.8462 46.6697 22.93 46.5376 23.0018C46.0094 23.337 45.3972 23.4926 44.737 23.4926ZM38.7591 9.03266L38.3629 9.27206C37.6307 9.78678 37.1986 10.8761 37.1986 12.3125C37.1986 15.7 39.5633 19.8537 42.4803 21.5774C43.8367 22.3674 45.0731 22.511 45.9614 21.9484C46.0454 21.9005 46.0935 21.8766 46.1415 21.8287C46.4416 21.5893 46.6697 21.302 46.8377 20.9549C47.1258 20.4043 47.2698 19.698 47.2819 18.8601C47.2819 17.9025 47.1018 16.8492 46.7297 15.7359C45.8294 13.1025 43.9568 10.6606 41.9521 9.51147C41.2319 9.09251 40.5597 8.86508 39.9355 8.82917C39.5033 8.80523 39.1072 8.86508 38.7831 9.02069L38.7591 9.03266Z"
        fill="#455068"
      />
      <path
        d="M73.7865 84.6119C73.7865 85.9645 73.3303 86.9461 72.5981 87.4608L72.262 87.6523L70.3774 88.7296C70.6415 88.5142 70.8815 88.2269 71.0616 87.8678C71.3257 87.365 71.4697 86.7187 71.4697 85.9526C71.4697 85.0787 71.3017 84.157 70.9896 83.2473L73.3783 82.1461C73.6424 82.972 73.7865 83.8099 73.7865 84.6119Z"
        fill="#455068"
      />
      <path
        d="M70.3774 89.3281C70.1853 89.3281 69.9932 89.2324 69.8852 89.0648C69.7051 88.8134 69.7532 88.4543 70.0052 88.2628C70.2213 88.0832 70.3894 87.8678 70.5214 87.6044C70.7495 87.1615 70.8695 86.6109 70.8695 85.9406C70.8695 85.1745 70.7255 84.3246 70.4254 83.4388C70.3294 83.1515 70.4614 82.8284 70.7375 82.7087L73.1263 81.6074C73.2823 81.5356 73.4624 81.5356 73.6184 81.6074C73.7745 81.6792 73.8945 81.8109 73.9425 81.9665C74.2426 82.8762 74.3867 83.774 74.3747 84.6119C74.3747 86.1082 73.8585 87.2932 72.9342 87.9516L70.6655 89.2443C70.5694 89.2922 70.4734 89.3281 70.3654 89.3281H70.3774ZM71.7218 83.5705C71.9619 84.4084 72.0819 85.2104 72.0699 85.9526C72.0699 86.3835 72.0219 86.7785 71.9379 87.1496L72.286 86.9461C72.8382 86.5511 73.1743 85.7132 73.1743 84.6119C73.1743 84.0852 73.1143 83.5466 72.9822 82.9959L71.7098 83.5825L71.7218 83.5705Z"
        fill="#455068"
      />
      <path
        d="M73.3903 82.158L71.0016 83.2593C70.2573 81.0688 68.6968 78.962 66.8842 77.9206C66.224 77.5376 65.5878 77.3341 65.0236 77.2982C64.5915 77.2742 64.1953 77.3341 63.8472 77.5017L65.8759 76.3166L66.08 76.1969C66.8962 75.778 67.9886 75.8737 69.201 76.568C71.0736 77.6453 72.6821 79.8717 73.3903 82.1461V82.158Z"
        fill="white"
      />
      <path
        d="M70.9896 83.8458C70.9055 83.8458 70.8335 83.8338 70.7495 83.7979C70.5934 83.7261 70.4734 83.6064 70.4134 83.4388C69.6931 81.3201 68.1806 79.357 66.5721 78.4233C66.0079 78.0882 65.4678 77.9086 64.9756 77.8847C64.6275 77.8727 64.3394 77.9086 64.0873 78.0283C63.7992 78.16 63.4511 78.0523 63.307 77.765C63.163 77.4777 63.259 77.1306 63.5351 76.975L65.7679 75.6822C66.8362 75.1316 68.1446 75.2752 69.5011 76.0533C71.4457 77.1785 73.1983 79.5007 73.9545 81.9785C74.0386 82.2658 73.9065 82.577 73.6304 82.6967L71.2416 83.7979C71.1576 83.8338 71.0736 83.8578 70.9896 83.8578V83.8458ZM66.068 76.8912C66.4281 77.0109 66.8002 77.1785 67.1843 77.3939C68.9009 78.3755 70.4494 80.2787 71.3257 82.4333L72.6341 81.8348C71.8899 79.8239 70.4614 77.9924 68.8889 77.0827C67.9046 76.5081 67.0043 76.3884 66.344 76.7236L66.068 76.8912Z"
        fill="#455068"
      />
      <path
        d="M71.4697 85.9526C71.4697 86.7186 71.3137 87.365 71.0616 87.8678C70.8815 88.2269 70.6534 88.5142 70.3774 88.7296C70.3053 88.7895 70.2333 88.8374 70.1733 88.8733C69.321 89.4 68.1446 89.3401 66.8362 88.574C64.3274 87.0897 62.2987 83.5346 62.2987 80.6019C62.2987 79.2253 62.7429 78.2199 63.4871 77.7051L63.8232 77.5016C63.8232 77.5016 63.8352 77.4897 63.8592 77.4897C64.2073 77.3341 64.6035 77.2622 65.0356 77.2862C65.6118 77.3221 66.236 77.5256 66.8962 77.9086C68.6968 78.95 70.2573 81.0568 71.0136 83.2473C71.3257 84.157 71.5057 85.0787 71.4937 85.9526H71.4697Z"
        fill="white"
      />
      <path
        d="M68.9249 89.8189C68.1806 89.8189 67.3524 89.5795 66.5241 89.0887C63.8112 87.4847 61.6865 83.762 61.6865 80.6019C61.6865 79.0697 62.2027 77.8727 63.127 77.2144L63.4991 76.9869C63.4991 76.9869 63.5591 76.951 63.5951 76.939C64.0273 76.7356 64.5194 76.6518 65.0596 76.6877C65.7438 76.7236 66.4641 76.963 67.1963 77.3819C69.0689 78.4593 70.7495 80.6378 71.5778 83.0438C71.9259 84.0613 72.0939 85.0428 72.0939 85.9406C72.0939 86.8024 71.9259 87.5326 71.6138 88.1311C71.4097 88.5381 71.1336 88.8972 70.7735 89.1845C70.6775 89.2683 70.5694 89.3401 70.4494 89.4C70.0172 89.6753 69.5011 89.8069 68.9369 89.8069L68.9249 89.8189ZM64.0993 78.0283L63.7872 78.2199C63.223 78.6149 62.8989 79.4767 62.8989 80.6019C62.8989 83.3311 64.8075 86.6708 67.1483 88.0593C68.2167 88.6817 69.177 88.7895 69.8612 88.3705L70.0052 88.2748C70.2213 88.0952 70.3894 87.8797 70.5214 87.6164C70.7495 87.1855 70.8695 86.6229 70.8695 85.9526C70.8695 85.1865 70.7255 84.3366 70.4254 83.4508C69.7051 81.3321 68.1926 79.369 66.5841 78.4353C66.0199 78.1001 65.4798 77.9206 64.9876 77.8967C64.6515 77.8847 64.3514 77.9206 64.0993 78.0403V78.0283Z"
        fill="#455068"
      />
    </svg>
  );
}
