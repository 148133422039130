import { NvRouterLink, NvTypography } from '@novaera/core';
import React from 'react';
import { PopupMenuItemWrapper } from './styled';
import { PopupMenuItemProps } from './types';

export const PopupMenuItem: React.FC<React.PropsWithChildren<PopupMenuItemProps>> = ({
  onClick,
  label,
  labelProps,
  to,
  icon,
}) => {
  return (
    <NvRouterLink to={to}>
      <PopupMenuItemWrapper onClick={onClick}>
        <NvTypography variant="h4" {...labelProps} flex="1 1 auto" minWidth={0}>
          {label}
        </NvTypography>
        {icon}
      </PopupMenuItemWrapper>
    </NvRouterLink>
  );
};
