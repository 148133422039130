import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { isAxiosError, useAxiosErrorHandler, useToast } from '@novaera/core';
import { removeCookieForSpecificDomain } from '@novaera/utils';
import { useLogout as useLogoutService } from '../../services/use-log-out';

export const useLogout = () => {
  const logout = useLogoutService();
  const { addToast } = useToast();
  const { axiosErrorHandler } = useAxiosErrorHandler();

  const handleLogoutClick = async () => {
    logout.mutate(undefined, {
      onSuccess: ({ redirectUri }) => {
        removeCookieForSpecificDomain(LOCAL_STORAGE_ACCESS_KEY, `${ApplicationConfig.Actioner.host}`);
        removeCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, `${ApplicationConfig.Actioner.host}`);
        // this is to remove leftovers with app.xxxx
        removeCookieForSpecificDomain(LOCAL_STORAGE_ACCESS_KEY, `${ApplicationConfig.Actioner.baseHost}`);
        removeCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, `${ApplicationConfig.Actioner.baseHost}`);

        window.location.href = redirectUri;
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          axiosErrorHandler(error);
        } else if (error instanceof Error) {
          addToast(`User can not logout, reason: ${error.message}`, { variant: 'error' });
        } else {
          addToast(`User can not logout, reason: ${JSON.stringify(error)}`, { variant: 'error' });
        }
      },
    });
  };

  return {
    onLogoutClick: handleLogoutClick,
  };
};
