import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const BodyWrapper = styled(NvFlex)`
  flex-direction: column;
  height: 100%;
`;

export const HeaderWrapper = styled(NvFlex)`
  position: relative;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  height: 47px;
  flex: 0 0 auto;
  justify-content: center;
  ${({ theme }) => theme.elevations.e100};
`;

export const ContentWrapper = styled(NvFlex)`
  flex-direction: row;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
`;

export const LeftContent = styled(NvFlex)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
export const RightContent = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  margin-left: -12px;
  width: calc(100% + 12px);
  height: 100%;
  overflow: auto;
`;

export const FieldLogoWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  gap: 4px;
  padding: 0px 8px 0px 4px;
  height: 24px;
  ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 6px;
`;
