import { NvInfoIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import {
  DependencyInputComponentStateReturnValue,
  isMissingDependencyForDynamicInput,
} from '../../dynamic-input/providers/controller/use-check-dependency-value/types';
import { ContentWrapper } from './styled';

export const DynamicOptionsWarningMessage = ({
  dynamicInputComponentState,
}: {
  dynamicInputComponentState: DependencyInputComponentStateReturnValue;
}) => {
  const theme = useTheme();

  assert(
    isMissingDependencyForDynamicInput(dynamicInputComponentState),
    new Error('State should be missing value'),
    'ERROR'
  );

  return (
    <ContentWrapper>
      <NvInfoIcon
        sx={{
          width: '16px',
          height: '16px',
        }}
        htmlColor={theme.palette.nv_neutral[300]}
      />
      <NvTypography variant="h6" textColor="subtle">
        Enter <b>{dynamicInputComponentState.missingDependencies.join(',')}</b> input values above to view available
        options here.
      </NvTypography>
    </ContentWrapper>
  );
};
