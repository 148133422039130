import { useGetNode } from '@novaera/actioner-service';
import {
  NvCustomFolderAddIcon,
  NvCustomFolderDeleteIcon,
  NvCustomFolderListIcon,
  NvCustomFolderShareIcon,
  NvCustomFolderTransferIcon,
  NvFolderOpenRounded,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { useGetWorkflowQueryParams } from '../../controllers/use-get-workflow-query-params';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type FileProps = BaseNodeProps;

export const File: FC<FileProps> = (props) => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { node } = useGetNode<'file'>({ appId: userAppId, workflowId, nodeAlias: props.alias });
  const theme = useTheme();
  return (
    <DraggableItem nodeId={props.id}>
      <Node
        type={NodeType.FILE}
        {...props}
        icon={
          node?.nodeOperation.type === 'put' ? (
            <NvCustomFolderAddIcon htmlColor={theme.palette.nv_node.file} />
          ) : node?.nodeOperation.type === 'transfer' ? (
            <NvCustomFolderTransferIcon htmlColor={theme.palette.nv_node.file} />
          ) : node?.nodeOperation.type === 'list' ? (
            <NvCustomFolderListIcon htmlColor={theme.palette.nv_node.file} />
          ) : node?.nodeOperation.type === 'change-accessibility' ? (
            <NvCustomFolderShareIcon htmlColor={theme.palette.nv_node.file} />
          ) : node?.nodeOperation.type === 'delete' ? (
            <NvCustomFolderDeleteIcon htmlColor={theme.palette.nv_node.file} />
          ) : (
            <NvFolderOpenRounded htmlColor={theme.palette.nv_node.file} />
          )
        }
      />
    </DraggableItem>
  );
};
