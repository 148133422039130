import {
  AccountCircleIcon,
  NvButton,
  NvDivider,
  NvFlex,
  NvHistoryIcon,
  NvImage,
  NvReactJson,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { useMemo } from 'react';

import { VersionHistoryFocusState, VersionHistoryJsonViewWrapper } from './styled';
import { VersionHistoryDetailProps } from './types';

export const VersionHistoryDetail: React.FC<React.PropsWithChildren<VersionHistoryDetailProps>> = ({
  open,
  onClose,
  onRestore,
  versionDetail,
}) => {
  const theme = useTheme();
  const parsedVersionDetailProperties = useMemo(() => {
    if (versionDetail) {
      try {
        return JSON.parse(versionDetail.properties);
      } catch (error) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [versionDetail]);
  return (
    <VersionHistoryFocusState
      open={open}
      onClose={onClose}
      maxWidth="100%"
      renderHeader={({ close }) => (
        <NvFlex
          direction="row"
          width="100%"
          padding="24px 0 12px 0"
          flex="0 0 auto"
          borderBottom={`1px solid ${theme.palette.nv_neutral[30]};`}
        >
          <NvFlex direction="row" alignItems="center" gap="10px" flex="1 1 auto" minWidth={0}>
            <NvHistoryIcon />
            {versionDetail ? (
              <NvTypography variant="h3">{format(new Date(versionDetail.createdAt), 'MMM d, p')}</NvTypography>
            ) : (
              <NvSkeleton variant="rectangular" height="24px" width="120px" />
            )}

            <NvDivider orientation="vertical" sx={{ height: '12px' }} />
            {versionDetail ? (
              <>
                <NvImage
                  src={versionDetail.logoUrl}
                  imageShape="circle"
                  size="small"
                  FallBack={<AccountCircleIcon />}
                />
                <NvTypography variant="h4" textColor="secondary" noWrap>
                  {versionDetail.username}
                </NvTypography>
              </>
            ) : (
              <NvSkeleton variant="rectangular" height="24px" width="120px" />
            )}
          </NvFlex>
          <NvFlex direction="row" alignItems="center" gap="8px" flex="0 0 auto">
            <NvButton
              color="secondary"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Cancel
            </NvButton>
            <NvButton
              size="small"
              onClick={() => {
                if (versionDetail) {
                  onRestore(versionDetail.properties);
                  close();
                }
              }}
            >
              Restore
            </NvButton>
          </NvFlex>
        </NvFlex>
      )}
    >
      <NvFlex width="100%" paddingBottom="24px" flex="1 1 auto" minHeight="0">
        {versionDetail ? (
          <VersionHistoryJsonViewWrapper>
            <NvReactJson src={parsedVersionDetailProperties} collapsed={1} />
          </VersionHistoryJsonViewWrapper>
        ) : (
          <NvSkeleton variant="rectangular" width="100%" height="100%" />
        )}
      </NvFlex>
    </VersionHistoryFocusState>
  );
};
