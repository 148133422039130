import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS_JOBS } from '../keys';
import { JobType } from '../types';
import { GetScheduledJobsParams, GetScheduledJobsResponse } from './types';

const getScheduledJobs = async ({ appId, limit = 10, timezoneId, pageParam }: GetScheduledJobsParams) => {
  const result = await NvAxios.get<GetScheduledJobsResponse>(`/v2/apps/${appId}/jobs/scheduled`, {
    params: {
      limit: limit,
      'display-tz-id': timezoneId,
      ...(pageParam ? { next: pageParam } : {}),
    },
  });
  return result?.data;
};

export const useGetScheduledJobs = ({ appId, limit, timezoneId }: GetScheduledJobsParams) => {
  return useInfiniteQuery<GetScheduledJobsResponse>(
    QUERY_KEYS_JOBS.list(appId, JobType.SCHEDULED, timezoneId),
    (props) => getScheduledJobs({ appId, timezoneId, limit, ...props }),
    {
      enabled: !!timezoneId && !!appId,
      getNextPageParam: (lastPage) => lastPage?.next,
    }
  );
};
