import { NvFlex, NvTypography } from '@novaera/core';
import { FC } from 'react';

export interface SimpleLabelLayoutProps {
  simpleLabel: string;
}

export const SimpleLabelLayout: FC<React.PropsWithChildren<SimpleLabelLayoutProps>> = ({ simpleLabel }) => {
  return (
    <NvFlex flex="0 0 auto" minWidth={0} gap="4px">
      <NvFlex direction="row" alignItems="center">
        <NvTypography variant="h5" noWrap>
          {simpleLabel}
        </NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
