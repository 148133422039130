import { SchemaType } from '@novaera/ah-common';
import { CodeInput, NvDateTimePicker, NvTextField } from '@novaera/core';

import { BooleanComponent } from '../../../../../../../components/data-model-layout/right-content/field/default-value-property/boolean-component';
import { ListSchemaInput } from './list-schema-input';
import { SelectItemFromModel } from './select-item-form-model';

export const RECORD_SCHEMA_FORM_COMPONENT: Record<SchemaType, JSX.Element> = {
  [SchemaType.string]: <NvTextField />,
  [SchemaType.dateTime]: (
    <NvDateTimePicker datePickerProps={{ fullWidth: true }} timePickerProps={{ fullWidth: true }} />
  ),
  [SchemaType.email]: <NvTextField type="email" />,
  [SchemaType.float]: <NvTextField type="number" />,
  [SchemaType.integer]: <NvTextField type="number" />,
  [SchemaType.phone]: <NvTextField />,
  [SchemaType.url]: <NvTextField />,
  [SchemaType.uuid]: <NvTextField />,
  [SchemaType.boolean]: <BooleanComponent />,
  [SchemaType.recordReference]: <SelectItemFromModel />,
  [SchemaType.array]: <ListSchemaInput />,
  [SchemaType.map]: <CodeInput context={{}} expectedType="object" growingHeight initialHeight="150px" />,
  [SchemaType.object]: <CodeInput context={{}} expectedType="object" growingHeight initialHeight="150px" />,
};

export const AUTOGENERATED_FIELD_NAMES = ['id'];
