import { CatalogEntityParameters } from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, useConfirmDialog, useForm, useFormState } from '@novaera/core';
import { noop } from 'lodash';
import { useMemo } from 'react';
import { useCatalogPermission } from '../../../../controllers/use-catalog-permission';
import { useCatalogUtil } from '../../../../controllers/use-catalog-util';
import { useEntityTypeController } from '../../../../controllers/use-entity-type';
import { useListEntityTypes } from '../../../../controllers/use-list-entity-types';
import { useSelectedCatalogEntity } from '../../../../controllers/use-selected-catalog-entity';

export const useCatalogHeader = () => {
  const { hasCatalogEditPermission } = useCatalogPermission();

  const { selectedEntityType: catalogEntityType } = useSelectedCatalogEntity();

  const { openConfirm } = useConfirmDialog();
  const { dirty, submitting } = useFormState<CatalogEntityParameters>();
  const { submit, reset, setConfig } = useForm<CatalogEntityParameters>();

  const { deleteCatalogEntityType, isDeleteCatalogEntityTypeLoading } = useEntityTypeController();

  const { getPreviousEntityTypeFromList } = useListEntityTypes();

  const { navigateToCatalogEntityType } = useCatalogUtil();

  const headerMenuActions = useMemo(() => {
    if (!catalogEntityType || catalogEntityType?.managed || !hasCatalogEditPermission) {
      return;
    }

    return [
      {
        name: 'Delete',
        isLoading: isDeleteCatalogEntityTypeLoading,
        onClick: () => {
          openConfirm({
            title: 'Are you sure?',
            message:
              'Are you sure you want to delete this catalog? This action cannot be undone. Please confirm to proceed.',
            onConfirm: () => {
              const previousEntityType = getPreviousEntityTypeFromList(catalogEntityType.id);
              deleteCatalogEntityType(
                { id: catalogEntityType.id },
                {
                  onSuccess: () => {
                    if (previousEntityType) {
                      navigateToCatalogEntityType(previousEntityType.id);
                    }
                  },
                }
              );
            },
          });
        },
        icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
      },
    ];
  }, [
    catalogEntityType,
    hasCatalogEditPermission,
    isDeleteCatalogEntityTypeLoading,
    openConfirm,
    getPreviousEntityTypeFromList,
    deleteCatalogEntityType,
    navigateToCatalogEntityType,
  ]);

  return {
    headerMenuActions,
    dirty: hasCatalogEditPermission && dirty,
    submit: hasCatalogEditPermission ? submit : noop,
    reset,
    setConfig,
    submitting,
    catalogEntityType,
    hasCatalogEditPermission,
  };
};
