import { Vertices } from '@novaera/actioner-service';

export const getComponentChildren = ({
  nodes,
  currentNodeId,
  nodeToStop,
}: {
  nodes: Vertices;
  currentNodeId: string;
  nodeToStop?: string;
}) => {
  let childNodes: string[] = [];
  const node = nodes.find((n) => n.alias === currentNodeId);
  if (!node) {
    return childNodes;
  }
  if (node.nextAlias && node.nextAlias !== nodeToStop) {
    childNodes = [...childNodes, node.nextAlias];
    const returnedChildNodes = getComponentChildren({
      nodes,
      currentNodeId: node.nextAlias,
      nodeToStop: node.nextAlias,
    });
    childNodes = [...childNodes, ...returnedChildNodes];
  }

  if (node.type === 'branchJunction') {
    for (const branch of node.firstInnerAliases) {
      childNodes = [...childNodes, branch];
      const returnedChildNodes = getComponentChildren({
        nodes,
        currentNodeId: branch,
        nodeToStop: node.nextAlias,
      });
      childNodes = [...childNodes, ...returnedChildNodes];
    }
  } else if (node.type === 'workflowCondition') {
    childNodes = [...childNodes, node.falseAlias, node.trueAlias];
    const returnedChildNodesFalse = getComponentChildren({
      nodes,
      currentNodeId: node.falseAlias,
      nodeToStop: node.nextAlias,
    });
    const returnedChildNodesTrue = getComponentChildren({
      nodes,
      currentNodeId: node.trueAlias,
      nodeToStop: node.nextAlias,
    });
    childNodes = [...childNodes, ...returnedChildNodesFalse, ...returnedChildNodesTrue];
  } else if (node.type === 'loop') {
    childNodes = [...childNodes, node.firstInnerAlias];
    const returnedChildNodesInner = getComponentChildren({
      nodes,
      currentNodeId: node.firstInnerAlias,
      nodeToStop: node.nextAlias,
    });
    childNodes = [...childNodes, ...returnedChildNodesInner];
  }
  return childNodes;
};
