import { useGetUserApp, useGetUserAppAsync } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';

type UseGetAppIdFromDependencyAsyncParams = { dependencyId: string; callerAppId: string };
type UseGetAppIdFromDependency = { mode: 'async' } | { rootAppID: string; mode: 'sync' | 'both' };

export const useGetAppIdFromDependency = (param: UseGetAppIdFromDependency) => {
  const { data: userApp } = useGetUserApp(param.mode !== 'async' ? param.rootAppID : undefined);
  const { mutateAsync: getUserAppAsync } = useGetUserAppAsync();

  const getAppIdFromDependency = (dependencyId: string) => {
    // assertion needs here since this hook may not need to have be provided parameters. We must be sure to have rootAppID
    // if we want to get appId from dependency in sync mode.
    assert(
      param.mode !== 'async' && !!param?.rootAppID,
      new Error('To get appId from dependency in sync mode, rootAppID should be provided.')
    );

    return userApp?.dependencies?.find((dependency) => dependency.id === dependencyId)?.appId;
  };

  const getAppIdFromDependencyAsync = async ({ callerAppId, dependencyId }: UseGetAppIdFromDependencyAsyncParams) => {
    const result = await getUserAppAsync(callerAppId);
    return result?.dependencies?.find((dependency) => dependency.id === dependencyId)?.appId;
  };

  return {
    getAppIdFromDependency,
    getAppIdFromDependencyAsync,
  };
};
