import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DOCUMENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DOCS } from '../keys';
import { CreateDocumentResponse } from '../use-create-doc/types';
import { useUpdateInfiniteSearchCache } from '../use-update-infinite-search-cache';
import { UpdateDocumentParams, UpdateDocumentResponse } from './types';

const updateDocument: (params: UpdateDocumentParams) => Promise<UpdateDocumentResponse> = async ({
  appId,
  docId,
  payload,
}) => {
  const result = await NvAxios.put<UpdateDocumentResponse>(`${DOCUMENTS_ROOT_PATH(appId)}/${docId}`, {
    ...payload,
  });
  return result?.data;
};

export const useUpdateDocument = () => {
  const cache = useQueryClient();
  const { updateInfiniteSearchCache } = useUpdateInfiniteSearchCache();

  return useMutation(updateDocument, {
    onSuccess: (doc, { appId, docId }) => {
      updateInfiniteSearchCache({
        appId,
        updateFunction: ({ old }) => {
          return (
            old?.pages?.map(({ documents, ...others }) => {
              return {
                ...others,
                documents: documents.map((oldDoc) => {
                  if (oldDoc.id !== docId) {
                    return oldDoc;
                  } else {
                    return doc;
                  }
                }),
              };
            }) ?? [
              {
                documents: [doc],
              },
            ]
          );
        },
      });

      cache.setQueryData<CreateDocumentResponse>(QUERY_KEYS_DOCS.detail(appId, docId), {
        ...doc,
      });
    },
  });
};
