import { MetaUnit } from './types';

export const META_UNITS_BY_ID: Record<string, MetaUnit> = {
  1: {
    title: 'Gmail Slack integration',
    description: 'Send emails through Gmail without leaving Slack with Actioner.',
  },
  2: {
    title: 'Slack-first service with Jira service management',
    description:
      'Create Jira service desk ticket from Slack with Actioner. List SLA breaching requests and subscribe to a request. Easily assign requests to yourself or other agents in Slack.',
  },
  7: {
    title: 'Utilities app for Slack',
    description:
      'Send a Giphy, check out the weather, quickly translate a word, learn the latest value of your altcoin, and more without leaving Slack.',
  },
  10: {
    title: 'Jira Cloud | Manage projects in Slack',
    description:
      'Create Jira issue from Slack, and view your open and done Jira issues. Check unassigned, recently updated, or in-progress issues in your project.',
  },
  26: {
    title: 'Conversational ticketing with Zendesk',
    description:
      'Actioner provides Zendesk and Slack integration for specific channels. Create a Zendesk ticket from Slack, update ticket status, create workflows, and enable conversational ticketing.',
  },
  44: {
    title: 'Slack-first incident management  | PagerDuty',
    description:
      'Integrate PagerDuty with Slack seamlessly. Build workflows with Slack and webhook. Solve incidents and collaborate with your team without leaving Slack.',
  },
  80: {
    title: 'Slack-first sales | HubSpot',
    description:
      'Integrate HubSpot with Slack seamlessly. Build workflows with Slack, HubSpot, and webhook. Create deals, assign contacts, and view company records in Slack.',
  },
  137: {
    title: 'Bitbucket | PR management in Slack',
    description:
      'Connect Bitbucket to Slack with Actioner. Get bitbucket pull request Slack notification. Manage pull requests without leaving your workspace.',
  },
  138: {
    title: 'GitHub | PR management in Slack',
    description:
      'Get GitHub PR notifications in Slack with Actioner. Approve, and merge pull requests without leaving Slack.',
  },
  142: {
    title: 'GitLab | PR management in Slack',
    description:
      'Collaborate with your team on merge request reviews through dedicated Slack channels. Actioner provides the best GitLab Slack integration for DevOps teams.',
  },
  146: {
    title: 'GitHub Actions workflows in Slack',
    description:
      'Seamlessly integrate GitHub Actions with Slack. Call Actioner app and run your action just by typing the name of your workflow.',
  },
  148: {
    title: 'Actioner No-code app for Slack builder tutorial',
    description:
      'Learn how to build actions and workflows to run in Slack. Create actionable Slack messages, dedicate Slack channels for specific purposes, and turn Slack into your digital HQ with Actioner.',
  },
};

export const DEFAULT_APP_DIRECTORY_TITLE = 'Actioner app directory | Find Slack-first solutions';

export const DEFAULT_APP_DIRECTORY_META_DESCRIPTION =
  'Integrate your tech stack with Slack seamlessly. Run any action in Slack and build workflows with Slack, webhook, and your favorite tools.';
