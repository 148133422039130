import { NvAxios, useMutation } from '@novaera/core';

import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_WORKFLOWS_SEARCH } from '../../workflows/keys';
import { SearchWorkflowResponse } from '../../workflows/use-search-workflows/types';
import { CLONE_ROOT } from '../constants';
import { WorkflowCloneParams, WorkflowCloneResponse } from '../types';

const workflowClone: (params: WorkflowCloneParams) => Promise<WorkflowCloneResponse> = async (params) => {
  const { appId, workflowId, ...rest } = params;
  const result = await NvAxios.post<WorkflowCloneResponse>(
    `${CLONE_ROOT({ appId })}/workflows/${workflowId}/clone`,
    rest.payload
  );
  return result?.data;
};

export const useWorkflowClone = () => {
  const cache = useQueryClient();
  return useMutation(workflowClone, {
    onSuccess: ({ clonedWorkflow }, { onSuccess }) => {
      onSuccess({ clonedWorkflow });
      const queryCached = cache.getQueryCache().findAll({
        queryKey: QUERY_KEYS_WORKFLOWS_SEARCH.all,
      });
      queryCached.forEach((query) => {
        cache.setQueryData<InfiniteData<SearchWorkflowResponse>>(query.queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old.pages.map(({ workflows, ...others }) => ({
                ...others,
                workflows: [clonedWorkflow, ...workflows],
              })),
            };
          }
          return old;
        });
      });
    },
  });
};
