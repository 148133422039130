import { NvConditionalRender, NvConditionalWrap, NvFlex, NvMenuWithItems, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { BodyItemWrapper } from './styled';
import { ConnectionDetailBodyItemProps } from './types';

export const BodyItem: React.FC<React.PropsWithChildren<ConnectionDetailBodyItemProps>> = ({
  description,
  title,
  children,
  menuItems,
  hasRequiredIndicator,
}) => {
  const theme = useTheme();
  return (
    <BodyItemWrapper>
      <NvFlex gap="2px">
        <NvConditionalWrap
          condition={menuItems !== undefined}
          wrap={(children) => (
            <NvFlex flexDirection="row" gap={'16px'}>
              {children}
            </NvFlex>
          )}
        >
          <>
            <NvConditionalWrap
              condition={menuItems !== undefined}
              wrap={(children) => (
                <NvFlex flex="1 1 auto" minWidth="0">
                  {children}
                </NvFlex>
              )}
            >
              <NvFlex direction="row" alignItems="center">
                <NvConditionalRender when={() => typeof title === 'string'}>
                  <NvTypography variant="h3">{title}</NvTypography>
                </NvConditionalRender>
                <NvConditionalRender when={() => typeof title !== 'string'}>{title}</NvConditionalRender>
                {hasRequiredIndicator && (
                  <NvTypography variant="h3" color={theme.palette.nv_error[40]}>
                    *
                  </NvTypography>
                )}
              </NvFlex>
            </NvConditionalWrap>
            <NvConditionalRender when={() => menuItems !== undefined}>
              <NvMenuWithItems menuItems={menuItems ?? []} />
            </NvConditionalRender>
          </>
        </NvConditionalWrap>
        <NvTypography variant="body1">{description}</NvTypography>
      </NvFlex>
      {children}
    </BodyItemWrapper>
  );
};
