import { ThemeMode, Themes } from './types';
import lightDefault from './default/light';
import darkDefault from './default/dark';

import lightAlternative from './alternative/light';
import darkAlternative from './alternative/dark';

import lightDracula from './dracula/light';
import darkDracula from './dracula/dark';

import lightWinter from './winter/light';
import darkWinter from './winter/dark';

import { typography } from '../constants/typography';
import { elevations } from '../constants/elevations';
import { mixins } from '../mixins';
import { COMMON_FONT_FAMILY } from '../constants';

const baseTheme = {
  typography: {
    fontFamily: COMMON_FONT_FAMILY,
    fontSize: 14,
    button: typography.h4,
    caption: typography.h6,
    ...typography,
  },
  elevations,
  mixins,
};

// https://bareynol.github.io/mui-theme-creator/
export const themes = (mode: ThemeMode) => ({
  [Themes.Default]: {
    ...baseTheme,
    palette: {
      mode,
      ...(mode === ThemeMode.Light ? lightDefault : darkDefault),
    },
  },
  [Themes.Alternative]: {
    ...baseTheme,
    palette: {
      mode,
      ...(mode === ThemeMode.Light ? lightAlternative : darkAlternative),
    },
  },
  [Themes.Dracula]: {
    ...baseTheme,
    palette: {
      mode,
      ...(mode === ThemeMode.Light ? lightDracula : darkDracula),
    },
  },
  [Themes.Winter]: {
    ...baseTheme,
    palette: {
      mode,
      ...(mode === ThemeMode.Light ? lightWinter : darkWinter),
    },
  },
});
