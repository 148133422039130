import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CATALOG_ENTITY_TYPES_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_ENTITY_TYPES } from '../keys';
import { useUpdateCatalogEntityCache } from '../use-update-catalog-entity-cache';
import { DeleteEntityTypeParams } from './types';

const deleteCatalogEntityType = async ({ id }: DeleteEntityTypeParams) => {
  await NvAxios.delete<void>(`${CATALOG_ENTITY_TYPES_ROOT_PATH}/${id}`);
};

export const useDeleteCatalogEntityType = () => {
  const { updateInfiniteSearchCache } = useUpdateCatalogEntityCache();
  const cache = useQueryClient();

  return useMutation(deleteCatalogEntityType, {
    onSuccess: (_, { id }) => {
      updateInfiniteSearchCache({
        updateFunction: ({ old }) => {
          return (
            old?.pages.map(({ entityTypes, ...others }) => {
              return {
                ...others,
                entityTypes: entityTypes?.filter((entityType) => entityType.id !== id),
              };
            }) ?? []
          );
        },
      });
      cache.removeQueries(QUERY_KEY_CATALOG_ENTITY_TYPES.detail(id));
    },
  });
};
