import { SetupWorkflow } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { FormIdentifierProvider } from '../../../../../providers/form-identifier-provider';
import { InstallStepsLoading } from '../install-steps-loading';
import { SetupWorkflowItem } from './setup-workflow-item';

export const RunSetupWorkflow: FC<
  React.PropsWithChildren<{ workflows: SetupWorkflow[]; appId: string; isAutoRunWorkflowsLoading: boolean }>
> = ({ workflows, appId, isAutoRunWorkflowsLoading }) => {
  return isAutoRunWorkflowsLoading ? (
    <InstallStepsLoading />
  ) : (
    <NvFlex gap="16px">
      <NvFlex gap="4px">
        <NvTypography variant="h2">Setup workflows</NvTypography>
        <NvTypography variant="body1">Setup workflows offers guidance for your initial app configuration.</NvTypography>
      </NvFlex>
      <NvFlex gap="12px">
        {workflows.map((workflow, index) => (
          <FormIdentifierProvider key={`setup_workflow_${workflow.id}`}>
            <SetupWorkflowItem setupWorkflow={workflow} order={index + 1} appId={appId} />
          </FormIdentifierProvider>
        ))}
      </NvFlex>
    </NvFlex>
  );
};
