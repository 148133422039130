import { useGetSlackWorkspace } from '@novaera/actioner-service';
import { Initial, NvDivider, NvFlex, NvImage, NvSkeleton, NvTypography } from '@novaera/core';
import { SlackWorkspaceSectionCard } from '../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/components/slack-workspace-or-workflow-connection-section/slack-workspace-section/styled';
import { InviteUsers } from '../../../../slack-workspace/ui/invite-users-modal/invite-users';

export const UserInviteViaSlack = () => {
  const { data: slackWorkspace, isInitialLoading } = useGetSlackWorkspace();

  return slackWorkspace ? (
    <NvFlex gap="16px">
      {isInitialLoading ? (
        <>
          <NvSkeleton />
          <NvSkeleton />
          <NvSkeleton />
        </>
      ) : (
        <>
          <NvTypography variant="body1">Select the users from your Slack workspace.</NvTypography>

          <SlackWorkspaceSectionCard className="isDisabled" maxWidth="300px ">
            <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
              <NvImage
                imageShape="square"
                src={slackWorkspace.iconUrl}
                FallBack={<Initial size="small" value={slackWorkspace.teamName} color={''} />}
                size="small"
                hasNoElevation
              />

              <NvTypography variant="h5" flex="1 1 auto" minWidth={0} noWrap>
                {slackWorkspace.teamName}
              </NvTypography>
            </NvFlex>
          </SlackWorkspaceSectionCard>
          <NvDivider />
          <NvFlex>{<InviteUsers teamId={slackWorkspace?.teamId} />}</NvFlex>
        </>
      )}
    </NvFlex>
  ) : null;
};
