export const QUERY_KEY_CATALOG_ENTITY_TYPES = {
  all: ['catalog-entity-types'] as const,
  list: (nameFilter?: string) =>
    nameFilter
      ? [...QUERY_KEY_CATALOG_ENTITY_TYPES.all, 'list', nameFilter]
      : ([...QUERY_KEY_CATALOG_ENTITY_TYPES.all, 'list'] as const),
  detail: (id?: string) => [...QUERY_KEY_CATALOG_ENTITY_TYPES.all, 'detail', id] as const,
  relationships: ({ entityTypeId, entityId }: { entityTypeId: string; entityId?: string }) =>
    [...QUERY_KEY_CATALOG_ENTITY_TYPES.all, 'relationships', entityTypeId, ...(entityId ? [entityId] : [])] as const,
};
