import { NvAxios, useMutation, useToast } from '@novaera/core';
import { LogoUploadParams } from '../types';

const logoUpload = async (params: LogoUploadParams) => {
  const body = new FormData();
  body.append('logo', params.logo);
  body.append('type', params.logo.type);
  const result = await NvAxios.post<{
    logoId: string;
  }>(`v1/logos/${params.type}`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return { logoId: result?.data.logoId };
};

export const useLogoUpload = () => {
  const { addToast } = useToast();
  return useMutation(logoUpload, {
    onError: () => {
      addToast('Logo could not be uploaded', { variant: 'error' });
    },
  });
};
