import { Vertex } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';

export const findRoot = (nodes: Vertex[]) => {
  const nextNodes = nodes.reduce<string[]>((previousValue, currentValue) => {
    if (currentValue.type === 'branchJunction') {
      const referenceAliases = currentValue.firstInnerAliases.map((b) => b);
      return currentValue.nextAlias
        ? [...previousValue, ...referenceAliases, currentValue.nextAlias]
        : [...previousValue, ...referenceAliases];
    } else if (currentValue.type === 'workflowCondition') {
      const falseReference = currentValue.falseAlias;
      const trueReference = currentValue.trueAlias;
      return currentValue.nextAlias
        ? [...previousValue, falseReference, trueReference, currentValue.nextAlias]
        : [...previousValue, falseReference, trueReference];
    } else if (currentValue.type === 'loop') {
      const innerReference = currentValue.firstInnerAlias;
      return currentValue.nextAlias
        ? [...previousValue, innerReference, currentValue.nextAlias]
        : [...previousValue, innerReference];
    } else {
      return currentValue.nextAlias ? [...previousValue, currentValue.nextAlias] : [...previousValue];
    }
  }, []);
  const root = nodes.find((n) => !nextNodes.includes(n.alias));

  assert(!!root, new Error('there should be 1 root at least'), 'ERROR');
  return root;
};
