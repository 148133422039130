import { styled } from '@novaera/theme-provider';
import { NvBox } from '../../box';
import { NvButton } from '../../button';

export const PlayerWrapper = styled(NvBox)<{ disabled?: boolean }>`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 100%;

  ${({ disabled }) => (disabled ? 'pointer-events:none;' : null)}

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    iframe {
      width: 100%;
      margin: 0;
    }
  }
`;

export const PlayButton = styled(NvButton)`
  &.MuiButtonBase-root {
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
    border-radius: 12px;

    &.NvButton-onlyIcon {
      &.MuiButton-sizeMedium {
        height: 52px;
        width: 72px;
      }
    }

    & .MuiButton-iconSizeMedium {
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    &.MuiButton-containedPrimary {
      color: ${({ theme }) => theme.palette.nv_neutral[0]};
      background-color: ${({ theme }) => theme.palette.nv_neutral[600]};

      &.MuiLoadingButton-loading {
        background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
      }

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.nv_neutral[400]};
      }

      & .MuiLoadingButton-loadingIndicator {
        color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }

      &.Mui-focusVisible,
      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[800]};
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.nv_neutral[900]};
      }
    }
  }
`;
