import { assert } from '@novaera/utils';
import { RefObject } from 'react';
import { createRoot } from 'react-dom/client';
import { CustomGhostImage } from '..';

export const createGhostImage = (e: DragEvent, nodeRef: RefObject<HTMLDivElement>) => {
  const image: JSX.Element = <CustomGhostImage element={nodeRef.current?.outerHTML} />;
  const ghost = document.createElement('div');
  ghost.setAttribute('id', 'ghost');
  ghost.style.transform = 'translate(-10000px, -10000px)';
  ghost.style.position = 'absolute';
  ghost.style.zIndex = '9999';

  document.body.appendChild(ghost);

  assert(e.dataTransfer !== null, new Error('e.dataTransfer should be sent'), 'ERROR');
  e.dataTransfer.setDragImage(ghost, 70, 30);

  const root = createRoot(ghost);
  root.render(image);
};
