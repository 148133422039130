import { NvAxios, useMutation } from '@novaera/core';
import { DOCUMENTS_ROOT_PATH } from '../constants';
import { useUpdateInfiniteSearchCache } from '../use-update-infinite-search-cache';
import { CreateDocumentParams, CreateDocumentResponse } from './types';

const createDocument: (params: CreateDocumentParams) => Promise<CreateDocumentResponse> = async ({
  appId,
  payload,
}) => {
  const result = await NvAxios.post<CreateDocumentResponse>(`${DOCUMENTS_ROOT_PATH(appId)}`, {
    ...payload,
  });
  return result?.data;
};

export const useCreateDocument = () => {
  const { updateInfiniteSearchCache } = useUpdateInfiniteSearchCache();

  return useMutation(createDocument, {
    onSuccess: (doc, { appId }) => {
      updateInfiniteSearchCache({
        appId,
        updateFunction: ({ old }) => {
          return (
            old?.pages.map(({ documents, ...others }) => ({
              ...others,
              documents: [...(documents ?? []), doc],
            })) ?? [
              {
                documents: [doc],
              },
            ]
          );
        },
      });
    },
  });
};
