import { NvWebhookIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { BaseNodeProps } from '../types';

export type WebhookTriggerProps = BaseNodeProps;

export const WebhookTrigger: FC<React.PropsWithChildren<WebhookTriggerProps>> = (props) => {
  const theme = useTheme();
  const { actions, ...rest } = props;
  return (
    <Node
      type={NodeType.INTEGRATION_TRIGGER}
      icon={<NvWebhookIcon htmlColor={theme.palette.nv_node.trigger} />}
      {...rest}
    />
  );
};
