import { assert } from '@novaera/utils';
import axios, {
  AxiosError as AxiosErrorType,
  AxiosHeaders,
  AxiosRequestConfig as AxiosRequestConfigType,
  AxiosRequestHeaders as AxiosRequestHeadersType,
  InternalAxiosRequestConfig as InternalAxiosRequestConfigType,
} from 'axios';
import { useCallback } from 'react';
import { useToast } from '../ui/toast';

type InternalAxiosRequestConfig = InternalAxiosRequestConfigType;
type AxiosRequestConfig = AxiosRequestConfigType;
type AxiosRequestHeaders = AxiosRequestHeadersType;
export {
  AxiosErrorType as AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
};

export const isAxiosError = axios.isAxiosError;

export const useAxiosErrorHandler = () => {
  const { addToast } = useToast();

  const getAxiosErrorMessage = useCallback((err: AxiosErrorType) => {
    if (err.response?.status && err.response?.status > 399 && err.response?.status < 500) {
      if (err.response.data) {
        if (typeof err.response.data === 'string') {
          return err.response.data;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorResponseDataError = (err.response.data as any)?.error;
          if (errorResponseDataError) {
            if (typeof errorResponseDataError === 'string') {
              return errorResponseDataError;
            } else {
              return JSON.stringify(errorResponseDataError);
            }
          } else {
            return JSON.stringify(err.response.data);
          }
        }
      } else {
        return err.response.statusText;
      }
    } else if (err.response?.status && err.response?.status >= 500) {
      return `${err.response.statusText}`;
    } else if (err.message === 'Network Error') {
      return 'Network Error';
    } else if (err.message === 'timeout exceeded') {
      return 'Timeout exceeded error';
    } else if (err.message === 'Request aborted') {
      return '';
    } else {
      assert(
        false,
        new Error(
          `[getAxiosErrorMessage] - Unexpected error state. Status: ${err.response?.status}, Message: ${
            err.message
          }, Error: ${JSON.stringify(err)}`
        ),
        'ERROR'
      );
    }
  }, []);

  const axiosErrorHandler = useCallback(
    (err: AxiosErrorType) => {
      if (err.response?.status && err.response?.status === 401) {
        return;
      }

      const axiosError = getAxiosErrorMessage(err);
      if (axiosError) {
        addToast(axiosError, { variant: 'error' });
      }
    },
    [addToast, getAxiosErrorMessage]
  );

  return { axiosErrorHandler, getAxiosErrorMessage };
};

export const NvAxios = axios.create({
  withCredentials: true,
});

export const NvAxiosWithoutInterceptor = axios.create({
  withCredentials: true,
});
