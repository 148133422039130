import { ParameterMappings, StringValue } from '..';

export enum SlackBlockTypes {
  MARKDOWN = 'markdown',
  SLACK_BLOCK_KIT = 'block-kit',
  WORKFLOW_REFERENCES = 'workflow-references',
}

export type MarkDownBlock = {
  type: SlackBlockTypes.MARKDOWN;
  value: Omit<StringValue, 'displayValue'>;
};

export type SlackBlockKitBlockType = {
  type: SlackBlockTypes.SLACK_BLOCK_KIT;
  value: Omit<StringValue, 'displayValue'>;
};

export enum SlackButtonStyles {
  PRIMARY = 'primary',
  DANGER = 'danger',
}

type DisplayConfiguration = {
  text: string;
  variant?: SlackButtonStyles;
  componentType: 'button';
};

interface WorkflowReferenceElementBase {
  id: string;
  executeInPlace?: boolean;
  displayConfiguration: DisplayConfiguration;
}

export interface StaticWorkflowReferenceElement extends WorkflowReferenceElementBase {
  type: 'static';
  appId?: string;
  workflowId: string;
  formId?: string;
  parameterMappings?: ParameterMappings;
}

export interface AppDependencyWorkflowReferenceElement extends WorkflowReferenceElementBase {
  type: 'dependency';
  dependencyId: string;
  workflowId: string;
  formId?: string;
  parameterMappings?: ParameterMappings;
}

export interface DynamicWorkflowReferenceElement extends WorkflowReferenceElementBase {
  type: 'dynamic';
  appIdTemplate: string;
  workflowIdTemplate: string;
  inputTemplate?: string;
}

export type WorkflowReferenceElement =
  | StaticWorkflowReferenceElement
  | DynamicWorkflowReferenceElement
  | AppDependencyWorkflowReferenceElement;

export type WorkflowReferencesBlock = {
  type: SlackBlockTypes.WORKFLOW_REFERENCES;
  label: string;
  elements: WorkflowReferenceElement[];
};

export type SlackBlock = MarkDownBlock | WorkflowReferencesBlock | SlackBlockKitBlockType;

export type SlackBlocks = SlackBlock[];
