import { useCreateCustomerPortalSession } from '@novaera/actioner-service';
import { NvFlex, NvLink, NvTypography } from '@novaera/core';
import { FC, useCallback } from 'react';
import { BillingCard } from '../billing-card';
import { CardLayout } from '../styled';
import { Invoices } from './invoices';
import { PaymentAndInvoicesProps } from './types';

export const PaymentAndInvoices: FC<PaymentAndInvoicesProps> = ({ billingDetail }) => {
  const { mutate: createCustomerPortalSession } = useCreateCustomerPortalSession();
  const goToCustomerPortal = useCallback(() => {
    createCustomerPortalSession(undefined, {
      onSuccess: (result) => {
        if (result.url) {
          window.location.href = result.url;
        }
      },
    });
  }, [createCustomerPortalSession]);

  const handleUpdatePaymentMethods = goToCustomerPortal;

  return (
    <CardLayout>
      <BillingCard
        title="Payment method"
        description={
          <NvFlex gap="8px">
            {billingDetail.billingDetails.defaultPaymentMethod ? (
              <>
                <NvTypography variant="body1">
                  Charges in the future will be applied to the card with the ending digits{' '}
                  <b>{billingDetail.billingDetails.defaultPaymentMethod.cardLatestDigits}</b>.
                </NvTypography>
                <NvLink onClick={handleUpdatePaymentMethods}>Manage payment method</NvLink>
              </>
            ) : (
              <>
                <NvTypography variant="body1" textColor="subtle">
                  You don't have a payment method
                </NvTypography>
                <NvLink onClick={handleUpdatePaymentMethods}>Add payment method</NvLink>
              </>
            )}
          </NvFlex>
        }
      />
      <Invoices address={billingDetail.billingDetails.address} email={billingDetail.billingDetails.billingEmail} />
    </CardLayout>
  );
};
