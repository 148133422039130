import { NvSkeleton } from '@novaera/core';
import { SummaryLayout } from '../layout';

export const SummaryLoading = () => {
  return (
    <SummaryLayout
      headerLeft={
        <>
          <NvSkeleton variant="circular" width="40px" height="40px" />
          <NvSkeleton width="213px" height="24px" />
        </>
      }
      headerRight={<NvSkeleton width="86px" height="20px" />}
      description={
        <>
          <NvSkeleton width="80%" height="16px" />
          <NvSkeleton width="95%" height="16px" />
          <NvSkeleton width="75%" height="16px" />
          <NvSkeleton width="65%" height="16px" />
          <br />
          <NvSkeleton width="80%" height="16px" />
          <NvSkeleton width="95%" height="16px" />
          <NvSkeleton width="75%" height="16px" />
          <NvSkeleton width="65%" height="16px" />
          <br />
          <NvSkeleton width="55%" height="16px" />
          <NvSkeleton width="70%" height="16px" />
        </>
      }
      infoBox={<NvSkeleton variant="rectangular" width="100%" height="86px" />}
    />
  );
};
