import { Version } from '../common/types';

type GlobalIntegrationScope = {
  type: 'global';
};

type WorkspaceIntegrationScope = {
  type: 'workspace';
  id: string;
};

export type IntegrationScope = GlobalIntegrationScope | WorkspaceIntegrationScope;

export type ChangeLog = {
  version: Version;
  changeLog: string;
};
export enum IntegrationType {
  SIMPLE = 'simple',
  HUBSPOT = 'hubspot',
  SLACK = 'slack',
  MS_TEAMS = 'ms-teams',
  DATA_MODEL = 'data-model',
  JOB = 'job',
}
export type Integration = {
  id: string;
  name: string;
  description: string;
  latestVersion: Version;
  scope: IntegrationScope;
  logoUrl: string;
  owners: { type: 'user' | 'workspace'; id: string };
  changeLogs: ChangeLog[];
  categories: string[];
  connectionSchemaId?: string;
  triggerType: 'integrationWebhook' | 'integrationApp';
  type: IntegrationType;
  sourceIntegrationId?: string;
  shortDescription?: string;
};
