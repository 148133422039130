import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const VersionHistoryItem = styled(NvBox)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 4px;
  border-radius: 6px;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  }
`;
