import { JobOperation, NodeExecution, NodeUnionTypeEnumLike } from '@novaera/actioner-service';
import {
  NvActionFilledIcon,
  NvArticleIcon,
  NvCustomAssistantIcon,
  NvCustomAssistantStopIcon,
  NvCustomBranchesIcon,
  NvCustomConditionIcon,
  NvCustomFolderAddIcon,
  NvCustomFolderDeleteIcon,
  NvCustomFolderListIcon,
  NvCustomFolderShareIcon,
  NvCustomFolderTransferIcon,
  NvCustomGetConversationIcon,
  NvCustomQA,
  NvCustomWorkflowResolver,
  NvFolderOpenRounded,
  NvFunctionIcon,
  NvGppBadIcon,
  NvHTTPIcon,
  NvHourglassBottomIcon,
  NvLinkIcon,
  NvMailOutlineIcon,
  NvReceiptLongIcon,
  NvRotateRightIcon,
  NvSensorsIcon,
  NvTimerIcon,
  NvTrackChangesIcon,
  NvWebhookIcon,
  NvWorkflowIcon,
  NvWysiwygIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import React from 'react';
import { useJobDescriptor } from '../../../../workflow-designer/user-app-workflow-canvas/common/use-job-descriptor';

export const WorkflowHistoryNodeExecutionIcon: React.FC<
  Pick<NodeExecution, 'type' | 'subtype'> & {
    width?: number;
    height?: number;
    htmlColor?: string;
  }
> = ({ type, subtype, width = 24, height = 24, htmlColor }) => {
  const theme = useTheme();
  const sx = { width: `${width}px`, height: `${height}px` };
  const { icon: jobIcon } = useJobDescriptor(
    type === NodeUnionTypeEnumLike.job ? { jobOperationType: subtype as JobOperation['type'] } : {}
  );

  switch (type) {
    case NodeUnionTypeEnumLike.output:
      return <NvArticleIcon htmlColor={htmlColor ?? theme.palette.nv_node.output} sx={sx} />;
    case NodeUnionTypeEnumLike.job:
      return jobIcon ?? <NvTimerIcon htmlColor={htmlColor ?? theme.palette.nv_node.job} sx={sx} />;
    case NodeUnionTypeEnumLike.integrationWebhook:
      return <NvTrackChangesIcon htmlColor={htmlColor ?? theme.palette.nv_node.trigger} sx={sx} />;
    case NodeUnionTypeEnumLike.form:
      return <NvWysiwygIcon htmlColor={htmlColor ?? theme.palette.nv_node.trigger} sx={sx} />;
    case NodeUnionTypeEnumLike.genericWebhook:
      return <NvWebhookIcon htmlColor={htmlColor ?? theme.palette.nv_node.trigger} sx={sx} />;
    case NodeUnionTypeEnumLike.email:
      return <NvMailOutlineIcon htmlColor={htmlColor ?? theme.palette.nv_node.trigger} sx={sx} />;
    case NodeUnionTypeEnumLike.actionerEventPublisher:
    case NodeUnionTypeEnumLike.actionerEvent:
      return <NvSensorsIcon htmlColor={htmlColor ?? theme.palette.nv_node.actionerEvent} sx={sx} />;
    case NodeUnionTypeEnumLike.action:
      return <NvActionFilledIcon htmlColor={htmlColor ?? theme.palette.nv_node.action} sx={sx} />;
    case NodeUnionTypeEnumLike.loop:
      return <NvRotateRightIcon htmlColor={htmlColor ?? theme.palette.nv_node.operator} sx={sx} />;
    case NodeUnionTypeEnumLike.workflowCondition:
      return <NvCustomConditionIcon htmlColor={htmlColor ?? theme.palette.nv_node.operator} sx={sx} />;
    case NodeUnionTypeEnumLike.branchJunction:
      return <NvCustomBranchesIcon htmlColor={htmlColor ?? theme.palette.nv_node.operator} sx={sx} />;
    case NodeUnionTypeEnumLike.delay:
      return <NvHourglassBottomIcon htmlColor={htmlColor ?? theme.palette.nv_node.operator} sx={sx} />;
    case NodeUnionTypeEnumLike.function:
      return <NvFunctionIcon htmlColor={htmlColor ?? theme.palette.nv_node.function} sx={sx} />;
    case NodeUnionTypeEnumLike.integrationApp:
      return <NvTrackChangesIcon htmlColor={htmlColor ?? theme.palette.nv_node.emitter} sx={sx} />;
    case NodeUnionTypeEnumLike.response:
      return <NvReceiptLongIcon htmlColor={htmlColor ?? theme.palette.nv_node.response} sx={sx} />;
    case NodeUnionTypeEnumLike.sendEmail:
      return <NvReceiptLongIcon htmlColor={htmlColor ?? theme.palette.nv_node.sendEmail} sx={sx} />;
    case NodeUnionTypeEnumLike.workflowDispatcher:
      return <NvWorkflowIcon htmlColor={htmlColor ?? theme.palette.nv_node.workflow} sx={sx} />;
    case NodeUnionTypeEnumLike.workflowResolver:
      return <NvCustomWorkflowResolver htmlColor={htmlColor ?? theme.palette.nv_node.ai} sx={sx} />;
    case NodeUnionTypeEnumLike.aiKnowledge:
      return <NvCustomQA htmlColor={htmlColor ?? theme.palette.nv_node.ai} sx={sx} />;
    case NodeUnionTypeEnumLike.assistant:
      return <NvCustomAssistantIcon htmlColor={htmlColor ?? theme.palette.nv_node.ai} sx={sx} />;
    case NodeUnionTypeEnumLike.getConversation:
      return <NvCustomGetConversationIcon htmlColor={htmlColor ?? theme.palette.nv_node.ai} sx={sx} />;
    case NodeUnionTypeEnumLike.file: {
      if (subtype === 'list') {
        return <NvCustomFolderListIcon htmlColor={theme.palette.nv_node.file} />;
      } else if (subtype === 'put') {
        return <NvCustomFolderAddIcon htmlColor={theme.palette.nv_node.file} />;
      } else if (subtype === 'transfer') {
        return <NvCustomFolderTransferIcon htmlColor={theme.palette.nv_node.file} />;
      } else if (subtype === 'change-accessibility') {
        return <NvCustomFolderShareIcon htmlColor={theme.palette.nv_node.file} />;
      } else if (subtype === 'delete') {
        return <NvCustomFolderDeleteIcon htmlColor={theme.palette.nv_node.file} />;
      } else {
        return <NvFolderOpenRounded htmlColor={theme.palette.nv_node.file} />;
      }
    }
    case NodeUnionTypeEnumLike.linkGenerator:
      return <NvLinkIcon htmlColor={htmlColor ?? theme.palette.nv_node.linkGenerator} sx={sx} />;
    case NodeUnionTypeEnumLike.http:
      return <NvHTTPIcon htmlColor={htmlColor ?? theme.palette.nv_node.http} sx={sx} />;
    case NodeUnionTypeEnumLike.validation:
      return <NvGppBadIcon htmlColor={htmlColor ?? theme.palette.nv_error[40]} sx={sx} />;
    case NodeUnionTypeEnumLike.assistant_cancel_run:
      return <NvCustomAssistantStopIcon htmlColor={theme.palette.nv_node.assistant_cancel_run} sx={sx} />;
    default:
      assert(false, new Error(`Unhandled node execution type in history detail: ${type}`), 'ERROR');
  }
};
