import { RetryLink } from '@apollo/client/link/retry';
import { ApplicationConfig } from '@novaera/application-config';
import { LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { assert, setCookieForSpecificDomain } from '@novaera/utils';
import Cookies from 'js-cookie';
import { AppSyncSubscriptionLinkError, NvApolloClientProviderV1Props } from '../../types';

export const retryLink = ({
  refreshTokenStrategy,
  initialDelay,
  maxDelayBetweenRetries,
  jitter,
  maxNumberOfAttempts,
}: {
  refreshTokenStrategy: NvApolloClientProviderV1Props['refreshTokenStrategy'];
  initialDelay: number;
  maxDelayBetweenRetries: number;
  jitter: boolean;
  maxNumberOfAttempts: number;
}) =>
  new RetryLink({
    delay: {
      initial: initialDelay,
      max: maxDelayBetweenRetries,
      jitter,
    },
    attempts: async (count, _operation, error) => {
      if (count < maxNumberOfAttempts) {
        if (error) {
          if ('errors' in error) {
            const appSyncSubscriptionError = error as AppSyncSubscriptionLinkError;
            assert(
              appSyncSubscriptionError.errors.length === 1,
              new Error(
                `There is something not handled in the error link check the object:${JSON.stringify(
                  appSyncSubscriptionError
                )}`
              ),
              'ERROR'
            );

            const subscriptionError = appSyncSubscriptionError.errors[0];
            if (subscriptionError.message && subscriptionError.message.includes('UnauthorizedException')) {
              const accessKey = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);
              assert(!!accessKey, new Error('Access key cannot be retrieved while refresh process.'), 'ERROR');
              return refreshTokenStrategy(accessKey).then((response) => {
                assert(
                  !!response,
                  new Error('[NvAxios.interceptors - Status 401] - response can not be undefined.'),
                  'ERROR'
                );
                if (response) {
                  return !!setCookieForSpecificDomain(
                    LOCAL_STORAGE_ACCESS_KEY,
                    response.data.accessToken,
                    `${ApplicationConfig.Actioner.baseHost}`
                  );
                }
                return false;
              });
            } else if (subscriptionError.message && subscriptionError.message === 'Connection closed') {
              return true;
            } else if (subscriptionError.message && subscriptionError.message === 'Timeout disconnect') {
              return false;
            }
            // Timeout disconnect
            else if (subscriptionError.message && subscriptionError.message.includes('Connection handshake error')) {
              return false;
            }
          }
        }
      }
      return false;
    },
  });
