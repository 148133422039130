import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, TARGET_URI } from '@novaera/constants';
import { AxiosError, isAxiosError, useAxiosErrorHandler, useToast } from '@novaera/core';
import { assert, getRootAppDomain, removeCookieForSpecificDomain, setCookieForSpecificDomain } from '@novaera/utils';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFetchWorkspaceList } from '../../../list/services/use-fetch-workspace-list';
import { useCheckWorkspaceAvailability } from '../../services/use-check-workspace-availability';
import { useCreateWorkspaceService } from '../../services/use-create-workspace-service';
import { WorkspaceCreateResponse } from '../../services/use-create-workspace-service/types';
import { StringError, UseCreateWorkspaceProps, ViolationError } from './types';
import { isStringError, isViolationError } from './utils';

export const useCreateWorkspace = (props: UseCreateWorkspaceProps) => {
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const { addToast } = useToast();
  const { mutateAsync: createWorkspace } = useCreateWorkspaceService();
  const { mutateAsync: checkWorkspaceAvailability, isError, isLoading } = useCheckWorkspaceAvailability();
  const [subdomain, setSubdomain] = useState<string>('');
  const [suggestedName, setSuggestedName] = useState<string>('');
  const { data: workspaceFetchResult, isInitialLoading: isFetchWorkspaceLoading } = useFetchWorkspaceList();
  const [isCreateFormOpened, setIsCreateFormOpened] = useState(false);
  const nameRef = useRef<string>();

  useEffect(() => {
    const initSuggestion = async () => {
      if (props?.username && !nameRef.current) {
        nameRef.current = props.username;
        const suggestedName = await suggestName(nameRef.current);
        setSuggestedName(suggestedName);
      }
    };
    if (isCreateFormOpened) {
      initSuggestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isCreateFormOpened]);

  const handleCheckSubDomain = useCallback(
    async (name: string) => {
      try {
        if (!name) {
          return;
        }
        const { subdomain } = await checkWorkspaceAvailability(
          { name, reason: props.reason },
          {
            onError: (error) => {
              //suppress error
            },
          }
        );
        setSubdomain(subdomain);
        return;
      } catch (error) {
        if (isAxiosError(error) && isViolationError(error)) {
          return (error as AxiosError<ViolationError>).response?.data.violations.join(',');
        } else if (isAxiosError(error) && isStringError(error)) {
          return (error as AxiosError<StringError>).response?.data.error;
        }
        return (error as AxiosError<string>).response?.data || 'Unexpected error occurred.';
      }
    },
    [checkWorkspaceAvailability]
  );

  const suggestName = useCallback(
    async (name?: string) => {
      const usernameWithOutDomain = name?.split('@')?.[0];
      let result;
      let count = 1;
      let suggestedName = usernameWithOutDomain;

      // eslint-disable-next-line eqeqeq
      assert(suggestedName != undefined, new Error('Suggest name function waits a username'), 'WARNING');

      if (suggestedName) {
        do {
          result = await handleCheckSubDomain(suggestedName);
          if (!result) {
            break;
          }
          count += 1;
          suggestedName = `${usernameWithOutDomain} ${count}`;
          // eslint-disable-next-line eqeqeq
        } while (result != undefined);
        return suggestedName;
      }
      return '';
    },
    [handleCheckSubDomain]
  );

  const handleCreateWorkspaceClick = async (values: WorkspaceCreateResponse) => {
    try {
      const { subdomain } = await createWorkspace(values);

      const parsedHost = window.location.host.split('.');
      if (parsedHost[0] === 'www') {
        // remove www from array
        parsedHost.shift();
      }

      const targetUri = Cookies.get(TARGET_URI);
      targetUri && removeCookieForSpecificDomain(TARGET_URI, `${ApplicationConfig.Actioner.baseHost}`);
      setCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, subdomain, `${ApplicationConfig.Actioner.baseHost}`);

      // if i m in root domain, redirect to subdomain
      if (getRootAppDomain() === parsedHost.join('.')) {
        window.location.href = `${window.location.protocol}//${subdomain}.${parsedHost.join('.')}${targetUri ?? ''}`;
      } else {
        window.location.href = `${window.location.protocol}//${subdomain}.${getRootAppDomain()}${targetUri ?? ''}`;
      }
    } catch (error) {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else if (error instanceof Error) {
        addToast(`Workspace can not be created, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`Workspace can not be created, reason: ${JSON.stringify(error)}`, { variant: 'error' });
      }
    }
  };

  return {
    onSubmit: handleCreateWorkspaceClick,
    checkSubDomain: handleCheckSubDomain,
    subdomain,
    isLoading,
    isError,
    suggestedName,
    hasWorkspaces:
      workspaceFetchResult && workspaceFetchResult.workspaces && workspaceFetchResult?.workspaces?.length > 0,
    isCreateFormOpened,
    setIsCreateFormOpened,
    isFetchWorkspaceLoading,
    getSuggestedName: suggestName,
  };
};
