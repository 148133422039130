import { Logo, useEmailUpdate, useFetchProfile, useProfileUpdate } from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { EmailForm, UsernameForm } from '../../types';

export const useAccountSettings = () => {
  const { mutate: updateEmail, isLoading: isEmailUpdateLoading } = useEmailUpdate();
  const { mutate: updateUsername, isLoading: isUsernameLoading } = useProfileUpdate();
  const { mutate: updateProfilePhoto } = useProfileUpdate();

  const { addToast } = useToast();

  const { data, isLoading, refetch } = useFetchProfile();

  const handleEmailUpdate = (values: EmailForm) => {
    return new Promise<void>((resolve) => {
      updateEmail(values, {
        onSettled: () => {
          resolve();
        },
      });
    });
  };

  const handleNameUpdate = async (values: UsernameForm) => {
    return new Promise<void>((resolve) => {
      updateUsername(values, {
        onSuccess: () => {
          addToast('Username is updated', { variant: 'success' });
        },
        onError: () => {
          addToast('Username could not be updated', { variant: 'error' });
        },
        onSettled: () => {
          resolve();
        },
      });
    });
  };

  const handleProfileLogoUpdate = async (logo: Logo) => {
    return new Promise<void>((resolve) => {
      updateProfilePhoto(
        { logo },
        {
          onSuccess: () => {
            refetch();
            addToast(`Profile photo is ${logo.id ? 'updated' : 'deleted'}`, { variant: 'success' });
          },
          onError: () => {
            addToast(`Profile photo could not be ${logo.id ? 'updated' : 'deleted'}`, { variant: 'error' });
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return {
    onEmailUpdate: handleEmailUpdate,
    onNameUpdate: handleNameUpdate,
    onProfileLogoUpdate: handleProfileLogoUpdate,
    formData: {
      email: data?.email || '',
      username: data?.name || '',
      logoUrl: data?.logoUrl,
      userId: data?.userId,
    },
    isFormDataLoading: isLoading,
    isEmailUpdateLoading,
    isUsernameLoading,
  };
};
