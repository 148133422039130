import { DragStart, DragUpdate, DropResult } from '@hello-pangea/dnd';
import { useState } from 'react';

export const useDragDropList = ({
  onDragEnd,
}: {
  onDragEnd: (sourceIndex: number, destinationIndex: number) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [placeholderProps, setPlaceholderProps] = useState<any>({});
  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const handleDragStart = (event: DragStart) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginTop + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginTop + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragEnd = ({ source, destination }: DropResult) => {
    setPlaceholderProps({});
    if (!destination) return;

    onDragEnd?.(source.index, destination.index);
  };

  return { handleDragStart, handleDragEnd, handleDragUpdate, placeholderProps };
};
