import { assert } from '@novaera/utils';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type WorkflowErrorStateType = {
  onErrorStateChanged: (props: { nodeId: string; showError: boolean }) => void;
  hasErrorInGraph: boolean;
};

const WorkflowErrorStateContext = createContext<WorkflowErrorStateType | undefined>(undefined);

export const WorkflowErrorStateProvider = ({
  children,
}: {
  children: React.ReactNode | ((props: WorkflowErrorStateType) => React.ReactNode);
}) => {
  const [nodesErrorState, setNodesErrorState] = useState<string[]>();

  const handleSetErrorState = useCallback<WorkflowErrorStateType['onErrorStateChanged']>((prop) => {
    if (prop.showError) {
      setNodesErrorState((prev) => {
        if (prev?.includes(prop.nodeId)) {
          return prev;
        } else {
          return [...(prev ?? []), prop.nodeId];
        }
      });
    } else {
      setNodesErrorState((prev) => {
        if (prev?.includes(prop.nodeId)) {
          return prev.filter((nodeId) => nodeId !== prop.nodeId);
        } else {
          return prev;
        }
      });
    }
  }, []);

  const hasErrorInGraph = useMemo(() => {
    return Boolean(nodesErrorState && nodesErrorState?.length > 0);
  }, [nodesErrorState]);

  return (
    <WorkflowErrorStateContext.Provider
      value={{
        onErrorStateChanged: handleSetErrorState,
        hasErrorInGraph,
      }}
    >
      {typeof children === 'function'
        ? children({ onErrorStateChanged: handleSetErrorState, hasErrorInGraph })
        : children}
    </WorkflowErrorStateContext.Provider>
  );
};

export const useWorkflowErrorStateProvider = () => {
  const context = useContext(WorkflowErrorStateContext);
  assert(
    !!context,
    new Error(`useWorkflowErrorStateProvider should be used within WorkflowErrorStateProvider`),
    'ERROR'
  );

  return context;
};
