import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_ACTION_STEP_EXECUTION_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION } from '../keys';
import { RunIntegrationActionStepParams, RunIntegrationActionStepResponse } from '../types';

const runIntegrationActionStep = async (params: RunIntegrationActionStepParams) => {
  const { integrationId, actionId, ...rest } = params;
  const result = await NvAxios.post<RunIntegrationActionStepResponse>(
    `${INTEGRATION_ACTION_STEP_EXECUTION_PATH(integrationId, actionId)}`,
    rest
  );
  return result?.data;
};

export const useRunIntegrationActionStep = () => {
  const cache = useQueryClient();
  return useMutation(runIntegrationActionStep, {
    onSuccess(data, { integrationId, actionId, step }) {
      cache.setQueryData<RunIntegrationActionStepResponse>(
        QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.detail(integrationId, actionId, step.id),
        data
      );
    },
  });
};
