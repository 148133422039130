import { AppConfigGenerationSourceType, AppConfigVersionListItem, useGetDocument } from '@novaera/actioner-service';
import {
  AccountCircleIcon,
  NvAutoAwesomeIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { isUndefined } from 'lodash';
import { VersionHistoryItem } from './styled';

export const VersionHistoryListItem: React.FC<{
  version: AppConfigVersionListItem;
  onItemClick: () => void;
}> = ({ version, onItemClick }) => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { data: document, isInitialLoading: isGettingDocumentDetail } = useGetDocument({
    appId: userAppId,
    ...(version.generationSource.type === AppConfigGenerationSourceType.FROM_AI
      ? { docId: version.generationSource.docId }
      : {}),
  });
  return (
    <VersionHistoryItem onClick={onItemClick}>
      <NvFlex direction="row" alignItems="center" gap="8px">
        <NvTypography variant="h5" flex="0 0 auto">
          {format(new Date(version.createdAt), 'MMM d, p')}
        </NvTypography>
        <NvDivider
          orientation="vertical"
          sx={{ height: '8px', flex: '0 0 auto' }}
          borderColor={theme.palette.nv_neutral_alpha[30]}
        />
        <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
          <NvImage
            src={version.logoUrl}
            imageShape="circle"
            size="xsmall"
            FallBack={<AccountCircleIcon sx={{ width: '16px', height: '16px' }} />}
          />
          <NvTypography variant="h6" textColor="secondary" noWrap>
            {version.username}
          </NvTypography>
        </NvFlex>
      </NvFlex>
      {(!!document || !isUndefined(isGettingDocumentDetail)) && (
        <NvFlex direction="row" alignItems="center" gap="4px" width="100%">
          {document && (
            <>
              <NvAutoAwesomeIcon htmlColor={theme.palette.nv_accent['50']} sx={{ width: '16px', height: '16px' }} />

              <NvTypography variant="body2" textColor="secondary">
                Generated from <b>{document.name}</b> document
              </NvTypography>
            </>
          )}
          {isGettingDocumentDetail && <NvSkeleton height="12px" variant="rectangular" width="100%"></NvSkeleton>}
        </NvFlex>
      )}
    </VersionHistoryItem>
  );
};
