import Pagination, { PaginationRenderItemParams } from '@mui/material/Pagination';
import { useGridApiContext, useGridState } from '@mui/x-data-grid';
import { isUndefined } from 'lodash';
import {
  NvChevronLeftIcon,
  NvChevronRightIcon,
  NvFirstPageIcon,
  NvFlex,
  NvLastPageIcon,
  NvPaginationDirection,
  NvPaginationNumber,
  NvTypography,
} from '../../../index';
import { NvPaginationProps } from './types';

export const NvPagination: React.FC<React.PropsWithChildren<NvPaginationProps>> = ({ totalCount }) => {
  const apiRef = useGridApiContext();
  const [state] = useGridState(apiRef);

  const renderItem = (item: PaginationRenderItemParams) => {
    const { variant, shape, color, size, ...restOfItem } = item;
    switch (item.type) {
      case 'page':
        // MURAT
        return <NvPaginationNumber value={item.page} selected={item.selected} onClick={item.onClick} />;
      case 'first':
        return (
          <NvPaginationDirection {...restOfItem}>
            <NvFirstPageIcon fontSize="small" />
          </NvPaginationDirection>
        );
      case 'last':
        return (
          <NvPaginationDirection {...restOfItem}>
            <NvLastPageIcon fontSize="small" />
          </NvPaginationDirection>
        );
      case 'previous':
        return (
          <NvPaginationDirection {...restOfItem}>
            <NvChevronLeftIcon fontSize="small" />
          </NvPaginationDirection>
        );
      case 'next':
        return (
          <NvPaginationDirection {...restOfItem}>
            <NvChevronRightIcon fontSize="small" />
          </NvPaginationDirection>
        );
      default:
        return null;
    }
  };

  return (
    <NvFlex direction="row" alignItems="center">
      {!isUndefined(totalCount) && (
        <NvTypography variant="h5" textColor="subtle" position="absolute" left="16px">
          {state.rows.ids.length} of {totalCount} records in total
        </NvTypography>
      )}
      {state.pagination.pageCount > 1 && (
        <Pagination
          page={state.pagination.page + 1}
          count={state.pagination.pageCount}
          showFirstButton
          showLastButton
          size="small"
          renderItem={renderItem}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      )}
    </NvFlex>
  );
};
