export const NvDependenciesIllustration = () => {
  return (
    <svg width="98" height="109" viewBox="0 0 98 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.3553 62.3685C63.1513 62.3685 62.9472 62.2608 62.8392 62.0693C62.6711 61.782 62.7671 61.4229 63.0552 61.2553L76.3676 53.5944C76.6557 53.4268 77.0158 53.5226 77.1839 53.8099C77.3519 54.0972 77.2559 54.4563 76.9678 54.6239L63.6554 62.2847C63.5594 62.3446 63.4514 62.3685 63.3553 62.3685Z"
        fill="#B1B6C0"
      />
      <path d="M31.1128 7.85651L17.8965 15.4575L31.1128 23.0705L44.3291 15.4575L31.1128 7.85651Z" fill="white" />
      <path
        d="M31.1128 23.669C31.0047 23.669 30.9087 23.6451 30.8127 23.5852L17.5964 15.9842C17.4163 15.8765 17.2963 15.685 17.2963 15.4695C17.2963 15.254 17.4163 15.0625 17.5964 14.9548L30.8127 7.35375C30.9927 7.24602 31.2328 7.24602 31.4129 7.35375L44.6292 14.9548C44.8092 15.0625 44.9293 15.254 44.9293 15.4695C44.9293 15.685 44.8092 15.8765 44.6292 15.9842L31.4129 23.5852C31.3168 23.6331 31.2208 23.669 31.1128 23.669ZM19.0969 15.4695L31.1128 22.3882L43.1287 15.4695L31.1128 8.55076L19.0969 15.4695Z"
        fill="#455068"
      />
      <path d="M31.1128 23.0705V56.1919L17.8965 48.5789V15.4575L31.1128 23.0705Z" fill="white" />
      <path
        d="M31.1128 56.7904C31.0047 56.7904 30.9087 56.7664 30.8127 56.7066L17.5964 49.1055C17.4163 48.9978 17.2963 48.8063 17.2963 48.5908V15.4575C17.2963 15.2421 17.4163 15.0505 17.5964 14.9428C17.7764 14.8351 18.0165 14.8351 18.1966 14.9428L31.4129 22.5438C31.5929 22.6516 31.713 22.8431 31.713 23.0585V56.1799C31.713 56.3954 31.5929 56.5869 31.4129 56.6946C31.3168 56.7425 31.2208 56.7784 31.1128 56.7784V56.7904ZM18.4967 48.2437L30.5126 55.1624V23.4057L18.4967 16.4869V48.2317V48.2437Z"
        fill="#455068"
      />
      <path d="M44.3291 15.4576V48.5789L31.1128 56.1919V23.0706L44.3291 15.4576Z" fill="#455068" />
      <path
        d="M31.1128 56.7904C31.0047 56.7904 30.9087 56.7665 30.8127 56.7066C30.6326 56.5989 30.5126 56.4074 30.5126 56.1919V23.0705C30.5126 22.8551 30.6326 22.6636 30.8127 22.5558L44.029 14.9548C44.209 14.8471 44.4491 14.8471 44.6292 14.9548C44.8092 15.0625 44.9293 15.254 44.9293 15.4695V48.5909C44.9293 48.8063 44.8092 48.9978 44.6292 49.1056L31.4129 56.7066C31.3168 56.7545 31.2208 56.7904 31.1128 56.7904ZM31.713 23.4177V55.1625L43.7289 48.2437V16.4989L31.713 23.4177Z"
        fill="#455068"
      />
      <path
        d="M36.2145 68.2697V75.0688L23.3583 69.9336C20.6334 68.3655 18.9288 62.8473 20.2973 59.9984L28.0038 48.4831H28.0158L31.833 54.0971L30.0204 57.8916C28.8801 60.2617 30.2965 64.8582 32.5773 66.1749L36.2145 68.2697Z"
        fill="white"
      />
      <path
        d="M36.2145 75.6673C36.1424 75.6673 36.0584 75.6553 35.9864 75.6194L23.1302 70.4842C20.0812 68.7366 18.2566 62.8353 19.7451 59.735L27.4996 48.1479C27.5957 47.9923 27.7637 47.8966 27.9438 47.8846C28.1358 47.8726 28.3039 47.9325 28.4359 48.0641L32.3252 53.7499C32.4452 53.9295 32.4572 54.1569 32.3732 54.3485L30.5606 58.143C29.5643 60.2258 30.8847 64.4991 32.8774 65.6483L36.5146 67.743C36.6946 67.8508 36.8147 68.0423 36.8147 68.2577V75.0568C36.8147 75.2603 36.7186 75.4398 36.5506 75.5476C36.4545 75.6194 36.3345 75.6553 36.2145 75.6553V75.6673ZM28.0158 49.5484L20.8014 60.3216C19.6251 62.7874 21.2336 68.0064 23.6584 69.4069L35.6143 74.171V68.6049L32.2772 66.6777C29.7083 65.1934 28.1959 60.2856 29.4803 57.6163L31.1488 54.133L28.0158 49.5365V49.5484Z"
        fill="#455068"
      />
      <path
        d="M45.0613 46.508L33.2014 53.331L31.845 54.109L31.833 54.0971L28.0157 48.495L41.2201 40.894L45.0613 46.508Z"
        fill="white"
      />
      <path
        d="M31.845 54.7075C31.689 54.7075 31.5329 54.6477 31.4249 54.528C31.4009 54.5041 31.3649 54.4562 31.3409 54.4322L27.5236 48.8302C27.4276 48.6985 27.4036 48.519 27.4396 48.3634C27.4756 48.1958 27.5836 48.0641 27.7277 47.9803L40.932 40.3793C41.1961 40.2237 41.5562 40.3075 41.7243 40.5589L45.5655 46.1729C45.6616 46.3045 45.6976 46.4841 45.6495 46.6397C45.6015 46.7953 45.5055 46.9389 45.3615 47.0227L32.1451 54.6238C32.0491 54.6716 31.9531 54.7075 31.845 54.7075ZM28.8801 48.6866L32.0251 53.307L44.1971 46.3045L41.04 41.6841L28.8801 48.6866Z"
        fill="#455068"
      />
      <path
        d="M49.4428 60.6807L42.4565 64.6907L36.2145 68.2697L32.5773 66.175C31.809 65.7321 31.1368 64.9181 30.6447 63.9485L43.981 56.551C44.4612 57.4368 45.0974 58.1669 45.8056 58.5739L49.4428 60.6807Z"
        fill="white"
      />
      <path
        d="M36.2145 68.8682C36.1065 68.8682 36.0104 68.8443 35.9144 68.7844L32.2772 66.6897C31.4489 66.2108 30.6807 65.3251 30.1045 64.2118C29.9605 63.9246 30.0685 63.5774 30.3446 63.4218L43.6809 56.0243C43.825 55.9524 43.981 55.9285 44.1371 55.9764C44.2931 56.0243 44.4252 56.12 44.4972 56.2637C44.9413 57.0776 45.5055 57.7121 46.0937 58.0472L49.7309 60.154C49.911 60.2617 50.031 60.4532 50.031 60.6687C50.031 60.8841 49.911 61.0757 49.7309 61.1834L36.5026 68.7725C36.4066 68.8203 36.3105 68.8562 36.2025 68.8562L36.2145 68.8682ZM31.4609 64.1759C31.8691 64.8463 32.3612 65.361 32.8774 65.6482L36.2145 67.5754L48.2424 60.6806L45.5055 59.0886C44.8573 58.7175 44.2691 58.119 43.765 57.3529L31.4609 64.1759Z"
        fill="#455068"
      />
      <path
        d="M43.981 56.5509L30.6446 63.9485C29.6363 62.0213 29.2642 59.4716 30.0204 57.8916L31.833 54.0971H31.845L33.2015 53.331L45.0613 46.508L43.2367 50.3025C42.4565 51.9305 42.8886 54.6118 43.981 56.5509Z"
        fill="#455068"
      />
      <path
        d="M30.6446 64.547C30.6446 64.547 30.5246 64.547 30.4766 64.5231C30.3205 64.4752 30.1885 64.3674 30.1164 64.2238C28.9521 61.9974 28.688 59.2921 29.4802 57.6283L31.2928 53.8337C31.3649 53.6781 31.4969 53.5704 31.665 53.5225L44.7612 45.9933C44.9893 45.8616 45.2774 45.8975 45.4695 46.0771C45.6615 46.2566 45.7215 46.5439 45.6015 46.7714L43.7769 50.5659C43.1287 51.9185 43.4408 54.3724 44.4971 56.2637C44.5812 56.4073 44.5932 56.5629 44.5572 56.7185C44.5211 56.8741 44.4131 57.0058 44.2691 57.0776L30.9327 64.4752C30.8367 64.5231 30.7407 64.547 30.6446 64.547ZM32.2772 54.5519L30.5606 58.1549C29.9964 59.34 30.1525 61.351 30.9087 63.1226L43.1767 56.3115C42.2764 54.4083 41.8443 51.8108 42.6846 50.0512L43.6689 48.0043L32.2651 54.5639L32.2772 54.5519Z"
        fill="#455068"
      />
      <path
        d="M49.4427 60.6807V67.4797L36.2264 75.0807L36.2144 68.2697L42.4564 64.6907L49.4427 60.6807Z"
        fill="#455068"
      />
      <path
        d="M36.2264 75.6792C36.1184 75.6792 36.0224 75.6553 35.9263 75.5954C35.7463 75.4877 35.6262 75.2962 35.6262 75.0807V68.2697C35.6262 68.0543 35.7343 67.8508 35.9143 67.755L49.1426 60.1659C49.3347 60.0582 49.5628 60.0582 49.7428 60.1659C49.9349 60.2737 50.0429 60.4652 50.0429 60.6807V67.4797C50.0429 67.6952 49.9229 67.8867 49.7428 67.9944L36.5265 75.5954C36.4305 75.6433 36.3345 75.6792 36.2264 75.6792ZM36.8146 68.6169V74.0513L48.8425 67.1445V61.7221L36.8146 68.6169Z"
        fill="#455068"
      />
      <path
        d="M54.2682 86.5601L54.1962 86.596C53.608 86.9431 52.8758 86.9431 52.0355 86.4643L39.1793 76.7925V69.9815L42.8285 72.0762C45.0973 73.3929 46.5257 70.4363 45.3853 66.7495L43.5608 60.8602L47.402 59.6752L49.3106 64.7026L55.1205 80.0723C56.0448 83.0887 55.5407 85.6982 54.2682 86.5601Z"
        fill="white"
      />
      <path
        d="M53.308 87.4459C52.8638 87.4459 52.3357 87.3262 51.7475 86.979L38.8313 77.2713C38.6752 77.1635 38.5912 76.984 38.5912 76.7925V69.9814C38.5912 69.766 38.6992 69.5745 38.8913 69.4667C39.0713 69.359 39.2994 69.359 39.4915 69.4667L43.1407 71.5615C43.6568 71.8608 44.089 71.8727 44.4251 71.6094C45.1453 71.0348 45.5295 69.2034 44.8212 66.9291L42.9966 61.0398C42.9006 60.7285 43.0807 60.3934 43.3928 60.2976L47.234 59.1126C47.5341 59.0168 47.8582 59.1724 47.9783 59.4717L55.6968 79.8688C56.6691 83.0409 56.2249 85.9735 54.6164 87.0628C54.5924 87.0748 54.5684 87.0868 54.5444 87.0987L54.4724 87.1346C54.1963 87.3022 53.8002 87.4459 53.308 87.4459ZM39.7796 76.4932L52.3957 85.9855C52.9359 86.2848 53.452 86.3326 53.8842 86.0813C53.8842 86.0813 53.9202 86.0573 53.9562 86.0454C54.9045 85.3631 55.4207 83.1247 54.5444 80.2518L47.0419 60.4173L44.3171 61.2552L45.9616 66.57C46.7539 69.1316 46.4297 71.5376 45.1573 72.5431C44.4251 73.1296 43.4648 73.1416 42.5285 72.5909L39.7796 71.0109V76.4932Z"
        fill="#455068"
      />
      <path d="M60.6185 52.0621L47.4022 59.6751L43.5609 60.8602L56.7772 53.2591L60.6185 52.0621Z" fill="white" />
      <path
        d="M43.5729 61.4587C43.3448 61.4587 43.1167 61.327 43.0207 61.0996C42.9007 60.8243 43.0087 60.4891 43.2728 60.3455L56.4891 52.7444C56.4891 52.7444 56.5731 52.7085 56.6091 52.6966L60.4504 51.4995C60.7505 51.4038 61.0506 51.5474 61.1826 51.8347C61.3027 52.11 61.1946 52.4452 60.9306 52.5888L47.7142 60.2018C47.7142 60.2018 47.6302 60.2377 47.5942 60.2497L43.7529 61.4348C43.7529 61.4348 43.6329 61.4587 43.5729 61.4587Z"
        fill="#455068"
      />
      <path
        d="M68.3249 72.4593L60.6184 52.0622L47.4021 59.6752L55.1206 80.0723C56.0449 83.0887 55.5408 85.6982 54.2683 86.5601L67.2326 79.0787L67.4486 78.959C68.7571 78.1211 69.2612 75.4997 68.3249 72.4593Z"
        fill="#455068"
      />
      <path
        d="M54.2683 87.1586C54.0642 87.1586 53.8722 87.0628 53.7642 86.8833C53.5961 86.6079 53.6681 86.2488 53.9322 86.0693C54.8925 85.4229 55.4327 83.1606 54.5444 80.2518L46.8379 59.8906C46.7299 59.6153 46.8379 59.3041 47.102 59.1604L60.3183 51.5474C60.4743 51.4636 60.6544 51.4397 60.8225 51.4996C60.9905 51.5594 61.1226 51.6911 61.1826 51.8467L68.8891 72.2438C68.8891 72.2438 68.8891 72.2677 68.8891 72.2797C69.8614 75.4518 69.4053 78.4084 67.7607 79.4618L67.5086 79.6054L54.5564 87.0868C54.4604 87.1466 54.3643 87.1705 54.2563 87.1705L54.2683 87.1586ZM48.1463 59.9385L55.6848 79.8568C56.261 81.7241 56.333 83.5077 55.9849 84.8723L67.1605 78.4204C68.1088 77.8099 68.661 75.5715 67.7607 72.6388L60.3063 52.924L48.1463 59.9265V59.9385Z"
        fill="#455068"
      />
      <path
        d="M42.8286 72.0762L39.1794 69.9814L45.3855 66.7495C46.5258 70.4363 45.0974 73.3929 42.8286 72.0762Z"
        fill="white"
      />
      <path
        d="M43.885 72.986C43.4528 72.986 42.9847 72.8543 42.5285 72.5909L38.8793 70.4962C38.6873 70.3884 38.5792 70.1849 38.5792 69.9695C38.5792 69.754 38.7113 69.5505 38.9033 69.4548L45.1094 66.2228C45.2654 66.139 45.4575 66.1271 45.6135 66.1989C45.7816 66.2707 45.9016 66.4024 45.9616 66.57C46.7539 69.1316 46.4298 71.5376 45.1574 72.5431C44.7852 72.8423 44.3411 72.9979 43.873 72.9979L43.885 72.986ZM40.4278 70.0054L43.1287 71.5615C43.6449 71.8608 44.077 71.8727 44.4131 71.6094C45.0613 71.0947 45.4335 69.5745 45.0013 67.6353L40.4278 70.0174V70.0054Z"
        fill="#455068"
      />
      <path
        d="M39.0474 47.717L33.3215 51.0327L35.1461 47.2502C35.9864 45.5145 37.727 45.9334 39.0474 47.717Z"
        fill="#455068"
      />
      <path
        d="M33.3216 51.6312C33.1775 51.6312 33.0215 51.5714 32.9135 51.4756C32.7214 51.296 32.6614 51.0088 32.7814 50.7813L34.606 46.9988C35.0141 46.1489 35.6744 45.6342 36.4546 45.5624C37.4869 45.4666 38.6033 46.125 39.5276 47.3698C39.6236 47.5015 39.6717 47.6811 39.6356 47.8487C39.5996 48.0162 39.4916 48.1599 39.3476 48.2437L33.6217 51.5594C33.5257 51.6192 33.4176 51.6432 33.3216 51.6432V51.6312ZM36.6587 46.7474C36.6587 46.7474 36.5987 46.7474 36.5746 46.7474C36.2145 46.7833 35.9144 47.0347 35.6864 47.5135L34.714 49.5364L38.1352 47.5614C37.631 47.0467 37.1028 46.7474 36.6587 46.7474Z"
        fill="#455068"
      />
      <path
        d="M45.9257 54.8871L42.0844 56.0841L40.2718 50.1948C39.9597 49.2133 39.5396 48.3754 39.0474 47.717C37.727 45.9335 35.9864 45.5145 35.1462 47.2502L33.3216 51.0327L29.4923 45.4188L34.63 30.1688C34.8581 29.7139 35.1221 29.3788 35.4343 29.1633L35.6863 29.0197C37.2949 28.2177 39.6836 30.1808 40.776 33.7L45.9257 54.8871Z"
        fill="white"
      />
      <path
        d="M42.0844 56.6826C41.9884 56.6826 41.8923 56.6587 41.8083 56.6108C41.6643 56.539 41.5562 56.4073 41.5202 56.2517L39.7076 50.3624C39.4195 49.4766 39.0354 48.6746 38.5793 48.0641C37.931 47.1903 37.1988 46.6876 36.5866 46.7355C36.2265 46.7714 35.9264 47.0227 35.6983 47.5015L33.8737 51.2841C33.7777 51.4756 33.5976 51.6073 33.3816 51.6193C33.1655 51.6432 32.9614 51.5355 32.8414 51.3559L29.0121 45.7419C28.9041 45.5863 28.8801 45.3948 28.9401 45.2153L34.0778 29.9653C34.3899 29.3309 34.714 28.9239 35.1101 28.6606L35.4102 28.493C36.0944 28.1578 36.8387 28.1578 37.6189 28.493C39.1795 29.1513 40.6199 31.0905 41.3762 33.5324L46.5379 54.7554C46.6099 55.0667 46.4298 55.3779 46.1297 55.4617L42.2885 56.6587C42.2885 56.6587 42.1684 56.6826 42.1084 56.6826H42.0844ZM36.6586 45.5504C37.6309 45.5504 38.6633 46.1968 39.5276 47.3699C40.0677 48.1001 40.5119 48.9858 40.836 50.0272L42.4685 55.342L45.1934 54.4921L40.1758 33.8556C39.5396 31.8087 38.3272 30.1209 37.1028 29.6062C36.6586 29.4147 36.2625 29.4027 35.9384 29.5703L35.7103 29.702C35.5303 29.8217 35.3262 30.085 35.1461 30.4561L30.1405 45.3469L33.2135 49.8597L34.582 47.0108C34.9901 46.1609 35.6503 45.6462 36.4306 45.5744C36.4906 45.5744 36.5626 45.5744 36.6346 45.5744L36.6586 45.5504Z"
        fill="#455068"
      />
      <path
        d="M59.1421 47.2861L45.9257 54.8871L40.7761 33.7C40.536 32.9339 40.2359 32.2396 39.8998 31.6291L53.1761 24.1478C53.4882 24.7224 53.7643 25.3807 53.9924 26.0989L59.1421 47.2861Z"
        fill="#455068"
      />
      <path
        d="M45.9257 55.4856C45.8417 55.4856 45.7697 55.4736 45.6856 55.4377C45.5176 55.3659 45.3855 55.2103 45.3375 55.0307L40.1878 33.8436C39.9838 33.1852 39.6957 32.5149 39.3716 31.9164C39.2155 31.6291 39.3116 31.27 39.5997 31.1024L52.876 23.6211C53.02 23.5373 53.1881 23.5254 53.3321 23.5732C53.4762 23.6211 53.6202 23.7169 53.6923 23.8605C54.0284 24.471 54.3165 25.1653 54.5565 25.9194L59.7182 47.1424C59.7782 47.4058 59.6702 47.6691 59.4301 47.8008L46.2138 55.4018C46.1178 55.4497 46.0218 55.4856 45.9137 55.4856H45.9257ZM40.692 31.8685C40.9441 32.3952 41.1602 32.9458 41.3402 33.5204L46.3098 53.9654L58.4458 46.9868L53.4042 26.2306C53.2601 25.7997 53.1041 25.3688 52.912 24.9737L40.692 31.8566V31.8685Z"
        fill="#455068"
      />
      <path
        d="M53.176 24.1478L39.8997 31.6291H39.8877C38.6753 29.4506 36.9467 28.3972 35.6863 29.0196L48.8906 21.4186C50.175 20.7722 51.9516 21.8854 53.176 24.1478Z"
        fill="white"
      />
      <path
        d="M39.8997 32.2276C39.6836 32.2276 39.4676 32.1079 39.3595 31.9164C38.3392 30.073 36.9107 29.0795 35.9504 29.5583C35.6623 29.702 35.3142 29.5942 35.1582 29.3069C35.0021 29.0197 35.1101 28.6725 35.3862 28.5049L48.5905 20.9039C50.2231 20.078 52.3118 21.3109 53.7042 23.8725C53.8603 24.1598 53.7522 24.5189 53.4761 24.6745L40.1998 32.1558C40.1038 32.2037 40.0077 32.2276 39.8997 32.2276ZM37.703 28.5528C38.5553 28.9478 39.3955 29.7379 40.1038 30.8271L52.3478 23.9323C51.3394 22.3523 50.031 21.5264 49.1547 21.9573L37.703 28.5528Z"
        fill="#455068"
      />
      <path d="M39.8997 32.2277H39.8877V31.0306L39.8997 32.2277Z" fill="#455068" />
      <path d="M55.0366 74.0753L41.8323 81.6763L55.0366 89.2893L68.2529 81.6763L55.0366 74.0753Z" fill="white" />
      <path
        d="M55.0366 89.8878C54.9286 89.8878 54.8325 89.8638 54.7365 89.804L41.5202 82.203C41.3401 82.0952 41.2201 81.9037 41.2201 81.6882C41.2201 81.4728 41.3401 81.2813 41.5202 81.1735L54.7365 73.5725C54.9166 73.4648 55.1566 73.4648 55.3367 73.5725L68.553 81.1735C68.7331 81.2813 68.8531 81.4728 68.8531 81.6882C68.8531 81.9037 68.7331 82.0952 68.553 82.203L55.3367 89.804C55.2407 89.8519 55.1446 89.8878 55.0366 89.8878ZM43.0207 81.6882L55.0366 88.607L67.0525 81.6882L55.0366 74.7695L43.0207 81.6882Z"
        fill="#455068"
      />
      <path d="M55.0366 89.2893V103.45L41.8323 95.8369V81.6763L55.0366 89.2893Z" fill="white" />
      <path
        d="M55.0366 104.048C54.9286 104.048 54.8325 104.024 54.7365 103.965C45.7256 98.7776 41.2201 96.0684 41.2201 95.8369V81.6763C41.2201 81.4608 41.3401 81.2693 41.5202 81.1615C41.7002 81.0538 41.9403 81.0538 42.1204 81.1615L55.3367 88.7626C55.5168 88.8703 55.6368 89.0618 55.6368 89.2773V103.438C55.6368 103.653 55.5168 103.845 55.3367 103.953C55.2407 104.001 55.1446 104.036 55.0366 104.036V104.048ZM42.4205 95.5018C44.041 96.4354 51.7715 100.888 54.4364 102.42V89.6364L42.4205 82.7177V95.5018Z"
        fill="#455068"
      />
      <path d="M68.2529 81.6763V95.8369L55.0366 103.45V89.2893L68.2529 81.6763Z" fill="#455068" />
      <path
        d="M55.0366 104.048C54.9286 104.048 54.8325 104.025 54.7365 103.965C54.5564 103.857 54.4364 103.665 54.4364 103.45V89.2893C54.4364 89.0738 54.5564 88.8823 54.7365 88.7746L67.9528 81.1735C68.1329 81.0658 68.373 81.0658 68.553 81.1735C68.7331 81.2813 68.8531 81.4728 68.8531 81.6883V95.8489C68.8531 96.0644 68.7331 96.2559 68.553 96.3636L55.3367 103.965C55.2407 104.013 55.1446 104.048 55.0366 104.048ZM55.6368 89.6364V102.421L67.6527 95.5018V82.7177L55.6368 89.6364Z"
        fill="#455068"
      />
      <path
        d="M0.598862 68.0783C0.394796 68.0783 0.190729 67.9705 0.0826937 67.779C-0.0853612 67.4917 0.0106702 67.1326 0.298764 66.965L17.8245 56.8742C18.1126 56.7066 18.4847 56.8024 18.6408 57.0897C18.8088 57.377 18.7128 57.7361 18.4247 57.9036L0.89896 67.9945C0.802929 68.0543 0.694894 68.0783 0.598862 68.0783Z"
        fill="#B1B6C0"
      />
      <path
        d="M2.19542 82.3705C1.99135 82.3705 1.78729 82.2628 1.67925 82.0713C1.5112 81.784 1.60723 81.4249 1.89532 81.2573L18.1846 71.8727C18.4727 71.7052 18.8329 71.8009 19.0009 72.0882C19.169 72.3755 19.0729 72.7346 18.7848 72.9022L2.49552 82.2868C2.39949 82.3466 2.29145 82.3705 2.19542 82.3705Z"
        fill="#B1B6C0"
      />
      <path
        d="M23.0582 85.5666C22.8541 85.5666 22.6501 85.4589 22.542 85.2674C22.374 84.9801 22.47 84.621 22.7581 84.4534L30.8007 79.821C31.0888 79.6534 31.449 79.7492 31.617 80.0364C31.7851 80.3237 31.689 80.6828 31.4009 80.8504L23.3583 85.4829C23.2623 85.5427 23.1542 85.5666 23.0582 85.5666Z"
        fill="#B1B6C0"
      />
      <path
        d="M64.1356 46.6997C63.9315 46.6997 63.7275 46.592 63.6194 46.4004C63.4514 46.1132 63.5474 45.7541 63.8355 45.5865L70.4377 41.78C70.7258 41.6124 71.0859 41.7082 71.2539 41.9954C71.422 42.2827 71.326 42.6418 71.0379 42.8094L64.4357 46.6159C64.3397 46.6758 64.2316 46.6997 64.1356 46.6997Z"
        fill="#B1B6C0"
      />
      <path
        d="M74.8551 70.963C74.651 70.963 74.447 70.8553 74.3389 70.6638C74.1709 70.3765 74.2669 70.0174 74.555 69.8498L91.9987 60C92.2868 59.8324 92.6469 59.9282 92.8149 60.2155C92.983 60.5027 92.8869 60.8619 92.5989 61.0294L75.1552 70.8792C75.0592 70.9391 74.9511 70.963 74.8551 70.963Z"
        fill="#B1B6C0"
      />
      <path
        d="M83.9541 80.9342C83.75 80.9342 83.546 80.8264 83.4379 80.6349C83.2699 80.3476 83.3659 79.9885 83.654 79.821L92.5 74.5C92.7881 74.3324 93.1482 74.4282 93.3163 74.7155C93.4843 75.0028 93.3883 75.3619 93.1002 75.5294L84.2542 80.8504C84.1582 80.9102 84.0501 80.9342 83.9541 80.9342Z"
        fill="#B1B6C0"
      />
      <path
        d="M35.2182 108.992C35.0021 108.992 34.7981 108.884 34.69 108.681C34.534 108.394 34.642 108.023 34.9301 107.867L44.0291 102.923C44.3172 102.768 44.6893 102.875 44.8453 103.163C45.0014 103.45 44.8934 103.821 44.6053 103.977L35.5063 108.92C35.4103 108.968 35.3142 108.992 35.2182 108.992Z"
        fill="#B1B6C0"
      />
      <path
        d="M29.6244 96.9981C29.4203 96.9981 29.2162 96.8903 29.1082 96.6988C28.9402 96.4115 29.0362 96.0524 29.3243 95.8848L37.4149 91.2285C37.703 91.0609 38.0631 91.1566 38.2312 91.4439C38.3992 91.7312 38.3032 92.0903 38.0151 92.2579L29.9245 96.9143C29.8284 96.9741 29.7204 96.9981 29.6244 96.9981Z"
        fill="#B1B6C0"
      />
      <path
        d="M37.715 11.2322C37.715 12.896 37.1508 14.0931 36.2505 14.7394L35.8424 14.9788L33.5376 16.2956C33.8617 16.0322 34.1498 15.6851 34.3779 15.2422C34.702 14.6317 34.87 13.8297 34.882 12.8841C34.882 11.8187 34.678 10.6816 34.2938 9.56835L37.2348 8.22769C37.5589 9.24515 37.739 10.2746 37.727 11.2442L37.715 11.2322Z"
        fill="#455068"
      />
      <path
        d="M33.5376 16.8821C33.3455 16.8821 33.1534 16.7863 33.0454 16.6188C32.8653 16.3674 32.9133 16.0083 33.1654 15.8168C33.4415 15.5893 33.6696 15.302 33.8377 14.9549C34.1257 14.4043 34.2698 13.698 34.2818 12.8601C34.2818 11.9025 34.1017 10.8492 33.7296 9.73594C33.6336 9.44865 33.7656 9.12546 34.0417 9.00576L36.9827 7.6651C37.1387 7.59328 37.3188 7.59328 37.4748 7.6651C37.6309 7.73692 37.7509 7.86859 37.7989 8.02421C38.1591 9.12546 38.3391 10.2147 38.3271 11.2322C38.3271 13.0277 37.7029 14.4402 36.6106 15.2183L33.8377 16.7983C33.7416 16.8462 33.6456 16.8821 33.5376 16.8821ZM35.026 9.87958C35.3381 10.9329 35.4942 11.9384 35.4822 12.8841C35.4822 13.5185 35.3982 14.105 35.2541 14.6317L35.9623 14.2247C36.6826 13.71 37.1147 12.6327 37.1267 11.2322C37.1267 10.5379 37.0427 9.80776 36.8506 9.05364L35.026 9.89155V9.87958Z"
        fill="#455068"
      />
      <path
        d="M37.2228 8.21572L34.2819 9.55638C33.3696 6.8631 31.4489 4.27755 29.2282 2.99675C28.4119 2.52991 27.6437 2.27854 26.9355 2.23066C26.3953 2.19475 25.9151 2.27854 25.495 2.48203L27.9918 1.03365L28.2439 0.890004C29.2402 0.375289 30.5967 0.49499 32.0731 1.35684C34.3659 2.68552 36.3465 5.41471 37.2108 8.20375L37.2228 8.21572Z"
        fill="white"
      />
      <path
        d="M34.2818 10.1549C34.1978 10.1549 34.1258 10.1429 34.0417 10.107C33.8857 10.0352 33.7656 9.91548 33.7056 9.7479C32.8053 7.11447 30.9327 4.67256 28.9281 3.52343C28.2078 3.10448 27.5356 2.87705 26.9114 2.84114C26.4793 2.8172 26.0831 2.87705 25.759 3.03266C25.4709 3.16433 25.1228 3.0566 24.9788 2.76931C24.8347 2.48203 24.9308 2.1349 25.2068 1.97929L27.9437 0.387258C29.1921 -0.259129 30.7647 -0.0915467 32.3852 0.854094C34.738 2.20672 36.8627 5.03167 37.799 8.04814C37.883 8.33543 37.7509 8.64665 37.4749 8.76635L34.5339 10.107C34.4499 10.1429 34.3658 10.1669 34.2818 10.1669V10.1549ZM27.8957 1.78776C28.4359 1.93141 28.9761 2.15884 29.5403 2.48203C31.653 3.70298 33.5616 6.0611 34.6299 8.74241L36.4905 7.89253C35.5782 5.34289 33.7656 3.02069 31.785 1.88352C30.5366 1.15335 29.3842 0.997735 28.5319 1.44063L27.9077 1.79973L27.8957 1.78776Z"
        fill="#455068"
      />
      <path
        d="M34.8701 12.8721C34.8701 13.8178 34.69 14.6078 34.3659 15.2302C34.1498 15.6731 33.8617 16.0203 33.5256 16.2836C33.4416 16.3554 33.3456 16.4153 33.2735 16.4632C32.2292 17.1095 30.7767 17.0377 29.1682 16.0921C26.0832 14.2726 23.5984 9.90353 23.5984 6.31249C23.5984 4.61273 24.1386 3.39178 25.0509 2.75736L25.459 2.50599C25.459 2.50599 25.483 2.48205 25.495 2.48205C25.9151 2.29053 26.4073 2.20673 26.9355 2.23067C27.6317 2.26659 28.412 2.51796 29.2282 2.99676C31.449 4.2656 33.3576 6.86311 34.2819 9.55639C34.666 10.6816 34.8821 11.8068 34.8701 12.8721Z"
        fill="white"
      />
      <path
        d="M31.737 17.4926C30.8487 17.4926 29.8764 17.1933 28.8681 16.6068C25.579 14.6676 22.9982 10.1429 22.9982 6.31248C22.9982 4.48105 23.5984 3.04463 24.7027 2.26657L25.1589 1.99126C25.1589 1.99126 25.2189 1.95535 25.2549 1.94338C25.7711 1.70398 26.3473 1.60822 26.9835 1.64413C27.7997 1.69201 28.664 1.97929 29.5403 2.48203C31.8211 3.78678 33.8497 6.43218 34.858 9.36486C35.2782 10.5978 35.4822 11.7828 35.4702 12.8841C35.4702 13.9135 35.2662 14.7873 34.8941 15.5055C34.654 15.9963 34.3179 16.4153 33.9097 16.7504C33.8137 16.8462 33.6697 16.93 33.5376 17.0018C33.0094 17.337 32.3972 17.4926 31.737 17.4926ZM25.7591 3.03266L25.3629 3.27206C24.6307 3.78678 24.1986 4.87606 24.1986 6.31248C24.1986 9.70002 26.5633 13.8537 29.4803 15.5774C30.8367 16.3674 32.0731 16.511 32.9614 15.9484C33.0454 15.9005 33.0935 15.8766 33.1415 15.8287C33.4416 15.5893 33.6697 15.302 33.8377 14.9549C34.1258 14.4043 34.2698 13.698 34.2819 12.8601C34.2819 11.9025 34.1018 10.8492 33.7297 9.73593C32.8294 7.10251 30.9568 4.6606 28.9521 3.51147C28.2319 3.09251 27.5597 2.86508 26.9355 2.82917C26.5033 2.80523 26.1072 2.86508 25.7831 3.02069L25.7591 3.03266Z"
        fill="#455068"
      />
      <path
        d="M60.7866 78.6119C60.7866 79.9645 60.3304 80.9461 59.5982 81.4608L59.2621 81.6523L57.3774 82.7296C57.6415 82.5142 57.8816 82.2269 58.0617 81.8678C58.3258 81.365 58.4698 80.7187 58.4698 79.9526C58.4698 79.0787 58.3017 78.157 57.9896 77.2473L60.3784 76.1461C60.6425 76.972 60.7866 77.8099 60.7866 78.6119Z"
        fill="#455068"
      />
      <path
        d="M57.3774 83.3281C57.1853 83.3281 56.9933 83.2324 56.8852 83.0648C56.7052 82.8134 56.7532 82.4543 57.0053 82.2628C57.2213 82.0832 57.3894 81.8678 57.5214 81.6044C57.7495 81.1615 57.8696 80.6109 57.8696 79.9406C57.8696 79.1745 57.7255 78.3246 57.4254 77.4388C57.3294 77.1515 57.4614 76.8283 57.7375 76.7086L60.1263 75.6074C60.2823 75.5356 60.4624 75.5356 60.6185 75.6074C60.7745 75.6792 60.8945 75.8109 60.9426 75.9665C61.2427 76.8762 61.3867 77.774 61.3747 78.6119C61.3747 80.1082 60.8585 81.2932 59.9342 81.9516L57.6655 83.2443C57.5695 83.2922 57.4734 83.3281 57.3654 83.3281H57.3774ZM58.7218 77.5705C58.9619 78.4084 59.082 79.2104 59.07 79.9526C59.07 80.3835 59.0219 80.7785 58.9379 81.1496L59.286 80.9461C59.8382 80.5511 60.1743 79.7132 60.1743 78.6119C60.1743 78.0852 60.1143 77.5466 59.9823 76.9959L58.7098 77.5825L58.7218 77.5705Z"
        fill="#455068"
      />
      <path
        d="M60.3903 76.158L58.0015 77.2593C57.2573 75.0688 55.6968 72.962 53.8842 71.9206C53.2239 71.5376 52.5877 71.3341 52.0236 71.2982C51.5914 71.2742 51.1953 71.3341 50.8472 71.5017L52.8758 70.3166L53.0799 70.1969C53.8962 69.778 54.9885 69.8737 56.2009 70.568C58.0735 71.6453 59.6821 73.8717 60.3903 76.1461V76.158Z"
        fill="white"
      />
      <path
        d="M57.9895 77.8458C57.9055 77.8458 57.8335 77.8338 57.7495 77.7979C57.5934 77.7261 57.4734 77.6064 57.4133 77.4388C56.6931 75.3201 55.1806 73.357 53.5721 72.4233C53.0079 72.0882 52.4677 71.9086 51.9756 71.8847C51.6274 71.8727 51.3394 71.9086 51.0873 72.0283C50.7992 72.16 50.4511 72.0523 50.307 71.765C50.163 71.4777 50.259 71.1306 50.5351 70.975L52.7678 69.6822C53.8362 69.1316 55.1446 69.2752 56.501 70.0533C58.4457 71.1785 60.1982 73.5007 60.9545 75.9785C61.0385 76.2658 60.9065 76.577 60.6304 76.6967L58.2416 77.7979C58.1576 77.8338 58.0736 77.8578 57.9895 77.8578V77.8458ZM53.0679 70.8912C53.428 71.0109 53.8002 71.1785 54.1843 71.3939C55.9008 72.3755 57.4494 74.2787 58.3256 76.4333L59.6341 75.8348C58.8898 73.8239 57.4614 71.9924 55.8888 71.0827C54.9045 70.5081 54.0042 70.3884 53.344 70.7236L53.0679 70.8912Z"
        fill="#455068"
      />
      <path
        d="M58.4697 79.9526C58.4697 80.7187 58.3136 81.365 58.0616 81.8678C57.8815 82.2269 57.6534 82.5142 57.3773 82.7296C57.3053 82.7895 57.2333 82.8374 57.1733 82.8733C56.321 83.4 55.1446 83.3401 53.8362 82.574C51.3274 81.0897 49.2987 77.5346 49.2987 74.6019C49.2987 73.2254 49.7429 72.2199 50.4871 71.7051L50.8232 71.5017C50.8232 71.5017 50.8352 71.4897 50.8592 71.4897C51.2073 71.3341 51.6035 71.2622 52.0356 71.2862C52.6118 71.3221 53.236 71.5256 53.8962 71.9086C55.6968 72.95 57.2573 75.0568 58.0136 77.2473C58.3257 78.157 58.5057 79.0787 58.4937 79.9526H58.4697Z"
        fill="white"
      />
      <path
        d="M55.9249 83.8189C55.1806 83.8189 54.3524 83.5795 53.5241 83.0887C50.8112 81.4847 48.6865 77.762 48.6865 74.6019C48.6865 73.0697 49.2027 71.8727 50.127 71.2144L50.4991 70.9869C50.4991 70.9869 50.5591 70.951 50.5951 70.939C51.0273 70.7356 51.5194 70.6518 52.0596 70.6877C52.7438 70.7236 53.4641 70.963 54.1963 71.3819C56.0689 72.4593 57.7495 74.6378 58.5778 77.0438C58.9259 78.0613 59.0939 79.0428 59.0939 79.9406C59.0939 80.8024 58.9259 81.5326 58.6138 82.1311C58.4097 82.5381 58.1336 82.8972 57.7735 83.1845C57.6775 83.2683 57.5694 83.3401 57.4494 83.4C57.0172 83.6753 56.5011 83.8069 55.9369 83.8069L55.9249 83.8189ZM51.0993 72.0283L50.7872 72.2199C50.223 72.6149 49.8989 73.4767 49.8989 74.6019C49.8989 77.3311 51.8075 80.6708 54.1483 82.0593C55.2167 82.6817 56.177 82.7895 56.8612 82.3705L57.0052 82.2748C57.2213 82.0952 57.3894 81.8797 57.5214 81.6164C57.7495 81.1855 57.8695 80.6229 57.8695 79.9526C57.8695 79.1865 57.7255 78.3366 57.4254 77.4508C56.7051 75.3321 55.1926 73.369 53.5841 72.4353C53.0199 72.1001 52.4798 71.9206 51.9876 71.8967C51.6515 71.8847 51.3514 71.9206 51.0993 72.0403V72.0283Z"
        fill="#455068"
      />
    </svg>
  );
};
