import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomSalesIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_1713_919"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <rect width="26" height="26" fill="#97EAE0" />
      </mask>
      <g mask="url(#mask0_1713_919)">
        <path
          d="M3.88561 22C3.62321 22 3.41 21.9181 3.246 21.7544C3.082 21.5907 3 21.3779 3 21.116V10.7776C3 10.5321 3.082 10.3233 3.246 10.1514C3.41 9.97954 3.62321 9.89359 3.88561 9.89359H7.35424C7.60025 9.89359 7.80935 9.97954 7.98155 10.1514C8.15375 10.3233 8.23985 10.5321 8.23985 10.7776V21.116C8.23985 21.3779 8.15375 21.5907 7.98155 21.7544C7.80935 21.9181 7.60025 22 7.35424 22H3.88561ZM11.2657 22C11.0033 22 10.7901 21.9181 10.6261 21.7544C10.4621 21.5907 10.3801 21.3779 10.3801 21.116V4.88404C10.3801 4.63847 10.4621 4.42974 10.6261 4.25784C10.7901 4.08595 11.0033 4 11.2657 4H14.7343C14.9803 4 15.1894 4.08595 15.3616 4.25784C15.5338 4.42974 15.6199 4.63847 15.6199 4.88404V21.116C15.6199 21.3779 15.5338 21.5907 15.3616 21.7544C15.1894 21.9181 14.9803 22 14.7343 22H11.2657ZM18.6458 22C18.3834 22 18.1702 21.9181 18.0062 21.7544C17.8421 21.5907 17.7601 21.3779 17.7601 21.116V12.7422C17.7601 12.4966 17.8421 12.2879 18.0062 12.116C18.1702 11.9441 18.3834 11.8581 18.6458 11.8581H22.1144C22.3604 11.8581 22.5695 11.9441 22.7417 12.116C22.9139 12.2879 23 12.4966 23 12.7422V21.116C23 21.3779 22.9139 21.5907 22.7417 21.7544C22.5695 21.9181 22.3604 22 22.1144 22H18.6458Z"
          fill="#97EAE0"
        />
      </g>
    </SvgIcon>
  );
}
