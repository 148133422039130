import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@novaera/theme-provider';

export const DatePickerWrapper = styled('div')`
  max-width: 200px;
`;

export const DefaultPickerDays = styled(PickersDay)`
  font-weight: 590;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  border-radius: 6px;
  transition: background-color 300ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    background-color: ${({ theme }) => theme.palette.nv_main[40]};

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.palette.nv_neutral[0]};
      background-color: ${({ theme }) => theme.palette.nv_main[50]};
    }
  }

  &.MuiPickersDay-today {
    border: ${({ theme }) => `2px solid ${theme.palette.nv_main[40]}`};

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }

    &.Mui-selected {
      color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border: ${({ theme }) => `2px solid ${theme.palette.nv_main[30]}`};
      background-color: ${({ theme }) => theme.palette.nv_main[40]};

      &:hover,
      &:focus,
      &:active {
        color: ${({ theme }) => theme.palette.nv_neutral[0]};
        background-color: ${({ theme }) => theme.palette.nv_main[50]};
      }
    }
  }
`;
