import { useUpdateDataModelOutline } from '@novaera/actioner-service';
import {
  isMaxLength,
  isMinLength,
  isRequired,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvEditIcon,
  NvField,
  NvFlex,
  NvForm,
  NvTextArea,
  NvTextField,
  NvTypography,
  useFormState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useMemo } from 'react';
import { isDataModelNameValid } from '../../../../utils';
import { DataModelValue } from '../../../create-table-modal/types';
import { DataModelNameAndDescriptionUpdateModalProps } from './types';

export const DataModelNameAndDescriptionUpdateModal: React.FC<
  React.PropsWithChildren<DataModelNameAndDescriptionUpdateModalProps>
> = ({ isOpen, handleClose }) => {
  const { userAppId, modelId } = useParams();
  const {
    values: { name, description },
  } = useFormState();
  const { mutate: updateDataModelOutline } = useUpdateDataModelOutline();
  const initialValues = useMemo(() => ({ name, description }), [description, name]);
  const handleSubmit = (values: DataModelValue) => {
    return new Promise<void>((resolve) => {
      updateDataModelOutline(
        { appId: userAppId, id: modelId, name: values.name, description: values.description },
        {
          onSuccess: () => {
            handleClose();
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <NvDialogV1 open={isOpen} fullWidth maxWidth="sm">
      <NvForm<DataModelValue> onSubmit={handleSubmit} initialValues={initialValues}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvEditIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Edit table</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex gap="16px">
                <NvField
                  name="name"
                  direction="label-on-top"
                  labelVariant="h5"
                  labelText="Name"
                  showErrorMessageOnlyWhenBlur
                  isRequired
                  validators={[
                    isRequired('Table name is required'),
                    isDataModelNameValid(),
                    isMinLength({ length: 2 }),
                    isMaxLength({ length: 50 }),
                  ]}
                  component={<NvTextField placeholder="What is your table name?" />}
                  hasRequiredIndicator
                />
                <NvField
                  name="description"
                  direction="label-on-top"
                  labelVariant="h5"
                  labelText="Description"
                  component={<NvTextArea rows={3} placeholder="What is this table about?" />}
                />
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" disabled={submitting} loading={submitting}>
                Save
              </NvButton>
              <NvButton color="secondary" onClick={handleClose}>
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={handleClose}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
