import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const QueryStartCard = styled(NvFlex)`
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  width: 700px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
  border-radius: 16px;
`;
