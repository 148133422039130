import {
  NvAddBoxIcon,
  NvArrowForwardIcon,
  NvButton,
  NvCustomHelpIcon,
  NvFlex,
  NvPowerRoundedIcon,
  NvSkeleton,
  NvTooltip,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { ConnectModal } from '../../integrations/components/connect-modal';
import { useTestConfigurationBarController } from './controllers/use-test-configuration-bar';
import { ConnectionCard, StyledNvMenuWithItems, StyledNvSelect } from './styled';

export const TestConfigurationBar = () => {
  const {
    connectionMenuItems,
    hasConnections,
    integrationId,
    defaultSelectedConnection,
    isSearchConnectionLoading,
    onConnectionChange,
    onNavigateToProfileConnectionClicked,
    connectionSchemaId,
  } = useTestConfigurationBarController();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const theme = useTheme();

  return (
    <>
      <ConnectionCard>
        <NvFlex flexDirection={'row'} justifyContent="space-between" alignItems="center">
          <NvFlex gap={'16px'} flexDirection={'row'} alignItems="center">
            <NvFlex gap={'8px'} flexDirection={'row'} alignItems="center">
              <NvPowerRoundedIcon fontSize="small" />
              <NvTypography variant="h5">Connection</NvTypography>
              <NvTooltip
                title="Select or add a new connection to test your action."
                variant="small"
                placement="top-start"
              >
                <NvCustomHelpIcon
                  htmlColor={theme.palette.nv_neutral[80]}
                  fontSize="small"
                  sx={{ width: '12px', height: '12px', ml: '3px' }}
                />
              </NvTooltip>
            </NvFlex>
            <NvFlex>
              {isSearchConnectionLoading && <NvSkeleton variant="rectangular" width="100px" />}
              {!isSearchConnectionLoading && hasConnections && (
                <StyledNvSelect
                  compact
                  defaultValue={defaultSelectedConnection}
                  onChange={onConnectionChange}
                  options={connectionMenuItems}
                />
              )}
              {!isSearchConnectionLoading && !hasConnections && (
                <NvButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    onModalOpenClicked();
                  }}
                >
                  Connect
                </NvButton>
              )}
            </NvFlex>
          </NvFlex>
          <StyledNvMenuWithItems
            triggerButton={{
              props: {
                onlyIcon: true,
                size: 'small',
                color: 'ghost',
              },
            }}
            menuItems={[
              {
                name: 'Add new connection',
                icon: <NvAddBoxIcon sx={{ fontSize: '16px' }} />,
                onClick: () => {
                  onModalOpenClicked();
                },
              },
              { name: 'Divider', isDivider: true },
              {
                name: 'Manage your connections',
                icon: <NvArrowForwardIcon sx={{ fontSize: '16px' }} />,
                onClick: () => {
                  onNavigateToProfileConnectionClicked();
                },
              },
            ]}
          />
        </NvFlex>
      </ConnectionCard>
      {connectionSchemaId && (
        <ConnectModal
          onModalCloseClicked={onModalCloseClicked}
          isOpened={isOpened}
          integrationId={integrationId}
          mode="Connect"
          schemaId={connectionSchemaId}
        />
      )}
    </>
  );
};
