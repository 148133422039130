import { NovaeraNode } from '@novaera/core';
import { AppWorkflowNodeType } from '../../../../components/user-app-workflow-node-types/types';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';

export const addClosure = () => {
  const buttonNode: NovaeraNode<AppWorkflowNodeType> = {
    id: `add-button-alias`,
    name: `add-button-alias`,
    width: 40,
    height: 40,
    type: 'AddButton',
    alias: `add-button-alias`,
  };

  const leaves = userAppGraph.getLeaves({
    isLeafFn: (node) => node.type !== 'AddButton' && node.isLeaf && node.type !== 'response',
  });

  leaves.forEach((l) => {
    userAppGraph.addEdge({
      sourceId: l.alias,
      targetId: `add-button-alias`,
      id: `${l.alias}-add-button-edge-id`,
      extraData: { type: 'Edge' },
    });
  });

  if (leaves.length > 0) {
    buttonNode.parent = leaves[0].alias;
    userAppGraph.addNode(buttonNode);
  }
};
