import { isAxiosError, NvButton, NvFlex, NvTextField, NvTypography, useToast } from '@novaera/core';
import { useState } from 'react';
import { useWorkflowNodeUtilities } from '../utils';

import {
  PropertyPanelHeaderCard,
  PropertyPanelHeaderCardActions,
  PropertyPanelHeaderCardBody,
  PropertyPanelHeaderIconBox,
  PropertyPanelHeaderTitleEditIcon,
  PropertyPanelHeaderTitleEditModeWrapper,
} from './styled';
import { PropertyPanelHeaderProps } from './types';

export const PropertyPanelHeader: React.FC<React.PropsWithChildren<PropertyPanelHeaderProps>> = ({
  icon,
  label,
  title,
  type,
  onTitleChange,
  validateTitle,
  actions,
  hasEditRight = true,
}) => {
  const { addToast } = useToast();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [editIsInProgress, setEditIsInProgress] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(title);
  const { getWorkflowNodeIcon, getWorkflowNodeLabel } = useWorkflowNodeUtilities();

  return (
    <PropertyPanelHeaderCard>
      <PropertyPanelHeaderCardBody>
        {icon ? (
          <PropertyPanelHeaderIconBox>{icon}</PropertyPanelHeaderIconBox>
        ) : type ? (
          <PropertyPanelHeaderIconBox>{getWorkflowNodeIcon({ type })}</PropertyPanelHeaderIconBox>
        ) : null}
        {onTitleChange ? (
          !editMode ? (
            <NvFlex flex="1 1 auto" minWidth={0}>
              {label ?? (type ? getWorkflowNodeLabel(type) : null)}
              <NvFlex direction="row" alignItems="center" gap="8px">
                <NvTypography variant="h2" noWrap>
                  {title}
                </NvTypography>
                {hasEditRight && (
                  <PropertyPanelHeaderTitleEditIcon
                    onClick={() => {
                      setEditMode(true);
                    }}
                  />
                )}
              </NvFlex>
            </NvFlex>
          ) : (
            <PropertyPanelHeaderTitleEditModeWrapper>
              <NvTextField
                fullWidth
                autoFocus
                value={currentValue}
                onChange={(value) => {
                  setCurrentValue(value.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                error={hasError}
              />

              <NvFlex direction="row" alignItems="center" gap="4px">
                <NvButton
                  size="small"
                  loading={editIsInProgress}
                  disabled={editIsInProgress}
                  onClick={async () => {
                    setHasError(false);
                    const validationError = validateTitle?.(currentValue);

                    if (validationError) {
                      setHasError(true);
                      addToast(validationError, { variant: 'error' });
                      return;
                    }
                    setEditIsInProgress(true);

                    try {
                      await onTitleChange(currentValue);
                      setEditMode(false);
                    } catch (e) {
                      setHasError(true);
                      if (isAxiosError(e) && typeof e !== 'string') {
                        addToast(JSON.stringify(e.response?.data), { variant: 'error' });
                      } else {
                        addToast(e as string, { variant: 'error' });
                      }
                    } finally {
                      setEditIsInProgress(false);
                    }
                  }}
                >
                  Save
                </NvButton>
                <NvButton
                  size="small"
                  color="secondary"
                  disabled={editIsInProgress}
                  onClick={() => {
                    setEditMode(false);
                    setCurrentValue(title);
                    setHasError(false);
                  }}
                >
                  Cancel
                </NvButton>
              </NvFlex>
            </PropertyPanelHeaderTitleEditModeWrapper>
          )
        ) : (
          <NvFlex flex="1 1 auto" minWidth={0}>
            {label ?? (type ? getWorkflowNodeLabel(type) : null)}
            <NvTypography variant="h2" noWrap>
              {title}
            </NvTypography>
          </NvFlex>
        )}
      </PropertyPanelHeaderCardBody>
      {actions && <PropertyPanelHeaderCardActions>{actions}</PropertyPanelHeaderCardActions>}
    </PropertyPanelHeaderCard>
  );
};
