import { Logo } from '../file';
import { InputParameterDependencyResponse } from '../types/common/types';
import { Integration } from '../types/integration';

export type BatchGetIntegrationParams = string[];

export type GetIntegrationParams = {
  id?: string;
};

export type CreateIntegrationParams = Pick<Integration, 'name'> & { logoId: string; description?: string };

export type CreateIntegrationResponse = Integration;

export type DeleteIntegrationParams = { id: string };

export enum QueryType {
  CATEGORY = 'category',
  OWNER = 'owner',
  TAG = 'tag',
  KEYWORD = 'keyword',
}

export enum SortParameterByType {
  NAME = 'name',
  VERSION = 'version',
  PUBLISH_DATE = 'publishDate',
}

export enum SortParameterOrderType {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortParameter = {
  order: SortParameterOrderType;
  by: SortParameterByType;
};

export enum IntegrationFieldType {
  EVENT_RULE_COUNT = 'event-rule-count',
}

export enum OperatorType {
  GREATER_THAN = 'greater-than',
}

export type Filter = {
  field: IntegrationFieldType;
  operator: OperatorType;
  value: string | number;
};

export type SearchIntegrationsParam = {
  type?: QueryType;
  value?: unknown;
  scopeType?: 'workspace' | 'global';
  sortParameters?: SortParameter[];
  next?: number;
  filters?: Filter[];
};

export type SearchIntegrationsResponse = {
  integrations: Integration[];
  next: string;
};

export interface UpdateIntegrationPartialParams {
  id: string;
  name?: string;
  logo?: Logo;
  description?: string;
  connectionSchemaId?: string | null;
}

export type UpdateIntegrationPartialResponse = Integration;

export type GetActionInputParamDependencies = {
  integrationId: string;
  actionId: string;
  params: {
    draft: boolean;
    version?: number;
  };
};

export type GetActionInputParamDependenciesResponse = InputParameterDependencyResponse;
