import classNames from 'classnames';
import { FC, useState } from 'react';
import { ChevronFrame, SideMenuFrame, SideMenuInner, SideMenuWrapper, StyledNvChevronRightIcon } from './styled';

export const SideMenu: FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <SideMenuFrame>
      <SideMenuWrapper orientation="horizontal" collapsedSize="24px" in={isOpen}>
        <SideMenuInner className={classNames({ isOpen: isOpen })}>{children}</SideMenuInner>
      </SideMenuWrapper>
      <ChevronFrame
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        className={classNames({ isOpen: isOpen })}
      >
        <StyledNvChevronRightIcon className="opener" fontSize="small" color="inherit" />
      </ChevronFrame>
    </SideMenuFrame>
  );
};
