import classnames from 'classnames';
import React from 'react';
import { NvLinkWrapper } from './styled';
import { NvLinkProps } from './types';

export const NvLink: React.FC<React.PropsWithChildren<NvLinkProps>> = ({ linkVariant, ...props }) => {
  return (
    <NvLinkWrapper
      className={classnames(props.className, {
        [linkVariant ?? '']: linkVariant ? true : false,
      })}
      {...props}
    />
  );
};
