import { NvDialogModal } from '@novaera/core';
import { CloneBody } from './body';
import { useCloneController } from './controllers/use-clone';
import { CloneParams } from './types';

export const Clone = ({ isOpened, onModalCloseClicked, name, onCloneSuccess, ...otherParams }: CloneParams) => {
  const { title, onFormSubmit, isLoading } = useCloneController({
    type: otherParams.type,
    onModalCloseClicked: onModalCloseClicked,
    onCloneSuccess,
  });

  return (
    <NvDialogModal
      Header={title}
      primaryButtonText="Clone"
      fullWidth
      maxWidth="sm"
      isLoading={isLoading}
      onFormSubmit={onFormSubmit}
      formProps={{
        initialValues: {
          newName: `${name}_cloned`,
          ...otherParams,
        },
      }}
      onCloseButtonClicked={onModalCloseClicked}
      open={isOpened}
      Body={<CloneBody />}
    />
  );
};
