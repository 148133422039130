import { CatalogEntityParameters, CatalogRelationship } from '@novaera/actioner-service';
import { SelectedItemDetail } from '../provider/use-catalog-provider';

export const getPreviousItem = (
  {
    parameters,
    relationshipDefinitions,
    removedItem,
  }: {
    parameters?: CatalogEntityParameters[];
    relationshipDefinitions: CatalogRelationship[];
    removedItem: 'parameters' | 'relationshipDefinitions';
  },
  index: number
): SelectedItemDetail | undefined => {
  if (removedItem === 'relationshipDefinitions') {
    if (index > 0) {
      return {
        selectedItemDetail: relationshipDefinitions[index - 1],
        index: index - 1,
      };
    } else if (parameters && index === 0 && parameters?.length > 0) {
      return {
        selectedItemDetail: parameters[parameters.length - 1],
        index: parameters.length - 1,
      };
    }
  } else if (removedItem === 'parameters' && parameters) {
    if (index > 0) {
      return {
        selectedItemDetail: parameters[index - 1],
        index: index - 1,
      };
    } else if (index === 0 && relationshipDefinitions?.length > 0) {
      return {
        selectedItemDetail: relationshipDefinitions[relationshipDefinitions.length - 1],
        index: relationshipDefinitions.length - 1,
      };
    }
  }
  return;
};
