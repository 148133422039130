import { NvCustomManagedAppsIcon, NvFlex, NvLink, NvLockIcon, NvTooltip, NvTypography } from '@novaera/core';
import { ManagedAppBox } from './styled';

export const ManagedAppInformationBox = () => {
  return (
    <ManagedAppBox>
      <NvFlex flexDirection={'row'} justifyContent={'space-between'} alignItems="center" width="100%">
        <NvFlex direction="row" gap={'8px'} alignItems="center" flex="1 1 auto" minHeight="0">
          <NvCustomManagedAppsIcon
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
          <NvTypography variant="h4">Managed app</NvTypography>
        </NvFlex>
        <NvTooltip title="This app is locked for changes." placement="top">
          <NvLockIcon sx={{ width: '16px', height: '16px', flex: '0 0 auto' }} />
        </NvTooltip>
      </NvFlex>

      <NvTypography variant="body2" noWrap flex="1 1 auto" minWidth={0}>
        This app is managed by <b>Actioner</b>,{' '}
        <u>
          <NvLink linkVariant="inherit" href="https://actioner.com/help/managed-apps" target="_blank">
            learn more
          </NvLink>
        </u>
        .
      </NvTypography>
    </ManagedAppBox>
  );
};
