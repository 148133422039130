import { useState } from 'react';

export const useDetailLayoutModalController = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsOpened(false);
  };
  const handleOpenModal = () => {
    setIsOpened(true);
  };

  return {
    isOpened,
    onClose: handleCloseModal,
    onOpenClick: handleOpenModal,
  };
};
