import { AuthenticationFields } from './fields/authentication-fields';
import { BaseUrlField } from './fields/base-url';
import { DescriptionField } from './fields/description';
import { TestConfiguration } from './fields/test-configuration';
import { UrlAndParameters } from './fields/url-and-parameters';
import { Variables } from './fields/variables';

export const IntegrationDetailBody = () => {
  return (
    <>
      <DescriptionField />
      <BaseUrlField />
      <AuthenticationFields />
      <UrlAndParameters />
      <Variables />
      <TestConfiguration />
    </>
  );
};
