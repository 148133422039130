import { useTheme } from '@novaera/theme-provider';
import { isString } from 'lodash';
import React from 'react';
import { NvButton } from '../button';
import { NvTypography } from '../typography';
import { EmptyStateBody, EmptyStateBodyContent, EmptyStateContainer } from './styled';
import { NvEmptyStateProps } from './types';

/**
 * ### NvEmptyState
 * #### Please read following tip!
 * If you want to add link into the `primaryText` or `secondaryText`.
 * You need to wrap text and anchor with `NvTypography` component without passing any props.
 * #### *Example*
 * ```jsx
 * <NvEmptyState
      primaryText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      secondaryText={
        <NvTypography>
          Lorem Ipsum has been the industry's standard dummy text, <a href="dummyUrl">here</a>
        </NvTypography>
      }
      buttonText="Action text"
      onButtonClick={() => {
        console.log('Button was clicked');
      }}
    />
 * ```
 */
export const NvEmptyState: React.FC<React.PropsWithChildren<NvEmptyStateProps>> = ({
  primaryText,
  secondaryText,
  buttonText,
  disabled = false,
  onButtonClick,
}) => {
  const theme = useTheme();
  return (
    <EmptyStateContainer sx={{ opacity: disabled ? 0.6 : 1 }}>
      <img
        width="60px"
        height="60px"
        alt="Empty state illustration"
        srcSet="assets/empty-state@2x.png, assets/empty-state.png"
        src="assets/empty-state.png"
      />
      <EmptyStateBody>
        <EmptyStateBodyContent>
          {isString(primaryText) ? (
            <NvTypography variant="body1" textColor="secondary">
              {primaryText}
            </NvTypography>
          ) : (
            React.isValidElement(primaryText) &&
            React.cloneElement(primaryText, {
              variant: 'body1',
              color: theme.palette.nv_neutral_alpha[600],
            })
          )}
          {secondaryText &&
            (isString(secondaryText) ? (
              <NvTypography variant="body2" textColor="subtle">
                {secondaryText}
              </NvTypography>
            ) : (
              React.isValidElement(secondaryText) &&
              React.cloneElement(secondaryText, {
                variant: 'body2',
                textColor: 'subtle',
              })
            ))}
        </EmptyStateBodyContent>
        {buttonText && (
          <NvButton color="secondary" onClick={onButtonClick}>
            {buttonText}
          </NvButton>
        )}
      </EmptyStateBody>
    </EmptyStateContainer>
  );
};
