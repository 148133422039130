import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const BodyItemWrapper = styled(NvFlex)`
  flex-direction: column;
  ${({ theme }) => theme.elevations.e100};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  gap: 16px;
`;
