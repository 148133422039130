import metadata from '../../../design-tokens/elevations';
import { Elevations } from '../../themes/types';
import { ElevationMetaData, SimplifiedElevationSchema } from './types';

const elevationsMetaData: ElevationMetaData = metadata.effect.elevation;

export const elevations: Partial<Elevations> = Object.keys(elevationsMetaData).reduce((acc, cur) => {
  const elevationColorGroup = elevationsMetaData[cur as keyof Elevations];
  const simplifiedElevationColorGroup: Partial<SimplifiedElevationSchema> = Object.keys(elevationColorGroup).reduce(
    (acc, cur) =>
      elevationColorGroup[cur as keyof typeof elevationColorGroup]?.value
        ? { ...acc, [cur]: elevationColorGroup[cur as keyof typeof elevationColorGroup].value }
        : { ...acc },
    {}
  );
  let shadowCSS = `box-shadow: `;
  const elevationValues = Object.values(simplifiedElevationColorGroup);
  elevationValues.forEach(({ offsetX, offsetY, radius, spread, color }, index) => {
    shadowCSS +=
      elevationValues.length - 1 === index
        ? `${offsetX}px ${offsetY}px ${radius}px ${spread}px ${color}`
        : `${offsetX}px ${offsetY}px ${radius}px ${spread}px ${color}, `;
  });
  return { ...acc, [cur]: shadowCSS };
}, {});
