import { NvButton, NvFlex, useFormState } from '@novaera/core';
import { DeleteWorkspaceFormValues } from '../types';

export const DeleteWorkspaceModalFooter = ({
  onCloseClicked,
  isLoading,
}: {
  onCloseClicked: () => void;
  isLoading: boolean;
}) => {
  const { values, invalid } = useFormState<DeleteWorkspaceFormValues>();

  return (
    <NvFlex flexDirection={'row'} gap="8px">
      <NvButton loading={isLoading} disabled={!values.acknowledge || invalid || isLoading} color="error" type="submit">
        Delete
      </NvButton>
      <NvButton variant="text" onClick={() => onCloseClicked()}>
        Cancel
      </NvButton>
    </NvFlex>
  );
};
