import { useParams } from '@novaera/route';

export const useGetIntegrationQueryParams = () => {
  const { integrationId, actionId } = useParams<{ integrationId: string; actionId: string }>();

  return {
    integrationId,
    actionId,
  };
};
