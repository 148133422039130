import {
  CatalogEntityType,
  RelationshipOfCatalogEntities,
  useSearchCatalogRelationship,
} from '@novaera/actioner-service';
import { NvArrowForwardIcon, NvChip, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { createSearchParams, useNavigate } from '@novaera/route';
import { assert } from '@novaera/utils';
import { DynamicMaterialIcons } from '../../../../../components/dynamic-material-icons';
import { RelationshipCard } from './styled';

export const Relationship: React.FC<
  { entityType: CatalogEntityType } & Pick<RelationshipOfCatalogEntities, 'relationship' | 'relatedEntity'>
> = ({ entityType, relationship, relatedEntity }) => {
  const navigate = useNavigate();
  const { data: catalogRelationships, isInitialLoading: isCatalogRelationshipsLoading } = useSearchCatalogRelationship({
    firstEntityTypeId: entityType.id,
  });
  const relationshipDefinition = catalogRelationships?.relationshipDefinitions.find(
    (d) => d.id === relationship.relationshipDefinitionId
  );

  const getDisplayParameter = (id: string): string => {
    const result = relatedEntity.propertiesByParameterId[id];
    if (typeof result === 'string') {
      return result;
    } else if (typeof result === 'number' || typeof result === 'boolean') {
      return result.toString();
    } else if (typeof result === 'object') {
      return JSON.stringify(result);
    } else {
      assert(
        false,
        new Error(
          `[Relationship/getDisplayParameter] - ${typeof result} is unexpected for type of propertiesByParameterId`
        ),
        'ERROR'
      );
    }
  };

  return isCatalogRelationshipsLoading ? (
    <NvFlex gap="8px" width="100%" alignItems="flex-start">
      <NvSkeleton variant="rectangular" width="100px" height="16px" />
      <NvSkeleton variant="rectangular" width="100px" height="32px" />
    </NvFlex>
  ) : (
    <NvFlex gap="8px" width="100%" alignItems="flex-start">
      <NvTypography variant="h5">{relationshipDefinition?.sourceToTargetRelationType}:</NvTypography>
      <RelationshipCard
        onClick={() => {
          navigate({
            pathname: `/catalog/${relationshipDefinition?.targetEntityType?.id}/records`,
            search: `?${createSearchParams({
              catalogEntityId: relatedEntity.id,
            })}`,
          });
        }}
      >
        <NvChip
          sx={{ flex: '0 0 auto' }}
          label={relationshipDefinition?.targetEntityType?.name}
          icon={
            <DynamicMaterialIcons initialValue={relationshipDefinition?.targetEntityType?.iconId}>
              {({ getCurrentIcon }) => {
                const Icon = getCurrentIcon();
                return Icon && <Icon sx={{ width: '16px', height: '16px' }} />;
              }}
            </DynamicMaterialIcons>
          }
        />
        <NvTypography variant="h5" flex="1 1 auto" minWidth={0}>
          {relationshipDefinition?.targetEntityType?.displayParameterId &&
            getDisplayParameter(relationshipDefinition.targetEntityType.displayParameterId)}
        </NvTypography>
        <NvArrowForwardIcon sx={{ flex: '0 0 auto', width: '16px', height: '16px' }} />
      </RelationshipCard>
    </NvFlex>
  );
};
