import React from 'react';
import { NvTooltip } from '../tooltip';
import { NvTypography } from '../typography';
import { TagWrapper } from './styled';
import { NvTagProps } from './types';

export const NvTag: React.FC<React.PropsWithChildren<NvTagProps>> = ({ label }) => {
  return (
    <NvTooltip title={label}>
      <TagWrapper>
        <NvTypography variant="body2" textColor="secondary" noWrap>
          {label}
        </NvTypography>
      </TagWrapper>
    </NvTooltip>
  );
};
