import { FC } from 'react';
import { NvBox } from '../../../../../ui/box';
import { NvFlex } from '../../../../../ui/flex';
import { NvSwitch } from '../../../../../ui/switch';
import { NvTypography } from '../../../../../ui/typography';
import { MenuItemStyled } from '../dynamic-static-menu-item/styled';

export const HiddenInputCheckbox: FC<
  React.PropsWithChildren<{ value?: boolean; onChange?: (value: boolean) => void }>
> = ({ value, onChange }) => {
  return (
    <MenuItemStyled
      sx={{ padding: '8px 12px' }}
      onClick={() => {
        onChange?.(!value);
      }}
    >
      <NvFlex gap="8px">
        <NvFlex direction="row" gap="8px" justifyContent="flex-start">
          <NvBox flex="0 0 auto">
            <NvSwitch checked={value} value={value ? value : null} variant="compact" />
          </NvBox>
          <NvTypography variant="h4" flex="1 1 auto" minWidth={0}>
            Hidden input
          </NvTypography>
        </NvFlex>
        <NvTypography variant="body2" textColor="subtle">
          Hidden inputs will not be visible when running this workflow.
        </NvTypography>
      </NvFlex>
    </MenuItemStyled>
  );
};
