import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppVersionWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 7px 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  flex: 0 0 auto;
`;
