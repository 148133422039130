import { ResponseConfiguration } from '@novaera/actioner-service';
import { useForm, useFormState } from '@novaera/core';
import { useEffect, useRef, useState } from 'react';
import { useRulesController } from '../../../../../../components/match-event-section/rules/controllers/use-rules';
import { ERROR_HANDLING_CONDITION_FIELD_NAME } from '../../constants';

export const useErrorHandlingResponseTabController = () => {
  const { values } = useFormState<ResponseConfiguration>();
  const { change } = useForm<ResponseConfiguration>();
  const [isErrorHandlingEnabled, setIsErrorHandlingEnabled] = useState<boolean>(
    values?.errorResponseConfiguration !== undefined
  );

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const { onAddClicked } = useRulesController({ conditionFieldName: ERROR_HANDLING_CONDITION_FIELD_NAME });

  useEffect(() => {
    const addEvent = function () {
      onAddClicked();
    };
    const addButton = addButtonRef.current;

    if (addButton) {
      addButton.addEventListener('click', addEvent);
    }

    return () => {
      if (addButton) {
        addButton.removeEventListener('click', addEvent);
      }
    };
  }, [addButtonRef, onAddClicked]);

  const handleErrorResponseConfigurationChange = (isChecked: boolean) => {
    setIsErrorHandlingEnabled(isChecked);
    if (!isChecked) {
      change('errorResponseConfiguration', undefined);
    }
  };

  return {
    isErrorHandlingEnabled,
    onErrorResponseConfigurationChange: handleErrorResponseConfigurationChange,
    addButtonRef,
  };
};
