import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_CATALOG_RELATIONSHIP_TYPES } from '../keys';
import { SearchCatalogRelationshipResult } from '../use-search-catalog-relationship';

export const useUpdateCatalogRelationshipCache = () => {
  const cache = useQueryClient();

  const updateCache = ({
    params,
    updateFunction,
  }: {
    params?: { entityTypeId: string };
    updateFunction: (param: { old?: SearchCatalogRelationshipResult }) => SearchCatalogRelationshipResult;
  }) => {
    cache
      .getQueriesData<SearchCatalogRelationshipResult>({
        queryKey: QUERY_KEY_CATALOG_RELATIONSHIP_TYPES.list(params?.entityTypeId),
      })
      .forEach((result) => {
        const [query, _] = result;
        cache.setQueryData<SearchCatalogRelationshipResult>(query, (old) => {
          const updated = updateFunction({ old });
          return updated;
        });
      });
  };

  return {
    updateCache,
  };
};
