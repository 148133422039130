import React from 'react';

type FocusStateBaseProps = {
  variant?: 'fullWidth';
  className?: string;
};

type FocusStateStandardProps = FocusStateBaseProps & {
  open: boolean;
  onClose: () => void;
  headerLogo?: React.ReactElement;
  maxWidth?: string;
};

type FocusStateFullWidthVariantProps = FocusStateBaseProps & {
  variant: 'fullWidth';
};

type FocusStateCustomHeaderProps = FocusStateStandardProps & {
  renderHeader: ({ close }: { close: () => void }) => React.ReactElement;
};

type FocusStateTitleHeaderProps = FocusStateStandardProps & {
  title: string;
};

export const isFocusStateCustomHeaderProps = (props: NvFocusStateProps): props is FocusStateCustomHeaderProps =>
  (props as FocusStateCustomHeaderProps).renderHeader !== undefined;

export const isFocusStateTitleHeaderProps = (props: NvFocusStateProps): props is FocusStateTitleHeaderProps =>
  (props as FocusStateTitleHeaderProps).title !== undefined;

export const isFocusStateFullWidthVariantProps = (props: NvFocusStateProps): props is FocusStateFullWidthVariantProps =>
  (props as FocusStateFullWidthVariantProps).variant === 'fullWidth';

export type NvFocusStateProps =
  | FocusStateCustomHeaderProps
  | FocusStateTitleHeaderProps
  | FocusStateFullWidthVariantProps;
