import { FC, PropsWithChildren } from 'react';
import { useDynamicInputContext } from '../../../dynamic-input';
import { useIntegrationActionOptionsContext } from '../../../options';
import { ParameterMapperForm } from '../../parameter-mapper-form';
import { PropertyMapperFormProps } from '../../parameter-mapper-form/types';

export const ActionParameterMapperForm: FC<
  PropsWithChildren<
    Pick<
      PropertyMapperFormProps,
      | 'inputParameters'
      | 'onParameterMappingsChanged'
      | 'initialParameterMappings'
      | 'hideInputParameterLabel'
      | 'context'
      | 'onScriptedChange'
      | 'isForceHideSwitch'
      | 'otherProps'
    >
  >
> = ({
  inputParameters,
  onParameterMappingsChanged,
  initialParameterMappings,
  hideInputParameterLabel,
  context,
  onScriptedChange,
  isForceHideSwitch,
  otherProps,
}) => {
  const { getOptionsResponse, setInputParameterIdsShowingOptions } = useIntegrationActionOptionsContext();
  const { getDynamicInputParameters, dynamicInputParameters, getDynamicInputComponentState } = useDynamicInputContext();
  return (
    <ParameterMapperForm
      dynamicInputParameters={dynamicInputParameters}
      inputParameters={inputParameters}
      onParameterMappingsChanged={onParameterMappingsChanged}
      getDynamicInputParameters={getDynamicInputParameters}
      initialParameterMappings={initialParameterMappings}
      getOptionsResponse={getOptionsResponse}
      setInputParameterIdsShowingOptions={setInputParameterIdsShowingOptions}
      hideInputParameterLabel={hideInputParameterLabel}
      onScriptedChange={onScriptedChange}
      context={context}
      getDynamicInputComponentState={getDynamicInputComponentState}
      isForceHideSwitch={isForceHideSwitch}
      otherProps={otherProps}
    />
  );
};
