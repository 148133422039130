import { useTheme } from '@novaera/theme-provider';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export const usePPDrawerItem = (params: { subPanels: { open: boolean; closeFn: () => void }[] }) => {
  const theme = useTheme();
  return helperFnDrawerItem({
    drawerBaseZIndex: theme.zIndex.drawer,
    ...params,
  });
};

export const usePPDrawerItemHelper = ({ refDom }: { refDom: MutableRefObject<null> }) => {
  const [style, setStyle] = useState({});
  const [className, setClassName] = useState('close');

  useEffect(() => {
    function findParentWithOpenClass(element: HTMLElement | null) {
      // Start from the current element and traverse up the DOM
      while (element) {
        // Check if the current element has the "open" class
        if (element.classList.contains('pp-drawer-item')) {
          return element;
        }

        // Move up to the parent element
        element = element.parentElement;
      }

      // If no ancestor with the "open" class is found, return null
      return null;
    }

    const childElement = refDom.current;
    const parentWithOpenClass = findParentWithOpenClass(childElement);

    if (parentWithOpenClass) {
      setStyle({ zIndex: Number(parentWithOpenClass.style.zIndex) - 1 });
    }
  }, [refDom]);

  const handlePanelOpen = useCallback(() => {
    setClassName('open');
  }, []);

  const handlePanelClose = useCallback(() => {
    setClassName('close');
  }, []);

  return {
    style,
    className,
    onPanelOpen: handlePanelOpen,
    onPanelClose: handlePanelClose,
  };
};

export const helperFnDrawerItem = ({
  drawerBaseZIndex = 0,
  ...params
}: {
  subPanels: { open: boolean; closeFn: () => void }[];
  drawerBaseZIndex?: number;
}) => {
  const { subPanels } = params;
  const maxZIndex = drawerBaseZIndex + subPanels.length;
  const panelProps = subPanels.map((subPanel, index) => {
    let className = subPanel.open ? 'open' : 'close';
    if (index === 0) {
      className += ' first';
    }

    className += ' pp-drawer-item';

    return {
      style: { zIndex: maxZIndex - index },
      className,
    };
  });
  const panelCloseFunctions = subPanels.map((_subPanel, index) => {
    return () => {
      const restOfSubPanels = subPanels.slice(index);
      restOfSubPanels.forEach((subPanelAfterThis) => subPanelAfterThis.closeFn());
    };
  });
  return { panelProps, panelCloseFunctions };
};

export const usePPDrawerWrapper = (params: { wrapper: { open: boolean } }) => {
  const { wrapper } = params;
  const wrapperProps = { className: wrapper.open ? 'open' : 'close' };
  return { wrapperProps };
};
