import {
  ActionerEventDetails,
  ConditionExecutionDetails,
  DispatchedWorkflowExecutionDetails,
  NodeExecutionDetails,
  QANodeExecutionDetails,
} from '@novaera/actioner-service';

export const isConditionExecutionDetails = (
  executionDetails: NodeExecutionDetails
): executionDetails is ConditionExecutionDetails => {
  return 'ruleEvaluationResults' in executionDetails;
};

export const isQANodeExecutionDetails = (
  executionDetails: NodeExecutionDetails
): executionDetails is QANodeExecutionDetails => {
  return executionDetails && 'contentMatchesByDocId' in executionDetails && !!executionDetails.contentMatchesByDocId;
};

export const isDispatchedWorkflowExecutionDetails = (
  executionDetails: NodeExecutionDetails
): executionDetails is DispatchedWorkflowExecutionDetails => {
  return 'dispatchedWorkflow' in executionDetails;
};

export const isActionerEventDetails = (
  executionDetails: NodeExecutionDetails
): executionDetails is ActionerEventDetails => {
  return executionDetails && 'eventId' in executionDetails;
};
