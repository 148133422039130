import { NvBox, NvFlex, NvHelpIcon, NvLockIcon, NvMenuWithItems, NvTooltip, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React, { ReactElement, isValidElement, useMemo } from 'react';

import { isCatalogEntityParameters } from '@novaera/actioner-service';
import { FieldLogoWrapper } from '../../styled';
import { findLogoFromFields } from '../../utils';
import { SCHEMA_TYPE_HELPS } from './constants';
import { DataModelFieldSchemaTitle } from './data-model-field-schema-title';
import { RightContentHeader } from './styled';
import { DataModelRightContentHeaderProps } from './type';

export const DataModelRightContentHeader = ({ field, fieldMenus }: DataModelRightContentHeaderProps) => {
  const { schema, id, name } = field;
  const { palette } = useTheme();
  const logo = useMemo(() => findLogoFromFields(schema.type), [schema.type]);

  return (
    <RightContentHeader>
      <NvFlex direction="row" alignItems="center" justifyContent="flex-start" flex="1 1 auto" minWidth={0} gap="12px">
        <NvTypography variant="h4">{name}</NvTypography>
        <NvFlex direction="row" alignItems="center" gap="4px">
          <FieldLogoWrapper>
            {isValidElement(logo) &&
              React.cloneElement(logo as ReactElement, { sx: { height: '12px', width: '12px' } })}
            <DataModelFieldSchemaTitle variant="h5" schema={schema} />
          </FieldLogoWrapper>
          <NvTooltip title={SCHEMA_TYPE_HELPS[schema.type]}>
            <NvHelpIcon fontSize="small" htmlColor={palette.nv_neutral[70]} />
          </NvTooltip>
        </NvFlex>
      </NvFlex>
      <NvBox flex="0 0 auto">
        {isCatalogEntityParameters(field) && field.managed && (
          <NvTooltip title={'This property is managed by Actioner, edit or delete operations are not allowed.'}>
            <NvLockIcon fontSize="small" htmlColor={palette.nv_neutral[300]} />
          </NvTooltip>
        )}
        {((isCatalogEntityParameters(field) && !field.managed && fieldMenus(id).length > 0) ||
          !isCatalogEntityParameters(field)) && <NvMenuWithItems menuItems={fieldMenus(id)} />}
      </NvBox>
    </RightContentHeader>
  );
};
