import { PartialUpdateQueryOption } from '@novaera/actioner-service';
import { LabelValue } from '@novaera/core';

export const QueryOptions: LabelValue<string>[] = [
  {
    label: 'Add',
    value: PartialUpdateQueryOption.ADD,
    description: 'The given value will be appended to the current value.',
  },
  {
    label: 'Set',
    value: PartialUpdateQueryOption.SET,
    description: 'The given value will replace the current value.',
  },
  {
    label: 'Remove',
    value: PartialUpdateQueryOption.REMOVE,
    description: 'The given value will be deleted from the current set of values.',
  },
];
