import { useCopyToClipboard } from '@novaera/utils';
import classNames from 'classnames';
import { FC } from 'react';
import { NvButton } from '../../button';
import { NvContentCopyIcon, NvCustomCheckCircle, NvErrorIcon } from '../../icons';
import { NvTypography } from '../../typography';
import { CalculatedResult } from '../types';
import { InformationBoxBody, InformationBoxWrapper, TitleText, TitleWrapper } from './styled';

interface InformationBoxProps {
  informationBoxId: string;
  informationBoxWidth: number;
  isFocused: boolean;
  calculatedResult: CalculatedResult;
  isExpandedMode?: boolean;
}

export const InformationBox: FC<React.PropsWithChildren<InformationBoxProps>> = ({
  informationBoxId,
  informationBoxWidth,
  isFocused,
  calculatedResult,
  isExpandedMode,
}) => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const handleContentCopyClicked = () => {
    copyToClipboard(String(calculatedResult.body));
  };

  return (
    <InformationBoxWrapper
      id={informationBoxId}
      width={informationBoxWidth}
      hidden={!isFocused || String(calculatedResult.body).length === 0}
      isExpandedMode={isExpandedMode}
    >
      <TitleWrapper
        className={classNames({
          result: !calculatedResult.isError,
          error: calculatedResult.isError,
        })}
      >
        <TitleText>
          {calculatedResult.isError ? (
            <NvErrorIcon sx={{ width: '12px', height: '12px' }} />
          ) : (
            <NvCustomCheckCircle sx={{ width: '12px', height: '12px' }} />
          )}
          <NvTypography variant="h7">{calculatedResult.title}</NvTypography>
        </TitleText>

        <NvButton
          size="small"
          onlyIcon
          color="ghost"
          onClick={handleContentCopyClicked}
          sx={{ transform: 'scale(0.8)', flex: '0 0 auto' }}
        >
          <NvContentCopyIcon />
        </NvButton>
      </TitleWrapper>
      <InformationBoxBody>
        <NvTypography variant="c3">{calculatedResult.body}</NvTypography>
      </InformationBoxBody>
    </InformationBoxWrapper>
  );
};
