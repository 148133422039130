import { NvAxios, useToast, useMutation } from '@novaera/core';
import { CONNECTION_ROOT_PATH } from '../constants';
import { InitiateOAuthParams } from '../types';

const initiateOAuth = async (params: InitiateOAuthParams) => {
  const result = await NvAxios.post<{ url: string }>(`${CONNECTION_ROOT_PATH}/oauth/initiate`, { ...params });
  return result?.data;
};

export const useInitiateOAuthService = () => {
  const { addToast } = useToast();

  return useMutation(initiateOAuth, {
    onError: () => {
      addToast('Connection cannot be initiated', { variant: 'error' });
    },
  });
};
