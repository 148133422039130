import {
  NvAddBoxIcon,
  NvButton,
  NvConditionalRender,
  NvFlex,
  NvSkeleton,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';

import { EmptyState } from '../../components/empty-state';
import { EmptyStates } from '../../components/empty-state/types';
import { ConnectModal } from '../../integrations/components/connect-modal';
import { ConnectionSchema } from './connection-schema';
import { useAppConnectionsController } from './controllers/use-app-connections';
import { AddConnectionPassedParam } from './types';

export const ProfileConnections = () => {
  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } =
    useNvDialogModalState<AddConnectionPassedParam>();
  const { userConnections, isUserConnectionsLoading } = useAppConnectionsController();

  return (
    <>
      <NvConditionalRender when={() => !isUserConnectionsLoading && (userConnections ?? [])?.length > 0}>
        <NvFlex gap="24px">
          <NvTypography variant="body1">
            Connections allow you to interact with external tools. Your authorized connections are listed below. You can
            connect to new apps, reconnect with old ones, or share credentials across different apps.
          </NvTypography>
          <NvFlex flexDirection="row">
            <NvButton color="secondary" onClick={() => onModalOpenClicked()} startIcon={<NvAddBoxIcon />}>
              Add connection
            </NvButton>
          </NvFlex>

          <NvFlex gap="16px">
            {userConnections?.map((userConnection) => (
              <ConnectionSchema key={userConnection.schemaId} userConnection={userConnection} />
            ))}
          </NvFlex>
        </NvFlex>
      </NvConditionalRender>
      <NvConditionalRender when={() => !isUserConnectionsLoading && (userConnections ?? [])?.length === 0}>
        <EmptyState
          variant={EmptyStates.PROFILE_CONNECTION}
          CustomButton={
            <NvButton color="secondary" onClick={() => onModalOpenClicked()} startIcon={<NvAddBoxIcon />} size="small">
              Add connection
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={() => isUserConnectionsLoading}>
        <NvFlex gap="16px">
          <NvSkeleton variant="rectangular" width={'100%'} height="56px" />
          <NvSkeleton variant="rectangular" width={'100%'} height="56px" />
        </NvFlex>
      </NvConditionalRender>

      {isOpened && (
        <ConnectModal
          isOpened={isOpened}
          schemaId={passedParameters?.schemaId}
          integrationId={passedParameters?.integrationId}
          onModalCloseClicked={onModalCloseClicked}
          mode="Connect"
        />
      )}
    </>
  );
};
