import { DOMAttributes, ReactNode } from 'react';
import { BaseNodeProps } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/node-ui-components/types';
import { NodeType } from '../property-panel/types';

export enum NodeStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface NodeProps extends BaseNodeProps {
  icon?: ReactNode;
  selected?: boolean;
  status?: NodeStatus;
  type: NodeType;
  name: string;
  actions?: ReactNode;
  onClick?: () => void;
  draggable?: boolean;
  onDragStart?: DOMAttributes<HTMLDivElement>['onDragStart'];
  onDragEnd?: DOMAttributes<HTMLDivElement>['onDragEnd'];
}
