import { MenuItems, NvBox, NvMenuWithItems } from '@novaera/core';
import classnames from 'classnames';
import { ReactNode, useState } from 'react';
import { ListItemActionContainer, ListItemContainer, ListItemContentContainer } from './styled';

type RoutelessListItemProps = {
  content: ReactNode;
  actions?: ReactNode | MenuItems[];
  className?: string;
  disabled?: boolean;
};

const isMenuItemsType = (actions: ReactNode | MenuItems[]): actions is MenuItems[] => {
  return Array.isArray(actions) && actions.length > 0 && actions[0].name !== undefined;
};

export const RoutelessListItem = ({ content, actions, className, disabled }: RoutelessListItemProps) => {
  const [isBaseIteActionsMenuOpen, setIsBaseItemActionsMenuOpen] = useState<boolean>(false);

  return (
    <ListItemContainer
      className={`${classnames({
        [`${className}`]: className,
        'is-base-item-actions-menu-open': isBaseIteActionsMenuOpen,
        'is-base-item-disabled': disabled,
      })}`}
    >
      <ListItemContentContainer>{content}</ListItemContentContainer>
      {actions && (
        <ListItemActionContainer>
          <NvBox className="base-item-actions" flex="0 0 auto">
            {isMenuItemsType(actions) ? (
              <NvMenuWithItems
                menuItems={actions}
                onOpened={() => {
                  setIsBaseItemActionsMenuOpen(true);
                }}
                onClosed={() => {
                  setTimeout(() => {
                    setIsBaseItemActionsMenuOpen(false);
                  }, 300);
                }}
              />
            ) : (
              actions
            )}
          </NvBox>
        </ListItemActionContainer>
      )}
    </ListItemContainer>
  );
};
