import { USER_PERMISSION, useUpdateIntegrationPartial } from '@novaera/actioner-service';
import { NvCloudUploadIcon, NvFlex, NvImage, NvTextField, NvTypography, isRequired } from '@novaera/core';
import { HeaderAndLogoInlineEdit } from '../../../../components/header-and-logo-inline-edit';
import { LogoPlaceholder } from '../../../../components/logo-placeholder';
import { NvLogoUpload } from '../../../../components/logo-upload';
import { UserPermissionBoundary } from '../../../../user-permission-boundary';
import { IntegrationHeaderAndLogoProps, UpdateIntegrationHeaderAndLogoParams } from './types';

export const IntegrationHeaderAndLogo: React.FC<React.PropsWithChildren<IntegrationHeaderAndLogoProps>> = ({
  integration,
}) => {
  const { mutate: updateIntegrationPartial } = useUpdateIntegrationPartial();
  const handleChangeImageAndLogo = (values?: UpdateIntegrationHeaderAndLogoParams) => {
    const { name, logo } = values ?? {};

    return new Promise<void>((resolve, reject) => {
      updateIntegrationPartial(
        { id: integration.id, name: name, logo, connectionSchemaId: integration.connectionSchemaId },
        {
          onSuccess: () => {
            return resolve();
          },
          onError: reject,
        }
      );
    });
  };
  return (
    <UserPermissionBoundary
      permission={USER_PERMISSION.INTEGRATION_UPDATE}
      Fallback={
        <NvFlex direction="row" gap="12px" alignItems="center">
          <NvImage
            FallBack={
              <LogoPlaceholder
                size={'large'}
                src="assets/integration-placeholder-image.png"
                initialText={integration.name}
              />
            }
            src={integration?.logoUrl}
            size="large"
            imageShape="square"
            hasNoElevation
          />
          <NvTypography variant="h1" flex="1 1 auto" minWidth="0" noWrap>
            {integration?.name}
          </NvTypography>
        </NvFlex>
      }
    >
      <HeaderAndLogoInlineEdit<UpdateIntegrationHeaderAndLogoParams>
        key={`${integration?.id}_header_and_logo_edit`}
        viewComponent={
          <NvFlex direction="row" gap="12px" alignItems="center">
            <NvImage
              FallBack={
                <LogoPlaceholder
                  size={'large'}
                  src="assets/integration-placeholder-image.png"
                  initialText={integration.name}
                />
              }
              src={integration?.logoUrl}
              size="large"
              imageShape="square"
              hasNoElevation
            />
            <NvTypography variant="h1" flex="1 1 auto" minWidth="0" noWrap>
              {integration?.name}
            </NvTypography>
          </NvFlex>
        }
        value={{ name: integration?.name }}
        validate={(value) => isRequired({ message: 'Name is required field' })(value?.name)}
        onConfirm={handleChangeImageAndLogo}
        editComponent={({ setCurrentValue, currentValue, error }) => (
          <NvFlex gap="24px" width="fit-content">
            <NvLogoUpload
              ImageFallBack={
                <LogoPlaceholder
                  size={'xlarge'}
                  src="assets/integration-placeholder-image.png"
                  initialText={integration.name}
                />
              }
              initialLogoUrl={integration?.logoUrl}
              type="integration"
              imageShape="square"
              imageSize="xlarge"
              onLogoStateChanged={({ logo }) => {
                setCurrentValue({ ...currentValue, logo });
              }}
              logoUploadButtonProps={{
                startIcon: <NvCloudUploadIcon />,
                text: 'Upload image',
              }}
              logoUploadHintProps={{ text: 'At least 280x280 pixels, and square images recommended' }}
              hasRemoveAction
            />
            <NvFlex gap="8px">
              <NvTypography variant="h5">Name</NvTypography>
              <NvTextField
                error={!!error}
                value={currentValue?.name}
                onChange={(e) => {
                  const value = e.target.value;
                  setCurrentValue({ ...currentValue, name: value });
                }}
              />
            </NvFlex>
          </NvFlex>
        )}
      />
    </UserPermissionBoundary>
  );
};
