import { NvAxios, useQuery } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';

import { QUERY_KEYS_WORKFLOW_BATCH_SAMPLE_RESPONSE, QUERY_KEYS_WORKFLOW_SAMPLE_RESPONSE } from '../keys';
import { GetWorkflowSampleResponse, GetWorkflowSampleResponseParam } from '../types';

export const getWorkflowSampleResponse = async ({ appId, workflowId }: GetWorkflowSampleResponseParam) => {
  const result = await NvAxios.get<GetWorkflowSampleResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/sample-response`
  );
  return result?.data;
};

export const useGetWorkflowSampleResponse = (param: GetWorkflowSampleResponseParam) => {
  const client = useQueryClient();

  return useQuery<GetWorkflowSampleResponse>(
    QUERY_KEYS_WORKFLOW_SAMPLE_RESPONSE.detail(param),
    () => getWorkflowSampleResponse(param),
    {
      enabled: !!param.appId && !!param.workflowId,
      onSuccess: () => {
        client.invalidateQueries(QUERY_KEYS_WORKFLOW_BATCH_SAMPLE_RESPONSE.all);
      },
    }
  );
};
