import { useFileUpload } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { FileUploadProps } from '../../types';

export const useFileUploadController = ({ file, onFileUploaded }: Pick<FileUploadProps, 'file' | 'onFileUploaded'>) => {
  const [uploadedFile, setUploadedFile] = useState<FileUploadProps['file']>(file);
  const { mutate: uploadFile, isLoading: isUploadLoading } = useFileUpload();

  const handleFileUpload = async ({ file }: { file: File }) =>
    new Promise<FileUploadProps['file']>((resolve) => {
      uploadFile(
        {
          data: file,
        },
        {
          onSuccess: ({ objectKey }) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              const base64data = reader.result;

              assert(
                typeof base64data === 'string',
                new Error(`base64Data should be string but it is ${typeof base64data}`),
                'WARNING'
              );

              const uploadedFile = { name: file.name, objectKey };
              setUploadedFile(uploadedFile);
              resolve(uploadedFile);
            };
          },
          onError: () => {
            resolve({ objectKey: '', name: '' });
          },
        }
      );
    });

  const cancelFileUpload = () => {
    const uploadedFile = { objectKey: '', name: '' };
    setUploadedFile(uploadedFile);
    onFileUploaded(uploadedFile);
  };

  return {
    isUploadLoading,
    uploadedFile,
    setUploadedFile,
    cancelFileUpload,
    onFileUpload: handleFileUpload,
  };
};
