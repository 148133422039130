import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { WORKFLOW_HISTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_WORKFLOW_HISTORIES } from '../keys';
import { GetWorkflowNodeExecutionsParams, GetWorkflowNodeExecutionsResponse } from './types';

const getWorkflowNodeExecutions = async ({
  appId,
  workflowId,
  workflowExecutionId,
  limit = 100,
  pageParam,
  ...params
}: GetWorkflowNodeExecutionsParams) => {
  const sortOrder = params['sort-order'];
  const nodeAlias = params['node-alias'];

  const result = await NvAxios.get<GetWorkflowNodeExecutionsResponse>(
    `${WORKFLOW_HISTORY_ROOT_PATH({ appId, workflowId })}/${workflowExecutionId}/node-executions`,
    {
      params: {
        limit,
        ...(pageParam ? { next: pageParam } : {}),
        ...(sortOrder ? { 'sort-order': sortOrder } : {}),
        ...(nodeAlias ? { 'node-alias': nodeAlias } : {}),
      },
    }
  );
  return result?.data;
};

export const useGetWorkflowNodeExecutions = ({
  appId,
  workflowId,
  workflowExecutionId,
  ...params
}: GetWorkflowNodeExecutionsParams) => {
  return useInfiniteQuery<GetWorkflowNodeExecutionsResponse>(
    QUERY_KEYS_WORKFLOW_HISTORIES.node_executions({ appId, workflowId, workflowExecutionId, ...params }),
    (props) => getWorkflowNodeExecutions({ appId, workflowId, workflowExecutionId, ...params, ...props }),
    {
      enabled: !!appId && !!workflowId && !!workflowExecutionId,
      getNextPageParam: (lastPage) => lastPage?.nextPageKey,
    }
  );
};
