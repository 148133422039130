import { styled } from '@novaera/theme-provider';
import { NvBox } from '../../box';
import { NvSelectMenuItem } from '../../select/styled';

export const NestedDropdownItemStyled = styled(NvSelectMenuItem)`
  &.MuiMenuItem-root {
    width: 225px;
    padding: 0;
    font-size: 16px;
    border: none;

    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }
`;

export const NestedDropdownInnerWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 6px;
  margin: 0 5px;
  gap: 8px;
  font-size: 16px;
  align-items: center;
  border-radius: 6px;
  min-height: 32px;

  :hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  }

  &.sub-menu-open {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  }
`;
