import { useRef } from 'react';

// check the blog post for more details and what is different from usePrevious
export const usePreviousPersistentWithMatcher = <TValue>(
  value: TValue,
  isEqualFunc: (prev: TValue, next: TValue) => boolean
) => {
  const ref = useRef<{ value: TValue; prev: TValue | null }>({
    value: value,
    prev: null,
  });

  const current = ref.current.value;

  if (isEqualFunc ? !isEqualFunc(current, value) : value !== current) {
    ref.current = {
      value: value,
      prev: current,
    };
  }
  // console.log('prev     ', JSON.stringify(ref.current.prev));
  return ref.current.prev;
};
