import { AdvanceFieldTypes, BaseFieldType } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { NestedDropdownItem, NvAddBoxIcon, NvButton, NvNestedDropdown, useField } from '@novaera/core';
import { generateUniqueId } from '@novaera/utils';
import { FIELD_TYPE_SCHEMAS, MENU_ITEMS } from '../constants';

import { snakeCase } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useListEntityTypes } from '../../../catalog/controllers/use-list-entity-types';
import { DataModelChooseFieldTypeModal } from '../left-content/data-model-choose-field-type-modal';
import { ChooseFieldTypeModalOnSubmitParamType } from '../left-content/data-model-choose-field-type-modal/types';

type DataModelMenuProps<T extends BaseFieldType> = {
  label: string;
  fieldsFieldName: string;
  fields: T[];
  onSelectedFieldIndexChange: (item: T, index: number) => void;
  buttonProps?: React.ComponentProps<typeof NvButton>;
};
export const DataModelMenu = <T extends BaseFieldType>({
  label,
  fieldsFieldName,
  fields,
  buttonProps,
  onSelectedFieldIndexChange,
}: DataModelMenuProps<T>) => {
  const [advanceFieldType, setAdvanceFieldType] = useState<AdvanceFieldTypes | undefined>();

  const {
    input: { onChange, value: fieldsValue },
  } = useField<T[]>(fieldsFieldName);

  const { entityTypes, isLoading } = useListEntityTypes();

  const referenceModels = useMemo(() => entityTypes?.map((m) => ({ value: m.id, label: m.name })), [entityTypes]);

  const handleSelectField = useCallback(
    (selectedField: NestedDropdownItem) => {
      const { id, name } = selectedField;
      const addedFieldTypeCount = fieldsValue.filter(
        (f) => f.schema.type === selectedField.id && f.name !== 'id'
      ).length;

      const selectedSchema = FIELD_TYPE_SCHEMAS[id as SchemaType];
      const newField = {
        id: generateUniqueId(),
        name: addedFieldTypeCount === 0 ? snakeCase(name) : `${snakeCase(name)}_${addedFieldTypeCount}`,
        schema: selectedSchema,
      } as T;

      if ([SchemaType.array, SchemaType.map, SchemaType.recordReference].includes(selectedField.id as SchemaType)) {
        setAdvanceFieldType(newField as AdvanceFieldTypes);
      } else {
        onChange([...fields, newField]);
        onSelectedFieldIndexChange(newField, fields.length);
      }
    },
    [fields, fieldsValue, onChange, onSelectedFieldIndexChange]
  );

  const handleOnSubmit = (values?: ChooseFieldTypeModalOnSubmitParamType) => {
    const fieldType = advanceFieldType?.schema.type;
    if (fieldType && fieldType === SchemaType.recordReference) {
      const newFieldType = { ...advanceFieldType, schema: { ...advanceFieldType.schema, ...values } } as T;
      onChange([...fields, newFieldType]);
      onSelectedFieldIndexChange(newFieldType, fields.length);
    } else if (fieldType && (fieldType === SchemaType.array || fieldType === SchemaType.map)) {
      const newFieldType = { ...advanceFieldType, schema: { ...advanceFieldType.schema, valueSchema: values } } as T;
      onChange([...fields, newFieldType]);
      onSelectedFieldIndexChange(newFieldType, fields.length);
    }
    setAdvanceFieldType(undefined);
  };

  return (
    <>
      <NvNestedDropdown
        menuItems={MENU_ITEMS}
        onClickItem={handleSelectField}
        trigger={
          <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" {...buttonProps}>
            {label}
          </NvButton>
        }
      />
      {advanceFieldType && (
        <DataModelChooseFieldTypeModal
          isOpen={!!advanceFieldType}
          selectedField={advanceFieldType}
          onClose={() => setAdvanceFieldType(undefined)}
          onSubmit={handleOnSubmit}
          referenceModels={referenceModels}
          modelId={entityTypes?.[0].id}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
