import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const InitiatorWrapper = styled(NvFlex)`
  flex-direction: row;
  padding: 16px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  ${({ theme }) => theme.elevations.e100};
  cursor: pointer;
`;

export const SourceTypesSlideWrapper = styled(NvFlex)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: ${({ theme: { palette } }) => palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  z-index: 100;
  overflow: auto;
  padding-bottom: 60px;
`;
