import { SearchUserAppPayloadFilter, UserApp } from '@novaera/actioner-service';
import {
  AccountCircleIcon,
  DefaultRenderOption,
  Initial,
  NvAutocomplete,
  NvDivider,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvImage,
  NvTextField,
  NvTypography,
  isMaxLength,
  isRequired,
  useFormState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { useAddDependency } from '../../add-dependency/controllers/use-add-dependency';
import { LoadingSkeleton } from '../../loading-skeleton';
import { DependencyForm } from '../../types';

export const UpdateDependency = () => {
  const { values } = useFormState<DependencyForm>();

  const filters = useMemo(() => {
    const filters: SearchUserAppPayloadFilter[] = [
      {
        field: 'dependencies',
        operation: 'not-exist',
      },
    ];

    if (values.appSchemaId) {
      filters.push({
        field: 'schema-id',
        operation: 'equals',
        value: values.appSchemaId,
      });
    }

    if (values.appSchemaVersion) {
      filters.push({
        field: 'schema-version',
        operation: 'equals',
        value: values.appSchemaVersion,
      });
    }

    if (!values.appSchemaId && !values.appSchemaVersion) {
      filters.push({
        field: 'id',
        operation: 'equals',
        value: values.appId,
      });
    }

    return filters;
  }, [values.appId, values.appSchemaId, values.appSchemaVersion]);

  const { onAppSearchKeywordChange, userApps, isLoading } = useAddDependency({
    filters,
  });
  const theme = useTheme();

  const selectedApp = userApps.find((app) => app.id === values.appId);

  return isLoading ? (
    <LoadingSkeleton />
  ) : (
    <NvFlex gap={'16px'}>
      <NvTypography>
        The <b>{selectedApp?.name}</b> app has been selected for this dependency. You may only replace it with another
        Jira software app.
      </NvTypography>
      <NvField<UserApp>
        labelText="App"
        direction="label-on-top"
        validators={[isRequired()]}
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        isAutoComplete
        name={'appId'}
        parse={(value: UserApp) => {
          return value?.id;
        }}
        format={(appId: string) => {
          const result = userApps.find((app) => app.id === appId) ?? null;
          return result;
        }}
        component={
          <NvAutocomplete
            onInputChange={(_, value, reason) => {
              if (!(value && reason === 'reset')) {
                onAppSearchKeywordChange(value);
              }
            }}
            options={userApps ?? []}
            getOptionLabel={(option) => option.name ?? ''}
            renderInput={(props) => {
              const selectedApp = userApps.find((app) => app.id === values.appId);
              return (
                <NvTextField
                  {...props}
                  placeholder="Select an app"
                  {...(selectedApp && {
                    startIcon: (
                      <NvImage
                        size="small"
                        src={selectedApp.logoUrl}
                        FallBack={
                          <Initial size="medium" color={theme.palette.nv_neutral[500]} value={selectedApp.name} />
                        }
                      />
                    ),
                  })}
                />
              );
            }}
            renderOption={(props, option) => (
              <DefaultRenderOption {...props} key={option.id}>
                <NvFlex direction="row" alignItems="center" gap="8px">
                  <NvImage
                    size="small"
                    imageShape="circle"
                    src={option?.logoUrl}
                    srcSet={option?.logoUrl}
                    FallBack={<AccountCircleIcon />}
                  />

                  <NvTypography>{option.name}</NvTypography>
                  {option.schemaVersion && (
                    <>
                      <NvDivider
                        orientation="vertical"
                        sx={{
                          height: '9px',
                        }}
                      />
                      <NvTypography variant="h6">{option.schemaVersion}</NvTypography>
                    </>
                  )}
                </NvFlex>
              </DefaultRenderOption>
            )}
            popupIcon={<NvExpandMoreIcon />}
            fullWidth
          />
        }
      />
      <NvField<string>
        labelText="Dependency name"
        direction="label-on-top"
        name="name"
        component={<NvTextField placeholder="Write a dependency name" />}
        validators={[
          isRequired(),
          isMaxLength({
            length: 100,
          }),
        ]}
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
      />
    </NvFlex>
  );
};
