import { NvButton, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkflowStickyPanelContainer = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 12px;
  gap: 8px;
  padding: 6px 6px 6px 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const WorkflowStickyPanelButton = styled(NvButton)`
  &.MuiButtonBase-root {
    border-radius: 6px;
  }
`;

export const StickyPanel = styled(NvFlex)`
  right: 0;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
