import { FormProps, NvForm } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { PropertyPanelHeader } from '../../../../../../../../components';
import { IntegrationTriggerRootConditionFormValues } from './types';

export const StyledNvForm = styled(NvForm)<FormProps<IntegrationTriggerRootConditionFormValues>>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;

export const PropertyPanelHeaderStyled = styled(PropertyPanelHeader)`
  flex: 0 0 auto;
`;
