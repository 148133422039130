import { AuthenticationField } from '@novaera/actioner-service';

export type AuthenticationFieldState = {
  isModalOpened?: boolean;
  editAuthenticationField?: {
    authenticationField: AuthenticationField;
    rowIndex: number;
  };
};

export enum Action {
  OpenAddFieldModal = 'open-add-field-modal',
  CloseAddFieldModal = 'close-add-field-modal',
  UpdateAuthenticationField = 'update-authentication-field',
}

export type UpdateAuthenticationFieldPayload = {
  authenticationField: AuthenticationField;
  rowIndex: number;
};

export type AuthenticationFieldPayloads =
  | Action.OpenAddFieldModal
  | Action.CloseAddFieldModal
  | UpdateAuthenticationFieldPayload;

export interface ActionType<T> {
  type: Action;
  payload?: T;
}
