import { WorkspaceType } from '../components/workspace-item/types';

export enum AppContextAction {
  UpdateWorkspaces = 'update-workspaces',
  UpdateUserInitialBgColorKeyMap = 'update-user-initial-bg-color-key-map',
  ChangeSettingsMenuState = 'change-settings-menu-state',
  UpdateWorkspacesPayload = 'UpdateWorkspacesPayload',
}

export type State = {
  workspaces: WorkspaceType[];
  userInitialBgColorKeyMap: Record<string, string>;
  isSettingsMenuOpened: boolean;
};

export type ActionType<T> = { type: AppContextAction; payload: T };

export type UpdateWorkspacesPayload = { workspaces: WorkspaceType[] };
export type ChangeSettingsMenuState = { isSettingsMenuOpened: boolean };
export type UpdateUserInitialBgColorKeyMapPayload = { userInitialBgColorKeyMap: Record<string, string> };

export type Dispatch = (
  action: ActionType<UpdateWorkspacesPayload | UpdateUserInitialBgColorKeyMapPayload | ChangeSettingsMenuState>
) => void;

export type AppContextProviderProps = { children: React.ReactNode };
