import { BaseFieldType, CatalogEntityParameters, CatalogRelationship, FieldType } from '@novaera/actioner-service';
import { ReactNode } from 'react';
import { DataModelDefaultPageProps } from './default-page/types';

export type SwitchedModeProps<FieldTypeType extends BaseFieldType> = {
  Component: ReactNode;
  hasSwitchedMode: true;
};
type WithoutSwitchedModeProps<FieldTypeType extends BaseFieldType> = {
  hasSwitchedMode: false;
} & DataModelDefaultPageProps<FieldTypeType>;

export type DataModelLayoutProps<FieldTypeType extends BaseFieldType> = {
  HeaderComponent: ReactNode;
} & (SwitchedModeProps<FieldTypeType> | WithoutSwitchedModeProps<FieldTypeType>);

export const isSwitchModeComponent = <FieldTypeType extends BaseFieldType>(
  props: SwitchedModeProps<FieldTypeType> | WithoutSwitchedModeProps<FieldTypeType>
): props is SwitchedModeProps<FieldTypeType> => {
  return props.hasSwitchedMode;
};

export type FieldTypesUnion = FieldType | CatalogEntityParameters;

export type DataModelDefaultPageGenericFieldType = CatalogRelationship | FieldTypesUnion;
