import { NvButton, NvFlex, NvTypography } from '@novaera/core';
import React from 'react';
import { useSlackManualUserMapping } from './controllers/use-slack-manual-user-mapping';

export const SlackManualUserMapping: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isSuccess, isError, isLoading } = useSlackManualUserMapping();

  if (isSuccess) {
    return (
      <NvFlex spacing={2} alignSelf="center" alignItems="center" marginTop="24px">
        <NvFlex>
          <NvTypography>User is mapped successfully</NvTypography>
        </NvFlex>
        <NvFlex>
          <NvButton color="primary" href="/">
            Go to My apps
          </NvButton>
        </NvFlex>
      </NvFlex>
    );
  } else if (isLoading) {
    <NvFlex spacing={2} alignSelf="center" alignItems="center" marginTop="24px">
      <NvTypography>Please wait while we are mapping your Slack user</NvTypography>
    </NvFlex>;
  } else if (isError) {
    return (
      <NvFlex spacing={2} alignSelf="center" alignItems="center" marginTop="24px">
        <NvFlex>
          <NvTypography>Slack user could not mapped</NvTypography>
        </NvFlex>
        <NvFlex>
          <NvButton color="primary" href="/">
            Go to My apps
          </NvButton>
        </NvFlex>
      </NvFlex>
    );
  } else {
    return (
      <NvFlex spacing={2} alignSelf="center" alignItems="center" marginTop="24px">
        <NvButton color="primary" href="/">
          Go to My apps
        </NvButton>
      </NvFlex>
    );
  }

  // although all paths return a val, ts does not recognize it
  return (
    <NvFlex spacing={2} alignSelf="center" alignItems="center" marginTop="24px">
      <NvButton color="primary" href="/">
        Go to My apps
      </NvButton>
    </NvFlex>
  );
};
