import { NvFlex, NvHelpIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { SampleSearchBox, SearchTooltip } from './styled';

export const QuerySearchHelpTooltip: React.FC<React.PropsWithChildren<{ href?: string }>> = ({
  children,
  href = 'https://actioner.com/help/table-search-query',
}) => {
  const { palette } = useTheme();

  return (
    <SearchTooltip
      title={
        <NvFlex gap="8px">
          <NvTypography variant="body1">You can build structured queries to search for records here.</NvTypography>
          <NvTypography variant="h5">Example:</NvTypography>
          <SampleSearchBox>
            <NvTypography variant="c1">
              fieldName = <span style={{ color: '#a11' }}>'fieldValue'</span>
            </NvTypography>
          </SampleSearchBox>
          <NvTypography variant="body1">
            For more information on creating search queries, click{' '}
            <a href={href} target="_blank" rel="noreferrer">
              <strong>here</strong>
            </a>
            .
          </NvTypography>
        </NvFlex>
      }
      fullWidth
    >
      <NvFlex>
        {children ?? <NvHelpIcon sx={{ width: '16px', height: '16px' }} htmlColor={palette.nv_neutral[300]} />}
      </NvFlex>
    </SearchTooltip>
  );
};
