import React from 'react';

export enum ConfirmDialogContextAction {
  Open = 'open-modal',
  Close = 'close-modal',
  SetUp = 'set-up',
}

export type ConfirmDialogState = {
  isOpen: boolean;
  title?: string;
  message?: React.ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm: () => void;
  onClose: () => void;
  confirmButton?: JSX.Element;
  icon?: JSX.Element;
};

export type ActionType<T> = { type: ConfirmDialogContextAction; payload?: T };

export type OpenConfirmDialogPayload = Omit<Partial<ConfirmDialogState>, 'isOpen'>;

export type Dispatch = (action: ActionType<OpenConfirmDialogPayload | undefined>) => void;

export type ConfirmDialogContextProviderProps = { children: React.ReactNode };
