import { NvFlex, NvSkeleton } from '@novaera/core';
import { FC } from 'react';
import { BillingCardWrapper } from '../billing-card/styled';

export const BillingLoading: FC = () => {
  return (
    <NvFlex gap="16px">
      <BillingCardWrapper>
        <NvSkeleton variant="rectangular" height="16px" width="300px" />
        <NvSkeleton variant="rectangular" height="16px" width="600px" />
      </BillingCardWrapper>
      <BillingCardWrapper>
        <NvSkeleton variant="rectangular" height="16px" width="300px" />
        <NvSkeleton variant="rectangular" height="16px" width="600px" />
        <NvSkeleton variant="rectangular" height="16px" width="600px" />
        <NvSkeleton variant="rectangular" height="16px" width="600px" />
        <NvSkeleton variant="rectangular" height="16px" width="600px" />
      </BillingCardWrapper>
    </NvFlex>
  );
};
