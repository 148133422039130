import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';

export const ChevronOpenerBox = styled(NvBox)`
  display: flex;
  align-items: center;
  cursor: pointer;

  .chevron-icon {
    transition: transform 200ms ease-in-out;
  }

  &.is-open {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
`;
