import { CarouselProps } from 'react-responsive-carousel';

export enum CarouselMode {
  MODAL = 'modal',
  PAGE = 'page',
}

export enum CarouselItemType {
  VIDEO = 'video',
  IMG = 'img',
}
export type CarouselItem = { type: CarouselItemType; url: string; displayText: string };

export interface NvCarouselProps extends Partial<CarouselProps> {
  items: CarouselItem[];
  title?: string;
}
