import { useGetConnectionService } from '@novaera/actioner-service';
import { AppConnectionDetailConnectionInformation } from '../../detail/connection-information';
import { useUserConnectionInfoController } from './controllers/use-user-connection-info';
import { UserConnectionInfoParams } from './types';

export const UserConnectionInfo = ({ connectionInfo, integrationId, schemaId }: UserConnectionInfoParams) => {
  const { data: connection, isLoading: isConnectionLoading } = useGetConnectionService({
    connectionId: connectionInfo.id,
  });

  const { isConnectionSchemaLoading, connectionSchema } = useUserConnectionInfoController({
    integrationId,
    schemaId,
  });

  return (
    <AppConnectionDetailConnectionInformation
      {...(isConnectionLoading || isConnectionSchemaLoading
        ? { state: 'loading' }
        : connection && connectionSchema
        ? { state: 'loaded', connectionSchema: connectionSchema, connection: connection }
        : { state: 'error' })}
      integrationId={integrationId}
    />
  );
};
