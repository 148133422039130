import {
  ActionState,
  RunStep,
  RunStepType,
  useGetIntegration,
  useGetIntegrationAction,
  useGetIntegrationActionStepResponse,
  useGetIntegrationActionStepResponses,
  useRunIntegrationActionStep,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../../../../../../../../action-designer/providers/input-values/utils';
import { useDynamicInputContext } from '../../../../../../../../components';
import { OutputItem } from '../../../../../../../../components/output-item';
import { useTestConfigurationProvider } from '../../../../../../../../components/test-configuration-bar/providers/test-configuration';
import { useFormIdentifierContext } from '../../../../../../../../providers/form-identifier-provider';
import { useFunctionsContext } from '../../../../../../../providers/functions-provider';
import { useInputValuesContext } from '../../../../../../../providers/input-values';
import { useRequestContext } from '../../../../../../../providers/request-provider';

export const useResponseResult = (testType: RunStepType = RunStepType.REQUEST) => {
  const { integrationId, actionId } = useParams<{ integrationId: string; actionId: string }>();
  const { selectedFunctionId } = useFunctionsContext();
  const { selectedRequestId } = useRequestContext();
  const {
    state: { selectedConnection },
  } = useTestConfigurationProvider();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
  });
  const { enrichedContextFromRequestExecutions } = useGetIntegrationActionStepResponses({
    integrationId,
    actionId,
  });
  const { dynamicInputParameters } = useDynamicInputContext();

  const allInputParameters = useMemo(
    () => [...(integrationAction?.inputParameters ?? []), ...dynamicInputParameters],
    [dynamicInputParameters, integrationAction?.inputParameters]
  );

  const { inputValues } = useInputValuesContext();
  const { data: executionStepResponse } = useGetIntegrationActionStepResponse({
    integrationId,
    actionId,
    stepId: testType === RunStepType.REQUEST ? selectedRequestId : selectedFunctionId,
  });

  const selectedStep = useMemo(() => {
    if (testType === RunStepType.REQUEST) {
      return integrationAction?.httpRequestConfigurations?.find((r) => r.id === selectedRequestId);
    } else {
      return integrationAction?.functions?.find((r) => r.id === selectedFunctionId);
    }
  }, [
    integrationAction?.functions,
    integrationAction?.httpRequestConfigurations,
    selectedFunctionId,
    selectedRequestId,
    testType,
  ]);

  const { mutate: runIntegrationActionStep, isLoading } = useRunIntegrationActionStep();

  const { formId } = useFormIdentifierContext();

  const handleRequestTest = async () => {
    assert(!!selectedStep?.id, new Error(`[useResponseResult] id of step can not be undefined to test`), 'ERROR');
    const step: RunStep = { id: selectedStep?.id, type: testType };

    if (step) {
      const executionContext = {
        ...enrichedContextFromRequestExecutions,
      };

      runIntegrationActionStep({
        actionId,
        integrationId,
        formId,
        step,
        context: executionContext,
        inputParameters: formatAndFilterInputFormValuesAsParameterMappings(
          inputValues,
          allInputParameters?.map((i) => i.id)
        ),
        draft: integrationAction?.state === ActionState.DRAFT,
        ...(selectedConnection && selectedStep?.type === 'http-request' && Boolean(selectedStep?.useConnectionSchema)
          ? { connectionId: selectedConnection.id }
          : {}),
        versionNumber: integrationAction?.version.number ?? 1,
      });
    }
  };

  const executionResponseBody = useMemo(() => {
    if (executionStepResponse?.context) {
      if ('context' in executionStepResponse.context) {
        return executionStepResponse?.context;
      } else {
        const currentRequestResponse = selectedStep && executionStepResponse?.context[selectedStep.name];
        return currentRequestResponse;
      }
    } else {
      return;
    }
  }, [executionStepResponse?.context, selectedStep]);

  const responseContent = useMemo(() => {
    if (executionResponseBody) {
      return <OutputItem content={executionResponseBody} />;
    }
    return;
  }, [executionResponseBody]);

  const responseError = useMemo(
    () =>
      executionStepResponse?.errorContext && {
        title: executionStepResponse?.errorContext.errorCode,
        reason: executionStepResponse?.errorContext.message,
      },
    [executionStepResponse?.errorContext]
  );

  return {
    responseContent,
    executionResponse: executionStepResponse,
    handleRequestTest,
    isLoading,
    responseError,
  };
};
