import { NvFlex } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC, PropsWithChildren } from 'react';
import { PropertyPanelEmptySection, PropertyPanelSimpleSection } from '../../../../../../../../../../../components';
import { ActionOptionProducerItem } from '../action-option-producer-item';
import { FormTriggerDynamicDataSourceMapperProps } from './types';

export const FormTriggerDynamicDataSourceMapper: FC<PropsWithChildren<FormTriggerDynamicDataSourceMapperProps>> = ({
  selectedIndex,
  setSelectedIndex,
  optionProducers,
}) => {
  return !optionProducers || optionProducers.length === 0 ? (
    <PropertyPanelEmptySection emptyText="This field doesn’t have any source mapping yet." />
  ) : (
    <PropertyPanelSimpleSection>
      <NvFlex gap="12px">
        {optionProducers.map((optionProducer, index) => {
          assert(
            optionProducer.type === 'action',
            new Error('Form trigger only supports action option producer'),
            'ERROR'
          );
          return (
            <ActionOptionProducerItem
              key={`action-option-producer-item-${index}`}
              optionProducer={optionProducer}
              index={index}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          );
        })}
      </NvFlex>
    </PropertyPanelSimpleSection>
  );
};
