import { NvAxios, useQuery } from '@novaera/core';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAllContributorsResponse } from './types';

const getAllContributors = async () => {
  const result = await NvAxios.get<GetAllContributorsResponse>(`${APP_DIRECTORY_ROOT_PATH}/contributors`);
  return result?.data;
};

export const useGetAllContributors = () =>
  useQuery<GetAllContributorsResponse>(QUERY_KEYS_APP_DIRECTORY.contributors, () => getAllContributors());
