import React, { isValidElement } from 'react';
import { NvManageSearchIcon } from '../icons';
import { NvTypography } from '../typography';
import { SearchEmptyStateCard } from './styled';
import { NvSearchEmptyStateProps } from './types';

export const NvSearchEmptyState: React.FC<React.PropsWithChildren<NvSearchEmptyStateProps>> = ({ text }) => (
  <SearchEmptyStateCard>
    <NvManageSearchIcon sx={{ width: '16px', height: '16px' }} />
    {isValidElement(text) ? (
      text
    ) : (
      <NvTypography variant="body3" textColor="subtle" padding="1px 0">
        {text}
      </NvTypography>
    )}
  </SearchEmptyStateCard>
);
