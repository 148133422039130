import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';

import { PARENT_PROPERTY_PANEL_DRAWER_ID } from '../common/row-item-action-selection/constants';
import { NodeProperties } from './node-properties';
import { ActionerPutFileNodePropertyPanelDrawerProps } from './types';

export const ActionerPutFileNodePropertyPanelDrawer: React.FC<ActionerPutFileNodePropertyPanelDrawerProps> = ({
  handleClose,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[ActionerPutFileNodePropertyPanelDrawer] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const { userAppId: appId, workflowId } = useParams();

  const { node, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.file>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const {
    panelProps: [first],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps} id={PARENT_PROPERTY_PANEL_DRAWER_ID} data-property-pane-parent="true">
      <PPDrawerItem {...first} data-property-pane-index="1">
        {isLoading ? <PropertiesLoading /> : <NodeProperties node={node} onCloseClicked={handleClose} />}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
