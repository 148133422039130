import { USER_PERMISSION } from '@novaera/actioner-service';
import { useMemo } from 'react';
import { useUserPermissions } from '../../../user-permission-boundary/use-user-permission';

export const useCatalogPermission = () => {
  const { checkPermission, isLoading } = useUserPermissions();

  const hasCatalogEditPermission = useMemo(() => {
    return isLoading ? false : checkPermission(USER_PERMISSION.CATALOG_EDIT);
  }, [isLoading, checkPermission]);

  return {
    hasCatalogEditPermission,
  };
};
