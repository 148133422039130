import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RelationshipCard = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 8px;
  ${({ theme }) => theme.elevations.e100};
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  width: 100%;
`;
