export const INTEGRATION_ACTION_ROOT_PATH = (integrationId: string) => `/v1/integrations/${integrationId}/actions`;

export const INTEGRATION_ACTION_NAME_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/name`;

export const INTEGRATION_ACTION_DESCRIPTION_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/description`;

export const INTEGRATION_ACTION_INPUT_PARAMETERS_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/input-parameters`;

export const INTEGRATION_ACTION_REQUEST_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/http-request-configurations`;

export const INTEGRATION_ACTION_FUNCTIONS_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/functions`;

export const INTEGRATION_ACTION_RUN_CONFIGURATION_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/run-configuration`;

export const INTEGRATION_ACTION_METADATA_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/metadata`;

export const INTEGRATION_ACTION_RESPONSE_CONFIGURATION_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/response-configuration`;

export const INTEGRATION_ACTION_RESPONSE_PERSIST_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/persist`;

export const INTEGRATION_ACTION_RESPONSE_ROLLBACK_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/rollback`;
