import { MediaFileImage, MediaFileVideo } from '@novaera/actioner-service';

export interface AddMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  onModalSubmit: (value: MediaFileImage | MediaFileVideo) => void;
}

export interface AddMediaModalFormValues {
  image: MediaFileImage;
  video: MediaFileVideo;
}

export enum AddMediaModalType {
  IMAGE = 'image',
  YOUTUBE_VIDEO = 'youtube-video',
}
