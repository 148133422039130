import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCheckIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8046 6.73354C16.197 6.33136 16.8437 6.33137 17.2361 6.73354L18.3186 7.84297C18.6976 8.23141 18.6976 8.85127 18.3186 9.23971L11.4623 16.2665C11.0699 16.6686 10.4233 16.6686 10.0309 16.2665L6.68142 12.8337C6.30241 12.4453 6.30241 11.8254 6.68142 11.437L7.76393 10.3275C8.15634 9.92536 8.80299 9.92536 9.1954 10.3275L10.7466 11.9173L15.8046 6.73354Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
