import { IntegrationAction, ParameterMappings, ParameterTypes } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';

export const getWorkflowId = (selectedAction?: IntegrationAction, params?: ParameterMappings) => {
  let workflowId;
  const parameterId = selectedAction?.inputParameters?.find((p) => p.name === 'workflowId')?.id;
  const parameter = params?.find((parameter) => parameter.parameterId === parameterId);
  if (parameter?.type === ParameterTypes.SIMPLE) {
    assert(typeof parameter.value?.value === 'string', new Error('WorkflowId should be a string'), 'ERROR');

    workflowId = parameter.value.value;
  }

  return workflowId;
};
