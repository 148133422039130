import { NodeExecutionSortOrder } from './types';

export const QUERY_KEYS_WORKFLOW_HISTORIES = {
  all: ['workflow-histories'] as const,
  list: ({
    appId,
    workflowId,
    ...params
  }: {
    appId?: string;
    workflowId?: string;
    'time-window'?: string;
    statuses?: string[];
  }) => {
    const timeWindow = params['time-window'];
    const statuses = params['statuses'];

    return [
      ...QUERY_KEYS_WORKFLOW_HISTORIES.all,
      appId,
      workflowId,
      'list',
      ...(timeWindow ? [`time-window=${timeWindow}`] : []),
      ...(statuses && statuses.length > 0 ? [`statuses=${statuses.join('-')}`] : []),
    ] as const;
  },
  detail: ({
    appId,
    workflowId,
    workflowExecutionId,
  }: {
    appId?: string;
    workflowId?: string;
    workflowExecutionId?: string;
  }) => [...QUERY_KEYS_WORKFLOW_HISTORIES.list({ appId, workflowId }), workflowExecutionId, 'detail'] as const,
  node_executions: ({
    appId,
    workflowId,
    workflowExecutionId,
    ...params
  }: {
    appId?: string;
    workflowId?: string;
    workflowExecutionId?: string;
    'sort-order'?: NodeExecutionSortOrder;
    'node-alias'?: string;
  }) => {
    const sortOrder = params['sort-order'];
    const nodeAlias = params['node-alias'];
    return [
      ...QUERY_KEYS_WORKFLOW_HISTORIES.detail({ appId, workflowId, workflowExecutionId }),
      'node-executions',
      ...(sortOrder ? [`sort-order=${sortOrder}`] : []),
      ...(nodeAlias ? [`node-alias=${nodeAlias}`] : []),
    ] as const;
  },

  node_execution: ({
    appId,
    workflowId,
    workflowExecutionId,
    nodeExecutionKey,
  }: {
    appId?: string;
    workflowId?: string;
    workflowExecutionId?: string;
    nodeExecutionKey: string | null;
  }) =>
    [
      ...QUERY_KEYS_WORKFLOW_HISTORIES.detail({ appId, workflowId, workflowExecutionId }),
      nodeExecutionKey,
      'node-execution',
    ] as const,
};
