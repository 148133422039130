import { NvInlineEdit } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const HeaderAndLogoInlineEdit = styled(NvInlineEdit)`
  width: auto;
  margin-top: -8px;
  &.edit-mode {
    width: 100%;
    flex: 1 1 auto;
    .edit-component-wrapper {
      border-radius: 6px;
      border: 1px solid ${({ theme: { palette } }) => palette.nv_neutral_alpha[40]};
      padding: 16px;
      background-color: ${({ theme: { palette } }) => palette.nv_neutral[10]};
    }
  }

  &.view-mode {
    .view-wrapper {
      gap: 8px;
      align-items: center;
    }
  }
` as typeof NvInlineEdit;
