import { StringValue, ValueTypes } from '@novaera/actioner-service';
import { CodeInput, Context, NvFlex, NvMenuWithItems, NvSettingsIcon, NvSideGroupedInputLayout } from '@novaera/core';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { ParameterMappingSettingsMenu } from '../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { RelationshipsAutocomplete } from './relationships-autocomplete';

export const RelationshipSideGroupedComponent: React.FC<{
  value?: StringValue;
  onChange: (v: StringValue) => void;
  firstEntityTypeId?: string;
  context: Context;
}> = ({ value, onChange, context, firstEntityTypeId }) => {
  const [isScripted, setIsScripted] = useState(
    () => value?.type === ValueTypes.STRING && isUndefined(value?.displayValue)
  );
  return (
    <NvSideGroupedInputLayout
      variant={'default'}
      rightAction={
        <NvMenuWithItems
          triggerButton={{
            content: <NvSettingsIcon />,
            props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
          }}
          menuItems={
            <ParameterMappingSettingsMenu
              hiddenConfig={{
                show: false,
              }}
              dynamicStaticConfig={{
                show: true,
                isScripted,
                onItemClicked: (type) => {
                  const newScriptedValue = type === 'static' ? false : true;
                  if (isScripted !== newScriptedValue) {
                    setIsScripted(newScriptedValue);

                    if (newScriptedValue) {
                      onChange({
                        type: ValueTypes.STRING,
                        value: '',
                      });
                    } else {
                      onChange({
                        type: ValueTypes.STRING,
                        value: '',
                        displayValue: '',
                      });
                    }
                  }
                },
              }}
            />
          }
        />
      }
      formItem={
        isScripted ? (
          <CodeInput
            value={value?.value}
            onChange={(value) => {
              onChange({
                type: ValueTypes.STRING,
                value: value,
              });
            }}
            context={context}
            placeholder={'{{...}}'}
          />
        ) : (
          <NvFlex width="100%">
            <RelationshipsAutocomplete value={value} onChange={onChange} firstEntityTypeId={firstEntityTypeId} />
          </NvFlex>
        )
      }
    />
  );
};
