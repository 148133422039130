import React from 'react';
import {
  DEFAULT_CONFIRM_DIALOG_CANCEL_BUTTON_LABEL,
  DEFAULT_CONFIRM_DIALOG_CONFIRM_BUTTON_LABEL,
  DEFAULT_CONFIRM_DIALOG_MESSAGE,
  DEFAULT_CONFIRM_DIALOG_TITLE,
} from './constants';
import { ConfirmDialogProps } from './types';
import { NvButton } from '../button';
import { NvDialogSlots, NvDialogV1 } from '../dialog';
import { NvCloseIcon, NvWarningAmberRounded } from '../icons';
import { NvTypography } from '../typography';
import { NvFlex } from '../flex';
import { isString } from 'lodash';

export const NvConfirmDialog: React.FC<React.PropsWithChildren<ConfirmDialogProps>> = ({
  isOpen,
  cancelButtonLabel = DEFAULT_CONFIRM_DIALOG_CANCEL_BUTTON_LABEL,
  onCancel,
  confirmButtonLabel = DEFAULT_CONFIRM_DIALOG_CONFIRM_BUTTON_LABEL,
  onConfirm,
  title = DEFAULT_CONFIRM_DIALOG_TITLE,
  message = DEFAULT_CONFIRM_DIALOG_MESSAGE,
  confirmButton = (
    <NvButton
      variant="contained"
      size="medium"
      color="error"
      onClick={() => {
        onConfirm();
        onCancel();
      }}
    >
      {confirmButtonLabel}
    </NvButton>
  ),
  icon = <NvWarningAmberRounded />,
}) => {
  return (
    <NvDialogV1 open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <NvDialogSlots>
        <NvDialogSlots.Slot name="icon">{icon}</NvDialogSlots.Slot>
        <NvDialogSlots.Slot name="title">
          <NvTypography variant="h2">{title}</NvTypography>
        </NvDialogSlots.Slot>
        <NvDialogSlots.Slot name="content">
          {isString(message) ? <NvTypography variant="body1">{message}</NvTypography> : message}
        </NvDialogSlots.Slot>
        <NvDialogSlots.Slot name="actions">
          <NvFlex spacing="8px" direction="row">
            {confirmButton}
            <NvButton variant="contained" size="medium" color="ghost" onClick={onCancel}>
              {cancelButtonLabel}
            </NvButton>
          </NvFlex>
        </NvDialogSlots.Slot>
        <NvDialogSlots.Slot name="closeButton">
          <NvButton variant="contained" color="secondary" size="small" onlyIcon onClick={onCancel}>
            <NvCloseIcon fontSize="medium" />
          </NvButton>
        </NvDialogSlots.Slot>
      </NvDialogSlots>
    </NvDialogV1>
  );
};
