import { SelectChangeEvent } from '@mui/material';
import { LoopNode, useUpdateNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';

import { cloneDeep } from 'lodash';
import { ReactNode, useRef, useState } from 'react';
import { useWorkflowPermission } from '../../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../../../provider';
import { OPERATIONS, OPERATIONS_VALUE } from '../../constants';
import { LoopNodeFormType } from '../../types';

export const useLoopPanelController = ({ node }: { node: LoopNode }) => {
  const { userAppId: appId, workflowId } = useParams();
  const [showBreakLoops, setShowBreakLoops] = useState<boolean>(node.breakCondition !== undefined);
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const { updateNode: updateNodeInGraph } = useNovaeraFlow(userAppGraph);

  const [operation, setOperation] = useState<OPERATIONS_VALUE>(
    node.arrayExpression ? OPERATIONS[1].value : OPERATIONS[0].value
  );

  const { mutate: updateNode } = useUpdateNode();

  const { hasEditPermission } = useWorkflowPermission();

  const formRef = useRef(null);
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const handleSave = (node: LoopNode, values: LoopNodeFormType) => {
    if (!hasEditPermission) return;

    const newNode: LoopNode = {
      ...node,
      ...values,
    };

    updateNode(
      { appId, workflowId, nodeAlias: node.alias, payload: newNode },
      {
        onSuccess: () => {
          const graphNode = userAppGraph.node(node.alias);
          const newGraphNode = cloneDeep(graphNode);
          newGraphNode.name = values.name;
          updateNodeInGraph({ newNode: newGraphNode });
        },
      }
    );
  };

  const handleOperationChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
    const value = e.target.value as OPERATIONS_VALUE;
    setOperation(value);

    if (value === OPERATIONS_VALUE.INFINITE) {
      (formRef?.current as unknown as HTMLFormElement)?.change('arrayExpression', '');
    }
  };

  return {
    onSave: handleSave,
    showBreakLoops,
    setShowBreakLoops,
    operation,
    showArrayExpression: operation === OPERATIONS_VALUE.ARRAY_ITERATION,
    onOperationChange: handleOperationChange,
    formRef,
    deleteNodeAndUpdateGraph,
    workflowCodeInputContext,
  };
};
