import { SpendingLimitNotificationRule, useUpdateBillingPreferencesNotificationRules } from '@novaera/actioner-service';
import { NvDivider, NvFlex, NvSwitch, NvTypography } from '@novaera/core';
import { useMemo, useState } from 'react';
import { SpendingLimitsProps } from './types';
import { UpdateSpendingLimit } from './update-spending-limits';

export const SpendingLimits: React.FC<SpendingLimitsProps> = ({ initialBillingPreferences }) => {
  const [notificationRules, setNotificationRules] = useState<SpendingLimitNotificationRule[]>(
    initialBillingPreferences.notificationRules
  );

  const {
    mutate: updateBillingPreferencesNotificationRules,
    isLoading: isUpdatingBillingPreferencesNotificationRules,
  } = useUpdateBillingPreferencesNotificationRules();

  const isEmailAlertsEnabled = useMemo(() => notificationRules.every((rule) => rule.enabled), [notificationRules]);

  return (
    <NvFlex gap="16px">
      <UpdateSpendingLimit initialBillingPreferences={initialBillingPreferences} />
      <NvDivider />
      <NvFlex gap="8px">
        <NvFlex direction="row" alignItems="center" gap="8px">
          <NvSwitch
            checked={isEmailAlertsEnabled}
            disabled={isUpdatingBillingPreferencesNotificationRules}
            onChange={(e, checked) => {
              const updatedNotificationRules = notificationRules.map((rule) => ({ ...rule, enabled: checked }));
              setNotificationRules(updatedNotificationRules);
              updateBillingPreferencesNotificationRules({ notificationRules: updatedNotificationRules });
            }}
          />
          <NvTypography variant="h4">Email alerts</NvTypography>
        </NvFlex>
        <NvTypography variant="body2">
          Receive email notifications when default and extra credit usage limits reach <b>75%</b>, <b>90%</b> and{' '}
          <b>100%</b> thresholds.
        </NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
