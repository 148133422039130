import { NvAxios, useMutation } from '@novaera/core';
import { STRIPE_SESSION_ROOT_PATH } from '../constants';
import { SessionResponse } from './types';

const createCustomerPortalSession = async () => {
  const result = await NvAxios.post<SessionResponse>(`${STRIPE_SESSION_ROOT_PATH}/customer-portal`);
  return result?.data;
};

export const useCreateCustomerPortalSession = () => {
  return useMutation(createCustomerPortalSession, {});
};
