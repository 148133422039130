import { useCheckConnectionValidationService } from '@novaera/actioner-service';
import { generatePath, useNavigate } from '@novaera/route';
import { useCallback, useEffect } from 'react';
import { ROUTES } from '../../../../../../../../common/routes';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';

export const useCheckConnections = ({
  isDraft,
  redirectIfNoMissingConnections = false,
}: {
  isDraft: boolean;
  redirectIfNoMissingConnections?: boolean;
}) => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { mutateAsync, mutate, isLoading, data, isSuccess, isError } = useCheckConnectionValidationService();
  const navigate = useNavigate();

  useEffect(() => {
    // instead of showing an empty state, it is set to redirect to the user app page
    // according to feedbacks, it may be changed in the future
    if (redirectIfNoMissingConnections && isSuccess && data?.invalidNodeConnections.length === 0) {
      navigate(`${generatePath(ROUTES.UserApps)}/${userAppId}`);
    }
  }, [data, isError, isLoading, isSuccess, navigate, redirectIfNoMissingConnections, userAppId]);

  const handleCheckConnections = async () => {
    try {
      const result = await mutateAsync({
        appId: userAppId,
        workflowId,
        isDraft,
      });
      return result;
    } catch (error) {
      return;
    }
  };

  const handleGetConnectionByMutate = useCallback(
    (isDraft?: boolean) => {
      mutate({
        appId: userAppId,
        workflowId,
        isDraft,
      });
    },
    [mutate, userAppId, workflowId]
  );

  return {
    checkConnections: handleCheckConnections,
    isLoading,
    data,
    getConnectionsByMutate: handleGetConnectionByMutate,
  };
};
