import { ConnectionValidationSchema } from '@novaera/actioner-service';
import { NvButton, NvFlex, NvForm, NvOpenInNewIcon } from '@novaera/core';
import { noop } from 'lodash';
import { ConnectModalBody } from '../../connect-modal/body';
import { useConnectModalController } from '../../connect-modal/controllers/use-connect-modal';

export const ConnectForm = ({
  schema,
  onConnectCancelClicked,
  onFormSubmitCompleted,
}: {
  schema: ConnectionValidationSchema;
  onConnectCancelClicked?: () => void;
  onFormSubmitCompleted?: () => void;
}) => {
  const {
    connectionSchema,
    isConnectionSchemaLoading,
    response,
    oauthPromiseResult,
    isLoading: isConnectModalLoading,
    onFormSubmit,
    hideConnectButton,
  } = useConnectModalController({
    mode: 'Connect',
    connected: false,
    integrationId: schema.integrationId,
    initialSchemaId: schema.connectionSchemaId,
  });
  return (
    <NvForm onSubmit={noop} key={`connect-form-${schema.connectionSchemaId}`}>
      {(formProps) => (
        <NvFlex gap={'16px'}>
          <ConnectModalBody
            mode={'Connect'}
            connected={false}
            formSubmitResult={oauthPromiseResult}
            isLoading={isConnectModalLoading}
            response={response}
            connectionSchema={connectionSchema}
            isConnectionSchemaLoading={isConnectionSchemaLoading}
          />
          <NvFlex direction={'row'} gap={'8px'}>
            {!hideConnectButton && (
              <NvButton
                size="small"
                loading={isConnectModalLoading}
                onClick={async () => {
                  try {
                    await onFormSubmit(formProps.values);
                    onConnectCancelClicked?.();
                    onFormSubmitCompleted?.();
                  } catch (error) {
                    console.log(error);
                  }
                }}
                {...(connectionSchema?.slack ? { endIcon: <NvOpenInNewIcon /> } : {})}
              >
                Connect
              </NvButton>
            )}

            {onConnectCancelClicked && (
              <NvButton size="small" color="ghost" onClick={onConnectCancelClicked}>
                Cancel
              </NvButton>
            )}
          </NvFlex>
        </NvFlex>
      )}
    </NvForm>
  );
};
