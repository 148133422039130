import { ProductId, useImpersonateUser } from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { useNavigate, useQueryParams } from '@novaera/route';
import { setAccessToken } from '@novaera/service';

import { useEffect } from 'react';

export const useImpersonate = ({ productId }: { productId: ProductId }) => {
  const { mutateAsync, isError, isLoading } = useImpersonateUser();
  const { getSearchParams } = useQueryParams();
  const params = getSearchParams<{ token: string }>();
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    getAccessToken();
  }, [params?.token]);

  useEffect(() => {
    if (isError) {
      addToast('An error occurred while impersonating user', { variant: 'error' });
    }
  }, [isError]);

  const getAccessToken = async () => {
    if (params?.token) {
      try {
        const { accessToken } = await mutateAsync({
          productId,
          token: params.token,
        });
        setAccessToken(accessToken);
        navigate('/');
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  };

  return {
    isLoading,
    isError,
  };
};
