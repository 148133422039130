import { NvFlex, NvTooltip } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

import { tooltipClasses } from '@mui/material/Tooltip';

export const AppDirectoryCardTags = styled(NvFlex)`
  flex-direction: row;
  gap: 8px;
  flex: 0 0 auto;
`;

export const MoreTagsTooltip = styled(NvTooltip)`
  .${tooltipClasses.tooltip} {
    padding: 12px;
    min-width: 150px;
    border-radius: 12px;
    background: ${({ theme }) => theme.palette.nv_neutral[0]};
    ${({ theme }) => theme.elevations.e300}
  }
`;
