import { Initial, ProfileImageProps } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { StyledNvImage } from './styled';

export const LogoPlaceholder = ({
  src,
  initialText,
  ...props
}: Pick<ProfileImageProps, 'size'> & { src: string; initialText: string }) => {
  const theme = useTheme();
  return (
    <StyledNvImage
      FallBack={<Initial size="small" value={initialText} color={theme.palette.nv_neutral[500]} />}
      src={src}
      imageShape="square"
      {...props}
    />
  );
};
