import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { GroupResponse, UseCreateGroupParams, UseGetGroupsResponse } from '../types';

const createGroup: (params: UseCreateGroupParams) => Promise<GroupResponse> = async (params) => {
  const result = await NvAxios.post<GroupResponse>(GROUPS_ROOT_PATH, params);
  return result?.data;
};

export const useCreateGroup = () => {
  const cache = useQueryClient();

  return useMutation(createGroup, {
    onSuccess: (response) => {
      cache.setQueryData<UseGetGroupsResponse | undefined>(QUERY_KEYS_GROUPS.list(), (old) => {
        if (old) {
          return { groups: [{ ...response, totalUsers: 0 }, ...old.groups] };
        }
        return old;
      });
    },
  });
};
