import { NvCssBaseline } from '@novaera/core';
import classnames from 'classnames';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../footer';

import { ContentCard, ContentWrapper, LayoutWrapper } from './styled';

export const Layout: React.FC<
  React.PropsWithChildren<{ hasCardWrapper?: boolean; isLayoutCentered?: boolean; footerTopSlot?: React.ReactElement }>
> = ({ hasCardWrapper, isLayoutCentered, footerTopSlot }) => {
  return (
    <>
      <NvCssBaseline />
      <LayoutWrapper>
        <ContentWrapper
          className={classnames({
            'center-content': isLayoutCentered,
          })}
        >
          {hasCardWrapper && (
            <ContentCard>
              <Outlet />
            </ContentCard>
          )}
          {!hasCardWrapper && <Outlet />}
        </ContentWrapper>
        <Footer topSlot={footerTopSlot} />
      </LayoutWrapper>
    </>
  );
};
