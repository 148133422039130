import { blue } from '@mui/material/colors';
import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Winter, ThemeMode.Light);

export default {
  primary: {
    main: '#ce28db',
  },
  secondary: {
    main: '#5c2878',
  },
  error: {
    main: blue.A700,
  },
  test: {
    value: 'testWinterLight',
  },
  ...colors,
};
