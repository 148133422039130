import { Contributor as ContributorType } from '@novaera/actioner-service';
import { NvBox, NvFlex } from '@novaera/core';
import { Contributor } from './contributor';
import { ContributorAvatar } from './contributor/styled';
import { ContributorAvatarGroup, MoreContributorsTooltip } from './styled';
import { ContributorsProps } from './types';

const MoreContributors: React.FC<React.PropsWithChildren<{ contributors: ContributorType[] }>> = ({ contributors }) => (
  <NvFlex width="100%">
    {contributors.map((contributor) => (
      <NvBox display="flex" padding="0 4px" width="100%" height="32px" alignItems="center">
        <Contributor key={contributor.id} contributor={contributor} />
      </NvBox>
    ))}
  </NvFlex>
);

export const Contributors: React.FC<React.PropsWithChildren<ContributorsProps>> = ({ contributors }) => (
  <NvFlex alignItems="flex-start">
    {contributors.length === 1 ? (
      <Contributor contributor={contributors[0]} />
    ) : (
      <MoreContributorsTooltip title={<MoreContributors contributors={contributors} />} placement="bottom-start">
        <ContributorAvatarGroup max={3} sx={{ cursor: 'pointer', flex: '1 1 auto', justifyContent: 'flex-end' }}>
          {contributors.map((contributor) => (
            <ContributorAvatar key={contributor.id} alt={contributor.name} src={contributor.logoUrl} />
          ))}
        </ContributorAvatarGroup>
      </MoreContributorsTooltip>
    )}
  </NvFlex>
);
