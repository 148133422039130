import { ConnectionSchema } from '@novaera/actioner-service';
import { ImageSize, NvImage } from '@novaera/core';
import { LogoPlaceholder } from '../logo-placeholder';

export const ConnectionSchemaLogo = ({
  connectionSchema,
  logoUrl,
  size,
}: {
  connectionSchema?: ConnectionSchema;
  logoUrl?: string;
  size?: ImageSize;
}) => {
  return (
    <NvImage
      size={size || 'medium'}
      FallBack={
        <LogoPlaceholder
          size={size || 'medium'}
          src="assets/connection-placeholder-image.png"
          initialText="Connection Schema"
        />
      }
      src={logoUrl || connectionSchema?.logoUrl}
      imageShape="square"
      hasNoElevation
    />
  );
};
