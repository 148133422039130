import { useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { isJsonValid } from '@novaera/utils';
import { FC, PropsWithChildren, useMemo } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeStatus } from '../../../../../../components/node/types';
import { NodeType } from '../../../../../../components/property-panel/types';
import { useWorkflowErrorStateProvider } from '../../providers/workflow-error-state-provider';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type ResponseProps = BaseNodeProps;

export const Response: FC<PropsWithChildren<ResponseProps>> = (props) => {
  const { userAppId, workflowId } = useParams();
  const { node } = useGetNode({ workflowId, appId: userAppId, nodeAlias: props.alias });
  const { onErrorStateChanged } = useWorkflowErrorStateProvider();

  const showError = useMemo(() => {
    const showError = Boolean(
      node && node.type === 'response' && node.exampleResponse && !isJsonValid(node.exampleResponse)
    );

    onErrorStateChanged({
      nodeId: props.id,
      showError,
    });

    return showError;
  }, [node, onErrorStateChanged, props.id]);

  return (
    <DraggableItem nodeId={props.id}>
      <Node {...(showError ? { status: NodeStatus.ERROR } : {})} type={NodeType.RESPONSE} {...props} />
    </DraggableItem>
  );
};
