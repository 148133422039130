import { AuthenticationField } from '@novaera/actioner-service';
import { DETAIL_FORM_NAME } from '../../../../../../../../../../../components/detail-layout';
import { useForm } from 'react-final-form';
import { useIntegrationConnectionAuthenticationFieldContext } from '../../../../provider';
import { Action } from '../../../../provider/reducer/types';
import { AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME } from '../../../table-body/constants';

export const useFieldItemController = () => {
  const { dispatch } = useIntegrationConnectionAuthenticationFieldContext();

  const { mutators } = useForm(DETAIL_FORM_NAME);

  const handleEditClick = (authenticationField: AuthenticationField, rowIndex: number) => {
    dispatch({
      type: Action.UpdateAuthenticationField,
      payload: {
        authenticationField,
        rowIndex,
      },
    });
  };

  const handleRemoveClick = (rowIndex: number) => {
    mutators.remove(AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME, rowIndex);
  };

  return {
    onEditClicked: handleEditClick,
    onRemoveClicked: handleRemoveClick,
  };
};
