import { NvAxios, useQuery } from '@novaera/core';
import { isAxiosError } from 'axios';
import { SimpleParameterMapping } from '../../types';
import { WORKFLOW_RUN_FROM_LINK_PATH } from '../constants';
import { QUERY_KEY_WORKFLOW_RUN_FROM_LINK } from '../keys';

type GetWorkflowFromLinkParams = {
  formContextId: string;
};

type GetWorkflowFromLinkResponse = {
  appId: string;
  workflowId: string;
  calculatedParameterMappings: SimpleParameterMapping[];
  formId: string;
  executeImmediately: boolean;
};

const getFormParameters = async ({ formContextId }: GetWorkflowFromLinkParams) => {
  const result = await NvAxios.get<GetWorkflowFromLinkResponse>(`${WORKFLOW_RUN_FROM_LINK_PATH}/${formContextId}`);

  return result?.data;
};

export const useGetWorkflowFromLink = (param: GetWorkflowFromLinkParams) => {
  return useQuery<GetWorkflowFromLinkResponse>(
    QUERY_KEY_WORKFLOW_RUN_FROM_LINK.detail({ formContextId: param.formContextId }),
    () => getFormParameters(param),
    {
      enabled: !!param.formContextId,
      keepPreviousData: true,
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
    }
  );
};
