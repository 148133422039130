import { BaseDataGrid } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecordDataGrid = styled(BaseDataGrid)`
  &.MuiDataGrid-root {
    background: ${({ theme }) => theme.palette.nv_neutral[0]};
    border: none;
    border-radius: 0;
    margin-right: 36px;
    border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
    width: 100%;

    .MuiDataGrid-main {
      height: 100%;
      .MuiDataGrid-columnHeaders {
        background: ${({ theme }) => theme.palette.nv_neutral[10]};

        .MuiDataGrid-columnHeadersInner {
          .MuiDataGrid-columnHeader {
            padding: 0 8px;
            height: 32px;
            border-left: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};

            .MuiDataGrid-columnHeaderTitleContainer {
              padding: 0;
            }

            .MuiDataGrid-columnSeparator {
              display: none;
            }

            .MuiDataGrid-menuIcon {
              display: none;
            }

            .MuiDataGrid-iconButtonContainer {
              .MuiButtonBase-root {
                .MuiTouchRipple-root {
                  display: none;
                }

                &:hover {
                  background: transparent;
                }
              }
            }

            &.stick-to-right {
              position: absolute;
              background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
            }

            &:first-of-type {
              border-left: 1px solid transparent;
            }

            &:focus,
            &:focus-within {
              outline: none;
            }
          }
        }
      }

      .MuiDataGrid-virtualScroller {
        .MuiDataGrid-virtualScrollerContent {
          overflow: unset;
        }
      }

      .MuiDataGrid-cell {
        padding: 0 8px;
        border-left: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
        border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};

        &:first-of-type {
          border-left: 1px solid transparent;
        }

        &.stick-to-right {
          transition: background-color 200ms ease;
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
          position: sticky;
          right: 0;
          padding: 0 6px;
          z-index: 10;
        }

        &:focus,
        &:focus-within {
          outline: none;
        }
      }

      .MuiDataGrid-row {
        transition: background-color 200ms ease;

        &.Mui-selected {
          background-color: ${({ theme }) => theme.palette.nv_main[10]};

          .stick-to-right {
            background-color: ${({ theme }) => theme.palette.nv_main[10]};
          }

          .MuiDataGrid-cell {
            .open-in-new-tab {
              background: linear-gradient(
                90deg,
                rgba(249, 251, 255, 0) 0,
                ${({ theme }) => theme.palette.nv_main[10]} 8px,
                ${({ theme }) => theme.palette.nv_main[10]} 100%
              );
            }
          }

          &:hover {
            background-color: ${({ theme }) => theme.palette.nv_main[10]};

            .MuiDataGrid-cell {
              .open-in-new-tab {
                background: linear-gradient(
                  90deg,
                  rgba(249, 251, 255, 0) 0,
                  ${({ theme }) => theme.palette.nv_main[10]} 8px,
                  ${({ theme }) => theme.palette.nv_main[10]} 100%
                );
              }
            }

            .stick-to-right {
              background-color: ${({ theme }) => theme.palette.nv_main[10]};
            }
          }
        }

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral[10]};

          .stick-to-right {
            background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
          }
        }
      }
    }

    .MuiDataGrid-footerContainer {
      background: ${({ theme }) => theme.palette.nv_neutral[0]};
      box-shadow: 0px 0px 0px 1px rgba(10, 45, 98, 0.04), 0px -2px 6px rgba(10, 45, 98, 0.06);
      border-top: none;
      min-height: 40px;
      justify-content: center;

      .MuiPagination-ul {
        li {
          margin: 0 2px;

          &:first-of-type {
            margin: 0 2px 0 0;
          }
          &:last-of-type {
            margin: 0 0 0 2px;
          }
        }
      }
    }
  }
`;
