import { NvField, NvTextField, isRequired } from '@novaera/core';

export const CloneBody = () => {
  return (
    <NvField
      hasRequiredIndicator
      isRequired
      direction="label-on-top"
      validators={[isRequired()]}
      name="newName"
      labelText="Name"
      component={<NvTextField />}
    />
  );
};
