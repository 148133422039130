import { UserWorkflowExecutionResultResponse, WorkflowWithState } from '@novaera/actioner-service';
import { useWorkflowOutputResult } from './controllers/use-workflow-output-result';
import { WorkflowOutputs } from './workflow-outputs';
import { WorkflowStateResult } from './workflow-state-result';

export const WorkflowRunPanelResult = ({
  result,
  isLoading,
  executionIdentifier,
  workflow,
}: {
  result?: UserWorkflowExecutionResultResponse | null;
  isLoading: boolean;
  executionIdentifier?: string;
  workflow?: WorkflowWithState;
}) => {
  const { setWorkflowStateResult, executedNodeAliases } = useWorkflowOutputResult();

  return (
    <>
      <WorkflowStateResult
        isLoading={isLoading}
        result={result}
        onWorkflowStateGet={(result) => {
          setWorkflowStateResult(result);
        }}
        executionIdentifier={executionIdentifier}
        workflow={workflow}
      />
      {!isLoading && executionIdentifier && executedNodeAliases && (
        <WorkflowOutputs
          workflow={workflow}
          executionIdentifier={executionIdentifier}
          executedNodeAliases={executedNodeAliases}
        />
      )}
    </>
  );
};
