export function NvEventRuleIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M81.1775 102.448C80.9509 102.448 80.7243 102.329 80.6044 102.116C80.4178 101.797 80.5244 101.398 80.8443 101.212L100.742 89.7504C101.062 89.5642 101.462 89.6706 101.649 89.9897C101.835 90.3088 101.729 90.7077 101.409 90.8939L81.5107 102.355C81.404 102.422 81.2841 102.448 81.1775 102.448Z"
        fill="#B1B6C0"
      />
      <path
        d="M78.272 112.979C78.0454 112.979 77.8189 112.859 77.6989 112.646C77.5123 112.327 77.619 111.928 77.9388 111.742L89.2406 105.227C89.5605 105.041 89.9737 105.147 90.1469 105.467C90.3335 105.786 90.2269 106.185 89.907 106.371L78.6052 112.886C78.4986 112.952 78.3786 112.979 78.272 112.979Z"
        fill="#B1B6C0"
      />
      <path
        d="M45.7793 112.726C45.5528 112.726 45.3262 112.607 45.2063 112.394C45.0197 112.075 45.1263 111.676 45.4462 111.49L52.8163 107.248C53.1362 107.062 53.536 107.168 53.7226 107.487C53.9092 107.807 53.8026 108.205 53.4827 108.392L46.1125 112.633C46.0059 112.7 45.886 112.726 45.7793 112.726Z"
        fill="#B1B6C0"
      />
      <path
        d="M89.5738 80.8551C89.3473 80.8551 89.1207 80.7354 89.0007 80.5227C88.8142 80.2036 88.9208 79.8047 89.2406 79.6186L96.6108 75.3771C96.9307 75.1909 97.3305 75.2973 97.5171 75.6164C97.7037 75.9355 97.5971 76.3344 97.2772 76.5205L89.907 80.762C89.8004 80.8285 89.6805 80.8551 89.5738 80.8551Z"
        fill="#B1B6C0"
      />
      <path
        d="M22.8025 105.347C22.576 105.347 22.3494 105.227 22.2294 105.014C22.0429 104.695 22.1495 104.296 22.4693 104.11L29.8395 99.8687C30.1594 99.6826 30.5592 99.7889 30.7458 100.108C30.9324 100.427 30.8258 100.826 30.5059 101.012L23.1357 105.254C23.0291 105.32 22.9092 105.347 22.8025 105.347Z"
        fill="#B1B6C0"
      />
      <path
        d="M17.9113 98.0339C17.6847 98.0339 17.4582 97.9143 17.3382 97.7015C17.1516 97.3824 17.2582 96.9835 17.5781 96.7974L22.0562 94.2179C22.376 94.0318 22.7759 94.1382 22.9625 94.4573C23.149 94.7764 23.0424 95.1753 22.7226 95.3614L18.2445 97.9409C18.1379 98.0074 18.0179 98.0339 17.9113 98.0339Z"
        fill="#B1B6C0"
      />
      <path
        d="M33.3047 108.671C33.0781 108.671 32.8515 108.551 32.7316 108.339C32.545 108.019 32.6516 107.621 32.9715 107.434L49.9242 97.675C50.2441 97.4888 50.6439 97.5952 50.8305 97.9143C51.0171 98.2334 50.9104 98.6323 50.5906 98.8185L33.6379 108.578C33.5312 108.644 33.4113 108.671 33.3047 108.671Z"
        fill="#B1B6C0"
      />
      <path d="M67.0102 45.6868L86.6817 56.9885L91.8395 54.0102L72.008 42.8148L67.0102 45.6868Z" fill="white" />
      <path
        d="M86.6817 57.6533C86.5618 57.6533 86.4552 57.6268 86.3485 57.5603L66.677 46.2585C66.4638 46.1388 66.3438 45.9261 66.3438 45.6868C66.3438 45.4474 66.4638 45.2347 66.677 45.115L71.6749 42.243C71.8748 42.1234 72.128 42.1234 72.3412 42.243L92.186 53.4384C92.3993 53.5581 92.5192 53.7709 92.5192 54.0102C92.5192 54.2495 92.3993 54.4756 92.186 54.5952L87.0283 57.5603C86.9216 57.6135 86.815 57.6533 86.6951 57.6533H86.6817ZM68.343 45.6868L86.6817 56.2174L90.4934 54.0235L71.9947 43.586L68.3296 45.7001L68.343 45.6868Z"
        fill="#455068"
      />
      <path d="M67.0102 45.6868V80.6957L86.6817 91.9974V56.9885L67.0102 45.6868Z" fill="white" />
      <path
        d="M86.6817 92.6623C86.5618 92.6623 86.4552 92.6357 86.3485 92.5692L66.677 81.2674C66.4638 81.1477 66.3438 80.935 66.3438 80.6957V45.6868C66.3438 45.4474 66.4771 45.2347 66.677 45.115C66.8769 44.9954 67.1301 44.9954 67.3434 45.115L87.0149 56.4168C87.2282 56.5365 87.3481 56.7492 87.3481 56.9885V91.9975C87.3481 92.2368 87.2282 92.4495 87.0149 92.5692C86.9083 92.6224 86.8017 92.6623 86.6817 92.6623ZM67.6766 80.3234L86.0154 90.854V57.3741L67.6766 46.8435V80.3234Z"
        fill="#455068"
      />
      <path d="M91.8395 54.0102V89.0191L86.6818 91.9975V56.9885L91.8395 54.0102Z" fill="#455068" />
      <path
        d="M86.6818 92.6623C86.5618 92.6623 86.4552 92.6357 86.3486 92.5692C86.1487 92.4495 86.0154 92.2368 86.0154 91.9975V56.9885C86.0154 56.7492 86.1487 56.5365 86.3486 56.4168L91.5064 53.4517C91.7063 53.3321 91.9728 53.3321 92.1727 53.4517C92.3727 53.5714 92.5059 53.7842 92.5059 54.0235V89.0324C92.5059 89.2717 92.3727 89.4845 92.1727 89.6041L87.015 92.5692C86.9083 92.6224 86.8017 92.6623 86.6818 92.6623ZM87.3481 57.3741V90.854L91.1732 88.6468V55.167L87.3481 57.3741Z"
        fill="#455068"
      />
      <path d="M41.7811 44.8226L71.1151 61.722L76.2729 58.7437L46.7789 41.9373L41.7811 44.8226Z" fill="white" />
      <path
        d="M71.1151 62.3736C70.9951 62.3736 70.8885 62.347 70.7819 62.2805L41.4478 45.381C41.2479 45.2613 41.1146 45.0486 41.1146 44.8093C41.1146 44.5699 41.2479 44.3572 41.4478 44.2375L46.4457 41.3656C46.6456 41.2459 46.8988 41.2459 47.112 41.3656L76.6194 58.172C76.8326 58.2916 76.9526 58.5044 76.9526 58.7437C76.9526 58.983 76.8326 59.1958 76.6194 59.3154L71.4616 62.2805C71.355 62.3337 71.2483 62.3736 71.1284 62.3736H71.1151ZM43.1138 44.8093L71.1151 60.9376L74.9401 58.7437L46.7789 42.7085L43.1138 44.8226V44.8093Z"
        fill="#455068"
      />
      <path d="M41.7811 44.8226V88.474L71.1151 105.36V61.722L41.7811 44.8226Z" fill="white" />
      <path
        d="M71.1151 106.025C70.9951 106.025 70.8885 105.998 70.7819 105.932L41.4478 89.0325C41.2479 88.9128 41.1146 88.7001 41.1146 88.4607V44.8226C41.1146 44.5832 41.2479 44.3705 41.4478 44.2508C41.6477 44.1312 41.9143 44.1312 42.1142 44.2508L71.4482 61.1503C71.6482 61.27 71.7814 61.4827 71.7814 61.7221V105.374C71.7814 105.613 71.6615 105.826 71.4482 105.945C71.3416 105.998 71.235 106.038 71.1151 106.038V106.025ZM42.4474 88.0751L70.4487 104.203V62.0943L42.4474 45.966V88.0884V88.0751Z"
        fill="#455068"
      />
      <path d="M76.2729 58.7437V102.395L71.1151 105.36V61.722L76.2729 58.7437Z" fill="#455068" />
      <path
        d="M71.1151 106.025C70.9952 106.025 70.8885 105.998 70.7819 105.932C70.582 105.812 70.4487 105.6 70.4487 105.36V61.7087C70.4487 61.4694 70.5687 61.2567 70.7819 61.137L75.9397 58.1719C76.1396 58.0523 76.4062 58.0523 76.6061 58.1719C76.806 58.2916 76.9393 58.5044 76.9393 58.7437V102.395C76.9393 102.634 76.806 102.847 76.6061 102.967L71.4483 105.932C71.3417 105.985 71.2351 106.025 71.1151 106.025ZM71.7815 62.0943V104.203L75.6065 101.996V59.8739L71.7815 62.081V62.0943Z"
        fill="#455068"
      />
      <path
        d="M59.9866 52.8134C59.6134 52.8134 59.3202 52.5209 59.3202 52.1486V35.2491C59.3202 34.8768 59.6134 34.5843 59.9866 34.5843C60.3597 34.5843 60.653 34.8768 60.653 35.2491V52.1486C60.653 52.5209 60.3597 52.8134 59.9866 52.8134Z"
        fill="#455068"
      />
      <path
        d="M59.9866 35.874C62.7101 35.874 64.9178 33.6715 64.9178 30.9544C64.9178 28.2374 62.7101 26.0348 59.9866 26.0348C57.2632 26.0348 55.0554 28.2374 55.0554 30.9544C55.0554 33.6715 57.2632 35.874 59.9866 35.874Z"
        fill="white"
      />
      <path
        d="M59.9866 36.5521C56.8946 36.5521 54.389 34.0392 54.389 30.9544C54.389 27.8697 56.908 25.37 59.9866 25.37C63.0653 25.37 65.5976 27.883 65.5976 30.9544C65.5976 34.0259 63.0786 36.5521 59.9866 36.5521ZM59.9866 26.6996C57.6276 26.6996 55.7218 28.6143 55.7218 30.9544C55.7218 33.2946 57.641 35.2225 59.9866 35.2225C62.3323 35.2225 64.2648 33.3079 64.2648 30.9544C64.2648 28.601 62.3456 26.6996 59.9866 26.6996Z"
        fill="#455068"
      />
      <path
        d="M62.1723 26.5667C62.1989 26.806 62.8253 33.1217 58.6271 35.6746C59.0669 35.7943 59.5068 35.8873 59.9866 35.8873C62.7187 35.8873 64.9178 33.6802 64.9178 30.9677C64.9178 29.0398 63.7849 27.3778 62.1723 26.5667Z"
        fill="#455068"
      />
      <path
        d="M59.9865 36.5522C59.5067 36.5522 59.0136 36.4724 58.4405 36.3128C58.1873 36.233 58.0007 36.0203 57.9607 35.7677C57.9208 35.5018 58.054 35.2491 58.2673 35.1162C62.079 32.8026 61.5192 26.8991 61.4926 26.6465C61.4659 26.4071 61.5725 26.1678 61.7724 26.0348C61.9724 25.9019 62.2256 25.8753 62.4521 25.9817C64.3713 26.939 65.5708 28.8536 65.5708 30.9677C65.5708 34.0525 63.0519 36.5654 59.9599 36.5654L59.9865 36.5522ZM62.892 27.8564C62.8786 29.7179 62.4921 33.0154 60.2931 35.2092C62.5055 35.0497 64.2647 33.2015 64.2647 30.9544C64.2647 29.7578 63.7583 28.6409 62.892 27.8431V27.8564Z"
        fill="#455068"
      />
      <path
        d="M69.7557 35.2358C69.3825 35.2358 69.0893 34.9433 69.0893 34.571C69.0893 29.6115 65.6508 23.6282 61.4259 21.2083C59.5334 20.118 57.7742 19.9319 56.4814 20.6765C55.1486 21.4477 54.429 23.123 54.429 25.3966C54.429 25.7689 54.1357 26.0614 53.7626 26.0614C53.3894 26.0614 53.0962 25.7689 53.0962 25.3966C53.0962 22.6177 54.0691 20.5302 55.815 19.5197C57.5343 18.5225 59.76 18.7086 62.0923 20.0516C66.6904 22.6709 70.4221 29.186 70.4221 34.5577C70.4221 34.93 70.1289 35.2225 69.7557 35.2225V35.2358Z"
        fill="#455068"
      />
      <path d="M69.0893 34.5843V34.571H70.4221L69.0893 34.5843Z" fill="#455068" />
      <path
        d="M75.22 38.3737C74.8469 38.3737 74.5537 38.0812 74.5537 37.7089C74.5537 29.2392 68.6629 18.9746 61.426 14.8128C58.0807 12.8982 54.9754 12.5924 52.6564 13.922C50.2708 15.2915 48.9647 18.2699 48.9647 22.272C48.9647 22.6443 48.6715 22.9368 48.2983 22.9368C47.9251 22.9368 47.6319 22.6443 47.6319 22.272C47.6319 17.7646 49.1779 14.3874 51.99 12.7652C54.7355 11.183 58.3206 11.4888 62.0924 13.6561C69.7024 18.0172 75.8864 28.8138 75.8864 37.6956C75.8864 38.0679 75.5932 38.3604 75.22 38.3604V38.3737Z"
        fill="#455068"
      />
      <path d="M69.0893 34.5843V34.571H70.4221L69.0893 34.5843Z" fill="#455068" />
      <path
        d="M81.1774 41.7909C80.8043 41.7909 80.5111 41.4983 80.5111 41.126C80.5111 28.8005 71.9547 13.8821 61.4259 7.84563C56.5214 5.02683 51.9233 4.57476 48.4848 6.5559C44.953 8.59022 43.0205 12.9647 43.0205 18.8549C43.0205 19.2272 42.7273 19.5197 42.3541 19.5197C41.981 19.5197 41.6877 19.2272 41.6877 18.8549C41.6877 12.4594 43.8601 7.68608 47.8184 5.39913C51.6834 3.16536 56.7479 3.61744 62.0923 6.67556C72.981 12.9115 81.8438 28.3617 81.8438 41.0994C81.8438 41.4717 81.5506 41.7643 81.1774 41.7643V41.7909Z"
        fill="#455068"
      />
      <path d="M45.7794 52.1486V62.3202L54.6022 67.3994V57.2411L45.7794 52.1486Z" fill="#455068" />
      <path
        d="M54.6022 68.0642C54.4822 68.0642 54.3756 68.0376 54.269 67.9711L45.4461 62.8919C45.2462 62.7723 45.1129 62.5595 45.1129 62.3202V52.1486C45.1129 51.9093 45.2462 51.6965 45.4461 51.5769C45.646 51.4572 45.9126 51.4572 46.1125 51.5769L54.9354 56.656C55.1353 56.7757 55.2686 56.9884 55.2686 57.2277V67.3993C55.2686 67.6387 55.1353 67.8514 54.9354 67.9711C54.8287 68.0243 54.7221 68.0642 54.6022 68.0642ZM46.4457 61.9346L53.9358 66.2559V57.6266L46.4457 53.3054V61.9346Z"
        fill="#455068"
      />
      <path
        d="M60.1598 76.1219C60.0532 76.1219 59.9333 76.0953 59.8266 76.0288L45.1529 67.5857C44.8331 67.3996 44.7265 67.0007 44.913 66.6816C45.0996 66.3625 45.4995 66.2561 45.8193 66.4422L60.493 74.8853C60.8129 75.0715 60.9195 75.4704 60.7329 75.7895C60.613 76.0022 60.3864 76.1219 60.1598 76.1219Z"
        fill="#455068"
      />
      <path
        d="M59.8 79.5521C59.6933 79.5521 59.5734 79.5255 59.4668 79.459L44.7931 71.0159C44.4732 70.8298 44.3666 70.4309 44.5532 70.1118C44.7398 69.7927 45.1396 69.6863 45.4595 69.8724L60.1331 78.3155C60.453 78.5017 60.5596 78.9006 60.373 79.2197C60.2531 79.4324 60.0265 79.5521 59.8 79.5521Z"
        fill="#455068"
      />
      <path
        d="M43.7002 81.653C43.7002 81.653 46.3524 77.9434 48.5514 79.1135C50.7505 80.2835 48.738 83.8469 50.2574 84.5915C51.7767 85.3361 54.3223 82.7965 57.0678 83.6475C59.8133 84.4984 56.5747 87.756 59.0269 88.9393C61.4792 90.1227 63.7849 87.4502 65.904 88.4607C68.0231 89.4712 68.4895 94.6035 68.4895 94.6035"
        fill="white"
      />
      <path
        d="M68.4896 95.2684C68.1564 95.2684 67.8632 95.0157 67.8232 94.67C67.6499 92.8352 66.9036 89.684 65.6108 89.059C64.8378 88.6867 63.9582 88.9926 62.9319 89.3383C61.6792 89.7637 60.2531 90.2557 58.7338 89.5244C57.0945 88.7266 57.321 87.1577 57.481 86.1206C57.6675 84.8574 57.6409 84.5117 56.8679 84.2724C55.5884 83.8735 54.2557 84.3788 53.0695 84.8175C51.9367 85.243 50.8705 85.6419 49.9509 85.1898C48.8713 84.658 48.9246 83.3949 48.9646 82.2913C49.0179 80.9483 48.9913 80.1107 48.2183 79.6985C46.8455 78.9805 44.8197 81.201 44.22 82.0386C44.0068 82.3445 43.5936 82.4109 43.2871 82.1982C42.9939 81.9855 42.9139 81.5733 43.1271 81.2675C43.2471 81.0946 46.1525 77.119 48.8313 78.5151C50.404 79.3395 50.3374 81.068 50.2841 82.3312C50.2574 83.0757 50.2174 83.8336 50.5239 83.9932C50.9238 84.1926 51.7234 83.8868 52.5764 83.5677C53.8825 83.0757 55.5085 82.4641 57.2277 83.0093C59.1736 83.6076 58.9337 85.243 58.7604 86.3333C58.5871 87.5167 58.5738 88.0086 59.2669 88.341C60.2931 88.833 61.346 88.474 62.4522 88.0884C63.6383 87.6762 64.8778 87.2641 66.1439 87.8624C68.5295 89.0059 69.0493 93.9786 69.1026 94.5504C69.1426 94.9094 68.8627 95.2418 68.5029 95.2684C68.4762 95.2684 68.4629 95.2684 68.4363 95.2684H68.4896Z"
        fill="#455068"
      />
      <path
        d="M74.2337 106.849C74.2204 109.043 73.4874 110.639 72.3012 111.476L71.7548 111.782L68.7028 113.524C69.1426 113.165 69.5157 112.713 69.8089 112.128C70.2354 111.317 70.462 110.267 70.4753 109.017C70.4887 107.607 70.2088 106.118 69.7023 104.629L73.5807 102.847C74.0205 104.19 74.247 105.546 74.2337 106.836V106.849Z"
        fill="#455068"
      />
      <path
        d="M68.7028 114.202C68.4895 114.202 68.2763 114.096 68.1563 113.91C67.9564 113.63 68.0097 113.232 68.2896 113.019C68.6761 112.713 68.9826 112.314 69.2092 111.849C69.5957 111.104 69.7956 110.16 69.8089 109.03C69.8089 107.754 69.569 106.344 69.0626 104.868C68.956 104.549 69.1026 104.19 69.4091 104.057L73.2874 102.276C73.4607 102.196 73.6606 102.196 73.8339 102.276C74.0071 102.355 74.1404 102.502 74.1937 102.674C74.6602 104.124 74.8868 105.533 74.8734 106.876C74.8601 109.203 74.0738 111.038 72.6611 112.035L69.0093 114.122C68.9027 114.176 68.796 114.216 68.6761 114.216L68.7028 114.202ZM70.5153 105.001C70.9418 106.411 71.155 107.767 71.1417 109.03C71.1417 109.987 70.9951 110.852 70.7552 111.596L71.968 110.905C72.9676 110.187 73.554 108.737 73.5673 106.849C73.5673 105.865 73.4341 104.842 73.1542 103.791L70.5153 105.001Z"
        fill="#455068"
      />
      <path
        d="M73.5807 102.861L69.7023 104.642C68.4895 101.092 65.9573 97.6617 63.0385 95.9864C61.959 95.3615 60.9461 95.0424 60.0131 94.9759C59.3068 94.936 58.6671 95.0424 58.1073 95.295L61.3992 93.3803L61.7324 93.1942C63.0518 92.5161 64.8377 92.6624 66.7969 93.8058C69.8356 95.5609 72.4478 99.1642 73.5807 102.861Z"
        fill="white"
      />
      <path
        d="M69.7024 105.307C69.6091 105.307 69.5291 105.294 69.4358 105.254C69.2625 105.174 69.1293 105.041 69.0626 104.855C67.8765 101.358 65.3709 98.1005 62.692 96.5581C61.7324 95.9997 60.8128 95.6939 59.9599 95.6407C59.3735 95.6008 58.827 95.6939 58.3739 95.9066C58.054 96.0529 57.6675 95.9332 57.5076 95.6141C57.3477 95.295 57.4543 94.9094 57.7608 94.7366L61.3726 92.6358C62.9852 91.8114 65.011 92.0241 67.1035 93.2474C70.1821 95.0291 72.9676 98.7254 74.1938 102.674C74.287 102.994 74.1404 103.339 73.8339 103.472L69.9556 105.254C69.8623 105.294 69.769 105.32 69.6757 105.32L69.7024 105.307ZM60.9328 94.4308C61.7191 94.5903 62.5454 94.9227 63.3717 95.4014C66.1705 97.0102 68.7028 100.161 70.0755 103.725L72.7544 102.502C71.5549 99.0711 69.1159 95.9199 66.4504 94.3776C64.7578 93.3937 63.1852 93.1809 62.0257 93.7792L60.9195 94.4175L60.9328 94.4308Z"
        fill="#455068"
      />
      <path
        d="M70.4754 109.03C70.462 110.28 70.2355 111.33 69.809 112.141C69.5291 112.726 69.1426 113.192 68.7028 113.537C68.5962 113.63 68.4762 113.71 68.3563 113.763C66.9835 114.614 65.0644 114.521 62.9453 113.271C58.8804 110.865 55.5884 105.094 55.5884 100.348C55.5884 98.1138 56.3081 96.4917 57.5076 95.654L58.054 95.3349C58.054 95.3349 58.0807 95.3083 58.1074 95.3083C58.6671 95.0557 59.3068 94.936 60.0132 94.9892C60.9328 95.0424 61.959 95.3748 63.0386 95.9997C65.9573 97.6883 68.4896 101.105 69.7024 104.656C70.2088 106.131 70.4887 107.634 70.4754 109.043V109.03Z"
        fill="white"
      />
      <path
        d="M66.3438 115C65.1976 115 63.9315 114.614 62.6254 113.843C58.3072 111.29 54.9354 105.374 54.9354 100.348C54.9354 97.9675 55.7217 96.1061 57.1344 95.1089L57.7208 94.7499C57.7208 94.7499 57.8008 94.71 57.8408 94.6967C58.4938 94.3909 59.2269 94.2579 60.0665 94.3111C61.1194 94.3776 62.2389 94.7366 63.3851 95.4014C66.3704 97.1166 69.036 100.574 70.3421 104.416C70.8885 106.038 71.1684 107.594 71.1551 109.017C71.1417 110.36 70.8885 111.503 70.4087 112.421C70.1022 113.045 69.6757 113.591 69.1293 114.029C68.996 114.136 68.8361 114.255 68.6628 114.335C67.9831 114.761 67.1968 114.973 66.3305 114.973L66.3438 115ZM58.3872 95.9066L57.8541 96.2257C56.8412 96.9437 56.2548 98.4196 56.2548 100.348C56.2548 104.868 59.4134 110.399 63.2918 112.7C65.1177 113.777 66.7969 113.95 68.0097 113.192C68.1297 113.125 68.1963 113.085 68.263 113.019C68.6628 112.686 68.9693 112.301 69.2092 111.835C69.5957 111.091 69.7956 110.147 69.809 109.017C69.809 107.74 69.5691 106.331 69.0626 104.855C67.8765 101.358 65.3709 98.1005 62.692 96.5581C61.7324 95.9997 60.8128 95.6939 59.9599 95.6407C59.3735 95.6008 58.827 95.6939 58.3739 95.9066H58.3872Z"
        fill="#455068"
      />
      <path d="M35.6636 76.8133L26.4942 82.0919L35.6636 87.3705L44.8197 82.0919L35.6636 76.8133Z" fill="white" />
      <path
        d="M35.6637 88.0353C35.5437 88.0353 35.4371 88.0087 35.3305 87.9422L26.1611 82.6636C25.9612 82.5439 25.8279 82.3312 25.8279 82.0919C25.8279 81.8525 25.9612 81.6398 26.1611 81.5201L35.3305 76.2415C35.5304 76.1219 35.7969 76.1219 35.9968 76.2415L45.1662 81.5201C45.3661 81.6398 45.4994 81.8525 45.4994 82.0919C45.4994 82.3312 45.3661 82.5439 45.1662 82.6636L35.9968 87.9422C35.8902 87.9954 35.7836 88.0353 35.6637 88.0353ZM27.827 82.0919L35.6637 86.5993L43.5003 82.0919L35.6637 77.5845L27.827 82.0919Z"
        fill="#455068"
      />
      <path d="M35.6636 87.3704V97.2096L26.4942 91.9311V82.0919L35.6636 87.3704Z" fill="white" />
      <path
        d="M35.6637 97.8745C35.5437 97.8745 35.4371 97.8479 35.3305 97.7814L26.1611 92.5028C25.9612 92.3831 25.8279 92.1704 25.8279 91.9311V82.0919C25.8279 81.8525 25.9612 81.6398 26.1611 81.5201C26.361 81.4005 26.6275 81.4005 26.8275 81.5201L35.9968 86.7987C36.1968 86.9184 36.33 87.1311 36.33 87.3705V97.2096C36.33 97.449 36.1968 97.6617 35.9968 97.7814C35.8902 97.8346 35.7836 97.8745 35.6637 97.8745ZM27.1606 91.5455L34.9973 96.0529V87.756L27.1606 83.2486V91.5455Z"
        fill="#455068"
      />
      <path d="M44.8197 82.0919V91.9311L35.6636 97.2096V87.3704L44.8197 82.0919Z" fill="#455068" />
      <path
        d="M35.6636 97.8745C35.5436 97.8745 35.437 97.8479 35.3304 97.7814C35.1305 97.6617 34.9972 97.449 34.9972 97.2096V87.3705C34.9972 87.1311 35.1305 86.9184 35.3304 86.7987L44.4998 81.5201C44.6997 81.4005 44.9662 81.4005 45.1662 81.5201C45.3661 81.6398 45.4993 81.8525 45.4993 82.0919V91.9311C45.4993 92.1704 45.3661 92.3831 45.1662 92.5028L35.9968 97.7814C35.8901 97.8346 35.7835 97.8745 35.6636 97.8745ZM36.33 87.756V96.0529L44.1666 91.5455V83.2486L36.33 87.756Z"
        fill="#455068"
      />
    </svg>
  );
}
