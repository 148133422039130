import { NvBox, NvFlex, NvSkeleton } from '@novaera/core';
import { WorkflowPermissionActions, WorkflowPermissionItemContainer, WorkflowPermissionUser } from '../styled';

export const WorkflowPermissionItemLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <WorkflowPermissionItemContainer disabled={false}>
    <WorkflowPermissionUser>
      <NvBox flex="0 0 auto" height="32px" padding="4px 0">
        <NvSkeleton variant="circular" width="24px" height="24px"></NvSkeleton>
      </NvBox>

      <NvFlex flex="1 1 auto" minWidth="0" gap="4px">
        <NvSkeleton variant="rectangular" width="48px" height="12px" />
        <NvSkeleton variant="rectangular" width="72px" height="12px" />
      </NvFlex>
    </WorkflowPermissionUser>
    <WorkflowPermissionActions>
      <NvSkeleton width="120px" height="24px" variant="rectangular" />
      <NvSkeleton width="24px" height="24px" variant="rectangular" />
    </WorkflowPermissionActions>
  </WorkflowPermissionItemContainer>
);
