import { FieldType } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { useMemo } from 'react';
import { QueryOptions } from '../../../constants';

export const useRecordFieldController = (field?: FieldType) => {
  const getQueryOptions = (field?: FieldType) => {
    switch (field?.schema.type) {
      case SchemaType.integer:
      case SchemaType.float:
      case SchemaType.map:
        return QueryOptions;
      case SchemaType.array:
        return QueryOptions.filter((option) => option.value === 'set' || option.value === 'add');
      default:
        return QueryOptions.filter((option) => option.value === 'set' || option.value === 'remove');
    }
  };
  const queryOptions = useMemo(() => {
    const queryOptions = getQueryOptions(field);
    return queryOptions;
  }, [field]);

  return {
    queryOptions,
    getQueryOptions,
  };
};
