import { assert } from '@novaera/utils';
import { once } from 'lodash';
import React, { ReactNode, useCallback, useContext, useEffect, useRef } from 'react';

type ContextType<ARGS_NOT_CHANGED, ARGS_CHANGED> = {
  wrapMethodForWaitUntil: (
    handleMethod: (params: { argsNotChanged: ARGS_NOT_CHANGED; argsChanged: ARGS_CHANGED }) => void
  ) => (argsNotChanged: ARGS_NOT_CHANGED) => void;
};

const WaitForContext = once(<ARGS_NOT_CHANGED, ARGS_CHANGED>() =>
  React.createContext({} as ContextType<ARGS_NOT_CHANGED, ARGS_CHANGED>)
);

type WaitForProviderProps<ARGS_CHANGED> = {
  conditionVariable: unknown;
  children: ReactNode;
  argsChanged: ARGS_CHANGED;
  conditionFunction: (params: { conditionVariable: unknown; prevConditionVariable: unknown }) => boolean;
};

/**
 * here argsNotChanged are the ones we don't need them to be changed like forms values but the argsChanged is something we want to change in time. use them accordingly.
 * @param argsChanged
 * the parameter that you want always be recent while executing it
 * @param conditionFunction
 * when you want it to be your function executed
 * @param conditionVariable
 * the variable that needs to be tracked
 */
export const WaitForProvider = <ARGS_NOT_CHANGED, ARGS_CHANGED>({
  children,
  conditionVariable,
  argsChanged,
  conditionFunction,
}: WaitForProviderProps<ARGS_CHANGED>) => {
  const WaitFor = WaitForContext<ARGS_NOT_CHANGED, ARGS_CHANGED>();
  const prevConditionVariable = useRef<unknown>(null);
  const lastCalledFunction = useRef<((argsChanged: ARGS_CHANGED) => void) | null>(null);

  useEffect(() => {
    if (conditionFunction({ conditionVariable, prevConditionVariable: prevConditionVariable.current })) {
      lastCalledFunction.current?.(argsChanged);
    }

    prevConditionVariable.current = conditionVariable;
  }, [conditionVariable, argsChanged, conditionFunction]);

  const wrapMethodForWaitUntil = useCallback(
    (
        handleMethod: ({
          argsNotChanged,
          argsChanged,
        }: {
          argsNotChanged: ARGS_NOT_CHANGED;
          argsChanged: ARGS_CHANGED;
        }) => void
      ) =>
      (argsNotChanged: ARGS_NOT_CHANGED) => {
        lastCalledFunction.current = (argsChangedArg: ARGS_CHANGED) => {
          handleMethod({ argsNotChanged, argsChanged: argsChangedArg });
        };

        handleMethod({ argsNotChanged, argsChanged });
      },
    [argsChanged]
  );

  return <WaitFor.Provider value={{ wrapMethodForWaitUntil }}>{children}</WaitFor.Provider>;
};

export const useWaitForContext = <T, ARGS_NOT_CHANGED>() => {
  const context = useContext(WaitForContext<T, ARGS_NOT_CHANGED>());
  assert(!!context, new Error(`WaitForContext should be used within WaitForProvider`), 'ERROR');

  return context;
};
