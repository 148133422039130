import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCodeIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6887 3.125H14.4553V5.125H15.6887C15.7975 5.125 15.9007 5.16804 15.9758 5.24271C16.0509 5.31736 16.0919 5.41728 16.0919 5.51999V10.2225C16.0919 10.4876 16.1972 10.7419 16.3846 10.9294L17.4546 12L16.3846 13.0706C16.1972 13.2581 16.0919 13.5124 16.0919 13.7775V18.4748C16.0903 18.5785 16.0482 18.6788 15.9726 18.754C15.8968 18.8293 15.7932 18.8734 15.6835 18.875H14.4553V20.875L15.6887 20.875L15.6966 20.875C16.3283 20.87 16.9341 20.6183 17.3827 20.1723C17.8314 19.7262 18.0868 19.1214 18.0919 18.488V14.1915L19.5757 12.7069C19.9659 12.3164 19.9659 11.6836 19.5757 11.2931L18.0919 9.80844V5.51999C18.0919 4.88274 17.8371 4.27297 17.3859 3.8244C16.9347 3.37585 16.3241 3.125 15.6887 3.125ZM9.55596 3.12505H8.32614C8.01121 3.12309 7.69868 3.18314 7.40675 3.30198C7.11353 3.42134 6.84667 3.59768 6.62194 3.82112C6.39726 4.04449 6.21919 4.31044 6.09845 4.60362C5.97831 4.89536 5.91741 5.20805 5.91938 5.52358V9.80663L4.42652 11.2909C4.23774 11.4786 4.13159 11.7338 4.13159 12.0001C4.13159 12.2663 4.23774 12.5215 4.42653 12.7092L5.91938 14.1934V18.48C5.91938 19.1173 6.17421 19.727 6.62539 20.1756C7.07651 20.6241 7.68715 20.875 8.32262 20.875H9.55596V18.875H8.32262C8.21383 18.875 8.11065 18.832 8.0355 18.7573C7.96041 18.6826 7.91938 18.5827 7.91938 18.48V13.7775C7.91938 13.5113 7.81323 13.2561 7.62444 13.0684L6.5499 12L7.62445 10.9317C7.81323 10.744 7.91938 10.4887 7.91938 10.2225L7.91941 5.52004L7.91934 5.51182C7.91893 5.46171 7.92855 5.41189 7.94777 5.36521C7.96699 5.31855 7.99554 5.27572 8.03205 5.23942C8.0685 5.20319 8.1122 5.17417 8.16081 5.15438C8.20929 5.13465 8.26162 5.12462 8.3145 5.12505H9.55596V3.12505Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
