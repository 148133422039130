import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';

import { QUERY_KEYS_WORKFLOW_BATCH_SAMPLE_RESPONSE } from '../keys';
import { GetWorkflowSampleResponseBatch, GetWorkflowSampleResponseBatchParam } from '../types';

export const getWorkflowSampleResponseBatch = async (param: GetWorkflowSampleResponseBatchParam) => {
  const result = await NvAxios.post<GetWorkflowSampleResponseBatch>(
    `${USER_APP_ROOT_PATH}/workflows/sample-payload/batch-get`,
    {
      workflowIdentifiers: param.workflowIdentifiers.filter((wf) => wf.appId && wf.id),
    }
  );
  return result?.data;
};

export const useGetWorkflowSampleResponseBatch = (param: GetWorkflowSampleResponseBatchParam) => {
  return useQuery<GetWorkflowSampleResponseBatch>(
    QUERY_KEYS_WORKFLOW_BATCH_SAMPLE_RESPONSE.batch(param),
    () => getWorkflowSampleResponseBatch(param),
    {
      enabled: param.workflowIdentifiers.length > 0 && param.workflowIdentifiers.some((wf) => wf.appId && wf.id),
    }
  );
};
