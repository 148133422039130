import { NvMailOutlineIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { BaseNodeProps } from '../types';

export type EmailTriggerProps = BaseNodeProps;

export const EmailTrigger: FC<EmailTriggerProps> = (props) => {
  const theme = useTheme();
  const { actions, alias, ...rest } = props;
  return (
    <Node
      type={NodeType.EMAIL_TRIGGER}
      icon={<NvMailOutlineIcon htmlColor={theme.palette.nv_node.trigger} />}
      alias="event"
      {...rest}
    />
  );
};
