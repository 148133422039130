import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { GetExecuteAutoRunWorkflowsResponse, GetUserAppSetupStatusParams } from './types';

const executeAutoRunWorkflows = async ({ appId }: GetUserAppSetupStatusParams) => {
  const result = await NvAxios.post<GetExecuteAutoRunWorkflowsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/setup/execute-auto-run-workflows`
  );
  return result.data;
};

export const useExecuteAutoRunWorkflowsQuery = ({
  params,
  enabled,
  onSuccess,
}: {
  params: GetUserAppSetupStatusParams;
  enabled: boolean;
  onSuccess?: (data: GetExecuteAutoRunWorkflowsResponse) => void;
}) => {
  return useQuery(['autorunWorkflows', params.appId], () => executeAutoRunWorkflows(params), {
    enabled,
    retry: false,
    onSuccess,
  });
};
