import { NvAxios, useQuery } from '@novaera/core';
import { GLOBAL_CONNECTION_SCHEMA } from './constants';
import { QUERY_KEYS_GLOBAL_CONNECTION_SCHEMAS } from './keys';
import { GetGlobalSchemaResponse } from './types';

const getGlobalConnectionSchemas = async () => {
  const result = await NvAxios.get<GetGlobalSchemaResponse>(GLOBAL_CONNECTION_SCHEMA());
  return result?.data;
};

export const useGetGlobalConnectionSchemasService = ({ enabled }: { enabled?: boolean }) => {
  return useQuery<GetGlobalSchemaResponse>(
    QUERY_KEYS_GLOBAL_CONNECTION_SCHEMAS.globalConnectionSchemas(),
    () => getGlobalConnectionSchemas(),
    { enabled }
  );
};
