import { AccountCircleIcon, NvConditionalRender, NvFlex, NvImage, NvMenuWithItems, NvTypography } from '@novaera/core';
import { useDefaultPrincipal } from './controllers/use-default-principal';
import { PrincipalCard } from './styled';
import { DefaultPrincipalProps } from './types';

export const DefaultPrincipal = (props: DefaultPrincipalProps) => {
  const { selectedPrincipalUser, showAssignToMe, onSwitchToMe, isSaveLoading } = useDefaultPrincipal(props);
  return (
    <PrincipalCard>
      <NvFlex gap={'8px'} flexDirection={'row'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
        <NvImage
          src={selectedPrincipalUser?.logoUrl}
          FallBack={<AccountCircleIcon sx={{ width: '16px', height: '16px' }} />}
          size={'smaller'}
        />
        <NvTypography variant="body2">{selectedPrincipalUser?.userName}</NvTypography>
      </NvFlex>
      <NvConditionalRender when={showAssignToMe}>
        <NvMenuWithItems
          menuItems={[
            {
              name: <NvTypography variant="h4">Switch to me</NvTypography>,
              description: (
                <NvTypography variant="body2" textColor="secondary">
                  Select yourself as the user for the default workflow.
                </NvTypography>
              ),
              onClick: onSwitchToMe,
              isLoading: isSaveLoading,
            },
          ]}
          transformOrigin={undefined}
          anchorOrigin={undefined}
        />
      </NvConditionalRender>
    </PrincipalCard>
  );
};
