import { CatalogRelationship, isCatalogRelationshipDefinition } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvCustomEmptyIcon,
  NvDialogModal,
  NvFlex,
  NvLockIcon,
  NvTooltip,
  NvTypography,
  useForm,
  useFormState,
  useNvDialogModalState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { useMemo } from 'react';
import { RelationshipBlocks } from '../../../components/data-model-layout/left-content/relationship-blocks';
import { useCatalogPermission } from '../../controllers/use-catalog-permission';
import { useSelectedCatalogEntity } from '../../controllers/use-selected-catalog-entity';
import { SelectedItemDetail } from '../../provider/use-catalog-provider';
import { CatalogFormType, EntityTypeFormTypes } from '../../types';
import { RelationshipModalBody } from '../relationship-modal-body';
import { CatalogRelationshipHeader } from './header';

export const CatalogRelationships = ({
  onItemSelected,
  selectedDetailItem,
}: {
  selectedDetailItem?: SelectedItemDetail;
  onItemSelected: (item: SelectedItemDetail) => void;
}) => {
  const { hasCatalogEditPermission } = useCatalogPermission();

  const { selectedEntityType } = useSelectedCatalogEntity();

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState<EntityTypeFormTypes>();
  const {
    values: { relationshipDefinitions },
  } = useFormState<CatalogFormType>();

  const { mutators } = useForm();

  const { palette } = useTheme();

  const actions = useMemo(() => {
    if (!hasCatalogEditPermission) {
      if (relationshipDefinitions?.length === 0) {
        return (
          <NvFlex direction="row" gap="4px">
            <NvCustomEmptyIcon
              sx={{ width: '16px', height: '16px' }}
              htmlColor={palette.nv_neutral[300]}
            ></NvCustomEmptyIcon>
            <NvTypography variant="body2" textColor="subtle">
              No relationship has been defined for this entity yet.
            </NvTypography>
          </NvFlex>
        );
      } else {
        return;
      }
    }

    return relationshipDefinitions?.length > 0 ? (
      <NvButton
        startIcon={<NvAddBoxIcon />}
        size="small"
        color="ghost"
        onClick={() => {
          onModalOpenClicked();
        }}
      >
        Add relationship
      </NvButton>
    ) : (
      <NvButton
        startIcon={<NvAddBoxIcon />}
        size="medium"
        color="secondary"
        onClick={() => {
          onModalOpenClicked();
        }}
      >
        Add relationship
      </NvButton>
    );
  }, [hasCatalogEditPermission, palette.nv_neutral, relationshipDefinitions?.length, onModalOpenClicked]);

  return (
    <>
      <RelationshipBlocks<CatalogRelationship>
        fieldName="relationshipDefinitions"
        onItemSelected={(selectedItem, index) => {
          onItemSelected({
            selectedItemDetail: selectedItem,
            index,
          });
        }}
        title="Relationships"
        actions={actions}
        items={relationshipDefinitions}
        isSelected={(data, index) => {
          if (!selectedDetailItem || !selectedDetailItem.selectedItemDetail) {
            return false;
          }
          return data.id
            ? data.id === selectedDetailItem?.selectedItemDetail.id
            : isCatalogRelationshipDefinition(selectedDetailItem?.selectedItemDetail) &&
                index === selectedDetailItem?.index;
        }}
        listItemFormat={(item, index) => {
          return {
            header: <CatalogRelationshipHeader item={item} index={index} />,
          };
        }}
        RightContent={(item) => {
          return (
            item?.managed && (
              <NvTooltip title={'This relationship is managed by Actioner, edit or delete operations are not allowed.'}>
                <NvLockIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  htmlColor={palette.nv_neutral[300]}
                />
              </NvTooltip>
            )
          );
        }}
      />
      {selectedEntityType && (
        <NvDialogModal<CatalogRelationship>
          Header="Create relationship"
          maxWidth="sm"
          fullWidth
          modalIcon="add"
          Body={<RelationshipModalBody sourceEntityType={selectedEntityType} />}
          onCloseButtonClicked={onModalCloseClicked}
          open={isOpened}
          primaryButtonText="Create"
          onFormSubmit={(values) => {
            const newRelationshipDefinition = { ...values, sourceEntityTypeId: selectedEntityType.id };
            const newRelationshipDefinitions = [...(relationshipDefinitions ?? []), newRelationshipDefinition];

            mutators.push('relationshipDefinitions', newRelationshipDefinition);
            onItemSelected({
              selectedItemDetail: newRelationshipDefinition,
              index: newRelationshipDefinitions.length - 1,
            });
            onModalCloseClicked();
          }}
        />
      )}
    </>
  );
};
