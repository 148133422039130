import { AnalyticsRecentQueryState } from '@novaera/actioner-service';
import {
  ColoredChipColor,
  NeutralChipColor,
  NvArrowForwardIcon,
  NvButton,
  NvChevronRightIcon,
  NvChip,
  NvCollapse,
  NvDownloadIcon,
  NvFlex,
  NvKeyboardArrowDownIcon,
  NvLink,
  NvTypography,
  SemanticChipColor,
} from '@novaera/core';
import { useNavigate } from '@novaera/route';
import { format } from 'date-fns';
import { useState } from 'react';
import { QueryStringCard, RecentQueryCardWrapper } from './styled';
import { RecentQueryCardProps } from './types';

export const RecentQueryCard: React.FC<React.PropsWithChildren<RecentQueryCardProps>> = ({ recentQuery, index }) => {
  const navigate = useNavigate();
  const [showQueryStringSection, setShowQueryStringSection] = useState<boolean>(index === 0);

  const getChipColor = (state: AnalyticsRecentQueryState) => {
    switch (state) {
      case AnalyticsRecentQueryState.SUCCEEDED:
        return SemanticChipColor.SUCCESS;
      case AnalyticsRecentQueryState.FAILED:
        return SemanticChipColor.ERROR;
      case AnalyticsRecentQueryState.QUEUED:
        return NeutralChipColor.DARK_ALPHA;
      case AnalyticsRecentQueryState.RUNNING:
        return ColoredChipColor.BLUE;
      case AnalyticsRecentQueryState.CANCELLED:
        return SemanticChipColor.WARNING;
      default:
        return NeutralChipColor.LIGHT_ALPHA;
    }
  };

  let encodedOutputUrl: string | null;
  try {
    if (recentQuery.outputUrl) {
      encodedOutputUrl = btoa(recentQuery.outputUrl);
    } else {
      encodedOutputUrl = null;
    }
  } catch (error) {
    encodedOutputUrl = null;
  }

  return (
    <RecentQueryCardWrapper>
      <NvFlex direction="row" alignItems="center" gap="8px">
        <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth={0}>
          <NvTypography variant="h4" noWrap>
            {recentQuery.id}
          </NvTypography>
          <NvChip label={recentQuery.state} compact color={getChipColor(recentQuery.state)} />
        </NvFlex>
        <NvFlex direction="row" alignItems="center" justifyContent="flex-end" gap="8px" flex="1 1 auto" minWidth={0}>
          {recentQuery.startTime && (
            <NvTypography variant="h6" textColor="subtle" noWrap>
              {format(new Date(recentQuery.startTime), 'MMM dd, pp')}
            </NvTypography>
          )}

          {recentQuery.endTime && (
            <>
              <NvTypography variant="h6" textColor="subtle">
                -
              </NvTypography>
              <NvTypography variant="h6" textColor="subtle" noWrap>
                {format(new Date(recentQuery.endTime), 'MMM dd, pp')}
              </NvTypography>
            </>
          )}
        </NvFlex>
        <NvFlex direction="row" alignItems="center" gap="8px" flex="0 0 auto">
          {recentQuery.outputUrl && (
            <NvLink href={recentQuery.outputUrl}>
              <NvButton color="secondary" onlyIcon size="small">
                <NvDownloadIcon />
              </NvButton>
            </NvLink>
          )}
          <NvButton
            color="secondary"
            endIcon={<NvArrowForwardIcon />}
            size="small"
            onClick={() => {
              navigate({
                pathname: `/admin/analytic-queries/${recentQuery.id}`,
                search: encodedOutputUrl ? `?outputUrl=${encodedOutputUrl}` : '',
              });
            }}
          >
            See result
          </NvButton>
        </NvFlex>
      </NvFlex>
      <QueryStringCard>
        <NvFlex
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          onClick={() => {
            setShowQueryStringSection(!showQueryStringSection);
          }}
        >
          {showQueryStringSection ? <NvKeyboardArrowDownIcon /> : <NvChevronRightIcon />}
          <NvTypography variant="h4">Query</NvTypography>
        </NvFlex>
        <NvCollapse in={showQueryStringSection}>
          <NvTypography variant="c2">{recentQuery.query}</NvTypography>
        </NvCollapse>
      </QueryStringCard>
    </RecentQueryCardWrapper>
  );
};
