export function NvDatabaseIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M93.9708 92.7654C93.7673 92.7654 93.5637 92.6573 93.4559 92.465C93.2883 92.1767 93.3841 91.8163 93.6715 91.6481L106.102 84.4517C106.389 84.2835 106.76 84.3796 106.916 84.6679C107.084 84.9563 106.988 85.3167 106.701 85.4849L94.2702 92.6813C94.1744 92.7414 94.0667 92.7654 93.9708 92.7654Z"
        fill="#B1B6C0"
      />
      <path
        d="M72.8704 88.164C72.6668 88.164 72.4632 88.0559 72.3554 87.8636C72.1878 87.5753 72.2836 87.2149 72.571 87.0467L94.3661 74.4199C94.6535 74.2517 95.0127 74.3478 95.1804 74.6362C95.348 74.9245 95.2522 75.2849 94.9648 75.4531L73.1698 88.0799C73.074 88.14 72.9662 88.164 72.8704 88.164Z"
        fill="#B1B6C0"
      />
      <path
        d="M21.3526 104.371C21.149 104.371 20.9455 104.263 20.8377 104.071C20.67 103.782 20.7658 103.422 21.0532 103.254L26.2385 100.25C26.526 100.082 26.8852 100.178 27.0529 100.466C27.2205 100.755 27.1247 101.115 26.8373 101.283L21.652 104.287C21.5562 104.347 21.4484 104.371 21.3526 104.371Z"
        fill="#B1B6C0"
      />
      <path
        d="M10.5988 93.7386C10.3952 93.7386 10.1916 93.6305 10.0838 93.4383C9.91616 93.1499 10.0239 92.7775 10.3113 92.6213L19.4964 87.3952C19.7838 87.239 20.1431 87.3351 20.3107 87.6235C20.4784 87.9118 20.3706 88.2842 20.0832 88.4404L10.8981 93.6665C10.8023 93.7146 10.7065 93.7386 10.5988 93.7386Z"
        fill="#B1B6C0"
      />
      <path
        d="M36.7528 71.3803C36.5493 71.3803 36.3457 71.2722 36.2379 71.08C36.0702 70.7917 36.166 70.4312 36.4534 70.263L41.6388 67.2595C41.9262 67.0913 42.2854 67.1874 42.4531 67.4758C42.6207 67.7641 42.5249 68.1245 42.2375 68.2927L37.0522 71.2962C36.9564 71.3563 36.8486 71.3803 36.7528 71.3803Z"
        fill="#B1B6C0"
      />
      <path
        d="M12.6585 83.1901C12.455 83.1901 12.2514 83.082 12.1436 82.8897C11.9759 82.6014 12.0717 82.241 12.3591 82.0728L22.1669 76.3901C22.4543 76.2219 22.8136 76.3181 22.9812 76.6064C23.1489 76.8947 23.0531 77.2551 22.7657 77.4233L12.9579 83.106C12.8621 83.1661 12.7543 83.1901 12.6585 83.1901Z"
        fill="#B1B6C0"
      />
      <path
        d="M48.5974 109.012C48.3939 109.012 48.1903 108.904 48.0825 108.712C47.9148 108.423 48.0106 108.063 48.2981 107.895L60.0458 101.083C60.3332 100.915 60.6925 101.011 60.8601 101.299C61.0278 101.587 60.932 101.948 60.6446 102.116L48.8968 108.928C48.801 108.988 48.6932 109.012 48.5974 109.012Z"
        fill="#B1B6C0"
      />
      <path
        d="M63.1584 110.726C62.9548 110.726 62.7512 110.618 62.6435 110.426C62.4758 110.138 62.5716 109.777 62.859 109.609L70.9304 104.936C71.2178 104.767 71.5771 104.863 71.7447 105.152C71.9124 105.44 71.8166 105.801 71.5292 105.969L63.4578 110.642C63.362 110.702 63.2542 110.726 63.1584 110.726Z"
        fill="#B1B6C0"
      />
      <path
        d="M36.5974 106.874C36.3939 106.874 36.1903 106.765 36.0825 106.573C35.9148 106.285 36.0106 105.924 36.2981 105.756L44.3694 101.083C44.6568 100.915 45.0161 101.011 45.1837 101.299C45.3514 101.587 45.2556 101.948 44.9682 102.116L36.8968 106.789C36.801 106.85 36.6932 106.874 36.5974 106.874Z"
        fill="#B1B6C0"
      />
      <path
        d="M89.5879 71.933C89.5879 71.933 89.5879 72.0291 89.552 72.0651C89.4203 73.5188 88.8814 74.9245 87.9712 76.3181C87.624 76.8227 87.2407 77.3273 86.7977 77.8078C85.66 79.0453 84.199 80.2227 82.3668 81.2318C75.5049 85.1364 65.6014 86.1937 56.9911 84.4156C55.7696 84.1633 54.5721 83.8629 53.4225 83.4905C51.1831 82.7937 49.0874 81.8926 47.2073 80.7633C45.4469 79.7181 44.0219 78.5407 42.9441 77.3033C42.4291 76.7866 42.0579 76.2821 41.7106 75.7414C40.8843 74.4439 40.3814 73.1464 40.1898 71.8369C40.1299 71.4885 40.1299 71.1761 40.1299 70.8877C40.0341 67.0793 42.501 63.3069 47.5306 60.5076C57.1827 55.1373 72.7147 55.3295 82.3309 60.9882C86.8575 63.6553 89.2646 67.0192 89.552 70.4432L89.5879 71.933Z"
        fill="white"
      />
      <path
        d="M65.3858 85.8693C62.5477 85.8693 59.6736 85.5929 56.8714 85.0043C55.5301 84.7279 54.3326 84.4156 53.2429 84.0672C50.8957 83.3343 48.7641 82.3972 46.9079 81.2799C45.1356 80.2227 43.6506 79.0213 42.501 77.6997C42.0459 77.2432 41.6508 76.7506 41.2196 76.0658C40.3574 74.7082 39.8185 73.3146 39.603 71.921C39.5431 71.5365 39.5431 71.1881 39.5431 70.8877C39.4353 66.6828 42.1777 62.8023 47.2552 59.979C57.051 54.5246 72.9303 54.7529 82.6422 60.4716C87.1809 63.1387 89.8394 66.6708 90.1627 70.3952L90.1987 71.921C90.1987 71.993 90.1987 72.1012 90.1508 72.2333C89.9951 73.7231 89.4442 75.1647 88.4862 76.6425C88.0671 77.2552 87.6599 77.7598 87.2527 78.2163C85.9953 79.5859 84.4505 80.7753 82.6662 81.7605C77.9479 84.4396 71.7687 85.8693 65.3978 85.8693H65.3858ZM64.3679 57.2278C58.3683 57.2278 52.4166 58.4892 47.83 61.0482C43.1596 63.6433 40.6448 67.1394 40.7406 70.8877C40.7406 71.1761 40.7406 71.4524 40.7885 71.7528C40.9682 72.9902 41.4472 74.2276 42.2256 75.4291C42.6088 76.0298 42.9561 76.4623 43.3752 76.8948C44.4769 78.1562 45.8541 79.2736 47.5187 80.2587C49.303 81.328 51.3508 82.229 53.6021 82.9258C54.6679 83.2742 55.8056 83.5626 57.1109 83.8389C65.793 85.629 75.5888 84.4036 82.0674 80.7152C83.744 79.7902 85.181 78.6849 86.3546 77.4114C86.7258 77.0029 87.097 76.5343 87.4803 75.9817C88.3545 74.6481 88.8335 73.3506 88.9532 72.0171C88.9532 71.969 88.9652 71.9209 88.9891 71.8729L88.9532 70.4673C88.6778 67.1754 86.2109 63.9797 82.0315 61.5168C77.1815 58.6694 70.7508 57.2278 64.3679 57.2278Z"
        fill="#455068"
      />
      <path
        d="M89.5879 85.1725C89.5879 85.1725 89.5879 85.2686 89.552 85.3286C89.4202 86.7583 88.8813 88.188 87.9712 89.5576C86.7617 91.3597 84.8936 93.0417 82.3668 94.4713C75.5049 98.4119 65.6013 99.4572 56.9911 97.6791C55.7696 97.4268 54.5721 97.1144 53.4225 96.754V83.4905C54.5721 83.8629 55.7696 84.1633 56.9911 84.4156C65.6013 86.1937 75.5049 85.1364 82.3668 81.2318C84.199 80.2227 85.66 79.0453 86.7976 77.8078C87.2407 77.3273 87.6239 76.8227 87.9712 76.3181C88.8813 74.9245 89.4202 73.5188 89.552 72.0651V83.7188L89.5879 85.1725Z"
        fill="#455068"
      />
      <path
        d="M65.3858 99.1328C62.5477 99.1328 59.6736 98.8565 56.8714 98.2678C55.6499 98.0155 54.4284 97.7031 53.2428 97.3307C52.9914 97.2586 52.8237 97.0183 52.8237 96.754V83.4905C52.8237 83.2983 52.9195 83.1181 53.0752 83.0099C53.2309 82.9018 53.4344 82.8658 53.6141 82.9258C54.6679 83.2622 55.8175 83.5626 57.1228 83.8389C65.8049 85.629 75.6007 84.4036 82.0794 80.7152C83.7559 79.7902 85.193 78.6849 86.3665 77.4114C86.7378 77.0029 87.109 76.5343 87.4922 75.9817C88.3664 74.6481 88.8454 73.3506 88.9652 72.0171C88.9891 71.6927 89.2286 71.4524 89.5879 71.4764C89.9112 71.4884 90.1627 71.7528 90.1627 72.0771L90.1986 85.1725C90.1986 85.2566 90.1986 85.3647 90.1507 85.4968C89.9951 86.9866 89.4322 88.4763 88.4862 89.906C87.1809 91.8523 85.2289 93.5703 82.6781 95.012C77.9718 97.7151 71.7806 99.1448 65.3978 99.1448L65.3858 99.1328ZM54.0212 96.3095C55.0272 96.6098 56.069 96.8741 57.1109 97.0904C65.9606 98.9165 75.5169 97.7151 82.0674 93.9547C84.4625 92.5971 86.2707 91.0113 87.4683 89.2212C88.3305 87.9237 88.8215 86.6021 88.9532 85.2686C88.9532 85.2205 88.9652 85.1725 88.9891 85.1244L88.9532 83.7308V75.8496C88.8095 76.1139 88.6418 76.3782 88.4742 76.6425C88.0551 77.2552 87.6479 77.7598 87.2407 78.2163C85.9833 79.5859 84.4385 80.7753 82.6542 81.7605C75.8402 85.641 65.9606 86.8785 56.8714 85.0043C55.8415 84.788 54.8954 84.5597 54.0212 84.3074V96.3095ZM88.9891 85.1845L89.5879 85.1725H88.9891V85.1845Z"
        fill="#455068"
      />
      <path
        d="M53.4225 83.4905V96.754C51.1831 96.0572 49.0874 95.1561 47.2073 94.0268C44.7164 92.501 42.8722 90.795 41.7106 88.9809C40.8843 87.7074 40.3814 86.3739 40.1898 85.0763V71.8369C40.3814 73.1464 40.8843 74.4439 41.7106 75.7414C42.0579 76.2821 42.4291 76.7866 42.9441 77.3032C44.0219 78.5407 45.4469 79.7181 47.2073 80.7633C49.0874 81.8926 51.1831 82.7937 53.4225 83.4905Z"
        fill="white"
      />
      <path
        d="M53.4225 97.3547C53.4225 97.3547 53.3027 97.3547 53.2428 97.3307C50.8957 96.5978 48.7521 95.6607 46.8959 94.5434C44.3452 92.9816 42.4291 91.2155 41.2077 89.3053C40.3574 87.9837 39.8065 86.5901 39.603 85.1604V71.8369C39.603 71.5245 39.8425 71.2602 40.1538 71.2361C40.4772 71.2241 40.7406 71.4404 40.7885 71.7528C40.9682 72.9782 41.4472 74.2036 42.2256 75.417C42.6088 76.0177 42.9561 76.4502 43.3752 76.8828C44.4769 78.1442 45.8541 79.2615 47.5186 80.2467C49.303 81.3159 51.3507 82.217 53.6021 82.9138C53.8536 82.9859 54.0212 83.2262 54.0212 83.4905V96.754C54.0212 96.9462 53.9254 97.1264 53.7817 97.2346C53.674 97.3066 53.5542 97.3547 53.4225 97.3547ZM40.7885 75.357V85.0763C40.9682 86.2417 41.4472 87.4791 42.2136 88.6565C43.3393 90.4106 45.1236 92.0445 47.5186 93.5222C49.0874 94.4593 50.8717 95.2763 52.8237 95.937V83.935C50.6322 83.2262 48.6443 82.3371 46.8959 81.2799C45.1236 80.2227 43.6386 79.0213 42.489 77.6997C42.034 77.2432 41.6388 76.7506 41.2077 76.0658C41.064 75.8255 40.9202 75.5972 40.7885 75.357Z"
        fill="#455068"
      />
      <path
        d="M46.9079 87.1428C46.9079 87.323 46.872 87.4791 46.8121 87.5993C46.7642 87.6834 46.7163 87.7555 46.6445 87.8035C46.6325 87.8155 46.6085 87.8276 46.5966 87.8396C46.393 87.9717 46.1176 87.9477 45.8062 87.7675C45.2074 87.4071 44.7284 86.5541 44.7284 85.8573C44.7284 85.5209 44.8362 85.2926 45.0158 85.1604L45.0997 85.1124C45.1835 85.0763 45.2793 85.0523 45.3751 85.0643C45.5068 85.0643 45.6625 85.1244 45.8182 85.2085C46.2493 85.4608 46.6205 85.9654 46.8002 86.494C46.872 86.7102 46.9199 86.9385 46.9199 87.1428H46.9079Z"
        fill="#455068"
      />
      <path
        d="M46.3092 88.5364C46.0577 88.5364 45.7703 88.4523 45.4948 88.2961C44.7045 87.8276 44.1177 86.7823 44.1177 85.8693C44.1177 85.3527 44.3093 84.9322 44.6566 84.6799C44.6685 84.6799 44.7643 84.6078 44.7763 84.6078C44.7763 84.6078 44.8122 84.5838 44.8362 84.5717C45.0158 84.4876 45.1955 84.4516 45.411 84.4636C45.6385 84.4756 45.878 84.5597 46.1176 84.6919C46.6564 85.0043 47.1355 85.617 47.363 86.3018C47.4588 86.5901 47.5067 86.8784 47.5067 87.1548C47.5067 87.4311 47.4468 87.6834 47.339 87.8876C47.2672 88.0318 47.1594 88.176 47.0277 88.2841C46.9918 88.3081 46.9079 88.3682 46.86 88.3922C46.7043 88.4883 46.5127 88.5484 46.3092 88.5484V88.5364ZM45.3511 85.677C45.3511 85.677 45.3152 85.7732 45.3152 85.8693C45.3152 86.3618 45.6864 87.0106 46.1056 87.2629C46.2373 87.335 46.2972 87.335 46.2972 87.335C46.2732 87.335 46.2972 87.2749 46.2972 87.1428C46.2972 87.0106 46.2732 86.8544 46.2134 86.6982C46.0816 86.2898 45.7942 85.9173 45.4948 85.7491C45.411 85.7011 45.3631 85.6891 45.3392 85.677H45.3511Z"
        fill="#455068"
      />
      <path
        d="M89.5879 49.623C89.5879 49.623 89.5879 49.7191 89.552 49.7551C89.4203 51.2088 88.8814 52.6145 87.9712 54.0081C87.624 54.5127 87.2407 55.0173 86.7977 55.4978C85.66 56.7353 84.199 57.9127 82.3668 58.9218C75.5049 62.8264 65.6014 63.8836 56.9911 62.1056C55.7696 61.8533 54.5721 61.5529 53.4225 61.1805C51.1831 60.4837 49.0874 59.5826 47.2073 58.4533C45.4469 57.4081 44.0219 56.2307 42.9441 54.9932C42.4291 54.4766 42.0579 53.972 41.7106 53.4314C40.8843 52.1339 40.3814 50.8364 40.1898 49.5268C40.1299 49.1784 40.1299 48.8661 40.1299 48.5777C40.0341 44.7693 42.501 40.9969 47.5306 38.1976C57.1827 32.8273 72.7147 33.0195 82.3309 38.6782C86.8575 41.3453 89.2646 44.7092 89.552 48.1332L89.5879 49.623Z"
        fill="white"
      />
      <path
        d="M65.3858 63.5472C62.5477 63.5472 59.6736 63.2709 56.8714 62.6822C55.5301 62.4059 54.3326 62.0935 53.2429 61.7451C50.8957 61.0123 48.7641 60.0752 46.9079 58.9579C45.1356 57.9006 43.6506 56.6992 42.501 55.3777C42.0459 54.9212 41.6508 54.4286 41.2196 53.7438C40.3574 52.3862 39.8185 50.9926 39.603 49.5989C39.5431 49.2145 39.5431 48.8661 39.5431 48.5657C39.4353 44.3608 42.1777 40.4803 47.2552 37.657C57.051 32.2026 72.9303 32.4308 82.6422 38.1495C87.1809 40.8167 89.8394 44.3488 90.1627 48.0731L90.1987 49.5989C90.1987 49.7191 90.1867 49.8272 90.1508 49.9113C89.9951 51.401 89.4562 52.8427 88.4862 54.3204C88.0671 54.9332 87.6599 55.4378 87.2527 55.8943C85.9953 57.2639 84.4505 58.4533 82.6662 59.4384C77.9479 62.1176 71.7687 63.5472 65.3978 63.5472H65.3858ZM64.3679 34.9057C58.3683 34.9057 52.4046 36.1672 47.83 38.7262C43.1596 41.3212 40.6448 44.8173 40.7406 48.5657C40.7406 48.8541 40.7406 49.1304 40.7885 49.4307C40.9682 50.6682 41.4472 51.9056 42.2256 53.107C42.6088 53.7077 42.9561 54.1523 43.3752 54.5727C44.4769 55.8342 45.8541 56.9515 47.5187 57.9367C49.303 59.0059 51.3508 59.907 53.6021 60.6038C54.6679 60.9522 55.8056 61.2405 57.1109 61.5169C65.793 63.307 75.5888 62.0815 82.0674 58.3932C83.744 57.4681 85.181 56.3628 86.3546 55.0893C86.7258 54.6809 87.097 54.2123 87.4803 53.6597C88.3545 52.3261 88.8335 51.0286 88.9532 49.695C88.9532 49.647 88.9652 49.5989 88.9891 49.5509L88.9532 48.1452C88.6778 44.8534 86.2109 41.6576 82.0315 39.1948C77.1815 36.3474 70.7508 34.9057 64.3679 34.9057Z"
        fill="#455068"
      />
      <path
        d="M89.5879 62.8624C89.5879 62.8624 89.5879 62.9586 89.552 63.0186C89.4202 64.4483 88.8813 65.878 87.9712 67.2476C86.7617 69.0497 84.8936 70.7317 82.3668 72.1613C75.5049 76.1019 65.6013 77.1472 56.9911 75.3691C55.7696 75.1168 54.5721 74.8044 53.4225 74.444V61.1805C54.5721 61.5529 55.7696 61.8533 56.9911 62.1056C65.6013 63.8836 75.5049 62.8264 82.3668 58.9218C84.199 57.9127 85.66 56.7353 86.7976 55.4978C87.2407 55.0173 87.6239 54.5127 87.9712 54.0081C88.8813 52.6145 89.4202 51.2088 89.552 49.7551V61.4087L89.5879 62.8624Z"
        fill="#455068"
      />
      <path
        d="M65.3858 76.8108C62.5477 76.8108 59.6736 76.5344 56.8714 75.9457C55.6499 75.6934 54.4284 75.3811 53.2428 75.0086C52.9914 74.9366 52.8237 74.6963 52.8237 74.432V61.1685C52.8237 60.9762 52.9195 60.796 53.0752 60.6879C53.2309 60.5798 53.4344 60.5437 53.6141 60.6038C54.6679 60.9402 55.8175 61.2405 57.1228 61.5169C65.8049 63.307 75.6007 62.0815 82.0794 58.3932C83.7559 57.4681 85.193 56.3628 86.3665 55.0893C86.7378 54.6809 87.109 54.2123 87.4922 53.6597C88.3664 52.3261 88.8454 51.0286 88.9652 49.695C88.9891 49.3707 89.2286 49.1184 89.5879 49.1544C89.9112 49.1664 90.1627 49.4307 90.1627 49.7551L90.1986 62.8504C90.1986 62.9345 90.1986 63.0426 90.1507 63.1748C89.9951 64.6645 89.4322 66.1543 88.4862 67.584C87.1809 69.5302 85.2289 71.2482 82.6781 72.6899C77.9718 75.3931 71.7926 76.8228 65.3978 76.8228L65.3858 76.8108ZM54.0212 73.9875C55.0272 74.2878 56.069 74.5521 57.1109 74.7684C65.9486 76.5945 75.5169 75.3931 82.0674 71.6327C84.4625 70.2751 86.2707 68.6893 87.4683 66.8992C88.3305 65.6016 88.8215 64.2801 88.9532 62.9465C88.9532 62.8985 88.9652 62.8504 88.9891 62.8024L88.9532 61.4087V53.5275C88.8095 53.7918 88.6418 54.0561 88.4742 54.3204C88.0551 54.9332 87.6479 55.4378 87.2407 55.8943C85.9833 57.2639 84.4385 58.4533 82.6542 59.4384C75.8402 63.319 65.9606 64.5564 56.8714 62.6822C55.8415 62.466 54.8954 62.2377 54.0212 61.9854V73.9875ZM88.9891 62.8624L89.5879 62.8504H88.9891V62.8624Z"
        fill="#455068"
      />
      <path
        d="M53.4225 61.1805V74.444C51.1831 73.7472 49.0874 72.8461 47.2073 71.7168C44.7164 70.191 42.8722 68.485 41.7106 66.6709C40.8843 65.3974 40.3814 64.0639 40.1898 62.7663V49.5269C40.3814 50.8364 40.8843 52.1339 41.7106 53.4314C42.0579 53.9721 42.4291 54.4766 42.9441 54.9932C44.0219 56.2307 45.4469 57.4081 47.2073 58.4533C49.0874 59.5826 51.1831 60.4837 53.4225 61.1805Z"
        fill="white"
      />
      <path
        d="M53.4225 75.0447C53.4225 75.0447 53.3027 75.0447 53.2428 75.0207C50.8957 74.2878 48.7521 73.3507 46.8959 72.2334C44.3452 70.6716 42.4291 68.9055 41.2077 66.9953C40.3574 65.6737 39.8065 64.2801 39.603 62.8504V49.5268C39.603 49.2145 39.8425 48.9502 40.1538 48.9261C40.4772 48.9021 40.7406 49.1304 40.7885 49.4427C40.9682 50.6682 41.4472 51.8936 42.2256 53.107C42.6088 53.7077 42.9561 54.1523 43.3752 54.5727C44.4769 55.8342 45.8541 56.9515 47.5186 57.9367C49.303 59.0059 51.3507 59.907 53.6021 60.6038C53.8536 60.6759 54.0212 60.9162 54.0212 61.1805V74.444C54.0212 74.6362 53.9254 74.8164 53.7817 74.9246C53.674 74.9966 53.5542 75.0447 53.4225 75.0447ZM40.7885 53.047V62.7663C40.9682 63.9317 41.4472 65.1691 42.2136 66.3465C43.3393 68.1006 45.1236 69.7345 47.5186 71.2122C49.0874 72.1493 50.8717 72.9663 52.8237 73.627V61.625C50.6442 60.9162 48.6443 60.0271 46.8959 58.9699C45.1236 57.9127 43.6386 56.7112 42.489 55.3897C42.034 54.9332 41.6388 54.4406 41.2077 53.7558C41.064 53.5155 40.9202 53.2872 40.7885 53.047Z"
        fill="#455068"
      />
      <path
        d="M46.9079 64.0638C46.9079 64.2441 46.872 64.4002 46.8121 64.5204C46.7642 64.6045 46.7163 64.6766 46.6445 64.7246C46.6325 64.7366 46.6085 64.7486 46.5966 64.7607C46.393 64.8928 46.1176 64.8688 45.8062 64.6886C45.2074 64.3282 44.7284 63.4752 44.7284 62.7783C44.7284 62.4419 44.8362 62.2137 45.0158 62.0815L45.0997 62.0335C45.1835 61.9974 45.2793 61.9734 45.3751 61.9854C45.5068 61.9854 45.6625 62.0455 45.8182 62.1296C46.2493 62.3819 46.6205 62.8865 46.8002 63.4151C46.872 63.6313 46.9199 63.8596 46.9199 64.0638H46.9079Z"
        fill="#455068"
      />
      <path
        d="M46.3092 65.4455C46.0577 65.4455 45.7703 65.3614 45.4948 65.2052C44.7045 64.7366 44.1177 63.6914 44.1177 62.7783C44.1177 62.2617 44.3093 61.8412 44.6566 61.5889C44.6685 61.5889 44.7643 61.5169 44.7763 61.5169C44.7763 61.5169 44.8122 61.4928 44.8362 61.4808C45.0038 61.3967 45.1955 61.3607 45.399 61.3727C45.6385 61.3847 45.8661 61.4568 46.1056 61.601C46.6445 61.9133 47.1235 62.526 47.351 63.2108C47.4468 63.4992 47.4947 63.7875 47.4947 64.0638C47.4947 64.3402 47.4348 64.5925 47.3271 64.7967C47.2552 64.9409 47.1474 65.085 47.0157 65.1932C46.9798 65.2172 46.8959 65.2773 46.848 65.3013C46.6924 65.3974 46.5008 65.4575 46.2972 65.4575L46.3092 65.4455ZM45.3511 62.5861C45.3511 62.5861 45.3152 62.6822 45.3152 62.7783C45.3152 63.2709 45.6864 63.9197 46.1056 64.172C46.2373 64.2441 46.2972 64.2441 46.2972 64.2441C46.2732 64.2441 46.2972 64.184 46.2972 64.0518C46.2972 63.9197 46.2732 63.7635 46.2134 63.6073C46.0816 63.1988 45.7942 62.8264 45.4948 62.6582C45.411 62.6101 45.3631 62.5981 45.3392 62.5861H45.3511Z"
        fill="#455068"
      />
      <path
        d="M89.5879 26.7843C89.5879 26.7843 89.5879 26.8804 89.552 26.9165C89.4203 28.3702 88.8814 29.7758 87.9712 31.1695C87.624 31.6741 87.2407 32.1786 86.7977 32.6592C85.66 33.8967 84.199 35.074 82.3668 36.0832C75.5049 39.9878 65.6014 41.045 56.9911 39.2669C55.7696 39.0146 54.5721 38.7143 53.4225 38.3419C51.1831 37.645 49.0874 36.744 47.2073 35.6147C45.4469 34.5694 44.0219 33.3921 42.9441 32.1546C42.4291 31.638 42.0579 31.1334 41.7106 30.5928C40.8843 29.2953 40.3814 27.9978 40.1898 26.6882C40.1299 26.3398 40.1299 26.0274 40.1299 25.7391C40.0341 21.9307 42.501 18.1582 47.5306 15.359C57.1827 9.98869 72.7147 10.1809 82.3309 15.8395C86.8575 18.5067 89.2646 21.8706 89.552 25.2946L89.5879 26.7843Z"
        fill="white"
      />
      <path
        d="M65.3858 40.7086C62.5477 40.7086 59.6736 40.4323 56.8714 39.8436C55.5301 39.5673 54.3326 39.2549 53.2429 38.9065C50.8957 38.1737 48.7641 37.2366 46.9079 36.1192C45.1356 35.074 43.6506 33.8606 42.501 32.5391C42.0459 32.0825 41.6508 31.59 41.2196 30.9051C40.3574 29.5476 39.8185 28.1539 39.603 26.7603C39.5431 26.3759 39.5431 26.0274 39.5431 25.7271C39.4353 21.5222 42.1777 17.6416 47.2552 14.8183C57.051 9.36395 72.9303 9.59222 82.6422 15.3109C87.1809 17.978 89.8394 21.5102 90.1627 25.2345L90.1987 26.7603C90.1987 26.8804 90.1867 26.9886 90.1508 27.0727C89.9951 28.5624 89.4562 30.0041 88.4862 31.4818C88.0671 32.0945 87.6599 32.5991 87.2527 33.0557C85.9953 34.4253 84.4505 35.6147 82.6662 36.5998C77.9479 39.2789 71.7687 40.7086 65.3978 40.7086H65.3858ZM64.3679 12.0671C58.3683 12.0671 52.4046 13.3286 47.83 15.8876C43.1596 18.4826 40.6448 21.9787 40.7406 25.7271C40.7406 26.0154 40.7406 26.2918 40.7885 26.5921C40.9682 27.8296 41.4472 29.067 42.2256 30.2684C42.6088 30.8691 42.9561 31.3136 43.3752 31.7341C44.4769 32.9956 45.8541 34.1129 47.5187 35.0981C49.303 36.1673 51.3508 37.0684 53.6021 37.7652C54.6679 38.1136 55.8056 38.4019 57.1109 38.6782C65.793 40.4683 75.5888 39.2429 82.0674 35.5546C83.744 34.6295 85.181 33.5242 86.3546 32.2507C86.7258 31.8422 87.097 31.3737 87.4803 30.8211C88.3545 29.4875 88.8335 28.19 88.9532 26.8564C88.9532 26.8084 88.9652 26.7603 88.9891 26.7122L88.9532 25.3066C88.6778 22.0148 86.2109 18.819 82.0315 16.3561C77.1815 13.5088 70.7508 12.0671 64.3679 12.0671Z"
        fill="#455068"
      />
      <path
        d="M89.5879 40.0238C89.5879 40.0238 89.5879 40.1199 89.552 40.18C89.4202 41.6097 88.8813 43.0393 87.9712 44.4089C86.7617 46.211 84.8936 47.893 82.3668 49.3227C75.5049 53.2633 65.6013 54.3085 56.9911 52.5304C55.7696 52.2781 54.5721 51.9658 53.4225 51.6054V38.3418C54.5721 38.7143 55.7696 39.0146 56.9911 39.2669C65.6013 41.045 75.5049 39.9878 82.3668 36.0832C84.199 35.074 85.66 33.8966 86.7976 32.6592C87.2407 32.1786 87.6239 31.674 87.9712 31.1694C88.8813 29.7758 89.4202 28.3702 89.552 26.9165V38.5701L89.5879 40.0238Z"
        fill="#455068"
      />
      <path
        d="M65.3858 53.9721C62.5477 53.9721 59.6736 53.6958 56.8714 53.1071C55.6499 52.8548 54.4284 52.5425 53.2428 52.17C52.9914 52.0979 52.8237 51.8577 52.8237 51.5933V38.3298C52.8237 38.1376 52.9195 37.9574 53.0752 37.8493C53.2309 37.7411 53.4344 37.7051 53.6141 37.7652C54.6679 38.1016 55.8175 38.4019 57.1228 38.6782C65.8049 40.4683 75.6007 39.2429 82.0794 35.5546C83.7559 34.6295 85.193 33.5242 86.3665 32.2507C86.7378 31.8422 87.109 31.3737 87.4922 30.821C88.3664 29.4875 88.8454 28.19 88.9652 26.8564C88.9891 26.532 89.2286 26.3038 89.5879 26.3158C89.9112 26.3278 90.1627 26.5921 90.1627 26.9165L90.1986 40.0118C90.1986 40.0959 90.1986 40.204 90.1507 40.3362C89.9951 41.8259 89.4322 43.3157 88.4862 44.7453C87.1809 46.6916 85.2289 48.4096 82.6781 49.8513C77.9718 52.5545 71.7926 53.9841 65.3978 53.9841L65.3858 53.9721ZM54.0212 51.1488C55.0272 51.4492 56.069 51.7135 57.1109 51.9297C65.9486 53.7559 75.5169 52.5545 82.0674 48.7941C84.4625 47.4365 86.2707 45.8506 87.4683 44.0605C88.3305 42.763 88.8215 41.4415 88.9532 40.1079C88.9532 40.0599 88.9652 40.0118 88.9891 39.9637L88.9532 38.5701V30.6889C88.8095 30.9532 88.6418 31.2175 88.4742 31.4818C88.0551 32.0945 87.6479 32.5991 87.2407 33.0557C85.9833 34.4253 84.4385 35.6147 82.6542 36.5998C75.8402 40.4803 65.9606 41.7178 56.8714 39.8436C55.8415 39.6273 54.8954 39.3991 54.0212 39.1468V51.1488Z"
        fill="#455068"
      />
      <path
        d="M53.4225 38.3418V51.6054C51.1831 50.9086 49.0874 50.0075 47.2073 48.8782C44.7164 47.3524 42.8722 45.6464 41.7106 43.8323C40.8843 42.5588 40.3814 41.2252 40.1898 39.9277V26.6882C40.3814 27.9977 40.8843 29.2953 41.7106 30.5928C42.0579 31.1334 42.4291 31.638 42.9441 32.1546C44.0219 33.3921 45.4469 34.5694 47.2073 35.6147C49.0874 36.744 51.1831 37.645 53.4225 38.3418Z"
        fill="white"
      />
      <path
        d="M53.4225 52.2061C53.4225 52.2061 53.3027 52.2061 53.2428 52.182C50.8957 51.4492 48.7521 50.5121 46.8959 49.3948C44.3452 47.833 42.4291 46.0669 41.2077 44.1567C40.3574 42.8351 39.8065 41.4415 39.603 40.0118V26.6882C39.603 26.3758 39.8425 26.1115 40.1538 26.0875C40.4772 26.0755 40.7406 26.2918 40.7885 26.6041C40.9682 27.8295 41.4472 29.055 42.2256 30.2684C42.6088 30.8691 42.9561 31.3136 43.3752 31.7341C44.4769 32.9956 45.8541 34.1129 47.5186 35.0981C49.303 36.1673 51.3507 37.0684 53.6021 37.7652C53.8536 37.8373 54.0212 38.0775 54.0212 38.3418V51.6054C54.0212 51.7976 53.9254 51.9778 53.7817 52.0859C53.674 52.158 53.5542 52.2061 53.4225 52.2061ZM40.7885 30.2083V39.9277C40.9682 41.0931 41.4472 42.3305 42.2136 43.5079C43.3393 45.2619 45.1236 46.8959 47.5186 48.3736C49.0874 49.3107 50.8717 50.1276 52.8237 50.7884V38.7864C50.6322 38.0775 48.6443 37.1885 46.8959 36.1313C45.1236 35.074 43.6386 33.8726 42.489 32.5511C42.034 32.0945 41.6388 31.602 41.2077 30.9172C41.064 30.6769 40.9202 30.4486 40.7885 30.2083Z"
        fill="#455068"
      />
      <path
        d="M46.9079 41.2252C46.9079 41.4054 46.872 41.5616 46.8121 41.6818C46.7642 41.7659 46.7163 41.838 46.6445 41.886C46.6325 41.898 46.6085 41.91 46.5966 41.922C46.393 42.0542 46.1176 42.0302 45.8062 41.85C45.2074 41.4895 44.7284 40.6365 44.7284 39.9397C44.7284 39.6033 44.8362 39.3751 45.0158 39.2429L45.0997 39.1949C45.1835 39.1588 45.2793 39.1348 45.3751 39.1468C45.5068 39.1468 45.6625 39.2069 45.8182 39.291C46.2493 39.5433 46.6205 40.0479 46.8002 40.5765C46.872 40.7927 46.9199 41.021 46.9199 41.2252H46.9079Z"
        fill="#455068"
      />
      <path
        d="M46.3092 42.6068C46.0577 42.6068 45.7703 42.5227 45.4948 42.3666C44.7045 41.898 44.1177 40.8528 44.1177 39.9397C44.1177 39.4231 44.3093 39.0026 44.6566 38.7503C44.6685 38.7503 44.7643 38.6783 44.7763 38.6783C44.7763 38.6783 44.8122 38.6542 44.8362 38.6422C45.0158 38.5581 45.1955 38.5221 45.411 38.5341C45.6385 38.5461 45.878 38.6302 46.1176 38.7624C46.6564 39.0747 47.1355 39.6874 47.363 40.3722C47.4588 40.6606 47.5067 40.9489 47.5067 41.2252C47.5067 41.5016 47.4468 41.7538 47.339 41.9581C47.2672 42.1023 47.1594 42.2464 47.0277 42.3546C46.9918 42.3786 46.9079 42.4387 46.86 42.4627C46.7043 42.5588 46.5127 42.6189 46.3092 42.6189V42.6068ZM45.3511 39.7475C45.3511 39.7475 45.3152 39.8436 45.3152 39.9397C45.3152 40.4323 45.6864 41.0811 46.1056 41.3334C46.2373 41.4054 46.2972 41.4054 46.2972 41.4054C46.2732 41.4054 46.2972 41.3454 46.2972 41.2132C46.2972 41.0811 46.2732 40.9249 46.2134 40.7687C46.0816 40.3602 45.7942 39.9878 45.4948 39.8196C45.411 39.7715 45.3631 39.7595 45.3392 39.7475H45.3511Z"
        fill="#455068"
      />
      <path
        d="M89.9113 99.0247C89.8993 101.716 89.0012 103.662 87.5521 104.695L86.8935 105.08L83.1812 107.206C83.7081 106.774 84.1751 106.209 84.5224 105.488C85.0373 104.491 85.3128 103.206 85.3367 101.68C85.3487 99.9497 85.0014 98.1236 84.3907 96.3095L89.1209 94.1349C89.6478 95.7809 89.9352 97.4508 89.9113 99.0247Z"
        fill="#455068"
      />
      <path
        d="M83.1811 107.807C82.9895 107.807 82.7979 107.711 82.6902 107.543C82.5105 107.29 82.5584 106.93 82.8099 106.738C83.3009 106.341 83.6961 105.825 83.9955 105.224C84.4865 104.275 84.7379 103.085 84.7499 101.668C84.7619 100.07 84.4625 98.3278 83.8398 96.4897C83.744 96.2014 83.8757 95.877 84.1511 95.7568L88.8814 93.5823C89.0371 93.5102 89.2167 93.5102 89.3724 93.5823C89.5281 93.6544 89.6478 93.7865 89.6957 93.9427C90.2586 95.6968 90.534 97.4028 90.522 99.0247C90.4981 101.812 89.576 103.998 87.9114 105.188L83.4805 107.735C83.3847 107.783 83.2889 107.819 83.1811 107.819V107.807ZM85.1211 96.6339C85.672 98.4119 85.9474 100.106 85.9355 101.692C85.9235 103.085 85.696 104.311 85.2648 105.32L87.2647 104.179C88.5581 103.254 89.3005 101.415 89.3245 99.0247C89.3364 97.7271 89.1448 96.3696 88.7497 94.9759L85.1331 96.6459L85.1211 96.6339Z"
        fill="#455068"
      />
      <path
        d="M89.1089 94.1349L84.3787 96.3095C82.9057 91.9484 79.8161 87.7555 76.2594 85.7011C74.9541 84.9322 73.7087 84.5357 72.583 84.4756C71.7208 84.4276 70.9424 84.5597 70.2598 84.8721L74.2715 82.5294L74.6667 82.3011C76.2714 81.4721 78.4509 81.6523 80.834 83.046C84.5344 85.1965 87.7198 89.6176 89.1089 94.1469V94.1349Z"
        fill="white"
      />
      <path
        d="M84.3907 96.9102C84.3068 96.9102 84.235 96.8982 84.1512 96.8621C83.9955 96.79 83.8757 96.6699 83.8158 96.5017C82.3429 92.1646 79.2652 88.1279 75.96 86.2177C74.7625 85.5209 73.6129 85.1244 72.5471 85.0643C71.7807 85.0163 71.0981 85.1364 70.5113 85.4127C70.2239 85.5449 69.8766 85.4368 69.7329 85.1484C69.5892 84.8601 69.685 84.5117 69.9604 84.3555L74.3673 81.7725C76.2594 80.7993 78.6545 81.0636 81.1454 82.5173C84.8577 84.6679 88.2108 89.1611 89.6838 93.9547C89.7676 94.2431 89.6358 94.5554 89.3604 94.6756L84.6302 96.8501C84.5463 96.8862 84.4625 96.9102 84.3787 96.9102H84.3907ZM73.086 83.911C74.1997 84.0551 75.3733 84.4756 76.5708 85.1845C80.0077 87.1668 83.0854 91.0834 84.7379 95.4925L88.3904 93.8106C86.9294 89.4855 83.8877 85.4968 80.5466 83.5626C78.427 82.3251 76.4391 82.0608 74.9541 82.8297L73.0979 83.923L73.086 83.911Z"
        fill="#455068"
      />
      <path
        d="M85.3367 101.68C85.3247 103.206 85.0373 104.491 84.5224 105.488C84.1751 106.209 83.708 106.774 83.1811 107.206C83.0494 107.326 82.8937 107.422 82.762 107.495C81.0855 108.54 78.7503 108.432 76.1636 106.894C71.2058 103.95 67.1941 96.8741 67.1941 91.0593C67.1941 88.3201 68.0683 86.3378 69.5293 85.3046L70.1879 84.9081C70.1879 84.9081 70.2238 84.8721 70.2598 84.8721C70.9424 84.5597 71.7208 84.4276 72.583 84.4756C73.7087 84.5477 74.9541 84.9442 76.2594 85.7011C79.828 87.7675 82.9057 91.9604 84.3787 96.3095C84.9894 98.1236 85.3367 99.9497 85.3247 101.68H85.3367Z"
        fill="white"
      />
      <path
        d="M80.2831 108.792C78.9179 108.792 77.409 108.336 75.8642 107.41C70.7508 104.371 66.5953 97.0423 66.5953 91.0593C66.5953 88.212 67.5174 85.9894 69.182 84.812L69.8766 84.3915C69.8766 84.3915 69.9604 84.3435 69.9963 84.3315C70.7627 83.971 71.6489 83.8149 72.6069 83.8749C73.8644 83.947 75.1936 84.3915 76.5588 85.1845C80.1514 87.2629 83.3727 91.4558 84.9535 96.1293C85.6121 98.0876 85.9474 99.9618 85.9355 101.704C85.9235 103.314 85.6241 104.683 85.0613 105.789C84.702 106.533 84.1871 107.182 83.5644 107.687C83.4207 107.819 83.2171 107.951 83.0374 108.035C82.2231 108.54 81.301 108.792 80.2831 108.792ZM70.4993 85.4247L69.8406 85.8212C68.5353 86.7463 67.7929 88.6205 67.7929 91.0593C67.7929 96.6699 71.6848 103.542 76.475 106.377C78.7742 107.735 80.8939 107.951 82.4506 106.978C82.5944 106.906 82.7021 106.834 82.786 106.75C83.2889 106.329 83.6961 105.825 83.9835 105.224C84.4745 104.275 84.726 103.085 84.7379 101.668C84.7499 100.07 84.4505 98.3278 83.8278 96.4897C82.3548 92.1526 79.2772 88.1159 75.972 86.2057C74.7745 85.5088 73.6248 85.1124 72.559 85.0523C71.7926 85.0043 71.11 85.1244 70.5232 85.4007C70.5232 85.4007 70.5232 85.4007 70.5113 85.4007L70.4993 85.4247Z"
        fill="#455068"
      />
      <path d="M31.2562 75.1407L20.2389 81.5202L31.2562 87.8876L42.2615 81.5202L31.2562 75.1407Z" fill="white" />
      <path
        d="M31.2562 88.4883C31.1484 88.4883 31.0526 88.4643 30.9568 88.4042L19.9395 82.0248C19.7599 81.9166 19.6401 81.7244 19.6401 81.5082C19.6401 81.2919 19.7599 81.0997 19.9395 80.9916L30.9568 74.6121C31.1364 74.504 31.3759 74.504 31.5556 74.6121L42.5729 80.9916C42.7525 81.0997 42.8722 81.2919 42.8722 81.5082C42.8722 81.7244 42.7525 81.9166 42.5729 82.0248L31.5556 88.4042C31.4598 88.4523 31.364 88.4883 31.2562 88.4883ZM21.4364 81.5082L31.2562 87.1908L41.0759 81.5082L31.2562 75.8255L21.4364 81.5082Z"
        fill="#455068"
      />
      <path d="M31.2562 87.8876V99.7695L20.2389 93.3901V81.5202L31.2562 87.8876Z" fill="white" />
      <path
        d="M31.2562 100.37C31.1484 100.37 31.0526 100.346 30.9568 100.286C23.4124 95.921 19.6401 93.6223 19.6401 93.3901V81.5082C19.6401 81.2919 19.7599 81.0997 19.9395 80.9916C20.1191 80.8834 20.3587 80.8834 20.5383 80.9916L31.5556 87.371C31.7352 87.4792 31.855 87.6714 31.855 87.8876V99.7695C31.855 99.9858 31.7352 100.178 31.5556 100.286C31.4598 100.334 31.364 100.37 31.2562 100.37ZM20.8377 93.0417C22.2508 93.8586 28.3342 97.3907 30.6574 98.7243V88.236L20.8377 82.5534V93.0417Z"
        fill="#455068"
      />
      <path d="M42.2615 81.5202V93.3901L31.2562 99.7695V87.8876L42.2615 81.5202Z" fill="#455068" />
      <path
        d="M31.2562 100.37C31.1484 100.37 31.0526 100.346 30.9568 100.286C30.7772 100.178 30.6574 99.9858 30.6574 99.7695V87.8876C30.6574 87.6714 30.7772 87.4792 30.9568 87.371L41.9741 80.9916C42.1537 80.8834 42.3932 80.8834 42.5729 80.9916C42.7525 81.0997 42.8722 81.2919 42.8722 81.5082V93.3901C42.8722 93.6063 42.7525 93.7985 42.5729 93.9067L31.5556 100.286C31.4598 100.334 31.364 100.37 31.2562 100.37ZM31.855 88.236V98.7243L41.6747 93.0417V82.5534L31.855 88.236Z"
        fill="#455068"
      />
      <path d="M73.05 7.59773L62.0327 13.9772L73.05 20.3567L84.0553 13.9772L73.05 7.59773Z" fill="white" />
      <path
        d="M73.05 20.9573C72.9422 20.9573 72.8464 20.9333 72.7506 20.8732L61.7333 14.4938C61.5537 14.3856 61.434 14.1934 61.434 13.9772C61.434 13.7609 61.5537 13.5687 61.7333 13.4606L72.7506 7.08109C72.9303 6.97297 73.1698 6.97297 73.3494 7.08109L84.3547 13.4606C84.5343 13.5687 84.6541 13.7609 84.6541 13.9772C84.6541 14.1934 84.5343 14.3856 84.3547 14.4938L73.3494 20.8732C73.2536 20.9213 73.1578 20.9573 73.05 20.9573ZM63.2303 13.9772L73.05 19.6598L82.8578 13.9772L73.05 8.29451L63.2303 13.9772Z"
        fill="#455068"
      />
      <path d="M73.05 20.3567V32.2265L62.0327 25.8471V13.9772L73.05 20.3567Z" fill="white" />
      <path
        d="M73.05 32.8272C72.9422 32.8272 72.8464 32.8032 72.7506 32.7431L61.7333 26.3637C61.5537 26.2555 61.434 26.0633 61.434 25.8471V13.9772C61.434 13.7609 61.5537 13.5687 61.7333 13.4606C61.913 13.3525 62.1525 13.3525 62.3321 13.4606L73.3494 19.84C73.529 19.9482 73.6488 20.1404 73.6488 20.3566V32.2385C73.6488 32.4548 73.529 32.647 73.3494 32.7551C73.2536 32.8032 73.1578 32.8392 73.05 32.8392V32.8272ZM62.6315 25.4987L72.4512 31.1813V20.7051L62.6315 15.0224V25.5107V25.4987Z"
        fill="#455068"
      />
      <path d="M84.0553 13.9772V25.8471L73.05 32.2265V20.3567L84.0553 13.9772Z" fill="#455068" />
      <path
        d="M73.05 32.8272C72.9422 32.8272 72.8464 32.8032 72.7506 32.7431C72.571 32.635 72.4512 32.4428 72.4512 32.2265V20.3566C72.4512 20.1404 72.571 19.9482 72.7506 19.84L83.7559 13.4606C83.9356 13.3525 84.1751 13.3525 84.3547 13.4606C84.5343 13.5687 84.6541 13.7609 84.6541 13.9772V25.8471C84.6541 26.0633 84.5343 26.2555 84.3547 26.3637L73.3494 32.7431C73.2536 32.7912 73.1578 32.8272 73.05 32.8272ZM73.6488 20.693V31.1813L83.4565 25.4987V15.0104L73.6488 20.693Z"
        fill="#455068"
      />
      <path
        d="M63.4099 26.4359C63.4099 27.7935 62.9548 28.7787 62.2243 29.2953L61.889 29.4875L60.0089 30.5688C60.2723 30.3525 60.5118 30.0642 60.6915 29.7037C60.9549 29.1992 61.0986 28.5504 61.0986 27.7815C61.0986 26.9045 60.931 25.9794 60.6196 25.0663L63.0027 23.961C63.2662 24.79 63.4099 25.631 63.4099 26.4359Z"
        fill="#455068"
      />
      <path
        d="M60.0089 31.1695C59.8173 31.1695 59.6257 31.0733 59.5179 30.9052C59.3383 30.6529 59.3862 30.2924 59.6376 30.1002C59.8532 29.92 60.0328 29.7037 60.1526 29.4394C60.3801 28.9949 60.4999 28.4423 60.4999 27.7695C60.4999 27.0006 60.3562 26.1476 60.0568 25.2585C59.961 24.9702 60.0927 24.6458 60.3681 24.5257L62.7512 23.4204C62.9069 23.3483 63.0865 23.3483 63.2422 23.4204C63.3979 23.4925 63.5176 23.6246 63.5655 23.7808C63.8649 24.6939 64.0086 25.5829 63.9967 26.4359C63.9967 27.9377 63.4817 29.1271 62.5596 29.7878L60.2963 31.0854C60.2005 31.1334 60.1047 31.1695 59.9969 31.1695H60.0089ZM61.3501 25.3907C61.5896 26.2317 61.7094 27.0246 61.6974 27.7815C61.6974 28.214 61.6495 28.6105 61.5657 28.9829L61.9129 28.7787C62.4638 28.3822 62.7991 27.5412 62.7991 26.4359C62.7991 25.9073 62.7392 25.3667 62.6075 24.814L61.3381 25.4027L61.3501 25.3907Z"
        fill="#455068"
      />
      <path
        d="M63.0027 23.961L60.6196 25.0663C59.8771 22.8678 58.3204 20.7533 56.5121 19.7081C55.8534 19.3236 55.2187 19.1194 54.6559 19.0833C54.2248 19.0593 53.8296 19.1194 53.4823 19.2876L55.5062 18.0982L55.7097 17.978C56.5241 17.5575 57.6138 17.6537 58.8233 18.3505C60.6915 19.4317 62.2962 21.6664 63.0027 23.949V23.961Z"
        fill="white"
      />
      <path
        d="M60.6196 25.667C60.5358 25.667 60.4639 25.655 60.3801 25.619C60.2244 25.5469 60.1047 25.4267 60.0448 25.2586C59.3263 23.1321 57.8174 21.1618 56.2127 20.2247C55.6499 19.8883 55.111 19.7081 54.62 19.684C54.2847 19.66 53.9853 19.7081 53.7338 19.8282C53.4464 19.9604 53.0991 19.8402 52.9554 19.5639C52.8117 19.2756 52.9075 18.9271 53.183 18.771L55.4104 17.4734C56.4642 16.9208 57.7815 17.065 59.1227 17.8459C61.0627 18.9752 62.8111 21.3059 63.5655 23.7928C63.6494 24.0812 63.5176 24.3935 63.2422 24.5137L60.8591 25.619C60.7753 25.655 60.6915 25.679 60.6076 25.679L60.6196 25.667ZM55.7097 18.6869C56.069 18.807 56.4402 18.9752 56.8234 19.1915C58.5359 20.1766 60.0807 22.0868 60.9669 24.2494L62.2722 23.6487C61.5297 21.6303 60.0927 19.7922 58.5359 18.8791C57.5539 18.3024 56.6558 18.1823 55.9971 18.5187L55.7217 18.6869H55.7097Z"
        fill="#455068"
      />
      <path
        d="M61.0986 27.7815C61.0986 28.5504 60.943 29.1992 60.6915 29.7037C60.5118 30.0642 60.2843 30.3525 60.0089 30.5688C59.937 30.6288 59.8652 30.6769 59.8053 30.7129C58.9551 31.2415 57.7815 31.1815 56.4762 30.4126C53.9733 28.9228 51.9495 25.3547 51.9495 22.4112C51.9495 21.0296 52.3926 20.0204 53.1351 19.5038L53.4704 19.2996C53.4704 19.2996 53.4823 19.2876 53.5063 19.2876C53.8536 19.1314 54.2488 19.0593 54.6799 19.0833C55.2547 19.1194 55.8774 19.3236 56.536 19.7081C58.3323 20.7533 59.8891 22.8678 60.6436 25.0663C60.9549 25.9794 61.1346 26.9045 61.1226 27.7815H61.0986Z"
        fill="white"
      />
      <path
        d="M58.5599 31.662C57.8174 31.662 56.9911 31.4218 56.1648 30.9292C53.4584 29.3193 51.3387 25.5829 51.3387 22.4112C51.3387 20.8734 51.8537 19.672 52.7758 19.0112L53.147 18.783C53.147 18.783 53.2069 18.7469 53.2308 18.7349C53.662 18.5307 54.1649 18.4466 54.6918 18.4826C55.3744 18.5187 56.0929 18.759 56.8234 19.1794C58.6916 20.2607 60.3681 22.4473 61.1944 24.8621C61.5417 25.8833 61.7094 26.8684 61.6974 27.7695C61.6974 28.6225 61.5297 29.3674 61.2184 29.9681C61.0148 30.3765 60.7394 30.737 60.3801 31.0253C60.2963 31.0974 60.1765 31.1815 60.0568 31.2416C59.6257 31.5179 59.1107 31.65 58.5479 31.65L58.5599 31.662ZM53.7458 19.8282L53.4224 20.0204C52.8596 20.4169 52.5363 21.2819 52.5363 22.4112C52.5363 25.1504 54.4404 28.5023 56.7755 29.896C57.8413 30.5207 58.7994 30.6288 59.482 30.2083L59.6257 30.1122C59.8412 29.932 60.0208 29.7158 60.1406 29.4515C60.3681 29.0069 60.4879 28.4543 60.4879 27.7815C60.4879 27.0126 60.3442 26.1596 60.0448 25.2706C59.3263 23.1441 57.8174 21.1738 56.2127 20.2367C55.6499 19.9003 55.111 19.7201 54.62 19.696C54.2847 19.684 53.9973 19.7201 53.7458 19.8402V19.8282Z"
        fill="#455068"
      />
      <path
        d="M35.9984 79.0213C35.9984 80.1746 35.6032 81.0156 34.9805 81.4601L34.6931 81.6283L33.1004 82.5414C33.3279 82.3612 33.5315 82.1089 33.6752 81.8086C33.8907 81.376 34.0225 80.8234 34.0225 80.1746C34.0225 79.4298 33.8788 78.6489 33.6153 77.8679L35.6511 76.9308C35.8786 77.6397 35.9984 78.3605 35.9984 79.0333V79.0213Z"
        fill="#455068"
      />
      <path
        d="M33.1004 83.1421C32.9088 83.1421 32.7172 83.046 32.6094 82.8778C32.4297 82.6255 32.4776 82.2651 32.7291 82.0729C32.8968 81.9287 33.0405 81.7485 33.1483 81.5442C33.3399 81.1838 33.4357 80.7153 33.4357 80.1626C33.4357 79.5139 33.3159 78.805 33.0644 78.0482C32.9686 77.7598 33.1004 77.4354 33.3758 77.3153L35.4116 76.3782C35.5673 76.3061 35.7469 76.3061 35.9026 76.3782C36.0583 76.4503 36.178 76.5824 36.2259 76.7386C36.4774 77.5316 36.6091 78.3005 36.5972 79.0333C36.5972 80.3428 36.1421 81.3761 35.3278 81.9527L33.3997 83.07C33.3039 83.1181 33.2081 83.1541 33.1004 83.1541V83.1421ZM34.3458 78.1803C34.5374 78.8771 34.6332 79.5379 34.6212 80.1746C34.6212 80.475 34.5973 80.7513 34.5494 81.0156L34.6811 80.9435C35.1122 80.6312 35.3876 79.9344 35.3876 79.0213C35.3876 78.6128 35.3517 78.1923 35.2559 77.7598L34.3458 78.1803Z"
        fill="#455068"
      />
      <path
        d="M35.6631 76.9188L33.6273 77.8559C32.9926 75.9817 31.6633 74.1796 30.1305 73.2906C29.5676 72.9662 29.0287 72.786 28.5497 72.762C28.1785 72.7379 27.8432 72.798 27.5558 72.9302L29.2802 71.921L29.4479 71.8249C30.1425 71.4644 31.0765 71.5485 32.1064 72.1492C33.6991 73.0743 35.0643 74.9726 35.6631 76.9188Z"
        fill="white"
      />
      <path
        d="M33.6273 78.4566C33.5434 78.4566 33.4716 78.4446 33.3878 78.4086C33.2321 78.3365 33.1123 78.2163 33.0525 78.0481C32.4417 76.2581 31.1843 74.5881 29.8311 73.8072C29.3641 73.5309 28.921 73.3867 28.5138 73.3507C28.2384 73.3386 27.9989 73.3747 27.8073 73.4708C27.5199 73.603 27.1726 73.4948 27.0289 73.2065C26.8852 72.9181 26.981 72.5697 27.2564 72.4136L29.1485 71.3083C30.0946 70.8277 31.2322 70.9478 32.4058 71.6326C34.0704 72.5938 35.5792 74.6121 36.2379 76.7506C36.3217 77.039 36.19 77.3513 35.9146 77.4715L33.8788 78.4086C33.7949 78.4446 33.7111 78.4686 33.6273 78.4686V78.4566ZM29.6515 72.4015C29.9029 72.4977 30.1664 72.6178 30.4299 72.774C31.8789 73.615 33.1962 75.2128 33.9506 77.039L34.9086 76.5944C34.2739 74.9245 33.0884 73.4107 31.795 72.6659C30.9807 72.1973 30.2502 72.0772 29.7113 72.3535L29.6395 72.3895L29.6515 72.4015Z"
        fill="#455068"
      />
      <path
        d="M34.0344 80.1626C34.0344 80.8234 33.9027 81.376 33.6871 81.7965C33.5315 82.1089 33.3399 82.3492 33.1123 82.5294C33.0524 82.5775 32.9926 82.6255 32.9327 82.6495C32.2142 83.1061 31.2082 83.046 30.0945 82.3972C27.9629 81.1358 26.2385 78.0842 26.2385 75.5853C26.2385 74.4079 26.6217 73.5549 27.2444 73.1104L27.5318 72.9422C27.5318 72.9422 27.5438 72.9302 27.5558 72.9302C27.8432 72.798 28.1785 72.7379 28.5497 72.762C29.0407 72.786 29.5676 72.9662 30.1305 73.2906C31.6633 74.1796 32.9926 75.9817 33.6273 77.8559C33.8907 78.6368 34.0464 79.4178 34.0344 80.1626Z"
        fill="white"
      />
      <path
        d="M31.8669 83.5626C31.2202 83.5626 30.5017 83.3464 29.7712 82.9259C27.436 81.5442 25.6158 78.3245 25.6158 75.5973C25.6158 74.2637 26.0589 73.2065 26.8732 72.6298L27.1965 72.4376C27.1965 72.4376 27.2564 72.4015 27.2804 72.3895C27.6636 72.2093 28.0947 72.1252 28.5617 72.1613C29.1605 72.1973 29.7832 72.4015 30.4059 72.774C32.0226 73.7111 33.4596 75.5853 34.1662 77.6637C34.4655 78.5407 34.6092 79.3937 34.6092 80.1746C34.6092 80.9195 34.4655 81.5563 34.1901 82.0849C34.0105 82.4453 33.771 82.7577 33.4596 83.01C33.3878 83.082 33.28 83.1541 33.1722 83.2022C32.789 83.4425 32.3339 83.5626 31.8549 83.5626H31.8669ZM27.7594 73.4948L27.5318 73.639C27.0768 73.9634 26.8133 74.6602 26.8133 75.5973C26.8133 77.904 28.406 80.7273 30.382 81.8927C31.2562 82.4093 32.0465 82.5054 32.5974 82.157C32.5974 82.157 32.7052 82.0849 32.7171 82.0729C32.8848 81.9287 33.0285 81.7485 33.1363 81.5442C33.3279 81.1838 33.4237 80.7153 33.4237 80.1626C33.4237 79.5139 33.3039 78.805 33.0525 78.0482C32.4417 76.2581 31.1843 74.5881 29.8311 73.8072C29.3641 73.5309 28.921 73.3867 28.5138 73.3507C28.2384 73.3386 27.9989 73.3747 27.8073 73.4708C27.8073 73.4708 27.7833 73.4708 27.7713 73.4828L27.7594 73.4948Z"
        fill="#455068"
      />
    </svg>
  );
}
