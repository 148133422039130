import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const LayoutContent = styled(NvFlex)`
  flex: 1 1 auto;
  min-height: 0;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
`;

export const LayoutContentInner = styled(NvFlex)`
  padding: 32px 24px 60px 24px;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
`;
