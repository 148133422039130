import { NvAxios, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_USERS_IN_WORKSPACE } from '../use-get-users-in-workspace/keys';
import { RemoveUserParams } from './types';

const removeUser = async (params: RemoveUserParams) => {
  const result = await NvAxios.delete<{
    userId: string;
  }>(`/v1/workspaces/users/${params.userId}`);
  return result?.data;
};

export const useRemoveUserFromWorkspace = () => {
  const cache = useQueryClient();
  const { addToast } = useToast();
  return useMutation(removeUser, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS_USERS_IN_WORKSPACE.lists());
    },
    onError: () => {
      addToast('User could not be deleted', {
        variant: 'error',
      });
    },
  });
};
