import {
  AppSchemasPayload,
  AppSchemasQuerySortOption,
  useDeleteSchema,
  useGetAppSchemaDetail,
  useGetAppSchemas,
  useGetAvailableSchemaTags,
  useUpdateSchemaTags,
} from '@novaera/actioner-service';
import {
  FieldTitle,
  NvAutocomplete,
  NvButton,
  NvCloseIcon,
  NvDivider,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvForm,
  NvSelect,
  NvSkeleton,
  NvTextField,
  NvTypography,
  useConfirmDialog,
  useToast,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC, useMemo, useState } from 'react';
import { AppMetadataUpdate } from './app-metadata-update';

type TagUpdateForm = {
  tags: string[];
};
type AppDirectoryOperations = 'delete-app' | 'update-tags' | 'update-metadata';

export const AppSettings: FC = () => {
  const [selectedAppSchemaId, setSelectedAppSchemaId] = useState<string>('');
  const [selectedOperation, setSelectedOperation] = useState<AppDirectoryOperations>('update-tags');
  const { data: availableTags } = useGetAvailableSchemaTags();
  const { mutate: updateTags } = useUpdateSchemaTags();
  const { mutate: deleteSchema } = useDeleteSchema();

  const searchQuerySchemeBody = useMemo<AppSchemasPayload | undefined>(
    () => ({
      pagination: null,
      sortParams: {
        sortOption: AppSchemasQuerySortOption.BY_NAME,
        sortOrder: 'asc',
      },
      queries: [],
    }),
    []
  );

  const { data: getAppSchemasQueries, isInitialLoading } = useGetAppSchemas({
    payload: searchQuerySchemeBody,
  });

  const { data: appSchema, isInitialLoading: isGetAppSchemaDetailLoading } = useGetAppSchemaDetail({
    schemaId: selectedAppSchemaId,
    isAnonymous: false,
  });

  const { addToast } = useToast();

  const handleChangeTags = (values: TagUpdateForm) => {
    assert(!!selectedAppSchemaId, new Error('[AppSettings/handleChangeTags] - schemaId cannot be undefined!'), 'ERROR');
    return new Promise<void>((resolve) => {
      updateTags(
        { schemaId: selectedAppSchemaId, payload: { tags: values.tags } },
        {
          onSuccess: () => {
            addToast('App tags update successfully!', { variant: 'success' });
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  const { openConfirm } = useConfirmDialog();

  const handleDeleteApp = () => {
    openConfirm({
      message: 'Are you sure?',
      onConfirm: () => {
        assert(
          !!selectedAppSchemaId,
          new Error('[AppSettings/handleDeleteApp] - schemaId cannot be undefined!'),
          'ERROR'
        );
        deleteSchema({ schemaId: selectedAppSchemaId });
      },
    });
  };

  return (
    <NvFlex gap={'16px'}>
      <NvTypography variant="h1">App directory settings</NvTypography>
      {isInitialLoading ? (
        <>
          <NvSkeleton />
        </>
      ) : (
        <NvFlex gap={'8px'}>
          <NvFlex direction="row" gap="8px" maxWidth="530px">
            <FieldTitle direction="label-on-side" labelText="App" hasRequiredIndicator labelWidth="90px" />
            <NvSelect<string>
              value={selectedAppSchemaId}
              options={getAppSchemasQueries?.pages[0].result.schemas.map((appSchema) => ({
                label: appSchema.name,
                value: appSchema.id,
              }))}
              onChange={(e) => {
                setSelectedAppSchemaId(e.target.value);
              }}
              fullWidth
            />
          </NvFlex>
          <NvFlex direction="row" gap="8px" maxWidth="530px">
            <FieldTitle direction="label-on-side" labelText="Operation" hasRequiredIndicator labelWidth="90px" />
            <NvSelect
              value={selectedOperation}
              options={[
                { label: 'Update tags', value: 'update-tags' },
                { label: 'Update metadata', value: 'update-metadata' },
                { label: 'Delete app', value: 'delete-app' },
              ]}
              onChange={(e) => {
                setSelectedOperation(e.target.value as AppDirectoryOperations);
              }}
              fullWidth
            />
          </NvFlex>
          {selectedOperation === 'update-tags' && (
            <NvFlex maxWidth="530px" gap={'8px'}>
              <NvDivider />
              <NvForm<TagUpdateForm>
                onSubmit={handleChangeTags}
                key={selectedAppSchemaId}
                keepDirtyOnReinitialize
                initialValues={{ tags: appSchema?.tags.map((t) => t.label) ?? [] }}
              >
                {({ submitting }) => (
                  <NvFlex gap={'8px'}>
                    <NvField
                      name="tags"
                      labelText="Tags"
                      isAutoComplete
                      allowNull
                      component={
                        <NvAutocomplete
                          options={availableTags?.tags ?? []}
                          getOptionLabel={(option) => option}
                          renderInput={(props) => <NvTextField {...props} placeholder="Select tags" />}
                          clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
                          popupIcon={<NvExpandMoreIcon />}
                          multiple
                        />
                      }
                    />

                    <NvFlex flexDirection={'row-reverse'} gap="16px">
                      <NvButton loading={submitting} disabled={submitting} type="submit">
                        Change Tag
                      </NvButton>
                    </NvFlex>
                  </NvFlex>
                )}
              </NvForm>
            </NvFlex>
          )}
          {selectedOperation === 'update-metadata' && (
            <>
              <NvDivider />
              {isGetAppSchemaDetailLoading || !appSchema ? (
                <NvFlex gap="8px">
                  <NvSkeleton variant="rectangular" height="32px"></NvSkeleton>
                  <NvSkeleton variant="rectangular" height="32px"></NvSkeleton>
                  <NvSkeleton variant="rectangular" height="32px"></NvSkeleton>
                </NvFlex>
              ) : (
                <AppMetadataUpdate key={selectedAppSchemaId} appSchema={appSchema} />
              )}
            </>
          )}
          {selectedOperation === 'delete-app' && (
            <NvFlex flexDirection={'row-reverse'} gap="16px">
              <NvButton color="error" onClick={() => handleDeleteApp()}>
                Delete App
              </NvButton>
            </NvFlex>
          )}
        </NvFlex>
      )}
    </NvFlex>
  );
};
