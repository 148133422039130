import { Option } from '@novaera/ah-common';
import { assert } from '@novaera/utils';
import { differenceBy, isEqual } from 'lodash';
import { useMemo } from 'react';
import { NvBox } from '../box';
import { NvCheckbox } from '../checkbox';
import { NvTypography } from '../typography';
import { NvCheckboxGroupProps } from './types';

export const NvCheckboxGroup = ({ value, onChange, options }: NvCheckboxGroupProps): React.ReactElement => {
  const finalOptions = useMemo(() => {
    const unIdentifiedOptions = differenceBy(value, options, 'identifier');
    const result = [...options, ...unIdentifiedOptions].map((option) => {
      return {
        ...option,
        checked: Boolean(value?.find((v) => isEqual(v?.identifier, option.identifier))),
      };
    });
    return result;
  }, [options, value]);

  const handleOnChange = (index: number, checked: boolean) => {
    const valueToUpdate = finalOptions?.[index];

    let newValue: Option[];
    if (valueToUpdate) {
      if (!checked) {
        assert(
          !!value,
          new Error('When there is an uncheck operation there should be a value containing unchecked value'),
          'ERROR'
        );

        newValue = value.filter((v) => v.identifier !== valueToUpdate?.identifier);
      } else {
        newValue = [...(value ?? []), valueToUpdate];
      }
      onChange?.(newValue);
    }
  };

  return (
    <NvBox
      display="flex"
      rowGap="8px"
      flexDirection="column"
      data-testid="checkbox-group"
      className="checkbox-group-container"
    >
      {finalOptions?.map(({ identifier, displayValue, checked }, index) => (
        <NvBox
          display="flex"
          justifyContent="start"
          flexDirection="row"
          key={`${JSON.stringify(identifier)}-${index}`}
          gap="6px"
          alignItems="center"
        >
          <NvCheckbox
            size="small"
            value={identifier}
            onChange={(e, checked) => handleOnChange(index, checked)}
            checked={checked}
          />
          <NvTypography variant="body2">{displayValue}</NvTypography>
        </NvBox>
      ))}
    </NvBox>
  );
};
