import { CodeInput } from '@novaera/core';
import { assert } from '@novaera/utils';
import React from 'react';
import { useIntegrationCodeFieldContext } from './controllers/use-integration-code-field-context';

import { IntegrationCodeFieldProps } from './types';

export const IntegrationCodeField: React.FC<React.PropsWithChildren<IntegrationCodeFieldProps>> = (props) => {
  assert(
    typeof props.value === 'string',
    new Error('type of value for the code input should be string but it is:' + typeof props.value),
    'WARNING'
  );

  const { codeFieldContext } = useIntegrationCodeFieldContext();

  return <CodeInput {...props} context={codeFieldContext} />;
};
