import {
  SlackBlockTypes,
  WorkflowReferenceColumn,
  WorkflowReferenceElement,
  WorkflowReferencesBlock,
} from '@novaera/actioner-service';
import { Context, NvAddBoxIcon, NvButton } from '@novaera/core';
import { FC, useMemo } from 'react';
import {
  PropertyPanelEmptySection,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
} from '../../../../../../../../../../../../components';
import { useWorkflowReferenceBlock } from '../../../../../../../../../../../../components/ui-components/slack-blocks-component/workflow-reference-block/use-workflow-reference-block';
import { WorkflowReferenceItems } from '../../../../../../../../../../../../components/workflow-reference-items';

export type WorkflowReferenceAdapterProps = {
  buttonColumn?: WorkflowReferenceColumn;
  onChange?: (value?: WorkflowReferenceColumn) => void;
  context?: Context;
};

export const WorkflowReferenceAdapter: FC<React.PropsWithChildren<WorkflowReferenceAdapterProps>> = ({
  buttonColumn,
  onChange,
  context,
}) => {
  const block = useMemo(() => {
    if (buttonColumn) {
      const elements: WorkflowReferenceElement[] = buttonColumn?.workflowReferences?.map((workflowReference) => {
        return workflowReference;
      });

      const workflowReferenceBlock: WorkflowReferencesBlock = {
        type: SlackBlockTypes.WORKFLOW_REFERENCES,
        elements,
        label: buttonColumn.name,
      };
      return workflowReferenceBlock;
    } else {
      const workflowReferenceBlock: WorkflowReferencesBlock = {
        type: SlackBlockTypes.WORKFLOW_REFERENCES,
        elements: [],
        label: 'Actions',
      };
      return workflowReferenceBlock;
    }
  }, [buttonColumn]);

  const handleWorkflowReferenceBlockChange = (block: WorkflowReferencesBlock) => {
    if (block.elements.length > 0) {
      const newWorkflowReferenceColumn: WorkflowReferenceColumn = {
        type: 'workflow-reference',
        name: 'Actions',
        workflowReferences: block.elements.map((element) => {
          return element;
        }),
      };

      onChange?.(newWorkflowReferenceColumn);
    } else {
      onChange?.();
    }
  };

  const { handleAddButtonElement } = useWorkflowReferenceBlock({
    block,
    onChange: handleWorkflowReferenceBlockChange,
    index: 0,
  });

  return (
    <>
      <PropertyPanelListHeader
        title="BUTTON COLUMN"
        tooltip="Add a column with a workflow button for quick run of workflows on your table."
        actions={
          <NvButton size="small" color="ghost" onlyIcon variant="contained" onClick={handleAddButtonElement}>
            <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
          </NvButton>
        }
      />
      {!block.elements?.length && <PropertyPanelEmptySection emptyText="There is no button column." />}
      {block.elements?.length > 0 && (
        <PropertyPanelSimpleSection>
          <WorkflowReferenceItems
            context={context}
            block={block}
            index={0}
            onChange={handleWorkflowReferenceBlockChange}
          />
        </PropertyPanelSimpleSection>
      )}
    </>
  );
};
