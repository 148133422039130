import {
  NvFlex,
  NvLinkedInIcon,
  NvProductLogoWithNameIcon,
  NvSlackIconNotColored,
  NvTwitterIcon,
  NvTypography,
  NvYouTubeIcon,
} from '@novaera/core';
import { PropsWithChildren } from 'react';
import {
  CopyRightSection,
  PublicFooterBody,
  PublicFooterBottom,
  PublicFooterInner,
  PublicFooterLink,
  PublicFooterLinks,
  PublicFooterLogoLink,
  PublicFooterMenu,
  PublicFooterMenuDivider,
  PublicFooterMenuLink,
  PublicFooterMenuWrapper,
  PublicFooterSocialMediaIconLink,
  PublicFooterSocialMediaLinks,
  PublicFooterTitle,
  PublicFooterWrapper,
  TermsAndConditionsWrapper,
} from './styled';

export const PublicFooter: React.FC<PropsWithChildren<unknown>> = () => {
  const currentYear = new Date().getFullYear();
  return (
    <PublicFooterWrapper>
      <PublicFooterInner>
        <PublicFooterBody>
          <NvFlex marginRight="60px" gap="16px">
            <PublicFooterLogoLink href="https://actioner.com" height="50px">
              <NvProductLogoWithNameIcon height={30} width={120} textColor="#fff" />
            </PublicFooterLogoLink>
          </NvFlex>
          <PublicFooterMenuWrapper>
            <PublicFooterMenu>
              <PublicFooterTitle variant="h3" fontWeight={700}>
                Product
              </PublicFooterTitle>
              <PublicFooterMenuDivider />
              <PublicFooterMenuLink href="https://actioner.com">
                <NvTypography variant="h2" fontWeight={500}>
                  Home
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/app-directory">
                <NvTypography variant="h2" fontWeight={500}>
                  App directory
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/security">
                <NvTypography variant="h2" fontWeight={500}>
                  Security
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/pricing">
                <NvTypography variant="h2" fontWeight={500}>
                  Pricing
                </NvTypography>
              </PublicFooterMenuLink>
            </PublicFooterMenu>
            <PublicFooterMenu>
              <PublicFooterTitle variant="h3" fontWeight={700}>
                Solutions
              </PublicFooterTitle>
              <PublicFooterMenuDivider />
              <PublicFooterMenuLink href="https://actioner.com/solutions/sales">
                <NvTypography variant="h2" fontWeight={500}>
                  Revenue operations
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/solutions/help-desk">
                <NvTypography variant="h2" fontWeight={500}>
                  Help desk
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/solutions/incident-management">
                <NvTypography variant="h2" fontWeight={500}>
                  Incident management
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/solutions/pull-request-management">
                <NvTypography variant="h2" fontWeight={500}>
                  Pull request reviews
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/solutions/continuous-integration-and-delivery">
                <NvTypography variant="h2" fontWeight={500}>
                  Continuous integration & delivery
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/solutions/jira-software">
                <NvTypography variant="h2" fontWeight={500}>
                  Jira software
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/solutions/celebrations">
                <NvTypography variant="h2" fontWeight={500}>
                  Celebrations
                </NvTypography>
              </PublicFooterMenuLink>
            </PublicFooterMenu>
            <PublicFooterMenu>
              <PublicFooterTitle variant="h3" fontWeight={700}>
                Resources
              </PublicFooterTitle>
              <PublicFooterMenuDivider />
              <PublicFooterMenuLink href="https://actioner.com/blog">
                <NvTypography variant="h2" fontWeight={500}>
                  Blog
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/guides">
                <NvTypography variant="h2" fontWeight={500}>
                  Guides
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/help/actioner-basics">
                <NvTypography variant="h2" fontWeight={500}>
                  Help center
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/use-cases">
                <NvTypography variant="h2" fontWeight={500}>
                  Use cases
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/compare">
                <NvTypography variant="h2" fontWeight={500}>
                  Compare
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/compare/slack">
                <NvTypography variant="h2" fontWeight={500}>
                  Actioner{' '}
                  <span role="img" aria-label="love">
                    ❤️
                  </span>{' '}
                  Slack
                </NvTypography>
              </PublicFooterMenuLink>
            </PublicFooterMenu>
            <PublicFooterMenu>
              <PublicFooterTitle variant="h3" fontWeight={700}>
                Company
              </PublicFooterTitle>
              <PublicFooterMenuDivider />
              <PublicFooterMenuLink href="https://actioner.com/contact">
                <NvTypography variant="h2" fontWeight={500}>
                  Contact us
                </NvTypography>
              </PublicFooterMenuLink>
              <PublicFooterMenuLink href="https://actioner.com/community">
                <NvTypography variant="h2" fontWeight={500}>
                  Community
                </NvTypography>
              </PublicFooterMenuLink>
            </PublicFooterMenu>
          </PublicFooterMenuWrapper>
        </PublicFooterBody>
        <PublicFooterBottom>
          <CopyRightSection>
            <NvTypography variant="h5">Copyright © Actioner | {currentYear} Actioner, All rights reserved</NvTypography>
            <TermsAndConditionsWrapper>
              <PublicFooterLink href="https://actioner.com/terms-conditions">
                <NvTypography variant="h5">Terms & Conditions</NvTypography>
              </PublicFooterLink>
              <PublicFooterLink href="https://actioner.com/privacy-policy">
                <NvTypography variant="h5">Privacy Policy</NvTypography>
              </PublicFooterLink>
              <PublicFooterLink href="https://actioner.com/disclaimer">
                <NvTypography variant="h5">Disclaimer</NvTypography>
              </PublicFooterLink>
            </TermsAndConditionsWrapper>
          </CopyRightSection>

          <PublicFooterLinks>
            <PublicFooterSocialMediaLinks>
              <PublicFooterSocialMediaIconLink href="https://twitter.com/actionercom" target="_blank">
                <NvTwitterIcon />
              </PublicFooterSocialMediaIconLink>
              <PublicFooterSocialMediaIconLink href="https://www.linkedin.com/company/82078026" target="_blank">
                <NvLinkedInIcon />
              </PublicFooterSocialMediaIconLink>
              <PublicFooterSocialMediaIconLink
                href="https://www.youtube.com/channel/UCIh738Xga0L3cbaqMVU4l9Q/videos"
                target="_blank"
              >
                <NvYouTubeIcon />
              </PublicFooterSocialMediaIconLink>
              <PublicFooterSocialMediaIconLink href="https://actioner.com/community" target="_blank">
                <NvSlackIconNotColored />
              </PublicFooterSocialMediaIconLink>
            </PublicFooterSocialMediaLinks>
          </PublicFooterLinks>
        </PublicFooterBottom>
      </PublicFooterInner>
    </PublicFooterWrapper>
  );
};
