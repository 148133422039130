import { Integration, Trigger, useSaveToDraftWorkflow, WorkflowWithState } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { useNovaeraFlow } from '../../../../../../../../user-app-workflow-canvas/use-novaera-flow';
import { userAppGraph } from '../../../../../../../graph-utils/user-app-graph';
import { usePropertyPanelContext } from '../../../../../../provider';

export const usePanelHeaderController = ({ workflow }: { workflow: WorkflowWithState; integration?: Integration }) => {
  const { mutate, isLoading } = useSaveToDraftWorkflow();
  const { selectedNode } = usePropertyPanelContext();
  const { updateNode } = useNovaeraFlow(userAppGraph);

  const handleNameChanged = async (name?: string) => {
    assert(!!name, new Error('Name is required'), 'ERROR');

    return new Promise<void>((resolve, reject) => {
      assert(
        !!workflow?.trigger,
        new Error('Since name has changed there should be a trigger in the workflow'),
        'ERROR'
      );
      const newTrigger: Trigger = { ...workflow.trigger, name };
      mutate(
        {
          ...workflow,
          trigger: newTrigger,
        },
        {
          onSuccess: () => {
            if (selectedNode) {
              const graphNode = userAppGraph.node(selectedNode.alias);
              const newGraphNode = cloneDeep(graphNode);
              newGraphNode.name = name;
              updateNode({ newNode: newGraphNode });
            }
          },
        }
      );
      resolve();
    });
  };

  return {
    onNameChanged: handleNameChanged,
    isLoading,
  };
};
