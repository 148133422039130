import {
  DynamicInputComponent,
  DynamicInputSourceActionInWorkflow,
  UIComponentType,
  useGetIntegration,
  useGetIntegrationAction,
  useGetWorkflow,
} from '@novaera/actioner-service';
import {
  NvActionFilledIcon,
  NvAddBoxIcon,
  NvButton,
  NvChip,
  NvDeleteOutlineIcon,
  NvFlex,
  NvImage,
  NvPopover,
  NvTypography,
  useField,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { IntegrationInitialLogo } from '../../../../../../../../../../integrations/components/integration-initial-logo';

import { useParams } from '@novaera/route';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { FC, useMemo } from 'react';
import {
  ActionParameterMapper,
  ActionSearchField,
  PropertyPanelEmptySection,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
  TestActionProvider,
  TestActionResult,
} from '../../../../../../../../../../components';
import { useFormIdentifierContext } from '../../../../../../../../../../providers/form-identifier-provider';
import { IntegrationSelect } from '../../../../../../components/integration-select';
import { useGetWorkflowContexts } from '../../../../../../controllers/use-get-workflow-contexts';
import { IntegrationBox } from '../../../../integration-trigger-property-panel-drawer/integration-trigger-properties/styled';
import { hasIntegrationActionSpecialInput } from '../../utils';

export const FormTriggerDynamicInput: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    input: { value, onChange },
  } = useField<DynamicInputComponent>('uiComponent');

  const dataSource = useMemo(() => value.dataSource, [value.dataSource]);
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  assert(
    dataSource?.type === 'workflow',
    new Error('[FormTriggerDynamicInput] UIComponent->dataSource->type should be workflow'),
    'ERROR'
  );
  const { formId } = useFormIdentifierContext();
  const { data: integration } = useGetIntegration({ id: dataSource.integrationId });
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const { data: selectedAction } = useGetIntegrationAction({
    integrationId: dataSource.integrationId,
    actionId: dataSource.actionId,
    version: dataSource.version,
  });

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'dynamic-input-action-select-popup',
  });

  const integrationActionHasSpecialInput = useMemo(() => {
    return !!selectedAction?.inputParameters?.find((i) => hasIntegrationActionSpecialInput(i.uiComponent.type));
  }, [selectedAction?.inputParameters]);

  assert(
    value.type === UIComponentType.DYNAMIC_INPUT,
    new Error('[FormTriggerDynamicInput] UIComponent->type should be dynamic'),
    'ERROR'
  );
  assert(!!workflow, new Error('[FormTriggerDynamicInput] component need a workflow'), 'ERROR');

  return (
    <>
      <PropertyPanelSection
        icon={<NvActionFilledIcon />}
        title="Action"
        {...(dataSource.integrationId && {
          actions: (
            <NvButton
              onlyIcon
              color="ghost"
              size="small"
              onClick={() => {
                const newDataSource: DynamicInputSourceActionInWorkflow = {
                  ...dataSource,
                  integrationId: undefined,
                  version: undefined,
                  actionId: undefined,
                  parameterMappings: [],
                };

                onChange({ ...value, dataSource: newDataSource });
              }}
            >
              <NvDeleteOutlineIcon />
            </NvButton>
          ),
        })}
      >
        {dataSource.integrationId ? (
          <NvFlex gap="8px">
            <IntegrationBox>
              <NvImage
                FallBack={<IntegrationInitialLogo name={integration?.name ?? ''} size="small" />}
                src={integration?.logoUrl}
                imageShape="square"
                size="smaller"
                className="integration-image"
              />
              <NvTypography variant="h5" textColor="main" flex="1 1 auto">
                {integration?.name}
              </NvTypography>
              {integration?.scope.type === 'workspace' ? <NvChip label="custom" compact /> : undefined}
              <NvTypography variant="body2" textColor="subtle">
                v{dataSource.version}.0.0
              </NvTypography>
            </IntegrationBox>
            <ActionSearchField
              integrationId={dataSource.integrationId}
              fieldName={`uiComponent.dataSource.actionId`}
              version={dataSource.version}
            />
          </NvFlex>
        ) : (
          <>
            <NvButton
              startIcon={<NvAddBoxIcon fontSize="small" />}
              color="secondary"
              size="small"
              {...bindTrigger(popupState)}
            >
              Add Action
            </NvButton>
            <NvPopover {...bindPopover(popupState)}>
              <IntegrationSelect
                onSelectIntegration={(integration) => {
                  popupState.close();
                  const dataSource: DynamicInputComponent['dataSource'] = {
                    ...value.dataSource,
                    integrationId: integration.id,
                    version: integration.latestVersion.number,
                    type: 'workflow',
                  };
                  onChange({ ...value, dataSource });
                }}
              />
            </NvPopover>
          </>
        )}
      </PropertyPanelSection>
      {integrationActionHasSpecialInput ? (
        <PropertyPanelEmptySection emptyText="The selected action cannot be used as a dynamic fields." />
      ) : (
        <TestActionProvider>
          {dataSource.actionId && dataSource.integrationId && (
            <>
              {/* Open it after test with sample context feature will be implemented */}
              {/* <TestActionButton
              testActionParams={{
                formId,
                actionId: dataSource.actionId,
                integrationId: dataSource.integrationId,
                connectionId: dataSource.connectionId,
                versionNumber: dataSource.version,
                inputParameters: [],
                testRun: true,
              }}
            /> */}
              <PropertyPanelListHeader title="Action inputs" />
              <PropertyPanelSimpleSection>
                <ActionParameterMapper
                  type="in-workflow"
                  appId={userAppId}
                  workflowId={workflowId}
                  context={{
                    input: {
                      ...workflowCodeInputContext.input,
                    },
                    config: {
                      ...workflowCodeInputContext?.config,
                    },
                  }}
                  actionId={dataSource.actionId}
                  integrationId={dataSource.integrationId}
                  versionNumber={dataSource.version ?? 1}
                  onParameterMappingsChanged={({ inputParametersValues: values, shouldAddFormId }) => {
                    const dataSource: DynamicInputComponent['dataSource'] = {
                      ...value.dataSource,
                      parameterMappings: values,
                      formId: shouldAddFormId ? formId : undefined,
                    };
                    onChange({ ...value, dataSource });
                  }}
                  initialParameterMappings={value.dataSource.parameterMappings}
                />
              </PropertyPanelSimpleSection>
              <TestActionResult
                sampleResponse={selectedAction?.responseConfiguration?.exampleResponse}
                tooltip="The response displayed below is solely a sample."
                // tooltip="The response displayed below is solely a sample. To see the actual response using real data from your tool, test the action."
              />
            </>
          )}
        </TestActionProvider>
      )}
    </>
  );
};
