import { QUERY_KEY_GET_MANIFEST } from '../keys';

import { NvAxios, useQuery } from '@novaera/core';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { GetDefaultManifestResponse } from './types';

const getDefaultManifest = async (appId?: string) => {
  const result = await NvAxios.get<GetDefaultManifestResponse>(`${SLACK_APP_ROOT_PATH(appId)}/default-manifest`);
  return result?.data;
};

export const useGetDefaultManifest = ({ appId, enabled = false }: { appId?: string; enabled?: boolean }) =>
  useQuery(QUERY_KEY_GET_MANIFEST.detail(appId), () => getDefaultManifest(appId), { enabled: !!appId && enabled });
