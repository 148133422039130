import { NvBox, NvInlineEdit } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const InstructionsInlineEdit = styled(NvInlineEdit)`
  width: 100%;
  margin: 0;

  &.edit-mode {
    padding: 0;
    margin: 0;
    box-shadow: none;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.view-mode {
    overflow: auto;
    max-height: 312px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};

    .view-wrapper {
      align-items: flex-start;
      gap: 2px;

      .edit-button {
        opacity: 1;
        position: sticky;
        top: 0;
      }
    }
  }
` as typeof NvInlineEdit;

export const InstructionsWrapper = styled(NvBox)`
  width: 100%;
  margin: 0;
  overflow: auto;
  max-height: 312px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  padding: 8px;
`;
