import { DividerProps } from '@mui/material/Divider';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { StyledDivider } from './styled';

export type NvDividerProps = { borderColor?: string } & DividerProps;

export const NvDivider: React.FC<React.PropsWithChildren<NvDividerProps>> = ({ borderColor, ...props }) => {
  const { palette } = useTheme();
  return <StyledDivider borderColor={borderColor ?? palette.nv_neutral[30]} {...props} />;
};
