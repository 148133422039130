import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP_SETUP } from '../keys';
import { GetUserAppSetupParams, GetUserAppSetupResponse } from './types';

export const getUserAppSetup = async ({ appId }: GetUserAppSetupParams) => {
  const result = await NvAxios.get<GetUserAppSetupResponse>(`${USER_APP_ROOT_PATH}/${appId}/setup`);
  return result.data;
};

export const useGetUserAppSetup = ({ appId }: GetUserAppSetupParams) =>
  useQuery<GetUserAppSetupResponse>(QUERY_KEYS_USER_APP_SETUP.detail({ appId }), () => getUserAppSetup({ appId }), {
    enabled: !!appId,
  });
