import { NvAxios, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SUBSCRIPTIONS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_BILLING, QUERY_KEYS_SUBSCRIPTION } from '../keys';

const deleteScheduledSubscription = async () => {
  await NvAxios.delete<void>(`${SUBSCRIPTIONS_ROOT_PATH}/schedule`);
  return;
};

export const useDeleteScheduledSubscription = () => {
  const client = useQueryClient();
  const { addToast } = useToast();

  return useMutation(deleteScheduledSubscription, {
    onSuccess: () => {
      setTimeout(() => {
        client.invalidateQueries(QUERY_KEYS_BILLING.all);
        client.invalidateQueries(QUERY_KEYS_SUBSCRIPTION.all);
        addToast('Scheduled plan change is cancelled.', { variant: 'success' });
      }, 3000);
    },
  });
};
