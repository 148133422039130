import { DelayNode, useUpdateNode } from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';

import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ComponentHint } from '../../../../../../../..//components/ui-components/common/adapter/component-hint';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { DelayOperatorPanelProps } from './types';

export const DelayOperatorPanel = ({ onCloseClicked, delayNode }: DelayOperatorPanelProps) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();

  assert(
    !!delayNode,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  assert(
    delayNode.type === 'delay',
    new Error('Since the selected node in the graph is having type delay it should be delay in the workflow object'),
    'ERROR'
  );

  const [duration, setDuration] = useState<number | undefined>(delayNode.duration);

  const handleSave = useCallback(
    (newNode: DelayNode) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail({ appId: userAppId, workflowId, nodeAlias: delayNode.alias, payload: newNode });
    },
    [hasEditPermission, updateNodeDetail, userAppId, workflowId, delayNode.alias]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: delayNode.alias,
          payload: { ...delayNode, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(delayNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [delayNode, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const debouncedSave = useDebouncedCallback(handleSave, 500);

  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          title={delayNode.name}
          type={NodeType.DELAY}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: delayNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={delayNode.name}
          type={NodeType.DELAY}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <PropertyPanelSimpleSection>
        <NvTypography variant="body2">
          The delay node allows you to define intentional pauses or accommodate specific timing requirements before
          proceeding to the next step.
        </NvTypography>
      </PropertyPanelSimpleSection>
      <PropertyPanelSection title={'Delay amount'}>
        <NvFlex>
          <NvFlex direction="row" alignItems="center" gap="8px">
            <NvTextField
              type="number"
              inputProps={{ min: 0 }}
              fullWidth
              value={duration}
              onChange={(e) => {
                let newDuration;
                try {
                  newDuration = parseFloat(e.target.value);
                } catch (error) {
                  newDuration = undefined;
                }

                setDuration(newDuration);
                debouncedSave({ ...delayNode, duration: newDuration });
              }}
            />

            <NvTypography variant="h6">Seconds</NvTypography>
          </NvFlex>
          <ComponentHint hint={'You can delay the process for up to 120 seconds.'} />
        </NvFlex>
      </PropertyPanelSection>
    </>
  );
};
