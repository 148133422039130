import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppPropertyCardWrapper = styled(NvBox)`
  padding: 12px 16px 16px 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 12px;
  ${({ theme }) => theme.elevations.e100};
  width: 100%;
  height: auto;
`;

export const WorkflowTypeBox = styled('div')`
  height: 22px;
  padding: 5px 0 1px 0;
`;
