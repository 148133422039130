import { nvIsEqual, usePreviousPersistentWithMatcher } from '@novaera/utils';
import { useCallback, useEffect } from 'react';
import { FormProps, FormSpyRenderProps } from 'react-final-form';

export const DebouncedAutoSave = <F,>({
  debounce,
  onChange,
  values,
  initialValues,
  ...rest
}: FormSpyRenderProps<F> & {
  onChange?: FormProps<F>['onChange'];
  debounce: number;
}) => {
  const previous = usePreviousPersistentWithMatcher(values, (prev, next) => nvIsEqual(prev, next));

  const save = useCallback(() => {
    if (!nvIsEqual(values, previous) && !nvIsEqual(values, initialValues)) {
      onChange && onChange({ initialValues, values, ...rest });
    }
    // This is intentional
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (debounce) {
      const timeoutRef = setTimeout(save, debounce);
      return () => {
        if (timeoutRef) {
          clearTimeout(timeoutRef);
        }
      };
    }
    return;
  }, [debounce, save]);

  return null;
};
