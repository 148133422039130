import { NvButton, NvDivider, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { dividerClasses } from '@mui/material';
export const SignInWrapper = styled(NvFlex)`
  display: flex;
`;

export const TitleFlex = styled(NvFlex)``;

export const WelcomeMessageFlex = styled(NvFlex)`
  margin-top: 8px;
`;
export const SocialLoginFlex = styled(NvFlex)`
  position: relative;
  margin-top: 32px;
  row-gap: 16px;
`;

export const SocialButton = styled('button')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-color: transparent;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_main[30]};
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(10, 45, 98, 0.06);
  max-width: 240px;
  width: 240px;

  transition: background-color 0.4s ease, border-color 0.5s ease, box-shadow 0.6s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
    border: 1px solid ${({ theme }) => theme.palette.nv_main[30]};
  }
`;

export const CustomDivider = styled(NvDivider)`
  &.${dividerClasses.root} {
    border-color: ${({ theme }) => theme.palette.nv_neutral[30]};
  }
`;

export const SignInFormFlex = styled(NvFlex)``;

export const ContinueWithEmailButtonWrapper = styled(NvButton)`
  padding: 0 28px !important;
`;
