import { NvFlex, NvTypography } from '@novaera/core';
import { isUndefined } from 'lodash';
import { StyledSpan } from './styled';
import { InfoTexts } from './type';

export const ResponseSummary: React.FC<
  React.PropsWithChildren<{
    response: {
      statusCode?: string;
      durationInMillis?: number;
      contentLength?: number;
    };
  }>
> = ({ response }) => {
  const { statusCode, durationInMillis, contentLength } = response;
  const isSuccess = `${statusCode}`?.startsWith('2');
  return (
    <NvFlex direction="row" alignItems="center" spacing="12px">
      <NvTypography variant="h5">
        Status:{' '}
        <b>
          <StyledSpan infoType={isSuccess ? InfoTexts.SUCCESS : InfoTexts.ERROR}>{`${statusCode} ${
            isSuccess ? ' OK' : ''
          }`}</StyledSpan>
        </b>
      </NvTypography>
      {!isUndefined(durationInMillis) && (
        <NvTypography variant="h5">
          Time:{' '}
          <b>
            <StyledSpan infoType={InfoTexts.DURATION}>{durationInMillis} ms</StyledSpan>
          </b>
        </NvTypography>
      )}
      {!isUndefined(durationInMillis) && (
        <NvTypography variant="h5">
          Size:{' '}
          <b>
            <StyledSpan infoType={Number(contentLength) < 0 ? InfoTexts.NOT_AVAILABLE : InfoTexts.DURATION}>
              {Number(contentLength) < 0 ? 'N/A' : `${contentLength} B`}
            </StyledSpan>
          </b>
        </NvTypography>
      )}
    </NvFlex>
  );
};
