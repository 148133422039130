export const subscribeToCreateClientEvent = /* GraphQL */ `
  subscription SubscribeToCreateClientEvent($userId: ID!, $workspaceId: ID!) {
    subscribeToEvent(userId: $userId, workspaceId: $workspaceId) {
      type
      properties
      userId
      workspaceId
      id
    }
  }
`;
