import { useUpdateAssistantInstructions } from '@novaera/actioner-service';
import { NvAddBoxIcon, NvButton, NvTextArea, isRequired } from '@novaera/core';
import { useParams } from '@novaera/route';
import React from 'react';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { AIAssistantSection } from '../section';
import { InstructionView } from './instruction-view';
import { InstructionsInlineEdit, InstructionsWrapper } from './styled';
import { InstructionsProps } from './types';

export const Instructions: React.FC<InstructionsProps> = ({ assistantInstructions }) => {
  const { userAppId } = useParams();
  const { mutate: updateAssistantInstructions } = useUpdateAssistantInstructions();
  const handleInstructionsUpdate = async (value?: string) => {
    return new Promise<void>((resolve) => {
      updateAssistantInstructions(
        { appId: userAppId, instructions: value },
        {
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <AIAssistantSection
      title="Instructions"
      infoText="Use the instructions parameter to guide the personality of the Assistant and define its goals. A well-defined set of instructions results in a more effective AI assistant"
    >
      <FreeAppPermissionBoundary
        appId={userAppId}
        Fallback={
          <InstructionsWrapper>
            <InstructionView value={assistantInstructions} />
          </InstructionsWrapper>
        }
      >
        <InstructionsInlineEdit
          viewComponent={<InstructionView />}
          editComponent={({ currentValue, setCurrentValue }) => (
            <NvTextArea
              value={currentValue}
              onChange={(e) => setCurrentValue(e.target.value)}
              placeholder="Provide clear instructions"
              fullWidth
              minRows={5}
              maxRows={15}
            />
          )}
          value={assistantInstructions}
          validate={(value) => isRequired()(value)}
          onConfirm={handleInstructionsUpdate}
          renderCustomEmptyState={({ onClick }) => (
            <NvButton color="secondary" onClick={onClick} size="small" startIcon={<NvAddBoxIcon />}>
              Add instructions
            </NvButton>
          )}
        />
      </FreeAppPermissionBoundary>
    </AIAssistantSection>
  );
};
