import { CodeInput, NvButton, NvCloseIcon, NvField } from '@novaera/core';
import { FC } from 'react';
import { PropertyPanelHeader, PropertyPanelSimpleSection } from '../../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { HTTPPropertiesSubPanelProps } from '../types';

export type HTTPExampleResponseSubPanelProps = Omit<HTTPPropertiesSubPanelProps, 'panelType'>;

export const HTTPExampleResponseSubPanel: FC<HTTPExampleResponseSubPanelProps> = ({ onCloseClicked }) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  return (
    <>
      <PropertyPanelHeader
        title={'Example response'}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />
      <PropertyPanelSimpleSection>
        <NvField
          name={`exampleResponse`}
          component={
            <CodeInput context={workflowCodeInputContext} initialHeight="243px" placeholder="{{ ... }}" growingHeight />
          }
        />
      </PropertyPanelSimpleSection>
    </>
  );
};
