import { NvConditionalWrap, NvFlex } from '@novaera/core';
import React from 'react';
import { PropertyPanelHeader, PropertyPanelSection, PropertyPanelSimpleSection } from '../../../property-panel';
import { PropertyList } from '../property-list';
import { MainHeaderAndPropertySectionsProps } from './types';

export const MainHeaderAndPropertySections: React.FC<React.PropsWithChildren<MainHeaderAndPropertySectionsProps>> = ({
  headerActions,
  propertyTitle,
  propertySections,
}) => (
  <NvFlex height="100%" overflow="auto" paddingBottom="60px">
    {propertyTitle && (
      <PropertyPanelHeader title={propertyTitle?.label} icon={propertyTitle?.icon} actions={headerActions} />
    )}

    {propertySections &&
      propertySections.map(
        ({ title, propertyPropsList, isCollapsible, infoText, disableDefaultSectionWrapper }, index) => (
          <NvConditionalWrap
            key={`${title ?? 'simple-section'}-${index}`}
            condition={!disableDefaultSectionWrapper}
            wrap={(children) => {
              return title ? (
                <PropertyPanelSection title={title} collapsible={isCollapsible} tooltip={infoText}>
                  {children}
                </PropertyPanelSection>
              ) : (
                <PropertyPanelSimpleSection>{children}</PropertyPanelSimpleSection>
              );
            }}
          >
            <PropertyList propertyPropsList={propertyPropsList} />
          </NvConditionalWrap>
        )
      )}
  </NvFlex>
);
