import { NvBox, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { foreignObjectSize } from '../../constants';
import { LabelForeignObjectProps } from './types';

export const LabelForeignObject: FC<React.PropsWithChildren<LabelForeignObjectProps>> = ({
  positionX,
  positionY,
  labelText,
}) => {
  const theme = useTheme();
  return (
    <foreignObject
      width={foreignObjectSize}
      height={foreignObjectSize}
      x={positionX}
      y={positionY}
      className="edgebutton-foreignobject"
      style={{
        overflow: 'visible',
      }}
      requiredExtensions="http://www.w3.org/1999/xhtml"
    >
      <NvBox position="relative" width="100%" height="100%">
        <NvFlex
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)', backgroundColor: theme.palette.nv_neutral[5] }}
        >
          <NvTypography variant="c3" textColor="subtle" noWrap>
            {labelText}
          </NvTypography>
        </NvFlex>
      </NvBox>
    </foreignObject>
  );
};
