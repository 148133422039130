import { ActionerEventTrigger, useListActionerEventsFromApps, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import {
  DefaultRenderOption,
  Initial,
  NvAutocomplete,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvCustomConditionIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvSensorsIcon,
  NvSkeleton,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { FC } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { DefaultPrincipalPropertyPanelSection } from '../../common/default-principal-property-panel-section';
import { PropertiesLoading } from '../../common/properties-loading';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { useGetLatestCapturedEventPayload } from '../../controllers/use-get-latest-captured-event-payload';
import { TriggerPayloadPanel } from '../../integration-trigger-property-panel-drawer/integration-trigger-properties/trigger-payload-panel';
import { ActionerEventTriggerPropertiesProps } from './types';

export const ActionerEventTriggerProperties: FC<ActionerEventTriggerPropertiesProps> = ({
  workflow,
  onCloseClicked,
  onRootConditionClicked,
}) => {
  const { mutate: saveToDraftWorkflow } = useSaveToDraftWorkflow();

  const { selectedNode } = usePropertyPanelContext();
  const { updateNode } = useNovaeraFlow(userAppGraph);

  const { hasEditPermission } = useWorkflowPermission();
  const { getCapturedData, isCapturingPayload } = useGetLatestCapturedEventPayload();

  const { data, isLoading: isEventsLoading } = useListActionerEventsFromApps({
    payload: {
      appId: workflow.appId,
    },
  });

  const theme = useTheme();

  assert(
    !!(workflow.trigger?.type === 'actionerEvent'),
    new Error('[ActionerEventTriggerProperties] - Type of trigger should be actioner event.'),
    'ERROR'
  );
  const workflowTrigger: ActionerEventTrigger = workflow.trigger;

  return (
    <NvFlex width="100%">
      {workflowTrigger ? (
        <>
          <NvConditionalRender when={hasEditPermission}>
            <PropertyPanelHeader
              title={workflowTrigger.name}
              type={NodeType.ACTIONER_EVENT_TRIGGER}
              onTitleChange={async (value) => {
                if (value) {
                  return new Promise<void>((resolve) => {
                    const trigger: ActionerEventTrigger = {
                      ...workflowTrigger,
                      type: 'actionerEvent',
                      name: value,
                    };

                    saveToDraftWorkflow(
                      { ...workflow, trigger },
                      {
                        onSuccess: () => {
                          if (selectedNode) {
                            const graphNode = userAppGraph.node(selectedNode.alias);
                            const newGraphNode = cloneDeep(graphNode);
                            newGraphNode.name = value;
                            updateNode({ newNode: newGraphNode });
                          }
                        },
                        onSettled: () => {
                          resolve();
                        },
                      }
                    );
                  });
                }
              }}
              validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
          </NvConditionalRender>
          <NvConditionalRender when={!hasEditPermission}>
            <PropertyPanelHeader
              title={workflowTrigger.name}
              type={NodeType.ACTIONER_EVENT_TRIGGER}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
          </NvConditionalRender>

          <PropertyPanelSection
            title="Select event"
            icon={<NvSensorsIcon />}
            tooltip="This workflow will start running when a new event is published."
          >
            <NvFlex gap="8px">
              {isEventsLoading && <NvSkeleton variant="rectangular" width="100%" height="32px" />}
              {!isEventsLoading && (
                <NvAutocomplete
                  fullWidth
                  disableClearable
                  options={data?.events ?? []}
                  size="small"
                  getOptionLabel={(option) => option.name}
                  value={data?.events?.find((option) => option.id === workflowTrigger.eventId)}
                  onChange={(e, selectedItem) => {
                    if (!hasEditPermission) {
                      return;
                    }
                    const trigger: ActionerEventTrigger = {
                      ...workflowTrigger,
                      type: 'actionerEvent',
                      eventId: selectedItem.id,
                    };

                    saveToDraftWorkflow({ ...workflow, trigger });
                  }}
                  renderOption={(props, option) => (
                    <DefaultRenderOption {...props} key={option.id}>
                      <NvFlex direction="row" alignItems="center" gap="8px">
                        <NvTypography variant="h5">{option.name}</NvTypography>
                        <NvDivider orientation="vertical" sx={{ height: '8px' }} />
                        <NvImage
                          src={option.appLogo}
                          FallBack={
                            <Initial
                              size="smaller"
                              value={option?.appName || 'User app'}
                              color={theme.palette.nv_neutral[500]}
                            />
                          }
                          size="smaller"
                          imageShape="square"
                        />

                        <NvTypography variant="h5">{option.appName}</NvTypography>
                      </NvFlex>
                    </DefaultRenderOption>
                  )}
                  renderInput={(params) => (
                    <NvTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      size="medium"
                    />
                  )}
                />
              )}
              <NvConditionalRender when={hasEditPermission}>
                <NvFlex direction="row" gap="8px" alignItems="center">
                  <NvButton
                    color="success"
                    size="small"
                    loading={isCapturingPayload}
                    onClick={() => {
                      getCapturedData();
                    }}
                  >
                    Capture event
                  </NvButton>

                  <NvTypography variant="body3" textColor="subtle">
                    Capture event to see the payload.
                  </NvTypography>
                </NvFlex>
              </NvConditionalRender>
            </NvFlex>
          </PropertyPanelSection>
          <DefaultPrincipalPropertyPanelSection defaultStarterUserId={workflowTrigger.defaultStarterUserId} />
          <TriggerPayloadPanel />
          <PropertyPanelSimpleSection>
            <NvFlex gap="8px">
              <RowItemCard
                rowItemLeftContentProps={{ draggable: false, icon: <NvCustomConditionIcon /> }}
                rowItemContent={<SimpleLabelLayout simpleLabel="Trigger conditions" />}
                onClick={onRootConditionClicked}
              />
            </NvFlex>
          </PropertyPanelSimpleSection>
        </>
      ) : (
        <PropertiesLoading />
      )}
    </NvFlex>
  );
};
