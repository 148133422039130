import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@novaera/theme-provider';

export const BasicTableContainer = styled(TableContainer)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  margin-top: 1px;
`;

export const BasicTable = styled(Table)``;

export const BasicTableHead = styled(TableHead)`
  .MuiTableCell-head {
    height: 32px;
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const BasicTableBody = styled(TableBody)`
  .MuiTableRow-root {
    transition: background-color 0.4s ease;

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
    }

    .MuiTableCell-root {
      vertical-align: top;
    }

    &:last-child {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  }
`;

export const BasicTableFooter = styled(TableFooter)`
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  height: 40px;
`;

export const BasicTableRow = styled(TableRow)``;

export const BasicTableCell = styled(TableCell)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  padding: 10px 12px;
`;
