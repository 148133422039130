import { ConnectionMapping, GetUserAppSetupResponse, UserApp } from '@novaera/actioner-service';
import { NvCloudUploadIcon, NvFocusState, NvFocusStateProps } from '@novaera/core';
import { FC, useMemo, useState } from 'react';
import { StepTrackerWrapper } from './step-tracker-wrapper';

export const InstallSteps: FC<
  React.PropsWithChildren<{
    onCancel: () => void;
    userApp: UserApp;
    connectionMappingsData: ConnectionMapping[];
    userAppSetup: GetUserAppSetupResponse;
    forceShowFilledSteps: boolean;
  }>
> = ({ onCancel, userApp, connectionMappingsData, userAppSetup, forceShowFilledSteps }) => {
  const { id: appId } = userApp;
  const { setupWorkflows } = userAppSetup ?? {
    setupWorkflows: [],
  };
  const [showConnectionStep] = useState(() => {
    return (
      connectionMappingsData.length > 0 &&
      (connectionMappingsData.some((cm) => cm.ambiguous || !cm.connected) || forceShowFilledSteps)
    );
  });

  const [showWorkflowStep] = useState(() => {
    return setupWorkflows.some((sw) => !sw.autoRun);
  });

  const focusStateProps = useMemo<NvFocusStateProps>(() => {
    if (!showConnectionStep && !showWorkflowStep) {
      return { open: true, variant: 'fullWidth' };
    } else {
      return {
        open: true,
        headerLogo: <NvCloudUploadIcon fontSize="large" />,
        title: userApp?.name ? `Setup ${userApp?.name} app` : '',
        onClose: onCancel,
      };
    }
  }, [onCancel, showConnectionStep, showWorkflowStep, userApp?.name]);

  return (
    <NvFocusState {...focusStateProps}>
      <StepTrackerWrapper
        appId={appId}
        userAppSetup={userAppSetup}
        connectionMappings={connectionMappingsData}
        onCancel={onCancel}
        showConnectionStep={showConnectionStep}
        showWorkflowStep={showWorkflowStep}
        forceShowFilledSteps={forceShowFilledSteps}
      />
    </NvFocusState>
  );
};
