import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomDependencyIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2162_111)">
        <path
          d="M3.19932 10.72C3.05932 13.29 4.85932 15.45 7.26932 15.9L6.47932 15.11C6.3866 15.0175 6.31305 14.9076 6.26286 14.7866C6.21267 14.6657 6.18683 14.536 6.18683 14.405C6.18683 14.274 6.21267 14.1443 6.26286 14.0234C6.31305 13.9024 6.3866 13.7925 6.47932 13.7C6.86932 13.31 7.49932 13.31 7.88932 13.7L10.4793 16.29C10.8693 16.68 10.8693 17.31 10.4793 17.7L7.89932 20.3C7.50932 20.69 6.87932 20.69 6.48932 20.3C6.39661 20.2075 6.32305 20.0976 6.27286 19.9766C6.22267 19.8557 6.19683 19.726 6.19683 19.595C6.19683 19.464 6.22267 19.3343 6.27286 19.2134C6.32305 19.0924 6.39661 18.9825 6.48932 18.89L7.36932 18.01V17.95C3.72932 17.52 0.93932 14.3 1.21932 10.48C1.47932 6.78 4.17932 4 7.88932 4C8.43932 4 8.88932 4.45 8.88932 5C8.88932 5.55 8.43932 6 7.88932 6C5.18932 6 3.33932 8.04 3.19932 10.72ZM12.8893 11H20.1893C21.2893 11 22.1893 10.1 22.1893 9V6C22.1893 4.9 21.2893 4 20.1893 4H12.8893C11.7893 4 10.8893 4.9 10.8893 6V9C10.8893 10.1 11.7893 11 12.8893 11ZM20.1893 9H12.8893V6H20.1893V9ZM20.1893 20H15.1893C14.0893 20 13.1893 19.1 13.1893 18V15C13.1893 13.9 14.0893 13 15.1893 13H20.1893C21.2893 13 22.1893 13.9 22.1893 15V18C22.1893 19.1 21.2893 20 20.1893 20Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2162_111">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
