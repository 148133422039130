import { useGetAppDependencies } from '@novaera/actioner-service';
import { useGetWorkflowQueryParams } from '../../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useAppDependencies = () => {
  const { userAppId } = useGetWorkflowQueryParams();

  const { data: appDependencies, isLoading: isLoadingAppDependencies } = useGetAppDependencies({
    appId: userAppId,
  });

  return {
    isLoadingAppDependencies,
    hasNoDependency: !isLoadingAppDependencies && (!appDependencies || appDependencies?.dependencies?.length === 0),
  };
};
