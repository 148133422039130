import { NvTypography } from '@novaera/core';
import { FC, useMemo } from 'react';
import { UsageInfoCard } from '../billing-card/usage-info-card';
import { EnterpriseCard } from '../enterprise-card';
import { FreeAppBanner } from '../free-app-banner';
import { Invoices } from '../payment-and-invoices/invoices';
import { TrialInformationCard } from '../trial-information-card';
import { BillingCard } from './../billing-card';
import { BillingLayoutWrapper, CardLayout, TrialBillingLayoutWrapper } from './../styled';
import { SubscriptionCard } from './../subscription-card';
import { TrialSubscriptionWrapper } from './styled';
import { FreeWorkspaceLayoutProps } from './types';

export const FreeWorkspaceLayout: FC<FreeWorkspaceLayoutProps> = ({ billingDetail }) => {
  const enterprisePlanKey = useMemo(() => {
    for (const [key, availablePlans] of Object.entries(billingDetail.availablePlansByGroupId)) {
      const enterprisesFound = availablePlans.some((availablePlan) => availablePlan.planType === 'enterprise');
      if (enterprisesFound) {
        return key;
      }
    }
    return;
  }, [billingDetail?.availablePlansByGroupId]);

  const filteredAvailablePlanKeys: string[] = useMemo(() => {
    if (enterprisePlanKey) {
      return [enterprisePlanKey];
    } else {
      const planKeys: string[] = [];
      Object.entries(billingDetail?.availablePlansByGroupId).forEach(([planKey, availablePlans]) => {
        if (
          availablePlans.some(
            (availablePlan) => availablePlan.planType !== 'free' && availablePlan.planType !== 'trial'
          )
        ) {
          planKeys.push(planKey);
        }
      });
      return planKeys;
    }
  }, [billingDetail?.availablePlansByGroupId, enterprisePlanKey]);

  return (
    <>
      <FreeAppBanner />
      {billingDetail.currentSubscriptionDetails.planType === 'trial' ? (
        <TrialBillingLayoutWrapper>
          <TrialInformationCard
            subscription={billingDetail.currentSubscriptionDetails}
            resourceUsageDetails={billingDetail.resourceUsageDetails}
          />
          {filteredAvailablePlanKeys &&
            filteredAvailablePlanKeys.map((availablePlanKey) => {
              return (
                <SubscriptionCard
                  SubscriptionCardWrapper={TrialSubscriptionWrapper}
                  key={availablePlanKey}
                  plans={billingDetail.availablePlansByGroupId[availablePlanKey]}
                />
              );
            })}
          {billingDetail.billingDetails.address && (
            <Invoices
              address={billingDetail.billingDetails.address}
              email={billingDetail.billingDetails.billingEmail}
            />
          )}
          {!enterprisePlanKey && <EnterpriseCard />}
        </TrialBillingLayoutWrapper>
      ) : (
        <BillingLayoutWrapper>
          <CardLayout>
            <BillingCard
              title="Workspace plan"
              description={
                <NvTypography variant="body1">
                  Your workspace is on the <b>{billingDetail.currentSubscriptionDetails.planName}</b>
                </NvTypography>
              }
            />
            <UsageInfoCard resourceUsageDetails={billingDetail.resourceUsageDetails} />
          </CardLayout>

          {filteredAvailablePlanKeys &&
            filteredAvailablePlanKeys.map((availablePlanKey) => {
              return (
                <SubscriptionCard
                  key={availablePlanKey}
                  plans={billingDetail.availablePlansByGroupId[availablePlanKey]}
                />
              );
            })}
          {billingDetail.billingDetails.address && (
            <Invoices
              address={billingDetail.billingDetails.address}
              email={billingDetail.billingDetails.billingEmail}
            />
          )}
          {!enterprisePlanKey && <EnterpriseCard />}
        </BillingLayoutWrapper>
      )}
    </>
  );
};
