import classnames from 'classnames';
import { useRef, useState } from 'react';

export const CarouselImg: React.FC<React.PropsWithChildren<{ src: string; alt: string }>> = ({
  src,
  alt,
  ...props
}) => {
  const ref = useRef<HTMLImageElement | null>(null);
  const [imageDirection, setImageDirection] = useState<'vertical' | 'horizontal'>();

  return (
    <img
      ref={ref}
      onLoad={() => {
        if (ref.current && ref.current.width > ref.current.height) {
          setImageDirection('horizontal');
        } else {
          setImageDirection('vertical');
        }
      }}
      className={classnames({
        vertical: imageDirection === 'vertical',
        horizontal: imageDirection === 'horizontal',
      })}
      src={src}
      alt={alt}
      {...props}
    />
  );
};
