import { SLACK_WORKSPACE_KEYS, SlackWorkspace } from '@novaera/ah-common';
import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SLACK_INTEGRATIONS_ROOT_PATH } from '../../constants';
import { UpdateSlackWorkspaceSettingsParams } from './types';

const updateSlackWorkspaceSettings = async ({
  teamId,
  syncSlackUsersToActioner,
  slackUsersCanJoinWithoutConfirmation,
}: UpdateSlackWorkspaceSettingsParams) => {
  const result = await NvAxios.patch<SlackWorkspace>(`${SLACK_INTEGRATIONS_ROOT_PATH}/${teamId}/settings`, {
    syncSlackUsersToActioner,
    slackUsersCanJoinWithoutConfirmation,
  });
  return result?.data;
};

export const useUpdateSlackWorkspaceSettings = () => {
  const cache = useQueryClient();
  return useMutation(updateSlackWorkspaceSettings, {
    onSuccess: (response) => {
      cache.setQueryData<SlackWorkspace>(SLACK_WORKSPACE_KEYS.all, response);
    },
  });
};
