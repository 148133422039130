import { DynamicValueRuleType } from '@novaera/actioner-service';
import { NvInputAntennaIcon } from '@novaera/core';
import { cloneDeep } from 'lodash';
import { VALUE_TYPE_OPTIONS } from '../../../../../../../../components/match-event-section/constants';

export const CONDITION_FIELD_NAME = 'conditionNode.condition';
export const DYNAMIC_VALUE_TYPE_OPTIONS = (() => {
  const options = cloneDeep(VALUE_TYPE_OPTIONS);
  Object.keys(options).forEach((key) => {
    options[key as keyof typeof options].push({
      label: 'Dynamic',
      value: DynamicValueRuleType.DYNAMIC,
      startIcon: <NvInputAntennaIcon sx={{ width: '16px', height: '16px' }} />,
    });
  });

  return options;
})();
