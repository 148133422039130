import { NvField, NvFlex, NvTagsAutoComplete, NvTextArea, NvTextField, isRequired } from '@novaera/core';

export const EventForm = () => {
  return (
    <NvFlex flexDirection={'column'} gap={'16px'}>
      <NvField
        direction="label-on-top"
        name="name"
        labelText="Name"
        isRequired
        hasRequiredIndicator
        labelVariant="h5"
        showErrorMessageOnlyWhenBlur
        validators={[isRequired()]}
        component={<NvTextField placeholder="What is your event name?" />}
      />
      <NvField
        name="description"
        direction="label-on-top"
        labelVariant="h5"
        labelText="Description"
        component={<NvTextArea rows={4} placeholder="What is your event about?" />}
      />
      <NvField
        direction="label-on-top"
        name="tags"
        labelText="Tags"
        labelVariant="h5"
        showErrorMessageOnlyWhenBlur
        isAutoComplete
        component={<NvTagsAutoComplete options={[]} placeholder={'Add tags'} />}
        defaultValue={[]}
      />
    </NvFlex>
  );
};
