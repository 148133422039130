import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type ActionerEventPublisherProps = BaseNodeProps;

export const ActionerEventPublisher: FC<ActionerEventPublisherProps> = (props) => {
  return (
    <DraggableItem nodeId={props.id}>
      <Node type={NodeType.ACTIONER_EVENT_PUBLISHER} {...props} />
    </DraggableItem>
  );
};
