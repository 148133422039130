import { NvFlex, NvSkeleton } from '@novaera/core';
import { FC, PropsWithChildren } from 'react';
import {
  PropertyPanelHeaderLoading,
  PropertyPanelListHeaderLoading,
  PropertyPanelSectionLoading,
  PropertyPanelSimpleSectionLoading,
} from '../../../../../../../../components';
import { PropertiesLoadingBackground } from './styled';

export const PropertiesLoading: FC<PropsWithChildren<unknown>> = () => (
  <PropertiesLoadingBackground>
    <PropertyPanelHeaderLoading />
    <PropertyPanelListHeaderLoading />
    <PropertyPanelSimpleSectionLoading>
      <NvFlex gap="8px">
        <NvSkeleton variant="rectangular" height="40px" width="100%" />
        <NvSkeleton variant="rectangular" height="40px" width="100%" />
      </NvFlex>
    </PropertyPanelSimpleSectionLoading>
    <PropertyPanelSectionLoading />
    <PropertyPanelSimpleSectionLoading />
    <PropertyPanelListHeaderLoading />
    <PropertyPanelSimpleSectionLoading>
      <NvSkeleton variant="rectangular" height="150px" width="100%" />
    </PropertyPanelSimpleSectionLoading>
  </PropertiesLoadingBackground>
);
