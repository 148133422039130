import { DefaultNodeSummary, WorkFlowDispatcherNode, WorkflowDispatcherVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class WorkflowDispatcherComponentFactory implements ComponentFactory<WorkFlowDispatcherNode> {
  aliasForWorkflowDispatcher: string;
  nameForWorkflowDispatcher: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('workflowDispatcher');
    this.aliasForWorkflowDispatcher = newAlias;
    this.nameForWorkflowDispatcher = newName;
  }

  get componentForGraph() {
    const outputRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForWorkflowDispatcher,
      width: 40,
      height: 40,
      id: this.aliasForWorkflowDispatcher,
      type: 'workflowDispatcher',
      alias: this.aliasForWorkflowDispatcher,
    };

    return { root: outputRoot };
  }

  componentForWorkflow(serverData: WorkFlowDispatcherNode) {
    const newNodeUnion: WorkflowDispatcherVertex = {
      type: 'workflowDispatcher',
      alias: this.aliasForWorkflowDispatcher,
    };

    const summary: DefaultNodeSummary = {
      name: this.nameForWorkflowDispatcher,
      alias: this.aliasForWorkflowDispatcher,
      type: 'workflowDispatcher',
    };

    return { root: newNodeUnion, nodeSummaries: { [this.aliasForWorkflowDispatcher]: summary } };
  }
}
