import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const NvFieldWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 6px;
  row-gap: 6px;
  margin-bottom: 8px;
  flex: 1 1 0;
  min-width: 0;

  .MuiFormControl-root {
    height: 100%;

    .MuiTextField-root {
      height: 100%;

      .MuiInput-root {
        height: 100%;
      }
    }
  }
`;

export const HeaderWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  gap: 6px;

  &.is-list-empty {
    margin-bottom: 12px;
  }
`;

export const FieldWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  min-width: 0;
  gap: 6px;
`;

export const KeyWrapper = styled(NvFlex)`
  flex-direction: row;
  flex: 1 1 0;
  min-width: 0;
`;

export const ValueGroupWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 6px;
  flex: 1 1 0;
  min-width: 0;
`;

export const ValueWrapper = styled(NvFlex)`
  flex-direction: row;
  flex: 1 1 0;
  min-width: 0;
`;

export const ContentTypeWrapper = styled(NvFlex)`
  flex-direction: row;
  flex: 0 0 auto;
  width: 175px;
`;

export const RemoveButtonWrapper = styled(NvFlex)`
  flex: 0 0 auto;
`;
