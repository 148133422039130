import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SelectWorkflowWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  width: 397px;
  padding: 6px 6px 6px 6px;
  align-items: flex-start;
  gap: 4px;

  border-radius: 6px;
  border: 1px solid ${({ theme: { palette } }) => palette.nv_neutral[20]};
`;
