import { ValueTypes } from '@novaera/actioner-service';
import { CodeInput, FieldTitle, NvFlex } from '@novaera/core';
import { DynamicRateFieldProps } from './types';

export const DynamicRateField: React.FC<DynamicRateFieldProps> = ({ value, onChange, context, label }) => (
  <NvFlex direction="row" gap="8px">
    {label && <FieldTitle direction="label-on-side" labelText={label} labelVariant="h6" labelWidth="90px" />}

    <CodeInput
      value={value.codeTemplate}
      onChange={(value) => {
        onChange({
          type: ValueTypes.NUMBER,
          displayValue: value,
          codeTemplate: value,
        });
      }}
      context={context}
      placeholder={'{{...}}'}
    />
  </NvFlex>
);
