import { NvDeleteOutlineIcon, useFormState } from '@novaera/core';
import { useCallback } from 'react';
import { useFieldArray } from 'react-final-form-arrays';

import { useTheme } from '@novaera/theme-provider';
import { useRequestContext } from '../../../../../providers/request-provider';
import { RequestsFormValues } from '../../../types';
import { setNextItemAfterDeletion } from '../../utils';

export const useRequestActionMenu = () => {
  const theme = useTheme();

  const { selectedRequestId, setSelectedRequestId } = useRequestContext();

  const {
    values: { httpRequestConfigurations },
  } = useFormState<RequestsFormValues>();
  const requestsFieldArray = useFieldArray('httpRequestConfigurations');

  const handleDeleteClick = useCallback(
    (id: string) => {
      const itemIndex = httpRequestConfigurations?.findIndex((r) => r.id === id) ?? -1;
      requestsFieldArray.fields.remove(itemIndex);
      if (selectedRequestId === id) {
        setNextItemAfterDeletion(id, itemIndex, setSelectedRequestId, httpRequestConfigurations);
      }
    },
    [httpRequestConfigurations, requestsFieldArray.fields, selectedRequestId, setSelectedRequestId]
  );

  const actionButtons = useCallback(
    (id: string) => [
      {
        name: 'Delete',
        onClick: () => {
          handleDeleteClick(id);
        },
        icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
      },
    ],
    [handleDeleteClick, theme.palette.nv_error]
  );

  return { actionButtons };
};
