import {
  Organization,
  useFetchProfile,
  useGetOrganizationMapping,
  useGetWorkspaceMapping,
} from '@novaera/actioner-service';
import { NvFlex, NvSearchIcon, NvSkeleton, NvTextField, NvTypography, useDebounce } from '@novaera/core';
import { useState } from 'react';
import { SSOSettings } from './sso-settings';
import { WorkspaceList } from './workspace-list';

export const OrganizationSettings = () => {
  const [keyword, setKeyword] = useState<string>('');

  const debouncedKeyword = useDebounce(keyword, 500);

  const { data: profile } = useFetchProfile();
  const { data: organization, isLoading: isOrganizationLoading } = useGetOrganizationMapping({
    workspaceId: profile?.workspaceId,
  });

  const {
    data: workspaceMappingsResult,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetWorkspaceMapping({
    organizationId: organization?.organizationId,
    params: {
      limit: 10,
      query: debouncedKeyword,
      includeWorkspaceLogoUrl: true,
    },
  });

  const workspaceMappings = workspaceMappingsResult?.pages.flatMap((page) => page.orgWorkspaceMappings) ?? [];

  return (
    <NvFlex gap={'32px'}>
      {isOrganizationLoading ? (
        <NvSkeleton variant="rectangular" width={'100%'} />
      ) : (
        organization && <SSOSettings containerId={organization.organizationId} containerType={'organization'} />
      )}
      <NvFlex gap={'16px'}>
        <NvTypography variant="h2">Actioner workspaces</NvTypography>
        <NvTextField
          sx={{ width: '300px' }}
          size="small"
          placeholder="Search"
          startIcon={<NvSearchIcon />}
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        />
        <WorkspaceList
          workspaceMappings={workspaceMappings as Organization[]}
          onSeeMoreClicked={() => {
            fetchNextPage();
          }}
          hasNextPage={Boolean(hasNextPage)}
          hasSearchKeyword={Boolean(debouncedKeyword)}
          isLoading={isLoading}
        />
      </NvFlex>
    </NvFlex>
  );
};
