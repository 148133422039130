import {
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvFlex,
  NvForm,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { FC, PropsWithChildren } from 'react';
import { useRecordModel } from './controller/use-record-model';
import { FieldFactory } from './field-factory';
import { RecordModelProps } from './types';

export const RecordModal: FC<PropsWithChildren<RecordModelProps>> = ({ onClose, table, record }) => {
  const { filteredParameters, handleOnSubmit, appId, isLoading } = useRecordModel(onClose, !!record);

  return (
    <NvDialogV1 open maxWidth="md" fullWidth>
      <NvForm onSubmit={handleOnSubmit} initialValues={record} keepDirtyOnReinitialize>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">
                {record ? 'Update' : 'Add'} {table.name} record
              </NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={onClose}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              {isLoading ? (
                <>
                  <NvSkeleton width="80%" />
                  <NvSkeleton width="60%" />
                  <NvSkeleton width="90%" />
                </>
              ) : (
                <NvFlex gap="20px">
                  {filteredParameters?.map(
                    (f) =>
                      f &&
                      f.schema && (
                        <FieldFactory key={`field_factory_${f.id}`} appId={appId} field={f} isEdit={!!record} />
                      )
                  )}
                </NvFlex>
              )}
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              {isLoading ? (
                <NvSkeleton width="20%" />
              ) : (
                <>
                  <NvButton type="submit" disabled={submitting} loading={submitting}>
                    {record ? 'Update' : 'Add'}
                  </NvButton>
                  <NvButton color="secondary" disabled={submitting} onClick={onClose}>
                    Cancel
                  </NvButton>
                </>
              )}
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
