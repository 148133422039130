import { useEffect, useMemo, useState } from 'react';
import { NvSlide } from '../../index';
import { FlaggerPositionBox } from './styled';
import { NvFlaggerProps } from './types';

export const NvFlagger: React.FC<React.PropsWithChildren<NvFlaggerProps>> = ({
  isAutoClose = true,
  positionX = 'center',
  positionY = 'start',
  children,
  ...props
}) => {
  const [timeoutClose, setTimeoutClose] = useState<NodeJS.Timeout>();
  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const direction = useMemo(() => {
    let d: 'down' | 'left' | 'right' | 'up' = 'down';

    if (positionY === 'start') {
      d = 'down';
    } else if (positionY === 'center') {
      if (positionX === 'start') {
        d = 'right';
      } else if (positionX === 'center') {
        d = 'down';
      } else if (positionX === 'end') {
        d = 'left';
      }
    } else if (positionY === 'end') {
      d = 'up';
    }
    return d;
  }, [positionX, positionY]);

  const createAndSetTimeout = () => {
    if (!isAutoClose) return;
    const newTimeout = setTimeout(() => setIsOpen(false), 3000);
    setTimeoutClose(newTimeout);
  };

  useEffect(() => {
    setIsOpen(props?.in);
  }, [props.in]);

  return (
    <NvSlide
      direction={direction}
      {...props}
      in={isOpen}
      timeout={{ enter: 400, exit: 200 }}
      mountOnEnter
      unmountOnExit
      onEntered={() => createAndSetTimeout()}
      onExited={(e) => {
        timeoutClose && clearTimeout(timeoutClose);
        props?.onExited && props.onExited(e);
      }}
    >
      <FlaggerPositionBox
        positionX={positionX}
        positionY={positionY}
        onMouseLeave={() => createAndSetTimeout()}
        onMouseEnter={() => timeoutClose && clearTimeout(timeoutClose)}
      >
        {children}
      </FlaggerPositionBox>
    </NvSlide>
  );
};
