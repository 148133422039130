import { NvBox, NvCarousel, NvFlex, NvTabs, NvVerifiedIcon } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { AppDirectoryBody } from '../app-directory-list/styled';

export const ComingSoonWrapper = styled(NvBox)`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
`;

export const AppDirectoryDetailHeaderInner = styled(NvFlex)`
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const AppVersionCard = styled('div')`
  height: 32px;
  padding: 5px 14px;
  background: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 6px;
  flex: 0 0 auto;
`;

export const AppDirectoryDetailBodyInner = styled(NvFlex)`
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const AppDirectoryDetailBodyLeft = styled(NvFlex)`
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
  gap: 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`;

export const AppDescription = styled('div')`
  padding: 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e300};
  border-radius: 12px;
`;

export const AppDirectoryDetailBodyRight = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isPublic' })<{
  isPublic?: boolean;
}>`
  position: sticky;
  top: ${({ isPublic }) => (isPublic ? '229px' : '149px')};
  flex: 0 0 auto;
  width: 340px;
  gap: 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const AppVersion = styled(NvFlex)`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
  box-sizing: border-box;
  border-radius: 12px;
  gap: 8px;
  align-items: flex-start;
`;

export const ChangeLogs = styled('ul')`
  margin: 0;
  padding-left: 24px;
`;

export const AppDirectoryItemPreviewBackground = styled(NvFlex)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`;

export const InstallWrapper = styled(NvFlex)`
  &.featured {
    position: relative;
    top: -14px;
    row-gap: 10px;

    @media screen and (max-width: 767px) {
      flex-direction: row-reverse;
      top: 0;
      gap: 10px;
    }
  }
`;

export const VerifiedIcon = styled(NvVerifiedIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.nv_neutral_alpha[600]};
`;

export const AppTitle = styled(NvFlex)`
  flex-direction: row;
  gap: 12px;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
`;

export const AnonymousStarBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 0px 4px;
  gap: 4px;
  height: 32px;
  min-width: 60px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
  border-radius: 6px;
`;

export const AppDirectoryDetailCarousel = styled(NvCarousel)`
  @media screen and (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const AppDirectoryDetailTabs = styled(NvTabs)`
  padding-top: 16px;
  margin-bottom: -16px;

  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        border-bottom: none;

        @media screen and (max-width: 991px) {
          padding: 0;
        }
      }
    }
  }
`;

export const AppDirectoryDetailBodyContainer = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  position: relative;
  flex: 1 1 auto;
  min-height: 0;

  &.app-directory-detail-body {
    height: auto;
    min-height: calc(100vh - 171px);

    &.public {
      min-height: calc(100vh - 245px);
    }
  }
`;

export const AppDirectoryDetailHeaderWrapper = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isPublic' })<{
  isPublic?: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  position: sticky;
  top: ${({ isPublic }) => (isPublic ? '80px' : '0')};
  z-index: 3;
`;

export const AppDirectoryDetailHeader = styled(NvFlex)`
  position: relative;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 32px 40px 16px 40px;
  max-width: 1288px;

  @media screen and (max-width: 991px) {
    padding: 32px 32px 16px 32px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding: 16px 24px;
    margin-bottom: 0;
  }
`;

export const AppDirectoryDetailBody = styled(AppDirectoryBody)`
  position: relative;
  padding: 24px 40px 0 40px;

  @media screen and (max-width: 991px) {
    padding: 24px 32px 0 32px;
  }

  @media screen and (max-width: 767px) {
    padding: 24px 24px 0 24px;
  }
`;
