import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';

export const SplitPanelItemContainer = styled('div', {
  shouldForwardProp: shouldForwardPropForFormFields,
})<{
  direction?: 'horizontal' | 'vertical';
  initialStyle?: string;
  collapsedSize?: string;
  minSize?: string;
  dataPanelIndex?: number;
  isCollapsed?: boolean;
}>`
  ${({ direction, initialStyle }) =>
    direction === 'vertical'
      ? initialStyle
        ? `height: ${initialStyle}; flex: 1 1 auto;`
        : `flex: 1 1 auto`
      : initialStyle
      ? `width: ${initialStyle}`
      : `flex: 1 1 auto`};
  ${({ direction, minSize }) => (direction === 'vertical' ? `min-height: ${minSize}` : `min-width: ${minSize}`)};

  transition: height 0.3s ease-in-out;

  &.is-full {
    height: calc(100% - 88px) !important;
  }

  &.is-dragging {
    transition: none;
  }

  &.horizontal-expanded {
    width: ${({ initialStyle }) => initialStyle || '50%'} !important;
  }

  &.vertical-expanded {
    height: ${({ initialStyle }) => initialStyle || '50%'} !important;
  }

  &.is-collapsed {
    flex: 0 1 auto;
    ${({ direction, collapsedSize }) =>
      direction === 'vertical'
        ? `min-height: ${collapsedSize || '40px'} !important`
        : `min-width: ${collapsedSize || '40px'} !important`};
    ${({ direction, collapsedSize }) =>
      direction === 'vertical'
        ? `height: ${collapsedSize || '40px'} !important`
        : `width: ${collapsedSize || '40px'} !important`};
  }

  &.split-panel-item-position-relative {
    position: relative;
  }
`;
