import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SlackUsersWrapper = styled(NvBox)`
  border-radius: 12px;
  ${({ theme: { elevations } }) => elevations.e100};
`;

export const HeaderRow = styled(NvFlex)`
  flex-direction: row;
  height: 44px;
  align-items: center;
  padding: 0 12px;
  gap: 24px;
  justify-content: flex-start;
  align-self: stretch;
`;

export const Row = styled(HeaderRow, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected: boolean;
}>`
  border-top: 1px solid ${({ theme: { palette } }) => palette.nv_neutral[30]};
  cursor: pointer;
  transition: background-color 300ms ease;
  ${({ selected, theme: { palette } }) => (selected ? `background-color:${palette.nv_main[20]};` : ``)}
  &:hover {
    ${({ selected, theme: { palette } }) =>
      selected ? `background-color:${palette.nv_main[20]};` : `background-color:${palette.nv_neutral[20]};`}
  }
`;

export const Column = styled(NvFlex)`
  flex: 1 1 260px;
  min-width: 0px;
`;

export const ShowMoreButtonRow = styled(NvFlex)`
  align-items: center;
  padding: 0 12px;
  flex-direction: row;
  height: 44px;
  border-top: 1px solid ${({ theme: { palette } }) => palette.nv_neutral[30]};
`;
