import { GetWorkflowSampleResponseBatchParam, QUERY_KEYS_EXECUTION_RESULT_DETAIL_PARAMS } from './types';
import { GetWorkflowDynamicInputParams } from './use-get-workflow-dynamic-input/types';
import { GetWorkflowSourceOptionsParams } from './use-get-workflow-source-options/types';
import { SearchWorkflowsParams } from './use-search-workflows/types';
import { generatedWorkflowSearchKey } from './utils';

export const QUERY_KEYS_WORKFLOWS = {
  all: ['workflows'] as const,
  list: ({ appId }: { appId?: string }) => [...QUERY_KEYS_WORKFLOWS.all, appId, 'list'] as const,
  detail: ({ appId, workflowId }: { appId?: string; workflowId?: string }) =>
    [...QUERY_KEYS_WORKFLOWS.list({ appId }), 'detail', workflowId] as const,
};

export const QUERY_KEYS_WORKFLOWS_SEARCH = {
  all: ['workflow_search'] as const,
  list: (params: SearchWorkflowsParams) => [
    ...QUERY_KEYS_WORKFLOWS_SEARCH.all,
    generatedWorkflowSearchKey(params),
    'infinite',
  ],
};

export const QUERY_KEYS_WORKFLOWS_SEARCH_SINGLE = {
  all: ['workflow_search_single'] as const,
  list: (params: SearchWorkflowsParams) => [
    ...QUERY_KEYS_WORKFLOWS_SEARCH_SINGLE.all,
    generatedWorkflowSearchKey(params),
  ],
};

export const QUERY_KEYS_WORKFLOW_EVENT_SAMPLE = {
  all: ['workflow-event-sample'] as const,
  detail: ({ appId, workflowId }: { appId: string; workflowId: string }) =>
    [...QUERY_KEYS_WORKFLOW_EVENT_SAMPLE.all, appId, workflowId] as const,
};

export const QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE = {
  all: ['workflow-integration-trigger-event-sample'] as const,
  detail: ({ appId, workflowId, isDraft }: { appId: string; workflowId: string; isDraft?: boolean }) =>
    [...QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE.all, appId, workflowId, isDraft] as const,
};

export const QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS = {
  all: () => ['workflow-source-options'] as const,
  detail: ({
    appId,
    workflowId,
    payload: { inputParameterId, inputParameters, context, draft, query },
  }: GetWorkflowSourceOptionsParams) =>
    [
      ...QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS.all(),
      'detail',
      appId,
      workflowId,
      draft,
      inputParameterId,
      inputParameters,
      context,
      ...(query ? [query] : []),
    ] as const,
  withParameterId: ({ appId, workflowId, payload: { inputParameterId, draft } }: GetWorkflowSourceOptionsParams) =>
    [...QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS.all(), 'detail', appId, workflowId, draft, inputParameterId] as const,
  withAppId: ({ appId }: Pick<GetWorkflowSourceOptionsParams, 'appId'>) =>
    [...QUERY_KEYS_WORKFLOW_SOURCE_OPTIONS.all(), 'detail', appId] as const,
};

export const QUERY_KEYS_WORKFLOW_SOURCE_DYNAMIC_INPUT = {
  all: () => ['workflow-dynamic-input'] as const,
  detail: ({
    appId,
    workflowId,
    payload: { inputParameterId, inputParameters, context, draft, formId },
  }: GetWorkflowDynamicInputParams) =>
    [
      ...QUERY_KEYS_WORKFLOW_SOURCE_DYNAMIC_INPUT.all(),
      'detail',
      appId,
      workflowId,
      draft,
      inputParameterId,
      formId,
      inputParameters,
      context,
    ] as const,
  withParameterId: ({ appId, workflowId, payload: { inputParameterId, draft } }: GetWorkflowDynamicInputParams) =>
    [...QUERY_KEYS_WORKFLOW_SOURCE_DYNAMIC_INPUT.all(), 'detail', appId, workflowId, draft, inputParameterId] as const,
};

export const QUERY_KEYS_EXECUTION_RESULT = {
  all: ['workflow-execution-result'] as const,
  detail: ({ workflowId, executionId }: QUERY_KEYS_EXECUTION_RESULT_DETAIL_PARAMS) =>
    [...QUERY_KEYS_EXECUTION_RESULT.all, workflowId, executionId] as const,
};

export const QUERY_KEYS_EXECUTION_OUTPUT_RESULT = {
  all: ['workflow-execution-output-result'] as const,
  detail: ({ executionIdentifier, outputAlias }: { executionIdentifier: string; outputAlias: string }) =>
    [...QUERY_KEYS_EXECUTION_OUTPUT_RESULT.all, executionIdentifier, outputAlias] as const,
};

export const QUERY_KEYS_WORKFLOW_SAMPLE_RESPONSE = {
  all: ['workflow-sample-response'] as const,
  detail: ({ appId, workflowId }: { appId: string; workflowId: string }) =>
    [...QUERY_KEYS_WORKFLOW_SAMPLE_RESPONSE.all, appId, workflowId] as const,
};

export const QUERY_KEYS_WORKFLOW_BATCH_SAMPLE_RESPONSE = {
  all: ['workflow-sample-batch-response'] as const,
  batch: (param: GetWorkflowSampleResponseBatchParam) =>
    [...QUERY_KEYS_WORKFLOW_BATCH_SAMPLE_RESPONSE.all, param] as const,
};

export const QUERY_KEYS_WORKFLOW_AVAILABLE_UPDATES = {
  all: ['workflow-available-updates'] as const,
  detail: ({ appId, workflowId }: { appId: string; workflowId: string }) =>
    [...QUERY_KEYS_WORKFLOW_AVAILABLE_UPDATES.all, appId, workflowId] as const,
};

export const QUERY_KEY_WORKFLOW_RUN_FROM_LINK = {
  all: ['workflow-run-from-link'] as const,
  detail: ({ formContextId }: { formContextId: string }) =>
    [...QUERY_KEY_WORKFLOW_RUN_FROM_LINK.all, formContextId] as const,
};
