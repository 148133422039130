import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ItemFlex = styled(NvFlex)`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  border: 1px solid ${({ theme: { palette } }) => palette.nv_neutral_alpha[30]};
  border-radius: 6px;
  box-sizing: border-box;
  height: 40px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: pointer;
  z-index: 10;
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[0]};

  .MuiCircularProgress-root {
    color: ${({ theme: { palette } }) => palette.nv_neutral[1000]};
  }

  .MuiTypography-root {
    transition: color 200ms ease;
  }

  &:hover {
    background-color: ${({ theme: { palette } }) => palette.nv_neutral[20]};
    border-color: ${({ theme: { palette } }) => palette.nv_neutral_alpha[40]};
  }

  &.isSelected {
    border-color: ${({ theme: { palette } }) => palette.nv_main[30]};
    background-color: ${({ theme: { palette } }) => palette.nv_main[20]};
    color: ${({ theme: { palette } }) => palette.nv_main[60]};

    &:hover {
      background-color: ${({ theme: { palette } }) => palette.nv_main[20]};
    }

    .edit-button {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }
  }

  &.isRunning {
    border-color: ${({ theme: { palette } }) => palette.nv_main[40]};
  }

  .edit-button {
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  .label {
    flex: 1 1 auto;
    margin-right: 8px;
  }
`;

export const ItemIconBox = styled(NvBox)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 4px;
`;
