import { Monaco } from '@monaco-editor/react';
import { useTheme } from '@novaera/theme-provider';
import { editor } from 'monaco-editor';
import { useCallback, useEffect, useRef } from 'react';
import { getPlaceholderWidget } from './placeholder';

export const useMonacoEditorWidgets = () => {
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const widgetsRef = useRef<editor.IContentWidget[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const widgets = widgetsRef.current;
    return () => {
      widgets.forEach((widget) => {
        if (editorRef.current) {
          editorRef.current.removeContentWidget(widget);
        }
      });
    };
  }, []);

  const handleEditorMount = useCallback(
    (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
      if (!editorRef.current) {
        editorRef.current = editor;
      }

      const placeholderWidget = getPlaceholderWidget({
        monaco,
        editor,
        theme,
      });

      widgetsRef.current.push(placeholderWidget);

      editor.addContentWidget(placeholderWidget);
    },
    [theme]
  );

  return {
    onEditorMount: handleEditorMount,
  };
};
