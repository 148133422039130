import { NvAutocomplete, NvButton, NvField, NvFlex, NvForm, NvTextField, NvTypography } from '@novaera/core';
import { useIntegrationSyncPanel } from './controllers/use-app-directory-sync-panel';

export const IntegrationSync = () => {
  const { initialAppDirectoryTableNames, availableTableNames, handleAppDirectorySync } = useIntegrationSyncPanel();
  return (
    <NvForm<{ tables: string[]; type?: 'import' | 'export' }>
      onSubmit={handleAppDirectorySync}
      initialValues={{
        tables: initialAppDirectoryTableNames,
      }}
      keepDirtyOnReinitialize
    >
      {({ form, submitting, values }) => (
        <NvFlex width="100%" alignItems="flex-start" gap="16px">
          <NvField
            isAutoComplete
            isMulti
            direction="label-on-top"
            labelText={<NvTypography variant="h4">Tables</NvTypography>}
            component={
              <NvAutocomplete
                multiple
                renderInput={(props) => <NvTextField {...props} placeholder="Select table" />}
                options={availableTableNames}
                fullWidth
              />
            }
            name="tables"
          />
          <NvFlex direction="row" gap="4px">
            <NvButton
              loading={submitting && values.type === 'import'}
              disabled={submitting}
              type="submit"
              onClick={() => {
                form.change('type', 'import');
              }}
            >
              Import
            </NvButton>
            <NvButton
              loading={submitting && values.type === 'export'}
              disabled={submitting}
              type="submit"
              onClick={() => {
                form.change('type', 'export');
              }}
            >
              Export
            </NvButton>
          </NvFlex>
        </NvFlex>
      )}
    </NvForm>
  );
};
