import { useMutation, NvAxios, useToast } from '@novaera/core';
import { UpdateWorkspaceInfo } from './types';

const updateWorkspaceInfo = async (params: UpdateWorkspaceInfo) => {
  const result = await NvAxios.patch(`v1/workspaces`, params);
  return result?.data;
};

export const useWorkspaceInfoUpdate = () => {
  const { addToast } = useToast();

  return useMutation(updateWorkspaceInfo, {
    onSuccess: () => {
      addToast('Workspace info updated', { variant: 'success' });
    },
    onError: () => {
      addToast('Workspace info could not be updated', { variant: 'error' });
    },
  });
};
