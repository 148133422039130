import { NvFlex, NvSkeleton } from '@novaera/core';
import { WorkflowHistoryListItemCard } from '../styled';

export const WorkflowHistoryListItemLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <WorkflowHistoryListItemCard>
    <NvFlex direction="row" gap="8px" alignItems="center" flex="1 1 auto" minWidth={0}>
      <NvSkeleton variant="rectangular" width="16px" height="16px" />
      <NvSkeleton variant="rectangular" width="100%" height="16px" />
    </NvFlex>
  </WorkflowHistoryListItemCard>
);
