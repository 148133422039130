import { useListActionerEventUsages } from '@novaera/actioner-service';
import { NvTabs } from '@novaera/core';
import { useParams } from '@novaera/route';
import { EventUsageList } from './list';

export const EventUsage = () => {
  const { userAppId, eventId } = useParams<{ userAppId: string; eventId: string }>();
  const { data: eventUsages, isLoading } = useListActionerEventUsages({ appId: userAppId, eventId });
  return (
    <NvTabs
      tabs={[
        {
          id: 'event-publishers',
          label: 'Event publishers',
          content: (
            <EventUsageList
              data={eventUsages?.publisherWorkflows}
              isLoading={isLoading}
              emptyStateContent="None of your workflows are currently set up to publish this event."
            />
          ),
        },
        {
          id: 'event-listeners',
          label: 'Event listeners',
          content: (
            <EventUsageList
              data={eventUsages?.listenerWorkflows}
              isLoading={isLoading}
              emptyStateContent="None of your workflows are currently set up to listen for this event"
            />
          ),
        },
      ]}
    ></NvTabs>
  );
};
