import { NvBox, NvLinearProgress } from '@novaera/core';
import { useQueryParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useEffect } from 'react';

export const AppPostMessage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { getSearchParams } = useQueryParams();
  const params = getSearchParams<{
    message: string;
    messageFrom: string;
    connectionId: string;
    error?: string;
    error_description: string;
  }>();

  useEffect(() => {
    try {
      if (params?.message && params.messageFrom) {
        window.opener?.postMessage(
          {
            messageFrom: params.messageFrom,
            data: params.message,
            connectionId: params.connectionId,
            error: params.error,
            error_description: params.error_description,
          },
          window.opener?.location.origin
        );
        window.close();
      } else {
        throw new Error('Invalid post message');
      }
    } catch (e: unknown) {
      assert(true, e as Error, 'WARNING');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NvBox>
      <NvLinearProgress />
    </NvBox>
  );
};
