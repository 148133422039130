import { useTheme } from '@novaera/theme-provider';
import { NvSmartDisplayIcon } from '../../icons';
import { CarouselVideo } from '../carousel-video';
import { CarouselItem, CarouselItemType } from '../types';

export const CarouselThumbCard: React.FC<React.PropsWithChildren<CarouselItem>> = ({ url, type, displayText }) => {
  const { palette } = useTheme();
  return type === CarouselItemType.IMG ? (
    <img src={url} alt={displayText} />
  ) : (
    <CarouselVideo
      disabled
      url={url}
      playIcon={<NvSmartDisplayIcon htmlColor={palette.nv_neutral[600]} />}
      alt={displayText}
    />
  );
};
