import { Theme } from '@mui/material';
import {
  NvAddIcon,
  NvAutoAwesomeIcon,
  NvCheckboxIcon,
  NvCreditCard,
  NvCustomCodeIcon,
  NvEditIcon,
  NvErrorIcon,
  NvFolderRounded,
  NvPlayArrowIcon,
  NvPowerRoundedIcon,
  NvReceiptLongIcon,
  NvRefreshIcon,
  NvRouterRoundedIcon,
  NvSensorsIcon,
  NvShortTextIcon,
  NvSupervisorAccountRoundedIcon,
  NvUpgradeIcon,
} from '../icons';
import { ModalIcons } from './types';

export const ModalIcon: ({ theme }: { theme: Theme }) => Record<ModalIcons, React.ReactNode> = ({ theme }) => ({
  add: <NvAddIcon />,
  refresh: <NvRefreshIcon />,
  edit: <NvEditIcon />,
  power: <NvPowerRoundedIcon />,
  playArrow: <NvPlayArrowIcon />,
  supervisorAccount: <NvSupervisorAccountRoundedIcon />,
  error: <NvErrorIcon />,
  redError: <NvErrorIcon htmlColor={theme.palette.nv_error[40]} />,
  appUpdate: <NvUpgradeIcon />,
  creditCard: <NvCreditCard />,
  customCode: <NvCustomCodeIcon />,
  folder: <NvFolderRounded />,
  autoAwesome: <NvAutoAwesomeIcon />,
  checkbox: <NvCheckboxIcon />,
  router: <NvRouterRoundedIcon />,
  sensor: <NvSensorsIcon />,
  receipt: <NvReceiptLongIcon />,
  shortText: <NvShortTextIcon />,
});
