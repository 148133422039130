import { isRequired, NvButton, NvField, NvFlex, NvForm, NvTextField, NvTypography, useFormState } from '@novaera/core';
import React from 'react';
import { TitleFlex, WelcomeMessageFlex } from '../../styled';
import { WorkspaceCreateResponse } from '../create/services/use-create-workspace-service/types';
import { WorkspaceCreateFormType } from './types';

import { ApplicationConfig } from '@novaera/application-config';
import { CREATE_WORKSPACE, CREATE_WORKSPACE_TITLE, WORKSPACE_DESC } from '../create/constants';
import { useCreateWorkspace } from '../create/controllers/use-create-workspace';
import { ButtonFlex } from '../create/styled';
import { useWorkspaceList } from '../list/controllers/use-workspace-list';

export const WorkspaceCreateForm: React.FC<React.PropsWithChildren<{ onCancelClicked?: () => void }>> = ({
  onCancelClicked,
}) => {
  const { subdomain, checkSubDomain, isLoading, isError } = useCreateWorkspace({
    reason: 'workspace-creation',
  });
  const { hasWorkspaces } = useWorkspaceList();
  const form = useFormState();
  return (
    <>
      <TitleFlex>
        <NvTypography variant="h1">{CREATE_WORKSPACE_TITLE}</NvTypography>
      </TitleFlex>
      <WelcomeMessageFlex>
        <NvTypography variant="body1" textColor="subtle">
          {WORKSPACE_DESC}
        </NvTypography>
      </WelcomeMessageFlex>

      <NvFlex sx={{ marginTop: '32px' }}>
        <NvTypography variant="h4" textColor="secondary">
          Workspace name
        </NvTypography>
      </NvFlex>
      <NvFlex sx={{ marginTop: '10px' }}>
        <NvField
          name="name"
          showErrorMessageOnlyWhenBlur
          component={<NvTextField size="large" autoFocus />}
          validators={[isRequired(), checkSubDomain]}
        />
      </NvFlex>

      {!isError && (
        <NvFlex sx={{ marginTop: '4px', marginLeft: '4px' }}>
          <NvTypography variant="body3" textColor="secondary">
            Workspace URL: &nbsp;<b>https://{`${subdomain}.${ApplicationConfig.Actioner.host}`}</b>
          </NvTypography>
        </NvFlex>
      )}

      <ButtonFlex>
        <NvFlex>
          <NvButton type="submit" size="large" loading={isLoading || form.submitting} isFitContent>
            <NvTypography variant="h3">{CREATE_WORKSPACE}</NvTypography>
          </NvButton>
        </NvFlex>
        {hasWorkspaces && onCancelClicked && (
          <NvFlex width="auto" flex="0 0 auto">
            <NvButton color="ghost" size="large" onClick={onCancelClicked}>
              <NvTypography variant="h3">Cancel</NvTypography>
            </NvButton>
          </NvFlex>
        )}
      </ButtonFlex>
    </>
  );
};

export const WorkspaceCreate: React.FC<React.PropsWithChildren<WorkspaceCreateFormType>> = ({
  suggestedName,
  onSubmit,
  onCancelClicked,
}) => {
  return (
    <NvForm<WorkspaceCreateResponse> onSubmit={onSubmit} initialValues={{ name: suggestedName }}>
      <WorkspaceCreateForm onCancelClicked={onCancelClicked} />
    </NvForm>
  );
};
