import { NvBox, NvFlex, NvListItemIcon, NvTabs } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const HeaderWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  width: 100%;
  height: 40px;
  margin: 0;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.nv_neutral_alpha[30]};
  flex: 0 0 auto;

  .chevron-icon {
    transition: transform 200ms ease-in-out;
  }

  &.is-open {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
`;

export const EmptyStateWrapper = styled(NvFlex)`
  margin: 24px 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme: { palette } }) => palette.nv_neutral_alpha[30]};
  padding: 12px 16px 12px 16px;
`;

export const ErrorStateWrapper = styled(NvFlex)`
  width: 100%;
  padding: 0 24px 0 24px;
  gap: 12px;
`;

export const ErrorTitleWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const ErrorDetailWrapper = styled(NvFlex)`
  border-radius: 8px;
  border: 1px solid ${({ theme: { palette } }) => palette.nv_error[30]};
  background: ${({ theme: { palette } }) => palette.nv_error[10]};
  padding: 8px 12px 8px 12px;
  max-width: 1000px;
`;

export const MiddleHeaderWrapper = styled(HeaderWrapper)`
  border-top: 1px solid ${({ theme: { palette } }) => palette.nv_neutral_alpha[30]};
  margin-top: -1px;
`;

export const HeaderWrapperLeftBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
`;

export const HeaderWrapperLeftBoxIcon = styled(NvBox)`
  display: flex;
  flex: 0 0 auto;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 4px;
`;

export const HeaderWrapperRightBox = styled(NvBox)`
  display: flex;
  align-items: center;
  margin-right: 16px;
  gap: 8px;
  flex: 0 0 auto;
`;

export const CollapseInnerWrapper = styled(NvBox)`
  width: 100%;
  padding: 16px 16px 32px 16px;
`;

export const NvListItemIconStyled = styled(NvListItemIcon)`
  min-width: 24px;
`;

export const NvTabsStyled = styled(NvTabs)`
  .tab-body-container {
    ::after {
      background: none;
    }
  }
`;

export const BaseUrlBox = styled(NvBox)`
  height: 20px;
  padding: 2px 6px;
  background: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 4px;
  cursor: pointer;
`;

export const RequestConnectionBoxWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const RequestConnectionCard = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
  gap: 4px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 6px;
`;
