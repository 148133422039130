import { NvCanvasProps } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { userAppGraph } from '../graph-utils/user-app-graph';
import { usePropertyPanelContext } from '../properties-panel/provider';

export const useWorkflowCanvas = () => {
  const { fillSelectedNode } = usePropertyPanelContext();

  const handleSelectionChange = useCallback<Required<NvCanvasProps>['onSelectionchange']>(
    ({ nodes }) => {
      assert(nodes.length <= 1, new Error('The number of nodes selected cannot be more than 1'), 'ERROR');
      if (nodes.length !== 0) {
        const selectedNode = nodes[0];
        const selectedNodeId = selectedNode.id;
        const node = userAppGraph.node(selectedNodeId);
        if (
          node.type === 'integrationWebhook' ||
          node.type === 'genericWebhook' ||
          node.type === 'action' ||
          node.type === 'form' ||
          node.type === 'email' ||
          node.type === 'actionerEvent' ||
          node.type === 'actionerEventPublisher' ||
          node.type === 'workflowCondition' ||
          node.type === 'workflowDispatcher' ||
          node.type === 'loop' ||
          node.type === 'branchJunction' ||
          node.type === 'function' ||
          node.type === 'integrationApp' ||
          node.type === 'response' ||
          node.type === 'delay' ||
          node.type === 'sendEmail' ||
          node.type === 'workflowResolver' ||
          node.type === 'output' ||
          node.type === 'job' ||
          node.type === 'aiKnowledge' ||
          node.type === 'assistant' ||
          node.type === 'getConversation' ||
          node.type === 'file' ||
          node.type === 'http' ||
          node.type === 'linkGenerator' ||
          node.type === 'assistant_cancel_run'
        ) {
          fillSelectedNode(node);
        }
      }
    },
    [fillSelectedNode]
  );

  return { handleSelectionChange };
};
