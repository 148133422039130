import { SvgIconProps } from '@mui/material';

export function NvPenIllustration({ ...props }: SvgIconProps) {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_504_130)">
        <path
          d="M75.3668 79.7487C75.1534 79.7487 74.9399 79.6349 74.8269 79.4326C74.6511 79.129 74.7515 78.7496 75.0529 78.5725L95.0695 66.9368C95.3709 66.7597 95.7476 66.8609 95.9234 67.1644C96.0992 67.468 95.9988 67.8474 95.6974 68.0245L75.6808 79.6602C75.5803 79.7234 75.4673 79.7487 75.3668 79.7487Z"
          fill="#B1B6C0"
        />
        <path
          d="M72.5037 90.145C72.2902 90.145 72.0767 90.0312 71.9637 89.8288C71.7879 89.5253 71.8884 89.1459 72.1897 88.9688L92.2064 77.3331C92.5077 77.156 92.8845 77.2572 93.0603 77.5607C93.2361 77.8643 93.1356 78.2437 92.8342 78.4207L72.8176 90.0565C72.7172 90.1197 72.6041 90.145 72.5037 90.145Z"
          fill="#B1B6C0"
        />
        <path d="M52.4495 32.1433L80.0885 48.2184L84.9482 45.3853L57.146 29.4115L52.4495 32.1433Z" fill="white" />
        <path
          d="M80.0884 48.8508C79.9754 48.8508 79.8749 48.8255 79.7745 48.7622L52.1355 32.6872C51.9471 32.5734 51.8215 32.371 51.8215 32.1433C51.8215 31.9157 51.9471 31.7133 52.1355 31.5995L56.8445 28.8676C57.0329 28.7538 57.2715 28.7538 57.4724 28.8676L85.2747 44.8541C85.4756 44.968 85.5886 45.1703 85.5886 45.398C85.5886 45.6256 85.4756 45.828 85.2747 45.9418L80.4149 48.7622C80.3145 48.8128 80.214 48.8508 80.101 48.8508H80.0884ZM53.7052 32.1433L80.0884 47.4848L83.6924 45.398L57.1585 30.145L53.7052 32.156V32.1433Z"
          fill="#455068"
        />
        <path d="M52.4495 32.1433V73.6653L80.0885 89.7403V48.2184L52.4495 32.1433Z" fill="white" />
        <path
          d="M80.0884 90.3727C79.9754 90.3727 79.8749 90.3474 79.7745 90.2842L52.1355 74.2091C51.9471 74.0953 51.8215 73.8929 51.8215 73.6653V32.1433C51.8215 31.9157 51.9471 31.7133 52.1355 31.5995C52.3238 31.4857 52.575 31.4857 52.7633 31.5995L80.4024 47.6745C80.5907 47.7884 80.7163 47.9907 80.7163 48.2184V89.7403C80.7163 89.968 80.5907 90.1703 80.4024 90.2842C80.3019 90.3347 80.2014 90.3727 80.0884 90.3727ZM53.0773 73.2985L79.4606 88.64V48.5852L53.0773 33.2437V73.3111V73.2985Z"
          fill="#455068"
        />
        <path d="M84.9482 45.3853V86.9073L80.0885 89.7403V48.2184L84.9482 45.3853Z" fill="#455068" />
        <path
          d="M80.0884 90.3727C79.9754 90.3727 79.875 90.3474 79.7745 90.2842C79.5861 90.1703 79.4606 89.968 79.4606 89.7403V48.2184C79.4606 47.9907 79.5861 47.7884 79.7745 47.6745L84.6343 44.8541C84.8226 44.7403 85.0738 44.7403 85.2621 44.8541C85.4505 44.968 85.5761 45.1703 85.5761 45.398V86.9073C85.5761 87.1349 85.4505 87.3373 85.2621 87.4511L80.4024 90.2715C80.3019 90.3221 80.2015 90.36 80.0884 90.36V90.3727ZM80.7163 48.5852V88.64L84.3203 86.5405V46.4857L80.7163 48.5852Z"
          fill="#455068"
        />
        <path d="M52.4495 32.1433L80.0885 48.2184L84.9482 45.3853L57.146 29.4115L52.4495 32.1433Z" fill="white" />
        <path
          d="M80.0884 48.8508C79.9754 48.8508 79.8749 48.8255 79.7745 48.7622L52.1355 32.6872C51.9471 32.5734 51.8215 32.371 51.8215 32.1433C51.8215 31.9157 51.9471 31.7133 52.1355 31.5995L56.8445 28.8676C57.0329 28.7538 57.2715 28.7538 57.4724 28.8676L85.2747 44.8541C85.4756 44.968 85.5886 45.1703 85.5886 45.398C85.5886 45.6256 85.4756 45.828 85.2747 45.9418L80.4149 48.7622C80.3145 48.8128 80.214 48.8508 80.101 48.8508H80.0884ZM53.7052 32.1433L80.0884 47.4848L83.6924 45.398L57.1585 30.145L53.7052 32.156V32.1433Z"
          fill="#455068"
        />
        <path d="M52.4495 32.1433V73.6653L80.0885 89.7403V48.2184L52.4495 32.1433Z" fill="white" />
        <path
          d="M80.0884 90.3727C79.9754 90.3727 79.8749 90.3474 79.7745 90.2842L52.1355 74.2091C51.9471 74.0953 51.8215 73.8929 51.8215 73.6653V32.1433C51.8215 31.9157 51.9471 31.7133 52.1355 31.5995C52.3238 31.4857 52.575 31.4857 52.7633 31.5995L80.4024 47.6745C80.5907 47.7884 80.7163 47.9907 80.7163 48.2184V89.7403C80.7163 89.968 80.5907 90.1703 80.4024 90.2842C80.3019 90.3347 80.2014 90.3727 80.0884 90.3727ZM53.0773 73.2985L79.4606 88.64V48.5852L53.0773 33.2437V73.3111V73.2985Z"
          fill="#455068"
        />
        <path d="M84.9482 45.3853V86.9073L80.0885 89.7403V48.2184L84.9482 45.3853Z" fill="#455068" />
        <path
          d="M80.0884 90.3727C79.9754 90.3727 79.875 90.3474 79.7745 90.2842C79.5861 90.1703 79.4606 89.968 79.4606 89.7403V48.2184C79.4606 47.9907 79.5861 47.7884 79.7745 47.6745L84.6343 44.8541C84.8226 44.7403 85.0738 44.7403 85.2621 44.8541C85.4505 44.968 85.5761 45.1703 85.5761 45.398V86.9073C85.5761 87.1349 85.4505 87.3373 85.2621 87.4511L80.4024 90.2715C80.3019 90.3221 80.2015 90.36 80.0884 90.36V90.3727ZM80.7163 48.5852V88.64L84.3203 86.5405V46.4857L80.7163 48.5852Z"
          fill="#455068"
        />
        <path
          d="M69.7662 59.8794C69.6658 59.8794 69.5528 59.8541 69.4523 59.7909L55.6265 51.7597C55.3251 51.5826 55.2247 51.2032 55.4005 50.8997C55.5763 50.5961 55.953 50.4949 56.2544 50.672L70.0802 58.7032C70.3815 58.8803 70.482 59.2597 70.3062 59.5632C70.1932 59.7656 69.9797 59.8794 69.7662 59.8794Z"
          fill="#455068"
        />
        <path
          d="M65.3082 52.2529C65.2078 52.2529 65.0947 52.2277 64.9943 52.1644L55.639 46.726C55.3376 46.5489 55.2371 46.1695 55.4129 45.8659C55.5887 45.5624 55.9655 45.4612 56.2668 45.6383L65.6222 51.0767C65.9235 51.2538 66.024 51.6332 65.8482 51.9368C65.7352 52.1391 65.5217 52.2529 65.3082 52.2529Z"
          fill="#455068"
        />
        <path
          d="M65.3082 48.3575C65.2078 48.3575 65.0947 48.3322 64.9943 48.269L55.639 42.8305C55.3376 42.6535 55.2371 42.274 55.4129 41.9705C55.5887 41.6669 55.9655 41.5658 56.2668 41.7428L65.6222 47.1813C65.9235 47.3583 66.024 47.7378 65.8482 48.0413C65.7352 48.2437 65.5217 48.3575 65.3082 48.3575Z"
          fill="#455068"
        />
        <path
          d="M69.4271 63.1425C69.3267 63.1425 69.2136 63.1172 69.1132 63.054L55.2874 55.0228C54.986 54.8457 54.8856 54.4663 55.0614 54.1627C55.2372 53.8592 55.6139 53.758 55.9153 53.9351L69.7411 61.9663C70.0424 62.1433 70.1429 62.5228 69.9671 62.8263C69.8541 63.0287 69.6406 63.1425 69.4271 63.1425Z"
          fill="#455068"
        />
        <path
          d="M54.2577 66.1653C54.2577 66.1653 56.7566 62.6366 58.8286 63.7496C60.9006 64.8626 59.0044 68.2521 60.436 68.9604C61.8675 69.6686 64.266 67.253 66.8528 68.0624C69.4397 68.8718 66.3882 71.9705 68.6988 73.0961C71.0094 74.2218 73.1818 71.6796 75.1785 72.6408C77.1751 73.602 77.6146 78.484 77.6146 78.484"
          fill="white"
        />
        <path
          d="M77.6146 79.1164C77.3007 79.1164 77.0244 78.876 76.9868 78.5472C76.8235 76.8018 76.1203 73.8044 74.9022 73.2099C74.1739 72.8558 73.3451 73.1467 72.3782 73.4755C71.1978 73.8803 69.8541 74.3482 68.4226 73.6526C66.878 72.8938 67.0915 71.414 67.2422 70.4148C67.418 69.2133 67.3929 68.8845 66.6645 68.6568C65.4465 68.2774 64.1907 68.758 63.0856 69.1754C62.0183 69.5801 61.0137 69.9595 60.1472 69.5295C59.13 69.0236 59.1803 67.8221 59.2179 66.7723C59.2682 65.4949 59.2431 64.6981 58.5147 64.3061C57.2087 63.6231 55.3126 65.7352 54.7475 66.532C54.5466 66.8229 54.1573 66.8862 53.8685 66.6838C53.5922 66.4814 53.5168 66.0894 53.7178 65.7985C53.8308 65.6341 56.5683 61.8398 59.0924 63.1804C60.5741 63.9646 60.5114 65.6088 60.4611 66.8103C60.436 67.5185 60.3983 68.2395 60.6872 68.3912C61.0513 68.5809 61.8173 68.29 62.621 67.9865C63.8517 67.5185 65.3837 66.9494 67.0036 67.4553C68.837 68.0244 68.6109 69.5801 68.4477 70.6172C68.2844 71.7428 68.2719 72.2108 68.9249 72.527C69.8918 72.9949 70.8838 72.6535 71.9261 72.2867C73.0437 71.8946 74.2116 71.4899 75.4045 72.0717C77.6523 73.1594 78.1421 77.8895 78.1923 78.4334C78.23 78.7749 77.9663 79.0911 77.6272 79.1164C77.6021 79.1164 77.5895 79.1164 77.5644 79.1164H77.6146Z"
          fill="#455068"
        />
        <path
          d="M85.7141 107.346C85.5006 107.346 85.2872 107.232 85.1742 107.03C84.9983 106.726 85.0988 106.347 85.4002 106.169L98.0581 98.8086C98.3595 98.6315 98.7362 98.7327 98.912 99.0363C99.0878 99.3398 98.9874 99.7192 98.686 99.8963L86.0281 107.257C85.9276 107.32 85.8146 107.346 85.7141 107.346Z"
          fill="#B1B6C0"
        />
        <path
          d="M51.6206 112C51.4071 112 51.1937 111.886 51.0806 111.684C50.9048 111.38 51.0053 111.001 51.3067 110.824L59.7202 105.929C60.0216 105.752 60.3983 105.853 60.5741 106.157C60.7499 106.46 60.6494 106.84 60.3481 107.017L51.9346 111.911C51.8341 111.975 51.7211 112 51.6206 112Z"
          fill="#B1B6C0"
        />
        <path
          d="M31.8928 84.3271C31.6794 84.3271 31.4659 84.2133 31.3529 84.011C31.1771 83.7074 31.2775 83.328 31.5789 83.1509L39.9924 78.2563C40.2938 78.0793 40.6705 78.1804 40.8463 78.484C41.0221 78.7875 40.9217 79.1669 40.6203 79.344L32.2068 84.2386C32.1063 84.3019 31.9933 84.3271 31.8928 84.3271Z"
          fill="#B1B6C0"
        />
        <path
          d="M33.7764 94.2302C33.5629 94.2302 33.3494 94.1164 33.2364 93.914C33.0606 93.6105 33.1611 93.231 33.4624 93.054L41.876 88.1594C42.1773 87.9823 42.5541 88.0835 42.7299 88.387C42.9057 88.6906 42.8052 89.07 42.5038 89.2471L34.0903 94.1417C33.9899 94.2049 33.8768 94.2302 33.7764 94.2302Z"
          fill="#B1B6C0"
        />
        <path d="M74.0734 77.8769L60.2601 85.9207L74.0734 93.9519L87.8991 85.9207L74.0734 77.8769Z" fill="white" />
        <path
          d="M74.0734 94.5843C73.9604 94.5843 73.8599 94.559 73.7595 94.4958L59.9337 86.4646C59.7453 86.3508 59.6198 86.1484 59.6198 85.9207C59.6198 85.6931 59.7453 85.4907 59.9337 85.3769L73.7595 77.3457C73.9478 77.2319 74.199 77.2319 74.3873 77.3457L88.2131 85.3769C88.4015 85.4907 88.5271 85.6931 88.5271 85.9207C88.5271 86.1484 88.4015 86.3508 88.2131 86.4646L74.3873 94.4958C74.2869 94.5464 74.1864 94.5843 74.0734 94.5843ZM61.5034 85.9207L74.0734 93.231L86.6435 85.9207L74.0734 78.6105L61.5034 85.9207Z"
          fill="#455068"
        />
        <path d="M74.0734 93.9519V108.914L60.2601 100.883V85.9207L74.0734 93.9519Z" fill="white" />
        <path
          d="M74.0734 109.546C73.9604 109.546 73.8599 109.521 73.7595 109.458C64.333 103.977 59.6198 101.115 59.6198 100.87V85.9081C59.6198 85.6804 59.7453 85.4781 59.9337 85.3642C60.1221 85.2504 60.3732 85.2504 60.5616 85.3642L74.3873 93.3954C74.5757 93.5093 74.7013 93.7116 74.7013 93.9393V108.901C74.7013 109.129 74.5757 109.331 74.3873 109.445C74.2869 109.496 74.1864 109.534 74.0734 109.534V109.546ZM60.8755 100.516C62.5708 101.503 70.6578 106.207 73.4455 107.826V94.3187L60.8755 87.0084V100.516Z"
          fill="#455068"
        />
        <path d="M87.8993 85.9207V100.883L74.0735 108.914V93.9519L87.8993 85.9207Z" fill="#455068" />
        <path
          d="M74.0734 109.546C73.9604 109.546 73.86 109.521 73.7595 109.458C73.5711 109.344 73.4456 109.142 73.4456 108.914V93.9519C73.4456 93.7243 73.5711 93.5219 73.7595 93.4081L87.5853 85.3769C87.7736 85.2631 88.0248 85.2631 88.2132 85.3769C88.4015 85.4907 88.5271 85.6931 88.5271 85.9207V100.883C88.5271 101.11 88.4015 101.313 88.2132 101.427L74.3874 109.458C74.2869 109.508 74.1864 109.546 74.0734 109.546ZM74.7013 94.3187V107.826L87.2713 100.516V87.0084L74.7013 94.3187Z"
          fill="#455068"
        />
        <path
          d="M80.0885 82.683C80.0885 84.1121 79.6114 85.1492 78.8454 85.6931L78.4937 85.8954L76.5222 87.0337C76.7985 86.8061 77.0496 86.5025 77.238 86.1231C77.5143 85.5919 77.6649 84.9089 77.6649 84.0995C77.6649 83.1762 77.4891 82.2024 77.1627 81.2411L79.6616 80.0776C79.9379 80.9502 80.0885 81.8356 80.0885 82.683Z"
          fill="#455068"
        />
        <path
          d="M76.5222 87.6661C76.3213 87.6661 76.1204 87.5649 76.0073 87.3879C75.819 87.1223 75.8818 86.7428 76.1329 86.5405C76.359 86.3508 76.5348 86.1231 76.6729 85.8449C76.9115 85.3769 77.0371 84.7951 77.0371 84.0869C77.0371 83.2774 76.8864 82.3794 76.5724 81.4435C76.472 81.14 76.6101 80.7985 76.8989 80.672L79.3979 79.5084C79.5611 79.4326 79.7495 79.4326 79.9127 79.5084C80.076 79.5843 80.2015 79.7234 80.2518 79.8879C80.5657 80.8491 80.7164 81.7976 80.7038 82.683C80.7038 84.2639 80.1513 85.516 79.1969 86.2116L76.8236 87.5776C76.7231 87.6282 76.6227 87.6661 76.5096 87.6661H76.5222ZM77.9286 81.5826C78.1798 82.468 78.3054 83.3027 78.2928 84.0995C78.2928 84.5548 78.2426 84.9722 78.1547 85.3643L78.5188 85.1492C79.0965 84.7319 79.4481 83.8465 79.4481 82.683C79.4481 82.1391 79.3853 81.5573 79.2472 80.9756L77.9161 81.5953L77.9286 81.5826Z"
          fill="#455068"
        />
        <path
          d="M79.6741 80.0776L77.1752 81.2411C76.3966 78.9266 74.7641 76.7007 72.868 75.6003C72.1773 75.1956 71.5118 74.9806 70.9216 74.9427C70.4695 74.9174 70.0551 74.9806 69.6909 75.1577L71.8131 73.9056L72.0266 73.7791C72.8805 73.3364 74.0232 73.4376 75.2916 74.1712C77.2505 75.3094 78.9332 77.6619 79.6741 80.0649V80.0776Z"
          fill="white"
        />
        <path
          d="M77.1751 81.8735C77.0872 81.8735 77.0119 81.8609 76.924 81.8229C76.7607 81.747 76.6352 81.6206 76.5724 81.4435C75.8189 79.2049 74.2367 77.1307 72.554 76.1442C71.9638 75.79 71.3987 75.6003 70.8838 75.575C70.5322 75.5624 70.2183 75.6003 69.9546 75.7268C69.6532 75.8659 69.289 75.7521 69.1383 75.4486C68.9876 75.145 69.0881 74.7782 69.3769 74.6138L71.7126 73.2479C72.8177 72.6661 74.199 72.8179 75.618 73.64C77.6523 74.8162 79.4857 77.2825 80.2768 79.9005C80.3647 80.204 80.2266 80.5329 79.9378 80.6594L77.4388 81.8229C77.3509 81.8609 77.263 81.8862 77.1751 81.8862V81.8735ZM72.0266 74.5253C72.4033 74.6518 72.7926 74.8288 73.1944 75.0565C74.9901 76.0936 76.61 78.1046 77.5267 80.3811L78.8955 79.7487C78.1169 77.6239 76.61 75.6889 74.9776 74.7277C73.9479 74.1206 73.006 73.9815 72.3154 74.3482L72.0266 74.5253Z"
          fill="#455068"
        />
        <path
          d="M77.6648 84.0995C77.6648 84.9089 77.5016 85.5919 77.2379 86.1231C77.0495 86.5025 76.8109 86.8061 76.5221 87.0337C76.4468 87.097 76.3714 87.1476 76.3086 87.1855C75.4171 87.742 74.1864 87.6788 72.8176 86.8693C70.1931 85.301 68.0709 81.5447 68.0709 78.446C68.0709 76.9916 68.5356 75.9292 69.3141 75.3853L69.6657 75.1703C69.6657 75.1703 69.6783 75.1577 69.7034 75.1577C70.0676 74.9933 70.482 74.9174 70.934 74.9427C71.5368 74.9806 72.1898 75.1956 72.8804 75.6003C74.7641 76.7007 76.3965 78.9266 77.1877 81.2411C77.5141 82.2024 77.7025 83.1762 77.69 84.0995H77.6648Z"
          fill="white"
        />
        <path
          d="M75.0027 88.1847C74.2241 88.1847 73.3702 87.9191 72.4912 87.4005C69.6532 85.7057 67.4305 81.7724 67.4305 78.4334C67.4305 76.8145 67.958 75.5498 68.9374 74.8541L69.3267 74.6138C69.3267 74.6138 69.3895 74.5759 69.4146 74.5632C69.8667 74.3482 70.3816 74.2597 70.9466 74.2976C71.6624 74.3356 72.4159 74.5885 73.1819 75.0312C75.1408 76.1695 76.8989 78.4713 77.7654 81.0135C78.1295 82.0885 78.3053 83.1256 78.2928 84.0742C78.2928 84.9722 78.117 85.7563 77.7905 86.3887C77.577 86.8187 77.2882 87.1982 76.9114 87.5017C76.8235 87.5776 76.698 87.6661 76.5724 87.7294C76.1078 88.0202 75.5678 88.172 74.9901 88.172L75.0027 88.1847ZM69.9546 75.7268L69.6155 75.9292C69.0253 76.3466 68.6863 77.2445 68.6863 78.446C68.6863 81.3297 70.6829 84.8584 73.1316 86.3255C74.2367 86.9831 75.2539 87.097 75.9696 86.6543L76.1203 86.5531C76.3464 86.3634 76.5222 86.1358 76.6603 85.8575C76.8989 85.3896 77.0245 84.8078 77.0245 84.0995C77.0245 83.2901 76.8738 82.3921 76.5598 81.4562C75.8064 79.2175 74.2241 77.1433 72.5414 76.1568C71.9512 75.8027 71.3861 75.613 70.8713 75.5877C70.5197 75.575 70.2057 75.6256 69.942 75.7395L69.9546 75.7268Z"
          fill="#455068"
        />
        <path
          d="M37.0665 106.916C36.8531 106.916 36.6396 106.802 36.5266 106.6C36.3508 106.296 36.4512 105.917 36.7526 105.739L56.7692 94.1037C57.0706 93.9267 57.4473 94.0278 57.6231 94.3314C57.7989 94.6349 57.6985 95.0143 57.3971 95.1914L37.3805 106.827C37.28 106.89 37.167 106.916 37.0665 106.916Z"
          fill="#B1B6C0"
        />
        <path
          d="M51.6458 17.1307V74.8541L48.3432 92.8263L41.4742 71.9199V14.5506C41.4742 10.731 44.5508 7.63239 48.3432 7.63239C50.2394 7.63239 51.9597 8.40389 53.1904 9.65599C53.6927 10.1619 54.1071 10.7437 54.4336 11.3761C51.1812 12.9823 51.6458 17.1307 51.6458 17.1307Z"
          fill="white"
        />
        <path
          d="M48.3431 93.4587C48.0794 93.4587 47.8283 93.2816 47.7529 93.0287L40.884 72.1223C40.8589 72.059 40.8463 71.9958 40.8463 71.9199V14.5506C40.8463 10.3895 44.2117 7 48.3431 7C50.3523 7 52.2359 7.78415 53.6424 9.21332C54.1698 9.74452 54.6344 10.3769 54.9986 11.0852C55.0739 11.2369 55.0865 11.414 55.0363 11.5784C54.986 11.7428 54.873 11.8693 54.7223 11.9452C51.9095 13.3364 52.2611 16.9157 52.2862 17.0675C52.2862 17.0927 52.2862 17.118 52.2862 17.1433V74.8541L48.971 92.9401C48.9208 93.2184 48.6822 93.4334 48.3934 93.4587C48.3808 93.4587 48.3682 93.4587 48.3557 93.4587H48.3431ZM42.1021 71.8187L48.1673 90.2841L51.0304 74.7403V17.1686C50.9802 16.726 50.7165 13.0202 53.5796 11.1484C53.341 10.769 53.0647 10.4148 52.7508 10.1113C51.583 8.92243 50.0258 8.2774 48.3431 8.2774C44.9024 8.2774 42.1021 11.0978 42.1021 14.5632V71.8187Z"
          fill="#455068"
        />
        <path
          d="M55.187 19.6729V20.0902C55.187 20.0902 55.187 20.1155 55.187 20.1282C55.1493 20.5329 54.9987 20.925 54.7475 21.317C54.647 21.4562 54.5466 21.5953 54.421 21.7344C54.1071 22.0759 53.7052 22.4047 53.1904 22.683C51.2816 23.7707 48.5315 24.0616 46.1456 23.5683C45.8066 23.4924 45.4801 23.4165 45.1536 23.3153C44.5257 23.1256 43.9481 22.8727 43.4332 22.5565C42.9435 22.2656 42.5542 21.9368 42.2528 21.5953C42.1147 21.4562 42.0017 21.3044 41.9138 21.1653C41.6877 20.7985 41.5496 20.4444 41.4868 20.0776C41.4742 19.9764 41.4742 19.8879 41.4742 19.812"
          fill="white"
        />
        <path
          d="M48.4813 24.4283C47.665 24.4283 46.8362 24.3524 46.0326 24.1754C45.6684 24.0995 45.3168 24.011 44.9777 23.8971C44.2996 23.6821 43.6717 23.4039 43.1192 23.075C42.5918 22.7588 42.1397 22.3921 41.7881 21.9873C41.6625 21.8609 41.5244 21.6965 41.3863 21.4814C41.11 21.0514 40.9342 20.5961 40.8714 20.1408C40.8463 20.0143 40.8463 19.9005 40.8463 19.7867H42.1021C42.1021 19.7867 42.1021 19.8879 42.1021 19.9511C42.1397 20.242 42.2528 20.5202 42.4286 20.7985C42.5165 20.9376 42.5918 21.0388 42.6797 21.1273C42.9685 21.4688 43.3201 21.747 43.7345 22C44.1992 22.2782 44.7266 22.5185 45.3293 22.6956C45.6307 22.7968 45.9447 22.8727 46.2586 22.9359C48.5692 23.4165 51.156 23.0877 52.8638 22.1138C53.2908 21.8735 53.6549 21.5953 53.9438 21.2791C54.0442 21.1653 54.1321 21.0514 54.22 20.9376C54.4084 20.6467 54.5214 20.3432 54.5465 20.0523C54.5465 20.0396 54.5465 20.027 54.5465 20.0017V19.6602H54.7098L55.7772 19.6349V20.0523C55.7772 20.0523 55.7772 20.1661 55.7772 20.2167C55.7269 20.6973 55.5511 21.1779 55.2497 21.6332C55.1242 21.8103 54.9986 21.9747 54.8605 22.1265C54.4712 22.5438 54.0066 22.9106 53.4666 23.2015C52.0978 23.9857 50.2895 24.403 48.4436 24.403L48.4813 24.4283Z"
          fill="#455068"
        />
        <path
          d="M55.1995 70.8828V71.3002C55.1995 71.3002 55.1995 71.3255 55.1995 71.3381C55.1618 71.7428 55.0111 72.1349 54.76 72.527C54.6595 72.6661 54.559 72.8052 54.4335 72.9444C54.1195 73.2858 53.7177 73.6147 53.2028 73.8929C51.2941 74.9806 48.544 75.2715 46.1581 74.7782C45.819 74.7024 45.4925 74.6265 45.166 74.5253C44.5382 74.3356 43.9605 74.0826 43.4457 73.7664C42.9559 73.4755 42.5666 73.1467 42.2653 72.8052C42.1271 72.6661 42.0141 72.5143 41.9262 72.3752C41.7002 72.0084 41.562 71.6543 41.4993 71.2875C41.4867 71.1863 41.4867 71.0978 41.4867 71.0219"
          fill="white"
        />
        <path
          d="M48.4813 75.6383C47.665 75.6383 46.8362 75.5624 46.0326 75.3853C45.6684 75.3095 45.3168 75.2209 44.9777 75.1071C44.2996 74.8921 43.6717 74.6138 43.1192 74.285C42.5918 73.9688 42.1397 73.602 41.7881 73.1973C41.6625 73.0708 41.5244 72.9064 41.3863 72.6914C41.11 72.2614 40.9342 71.8061 40.8714 71.3508C40.8463 71.2243 40.8463 71.1105 40.8463 70.9966H42.1021C42.1021 70.9966 42.1021 71.0978 42.1021 71.1611C42.1397 71.4519 42.2528 71.7302 42.4286 72.0084C42.5165 72.1476 42.5918 72.2487 42.6797 72.3373C42.9811 72.6788 43.3201 72.957 43.7345 73.21C44.1992 73.4882 44.7391 73.7285 45.3293 73.9056C45.6307 74.0068 45.9447 74.0826 46.2586 74.1459C48.5692 74.6265 51.156 74.2976 52.8638 73.3238C53.2908 73.0835 53.6549 72.8052 53.9438 72.489C54.0442 72.3752 54.1321 72.2614 54.22 72.1476C54.4084 71.8567 54.5214 71.5658 54.5465 71.2622C54.5465 71.2496 54.5465 71.2243 54.5465 71.2116V70.8828H54.7098C54.7098 70.8828 54.7349 70.8575 54.7474 70.8449L54.7726 70.8702L55.7897 70.8449V71.2622C55.7897 71.2622 55.7897 71.3761 55.7897 71.4393C55.7395 71.9199 55.5637 72.3879 55.2623 72.8558C55.1367 73.0329 55.0112 73.1973 54.873 73.3491C54.4837 73.7664 54.0191 74.1332 53.4791 74.4241C52.1104 75.2083 50.3021 75.6256 48.4561 75.6256L48.4813 75.6383Z"
          fill="#455068"
        />
        <path
          d="M55.1995 14.5506V71.6037L48.3431 92.8263L51.6458 74.8541V17.1307C51.6458 17.1307 51.1811 12.9823 54.4335 11.3761C54.9233 12.3246 55.1995 13.4123 55.1995 14.5506Z"
          fill="#455068"
        />
        <path
          d="M48.3431 93.4587C48.3431 93.4587 48.2426 93.4587 48.1924 93.4334C47.8785 93.3575 47.665 93.0287 47.7278 92.6998L51.0304 74.7277V17.1307C51.0053 17.0169 50.5532 12.5902 54.1572 10.8069C54.4586 10.6551 54.8353 10.7816 54.986 11.0852C55.5385 12.1476 55.8273 13.3491 55.8273 14.5506V71.6037C55.8273 71.667 55.8273 71.7302 55.8022 71.7934L48.9458 93.016C48.8579 93.2816 48.6194 93.4587 48.3431 93.4587ZM54.1446 12.274C51.9471 13.8297 52.2485 16.9157 52.261 17.0548V74.8541L50.6788 83.543L54.5716 71.5025V14.5506C54.5716 13.7664 54.4209 12.9949 54.1446 12.274Z"
          fill="#455068"
        />
        <path
          d="M55.8274 69.5801V71.7808C55.4256 72.1729 54.9735 72.5017 54.4963 72.7799C52.7885 73.7664 50.9175 74.2724 48.9334 74.2724C47.8409 74.2724 46.7233 74.1206 45.5554 73.8297L45.8694 72.6029C48.8329 73.3491 51.5202 73.0455 53.8685 71.6923C54.3457 71.4014 54.8103 71.0346 55.187 70.6172C55.3251 70.4781 55.4256 70.3263 55.5261 70.1872C55.6642 69.9848 55.7647 69.7825 55.8274 69.5801Z"
          fill="white"
        />
        <path
          d="M55.8274 18.3828V20.5708C55.4256 20.9629 54.9735 21.3044 54.4963 21.5826C52.7885 22.5691 50.9175 23.0624 48.9334 23.0624C47.8409 23.0624 46.7233 22.9106 45.5554 22.6197L45.8694 21.3929C48.8329 22.1518 51.5202 21.8356 53.8685 20.4823C54.3457 20.204 54.8103 19.8246 55.187 19.4072C55.3251 19.2681 55.4256 19.129 55.5261 18.9772C55.6642 18.7875 55.7647 18.5852 55.8274 18.3828Z"
          fill="white"
        />
        <path
          d="M47.3511 43.172C47.3385 45.398 46.5976 47.0169 45.4172 47.8643L44.8647 48.1804L41.8007 49.9384C42.2402 49.5843 42.6169 49.1164 42.9057 48.5219C43.3327 47.6998 43.5587 46.6374 43.5713 45.3727C43.5838 43.9435 43.295 42.4258 42.7927 40.9334L46.6855 39.1248C47.125 40.4907 47.3511 41.8693 47.3385 43.172H47.3511Z"
          fill="#455068"
        />
        <path
          d="M41.8007 50.5708C41.5998 50.5708 41.3989 50.4696 41.2859 50.2926C41.0975 50.027 41.1477 49.6476 41.4114 49.4452C41.8007 49.129 42.1146 48.7243 42.3532 48.2437C42.7425 47.4848 42.9434 46.511 42.956 45.36C42.956 44.0573 42.7174 42.6282 42.2151 41.1231C42.1146 40.8196 42.2528 40.4781 42.5416 40.3516L46.4344 38.543C46.5977 38.4671 46.786 38.4671 46.9493 38.543C47.1125 38.6189 47.2381 38.758 47.2883 38.9224C47.753 40.3769 47.9915 41.8061 47.979 43.172C47.9664 45.5118 47.1879 47.371 45.7814 48.3701L42.1272 50.4823C42.0267 50.5329 41.9263 50.5708 41.8133 50.5708H41.8007ZM43.5713 41.2622C44.0108 42.704 44.2243 44.0826 44.2117 45.3727C44.2117 46.3845 44.0611 47.2825 43.7848 48.0666L45.1033 47.3078C46.1205 46.5742 46.7107 45.0944 46.7232 43.1594C46.7232 42.1476 46.5851 41.0852 46.2963 39.9975L43.5713 41.2622Z"
          fill="#455068"
        />
        <path
          d="M46.6856 39.1248L42.7928 40.9334C41.5747 37.3288 39.0381 33.8634 36.0996 32.156C35.0197 31.5236 33.99 31.1948 33.0607 31.1315C32.3449 31.0936 31.7045 31.1948 31.152 31.4604L34.4546 29.5253L34.7811 29.3356C36.1122 28.6526 37.8953 28.8044 39.8669 29.9553C42.9183 31.726 45.5428 35.3938 46.6856 39.1374V39.1248Z"
          fill="white"
        />
        <path
          d="M42.7927 41.5531C42.7048 41.5531 42.6295 41.5405 42.5416 41.5025C42.3783 41.4266 42.2527 41.3002 42.1899 41.1231C40.9844 37.5691 38.4729 34.2555 35.7731 32.6872C34.8061 32.118 33.8769 31.8018 33.023 31.7513C32.4202 31.7133 31.8803 31.8145 31.4156 32.0295C31.1143 32.1686 30.7501 32.0422 30.5994 31.7513C30.4487 31.4477 30.5492 31.0809 30.838 30.9165L34.4671 28.7791C36.0745 27.9443 38.0962 28.172 40.1808 29.3988C43.2699 31.1948 46.0577 34.9384 47.2757 38.9351C47.3636 39.2386 47.2255 39.5674 46.9367 39.6939L43.0439 41.5025C42.956 41.5405 42.8681 41.5658 42.7802 41.5658L42.7927 41.5531ZM33.8769 30.5877C34.7057 30.7521 35.547 31.0809 36.4261 31.5868C39.2515 33.2184 41.7881 36.4309 43.1569 40.0481L45.9195 38.7707C44.714 35.2673 42.2527 32.0548 39.5654 30.4739C37.8576 29.4747 36.2628 29.2597 35.0824 29.8668L33.8769 30.575V30.5877Z"
          fill="#455068"
        />
        <path
          d="M43.5713 45.3727C43.5588 46.6374 43.3202 47.6998 42.9058 48.5219C42.617 49.1164 42.2402 49.5843 41.8007 49.9384C41.6877 50.0396 41.5747 50.1155 41.4617 50.1661C40.0804 51.0388 38.1591 50.9376 36.0243 49.6728C31.9305 47.2445 28.6279 41.3887 28.6279 36.57C28.6279 34.2934 29.3563 32.6619 30.5492 31.8145L31.1018 31.4857C31.1018 31.4857 31.1269 31.4604 31.152 31.4604C31.7171 31.1948 32.3575 31.0936 33.0607 31.1315C33.99 31.1821 35.0197 31.5109 36.0996 32.156C39.0381 33.8634 41.5747 37.3288 42.7928 40.9334C43.2951 42.4384 43.5839 43.9435 43.5713 45.3727Z"
          fill="white"
        />
        <path
          d="M39.4148 51.3803C38.272 51.3803 37.0037 50.9882 35.6977 50.2167C31.4533 47.6872 28 41.5658 28 36.57C28 34.1669 28.7786 32.2951 30.1976 31.2959L30.7878 30.9418C30.7878 30.9418 30.8631 30.9039 30.9008 30.8786C31.5538 30.575 32.2947 30.4359 33.1109 30.4865C34.1657 30.5497 35.2833 30.9165 36.4261 31.5868C39.4148 33.3196 42.0895 36.8229 43.3955 40.7057C43.948 42.3499 44.2243 43.9056 44.2117 45.36C44.1992 46.7133 43.948 47.8642 43.4708 48.8002C43.1694 49.4325 42.7299 49.9764 42.2025 50.419C42.0769 50.5329 41.9011 50.6467 41.7504 50.7226C41.0723 51.1526 40.2812 51.3676 39.4273 51.3676L39.4148 51.3803ZM31.4156 32.0295L30.8882 32.3457C29.8585 33.0792 29.2683 34.5843 29.2683 36.57C29.2683 41.161 32.4453 46.8018 36.3507 49.129C38.2092 50.2293 39.9045 50.4064 41.1351 49.6349C41.2481 49.5717 41.336 49.5211 41.3988 49.4578C41.8007 49.129 42.1272 48.7243 42.3532 48.2437C42.7425 47.4848 42.9434 46.511 42.956 45.36C42.956 44.0573 42.7174 42.6281 42.2151 41.1231C41.0096 37.5691 38.4981 34.2555 35.7982 32.6872C34.8313 32.118 33.902 31.8018 33.0481 31.7513C32.4453 31.7133 31.9054 31.8145 31.4407 32.0295H31.4156Z"
          fill="#455068"
        />
      </g>
      <defs>
        <clipPath id="clip0_504_130">
          <rect width="71" height="105" fill="white" transform="translate(28 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}
