import { INITIAL_LETTER_FOR_ICON_LIST } from './constants';
import { IconListType } from './types';

export const fetchIconList = async (searchQuery = INITIAL_LETTER_FOR_ICON_LIST) => {
  const fetchIcons = await (await import('@novaera/material-icons')).autoCompleteLoader;
  const result = await fetchIcons(searchQuery);
  return result;
};

export const getIconForAutocomplete = async (iconId: string) => {
  const loadIcon = await (await import('@novaera/material-icons')).iconLoader;
  const result = await loadIcon(iconId);
  return result;
};

export const isIconListType = (value: IconListType | string): value is IconListType => {
  return Boolean(value) && typeof value === 'object' && 'name' in value;
};
