import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CATALOG_RELATIONSHIP_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_RELATIONSHIP_TYPES } from '../keys';
import { CatalogRelationship } from '../types';
import { SearchCatalogRelationshipResult } from '../use-search-catalog-relationship';

type CreateCatalogRelationshipProps = {
  payload: {
    sourceEntityTypeId: string;
    targetEntityTypeId: string;
  };
};

const createCatalogRelationship: (params: CreateCatalogRelationshipProps) => Promise<CatalogRelationship> = async (
  params
) => {
  const result = await NvAxios.post<CatalogRelationship>(`${CATALOG_RELATIONSHIP_ROOT_PATH}`, params.payload);
  return result?.data;
};

export const useCreateCatalogRelationship = () => {
  const cache = useQueryClient();
  return useMutation(createCatalogRelationship, {
    onSuccess: (data, { payload }) => {
      cache.setQueryData<SearchCatalogRelationshipResult>(
        QUERY_KEY_CATALOG_RELATIONSHIP_TYPES.list(payload.sourceEntityTypeId),
        (oldData) => {
          if (!oldData)
            return {
              relationshipDefinitions: [data],
            };

          return {
            relationshipDefinitions: [...oldData.relationshipDefinitions, data],
          };
        }
      );
    },
  });
};
