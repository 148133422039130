import { APP_PERMISSION, JobCreatorType, JobCreatorUser, JobTargetType } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvDivider,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSearchEmptyState,
  NvSkeleton,
  NvTimerOutlinedIcon,
  NvTypography,
  NvWorkflowIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { SimpleSearchInput } from '../../../../components';
import { EmptyState } from '../../../../components/empty-state';
import { EmptyStates } from '../../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { useUserAppPermissionBoundary } from '../../../user-app-permission-boundary/use-user-app-permission-boundary';
import { USER_APP_WORKFLOW } from '../../constants';
import { JobCreatorUserItem, JobLinkItem } from '../components';
import { useRecurringJobs } from '../controllers/use-recurring-jobs';
import { getGMTOffset } from '../utils';
import { RecurringJobItemCard } from './styled';
import { RecurringJobsProps } from './types';

export const RecurringJobs: React.FC<React.PropsWithChildren<RecurringJobsProps>> = ({
  timezoneId,
  handleAddClick,
  handleEditClick,
}) => {
  const { jobs, filteredJobs, isJobsLoading, userAppId, menuItems, theme, searchKeyword, setSearchKeyword } =
    useRecurringJobs({
      timezoneId,
      handleEditClick,
    });
  const { palette } = useTheme();

  const { checkPermission } = useUserAppPermissionBoundary({
    appId: userAppId,
  });

  const { isAppFree } = useIsAppFree({ userAppId });

  return !timezoneId || isJobsLoading ? (
    <NvFlex gap="12px">
      <NvSkeleton height="68px" variant="rectangular" />
      <NvSkeleton height="68px" variant="rectangular" />
      <NvSkeleton height="68px" variant="rectangular" />
    </NvFlex>
  ) : jobs.length === 0 ? (
    <EmptyState
      variant={EmptyStates.RECURRING_JOB}
      CustomButton={
        <FreeAppPermissionBoundary>
          <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
            <NvButton
              startIcon={<NvAddBoxIcon />}
              color="secondary"
              onClick={handleAddClick}
              sx={{ flex: '0 0 auto' }}
              size="small"
            >
              Add recurring job
            </NvButton>
          </UserAppPermissionBoundary>
        </FreeAppPermissionBoundary>
      }
    />
  ) : (
    <NvFlex gap="12px" alignItems="flex-start">
      <SimpleSearchInput
        placeholder="Search jobs"
        onKeywordChanged={(keyword) => {
          setSearchKeyword(keyword ?? '');
        }}
      />
      {filteredJobs.length === 0 && searchKeyword.length > 0 ? (
        <NvBox width="100%">
          <NvSearchEmptyState text={'No recurring jobs found. Try different words or clear search bar.'} />
        </NvBox>
      ) : (
        <NvFlex gap="8px" width="100%">
          {filteredJobs.map((job) => (
            <RecurringJobItemCard
              key={job.id}
              {...(isAppFree
                ? {}
                : {
                    onClick: () => {
                      if (!checkPermission(APP_PERMISSION.APP_EDIT)) {
                        return;
                      }

                      handleEditClick({ id: job.id, type: job.type });
                    },
                  })}
            >
              <NvFlex direction="row" alignItems="center" gap="16px">
                <NvTypography variant="h4" flex="1 1 auto" minWidth={0} noWrap>
                  {job.name}
                </NvTypography>
                <NvFlex direction="row" alignItems="center" gap="12px" flex="0 0 auto">
                  {job.creator.type === JobCreatorType.USER ? (
                    <JobCreatorUserItem creator={job.creator as JobCreatorUser} />
                  ) : (
                    job.creator.type === JobCreatorType.WORKFLOW && (
                      <UserAppPermissionBoundary
                        appId={userAppId}
                        permission={APP_PERMISSION.APP_EDIT}
                        Fallback={
                          <JobLinkItem
                            to={USER_APP_WORKFLOW({ userAppId, workflowId: job.creator.id })}
                            icon={<NvWorkflowIcon />}
                            name={job.creator.displayName}
                            disabled
                          />
                        }
                      >
                        <JobLinkItem
                          to={USER_APP_WORKFLOW({ userAppId, workflowId: job.creator.id })}
                          icon={<NvWorkflowIcon />}
                          name={job.creator.displayName}
                        />
                      </UserAppPermissionBoundary>
                    )
                  )}

                  <FreeAppPermissionBoundary>
                    <NvDivider
                      orientation="vertical"
                      borderColor={theme.palette.nv_neutral[20]}
                      sx={{ height: '12px' }}
                    />
                    <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                      <NvMenuWithItems
                        triggerButton={{
                          content: <NvMoreHorizIcon />,
                          props: { onlyIcon: true, size: 'small', color: 'secondary' },
                        }}
                        menuItems={menuItems(job)}
                      />
                    </UserAppPermissionBoundary>
                  </FreeAppPermissionBoundary>
                </NvFlex>
              </NvFlex>
              <NvFlex gap="8px" alignItems="flex-start">
                <NvFlex direction="row" gap="4px" alignItems="center">
                  <NvTimerOutlinedIcon htmlColor={palette.nv_accent[60]} sx={{ width: '16px', height: '16px' }} />

                  <NvTypography variant="body2" textColor="secondary">
                    {job.timeDisplay.period}
                  </NvTypography>

                  <NvTypography variant="body3" textColor="subtle" flex="0 0 auto">
                    {getGMTOffset(job.timezone.offset)}
                  </NvTypography>
                </NvFlex>
                {job.target.type === JobTargetType.WORKFLOW && (
                  <UserAppPermissionBoundary
                    appId={userAppId}
                    permission={APP_PERMISSION.APP_EDIT}
                    Fallback={
                      <JobLinkItem
                        to={USER_APP_WORKFLOW({ userAppId, workflowId: job.target.id })}
                        icon={<NvWorkflowIcon />}
                        name={job.target.displayName}
                        disabled
                      />
                    }
                  >
                    <JobLinkItem
                      to={USER_APP_WORKFLOW({ userAppId, workflowId: job.target.id })}
                      icon={<NvWorkflowIcon />}
                      name={job.target.displayName}
                    />
                  </UserAppPermissionBoundary>
                )}
              </NvFlex>
            </RecurringJobItemCard>
          ))}
        </NvFlex>
      )}
    </NvFlex>
  );
};
