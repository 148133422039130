import { styled } from '@novaera/theme-provider';

export const ReactQueryDevToolsContainerElement = styled('div')`
  .react-dev-tools-toggle-button {
    margin: 5px !important;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
