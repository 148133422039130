import { isAxiosError, NvAxios, useQuery } from '@novaera/core';
import { ConnectionSchema } from '..';
import { CONNECTION_SCHEMA_ROOT_PATH } from '../constants';

import { QUERY_KEYS_CONNECTION_SCHEMA } from '../keys';
import { UseGetConnectionParams } from './types';

const getConnectionSchema = async (params: UseGetConnectionParams) => {
  const { schemaId, integrationId } = params;

  const result = await NvAxios.get<ConnectionSchema>(`${CONNECTION_SCHEMA_ROOT_PATH}/${schemaId}`, {
    ...(params.integrationId && { params: { integrationId } }),
  });
  return result?.data;
};

export const useGetConnectionSchemaService = (params: UseGetConnectionParams) => {
  return useQuery<ConnectionSchema>(
    QUERY_KEYS_CONNECTION_SCHEMA.connection_schema(params.schemaId, params.integrationId),
    () => getConnectionSchema(params),
    {
      // there are modal that open on top of the connections page
      // when the oauth flow is started and client returns to the connections page
      // that refetch is causing the modal to close
      refetchOnWindowFocus: false,
      enabled: Boolean(params.schemaId),
      retry(failureCount, error) {
        const isAxios = isAxiosError(error);
        const isNotFound = isAxios && error.response?.status === 404;
        return !isNotFound;
      },
      onError: (error) => {
        const isAxios = isAxiosError(error);
        const isNotFound = isAxios && error.response?.status === 404;
        if (!isNotFound) {
          throw error;
        }
      },
    }
  );
};
