import {
  IntegrationAction,
  useGetIntegration,
  useGetIntegrationAction,
  useUpdateIntegrationActionMetadata,
} from '@novaera/actioner-service';
import {
  NvDialogModal,
  NvField,
  NvTagsAutoComplete,
  NvTextArea,
  NvTextField,
  isMaxLength,
  isRequired,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';

export const UpdateActionMetadataModal: React.FC<React.PropsWithChildren<{ open: boolean; onClose: () => void }>> = ({
  open,
  onClose,
}) => {
  const { actionId, integrationId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { persistedIntegrationAction } = useGetIntegrationAction({
    actionId,
    integrationId,
    version: integration?.latestVersion.number,
  });
  const { mutate: updateActionMetadata, isLoading: isUpdatingIntegrationEventRuleMetadata } =
    useUpdateIntegrationActionMetadata();
  return (
    <NvDialogModal<Pick<IntegrationAction, 'name' | 'description' | 'tags'>>
      onFormSubmit={({ name, description, tags }) => {
        return new Promise<void>((resolve) => {
          const version = integration?.latestVersion?.number;
          assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');
          updateActionMetadata(
            { actionId, integrationId, name, description, version, tags },
            {
              onSuccess: () => {
                onClose();
              },
              onSettled: () => {
                resolve();
              },
            }
          );
        });
      }}
      Header="Update action"
      modalIcon="edit"
      open={open}
      onCloseButtonClicked={onClose}
      isLoading={isUpdatingIntegrationEventRuleMetadata}
      fullWidth
      Body={
        <>
          <NvField
            name="name"
            direction="label-on-top"
            labelVariant="h5"
            labelText="Name"
            showErrorMessageOnlyWhenBlur
            isRequired
            validators={[isRequired()]}
            component={<NvTextField />}
            hasRequiredIndicator
          />
          <NvField
            name="description"
            direction="label-on-top"
            labelVariant="h5"
            labelText="Description"
            validators={[isMaxLength({ length: 3000 })]}
            component={<NvTextArea rows={4} placeholder="Enter description" />}
            showErrorMessageOnlyWhenBlur
          />
          <NvField
            direction="label-on-top"
            name="tags"
            labelText="Tags"
            showErrorMessageOnlyWhenBlur
            isAutoComplete
            component={<NvTagsAutoComplete placeholder={'Add tags'} />}
          />
        </>
      }
      maxWidth="sm"
      primaryButtonText={'Save'}
      formProps={{
        initialValues: {
          name: persistedIntegrationAction?.name ?? '',
          description: persistedIntegrationAction?.description ?? '',
          tags: persistedIntegrationAction?.tags ?? [],
        },
      }}
    />
  );
};
