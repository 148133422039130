import { NvAxios, useQuery } from '@novaera/core';
import { SLACK_INTEGRATION_METADATA_KEYS } from '../keys';
import { MetaData, MetaDataQueryParams } from './types';

const getMetaData = async (params?: MetaDataQueryParams) => {
  const endpoint = params?.isAnonymous ? '/v1/slack/connections/anonymous/metadata' : '/v1/slack/connections/metadata';
  const { isAnonymous, ...queryParams } = params ?? {};
  const { data } = await NvAxios.get<MetaData>(endpoint, {
    params: queryParams,
  });
  return data;
};

export const useGetMetaData = (params?: MetaDataQueryParams, enabled?: boolean) => {
  return useQuery([SLACK_INTEGRATION_METADATA_KEYS.lists(), params], () => getMetaData(params), {
    enabled,
  });
};
