import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldInputProps } from 'react-final-form';

import { NvBox, NvField, NvKeyValueInput, NvSelect } from '@novaera/core';

import { StyledSubTabs, SubTabsSelectActionBox } from '../styled';

import { HTTPRequestConfiguration, MediaTypes } from '@novaera/actioner-service';

import { IntegrationCodeField } from '../../../../../../integration-code-field';
import { RequestCustomInput } from '../../request-custom-input';
import { RequestFormDataInput } from '../../request-form-data-input';

export const TabsAdapter: React.FC<
  React.PropsWithChildren<{ input: FieldInputProps<HTTPRequestConfiguration, HTMLElement> }>
> = ({ input: { value, onChange, name }, ...rest }) => {
  const bodyFormatOptions = useMemo(() => Object.values(MediaTypes).map((m) => ({ label: m, value: m })), []);
  const selectedTab = useMemo((): number => {
    if (value.mediaType === MediaTypes.MULTIPART_FORM_DATA) {
      return 0;
    } else if (value.mediaType === MediaTypes.APPLICATION_FORM_URLENCODED) {
      return 1;
    } else {
      return 2;
    }
  }, [value.mediaType]);

  const findSelectedMediaType = useCallback(
    (index: number): MediaTypes => {
      if (index === 0) {
        return MediaTypes.MULTIPART_FORM_DATA;
      } else if (index === 1) {
        return MediaTypes.APPLICATION_FORM_URLENCODED;
      } else {
        return value.mediaType;
      }
    },
    [value.mediaType]
  );
  const [showMediaType, setShowMediaType] = useState<boolean>(selectedTab === 2 ? true : false);

  const tabs = useMemo(
    () => [
      {
        label: 'Form-data',
        content: (
          <NvKeyValueInput
            addNewInputLabel="Add new form field"
            component={<RequestFormDataInput />}
            fieldArrayName={`${name}.multipartFormParameters`}
            hasRemoveAction
            keyTitle="KEY"
            valueTitle="VALUE"
            keyFieldName="key"
            valueFieldName="value"
            KeyWrapperStyle={{
              maxWidth: '300px',
            }}
          />
        ),
      },
      {
        label: 'Form URL encoded',
        content: (
          <NvKeyValueInput
            addNewInputLabel="Add new form field"
            component={<RequestCustomInput />}
            fieldArrayName={`${name}.formParameters`}
            hasRemoveAction
            keyTitle="KEY"
            valueTitle="VALUE"
            keyFieldName="key"
            valueFieldName="valueGenerationTemplate"
          />
        ),
      },
      {
        label: 'Raw',
        content: <NvField name={`${name}.body.raw`} component={<IntegrationCodeField expectedType="string" />} />,
      },
    ],
    [name]
  );

  useEffect(() => {
    if (value.mediaType) {
      setShowMediaType(selectedTab === 2 ? true : false);
    }
  }, []);

  return (
    <NvBox>
      <StyledSubTabs
        variant="button"
        defaultActiveTabIndex={selectedTab}
        onActiveTabChanged={(activeIndex) => {
          if (activeIndex === 2) {
            setShowMediaType(true);
          } else {
            setShowMediaType(false);
          }

          const selectedMediaType = findSelectedMediaType(activeIndex);

          if (
            activeIndex === 2 &&
            (selectedMediaType === MediaTypes.MULTIPART_FORM_DATA ||
              selectedMediaType === MediaTypes.APPLICATION_FORM_URLENCODED)
          ) {
            if (value.mediaType !== MediaTypes.APPLICATION_JSON)
              onChange({ ...value, mediaType: MediaTypes.APPLICATION_JSON });
          } else {
            if (value.mediaType !== selectedMediaType) {
              onChange({ ...value, mediaType: selectedMediaType });
            }
          }
        }}
        customWrapperClassName="sub-tabs-action-designer"
        commandLineHeight={44}
        tabs={tabs}
        focusIndex={selectedTab}
        {...(showMediaType
          ? {
              CustomTabActionComponents: [
                <SubTabsSelectActionBox key="select_body_format">
                  <NvField
                    name={`${name}.mediaType`}
                    defaultValue={MediaTypes.APPLICATION_JSON}
                    component={<NvSelect compact options={bodyFormatOptions} />}
                  />
                </SubTabsSelectActionBox>,
              ],
            }
          : {})}
        {...rest}
      />
    </NvBox>
  );
};
