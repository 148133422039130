import { NvAxios, useQuery } from '@novaera/core';
import { ADMIN_APP_DIRECTORY_PREFIX } from '../constants';
import { AVAILABLE_TAGS_LIST } from '../keys';
import { GetTagsResponse } from '../types';

const getAvailableTags = async () => {
  const result = await NvAxios.get<GetTagsResponse>(`${ADMIN_APP_DIRECTORY_PREFIX}/schemas/tags`);
  return result?.data;
};

export const useGetAvailableSchemaTags = () => {
  return useQuery(AVAILABLE_TAGS_LIST, () => getAvailableTags());
};
