import { NvBox, NvSkeleton } from '@novaera/core';
import { SideMenu } from '../../../../components/side-menu';
import { SideMenuDividerItem } from '../../../../components/side-menu/divider';
import { TitleLoading } from '../../../../components/side-menu/title-loading';
import { UserAppSideMenuItemLoadingFrameMain } from './styled';

export const UserAppSideMenuLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SideMenu>
    <TitleLoading />
    <UserAppSideMenuItemLoadingFrameMain>
      <NvBox display="flex" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="16px" height="16px" />
        <NvSkeleton variant="rectangular" height="16px" width="180px" />
      </NvBox>
    </UserAppSideMenuItemLoadingFrameMain>
    <UserAppSideMenuItemLoadingFrameMain>
      <NvBox display="flex" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="16px" height="16px" />
        <NvSkeleton variant="rectangular" height="16px" width="180px" />
      </NvBox>
    </UserAppSideMenuItemLoadingFrameMain>
    <UserAppSideMenuItemLoadingFrameMain>
      <NvBox display="flex" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="16px" height="16px" />
        <NvSkeleton variant="rectangular" height="16px" width="180px" />
      </NvBox>
    </UserAppSideMenuItemLoadingFrameMain>
    <UserAppSideMenuItemLoadingFrameMain>
      <NvBox display="flex" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="16px" height="16px" />
        <NvSkeleton variant="rectangular" height="16px" width="180px" />
      </NvBox>
    </UserAppSideMenuItemLoadingFrameMain>
    <UserAppSideMenuItemLoadingFrameMain>
      <NvBox display="flex" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="16px" height="16px" />
        <NvSkeleton variant="rectangular" height="16px" width="180px" />
      </NvBox>
    </UserAppSideMenuItemLoadingFrameMain>
    <SideMenuDividerItem />
    <UserAppSideMenuItemLoadingFrameMain>
      <NvBox display="flex" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="16px" height="16px" />
        <NvSkeleton variant="rectangular" height="16px" width="180px" />
      </NvBox>
    </UserAppSideMenuItemLoadingFrameMain>
    <SideMenuDividerItem />
  </SideMenu>
);
