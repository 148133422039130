import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledListItem = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 24px;
  padding: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid transparent;
  ${({ theme }) => theme.elevations.e100};
  transition: background-color 200ms ease 0s, border-color 300ms ease-in-out 0s, box-shadow 200ms ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    border-color: ${({ theme }) => theme.palette.nv_neutral[30]};
    &.has-error {
      border: 1px solid ${({ theme }) => theme.palette.nv_error[30]};
    }
  }
  &.selected-item {
    border-color: ${({ theme }) => theme.palette.nv_main[40]};
    ${({ theme }) => theme.mixins.itemSelected};
    background-color: ${({ theme }) => theme.palette.nv_main[10]};
    &:hover {
      border-color: ${({ theme }) => theme.palette.nv_main[40]};
      ${({ theme }) => theme.mixins.itemSelected};
      background-color: ${({ theme }) => theme.palette.nv_main[10]};
    }
  }
  &.has-error {
    border: 1px solid ${({ theme }) => theme.palette.nv_error[30]};
    &.selected-item {
      ${({ theme }) => theme.mixins.itemSelected};
      border-color: ${({ theme }) => theme.palette.nv_main[40]};
    }
  }
`;

export const StyledListItemLeft = styled(NvFlex)`
  flex: 1 1 auto;
  gap: 8px;
  width: 100%;
  min-width: 0;
`;

export const StyledListItemRight = styled(NvFlex)`
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: auto;
`;

export const SmallMarkdownWrapper = styled(NvFlex)`
  &.small-markdown-wrapper {
    font-size: 12px;

    strong,
    em,
    i,
    a {
      font-size: 12px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 12px;

      strong,
      em,
      i,
      a {
        font-size: 12px;
      }
    }
  }
`;
