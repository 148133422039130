import { assert, generateUniqueId } from '@novaera/utils';
import { FC, ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react';
import { FormIdentifierType } from './types';

const FormIdentifierContext = createContext<FormIdentifierType | undefined>(undefined);

export const FormIdentifierProvider: FC<{
  initialFormId?: string;
  children: ((params: FormIdentifierType) => ReactNode) | ReactNode;
}> = ({ children, initialFormId }) => {
  const [statefulFormId, setStatefulFormId] = useState(() => {
    return initialFormId ?? generateUniqueId();
  });

  const handleFormIdChange = useCallback((newFormId: string) => {
    setStatefulFormId(newFormId);
  }, []);

  const formIdRef = useRef(generateUniqueId());

  const value: FormIdentifierType = {
    formId: initialFormId ? initialFormId : formIdRef.current,
    getStatefulFormId: () => statefulFormId,
    setStatefulFormId: handleFormIdChange,
  };

  return (
    <FormIdentifierContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </FormIdentifierContext.Provider>
  );
};

export const useFormIdentifierContext = () => {
  const context = useContext(FormIdentifierContext);
  assert(!!context, new Error(`useFormIdentifierContext should be used within FormIdentifierProvider`), 'ERROR');
  return context;
};
