import {
  isMaxLength,
  isMinLength,
  isRequired,
  isUnique,
  isWordChar,
  NvAddIcon,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvEditIcon,
  NvField,
  NvForm,
  NvTextField,
  NvTypography,
} from '@novaera/core';

import { InitModalValue, RequestModalParams } from './type';

import { lowerCase } from 'lodash';
import { useRequestModal } from './controllers/use-create-request';

export const RequestModal: React.FC<React.PropsWithChildren<RequestModalParams>> = ({ isOpened, onClose, ...rest }) => {
  const { onSubmit, formInitialValues, requestListForValidation, handleClose } = useRequestModal({
    onClose,
    ...rest,
  });

  return (
    <NvDialogV1 open={Boolean(isOpened)} maxWidth="sm" fullWidth disableEscapeKeyDown>
      <NvForm<InitModalValue> onSubmit={onSubmit} initialValues={formInitialValues}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              {rest.mode === 'update' ? <NvEditIcon /> : <NvAddIcon />}
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">{rest.mode === 'update' ? 'Update request' : 'Add new request'}</NvTypography>
            </NvDialogSlots.Slot>

            <NvDialogSlots.Slot name="content">
              <NvBox>
                <NvField
                  name="name"
                  labelText="Request name"
                  labelVariant="h5"
                  direction="label-on-top"
                  hasRequiredIndicator
                  showErrorMessageOnlyWhenBlur
                  validators={[
                    isRequired({
                      message: 'Request name can not be empty',
                    }),
                    isWordChar({
                      message: "Request name can only contain letters, numbers and '_'",
                    }),
                    isUnique({
                      message: 'Request name can be unique',
                      list: requestListForValidation,
                      filterFunction: (current, value) => {
                        return lowerCase(current.name) === lowerCase(value);
                      },
                    }),
                    isMinLength({
                      length: 3,
                    }),
                    isMaxLength({
                      length: 150,
                    }),
                  ]}
                  component={<NvTextField autoComplete="off" startLabelAdornment="request." />}
                />
              </NvBox>
            </NvDialogSlots.Slot>

            <NvDialogSlots.Slot name="actions">
              <NvButton disabled={submitting} type="submit">
                {rest.mode === 'update' ? 'Update' : 'Add'}
              </NvButton>
              <NvButton disabled={submitting} onClick={handleClose} color="ghost">
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={handleClose}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
