import { useGetSlackWorkspace } from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { useQueryParams } from '@novaera/route';
import { useEffect, useRef, useState } from 'react';

export const useSlackWorkspace = () => {
  const { data: slackWorkspace, isInitialLoading } = useGetSlackWorkspace();
  const { addToast } = useToast();
  const { getSearchParams, removeQueryParams } = useQueryParams();
  const { error } = getSearchParams<{ error?: string }>() ?? { error: undefined };
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState<boolean>(false);

  const isErrorShown = useRef(false);

  useEffect(() => {
    if (error && !isErrorShown.current) {
      isErrorShown.current = true;
      addToast(error, { variant: 'error' });
      removeQueryParams(['error']);
    }
  }, [addToast, error, removeQueryParams]);

  return {
    isSlackWorkspaceFetchLoading: isInitialLoading,
    slackWorkspace,
    isInviteUserModalOpen,
    setIsInviteUserModalOpen,
  };
};
