import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, LOCAL_STORAGE_ACCESS_KEY, ROUTE_DEFAULTS } from '@novaera/constants';
import { useQueryParams } from '@novaera/route';
import Cookies from 'js-cookie';
import { FC } from 'react';
import { AppLoading } from '../app-loading';

export const Redirection: FC<React.PropsWithChildren<unknown>> = () => {
  const { getSearchParams } = useQueryParams();
  const { targetUri, path = ROUTE_DEFAULTS.SIGN_IN } = getSearchParams<{ targetUri: string; path: string }>() || {};
  const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);
  const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);

  if (lastActiveWorkspace && accessToken && targetUri) {
    window.location.href = `${window.location.protocol}//${lastActiveWorkspace}.${process.env.NX_FRONTEND_HOST}${targetUri}`;
  } else {
    window.location.href = `${ApplicationConfig.Actioner.origin}${path}?targetUri=${targetUri}`;
  }
  return <AppLoading />;
};
