import { NvAutocomplete, NvTextField } from '@novaera/core';
import { CRON_MONTH_OPTIONS } from '../../save-job-modal/configure-recurrence/constants';
import { CronMonthPickerProps } from './types';

export const CronMonthPicker: React.FC<CronMonthPickerProps> = ({ cron, onChange }) => {
  const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = cron.split(' ');
  const selectedMonth = CRON_MONTH_OPTIONS.filter(({ value }) => month.split(',').includes(value));
  return (
    <NvAutocomplete
      value={selectedMonth}
      multiple
      options={CRON_MONTH_OPTIONS}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
        const m = value.map((v) => v.value).join(',');
        const cron = `${minutes} ${hours} ${dayOfMonth} ${m || '?'} ${dayOfWeek} ${year}`;
        onChange(cron);
      }}
      renderInput={(params) => (
        <NvTextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          size="medium"
        />
      )}
    />
  );
};
