import { NvAxios } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { setDefaultSearchUserAppParams } from '../utils';
import { SearchUserAppParams, SearchUserAppResponse } from './types';

const searchUserApps = async ({ payload, next }: SearchUserAppParams) => {
  assert(!!payload, new Error('[searchUserApps] - SearchUserApp payload can not be undefined!'), 'ERROR');
  const { keyword = '', from = 0, sortOption = { field: 'name', order: 'asc' }, limit = 20, filters } = payload;

  const result = await NvAxios.post<SearchUserAppResponse>(`${USER_APP_ROOT_PATH}/search`, {
    keyword,
    limit,
    ...(keyword.length === 0 ? { sortOption } : {}),
    ...(filters ? { filters } : {}),
    ...(next ? { from: next } : { from }),
  });
  return result?.data;
};

export const useSearchUserApps = ({ payload }: SearchUserAppParams) => {
  const searchPayload = setDefaultSearchUserAppParams(payload);
  return useQuery<SearchUserAppResponse>(
    QUERY_KEYS_USER_APP.search(searchPayload),
    () => searchUserApps({ payload: searchPayload }),
    { enabled: !!payload }
  );
};

export const useInfiniteSearchUserApps = ({ payload }: SearchUserAppParams) => {
  const searchPayload = setDefaultSearchUserAppParams(payload);
  return useInfiniteQuery<SearchUserAppResponse>(
    QUERY_KEYS_USER_APP.infiniteSearch(searchPayload),
    ({ pageParam }) => searchUserApps({ payload: searchPayload, next: pageParam }),
    {
      enabled: !!payload,
      getNextPageParam: (lastPage) => lastPage.next,
    }
  );
};
