import React from 'react';
import { NvErrorBoundary } from '@novaera/core';
import { ErrorBoundaryProps } from './types';
import { useErrorBoundary } from './controllers/use-error-boundary';

export const ErrorBoundary: React.FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({
  variant,
  FallbackComponent,
  children,
}) => {
  const { ErrorComponent } = useErrorBoundary({
    variant,
    FallbackComponent,
  });

  return <NvErrorBoundary fallback={(props) => <ErrorComponent {...props} />}>{children}</NvErrorBoundary>;
};
