import { NodeUnionTypeEnumLike, ResponseNode, useGetNode, useUpdateNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useWorkflowPermission } from '../../../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { userAppGraph } from '../../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../../provider';

export const useResponsePropertyPanel = () => {
  const { userAppId: appId, workflowId } = useParams();
  const { selectedNode } = usePropertyPanelContext();
  const { node: responseNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.response>({
    workflowId,
    appId,
    nodeAlias: selectedNode?.id,
  });
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();

  const { updateNode } = useNovaeraFlow(userAppGraph);

  const handleSave = useCallback(
    (newNode: ResponseNode) => {
      assert(responseNode !== undefined && Boolean(responseNode.alias), new Error('responseNode is undefined'));

      if (!hasEditPermission) return;

      updateNodeDetail(
        { appId, workflowId, nodeAlias: responseNode.alias, payload: newNode },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(newNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newNode.name;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [responseNode, hasEditPermission, updateNodeDetail, appId, workflowId, updateNode]
  );

  const handleSaveResponses = useCallback(
    (updates: Pick<ResponseNode, 'executionResult' | 'responseTemplate' | 'exampleResponse'>) => {
      assert(responseNode !== undefined && Boolean(responseNode.alias), new Error('responseNode is undefined'));

      const newNode = { ...responseNode, ...updates };

      handleSave(newNode);
    },
    [responseNode, handleSave]
  );

  const handleSaveTitle = useCallback(
    (title: string) => {
      assert(responseNode !== undefined && Boolean(responseNode.alias), new Error('responseNode is undefined'));

      const newNode = { ...responseNode, name: title };
      handleSave(newNode);
    },
    [responseNode, handleSave]
  );

  return {
    onSaveResponses: handleSaveResponses,
    onSaveTitle: handleSaveTitle,
    hasEditPermission,
    isLoading,
    responseNode,
  };
};
