import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetConfigResponse } from '../use-get-config/types';
import { GetConfigsResponse } from '../use-get-configs/types';
import { UpdateConfigParams, UpdateConfigResponse } from './types';

const updateConfig: (params: UpdateConfigParams) => Promise<UpdateConfigResponse> = async ({
  appId,
  configId,
  ...params
}) => {
  const result = await NvAxios.patch<UpdateConfigResponse>(`${CONFIG_ROOT_PATH(appId)}/${configId}`, params);
  return result?.data;
};

export const useUpdateConfig = () => {
  const cache = useQueryClient();

  return useMutation(updateConfig, {
    onSuccess: (config, { appId }) => {
      const { id, name, createdAt, version, properties, ...restOfConfig } = config;
      cache.setQueryData<InfiniteData<GetConfigsResponse>>(QUERY_KEYS_CONFIGS.list(appId), (old) => {
        if (old) {
          return {
            ...old,
            pages: old?.pages.map((page) => ({
              ...page,
              configurations: page.configurations.map((c) => (c.id === id ? { ...c, name } : c)),
            })),
          };
        }
        return old;
      });
      cache.setQueryData<GetConfigResponse>(QUERY_KEYS_CONFIGS.detail(appId, id), (old) => {
        return { ...old, saved: { id, name, createdAt, version, properties, ...restOfConfig } };
      });
    },
  });
};
