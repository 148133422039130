import { AppSchemasPayload } from '../types';

export const generatedAppSchemasSearchKey = (params: AppSchemasPayload) => {
  const { queries, pagination, sortParams } = params;
  // If we want to add new parameter for search app schemas, we need get this from params and add orderGuaranteedParams object.
  const orderGuaranteedParams = {
    ...(queries ? { queries: JSON.stringify(queries) } : {}),
    ...(pagination ? { pagination: JSON.stringify(pagination) } : {}),
    ...(sortParams ? { sortParams: JSON.stringify(sortParams) } : {}),
  };
  const searchAppSchemasParamsKeys = Object.keys(orderGuaranteedParams) as Array<keyof typeof orderGuaranteedParams>;
  const queryStringToCache =
    searchAppSchemasParamsKeys.length > 0
      ? searchAppSchemasParamsKeys.reduce(
          (acc, cur) => `${acc}${acc.length > 0 ? '&' : ''}${cur}="${orderGuaranteedParams[cur]}"`,
          ''
        )
      : '';

  return queryStringToCache;
};
