import { APP_PERMISSION, AccessType } from '@novaera/actioner-service';
import { NvButton, NvEditIcon, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { FC } from 'react';
import { UserAndGroupImage } from '../../../../components/user-and-group-image';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { AppLevelMenu } from '../../../user-app-permission-boundary/app-level-menu';
import { USER_APP_PERMISSIONS } from '../../constants';
import { useUserAppUsersWithPermissions } from '../../permissions/use-user-app-users-with-permissions';
import { AppAdminsHeader } from './styled';

export const AppAdmins: FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useParams();
  const navigate = useNavigate();
  const { userAppAdmins, isUserAppLoading, isBatchGetLoading } = useUserAppUsersWithPermissions({ userAppId });

  return !isUserAppLoading && !isBatchGetLoading ? (
    <NvFlex gap="8px" width="100%">
      <AppAdminsHeader>
        <NvTypography variant="h4" flex="1 1 auto" min-width="0">
          App admins
        </NvTypography>
        <AppLevelMenu menuItemId={'permissions'}>
          <UserAppPermissionBoundary permission={APP_PERMISSION.APP_EDIT} appId={userAppId}>
            <NvButton color="ghost" onlyIcon size="small" onClick={() => navigate(USER_APP_PERMISSIONS(userAppId))}>
              <NvEditIcon />
            </NvButton>
          </UserAppPermissionBoundary>
        </AppLevelMenu>
      </AppAdminsHeader>
      {userAppAdmins.map((user) => {
        return user.principalType === 'user' ? (
          <NvFlex direction="row" gap="8px" alignItems="center" key={user.userDetail?.userId}>
            <UserAndGroupImage type={AccessType.USER} id={user.principalId} src={user.userDetail?.logoUrl} />

            <NvFlex flex={'1 1 auto'} minWidth={'0'}>
              <NvTypography variant="body1" noWrap>
                {user.userDetail?.userName}
              </NvTypography>
              <NvTypography variant="body3" textColor="subtle" noWrap>
                {user.userDetail?.email}
              </NvTypography>
            </NvFlex>
          </NvFlex>
        ) : user.principalType === 'group' ? (
          <NvFlex direction="row" gap="8px" alignItems="center" key={user.groupDetail?.id}>
            <UserAndGroupImage type={AccessType.GROUP} id={user.principalId} src={user.groupDetail?.logoUrl} />

            <NvFlex flex={'1 1 auto'} minWidth={'0'}>
              <NvTypography variant="body1" noWrap>
                {user.groupDetail?.name}
              </NvTypography>
            </NvFlex>
          </NvFlex>
        ) : null;
      })}
    </NvFlex>
  ) : (
    <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
      <NvTypography variant="h4" flex="1 1 auto" min-width="0">
        App admins
      </NvTypography>
      <NvFlex flex="1 1 auto">
        <NvSkeleton width="100%" />
      </NvFlex>
    </NvFlex>
  );
};
