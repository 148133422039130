import { InputParameterValues, StringValue } from '..';

export type RecordField = {
  id: string;
  value?: InputParameterValues;
};

export enum PartialUpdateQueryOption {
  ADD = 'add',
  SET = 'set',
  REMOVE = 'remove',
}

export type RecordUpdate = {
  field: RecordField;
  type: PartialUpdateQueryOption;
  path?: StringValue;
};
