import { BillingCycle } from '@novaera/actioner-service';
import { NvButton, NvDialogModal } from '@novaera/core';
import { FC } from 'react';
import { SpendingLimitModalBody } from './spending-limit-modal-body';
import { SpendingLimitModalProps } from './types';

export const SpendingLimitModal: FC<SpendingLimitModalProps> = ({
  onClose,
  open,
  currentSubscriptionDetails,
  billingPreferences,
}) => {
  return (
    <NvDialogModal<{ planId?: string; billingCycle?: BillingCycle }>
      unmountOnExit
      modalIcon="checkbox"
      Header={`You're now subscribed to ${currentSubscriptionDetails.planName}!`}
      maxWidth="sm"
      onCloseButtonClicked={onClose}
      Footer={<NvButton onClick={onClose}>Done</NvButton>}
      open={open}
      Body={
        <SpendingLimitModalBody
          initialBillingPreferences={billingPreferences}
          currentSubscriptionDetails={currentSubscriptionDetails}
        />
      }
    />
  );
};
