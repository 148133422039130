import { NvTypography } from '@novaera/core';
import { ContributorAvatar, ContributorContainer } from './styled';
import { ContributorProps } from './types';

export const Contributor: React.FC<React.PropsWithChildren<ContributorProps>> = ({ contributor }) => (
  <ContributorContainer>
    <ContributorAvatar alt={contributor.name} src={contributor.logoUrl} />
    <NvTypography variant="h5" noWrap>
      {contributor.name}
    </NvTypography>
  </ContributorContainer>
);
