import { RequestOptionProducer } from '@novaera/actioner-service';
import { FieldArray } from 'react-final-form-arrays';
import { PropertyPanelEmptySection, PropertyPanelSimpleSection } from '../../../../../../components';
import { RequestOptionProducerItem } from '../request-option-producer-item';

export const IntegrationActionDynamicDataSourceMapper = () => (
  <FieldArray<RequestOptionProducer> name={`uiComponent.dataSource.optionsProducers`} defaultValue={[]}>
    {({ fields }) => {
      const optionProducers = fields.value as RequestOptionProducer[] | undefined;
      return !optionProducers || optionProducers.length === 0 ? (
        <PropertyPanelEmptySection emptyText="This field doesn’t have any source mapping yet." />
      ) : (
        optionProducers.map((optionProducer, index) => {
          return (
            <PropertyPanelSimpleSection key={`${index}`}>
              <RequestOptionProducerItem
                producerFieldName={`uiComponent.dataSource.optionsProducers[${index}]`}
                onRemove={() => {
                  fields.remove(index);
                }}
              />
            </PropertyPanelSimpleSection>
          );
        })
      );
    }}
  </FieldArray>
);
