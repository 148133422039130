import { styled } from '@novaera/theme-provider';
import { BasicTableRow } from '../basic-table/styled';

export const KeyValueTableRowContainer = styled(BasicTableRow, {
  shouldForwardProp: (prop) => prop !== 'isRowSelectable',
})<{
  isRowSelectable?: boolean;
}>`
  ${({ isRowSelectable }) => (isRowSelectable ? 'cursor:pointer;' : null)}
  &:hover {
    ${({ isRowSelectable }) => (!isRowSelectable ? 'background-color:transparent !important; ' : null)}
  }

  position: relative;
  z-index: 10;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
    z-index: -1;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    background-color: rgba(84, 172, 253, 0.03);
  }

  &.isSelected {
    &::after {
      opacity: 1;
    }
  }

  &:first-of-type {
    &::after {
      border-radius: 12px 12px 0 0;
    }
  }

  &:last-of-type {
    &::after {
      border-radius: 0 0 12px 12px;
    }
  }
`;
