import {
  MarkdownOutputComponent,
  OutputNode,
  SlackBlocks,
  useUpdateNode,
  WorkflowState,
} from '@novaera/actioner-service';
import {
  NvBox,
  NvConditionalRender,
  NvCustomShareIcon,
  NvField,
  NvFlex,
  NvForm,
  NvSwitch,
  NvTypography,
} from '@novaera/core';
import { noop } from '@novaera/utils';
import { FC, useCallback } from 'react';
import { PropertyPanelSimpleSection } from '../../../../../../../../../components';
import { SlackBlocksComponent } from '../../../../../../../../../components/ui-components/slack-blocks-component';
import { FieldInputType } from '../../../../../../../../../components/ui-components/use-ui-component/types';
import { useWorkflowPermission } from '../../../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { OutputOrder } from '../common/output-order';
import { StyledNvField } from './styled';
import { MarkdownPanelSectionProps } from './types';

export const MarkdownPanelSection: FC<React.PropsWithChildren<MarkdownPanelSectionProps>> = ({
  markdownOutputComponent,
  alias,
  name,
  userAppId,
  workflowId,
}) => {
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { hasEditPermission } = useWorkflowPermission();

  const handleSave = useCallback(
    (newMarkdownOutputComponent: MarkdownOutputComponent) => {
      const newNode: OutputNode = {
        alias,
        name,
        outputComponent: newMarkdownOutputComponent,
        type: 'output',
        state: WorkflowState.DRAFT,
      };

      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: alias,
        payload: newNode,
      });
    },
    [alias, name, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvForm<MarkdownOutputComponent>
      onSubmit={noop}
      onChange={({ values }) => {
        handleSave(values);
      }}
      initialValues={markdownOutputComponent}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <StyledNvField
        component={(props) => {
          const actualPropsType = props as unknown as FieldInputType<SlackBlocks>;
          return (
            <SlackBlocksComponent context={workflowCodeInputContext} inputProps={actualPropsType} type="markdown" />
          );
        }}
        name={'blocks'}
        defaultValue={[]}
      />
      <NvConditionalRender when={hasEditPermission}>
        <OutputOrder />
      </NvConditionalRender>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelSimpleSection>
          <NvBox width={'100%'}>
            <NvField<boolean>
              formControlStyle={{ width: '100%' }}
              name="showShareButton"
              type="checkbox"
              component={({ onChange, checked, value }) => {
                return (
                  <NvFlex flexDirection={'row'} alignItems={'center'}>
                    <NvFlex direction={'row'} gap={'8px'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
                      <NvCustomShareIcon />
                      <NvTypography variant="body2">Show share button</NvTypography>
                    </NvFlex>
                    <NvSwitch value={value} onChange={onChange} checked={checked} variant="compact" />
                  </NvFlex>
                );
              }}
            />
          </NvBox>
        </PropertyPanelSimpleSection>
      </NvConditionalRender>
    </NvForm>
  );
};
