import { COMMON_FONT_FAMILY } from '..';
import metadata from '../../../design-tokens/typography';
import { TypographyMetaData, TypographyMetaDataFont, TypographyValue, Typography } from './types';

const typographyMetaData: TypographyMetaData = metadata.font;
const typographyMetaDataFont: Partial<TypographyMetaDataFont> = Object.keys(typographyMetaData).reduce(
  (acc, cur) => ({ ...acc, ...typographyMetaData[cur as keyof typeof typographyMetaData] }),
  {}
);

export const typography: Partial<Typography> = Object.keys(typographyMetaDataFont).reduce((acc, cur) => {
  const { fontFamily, textCase, lineHeight, ...restOfValue } = typographyMetaDataFont[
    cur as keyof typeof typographyMetaDataFont
  ]?.value as TypographyValue;
  return {
    ...acc,
    ...{
      [cur]: {
        ...restOfValue,
        ...(cur === 'c1' || cur === 'c2' || cur === 'c3'
          ? { fontFamily: `${fontFamily}, monospace` }
          : { fontFamily: COMMON_FONT_FAMILY }),
        lineHeight: `${lineHeight}px`,
        textTransform: textCase,
      },
    },
  };
}, {});
