import { NvFlex, NvFocusState } from '@novaera/core';
import { useParams } from '@novaera/route';
import { noop } from 'lodash';
import { RecordsDetail } from './records-detail';
import { RecordsMenu } from './records-menu';

export const Records: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { modelId } = useParams();
  return (
    <NvFocusState open variant="fullWidth" onClose={noop}>
      <NvFlex direction="row" flex="1 1 auto" minHeight={0} width="100%">
        <RecordsMenu />
        <NvFlex flex="1 1 auto" minWidth="0">
          <RecordsDetail key={modelId} />
        </NvFlex>
      </NvFlex>
    </NvFocusState>
  );
};
