import { NvAxios } from '@novaera/core';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOW_SOURCE_DYNAMIC_INPUT } from '../keys';
import {
  GetWorkflowDynamicInputParams,
  GetWorkflowDynamicInputParamsForMultipleInputParameters,
  GetWorkflowDynamicInputResponse,
} from './types';

const getWorkflowDynamicInput = async ({ appId, workflowId, payload }: GetWorkflowDynamicInputParams) => {
  const result = await NvAxios.post<GetWorkflowDynamicInputResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/dynamic-input-parameters`,
    payload
  );
  return result?.data;
};

export const useGetWorkflowDynamicInput = ({
  requestParams,
  allInputParameterIdsWithOrder,
  options,
  enabledInputParameters,
}: GetWorkflowDynamicInputParamsForMultipleInputParameters) => {
  const queries = useMemo(() => {
    return (
      requestParams.payloads.map((payload) => {
        const { inputParameterId, inputParameters, ...rest } = payload;
        const inputParameterIndex = allInputParameterIdsWithOrder?.indexOf(inputParameterId);
        const inputParameterIdsToFilter = allInputParameterIdsWithOrder.slice(inputParameterIndex);
        const newInputParameters = inputParameters?.filter((i) => !inputParameterIdsToFilter.includes(i.parameterId));
        const newParam: GetWorkflowDynamicInputParams = {
          ...requestParams,
          payload: { inputParameterId, inputParameters: newInputParameters, ...rest },
        };

        return {
          queryKey: QUERY_KEYS_WORKFLOW_SOURCE_DYNAMIC_INPUT.detail(newParam),
          queryFn: () => getWorkflowDynamicInput(newParam),
          ...options,
          enabled: enabledInputParameters.includes(inputParameterId),
          staleTime: 0,
        };
      }) ?? []
    );
  }, [requestParams, allInputParameterIdsWithOrder, options, enabledInputParameters]);

  return useQueries({
    queries: queries,
  });
};
