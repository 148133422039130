import React, { isValidElement } from 'react';
import { NvDivider } from '../divider';
import { NvFlex } from '../flex';
import { NvExpandMoreIcon } from '../icons';
import { NvTypography } from '../typography';
import { Section, SectionBody, SectionFooterActions, SectionHeader } from './styled';
import { SectionProps } from './types';

export const NvSection: React.FC<React.PropsWithChildren<SectionProps>> = ({
  sectionKey,
  title,
  isDefaultExpanded = false,
  headerActions,
  footerActions,
  children,
  className,
  customExpandIcon,
  onSectionExpanded,
  sx,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(isDefaultExpanded);

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
    onSectionExpanded?.(expanded);
  };

  return (
    <Section elevation={0} sx={sx} expanded={expanded} onChange={handleChange} disableGutters className={className}>
      <SectionHeader
        expandIcon={customExpandIcon ? customExpandIcon : <NvExpandMoreIcon />}
        id={`${sectionKey}-section-header`}
        aria-controls={`${sectionKey}-section-content`}
      >
        <NvFlex direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
          {isValidElement(title) ? title : <NvTypography variant="h4">{title}</NvTypography>}
          {headerActions && (
            <NvFlex direction="row" spacing={1} onClick={(event) => event.stopPropagation()}>
              {headerActions}
            </NvFlex>
          )}
        </NvFlex>
      </SectionHeader>
      <SectionBody>{children}</SectionBody>
      {footerActions && (
        <>
          <NvDivider />
          <SectionFooterActions>{footerActions}</SectionFooterActions>
        </>
      )}
    </Section>
  );
};
