import { RetryConfiguration } from '@novaera/actioner-service';
import {
  isMaxLength,
  isMinLength,
  isRequired,
  NvAutocomplete,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { range } from 'lodash';
import { FC, useMemo } from 'react';
import { RetryFieldsProps } from './types';

export const RetryFields: FC<React.PropsWithChildren<RetryFieldsProps>> = ({
  statusCodeFieldName,
  maxRetryCountFieldName,
  delayInSecondsFieldName,
}) => {
  const statusCodeList = useMemo(
    () => [
      200, 201, 202, 203, 204, 205, 206, 226, 300, 301, 302, 303, 304, 307, 308, 400, 401, 402, 403, 404, 405, 406, 407,
      408, 409, 410, 411, 412, 415, 416, 417, 421, 422, 423, 424, 426, 428, 429, 431, 451, 500, 501, 502, 503, 504, 505,
      506, 507, 508, 510, 511,
    ],
    []
  );

  const retryList = useMemo(() => range(1, 11, 1), []);
  const delayList = useMemo(() => range(1, 31, 1), []);

  return (
    <NvFlex gap="8px" marginTop="-8px">
      <NvFlex direction="row" gap="16px" alignItems="flex-start">
        <NvField
          labelVariant="h5"
          labelText="Status codes"
          direction="label-on-side"
          name={statusCodeFieldName}
          isAutoComplete
          labelWidth="140px"
          format={(value) => {
            return value ?? [];
          }}
          parse={(value) => {
            return value ?? [];
          }}
          validators={[isRequired(), isMinLength({ length: 1 }), isMaxLength({ length: 10 })]}
          component={
            <NvAutocomplete
              multiple
              options={statusCodeList}
              size="small"
              getOptionLabel={(option) => `${option}` ?? ''}
              renderInput={(params) => (
                <NvTextField
                  {...params}
                  placeholder="You can select multiple status codes"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  size="medium"
                />
              )}
            />
          }
        />
        <NvTypography variant="body3" textColor="subtle" marginTop="10px" flex="0 0 auto">
          Max 10 status codes
        </NvTypography>
      </NvFlex>
      <NvFlex direction="row" gap="32px" justifyContent="flex-start">
        <NvField<RetryConfiguration['maxRetryCount']>
          labelVariant="h5"
          labelText="Max number of retries"
          direction="label-on-side"
          name={maxRetryCountFieldName}
          isAutoComplete
          labelWidth="140px"
          component={
            <NvAutocomplete
              disableClearable
              options={retryList}
              size="small"
              getOptionLabel={(option) => `${option} times` ?? ''}
              sx={{ maxWidth: '100px', width: '100px' }}
              renderInput={(params) => (
                <NvTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  size="medium"
                />
              )}
            />
          }
        />
        <NvField<RetryConfiguration['delayInSeconds']>
          labelVariant="h5"
          labelText="Delay between retries"
          direction="label-on-side"
          name={delayInSecondsFieldName}
          isAutoComplete
          labelWidth="140px"
          component={
            <NvAutocomplete
              disableClearable
              options={delayList}
              size="small"
              getOptionLabel={(option) => `${option} sec` ?? ''}
              sx={{ maxWidth: '100px', width: '100px' }}
              renderInput={(params) => (
                <NvTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  size="medium"
                />
              )}
            />
          }
        />
      </NvFlex>
    </NvFlex>
  );
};
