import { ROUTES } from '../../common/routes';

export const USER_APP_TABS = {
  DESCRIPTION: 'description',
  CONFIG: 'config',
  WORKFLOWS: 'workflows',
  DATABASE: 'tables',
  JOBS: 'jobs',
  PERMISSIONS: 'permissions',
  SLACK_APP: 'slack-app',
  DOCUMENTS: 'documents',
  EVENTS: 'events',
  AI_ASSISTANT: 'ai-assistant',
  APP_DEPENDENCIES: 'dependencies',
};

export const USER_APP_LINKS = Object.assign({}, USER_APP_TABS, {
  RECORDS: 'records',
});

export const USER_APP_LIST = ROUTES.UserApps;
export const USER_APP_DETAIL = (userAppId: string) => USER_APP_LIST + `/${userAppId}`;
export const USER_APP_DESCRIPTION = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.DESCRIPTION}`;
export const USER_APP_CONFIG = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.CONFIG}`;
export const USER_APP_CONFIG_DETAIL = (userAppId: string, configId: string) =>
  USER_APP_CONFIG(userAppId) + `/${configId}`;
export const USER_APP_SETUP = (userAppId: string) => USER_APP_DETAIL(userAppId) + '/setup';
export const USER_APP_WORKFLOWS = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.WORKFLOWS}`;
export const USER_APP_WORKFLOW = ({ userAppId, workflowId }: { userAppId: string; workflowId: string }) =>
  USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.WORKFLOWS}/${workflowId}`;
export const USER_APP_DATABASE = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.DATABASE}`;
export const USER_APP_DATABASE_DETAIL = (userAppId: string, modelId: string) =>
  USER_APP_DATABASE(userAppId) + `/${modelId}`;
export const USER_APP_JOBS = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.JOBS}`;
export const USER_APP_JOBS_RECURRING = (userAppId: string) => USER_APP_JOBS(userAppId) + `/recurring`;
export const USER_APP_JOBS_RECURRING_DETAIL = (userAppId: string, jobId: string, jobType: string) =>
  USER_APP_JOBS(userAppId) + `/recurring?jobId=${jobId}&jobType=${jobType}`;
export const USER_APP_JOBS_SCHEDULED = (userAppId: string) => USER_APP_JOBS(userAppId) + `/scheduled`;
export const USER_APP_JOBS_SCHEDULED_DETAIL = (userAppId: string, jobId: string, jobType: string) =>
  USER_APP_JOBS(userAppId) + `/scheduled?jobId=${jobId}&jobType=${jobType}`;
export const USER_APP_PERMISSIONS = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.PERMISSIONS}`;
export const USER_APP_SLACK_APP = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.SLACK_APP}`;
export const USER_APP_RECORDS = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_LINKS.RECORDS}`;
export const USER_APP_RECORDS_DETAIL = (userAppId: string, modelId: string) =>
  USER_APP_RECORDS(userAppId) + `/${modelId}`;
export const USER_APP_DOCUMENTS = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.DOCUMENTS}`;
export const USER_APP_DOCUMENT_DETAIL = (userAppId: string, docId: string) =>
  USER_APP_DOCUMENTS(userAppId) + `/${docId}`;

export const USER_APP_EVENTS = (userAppId: string) => USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.EVENTS}`;
export const USER_APP_EVENT_DETAIL = (userAppId: string, eventId: string) => USER_APP_EVENTS(userAppId) + `/${eventId}`;

export const USER_APP_AI_ASSISTANT = (userAppId: string) =>
  USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.AI_ASSISTANT}`;

export const USER_APP_DEPENDENCIES = (userAppId: string) =>
  USER_APP_DETAIL(userAppId) + `/${USER_APP_TABS.APP_DEPENDENCIES}`;

export const getUserAppRootPages: (userAppId: string) => string[] = (userAppId) => [
  USER_APP_DESCRIPTION(userAppId),
  USER_APP_CONFIG(userAppId),
  USER_APP_WORKFLOWS(userAppId),
  USER_APP_DATABASE(userAppId),
  USER_APP_JOBS(userAppId),
  USER_APP_JOBS_RECURRING(userAppId),
  USER_APP_JOBS_SCHEDULED(userAppId),
  USER_APP_PERMISSIONS(userAppId),
  USER_APP_SLACK_APP(userAppId),
  USER_APP_DOCUMENTS(userAppId),
  USER_APP_EVENTS(userAppId),
  USER_APP_AI_ASSISTANT(userAppId),
  USER_APP_DEPENDENCIES(userAppId),
];
