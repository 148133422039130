import { useDeleteActionerEvent, useListActionerEvents } from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, NvTypography, useConfirmDialog } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { matchSorter } from 'match-sorter';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useIsAppFree } from '../../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';

export const useEvents = () => {
  const theme = useTheme();

  const { userAppId } = useParams();
  const { isAppFree } = useIsAppFree({ userAppId });
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const isSearchBarTouched = useRef<boolean>();
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState<boolean>(false);
  const { openConfirm } = useConfirmDialog();
  const { mutate: deleteEvent } = useDeleteActionerEvent();

  const { data: events, isInitialLoading } = useListActionerEvents({
    appId: userAppId,
  });

  const filteredSearchResult = useMemo(
    () => (searchKeyword.length > 0 && events ? matchSorter(events, searchKeyword, { keys: ['name'] }) : events),
    [searchKeyword, events]
  );
  const hasRecord = filteredSearchResult && filteredSearchResult?.length > 0;

  const menuItems = useCallback(
    ({ id, name }: { id: string; name: string }) =>
      [
        {
          name: 'Delete',
          onClick: () => {
            openConfirm({
              title: `Delete ${name ?? 'This event'}`,
              message: (
                <NvTypography variant="body1">
                  By deleting this event, you will lose access to its usage history and other configurations
                  permanently. This action cannot be undone. Do you want to proceed?
                </NvTypography>
              ),
              onConfirm: () => {
                deleteEvent({ appId: userAppId, id });
              },
            });
          },
          icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          right: !isAppFree,
        },
      ].filter(({ right }) => right),
    [deleteEvent, isAppFree, openConfirm, theme.palette.nv_error, userAppId]
  );

  const handleAddEvent = () => {
    setIsAddEventModalOpen(true);
  };
  const handleEventModalClose = () => {
    setIsAddEventModalOpen(false);
  };

  return {
    onAddEventClicked: handleAddEvent,
    setSearchKeyword,
    menuItems,
    isInitialLoading,
    isSearchBarTouched,
    hasRecord,
    searchKeyword,
    userAppId,
    filteredSearchResult,

    isAddEventModalOpen,
    onEventModalClose: handleEventModalClose,
  };
};
