import { GenericWebhookTrigger, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import { NvButton, NvCloseIcon, NvConditionalRender, NvCustomConditionIcon, NvFlex } from '@novaera/core';

import { cloneDeep } from 'lodash';
import { FC } from 'react';
import { NodeType, PropertyPanelHeader, PropertyPanelSimpleSection } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { DefaultPrincipalPropertyPanelSection } from '../../common/default-principal-property-panel-section';
import { PropertiesLoading } from '../../common/properties-loading';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { GenericWebhookPayload } from './payload';
import { WebhookTriggerPropertiesProps } from './types';
import { GenericWebhookUrl } from './url';

export const WebhookTriggerProperties: FC<React.PropsWithChildren<WebhookTriggerPropertiesProps>> = ({
  workflow,
  savedWorkflow,
  onCloseClicked,
  onRootConditionClicked,
}) => {
  const { mutate: saveToDraftWorkflow } = useSaveToDraftWorkflow();
  const { selectedNode } = usePropertyPanelContext();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { hasEditPermission } = useWorkflowPermission();
  return (
    <NvFlex width="100%">
      {workflow && workflow.trigger ? (
        <>
          <NvConditionalRender when={hasEditPermission}>
            <PropertyPanelHeader
              title={workflow.trigger.name}
              type={NodeType.WEBHOOK_TRIGGER}
              onTitleChange={async (value) => {
                if (value) {
                  return new Promise<void>((resolve) => {
                    const trigger: GenericWebhookTrigger = {
                      type: 'genericWebhook',
                      name: value,
                    };

                    saveToDraftWorkflow(
                      { ...workflow, trigger },
                      {
                        onSuccess: () => {
                          if (selectedNode) {
                            const graphNode = userAppGraph.node(selectedNode.alias);
                            const newGraphNode = cloneDeep(graphNode);
                            newGraphNode.name = value;
                            updateNode({ newNode: newGraphNode });
                          }
                        },
                        onSettled: () => {
                          resolve();
                        },
                      }
                    );
                  });
                }
              }}
              validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />

            {savedWorkflow?.endpoint && <GenericWebhookUrl url={savedWorkflow.endpoint} />}
            <GenericWebhookPayload appId={workflow.appId} workflowId={workflow.id} />
          </NvConditionalRender>
          <NvConditionalRender when={!hasEditPermission}>
            <PropertyPanelHeader
              title={workflow.trigger.name}
              type={NodeType.WEBHOOK_TRIGGER}
              validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />

            {savedWorkflow?.endpoint && <GenericWebhookUrl url={savedWorkflow.endpoint} />}
          </NvConditionalRender>
          <DefaultPrincipalPropertyPanelSection defaultStarterUserId={workflow.trigger.defaultStarterUserId} />
          <PropertyPanelSimpleSection>
            <NvFlex gap="8px">
              <RowItemCard
                rowItemLeftContentProps={{ draggable: false, icon: <NvCustomConditionIcon /> }}
                rowItemContent={<SimpleLabelLayout simpleLabel="Trigger conditions" />}
                onClick={onRootConditionClicked}
              />
            </NvFlex>
          </PropertyPanelSimpleSection>
        </>
      ) : (
        <PropertiesLoading />
      )}
    </NvFlex>
  );
};
