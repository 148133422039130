import { AssistantNode, AssistantVertex, DefaultNodeSummary } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class AssistantComponentFactory implements ComponentFactory<AssistantNode> {
  aliasForAssistant: string;
  nameForAssistant: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('assistant');
    this.aliasForAssistant = newAlias;
    this.nameForAssistant = newName;
  }

  get componentForGraph() {
    const workflowResolverRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForAssistant,
      width: 40,
      height: 40,
      id: this.aliasForAssistant,
      type: 'assistant',
      alias: this.aliasForAssistant,
    };

    return { root: workflowResolverRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: AssistantNode) {
    const newNodeUnion: AssistantVertex = {
      type: 'assistant',
      alias: this.aliasForAssistant,
    };
    const summary: DefaultNodeSummary = {
      name: this.nameForAssistant,
      alias: this.aliasForAssistant,
      type: 'assistant',
    };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForAssistant]: summary } };
  }
}
