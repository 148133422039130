import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPolicyResponse } from '../use-get-permission-policy/types';
import { SavePermissionToPolicyParams, SavePermissionToPolicyResponse } from './types';

const savePermissionToPolicy: ({
  policyId,
}: SavePermissionToPolicyParams) => Promise<SavePermissionToPolicyResponse> = async ({ policyId, permission }) => {
  const result = await NvAxios.post<SavePermissionToPolicyResponse>(
    `${PERMISSION_POLICIES_ROOT_PATH}/policies/${policyId}/permissions`,
    { permission }
  );
  return result?.data;
};

export const useSavePermissionToPolicy = () => {
  const cache = useQueryClient();

  return useMutation(savePermissionToPolicy, {
    onSuccess: (_, { policyId, permission }) => {
      cache.setQueryData<GetPermissionPolicyResponse>(QUERY_KEYS_PERMISSION_POLICIES.detail({ policyId }), (old) => {
        if (old) {
          const existingPermission = old.permissions.find((p) => p.definition.id === permission.definition.id);
          return {
            ...old,
            permissions: existingPermission
              ? old.permissions.map((p) =>
                  p.definition.id === permission.definition.id ? { ...p, granted: permission.granted } : p
                )
              : [...old.permissions, permission],
          };
        }
        return old;
      });
    },
  });
};
