import { NvAxios, useMutation } from '@novaera/core';
import { CONNECTION_ROOT_PATH } from '../constants';
import { Connection, TestConnectionError } from '../types';

import { UseTestConnectionParams } from './types';

const testConnection = async (params: UseTestConnectionParams) => {
  const { connectionId, payload } = params;
  const result = await NvAxios.post<Connection | TestConnectionError>(`${CONNECTION_ROOT_PATH}/${connectionId}/test`, {
    context: payload.context,
  });
  return result?.data;
};

export const useTestConnectionService = () => {
  return useMutation(testConnection);
};
