import { NvAxios, useMutation } from '@novaera/core';
import { ExportIntegrationTablesProps } from './types';

const exportIntegrationTables = async (props: ExportIntegrationTablesProps) => {
  const result = await NvAxios.post('/admin/environment-sync/exports', {
    ...props,
  });
  return result?.data;
};

export const useExportIntegrationsTables = () => {
  return useMutation(exportIntegrationTables);
};
