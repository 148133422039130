import {
  NvAutocomplete,
  NvAutocompleteRenderInputParams,
  NvChevronRightIcon,
  NvCollapse,
  NvConditionalRender,
  NvConditionalWrap,
  NvDialogModal,
  NvDivider,
  NvField,
  NvFlex,
  NvMenuItem,
  NvPopoverWithAnchor,
  NvSkeleton,
  NvTextField,
  NvTypography,
  isRequired,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { useState } from 'react';
import { AddConfigAttachmentButton, AttachmentCard, Container } from './styled';
import { ConditionAttachmentProp } from './types';

export const ConfigAttachment = <T, V>({
  isLoading,
  onAddClicked,
  options,
  onEditClicked,
  onRemoveClicked,
  customActionButton,
  value,
  onChange,
  startIcon,
  endIcon,
  isOptionEqualToValue,
  getOptionLabel,
  getValue,
  title,
  description,
  type,
  selectAttachmentModalProps,
  bottomSlot,
}: ConditionAttachmentProp<T, V>) => {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [showSelectAttachmentModal, setShowSelectAttachmentModal] = useState(false);
  const { modalDescription, onInputChange, ...attachmentModalProps } = selectAttachmentModalProps;
  return (
    <>
      <Container className={classNames({ 'is-open': !collapsed, 'collapse-mode': !value })}>
        <NvConditionalRender when={!value}>
          <NvChevronRightIcon
            className="chevron-icon"
            onClick={() => {
              setCollapsed((prev) => !prev);
            }}
          />
        </NvConditionalRender>

        <NvFlex alignItems="flex-start" width="100%" flex="1 1 auto" minWidth={0}>
          <NvFlex
            direction="row"
            alignItems="center"
            gap="4px"
            height="20px"
            sx={!value ? { cursor: 'pointer' } : {}}
            onClick={() => {
              setCollapsed((prev) => !prev);
            }}
            width="100%"
          >
            {startIcon}
            <NvTypography variant="h5" flex="1 1 auto" minWidth={0} noWrap>
              {title}
            </NvTypography>
            {endIcon}
          </NvFlex>

          <NvConditionalWrap
            condition={!value}
            wrap={(children) => (
              <NvCollapse in={!collapsed} unmountOnExit mountOnEnter>
                {children}
              </NvCollapse>
            )}
          >
            <NvFlex gap="8px" alignItems="flex-start" width="100%" marginTop="8px">
              <NvTypography variant="body3">{description}</NvTypography>
              <NvConditionalRender when={isLoading}>
                <NvSkeleton variant="rectangular" width="100%" height="32px" />
              </NvConditionalRender>
              <NvConditionalRender when={!!onAddClicked && !isLoading && options?.length === 0 && !value}>
                <AddConfigAttachmentButton onClick={onAddClicked} label={`Add ${type}`} fullWidth size="small" />
              </NvConditionalRender>
              <NvConditionalRender when={!!onAddClicked && !isLoading && !!(options && options.length > 0) && !value}>
                <NvPopoverWithAnchor
                  id="add-config-attachment-popover"
                  initiator={<AddConfigAttachmentButton label={`Add ${type}`} fullWidth size="small" />}
                  popoverProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  {({ close }) => (
                    <NvFlex width="240px" padding="6px">
                      <NvMenuItem
                        onClick={() => {
                          setShowSelectAttachmentModal(true);
                          close();
                        }}
                      >
                        Select {type}
                      </NvMenuItem>
                      <NvMenuItem
                        onClick={() => {
                          onAddClicked?.();
                          close();
                        }}
                      >
                        Create {type}
                      </NvMenuItem>
                    </NvFlex>
                  )}
                </NvPopoverWithAnchor>
              </NvConditionalRender>
              <NvConditionalRender when={!!customActionButton && !isLoading && !value}>
                {customActionButton?.({
                  onClick: () => {
                    setShowSelectAttachmentModal(true);
                  },
                })}
              </NvConditionalRender>
              <NvConditionalRender when={!isLoading && !!value}>
                <AttachmentCard>
                  <NvTypography variant="h5" flex="1 1 auto" minWidth={0} noWrap>{`${
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (getValue?.(options ?? [], value as unknown as V) as any)?.name
                  }`}</NvTypography>
                  <NvFlex direction="row" alignItems="center" gap="8px" flex="0 0 auto">
                    <NvTypography
                      variant="body2"
                      color={theme.palette.nv_main[40]}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (value) {
                          const selectedItem = getValue?.(options ?? [], value as unknown as V);
                          selectedItem && onEditClicked?.(selectedItem);
                        }
                      }}
                    >
                      <b>Edit {type}</b>
                    </NvTypography>
                    <NvTypography
                      variant="body2"
                      color={theme.palette.nv_main[40]}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (value) {
                          const selectedItem = getValue?.(options ?? [], value as unknown as V);
                          selectedItem && onRemoveClicked?.(selectedItem);
                        }
                      }}
                    >
                      <b>Remove {type}</b>
                    </NvTypography>
                  </NvFlex>
                </AttachmentCard>
              </NvConditionalRender>

              {bottomSlot && (
                <NvFlex gap="8px" width="100%">
                  <NvDivider />
                  {bottomSlot}
                </NvFlex>
              )}
            </NvFlex>
          </NvConditionalWrap>
        </NvFlex>
      </Container>
      <NvDialogModal<{ selectedValue: T }>
        Header={`Select ${type}`}
        primaryButtonText={'Select'}
        Body={
          <NvFlex gap="16px">
            <NvTypography>{modalDescription}</NvTypography>

            <NvField
              component={
                <NvAutocomplete<T>
                  renderInput={(params: NvAutocompleteRenderInputParams) => (
                    <NvTextField
                      {...params}
                      placeholder="Select"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  isOptionEqualToValue={isOptionEqualToValue}
                  getOptionLabel={getOptionLabel}
                  options={options ?? []}
                  onInputChange={(_, newValue) => {
                    onInputChange?.(newValue);
                  }}
                />
              }
              isAutoComplete
              name={'selectedValue'}
              labelText={title}
              direction="label-on-top"
              hasRequiredIndicator
              validators={[isRequired()]}
              showErrorMessageOnlyWhenBlur
            />
          </NvFlex>
        }
        {...attachmentModalProps}
        open={!!showSelectAttachmentModal}
        onCloseButtonClicked={() => {
          setShowSelectAttachmentModal(false);
        }}
        onFormSubmit={({ selectedValue }) => {
          onChange?.(selectedValue);
          setShowSelectAttachmentModal(false);
        }}
      />
    </>
  );
};
