import { SlackButtonStyles, WorkflowReferenceElement } from '@novaera/actioner-service';
import { NvButton } from '@novaera/core';
import { useWorkflowReferenceOutputProvider } from '../../../../../../providers/workflow-reference-output-provider';

export const WorkflowReferenceButton = ({
  workflowReference,
  callerAppId,
}: {
  workflowReference: WorkflowReferenceElement;
  callerAppId: string;
}) => {
  const { dispatchReferenceOutputItem, isLoading } = useWorkflowReferenceOutputProvider();

  return (
    <NvButton
      size="small"
      {...(workflowReference.displayConfiguration.variant
        ? workflowReference.displayConfiguration.variant === SlackButtonStyles.PRIMARY
          ? { color: 'primary' }
          : { color: 'warning' }
        : { color: 'secondary' })}
      onClick={async () => {
        await dispatchReferenceOutputItem(workflowReference, callerAppId);
      }}
      loading={isLoading}
    >
      {workflowReference.displayConfiguration.text}
    </NvButton>
  );
};
