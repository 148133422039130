export const QUERY_KEYS_DATA_MODEL = {
  all: ['app-data-models'] as const,
  metadata: ['app-data-models-metadata'],
  list: (appId?: string) => [...QUERY_KEYS_DATA_MODEL.all, 'list', ...(appId ? [appId] : [])] as const,
  detail: (appId?: string, modelId?: string) =>
    [...QUERY_KEYS_DATA_MODEL.list(appId), 'detail', ...(modelId ? [modelId] : [])] as const,
};

export const QUERY_KEYS_DATA_MODEL_REQUEST_TYPES = {
  all: ['app-data-models-request-types'] as const,
  list: (appId?: string, modelId?: string, type?: string) =>
    [...QUERY_KEYS_DATA_MODEL_REQUEST_TYPES.all, 'list', ...(appId ? [appId] : []), modelId, type] as const,
};
