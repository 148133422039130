import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomTableIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1102_59)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 4.5C4.44772 4.5 4 4.94772 4 5.5V7.5C4 8.05228 4.44772 8.5 5 8.5H7C7.55228 8.5 8 8.05228 8 7.5V5.5C8 4.94772 7.55228 4.5 7 4.5H5ZM5 10C4.44772 10 4 10.4477 4 11V13C4 13.5523 4.44772 14 5 14H7C7.55228 14 8 13.5523 8 13V11C8 10.4477 7.55228 10 7 10H5ZM4 16.5C4 15.9477 4.44772 15.5 5 15.5H7C7.55228 15.5 8 15.9477 8 16.5V18.5C8 19.0523 7.55228 19.5 7 19.5H5C4.44772 19.5 4 19.0523 4 18.5V16.5ZM10 4.5C9.44772 4.5 9 4.94772 9 5.5V7.5C9 8.05228 9.44771 8.5 10 8.5H19C19.5523 8.5 20 8.05228 20 7.5V5.5C20 4.94772 19.5523 4.5 19 4.5H10ZM9 11C9 10.4477 9.44772 10 10 10H19C19.5523 10 20 10.4477 20 11V13C20 13.5523 19.5523 14 19 14H10C9.44771 14 9 13.5523 9 13V11ZM10 15.5C9.44772 15.5 9 15.9477 9 16.5V18.5C9 19.0523 9.44771 19.5 10 19.5H19C19.5523 19.5 20 19.0523 20 18.5V16.5C20 15.9477 19.5523 15.5 19 15.5H10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1102_59">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
