import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { CreateUserAppParams, CreateUserAppResponse } from '../types';
import { SearchUserAppResponse } from '../use-search-user-apps/types';

const createUserApp: (params: CreateUserAppParams) => Promise<CreateUserAppResponse> = async (params) => {
  const result = await NvAxios.post<CreateUserAppResponse>(USER_APP_ROOT_PATH, params);
  return result?.data;
};

export const useCreateUserApp = () => {
  const cache = useQueryClient();

  return useMutation(createUserApp, {
    onSuccess: (response) => {
      cache.setQueriesData<InfiniteData<SearchUserAppResponse>>(QUERY_KEYS_USER_APP.infiniteSearch(), (old) => {
        return old
          ? {
              ...old,
              pages: old.pages.map((page, index) => {
                const isLastPage = old.pages.length - 1 === index;
                return isLastPage
                  ? {
                      ...page,
                      apps: [...page.apps, response],
                    }
                  : page;
              }),
            }
          : old;
      });
      cache.setQueriesData(QUERY_KEYS_USER_APP.search(), (old?: SearchUserAppResponse) => {
        if (old) {
          return { ...old, apps: [...old.apps, response] };
        }
        return old;
      });
    },
  });
};
