import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import React, { FC, ReactElement, useRef, useState } from 'react';
import { NvFlex } from '../flex';
import { NvPopover } from '../popover';
import { NvPopoverWithAnchorProps } from './types';

export const NvPopoverWithAnchor: FC<
  NvPopoverWithAnchorProps & {
    children: React.ReactNode | ((props: { close: () => void }) => React.ReactNode);
  }
> = ({ initiator, id: givenId, onClose, loading, children, popoverProps, fullInitiatorWidth }) => {
  const { palette } = useTheme();
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>();

  const open = Boolean(anchorEl);
  const PaperProps = {
    style: {
      background: palette.nv_neutral[0],
      boxShadow: '0px 0px 0px 1px #EBEEF2, 0px 18px 30px -3px rgba(10, 45, 98, 0.08)',
      borderRadius: 12,
      marginTop: 8,
    },
  };

  const id = open ? `${givenId}-popover` : undefined;

  return (
    <>
      <NvFlex
        ref={ref}
        onClick={() => {
          setAnchorEl(ref.current);
        }}
        {...(fullInitiatorWidth ? { width: '100%' } : {})}
      >
        {React.isValidElement(initiator) &&
          React.cloneElement<{ className: string }>(initiator as ReactElement, {
            className: classNames({ 'is-open': open }),
          })}
      </NvFlex>
      <NvPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          if (!loading) {
            onClose?.();
            setAnchorEl(undefined);
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={PaperProps}
        onClick={(e) => e.stopPropagation()}
        onBackdropClick={(e) => {
          if (loading) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        {...popoverProps}
      >
        <NvFlex>{typeof children === 'function' ? children({ close: () => setAnchorEl(undefined) }) : children}</NvFlex>
      </NvPopover>
    </>
  );
};
