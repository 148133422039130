import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomEmptySearchIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.71 14H16.5L20.74 18.26C21.15 18.67 21.15 19.34 20.74 19.75C20.33 20.16 19.66 20.16 19.25 19.75L15 15.5V14.71L14.73 14.43C13.48 15.51 11.81 16.11 10.01 15.98L10 15.97V13.95C10.0452 13.9553 10.0897 13.9614 10.1339 13.9673C10.2558 13.9839 10.3752 14 10.5 14C12.99 14 15 11.99 15 9.50003C15 6.95003 12.65 4.80003 10.11 5.02003C7.97003 5.20003 6.29003 6.88003 6.05003 9.00003H4.03003C4.30003 5.38003 7.54003 2.58003 11.33 3.05003C14.12 3.40003 16.44 5.61003 16.91 8.39003C17.25 10.42 16.63 12.33 15.43 13.73L15.71 14ZM5.58116 18.0486C5.58116 18.5757 5.99201 19 6.50246 19C7.02537 19 7.41132 18.5757 7.41132 18.0486C7.41132 17.5215 7.01292 17.11 6.50246 17.11C5.99201 17.11 5.58116 17.5215 5.58116 18.0486ZM7.89553 14.3947C8.13565 14.1535 8.41136 13.8764 8.69367 13.4457C9.19168 12.7 9.00493 11.6843 8.68122 11.1315C8.30772 10.5015 7.57317 10 6.50246 10C5.20766 10 4.33615 10.8615 4 11.8643L5.19521 12.3786C5.35706 11.8772 5.73056 11.2729 6.50246 11.2729C7.68522 11.2729 7.90932 12.4172 7.49847 13.0215C7.32589 13.2802 7.09357 13.5003 6.85828 13.7233C6.56736 13.999 6.27189 14.2789 6.07916 14.6415C5.83968 15.1024 5.84765 15.6124 5.85344 15.9825C5.85427 16.0357 5.85506 16.086 5.85506 16.1329H7.17477C7.17477 15.4386 7.22457 15.2972 7.33662 15.0786C7.47539 14.8169 7.66973 14.6216 7.89553 14.3947Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
