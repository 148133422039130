import { SvgIcon, SvgIconProps } from '@mui/material';

export function StepLabelStartIntersect({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      className="step-label-start-intersect"
      width="26px"
      height="40px"
      viewBox="0 0 26 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: '26px', height: '40px', ...(props.sx ?? {}) }}
    >
      <mask
        id="mask0_2_177"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="40"
      >
        <rect x="26" y="40" width="26" height="40" transform="rotate(180 26 40)" />
      </mask>
      <g mask="url(#mask0_2_177)">
        <rect x="1" width="25" height="40" />
        <path
          d="M24.9676 22.5145C26.1531 21.0478 26.1531 18.9522 24.9676 17.4855L7.86087 -3.67853C5.49033 -6.61132 0.750001 -4.9351 0.750001 -1.16407L0.750003 41.1641C0.750003 44.9351 5.49032 46.6113 7.86086 43.6786L24.9676 22.5145Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}
