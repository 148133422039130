import {
  ProductId,
  WorkspaceStatus,
  useExtendTrialPeriod,
  useGetWorkspaceSubscriptionPlan,
  useGetWorkspaceWithSubdomain,
  useResetWorkspaceUsage,
  useUpdateWorkspaceStatus,
} from '@novaera/actioner-service';
import { SimpleSearchInput } from '@novaera/actioner-ui';
import {
  BasicTableCell,
  BasicTableRow,
  NvBasicTable,
  NvBlockRoundedIcon,
  NvButton,
  NvCheckIcon,
  NvDialogModal,
  NvEmptyState,
  NvFlex,
  NvMenuWithItems,
  NvRestartAltRoundedIcon,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
  useNvDialogModalState,
  useToast,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { WorkspaceSubscriptionExtendModal } from './extend-modal';

export const AdminWorkspaces = ({ productId }: { productId: ProductId }) => {
  const [subdomain, setSubdomain] = useState<string | undefined>();
  const { data: workspace, isInitialLoading: isGettingWorkspace } = useGetWorkspaceWithSubdomain({
    productId,
    subdomain,
  });

  const { data: workspaceSubscription, isInitialLoading: isWorkspaceSubscriptionPlanLoading } =
    useGetWorkspaceSubscriptionPlan({
      workspaceId: workspace?.id,
    });

  const { mutate: updateWorkspaceStatus, isLoading } = useUpdateWorkspaceStatus();

  const { mutate: resetWorkspaceUsage } = useResetWorkspaceUsage();

  const { mutate: extendTrialPeriod } = useExtendTrialPeriod();

  const { openConfirm } = useConfirmDialog();

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const { addToast } = useToast();

  const handleFindWorkspace = (keyword?: string) => {
    setSubdomain(keyword);
  };

  const handelChangeStatus = (id: string) => {
    openConfirm({
      title: 'Change status of this workspace?',
      message: `This will ${
        workspace?.status === WorkspaceStatus.ACTIVE ? 'suspend' : 'activate'
      } the workspace. Do you want to proceed?`,
      onConfirm: () => {
        updateWorkspaceStatus(
          {
            productId,
            id,
            status: workspace?.status === WorkspaceStatus.ACTIVE ? WorkspaceStatus.DEACTIVATED : WorkspaceStatus.ACTIVE,
          },
          {
            onSuccess: () => {
              addToast(
                `Workspace has been ${workspace?.status === WorkspaceStatus.ACTIVE ? 'suspended' : 'activated'}.`,
                {
                  variant: 'success',
                }
              );
            },
          }
        );
      },
      confirmButtonLabel: workspace?.status === WorkspaceStatus.ACTIVE ? 'Suspend' : 'Activate',
    });
  };

  const handleResetCredits = (id: string) => {
    openConfirm({
      title: 'Reset credits for this workspace?',
      message: 'This will reset all the credits for this workspace. Do you want to proceed?',
      onConfirm: () => {
        resetWorkspaceUsage(
          {
            workspaceId: id,
          },
          {
            onSuccess: () => {
              addToast('Workspace credits have been reset.', { variant: 'success' });
            },
          }
        );
      },
      confirmButtonLabel: 'Reset credits',
    });
  };

  return (
    <>
      <NvFlex gap="16px" maxWidth="780px">
        <NvFlex>
          <SimpleSearchInput
            helperText="Search workspace with subdomain"
            initialKeyword=""
            onKeywordChanged={handleFindWorkspace}
          />
        </NvFlex>
        {isGettingWorkspace ? (
          <NvBasicTable
            tableBody={
              <BasicTableRow>
                <BasicTableCell>
                  <NvSkeleton />
                </BasicTableCell>
              </BasicTableRow>
            }
          />
        ) : workspace ? (
          <NvBasicTable
            tableHeader={
              <BasicTableRow>
                <BasicTableCell width="250px">Subdomain</BasicTableCell>
                <BasicTableCell width="250px">Name</BasicTableCell>
                <BasicTableCell width="150px">Status</BasicTableCell>
                <BasicTableCell width={'200px'}>Subscription Plan</BasicTableCell>
                <BasicTableCell></BasicTableCell>
              </BasicTableRow>
            }
            tableBody={
              <BasicTableRow>
                <BasicTableCell>{workspace.subdomain}</BasicTableCell>
                <BasicTableCell>{workspace.displayName}</BasicTableCell>
                <BasicTableCell>{workspace.status}</BasicTableCell>
                {!isWorkspaceSubscriptionPlanLoading && (
                  <>
                    <BasicTableCell>
                      <NvTypography>{workspaceSubscription?.planType}</NvTypography>
                    </BasicTableCell>
                    {workspaceSubscription?.planType === 'trial' && (
                      <BasicTableCell>
                        <NvButton onClick={onModalOpenClicked} size="small">
                          Extend trial
                        </NvButton>
                      </BasicTableCell>
                    )}
                  </>
                )}
                {isWorkspaceSubscriptionPlanLoading && (
                  <BasicTableCell>
                    <NvSkeleton />
                  </BasicTableCell>
                )}
                <BasicTableCell>
                  <NvMenuWithItems
                    menuItems={[
                      {
                        name: workspace.status === WorkspaceStatus.ACTIVE ? 'Suspend' : 'Activate',
                        isLoading: isLoading,
                        onClick: () => {
                          handelChangeStatus(workspace.id);
                        },
                        icon:
                          workspace.status === WorkspaceStatus.ACTIVE ? (
                            <NvBlockRoundedIcon sx={{ width: '16px', height: '16px' }} />
                          ) : (
                            <NvCheckIcon sx={{ width: '16px', height: '16px' }} />
                          ),
                      },
                      {
                        name: 'Reset credits',
                        isLoading: isLoading,
                        onClick: () => {
                          handleResetCredits(workspace.id);
                        },
                        icon: <NvRestartAltRoundedIcon sx={{ width: '16px', height: '16px' }} />,
                      },
                    ]}
                  ></NvMenuWithItems>
                </BasicTableCell>
              </BasicTableRow>
            }
          />
        ) : (
          <NvEmptyState primaryText="There is no workspace." />
        )}
      </NvFlex>
      <NvDialogModal<{
        amountInMillis: number;
      }>
        primaryButtonText="Extend"
        modalIcon="edit"
        fullWidth
        maxWidth="sm"
        open={isOpened}
        Header={`Extend ${workspace?.displayName} plan`}
        onCloseButtonClicked={onModalCloseClicked}
        Body={
          !workspace || !workspaceSubscription ? (
            <NvSkeleton />
          ) : (
            <WorkspaceSubscriptionExtendModal workspace={workspace} workspaceSubscription={workspaceSubscription} />
          )
        }
        onFormSubmit={({ amountInMillis }) => {
          assert(!!workspace?.id, new Error('Workspace id is not found'));

          extendTrialPeriod({
            amountInMillis,
            workspaceId: workspace?.id,
          });

          addToast("Workspace's trial period has been extended.", { variant: 'success' });
        }}
      />
    </>
  );
};
