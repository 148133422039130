import { NovaeraEdge } from '@novaera/core';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';

export const connectNodes = <EdgeType extends string = string>({
  sourceId,
  targetId,
  edgeExtraData,
}: {
  sourceId: string;
  targetId: string;
  edgeExtraData: NovaeraEdge<EdgeType>['extraData'];
}) => {
  userAppGraph.addEdge({
    sourceId: sourceId,
    targetId: targetId,
    id: `${sourceId}-${targetId}`,
    extraData: edgeExtraData,
  });
};
