import { ConnectionSchema } from '@novaera/actioner-service';
import { NvField, useFormState } from '@novaera/core';
import { BodyItem } from '../../item';
import { ConnectionTestConfiguration } from './connection-test-configuration';

export const TestConfiguration = () => {
  const { values, pristine } = useFormState<ConnectionSchema>();

  return (
    <BodyItem
      title="Test configuration"
      description="Enter a path to test your connection. It is recommended to use a path that doesn’t require any inputs such as /me."
    >
      <NvField
        name="authentication.connectionTest"
        component={<ConnectionTestConfiguration connectionSchema={values} isFormSaved={pristine} />}
      />
    </BodyItem>
  );
};
