import { NumberValue, StringValue, ValueTypes } from '@novaera/actioner-service';
import { DateTimeFormat } from '@novaera/ah-common';
import { NvBox, NvField, NvSelect, useField } from '@novaera/core';
import { FC } from 'react';

export const DateTimeFormatField: FC<React.PropsWithChildren<{ schemaFieldName: string }>> = ({ schemaFieldName }) => {
  const {
    input: { value: defaultValue, onChange: onChangeDefaultValue },
  } = useField(`${schemaFieldName}.defaultValue`);

  return (
    <NvField
      name={`${schemaFieldName}.format`}
      direction="label-on-side"
      labelVariant="h5"
      labelText="Format"
      component={({ value, onChange }) => (
        <NvBox width="160px">
          <NvSelect
            fullWidth
            value={value}
            onChange={(e) => {
              onChange(e);
              if (defaultValue) {
                const newDate = new Date(defaultValue.value);
                if (e.target.value === DateTimeFormat.utc) {
                  const constructedValue: NumberValue = {
                    type: ValueTypes.NUMBER,
                    value: newDate.getTime(),
                    displayValue: newDate.toISOString(),
                  };
                  onChangeDefaultValue(constructedValue);
                } else {
                  const constructedValue: StringValue = {
                    type: ValueTypes.STRING,
                    value: newDate.toISOString(),
                    displayValue: newDate.toISOString(),
                  };
                  onChangeDefaultValue(constructedValue);
                }
              }
            }}
            options={[
              { value: DateTimeFormat.iso_8601, label: 'ISO 8601' },
              { value: DateTimeFormat.utc, label: 'UTC Milliseconds' },
            ]}
          />
        </NvBox>
      )}
      formControlStyle={{ width: '100%', maxWidth: 700 - 98 }}
    />
  );
};
