import { SnackbarKey } from 'notistack';
import { useToast } from '..';
import { NvButton } from '../../button';
import { ToastCloseIcon } from '../styled';

export const ToastCloseAction = (snackbarId: SnackbarKey) => {
  const { closeToast } = useToast();
  return (
    <NvButton
      color="ghost"
      onlyIcon
      onClick={() => {
        closeToast(snackbarId);
      }}
    >
      <ToastCloseIcon fontSize="small" />
    </NvButton>
  );
};
