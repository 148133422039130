import { assert } from '@novaera/utils';
import React, { createContext, useContext } from 'react';

type JobTargetContextType = {
  workflowId?: string;
};

const JobTargetContext = createContext<JobTargetContextType | undefined>(undefined);

export const JobTargetProvider: React.FC<
  React.PropsWithChildren<{
    initialValues?: {
      workflowId?: string;
    };
  }>
> = ({ children, initialValues }) => {
  return <JobTargetContext.Provider value={initialValues}>{children}</JobTargetContext.Provider>;
};

export const useJobTargetContext = () => {
  const context = useContext(JobTargetContext);
  assert(!!context, new Error(`useJobTargetContext should be used within JobTargetProvider`), 'ERROR');

  return context;
};
