import { NvTypography } from '../typography';
import { PaginationNumberWrapper } from './styled';
import { PaginationNumberProps } from './types';

export const NvPaginationNumber: React.FC<React.PropsWithChildren<PaginationNumberProps>> = ({
  value,
  selected,
  onClick,
}) => (
  <PaginationNumberWrapper selected={selected} onClick={onClick}>
    {
      <NvTypography textAlign="center" variant="h5">
        {value}
      </NvTypography>
    }
  </PaginationNumberWrapper>
);
