import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SUBSCRIPTIONS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_BILLING, QUERY_KEYS_SUBSCRIPTION } from '../keys';
import { ChangePlanParams } from './types';

const changePlan = async (params: ChangePlanParams) => {
  const result = await NvAxios.post(SUBSCRIPTIONS_ROOT_PATH, params);
  return result?.data;
};

export const useChangePlan = () => {
  const client = useQueryClient();
  return useMutation(changePlan, {
    onSuccess: () => {
      setTimeout(() => {
        client.invalidateQueries(QUERY_KEYS_BILLING.all);
        client.invalidateQueries(QUERY_KEYS_SUBSCRIPTION.all);
      }, 4000);
    },
  });
};
