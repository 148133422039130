// todo check this if it is correctly imported
import { FieldSchema } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { FIELD_TYPE_SCHEMAS } from '../../../../components/data-model-layout/constants';

export const FIELD_SCHEMA: Record<string, FieldSchema> = {
  date: {
    ...{
      ...FIELD_TYPE_SCHEMAS[SchemaType.string],
      constraints: [],
    },
  },
  time: {
    ...{
      ...FIELD_TYPE_SCHEMAS[SchemaType.string],
      constraints: [],
    },
  },
  [SchemaType.string]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.string],
  },
  [SchemaType.email]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.email],
  },
  [SchemaType.url]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.url],
  },
  [SchemaType.phone]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.phone],
  },
  [SchemaType.integer]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.integer],
  },
  [SchemaType.float]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.float],
  },
  [SchemaType.uuid]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.uuid],
  },
  [SchemaType.array]: {
    ...{
      ...FIELD_TYPE_SCHEMAS[SchemaType.array],
      valueSchema: {
        type: SchemaType.string,
        mandatory: false,
        readOnly: false,
        constraints: [],
      },
    },
  },
  [SchemaType.boolean]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.boolean],
  },
  [SchemaType.dateTime]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.dateTime],
  },
  [SchemaType.map]: {
    ...FIELD_TYPE_SCHEMAS[SchemaType.map],
  },
};
