import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const BaseUrlBox = styled(NvBox)`
  height: 20px;
  padding: 2px 6px;
  background: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 4px;
  cursor: pointer;
`;
