import { Markdown, NvChip, NvFlex, NvTypography } from '@novaera/core';
import React from 'react';
import { AppPropertyCardWrapper, WorkflowTypeBox } from './styled';
import { AppPropertyCardProps } from './types';

export const AppPropertyCard: React.FC<React.PropsWithChildren<AppPropertyCardProps>> = ({
  name,
  workflowType,
  description,
  descriptionType,
}) => {
  return (
    <AppPropertyCardWrapper>
      <NvFlex gap="8px" width="100%">
        <NvFlex direction="row" gap="12px" alignItems="center">
          <NvTypography noWrap textOverflow="ellipsis" variant="h3">
            {name}
          </NvTypography>
          {workflowType && (
            <WorkflowTypeBox>
              <NvChip label={workflowType} compact />
            </WorkflowTypeBox>
          )}
        </NvFlex>

        {description.length > 0 &&
          (descriptionType && descriptionType === 'markdown' ? (
            <Markdown>{description}</Markdown>
          ) : (
            <NvTypography variant="body2">{description}</NvTypography>
          ))}
      </NvFlex>
    </AppPropertyCardWrapper>
  );
};
