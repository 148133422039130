import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledWrapper = styled(NvBox)`
  color: ${({ theme }) => theme.palette.nv_main[40]};
  cursor: pointer;
  flex: 0 0 auto;
  &:hover {
    color: ${({ theme }) => theme.palette.nv_main[60]};
  }
  p {
    transition: color 300ms ease;
  }
`;
