export const CRON_DAY_OF_WEEK_OPTIONS = [
  { label: 'Monday', value: 'MON' },
  { label: 'Tuesday', value: 'TUE' },
  { label: 'Wednesday', value: 'WED' },
  { label: 'Thursday', value: 'THU' },
  { label: 'Friday', value: 'FRI' },
  { label: 'Saturday', value: 'SAT' },
  { label: 'Sunday', value: 'SUN' },
];

export const CRON_DAY_OF_MONTH_OPTIONS = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' },
];

export const CRON_MONTH_OPTIONS = [
  { label: 'January', value: 'JAN' },
  { label: 'February', value: 'FEB' },
  { label: 'March', value: 'MAR' },
  { label: 'April', value: 'APR' },
  { label: 'May', value: 'MAY' },
  { label: 'June', value: 'JUN' },
  { label: 'July', value: 'JUL' },
  { label: 'August', value: 'AUG' },
  { label: 'September', value: 'SEP' },
  { label: 'October', value: 'OCT' },
  { label: 'November', value: 'NOV' },
  { label: 'December', value: 'DEC' },
];
