import {
  NvConditionalRender,
  NvDynamicFormIcon,
  NvFlex,
  NvReactJson,
  NvSection,
  NvSkeleton,
  NvTypography,
  SectionMessage,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { FC } from 'react';
import {
  DependencyInputComponentStateReturnValue,
  FORM_ITEM_DEPENDENCY_STATE,
} from '../../dynamic-input/providers/controller/use-check-dependency-value/types';
import { ParameterMapperItem } from '../../parameter-mapper/parameter-mapper-form/parameter-mapper-item';
import { DynamicInputComponentParams } from '../use-ui-component/types';
import { DynamicInputParameter } from './input-parameter';
import { DynamicInputMissingInput } from './styled';

const getLabelsForMissingDependedValue = (missingInputs?: DependencyInputComponentStateReturnValue) => {
  if (missingInputs?.state === FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE) {
    return missingInputs?.missingDependencies.join(', ');
  }
  return;
};

export const DynamicInputComponent: FC<React.PropsWithChildren<DynamicInputComponentParams>> = ({
  inputParameter,
  dynamicInputParameters,
  isLoading,
  errorContext,
  dynamicInputNestedComponentProps,
  context,
  dependencyState,
  ...restProps
}) => {
  const { palette } = useTheme();
  const showMissingDependedValue = dependencyState?.state === FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE;
  const showLoading = Boolean(isLoading) || dependencyState?.state === FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING;
  const showErrorContext = !showMissingDependedValue && Boolean(errorContext) && !isLoading;
  const showDynamicFields =
    !showLoading &&
    !showErrorContext &&
    !showMissingDependedValue &&
    Boolean(dynamicInputParameters && dynamicInputParameters.length > 0);

  return (
    <>
      <NvConditionalRender when={Boolean(showMissingDependedValue)}>
        <DynamicInputMissingInput>
          <NvDynamicFormIcon htmlColor={palette.nv_neutral[300]} />
          <NvTypography variant="h6">
            Enter a value for <b>{getLabelsForMissingDependedValue(dependencyState)}</b> to see other fields.
          </NvTypography>
        </DynamicInputMissingInput>
      </NvConditionalRender>
      <NvConditionalRender when={showLoading}>
        <NvSkeleton />
      </NvConditionalRender>
      <NvConditionalRender when={showErrorContext}>
        <SectionMessage
          title={
            <NvTypography variant="body2">
              Dynamic fields currently unavailable. Please check your configuration.
            </NvTypography>
          }
          message={
            <NvSection
              sectionKey="dynamic-input-error"
              sx={{ backgroundColor: palette.nv_neutral[0] }}
              title={<NvTypography>See more</NvTypography>}
            >
              {typeof errorContext?.message === 'string' ? (
                <NvTypography variant="c2" textColor="secondary">
                  {errorContext.message}
                </NvTypography>
              ) : (
                <NvReactJson
                  src={errorContext ?? {}}
                  hideCopyIcon
                  style={{
                    minWidth: '250px',
                    maxHeight: '450px',
                    overflow: 'auto',
                    padding: '2px',
                  }}
                  enableClipboard={false}
                />
              )}
            </NvSection>
          }
          variant={'error'}
        />
      </NvConditionalRender>
      <NvConditionalRender when={showDynamicFields}>
        <NvFlex gap="16px">
          {dynamicInputNestedComponentProps?.isParameterMapping
            ? dynamicInputParameters?.map((dynamicInputParameter) => (
                <ParameterMapperItem
                  key={`dynamic-input-mapping-${dynamicInputParameter.id}`}
                  inputParameter={dynamicInputParameter}
                  getDynamicInputParameters={restProps.getDynamicInputResponse}
                  getOptionsResponse={restProps.getOptionsResponse}
                  setInputParameterIdsShowingOptions={restProps.setInputParameterIdsShowingOptions}
                  context={context}
                  getDynamicInputComponentState={restProps.getDynamicInputComponentState}
                />
              ))
            : dynamicInputParameters?.map((inputParameter) => (
                <DynamicInputParameter
                  key={`dynamic-input-${inputParameter.id}`}
                  inputParameter={inputParameter}
                  context={context}
                  {...(dynamicInputNestedComponentProps?.labelVariant && {
                    dynamicInputNestedComponentProps: { labelVariant: dynamicInputNestedComponentProps.labelVariant },
                  })}
                  {...restProps}
                />
              ))}
        </NvFlex>
      </NvConditionalRender>
    </>
  );
};
