import { NvArrowForwardIcon, NvBox, NvButton, NvFlex, NvTypography } from '@novaera/core';
import { Link } from '@novaera/route';
import notFoundImage from '../../images/404_not_found.png';
import { ErrorCode } from './styled';

export const ErrorPage = () => {
  return (
    <NvFlex alignItems="center" justifyContent="center" width={1} paddingTop="90px">
      <NvBox>
        <img src={notFoundImage} width="424px" srcSet={notFoundImage} alt="404 not found" />
      </NvBox>

      <ErrorCode>404</ErrorCode>
      <NvTypography variant="h1" marginBottom={1.5}>
        Page not found
      </NvTypography>
      <NvBox width="320px">
        <NvTypography variant="body1">
          The page you are looking for doesn't exist or has been moved. Please go back to the homepage.
        </NvTypography>
      </NvBox>

      <NvBox marginTop={3}>
        <NvButton component={Link} to="/" endIcon={<NvArrowForwardIcon />}>
          Back to home
        </NvButton>
      </NvBox>
    </NvFlex>
  );
};
