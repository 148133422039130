import { useUpdateIntegrationVersion } from '@novaera/actioner-service';
import { useGetWorkflowQueryParams } from '../../../../../../controllers/use-get-workflow-query-params';

export const useUpdateIntegrationModalBody = () => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { isLoading, mutate } = useUpdateIntegrationVersion();

  const handleUpdateClicked = ({ integrationId, version }: { integrationId: string; version: string }) => {
    mutate({
      appId: userAppId,
      workflowId,
      payload: {
        updates: [{ integrationId, version }],
      },
    });
  };

  return {
    isLoading,
    onUpdateClicked: handleUpdateClicked,
  };
};
