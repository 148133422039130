import { DEFAULT_REACT_QUERY_STALE_TIME } from '@novaera/ah-common';
import { NvAxios, useQuery } from '@novaera/core';
import { QUERY_KEYS_USERS_IN_WORKSPACE } from './keys';
import { UseGetUsersInWorkspaceResponse } from './types';

const getUsersInWorkspace = async () => {
  const result = await NvAxios.get<UseGetUsersInWorkspaceResponse>('/v1/workspaces/users');
  return result?.data;
};

export const useGetUsersInWorkspace = (useQueryOptions?: {
  refetchOnWindowFocus?: boolean | 'always';
  staleTime?: number;
}) => {
  const { refetchOnWindowFocus, staleTime } = useQueryOptions ?? {
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_REACT_QUERY_STALE_TIME,
  };
  return useQuery(QUERY_KEYS_USERS_IN_WORKSPACE.lists(), getUsersInWorkspace, {
    refetchOnWindowFocus,
    staleTime,
  });
};
