import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetConfigResponse } from '../use-get-config/types';
import { GetConfigsResponse } from '../use-get-configs/types';
import { CreateConfigParams, CreateConfigResponse } from './types';

const createConfig: (params: CreateConfigParams) => Promise<CreateConfigResponse> = async ({ appId, ...params }) => {
  const result = await NvAxios.post<CreateConfigResponse>(`${CONFIG_ROOT_PATH(appId)}`, params);
  return result?.data;
};

export const useCreateConfig = () => {
  const cache = useQueryClient();

  return useMutation(createConfig, {
    onSuccess: (config, { appId }) => {
      const { id, name, createdAt, properties, version, ...restOfConfig } = config;
      cache.setQueryData<InfiniteData<GetConfigsResponse>>(QUERY_KEYS_CONFIGS.list(appId), (old) => {
        if (old) {
          return {
            ...old,
            pages: old?.pages.map((page) => ({
              ...page,
              configurations: [...page.configurations, { id, name, createdAt, version, isDraft: false }],
            })),
          };
        }
        return old;
      });
      cache.setQueryData<GetConfigResponse>(QUERY_KEYS_CONFIGS.detail(appId, id), {
        saved: { id, name, createdAt, properties, version, ...restOfConfig },
      });
    },
  });
};
