import { NvAxios, useQuery } from '@novaera/core';
import { GetMsTeamsConsentCompleteParams, GetMsTeamsConsentCompleteResponse } from './types';

const getMsTeamsConsentComplete = async (params: GetMsTeamsConsentCompleteParams) => {
  const result = await NvAxios.get<GetMsTeamsConsentCompleteResponse>(
    params.queryParams ? `/v1/msteams/consent/complete?${params.queryParams}` : `/v1/msteams/consent/complete`
  );
  return result?.data;
};

export const useMsTeamsGetConsentComplete = (params: GetMsTeamsConsentCompleteParams) => {
  return useQuery<GetMsTeamsConsentCompleteResponse>(
    ['msteams-consent-complete'],
    () => getMsTeamsConsentComplete(params),
    {
      enabled: !!params.queryParams,
    }
  );
};
