import { BaseFieldType } from '@novaera/actioner-service';
import {
  MenuItems,
  NestedDropdownItem,
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvFlex,
  NvNestedDropdown,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';

import React, { Dispatch, SetStateAction } from 'react';
import { FreeAppPermissionBoundary } from '../../../../user-app/user-app-permission-boundary/free-app-permission-boundary';
import { DataModelLayoutLeftContentItem } from '../item';
import { DataModelLayoutLeftContentItemProps } from '../item/types';

export type FieldBlockProps<FieldTypeType> = {
  selectedFieldIndex: number;
  setSelectedFieldIndex: Dispatch<SetStateAction<number>>;
  fieldMenus: (id: string) => MenuItems[];
  fieldMenuItems: NestedDropdownItem[];
  fields: FieldTypeType[];
  fieldHeaderProps?: React.ComponentProps<typeof NvTypography> & { label?: string };
  fieldHeaderActionButtonProps?: React.ComponentProps<typeof NvButton> & { label?: string };
} & { onFieldSelected: (selectedField: NestedDropdownItem) => void } & Pick<
    DataModelLayoutLeftContentItemProps<FieldTypeType>,
    'RightContent'
  >;

export const FieldBlock = <FieldTypeType extends BaseFieldType>({
  fieldMenus,
  fields,
  selectedFieldIndex,
  setSelectedFieldIndex,
  fieldMenuItems,
  onFieldSelected,
  RightContent,
  fieldHeaderActionButtonProps,
  fieldHeaderProps,
}: FieldBlockProps<FieldTypeType>) => {
  const { userAppId } = useParams();
  return (
    <NvFlex gap="16px">
      <NvBox display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
        <NvBox display="flex" alignItems="center">
          <NvTypography variant="h3" {...fieldHeaderProps}>
            {fieldHeaderProps?.label ?? 'Fields'}
          </NvTypography>
        </NvBox>
        <FreeAppPermissionBoundary appId={userAppId}>
          <NvBox>
            <NvNestedDropdown
              menuItems={fieldMenuItems}
              onClickItem={onFieldSelected}
              trigger={
                <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" {...fieldHeaderActionButtonProps}>
                  {fieldHeaderActionButtonProps?.label ?? 'Add Field'}
                </NvButton>
              }
            />
          </NvBox>
        </FreeAppPermissionBoundary>
      </NvBox>

      <NvFlex gap="12px" marginBottom="68px">
        {fields.map((f, index) => (
          <DataModelLayoutLeftContentItem<FieldTypeType>
            fieldName={`fields[${index}]`}
            field={f}
            key={`data_model_left_item_${f.id}`}
            onClick={() => {
              setSelectedFieldIndex(index);
            }}
            selected={index === selectedFieldIndex}
            RightContent={RightContent}
            item={f}
          />
        ))}
      </NvFlex>
    </NvFlex>
  );
};
