import { APP_PERMISSION } from '@novaera/actioner-service';
import {
  Initial,
  NvAddBoxIcon,
  NvButton,
  NvDialogModal,
  NvDivider,
  NvFlex,
  NvImage,
  NvSearchEmptyState,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { SimpleSearchInput } from '../../../../../src/components';
import { ROUTES } from '../../../../common/routes';
import { RoutelessListItem } from '../../../../components/routeless-list-item';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { useGetWorkflowQueryParams } from '../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { WorkflowItemsLoading } from '../../workflows/workflows-loading';
import { DependencyForm } from '../types';
import { useDependencyItems } from './controllers/use-dependency-items';
import { useUpdateDependencyItem } from './controllers/use-update-dependency-item';
import { LinkButton } from './styled';
import { UpdateDependency } from './update-dependency';

type DependencyItemsProps = {
  onAddDependencyClicked: () => void;
};

export const DependencyItems = ({ onAddDependencyClicked }: DependencyItemsProps) => {
  const {
    dependencyItems,
    onSearchKeywordChanged,
    isLoadingAppDependencies,
    hasNoDependency,
    hasSearchKeyword,
    getMenuItems,
    isEditDependencyModalOpened,
    onModalCloseClicked,
    selectedDependency,
  } = useDependencyItems();

  const { updateDependencyItem, isDependencyItemUpdateLoading } = useUpdateDependencyItem();

  const { userAppId } = useGetWorkflowQueryParams();

  const theme = useTheme();

  return (
    <>
      <NvTypography variant="body2">
        Dependencies enable you to establish connections between the current app and others. By incorporating
        dependencies, you gain the ability to invoke workflows from other applications within the workflows of this
        specific app.
      </NvTypography>
      <NvFlex direction="row" alignItems="center" justifyContent="space-between">
        <SimpleSearchInput
          placeholder="Search dependency"
          onKeywordChanged={(keyword) => {
            onSearchKeywordChanged(keyword ?? '');
          }}
        />
        {
          <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
            <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={onAddDependencyClicked}>
              Add dependency
            </NvButton>
          </UserAppPermissionBoundary>
        }
      </NvFlex>

      {isLoadingAppDependencies ? (
        <WorkflowItemsLoading />
      ) : hasNoDependency && hasSearchKeyword ? (
        <NvSearchEmptyState text={'No dependency found. Try different words or clear search bar.'} />
      ) : (
        <NvFlex gap="8px">
          {dependencyItems?.map(({ appName, dependencyId, logoUrl, appId, dependencyName, schemaVersion }) => (
            <RoutelessListItem
              key={`dependency-item-${appName}-${appId}`}
              actions={getMenuItems(dependencyId)}
              content={
                <NvFlex alignItems={'center'} direction={'row'} gap={'8px'}>
                  <NvTypography variant="h4">{dependencyName}</NvTypography>
                  <NvDivider orientation="vertical" sx={{ height: '10px' }} />

                  <LinkButton
                    size="medium"
                    color="ghost"
                    href={`${ROUTES.UserApps}/${appId}`}
                    rel="noopener noreferrer"
                    isFitContent
                    target="_blank"
                    startIcon={
                      <NvImage
                        imageShape="square"
                        size="smaller"
                        src={logoUrl}
                        FallBack={<Initial size="small" color={theme.palette.nv_neutral[500]} value={appName} />}
                      />
                    }
                  >
                    <NvFlex flexDirection={'row'} gap={'6px'} alignItems={'center'}>
                      <NvTypography variant="h4" textColor="secondary">
                        {appName}
                      </NvTypography>
                      {schemaVersion && (
                        <>
                          <NvDivider orientation="vertical" sx={{ height: '10px' }} />
                          <NvTypography variant="h6" textColor="secondary">
                            v{schemaVersion}
                          </NvTypography>
                        </>
                      )}
                    </NvFlex>
                  </LinkButton>
                </NvFlex>
              }
            />
          ))}
        </NvFlex>
      )}
      <NvDialogModal<DependencyForm>
        formProps={{
          initialValues: selectedDependency,
        }}
        fullWidth
        maxWidth="sm"
        onCloseButtonClicked={onModalCloseClicked}
        open={isEditDependencyModalOpened}
        Header={'Edit dependency'}
        Body={<UpdateDependency />}
        primaryButtonText="Save"
        onFormSubmit={(values) => {
          updateDependencyItem({
            appId: userAppId,
            dependencyId: values.id,
            payload: {
              name: values.name,
              dependentAppId: values.appId,
            },
          });
          onModalCloseClicked();
        }}
        isLoading={isDependencyItemUpdateLoading}
      />
    </>
  );
};
