import * as React from 'react';

import { isArray, isUndefined } from 'lodash';
import {
  NvBox,
  NvCircularProgress,
  NvConditionalRender,
  NvConditionalWrap,
  NvDivider,
  NvFlex,
  NvSelectMenuItem,
  NvTooltip,
  NvTypography,
} from '../../index';
import { NvCustomCheckIcon, NvMoreHorizIcon } from '../icons';
import { MenuButtonWrapper, MenuItemNameWrapper, MenuItemStyled, NvStyledMenu } from './styled';
import { MenuWithItems } from './type';

import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import classnames from 'classnames';
import { useState } from 'react';

export const NvMenuWithItems: React.FC<React.PropsWithChildren<MenuWithItems>> = ({
  triggerButton,
  menuItems,
  menuProps,
  onClosed,
  onOpened,
  className,
  anchorOrigin,
  transformOrigin,
  menuType = 'normal',
  useSelectedItemInTriggerButtonContent = false,
  initialSelectedIndex,
}) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { onClick, ...triggerProps } = triggerButton?.props || { onClick: undefined };

  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number | undefined>(initialSelectedIndex);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    onClick?.(event);
    onOpened?.();
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClose = (e: React.BaseSyntheticEvent) => {
    setAnchorEl(null);
    onClosed?.();
    e.stopPropagation();
  };

  return (
    <>
      <MenuButtonWrapper
        color="ghost"
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...(triggerButton
          ? { ...triggerProps }
          : !useSelectedItemInTriggerButtonContent
          ? { onlyIcon: true, size: 'small', color: 'secondary' }
          : { color: 'secondary', size: 'small' })}
        className={classnames(triggerButton?.props.className, {
          'is-opened': !!open,
        })}
        {...(useSelectedItemInTriggerButtonContent &&
          selectedMenuIndex !== undefined &&
          isArray(menuItems) && {
            startIcon: menuItems[selectedMenuIndex].icon,
          })}
      >
        {!useSelectedItemInTriggerButtonContent && triggerButton && triggerButton.content ? (
          triggerButton.content
        ) : !useSelectedItemInTriggerButtonContent ? (
          <NvMoreHorizIcon />
        ) : useSelectedItemInTriggerButtonContent && selectedMenuIndex !== undefined && isArray(menuItems) ? (
          menuItems[selectedMenuIndex].name
        ) : (
          <NvMoreHorizIcon />
        )}
      </MenuButtonWrapper>
      <NvStyledMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: 'bottom',
                horizontal: 'right',
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: 'top',
                horizontal: 'right',
              }
        }
        className={classnames(className)}
        {...menuProps}
      >
        {isArray(menuItems)
          ? menuType === 'normal'
            ? menuItems.map(
                (
                  {
                    name,
                    onClick,
                    isLoading,
                    icon,
                    isDivider,
                    dividerProps,
                    disabled,
                    tooltip,
                    description,
                    iconPosition = 'right',
                  },
                  index
                ) => {
                  if (isDivider) {
                    return <NvDivider key={`menu_${name}_${index}-divider`} {...dividerProps} />;
                  }

                  return (
                    <NvConditionalWrap
                      key={`menu_${name}_${index}`}
                      condition={!!tooltip}
                      wrap={(children) => {
                        assert(!isUndefined(tooltip), new Error('Tooltip should be defined!'), 'ERROR');
                        // Additional NvBox was added to be able to use tooltip on disabled state.
                        return (
                          <NvTooltip {...(typeof tooltip === 'string' ? { title: tooltip } : tooltip.props)}>
                            <NvBox>{children}</NvBox>
                          </NvTooltip>
                        );
                      }}
                    >
                      <NvSelectMenuItem
                        disabled={disabled}
                        sx={isLoading ? { justifyContent: 'center' } : {}}
                        onClick={async (e) => {
                          e.stopPropagation();
                          await onClick?.(e);
                          handleClose(e);
                        }}
                        className={classnames({
                          'left-icon': iconPosition === 'left',
                        })}
                      >
                        <NvConditionalRender when={Boolean(isLoading)}>
                          <NvCircularProgress size={20} />
                        </NvConditionalRender>

                        <NvConditionalRender when={!isLoading && !icon && !description}>{name}</NvConditionalRender>

                        <NvConditionalRender when={!isLoading && !icon && !!description}>
                          <MenuItemNameWrapper flexDirection={'column'} gap={'4px'}>
                            <NvFlex>{name}</NvFlex>
                            <NvFlex>{description}</NvFlex>
                          </MenuItemNameWrapper>
                        </NvConditionalRender>

                        <NvConditionalRender when={!isLoading && Boolean(icon)}>
                          <NvConditionalRender when={iconPosition === 'left'}>
                            <NvConditionalWrap
                              wrap={(children) => <NvBox alignSelf={'flex-start'}>{children}</NvBox>}
                              condition={Boolean(description)}
                            >
                              <>{icon}</>
                            </NvConditionalWrap>
                          </NvConditionalRender>

                          <MenuItemNameWrapper flex={'row'}>
                            <NvBox>{name}</NvBox>
                            <NvConditionalRender when={!!description}>
                              <NvBox>{description}</NvBox>
                            </NvConditionalRender>
                          </MenuItemNameWrapper>

                          <NvConditionalRender when={iconPosition === 'right'}>
                            <NvConditionalWrap
                              wrap={(children) => <NvBox alignSelf={'flex-start'}>{children}</NvBox>}
                              condition={Boolean(description)}
                            >
                              <>{icon}</>
                            </NvConditionalWrap>
                          </NvConditionalRender>
                        </NvConditionalRender>
                      </NvSelectMenuItem>
                    </NvConditionalWrap>
                  );
                }
              )
            : menuItems.map(({ name, description, onClick, icon }, index) => {
                return (
                  <MenuItemStyled
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedMenuIndex(index);
                      onClick?.(e);
                      handleClose(e);
                    }}
                    isSelected={selectedMenuIndex === index}
                    key={`menu_${name}_${index}`}
                  >
                    <NvFlex flexDirection={'row'} gap={'8px'}>
                      <NvBox sx={{ flex: '0 0 auto', paddingTop: '4px' }}>{icon}</NvBox>
                      <NvFlex width="100%" maxWidth="280px">
                        <NvFlex direction="row" alignItems="center" height="24px">
                          <NvTypography
                            variant="h4"
                            sx={{ flex: '1 1 auto', transition: 'color 200ms ease' }}
                            {...(selectedMenuIndex !== undefined && selectedMenuIndex === index
                              ? { color: palette.nv_main[60] }
                              : { color: palette.nv_neutral[1000] })}
                          >
                            {name}
                          </NvTypography>
                          {selectedMenuIndex !== undefined && selectedMenuIndex === index && (
                            <NvCustomCheckIcon
                              sx={{ flex: '0 0 auto' }}
                              {...(selectedMenuIndex !== undefined && selectedMenuIndex === index
                                ? { htmlColor: palette.nv_main[60] }
                                : { htmlColor: palette.nv_neutral[1000] })}
                            />
                          )}
                        </NvFlex>
                        <NvFlex direction="row">
                          <NvTypography variant="body2" sx={{ flex: '1 1 auto' }} textColor="secondary">
                            {description}
                          </NvTypography>
                        </NvFlex>
                      </NvFlex>
                    </NvFlex>
                  </MenuItemStyled>
                );
              })
          : React.cloneElement(menuItems, { onClose: handleClose })}
      </NvStyledMenu>
    </>
  );
};
