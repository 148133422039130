export enum InviteType {
  some = 'some',
  all = 'all',
}

export type InviteUserParams = {
  teamId: string;
  inviteType: InviteType;
  invitedSlackUserIds: string[];
};
