import { useGetIntegration, useGetIntegrationAction, useUpdateFunctions } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { FunctionsFormValues } from '../../types';

export const useFunctionsTab = () => {
  const { actionId, integrationId } = useParams();

  const { mutate } = useUpdateFunctions();

  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({
    actionId,
    integrationId,
    version: integration?.latestVersion.number,
    includeDraft: true,
  });

  const handleOnChange = (values: FunctionsFormValues) => {
    const versionNumber = integration?.latestVersion?.number;
    assert(!isUndefined(versionNumber), new Error('Integration is expected to be defined'), 'ERROR');

    mutate({
      actionId,
      integrationId,
      version: versionNumber,
      payload: { functions: values.functions ?? [] },
    });
  };

  return { data, handleOnChange };
};
