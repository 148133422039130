import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { Connection, TestConnectionError } from '../../connections/types';
import { INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_SCHEMA } from '../keys';
import { UseTestConnectionSchemaParams } from './types';

const testConnection = async (params: UseTestConnectionSchemaParams) => {
  const { integrationId, connectionId, payload } = params;
  const result = await NvAxios.post<Connection | TestConnectionError>(
    `${INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH(integrationId)}/${connectionId}/test`,
    {
      context: payload.context,
    }
  );
  return result?.data;
};

export const useTestConnectionSchema = () => {
  const cache = useQueryClient();

  return useMutation(testConnection, {
    onSuccess(data, variables) {
      cache.setQueryData(
        QUERY_KEYS_CONNECTION_SCHEMA.connectionTest(variables.integrationId, variables.connectionId),
        () => {
          return data;
        }
      );
    },
  });
};
