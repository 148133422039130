import {
  NvBox,
  NvButton,
  NvDivider,
  NvErrorIcon,
  NvFlex,
  NvMenuWithItems,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { ConnectionSchemaLogo } from '../../../../../components/connection-schema-logo';
import { MultipleConnectionSelect } from './multiple-connection-select';
import { ConnectionItemCard } from './styled';
import { AppConnectionItemProps } from './types';
import { useConnectionState } from './use-connection-state';
import { useItemActionMenuItems } from './use-item-action-menu-items';

export const AppConnectionItem: React.FC<AppConnectionItemProps> = ({ connectionMapping, onClick }) => {
  const theme = useTheme();
  const { connectionState } = useConnectionState({ connectionMapping });
  const { moreActionMenuItems } = useItemActionMenuItems({ connectionMapping, connectionState, onClick });

  return (
    <ConnectionItemCard className={classNames({ ambiguous: connectionMapping.ambiguous })}>
      {connectionMapping.ambiguous && (
        <NvErrorIcon
          htmlColor={theme.palette.nv_warning[40]}
          sx={{
            position: 'absolute',
            width: '16px',
            height: '16px',
            top: '50%',
            left: '-10px',
            transform: 'translateY(-50%)',
          }}
        />
      )}
      <NvBox flex={'0 0 auto'}>
        <NvTooltip title={connectionMapping.schemaName}>
          <NvBox>
            <ConnectionSchemaLogo logoUrl={connectionMapping.logoUrl} size="small" />
          </NvBox>
        </NvTooltip>
      </NvBox>
      <NvFlex flex={'1 1 auto'} minWidth={'0'} flexDirection={'row'} alignItems={'center'} gap={'6px'}>
        {connectionState.type === 'no-connection' && (
          <NvTypography variant="h5" noWrap maxWidth={'100%'} flex="1 1 auto">
            {connectionMapping.schemaName}
          </NvTypography>
        )}
        {connectionState.type !== 'no-connection' && (
          <NvDivider orientation="vertical" sx={{ height: '16px', borderColor: theme.palette.nv_neutral_alpha[40] }} />
        )}
        {connectionState.type === 'multiple' &&
        !connectionMapping.slackConnection &&
        !connectionMapping.msTeamsConnection ? (
          <NvBox flex={'0 1 auto'} minWidth={'0'} marginRight="auto">
            <MultipleConnectionSelect connectionMapping={connectionMapping} />
          </NvBox>
        ) : connectionState.type === 'only-one' ? (
          <NvTypography variant="h6" noWrap sx={{ flex: '1 1 auto', marginLeft: '2px' }}>
            {connectionState.connectedConnection.name}
          </NvTypography>
        ) : connectionState.type === 'only-one-slack' ? (
          <NvTypography variant="h6" noWrap sx={{ flex: '1 1 auto', marginLeft: '2px' }}>
            {connectionState.connectedConnection.name}
          </NvTypography>
        ) : connectionState.type === 'only-one-ms-teams' ? (
          <NvTypography variant="h6" noWrap sx={{ flex: '1 1 auto', marginLeft: '2px' }}>
            {connectionState.connectedConnection.name}
          </NvTypography>
        ) : (
          <NvButton
            onClick={() => {
              onClick({
                type: 'connect',
                schemaId: connectionMapping.schemaId,
                integrationId: connectionMapping.integrationId,
              });
            }}
            size="small"
            sx={{ flex: '0 0 auto' }}
          >
            Connect
          </NvButton>
        )}
        {connectionState.type !== 'no-connection' && moreActionMenuItems.length > 1 && (
          <NvMenuWithItems menuItems={moreActionMenuItems} />
        )}
      </NvFlex>
    </ConnectionItemCard>
  );
};
