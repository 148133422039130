import { AwsAssumeRoleAuthentication, BaseAuthentication, OAuth2Authentication } from '@novaera/actioner-service';
// typescript guard function to check if given authentication prop is OAuth2Authentication
//
// @param authentication - authentication prop
// @returns true if authentication is OAuth2Authentication
export const isOAuth2Authentication = (
  authentication?: BaseAuthentication | AwsAssumeRoleAuthentication
): authentication is OAuth2Authentication => {
  return authentication?.type === 'oauth2';
};
