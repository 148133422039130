import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANTS } from '../keys';
import { Assistant } from '../types';

const updateAssistantInstructions = async ({ appId, instructions }: { appId: string; instructions?: string }) => {
  const result = await NvAxios.patch<Assistant>(`${ASSISTANTS_ROOT_PATH(appId)}/instructions`, { instructions });
  return result?.data;
};

export const useUpdateAssistantInstructions = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAssistantInstructions, {
    onSuccess: (response, { appId }) => {
      queryClient.setQueryData(QUERY_KEYS_ASSISTANTS.detail({ appId }), response);
    },
  });
};
