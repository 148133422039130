import { useCreateIntegrationEventRule } from '@novaera/actioner-service';
import { useNavigate, useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { INTEGRATION_EVENT_DETAIL } from '../../../../constants';
import { CreateEventRuleModalFormValues } from '../../types';

export const useCreateEventRuleModalController = ({ onClose }: { onClose: () => void }) => {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const { mutate: createIntegrationEventRule, isLoading } = useCreateIntegrationEventRule();
  const handleOnSubmit = (values: CreateEventRuleModalFormValues) => {
    assert(!!integrationId, new Error(`currentIntegrationId:${integrationId} is undefined`), 'WARNING');

    return new Promise<void>((resolve) => {
      return createIntegrationEventRule(
        { ...values, integrationId },
        {
          onSuccess: ({ id, integrationId }) => {
            resolve();
            onClose();
            navigate(INTEGRATION_EVENT_DETAIL(integrationId, id));
          },
        }
      );
    });
  };

  return {
    isLoading,
    onSubmit: handleOnSubmit,
  };
};
