import { Group } from '@novaera/actioner-service';
import { ProfileImageProps } from '@novaera/core';
import { ReactElement } from 'react';
import { LogoPlaceholder } from '../../../components';

export const DEFAULT_GROUP_IDS = ['workspace-admins', 'everyone'];
export const DEFAULT_GROUP_HELPER_TEXT: Record<Group['id'], ReactElement> = {
  'workspace-admins': (
    <>
      Automatically generated and include <b>all administrator users</b> within your workspace
    </>
  ),
  everyone: (
    <>
      Automatically generated and includes <b>all users</b> within your workspace
    </>
  ),
};

export const DEFAULT_GROUP_LOGO: ({
  size,
}: {
  size?: ProfileImageProps['size'];
}) => Record<Group['id'], ReactElement> = ({ size = 'medium' }) => ({
  'workspace-admins': <LogoPlaceholder size={size} src="assets/group-admin.png" initialText="Group admin logo" />,
  everyone: <LogoPlaceholder size={size} src="assets/group-everyone.png" initialText="Group everyone logo" />,
});
