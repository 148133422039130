import { ParameterMappings } from '@novaera/actioner-service';

export enum FORM_ITEM_DEPENDENCY_STATE {
  NO_DEPENDENCY = 'NO_DEPENDENCY',
  MISSING_VALUE = 'MISSING_VALUE',
  HAS_DEPENDENCY = 'HAS_DEPENDENCY',
  DEPENDENCIES_LOADING = 'LOADING',
}

export type MissingDependencyValue = {
  state: FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE;
  missingDependencies: string[];
};

export type DependencyInputComponentStateReturnValue =
  | {
      state:
        | FORM_ITEM_DEPENDENCY_STATE.NO_DEPENDENCY
        | FORM_ITEM_DEPENDENCY_STATE.HAS_DEPENDENCY
        | FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING;
    }
  | MissingDependencyValue;

export type DependencyInputComponentStateFN = (param: {
  inputParameterId: string;
  inputParameterValues?: ParameterMappings;
}) => DependencyInputComponentStateReturnValue;

export const isMissingDependencyForDynamicInput = (
  value: DependencyInputComponentStateReturnValue
): value is MissingDependencyValue => {
  return value.state === FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE;
};
