import { CatalogEntityType, useListCatalogEntityTypes } from '@novaera/actioner-service';
import {
  DefaultRenderOption,
  NvAutocomplete,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  isRequired,
  useField,
  useForm,
} from '@novaera/core';
import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import OnChange from '../../../action-designer/components/on-change';
import { DynamicMaterialIcons } from '../../../components/dynamic-material-icons';

type RelationshipModalBodyProps = {
  sourceEntityType: CatalogEntityType;
};

export const RelationshipModalBody = ({ sourceEntityType }: RelationshipModalBodyProps) => {
  const [keyWord, setKeyWord] = useState('');

  const { data: catalogEntityTypes, isLoading } = useListCatalogEntityTypes({
    queryParams: {
      nameFilter: keyWord,
    },
  });

  const filteredListItems = useMemo(
    () =>
      catalogEntityTypes?.pages.reduce<CatalogEntityType[]>((acc, page) => [...acc, ...(page.entityTypes ?? [])], []) ??
      [],
    [catalogEntityTypes?.pages]
  );

  const { change } = useForm();

  const {
    input: { value },
  } = useField<string>('targetEntityTypeId');

  const handleSearch = (value: string) => {
    setKeyWord(value);
  };

  const debouncedSearch = useDebouncedCallback(handleSearch, 500);

  return (
    <>
      <OnChange name="targetEntityTypeId">
        {(value) => {
          // the targetEntityType which is used in relationship list is added by client side because it is not saved until the save button is clicked
          const targetEntityType = filteredListItems.find((item) => item.id === value);
          change('targetEntityType', targetEntityType);
        }}
      </OnChange>
      <NvField
        isRequired
        hasRequiredIndicator
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        isAutoComplete
        name={'targetEntityTypeId'}
        parse={(value: CatalogEntityType) => {
          return value?.id;
        }}
        format={(value: string) => {
          return filteredListItems.find((item) => item.id === value);
        }}
        component={
          <NvAutocomplete<CatalogEntityType>
            loading={isLoading}
            onInputChange={(e, value) => {
              debouncedSearch(value);
            }}
            options={filteredListItems}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(props) => {
              const selectedEntityType = filteredListItems.find((item) => item.id === value);
              return (
                <DynamicMaterialIcons initialValue={selectedEntityType?.iconId}>
                  {({ getCurrentIcon }) => {
                    const Icon = getCurrentIcon();
                    return (
                      <NvTextField
                        {...props}
                        placeholder="Select catalog entity type"
                        {...(Icon && {
                          startIcon: <Icon />,
                        })}
                      />
                    );
                  }}
                </DynamicMaterialIcons>
              );
            }}
            renderOption={(props, option) => (
              <DynamicMaterialIcons initialValue={option.iconId}>
                {({ getCurrentIcon }) => {
                  const Icon = getCurrentIcon();
                  return (
                    <DefaultRenderOption {...props} key={option.id}>
                      <NvFlex direction="row" alignItems="center" gap="8px">
                        {Icon && <Icon />}
                        <NvTypography>{option.name}</NvTypography>
                      </NvFlex>
                    </DefaultRenderOption>
                  );
                }}
              </DynamicMaterialIcons>
            )}
            fullWidth
          />
        }
      />
    </>
  );
};
