import { AccessType, GroupUser, WorkspaceUser, useDeleteUserInGroup } from '@novaera/actioner-service';
import {
  BasicTableCell,
  BasicTableRow,
  NeutralChipColor,
  NvButton,
  NvChip,
  NvCloseIcon,
  NvDivider,
  NvFlex,
  NvTypography,
} from '@novaera/core';
import { UserAndGroupImage } from '../../../../../components/user-and-group-image';
import { useUserManagement } from '../../../users/use-user-management';

export const GroupUserItem = ({ user, groupId }: { user: GroupUser; groupId: string }) => {
  const { invitedUsers, onResentInvitationClick } = useUserManagement();
  const { mutate, isLoading } = useDeleteUserInGroup();
  const handleRemoveUserFromGroup = (userId: string) => {
    mutate({ id: groupId, userId });
  };
  return (
    <BasicTableRow key={`${user.email}-${user.userId}`}>
      <BasicTableCell>
        <NvFlex direction="row" gap="16px" alignItems="center">
          <UserAndGroupImage src={user.logoUrl} type={AccessType.USER} />
          <NvTypography variant="h4">{user.userName}</NvTypography>
          <NvDivider orientation="vertical" sx={{ height: '10px' }} />
          <NvTypography variant="body3">{user.email}</NvTypography>

          {user.invitationStatus === 'pending' && (
            <NvChip color={NeutralChipColor.DARK_ALPHA} label="PENDING" compact />
          )}
        </NvFlex>
      </BasicTableCell>

      <BasicTableCell width="176px">
        <NvFlex direction="row" gap="8px" justifyContent="flex-end" height="32px" padding="4px 0">
          {user.invitationStatus === 'pending' && (
            <NvButton
              loading={Boolean(invitedUsers.find((f) => f === user.email))}
              color="secondary"
              size="small"
              onClick={() => {
                onResentInvitationClick(user as WorkspaceUser);
              }}
              sx={{
                minWidth: 0,
                flex: '0 0 auto',
              }}
            >
              Resend invitation
            </NvButton>
          )}

          <NvButton
            onlyIcon
            size="small"
            color="ghost"
            loading={isLoading}
            onClick={() => handleRemoveUserFromGroup(user.userId)}
          >
            <NvCloseIcon />
          </NvButton>
        </NvFlex>
      </BasicTableCell>
    </BasicTableRow>
  );
};
