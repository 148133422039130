import {
  useGetIntegration,
  useGetIntegrationAction,
  useGetIntegrationActionStepResponses,
} from '@novaera/actioner-service';
import { Context } from '@novaera/core';
import { useMemo } from 'react';
import { useInputValuesContext } from '../../../../../action-designer/providers/input-values';
import { useGetIntegrationQueryParams } from '../../../../../controllers/use-get-integration-query-params';

import { getInputContext } from './utils';

export const useIntegrationCodeFieldContext = () => {
  const { actionId, integrationId } = useGetIntegrationQueryParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { enrichedContextFromRequestExecutions } = useGetIntegrationActionStepResponses({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
  });

  const { data: integrationAction } = useGetIntegrationAction({
    actionId,
    integrationId,
    version: integration?.latestVersion.number,
  });

  const { inputValues } = useInputValuesContext();

  const codeFieldContext: Context = useMemo(() => {
    return {
      ...enrichedContextFromRequestExecutions,
      input: getInputContext(integrationAction?.inputParameters, inputValues),
    };
  }, [enrichedContextFromRequestExecutions, inputValues, integrationAction?.inputParameters]);

  return { codeFieldContext };
};
