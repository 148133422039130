import { NvChip, NvFlex, NvTypography, NvWorkflowIcon } from '@novaera/core';
import React from 'react';
import { AppPropertyCard } from './app-property-card';
import { AppPropertiesEmptyStateBox, AppPropertiesGrid } from './styled';
import { AppPropertiesProps } from './types';

export const AppProperties = React.forwardRef<HTMLDivElement, AppPropertiesProps>(({ title, items }, ref) => {
  return (
    <NvFlex ref={ref} gap="12px">
      <NvFlex gap="12px" flexDirection="row" alignItems="center">
        <NvFlex gap="2px" flexDirection="row" alignItems="center">
          <NvWorkflowIcon />
          <NvTypography variant="h2">{title}</NvTypography>
        </NvFlex>

        <NvChip label={items.length} />
      </NvFlex>

      <AppPropertiesGrid>
        {items.length > 0 ? (
          items.map((item, index) => {
            return (
              <AppPropertyCard
                key={`${item.name}-${index}`}
                name={item.name}
                description={item?.description ?? ''}
                workflowType={item.trigger?.type}
              />
            );
          })
        ) : (
          <AppPropertiesEmptyStateBox>
            <NvTypography variant="h5" textColor="subtle">
              This app doesn't have any actions.
            </NvTypography>
          </AppPropertiesEmptyStateBox>
        )}
      </AppPropertiesGrid>
    </NvFlex>
  );
});
