import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomSortDownIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1425_117)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.29381 18L10.301 16H13.0001C13.5501 16 14.0001 16.45 14.0001 17C14.0001 17.55 13.5501 18 13.0001 18H8.29381ZM8.2915 13V11H16.5001C17.0501 11 17.5001 11.45 17.5001 12C17.5001 12.55 17.0501 13 16.5001 13H8.2915ZM8.2915 6V8H20.0001C20.5501 8 21.0001 7.55 21.0001 7C21.0001 6.45 20.5501 6 20.0001 6H8.2915Z"
          fill="currentColor"
        />
        <path
          d="M6.29143 15.01L6.29143 6C6.29143 5.45 5.84143 5 5.29143 5C4.74143 5 4.29143 5.45 4.29143 6L4.29143 15.01L2.50143 15.01C2.05143 15.01 1.83143 15.55 2.15143 15.86L4.94142 18.64C5.14143 18.83 5.45142 18.83 5.65143 18.64L8.44143 15.86C8.76143 15.55 8.53143 15.01 8.09143 15.01L6.29143 15.01Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1425_117">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
