import { ServiceError, ServiceErrorCode } from '@novaera/ah-common';
import axios from 'axios';
import { useCallback } from 'react';
import { useAxiosErrorHandler } from '../../axios';
import { useToast } from '../../ui/toast';

export const useHandleCommonError = () => {
  const { addToast } = useToast();
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const handleCommonError = useCallback(
    (err: unknown) => {
      if (err instanceof ServiceError && err.code === ServiceErrorCode.ACCESS_TOKEN_REFRESH_PROGRESS) {
        return;
      }

      if (axios.isAxiosError(err)) {
        axiosErrorHandler(err);
      } else if (err instanceof Error) {
        if (err.message !== 'undefined') {
          addToast(`An error occurred, message: ${err.message}`, { variant: 'error' });
        }
      } else {
        addToast(`An error occurred, message: ${JSON.stringify(err)}`, { variant: 'error' });
      }
    },
    [addToast, axiosErrorHandler]
  );

  return { handleCommonError };
};
