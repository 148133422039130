import { NvAxios, useQuery } from '@novaera/core';

import { APP_DEPENDENCIES_ROOT } from '../constants';
import { QUERY_KEYS_APP_DEPENDENCIES } from '../keys';
import { AppDependencyListItem } from '../types';
import { GetAppDependencyParam } from './types';

export type GetAppDependenciesResponse = {
  dependencies: AppDependencyListItem[];
};

const getAppDependencies = async ({ appId }: GetAppDependencyParam) => {
  const result = await NvAxios.get<GetAppDependenciesResponse>(`${APP_DEPENDENCIES_ROOT}/${appId}/dependencies`);
  return result?.data;
};

export const useGetAppDependencies = (params: GetAppDependencyParam) =>
  useQuery<GetAppDependenciesResponse>(QUERY_KEYS_APP_DEPENDENCIES.lists(params), () => getAppDependencies(params), {
    enabled: !!params.appId,
  });
