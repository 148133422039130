import { noop } from '@novaera/utils';
import { useCallback } from 'react';
import { useConfirmDialogContext } from '../../context';
import { ConfirmDialogContextAction, OpenConfirmDialogPayload } from '../../context/types';

export const useConfirmDialog = () => {
  const { dispatch } = useConfirmDialogContext();

  const openConfirmDialog = useCallback(
    (payload: OpenConfirmDialogPayload) => {
      const resetContent: OpenConfirmDialogPayload = {
        confirmButtonLabel: undefined,
        cancelButtonLabel: undefined,
        title: undefined,
        message: undefined,
        confirmButton: undefined,
        icon: undefined,
        onConfirm: noop,
      };
      dispatch({ type: ConfirmDialogContextAction.Open, payload: { ...resetContent, ...payload } });
    },
    [dispatch]
  );

  const closeConfirmDialog = useCallback(() => {
    dispatch({ type: ConfirmDialogContextAction.Close });
  }, [dispatch]);
  return { openConfirm: openConfirmDialog, closeConfirm: closeConfirmDialog };
};
