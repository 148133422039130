import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ACTIONER_EVENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS } from '../keys';
import { ActionerEvent } from '../types';
import { UpdateActionerEventPayloadParams } from './types';

const updateActionerEventPayload: (params: UpdateActionerEventPayloadParams) => Promise<ActionerEvent> = async ({
  appId,
  id,
  payload,
}) => {
  const result = await NvAxios.put<ActionerEvent>(`${ACTIONER_EVENTS_ROOT_PATH(appId)}/${id}/payload`, payload);
  return result?.data;
};

export const useUpdateActionerEventPayload = () => {
  const cache = useQueryClient();

  return useMutation(updateActionerEventPayload, {
    onSuccess: (data, { appId }) => {
      cache.setQueryData<ActionerEvent[]>(QUERY_KEYS_ACTIONER_EVENTS.list(appId), (old) => {
        return old?.map((e) => (e.id === data.id ? { ...e, ...data } : e));
      });
      cache.setQueryData<ActionerEvent>(QUERY_KEYS_ACTIONER_EVENTS.detail(data.appId, data.id), (old) => {
        return { ...data };
      });
    },
  });
};
