import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

// App directory list header inner
// ------------------------------------------------------------------------

export const AppDirectoryMobileSearch = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isPublic' })<{
  isPublic?: boolean;
}>`
  display: none;
  flex-direction: column;
  gap: 12px;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  margin: -32px -32px 0 -32px;
  padding: 16px 32px;

  @media screen and (max-width: 991px) {
    display: flex;
    position: sticky;
    top: ${({ isPublic }) => (isPublic ? '36px' : '-44px')};
    z-index: 3;
  }

  @media screen and (max-width: 767px) {
    margin: -32px -24px 0 -24px;
    padding: 16px 24px;
  }
`;

// App directory body
// ------------------------------------------------------------------------

export const AppDirectoryBody = styled(NvFlex)`
  flex-direction: row;
  width: 100%;
  max-width: 1288px;
  margin: 0 auto;
`;

export const AppDirectoryBodyLeft = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isPublic' })<{
  isPublic?: boolean;
}>`
  flex: 0 0 auto;
  min-height: 0;
  padding: 32px 24px 32px 32px;
  position: sticky;
  height: 100vh;
  top: 0;
  overflow: auto;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const AppDirectoryBodyRight = styled(NvFlex)`
  width: 100%;
  flex: 1 1 auto;
  min-width: 0;
  gap: 32px;
  padding: 32px 40px 32px 16px;

  @media screen and (max-width: 991px) {
    padding: 32px 32px 32px 32px;
  }

  @media screen and (max-width: 767px) {
    padding: 32px 24px 32px 24px;
  }
`;

export const AppDirectoryGrid = styled('div')`
  display: grid;
  width: 100%;
  height: auto;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(342px, 1fr));

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const FilterDrawerHeader = styled(NvFlex)`
  position: sticky;
  top: 0;
  flex-direction: row;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  align-items: center;
`;

export const ApplyFilterButtonBox = styled(NvFlex)`
  position: sticky;
  bottom: 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  flex: 0 0 auto;
`;

export const AppDirectoryListContainer = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  position: relative;
  flex: 1 1 auto;
  min-height: 0;

  &.app-directory-body {
    height: auto;
    min-height: calc(100vh);

    &.public {
      min-height: calc(100vh - 38px);
    }
  }
`;
