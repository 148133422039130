import { DefaultNodeSummary, WorkflowResolverNode, WorkflowResolverVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class WorkflowResolverComponentFactory implements ComponentFactory<WorkflowResolverNode> {
  aliasForWorkflowResolver: string;
  nameForWorkflowResolver: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('workflowResolver');
    this.aliasForWorkflowResolver = newAlias;
    this.nameForWorkflowResolver = newName;
  }

  get componentForGraph() {
    const workflowResolverRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForWorkflowResolver,
      width: 40,
      height: 40,
      id: this.aliasForWorkflowResolver,
      type: 'workflowResolver',
      alias: this.aliasForWorkflowResolver,
    };

    return { root: workflowResolverRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: WorkflowResolverNode) {
    const newNodeUnion: WorkflowResolverVertex = {
      type: 'workflowResolver',
      alias: this.aliasForWorkflowResolver,
    };
    const summary: DefaultNodeSummary = {
      name: this.nameForWorkflowResolver,
      alias: this.aliasForWorkflowResolver,
      type: 'workflowResolver',
    };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForWorkflowResolver]: summary } };
  }
}
