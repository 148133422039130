import { ActionerEventNode, useListActionerEvents, useUpdateNode } from '@novaera/actioner-service';
import {
  CodeInput,
  NvAutocomplete,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvReceiptLongIcon,
  NvSkeleton,
  NvTextField,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { NodeType, PropertyPanelHeader, PropertyPanelSection } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { ActionerEventNodePropertiesProps } from './types';

export const ActionerEventNodeProperties: FC<ActionerEventNodePropertiesProps> = ({
  onCloseClicked,
  node,
  onRowItemClicked,
}) => {
  assert(
    !!node,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const theme = useTheme();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { data, isLoading: isEventsLoading } = useListActionerEvents({
    appId: userAppId,
  });

  const { hasEditPermission } = useWorkflowPermission();

  const { mutate: updateNodeDetail } = useUpdateNode();

  const [scriptValue, setScriptValue] = useState<string>(node.eventTemplate ?? '');

  useEffect(() => {
    setScriptValue(node.eventTemplate);
  }, [node.eventTemplate]);

  const handleSave = useCallback(
    (newNode: ActionerEventNode) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail({ appId: userAppId, workflowId, nodeAlias: node.alias, payload: newNode });
    },
    [hasEditPermission, updateNodeDetail, userAppId, workflowId, node.alias]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: node.alias,
          payload: { ...node, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(node.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [node, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const debouncedSave = useDebouncedCallback(handleSave, 500);

  return (
    <NvFlex width="100%">
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          title={node?.name}
          type={NodeType.ACTIONER_EVENT_TRIGGER}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: node.alias });
                    },
                  },
                ]}
              />
              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={node?.name}
          type={NodeType.ACTIONER_EVENT_TRIGGER}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>

      <PropertyPanelSection title="Event" tooltip="The node's response will be published to the chosen event.">
        <NvFlex gap="8px">
          {isEventsLoading && <NvSkeleton variant="rectangular" width="100%" height="32px" />}
          {!isEventsLoading && (
            <>
              <NvAutocomplete
                fullWidth
                disableClearable
                options={data ?? []}
                size="small"
                getOptionLabel={(option) => option.name}
                value={data?.find((option) => option.id === node?.eventId)}
                onChange={(e, selectedItem) => {
                  if (!hasEditPermission) {
                    return;
                  }
                  const newNode: ActionerEventNode = {
                    ...node,
                    eventId: selectedItem.id,
                  };

                  handleSave(newNode);
                }}
                renderInput={(params) => (
                  <NvTextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    size="medium"
                  />
                )}
              />

              <NvFlex gap="8px">
                <RowItemCard
                  rowItemLeftContentProps={{ draggable: false }}
                  rowItemContent={<SimpleLabelLayout simpleLabel="See schema" />}
                  onClick={() =>
                    onRowItemClicked({
                      index: 1,
                      property: {
                        schema: data?.find((option) => option.id === node?.eventId)?.payloadSchema ?? '',
                      },
                    })
                  }
                />
                <RowItemCard
                  rowItemLeftContentProps={{ draggable: false }}
                  rowItemContent={<SimpleLabelLayout simpleLabel="See example payload" />}
                  onClick={() =>
                    onRowItemClicked({
                      index: 2,
                      property: {
                        schema: data?.find((option) => option.id === node?.eventId)?.examplePayload ?? '',
                      },
                    })
                  }
                />
              </NvFlex>
            </>
          )}
        </NvFlex>
      </PropertyPanelSection>

      <PropertyPanelSection icon={<NvReceiptLongIcon />} title={'Event payload'}>
        <NvFlex gap="8px">
          <CodeInput
            value={scriptValue}
            onChange={(newValue) => {
              setScriptValue(newValue);
              debouncedSave({ ...node, eventTemplate: newValue });
            }}
            context={workflowCodeInputContext}
            initialHeight="178px"
            growingHeight={true}
            maxHeight="500px"
            placeholder="There isn't any payload data to show here. "
          />
        </NvFlex>
      </PropertyPanelSection>
    </NvFlex>
  );
};
