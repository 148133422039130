import { MarkDownBlock } from '@novaera/actioner-service';
import { CodeInput, NvDeleteOutlineIcon, NvMenuWithItems } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { PropertyPanelSection } from '../../../property-panel';
import { MarkdownBlockProps } from '../types';

export const MarkdownBlock: FC<React.PropsWithChildren<MarkdownBlockProps>> = ({
  block,
  onChange,
  dragHandleProps,
  onDeleteClick,
  index,
  context = {},
}) => {
  const theme = useTheme();

  const handleOnChange = (value: string) => {
    const newBlock: MarkDownBlock = {
      ...block,
      value: { ...block.value, value },
    };

    onChange(newBlock, index);
  };
  return (
    <PropertyPanelSection
      dragHandleProps={dragHandleProps}
      title="Markdown block"
      draggable
      collapsible
      actions={
        <NvMenuWithItems
          triggerButton={{
            props: { color: 'ghost', size: 'small', variant: 'contained', onlyIcon: true },
          }}
          menuItems={[
            {
              name: 'Delete',
              onClick: onDeleteClick,
              icon: (
                <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
              ),
            },
          ]}
        />
      }
    >
      <CodeInput
        value={block.value.value}
        onChange={handleOnChange}
        context={context}
        initialHeight="100px"
        placeholder="Supports markdown here"
      />
    </PropertyPanelSection>
  );
};
