export const WORKFLOW_RESOLVER_SAMPLE_RESPONSE = {
  workflowId: 12345,
  workflowName: 'Sample workflow name',
  matchScore: 0.4,
  appId: 12345,
  allMandatoryParametersAreSatisfied: true,
  hasMissingParameterValues: false,
  parameterValues: '{ name: Joe, surname: Doe }',
  parameterNames: ['name', 'surname'],
  otherMatchingWorkflows: [
    {
      appId: 'a1',
      workflowId: 'wf2',
      matchScore: 76.1,
    },
  ],
};

export const QA_SAMPLE_RESPONSE = {
  answer: 'Here is the answer to your question...',
  usedInformation: 'Here is the information used:...',
};

export const ASSISTANT_SAMPLE_RESPONSE = {};
export const GET_CONVERSATION_SAMPLE_RESPONSE = {
  existing: true,
  metadata: {
    channelId: 'C0XXXXXXXXX',
  },
};

export const SLACK_SAMPLE = {
  channel: {
    id: 'C0XXXXXXXXX',
    name: 'general',
  },
  team: {
    id: 'T0XXXXXXXXX',
  },
  message: {
    thread_ts: '1691992610.862199',
    user: 'U0XXXXXXXXX',
    app_id: 'A05XXXXXXXXX',
    bot_id: 'B0XXXXXXXXX',
    ts: '1691992630.880089',
  },
  user: {
    id: 'U0XXXXXXXXX',
    name: 'Alison',
  },
};

export const SAVE_RECURRING_JOB_SAMPLE_RESPONSE = {
  job: {
    targetWorkflowParameters: {
      input_component_1: 'sample text',
    },
    appId: 'xxxxxxxx-771b-4c98-b35a-238c0316f113',
    name: 'First recurring job',
    id: 'xxxxxxxx-0c5b-48c2-9306-bf6f64c9fa07',
    type: 'recurring',
    targetWorkflowId: 'xxxxxxxx-bcd4-4927-ae76-e80e983aad61',
  },
};

export const SAVE_SCHEDULED_JOB_SAMPLE_RESPONSE = {
  job: {
    targetWorkflowParameters: {
      input_component_1: 'sample text',
    },
    appId: 'xxxxxxxx-771b-4c98-b35a-238c0316f113',
    name: 'First scheduled job',
    id: 'xxxxxxxx-5529-4b00-a8bf-1d7a20ca45dc',
    type: 'scheduled',
    targetWorkflowId: 'xxxxxxxx-bcd4-4927-ae76-e80e983aad61',
  },
};

export const DELETE_JOB_SAMPLE_RESPONSE = {
  job: {
    appId: 'xxxxxxxx-771b-4c98-b35a-238c0316f113',
    name: 'First scheduled job',
    id: 'xxxxxxxx-5529-4b00-a8bf-1d7a20ca45dc',
    type: 'scheduled',
    targetWorkflowId: 'xxxxxxxx-bcd4-4927-ae76-e80e983aad61',
  },
};
