import {
  SlackAuthAnonymousAccessEnterpriseGridResponse,
  SlackAuthAnonymousAccessResponse,
  SlackAuthAnonymousAccessWorkspaceSelectionResponse,
} from './types';

export const isSlackAuthAccessAnonymous = (
  response:
    | SlackAuthAnonymousAccessResponse
    | SlackAuthAnonymousAccessWorkspaceSelectionResponse
    | SlackAuthAnonymousAccessEnterpriseGridResponse
): response is SlackAuthAnonymousAccessResponse => {
  return (
    (response as SlackAuthAnonymousAccessResponse).slackAppMessageTabUrl !== undefined &&
    (response as SlackAuthAnonymousAccessResponse).status === 'all-set'
  );
};

export const isSlackAuthWorkspaceSelectionResponse = (
  response:
    | SlackAuthAnonymousAccessResponse
    | SlackAuthAnonymousAccessWorkspaceSelectionResponse
    | SlackAuthAnonymousAccessEnterpriseGridResponse
): response is SlackAuthAnonymousAccessWorkspaceSelectionResponse => {
  return (response as SlackAuthAnonymousAccessWorkspaceSelectionResponse).status === 'workspace-selection-pending';
};

export const isSlackAuthEnterpriseGridResponse = (
  response:
    | SlackAuthAnonymousAccessResponse
    | SlackAuthAnonymousAccessWorkspaceSelectionResponse
    | SlackAuthAnonymousAccessEnterpriseGridResponse
): response is SlackAuthAnonymousAccessEnterpriseGridResponse => {
  return (response as SlackAuthAnonymousAccessEnterpriseGridResponse).status === 'enterprise-installation-result';
};
