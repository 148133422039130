import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const NvCustomHelpIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(({ ...props }, ref) => {
  return (
    <SvgIcon
      ref={ref}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM6.5 9.5H5.5V8.5H6.5V9.5ZM7.535 5.625L7.085 6.085C6.835 6.34 6.655 6.57 6.565 6.93C6.525 7.09 6.5 7.27 6.5 7.5H5.5V7.25C5.5 7.02 5.54 6.8 5.61 6.595C5.71 6.305 5.875 6.045 6.085 5.835L6.705 5.205C6.935 4.985 7.045 4.655 6.98 4.305C6.915 3.945 6.635 3.64 6.285 3.54C5.73 3.385 5.215 3.7 5.05 4.175C4.99 4.36 4.835 4.5 4.64 4.5H4.49C4.2 4.5 4 4.22 4.08 3.94C4.295 3.205 4.92 2.645 5.695 2.525C6.455 2.405 7.18 2.8 7.63 3.425C8.22 4.24 8.045 5.115 7.535 5.625Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
