export const JS_DECLARATIONS: Record<string, string> = {
  URL: `class URL {
        constructor(url: string, base?: string);
        attribute href: string;
        readonly attribute origin: string;
        attribute protocol: string;
        attribute username: string;
        attribute password: string;
        attribute host: string;
        attribute hostname: string;
        attribute port: string;
        attribute pathname: string;
        attribute search: string;
        readonly attribute searchParams: URLSearchParams;
        attribute hash: string;
        toJSON(): string;
        toString(): string;
        static canParse(url: string, base?: string): boolean;
      }`,
};
