import { OutputNode, PieChartOutputComponent, useUpdateNode, WorkflowState } from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvCandleStickChart,
  NvConditionalRender,
  NvCustomShareIcon,
  NvDatasetIcon,
  NvField,
  NvFlex,
  NvForm,
  NvSelect,
  NvSwitch,
  NvTextField,
  NvTypography,
  NvViewCompactIcon,
} from '@novaera/core';
import { noop } from 'lodash';
import { FC, useCallback } from 'react';
import { PropertyPanelSection, PropertyPanelSimpleSection } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { OutputOrder } from '../common/output-order';
import { METHOD_OPTIONS } from '../utils/method-options';
import { PieChartOutputComponentForm, PieChartPanelSectionProps } from './types';

export const PieChartPanelSection: FC<React.PropsWithChildren<PieChartPanelSectionProps>> = ({
  pieChartOutputComponent,
  alias,
  name,
  userAppId,
  workflowId,
}) => {
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { hasEditPermission } = useWorkflowPermission();

  const handleSave = useCallback(
    (newPieChartOutputComponent: PieChartOutputComponentForm) => {
      const newNode: OutputNode = {
        alias,
        name,
        // this is because the wrong typing in update Node detail function. it actually can take values with optional fields but it looks it is not.
        outputComponent: newPieChartOutputComponent as PieChartOutputComponent,
        type: 'output',
        state: WorkflowState.DRAFT,
      };

      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: alias,
        payload: newNode,
      });
    },
    [alias, name, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvForm<PieChartOutputComponentForm>
      onSubmit={noop}
      onChange={({ values }) => {
        const { chart, dataSource, ...otherValues } = values;
        const newPieChartOutputComponent: PieChartOutputComponentForm = {
          chart: {
            type: 'pie',
            title: chart?.title,
            distribution: {
              type: 'aggregator-method',
              method: chart?.distribution?.method,
              value: chart?.distribution?.value,
              title: chart?.distribution?.title,
            },
            groupBy: {
              type: 'javascript',
              title: chart?.groupBy?.title,
              value: chart?.groupBy?.value,
            },
          },
          dataSource: { type: 'javascript', value: dataSource?.value },
          ...otherValues,
        };
        handleSave(newPieChartOutputComponent);
      }}
      keepDirtyOnReinitialize
      initialValues={pieChartOutputComponent}
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <PropertyPanelSection icon={<NvCandleStickChart />} title="Data">
        <NvFlex gap="8px" direction="column">
          <NvField<PieChartOutputComponent['dataSource']['value']>
            name="dataSource.value"
            labelText="Chart source"
            labelVariant="h6"
            component={<CodeInput context={workflowCodeInputContext} />}
            defaultValue="{{ }}"
          />
          <NvField<PieChartOutputComponent['chart']['groupBy']['value']>
            name="chart.groupBy.value"
            labelText="X-axis values"
            labelVariant="h6"
            component={<CodeInput context={workflowCodeInputContext} />}
            defaultValue="{{ }}"
          />
        </NvFlex>
      </PropertyPanelSection>
      <PropertyPanelSection icon={<NvDatasetIcon />} title="Dataset">
        <NvFlex gap="8px" direction="column">
          <NvField<PieChartOutputComponent['chart']['distribution']['value']>
            name="chart.distribution.value"
            labelText="Source"
            labelVariant="h6"
            component={<CodeInput context={workflowCodeInputContext} />}
          />
          <NvField<PieChartOutputComponent['chart']['distribution']['method']>
            name="chart.distribution.method"
            labelText="Method"
            labelVariant="h6"
            defaultValue="sum"
            component={<NvSelect options={METHOD_OPTIONS} sx={{ width: '200px' }} />}
          />
        </NvFlex>
      </PropertyPanelSection>
      <PropertyPanelSection icon={<NvViewCompactIcon />} title="Layout">
        <NvFlex gap="8px" direction="column">
          <NvField<PieChartOutputComponent['chart']['title']>
            name="chart.title"
            labelText="Chart title"
            labelVariant="h6"
            component={<NvTextField />}
          />
        </NvFlex>
      </PropertyPanelSection>
      <NvConditionalRender when={hasEditPermission}>
        <OutputOrder />
      </NvConditionalRender>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelSimpleSection>
          <NvBox width={'100%'}>
            <NvField
              formControlStyle={{ width: '100%' }}
              name="showShareButton"
              type="checkbox"
              component={({ onChange, checked, value }) => {
                return (
                  <NvFlex flexDirection={'row'} alignItems={'center'}>
                    <NvFlex direction={'row'} gap={'8px'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
                      <NvCustomShareIcon />
                      <NvTypography variant="body2">Show share button</NvTypography>
                    </NvFlex>
                    <NvSwitch value={value} onChange={onChange} checked={checked} variant="compact" />
                  </NvFlex>
                );
              }}
            />
          </NvBox>
        </PropertyPanelSimpleSection>
      </NvConditionalRender>
    </NvForm>
  );
};
