import { useGetIntegrationUpdates, useWorkflowServiceUtils } from '@novaera/actioner-service';
import { NvButton, NvDialogModal } from '@novaera/core';
import { useGetWorkflowQueryParams } from '../../../controllers/use-get-workflow-query-params';
import { usePropertyPanelContext } from '../../../properties-panel/provider';
import { IntegrationUpdateModalBody } from './body';

export const IntegrationUpdateModal = ({
  isModalOpened,
  onModalCloseClicked,
}: {
  isModalOpened: boolean;
  onModalCloseClicked: () => void;
}) => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();

  const { data: integrationUpdates, isFetching } = useGetIntegrationUpdates({
    appId: userAppId,
    workflowId,
  });

  const { invalidateWorkflowCache, invalidateNodeCache } = useWorkflowServiceUtils();
  const { isPanelOpen, selectedNode } = usePropertyPanelContext();

  const handleModalClose = () => {
    if (integrationUpdates?.hasUpdatedIntegrations) {
      invalidateWorkflowCache({
        appId: userAppId,
        workflowId,
      });

      if (isPanelOpen && selectedNode) {
        invalidateNodeCache({
          appId: userAppId,
          workflowId,
          nodeAlias: selectedNode.alias,
        });
      }
    }
    onModalCloseClicked();
  };

  return (
    <NvDialogModal
      unmountOnExit
      maxWidth="md"
      fullWidth
      onCloseButtonClicked={handleModalClose}
      open={isModalOpened}
      Header="Update integrations"
      modalIcon="appUpdate"
      Footer={
        <NvButton color="secondary" onClick={handleModalClose}>
          Close
        </NvButton>
      }
      Body={
        <IntegrationUpdateModalBody
          availableUpdates={integrationUpdates?.availableUpdates ?? []}
          isLoading={isFetching}
        />
      }
    />
  );
};
