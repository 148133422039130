import { ConditionType } from '@novaera/actioner-service';
import { CodeInput, CollapsibleBySwitch, NvBox, NvField, NvFlex, NvTypography } from '@novaera/core';
import { useState } from 'react';
import { MatchEventSectionCondition, MatchEventSectionRules } from '../../../../../src/components';
import { AddButton } from '../../../../../src/components/button/add';
import { ERROR_HANDLING_CONDITION_FIELD_NAME } from './constants';
import { useErrorHandlingResponseTabController } from './controllers/use-error-handling-response-tab';
import { JavascriptWrapper } from './styled';

export const ErrorHandlingResponseTab = () => {
  const { isErrorHandlingEnabled, onErrorResponseConfigurationChange, addButtonRef } =
    useErrorHandlingResponseTabController();
  const [conditionType, setConditionType] = useState<ConditionType['type']>();

  return (
    <NvFlex gap="16px">
      <NvTypography>
        Actioner will classify all request responses with a 2xx status code as successful. Conversely, any response
        without a 2xx status code will be treated as an error. To establish further criteria for identifying an error
        state, you can set conditions and construct an error response body below
      </NvTypography>
      <CollapsibleBySwitch
        isOpen={isErrorHandlingEnabled}
        fields={
          <NvFlex gap="24px">
            <NvFlex gap="8px" height="100%">
              <NvFlex direction="row" alignItems="center" gap="8px">
                <MatchEventSectionCondition
                  codeInputContext={{}}
                  conditionFieldName={ERROR_HANDLING_CONDITION_FIELD_NAME}
                  onConditionTypeChange={(conditionType) => {
                    setConditionType(conditionType);
                  }}
                />
                {(conditionType === 'match-all-condition' || conditionType === 'match-any-condition') && (
                  <NvBox flex="0 0 auto">
                    <AddButton label="Add rule" color="ghost" size="small" fullWidth ref={addButtonRef} />
                  </NvBox>
                )}
              </NvFlex>

              {conditionType !== 'no-condition' && (
                <MatchEventSectionRules
                  codeInputContext={{}}
                  conditionFieldName={ERROR_HANDLING_CONDITION_FIELD_NAME}
                  conditionType={conditionType}
                  showAddButton={false}
                  showRemoveButton={true}
                  JavascriptWrapperComponent={JavascriptWrapper}
                />
              )}
            </NvFlex>
            <NvFlex gap="8px">
              <NvTypography variant="h4">Response</NvTypography>
              <NvField
                name="errorResponseConfiguration.responseTemplate"
                formControlStyle={{ width: '100%' }}
                component={<CodeInput context={{}} initialHeight="178px" placeholder="{{ ... }}" />}
                hint={
                  <NvTypography variant="body3" textColor="subtle">
                    JSON is supported.
                  </NvTypography>
                }
              />
            </NvFlex>
          </NvFlex>
        }
        title="Error handling"
        onCollapseChanged={(e, checked) => {
          onErrorResponseConfigurationChange(checked);
        }}
      />
    </NvFlex>
  );
};
