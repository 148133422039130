import { useCallback } from 'react';

export const useDocumentTitle = () => {
  const handleSetDocumentTitle = useCallback((title: string, callback?: () => void) => {
    document.title = title;
    callback?.();
  }, []);

  return {
    setDocumentTitle: handleSetDocumentTitle,
  };
};
