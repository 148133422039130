import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppSelectInitiator = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: -4px 8px -4px 0;
  border-radius: 6px;
  cursor: pointer;

  &.is-open {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};

    &.is-open {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  }
`;
