import { MediaFileReferenceType, MediaFileType } from '@novaera/actioner-service';
import { ImageContent } from './image';
import { AddMediaModalFormValues, AddMediaModalType } from './types';
import { YoutubeVideoContent } from './youtube-video';

export const ADD_MEDIA_MODAL_INITIAL_VALUES: AddMediaModalFormValues = {
  image: {
    displayText: '',
    fileReference: { type: MediaFileReferenceType.S3, objectKey: '', fileName: '' },
    type: MediaFileType.IMAGE,
  },
  video: {
    displayText: '',
    fileReference: { type: MediaFileReferenceType.WEB, url: '' },
    type: MediaFileType.VIDEO,
  },
};

export const ADD_MEDIA_MODAL_TABS = [
  {
    label: 'Image',
    value: AddMediaModalType.IMAGE,
    content: <ImageContent />,
  },
  {
    label: 'Youtube video',
    value: AddMediaModalType.YOUTUBE_VIDEO,
    content: <YoutubeVideoContent />,
  },
];
