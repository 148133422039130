import { NvAutocomplete, NvTextField } from '@novaera/core';
import { CRON_DAY_OF_MONTH_OPTIONS } from '../../save-job-modal/configure-recurrence/constants';
import { CronDayOfMonthPickerProps } from './types';

export const CronDayOfMonthPicker: React.FC<CronDayOfMonthPickerProps> = ({ cron, onChange }) => {
  const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = cron.split(' ');
  const selectedDayOfMonth = CRON_DAY_OF_MONTH_OPTIONS.filter(({ value }) => dayOfMonth.split(',').includes(value));
  return (
    <NvAutocomplete
      value={selectedDayOfMonth}
      multiple
      options={CRON_DAY_OF_MONTH_OPTIONS}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
        const dom = value.map((v) => v.value).join(',');
        const cron = `${minutes} ${hours} ${dom || `?`} ${month} ${dayOfWeek} ${year}`;
        onChange(cron);
      }}
      renderInput={(params) => (
        <NvTextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          size="medium"
        />
      )}
    />
  );
};
