import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ListItemContainer = styled(NvFlex)`
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  justify-content: space-between;
  padding: 11px 16px 11px 16px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;

  .base-item-actions {
    opacity: 0;
    transition: opacity 150ms ease-in-out, margin 200ms ease-in-out;
    margin-right: -32px;
  }

  &.is-base-item-actions-menu-open,
  :hover {
    .base-item-actions {
      opacity: 1;
      margin-right: 0;
    }
  }

  &.is-base-item-disabled {
    pointer-events: none;
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  }
`;
export const ListItemContentContainer = styled(NvFlex)`
  flex-direction: column;
  flex: 1 1 auto;
`;
export const ListItemActionContainer = styled(NvFlex)`
  /* display: none; */
`;
