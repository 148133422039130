import { NvFlex, NvTypography } from '@novaera/core';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import { RuleValueCard } from './styled';

export const RuleKeyValue: React.FC<{ label: string; value: string; isSuccess?: boolean; secondaryValue?: string }> = ({
  label,
  value,
  secondaryValue,
  isSuccess,
}) => {
  return (
    <NvFlex direction="row" alignItems="center">
      <NvTypography variant="h5" textColor="secondary" width="90px" flex="0 0 auto">
        {label}
      </NvTypography>
      <RuleValueCard
        className={classnames({
          success: !isUndefined(isSuccess) && isSuccess,
          error: !isUndefined(isSuccess) && !isSuccess,
        })}
      >
        <NvTypography variant="c2">{value}</NvTypography>
        {secondaryValue && (
          <NvTypography variant="c2" textColor="subtle">
            {secondaryValue}
          </NvTypography>
        )}
      </RuleValueCard>
    </NvFlex>
  );
};
