import { NvCheckbox, NvField, NvFlex, NvTextArea, NvTypography, SectionMessage, isRequired } from '@novaera/core';
import { CheckboxContainer } from '../../../../components/ui-components/checkbox-component/styled';

export const DeleteWorkspaceModalBody = () => {
  return (
    <NvFlex gap="16px">
      <SectionMessage
        variant="error"
        message={
          <NvTypography variant="body2">
            Deleting the Actioner workspace <b>will permanently remove all the data</b>.<br />
            Workspace cannot be recovered after the deletion.
          </NvTypography>
        }
      />
      <NvTypography>
        You are about to begin the deletion process. We will also notify the other workspace admins about the process.
      </NvTypography>
      <NvTypography>
        You have a <b>72-hours to reconsider</b> and cancel the process. After this period, the deletion process will
        start and there will be no way to retrieve the workspace data.
      </NvTypography>
      <NvTypography>The complete data deletion could take up to 24 hours once initiated.</NvTypography>
      <NvField
        labelText="Reason for workspace deletion"
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        validators={[isRequired()]}
        direction="label-on-top"
        name="reason"
        component={<NvTextArea rows={3} placeholder="Please briefly explain the reason for deleting this workspace." />}
      />

      <NvField
        name="acknowledge"
        component={({ value, onChange }) => (
          <CheckboxContainer
            onClick={() => {
              onChange(!value);
            }}
          >
            <NvCheckbox sx={{ marginTop: '4px' }} checked={!!value} />
            <NvTypography>I acknowledge the outcomes of deleting this workspace.</NvTypography>
          </CheckboxContainer>
        )}
      />
    </NvFlex>
  );
};
