import {
  UIComponentType,
  useGetIntegration,
  useGetIntegrationAction,
  useUpdateInputParameters,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { useDynamicInputContext } from '../../../../../../components';
import { useIntegrationActionOptionsContext } from '../../../../../../components/options/provider';
import { InputMenuItemValue } from '../../../../../../utils/helpers/add-new-input-menu-items/types';
import { generateNewInputParameter } from '../../../../../../utils/helpers/generate-new-input-parameter';
import { useInputsContext } from '../../../../../providers/input-provider';

export const useAddNewInputItem = () => {
  const { actionId, integrationId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ actionId, integrationId, version: integration?.latestVersion.number });
  const { mutate } = useUpdateInputParameters();
  const { setSelectedInputParameterId } = useInputsContext();
  const { setInputParameterIdsShowingOptions } = useIntegrationActionOptionsContext();
  const { setInputParameterIdsShowingDynamicInput } = useDynamicInputContext();
  const inputParameters = data?.inputParameters ?? [];

  const handleNewItemAddClicked = (input: InputMenuItemValue) => {
    const newInputParameter = generateNewInputParameter(input, inputParameters);
    const version = integration?.latestVersion?.number;
    assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');

    mutate(
      { integrationId, actionId, version, payload: { inputParameters: [...inputParameters, newInputParameter] } },
      {
        onSuccess: () => {
          setSelectedInputParameterId(newInputParameter.id);
          if (
            newInputParameter.uiComponent.type === UIComponentType.CHECK_BOX_GROUP ||
            newInputParameter.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP
          ) {
            setInputParameterIdsShowingOptions((prevValue) => {
              return [...prevValue, newInputParameter.id];
            });
          } else if (newInputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT) {
            setInputParameterIdsShowingDynamicInput((prevValue) => {
              return [...prevValue, newInputParameter.id];
            });
          }
        },
      }
    );
  };

  return {
    onNewItemAddClick: handleNewItemAddClicked,
  };
};
