import { Draggable } from '@hello-pangea/dnd';
import Tab, { TabProps } from '@mui/material/Tab';
import { assert } from '@novaera/utils';
import { isString } from 'lodash';
import React from 'react';
import { NvBox } from '../../box';
import { TypeOfTab } from '../types';

const DraggableTabItem: React.FC<
  React.PropsWithChildren<{ tab: TypeOfTab; index: number; TabItemWrapper?: React.ReactElement } & TabProps>
> = ({ tab, index, TabItemWrapper, ...props }) => {
  assert(
    Boolean(tab.id) || (!isString(tab.label) && Boolean(tab.label)),
    new Error(
      "For draggable tab item, id or label should be provided. If you are sure you set label, be sure it's a string value"
    ),
    'ERROR'
  );
  return (
    <Draggable
      draggableId={tab.id ?? isString(tab.label) ? (tab.label as string) : ''}
      index={index}
      disableInteractiveElementBlocking
    >
      {(provided, snapshot) => (
        <NvBox
          flex="0 0 auto"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...(snapshot.isDragging
              ? {
                  ...provided.draggableProps.style,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  left: (provided.draggableProps.style as any)?.left - 484,
                  top: 16,
                }
              : provided.draggableProps.style),
          }}
        >
          {TabItemWrapper ? (
            React.cloneElement(TabItemWrapper, {
              children: (
                <Tab label={tab.label} icon={tab.icon} iconPosition={tab.iconPosition} disableRipple {...props} />
              ),
            })
          ) : (
            <Tab label={tab.label} icon={tab.icon} iconPosition={tab.iconPosition} disableRipple {...props} />
          )}
        </NvBox>
      )}
    </Draggable>
  );
};

export const MemoizedDraggableTabItem = React.memo(DraggableTabItem);
