import { NvCustomExecuteButtonIcon } from '@novaera/core';
import { PropertyPanelSimpleSection } from '../../../../../../../../../../../components';
import { RowItemCard } from '../../../../../common/row-item-card';
import { SimpleLabelLayout } from '../../../../../common/row-item-card/row-item-layouts';
import { JobNodeTargetTriggerProps } from './types';

export const JobNodeTargetTrigger: React.FC<JobNodeTargetTriggerProps> = ({ onClick, isSelected }) => (
  <PropertyPanelSimpleSection>
    <RowItemCard
      key={`job-target-parameter-mapper-row-item`}
      isSelected={isSelected}
      rowItemLeftContentProps={{ draggable: false, icon: <NvCustomExecuteButtonIcon /> }}
      rowItemContent={<SimpleLabelLayout simpleLabel="Job target" />}
      onClick={onClick}
    />
  </PropertyPanelSimpleSection>
);
