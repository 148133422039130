import {
  NavigationContextAction,
  ActionType,
  State,
  SetOpenedPopoverIdPayload,
  RemoveOpenedPopoverPayload,
} from '../types';

export const appStateReducer = (
  state: State,
  action: ActionType<SetOpenedPopoverIdPayload | RemoveOpenedPopoverPayload>
) => {
  switch (action.type) {
    case NavigationContextAction.SetOpenedPopoverId: {
      return { ...state, openedPopoverId: (action.payload as SetOpenedPopoverIdPayload).popoverId };
    }
    case NavigationContextAction.RemoveOpenedPopover: {
      return { ...state, openedPopoverId: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
