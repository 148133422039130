import { NvBox, NvCssBaseline, NvFlex, NvImage, NvLink, NvTypography, NvVerticalActionerLogo } from '@novaera/core';
import { Outlet } from '@novaera/route';
import { LayoutContentInner } from '../layout-top-menu/styled';

import { useWorkspaceMenuController } from '../navigation-menu/controllers/use-workspace-menu-controller';
import { NavigationContextProvider } from '../navigation-menu/nav-context';
import { ProfileMenuItem } from '../navigation-menu/profile-menu-item';

import { useTheme } from '@novaera/theme-provider';
import { WorkspaceInitial } from '../workspace-item/initial';
import { Bottom, Header, LayoutContent, ProfileMenuItemWrapper } from './styled';

export const FullPageLayout = () => {
  const { selectedWorkspace } = useWorkspaceMenuController();
  const theme = useTheme();
  return (
    <>
      <NvCssBaseline />
      <NvFlex height="100vh" width="100%" bgcolor={theme.palette.nv_neutral[0]}>
        <NavigationContextProvider>
          <Header>
            <NvFlex
              flexDirection={'row'}
              gap={'16px'}
              alignItems={'center'}
              flex={'1 1 auto'}
              minWidth={'0'}
              zIndex={'1'}
            >
              <NvLink href={'/'} linkVariant="inherit">
                {selectedWorkspace && (
                  <NvFlex flexDirection={'row'} alignItems={'center'} gap={'8px'}>
                    <NvImage
                      FallBack={
                        <WorkspaceInitial
                          size="medium"
                          subdomain={selectedWorkspace.subdomain}
                          displayName={selectedWorkspace.displayName}
                        />
                      }
                      imageShape="square"
                      style={{
                        width: '28px',
                        height: '28px',
                      }}
                      src={selectedWorkspace.logoUrl}
                    />
                    <NvTypography variant="h2">{selectedWorkspace.displayName}</NvTypography>
                  </NvFlex>
                )}
              </NvLink>
            </NvFlex>
            <NvFlex flex={'0 0 auto'}>
              <ProfileMenuItem
                profileImageSize="medium"
                showName={false}
                MenuItemWrapperComponent={ProfileMenuItemWrapper}
                hasIndicator={false}
              />
            </NvFlex>
          </Header>
        </NavigationContextProvider>
        <LayoutContent>
          <LayoutContentInner>
            <Outlet />
          </LayoutContentInner>
        </LayoutContent>

        <Bottom>
          <NvBox zIndex={'1'}>
            <NvVerticalActionerLogo />
          </NvBox>
        </Bottom>
      </NvFlex>
    </>
  );
};
