import { Connection } from '@novaera/actioner-service';

export type TestConfigurationState = {
  selectedConnection?: Connection;
};

export enum ConnectionAction {
  SetSelectedConnection = 'set-selected-connection',
}

export type SetSelectedConnectionPayload = {
  connection?: Connection;
};

export interface ActionType<T> {
  type: ConnectionAction;
  payload?: T;
}
