import { useGetIntegrationUpdates } from '@novaera/actioner-service';
import { useNvDialogModalState } from '@novaera/core';
import { useState } from 'react';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';

export const useIntegrationUpdate = () => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();

  const [isPanelOpen, setPanelOpen] = useState(true);
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const { data: integrationUpdates, isLoading } = useGetIntegrationUpdates({
    appId: userAppId,
    workflowId,
  });

  const handleHidePanelClicked = () => {
    setPanelOpen(false);
  };

  return {
    availableUpdates: integrationUpdates?.availableUpdates ?? [],
    isLoading,
    isModalOpened: isOpened,
    showPanel:
      isPanelOpen &&
      integrationUpdates &&
      !integrationUpdates.isAllIntegrationsUpdated &&
      integrationUpdates?.availableUpdates &&
      integrationUpdates?.availableUpdates?.length > 0,
    onHidePanelClicked: handleHidePanelClicked,
    onModalCloseClicked,
    onModalOpenClicked,
  };
};
