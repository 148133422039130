import { NvAxios, useQuery } from '@novaera/core';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { Group, UseGetGroupParams } from '../types';

const getGroup = async (params: UseGetGroupParams) => {
  const result = await NvAxios.get(`${GROUPS_ROOT_PATH}/${params.id}`);
  return result?.data;
};

export const useGetGroup = (params: UseGetGroupParams) => {
  return useQuery<Group>(QUERY_KEYS_GROUPS.detail(params.id), () => getGroup(params));
};
