import { FC } from 'react';
import { UserAppEdgeProps } from '../../types';
import { BaseEdge } from '../base-edge';
import { BaseEdgeProps } from '../base-edge/types';
export const BaseEdgeWithPlus: FC<
  React.PropsWithChildren<UserAppEdgeProps & Pick<BaseEdgeProps, 'buttonBehavior'>>
> = ({ sourceY, targetX, targetY, buttonBehavior, ...rest }) => {
  return (
    <BaseEdge
      sourceY={sourceY - 4}
      targetY={targetY + 4}
      targetX={targetX}
      centerX={targetX}
      centerY={sourceY + 12}
      borderRadius={10}
      topDownOffset={12}
      leftRightOffset={0}
      buttonBehavior={buttonBehavior}
      {...rest}
    />
  );
};
