import classNames from 'classnames';
import { cloneElement, forwardRef, useState } from 'react';
import { NvBox, NvTypography } from '../../../index';
import { NvChevronRightIcon } from '../../icons';
import { NestedDropdown } from '../styled';
import { NestedDropdownItem as NestedDropdownItemType } from '../types';
import { NestedDropdownInnerWrapper, NestedDropdownItemStyled } from './styled';
import { NestedDropdownItemProps } from './types';

const NestedDropdownItem = forwardRef<HTMLLIElement, NestedDropdownItemProps<unknown>>(
  ({ id: parentId, name: parentName, items: parentChildrenItems = [], onClick, leftIcon: parentIcon, value }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isSubMenuOpen = Boolean(anchorEl);

    const hasChildrenItems = parentChildrenItems?.length || false;
    const isLeafNode = !hasChildrenItems;

    const handleMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      if (isLeafNode) {
        onClick?.({ id: parentId, name: parentName, leftIcon: parentIcon, value });
      }
    };

    return (
      <NestedDropdownItemStyled
        ref={ref}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
      >
        <NestedDropdownInnerWrapper className={classNames({ 'sub-menu-open': isSubMenuOpen })}>
          {parentIcon && cloneElement(parentIcon, { ...parentIcon.props, fontSize: 'inherit' })}
          <NvTypography flex="1 1 auto" variant="body1">
            {parentName}
          </NvTypography>
          {hasChildrenItems && <NvChevronRightIcon fontSize="small" />}
          {hasChildrenItems && (
            <NestedDropdown
              style={{ pointerEvents: 'none' }}
              anchorEl={anchorEl}
              open={isSubMenuOpen}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                sx: { marginLeft: '6px', marginTop: '-6px' },
              }}
            >
              <NvBox sx={{ pointerEvents: 'auto' }}>
                {parentChildrenItems.map((item: NestedDropdownItemType) => {
                  return <NestedDropdownItem key={item.id} {...item} onClick={onClick} />;
                })}
              </NvBox>
            </NestedDropdown>
          )}
        </NestedDropdownInnerWrapper>
      </NestedDropdownItemStyled>
    );
  }
);

export default NestedDropdownItem;
