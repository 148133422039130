import { FieldSchema } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { BaseProperties } from './base-properties';
import { DefaultValueProperty } from './default-value-property';
import { DisplayValueProperty, DisplayValuePropertyProps } from './display-value-property';
import { LabelProperty, LabelPropertyProps } from './label-property';
import {
  BasePropertiesProps,
  DataModelType,
  DefaultValuePropertyProps,
  ValidationPropertiesProps,
  VectorDBConfigurationProps,
} from './types';
import { ValidationProperties } from './validation-properties';
import { VectorDBConfiguration } from './vector-db-configuration';

const SchemaTypeMap: Record<
  SchemaType,
  {
    Base: React.FC<React.PropsWithChildren<BasePropertiesProps>>;
    DefaultValue?: React.FC<React.PropsWithChildren<DefaultValuePropertyProps>>;
    ValidationValues?: React.FC<React.PropsWithChildren<ValidationPropertiesProps>>;
    VectorDBConfiguration?: (props: VectorDBConfigurationProps) => JSX.Element | null;
    LabelProperty?: (props: LabelPropertyProps) => JSX.Element | null;
    DisplayValueProperty?: (props: DisplayValuePropertyProps) => JSX.Element | null;
  }
> = {
  [SchemaType.boolean]: {
    Base: BaseProperties,
    DefaultValue: DefaultValueProperty,
  },
  [SchemaType.dateTime]: { Base: BaseProperties, DefaultValue: DefaultValueProperty },
  [SchemaType.email]: {
    Base: BaseProperties,
    DefaultValue: DefaultValueProperty,
  },
  [SchemaType.float]: {
    Base: BaseProperties,
    DefaultValue: DefaultValueProperty,
  },
  [SchemaType.integer]: {
    Base: BaseProperties,
    DefaultValue: DefaultValueProperty,
  },
  [SchemaType.phone]: { Base: BaseProperties, DefaultValue: DefaultValueProperty },
  [SchemaType.url]: { Base: BaseProperties, DefaultValue: DefaultValueProperty },
  [SchemaType.uuid]: { Base: BaseProperties },
  [SchemaType.string]: {
    Base: BaseProperties,
    DefaultValue: DefaultValueProperty,
    ValidationValues: ValidationProperties,
  },
  [SchemaType.recordReference]: { Base: BaseProperties },
  [SchemaType.map]: { Base: BaseProperties, ValidationValues: ValidationProperties },
  [SchemaType.array]: {
    Base: BaseProperties,
    ValidationValues: ValidationProperties,
  },
  [SchemaType.object]: { Base: BaseProperties, ValidationValues: ValidationProperties },
};

export const FieldPropertiesMap = ({ schema, modalType }: { schema: FieldSchema; modalType: DataModelType }) => {
  const schemaTypeMap = SchemaTypeMap[schema.type];

  if (schema.type === SchemaType.array && schema.valueSchema.type === SchemaType.string) {
    schemaTypeMap.VectorDBConfiguration = VectorDBConfiguration;
  }

  if (modalType === 'catalog') {
    schemaTypeMap.LabelProperty = LabelProperty;
    schemaTypeMap.DisplayValueProperty = DisplayValueProperty;
  }

  return schemaTypeMap;
};
