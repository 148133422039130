import classNames from 'classnames';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/addon/scroll/simplescrollbars.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql';

import React from 'react';
import { NvCodeMirrorWrapper } from '../styled';
import { CodeInputProps } from '../types';
import { shouldRenderCodeInput } from '../utils';
import { SqlCodeMirror, SqlCodeMirrorStartAdornmentBox } from './styled';
import { useCodeInputForSql } from './use-code-input-for-sql';

const UnMemoizedCodeInput: React.FC<React.PropsWithChildren<CodeInputProps>> = ({
  context,
  value,
  onChange,
  onBlur,
  onFocus,
  onEnter,
  startAdornment,
  startAdornmentOffset,
  isDisabled,
  placeholder,
  className,
}) => {
  const {
    handleOnFocus,
    error,
    handleEditorChange,
    handleEditorUpdate,
    handleEditorKeyUp,
    handleEditorKeyDown,
    handleEditorDidMount,
    handleOnBlur,
  } = useCodeInputForSql({
    context,
    onBlur,
    onChange,
    onFocus,
    onEnter,
  });

  return (
    <NvCodeMirrorWrapper className={className}>
      {startAdornment && <SqlCodeMirrorStartAdornmentBox>{startAdornment}</SqlCodeMirrorStartAdornmentBox>}
      <SqlCodeMirror
        className={classNames({
          [`${className}`]: true,
          NvCodeInput: true,
          error: error !== undefined,
        })}
        startAdornmentOffset={startAdornmentOffset}
        value={value ?? ''}
        onChange={handleEditorChange}
        onUpdate={handleEditorUpdate}
        onKeyUp={handleEditorKeyUp}
        onKeyDown={handleEditorKeyDown}
        editorDidMount={handleEditorDidMount}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        options={{
          scrollbarStyle: 'overlay',
          readOnly: isDisabled,
          lineWrapping: true,
          mode: 'sql',
          lint: false,
          placeholder,
        }}
      />
    </NvCodeMirrorWrapper>
  );
};

export const CodeInputForSql = React.memo(UnMemoizedCodeInput, (prevProps, nextProps) =>
  shouldRenderCodeInput(prevProps, nextProps)
);
