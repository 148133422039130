import { NvTypography } from '@novaera/core';
import { FC } from 'react';
import { EMPTY_STATE } from './constants';
import { EmptyStateBackground, EmptyStateContentBox, EmptyStateWrapper } from './styled';
import { EmptyStateProps } from './types';

export const EmptyState: FC<EmptyStateProps> = ({ variant, CustomButton, hasEmptyStateBackground = true }) => (
  <EmptyStateWrapper>
    {hasEmptyStateBackground && <EmptyStateBackground />}
    <EmptyStateContentBox>
      {EMPTY_STATE[variant].logo}
      <NvTypography variant="body2">{EMPTY_STATE[variant].content}</NvTypography>
      {CustomButton}
    </EmptyStateContentBox>
  </EmptyStateWrapper>
);
