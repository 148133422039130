import { NvAxios, useMutation, useQuery } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_EXECUTION_RESULT } from '../keys';
import { UserWorkflowExecutionResultResponse, UseWorkflowExecutionResultProps } from './types';

const getExecutionResult = async ({ userAppId, workflowId, executionId }: UseWorkflowExecutionResultProps) => {
  const result = await NvAxios.get<UserWorkflowExecutionResultResponse>(
    `${USER_APP_ROOT_PATH}/${userAppId}/workflows/${workflowId}/executions/${executionId}`
  );
  return result?.data;
};

export const useGetWorkflowExecutionResultForPolling = () => {
  const cache = useQueryClient();

  return useMutation(getExecutionResult, {
    onSuccess: (response, { executionId, workflowId }) => {
      cache.setQueryData<Partial<UserWorkflowExecutionResultResponse>>(
        QUERY_KEYS_EXECUTION_RESULT.detail({ workflowId, executionId }),
        () => ({
          ...response,
        })
      );
    },
  });
};

export const useGetWorkflowExecutionResult = ({
  userAppId,
  workflowId,
  executionId,
}: UseWorkflowExecutionResultProps) => {
  return useQuery<UserWorkflowExecutionResultResponse>(
    QUERY_KEYS_EXECUTION_RESULT.detail({ workflowId, executionId }),
    () => getExecutionResult({ userAppId, workflowId, executionId }),
    { enabled: !!userAppId && !!workflowId && !!executionId }
  );
};
