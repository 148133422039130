import { useCreateConnectionSchemaService, useUpdateIntegrationPartial } from '@novaera/actioner-service';
import { isAxiosError, useToast } from '@novaera/core';
import { CUSTOM_CONNECTION_OPTIONS } from '../../../../../../constants';

import { useGetIntegrationQueryParams } from '../../../../../../controllers/use-get-integration-query-params';
import { IntegrationConnectionFormValues } from '../../types';

export const useCreateNewConnection = ({ onClose }: { onClose: () => void }) => {
  const { mutateAsync: createConnection, isLoading } = useCreateConnectionSchemaService();
  const { integrationId } = useGetIntegrationQueryParams();
  const { mutateAsync: updateIntegrationPartial } = useUpdateIntegrationPartial();
  const { addToast } = useToast();

  const handleFormSubmit = async (values: IntegrationConnectionFormValues) => {
    const { authentication, ...otherValues } = values;

    try {
      const isGlobalSchema = CUSTOM_CONNECTION_OPTIONS.find((option) => option.id === authentication.id) === undefined;

      let connectionSchemaId = isGlobalSchema ? authentication.id : undefined;

      if (!isGlobalSchema) {
        const connectionSchema = await createConnection({
          authentication: {
            ...authentication,
          },
          ...otherValues,
          integrationId,
        });
        connectionSchemaId = connectionSchema.id;
      }

      await updateIntegrationPartial({
        id: integrationId,
        connectionSchemaId,
      });
      onClose();
    } catch (error) {
      addToast(isAxiosError(error) ? error.response?.data?.message || 'Something went wrong' : 'Something went wrong', {
        variant: 'error',
      });
    }
  };

  return {
    onFormSubmit: handleFormSubmit,
    isLoading,
  };
};
