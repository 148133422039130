import { UIComponentType, UIComponentTypes } from '@novaera/actioner-service';

export const componentsNeedsOptions = (uiComponent: UIComponentTypes) => {
  return (
    uiComponent &&
    (uiComponent.type === UIComponentType.SINGLE_SELECT ||
      uiComponent.type === UIComponentType.MULTI_SELECT ||
      uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP ||
      uiComponent.type === UIComponentType.CHECK_BOX_GROUP)
  );
};
