import { NvBox, NvFlex, useFormState } from '@novaera/core';
import { AppDirectoryItemPreviewBackground } from '../../../app-directory-detail/styled';
import { AppDirectoryCard } from '../../app-directory-card';
import { PublishAppFormData } from '../types';

export const PreviewStep = () => {
  const {
    values: { appDetails },
  } = useFormState<PublishAppFormData>();

  return (
    <NvFlex alignItems="center">
      <NvBox width="667px">
        <AppDirectoryItemPreviewBackground padding="32px 160px">
          <AppDirectoryCard
            isPreviewCard
            title={appDetails.appName}
            description={appDetails.shortDescription}
            tags={[{ label: 'NEW', color: 'pink' }]}
            star="0"
            contributors={appDetails.contributors}
            logoUrl={appDetails.logoUrl}
            isManagedApp={appDetails.managed}
            free={false}
          />
        </AppDirectoryItemPreviewBackground>
      </NvBox>
    </NvFlex>
  );
};
