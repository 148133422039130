import {
  ContextEnrichedTransformation,
  OutputTransformation,
  TemplateTransformation,
  TransformationData,
} from './types';

import { useCallback } from 'react';

import { assert } from '..';
import { transformOutput } from '../../output-transformers';
import { FinalContext, OutputComponent } from '../../types';
import { ContextEnrichedExpressionTransformer, ExpressionTransformer } from '../types';

export const useSandboxedTransform = () => {
  const communicate = useCallback(async <T>(input: TransformationData) => {
    return await new Promise<T>((resolve, reject) => {
      const channel = new MessageChannel();
      const timeoutHandle = setTimeout(() => {
        reject('Novaera Post operation timed out');
      }, 5000);

      channel.port1.onmessage = ({ data }) => {
        clearTimeout(timeoutHandle);
        resolve(data);
      };

      const frame = document.querySelector<HTMLIFrameElement>('#sandboxed');
      assert(!!frame, new Error('Iframe cannot be found'), 'ERROR');
      assert(!!frame.contentWindow, new Error('Iframe content window cannot be found'), 'ERROR');
      frame.contentWindow.postMessage(input, '*', [channel.port2]);
    });
  }, []);

  const sandboxedContextEnrichedExpressionTransformer = useCallback(
    async (key: string, enrichedContext?: Record<string, unknown>) => {
      const input: ContextEnrichedTransformation = { type: 'ContextEnrichedTransformation', key, enrichedContext };
      const result = await communicate<Promise<ReturnType<ContextEnrichedExpressionTransformer>>>(input);
      return result;
    },
    [communicate]
  );

  const sandboxedExpressionTransformer = useCallback(
    async (key?: string, contextArg?: Record<string, unknown>, failable?: boolean) => {
      const input: TemplateTransformation = {
        type: 'TemplateTransformation',
        key,
        context: contextArg || {},
        failable,
      };
      const result = await communicate<Promise<ReturnType<ExpressionTransformer>>>(input);
      return result;
    },
    [communicate]
  );

  const sandboxedTransformOutput = useCallback(
    async ({ config, output }: { config: OutputComponent; output?: FinalContext }) => {
      const input: OutputTransformation = { type: 'OutputTransformation', config, output };
      const result = await communicate<Promise<ReturnType<typeof transformOutput>>>(input);
      return { id: config.id, ...result };
    },
    [communicate]
  );

  return { sandboxedContextEnrichedExpressionTransformer, sandboxedExpressionTransformer, sandboxedTransformOutput };
};
