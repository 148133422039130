import { assert } from '@novaera/utils';
import { backOff, BackoffOptions } from 'exponential-backoff';
import { REAL_TIME_TASKS, SUBSCRIPTION_STATUS } from '../real-time-tasks-object';
import {
  POLLING_JITTER_ENABLED,
  POLLING_MAX_DELAY_BETWEEN_RETRIES,
  POLLING_MAX_NUMBER_OF_ATTEMPTS,
  POLLING_STARTING_DELAY_WITH_SOCKET_ERROR,
  POLLING_STARTING_DELAY_WITHOUT_SOCKET_ERROR,
} from './constants';

export class RealTimeTaskEngine<POLLING_RESULT, SOCKET_RESULT> {
  readonly id: string;
  pollingCallback?: () => Promise<POLLING_RESULT>;
  readonly resultAction: (
    arg: { resultContext: POLLING_RESULT; source: 'polling' } | { resultContext: SOCKET_RESULT; source: 'socket' }
  ) => void;

  constructor({
    id,
    pollingCallback,
    resultAction,
  }: {
    id: string;
    pollingCallback?: () => Promise<POLLING_RESULT>;
    resultAction: (
      arg: { resultContext: POLLING_RESULT; source: 'polling' } | { resultContext: SOCKET_RESULT; source: 'socket' }
    ) => void;
  }) {
    this.id = id;
    this.resultAction = resultAction;
    this.pollingCallback = pollingCallback;
  }

  stopPolling: () => void = () => {
    delete REAL_TIME_TASKS[this.id];
  };

  executePolling: () => void = async () => {
    REAL_TIME_TASKS[this.id] = this;

    try {
      const pollingExecutionOptions: BackoffOptions = {
        delayFirstAttempt: SUBSCRIPTION_STATUS.hasError ? false : true,
        jitter: POLLING_JITTER_ENABLED ? 'full' : 'none',
        maxDelay: POLLING_MAX_DELAY_BETWEEN_RETRIES,
        numOfAttempts: POLLING_MAX_NUMBER_OF_ATTEMPTS,
        startingDelay: SUBSCRIPTION_STATUS.hasError
          ? POLLING_STARTING_DELAY_WITH_SOCKET_ERROR
          : POLLING_STARTING_DELAY_WITHOUT_SOCKET_ERROR,

        retry: (e: unknown, attemptNumber: number) => {
          if (REAL_TIME_TASKS[this.id]) {
            return true;
          } else {
            return false;
          }
        },
      };

      const result = await backOff<POLLING_RESULT | 'skipped'>(() => {
        if (REAL_TIME_TASKS[this.id] && this.pollingCallback) {
          return this.pollingCallback();
        } else {
          return Promise.resolve('skipped');
        }
      }, pollingExecutionOptions);

      if (result) {
        if (result !== 'skipped') {
          if (REAL_TIME_TASKS[this.id]) {
            this.resultAction({ resultContext: result, source: 'polling' });
            delete REAL_TIME_TASKS[this.id];
          }
        }
      } else {
        assert(!result, new Error(`Result is unintentionally undefined for id: ${this.id}`), 'ERROR');
      }
    } catch (error) {
      assert(
        false,
        new Error(`Error occurred for retrieving data for id:${this.id} - ${JSON.stringify(error)}`),
        'ERROR'
      );
    }
  };
}
