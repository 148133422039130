import { NvAxios } from '@novaera/core';
import { useQueries } from '@tanstack/react-query';
import { QUERY_KEYS_INPUT_PARAMETER_OPTIONS } from '../keys';

import { INTEGRATION_ACTION_INPUT_PARAMETER_OPTIONS } from '../constants';
import {
  GetOptionsParams,
  GetOptionsParamsForMultipleInputParametersIntegrationAction,
  GetOptionsResponse,
} from '../types';

const getOptions = async ({ integrationId, actionId, payload, ...rest }: GetOptionsParams) => {
  if (rest.type === 'in-workflow') {
    const appId = rest.appId;
    const id = rest.workflowId;

    const result = await NvAxios.post<GetOptionsResponse>(`/v2/apps/${appId}/workflows/${id}/action-options`, {
      ...payload,
      integrationId,
      actionId,
      actionInputParameterId: payload.inputParameterId,
    });
    return result?.data;
  } else {
    const result = await NvAxios.post<GetOptionsResponse>(
      INTEGRATION_ACTION_INPUT_PARAMETER_OPTIONS(integrationId, actionId),
      { ...payload, connectionId: rest.connectionId }
    );
    return result?.data;
  }
};

export const useGetOptions = (params: GetOptionsParamsForMultipleInputParametersIntegrationAction) => {
  const queries =
    params.requestParams.payload.inputParameterIds?.map((inputParameterId) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { inputParameterIds, inputParameters, searchAsYouTypeValues, ...rest } = params.requestParams.payload;
      const allInputParameterIdsWithOrder = params.allInputParametersWithOrder.map((i) => i.id);
      const inputParameterIndex = allInputParameterIdsWithOrder?.indexOf(inputParameterId);
      const inputParameterIdsToFilter = allInputParameterIdsWithOrder.slice(inputParameterIndex);
      const newInputParameters = inputParameters?.filter((i) => !inputParameterIdsToFilter.includes(i.parameterId));
      const query = searchAsYouTypeValues?.[inputParameterId];

      let newParam: GetOptionsParams;

      if (params.type === 'in-workflow') {
        const parentId = params.getDynamicInputParameterParent(inputParameterId);
        const dependencies = params.dependenciesOfParameters?.[parentId ?? inputParameterId];
        newParam = {
          type: params.type,
          appId: params.appId,
          workflowId: params.workflowId,
          ...params.requestParams,
          payload: {
            connectionId: params.connectionId,
            inputParameterId,
            inputParameters: newInputParameters?.filter((i) => {
              const inputParameterName = params.allInputParametersWithOrder.find(
                (input) => input.id === i.parameterId
              )?.name;

              return (
                inputParameterName === 'appId' ||
                inputParameterName === 'modelId' ||
                dependencies?.includes(i.parameterId)
              );
            }),
            ...(query ? { query } : {}),
            ...rest,
          },
        };
      } else {
        newParam = {
          type: params.type,
          connectionId: params.connectionId,
          ...params.requestParams,
          payload: { inputParameterId, inputParameters: newInputParameters, ...(query ? { query } : {}), ...rest },
        };
      }

      return {
        queryKey: QUERY_KEYS_INPUT_PARAMETER_OPTIONS.detail(newParam),
        queryFn: () => getOptions(newParam),
        ...params.options,
        enabled: params.enabledInputParameters.includes(inputParameterId),
      };
    }) ?? [];

  return useQueries({
    queries: queries,
  });
};
