import {
  ActionState,
  USER_PERMISSION,
  useGetIntegration,
  useGetIntegrationAction,
  useTestIntegrationAction,
} from '@novaera/actioner-service';
import { NvFlex, NvForm, NvSplitPane, SplitPanelItem } from '@novaera/core';
import { useParams } from '@novaera/route';
import _, { noop } from 'lodash';
import { useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useDynamicInputContext, useTestActionContext } from '../../../components';
import { useTestConfigurationProvider } from '../../../components/test-configuration-bar/providers/test-configuration';
import { useFormIdentifierContext } from '../../../providers/form-identifier-provider';
import { useUserPermissions } from '../../../user-permission-boundary/use-user-permission';
import { InputsProvider } from '../../providers/input-provider';
import { InputFormValues, useInputValuesContext } from '../../providers/input-values';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../../providers/input-values/utils';
import { InputPanel } from './input-panel';
import { IntegrationActionInputParameterProperties } from './integration-action-input-parameter-properties';
import { InputPropertiesContainer, InputViewContainer } from './styled';
import { TestAction } from './test-action';

export const InputTab = ({ onTestExecuted }: { onTestExecuted: () => void }) => {
  const { setFormValues, inputValues } = useInputValuesContext();
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const { mutate: testAction } = useTestIntegrationAction();
  const { integrationId, actionId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
  });
  const { setTestActionParams } = useTestActionContext();
  const { formId } = useFormIdentifierContext();
  const { dynamicInputParameters } = useDynamicInputContext();

  const allInputParameters = useMemo(
    () => [...(integrationAction?.inputParameters ?? []), ...dynamicInputParameters],
    [dynamicInputParameters, integrationAction?.inputParameters]
  );

  const {
    state: { selectedConnection },
  } = useTestConfigurationProvider();

  const handleOnChange = useDebouncedCallback((values: InputFormValues) => {
    setFormValues((prev) => {
      const alreadyInFormKeys = Object.keys(prev ?? {});
      const changedFormKeys = Object.keys(values ?? {});
      const removedKeysFromForm = _.difference(alreadyInFormKeys, changedFormKeys);

      const previousFormItems: InputFormValues = {};
      if (prev) {
        alreadyInFormKeys.forEach((key) => {
          if (!removedKeysFromForm.includes(key)) {
            previousFormItems[key] = { ...prev[key] };
          }
        });
      }

      return { ...previousFormItems, ...values };
    });
  }, 500);

  const handleOnSubmit = (values: InputFormValues) => {
    return new Promise<void>((resolve) => {
      testAction(
        {
          integrationId,
          actionId,
          formId,
          inputParameters: formatAndFilterInputFormValuesAsParameterMappings(
            values,
            allInputParameters.map((i) => i.id)
          ),
          draft: integrationAction?.state === ActionState.DRAFT,
          ...(selectedConnection ? { connectionId: selectedConnection.id } : {}),
          versionNumber: integrationAction?.version.number ?? 1,
        },

        {
          onSuccess: ({ executionId }) => {
            setTestActionParams({ integrationId, actionId, executionId });
          },
          onSettled: () => {
            resolve();
            onTestExecuted();
          },
        }
      );
    });
  };

  return (
    <NvFlex direction="column" height="100%">
      <InputsProvider initialInputParameterId={allInputParameters?.[0]?.id}>
        <NvSplitPane direction="horizontal">
          <SplitPanelItem>
            <NvForm<InputFormValues>
              onSubmit={handleOnSubmit}
              onChange={
                hasIntegrationUpdateRight
                  ? ({ values }) => {
                      handleOnChange(values);
                    }
                  : noop
              }
              initialValues={inputValues}
              keepDirtyOnReinitialize
            >
              <InputViewContainer>
                <InputPanel />
                <TestAction />
              </InputViewContainer>
            </NvForm>
          </SplitPanelItem>
          <SplitPanelItem minSize="35%" initialSize="35%">
            <InputPropertiesContainer>
              {integrationAction && integration && (
                <IntegrationActionInputParameterProperties
                  integrationAction={integrationAction}
                  integrationVersion={integration.latestVersion.number}
                />
              )}
            </InputPropertiesContainer>
          </SplitPanelItem>
        </NvSplitPane>
      </InputsProvider>
    </NvFlex>
  );
};
