import { NvAddBoxIcon, NvAddIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { bindTrigger } from 'material-ui-popup-state/hooks';
import { FC, MouseEvent, useCallback, useMemo, useRef } from 'react';
import { ButtonContext, useAddNewNodeContext } from '../../add-new-node-popper/provider';
import { AddNewButtonBox } from './styled';

export type AddNewButtonProps = ButtonContext & { disabled?: boolean };

export const AddNewButton: FC<React.PropsWithChildren<AddNewButtonProps>> = (params) => {
  const theme = useTheme();
  const ref = useRef(null);
  const { popupState, setButtonContext } = useAddNewNodeContext();
  const { onClick, ...rest } = bindTrigger(popupState);
  const handleOnClick: React.ComponentProps<typeof NvAddBoxIcon>['onClick'] = useCallback(
    (event: MouseEvent) => {
      setButtonContext(params);
      onClick(event);
    },
    [onClick, params, setButtonContext]
  );

  const isActive = useMemo(
    () => popupState.isOpen && popupState.anchorEl === ref.current,
    [popupState.anchorEl, popupState.isOpen]
  );

  return (
    <AddNewButtonBox isActive={isActive}>
      <NvAddIcon
        ref={ref}
        sx={{ width: '16px', height: '16px' }}
        htmlColor={isActive ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[300]}
        {...rest}
        {...(!params.disabled
          ? {
              onClick: handleOnClick,
            }
          : {})}
      />
    </AddNewButtonBox>
  );
};
