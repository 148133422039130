import { useTheme } from '@novaera/theme-provider';
import { FC, PropsWithChildren, useMemo } from 'react';
import ReactFlow, { Background, OnSelectionChangeFunc, SelectionMode } from 'reactflow';
import 'reactflow/dist/style.css';
import { transformToReactFlowEdges, transformToReactFlowNodes } from '../react-flow-transformer';
import { NovaeraEdge, NovaeraNodeWithPosition, NvEdgeTypes, NvNodeTypes } from '../types';
import { CustomControls } from './custom-controls';

export interface NvCanvasProps {
  defaultNodes: NovaeraNodeWithPosition[];
  defaultEdges: NovaeraEdge[];
  nodeTypes: NvNodeTypes;
  edgeTypes: NvEdgeTypes;
  onSelectionchange?: OnSelectionChangeFunc;
}

const panOnDrag = [1, 2];
const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

export const NvCanvas: FC<React.PropsWithChildren<PropsWithChildren<NvCanvasProps>>> = ({
  children,
  defaultNodes,
  defaultEdges,
  nodeTypes,
  edgeTypes,
  onSelectionchange,
  ...props
}) => {
  const theme = useTheme();
  const isFitView = useMemo(() => {
    return defaultNodes.length > 5;
  }, [defaultNodes]);
  return (
    <ReactFlow
      defaultViewport={defaultViewport}
      defaultNodes={transformToReactFlowNodes({ nodes: defaultNodes })}
      defaultEdges={transformToReactFlowEdges(defaultEdges)}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      panOnScroll
      selectionOnDrag={false}
      panOnDrag={panOnDrag}
      selectionMode={SelectionMode.Partial}
      proOptions={{ hideAttribution: true }}
      maxZoom={100}
      nodesDraggable={false}
      onSelectionChange={onSelectionchange}
      deleteKeyCode={null}
      multiSelectionKeyCode={null}
      selectionKeyCode={null}
      fitView={isFitView}
      {...props}
    >
      {children}
      <CustomControls />
      <Background gap={12} color={theme.palette.nv_neutral_alpha[40]} />
    </ReactFlow>
  );
};
