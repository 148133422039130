import { NvSkeleton, NvTypography, NvWorkflowIcon } from '@novaera/core';

import { FC } from 'react';
import { DynamicWorkflowReferenceRowDetailWrapper, StaticWorkflowReferenceRowDetailWrapper } from './styled';
import { WorkflowReferenceRowDetailProps } from './types';

export const WorkflowReferenceRowDetail: FC<WorkflowReferenceRowDetailProps> = (props) => {
  return props.type === 'dynamic' ? (
    <DynamicWorkflowReferenceRowDetailWrapper>
      <NvWorkflowIcon sx={{ width: '16px', height: '16px' }} />
      <NvTypography variant="h6" textColor="subtle">
        Determined at runtime
      </NvTypography>
    </DynamicWorkflowReferenceRowDetailWrapper>
  ) : (
    <StaticWorkflowReferenceRowDetailWrapper>
      <NvWorkflowIcon sx={{ width: '16px', height: '16px' }} />
      {props.workflowDetail?.isInitialLoading ? (
        <NvSkeleton variant="rectangular" height="16px" width="200px" />
      ) : (
        <NvTypography variant="h6" textColor="secondary">
          {props.workflowDetail?.data?.saved.name ? props.workflowDetail.data.saved.name : 'Select a workflow'}
        </NvTypography>
      )}
    </StaticWorkflowReferenceRowDetailWrapper>
  );
};
