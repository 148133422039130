import { NvBox, NvFlex, NvReceiptLongIcon, NvSkeleton, NvTypography } from '@novaera/core';
import { PropertyPanelSection } from '../../../../../../../../../components';
import { OutputItem } from '../../../../../../../../../components/output-item';
import { useTriggerPayloadController } from './controllers/use-trigger-payload';

export const TriggerPayloadPanel = () => {
  const { payload, isLoading } = useTriggerPayloadController();
  return (
    <PropertyPanelSection title="Trigger payload" icon={<NvReceiptLongIcon />}>
      <NvFlex gap="8px">
        <NvBox>
          <NvTypography variant="body2" textColor="secondary" display="inline">
            You can use the trigger payload below while configuring workflow conditions and actions.
          </NvTypography>
        </NvBox>
        {!isLoading && <OutputItem content={payload} />}
        {isLoading && <NvSkeleton width={'100%'} height="50px" variant="rectangular" />}
      </NvFlex>
    </PropertyPanelSection>
  );
};
