import { FieldType, isCatalogEntityParameters } from '@novaera/actioner-service';
import { NvFlex, SectionMessage } from '@novaera/core';
import { useField } from 'react-final-form';

import { PropertyPanelSimpleSection } from '../../property-panel';
import { DataModelField } from './field';
import { DataModelRightContentHeader } from './header';
import { FieldBodyWrapper } from './styled';
import { DataModelLayoutRightContentProps } from './types';

export const DataModelLayoutRightContent = ({
  selectedFieldIndex,
  fieldMenus,
  fieldsFieldName,
  modalType,
}: DataModelLayoutRightContentProps) => {
  const {
    input: { value, name: fieldName },
  } = useField<FieldType>(`${fieldsFieldName}[${selectedFieldIndex}]`);

  const {
    input: { value: replicateToVectorDB },
  } = useField<boolean>(`replicateToVectorDB`);

  return (
    value && (
      <NvFlex>
        <DataModelRightContentHeader field={value} fieldMenus={fieldMenus} />
        {isCatalogEntityParameters(value) && value.managed && (
          <PropertyPanelSimpleSection>
            <SectionMessage
              message={'This property is managed by Actioner, edit or delete operations are not allowed.'}
              variant={'info'}
            ></SectionMessage>
          </PropertyPanelSimpleSection>
        )}
        <FieldBodyWrapper>
          {modalType === 'table' && (
            <DataModelField
              field={value}
              fieldName={fieldName}
              selectedFieldIndex={selectedFieldIndex}
              replicateToVectorDB={replicateToVectorDB}
              modalType={modalType}
            />
          )}
          {modalType === 'catalog' && (
            <DataModelField
              field={value}
              fieldName={fieldName}
              selectedFieldIndex={selectedFieldIndex}
              modalType={modalType}
            />
          )}
        </FieldBodyWrapper>
      </NvFlex>
    )
  );
};
