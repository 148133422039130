import { NvDivider } from '@novaera/core';
import { useState } from 'react';
import { AppAssetsWrapper } from '../styled';
import { AppVideosAndImagesProps } from './types';
import { VideosAndImages } from './videos-and-images';

export const AppVideosAndImages: React.FC<React.PropsWithChildren<AppVideosAndImagesProps>> = ({
  assets,
  appHasNoDescription,
}) => {
  const [mediaEditModeState, setMediaEditModeState] = useState<boolean>(false);
  const handleEditModeChange = setMediaEditModeState;

  const hideAssets = appHasNoDescription;
  return hideAssets ? null : (
    <AppAssetsWrapper>
      <VideosAndImages
        mediaFiles={assets?.mediaFiles ?? []}
        onEditModeChange={handleEditModeChange}
        isEditMode={mediaEditModeState}
        enableAutomaticAddModal
      />
      {!mediaEditModeState && <NvDivider orientation="horizontal" sx={{ width: '100%' }} />}
    </AppAssetsWrapper>
  );
};
