import {
  NvField,
  NvFlex,
  NvHelpIcon,
  NvRemoveRedEyeIcon,
  NvSwitch,
  NvTooltip,
  NvTypography,
  useForm,
  useFormState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import OnChange from '../../../../../action-designer/components/on-change';
import { CatalogFormType } from '../../../../../catalog/types';
import { PropertyPanelSimpleSection } from '../../../../property-panel';

export type DisplayValuePropertyProps = {
  fieldName: string;
  fieldId: string;
  disabled?: boolean;
};

export const DisplayValueProperty = ({ fieldName, fieldId, disabled }: DisplayValuePropertyProps) => {
  const { palette } = useTheme();

  const { change } = useForm();
  const { values } = useFormState<CatalogFormType>();

  // if the current display property is a managed field, disable the checkbox because it cannot be changed by other fields
  const isCurrentDisplayPropertyAManagedField = values.selectedEntityType?.parameters?.find(
    (item) => item.id === values.selectedEntityType?.displayParameterId
  )?.managed;

  const isFieldDisabled = disabled || isCurrentDisplayPropertyAManagedField;

  return (
    <PropertyPanelSimpleSection>
      <OnChange name={fieldName}>
        {() => {
          change(
            'selectedEntityType.displayParameterName',
            values.selectedEntityType?.parameters?.find((item) => item.id === fieldId)?.name
          );
        }}
      </OnChange>
      <NvField
        isDisabled={isFieldDisabled}
        name={fieldName}
        direction="label-on-side"
        labelVariant="h5"
        type="checkbox"
        format={(value) => {
          return value === fieldId;
        }}
        parse={(value) => {
          return value ? fieldId : '';
        }}
        component={({ onChange, value, checked }) => {
          return (
            <NvFlex flexDirection={'row'} alignItems={'center'} gap={'12px'}>
              <NvSwitch variant="compact" checked={checked} onChange={onChange} disabled={isFieldDisabled} />
              <NvFlex flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                <NvRemoveRedEyeIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                />
                <NvTypography variant="h5">Set as display property</NvTypography>
                <NvTooltip
                  title={
                    'If you enable the display property, it will be used for display purposes to provide consistent visibility in all relevant areas of the app.'
                  }
                >
                  <NvHelpIcon fontSize="small" htmlColor={palette.nv_neutral[70]} />
                </NvTooltip>
              </NvFlex>
            </NvFlex>
          );
        }}
        formControlStyle={{ width: '100%', maxWidth: 700 - 98 }}
      />
    </PropertyPanelSimpleSection>
  );
};
