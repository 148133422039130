import { ActionType, ConfirmDialogContextAction, ConfirmDialogState, OpenConfirmDialogPayload } from '../types';

export const confirmDialogStateReducer = (
  state: ConfirmDialogState,
  action: ActionType<OpenConfirmDialogPayload | undefined>
) => {
  switch (action.type) {
    case ConfirmDialogContextAction.Close: {
      return { ...state, isOpen: false };
    }

    case ConfirmDialogContextAction.Open: {
      return { ...state, ...(action.payload as OpenConfirmDialogPayload), isOpen: true };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
