import { FieldSchema, ValueTypes } from '@novaera/actioner-service';
import { ConstraintType, SchemaType } from '@novaera/ah-common';
import { NvField, isMaxLength, isMinLength, useField } from '@novaera/core';
import { FC, useMemo } from 'react';
import { PropertyPanelSection } from '../../../../property-panel';
import { useCheckboxFieldAdapter } from '../../../../ui-components/checkbox-component/checkbox-field-adapter';
import { useDateTimePickerFieldAdapter } from '../../../../ui-components/date-time-picker-component/date-time-picker-field-adapter';
import { useTextFieldFieldAdapter } from '../../../../ui-components/text-field-component/use-text-field-field-adapter';
import { DefaultValuePropertyProps } from '../types';
import { DefaultValuesMap } from './constants';

export const DefaultValueProperty: FC<React.PropsWithChildren<DefaultValuePropertyProps>> = ({
  fieldName,
  fieldType,
  disabled,
}) => {
  const {
    input: { value: schemaValue },
  } = useField<FieldSchema>(`${fieldName}.schema`);
  const { format: textFieldFormat, parse: textFieldParse } = useTextFieldFieldAdapter({
    extractValue: true,
    hidden: false,
  });
  const { format: checkboxFormat, parse: checkboxParse } = useCheckboxFieldAdapter({
    extractValue: true,
    hidden: false,
  });
  const { format: dateTimePickerFormat, parse: dateTimePickerParse } = useDateTimePickerFieldAdapter({
    extractValue: true,
    hidden: false,
  });

  const extraProps = useMemo(() => {
    const { type, constraints } = schemaValue;

    const validationOfMinMax =
      type === SchemaType.string && constraints.find((c) => c.type === ConstraintType.stringSize);

    switch (type) {
      case SchemaType.string: {
        const stringFormatParse = { format: textFieldFormat, parse: textFieldParse({ schema: schemaValue, id: '' }) };
        if (
          validationOfMinMax &&
          validationOfMinMax.enabled &&
          'min' in validationOfMinMax &&
          'max' in validationOfMinMax
        ) {
          return {
            validators: [
              isMinLength({ length: validationOfMinMax.min }),
              isMaxLength({ length: validationOfMinMax.max }),
            ],
            formControlStyle: { width: '100%', maxWidth: '700px' },
            ...stringFormatParse,
          };
        }
        return {
          formControlStyle: { width: '100%', maxWidth: '700px' },
          ...stringFormatParse,
        };
      }
      case SchemaType.dateTime:
        return {
          format: dateTimePickerFormat,
          parse: dateTimePickerParse({ schema: schemaValue, id: '' }),
          formControlStyle: { width: '100%', maxWidth: '700px' },
        };
      case SchemaType.float:
      case SchemaType.integer:
        return {
          format: textFieldFormat,
          parse: textFieldParse({ schema: schemaValue, id: '' }),
          formControlStyle: { width: '100%', maxWidth: '215px' },
        };
      case SchemaType.boolean:
        return {
          format: checkboxFormat,
          parse: checkboxParse({ id: '' }),
          formControlStyle: { width: '100%', maxWidth: '700px' },
          defaultValue: {
            type: ValueTypes.BOOLEAN,
            value: false,
          },
        };
      default:
        return {
          formControlStyle: { width: '100%', maxWidth: '700px' },
          format: textFieldFormat,
          parse: textFieldParse({ schema: schemaValue, id: '' }),
        };
    }
  }, [
    checkboxFormat,
    checkboxParse,
    dateTimePickerFormat,
    dateTimePickerParse,
    schemaValue,
    textFieldFormat,
    textFieldParse,
  ]);

  return (
    <PropertyPanelSection title="Default value">
      <NvField
        name={`${fieldName}.schema.defaultValue`}
        isRequired
        component={DefaultValuesMap[fieldType]}
        {...extraProps}
        isDisabled={disabled}
      />
    </PropertyPanelSection>
  );
};
