import { NvFlex, NvSkeleton } from '@novaera/core';
import { PropertyPanelListHeaderCard, PropertyPanelListHeaderCardActions } from '../styled';

export const PropertyPanelListHeaderLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <PropertyPanelListHeaderCard>
    <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
      <NvSkeleton variant="rectangular" height="12px" width="30px" />
      <NvSkeleton variant="rectangular" height="12px" width="12px" />
    </NvFlex>
    <PropertyPanelListHeaderCardActions>
      <NvSkeleton variant="rectangular" width="16px" height="16px" />
    </PropertyPanelListHeaderCardActions>
  </PropertyPanelListHeaderCard>
);
