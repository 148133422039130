import { SlackAppInstallationTrigger } from '@novaera/actioner-service';
import { NvFocusState, NvSlackIcon } from '@novaera/core';
import { CompleteStep } from '../../complete-step';
import { ExportSlackAppFocusStateOption } from '../../export-slack-app/types';

import { CompleteSlackAppInstallationProps } from './types';

export const CompleteSlackAppInstallation: React.FC<React.PropsWithChildren<CompleteSlackAppInstallationProps>> = ({
  isOpen,
  onClose,
  installationTrigger,
}) => {
  return (
    <NvFocusState
      open={isOpen}
      onClose={onClose}
      headerLogo={<NvSlackIcon fontSize="large" />}
      title="App for Slack installation"
    >
      {installationTrigger === SlackAppInstallationTrigger.DIRECT_INSTALLATION ? (
        <CompleteStep type={ExportSlackAppFocusStateOption.INSTALL} />
      ) : (
        <CompleteStep type={ExportSlackAppFocusStateOption.CREATE} />
      )}
    </NvFocusState>
  );
};
