import { NvAxios, useQuery } from '@novaera/core';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemaVersionsParams, GetAppSchemaVersionsResponse } from './types';

const getAppSchemaVersions = async ({ schemaId, next }: GetAppSchemaVersionsParams) => {
  const result = await NvAxios.get<GetAppSchemaVersionsResponse>(
    next
      ? `${APP_DIRECTORY_ROOT_PATH}/anonymous/schemas/${schemaId}/versions?next=${next}`
      : `${APP_DIRECTORY_ROOT_PATH}/anonymous/schemas/${schemaId}/versions`
  );
  return result?.data;
};

export const useGetAppSchemaVersions = (params: GetAppSchemaVersionsParams) =>
  useQuery<GetAppSchemaVersionsResponse>(QUERY_KEYS_APP_DIRECTORY.versionHistory(params.schemaId), () =>
    getAppSchemaVersions(params)
  );
