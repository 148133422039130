import { Timezone } from '@novaera/actioner-service';
import { isRequired, isValidTime, NvDatePicker, NvField, NvFlex, NvTimePicker } from '@novaera/core';
import { format, isValid } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { TimezonesField } from '../timezones';

export const ConfigureScheduledTime: React.FC<React.PropsWithChildren<{ timezones: Timezone[] }>> = ({ timezones }) => (
  <NvFlex direction="row" gap="8px" alignItems="flex-start">
    <NvField
      hasRequiredIndicator
      showErrorMessageOnlyWhenBlur
      validators={[isRequired()]}
      name="triggerAt.date"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parse={(value: any) => {
        if (isValid(value)) {
          return format(value, 'yyyy/MM/dd');
        } else {
          return value;
        }
      }}
      component={<NvDatePicker mask="____-__-__" inputFormat="yyyy-MM-dd" disablePast fullWidth />}
    />
    <NvField
      showErrorMessageOnlyWhenBlur
      validators={[isRequired(), isValidTime()]}
      name="triggerAt.time"
      format={(value: string) => {
        return toDate(`${format(new Date(), 'yyyy-MM-dd')}T${value}`);
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parse={(value: any) => {
        try {
          if (isValid(value)) {
            const formattedTime = format(value, 'HH:mm');
            return formattedTime;
          } else {
            return value;
          }
        } catch (error) {
          return value;
        }
      }}
      component={<NvTimePicker open={false} fullWidth />}
    />
    <TimezonesField timezones={timezones} />
  </NvFlex>
);
