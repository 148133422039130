import { useFormState } from '@novaera/core';
import { isNumber, isUndefined } from 'lodash';
import { useFieldArray } from 'react-final-form-arrays';

import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';

import { useFunctionsContext } from '../../../../../../providers/functions-provider';
import { setNextItemAfterDeletion } from '../../../../../request-tab/requests/utils';
import { FunctionsFormValues } from '../../../../types';

export const useFunctionDetail = () => {
  const theme = useTheme();
  const { values } = useFormState<FunctionsFormValues>();
  const functionsFieldArray = useFieldArray('functions');

  const { selectedFunctionId, setSelectedFunctionId } = useFunctionsContext();

  const functionStepIndex = selectedFunctionId && values.functions?.findIndex((f) => f.id === selectedFunctionId);

  const onHandleDeleteClick = (id?: string) => {
    assert(!isUndefined(id), new Error('[useFunctionDetail] functions cannot be deleted without id field'), 'ERROR');

    const itemIndex = functionsFieldArray.fields.value.findIndex((r) => r.id === id);
    if (itemIndex > -1) {
      functionsFieldArray.fields.remove(itemIndex);
    }
    setNextItemAfterDeletion(id, itemIndex, setSelectedFunctionId, values.functions);
  };

  return {
    theme,
    functionStep: isNumber(functionStepIndex) ? values?.functions?.[functionStepIndex] : undefined,
    functionStepIndex,
    onHandleDeleteClick,
  };
};
