import { useUnMount } from '@novaera/utils';
import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/use-document-title';

export const DocumentTitle: React.FC<React.PropsWithChildren<{ title: string; defaultTitle: string }>> = ({
  title,
  defaultTitle,
}) => {
  const { setDocumentTitle } = useDocumentTitle();

  useEffect(() => {
    setDocumentTitle(title);
  }, [setDocumentTitle, title]);

  useUnMount(() => {
    setDocumentTitle(defaultTitle);
  });

  return null;
};
