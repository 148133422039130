import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPoliciesParams, GetPermissionPoliciesResponse } from './types';

const getPermissionPolicies = async ({ queryParams }: GetPermissionPoliciesParams) => {
  const result = await NvAxios.get<GetPermissionPoliciesResponse>(`${PERMISSION_POLICIES_ROOT_PATH}/policies`, {
    params: {
      ...(queryParams?.pageKey ? { ['page-key']: queryParams?.pageKey } : {}),
      ['name-prefix']: queryParams?.namePrefix,
    },
  });
  return result?.data;
};

export const useGetPermissionPolicies = (params?: GetPermissionPoliciesParams) => {
  return useInfiniteQuery<GetPermissionPoliciesResponse>(
    QUERY_KEYS_PERMISSION_POLICIES.list(params?.queryParams),
    ({ pageParam }) => {
      return getPermissionPolicies({
        ...params,
        queryParams: {
          ...params?.queryParams,
          pageKey: pageParam,
        },
      });
    },
    {
      getNextPageParam: (lastPage) => lastPage?.nextPageKey,
    }
  );
};
