import { useInfiniteSearchUserApps, UserApp } from '@novaera/actioner-service';
import {
  Initial,
  NvArrowForwardIcon,
  NvEmptyState,
  NvFlex,
  NvImage,
  NvLink,
  NvSkeleton,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { FC, useState } from 'react';
import { SimpleSearchInput } from '../../../../components';
import { useSelectedAppIdContext } from '../providers/selected-app-id';
import { AppItem, AppsContainer } from './styled';

export const SelectAppStep: FC<React.PropsWithChildren<{ handleNextClick: () => void }>> = ({ handleNextClick }) => {
  const { storeSchemaId } = useParams();
  const { setSelectedAppId } = useSelectedAppIdContext();
  const [keyword, setKeyword] = useState<string | undefined>();
  const debouncedKeyword = useDebounce(keyword, 500);
  const {
    data: searchUserAppsQueries,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteSearchUserApps({
    payload: {
      keyword: debouncedKeyword,
      ...(storeSchemaId ? { filters: [{ field: 'schema-id', operation: 'equals', value: storeSchemaId }] } : {}),
    },
  });
  const searchUserAppsPages = searchUserAppsQueries?.pages?.filter((p) => p);
  const userApps = searchUserAppsPages?.reduce<UserApp[]>((acc, cur) => [...acc, ...cur.apps], []);

  const theme = useTheme();

  const handleSelectApp = (app: UserApp) => {
    if (app) {
      setSelectedAppId(app.id);
      handleNextClick();
    }
  };

  return (
    <NvFlex gap="8px">
      <NvTypography variant="h2">Select your app</NvTypography>
      <NvTypography variant="body1">
        Publish your app to App directory to share it with Actioner Community. Make sure to remove any private
        information before publishing your app. <br /> Note that your connection credentials will not be copied to the
        published version.
        <NvLink href="https://actioner.com/help/publishing-in-app-directory" target="_blank">
          Learn more about.
        </NvLink>
      </NvTypography>
      <NvFlex marginTop="24px" marginBottom="8px" width="254px">
        <SimpleSearchInput
          color="secondary"
          onKeywordChanged={(keyword?: string | undefined) => {
            setKeyword(keyword);
          }}
        />
      </NvFlex>

      {isInitialLoading ? (
        <AppsContainer>
          <AppItem>
            <NvSkeleton variant="rectangular" width="32px" height="32px" />
            <NvSkeleton variant="text" width="70%" />
          </AppItem>
          <AppItem>
            <NvSkeleton variant="rectangular" width="32px" height="32px" />
            <NvSkeleton variant="text" width="70%" />
          </AppItem>
          <AppItem>
            <NvSkeleton variant="rectangular" width="32px" height="32px" />
            <NvSkeleton variant="text" width="70%" />
          </AppItem>
          <AppItem>
            <NvSkeleton variant="rectangular" width="32px" height="32px" />
            <NvSkeleton variant="text" width="70%" />
          </AppItem>
          <AppItem>
            <NvSkeleton variant="rectangular" width="32px" height="32px" />
            <NvSkeleton variant="text" width="70%" />
          </AppItem>
          <AppItem>
            <NvSkeleton variant="rectangular" width="32px" height="32px" />
            <NvSkeleton variant="text" width="70%" />
          </AppItem>
        </AppsContainer>
      ) : (
        <>
          <AppsContainer>
            {userApps?.map((app) => {
              return (
                <AppItem key={`app_item_${app.id}`} onClick={() => handleSelectApp(app)}>
                  <NvImage
                    imageShape="square"
                    src={app.logoUrl}
                    FallBack={<Initial size="medium" value={app.name} color={theme.palette.nv_neutral[500]} />}
                    size="medium"
                    hasNoElevation
                  />
                  <NvTypography variant="h3" noWrap>
                    {app.name}
                  </NvTypography>
                </AppItem>
              );
            })}
            {hasNextPage && (
              <AppItem className="show-more" key={`app_item_show_more`} onClick={() => fetchNextPage()}>
                {isFetchingNextPage ? (
                  <>
                    <NvSkeleton variant="rectangular" width="32px" height="32px" />
                    <NvSkeleton variant="text" width="70%" />
                  </>
                ) : (
                  <>
                    <NvTypography variant="h5" flex="1 1 auto" minWidth={0}>
                      Show more
                    </NvTypography>
                    <NvArrowForwardIcon sx={{ width: '16px', height: '16px' }} />
                  </>
                )}
              </AppItem>
            )}
          </AppsContainer>
          {debouncedKeyword && userApps?.length === 0 && (
            <NvFlex alignItems="center" width="100%">
              <NvEmptyState
                primaryText={
                  <NvTypography textAlign="center">
                    No app found.
                    <br />
                    Try different words or clean search bar.
                  </NvTypography>
                }
              />
            </NvFlex>
          )}
        </>
      )}
    </NvFlex>
  );
};
