import {
  FieldSchema,
  NumberValue,
  ParameterTypes,
  SimpleParameterMapping,
  StringValue,
  ValueTypes,
} from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { fieldSchemaToValue } from '../../utils/field-schema-to-value';

export const useTextFieldFieldAdapter = ({
  extractValue = false,
  hidden,
}: {
  extractValue?: boolean;
  hidden: boolean;
}) => {
  const formatValue = useCallback((value: StringValue | undefined | null) => {
    if (value) {
      return value.value;
    } else {
      return '';
    }
  }, []);

  const parseValue = useCallback(
    ({ schema }: { schema?: FieldSchema }) =>
      (value: string | null) => {
        if (value) {
          assert(!!schema, new Error('Schema should be defined for textfield'), 'ERROR');

          const valueType = fieldSchemaToValue(schema);
          assert(
            valueType === ValueTypes.NUMBER || valueType === ValueTypes.STRING,
            new Error('value type should be number or text in text field adapter'),
            'ERROR'
          );
          if (valueType === ValueTypes.NUMBER) {
            if (schema.type === SchemaType.float) {
              const retVal: NumberValue = { value: parseFloat(value), displayValue: value, type: valueType };
              return retVal;
            } else {
              const retVal: NumberValue = { value: parseInt(value), displayValue: value, type: valueType };
              return retVal;
            }
          } else {
            const retVal: StringValue = { value: value, displayValue: value, type: valueType };
            return retVal;
          }
        } else {
          return undefined;
        }
      },
    []
  );

  const parse = useCallback(
    ({ schema, id }: { schema?: FieldSchema; id: string }) =>
      (value: string | null): SimpleParameterMapping<StringValue | NumberValue> | undefined => {
        const parsedValue = parseValue({ schema })(value);

        if (parsedValue || hidden) {
          return { type: ParameterTypes.SIMPLE, parameterId: id, hidden, ...(parsedValue && { value: parsedValue }) };
        } else {
          return undefined;
        }
      },
    [parseValue, hidden]
  );

  const format = useCallback(
    (parameterMapping?: SimpleParameterMapping<StringValue | undefined | null>) => {
      if (parameterMapping) {
        const { value } = parameterMapping;
        return formatValue(value);
      } else {
        return '';
      }
    },
    [formatValue]
  );

  return { format: extractValue ? formatValue : format, parse: extractValue ? parseValue : parse };
};
