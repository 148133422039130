import { isObject } from 'lodash';
import { SearchRecordsQueryParams } from './types';

export const useGenerateQueryKey = () => {
  const generateQueryKey = (params: SearchRecordsQueryParams) => {
    const { query, limit, nextPageOffset, sortParams } = params;
    const orderGuaranteedParams = {
      ...(query ? { query } : {}),
      ...(limit ? { limit } : {}),
      ...(nextPageOffset ? { nextPageOffset } : {}),
      ...(sortParams ? { sortParams } : {}),
    };
    const searchRecordsParamsKeys = Object.keys(orderGuaranteedParams) as Array<keyof typeof orderGuaranteedParams>;
    const queryStringToCache =
      searchRecordsParamsKeys.length > 0
        ? searchRecordsParamsKeys.reduce((acc, cur) => {
            const currValue = isObject(orderGuaranteedParams[cur])
              ? JSON.stringify(orderGuaranteedParams[cur])
              : orderGuaranteedParams[cur];

            return `${acc}${acc.length > 0 ? '&' : ''}${cur}="${currValue}"`;
          }, '')
        : '';

    return queryStringToCache;
  };
  return {
    generateQueryKey,
  };
};
