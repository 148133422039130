import { NvAxios, useQuery } from '@novaera/core';
import { QUERY_KEYS_INTEGRATION } from '../keys';

import { Integration } from '../../types';
import { INTEGRATION_ROOT_PATH } from '../constants';
import { GetIntegrationParams } from '../types';

const getIntegration = async ({ id }: GetIntegrationParams) => {
  const result = await NvAxios.get(`${INTEGRATION_ROOT_PATH}/${id}`);
  return result?.data;
};

export const useGetIntegration = ({ id }: GetIntegrationParams) => {
  return useQuery<Integration>(QUERY_KEYS_INTEGRATION.detail(id), () => getIntegration({ id }), { enabled: !!id });
};
