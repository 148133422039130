import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomFolderTransferIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2077_203)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17ZM9.29282 11C9.29282 10.4477 9.74053 10 10.2928 10H14.2928C14.8451 10 15.2928 10.4477 15.2928 11V15C15.2928 15.5523 14.8451 16 14.2928 16C13.7405 16 13.2928 15.5523 13.2928 15V13.4142L10.7071 16C10.3166 16.3905 9.68342 16.3905 9.29289 16C8.90237 15.6095 8.90237 14.9763 9.29289 14.5858L11.8786 12H10.2928C9.74053 12 9.29282 11.5523 9.29282 11Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2077_203">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
