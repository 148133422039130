import { Integration, useSearchConnectionsService } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvFlex,
  NvMsTeamsIcon,
  NvSkeleton,
  NvSlackIcon,
  NvSlackIconNotColored,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { PropertyPanelSection } from '../../../../../../../components';
import { ConnectMsTeamsButton } from '../../../../../../../integrations/components/missing-connection/missing-connection-body/item/connect-ms-teams-button';
import { ConnectSlackButton } from '../../../../../../../integrations/components/missing-connection/missing-connection-body/item/connect-slack-button';
import { SlackWorkspaceSectionCard } from './styled';

export const SlackOrMsTeamsWorkspaceSection: React.FC<{ integration: Integration }> = ({ integration }) => {
  const { userAppId } = useParams();
  const { data: connectionsResult, isLoading: isConnectionsLoading } = useSearchConnectionsService({
    payload: {
      appId: userAppId,
      schemaId: integration?.connectionSchemaId,
    },
  });
  const connection = connectionsResult?.connections[0];
  const displayName = integration.type === 'slack' ? 'Slack' : 'MS Teams';

  return (
    <PropertyPanelSection
      title={`${displayName} workspace`}
      icon={integration.type === 'slack' ? <NvSlackIconNotColored /> : <NvMsTeamsIcon />}
    >
      {isConnectionsLoading ? (
        <NvSkeleton height="32px" width="100%" variant="rectangular" />
      ) : connection ? (
        <SlackWorkspaceSectionCard>
          <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
            {integration.type === 'slack' ? <NvSlackIcon /> : <NvMsTeamsIcon />}
            <NvTypography variant="h5" flex="1 1 auto" minWidth={0} noWrap>
              {connection.name.split('#')[0]}
            </NvTypography>
          </NvFlex>
        </SlackWorkspaceSectionCard>
      ) : (
        <NvFlex gap="8px" alignItems="flex-start">
          <NvTypography variant="body2" textColor="subtle">
            You don’t have any connected <b>{displayName} workspace.</b>
          </NvTypography>
          {integration.type === 'slack' ? (
            <ConnectSlackButton
              ButtonBase={({ onClick }) => (
                <NvButton size="small" color="secondary" startIcon={<NvAddBoxIcon />} onClick={onClick}>
                  Connect Slack workspace
                </NvButton>
              )}
            />
          ) : (
            <ConnectMsTeamsButton
              ButtonBase={({ onClick }) => (
                <NvButton size="small" color="secondary" startIcon={<NvAddBoxIcon />} onClick={onClick}>
                  Connect Ms teams workspace
                </NvButton>
              )}
            />
          )}
        </NvFlex>
      )}
    </PropertyPanelSection>
  );
};
