import {
  isRequired,
  NvConditionalWrap,
  NvField,
  NvFlex,
  NvSelect,
  NvTextField,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { BaseUrlBox } from '../../../../test-configuration/connection-test-configuration/styled';

export const VariableRequest = ({ baseUrl }: { baseUrl: string }) => {
  return (
    <NvFlex gap={'16px'}>
      <NvFlex>
        <NvField
          labelText="Request name"
          hasRequiredIndicator
          isRequired
          validators={[isRequired()]}
          direction="label-on-top"
          name={`requestName`}
          component={<NvTextField />}
          showErrorMessageOnlyWhenBlur
        />
      </NvFlex>
      <NvFlex>
        <NvField
          labelText="Path"
          hasRequiredIndicator
          isRequired
          validators={[isRequired()]}
          direction="label-on-top"
          name={`path`}
          component={
            <NvTextField
              sx={{ flex: '1 1 auto' }}
              startLabelAdornment={
                <NvConditionalWrap
                  condition={Boolean(baseUrl)}
                  wrap={(children) => (
                    <NvTooltip title={baseUrl} variant="large" placement="top" fullWidth>
                      {children}
                    </NvTooltip>
                  )}
                >
                  <BaseUrlBox>
                    <NvTypography variant="c2" textColor="subtle" component="p">
                      baseUrl
                    </NvTypography>
                  </BaseUrlBox>
                </NvConditionalWrap>
              }
            />
          }
          showErrorMessageOnlyWhenBlur
        />
      </NvFlex>
      <NvFlex>
        <NvField
          labelText="HTTP Method"
          hasRequiredIndicator
          isRequired
          validators={[isRequired()]}
          direction="label-on-top"
          name={`httpMethod`}
          defaultValue={'GET'}
          component={
            <NvSelect
              sx={{
                width: '143px',
              }}
              options={[{ label: 'GET', value: 'GET' }]}
            />
          }
          showErrorMessageOnlyWhenBlur
        />
      </NvFlex>
    </NvFlex>
  );
};
