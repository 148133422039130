import { lowerCase } from 'lodash';
import * as yup from 'yup';
import { NvYup } from '..';

declare module 'yup' {
  interface ArraySchema<T> {
    unique(message: string, mapper: (a: T) => T): ArraySchema<T>;
  }
}

yup.addMethod(yup.array, 'unique', function (message, mapper) {
  return this.test('unique', message, function (list) {
    const listOfMapped = list?.map(mapper);

    const duplicatedValues = listOfMapped
      ?.filter((item, index) => listOfMapped.indexOf(item) !== index)
      .map((i) => (typeof i === 'string' ? lowerCase(i) : i));

    if (!duplicatedValues?.length) {
      return true;
    } else {
      const errors: NvYup.ValidationError[] = [];
      listOfMapped?.forEach((v, i) => {
        if (typeof v === 'string' && duplicatedValues.includes(lowerCase(v))) {
          errors.push(new NvYup.ValidationError(message, v, `${i}`));
        } else if (duplicatedValues.includes(v)) {
          errors.push(new NvYup.ValidationError(message, v, `${i}`));
        }
      });
      throw this.createError({
        message: () => errors,
      });
    }
  });
});

/**
 * this method helps you to validate optional object's in yup. Otherwise, the optional part
 * will throw an error when it is undefined
 */
yup.addMethod(yup.object, 'optional', function (isOptional = true, defaultValue = undefined) {
  return (
    this.transform(function (value) {
      // If false is passed, skip the transform
      if (!isOptional) return value;

      // If any child property has a value, skip the transform
      if (value && Object.values(value).some((v) => !(v === null || v === undefined || v === ''))) {
        return value;
      }

      return defaultValue;
    })
      // Remember, since we're dealing with the `object`
      // type, we have to change the default value
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .default(defaultValue) as any
  );
});

export default yup;
