import { APP_PERMISSION } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvAutocomplete,
  NvBox,
  NvDivider,
  NvEventRepeatIcon,
  NvFlex,
  NvPopoverWithAnchor,
  NvSkeleton,
  NvTextField,
  NvTimerIcon,
  NvTimerOutlinedIcon,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../user-app-permission-boundary/free-app-permission-boundary';
import { useJobs } from './controllers/use-jobs';
import { SaveJobModal } from './save-job-modal';
import { AddJobButton, AddNewJobDropdownItem, JobTabs } from './styled';

export const Jobs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    tabs,
    handleOnTabsChange,
    activeTabIndex,
    userAppId,
    timezones,
    isTimezonesLoading,
    selectedTimezone,
    addJobPaperProps,
    handleAddNewRecurringJobClick,
    handleAddNewScheduledJobClick,
    handleSaveJobModalClose,
    jobId,
    jobType,
    handleTimezoneChange,
  } = useJobs();

  return (
    <>
      <NvFlex gap="16px">
        <NvFlex direction="row" alignItems="center">
          <NvFlex direction="row" gap="8px" alignItems="center" flex="1 1 auto">
            <NvTimerIcon sx={{ width: '32px', height: '32px' }} />
            <NvTypography variant="h1">Jobs</NvTypography>
          </NvFlex>
          <NvFlex direction="row" gap="8px" alignItems="center" flex="0 0 auto">
            <FreeAppPermissionBoundary>
              <UserAppPermissionBoundary permission={APP_PERMISSION.APP_EDIT} appId={userAppId}>
                <NvPopoverWithAnchor
                  id="add-jobs-button"
                  initiator={
                    <AddJobButton size="small" color="ghost" startIcon={<NvAddBoxIcon />}>
                      Add job
                    </AddJobButton>
                  }
                  popoverProps={{
                    PaperProps: addJobPaperProps,
                  }}
                >
                  {({ close }) => (
                    <NvFlex>
                      <AddNewJobDropdownItem
                        onClick={() => {
                          handleAddNewRecurringJobClick();
                          close();
                        }}
                      >
                        <NvFlex direction="row" gap="8px">
                          <NvEventRepeatIcon sx={{ width: '16px', height: '16px' }} />
                          <NvTypography variant="h4">Add recurring job</NvTypography>
                        </NvFlex>
                        <NvTypography variant="body2" textColor="subtle" marginLeft="24px">
                          Add a job that will be repeated.
                        </NvTypography>
                      </AddNewJobDropdownItem>
                      <AddNewJobDropdownItem
                        onClick={() => {
                          handleAddNewScheduledJobClick();
                          close();
                        }}
                      >
                        <NvFlex direction="row" gap="8px">
                          <NvTimerOutlinedIcon sx={{ width: '16px', height: '16px' }} />
                          <NvTypography variant="h4">Add scheduled job</NvTypography>
                        </NvFlex>

                        <NvTypography variant="body2" textColor="subtle" marginLeft="24px">
                          Add a job to run a task only once.
                        </NvTypography>
                      </AddNewJobDropdownItem>
                    </NvFlex>
                  )}
                </NvPopoverWithAnchor>
                <NvDivider orientation="vertical" sx={{ height: '12px' }} />
              </UserAppPermissionBoundary>
            </FreeAppPermissionBoundary>
            {!selectedTimezone || isTimezonesLoading ? (
              <NvSkeleton width="200px" height="24px" variant="rectangular" />
            ) : (
              <NvTooltip title={selectedTimezone.displayName} maxWidth={220} enterDelay={1000} placement="top">
                <NvBox>
                  <NvAutocomplete
                    value={selectedTimezone}
                    onChange={handleTimezoneChange}
                    renderInput={(props) => (
                      <NvTextField
                        {...props}
                        inputProps={{
                          ...props.inputProps,
                          // Shows only the timezone with the offset
                          // TODO: The value is not recognized and could not find the proper way to type it!
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ...((props.inputProps as any)?.value && {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            value: (props.inputProps as any)?.value?.split(')')?.[0]?.replace('(', ''),
                          }),
                        }}
                        placeholder="Select timezone"
                        color="ghost"
                      />
                    )}
                    getOptionLabel={(o) => o.displayName}
                    options={timezones}
                    disableClearable
                    size="small"
                  />
                </NvBox>
              </NvTooltip>
            )}
          </NvFlex>
        </NvFlex>

        <JobTabs
          tabs={tabs}
          focusIndex={activeTabIndex}
          onActiveTabChanged={handleOnTabsChange}
          defaultActiveTabIndex={activeTabIndex}
          BodyWrapperComponent={<NvBox padding="16px 0 0 0" />}
        />
      </NvFlex>
      {!!jobType && <SaveJobModal open={!!jobType} type={jobType} id={jobId} handleClose={handleSaveJobModalClose} />}
    </>
  );
};
