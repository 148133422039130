import { NvCssBaseline, NvFlex } from '@novaera/core';
import { Outlet } from '@novaera/route';
import { MenuBar } from '../menu-bar';
import { LayoutContent, LayoutContentInner } from './styled';

export const LayoutTopMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <NvCssBaseline />
      <NvFlex height="100vh" width="100%">
        <MenuBar />
        <LayoutContent>
          <LayoutContentInner>
            <Outlet />
          </LayoutContentInner>
        </LayoutContent>
      </NvFlex>
    </>
  );
};
