import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { ColoredChipColor, NeutralChipColor, NvChipProps, SemanticChipColor } from './types';

export const Chip = styled(NvBox, {
  shouldForwardProp: (prop) =>
    prop !== 'compact' && prop !== 'color' && prop !== 'maxWidth' && prop !== 'isNumber' && prop !== 'href',
})<Pick<NvChipProps, 'color' | 'compact' | 'maxWidth'> & { isNumber?: boolean }>`
  display: flex;
  flex: 0 0 auto;
  padding: ${({ isNumber }) => (isNumber ? '2px 6px' : '0 2px')};
  align-items: center;

  background: ${({ theme, color }) =>
    color === NeutralChipColor.LIGHT_ALPHA
      ? theme.palette.nv_neutral_alpha[20]
      : color === NeutralChipColor.LIGHT_SOLID
      ? theme.palette.nv_neutral[20]
      : color === NeutralChipColor.DARK_ALPHA
      ? theme.palette.nv_neutral_alpha[600]
      : color === NeutralChipColor.DARK_SOLID
      ? theme.palette.nv_neutral[600]
      : color === SemanticChipColor.SUCCESS
      ? theme.palette.nv_success[30]
      : color === SemanticChipColor.ERROR
      ? theme.palette.nv_error[40]
      : color === SemanticChipColor.WARNING
      ? theme.palette.nv_warning[30]
      : color === SemanticChipColor.NEW
      ? theme.palette.nv_new[40]
      : color === ColoredChipColor.ACCENT
      ? theme.palette.nv_accent[40]
      : color === ColoredChipColor.BLUE
      ? '#9BE8FC'
      : color === ColoredChipColor.PINK
      ? '#EB60BC'
      : color === ColoredChipColor.GREEN
      ? '#A0F478'
      : color === ColoredChipColor.TEAL
      ? '#78F4D7'
      : color === ColoredChipColor.PURPLE
      ? '#D17CEA'
      : color === ColoredChipColor.LIGHT_BLUE_GRAY
      ? theme.palette.nv_neutral[30]
      : theme.palette.nv_neutral_alpha[20]};

  color: ${({ theme, color }) =>
    color === NeutralChipColor.LIGHT_ALPHA ||
    color === NeutralChipColor.LIGHT_SOLID ||
    color === SemanticChipColor.SUCCESS ||
    color === SemanticChipColor.WARNING ||
    color === ColoredChipColor.BLUE ||
    color === ColoredChipColor.GREEN ||
    color === ColoredChipColor.TEAL ||
    color === SemanticChipColor.NEW ||
    color === ColoredChipColor.LIGHT_BLUE_GRAY
      ? theme.palette.nv_neutral[1000]
      : color === SemanticChipColor.ERROR ||
        color === ColoredChipColor.PURPLE ||
        color === ColoredChipColor.PINK ||
        color === NeutralChipColor.DARK_ALPHA ||
        color === NeutralChipColor.DARK_SOLID
      ? theme.palette.nv_neutral[0]
      : theme.palette.nv_neutral[1000]};

  min-width: ${({ isNumber, compact }) => (isNumber && compact ? '20px' : isNumber && !compact ? '24px' : 'none')};

  height: ${({ compact }) => (compact ? '16px' : '20px')};
  border-radius: ${({ isNumber, compact }) => (isNumber && compact ? '8px' : isNumber && !compact ? '10px' : '4px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : null)}
`;
