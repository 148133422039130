import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';

import { CONFIG_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIG_SCHEMA } from '../keys';
import { ConfigSchema } from '../types';
import { CreateConfigSchemaParams } from './types';

const createConfigSchema = async (params: CreateConfigSchemaParams) => {
  const { appId, payload } = params;
  const result = await NvAxios.post<ConfigSchema>(`${CONFIG_SCHEMA_ROOT_PATH(appId)}`, {
    ...payload,
  });
  return result?.data;
};

export const useCreateConfigSchemaService = () => {
  const cache = useQueryClient();

  return useMutation(createConfigSchema, {
    onSuccess(data, params) {
      cache.invalidateQueries(QUERY_KEYS_CONFIG_SCHEMA.list(params.appId));
    },
  });
};
