import { DefaultRenderOption, NvPaper } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConnectionSelectOption = styled(DefaultRenderOption)`
  &.MuiAutocomplete-option {
    padding: 4px 6px;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
`;

export const ConnectionSelectMenu = styled(NvPaper)`
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e300};
  border-radius: 12px;
  width: 100%;

  .MuiAutocomplete-listbox {
    margin: 0;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
