import { NvButton } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const DropdownButtonComponent = styled(NvButton)`
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  transition: background-color 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
  } */

  &.MuiButtonBase-root {
    &.MuiButton-containedSecondary {
      background-color: ${({ theme, isSelected }) =>
        isSelected ? theme.palette.nv_neutral_alpha[600] : theme.palette.nv_neutral_alpha[10]};

      &.MuiLoadingButton-loading {
        background-color: ${({ theme, isSelected }) =>
          isSelected ? theme.palette.nv_neutral_alpha[600] : theme.palette.nv_neutral_alpha[10]};
      }

      &.Mui-focusVisible,
      &:hover {
        background-color: ${({ theme, isSelected }) =>
          isSelected ? theme.palette.nv_neutral_alpha[700] : theme.palette.nv_neutral_alpha[20]};
      }

      &:active {
        background-color: ${({ theme, isSelected }) =>
          isSelected ? theme.palette.nv_neutral_alpha[800] : theme.palette.nv_neutral_alpha[30]};
      }
    }
  }
`;
