import { WorkflowHistoryStatus } from '@novaera/actioner-service';
import {
  NvAccessTimeRounded,
  NvArrowForwardIcon,
  NvCustomCheckIcon,
  NvCustomCrossIcon,
  NvCustomIndeterminateIcon,
  NvCustomSpinner,
  NvDivider,
  NvFlex,
  NvTypography,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { WORKFLOW_HISTORY_DETAIL } from '../../../workflow-detail/constants';
import { WorkflowHistoryListItemCard } from './styled';
import { WorkflowHistoryListItemProps } from './types';

export const WorkflowHistoryListItem: React.FC<React.PropsWithChildren<WorkflowHistoryListItemProps>> = ({ log }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userAppId, workflowId, workflowExecutionId } = useParams();
  const isSelected = useMemo(() => log.executionId === workflowExecutionId, [log.executionId, workflowExecutionId]);
  const handleItemClick = () => {
    if (!isSelected) {
      navigate(WORKFLOW_HISTORY_DETAIL({ appId: userAppId, workflowId, executionId: log.executionId }));
    }
  };
  return (
    <WorkflowHistoryListItemCard isSelected={isSelected} onClick={handleItemClick}>
      <NvFlex direction="row" gap="8px" alignItems="center" flex="1 1 auto" minWidth={0}>
        {log.status === WorkflowHistoryStatus.SUCCESSFUL ? (
          <NvCustomCheckIcon htmlColor={theme.palette.nv_success[60]} sx={{ width: '16px', height: '16px' }} />
        ) : log.status === WorkflowHistoryStatus.IN_PROGRESS ? (
          <NvCustomSpinner htmlColor={theme.palette.nv_neutral[600]} sx={{ width: '16px', height: '16px' }} />
        ) : log.status === WorkflowHistoryStatus.DISCARDED ? (
          <NvCustomIndeterminateIcon htmlColor={theme.palette.nv_main[40]} sx={{ width: '16px', height: '16px' }} />
        ) : log.status === WorkflowHistoryStatus.FAILED ? (
          <NvCustomCrossIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
        ) : null}

        <NvTypography variant="body2">{format(new Date(log.startedAt), 'PP')}</NvTypography>
        <NvDivider
          orientation="vertical"
          borderColor={theme.palette.nv_neutral_alpha[20]}
          sx={{
            height: '12px',
          }}
        />
        <NvFlex direction="row" gap="3px" alignItems="center">
          <NvAccessTimeRounded sx={{ width: '16px', height: '16px' }} />
          <NvTypography variant="body2">
            <b>{format(new Date(log.startedAt), 'pp')}</b>
          </NvTypography>
        </NvFlex>
      </NvFlex>
      <NvArrowForwardIcon sx={{ width: '16px', height: '16px', flex: '0 0 auto' }} />
    </WorkflowHistoryListItemCard>
  );
};
