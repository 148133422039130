import { NvDialogModal, NvSkeleton } from '@novaera/core';
import { useMemo } from 'react';
import { ConnectModalBody } from './body';
import { useConnectModalController } from './controllers/use-connect-modal';
import { ConnectModalFooter } from './footer';

import { ConnectionModalForm, ConnectModalProps } from './types';

export const ConnectModal = ({
  mode,
  onModalCloseClicked,
  isOpened,
  connected,
  connectionId,
  test,
  connectionName,
  integrationId,
  schemaId: initialSchemaId,
  modalTitle,
  onFormSubmitFinished,
}: ConnectModalProps) => {
  const {
    onFormSubmit,
    response,
    isLoading,
    oauthPromiseResult,
    onClose,
    isConnectionSchemaLoading,
    connectionSchema,
    setSchemaId,
  } = useConnectModalController({
    mode,
    connected,
    connectionId,
    onModalCloseClicked,
    test,
    integrationId,
    initialSchemaId,
  });

  const header = useMemo(() => {
    if (modalTitle) {
      return modalTitle;
    }
    if (initialSchemaId && connectionSchema) {
      return mode === 'Connect'
        ? connected
          ? `Reconnect ${connectionSchema.name}`
          : `Connect ${connectionSchema.name}`
        : 'Test connection';
    } else {
      return 'Add connection';
    }
  }, [connected, connectionSchema, initialSchemaId, modalTitle, mode]);

  return (
    <NvDialogModal<ConnectionModalForm>
      modalIcon="power"
      maxWidth="sm"
      fullWidth
      open={isOpened || false}
      Header={isConnectionSchemaLoading ? <NvSkeleton variant="rectangular" width={'100px'} /> : header}
      Body={
        <ConnectModalBody
          mode={mode}
          connected={connected}
          formSubmitResult={oauthPromiseResult}
          isLoading={isLoading}
          response={response}
          connectionSchema={connectionSchema}
          isConnectionSchemaLoading={isConnectionSchemaLoading}
          showSchemaSelect={!initialSchemaId}
          setSchemaId={setSchemaId}
        />
      }
      formProps={{
        initialValues: {
          name: connectionName,
        },
      }}
      onCloseButtonClicked={onClose}
      isLoading={isLoading}
      onFormSubmit={async (value) => {
        const result = await onFormSubmit(value);

        if (result && 'type' in result && result.type === 'error') {
          return;
        }

        onFormSubmitFinished?.(result);
      }}
      Footer={
        <ConnectModalFooter
          connectionSchema={connectionSchema}
          onModalCloseClicked={onClose}
          formSubmitResult={oauthPromiseResult}
          isLoading={isLoading || false}
          response={response}
          mode={mode}
          connected={connected}
        />
      }
    />
  );
};
