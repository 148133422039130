import Typography from '@mui/material/Typography';
import { styled } from '@novaera/theme-provider';
import { TypographyColor } from './types';

export const StyledTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== 'textColor' })<{
  textColor: TypographyColor;
}>`
  b,
  strong {
    font-weight: 600;
  }

  blockquote {
    position: relative;
    margin-left: 24px;
    color: ${({ theme }) => theme.palette.nv_neutral_alpha[500]};

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -24px;
      top: -4px;
      height: calc(100% + 8px);
      width: 4px;
      border-radius: 1px;
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
    }
  }

  hr {
    border-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    border-top: none;
  }

  ul {
    padding-left: 22px;
  }

  a {
    color: ${({ theme }) => theme.palette.nv_main[60]};
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: ${({ theme }) => theme.palette.nv_main[50]};
      text-decoration: underline;
    }
  }

  img {
    border-radius: 16px;
    ${({ theme }) => theme.elevations.e300};
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    min-width: 48px;
    min-height: 48px;
    max-width: 100%;
  }

  code {
    font-family: Fira Code, monospace;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    color: ${({ theme }) => theme.palette.nv_accent[60]};
    font-weight: 500;
  }

  dfn {
    font-style: initial;
    opacity: 0.5;
  }

  color: ${({ theme, textColor, color }) =>
    color
      ? `${color}`
      : textColor === 'main'
      ? // this should be inherit because of typography use inside buttons.
        'inherit'
      : textColor === 'secondary'
      ? theme.palette.nv_neutral[600]
      : textColor === 'subtle'
      ? theme.palette.nv_neutral[300]
      : textColor === 'ghost'
      ? theme.palette.nv_neutral[60]
      : 'inherit'};

  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
`;
