import React, { ReactNode, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { IconListType } from './types';
import { fetchIconList, getIconForAutocomplete } from './utils';

export const DynamicMaterialIcons = ({
  initialValue,
  children,
  prefetchIcons = false,
}: {
  initialValue?: string;
  prefetchIcons?: boolean;
  children: (props: {
    icons: IconListType[];
    iconsLoading: boolean;
    onSearch: (value: string) => void;
    renderIcon: (iconType: IconListType) => typeof React.Component;
    getCurrentIcon: () => typeof React.Component;
  }) => ReactNode;
}) => {
  const [icons, setIcons] = useState<IconListType[]>([]);
  const [iconsLoading, setIconsLoading] = useState<boolean>(false);
  const [IconComponent, setIconComponent] = useState<React.Component>();

  const onSearch = useDebouncedCallback((value: string) => {
    setIconsLoading(true);
    fetchIconList(value).then((iconList) => {
      setIcons(iconList);
      setIconsLoading(false);
    });
  }, 500);

  useEffect(() => {
    if (prefetchIcons) {
      onSearch('A');
    }
  }, [onSearch, prefetchIcons]);

  useEffect(() => {
    if (initialValue) {
      getIconForAutocomplete(initialValue).then((iconList) => {
        setIconComponent(iconList.Icon);
      });
    }
  }, [initialValue]);

  return (
    <>
      {children({
        icons,
        iconsLoading,
        onSearch,
        renderIcon: (iconType) => {
          return iconType.Icon as unknown as typeof React.Component;
        },
        getCurrentIcon: () => {
          return IconComponent as unknown as typeof React.Component;
        },
      })}
    </>
  );
};
