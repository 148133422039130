import { ConstraintType } from '@novaera/ah-common';
import { FC, useMemo } from 'react';
import { ConstraintMapProps } from '../../types';
import { ConstraintItem } from '../constraint-Item';

export const ConstraintMap: FC<React.PropsWithChildren<ConstraintMapProps>> = ({
  fieldSchemaName,
  constraints,
  schemaType,
  disabled,
}) => {
  const constraintMap = useMemo(
    () =>
      constraints
        ?.filter((c) => Object.values(ConstraintType).includes(c.type))
        .map((constraint, index) => (
          <ConstraintItem
            key={`key_${fieldSchemaName}.constraints[${index}]`}
            fieldName={`${fieldSchemaName}.constraints[${index}]`}
            constraint={constraint}
            schemaType={schemaType}
            disabled={disabled}
          />
        )),
    [constraints, disabled, fieldSchemaName, schemaType]
  );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{constraintMap}</>;
};
