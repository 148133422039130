import { ConditionType } from '@novaera/actioner-service';
import { Context, NvAddBoxIcon, NvButton, NvConditionalRender } from '@novaera/core';

import { useRef, useState } from 'react';
import {
  MatchEventSectionCondition,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../components';
import { DEFAULT_CONDITION_TYPE_OPTIONS } from '../../../../../../components/match-event-section/constants';
import { RulesProps } from '../../../../../../components/match-event-section/rules/types';
import { MatchEventSectionProps } from '../../../../../../components/match-event-section/types';
import { useWorkflowPermission } from '../../../../../user-app-permission-boundary/use-workflow-permission';
import { ConditionRule } from '../condition-rule';
import { PropertyPanelFunctionSectionStyled } from '../condition-rule/styled';

import { css } from '@emotion/css';
import classNames from 'classnames';

const PropertyPanelConditionClass = css`
  flex: 0 0 auto;
`;

export const Conditions = ({
  title,
  headerActions,
  hideContent = false,
  conditionFieldName,
  context = {},
  conditionOptions = DEFAULT_CONDITION_TYPE_OPTIONS,
  valueTypeOptions,
}: {
  title?: string;
  headerActions?: React.ReactNode;
  hideContent?: boolean;
  conditionFieldName: string;
  context?: Context;
  conditionOptions?: MatchEventSectionProps['conditionTypeOptions'];
  valueTypeOptions?: RulesProps['valueTypeOptions'];
}) => {
  const [conditionType, setConditionType] = useState<ConditionType['type']>();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const { hasEditPermission } = useWorkflowPermission();

  return (
    <>
      {title ? (
        <PropertyPanelSection title={title} actions={hasEditPermission && headerActions}>
          {!hideContent && (
            <MatchEventSectionCondition
              codeInputContext={{}}
              conditionFieldName={conditionFieldName}
              onConditionTypeChange={(conditionType) => {
                setConditionType(conditionType);
              }}
              conditionTypeOptions={conditionOptions}
            />
          )}
        </PropertyPanelSection>
      ) : (
        <PropertyPanelSimpleSection className={classNames(PropertyPanelConditionClass)}>
          {!hideContent && (
            <MatchEventSectionCondition
              codeInputContext={{}}
              conditionFieldName={conditionFieldName}
              onConditionTypeChange={(conditionType) => {
                setConditionType(conditionType);
              }}
              conditionTypeOptions={conditionOptions}
            />
          )}
        </PropertyPanelSimpleSection>
      )}

      {!hideContent && conditionType !== 'no-condition' && (
        <>
          {conditionType !== 'javascript' && (
            <>
              <NvConditionalRender when={hasEditPermission}>
                <PropertyPanelListHeader
                  title="Rules"
                  actions={
                    <NvButton
                      onlyIcon
                      ref={addButtonRef}
                      size="small"
                      variant="contained"
                      color="ghost"
                      sx={{ flex: '0 0 auto' }}
                    >
                      <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
                    </NvButton>
                  }
                />
              </NvConditionalRender>
              <NvConditionalRender when={!hasEditPermission}>
                <PropertyPanelListHeader title="Rules" />
              </NvConditionalRender>
            </>
          )}

          <ConditionRule
            addButtonRef={addButtonRef}
            codeInputContext={context}
            conditionFieldName={conditionFieldName}
            conditionType={conditionType}
            valueTypeOptions={valueTypeOptions}
            JavascriptWrapperComponent={PropertyPanelFunctionSectionStyled}
          />
        </>
      )}
    </>
  );
};
