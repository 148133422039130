import { CatalogEntityType, CatalogRelationship, useGetCatalogEntityType } from '@novaera/actioner-service';
import {
  MenuItems,
  NvBox,
  NvCompareArrowsIcon,
  NvDivider,
  NvField,
  NvFlex,
  NvLockIcon,
  NvMenuWithItems,
  NvSkeleton,
  NvTextField,
  NvTooltip,
  NvTypography,
  SectionMessage,
  isRequired,
  useField,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { DynamicMaterialIcons } from '../../dynamic-material-icons';
import { PropertyPanelSection, PropertyPanelSimpleSection } from '../../property-panel';
import { RightContentHeader } from '../right-content/header/styled';
import { FieldBodyWrapper } from '../right-content/styled';
import { RelationshipTypeInformation } from './relationship-type-information';
import { EndLabelAdornment } from './styled';

export const RelationshipRightPanel = ({
  fieldsFieldName,
  selectedFieldIndex,
  fieldMenus,
  selectedCatalogEntityType,
}: {
  fieldsFieldName: string;
  selectedFieldIndex: number;
  selectedCatalogEntityType: CatalogEntityType;
  fieldMenus: (id: string, selectedFieldIndex: number) => MenuItems[];
}) => {
  const {
    input: { value, name: fieldName },
  } = useField<CatalogRelationship>(`${fieldsFieldName}[${selectedFieldIndex}]`);

  const { palette } = useTheme();

  const fieldMenuItems = useMemo(
    () => fieldMenus(value.id, selectedFieldIndex),
    [fieldMenus, value.id, selectedFieldIndex]
  );

  const { data: sourceEntityType, isLoading: isLoadingSourceEntityType } = useGetCatalogEntityType({
    id: value.sourceEntityTypeId,
  });

  return (
    <NvFlex>
      <RightContentHeader>
        <NvFlex direction="row" alignItems="center" justifyContent="flex-start" flex="1 1 auto" minWidth={0} gap="8px">
          <DynamicMaterialIcons initialValue={value.targetEntityType?.iconId}>
            {({ getCurrentIcon }) => {
              const Icon = getCurrentIcon();
              return Icon && <Icon sx={{ width: '20px', height: '20px' }} />;
            }}
          </DynamicMaterialIcons>
          <NvTypography variant="h4"> {value.targetEntityType?.name}</NvTypography>
          <NvDivider
            orientation="vertical"
            sx={{
              height: '12px',
            }}
          />
          <NvFlex direction="row" alignItems="center" gap="4px">
            <RelationshipTypeInformation fieldsFieldName={fieldsFieldName} selectedFieldIndex={selectedFieldIndex} />
          </NvFlex>
        </NvFlex>
        <NvBox flex="0 0 auto">
          {value.managed && (
            <NvTooltip title="This relationship is managed by Actioner, edit or delete operations are not allowed.">
              <NvLockIcon fontSize="small" htmlColor={palette.nv_neutral[300]} />
            </NvTooltip>
          )}
          {!value.managed && fieldMenuItems && fieldMenuItems.length > 0 && (
            <NvMenuWithItems menuItems={fieldMenuItems} />
          )}
        </NvBox>
      </RightContentHeader>
      <FieldBodyWrapper>
        {value.managed && (
          <PropertyPanelSimpleSection>
            <SectionMessage
              message={'This relationship is managed by Actioner, edit or delete operations are not allowed.'}
              variant={'info'}
            ></SectionMessage>
          </PropertyPanelSimpleSection>
        )}
        <PropertyPanelSection title="Relationship names" icon={<NvCompareArrowsIcon />}>
          <NvFlex gap={'10px'}>
            {isLoadingSourceEntityType ? (
              <NvSkeleton />
            ) : (
              <NvField
                isRequired
                validators={[isRequired()]}
                hasRequiredIndicator
                showErrorMessageOnlyWhenBlur
                name={`${fieldName}.sourceToTargetRelationType`}
                component={
                  <NvTextField
                    disabled={value.managed}
                    placeholder="relation name"
                    startLabelAdornment={
                      <NvTypography variant="body2" textColor="subtle" noWrap>
                        {sourceEntityType?.name}
                      </NvTypography>
                    }
                    endLabelAdornment={
                      <EndLabelAdornment>
                        <NvTypography variant="body2" textColor="subtle" noWrap>
                          {value.targetEntityType?.name}
                        </NvTypography>
                      </EndLabelAdornment>
                    }
                  />
                }
              />
            )}
            {isLoadingSourceEntityType ? (
              <NvSkeleton />
            ) : (
              <NvField
                name={`${fieldName}.targetToSourceRelationType`}
                isRequired
                validators={[isRequired()]}
                hasRequiredIndicator
                showErrorMessageOnlyWhenBlur
                component={
                  <NvTextField
                    disabled={value.managed}
                    placeholder="relation name"
                    startLabelAdornment={
                      <NvTypography variant="body2" textColor="subtle" noWrap>
                        {value.targetEntityType?.name}
                      </NvTypography>
                    }
                    endLabelAdornment={
                      <EndLabelAdornment>
                        <NvTypography variant="body2" textColor="subtle" noWrap>
                          {sourceEntityType?.name}
                        </NvTypography>
                      </EndLabelAdornment>
                    }
                  />
                }
              />
            )}
          </NvFlex>
        </PropertyPanelSection>
      </FieldBodyWrapper>
    </NvFlex>
  );
};
