import { Navigate, Route, Routes } from '@novaera/route';
import { UserManagement } from './user-management';
import { USER_MANAGEMENT_TAB_PATHS } from './user-management/constants';
import { GroupDetail } from './user-management/groups/group-detail';
import { PermissionPolicyDetail } from './user-management/permission-policies/permission-policy-detail';
import { WorkflowSettings } from './workspace-settings';
import { WORKSPACE_SETTINGS_TAB_PATHS } from './workspace-settings/constants';

export const Workspace: React.FC = () => (
  <Routes>
    <Route path={USER_MANAGEMENT_TAB_PATHS.USER} element={<UserManagement />} />
    <Route path={USER_MANAGEMENT_TAB_PATHS.GROUP} element={<UserManagement />} />
    <Route path={`${USER_MANAGEMENT_TAB_PATHS.GROUP}/:groupId`} element={<GroupDetail />} />
    <Route path={USER_MANAGEMENT_TAB_PATHS.PERMISSION_POLICIES} element={<UserManagement />} />
    <Route
      path={`${USER_MANAGEMENT_TAB_PATHS.PERMISSION_POLICIES}/:permissionPolicyId`}
      element={<PermissionPolicyDetail />}
    />
    <Route path={'user-management/*'} element={<Navigate to={'users'} replace={true} />} />

    <Route path={WORKSPACE_SETTINGS_TAB_PATHS.INFO} element={<WorkflowSettings />} />
    <Route path={WORKSPACE_SETTINGS_TAB_PATHS.SLACK_WORKSPACE} element={<WorkflowSettings />} />
    <Route path={WORKSPACE_SETTINGS_TAB_PATHS.MS_TEAMS} element={<WorkflowSettings />} />
    <Route path={WORKSPACE_SETTINGS_TAB_PATHS.BILLING} element={<WorkflowSettings />} />
    <Route path={WORKSPACE_SETTINGS_TAB_PATHS.INTEGRATIONS} element={<WorkflowSettings />} />
    <Route path={WORKSPACE_SETTINGS_TAB_PATHS.ORGANIZATION} element={<WorkflowSettings />} />

    <Route index element={<Navigate to={WORKSPACE_SETTINGS_TAB_PATHS.INFO} replace={true} />} />
    <Route path="*" element={<Navigate to={'/error-page/404'} />} />
  </Routes>
);
