import { NvBox, NvQuestionMark, NvTooltip, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { FC } from 'react';
import { UsageInfoProps } from './types';

export const UsageInfo: FC<UsageInfoProps> = ({ usageDetail }) => {
  const theme = useTheme();
  return usageDetail.used < usageDetail.limit ? (
    <NvBox component="span">
      <NvTypography variant="body1" key={usageDetail.resource} component="span">
        <b>{usageDetail.used.toLocaleString()}</b> of {usageDetail.limit.toLocaleString()}{' '}
        <NvTooltip
          title="A credit is a unit of usage on Actioner, and is defined with the execution of a single node."
          placement="top"
          maxWidth={240}
          variant="large"
        >
          <NvBox component="span">
            credits
            <NvQuestionMark
              htmlColor={theme.palette.nv_main[50]}
              sx={{ width: '12px', height: '12px', verticalAlign: 'top', marginLeft: '-3px', marginRight: '-2px' }}
            />
          </NvBox>
        </NvTooltip>{' '}
        are used.
      </NvTypography>{' '}
      {usageDetail.nextInvoiceDate && (
        <NvTypography variant="body1" component="span" textColor="secondary">
          (Usage count will reset on <b>{format(new Date(usageDetail.nextInvoiceDate), 'MMMM dd, yyyy')}</b>)
        </NvTypography>
      )}
    </NvBox>
  ) : (
    <>
      <NvTypography variant="body1" key={usageDetail.resource}>
        You've used up all <b>{usageDetail.limit.toLocaleString()}</b> credits this month.
      </NvTypography>
      {usageDetail.nextInvoiceDate && usageDetail.additionalCreditsUsed && (
        <NvTypography variant="body1" key={usageDetail.resource}>
          You've spent <b>additional {usageDetail.additionalCreditsUsed.toLocaleString()} credits</b>, and it will be
          billed at{' '}
          <b>
            $
            {(usageDetail.additionalCostInCents
              ? usageDetail.additionalCostInCents / 100
              : usageDetail.additionalCreditsUsed / 1000
            ).toLocaleString()}
          </b>{' '}
          on <b>{format(new Date(usageDetail.nextInvoiceDate), 'MMMM dd, yyyy')}</b>.
        </NvTypography>
      )}
    </>
  );
};
