import { ListValue, StringValue, ValueTypes } from '@novaera/actioner-service';
import { CodeInput, NvButton, NvCloseIcon, NvField, NvFlex, NvTuneIcon } from '@novaera/core';
import { PropertyPanelHeader, PropertyPanelSection } from '../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { SideGroupFreeSoloMulti } from '../send-email-properties/side-grouped-free-solo-multi';
import { MoreSettingsProps } from './types';

export const MoreSettings: React.FC<MoreSettingsProps> = ({ onCloseClicked }) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        title="More settings"
        icon={<NvTuneIcon />}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />

      <PropertyPanelSection title={'CC'}>
        <NvField<undefined | (Omit<ListValue, 'value'> & { value?: StringValue[] })>
          name="cc"
          formControlStyle={{ width: '100%' }}
          component={({ value, onChange }) => (
            <SideGroupFreeSoloMulti
              value={value}
              onChange={onChange}
              context={workflowCodeInputContext}
              placeholder="Add"
            />
          )}
        />
      </PropertyPanelSection>
      <PropertyPanelSection title={'BCC'}>
        <NvField<undefined | (Omit<ListValue, 'value'> & { value?: StringValue[] })>
          name="bcc"
          formControlStyle={{ width: '100%' }}
          component={({ value, onChange }) => (
            <SideGroupFreeSoloMulti
              value={value}
              onChange={onChange}
              context={workflowCodeInputContext}
              placeholder="Add"
            />
          )}
        />
      </PropertyPanelSection>
      <PropertyPanelSection title={'Reply to'}>
        <NvField
          name="replyTo"
          formControlStyle={{ width: '100%' }}
          format={(value: StringValue | undefined) => value?.value}
          parse={(value: string) => {
            return value ? { type: ValueTypes.STRING, value: value } : undefined;
          }}
          component={<CodeInput context={workflowCodeInputContext} />}
        />
      </PropertyPanelSection>
    </NvFlex>
  );
};
