import { useUpdateAppDependencyInstance } from '@novaera/actioner-service';

export const useUpdateDependencyItem = () => {
  const { mutate: updateDependencyItem, isLoading: isDependencyItemUpdateLoading } = useUpdateAppDependencyInstance();

  return {
    updateDependencyItem,
    isDependencyItemUpdateLoading,
  };
};
