import { MediaTypes } from '@novaera/actioner-service';

export const MEDIA_TYPE_LABELS: Record<MediaTypes, string> = {
  [MediaTypes.MULTIPART_FORM_DATA]: 'Form Data',
  [MediaTypes.APPLICATION_XML]: 'XML',
  [MediaTypes.APPLICATION_XHTML_XML]: 'XHTML+XML',
  [MediaTypes.APPLICATION_JSON]: 'JSON',
  [MediaTypes.APPLICATION_JSON_PATCH_JSON]: 'JSON-patch+JSON',
  [MediaTypes.APPLICATION_FORM_URLENCODED]: 'Form URL Encoded',
  [MediaTypes.TEXT_PLAIN]: 'Text',
  [MediaTypes.TEXT_XML]: 'Text XML',
  [MediaTypes.TEXT_HTML]: 'Text HTML',
};
