import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DataModel, DeleteDraftDataModelParams, GetDataModelResponse, GetDataModelsResponse } from '../common/types';
import { DATA_MODEL_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DATA_MODEL } from '../keys';

const deleteDraftDataModel: (params: DeleteDraftDataModelParams) => Promise<DataModel> = async (params) => {
  const { appId, id } = params;
  const result = await NvAxios.delete<DataModel>(`${DATA_MODEL_ROOT_PATH(appId)}/${id}/drafts`);
  return result?.data;
};

export const useDeleteDraftDataModel = () => {
  const cache = useQueryClient();
  return useMutation(deleteDraftDataModel, {
    onSuccess: (savedDraftModel, { appId, id }) => {
      cache.setQueryData<GetDataModelResponse>(QUERY_KEYS_DATA_MODEL.detail(appId, id), { saved: savedDraftModel });
      cache.setQueryData<GetDataModelsResponse>(QUERY_KEYS_DATA_MODEL.list(appId), (old) =>
        old
          ? {
              ...old,
              models: old.models.map((model) =>
                model.id === savedDraftModel.id
                  ? { ...model, hasDraft: false, numberOfFields: savedDraftModel.fields.length }
                  : model
              ),
            }
          : undefined
      );
    },
  });
};
