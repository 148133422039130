import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  CodeInput,
  FieldTitle,
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvConditionalWrap,
  NvDeleteOutlineIcon,
  NvFlex,
  NvTextField,
  Portal,
} from '@novaera/core';
import { FC } from 'react';
import {
  PropertyPanelEmptySection,
  PropertyPanelListHeader,
  PropertyPanelSection,
} from '../../../../../../../../../../../components';
import { KeyValueTableRowsProps } from './types';
import { useKeyValueTableRows } from './use-key-value-table-rows';

export const KeyValueTableRows: FC<React.PropsWithChildren<KeyValueTableRowsProps>> = ({
  keyValueTableRows,
  onChange,
}) => {
  const {
    handleKeyValueTableRowNameChange,
    handleKeyValueTableRowRemoved,
    handleDataSourceValueChange,
    handleDragEnd,
    handleNewKeyValueTableRowAdded,
  } = useKeyValueTableRows({ keyValueTableRows, onChange });
  return (
    <>
      <PropertyPanelListHeader
        title="COLUMNS"
        tooltip="Add columns to display data in your table"
        actions={
          <NvButton size="small" color="ghost" onlyIcon variant="contained" onClick={handleNewKeyValueTableRowAdded}>
            <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
          </NvButton>
        }
      />
      {!keyValueTableRows?.length && <PropertyPanelEmptySection emptyText="There is no column." />}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`data_columns`}>
          {(provided) => (
            <NvBox ref={provided.innerRef}>
              {keyValueTableRows?.map((keyValueTableRow, index) => {
                return (
                  <Draggable key={`draggable_${index}`} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                      <NvConditionalWrap
                        condition={snapshot.isDragging}
                        wrap={(children) => <Portal>{children}</Portal>}
                      >
                        <NvBox ref={provided.innerRef} {...provided.draggableProps} position="relative">
                          <PropertyPanelSection
                            title={`Column ${index + 1}`}
                            collapsible
                            draggable
                            dragHandleProps={provided.dragHandleProps}
                            actions={
                              <NvButton
                                size="small"
                                onlyIcon
                                color="ghost"
                                onClick={() => handleKeyValueTableRowRemoved(index)}
                              >
                                <NvDeleteOutlineIcon />
                              </NvButton>
                            }
                            dragging={snapshot.isDragging}
                          >
                            <NvFlex gap="8px" direction="column">
                              <NvFlex direction="row" gap="8px" alignItems="flex-start">
                                <FieldTitle
                                  direction="label-on-side"
                                  labelText="Column title"
                                  labelVariant="h6"
                                  labelWidth="90px"
                                />
                                <NvTextField
                                  value={keyValueTableRow.name}
                                  onChange={handleKeyValueTableRowNameChange(index)}
                                  sx={{ flex: '1 1 auto' }}
                                />
                              </NvFlex>
                              <NvFlex direction="row" gap="8px" alignItems="flex-start">
                                <FieldTitle
                                  direction="label-on-side"
                                  labelText="Value"
                                  labelVariant="h6"
                                  labelWidth="90px"
                                />
                                <NvFlex flex="1 1 auto" minWidth={0}>
                                  <CodeInput
                                    value={keyValueTableRow.dataSource.value}
                                    onChange={handleDataSourceValueChange(index)}
                                    context={{}}
                                  />
                                </NvFlex>
                              </NvFlex>
                            </NvFlex>
                          </PropertyPanelSection>
                        </NvBox>
                      </NvConditionalWrap>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </NvBox>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
