import { NvBox, NvChevronRightIcon, NvCollapse, NvFlex, NvRouterLink, NvTypography } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledNvChevronRightIcon = styled(NvChevronRightIcon)`
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  width: 16px;
  height: 16px;
`;
export const SideMenuWrapper = styled(NvCollapse)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  transition-duration: 300ms;
  min-width: 24px;
  width: auto;
`;

export const SideMenuInner = styled(NvFlex)`
  opacity: 0;
  transition: opacity 300ms ease;
  width: 380px;
  padding: 0 16px 60px 16px;
  gap: 8px;
  pointer-events: none;
  height: 100%;
  overflow: auto;
  &.isOpen {
    pointer-events: initial;
    opacity: 1;
  }

  transition: width 300ms ease;

  @media screen and (max-width: 1440px) {
    width: 280px;
  }
`;

export const ChevronFrame = styled(NvBox)`
  position: absolute;
  top: 32px;
  right: -8px;
  z-index: 11;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 16px;
  height: 16px;

  background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
  border-radius: 76px;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  cursor: pointer;

  transition: transform 300ms ease, background-color 300ms ease, rotate 400ms ease, right 300ms ease;

  &.isOpen {
    right: 16px;
    .opener {
      transform: rotate(180deg);
    }
  }
`;

export const SideMenuFrame = styled(NvBox)`
  position: relative;
  height: 100%;
`;

export const SideMenuDivider = styled(NvBox)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
`;

export const SideMenuSubMenuList = styled(NvBox)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0px;
  gap: 8px;
  margin: 8px 0;
`;

export const SideMenuSubMenuItem = styled(NvRouterLink, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px 0 8px;
  gap: 8px;
  height: 40px;
  border-radius: 6px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  &:hover {
    ${({ selected, theme: { palette } }) =>
      `background-color:${selected ? palette.nv_main[20] : palette.nv_neutral[10]};`}
  }

  ${({ selected, theme: { palette } }) => selected && `background-color:${palette.nv_main[20]};`}
`;

export const SideMenuSubMenuItemLabel = styled(NvTypography)`
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const SideMenuTitleFrame = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'hasNoBorderBottom' })<{
  hasNoBorderBottom?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 16px 24px 0px;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  position: sticky;
  top: 0;
  z-index: 1;
  ${({ theme, hasNoBorderBottom }) => (hasNoBorderBottom ? `border-color: ${theme.palette.nv_neutral[0]};` : null)}
`;

export const SideMenuItemFrameDivider = styled(NvBox)`
  padding: 10px 4px;
  gap: 10px;
  height: 24px;
  flex: 0 0 auto;
  min-width: 0;
`;

export const SideMenuItemFrameMain = styled(NvRouterLink, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 0px 4px;
  gap: 4px;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  flex: 0 0 auto;
  min-width: 0;
  cursor: pointer;
  transition: background-color 300ms ease-in;
  ${({ selected, theme: { palette } }) => selected && `background-color:${palette.nv_main[20]};`}
  &:hover {
    ${({ selected, theme: { palette } }) =>
      `background-color:${selected ? palette.nv_main[20] : palette.nv_neutral[10]};`}
  }
`;
