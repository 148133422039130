import { DefaultRenderOption, NvAutocomplete, NvFlex, NvTextField, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import { SyntheticEvent } from 'react';
import { DynamicMaterialIcons } from '../../dynamic-material-icons';
import { IconListType } from '../../dynamic-material-icons/types';
import { isIconListType } from '../../dynamic-material-icons/utils';
export const EntityTypeIconAutoComplete = ({
  onChange,
  value,
  defaultValue,
  prefetchIcons,
}: {
  value?: string;
  defaultValue?: string;
  onChange?: (e: SyntheticEvent<Element, Event>, value: string) => void;
  prefetchIcons?: boolean;
}) => {
  return (
    <DynamicMaterialIcons initialValue={value} prefetchIcons={prefetchIcons}>
      {({ icons, iconsLoading, getCurrentIcon, onSearch, renderIcon }) => (
        <NvAutocomplete<IconListType, false, true, true>
          freeSolo={true}
          defaultValue={defaultValue}
          loading={iconsLoading}
          isOptionEqualToValue={(option, value) => {
            return option.name === (isIconListType(value) ? value.name : value);
          }}
          value={value}
          filterOptions={(options, state) => options}
          disableClearable={true}
          onInputChange={(e, value) => {
            onSearch(value);
          }}
          onChange={(e, value) => {
            assert(typeof value !== 'string', new Error('value should be IconListType'));
            onChange?.(e, value?.name ?? '');
          }}
          renderInput={(params) => {
            const Icon = getCurrentIcon();
            return (
              <NvTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                }}
                {...(Icon && {
                  startIcon: <Icon />,
                })}
                size="medium"
              />
            );
          }}
          getOptionLabel={(option) => {
            return isIconListType(option) ? option.name : option;
          }}
          renderOption={(props, option) => {
            const Icon = renderIcon(option);
            return (
              <DefaultRenderOption {...props} key={option.name}>
                <NvFlex direction="row" alignItems="center" gap="8px">
                  {Icon && <Icon />}
                  <NvTypography variant="body1">{option.name}</NvTypography>
                </NvFlex>
              </DefaultRenderOption>
            );
          }}
          options={icons}
        />
      )}
    </DynamicMaterialIcons>
  );
};
