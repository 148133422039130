import {
  DataColumn,
  OutputNode,
  TableOutputComponent,
  useUpdateNode,
  WorkflowReferenceColumn,
  WorkflowState,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvConditionalRender,
  NvCustomShareIcon,
  NvField,
  NvFlex,
  NvForm,
  NvSwitch,
  NvTypography,
} from '@novaera/core';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import { FC, useCallback } from 'react';
import { PropertyPanelSection, PropertyPanelSimpleSection } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { OutputOrder } from '../common/output-order';
import { Columns } from './columns';
import { TablePanelSectionProps } from './types';

export const TablePanelSection: FC<React.PropsWithChildren<TablePanelSectionProps>> = ({
  tableOutputComponent,
  alias,
  name,
  userAppId,
  workflowId,
}) => {
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { hasEditPermission } = useWorkflowPermission();

  const handleSave = useCallback(
    (newTableOutputComponent: TableOutputComponent) => {
      const { columns, dataSource, ...otherProps } = newTableOutputComponent;
      const constructedTableOutputComponent: TableOutputComponent = {
        columns: columns?.map((column) => {
          if (column.type === 'data') {
            const dataColumn: DataColumn = {
              type: 'data',
              dataSource: column?.dataSource,
              name: column?.name,
            };
            return dataColumn;
          } else {
            const workflowReferenceColumn: WorkflowReferenceColumn = {
              type: 'workflow-reference',
              workflowReferences: column?.workflowReferences,
              name: column?.name,
            };
            return workflowReferenceColumn;
          }
        }),
        dataSource: { type: 'javascript', value: dataSource?.value },
        ...otherProps,
      };
      const newNode: OutputNode = {
        alias,
        name,
        outputComponent: constructedTableOutputComponent,
        type: 'output',
        state: WorkflowState.DRAFT,
      };

      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: alias,
        payload: newNode,
      });
    },
    [alias, name, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvForm<TableOutputComponent>
      onSubmit={noop}
      onChange={({ values }) => {
        handleSave(values);
      }}
      initialValues={tableOutputComponent}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <PropertyPanelSection title="Table source">
        <NvFlex gap="8px" direction="column">
          <NvField
            name="dataSource.value"
            component={<CodeInput context={workflowCodeInputContext} />}
            defaultValue="{{ }}"
          />
        </NvFlex>
      </PropertyPanelSection>
      <NvFlex>
        <NvField
          name="columns"
          component={<Columns context={workflowCodeInputContext} />}
          allowNull
          format={(value) => value}
          parse={(value) => value}
        />
      </NvFlex>
      <NvConditionalRender when={hasEditPermission}>
        <OutputOrder />
      </NvConditionalRender>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelSimpleSection>
          <NvBox width={'100%'}>
            <NvField
              formControlStyle={{ width: '100%' }}
              name="showShareButton"
              type="checkbox"
              component={({ onChange, checked, value }) => {
                return (
                  <NvFlex flexDirection={'row'} alignItems={'center'}>
                    <NvFlex direction={'row'} gap={'8px'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
                      <NvCustomShareIcon />
                      <NvTypography variant="body2">Show share button</NvTypography>
                    </NvFlex>
                    <NvSwitch value={value} onChange={onChange} checked={checked} variant="compact" />
                  </NvFlex>
                );
              }}
            />
          </NvBox>
        </PropertyPanelSimpleSection>
      </NvConditionalRender>
    </NvForm>
  );
};
