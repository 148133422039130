import {
  AppSchemasPayload,
  AppSchemasQuery,
  AppSchemasQueryOperation,
  AppSchemasQueryType,
  useGetAppDirectorySearchMetadata,
  useGetAppSchemas,
} from '@novaera/actioner-service';
import { useQueryParams } from '@novaera/route';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ALL_PACKAGES_SEARCH_SCHEMA, INITIAL_SEARCH_SCHEMA_BODY } from '../../../constants';
import { getSearchSchemeBody } from '../../../utils';
import { AppDirectoryMenuItem } from '../../app-directory-menu/types';

export const useAppDirectoryList = ({ isAnonymous }: { isAnonymous: boolean }) => {
  const searchTextFieldRef = useRef<HTMLDivElement>(null);
  const { removeQueryParams } = useQueryParams();
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [searchQueries, setSearchQueries] = useState<AppSchemasQuery[]>([]);
  const [selectedItem, setSelectedItem] = useState<AppDirectoryMenuItem | undefined>();
  const [searchQuerySchemeBody, setSearchQuerySchemeBody] = useState<AppSchemasPayload | undefined>();
  const [isAppDirectoryFilterMenuOpen, setIsAppDirectoryFilterMenuOpen] = useState<boolean>(false);
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const {
    data: getAppSchemasQueries,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAppSchemas({
    payload: searchQuerySchemeBody,
  });

  const getAppSchemasPages = getAppSchemasQueries?.pages.filter((p) => p);
  const { data: searchMetadataResult, isLoading: isSearchMetadataLoading } = useGetAppDirectorySearchMetadata({
    isAnonymous,
  });

  const updateSearchSchemaBody = useCallback(
    (updateType: 'add' | 'delete' | 'override', query?: AppSchemasQuery, menuItem?: AppDirectoryMenuItem) => {
      let updatedQueries: AppSchemasQuery[] = [];

      if (query) {
        if (updateType === 'add') {
          if (searchQueries.length > 0) {
            if (query.type === AppSchemasQueryType.KEYWORD) {
              updatedQueries = [...searchQueries.filter((query) => query.type !== AppSchemasQueryType.KEYWORD), query];
            } else {
              updatedQueries = [
                ...searchQueries.filter(
                  (query) =>
                    query.type === AppSchemasQueryType.KEYWORD || query.value === (menuItem ?? selectedItem)?.value
                ),
                query,
              ];
            }
          } else {
            // First add
            updatedQueries = [query];
          }
        } else if (updateType === 'delete') {
          updatedQueries = searchQueries.filter((q) => q.value !== query.value);
        } else if (updateType === 'override') {
          updatedQueries = [query];
        }

        // Clean empty string keyword query if it is exist.
        updatedQueries = updatedQueries.filter((q) => !(q.type === AppSchemasQueryType.KEYWORD && q.value === ''));
      }

      setSearchQueries(updatedQueries);

      ALL_PACKAGES_SEARCH_SCHEMA.queries = updatedQueries;

      const searchSchemaBody = getSearchSchemeBody(ALL_PACKAGES_SEARCH_SCHEMA, INITIAL_SEARCH_SCHEMA_BODY);

      setSearchQuerySchemeBody(searchSchemaBody);
    },
    [selectedItem, searchQueries]
  );

  useEffect(() => {
    updateSearchSchemaBody('add', {
      operation: AppSchemasQueryOperation.CONTAINS,
      type: AppSchemasQueryType.KEYWORD,
      value: debouncedSearchValue ?? '',
    });
  }, [debouncedSearchValue]);

  const onSearchValueChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const onAppDirectoryMenuItemSelect = (menuItem: AppDirectoryMenuItem) => {
    if (menuItem.value === 'all' || menuItem.value === 'clear-filters') {
      if (searchValue?.length === 0) {
        updateSearchSchemaBody('override', menuItem.query, menuItem);
      } else {
        setSearchQueries([]);
        setSearchValue('');
      }
    } else {
      updateSearchSchemaBody('add', menuItem.query, menuItem);
    }

    setSelectedItem(menuItem.value === 'clear-filters' ? undefined : menuItem);
  };

  const shouldUpdateSearchSchemeBody = useCallback(
    (type?: AppSchemasQueryType) =>
      type === AppSchemasQueryType.TAG || type === AppSchemasQueryType.CATEGORY || type === AppSchemasQueryType.OWNER,
    []
  );

  const onAppDirectoryMenuItemDeselect = (menuItem: AppDirectoryMenuItem) => {
    if (menuItem.query?.type === AppSchemasQueryType.KEYWORD) {
      setSearchValue('');
    }
    if (shouldUpdateSearchSchemeBody(menuItem.query?.type)) {
      updateSearchSchemaBody('delete', menuItem.query);
    }
    setSelectedItem(undefined);
  };

  const onSearchQueryDelete = (deletedQuery: AppSchemasQuery) => {
    if (deletedQuery.type === AppSchemasQueryType.KEYWORD) {
      setSearchValue('');
    } else if (shouldUpdateSearchSchemeBody(deletedQuery.type)) {
      updateSearchSchemaBody('delete', deletedQuery);
      setSelectedItem(undefined);
      removeQueryParams('category');
    }
  };

  return {
    schemasPages: getAppSchemasPages,
    isSchemasLoading: isInitialLoading,
    hasNoAppSchemas:
      !getAppSchemasPages ||
      (getAppSchemasPages && getAppSchemasPages.length === 1 && getAppSchemasPages[0].result.schemas.length === 0),
    fetchNextSchemasPage: fetchNextPage,
    hasNextSchemasPage: hasNextPage,
    isFetchingNextSchemasPage: isFetchingNextPage,
    searchValue,
    searchQueries,
    selectedItem,
    onSearchValueChange,
    onAppDirectoryMenuItemSelect,
    onAppDirectoryMenuItemDeselect,
    onSearchQueryDelete,
    searchMetadata: searchMetadataResult,
    isAppDirectoryFilterMenuOpen,
    setIsAppDirectoryFilterMenuOpen,
    searchTextFieldRef,
    isSearchMetadataLoading,
  };
};
