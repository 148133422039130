import { useGetWorkflow, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import {
  NvBox,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvMenuWithItems,
  NvMoreHorizIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';

import { useMemo } from 'react';
import { useInputsContext } from '../../../../../../../../../action-designer/providers/input-provider';
import { PropertyConfiguration } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { PropertiesLoading } from '../../../common/properties-loading';
import { FormTriggerInputParameterDetailProps } from './types';

export const FormTriggerInputParameterDetail: React.FC<
  React.PropsWithChildren<FormTriggerInputParameterDetailProps>
> = ({ handleDelete, onCloseClicked, onDataSourceIndexChanged, dataSourceIndex }) => {
  const theme = useTheme();
  const { mutate: saveToDraftWorkflow } = useSaveToDraftWorkflow();
  const { selectedInputParameterId } = useInputsContext();
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ workflowId, appId: userAppId });
  const { hasEditPermission } = useWorkflowPermission();

  assert(
    workflow?.trigger?.type === 'form',
    new Error(`[FormTriggerInputParameterDetail] - type should be form, but it is ${workflow?.trigger?.type}`),
    'ERROR'
  );

  const inputParameter = useMemo(
    () =>
      workflow?.trigger?.type === 'form'
        ? workflow.trigger.inputParameters.find((i) => i.id === selectedInputParameterId)
        : undefined,
    [selectedInputParameterId, workflow?.trigger.inputParameters, workflow?.trigger.type]
  );

  const handleDeleteLocal = ({ selectedInputParameterId }: { selectedInputParameterId: string }) => {
    if (!hasEditPermission) {
      return;
    }

    if (workflow && workflow.trigger?.type === 'form') {
      saveToDraftWorkflow({
        ...workflow,
        trigger: {
          ...workflow.trigger,
          inputParameters: workflow.trigger.inputParameters.filter((i) => i.id !== selectedInputParameterId),
        },
      });
    }
  };

  return inputParameter ? (
    <NvBox width="100%" height="100%">
      <NvConditionalRender when={hasEditPermission}>
        <PropertyConfiguration
          type="form-trigger"
          key={selectedInputParameterId}
          configuredEntity={{ type: 'form-trigger-input-parameter', inputParameter }}
          headerActions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      handleDelete();
                      if (selectedInputParameterId) {
                        handleDeleteLocal({ selectedInputParameterId });
                      }
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
          onDataSourceIndexChanged={onDataSourceIndexChanged}
          dataSourceIndex={dataSourceIndex}
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyConfiguration
          type="form-trigger"
          key={selectedInputParameterId}
          configuredEntity={{ type: 'form-trigger-input-parameter', inputParameter }}
          headerActions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
          onDataSourceIndexChanged={onDataSourceIndexChanged}
          dataSourceIndex={dataSourceIndex}
        />
      </NvConditionalRender>
    </NvBox>
  ) : (
    <PropertiesLoading />
  );
};
