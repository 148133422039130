import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomStoreIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.155 18.5959C7.94288 18.5959 8.58838 19.2552 8.58838 20.0695C8.58838 20.8742 7.94288 21.5334 7.155 21.5334C6.35762 21.5334 5.71212 20.8742 5.71212 20.0695C5.71212 19.2552 6.35762 18.5959 7.155 18.5959ZM17.8342 18.5959C18.6221 18.5959 19.2676 19.2552 19.2676 20.0695C19.2676 20.8742 18.6221 21.5334 17.8342 21.5334C17.0368 21.5334 16.3913 20.8742 16.3913 20.0695C16.3913 19.2552 17.0368 18.5959 17.8342 18.5959ZM2.73931 2.53356L2.83605 2.54175L5.10004 2.88979C5.42279 2.94893 5.6601 3.21942 5.68858 3.54904L5.86894 5.72069C5.89742 6.03189 6.14423 6.26457 6.44799 6.26457H19.2677C19.8468 6.26457 20.2265 6.46816 20.6062 6.91412C20.9859 7.36008 21.0524 7.99994 20.9669 8.58066L20.0651 14.9405C19.8943 16.163 18.8691 17.0636 17.6635 17.0636H7.30707C6.04455 17.0636 5.00037 16.0757 4.89595 14.796L4.02263 4.22768L2.58925 3.97561C2.20954 3.90775 1.94375 3.52965 2.0102 3.14186C2.07665 2.74534 2.44686 2.48261 2.83605 2.54175L2.73931 2.53356ZM16.1447 9.85069H13.5152C13.1165 9.85069 12.8033 10.1706 12.8033 10.5778C12.8033 10.9753 13.1165 11.3049 13.5152 11.3049H16.1447C16.5434 11.3049 16.8566 10.9753 16.8566 10.5778C16.8566 10.1706 16.5434 9.85069 16.1447 9.85069Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
