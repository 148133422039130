/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactElement } from 'react';
import { NvFlex } from '../../flex';
import { NvImage } from '../../profile-image';
import { NvTypography } from '../../typography';
import { NodeListGroupItemCard } from './styled';
import { NodeListGroupItemProps } from './types';

export const NodeListGroupItem: React.FC<React.PropsWithChildren<NodeListGroupItemProps>> = ({
  name,
  icon,
  leftSlot,
}) => (
  <NodeListGroupItemCard>
    {leftSlot}
    <NvFlex direction="row" alignItems="center" gap="2px">
      {icon &&
        (React.isValidElement(icon) && typeof icon !== 'string' ? (
          React.cloneElement(icon as ReactElement, { sx: { width: '16px', height: '16px' } })
        ) : (
          <NvImage imageShape="square" src={icon as string} size="smaller" hasNoElevation FallBack={<></>} />
        ))}

      <NvTypography variant="h5">{name}</NvTypography>
    </NvFlex>
  </NodeListGroupItemCard>
);
