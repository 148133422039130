import { NvBox, NvKeyValueInput, TypeOfTab } from '@novaera/core';
import { useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { RequestCustomInput } from '../request-custom-input';

import { IntegrationAction, useGetConnectionSchemaService, useGetIntegration } from '@novaera/actioner-service';
import { HTTP_METHODS } from '@novaera/constants';
import { useGetIntegrationQueryParams } from '../../../../../../../controllers/use-get-integration-query-params';
import { useRequestContext } from '../../../../../../providers/request-provider';
import { useRequestActionMenu } from '../../../controllers/use-request-action-menu';
import { useResponseResult } from '../../response-result/controllers/use-response-result';
import { RequestBody } from '../request-body';
import { RequestSettings } from '../request-settings';

export const useAPIRequestDetail = () => {
  const { integrationId } = useGetIntegrationQueryParams();
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(true);

  const { actionButtons } = useRequestActionMenu();
  const { handleRequestTest, executionResponse, responseError, responseContent, isLoading } = useResponseResult();

  const { selectedRequestId } = useRequestContext();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data: connectionSchema } = useGetConnectionSchemaService({
    integrationId,
    schemaId: integration?.connectionSchemaId,
  });

  const {
    values: { httpRequestConfigurations },
  } = useFormState<Pick<IntegrationAction, 'httpRequestConfigurations'>>();

  const { selectedRequest, selectedRequestIndex } = useMemo(() => {
    return {
      selectedRequest: httpRequestConfigurations?.find((r) => r.id === selectedRequestId),
      selectedRequestIndex: httpRequestConfigurations?.findIndex((r) => r.id === selectedRequestId) ?? 0,
    };
  }, [httpRequestConfigurations, selectedRequestId]);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Params',
        content: (
          <NvBox paddingTop={1.5}>
            <NvKeyValueInput
              key="query-params"
              addNewInputLabel="Add new parameter"
              component={<RequestCustomInput />}
              fieldArrayName={`httpRequestConfigurations[${selectedRequestIndex}].queryParameters`}
              hasRemoveAction
              keyTitle="KEY"
              valueTitle="VALUE"
              keyFieldName="key"
              valueFieldName={'valueGenerationTemplate'}
            />
          </NvBox>
        ),
      },
      {
        label: 'Headers',
        value: 'headers',
        content: (
          <NvBox paddingTop={1.5}>
            <NvKeyValueInput
              key="header-params"
              addNewInputLabel="Add new header"
              component={<RequestCustomInput />}
              fieldArrayName={`httpRequestConfigurations[${selectedRequestIndex}].headerParameters`}
              hasRemoveAction
              keyTitle="KEY"
              valueTitle="VALUE"
              keyFieldName="key"
              valueFieldName={'valueGenerationTemplate'}
            />
          </NvBox>
        ),
      },
      {
        label: 'Body',
        value: 'body',
        content: selectedRequest && (
          <RequestBody name={`httpRequestConfigurations[${selectedRequestIndex}]`} request={selectedRequest} />
        ),
      },
      {
        label: 'Settings',
        value: 'settings',
        content: selectedRequest && (
          <RequestSettings
            requestFieldName={`httpRequestConfigurations[${selectedRequestIndex}]`}
            requestIndex={selectedRequestIndex}
          />
        ),
        iconPosition: 'end' as TypeOfTab['iconPosition'],
      },
    ];
  }, [selectedRequest, selectedRequestIndex]);

  return {
    request: selectedRequest,
    connectionSchema,
    tabs,
    requestIndex: selectedRequest && selectedRequestIndex,
    isDetailOpen,
    httpMethods: HTTP_METHODS,
    executionResponse,
    responseError,
    responseContent,
    isLoading,
    handleRequestTest,
    setIsDetailOpen,
    actionButtons,
    hasConnectionSchema: selectedRequest?.useConnectionSchema,
  };
};
