import {
  CatalogEntityType,
  useCreateCatalogEntityType,
  useCreateCatalogRelationship,
  useDeleteCatalogEntityType,
  useUpdateCatalogEntityType,
} from '@novaera/actioner-service';
import { useNvDialogModalState } from '@novaera/core';
import { CreateRelationshipForm } from '../../body/types';
import { EntityTypeFormTypes } from '../../types';
import { useCatalogUtil } from '../use-catalog-util';

export const useEntityTypeController = () => {
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const { navigateToCatalogEntityType } = useCatalogUtil();

  const { mutate: createEntityType } = useCreateCatalogEntityType({
    onSuccess: (data) => {
      onModalCloseClicked();
      navigateToCatalogEntityType(data.id);
    },
  });

  const { mutate: createCatalogRelationship } = useCreateCatalogRelationship();

  const { mutate: deleteCatalogEntityType, isLoading: isDeleteCatalogEntityTypeLoading } = useDeleteCatalogEntityType();

  const { mutate: updateEntityType, isLoading: isUpdateEntityTypeLoading } = useUpdateCatalogEntityType();

  const handleFormSubmit = (values: EntityTypeFormTypes) => {
    createEntityType({ payload: values });
  };

  const handleUpdateEntityType = (values: CatalogEntityType) => {
    updateEntityType({ id: values.id, payload: { entityType: values } });
  };

  const handleCreateRelationshipFormSubmit = (values: CreateRelationshipForm) => {
    createCatalogRelationship({ payload: values });
  };

  return {
    onFormSubmit: handleFormSubmit,
    onAddClicked: onModalOpenClicked,
    onCreateModalClosed: onModalCloseClicked,
    isCreateModalOpen: isOpened,
    isDeleteCatalogEntityTypeLoading,
    deleteCatalogEntityType,
    onUpdateEntityType: handleUpdateEntityType,
    isUpdateEntityTypeLoading,
    onCreateRelationshipFormSubmit: handleCreateRelationshipFormSubmit,
  };
};
