import { NvAxios, useMutation } from '@novaera/core';
import { STRIPE_SESSION_ROOT_PATH } from '../constants';
import { BillingCycle } from '../types';
import { SessionResponse } from './types';

const createCheckoutSession = async ({ planId, billingCycle }: { planId: string; billingCycle: BillingCycle }) => {
  const result = await NvAxios.post<SessionResponse>(`${STRIPE_SESSION_ROOT_PATH}/subscription`, {
    planId,
    billingCycle,
  });
  return result?.data;
};

export const useCreateCheckoutSession = () => {
  return useMutation(createCheckoutSession, {});
};
