import { NvAxios, useMutation, useToast } from '@novaera/core';
import { getSubdomain } from '@novaera/utils';
import { ROOT_ACCOUNT_PATH } from '../constants';
import { UpdateEmailParams } from './types';

const updateEmail = async (params: UpdateEmailParams) => {
  const result = await NvAxios.post(`${ROOT_ACCOUNT_PATH}/change-email`, { ...params, subdomain: getSubdomain() });
  return result?.data;
};

export const useEmailUpdate = () => {
  const { addToast } = useToast();

  return useMutation(updateEmail, {
    onSuccess: () => {
      addToast('Check your email address to complete email change', { variant: 'success' });
    },
    onError: () => {
      addToast('Email could not be updated', { variant: 'error' });
    },
  });
};
