import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AddNewButtonBox = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'isActive' })<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.palette.nv_main[40] : theme.palette.nv_neutral[0])};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 6px;
`;
