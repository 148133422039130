import { useParams } from '@novaera/route';
import { FC } from 'react';
import { BaseItem } from '../../../../../components/base-item';
import { USER_APP_DATABASE_DETAIL } from '../../../constants';
import { useTable } from '../use-table';
import { TableListItemProps } from './types';

export const TableListItem: FC<React.PropsWithChildren<TableListItemProps>> = ({
  onCloneItemClicked,
  dataModel: { description, id, name, hasDraft },
}) => {
  const { userAppId: appId } = useParams();
  const { modelMenuItems } = useTable({ source: 'list' });

  return (
    <BaseItem
      to={USER_APP_DATABASE_DETAIL(appId, id)}
      name={name}
      description={description}
      isDraft={hasDraft}
      menuItems={modelMenuItems({ name, modelId: id }, { onCloneItemClicked })}
    />
  );
};
