import { NvBox, NvButton } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { StyledInputParameter } from '../input-parameter/styled';

export const StyledTestActionButtonWrapper = styled(NvButton)<{ isSelected: boolean }>`
  &.MuiButtonBase-root {
    &.custom {
      min-width: 120px;
      background-color: ${({ theme, isSelected }) => (isSelected ? theme.palette.nv_neutral_alpha[30] : 'transparent')};
      color: ${({ theme }) => theme.palette.nv_neutral_alpha[500]};
      border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[500]};
      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
      }
    }
    &.MuiLoadingButton-loading {
      &.custom {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
        color: transparent;
      }
      &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      }

      & .MuiLoadingButton-loadingIndicator {
        color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      }
    }
  }
`;

export const StyledCommandExecute = styled(StyledInputParameter)`
  flex: 1 1 auto;
`;

export const CommandExecuteWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const RequestExecutionWrapper = styled(NvBox)`
  margin-top: 32px;
  padding: 24px 0;
  display: flex;
  border-top: 1px dashed ${({ theme }) => theme.palette.nv_neutral[30]};
  position: relative;
  flex-direction: column;
  gap: 14px;
`;

export const ExecutionLabel = styled(NvBox)`
  position: absolute;
  top: -9px;
  left: 10px;
`;
