import { useGetAppSchemaPreviews, useGetFreeApps } from '@novaera/actioner-service';
import { NvButton, NvCarouselBase, NvCustomGiftIcon, NvImage, NvSkeleton, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAppSchemaUrl } from '../../../app-directory/utils';
import { FreeAppBody, FreeAppBox, FreeAppHeader, FreeAppWrapper, StyledImage } from './styled';

export const FreeAppBanner: FC = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { data } = useGetFreeApps();
  const appSchemaQueries = useGetAppSchemaPreviews({ schemaIds: data?.['free-app-schema-ids'] ?? [] });
  const isAllAppSchemaPreviewFetched = !!appSchemaQueries.find((query) => query.isFetched);
  return (
    <FreeAppWrapper>
      <FreeAppBox>
        <FreeAppHeader>
          <NvCustomGiftIcon
            data-testid="trial-menu-button"
            sx={{ width: '12px', height: '12px' }}
            htmlColor={palette.nv_new[60]}
          />
          <NvTypography variant="h7" color={palette.nv_neutral[0]}>
            FREE APPS
          </NvTypography>
        </FreeAppHeader>
        {!isAllAppSchemaPreviewFetched ? (
          <FreeAppBody>
            <NvSkeleton variant="rectangular" width="60px" height="60px" />
            <NvSkeleton variant="rectangular" width="48px" height="24px" />
            <NvSkeleton variant="rectangular" width="72px" height="24px" />
          </FreeAppBody>
        ) : (
          <NvCarouselBase
            showArrows={false}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay
            autoFocus
            infiniteLoop
            interval={3000}
          >
            {appSchemaQueries.map(({ data }) => (
              <FreeAppBody key={data.schemaId}>
                <StyledImage width="108px" height="108px" src={data?.logoUrl}></StyledImage>
                <NvImage size={'xlarge'} src={data?.logoUrl} imageShape="square" hasNoElevation FallBack={''} />
                <NvTypography variant="h2">{data?.packageName}</NvTypography>
                <NvButton
                  color="secondary"
                  size="small"
                  onClick={() => {
                    const url = getAppSchemaUrl({ name: data.packageName, schemaId: data.schemaId });
                    navigate(url);
                  }}
                  sx={{ marginTop: '12px' }}
                >
                  Install app
                </NvButton>
              </FreeAppBody>
            ))}
          </NvCarouselBase>
        )}
      </FreeAppBox>
    </FreeAppWrapper>
  );
};
