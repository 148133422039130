import { FieldType, InputParameters } from '@novaera/actioner-service';
import { assert, noop } from '@novaera/utils';
import { FC, PropsWithChildren, createContext, useContext } from 'react';
import { useGenerateInputParametersFromRecordModel } from '../../../../../../../../database/utils';

const RecordParametersContext = createContext<
  | {
      recordParameters: InputParameters;
      recordFields: FieldType[];
      isRecordParametersLoading: boolean;
    }
  | undefined
>(undefined);

export const RecordParametersProvider: FC<PropsWithChildren<{ appId?: string; modelId?: string }>> = ({
  children,
  appId,
  modelId,
}) => {
  const { inputParameters, fields, isLoading } = useGenerateInputParametersFromRecordModel({
    appId,
    modelId,
    retry: false,
    onError: noop,
  });

  const value = {
    recordParameters: inputParameters,
    recordFields: fields,
    isRecordParametersLoading: isLoading,
  };

  return <RecordParametersContext.Provider value={value}>{children}</RecordParametersContext.Provider>;
};

export const useRecordParametersContext = () => {
  const context = useContext(RecordParametersContext);
  assert(!!context, new Error(`useRecordParametersContext should be used within RecordParametersProvider`), 'ERROR');
  return context;
};
