import { NvFlex } from '@novaera/core';
import { HTTPBodySubPanel } from './http-body-sub-panel';
import { HTTPExampleResponseSubPanel } from './http-example-response-sub-panel';
import { HTTPKeyValueSubPanel } from './http-key-value-sub-panel';
import { HTTPPropertiesSubPanelProps } from './types';

export const HTTPPropertiesSubPanel = ({ panelType, ...restOfParams }: HTTPPropertiesSubPanelProps) => {
  return (
    <NvFlex width="100%" height={'100%'}>
      {panelType === 'params' ? (
        <HTTPKeyValueSubPanel keyValueSubPanelType="queryParameters" {...restOfParams} />
      ) : panelType === 'headers' ? (
        <HTTPKeyValueSubPanel keyValueSubPanelType="headers" {...restOfParams} />
      ) : panelType === 'body' ? (
        <HTTPBodySubPanel {...restOfParams} />
      ) : panelType === 'example-response' ? (
        <HTTPExampleResponseSubPanel {...restOfParams} />
      ) : null}
    </NvFlex>
  );
};
