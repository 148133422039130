import { NvButton } from '../../button';
import { DefaultFooterProps } from './types';

export const DefaultFooter: React.FC<React.PropsWithChildren<DefaultFooterProps>> = ({
  isLoading,
  onClose,
  primaryButtonText,
  secondaryButtonText,
  form,
}) => {
  return (
    <>
      <NvButton
        {...(form && {
          onClick: () => {
            form.submit();
          },
        })}
        {...(!form && {
          type: 'submit',
        })}
        loading={isLoading}
        disabled={isLoading}
      >
        {primaryButtonText || 'Add'}
      </NvButton>
      <NvButton color="ghost" onClick={onClose} disabled={isLoading}>
        {secondaryButtonText || 'Cancel'}
      </NvButton>
    </>
  );
};
