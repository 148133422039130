import { useSandboxedTransform } from '@novaera/chart-data-engine';
import { generateUniqueId } from '@novaera/utils';
import { Instance, createPopper } from '@popperjs/core';
import { Annotation } from 'codemirror/addon/lint/lint';
import { isArray, replace } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { DomEvent } from '../../codemirror-wrapper';
import { MUSTACHE_REGEX } from '../../constants';
import { CodeInputProps, Context } from '../../types';
import { useCodeInputBaseController } from '../../use-code-input-base-controller';
import { isJsError, isTypeMatched } from '../../utils';

export const useCodeInputEvaluation = ({
  context,
  value,
  expectedType,
  onResultCalculated,
  onFocus,
  matchArrayItemTypes,
  setError,
  error,
}: {
  context: Context;
  value?: string;
  expectedType?: CodeInputProps['expectedType'];
  onResultCalculated?: ((isError: boolean, result?: unknown) => void) | ((isError: boolean) => void);
  onChange?: (value: string) => void;
  onFocus?: (event: CodeMirror.Editor) => void;
  onBlur?: (event: CodeMirror.Editor) => void;
  matchArrayItemTypes?: boolean;
} & Pick<ReturnType<typeof useCodeInputBaseController>, 'setError' | 'error'>) => {
  const [result, setResult] = useState<unknown>();
  const { sandboxedExpressionTransformer } = useSandboxedTransform();
  const [debouncedValue] = useDebounce(value || '', 500);

  useEffect(() => {
    (async () => {
      const regex = new RegExp(MUSTACHE_REGEX);
      const isInMustache = true;
      debouncedValue?.match(regex);
      const result = isInMustache
        ? await sandboxedExpressionTransformer(`${debouncedValue}`, context, true)
        : debouncedValue;

      setResult(result);
    })();
  }, [context, debouncedValue, sandboxedExpressionTransformer]);

  const informationBoxId = useMemo(() => `information-box-${generateUniqueId()}`, []);
  const [informationBoxWidth, setInformationBoxWidth] = useState<number>(0);

  // check this again it should be used for just information box.
  const popperRef = useRef<Instance | undefined>();

  const isResultTypeMatched = useMemo(() => {
    if (!expectedType) {
      // no expected type so everything will work
      return true;
    }
    if (isArray(expectedType)) {
      return expectedType.some((type) => {
        return isTypeMatched(type, result);
      });
    }
    if (matchArrayItemTypes && isArray(result)) {
      return result.every((item) => typeof item === expectedType);
    }

    return isTypeMatched(expectedType, result);
  }, [expectedType, matchArrayItemTypes, result]);

  const calculatedResult = useMemo(() => {
    const resultType = isArray(result)
      ? matchArrayItemTypes && result.length > 0
        ? typeof result[0]
        : 'array'
      : typeof result;

    if (isJsError(result)) {
      return {
        title: 'ERROR',
        body: result?.message,
        isError: true,
      };
    }

    try {
      if (!expectedType || isResultTypeMatched) {
        return {
          title: resultType,
          body:
            typeof result === 'string' || typeof result === 'boolean' || typeof result === 'number'
              ? result
              : result
              ? (() => {
                  try {
                    return JSON.stringify(result, undefined, 2);
                  } catch (error) {
                    return '';
                  }
                })()
              : `''`,
          isError: false,
          result,
        };
      } else {
        return {
          title: 'ERROR',
          body: `The expected type is '${
            isArray(expectedType) ? expectedType.join(' or ') : expectedType
          }' but you provided a value of type '${resultType}'`,
          isError: true,
        };
      }
    } catch (error: unknown) {
      const { message } = error as { message: string };
      return {
        title: 'ERROR',
        body: message,
        isError: true,
      };
    }
  }, [expectedType, isResultTypeMatched, matchArrayItemTypes, result]);

  useEffect(() => {
    if (calculatedResult.isError) {
      onResultCalculated?.(true);
    } else {
      onResultCalculated?.(false, calculatedResult.result);
    }
  }, [calculatedResult, onResultCalculated]);

  const handleOnFocus: DomEvent = useCallback(
    (event) => {
      const informationBox = document.querySelector(`#${informationBoxId}`) as HTMLElement;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const CodeMirrorWrapperElement = (event as any).display.wrapper;

      if (CodeMirrorWrapperElement && informationBox) {
        const width = CodeMirrorWrapperElement.clientWidth;
        setInformationBoxWidth(width);
        popperRef.current = createPopper(CodeMirrorWrapperElement, informationBox, {
          placement: 'bottom-start',
          strategy: 'fixed',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        });
      }
      onFocus?.(event);
    },
    [informationBoxId, onFocus]
  );

  const getAnnotations = useCallback(
    (value: string, options: unknown, cm: CodeMirror.Editor): Annotation[] => {
      /**
       * To check lint options
       * https://jshint.com/docs/options/
       */
      const allPaths = cm.state.allPaths || [];

      /**
       * check if request is selected by checking whether it's first level value
       */

      let currentWord = replace(value, /\{/g, '');
      currentWord = replace(currentWord, /\}/g, '');

      if (!currentWord.startsWith('[')) {
        currentWord = replace(currentWord, /\[/g, '.');
        currentWord = replace(currentWord, /\]/g, '.');
        currentWord = replace(currentWord, /\.\./g, '.');
      }

      const checkForFirstLevel = currentWord.split('.').length === 0;

      if (checkForFirstLevel) {
        if (
          currentWord !== '' &&
          !currentWord.endsWith('.') &&
          allPaths.filter((listItem: string) => listItem.startsWith(currentWord))?.length === 0
        ) {
          const syntaxError = {
            message: `${currentWord} is not defined`,
            severity: 'error',
            from: cm.posFromIndex(0),
            to: cm.posFromIndex(currentWord.length + 2), // because we trim }}, +2 comes from }}
          };
          if (!error || syntaxError.message !== error) {
            setError(`${currentWord} is not defined`);
          }
          return [syntaxError];
        }
      } else {
        const { isError } = calculatedResult;

        if (isError) {
          const syntaxError = {
            message: `Expected type is ${expectedType}`,
            severity: 'error',
            from: cm.posFromIndex(0),
            to: cm.posFromIndex(cm.getValue().length),
          };
          if (!error || syntaxError.message !== error) {
            setError(`Expected type is ${expectedType}`);
          }
          return [syntaxError];
        } else {
          setError(undefined);
          return [];
        }
      }

      return [];
    },
    [calculatedResult, error, expectedType, setError]
  );

  return {
    getAnnotations,
    handleOnFocus,
    calculatedResult,
    informationBoxId,
    informationBoxWidth,
  };
};
