import { useGridApiContext } from '@novaera/core';
import { useUnMount } from '@novaera/utils';
import { useCallback, useMemo } from 'react';

export const StickyHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    current: { columnHeadersElementRef, columnHeadersContainerElementRef, getColumnHeaderElement },
  } = useGridApiContext();

  const calculatePositionLeftFromTransform = useCallback(
    (transform: string) => {
      let result: number | null = null;
      const regex = /translate3d\((?<translateX>.*)px, .*px, .*px\)/;
      const translateX = transform.match(regex)?.groups?.translateX;

      const headerContainerWidth = columnHeadersContainerElementRef?.current?.clientWidth;
      const actionsHeaderElement = getColumnHeaderElement('muiDataGridActionsField');

      if (headerContainerWidth && actionsHeaderElement && translateX) {
        const lastElementPositionLeft =
          headerContainerWidth - actionsHeaderElement.clientWidth + Math.abs(Number(translateX)) - 1;
        result = lastElementPositionLeft;
      }

      return result;
    },
    [columnHeadersContainerElementRef, getColumnHeaderElement]
  );

  const setAbsolutePositionForActions = useCallback(
    (left: number | null) => {
      const actionsHeaderElement = getColumnHeaderElement('muiDataGridActionsField');
      if (left && actionsHeaderElement) {
        actionsHeaderElement.style.left = `${left}px`;
      }
    },
    [getColumnHeaderElement]
  );

  const target = useMemo(() => columnHeadersElementRef?.current, [columnHeadersElementRef]);

  let mutationObserver: MutationObserver | null = null;

  if (target) {
    const initialLeft = calculatePositionLeftFromTransform(target.style.transform);
    setAbsolutePositionForActions(initialLeft);

    mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutationRecord) => {
        const transform = (mutationRecord.target as HTMLDivElement).style.transform;
        const left = calculatePositionLeftFromTransform(transform);
        setAbsolutePositionForActions(left);
      });
    });

    mutationObserver.observe(target, { attributes: true, attributeFilter: ['style'] });
  }

  useUnMount(() => {
    if (mutationObserver) {
      mutationObserver.disconnect();
    }
  });

  return null;
};
