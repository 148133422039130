import { NvAxios, useMutation } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { UserWorkflowExecutionResponse, UseWorkflowExecutionProps } from './types';

const executeWorkflow = async ({
  userAppId,
  workflowId,
  isDraft,
  isTestRun = true,
  ...data
}: UseWorkflowExecutionProps) => {
  const result = await NvAxios.post<UserWorkflowExecutionResponse>(
    `${USER_APP_ROOT_PATH}/${userAppId}/workflows/${workflowId}/executions`,
    data,
    {
      params: {
        draft: isDraft,
        testRun: isTestRun,
      },
    }
  );
  return result?.data;
};

export const useExecuteWorkflowService = () => {
  return useMutation(executeWorkflow);
};
