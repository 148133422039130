import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SaveJobModalFieldsCard = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
`;
