import { AppSchemaDetail } from '@novaera/actioner-service';
import { Markdown, NvFlex, NvTypography } from '@novaera/core';
import { UpdateInfoBox } from './styled';

export const ManagedAppUpdateModalBody = ({ appSchemaDetail }: { appSchemaDetail?: AppSchemaDetail }) => {
  return (
    <NvFlex gap={'16px'}>
      <NvTypography variant="body1">
        The latest version of this app includes the following changes. When you click the update button, the changes
        will be automatically applied to your app. This process may take a few minutes to complete.
      </NvTypography>
      <UpdateInfoBox>
        {appSchemaDetail && (
          <>
            <NvTypography variant="h4">v{appSchemaDetail.version}</NvTypography>
            <Markdown>{appSchemaDetail.changeLog}</Markdown>
          </>
        )}
      </UpdateInfoBox>
    </NvFlex>
  );
};
