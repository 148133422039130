import { NvAxios, useQuery } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';

import { INTEGRATION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS_SAMPLE_PAYLOAD } from '../keys';
import { BatchGetIntegrationsSamplePayloadParams, BatchGetIntegrationsSamplePayloadResponse } from './types';

const getIntegrationsSamplePayload = async (params: BatchGetIntegrationsSamplePayloadParams) => {
  const result = await NvAxios.post<BatchGetIntegrationsSamplePayloadResponse>(
    `${INTEGRATION_ROOT_PATH}/sample-payload/batch-get`,
    params
  );
  return result?.data;
};

export const useBatchGetIntegrationsSamplePayload = (params: BatchGetIntegrationsSamplePayloadParams) => {
  const cache = useQueryClient();

  return useQuery<BatchGetIntegrationsSamplePayloadResponse>(
    [JSON.stringify(params)],
    () => getIntegrationsSamplePayload(params),
    {
      onSuccess: ({ actionResponseSamples }) => {
        actionResponseSamples?.forEach((actionResponseSample) => {
          if (typeof actionResponseSample?.response === 'string') {
            try {
              const parsedValue = JSON.parse(actionResponseSample.response);
              cache.setQueryData(
                QUERY_KEYS_INTEGRATION_ACTIONS_SAMPLE_PAYLOAD.detail({
                  integrationId: actionResponseSample.identifier.integrationId,
                  actionId: actionResponseSample.identifier.actionId,
                  version: actionResponseSample.identifier.version.number,
                }),
                parsedValue
              );
            } catch (e) {
              assert(false, new Error('Invalid JSON response'), 'ERROR');
            }
          }
        });
      },
    }
  );
};
