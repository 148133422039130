import { GetFormParameters } from './types';
import { SearchUserAppPayload } from './use-search-user-apps/types';
import { generatedUserAppSearchKey } from './utils';

export type FORM_PARAMETERS_QUERY_KEY_TYPE = {
  appId: string;
  workflowId: string;
  payload: Omit<GetFormParameters['payload'], 'formId'>;
};

export const QUERY_KEYS_USER_APP = {
  all: ['user-app'] as const,
  search: (payload?: SearchUserAppPayload) =>
    [...QUERY_KEYS_USER_APP.all, 'search', ...(payload ? [generatedUserAppSearchKey(payload)] : [])] as const,
  infiniteSearch: (payload?: SearchUserAppPayload) =>
    [...QUERY_KEYS_USER_APP.all, 'infinite-search', ...(payload ? [generatedUserAppSearchKey(payload)] : [])] as const,
  detail: (id?: string) => [...QUERY_KEYS_USER_APP.all, 'detail', ...(id ? [id] : [])] as const,
  batch: (ids: string[]) => [...QUERY_KEYS_USER_APP.all, 'batch', ...ids] as const,
};

export const QUERY_KEYS_USER_APP_SETUP = {
  all: ['user-app-setup'] as const,
  status: ({ appId }: { appId?: string }) => [...QUERY_KEYS_USER_APP_SETUP.all, appId, 'status'] as const,
  detail: ({ appId }: { appId?: string }) => [...QUERY_KEYS_USER_APP_SETUP.all, appId, 'detail'] as const,
};

export const QUERY_KEYS_WORKFLOW_DEPENDENCIES = {
  all: ['workflow-dependencies'] as const,
  workflow: ({ appId, workflowId, isDraft }: { appId: string; workflowId: string; isDraft: boolean }) =>
    [...QUERY_KEYS_WORKFLOW_DEPENDENCIES.all, appId, workflowId, isDraft, 'dependencies'] as const,
};

export const QUERY_KEYS_WORKFLOW_FORM_PARAMETERS = {
  all: ['workflow-form-parameters'] as const,
  formParameters: (params: FORM_PARAMETERS_QUERY_KEY_TYPE) =>
    [...QUERY_KEYS_WORKFLOW_FORM_PARAMETERS.all, params] as const,
};
