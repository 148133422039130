import {
  BooleanValue,
  ListValue,
  NumberValue,
  ObjectValue,
  ParameterTypes,
  StringValue,
  ValueTypes,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { fieldSchemaToValue } from '../utils/field-schema-to-value';
import {
  CodeInputFieldAdapterProps,
  CodeInputFormatFunction,
  CodeInputFormatValueFunction,
  CodeInputParseFunction,
  CodeInputParseValueFunction,
} from './types';

export const useCodeInputFieldAdapter = <
  F extends CodeInputFormatFunction | CodeInputFormatValueFunction,
  P extends CodeInputParseFunction | CodeInputParseValueFunction
>({
  extractValue = false,
  hidden,
  forceValueUsage,
}: CodeInputFieldAdapterProps): {
  format: F;
  parse: P;
} => {
  const formatValue: CodeInputFormatValueFunction = useCallback(
    (value) => {
      if (value) {
        if (forceValueUsage) {
          return typeof value.value === 'object' ? JSON.stringify(value.value) : value.value;
        } else if (value.type === ValueTypes.STRING) {
          return value.value;
        } else {
          return value.codeTemplate;
        }
      } else {
        return '';
      }
    },
    [forceValueUsage]
  );

  const parseValue: CodeInputParseValueFunction = useCallback(
    ({ schema, removeDisplayValue = false }) =>
      (valueToParse) => {
        if (valueToParse) {
          assert(!!schema, new Error('Schema should be defined for textfield'), 'ERROR');
          const valueType = fieldSchemaToValue(schema);

          if (valueType === ValueTypes.OBJECT) {
            if (forceValueUsage) {
              let theValue;
              try {
                theValue = JSON.parse(valueToParse);
              } catch (error) {
                /* empty */
              }
              return {
                type: valueType,
                value: theValue ?? valueToParse,
                ...(removeDisplayValue ? {} : { displayValue: valueToParse }),
              };
            } else {
              const retVal: ObjectValue = {
                type: valueType,
                codeTemplate: valueToParse,
              };
              return retVal;
            }
          } else if (valueType === ValueTypes.BOOLEAN) {
            const retVal: BooleanValue = {
              type: valueType,
              codeTemplate: valueToParse,
            };
            return retVal;
          } else if (valueType === ValueTypes.NUMBER) {
            const retVal: NumberValue = {
              type: valueType,
              codeTemplate: valueToParse,
            };
            return retVal;
          } else if (valueType === ValueTypes.STRING) {
            const retVal: StringValue = {
              type: valueType,
              value: valueToParse,
            };
            return retVal;
          } else if (valueType === ValueTypes.LIST) {
            const retVal: ListValue = {
              type: valueType,
              codeTemplate: valueToParse,
            };
            return retVal;
          } else {
            throw new Error(`Unknown value type: ${valueType}`);
          }
        } else {
          return undefined;
        }
      },
    [forceValueUsage]
  );
  const format: CodeInputFormatFunction = useCallback(
    (parameterMapping) => {
      if (parameterMapping?.value) {
        return formatValue(parameterMapping.value);
      } else {
        return '';
      }
    },
    [formatValue]
  );

  const parse: CodeInputParseFunction = useCallback(
    ({ schema, id, removeDisplayValue = false }) =>
      (valueToParse) => {
        const result = parseValue({ schema, id, removeDisplayValue })(valueToParse);
        if (result || hidden) {
          return { parameterId: id, type: ParameterTypes.SIMPLE, hidden, ...(result && { value: result }) };
        } else {
          return undefined;
        }
      },
    [hidden, parseValue]
  );

  return {
    format: (extractValue ? formatValue : format) as F,
    parse: (extractValue ? parseValue : parse) as P,
  };
};
