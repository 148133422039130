import { StaticWeekdaysJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { CronTimePicker } from '../../../../../../../../../../../jobs/components/cron-time-picker';
import { WEEKDAYS_CRON_EXPRESSION } from '../../../../../../../../../../../jobs/constants';
import { RecurrenceProps } from '../../types';

export const StaticWeekdaysRecurrence: React.FC<
  Omit<RecurrenceProps<StaticWeekdaysJobRecurrenceConfiguration>, 'context'>
> = ({ recurrenceConfiguration, onChange }) => {
  const cron = recurrenceConfiguration.cronExpression ?? WEEKDAYS_CRON_EXPRESSION;

  return (
    <NvFlex direction="row" alignItems="center" gap="4px">
      <NvTypography variant="body2">At</NvTypography>

      <CronTimePicker
        cron={cron}
        onChange={(cron) => {
          onChange({
            ...recurrenceConfiguration,
            cronExpression: cron,
          });
        }}
      />
    </NvFlex>
  );
};
