import { NodeUnionObject, NodeUnionType } from '@novaera/actioner-service';
import {
  APP_WORKFLOW_REAL_NODE_TYPES,
  APP_WORKFLOW_TRIGGER_NODE_TYPES,
} from '../components/user-app-workflow-node-types';
import { AppWorkflowRealNodeType, AppWorkflowTriggerNodeType } from '../components/user-app-workflow-node-types/types';

export const isUserAppWorkflowType = (type: string): type is AppWorkflowRealNodeType => {
  return type in APP_WORKFLOW_REAL_NODE_TYPES;
};

export const isTriggerWorkflowType = (type: string): type is AppWorkflowTriggerNodeType => {
  return type in APP_WORKFLOW_TRIGGER_NODE_TYPES;
};

export const isNodeUnionType = (type: string): type is NodeUnionType => {
  return type in NodeUnionObject;
};
