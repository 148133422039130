import { HTTPKeyValueSubPanelProps } from '.';

export const HTTPKeyValueSubPanelMap: Record<
  HTTPKeyValueSubPanelProps['keyValueSubPanelType'],
  Record<string, string>
> = {
  headers: { title: 'Headers', fieldName: 'headers', singleUnitName: 'header' },
  queryParameters: { title: 'Params', fieldName: 'queryParameters', singleUnitName: 'query parameter' },
  formParameters: { title: 'Form parameters', fieldName: 'formParameters', singleUnitName: 'form field' },
};
