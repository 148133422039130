import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { APP_DEPENDENCIES_ROOT } from '../constants';
import { QUERY_KEYS_APP_DEPENDENCIES } from '../keys';
import { AppDependency, AppDependencyListItem } from '../types';
import { GetAppDependenciesResponse } from '../use-get-app-dependencies';

export type UpdateAppDependencyParams = {
  appId: string;
  payload: Pick<AppDependency, 'id' | 'name' | 'appSchemaId' | 'appId'>;
};

export type UpdateAppDependencyResponse = AppDependencyListItem;

const updateAppDependency = async ({ appId, payload }: UpdateAppDependencyParams) => {
  const result = await NvAxios.patch<UpdateAppDependencyResponse>(
    `${APP_DEPENDENCIES_ROOT}/${appId}/dependencies`,
    payload
  );
  return result?.data;
};

export const useUpdateAppDependency = () => {
  const cache = useQueryClient();

  return useMutation(updateAppDependency, {
    onSuccess: (data, variables) => {
      cache.setQueryData<GetAppDependenciesResponse>(
        QUERY_KEYS_APP_DEPENDENCIES.lists({ appId: variables.appId }),
        (old) => {
          if (variables.payload.id) {
            return old
              ? {
                  ...old,
                  dependencies: old.dependencies.map((dependency) =>
                    dependency.dependency.id === variables.payload.id
                      ? { ...dependency, ...variables.payload }
                      : dependency
                  ),
                }
              : {
                  dependencies: [data],
                };
          }
          return {
            dependencies: [...(old?.dependencies || []), data],
          };
        }
      );
    },
  });
};
