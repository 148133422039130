import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';

export const useIsOverflow = (ref: MutableRefObject<HTMLElement | null>, callback?: (isOverflow: boolean) => void) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const { current } = ref;

  useLayoutEffect(() => {
    resizeObserverRef.current = new ResizeObserver(() => {
      if (current) {
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflow(hasOverflow);
        if (callback) callback(hasOverflow);
      }
    });

    if (current) resizeObserverRef.current.observe(current);

    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [callback, current]);

  return isOverflow;
};
