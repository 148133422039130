import { styled } from '@novaera/theme-provider';
import { NvBox, NvMenu } from '../../index';

export const NestedDropdown = styled(NvMenu)`
  .MuiPaper-root {
    ${({ theme: { elevations } }) => elevations.e400};
    border-radius: 12px;

    .MuiList-root {
      padding: 6px 0;
      border-radius: 12px;
    }
  }
`;

export const NestedDropdownWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  .trigger-button {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]} !important;
    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[50]} !important;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]} !important;
    }
  }
  .more-icon {
    transition: transform 0.3s ease-out;
  }
  &.is-open-nested-dropdown {
    .trigger-button {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]} !important;
      border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
      ${({ theme }) => theme.mixins.focus};
      .more-icon {
        transform: rotate(180deg);
      }
    }
  }
`;
