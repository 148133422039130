import { AutocompleteRenderGetTagProps } from '@mui/material';
import {
  ListValue,
  ObjectValue,
  StringValue,
  useUpdateNode,
  ValueTypes,
  WorkflowResolverNode,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvButton,
  NvChip,
  NvCloseIcon,
  NvCustomWorkflowResolver,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSettingsIcon,
  NvSideGroupedInputLayout,
  NvTagsAutoComplete,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { OutputItem } from '../../../../../../../../components/output-item';
import { ParameterMappingSettingsMenu } from '../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { WORKFLOW_RESOLVER_SAMPLE_RESPONSE } from '../../../../common/constants';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { WorkflowResolverPropertiesProps } from './types';

export const WorkflowResolverProperties: FC<WorkflowResolverPropertiesProps> = ({
  workflowResolverNode,
  onCloseClicked,
}) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const initialWorkflowResolverNode = useMemo<WorkflowResolverNode>(
    () => ({
      appId: { type: ValueTypes.STRING, value: '{{app.id}}' },
      command: { type: ValueTypes.STRING },
      tags: { type: ValueTypes.LIST, value: [] },
      errorHandlingStrategy: { type: 'simple', continueOnError: true },
      ...workflowResolverNode,
    }),
    [workflowResolverNode]
  );

  const [hasTagsCodeTemplate, setHasTagsCodeTemplate] = useState(() =>
    Boolean(workflowResolverNode?.tags && 'codeTemplate' in workflowResolverNode.tags)
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: workflowResolverNode.alias,
          payload: { ...(workflowResolverNode as WorkflowResolverNode), name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(workflowResolverNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [workflowResolverNode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const handleOnChange = useCallback(
    (values: WorkflowResolverNode) => {
      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: workflowResolverNode.alias,
        payload: { ...values },
      });
    },
    [updateNodeDetail, userAppId, workflowResolverNode, workflowId]
  );

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        hasEditRight={hasEditPermission}
        icon={<NvCustomWorkflowResolver />}
        title={workflowResolverNode.name}
        type={NodeType.WORKFLOW_RESOLVER}
        onTitleChange={async (title) => {
          if (title) {
            handleSaveName(title);
          }
        }}
        validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'small', color: 'secondary' },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    deleteNodeAndUpdateGraph({ nodeId: workflowResolverNode.alias });
                  },
                },
              ]}
            />

            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <NvForm<WorkflowResolverNode>
        onSubmit={noop}
        onChange={({ values }) => {
          handleOnChange(values);
        }}
        initialValues={initialWorkflowResolverNode}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        <PropertyPanelSimpleSection>
          <NvTypography variant="body2">
            Workflow resolver identifies relevant workflows based on the given content. You can specify which app's
            workflows will be targeted and filter workflows with tags or additional context.
          </NvTypography>
        </PropertyPanelSimpleSection>
        <PropertyPanelSection
          title="Content"
          tooltip={`AI agent will find the target workflow
           based on the given content.`}
        >
          <NvField
            name="command"
            formControlStyle={{ width: '100%' }}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => {
              return value ? { type: ValueTypes.STRING, value: value } : undefined;
            }}
            component={<CodeInput context={workflowCodeInputContext} placeholder={'{{...}}'} />}
          />
        </PropertyPanelSection>
        <PropertyPanelListHeader title="PARAMETERS" />

        <PropertyPanelSimpleSection>
          <NvFlex gap="16px">
            <NvField
              labelText="App id"
              direction="label-on-top"
              name="appId"
              infoText="By default, the AI agent will attempt to find workflows within this app. For other apps, enter corresponding app ID."
              formControlStyle={{ width: '100%' }}
              format={(value: StringValue | undefined) => value?.value}
              parse={(value: string) => {
                return value ? { type: ValueTypes.STRING, value: value } : undefined;
              }}
              component={<CodeInput context={workflowCodeInputContext} placeholder={'{{...}}'} />}
            />
            <NvField<undefined | (Omit<ListValue, 'value'> & { value?: StringValue[] })>
              direction="label-on-top"
              name="tags"
              infoText="If you provide tags, only matching workflows will be returned."
              component={({ value, onChange }) => {
                return (
                  <NvSideGroupedInputLayout
                    variant={'default'}
                    title={{
                      direction: 'label-on-top',
                      labelText: 'Tag filter',
                      labelVariant: 'h5',
                    }}
                    rightAction={
                      <NvMenuWithItems
                        triggerButton={{
                          content: <NvSettingsIcon />,
                          props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
                        }}
                        menuItems={
                          <ParameterMappingSettingsMenu
                            hiddenConfig={{
                              show: false,
                            }}
                            dynamicStaticConfig={{
                              show: true,
                              isScripted: hasTagsCodeTemplate,
                              onItemClicked: (type) => {
                                const newScriptedValue = type === 'static' ? false : true;
                                if (hasTagsCodeTemplate !== newScriptedValue) {
                                  setHasTagsCodeTemplate(newScriptedValue);
                                  if (newScriptedValue) {
                                    onChange({
                                      type: ValueTypes.LIST,
                                      codeTemplate: '',
                                    });
                                  } else {
                                    onChange({
                                      type: ValueTypes.LIST,
                                      value: [],
                                    });
                                  }
                                }
                              },
                            }}
                          />
                        }
                      />
                    }
                    formItem={
                      hasTagsCodeTemplate ? (
                        <CodeInput
                          value={value?.codeTemplate}
                          onChange={(value) => {
                            onChange({
                              type: ValueTypes.LIST,
                              codeTemplate: value,
                            });
                          }}
                          context={workflowCodeInputContext}
                          placeholder={'{{...}}'}
                        />
                      ) : (
                        <NvTagsAutoComplete
                          sx={{ width: '100%' }}
                          value={value?.value}
                          getOptionLabel={(value?: StringValue) => value?.displayValue}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(e: any, values: string[]) => {
                            const newValues =
                              values?.map<StringValue>((v) =>
                                typeof v === 'object' ? v : { type: ValueTypes.STRING, value: v, displayValue: v }
                              ) ?? [];

                            onChange({
                              type: ValueTypes.LIST,
                              value: newValues,
                            });
                          }}
                          renderTags={(value: StringValue[], getTagProps: AutocompleteRenderGetTagProps) => {
                            return value.map((option, index) => {
                              return (
                                <NvChip
                                  {...getTagProps({ index })}
                                  key={`tag_auto_complete_index_${index}`}
                                  label={option.displayValue ?? ''}
                                />
                              );
                            });
                          }}
                          placeholder={'Add tags'}
                        />
                      )
                    }
                  />
                );
              }}
            />
            <NvField<undefined | ListValue>
              direction="label-on-top"
              labelText="Messages"
              name="messages"
              component={({ value, onChange }) => {
                return (
                  <CodeInput
                    value={value?.codeTemplate}
                    onChange={(value) => {
                      onChange({
                        type: ValueTypes.LIST,
                        codeTemplate: value,
                      });
                    }}
                    context={workflowCodeInputContext}
                    placeholder={'{{...}}'}
                  />
                );
              }}
            />
            <NvField<undefined | ObjectValue>
              direction="label-on-top"
              name="additionalContext"
              infoText="You can provide additional context in JSON format for the target workflow's usage."
              labelText="Additional context"
              format={(value: ObjectValue) => value?.codeTemplate}
              parse={(value) => ({
                type: ValueTypes.OBJECT,
                displayValue: '',
                codeTemplate: value,
              })}
              component={<CodeInput context={workflowCodeInputContext} placeholder={'{{...}}'} />}
            />
          </NvFlex>
        </PropertyPanelSimpleSection>

        <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <OutputItem content={WORKFLOW_RESOLVER_SAMPLE_RESPONSE} isTestResultFailed={false} />
        </PropertyPanelSimpleSection>

        <NvField name="errorHandlingStrategy" component={<ErrorHandlingStrategyPanel />} />
      </NvForm>
    </NvFlex>
  );
};
