import { useMsTeamsGetConsentComplete } from '@novaera/actioner-service';
import { ErrorPage, LoadingPage } from '@novaera/identity-provider';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const MsTeamsConsentComplete = () => {
  const [searchParams] = useSearchParams();
  const { data, error } = useMsTeamsGetConsentComplete({ queryParams: searchParams.toString() });

  useEffect(() => {
    if (data && 'authorizationUrl' in data && data.authorizationUrl) {
      window.location.href = data.authorizationUrl;
    }
  }, [data]);

  return (data && 'errorMessage' in data && data.errorMessage) || error ? (
    <ErrorPage
      title={'Something went wrong!'}
      description={data && 'errorMessage' in data && data.errorMessage ? data.errorMessage : `${error}`}
      buttonText="Close"
      onClick={() => {
        window.close();
      }}
    />
  ) : (
    <LoadingPage message="Redirecting ..." />
  );
};
