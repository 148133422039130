import { ActionerEvent, useCreateActionerEvent, useUpdateActionerEventMetadata } from '@novaera/actioner-service';
import { useMemo } from 'react';

import { useNavigate } from '@novaera/route';
import { USER_APP_EVENT_DETAIL } from '../../../../constants';
import { useGetWorkflowQueryParams } from '../../../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { EventFormValues } from '../../types';

export const useEventModalController = ({ event, onClose }: { event?: ActionerEvent; onClose: () => void }) => {
  const { userAppId } = useGetWorkflowQueryParams();

  const navigate = useNavigate();

  const { mutate: createEvent, isLoading: isCreateLoading } = useCreateActionerEvent();
  const { mutate: updateEvent } = useUpdateActionerEventMetadata();

  const initialFormValues = useMemo(() => (event ? event : { name: '', description: '', tags: [] }), [event]);

  const handleFormSubmit = (values: EventFormValues) => {
    if (!event) {
      createEvent(
        {
          appId: userAppId,
          payload: {
            name: values.name,
            description: values.description,
            sharedAcrossApps: false,
            tags: values.tags,
          },
        },
        {
          onSuccess: ({ id }) => {
            navigate(USER_APP_EVENT_DETAIL(userAppId, id));
          },
        }
      );
    } else {
      updateEvent({
        appId: userAppId,
        id: event.id,
        payload: {
          name: values.name,
          description: values.description,
          tags: values.tags,
        },
      });
      onClose();
    }
  };

  return {
    onFormSubmit: handleFormSubmit,
    initialFormValues,
    isLoading: isCreateLoading,
  };
};
