import { NvField, NvMarkDownEditor } from '@novaera/core';
import { BodyItem } from '../../item';

export const DescriptionField = () => {
  return (
    <BodyItem description="Enter the description of your connection schema." title="Description">
      <NvField name="description.value" component={<NvMarkDownEditor />} />
    </BodyItem>
  );
};
