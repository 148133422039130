import { NvButton, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ManagedAppBox = styled(NvFlex)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  gap: 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
`;

export const UnlockButton = styled(NvButton)`
  &.MuiButtonBase-root {
    &.MuiButtonBase-root.NvButton-onlyIcon.MuiButton-sizeSmall {
      font-size: 12px;
      min-width: 24px;
      width: 24px;
      height: 24px;
    }

    &.MuiButton-containedGhost {
      background-color: ${({ theme }) => theme.palette.nv_neutral[800]};

      &.is-opened {
        background-color: ${({ theme }) => theme.palette.nv_neutral[600]} !important;
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
      }
    }
  }
`;
