import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { assert } from '@novaera/utils';
import classnames from 'classnames';
import { isString, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NvBox } from '../box';
import { NvFlex } from '../flex';
import { MemoizedDraggableTabItem } from './draggable-tab-item';
import { DefaultTabWrapper, TabsWrapper } from './styled';
import { NvTabsProps, TypeOfTab } from './types';

export const NvTabs: React.FC<React.PropsWithChildren<NvTabsProps>> = ({
  defaultActiveTabIndex = 0,
  tabs,
  CustomTabActionComponents,
  BodyWrapperComponent,
  onActiveTabChanged,
  ActionComponent,
  focusIndex,
  onTabDragEnd,
  variant = 'medium',
  ...props
}) => {
  const [activeTab, setActiveTab] = useState<number>(defaultActiveTabIndex);

  useEffect(() => {
    !isUndefined(focusIndex) && setActiveTab(focusIndex);
  }, [focusIndex]);

  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, newIndex: number) => {
    setActiveTab(newIndex);
    onActiveTabChanged?.(newIndex);
  };

  const tabContent = useMemo(() => tabs[activeTab]?.content, [activeTab, tabs]);
  const disableDragAndDropSupport = useMemo(() => isUndefined(onTabDragEnd), [onTabDragEnd]);

  const { className } = props; // to use NvTabs as a styled component.

  const uniqueKey = useCallback((tab: TypeOfTab, index?: number) => {
    assert(
      disableDragAndDropSupport || Boolean(tab.id),
      new Error('You must provide an id for each tab item while using drag and drop tab item')
    );

    if (tab.id) {
      return `tab-item-${tab.id}`;
    } else if (tab.label) {
      if (isString(tab.label)) {
        return `tab-item-${tab.label}`;
      } else {
        return `tab-item-${index}`;
      }
    } else {
      return `tab-item-${index}`;
    }
  }, []);

  return (
    //className was passed to TabsWrapper to be able to make style override.
    <TabsWrapper
      className={classnames(className, {
        [variant]: variant,
      })}
    >
      <NvFlex flexDirection="row" className="tab-header-container" data-testid="tabs-output">
        <NvFlex flexGrow="1">
          {disableDragAndDropSupport ? (
            <Tabs value={activeTab} onChange={handleOnChange}>
              {tabs.map((tab, index) => (
                <Tab
                  label={tab.label}
                  key={uniqueKey(tab, index)}
                  icon={tab.icon}
                  iconPosition={tab.iconPosition}
                  disableRipple
                  className={tab.className}
                />
              ))}
              {CustomTabActionComponents &&
                CustomTabActionComponents.map((CustomTabComponent) =>
                  React.cloneElement(CustomTabComponent, {
                    ...CustomTabComponent.props,
                  })
                )}
            </Tabs>
          ) : (
            <DragDropContext
              onDragEnd={({ source, destination }) => {
                if (!destination) return;

                onTabDragEnd?.(source.index, destination.index);
              }}
            >
              <Droppable droppableId="tabs-droppable-area" direction="horizontal">
                {(provided) => (
                  <NvBox ref={provided.innerRef} {...provided.droppableProps}>
                    <Tabs value={activeTab} onChange={handleOnChange}>
                      {/*
                        Tabs component was passing some dynamic props automatically for each child.
                        While adding DragAndDrop feature, we had to add some wrapper div between Tabs and Tab component and it brakes this automatic prop passing behavior.
                        To save this behavior we also wrapped Tab with MemoizedTabItem component, This component get props from Tabs and then we pass it manually to Tab component that is not reachable by Tabs anymore.
                      */}
                      {tabs.map((tab, index) => (
                        <MemoizedDraggableTabItem
                          TabItemWrapper={tab.TabItemWrapper}
                          key={uniqueKey(tab)}
                          tab={tab}
                          index={index}
                        />
                      ))}
                      {provided.placeholder}

                      {CustomTabActionComponents &&
                        CustomTabActionComponents.map((CustomTabComponent) =>
                          React.cloneElement(CustomTabComponent, {
                            ...CustomTabComponent.props,
                          })
                        )}
                    </Tabs>
                  </NvBox>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </NvFlex>
        {ActionComponent && <NvFlex className="header-action">{ActionComponent}</NvFlex>}
      </NvFlex>
      {BodyWrapperComponent ? (
        React.cloneElement(BodyWrapperComponent, {
          children: tabContent,
        })
      ) : (
        <DefaultTabWrapper className="tab-body-container">{tabContent}</DefaultTabWrapper>
      )}
    </TabsWrapper>
  );
};
