import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { styled } from '@novaera/theme-provider';

export const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  border: 2px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  box-sizing: border-box;
`;

export const StyledAvatarGroup = styled(AvatarGroup)`
  .MuiAvatarGroup-avatar {
    width: 32px;
    height: 32px;
    border: 2px solid ${({ theme }) => theme.palette.nv_neutral[30]};
    box-sizing: border-box;
  }
`;
