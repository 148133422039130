import {
  ActionerEventNode,
  ActionerEventPublisherNodeSummary,
  ActionerEventPublisherVertex,
} from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class ActionerEventComponentFactory implements ComponentFactory<ActionerEventNode> {
  aliasForNode: string;
  nameForNode: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('actionerEventPublisher');
    this.aliasForNode = newAlias;
    this.nameForNode = newName;
  }

  get componentForGraph() {
    const NodeRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForNode,
      width: 40,
      height: 40,
      id: this.aliasForNode,
      type: 'actionerEventPublisher',
      alias: this.aliasForNode,
    };

    return { root: NodeRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: ActionerEventNode) {
    const newNodeUnion: ActionerEventPublisherVertex = {
      type: 'actionerEventPublisher',
      alias: this.aliasForNode,
    };

    const summary: ActionerEventPublisherNodeSummary = {
      name: this.nameForNode,
      alias: this.aliasForNode,
      type: 'actionerEventPublisher',
    };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForNode]: summary } };
  }
}
