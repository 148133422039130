import { NvBox, NvChevronRightIcon, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { WorkspaceItem } from '../../components/workspace-item';
import { useWorkspaceSettings } from './controller';
import { SlackUserMappingSection } from './slack-user-mapping-section';
import { NvAccordionDetailsWrapper, NvAccordionSummaryWrapper, NvAccordionWrapper } from './styled';

export const Workspaces: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { workspaces, isWorkspacesLoading } = useWorkspaceSettings();

  if (isWorkspacesLoading) {
    return (
      <NvFlex gap="16px">
        <NvSkeleton variant="rectangular" height="56px" />
        <NvSkeleton variant="rectangular" height="56px" />
        <NvSkeleton variant="rectangular" height="56px" />
      </NvFlex>
    );
  }

  return (
    <NvFlex gap="16px">
      {(workspaces || [])?.map((workspace) => {
        return (
          <NvBox key={workspace.id}>
            <NvAccordionWrapper disableGutters>
              <NvAccordionSummaryWrapper expandIcon={<NvChevronRightIcon />}>
                <WorkspaceItem workspace={workspace} />
              </NvAccordionSummaryWrapper>
              <NvAccordionDetailsWrapper>
                <NvFlex gap="8px">
                  <NvTypography variant="h4">User mapping for Slack</NvTypography>

                  <NvFlex>
                    {workspace.hasSlackConnection && <SlackUserMappingSection workspaceId={workspace.id} />}
                    {!workspace.hasSlackConnection && (
                      <NvTypography>You do not have any Slack user mapping yet.</NvTypography>
                    )}
                  </NvFlex>
                </NvFlex>
              </NvAccordionDetailsWrapper>
            </NvAccordionWrapper>
          </NvBox>
        );
      })}
    </NvFlex>
  );
};
