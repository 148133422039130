import {
  NvAddIcon,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvForm,
  NvTabs,
  NvTypography,
} from '@novaera/core';
import { useCallback, useState } from 'react';
import { ADD_MEDIA_MODAL_INITIAL_VALUES, ADD_MEDIA_MODAL_TABS } from './constants';

import { AddMediaModalFormValues, AddMediaModalProps, AddMediaModalType } from './types';

export const AddMediaModal: React.FC<React.PropsWithChildren<AddMediaModalProps>> = ({
  isOpen,
  onClose,
  onModalSubmit,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleSubmit = useCallback(
    ({ image, video }: AddMediaModalFormValues) => {
      const activeTabValue = ADD_MEDIA_MODAL_TABS[activeTabIndex].value;

      if (activeTabValue === AddMediaModalType.IMAGE) {
        onModalSubmit(image);
      } else if (activeTabValue === AddMediaModalType.YOUTUBE_VIDEO) {
        onModalSubmit(video);
      }
      onClose();
    },
    [activeTabIndex, onClose, onModalSubmit]
  );

  return (
    <NvDialogV1 open={isOpen} maxWidth="sm" fullWidth disableEscapeKeyDown>
      <NvForm<AddMediaModalFormValues>
        onSubmit={handleSubmit}
        initialValues={ADD_MEDIA_MODAL_INITIAL_VALUES}
        keepDirtyOnReinitialize
      >
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">{<NvAddIcon />}</NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Add media</NvTypography>
            </NvDialogSlots.Slot>

            <NvDialogSlots.Slot name="content">
              <NvTabs
                variant="small"
                BodyWrapperComponent={<NvBox paddingTop="16px" />}
                onActiveTabChanged={(index) => {
                  setActiveTabIndex(index);
                }}
                tabs={ADD_MEDIA_MODAL_TABS}
              />
            </NvDialogSlots.Slot>

            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit">Add</NvButton>
              <NvButton disabled={submitting} onClick={onClose} color="ghost">
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={onClose}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
