import { NvAxios, useQuery } from '@novaera/core';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_DEFINITIONS } from '../keys';
import { GetPermissionDefinitionsResponse } from './types';

const getPermissionDefinitions = async () => {
  const result = await NvAxios.get<GetPermissionDefinitionsResponse>(`${PERMISSION_POLICIES_ROOT_PATH}/definitions`);
  return result?.data;
};

export const useGetPermissionDefinitions = () => {
  return useQuery<GetPermissionDefinitionsResponse>(QUERY_KEYS_PERMISSION_DEFINITIONS.all, () =>
    getPermissionDefinitions()
  );
};
