import { isAxiosError, NvAxios, useAxiosErrorHandler, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { QUERY_KEY_GET_SLACK_APP } from '../keys';

import { UpdateSlackAppParams, UpdateSlackAppResponse } from './types';

const updateSlackApp = async ({ appId, updateSlackAppRequestParams }: UpdateSlackAppParams) => {
  const result = await NvAxios.put<UpdateSlackAppResponse>(SLACK_APP_ROOT_PATH(appId), updateSlackAppRequestParams);
  return result?.data;
};

export const useUpdateSlackApp = (appId?: string) => {
  const cache = useQueryClient();
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const { addToast } = useToast();

  return useMutation(updateSlackApp, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEY_GET_SLACK_APP.detail(appId));
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else if (error instanceof Error) {
        addToast(`The app for Slack cannot be updated, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`The app for Slack cannot be updated, reason: ${JSON.stringify(error)}`, {
          variant: 'error',
        });
      }
    },
  });
};
