import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomGetConversationIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CustomGetConversation"
      {...props}
    >
      <g clip-path="url(#clip0_2145_112)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2 4H4.8C3.81 4 3 4.81 3 5.8V22L6.6 18.4H10.7544C10.5915 18.0681 10.5 17.6947 10.5 17.3C10.5 16.2748 11.117 15.3938 12 15.008V14.5C12 13.1193 13.1193 12 14.5 12H15.4998C15.9559 11.3928 16.6821 11 17.5 11C18.3179 11 19.0441 11.3928 19.5002 12H20.5C20.6712 12 20.8384 12.0172 21 12.05V5.8C21 4.81 20.19 4 19.2 4ZM9.17014 10.1078L9.17014 7.02696C9.17014 6.46213 8.73286 6 8.1984 6C7.66395 6 7.22667 6.46213 7.22667 7.02696V10.1078L5.48726 10.1078C5.04998 10.1078 4.8362 10.6624 5.14715 10.9807L7.8583 13.846C8.05264 14.0513 8.35388 14.0513 8.54823 13.846L11.2594 10.9807C11.5606 10.6624 11.3468 10.1078 10.9095 10.1078H9.17014Z"
          fill="currentColor"
        />
        <path
          d="M21.5 15.6842V14.6316C21.5 14.0526 21.05 13.5789 20.5 13.5789H19C19 12.7053 18.33 12 17.5 12C16.67 12 16 12.7053 16 13.5789H14.5C13.95 13.5789 13.5 14.0526 13.5 14.6316V15.6842C12.67 15.6842 12 16.3895 12 17.2632C12 18.1368 12.67 18.8421 13.5 18.8421V20.9474C13.5 21.5263 13.95 22 14.5 22H20.5C21.05 22 21.5 21.5263 21.5 20.9474V18.8421C22.33 18.8421 23 18.1368 23 17.2632C23 16.3895 22.33 15.6842 21.5 15.6842ZM15.25 17C15.25 16.5632 15.585 16.2105 16 16.2105C16.415 16.2105 16.75 16.5632 16.75 17C16.75 17.4368 16.415 17.7895 16 17.7895C15.585 17.7895 15.25 17.4368 15.25 17ZM19.5 19.8947H15.5V18.8421H19.5V19.8947ZM19 17.7895C18.585 17.7895 18.25 17.4368 18.25 17C18.25 16.5632 18.585 16.2105 19 16.2105C19.415 16.2105 19.75 16.5632 19.75 17C19.75 17.4368 19.415 17.7895 19 17.7895Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2145_112">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
