export const QUERY_KEYS_ACTIONER_EVENTS = {
  all: ['actioner-events'] as const,
  list: (appId?: string) => [...QUERY_KEYS_ACTIONER_EVENTS.all, 'list', ...(appId ? [appId] : [])] as const,
  detail: (appId?: string, eventId?: string) => [...QUERY_KEYS_ACTIONER_EVENTS.all, 'detail', appId, eventId] as const,
  usages: (appId?: string, eventId?: string) =>
    [...QUERY_KEYS_ACTIONER_EVENTS.all, 'detail', 'usages', appId, eventId] as const,
};

export const QUERY_KEYS_ACTIONER_EVENTS_ALL_APPS = {
  all: ['actioner-events-all-apps'] as const,
  list: (appId: string) => [...QUERY_KEYS_ACTIONER_EVENTS_ALL_APPS.all, 'list', appId] as const,
};
