import {
  CatalogEntityType,
  RelationshipOfCatalogEntities,
  useGetRelationshipsOfCatalogEntities,
} from '@novaera/actioner-service';
import { NvButton, NvCloseIcon, NvFlex, NvSkeleton } from '@novaera/core';
import { getStringifiedValue } from '@novaera/utils';
import { useMemo } from 'react';
import { PropertyPanelHeader } from '../../../../components';
import { CatalogDetailPanelItem } from './item';
import { Relationship } from './relationship';

export const CatalogDetailPanel = ({
  catalogEntity,
  entityType,
  onDetailPanelClose,
}: {
  catalogEntity?: Record<string, unknown>;
  entityType: CatalogEntityType;
  onDetailPanelClose: () => void;
}) => {
  const {
    data: relationshipsOfCatalogEntitiesQueries,
    isInitialLoading: isRelationshipsOfCatalogEntitiesLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetRelationshipsOfCatalogEntities({
    entityTypeId: entityType.id,
    entityId: catalogEntity?.id as string | undefined,
  });

  const entityItems = useMemo(() => {
    const items: {
      label: string;
      value: string | undefined;
    }[] = [
      {
        label: 'Id',
        value: getStringifiedValue(catalogEntity?.id),
      },
    ];

    entityType.parameters?.forEach((parameter) => {
      items.push({
        label: parameter.name,
        value: getStringifiedValue(catalogEntity?.[parameter.name]),
      });
    });

    if (catalogEntity?.customProperties) {
      items.push({
        label: 'Custom properties',
        value: JSON.stringify(catalogEntity.customProperties),
      });
    }

    items.push({
      label: 'Tags',
      value: getStringifiedValue(catalogEntity?.tags),
    });
    items.push({
      label: 'Created at',
      value: getStringifiedValue(catalogEntity?.createdAt),
    });
    items.push({
      label: 'Updated at',
      value: getStringifiedValue(catalogEntity?.updatedAt),
    });

    return items;
  }, [catalogEntity, entityType.parameters]);

  const relationshipsOfCatalogEntities = useMemo(
    () =>
      relationshipsOfCatalogEntitiesQueries?.pages
        .filter((p) => p)
        ?.reduce<RelationshipOfCatalogEntities[]>((acc, page) => [...acc, ...page.relationships], []) ?? [],
    [relationshipsOfCatalogEntitiesQueries?.pages]
  );

  return (
    <NvFlex flexDirection={'column'} width={'100%'}>
      <PropertyPanelHeader
        title={entityType.name}
        actions={
          <NvButton
            onlyIcon
            size="small"
            color="secondary"
            onClick={() => {
              onDetailPanelClose();
            }}
          >
            <NvCloseIcon />
          </NvButton>
        }
      />
      <NvFlex flexDirection={'column'} gap={'8px'} padding="16px">
        {entityItems?.map(({ label, value }) => (
          <CatalogDetailPanelItem key={label} label={label} value={value} />
        ))}
        {isRelationshipsOfCatalogEntitiesLoading ? (
          <NvFlex gap="8px" width="100%" alignItems="flex-start">
            <NvSkeleton variant="rectangular" width="100px" height="16px" />
            <NvSkeleton variant="rectangular" width="100%" height="32px" />
          </NvFlex>
        ) : (
          <NvFlex gap="8px" width="100%" alignItems="flex-start">
            {relationshipsOfCatalogEntities?.map(({ relationship, relatedEntity }) => (
              <Relationship
                key={relationship.id}
                entityType={entityType}
                relationship={relationship}
                relatedEntity={relatedEntity}
              />
            ))}
            {hasNextPage && (
              <NvButton
                size="small"
                color="secondary"
                onClick={() => {
                  return fetchNextPage();
                }}
                loading={isFetchingNextPage}
                disabled={isFetchingNextPage}
              >
                Show more
              </NvButton>
            )}
          </NvFlex>
        )}
        {}
      </NvFlex>
    </NvFlex>
  );
};
