import {
  NeutralChipColor,
  NvButton,
  NvChip,
  NvCircularProgress,
  NvFlex,
  NvRuleIcon,
  SectionMessage,
  useFormState,
} from '@novaera/core';
import classnames from 'classnames';

import {
  CommandExecuteWrapper,
  ExecutionLabel,
  RequestExecutionWrapper,
  StyledCommandExecute,
  StyledTestActionButtonWrapper,
} from './styled';

import { useParams } from '@novaera/route';
import { useCallback, useMemo, useState } from 'react';

import { useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { useInputsContext } from '../../../providers/input-provider';
import { ErrorMarker } from '../../error-marker';
import { ACTION_EXECUTION_BUTTON_ID } from '../constants';

export const TestAction: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setSelectedInputParameterId, selectedInputParameterId } = useInputsContext();

  const { actionId, integrationId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data: values } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
  });

  const [hideTestActionWarning, setHideTestActionWarning] = useState<boolean>(false);

  const showSectionMessage = useMemo(
    () =>
      !hideTestActionWarning &&
      values?.httpRequestConfigurations &&
      values?.httpRequestConfigurations?.length > 0 &&
      values.runConfiguration?.runSteps?.length === 0,
    [hideTestActionWarning, values?.httpRequestConfigurations, values?.runConfiguration?.runSteps?.length]
  );

  const handleSelectForInputParam = useCallback(() => {
    setSelectedInputParameterId(ACTION_EXECUTION_BUTTON_ID);
  }, [setSelectedInputParameterId]);

  const handleSectionMessageClose = () => {
    setHideTestActionWarning(true);
  };

  const hasError = false;
  const { submitting: isSubmitting } = useFormState();

  return (
    <RequestExecutionWrapper>
      <NvFlex justifyContent="center">
        <StyledCommandExecute
          onClick={handleSelectForInputParam}
          padding={1}
          className={classnames({
            'is-selected': ACTION_EXECUTION_BUTTON_ID === selectedInputParameterId,
            'has-error': hasError,
          })}
        >
          <CommandExecuteWrapper>
            <NvButton size="medium" variant="contained" onClick={handleSelectForInputParam} color="primary">
              {values?.runConfiguration?.label || 'Run'}
            </NvButton>

            <StyledTestActionButtonWrapper
              type="submit"
              size="small"
              variant="contained"
              loadingIndicator={<NvCircularProgress size={20} />}
              startIcon={<NvRuleIcon />}
              className="custom"
              loading={isSubmitting}
              isSelected={ACTION_EXECUTION_BUTTON_ID === selectedInputParameterId}
            >
              Test action
            </StyledTestActionButtonWrapper>
          </CommandExecuteWrapper>
        </StyledCommandExecute>
        {showSectionMessage && (
          <SectionMessage
            variant="info"
            message="Add the requests and functions in the order they will be executed when this action is run."
            onClose={handleSectionMessageClose}
          />
        )}

        <ExecutionLabel>
          <NvChip label="Run steps" compact color={NeutralChipColor.DARK_ALPHA}></NvChip>
        </ExecutionLabel>

        <NvFlex alignSelf="center" position="absolute" right="-2px" bottom="18px">
          {hasError && <ErrorMarker variant="circle" />}
        </NvFlex>
      </NvFlex>
    </RequestExecutionWrapper>
  );
};
