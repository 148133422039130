import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { HTTPPropertiesForm } from './http-properties-form';
import { HTTPPropertyPanelDrawerProps } from './types';

export const HTTPPropertyPanelDrawer: FC<HTTPPropertyPanelDrawerProps> = ({ handleClose, workflow }) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen } = usePropertyPanelContext();
  assert(!!selectedNode, new Error('[HTTPPropertyPanelDrawer] - selectedNode should not be undefined!'), 'ERROR');

  const { userAppId: appId, workflowId } = useParams();

  const { node: httpNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.http>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      {httpNode && !isLoading ? (
        <HTTPPropertiesForm httpNode={httpNode} handleClose={handleClose} workflow={workflow} />
      ) : (
        <NvFlex width={`430px`}>
          <PropertiesLoading />
        </NvFlex>
      )}
    </PropertyPaneWrapper>
  );
};
