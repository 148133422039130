import { red } from '@mui/material/colors';
import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Default, ThemeMode.Light);

export default {
  primary: {
    main: '#3A4452CC',
  },
  secondary: {
    main: '#19857b',
  },
  error: {
    main: red.A400,
  },
  grey: {
    '50': '#AA9329',
  },
  test: {
    value: 'testDefaultLight',
  },
  background: {
    paper: '#FFFFFF',
  },
  action: {
    active: colors.nv_neutral?.[1000],
  },
  ghost: {},
  text: {
    ...(colors.nv_neutral ? { primary: colors.nv_neutral[1000] } : {}),
    ...(colors.nv_neutral_alpha
      ? { secondary: colors.nv_neutral_alpha[300], disabled: colors.nv_neutral_alpha[60] }
      : {}),
  },
  ...colors,
};
