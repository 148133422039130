import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { APP_DEPENDENCIES_ROOT } from '../constants';
import { QUERY_KEYS_APP_DEPENDENCIES } from '../keys';
import { AppDependencyListItem } from '../types';
import { GetAppDependenciesResponse } from '../use-get-app-dependencies';

export type UpdateAppDependencyInstanceParam = {
  appId: string;
  dependencyId: string;
  payload: {
    name: string;
    dependentAppId: string;
  };
};

const updateAppDependencyInstance = async ({ appId, dependencyId, payload }: UpdateAppDependencyInstanceParam) => {
  const result = await NvAxios.patch<AppDependencyListItem>(
    `${APP_DEPENDENCIES_ROOT}/${appId}/dependencies/${dependencyId}`,
    payload
  );
  return result?.data;
};

export const useUpdateAppDependencyInstance = () => {
  const cache = useQueryClient();

  return useMutation(updateAppDependencyInstance, {
    onSuccess: (data, variables) => {
      cache.setQueryData<GetAppDependenciesResponse>(
        QUERY_KEYS_APP_DEPENDENCIES.lists({ appId: variables.appId }),
        (old) => {
          const updatedCache =
            old?.dependencies.map(({ dependency, dependentApp }) => {
              if (dependency.id === variables.dependencyId) {
                return {
                  ...data,
                };
              }
              return { dependentApp, dependency };
            }) || [];

          return {
            dependencies: updatedCache,
          };
        }
      );
    },
  });
};
