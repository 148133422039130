import { DataColumn, WorkflowReferenceColumn } from '@novaera/actioner-service';
import { FC, useCallback, useMemo } from 'react';
import { WorkflowReferenceAdapter } from './buton-column/workflow-reference-adapter';
import { DataColumns } from './data-columns';
import { ColumnsProps } from './types';

export const Columns: FC<React.PropsWithChildren<ColumnsProps>> = ({ value: columns, onChange, context }) => {
  const dataColumns = useMemo(
    () => columns?.filter((column): column is DataColumn => column.type === 'data'),
    [columns]
  );

  const buttonColumn = useMemo(() => {
    const buttonColumn = columns?.find(
      (column): column is WorkflowReferenceColumn => column.type === 'workflow-reference'
    );
    return buttonColumn;
  }, [columns]);

  const handleDataColumnsChange = useCallback<(newDataColumns?: DataColumn[]) => void>(
    (newDataColumns) => {
      if (newDataColumns) {
        if (buttonColumn) {
          const newMergedColumns = [...newDataColumns, buttonColumn];
          onChange?.(newMergedColumns);
        } else {
          onChange?.(newDataColumns);
        }
      } else {
        if (buttonColumn) {
          const newMergedColumns = [buttonColumn];
          onChange?.(newMergedColumns);
        } else {
          onChange?.();
        }
      }
    },
    [buttonColumn, onChange]
  );

  const handleWorkflowReferenceColumnChange = useCallback<
    (newWorkflowReferenceColumn?: WorkflowReferenceColumn) => void
  >(
    (newWorkflowReferenceColumn) => {
      if (newWorkflowReferenceColumn) {
        if (dataColumns) {
          const newMergedColumns = [...dataColumns, newWorkflowReferenceColumn];
          onChange?.(newMergedColumns);
        } else {
          onChange?.([newWorkflowReferenceColumn]);
        }
      } else {
        if (dataColumns) {
          const newMergedColumns = dataColumns;
          onChange?.(newMergedColumns);
        } else {
          onChange?.();
        }
      }
    },
    [dataColumns, onChange]
  );

  return (
    <>
      <DataColumns dataColumns={dataColumns} onChange={handleDataColumnsChange} />
      <WorkflowReferenceAdapter
        context={context}
        buttonColumn={buttonColumn}
        onChange={handleWorkflowReferenceColumnChange}
      />
    </>
  );
};
