import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppDirectoryMenuWrapper = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'fullWidth' })<{
  fullWidth?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '264px')};
  gap: 8px;
`;

export const AppDirectoryMenuItem = styled('div', { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>`
  padding: 7px 12px;
  border-radius: 6px;
  background: ${({ theme, isSelected }) => (isSelected ? theme.palette.nv_main[20] : 'transparent')};
  color: ${({ theme, isSelected }) => (isSelected ? theme.palette.nv_main[60] : 'inherit')};
  cursor: pointer;

  &:hover {
    background: ${({ theme, isSelected }) =>
      isSelected ? theme.palette.nv_main[20] : theme.palette.nv_neutral_alpha[20]};
    color: ${({ theme, isSelected }) => (isSelected ? theme.palette.nv_main[60] : 'inherit')};
  }
`;
