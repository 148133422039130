import {
  NvArticleIcon,
  NvCustomDependencyIcon,
  NvDNSIcon,
  NvManageAccountsIcon,
  NvSensorsIcon,
  NvSlackIconNotColored,
  NvSmartToyIcon,
  NvTimerIcon,
  NvTuneIcon,
  NvWorkflowIcon,
} from '@novaera/core';
import { ReactNode } from 'react';

export type SIDE_MENU_ITEM_ID =
  | 'documentation'
  | 'configs'
  | 'workflows'
  | 'ai-assistant'
  | 'dependencies'
  | 'events'
  | 'tables'
  | 'jobs'
  | 'standalone-slack-app'
  | 'permissions';

export type SIDE_MENU_ITEM_IDS_UNDER_SETTINGS = Omit<SIDE_MENU_ITEM_ID, 'documentation'>;

export const getSideMenuItemIdsUnderSettings = (): SIDE_MENU_ITEM_IDS_UNDER_SETTINGS[] => [
  'configs',
  'workflows',
  'ai-assistant',
  'dependencies',
  'events',
  'tables',
  'jobs',
  'standalone-slack-app',
  'permissions',
];

type SIDE_MENU_ITEM = { id: SIDE_MENU_ITEM_ID; title: string; icon: ReactNode };

export const SIDE_MENU_ITEMS: SIDE_MENU_ITEM[] = [
  {
    id: 'documentation',
    title: 'Documentation',
    icon: <NvArticleIcon />,
  },
  {
    id: 'configs',
    title: 'Configs',
    icon: <NvTuneIcon />,
  },
  {
    id: 'workflows',
    title: 'Workflows',
    icon: <NvWorkflowIcon />,
  },
  {
    id: 'ai-assistant',
    title: 'AI assistant',
    icon: <NvSmartToyIcon />,
  },
  {
    id: 'dependencies',
    title: 'Dependencies',
    icon: <NvCustomDependencyIcon />,
  },
  {
    id: 'events',
    title: 'Events',
    icon: <NvSensorsIcon />,
  },
  {
    id: 'tables',
    title: 'Tables',
    icon: <NvDNSIcon />,
  },
  {
    id: 'jobs',
    title: 'Jobs',
    icon: <NvTimerIcon />,
  },
  {
    id: 'standalone-slack-app',
    title: 'Standalone app for Slack',
    icon: <NvSlackIconNotColored />,
  },
  { id: 'permissions', title: 'Permissions', icon: <NvManageAccountsIcon /> },
];

export const SIDE_MENU_ITEMS_FOR_MENU: Record<SIDE_MENU_ITEM_ID, SIDE_MENU_ITEM> = SIDE_MENU_ITEMS.reduce(
  (acc, item) => {
    acc[item.id] = item;
    return acc;
  },
  {} as Record<SIDE_MENU_ITEM_ID, SIDE_MENU_ITEM>
);
