import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS, QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE } from '../keys';
import { UseGetWorkflowResponse } from '../use-get-workflow/types';

import { QUERY_KEYS_WORKFLOW_FORM_PARAMETERS } from '../../user-app/keys';
import { SaveWorkflowParams } from './types';

const saveWorkflow = async (workflow: SaveWorkflowParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, ...restOfWorkflow } = workflow;
  return await NvAxios.put(`${USER_APP_ROOT_PATH}/${workflow.appId}/workflows/${workflow.id}/drafts`, restOfWorkflow);
};

export const useSaveToDraftWorkflow = () => {
  const cache = useQueryClient();

  return useMutation(saveWorkflow, {
    onMutate: (workflowParam) => {
      const oldWorkflowDetail = cache.getQueryData<UseGetWorkflowResponse>(
        QUERY_KEYS_WORKFLOWS.detail({ appId: workflowParam.appId, workflowId: workflowParam.id })
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { state, ...restOfWorkflow } = workflowParam;

      cache.invalidateQueries(QUERY_KEYS_WORKFLOW_FORM_PARAMETERS.all);

      cache.invalidateQueries(
        QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE.detail({
          appId: workflowParam.appId,
          workflowId: workflowParam.id,
        })
      );

      cache.setQueryData<UseGetWorkflowResponse>(
        QUERY_KEYS_WORKFLOWS.detail({ appId: workflowParam.appId, workflowId: workflowParam.id }),
        (old) =>
          old
            ? {
                saved: old?.saved,
                draft: restOfWorkflow,
              }
            : undefined
      );

      return {
        oldWorkflowDetail,
      };
    },
    onError: (_error, workflowParam, context) => {
      if (context) {
        const { oldWorkflowDetail } = context;
        cache.setQueryData<UseGetWorkflowResponse>(
          QUERY_KEYS_WORKFLOWS.detail({ appId: workflowParam.appId, workflowId: workflowParam.id }),
          oldWorkflowDetail
        );
      }
    },
  });
};
