import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CompletionActions = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  position: sticky;
  bottom: 0;
`;

export const HelpWithBullets = styled('ul')`
  padding-left: 20px;
  margin: 0;
`;
