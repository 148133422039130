import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { VideoAndImagesCardProps } from './types';

const VideoAndImagesCardOptions = {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'isEditMode' && prop !== 'disabled',
};

export const VideoAndImagesCard = styled(NvBox, VideoAndImagesCardOptions)<VideoAndImagesCardProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${({ theme, isEditMode }) => (isEditMode ? theme.palette.nv_neutral[0] : 'transparent')};
  padding: ${({ isEditMode }) => (isEditMode ? '16px' : '0')};

  .edit-media-button {
    visibility: hidden;
  }
`;

export const MediaFilesScrollWrapper = styled(NvBox)`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const MediaFileWrapper = styled(NvBox)`
  width: 130px;
  height: 72px;
  min-width: 130px;
  min-height: 72px;

  img {
    width: 100%;
    height: 100%;
  }
`;
