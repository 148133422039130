import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const UserRolePopoverItem = styled(NvFlex)`
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  }
`;
