import { NvBox, NvEditIcon, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PropertyPanelHeaderCard = styled(NvBox)`
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  height: 60px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  flex: 0 0 auto;
`;

export const PropertyPanelHeaderCardBody = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1 1 auto;
  min-width: 0;
`;

export const PropertyPanelHeaderIconBox = styled(NvBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

export const PropertyPanelHeaderCardActions = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: 0 0 auto;
`;

export const PropertyPanelHeaderTitleEditModeWrapper = styled(NvFlex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  z-index: 1;
`;

export const PropertyPanelHeaderTitleEditIcon = styled(NvEditIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_neutral[600]};
  &.MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.nv_main[40]};
  }
`;
