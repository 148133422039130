import { CustomCronJobRecurrenceConfiguration, RecurrenceTimeUnit, ValueTypes } from '@novaera/actioner-service';
import { NvMenuWithItems, NvSettingsIcon, NvSideGroupedInputLayout } from '@novaera/core';
import { ParameterMappingSettingsMenu } from '../../../../../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { DEFAULT_CRON_EXPRESSION } from '../../../../../../../../../../jobs/constants';
import { RecurrenceFieldCard } from '../../../styled';
import { RecurrenceProps } from '../types';
import { DynamicCustomRecurrence } from './dynamic';
import { StaticCustomRecurrence } from './static';

export const CustomRecurrence: React.FC<RecurrenceProps<CustomCronJobRecurrenceConfiguration>> = ({
  recurrenceConfiguration,
  context,
  onChange,
}) => {
  return (
    <RecurrenceFieldCard>
      <NvSideGroupedInputLayout
        variant={'default'}
        rightAction={
          <NvMenuWithItems
            triggerButton={{
              content: <NvSettingsIcon />,
              props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
            }}
            menuItems={
              <ParameterMappingSettingsMenu
                hiddenConfig={{
                  show: false,
                }}
                dynamicStaticConfig={{
                  show: true,
                  isScripted: recurrenceConfiguration.dynamic,
                  onItemClicked: (type) => {
                    const newScriptedValue = type === 'static' ? false : true;

                    if (recurrenceConfiguration.dynamic !== newScriptedValue) {
                      onChange({
                        ...(!newScriptedValue
                          ? {
                              type: RecurrenceTimeUnit.CUSTOM,
                              cronExpression: DEFAULT_CRON_EXPRESSION,
                              dynamic: false,
                            }
                          : {
                              type: RecurrenceTimeUnit.CUSTOM,
                              dayOfMonth: { type: ValueTypes.STRING, value: '' },
                              hour: { type: ValueTypes.STRING, value: '' },
                              minute: { type: ValueTypes.STRING, value: '' },
                              month: { type: ValueTypes.STRING, value: '' },
                              dayOfWeek: { type: ValueTypes.STRING, value: '' },
                              year: { type: ValueTypes.STRING, value: '' },
                              dynamic: true,
                            }),
                      });
                    }
                  },
                }}
              />
            }
          />
        }
        formItem={
          recurrenceConfiguration.dynamic ? (
            <DynamicCustomRecurrence
              recurrenceConfiguration={recurrenceConfiguration}
              context={context}
              onChange={onChange}
            />
          ) : (
            <StaticCustomRecurrence recurrenceConfiguration={recurrenceConfiguration} onChange={onChange} />
          )
        }
      />
    </RecurrenceFieldCard>
  );
};
