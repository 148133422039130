import { SkeletonStyled } from './styled';
import { NvSkeletonProps } from './types';
/**
 * NvSkeleton wraps Skeleton component of @mui/material/Skeleton with custom bg-color and border-radius.
 * There are 5 size options for Skeleton component with text variant.
 * 'xs', 's', 'm', 'l', 'xl'
 * size prop can only be used with text variant of Skeleton component. It is useless with circular and rectangular variants.
 */
export const NvSkeleton: React.FC<React.PropsWithChildren<NvSkeletonProps>> = ({ size, ...props }) => {
  return <SkeletonStyled size={size} {...props} />;
};
