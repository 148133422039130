import { styled } from '@mui/material/styles';
import { NvBox } from '../box';

export const PaginationDirectionWrapper = styled(NvBox)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: ${({ theme, disabled }) => (disabled ? theme.palette.nv_neutral_alpha[60] : theme.palette.nv_neutral[1000])};

  &:hover {
    background: ${({ theme, disabled }) => (!disabled ? theme.palette.nv_neutral_alpha[20] : 'transparent')};
  }
`;
