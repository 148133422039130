import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomUpgradeIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2001_124)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM10.7872 11.8652V15.7163C10.7872 16.4223 11.3338 17 12.0019 17C12.6699 17 13.2165 16.4223 13.2165 15.7163V11.8652H15.3908C15.9374 11.8652 16.2046 11.172 15.8159 10.7741L12.427 7.19255C12.1841 6.93582 11.8075 6.93582 11.5646 7.19255L8.17566 10.7741C7.79912 11.172 8.06634 11.8652 8.61294 11.8652H10.7872Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2001_124">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
