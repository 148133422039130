import { FilteredComponent } from '../../../types';

export const commandProperties: FilteredComponent = {
  selectedComponent: 'command',
  propertyIds: [
    {
      id: 'command.basic.label',
    },
    {
      id: 'execute.requests',
    },
  ],
};
