import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_RECORDS } from '../keys';
import { SearchRecordsResponse } from '../use-search-records/types';
import { BatchDeleteRecordsParams } from './types';

const batchDeleteRecords = async (params: BatchDeleteRecordsParams) => {
  const { appId, modelId, recordIds } = params;
  const result = await NvAxios.post(
    `${USER_APP_ROOT_PATH}/${appId}/data-models/${modelId}/records/batch-delete`,
    recordIds
  );
  return result?.data;
};

export const useBatchDeleteRecords = () => {
  const queryClient = useQueryClient();
  return useMutation(batchDeleteRecords, {
    onSuccess: (data, { appId, modelId, recordIds }) => {
      const allCachedSearchRecords = queryClient.getQueriesData<SearchRecordsResponse>(
        QUERY_KEYS_RECORDS.detail({ appId, modelId })
      );
      allCachedSearchRecords.forEach(([cacheKey, cachedSearchRecords]) => {
        if (cachedSearchRecords) {
          const updatedCachedSearchRecords = {
            ...cachedSearchRecords,
            ...(cachedSearchRecords.records.length > 0
              ? {
                  records: cachedSearchRecords.records.filter(({ id }) => !recordIds.includes(id as string)),
                  totalHits: cachedSearchRecords.totalHits - recordIds.length,
                }
              : { records: cachedSearchRecords.records, totalHits: cachedSearchRecords.totalHits }),
          };
          queryClient.setQueryData(cacheKey, updatedCachedSearchRecords);
        }
      });
    },
  });
};
