import { useBatchGetUsers } from '../use-batch-get-users';
import { useFetchProfile } from '../use-fetch-profile';

export const useGetActiveUser = ({ enabled }: { enabled?: boolean } | undefined = { enabled: true }) => {
  const { data: profile, isInitialLoading: isFetchProfileLoading } = useFetchProfile({ enabled });
  const { data, isInitialLoading: isBatchGetUsersLoading } = useBatchGetUsers(
    profile?.userId && enabled ? [profile.userId] : []
  );
  const user = data?.users[0];

  return { user, isLoading: isFetchProfileLoading || isBatchGetUsersLoading };
};
