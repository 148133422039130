import { Markdown, NvFlex, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { DescriptionProps } from './types';

export const DescriptionView: FC<React.PropsWithChildren<DescriptionProps>> = ({ value }) => {
  return (
    <NvFlex>
      {value && value.length > 0 ? (
        <Markdown style={{ wordBreak: 'break-word' }}>{value}</Markdown>
      ) : (
        <NvTypography variant="body1" textColor="ghost">
          <i>There is no description</i>
        </NvTypography>
      )}
    </NvFlex>
  );
};
