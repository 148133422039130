import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type SendEmailProps = BaseNodeProps;

export const SendEmail: FC<SendEmailProps> = (props) => {
  return (
    <DraggableItem nodeId={props.id}>
      <Node type={NodeType.SEND_EMAIL} {...props} />
    </DraggableItem>
  );
};
