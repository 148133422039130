import { AppSchemasPayload } from './types';
import { generatedAppSchemasSearchKey } from './utils';

export const QUERY_KEYS_APP_DIRECTORY = {
  all: ['app-directory'] as const,
  lists: (payload?: AppSchemasPayload) =>
    [...QUERY_KEYS_APP_DIRECTORY.all, 'list', ...(payload ? [generatedAppSchemasSearchKey(payload)] : [])] as const,
  detail: (schemaId?: string, version?: string, isAnonymous?: boolean) =>
    [
      ...QUERY_KEYS_APP_DIRECTORY.all,
      'detail',
      schemaId,
      version ?? 'latest',
      isAnonymous ? 'anonymous' : 'authenticated',
    ] as const,

  version: ['versions'] as const,
  versions: () => [...QUERY_KEYS_APP_DIRECTORY.version] as const,
  versionHistory: (schemaId?: string) => [...QUERY_KEYS_APP_DIRECTORY.versions(), schemaId],

  searchMetadata: ['searchMetadata'] as const,

  contributors: ['contributors'] as const,

  preview: ['preview'] as const,
  previews: () => [...QUERY_KEYS_APP_DIRECTORY.preview] as const,
  previewDetail: (schemaId?: string) => [...QUERY_KEYS_APP_DIRECTORY.previews(), schemaId],
};
