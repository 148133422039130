import { USER_APP_LIST } from '../constants';

export enum WORKFLOW_DETAIL_MODES {
  DESIGNER = 'designer',
  HISTORY = 'history',
}

export const WORKFLOW_DETAIL = ({ appId, workflowId }: { appId: string; workflowId: string }) =>
  USER_APP_LIST + `/${appId}/workflows/${workflowId}`;
export const WORKFLOW_DESIGNER = ({ appId, workflowId }: { appId: string; workflowId: string }) =>
  `${WORKFLOW_DETAIL({ appId, workflowId })}/${WORKFLOW_DETAIL_MODES.DESIGNER}`;
export const WORKFLOW_HISTORY = ({ appId, workflowId }: { appId: string; workflowId: string }) =>
  `${WORKFLOW_DETAIL({ appId, workflowId })}/${WORKFLOW_DETAIL_MODES.HISTORY}`;
export const WORKFLOW_HISTORY_DETAIL = ({
  appId,
  workflowId,
  executionId,
}: {
  appId: string;
  workflowId: string;
  executionId: string;
}) => `${WORKFLOW_HISTORY({ appId, workflowId })}/${executionId}`;
