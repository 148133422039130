import { NvAxios, useQuery } from '@novaera/core';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { UseGetUsersInGroupParams, UseGetUsersInGroupResponse } from '../types';

const getUsersInGroup = async (params: UseGetUsersInGroupParams) => {
  const result = await NvAxios.get<UseGetUsersInGroupResponse>(`${GROUPS_ROOT_PATH}/${params.id}/users`);
  return result?.data;
};

export const useGetUsersInGroup = (params: UseGetUsersInGroupParams) => {
  return useQuery<UseGetUsersInGroupResponse>(QUERY_KEYS_GROUPS.users(params.id), () => getUsersInGroup(params), {
    enabled: !!params.id,
  });
};
