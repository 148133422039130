import { FieldArray, NvBox, NvFlex, NvTypography, useFormState } from '@novaera/core';
import { ValidationErrors, getIn } from 'final-form';
import React from 'react';
import { BaseListItem } from '../item/base-list-item';
import { BaseListItemProps } from '../item/base-list-item/types';
import { RelationshipBlockDataModelHeader } from './data-model-header';

type RelationshipBlockProps<T> = {
  items?: T[];
  title: string;
  actions?: React.ReactNode;
  RightContent?: (item?: T) => React.ReactNode;
  onItemSelected: (selectedItem: T, index: number) => void;
  fieldName: string;
  isSelected: (data: T, index: number) => boolean;
  listItemFormat?: BaseListItemProps<T>['format'];
};

const hasFieldError = (errors: ValidationErrors, fieldName: string) => {
  return errors ? getIn(errors, fieldName) : undefined;
};

export const RelationshipBlocks = <T,>({
  items,
  title,
  actions,
  onItemSelected,
  RightContent,
  fieldName,
  isSelected,
  listItemFormat,
}: RelationshipBlockProps<T>) => {
  const { errors } = useFormState();

  return !items || items.length === 0 ? (
    <NvFlex gap="12px">
      <NvBox display="flex">
        <NvTypography variant="h3">{title}</NvTypography>
      </NvBox>

      {actions && <NvBox>{actions}</NvBox>}
    </NvFlex>
  ) : (
    <NvFlex gap="16px">
      <NvBox display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
        <NvBox display="flex" alignItems="center">
          <NvTypography variant="h3">{title}</NvTypography>
        </NvBox>
        {actions && <NvBox>{actions}</NvBox>}
      </NvBox>

      <NvFlex gap="12px">
        <FieldArray name={fieldName}>
          {({ fields }) =>
            fields.map((name, index) => {
              const item = fields.value[index];
              return (
                <BaseListItem
                  key={`base-list-item-${name}`}
                  isSelected={isSelected(item, index)}
                  header={<RelationshipBlockDataModelHeader item={item} isSelected={isSelected(item, index)} />}
                  onClick={() => {
                    onItemSelected(item, index);
                  }}
                  hasError={hasFieldError(errors, `${fieldName}[${index}]`)}
                  RightContent={RightContent}
                  item={item}
                  index={index}
                  format={listItemFormat}
                />
              );
            })
          }
        </FieldArray>
      </NvFlex>
    </NvFlex>
  );
};
