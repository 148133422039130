export const REQUIRED_MSG = 'This field is required.';
export const ALL_REQUIRED_MSG = 'No null or undefined value allowed in this array';
export const VALIDATION_MSG = 'This field cannot contain special characters and space';
export const MIN_REQUIRED_CHAR_MSG = (length: number) => `Field should have at least ${length} length`;
export const MAX_REQUIRED_CHAR_MSG = (length: number) => `Field should have at most ${length} length`;
export const ONLY_WORD_CHAR = 'Valid characters are A-Z a-z 0-9 and _';
export const UNIQUE_NAME = 'This field should be unique in list';
export const INVALID_JSON_MESSAGE = 'Invalid JSON';
export const STRING_WITHOUT_EMPTY_CHAR = 'It should not contains any empty space character';
export const TIME_VALIDATION_MESSAGE = 'This is not a valid time.';
export const VALID_URL_MESSAGE = 'Invalid url';
export const MUSTACHE_VALIDATION_MESSAGE = 'Curly braces are not required here.';
