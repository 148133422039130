import { DataGridEmptyState, NvArrowDropDownIcon, NvArrowDropUpIcon, NvCheckbox, NvPagination } from '@novaera/core';

import { RecordDataGrid } from './styled';
import { RecordTableProps } from './types';

export const RecordsTable = ({ totalHits, ...props }: RecordTableProps) => {
  const { components } = props;

  const overriddenComponents = {
    ...components,
    Pagination: () => <NvPagination totalCount={totalHits} />,
    ColumnSortedAscendingIcon: NvArrowDropUpIcon,
    ColumnSortedDescendingIcon: NvArrowDropDownIcon,
    NoRowsOverlay: DataGridEmptyState,
    BaseCheckbox: NvCheckbox,
  };

  return (
    <RecordDataGrid
      headerHeight={32}
      rowHeight={32}
      columnBuffer={100}
      columnThreshold={100}
      rowThreshold={10}
      rowBuffer={10}
      hideFooterSelectedRowCount
      checkboxSelection
      disableSelectionOnClick
      pagination
      paginationMode="server"
      sortingMode="server"
      rowCount={totalHits}
      components={overriddenComponents}
      {...props}
    />
  );
};
