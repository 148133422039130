import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const TrialCard = styled(NvFlex)`
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
`;

export const UsageWrapper = styled(NvFlex)`
  flex-direction: row;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[700]};
  background: ${({ theme }) => theme.palette.nv_neutral[900]};
`;
