import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';

import { UseGetBatchUserAppsParams, UseGetBatchUserAppsResponse } from './types';

const getBatchUserApps = async (params: UseGetBatchUserAppsParams) => {
  const result = await NvAxios.post<UseGetBatchUserAppsResponse>(`${USER_APP_ROOT_PATH}/batch-get`, {
    appIds: params.appIds,
  });
  return result?.data;
};

export const useGetBatchUserApps = (params: UseGetBatchUserAppsParams) => {
  return useQuery<UseGetBatchUserAppsResponse>(
    QUERY_KEYS_USER_APP.batch(params.appIds),
    () => getBatchUserApps(params),
    {
      enabled: params?.appIds?.length > 0,
    }
  );
};
