import { NvField, NvFlex } from '@novaera/core';
import React from 'react';
import { PropertyListProps } from './types';

export const PropertyList: React.FC<React.PropsWithChildren<PropertyListProps>> = ({ propertyPropsList }) => (
  <NvFlex {...(propertyPropsList.length > 1 ? { gap: '8px' } : {})}>
    {propertyPropsList.map(({ hasOwnFieldImplementation, component, ...restOfPropertyProps }) => {
      if (hasOwnFieldImplementation) {
        // MURAT
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <React.Fragment key={restOfPropertyProps.name}>{component as any}</React.Fragment>;
      } else {
        return (
          <NvField
            key={restOfPropertyProps.name}
            direction="label-on-side"
            {...restOfPropertyProps}
            component={component}
            labelVariant="h6"
          />
        );
      }
    })}
  </NvFlex>
);
