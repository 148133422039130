import { ValueTypes } from '@novaera/actioner-service';
import { CodeInput, NvField, NvFingerPrintIcon } from '@novaera/core';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import { JobIDProps } from './types';

export const JobID: React.FC<JobIDProps> = ({ context }) => (
  <PropertyPanelSection title={'Job ID'} icon={<NvFingerPrintIcon />}>
    <NvField
      formControlStyle={{ width: '100%' }}
      name={'jobId'}
      parse={(v) => ({ type: ValueTypes.STRING, value: v })}
      format={(v) => v?.value}
      component={<CodeInput context={context} />}
    />
  </PropertyPanelSection>
);
