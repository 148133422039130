import { Edge } from '../../edge-ui-components/edge';
import { EdgeForLoop } from '../../edge-ui-components/edge-loop';
import { EdgeWithEndPlusButton } from '../../edge-ui-components/edge-with-end-plus';
import { EdgeWithButton } from '../../edge-ui-components/edge-with-plus';
import { EdgeWithStartPlusButton } from '../../edge-ui-components/edge-with-start-plus';

export const APP_WORKFLOW_EDGE_TYPES = {
  EdgeWithButton,
  Edge,
  EdgeForLoop,
  EdgeWithEndPlusButton,
  EdgeWithStartPlusButton,
};
