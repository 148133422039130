import { NvDialogModal, NvField, NvTextField, isRequired } from '@novaera/core';
import { useConnectionNameUpdateController } from './controllers/use-connection-name-update';
import { ConnectionNameUpdateModalFormType, ConnectionNameUpdateModalProps } from './types';

export const ConnectionNameUpdateModal = ({
  isOpened,
  connectionId,

  connectionName,
  onClose,
}: ConnectionNameUpdateModalProps) => {
  const { onFormSubmit, isLoading } = useConnectionNameUpdateController({
    connectionId,
    onClose,
  });

  return (
    <NvDialogModal<ConnectionNameUpdateModalFormType>
      modalIcon="edit"
      maxWidth="sm"
      fullWidth
      open={isOpened || false}
      Header="Edit connection name"
      isLoading={isLoading}
      primaryButtonText="Update"
      formProps={{
        initialValues: {
          name: connectionName,
        },
      }}
      Body={
        <NvField
          isRequired
          hasRequiredIndicator
          validators={[isRequired()]}
          showErrorMessageOnlyWhenBlur
          name="name"
          labelText="Connection name"
          direction="label-on-top"
          component={<NvTextField />}
        />
      }
      onFormSubmit={onFormSubmit}
      onCloseButtonClicked={onClose}
    />
  );
};
