import { AccessType, GroupEntity, useGetGroups } from '@novaera/actioner-service';
import {
  DefaultRenderOption,
  NvAutocomplete,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  isRequired,
} from '@novaera/core';
import { useMemo } from 'react';
import { UserAndGroupImage } from '../../../../../components/user-and-group-image';

export const AttachGroupModalBody = ({ currentGroups }: { currentGroups?: GroupEntity[] }) => {
  const { data } = useGetGroups();

  const groupOptions = useMemo(
    () => data?.groups.filter((group) => !currentGroups?.find((g) => g.id === group.id)) ?? [],
    [currentGroups, data?.groups]
  );

  return (
    <NvField
      labelText="Group"
      direction="label-on-top"
      name="group"
      isAutoComplete
      hasRequiredIndicator
      showErrorMessageOnlyWhenBlur
      validators={[isRequired()]}
      component={
        <NvAutocomplete
          options={groupOptions}
          size="small"
          getOptionLabel={(option) => (option ? `${option.name}` : '')}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          renderOption={(props, option) => (
            <DefaultRenderOption {...props} key={option.id}>
              <NvFlex direction="row" alignItems="center" gap="8px">
                <UserAndGroupImage src={option.logoUrl} type={AccessType.GROUP} id={option.id} size="smaller" />
                <NvTypography>{option.name}</NvTypography>
              </NvFlex>
            </DefaultRenderOption>
          )}
          renderInput={(params) => (
            <NvTextField
              {...params}
              placeholder="Select group"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              size="medium"
            />
          )}
        />
      }
    />
  );
};
