import { ParameterTypes, SimpleParameterMapping, StringValue, ValueTypes } from '@novaera/actioner-service';
import {
  FormHelperErrorText,
  Initial,
  NvActionFilledIcon,
  NvButton,
  NvChip,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvFlex,
  NvImage,
  NvMenuWithItems,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import {
  ActionParameterMapper,
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
  TestActionProvider,
  TestActionResult,
  UpdateVersionButton,
} from '../../../../../../../../components';
import { ActionSearchAutoComplete } from '../../../../../../../../components/action-search-field/action-search-auto-complete';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { SlackWorkspaceOrWorkflowConnectionSection } from '../../../../components/slack-workspace-or-workflow-connection-section';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { PropertiesLoading } from '../../common/properties-loading';
import { IntegrationBox } from '../../integration-trigger-property-panel-drawer/integration-trigger-properties/styled';
import { JobTargetParameterMapping } from './job-target-parameter-mapping';
import { RecordsParameterMapping } from './records-parameter-mapping';
import { SearchEntitiesFilterParameterMapping } from './search-entities-filter-parameter-mapping';
import { SlackSendMessageParameterMapping } from './slack-send-message-parameter-mapping';
import { ActionNodePropertiesProps } from './types';
import { useActionNodeProperties } from './use-action-node-properties';

export const ActionNodeProperties: FC<React.PropsWithChildren<ActionNodePropertiesProps>> = ({
  onCloseClicked,
  onSlackItemSelected,
  onParameterMappingChanged,
  onJobTargetParameterSelected,
  actionNode,
  workflow,
}) => {
  const { userAppId, workflowId } = useParams();
  const {
    integration,
    theme,
    actions,
    isGettingIntegrationActions,
    selectedAction,
    hasSlackBlocks,
    isRecordIntegration,
    hasJobTarget,
    hasCatalogRelationshipFilter,
    context,
    handleUpdateNode,
    deleteNodeAndUpdateGraph,
  } = useActionNodeProperties({
    actionNode,
  });

  const { hasEditPermission, isManagedApp } = useWorkflowPermission();

  return (
    <NvFlex width="100%">
      {integration ? (
        <>
          <NvConditionalRender when={hasEditPermission}>
            <PropertyPanelHeader
              title={actionNode.name}
              type={NodeType.ACTION}
              onTitleChange={async (newValue) => {
                assert(!!newValue, new Error('Name should be existed as new value'), 'ERROR');
                handleUpdateNode({ actionNode: { ...actionNode, name: newValue }, isUpdateGraph: true });
              }}
              validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
              actions={
                <>
                  <NvMenuWithItems
                    menuItems={[
                      {
                        name: 'Delete',
                        icon: (
                          <NvDeleteOutlineIcon
                            htmlColor={theme.palette.nv_error[40]}
                            sx={{ width: '16px', height: '16px' }}
                          />
                        ),
                        onClick: () => {
                          deleteNodeAndUpdateGraph({ nodeId: actionNode.alias });
                        },
                      },
                    ]}
                  />

                  <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                    <NvCloseIcon />
                  </NvButton>
                </>
              }
            />
          </NvConditionalRender>
          <NvConditionalRender when={!hasEditPermission}>
            <PropertyPanelHeader
              title={actionNode.name}
              type={NodeType.ACTION}
              validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
          </NvConditionalRender>
          <PropertyPanelSection icon={<NvActionFilledIcon />} title="Action">
            <NvFlex gap="8px">
              <IntegrationBox>
                <NvImage
                  imageShape="square"
                  src={integration?.logoUrl}
                  FallBack={<Initial size={'small'} value={integration?.name} color={theme.palette.nv_neutral[500]} />}
                  size="small"
                  hasNoElevation
                  noBorderRadius
                />
                <NvTypography variant="h5" textColor="main" flex="1 1 auto" noWrap>
                  {integration?.name}
                </NvTypography>
                {hasEditPermission && !isManagedApp && integration.latestVersion.number !== actionNode.version && (
                  <UpdateVersionButton
                    onClick={() => {
                      handleUpdateNode({
                        actionNode: { ...actionNode, version: integration.latestVersion.number },
                      });
                    }}
                  />
                )}
                {integration.scope.type === 'workspace' ? <NvChip label="custom" compact /> : undefined}
                <NvTypography variant="body2" textColor="subtle">
                  v{actionNode.version}.0.0
                </NvTypography>
              </IntegrationBox>

              {actions ? (
                <NvFlex>
                  <ActionSearchAutoComplete
                    options={actions.actions ?? []}
                    error={!selectedAction}
                    value={selectedAction}
                    isLoading={isGettingIntegrationActions}
                    onChange={(event, value) => {
                      if (!hasEditPermission) {
                        return;
                      }

                      if (value) {
                        let initialParameterMapping: SimpleParameterMapping<StringValue>[] = [];
                        if (isRecordIntegration || hasJobTarget) {
                          const appIdInputParameterId = value.inputParameters?.find(({ name }) => name === 'appId')?.id;
                          if (appIdInputParameterId && userAppId) {
                            initialParameterMapping = [
                              {
                                type: ParameterTypes.SIMPLE,
                                parameterId: appIdInputParameterId,
                                value: { type: ValueTypes.STRING, value: `{{app.id}}` },
                              },
                            ];
                          }
                        }

                        handleUpdateNode({
                          actionNode: {
                            ...actionNode,
                            actionId: value.id,
                            version: value.version.number,
                            parameterMappings: initialParameterMapping,
                          },
                        });
                      }
                    }}
                    disableClearable
                  />
                  {!selectedAction && <FormHelperErrorText error="This field is required" />}
                </NvFlex>
              ) : (
                <NvSkeleton variant="rectangular" width="100%" height="32px" />
              )}
            </NvFlex>
          </PropertyPanelSection>
          {actionNode.actionId && (
            <TestActionProvider>
              {integration.connectionSchemaId && (
                <SlackWorkspaceOrWorkflowConnectionSection
                  type="schema-selected-with-integration"
                  workflow={workflow}
                  integration={integration}
                  connectionIdValue={actionNode.connectionId}
                  onConnectionChange={(connection) => {
                    handleUpdateNode({ actionNode: { ...actionNode, connectionId: connection?.connectionId } });
                  }}
                />
              )}

              {/* Open it after test with sample context feature will be implemented */}
              {/* <TestActionButton
                testActionParams={{
                  formId,
                  actionId: actionNode.actionId,
                  integrationId: actionNode.integrationId,
                  connectionId: actionNode.connectionId,
                  versionNumber: actionNode.version,
                  inputParameters: actionNode.parameterMappings,
                  testRun: true,
                }}
              /> */}

              {!isGettingIntegrationActions &&
                (isRecordIntegration ? (
                  <RecordsParameterMapping
                    context={context}
                    actionId={actionNode.actionId}
                    integrationId={actionNode.integrationId}
                    parameterMappings={actionNode.parameterMappings}
                    version={actionNode?.version}
                    onParameterMappingChanged={onParameterMappingChanged}
                  />
                ) : hasCatalogRelationshipFilter ? (
                  <SearchEntitiesFilterParameterMapping
                    context={context}
                    actionId={actionNode.actionId}
                    integrationId={actionNode.integrationId}
                    parameterMappings={actionNode.parameterMappings}
                    version={actionNode?.version}
                    inputParameters={selectedAction?.inputParameters}
                    onParameterMappingChanged={onParameterMappingChanged}
                  />
                ) : (
                  <>
                    <PropertyPanelListHeader title="Action inputs" />
                    <PropertyPanelSimpleSection>
                      <NvFlex gap="16px" paddingBottom="8px" width="100%">
                        {hasSlackBlocks ? (
                          <SlackSendMessageParameterMapping
                            userAppId={userAppId}
                            workflowId={workflowId}
                            actionNode={actionNode}
                            onParameterMappingChanged={onParameterMappingChanged}
                            onSlackItemSelected={onSlackItemSelected}
                            context={context}
                          />
                        ) : hasJobTarget ? (
                          <JobTargetParameterMapping
                            context={context}
                            onJobTargetParameterSelected={onJobTargetParameterSelected}
                            actionNode={actionNode}
                            selectedAction={selectedAction}
                            onParameterMappingChanged={onParameterMappingChanged}
                          />
                        ) : (
                          <ActionParameterMapper
                            type="in-workflow"
                            appId={userAppId}
                            workflowId={workflowId}
                            actionId={actionNode.actionId}
                            integrationId={actionNode.integrationId}
                            versionNumber={actionNode.version}
                            // do not send connectionId if it is Slack
                            {...(integration?.type !== 'slack' ? { connectionId: actionNode.connectionId } : {})}
                            context={context}
                            onParameterMappingsChanged={onParameterMappingChanged}
                            initialParameterMappings={actionNode.parameterMappings}
                          />
                        )}
                      </NvFlex>
                    </PropertyPanelSimpleSection>
                  </>
                ))}

              <TestActionResult
                sampleResponse={selectedAction?.responseConfiguration?.exampleResponse}
                tooltip="The response displayed below is solely a sample."
                // tooltip="The response displayed below is solely a sample. To see the actual response using real data from your tool, test the action."
              />
            </TestActionProvider>
          )}

          <ErrorHandlingStrategyPanel
            value={actionNode.errorHandlingStrategy}
            onChange={(value) => {
              handleUpdateNode({
                actionNode: {
                  ...actionNode,
                  errorHandlingStrategy: value,
                },
              });
            }}
          />
        </>
      ) : (
        <PropertiesLoading />
      )}
    </NvFlex>
  );
};
