import { styled } from '@novaera/theme-provider';
import { NvMenuItem } from '../../../../../ui/menu-item';

export const MenuItemStyled = styled(NvMenuItem, {
  shouldForwardProp: (prop) => {
    return prop !== 'isSelected';
  },
})<{
  isSelected?: boolean;
}>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.nv_main[20] : theme.palette.nv_neutral[0]};
  transition: background-color 200ms ease;
  border-radius: 6px;

  &:hover {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.nv_main[20] : theme.palette.nv_neutral[20]};
  }
`;
