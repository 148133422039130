import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANTS } from '../keys';

const deleteAssistant = async ({ appId }: { appId: string }) => {
  const result = await NvAxios.delete(`${ASSISTANTS_ROOT_PATH(appId)}`);
  return result?.data;
};

export const useDeleteAssistant = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAssistant, {
    onSuccess: (_, { appId }) => {
      queryClient.removeQueries(QUERY_KEYS_ASSISTANTS.detail({ appId }));
    },
  });
};
