import { Form, FormSpy } from 'react-final-form';
import { DebouncedAutoSave } from './auto-save/debounced-auto-save';
import { LegacyAutoSave } from './auto-save/legacy-auto-save';
import { EmptyInterface, FormProps } from './types';

export const NvForm = <F extends EmptyInterface>({
  onSubmit,
  initialValues,
  subscription,
  children,
  className,
  onChange,
  mutators,
  validate,
  keepDirtyOnReinitialize,
  initialValuesEqual,
  myRef,
  name,
  autoSaveProps = { autoSaveType: 'legacy' },
  formRef,
}: FormProps<F>) => {
  return (
    <Form
      name={name}
      mutators={mutators}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={subscription}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      initialValuesEqual={initialValuesEqual}
      render={(renderProps) => {
        if (myRef) {
          myRef.current = renderProps.form;
        }

        return (
          <form
            ref={formRef}
            onSubmit={renderProps.handleSubmit}
            onReset={() => {
              renderProps.form.reset(initialValues);
            }}
            className={className}
            style={{ height: '100%', minHeight: 0 }}
          >
            {autoSaveProps?.autoSaveType === 'debounce' ? (
              <FormSpy<F>
                subscription={{ values: true, initialValues: true, dirtyFields: true }}
                render={(props) => (
                  <DebouncedAutoSave<F> {...props} onChange={onChange} debounce={autoSaveProps.debounceDelay} />
                )}
              />
            ) : autoSaveProps.autoSaveType === 'legacy' ? (
              <FormSpy
                subscription={{ values: true, valid: true, dirty: true, dirtyFields: true }}
                render={(props) => <LegacyAutoSave {...props} onChange={onChange} />}
              />
            ) : (
              autoSaveProps.formSpy()
            )}

            {typeof children === 'function' ? children(renderProps) : children}
          </form>
        );
      }}
    />
  );
};
