import {
  useGetCurrentWorkspaceInfo,
  useGetUsersInWorkspace,
  useInviteUser,
  useRemoveUserFromWorkspace,
  UserRole,
  useUpdateUserRole,
  WorkspaceUser,
} from '@novaera/actioner-service';
import { useConfirmDialog, useToast } from '@novaera/core';
import React, { useMemo, useState } from 'react';

export const useUserManagement = () => {
  const [searchUserValue, setSearchUserValue] = useState<string>('');
  const [isNewMemberOpened, setIsNewMemberOpened] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [inProgressDeletingUser, setInProgressDeletingUser] = useState<string[]>([]);
  const [inProgressUpdatingUser, setInProgressUpdatingUser] = useState<string[]>([]);

  const { openConfirm } = useConfirmDialog();
  const { workspaceInfo } = useGetCurrentWorkspaceInfo();
  const { data } = useGetUsersInWorkspace();
  const { mutateAsync: inviteUser } = useInviteUser();
  const { mutate: removeUser } = useRemoveUserFromWorkspace();
  const { mutateAsync: updateUserRole } = useUpdateUserRole();
  const { addToast } = useToast();
  const users = useMemo(
    () => data?.users.filter((user) => user.email.toLowerCase().includes(searchUserValue.toLowerCase())),
    [data?.users, searchUserValue]
  );
  const deleteUser = async (item: WorkspaceUser) => {
    setInProgressDeletingUser([...inProgressDeletingUser, item.email]);

    await new Promise<void>((resolve) => {
      removeUser(
        {
          userId: item.userId,
        },
        {
          onSuccess: () => {
            addToast('User removed', {
              variant: 'success',
            });
            resolve();
          },
          onError: () => {
            resolve();
          },
        }
      );
    });

    setInProgressDeletingUser([...inProgressDeletingUser.filter((user) => user !== item.email)]);
  };

  const handleUpdateUserRoleChange = async (user: WorkspaceUser, roleId: UserRole) => {
    setInProgressUpdatingUser([...inProgressUpdatingUser, user.email]);
    try {
      await updateUserRole(
        { userId: user.userId, roleId },
        {
          onSettled: () => {
            setInProgressUpdatingUser([...inProgressUpdatingUser.filter((u) => u !== user.email)]);
          },
        }
      );
    } catch (err) {
      /* empty */
    }
  };

  const handleInviteNewMemberClicked = () => {
    setIsNewMemberOpened(true);
  };
  const handleInviteNewMemberClosed = React.useCallback(() => {
    setIsNewMemberOpened(false);
  }, []);

  const handleResentInvitation = async (user: WorkspaceUser) => {
    try {
      setInvitedUsers([...invitedUsers, user.email]);
      await inviteUser({
        email: user.email,
        roleId: user.roleId,
      });
      addToast('Invitation is sent.', {
        variant: 'success',
      });
    } catch (error) {
      addToast('Invitation could not be sent.', {
        variant: 'error',
      });
    } finally {
      setInvitedUsers([...invitedUsers.filter((invitedUser) => invitedUser !== user.email)]);
    }
  };

  const onRemoveClicked = (item: WorkspaceUser) => {
    openConfirm({
      title: `Delete ${item.userName} from ${workspaceInfo?.displayName}?`,
      message: `You are about to delete this user from your workspace. Removed users can no longer access your workspace.`,
      onConfirm: () => deleteUser(item),
    });
  };

  return {
    users,
    availableRoles: data?.availableRoles,
    onRemoveClicked: onRemoveClicked,
    onInviteNewMemberClicked: handleInviteNewMemberClicked,
    onInviteNewMemberClosed: handleInviteNewMemberClosed,
    isNewMemberOpened,
    onResentInvitationClick: handleResentInvitation,
    invitedUsers,
    setSearchUserValue,
    onUpdateUserRoleChange: handleUpdateUserRoleChange,
    inProgressDeletingUser,
    inProgressUpdatingUser,
  };
};
