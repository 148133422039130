import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { Group, GroupResponse, UseGetGroupsResponse, UseUpdateGroupParams } from '../types';

const updateGroup: (params: UseUpdateGroupParams) => Promise<GroupResponse> = async (params) => {
  const { id, ...rest } = params;
  const result = await NvAxios.put<GroupResponse>(`${GROUPS_ROOT_PATH}/${id}`, rest);
  return result?.data;
};

export const useUpdateGroup = () => {
  const cache = useQueryClient();

  return useMutation(updateGroup, {
    onSuccess: (response) => {
      cache.setQueryData<UseGetGroupsResponse | undefined>(QUERY_KEYS_GROUPS.list(), (old) => {
        if (old) {
          return {
            groups: old.groups.map((p) => (p.id === response.id ? { ...p, ...response } : p)),
          };
        }
        return old;
      });
      cache.setQueryData<Group | undefined>(QUERY_KEYS_GROUPS.detail(response.id), (old) => {
        if (old) {
          return response;
        }
        return old;
      });
    },
  });
};
