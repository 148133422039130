import { NvAxios, useMutation } from '@novaera/core';
import { CATALOG_ENTITY_TYPES_ROOT_PATH } from '../constants';

import { CatalogEntityType } from '../types';
import { useUpdateCatalogEntityCache } from '../use-update-catalog-entity-cache';
import { CreateEntityTypeParams } from './types';

const createCatalogEntityType: (params: CreateEntityTypeParams) => Promise<CatalogEntityType> = async (params) => {
  const result = await NvAxios.post<CatalogEntityType>(`${CATALOG_ENTITY_TYPES_ROOT_PATH}`, params.payload);
  return result?.data;
};

export const useCreateCatalogEntityType = ({ onSuccess }: { onSuccess: (data: CatalogEntityType) => void }) => {
  const { updateInfiniteSearchCache } = useUpdateCatalogEntityCache();
  return useMutation(createCatalogEntityType, {
    onSuccess: (data) => {
      const newlyAddedEntityType: CatalogEntityType = {
        ...data,
        parameters: [],
      };
      onSuccess(newlyAddedEntityType);

      updateInfiniteSearchCache({
        updateFunction: ({ old }) => {
          return (
            old?.pages.map(({ entityTypes, ...others }) => ({
              ...others,
              entityTypes: [...(entityTypes ?? []), newlyAddedEntityType],
            })) ?? [
              {
                entityTypes: [newlyAddedEntityType],
              },
            ]
          );
        },
      });
    },
  });
};
