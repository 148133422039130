import { BaseFieldType, isCatalogEntityParameters } from '@novaera/actioner-service';
import { NvFlex, NvSplitPane, SplitPanelItem } from '@novaera/core';
import { DataModelLayoutLeftContent } from '../left-content';
import { RelationshipRightPanel } from '../relationship-right-panel';
import { DataModelLayoutRightContent } from '../right-content';
import { LeftContent, RightContent as StyledRightContent } from '../styled';
import {
  CatalogDataModelDefaultPageProps,
  DataModelDataModelDefaultPageProps,
  DataModelDefaultPageProps,
} from './types';

const isDataModelForTable = <T extends BaseFieldType>(
  props: DataModelDefaultPageProps<T>
): props is DataModelDataModelDefaultPageProps<T> => props.modalType === 'table';

const isCatalogDataModel = (props: unknown): props is CatalogDataModelDefaultPageProps =>
  (props as CatalogDataModelDefaultPageProps).modalType === 'catalog';

export const DataModelDefaultPage = <FieldTypeType extends BaseFieldType>(
  props: DataModelDefaultPageProps<FieldTypeType>
) => {
  return (
    <NvSplitPane direction="horizontal" style={{ width: '100%' }}>
      <SplitPanelItem initialSize="50%" minSize="35%">
        <LeftContent>
          <NvFlex padding="24px 20px 24px 32px">
            {isDataModelForTable(props) && (
              <DataModelLayoutLeftContent<FieldTypeType>
                selectedFieldIndex={props.selectedFieldIndex}
                fieldMenus={props.fieldMenus}
                fieldsFieldName={props.fieldsFieldName}
                fieldMenuItems={props.fieldMenuItems}
                fieldTypeSchemas={props.fieldTypeSchemas}
                fields={props.fields}
                setSelectedFieldIndex={props.setSelectedFieldIndex}
                fieldHeaderActionButtonProps={props.fieldHeaderActionButtonProps}
                fieldHeaderProps={props.fieldHeaderProps}
              />
            )}
            {isCatalogDataModel(props) && <NvFlex gap={'32px'}>{props.blocks}</NvFlex>}
          </NvFlex>
        </LeftContent>
      </SplitPanelItem>
      <SplitPanelItem minSize="35%">
        <StyledRightContent>
          {/* empty state component is for catalog for now, when table is moved to using new layout, this can be removed */}
          {isCatalogDataModel(props) && !props.selectedItem && props.rightPanelEmptyStateComponent}
          {(isDataModelForTable(props) ||
            (isCatalogDataModel(props) && props.selectedItem && isCatalogEntityParameters(props.selectedItem))) && (
            <DataModelLayoutRightContent
              fieldsFieldName={props.fieldsFieldName}
              fieldMenus={props.fieldMenus}
              selectedFieldIndex={props.selectedFieldIndex}
              modalType={props.modalType}
            />
          )}
          {isCatalogDataModel(props) &&
            props.selectedParent &&
            props.selectedItem &&
            !isCatalogEntityParameters(props.selectedItem) && (
              <RelationshipRightPanel
                key={`relationship-right-panel-${props.selectedItem.id}`}
                fieldMenus={props.fieldMenus}
                fieldsFieldName={props.fieldsFieldName}
                selectedFieldIndex={props.selectedFieldIndex}
                selectedCatalogEntityType={props.selectedParent}
              />
            )}
        </StyledRightContent>
      </SplitPanelItem>
    </NvSplitPane>
  );
};
