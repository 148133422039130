import { FC, useMemo, useState } from 'react';
import { getItems } from '../free-workspace-layout/constants';
import { SubscriptionCardWrapper as DefaultSubscriptionCardWrapper } from './styled';
import { SubscriptionPlanDetail } from './subscription-plan-detail';
import { SubscriptionPlanInfo } from './subscription-plan-info';
import { DetailSection, SubscriptionCardProps } from './types';

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  plans,
  SubscriptionCardWrapper = DefaultSubscriptionCardWrapper,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);

  const detailSection: DetailSection = useMemo(() => {
    return selectedPlan.planType === 'standard'
      ? {
          showDetail: true,
          detailHeaderText: 'Everything in Free plan, plus',
          detailItems: getItems(selectedPlan),
        }
      : { showDetail: false };
  }, [selectedPlan]);

  return (
    <SubscriptionCardWrapper>
      <SubscriptionPlanInfo plans={plans} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
      {detailSection.showDetail && (
        <SubscriptionPlanDetail headerText={detailSection.detailHeaderText} items={detailSection.detailItems} />
      )}
    </SubscriptionCardWrapper>
  );
};
