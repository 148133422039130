import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const MyConnectionItemWrapper = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  padding: 16px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;

export const ConnectionWrapper = styled(NvFlex)`
  flex-direction: row;
  column-gap: 8px;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;
