import { NvAxios, useQuery } from '@novaera/core';
import { assert } from '@novaera/utils';
import { Workflow } from '../../types/workflow/types';

import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS } from '../keys';

import { GetWorkflowParams, GetWorkflowResponse } from './types';
import { useWorkflow } from './use-workflow';

export const getWorkflow: ({
  workflowId,
  appId,
}: GetWorkflowParams) => Promise<{ draft: Workflow | undefined; saved: Workflow } | undefined> = async ({
  workflowId,
  appId,
}: GetWorkflowParams) => {
  const result = await NvAxios.get<GetWorkflowResponse>(`${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}`);
  if (!result?.data) {
    return;
  } else {
    let draft: Workflow | undefined;
    if (result?.data?.draft) {
      draft = result.data.draft;
    }

    let saved: Workflow | undefined;
    if (result?.data?.saved) {
      saved = result.data.saved;
    }

    assert(!!saved, new Error('there should always be a saved workflow if there is workflow'), 'ERROR');

    return { draft, saved };
  }
};

export const useGetWorkflow = ({ workflowId, appId }: GetWorkflowParams) => {
  const {
    data: userAppResponse,
    isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  } = useQuery(QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId }), () => getWorkflow({ appId, workflowId }), {
    enabled: !!appId && !!workflowId,
    keepPreviousData: true,
  });

  const { draftWorkflow, savedWorkflow, workflow } = useWorkflow(userAppResponse);

  return {
    workflow,
    savedWorkflow,
    draftWorkflow,
    isLoading: isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  };
};
