import { Navigate, Route, Routes } from '@novaera/route';
import React from 'react';
import { AdminTabs } from './admin-tabs';
import { RecentQueryResult } from './admin-tabs/analytics/recent-query-result';
import { AdminPanelProps } from './types';

export const AdminPanel: React.FC<React.PropsWithChildren<AdminPanelProps>> = ({ productId }) => (
  <Routes>
    <Route path="/users" element={<AdminTabs productId={productId} />} />
    <Route path="/workspaces" element={<AdminTabs productId={productId} />} />
    <Route path="/analytic-queries" element={<AdminTabs productId={productId} />} />
    <Route path="/analytic-queries/:queryId" element={<RecentQueryResult />} />
    <Route path="/sync-integration" element={<AdminTabs productId={productId} />} />
    <Route path="/integration-directory" element={<AdminTabs productId={productId} />} />
    <Route path="/app-directory-settings" element={<AdminTabs productId={productId} />} />
    <Route path="/custom-plan-configuration" element={<AdminTabs productId={productId} />} />
    <Route path="/es-search" element={<AdminTabs productId={productId} />} />
    <Route path="" element={<Navigate to="users" replace={true} />} />
    <Route path="*" element={<Navigate to="/error/404" replace={true} />} />
  </Routes>
);
