import { useGetConfigVersion, useGetConfigVersions } from '@novaera/actioner-service';
import {
  NvButton,
  NvChevronRightIcon,
  NvCollapse,
  NvCustomEmptyIcon,
  NvDivider,
  NvFlex,
  NvHistoryIcon,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import React, { useState } from 'react';
import { VersionHistoryDetail } from './detail';
import { VersionHistoryListItem } from './item';
import { VersionHistoryBody, VersionHistoryContainer, VersionHistoryHeader } from './styled';

export const VersionHistory: React.FC<React.PropsWithChildren<{ onRestore: (properties: string) => void }>> = ({
  onRestore,
}) => {
  const theme = useTheme();
  const { userAppId, configId } = useParams();
  const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
  const [activeVersionHistoryId, setActiveVersionHistoryId] = useState<string | undefined>();
  const {
    data: getVersionsQueries,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetConfigVersions({ appId: userAppId, configId, enabled: showVersionHistory });
  const versionPages = getVersionsQueries?.pages.filter((p) => p);
  const hasNoVersions =
    !versionPages || (versionPages && versionPages.length === 1 && versionPages[0].versions.length === 0);
  const { data: versionDetail } = useGetConfigVersion({ appId: userAppId, configId, version: activeVersionHistoryId });
  return (
    <>
      <VersionHistoryContainer>
        <VersionHistoryHeader
          className={classNames([{ 'is-open': showVersionHistory }])}
          onClick={() => {
            setShowVersionHistory(!showVersionHistory);
          }}
        >
          <NvFlex direction="row" alignItems="center" gap="4px">
            <NvChevronRightIcon className="chevron-icon" sx={{ width: '20px', height: '20px' }} />
            <NvFlex direction="row" alignItems="center" gap="6px">
              <NvHistoryIcon sx={{ width: '20px', height: '20px' }} />
              <NvTypography variant="h5">History</NvTypography>
            </NvFlex>
          </NvFlex>
        </VersionHistoryHeader>

        <NvCollapse in={showVersionHistory}>
          <VersionHistoryBody>
            {isInitialLoading ? (
              <>
                <NvSkeleton variant="rectangular" height="32px" />
                <NvSkeleton variant="rectangular" height="32px" />
                <NvSkeleton variant="rectangular" height="32px" />
              </>
            ) : hasNoVersions ? (
              <NvFlex direction="row" alignItems="flex-start" gap="8px">
                <NvCustomEmptyIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[60]} />
                <NvTypography variant="h6" textColor="ghost">
                  This configuration policy doesn’t have a version history.
                </NvTypography>
              </NvFlex>
            ) : (
              <>
                {versionPages?.map((page) =>
                  page.versions?.map((v) => (
                    <VersionHistoryListItem
                      key={v.version}
                      version={v}
                      onItemClick={() => {
                        setActiveVersionHistoryId(v.version);
                      }}
                    />
                  ))
                )}
                {hasNextPage && (
                  <NvFlex gap="8px">
                    <NvDivider />
                    <NvButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        fetchNextPage();
                      }}
                      loading={isFetchingNextPage}
                      disabled={isFetchingNextPage}
                    >
                      Show more
                    </NvButton>
                  </NvFlex>
                )}
              </>
            )}
          </VersionHistoryBody>
        </NvCollapse>
      </VersionHistoryContainer>
      <VersionHistoryDetail
        open={!!activeVersionHistoryId}
        onClose={() => {
          setActiveVersionHistoryId(undefined);
        }}
        onRestore={onRestore}
        versionDetail={versionDetail}
      />
    </>
  );
};
