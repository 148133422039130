import {
  InputParameter,
  ParameterMapping,
  ParameterTypes,
  UIComponentType,
  ValueTypes,
} from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';

export * from './use-calculate-should-add-form-id';

export const calculateIsScripted = (inputParameter: InputParameter, value: ParameterMapping) => {
  if (
    inputParameter.uiComponent.type === UIComponentType.TEXT_FIELD &&
    inputParameter.schema?.type === SchemaType.map
  ) {
    return false;
  }

  if (
    inputParameter.uiComponent.type === UIComponentType.TEXT_FIELD &&
    inputParameter.schema?.type !== SchemaType.map
  ) {
    return true;
  }

  if (value.type === ParameterTypes.SIMPLE && value?.value && 'codeTemplate' in value.value) {
    return true;
  }

  if (value.type === ParameterTypes.SIMPLE && value?.value?.type === ValueTypes.STRING && !value?.value?.displayValue) {
    return true;
  }
  return false;
};
