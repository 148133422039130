import { NvFlex, NvMenu, NvTypography } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const MenuIconWrapper = styled(NvFlex)`
  position: relative;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 64px;
`;

export const MenuIndicatorWrapper = styled(NvFlex)`
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
`;

export const ActiveMenuIndicator = styled('div')`
  position: absolute;
  left: 0;
  top: calc(50% - 20px);
  background-color: ${({ theme }) => theme.palette.nv_new[60]};
  width: 0;
  height: 40px;
  transition: width 200ms ease-in-out;
`;

export const MenuDropdown = styled(NvMenu)`
  &.MuiPopover-root {
    z-index: inherit;
    position: absolute;
  }

  .MuiPaper-root {
    ${({ theme }) => theme.elevations.e400};
    border-radius: 12px;
    min-width: 380px;
    max-width: 580px;
    margin-left: -8px;
    margin-top: -8px;
    z-index: 1500;

    .MuiList-root {
      padding: 12px;
    }
  }
`;

export const MenuLabelWrapper = styled(NvFlex)`
  flex: 1 1 auto;
  justify-content: center;
  padding-right: 12px;
  min-width: 0;
`;

export const UpgradeLabelWrapper = styled(NvFlex)`
  flex: 1 1 auto;
  justify-content: start;
  min-width: 0;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const NavigationGroupBorder = styled('div')`
  border: 1px solid rgba(10, 45, 98, 0.08);
  box-sizing: border-box;
  border-radius: 49px;
  top: 0;
  bottom: 0;
  position: absolute;
  width: 52px;
  left: 14px;
  transition: border-color 300ms ease-in-out;
  z-index: -1;

  &.collapsed {
    border-color: transparent;
  }
`;

export const StyledTypography = styled(NvTypography)`
  white-space: nowrap;
`;

export const MenuItemWrapper = styled(NvFlex)`
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 8px;
  transition: background-color 300ms ease, color 300ms ease;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.nv_neutral[800]};
  }

  &.active {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};

    svg.menu-item-icon {
      color: ${({ theme }) => theme.palette.nv_new[60]};
    }

    .menu-item-label {
      color: ${({ theme }) => theme.palette.nv_new[60]};
    }
    .active-indicator {
      width: 4px;
    }
  }

  &.is-popup-menu-open {
    background-color: ${({ theme }) => theme.palette.nv_neutral[800]};
  }
`;
export const SubMenuItemWrapper = styled(MenuItemWrapper)`
  margin-top: 0px;
  margin-bottom: 0px;
`;
