import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledNvBox = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'disabled' })<{ disabled: boolean }>`
  min-height: 64px;
  height: auto;
  border: 1px solid ${({ theme }) => theme.palette.nv_new[40]};
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  padding-left: 16px;
  padding-top: 16px;
  gap: 16px;
  max-width: 700px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  background-color: transparent;
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.palette.nv_neutral_alpha[10]};
  }
  ${({ disabled }) => (disabled ? `cursor:auto; opacity:0.6;` : '')}
`;

export const StyledContentBox = styled(NvBox)`
  padding: 8px 0;
`;
