import { CatalogEntityParameters } from '@novaera/actioner-service';
import {
  NvCustomEmptyIcon,
  NvFlex,
  NvLockIcon,
  NvRemoveRedEyeIcon,
  NvTooltip,
  NvTypography,
  useFormState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { useMemo } from 'react';
import { DataModelMenu } from '../../../components/data-model-layout/data-model-menu';
import { RelationshipBlocks } from '../../../components/data-model-layout/left-content/relationship-blocks';
import { useCatalogPermission } from '../../controllers/use-catalog-permission';
import { SelectedItemDetail } from '../../provider/use-catalog-provider';
import { CatalogFormType } from '../../types';

export const CatalogParameters = ({
  onItemSelected,
  selectedDetailItem,
}: {
  selectedDetailItem?: SelectedItemDetail;
  onItemSelected: (item: SelectedItemDetail) => void;
}) => {
  const { hasCatalogEditPermission } = useCatalogPermission();

  const {
    values: { selectedEntityType },
  } = useFormState<CatalogFormType>();
  const { palette } = useTheme();

  const actions = useMemo(() => {
    const parameters = selectedEntityType?.parameters ?? [];

    if (!hasCatalogEditPermission) {
      if (parameters.length === 0) {
        return (
          <NvFlex direction="row" gap="4px">
            <NvCustomEmptyIcon
              sx={{ width: '16px', height: '16px' }}
              htmlColor={palette.nv_neutral[300]}
            ></NvCustomEmptyIcon>
            <NvTypography variant="body2" textColor="subtle">
              No properties have been defined for this entity yet.
            </NvTypography>
          </NvFlex>
        );
      } else {
        return;
      }
    }

    return (
      <DataModelMenu
        label={'Add properties'}
        fieldsFieldName={'selectedEntityType.parameters'}
        fields={parameters}
        onSelectedFieldIndexChange={(item, index) => {
          onItemSelected({
            selectedItemDetail: item,
            index,
          });
        }}
        {...((!parameters || parameters?.length === 0) && {
          buttonProps: {
            size: 'medium',
            color: 'secondary',
            isFitContent: true,
          },
        })}
      />
    );
  }, [hasCatalogEditPermission, onItemSelected, palette.nv_neutral, selectedEntityType?.parameters]);

  return (
    selectedEntityType && (
      <RelationshipBlocks<CatalogEntityParameters>
        fieldName="selectedEntityType.parameters"
        onItemSelected={(selectedItem, index) => {
          onItemSelected({
            selectedItemDetail: selectedItem,
            index,
          });
        }}
        title="Properties"
        actions={actions}
        items={selectedEntityType.parameters}
        RightContent={(item) => {
          return (
            <>
              {selectedEntityType?.displayParameterId === item?.id && (
                <NvTooltip title="This property is used for display purposes, providing consistent visibility in all relevant areas of the app.">
                  <NvRemoveRedEyeIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                </NvTooltip>
              )}
              {item?.managed && (
                <NvTooltip title="This property is managed by Actioner, edit or delete operations are not allowed.">
                  <NvLockIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                    htmlColor={palette.nv_neutral[300]}
                  />
                </NvTooltip>
              )}
            </>
          );
        }}
        isSelected={(data) => {
          if (!selectedDetailItem || !selectedDetailItem.selectedItemDetail) {
            return false;
          }
          return data.id === selectedDetailItem?.selectedItemDetail.id;
        }}
      />
    )
  );
};
