import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ContentWrapper = styled(NvFlex)`
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  gap: 10px;
  padding: 8px 16px;
  align-items: center;
`;
