import { NvAxios, useMutation, useToast } from '@novaera/core';
import { ConfirmEmailChangeParams } from './types';

const confirmEmailChange = async (params: ConfirmEmailChangeParams) => {
  const result = await NvAxios.post(`v1/users/confirm-email`, params);
  return result?.data;
};

export const useConfirmEmailChange = () => {
  const { addToast } = useToast();

  return useMutation(confirmEmailChange, {
    onSuccess: () => {
      addToast('E-mail is changed', { variant: 'success' });
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((error as any).response.status !== 401) {
        addToast('E-mail could not be updated', { variant: 'error' });
      }
    },
  });
};
