import { SearchWorkflowsParams, useSearchWorkflows } from '@novaera/actioner-service';
import {
  NvAutocomplete,
  NvBox,
  NvCheckbox,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvFlex,
  NvSkeleton,
  NvTextField,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo, useState } from 'react';
import { ActionSelectMenu, ActionSelectMenuItem } from '../../action-select-menu';

type SetupWorkflow = { id?: string; name?: string; autoRun?: boolean };

export const SelectWorkflow = ({
  appId,
  searchWorkflowsParams,
  value,
  onChange,
}: {
  appId: string;
  searchWorkflowsParams: SearchWorkflowsParams | undefined;
  value?: SetupWorkflow;
  onChange?: (v: SetupWorkflow | null) => void;
}) => {
  const { palette } = useTheme();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const { data: workflowSearchData, isInitialLoading: isSearchingWorkflows } = useSearchWorkflows({
    appIds: [appId],
    keyword: debouncedSearchKeyword,
    ...(searchWorkflowsParams ?? {}),
    includeSearchInvisible: true,
  });

  const workflows = useMemo(
    () => workflowSearchData?.workflows.map(({ id, name }) => ({ id, name })) ?? [],
    [workflowSearchData?.workflows]
  );

  const memoizedValue = useMemo(() => {
    return value?.id ? { id: value?.id, name: value?.name } : null;
  }, [value?.id, value?.name]);

  return (
    <NvFlex direction="row" width="100%" gap="8px">
      <NvBox flex="1 1 auto">
        <NvAutocomplete
          fullWidth
          value={memoizedValue}
          onChange={(_event, newValue) => {
            onChange?.({ ...newValue, autoRun: value?.autoRun });
          }}
          sx={{ width: '100%' }}
          options={workflows}
          getOptionLabel={(option) => option.name ?? ''}
          renderInput={(props) => <NvTextField {...props} placeholder="Select workflow" />}
          PaperComponent={({ children, ...props }) => (
            <ActionSelectMenu {...props}>
              {isSearchingWorkflows ? (
                <NvFlex margin="8px" gap="8px">
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="40%" height="20px" />
                  </NvFlex>
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="60%" height="20px" />
                  </NvFlex>
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="30%" height="20px" />
                  </NvFlex>
                </NvFlex>
              ) : (
                children
              )}
            </ActionSelectMenu>
          )}
          renderOption={(props, option) => (
            <ActionSelectMenuItem {...props} key={option.id}>
              <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
                <NvTypography variant="body1" noWrap>
                  {option.name}
                </NvTypography>
              </NvFlex>
            </ActionSelectMenuItem>
          )}
          isOptionEqualToValue={(option, value) => value && option.id === value.id}
          clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
          popupIcon={<NvExpandMoreIcon />}
          onInputChange={(_event, value) => {
            setSearchKeyword(value);
          }}
          filterOptions={(o) => o}
        />
      </NvBox>
      <NvFlex direction="row" gap="8px" padding="0px 12px 0px 8px" bgcolor={palette.nv_neutral[10]} alignItems="center">
        <NvCheckbox
          checked={value?.autoRun}
          onChange={(_event, checked) => {
            onChange?.({ ...value, autoRun: checked });
          }}
        />
        <NvTypography variant="h5">Run automatically</NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
