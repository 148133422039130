import {
  AccountCircleIcon,
  isRequired,
  NvBox,
  NvButton,
  NvField,
  NvFlex,
  NvForm,
  NvSkeleton,
  NvTextField,
  NvTypography,
  SectionMessage,
} from '@novaera/core';
import React, { PropsWithChildren } from 'react';
import { NvLogoUpload } from '../../components/logo-upload';
import { useAccountSettings } from './controllers/use-account-settings';
import { EmailForm, UsernameForm } from './types';
export const AccountSettings: React.FC<PropsWithChildren<unknown>> = () => {
  const {
    onEmailUpdate,
    onNameUpdate,
    onProfileLogoUpdate,
    formData,
    isFormDataLoading,
    isEmailUpdateLoading,
    isUsernameLoading,
  } = useAccountSettings();

  if (isFormDataLoading) {
    return (
      <NvFlex marginTop="10px">
        <NvSkeleton height="40px" />
        <NvSkeleton height="40px" />
        <NvSkeleton height="40px" />
      </NvFlex>
    );
  }

  return (
    <NvFlex spacing={3}>
      <SectionMessage variant="info" message="Any updates made to your profile will affect all workspaces." />

      <NvFlex spacing={3}>
        <NvFlex direction="column" spacing={1}>
          <NvFlex>
            <NvTypography variant="h4">Profile photo</NvTypography>
          </NvFlex>
          <NvFlex direction="row">
            <NvLogoUpload
              imageSize="xlarge"
              initialLogoUrl={formData.logoUrl}
              imageShape="circle"
              type="user"
              logoUploadButtonProps={{ text: 'Change photo' }}
              hasRemoveAction
              ImageFallBack={<AccountCircleIcon sx={{ fontSize: '60px' }} />}
              onLogoStateChanged={({ logo }) => {
                onProfileLogoUpdate(logo);
              }}
            />
          </NvFlex>
        </NvFlex>
        <NvForm<EmailForm>
          onSubmit={onEmailUpdate}
          initialValues={{
            email: formData?.email,
          }}
        >
          <NvFlex direction="row" width="100%" alignItems="flex-start" gap="16px">
            <NvBox flex="1 1 auto">
              <NvField
                direction="label-on-top"
                labelText={<NvTypography variant="h4">Email</NvTypography>}
                validators={[isRequired()]}
                isRequired
                component={(props) => {
                  return (
                    <NvFlex flexDirection={'row'} gap={'16px'} alignItems={'center'}>
                      <NvTextField size="medium" {...props} sx={{ flex: '1 1 auto' }} />
                      <NvButton
                        type="submit"
                        color="secondary"
                        size="small"
                        style={{
                          flex: '0 0 auto',
                        }}
                        loading={isEmailUpdateLoading}
                      >
                        Update
                      </NvButton>
                    </NvFlex>
                  );
                }}
                name="email"
              />
            </NvBox>
          </NvFlex>
        </NvForm>
        <NvForm<UsernameForm>
          onSubmit={onNameUpdate}
          initialValues={{
            name: formData?.username,
          }}
        >
          <NvFlex direction="row" width="100%" alignItems="flex-start" gap="16px">
            <NvBox flex="1 1 auto">
              <NvField
                direction="label-on-top"
                labelText={<NvTypography variant="h4">Name</NvTypography>}
                validators={[isRequired()]}
                isRequired
                component={(props) => {
                  return (
                    <NvFlex flexDirection={'row'} gap={'16px'} alignItems={'center'}>
                      <NvTextField size="medium" {...props} sx={{ flex: '1 1 auto' }} />
                      <NvButton
                        type="submit"
                        color="secondary"
                        size="small"
                        style={{
                          flex: '0 0 auto',
                        }}
                        loading={isUsernameLoading}
                      >
                        Update
                      </NvButton>
                    </NvFlex>
                  );
                }}
                name="name"
              />
            </NvBox>
          </NvFlex>
        </NvForm>
      </NvFlex>
    </NvFlex>
  );
};
