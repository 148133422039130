import { UserRole } from '@novaera/actioner-service';
import { NvButton, NvFlex, NvKeyboardArrowDownIcon, NvPopover, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { USER_MANAGEMENT_ROLES } from './constants';
import { UserRolePopoverItem } from './styled';
import { UserRoleSelectProps } from './types';

export const UserRoleSelect: React.FC<React.PropsWithChildren<UserRoleSelectProps>> = ({
  value,
  onChange,
  availableRoles,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedValue, setSelectedValue] = useState<string>(value);
  const roles = useMemo(
    () => USER_MANAGEMENT_ROLES.filter((role) => availableRoles?.find((r) => r === role.value)),
    [availableRoles]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'user-role-select-popover' : undefined;

  const PaperProps = {
    style: {
      background: theme.palette.nv_neutral[0],
      border: `1px solid ${theme.palette.nv_neutral[0]}`,
      boxShadow: '0px 0px 0px 1px #EBEEF2, 0px 18px 30px -3px rgba(10, 45, 98, 0.08)',
      borderRadius: 12,
      marginTop: 8,
    },
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (value: UserRole) => {
    value && setSelectedValue(value);
    value && onChange?.(value);
    handleClose();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NvButton onClick={handleClick} size="small" color="secondary" isSelected={open}>
        <NvFlex flexDirection="row" width="100px" flex="1 1 auto" alignItems="center" justifyContent="space-between">
          {capitalize(selectedValue)}
          <NvKeyboardArrowDownIcon />
        </NvFlex>
      </NvButton>

      <NvPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={PaperProps}
      >
        <NvFlex margin="6px" width="100px">
          {roles.map((role, index) => {
            return (
              <UserRolePopoverItem onClick={() => handleChange(role.value)} key={`${role.label}-${index}`}>
                <NvTypography variant="h4">{role.label}</NvTypography>
              </UserRolePopoverItem>
            );
          })}
        </NvFlex>
      </NvPopover>
    </>
  );
};
