import { WorkflowState, WorkflowWithState } from '@novaera/actioner-service';
import { NvDialogModal, NvPlayArrowIcon, NvTooltip } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FormApi } from 'final-form';
import { MutableRefObject, PropsWithChildren, useRef } from 'react';
import { InputFormValues } from '../../../../../../action-designer/providers/input-values';
import { MissingConnectionModal } from '../../../../../../integrations/components/missing-connection/modal';
import { useFormIdentifierContext } from '../../../../../../providers/form-identifier-provider';
import { useWorkflowReferenceOutputProvider } from '../../providers/workflow-reference-output-provider';
import { WorkflowOutputRefRunImmediatelyParams } from '../../providers/workflow-reference-output-provider/types';
import { WorkflowStickyPanelButton } from '../styled';
import { WorkflowRunModalBody } from './body';
import { useExecuteWorkflow } from './controllers/use-execute-workflow';
import { useTriggerOutput } from './controllers/use-trigger-output';
import { useWorkflowRun } from './controllers/use-workflow-run';
import { WorkflowRunPanelFooter } from './footer';

export const WorkflowRun = ({
  workflow,
  currentPage,
  workflowOutputRefParams,
  initialFormValues,
  isWorkflowOutputLoading,
  isSaveProgress,
}: PropsWithChildren<{
  workflow?: WorkflowWithState;
  initialFormValues?: InputFormValues;
  currentPage: number;
  workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
  isWorkflowOutputLoading?: boolean;
  isSaveProgress: boolean;
}>) => {
  const { getStatefulFormId } = useFormIdentifierContext();

  const { resetWorkflowReferenceOutputs, onExecutionRunFinished, updateFormValues } =
    useWorkflowReferenceOutputProvider();

  assert(workflow !== undefined, new Error('For workflow run, workflow can not be undefined'));

  const { onExecuteWorkflow, isLoading, result, cleanResult, executionIdentifier } = useExecuteWorkflow({
    workflowId: workflow.id,
    workflowTriggerType: workflow?.trigger?.type,
    formId: getStatefulFormId(),
    isDraft: workflow?.state === WorkflowState.DRAFT,
    onExecutionRunFinished,
    workflowOutputRefParams,
    userAppId: workflow.appId,
  });

  const {
    onRunClick,
    isRunModalOpened,
    onRunModalCloseClicked,
    isConnectionMappingModalOpened,
    onConnectionMappingModalClosed,
    connectionValidationCheckResult,
    onConnectionsCompleted,
  } = useWorkflowRun({ workflow });

  useTriggerOutput({
    workflowOutputRefParams,
    workflow,
    onExecuteWorkflow,
    initialFormValues,
    cleanResult,
  });

  const handleModalClose = () => {
    onRunModalCloseClicked();
    // this is for not to see cleaned result before closing the modal
    setTimeout(cleanResult, 0);
    resetWorkflowReferenceOutputs();
  };

  const modalHeader =
    !isWorkflowOutputLoading && !isLoading && result
      ? 'Workflow Result'
      : currentPage === 0
      ? 'Run Workflow'
      : `Run ${workflow?.name}`;

  const formRef: MutableRefObject<FormApi<InputFormValues, Partial<InputFormValues>> | null> = useRef(null);

  return (
    <>
      <NvTooltip title="Test workflow" placement="top">
        <WorkflowStickyPanelButton
          id="run-workflow-button"
          startIcon={<NvPlayArrowIcon />}
          color="success"
          size="small"
          onClick={onRunClick}
          disabled={isSaveProgress}
          loading={isSaveProgress}
        >
          Run
        </WorkflowStickyPanelButton>
      </NvTooltip>
      <NvDialogModal<InputFormValues>
        formProps={{
          initialValues: initialFormValues,
          myRef: formRef,
        }}
        unmountOnExit
        modalIcon="playArrow"
        Header={modalHeader}
        fullWidth
        maxWidth="md"
        onCloseButtonClicked={handleModalClose}
        open={isRunModalOpened}
        primaryButtonText="Run"
        isLoading={isLoading}
        onFormSubmit={(values) => {
          updateFormValues({
            values,
          });
          onExecuteWorkflow(values);
        }}
        Body={
          <WorkflowRunModalBody
            key={`workflow-run-modal-body-${workflow.id}`}
            workflow={workflow}
            isLoading={isLoading}
            result={result}
            executionIdentifier={executionIdentifier}
            formRef={formRef}
          />
        }
        Footer={
          <WorkflowRunPanelFooter
            workflow={workflow}
            isLoading={Boolean(isLoading || isWorkflowOutputLoading)}
            isResult={!!result && result.status !== 'in_progress'}
            onCloseClicked={handleModalClose}
            currentPage={currentPage}
          />
        }
      />
      {workflow.trigger?.type === 'form' && (
        <MissingConnectionModal
          connectionValidationCheckResult={connectionValidationCheckResult}
          onConnectionsCompleted={onConnectionsCompleted}
          isOpened={isConnectionMappingModalOpened}
          onModalClosed={onConnectionMappingModalClosed}
          workflowName={workflow.name}
        />
      )}
    </>
  );
};
