import { useDeleteMsTeamsWorkspace, useGetMsTeamsConsent, useGetMsTeamsWorkspace } from '@novaera/actioner-service';
import {
  NvButton,
  NvDeleteOutlineIcon,
  NvFlex,
  NvMenuWithItems,
  NvMsTeamsIcon,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { SlackIntegrationItem } from '../../slack-workspace/ui/slack-workspace-card/styled';

export const MsTeamsWorkspace: React.FC = () => {
  const theme = useTheme();
  const { openConfirm } = useConfirmDialog();
  const { mutate: getMsTeamsConsent } = useGetMsTeamsConsent();
  const { mutate: deleteMsTeamsWorkspace, isLoading: isDeleteMsTeamsWorkspaceLoading } = useDeleteMsTeamsWorkspace();
  const { data: msTeamsWorkspace, isInitialLoading: isMsTeamsWorkspaceLoading, isFetched } = useGetMsTeamsWorkspace();
  const onRemoveWorkspaceClicked = () => {
    openConfirm({
      title: `Remove ${msTeamsWorkspace?.tenantName} ?`,
      confirmButtonLabel: 'Remove',
      message:
        'You are about to remove your Ms teams connection. You will not be able to run your actions in your Ms teams workspace.',
      onConfirm: () => {
        deleteMsTeamsWorkspace();
      },
    });
  };

  return isMsTeamsWorkspaceLoading && !isFetched ? (
    <NvSkeleton variant="rectangular" width="100%" height="64px" />
  ) : msTeamsWorkspace ? (
    <SlackIntegrationItem>
      <NvFlex direction="row" gap="8px" alignItems="center" paddingBottom="16px">
        <NvMsTeamsIcon sx={{ width: '32px', height: '32px' }} />

        <NvTypography variant="h3" flex="1 1 auto" minWidth={0} noWrap>
          {msTeamsWorkspace.tenantName}
        </NvTypography>

        <NvFlex direction="row" gap="12px" alignItems="center" flex="0 0 auto">
          {isDeleteMsTeamsWorkspaceLoading ? (
            <NvButton disabled onlyIcon loading color="secondary" size="small" />
          ) : (
            <NvMenuWithItems
              menuItems={[
                {
                  name: 'Remove workspace',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    onRemoveWorkspaceClicked();
                  },
                },
              ]}
            />
          )}
        </NvFlex>
      </NvFlex>
    </SlackIntegrationItem>
  ) : (
    <EmptyState
      variant={EmptyStates.MS_TEAMS}
      CustomButton={
        <NvButton
          size="small"
          color="secondary"
          onClick={() => {
            const popupWindow = window.open('/app-loading', '_blank', 'width=700,height=800');

            getMsTeamsConsent(undefined, {
              onSuccess: ({ consentUrl }) => {
                if (popupWindow) {
                  popupWindow.location.href = consentUrl;
                }
              },
              onError: () => {
                popupWindow?.close();
              },
            });
          }}
        >
          Add new Ms teams workspace
        </NvButton>
      }
    />
  );
};
