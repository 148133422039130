import { useGetSlackUsers } from '@novaera/actioner-service';
import { NvBox, NvFlex, NvSearchEmptyState, NvSkeleton } from '@novaera/core';
import { FC, useCallback, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { SimpleSearchInput } from '../../../../../components';
import { SLACK_USER_DEFAULT_LIMIT } from './constants';
import { SlackUserList } from './slack-user-list';

export const InviteUsers: FC<React.PropsWithChildren<{ teamId: string }>> = ({ teamId }) => {
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [limit, setLimit] = useState<number>(SLACK_USER_DEFAULT_LIMIT);
  const { data, isInitialLoading } = useGetSlackUsers({ teamId, includeBotUsers: false });

  const searchedUser = useMemo(() => {
    return data?.slackUsers.filter((user) => {
      const keyword = searchKeyword && searchKeyword.toLowerCase();
      return keyword && user
        ? user.email?.toLowerCase().indexOf(keyword) > -1 || user.realName?.toLowerCase().indexOf(keyword) > -1
        : true;
    });
  }, [data?.slackUsers, searchKeyword]);

  const filteredUsersList = useMemo(() => {
    return searchedUser?.slice(0, limit);
  }, [limit, searchedUser]);

  const isShowMoreVisible = useMemo(() => {
    return Boolean(
      searchedUser &&
        filteredUsersList &&
        filteredUsersList?.length > 0 &&
        filteredUsersList?.length < searchedUser?.length
    );
  }, [filteredUsersList, searchedUser]);

  const handleKeywordChanged = useCallback((keyword?: string) => {
    setSearchKeyword(keyword);
    setLimit(SLACK_USER_DEFAULT_LIMIT);
  }, []);

  const defaultValue = useMemo(() => [], []);

  return (
    <NvFlex gap="12px">
      <NvBox>
        <SimpleSearchInput onKeywordChanged={handleKeywordChanged} />
      </NvBox>
      {!isInitialLoading &&
        (filteredUsersList && filteredUsersList?.length > 0 ? (
          <Field
            name="invitedSlackUserIds"
            defaultValue={defaultValue}
            render={({ input: { value, onChange } }) => (
              <SlackUserList
                onShowMoreClick={() => {
                  setLimit(limit + SLACK_USER_DEFAULT_LIMIT);
                }}
                value={value}
                onChange={onChange}
                isShowMoreVisible={isShowMoreVisible}
                users={filteredUsersList}
              />
            )}
          ></Field>
        ) : (
          <NvSearchEmptyState text="No user found. Try different words or clear search bar." />
        ))}

      {isInitialLoading && (
        <>
          <NvSkeleton width="90%"></NvSkeleton>
          <NvSkeleton width="60%"></NvSkeleton>
          <NvSkeleton width="80%"></NvSkeleton>
        </>
      )}
    </NvFlex>
  );
};
