import { ConnectionSchema, GlobalSchema } from '@novaera/actioner-service';
import {
  NvConditionalRender,
  NvField,
  NvFlex,
  NvTextField,
  isMaxLength,
  isMinLength,
  isRequired,
  useForm,
} from '@novaera/core';
import { useState } from 'react';
import OnChange from '../../../action-designer/components/on-change';
import { DETAIL_FORM_NAME } from '../../../components/detail-layout';
import { LogoPlaceholder } from '../../../components/logo-placeholder';
import { NvLogoUpload } from '../../../components/logo-upload';
import { CUSTOM_CONNECTION_OPTIONS } from '../../../constants';
import { AutoCompleteConnectionType } from './autocomplete-connection-type';
import { useGlobalConnectionSchemasController } from './controllers/use-global-connection-schemas';
import { LogoWrapper } from './styled';

export const ConnectionSchemaModelBody = (prop: { isEdit?: boolean }) => {
  const { isLoading, connectionSchemas, initialValue } = useGlobalConnectionSchemasController({
    enabled: true,
    addCustomConnectionSchema: true,
  });
  const { getState } = useForm<ConnectionSchema>(DETAIL_FORM_NAME);
  const logoUrl = getState().values.logoUrl;
  const [isGlobalSchema, setIsGlobalSchema] = useState<boolean>(false);

  return (
    <>
      <OnChange name="authentication">
        {(value) => {
          const isGlobalSchema =
            CUSTOM_CONNECTION_OPTIONS.find((option) => option.id === (value as GlobalSchema)?.id) === undefined;
          setIsGlobalSchema(isGlobalSchema);
        }}
      </OnChange>
      <NvFlex spacing="16px">
        <NvConditionalRender when={() => !prop.isEdit}>
          <NvFlex gap="4px" width="100%" maxWidth="300px" minWidth="300px">
            <NvField
              hasRequiredIndicator
              labelText="Connection type"
              direction="label-on-top"
              labelVariant="h5"
              name="authentication"
              isRequired
              validators={[isRequired('Connection type is required')]}
              isAutoComplete
              showErrorMessageOnlyWhenBlur
              defaultValue={initialValue}
              component={
                <AutoCompleteConnectionType isLoading={isLoading} options={connectionSchemas} disableClearable />
              }
            />
          </NvFlex>
        </NvConditionalRender>
        {!isGlobalSchema && (
          <NvFlex>
            <NvField
              labelText="Connection schema name"
              labelVariant="h5"
              hasRequiredIndicator
              direction="label-on-top"
              name="name"
              isRequired
              showErrorMessageOnlyWhenBlur
              validators={[
                isRequired('Connection name is required'),
                isMinLength({
                  length: 3,
                }),
                isMaxLength({
                  length: 150,
                }),
              ]}
              component={<NvTextField placeholder="Enter a connection name" size="large"></NvTextField>}
            />
          </NvFlex>
        )}

        <NvField
          name="logo"
          labelText="Connection schema logo"
          direction="label-on-top"
          component={({ onChange }) => {
            return (
              <LogoWrapper>
                <NvLogoUpload
                  hasRemoveAction
                  imageSize="xlarge"
                  ImageFallBack={
                    <LogoPlaceholder
                      size={'xlarge'}
                      src="assets/connection-placeholder-image.png"
                      initialText="Connection Schema"
                    />
                  }
                  imageShape="square"
                  type="connection-schema"
                  logoUploadHintProps={{
                    text: 'Upload an image that is 280px square or larger.',
                  }}
                  onLogoStateChanged={({ logo }) => {
                    onChange(logo);
                  }}
                  initialLogoUrl={logoUrl}
                />
              </LogoWrapper>
            );
          }}
        />
      </NvFlex>
    </>
  );
};
