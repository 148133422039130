import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ORGANIZATION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ORGANIZATIONS } from '../keys';
import { Organization } from '../types';

export type GetWorkspaceMappingParams = {
  organizationId?: string;
  params?: {
    query?: string;
    nextPageKey?: string;
    limit: number;
    includeWorkspaceLogoUrl?: boolean;
  };
};

type GetWorkspaceMappingResponse = {
  nextPageKey?: string;
  orgWorkspaceMappings?: Organization[];
};

const getWorkspaceMapping = async ({ organizationId, params }: GetWorkspaceMappingParams) => {
  const result = await NvAxios.get<GetWorkspaceMappingResponse>(
    `${ORGANIZATION_ROOT_PATH}/${organizationId}/workspace-mappings`,
    {
      params,
    }
  );
  return result?.data;
};

export const useGetWorkspaceMapping = (params: GetWorkspaceMappingParams) =>
  useInfiniteQuery<GetWorkspaceMappingResponse>(
    QUERY_KEYS_ORGANIZATIONS.workspaces(params),
    () => getWorkspaceMapping(params),
    {
      enabled: !!params.organizationId,
      getNextPageParam: (lastPage) => lastPage?.nextPageKey,
    }
  );
