import { BlankVertex, DefaultNodeSummary, LoopNode, LoopVertex } from '@novaera/actioner-service';
import { NovaeraEdge, NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class LoopComponentFactory implements ComponentFactory<LoopNode> {
  aliasForInner: string;
  rootAlias: string;
  rootName: string;

  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newAlias, newName } = graph.getNewAlias('loop');
    this.rootAlias = newAlias;
    this.aliasForInner = `${this.rootAlias}_inner`;
    this.rootName = newName;
  }

  get componentForGraph() {
    const loopRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.rootName,
      width: 40,
      height: 40,
      id: this.rootAlias,
      type: 'loop',
      alias: this.rootAlias,
    };

    const innerNode: NovaeraNode<AppWorkflowNodeType> = {
      name: this.aliasForInner,
      width: 40,
      height: 40,
      id: this.aliasForInner,
      type: 'blank',
      alias: this.aliasForInner,
    };

    const edge1: NovaeraEdge = {
      sourceId: this.rootAlias,
      targetId: innerNode.alias,
      id: `${this.rootAlias}-${innerNode.alias}`,
      extraData: { type: 'Edge', centerPosition: 'up' },
    };

    return { root: loopRoot, branches: [innerNode], edges: [edge1] };
  }

  componentForWorkflow(serverData: LoopNode) {
    const innerVertex: BlankVertex = {
      alias: this.aliasForInner,
      type: 'blank',
    };

    const innerSummary: DefaultNodeSummary = {
      name: this.aliasForInner,
      type: 'blank',
      alias: this.aliasForInner,
    };

    const vertex: LoopVertex = {
      type: 'loop',
      alias: this.rootAlias,
      firstInnerAlias: innerVertex.alias,
    };

    const summary: DefaultNodeSummary = {
      name: this.rootName,
      alias: this.rootAlias,
      type: 'loop',
    };

    return {
      root: vertex,
      branches: [innerVertex],
      nodeSummaries: { [this.rootAlias]: summary, [this.aliasForInner]: innerSummary },
    };
  }
}
