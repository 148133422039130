import { NvButton, NvConditionalRender } from '@novaera/core';
import { useWorkflowReferenceOutputProvider } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/providers/workflow-reference-output-provider';

export const WorkflowRunPanelOutputNavigator = ({ currentPage }: { currentPage: number }) => {
  const { onBackClicked } = useWorkflowReferenceOutputProvider();

  const hasMoreBackPage = currentPage > 1;

  return (
    <NvConditionalRender when={hasMoreBackPage}>
      <NvButton
        color="secondary"
        onClick={() => {
          onBackClicked(currentPage);
        }}
        isFitContent
      >
        Back
      </NvButton>
    </NvConditionalRender>
  );
};
