import { NvCustomConditionIcon, NvFlex } from '@novaera/core';
import { assert } from '@novaera/utils';

import { ExternalAppConnectionType, useGetIntegrationEventRules } from '@novaera/actioner-service';
import { FC } from 'react';
import { PropertyPanelSimpleSection } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { SlackWorkspaceOrWorkflowConnectionSection } from '../../../../components/slack-workspace-or-workflow-connection-section';
import { DefaultPrincipalPropertyPanelSection } from '../../common/default-principal-property-panel-section';
import { PropertiesLoading } from '../../common/properties-loading';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { FormTriggerDefaultUserSection } from '../../form-trigger-property-panel-drawer/form-trigger-properties/form-trigger-default-user-section';
import { slackTriggerDefaultUserBehaviorOptions } from '../../form-trigger-property-panel-drawer/form-trigger-properties/form-trigger-default-user-section/constants';
import { useIntegrationTriggerProperties } from './controllers/use-integration-trigger-properties';
import { PanelHeader } from './panel-header';
import { TriggerPanel } from './trigger-panel';
import { TriggerPayloadPanel } from './trigger-payload-panel';
import { IntegrationTriggerPropertiesProps } from './types';
import { WebhookUrlPanel } from './webhook-url-panel';

export const IntegrationTriggerProperties: FC<React.PropsWithChildren<IntegrationTriggerPropertiesProps>> = ({
  onCloseClicked,
  onRootConditionClicked,
  workflow,
  savedWorkflow,
}) => {
  const { integration, saveDraftWorkflow, isLoading, showConnectionSelection } = useIntegrationTriggerProperties({
    workflow,
  });
  const { data, isLoading: eventRulesLoading } = useGetIntegrationEventRules({
    integrationId: integration?.id,
    version: integration?.latestVersion.number,
  });
  const { hasEditPermission } = useWorkflowPermission();

  assert(
    workflow.trigger?.type === 'integrationApp' || workflow.trigger?.type === 'integrationWebhook',
    new Error(`IntegrationTriggerProperties should use for integrationApp or integrationWebhook`),
    'ERROR'
  );

  return (
    <NvFlex width="100%">
      {!isLoading ? (
        <>
          <PanelHeader workflow={workflow} integration={integration} onCloseClicked={onCloseClicked} />
          <TriggerPanel workflow={workflow} savedWorkflow={savedWorkflow} />
          {!eventRulesLoading && data?.eventRules && data.eventRules.length > 0 && (
            <>
              {workflow.endpoint && <WebhookUrlPanel integrationName={integration?.name} workflow={workflow} />}
              {showConnectionSelection && (
                <SlackWorkspaceOrWorkflowConnectionSection
                  type="schema-selected-with-integration"
                  workflow={workflow}
                  integration={integration}
                  connectionIdValue={workflow.trigger?.connectionId}
                  onConnectionChange={(connection) => {
                    if (!hasEditPermission) {
                      return;
                    }

                    assert(
                      workflow.trigger?.type === 'integrationApp' || workflow.trigger?.type === 'integrationWebhook',
                      new Error(
                        `IntegrationTriggerProperties->SlackWorkspaceOrWorkflowConnectionSection onchange should use for integrationApp or integrationWebhook`
                      ),
                      'ERROR'
                    );
                    if (connection?.connectionId) {
                      const trigger = { ...workflow.trigger, connectionId: connection?.connectionId };

                      saveDraftWorkflow({
                        ...workflow,
                        trigger,
                      });
                    }
                  }}
                />
              )}
              {integration?.type !== 'slack' && (
                <DefaultPrincipalPropertyPanelSection defaultStarterUserId={workflow.trigger.defaultStarterUserId} />
              )}
              {integration?.type === 'slack' &&
                workflow.trigger.eventRuleIdentifier?.eventRuleId &&
                workflow.trigger.type === 'integrationApp' && (
                  <FormTriggerDefaultUserSection
                    enabled={ExternalAppConnectionType.NONE !== workflow.trigger.connectionType}
                    defaultStarterUserId={workflow.trigger.defaultStarterUserId}
                    useDefaultUserAsPrimeUser={workflow.trigger.connectionType}
                    defaultUserBehaviorOptions={slackTriggerDefaultUserBehaviorOptions}
                  />
                )}

              <TriggerPayloadPanel />
              <PropertyPanelSimpleSection>
                <NvFlex gap="8px">
                  <RowItemCard
                    rowItemLeftContentProps={{ draggable: false, icon: <NvCustomConditionIcon /> }}
                    rowItemContent={<SimpleLabelLayout simpleLabel="Trigger conditions" />}
                    onClick={onRootConditionClicked}
                  />
                </NvFlex>
              </PropertyPanelSimpleSection>
            </>
          )}
        </>
      ) : (
        <PropertiesLoading />
      )}
    </NvFlex>
  );
};
