import { NvTypography } from '@novaera/core';
import { FC, MouseEventHandler } from 'react';
import { StyledWrapper } from './styled';

export const UpdateVersionButton: FC<React.PropsWithChildren<{ onClick: MouseEventHandler<HTMLDivElement> }>> = ({
  onClick,
}) => {
  return (
    <StyledWrapper onClick={onClick}>
      <NvTypography variant="h5">Update available</NvTypography>
    </StyledWrapper>
  );
};
