import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const SearchEmptyStateCard = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 12px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  color: ${({ theme }) => theme.palette.nv_neutral[300]};
  border-radius: 6px;
`;
