import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Alternative, ThemeMode.Dark);

export default {
  primary: {
    main: '#C0AE4A',
  },
  secondary: {
    main: '#2277DD',
  },
  test: {
    value: 'testAlternativeDark',
  },
  ...colors,
};
