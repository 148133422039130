import { useAddSample, useGetLatestCapturedHook } from '@novaera/actioner-service';
import { NvButton, NvFlex, NvLink, NvReceiptLongIcon, NvSkeleton, NvTypography } from '@novaera/core';
import { useState } from 'react';
import { PropertyPanelSection, PropertyPanelSimpleSection } from '../../../../../../../../../components';
import { OutputItem } from '../../../../../../../../../components/output-item';
import { OutputWrapper } from '../../../../../../../../../components/output-item/styled';
import { useTriggerPayloadController } from '../../../integration-trigger-property-panel-drawer/integration-trigger-properties/trigger-payload-panel/controllers/use-trigger-payload';
import { AddGenericWebhookPayloadModal } from './payload-modal';
import { GenericWebhookPayloadProps } from './types';

export const GenericWebhookPayload: React.FC<React.PropsWithChildren<GenericWebhookPayloadProps>> = ({
  appId,
  workflowId,
}) => {
  const [isPayloadModalOpen, setIsPayloadModalOpen] = useState<boolean>(false);
  const { mutate: addSample } = useAddSample();

  const { mutate: getCapturedData, isLoading: isCapturingPayload } = useGetLatestCapturedHook();

  const { payload, isLoading } = useTriggerPayloadController();

  const handleTestWebhook = () => {
    getCapturedData(
      { appId, workflowId },
      {
        onSuccess: (capturedData) => {
          addSample({ appId: appId, workflowId: workflowId, sourceEventKey: capturedData.key });
        },
      }
    );
  };

  return (
    <>
      <PropertyPanelSimpleSection>
        <NvFlex direction="row" alignItems="center" gap="8px">
          <NvButton size="small" onClick={handleTestWebhook} loading={isCapturingPayload} disabled={isCapturingPayload}>
            Test webhook
          </NvButton>
          <NvTypography variant="h5">or</NvTypography>
          <NvButton
            size="small"
            color="secondary"
            onClick={() => {
              setIsPayloadModalOpen(true);
            }}
          >
            Add an example payload
          </NvButton>
        </NvFlex>
      </PropertyPanelSimpleSection>
      <PropertyPanelSection title="Webhook payload" icon={<NvReceiptLongIcon />}>
        <NvFlex gap="8px">
          <NvTypography variant="body2" textColor="secondary" display="inline">
            You can use the webhook payload below while configuring workflow conditions and actions.{' '}
            <NvLink target="_blank" href="https://actioner.com/help/webhook-triggers">
              Learn more
            </NvLink>
          </NvTypography>

          {isLoading ? (
            <NvSkeleton variant="rectangular" height="100px" />
          ) : payload ? (
            <OutputItem content={payload} jsonViewProps={{ collapsed: 2 }} />
          ) : (
            <OutputWrapper padding="16px">
              <NvTypography variant="body2" textColor="subtle">
                No data received or added.
              </NvTypography>
            </OutputWrapper>
          )}
        </NvFlex>

        {isPayloadModalOpen && (
          <AddGenericWebhookPayloadModal
            isOpened={isPayloadModalOpen}
            appId={appId}
            workflowId={workflowId}
            onClose={() => {
              setIsPayloadModalOpen(false);
            }}
          />
        )}
      </PropertyPanelSection>
    </>
  );
};
