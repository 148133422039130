import { EventRule, SimpleParameterMapping, useGetIntegration } from '@novaera/actioner-service';
import { CodeInput, NvBox, NvDivider, NvField, NvFlex, NvTypography, useField, useForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import OnChange from '../../../../action-designer/components/on-change';
import { ActionParameterMapper, ActionSearchField, MatchEventSection } from '../../../../components';
import { FILTERED_CONDITION_TYPE_OPTIONS } from '../../../../components/match-event-section/constants';
import { TestConfigurationBar } from '../../../../components/test-configuration-bar';
import { TestConfigurationProvider } from '../../../../components/test-configuration-bar/providers/test-configuration';
import { FormIdentifierProvider } from '../../../../providers/form-identifier-provider';
import { EventRuleFieldCard } from './styled';

export const EventDetailBody: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { integrationId } = useParams();
  const { change } = useForm();
  const {
    input: { value: transformerAction },
  } = useField<EventRule['transformerAction']>('transformerAction');
  const {
    input: { value: parameterMappings },
  } = useField<SimpleParameterMapping[]>('transformerAction.parameterMappings', { format: (x) => x });

  const { data } = useGetIntegration({ id: integrationId });

  return (
    <>
      <OnChange name="transformerAction.actionId">
        {() => {
          change(`transformerAction.parameterMappings`, []);
        }}
      </OnChange>
      <TestConfigurationProvider>
        {({ state }) => {
          return (
            <NvFlex gap="16px" paddingTop="16px">
              <TestConfigurationBar />
              <EventRuleFieldCard>
                <NvTypography variant="h4">Filter</NvTypography>
                <NvTypography variant="body2">
                  You can filter the event data received through the webhook subscriptions.
                </NvTypography>
                <MatchEventSection codeInputContext={{}} conditionTypeOptions={FILTERED_CONDITION_TYPE_OPTIONS} />
              </EventRuleFieldCard>
              <EventRuleFieldCard>
                <NvFlex gap="8px">
                  <NvTypography variant="h4">Action</NvTypography>
                  <NvTypography variant="body2">
                    You can enrich the event data received through webhook subscriptions by utilizing actions. When an
                    action is added to the trigger, the response from the action will define the integration trigger.
                  </NvTypography>
                </NvFlex>
                <NvBox maxWidth="400px" width="100%">
                  <ActionSearchField
                    integrationId={integrationId}
                    version={data?.latestVersion.number}
                    fieldName={'transformerAction.actionId'}
                  />
                </NvBox>

                {transformerAction?.actionId && (
                  <>
                    <NvDivider />
                    <FormIdentifierProvider>
                      <ActionParameterMapper
                        type="in-integration"
                        actionId={transformerAction.actionId}
                        integrationId={integrationId}
                        connectionId={state.selectedConnection?.id}
                        versionNumber={1}
                        initialParameterMappings={parameterMappings}
                        onParameterMappingsChanged={({ inputParametersValues }) => {
                          // The backend does not support dynamic fields in the transformer actions. so we don't need to send formId here.
                          change(`transformerAction.parameterMappings`, inputParametersValues);
                        }}
                      />
                    </FormIdentifierProvider>
                  </>
                )}
              </EventRuleFieldCard>
              <EventRuleFieldCard>
                <NvFlex gap="8px">
                  <NvTypography variant="h4">Sample payload *</NvTypography>
                  <NvTypography variant="body2">
                    Add a sample payload for your integration trigger, which will be presented to workflow builders to
                    assist in the design of their flows.
                  </NvTypography>
                </NvFlex>

                <NvFlex height="500px">
                  <NvField<string>
                    showErrorMessageOnlyWhenBlur
                    name="sampleEventPayload.payload"
                    component={({ value, onChange, ...props }) => (
                      <CodeInput
                        value={value}
                        onChange={onChange}
                        placeholder="Enter sample payload"
                        {...props}
                        context={{}}
                        expectedType="object"
                        mode="json"
                        lineNumbers
                        hasBorder
                      />
                    )}
                  />
                </NvFlex>
              </EventRuleFieldCard>
            </NvFlex>
          );
        }}
      </TestConfigurationProvider>
    </>
  );
};
