import { JobTarget } from './job-target';
import { RecordField, RecordUpdate } from './record';
import { SlackBlock } from './slack-blocks';

export enum ParameterTypes {
  SIMPLE = 'simple',
  SLACK_BLOCK = 'slack-block',
  JOB_TARGET = 'job-target',
  RECORD_ATTRIBUTES = 'record-attributes',
  RECORD_PARTIAL_UPDATE = 'record-partial-update',
  CATALOG_RELATIONSHIP_FILTER = 'catalog-relationship-filter',
}

export enum ValueTypes {
  STRING = 'string',
  NUMBER = 'number',
  LIST = 'list',
  BOOLEAN = 'boolean',
  OBJECT = 'object',
}

interface Value<T> {
  type: ValueTypes;
  value?: T;
  displayValue?: string;
}

export type StringValue = {
  type: ValueTypes.STRING;
} & Partial<Value<string>>;

export type NumberValue = {
  type: ValueTypes.NUMBER;
  codeTemplate?: string;
} & Value<number>;

export type BooleanValue = {
  type: ValueTypes.BOOLEAN;
  codeTemplate?: string;
} & Value<boolean>;

export type SingularValue = StringValue | NumberValue | BooleanValue | ObjectValue;

export type ListValue = {
  type: ValueTypes.LIST;
  value?: SingularValue[];
  codeTemplate?: string;
};

export type ObjectValue = {
  type: ValueTypes.OBJECT;
  codeTemplate?: string;
} & Value<unknown>;

export type InputParameterValues = StringValue | NumberValue | ListValue | BooleanValue | ObjectValue;

export interface BaseParameter {
  type: ParameterTypes;
  parameterId: string;
}

export interface SimpleParameterMapping<T = InputParameterValues> extends BaseParameter {
  type: ParameterTypes.SIMPLE;
  value?: T;
  displayValue?: T;
  hidden?: boolean;
}

export interface SlackBlockParameterMapping extends BaseParameter {
  type: ParameterTypes.SLACK_BLOCK;
  blocks: SlackBlock[];
}

export interface RecordAttributesParameterMapping extends BaseParameter {
  type: ParameterTypes.RECORD_ATTRIBUTES;
  fields: RecordField[];
}

export interface RecordPartialUpdateParameterMapping extends BaseParameter {
  type: ParameterTypes.RECORD_PARTIAL_UPDATE;
  updates: RecordUpdate[];
}

export type CatalogRelationshipFilter = {
  relationshipDefinitionId: StringValue;
  query: StringValue;
};

export interface CatalogRelationshipFilterParameterMapping extends BaseParameter {
  type: ParameterTypes.CATALOG_RELATIONSHIP_FILTER;
  filters: CatalogRelationshipFilter[];
}

export type ParameterMapping =
  | SimpleParameterMapping
  | SlackBlockParameterMapping
  | RecordAttributesParameterMapping
  | RecordPartialUpdateParameterMapping
  | JobTargetParameterMapping
  | CatalogRelationshipFilterParameterMapping;

type JobTargetExtended = JobTarget & BaseParameter;
export interface JobTargetParameterMapping extends JobTargetExtended {
  type: ParameterTypes.JOB_TARGET;
}

export type ParameterMappings = ParameterMapping[];
