import { BorderedCard, NvField, NvFlex, NvHelpIcon, NvSwitch, NvTooltip, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

type ApplySSOProps = {
  emailDomain?: string;
  userEmailDomain: string;
};

export const ApplySSO = ({ emailDomain, userEmailDomain }: ApplySSOProps) => {
  const { palette } = useTheme();

  return (
    <BorderedCard
      sx={{
        width: 'fit-content',
      }}
    >
      <NvField
        name="emailDomain"
        component={({ onChange, checked, value }) => {
          return (
            <NvFlex flexDirection={'row'} gap={'8px'} alignItems={'center'}>
              <NvSwitch
                variant="compact"
                onChange={(e, checked) => {
                  onChange(checked ? emailDomain ?? userEmailDomain : null);
                }}
                value={Boolean(value)}
                checked={Boolean(value)}
              />
              <NvFlex flexDirection={'row'} alignItems={'center'} gap={'2px'}>
                <NvTypography variant="body2">
                  Apply SSO only for <b>{emailDomain ?? userEmailDomain}</b>
                </NvTypography>
                <NvTooltip
                  title={`To enable Single Sign-On (SSO) exclusively for users with email addresses under a specific domain, check this box. SSO will only apply to users with email addresses ending with the domain configured for ${
                    emailDomain ?? userEmailDomain
                  }`}
                >
                  <NvHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={palette.nv_neutral[70]} />
                </NvTooltip>
              </NvFlex>
            </NvFlex>
          );
        }}
      />
    </BorderedCard>
  );
};
