import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { IntegrationAction } from '../../types';
import { INTEGRATION_ACTION_RESPONSE_ROLLBACK_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { GetIntegrationActionsResponse, RollbackIntegrationActionParams } from '../types';

const rollbackIntegrationAction = async (params: RollbackIntegrationActionParams) => {
  const { integrationId, actionId } = params;
  const result = await NvAxios.post<IntegrationAction>(
    INTEGRATION_ACTION_RESPONSE_ROLLBACK_PATH(integrationId, actionId)
  );
  return result?.data;
};

export const useRollbackIntegrationAction = () => {
  const cache = useQueryClient();

  return useMutation(rollbackIntegrationAction, {
    onSuccess(rolledBackIntegrationAction, { integrationId, actionId, version }) {
      cache.setQueryData(
        QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version),
        (old?: GetIntegrationActionsResponse) => {
          if (old) {
            return {
              ...old,
              actions: old.actions.map((action) => (action.id === actionId ? rolledBackIntegrationAction : action)),
            };
          }
          return old;
        }
      );
      cache.setQueryData(QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version), {
        persisted: rolledBackIntegrationAction,
      });
    },
  });
};
