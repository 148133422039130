import { UserApp } from '@novaera/actioner-service';
import {
  AccountCircleIcon,
  DefaultRenderOption,
  Initial,
  NvAutocomplete,
  NvBox,
  NvDivider,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvImage,
  NvTextField,
  NvTypography,
  isMaxLength,
  isRequired,
  useForm,
  useFormState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import OnChange from '../../../../action-designer/components/on-change';
import { useGetWorkflowQueryParams } from '../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { DependencyForm } from '../types';
import { useAddDependency } from './controllers/use-add-dependency';
import { LoadingSkeleton } from './loading-skeleton';

export const AddDependency = () => {
  const { userAppId } = useGetWorkflowQueryParams();

  const { onAppSearchKeywordChange, userApps, isLoading } = useAddDependency({
    filters: [
      {
        field: 'dependencies',
        operation: 'not-exist',
      },
      {
        field: 'id',
        operation: 'not-equals',
        value: userAppId,
      },
    ],
  });

  const { change } = useForm<DependencyForm>();
  const { values } = useFormState<DependencyForm>();
  const theme = useTheme();

  return isLoading ? (
    <LoadingSkeleton />
  ) : (
    <NvFlex gap={'16px'}>
      <NvTypography variant="body1">
        Connect to another app by adding a dependency to invoke workflows of selected app.
      </NvTypography>
      <NvBox width={'80%'}>
        <NvField<UserApp>
          labelText="App"
          direction="label-on-top"
          validators={[isRequired()]}
          hasRequiredIndicator
          showErrorMessageOnlyWhenBlur
          isAutoComplete
          name={'appId'}
          parse={(value: UserApp) => {
            return value?.id;
          }}
          format={(value: string) => {
            return userApps.find((app) => app.id === value) ?? null;
          }}
          component={
            <NvAutocomplete
              onInputChange={(e, value, reason) => {
                if (!(value && reason === 'reset')) {
                  onAppSearchKeywordChange(value);
                }
              }}
              options={userApps ?? []}
              getOptionLabel={(option) => option.name ?? ''}
              renderInput={(props) => {
                const selectedApp = userApps.find((app) => app.id === values.appId);
                return (
                  <NvTextField
                    {...props}
                    placeholder="Select an app"
                    {...(selectedApp && {
                      startIcon: (
                        <NvImage
                          size="small"
                          src={selectedApp.logoUrl}
                          FallBack={
                            <Initial size="small" color={theme.palette.nv_neutral[500]} value={selectedApp.name} />
                          }
                        />
                      ),
                    })}
                  />
                );
              }}
              renderOption={(props, option) => (
                <DefaultRenderOption {...props} key={option.id}>
                  <NvFlex direction="row" alignItems="center" gap="8px">
                    <NvImage
                      size="small"
                      imageShape="circle"
                      src={option?.logoUrl}
                      srcSet={option?.logoUrl}
                      FallBack={<AccountCircleIcon />}
                    />

                    <NvTypography>{option.name}</NvTypography>
                    {option.schemaVersion && (
                      <>
                        <NvDivider
                          orientation="vertical"
                          sx={{
                            height: '9px',
                          }}
                        />
                        <NvTypography variant="h6">{option.schemaVersion}</NvTypography>
                      </>
                    )}
                  </NvFlex>
                </DefaultRenderOption>
              )}
              popupIcon={<NvExpandMoreIcon />}
              fullWidth
            />
          }
        />
      </NvBox>
      <NvBox width={'60%'}>
        <NvField<string>
          labelText="Dependency name"
          direction="label-on-top"
          name="name"
          component={<NvTextField placeholder="Write a dependency name" />}
          validators={[
            isRequired(),
            isMaxLength({
              length: 100,
            }),
          ]}
          hasRequiredIndicator
          showErrorMessageOnlyWhenBlur
        />
      </NvBox>
      <OnChange name="appId">
        {(value) => {
          if (!values.name) {
            change('name', userApps.find((app) => app.id === value)?.name ?? '');
          }

          change('appSchemaId', userApps.find((app) => app.id === value)?.schemaId ?? undefined);
        }}
      </OnChange>
    </NvFlex>
  );
};
