import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_MS_TEAMS_WORKSPACE } from '../keys';

const deleteMsTeamsWorkspace = async () => {
  const result = await NvAxios.delete('/v1/msteams');
  return result?.data;
};

export const useDeleteMsTeamsWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMsTeamsWorkspace, {
    onSuccess: () => {
      queryClient.setQueryData(QUERY_KEYS_MS_TEAMS_WORKSPACE.all, null);
    },
  });
};
