import { NvAxios, useQuery } from '@novaera/core';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemaDetailParams, GetAppSchemaDetailResponse } from './types';

const getAppSchemaDetail = async ({ schemaId, version, isAnonymous }: GetAppSchemaDetailParams) => {
  const result = await NvAxios.get<GetAppSchemaDetailResponse>(
    isAnonymous
      ? `${APP_DIRECTORY_ROOT_PATH}/anonymous/schemas/${schemaId}`
      : `${APP_DIRECTORY_ROOT_PATH}/schemas/${schemaId}`,
    { params: version ? { version } : {} }
  );
  return result?.data;
};

export const useGetAppSchemaDetail = (params: GetAppSchemaDetailParams, onError?: (error: unknown) => void) =>
  useQuery<GetAppSchemaDetailResponse>(
    QUERY_KEYS_APP_DIRECTORY.detail(params.schemaId, undefined, params.isAnonymous),
    () => getAppSchemaDetail(params),
    {
      onError,
      enabled: !!params.schemaId,
    }
  );
