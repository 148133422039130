import { ControlProps } from '@reactflow/controls';
import { useReactFlow } from '@reactflow/core';
import { NvCenterFocusStrongIcon, NvZoomInIcon, NvZoomOutIcon } from '../../../ui/icons';
import { ControlsWithoutShadow, CustomControlButton, CustomControlsWrapper } from './styled';

export const CustomControls: React.FC<React.PropsWithChildren<ControlProps>> = (props) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  return (
    <ControlsWithoutShadow
      {...props}
      showZoom={false}
      showFitView={false}
      showInteractive={false}
      position={'bottom-right'}
    >
      <CustomControlsWrapper>
        <CustomControlButton size="small" color="ghost" onlyIcon onClick={() => zoomIn()}>
          <NvZoomInIcon />
        </CustomControlButton>
        <CustomControlButton size="small" color="ghost" onlyIcon onClick={() => fitView()}>
          <NvCenterFocusStrongIcon />
        </CustomControlButton>
        <CustomControlButton size="small" color="ghost" onlyIcon onClick={() => zoomOut()}>
          <NvZoomOutIcon />
        </CustomControlButton>
      </CustomControlsWrapper>
    </ControlsWithoutShadow>
  );
};
