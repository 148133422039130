import {
  AppDocumentCategory,
  GetUserAppResponse,
  MediaFileType,
  getUserApp,
  useDeleteAppSchema,
  useFetchProfile,
  useGetAppSchemaDetail,
  useInstallAppSchema,
  useUpdateAppSchemaStar,
} from '@novaera/actioner-service';
import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, LOCAL_STORAGE_ACCESS_KEY, ROUTE_DEFAULTS } from '@novaera/constants';
import {
  CarouselItemType,
  NvSubjectIcon,
  NvTypography,
  NvWorkflowIcon,
  TypeOfTab,
  isAxiosError,
  useAxiosErrorHandler,
  useConfirmDialog,
  useToast,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert, getSubdomain, switchToWorkspace } from '@novaera/utils';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../../common/routes';
import { RealTimeTaskEngine } from '../../../../real-time/real-time-task-engine';
import { USER_APP_SETUP } from '../../../../user-app/user-app-detail/constants';
import { COMING_SOON_APP_TAG } from '../../../constants';
import { boostNumberOfStars } from '../../../utils';

export const useAppDirectoryDetail = ({
  isAnonymous,
  appDirectoryContainerRef,
}: {
  isAnonymous: boolean;
  appDirectoryContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { storeSchemaId } = useParams();
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');

  const appDirectoryDetailHeaderRef = useRef<HTMLDivElement | null>(null);
  const workflowsSectionRef = useRef<HTMLDivElement | null>(null);
  const [isVersionHistoryModalOpen, setIsVersionHistoryModalOpen] = useState<boolean>(false);
  const [isFocusStateOpen, setIsFocusStateOpen] = useState<boolean>(false);
  const { mutate: installApp } = useInstallAppSchema();
  const [isInstallAppLoading, setInstallAppLoading] = useState<boolean>();
  const { openConfirm } = useConfirmDialog();
  const { addToast } = useToast();
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [focusIndex, setFocusIndex] = useState<number>();

  const { data: userData } = useFetchProfile();
  const { data: activeAppSchema, isLoading: isActiveAppSchemaLoading } = useGetAppSchemaDetail(
    {
      schemaId: storeSchemaId,
      version: version,
      isAnonymous,
    },
    (error) => {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          navigate('/error/404');
        } else {
          axiosErrorHandler(error);
        }
      } else if (error instanceof Error) {
        addToast(`The app directory schema cannot be retrieved, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`The app directory schema cannot be retrieved, reason: ${JSON.stringify(error)}`, {
          variant: 'error',
        });
      }
      return;
    }
  );

  const activeAppSchemaSupportLinks = useMemo(
    () => activeAppSchema?.documents?.filter((document) => document.category === AppDocumentCategory.SUPPORT),
    [activeAppSchema?.documents]
  );

  const activeAppSchemaUseCasesLinks = useMemo(
    () => activeAppSchema?.documents?.filter((document) => document.category === AppDocumentCategory.USE_CASE),
    [activeAppSchema?.documents]
  );

  const carouselItems = useMemo(
    () =>
      activeAppSchema?.mediaFiles?.map((mediaFile) => ({
        url: mediaFile.fileReference.url ?? '',
        displayText: mediaFile.displayText,
        type: mediaFile.type === MediaFileType.IMAGE ? CarouselItemType.IMG : CarouselItemType.VIDEO,
      })),
    [activeAppSchema?.mediaFiles]
  );
  const appDirectoryDetailTabs = useMemo(
    () => [
      {
        label: 'Description',
        value: 'description',
        content: <></>,
        icon: <NvSubjectIcon />,
        iconPosition: 'start' as TypeOfTab['iconPosition'],
      },
      {
        label: 'Workflows',
        value: 'workflows',
        content: <></>,
        icon: <NvWorkflowIcon />,
        iconPosition: 'start' as TypeOfTab['iconPosition'],
      },
    ],
    []
  );

  const updatedNumberOfStars = useMemo(
    () =>
      activeAppSchema
        ? boostNumberOfStars({
            name: activeAppSchema.name,
            numberOfStars: activeAppSchema.numberOfStars,
            contributors: activeAppSchema.contributors,
          })
        : null,
    [activeAppSchema]
  );

  const getTabValueWithHash = useCallback(
    (index: number) => `#${appDirectoryDetailTabs[index].value}`,
    [appDirectoryDetailTabs]
  );

  const getTabIndex = useCallback(
    (value: string) => {
      let cleanValue = value;
      if (cleanValue.startsWith('#')) {
        cleanValue = cleanValue.slice(1);
      }
      let tabIndex = appDirectoryDetailTabs?.findIndex((tab) => tab.value === cleanValue);
      if (tabIndex < 0) {
        tabIndex = 0;
      }
      return tabIndex;
    },
    [appDirectoryDetailTabs]
  );

  const { mutate: updateSchemaStar, isLoading: isUpdateSchemaStarLoading } = useUpdateAppSchemaStar({
    schemaId: storeSchemaId,
    version: version,
  });
  const { mutate: deleteSchema } = useDeleteAppSchema();

  const scrollToSection = useCallback(
    (index: number) => {
      const scrollBuffer = 16;

      if (index === 0) {
        appDirectoryContainerRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else if (index === 1) {
        const workflowsSectionOffsetTop = workflowsSectionRef.current?.offsetTop ?? 0;
        appDirectoryContainerRef.current?.scrollTo({
          top: workflowsSectionOffsetTop - scrollBuffer,
          behavior: 'smooth',
        });
      }
    },
    [appDirectoryContainerRef]
  );

  useEffect(() => {
    const tabIndex = getTabIndex(hash);
    setFocusIndex(tabIndex);
    if (!isActiveAppSchemaLoading) {
      scrollToSection(tabIndex);
    }
  }, [getTabIndex, hash, isActiveAppSchemaLoading, scrollToSection]);

  const handleActiveTabChange = useCallback(
    (index: number) => {
      navigate(getTabValueWithHash(index));
      scrollToSection(index);
    },
    [getTabValueWithHash, navigate, scrollToSection]
  );

  const onVersionClick = () => {
    setIsVersionHistoryModalOpen(true);
  };

  const onVersionHistoryModalClose = () => {
    setIsVersionHistoryModalOpen(false);
  };

  const onToggleSchemaStar = (starred: boolean) => {
    updateSchemaStar({ schemaId: storeSchemaId, undo: starred });
  };

  const onInstallClick = () => {
    assert(activeAppSchema !== undefined, new Error('Install button is clicked without selecting an app'));
    setInstallAppLoading(true);
    if (activeAppSchema.slackAppDetails.inMarketplace) {
      window.location.href = activeAppSchema.slackAppDetails.installationUrl;
      return;
    } else {
      return new Promise<void>((resolve) => {
        let timeout: NodeJS.Timeout | undefined;
        installApp(
          {
            schemaId: activeAppSchema?.id,
            version: activeAppSchema?.version,
          },
          {
            onSuccess: ({ packageId: appId }) => {
              const newTask = new RealTimeTaskEngine<
                GetUserAppResponse,
                {
                  id: string;
                  type: 'app-installation';
                  userId: string;
                  workspaceId: string;
                  properties: string;
                }
              >({
                id: appId,
                pollingCallback: async () => {
                  const result = await getUserApp(appId);

                  if (!result || result.installationStatus === 'in-progress') {
                    throw new Error('in_progress');
                  }

                  return result;
                },
                resultAction: async ({ resultContext: result, source }) => {
                  if (source === 'socket') {
                    if (result.type === 'app-installation') {
                      const appInstallationResult = JSON.parse(result.properties) as { appId?: string };

                      if (appInstallationResult.appId) {
                        setInstallAppLoading(false);
                        resolve();
                        if (timeout) {
                          clearTimeout(timeout);
                        }
                        navigate(USER_APP_SETUP(appInstallationResult.appId));
                      } else {
                        addToast(
                          `The installation has finished with errors, which may result in issues with workflows and AI assistant functionalities.`,
                          {
                            variant: 'error',
                          }
                        );
                      }
                    }
                  } else {
                    setInstallAppLoading(false);
                    resolve();
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    navigate(USER_APP_SETUP(appId));
                  }
                },
              });

              newTask.executePolling();
              timeout = setTimeout(() => {
                newTask.stopPolling();

                navigate(USER_APP_SETUP(appId));
                assert(
                  false,
                  new Error(
                    'customer is redirected to app page without getting app installation is completed. AppId:' + appId
                  ),
                  'ERROR'
                );
              }, 30000);
            },
          }
        );
      });
    }
  };

  const isOwner = useMemo(
    () => !!activeAppSchema?.contributors.find((contributor) => contributor.id === userData?.userId),
    [activeAppSchema, userData]
  );

  const onUpdateClick = () => {
    setIsFocusStateOpen(true);
  };

  const onUpdateAppModalClose = () => {
    setIsFocusStateOpen(false);
  };

  const handleDeleteActionPack = (id: string) => {
    openConfirm({
      confirmButtonLabel: 'Delete',
      title: `Delete ${activeAppSchema?.name}?`,
      message: (
        <NvTypography variant="body1">
          If you delete <b>{activeAppSchema?.name}</b>, its current and previous versions will be permanently removed
          from app directory. It will not be deleted from any workspace that has this app installed.
        </NvTypography>
      ),
      onConfirm: () => {
        deleteSchema(id, {
          onSuccess: () => {
            navigate(ROUTES.AppDirectory);
          },
        });
      },
    });
  };

  const handleSignInToInstall = useCallback(() => {
    if (activeAppSchema?.tags.find((t) => t.label === COMING_SOON_APP_TAG[0].label)) {
      window.location.href = 'https://actioner.com/coming-soon';
      return;
    }
    const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);
    const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);
    const subdomain = getSubdomain();

    if (accessToken && lastActiveWorkspace && subdomain === '') {
      switchToWorkspace(lastActiveWorkspace, window.location.pathname);
      return;
    }

    const targetUri = window.location.pathname;

    window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.SIGN_IN}?targetUri=${targetUri}`;
  }, [activeAppSchema?.tags]);

  return {
    activeAppSchema,
    isActiveAppSchemaLoading,
    onVersionClick,
    isVersionHistoryModalOpen,
    onVersionHistoryModalClose,
    onToggleSchemaStar,
    isUpdateSchemaStarLoading,
    onInstallClick,
    isOwner,
    isFocusStateOpen,
    setIsFocusStateOpen,
    onUpdateClick,
    onUpdateAppModalClose,
    appLogoInitialColor: theme.palette.nv_neutral[500],
    onDeleteActionPack: handleDeleteActionPack,
    isInstallAppLoading,
    handleSignInToInstall,
    activeAppSchemaSupportLinks,
    activeAppSchemaUseCasesLinks,
    carouselItems,
    appDirectoryDetailTabs,
    handleActiveTabChange,
    appDirectoryDetailHeaderRef,
    workflowsSectionRef,
    isMobile,
    focusIndex,
    updatedNumberOfStars,
  };
};
