import { useGetIntegrationAction } from '@novaera/actioner-service';
import { NvFlex, TypeOfTab } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useCallback, useMemo, useState } from 'react';
import { ErrorMarker } from '../../components/error-marker';
import { FunctionsTab } from '../../components/functions-tab';
import { InputTab } from '../../components/input-tab';
import { RequestTab } from '../../components/request-tab';
import { ResponseTab } from '../../components/response-tab';

export const useActionDesigner = ({ integrationVersion }: { integrationVersion?: number }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeBottomTabIndex, setActiveBottomTabIndex] = useState<number>(0);
  const { integrationId, actionId } = useParams<{ integrationId: string; actionId: string }>();

  //If defaultTab is first one, collapsedIndex should be 1.
  const [collapsedIndex, setCollapsedIndex] = useState<number | undefined>(undefined);
  //If defaultTab is first one, isExpanded should be false.
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const tabErrors = {
    input: false,
    response: false,
    request: false,
    function: false,
  };

  const { data, isInitialLoading } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integrationVersion,
  });

  const onExpendClicked = useCallback((index: number) => {
    setCollapsedIndex(index === 0 ? 1 : 0);
    setIsExpanded(false);
  }, []);

  const onCollapseClicked = useCallback(
    (index: number) => {
      if (index === 0) {
        if (collapsedIndex === undefined || collapsedIndex === 1) {
          setCollapsedIndex(0);
          setIsExpanded(false);
        } else {
          setCollapsedIndex(undefined);
          setIsExpanded(true);
        }
      }

      if (index === 1) {
        if (collapsedIndex === undefined || collapsedIndex === 0) {
          setCollapsedIndex(1);
          setIsExpanded(false);
        } else {
          setCollapsedIndex(undefined);
          setIsExpanded(true);
        }
      }
    },
    [collapsedIndex]
  );

  const handleTestExecuted = useCallback(() => {
    setActiveTabIndex(1);
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Input',
        value: 'input',
        content: <InputTab onTestExecuted={handleTestExecuted} />,
        right: true,
        icon: tabErrors.input ? (
          <NvFlex marginLeft="4px">
            <ErrorMarker variant="default" />
          </NvFlex>
        ) : undefined,
        iconPosition: 'end' as TypeOfTab['iconPosition'],
      },
      {
        label: 'Response',
        value: 'response',
        content: <ResponseTab />,
        right: true,
        icon: tabErrors.response ? (
          <NvFlex marginLeft="4px">
            <ErrorMarker variant="default" />
          </NvFlex>
        ) : undefined,
        iconPosition: 'end' as TypeOfTab['iconPosition'],
      },
    ].filter((t) => t.right);
  }, [handleTestExecuted, tabErrors.input, tabErrors.response]);

  const bottomTabs = useMemo(
    () =>
      [
        {
          label: 'Request',
          value: 'request',
          content: <RequestTab />,
          right: true,
          icon: tabErrors.request ? (
            <NvFlex marginLeft="4px">
              <ErrorMarker variant="default" />
            </NvFlex>
          ) : undefined,
          iconPosition: 'end' as TypeOfTab['iconPosition'],
        },
        {
          label: 'Functions',
          value: 'functions',
          content: <FunctionsTab />,
          right: true,
        },
      ].filter((t) => t.right),
    [tabErrors.request]
  );

  const handleTopTabsOnChange = useCallback((selectedTabIndex: number) => {
    setActiveTabIndex(selectedTabIndex);
  }, []);

  const handleBottomTabsOnChange = (selectedTabIndex: number) => {
    setActiveBottomTabIndex(selectedTabIndex);
  };

  return {
    collapsedIndex,
    isExpanded,
    activeTabIndex,
    tabs,
    bottomTabs,
    isReady: !!data && !isInitialLoading,
    onCollapseClicked,
    handleTopTabsOnChange,
    handleBottomTabsOnChange,
    onExpendClicked,
    setCollapsedIndex,
    setIsExpanded,
    activeBottomTabIndex,
  };
};
