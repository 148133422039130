import {
  ExternalAppConnectionType,
  FormTrigger,
  NonFormTrigger,
  WorkflowWithState,
  useGetWorkflow,
  useSaveToDraftWorkflow,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../controllers/use-get-workflow-query-params';

export const useDefaultUserBehavior = () => {
  const { mutate: saveToDraft, isLoading: isSaveLoading } = useSaveToDraftWorkflow();
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const handleUpdateDefaultUserBehavior = useCallback(
    (useDefaultUserAsPrimeUser: boolean | ExternalAppConnectionType) => {
      const trigger = workflow?.trigger;
      if (trigger && trigger.type === 'form') {
        assert(
          typeof useDefaultUserAsPrimeUser === 'boolean',
          new Error('In form useDefaultUserAsPrimeUser should be a boolean'),
          'ERROR'
        );

        const newTrigger: FormTrigger = {
          ...trigger,
          useDefaultUserAsPrimeUser,
        };
        const newWorkflow: WorkflowWithState = {
          ...workflow,
          trigger: newTrigger,
        };
        saveToDraft(newWorkflow);
      } else if (trigger && trigger.type === 'integrationApp') {
        assert(
          typeof useDefaultUserAsPrimeUser === 'string' &&
            [
              ExternalAppConnectionType.FALLBACK_TO_DEFAULT_USER,
              ExternalAppConnectionType.NONE,
              ExternalAppConnectionType.OVERRIDE_BY_DEFAULT_USER,
            ].includes(useDefaultUserAsPrimeUser),
          new Error('In form useDefaultUserAsPrimeUser should be one of ExternalAppConnectionType'),
          'ERROR'
        );
        const newTrigger: NonFormTrigger = {
          ...trigger,
          connectionType: useDefaultUserAsPrimeUser,
        };
        const newWorkflow: WorkflowWithState = {
          ...workflow,
          trigger: newTrigger,
        };
        saveToDraft(newWorkflow);
      }
    },
    [saveToDraft, workflow]
  );

  return { handleUpdateDefaultUserBehavior, isSaveLoading };
};
