import { APP_PERMISSION, JobCreatorType, JobCreatorUser, JobTargetType } from '@novaera/actioner-service';
import {
  BasicTableCell,
  BasicTableRow,
  NvAddBoxIcon,
  NvBasicTable,
  NvBox,
  NvButton,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSkeleton,
  NvTypography,
  NvWorkflowIcon,
} from '@novaera/core';
import { EmptyState } from '../../../../components/empty-state';
import { EmptyStates } from '../../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { useUserAppPermissionBoundary } from '../../../user-app-permission-boundary/use-user-app-permission-boundary';
import { USER_APP_WORKFLOW } from '../../constants';
import { JobCreatorUserItem, JobLinkItem } from '../components';
import { useScheduledJobs } from '../controllers/use-scheduled-jobs';
import { BasicTableCellForFooter } from '../styled';
import { ScheduledJobsProps } from './types';

export const ScheduledJobs: React.FC<React.PropsWithChildren<ScheduledJobsProps>> = ({
  timezoneId,
  handleAddClick,
  handleEditClick,
}) => {
  const { getScheduledJobsPages, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, menuItems, userAppId } =
    useScheduledJobs({ timezoneId, handleEditClick });

  const { checkPermission } = useUserAppPermissionBoundary({
    appId: userAppId,
  });

  const { isAppFree } = useIsAppFree({ userAppId });

  return !timezoneId || isLoading ? (
    <NvFlex gap="12px">
      <NvSkeleton height="68px" variant="rectangular" />
      <NvSkeleton height="68px" variant="rectangular" />
      <NvSkeleton height="68px" variant="rectangular" />
    </NvFlex>
  ) : !getScheduledJobsPages ||
    (getScheduledJobsPages && getScheduledJobsPages.length === 1 && getScheduledJobsPages[0].jobs.length === 0) ? (
    <EmptyState
      variant={EmptyStates.SCHEDULED_JOB}
      CustomButton={
        <FreeAppPermissionBoundary>
          <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
            <NvButton
              startIcon={<NvAddBoxIcon />}
              color="secondary"
              onClick={handleAddClick}
              sx={{ flex: '0 0 auto' }}
              size="small"
            >
              Add scheduled job
            </NvButton>
          </UserAppPermissionBoundary>
        </FreeAppPermissionBoundary>
      }
    />
  ) : (
    <NvBasicTable
      tableHeader={
        <BasicTableRow>
          <BasicTableCell>Run date</BasicTableCell>
          <BasicTableCell>Run</BasicTableCell>
          <BasicTableCell>Created by</BasicTableCell>
          <BasicTableCell></BasicTableCell>
        </BasicTableRow>
      }
      tableBody={
        <>
          {getScheduledJobsPages.map(({ jobs }) =>
            jobs.map((job) => (
              <BasicTableRow
                key={`scheduled-job-table-row-${job.id}`}
                {...(isAppFree
                  ? {}
                  : {
                      onClick: () => {
                        if (!checkPermission(APP_PERMISSION.APP_EDIT)) {
                          return;
                        }

                        handleEditClick({ id: job.id, type: job.type });
                      },
                    })}
                sx={{ cursor: 'pointer' }}
              >
                <BasicTableCell>
                  <NvTypography variant="body2" padding="3px 0">
                    {job.timeDisplay.runsAt}
                  </NvTypography>
                </BasicTableCell>
                <BasicTableCell>
                  <NvBox padding="3px 0">
                    {job.target.type === JobTargetType.WORKFLOW && (
                      <FreeAppPermissionBoundary
                        Fallback={
                          <JobLinkItem
                            to={USER_APP_WORKFLOW({ userAppId, workflowId: job.target.id })}
                            icon={<NvWorkflowIcon />}
                            name={job.target.displayName}
                            disabled
                          />
                        }
                      >
                        <UserAppPermissionBoundary
                          appId={userAppId}
                          permission={APP_PERMISSION.APP_EDIT}
                          Fallback={
                            <JobLinkItem
                              to={USER_APP_WORKFLOW({ userAppId, workflowId: job.target.id })}
                              icon={<NvWorkflowIcon />}
                              name={job.target.displayName}
                              disabled
                            />
                          }
                        >
                          <JobLinkItem
                            to={USER_APP_WORKFLOW({ userAppId, workflowId: job.target.id })}
                            icon={<NvWorkflowIcon />}
                            name={job.target.displayName}
                          />
                        </UserAppPermissionBoundary>
                      </FreeAppPermissionBoundary>
                    )}
                  </NvBox>
                </BasicTableCell>
                <BasicTableCell>
                  {job.creator.type === JobCreatorType.USER ? (
                    <NvBox padding="2px 0">
                      <JobCreatorUserItem creator={job.creator as JobCreatorUser} />
                    </NvBox>
                  ) : (
                    <NvBox padding="3px 0">
                      {job.creator.type === JobCreatorType.WORKFLOW && (
                        <FreeAppPermissionBoundary
                          Fallback={
                            <JobLinkItem
                              to={USER_APP_WORKFLOW({ userAppId, workflowId: job.creator.id })}
                              icon={<NvWorkflowIcon />}
                              name={job.creator.displayName}
                              disabled
                            />
                          }
                        >
                          <UserAppPermissionBoundary
                            appId={userAppId}
                            permission={APP_PERMISSION.APP_EDIT}
                            Fallback={
                              <JobLinkItem
                                to={USER_APP_WORKFLOW({ userAppId, workflowId: job.creator.id })}
                                icon={<NvWorkflowIcon />}
                                name={job.creator.displayName}
                                disabled
                              />
                            }
                          >
                            <JobLinkItem
                              to={USER_APP_WORKFLOW({ userAppId, workflowId: job.creator.id })}
                              icon={<NvWorkflowIcon />}
                              name={job.creator.displayName}
                            />
                          </UserAppPermissionBoundary>
                        </FreeAppPermissionBoundary>
                      )}
                    </NvBox>
                  )}
                </BasicTableCell>
                <BasicTableCell sx={{ verticalAlign: 'middle !important' }}>
                  <NvFlex direction="row" justifyContent="flex-end" alignItems="center">
                    <FreeAppPermissionBoundary>
                      <UserAppPermissionBoundary permission={APP_PERMISSION.APP_EDIT} appId={userAppId}>
                        <NvMenuWithItems
                          triggerButton={{
                            content: <NvMoreHorizIcon />,
                            props: { onlyIcon: true, size: 'small', color: 'secondary' },
                          }}
                          menuItems={menuItems(job)}
                        />
                      </UserAppPermissionBoundary>
                    </FreeAppPermissionBoundary>
                  </NvFlex>
                </BasicTableCell>
              </BasicTableRow>
            ))
          )}
        </>
      }
      {...(hasNextPage
        ? {
            tableFooter: (
              <BasicTableRow>
                <BasicTableCellForFooter>
                  <NvButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      fetchNextPage();
                    }}
                    loading={isFetchingNextPage}
                    disabled={isFetchingNextPage}
                  >
                    Show more
                  </NvButton>
                </BasicTableCellForFooter>
                <BasicTableCellForFooter />
                <BasicTableCellForFooter />
                <BasicTableCellForFooter />
              </BasicTableRow>
            ),
          }
        : {})}
    />
  );
};
