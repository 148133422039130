import { NvAxios, useMutation } from '@novaera/core';
import { CONFIG_ROOT_PATH } from '../constants';
import { ValidateConfigResult } from '../types';

import { ValidateConfigParams } from './types';

const validateConfig = async ({ appId, id, payload }: ValidateConfigParams) => {
  const result = await NvAxios.post<ValidateConfigResult>(`${CONFIG_ROOT_PATH(appId)}/${id}/validation`, {
    ...payload,
  });
  return result?.data;
};

export const useValidateConfig = () => {
  return useMutation(validateConfig);
};
