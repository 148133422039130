import { styled } from '@novaera/theme-provider';
import { NvBox } from '../../box';
import { NvPaper } from '../../paper';

export const CodeInputExpanderContainer = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;

  &:hover {
    .expand-function-view-box {
      opacity: 1;
    }
  }
`;

export const ExpandFunctionViewBox = styled(NvBox)`
  opacity: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  }
`;

export const CloseButtonContainer = styled(NvBox)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
`;

export const ExpandedFunctionModalPaper = styled(NvPaper)`
  box-shadow: none;
  height: calc(100% - 64px);
  max-width: initial !important;
`;
