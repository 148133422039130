import { ROUTE_DEFAULTS } from '@novaera/constants';
import { NvButton, NvField, NvFlex, NvLink, NvTypography, useFormState } from '@novaera/core';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomDivider, SocialLoginFlex, TitleFlex, WelcomeMessageFlex } from '../styled';
import { SignInFormValues } from '../types';
import { useCodeEntrance } from './controllers/use-code-entrance';
import { CodeInput, NumberLine, NumberLineContainer } from './styled';

export const CodeEntrance: React.FC<
  React.PropsWithChildren<{
    hasAccount: boolean;
    isLoading?: boolean;
    error?: string;
    onCancelClicked?: () => void;
  }>
> = ({ isLoading, error, onCancelClicked }) => {
  const {
    values: { username },
  } = useFormState<SignInFormValues>();

  const codeInputRef = useRef();
  const { onResendCodeClick } = useCodeEntrance({
    codeInputRef,
  });
  const navigate = useNavigate();

  const handleCancelClick = () => {
    onCancelClicked?.();
    navigate(ROUTE_DEFAULTS.SIGN_IN);
  };

  return (
    <>
      <TitleFlex>
        <NvTypography variant="h1">Check your email for a code</NvTypography>
      </TitleFlex>
      <WelcomeMessageFlex>
        <NvTypography variant="body1" textColor="subtle">
          We've sent you a 6 character code to <b>{username}</b>. The code will expire in a few minutes.
        </NvTypography>
      </WelcomeMessageFlex>
      <SocialLoginFlex>
        <NumberLineContainer>
          <NumberLine />
          <NumberLine />
          <NumberLine />
          <NumberLine />
          <NumberLine />
          <NumberLine />
        </NumberLineContainer>

        <NvField
          error={error}
          name="code"
          component={
            <CodeInput
              id="code-input"
              inputRef={codeInputRef}
              inputProps={{
                maxLength: 6,
                minLength: 6,
                size: 12,
              }}
              autoFocus
            />
          }
        />
      </SocialLoginFlex>
      <NvFlex sx={{ marginTop: 2 }} direction="row" columnGap={'16px'}>
        <NvButton type="submit" size="large" loading={isLoading} isFitContent>
          Enter and continue
        </NvButton>
        <NvButton color="ghost" size="large" isFitContent onClick={handleCancelClick}>
          Cancel
        </NvButton>
      </NvFlex>
      <NvFlex sx={{ paddingTop: '24px' }}>
        <CustomDivider />
      </NvFlex>
      <NvFlex sx={{ marginTop: 2 }}>
        <NvTypography textColor="subtle" variant="body2">
          Haven't received the email?&nbsp;
          <NvLink linkVariant="ghost" onClick={onResendCodeClick}>
            Resend the code.
          </NvLink>
        </NvTypography>
      </NvFlex>
    </>
  );
};
