import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppAdminsHeader = styled(NvFlex)`
  width: 100%;
  flex: 1 1 auto;
  min-width: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
`;
