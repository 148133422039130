import { WorkflowState, WorkflowWithState } from '@novaera/actioner-service';
import { useFormIdentifierContext } from '../../../providers/form-identifier-provider';
import { useWorkflowReferenceOutputProvider } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/providers/workflow-reference-output-provider';
import { WorkflowOutputRefRunImmediatelyParams } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/providers/workflow-reference-output-provider/types';
import { useExecuteWorkflow } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/controllers/use-execute-workflow';
import { useWorkflowRun } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/controllers/use-workflow-run';

export const useWorkflowRunExecution = ({
  workflow,
  workflowOutputRefParams,
}: {
  workflow?: WorkflowWithState;
  workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
}) => {
  const { getStatefulFormId } = useFormIdentifierContext();

  const { onExecutionRunFinished } = useWorkflowReferenceOutputProvider();

  const {
    isConnectionMappingModalOpened,
    onConnectionMappingModalClosed,
    connectionValidationCheckResult,
    onConnectionsCompleted,
  } = useWorkflowRun({ workflow });

  const { onExecuteWorkflow, isLoading, result, cleanResult, executionIdentifier } = useExecuteWorkflow({
    workflowId: workflow?.id ?? '',
    workflowTriggerType: workflow?.trigger?.type,
    formId: getStatefulFormId(),
    isDraft: workflow?.state === WorkflowState.DRAFT,
    onExecutionRunFinished,
    workflowOutputRefParams,
    userAppId: workflow?.appId ?? '',
  });

  return {
    startExecution: onExecuteWorkflow,
    isConnectionMappingModalOpened,
    onConnectionMappingModalClosed,
    connectionValidationCheckResult,
    onConnectionsCompleted,
    cleanResult,
    isExecuteLoading: isLoading,
    result,
    executionIdentifier,
  };
};
