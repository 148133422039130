import { MediaFile, MediaFileType } from '@novaera/actioner-service';
import {
  FieldArray,
  NvAddBoxIcon,
  NvButton,
  NvCustomEmptyIcon,
  NvFlex,
  NvPhotoIcon,
  NvTypography,
  NvVideocamIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { DragDropList } from '../../../../../components/drag-drop-list';
import { AddMediaModal } from './add-media-modal';
import { ImageAndVideoCard } from './styled';
import { ImagesAndVideosProps } from './types';

export const ImagesAndVideos: FC<React.PropsWithChildren<ImagesAndVideosProps>> = ({
  fieldName,
  title,
  titleVariant = 'h5',
  addNewButtonPosition = 'bottom',
  enableAutomaticAddModal,
}) => {
  const { fields } = useFieldArray(fieldName);
  const theme = useTheme();
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState<boolean>(() =>
    enableAutomaticAddModal ? fields.value?.length === 0 : false
  );
  const handleAddMediaModalClose = () => {
    setIsAddMediaModalOpen(false);
  };
  const handleAddMediaModalSubmit = (mediaFile: MediaFile) => {
    fields.push(mediaFile);
  };

  return (
    <>
      <FieldArray<MediaFile> name={fieldName}>
        {({ fields }) => (
          <NvFlex gap="8px" width="100%" alignItems="flex-start">
            <NvFlex direction="row" alignItems="center" width="100%">
              <NvTypography variant={titleVariant} flex="1 1 auto" minWidth={0}>
                {title}
              </NvTypography>
              {addNewButtonPosition === 'top' && (
                <NvButton
                  size="small"
                  color="secondary"
                  startIcon={<NvAddBoxIcon />}
                  onClick={() => {
                    setIsAddMediaModalOpen(true);
                  }}
                >
                  Add media
                </NvButton>
              )}
            </NvFlex>

            {fields.value?.length > 0 ? (
              <DragDropList
                droppableId="ImagesAndVideos.droppableId"
                listItems={fields}
                onDragEnd={(sourceIndex, destination) => {
                  fields.move(sourceIndex, destination);
                }}
                onItemRemoved={fields.remove}
                itemNode={(field, fieldName) => (
                  <ImageAndVideoCard>
                    <NvFlex direction="row" gap="4px" alignItems="flex-start">
                      {field.type === MediaFileType.IMAGE ? <NvPhotoIcon /> : <NvVideocamIcon />}
                      <NvFlex gap="4px">
                        <NvTypography variant="body1">{field.displayText}</NvTypography>
                        <NvTypography variant="body2" textColor="secondary">
                          {field.type === MediaFileType.IMAGE ? field.fileReference.fileName : field.fileReference.url}
                        </NvTypography>
                      </NvFlex>
                    </NvFlex>
                  </ImageAndVideoCard>
                )}
                getDraggableId={(item: MediaFile, index: number) => `ImagesAndVideos-${item.type}-${index}`}
                getItemValue={(index) => fields.value[index]}
              />
            ) : (
              <NvFlex direction="row" gap="4px">
                <NvCustomEmptyIcon
                  sx={{ width: '16px', height: '16px' }}
                  htmlColor={theme.palette.nv_neutral[300]}
                ></NvCustomEmptyIcon>
                <NvTypography variant="h6" textColor="secondary">
                  No media file has been added yet.
                </NvTypography>
              </NvFlex>
            )}

            {addNewButtonPosition === 'bottom' && (
              <NvButton
                size="small"
                color="secondary"
                startIcon={<NvAddBoxIcon />}
                onClick={() => {
                  setIsAddMediaModalOpen(true);
                }}
              >
                Add media
              </NvButton>
            )}
          </NvFlex>
        )}
      </FieldArray>
      {isAddMediaModalOpen && (
        <AddMediaModal
          isOpen={isAddMediaModalOpen}
          onClose={handleAddMediaModalClose}
          onModalSubmit={handleAddMediaModalSubmit}
        />
      )}
    </>
  );
};
