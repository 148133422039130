import { JobRecurrenceEndType, NumberValue, RecurringJobTermination, ValueTypes } from '@novaera/actioner-service';
import {
  CodeInput,
  NvDateTimePicker,
  NvField,
  NvFlex,
  NvMenuWithItems,
  NvSelect,
  NvSettingsIcon,
  NvSideGroupedInputLayout,
  NvStopIcon,
  isRequired,
  useField,
  useForm,
} from '@novaera/core';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import { ParameterMappingSettingsMenu } from '../../../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { RECURRENCE_END_OPTIONS } from '../../../../../../../../jobs/constants';
import { EndsProps } from './types';

export const Ends: React.FC<EndsProps> = ({ context }) => {
  const { change } = useForm();
  const {
    input: { value: termination },
  } = useField<RecurringJobTermination>('operation.termination');
  return (
    <PropertyPanelSection title={'Ends'} icon={<NvStopIcon />}>
      <NvFlex width="100%" gap="8px">
        <NvField
          name="operation.termination.type"
          formControlStyle={{ width: '250px' }}
          component={({ value, onChange }) => (
            <NvSelect
              value={value}
              onChange={(event) => {
                onChange(event);
                change('operation.termination', { type: event.target.value });
              }}
              options={RECURRENCE_END_OPTIONS}
            />
          )}
        />

        {termination.type === JobRecurrenceEndType.NUMBER_OF_TIMES && (
          <NvField
            formControlStyle={{ width: '100%' }}
            parse={(v) => ({ type: ValueTypes.NUMBER, codeTemplate: v, displayValue: v })}
            format={(v) => v?.codeTemplate}
            name={'operation.termination.count'}
            labelText="Occurrence count"
            direction="label-on-side"
            labelVariant="body2"
            labelWidth="105px"
            component={<CodeInput context={context} placeholder="Enter count" />}
          />
        )}
        {termination.type === JobRecurrenceEndType.DATE_TIME && (
          <NvField<NumberValue>
            formControlStyle={{ width: '100%' }}
            name={'operation.termination.date'}
            validators={[isRequired()]}
            showErrorMessageOnlyWhenBlur
            component={({ value, onChange }) => (
              <NvSideGroupedInputLayout
                variant={'default'}
                rightAction={
                  <NvMenuWithItems
                    triggerButton={{
                      content: <NvSettingsIcon />,
                      props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
                    }}
                    menuItems={
                      <ParameterMappingSettingsMenu
                        hiddenConfig={{
                          show: false,
                        }}
                        dynamicStaticConfig={{
                          show: true,
                          isScripted: termination.dynamic,
                          onItemClicked: (type) => {
                            const newScriptedValue = type === 'static' ? false : true;

                            if (termination.dynamic !== newScriptedValue) {
                              change('operation.termination', {
                                ...termination,
                                dynamic: newScriptedValue,
                                date: {
                                  type: ValueTypes.NUMBER,
                                  displayValue: '',
                                  ...(!newScriptedValue ? { value: Date.now() } : { codeTemplate: '' }),
                                },
                              });
                            }
                          },
                        }}
                      />
                    }
                  />
                }
                formItem={
                  termination.dynamic ? (
                    <CodeInput
                      value={value?.codeTemplate}
                      onChange={(value) => {
                        onChange({
                          type: ValueTypes.NUMBER,
                          codeTemplate: value,
                          displayValue: value,
                        });
                      }}
                      context={context}
                      placeholder={'{{...}}'}
                    />
                  ) : (
                    <NvDateTimePicker
                      value={value?.value ? utcToZonedTime(value?.value, 'UTC') : undefined}
                      onChange={(v) => {
                        const date = v as string | undefined;
                        const dateMillis = date ? zonedTimeToUtc(date, 'UTC').getTime() : undefined;
                        onChange({
                          type: ValueTypes.NUMBER,
                          value: dateMillis,
                          displayValue: dateMillis?.toString() ?? '',
                        });
                      }}
                    />
                  )
                }
              />
            )}
          />
        )}
      </NvFlex>
    </PropertyPanelSection>
  );
};
