import { useSearchUserApps } from '@novaera/actioner-service';
import {
  Initial,
  NvArrowBackIcon,
  NvBox,
  NvChevronRightIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvPopoverWithAnchor,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../common/routes';
import { AppDropdownListItem, AppDropdownListItemLoading, DropdownButton } from '../../../../components';
import { DropdownSimpleSearchInput } from '../../../../components/simple-search-input/styled';
import { AppSelectInitiator } from './styled';
import { UserAppSelectProps } from './types';

export const UserAppSelect: React.FC<React.PropsWithChildren<UserAppSelectProps>> = ({ userApp }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(searchKeyword, 500);
  const { data, isLoading } = useSearchUserApps({ payload: { keyword: debouncedKeyword } });
  const userApps = useMemo(() => data?.apps ?? [], [data?.apps]);
  return (
    <NvPopoverWithAnchor
      id="select-user-apps"
      fullInitiatorWidth
      initiator={
        <AppSelectInitiator>
          <NvBox flex="0 0 auto">
            <NvImage
              FallBack={
                <Initial size={'medium'} value={userApp?.name || 'User app'} color={theme.palette.nv_neutral[500]} />
              }
              src={userApp.logoUrl}
              imageShape="square"
              size="small"
              fallbackImageHasBlurEffect
              className="package-image"
            />
          </NvBox>

          <NvTypography variant="h3" flex="1 1 auto" minWidth={0} noWrap>
            {userApp?.name}
          </NvTypography>
          <NvChevronRightIcon sx={{ width: '24px', height: '24px', flex: '0 0 auto', transform: 'rotate(90deg)' }} />
        </AppSelectInitiator>
      }
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {({ close }) => (
        <NvFlex width="324px" padding="0">
          <DropdownSimpleSearchInput onKeywordChanged={(keyword) => setSearchKeyword(keyword ?? '')} />
          <NvDivider />
          <NvFlex padding="8px 12px 12px 12px" gap="2px" maxHeight="300px" overflow="auto">
            {isLoading ? (
              <>
                <AppDropdownListItemLoading />
                <AppDropdownListItemLoading />
                <AppDropdownListItemLoading />
              </>
            ) : (
              userApps.map((i) => (
                <AppDropdownListItem
                  key={`user-app-list-item-${i.id}`}
                  name={i.name}
                  logo={i.logoUrl}
                  logoFallBack={
                    <Initial size={'medium'} value={i?.name || 'User app'} color={theme.palette.nv_neutral[500]} />
                  }
                  onItemClick={() => {
                    navigate(`${ROUTES.UserApps}/${i.id}`);
                    close();
                  }}
                  isSelected={i.id === userApp.id}
                />
              ))
            )}
          </NvFlex>
          <NvDivider />
          <NvBox padding="12px">
            <DropdownButton
              onClick={() => {
                navigate(`${ROUTES.UserApps}`);
                close();
              }}
              startIcon={<NvArrowBackIcon sx={{ width: '16px', height: '16px' }} />}
            >
              Go back to My apps
            </DropdownButton>
          </NvBox>
        </NvFlex>
      )}
    </NvPopoverWithAnchor>
  );
};
