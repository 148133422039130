import { NvFlex } from '@novaera/core';
import { useSocialLogin } from '../../controllers/use-social-login';
import { LoadingPage } from '../loading-page';
import { NvProductLogoWithName } from '../product-icon/styled';
import { SignInWrapper } from '../sign-in/styled';
import { WorkspacePanel } from '../sign-in/workspace-panel';

export const SocialLogin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { shouldShowWorkSpaceList, username } = useSocialLogin();

  if (shouldShowWorkSpaceList) {
    return (
      <SignInWrapper>
        <NvFlex marginBottom="40px">
          <NvProductLogoWithName />
        </NvFlex>
        <WorkspacePanel username={username} />
      </SignInWrapper>
    );
  }

  return <LoadingPage />;
};
