import { styled } from '@novaera/theme-provider';

export const ErrorCode = styled('div')`
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: 119px;
  letter-spacing: 15px;
  text-align: center;
`;
