export enum ServiceErrorCode {
  ACCESS_TOKEN_REFRESH_PROGRESS,
}
export class ServiceError extends Error {
  readonly code: ServiceErrorCode;

  constructor({ message, code }: { message: string; code: ServiceErrorCode }) {
    super(message);
    this.code = code;
  }
}

export function isServiceError(err: unknown): err is ServiceError {
  return err instanceof ServiceError;
}
