import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvKeyValueTableIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 6.44772 3.44772 6 4 6H8C8.55228 6 9 6.44772 9 7C9 7.55228 8.55228 8 8 8H4C3.44772 8 3 7.55228 3 7ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H8C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11H4ZM12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H12ZM3 17C3 16.4477 3.44772 16 4 16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H4C3.44772 18 3 17.5523 3 17ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18H16C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16H12ZM12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8H18C18.5523 8 19 7.55228 19 7C19 6.44772 18.5523 6 18 6H12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
