import { tooltipClasses } from '@mui/material/Tooltip';
import { NvAvatarGroup, NvTooltip } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const MoreContributorsTooltip = styled(NvTooltip)`
  .${tooltipClasses.tooltip} {
    padding: 6px;
    min-width: 230px;
    border-radius: 12px;
    background: ${({ theme }) => theme.palette.nv_neutral[0]};
    ${({ theme }) => theme.elevations.e300};
    color: inherit;
  }
`;

export const ContributorAvatarGroup = styled(NvAvatarGroup)`
  .MuiAvatarGroup-avatar {
    width: 24px;
    height: 24px;
    font-size: 12px;
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[0]};
  }
`;
