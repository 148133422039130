import { DataModelSummary } from '../data-model';
import { UserApp, Workflow } from '../types';

export type CloneType = 'workflows' | 'data-models' | 'apps';

export interface BaseClone {
  type: CloneType;
  appId: string;
  payload: {
    newName: string;
  };
  onSuccess: (params: CloneResponse) => void;
}

export interface AppCloneParams extends BaseClone {
  type: 'apps';
}

export interface WorkflowCloneParams extends BaseClone {
  type: 'workflows';
  workflowId: string;
}

export interface DataModelCloneParams extends BaseClone {
  type: 'data-models';
  modelId: string;
}

export type CloneParams = AppCloneParams | WorkflowCloneParams | DataModelCloneParams;

// using omit for union types makes other params not work, so added a new type
export type CloneServiceParamsForComponent =
  | Omit<AppCloneParams, 'payload' | 'onSuccess'>
  | Omit<WorkflowCloneParams, 'payload' | 'onSuccess'>
  | Omit<DataModelCloneParams, 'payload' | 'onSuccess'>;

export type AppCloneResponse = Record<'clonedApp', UserApp>;

export type DataTableCloneResponse = Record<'clonedTable', DataModelSummary>;

export type WorkflowCloneResponse = Record<
  'clonedWorkflow',
  Workflow & {
    workspaceId: string;
    createdAt: string;
    updatedAt: string;
  }
>;

export type CloneResponse = AppCloneResponse | DataTableCloneResponse | WorkflowCloneResponse;

export const isAppCloneResponse = (params: CloneResponse, type: CloneType): params is AppCloneResponse => {
  return params && type === 'apps';
};
export const isWorkflowCloneResponse = (params: CloneResponse, type: CloneType): params is WorkflowCloneResponse => {
  return params && type === 'workflows';
};
export const isDataTableCloneResponse = (params: CloneResponse, type: CloneType): params is DataTableCloneResponse => {
  return params && type === 'data-models';
};
