import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvHelpDeskIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_1713_920"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <rect width="26" height="26" fill="#FEE386" />
      </mask>
      <g mask="url(#mask0_1713_920)">
        <path
          d="M3 16.7424V4.03413C3 3.789 3.11085 3.55536 3.33254 3.33322C3.55424 3.11107 3.78385 3 4.02138 3H16.943C17.2122 3 17.4537 3.11107 17.6675 3.33322C17.8812 3.55536 17.9881 3.789 17.9881 4.03413V12.7438C17.9881 12.9889 17.8773 13.2225 17.6556 13.4447C17.4339 13.6668 17.1964 13.7779 16.943 13.7779H7.10926L3.73634 17.0411C3.59382 17.179 3.43547 17.2096 3.26128 17.133C3.08709 17.0564 3 16.9262 3 16.7424ZM8.15439 18.6268C7.90103 18.6268 7.6635 18.5119 7.44181 18.2821C7.22011 18.0523 7.10926 17.8225 7.10926 17.5927V15.1567H19.4133V6.99862H21.9786C22.232 6.99862 22.4656 7.11352 22.6793 7.34333C22.8931 7.57313 23 7.8106 23 8.05572V21.5683C23 21.7521 22.9129 21.8824 22.7387 21.959C22.5645 22.0356 22.4062 22.0049 22.2637 21.867L18.9145 18.6268H8.15439Z"
          fill="#FEE386"
        />
      </g>
    </SvgIcon>
  );
}
