import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomGiftIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <g clip-path="url(#clip0_2127_105)">
        <path
          d="M9.44141 4.6875L10.8008 7H10.75H7.9375C7.07422 7 6.375 6.30078 6.375 5.4375C6.375 4.57422 7.07422 3.875 7.9375 3.875H8.02344C8.60547 3.875 9.14844 4.18359 9.44141 4.6875ZM4.5 5.4375C4.5 6 4.63672 6.53125 4.875 7H3.25C2.55859 7 2 7.55859 2 8.25V10.75C2 11.4414 2.55859 12 3.25 12H20.75C21.4414 12 22 11.4414 22 10.75V8.25C22 7.55859 21.4414 7 20.75 7H19.125C19.3633 6.53125 19.5 6 19.5 5.4375C19.5 3.53906 17.9609 2 16.0625 2H15.9766C14.7305 2 13.5742 2.66016 12.9414 3.73438L12 5.33984L11.0586 3.73828C10.4258 2.66016 9.26953 2 8.02344 2H7.9375C6.03906 2 4.5 3.53906 4.5 5.4375ZM17.625 5.4375C17.625 6.30078 16.9258 7 16.0625 7H13.25H13.1992L14.5586 4.6875C14.8555 4.18359 15.3945 3.875 15.9766 3.875H16.0625C16.9258 3.875 17.625 4.57422 17.625 5.4375ZM3.25 13.25V20.125C3.25 21.1602 4.08984 22 5.125 22H10.75V13.25H3.25ZM13.25 22H18.875C19.9102 22 20.75 21.1602 20.75 20.125V13.25H13.25V22Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2127_105">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
