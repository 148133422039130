import { KeyValueTableOutputComponent, KeyValueTableRow } from '@novaera/actioner-service';
import { FC, useCallback } from 'react';
import { KeyValueTableRows } from './key-value-table-rows';

export interface RowsProps {
  value?: KeyValueTableOutputComponent['rows'];
  onChange?: (value?: KeyValueTableOutputComponent['rows']) => void;
}

export const KeyValueTableRowsAdapter: FC<React.PropsWithChildren<RowsProps>> = ({
  value: keyValueTableRows,
  onChange,
}) => {
  const handleKeyValueTableRowChange = useCallback<(newKeyValueTableRows?: KeyValueTableRow[]) => void>(
    (newRows) => {
      if (newRows) {
        onChange?.(newRows);
      } else {
        onChange?.();
      }
    },
    [onChange]
  );

  return <KeyValueTableRows keyValueTableRows={keyValueTableRows} onChange={handleKeyValueTableRowChange} />;
};
