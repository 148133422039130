import { FC } from 'react';
import { NvAddIcon } from '../icons';
import { BottomLine, Icon, TopLine, Wrapper } from './style';
import { HorizontalLineWithIconProp } from './type';

export const HorizontalLineWithIcon: FC<React.PropsWithChildren<HorizontalLineWithIconProp>> = ({
  icon = <NvAddIcon />,
  topLineBackground = 'linear-gradient(135deg, #0a2d6240 0%, #0a2d62 100%)',
  bottomLineBackground = 'linear-gradient(135deg, #0a2d6240 0%, #0a2d62 100%)',
}) => {
  return (
    <Wrapper>
      <TopLine customBackground={topLineBackground} />
      <Icon>{icon}</Icon>
      <BottomLine customBackground={bottomLineBackground} />
    </Wrapper>
  );
};
