import { NodeSummaries, NodeSummary, Vertices } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { getComponentChildren } from '../../../../common/use-node-deletion/delete-node-from-workflow/get-component-children';

export const deleteBranch = ({
  vertices,
  branchJunctionRootAlias,
  deletedBranchIndex,
  nodeSummaries,
}: {
  vertices: Vertices;
  branchJunctionRootAlias: string;
  deletedBranchIndex: number;
  nodeSummaries: NodeSummaries;
}): { vertices: Vertices; nodeSummary?: NodeSummary } => {
  const branchJunctionRoot = vertices.find((v) => v.alias === branchJunctionRootAlias);
  assert(
    !!branchJunctionRoot && branchJunctionRoot.type === 'branchJunction',
    new Error('Branch junction root not found'),
    'ERROR'
  );

  const branchJunctionRootSummary = nodeSummaries[branchJunctionRootAlias];
  assert(
    !!branchJunctionRootSummary && branchJunctionRootSummary.type === 'branchJunction',
    new Error('Branch junction root summary not found'),
    'ERROR'
  );

  const newBranches = branchJunctionRootSummary?.branches?.filter((_, index) => index !== deletedBranchIndex);

  const deletedBranchFirstAlias = branchJunctionRoot.firstInnerAliases[deletedBranchIndex];
  const newFirstInnerAliases = branchJunctionRoot.firstInnerAliases.filter((_, index) => index !== deletedBranchIndex);

  const deletedAliases = getComponentChildren({
    nodes: vertices,
    currentNodeId: deletedBranchFirstAlias,
    nodeToStop: branchJunctionRoot.nextAlias,
  });
  const newVertices = vertices
    .filter((v) => ![...deletedAliases, deletedBranchFirstAlias].includes(v.alias))
    ?.map((v) =>
      v.alias === branchJunctionRootAlias ? { ...branchJunctionRoot, firstInnerAliases: newFirstInnerAliases } : v
    );

  return { vertices: newVertices ?? [], nodeSummary: { ...branchJunctionRootSummary, branches: newBranches } };
};
