export enum ExternalAppConnectionType {
  NONE = 'none',
  FALLBACK_TO_DEFAULT_USER = 'fallback',
  OVERRIDE_BY_DEFAULT_USER = 'override',
}

export function isExternalAppConnectionType(value: string): value is ExternalAppConnectionType {
  return (
    value === ExternalAppConnectionType.FALLBACK_TO_DEFAULT_USER ||
    value === ExternalAppConnectionType.NONE ||
    value === ExternalAppConnectionType.OVERRIDE_BY_DEFAULT_USER
  );
}
