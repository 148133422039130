import { NvAxios, useQuery } from '@novaera/core';
import { UseQueryOptions } from '@tanstack/react-query';
import { GetDataModelParams, GetDataModelResponse } from '../common/types';
import { DATA_MODEL_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DATA_MODEL } from '../keys';
import { useDataModel } from './use-data-model';

const getDataModel = async ({ appId, modelId }: GetDataModelParams) => {
  const result = await NvAxios.get<GetDataModelResponse>(`${DATA_MODEL_ROOT_PATH(appId)}/${modelId}`);
  return result?.data;
};

export const useGetDataModel = ({
  appId,
  modelId,
  retry,
  onError,
}: GetDataModelParams & { retry: UseQueryOptions['retry']; onError?: (err: unknown) => void }) => {
  const { data, isInitialLoading, isError, isSuccess, isFetched } = useQuery<GetDataModelResponse>(
    QUERY_KEYS_DATA_MODEL.detail(appId, modelId),
    () => getDataModel({ appId, modelId }),
    { enabled: !!appId && !!modelId, retry, onError }
  );

  const { draftDataModel, savedDataModel, dataModel } = useDataModel(data);

  return {
    dataModel,
    savedDataModel,
    draftDataModel,
    isLoading: isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  };
};
