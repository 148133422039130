import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_EVENT_RULES } from '../keys';
import { GetIntegrationEventRulesResponse } from '../use-get-integration-event-rules/types';
import { DeleteIntegrationEventRuleParams } from './types';

const deleteIntegrationEventRule = async (params: DeleteIntegrationEventRuleParams) => {
  const { integrationId, id } = params;
  const result = await NvAxios.delete(`${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}`);
  return result?.data;
};

export const useDeleteIntegrationEventRule = () => {
  const cache = useQueryClient();

  return useMutation(deleteIntegrationEventRule, {
    onSuccess: (_, { integrationId, id }) => {
      cache.setQueryData<GetIntegrationEventRulesResponse>(
        QUERY_KEYS_INTEGRATION_EVENT_RULES.list(integrationId),
        (old) => {
          if (old) {
            return { ...old, eventRules: old.eventRules.filter((eventRule) => eventRule.id !== id) };
          }
          return old;
        }
      );
    },
  });
};
