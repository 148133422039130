import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCICDIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_1713_923)">
        <path
          d="M15.2982 20.8902L15.2982 16.6157C15.2982 16.1254 15.8803 15.8803 16.2251 16.225L17.5963 17.5962C20.1318 15.0606 20.1318 10.9394 17.5963 8.40383C16.9911 7.79866 16.2863 7.32372 15.5356 7.01731C15.145 6.85644 14.9151 6.45811 14.9151 6.04445C14.9151 5.26309 15.7118 4.74219 16.4319 5.04861C17.4124 5.46227 18.3317 6.07509 19.1283 6.87176C22.5142 10.2576 22.5142 15.7424 19.1283 19.1283L20.4995 20.4995C20.8442 20.8442 20.5991 21.4264 20.1165 21.4187L15.842 21.4187C15.5433 21.4264 15.2982 21.1812 15.2982 20.8902ZM8.40387 8.40383C5.8683 10.9394 5.8683 15.0606 8.40387 17.5962C9.00904 18.2014 9.71379 18.6763 10.4645 18.9827C10.8552 19.1436 11.085 19.5419 11.085 19.9556C11.085 20.7369 10.2883 21.2578 9.56824 20.9514C8.58772 20.5378 7.66848 19.925 6.87181 19.1283C3.48594 15.7424 3.48594 10.2576 6.87181 6.87176L5.50061 5.50056C5.1559 5.15585 5.40103 4.57366 5.88363 4.58132L10.1581 4.58133C10.4645 4.58133 10.702 4.8188 10.702 5.12521L10.702 9.39967C10.702 9.87461 10.1198 10.1197 9.77507 9.77502L8.40387 8.40383Z"
          fill="#ABD7FF"
        />
        <circle
          cx="3"
          cy="3"
          r="3"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 8.75745 13)"
          fill="#ABD7FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1713_923">
          <rect width="26" height="26" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 26 26)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
