import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import {
  USER_PERMISSION,
  useDeleteInputParameter,
  useGetIntegration,
  useGetIntegrationAction,
  useUpdateInputParameters,
} from '@novaera/actioner-service';
import { NvAddButtonWithLabel, NvBox, NvNestedDropdown } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { UserPermissionBoundary } from '../../../../user-permission-boundary';
import { useUserPermissions } from '../../../../user-permission-boundary/use-user-permission';
import { ADD_NEW_INPUT_MENU_ITEMS } from '../../../../utils';
import { InputMenuItemValue } from '../../../../utils/helpers/add-new-input-menu-items/types';
import { InputParameter } from '../input-parameter';
import { useAddNewInputItem } from './controllers/use-add-new-input-item';
import { NvBoxWrapper } from './styled';

export const InputPanel: FC<React.PropsWithChildren<unknown>> = () => {
  const { integrationId, actionId } = useParams();
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ integrationId, actionId, version: integration?.latestVersion.number });
  const { mutate } = useUpdateInputParameters();
  const { mutate: deleteInputParameter } = useDeleteInputParameter();

  const inputParameters = useMemo(() => data?.inputParameters ?? [], [data?.inputParameters]);

  const { onNewItemAddClick } = useAddNewInputItem();

  const makeOnDragEndFunction = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const source = inputParameters[result.source.index];
      inputParameters.splice(result.source.index, 1);
      inputParameters.splice(result.destination.index, 0, source);

      const versionNumber = integration?.latestVersion?.number;
      assert(!isUndefined(versionNumber), new Error('Integration is expected to be defined'), 'ERROR');
      mutate({ integrationId, actionId, version: versionNumber, payload: { inputParameters } });
    },
    [actionId, inputParameters, integration?.latestVersion.number, integrationId, mutate]
  );

  const handleRemoveInputParameters = (id: string) => {
    assert(!!integration, new Error('Integration is expected to be defined'), 'ERROR');
    deleteInputParameter({ integrationId, actionId, id, version: integration.latestVersion.number });
  };

  return (
    <DragDropContext onDragEnd={makeOnDragEndFunction}>
      <Droppable droppableId="action-configure" isDropDisabled={!hasIntegrationUpdateRight}>
        {(provided) => (
          <NvBox
            display="flex"
            flexDirection="column"
            align-items="center"
            justifyContent="space-around"
            ref={provided.innerRef}
            gap="16px"
          >
            {inputParameters?.map((inputParameter, index) => {
              const {
                id,
                name,
                uiComponent: { type },
              } = inputParameter;

              return (
                <Draggable
                  key={`draggable_${id}`}
                  draggableId={name}
                  index={index}
                  isDragDisabled={!hasIntegrationUpdateRight}
                >
                  {(provided) => (
                    <NvBoxWrapper ref={provided.innerRef} {...provided.draggableProps} position="relative">
                      <NvBox style={{ width: '100%' }} {...provided.dragHandleProps}>
                        <InputParameter
                          index={index}
                          key={`field_${id}_${type}`}
                          {...inputParameter}
                          onRemove={() => {
                            handleRemoveInputParameters(id);
                          }}
                        />
                      </NvBox>
                    </NvBoxWrapper>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
              <NvNestedDropdown<InputMenuItemValue>
                menuItems={ADD_NEW_INPUT_MENU_ITEMS}
                onClickItem={(props) => {
                  if (props.value) {
                    onNewItemAddClick(props.value);
                  }
                  assert(!!props.value, new Error('The menu item is expected to have value'));
                }}
                trigger={<NvAddButtonWithLabel label="Add new field" variant="small" />}
              />
            </UserPermissionBoundary>
          </NvBox>
        )}
      </Droppable>
    </DragDropContext>
  );
};
