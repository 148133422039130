import { EventRule, IntegrationEventRuleState } from '@novaera/actioner-service';
import { NvFlex, NvSkeleton } from '@novaera/core';
import { noop } from '@novaera/utils';
import { DetailLayout } from '../../../components/detail-layout';
import { useEventDetailController } from './controllers/use-event-detail-controller';
import { EventDetailBody } from './event-detail-body';
import { UpdateEventNameAndDescriptionModalBody } from './update-event-name-and-description-modal-body';

export const EventDetail = () => {
  const {
    eventId,
    integrationEventRule,
    persistedIntegrationEventRule,
    isLoading,
    initialValues,
    isUpdatingIntegrationEventRuleMetadata,
    handleUpdateEventRuleMetadata,
    debouncedHandleOnChangeEventHandler,
    handleEventRulePersist,
    handleEventRuleDiscard,
    handleActionButtonClicked,
    hasIntegrationUpdateRight,
  } = useEventDetailController();

  return (
    <DetailLayout<Omit<EventRule, 'state'>, Pick<EventRule, 'name' | 'description'>>
      key={eventId}
      useArrayMutators
      headerProps={{
        name: persistedIntegrationEventRule?.name ?? '',
        icon: 'eventRule',
        isDraft: integrationEventRule?.state === IntegrationEventRuleState.DRAFT,
        enableEdit: hasIntegrationUpdateRight,
        isLoading: isLoading,
        onDiscard: handleEventRuleDiscard,
      }}
      editModalProps={{
        title: 'Update modal',
        isLoading: isUpdatingIntegrationEventRuleMetadata,
        modalBody: <UpdateEventNameAndDescriptionModalBody />,
        onSubmit: handleUpdateEventRuleMetadata,
        primaryButtonText: 'Save',
        formProps: {
          initialValues: {
            name: persistedIntegrationEventRule?.name ?? '',
            description: persistedIntegrationEventRule?.description ?? '',
          },
        },
      }}
      onFormSubmit={handleEventRulePersist}
      formProps={{
        initialValues,
        keepDirtyOnReinitialize: true,
        onChange: hasIntegrationUpdateRight ? debouncedHandleOnChangeEventHandler : noop,
      }}
      onActionButtonClicked={handleActionButtonClicked}
      isLoading={isLoading}
      LoadingSkeleton={
        <NvFlex gap="16px" paddingTop="16px">
          <NvSkeleton variant="rectangular" height="80px" />
          <NvSkeleton variant="rectangular" height="80px" />
          <NvSkeleton variant="rectangular" height="80px" />
        </NvFlex>
      }
    >
      <EventDetailBody />
    </DetailLayout>
  );
};
