import { isAxiosError, NvAxios, useAxiosErrorHandler, useMutation, useToast } from '@novaera/core';
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { Workflow } from '../../types';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS, QUERY_KEYS_WORKFLOWS_SEARCH } from '../keys';
import { GetWorkflowResponse } from '../use-get-workflow/types';
import { SearchWorkflowResponse } from '../use-search-workflows/types';
import { UpdateWorkflowEnableStateParams } from './types';

const updateWorkflowEnableState = async ({ appId, id, enabled }: UpdateWorkflowEnableStateParams) => {
  const result = await NvAxios.patch<Workflow>(`${USER_APP_ROOT_PATH}/${appId}/workflows/${id}/enable`, { enabled });
  return result?.data;
};

export const useUpdateWorkflowEnableState = () => {
  const cache = useQueryClient();
  const { addToast } = useToast();
  const { axiosErrorHandler } = useAxiosErrorHandler();

  return useMutation(updateWorkflowEnableState, {
    onMutate: ({ appId, id, enabled }) => {
      const queryCached = cache.getQueryCache().findAll({
        queryKey: QUERY_KEYS_WORKFLOWS_SEARCH.all,
      });
      let cachedSearchedWorkflows: { key: QueryKey; value?: InfiniteData<SearchWorkflowResponse> }[] = [];

      queryCached.forEach((query) => {
        cachedSearchedWorkflows = [
          ...cachedSearchedWorkflows,
          { key: query.queryKey, value: cache.getQueryData<InfiniteData<SearchWorkflowResponse>>(query.queryKey) },
        ];
        cache.setQueryData<InfiniteData<SearchWorkflowResponse>>(query.queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old.pages.map((p) => {
                return { ...p, workflows: p.workflows.map((w) => (w.id === id ? { ...w, disabled: !enabled } : w)) };
              }),
            };
          }
          return old;
        });
      });

      const cachedWorkflowDetail = cache.getQueryData<GetWorkflowResponse>(
        QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId: id })
      );

      cache.setQueryData<GetWorkflowResponse>(QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId: id }), (old) => {
        if (old) {
          return {
            ...old,
            ...(old.draft ? { draft: { ...old.draft, disabled: !enabled } } : {}),
            saved: { ...old.saved, disabled: !enabled },
          };
        }
        return old;
      });

      return { cachedSearchedWorkflows, cachedWorkflowDetail };
    },
    onError: (error, { appId, id }, context) => {
      if (context) {
        const { cachedSearchedWorkflows, cachedWorkflowDetail } = context;
        cachedSearchedWorkflows.forEach(({ key, value }) => {
          cache.setQueryData<InfiniteData<SearchWorkflowResponse>>(key, value);
        });
        cache.setQueryData<GetWorkflowResponse>(
          QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId: id }),
          cachedWorkflowDetail
        );
      }

      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else if (error instanceof Error) {
        addToast(`Enable state of workflow can not be updated, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`Enable state of workflow can not be updated, reason: ${JSON.stringify(error)}`, { variant: 'error' });
      }
    },
  });
};
