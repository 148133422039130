import { WorkflowWithState } from '@novaera/actioner-service';
import {
  NvBox,
  NvCollapseCard,
  NvConditionalWrap,
  NvDivider,
  NvFlex,
  NvTabs,
  NvTypography,
  TypeOfTab,
  iconPosition,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { userAppGraph } from '../../../../../graph-utils/user-app-graph';
import { OutputTabItemWrapper } from './output-item-wrapper';
import { TAB_CONTENT } from './tab-content';
import { TAB_ICON } from './tab-icon';
import { TabSelectOutput } from './tab-select-output';

export const WorkflowOutputs = ({
  executionIdentifier,
  executedNodeAliases,
  workflow,
  isWrappedWithCollapse = true,
}: {
  executionIdentifier: string;
  executedNodeAliases: string[];
  workflow?: WorkflowWithState;
  isWrappedWithCollapse?: boolean;
}) => {
  const tabs: TypeOfTab[] = useMemo(() => {
    if (workflow?.nodeSummaries) {
      const reducedTabs = Object.values(workflow?.nodeSummaries).reduce<TypeOfTab[]>((previousValue, currentValue) => {
        if (
          currentValue.type === 'output' &&
          currentValue.outputComponent.type !== 'slack' &&
          executedNodeAliases.includes(currentValue.alias)
        ) {
          let icon;
          let Content;
          if (currentValue.outputComponent.type === 'chart') {
            icon = TAB_ICON[currentValue.outputComponent.type][currentValue.outputComponent.chart.type];
            Content = TAB_CONTENT[currentValue.outputComponent.type][currentValue.outputComponent.chart.type];
          } else {
            icon = TAB_ICON[currentValue.outputComponent.type];
            Content = TAB_CONTENT[currentValue.outputComponent.type];
          }

          const tab: TypeOfTab = {
            id: currentValue.alias,
            label: currentValue.name,
            content: (
              <Content
                userAppId={workflow.appId}
                workflowId={workflow.id}
                alias={currentValue.alias}
                executionIdentifier={executionIdentifier}
              />
            ),
            TabItemWrapper: <OutputTabItemWrapper hasError={false} />,
            icon,
            iconPosition: iconPosition.START,
          };

          return [...previousValue, tab];
        } else {
          return previousValue;
        }
      }, []);

      return reducedTabs;
    }
    return [];
  }, [workflow?.nodeSummaries, workflow?.appId, workflow?.id, executedNodeAliases, executionIdentifier]);

  const sortedTabs = sortBy(tabs, (t) => {
    assert(!!t.id, new Error('[WorkflowOutputs], id of output node cannot be undefined!'), 'ERROR');
    try {
      const currentNode = userAppGraph.node(t.id);
      return currentNode.position.y;
    } catch (error) {
      return -1;
    }
  });

  return sortedTabs.length > 0 ? (
    <NvConditionalWrap
      condition={isWrappedWithCollapse}
      wrap={(children) => (
        <NvCollapseCard
          title={
            <NvFlex flexDirection={'row'} gap={'8px'} alignItems={'center'}>
              <NvTypography variant="h5">Output views</NvTypography>
              <NvDivider orientation="vertical" sx={{ height: '12px' }} />
              <NvTypography variant="h6" textColor="subtle">
                {sortedTabs.length} output views
              </NvTypography>
            </NvFlex>
          }
          iconSx={{
            width: '16px',
            height: '16px',
          }}
        >
          {children}
        </NvCollapseCard>
      )}
    >
      {sortedTabs ? (
        sortedTabs.length <= 4 ? (
          <NvTabs variant="small" tabs={sortedTabs} BodyWrapperComponent={<NvBox padding="8px 8px" />}></NvTabs>
        ) : (
          <TabSelectOutput tabs={sortedTabs} />
        )
      ) : (
        <div>no output</div>
      )}
    </NvConditionalWrap>
  ) : null;
};
