import { CatalogEntityParameters, isCatalogEntityParameters } from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, useForm, useFormState } from '@novaera/core';
import { assert, noop } from '@novaera/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { DataModelLayout } from '../../components/data-model-layout';
import { DataModelDefaultPage } from '../../components/data-model-layout/default-page';
import { useCatalogPermission } from '../controllers/use-catalog-permission';
import { useSelectedCatalogEntity } from '../controllers/use-selected-catalog-entity';
import { CatalogFormType } from '../types';
import { CatalogBodyHeader } from './catalog-body-header';
import { CatalogEntitySearch } from './catalog-entity-search';
import { CatalogParameters } from './catalog-parameters';
import { CatalogRelationships } from './catalog-relationships';
import { useCatalogPageMode } from './controllers/use-catalog-page-mode';
import { CatalogRightPanelEmptyState } from './right-panel-empty-state';
import { getPreviousItem } from './utils';

export const CatalogBody = () => {
  const { mode, switchMode } = useCatalogPageMode();

  const { hasCatalogEditPermission } = useCatalogPermission();

  const { selectedDetailItem, selectedEntityType, setSelectedDetailItem } = useSelectedCatalogEntity();

  const fieldsFieldName = useMemo(() => {
    if (selectedDetailItem?.selectedItemDetail === undefined) return '';

    return isCatalogEntityParameters(selectedDetailItem?.selectedItemDetail)
      ? 'selectedEntityType.parameters'
      : 'relationshipDefinitions';
  }, [selectedDetailItem?.selectedItemDetail]);

  const { mutators, registerField } = useForm<CatalogFormType>();

  const { values } = useFormState<CatalogFormType>();

  const fieldsMenus = useCallback(
    (id: string, index?: number) => {
      if (!hasCatalogEditPermission) return [];

      if (
        selectedDetailItem &&
        selectedDetailItem.selectedItemDetail &&
        isCatalogEntityParameters(selectedDetailItem.selectedItemDetail)
      ) {
        return [
          {
            name: 'Delete',
            onClick: () => {
              assert(!!values.selectedEntityType, new Error('To delete a parameter, the entity type must be defined'));
              const parameterIndex = values.selectedEntityType?.parameters?.findIndex((item) => item.id === id);
              const newParameters = values.selectedEntityType?.parameters?.filter((item) => item.id !== id);

              mutators.remove('selectedEntityType.parameters', parameterIndex);

              setSelectedDetailItem(
                getPreviousItem(
                  {
                    parameters: newParameters,
                    relationshipDefinitions: values.relationshipDefinitions,
                    removedItem: 'parameters',
                  },
                  parameterIndex ?? 0
                )
              );
            },
            icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
          },
        ];
      } else {
        if (id) {
          return [
            {
              name: 'Delete',
              onClick: () => {
                const newRelationshipDefinitions = [...values.relationshipDefinitions.filter((item) => item.id !== id)];
                const relationshipIndex = values.relationshipDefinitions.findIndex((item) => item.id === id);

                mutators.remove('relationshipDefinitions', relationshipIndex);
                setSelectedDetailItem(
                  getPreviousItem(
                    {
                      parameters: values.selectedEntityType?.parameters,
                      relationshipDefinitions: newRelationshipDefinitions,
                      removedItem: 'relationshipDefinitions',
                    },
                    relationshipIndex ?? 0
                  )
                );
              },
              icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
            },
          ];
        } else {
          // the ones that are not saved yet
          return [
            {
              name: 'Delete',
              onClick: () => {
                const newRelationshipDefinitions = [...values.relationshipDefinitions.filter((item, i) => i !== index)];
                mutators.remove('relationshipDefinitions', index ?? 0);

                setSelectedDetailItem(
                  getPreviousItem(
                    {
                      parameters: values.selectedEntityType?.parameters,
                      relationshipDefinitions: newRelationshipDefinitions,
                      removedItem: 'relationshipDefinitions',
                    },
                    index ?? 0
                  )
                );
              },
              icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
            },
          ];
        }
      }
    },
    [
      hasCatalogEditPermission,
      mutators,
      selectedDetailItem,
      setSelectedDetailItem,
      values.relationshipDefinitions,
      values.selectedEntityType,
    ]
  );

  useEffect(() => {
    registerField('relationshipDefinitions', noop, { value: true, error: true });
  }, [registerField]);

  return (
    <DataModelLayout<CatalogEntityParameters>
      hasSwitchedMode={true}
      Component={
        mode === 'schema' ? (
          <DataModelDefaultPage<CatalogEntityParameters>
            blocks={
              <>
                <CatalogParameters selectedDetailItem={selectedDetailItem} onItemSelected={setSelectedDetailItem} />
                <CatalogRelationships selectedDetailItem={selectedDetailItem} onItemSelected={setSelectedDetailItem} />
              </>
            }
            rightPanelEmptyStateComponent={<CatalogRightPanelEmptyState />}
            selectedParent={selectedEntityType}
            selectedItem={selectedDetailItem?.selectedItemDetail}
            modalType={'catalog'}
            fieldsFieldName={fieldsFieldName}
            selectedFieldIndex={selectedDetailItem?.index ?? 0}
            fieldMenus={fieldsMenus}
          />
        ) : (
          <CatalogEntitySearch />
        )
      }
      HeaderComponent={
        <CatalogBodyHeader mode={mode} onSwitchModeChanged={switchMode} setSelectedDetailItem={setSelectedDetailItem} />
      }
    />
  );
};
