import { styled } from '@novaera/theme-provider';

export const Circle = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
`;
