import { ActionNode, ActionNodeSummary, ActionVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class ActionComponentFactory implements ComponentFactory<ActionNode> {
  aliasForAction: string;
  nameForAction: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('action');
    this.aliasForAction = newAlias;
    this.nameForAction = newName;
  }

  get componentForGraph() {
    const actionRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForAction,
      width: 40,
      height: 40,
      id: this.aliasForAction,
      type: 'action',
      alias: this.aliasForAction,
    };

    return { root: actionRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: ActionNode) {
    const newActionVertex: ActionVertex = {
      type: serverData.type,
      alias: this.aliasForAction,
    };
    const summary: ActionNodeSummary = {
      name: this.nameForAction,
      actionId: serverData.actionId,
      integrationId: serverData.integrationId,
      version: serverData.version,
      alias: this.aliasForAction,
      type: serverData.type,
    };

    return { root: newActionVertex, nodeSummaries: { [this.aliasForAction]: summary } };
  }
}
