import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS_SEARCH } from '../keys';
import { SearchWorkflow, SearchWorkflowResponse } from '../use-search-workflows/types';
import { CreateWorkflowParams, CreateWorkflowResponse } from './types';

const createWorkflow = async ({ appId, ...params }: CreateWorkflowParams) => {
  const result = await NvAxios.post<CreateWorkflowResponse>(`${USER_APP_ROOT_PATH}/${appId}/workflows`, params);
  return result?.data;
};

export const useCreateWorkflow = () => {
  const cache = useQueryClient();

  return useMutation(createWorkflow, {
    onSuccess: (response) => {
      const queryCached = cache.getQueryCache().findAll({
        queryKey: QUERY_KEYS_WORKFLOWS_SEARCH.all,
      });
      const { appId, description, disabled, id, name, tags, trigger } = response;
      const searchWorkflow: SearchWorkflow = {
        appId,
        description,
        disabled,
        id,
        name,
        tags,
        ...(trigger
          ? {
              trigger: {
                name: trigger.name,
                type: trigger.type,
                ...('eventRuleIdentifier' in trigger
                  ? {
                      integration: {
                        id: trigger.eventRuleIdentifier.integrationId,
                        version: trigger.eventRuleIdentifier.version.number,
                      },
                    }
                  : {}),
              },
            }
          : {}),
      };
      queryCached.forEach((query) => {
        cache.setQueryData<InfiniteData<SearchWorkflowResponse>>(query.queryKey, (old) => {
          if (old) {
            old.pages[0].workflows.unshift(searchWorkflow);
            return old;
          }
          return old;
        });
      });
    },
  });
};
