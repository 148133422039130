import { NvConditionalRender, NvFlex, NvSkeleton } from '@novaera/core';
import { FORM_ITEM_DEPENDENCY_STATE } from '../../dynamic-input/providers/controller/use-check-dependency-value/types';
import { DynamicOptionsErrorMessage } from '../../options/dynamic-options-error-message';
import { DynamicOptionsWarningMessage } from '../../options/dynamic-options-warning-message';
import { ComponentHint } from '../common/adapter/component-hint';
import { RadioButtonComponentParams } from '../use-ui-component/types';
import { RadioButton } from './radio-button';

export const RadioButtonComponent = ({
  inputParameter: { uiComponent },
  inputProps,
  options,
  errorContext,
  isLoading,
  dynamicInputComponentState,
}: RadioButtonComponentParams) => {
  const { hint } = uiComponent;
  //todo when there is error show it.
  const { error } = inputProps;

  const showLoading =
    Boolean(isLoading) || dynamicInputComponentState?.state === FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING;
  const showErrorView =
    !isLoading &&
    Boolean(errorContext) &&
    dynamicInputComponentState?.state !== FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE;
  const showRadioView = !showLoading && !showErrorView;
  const showWarningView = !isLoading && dynamicInputComponentState?.state === FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE;

  return (
    <>
      <NvConditionalRender when={showLoading}>
        <NvFlex gap="8px">
          <NvFlex direction="row" alignItems="center" gap="6px">
            <NvSkeleton variant="circular" width="16px" height="16px" />
            <NvSkeleton variant="rectangular" width="96px" height="16px" />
          </NvFlex>
          <NvFlex direction="row" alignItems="center" gap="6px">
            <NvSkeleton variant="circular" width="16px" height="16px" />
            <NvSkeleton variant="rectangular" width="96px" height="16px" />
          </NvFlex>
          <NvFlex direction="row" alignItems="center" gap="6px">
            <NvSkeleton variant="circular" width="16px" height="16px" />
            <NvSkeleton variant="rectangular" width="96px" height="16px" />
          </NvFlex>
        </NvFlex>
      </NvConditionalRender>
      <NvConditionalRender when={showErrorView}>
        {errorContext && <DynamicOptionsErrorMessage errorContext={errorContext} />}
      </NvConditionalRender>
      <NvConditionalRender when={showRadioView}>
        <RadioButton {...inputProps} options={options ?? []} />
      </NvConditionalRender>
      <NvConditionalRender when={showWarningView}>
        {dynamicInputComponentState && (
          <DynamicOptionsWarningMessage dynamicInputComponentState={dynamicInputComponentState} />
        )}
      </NvConditionalRender>
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
