import { DEFAULT_REACT_QUERY_STALE_TIME, isServiceError, ServiceErrorCode } from '@novaera/ah-common';
import { LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { isAxiosError } from '@novaera/core';
import { QueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_REACT_QUERY_STALE_TIME,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: (_, error) => {
        const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY) || '';

        const shouldRefreshAccessToken =
          Boolean(accessToken) &&
          isAxiosError(error) &&
          // eslint-disable-next-line eqeqeq
          (error.response?.headers['ErrorCode'] == '4012' ||
            error.response?.headers['errorcode'] == '4012' ||
            error.response?.data.errorDescription === 'Token expired');

        return (
          shouldRefreshAccessToken ||
          (isServiceError(error) && error.code === ServiceErrorCode.ACCESS_TOKEN_REFRESH_PROGRESS)
        );
      },
      retryDelay(failureCount) {
        return failureCount * 200;
      },
    },
  },
});
