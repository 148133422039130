import { NvCloseIcon, NvTypography, NvUpgradeIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useIntegrationUpdate } from './controllers/use-integration-update';
import { IntegrationUpdateModal } from './modal';
import { StyledButton, UpdateNodeButton } from './styled';

export const IntegrationUpdate = () => {
  const {
    availableUpdates,
    isLoading,
    onHidePanelClicked,
    showPanel,
    isModalOpened,
    onModalCloseClicked,
    onModalOpenClicked,
  } = useIntegrationUpdate();

  const theme = useTheme();
  return (
    <>
      {showPanel && !isLoading && (
        <UpdateNodeButton>
          <StyledButton
            color="ghost"
            size="small"
            onClick={onModalOpenClicked}
            startIcon={
              <NvUpgradeIcon
                htmlColor={theme.palette.nv_neutral[0]}
                sx={{
                  width: '16px',
                  height: '16px',
                }}
              />
            }
          >
            <NvTypography variant="h5" color={theme.palette.nv_neutral[0]}>
              Update available
            </NvTypography>
          </StyledButton>
          <StyledButton
            onlyIcon
            size="small"
            color="ghost"
            onClick={() => {
              onHidePanelClicked();
            }}
          >
            <NvCloseIcon
              htmlColor={theme.palette.nv_neutral[0]}
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </StyledButton>
        </UpdateNodeButton>
      )}
      {availableUpdates && isModalOpened && (
        <IntegrationUpdateModal isModalOpened={isModalOpened} onModalCloseClicked={onModalCloseClicked} />
      )}
    </>
  );
};
