import { NvBox, NvFlex, NvPersonIcon, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { PropertyPanelSection } from '../../../../../../../../components';
import { DefaultPrincipal } from './default-principal';
import { DefaultPrincipalPropertyPanelSectionProps } from './types';

export const DefaultPrincipalPropertyPanelSection: FC<DefaultPrincipalPropertyPanelSectionProps> = ({
  defaultStarterUserId,
}) => {
  return (
    defaultStarterUserId && (
      <PropertyPanelSection title="Override user connection" icon={<NvPersonIcon />}>
        <NvFlex gap="8px">
          <NvBox>
            <NvTypography variant="body2" textColor="secondary" display="inline">
              This workflow will be run using the credentials of the this user.
            </NvTypography>
          </NvBox>
          <DefaultPrincipal defaultStarterUserId={defaultStarterUserId} />
        </NvFlex>
      </PropertyPanelSection>
    )
  );
};
