import { NvTypography } from '@novaera/core';
import React from 'react';
import { JobRouterLink } from './styled';
import { JobLinkItemProps } from './types';

export const JobLinkItem: React.FC<React.PropsWithChildren<JobLinkItemProps>> = ({
  to,
  icon,
  name,
  sx,
  disabled = false,
}) => (
  <JobRouterLink
    to={to}
    sx={{ ...sx, display: 'flex', maxWidth: 300 }}
    disabled={disabled}
    onClick={(event) => {
      event.stopPropagation();
    }}
  >
    {React.isValidElement(icon) &&
      React.cloneElement(icon, {
        ...icon.props,
        sx: { width: '16px', height: '16px', flex: '0 0 auto', ...(icon.props.sx ?? {}) },
      })}
    <NvTypography variant="body2" noWrap flex="1 1 auto" minWidth={0}>
      {name}
    </NvTypography>
  </JobRouterLink>
);
