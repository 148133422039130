import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiPaper-root {
    &.MuiDialog-paper {
      ${({ theme }) => theme.elevations.e500};
      border-radius: 12px;

      .MuiDialogTitle-root {
      }
    }
  }
`;

export const StyledDialogTitle = styled('div')`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 24px;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0 24px;
`;

export const StyledDialogActions = styled(DialogActions)`
  justify-content: flex-start;
  padding: 32px 24px;
`;

export const StyledDialogV1 = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-start;
  }

  .MuiPaper-root {
    &.MuiDialog-paper {
      ${({ theme }) => theme.elevations.e500};
      border-radius: 12px;
    }
  }
`;

export const StyleDialogWrapperV1 = styled(NvFlex)`
  flex-direction: row;
`;

export const StyledDialogIconWrapperV1 = styled(NvFlex)`
  padding: 24px 16px;
  align-items: center;
`;

export const StyledDialogTitleV1 = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  padding: 24px 24px 16px 0;
`;

export const StyledDialogContentV1 = styled(NvFlex)`
  gap: 12px;
`;

export const StyledDialogActionsV1 = styled(NvFlex)`
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 24px 32px 0;
`;

export const StyleDialogCloseIconWrapperV1 = styled(NvFlex)`
  flex: 0 0 auto;
  padding: 24px 16px;
`;
