import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvFlex } from '../flex';

export const StepTrackerWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
`;

export const Steps = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StepContent = styled(NvBox)`
  width: 100%;
  height: 100%;
  min-height: 320px;
  padding: 32px 4px;
`;

export const StepActions = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  position: sticky;
  bottom: 0;
`;
