import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const HeaderWrapperLeftBoxRefersName = styled(NvBox)`
  background-color: ${({ theme: { palette } }) => palette.nv_neutral_alpha[20]};
  padding: 2px 4px;
  height: 20px;
  display: flex;
  align-items: center;
  min-width: 0;
  border-radius: 6px;
  max-width: 280px;
  justify-content: center;
`;
