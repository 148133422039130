import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { assert } from '@novaera/utils';
import { PropsWithChildren, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { useRealTimeServerMetadata } from '../use-real-time-server-metadata';
import { subscriptionHandshakeLink } from './apollo-links/create-subscription-handshake-link';
import { retryLink } from './apollo-links/retry-link';
import {
  APOLLO_SUBSCRIPTION_JITTER_ENABLED,
  APOLLO_SUBSCRIPTION_MAX_DELAY_BETWEEN_RETRIES,
  APOLLO_SUBSCRIPTION_MAX_NUMBER_OF_ATTEMPTS,
  APOLLO_SUBSCRIPTION_STARTING_DELAY,
} from './constants';
import { NvApolloClientProviderV1Props } from './types';

export const NvApolloClientProviderV1 = ({
  metadataPath,
  onMetadataFailed,
  loadingComponent,
  refreshTokenStrategy,
  SubscriptionComponent,
  initialDelay = APOLLO_SUBSCRIPTION_STARTING_DELAY,
  maxDelayBetweenRetries = APOLLO_SUBSCRIPTION_MAX_DELAY_BETWEEN_RETRIES,
  jitter = APOLLO_SUBSCRIPTION_JITTER_ENABLED,
  maxNumberOfAttempts = APOLLO_SUBSCRIPTION_MAX_NUMBER_OF_ATTEMPTS,
}: PropsWithChildren<NvApolloClientProviderV1Props>) => {
  const {
    isLoading,
    isFetched,
    data: metadata,
    refetch,
    isRefetching,
  } = useRealTimeServerMetadata({ metadataPath, onMetadataFailed });
  useInterval(refetch, metadata ? metadata?.connectionRefreshPeriodInMinutes * 60 * 1000 : null);
  const componentResult = useMemo(() => {
    if (isFetched && !metadata) {
      return <Outlet />;
    } else if (isLoading) {
      return loadingComponent;
    } else if (isFetched && metadata) {
      const client = new ApolloClient({
        link: ApolloLink.from([
          retryLink({ refreshTokenStrategy, initialDelay, jitter, maxDelayBetweenRetries, maxNumberOfAttempts }),
          subscriptionHandshakeLink({ apiURI: metadata.apiURI, region: metadata.region }),
        ]),
        cache: new InMemoryCache(),
      });

      return (
        <ApolloProvider client={client}>
          {!isRefetching && <SubscriptionComponent userId={metadata.userId} workspaceId={metadata.workspaceId} />}
          <Outlet />
        </ApolloProvider>
      );
    }

    assert(false, new Error('Metadata is empty even if it is fetched from the backend'), 'ERROR');
  }, [
    SubscriptionComponent,
    initialDelay,
    isFetched,
    isLoading,
    isRefetching,
    jitter,
    loadingComponent,
    maxDelayBetweenRetries,
    maxNumberOfAttempts,
    metadata,
    refreshTokenStrategy,
  ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{componentResult}</>;
};
