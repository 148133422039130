import { isAxiosError, NvAxios, useAxiosErrorHandler, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE } from '../keys';
import { GetSampleOrExampleEventParams, GetSampleOrExampleEventResponse, GetSampleResponse } from '../types';

const getSampleOrExampleEvent = async (params: GetSampleOrExampleEventParams) => {
  const result = await NvAxios.get<GetSampleResponse>(
    `${USER_APP_ROOT_PATH}/${params.appId}/workflows/${params.workflowId}/events/sample-or-example-event`,
    {
      params: {
        draft: params.isDraft,
      },
    }
  );
  return result?.data;
};

export const useGetSampleOrExampleEventService = ({ appId, workflowId, isDraft }: GetSampleOrExampleEventParams) => {
  const { axiosErrorHandler } = useAxiosErrorHandler();
  return useQuery<GetSampleOrExampleEventResponse>(
    QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE.detail({ appId, workflowId }),
    () => getSampleOrExampleEvent({ appId, workflowId, isDraft }),
    {
      enabled: !!appId && !!workflowId,
      onError: (error) => {
        if (isAxiosError(error)) {
          if (error.response?.status !== 404) {
            axiosErrorHandler(error);
          }
        }
      },
      retry(failureCount, error) {
        if (isAxiosError(error)) {
          return error.response?.status !== 404;
        }
        return true;
      },
    }
  );
};
