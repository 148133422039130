import { FallbackProps } from '@novaera/core';
import { ErrorFallback } from '../../error-fallback';
import { ErrorBoundaryProps, ErrorBoundaryVariant } from '../../types';

const FallbackComponentVariant: Record<ErrorBoundaryVariant, React.FC<React.PropsWithChildren<FallbackProps>>> = {
  Page: ErrorFallback,
  Section: ErrorFallback,
};

export const useErrorBoundary = ({
  variant,
  FallbackComponent,
}: Pick<ErrorBoundaryProps, 'FallbackComponent' | 'variant'>) => {
  return {
    ErrorComponent: FallbackComponent ? FallbackComponent : FallbackComponentVariant[variant || 'Page'],
  };
};
