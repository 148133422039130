import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { DelayOperatorPanel } from './panel';
import { DelayOperatorPropertyPanelDrawerProps } from './types';

export const DelayOperatorPropertyPanelDrawer: React.FC<
  React.PropsWithChildren<DelayOperatorPropertyPanelDrawerProps>
> = ({ handleClose }) => {
  const { userAppId: appId, workflowId } = useParams();
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  const { node: delayNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.delay>({
    workflowId,
    appId,
    nodeAlias: selectedNode?.id,
  });

  const {
    panelProps: [first],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });
  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        {isLoading ? <PropertiesLoading /> : <DelayOperatorPanel onCloseClicked={handleClose} delayNode={delayNode} />}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
