import { NvButton, NvDialogModal, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useEffect } from 'react';
import { AppConfigFlagger } from '..';
import { RealTimeTaskEngine } from '../../../real-time/real-time-task-engine';
import { GLOBAL_REAL_TIME_TASKS } from '../../../real-time/real-time-tasks-object';
import { useAppConfigFlaggerController } from '../controller';
import { AppConfigGenerationData } from '../provider';

export const AppConfigFlaggerSubscriber = () => {
  const {
    appConfigGenerationState,
    appConfigGenerationData,
    isAppConfigGenerationResultModalOpen,
    setAppConfigGenerationData,
    setAppConfigGenerationState,
    setIsAppConfigGenerationResultModalOpen,
    icon,
    title,
    description,
    action,
  } = useAppConfigFlaggerController();

  useEffect(() => {
    // It subscribes auto generated app config event.
    // There is no pollingCallback because we don't have an entity which is created with unique id.
    // On resultAction, result of auto generated app config data will set state on AppConfigGenerationProvider to reach.
    GLOBAL_REAL_TIME_TASKS['generate-app-config'] = new RealTimeTaskEngine<
      unknown,
      {
        id: string;
        type: 'generate-app-config';
        userId: string;
        workspaceId: string;
        properties: string;
      }
    >({
      id: 'generate-app-config-task',
      resultAction: async ({ resultContext: result, source }) => {
        if (source === 'socket') {
          if (result.type === 'generate-app-config') {
            try {
              const response: AppConfigGenerationData = JSON.parse(result.properties);
              setAppConfigGenerationData(response);
              setAppConfigGenerationState(response.success ? 'success' : 'failed');
            } catch (error) {
              assert(true, new Error(`[AppConfigFlaggerSubscriber] - ${JSON.stringify(error)}`));
            }
          }
        }
      },
    });
  }, [setAppConfigGenerationData, setAppConfigGenerationState]);

  return (
    <>
      <AppConfigFlagger
        in={!!appConfigGenerationState}
        icon={icon}
        title={title}
        description={description}
        action={action}
      />
      {isAppConfigGenerationResultModalOpen && !!appConfigGenerationData && !appConfigGenerationData.success && (
        <NvDialogModal
          open={isAppConfigGenerationResultModalOpen}
          onCloseButtonClicked={() => setIsAppConfigGenerationResultModalOpen(false)}
          modalIcon="error"
          Header="Error"
          maxWidth="sm"
          fullWidth
          Body={<NvTypography variant="body1">{appConfigGenerationData?.error}</NvTypography>}
          Footer={
            <NvButton color="secondary" onClick={() => setIsAppConfigGenerationResultModalOpen(false)}>
              Close
            </NvButton>
          }
        />
      )}
    </>
  );
};
