import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const CardListItem = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0 8px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 6px;
  ${({ theme }) => theme.elevations.e100};
  width: 100%;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CardActions = styled(NvFlex)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
export const CardInfo = styled(NvFlex)`
  flex-direction: row;
`;
