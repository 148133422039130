import { useTheme } from '@novaera/theme-provider';
import { isString } from 'lodash';
import { NvBox } from '../../box';
import { NvFlex } from '../../flex';
import { NvCustomHelpIcon } from '../../icons';
import { NvTooltip } from '../../tooltip';
import { NvTypography } from '../../typography';
import { DefaultDisplayValue } from '../default-value-display';
import { FieldTitleProps } from './types';

export const FieldTitle = ({
  direction,
  labelText,
  labelWidth,
  hasRequiredIndicator,
  hasHiddenIndicator,
  infoText,
  labelVariant = 'h5',
  className,
  defaultDisplayValue,
}: FieldTitleProps) => {
  const theme = useTheme();
  return (
    <NvFlex
      sx={
        direction === 'label-on-top'
          ? { margin: '4px 0 4px 0' }
          : { width: labelWidth, flex: '0 0 auto', padding: '8px 0' }
      }
      className={className}
    >
      <NvBox display="flex" flexDirection="row" gap="2px" alignItems="center">
        {isString(labelText) ? (
          <NvTypography noWrap alignSelf="flex-start" title={labelText} variant={labelVariant}>
            {labelText}
          </NvTypography>
        ) : (
          labelText
        )}
        {hasRequiredIndicator && (
          <NvTypography variant={labelVariant} color={theme.palette.nv_error[40]}>
            *
          </NvTypography>
        )}
        {hasHiddenIndicator && (
          <NvTypography variant="h6" textColor="ghost">
            (Hidden)
          </NvTypography>
        )}
        {defaultDisplayValue && (
          <DefaultDisplayValue tooltipTitle={`Default value: ${defaultDisplayValue}`} text="Default value" />
        )}
        {isString(infoText) ? (
          <NvTooltip title={infoText} variant="small" placement="top-start">
            <NvCustomHelpIcon
              htmlColor={theme.palette.nv_neutral[80]}
              fontSize="small"
              sx={{ width: '12px', height: '12px', ml: '3px' }}
            />
          </NvTooltip>
        ) : (
          infoText
        )}
      </NvBox>
    </NvFlex>
  );
};
