import {
  AssistantModel,
  ListValue,
  SingularValue,
  StringValue,
  ValueTypes,
  useGetAssistantModels,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvConditionalRender,
  NvCustomHelpIcon,
  NvField,
  NvFlex,
  NvSelect,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import {
  PropertyPanelEmptySection,
  PropertyPanelListHeader,
  PropertyPanelListSection,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../../components';
import { OutputItem } from '../../../../../../../../../components/output-item';
import { SearchTooltip } from '../../../../../../../../../components/query-search-help-tooltip/styled';
import { QA_SAMPLE_RESPONSE } from '../../../../../common/constants';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { ErrorHandlingStrategyPanel } from '../../../common/error-handling-strategy-panel';
import { DocumentFilter } from '../document-filter';
import { DocItemFilter } from '../document-filter/doc-item-filter';
import { TagItemFilter } from '../document-filter/tag-item-filter';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const FormBody = () => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const {
    fields: { push: pushNewDocsId, remove: removeDocId, update: updateDocId, value: docs },
  } = useFieldArray<SingularValue>('docIds.value');

  const {
    fields: { push: pushNewTag, remove: removeTag, update: updateTag, value: tags },
  } = useFieldArray<SingularValue>('tags.value');

  const { data, isLoading: isAssistantModelLoading } = useGetAssistantModels({ appId: userAppId });
  const languageModelOptions = useMemo(
    () => data?.models.map((model) => ({ label: model, value: model })) ?? [],
    [data?.models]
  );
  return (
    <>
      <PropertyPanelSimpleSection>
        <NvTypography variant="body2">
          Q&A returns an answer for a given prompt based on the selected documentation.
        </NvTypography>
      </PropertyPanelSimpleSection>
      <PropertyPanelSection
        title="Language model"
        tooltip={`GPT model to use for the Q&A node. The model will be used to generate the response based on the prompt.`}
      >
        {isAssistantModelLoading ? (
          <NvSkeleton width={'100%'} height={'40px'} />
        ) : (
          <NvField
            defaultValue={languageModelOptions?.[0]?.value}
            name="model"
            formControlStyle={{ width: '100%' }}
            parse={(value: string) => {
              return value ? { type: ValueTypes.STRING, value: value } : undefined;
            }}
            format={(value: StringValue | undefined | string) => {
              return typeof value === 'string' ? value : value?.value;
            }}
            component={<NvSelect<AssistantModel | undefined> options={languageModelOptions} />}
          />
        )}
      </PropertyPanelSection>
      <PropertyPanelSection
        title="Prompt"
        tooltip={`AI agent will return a response from the app documents based on the given prompt.`}
      >
        <NvField
          name="prompt"
          formControlStyle={{ width: '100%' }}
          format={(value: StringValue | undefined) => value?.value}
          parse={(value: string) => {
            return value ? { type: ValueTypes.STRING, value: value } : undefined;
          }}
          component={<CodeInput context={workflowCodeInputContext} placeholder={'{{...}}'} />}
        />
      </PropertyPanelSection>
      <PropertyPanelSection
        title="Messages"
        tooltip={
          <SearchTooltip
            placement="top-end"
            title={
              <NvFlex gap="8px">
                <NvTypography variant="body1">
                  Messages must be an array of objects, where each object has a{' '}
                  <b>role (either system, user, or assistant)</b> and <b>content</b>. Conversations can be as short as
                  one message or many back and forth turns. <br />
                  Learn more about Q&A node{' '}
                  <a
                    href={'https://actioner.com/help/q-and-a-nodes#how-to-configure-a-qa-node'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>here</strong>
                  </a>
                  .
                </NvTypography>
              </NvFlex>
            }
          >
            <NvFlex>
              <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
            </NvFlex>
          </SearchTooltip>
        }
      >
        <NvField<undefined | ListValue>
          name="messages"
          formControlStyle={{ width: '100%' }}
          component={({ value, onChange }) => {
            return (
              <CodeInput
                value={value?.codeTemplate}
                onChange={(value) => {
                  onChange({
                    type: ValueTypes.LIST,
                    codeTemplate: value,
                  });
                }}
                context={workflowCodeInputContext}
                placeholder={'{{...}}'}
              />
            );
          }}
        />
      </PropertyPanelSection>
      <PropertyPanelListHeader
        title="DOCUMENTS"
        tooltip="The returned answer will be based on the selected document below."
        actions={
          <DocumentFilter
            onAddClicked={({ type }) => {
              if (type === 'tags') {
                pushNewTag({ type: ValueTypes.STRING, value: '' });
              } else {
                pushNewDocsId({ type: ValueTypes.STRING, value: '' });
              }
            }}
          />
        }
      />

      <NvConditionalRender when={Boolean(docs?.length) || Boolean(tags?.length)}>
        <PropertyPanelListSection>
          <FieldArray<StringValue> name="docIds.value">
            {({ fields }) => {
              const docs = fields.value;

              return docs?.map((doc, index) => {
                return (
                  <DocItemFilter
                    selectedDocs={docs.map((doc) => doc.value ?? '')}
                    value={{
                      type: ValueTypes.STRING,
                      displayValue: doc.displayValue,
                      value: doc.value,
                    }}
                    mode={!doc.value ? 'edit' : 'display'}
                    key={`doc-item-filter-${doc.value}-${index}`}
                    onApplyClicked={(appliedValue) => {
                      if (appliedValue) {
                        updateDocId(index, {
                          type: ValueTypes.STRING,
                          displayValue: appliedValue.label,
                          value: appliedValue.value,
                        });
                      }
                    }}
                    onRemoveClicked={() => {
                      removeDocId(index);
                    }}
                  />
                );
              });
            }}
          </FieldArray>
          <FieldArray<StringValue> name="tags.value">
            {({ fields }) => {
              const tags = fields.value;

              return tags?.map((tag, index) => {
                return (
                  <TagItemFilter
                    value={{
                      type: ValueTypes.STRING,
                      displayValue: tag.value,
                      value: tag.value,
                    }}
                    mode={!tag.value ? 'edit' : 'display'}
                    key={`tag-item-filter-${tag.value}-${index}`}
                    onApplyClicked={(appliedValue) => {
                      if (appliedValue) {
                        updateTag(index, {
                          type: ValueTypes.STRING,
                          displayValue: appliedValue,
                          value: appliedValue,
                        });
                      }
                    }}
                    onRemoveClicked={() => {
                      removeTag(index);
                    }}
                  />
                );
              });
            }}
          </FieldArray>
        </PropertyPanelListSection>
      </NvConditionalRender>

      {!docs?.length && !tags?.length && <PropertyPanelEmptySection emptyText="There is no document selected" />}

      <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
      <PropertyPanelSimpleSection>
        <OutputItem content={QA_SAMPLE_RESPONSE} isTestResultFailed={false} />
      </PropertyPanelSimpleSection>

      <NvField name="errorHandlingStrategy" component={<ErrorHandlingStrategyPanel />} />
    </>
  );
};
