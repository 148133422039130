import { SvgIconProps } from '@mui/material';
import { NvFlex, NvTypography } from '@novaera/core';
import React from 'react';

export type HeaderProps = {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
};

export const Header = ({ icon, title }: HeaderProps) => {
  return (
    <NvFlex gap={'8px'} flexDirection={'row'} alignItems={'center'} marginBottom={'26px'}>
      {React.createElement(icon, { sx: { height: '32px', width: '32px' } })}
      <NvTypography variant="h1">{title}</NvTypography>
    </NvFlex>
  );
};
