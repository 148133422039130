import { RateRecurrenceUnit, RecurrenceTimeUnit, RecurrenceType } from '@novaera/actioner-service';
import { NvBox, NvDivider, NvFlex, NvSelect, useField, useForm } from '@novaera/core';
import { useState } from 'react';
import {
  ANNUAL_CRON_EXPRESSION,
  DEFAULT_CRON_EXPRESSION,
  DEFAULT_RATE_UNIT,
  MONTHLY_CRON_EXPRESSION,
  RECURRENCE_TIME_UNITS,
  WEEKDAYS_CRON_EXPRESSION,
  WEEKLY_CRON_EXPRESSION,
} from '../../constants';
import { SaveRecurringJobFormValues } from '../types';
import { BoilerplateCronConfiguration } from './cron/boilerplate';
import { CustomCronConfiguration } from './cron/custom';
import { EndConfiguration } from './end';
import { RateRecurrenceConfiguration } from './rate';
import { ConfigureRecurrenceProps } from './types';

export const ConfigureRecurrence: React.FC<ConfigureRecurrenceProps> = ({ onCronErrorStateChange }) => {
  const { change } = useForm();
  const {
    input: { value: recurrence },
  } = useField<SaveRecurringJobFormValues['recurrence']>('recurrence');

  const [selectedTimeUnit, setSelectedTimeUnit] = useState<RecurrenceTimeUnit | null>(() =>
    recurrence.type === RecurrenceType.CRON
      ? RecurrenceTimeUnit.CUSTOM
      : recurrence.unit === RateRecurrenceUnit.DAYS
      ? RecurrenceTimeUnit.DAY
      : recurrence.unit === RateRecurrenceUnit.HOURS
      ? RecurrenceTimeUnit.HOUR
      : recurrence.unit === RateRecurrenceUnit.MINUTES
      ? RecurrenceTimeUnit.MINUTE
      : null
  );

  return (
    <NvFlex gap="16px">
      <NvFlex gap="16px">
        <NvFlex
          direction={
            recurrence.type === RecurrenceType.RATE ||
            (recurrence.type === RecurrenceType.CRON &&
              selectedTimeUnit !== RecurrenceTimeUnit.WEEKLY &&
              selectedTimeUnit !== RecurrenceTimeUnit.ANNUAL)
              ? 'row'
              : 'column'
          }
          alignItems="flex-start"
          flexWrap="wrap"
          gap="8px"
        >
          <NvBox width="140px">
            <NvSelect
              fullWidth
              value={selectedTimeUnit}
              onChange={(e) => {
                const value = e.target.value as RecurrenceTimeUnit;
                setSelectedTimeUnit(value);
                let newRecurrence = {};
                if (value === RecurrenceTimeUnit.WEEKDAYS) {
                  newRecurrence = {
                    end: recurrence.end,
                    type: RecurrenceType.CRON,
                    cron: WEEKDAYS_CRON_EXPRESSION,
                  };
                } else if (value === RecurrenceTimeUnit.WEEKLY) {
                  newRecurrence = {
                    end: recurrence.end,
                    type: RecurrenceType.CRON,
                    cron: WEEKLY_CRON_EXPRESSION,
                  };
                } else if (value === RecurrenceTimeUnit.MONTHLY) {
                  newRecurrence = {
                    end: recurrence.end,
                    type: RecurrenceType.CRON,
                    cron: MONTHLY_CRON_EXPRESSION,
                  };
                } else if (value === RecurrenceTimeUnit.ANNUAL) {
                  newRecurrence = {
                    end: recurrence.end,
                    type: RecurrenceType.CRON,
                    cron: ANNUAL_CRON_EXPRESSION,
                  };
                } else if (value === RecurrenceTimeUnit.CUSTOM) {
                  newRecurrence = {
                    end: recurrence.end,
                    type: RecurrenceType.CRON,
                    cron: DEFAULT_CRON_EXPRESSION,
                  };
                } else {
                  newRecurrence = {
                    end: recurrence.end,
                    type: RecurrenceType.RATE,
                    value: DEFAULT_RATE_UNIT,
                    recurrenceTime:
                      value === RecurrenceTimeUnit.DAY
                        ? { hours: 12, minutes: 0 }
                        : value === RecurrenceTimeUnit.HOUR
                        ? { hours: null, minutes: 0 }
                        : value === RecurrenceTimeUnit.MINUTE
                        ? { hours: null, minutes: null }
                        : { hours: null, minutes: null },
                    unit:
                      value === RecurrenceTimeUnit.DAY
                        ? RateRecurrenceUnit.DAYS
                        : value === RecurrenceTimeUnit.HOUR
                        ? RateRecurrenceUnit.HOURS
                        : value === RecurrenceTimeUnit.MINUTE
                        ? RateRecurrenceUnit.MINUTES
                        : RateRecurrenceUnit.MINUTES,
                  };
                }
                change('recurrence', newRecurrence);
              }}
              options={RECURRENCE_TIME_UNITS}
            />
          </NvBox>
          {!(
            (recurrence.type === RecurrenceType.CRON &&
              (selectedTimeUnit === RecurrenceTimeUnit.WEEKLY || selectedTimeUnit === RecurrenceTimeUnit.ANNUAL)) ||
            selectedTimeUnit === RecurrenceTimeUnit.CUSTOM
          ) && <NvDivider orientation="vertical" sx={{ margin: '8px 6px', height: '16px' }} />}
          {recurrence.type === RecurrenceType.RATE && (
            <RateRecurrenceConfiguration recurrence={recurrence} selectedTimeUnit={selectedTimeUnit} />
          )}
          {recurrence.type === RecurrenceType.CRON && selectedTimeUnit !== RecurrenceTimeUnit.CUSTOM && (
            <BoilerplateCronConfiguration selectedTimeUnit={selectedTimeUnit} />
          )}
        </NvFlex>

        {recurrence.type === RecurrenceType.CRON && selectedTimeUnit === RecurrenceTimeUnit.CUSTOM && (
          <CustomCronConfiguration cron={recurrence.cron} onCronErrorStateChange={onCronErrorStateChange} />
        )}
      </NvFlex>
      <NvDivider />

      <EndConfiguration end={recurrence.end} />
    </NvFlex>
  );
};
