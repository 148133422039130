import { Workflow } from '@novaera/actioner-service';
import { NovaeraEdge, NovaeraNode, NovaeraNodeWithPosition } from '@novaera/core';
import { AppWorkflowNodeType, AppWorkflowTriggerNodeType } from '../../components/user-app-workflow-node-types/types';
import { TRIGGER_NODE_ALIAS } from '../../constants';
import { userAppGraph } from '../../graph-utils/user-app-graph';
import { addClosure } from './utils/add-closure';
import { connectNodes } from './utils/connect-nodes';
import { findRoot } from './utils/find-root';
import { traverseGraph } from './utils/traverse-graph';

export const convertBackendToUi = ({ workflow }: { workflow?: Workflow }) => {
  const novaeraNodes: NovaeraNodeWithPosition[] = [];
  const novaeraEdges: NovaeraEdge[] = [];

  if (workflow) {
    userAppGraph.initialize({ nodes: [], edges: [] });

    if (workflow.trigger) {
      let triggerNode: NovaeraNode<AppWorkflowTriggerNodeType>;
      if (workflow.trigger.type === 'integrationApp') {
        triggerNode = {
          type: 'integrationApp',
          alias: TRIGGER_NODE_ALIAS,
          id: TRIGGER_NODE_ALIAS,
          name: workflow.trigger.name,
          height: 40,
          width: 40,
        };
        userAppGraph.addNode(triggerNode);
      } else if (workflow.trigger.type === 'integrationWebhook') {
        triggerNode = {
          type: 'integrationWebhook',
          alias: TRIGGER_NODE_ALIAS,
          id: TRIGGER_NODE_ALIAS,
          name: workflow.trigger.name,
          height: 40,
          width: 40,
        };
        userAppGraph.addNode(triggerNode);
      } else if (workflow.trigger.type === 'genericWebhook') {
        triggerNode = {
          type: 'genericWebhook',
          alias: TRIGGER_NODE_ALIAS,
          id: TRIGGER_NODE_ALIAS,
          name: workflow.trigger.name,
          height: 40,
          width: 40,
        };
        userAppGraph.addNode(triggerNode);
      } else if (workflow.trigger.type === 'email') {
        triggerNode = {
          type: 'email',
          alias: TRIGGER_NODE_ALIAS,
          id: TRIGGER_NODE_ALIAS,
          name: workflow.trigger.name,
          height: 40,
          width: 40,
        };
        userAppGraph.addNode(triggerNode);
      } else if (workflow.trigger.type === 'actionerEvent') {
        triggerNode = {
          type: 'actionerEvent',
          alias: TRIGGER_NODE_ALIAS,
          id: TRIGGER_NODE_ALIAS,
          name: workflow.trigger.name,
          height: 40,
          width: 40,
        };
        userAppGraph.addNode(triggerNode);
      } else if (workflow.trigger.type === 'form') {
        triggerNode = {
          type: 'form',
          alias: TRIGGER_NODE_ALIAS,
          id: TRIGGER_NODE_ALIAS,
          name: workflow.trigger.name,
          height: 40,
          width: 40,
        };
        userAppGraph.addNode(triggerNode);
      } else {
        throw Error('not implemented yet');
      }

      const nodes = workflow?.graph?.vertices;
      const nodeSummaries = workflow.nodeSummaries;
      if (nodes && nodes.length > 0) {
        const root = findRoot(nodes);
        if (triggerNode) {
          connectNodes({
            sourceId: triggerNode?.alias,
            targetId: root.alias,
            edgeExtraData: { type: 'EdgeWithButton' },
          });
        }
        window.traverseNumber = 0;
        traverseGraph({ node: root, nodes, nodeSummaries });
      }
      addClosure();
      userAppGraph.refreshLayout();
    } else {
      const startNode: NovaeraNode<AppWorkflowNodeType> = {
        type: 'StartNode',
        width: 40,
        height: 40,
        id: 'startNode',
        name: 'Choose trigger',
        alias: 'startNodeAlias',
      };
      userAppGraph.addNode(startNode);
      userAppGraph.refreshLayout();
      return {
        novaeraNodes: userAppGraph.nodes(),
        novaeraEdges,
      };
    }

    return { novaeraNodes: userAppGraph.nodes(), novaeraEdges: userAppGraph.edges() };
  }
  return { novaeraNodes, novaeraEdges };
};
