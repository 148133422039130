import { enrichContext } from '@novaera/chart-data-engine';
import CodeMirror from 'codemirror';
import { useMemo } from 'react';
import { useCodeInputEvaluation } from '../../controllers/use-code-input-evaluation';
import { CodeInputProps, Context } from '../../types';
import { useCodeInputBaseController } from '../../use-code-input-base-controller';

export const useCodeInputForExpression = ({
  context,
  value,
  expectedType,
  onResultCalculated,
  onFocus,
  onBlur,
  onChange,
  matchArrayItemTypes,
}: {
  context: Context;
  value?: string;
  expectedType?: CodeInputProps['expectedType'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResultCalculated: any;
  onChange?: (value: string) => void;
  onFocus?: (event: CodeMirror.Editor) => void;
  onBlur?: (event: CodeMirror.Editor) => void;
  matchArrayItemTypes?: boolean;
}) => {
  const enrichedContext = useMemo(() => {
    return enrichContext(context);
  }, [context]);

  const {
    handleEditorChange,
    handleEditorUpdate,
    handleEditorKeyUp,
    handleEditorDidMount,
    handleOnBlur,
    handleOnFocus: handleInputBaseOnFocus,
    tooltipId,
    isOpenedToolTip,
    isFocused,
    tooltipContent,
    error,
    setError,
  } = useCodeInputBaseController({ context, onBlur, onChange, enrichedContext, onFocus });

  const { getAnnotations, handleOnFocus, calculatedResult, informationBoxId, informationBoxWidth } =
    useCodeInputEvaluation({
      context,
      error,
      onResultCalculated,
      setError,
      expectedType,
      matchArrayItemTypes,
      onBlur,
      onFocus,
      onChange,
      value,
    });

  return {
    handleOnFocus: (editor: CodeMirror.Editor, event?: FocusEvent | undefined) => {
      handleOnFocus(editor, event);
      handleInputBaseOnFocus(editor, event);
    },
    handleEditorChange,
    handleEditorUpdate,
    handleEditorKeyUp,
    handleEditorDidMount,
    handleOnBlur,
    error,
    calculatedResult,
    informationBoxId,
    informationBoxWidth,
    getAnnotations,
    tooltipId,
    isOpenedToolTip,
    isFocused,
    tooltipContent,
  };
};
