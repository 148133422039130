import { styled } from '@novaera/theme-provider';
import { MenuDropdown, MenuItemWrapper } from '../menu-item/styled';

export const ProfileMenuItemWrapper = styled(MenuItemWrapper)`
  padding: 12px 8px 12px 0;
`;

export const ProfileMenuPopupMenuDropdown = styled(MenuDropdown)`
  .MuiPaper-root {
    margin-top: 8px;
    min-width: 260px;

    .MuiList-root {
      padding: 6px;
    }
  }
`;
