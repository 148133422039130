import { styled } from '@novaera/theme-provider';
import { NvAccordion, NvAccordionActions, NvAccordionDetails, NvAccordionSummary } from '../accordion';

export const Section = styled(NvAccordion)`
  background: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  overflow: hidden;

  &:before {
    display: none;
  }

  &:first-of-type,
  &:last-of-type {
    border-radius: 12px;
  }

  box-shadow: none;
`;

export const SectionHeader = styled(NvAccordionSummary)`
  flex-direction: row-reverse;
  padding: 0 12px;

  & .MuiAccordionSummary-content {
    margin: 12px 0 12px 8px;
  }
  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg);
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(360deg);
  }
`;

export const SectionBody = styled(NvAccordionDetails)`
  padding: 8px 16px 16px 40px;
`;

export const SectionFooterActions = styled(NvAccordionActions)`
  justify-content: flex-start;
`;
