import { NvAxios, useQuery } from '@novaera/core';

import { Subscription } from '../../../billing/types';
import { ADMIN_SUBSCRIPTION } from '../constants';
import { QUERY_KEYS_ADMIN_WORKSPACES } from '../keys';

type WorkspaceSubscriptionPlanParams = {
  workspaceId?: string;
};

const getWorkspaceSubscriptionPlan = async (params: WorkspaceSubscriptionPlanParams) => {
  const result = await NvAxios.get<Subscription>(`${ADMIN_SUBSCRIPTION}/subscriptions/${params.workspaceId}/current`);
  return result?.data;
};

export const useGetWorkspaceSubscriptionPlan = (params: WorkspaceSubscriptionPlanParams) => {
  return useQuery(
    QUERY_KEYS_ADMIN_WORKSPACES.subscriptionPlan(params.workspaceId),
    () => getWorkspaceSubscriptionPlan(params),
    {
      enabled: !!params.workspaceId,
      retry: false,
    }
  );
};
