import { UserRole } from '@novaera/actioner-service';
import { UserManagementRoleOption } from '../types';

export const USER_MANAGEMENT_ROLES: UserManagementRoleOption[] = [
  {
    label: 'Admin',
    value: UserRole.ADMIN,
    description: 'Can change workspace settings and workspace plan. Also, can invite new members to the workspace.',
  },
  {
    label: 'User',
    value: UserRole.USER,
    description:
      'Can not change workspace settings or workspace plan. Also cannot invite new members to the workspace.',
  },
];
