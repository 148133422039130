import { RecurrenceTimeUnit } from '@novaera/actioner-service';
import { MonthlyCronConfiguration } from './monthly';
import { BoilerplateCronConfigurationProps } from './types';
import { WeekdaysCronConfiguration } from './weekdays';
import { WeeklyCronConfiguration } from './weekly';
import { YearlyCronConfiguration } from './yearly';

export const BoilerplateCronConfiguration: React.FC<BoilerplateCronConfigurationProps> = ({ selectedTimeUnit }) => (
  <>
    {selectedTimeUnit === RecurrenceTimeUnit.WEEKDAYS && <WeekdaysCronConfiguration />}
    {selectedTimeUnit === RecurrenceTimeUnit.WEEKLY && <WeeklyCronConfiguration />}
    {selectedTimeUnit === RecurrenceTimeUnit.MONTHLY && <MonthlyCronConfiguration />}
    {selectedTimeUnit === RecurrenceTimeUnit.ANNUAL && <YearlyCronConfiguration />}
  </>
);
