import {
  Initial,
  isMaxLength,
  isMinLength,
  isRequired,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvMarkDownEditor,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import * as React from 'react';
import { NvLogoUpload } from '../../components/logo-upload';
import { CreateUserAppModalFormValues, CreateUserAppModalProps } from './types';
import { useCreateUserAppModal } from './use-create-user-app-modal';

export const CreateUserAppModal: React.FC<React.PropsWithChildren<CreateUserAppModalProps>> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const { handleOnSubmit } = useCreateUserAppModal({ onClose });
  const initialValues = React.useMemo(() => ({ name: '', logoId: '' }), []);

  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <NvForm<CreateUserAppModalFormValues> onSubmit={handleOnSubmit} initialValues={initialValues}>
        {({ form, submitting }) => {
          return (
            <NvDialogSlots>
              <NvDialogSlots.Slot name="icon">
                <NvAddIcon />
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="title">
                <NvTypography variant="h2">Create new app</NvTypography>
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="closeButton">
                <NvButton
                  onlyIcon
                  size="small"
                  color="secondary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <NvCloseIcon />
                </NvButton>
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="content">
                <NvFlex gap="16px" direction="column">
                  <NvLogoUpload
                    ImageFallBack={
                      <Initial
                        size="xlarge"
                        value={initialValues?.name || 'User app'}
                        color={theme.palette.nv_neutral[500]}
                      />
                    }
                    initialLogoUrl={initialValues?.logoId}
                    type="app"
                    imageShape="square"
                    imageSize="xlarge"
                    onLogoStateChanged={({ logo }) => {
                      form.change('logoId', logo.id);
                    }}
                    logoUploadButtonProps={{
                      text: 'Upload icon',
                    }}
                    hasRemoveAction
                    fallbackImageProps={{
                      hasBlurEffect: true,
                      className: 'package-image',
                    }}
                  />
                  <NvField
                    name="name"
                    labelText="Name"
                    labelVariant="h5"
                    direction="label-on-top"
                    showErrorMessageOnlyWhenBlur
                    component={<NvTextField size="medium" disabled={submitting} />}
                    validators={[isRequired(), isMinLength({ length: 3 }), isMaxLength({ length: 150 })]}
                    hasRequiredIndicator
                  />
                  <NvField
                    name="description.value"
                    labelText="Description"
                    labelVariant="h5"
                    direction="label-on-top"
                    component={<NvMarkDownEditor />}
                    showErrorMessageOnlyWhenBlur
                    validators={[
                      isMaxLength({
                        length: 3000,
                      }),
                    ]}
                  />
                </NvFlex>
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="actions">
                <NvButton type="submit" loading={submitting} disabled={submitting}>
                  Create
                </NvButton>
                <NvButton onClick={onClose} color="ghost">
                  Cancel
                </NvButton>
              </NvDialogSlots.Slot>
            </NvDialogSlots>
          );
        }}
      </NvForm>
    </NvDialogV1>
  );
};
