import { USER_PERMISSION, useDeleteIntegrationAction } from '@novaera/actioner-service';
import { MenuItems, NvDeleteOutlineIcon, useConfirmDialog } from '@novaera/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserPermissions } from '../../../../user-permission-boundary/use-user-permission';
import { INTEGRATION_ACTIONS } from '../../../constants';

export const useActionMenus = () => {
  const navigate = useNavigate();
  const { mutate: deleteIntegrationAction, isLoading } = useDeleteIntegrationAction();
  const { checkPermission } = useUserPermissions();
  const { openConfirm } = useConfirmDialog();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);

  const getActionMenus = useCallback(
    (integrationId: string, actionId: string, version: number) => {
      const menuItems: MenuItems[] = hasIntegrationUpdateRight
        ? [
            {
              name: 'Delete',
              isLoading: isLoading,
              onClick: () => {
                openConfirm({
                  message:
                    'You are about to delete this action. Deleting the action will affect all workflows depending on it. Are you sure? ',
                  onConfirm: () => {
                    deleteIntegrationAction(
                      { integrationId, actionId, version },
                      {
                        onSuccess: () => {
                          navigate(INTEGRATION_ACTIONS(integrationId));
                        },
                      }
                    );
                  },
                });
              },
              icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
            },
          ]
        : [];
      return menuItems;
    },
    [hasIntegrationUpdateRight, isLoading, openConfirm, deleteIntegrationAction, navigate]
  );
  return {
    getActionMenus,
  };
};
