import { Workflow, WorkflowWithState } from '@novaera/actioner-service';
import {
  Initial,
  NvAutocomplete,
  NvButton,
  NvChip,
  NvConditionalRender,
  NvExtensionIcon,
  NvFlex,
  NvImage,
  NvSkeleton,
  NvTextField,
  NvTrackChangesIcon,
  NvTypography,
  NvWarningIcon,
  SectionMessage,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { PropertyPanelSection, UpdateVersionButton } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { IntegrationBox } from '../styled';
import { useTriggerPanelController } from './controllers/use-trigger-panel';

export const TriggerPanel = ({
  workflow,
  savedWorkflow,
}: {
  workflow: WorkflowWithState;
  savedWorkflow?: Workflow;
}) => {
  const {
    trigger,
    integration,
    eventRulesLoading,
    eventRulesOptions,
    onTriggerChange,
    isCaptureEventDisabled,
    captureEventWarningMessage,
    onCaptureEventClick,
    isCaptureLoading,
    handleDiscardChanges,
    isRollbackWorkflowLoading,
  } = useTriggerPanelController({
    workflow,
    savedWorkflow,
  });

  const { hasEditPermission, isManagedApp } = useWorkflowPermission();

  const theme = useTheme();

  return (
    <PropertyPanelSection icon={<NvExtensionIcon />} title="Trigger">
      <NvFlex gap="8px">
        <IntegrationBox>
          <NvImage
            imageShape="circle"
            src={integration?.logoUrl}
            FallBack={<Initial size={'small'} value={integration?.name || ''} color={theme.palette.nv_neutral[500]} />}
            size="small"
            hasNoElevation
          />
          <NvTypography variant="h5" textColor="main" flex="1 1 auto">
            {integration?.name}
          </NvTypography>
          <NvConditionalRender
            when={
              !isManagedApp &&
              hasEditPermission &&
              integration?.latestVersion.number !== trigger.eventRuleIdentifier.version.number
            }
          >
            <UpdateVersionButton
              onClick={() => {
                if (integration?.latestVersion) {
                  onTriggerChange({ ...trigger.eventRuleIdentifier, version: integration?.latestVersion });
                }
              }}
            />
          </NvConditionalRender>
          {integration?.scope.type === 'workspace' ? <NvChip label="custom" compact /> : undefined}
          <NvTypography variant="body2" textColor="subtle">
            v{trigger.eventRuleIdentifier.version.number}.0.0
          </NvTypography>
        </IntegrationBox>
        {!eventRulesLoading &&
          eventRulesOptions &&
          (eventRulesOptions.length > 0 ? (
            <>
              <NvAutocomplete
                fullWidth
                disableClearable
                options={eventRulesOptions}
                size="small"
                getOptionLabel={(option) => option.label}
                value={eventRulesOptions.find((option) => option.value === trigger?.eventRuleIdentifier?.eventRuleId)}
                onChange={(e, selectedItem) => {
                  if (!hasEditPermission) {
                    return;
                  }
                  onTriggerChange({ ...trigger.eventRuleIdentifier, eventRuleId: selectedItem?.value });
                }}
                renderInput={(params) => (
                  <NvTextField
                    {...params}
                    startIcon={<NvTrackChangesIcon />}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    placeholder="Select an event"
                    size="medium"
                  />
                )}
              />
              <NvConditionalRender when={hasEditPermission}>
                <NvFlex direction="row" gap="8px" alignItems="center">
                  <NvButton
                    color="success"
                    size="small"
                    loading={isCaptureLoading}
                    disabled={isCaptureEventDisabled}
                    onClick={onCaptureEventClick}
                  >
                    Capture event
                  </NvButton>

                  {captureEventWarningMessage && (
                    <NvFlex flexDirection={'row'} alignItems="center" gap={'4px'}>
                      <NvWarningIcon fontSize="small" htmlColor={theme.palette.nv_warning[40]} />
                      <NvTypography variant="body3" textColor="subtle">
                        {captureEventWarningMessage}
                      </NvTypography>
                    </NvFlex>
                  )}

                  {!captureEventWarningMessage && (
                    <NvTypography variant="body3" textColor="subtle">
                      Capture event to see the payload.
                    </NvTypography>
                  )}
                </NvFlex>
              </NvConditionalRender>
            </>
          ) : (
            <SectionMessage
              message={'This integration does not have any trigger!'}
              variant={'info'}
              actionButton={
                <NvButton
                  size="small"
                  loading={isRollbackWorkflowLoading}
                  disabled={isRollbackWorkflowLoading}
                  onClick={() => {
                    if (!hasEditPermission) {
                      return;
                    }
                    handleDiscardChanges();
                  }}
                >
                  Remove
                </NvButton>
              }
            />
          ))}
        {eventRulesLoading && (
          <>
            <NvSkeleton width="100%" variant="rectangular" height={'32px'} />
            <NvSkeleton width="100%" variant="rectangular" height={'24px'} />
          </>
        )}
      </NvFlex>
    </PropertyPanelSection>
  );
};
