import { SvgIcon, SvgIconProps } from '@mui/material';

export const NvAdminIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.6667 14.6667C23.12 14.6667 23.56 14.72 24 14.7867V10.1067C24 9.04001 23.3733 8.08001 22.4 7.66667L15.0667 4.46667C14.3867 4.17334 13.6133 4.17334 12.9333 4.46667L5.6 7.66667C4.62667 8.09334 4 9.05334 4 10.1067V14.9067C4 20.96 8.26667 26.6267 14 28C14.7333 27.8267 15.44 27.5733 16.1333 27.2667C15.2133 25.96 14.6667 24.3733 14.6667 22.6667C14.6667 18.2533 18.2533 14.6667 22.6667 14.6667Z"
        fill="#0A2D62"
        fillOpacity="0.77"
      />
      <path
        d="M22.6666 17.3333C19.72 17.3333 17.3333 19.72 17.3333 22.6667C17.3333 25.6133 19.72 28 22.6666 28C25.6133 28 28 25.6133 28 22.6667C28 19.72 25.6133 17.3333 22.6666 17.3333ZM22.6666 19.1733C23.4933 19.1733 24.16 19.8533 24.16 20.6667C24.16 21.48 23.48 22.16 22.6666 22.16C21.8533 22.16 21.1733 21.48 21.1733 20.6667C21.1733 19.8533 21.84 19.1733 22.6666 19.1733ZM22.6666 26.3333C21.4266 26.3333 20.3466 25.72 19.68 24.7733C19.7466 23.8133 21.6933 23.3333 22.6666 23.3333C23.64 23.3333 25.5866 23.8133 25.6533 24.7733C24.9866 25.72 23.9066 26.3333 22.6666 26.3333Z"
        fill="#0A2D62"
        fillOpacity="0.77"
      />
    </SvgIcon>
  );
};
