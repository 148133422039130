import { useState, useMemo, createContext, useContext } from 'react';
import { ThemeMode } from '../../themes/types';

interface ModeValueContextType {
  updateModeContext: () => void;
  mode: ThemeMode;
}

export const ModeValueContext = createContext<ModeValueContextType>({
  mode: ThemeMode.Light,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateModeContext: () => {},
});

export const ModeValueProvider = ({
  children,
  initialMode = ThemeMode.Light,
}: {
  children: React.ReactNode;
  initialMode?: ThemeMode;
}) => {
  const [mode, setMode] = useState<ThemeMode>(initialMode);
  const modeContextValue = useMemo(
    () => ({
      updateModeContext: () => {
        setMode((prevMode) => {
          return prevMode === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light;
        });
      },
      mode,
    }),
    [mode]
  );
  return <ModeValueContext.Provider value={modeContextValue}>{children}</ModeValueContext.Provider>;
};

export const useModeValue = () => {
  const context = useContext(ModeValueContext);
  if (context === undefined) {
    throw new Error('useMode must be used within a ModeProvider');
  }
  return context;
};
