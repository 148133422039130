import { styled } from '@novaera/theme-provider';
import { NvTypography } from '../typography';

export const FormHelperTextWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-top: 4px;
  margin-left: 2px;
`;

export const ErrorNvTypographyWrapper = styled(NvTypography)`
  margin-left: 2px;
`;
