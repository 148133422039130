import { SchemaType } from '@novaera/ah-common';
import { NvBox, NvButton, NvCloseIcon, NvFlex, NvOpenInNewIcon, NvPopover } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { isUndefined } from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { CSSProperties, useMemo } from 'react';
import { MemoizedRecordCellBody } from './body';
import { OpenInNewTabBox, OpenInNewTabContainer, RecordCellWrapper } from './styled';
import { RENDER_CELL_BODY_MODE, RecordCellProps } from './types';

const RecordCell: React.FC<React.PropsWithChildren<RecordCellProps>> = ({ id, name, value, type, format }) => {
  const { palette } = useTheme();
  const recordCellPopoverId = useMemo(() => `record-cell-popover-${id}-${name}`, [id, name]);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: recordCellPopoverId,
  });
  const isPopoverOpen = popupState.isOpen;
  const myStyle: CSSProperties = {
    background: palette.nv_neutral[0],
    border: `1px solid ${palette.nv_main[30]}`,
    boxShadow: '0px 0px 0px 1px #EBEEF2, 0px 15px 25px -2px rgba(10, 45, 98, 0.08)',
    borderRadius: 12,
    padding: '12px 8px 12px 12px',
    marginLeft: 8,
    minWidth: 200,
    maxWidth: 600,
    wordBreak: 'break-word',
    overflow: 'auto',
    maxHeight: 500,
  };
  const recordCellPaperProps = {
    style: myStyle,
  };

  const handleRecordCellPopoverClose = () => {
    popupState.close();
  };

  if (isUndefined(value)) {
    return null;
  }

  return (
    <RecordCellWrapper isPopoverOpen={isPopoverOpen}>
      <MemoizedRecordCellBody value={value} type={type} mode={RENDER_CELL_BODY_MODE.CELL} format={format} />
      {type !== SchemaType.boolean && (
        <OpenInNewTabContainer className="open-in-new-tab">
          <OpenInNewTabBox {...bindTrigger(popupState)}>
            <NvOpenInNewIcon sx={{ width: '12px', height: '12px' }} />
          </OpenInNewTabBox>
        </OpenInNewTabContainer>
      )}

      {isPopoverOpen && (
        <NvPopover
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          PaperProps={recordCellPaperProps}
          {...bindPopover(popupState)}
        >
          {
            <NvFlex direction="row" alignItems="flex-start" gap="4px">
              <NvBox flex="1 1 auto">
                <MemoizedRecordCellBody
                  value={value}
                  type={type}
                  mode={RENDER_CELL_BODY_MODE.POPOVER}
                  format={format}
                />
              </NvBox>
              <NvFlex position="sticky" top={0}>
                <NvButton onlyIcon size="small" color="ghost" onClick={handleRecordCellPopoverClose}>
                  <NvCloseIcon />
                </NvButton>
              </NvFlex>
            </NvFlex>
          }
        </NvPopover>
      )}
    </RecordCellWrapper>
  );
};

export const MemoizedRecordCell = React.memo(RecordCell, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
