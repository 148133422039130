import { NvFlex, NvSkeleton } from '@novaera/core';

export const PermissionPolicyDetailLoading = () => (
  <>
    <NvFlex gap="4px" width="100%">
      <NvFlex flexDirection="row" justifyContent="space-between">
        <NvFlex flexDirection="row" gap="12px" alignItems="center">
          <NvSkeleton variant="rectangular" width="40px" height="40px" />
          <NvSkeleton variant="rectangular" width="200px" height="32px" />
        </NvFlex>
        <NvSkeleton variant="rectangular" width="32px" height="32px" />
      </NvFlex>
      <NvSkeleton variant="rectangular" width="100%" height="48px" />
    </NvFlex>
    <NvFlex gap="8px" width="100%">
      <NvSkeleton variant="rectangular" width="100%" height="24px" />
      <NvFlex direction="row" alignItems="center" gap="8px">
        <NvSkeleton variant="rectangular" width="150px" height="40px" />
        <NvSkeleton variant="rectangular" width="150px" height="40px" />
        <NvSkeleton variant="rectangular" width="150px" height="40px" />
      </NvFlex>
    </NvFlex>
    <NvFlex gap="8px" width="100%">
      <NvSkeleton variant="rectangular" width="100%" height="24px" />
      <NvFlex alignItems="center" gap="8px">
        <NvSkeleton variant="rectangular" width="100%" height="24px" />
        <NvSkeleton variant="rectangular" width="100%" height="24px" />
        <NvSkeleton variant="rectangular" width="100%" height="24px" />
      </NvFlex>
    </NvFlex>
  </>
);
