import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const JsonViewWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
`;
