import { cloneElement, FC, isValidElement } from 'react';
import { NvTypography } from '../typography';
import { NoTextDecorationLink, StyledNvChip } from './styled';
import { NvChipLinkProps } from './types';

export const NvChipLink: FC<React.PropsWithChildren<NvChipLinkProps>> = ({ linkProps, ...props }) => {
  const { color, compact, maxWidth, sx, label, icon } = props;
  return (
    <NoTextDecorationLink {...linkProps} onClick={(e) => e.stopPropagation()}>
      <StyledNvChip
        color={color}
        compact={compact}
        className="nv-chip"
        sx={sx}
        maxWidth={maxWidth}
        isNumber={typeof label === 'number'}
      >
        {icon &&
          isValidElement(icon) &&
          cloneElement(icon, {
            ...icon.props,
            sx: compact
              ? { width: '12px', height: '12px', flex: '0 0 auto' }
              : { width: '16px', height: '16px', flex: '0 0 auto' },
          })}
        <NvTypography margin="0px 4px" variant={compact ? 'h7' : 'body1'} flex="1 1 auto" minWidth={0} noWrap>
          {label}
        </NvTypography>
      </StyledNvChip>
    </NoTextDecorationLink>
  );
};
