import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomFolderDeleteIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2077_206)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17ZM14.8284 10.1716C15.2244 10.5675 15.2173 11.1969 14.8284 11.5858L13.4142 13L14.8284 14.4142C15.2244 14.8102 15.2173 15.4395 14.8284 15.8284C14.4324 16.2244 13.8031 16.2173 13.4142 15.8284L12 14.4142L10.5858 15.8284C10.1898 16.2244 9.56048 16.2173 9.17157 15.8284C8.77559 15.4324 8.78266 14.8031 9.17157 14.4142L10.5858 13L9.17157 11.5858C8.77559 11.1898 8.78266 10.5605 9.17157 10.1716C9.56755 9.77559 10.1969 9.78266 10.5858 10.1716L12 11.5858L13.4142 10.1716C13.8102 9.77559 14.4395 9.78266 14.8284 10.1716Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2077_206">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
