import { CatalogRelationship } from '@novaera/actioner-service';
import { NvDivider, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { RelationshipTypeInformation } from '../../../../components/data-model-layout/relationship-right-panel/relationship-type-information';
import { DynamicMaterialIcons } from '../../../../components/dynamic-material-icons';

export const CatalogRelationshipHeader = ({
  item,
  isSelected,
  index,
}: {
  isSelected?: boolean;
  item?: CatalogRelationship;
  index: number;
}) => {
  const { palette } = useTheme();

  return (
    item && (
      <NvFlex direction="row" gap="12px" alignItems="center" width="100%" key={`relationship-header-${index}`}>
        {item.targetEntityType?.iconId && (
          <DynamicMaterialIcons initialValue={item.targetEntityType.iconId}>
            {({ getCurrentIcon }) => {
              const Icon = getCurrentIcon();
              return Icon && <Icon />;
            }}
          </DynamicMaterialIcons>
        )}
        <NvTypography variant="h4" {...(isSelected && { color: palette.nv_main[60] })}>
          {item.targetEntityType?.name}
        </NvTypography>
        <NvDivider
          orientation="vertical"
          sx={{
            height: '12px',
          }}
        />
        <NvFlex>
          <RelationshipTypeInformation fieldsFieldName={'relationshipDefinitions'} selectedFieldIndex={index} />
        </NvFlex>
      </NvFlex>
    )
  );
};
