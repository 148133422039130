import {
  Initial,
  NvAutocomplete,
  NvCloseIcon,
  NvFlex,
  NvImage,
  NvPowerRoundedIcon,
  NvTextField,
  NvTextFieldProps,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { ActionSelectMenu, ActionSelectMenuItem } from '../../../../components/action-select-menu';
import { FormattedConnectionType } from '../types';
import { isConnectionSchemaPredefined } from '../utils';

export const AutoCompleteConnectionType = (props: {
  isLoading: boolean;
  options: FormattedConnectionType[];
  renderInputProps?: NvTextFieldProps;
  disableClearable?: boolean;
  value?: FormattedConnectionType | null;
  onChange?: (event: React.SyntheticEvent, value: FormattedConnectionType | null) => void;
}) => {
  const { options, isLoading, renderInputProps, disableClearable, ...other } = props;
  const theme = useTheme();

  return (
    <NvAutocomplete
      {...other}
      loading={isLoading}
      renderInput={(props) => (
        <NvTextField {...props} placeholder="Select connection type" size="large" {...renderInputProps} />
      )}
      disableClearable={disableClearable}
      options={options}
      getOptionLabel={(option) => option?.name ?? ''}
      PaperComponent={ActionSelectMenu}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <ActionSelectMenuItem {...props}>
          <NvFlex direction="row" alignItems="center" gap="8px">
            {!isConnectionSchemaPredefined(option) ? (
              <NvImage
                imageShape="square"
                src={option.logoUrl}
                FallBack={<Initial size="small" value={option.name} color={theme.palette.nv_neutral[500]} />}
                size="small"
                hasNoElevation
              />
            ) : (
              <NvPowerRoundedIcon fontSize="small" />
            )}
            <NvTypography variant="h4">{option.name}</NvTypography>
          </NvFlex>
        </ActionSelectMenuItem>
      )}
      clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
    />
  );
};
