import { ActionType, ConnectionAction, SetSelectedConnectionPayload, TestConfigurationState } from './types';

export const testConfigurationReducer = (
  state: TestConfigurationState,
  action: ActionType<SetSelectedConnectionPayload>
): TestConfigurationState => {
  switch (action.type) {
    case ConnectionAction.SetSelectedConnection: {
      return {
        ...state,
        selectedConnection: action.payload?.connection,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
