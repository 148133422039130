import { useIntegrationConnectionAuthenticationFieldContext } from '../../../provider';
import { Action } from '../../../provider/reducer/types';

export const useAddFieldButtonController = () => {
  const { dispatch } = useIntegrationConnectionAuthenticationFieldContext();

  const handleButtonClick = () => {
    dispatch({
      type: Action.OpenAddFieldModal,
    });
  };

  return {
    onClick: handleButtonClick,
  };
};
