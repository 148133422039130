import {
  NvBox,
  NvButton,
  NvCircularProgress,
  NvEditIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { ErrorMarker } from '../../../../error-marker';

import { ItemFlex, ItemIconBox } from './styled';
import { ItemProps } from './type';

export const Item: React.FC<React.PropsWithChildren<ItemProps>> = ({
  label,
  variant,
  actionButtons,
  onItemClick,
  onEditClick,
  isSelected = false,
  isRunning = false,
  hasError,
}) => {
  const { palette } = useTheme();

  return (
    <ItemFlex
      className={classNames({ isSelected: isSelected, isRunning: isRunning })}
      {...(!isSelected && { onClick: onItemClick })}
    >
      <NvFlex flexDirection="row" alignItems="center" flex="1 1 auto" marginRight="8px" minWidth={0}>
        <NvTypography variant="h4" noWrap marginRight="4px">
          {label}
        </NvTypography>
        {hasError && (
          <NvBox marginTop="4px" marginRight="4px">
            <ErrorMarker variant="default" />
          </NvBox>
        )}
        <NvBox width="auto" marginLeft={1} sx={{ flex: '0 0 auto' }}>
          {isRunning ? (
            <ItemIconBox>
              <NvCircularProgress size={15} />
            </ItemIconBox>
          ) : (
            <NvTypography variant="c2" noWrap color={palette.nv_accent[60]}>
              {variant}
            </NvTypography>
          )}
        </NvBox>
      </NvFlex>

      <NvFlex direction="row" alignItems="flex-start" spacing={0.5} sx={{ zIndex: 15 }}>
        {onEditClick && (
          <NvButton className="edit-button" onlyIcon size="small" color="secondary">
            <NvEditIcon onClick={onEditClick} />
          </NvButton>
        )}
        {actionButtons && (
          <NvMenuWithItems
            triggerButton={{
              content: <NvMoreHorizIcon />,
              props: { onlyIcon: true, size: 'small', color: 'secondary' },
            }}
            menuItems={actionButtons}
          ></NvMenuWithItems>
        )}
      </NvFlex>
    </ItemFlex>
  );
};
