import { InputParameter, UIComponentType } from '@novaera/actioner-service';
import { ADD_NEW_INPUT_MENU_ITEMS } from '../../../utils';

export const getPropertyTitle = (selectedInputParameter: InputParameter) => {
  const flattedMenuItems = ADD_NEW_INPUT_MENU_ITEMS.map((menuItem) =>
    menuItem.items ? menuItem.items : menuItem
  ).flat();

  return flattedMenuItems.find((menuItems) => {
    const { uiComponent, schema } = selectedInputParameter;

    if (uiComponent.type === UIComponentType.TEXT_FIELD && uiComponent.multiLine) {
      return menuItems.value?.component.type === uiComponent.type && menuItems.value.component.multiLine;
    } else {
      return (
        menuItems?.value?.component.type === uiComponent?.type &&
        (uiComponent.type !== UIComponentType.SINGLE_SELECT ? menuItems?.value?.schema?.type === schema?.type : true)
      );
    }
  })?.value?.propertyTitle;
};
