import { InternalAxiosRequestConfig } from '@novaera/core';
import { getSubdomain } from '@novaera/utils';
import { AUTHORIZATION_HEADER, SUBDOMAIN_HEADER, TokenState } from '../../common/constants';

import { ServiceError, ServiceErrorCode } from '@novaera/ah-common';
import { LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import Cookies from 'js-cookie';

class RequestInterceptor {
  private request: InternalAxiosRequestConfig;

  constructor(request: InternalAxiosRequestConfig) {
    this.request = request;
  }

  getRequest(): InternalAxiosRequestConfig {
    return this.request;
  }

  shouldSendRequest() {
    if (TokenState.expired && !TokenState.isGettingNewToken) {
      return this;
    } else if (!TokenState.expired) {
      return this;
    }
    throw new ServiceError({
      code: ServiceErrorCode.ACCESS_TOKEN_REFRESH_PROGRESS,
      message: 'Access token to be refreshed is in progress',
    });
  }

  addAuthHeader() {
    const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);
    if (this.request.headers && accessToken) {
      this.request.headers[AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;
    }
    return this;
  }
  addSubDomainHeader() {
    if (this.request.headers && Boolean(getSubdomain())) {
      this.request.headers[SUBDOMAIN_HEADER] = getSubdomain();
    }
    return this;
  }
}

export { RequestInterceptor };
