import { NvBox, NvTypography } from '@novaera/core';
import { SideMenuItemsEmptyStateProps } from './types';

export const SideMenuItemsEmptyState: React.FC<React.PropsWithChildren<SideMenuItemsEmptyStateProps>> = ({ label }) => (
  <NvBox padding="12px 4px 12px 8px">
    <NvTypography variant="body2" textColor="subtle">
      {label}
    </NvTypography>
  </NvBox>
);
