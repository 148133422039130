import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { SearchUserAppResponse } from '../use-search-user-apps/types';
import { DeleteUserAppParams } from './types';

const deleteUserApp = async ({ appId }: DeleteUserAppParams) => {
  const result = await NvAxios.delete(`${USER_APP_ROOT_PATH}/${appId}`);
  return result?.data;
};

export const useDeleteUserApp = () => {
  const cache = useQueryClient();
  return useMutation(deleteUserApp, {
    onSuccess: (_, { appId }) => {
      cache.setQueriesData<InfiniteData<SearchUserAppResponse>>(QUERY_KEYS_USER_APP.infiniteSearch(), (old) => {
        return old
          ? {
              ...old,
              pages: old.pages.map((page) => {
                return {
                  ...page,
                  apps: page.apps.filter((a) => a.id !== appId),
                };
              }),
            }
          : old;
      });
      cache.setQueriesData<SearchUserAppResponse>(QUERY_KEYS_USER_APP.search(), (old) => {
        if (old) {
          return { ...old, apps: old.apps.filter((a) => a.id !== appId) };
        }
        return old;
      });
    },
  });
};
