import { useLocation } from '@novaera/route';
import { useEffect, useMemo, useState } from 'react';
import {
  SlackAuthAnonymousAccessWorkspaceSelectionResponse,
  WorkspaceSelectionWorkspaceInfo,
} from '../../../../../services/slack/use-slack-auth-access/types';
import { useJoinWorkspace } from '../../../controllers/use-join-workspace';

export const useSlackWorkspace = () => {
  const location = useLocation();

  const [slackResponse, setSlackResponse] = useState<SlackAuthAnonymousAccessWorkspaceSelectionResponse>();
  const { joinWorkspace } = useJoinWorkspace();
  const [loadingList, setLoadingList] = useState<string[]>([]);

  useEffect(() => {
    setSlackResponse(location.state as SlackAuthAnonymousAccessWorkspaceSelectionResponse);
  }, [location.state]);

  const joinedWorkspaces: WorkspaceSelectionWorkspaceInfo[] = useMemo(
    () => slackResponse?.workspaceInfo?.filter((workspace) => workspace.member) ?? [],
    [slackResponse]
  );

  const notJoinedWorkspaces: WorkspaceSelectionWorkspaceInfo[] = useMemo(
    () => slackResponse?.workspaceInfo?.filter((workspace) => !workspace.member) ?? [],
    [slackResponse]
  );

  const handleWorkspaceItemClicked = async (workspaceId: string, workspaceName: string) => {
    setLoadingList([...loadingList, workspaceId]);
    await joinWorkspace({ workspaceId, workspaceName });
    setLoadingList(loadingList.filter((id) => id !== workspaceId));
  };

  return {
    joinedWorkspaces,
    notJoinedWorkspaces,
    loadingList,
    onWorkspaceItemClicked: handleWorkspaceItemClicked,
  };
};
