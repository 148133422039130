import {
  NvButton,
  NvConditionalRender,
  NvFlex,
  NvFullscreenRounded,
  NvSkeleton,
  NvSplitPane,
  SplitPanelItem,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { DynamicInputWrapper, TestActionProvider } from '../components';
import { useTestConfigurationProvider } from '../components/test-configuration-bar/providers/test-configuration';
import { ErrorBoundary } from '../error-boundary';
import { FormIdentifierProvider } from '../providers/form-identifier-provider';
import { useActionDesigner } from './controller/use-action-designer';
import { FunctionsProvider } from './providers/functions-provider';
import { InputValuesProvider } from './providers/input-values';
import { InputOptionsWrapper } from './providers/inputs-options-wrapper';
import { RequestProvider } from './providers/request-provider';
import { SearchAsYouTypeValuesProvider } from './providers/search-as-you-type-values';
import {
  ActionDesignerWrapper,
  BodyWrapper,
  Divider,
  DividerIconBox,
  DividerIconItem,
  StyledTabsWrapper,
} from './styled';

export const ActionDesignerV1: React.FC<React.PropsWithChildren<{ integrationVersion?: number }>> = ({
  integrationVersion,
}) => {
  const { actionId, integrationId } = useParams();

  const {
    collapsedIndex,
    isExpanded,
    activeTabIndex,
    tabs,
    bottomTabs,
    isReady,
    onCollapseClicked,
    onExpendClicked,
    handleTopTabsOnChange,
    handleBottomTabsOnChange,
    setIsExpanded,
    setCollapsedIndex,
    activeBottomTabIndex,
  } = useActionDesigner({ integrationVersion });
  const {
    state: { selectedConnection },
  } = useTestConfigurationProvider();

  return (
    <NvFlex padding="16px 16px 0 16px" flex="1 1 0" height="100%" minHeight={0}>
      <ErrorBoundary>
        <InputValuesProvider>
          <SearchAsYouTypeValuesProvider>
            <ActionDesignerWrapper key={`integration-action-detail_${actionId}`} id="layout-body">
              <NvConditionalRender when={() => !isReady}>
                <NvFlex margin={3}>
                  <NvSkeleton height="32px" width="60%" />
                  <NvSkeleton height="32px" width="80%" />
                  <NvSkeleton height="32px" width="40%" />
                </NvFlex>
              </NvConditionalRender>
              <NvConditionalRender when={() => isReady}>
                <FormIdentifierProvider>
                  <DynamicInputWrapper
                    type="in-integration"
                    connectionId={selectedConnection?.id}
                    actionId={actionId}
                    integrationId={integrationId}
                    versionNumber={integrationVersion}
                  >
                    <InputOptionsWrapper
                      type="in-integration"
                      actionId={actionId}
                      integrationId={integrationId}
                      connectionId={selectedConnection?.id}
                    >
                      <NvSplitPane
                        direction="vertical"
                        Divider={
                          <Divider>
                            <DividerIconBox className="DividerIconBox">
                              <DividerIconItem className="DividerIconItem" />
                              <DividerIconItem className="DividerIconItem" />
                              <DividerIconItem className="DividerIconItem" />
                              <DividerIconItem className="DividerIconItem" />
                              <DividerIconItem className="DividerIconItem" />
                              <DividerIconItem className="DividerIconItem" />
                            </DividerIconBox>
                          </Divider>
                        }
                        onSizeChange={() => {
                          if (collapsedIndex !== undefined || isExpanded) {
                            setCollapsedIndex(undefined);
                            setIsExpanded(false);
                          }
                        }}
                        onCollapsedPanel={(index) => {
                          setCollapsedIndex(index);
                        }}
                      >
                        <SplitPanelItem
                          collapsedSize="40px"
                          isCollapsed={collapsedIndex === 0}
                          isExpanded={isExpanded}
                          initialSize="70%"
                        >
                          <BodyWrapper isCollapsed={collapsedIndex === 0}>
                            <TestActionProvider>
                              <StyledTabsWrapper
                                isCollapsed={collapsedIndex === 0}
                                tabs={tabs}
                                onActiveTabChanged={handleTopTabsOnChange}
                                defaultActiveTabIndex={activeTabIndex}
                                focusIndex={activeTabIndex}
                                ActionComponent={
                                  (activeTabIndex === 0 || activeTabIndex === 1) && (
                                    <NvFlex direction="row" spacing={1} height="100%" alignItems="center">
                                      <NvFlex>
                                        <NvButton size="small" color="secondary" onClick={() => onCollapseClicked(0)}>
                                          {collapsedIndex === 0
                                            ? `Show ${
                                                activeTabIndex === 0 ? 'input' : activeTabIndex === 1 ? 'response' : ''
                                              }`
                                            : `Hide ${
                                                activeTabIndex === 0 ? 'input' : activeTabIndex === 1 ? 'response' : ''
                                              }`}
                                        </NvButton>
                                      </NvFlex>
                                      <NvFlex>
                                        <NvButton
                                          color="secondary"
                                          size="small"
                                          onlyIcon
                                          onClick={() => onExpendClicked(0)}
                                        >
                                          <NvFullscreenRounded />
                                        </NvButton>
                                      </NvFlex>
                                    </NvFlex>
                                  )
                                }
                              />
                            </TestActionProvider>
                          </BodyWrapper>
                        </SplitPanelItem>

                        <SplitPanelItem
                          collapsedSize="40px"
                          isCollapsed={collapsedIndex === 1}
                          isExpanded={isExpanded}
                          initialSize="30%"
                        >
                          <FunctionsProvider>
                            <RequestProvider>
                              <BodyWrapper isCollapsed={collapsedIndex === 1} isLastItem>
                                <StyledTabsWrapper
                                  isCollapsed={collapsedIndex === 1}
                                  tabs={bottomTabs}
                                  onActiveTabChanged={handleBottomTabsOnChange}
                                  defaultActiveTabIndex={activeBottomTabIndex}
                                  ActionComponent={
                                    (activeTabIndex === 0 || activeTabIndex === 1) && (
                                      <NvFlex direction="row" spacing={1} height="100%" alignItems="center">
                                        <NvButton color="secondary" size="small" onClick={() => onCollapseClicked(1)}>
                                          {collapsedIndex === 1
                                            ? `Show ${activeBottomTabIndex === 0 ? 'requests' : 'functions'}`
                                            : `Hide ${activeBottomTabIndex === 0 ? 'requests' : 'functions'}`}
                                        </NvButton>
                                      </NvFlex>
                                    )
                                  }
                                />
                              </BodyWrapper>
                            </RequestProvider>
                          </FunctionsProvider>
                        </SplitPanelItem>
                      </NvSplitPane>
                    </InputOptionsWrapper>
                  </DynamicInputWrapper>
                </FormIdentifierProvider>
              </NvConditionalRender>
            </ActionDesignerWrapper>
          </SearchAsYouTypeValuesProvider>
        </InputValuesProvider>
      </ErrorBoundary>
    </NvFlex>
  );
};
