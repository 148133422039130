import { assert } from '@novaera/utils';
import dagre from 'dagre';
import { camelCase } from 'lodash';
import { NovaeraEdge, NovaeraNode, NovaeraNodeWithPosition } from '../../flow/types';
import { GetNewAliasReturnType, NvGraph } from '../types';

export type ExtraData<NodeType extends string = string> = {
  type: NodeType;
  name: string;
  alias: string;
  marginX?: number;
  marginY?: number;
  nextNodeAlias?: string;
  parent?: string;
  selected?: boolean;
  disabled?: boolean;
};

export class DagreNovaeraGraph<NodeType extends string = string> implements NvGraph<NodeType> {
  readonly g: dagre.graphlib.Graph<ExtraData<NodeType>>;
  humanReadableTypeMap: Record<NodeType, string>;

  constructor(initialNode: NovaeraNode<NodeType>, offsetX: number, humanReadableTypeMap: Record<NodeType, string>) {
    this.g = new dagre.graphlib.Graph();
    this.g.setGraph({
      nodesep: 75,
      ranksep: 75,
      edgesep: 350,
      marginx: offsetX,
      rankdir: 'TB',
    });

    // Default to assigning a new object as a label for each new edge.
    this.g.setDefaultEdgeLabel(function () {
      return {};
    });

    this.addNode(initialNode);
    this.refreshLayout();
    this.humanReadableTypeMap = humanReadableTypeMap;
  }

  edge: (params: Pick<NovaeraEdge<string>, 'sourceId' | 'targetId'>) => NovaeraEdge<string> = (params) => {
    const { type, ...rest } = this.g.edge(params.sourceId, params.targetId);
    return {
      id: `${params.sourceId}-${params.targetId}`,
      sourceId: params.sourceId,
      targetId: params.targetId,
      extraData: { type, ...rest },
    };
  };

  getNewAlias: (type: NodeType, skipCheck?: string[]) => GetNewAliasReturnType = (type, skipCheck) => {
    const currentNodes = this.nodes();

    const nodesWithType = currentNodes.filter((n) => n.type === type);
    let currentCount = nodesWithType.length;

    const typeString = this.humanReadableTypeMap[type];
    let newAlias = camelCase(`${typeString}${currentCount}`);
    let newName = `${typeString} ${currentCount}`;
    let isExist = currentNodes.some((n) => n.alias === newAlias);
    if (skipCheck?.includes(newAlias)) {
      isExist = true;
    }
    // to prevent infinite loop
    let tryCount = 0;
    while (isExist && tryCount < 500) {
      currentCount++;
      tryCount++;
      const newAliasTemp = camelCase(`${typeString}${currentCount}`);
      newAlias = newAliasTemp;
      newName = `${typeString} ${currentCount}`;
      isExist = currentNodes.some((n) => n.alias === newAliasTemp);
      if (skipCheck?.includes(newAlias)) {
        isExist = true;
      }
    }

    return { newAlias: newAlias, newName };
  };

  removeEdge: (params: Pick<NovaeraEdge, 'sourceId' | 'targetId'>) => void = (params) => {
    this.g.removeEdge(params.sourceId, params.targetId);
  };

  getPredecessors: (params: {
    nodeId: string;
    filterCondition?: (node: NovaeraNodeWithPosition<NodeType>) => boolean;
  }) => NovaeraNodeWithPosition<NodeType>[] | undefined = ({ filterCondition, nodeId }) => {
    if (filterCondition) {
      const result = this.g
        .predecessors(nodeId)
        ?.filter((n) => {
          return filterCondition(this.node(n.name));
        })
        .map((n) => {
          return this.node(n.name);
        });
      return result;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = this.g.predecessors(nodeId)?.map((n: any) => {
        return this.node(n);
      });
      return result;
    }
  };

  getSuccessors: (params: {
    nodeId: string;
    filterCondition?: (node: NovaeraNodeWithPosition<NodeType>) => boolean;
  }) => NovaeraNodeWithPosition<NodeType>[] | undefined = ({ filterCondition, nodeId }) => {
    if (filterCondition) {
      const result = this.g
        .successors(nodeId)
        ?.filter((n) => {
          return filterCondition(this.node(n.name));
        })
        .map((n) => {
          return this.node(n.name);
        });
      return result;
    } else {
      const result = this.g.successors(nodeId)?.map((n) => {
        assert(typeof n === 'string', new Error('n is string'), 'ERROR');
        return this.node(n);
      });
      return result;
    }
  };

  getLeaves: (params: {
    isLeafFn?: (node: NovaeraNodeWithPosition<NodeType>) => boolean;
  }) => NovaeraNodeWithPosition<NodeType>[] = ({ isLeafFn }) => {
    return this.nodes().filter((n) => {
      if (isLeafFn) {
        return isLeafFn(n);
      } else {
        return n.isLeaf;
      }
    });
  };

  initialize: (params: { nodes: NovaeraNode<NodeType>[]; edges: NovaeraEdge[] }) => void = ({ nodes, edges }) => {
    this.g.nodes().forEach((n) => {
      this.g.removeNode(n);
    });

    this.g.edges().forEach(({ v, w }) => {
      this.g.removeEdge(v, w);
    });

    nodes.forEach((n) => {
      this.addNode(n);
    });

    edges.forEach((e) => {
      this.addEdge(e);
    });
  };

  removeNode: (nodeId: string) => void = (nodeId) => {
    this.g.removeNode(nodeId);
  };

  insertNode = ({
    oldEdge,
    node,
    newEdges,
  }: {
    oldEdge: Pick<NovaeraEdge, 'sourceId' | 'targetId'>;
    node: NovaeraNode<NodeType>;
    newEdges: NovaeraEdge[];
  }) => {
    this.g.removeEdge(oldEdge.sourceId, oldEdge.targetId);
    this.addNode(node);

    newEdges.forEach((n) => {
      this.addEdge(n);
    });
  };

  outEdges = (nodeId: string) => {
    const es = this.g.outEdges(nodeId);

    return es?.map((e) => {
      const { v, w, ...rest } = e;
      const extraData = this.g.edge(v, w);
      const retVal: NovaeraEdge = {
        id: `${v}-${w}`,
        sourceId: v,
        targetId: w,
        extraData: { type: extraData.type, ...rest, ...extraData },
      };
      return retVal;
    });
  };

  node = (nodeId: string) => {
    const n = this.g.node(nodeId);
    if (n?.label) {
      // todo change here by taking the rest and removing  unnecessary fields.
      const { width, height, type, alias, marginY, marginX, nextNodeAlias, parent, selected, disabled } = n;
      const outEdges = this.g.outEdges(nodeId);
      const retVal: NovaeraNodeWithPosition<NodeType> = {
        id: nodeId,
        position: { x: n.x, y: n.y },
        isLeaf: outEdges ? outEdges.length === 0 : false,
        name: n.label,
        width,
        height,
        type,
        alias,
        marginX,
        marginY,
        nextNodeAlias,
        parent,
        selected,
        disabled,
      };
      return retVal;
    } else {
      throw new Error('label is empty for:' + nodeId);
    }
  };

  getChildren = (nodeId: string) => {
    const node = this.node(nodeId);

    if (node.type !== 'branchJunction' && node.type !== 'loop' && node.type !== 'workflowCondition') {
      return;
    }

    const nodeIndex = this.nodes().findIndex((node) => node.alias === nodeId);
    const endNodeAlias = nodeIndex <= this.nodes().length ? this.nodes()[nodeIndex + 1] : undefined; // means it is leaf

    const result: string[] = [];
    const graph = this.g;

    function bfs(root: string) {
      const successors: string[] = graph.successors(root) as unknown as string[];
      assert(successors !== undefined, new Error('successors should be more than 0'), 'ERROR');

      while (successors.length > 0) {
        const successor = successors.shift();
        if (successor === undefined || successor === endNodeAlias?.alias || result.includes(successor)) {
          continue;
        }
        result.push(successor);
        const childSuccessors = graph.successors(successor);
        if (childSuccessors) {
          for (const child of childSuccessors) {
            successors.push(child as unknown as string);
          }
        }
      }
    }

    bfs(nodeId);

    return result;
  };

  nodes = () => {
    return this.g.nodes().map((nodeId) => {
      const n = this.g.node(nodeId);
      if (n?.label) {
        const { label, x, y, width, height, alias, marginY, marginX, type, nextNodeAlias, selected, disabled } = n;
        const outEdges = this.g.outEdges(nodeId);
        const retVal: NovaeraNodeWithPosition<NodeType> = {
          id: nodeId,
          position: { x, y },
          name: label,
          width,
          height,
          type,
          isLeaf: outEdges ? outEdges.length === 0 : false,
          alias,
          marginX,
          marginY,
          nextNodeAlias,
          selected,
          disabled,
        };
        return retVal;
      } else {
        throw new Error('label is empty for: ' + nodeId);
      }
    });
  };

  edges = () => {
    return this.g.edges().map((e) => {
      const { v, w, name, ...rest } = e;
      const extraData = this.g.edge(v, w);
      return {
        id: `${v}-${w}`,
        sourceId: v,
        targetId: w,
        extraData: { type: extraData.type, ...rest, ...extraData },
      };
    });
  };

  addNode = ({ id, name, ...rest }: NovaeraNode<NodeType> | NovaeraNodeWithPosition<NodeType>) => {
    if ('position' in rest) {
      const { position, ...propsWithoutPosition } = rest;
      const { x, y } = position;
      this.g.setNode(id, { label: name, ...propsWithoutPosition, name, x, y });
    } else {
      this.g.setNode(id, { label: name, ...rest, name });
    }
  };

  public addEdge = ({ sourceId, targetId, extraData }: NovaeraEdge) => {
    this.g.setEdge({ v: sourceId, w: targetId }, { ...extraData });
  };

  refreshLayout = () => {
    dagre.layout(this.g);
  };
}
