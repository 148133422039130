import { useGetIntegrationTestResult } from '@novaera/actioner-service';
import { NvFlex, NvReceiptLongIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC, useMemo } from 'react';
import { OutputItem } from '../../output-item';
import { PropertyPanelListHeader, PropertyPanelSimpleSection } from '../../property-panel';
import { useTestActionContext } from '../provider/test-action-provider';

export const TestActionResult: FC<React.PropsWithChildren<{ sampleResponse?: string; tooltip?: string }>> = ({
  sampleResponse,
  tooltip,
}) => {
  const theme = useTheme();

  const { testActionParams } = useTestActionContext();
  const { data: integrationTestResult } = useGetIntegrationTestResult({ ...testActionParams });

  const testResult = useMemo(() => {
    let sampleResponseJson;
    try {
      if (sampleResponse) {
        sampleResponseJson = JSON.parse(sampleResponse);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    return integrationTestResult?.successResponse || integrationTestResult?.errorContext || sampleResponseJson;
  }, [integrationTestResult?.errorContext, integrationTestResult?.successResponse, sampleResponse]);

  const isTestResultFailed = Boolean(integrationTestResult?.errorContext);

  return (
    <>
      <PropertyPanelListHeader title="Response" tooltip={tooltip} />
      <PropertyPanelSimpleSection>
        {testResult ? (
          <OutputItem content={testResult} isTestResultFailed={isTestResultFailed} />
        ) : (
          <NvFlex direction="row" alignItems="center" gap="8px">
            <NvReceiptLongIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[60]} />
            <NvTypography variant="body2" textColor="ghost">
              {/* Test the action to see the response. */}
              There is no response.
            </NvTypography>
          </NvFlex>
        )}
      </PropertyPanelSimpleSection>
    </>
  );
};
