import { NvAxios, useQuery } from '@novaera/core';
import { SlackUserMappingItemType } from '../../../../components/slack-user-mapping-item/types';

const getWorkspaceSummary = async (workspaceId: string) => {
  const result = await NvAxios.get<SlackUserMappingItemType[]>(
    `v1/slack/connections/workspaces/${workspaceId}/summary`
  );
  return result?.data;
};

export const useGetWorkspaceSummary = ({ workspaceId, enabled }: { workspaceId: string; enabled: boolean }) =>
  useQuery<SlackUserMappingItemType[]>(['getWorkspaceSummary', workspaceId], () => getWorkspaceSummary(workspaceId), {
    enabled,
  });
