import { PermissionPolicySummary } from '@novaera/actioner-service';
import {
  NvDialogModal,
  NvDialogModalProps,
  NvField,
  NvFlex,
  NvTagsAutoComplete,
  NvTextArea,
  NvTextField,
  isMaxLength,
  isRequired,
} from '@novaera/core';

export const CreateOrUpdatePermissionPolicyModal = ({
  onFormSubmit,
  open,
  onCloseButtonClicked,
  Header,
  primaryButtonText,
  modalIcon,
  formProps,
  isLoading,
}: Pick<
  NvDialogModalProps<Omit<PermissionPolicySummary, 'id'>>,
  | 'onFormSubmit'
  | 'open'
  | 'onCloseButtonClicked'
  | 'Header'
  | 'primaryButtonText'
  | 'modalIcon'
  | 'formProps'
  | 'isLoading'
>) => (
  <NvDialogModal<Omit<PermissionPolicySummary, 'id'>>
    isLoading={isLoading}
    formProps={formProps}
    onFormSubmit={onFormSubmit}
    maxWidth="sm"
    fullWidth
    open={open}
    onCloseButtonClicked={onCloseButtonClicked}
    Header={Header}
    primaryButtonText={primaryButtonText}
    modalIcon={modalIcon}
    Body={
      <NvFlex gap="16px">
        <NvField
          name="name"
          labelText="Name"
          labelVariant="h5"
          direction="label-on-top"
          validators={[isRequired(), isMaxLength({ length: 100 })]}
          showErrorMessageOnlyWhenBlur
          hasRequiredIndicator
          component={<NvTextField placeholder="Write permission name" />}
        />
        <NvField
          name="description"
          labelText="Description"
          labelVariant="h5"
          direction="label-on-top"
          defaultValue={''}
          parse={(v) => v ?? ''}
          component={<NvTextArea placeholder="Write description" />}
          validators={[
            isMaxLength({
              length: 3000,
            }),
          ]}
        />
        <NvField
          direction="label-on-top"
          name="tags"
          labelText="Tags"
          labelVariant="h5"
          isAutoComplete
          defaultValue={[]}
          component={<NvTagsAutoComplete placeholder="Enter tags" />}
        />
      </NvFlex>
    }
  />
);
