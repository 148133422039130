import { Vertices } from '@novaera/actioner-service';
import { WorkflowComponent } from '../../../use-novaera-flow/create-node-parts/types';

export const connectToWorkflowNodes = ({
  root,
  workflowVertices,
  nextNodeAlias,
  branches,
}: WorkflowComponent & { workflowVertices: Vertices; nextNodeAlias: string | undefined }) => {
  workflowVertices = [
    ...workflowVertices,
    {
      ...root,
      nextAlias: nextNodeAlias,
    },
    ...(branches ?? []),
  ];

  return workflowVertices;
};
