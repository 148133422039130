import { assert } from '@novaera/utils';
import { CloneParams, CloneResponse } from '../types';
import { useAppClone } from '../use-app-clone';
import { useDataModelClone } from '../use-data-model-clone';
import { useWorkflowClone } from '../use-workflow-clone';

export const useCloneService = ({ onSuccess }: { onSuccess: (params: CloneResponse) => void }) => {
  const appClone = useAppClone();
  const dataModelClone = useDataModelClone();
  const workflowClone = useWorkflowClone();

  const handleClone = (params: CloneParams) => {
    if (params.type === 'apps') {
      return appClone.mutateAsync({
        ...params,
        onSuccess,
      });
    } else if (params.type === 'data-models') {
      return dataModelClone.mutateAsync({ ...params, onSuccess });
    } else if (params.type === 'workflows') {
      return workflowClone.mutateAsync({ ...params, onSuccess });
    } else {
      assert(false, new Error('Invalid clone type'));
    }
  };

  return {
    clone: handleClone,
    isLoading: appClone.isLoading || dataModelClone.isLoading || workflowClone.isLoading,
  };
};
