import classnames from 'classnames';
import React, { useMemo } from 'react';

import { Initial, NvArrowForwardIcon, NvChip, NvFlex, NvImage, NvSkeleton, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { StyledWorkspaceItem } from './styled';

import { BG_COLORS_OF_INITIAL_COMPONENT } from '@novaera/constants';
import { WorkListItemProps } from './types';

export const WorkspaceListItem: React.FC<React.PropsWithChildren<WorkListItemProps>> = ({
  displayName,
  subdomain,
  isPreview,
  isDisabled,
  logoUrl,
  onClick,
  IndicatorComponent,
  isLoading,
  isSuspended,
}) => {
  const { palette } = useTheme();

  const handleWorkspaceItemClick = () => {
    onClick?.({
      subdomain,
      displayName,
    });
  };

  const color = useMemo(
    () =>
      BG_COLORS_OF_INITIAL_COMPONENT[
        //generate a random index for the color array with the length of the array
        Math.floor(Math.random() * BG_COLORS_OF_INITIAL_COMPONENT.length)
      ],
    []
  );

  return (
    <StyledWorkspaceItem
      isDisabled={isDisabled}
      isPreview={isPreview}
      {...(!isPreview && !isSuspended && { onClick: handleWorkspaceItemClick })}
      className={classnames({
        'is-preview': isSuspended || isPreview,
        'has-no-click': isSuspended || onClick === undefined,
      })}
    >
      <NvFlex flexGrow={1} width="100%" direction="row" columnGap="12px">
        <NvFlex justifyContent={'center'}>
          <NvImage
            FallBack={<Initial color={color} value={displayName} size="medium" />}
            imageShape="square"
            src={logoUrl}
          />
        </NvFlex>
        <NvFlex justifyContent={'center'}>
          <NvTypography variant="h3">{displayName}</NvTypography>
        </NvFlex>
      </NvFlex>
      {!isPreview && !isSuspended && !isLoading && !IndicatorComponent && (
        <NvArrowForwardIcon
          fontSize="small"
          style={{
            color: palette.nv_neutral_alpha[60],
          }}
        />
      )}
      {isSuspended && <NvChip compact label="SUSPENDED" />}
      {!isPreview && !isLoading && !isSuspended && IndicatorComponent && IndicatorComponent}
      {isLoading && <NvSkeleton width="40px" />}
    </StyledWorkspaceItem>
  );
};
