import { NvAxios, useMutation } from '@novaera/core';

const logout = async () => {
  const result = await NvAxios.post<{
    redirectUri: string;
  }>('/v1/auth/logout');
  return result.data;
};

export const useLogout = () => {
  return useMutation(() => logout());
};
