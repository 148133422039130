import { DataModel } from '@novaera/actioner-service';

import { DataModelHeader } from './data-model-header';

import { DataModelLayout } from '../../../../../components/data-model-layout';
import { FIELD_TYPE_SCHEMAS, MENU_ITEMS } from '../../../../../components/data-model-layout/constants';
import { useTableDetail } from './use-table-detail';

export const TableDetail = ({ dataModel }: { dataModel: DataModel }) => {
  const { selectedFieldIndex, setSelectedFieldIndex, fieldMenus } = useTableDetail();

  return (
    <DataModelLayout
      hasSwitchedMode={false}
      HeaderComponent={<DataModelHeader />}
      fieldMenus={fieldMenus}
      selectedFieldIndex={selectedFieldIndex}
      setSelectedFieldIndex={setSelectedFieldIndex}
      fields={dataModel.fields}
      fieldTypeSchemas={FIELD_TYPE_SCHEMAS}
      fieldMenuItems={MENU_ITEMS}
      fieldsFieldName={'fields'}
      modalType={'table'}
    />
  );
};
