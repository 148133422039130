import { FC, useEffect } from 'react';
import './style.css';

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  InsertImage,
  InsertThematicBreak,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import { NvDivider } from '../divider';
import { StyledEditorWrapper, ToolbarWrapper } from './styled';
import { WysiwygMarkdownProps } from './types';

export const WysiwygMarkdown: FC<WysiwygMarkdownProps> = ({ value, onChange, markdownEditorRef }) => {
  useEffect(() => {
    const markdownText = markdownEditorRef.current?.getMarkdown();
    if (markdownText !== value) {
      markdownEditorRef.current?.setMarkdown(value);
    }
  }, [markdownEditorRef, value]);
  return (
    <StyledEditorWrapper>
      <MDXEditor
        autoFocus
        contentEditableClassName="nv-markdown-editor"
        className="nv-editor"
        ref={markdownEditorRef}
        markdown={value ?? ''}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        plugins={[
          toolbarPlugin({
            toolbarContents: () => (
              <ToolbarWrapper>
                <UndoRedo />
                <NvDivider orientation="vertical" sx={{ height: '16px' }} />
                <BlockTypeSelect />
                <NvDivider orientation="vertical" sx={{ height: '16px' }} />
                <BoldItalicUnderlineToggles />
                <NvDivider orientation="vertical" sx={{ height: '16px' }} />
                <ListsToggle />
                <NvDivider orientation="vertical" sx={{ height: '16px' }} />
                <CreateLink />
                <InsertImage />
                <InsertThematicBreak />
              </ToolbarWrapper>
            ),
          }),
          headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }),
          imagePlugin(),
          listsPlugin(),
          thematicBreakPlugin(),
          linkDialogPlugin(),
          linkPlugin(),
        ]}
      />
    </StyledEditorWrapper>
  );
};
