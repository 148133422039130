import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { DOCUMENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DOCS } from '../keys';
import { ListDocsParams, ListDocsResponse } from './types';

const listDocs = async ({ appId, queryParams }: ListDocsParams) => {
  const result = await NvAxios.get<ListDocsResponse>(`${DOCUMENTS_ROOT_PATH(appId)}`, {
    params: {
      ...(queryParams?.pageKey ? { pageKey: queryParams?.pageKey } : {}),
      namePrefix: queryParams?.namePrefix,
    },
  });
  return result?.data;
};

export const useListDocs = (param: ListDocsParams) => {
  return useInfiniteQuery<ListDocsResponse>(
    QUERY_KEYS_DOCS.list(param.appId, param.queryParams?.namePrefix),
    ({ pageParam }) => {
      return listDocs({
        ...param,
        queryParams: {
          ...param.queryParams,
          pageKey: pageParam,
        },
      });
    },
    {
      enabled: !!param.appId,
      getNextPageParam: (lastPage) => lastPage?.pageKey,
    }
  );
};
