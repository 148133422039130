import { SvgIcon, SvgIconProps } from '@mui/material';

export const NvActionerIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.4807 10.6886L14.3878 11.897L13.3954 10.1779C12.7751 9.10409 11.2251 9.10409 10.6049 10.1779L9.61239 11.897L7.51953 10.6886L10.3253 4.21669C11.0697 2.92777 12.9305 2.92777 13.6749 4.21668L16.4807 10.6886Z"
        fill="#6B91E0"
      />
      <path
        d="M11.1944 17.4281L11.1944 19.8448L4.18597 20.6504C2.69807 20.6504 1.76764 19.0392 2.51199 17.7503L6.71387 12.0839L8.80672 13.2923L7.81426 15.0114C7.19397 16.086 7.96893 17.4281 9.20951 17.4281L11.1944 17.4281Z"
        fill="#F76D6D"
      />
      <path
        d="M19.8133 20.6504L12.8057 19.8448L12.8057 17.4281L14.7906 17.4281C16.0312 17.4281 16.8061 16.086 16.1858 15.0114L15.1934 13.2923L17.2862 12.0839L21.4881 17.7503C22.2325 19.0392 21.302 20.6504 19.8133 20.6504Z"
        fill="#FAB701"
      />
    </SvgIcon>
  );
};
