import {
  isEventPayload,
  isS3WorkflowEventPayload,
  useFetchFromS3,
  useGetSampleOrExampleEventService,
  useGetWorkflow,
  WorkflowState,
} from '@novaera/actioner-service';
import { useMemo } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useTriggerPayloadController = () => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const shouldGetSampleOrExampleEvent =
    workflow?.trigger?.type === 'genericWebhook' ||
    workflow?.trigger?.type === 'integrationApp' ||
    workflow?.trigger?.type === 'email' ||
    workflow?.trigger?.type === 'actionerEvent' ||
    workflow?.trigger?.type === 'integrationWebhook';

  const { data: sampleOrExampleEventData, isLoading } = useGetSampleOrExampleEventService({
    appId: userAppId,
    workflowId: shouldGetSampleOrExampleEvent ? workflowId : '',
    isDraft: workflow?.state === WorkflowState.DRAFT,
  });

  const s3Params = useMemo(() => {
    return isS3WorkflowEventPayload(sampleOrExampleEventData)
      ? {
          key: [userAppId, workflowId, sampleOrExampleEventData.key, sampleOrExampleEventData.modifiedAt],
          link: sampleOrExampleEventData.link,
          enabled: !!sampleOrExampleEventData.link,
        }
      : {
          key: ['s3-payload', userAppId, workflowId],
          link: undefined,
          enabled: false,
        };
  }, [sampleOrExampleEventData, userAppId, workflowId]);

  const { data: examplePayload, isInitialLoading: isS3Loading } = useFetchFromS3(s3Params);

  const payload = useMemo(() => {
    if (isEventPayload(sampleOrExampleEventData)) {
      try {
        return JSON.parse(sampleOrExampleEventData.payload);
      } catch (error) {
        return sampleOrExampleEventData.payload;
      }
    } else if (isS3WorkflowEventPayload(sampleOrExampleEventData)) {
      return examplePayload || {};
    } else {
      return {};
    }
  }, [examplePayload, sampleOrExampleEventData]);

  return {
    payload,
    isLoading: isLoading || isS3Loading,
  };
};
