import { useDeleteScheduledSubscription, useGetBillingPreferences } from '@novaera/actioner-service';
import { NvDivider, NvFlex, NvLink, NvSkeleton, NvTypography, NvWarningIcon } from '@novaera/core';
import { useQueryParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { BillingCard } from '../billing-card';
import { UsageInfo } from '../billing-card/usage-info-card/usage-info';
import { ChangePlanModal } from '../change-plan-modal';
import { EnterpriseCard } from '../enterprise-card';
import { FreeAppBanner } from '../free-app-banner';
import { PaymentAndInvoices } from '../payment-and-invoices';
import { SpendingLimitModal } from '../spending-limit-modal';
import { BillingLayoutWrapper } from '../styled';
import { SpendingLimits } from './spending-limits';
import { NonFreeWorkspaceLayoutProps } from './types';

export const NonFreeWorkspaceLayout: FC<NonFreeWorkspaceLayoutProps> = ({ billingDetail }) => {
  const [isPlanCancelling, setIsPlanCancelling] = useState<boolean>(false);
  const { mutate: cancelScheduledSubscription } = useDeleteScheduledSubscription();
  const { getSearchParams, removeQueryParams, addQueryParam } = useQueryParams();
  const { data: billingPreferences, isInitialLoading: isBillingPreferencesLoading } = useGetBillingPreferences();
  const { palette } = useTheme();

  const isChangePlanModalOpen = getSearchParams<{ upgrade?: string }>()?.upgrade === 'true';

  const showSpendingLimitModal = useMemo(() => {
    return getSearchParams<{ spendingLimit?: string }>()?.spendingLimit === 'true';
  }, [getSearchParams]);

  const handleUpgrade = useCallback(() => {
    addQueryParam('upgrade', 'true');
  }, [addQueryParam]);

  const handleCancelScheduledSubscription = useCallback(() => {
    setIsPlanCancelling(true);
    cancelScheduledSubscription(undefined, {
      onSuccess: () => {
        setTimeout(() => setIsPlanCancelling(false), 4000);
      },
      onError: (error) => {
        setIsPlanCancelling(false);
        throw error;
      },
      onSettled: () => {
        setTimeout(() => setIsPlanCancelling(false), 5000);
      },
    });
  }, [cancelScheduledSubscription]);

  const workspacePlanSecondaryTextComponent = useMemo(() => {
    const {
      billingCycle: currentBillingCycle,
      planId: currentPlanId,
      amount: currentAmount,
      periodEnd: currentPeriodEnd,
    } = billingDetail.currentSubscriptionDetails;

    if (billingDetail.scheduledSubscriptionDetails) {
      if (isPlanCancelling) {
        return (
          <NvFlex height={'20px'} alignItems="center" direction="row">
            <NvSkeleton variant="rectangular" width={'90%'} height={'16px'} />
          </NvFlex>
        );
      }

      const cancelChangeLink = (
        <NvTypography variant="body1">
          <NvLink onClick={handleCancelScheduledSubscription}>Cancel change</NvLink>
        </NvTypography>
      );

      const {
        billingCycle: scheduledBillingCycle,
        planId: scheduledPlanId,
        startDate: scheduledPlanStartDate,
        amount: scheduledAmount,
        planName: scheduledPlanName,
      } = billingDetail.scheduledSubscriptionDetails;
      const formattedScheduledStartDate = format(new Date(scheduledPlanStartDate), 'MMMM dd, yyyy');

      if (scheduledPlanId === currentPlanId && scheduledBillingCycle !== currentBillingCycle) {
        return (
          <>
            <NvFlex direction="row" alignItems={'center'} gap="4px">
              <NvWarningIcon sx={{ width: '16px', height: '16px' }} htmlColor={palette.nv_warning[40]} />
              <NvTypography variant="body1">
                Your plan's billing cycle will be changed to <b>{scheduledBillingCycle}</b> on{' '}
                <b>{formattedScheduledStartDate}</b> for $<b>{scheduledAmount}</b>.{' '}
              </NvTypography>
            </NvFlex>
            {cancelChangeLink}
          </>
        );
      } else {
        return (
          <>
            <NvFlex direction="row" alignItems={'center'} gap="4px">
              <NvWarningIcon sx={{ width: '16px', height: '16px' }} htmlColor={palette.nv_warning[40]} />
              {scheduledAmount ? (
                <NvTypography variant="body1">
                  Your plan will switch to the {<b>{scheduledPlanName}</b>}, <b>{scheduledBillingCycle}</b> on{' '}
                  <b>{formattedScheduledStartDate}</b> for $<b>{scheduledAmount.toLocaleString()}</b>.{' '}
                </NvTypography>
              ) : (
                <NvTypography variant="body1">
                  Your plan will switch to the {<b>{scheduledPlanName}</b>}, <b>{scheduledBillingCycle}</b> on{' '}
                  <b>{formattedScheduledStartDate}</b>.{' '}
                </NvTypography>
              )}
            </NvFlex>
            {cancelChangeLink}
          </>
        );
      }
    } else {
      const currentPeriodEndFormatted = format(new Date(currentPeriodEnd), 'MMMM dd, yyyy');
      return (
        <>
          <NvTypography variant="body1">
            Your plan will renew on <b>{currentPeriodEndFormatted}</b> for <b>${currentAmount}</b>.
          </NvTypography>
          <NvLink onClick={handleUpgrade}>Change plan details</NvLink>
        </>
      );
    }
  }, [
    billingDetail.currentSubscriptionDetails,
    billingDetail.scheduledSubscriptionDetails,
    handleCancelScheduledSubscription,
    handleUpgrade,
    isPlanCancelling,
    palette.nv_warning,
  ]);

  return (
    <>
      <FreeAppBanner />
      <BillingLayoutWrapper>
        <BillingCard
          title="Workspace plan"
          description={
            <NvFlex gap="8px">
              <NvTypography variant="body1">
                Your workspace is on the <b>{billingDetail.currentSubscriptionDetails.planName},</b> billed{' '}
                <b>{billingDetail.currentSubscriptionDetails.billingCycle}</b>
              </NvTypography>
              {workspacePlanSecondaryTextComponent}
            </NvFlex>
          }
        />
        {billingDetail.billingDetails.defaultPaymentMethod && <PaymentAndInvoices billingDetail={billingDetail} />}
        <BillingCard
          title="Usage"
          description={
            <NvFlex gap="16px">
              {billingDetail.resourceUsageDetails.map((usageDetail) => (
                <UsageInfo usageDetail={usageDetail} key={usageDetail.resource} />
              ))}
              {isBillingPreferencesLoading ? (
                <>
                  <NvDivider />
                  <NvFlex gap="8px">
                    <NvSkeleton variant="rectangular" height="16px" width="25%" />
                    <NvSkeleton variant="rectangular" height="12px" width="80%" />
                    <NvSkeleton variant="rectangular" height="32px" width="35%" />
                    <NvSkeleton variant="rectangular" height="32px" width="65%" />
                    <NvDivider />
                    <NvSkeleton variant="rectangular" height="24px" width="15%" />
                    <NvSkeleton variant="rectangular" height="12px" width="80%" />
                  </NvFlex>
                </>
              ) : (
                billingPreferences && (
                  <>
                    <NvDivider />
                    <SpendingLimits
                      key={billingPreferences.exceedUpToDollars}
                      initialBillingPreferences={billingPreferences}
                    />
                  </>
                )
              )}
            </NvFlex>
          }
        />
        {billingDetail.currentSubscriptionDetails.planType !== 'enterprise' && <EnterpriseCard />}
      </BillingLayoutWrapper>
      {isChangePlanModalOpen && (
        <ChangePlanModal
          initialAvailablePlansByGroupId={billingDetail?.availablePlansByGroupId}
          onClose={() => removeQueryParams('upgrade')}
          open={isChangePlanModalOpen}
          currentSubscription={billingDetail.currentSubscriptionDetails}
        />
      )}
      {billingPreferences && billingDetail.currentSubscriptionDetails && (
        <SpendingLimitModal
          onClose={() => removeQueryParams('spendingLimit')}
          open={showSpendingLimitModal}
          currentSubscriptionDetails={billingDetail.currentSubscriptionDetails}
          billingPreferences={billingPreferences}
        />
      )}
    </>
  );
};
