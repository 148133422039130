import { InputParameter, ParameterMappings, ParameterTypes, useGetIntegrationAction } from '@novaera/actioner-service';
import { Context, NvCustomHelpIcon, NvCustomTableIcon, NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { FC, useMemo } from 'react';
import {
  ActionParameterMapper,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../../components';
import { ActionOnParameterMappingsChanged } from '../../../../../../../../../components/parameter-mapper/types';
import { QuerySearchHelpTooltip } from '../../../../../../../../../components/query-search-help-tooltip';
import { useAppIdParameterMapping } from '../common/use-app-id-parameter-mapping';
import { RecordParametersProvider } from './provider';

export const RecordsParameterMapping: FC<
  React.PropsWithChildren<{
    actionId?: string;
    integrationId: string;
    parameterMappings?: ParameterMappings;
    version: number;
    context?: Context;
    onParameterMappingChanged: ActionOnParameterMappingsChanged;
  }>
> = ({ actionId, integrationId, parameterMappings, version, onParameterMappingChanged, context }) => {
  const { userAppId, workflowId } = useParams();
  const theme = useTheme();
  const { data: action } = useGetIntegrationAction({
    actionId: actionId,
    version,
    integrationId: integrationId,
    includeDraft: false,
  });

  const { appIdParameterMappings: replaceParameterMappingsForOptions } = useAppIdParameterMapping({
    userAppId,
    action,
  });

  const modelId = useMemo(() => {
    const modelParameterId = action?.inputParameters?.find(({ name }) => name === 'modelId')?.id;
    if (modelParameterId) {
      const modelIdInputParameter = parameterMappings?.find(({ parameterId }) => parameterId === modelParameterId);
      return modelIdInputParameter?.type === ParameterTypes.SIMPLE
        ? (modelIdInputParameter.value?.value as string)
        : undefined;
    } else {
      return undefined;
    }
  }, [parameterMappings, action?.inputParameters]);

  //there is no way to differentiate actioner database action.
  const isUpdatePartial = action?.name === 'Update partial';
  const isCreate = action?.name === 'Create record';
  const isUpdate = action?.name === 'Update record';
  const isSearch = action?.name === 'Search record';
  const isSemanticSearch = action?.name === 'Semantic search';

  return (
    <RecordParametersProvider appId={userAppId} modelId={modelId}>
      <PropertyPanelSection title="Table" icon={<NvCustomTableIcon />}>
        <ActionParameterMapper
          type="in-workflow"
          appId={userAppId}
          workflowId={workflowId}
          actionId={actionId}
          integrationId={integrationId}
          versionNumber={version}
          onParameterMappingsChanged={onParameterMappingChanged}
          filterInputParameters={(input: InputParameter) => input.name === 'modelId'}
          hideInputParameterLabel={(input: InputParameter) => input.name === 'modelId'}
          initialParameterMappings={parameterMappings}
          replaceParameterMappingsForOptions={replaceParameterMappingsForOptions}
          isForceHideSwitch={isCreate || isUpdate || isUpdatePartial}
        />
      </PropertyPanelSection>
      <PropertyPanelListHeader
        title="Table fields"
        tooltip={
          isUpdatePartial || isUpdate ? (
            `Specify the ID of the record, then ${
              isUpdate ? 'enter the new values for the fields.' : 'select the fields you would like to update.'
            } `
          ) : isSearch ? (
            <QuerySearchHelpTooltip>
              <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
            </QuerySearchHelpTooltip>
          ) : isSemanticSearch ? (
            <QuerySearchHelpTooltip href="https://actioner.com/help/semantic-search#filter-query-in-semantic-search-enabled-tables">
              <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
            </QuerySearchHelpTooltip>
          ) : null
        }
      />
      <PropertyPanelSimpleSection>
        <NvFlex gap="16px" paddingBottom="8px" width="100%">
          <ActionParameterMapper
            type="in-workflow"
            appId={userAppId}
            workflowId={workflowId}
            actionId={actionId}
            integrationId={integrationId}
            versionNumber={version}
            context={context}
            onParameterMappingsChanged={onParameterMappingChanged}
            filterInputParameters={(input: InputParameter) => input.name !== 'modelId' && input.name !== 'appId'}
            initialParameterMappings={parameterMappings}
            replaceParameterMappingsForOptions={replaceParameterMappingsForOptions}
          />
        </NvFlex>
      </PropertyPanelSimpleSection>
    </RecordParametersProvider>
  );
};
