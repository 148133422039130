import { SvgIcon, SvgIconProps } from '@mui/material';

export const NvMsTeamsIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2173_145)">
        <g clipPath="url(#clip1_2173_145)">
          <path
            d="M15.9501 9.75H21.1162C21.6043 9.75 21.9999 10.1328 21.9999 10.605V15.1576C21.9999 16.8931 20.5458 18.3 18.752 18.3H18.7367C16.9429 18.3002 15.4885 16.8936 15.4883 15.1581C15.4883 15.1579 15.4883 15.1578 15.4883 15.1576V10.1969C15.4883 9.95006 15.6951 9.75 15.9501 9.75Z"
            fill="#5059C9"
          />
          <path
            d="M19.4419 8.84999C20.5978 8.84999 21.5349 7.94337 21.5349 6.82499C21.5349 5.70661 20.5978 4.79999 19.4419 4.79999C18.286 4.79999 17.3489 5.70661 17.3489 6.82499C17.3489 7.94337 18.286 8.84999 19.4419 8.84999Z"
            fill="#5059C9"
          />
          <path
            d="M12.9302 8.85001C14.5999 8.85001 15.9535 7.54044 15.9535 5.925C15.9535 4.30957 14.5999 3 12.9302 3C11.2605 3 9.90698 4.30957 9.90698 5.925C9.90698 7.54044 11.2605 8.85001 12.9302 8.85001Z"
            fill="#7B83EB"
          />
          <path
            d="M16.9613 9.75H8.43384C7.95158 9.76155 7.57009 10.1488 7.58128 10.6154V15.8079C7.51394 18.6079 9.80358 20.9315 12.6976 21C15.5915 20.9315 17.8812 18.6079 17.8138 15.8079V10.6154C17.825 10.1488 17.4435 9.76155 16.9613 9.75Z"
            fill="#7B83EB"
          />
          <path
            opacity="0.1"
            d="M13.1627 9.75V17.0265C13.1604 17.3602 12.9514 17.66 12.6325 17.787C12.5309 17.8286 12.4218 17.85 12.3115 17.85H7.9906C7.93014 17.7015 7.87433 17.553 7.82781 17.4C7.665 16.8836 7.58192 16.3469 7.58129 15.807V10.614C7.57011 10.1482 7.95098 9.76155 8.43246 9.75H13.1627Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.6976 9.75V17.4765C12.6976 17.5832 12.6754 17.6888 12.6325 17.787C12.5012 18.0956 12.1913 18.2978 11.8464 18.3H8.20921C8.13014 18.1515 8.05572 18.003 7.9906 17.85C7.92548 17.697 7.87433 17.553 7.82781 17.4C7.665 16.8837 7.58192 16.3469 7.58129 15.807V10.614C7.57011 10.1482 7.95098 9.76155 8.43246 9.75H12.6976Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.6976 9.75V16.5765C12.694 17.0299 12.315 17.3966 11.8464 17.4H7.82781C7.665 16.8837 7.58192 16.3469 7.58129 15.807V10.614C7.57011 10.1482 7.95098 9.76155 8.43246 9.75H12.6976Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.2325 9.75V16.5765C12.2289 17.0299 11.8499 17.3966 11.3813 17.4H7.82781C7.665 16.8837 7.58192 16.3469 7.58129 15.807V10.614C7.57011 10.1482 7.95098 9.76155 8.43246 9.75H12.2325Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M13.1628 7.4235V8.841C13.0837 8.8455 13.0093 8.85 12.9303 8.85C12.8512 8.85 12.7768 8.8455 12.6977 8.841C12.5407 8.83092 12.385 8.80682 12.2326 8.769C11.2907 8.5532 10.5126 7.91413 10.1396 7.05C10.0754 6.90489 10.0255 6.75421 9.99072 6.60001H12.3117C12.781 6.60173 13.161 6.9694 13.1628 7.4235Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.6978 7.87351V8.84101C12.5408 8.83093 12.3851 8.80683 12.2327 8.76902C11.2908 8.55322 10.5127 7.91414 10.1396 7.05002H11.8466C12.316 7.05174 12.696 7.41942 12.6978 7.87351Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.6978 7.87351V8.84101C12.5408 8.83093 12.3851 8.80683 12.2327 8.76902C11.2908 8.55322 10.5127 7.91414 10.1396 7.05002H11.8466C12.316 7.05174 12.696 7.41942 12.6978 7.87351Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.2327 7.87349V8.76899C11.2908 8.55318 10.5127 7.91411 10.1396 7.04999H11.3815C11.8509 7.05172 12.2309 7.4194 12.2327 7.87349Z"
            fill="black"
          />
          <path
            d="M2.85255 7.04999H11.38C11.8509 7.04999 12.2325 7.41929 12.2325 7.87483V16.1251C12.2325 16.5807 11.8508 16.95 11.38 16.95H2.85255C2.3817 16.95 2 16.5807 2 16.1251V7.87483C2 7.41929 2.38171 7.04999 2.85255 7.04999Z"
            fill="url(#paint0_linear_2173_145)"
          />
          <path d="M9.36 10.1901H7.65534V14.6811H6.5693V10.1901H4.87256V9.31891H9.36V10.1901Z" fill="white" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2173_145"
          x1="3.7776"
          y1="6.40547"
          x2="10.1295"
          y2="17.7763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A62C3" />
          <stop offset="0.5" stopColor="#4D55BD" />
          <stop offset="1" stopColor="#3940AB" />
        </linearGradient>
        <clipPath id="clip0_2173_145">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_2173_145">
          <rect width="20" height="18" fill="white" transform="translate(2 3)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
