import { styled } from '@novaera/theme-provider';

export const Header = styled('div')`
  width: 100%;

  &.store-header,
  &.store-detail-header {
    flex: 0 0 auto;
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    position: sticky;
    top: 0px;
    z-index: 10;
    ${({ theme }) => theme.elevations.e100};

    &.store-header {
      top: -50px;
    }
    &.public {
      top: 80px;
    }

    @media screen and (max-width: 767px) {
      position: initial;
      z-index: 1;
    }
  }
`;
export const Body = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  position: relative;
  flex: 1 1 auto;
  min-height: 0;

  &.store-body {
    height: auto;
    min-height: calc(100vh - 165px);

    &.public {
      min-height: calc(100vh - 203px);
    }
  }

  &.store-detail-body {
    height: auto;
    min-height: calc(100vh - 171px);

    &.public {
      min-height: calc(100vh - 245px);
    }
  }
`;
