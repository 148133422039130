import { useGetActiveUser } from '@novaera/actioner-service';
import { useMemo } from 'react';
import { OAuthConnectionButtons } from '../../../oauth2-connection/constant';
import { ConnectModalFooterProps } from '../../types';

export const useConnectModalFooterController = ({
  connectionSchema,
  mode,
  connected,
  formSubmitResult,
  response,
}: Omit<ConnectModalFooterProps, 'isLoading' | 'onModalCloseClicked'>) => {
  const hasResponse = useMemo(() => {
    return !!response || formSubmitResult?.type === 'error';
  }, [formSubmitResult?.type, response]);

  const isConnected = useMemo(() => {
    return Boolean(!hasResponse && connected && mode === 'Connect');
  }, [connected, hasResponse, mode]);

  const isNotConnected = useMemo(() => {
    return !hasResponse && !connected && mode === 'Connect';
  }, [connected, hasResponse, mode]);

  const isTestConnection = useMemo(() => {
    return !hasResponse && mode === 'Test';
  }, [hasResponse, mode]);

  const PredefinedConnectionButton = useMemo(() => {
    return OAuthConnectionButtons.find((button) => button.name === connectionSchema?.name);
  }, [connectionSchema?.name]);

  const isSlackConnection = connectionSchema?.slack;
  const isMsTeamsConnection = connectionSchema?.msteams;
  const { user } = useGetActiveUser({ enabled: isSlackConnection || isMsTeamsConnection });
  const hideConnectButton = user?.roleId === 'user' && (isSlackConnection || isMsTeamsConnection);

  return {
    hasResponse,
    isConnected,
    isNotConnected,
    isTestConnection,
    PredefinedConnectionButton,
    isSlackConnection,
    isMsTeamsConnection,
    hideConnectButton,
  };
};
