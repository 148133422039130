import { styled } from '@novaera/theme-provider';
import Markdown from '@insanicly/markdown-to-jsx';

export const MarkdownWrapper = styled(Markdown)`
  // Header Styling

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};

    /* &:first-of-type {
      margin-top: 0;
    } */
  }

  h1 {
    font-weight: 500;
    font-size: 28px;
    margin: 32px 0 12px 0;
    line-height: 34px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
    margin: 24px 0 12px 0;
    line-height: 28px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 18px 0 12px 0;
    line-height: 24px;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 16px 0 8px 0;
    line-height: 18px;
  }

  h5 {
    font-size: 12px;
    font-weight: 600;
    margin: 16px 0 8px 0;
    line-height: 16px;
  }

  h6 {
    font-size: 10px;
    font-weight: 600;
    margin: 16px 0 6px 0;
    line-height: 16px;
  }

  // Paragraph Styling

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    margin-top: 0;
    margin-bottom: 8px;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  small {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.nv_neutral_alpha[500]};
    letter-spacing: 0px;
    font-size: 12px;
  }

  // Code Styling
  code {
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    background-color: transparent;
    border: none;
  }

  // blockquote Styling
  blockquote {
    position: relative;
    margin: 24px 24px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -18px;
      top: 4px;
      bottom: 4px;
      width: 4px;
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
      border-radius: 2px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: ${({ theme }) => theme.palette.nv_neutral_alpha[600]};
      margin: 0;
    }
  }

  // Image Styling
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    ${({ theme }) => theme.elevations.e100};
  }

  // Lists Styling
  ul,
  ol {
    padding-left: 24px;

    li {
      &::marker {
        font-weight: 600;
      }
    }
  }

  // iFrame Styling For (Youtube videos)
  iframe {
    max-width: 100%;
    border-radius: 12px;
    ${({ theme }) => theme.elevations.e100};
  }

  // Divider

  hr {
    border: none;
    margin: 24px 0;
    border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
  }
`;
