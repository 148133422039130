import { RecurrenceTimeUnit, ValueTypes, WeekdaysJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvMenuWithItems, NvSettingsIcon, NvSideGroupedInputLayout } from '@novaera/core';
import { ParameterMappingSettingsMenu } from '../../../../../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { WEEKDAYS_CRON_EXPRESSION } from '../../../../../../../../../../jobs/constants';
import { RecurrenceFieldCard } from '../../../styled';
import { RecurrenceProps } from '../types';
import { DynamicWeekdaysRecurrence } from './dynamic';
import { StaticWeekdaysRecurrence } from './static';

export const WeekdaysRecurrence: React.FC<RecurrenceProps<WeekdaysJobRecurrenceConfiguration>> = ({
  recurrenceConfiguration,
  context,
  onChange,
}) => (
  <RecurrenceFieldCard>
    <NvSideGroupedInputLayout
      variant={'default'}
      rightAction={
        <NvMenuWithItems
          triggerButton={{
            content: <NvSettingsIcon />,
            props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
          }}
          menuItems={
            <ParameterMappingSettingsMenu
              hiddenConfig={{
                show: false,
              }}
              dynamicStaticConfig={{
                show: true,
                isScripted: recurrenceConfiguration.dynamic,
                onItemClicked: (type) => {
                  const newScriptedValue = type === 'static' ? false : true;

                  if (recurrenceConfiguration.dynamic !== newScriptedValue) {
                    onChange({
                      ...(!newScriptedValue
                        ? {
                            type: RecurrenceTimeUnit.WEEKDAYS,
                            cronExpression: WEEKDAYS_CRON_EXPRESSION,
                            dynamic: false,
                          }
                        : {
                            type: RecurrenceTimeUnit.WEEKDAYS,
                            hour: { type: ValueTypes.STRING, value: '' },
                            minute: { type: ValueTypes.STRING, value: '' },
                            dynamic: true,
                          }),
                    });
                  }
                },
              }}
            />
          }
        />
      }
      formItem={
        recurrenceConfiguration.dynamic ? (
          <DynamicWeekdaysRecurrence
            recurrenceConfiguration={recurrenceConfiguration}
            context={context}
            onChange={onChange}
          />
        ) : (
          <StaticWeekdaysRecurrence recurrenceConfiguration={recurrenceConfiguration} onChange={onChange} />
        )
      }
    />
  </RecurrenceFieldCard>
);
