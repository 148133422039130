import { NvAvatar, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ContributorContainer = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  gap: 4px;
  padding-right: 6px;
`;

export const ContributorAvatar = styled(NvAvatar)`
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[0]};
`;
