import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemasResponse } from '../use-get-app-schemas/types';

const deleteAppSchema = async (id: string) => {
  const result = await NvAxios.delete<void>(`${APP_DIRECTORY_ROOT_PATH}/schemas/${id}`);
  return result?.data;
};

export const useDeleteAppSchema = () => {
  const cache = useQueryClient();
  return useMutation(deleteAppSchema, {
    onSuccess: (_, id) => {
      cache.setQueriesData<InfiniteData<GetAppSchemasResponse>>(QUERY_KEYS_APP_DIRECTORY.lists(), (old) => {
        return old
          ? {
              ...old,
              pages: old.pages.map((page) => ({
                ...page,
                result: { ...page.result, schemas: page.result.schemas.filter((schema) => schema.id !== id) },
              })),
            }
          : undefined;
      });
    },
  });
};
