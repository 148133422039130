import { NvAxios, useQuery } from '@novaera/core';

import { ListUsersParams, ListUsersResponse } from './types';

const listUsers = async (params: ListUsersParams) => {
  const result = await NvAxios.get<ListUsersResponse>(
    `/admin/identity/users?productId=actioner&subdomain=${params?.subdomain}`
  );
  return result?.data;
};

export const useListUsers = (params: ListUsersParams) => {
  return useQuery(['admin-list-users', ...(params?.subdomain ? [params.subdomain] : [])], () => listUsers(params), {
    enabled: !!params.subdomain,
    retry: false,
  });
};
