import { NvField, NvFlex, NvTypography } from '@novaera/core';
import { CronDayOfMonthPicker } from '../../../../../components/cron-day-of-month-picker';
import { CronTimePicker } from '../../../../../components/cron-time-picker';
import { MONTHLY_CRON_EXPRESSION } from '../../../../../constants';

export const MonthlyCronConfiguration: React.FC = () => (
  <NvField<string>
    name="recurrence.cron"
    component={({ value, onChange }) => {
      const cron = value ?? MONTHLY_CRON_EXPRESSION;

      return (
        <NvFlex direction="row" gap="8px" alignItems="flex-start">
          <CronDayOfMonthPicker cron={cron} onChange={onChange} />
          <NvTypography padding="6px 0" flex="0 0 auto">
            days, at
          </NvTypography>
          <CronTimePicker cron={cron} onChange={onChange} />
        </NvFlex>
      );
    }}
  />
);
