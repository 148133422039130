import { ParameterMapping, ParameterMappings } from '../../../parameter';

export enum DataSourceType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export type OptionTemplatePair = {
  identifierTemplate: string;
  displayValueTemplate: string;
};

export interface BaseOptionsProducer {
  optionTemplatePair: OptionTemplatePair;
}

export interface RequestOptionProducer extends BaseOptionsProducer {
  type: 'request';
  requestDefinitionId: string;
}

export interface ActionOptionProducer extends BaseOptionsProducer {
  type: 'action';
  integrationId: string;
  actionId: string;
  versionNumber?: number;
  connectionId?: string;
  parameterMappings: ParameterMapping[];
}

export type OptionsProducer = RequestOptionProducer | ActionOptionProducer;

export type DynamicDataSource = {
  type: DataSourceType.DYNAMIC;
  optionsProducers: OptionsProducer[];
};

export type KeyDisplayValue = { key: string; displayValue: string };

export type StaticDataSource = {
  type: DataSourceType.STATIC;
  options?: KeyDisplayValue[];
};

export type DataSource = DynamicDataSource | StaticDataSource;

export type DynamicInputSourceActionInIntegration = {
  type: 'integration';
  actionId?: string;
  parameterMappings?: ParameterMappings;
  formId?: string;
};

export type DynamicInputSourceActionInWorkflow = {
  type: 'workflow';
  integrationId?: string;
  version?: number;
  actionId?: string;
  connectionId?: string;
  parameterMappings?: ParameterMappings;
  formId?: string;
};
