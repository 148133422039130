import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomFolderAddIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2077_202)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17ZM12 9C12.56 9 13 9.45 13 10V12H15C15.56 12 16 12.45 16 13C16 13.56 15.55 14 15 14H13V16C13 16.56 12.55 17 12 17C11.44 17 11 16.55 11 16V14H9C8.44 14 8 13.55 8 13C8 12.44 8.45 12 9 12H11V10C11 9.44 11.45 9 12 9Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2077_202">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
