import { CreateCustomPlanParameters, useCreateCustomPlanConfiguration } from '@novaera/actioner-service';
import { CodeInput, FieldTitle, NvButton, NvField, NvFlex, NvForm, isValidJSON, useToast } from '@novaera/core';
import { CustomPlanConfigurationFormParameters } from './types';

export const CustomPlanConfiguration: React.FC = () => {
  const initialValues: CustomPlanConfigurationFormParameters = {
    customPlanParameters: JSON.stringify(
      {
        workspaceId: '',
        defaultPlanId: 'enterprise-v1',
        price: {
          billingCycle: 'monthly',
          amountInDollars: 50,
        },

        resourceLimits: [
          {
            resource: 'node-execution',
            quantity: 100000,
          },
        ],
        resourceExtraPricings: [
          {
            resource: 'node-execution',
            amountInCents: 1,
          },
        ],
        features: ['node', 'ai'],
        selectable: true,
      },
      null,
      2
    ),
  };
  const { addToast } = useToast();
  const { mutate: createCustomPlan } = useCreateCustomPlanConfiguration();
  const handleSubmit = ({ customPlanParameters }: CustomPlanConfigurationFormParameters) => {
    try {
      const params: CreateCustomPlanParameters = JSON.parse(customPlanParameters);
      return new Promise<void>((resolve) => {
        createCustomPlan(params, {
          onSettled: () => {
            resolve();
          },
        });
      });
    } catch (error) {
      addToast('Invalid JSON can not be submitted!', { variant: 'error' });
      return;
    }
  };
  return (
    <NvForm<CustomPlanConfigurationFormParameters>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ valid, submitting }) => {
        return (
          <NvFlex alignItems="flex-start" gap="8px">
            <NvFlex width="100%" height="500px">
              <FieldTitle labelText="Custom plan" />
              <NvField
                name={'customPlanParameters'}
                showErrorMessageOnlyWhenBlur
                format={(value) => (typeof value === 'object' ? JSON.stringify(value, null, 2) : value)}
                validators={[
                  isValidJSON({
                    valueExtractFunction: (value) =>
                      typeof value === 'object' ? JSON.stringify(value, null, 2) : value,
                  }),
                ]}
                component={<CodeInput context={{}} expectedType="object" mode="json" lineNumbers hasBorder />}
              />
            </NvFlex>
            <NvButton type="submit" disabled={!valid || submitting} loading={submitting}>
              Create
            </NvButton>
          </NvFlex>
        );
      }}
    </NvForm>
  );
};
