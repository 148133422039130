import { NvAxios, useMutation } from '@novaera/core';

import { useQueryClient } from '@tanstack/react-query';
import { GetUserAppResponse } from '../../user-app';
import { QUERY_KEYS_USER_APP } from '../../user-app/keys';
import { MANAGED_APP_ROOT_PATH } from '../constants';
import { UnlockManagedAppParams } from '../types';

const updateManagedApp: ({ appId }: UnlockManagedAppParams) => Promise<void> = async ({ appId }) => {
  await NvAxios.post<void>(`${MANAGED_APP_ROOT_PATH}/${appId}/update-managed-app`);
};

export const useUpdateManagedApp = () => {
  const cache = useQueryClient();
  return useMutation(updateManagedApp, {
    onSuccess: (_, { appId }) => {
      const appDetail = cache.getQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId));
      if (appDetail) {
        cache.setQueryData(QUERY_KEYS_USER_APP.detail(appId), {
          ...appDetail,
          updateInProgress: true,
        });
      }
    },
  });
};
