import { NvAxios, useMutation } from '@novaera/core';

import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_USER_APP } from '../../user-app/keys';
import { SearchUserAppResponse } from '../../user-app/use-search-user-apps/types';
import { CLONE_ROOT } from '../constants';
import { AppCloneParams, AppCloneResponse } from '../types';

const appClone: (params: AppCloneParams) => Promise<AppCloneResponse> = async (params) => {
  const { appId, ...rest } = params;
  const result = await NvAxios.post<AppCloneResponse>(`${CLONE_ROOT({ appId })}/clone`, rest.payload);
  return result?.data;
};

export const useAppClone = () => {
  const cache = useQueryClient();
  return useMutation(appClone, {
    onSuccess: ({ clonedApp }, { onSuccess }) => {
      onSuccess({ clonedApp });

      cache
        .getQueriesData<SearchUserAppResponse>({
          queryKey: [...QUERY_KEYS_USER_APP.all, 'search'],
        })
        .forEach((result) => {
          const [query, data] = result;
          cache.setQueryData<SearchUserAppResponse>(query, (old) => {
            const apps = [clonedApp, ...(data?.apps ?? [])];
            if (old && data?.apps) {
              return {
                ...old,
                apps,
              };
            }
            return old;
          });
        });

      cache
        .getQueriesData<InfiniteData<SearchUserAppResponse>>({
          queryKey: [...QUERY_KEYS_USER_APP.all, 'infinite-search'],
        })
        .forEach((result) => {
          const [query, _] = result;
          cache.setQueryData<InfiniteData<SearchUserAppResponse>>(query, (old) => {
            if (old) {
              return {
                ...old,
                pages: old?.pages?.map(({ apps, ...others }) => ({
                  ...others,
                  apps: [clonedApp, ...apps],
                })) ?? [{ apps: [clonedApp] }],
              };
            }
            return old;
          });
        });
    },
  });
};
