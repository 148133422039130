import { NvDivider, NvFlex, NvSkeleton } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { WorkflowHistoryNodeExecutionCard, WorkflowHistoryNodeExecutionCardHeader } from '../styled';

export const WorkflowHistoryNodeExecutionLoadingItem: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  return (
    <WorkflowHistoryNodeExecutionCard>
      <WorkflowHistoryNodeExecutionCardHeader>
        <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth={0}>
          <NvSkeleton variant="rectangular" width="24px" height="24px" />
          <NvFlex gap="4px">
            <NvSkeleton variant="rectangular" width="90px" height="12px" />
            <NvSkeleton variant="rectangular" width="90px" height="16px" />
          </NvFlex>
        </NvFlex>
        <NvFlex alignItems="flex-end" flex="0 0 auto" gap="8px">
          <NvFlex direction="row" alignItems="center" gap="8px">
            <NvSkeleton variant="rectangular" width="12px" height="12px" />
            <NvDivider orientation="vertical" borderColor={theme.palette.nv_neutral_alpha[20]} sx={{ height: '8px' }} />
            <NvSkeleton variant="rectangular" width="12px" height="12px" />
          </NvFlex>
          <NvFlex direction="row" gap="3px" alignItems="center">
            <NvSkeleton variant="circular" width="12px" height="12px" />
            <NvSkeleton variant="rectangular" width="60px" height="12px" />
          </NvFlex>
        </NvFlex>
      </WorkflowHistoryNodeExecutionCardHeader>
    </WorkflowHistoryNodeExecutionCard>
  );
};
