import { useCopyToClipboard } from '@novaera/utils';
import { useState } from 'react';

export const useJsonViewer = () => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyButtonClicked = (json: object) => {
    copyToClipboard(JSON.stringify(json, null, 2));
    setIsCopied(true);
  };

  const handleResetCopyState = () => {
    setIsCopied(false);
  };

  return {
    onCopyClicked: handleCopyButtonClicked,
    copyText: isCopied ? 'Copied!' : 'Copy',
    resetCopyState: handleResetCopyState,
  };
};
