import { NvFlex, NvStarIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { StatsProps } from './types';

export const Stats: React.FC<React.PropsWithChildren<StatsProps>> = ({ star }) => {
  const theme = useTheme();

  return (
    <NvFlex direction="row" spacing="12px" flex="0 0 auto">
      <NvFlex direction="row" gap="4px" alignItems="center">
        <NvStarIcon sx={{ width: 16, height: 16 }} htmlColor={theme.palette.nv_new[40]} />
        <NvTypography variant="h7">{star}</NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
