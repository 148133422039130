import { ROUTE_DEFAULTS } from '@novaera/constants';

export const getPathForTargetURI = () => {
  return window.location.pathname.includes(ROUTE_DEFAULTS.SOCIAL_LOG_IN) ||
    window.location.pathname.includes(ROUTE_DEFAULTS.SIGN_IN) ||
    window.location.pathname.includes(ROUTE_DEFAULTS.LOG_OUT) ||
    window.location.pathname.includes(ROUTE_DEFAULTS.SIGN_UP) ||
    window.location.pathname.includes(ROUTE_DEFAULTS.EMAIL_CHANGE_CONFIRMATION) ||
    window.location.pathname.includes(ROUTE_DEFAULTS.USER_INVITATION)
    ? '/'
    : `${window.location.pathname}${window.location.search}`;
};
