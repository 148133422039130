import { useGetUserAppSetup } from '@novaera/actioner-service';
import {
  FieldArray,
  isRequired,
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvField,
  NvFlex,
  NvMarkDownEditor,
  NvSkeleton,
  NvTypography,
  StepField,
  useFormState,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC, useMemo } from 'react';
import { DragDropList } from '../../../../components/drag-drop-list';
import { WorkflowSearchField } from '../../../../components/workflow-search-field';
import { WorkflowSearchOption } from '../../../../components/workflow-search-field/types';
import { PublishAppFormData, PublishAppMode, PublishNewVersionFormData } from '../types';
import { LinkConfiguration } from './link-configuration';

export const SetupWorkflowsStep: FC<React.PropsWithChildren<{ mode: PublishAppMode }>> = ({ mode }) => {
  const {
    values: { appDetails },
  } = useFormState<PublishAppFormData | PublishNewVersionFormData>();

  const { data: userAppSetup, isLoading: isUserAppSetupLoading } = useGetUserAppSetup({ appId: appDetails.appId });

  const initialSetupWorkflows: WorkflowSearchOption[] = useMemo(
    () =>
      userAppSetup && mode === PublishAppMode.Update && userAppSetup.setupWorkflows.length > 0
        ? userAppSetup.setupWorkflows.map(({ id, name, autoRun }) => ({ id, name, autoRun }))
        : [],
    [userAppSetup, mode]
  );

  return (
    <NvFlex gap="32px">
      <NvBox>
        <NvTypography marginBottom="8px" variant="h2">
          Setup workflows
        </NvTypography>

        <StepField
          help={{
            title: 'Setup workflows',
            description: (
              <NvFlex gap="16px">
                <NvTypography variant="body2">
                  If your app requires some configuration steps, you can design <b>Setup workflow</b> for users who will
                  install your app.
                </NvTypography>
                <NvTypography variant="body2">
                  Check out for example setup workflows <a>here.</a>
                </NvTypography>
              </NvFlex>
            ),
          }}
        >
          <NvTypography>
            If you have any setup workflow in your app, select them from the list. Those workflow will be available to
            the users during the setup process.
          </NvTypography>
          {isUserAppSetupLoading ? (
            <NvFlex gap="16px" marginTop="16px">
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
            </NvFlex>
          ) : (
            <FieldArray<WorkflowSearchOption> name="appDetails.setupWorkflows" initialValue={initialSetupWorkflows}>
              {({ fields }) => (
                <NvBox marginTop="16px">
                  {fields.value?.length > 0 && (
                    <DragDropList<WorkflowSearchOption>
                      droppableId="setupWorkflows.droppableId"
                      listItems={fields}
                      onDragEnd={(sourceIndex, destination) => {
                        fields.move(sourceIndex, destination);
                      }}
                      onItemRemoved={fields.remove}
                      itemNode={(field, fieldName) => {
                        assert(typeof fieldName === 'string', new Error("Field name can't be empty"));

                        return (
                          <WorkflowSearchField
                            appId={appDetails.appId}
                            fieldName={fieldName}
                            fieldProps={{ validators: [isRequired()] }}
                            searchWorkflowsParams={{ triggerTypes: ['form'], sort: { order: 'asc', field: 'name' } }}
                          />
                        );
                      }}
                      getDraggableId={(item, index) => `draggable-workflow-item-${item?.id}`}
                      getItemValue={(index) => fields.value[index]}
                    />
                  )}

                  <NvButton
                    startIcon={<NvAddBoxIcon />}
                    size="small"
                    color="secondary"
                    onClick={() => {
                      fields.push(null);
                    }}
                    sx={{ marginTop: '4px' }}
                  >
                    Add workflow
                  </NvButton>
                </NvBox>
              )}
            </FieldArray>
          )}
        </StepField>
      </NvBox>
      <NvBox>
        <NvTypography marginBottom="8px" variant="h2">
          Success message
        </NvTypography>
        <StepField
          help={{
            title: 'Success message',
            description: (
              <NvFlex gap="16px">
                <NvTypography variant="body2">
                  After the user installs the app, you can use <b>Success message</b> field to direct them to the
                  desired location and indicate that the setup process has been completed correctly.
                </NvTypography>
              </NvFlex>
            ),
          }}
        >
          <NvTypography>Compose a success message for users upon completing the installation wizard.</NvTypography>
          {isUserAppSetupLoading ? (
            <NvFlex gap="16px" marginTop="16px">
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
            </NvFlex>
          ) : (
            <NvFlex gap="8px" marginTop="16px">
              <NvField
                name="appDetails.successView.successMessage"
                hideLabel
                component={<NvMarkDownEditor />}
                initialValue={userAppSetup?.successView?.successMessage}
              />

              <NvField
                direction="label-on-side"
                name="appDetails.successView.actionerAppHomeText"
                hideLabel
                component={<LinkConfiguration label="Show Actioner direction link" resetText="Go to app home page" />}
                initialValue={userAppSetup?.successView?.actionerAppHomeText}
              />

              <NvField
                name="appDetails.successView.slackAppHomeText"
                hideLabel
                component={<LinkConfiguration label="Show Slack direction link" resetText="Go to app for Slack home" />}
                initialValue={userAppSetup?.successView?.slackAppHomeText}
              />
            </NvFlex>
          )}
        </StepField>
      </NvBox>
    </NvFlex>
  );
};
