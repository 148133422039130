import { DataGrid } from '@mui/x-data-grid';
import { isUndefined } from 'lodash';
import React, { useMemo } from 'react';
import { NvArrowDropDownIcon, NvArrowDropUpIcon } from '../../index';
import { DataGridEmptyState, defaultRenderCell, defaultRenderHeader } from './common';
import { HEADER_HEIGHT, PAGE_SIZE, ROW_HEIGHT } from './constants';
import { NvPagination } from './pagination';
import { CustomDataGrid } from './styled';
import { OverriddenDataGridProps } from './types';

export const NvDataGrid = ({
  rows,
  columns,
  loading,
  pageSize = PAGE_SIZE,
  customRenderCell,
  customRenderHeader,
  isColumnSelectable = false,
  onColumnSelect,
  initialSelectedFieldIndex,
}: OverriddenDataGridProps) => {
  const finalColumns = useMemo(() => {
    const result = columns.map((column) => ({
      renderCell: customRenderCell ?? defaultRenderCell,
      renderHeader: customRenderHeader ?? defaultRenderHeader,
      ...column,
    }));
    return result;
  }, [columns, customRenderCell, customRenderHeader]);

  const selectedField = useMemo(() => {
    return isColumnSelectable
      ? !isUndefined(initialSelectedFieldIndex)
        ? finalColumns[initialSelectedFieldIndex]?.field
        : ''
      : undefined;
  }, [isColumnSelectable, finalColumns, initialSelectedFieldIndex]);

  return (
    <CustomDataGrid
      getRowId={(row) => row.muiDataGridRowId}
      rows={rows}
      columns={finalColumns}
      loading={loading}
      pageSize={pageSize}
      headerHeight={HEADER_HEIGHT}
      rowHeight={ROW_HEIGHT}
      hideFooterSelectedRowCount
      columnBuffer={100}
      columnThreshold={100}
      autoHeight
      isRowSelectable={() => false}
      components={{
        Pagination: NvPagination,
        ColumnSortedAscendingIcon: NvArrowDropUpIcon,
        ColumnSortedDescendingIcon: NvArrowDropDownIcon,
        NoRowsOverlay: DataGridEmptyState,
      }}
      {...(isColumnSelectable
        ? {
            selectedField,
            onCellClick: ({ field }) => {
              const index = finalColumns.findIndex((column) => column.field === field);
              onColumnSelect?.(index);
            },
            onColumnHeaderClick: ({ field }) => {
              const index = finalColumns.findIndex((column) => column.field === field);
              onColumnSelect?.(index);
            },
          }
        : {})}
    />
  );
};

export const MemoNvDataGrid = React.memo(NvDataGrid);

export const BaseDataGrid = DataGrid;
export { useGridApiContext } from '@mui/x-data-grid';
