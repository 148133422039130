import { useFetchWorkspaceList } from '../../services/use-fetch-workspace-list';

export const useWorkspaceList = () => {
  const { data, isInitialLoading } = useFetchWorkspaceList();

  return {
    workspaces: data?.workspaces,
    isLoading: isInitialLoading,
    hasWorkspaces: data?.workspaces && data?.workspaces.length > 0,
  };
};
