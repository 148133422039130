import { NvSkeleton } from '@novaera/core';
import { useGetSlackAuth } from '@novaera/identity-provider';
import { ConnectChatToolButtonBase } from '../connect-chat-tool-base';

export const ConnectSlackButton: React.FC<{ ButtonBase: React.FC<{ onClick: () => void }> }> = ({ ButtonBase }) => {
  const { slackAuthUrl, isLoading } = useGetSlackAuth();

  return isLoading ? (
    <NvSkeleton variant="rectangular" height="24px" width="64px" />
  ) : (
    <ConnectChatToolButtonBase
      type="slack"
      ButtonBase={ButtonBase}
      onClick={() => {
        if (slackAuthUrl) {
          window.open(slackAuthUrl, '_blank');
        }
      }}
    />
  );
};
