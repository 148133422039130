import { HourlyJobRecurrenceConfiguration, ValueTypes } from '@novaera/actioner-service';
import { NvFlex, NvNumberInput, NvTypography } from '@novaera/core';
import { RecurrenceProps } from '../../types';

export const StaticHourlyRecurrence: React.FC<Omit<RecurrenceProps<HourlyJobRecurrenceConfiguration>, 'context'>> = ({
  recurrenceConfiguration,
  onChange,
}) => {
  return (
    <NvFlex direction="row" alignItems="center" gap="4px">
      <NvNumberInput
        value={recurrenceConfiguration.recurrenceMinute.value}
        onChange={(e) => {
          onChange({
            ...recurrenceConfiguration,
            recurrenceMinute: {
              type: ValueTypes.NUMBER,
              displayValue: e.target.value,
              value: e.target.value ? Number(e.target.value) : undefined,
            },
          });
        }}
      />
      <NvTypography variant="body2">minute, repeats every</NvTypography>
      <NvNumberInput
        value={recurrenceConfiguration.repeatInterval.value}
        onChange={(e) => {
          onChange({
            ...recurrenceConfiguration,
            repeatInterval: {
              type: ValueTypes.NUMBER,
              displayValue: e.target.value,
              value: e.target.value ? Number(e.target.value) : undefined,
            },
          });
        }}
      />
      <NvTypography variant="body2">hours</NvTypography>
    </NvFlex>
  );
};
