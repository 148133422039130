import { isRequired, NvAutocomplete, NvField, NvTextField } from '@novaera/core';
import { useState } from 'react';
import { TimezoneFieldProps } from './types';

export const TimezonesField: React.FC<TimezoneFieldProps> = ({
  timezones,
  compact,
  name = 'timezone',
  format,
  parse,
  defaultValue,
}) => {
  const [isTimezoneInputFocused, setIsTimezoneFocused] = useState<boolean>(false);
  return (
    <NvField
      name={name}
      validators={[isRequired()]}
      showErrorMessageOnlyWhenBlur
      isAutoComplete
      format={format}
      parse={parse}
      defaultValue={defaultValue}
      component={
        <NvAutocomplete
          renderInput={(props) => {
            const inputProps = props.inputProps as typeof props.inputProps & { value: string };
            const regex = /(?<displayValue>\(UTC .*\))/;
            let value = inputProps.value;
            const result = inputProps.value.match(regex)?.groups?.displayValue;
            if (!isTimezoneInputFocused && result) {
              value = result;
            }
            return (
              <NvTextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  onFocus: (e: React.FocusEvent<HTMLInputElement, Element>) => {
                    props.inputProps.onFocus?.(e);
                    setIsTimezoneFocused(true);
                  },
                  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => {
                    props.inputProps.onBlur?.(e);
                    setIsTimezoneFocused(false);
                  },
                  value,
                }}
                placeholder="Select timezone"
              />
            );
          }}
          getOptionLabel={(o) => o.displayName}
          options={timezones}
          disableClearable
          compact={compact}
        />
      }
    />
  );
};
