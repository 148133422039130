import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS } from '../keys';

type RemoveCacheForDynamicInputParametersBaseProps = {
  actionId: string;
  integrationId: string;
  inputParameterId: string;
  draft?: boolean;
  versionNumber?: number;
  formId: string;
};

type RemoveCacheForDynamicInputParametersInWorkflowProps = RemoveCacheForDynamicInputParametersBaseProps & {
  type: 'in-workflow';
  appId: string;
  workflowId: string;
};
type RemoveCacheForDynamicInputParametersInIntegrationProps = RemoveCacheForDynamicInputParametersBaseProps & {
  type: 'in-integration';
  connectionId?: string;
};

type RemoveCacheForDynamicInputParametersProps =
  | RemoveCacheForDynamicInputParametersInWorkflowProps
  | RemoveCacheForDynamicInputParametersInIntegrationProps;

export const useResetDynamicInputParameters = () => {
  const client = useQueryClient();

  const removeCacheForDynamicInputParameters = useCallback(
    ({
      actionId,
      draft,
      inputParameterId,
      integrationId,
      versionNumber,
      formId,
      ...rest
    }: RemoveCacheForDynamicInputParametersProps) => {
      client.removeQueries(
        QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS.withParameterId({
          ...rest,
          integrationId,
          actionId,
          payload: {
            inputParameterId,
            draft,
            versionNumber,
            formId,
          },
        })
      );
    },
    [client]
  );

  return { removeCacheForDynamicInputParameters };
};
