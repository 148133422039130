import { DELETE_JOB_SAMPLE_RESPONSE } from '../../../../../common/constants';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { JobID } from '../common-fields/job-id';
import { JobSampleResponse } from '../common-fields/sample-response';

export const DeleteJobFields = () => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  return (
    <>
      <JobID context={workflowCodeInputContext} />
      <JobSampleResponse content={DELETE_JOB_SAMPLE_RESPONSE} />
    </>
  );
};
