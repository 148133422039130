import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ReactQueryDevToolsContainerElement } from './styled';

export const NvReactQueryDevTools: typeof ReactQueryDevtools = (props) => (
  <ReactQueryDevtools
    initialIsOpen={false}
    containerElement={ReactQueryDevToolsContainerElement}
    toggleButtonProps={{ className: 'react-dev-tools-toggle-button' }}
    {...props}
  />
);
