import {
  CheckConnectionValidationResponse,
  useResetWorkflowSourceOptions,
  WorkflowState,
  WorkflowWithState,
} from '@novaera/actioner-service';
import { useNvDialogModalState } from '@novaera/core';
import { useCheckConnections } from '../use-check-connections';

export const useWorkflowRun = ({ workflow }: { workflow?: WorkflowWithState }) => {
  const {
    isOpened: isRunModalOpened,
    onModalCloseClicked: onRunModalCloseClicked,
    onModalOpenClicked: onRunModalOpenedClicked,
  } = useNvDialogModalState();
  const { removeWorkflowSourceOptionsCacheForApp } = useResetWorkflowSourceOptions();
  const {
    isOpened: isConnectionMappingModalOpened,
    onModalCloseClicked: onConnectionMappingModalClosed,
    onModalOpenClicked: isConnectionMappingOpenClicked,
    passedParameters,
  } = useNvDialogModalState<CheckConnectionValidationResponse>();

  const { checkConnections } = useCheckConnections({
    isDraft: workflow?.state === WorkflowState.DRAFT,
  });

  const handleWorkflowRunClicked = async () => {
    const result = await checkConnections();
    if (
      workflow?.trigger?.type === 'form' &&
      result?.invalidNodeConnections &&
      result?.invalidNodeConnections?.length > 0
    ) {
      isConnectionMappingOpenClicked(result);
    } else {
      onRunModalOpenedClicked();
      if (workflow && workflow.trigger?.type === 'form') {
        removeWorkflowSourceOptionsCacheForApp({ appId: workflow.appId });
      }
    }
  };

  const onConnectionsCompleted = () => {
    onRunModalOpenedClicked();
    if (workflow && workflow.trigger?.type === 'form') {
      removeWorkflowSourceOptionsCacheForApp({ appId: workflow.appId });
    }
  };

  return {
    onRunClick: handleWorkflowRunClicked,
    isRunModalOpened,
    onRunModalCloseClicked,
    isConnectionMappingModalOpened,
    onConnectionMappingModalClosed,
    connectionValidationCheckResult: passedParameters,
    onRunModalOpenedClicked,
    onConnectionsCompleted,
  };
};
