import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const IntegrationBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  align-items: center;
`;

export const EndpointWrapper = styled(NvBox)`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  border-radius: 6px;
  flex: 1 1 auto;
  min-width: 0;
`;
