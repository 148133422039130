import { isValidElement } from 'react';
import { NvTypography } from '../../typography';
import { EMPTY_STATE_TEXT } from '../constants';
import { TableEmptyState } from '../styled';
import { RenderCellParams, RenderHeaderParams } from '../types';
import { CellTypography, CellWrapper } from './styled';

export const defaultRenderCell = ({ formattedValue }: RenderCellParams) => (
  <CellWrapper>
    {isValidElement(formattedValue) ? formattedValue : <CellTypography>{formattedValue}</CellTypography>}
  </CellWrapper>
);

export const defaultRenderHeader = ({ colDef: { headerName } }: RenderHeaderParams) => (
  <NvTypography variant="h7" noWrap>
    {headerName}
  </NvTypography>
);

export const DataGridEmptyState = () => (
  <TableEmptyState>
    <NvTypography>{EMPTY_STATE_TEXT}</NvTypography>
  </TableEmptyState>
);
