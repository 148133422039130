import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ConnectionSchema } from '..';
import { INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_SCHEMA } from '../keys';
import { UpdateIntegrationConnectionParams } from './types';

const updateConnection = async (params: UpdateIntegrationConnectionParams) => {
  const { integrationId, ...rest } = params;
  const result = await NvAxios.put<ConnectionSchema>(
    `${INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH(integrationId)}/${params.connection.id}`,
    { ...rest.connection }
  );
  return result?.data;
};

export const useUpdateConnectionSchemaService = () => {
  const cache = useQueryClient();
  return useMutation(updateConnection, {
    onSuccess(data, variables) {
      cache.setQueryData(
        QUERY_KEYS_CONNECTION_SCHEMA.connection_schema(variables.connection.id, variables.integrationId),
        () => {
          return data;
        }
      );
    },
  });
};
