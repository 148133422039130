import { JobOperation, NodeExecution, NodeUnionTypeEnumLike } from '@novaera/actioner-service';
import { NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { useJobDescriptor } from '../../../../workflow-designer/user-app-workflow-canvas/common/use-job-descriptor';

export const WorkflowHistoryNodeExecutionHeader: React.FC<Pick<NodeExecution, 'type' | 'subtype'>> = ({
  type,
  subtype,
}) => {
  const theme = useTheme();
  const { label: jobLabel } = useJobDescriptor(
    type === NodeUnionTypeEnumLike.job ? { jobOperationType: subtype as JobOperation['type'] } : {}
  );
  switch (type) {
    case NodeUnionTypeEnumLike.output:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.output}>
          Output
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.job:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.job}>
          {jobLabel ?? 'Job'}
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.integrationApp:
    case NodeUnionTypeEnumLike.integrationWebhook:
    case NodeUnionTypeEnumLike.form:
    case NodeUnionTypeEnumLike.genericWebhook:
    case NodeUnionTypeEnumLike.email:
    case NodeUnionTypeEnumLike.actionerEvent:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.trigger}>
          Trigger
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.actionerEventPublisher:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.action}>
          Actioner event
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.action:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.action}>
          Action
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.loop:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.operator}>
          Loop
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.workflowCondition:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.operator}>
          Condition
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.branchJunction:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.operator}>
          Branch
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.delay:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.operator}>
          Delay
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.function:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.function}>
          Function
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.response:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.response}>
          Response
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.workflowDispatcher:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.workflow}>
          Workflow
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.sendEmail:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.sendEmail}>
          Send Email
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.workflowResolver:
    case NodeUnionTypeEnumLike.aiKnowledge:
    case NodeUnionTypeEnumLike.assistant:
    case NodeUnionTypeEnumLike.getConversation:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.ai}>
          AI
        </NvTypography>
      );
    case NodeUnionTypeEnumLike.file:
      if (subtype === 'list') {
        return (
          <NvTypography variant="h8" color={theme.palette.nv_node.file}>
            List file
          </NvTypography>
        );
      } else if (subtype === 'put') {
        return (
          <NvTypography variant="h8" color={theme.palette.nv_node.file}>
            Put file
          </NvTypography>
        );
      } else if (subtype === 'transfer') {
        return (
          <NvTypography variant="h8" color={theme.palette.nv_node.file}>
            Transfer file
          </NvTypography>
        );
      } else if (subtype === 'change-accessibility') {
        return (
          <NvTypography variant="h8" color={theme.palette.nv_node.file}>
            Update file privacy
          </NvTypography>
        );
      } else if (subtype === 'delete') {
        return (
          <NvTypography variant="h8" color={theme.palette.nv_node.file}>
            Delete file
          </NvTypography>
        );
      } else {
        return (
          <NvTypography variant="h8" color={theme.palette.nv_node.sendEmail}>
            File
          </NvTypography>
        );
      }
    case NodeUnionTypeEnumLike.linkGenerator:
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.linkGenerator}>
          Link generator
        </NvTypography>
      );
    default:
      return null;
  }
};
