import { Organization } from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvButton,
  NvFlex,
  NvImage,
  NvMenuWithItems,
  NvSearchEmptyState,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { getSubdomain } from '@novaera/utils';
import { WorkspaceInitial } from '../../../components/workspace-item/initial';
import { WorkspaceCard, WorkspaceListWrapper } from '../styled';

type WorkspaceListProps = {
  workspaceMappings: Organization[];
  onSeeMoreClicked: () => void;
  hasNextPage: boolean;
  hasSearchKeyword?: boolean;
  isLoading?: boolean;
};

export const WorkspaceList = ({
  workspaceMappings,
  onSeeMoreClicked,
  hasNextPage,
  hasSearchKeyword,
  isLoading,
}: WorkspaceListProps) => {
  return (
    <>
      <WorkspaceListWrapper>
        {workspaceMappings.map((workspace) => {
          return (
            <WorkspaceCard key={workspace.workspaceId}>
              <NvFlex flex={'1 1 auto'} minWidth={'0'} flexDirection={'row'} gap="8px" alignItems={'center'}>
                <NvImage
                  FallBack={
                    <WorkspaceInitial
                      size="medium"
                      subdomain={workspace.workspaceSubdomain}
                      displayName={workspace.workspaceName}
                    />
                  }
                  imageShape="square"
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  src={workspace.logoUrl}
                />
                <NvTypography variant="h3">{workspace.workspaceName}</NvTypography>
              </NvFlex>
              <NvMenuWithItems
                menuItems={[
                  {
                    name: 'Go to workspace',
                    onClick: () => {
                      const { host, protocol } = window.location;
                      const splittedHostVals = host.split('.');
                      let newHost = host;
                      if (splittedHostVals[0] === getSubdomain()) {
                        newHost = splittedHostVals.slice(1).join('.');
                      }
                      window.open(`${protocol}//${workspace.workspaceSubdomain}.${newHost}`, '_blank');
                    },
                    icon: <NvArrowForwardIcon sx={{ width: '16px', height: '16px' }} />,
                  },
                ]}
              ></NvMenuWithItems>
            </WorkspaceCard>
          );
        })}
      </WorkspaceListWrapper>
      {hasNextPage && (
        <NvButton size="small" color="secondary" isFitContent onClick={onSeeMoreClicked}>
          See more
        </NvButton>
      )}
      {!isLoading && hasSearchKeyword && workspaceMappings.length === 0 && (
        <NvSearchEmptyState text="No workspace found. Try different words or clear search bar." />
      )}
      {isLoading && (
        <NvFlex gap={'16px'} flexDirection={'row'}>
          <NvSkeleton variant="rectangular" height={'40px'} width={'100%'} />
          <NvSkeleton variant="rectangular" height={'40px'} width={'100%'} />
        </NvFlex>
      )}
    </>
  );
};
