import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_EVENT_RULES } from '../keys';
import { GetIntegrationEventRulesResponse } from '../use-get-integration-event-rules/types';
import { CreateIntegrationEventRuleParams, CreateIntegrationEventRuleResponse } from './types';

const createIntegrationEventRule = async (params: CreateIntegrationEventRuleParams) => {
  const { integrationId, ...rest } = params;
  const result = await NvAxios.post<CreateIntegrationEventRuleResponse>(
    INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId),
    { eventFilter: { condition: { type: 'no-condition' } }, ...rest }
  );
  return result?.data;
};

export const useCreateIntegrationEventRule = () => {
  const cache = useQueryClient();

  return useMutation(createIntegrationEventRule, {
    onSuccess: (eventRule, { integrationId }) => {
      cache.setQueryData<GetIntegrationEventRulesResponse>(
        QUERY_KEYS_INTEGRATION_EVENT_RULES.list(integrationId),
        (old) => {
          if (old) {
            return { ...old, eventRules: [...old.eventRules, eventRule] };
          }
          return old;
        }
      );
    },
  });
};
