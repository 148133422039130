import { NovaeraNode } from '@novaera/core';
import { AppWorkflowNodeType } from '../../../../components/user-app-workflow-node-types/types';

import { userAppGraph } from '../../../../graph-utils/user-app-graph';

export const addButtonNode = ({ alias, nextNodeAlias }: { alias: string; nextNodeAlias?: string }) => {
  const buttonNode: NovaeraNode<AppWorkflowNodeType> = {
    id: `add-button-for-${alias}`,
    name: `add-button-for-${alias}`,
    width: 0,
    height: 0,
    type: 'AddButton',
    alias: `add-button-for-${alias}`,
    parent: alias,
  };

  if (nextNodeAlias) {
    userAppGraph.addEdge({
      sourceId: buttonNode.alias,
      targetId: nextNodeAlias,
      id: `${alias}-${buttonNode.alias}`,
      extraData: { type: 'Edge', centerPosition: 'bottom' },
    });
  }

  userAppGraph.addNode(buttonNode);

  return buttonNode.alias;
};
