import { NodeUnionTypeEnumLike, ResponseNode, ResponseNodeSummary, ResponseVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class ResponseComponentFactory implements ComponentFactory<ResponseNode> {
  aliasForResponse: string;
  nameForResponse: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias(NodeUnionTypeEnumLike.response);
    this.aliasForResponse = newAlias;
    this.nameForResponse = newName;
  }

  get componentForGraph() {
    const functionRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForResponse,
      width: 40,
      height: 40,
      id: this.aliasForResponse,
      type: NodeUnionTypeEnumLike.response,
      alias: this.aliasForResponse,
    };

    return { root: functionRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: ResponseNode) {
    const newNodeUnion: ResponseVertex = {
      type: NodeUnionTypeEnumLike.response,
      alias: this.aliasForResponse,
    };
    const summary: ResponseNodeSummary = {
      name: this.nameForResponse,
      alias: this.aliasForResponse,
      type: NodeUnionTypeEnumLike.response,
    };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForResponse]: summary } };
  }
}
