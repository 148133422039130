import { NvCustomManagedAppsIcon, NvFlex, NvTooltip, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

export const ManagedAppIcon = ({ iconTheme }: { iconTheme: 'light' | 'dark' }) => {
  const theme = useTheme();

  return (
    <NvTooltip title={<NvTypography variant="body2">This app is managed by Actioner.</NvTypography>}>
      <NvFlex>
        <NvCustomManagedAppsIcon
          htmlColor={iconTheme === 'light' ? theme.palette.nv_main[40] : theme.palette.nv_neutral[60]}
        />
      </NvFlex>
    </NvTooltip>
  );
};
