import { LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { assert } from '@novaera/utils';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useInterval } from 'usehooks-ts';
import { REFETCH_INTERVAL, TokenState } from '../../common/constants';
import { setAccessToken as persistAccessToken } from '../../common/utils';
import { refreshAccessToken } from '../../services/endpoints';

export const useRefreshAccessToken = () => {
  const { mutate: refresh } = useMutation(() => refreshAccessToken(Cookies.get(LOCAL_STORAGE_ACCESS_KEY)), {
    onSuccess: (response) => {
      TokenState.expired = false;
      TokenState.isGettingNewToken = false;

      assert(!!response, new Error('[useRefreshAccessToken] - response can not be undefined.'), 'ERROR');
      persistAccessToken(response.data.accessToken);
    },
  });

  useInterval(refresh, REFETCH_INTERVAL);
};
