import { NvBox } from '@novaera/core';
import { styled, withAlpha } from '@novaera/theme-provider';

export const EmptyStateWrapper = styled(NvBox)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 40px;
  min-height: 400px;
  width: 100%;
  overflow: hidden;
`;

export const EmptyStateBackground = styled(NvBox)`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.palette.nv_neutral[20]} 0%,
    ${({ theme }) => withAlpha(theme.palette.nv_neutral[20], 0)} 70%
  );
  width: 480px;
  height: 480px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateContentBox = styled(NvBox)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  padding: 8px;
  max-width: 400px;
  width: 100%;
  z-index: 10;
`;
