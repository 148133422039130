import { SearchWorkflow, useSearchInfiniteWorkflows } from '@novaera/actioner-service';
import { NvBox, NvNodeList, useDebounce } from '@novaera/core';
import { useParams } from '@novaera/route';
import { FC, useMemo, useState } from 'react';
import { DropdownSimpleSearchInput } from '../../../../../components/simple-search-input/styled';
import { convertAssistantItemToNodeListItem } from '../../utils';
import { WorkflowSelectProps } from './types';

export const WorkflowSelect: FC<WorkflowSelectProps> = ({ existingWorkflowIds, onSelectWorkflow }) => {
  const [keyword, setKeyword] = useState<string | undefined>('');
  const debouncedKeyword = useDebounce(keyword, 500);
  const { userAppId } = useParams();
  const { data: getWorkflowsQueries, isLoading } = useSearchInfiniteWorkflows({
    appIds: [userAppId],
    keyword: debouncedKeyword,
    ...(debouncedKeyword?.length === 0 ? { sort: { order: 'asc', field: 'name' } } : {}),
    triggerTypes: ['form'],
    from: 0,
    includeSearchInvisible: true,
  });

  const workflows = useMemo(
    () =>
      convertAssistantItemToNodeListItem<SearchWorkflow>({
        items: getWorkflowsQueries?.pages[0]?.workflows ?? [],
        existingItemIds: existingWorkflowIds,
      }),
    [existingWorkflowIds, getWorkflowsQueries?.pages]
  );

  return (
    <NvBox width="730px">
      <DropdownSimpleSearchInput autoFocus placeholder="Search names & tags" onKeywordChanged={setKeyword} />
      <NvNodeList
        nodes={workflows ?? []}
        onItemClick={(e, item) => {
          onSelectWorkflow(item.extraData as SearchWorkflow);
        }}
        isLoading={isLoading}
      />
    </NvBox>
  );
};
