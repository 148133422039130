import Link from '@mui/material/Link';
import { styled } from '@novaera/theme-provider';

export const NvLinkWrapper = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_main[60]};
  text-decoration: none;
  font-weight: 600;

  &.ghost {
    color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
    text-decoration: underline;
    font-weight: 400;
  }

  &.inherit {
    color: inherit;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.nv_main[50]};
    text-decoration: underline;

    &.inherit {
      color: inherit;
      text-decoration: none;
    }
  }
`;
