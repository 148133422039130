import { MultiValueRule, SingleValueRule } from '../condition-types';

export type SlackBlock = {
  type: 'text' | 'action-references';
  label: string;
  value?: string;
  defaultValue?: UIComponentDefaultValue;
  actionReferences?: RequestActionReference[];
};

export type UIComponentDefaultValue = {
  type: 'string' | 'stringList' | 'boolean' | 'number';
  codeTemplate?: string;
  value?:
    | {
        type: 'string' | 'stringList' | 'boolean' | 'number';
        value: string;
        displayValue?: string;
      }[]
    | string;
};

export interface RequestActionReference {
  id: string;
  referredActionName: string;
  referredActionId: string;
  executeInPlace: boolean;
  inputParameterMappings: ParameterMapping[];
  displayConfiguration: {
    componentType: 'button' | 'link';
    variant: 'contained';
    size: 'small' | 'medium';
    color: 'primary' | 'secondary';
    text: string;
  };
}

export type ExecutionContextObjectValue =
  | InputDefinitionList
  | InputDefinitionPrimitive
  | InputDefinitionObject
  | InputDefinitionObjectList;

export interface ParameterMapping {
  parameterId: string;
  value: ExecutionContextObjectValue | CodeTemplateListValueType | CodeTemplateObjectValueType | CodeStringValueType;
}

export const enum ExecutionContextValueType {
  STRING = 'string',
  NUMBER = 'number',
  OBJECT_LIST = 'objectList',
  BOOLEAN = 'boolean',
  LIST = 'list',
  OBJECT = 'object',
  MAP = 'map',
}

export interface InputDefinitionList {
  type: ExecutionContextValueType.LIST;
  value: (InputDefinitionObject | InputDefinitionPrimitive | InputDefinitionObjectList)[];
}

export interface InputDefinitionPrimitive {
  type: ExecutionContextValueType.BOOLEAN | ExecutionContextValueType.STRING | ExecutionContextValueType.NUMBER;
  value: boolean | string | number;
  displayValue: string;
}

export interface InputDefinitionObject {
  type: ExecutionContextValueType.OBJECT;
  value: Record<string, unknown>;
  displayValue: string;
}

export interface InputDefinitionObjectList {
  type: ExecutionContextValueType.OBJECT_LIST;
  value: Record<string, unknown>[];
  displayValue: string;
}

export interface CodeTemplateListValueType {
  type: ExecutionContextValueType.LIST;
  codeTemplate: string;
}

export interface CodeStringValueType {
  type: ExecutionContextValueType.STRING;
  value: string;
}

export interface CodeTemplateObjectValueType {
  type: ExecutionContextValueType.OBJECT;
  codeTemplate: string;
}

export const isMultiValueRule = (ruleValue?: MultiValueRule | SingleValueRule): ruleValue is MultiValueRule => {
  return (ruleValue as MultiValueRule)?.type === 'list';
};

export type SlackWorkspace = {
  iconUrl: string;
  settings: {
    syncSlackUsersToActioner: boolean;
    slackUsersCanJoinWithoutConfirmation: boolean;
  };
  slackTeamLink: string;
  teamId: string;
  teamName: string;
  workspaceId: string;
  enterpriseInstallation: boolean;
};

export interface ExecuteActionValues {
  executionContext: ExecutionContext;
}

export interface ExecutionContext {
  input: {
    type: ExecutionContextValueType.MAP;
    value: ExecutionContextInputValue;
  };
}

export type ExecutionContextInputValue = Record<string, ExecutionContextObjectValue>;

export type DefaultInputValueType = {
  type: string;
  value: { type: string; value: unknown; displayValue?: string }[] | string | number;
  codeTemplate?: string;
  displayValue?: string;
};

export enum SchemaType {
  'phone' = 'phone',
  'integer' = 'integer',
  'boolean' = 'boolean',
  'float' = 'float',
  'dateTime' = 'date-time',
  'url' = 'url',
  'uuid' = 'uuid',
  'email' = 'email',
  'string' = 'string',
  'array' = 'array',
  'map' = 'map',
  'recordReference' = 'model-reference',
  'object' = 'object',
}

export enum ConstraintType {
  'stringSize' = 'string-size',
  'listSize' = 'list-size',
  'mapSize' = 'map-size',
  'regex' = 'regex',
}

export enum DateTimeFormat {
  'iso_8601' = 'iso_8601',
  'utc' = 'utc',
}

export interface Option {
  identifier: unknown;
  displayValue: string;
}
