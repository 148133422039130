import {
  useDeleteDocument,
  useFetchFromS3,
  useGetDocument,
  useUpdateDocument,
  useUpdateDocumentContent,
} from '@novaera/actioner-service';
import { NvButton, NvTypography, isAxiosError, useConfirmDialog } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useMemo, useState } from 'react';
import { useIsAppFree } from '../../../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { USER_APP_DOCUMENTS } from '../../../../constants';

export const useDocumentDetail = () => {
  const { userAppId, docId } = useParams<{ userAppId: string; docId: string }>();

  const [content, setContent] = useState<string | undefined>();

  const { data: document, isLoading: isGetDocumentLoading } = useGetDocument({ appId: userAppId, docId });
  const { mutate: deleteDocument, isLoading: isDeleteLoading } = useDeleteDocument();
  const { mutate: updateDocument, isLoading: isUpdateLoading } = useUpdateDocument();
  const { mutate: updateDocumentContent, isLoading: isUpdateDocumentContentLoading } = useUpdateDocumentContent();
  const { isAppFree } = useIsAppFree({ userAppId });

  const { isLoading: isContentLoading } = useFetchFromS3({
    key: ['app-doc-s3', userAppId, docId],
    enabled: Boolean(document?.contentUrl),
    link: document?.contentUrl,
    onSettled: (s3Data) => {
      const data = s3Data?.data;
      if (!content) {
        setContent(data);
      }
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 404) {
        setContent(undefined);
      }
    },
    staleTime: 0,
  });

  const { openConfirm, closeConfirm } = useConfirmDialog();
  const navigate = useNavigate();

  const handleDeleteDocument = (documentName: string) => {
    openConfirm({
      title: `Delete ${documentName ?? 'Document'}`,
      message: (
        <NvTypography variant="body1">
          If you delete this document, any workflows associated with will be impacted. Do you want to proceed?
        </NvTypography>
      ),
      onConfirm: () => {
        deleteDocument(
          { appId: userAppId, docId },
          {
            onSuccess: () => {
              navigate(USER_APP_DOCUMENTS(userAppId));
            },
          }
        );
      },
    });
  };

  const handleBackClickConfirmation = () => {
    openConfirm({
      title: 'You have unsaved changes',
      message:
        'If you leave this page without saving the document, you will loose the recent changes. Do you want to proceed?',
      icon: <></>,
      onClose: () => {
        closeConfirm();
      },
      confirmButtonLabel: 'Discard Changes',
      confirmButton: (
        <NvButton
          color="primary"
          onClick={() => {
            closeConfirm();
            navigate(USER_APP_DOCUMENTS(userAppId));
          }}
        >
          Discard Changes
        </NvButton>
      ),
      cancelButtonLabel: 'Cancel',
    });
  };

  const handleUpdateDocumentContent = (value: { data?: string }) => {
    updateDocumentContent(
      {
        appId: userAppId,
        docId,
        payload: {
          data: value.data,
        },
      },
      {
        onSuccess: () => {
          setContent(value.data);
        },
      }
    );
  };

  const initialValue = useMemo(() => {
    return {
      data: content,
    };
  }, [content]);

  return {
    document,
    onDeleteDocument: handleDeleteDocument,
    updateDocument,
    userAppId,
    docId,
    handleBackClickConfirmation,
    isUpdateLoading,
    isUpdateDocumentContentLoading,
    isDeleteLoading,
    isContentLoading: document?.contentUrl ? isContentLoading : false,
    initialValue,
    onDocumentContentChange: handleUpdateDocumentContent,
    isGetDocumentLoading,
    isAppFree,
  };
};
