export enum WorkspaceStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  WAITING_DELETION = 'waiting-deletion',
}

export type Workspace = {
  id: string;
  subdomain: string;
  displayName: string;
  logoUrl?: string;
  status: WorkspaceStatus;
};
