import { NvAxios, useQuery } from '@novaera/core';
import { assert } from '@novaera/utils';
import { BatchGetUsersParams, BatchGetUsersResponse } from './types';

const batchGetUsers = async (userIds?: BatchGetUsersParams) => {
  assert(!userIds || userIds?.length <= 40, new Error('Batch get can be done only for 40 items'), 'ERROR');
  const result = await NvAxios.post<BatchGetUsersResponse>(`/v1/workspaces/users/batch-get`, { userIds });
  return result?.data;
};

export const useBatchGetUsers = (userIds?: BatchGetUsersParams) => {
  return useQuery([userIds], () => batchGetUsers(userIds), { enabled: !!userIds && userIds.length > 0 });
};
