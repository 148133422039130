import { MediaTypes } from '@novaera/actioner-service';
import {
  ContentTypeWrapper,
  FieldWrapper,
  KeyWrapper,
  NvField,
  NvListSubheader,
  NvMenuItem,
  NvSelect,
  NvTypography,
  ValueGroupWrapper,
  ValueWrapper,
  useField,
  useForm,
} from '@novaera/core';
import { useMemo } from 'react';
import { IntegrationCodeField } from '../../../../../integration-code-field';

export const RequestFormDataInput: React.FC<React.PropsWithChildren<{ name?: string }>> = ({ name }) => {
  const bodyFormatOptions = useMemo(() => {
    const mediaTypes = Object.values(MediaTypes).map((m) => ({ label: m, value: m }));
    return [
      {
        value: 'group-title',
        label: 'Content types',
      },
      ...mediaTypes,
      {
        value: 'group-title',
        label: 'File upload',
      },
      {
        label: 'Amazon S3',
        value: true,
      },
    ];
  }, []);

  const {
    input: { value: contentTypeValue },
  } = useField(`${name}.contentType`);
  const {
    input: { value: s3KeyValue },
  } = useField(`${name}.s3`);

  const { change } = useForm();

  const isS3File = useMemo(() => s3KeyValue, [s3KeyValue]);

  const handleCustomSelectChange = (value: MediaTypes | boolean) => {
    if (typeof value === 'boolean') {
      change(`${name}.contentType`, null);
      change(`${name}.s3`, value);
    } else {
      change(`${name}.contentType`, value);
      change(`${name}.s3`, null);
    }
  };

  const customSelectValue = useMemo(() => {
    if (!isS3File && !contentTypeValue) {
      return MediaTypes.APPLICATION_JSON;
    } else if (isS3File) {
      return true;
    } else {
      return contentTypeValue;
    }
  }, [isS3File, contentTypeValue]);

  return (
    <FieldWrapper>
      <KeyWrapper maxWidth={'300px'}>
        <NvField
          formControlStyle={{
            width: '100%',
          }}
          name={`${name}.key`}
          component={<IntegrationCodeField placeholder="Key" expectedType="string" />}
        />
      </KeyWrapper>
      <ValueGroupWrapper>
        <ValueWrapper>
          <NvField
            formControlStyle={{
              width: '100%',
            }}
            name={`${name}.value`}
            component={<IntegrationCodeField placeholder="Value" expectedType="string" />}
          />
        </ValueWrapper>
        <ContentTypeWrapper>
          <NvSelect
            sx={{
              width: 'inherit',
            }}
            value={customSelectValue}
            onChange={(event) => handleCustomSelectChange(event.target.value)}
          >
            {bodyFormatOptions.map((option, index) => {
              if (option.value === 'group-title') {
                return (
                  <NvListSubheader
                    sx={{
                      paddingTop: '16px',
                      paddingBottom: '4px',
                      lineHeight: 'initial',
                    }}
                    key={index}
                  >
                    {option.label}
                  </NvListSubheader>
                );
              } else {
                return (
                  <NvMenuItem key={index} value={option.value as string}>
                    <NvTypography noWrap> {option.label}</NvTypography>
                  </NvMenuItem>
                );
              }
            })}
          </NvSelect>
        </ContentTypeWrapper>
      </ValueGroupWrapper>
    </FieldWrapper>
  );
};
