import { ConnectionSearchPayload } from './use-search-connections/types';

export const QUERY_KEYS_CONNECTION = {
  all: ['connection'] as const,
  list: () => [...QUERY_KEYS_CONNECTION.all],
  detail: (id?: string) => [...QUERY_KEYS_CONNECTION.list(), 'detail', ...(id ? [id] : [])] as const,
  search: (payload: Pick<ConnectionSearchPayload, 'schemaId'>) => ['search', payload.schemaId] as const,
  connectionValidation: (appId: string, workflowId: string, isDraft?: boolean) =>
    ['connectionValidation', appId, workflowId, isDraft] as const,
};
