import { styled } from '@novaera/theme-provider';
import { NvCodeMirror } from '../styled';

export const SqlCodeMirrorStartAdornmentBox = styled('div')`
  position: absolute;
  left: 6px;
  top: 12px;
  z-index: 10;
  pointer-events: none;
`;

export const SqlCodeMirror = styled(NvCodeMirror, {
  shouldForwardProp: (prop) => prop !== 'startAdornmentOffset',
})<{
  startAdornmentOffset?: number;
}>`
  &.NvCodeInput {
    .CodeMirror {
      height: auto;
      min-height: 56px;
      background: none;
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border: none;
      border-radius: 0;
      padding: 0;
      box-shadow: none;

      // Font Family
      font-family: Fira Code, monospace;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.nv_neutral[1000]};

      &-focused {
        border: none;
        box-shadow: none;

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]} !important;
          border: none;
        }
      }

      &-lines {
        padding: ${({ startAdornmentOffset }) =>
          startAdornmentOffset ? `19px 8px 19px ${startAdornmentOffset + 8}px;` : '19px 8px;'};
      }

      &-cursor {
        border-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      }

      &-code {
      }

      &-line {
        span {
          height: 15px;
          margin: 0;
          padding: 0;
          border: 0;
          vertical-align: baseline;
          padding-top: 1px;
        }
      }

      &-scroll {
        overflow: hidden !important;
        max-height: 182px;
      }

      //color theme

      &-placeholder {
        color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
      }

      &-activeline-background {
        background: #ffffff0d;
      }

      &-selected {
        background: #b7dce8;
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }

      .cm-novaera_expression {
        background-color: rgba(153, 239, 192, 0.15);

        &.cm-undefined,
        &.cm-novaera_base {
          color: ${({ theme }) => theme.palette.nv_code.green};
        }
        &.cm-string {
          color: ${({ theme }) => theme.palette.nv_neutral[1000]};
        }

        &.cm-variable {
          color: ${({ theme }) => theme.palette.nv_code.purple};
        }

        &.cm-property {
          color: ${({ theme }) => theme.palette.nv_code.brown};
        }

        &.cm-def {
          color: ${({ theme }) => theme.palette.nv_code.green};
        }

        &.cm-variable-2 {
          color: ${({ theme }) => theme.palette.nv_code.green};
        }

        &.cm-operator {
          color: ${({ theme }) => theme.palette.nv_code.orange};
        }

        &.CodeMirror-lint-mark-error {
          color: ${({ theme }) => theme.palette.nv_error[40]};

          &.cm-novaera_expression {
            background-color: rgba(251, 236, 245, 0.5);
          }
        }
      }
    }
  }
`;
