import { usePathCondition, UsePathConditionParams } from '@novaera/utils';
import React, { useMemo, useState } from 'react';
import { Location, useNavigate } from 'react-router-dom';
import { NvFlex } from '../../flex';

import classNames from 'classnames';
import { NvIconLabel } from '../../icon-label';
import { NvSkeleton } from '../../skeleton';
import { HeaderWithTabLayoutStyled, NvTabsWrapper, Title } from './styled';
import { HeaderWithTabLayoutProps } from './types';

export const HeaderWithTabLayout: React.FC<HeaderWithTabLayoutProps> = ({
  title,
  tabs,
  variant,
  className,
  loading,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>();
  const navigate = useNavigate();

  const conditions: UsePathConditionParams = useMemo(() => {
    const availablePathConditions = tabs.map((tab, index) => ({
      when: tab.path,
      do: () => setSelectedTab(index),
    }));

    const notAvailablePathCondition = {
      when: ({ pathname }: Location) => !tabs.find((tab) => tab.path === pathname),
      do: () => navigate('/error/404'),
    };

    return [...availablePathConditions, notAvailablePathCondition];
  }, [navigate, tabs]);

  usePathCondition(conditions);

  const handleActiveTabChanged = (activeIndex: number) => {
    const path = tabs[activeIndex].path;
    navigate(path);
  };

  return (
    <HeaderWithTabLayoutStyled className={classNames(className)}>
      <NvFlex maxWidth={variant === 'center-aligned' ? '1024px' : '100%'} width="100%">
        <NvFlex>
          {'label' in title ? (
            <Title>
              <NvIconLabel icon={title.icon} label={title.label} />
            </Title>
          ) : (
            title
          )}
        </NvFlex>
        {!loading && <NvTabsWrapper onActiveTabChanged={handleActiveTabChanged} focusIndex={selectedTab} tabs={tabs} />}
        {loading && (
          <NvFlex padding={'24px'} gap={'8px'}>
            <NvSkeleton width={'40%'} />
            <NvSkeleton width={'20%'} />
            <NvSkeleton width={'30%'} />
          </NvFlex>
        )}
      </NvFlex>
    </HeaderWithTabLayoutStyled>
  );
};
