import { NvAxios, useMutation, useToast } from '@novaera/core';
import { ASSISTANTS_ROOT_PATH } from '../constants';

const syncAssistant = async ({ appId }: { appId: string }) => {
  const result = await NvAxios.post(`${ASSISTANTS_ROOT_PATH(appId)}/sync`);
  return result?.data;
};

export const useSyncAssistant = () => {
  const { addToast } = useToast();
  return useMutation(syncAssistant, {
    onSuccess: () => {
      addToast('AI assistant updated successfully!', { variant: 'success' });
    },
  });
};
