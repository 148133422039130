import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, LOCAL_STORAGE_ACCESS_KEY, ROUTE_DEFAULTS } from '@novaera/constants';
import { useLocation, useNavigate } from '@novaera/route';
import { removeCookieForSpecificDomain } from '@novaera/utils';
import { useCallback } from 'react';

export const useIdentityProvider = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const redirectToLogin = async () => {
    removeCookieForSpecificDomain(LOCAL_STORAGE_ACCESS_KEY, `${ApplicationConfig.Actioner.host}`);
    removeCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, `${ApplicationConfig.Actioner.host}`);
    window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.LOG_OUT}`;
  };

  const redirectToLastState = useCallback(
    async () => {
      if (state) {
        navigate('.', { state });
      } else {
        navigate('/');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location, navigate]
  );

  return {
    redirectToLogin,
    redirectToLastState,
  };
};
