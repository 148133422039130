import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomTextField({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.39286 6C3.62214 6 3 5.33 3 4.5C3 3.67 3.62214 3 4.39286 3H14.6071C15.3779 3 16 3.67 16 4.5C16 5.33 15.3779 6 14.6071 6H10.8929V19C10.8929 19.83 10.2707 20.5 9.5 20.5C8.72929 20.5 8.10714 19.83 8.10714 19V6H4.39286Z"
        fill="currentColor"
      />
      <path d="M7 8H4C2.89543 8 2 8.89543 2 10V16C2 17.1046 2.89543 18 4 18H7V16H4V10H7V8Z" fill="currentColor" />
      <path
        d="M22 16C22 17.1046 21.1046 18 20 18H12V16H20V10H12V8H20C21.1046 8 22 8.89543 22 10V16Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
