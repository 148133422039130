import { useUpdateNode } from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { PropertyPanelHeader } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useJobDescriptor } from '../../../../common/use-job-descriptor';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { DeleteJobFields } from './delete-job-fields';
import { SaveRecurringJobFields } from './save-recurring-job-fields';
import { SaveScheduledJobFields } from './save-scheduled-job-fields';
import { JobPropertyPanelProps } from './types';

export const JobPropertyPanel = ({
  onCloseClicked,
  jobNode,
  isJobTargetPanelOpen,
  onJobTargetClicked,
}: JobPropertyPanelProps) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { hasEditPermission } = useWorkflowPermission();

  assert(
    !!jobNode,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  assert(
    jobNode.type === 'job',
    new Error(
      'Since the selected node in the graph is having type function it should be function in the workflow object'
    ),
    'ERROR'
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }

      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: jobNode.alias,
          payload: { ...jobNode, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(jobNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [jobNode, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const { icon, label } = useJobDescriptor({ jobOperationType: jobNode.operation.type });

  return (
    <NvFlex width="100%">
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={icon}
          label={label}
          title={jobNode.name}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: jobNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={jobNode.name}
          icon={icon}
          label={label}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>

      {jobNode.operation.type === 'saveRecurring' && (
        <SaveRecurringJobFields isJobTargetPanelOpen={isJobTargetPanelOpen} onJobTargetClicked={onJobTargetClicked} />
      )}
      {jobNode.operation.type === 'saveScheduled' && (
        <SaveScheduledJobFields isJobTargetPanelOpen={isJobTargetPanelOpen} onJobTargetClicked={onJobTargetClicked} />
      )}
      {jobNode.operation.type === 'delete' && <DeleteJobFields />}
    </NvFlex>
  );
};
