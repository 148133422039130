import { isRequired, NvField, NvFlex, NvSelect, NvTextArea, NvTypography, useField, useFormState } from '@novaera/core';
import { FC, useMemo } from 'react';

import { AppDirectoryItemPreviewBackground } from '../../../app-directory-detail/styled';
import { changeSizeCalculator } from '../../../utils';
import { AppDirectoryCard } from '../../app-directory-card';
import { PublishNewVersionFormData } from '../types';
import { CHANGE_SIZE_OPTIONS } from './constants';

export const ChangeLog: FC<React.PropsWithChildren<{ currentVersion: string }>> = ({ currentVersion }) => {
  const {
    values: { appDetails, changeSize },
  } = useFormState<PublishNewVersionFormData>();

  const {
    meta: { dirty: isChangeSizeDirty },
  } = useField('changeSize');

  const calculatedChangeSize = useMemo(
    () => (currentVersion && changeSize ? changeSizeCalculator(changeSize, currentVersion) : null),
    [currentVersion, changeSize]
  );

  return (
    <NvFlex gap="24px" flexDirection="row" justifyContent="center">
      <NvFlex flex="1 1 auto" minWidth={0} gap="8px">
        <NvTypography variant="h3">Change log</NvTypography>
        <NvFlex gap="24px">
          <NvFlex direction="row" spacing="32px" alignItems="flex-end">
            <NvFlex width="178px">
              <NvField
                name="changeSize"
                labelText="Change size"
                labelVariant="h5"
                direction="label-on-top"
                validators={[isRequired()]}
                showErrorMessageOnlyWhenBlur
                hasRequiredIndicator
                component={<NvSelect placeholder="Select change size" options={CHANGE_SIZE_OPTIONS} fullWidth />}
              />
            </NvFlex>

            <NvFlex direction="row" spacing="8px" flex="0 0 auto" height="32px" alignItems="center">
              <NvTypography variant="body1">
                {isChangeSizeDirty ? 'Update version to ' : 'Current version '}
              </NvTypography>
              <NvTypography variant="h3">v{isChangeSizeDirty ? calculatedChangeSize : currentVersion}</NvTypography>
            </NvFlex>
          </NvFlex>

          <NvField
            name="changeLog"
            labelText="What has changed?"
            labelVariant="h5"
            direction="label-on-top"
            validators={[isRequired()]}
            showErrorMessageOnlyWhenBlur
            hasRequiredIndicator
            component={<NvTextArea rows={3} placeholder="Describe what is updated in your app." />}
          />
        </NvFlex>
      </NvFlex>
      <NvFlex width="390px" gap="8px">
        <NvTypography variant="h3">Preview</NvTypography>
        <AppDirectoryItemPreviewBackground padding="24px">
          <AppDirectoryCard
            isPreviewCard
            title={appDetails?.appName}
            description={appDetails?.shortDescription}
            tags={[{ label: 'NEW', color: 'pink' }]}
            star="0"
            contributors={appDetails.contributors}
            logoUrl={appDetails?.logoUrl}
            isManagedApp={appDetails.managed}
            free={false}
          />
        </AppDirectoryItemPreviewBackground>
      </NvFlex>
    </NvFlex>
  );
};
