import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConfigMenuWrapper = styled(NvFlex)`
  width: 100%;
  gap: 8px;
`;

export const ConfigMenuLine = styled(NvFlex)`
  width: 1px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
  margin-left: 16px;
  margin-right: 12px;
`;

export const CollapseItemWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 0px 4px;
  gap: 4px;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  flex: 0 0 auto;
  min-width: 0;
  cursor: pointer;
  transition: background-color 300ms ease-in;
  &:hover {
    ${({ theme: { palette } }) => `background-color:${palette.nv_neutral[10]};`}
  }
`;

export const ChevronIcon = styled(NvFlex)`
  justify-content: center;
  transition: transform 300ms ease, background-color 300ms ease, rotate 400ms ease, right 300ms ease;

  &.opened {
    transform: rotate(90deg);
  }
`;
