import { BlankVertex, DefaultNodeSummary, WorkflowConditionVertex } from '@novaera/actioner-service';
import { EmptyInterface, NovaeraEdge, NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class WorkflowConditionComponentFactory implements ComponentFactory<EmptyInterface> {
  aliasForFalse: string;
  aliasForTrue: string;
  rootAlias: string;
  rootName: string;

  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newAlias, newName } = graph.getNewAlias('workflowCondition');
    this.rootName = newName;
    this.rootAlias = newAlias;
    this.aliasForFalse = `${this.rootAlias}_false`;
    this.aliasForTrue = `${this.rootAlias}_true`;
  }

  get componentForGraph() {
    const conditionRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.rootName,
      width: 40,
      height: 40,
      id: this.rootAlias,
      type: 'workflowCondition',
      alias: this.rootAlias,
    };

    const falseNode: NovaeraNode<AppWorkflowNodeType> = {
      name: 'false',
      width: 40,
      height: 40,
      id: this.aliasForFalse,
      type: 'blank',
      alias: this.aliasForFalse,
    };

    const trueNode: NovaeraNode<AppWorkflowNodeType> = {
      name: 'true',
      width: 40,
      height: 40,
      id: this.aliasForTrue,
      type: 'blank',
      alias: this.aliasForTrue,
    };

    const edge1: NovaeraEdge = {
      sourceId: this.rootAlias,
      targetId: falseNode.alias,
      id: `${this.rootAlias}-${falseNode.alias}`,
      extraData: { type: 'Edge', centerPosition: 'up', name: falseNode.name },
    };

    const edge2: NovaeraEdge = {
      sourceId: this.rootAlias,
      targetId: trueNode.alias,
      id: `${this.rootAlias}-${trueNode.alias}`,
      extraData: { type: 'Edge', centerPosition: 'up', name: trueNode.name },
    };

    return { root: conditionRoot, branches: [falseNode, trueNode], edges: [edge1, edge2] };
  }

  componentForWorkflow() {
    const falseVertex: BlankVertex = {
      alias: this.aliasForFalse,
      type: 'blank',
    };

    const trueVertex: BlankVertex = {
      alias: this.aliasForTrue,
      type: 'blank',
    };

    const newNodeUnion: WorkflowConditionVertex = {
      type: 'workflowCondition',
      alias: this.rootAlias,
      falseAlias: this.aliasForFalse,
      trueAlias: this.aliasForTrue,
    };

    const falseSummary: DefaultNodeSummary = { name: 'false', type: 'blank', alias: this.aliasForFalse };
    const trueSummary: DefaultNodeSummary = { name: 'true', type: 'blank', alias: this.aliasForTrue };
    const rootSummary: DefaultNodeSummary = { name: this.rootName, type: 'workflowCondition', alias: this.rootAlias };

    const summaries = {
      [this.aliasForFalse]: falseSummary,
      [this.aliasForTrue]: trueSummary,
      [this.rootAlias]: rootSummary,
    };
    return { root: newNodeUnion, branches: [falseVertex, trueVertex], nodeSummaries: summaries };
  }
}
