import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ItemContainer = styled(NvFlex)`
  padding: 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
`;

export const ItemHeader = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const StyledInfo = styled('span')`
  color: ${({ theme }) => theme.palette.nv_neutral[300]};
`;

export const SlackWorkspaceLogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  ${({ theme }) => theme.elevations.e100};

  img,
  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
`;
