import { CatalogRelationshipFilter, ValueTypes } from '@novaera/actioner-service';
import { CodeInput, Context, FieldTitle, NvFlex } from '@novaera/core';
import { RelationshipSideGroupedComponent } from './relationship-side-grouped-component';

export const RelationshipFilter: React.FC<{
  value: CatalogRelationshipFilter;
  onChange: (value: CatalogRelationshipFilter) => void;
  context: Context;
  firstEntityTypeId?: string;
}> = ({ value, onChange, context, firstEntityTypeId }) => (
  <NvFlex gap="16px" width="100%">
    <NvFlex direction={'column'} alignItems={'left'}>
      <FieldTitle direction="label-on-top" labelText="Relationship" />
      <RelationshipSideGroupedComponent
        value={value.relationshipDefinitionId}
        onChange={(newRelationshipDefinitionId) => {
          onChange({
            ...value,
            relationshipDefinitionId: newRelationshipDefinitionId,
          });
        }}
        context={context}
        firstEntityTypeId={firstEntityTypeId}
      />
    </NvFlex>

    <NvFlex direction={'column'} alignItems={'left'}>
      <FieldTitle direction="label-on-top" labelText="Query" />
      <CodeInput
        value={value.query.value}
        onChange={(newQuery) => {
          onChange({ ...value, query: { type: ValueTypes.STRING, value: newQuery } });
        }}
        context={context}
        placeholder="Write search query"
      />
    </NvFlex>
  </NvFlex>
);
