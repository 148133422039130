import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ChevronButton = styled(NvBox)`
  padding: 2px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  }

  .opener {
    transition: transform 300ms ease;
  }
  &.isOpen {
    .opener {
      transform: rotate(90deg);
    }
  }
`;
