import { ParameterMapping } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

export type InputFormValues = Record<string, ParameterMapping>;

const InputValuesContext = createContext<
  | {
      inputValues?: InputFormValues;
      setFormValues: Dispatch<SetStateAction<InputFormValues | undefined>>;
    }
  | undefined
>(undefined);

export const InputValuesProvider: FC<PropsWithChildren<{ initialInputFormValues?: InputFormValues }>> = ({
  children,
  initialInputFormValues,
}) => {
  const [inputValues, setInputValues] = useState<InputFormValues | undefined>(initialInputFormValues);

  const value = { setFormValues: setInputValues, inputValues };

  return <InputValuesContext.Provider value={value}>{children}</InputValuesContext.Provider>;
};

export const useInputValuesContext = () => {
  const context = useContext(InputValuesContext);
  assert(!!context, new Error(`useInputValuesContext should be used within InputValuesProvider`), 'ERROR');

  return context;
};
