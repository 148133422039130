import { CachedAvailableIntegrationUpdate } from '@novaera/actioner-service';
import { NvFlex, NvSkeleton } from '@novaera/core';
import { IntegrationUpdateSection } from './update-section';

export const IntegrationUpdateModalBody = ({
  availableUpdates,
  isLoading,
}: {
  availableUpdates: CachedAvailableIntegrationUpdate[];
  isLoading?: boolean;
}) => {
  return (
    <NvFlex gap={'16px'} flexDirection={'column'}>
      {isLoading && (
        <NvFlex gap={'16px'} flexDirection={'column'}>
          <NvSkeleton variant="rectangular" width={'350px'} height={'24px'} />
          <NvSkeleton variant="rectangular" width={'250px'} height={'24px'} />
          <NvSkeleton variant="rectangular" width={'300px'} height={'24px'} />
        </NvFlex>
      )}
      {!isLoading &&
        availableUpdates?.map(({ integration, isUpdated }, index) => {
          return (
            <IntegrationUpdateSection
              key={`integration-update-section-${integration.id}-key}`}
              integration={integration}
              isDefaultExpanded={index === 0}
              isUpdated={isUpdated}
            />
          );
        })}
    </NvFlex>
  );
};
