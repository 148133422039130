import { useDeleteDataModel } from '@novaera/actioner-service';
import {
  MenuItemNameWrapper,
  NvBox,
  NvContentCopyIcon,
  NvDeleteOutlineIcon,
  NvDivider,
  NvFlex,
  NvInfoIcon,
  NvReceiptLongIcon,
  NvSelectMenuItem,
  NvSwitch,
  NvTooltip,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { FreeAppPermissionBoundary } from '../../../../user-app-permission-boundary/free-app-permission-boundary';
import { USER_APP_DATABASE, USER_APP_RECORDS_DETAIL } from '../../../constants';
import { TableListItemProps } from '../table-list-item/types';

export const useTable = ({ source }: { source: 'list' | 'header' }) => {
  const theme = useTheme();
  const { userAppId: appId } = useParams();
  const { mutate } = useDeleteDataModel();
  const { openConfirm } = useConfirmDialog();
  const navigate = useNavigate();
  const handleDeleteDataModel = useCallback(
    (params: { name: string; modelId: string }) => {
      const { name, modelId } = params;
      openConfirm({
        title: `Delete "${name}" table?`,
        message: (
          <NvTypography variant="body1">
            Deleting <strong>{name}</strong> table will cause the following problems:
            <ul>
              <li>
                Some inconsistencies may appear in any tables dependent on <strong>{name}</strong>.
              </li>
              <li>
                Any requests that interact with <strong>{name}</strong> table will become obsolete.
              </li>
            </ul>
            Before proceeding, please carefully review the items above.
            <br />
            <br />
            Do you want to delete <strong>{name}</strong> table?
          </NvTypography>
        ),
        onConfirm: () =>
          mutate(
            { appId, modelId },
            {
              onSuccess: () => {
                modelId && navigate(USER_APP_DATABASE(appId));
              },
            }
          ),
      });
    },
    [appId, mutate, navigate, openConfirm]
  );

  const modelMenuItems = (
    params: { name: string; modelId: string; replicateToVectorDB?: boolean },
    {
      onCloneItemClicked,
      onToggleReplicateToVectorDB,
    }: {
      onCloneItemClicked: TableListItemProps['onCloneItemClicked'];
      onToggleReplicateToVectorDB?: (value: boolean) => void;
    }
  ) => {
    const { name, modelId, replicateToVectorDB } = params;

    return (
      <FreeAppPermissionBoundary
        appId={appId}
        Fallback={
          <NvBox minWidth="230px">
            <NvSelectMenuItem
              onClick={(e) => {
                e.stopPropagation();
                navigate(USER_APP_RECORDS_DETAIL(appId, modelId));
              }}
            >
              <MenuItemNameWrapper>Records</MenuItemNameWrapper>
              <NvReceiptLongIcon sx={{ width: '16px', height: '16px' }} />
            </NvSelectMenuItem>
          </NvBox>
        }
      >
        <NvBox minWidth="230px">
          <NvSelectMenuItem
            onClick={(e) => {
              e.stopPropagation();
              onCloneItemClicked({ name, id: modelId });
            }}
          >
            <MenuItemNameWrapper>Clone</MenuItemNameWrapper>
            <NvContentCopyIcon sx={{ width: '16px', height: '16px' }} />
          </NvSelectMenuItem>
          <NvSelectMenuItem
            onClick={(e) => {
              e.stopPropagation();
              navigate(USER_APP_RECORDS_DETAIL(appId, modelId));
            }}
          >
            <MenuItemNameWrapper>Records</MenuItemNameWrapper>
            <NvReceiptLongIcon sx={{ width: '16px', height: '16px' }} />
          </NvSelectMenuItem>
          {source === 'header' && !isUndefined(replicateToVectorDB) && (
            <NvSelectMenuItem
              onClick={(e) => {
                if (replicateToVectorDB) {
                  openConfirm({
                    title: 'Disable semantic search?',
                    message: (
                      <NvTypography variant="body1">
                        If you turn off semantic search, previously indexed data will be removed. You can enable it on
                        anytime, but you might have to re-index the table data.
                        <br />
                        <br />
                        Do you want to proceed?
                      </NvTypography>
                    ),
                    confirmButtonLabel: 'Disable',
                    onConfirm: () => onToggleReplicateToVectorDB?.(false),
                  });
                } else {
                  onToggleReplicateToVectorDB?.(true);
                }
              }}
            >
              <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
                <NvFlex>Semantic search</NvFlex>
                <NvTooltip
                  title="If you enable semantic search for this table, you will be able to utilize semantic search functionality on the selected fields within this table."
                  placement="top"
                  maxWidth={240}
                  variant="large"
                >
                  <NvInfoIcon htmlColor={theme.palette.nv_neutral[60]} sx={{ width: '12px', height: '12px' }} />
                </NvTooltip>
              </NvFlex>
              <NvSwitch variant="compact" checked={replicateToVectorDB} />
            </NvSelectMenuItem>
          )}

          <NvDivider sx={{ marginTop: '4px !important', marginBottom: '4px !important' }} />
          <NvSelectMenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteDataModel({ name, modelId });
            }}
          >
            <MenuItemNameWrapper>Delete</MenuItemNameWrapper>
            <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
          </NvSelectMenuItem>
        </NvBox>
      </FreeAppPermissionBoundary>
    );
  };

  return {
    modelMenuItems,
  };
};
