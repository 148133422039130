import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { updateUsersInGroupPayload } from '../types';

const updateUsersInGroup = async (params: updateUsersInGroupPayload) => {
  const { id, users } = params;
  const result = await NvAxios.post(`${GROUPS_ROOT_PATH}/${id}/users`, { users });
  return result?.data;
};
export const useUpdateUsersInGroup = () => {
  const cache = useQueryClient();
  return useMutation(updateUsersInGroup, {
    onSuccess: (_, { id }) => {
      cache.invalidateQueries(QUERY_KEYS_GROUPS.users(id));
    },
  });
};
