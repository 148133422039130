import { NvAxios, useQuery } from '@novaera/core';
import { INTEGRATION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTION_INPUT_DEPENDENCIES } from '../keys';
import { GetActionInputParamDependencies, GetActionInputParamDependenciesResponse } from '../types';

const getActionDependencies = async ({ actionId, integrationId, params }: GetActionInputParamDependencies) => {
  const result = await NvAxios.get<GetActionInputParamDependenciesResponse>(
    `${INTEGRATION_ROOT_PATH}/${integrationId}/actions/${actionId}/input-parameter-dependencies`,
    {
      params,
    }
  );
  return result?.data;
};

export const useGetActionInputParamDependencies = (param: GetActionInputParamDependencies) => {
  return useQuery<GetActionInputParamDependenciesResponse>(
    QUERY_KEYS_ACTION_INPUT_DEPENDENCIES.workflow(param),
    () => getActionDependencies(param),
    {
      enabled: !!param.actionId && !!param.integrationId && !!param.params.version,
    }
  );
};
