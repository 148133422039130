import { BranchJunctionNodeSummary } from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvCustomBranchesIcon,
  NvDeleteOutlineIcon,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';

import { noop } from 'lodash';
import { NodeType, PropertyPanelHeader } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { Branches } from './branches';
import { useBranches } from './controllers/use-branches';
import { BranchOperatorPanelProps } from './types';

export const BranchOperatorPanel = ({ node, onCloseClicked, workflow }: BranchOperatorPanelProps) => {
  const theme = useTheme();
  const { onSave, deleteNodeAndUpdateGraph } = useBranches();
  const { hasEditPermission } = useWorkflowPermission();
  return (
    <NvForm<BranchJunctionNodeSummary>
      onSubmit={noop}
      {...(hasEditPermission && {
        onChange: ({ values }) => {
          onSave(values);
        },
      })}
      keepDirtyOnReinitialize
      initialValues={node}
      mutators={{ ...arrayMutators }}
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvCustomBranchesIcon />}
          title={node.name}
          type={NodeType.BRANCH}
          onTitleChange={async (title) => {
            if (title) {
              onSave(
                {
                  ...node,
                  name: title,
                },
                true
              );
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: node.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvCustomBranchesIcon />}
          title={node.name}
          type={NodeType.BRANCH}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <Branches branchNode={node} workflow={workflow} />
    </NvForm>
  );
};
