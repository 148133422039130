import { InputParameter } from '@novaera/actioner-service';
import { NvField } from '@novaera/core';
import { useCallback } from 'react';
import { useSearchAsYouTypeValuesContext } from '../../../../action-designer/providers/search-as-you-type-values';
import { useIntegrationActionOptionsContext } from '../../../options';
import { useUIComponents } from '../../../ui-components/use-ui-component';

export const IntegrationActionDefaultValue: React.FC<
  React.PropsWithChildren<{
    inputParameter: InputParameter;
    name: string;
  }>
> = ({ name, inputParameter }) => {
  const { getOptionsResponse, setInputParameterIdsShowingOptions, getDynamicInputComponentState } =
    useIntegrationActionOptionsContext();
  const { setSearchAsYouTypeValues } = useSearchAsYouTypeValuesContext();
  const handleSearchAsYouTypeValueUpdate = useCallback(
    ({ value, inputParameterId }: { value: string; inputParameterId: string }) => {
      setSearchAsYouTypeValues((prev) => ({ ...prev, [inputParameterId]: value }));
    },
    [setSearchAsYouTypeValues]
  );
  const { componentFieldProps, getComponentForField } = useUIComponents({
    inputParameter,
    extractValue: true,
    getOptionsResponse,
    setInputParameterIdsShowingOptions,
    onSearchAsYouTypeValueUpdate: handleSearchAsYouTypeValueUpdate,
    getDynamicInputComponentState,
  });
  const { labelText, isRequired, showErrorMessageOnlyWhenBlur, validators, ...rest } = componentFieldProps;

  return (
    <NvField
      {...rest}
      name={`${name}.defaultValue`}
      component={getComponentForField({ inputParameter, otherProps: { size: 'small' } })}
    />
  );
};
