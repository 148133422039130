import { GroupUser, useGetUsersInWorkspace } from '@novaera/actioner-service';
import { NvAutocomplete, NvField, NvFlex, NvTextField, isMinLength, isRequired } from '@novaera/core';
import { useMemo } from 'react';

export const SelectUsersModalBody = ({ groupUsers }: { groupUsers?: GroupUser[] }) => {
  const { data: workspaceUsers } = useGetUsersInWorkspace();

  const userOptions = useMemo(
    () =>
      workspaceUsers?.users.filter((user) => !groupUsers?.find((groupUser) => groupUser?.userId === user.userId)) ?? [],
    [groupUsers, workspaceUsers?.users]
  );
  return (
    <NvFlex>
      <NvField
        labelText="Select users"
        direction="label-on-top"
        name="users"
        isAutoComplete
        format={(value) => {
          return value ?? [];
        }}
        parse={(value) => {
          return value ?? [];
        }}
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        validators={[isRequired(), isMinLength({ length: 1 })]}
        component={
          <NvAutocomplete
            multiple
            options={userOptions}
            size="small"
            getOptionLabel={(option) => (option ? `${option.userName}` : '')}
            renderInput={(params) => (
              <NvTextField
                {...params}
                placeholder="You can select multiple users"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                }}
                size="medium"
              />
            )}
          />
        }
      />
    </NvFlex>
  );
};
