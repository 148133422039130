import { MinutelyJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvBox, NvFlex, NvTypography } from '@novaera/core';
import { DynamicRateField } from '../../dynamic-rate-field';
import { RecurrenceProps } from '../../types';

export const DynamicMinutelyRecurrence: React.FC<RecurrenceProps<MinutelyJobRecurrenceConfiguration>> = ({
  recurrenceConfiguration,
  context,
  onChange,
}) => (
  <NvFlex direction="row" alignItems="center" gap="4px" width="100%">
    <NvBox flex="1 1 auto" minWidth={0}>
      <DynamicRateField
        value={recurrenceConfiguration.repeatInterval}
        onChange={(v) => {
          onChange({
            ...recurrenceConfiguration,
            repeatInterval: v,
          });
        }}
        context={context}
      />
    </NvBox>
    <NvTypography flex="0 0 auto" variant="body2">
      minutes
    </NvTypography>
  </NvFlex>
);
