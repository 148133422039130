import { NvBox, NvFlex, NvSkeleton } from '@novaera/core';
import { FC, ReactNode } from 'react';
import { List } from '../styled';

export const LoadingState: FC<
  React.PropsWithChildren<{
    loadingItem: ReactNode;
    CardListWrapperComponent: React.FC<React.PropsWithChildren>;
    onlyList?: boolean;
  }>
> = ({ loadingItem, CardListWrapperComponent, onlyList = false }) =>
  onlyList ? (
    <List>
      {loadingItem}
      {loadingItem}
      {loadingItem}
      {loadingItem}
    </List>
  ) : (
    <CardListWrapperComponent>
      <NvBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <NvFlex flexDirection="row" gap="8px" alignItems="center">
          <NvSkeleton variant="circular" width="40px" height="40px" />
          <NvSkeleton width="213px" height="24px" variant="rectangular" />
        </NvFlex>
        <NvSkeleton width="120px" height="20px" variant="rectangular" />
      </NvBox>
      <List>
        {loadingItem}
        {loadingItem}
        {loadingItem}
        {loadingItem}
      </List>
    </CardListWrapperComponent>
  );
