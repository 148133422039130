import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvButton } from '../button';

export const InlineEditWrapper = styled(NvBox)`
  width: calc(100% + 8px);
  margin-left: -8px;
  margin-top: -4px;
  margin-bottom: -4px;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 200ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
    .edit-button {
      opacity: 1;
    }
  }

  &.edit-mode {
    transition: background-color 0ms ease;
    margin-top: -8px;
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    ${({ theme }) => theme.elevations.e100};

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    }
  }

  &.view-mode {
    flex: 0 1 auto;
    min-width: 0;

    .view-wrapper {
      align-items: center;
    }
  }
`;

export const EditButton = styled(NvButton)`
  flex: 0 0 auto;
  opacity: 0;
  transition: opacity 200ms ease;
`;
