import { NvButton, NvChevronLeftIcon, NvChevronRightIcon, NvFlex } from '@novaera/core';
import { RecordsLayout } from '../../../components/records-layout';
import { RecordsSearch } from '../../../components/records-search';
import { RecordsTable } from '../../../components/records-table';
import { CatalogDetailPanel } from './catalog-detail-panel';
import { RECORD_LIMIT } from './constants';
import { useCatalogEntitySearch } from './controllers/use-catalog-entity-search';

export const CatalogEntitySearch = () => {
  const {
    rows,
    columns,
    catalogEntity,
    catalogEntityId,
    selectedEntityType,
    searchKeyword,
    isLoading,
    isRefetching,
    nextPageKey,
    previousPageKey,
    disableNextPage,
    disablePreviousPage,
    onSearchChange,
    onSearchEnter,
    onSearchRefresh,
    onSortModelChange,
    onPageChange,
    removeQueryParams,
    addQueryParam,
  } = useCatalogEntitySearch();

  return (
    selectedEntityType && (
      <NvFlex width={'100%'}>
        <RecordsLayout
          HeaderComponent={
            <RecordsSearch
              minHeight="59px"
              search={searchKeyword}
              onChange={onSearchChange}
              onEnter={onSearchEnter}
              handleRefresh={onSearchRefresh}
            />
          }
          RowDetailPanelComponent={
            <CatalogDetailPanel
              onDetailPanelClose={() => {
                removeQueryParams('catalogEntityId');
              }}
              entityType={selectedEntityType}
              catalogEntity={catalogEntity}
            />
          }
          isPanelOpen={catalogEntityId !== undefined}
        >
          <RecordsTable
            rows={rows}
            columns={columns}
            loading={isLoading || isRefetching}
            onSortModelChange={onSortModelChange}
            pageSize={RECORD_LIMIT}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            onRowClick={(params) => {
              addQueryParam('catalogEntityId', params.row.id);
            }}
            components={{
              Pagination: () => {
                return (
                  <NvFlex flexDirection={'row'} gap={'8px'}>
                    <NvButton
                      startIcon={<NvChevronLeftIcon />}
                      disabled={disablePreviousPage}
                      onClick={() => onPageChange(previousPageKey)}
                      size="small"
                      color="secondary"
                    >
                      Previous
                    </NvButton>
                    <NvButton
                      color="secondary"
                      disabled={disableNextPage}
                      onClick={() => nextPageKey && onPageChange(nextPageKey)}
                      endIcon={<NvChevronRightIcon />}
                      size="small"
                    >
                      Next
                    </NvButton>
                  </NvFlex>
                );
              },
            }}
          />
        </RecordsLayout>
      </NvFlex>
    )
  );
};
