import { ROUTE_DEFAULTS } from '@novaera/constants';
import {
  AccountCircleIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvLogoutIcon,
  NvManageAccountsIcon,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { ROUTES } from '../../../../common/routes';
import { PopupMenuItem } from '../popup-menu-item';
import { GrayRectangle } from './styled';
import { PopupMenuComponentProps } from './types';

export const ProfilePopupMenuComponent: React.FC<React.PropsWithChildren<PopupMenuComponentProps>> = ({
  profile,
  onMenuItemClicked,
}) => {
  const { palette } = useTheme();

  const handleMenuItemClicked = (link?: string) => {
    onMenuItemClicked?.(link);
  };

  return (
    <NvFlex gap="12px">
      <NvFlex position="relative" padding="4px 18px 12px" gap="4px" alignItems="flex-start">
        <NvImage
          src={profile?.logoUrl}
          FallBack={<AccountCircleIcon sx={{ width: '40px', height: '40px' }} />}
          size="large"
        />
        <GrayRectangle />
        <NvFlex>
          <NvTypography variant="h3">{profile?.name}</NvTypography>
          <NvTypography variant="h8" textColor="subtle">
            {profile?.email}
          </NvTypography>
        </NvFlex>
      </NvFlex>
      <NvFlex>
        <PopupMenuItem
          to={ROUTE_DEFAULTS.LOG_OUT}
          onClick={() => {
            handleMenuItemClicked(ROUTE_DEFAULTS.LOG_OUT);
          }}
          label="Logout"
          labelProps={{ variant: 'body1' }}
          icon={<NvLogoutIcon htmlColor={palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />}
        />
        <NvDivider sx={{ margin: '4px' }} />

        <PopupMenuItem
          to={`${ROUTES.ProfileSettings}/profile`}
          onClick={() => {
            handleMenuItemClicked(`${ROUTES.ProfileSettings}/profile`);
          }}
          label="Settings"
          labelProps={{ variant: 'body1' }}
          icon={<NvManageAccountsIcon htmlColor={palette.nv_neutral[600]} sx={{ width: '16px', height: '16px' }} />}
        />
      </NvFlex>
    </NvFlex>
  );
};
