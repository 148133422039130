import { NvAxios, useQuery } from '@novaera/core';

import { QUERY_KEYS_JOBS } from '../keys';
import { JobType } from '../types';
import { GetRecurringJobsParams, GetRecurringJobsResponse } from './types';

const getRecurringJobs = async ({ appId, timezoneId }: GetRecurringJobsParams) => {
  const result = await NvAxios.get<GetRecurringJobsResponse>(`/v2/apps/${appId}/jobs/recurring`, {
    params: {
      'display-tz-id': timezoneId,
    },
  });
  return result?.data;
};

export const useGetRecurringJobs = ({ appId, timezoneId }: GetRecurringJobsParams) => {
  return useQuery<GetRecurringJobsResponse>(
    QUERY_KEYS_JOBS.list(appId, JobType.RECURRING, timezoneId),
    () => getRecurringJobs({ appId, timezoneId }),
    { enabled: !!appId && !!timezoneId }
  );
};
