import { DateTimePickerDefaultTextField, NvDatePicker, useDatePicker } from '@novaera/core';
import { ComponentHint } from '../common/adapter/component-hint';
import { DatePickerComponentParams } from '../use-ui-component/types';

export const DatePickerComponent = ({
  inputParameter: { uiComponent },
  inputProps,
  otherProps,
}: DatePickerComponentParams) => {
  const { hint } = uiComponent;
  const { error } = inputProps;
  const { isOpen, changeDatePickerOpen } = useDatePicker();

  return (
    <>
      <NvDatePicker
        {...inputProps}
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        onClose={() => changeDatePickerOpen(false)}
        open={isOpen}
        error={!!error}
        renderInput={(params) => (
          <DateTimePickerDefaultTextField
            {...params}
            size="large"
            onClick={() => changeDatePickerOpen(!isOpen)}
            error={!!error}
            {...otherProps}
          />
        )}
      />
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
