import { useCreateWorkspaceDeletionRequest, useGetWorkspaceDeletionRequest } from '@novaera/actioner-service';
import { NvButton, NvCollapseCard, NvDialogModal, NvFlex, NvTypography, useNvDialogModalState } from '@novaera/core';
import { DeleteWorkspaceModalBody } from './delete-workspace-modal-body';
import { DeleteWorkspaceModalFooter } from './delete-workspace-modal-footer';
import { CollapsedCardStyle } from './styled';
import { DeleteWorkspaceFormValues } from './types';

export const WorkspaceDeleteRequest = () => {
  const { error: workspaceDeletionRequestError } = useGetWorkspaceDeletionRequest();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const { mutate, isLoading } = useCreateWorkspaceDeletionRequest();

  const handleSubmitForm = (values: DeleteWorkspaceFormValues) => {
    return new Promise<void>((resolve) => {
      mutate(
        {
          reason: values.reason === 'Other' ? values.otherReason : values.reason,
        },
        {
          onSuccess: () => {
            onModalCloseClicked();
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return workspaceDeletionRequestError ? (
    <>
      <NvCollapseCard
        CustomCollapseCardWrapper={CollapsedCardStyle}
        title={<NvTypography variant="h4">Delete workspace</NvTypography>}
      >
        <NvFlex padding={'0 28px'} gap="8px">
          <NvTypography>
            Deleting the Actioner workspace will <b>permanently remove all the data.</b>
            <br />
            Workspace cannot be recovered after the deletion.
          </NvTypography>
          <NvFlex flexDirection="row">
            <NvButton color="error" size="small" onClick={() => onModalOpenClicked()}>
              Delete workspace
            </NvButton>
          </NvFlex>
        </NvFlex>
      </NvCollapseCard>
      <NvDialogModal<DeleteWorkspaceFormValues>
        open={isOpened}
        onCloseButtonClicked={onModalCloseClicked}
        Header="Delete workspace"
        modalIcon="error"
        maxWidth="sm"
        fullWidth
        primaryButtonText="Update"
        Body={<DeleteWorkspaceModalBody />}
        onFormSubmit={(values) => handleSubmitForm(values)}
        Footer={<DeleteWorkspaceModalFooter onCloseClicked={onModalCloseClicked} isLoading={isLoading} />}
      />
    </>
  ) : null;
};
