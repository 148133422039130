import { cloneElement } from 'react';
import { NvNestedDropdown } from '..';
import { NvBox } from '../../box';
import { NvButton } from '../../button';
import { NvFlex } from '../../flex';
import { NvExpandMoreIcon } from '../../icons';
import { NvTypography } from '../../typography';
import { NestedDropdownItem } from '../types';
import { NestedDropdownSelectProps } from './types';

export const NestedDropdownSelect = ({ value, onChange, options }: NestedDropdownSelectProps<NestedDropdownItem>) => {
  return (
    <NvNestedDropdown
      menuItems={options}
      onClickItem={(param) => onChange?.(param)}
      trigger={
        <NvButton className="trigger-button" color="ghost" sx={{ width: '225px' }}>
          <NvFlex width="100%" direction="row" alignItems="center" justifyContent="space-between">
            <NvBox display="flex" flexDirection="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth="0">
              {value?.leftIcon && cloneElement(value.leftIcon, { ...value.leftIcon.props, fontSize: 'inherit' })}
              <NvTypography noWrap>{value?.name}</NvTypography>
            </NvBox>

            <NvExpandMoreIcon className="more-icon" />
          </NvFlex>
        </NvButton>
      }
    />
  );
};
