import {
  useGetWorkflow,
  useGetWorkflowNodeExecution,
  useGetWorkflowNodeExecutions,
  useResetWorkflowSourceOptions,
  useUpdateUserAppSetupStatus,
} from '@novaera/actioner-service';
import {
  Markdown,
  NvBox,
  NvButton,
  NvCustomCheckIcon,
  NvDialogModal,
  NvDivider,
  NvFlex,
  NvLink,
  NvReactJson,
  NvSkeleton,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { FormApi } from 'final-form';
import { FC, MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { InputFormValues } from '../../../../../../action-designer/providers/input-values';
import { SearchAsYouTypeValuesProvider } from '../../../../../../action-designer/providers/search-as-you-type-values';
import { useFormIdentifierContext } from '../../../../../../providers/form-identifier-provider';
import { FormParameterProvider } from '../../../../workflow-designer/user-app-workflow-canvas/providers/form-parameter-provider';
import { OnExecutionFinishedCallback } from '../../../../workflow-designer/user-app-workflow-canvas/providers/workflow-reference-output-provider/types';
import { RunWorkflowFormTriggerBody } from '../../../../workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/body/form-trigger';
import { useExecuteWorkflow } from '../../../../workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/controllers/use-execute-workflow';
import { WORKFLOW_HISTORY, WORKFLOW_HISTORY_DETAIL } from '../../../../workflow-detail/constants';
import { ErrorReasonCard, OrderBox, SetupWorkflowWrapper } from './styled';
import { SetupWorkflowItemProps } from './types';
import { findResponseNodeAlias, findResponseNodeObjectKey, buildResponseMessage } from '../../../utils';

export const SetupWorkflowItem: FC<React.PropsWithChildren<SetupWorkflowItemProps>> = ({
  setupWorkflow,
  order,
  appId,
}) => {
  const theme = useTheme();
  const { getStatefulFormId } = useFormIdentifierContext();

  const formRef: MutableRefObject<FormApi<InputFormValues, Partial<InputFormValues>> | null> = useRef(null);

  const updateStatusAndCloseModal: OnExecutionFinishedCallback = (props) => {
    if (props.result?.status) {
      const { result } = props;
      updateUserAppSetupStatus({ setupWorkflowId: setupWorkflow.id, status: result.status });
      if (result.status === 'successful') {
        handleModalClose();
      }
    }
  };

  const { onExecuteWorkflow, isLoading, result, cleanResult, executionIdentifier } = useExecuteWorkflow({
    workflowId: setupWorkflow.id,
    workflowTriggerType: setupWorkflow.trigger.type,
    isDraft: false,
    formId: getStatefulFormId(),
    onExecutionRunFinished: updateStatusAndCloseModal,
    userAppId: setupWorkflow.appId,
  });

  const {
    isOpened: isRunModalOpened,
    onModalCloseClicked: onRunModalCloseClicked,
    onModalOpenClicked: onRunModalOpenedClicked,
  } = useNvDialogModalState();
  const { updateUserAppSetupStatus } = useUpdateUserAppSetupStatus({ appId });
  const { removeWorkflowSourceOptionsCacheForApp } = useResetWorkflowSourceOptions();
  const isFailed = useMemo(() => result && 'status' in result && result.status === 'failed', [result]);
  const handleModalClose = useCallback(() => {
    cleanResult();
    onRunModalCloseClicked();
  }, [cleanResult, onRunModalCloseClicked]);

  const workflow = useGetWorkflow({ appId: appId, workflowId: setupWorkflow.id });

  const responseNodeAlias = useMemo(() => {
    if (result?.status === 'failed') {
      return findResponseNodeAlias(workflow?.workflow?.nodeSummaries, result?.nodeStates);
    }
    return '';
  }, [result?.status, workflow?.workflow?.nodeSummaries, result?.nodeStates]);

  const nodeExecutions = useGetWorkflowNodeExecutions({
    appId: appId,
    workflowId: setupWorkflow.id,
    workflowExecutionId: result?.status === 'failed' ? (executionIdentifier ? executionIdentifier : '') : '',
    'node-alias': responseNodeAlias,
  });

  const objectKey = useMemo(() => {
    return findResponseNodeObjectKey(nodeExecutions.data?.pages);
  }, [nodeExecutions.data?.pages]);

  const { data: nodeExecutionDetail, isLoading: isNodeExecutionDetailLoading } = useGetWorkflowNodeExecution({
    appId: appId,
    workflowId: setupWorkflow.id,
    workflowExecutionId: result?.status === 'failed' ? (executionIdentifier ? executionIdentifier : '') : '',
    nodeExecutionKey: objectKey ? objectKey : '',
  });
  const response: unknown = useMemo(
    () => buildResponseMessage(nodeExecutionDetail?.response),
    [nodeExecutionDetail?.response]
  );

  const isButtonLoading: boolean = useMemo(() => {
    if (isLoading) {
      return true;
    }
    if (responseNodeAlias) {
      return Boolean(isNodeExecutionDetailLoading && objectKey && executionIdentifier) || !response;
    }
    return false;
  }, [isLoading, responseNodeAlias, isNodeExecutionDetailLoading, objectKey, executionIdentifier, response]);

  return (
    <>
      <SetupWorkflowWrapper className={classNames({ success: setupWorkflow.status === 'successful' })}>
        <OrderBox>
          <NvTypography variant="h4">{order}</NvTypography>
        </OrderBox>
        <NvBox flex="1 1 auto" height="auto" minWidth="0">
          <NvTypography noWrap variant="h3">
            {setupWorkflow.name}
          </NvTypography>
          <NvTypography variant="body1" maxWidth="720px">
            <Markdown>{setupWorkflow.description ?? ''}</Markdown>
          </NvTypography>
        </NvBox>
        <NvFlex direction="row" alignItems="center" gap="8px" flex="0 0 auto">
          <NvButton
            onClick={() => {
              onRunModalOpenedClicked();
              if (setupWorkflow.trigger?.type === 'form') {
                removeWorkflowSourceOptionsCacheForApp({ appId: setupWorkflow.appId });
              }
            }}
            size="small"
            color={setupWorkflow.status === 'successful' ? 'secondary' : 'primary'}
          >
            {setupWorkflow.status === 'successful' ? 'Re-run' : setupWorkflow.trigger.runButtonLabel}
          </NvButton>
          {setupWorkflow.status === 'successful' && (
            <>
              <NvDivider
                orientation="vertical"
                sx={{ height: '8px' }}
                borderColor={theme.palette.nv_neutral_alpha[30]}
              />
              <NvFlex direction="row" alignItems="center" gap="4px">
                <NvCustomCheckIcon htmlColor={theme.palette.nv_success[60]} />
                <NvTypography variant="h6" textColor="secondary">
                  Completed
                </NvTypography>
              </NvFlex>
            </>
          )}
        </NvFlex>
      </SetupWorkflowWrapper>
      <NvDialogModal<InputFormValues>
        modalIcon={isFailed ? 'redError' : 'playArrow'}
        Header={isFailed ? 'Oops!' : setupWorkflow.name}
        fullWidth
        maxWidth="md"
        onCloseButtonClicked={handleModalClose}
        open={isRunModalOpened}
        onFormSubmit={(values) => {
          onExecuteWorkflow(values);
        }}
        isLoading={isLoading}
        formProps={{
          myRef: formRef,
        }}
        Body={
          isFailed && !!response && !isButtonLoading ? (
            <NvFlex gap="16px">
              <NvTypography variant="body1">
                We couldn't run <b>{setupWorkflow.name}</b> properly.
              </NvTypography>
              <ErrorReasonCard>
                {typeof response === 'string' ? (
                  <NvTypography variant="body1">{response}</NvTypography>
                ) : response ? (
                  <NvReactJson src={response} />
                ) : null}
              </ErrorReasonCard>
            </NvFlex>
          ) : isFailed && !isButtonLoading ? (
            <NvFlex gap="16px">
              <NvTypography variant="body1">
                We couldn't run <b>{setupWorkflow.name}</b> properly.
              </NvTypography>
              <ErrorReasonCard>
                <NvTypography variant="body1">
                  Please check the{' '}
                  <NvLink
                    href={
                      executionIdentifier
                        ? WORKFLOW_HISTORY_DETAIL({
                            appId,
                            workflowId: setupWorkflow.id,
                            executionId: executionIdentifier,
                          })
                        : WORKFLOW_HISTORY({
                            appId,
                            workflowId: setupWorkflow.id,
                          })
                    }
                    target="_blank"
                  >
                    workflow history
                  </NvLink>{' '}
                  for more information
                </NvTypography>
              </ErrorReasonCard>
            </NvFlex>
          ) : isFailed && isButtonLoading ? (
            <NvFlex gap="16px">
              <NvTypography variant="body1">
                We couldn't run <b>{setupWorkflow.name}</b> properly.
              </NvTypography>
              <NvSkeleton variant="rectangular" width="40%" height="32px" />
              <NvSkeleton variant="rectangular" width="25%" height="32px" />
            </NvFlex>
          ) : (
            <SearchAsYouTypeValuesProvider>
              <FormParameterProvider workflow={setupWorkflow} formRef={formRef}>
                {({ inputParameters }) => (
                  <RunWorkflowFormTriggerBody
                    workflowId={setupWorkflow.id}
                    workflowInputParameters={inputParameters ?? []}
                    userAppId={appId}
                  />
                )}
              </FormParameterProvider>
            </SearchAsYouTypeValuesProvider>
          )
        }
        Footer={
          <NvFlex direction="row" alignItems="center" gap="8px">
            {!isFailed && (
              <NvButton type="submit" color="primary" loading={isButtonLoading} disabled={isLoading}>
                {setupWorkflow.trigger.runButtonLabel}
              </NvButton>
            )}

            <NvButton color={!isFailed ? 'ghost' : 'primary'} onClick={handleModalClose} disabled={isLoading}>
              {!isFailed ? 'Cancel' : 'Close'}
            </NvButton>
          </NvFlex>
        }
      />
    </>
  );
};
