import { DocumentItem, useListDocs } from '@novaera/actioner-service';
import { NvBox, NvNodeList, useDebounce } from '@novaera/core';
import { useParams } from '@novaera/route';
import { FC, useMemo, useState } from 'react';
import { DropdownSimpleSearchInput } from '../../../../../components/simple-search-input/styled';
import { convertAssistantItemToNodeListItem } from '../../utils';
import { DocumentSelectProps } from './types';

export const DocumentSelect: FC<DocumentSelectProps> = ({ existingDocumentIds, onSelectDocument }) => {
  const [keyword, setKeyword] = useState<string | undefined>('');
  const debouncedKeyword = useDebounce(keyword, 500);
  const { userAppId } = useParams();
  const { data: docsQueries, isLoading } = useListDocs({
    appId: userAppId,
    queryParams: {
      namePrefix: debouncedKeyword,
    },
  });

  const documents = useMemo(
    () =>
      convertAssistantItemToNodeListItem<DocumentItem>({
        items: docsQueries?.pages[0]?.documents ?? [],
        existingItemIds: existingDocumentIds,
      }),
    [docsQueries?.pages, existingDocumentIds]
  );

  return (
    <NvBox width="730px">
      <DropdownSimpleSearchInput autoFocus placeholder="Search name prefix" onKeywordChanged={setKeyword} />
      <NvNodeList
        nodes={documents ?? []}
        onItemClick={(e, item) => {
          onSelectDocument(item.extraData as DocumentItem);
        }}
        isLoading={isLoading}
      />
    </NvBox>
  );
};
