import { BaseFieldType } from '@novaera/actioner-service';
import { DataModelDefaultPage } from './default-page';
import { BodyWrapper, ContentWrapper } from './styled';
import { DataModelLayoutProps, isSwitchModeComponent } from './types';

export const DataModelLayout = <FieldTypeType extends BaseFieldType>({
  HeaderComponent,
  ...otherProps
}: DataModelLayoutProps<FieldTypeType>) => {
  return (
    <BodyWrapper>
      {HeaderComponent}
      <ContentWrapper>
        {isSwitchModeComponent(otherProps) ? (
          otherProps.Component
        ) : (
          <DataModelDefaultPage<FieldTypeType> {...otherProps} />
        )}
      </ContentWrapper>
    </BodyWrapper>
  );
};
