import { useCreateWorkflow } from '@novaera/actioner-service';
import {
  isRequired,
  NvAddIcon,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTagsAutoComplete,
  NvTextArea,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useMemo } from 'react';
import { USER_APP_WORKFLOW } from '../../constants';

import { AddNewWorkflowModalFormValues, AddNewWorkflowModalProps } from './types';

export const AddNewWorkflowModal: React.FC<React.PropsWithChildren<AddNewWorkflowModalProps>> = ({
  isOpen,
  onClose,
}) => {
  const { userAppId } = useParams();
  const navigate = useNavigate();
  const { mutate: createWorkflow } = useCreateWorkflow();
  const initialFormValues = useMemo(() => ({ name: '', description: '', tags: [], searchable: true }), []);
  const handleOnSubmit = (values: AddNewWorkflowModalFormValues) => {
    return new Promise<void>((resolve) => {
      const { searchable, ...formValues } = values;
      createWorkflow(
        { appId: userAppId, searchInvisibleByDefault: !values.searchable, ...formValues },
        {
          onSuccess: ({ id }) => {
            onClose();
            navigate(USER_APP_WORKFLOW({ userAppId, workflowId: id }));
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };
  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <NvForm<AddNewWorkflowModalFormValues> onSubmit={handleOnSubmit} initialValues={initialFormValues}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Add workflow</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton
                onlyIcon
                size="small"
                color="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex gap="16px">
                <NvBox width="400px">
                  <NvField
                    name="name"
                    labelText="Name"
                    labelVariant="h5"
                    direction="label-on-top"
                    showErrorMessageOnlyWhenBlur
                    component={<NvTextField size="medium" disabled={submitting} />}
                    validators={[isRequired()]}
                    hasRequiredIndicator
                  />
                </NvBox>
                <NvField
                  direction="label-on-top"
                  name="description"
                  labelText="Description"
                  labelVariant="h5"
                  showErrorMessageOnlyWhenBlur
                  component={<NvTextArea rows={4} />}
                />
                <NvField
                  direction="label-on-top"
                  name="tags"
                  labelText="Tags"
                  labelVariant="h5"
                  showErrorMessageOnlyWhenBlur
                  isAutoComplete
                  component={<NvTagsAutoComplete options={[]} placeholder={'Add tags'} />}
                />
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" loading={submitting} disabled={submitting}>
                Create
              </NvButton>
              <NvButton onClick={onClose} color="ghost">
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
