import { HeaderWithTabLayout, NvAccountBox } from '@novaera/core';
import { Route, Routes } from '@novaera/route';
import { Navigate } from 'react-router';

import { DEFAULT_ROOT_PATH, PROFILE_SETTINGS_TAB_PATHS, PROFILE_SETTING_TABS } from './constants';

export const ProfileSettings: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={DEFAULT_ROOT_PATH} />} />
      <Route
        path={`${PROFILE_SETTINGS_TAB_PATHS.CONNECTIONS}`}
        element={
          <HeaderWithTabLayout
            title={{ label: 'Settings', icon: <NvAccountBox /> }}
            tabs={PROFILE_SETTING_TABS}
            variant="center-aligned"
          />
        }
      />
      <Route
        path={`${PROFILE_SETTINGS_TAB_PATHS.PROFILE}`}
        element={
          <HeaderWithTabLayout
            title={{ label: 'Settings', icon: <NvAccountBox /> }}
            tabs={PROFILE_SETTING_TABS}
            variant="center-aligned"
          />
        }
      />
      <Route
        path={`${PROFILE_SETTINGS_TAB_PATHS.WORKSPACES}`}
        element={
          <HeaderWithTabLayout
            title={{ label: 'Settings', icon: <NvAccountBox /> }}
            tabs={PROFILE_SETTING_TABS}
            variant="center-aligned"
          />
        }
      />
    </Routes>
  );
};
