import { NvTypography } from '@novaera/core';
import { FC } from 'react';

export interface ComponentHintProps {
  hint: string;
}

export const ComponentHint: FC<React.PropsWithChildren<ComponentHintProps>> = ({ hint }) => {
  return (
    <NvTypography variant="body3" sx={{ marginTop: '4px' }}>
      {hint}
    </NvTypography>
  );
};
