import { GetActionInputParamDependencies, SearchIntegrationsParam } from './types';

export const generatedIntegrationsSearchKey = (params: SearchIntegrationsParam) => {
  const { type, value, scopeType, sortParameters } = params;
  // If we want to add new parameter for search workflow, we need get this from params and add orderGuaranteedParams object.
  const orderGuaranteedParams = {
    ...(value ? { value } : {}),
    ...(type ? { type } : {}),
    ...(scopeType ? { scopeType } : {}),
    ...(sortParameters ? { sortParameters } : {}),
  };
  const searchIntegrationParamsKeys = Object.keys(orderGuaranteedParams) as Array<keyof typeof orderGuaranteedParams>;
  const queryStringToCache =
    searchIntegrationParamsKeys.length > 0
      ? searchIntegrationParamsKeys.reduce(
          (acc, cur) => `${acc}${acc.length > 0 ? '&' : ''}${cur}="${orderGuaranteedParams[cur]}"`,
          ''
        )
      : '';

  return queryStringToCache;
};

export const QUERY_KEYS_INTEGRATION = {
  all: ['integration'] as const,
  list: (params?: SearchIntegrationsParam) => {
    const key = params && generatedIntegrationsSearchKey(params);
    if (key) {
      return [...QUERY_KEYS_INTEGRATION.all, 'list', key] as const;
    }
    return [...QUERY_KEYS_INTEGRATION.all, 'list'] as const;
  },
  detail: (id?: string) => [...QUERY_KEYS_INTEGRATION.list(), 'detail', id] as const,
};

export const QUERY_KEYS_INTEGRATION_ACTIONS_SAMPLE_PAYLOAD = {
  all: ['integration-actions-sample-payload'] as const,
  detail: (params: { integrationId: string; actionId: string; version?: number }) => {
    return [
      ...QUERY_KEYS_INTEGRATION_ACTIONS_SAMPLE_PAYLOAD.all,
      params.integrationId,
      params.actionId,
      params.version,
    ] as const;
  },
};

export const QUERY_KEYS_INTEGRATION_EVENT_RULES = {
  all: ['integration-event-rules'] as const,
  list: (params: { integrationId?: string; version?: number }) => {
    return [...QUERY_KEYS_INTEGRATION_EVENT_RULES.all, params.integrationId, params.version] as const;
  },
  detail: ({ id, integrationId, version }: { id: string; integrationId: string; version: number }) =>
    [...QUERY_KEYS_INTEGRATION_EVENT_RULES.list({ integrationId, version }), 'detail', id, version] as const,
};

export const QUERY_KEYS_ACTION_INPUT_DEPENDENCIES = {
  all: ['action-input-dependencies'] as const,
  workflow: (param: GetActionInputParamDependencies) =>
    [...QUERY_KEYS_ACTION_INPUT_DEPENDENCIES.all, param, 'dependencies'] as const,
};
