import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppItem = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  width: 100%;
  height: 48px;
  transition: background-color 300ms ease, border-color 300ms ease;
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[0]};
  ${({ theme: { elevations } }) => elevations.e100};
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 1;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: ${({ theme: { palette } }) => palette.nv_neutral[30]};
    background-color: ${({ theme: { palette } }) => palette.nv_neutral[20]};
  }

  &.show-more {
    background-color: ${({ theme: { palette } }) => palette.nv_neutral[10]};

    &:hover {
      border-color: ${({ theme: { palette } }) => palette.nv_neutral[40]};
      background-color: ${({ theme: { palette } }) => palette.nv_neutral[30]};
    }
  }
`;

export const AppsContainer = styled(NvBox)`
  display: grid;
  width: 100%;
  height: auto;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(342px, 1fr));
`;
