import { NvAxios, useMutation } from '@novaera/core';
import { GetMsTeamsConsentResponse } from './types';

const getMsTeamsConsent = async () => {
  const result = await NvAxios.get<GetMsTeamsConsentResponse>('/v1/msteams/consent');
  return result?.data;
};

export const useGetMsTeamsConsent = () => {
  return useMutation(getMsTeamsConsent);
};
