import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DOCUMENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DOCS } from '../keys';
import { useUpdateInfiniteSearchCache } from '../use-update-infinite-search-cache';
import { UseDeleteDocParams } from './types';

const deleteDocument = async ({ appId, docId }: UseDeleteDocParams) => {
  await NvAxios.delete<void>(`${DOCUMENTS_ROOT_PATH(appId)}/${docId}`);
};

export const useDeleteDocument = () => {
  const cache = useQueryClient();
  const { updateInfiniteSearchCache } = useUpdateInfiniteSearchCache();

  return useMutation(deleteDocument, {
    onSuccess: (_, { appId, docId }) => {
      updateInfiniteSearchCache({
        appId,
        updateFunction: ({ old }) => {
          return (
            old?.pages.map(({ documents, ...others }) => {
              return {
                ...others,
                documents: documents.filter((doc) => doc.id !== docId),
              };
            }) ?? []
          );
        },
      });

      cache.removeQueries(QUERY_KEYS_DOCS.detail(appId, docId));
    },
  });
};
