import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_ACTION_EXECUTION_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTION_TEST_EXECUTION } from '../keys';
import { IntegrationTestActionParams, IntegrationTestActionResponse, TestActionResultParams } from '../types';

const testIntegrationAction = async (params: IntegrationTestActionParams) => {
  const { integrationId, actionId, ...rest } = params;
  const result = await NvAxios.post<IntegrationTestActionResponse>(
    `${INTEGRATION_ACTION_EXECUTION_PATH(integrationId, actionId)}`,
    rest
  );
  return result?.data;
};

export const useTestIntegrationAction = () => {
  const cache = useQueryClient();
  return useMutation(testIntegrationAction, {
    onSuccess(data, { integrationId, actionId }) {
      cache.setQueryData<IntegrationTestActionResponse>(
        QUERY_KEYS_INTEGRATION_ACTION_TEST_EXECUTION.detail(integrationId, actionId, data.executionId),
        data
      );
    },
  });
};

export const useGetIntegrationTestResult = ({ integrationId, actionId, executionId }: TestActionResultParams) => {
  const cache = useQueryClient();
  let data: IntegrationTestActionResponse | undefined = undefined;
  if (integrationId && actionId && executionId) {
    data = cache.getQueryData<IntegrationTestActionResponse>(
      QUERY_KEYS_INTEGRATION_ACTION_TEST_EXECUTION.detail(integrationId, actionId, executionId)
    );
  }

  return { data };
};
