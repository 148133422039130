import Skeleton from '@mui/material/Skeleton';
import { styled } from '@novaera/theme-provider';
import { TextSkeletonSize } from './types';

const getTextSkeletonHeight = (size?: TextSkeletonSize): number => {
  switch (size) {
    case TextSkeletonSize.XLARGE:
      return 28;
    case TextSkeletonSize.LARGE:
      return 24;
    case TextSkeletonSize.MEDIUM:
      return 20;
    case TextSkeletonSize.SMALL:
      return 16;
    case TextSkeletonSize.XSMALL:
      return 14;
    default:
      return 24;
  }
};

export const SkeletonStyled = styled(Skeleton)<{ size?: TextSkeletonSize }>`
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};

  &.MuiSkeleton-text {
    border-radius: 6px;
    height: ${({ size }) => getTextSkeletonHeight(size)}px;
  }

  &.MuiSkeleton-rectangular {
    border-radius: 6px;
  }

  &.MuiSkeleton-circular {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
  }
`;
