import { useMemo } from 'react';
import { IntegrationEventRuleState } from '../../types';
import { GetIntegrationEventRuleResponse } from '../types';

export const useIntegrationEventRule = (response: GetIntegrationEventRuleResponse | undefined) => {
  const integrationEventRule = useMemo(() => {
    return !response
      ? undefined
      : response.draft
      ? { state: IntegrationEventRuleState.DRAFT, ...response.draft }
      : { state: IntegrationEventRuleState.PERSISTED, ...response.persisted };
  }, [response]);

  const persistedIntegrationEventRule = useMemo(() => {
    return response?.persisted;
  }, [response?.persisted]);

  const draftIntegrationEventRule = useMemo(() => {
    return response?.draft;
  }, [response?.draft]);

  return {
    integrationEventRule,
    persistedIntegrationEventRule,
    draftIntegrationEventRule,
  };
};
