import {
  NvLinkIcon,
  NvListAltIcon,
  NvMailOutlineIcon,
  NvPercentIcon,
  NvPhoneIcon,
  NvTagIcon,
  NvTitleIcon,
} from '@novaera/core';

export const SOURCE_TYPE_OPTIONS = [
  { label: 'String', value: 'string', startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} /> },
  { label: 'Email', value: 'email', startIcon: <NvMailOutlineIcon sx={{ width: '16px', height: '16px' }} /> },
  { label: 'URL', value: 'url', startIcon: <NvLinkIcon sx={{ width: '16px', height: '16px' }} /> },
  { label: 'Phone number', value: 'phone', startIcon: <NvPhoneIcon sx={{ width: '16px', height: '16px' }} /> },
  { label: 'Integer', value: 'integer', startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} /> },
  { label: 'Decimal', value: 'float', startIcon: <NvPercentIcon sx={{ width: '16px', height: '16px' }} /> },
  { label: 'Map', value: 'map', startIcon: <NvListAltIcon sx={{ width: '16px', height: '16px' }} /> },
];
