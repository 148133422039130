import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_EVENT_RULES } from '../keys';
import { GetIntegrationEventRuleResponse } from '../use-get-integration-event-rule/types';
import { GetIntegrationEventRulesResponse } from '../use-get-integration-event-rules/types';
import { UpdateIntegrationEventRuleMetadataParams, UpdateIntegrationEventRuleMetadataResponse } from './types';

const updateIntegrationEventRuleMetadata = async (params: UpdateIntegrationEventRuleMetadataParams) => {
  const { integrationId, id, name, description } = params;
  const result = await NvAxios.post<UpdateIntegrationEventRuleMetadataResponse>(
    `${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}/metadata`,
    { name, description }
  );
  return result?.data;
};

export const useUpdateIntegrationEventRuleMetadata = () => {
  const cache = useQueryClient();

  return useMutation(updateIntegrationEventRuleMetadata, {
    onSuccess: ({ name, description }, { integrationId, id }) => {
      cache.setQueryData<GetIntegrationEventRuleResponse>(
        QUERY_KEYS_INTEGRATION_EVENT_RULES.detail(integrationId, id),
        (old) =>
          old
            ? {
                ...old,
                persisted: { ...old.persisted, name, description },
              }
            : undefined
      );
      cache.setQueryData<GetIntegrationEventRulesResponse>(
        QUERY_KEYS_INTEGRATION_EVENT_RULES.list(integrationId),
        (old) =>
          old
            ? {
                ...old,
                eventRules: old.eventRules.map((eventRule) =>
                  eventRule.id === id ? { ...eventRule, name, description } : eventRule
                ),
              }
            : undefined
      );
    },
  });
};
