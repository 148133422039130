import { FC } from 'react';
import { useUserAppOptionsContext } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/components/options/provider';
import { useWorkflowDynamicInputContext } from '../../../dynamic-input/providers/workflow-dynamic-input-provider';
import { ParameterMapperForm } from '../../parameter-mapper-form';
import { PropertyMapperFormProps } from '../../parameter-mapper-form/types';

export const WorkflowParameterMapperForm: FC<
  React.PropsWithChildren<
    Pick<
      PropertyMapperFormProps,
      | 'inputParameters'
      | 'onParameterMappingsChanged'
      | 'initialParameterMappings'
      | 'context'
      | 'onScriptedChange'
      | 'isForceHideSwitch'
      | 'isDisplayValueConfigurable'
    >
  >
> = ({
  inputParameters,
  onParameterMappingsChanged,
  initialParameterMappings,
  context,
  onScriptedChange,
  isForceHideSwitch,
  isDisplayValueConfigurable,
}) => {
  const { getOptionsResponse, setInputParameterIdsShowingOptions } = useUserAppOptionsContext();
  const { getDynamicInputParameters, dynamicInputParameters, getDynamicInputComponentState } =
    useWorkflowDynamicInputContext();
  return (
    <ParameterMapperForm
      showHiddenConfig
      isDisplayValueConfigurable={isDisplayValueConfigurable}
      dynamicInputParameters={dynamicInputParameters}
      context={context}
      inputParameters={inputParameters}
      onParameterMappingsChanged={onParameterMappingsChanged}
      initialParameterMappings={initialParameterMappings}
      getOptionsResponse={getOptionsResponse}
      setInputParameterIdsShowingOptions={setInputParameterIdsShowingOptions}
      getDynamicInputParameters={getDynamicInputParameters}
      onScriptedChange={onScriptedChange}
      isForceHideSwitch={isForceHideSwitch}
      getDynamicInputComponentState={getDynamicInputComponentState}
    />
  );
};
