import { NvAxios, useMutation } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { AppSchemaDetail } from '../types';
import { UpdateAppSchemaStarParams } from './types';

const updateAppSchemaStar = async ({ schemaId, undo }: UpdateAppSchemaStarParams) => {
  const result = await NvAxios.patch(`${APP_DIRECTORY_ROOT_PATH}/schemas/${schemaId}/star`, { undo });
  return result?.data;
};

export const useUpdateAppSchemaStar = ({ schemaId }: { schemaId: string; version: string | null }) => {
  const cache = useQueryClient();
  return useMutation(updateAppSchemaStar, {
    onSuccess: (response, { undo }) => {
      const cachedSchemaDetail = cache.getQueryData<AppSchemaDetail>(QUERY_KEYS_APP_DIRECTORY.detail(schemaId));

      if (cachedSchemaDetail) {
        cache.setQueryData(QUERY_KEYS_APP_DIRECTORY.detail(schemaId), {
          ...cachedSchemaDetail,
          numberOfStars: undo
            ? Number(cachedSchemaDetail.numberOfStars) - 1
            : Number(cachedSchemaDetail.numberOfStars) + 1,
          starred: !undo,
        });
      } else {
        assert(
          true,
          new Error(
            '[useUpdateAppSchemaStar] - Looks like QUERY_KEYS_APP_DIRECTORY.detail(schemaId) cache was removed.'
          ),
          'ERROR'
        );
      }

      cache.invalidateQueries(QUERY_KEYS_APP_DIRECTORY.lists());
    },
  });
};
