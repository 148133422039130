import { EventPayload, GetSampleOrExampleEventResponse, S3WorkflowEvent } from '../../types';

export const isEventPayload = (payload?: GetSampleOrExampleEventResponse): payload is EventPayload => {
  if (!payload) {
    return false;
  }
  return payload && 'payload' in payload;
};

export const isS3WorkflowEventPayload = (payload?: GetSampleOrExampleEventResponse): payload is S3WorkflowEvent => {
  if (!payload) {
    return false;
  }
  return payload && 'key' in payload && 'link' in payload;
};
