import { NvForm } from '@novaera/core';
import { ExportSlackAppFormBody } from './body';
import { ExportSlackAppFocusStateProps, ExportSlackAppFormValues } from './types';
import { useExportSlackAppController } from './use-export-slack-app';

export const ExportSlackAppFocusState: React.FC<React.PropsWithChildren<ExportSlackAppFocusStateProps>> = ({
  isOpen,
  onClose,
  type,
}) => {
  const { initialValues, isInitialValuesLoading, handleExportSlackAppSubmit } = useExportSlackAppController({ type });
  return isOpen && !isInitialValuesLoading ? (
    <NvForm<ExportSlackAppFormValues> onSubmit={handleExportSlackAppSubmit} initialValues={initialValues}>
      <ExportSlackAppFormBody isOpen={isOpen} onClose={onClose} type={type} />
    </NvForm>
  ) : null;
};
