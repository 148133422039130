import { UserWorkflowExecutionResultResponse, WorkflowWithState } from '@novaera/actioner-service';
import { NvCustomCheckCircle, NvCustomSpinner, NvFlex, NvLink, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import _ from 'lodash';
import { useEffect } from 'react';
import { ErrorReasonCard } from '../../../../../../../install-user-app-flow/install-steps/run-setup-workflow-step/setup-workflow-item/styled';
import { WORKFLOW_HISTORY, WORKFLOW_HISTORY_DETAIL } from '../../../../../../../workflow-detail/constants';
import { useWorkflowStateResult } from './controllers/use-workflow-state-result';
import { StyledCard } from './styled';

export const WorkflowStateResult = ({
  result,
  isLoading,
  onWorkflowStateGet,
  executionIdentifier,
  workflow,
}: {
  result?: UserWorkflowExecutionResultResponse | null;
  isLoading: boolean;
  onWorkflowStateGet: (UserWorkflowExecutionResultResponse: UserWorkflowExecutionResultResponse) => void;
  executionIdentifier?: string;
  workflow?: WorkflowWithState;
}) => {
  const { state, inProgress, isError, isSuccess } = useWorkflowStateResult({ response: result });
  const theme = useTheme();

  useEffect(() => {
    if (!_.isError(state) && !isError && isSuccess && state) {
      onWorkflowStateGet(state);
    }
  }, [isError, isSuccess, onWorkflowStateGet, state]);

  if (!isLoading && isError && workflow) {
    return (
      <NvFlex gap="16px">
        <NvTypography variant="body1">
          We couldn’t run <b>{workflow?.name}</b> properly.
        </NvTypography>
        <ErrorReasonCard>
          <NvTypography variant="body1">
            Please check the{' '}
            <NvLink
              href={
                executionIdentifier
                  ? WORKFLOW_HISTORY_DETAIL({
                      appId: workflow.appId,
                      workflowId: workflow.id,
                      executionId: executionIdentifier,
                    })
                  : WORKFLOW_HISTORY({
                      appId: workflow.appId,
                      workflowId: workflow.id,
                    })
              }
            >
              workflow history
            </NvLink>{' '}
            for more information
          </NvTypography>
        </ErrorReasonCard>
      </NvFlex>
    );
  } else if (isLoading || inProgress) {
    return (
      <StyledCard>
        <NvFlex gap={'8px'} flexDirection="row" columnGap={'8px'} alignItems="center">
          <NvCustomSpinner htmlColor={theme.palette.nv_neutral_alpha[300]} sx={{ width: '16px', height: '16px' }} />
          <NvTypography variant="h5">Workflow is in progress</NvTypography>
        </NvFlex>
      </StyledCard>
    );
  } else if (!isLoading && isSuccess) {
    return (
      <StyledCard className="success">
        <NvFlex gap={'8px'} flexDirection="row" columnGap={'8px'} alignItems="center">
          <NvCustomCheckCircle htmlColor={theme.palette.nv_success[50]} sx={{ width: '16px', height: '16px' }} />
          <NvTypography variant="h5">Workflow is successfully run</NvTypography>
        </NvFlex>
      </StyledCard>
    );
  } else {
    return (
      <StyledCard className="error">
        <NvFlex gap={'8px'} flexDirection="row" columnGap={'8px'} alignItems="center">
          <NvTypography variant="h5">The workflow run could not be completed. Try again.</NvTypography>
        </NvFlex>
      </StyledCard>
    );
  }
};
