import {
  DynamicValueRuleType,
  MultiValueRule,
  MultiValueRuleType,
  Operation,
  SingleValueRule,
  SingleValueRuleType,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvAddBoxIcon,
  NvAutocomplete,
  NvButton,
  NvConditionalRender,
  NvConditionalWrap,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvSelect,
  NvTextField,
  isMustacheWritten,
  useForm,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { OPTIONS, VALUE_TYPE_OPTIONS } from '../constants';
import { Options } from '../types';
import { ArrayField } from './array-field';
import { DEFAULT_VALUE } from './constants';
import { useRulesController } from './controllers/use-rules';
import { FillingComponent, StyledExpression, StyledOperator, StyledRuleList, StyledValue } from './styled';
import { RulesProps } from './types';

export const Rules = React.forwardRef<HTMLDivElement, RulesProps>(
  (
    {
      conditionFieldName,
      codeInputContext,
      layout = 'horizontal',
      WrapperComponent,
      showAddButton = true,
      showRemoveButton = true,
      valueTypeOptions = VALUE_TYPE_OPTIONS,
    },
    ref
  ) => {
    const { change } = useForm();
    const { onAddClicked, onRemoveClicked, fields } = useRulesController({
      conditionFieldName,
    });

    useEffect(() => {
      if (fields.value && fields.length === 0) {
        onAddClicked();
      }
    }, [fields, onAddClicked]);

    const layoutClass = classNames({
      'horizontal-layout': layout === 'horizontal',
      'vertical-layout': layout === 'vertical',
    });

    return (
      <FieldArray name={`${conditionFieldName}.rules`} defaultValue={DEFAULT_VALUE}>
        {({ fields }) => {
          return (
            <NvFlex width="100%" gap="8px" alignItems="flex-start">
              {fields.map((name, index) => {
                const field = fields.value[index];

                return (
                  <NvConditionalWrap
                    wrap={(children) => {
                      assert(
                        !!WrapperComponent,
                        new Error('wrapper component should exist when it is wrapping'),
                        'ERROR'
                      );
                      return (
                        <WrapperComponent
                          children={children}
                          onRemoveClicked={() => onRemoveClicked(index)}
                          index={index + 1}
                        />
                      );
                    }}
                    key={`match_event_section_${name}`}
                    condition={WrapperComponent !== undefined}
                  >
                    <StyledRuleList ref={ref} className={layoutClass} key={`match_event_section_${name}`}>
                      <StyledExpression fieldLength={fields.value?.length} className={layoutClass}>
                        <NvField
                          formControlStyle={{ width: '100%' }}
                          name={`${name}.keyExpression`}
                          validateForWarnings={[isMustacheWritten()]}
                          component={<CodeInput mode="expression" context={codeInputContext} />}
                        />
                      </StyledExpression>

                      <StyledOperator className={layoutClass}>
                        <NvField<Options>
                          showError={false}
                          name={name}
                          isAutoComplete
                          format={(value) =>
                            OPTIONS({ type: field.value?.type }).find(
                              (option) =>
                                option.value.operation === value?.operation &&
                                option.value.negateResult === value?.negateResult
                            ) ?? null
                          }
                          parse={(autoCompleteInput) => ({
                            ...fields.value[index],
                            negateResult: autoCompleteInput?.value.negateResult,
                            operation: autoCompleteInput?.value.operation,
                          })}
                          component={({ value, onChange }) => (
                            <NvAutocomplete
                              value={value}
                              onChange={(e, v) => {
                                onChange(e, v);
                                const type = VALUE_TYPE_OPTIONS[v.value.operation][0].value;
                                change(`${name}.value`, { type });
                              }}
                              size="small"
                              disableClearable
                              renderInput={(props) => <NvTextField {...props} placeholder="Select operation" />}
                              isOptionEqualToValue={(option, selectedItem) =>
                                option?.value?.operation === selectedItem?.value?.operation &&
                                selectedItem?.value?.negateResult === option?.value?.negateResult
                              }
                              options={OPTIONS({
                                type: field.value?.type,
                              })}
                              sx={{ flex: '0 0 auto' }}
                            />
                          )}
                        />
                        {!(field?.operation === Operation.IS_EMPTY || field?.operation === Operation.EXISTS) && (
                          <NvFlex maxWidth="52px">
                            <NvField
                              name={`${name}.value.type`}
                              component={({ value, onChange }) => {
                                return (
                                  <NvSelect
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      change(`${name}.value.value`, undefined);
                                    }}
                                    renderValue={(option) =>
                                      valueTypeOptions[field?.operation].find(
                                        ({ value }) => value === (option as SingleValueRuleType | MultiValueRuleType)
                                      )?.startIcon
                                    }
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                      },
                                    }}
                                    options={valueTypeOptions[field?.operation]}
                                  />
                                );
                              }}
                            />
                          </NvFlex>
                        )}
                      </StyledOperator>

                      <StyledValue fieldLength={fields.value?.length} className={classNames(layoutClass)}>
                        {field?.operation === Operation.IS_ONE_OF &&
                        field.value?.type !== DynamicValueRuleType.DYNAMIC ? (
                          <NvField name={`${name}.value`} component={<ArrayField name={name} />} />
                        ) : field?.operation === Operation.IS_EMPTY || field?.operation === Operation.EXISTS ? (
                          <FillingComponent />
                        ) : (
                          <>
                            <NvConditionalRender when={field.value?.type === DynamicValueRuleType.DYNAMIC}>
                              <NvField
                                name={`${name}.value.valueExpression`}
                                format={(fieldValue: SingleValueRule | MultiValueRule | null) => {
                                  return fieldValue ?? '';
                                }}
                                validateForWarnings={[isMustacheWritten()]}
                                component={<CodeInput mode="expression" context={codeInputContext} />}
                              />
                            </NvConditionalRender>
                            <NvConditionalRender when={field.value?.type !== DynamicValueRuleType.DYNAMIC}>
                              <NvField
                                name={`${name}.value.value`}
                                format={(fieldValue: SingleValueRule | MultiValueRule | null) => {
                                  return fieldValue ?? '';
                                }}
                                parse={(value) => {
                                  if (
                                    field.value?.type === SingleValueRuleType.STRING ||
                                    field.value?.type === MultiValueRuleType.LIST
                                  ) {
                                    return value;
                                  } else if (field.value?.type === SingleValueRuleType.NUMBER) {
                                    return parseInt(value);
                                  } else if (field.value?.type === SingleValueRuleType.BOOLEAN) {
                                    return Boolean(value);
                                  } else {
                                    return undefined;
                                  }
                                }}
                                component={
                                  field.value?.type === SingleValueRuleType.STRING ? (
                                    <NvTextField />
                                  ) : field.value?.type === SingleValueRuleType.NUMBER ? (
                                    <NvTextField type="number" />
                                  ) : field.value?.type === SingleValueRuleType.BOOLEAN ? (
                                    <NvSelect
                                      placeholder="No value"
                                      options={[
                                        { label: 'True', value: true },
                                        { label: 'False', value: false },
                                      ]}
                                    />
                                  ) : (
                                    <></>
                                  )
                                }
                              />
                            </NvConditionalRender>
                          </>
                        )}
                      </StyledValue>

                      {showRemoveButton && fields.value?.length > 1 && (
                        <NvFlex marginTop="4px">
                          <NvButton
                            onlyIcon
                            color="ghost"
                            size="small"
                            onClick={() => {
                              onRemoveClicked(index);
                            }}
                          >
                            <NvDeleteOutlineIcon />
                          </NvButton>
                        </NvFlex>
                      )}
                    </StyledRuleList>
                  </NvConditionalWrap>
                );
              })}
              {showAddButton && (
                <NvButton
                  startIcon={<NvAddBoxIcon />}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    onAddClicked();
                  }}
                >
                  Add filter
                </NvButton>
              )}
            </NvFlex>
          );
        }}
      </FieldArray>
    );
  }
);
