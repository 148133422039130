import { styled } from '@novaera/theme-provider';
import { NvBox } from '../../box';

export const NodeListItemCard = styled(NvBox, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'isCompact',
})<{
  disabled?: boolean;
  isCompact?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${({ isCompact }) => (isCompact ? '12px 16px' : '16px')};
  gap: 8px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};

  ${({ disabled, theme }) =>
    disabled
      ? `opacity:0.5;`
      : `
      cursor: pointer;
      &:hover {
        background: ${theme.palette.nv_neutral[5]};
      }

      &:active {
        background: ${theme.palette.nv_main[20]};
      }
  `}

  &:last-of-type {
    border-bottom: none;
  }

  ${({ isCompact }) => (isCompact ? `align-items:center; .name-wrapper:only-child {margin-top: 0px!important;}` : null)}
`;

export const NodeListItemCardLoading = styled(NodeListItemCard)`
  cursor: initial;

  &:hover {
    background: ${({ theme }) => theme.palette.nv_neutral[0]};
  }

  &:active {
    background: ${({ theme }) => theme.palette.nv_neutral[0]};
  }
`;

export const NodeListItemContent = styled(NvBox)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;
  min-width: 0;

  .name-wrapper:only-child {
    margin-top: 4px;
  }
`;
