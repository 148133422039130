import {
  useFetchProfile,
  useGetOrganizationMapping,
  useGetSSO,
  useGetSSOParameters,
  useGetSSOProviders,
} from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvButton,
  NvDivider,
  NvField,
  NvFlex,
  NvSelect,
  NvSkeleton,
  NvTypography,
  useField,
} from '@novaera/core';
import { useMemo } from 'react';
import { SearchAsYouTypeValuesProvider } from '../../../../action-designer/providers/search-as-you-type-values';
import { ApplySSO } from './apply-sso';
import { SimpleField } from './simple-field';
import { StyledSectionMessage } from './styled';

export const SSOSettingsBody = () => {
  const { input: ssoProviderInput } = useField('ssoProvider.type');

  const { data: ssoProviders } = useGetSSOProviders();

  const { data: ssoParameters, isLoading: isSSOParametersLoading } = useGetSSOParameters({
    ssoType: ssoProviderInput.value,
  });

  const { data: profile } = useFetchProfile();
  const { data: organization } = useGetOrganizationMapping({
    workspaceId: profile?.workspaceId,
  });

  const { data: savedSSO, isLoading: isSavedSSOLoading } = useGetSSO({
    containerType: 'organization',
    organizationId: organization?.organizationId,
  });

  const docLink = useMemo(
    () => ssoProviders?.providers?.find((provider) => provider.type === ssoProviderInput.value)?.documentationLink,
    [ssoProviders, ssoProviderInput.value]
  );

  const ssoProviderOptions = ssoProviders?.providers?.map((provider) => ({
    value: provider.type,
    label: provider.display,
  }));

  const parameters = ssoParameters?.parameters;

  return (
    <NvFlex gap={'16px'}>
      <NvField
        labelText="Select single sign-on provider"
        labelVariant="h4"
        direction="label-on-top"
        name={'ssoProvider.type'}
        component={
          <NvSelect
            placeholder="Select a provider"
            sx={{
              width: '300px',
            }}
            options={ssoProviderOptions}
          />
        }
      />
      {docLink && (
        <StyledSectionMessage
          message={
            <NvFlex flexDirection={'row'} alignItems={'center'}>
              <NvTypography variant="body2" flex={'1 1 0'} minWidth={'0'}>
                Check out the documentation for how to set SSO settings on <b>{docLink}</b>
              </NvTypography>
              <NvButton
                size="small"
                color="secondary"
                endIcon={<NvArrowForwardIcon />}
                onClick={() => {
                  window.open(docLink, '_blank');
                }}
                isFitContent
              >
                Go to documentation
              </NvButton>
            </NvFlex>
          }
          variant={'info'}
        />
      )}
      {ssoProviderInput.value && <NvDivider />}
      {isSavedSSOLoading ? (
        <NvSkeleton height="50px" width={'40%'} />
      ) : (
        profile &&
        ssoProviderInput.value && (
          <ApplySSO emailDomain={savedSSO?.emailDomain} userEmailDomain={profile.email.split('@')?.[1]} />
        )
      )}
      <SearchAsYouTypeValuesProvider>
        {ssoParameters?.parameters.map((inputParameter, index) => {
          return <SimpleField key={inputParameter.id} inputParameter={inputParameter} index={index} />;
        })}
      </SearchAsYouTypeValuesProvider>
    </NvFlex>
  );
};
