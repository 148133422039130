import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { QUERY_KEY_GET_SLACK_APP } from '../keys';

import { InstallSlackAppParams, InstallSlackAppResponse } from './types';

const installSlackApp = async ({ appId, installSlackAppRequestParams }: InstallSlackAppParams) => {
  const result = await NvAxios.post<InstallSlackAppResponse>(
    `${SLACK_APP_ROOT_PATH(appId)}/install`,
    installSlackAppRequestParams
  );
  return result?.data;
};

export const useInstallSlackApp = (appId: string) => {
  const cache = useQueryClient();
  return useMutation(installSlackApp, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEY_GET_SLACK_APP.detail(appId));
    },
  });
};
