import classnames from 'classnames';
import { StyledNvTabs } from './styled';
import { SubTabsProps } from './types';

export const SubTabs: React.FC<React.PropsWithChildren<SubTabsProps>> = ({
  customWrapperClassName,
  commandLineHeight,
  ...props
}) => {
  return (
    <StyledNvTabs
      className={classnames(customWrapperClassName, 'sub-tabs')}
      commandLineHeight={commandLineHeight}
      BodyWrapperComponent={<div className="sub-tabs-body-wrapper" />}
      {...props}
    />
  );
};
