import { InputParameter, UIComponentType } from '@novaera/actioner-service';
import { NvChatBubbleIcon, NvSettingsIcon } from '@novaera/core';

export const firstPanelUIComponentNames = ['channelId', 'users', 'channel', 'ts'];

export const SLACK_SEND_MESSAGE_CATEGORIES = [
  {
    id: '1_1',
    icon: <NvChatBubbleIcon />,
    title: 'Message blocks',
    filter: (input: InputParameter) => input.uiComponent.type === UIComponentType.BLOCK_LIST,
  },

  {
    id: '1_3',
    icon: <NvSettingsIcon />,
    title: 'Advanced settings',
    filter: (input: InputParameter) =>
      input.uiComponent.type !== UIComponentType.BLOCK_LIST && !input.schema?.mandatory,
    wrapWithSimpleSection: true,
  },
];
