import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CustomStartRunWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
`;
