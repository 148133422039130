import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StartNodeWrapper = styled(NvBox)`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  padding: 0 16px;
  height: 40px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 2px solid ${({ theme }) => theme.palette.nv_code.green};
  border-radius: 12px;
`;

export const PaperWrapper = styled(NvBox)`
  position: absolute;
  top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 370px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 12px;
  overflow: hidden;
`;
