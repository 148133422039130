import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvTypography } from '../typography';

export const AddNewButtonWrapper = styled(NvBox)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  gap: 8px;
  button.add-new-button {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const LabelStyled = styled(NvTypography)``;
