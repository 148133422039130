import { RecordItem, RecordReferenceSchema, useSearchRecords } from '@novaera/actioner-service';
import { DefaultRenderOption, NvAutocomplete, NvTextField, NvTypography } from '@novaera/core';
import { FC, SyntheticEvent, useMemo } from 'react';
import { RECORD_LIMIT } from '../../../constants';

export const SelectItemFromModel: FC<
  React.PropsWithChildren<{
    value?: string;
    onChange?: (event: SyntheticEvent<Element, Event>, value: string | string[] | null) => void;
    recordReferenceSchema?: RecordReferenceSchema;
    appId?: string;
    isMultiple?: boolean;
  }>
> = ({ value, onChange, recordReferenceSchema, appId, isMultiple = false }) => {
  const { modelId } = recordReferenceSchema ?? { modelId: undefined };

  const {
    data: searchRecordsResult,
    isLoading: isSearchRecordsLoading,
    isRefetching: isSearchRecordsRefetching,
  } = useSearchRecords({
    appId,
    modelId,
    queryParams: {
      query: '',
      limit: RECORD_LIMIT,
    },
  });

  const { options, mapOfRecordItems } = useMemo(() => {
    const mapOfRecordItems: Record<string, RecordItem> = {};
    const options = searchRecordsResult
      ? searchRecordsResult.records.map((r) => {
          mapOfRecordItems[r.id] = r;
          return r.id;
        })
      : [];
    return { options, mapOfRecordItems };
  }, [searchRecordsResult]);
  return (
    <NvAutocomplete
      loading={isSearchRecordsLoading || isSearchRecordsRefetching}
      options={options}
      renderInput={(params) => {
        return (
          <NvTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            placeholder="Select item"
          />
        );
      }}
      multiple={isMultiple}
      value={value}
      renderOption={(props, option) => (
        <DefaultRenderOption {...props} key={option}>
          <NvTypography>{JSON.stringify(mapOfRecordItems[option])}</NvTypography>
        </DefaultRenderOption>
      )}
      getOptionLabel={(option) => (option ? option : '')}
      onChange={onChange}
    />
  );
};
