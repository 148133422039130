import { USER_PERMISSION } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { AddButton } from '../../../../../../../../components/button/add';
import { UserPermissionBoundary } from '../../../../../../../../user-permission-boundary';

export const VariablesTitle = ({ onAddVariableClicked }: { onAddVariableClicked: () => void }) => {
  return (
    <NvFlex gap={'10px'}>
      <NvFlex flexDirection={'row'}>
        <NvFlex flex="1 1 auto" minWidth="0">
          <NvTypography variant="h3">Variables</NvTypography>
        </NvFlex>
        <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
          <NvFlex>
            <AddButton label="Add request" onClick={onAddVariableClicked} />
          </NvFlex>
        </UserPermissionBoundary>
      </NvFlex>
      <NvFlex>
        <NvTypography variant="body2">
          Connection variables allow workflow builders to set additional restrictions for authentication. The value
          given for the connection variables in the workflow designer will be used as an authentication condition.
        </NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
