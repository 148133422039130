import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';

import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS } from '../keys';
import { QUERY_KEYS_NODE } from '../node/keys';
import { UseGetWorkflowResponse } from '../use-get-workflow/types';
import { UpdateWorkflowGraphParams } from './types';

const updateWorkflowGraph = async ({ appId, workflowId, payload }: UpdateWorkflowGraphParams) => {
  const result = await NvAxios.post<UseGetWorkflowResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/nodes/batch`,
    {
      ...payload,
    }
  );
  return result?.data;
};

export const useUpdateWorkflowGraph = () => {
  const cache = useQueryClient();

  return useMutation(updateWorkflowGraph, {
    onSuccess: (response, { appId, workflowId }) => {
      const currentNodeAliases = (response.draft ?? response.saved).graph?.vertices.map(({ alias }) => alias);
      const nodeDetailCaches = cache.getQueriesData(QUERY_KEYS_NODE.detail({ appId, workflowId }));

      nodeDetailCaches.forEach((nodeDetailCache) => {
        const [queryKey, _] = nodeDetailCache;
        const aliasDoesNotExistButHasCachedData =
          currentNodeAliases?.filter((alias) => queryKey.includes(alias))?.length === 0;

        if (aliasDoesNotExistButHasCachedData) {
          cache.removeQueries(queryKey);
        }
      });

      cache.setQueryData<UseGetWorkflowResponse>(QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId }), (old) =>
        old
          ? {
              ...old,
              ...response,
            }
          : undefined
      );
    },
  });
};
