import { useDeleteSlackApp, useGetSlackApp } from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvButton,
  NvDeleteOutlineIcon,
  NvFocusState,
  NvForm,
  NvSlackIcon,
  StepTracker,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { USER_APP_DESCRIPTION } from '../../constants';

import { PropsWithChildren } from 'react';
import { SlackAccessToken } from '../export-slack-app/body/slack-access-token';
import { RemoveStep } from './remove-step';
import { RemoveSlackAppFocusStateProps, RemoveSlackAppFormValues } from './types';

export const RemoveSlackAppFocusState: React.FC<PropsWithChildren<RemoveSlackAppFocusStateProps>> = ({
  isOpen,
  onClose,
}) => {
  const { userAppId } = useParams();
  const navigate = useNavigate();
  const { data: slackApp } = useGetSlackApp({ appId: userAppId });
  const { mutate: deleteSlackApp } = useDeleteSlackApp(userAppId);

  const handleRemoveSlackAppSubmit = (values: RemoveSlackAppFormValues) => {
    assert(
      !!userAppId && !!slackApp,
      new Error('Slack can not be removed without appId, slackAppId and slackConfigurationToken'),
      'ERROR'
    );

    return new Promise<void>((resolve) => {
      deleteSlackApp(
        {
          appId: userAppId,
          deleteSlackAppRequestParams: {
            slackAppId: slackApp.slackAppId,
            slackConfigurationToken: values.slackConfigurationToken,
          },
        },
        {
          onSuccess: () => {
            navigate(USER_APP_DESCRIPTION(userAppId));
          },
          onSettled: () => resolve(),
        }
      );
    });
  };

  return isOpen ? (
    <NvForm<RemoveSlackAppFormValues> onSubmit={handleRemoveSlackAppSubmit}>
      {({ submitting, form, hasValidationErrors }) => (
        <NvFocusState
          open={isOpen}
          onClose={onClose}
          headerLogo={<NvSlackIcon fontSize="large" />}
          title={`Remove ${slackApp?.slackAppManifest.displayInfo.name} app from Slack`}
        >
          <StepTracker
            steps={[
              {
                label: 'Slack Access Token',
                content: <SlackAccessToken />,
                nextButton: ({ nextClick }) => {
                  return (
                    <NvButton
                      disabled={hasValidationErrors}
                      endIcon={<NvArrowForwardIcon />}
                      onClick={() => {
                        nextClick();
                      }}
                    >
                      Next
                    </NvButton>
                  );
                },
                showButtons: true,
              },
              {
                label: 'Remove app',
                content: <RemoveStep />,
                nextButton: ({ nextClick }) => {
                  return (
                    <NvButton
                      color="error"
                      endIcon={<NvDeleteOutlineIcon />}
                      loading={submitting}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      Remove
                    </NvButton>
                  );
                },
                showButtons: true,
              },
            ]}
            onCancelClick={onClose}
          />
        </NvFocusState>
      )}
    </NvForm>
  ) : null;
};
