import {
  ActionOptionProducer,
  DataSource,
  DataSourceType,
  Integration,
  KeyDisplayValue,
  OptionsProducer,
} from '@novaera/actioner-service';
import { NvAddBoxIcon, NvButton, NvPopover, useField } from '@novaera/core';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useCallback } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import OnChange from '../../../../../../../../../../action-designer/components/on-change';
import { PropertyPanelListHeader } from '../../../../../../../../../../components';
import { DataSourceTypeSection } from '../../../../../../../../../../components/property-configuration/components/data-source-type-section';
import { StaticDataSourceMapper } from '../../../../../../../../../../components/property-configuration/components/static-data-source-mapper';
import { IntegrationSelect } from '../../../../../../components/integration-select';
import { FormTriggerDynamicDataSourceMapper } from './dynamic-data-source-mapper';
import { FormTriggerDatasourceMapperProps } from './types';

export const FormTriggerDatasourceMapper: React.FC<React.PropsWithChildren<FormTriggerDatasourceMapperProps>> = ({
  schema,
  onDataSourceIndexChanged,
  dataSourceIndex,
}) => {
  const {
    input: { value: dataSourceFormValue },
  } = useField<DataSource>('uiComponent.dataSource', { subscription: { value: true } });

  const {
    fields: { push: addNewOptionProducer, value: optionProducers },
  } = useFieldArray<Partial<OptionsProducer>>('uiComponent.dataSource.optionsProducers');

  const {
    fields: { push: addNewStaticOption },
  } = useFieldArray<KeyDisplayValue>('uiComponent.dataSource.options');

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'dynamic-data-source-popup',
  });

  const handleNewStaticItemClick = useCallback(() => {
    const newOption: KeyDisplayValue = {
      displayValue: '',
      key: '',
    };
    addNewStaticOption(newOption);
  }, [addNewStaticOption]);

  const handleNewDynamicItemClick = useCallback(
    (item: Integration) => {
      const newRequestOptionProducer: Partial<ActionOptionProducer> = {
        type: 'action',
        integrationId: item.id,
        versionNumber: item.latestVersion.number,
        optionTemplatePair: {
          displayValueTemplate: '',
          identifierTemplate: '',
        },
      };
      addNewOptionProducer(newRequestOptionProducer);
    },
    [addNewOptionProducer]
  );
  return (
    <>
      <OnChange name={'uiComponent.dataSource.type'}>
        {() => {
          onDataSourceIndexChanged(null);
        }}
      </OnChange>
      <DataSourceTypeSection schema={schema} />
      <PropertyPanelListHeader
        title={dataSourceFormValue.type === 'dynamic' ? 'Source mapping' : 'Options'}
        tooltip={
          dataSourceFormValue.type === 'dynamic'
            ? 'Select an action to map its response as the source of this input field.'
            : 'The options listed below will appear as selectable items for users to choose from.'
        }
        actions={
          dataSourceFormValue.type === 'dynamic' ? (
            <>
              <NvButton onlyIcon size="small" color="ghost" {...bindTrigger(popupState)}>
                <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
              </NvButton>
              <NvPopover {...bindPopover(popupState)}>
                <IntegrationSelect
                  onSelectIntegration={(integration) => {
                    handleNewDynamicItemClick(integration);
                    popupState.close();
                  }}
                />
              </NvPopover>
            </>
          ) : (
            <NvButton onlyIcon onClick={handleNewStaticItemClick} size="small" color="ghost">
              <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
            </NvButton>
          )
        }
      />
      {dataSourceFormValue.type === DataSourceType.DYNAMIC ? (
        <FormTriggerDynamicDataSourceMapper
          selectedIndex={dataSourceIndex}
          setSelectedIndex={(index) => {
            onDataSourceIndexChanged(index);
          }}
          optionProducers={optionProducers}
        />
      ) : (
        <StaticDataSourceMapper />
      )}
    </>
  );
};
