import { NvField, NvFlex, NvTagsAutoComplete, NvTextArea, NvTextField, isMaxLength, isRequired } from '@novaera/core';
import { LogoPlaceholder } from '../../../../components';
import { NvLogoUpload } from '../../../../components/logo-upload';

export const GroupsCreateUpdateModalBody = ({ logoUrl }: { logoUrl?: string }) => {
  return (
    <NvFlex gap="16px">
      <NvField
        name="logo"
        labelText="Group icon"
        direction="label-on-top"
        component={({ onChange }) => {
          return (
            <NvLogoUpload
              ImageFallBack={
                <LogoPlaceholder size="xlarge" src="assets/group_fallback_img.png" initialText="Group logo" />
              }
              type="group"
              imageShape="square"
              imageSize="xlarge"
              onLogoStateChanged={({ logo }) => {
                onChange(logo);
              }}
              logoUploadButtonProps={{
                text: 'Upload image',
              }}
              initialLogoUrl={logoUrl}
              logoUploadHintProps={{
                text: 'Recommended size: 120px square',
              }}
            />
          );
        }}
      />
      <NvField
        name="name"
        labelText="Name"
        labelVariant="h5"
        direction="label-on-top"
        validators={[isRequired(), isMaxLength({ length: 100 })]}
        showErrorMessageOnlyWhenBlur
        hasRequiredIndicator
        component={<NvTextField />}
      />
      <NvField
        name="description"
        labelText="Description"
        labelVariant="h5"
        direction="label-on-top"
        validators={[isMaxLength({ length: 3000 })]}
        component={<NvTextArea />}
      />
      <NvField
        direction="label-on-top"
        name="tags"
        labelText="Tags"
        labelVariant="h5"
        showErrorMessageOnlyWhenBlur
        defaultValue={[]}
        isAutoComplete
        component={<NvTagsAutoComplete placeholder={'Add tags'} />}
      />
    </NvFlex>
  );
};
