import classNames from 'classnames';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/addon/scroll/simplescrollbars.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';

import { helperFunctions } from '@novaera/chart-data-engine';

import React, { Suspense, lazy } from 'react';
import { NvConditionalWrap } from '../../conditional-wrap';
import '../autocomplete';
import { CodeInputExpander } from '../code-input-expander';
import { NvCodeMirrorWrapper } from '../styled';
import { CodeInputProps } from '../types';
import { shouldRenderCodeInput } from '../utils';
import { useMonacoEditorWidgets } from '../widgets';
import { convertObjectToTypeDefinition } from './convert-object-to-definition';
import { JavascriptCodeInputProps } from './types';

const Editor = lazy(() => import('@monaco-editor/react'));

const UnMemoizedCodeInput = ({
  context,
  value,
  onChange,
  lineNumbers,
  className,
  isExpanded,
  mode,
  hasBorder,
}: JavascriptCodeInputProps) => {
  const { onEditorMount } = useMonacoEditorWidgets();
  return (
    <Suspense>
      <NvCodeMirrorWrapper
        className={classNames({
          [`${className}`]: !!className,
          'expanded-mode': isExpanded,
          'has-border': hasBorder,
        })}
      >
        <Editor
          key={JSON.stringify(context)}
          height={'100%'}
          width={'100%'}
          language={mode === 'json' ? 'json' : 'javascript'}
          theme="nvMonacoTheme"
          onChange={(value, event) => {
            onChange?.(value ?? '');
          }}
          options={{
            // please do not add any new option here unless you are sure that is working in production.
            // especially automaticLayout, fixedOverflowWidgets, overflowWidgetsDomNode options created RangeError: Maximum call stack size exceeded problem
            // only in production which very very hard to debug.
            scrollBeyondLastLine: false,
            minimap: { enabled: false },
            wordWrap: 'on',
            wrappingStrategy: 'advanced',
            scrollbar: { verticalScrollbarSize: 8 },
            fontFamily: 'Menlo',
            fontSize: 12,
            tabSize: 2,
            padding: { top: 16, bottom: 16 },
            lineNumbers: lineNumbers || isExpanded ? 'on' : 'off',
            bracketPairColorization: {
              enabled: true,
            },
          }}
          onMount={onEditorMount}
          beforeMount={function handleEditorWillMount(monaco) {
            const libSource = `declare module context {
            function put(key: string, theValue: any): void;
            function get(key: string): void;
          }
          ${convertObjectToTypeDefinition({
            context: { ...context, ...helperFunctions },
          })}`;

            const libUri = 'js:filename/actioner-libs.d.ts';
            monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
              target: monaco.languages.typescript.ScriptTarget.ES2015,
              lib: ['es2015'],
              allowNonTsExtensions: true,
            });
            monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri);

            monaco.editor.defineTheme('nvMonacoTheme', {
              base: 'vs',
              inherit: true,
              rules: [
                {
                  token: 'keyword.js',
                  foreground: '#A74047',
                },
                {
                  token: 'identifier.js',
                  foreground: '#005CC5',
                },
                {
                  token: 'delimiter.js',
                  foreground: '#A74047',
                },
                {
                  token: 'delimiter.parenthesis.js',
                  foreground: '#24292E',
                },
                {
                  token: 'delimiter.bracket.js',
                  foreground: '#032F62',
                },
                {
                  token: 'comment.js',
                  foreground: '#6A737D',
                },
                { token: 'string.js', foreground: '#032F62' },
                {
                  token: 'string.escape.js',
                  foreground: '#22863A',
                },
                {
                  token: 'string.invalid.js',
                  foreground: '#FF8C73',
                },
                { token: 'number.js', foreground: '#005CC5' },
                { token: 'number.float.js', foreground: '#005CC5' },
                {
                  token: 'number.octal.js',
                  foreground: '#005CC5',
                },
                {
                  token: 'number.hex.js',
                  foreground: '#005CC5',
                },
                {
                  token: 'number.binary.js',
                  foreground: '#005CC5',
                },
                {
                  token: 'comment.doc.js',
                  foreground: '#005CC5',
                },
                { token: 'type.identifier.js', foreground: '#032F62' },
                { token: 'regexp.js', foreground: '#032F62' },
                {
                  token: 'keyword.other.js',
                  foreground: '#005CC5',
                },
                {
                  token: 'delimiter.square.js',
                  foreground: '#586069',
                },
              ],
              colors: {
                'editorBracketHighlight.foreground1': '#24292E',
                'editorBracketHighlight.foreground2': '#24292E',
                'editorBracketHighlight.foreground3': '#24292E',
                'editorBracketHighlight.foreground4': '#24292E',
                'editorHoverWidget.statusBarBackground': '#f7f8f9',
                'editor.foldBackground': '#fbfbfc',
                'editorHoverWidget.foreground': '#455068',
                'editorHoverWidget.background': '#ffffff',
                'editor.wordHighlightStrongBackground': '#0D1B3B09',
                'editor.wordHighlightTextBackground': '#0D1B3B09',
                'editor.foreground': '#0D1B3B',
                'editor.background': '#ffffff',
                'editorCursor.foreground': '#547ED4',
                'editor.lineHighlightBackground': '#F9FBFF',
                'editorLineNumber.foreground': '#C0C4CC',
                'editor.selectionBackground': '#B5D5FB',
                'editor.inactiveSelectionBackground': '#E6EBF0',
                'editor.selectionHighlightBackground': '#E9F2FD',
                'editor.inactiveSelectionHighlightBackground': '#FBFBFC',
                'textCodeBlock.background': '#b3d90c',
                'editorLineNumber.background': '#FF3CBD',
                'editorRuler.foreground': '#FF3CBD',
                'editorBracketMatch.background': '#BFEFD5',
                'editorBracketMatch.border': '#BFEFD5',
                'editorGutter.background': '#FFF',
                'editorOverviewRuler.border': '#fff',
                'peekViewEditor.background': '#FF3CBD',
                'editorInlayHint.foreground': '#FF3CBD',
                'editorInlayHint.background': '#FF3CBD',
                'scrollbarSlider.background': '#C0C4CC',
                'scrollbarSlider.hoverBackground': '#9EA4B1',
                'scrollbarSlider.activeBackground': '#868D9C',
                'scrollbar.shadow': '#fff',
                'dropdown.background': '#fff',
                'dropdown.foreground': '#0D1B3B',
                'dropdown.border': '#FF3CBD',
                'editorSuggestWidget.border': '#DDDFE3',
                'editorSuggestWidget.foreground': '#0D1B3B',
                'editorSuggestWidget.selectedBackground': '#455068',
                'editorSuggestWidget.highlightForeground': '#547ED4',
                'editorSuggestWidget.background': '#fff',
              },
            });

            monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);

            monaco.editor.setTheme('nvMonacoTheme');
          }}
          value={value}
        />
      </NvCodeMirrorWrapper>
    </Suspense>
  );
};

const CodeInputForJavascriptWithExpander = ({
  disableExpandMode,
  ...props
}: CodeInputProps & { disableAutoCompletion?: boolean }) => (
  <NvConditionalWrap
    condition={!disableExpandMode}
    wrap={(children) => <CodeInputExpander>{children}</CodeInputExpander>}
  >
    <UnMemoizedCodeInput {...props} />
  </NvConditionalWrap>
);

CodeInputForJavascriptWithExpander.displayName = 'Code input for javascript';

export const CodeInputForJavascript = React.memo(CodeInputForJavascriptWithExpander, (prevProps, nextProps) =>
  shouldRenderCodeInput(prevProps, nextProps)
);
CodeInputForJavascript.displayName = 'MemoizedCodeInputForJavascript';
