import { ClickAwayListener } from '@mui/material';
import { NvConditionalWrap, NvRouterLink, NvSkeleton, Portal } from '@novaera/core';
import { useLocation } from '@novaera/route';
import { assert } from '@novaera/utils';
import classNames from 'classnames';
import React, { ReactElement, useMemo, useState } from 'react';
import { PORTAL_ID_FOR_NAVIGATION_POPOVER } from '../constants';
import { NavigationContextAction, useNavigationContext } from '../nav-context';
import {
  ActiveMenuIndicator,
  MenuDropdown,
  MenuIconWrapper,
  MenuIndicatorWrapper,
  MenuItemWrapper,
  MenuLabelWrapper,
  StyledTypography,
} from './styled';
import { MenuItemProps } from './types';

export const MenuItem = ({
  icon,
  labelProps,
  onClick,
  indicator,
  MenuItemWrapperComponent = MenuItemWrapper,
  PopupMenuDropdown = MenuDropdown,
  isLoading,
  id,
  forceNeverActive,
  ...restOfProps
}: MenuItemProps) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { state, dispatch } = useNavigationContext();

  const open = useMemo(() => Boolean(anchorEl) && state.openedPopoverId === id, [anchorEl, id, state.openedPopoverId]);

  const isMenuActive = useMemo(() => {
    if (forceNeverActive) {
      return false;
    }
    /** for sub menu items and pop up menu items, do not show active classes */
    if (restOfProps.type === 'popup') {
      return false;
    } else if (Array.isArray(restOfProps.link)) {
      return restOfProps.link.some((l) => location.pathname.startsWith(l));
    } else if (restOfProps.link) {
      // for any link that may have path params /action/actionId/.../
      return location.pathname.startsWith(restOfProps.link);
    } else {
      return false;
    }
  }, [forceNeverActive, location.pathname, restOfProps]);

  const defaultLink = useMemo(() => {
    if (restOfProps.type === 'link') {
      if (Array.isArray(restOfProps.link)) {
        assert(
          restOfProps.link.length > 0,
          new Error('When link is a string array it should have length more than zero'),
          'ERROR'
        );
        return restOfProps.link[0];
      } else {
        return restOfProps.link;
      }
    }
    return undefined;
  }, [restOfProps]);

  const showActiveMenuIndicator = useMemo(() => restOfProps.type === 'popup', [restOfProps]);

  const handleMenuDropdownClose = () => {
    setAnchorEl(null);
    dispatch({
      type: NavigationContextAction.RemoveOpenedPopover,
      payload: {
        popoverId: '',
      },
    });
  };

  if (isLoading) {
    return (
      <MenuItemWrapperComponent
        key={`menu_item_loading_${labelProps?.type === 'typography' ? labelProps.labelText : labelProps?.id}`}
      >
        <MenuIconWrapper>
          <NvSkeleton variant="circular" width="40px" height="40px" />
        </MenuIconWrapper>
        <MenuLabelWrapper className="hidden-item">
          <NvSkeleton width="100%" height="32px" />
        </MenuLabelWrapper>
      </MenuItemWrapperComponent>
    );
  }

  return (
    <>
      <NvConditionalWrap
        condition={Boolean(defaultLink)}
        wrap={(children) => <NvRouterLink to={defaultLink ?? ''}>{children}</NvRouterLink>}
      >
        <MenuItemWrapperComponent
          key={`menu_item_${id}`}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            onClick?.();

            if (restOfProps.type === 'link' && restOfProps.link) {
              dispatch({
                type: NavigationContextAction.RemoveOpenedPopover,
                payload: {
                  popoverId: '',
                },
              });
            }

            if (restOfProps.type === 'popup' && restOfProps.PopupMenuComponent) {
              // at first click, if anchorEl not set, open menu,
              // at second click, if anchorEl is set, close menu
              if (state.openedPopoverId !== id) {
                setAnchorEl(event.currentTarget);
                dispatch({
                  type: NavigationContextAction.SetOpenedPopoverId,
                  payload: {
                    popoverId: id,
                  },
                });
              } else {
                handleMenuDropdownClose();
              }
            }
          }}
          className={classNames({
            active: isMenuActive,
            'is-popup-menu-open': open,
          })}
        >
          <MenuIconWrapper>
            {React.isValidElement(icon) &&
              React.cloneElement(icon, {
                ...icon.props,
                className: 'menu-item-icon',
              })}
          </MenuIconWrapper>
          {labelProps?.type === 'typography' ? (
            <MenuLabelWrapper>
              <StyledTypography
                noWrap
                {...labelProps.labelTypographyProps}
                variant={labelProps?.labelTypographyProps?.variant ?? 'h4'}
                className="hidden-item menu-item-label"
              >
                {labelProps.labelText}
              </StyledTypography>
            </MenuLabelWrapper>
          ) : (
            labelProps?.labelComponent
          )}
          {indicator && <MenuIndicatorWrapper className="hidden-item ">{indicator}</MenuIndicatorWrapper>}
          {showActiveMenuIndicator && <ActiveMenuIndicator className="active-indicator" />}
        </MenuItemWrapperComponent>
      </NvConditionalWrap>

      {open && restOfProps.type === 'popup' && (
        <Portal node={document && document.getElementById(PORTAL_ID_FOR_NAVIGATION_POPOVER)}>
          <ClickAwayListener onClickAway={handleMenuDropdownClose}>
            <PopupMenuDropdown
              id={labelProps?.type === 'typography' ? labelProps.labelText : labelProps?.id}
              disablePortal
              open={open}
              anchorEl={anchorEl}
              onClose={handleMenuDropdownClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {React.isValidElement(restOfProps.PopupMenuComponent) &&
                React.cloneElement(restOfProps.PopupMenuComponent as ReactElement, {
                  key: `popup_menu_component_${restOfProps.PopupMenuComponent.key}`,
                  onMenuItemClicked: (link?: string) => {
                    handleMenuDropdownClose();
                  },
                })}
            </PopupMenuDropdown>
          </ClickAwayListener>
        </Portal>
      )}
    </>
  );
};
