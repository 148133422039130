import {
  useBatchCreateCatalogRelationship,
  useBatchDeleteCatalogRelationship,
  useSearchCatalogRelationship,
  useUpdateBatchCatalogRelationship,
  useUpdateCatalogEntityType,
} from '@novaera/actioner-service';
import { NvCustomCatalogIcon, NvDialogModal, NvFlex, NvForm } from '@novaera/core';
import { assert } from '@novaera/utils';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';

import { DynamicMaterialIcons } from '../../components/dynamic-material-icons';
import { InPageSideMenu } from '../../components/in-page-side-menu';
import { CatalogBody } from '../body';
import { useCatalogPermission } from '../controllers/use-catalog-permission';
import { useCatalogSideMenuController } from '../controllers/use-catalog-side-menu';
import { useEntityTypeController } from '../controllers/use-entity-type';
import { useSelectedCatalogEntity } from '../controllers/use-selected-catalog-entity';
import { EntityTypeModalBody } from '../entity-type-modal-body';
import { CatalogFormType, EntityTypeFormTypes, SideMenuItemType } from '../types';

export const CatalogWithoutProvider = () => {
  const { items, onItemSelected, onSearchKeywordChanged, isCatalogEntitiesLoading } = useCatalogSideMenuController();

  const { hasCatalogEditPermission } = useCatalogPermission();

  const { selectedEntityType } = useSelectedCatalogEntity();

  const { onFormSubmit, onAddClicked, isCreateModalOpen, onCreateModalClosed } = useEntityTypeController();

  const { data: catalogRelationships } = useSearchCatalogRelationship({
    firstEntityTypeId: selectedEntityType?.id,
  });

  const { mutate: onUpdateEntityType } = useUpdateCatalogEntityType();
  const { mutate: batchUpdateRelationships } = useUpdateBatchCatalogRelationship();
  const { mutate: batchCreateRelationships } = useBatchCreateCatalogRelationship();
  const { mutate: batchDeleteRelationships } = useBatchDeleteCatalogRelationship();

  return (
    <NvForm<CatalogFormType>
      initialValues={{
        relationshipDefinitions: catalogRelationships?.relationshipDefinitions ?? [],
        selectedEntityType,
      }}
      keepDirtyOnReinitialize
      onSubmit={
        hasCatalogEditPermission
          ? ({ selectedEntityType, relationshipDefinitions }, form) => {
              assert(!!selectedEntityType, new Error(`There should an entity type selected`), 'ERROR');

              onUpdateEntityType({
                id: selectedEntityType.id,
                payload: {
                  entityType: selectedEntityType,
                },
              });

              const relationshipsToUpdate = relationshipDefinitions?.filter((item) => item.id && !item.managed) ?? [];
              const relationshipsToCreate = relationshipDefinitions?.filter((item) => !item.id) ?? [];
              const relationshipsToDelete =
                catalogRelationships?.relationshipDefinitions.filter(
                  (item) => !relationshipDefinitions?.find((i) => i.id === item.id)
                ) ?? [];

              if (relationshipsToDelete && relationshipsToDelete?.length > 0) {
                batchDeleteRelationships({
                  entityTypeId: selectedEntityType.id,
                  payload: {
                    ids: relationshipsToDelete.map((item) => item.id),
                  },
                });
              }

              if (relationshipsToUpdate.length > 0) {
                batchUpdateRelationships({
                  payload: {
                    relationshipDefinitions: relationshipsToUpdate,
                  },
                  entityTypeId: selectedEntityType.id,
                });
              }

              if (relationshipsToCreate.length > 0) {
                batchCreateRelationships({
                  payload: {
                    relationshipDefinitions: relationshipsToCreate,
                    entityTypeId: selectedEntityType.id,
                  },
                });
              }

              form.setConfig('keepDirtyOnReinitialize', false);
              form.reset({ selectedEntityType, relationshipDefinitions });
              form.setConfig('keepDirtyOnReinitialize', true);
            }
          : noop
      }
      key={`catalog_${selectedEntityType?.id}`}
      mutators={{ ...arrayMutators }}
    >
      {({ dirty }) => {
        return (
          <>
            <NvFlex flex="1 1 auto" minWidth="auto" height="100%">
              <NvFlex flexDirection={'row'} height={'100%'}>
                <NvFlex width={'279px'} flex={'0 0 auto'} zIndex={'11'}>
                  <InPageSideMenu<SideMenuItemType>
                    icon={NvCustomCatalogIcon}
                    title={'Catalog'}
                    onSearchKeywordChanged={onSearchKeywordChanged}
                    onAddClicked={onAddClicked}
                    onItemSelected={(item) => {
                      onItemSelected(item, dirty);
                    }}
                    items={items}
                    isLoading={isCatalogEntitiesLoading}
                    selectedItem={selectedEntityType}
                    renderCustomLogo={(item) => (
                      <DynamicMaterialIcons initialValue={item.iconId}>
                        {({ getCurrentIcon }) => {
                          const Icon = getCurrentIcon();
                          return Icon && <Icon />;
                        }}
                      </DynamicMaterialIcons>
                    )}
                    hasPermissionToAdd={hasCatalogEditPermission}
                  />
                </NvFlex>
                <NvFlex flex={'1 1 auto'} minWidth={'0'} height={'100%'}>
                  {selectedEntityType && <CatalogBody />}
                </NvFlex>
              </NvFlex>
            </NvFlex>
            {hasCatalogEditPermission && (
              <NvDialogModal<EntityTypeFormTypes>
                Header="Create catalog"
                maxWidth="sm"
                fullWidth
                Body={<EntityTypeModalBody mode="create" />}
                onCloseButtonClicked={onCreateModalClosed}
                open={isCreateModalOpen}
                primaryButtonText="Create"
                onFormSubmit={onFormSubmit}
              />
            )}
          </>
        );
      }}
    </NvForm>
  );
};
