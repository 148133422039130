import { assert } from '@novaera/utils';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { usePPDrawerItem } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/controllers/use-pp-drawer-item';

export type SlackBlocksContextType = {
  selectedElementId?: string;
  setSelectedElementId: Dispatch<SetStateAction<string | undefined>>;
  panelProps: ReturnType<typeof usePPDrawerItem>['panelProps'][0];
  panelCloseFn: ReturnType<typeof usePPDrawerItem>['panelCloseFunctions'][0];
  setPanelOpen: Dispatch<SetStateAction<boolean>>;
};

const SlackBlocksContext = createContext<SlackBlocksContextType | undefined>(undefined);

export const SlackBlocksProvider: FC<{
  children: ((params: SlackBlocksContextType) => ReactNode) | ReactNode;
  panelProps: ReturnType<typeof usePPDrawerItem>['panelProps'][0];
  panelCloseFn: ReturnType<typeof usePPDrawerItem>['panelCloseFunctions'][0];
  setPanelOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ children, panelCloseFn, panelProps, setPanelOpen }) => {
  const [selectedElementId, setSelectedElementId] = useState<string | undefined>();

  const value = {
    selectedElementId,
    setSelectedElementId,
    panelProps,
    panelCloseFn,
    setPanelOpen,
  };

  return (
    <SlackBlocksContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </SlackBlocksContext.Provider>
  );
};

export const useSlackBlocksContext = () => {
  const context = useContext(SlackBlocksContext);
  assert(!!context, new Error(`useSlackBlocksContext should be used within SlackBlocksProvider`), 'ERROR');

  return context;
};
