import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_USERS_IN_WORKSPACE } from '../use-get-users-in-workspace/keys';

import { UpdateUserRoleParams, UpdateUserRoleResponse } from './types';

const updateUserRole = async (params: UpdateUserRoleParams) => {
  const result = await NvAxios.post<UpdateUserRoleResponse>('/v1/workspaces/users/roles', params);
  return result?.data;
};

export const useUpdateUserRole = () => {
  const cache = useQueryClient();
  return useMutation(updateUserRole, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS_USERS_IN_WORKSPACE.all);
    },
  });
};
