import Autocomplete from '@mui/material/Autocomplete';
import { assert } from '@novaera/utils';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import { NvBox } from '../box';
import { NvChip } from '../chip';
import { NvDivider } from '../divider';
import { NvFlex } from '../flex';
import { NvExpandMoreIcon } from '../icons';
import { NvTypography } from '../typography';
import { AutocompleteWrapper, DefaultPaper, DefaultRenderOption, Tags } from './styled';
import { NvAutocompleteProps } from './types';

export function NvAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel = (option: any): string => option.label ?? option,
  formatGroupLabel = (value: string) => value,
  renderTags = (tags, getTagProps) => (
    <>
      {tags.map((option, index) => {
        const optionLabel = (getOptionLabel ? getOptionLabel(option) : option) as string;
        return (
          <Tags key={`${optionLabel}-${index}`}>
            <NvChip {...getTagProps({ index })} label={optionLabel} />
          </Tags>
        );
      })}
    </>
  ),
  renderOption = (props, option) => {
    const optionLabel = getOptionLabel ? getOptionLabel(option) : option;
    assert(typeof optionLabel === 'string', new Error('optionLabel should be string'), 'ERROR');
    return (
      <DefaultRenderOption {...props} key={props.id}>
        <NvTypography variant="body1" noWrap>
          {optionLabel}
        </NvTypography>
      </DefaultRenderOption>
    );
  },
  renderGroup = ({ key, group, children }) => {
    const groupLabel = formatGroupLabel(group);
    return (
      <React.Fragment key={`${key}-${groupLabel}`}>
        {Number(key) > 0 && (
          <NvBox padding="12px 6px" width="100%">
            <NvDivider />
          </NvBox>
        )}
        <NvFlex direction="row" padding="4px 6px">
          <NvTypography variant="h7" textColor="subtle">
            {groupLabel}
          </NvTypography>
        </NvFlex>
        {children}
      </React.Fragment>
    );
  },
  PaperComponent = (props) => <DefaultPaper {...props} />,
  popupIcon = <NvExpandMoreIcon />,
  onComponentRendered,
  compact,
  ...otherProps
}: NvAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      onComponentRendered?.();
    }
  }, [onComponentRendered, isFirstRender]);

  return (
    <AutocompleteWrapper>
      <Autocomplete
        className={classNames('nv-autocomplete', {
          compact: compact,
        })}
        {...(getOptionLabel ? { getOptionLabel: getOptionLabel } : {})}
        renderTags={renderTags}
        renderOption={renderOption}
        renderGroup={renderGroup}
        PaperComponent={PaperComponent}
        popupIcon={popupIcon}
        {...otherProps}
      />
    </AutocompleteWrapper>
  );
}

export type { AutocompleteRenderInputParams as NvAutocompleteRenderInputParams } from '@mui/material/Autocomplete';
