import { NvBasicTable, NvConditionalRender } from '@novaera/core';

import { useAuthenticationFields } from './controllers/use-authentication-items';
import { FieldItemTableBody } from './table-body';
import { FieldItemTableHeader } from './table-header';

export const AuthenticationFieldItems = () => {
  const { hasAuthenticationFields } = useAuthenticationFields();

  return (
    <NvConditionalRender when={() => hasAuthenticationFields}>
      <NvBasicTable tableHeader={<FieldItemTableHeader />} tableBody={<FieldItemTableBody />} />
    </NvConditionalRender>
  );
};
