import { USER_PERMISSION } from '@novaera/actioner-service';
import { NvForm } from '@novaera/core';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import { useUserPermissions } from '../../../user-permission-boundary/use-user-permission';
import { useActionDesignerFormApiContext } from '../../providers/form-api-provider';
import { useFunctionsTab } from './controllers/use-functions-tab';
import { Functions } from './functions';
import { FunctionsFormValues } from './types';

export const FunctionsTab = () => {
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const { data, handleOnChange } = useFunctionsTab();
  const { functionsFormApiRef } = useActionDesignerFormApiContext();
  return (
    <NvForm<FunctionsFormValues>
      myRef={functionsFormApiRef}
      onSubmit={noop}
      initialValues={{ functions: data?.functions }}
      mutators={{ ...arrayMutators }}
      onChange={
        hasIntegrationUpdateRight
          ? ({ values }) => {
              handleOnChange(values);
            }
          : noop
      }
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <Functions />
    </NvForm>
  );
};
