import { USER_PERMISSION } from '@novaera/actioner-service';
import { useFormState } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useMemo, useState } from 'react';
import { useUserPermissions } from '../../../../../../user-permission-boundary/use-user-permission';
import { useRequestContext } from '../../../../../providers/request-provider';
import { RequestsFormValues } from '../../../types';
import { OpenModalParams } from '../../list/types';
import { RequestModalParams } from '../../request-modal/type';

export const useRequests = () => {
  const {
    values: { httpRequestConfigurations },
  } = useFormState<RequestsFormValues>();
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const showList = useMemo(() => Boolean(httpRequestConfigurations?.length), [httpRequestConfigurations]);
  const [modalMode, setModalMode] = useState<'create' | 'update'>('create');

  const { selectedRequestId, setSelectedRequestId } = useRequestContext();

  const handleOpenModal = (params: OpenModalParams) => {
    if (params.mode === 'update') {
      setSelectedRequestId(params.id);
    }
    setModalMode(params.mode);
    setIsOpen(true);
  };

  const requestModalParams = useMemo(() => {
    if (modalMode === 'create') {
      const modalParams: RequestModalParams = { mode: 'create', onClose: () => setIsOpen(false), isOpened: isOpen };
      return modalParams;
    } else {
      assert(
        !!selectedRequestId,
        new Error('When we try to update a request the user should select it first.'),
        'ERROR'
      );

      const modalParams: RequestModalParams = {
        mode: 'update',
        onClose: () => setIsOpen(false),
        requestId: selectedRequestId,
        isOpened: isOpen,
      };
      return modalParams;
    }
  }, [isOpen, modalMode, selectedRequestId]);
  return {
    showList,
    selectedRequestId,
    onOpenModal: handleOpenModal,
    requestModalParams,
    hasIntegrationUpdateRight,
  };
};
