import {
  ExternalAppConnectionType,
  WorkflowWithState,
  useGetWorkflow,
  useSaveToDraftWorkflow,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../controllers/use-get-workflow-query-params';

export const useEmptyDefaultPrinciple = () => {
  const { mutate: saveToDraft, isLoading: isSaveLoading } = useSaveToDraftWorkflow();
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const emptyDefaultPrinciple = useCallback(() => {
    const trigger = workflow?.trigger;
    if (trigger) {
      const { defaultStarterUserId, ...restOfTrigger } = trigger;
      assert(!!defaultStarterUserId, new Error('When default principle emptied it should already be filled'), 'ERROR');
      assert(
        ['form', 'integrationApp'].includes(trigger.type),
        new Error('Only form and integration app trigger types are supporting emptying the default starter id'),
        'ERROR'
      );

      const newWorkflow: WorkflowWithState = {
        ...workflow,
        trigger:
          restOfTrigger.type === 'form'
            ? { ...restOfTrigger, useDefaultUserAsPrimeUser: false }
            : restOfTrigger.type === 'integrationApp'
            ? { defaultStarterUserId, ...restOfTrigger, connectionType: ExternalAppConnectionType.NONE }
            : restOfTrigger,
      };
      saveToDraft(newWorkflow);
    }
  }, [saveToDraft, workflow]);

  return { emptyDefaultPrinciple, isSaveLoading };
};
