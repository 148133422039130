import { StringValue } from '@novaera/actioner-service';
import { NvConditionalRender, NvDivider, NvFlex, NvTextField, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FilterLayout } from '../filter-layout';
import { FilterLayoutProps } from '../filter-layout/types';

export const TagItemFilter = ({
  onApplyClicked,
  onRemoveClicked,
  mode,
  value,
}: Pick<FilterLayoutProps<string>, 'onApplyClicked' | 'onRemoveClicked' | 'mode'> & {
  value?: StringValue;
}) => {
  const theme = useTheme();
  return (
    <FilterLayout<string> onApplyClicked={onApplyClicked} onRemoveClicked={onRemoveClicked} mode={mode}>
      {({ onValueChanged }) => {
        return (
          <>
            <NvConditionalRender when={mode === 'edit'}>
              <NvTextField
                onChange={(event) => {
                  onValueChanged(event.target.value);
                }}
                placeholder="Write a document tag"
                size="small"
              />
            </NvConditionalRender>
            <NvConditionalRender when={mode === 'display'}>
              <NvFlex flexDirection={'row'} gap={'8px'} alignItems={'center'}>
                <NvTypography textColor="subtle" variant="h7">
                  TAG
                </NvTypography>
                <NvDivider
                  orientation="vertical"
                  sx={{
                    height: '12px',
                    color: theme.palette.nv_neutral[30],
                  }}
                />
                <NvTypography variant="h5">{value?.displayValue}</NvTypography>
              </NvFlex>
            </NvConditionalRender>
          </>
        );
      }}
    </FilterLayout>
  );
};
