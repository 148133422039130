import { assert } from '@novaera/utils';

import { NvConditionalWrap, NvTooltip } from '@novaera/core';
import { FC, useMemo } from 'react';
import { useWorkflowPermission } from '../../../../../../user-app-permission-boundary/use-workflow-permission';
import { foreignObjectSize } from '../../constants';
import { getSmoothPath } from '../../utils/get-smooth-path';
import { AddNewButtonForeignObject } from '../add-new-button-foreign-object';
import { LabelForeignObject } from '../label-foreign-object';
import { BaseEdgeProps } from './types';

export const BaseEdge: FC<React.PropsWithChildren<BaseEdgeProps>> = ({
  source: sourceId,
  target: targetId,
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  markerStart,
  borderRadius,
  topDownOffset,
  leftRightOffset,
  centerX,
  centerY,
  data,
  buttonBehavior,
}) => {
  const { hasEditPermission } = useWorkflowPermission();

  const { points, centerPosition } = getSmoothPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius,
    topDownOffset,
    leftRightOffset,
    centerPosition: data?.centerPosition,
    centerX,
    centerY,
    tailBehavior: data?.tailBehavior,
  });

  const buttonPosition: { isButtonShown: false } | { isButtonShown: true; positionX: number; positionY: number } =
    useMemo(() => {
      if (buttonBehavior === 'notShown') {
        return { isButtonShown: false };
      } else if (buttonBehavior === 'center') {
        return {
          isButtonShown: true,
          positionX: centerPosition.x - foreignObjectSize / 2,
          positionY: centerPosition.y - foreignObjectSize / 2,
        };
      } else if (buttonBehavior === 'end') {
        return {
          isButtonShown: true,
          positionX: targetX - foreignObjectSize / 2,
          positionY: targetY - 18 - foreignObjectSize / 2,
        };
      } else if (buttonBehavior === 'start') {
        return {
          isButtonShown: true,
          positionX: sourceX - foreignObjectSize / 2,
          positionY: sourceY + 18 - foreignObjectSize / 2,
        };
      }
      assert(false, new Error('Unknown button behavior'), 'ERROR');
    }, [buttonBehavior, centerPosition.x, centerPosition.y, sourceX, sourceY, targetX, targetY]);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={points}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      {data?.name && (
        <LabelForeignObject
          labelText={data.name}
          positionX={targetX - foreignObjectSize / 2}
          positionY={sourceY + 24 - foreignObjectSize / 2}
        />
      )}
      {buttonPosition.isButtonShown && (
        <NvConditionalWrap
          condition={!hasEditPermission}
          wrap={(children) => {
            return <NvTooltip title="You are not allowed to add new nodes.">{children}</NvTooltip>;
          }}
        >
          <AddNewButtonForeignObject
            positionX={buttonPosition.positionX}
            positionY={buttonPosition.positionY}
            sourceId={sourceId}
            targetId={targetId}
            isDisabled={!hasEditPermission}
          />
        </NvConditionalWrap>
      )}
    </>
  );
};
