import { CodeInput } from '@novaera/core';
import { ComponentHint } from '../common/adapter/component-hint';
import { CodeInputComponentParams } from '../use-ui-component/types';

export const CodeInputComponent = ({
  inputParameter: { uiComponent },
  inputProps,
  context = {},
}: CodeInputComponentParams) => {
  const { hint } = uiComponent;

  return (
    <>
      <CodeInput
        context={context}
        {...inputProps}
        value={typeof inputProps.value === 'string' ? inputProps.value : ''}
        growingHeight
        maxHeight="300px"
      />
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
