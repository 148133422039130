import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Alternative, ThemeMode.Light);

export default {
  primary: {
    main: '#d32f2f',
  },
  secondary: {
    main: '#DD8822',
  },
  success: {
    main: '#71aa74',
  },
  grey: {
    '50': '#BB9329',
  },
  test: {
    value: 'testAlternativeLight',
  },
  ...colors,
};
