import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvCustomConditionIcon,
  NvDeleteOutlineIcon,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';

import { css } from '@emotion/css';
import classNames from 'classnames';
import { noop } from 'lodash';
import { NodeType, PropertyPanelHeader } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { WorkflowConditionFormType } from '../../../../components/condition-rule/types';
import { Conditions } from '../../../../components/conditions';
import { CONDITION_FIELD_NAME, DYNAMIC_VALUE_TYPE_OPTIONS } from './constants';
import { useConditionPropertiesController } from './controllers/use-condition-properties';
import { WorkflowConditionPropertiesProps } from './types';

const FormClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 0px;
`;

export const WorkflowConditionProperties = ({ conditionNode, onCloseClicked }: WorkflowConditionPropertiesProps) => {
  const theme = useTheme();
  const { onSave, deleteNodeAndUpdateGraph, context } = useConditionPropertiesController();
  const { hasEditPermission } = useWorkflowPermission();
  return (
    <NvForm
      className={classNames(FormClass)}
      initialValues={{
        conditionNode,
      }}
      onSubmit={noop}
      {...(hasEditPermission && {
        onChange: ({ values }) => {
          onSave(conditionNode, values as WorkflowConditionFormType);
        },
      })}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvCustomConditionIcon />}
          title={conditionNode.name}
          type={NodeType.CONDITION}
          onTitleChange={async (title) => {
            if (title) {
              onSave(conditionNode, { conditionNode: { ...conditionNode, name: title } }, true);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: conditionNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvCustomConditionIcon />}
          title={conditionNode.name}
          type={NodeType.CONDITION}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <Conditions
        context={context}
        conditionFieldName={CONDITION_FIELD_NAME}
        valueTypeOptions={DYNAMIC_VALUE_TYPE_OPTIONS}
      />
    </NvForm>
  );
};
