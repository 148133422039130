import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkspaceCard = styled(NvFlex)`
  flex-direction: row;
  padding: 8px;
  gap: 8px;
  width: 380px;
  border-radius: 12px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
`;

export const WorkspaceListWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;
