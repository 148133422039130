import { SchemaType } from '@novaera/ah-common';
import { NvDateTimePicker, NvTextField } from '@novaera/core';
import { ReactElement } from 'react';
import { BooleanComponent } from './boolean-component';

export const DefaultValuesMap: Record<SchemaType, ReactElement> = {
  [SchemaType.boolean]: <BooleanComponent />,
  [SchemaType.dateTime]: (
    <NvDateTimePicker timePickerProps={{ fullWidth: false }} datePickerProps={{ fullWidth: false }} />
  ),
  [SchemaType.email]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.float]: <NvTextField type="number" placeholder="Enter a default valid value" />,
  [SchemaType.integer]: <NvTextField type="number" placeholder="Enter a default valid value" />,
  [SchemaType.phone]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.url]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.uuid]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.string]: <NvTextField placeholder="Enter a default valid value" fullWidth />,
  [SchemaType.recordReference]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.map]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.array]: <NvTextField placeholder="Enter a default valid value" />,
  [SchemaType.object]: <NvTextField placeholder="Enter a default valid value" />,
};
