import { NvAxios, useQuery } from '@novaera/core';
import { GetAvailableTablesResponse } from './types';

const getAvailableTables = async () => {
  const result = await NvAxios.get<GetAvailableTablesResponse>('/admin/environment-sync/available-tables');
  return result?.data;
};

export const useGetAvailableTables = () => {
  return useQuery(['environment-sync/available-tables'], () => getAvailableTables());
};
