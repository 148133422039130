import { NvField, NvTextField, isRequired } from '@novaera/core';
import { PropertyPanelSection } from '../../../../property-panel';

export type LabelPropertyProps = {
  fieldName: string;
  disabled?: boolean;
};

export const LabelProperty = ({ fieldName, disabled }: LabelPropertyProps) => {
  return (
    <PropertyPanelSection title="Label">
      <NvField
        name={fieldName}
        direction="label-on-side"
        labelVariant="h6"
        isRequired
        validators={[isRequired()]}
        component={<NvTextField disabled={disabled} />}
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        formControlStyle={{ width: '100%' }}
        isDisabled={disabled}
      />
    </PropertyPanelSection>
  );
};
