import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../../permission-policies/keys';
import { GetPermissionPolicyResponse } from '../../permission-policies/use-get-permission-policy/types';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { Group } from '../types';
import { DeletePermissionPolicyFromGroupParams } from './types';

const deletePermissionPolicyFromGroup = async ({ groupId, policyId }: DeletePermissionPolicyFromGroupParams) => {
  await NvAxios.delete(`${GROUPS_ROOT_PATH}/${groupId}/permission-policies/${policyId}`);
  return;
};

export const useDeletePermissionPolicyFromGroup = () => {
  const cache = useQueryClient();

  return useMutation(deletePermissionPolicyFromGroup, {
    onSuccess: (_, { groupId, policyId }) => {
      cache.setQueryData<GetPermissionPolicyResponse>(QUERY_KEYS_PERMISSION_POLICIES.detail({ policyId }), (old) => {
        if (old) {
          return { ...old, groups: old.groups.filter((g) => g.id !== groupId) };
        } else {
          return old;
        }
      });
      cache.setQueryData<Group>(QUERY_KEYS_GROUPS.detail(groupId), (old) => {
        if (old) {
          return { ...old, permissionPolicies: old.permissionPolicies?.filter((p) => p.id !== policyId) };
        } else {
          return old;
        }
      });
    },
  });
};
