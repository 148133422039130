import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_CONNECTION } from '../keys';

export const useInvalidateUserConnectionsService = () => {
  const cache = useQueryClient();

  return {
    invalidate: () => {
      cache.invalidateQueries(QUERY_KEYS_CONNECTION.list());
    },
  };
};
