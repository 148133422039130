import { Navigate, Route, Routes } from '@novaera/route';
import React, { PropsWithChildren, useRef } from 'react';
import { ROUTES } from '../common/routes';
import { NavigationMenu } from '../components/navigation-menu';
import { PublicFooter } from '../components/public-footer';
import { PublicNavigation } from '../components/public-navigation';
import { ErrorBoundary } from '../error-boundary';
import { AppDirectoryDetail } from './app-directory-detail';
import { AppDirectoryHead } from './app-directory-head';
import { AppDirectoryList } from './app-directory-list';
import { AppDirectoryContainer } from './styled';

export { AppDocuments } from './app-directory-list/publish-your-app/app-details-step/app-documents';
export { Contributors } from './app-directory-list/publish-your-app/app-details-step/contributors';
export { ImagesAndVideos } from './app-directory-list/publish-your-app/app-details-step/images-and-videos';

export const AppDirectory: React.FC<PropsWithChildren<{ isAuthenticated: boolean }>> = ({ isAuthenticated }) => {
  const appDirectoryContainerRef = useRef<HTMLDivElement | null>(null);
  return (
    <>
      <AppDirectoryHead isAnonymous={!isAuthenticated} />
      {isAuthenticated ? <NavigationMenu /> : null}

      <AppDirectoryContainer ref={appDirectoryContainerRef} isPublic={!isAuthenticated}>
        {!isAuthenticated ? <PublicNavigation /> : null}
        <Routes>
          <Route
            path=""
            element={
              <ErrorBoundary>
                <AppDirectoryList isAnonymous={!isAuthenticated} />
              </ErrorBoundary>
            }
          />
          <Route path="birthday/166" element={<Navigate to={`${ROUTES.AppDirectory}/celebrations/33`} />}></Route>
          <Route
            path="bitbucket-pipelines/154"
            element={<Navigate to={`${ROUTES.AppDirectory}/bitbucket-pipelines/15`} />}
          ></Route>
          <Route
            path="bitbucket-pull-request-management/137"
            element={<Navigate to={`${ROUTES.AppDirectory}/bitbucket-pull-request-management/16`} />}
          ></Route>
          <Route
            path="continuous-integration-deployment/48"
            element={<Navigate to={`${ROUTES.AppDirectory}/continuous-integration-deployment/18`} />}
          ></Route>
          <Route
            path="conversational-ticketing-with-zendesk/26"
            element={<Navigate to={`${ROUTES.AppDirectory}/conversational-ticketing-with-zendesk/47`} />}
          ></Route>
          <Route
            path="github-actions-workflows/146"
            element={<Navigate to={`${ROUTES.AppDirectory}/github-actions-workflows/32`} />}
          ></Route>
          <Route
            path="github-pull-request-management/138"
            element={<Navigate to={`${ROUTES.AppDirectory}/git-hub-pull-request-management/20`} />}
          ></Route>
          <Route
            path="gitlab-merge-request-management/142"
            element={<Navigate to={`${ROUTES.AppDirectory}/git-lab-merge-request-management/40`} />}
          ></Route>
          <Route path="gmail/1" element={<Navigate to={`${ROUTES.AppDirectory}/gmail/21`} />}></Route>
          <Route path="jira/10" element={<Navigate to={`${ROUTES.AppDirectory}/jira-software/22`} />}></Route>
          <Route
            path="jira-service-management/2"
            element={<Navigate to={`${ROUTES.AppDirectory}/jira-service-management/27`} />}
          ></Route>
          <Route path="opsgenie/12" element={<Navigate to={`${ROUTES.AppDirectory}/opsgenie/28`} />}></Route>
          <Route path="quotes/157" element={<Navigate to={`${ROUTES.AppDirectory}`} />}></Route>
          <Route
            path="slack-private-channel-access/172"
            element={<Navigate to={`${ROUTES.AppDirectory}/slack-private-channel-access/26`} />}
          ></Route>
          <Route
            path="slack-first-incident-and-on-call-management-with-pagerduty/44"
            element={
              <Navigate to={`${ROUTES.AppDirectory}/slack-first-incident-and-on-call-management-with-pager-duty/23`} />
            }
          ></Route>
          <Route
            path="slack-first-sales-automation-with-hubspot/80"
            element={<Navigate to={`${ROUTES.AppDirectory}/slack-first-sales-automation-with-hub-spot/24`} />}
          ></Route>
          <Route path="statuspage/11" element={<Navigate to={`${ROUTES.AppDirectory}/statuspage/25`} />}></Route>

          <Route
            path="typeform-nps-results/167"
            element={<Navigate to={`${ROUTES.AppDirectory}/typeform-nps-results-app/30`} />}
          ></Route>
          <Route path="tutorial-app/148" element={<Navigate to={`${ROUTES.AppDirectory}/tutorial-app/49 `} />}></Route>
          <Route path="utilities/7" element={<Navigate to={`${ROUTES.AppDirectory}`} />}></Route>

          <Route
            path=":name/:storeSchemaId"
            element={
              <ErrorBoundary>
                <AppDirectoryDetail
                  isAnonymous={!isAuthenticated}
                  appDirectoryContainerRef={appDirectoryContainerRef}
                />
              </ErrorBoundary>
            }
          />
          <Route path="*" element={<Navigate to={'/error/404'} />}></Route>
        </Routes>
        {!isAuthenticated ? <PublicFooter /> : null}
      </AppDirectoryContainer>
    </>
  );
};
