import { CSSProperties } from '@novaera/theme-provider';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { SplitPaneContainer, StyledDivider } from './styled';

export const NvSplitPane: React.FC<
  React.PropsWithChildren<{
    direction: 'horizontal' | 'vertical';
    Divider?: React.ReactNode;
    style?: CSSProperties;
    onSizeChange?: () => void;
    onCollapsedPanel?: (collapsedIndex: number) => void;
  }>
> = ({ children, direction, Divider, style, onSizeChange, onCollapsedPanel }) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resizable = function (resizer: any) {
      const direction = resizer.getAttribute('data-direction') || 'horizontal';
      const prevSibling = resizer.previousElementSibling;
      const nextSibling = resizer.nextElementSibling;

      // The current position of mouse
      let x = 0;
      let y = 0;
      let prevSiblingHeight = 0;
      let prevSiblingWidth = 0;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mouseMoveHandler = function (e: any) {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        const dy = e.clientY - y;
        document.body.style.setProperty('user-select', 'none');

        switch (direction) {
          case 'vertical': {
            // eslint-disable-next-line no-case-declarations

            const h = ((prevSiblingHeight + dy) * 100) / resizer.parentNode.getBoundingClientRect().height;
            prevSibling.style.height = `${h}%`;
            if (nextSibling) {
              nextSibling.style.height = `${100 - h}%`;
              nextSibling.classList.remove('is-collapsed');
              nextSibling.classList.remove('is-full');
              nextSibling.classList.remove('vertical-expanded');
              nextSibling.classList.remove('horizontal-expanded');
              nextSibling.classList.add('is-dragging');
            }

            prevSibling.classList.remove('is-collapsed');
            prevSibling.classList.remove('is-full');
            prevSibling.classList.add('is-dragging');

            // eslint-disable-next-line no-case-declarations
            const shouldFirstPanelCollapsed =
              Math.floor(
                (parseFloat(resizer.parentNode.getBoundingClientRect().height) *
                  Math.floor(parseFloat(prevSibling.style.height))) /
                  100
              ) < parseFloat(prevSibling.style.minHeight);

            // eslint-disable-next-line no-case-declarations
            const shouldSecondPanelCollapsed =
              Math.floor(
                (parseFloat(resizer.parentNode.getBoundingClientRect().height) *
                  Math.floor(parseFloat(nextSibling.style.height))) /
                  100
              ) < parseFloat(nextSibling.style.minHeight);

            if (shouldFirstPanelCollapsed || shouldSecondPanelCollapsed) {
              onCollapsedPanel?.(shouldFirstPanelCollapsed ? 0 : 1);
            } else {
              onSizeChange?.();
            }

            break;
          }
          case 'horizontal':
          default: {
            // eslint-disable-next-line no-case-declarations
            const w = ((prevSiblingWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
            prevSibling.style.width = `${w}%`;
            nextSibling.style.width = `${100 - w}%`;
            prevSibling.classList.remove('is-collapsed');
            nextSibling.classList.remove('is-collapsed');

            prevSibling.classList.add('is-dragging');
            nextSibling.classList.add('is-dragging');

            break;
          }
        }

        const cursor = direction === 'horizontal' ? 'col-resize' : 'row-resize';
        resizer.style.cursor = cursor;
        document.body.style.cursor = cursor;

        prevSibling.style.userSelect = 'none';
        prevSibling.style.pointerEvents = 'none';

        nextSibling.style.userSelect = 'none';
        nextSibling.style.pointerEvents = 'none';
      };

      const mouseUpHandler = function () {
        resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');
        document.body.style.removeProperty('user-select');
        prevSibling.style.removeProperty('user-select');
        prevSibling.style.removeProperty('pointer-events');

        nextSibling.style.removeProperty('user-select');
        nextSibling.style.removeProperty('pointer-events');

        prevSibling.classList.remove('is-dragging');
        nextSibling.classList.remove('is-dragging');

        const isVerticalDragging = resizer.getAttribute('data-direction') === 'vertical';

        let shouldFirstPanelCollapsed: boolean | null = null;
        let shouldSecondPanelCollapsed: boolean | null = null;

        if (isVerticalDragging) {
          shouldFirstPanelCollapsed =
            Math.floor(
              (parseFloat(resizer.parentNode.getBoundingClientRect().height) *
                Math.floor(parseFloat(prevSibling.style.height))) /
                100
            ) < parseFloat(prevSibling.style.minHeight);

          shouldSecondPanelCollapsed =
            Math.floor(
              (parseFloat(resizer.parentNode.getBoundingClientRect().height) *
                Math.floor(parseFloat(nextSibling.style.height))) /
                100
            ) < parseFloat(nextSibling.style.minHeight);
        } else {
          shouldFirstPanelCollapsed =
            Math.floor(
              (parseFloat(resizer.parentNode.getBoundingClientRect().width) *
                Math.floor(parseFloat(prevSibling.style.width))) /
                100
            ) < parseFloat(prevSibling.style.minWidth);

          // eslint-disable-next-line no-case-declarations
          shouldSecondPanelCollapsed =
            Math.floor(
              (parseFloat(resizer.parentNode.getBoundingClientRect().width) *
                Math.floor(parseFloat(nextSibling.style.width))) /
                100
            ) < parseFloat(nextSibling.style.minWidth);
        }

        if (shouldFirstPanelCollapsed) {
          prevSibling.classList.add('is-collapsed');
          nextSibling.classList.add('is-full');
        } else if (shouldSecondPanelCollapsed) {
          nextSibling.classList.add('is-collapsed');
          prevSibling.classList.add('is-full');
        }

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      // Handle the mousedown event
      // that's triggered when user drags the resizer
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mouseDownHandler = function (e: any) {
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        const rect = prevSibling.getBoundingClientRect();
        prevSiblingHeight = rect.height;
        prevSiblingWidth = rect.width;

        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      // Attach the handler
      resizer.addEventListener('mousedown', mouseDownHandler);
    };
    // Query all resizers
    document.querySelectorAll('.resizer').forEach(function (ele) {
      resizable(ele);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SplitPaneContainer direction={direction} style={{ ...style }}>
      {React.Children.map(children, (child, index) => {
        return (
          <>
            {React.isValidElement(child) &&
              React.cloneElement(child, {
                ...child.props,
                dataPanelIndex: index,
                direction,
                panelCount: React.Children.count,
              })}
            {index === 0 &&
              (React.isValidElement(Divider) && Divider ? (
                React.cloneElement(Divider, {
                  ...Divider.props,
                  'data-direction': direction,
                  className: classnames({
                    [`${Divider.props.className ? Divider.props.className : ''} resizer`]: true,
                    'horizontal-divider': direction === 'horizontal',
                    'vertical-divider': direction === 'vertical',
                  }),
                })
              ) : (
                <StyledDivider
                  data-direction={direction}
                  className={classnames({
                    resizer: true,
                    'horizontal-divider': direction === 'horizontal',
                    'vertical-divider': direction === 'vertical',
                  })}
                />
              ))}
          </>
        );
      })}
    </SplitPaneContainer>
  );
};
