import { ConnectionValidationSchema } from '@novaera/actioner-service';
import { useMemo } from 'react';
import { ConnectionValidationType } from '../../types';

export const useMissingConnectionController = ({
  connectionValidationSchemas,
}: {
  connectionValidationSchemas?: ConnectionValidationSchema[];
}) => {
  const connectionValidationMode: ConnectionValidationType = useMemo(() => {
    if (connectionValidationSchemas?.length === 1) {
      return connectionValidationSchemas[0].status;
    } else if (connectionValidationSchemas && connectionValidationSchemas?.length > 1) {
      return 'multiple';
    }
    return 'not-valid';
  }, [connectionValidationSchemas]);

  const worksWithFormSubmit = useMemo(() => {
    return connectionValidationSchemas?.length === 1 && connectionValidationMode === 'multiple';
  }, [connectionValidationSchemas?.length, connectionValidationMode]);

  return {
    connectionValidationMode,
    worksWithFormSubmit,
  };
};
