import CodeMirror, { StringStream } from 'codemirror';

import 'codemirror/addon/mode/overlay';
import 'codemirror/mode/javascript/javascript';

import 'codemirror/addon/mode/multiplex';
import { expressionTokenizer, functionTokenizer } from './tokenizer';
import { ExpressionState, FunctionModeState, FunctionParsedConfig, ParsedConfig } from './types';
import { getInitialStateForExpression, getInitialStateForFunction } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
CodeMirror.defineMode('novaera_base', function (config: any, parsedConfig: ParsedConfig | FunctionParsedConfig) {
  return CodeMirror.multiplexingMode(CodeMirror.getMode(config, parsedConfig.base), {
    open: '{{',
    close: '}}',
    mode: CodeMirror.getMode(config, 'javascript'),
    parseDelimiters: false,
  });
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
CodeMirror.defineMode('novaera', function (config: any, parsedConfig: ParsedConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const novaeraBaseMode: any = CodeMirror.getMode(
    config,
    Object.assign(Object.assign({}, parsedConfig), {
      name: 'novaera_base',
    })
  );

  const initialState: ExpressionState = getInitialStateForExpression(config);

  const object = {
    startState: () => ({
      ...initialState,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: (stream: StringStream, state: ExpressionState) => {
      return expressionTokenizer(stream, state);
    },
  };

  return CodeMirror.overlayMode(CodeMirror.getMode(novaeraBaseMode, 'javascript'), object);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
CodeMirror.defineMode('novaera_function', function (config: any, parsedConfig: FunctionParsedConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const novaeraBaseMode: any = CodeMirror.getMode(
    config,
    Object.assign(Object.assign({}, parsedConfig), {
      name: 'novaera_base',
    })
  );

  const initialState: FunctionModeState = getInitialStateForFunction(config, parsedConfig);

  const object = {
    startState: () => ({
      ...initialState,
    }),
    token: (stream: StringStream, state: FunctionModeState) => {
      return functionTokenizer(stream, state);
    },
  };

  return CodeMirror.overlayMode(CodeMirror.getMode(novaeraBaseMode, 'javascript'), object);
});
