import { NvField, NvFlex, NvSwitch, NvTypography, SectionMessage } from '@novaera/core';

import { PropertyPanelSection } from '../../../../property-panel';
import { VectorDBConfigurationProps } from '../types';

export const VectorDBConfiguration = ({ fieldName, replicateToVectorDB }: VectorDBConfigurationProps) => {
  return (
    <PropertyPanelSection title="Semantic search" collapsible defaultCollapsed>
      <NvFlex gap="8px">
        {!replicateToVectorDB && (
          <SectionMessage
            message="The table currently does not support semantic search capabilities. To use this feature within this field, you need to enable semantic search for this table first."
            variant="warning"
          />
        )}
        <NvFlex direction="row" alignItems="flex-start" gap="8px">
          <NvFlex>
            <NvField<boolean>
              name={`${fieldName}.replicateField`}
              type="checkbox"
              component={({ checked, onChange }) => (
                <NvSwitch variant="compact" checked={checked} onChange={onChange} />
              )}
            />
          </NvFlex>
          <NvTypography variant="body2">
            Enable for this field to apply <b>semantic search</b> functionality.
          </NvTypography>
        </NvFlex>
        <NvFlex direction="row" alignItems="flex-start" gap="8px">
          <NvFlex>
            <NvField<boolean>
              name={`${fieldName}.filterable`}
              type="checkbox"
              component={({ checked, onChange }) => (
                <NvSwitch variant="compact" checked={checked} onChange={onChange} />
              )}
            />
          </NvFlex>
          <NvTypography variant="body2">
            If you enable the <b>filter</b> functionality, you can apply filters to the results of this field while
            using semantic search.
          </NvTypography>
        </NvFlex>
      </NvFlex>
    </PropertyPanelSection>
  );
};
