import { assert } from '@novaera/utils';

export const MoveNodeDataTransfer = {
  setData: (e: DragEvent, data: string) => {
    assert(e.dataTransfer !== null, new Error('e.dataTransfer should be sent'), 'ERROR');

    e.dataTransfer.setData('application/reactflow', data);
    e.dataTransfer.effectAllowed = 'move';
  },

  getData: (e: DragEvent) => {
    assert(e.dataTransfer !== null, new Error('e.dataTransfer should be sent'), 'ERROR');

    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    const data = e.dataTransfer.getData('application/reactflow');
    return data;
  },
};
