import { NvBox, NvFocusState } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const VersionHistoryFocusState = styled(NvFocusState)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  .NvFocusStateWrapper {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.nv_neutral[5]};

    .NvFocusStateBody {
      height: 100%;
    }
  }
`;

export const VersionHistoryJsonViewWrapper = styled(NvBox)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
  height: 100%;
  overflow: auto;
`;
