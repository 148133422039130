import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_SUBSCRIPTION } from '../constants';

type GetResetWorkspaceUsageParams = {
  workspaceId: string;
};

const resetWorkspaceUsage = async ({ workspaceId }: GetResetWorkspaceUsageParams) => {
  const result = await NvAxios.get(`${ADMIN_SUBSCRIPTION}/subscriptions/${workspaceId}/reset`);
  return result?.data;
};

export const useResetWorkspaceUsage = () => useMutation(resetWorkspaceUsage);
