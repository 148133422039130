import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PrincipalCard = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 12px;
  height: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
`;
