import { Vertices, WorkflowWithState } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { getComponentChildren } from './get-component-children';
import { replaceNextAlias } from './replace-next-alias';

export const deleteNodeFromWorkflow = ({
  workflow,
  deletedNodeId,
}: {
  workflow?: WorkflowWithState;
  deletedNodeId: string;
}): WorkflowWithState => {
  assert(
    !!workflow?.graph?.vertices,
    new Error('Since a delete operation is initiated, there should be nodes'),
    'ERROR'
  );
  const nodeToDelete = workflow?.graph?.vertices.find((v) => v.alias === deletedNodeId);

  if (nodeToDelete) {
    const deletedNodes = getComponentChildren({
      nodes: workflow?.graph?.vertices,
      currentNodeId: deletedNodeId,
      nodeToStop: nodeToDelete?.nextAlias,
    });
    const additionalDeletedNodes = [...deletedNodes, deletedNodeId];

    const newNodes: Vertices = workflow.graph.vertices.filter((v) => !additionalDeletedNodes.includes(v.alias));

    const { replacedNodes, nodeSummaries } = replaceNextAlias({
      nodes: newNodes,
      nodeToDelete,
    });

    return {
      ...workflow,
      graph: { vertices: replacedNodes },
      nodeSummaries: { ...nodeSummaries },
    };
  }
  return workflow;
};
