import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CheckboxContainer = styled(NvBox)`
  display: flex;
  direction: row;
  align-items: flex-start;
  justify-content: start;
  gap: 6px;
  border-radius: 6px;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  transition: background-color 200ms ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
  }
`;
