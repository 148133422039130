import { BaseFieldType } from '@novaera/actioner-service';
import { NvDivider, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React, { ReactElement, isValidElement } from 'react';
import { DataModelFieldSchemaTitle } from '../../../right-content/header/data-model-field-schema-title';
import { FieldLogoWrapper } from '../../../styled';
import { findLogoFromFields } from '../../../utils';

export const RelationshipBlockDataModelHeader = ({
  item,
  isSelected,
}: {
  isSelected?: boolean;
  item: BaseFieldType;
}) => {
  const { palette } = useTheme();

  return (
    <NvFlex direction="row" gap="12px" alignItems="center" width="100%">
      <NvTypography variant="h4" {...(isSelected && { color: palette.nv_main[60] })}>
        {item.name}
      </NvTypography>
      <NvDivider
        orientation="vertical"
        sx={{
          height: '12px',
        }}
      />
      <FieldLogoWrapper>
        {isValidElement(findLogoFromFields(item.schema.type)) &&
          React.cloneElement(findLogoFromFields(item.schema.type) as ReactElement, {
            sx: { height: '12px', width: '12px', color: palette.nv_neutral[600] },
          })}
        <DataModelFieldSchemaTitle variant="h5" schema={item.schema} textColor="secondary" />
      </FieldLogoWrapper>
    </NvFlex>
  );
};
