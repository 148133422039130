import { DataSourceType } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvArrowForwardIcon,
  NvButton,
  NvCloseIcon,
  NvSlide,
  NvSourceIcon,
  NvTypography,
  Portal,
} from '@novaera/core';
import { FC, useEffect } from 'react';
import { PropertyPanelHeader, PropertyPanelListHeader } from '../../../../../components';
import { DataSourceTypeSection } from '../../../../../components/property-configuration/components/data-source-type-section';
import { StaticDataSourceMapper } from '../../../../../components/property-configuration/components/static-data-source-mapper';
import { IntegrationActionDynamicDataSourceMapper } from './dynamic-data-source-mapper';
import { InitiatorWrapper, SourceTypesSlideWrapper } from './styled';
import { DataSourceMapperProps } from './types';
import { useDataSourceMapper } from './use-data-source-mapper';

export const DatasourceMapper: FC<React.PropsWithChildren<DataSourceMapperProps>> = ({ containerRef, schema }) => {
  const { handleInitiatorClick, handleNewItemClick, showSlide, handleCloseSlide, dataSourceFormValue } =
    useDataSourceMapper();

  useEffect(() => {
    if (containerRef?.current && showSlide) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [containerRef, showSlide]);

  return (
    <>
      <InitiatorWrapper onClick={handleInitiatorClick}>
        <NvSourceIcon />
        <NvTypography variant="h5" flex="1 1 auto">
          Sources
        </NvTypography>
        <NvArrowForwardIcon sx={{ width: '16px', height: '16px' }} />
      </InitiatorWrapper>
      <Portal node={containerRef?.current}>
        <NvSlide direction="left" in={showSlide} mountOnEnter unmountOnExit container={containerRef?.current}>
          <SourceTypesSlideWrapper>
            <PropertyPanelHeader
              title={'Sources'}
              icon={<NvSourceIcon />}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={handleCloseSlide}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
            <DataSourceTypeSection schema={schema} />
            <PropertyPanelListHeader
              title={dataSourceFormValue.type === 'dynamic' ? 'Source mapping' : 'Options'}
              tooltip={
                dataSourceFormValue.type === 'dynamic'
                  ? 'Select a request to map its response as the source of this input field.'
                  : 'The options listed below will appear as selectable items for users to choose from.'
              }
              actions={
                <NvButton
                  onlyIcon
                  onClick={handleNewItemClick}
                  size="small"
                  variant="contained"
                  color="ghost"
                  sx={{ flex: '0 0 auto' }}
                >
                  <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
                </NvButton>
              }
            />
            {dataSourceFormValue.type === DataSourceType.DYNAMIC ? (
              <IntegrationActionDynamicDataSourceMapper />
            ) : (
              <StaticDataSourceMapper />
            )}
          </SourceTypesSlideWrapper>
        </NvSlide>
      </Portal>
    </>
  );
};
