import {
  MutateOptions,
  MutationFunction,
  useMutation as originalUseMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { useHandleCommonError } from '../use-handle-error';

export function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { handleCommonError } = useHandleCommonError();
  const newOptionsFn = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (hookOnError: any, mutateOptions: any) => {
      return {
        ...(mutateOptions ?? {}),
        onError: (err: TError, variables: TVariables, context: TContext) => {
          if (mutateOptions?.onError || hookOnError) {
            try {
              mutateOptions?.onError?.(err, variables, context);
            } catch (error) {
              handleCommonError(err);
            }
            try {
              hookOnError?.(err, variables, context);
            } catch (error) {
              handleCommonError(err);
            }
          } else {
            handleCommonError(err);
          }
        },
      };
    },
    [handleCommonError]
  );
  const { onError: hookOnError, ...optionsWithoutOnError } = options ?? {};
  const { mutate, mutateAsync, ...rest } = originalUseMutation(mutationFn, optionsWithoutOnError);

  const newMutate = useCallback(
    (variables: TVariables, mutateOptions?: MutateOptions<TData, TError, TVariables, TContext> | undefined) => {
      const newMutateOptions = newOptionsFn(hookOnError, mutateOptions);
      return mutate(variables, newMutateOptions);
    },
    [hookOnError, mutate, newOptionsFn]
  );

  const newAsyncMutate = useCallback(
    (variables: TVariables, mutateOptions?: MutateOptions<TData, TError, TVariables, TContext> | undefined) => {
      const newMutateOptions = newOptionsFn(hookOnError, mutateOptions);
      return mutateAsync(variables, newMutateOptions);
    },
    [hookOnError, mutateAsync, newOptionsFn]
  );

  return { mutate: newMutate, mutateAsync: newAsyncMutate, ...rest };
}
