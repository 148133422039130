import { usePrevious } from '@novaera/utils';
import { isEqual } from 'lodash';
import { memo, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LegacyAutoSave = memo((props: any) => {
  const previous = usePrevious(props.values);
  useEffect(() => {
    if (!isEqual(props.values, previous) && props.dirty) {
      props.onChange && props.onChange(props);
    }
  }, [previous, props]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
});
