import { InputParameter, useGetWorkflow, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import { NvFlex, NvForm } from '@novaera/core';
import arrayMutators from 'final-form-arrays';

import { useParams } from '@novaera/route';
import { noop } from 'lodash';
import { FC, useMemo } from 'react';
import { useInputsContext } from '../../../../../../../../../action-designer/providers/input-provider';
import { PPDrawerItem } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useUserAppOptionsContext } from '../../../../../components/options/provider';
import { PropertiesLoading } from '../../../common/properties-loading';
import { FormTriggerDynamicSourceDetail } from '../form-trigger-dynamic-source-detail';
import { FormTriggerInputParameterDetail } from '../form-trigger-input-parameter-detail';
import { FormTriggerDetailAndSourceProps } from './types';

export const FormTriggerDetailAndSource: FC<React.PropsWithChildren<FormTriggerDetailAndSourceProps>> = ({
  onSecondClose,
  secondPanelProps: second,
  onThirdClose,
  thirdPanelProps: third,
  dataSourceIndex,
  setDataSourceIndex,
  setThirdPanelOpen,
}) => {
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const { selectedInputParameterId, emptySelectedInputParameterIdWithDelay } = useInputsContext();
  const { mutate: saveToDraftWorkflow } = useSaveToDraftWorkflow();
  const { invalidate } = useUserAppOptionsContext();
  const { hasEditPermission } = useWorkflowPermission();
  const handleOnChanges = (
    values: InputParameter,
    dirtyFields: {
      [key: string]: boolean;
    }
  ) => {
    if (!hasEditPermission) {
      return;
    }

    if (workflow?.trigger?.type === 'form') {
      saveToDraftWorkflow(
        {
          ...workflow,
          trigger: {
            ...workflow.trigger,
            inputParameters: workflow.trigger.inputParameters.map((i) => (i.id === values.id ? values : i)),
          },
        },
        {
          onSuccess: () => {
            if (
              (dirtyFields['uiComponent.dataSource.optionsProducers'] ||
                dirtyFields['uiComponent.dataSource.options']) &&
              values.id
            ) {
              invalidate(values.id);
            }
          },
        }
      );
    }
  };

  const initialInputParameter = useMemo(
    () =>
      workflow?.trigger?.type === 'form'
        ? workflow.trigger.inputParameters.find((i) => i.id === selectedInputParameterId)
        : undefined,
    [selectedInputParameterId, workflow?.trigger]
  );

  return selectedInputParameterId && initialInputParameter ? (
    <NvForm<InputParameter>
      key={selectedInputParameterId}
      onSubmit={noop}
      initialValues={initialInputParameter}
      {...(hasEditPermission && {
        onChange: ({ values, dirtyFields }) => {
          handleOnChanges(values, dirtyFields);
        },
      })}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <NvFlex direction="row" height={'100%'}>
        <PPDrawerItem {...second}>
          <FormTriggerInputParameterDetail
            onDataSourceIndexChanged={(index: number | null) => {
              setDataSourceIndex(index);
              setThirdPanelOpen(index !== null);
            }}
            onCloseClicked={onSecondClose}
            handleDelete={() => {
              if (!hasEditPermission) {
                return;
              }
              emptySelectedInputParameterIdWithDelay();
              onSecondClose();
            }}
            dataSourceIndex={dataSourceIndex}
          />
        </PPDrawerItem>
        <PPDrawerItem {...third}>
          {dataSourceIndex !== null && (
            <FormTriggerDynamicSourceDetail
              title={`Source ${dataSourceIndex + 1}`}
              dataSourceIndex={dataSourceIndex}
              onDelete={() => {
                if (!hasEditPermission) {
                  return;
                }
                onThirdClose();
              }}
              onCloseClicked={onThirdClose}
            />
          )}
        </PPDrawerItem>
      </NvFlex>
    </NvForm>
  ) : (
    <PPDrawerItem {...second}>
      <PropertiesLoading />
    </PPDrawerItem>
  );
};
