import {
  APP_PERMISSION,
  AccessType,
  WorkflowPermission,
  WorkflowPermissionScope,
  useFetchProfile,
  useGetUserApp,
  useGetWorkflow,
} from '@novaera/actioner-service';
import { useCallback, useMemo } from 'react';
import { useGetWorkflowQueryParams } from '../../user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { useUserAppPermissionBoundary } from '../use-user-app-permission-boundary';

export const useWorkflowPermission = () => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { data: userApp } = useGetUserApp(userAppId);

  const { data: profile } = useFetchProfile();

  const { savedWorkflow } = useGetWorkflow({
    appId: userAppId,
    workflowId,
  });

  const { checkPermission } = useUserAppPermissionBoundary({
    appId: userAppId,
  });

  const checkWorkflowPermission = useCallback(
    (permission: WorkflowPermission) => {
      return (
        (permission.principal.type === AccessType.USER &&
          permission.principal.id === profile?.userId &&
          permission.scope === WorkflowPermissionScope.EDIT) ||
        (permission.principal.type === AccessType.GROUP &&
          !!profile?.groupIds.includes(permission.principal.id) &&
          permission.scope === WorkflowPermissionScope.EDIT)
      );
    },
    [profile?.groupIds, profile?.userId]
  );

  const getActivePermission = useCallback(
    (permissions?: WorkflowPermission[]) => {
      if (!permissions) return undefined;

      const userPermission = permissions.find(
        (permission) => permission.principal.type === AccessType.USER && permission.principal.id === profile?.userId
      );

      if (userPermission) {
        return userPermission;
      } else {
        const groupPermission = permissions.find(
          (permission) =>
            permission.principal.type === AccessType.GROUP && !!profile?.groupIds.includes(permission.principal.id)
        );

        if (groupPermission) {
          return groupPermission;
        } else {
          return undefined;
        }
      }
    },
    [profile?.groupIds, profile?.userId]
  );

  const hasEditPermission = useMemo(() => {
    return (
      checkPermission(APP_PERMISSION.APP_EDIT) ||
      getActivePermission(savedWorkflow?.permissions)?.scope === WorkflowPermissionScope.EDIT
    );
  }, [checkPermission, getActivePermission, savedWorkflow?.permissions]);

  const handleCheckEditPermission = (permission?: WorkflowPermission) => {
    return checkPermission(APP_PERMISSION.APP_EDIT) || Boolean(permission && checkWorkflowPermission(permission));
  };

  return {
    hasEditPermission,
    checkEditPermission: handleCheckEditPermission,
    isManagedApp: userApp?.managed,
  };
};
