import { isBarChartOutputComponent, isPieChartOutputComponent, useUpdateNode } from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';

import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { NodeType, PropertyPanelHeader, PropertyPanelSimpleSection } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useOutputIcon } from '../../../../common/use-output-icon';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { OUTPUT_DESCRIPTION_MAP } from '../../../../utils/output-description-map';
import { usePropertyPanelContext } from '../../../provider';
import { BarChartPanelSection } from './bar-chart-panel-section';
import { KeyValueTablePanelSection } from './key-value-table-panel-section';
import { MarkdownPanelSection } from './markdown-panel-section';
import { PieChartPanelSection } from './pie-chart-panel-section';
import { SlackBlockKitPanelSection } from './slack-block-kit-panel-section';
import { TablePanelSection } from './table-panel-section';
import { OutputPropertyPanelProps } from './types';

export const OutputPropertyPanel = ({ onCloseClicked, outputNode }: OutputPropertyPanelProps) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { hasEditPermission } = useWorkflowPermission();

  assert(
    !!outputNode,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  assert(
    outputNode.type === 'output',
    new Error(
      'Since the selected node in the graph is having type function it should be function in the workflow object'
    ),
    'ERROR'
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }

      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: outputNode.alias,
          payload: { ...outputNode, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(outputNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [outputNode, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const { outputIcon: titleIcon } = useOutputIcon({ outputNode });

  const outputForm = useMemo(() => {
    const outputComponent = outputNode.outputComponent;
    if (outputComponent.type === 'key-value-table') {
      return (
        <KeyValueTablePanelSection
          keyValueTableOutputComponent={outputComponent}
          alias={outputNode.alias}
          name={outputNode.name}
          userAppId={userAppId}
          workflowId={workflowId}
        />
      );
    } else if (outputComponent.type === 'chart' && isBarChartOutputComponent(outputComponent)) {
      return (
        <BarChartPanelSection
          barChartOutputComponent={outputComponent}
          alias={outputNode.alias}
          name={outputNode.name}
          userAppId={userAppId}
          workflowId={workflowId}
        />
      );
    } else if (outputComponent.type === 'chart' && isPieChartOutputComponent(outputComponent)) {
      return (
        <PieChartPanelSection
          pieChartOutputComponent={outputComponent}
          alias={outputNode.alias}
          name={outputNode.name}
          userAppId={userAppId}
          workflowId={workflowId}
        />
      );
    } else if (outputComponent.type === 'markdown') {
      return (
        <MarkdownPanelSection
          markdownOutputComponent={outputComponent}
          alias={outputNode.alias}
          name={outputNode.name}
          userAppId={userAppId}
          workflowId={workflowId}
        />
      );
    } else if (outputComponent.type === 'slack') {
      return (
        <SlackBlockKitPanelSection
          slackBlockKitOutputComponent={outputComponent}
          alias={outputNode.alias}
          name={outputNode.name}
          userAppId={userAppId}
          workflowId={workflowId}
        />
      );
    } else if (outputComponent.type === 'table') {
      return (
        <TablePanelSection
          tableOutputComponent={outputComponent}
          alias={outputNode.alias}
          name={outputNode.name}
          userAppId={userAppId}
          workflowId={workflowId}
        />
      );
    }
    assert(false, new Error(`Output type can be table, bar-chart, pie-chart, key-value-table or markdown`), 'ERROR');
  }, [outputNode.alias, outputNode.name, outputNode.outputComponent, userAppId, workflowId]);

  const outputExplanation = useMemo(() => {
    const outputComponent = outputNode.outputComponent;
    if (outputComponent.type === 'markdown') {
      return 'To create your output, use markdown and button blocks. Markdown blocks enable you to customize the layout and style of your content. Include button blocks if you want to run workflows directly from the buttons within output view.';
    }
    if (outputComponent.type === 'slack') {
      return 'Generate customized Slack messages using Block Kit JSON formatting. Please note that Slack Block Kit does not support components that directly interact with users. Instead, it allows you to create custom message formats.';
    } else if (outputComponent.type === 'chart') {
      return OUTPUT_DESCRIPTION_MAP['chart'][outputComponent.chart.type];
    } else {
      return OUTPUT_DESCRIPTION_MAP[outputComponent.type];
    }
  }, [outputNode.outputComponent]);

  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          type={NodeType.OUTPUT}
          icon={titleIcon}
          title={outputNode.name}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: outputNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          icon={titleIcon}
          title={outputNode.name}
          type={NodeType.OUTPUT}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <PropertyPanelSimpleSection>
        <NvTypography variant="body2">{outputExplanation}</NvTypography>
      </PropertyPanelSimpleSection>
      {outputForm}
    </>
  );
};
