import { AutocompleteRenderInputParams } from '@mui/material';
import { assert } from '@novaera/utils';
import { includes, uniqueId } from 'lodash';

import { NvChip } from '../../chip';

import { NvTextField } from '../../textfield';

import { NvTagsAutoCompleteWrapper } from './styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NvTagsAutoComplete = (props: any) => {
  assert(
    !!props.onChange && typeof props.onChange === 'function',
    new Error('OnChange should be provided to work that component'),
    'ERROR'
  );

  const tagsUniqueId = uniqueId();

  return (
    <NvTagsAutoCompleteWrapper
      options={[]}
      multiple
      freeSolo
      open={false}
      clearOnBlur
      placeholder="Add aliases"
      renderInput={(renderInputProps: AutocompleteRenderInputParams) => {
        return (
          <NvTextField
            {...renderInputProps}
            sx={{ marginRight: '16px' }}
            placeholder={props.placeholder ? props.placeholder : 'Add aliases'}
          />
        );
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <NvChip
              {...getTagProps({ index })}
              key={`tag_auto_complete_${tagsUniqueId}_${option}`}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              label={option as any}
            />
          );
        });
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onKeyDown={(event: any) => {
        if (event.key === 'Tab' || event.key === 'Enter') {
          const newValue = event.target.value;
          event.preventDefault();
          event.defaultMuiPrevented = true;

          if (event.target instanceof HTMLInputElement && newValue) {
            if (!includes(props.value, newValue)) {
              assert(Array.isArray(props.value), new Error('Values should be an array'), 'ERROR');
              props.onChange(event, [...props.value, newValue]);
            }
          }
        }
      }}
      {...props}
    />
  );
};
