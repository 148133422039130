import { NvAxios, useQuery } from '@novaera/core';
import { CATALOG_ENTITY_TYPES_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_ENTITY_TYPES } from '../keys';

import { CatalogEntityType } from '../types';
import { useUpdateCatalogEntityCache } from '../use-update-catalog-entity-cache';
import { GetCatalogEntityTypeParams } from './types';

const getCatalogEntityType = async (params: GetCatalogEntityTypeParams) => {
  const result = await NvAxios.get<CatalogEntityType>(`${CATALOG_ENTITY_TYPES_ROOT_PATH}/${params.id}`);
  return result?.data;
};

export const useGetCatalogEntityType = (params: GetCatalogEntityTypeParams) => {
  const { getEntityTypeFromListCache } = useUpdateCatalogEntityCache();

  return useQuery<CatalogEntityType>(
    QUERY_KEY_CATALOG_ENTITY_TYPES.detail(params.id),
    () => getCatalogEntityType(params),
    {
      enabled: !!params.id,
      select: (data) => {
        return {
          ...data,
          parameters: data?.parameters ?? [],
        };
      },
      placeholderData: () => {
        const data = params.id ? getEntityTypeFromListCache(params.id) : undefined;

        return data;
      },
    }
  );
};
