import { ListValue, SendEmailNode, StringValue, ValueTypes, useUpdateNode } from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvMailOutlineIcon,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvNorthEastIcon,
  NvReceiptLongIcon,
  NvSelect,
  NvShortTextIcon,
  NvSouthEastIcon,
  NvTuneIcon,
  isRequired,
  useField,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { getSubdomain } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { EmailAddressCard } from '../../email-trigger-property-panel-drawer/email-trigger-properties/email-address-card';
import { EMAIL_CONTENT_TYPES } from './constants';
import { SideGroupFreeSoloMulti } from './side-grouped-free-solo-multi';
import { SendEmailPropertiesProps } from './types';

export const SendEmailProperties: FC<SendEmailPropertiesProps> = ({
  sendEmailNode,
  onCloseClicked,
  secondPanelOpen,
  onMoreSettingsClicked,
}) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const {
    input: { value: contentType },
  } = useField('contentType');
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const emailPrefix = useMemo(() => `${getSubdomain()}`, []);
  const emailPostfix = `@${sendEmailNode.senderDomain}`;

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: sendEmailNode.alias,
          payload: { ...(sendEmailNode as SendEmailNode), name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(sendEmailNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [sendEmailNode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        hasEditRight={hasEditPermission}
        icon={<NvMailOutlineIcon />}
        title={sendEmailNode.name}
        type={NodeType.SEND_EMAIL}
        onTitleChange={async (title) => {
          if (title) {
            handleSaveName(title);
          }
        }}
        validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'small', color: 'secondary' },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    deleteNodeAndUpdateGraph({ nodeId: sendEmailNode.alias });
                  },
                },
              ]}
            />

            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <PropertyPanelSection title="From" icon={<NvSouthEastIcon />}>
        <NvFlex gap="8px">
          <NvField<string | undefined>
            labelText="Email"
            labelVariant="h6"
            direction="label-on-top"
            name="fromLocalPartSuffix"
            formControlStyle={{ width: '100%' }}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => {
              return value ? { type: ValueTypes.STRING, value: value } : undefined;
            }}
            component={({ value, onChange }) => (
              <EmailAddressCard emailPrefix={emailPrefix} emailPostfix={emailPostfix}>
                <NvBox flex="1 1 0">
                  <CodeInput
                    placeholder="suffix"
                    value={value}
                    onChange={onChange}
                    context={workflowCodeInputContext}
                  />
                </NvBox>
              </EmailAddressCard>
            )}
            validators={[isRequired()]}
            hasRequiredIndicator
            showErrorMessageOnlyWhenBlur
          />
          <NvField
            labelText="Name"
            labelVariant="h6"
            direction="label-on-top"
            name="fromName"
            infoText="The given name will be displayed in an inbox above or to the left of the subject line."
            formControlStyle={{ width: '100%' }}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => {
              return value ? { type: ValueTypes.STRING, value: value } : undefined;
            }}
            component={<CodeInput placeholder="Add a name (Optional)" context={workflowCodeInputContext} />}
          />
        </NvFlex>
      </PropertyPanelSection>

      <PropertyPanelSection title="To" icon={<NvNorthEastIcon />}>
        <NvField<undefined | (Omit<ListValue, 'value'> & { value?: StringValue[] })>
          name="to"
          validators={[isRequired()]}
          showErrorMessageOnlyWhenBlur
          formControlStyle={{ width: '100%' }}
          component={({ value, onChange }) => (
            <SideGroupFreeSoloMulti
              value={value}
              onChange={onChange}
              context={workflowCodeInputContext}
              placeholder="Add multiple emails"
            />
          )}
        />
      </PropertyPanelSection>
      <PropertyPanelSection title="Subject" icon={<NvShortTextIcon />}>
        <NvField
          name="subject"
          formControlStyle={{ width: '100%' }}
          format={(value: StringValue | undefined) => value?.value}
          parse={(value: string) => {
            return value ? { type: ValueTypes.STRING, value: value } : undefined;
          }}
          component={<CodeInput placeholder="Add a subject" context={workflowCodeInputContext} />}
          validators={[isRequired()]}
          showErrorMessageOnlyWhenBlur
        />
      </PropertyPanelSection>
      <PropertyPanelSection title="Content" icon={<NvReceiptLongIcon />}>
        <NvFlex gap="8px">
          <NvField
            labelText="Content type"
            direction="label-on-side"
            labelVariant="h6"
            name="contentType"
            formControlStyle={{ maxWidth: '170px' }}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => {
              return value ? { type: ValueTypes.STRING, value: value } : undefined;
            }}
            component={<NvSelect placeholder="Select content type" options={EMAIL_CONTENT_TYPES} />}
          />
          <NvField
            name="content"
            formControlStyle={{ width: '100%' }}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => {
              return value ? { type: ValueTypes.STRING, value: value } : undefined;
            }}
            component={
              <CodeInput
                key={contentType.value}
                initialHeight="150px"
                context={workflowCodeInputContext}
                placeholder={`You can write ${
                  contentType?.value
                    ? EMAIL_CONTENT_TYPES.find((c) => c.value === contentType?.value)?.label
                    : 'content'
                } in here`}
              />
            }
          />
        </NvFlex>
      </PropertyPanelSection>

      <PropertyPanelSimpleSection>
        <NvFlex gap="8px">
          <RowItemCard
            isSelected={secondPanelOpen}
            rowItemLeftContentProps={{ draggable: false, icon: <NvTuneIcon /> }}
            rowItemContent={<SimpleLabelLayout simpleLabel="More settings" />}
            onClick={onMoreSettingsClicked}
          />
        </NvFlex>
      </PropertyPanelSimpleSection>
      <NvField name="errorHandlingStrategy" component={<ErrorHandlingStrategyPanel />} />
    </NvFlex>
  );
};
