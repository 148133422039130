import { useFetchProfile, useFetchWorkspaceList } from '@novaera/actioner-service';
import { LAST_ACTIVE_WORKSPACE, ROUTE_DEFAULTS } from '@novaera/constants';
import { useLocation, useNavigate, useQueryParams } from '@novaera/route';
import { setAccessToken } from '@novaera/service';
import { assert } from '@novaera/utils';
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';
import { checkWorkspaceLength } from '../../common/utils';
import { useGetAccessTokenFromOauth2 } from '../../services/use-oauth2';
import { GetAccessTokenFromOauth2Params } from '../../services/use-oauth2/types';
import { useIdentityProvider } from '../use-identity-provider';

export const useSocialLogin = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { getSearchParams } = useQueryParams();

  const queryParams = getSearchParams<GetAccessTokenFromOauth2Params & { error: string }>();
  const { redirectToLastState, redirectToLogin } = useIdentityProvider();
  const { data, isError } = useGetAccessTokenFromOauth2({ code: queryParams?.code, provider: queryParams?.provider });

  const { data: userData } = useFetchProfile({ enabled: !!data });
  const { data: workspaceListData } = useFetchWorkspaceList(!!data);

  const username = useMemo(() => userData?.email.split('@')[0] || '', [userData?.email]);

  const { willRedirectToDashboard, shouldShowWorkSpaceList } = useMemo(() => {
    if (data?.accessToken) {
      const willRedirectToDashboard =
        checkWorkspaceLength(workspaceListData?.workspaces.length ?? 0) || Cookies.get(LAST_ACTIVE_WORKSPACE);
      return { willRedirectToDashboard, shouldShowWorkSpaceList: !state && !willRedirectToDashboard };
    } else return { willRedirectToDashboard: false, shouldShowWorkSpaceList: false };
  }, [data, state, workspaceListData?.workspaces.length]);

  useEffect(() => {
    if (queryParams?.error) {
      assert(
        queryParams.error === 'access_denied',
        new Error(`[useSocialLogin] Unexpected error "${queryParams.error}" on social login.`),
        'ERROR'
      );
      redirectToLogin();
      return;
    }

    if (data) {
      try {
        if (data.redirectUri) {
          window.location.href = data.redirectUri;
          return;
        }
        setAccessToken(data.accessToken);
        // redirect to last state to let user to continue from where s/he left
        if (state) {
          redirectToLastState();
        }

        if (willRedirectToDashboard) {
          navigate(ROUTE_DEFAULTS.REDIRECTING_TO_DASHBOARD);
        }
      } catch (error) {
        redirectToLogin();
      }
    } else {
      if (isError) {
        redirectToLogin();
      }
    }
  }, [
    data,
    isError,
    navigate,
    queryParams?.error,
    redirectToLastState,
    redirectToLogin,
    state,
    willRedirectToDashboard,
  ]);

  return {
    shouldShowWorkSpaceList,
    username,
  };
};
