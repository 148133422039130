import { FormProps, NvDialogModal } from '@novaera/core';
import { FormApi } from 'final-form';
import React from 'react';
import { Header } from '../header';
import { HeaderProps } from '../header/types';
import { TrackFormStateProps } from './track-form-state/types';

export type DEFAULT_ACTION_BUTTON_TYPES = 'delete';

export enum DETAIL_LAYOUT_HEADER_ICON {
  connection = 'connection',
  eventRule = 'eventRule',
}

export type HeaderIconType = keyof typeof DETAIL_LAYOUT_HEADER_ICON;

export type DetailLayoutFormProps<FormValue> = Omit<FormProps<FormValue>, 'onSubmit'>;

export type DetailLayoutProps<LayoutFormValue, ModalFormValue> = {
  headerProps: Omit<React.ComponentPropsWithoutRef<typeof Header>, 'actions' | 'icon'> & {
    actions?: HeaderProps['actions'];
    icon: HeaderIconType | React.ReactElement;
    enableEdit?: boolean;
    onDiscard?: (formApi: FormApi<LayoutFormValue, Partial<LayoutFormValue>>) => void;
  };
  children: React.ReactNode;
  formProps?: DetailLayoutFormProps<LayoutFormValue>;
  useArrayMutators?: boolean;
  editModalProps: {
    title: string;
    isLoading?: boolean;
    modalBody: React.ReactNode;
    // when you want to use layout's form, set this one true, otherwise, you can implement your own form in your modal
    useLayoutForm?: boolean;
    onSubmit: (values: ModalFormValue) => void;
  } & { formProps?: DetailLayoutFormProps<ModalFormValue> } & Partial<
      Omit<React.ComponentPropsWithoutRef<typeof NvDialogModal>, 'onSubmit'>
    >;
  onFormSubmit: (value: LayoutFormValue, formApi: FormApi<LayoutFormValue, Partial<LayoutFormValue>>) => void;
  onActionButtonClicked?: (type: DEFAULT_ACTION_BUTTON_TYPES, value: LayoutFormValue) => void;
  isLoading?: boolean;
  LoadingSkeleton?: React.ReactNode;
} & Partial<Pick<TrackFormStateProps, 'onFormStateChanged'>>;
