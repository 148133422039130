import { ActionerEventUsage } from '@novaera/actioner-service';
import {
  Initial,
  NvArrowForwardIcon,
  NvCustomEmptyIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvRouterLink,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { USER_APP_WORKFLOW } from '../../../constants';
import { EmptyState, EventListItem } from './styled';

export const EventUsageList = ({
  data,
  isLoading,
  emptyStateContent,
}: {
  data?: ActionerEventUsage[];
  isLoading: boolean;
  emptyStateContent: string;
}) => {
  const theme = useTheme();

  const getWorkflowLink = (appId: string, workflowId: string) =>
    `${USER_APP_WORKFLOW({
      userAppId: appId,
      workflowId: workflowId,
    })}/designer`;

  return (
    <NvFlex direction={'column'} gap={'8px'} marginTop={'16px'}>
      {isLoading && <NvSkeleton width={'100%'} height={'24px'} variant="rectangular" />}
      {!isLoading && (!data || data?.length === 0) && (
        <EmptyState>
          <NvCustomEmptyIcon sx={{ width: '20px', height: '20px', color: theme.palette.nv_neutral_alpha[300] }} />{' '}
          <NvTypography variant="body2" textColor="subtle">
            {emptyStateContent}
          </NvTypography>
        </EmptyState>
      )}
      {!isLoading &&
        data?.map((eventUsage) => (
          <NvRouterLink
            key={`event_usage_${eventUsage.appId}_${eventUsage.workflowId}`}
            rel="noopener"
            target="_blank"
            to={getWorkflowLink(eventUsage.appId, eventUsage.workflowId)}
          >
            <EventListItem alignItems={'center'}>
              <NvFlex flex={'1 1 auto'} minWidth={0} alignItems={'center'} flexDirection={'row'} gap={'8px'}>
                <NvTypography variant="h5" noWrap>
                  {eventUsage.workflowName}
                </NvTypography>
                <NvDivider orientation="vertical" sx={{ height: '8px' }} />
                <NvFlex flex={'1 1 auto'} minWidth={0} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                  <NvImage
                    src={eventUsage.appLogo}
                    FallBack={
                      <Initial
                        size="smaller"
                        value={eventUsage?.appName || 'User app'}
                        color={theme.palette.nv_neutral[500]}
                      />
                    }
                    size="smaller"
                    imageShape="square"
                  />
                  <NvTypography textColor="secondary" variant="h5" noWrap>
                    {eventUsage.appName}
                  </NvTypography>
                </NvFlex>
              </NvFlex>
              <NvFlex
                flex={'0 0 auto'}
                alignItems={'center'}
                direction="row"
                gap="3px"
                className="go-to-workflow-button"
              >
                <NvTypography variant="h6" textColor="secondary">
                  Go to workflow
                </NvTypography>
                <NvArrowForwardIcon sx={{ width: '16px', height: '16px' }} />
              </NvFlex>
            </EventListItem>
          </NvRouterLink>
        ))}
    </NvFlex>
  );
};
