import { TabProps } from '@mui/material/Tab';
import React from 'react';
export enum iconPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
  END = 'end',
  START = 'start',
}

export type TypeOfTab = Omit<TabProps, 'content'> & {
  content: React.ReactNode;
  id?: string;
  TabItemWrapper?: React.ReactElement;
};
export interface NvTabsProps {
  defaultActiveTabIndex?: number;
  tabs: TypeOfTab[];
  CustomTabActionComponents?: React.ReactElement[];
  BodyWrapperComponent?: React.ReactElement;
  onActiveTabChanged?: (newActiveIndex: number) => void;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ActionComponent?: any;
  focusIndex?: number;
  onTabDragEnd?: (sourceIndex: number, destinationIndex: number) => void;
  variant?: 'small' | 'medium' | 'button';
}
