import { UserApp } from '@novaera/actioner-service';
import { Initial, NvConditionalRender, NvFlex, NvImage, NvRouterLink, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { ROUTES } from '../../../common/routes';
import { ManagedAppIcon } from '../../../components/managed-apps/icon';
import { CardWrapper } from './styled';

export const CardView: FC<React.PropsWithChildren<UserApp>> = ({ id, name, logoUrl, managed }) => {
  const { palette } = useTheme();
  return (
    <NvRouterLink to={`${ROUTES.UserApps}/${id}`}>
      <CardWrapper>
        <NvFlex flexDirection={'row'} gap={'8px'} alignItems={'center'} flex={'1 1 auto'} minWidth={'0'}>
          <NvImage
            FallBack={<Initial size="large" value={name} color={palette.nv_neutral[500]} />}
            src={logoUrl}
            imageShape="square"
            size="large"
            fallbackImageHasBlurEffect
          />
          <NvTypography variant="h4" noWrap>
            {name}
          </NvTypography>
        </NvFlex>
        <NvConditionalRender when={Boolean(managed)}>
          <ManagedAppIcon iconTheme="dark" />
        </NvConditionalRender>
      </CardWrapper>
    </NvRouterLink>
  );
};
