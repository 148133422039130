import { useFetchProfile } from '@novaera/actioner-service';
import { AccountCircleIcon, ImageSize, NvChevronRightIcon, NvImage } from '@novaera/core';
import React from 'react';
import { MenuItem } from '../menu-item';
import { MenuItemProps } from '../menu-item/types';
import { ProfilePopupMenuComponent } from './popup-menu-component';
import { ProfileMenuItemWrapper, ProfileMenuPopupMenuDropdown } from './styled';

export const ProfileMenuItem: React.FC<{
  onClick?: () => void;
  MenuItemWrapperComponent?: MenuItemProps['MenuItemWrapperComponent'];
  hasIndicator?: boolean;
  profileImageSize?: ImageSize;
  showName?: boolean;
}> = ({
  onClick,
  MenuItemWrapperComponent = ProfileMenuItemWrapper,
  hasIndicator = true,
  profileImageSize = 'medium',
  showName = true,
}) => {
  const { data, isLoading } = useFetchProfile();

  return (
    <MenuItem
      type="popup"
      key="profile_menu"
      id="profile_menu"
      icon={
        <NvImage
          src={data?.logoUrl}
          FallBack={<AccountCircleIcon sx={{ width: '32px', height: '32px' }} />}
          size={profileImageSize}
        />
      }
      {...(showName && {
        labelProps: { type: 'typography', labelText: data?.name },
      })}
      indicator={hasIndicator && <NvChevronRightIcon className="hidden-item" />}
      isLoading={isLoading}
      PopupMenuComponent={<ProfilePopupMenuComponent profile={data} />}
      PopupMenuDropdown={ProfileMenuPopupMenuDropdown}
      onClick={onClick}
      MenuItemWrapperComponent={MenuItemWrapperComponent}
    />
  );
};
