import { styled } from '@novaera/theme-provider';
import { Controls } from '@reactflow/controls';
import { NvBox } from '../../../ui/box';
import { NvButton } from '../../../ui/button';

export const ControlsWithoutShadow = styled(Controls)`
  &.react-flow__controls {
    box-shadow: none;
  }
`;

export const CustomControlsWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 5px 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e200};
  border-radius: 16px;
`;

export const CustomControlButton = styled(NvButton)`
  &.MuiButtonBase-root {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
    ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
    border-radius: 12px;
    transition: background-color 200ms ease;
  }
`;
