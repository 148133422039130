import {
  AssistantTransferType,
  FileNode,
  IntegrationTransferType,
  TransferFileAssistantAction,
  TransferFileIntegrationAction,
  ValueTypes,
  isFileNodeSummary,
  isTransferFileAssistantNodeAction,
  isTransferFileOperation,
  isTransferTypeAssistant,
  useGetIntegrationAction,
  useGetTransferTypes,
  useUpdateNode,
} from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvField,
  NvFlex,
  NvForm,
  NvNorthEastIcon,
  NvSmartToyIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { OutputItem } from '../../../../../../../../components/output-item';
import { useWorkflowNodeUtilities } from '../../../../../../../../components/property-panel/utils';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { RowItemActionSelection } from '../../common/row-item-action-selection';
import { NodeListItemType, NodePropertiesProps } from './types';

export const NodeProperties: FC<NodePropertiesProps> = ({ onCloseClicked, node }) => {
  assert(
    !!node,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  const { userAppId, workflowId } = useGetWorkflowQueryParams();

  const { updateNode } = useNovaeraFlow(userAppGraph);

  const { hasEditPermission } = useWorkflowPermission();

  const { mutate: updateNodeDetail } = useUpdateNode();

  const [keyword, setKeyword] = useState<string | undefined>();

  const theme = useTheme();

  const { data: transferTypesResult, isLoading } = useGetTransferTypes({
    appId: userAppId,
    queryParams: {
      keyword: keyword,
    },
  });

  const actionNodeListItems = useMemo(() => {
    const result: NodeListItemType[] =
      transferTypesResult?.pages.flatMap((page) =>
        page.types.map<NodeListItemType>((type) =>
          isTransferTypeAssistant(type)
            ? {
                type: type.type,
                name: type.name,
                icon: <NvSmartToyIcon htmlColor={theme.palette.nv_node.ai} />,
                extraData: {
                  ...type,
                },
              }
            : {
                id: type.id,
                name: type.name,
                type: type.type,
                icon: type.logoUrl,
                extraData: {
                  ...type,
                },
              }
        )
      ) ?? [];
    return result;
  }, [theme.palette.nv_node.ai, transferTypesResult?.pages]);

  const getIntegrationParams = useMemo(() => {
    if (!isFileNodeSummary(node)) {
      return;
    }

    if (!isTransferFileOperation(node.nodeOperation)) {
      return;
    }

    const { action } = node.nodeOperation;

    if (!action) {
      return;
    }

    if (isTransferFileAssistantNodeAction(action)) {
      return;
    }

    const { actionId, integrationId, version } = action;

    return {
      actionId,
      integrationId,
      version,
    };
  }, [node]);

  const { data: integrationAction } = useGetIntegrationAction({
    ...getIntegrationParams,
  });

  const { getWorkflowNodeIcon } = useWorkflowNodeUtilities();

  const handleSave = useCallback(
    (newNode: FileNode) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail({ appId: userAppId, workflowId, nodeAlias: node.alias, payload: newNode });
    },
    [hasEditPermission, updateNodeDetail, userAppId, workflowId, node.alias]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: node.alias,
          payload: { ...node, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(node.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [node, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvFlex width="100%">
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={getWorkflowNodeIcon({ type: NodeType.TRANSFER_FILE })}
          title={node?.name}
          type={node.nodeOperation.type as NodeType}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={node?.name}
          type={NodeType.FILE}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>

      <NvForm
        initialValues={node}
        onSubmit={noop}
        onChange={({ values }) => {
          handleSave(values);
        }}
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        <NvConditionalRender when={!hasEditPermission}>
          <PropertyPanelHeader
            title={node?.name}
            type={NodeType.FILE}
            actions={
              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            }
          />
        </NvConditionalRender>

        <PropertyPanelSection title="Transfer to" icon={<NvNorthEastIcon />}>
          <NvField<TransferFileAssistantAction | TransferFileIntegrationAction | undefined>
            name={'nodeOperation.action'}
            formControlStyle={{
              width: '100%',
            }}
            component={({ onChange, value }) => {
              return (
                <RowItemActionSelection<
                  AssistantTransferType | IntegrationTransferType | undefined,
                  TransferFileAssistantAction | TransferFileIntegrationAction
                >
                  key={`row-item-action-selection-1`}
                  onChange={onChange}
                  onItemSelected={(node) => {
                    if (!node) {
                      onChange(undefined);
                      return;
                    }
                    if (isTransferTypeAssistant(node)) {
                      onChange({
                        fileKey: {
                          type: ValueTypes.STRING,
                          value: '',
                        },
                        type: 'assistant',
                      });
                    } else {
                      onChange({
                        ...((value ?? {}) as TransferFileIntegrationAction),
                        integrationId: node.id,
                        version: node.version ?? 1,
                      });
                    }
                  }}
                  value={value}
                  actionTag="upload"
                  actionNodeListItems={actionNodeListItems}
                  onSearchActionNodeListItems={(keyword) => {
                    setKeyword(keyword);
                  }}
                  isActionsLoading={isLoading}
                />
              );
            }}
          />
        </PropertyPanelSection>

        <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <OutputItem
            content={
              integrationAction?.responseConfiguration?.exampleResponse
                ? JSON.parse(integrationAction?.responseConfiguration?.exampleResponse)
                : {}
            }
            isTestResultFailed={false}
          />
        </PropertyPanelSimpleSection>

        <NvField name="errorHandlingStrategy" component={<ErrorHandlingStrategyPanel />} />
      </NvForm>
    </NvFlex>
  );
};
