import { CronUnits } from './types';

export const CRON_INPUT_FIELDS = [
  { key: CronUnits.MINUTE, label: 'Minutes' },
  { key: CronUnits.HOUR, label: 'Hours' },
  { key: CronUnits.DAY_OF_MONTH, label: 'Day of month', hint: 'Enter days: 1, 2, 3, etc.' },
  { key: CronUnits.MONTH, label: 'Month' },
  { key: CronUnits.DAY_OF_WEEK, label: 'Day of week', hint: 'Enter days: MON, TUE, WED, etc.' },
  { key: CronUnits.YEAR, label: 'Year' },
];
