import { IntegrationAction, ParameterTypes, SimpleParameterMapping, ValueTypes } from '@novaera/actioner-service';
import { useMemo } from 'react';

export const useAppIdParameterMapping = ({ action, userAppId }: { action?: IntegrationAction; userAppId?: string }) => {
  const appIdParameterMappings = useMemo(() => {
    const appIdInputParameterId = action?.inputParameters?.find(({ name }) => name === 'appId')?.id;
    if (appIdInputParameterId && userAppId) {
      const appIdSimpleParameterMapping: SimpleParameterMapping = {
        type: ParameterTypes.SIMPLE,
        parameterId: appIdInputParameterId,
        value: { type: ValueTypes.STRING, value: userAppId },
      };
      return [appIdSimpleParameterMapping];
    } else {
      return [];
    }
  }, [action?.inputParameters, userAppId]);

  return { appIdParameterMappings };
};
