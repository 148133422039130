import { isRequired, NvField, NvFlex, NvTextField, NvTypography, useForm, useFormState } from '@novaera/core';
import { MemoizedFileUpload } from '../../../../../../../components/file-upload';
import { FileType } from '../../../../../../../components/file-upload/types';

export const ImageContent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { change } = useForm();
  const { values } = useFormState();

  return (
    <NvFlex gap="16px">
      <NvFlex gap="8px">
        <NvTypography variant="body1">You can upload images up to 10 MB.</NvTypography>

        <NvFlex width="100%" alignItems="flex-start">
          <MemoizedFileUpload
            onFileUploaded={({ objectKey, name }) => {
              const updatedFileReference = { ...values.image.fileReference, objectKey, fileName: name };
              change('image.fileReference', updatedFileReference);
            }}
            file={{
              name: values.image.fileReference.fileName,
              objectKey: values.image.fileReference.objectKey,
            }}
            typeRestrictions={[FileType.IMAGE]}
          />
          <NvField
            name="image.fileReference.objectKey"
            // eslint-disable-next-line react/jsx-no-useless-fragment
            component={<></>}
            validators={[isRequired()]}
            showErrorMessageOnlyWhenBlur
          />
        </NvFlex>
      </NvFlex>
      <NvField
        labelText="Image label"
        direction="label-on-top"
        validators={[isRequired()]}
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        component={<NvTextField />}
        name="image.displayText"
      />
    </NvFlex>
  );
};
