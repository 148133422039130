import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const EmptyStateContainer = styled(NvFlex)`
  padding: 30px 24px 24px 24px;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const EmptyStateBody = styled(NvFlex)`
  align-items: center;
  gap: 16px;
`;

export const EmptyStateBodyContent = styled(NvFlex)`
  align-items: center;
`;
