import { assert } from '@novaera/utils';
import { NvTypography } from '../typography';
import { SwitchButton, SwitchButtonGroup } from './styled';
import { NvSwitchButtonProps } from './types';

export const NvSwitchButton = <OptionsType,>({
  value,
  onChange,
  options,
  className,
}: NvSwitchButtonProps<OptionsType>) => (
  <SwitchButtonGroup
    value={value}
    exclusive
    onChange={(_event, newValue) => {
      if (newValue) {
        onChange(newValue);
      }
    }}
    className={className}
  >
    {options.map((option) => {
      assert(!!option.value, new Error('SwitchButton option must have a value'));

      return (
        <SwitchButton key={`switch-button-${option.value}`} value={option.value} disableRipple>
          <NvTypography variant="h5"> {option.label}</NvTypography>
        </SwitchButton>
      );
    })}
  </SwitchButtonGroup>
);
