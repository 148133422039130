import { NvBox, NvFlex, NvMenuItem } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';

export const WorkspaceList = styled(NvFlex)`
  gap: 6px;
`;

export const WorkspaceMenuItem = styled(NvMenuItem, {
  shouldForwardProp: shouldForwardPropForFormFields,
})<{ isSelected?: boolean }>`
  &.MuiMenuItem-root {
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.4s ease, border-color 0.5s ease, box-shadow 0.6s ease;

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    }

    ${({ isSelected, theme }) =>
      isSelected
        ? `background-color: ${theme.palette.nv_main[20]}; 
           margin:-1px; 
            &:hover {
            background-color: ${theme.palette.nv_main[20]};
          }`
        : ''}
  }
`;

export const AddWorkspaceButtonBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  }
`;
