import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SetupWorkflowWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 12px 12px 8px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;

  &.success {
    background-color: ${({ theme }) => theme.palette.nv_success[10]};
    border: 1px solid ${({ theme }) => theme.palette.nv_success[20]};
  }
`;

export const OrderBox = styled(NvBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  width: 24px;
  height: 20px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 10px;
  flex: 0 0 auto;
`;

export const RunCompletedBox = styled(NvBox)`
  padding: 0px 6px 0px 8px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.nv_success[20]};
  border-radius: 6px;
`;

export const ErrorReasonCard = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 12px;
`;
