import { useForm, useFormState } from '@novaera/core';
import { WorkflowSelectComponent } from '../../../common/workflow-select-component';

import { get } from 'lodash';
import { useMemo } from 'react';
import { WorkflowSelectMultipleAppValue } from '../../../common/workflow-select-component/types';

export const MultipleAppWorkflowSelection = ({
  staticFieldWorkflowIdFieldName,
  staticFieldAppIdFieldName,
  staticFieldDependencyIdFieldName,
  typeFieldName,
}: {
  staticFieldWorkflowIdFieldName: string;
  staticFieldAppIdFieldName: string;
  staticFieldDependencyIdFieldName: string;
  typeFieldName: string;
}) => {
  const form = useForm();
  const { values } = useFormState();

  const value: WorkflowSelectMultipleAppValue = useMemo(() => {
    return get(values, staticFieldDependencyIdFieldName)
      ? {
          dependencyId: get(values, staticFieldDependencyIdFieldName),
          workflowId: get(values, staticFieldWorkflowIdFieldName),
          type: get(values, typeFieldName),
        }
      : {
          appId: get(values, staticFieldAppIdFieldName),
          workflowId: get(values, staticFieldWorkflowIdFieldName),
          type: get(values, typeFieldName),
        };
  }, [
    values,
    staticFieldDependencyIdFieldName,
    staticFieldWorkflowIdFieldName,
    typeFieldName,
    staticFieldAppIdFieldName,
  ]);

  return (
    <WorkflowSelectComponent
      onChange={(value) => {
        form.batch(() => {
          form.change(staticFieldAppIdFieldName, value?.appId);
          form.change(staticFieldDependencyIdFieldName, value?.dependencyId);
          form.change(staticFieldWorkflowIdFieldName, value?.workflowId);
          form.change(typeFieldName, value?.type);
        });
      }}
      value={value}
      hasMultipleAppSupport
      searchParamsTriggerTypes={['form', 'genericWebhook']}
      size="small"
    />
  );
};
