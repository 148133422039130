import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const DraggableItem = styled(NvFlex, {
  shouldForwardProp: (prop) => prop !== 'isDragging',
})<{
  isDragging: boolean;
}>`
  flex-direction: row;
  gap: 8px;
  padding: 4px;
  ${({ isDragging, theme }) => (isDragging ? `${theme.elevations.e300};` : null)}
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 6px;
  margin: 2px 0;
`;

export const DraggablePlaceholder = styled('div')`
  position: absolute;
  padding: 4px;
`;

export const DraggablePlaceholderBorderedBox = styled('div')`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.nv_main[10]};
  opacity: 0.5;
  border: 2px dashed ${({ theme }) => theme.palette.nv_main[40]};
  border-radius: 6px;
`;
