import { NvAxios, useMutation } from '@novaera/core';
import { CATALOG_ENTITY_TYPES_ROOT_PATH } from '../constants';

import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_CATALOG_ENTITY_TYPES } from '../keys';
import { CatalogEntityType } from '../types';
import { useUpdateCatalogEntityCache } from '../use-update-catalog-entity-cache';
import { UpdateEntityTypeParams } from './types';

const updateCatalogEntityType: (params: UpdateEntityTypeParams) => Promise<CatalogEntityType> = async (params) => {
  const result = await NvAxios.put(`${CATALOG_ENTITY_TYPES_ROOT_PATH}/${params.id}`, params.payload.entityType);
  return result?.data;
};

export const useUpdateCatalogEntityType = () => {
  const { updateInfiniteSearchCache } = useUpdateCatalogEntityCache();
  const client = useQueryClient();

  return useMutation(updateCatalogEntityType, {
    onSuccess: (data) => {
      client.setQueryData<CatalogEntityType>(QUERY_KEY_CATALOG_ENTITY_TYPES.detail(data.id), (old) => {
        return data;
      });

      updateInfiniteSearchCache({
        updateFunction: ({ old }) => {
          return (
            old?.pages?.map(({ entityTypes, ...others }) => ({
              ...others,
              entityTypes: entityTypes?.map((entityType) => (entityType.id === data.id ? data : entityType)),
            })) ?? [
              {
                entityTypes: [data],
              },
            ]
          );
        },
      });
    },
  });
};
