import { Theme } from '@mui/system';
import { styled } from '@novaera/theme-provider';
import { NvBox } from '../../box';
import { StepLabelState, StepLabelVariant } from './types';

const getColor = ({ state, theme }: { state: StepLabelState; theme: Theme }) =>
  state === StepLabelState.COMPLETED
    ? theme.palette.nv_neutral[600]
    : state === StepLabelState.NOT_COMPLETED
    ? theme.palette.nv_neutral[30]
    : theme.palette.nv_main[40];

const getHoverColor = ({ state, theme }: { state: StepLabelState; theme: Theme }) =>
  state === StepLabelState.COMPLETED
    ? theme.palette.nv_neutral[700]
    : state === StepLabelState.NOT_COMPLETED
    ? theme.palette.nv_neutral[30]
    : theme.palette.nv_main[40];

export const StepLabelContainer = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'stepLength' })<{
  index: number;
  stepLength: number;
  state: StepLabelState;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ index }) => (index > 0 ? 'margin-left:-16px' : null)};
  ${({ index, stepLength }) => `z-index: ${stepLength - index}`};
  ${({ state }) => (state === StepLabelState.COMPLETED ? 'cursor: pointer' : null)};

  &:hover {
    .step-label-center-box {
      background-color: ${getHoverColor};
    }

    .step-label-start-intersect {
      rect {
        transition: fill 300ms ease;
        fill: ${getHoverColor};
      }
    }
    .step-label-end-intersect {
      path {
        transition: fill 300ms ease;
        fill: ${getHoverColor};
      }
    }
  }

  .step-label-center-box {
    transition: background-color 300ms ease;
    background-color: ${getColor};
  }

  .step-label-start-intersect {
    rect {
      transition: fill 300ms ease;
      fill: ${getColor};
    }
  }
  .step-label-end-intersect {
    path {
      transition: fill 300ms ease;
      fill: ${getColor};
    }
  }
`;

export const StepLabelCenterBox = styled(NvBox)<{ variant: StepLabelVariant; state: StepLabelState }>`
  flex: 1 1 auto;
  height: 40px;

  padding: ${({ variant }) =>
    variant === StepLabelVariant.FIRST
      ? '11px 8px 11px 24px'
      : variant === StepLabelVariant.END
      ? '11px 24px 11px 20px'
      : '11px 8px 11px 20px'};
  border-radius: ${({ variant }) =>
    variant === StepLabelVariant.FIRST ? '6px 0 0 6px' : variant === StepLabelVariant.END ? '0 6px 6px 0' : '0'};
`;
