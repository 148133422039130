import { VariableSchema } from '@novaera/actioner-service';
import { NvDialogModal } from '@novaera/core';

import { RequestVariableEditParams } from '../types';
import { VariablesModalBody } from './body';
import { useVariablesModalController } from './controllers/use-variables-modal';

export const VariablesModal = ({
  isOpened,
  onModalClosed,
  passedParams,
}: {
  isOpened: boolean;
  onModalClosed: () => void;
  passedParams?: RequestVariableEditParams;
}) => {
  const { onFormSubmit, modalProps, variableSchema, baseUrl } = useVariablesModalController({
    onModalClosed,
    passedParams,
  });

  return (
    <NvDialogModal<VariableSchema>
      maxWidth="sm"
      fullWidth
      onCloseButtonClicked={onModalClosed}
      open={isOpened}
      {...modalProps}
      Body={<VariablesModalBody baseUrl={baseUrl} />}
      formProps={{
        initialValues: variableSchema,
      }}
      onFormSubmit={onFormSubmit}
    />
  );
};
