import { blue } from '@mui/material/colors';
import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Winter, ThemeMode.Dark);

export default {
  primary: {
    main: '#31D724',
  },
  secondary: {
    main: '#A3D787',
  },
  error: {
    main: blue.A100,
  },
  test: {
    value: 'testWinterDark',
  },
  ...colors,
};
