import { useCreateIntegrationAction } from '@novaera/actioner-service';
import { useNavigate, useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { INTEGRATION_ACTION_DETAIL } from '../../../constants';
import { CreateIntegrationActionModalValues } from '../types';

export const useCreateActionModal = ({ onClose }: { onClose: () => void }) => {
  const { integrationId } = useParams();
  const { mutate: createAction, isLoading: isCreatingAction } = useCreateIntegrationAction();

  const navigate = useNavigate();

  const handleOnSubmit = (values: CreateIntegrationActionModalValues) => {
    assert(!!integrationId, new Error(`currentIntegrationId:${integrationId} is undefined`), 'WARNING');

    return new Promise<void>((resolve) => {
      return createAction(
        { ...values, integrationId, version: 1, enabled: true },
        {
          onSuccess: (response) => {
            resolve();
            onClose();
            navigate(INTEGRATION_ACTION_DETAIL(integrationId, response.id));
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return {
    isLoading: isCreatingAction,
    onSubmit: handleOnSubmit,
  };
};
