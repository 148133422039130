import { useGetBilling } from '@novaera/actioner-service';
import { FC } from 'react';
import { BillingLoading } from './billing-loading';
import { FreeWorkspaceLayout } from './free-workspace-layout';
import { NonFreeWorkspaceLayout } from './non-free-workspace-layout';

export const Billing: FC = () => {
  const { data: billing, isLoading: isBillingLoading } = useGetBilling();

  return isBillingLoading ? (
    <BillingLoading />
  ) : billing?.currentSubscriptionDetails.planType === 'free' ||
    billing?.currentSubscriptionDetails.planType === 'trial' ? (
    <FreeWorkspaceLayout billingDetail={billing} />
  ) : billing ? (
    <NonFreeWorkspaceLayout billingDetail={billing} />
  ) : null;
};
