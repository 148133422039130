import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { AddButton } from '../../../../../components/button/add';

export const Container = styled(NvFlex)`
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 8px;
  gap: 4px;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.nv_neutral[20]};

  .chevron-icon {
    width: 20px;
    height: 20px;
    transition: transform 200ms ease-in-out;
    cursor: pointer;
  }

  &.is-open {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }

  &.collapse-mode {
    padding: 10px 4px;
  }
`;

export const AddConfigAttachmentButton = styled(AddButton)`
  &.MuiButtonBase-root {
    &.MuiButton-containedSecondary {
      &.is-open {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
      }

      &:hover {
        &.is-open {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
        }
      }
    }
  }
`;

export const AttachmentCard = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 7px 8px;
  gap: 4px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
`;
