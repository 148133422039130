import { AccessType, GroupEntity } from '@novaera/actioner-service';
import {
  EditButton,
  InlineEditWrapper,
  NvAddBoxIcon,
  NvArrowBackIcon,
  NvBox,
  NvButton,
  NvChip,
  NvCloseIcon,
  NvConditionalWrap,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvDivider,
  NvEditIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvToggleOffIcon,
  NvToggleOnIcon,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { isUndefined } from 'lodash';
import { DetailLayoutBody } from '../../../../components/detail-layout/body';
import { UserAndGroupImage } from '../../../../components/user-and-group-image';
import { GroupOrPermissionEmptyState } from '../../components/group-or-permission-empty-state';
import { GroupOrPermissionPolicyCard } from '../../components/group-or-permission-policy-card';
import { TagAndDescriptionEmptyState } from '../../components/tag-and-description-empty-state';
import { CreateOrUpdatePermissionPolicyModal } from '../create-or-update-permission-policy-modal';
import { AddNewPermissionButton } from './add-new-permission-button';
import { AttachGroupModalBody } from './attach-group-modal-body';
import { usePermissionPolicyDetailController } from './controllers';
import { PermissionPolicyDetailLoading } from './loading';
import { PermissionDefinitionRow, PermissionDefinitionsCard } from './styled';

export const PermissionPolicyDetail = () => {
  const theme = useTheme();
  const {
    permissionPolicy,
    isAdminPermissionPolicy,
    isPermissionPolicyLoading,
    isSavePermissionLoading,
    isDeletePermissionFromPolicyLoading,
    isUpdatePermissionPolicyModalOpened,
    onUpdatePermissionPolicyModalOpenClicked,
    onUpdatePermissionPolicyModalCloseClicked,
    permissionInProgressIndex,
    setPermissionInProgressIndex,
    isAttachGroupModalOpen,
    onAttachGroupModalCloseClicked,
    onAttachGroupModalOpenClicked,
    handleGoBack,
    handleDeletePermissionPolicy,
    handleUpdatePermissionPolicySubmit,
    handleDeleteGroupFromPolicy,
    handleAttachGroup,
    handleSavePermission,
    handleDeletePermissionFormPolicy,
  } = usePermissionPolicyDetailController();

  return (
    <DetailLayoutBody>
      <NvFlex marginTop={'24px'} flexDirection="column" gap="12px">
        <NvBox>
          <NvButton color="secondary" startIcon={<NvArrowBackIcon />} onClick={handleGoBack} size="small">
            Back
          </NvButton>
        </NvBox>
        {!isPermissionPolicyLoading && permissionPolicy && (
          <>
            <NvFlex gap="4px">
              <NvFlex direction="row" gap="12px" alignItems="flex-start" justifyContent="space-between">
                <NvConditionalWrap
                  condition={!isAdminPermissionPolicy}
                  wrap={(children) => (
                    <InlineEditWrapper className="view-mode" sx={{ width: 'auto' }}>
                      <NvFlex flexDirection="row" className="view-wrapper">
                        <NvFlex flex="1 1 auto" minWidth="0">
                          {children}
                        </NvFlex>

                        <EditButton
                          className="edit-button"
                          color="ghost"
                          size="small"
                          onlyIcon
                          onClick={onUpdatePermissionPolicyModalOpenClicked}
                        >
                          <NvEditIcon />
                        </EditButton>
                      </NvFlex>
                    </InlineEditWrapper>
                  )}
                >
                  <NvFlex flexDirection="row" gap="12px" alignItems="center" flex="0 1 auto">
                    <NvTypography variant="h1">{permissionPolicy.name}</NvTypography>
                  </NvFlex>
                </NvConditionalWrap>

                {!isAdminPermissionPolicy && (
                  <NvMenuWithItems
                    triggerButton={{
                      content: <NvMoreHorizIcon />,
                      props: { onlyIcon: true, size: 'medium', color: 'secondary' },
                    }}
                    menuItems={[
                      {
                        name: 'Delete',
                        onClick: () => {
                          handleDeletePermissionPolicy({ id: permissionPolicy.id, name: permissionPolicy.name });
                        },
                        icon: (
                          <NvDeleteOutlineIcon
                            htmlColor={theme.palette.nv_error[40]}
                            sx={{ width: '16px', height: '16px' }}
                          />
                        ),
                      },
                    ]}
                  />
                )}
              </NvFlex>

              <NvConditionalWrap
                condition={!isAdminPermissionPolicy}
                wrap={(children) => (
                  <InlineEditWrapper className="view-mode">
                    <NvFlex flexDirection="row" className="view-wrapper">
                      {children}
                      <EditButton
                        className="edit-button"
                        color="ghost"
                        size="small"
                        onlyIcon
                        onClick={onUpdatePermissionPolicyModalOpenClicked}
                      >
                        <NvEditIcon />
                      </EditButton>
                    </NvFlex>
                  </InlineEditWrapper>
                )}
              >
                <NvFlex flex="1 1 auto" minWidth="0" gap="8px">
                  {permissionPolicy.description || (permissionPolicy.tags && permissionPolicy.tags.length > 0) ? (
                    <>
                      {permissionPolicy.description ? (
                        <NvTypography variant="body1">{permissionPolicy.description}</NvTypography>
                      ) : (
                        <TagAndDescriptionEmptyState message="No descriptions available for this permission policy yet." />
                      )}

                      {permissionPolicy.tags && permissionPolicy.tags.length > 0 ? (
                        <NvFlex direction="row" alignItems="center" gap="4px" flexWrap="wrap">
                          {permissionPolicy.tags.map((tag, index) => (
                            <NvChip key={`${tag}-${index}`} label={tag} />
                          ))}
                        </NvFlex>
                      ) : (
                        <TagAndDescriptionEmptyState message="No tags available for this permission policy yet." />
                      )}
                    </>
                  ) : (
                    <TagAndDescriptionEmptyState message="No descriptions and tags available for this permission policy yet." />
                  )}
                </NvFlex>
              </NvConditionalWrap>
            </NvFlex>
            <NvFlex gap="8px" alignItems="flex-start">
              <NvTypography variant="h3">Groups</NvTypography>
              <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
                {permissionPolicy.groups.length === 0 ? (
                  <GroupOrPermissionEmptyState message={'No groups have been added for this permission.'} />
                ) : (
                  permissionPolicy.groups.map((group) => (
                    <GroupOrPermissionPolicyCard
                      key={group.id}
                      name={group.name}
                      logo={
                        <UserAndGroupImage src={group.logoUrl} type={AccessType.GROUP} id={group.id} size="smaller" />
                      }
                      {...(!isAdminPermissionPolicy
                        ? {
                            onDelete: () => {
                              handleDeleteGroupFromPolicy(group.id);
                            },
                          }
                        : {})}
                    />
                  ))
                )}
              </NvFlex>
              {!isAdminPermissionPolicy && (
                <NvButton
                  size="small"
                  color="secondary"
                  startIcon={<NvAddBoxIcon />}
                  onClick={onAttachGroupModalOpenClicked}
                >
                  Add group
                </NvButton>
              )}
            </NvFlex>
            <NvFlex gap="8px" alignItems="flex-start">
              <NvTypography variant="h3">Permissions</NvTypography>

              {permissionPolicy.permissions.length === 0 ? (
                <NvFlex gap="8px" width="100%" alignItems="flex-start">
                  <GroupOrPermissionEmptyState message={'No permission have been selected for this policy.'} />
                  {!isAdminPermissionPolicy && (
                    <AddNewPermissionButton
                      wrap
                      currentPermissions={permissionPolicy.permissions}
                      isLoading={isSavePermissionLoading}
                      handleAddNewPermission={handleSavePermission}
                    />
                  )}
                </NvFlex>
              ) : (
                <PermissionDefinitionsCard>
                  {permissionPolicy.permissions.map((p, index) => {
                    const saveIsInProgress =
                      isSavePermissionLoading &&
                      !isUndefined(permissionInProgressIndex) &&
                      permissionInProgressIndex === index;
                    const deleteIsInProgress =
                      isDeletePermissionFromPolicyLoading &&
                      !isUndefined(permissionInProgressIndex) &&
                      permissionInProgressIndex === index;
                    return (
                      <PermissionDefinitionRow key={p.definition.id}>
                        <NvFlex direction="row" gap="8px" alignItems="center" justifyContent="space-between">
                          <NvTypography variant="body2">{p.definition.display}</NvTypography>
                          <NvFlex direction="row" alignItems="center" gap="4px">
                            <NvButton
                              size="small"
                              color="ghost"
                              disabled={saveIsInProgress || isAdminPermissionPolicy}
                              loading={saveIsInProgress}
                              labelProps={{ variant: 'h5', textColor: 'secondary' }}
                              startIcon={
                                p.granted ? (
                                  <NvToggleOnIcon htmlColor={theme.palette.nv_success[60]} />
                                ) : (
                                  <NvToggleOffIcon htmlColor={theme.palette.nv_error[40]} />
                                )
                              }
                              onClick={() => {
                                setPermissionInProgressIndex(index);
                                handleSavePermission({ permission: { ...p, granted: !p.granted } });
                              }}
                            >
                              {p.granted ? 'Enabled' : 'Disabled'}
                            </NvButton>
                            {!isAdminPermissionPolicy && (
                              <>
                                <NvDivider sx={{ height: '12px' }} orientation="vertical" />
                                <NvButton
                                  onlyIcon
                                  size="small"
                                  color="ghost"
                                  disabled={deleteIsInProgress}
                                  loading={deleteIsInProgress}
                                  onClick={() => {
                                    setPermissionInProgressIndex(index);
                                    handleDeletePermissionFormPolicy(p.definition.id);
                                  }}
                                >
                                  <NvCloseIcon />
                                </NvButton>
                              </>
                            )}
                          </NvFlex>
                        </NvFlex>
                      </PermissionDefinitionRow>
                    );
                  })}
                  {!isAdminPermissionPolicy && (
                    <PermissionDefinitionRow alignItems="flex-start">
                      <AddNewPermissionButton
                        currentPermissions={permissionPolicy.permissions}
                        isLoading={isSavePermissionLoading}
                        handleAddNewPermission={handleSavePermission}
                      />
                    </PermissionDefinitionRow>
                  )}
                </PermissionDefinitionsCard>
              )}
            </NvFlex>
          </>
        )}
        {isPermissionPolicyLoading && <PermissionPolicyDetailLoading />}
      </NvFlex>
      {permissionPolicy && (
        <CreateOrUpdatePermissionPolicyModal
          formProps={{
            initialValues: {
              name: permissionPolicy.name,
              description: permissionPolicy.description,
              tags: permissionPolicy.tags,
            },
          }}
          onFormSubmit={handleUpdatePermissionPolicySubmit}
          open={isUpdatePermissionPolicyModalOpened}
          onCloseButtonClicked={onUpdatePermissionPolicyModalCloseClicked}
          Header="Edit permission policy"
          modalIcon="edit"
          primaryButtonText="Save"
        />
      )}
      {permissionPolicy && (
        <NvDialogModal<{ group: GroupEntity }>
          onCloseButtonClicked={onAttachGroupModalCloseClicked}
          maxWidth="sm"
          fullWidth
          Header={`Add group to ${permissionPolicy.name} permission policy`}
          open={isAttachGroupModalOpen}
          Body={<AttachGroupModalBody currentGroups={permissionPolicy.groups} />}
          onFormSubmit={handleAttachGroup}
        />
      )}
    </DetailLayoutBody>
  );
};
