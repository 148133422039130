import { NvFlex, NvTag, NvTypography } from '@novaera/core';
import { GLOBAL_SCHEMA_TITLE_TAG } from './constants';
import { GlobalSchemaDetailTitleProps } from './types';

export const GlobalSchemaDetailTitle = ({ connectionSchema }: GlobalSchemaDetailTitleProps) => {
  return (
    <NvFlex flexDirection="row" gap="8px">
      <NvTypography>{connectionSchema?.name}</NvTypography>
      {connectionSchema && <NvTag label={GLOBAL_SCHEMA_TITLE_TAG[connectionSchema?.authentication.type]} />}
    </NvFlex>
  );
};
