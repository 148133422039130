export const QUERY_KEYS_JOBS = {
  all: ['app-jobs'] as const,
  lists: () => [...QUERY_KEYS_JOBS.all] as const,
  list: (appId?: string, type?: string, timezoneId?: string) =>
    [
      ...QUERY_KEYS_JOBS.lists(),
      'list',
      ...(appId ? [appId] : []),
      ...(type ? [type] : []),
      ...(timezoneId ? [timezoneId] : []),
    ] as const,
  detail: (appId?: string, jobId?: string) =>
    [...QUERY_KEYS_JOBS.lists(), 'detail', ...(appId ? [appId] : []), ...(jobId ? [jobId] : [])] as const,
};
