import { DateTimePickerDefaultTextField, NvDateTimePicker, TimeInputMask, useDatePicker } from '@novaera/core';
import { ComponentHint } from '../common/adapter/component-hint';
import { DateTimePickerComponentParams } from '../use-ui-component/types';

export const DateTimePickerComponent = ({
  inputParameter: { uiComponent },
  inputProps,
  otherProps,
}: DateTimePickerComponentParams) => {
  const { hint } = uiComponent;
  const { error } = inputProps;
  const { isOpen, changeDatePickerOpen } = useDatePicker();

  return (
    <>
      <NvDateTimePicker
        {...inputProps}
        datePickerProps={{
          renderInput: (params) => (
            <DateTimePickerDefaultTextField
              {...params}
              error={!!error}
              onClick={() => changeDatePickerOpen((previousValue) => !previousValue)}
            />
          ),
          open: isOpen,
          onClose: () => changeDatePickerOpen(false),
        }}
        timePickerProps={{
          renderInput: (params) => (
            <DateTimePickerDefaultTextField
              {...params}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                inputComponent: TimeInputMask,
              }}
              {...otherProps}
            />
          ),
        }}
      />
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
