import { UserWorkflowExecutionResultResponse } from '@novaera/actioner-service';
import _ from 'lodash';

export const useWorkflowStateResult = ({ response }: { response?: UserWorkflowExecutionResultResponse | null }) => {
  return {
    state: response,
    isError: _.isError(response) || response?.status === 'failed' || response?.status === 'discarded',
    isSuccess: !_.isError(response) && response?.status === 'successful',
    inProgress: !_.isError(response) && response?.status === 'in_progress',
  };
};
