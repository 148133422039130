import { HeaderWithTabLayout, NvManageAccountsIcon } from '@novaera/core';

import { USER_MANAGEMENT_TABS } from './constants';

export const UserManagement = () => {
  return (
    <HeaderWithTabLayout
      title={{ label: 'User management', icon: <NvManageAccountsIcon /> }}
      tabs={USER_MANAGEMENT_TABS}
      variant="center-aligned"
    />
  );
};
