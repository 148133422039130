import { InputParameter, InputParameters, ParameterTypes, ValueTypes } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { InputFormValues } from '../../../../../action-designer/providers/input-values';

const getValueForInput = (inputParameter: InputParameter, inputFormValues?: InputFormValues) => {
  if (!inputFormValues) {
    return '';
  }

  const selectedInputValue = inputFormValues?.[inputParameter.id];

  assert(
    selectedInputValue.type === ParameterTypes.SIMPLE,
    new Error(
      `Type is: ${selectedInputValue?.type}, Only simple types can be configured in the designer, other types like slack blocks cannot be configured yet.`
    ),
    'ERROR'
  );

  const value = selectedInputValue.value;

  if (value?.type === ValueTypes.STRING) {
    return value.value;
  } else if (value?.type === ValueTypes.NUMBER) {
    return value.value;
  } else if (value?.type === ValueTypes.BOOLEAN) {
    return value.value;
  } else if (value?.type === ValueTypes.OBJECT) {
    return value.value;
  } else if (value?.type === ValueTypes.LIST) {
    return value.value?.map((v) => v.value);
  } else {
    return '';
  }
};

export const getInputContext = (inputParameters?: InputParameters, inputValues?: InputFormValues) => {
  if (!inputParameters) {
    return {};
  }
  const context: Record<string, unknown> = {};
  inputParameters.forEach((inputParameter) => {
    context[inputParameter.name] =
      inputValues?.[inputParameter.id]?.type === ParameterTypes.SIMPLE
        ? getValueForInput(inputParameter, inputValues)
        : '';
  });

  return context;
};
