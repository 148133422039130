import { NvButton, NvFileUploadButton, NvFlex, NvImage, NvTypography } from '@novaera/core';
import React from 'react';
import { useLogoUpload } from './controllers/use-logo-upload';
import { LogoUploadProps } from './types';

export const NvLogoUpload: React.FC<React.PropsWithChildren<LogoUploadProps>> = ({
  type,
  hasRemoveAction,
  imageShape,
  ImageFallBack,
  imageSize,
  logoUploadButtonProps,
  logoUploadHintProps,
  direction = 'column',
  initialLogoUrl,
  fallbackImageProps,
  onLogoStateChanged,
}) => {
  const { onLogoUpload, isUploadLoading, logoUrl, setImagePreviewUrl } = useLogoUpload({
    type,
    initialLogoUrl,
  });

  return (
    <NvFlex direction="row" spacing={direction === 'row' ? '12px' : '16px'} alignItems="center">
      <NvFlex>
        <NvImage
          size={imageSize}
          FallBack={ImageFallBack}
          imageShape={imageShape}
          src={logoUrl}
          hasNoElevation={true}
          fallbackImageHasBlurEffect={false}
          {...fallbackImageProps}
        />
      </NvFlex>
      <NvFlex
        direction={direction}
        spacing={direction === 'row' ? '12px' : '4px'}
        {...(direction === 'row' ? { alignItems: 'center' } : {})}
      >
        <NvFlex direction="row">
          <NvFileUploadButton
            loading={isUploadLoading}
            onFileSelected={async (file) => {
              const { logoId, imagePreviewUrl } = await onLogoUpload({
                file,
                type,
              });
              onLogoStateChanged?.({ logo: { id: logoId, operation: 'update' }, logoUrl: imagePreviewUrl });
            }}
            {...logoUploadButtonProps}
          />
          {hasRemoveAction && (
            <NvButton
              onClick={async () => {
                setImagePreviewUrl(undefined);
                onLogoStateChanged?.({ logo: { operation: 'delete' } });
              }}
              style={{
                marginLeft: '8px',
              }}
              color="ghost"
              size="small"
            >
              Remove
            </NvButton>
          )}
        </NvFlex>
        <NvFlex>
          <NvTypography textColor="subtle" variant="body2" {...logoUploadHintProps}>
            {logoUploadHintProps?.text ? logoUploadHintProps.text : 'Upload an image that is 280px square or larger.'}
          </NvTypography>
        </NvFlex>
      </NvFlex>
    </NvFlex>
  );
};
