import { NvBox, NvFlagger, NvFlaggerProps, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import { AppConfigFlaggerBody, AppConfigFlaggerCard, AppConfigFlaggerIconBox } from './styled';

export const AppConfigFlagger = ({
  icon,
  title,
  description,
  action,
  ...props
}: {
  icon: React.ReactElement;
  title: string;
  description?: string;
  action?: React.ReactElement;
} & NvFlaggerProps) => {
  assert(
    !description || !action,
    new Error('[AppConfigFlagger] - Both action and description props can not be used together.'),
    'ERROR'
  );
  return (
    <NvFlagger {...props} positionX="end" positionY="end" isAutoClose={false}>
      <AppConfigFlaggerCard>
        <AppConfigFlaggerIconBox>{icon}</AppConfigFlaggerIconBox>
        <AppConfigFlaggerBody>
          <NvTypography variant="h5">{title}</NvTypography>
          {description && <NvTypography variant="body3">{description}</NvTypography>}
          {action && <NvBox paddingTop="4px">{action}</NvBox>}
        </AppConfigFlaggerBody>
      </AppConfigFlaggerCard>
    </NvFlagger>
  );
};
