import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkspaceDeletionWarningWrapper = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  padding: 16px;
  border-radius: 12px;
  align-items: flex-start;
  gap: 8px;
`;
