import { NvButton } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { CardWrapper } from './card-view/styled';

export const AddNewAppInitiatorButton = styled(NvButton)`
  &.MuiButtonBase-root {
    &.MuiButton-containedGhost {
      &.is-open {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      }

      &:hover {
        &.is-open {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
        }
      }
    }

    &.MuiButton-containedSecondary {
      &.is-open {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
      }

      &:hover {
        &.is-open {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
        }
      }
    }
  }
`;

export const ShowMoreCardWrapper = styled(CardWrapper)`
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[5]};
`;
