import { GetDynamicInputParametersParams, GetOptionsParams } from './types';

export const QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION = {
  all: ['integration-action-step-execution'] as const,
  list: (integrationId: string, actionId: string) =>
    [...QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.all, integrationId, actionId] as const,
  detail: (integrationId: string, actionId: string, stepId: string) =>
    [...QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.all, integrationId, actionId, stepId] as const,
};

export const QUERY_KEYS_INTEGRATION_ACTION_TEST_EXECUTION = {
  all: ['integration-action-test-execution'] as const,
  list: (integrationId: string, actionId: string) =>
    [...QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.all, integrationId, actionId] as const,
  detail: (integrationId: string, actionId: string, executionId: string) =>
    [...QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.all, integrationId, actionId, executionId] as const,
};

export const QUERY_KEYS_INPUT_PARAMETER_OPTIONS = {
  all: () => ['input-parameter-options'] as const,
  detail: ({
    integrationId,
    actionId,
    payload: { inputParameterId, inputParameters, context, draft, versionNumber, query },
    ...rest
  }: GetOptionsParams) =>
    [
      ...QUERY_KEYS_INPUT_PARAMETER_OPTIONS.all(),
      'detail',
      integrationId,
      actionId,
      draft,
      versionNumber,
      inputParameterId,
      ...(rest.type === 'in-workflow' ? [rest.appId, rest.workflowId] : rest.connectionId ? [rest.connectionId] : []),
      inputParameters,
      context,
      ...(query ? [query] : []),
    ] as const,
  withParameterId: ({
    integrationId,
    actionId,
    payload: { inputParameterId, draft, versionNumber },
    ...rest
  }: GetOptionsParams) =>
    [
      ...QUERY_KEYS_INPUT_PARAMETER_OPTIONS.all(),
      'detail',
      integrationId,
      actionId,
      draft,
      versionNumber,
      inputParameterId,
      ...(rest.type === 'in-workflow' ? [rest.appId, rest.workflowId] : rest.connectionId ? [rest.connectionId] : []),
    ] as const,
};

export const QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS = {
  all: () => ['input-parameter-dynamic-input-parameters'] as const,
  detail: ({
    integrationId,
    actionId,
    payload: { inputParameterId, inputParameters, draft, versionNumber, formId },
    ...rest
  }: GetDynamicInputParametersParams) =>
    [
      ...QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS.all(),
      'detail',
      integrationId,
      actionId,
      draft,
      versionNumber,
      inputParameterId,
      formId,
      ...(rest.type === 'in-workflow' ? [rest.appId, rest.workflowId] : rest.connectionId ? [rest.connectionId] : []),
      inputParameters,
    ] as const,
  withParameterId: ({
    integrationId,
    actionId,
    payload: { inputParameterId, draft, versionNumber, formId },
    ...rest
  }: GetDynamicInputParametersParams) =>
    [
      ...QUERY_KEYS_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS.all(),
      'detail',
      integrationId,
      actionId,
      draft,
      versionNumber,
      inputParameterId,
      formId,
      ...(rest.type === 'in-workflow' ? [rest.appId, rest.workflowId] : rest.connectionId ? [rest.connectionId] : []),
    ] as const,
};
