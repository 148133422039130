import { useGetWorkflow } from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvBox,
  NvButton,
  NvDivider,
  NvFlex,
  NvPlayCircle,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { WORKFLOW_HISTORY_DETAIL } from '../../../../workflow-detail/constants';
import { RunWorkFlowWrapperLink } from './styled';

export const RunWorkflowLink: FC<{
  dispatchedWorkflow: { appId: string; workflowId: string; executionId: string };
}> = ({ dispatchedWorkflow }) => {
  const { savedWorkflow, isLoading } = useGetWorkflow({
    workflowId: dispatchedWorkflow.workflowId,
    appId: dispatchedWorkflow.appId,
  });
  const { palette } = useTheme();
  return (
    <NvBox>
      <NvDivider borderColor={palette.nv_neutral[20]} />
      <NvBox padding="16px">
        {isLoading ? (
          <NvSkeleton variant="rectangular" height="40px" />
        ) : (
          <RunWorkFlowWrapperLink
            target="_blank"
            href={WORKFLOW_HISTORY_DETAIL({
              appId: dispatchedWorkflow.appId,
              workflowId: dispatchedWorkflow.workflowId,
              executionId: dispatchedWorkflow.executionId,
            })}
          >
            <NvFlex flexDirection="row" alignItems="center" gap="8px">
              <NvFlex flexDirection="row" alignItems="center" gap="4px">
                <NvPlayCircle />
                <NvTypography variant="h5">Run workflow</NvTypography>
              </NvFlex>
              <NvDivider sx={{ height: '9px' }} orientation="vertical" borderColor={palette.nv_neutral_alpha[30]} />
              <NvTypography variant="h6" textColor="subtle">
                {savedWorkflow?.name}
              </NvTypography>
            </NvFlex>
            <NvButton onlyIcon variant="contained" size="small" color="secondary">
              <NvArrowForwardIcon />
            </NvButton>
          </RunWorkFlowWrapperLink>
        )}
      </NvBox>
    </NvBox>
  );
};
