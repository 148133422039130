import { NvAxios, useMutation } from '@novaera/core';
import { CATALOG_RELATIONSHIP_ROOT_PATH } from '../constants';
import { CatalogRelationship } from '../types';
import { useUpdateCatalogRelationshipCache } from '../use-update-catalog-relationship-cache';

type UpdateBatchRelationshipParams = {
  payload: {
    relationshipDefinitions: Omit<CatalogRelationship, 'id'>[];
  };
  entityTypeId: string;
};

type UpdateBatchCatalogRelationshipResult = {
  relationshipDefinitions: CatalogRelationship[];
};

const updateBatchCatalogRelationship: (
  params: UpdateBatchRelationshipParams
) => Promise<UpdateBatchCatalogRelationshipResult> = async (params) => {
  const result = await NvAxios.put<UpdateBatchCatalogRelationshipResult>(
    `${CATALOG_RELATIONSHIP_ROOT_PATH}/batch`,
    params.payload
  );
  return result?.data;
};

export const useUpdateBatchCatalogRelationship = () => {
  const { updateCache } = useUpdateCatalogRelationshipCache();

  return useMutation(updateBatchCatalogRelationship, {
    onSuccess: (data, { entityTypeId }) => {
      updateCache({
        params: { entityTypeId },
        updateFunction: ({ old }) => {
          return {
            relationshipDefinitions:
              old?.relationshipDefinitions?.map((relationship) => {
                const updatedRelationship = data.relationshipDefinitions.find(
                  (updated) => updated.id === relationship.id
                );

                if (updatedRelationship) {
                  return updatedRelationship;
                }

                return relationship;
              }) ?? [],
          };
        },
      });
    },
  });
};
