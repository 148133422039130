import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { WorkflowDispatcherPropertyPanelDrawerProps } from './types';
import { WorkflowDispatcherProperties } from './workflow-dispatcher-properties';

export const WorkflowDispatcherPropertyPanelDrawer: FC<WorkflowDispatcherPropertyPanelDrawerProps> = ({
  handleClose,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[WorkflowDispatcherPropertyPanelDrawer] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const { userAppId: appId, workflowId } = useParams();

  const { node: workflowDispatcherNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.workflowDispatcher>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const {
    panelProps: [first],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        {isLoading ? (
          <PropertiesLoading />
        ) : (
          workflowDispatcherNode && (
            <WorkflowDispatcherProperties
              onCloseClicked={handleClose}
              workflowDispatcherNode={workflowDispatcherNode}
            />
          )
        )}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
