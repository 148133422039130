import { NvCard, NvTypography } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NvCardWrapper = styled(NvCard)`
  ${({ theme }) => theme.elevations.e500};
  border-radius: 12px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 22px;
  padding-bottom: 32px;
  max-width: 600px;
`;

export const JoinTypographyWrapper = styled(NvTypography)`
  cursor: pointer;
`;
