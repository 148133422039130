import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecordDetailBox = styled(NvBox)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const RecordDetailHeaderBox = styled(NvBox)`
  min-height: 56px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
`;

export const PanelWrapper = styled(NvFlex)`
  width: 0;
  max-width: 460px;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  flex-direction: row;
  transform: translateX(100%);
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};

  &.open {
    transform: translateX(0);
    opacity: 1;
    width: 100%;
    transition: transform 300ms ease, opacity 400ms ease, width 300ms ease;
  }

  &.close {
    width: 0;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 300ms ease, opacity 400ms ease, width 300ms ease;
  }
`;
