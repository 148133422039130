import { ConditionType, SimpleParameterMapping, Version } from '../types';

export interface EventFilter {
  condition: ConditionType;
}

export interface TransformerAction {
  actionId: string;
  parameterMappings: SimpleParameterMapping[];
}

export interface SampleEventPayload {
  payload: Partial<Record<string, unknown>>;
}

export interface EventRule {
  integrationId: string;
  version: Version;
  id: string;
  name: string;
  state?: IntegrationEventRuleState;
  description?: string;
  eventFilter?: EventFilter;
  transformerAction?: TransformerAction;
  sampleEventPayload?: SampleEventPayload;
}

export enum IntegrationEventRuleState {
  PERSISTED = 'persisted',
  DRAFT = 'draft',
}
