import { SlackAppManifest } from '@novaera/actioner-service';
import { Optional } from '@novaera/utils';

export enum ExportSlackAppFocusStateOption {
  CREATE = 'create',
  INSTALL = 'install',
  UPDATE = 'update',
}
export interface ExportSlackAppFocusStateProps {
  isOpen: boolean;
  onClose: () => void;
  type: ExportSlackAppFocusStateOption;
}

export interface ExportSlackAppFormValues extends Optional<SlackAppManifest, 'appFeatures' | 'displayInfo'> {
  slackConfigurationToken: string | null;
}
