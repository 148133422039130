import { RateRecurrenceUnit } from '@novaera/actioner-service';
import { NvField, NvFlex, NvNumberInput, NvTimePicker, NvTypography, isRequired } from '@novaera/core';
import { getHours, getMinutes, isValid, set } from 'date-fns';
import { isNull } from 'lodash';
import { useMemo } from 'react';
import { RECURRENCE_TIME_UNITS } from '../../../constants';
import { RateRecurrenceConfigurationProps } from './types';

export const RateRecurrenceConfiguration: React.FC<RateRecurrenceConfigurationProps> = ({
  recurrence,
  selectedTimeUnit,
}) => {
  const repeatsLabel = useMemo(() => {
    const activeTimeUnit = RECURRENCE_TIME_UNITS.find(({ value }) => value === selectedTimeUnit);
    return activeTimeUnit ? `${activeTimeUnit.label.toLowerCase()}s` : undefined;
  }, [selectedTimeUnit]);
  return (
    <NvFlex direction="row" alignItems="center">
      {(recurrence.unit === RateRecurrenceUnit.DAYS || recurrence.unit === RateRecurrenceUnit.HOURS) && (
        <NvTypography variant="body1" marginRight="8px" flex="0 0 auto">
          repeats every
        </NvTypography>
      )}

      <NvField
        name={'recurrence.value'}
        showError={false}
        showErrorMessageOnlyWhenBlur
        validators={[isRequired()]}
        component={<NvNumberInput />}
      />

      {repeatsLabel && (
        <NvTypography variant="body1" marginLeft="8px">
          {repeatsLabel}
        </NvTypography>
      )}

      {recurrence.unit === RateRecurrenceUnit.HOURS && (
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvTypography>, at</NvTypography>
          <NvField
            name="recurrence.recurrenceTime"
            showError={false}
            showErrorMessageOnlyWhenBlur
            validators={[isRequired()]}
            format={(value: { hours: number; minutes: number }) => value.minutes}
            parse={(value: number) => ({ minutes: value, hours: null })}
            component={<NvNumberInput />}
          />
          <NvTypography>minutes</NvTypography>
        </NvFlex>
      )}
      {recurrence.unit === RateRecurrenceUnit.DAYS && (
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvTypography>, at</NvTypography>
          <NvField
            name="recurrence.recurrenceTime"
            showError={false}
            showErrorMessageOnlyWhenBlur
            validators={[isRequired()]}
            format={({ hours, minutes }: { hours: number | null; minutes: number | null }) => {
              let date = undefined;
              if (!isNull(hours) && !isNull(minutes)) {
                date = set(new Date(), {
                  hours,
                  minutes,
                });
              }
              return date;
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parse={(value: any) => {
              if (isValid(value)) {
                return { minutes: getMinutes(value), hours: getHours(value) };
              } else {
                return { minutes: null, hours: null };
              }
            }}
            component={<NvTimePicker />}
          />
        </NvFlex>
      )}
    </NvFlex>
  );
};
