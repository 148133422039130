import {
  SlackAppInstallationTrigger,
  useDeleteSlackApp,
  useGetSlackApp,
  useGetUserApp,
  useStartSlackAppInstallation,
} from '@novaera/actioner-service';
import {
  Initial,
  NvArrowForwardIcon,
  NvButton,
  NvDeleteOutlineIcon,
  NvErrorIcon,
  NvFlex,
  NvImage,
  NvMenuWithItems,
  NvSlackIconNotColored,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useNavigate } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { USER_APP_DESCRIPTION } from '../constants';
import { ExportSlackAppFocusState } from './export-slack-app';
import { ExportSlackAppFocusStateOption } from './export-slack-app/types';
import { RemoveSlackAppFocusState } from './remove-slack-app';
import { SlackAppUnit } from './slack-app-unit';
import { useStartMarketplaceSlackAppInstallation } from './start-marketplace-slack-app-installation/controller';
import { HeaderCard } from './styled';
import { SlackAppProps } from './types';
import { getSlackAppAuthUrl } from './utils';

export const SlackApp: React.FC<PropsWithChildren<SlackAppProps>> = ({ appId }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { openConfirm } = useConfirmDialog();
  const [isUpdateFocusStateOpen, setIsUpdateFocusStateOpen] = useState<boolean>(false);
  const [isRemoveFocusStateOpen, setIsRemoveFocusStateOpen] = useState<boolean>(false);
  const [appSchemaId, setAppSchemaId] = useState<string | undefined>(undefined);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const { data: userApp } = useGetUserApp(appId);
  const { data: slackApp } = useGetSlackApp({ appId });
  const { mutate: deleteSlackApp } = useDeleteSlackApp(appId);
  const { mutate: startSlackAppInstallation, isLoading: isStartInstallationLoading } = useStartSlackAppInstallation();
  const { slackAuthUrl } = useStartMarketplaceSlackAppInstallation({
    isAnonymous: false,
    schemaId: appSchemaId,
  });

  const menuItems = useMemo(
    () =>
      [
        {
          name: 'Remove from Slack',
          onClick: () => {
            setIsRemoveFocusStateOpen(true);
          },
          icon: <NvDeleteOutlineIcon htmlColor={palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          right: !slackApp?.marketplaceApp,
        },
        {
          name: 'Remove from Slack',
          onClick: () => {
            openConfirm({
              title: `Remove ${slackApp?.slackAppManifest.displayInfo.name} app from Slack`,
              message: 'Are you sure?',
              onConfirm: () => {
                assert(
                  !!appId && !!slackApp,
                  new Error('Slack can not be uninstalled without appId, slackAppId'),
                  'ERROR'
                );
                deleteSlackApp(
                  { appId, deleteSlackAppRequestParams: { slackAppId: slackApp?.slackAppId } },
                  {
                    onSuccess: () => {
                      navigate(USER_APP_DESCRIPTION(appId));
                    },
                  }
                );
              },
            });
          },
          icon: <NvDeleteOutlineIcon htmlColor={palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          right: !!slackApp?.marketplaceApp,
        },
      ].filter((i) => i.right),
    [appId, deleteSlackApp, navigate, openConfirm, palette.nv_error, slackApp]
  );

  useEffect(() => {
    if (slackAuthUrl) {
      setAppSchemaId(undefined);
      window.location.href = slackAuthUrl;
    }
  }, [slackAuthUrl]);

  return (
    <>
      <NvFlex direction="row" gap="8px" alignItems="center">
        <NvSlackIconNotColored sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
        <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
          App for Slack
        </NvTypography>
      </NvFlex>
      <HeaderCard>
        <NvFlex flex="1 1 auto" gap="16px" minWidth={0} flexDirection="row">
          <NvImage
            FallBack={
              <Initial
                size={'medium'}
                value={slackApp?.slackAppManifest.displayInfo.name ?? ''}
                color={palette.nv_neutral[500]}
              />
            }
            src={slackApp?.slackTeamIconUrl}
            imageShape="square"
            fallbackImageHasBlurEffect
            className="package-image"
          />

          <NvFlex>
            <NvTypography variant="h2" noWrap>
              {slackApp?.slackAppManifest.displayInfo.name}
            </NvTypography>
            {slackApp?.installed ? (
              <NvFlex direction="row" alignItems="center" gap="2px">
                <NvTypography variant="body2" textColor="subtle" noWrap>
                  This app is available at{' '}
                </NvTypography>
                <NvTypography variant="body2" noWrap>
                  <strong>{slackApp?.slackTeamName}</strong>
                </NvTypography>
              </NvFlex>
            ) : (
              <NvFlex direction="row" alignItems="center" gap="4px">
                <NvErrorIcon htmlColor={palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
                {slackApp?.slackTeamName ? (
                  <NvTypography variant="body2" noWrap>
                    This app is not installed at <strong>{slackApp.slackTeamName}</strong>
                  </NvTypography>
                ) : (
                  <NvTypography variant="body2" noWrap>
                    This app is not installed
                  </NvTypography>
                )}
              </NvFlex>
            )}
          </NvFlex>
        </NvFlex>

        <NvFlex direction="row" gap="8px" alignItems="center">
          {!slackApp?.marketplaceApp && (
            <NvButton
              color="secondary"
              size="small"
              onClick={() => {
                setIsUpdateFocusStateOpen(true);
              }}
            >
              Update
            </NvButton>
          )}

          {!slackApp?.installed ? (
            <NvButton
              color="primary"
              size="small"
              loading={isStartInstallationLoading || isRedirecting}
              disabled={isStartInstallationLoading || isRedirecting}
              onClick={() => {
                assert(!!appId, new Error('App for Slack can not be installed without appId.'), 'ERROR');
                if (slackApp?.marketplaceApp) {
                  setAppSchemaId(userApp?.metadata.schemaId);
                } else {
                  startSlackAppInstallation(
                    { appId },
                    {
                      onSuccess: ({ triggerId, slackAuthorizationUrl, slackClientId }) => {
                        const slackAuthUrl = getSlackAppAuthUrl({
                          triggerId,
                          slackAuthorizationUrl,
                          slackClientId,
                          installTrigger: SlackAppInstallationTrigger.DIRECT_INSTALLATION,
                          packageId: appId,
                        });
                        setIsRedirecting(true);
                        window.location.replace(slackAuthUrl);
                      },
                    }
                  );
                }
              }}
            >
              Install
            </NvButton>
          ) : (
            <NvButton
              color="secondary"
              size="small"
              endIcon={<NvArrowForwardIcon />}
              onClick={() => {
                window.open(slackApp?.slackAppHomeUrl, '_blank');
              }}
            >
              Go to Slack
            </NvButton>
          )}

          {menuItems.length > 0 && <NvMenuWithItems menuItems={menuItems} />}
        </NvFlex>
      </HeaderCard>
      <NvFlex gap="16px" maxWidth="600px">
        <SlackAppUnit label="App name" value={slackApp?.slackAppManifest.displayInfo.name} />
        <SlackAppUnit label="Slash command" value={slackApp?.slackAppManifest.appFeatures.commandFeatures[0].command} />
        <SlackAppUnit label="Short description" value={slackApp?.slackAppManifest.displayInfo.description} />
        <SlackAppUnit label="Long description" value={slackApp?.slackAppManifest.displayInfo.longDescription} />
      </NvFlex>

      {isUpdateFocusStateOpen && (
        <ExportSlackAppFocusState
          isOpen={isUpdateFocusStateOpen}
          onClose={() => {
            setIsUpdateFocusStateOpen(false);
          }}
          type={ExportSlackAppFocusStateOption.UPDATE}
        />
      )}
      {isRemoveFocusStateOpen && (
        <RemoveSlackAppFocusState
          isOpen={isRemoveFocusStateOpen}
          onClose={() => {
            setIsRemoveFocusStateOpen(false);
          }}
        />
      )}
    </>
  );
};
