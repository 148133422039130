import { useGetIntegration } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { Navigate, Route, Routes } from '@novaera/route';
import { FC, useState } from 'react';
import { ActionDesignerFormApiProvider } from '../../action-designer/providers/form-api-provider';
import { useGetIntegrationQueryParams } from '../../controllers/use-get-integration-query-params';
import { IntegrationDetailLayout } from '../components/integration-detail-layout';
import { IntegrationSideMenu } from '../components/integration-side-menu';
import { INTEGRATION_PATHS } from '../constants';
import { ActionDetail } from './action-detail';
import { Actions } from './action-list';
import { Connection } from './connection';
import { CreateActionModal } from './create-action-modal';
import { CreateEventRuleModal } from './create-event-rule-modal';
import { EventDetail } from './event-detail';
import { Events } from './events';
import { Summary } from './summary';

export const IntegrationDetail: FC<React.PropsWithChildren<unknown>> = () => {
  const { integrationId } = useGetIntegrationQueryParams();
  const { data, isInitialLoading } = useGetIntegration({ id: integrationId });
  const [isCreateActionModalOpen, setIsCreateActionModelOpen] = useState(false);
  const [isCreateEventRuleModalOpen, setIsCreateEventRuleModelOpen] = useState(false);

  return (
    <NvFlex direction="row" height="100%" flex="1 1 0" minHeight={0}>
      <IntegrationSideMenu
        integration={data}
        isLoading={isInitialLoading}
        onClickActionCreateButton={() => {
          setIsCreateActionModelOpen(true);
        }}
        onClickEventRuleCreateButton={() => {
          setIsCreateEventRuleModelOpen(true);
        }}
      />
      <NvFlex height="100%" flex="1 1 0" minWidth={0}>
        <Routes>
          <Route
            path={INTEGRATION_PATHS.SUMMARY}
            element={
              <IntegrationDetailLayout>
                <Summary />
              </IntegrationDetailLayout>
            }
          />
          <Route path={INTEGRATION_PATHS.CONNECTION} element={<Connection />} />
          <Route
            path={INTEGRATION_PATHS.TRIGGERS}
            element={
              <IntegrationDetailLayout>
                <Events onCreateEventRuleClick={() => setIsCreateEventRuleModelOpen(true)} />
              </IntegrationDetailLayout>
            }
          />
          <Route path={INTEGRATION_PATHS.EVENT_DETAIL} element={<EventDetail />} />
          <Route
            path={INTEGRATION_PATHS.ACTIONS}
            element={
              <IntegrationDetailLayout>
                <Actions onCreateActionClick={() => setIsCreateActionModelOpen(true)} />
              </IntegrationDetailLayout>
            }
          />
          <Route
            path={`${INTEGRATION_PATHS.ACTIONS_DETAIL}`}
            element={
              <ActionDesignerFormApiProvider>
                <ActionDetail />
              </ActionDesignerFormApiProvider>
            }
          />
          <Route path={`${INTEGRATION_PATHS.ACTIONS_DETAIL}/*`} element={<Navigate to={''} replace={true} />} />
        </Routes>
        <CreateActionModal isOpen={isCreateActionModalOpen} onClose={() => setIsCreateActionModelOpen(false)} />
        <CreateEventRuleModal
          isOpen={isCreateEventRuleModalOpen}
          onClose={() => setIsCreateEventRuleModelOpen(false)}
        />
      </NvFlex>
    </NvFlex>
  );
};
