import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { ConnectionStepItemCardStyleProps } from './types';

export const ConnectionStepItemCard = styled(NvBox, {
  shouldForwardProp: (prop) => prop !== 'connected',
})<ConnectionStepItemCardStyleProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background-color: ${({ theme, connected }) =>
    connected ? theme.palette.nv_success[10] : theme.palette.nv_neutral[20]};
  ${({ theme, connected }) => (connected ? `border: 1px solid ${theme.palette.nv_success[20]}` : null)};
  border-radius: 12px;

  &.ambiguous {
    border: 1px solid ${({ theme }) => theme.palette.nv_warning[30]};
  }
`;
