import { ConnectionTest } from '@novaera/actioner-service';
import {
  NvBox,
  NvButton,
  NvConditionalWrap,
  NvFlex,
  NvSelect,
  NvTextField,
  NvTooltip,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { FC } from 'react';
import { useGetIntegrationQueryParams } from '../../../../../../../../controllers/use-get-integration-query-params';
import { ConnectModal } from '../../../../../../../components/connect-modal';
import { HTTP_METHODS } from './constants';
import { BaseUrlBox } from './styled';

import { ConnectionTestConfigurationProps } from './types';

export const ConnectionTestConfiguration: FC<React.PropsWithChildren<ConnectionTestConfigurationProps>> = ({
  value,
  onChange,
  connectionSchema,
  isFormSaved,
}) => {
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { integrationId } = useGetIntegrationQueryParams();

  return (
    <>
      <NvFlex width={1} direction="row" alignItems="flex-start" spacing={1}>
        <NvBox sx={{ flex: '0 0 auto' }}>
          <NvSelect
            id="httpMethod"
            options={HTTP_METHODS}
            defaultValue={HTTP_METHODS[0].value}
            onChange={(event) => {
              const connectionTestValue: ConnectionTest = {
                path: value?.path ?? '',
                httpMethod: event.target.value as ConnectionTest['httpMethod'],
              };
              onChange?.(connectionTestValue);
            }}
            sx={{ minWidth: '120px' }}
            value={value?.httpMethod ?? HTTP_METHODS[0].value}
          />
        </NvBox>

        <NvTextField
          sx={{ flex: '1 1 auto' }}
          startLabelAdornment={
            <NvConditionalWrap
              condition={Boolean(connectionSchema?.authentication?.baseUrl)}
              wrap={(children) => (
                <NvTooltip
                  title={connectionSchema.authentication.baseUrl ?? ''}
                  variant="large"
                  placement="top"
                  fullWidth
                >
                  {children}
                </NvTooltip>
              )}
            >
              <BaseUrlBox>
                <NvTypography variant="c2" textColor="subtle" component="p">
                  baseUrl
                </NvTypography>
              </BaseUrlBox>
            </NvConditionalWrap>
          }
          value={value?.path ?? ''}
          onChange={(event) => {
            const connectionTestValue: ConnectionTest = {
              httpMethod: value?.httpMethod ?? (HTTP_METHODS[0].value as ConnectionTest['httpMethod']),
              path: event.target.value,
            };
            onChange?.(connectionTestValue);
          }}
        />
        <NvConditionalWrap
          condition={!isFormSaved}
          wrap={(children) => (
            <NvTooltip title="Connection is not saved. Please save connection to test">
              {/** span is the hacky way to show tooltips for disabled buttons */}
              <span>{children}</span>
            </NvTooltip>
          )}
        >
          <NvButton
            color="secondary"
            onClick={() => {
              onModalOpenClicked();
            }}
            disabled={!isFormSaved}
          >
            Test connection
          </NvButton>
        </NvConditionalWrap>
      </NvFlex>
      <ConnectModal
        isOpened={isOpened}
        onModalCloseClicked={onModalCloseClicked}
        mode={'Test'}
        integrationId={integrationId}
        schemaId={connectionSchema.id}
        test="connection-schema"
      />
    </>
  );
};
