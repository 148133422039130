import {
  APP_PERMISSION,
  AccessSetting,
  AccessType,
  useDeleteUserAppAccessSettings,
  useUpdateUserAppAccessSettings,
} from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvDeleteOutlineIcon,
  NvDivider,
  NvFlex,
  NvManageAccountsIcon,
  NvSearchEmptyState,
  NvSearchIcon,
  NvSelect,
  NvTextField,
  NvTypography,
  useConfirmDialog,
  useDebounce,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import React, { useMemo, useState } from 'react';
import { UserAndGroupImage } from '../../../components/user-and-group-image';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { AddUserAppAccessUserModal } from './add-package-access-user-modal';
import { USER_ROLES } from './constants';
import { PermissionsTableLoading } from './permissions-loading';
import {
  AppPermissionsCard,
  AppPermissionsRow,
  AppPermissionsRowLeft,
  AppPermissionsRowRight,
  AppPermissionsUserBox,
} from './styled';
import { useUserAppUsersWithPermissions } from './use-user-app-users-with-permissions';

export const UserAppPermissions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useParams();
  const theme = useTheme();
  const [indexIsInProgress, setIndexIsInProgress] = useState<number | null>(null);
  const { openConfirm } = useConfirmDialog();
  const [keyword, setKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(keyword, 500);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);

  const { userAppAccessUsers, isUserAppLoading, isBatchGetLoading, userApp } = useUserAppUsersWithPermissions({
    userAppId,
  });

  const filteredPackageAccessUsers = useMemo(
    () =>
      userAppAccessUsers?.filter((user) => {
        let name = '';
        if (user.principalType === AccessType.USER && user?.userDetail) {
          name = user.userDetail.userName;
        } else if (user.principalType === AccessType.GROUP && user?.groupDetail) {
          name = user.groupDetail.name;
        }
        return name.toLowerCase().includes(debouncedKeyword.toLowerCase());
      }),
    [debouncedKeyword, userAppAccessUsers]
  );

  const { mutate: updateUserAppAccessSettings } = useUpdateUserAppAccessSettings();
  const { mutate: deleteUserAppAccessSettings, isLoading: isDeleteUserAppAccessSettingsLoading } =
    useDeleteUserAppAccessSettings();

  const handleEditRightUpdate = (accessSetting: AccessSetting, checked: boolean, index: number) => {
    setIndexIsInProgress(index);

    const newAccessSetting: AccessSetting = {
      principalType: accessSetting.principalType,
      principalId: accessSetting.principalId,
      editGranted: checked,
    };

    return updateUserAppAccessSettings(
      {
        appId: userAppId,
        accessSettings: [newAccessSetting],
      },
      {
        onSettled: () => {
          setIndexIsInProgress(null);
        },
      }
    );
  };

  const handleDeleteAccessUser = (user: AccessSetting, index: number) => {
    setIndexIsInProgress(index);
    deleteUserAppAccessSettings(
      { appId: userAppId, principals: [{ type: user.principalType, id: user.principalId }] },
      {
        onSettled: () => {
          setIndexIsInProgress(null);
        },
      }
    );
  };

  const handleAddUserClick = () => {
    setIsAddUserModalOpen(true);
  };

  const handleAddUserAppAccessUserModalClose = () => {
    setIsAddUserModalOpen(false);
  };

  return (
    <>
      <NvFlex direction="row" gap="8px" alignItems="center">
        <NvManageAccountsIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
        <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
          Permissions
        </NvTypography>
      </NvFlex>

      <NvFlex gap="16px">
        <NvFlex direction="row" alignItems="center" gap="24px" justifyContent="space-between">
          <NvTextField
            placeholder="Search"
            startIcon={<NvSearchIcon />}
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />

          <UserAppPermissionBoundary permission={APP_PERMISSION.APP_EDIT} appId={userAppId}>
            <NvButton size="small" color="ghost" startIcon={<NvAddBoxIcon />} onClick={handleAddUserClick}>
              Add permission
            </NvButton>
          </UserAppPermissionBoundary>
        </NvFlex>
        {filteredPackageAccessUsers?.length === 0 && debouncedKeyword.length > 0 ? (
          <NvSearchEmptyState text={'No users found. Try different words or clear search bar.'} />
        ) : (
          <AppPermissionsCard>
            {isUserAppLoading || isBatchGetLoading ? (
              <PermissionsTableLoading />
            ) : (
              filteredPackageAccessUsers?.map((user, index) => (
                <AppPermissionsRow key={`${user.principalId}-${user.principalType}`}>
                  <AppPermissionsRowLeft>
                    {user.principalType === AccessType.USER ? (
                      <>
                        <AppPermissionsUserBox>
                          <UserAndGroupImage src={user.userDetail?.logoUrl} type={AccessType.USER} size="small" />
                          <NvTypography variant="h5" noWrap>
                            {user.userDetail?.userName ?? 'Deleted user'}
                          </NvTypography>
                        </AppPermissionsUserBox>

                        <NvDivider
                          orientation="vertical"
                          borderColor={theme.palette.nv_neutral_alpha[20]}
                          sx={{ height: '8px' }}
                        />
                        <NvTypography variant="body2" textColor="secondary" noWrap flex="1 1 auto" minWidth={0}>
                          {user.userDetail?.email}
                        </NvTypography>
                      </>
                    ) : user.principalType === AccessType.GROUP ? (
                      <>
                        <AppPermissionsUserBox>
                          <UserAndGroupImage
                            src={user.groupDetail?.logoUrl}
                            id={user.groupDetail?.id}
                            type={AccessType.GROUP}
                            size="small"
                          />

                          <NvTypography variant="h5" noWrap>
                            {user.groupDetail?.name ?? 'Deleted group'}
                          </NvTypography>
                        </AppPermissionsUserBox>

                        <NvDivider
                          orientation="vertical"
                          borderColor={theme.palette.nv_neutral_alpha[20]}
                          sx={{ height: '8px' }}
                        />
                      </>
                    ) : null}
                  </AppPermissionsRowLeft>
                  <AppPermissionsRowRight>
                    <NvSelect
                      sx={{ width: '100px' }}
                      options={USER_ROLES}
                      value={user.editGranted}
                      onChange={(e) => {
                        const value = e.target.value as boolean;
                        handleEditRightUpdate(user, value, index);
                      }}
                      compact
                    />
                    <NvButton
                      size="small"
                      color="ghost"
                      onlyIcon
                      loading={isDeleteUserAppAccessSettingsLoading && indexIsInProgress === index}
                      onClick={() => {
                        const deletingItemName =
                          user.principalType === AccessType.USER
                            ? user.userDetail?.userName
                            : user.principalType === AccessType.GROUP
                            ? user.groupDetail?.name
                            : undefined;
                        openConfirm({
                          title: `Remove ${deletingItemName} from ${userApp?.name}?`,
                          message: `You are about to remove this user from your app. Removed users can no longer see or run the workflows in this app.`,
                          confirmButtonLabel: 'Remove',
                          onConfirm: () => handleDeleteAccessUser(user, index),
                        });
                      }}
                    >
                      <NvDeleteOutlineIcon />
                    </NvButton>
                  </AppPermissionsRowRight>
                </AppPermissionsRow>
              ))
            )}
          </AppPermissionsCard>
        )}
      </NvFlex>

      {isAddUserModalOpen && (
        <AddUserAppAccessUserModal
          isOpen={isAddUserModalOpen}
          onClose={handleAddUserAppAccessUserModalClose}
          existingUsers={userAppAccessUsers}
        />
      )}
    </>
  );
};
