import { getSubdomain } from '@novaera/utils';
import { useGetMetaData } from '../../../../services/slack/use-get-metadata';
import { STATE_DELIMITER } from '../../common/constants';
import { getSlackRedirectUri } from '../../common/utils';

export const useGetSlackAuth = (params?: { isAnonymous?: boolean }) => {
  const { data, isSuccess, isInitialLoading } = useGetMetaData({
    append: true,
    isAnonymous: params?.isAnonymous,
  });

  const slackAuthUrl = isSuccess
    ? `${data?.authorizeUrl}&state=${getSubdomain()}${STATE_DELIMITER}${data?.triggerId}${STATE_DELIMITER}${
        params?.isAnonymous ?? false
      }&redirect_uri=${getSlackRedirectUri()}`
    : data?.authorizeUrl;

  return {
    slackAuthUrl,
    isLoading: isInitialLoading,
  };
};
