import { NvDivider, NvFlex, NvSelect, TypeOfTab } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useEffect, useMemo, useState } from 'react';
import { OutputTabItemWrapper } from '../output-item-wrapper';
type StateType = Pick<TypeOfTab, 'id' | 'icon'>;

export const TabSelectOutput = ({ tabs }: { tabs: TypeOfTab[] }) => {
  const [value, setValue] = useState<StateType>();

  const options = useMemo(() => {
    const result = tabs.map((tab) => {
      assert(typeof tab.label === 'string', new Error('label must be string'));
      return {
        id: tab.id,
        label: tab.label ?? '',
        value: {
          id: tab.id,
          icon: tab.icon,
        },
        icon: tab.icon,
      };
    });

    return result;
  }, [tabs]);

  const handleChange = (value: StateType) => {
    setValue(value);
  };

  useEffect(() => {
    if (!value) {
      setValue(options[0].value);
    }
  }, [options, value]);

  const content = useMemo(() => {
    return tabs.find((tab) => tab.id === value?.id)?.content;
  }, [tabs, value?.id]);

  return (
    <NvFlex gap={'8px'}>
      <NvSelect<StateType>
        options={options}
        size="small"
        onChange={(e) => {
          assert(typeof e.target.value !== 'string', new Error('value must be StateType'));
          handleChange(e.target.value);
        }}
        value={value}
        sx={{
          maxWidth: '150px',
        }}
        defaultValue={options[0].value}
        {...(value?.icon && typeof value.icon !== 'string' && { startIcon: value?.icon })}
      />
      <NvDivider orientation="horizontal" sx={{ marginBottom: '8px' }} />
      <OutputTabItemWrapper hasError={false}>{content}</OutputTabItemWrapper>
    </NvFlex>
  );
};
