import React, { isValidElement } from 'react';

import { NvTypography } from '../typography';
import { capitalizeFirstLetter } from '@novaera/utils';
import classnames from 'classnames';
import { ButtonStyled } from './styled';
import { NvButtonPropsButtonProps } from './types';

/**
 * Design {@link https://www.figma.com/file/3dc69PHGohVkcKCMby2CSd/Form-elements?node-id=1%3A13}
 * @returns Button Component
 */

export const NvButton = React.forwardRef<HTMLButtonElement, NvButtonPropsButtonProps>(
  (
    {
      children,
      disableFocusRipple,
      disableTouchRipple,
      disableRipple,
      disableElevation,
      onlyIcon,
      size = 'medium',
      variant = 'contained',
      isFitContent,
      labelProps,
      ...props
    },
    ref
  ) => (
    <ButtonStyled
      {...props}
      disableFocusRipple
      disableTouchRipple
      disableRipple
      disableElevation
      onlyIcon={onlyIcon}
      size={size}
      variant={variant}
      {...(onlyIcon && { startIcon: children })}
      className={classnames({
        'fit-content': isFitContent,
        'NvButton-onlyIcon': onlyIcon,
        [`NvButton-size${capitalizeFirstLetter(size)}`]: true,
        [`${props.className}`]: props.className !== undefined,
      })}
      ref={ref}
    >
      {!onlyIcon && !isValidElement(children) && (
        <NvTypography variant={size === 'small' ? 'h5' : size === 'large' ? 'h3' : 'h4'} noWrap {...labelProps}>
          {children}
        </NvTypography>
      )}
      {!onlyIcon && isValidElement(children) && React.cloneElement(children)}
    </ButtonStyled>
  )
);
