import { NvAxios, useQuery } from '@novaera/core';

import { QUERY_KEYS_JOBS } from '../keys';
import { GetJobDetailParams, GetJobDetailResponse } from './types';

const getJobDetail = async ({ appId, jobId }: GetJobDetailParams) => {
  const result = await NvAxios.get<GetJobDetailResponse>(`/v2/apps/${appId}/jobs/${jobId}`);
  return result?.data;
};

export const useGetJobDetail = ({ appId, jobId }: GetJobDetailParams) => {
  return useQuery<GetJobDetailResponse>(QUERY_KEYS_JOBS.detail(appId, jobId), () => getJobDetail({ appId, jobId }), {
    enabled: !!jobId && !!appId,
  });
};
