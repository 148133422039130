import { ConnectionMapping } from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, NvEditIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { useDeleteConnection } from '../../../../../../profile-settings/profile-connections/detail/connection-information/use-delete-connection';
import { ConnectionModalParams } from '../../types';
import { ConnectionState } from '../types';

export const useItemActionMenuItems = ({
  connectionState,
  connectionMapping,
  onClick,
}: {
  connectionState: ConnectionState;
  connectionMapping: ConnectionMapping;
  onClick: (params: ConnectionModalParams) => void;
}) => {
  const theme = useTheme();
  const { deleteConnectionWithConfirmation } = useDeleteConnection();

  const moreActionMenuItems = [
    ...(connectionState.connected &&
    connectionState.type !== 'only-one-slack' &&
    connectionState.type !== 'only-one-ms-teams'
      ? [
          {
            name: 'Reconnect',
            onClick: () => {
              assert(
                connectionMapping.connected,
                new Error('To reconnect connection should already be connected'),
                'ERROR'
              );
              onClick({
                connection: connectionState.connectedConnection,
                integrationId: connectionMapping.integrationId,
                schemaId: connectionMapping.schemaId,
                type: 'reconnect',
              });
            },
            icon: <NvEditIcon sx={{ width: '16px', height: '16px' }} />,
          },
        ]
      : []),
    {
      name: 'divider',
      isDivider: true,
      dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
    },
    ...(connectionState.connected &&
    connectionState.type !== 'only-one-slack' &&
    connectionState.type !== 'only-one-ms-teams'
      ? [
          {
            name: 'Delete',
            onClick: () => {
              assert(!!connectionState.connectedConnection.id, new Error('There is no connection to delete'), 'ERROR');

              deleteConnectionWithConfirmation({
                connectionId: connectionState.connectedConnection.id,
                connectionName: connectionState.connectedConnection.name,
              });
            },
            icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          },
        ]
      : []),
  ];

  return { moreActionMenuItems };
};
