import { NvCustomCheckIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { MenuItemStyled } from './styled';
import { ComponentMenuTypeMenuItemProps } from './types';

export const DynamicStaticMenuItem: FC<React.PropsWithChildren<ComponentMenuTypeMenuItemProps>> = ({
  selectedIndex,
  onItemClicked,
  onClose,
  items,
}) => {
  const { palette } = useTheme();
  return (
    <>
      {items.map((item, index) => {
        const isSelected = selectedIndex === index;
        return (
          <MenuItemStyled
            onClick={(e) => {
              onItemClicked(item.value);
              onClose?.(e);
            }}
            isSelected={isSelected}
            key={`${item.value}`}
          >
            <NvFlex width="100%" maxWidth="280px">
              <NvFlex direction="row" alignItems="center" height="24px">
                <NvTypography
                  variant="h4"
                  sx={{ flex: '1 1 auto', transition: 'color 200ms ease' }}
                  {...(isSelected ? { color: palette.nv_main[60] } : { color: palette.nv_neutral[1000] })}
                >
                  {item.title}
                </NvTypography>
                {isSelected && (
                  <NvCustomCheckIcon
                    sx={{ flex: '0 0 auto' }}
                    {...(isSelected ? { htmlColor: palette.nv_main[60] } : { htmlColor: palette.nv_neutral[1000] })}
                  />
                )}
              </NvFlex>
              <NvFlex direction="row">
                <NvTypography variant="body2" sx={{ flex: '1 1 auto' }} textColor="secondary">
                  {item.description}
                </NvTypography>
              </NvFlex>
            </NvFlex>
          </MenuItemStyled>
        );
      })}
    </>
  );
};
