import {
  AppContextAction,
  ActionType,
  State,
  UpdateUserInitialBgColorKeyMapPayload,
  UpdateWorkspacesPayload,
  ChangeSettingsMenuState,
} from '../types';

export const appStateReducer = (
  state: State,
  action: ActionType<UpdateWorkspacesPayload | UpdateUserInitialBgColorKeyMapPayload | ChangeSettingsMenuState>
) => {
  switch (action.type) {
    case AppContextAction.UpdateWorkspaces: {
      return { ...state, ...action.payload };
    }
    case AppContextAction.UpdateUserInitialBgColorKeyMap: {
      return { ...state, ...action.payload };
    }
    case AppContextAction.ChangeSettingsMenuState: {
      return { ...state, ...action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
