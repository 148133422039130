import { NvAxios } from '@novaera/core';
import { useQuery } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEY_ADHOC_CONFIG_VALIDATION } from '../keys';
import { ValidateConfigResult } from '../types';
import { ValidateAdhocConfigParams } from './types';

const validateAdhocConfig = async ({ appId, payload }: ValidateAdhocConfigParams) => {
  const result = await NvAxios.post<ValidateConfigResult>(`${CONFIG_ROOT_PATH(appId)}/validation`, {
    ...payload,
  });
  return result?.data;
};

export const useValidateAdhocConfig = ({ appId, payload }: ValidateAdhocConfigParams) => {
  return useQuery<ValidateConfigResult>(
    QUERY_KEY_ADHOC_CONFIG_VALIDATION.detail(appId, payload.schemaId, payload.properties),
    () => validateAdhocConfig({ appId, payload }),
    { enabled: !!appId && !!payload.schemaId && !!payload.properties }
  );
};
