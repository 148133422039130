import { SSOProvider, useChangeSSOEnableState, useGetSSO, useUpdateSSO } from '@novaera/actioner-service';
import { NvButton, NvForm, NvSkeleton, SwitchedCollapsedPanel } from '@novaera/core';
import { useMemo } from 'react';
import { SSOSettingsBody } from './body';

type SSOSettingsForm = SSOProvider;

type SSOSettingsProps = {
  containerId: SSOProvider['containerId'];
  containerType: SSOProvider['containerType'];
};

export const SSOSettings = ({ containerId, containerType }: SSOSettingsProps) => {
  const { mutate: updateSSO, isLoading: isUpdateSSOLoading } = useUpdateSSO();
  const { mutate: changeSSOEnabledState } = useChangeSSOEnableState();

  const { data: ssoData, isLoading: isSSOLoading } = useGetSSO({
    containerType,
    organizationId: containerId,
  });

  const initialValues = useMemo(() => {
    return (
      ssoData ?? {
        containerId,
        containerType,
        enabled: false,
        ssoProvider: {
          type: '',
          parameterValues: [],
        },
      }
    );
  }, [containerId, containerType, ssoData]);

  return isSSOLoading ? (
    <NvSkeleton width={'100%'} height={'56px'} />
  ) : (
    <NvForm<SSOSettingsForm>
      initialValues={initialValues}
      onSubmit={(values) => {
        updateSSO({
          body: {
            ...values,
          },
        });
      }}
    >
      {({ dirty, form, values }) => {
        return (
          <SwitchedCollapsedPanel
            onSwitchChange={(enabled) => {
              if (ssoData) {
                changeSSOEnabledState({
                  body: {
                    containerId: values.containerId,
                    containerType: values.containerType,
                    enabled,
                  },
                });
              }
            }}
            value={initialValues.enabled}
            switchLabel="Single sign-on settings"
            Body={<SSOSettingsBody />}
            {...(dirty && {
              FooterInformation: 'You have unapplied changes',
            })}
            FooterButtons={
              <>
                <NvButton
                  disabled={!dirty || isUpdateSSOLoading}
                  color="secondary"
                  size="medium"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Discard
                </NvButton>
                <NvButton
                  loading={isUpdateSSOLoading}
                  disabled={!dirty || isUpdateSSOLoading}
                  type="submit"
                  size="medium"
                >
                  Apply changes
                </NvButton>
              </>
            }
          />
        );
      }}
    </NvForm>
  );
};
