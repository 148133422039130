import { useGetIntegration, useGetWorkflow } from '@novaera/actioner-service';
import { NvImage } from '@novaera/core';
import { useParams } from '@novaera/route';
import { FC, useMemo } from 'react';
import { LogoPlaceholder } from '../../../../../../components/logo-placeholder';
import { Node } from '../../../../../../components/node';
import { NodeStatus } from '../../../../../../components/node/types';
import { NodeType } from '../../../../../../components/property-panel/types';
import { useWorkflowErrorStateProvider } from '../../providers/workflow-error-state-provider';
import { BaseNodeProps } from '../types';

export type IntegrationTriggerProps = BaseNodeProps;

export const IntegrationTrigger: FC<React.PropsWithChildren<IntegrationTriggerProps>> = (props) => {
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const integrationId = useMemo(() => {
    if (
      workflow?.trigger &&
      (workflow.trigger.type === 'integrationWebhook' || workflow.trigger.type === 'integrationApp')
    ) {
      return workflow.trigger.eventRuleIdentifier.integrationId;
    } else {
      return undefined;
    }
  }, [workflow?.trigger]);

  const { data: integration } = useGetIntegration({ id: integrationId });

  const { onErrorStateChanged } = useWorkflowErrorStateProvider();

  const showError = useMemo(() => {
    const showError = Boolean(
      !workflow?.trigger ||
        ((workflow.trigger.type === 'integrationWebhook' || workflow.trigger.type === 'integrationApp') &&
          !workflow.trigger.eventRuleIdentifier?.eventRuleId)
    );

    onErrorStateChanged({
      nodeId: props.id,
      showError,
    });

    return showError;
  }, [onErrorStateChanged, props.id, workflow?.trigger]);
  const { actions, ...rest } = props;

  return (
    <Node
      {...(showError ? { status: NodeStatus.ERROR } : {})}
      type={NodeType.INTEGRATION_TRIGGER}
      icon={
        <NvImage
          src={integration?.logoUrl}
          size="smaller"
          FallBack={
            <LogoPlaceholder
              size="smaller"
              src="assets/integration-placeholder-image.png"
              initialText={integration?.name ?? 'Integration'}
            />
          }
          hasNoElevation
        />
      }
      {...rest}
    />
  );
};
