import { SvgIcon, SvgIconProps } from '@mui/material';

export const NvSlackIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.78177 14.3745C6.78177 15.4152 5.9316 16.2654 4.89088 16.2654C3.85017 16.2654 3 15.4152 3 14.3745C3 13.3338 3.85017 12.4836 4.89088 12.4836H6.78177V14.3745Z"
        fill="#E01E5A"
      />
      <path
        d="M7.73413 14.3745C7.73413 13.3338 8.5843 12.4836 9.62502 12.4836C10.6657 12.4836 11.5159 13.3338 11.5159 14.3745V19.1091C11.5159 20.1498 10.6657 20.9999 9.62502 20.9999C8.5843 20.9999 7.73413 20.1498 7.73413 19.1091V14.3745Z"
        fill="#E01E5A"
      />
      <path
        d="M9.62502 6.78176C8.5843 6.78176 7.73413 5.9316 7.73413 4.89088C7.73413 3.85016 8.5843 3 9.62502 3C10.6657 3 11.5159 3.85016 11.5159 4.89088V6.78176H9.62502Z"
        fill="#36C5F0"
      />
      <path
        d="M9.62543 7.7345C10.6661 7.7345 11.5163 8.58466 11.5163 9.62538C11.5163 10.6661 10.6661 11.5163 9.62543 11.5163H4.89088C3.85017 11.5163 3 10.6661 3 9.62538C3 8.58466 3.85017 7.7345 4.89088 7.7345H9.62543Z"
        fill="#36C5F0"
      />
      <path
        d="M17.218 9.62538C17.218 8.58466 18.0682 7.7345 19.1089 7.7345C20.1496 7.7345 20.9998 8.58466 20.9998 9.62538C20.9998 10.6661 20.1496 11.5163 19.1089 11.5163H17.218V9.62538Z"
        fill="#2EB67D"
      />
      <path
        d="M16.2654 9.62542C16.2654 10.6661 15.4152 11.5163 14.3745 11.5163C13.3338 11.5163 12.4836 10.6661 12.4836 9.62542V4.89088C12.4836 3.85016 13.3338 3 14.3745 3C15.4152 3 16.2654 3.85016 16.2654 4.89088V9.62542Z"
        fill="#2EB67D"
      />
      <path
        d="M14.3745 17.2183C15.4152 17.2183 16.2654 18.0684 16.2654 19.1091C16.2654 20.1499 15.4152 21 14.3745 21C13.3338 21 12.4836 20.1499 12.4836 19.1091V17.2183H14.3745Z"
        fill="#ECB22E"
      />
      <path
        d="M14.3745 16.2654C13.3338 16.2654 12.4836 15.4152 12.4836 14.3745C12.4836 13.3338 13.3338 12.4836 14.3745 12.4836H19.1091C20.1498 12.4836 21 13.3338 21 14.3745C21 15.4152 20.1498 16.2654 19.1091 16.2654H14.3745Z"
        fill="#ECB22E"
      />
    </SvgIcon>
  );
};

export const NvSlackIconNotColored = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.78177 14.3745C6.78177 15.4152 5.9316 16.2654 4.89088 16.2654C3.85017 16.2654 3 15.4152 3 14.3745C3 13.3338 3.85017 12.4836 4.89088 12.4836H6.78177V14.3745Z"
        fill="currentColor"
      />
      <path
        d="M7.73413 14.3745C7.73413 13.3338 8.5843 12.4836 9.62502 12.4836C10.6657 12.4836 11.5159 13.3338 11.5159 14.3745V19.1091C11.5159 20.1498 10.6657 20.9999 9.62502 20.9999C8.5843 20.9999 7.73413 20.1498 7.73413 19.1091V14.3745Z"
        fill="currentColor"
      />
      <path
        d="M9.62502 6.78176C8.5843 6.78176 7.73413 5.9316 7.73413 4.89088C7.73413 3.85016 8.5843 3 9.62502 3C10.6657 3 11.5159 3.85016 11.5159 4.89088V6.78176H9.62502Z"
        fill="currentColor"
      />
      <path
        d="M9.62543 7.7345C10.6661 7.7345 11.5163 8.58466 11.5163 9.62538C11.5163 10.6661 10.6661 11.5163 9.62543 11.5163H4.89088C3.85017 11.5163 3 10.6661 3 9.62538C3 8.58466 3.85017 7.7345 4.89088 7.7345H9.62543Z"
        fill="currentColor"
      />
      <path
        d="M17.218 9.62538C17.218 8.58466 18.0682 7.7345 19.1089 7.7345C20.1496 7.7345 20.9998 8.58466 20.9998 9.62538C20.9998 10.6661 20.1496 11.5163 19.1089 11.5163H17.218V9.62538Z"
        fill="currentColor"
      />
      <path
        d="M16.2654 9.62542C16.2654 10.6661 15.4152 11.5163 14.3745 11.5163C13.3338 11.5163 12.4836 10.6661 12.4836 9.62542V4.89088C12.4836 3.85016 13.3338 3 14.3745 3C15.4152 3 16.2654 3.85016 16.2654 4.89088V9.62542Z"
        fill="currentColor"
      />
      <path
        d="M14.3745 17.2183C15.4152 17.2183 16.2654 18.0684 16.2654 19.1091C16.2654 20.1499 15.4152 21 14.3745 21C13.3338 21 12.4836 20.1499 12.4836 19.1091V17.2183H14.3745Z"
        fill="currentColor"
      />
      <path
        d="M14.3745 16.2654C13.3338 16.2654 12.4836 15.4152 12.4836 14.3745C12.4836 13.3338 13.3338 12.4836 14.3745 12.4836H19.1091C20.1498 12.4836 21 13.3338 21 14.3745C21 15.4152 20.1498 16.2654 19.1091 16.2654H14.3745Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
