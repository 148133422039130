import { APP_PERMISSION, Permissions, USER_PERMISSION } from '@novaera/actioner-service';

type UsePermissionBase = { children?: React.ReactNode; Fallback?: React.ReactElement };

export type PermissionGenericNoArray<PERM extends APP_PERMISSION | USER_PERMISSION> = {
  permission: PERM;
  permissions: Permissions<PERM>;
} & UsePermissionBase;

export type PermissionGenericArray<PERM extends APP_PERMISSION | USER_PERMISSION> = {
  permission: PERM[];
  permissions: Permissions<PERM>;
  conditionFn: (params: boolean[]) => boolean;
} & UsePermissionBase;

export type PermissionGeneric<PERM extends APP_PERMISSION | USER_PERMISSION> =
  | PermissionGenericNoArray<PERM>
  | PermissionGenericArray<PERM>;

export const isPermissionGenericArray = <PERM extends APP_PERMISSION | USER_PERMISSION>(
  params: PermissionGeneric<PERM>
): params is PermissionGenericArray<PERM> => {
  return Array.isArray(params.permission);
};

export const usePermission = <PERM extends APP_PERMISSION | USER_PERMISSION>(param: PermissionGeneric<PERM>) => {
  let permissionGranted: boolean;
  if (!isPermissionGenericArray(param)) {
    permissionGranted = param.permissions[param.permission] === true;
  } else {
    const params: boolean[] = param.permission.map((p) => {
      return param.permissions[p] === true;
    });
    permissionGranted = param.conditionFn(params);
  }
  if (!permissionGranted) return param.Fallback ?? null;

  return param.children;
};
