import { NvField, NvFlex, NvTextField, isRequired } from '@novaera/core';
import { EntityTypeIconAutoComplete } from '../../components/catalog-entity-type-icon/icon-autocomplete';

export const EntityTypeModalBody = (props: { mode: 'edit' | 'create' }) => {
  return (
    <NvFlex>
      <NvField<string>
        direction="label-on-top"
        labelText="Icon"
        name={'iconId'}
        isAutoComplete
        isRequired
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        validators={[isRequired()]}
        component={<EntityTypeIconAutoComplete prefetchIcons={props.mode === 'create'} />}
      />
      <NvField
        direction="label-on-top"
        labelText="Name"
        name={'name'}
        isRequired
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        validators={[isRequired()]}
        component={<NvTextField placeholder="Catalog name" />}
      />
    </NvFlex>
  );
};
