import { NvButton, NvCloseIcon, NvDivider, NvFlex } from '@novaera/core';
import classnames from 'classnames';
import React from 'react';

export const AssistantListItemRightComponent: React.FC<{
  isOpen?: boolean;
  onDelete?: () => void;
  actions?: React.ReactNode;
}> = ({ isOpen = false, actions, onDelete }) => (
  <NvFlex direction="row" alignItems="center" justifyContent="flex-end" flex="1 1 auto" minWidth={0} gap="4px">
    <NvFlex
      display="none"
      className={classnames({
        'assistant-actions': true,
        'keep-open': isOpen,
      })}
      direction="row"
      alignItems="center"
      gap="8px"
    >
      {actions}
      {!!actions && <NvDivider orientation="vertical" sx={{ height: '12px' }} />}
    </NvFlex>

    {onDelete && (
      <NvButton color="ghost" size="small" onlyIcon onClick={onDelete}>
        <NvCloseIcon />
      </NvButton>
    )}
  </NvFlex>
);
