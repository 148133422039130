import { Vertices } from '@novaera/actioner-service';

export const replaceInReferringNode = ({
  workflowVertices,
  aliasToFind,
  aliasToReplace,
}: {
  workflowVertices: Vertices;
  aliasToFind: string;
  aliasToReplace: string;
}) => {
  const referringNode = workflowVertices.find((n) => {
    if (n.type === 'branchJunction') {
      return n.firstInnerAliases.some((b) => b === aliasToFind);
    } else if (n.type === 'workflowCondition') {
      return n.trueAlias === aliasToFind || n.falseAlias === aliasToFind;
    } else if (n.type === 'loop') {
      return n.firstInnerAlias === aliasToFind;
    }
    return n.nextAlias === aliasToFind;
  });
  if (referringNode) {
    workflowVertices = workflowVertices.map((v) => {
      if (v.alias === referringNode.alias) {
        if (v.type === 'branchJunction') {
          return {
            ...referringNode,
            firstInnerAliases: v.firstInnerAliases.map((b) => {
              if (b === aliasToFind) {
                return aliasToReplace;
              } else return b;
            }),
          };
        } else if (v.type === 'workflowCondition') {
          if (v.trueAlias === aliasToFind) {
            return { ...referringNode, trueAlias: aliasToReplace };
          } else if (v.falseAlias === aliasToFind) {
            return { ...referringNode, falseAlias: aliasToReplace };
          }
          return v;
        } else if (v.type === 'loop' && v.firstInnerAlias === aliasToFind) {
          return { ...referringNode, firstInnerAlias: aliasToReplace };
        } else {
          return { ...referringNode };
        }
      } else {
        return v;
      }
    });
  }

  return workflowVertices;
};
