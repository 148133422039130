import { gql, useSubscription } from '@apollo/client';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { GLOBAL_REAL_TIME_TASKS, REAL_TIME_TASKS, SUBSCRIPTION_STATUS } from '../real-time-tasks-object';
import { subscribeToCreateClientEvent } from './graphql/subscribe-to-create-client-event';
import { RealTimeSubscriptionProps } from './types';

export const RealTimeSubscription: FC<RealTimeSubscriptionProps> = ({ userId, workspaceId }) => {
  const { error } = useSubscription<{
    subscribeToEvent: {
      id: string;
      properties: Record<string, unknown>;
      type: string;
      userId: string;
      workspaceId: string;
    };
  }>(gql(subscribeToCreateClientEvent), {
    variables: {
      workspaceId,
      userId,
    },
    shouldResubscribe: true,
    onData: ({ data }) => {
      assert(!!data.data?.subscribeToEvent, new Error(`Unknown subscription data ${data.data}`), 'ERROR');
      const task = REAL_TIME_TASKS[data.data.subscribeToEvent.id];
      delete REAL_TIME_TASKS[data.data?.subscribeToEvent.id];
      if (task) {
        task.resultAction({ resultContext: data.data.subscribeToEvent, source: 'socket' });
        return;
      }

      const globalTask = GLOBAL_REAL_TIME_TASKS[data.data.subscribeToEvent.type];

      if (globalTask) {
        globalTask.resultAction({ resultContext: data.data.subscribeToEvent, source: 'socket' });
      }
    },
  });

  SUBSCRIPTION_STATUS.hasError = !!error;

  return null;
};
