import { ValueTypes } from '@novaera/actioner-service';
import { CodeInput, NvField, NvShortTextIcon } from '@novaera/core';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import { JobNameProps } from './types';

export const JobName: React.FC<JobNameProps> = ({ context }) => (
  <PropertyPanelSection title={'Job name'} icon={<NvShortTextIcon />}>
    <NvField
      formControlStyle={{ width: '100%' }}
      parse={(v) => ({ type: ValueTypes.STRING, value: v })}
      format={(v) => v?.value}
      name={'operation.name'}
      component={<CodeInput context={context} placeholder="Enter name" />}
    />
  </PropertyPanelSection>
);
