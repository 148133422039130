import { useAddSample } from '@novaera/actioner-service';
import { AddPayloadModalForm } from '../../types';

export const usePayloadModal = (params: { workflowId: string; appId: string; onClose: () => void }) => {
  const { mutate } = useAddSample();

  const handleSubmit = (value: AddPayloadModalForm) => {
    return new Promise<void>((resolve) => {
      mutate(
        {
          appId: params.appId,
          workflowId: params.workflowId,
          content: value.content,
        },
        {
          onSettled: () => {
            params.onClose();
            resolve();
          },
        }
      );
    });
  };

  return {
    isDisabled: false,
    onSubmit: handleSubmit,
  };
};
