import { Card } from '@mui/material';
import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const Header = styled(NvFlex)``;
export const HeaderInner = styled(NvFlex)`
  padding: 16px;
`;
export const BodyWrapper = styled(NvFlex)`
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
`;
export const BodyInner = styled(NvFlex)`
  padding: 16px;
`;
export const Footer = styled(NvFlex)``;
export const FooterInner = styled(NvFlex)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;
export const ButtonWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 8px;
`;
export const Content = styled(NvFlex)`
  height: 0;
  overflow: auto;
  transition: max-height 0.3s ease-out;

  &.opened {
    height: 100%;
  }
`;

export const CardWrapper = styled(Card)`
  padding: 0px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 12px;
`;
