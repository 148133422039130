import { NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { WorkflowInputs } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/workflow-inputs';
import { useJobTargetContext } from '../provider';
import { JobTargetTaskPanelProps } from './types';

export const JobTargetTaskPanel = ({ inputProps, context }: JobTargetTaskPanelProps) => {
  const { workflowId } = useJobTargetContext();
  const { userAppId } = useParams();
  return (
    <NvFlex flexDirection={'column'} width="100%">
      {workflowId && (
        <WorkflowInputs
          onChange={inputProps.onChange}
          context={context}
          workflowId={workflowId}
          userAppId={userAppId}
          initialParameterMappings={inputProps.value?.parameterMappings ?? []}
        />
      )}
    </NvFlex>
  );
};
