import { NvCustomGiftIcon, NvFlex, NvLink, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { FreeAppInformationWrapper } from './styled';

export interface FreeAppInformationProps {}

export const FreeAppInformation: FC<FreeAppInformationProps> = () => {
  const { palette } = useTheme();
  return (
    <FreeAppInformationWrapper>
      <NvFlex gap="8px" direction="row" alignItems="center">
        <NvCustomGiftIcon htmlColor={palette.nv_new[60]} sx={{ width: '20px', height: '20px' }} />
        <NvTypography variant="h4">Free app</NvTypography>
      </NvFlex>
      <NvTypography variant="body2" flex="1 1 auto" minWidth={0}>
        This is a free app! Please note that modifications are not available.{' '}
        <NvLink href="https://actioner.com/help/free-apps" target="_blank">
          Learn more
        </NvLink>{' '}
        about Actioner free apps.
      </NvTypography>
    </FreeAppInformationWrapper>
  );
};
