import { assert } from '@novaera/utils';
import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react';

type AppConfigGenerationState = 'in_progress' | 'success' | 'failed' | undefined;

export type AppConfigGenerationData = AppConfigGenerationSuccessData | AppConfigGenerationErrorData;

export type AppConfigGenerationSuccessData = {
  appId: string;
  configId: string;
  docId: string;
  schemaId: string;
  config: Record<string, unknown>;
  success: true;
};

export type AppConfigGenerationErrorData = {
  error: string;
  success: false;
};

const AppConfigFlaggerContext = createContext<
  | {
      appConfigGenerationState: AppConfigGenerationState;
      setAppConfigGenerationState: Dispatch<SetStateAction<AppConfigGenerationState>>;
      appConfigGenerationData: AppConfigGenerationData | undefined;
      setAppConfigGenerationData: Dispatch<SetStateAction<AppConfigGenerationData | undefined>>;
      isAppConfigGenerationResultModalOpen: boolean;
      setIsAppConfigGenerationResultModalOpen: Dispatch<SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const AppConfigFlaggerProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [appConfigGenerationState, setAppConfigGenerationState] = useState<AppConfigGenerationState>();
  const [appConfigGenerationData, setAppConfigGenerationData] = useState<AppConfigGenerationData | undefined>();
  const [isAppConfigGenerationResultModalOpen, setIsAppConfigGenerationResultModalOpen] = useState<boolean>(false);

  const value = {
    appConfigGenerationState,
    setAppConfigGenerationState,
    appConfigGenerationData,
    setAppConfigGenerationData,
    isAppConfigGenerationResultModalOpen,
    setIsAppConfigGenerationResultModalOpen,
  };

  return <AppConfigFlaggerContext.Provider value={value}>{children}</AppConfigFlaggerContext.Provider>;
};

export const useAppConfigFlaggerContext = () => {
  const context = useContext(AppConfigFlaggerContext);
  assert(!!context, new Error(`useAppConfigFlaggerContext should be used within AppConfigFlaggerProvider`), 'ERROR');

  return context;
};
