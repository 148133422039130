import { Global } from '@emotion/react';
import { GetWorkflowSourceOptionsResponse, InputParameter } from '@novaera/actioner-service';
import { NvDivider, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { UseQueryResult } from '@tanstack/react-query';
import { useFormParameterProvider } from '../../../../../providers/form-parameter-provider';
import { InputParameterField } from './input-parameter-field';
import { AnimationClass } from './input-parameter-field/styled';

export const FormTriggerWorkflowRun = ({
  inputParameters,
  showHintText = false,
  results,
}: {
  inputParameters: InputParameter[];
  showHintText?: boolean;
  results?: (UseQueryResult<GetWorkflowSourceOptionsResponse, unknown> & { inputParameterId: string })[];
}) => {
  const { isInitialLoading, isLoading } = useFormParameterProvider();

  return (
    <NvFlex gap="16px">
      {isInitialLoading && (
        <>
          <NvSkeleton variant="rectangular" width={'40%'} height={'20px'} />
          <NvDivider />
          <NvSkeleton variant="rectangular" width={'80px'} height={'16px'} />
          <NvSkeleton variant="rectangular" width={'100%'} height={'40px'} />
        </>
      )}
      {inputParameters.length > 0 && showHintText && (
        <>
          <NvTypography variant="body1">Provide the input values to test the workflow.</NvTypography>
          <NvDivider />
        </>
      )}
      <Global styles={AnimationClass} />
      {inputParameters.map((inputParameter) => {
        return (
          <InputParameterField
            key={`input-parameter-field-${inputParameter.id}`}
            inputParameter={inputParameter}
            result={results?.find((result) => result.inputParameterId === inputParameter.id)}
          />
        );
      })}
      {!isInitialLoading && isLoading && (
        <>
          <NvSkeleton variant="rectangular" width={'60%'} height={'16px'} />
          <NvSkeleton variant="rectangular" width={'80%'} height={'16px'} />
          <NvSkeleton variant="rectangular" width={'40%'} height={'16px'} />
        </>
      )}
    </NvFlex>
  );
};
