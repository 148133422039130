import { Contributor, useFetchProfile, useGetAllContributors } from '@novaera/actioner-service';
import {
  AccountCircleIcon,
  DefaultRenderOption,
  FieldArray,
  NvAddBoxIcon,
  NvAutocomplete,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvImage,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { matchSorter } from 'match-sorter';
import { FC } from 'react';

export const Contributors: FC<React.PropsWithChildren<{ fieldName: string; contributors: Contributor[] }>> = ({
  fieldName,
  contributors,
}) => {
  const theme = useTheme();
  const { data: userData } = useFetchProfile();
  const { data: allContributors } = useGetAllContributors();

  return (
    <FieldArray<Contributor | undefined> name={fieldName}>
      {({ fields }) => {
        return (
          <NvFlex spacing="8px" width="50%">
            <NvFlex direction="row" gap="4px">
              <NvTypography variant="h5">Contributors</NvTypography>
              <NvTypography variant="h5" color={theme.palette.nv_error[40]}>
                *
              </NvTypography>
            </NvFlex>

            {fields.map((name, index) => {
              const field = fields.value[index];
              return (
                <NvFlex direction="row" alignItems="center" spacing="8px" key={field?.id}>
                  <NvField
                    isAutoComplete
                    name={name}
                    component={
                      <NvAutocomplete
                        disabled={field?.id === userData?.userId}
                        options={allContributors ?? []}
                        getOptionLabel={(option) => option.name ?? ''}
                        renderInput={(props) => (
                          <NvTextField
                            {...props}
                            placeholder="Select contributors"
                            {...(field && {
                              startIcon: (
                                <NvImage
                                  size="xsmall"
                                  imageShape="circle"
                                  src={field?.logoUrl}
                                  srcSet={field?.logoUrl}
                                  FallBack={<AccountCircleIcon />}
                                />
                              ),
                            })}
                          />
                        )}
                        renderOption={(props, option) => (
                          <DefaultRenderOption {...props} key={option.id}>
                            <NvFlex direction="row" alignItems="center" gap="8px">
                              <NvImage
                                size="small"
                                imageShape="circle"
                                src={option?.logoUrl}
                                srcSet={option?.logoUrl}
                                FallBack={<AccountCircleIcon />}
                              />

                              <NvTypography>{option.name}</NvTypography>
                            </NvFlex>
                          </DefaultRenderOption>
                        )}
                        popupIcon={<NvExpandMoreIcon />}
                        filterOptions={(options, { inputValue }) => {
                          const filteredOptions = options.filter((o) => !contributors.find((c) => c?.id === o?.id));
                          return matchSorter(filteredOptions, inputValue, { keys: ['name'] });
                        }}
                        isOptionEqualToValue={(option, value) => value && option.id === value.id}
                        fullWidth
                      />
                    }
                  />

                  {userData?.userId !== field?.id && (
                    <NvButton
                      onlyIcon
                      size="small"
                      color="secondary"
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      <NvCloseIcon />
                    </NvButton>
                  )}
                </NvFlex>
              );
            })}

            <NvBox marginTop="4px">
              <NvButton
                size="small"
                color="secondary"
                startIcon={<NvAddBoxIcon />}
                onClick={() => {
                  fields.push(undefined);
                }}
              >
                Add contributor
              </NvButton>
            </NvBox>
          </NvFlex>
        );
      }}
    </FieldArray>
  );
};
