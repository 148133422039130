import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvRotateRightIcon,
  NvSelect,
  NvSwitch,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';

import { noop } from 'lodash';
import { NodeType, PropertyPanelHeader, PropertyPanelSection } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { Conditions } from '../../../../components/conditions';
import { DYNAMIC_VALUE_TYPE_OPTIONS } from '../../workflow-condition-panel-drawer/workflow-condition-properties/constants';
import { CONDITION_FIELD_NAME, OPERATIONS } from './constants';
import { useLoopPanelController } from './controllers/use-loop-panel';
import { StyledNvForm } from './styled';
import { LoopNodeFormType, LoopOperatorPanelProps } from './types';

export const LoopOperatorPanel = ({ node, onCloseClicked }: LoopOperatorPanelProps) => {
  const theme = useTheme();
  const {
    onSave,
    operation,
    setShowBreakLoops,
    showBreakLoops,
    showArrayExpression,
    onOperationChange,
    formRef,
    deleteNodeAndUpdateGraph,
    workflowCodeInputContext,
  } = useLoopPanelController({ node });

  const { hasEditPermission } = useWorkflowPermission();

  return (
    <StyledNvForm
      myRef={formRef}
      onSubmit={noop}
      {...(hasEditPermission && {
        onChange: ({ values }) => {
          onSave(node, values as LoopNodeFormType);
        },
      })}
      keepDirtyOnReinitialize
      initialValues={{
        ...node,
      }}
      mutators={{ ...arrayMutators }}
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvRotateRightIcon />}
          title={node.name}
          type={NodeType.LOOP}
          onTitleChange={async (title) => {
            if (title) {
              onSave(node, { ...node, name: title });
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: node.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvRotateRightIcon />}
          title={node.name}
          type={NodeType.LOOP}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <PropertyPanelSection title="Operation">
        <NvFlex gap="8px">
          <NvSelect onChange={onOperationChange} defaultValue={operation} options={OPERATIONS}></NvSelect>
        </NvFlex>
      </PropertyPanelSection>
      {showArrayExpression && (
        <PropertyPanelSection title="Array expression">
          <NvFlex gap="8px">
            <NvField
              name="arrayExpression"
              component={<CodeInput context={workflowCodeInputContext} placeholder="{{..}}" />}
            />
          </NvFlex>
        </PropertyPanelSection>
      )}

      <Conditions
        title="Break condition"
        conditionFieldName={CONDITION_FIELD_NAME}
        hideContent={!showBreakLoops}
        context={workflowCodeInputContext}
        headerActions={
          <NvSwitch
            defaultChecked={showBreakLoops}
            onChange={(e, isChecked) => {
              setShowBreakLoops(isChecked);
              if (!isChecked) {
                const { breakCondition, ...otherNodeProps } = node;
                if (!hasEditPermission) return;
                onSave(otherNodeProps, { ...otherNodeProps });
              }
            }}
            variant="compact"
          />
        }
        valueTypeOptions={DYNAMIC_VALUE_TYPE_OPTIONS}
      />
    </StyledNvForm>
  );
};
