import { useMutation, NvAxios } from '@novaera/core';
import { ConfirmCodeParams } from '../../common/types';
import { ConfirmCodeResponse } from './types';

const confirmCode = async (props: ConfirmCodeParams) => {
  const result = await NvAxios.post<ConfirmCodeResponse>('v1/users/signup/confirm', {
    ...props,
  });
  return result.data;
};

export const useConfirmCode = () => {
  return useMutation((props: ConfirmCodeParams) => confirmCode(props));
};
