import Menu from '@mui/material/Menu';
import { styled } from '@novaera/theme-provider';
import { NvButton } from '../button';
import { NvFlex } from '../flex';
import { NvMenuItem } from '../menu-item';

export const NvStyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    ${({ theme }) => theme.elevations.e400};
    border-radius: 12px;
    margin-top: 6px;
    min-width: 200px;

    .MuiMenu-list {
      padding: 6px;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
`;

export const MenuItemNameWrapper = styled(NvFlex)`
  flex: 1 1 auto;
  min-width: 0;
`;

export const MenuButtonWrapper = styled(NvButton)`
  &.is-opened {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[50]} !important;
  }
`;

export const MenuItemStyled = styled(NvMenuItem, {
  shouldForwardProp: (prop) => {
    return prop !== 'isSelected';
  },
})<{
  isSelected?: boolean;
}>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.nv_main[20] : theme.palette.nv_neutral[0]};
  transition: background-color 200ms ease;
  border-radius: 6px;

  &:hover {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.nv_main[20] : theme.palette.nv_neutral[20]};
  }
`;
