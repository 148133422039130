import { NvAxios, useQuery } from '@novaera/core';
import { QUERY_KEYS_DATA_MODEL } from '../keys';

import { GetDataModelsParams, GetDataModelsResponse } from '../common/types';
import { DATA_MODEL_ROOT_PATH } from '../constants';

const getDataModels = async ({ appId }: GetDataModelsParams) => {
  const result = await NvAxios.get<GetDataModelsResponse>(`${DATA_MODEL_ROOT_PATH(appId)}`);
  return result?.data;
};

export const useGetDataModels = ({ appId, enabled = true }: GetDataModelsParams) => {
  return useQuery<GetDataModelsResponse>(QUERY_KEYS_DATA_MODEL.list(appId), () => getDataModels({ appId }), {
    enabled,
  });
};
