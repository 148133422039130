import { TextField } from '@mui/material';
import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';
import { NvTextField } from '.';
import { NvTextFieldProps } from './types';
const props = {
  shouldForwardProp: shouldForwardPropForFormFields,
};
export const StyledTextField = styled(TextField, props)<NvTextFieldProps>`
  &.MuiTextField-root {
    .MuiInput-root {
      display: flex;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 6px;
      color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
      padding: 0 12px;
      height: 32px;
      transition: background-color 0.4s ease, border-color 0.5s ease, box-shadow 0.6s ease;

      ${({ disabled }) => disabled && 'opacity:0.5'};

      &.MuiInputBase-multiline {
        padding: 0;
      }

      &.MuiInputBase-colorGhost {
        background-color: transparent;
        :hover:not(.Mui-disabled) {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
        }
      }

      &.MuiInputBase-sizeSmall {
        height: 32px;

        &.MuiInputBase-multiline {
          height: auto;
          min-height: 32px;
          padding-top: 1px;
          padding-bottom: 1px;
        }
      }

      &.MuiInputBase-sizeMedium {
        height: 32px;

        &.MuiInputBase-multiline {
          height: auto;
          min-height: 32px;
          padding: 0;
        }
      }

      &.MuiInputBase-sizeLarge {
        height: 40px;

        &.MuiInputBase-multiline {
          height: auto;
          min-height: 40px;
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }

      &.Mui-focused {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }

      &:focus-within:not(.Mui-disabled),
      &:active:not(.Mui-disabled) {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        ${({ theme }) => theme.mixins.focus}

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        }
      }

      :hover:not(.Mui-disabled) {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      }

      ::before {
        border: unset;
      }

      ::after {
        border: unset;
      }

      :hover:not(.Mui-disabled):before {
        border: unset;
      }

      &.Mui-error {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        border: 1px solid ${({ theme }) => theme.palette.nv_error[40]};
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        ${({ theme }) => theme.mixins.error}

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        }
      }

      //  With icon styling

      .MuiInputAdornment-sizeSmall {
        width: auto;
        height: 16px;
        margin-right: 8px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .MuiInputAdornment-sizeMedium {
        width: auto;
        height: 20px;
        margin-right: 8px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .MuiInputAdornment-sizeLarge {
        width: auto;
        height: 24px;
        margin-right: 8px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &.MuiInputBase-adornedStart {
        &.MuiInput-root {
          padding-left: 6px;

          .MuiInputAdornment-sizeLarge {
            margin-left: 2px;
          }
        }
      }

      &.MuiInputBase-adornedEnd {
        &.MuiInput-root {
          padding-right: 40px;

          .MuiInputAdornment-sizeLarge {
            margin-right: 2px;
          }
        }
      }
    }

    .hasLabelAdornment {
      .MuiInputAdornment-root {
        margin-right: 6px;
      }

      .MuiInputAdornment-positionStart {
        &::after {
          content: '';
          padding-left: 6px;
          height: 30px;
          border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .hasEndAdornment {
      padding-right: 4px;
      .MuiInputAdornment-positionEnd {
        margin-right: 0px;
      }
    }

    input.MuiInput-input {
      &.MuiInputBase-input {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    textarea.MuiInput-input {
      padding: 6px 12px;
    }

    .MuiInput-input {
      padding: 0;
      height: auto;
      line-height: 20px;
      font-size: 14px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme, disabled }) =>
          disabled ? theme.palette.nv_neutral_alpha[200] : theme.palette.nv_neutral_alpha[60]};
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
      }

      &.MuiInputBase-inputSizeSmall {
        line-height: 18px;
        font-size: 12px;
      }

      &.MuiInputBase-inputSizeMedium {
        line-height: 20px;
        font-size: 14px;
      }

      &.MuiInputBase-inputSizeLarge {
        line-height: 20px;
        font-size: 14px;
      }

      &.Mui-disabled {
        cursor: not-allowed;
        -webkit-text-fill-color: inherit;
      }
    }

    .MuiInputAdornment-root {
      color: inherit;

      .MuiSvgIcon-fontSizeSmall {
        width: 16px;
        height: 16px;
      }
      .MuiSvgIcon-fontSizeMedium {
        width: 20px;
        height: 20px;
      }
      .MuiSvgIcon-fontSizeLarge {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const DateTimePickerDefaultTextField = styled(NvTextField)`
  &.MuiTextField-root {
    .MuiInput-root {
      &.MuiInputBase-adornedStart {
        .MuiButtonBase-root {
          margin: 0;
          padding: 0;
        }

        .MuiInputAdornment-sizeSmall {
          width: 16px;
          height: 16px;
          margin-left: 0px;
          margin-right: 8px;
        }

        .MuiInputAdornment-sizeMedium {
          width: 20px;
          height: 20px;
          margin-left: 0px;
          margin-right: 8px;
        }

        .MuiInputAdornment-sizeLarge {
          width: 24px;
          height: 24px;
          margin-left: 0px;
          margin-right: 8px;
        }
      }
    }
  }
`;
