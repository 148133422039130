import { NvAxios, useQuery } from '@novaera/core';
import { SUBSCRIPTIONS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_SWITCH_TYPE } from '../keys';
import { GetSwitchTypeParams, SubscriptionSwitchType } from './types';

const getSwitchType = async ({ fromBillingCycle, fromPlanId, toBillingCycle, toPlanId }: GetSwitchTypeParams) => {
  const result = await NvAxios.get<SubscriptionSwitchType>(`${SUBSCRIPTIONS_ROOT_PATH}/switch-type`, {
    params: {
      'from-plan-id': fromPlanId,
      'from-billing-cycle': fromBillingCycle,
      'to-plan-id': toPlanId,
      'to-billing-cycle': toBillingCycle,
    },
  });
  return result?.data;
};

export const useGetSwitchType = (params: GetSwitchTypeParams) => {
  return useQuery<SubscriptionSwitchType>(QUERY_KEYS_SWITCH_TYPE.detail(params), () => getSwitchType(params), {
    enabled: !!params.fromBillingCycle && !!params.fromPlanId && !!params.toBillingCycle && !!params.toPlanId,
  });
};
