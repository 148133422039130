import {
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvConditionalWrap,
  NvCustomEmptyIcon,
  NvCustomStringFieldIcon,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvSkeleton,
  NvTooltip,
  NvTypography,
  Portal,
} from '@novaera/core';
import { noop } from '@novaera/utils';
import React from 'react';
import { RowItemCard } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/common/row-item-card';
import { SimpleLabelLayout } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/common/row-item-card/row-item-layouts';
import {
  PPDrawerItem,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
} from '../../property-panel';
import { RECORD_FIELDS_ROW_ITEM_WARNING_MESSAGE } from '../common/constants';
import { RecordPartialUpdateUIComponentParams } from '../use-ui-component/types';
import { useRecordPartialUpdateController } from './controllers/use-record-partial-update-controller';
import { RecordField } from './record-field';
import { RecordMenuItems } from './record-menu-items';
import { RecordPartialUpdateFormValues } from './types';

export const RecordPartialUpdateUIComponent: React.FC<
  React.PropsWithChildren<RecordPartialUpdateUIComponentParams>
> = ({ inputProps, context }) => {
  const {
    theme,
    value,
    selectedRecordFields,
    isOpen,
    isDisabled,
    isPanelOpen,
    isRecordParametersLoading,
    wrapperElementRef,
    handleOpenFieldsToUpdate,
    handleCloseFieldsToUpdate,
    handleFormOnChange,
    handleAddNewRecordField,
    handleDeleteRecordField,
  } = useRecordPartialUpdateController({ inputProps });

  return isRecordParametersLoading ? (
    <NvSkeleton variant="rectangular" height="44px" />
  ) : (
    <>
      <NvConditionalWrap
        condition={isDisabled}
        wrap={(children) => (
          <NvTooltip title={RECORD_FIELDS_ROW_ITEM_WARNING_MESSAGE} children={<NvBox>{children}</NvBox>} />
        )}
      >
        <RowItemCard
          isSelected={isOpen}
          rowItemLeftContentProps={{ draggable: false, icon: <NvCustomStringFieldIcon /> }}
          rowItemContent={<SimpleLabelLayout simpleLabel="Fields" />}
          isDisabled={isDisabled}
          onClick={() => {
            if (isOpen && isPanelOpen) {
              handleCloseFieldsToUpdate();
            } else {
              handleOpenFieldsToUpdate();
            }
          }}
        />
      </NvConditionalWrap>
      {isOpen && (
        <Portal node={wrapperElementRef.current}>
          <PPDrawerItem zIndex="1000" className={isPanelOpen ? 'open' : 'close'}>
            <NvForm<RecordPartialUpdateFormValues> onSubmit={noop} onChange={handleFormOnChange} initialValues={value}>
              <PropertyPanelHeader
                title="Fields"
                icon={<NvCustomStringFieldIcon />}
                actions={
                  <NvButton onlyIcon size="small" color="secondary" onClick={handleCloseFieldsToUpdate}>
                    <NvCloseIcon />
                  </NvButton>
                }
              />
              <PropertyPanelSimpleSection>
                <NvTypography variant="body2">
                  To update the selected record, provide the new values for the inputs below.
                </NvTypography>
              </PropertyPanelSimpleSection>
              <PropertyPanelListHeader
                title="Update Fields"
                tooltip="Selected fields below to be updated with the given values."
                actions={
                  <NvMenuWithItems
                    triggerButton={{
                      content: <NvAddBoxIcon />,
                      props: { size: 'small', color: 'ghost', onlyIcon: true },
                    }}
                    menuItems={
                      <RecordMenuItems
                        filterMenuItems={(item) =>
                          !!selectedRecordFields.find((recordField) => recordField.id === item.id)
                        }
                        onItemClick={handleAddNewRecordField}
                      />
                    }
                  />
                }
              />
              {selectedRecordFields.length === 0 ? (
                <PropertyPanelSimpleSection>
                  <NvFlex direction="row" alignItems="center" gap="8px">
                    <NvCustomEmptyIcon
                      sx={{ width: '16px', height: '16px' }}
                      htmlColor={theme.palette.nv_neutral[60]}
                    />
                    <NvTypography variant="body2" textColor="ghost">
                      There is no fields
                    </NvTypography>
                  </NvFlex>
                </PropertyPanelSimpleSection>
              ) : (
                selectedRecordFields.map((field) => (
                  <RecordField
                    context={context}
                    key={`record-field-${field.id}`}
                    field={field}
                    onDelete={handleDeleteRecordField}
                  />
                ))
              )}
            </NvForm>
          </PPDrawerItem>
        </Portal>
      )}
    </>
  );
};
