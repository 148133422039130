import { GridColumns } from '@mui/x-data-grid';
import { BaseFieldType, FieldType } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { NvFlex, NvTypography, RenderCellParams, RenderHeaderParams } from '@novaera/core';
import { MemoizedRecordCell } from './record-cell';
import { MemoizedRecordItemAction } from './record-item-action';
import { RecordsTableHeaderIcon } from './records-table-header-icon';
import { StickyHeader } from './sticky-header';

export const getRecordColumns = <RecordItemType, FieldTypeType extends BaseFieldType = FieldType>(
  model?: { name: string; fields?: FieldTypeType[] },
  actionColumn?: {
    onRecordEditClick: (record: RecordItemType) => void;
  }
) => {
  if (model && model.fields) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: GridColumns<any> = [
      ...model.fields.map((f) => ({
        field: f.name,
        headerName: f.name,
        minWidth: f.schema.type === SchemaType.array || f.schema.type === SchemaType.map ? 300 : 150,
        flex: 1,
        sortable:
          f.schema.type === SchemaType.array ||
          f.schema.type === SchemaType.map ||
          f.schema.type === SchemaType.recordReference
            ? false
            : true,
        renderCell: ({ formattedValue, id }: RenderCellParams) => (
          <MemoizedRecordCell
            id={id as string}
            name={f.name}
            value={formattedValue}
            type={f.schema.type}
            {...(f.schema.type === SchemaType.dateTime ? { format: f.schema.format } : {})}
          />
        ),
        renderHeader: ({ colDef }: RenderHeaderParams) => (
          <NvFlex direction="row" alignItems="center" gap="2px">
            <RecordsTableHeaderIcon type={f.schema.type} />
            <NvTypography variant="h5" textColor="secondary">
              {colDef.headerName}
            </NvTypography>
          </NvFlex>
        ),
      })),
    ];

    if (actionColumn && actionColumn.onRecordEditClick) {
      columns.push({
        field: 'muiDataGridActionsField',
        sortable: false,
        headerClassName: 'stick-to-right',
        cellClassName: 'stick-to-right',
        width: 36,
        minWidth: 36,
        renderCell: ({ value: record }: RenderCellParams<(RecordItemType & { id: string }) | undefined>) => (
          <MemoizedRecordItemAction<RecordItemType>
            onRecordEditClick={actionColumn.onRecordEditClick}
            record={record}
            modelName={model.name}
          />
        ),
        headerName: '',
        renderHeader: () => <StickyHeader />,
      });
    }

    return columns;
  } else {
    return [];
  }
};
