import { NvFlex } from '@novaera/core';

export const DetailLayoutBody: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <NvFlex height="100%" overflow={'auto'} flex="1 1 auto" minWidth={0} paddingBottom="60px">
      <NvFlex flex="1 1 auto" padding="0 40px" alignItems="center">
        <NvFlex maxWidth="768px" gap="32px" width="100%">
          {children}
        </NvFlex>
      </NvFlex>
    </NvFlex>
  );
};
