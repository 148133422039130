import { isRequired, NvField, NvFlex, NvTextField } from '@novaera/core';

export const RequestVariableFieldBody = (props: { fieldName: string }) => {
  return (
    <NvFlex gap={'8px'}>
      <NvField
        name={`label`}
        labelText="Label"
        isRequired
        hasRequiredIndicator
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        direction="label-on-top"
        component={<NvTextField />}
      />
      <NvField
        name={`data.key`}
        labelText="Key"
        isRequired
        hasRequiredIndicator
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        direction="label-on-top"
        component={<NvTextField />}
      />
      <NvField
        name={`data.value`}
        labelText="Value"
        isRequired
        hasRequiredIndicator
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        direction="label-on-top"
        component={<NvTextField />}
      />
    </NvFlex>
  );
};
