import { NvAxios, useQuery } from '@novaera/core';
import { isUndefined } from 'lodash';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_EVENT_RULES } from '../keys';
import { GetIntegrationEventRulesParams, GetIntegrationEventRulesResponse } from './types';

const getIntegrationEventRules = async ({ integrationId, version, includeDrafts }: GetIntegrationEventRulesParams) => {
  const result = await NvAxios.get(`${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}`, {
    params: {
      ...(!isUndefined(version) ? { version } : {}),
      ...(!isUndefined(includeDrafts) ? { includeDrafts } : {}),
    },
  });
  return result?.data;
};

export const useGetIntegrationEventRules = ({
  integrationId,
  version,
  includeDrafts,
}: GetIntegrationEventRulesParams) => {
  return useQuery<GetIntegrationEventRulesResponse>(
    QUERY_KEYS_INTEGRATION_EVENT_RULES.list(integrationId),
    () => getIntegrationEventRules({ integrationId, version, includeDrafts }),
    { enabled: !!integrationId }
  );
};
