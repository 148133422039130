import { assert } from '@novaera/utils';
import { Children, ReactFragment, ReactNode, ReactPortal } from 'react';

interface UseSlotHookOptions {
  children: ReactNode | Array<ReactNode>;
  name: string;
  fallback?: ReactNode;
}

export const useSlot = (options: UseSlotHookOptions) => {
  const children = Children.toArray(options.children);
  const predicate = (child: React.ReactElement | number | string | ReactFragment | ReactPortal) => {
    assert(
      typeof child !== 'string' && typeof child !== 'number' && 'props' in child && child.props?.name,
      new Error('A slot child should have a name prop'),
      'ERROR'
    );

    return child?.props?.name === options.name;
  };

  const result = children.find(predicate);
  assert(
    !!result && typeof result !== 'string' && typeof result !== 'number' && 'props' in result,
    new Error('A slot child should have props'),
    'WARNING'
  );

  if (result?.props?.children) {
    return result.props?.children;
  }

  if (options.fallback) {
    return options.fallback;
  }

  return null;
};
