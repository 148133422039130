import { DynamicInputComponent, useGetIntegrationAction } from '@novaera/actioner-service';

import { NvActionFilledIcon, NvFlex, NvTypography, useField } from '@novaera/core';
import { useParams } from '@novaera/route';
import { FC } from 'react';
import {
  ActionParameterMapper,
  ActionSearchField,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
  TestActionProvider,
  TestActionResult,
} from '../../../../../components';
import { useTestConfigurationProvider } from '../../../../../components/test-configuration-bar/providers/test-configuration';
import { useIntegrationCodeFieldContext } from '../../../integration-code-field/controllers/use-integration-code-field-context';

export const DynamicFieldsDataSource: FC<React.PropsWithChildren<{ integrationVersion: number }>> = ({
  integrationVersion,
}) => {
  const { integrationId } = useParams();
  const { codeFieldContext } = useIntegrationCodeFieldContext();
  const {
    input: {
      value: { dataSource },
      value,
      onChange,
    },
  } = useField<DynamicInputComponent>('uiComponent');
  const { data: action } = useGetIntegrationAction({
    actionId: dataSource.actionId,
    integrationId,
    includeDraft: false,
    version: integrationVersion,
  });
  const {
    state: { selectedConnection },
  } = useTestConfigurationProvider();

  return (
    <NvFlex>
      <PropertyPanelSimpleSection>
        <NvFlex flexDirection="row" alignItems="center" gap="4px">
          <NvActionFilledIcon fontSize="small" />
          <NvTypography variant="h5" flex="1 1 auto">
            Action
          </NvTypography>
        </NvFlex>
        <ActionSearchField integrationId={integrationId} version={1} fieldName={`uiComponent.dataSource.actionId`} />
      </PropertyPanelSimpleSection>

      {dataSource.actionId && (
        <TestActionProvider>
          {/* Open it after test with sample context feature will be implemented */}
          {/* <TestActionButton
            testActionParams={{
              actionId: dataSource.actionId,
              integrationId,
              connectionId: selectedConnection?.id,
              formId,
            }}
          /> */}

          <PropertyPanelListHeader title="Action inputs" />
          <PropertyPanelSimpleSection>
            <ActionParameterMapper
              type="in-integration"
              connectionId={selectedConnection?.id}
              key={`action-designer-${dataSource.actionId}`}
              actionId={dataSource.actionId}
              integrationId={integrationId}
              versionNumber={integrationVersion}
              onParameterMappingsChanged={({ inputParametersValues: parameterMappings }) => {
                // The backend does not support dynamic fields in the dynamic data source actions. so we don't need to send formId here.
                onChange({
                  ...value,
                  dataSource: { ...value.dataSource, parameterMappings },
                });
              }}
              initialParameterMappings={dataSource.parameterMappings}
              context={codeFieldContext}
            />
          </PropertyPanelSimpleSection>
          <TestActionResult
            sampleResponse={action?.responseConfiguration?.exampleResponse}
            tooltip="The response displayed below is solely a sample."
          />
        </TestActionProvider>
      )}
    </NvFlex>
  );
};
