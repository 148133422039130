import { NvButton, NvCloseIcon, NvFlex, NvReactJson } from '@novaera/core';
import { PropertyPanelHeader } from '../../../../../../../../../components';

export const ActionerEventNodeSchemaProperties = ({
  json,
  title,
  onCloseClicked,
}: {
  title: string;
  json?: string;
  onCloseClicked: () => void;
}) => {
  return (
    <NvFlex width="100%" height={'100%'}>
      <PropertyPanelHeader
        title={title}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />

      <NvReactJson src={json ? JSON.parse(json) : {}} />
    </NvFlex>
  );
};
