import { SchemaType } from '@novaera/ah-common';
import { NestedDropdownItem } from '@novaera/core';
import { MENU_ITEMS } from './constants';

export const findLogoFromFields = (schemaType: SchemaType) => {
  return MENU_ITEMS.reduce<NestedDropdownItem[]>((prev, curr) => {
    const { items = [], ...rest } = curr;
    return [...prev, rest, ...items];
  }, []).find((m) => (m.id as SchemaType) === schemaType)?.leftIcon;
};
