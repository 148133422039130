import { WorkflowWithState } from '@novaera/actioner-service';
import { NvButton, NvConditionalRender, NvFlex } from '@novaera/core';
import { useWorkflowReferenceOutputProvider } from '../../../providers/workflow-reference-output-provider';

export const WorkflowRunPanelFooter = ({
  isLoading,
  workflow,
  onCloseClicked,
  currentPage,
  isResult,
}: {
  isLoading: boolean;
  onCloseClicked: () => void;
  workflow?: WorkflowWithState;
  currentPage: number;
  isResult?: boolean;
}) => {
  const { onBackClicked } = useWorkflowReferenceOutputProvider();

  const hasMoreBackPage = currentPage > 1;

  return (
    <>
      <NvConditionalRender when={!isResult && !isLoading}>
        <NvFlex flexDirection={'row'} gap="8px">
          <NvConditionalRender when={hasMoreBackPage}>
            <NvButton
              color="secondary"
              onClick={() => {
                onBackClicked(currentPage);
              }}
            >
              Back
            </NvButton>
          </NvConditionalRender>
          <NvButton type="submit" color="primary">
            {workflow?.trigger?.type === 'form' ? workflow.trigger.runButtonLabel : 'Run'}
          </NvButton>
          <NvButton color="ghost" onClick={onCloseClicked}>
            Cancel
          </NvButton>
        </NvFlex>
      </NvConditionalRender>
      <NvConditionalRender when={Boolean(isResult)}>
        <NvConditionalRender when={hasMoreBackPage}>
          <NvButton
            color="secondary"
            onClick={() => {
              onBackClicked(currentPage);
            }}
          >
            Back
          </NvButton>
        </NvConditionalRender>
        <NvButton color="secondary" onClick={onCloseClicked}>
          Close
        </NvButton>
      </NvConditionalRender>
      <NvConditionalRender when={isLoading}>
        <NvButton color="secondary" onClick={onCloseClicked}>
          Close
        </NvButton>
      </NvConditionalRender>
    </>
  );
};
