import { ROUTE_DEFAULTS } from '@novaera/constants';
import { NvFlex, NvForm } from '@novaera/core';
import { useLocation, useNavigate } from '@novaera/route';
import { useState } from 'react';
import { useSignIn } from '../../controllers/use-sign-in';
import { SIGN_IN_STATE } from '../../controllers/use-sign-in/types';
import { LoadingPage } from '../loading-page';
import { NvProductLogoWithName } from '../product-icon/styled';
import { CodeEntrance } from './code-entrance';
import { SignInFormFlex, SignInWrapper } from './styled';
import { SignInFormValues, SignInProps } from './types';
import { Welcome } from './welcome';
import { WorkspacePanel } from './workspace-panel';

export const SignIn: React.FC<React.PropsWithChildren<SignInProps>> = ({ render, fromSignUp }) => {
  const [username, setUsername] = useState('');
  const location = useLocation();

  const {
    error,
    onSignInClick,
    signInState,
    hasAccount,
    identityProviders,
    onProviderButtonClick,
    showSignInForm,
    isLoading,
    isVerifyLoading,
    resetLoginFlow,
    isIdentityProvidersLoading,
  } = useSignIn({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialSignInState: (location.state as any)?.signInState ?? SIGN_IN_STATE.INITIAL,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    username: (location.state as any)?.username,
    fromSignUp,
  });

  const navigate = useNavigate();

  if (render) {
    return render({ onSignInClick });
  }

  if (isVerifyLoading) {
    return <LoadingPage />;
  }

  if (signInState === SIGN_IN_STATE.REDIRECT_TO_DASHBOARD) {
    navigate(ROUTE_DEFAULTS.REDIRECTING_TO_DASHBOARD);
    return null;
  }

  return (
    <SignInWrapper>
      <SignInFormFlex>
        {showSignInForm && (
          <NvForm<SignInFormValues>
            onSubmit={(formValues) => {
              onSignInClick(formValues);
              setUsername(formValues.username);
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initialValues={{ username: (location.state as any)?.username ?? '', code: '' }}
          >
            {({ form }) => (
              <>
                <NvFlex marginBottom="40px">
                  <NvProductLogoWithName />
                </NvFlex>
                {signInState === SIGN_IN_STATE.INITIAL && (
                  <Welcome
                    identityProviders={identityProviders}
                    onProviderButtonClick={onProviderButtonClick}
                    isLoading={isLoading}
                    error={error}
                    fromSignUp={fromSignUp}
                    isIdentityProvidersLoading={isIdentityProvidersLoading}
                  />
                )}
                {signInState === SIGN_IN_STATE.IN_CODE && (
                  <CodeEntrance
                    hasAccount={hasAccount}
                    isLoading={isLoading}
                    error={error}
                    onCancelClicked={() => {
                      form.reset();
                      resetLoginFlow();
                    }}
                  />
                )}
                {signInState === SIGN_IN_STATE.SSO_REDIRECT && <LoadingPage message="Waiting for SSO redirection" />}
              </>
            )}
          </NvForm>
        )}
        {signInState === SIGN_IN_STATE.CHOOSE_WORKSPACE && (
          <>
            <NvFlex marginBottom="40px">
              <NvProductLogoWithName />
            </NvFlex>
            <WorkspacePanel username={username} />
          </>
        )}
      </SignInFormFlex>
    </SignInWrapper>
  );
};
