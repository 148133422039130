enum Routes {
  Integrations,
  AppDirectory,
  UserApps,
  UserAppSetup,
  Workspace,
  SlackUsers,
  Admin,
  Impersonate,
  SlackVerification,
  MsTeamsConsentComplete,
  MsTeamsCode,
  NotFound404,
  InvalidWorkspace,
  SuspendedWorkspace,
  OAuthRedirection,
  PostMessage,
  Redirection,
  ProfileSettings,
  MissingConnectionPage,
  Catalog,
  RunWorkflowFromLink,
}
export const ROUTES: Record<keyof typeof Routes, string> = {
  Integrations: '/integrations',
  AppDirectory: '/app-directory',
  UserApps: '/my-apps',
  UserAppSetup: `/my-apps/:id/setup`,
  Workspace: '/workspace',
  SlackUsers: '/slack/users',
  Admin: '/admin',
  Impersonate: '/impersonate',
  SlackVerification: '/workspace/slack/access',
  MsTeamsConsentComplete: '/workspace/ms-teams/consent-complete',
  MsTeamsCode: '/workspace/ms-teams/code',
  NotFound404: '/error/404',
  InvalidWorkspace: '/error/invalid-workspace',
  SuspendedWorkspace: '/error/suspended-workspace',
  OAuthRedirection: '/oauth/code',
  PostMessage: '/post-message',
  Redirection: `/redirection`,
  ProfileSettings: '/settings',
  MissingConnectionPage: '/my-apps/:userAppId/workflows/:workflowId/missing-connections',
  Catalog: '/catalog',
  RunWorkflowFromLink: '/workflow-forms',
};
