import { HourlyJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { DynamicRateField } from '../../dynamic-rate-field';
import { RecurrenceProps } from '../../types';

export const DynamicHourlyRecurrence: React.FC<RecurrenceProps<HourlyJobRecurrenceConfiguration>> = ({
  recurrenceConfiguration,
  context,
  onChange,
}) => (
  <NvFlex gap="8px" width="100%">
    <DynamicRateField
      value={recurrenceConfiguration.recurrenceMinute}
      onChange={(v) => {
        onChange({
          ...recurrenceConfiguration,
          recurrenceMinute: v,
        });
      }}
      context={context}
      label="Minute"
    />
    <DynamicRateField
      value={recurrenceConfiguration.repeatInterval}
      onChange={(v) => {
        onChange({
          ...recurrenceConfiguration,
          repeatInterval: v,
        });
      }}
      context={context}
      label="Repeats"
    />
  </NvFlex>
);
