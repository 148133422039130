import { BooleanValue, ParameterTypes, SimpleParameterMapping, ValueTypes } from '@novaera/actioner-service';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export const useCheckboxFieldAdapter = ({
  extractValue = false,
  hidden,
}: {
  extractValue?: boolean;
  hidden: boolean;
}) => {
  const formatValue = useCallback((value: BooleanValue | undefined | null) => {
    if (value) {
      return value.value;
    } else {
      return null;
    }
  }, []);

  const parseValue = useCallback(
    ({ id }: { id: string }) =>
      (valueToParse: boolean | null) => {
        if (!isNil(valueToParse)) {
          const result: BooleanValue = {
            type: ValueTypes.BOOLEAN,
            value: valueToParse,
            displayValue: `${valueToParse}`,
          };
          return result;
        } else {
          return undefined;
        }
      },
    []
  );

  const format = useCallback(
    (parameterMapping: SimpleParameterMapping<BooleanValue> | undefined | null) => {
      if (parameterMapping) {
        return formatValue(parameterMapping.value);
      } else {
        return false;
      }
    },
    [formatValue]
  );

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (valueToParse: boolean | null): SimpleParameterMapping<BooleanValue> | undefined => {
        const result = parseValue({ id })(valueToParse);
        if (result || hidden) {
          return {
            parameterId: id,
            type: ParameterTypes.SIMPLE,
            hidden,
            ...(result && { value: result }),
          };
        } else {
          return undefined;
        }
      },
    [hidden, parseValue]
  );

  return { format: extractValue ? formatValue : format, parse: extractValue ? parseValue : parse };
};
