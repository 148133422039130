import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppPermissionsCard = styled(NvBox)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  overflow: hidden;
`;

export const AppPermissionsRow = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  padding: 8px 12px;
  gap: 24px;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const AppPermissionsRowLeft = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex: 1 1 auto;
  min-width: 0;
`;

export const AppPermissionsRowRight = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 0 0 auto;
`;

export const AppPermissionsUserBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
