import {
  DynamicValueRule,
  JavascriptRuleEvaluationResult,
  Rule,
  RuleEvaluationResult,
  SingleValueRule,
} from '@novaera/actioner-service';
import { isUndefined } from 'lodash';

export const isJavascriptRuleEvaluationResult = (
  result: RuleEvaluationResult | JavascriptRuleEvaluationResult
): result is JavascriptRuleEvaluationResult => {
  return 'function' in result;
};

export const isSingleRuleValue = (value: Rule['value']): value is SingleValueRule => {
  return !isUndefined(value) && 'value' in value;
};

export const isDynamicRuleValue = (value: Rule['value']): value is DynamicValueRule => {
  return !isUndefined(value) && 'valueExpression' in value;
};
