import { useGetMsTeamsConsent } from '@novaera/actioner-service';
import { ConnectChatToolButtonBase } from '../connect-chat-tool-base';

export const ConnectMsTeamsButton: React.FC<{ ButtonBase: React.FC<{ onClick: () => void }> }> = ({ ButtonBase }) => {
  const { mutate: getMsTeamsConsent } = useGetMsTeamsConsent();
  return (
    <ConnectChatToolButtonBase
      type="ms-teams"
      ButtonBase={ButtonBase}
      onClick={() => {
        const popupWindow = window.open('/app-loading', '_blank', 'width=700,height=800');
        getMsTeamsConsent(undefined, {
          onSuccess: ({ consentUrl }) => {
            if (popupWindow) {
              popupWindow.location.href = consentUrl;
            }
          },
          onError: () => {
            popupWindow?.close();
          },
        });
      }}
    />
  );
};
