import { css } from '@emotion/css';
import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const FocusStateHeader = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 40px 0 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
  position: sticky;
  top: -24px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  z-index: 10;
`;

export const FocusStateBody = css`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
  padding: 0 32px;
  max-width: 1440px;
`;

export const FocusStateBodyFullWidth = css`
  padding: 0;
  max-width: unset;
  height: 100vh;
`;
