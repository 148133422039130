import { NvAutocomplete, NvAutocompleteProps } from '@novaera/core';
import { useState } from 'react';

export function CommandRequestAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({ ...props }: NvAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onOpen = ({ ...props }) => {
    setIsOpen(true);
    props.onOpen?.(props);
  };
  const onClose = ({ ...props }) => {
    setIsOpen(false);
    props.onClose?.(props);
  };
  return <NvAutocomplete open={isOpen} onOpen={onOpen} onClose={onClose} openOnFocus {...props} />;
}
