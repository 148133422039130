import { Timezone } from '../timezones';
import { SimpleParameterMapping } from '../types';

export enum JobType {
  RECURRING = 'recurring',
  SCHEDULED = 'scheduled',
}

export enum JobCreatorType {
  ACTION = 'action',
  WORKFLOW = 'workflow',
  USER = 'user',
}

export type JobCreator = {
  type: JobCreatorType;
  id: string;
  displayName: string;
};

export interface JobCreatorUser extends JobCreator {
  type: JobCreatorType.USER;
  logoUrl: string;
}

type JobTarget = {
  type: JobTargetType;
  id: string;
  displayName: string;
};

export interface WorkflowJobTarget extends Omit<JobTarget, 'displayName' | 'type'> {
  type: JobTargetType.WORKFLOW;
  eventPayload?: string;
  formId?: string;
  parameters?: SimpleParameterMapping[];
}

export enum JobTargetType {
  WORKFLOW = 'workflow',
}

export enum JobRecurrenceEndType {
  NUMBER_OF_TIMES = 'number-of-times',
  DATE_TIME = 'date-time',
  NEVER = 'never',
}

export type RecurrenceEndNever = {
  type: JobRecurrenceEndType.NEVER;
};

export type RecurrenceEndDate = {
  type: JobRecurrenceEndType.DATE_TIME;
  at: string;
};

export type RecurrenceEndNumberOfTimes = {
  type: JobRecurrenceEndType.NUMBER_OF_TIMES;
  times: string;
};

export enum RecurrenceType {
  CRON = 'cron',
  RATE = 'rate',
}

export enum RateRecurrenceUnit {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export type BaseRecurrence = {
  end: RecurrenceEndDate | RecurrenceEndNumberOfTimes | RecurrenceEndNever;
};

export interface RateRecurrence extends BaseRecurrence {
  type: RecurrenceType.RATE;
  value: string;
  recurrenceTimeHour: number | null;
  recurrenceTimeMinute: number | null;
  unit: RateRecurrenceUnit;
}

export interface CronRecurrence extends BaseRecurrence {
  type: RecurrenceType.CRON;
  cron: string;
}

export type Recurrence = CronRecurrence | RateRecurrence;

type BaseJob = {
  id: string;
  name: string;
  creator: JobCreator | JobCreatorUser;
  target: JobTarget;
  timezone: Timezone;
};

export interface RecurringJob extends BaseJob {
  type: JobType.RECURRING;
  recurrence: Recurrence;
  timeDisplay: { runsAt: string; period: string; nextExecution: string };
}

export interface RecurringJobDetail extends Omit<RecurringJob, 'target'> {
  target: WorkflowJobTarget;
}

export interface ScheduledJob extends BaseJob {
  type: JobType.SCHEDULED;
  triggerAt: string;
  timeDisplay: { runsAt: string };
}

export interface ScheduledJobDetail extends Omit<ScheduledJob, 'target'> {
  target: WorkflowJobTarget;
}

export enum RecurrenceTimeUnit {
  MINUTE = 'minutely',
  HOUR = 'hourly',
  DAY = 'daily',
  CUSTOM = 'customCron',
  WEEKDAYS = 'weekdays',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ANNUAL = 'yearly',
}
