import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DeleteDataModelParam, GetDataModelsResponse } from '../common/types';
import { DATA_MODEL_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DATA_MODEL } from '../keys';

const deleteDataModel = async (params: DeleteDataModelParam) => {
  const { appId, modelId } = params;
  const result = await NvAxios.delete(`${DATA_MODEL_ROOT_PATH(appId)}/${modelId}`);
  return result?.data;
};

export const useDeleteDataModel = () => {
  const cache = useQueryClient();
  return useMutation(deleteDataModel, {
    onSuccess: (_, { modelId, appId }) => {
      cache.setQueryData<GetDataModelsResponse>(QUERY_KEYS_DATA_MODEL.list(appId), (old) =>
        old ? { ...old, models: old.models.filter(({ id }) => id !== modelId) } : undefined
      );
    },
  });
};
