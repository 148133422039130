import { ParameterMapping } from '..';

export interface JobTarget {
  eventPayload?: Record<string, unknown>;
  parameterMappings?: ParameterMapping[];
  formId?: string;
}

export interface JobTargetParameterMappingParam {
  parameterMappings?: ParameterMapping[];
  formId?: string;
}

export interface JobTargetEventPayloadParam {
  eventPayload?: Record<string, unknown>;
}

export const isJobTargetParameterMappingParam = (param: JobTarget): param is JobTargetParameterMappingParam => {
  return 'parameterMappings' in param !== undefined;
};
