import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const UserAppsWrapper = styled(NvFlex)`
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[5]};
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 0 40px;
  align-items: center;
`;
