import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { MenuItemWrapper } from '../navigation-menu/menu-item/styled';

export const Header = styled(NvFlex)`
  align-items: center;
  flex-direction: row;
  padding: 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  flex: 0 0 auto;
  position: relative;
  z-index: 1;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    content: '';

    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, #fbfbfc 77.5%, rgba(251, 251, 252, 0) 100%);
  }
`;

export const Bottom = styled(NvFlex)`
  padding: 16px;
  align-items: center;
  z-index: 1;

  &::before {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;

    content: '';

    width: 100%;
    height: 82px;
    background: linear-gradient(0deg, #fbfbfc 76.92%, rgba(251, 251, 252, 0) 100%);
  }
`;

export const LayoutContent = styled(NvFlex)`
  flex: 1 1 auto;
  min-height: 0;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
`;

export const ProfileMenuItemWrapper = styled(MenuItemWrapper)`
  &:hover,
  &.is-popup-menu-open {
    background-color: inherit;
  }
`;
