import { useGetGlobalConnectionSchemasService } from '@novaera/actioner-service';
import { useMemo } from 'react';
import { CUSTOM_CONNECTION_OPTIONS } from '../../../../../constants';
import { FormattedConnectionType } from '../../types';

export const useGlobalConnectionSchemasController = (params?: {
  enabled: boolean;
  addCustomConnectionSchema: boolean;
}) => {
  const { data: result, isLoading } = useGetGlobalConnectionSchemasService({ enabled: params?.enabled });

  const connectionSchemas: FormattedConnectionType[] = useMemo(() => {
    return [
      ...(params?.addCustomConnectionSchema ? CUSTOM_CONNECTION_OPTIONS : []),
      ...(result?.connectionSchemas ?? []),
    ].map((connectionSchema) => ({
      type: connectionSchema.authenticationType,
      name: connectionSchema.name,
      id: connectionSchema.id,
      logoUrl: connectionSchema.logoUrl,
      description: connectionSchema.description,
    }));
  }, [params?.addCustomConnectionSchema, result?.connectionSchemas]);

  return {
    connectionSchemas,
    isLoading,
    initialValue: connectionSchemas[0],
  };
};
