import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const ModalStep = styled(NvFlex)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const ModalNumber = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: ${({ theme, isActive }) => (isActive ? theme.palette.nv_main[40] : theme.palette.nv_neutral_alpha[20])};
  color: ${({ theme, isActive }) => (isActive ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[1000])};
`;
