import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { useEventRuleCacheUpdateHelper } from '../utils';
import { UpdateIntegrationEventRuleFilterParams, UpdateIntegrationEventRuleFilterResponse } from './types';

const updateIntegrationEventRuleFilter = async (params: UpdateIntegrationEventRuleFilterParams) => {
  const { integrationId, id, eventFilter } = params;
  const result = await NvAxios.post<UpdateIntegrationEventRuleFilterResponse>(
    `${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}/event-filter`,
    { eventFilter }
  );
  return result?.data;
};

export const useUpdateIntegrationEventRuleFilter = () => {
  const { updateDraftEventRuleCache, setEventRuleAsDraftFromListCache } = useEventRuleCacheUpdateHelper();
  return useMutation(updateIntegrationEventRuleFilter, {
    onSuccess: (draftEventRule, { integrationId, id }) => {
      updateDraftEventRuleCache({ eventRule: draftEventRule, integrationId, id });
      setEventRuleAsDraftFromListCache({ eventRule: draftEventRule, integrationId });
    },
  });
};
