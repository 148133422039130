import { NvDivider, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC, ReactElement, cloneElement, isValidElement } from 'react';
import { InputParameterLayoutProps } from './types';

export const InputParameterLayout: FC<React.PropsWithChildren<InputParameterLayoutProps>> = ({
  propertyTitle,
  label,
  name,
}) => {
  const theme = useTheme();
  return (
    <NvFlex flex="0 0 auto" minWidth={0} gap="4px">
      <NvFlex direction="row" alignItems="center">
        <NvFlex direction="row" alignItems="center" gap="4px" flex="0 0 auto">
          {propertyTitle?.icon &&
            isValidElement(propertyTitle?.icon) &&
            cloneElement(propertyTitle?.icon as ReactElement, {
              sx: { flex: '0 0 auto', width: '16px', height: '16px' },
            })}
          <NvTypography variant="h5">{propertyTitle?.label}</NvTypography>
        </NvFlex>
        {label && (
          <NvFlex direction="row" alignItems="center" gap="12px" marginLeft="12px" flex="1 1 auto" minWidth={0}>
            <NvDivider orientation="vertical" borderColor={theme.palette.nv_neutral_alpha[30]} sx={{ height: '8px' }} />
            <NvTypography variant="h6" noWrap>
              {label}
            </NvTypography>
          </NvFlex>
        )}
      </NvFlex>
      <NvTypography variant="c3" textColor="subtle">
        {`{{input.${name}}}`}
      </NvTypography>
    </NvFlex>
  );
};
