import { NvAxios, useMutation, useQuery } from '@novaera/core';
import {
  GetAccessTokenFromOauth2Params,
  GetAccessTokenFromOauth2Response,
  GetAuthorizationUrlParams,
  GetAuthorizationUrlResponse,
} from './types';

const getAuthorizationUrl = async ({ identityProvider }: GetAuthorizationUrlParams) => {
  const result = await NvAxios.post<GetAuthorizationUrlResponse>('/v1/oauth2/authorize', {
    identityProvider,
  });
  return result.data;
};

export const useGetAuthorizationUrl = () => useMutation(getAuthorizationUrl);

const getAccessTokenFromOauth2 = async ({ code, provider }: GetAccessTokenFromOauth2Params) => {
  const result = await NvAxios.post<GetAccessTokenFromOauth2Response>('/v1/oauth2/token', {
    code,
    provider,
  });

  return result.data;
};

export const useGetAccessTokenFromOauth2 = (params: GetAccessTokenFromOauth2Params) =>
  useQuery([], () => getAccessTokenFromOauth2(params), {
    enabled: !!params.code && !!params.provider,
    staleTime: 0,
    retry: 1,
  });
