import { NvDivider, NvFlex, NvLink, NvTypography } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PublicFooterWrapper = styled(NvFlex)`
  align-items: center;
  width: 100%;
  background: #27315b;
`;

export const PublicFooterInner = styled(NvFlex)`
  flex-direction: column;
  padding: 130px 40px 30px 40px;
  width: 100%;
  max-width: 1288px;
  gap: 40px;

  @media screen and (max-width: 991px) {
    padding: 60px 32px 30px 32px;
  }

  @media screen and (max-width: 767px) {
    padding: 60px 24px 30px 24px;
  }
`;

export const PublicFooterTop = styled(NvFlex)`
  flex-direction: row;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0 24px;
    gap: 20px;
  }
`;

export const PublicFooterLogoLink = styled(NvLink)`
  transition: transform 300ms ease, -webkit-transform 300ms ease;

  &:hover {
    transform: scale(0.95);
  }
`;

export const PublicFooterSocialMediaLinks = styled(NvFlex)`
  visibility: visible;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const PublicFooterSocialMediaIconLink = styled(NvLink)`
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  transition: transform 300ms ease, -webkit-transform 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    -webkit-transform: translate(0px, -6px);
    -ms-transform: translate(0px, -6px);
    transform: translate(0px, -6px);
  }
`;

export const PublicFooterBody = styled(NvFlex)`
  flex-direction: row;
  gap: 20px;
  padding-top: 30px;

  @media screen and (max-width: 767px) {
    /* gap: 64px; */
    flex-direction: column;
  }
`;

export const PublicFooterMenuWrapper = styled(NvFlex)`
  flex-direction: row;
  width: 100%;
  gap: 16px;

  @media screen and (max-width: 767px) {
    gap: 64px;
    flex-direction: column;
  }
`;

export const CopyRightSection = styled(NvFlex)`
  min-height: 20px;
  padding: 2px 0;
  width: 100%;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const TermsAndConditionsWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 12px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const PublicFooterMenu = styled(NvFlex)`
  gap: 20px;
  width: 100%;
  max-width: 382px;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
`;

export const PublicFooterMenuLink = styled(NvLink)`
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;

  &:hover {
    text-decoration: none;
    color: #f76d6d;
    transform: translate(4px, 0px);
  }
`;

export const PublicFooterMenuDivider = styled(NvDivider)`
  border-color: rgba(255, 255, 255, 0.2);
`;

export const PublicFooterBottom = styled(NvFlex)`
  flex-direction: row;
  gap: 12px;
  align-items: center;
  min-height: 20px;
  padding-top: 20px;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  width: 100%;

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
  }
`;

export const PublicFooterLinks = styled(NvFlex)`
  flex-direction: row;
  gap: 12px;
  align-items: center;
  min-height: 20px;

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const PublicFooterLink = styled(NvLink)`
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  text-decoration: underline;

  &:hover {
    color: #f76d6d;
  }
`;

export const PublicFooterTitle = styled(NvTypography)`
  color: #ffffffb3;
`;
