import { NodeListItemComponent } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AssistantListItem = styled(NodeListItemComponent)`
  .assistant-actions {
    &.keep-open {
      display: flex;
    }
  }

  &:hover {
    .assistant-actions {
      display: flex;
    }
  }
`;
