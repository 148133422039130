import { SLACK_WORKSPACE_KEYS, SlackWorkspace } from '@novaera/ah-common';
import { NvAxios, useQuery } from '@novaera/core';
import { SLACK_INTEGRATIONS_ROOT_PATH } from '../../constants';

const getSlackWorkspace = async () => {
  const result = await NvAxios.get<SlackWorkspace>(`${SLACK_INTEGRATIONS_ROOT_PATH}/slack-workspace`);
  return result?.data;
};

export const useGetSlackWorkspace = () => {
  return useQuery<SlackWorkspace>(SLACK_WORKSPACE_KEYS.all, () => getSlackWorkspace());
};
