import { NvAxios, useMutation } from '@novaera/core';
import { APPS_ROOT_PATH } from '../constants';
import { CheckConnectionValidationParams, CheckConnectionValidationResponse } from './types';

const checkConnectionValidation = async ({ appId, workflowId, isDraft }: CheckConnectionValidationParams) => {
  const result = await NvAxios.get<CheckConnectionValidationResponse>(
    `${APPS_ROOT_PATH}/${appId}/workflows/${workflowId}/connections-validation`,
    { params: { draft: isDraft } }
  );
  return result?.data;
};

export const useCheckConnectionValidationService = () => {
  return useMutation(checkConnectionValidation);
};
