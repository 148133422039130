import { BasicTableCell, BasicTableRow } from '@novaera/core';

export const FieldItemTableHeader = () => {
  return (
    <BasicTableRow>
      <BasicTableCell align="left">Label</BasicTableCell>
      <BasicTableCell align="left">Key</BasicTableCell>
      <BasicTableCell align="left">Field type</BasicTableCell>
      <BasicTableCell align="left">Mandatory</BasicTableCell>
      <BasicTableCell align="center" size="small"></BasicTableCell>
    </BasicTableRow>
  );
};
