import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RegenerateSectionWrapper = styled(NvFlex)`
  flex-direction: column;
  padding: 10px 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
`;
