import { NvAxios, useMutation } from '@novaera/core';
import { CONFIG_ROOT_PATH } from '../constants';
import { GenerateConfigParams } from './types';

const generateConfig: (params: GenerateConfigParams) => Promise<unknown> = async ({ appId, configId, ...params }) => {
  const result = await NvAxios.post(`${CONFIG_ROOT_PATH(appId)}/${configId}/generate`, params);
  return result?.data;
};

export const useGenerateConfig = () => {
  return useMutation(generateConfig);
};
