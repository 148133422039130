import { useCallback } from 'react';
import { DynamicInputMapArray } from '../../action-dynamic-input-provider/types';

export const useGetDynamicInputParameterParent = ({ dynamicInputMap }: { dynamicInputMap: DynamicInputMapArray }) => {
  const getDynamicInputParameterParent = useCallback(
    (inputParameterId: string) => {
      let parent;
      dynamicInputMap.some((inputMap) => {
        if (inputMap.data?.parameters?.find((i) => i.id === inputParameterId)) {
          parent = inputMap.inputParameterId;
          return true;
        } else {
          return false;
        }
      });

      return parent;
    },
    [dynamicInputMap]
  );

  return { getDynamicInputParameterParent };
};
