import { NvAxios, useQuery } from '@novaera/core';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppDirectorySearchMetadataResponse } from './types';

const getAppDirectorySearchMetadata = async ({ isAnonymous }: { isAnonymous: boolean }) => {
  const result = await NvAxios.get<GetAppDirectorySearchMetadataResponse>(
    isAnonymous ? `${APP_DIRECTORY_ROOT_PATH}/anonymous/metadata` : `${APP_DIRECTORY_ROOT_PATH}/metadata`
  );
  return result?.data;
};

export const useGetAppDirectorySearchMetadata = (params: { isAnonymous: boolean }) =>
  useQuery<GetAppDirectorySearchMetadataResponse>(QUERY_KEYS_APP_DIRECTORY.searchMetadata, () =>
    getAppDirectorySearchMetadata(params)
  );
