import { useMsTeamsGetCode } from '@novaera/actioner-service';
import { ErrorPage, LoadingPage } from '@novaera/identity-provider';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const MsTeamsCode = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const { data, error } = useMsTeamsGetCode({ code, state });

  useEffect(() => {
    if (data && 'subdomain' in data && data.subdomain) {
      window.close();
    }
  }, [data]);

  return (data && 'errorMessage' in data && data.errorMessage) || error ? (
    <ErrorPage
      title={'Something went wrong!'}
      description={data && 'errorMessage' in data && data.errorMessage ? data.errorMessage : `${error}`}
      buttonText="Close"
      onClick={() => {
        window.close();
      }}
    />
  ) : (
    <LoadingPage message="Redirecting ..." />
  );
};
