import { ActionNode, useSearchInfiniteIntegrations } from '@novaera/actioner-service';
import { EmptyInterface, NodeListItem, NvActionFilledIcon, NvChip } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { matchSorter } from 'match-sorter';
import { useMemo } from 'react';
import { SLACK_INTEGRATION_ID } from '../../constants';
import { useGetStaticTriggerItems } from './use-get-static-trigger-items';

export const useGetTriggerItems = ({
  activeParent,
  keyword,
  itemTypesToHide,
}: {
  activeParent?: string;
  keyword?: string;
  itemTypesToHide: string[];
}) => {
  const theme = useTheme();

  const { staticTriggerItems } = useGetStaticTriggerItems();

  const searchModeActive = useMemo(() => !!keyword && activeParent === undefined, [activeParent, keyword]);
  const { data: integrationsSearchResponse, isInitialLoading: isActionSearchLoading } = useSearchInfiniteIntegrations(
    { ...(keyword && { value: keyword }) },
    {
      enabled: searchModeActive || activeParent === 'action',
    }
  );

  const rawActionNodeListItems = useMemo(() => {
    const retVal = integrationsSearchResponse?.pages.map((page) => {
      return page.integrations?.map((integration) => {
        const actionNodeListItem: NodeListItem<Partial<ActionNode>> = {
          name: integration.name,
          icon: integration.logoUrl,
          type: 'action',
          rightComponent: integration.scope.type === 'workspace' ? <NvChip label="custom" compact /> : undefined,
          extraData: {
            type: 'action',
            integrationId: integration.id,
            version: integration.latestVersion.number,
          },
        };
        return actionNodeListItem;
      });
    });
    return retVal?.flat() ?? [];
  }, [integrationsSearchResponse]);

  const actionNodeListItems = useMemo(() => {
    const slackItem = rawActionNodeListItems.find((item) => item.extraData?.integrationId === SLACK_INTEGRATION_ID);
    if (slackItem) {
      const result = [
        slackItem,
        ...rawActionNodeListItems.filter((item) => !(item.extraData?.integrationId === SLACK_INTEGRATION_ID)),
      ];
      return result;
    } else {
      return rawActionNodeListItems;
    }
  }, [rawActionNodeListItems]);

  const actionsTriggerItems = useMemo(
    () => [
      {
        name: 'Actions',
        type: 'action',
        description: 'Add an integration action.',
        icon: <NvActionFilledIcon htmlColor={theme.palette.nv_node['action']} />,
        childNodes: actionNodeListItems,
      },
    ],
    [actionNodeListItems, theme.palette.nv_node]
  );

  const allTriggerItems: NodeListItem<Partial<ActionNode> | EmptyInterface>[] = useMemo(
    () => [...staticTriggerItems, ...actionsTriggerItems],
    [actionsTriggerItems, staticTriggerItems]
  );

  const allFilteredTriggerItems: NodeListItem<Partial<ActionNode> | EmptyInterface>[] = useMemo(() => {
    if (keyword) {
      if (activeParent === undefined) {
        const groupedAndMergedStaticTriggerItems = staticTriggerItems.reduce<NodeListItem[]>((acc, cur) => {
          if (cur.childNodes) {
            return [...acc, ...cur.childNodes.map((node) => ({ ...node, group: cur.type }))];
          } else {
            return [...acc, { ...cur, group: cur.type }];
          }
        }, []);

        const filteredStaticTriggerItems = matchSorter(groupedAndMergedStaticTriggerItems, keyword, {
          keys: ['group', 'name'],
        });

        const groupedActionNodeListItems = actionNodeListItems.map((item) => ({ ...item, group: item.type }));

        return [...filteredStaticTriggerItems, ...groupedActionNodeListItems];
      } else if (activeParent === 'action') {
        return [...actionsTriggerItems, ...staticTriggerItems];
      } else {
        const filteredStaticItems = staticTriggerItems.map((item) =>
          item.type === activeParent && !!item.childNodes
            ? {
                ...item,
                childNodes: matchSorter(item.childNodes, keyword, {
                  keys: ['name'],
                }),
              }
            : { ...item }
        );
        return [...actionsTriggerItems, ...filteredStaticItems];
      }
    } else {
      return [...actionsTriggerItems, ...staticTriggerItems];
    }
  }, [actionNodeListItems, actionsTriggerItems, activeParent, keyword, staticTriggerItems]);

  const triggerItems = useMemo(
    () => allTriggerItems.filter((item) => item.type && !itemTypesToHide.includes(item.type)),
    [allTriggerItems, itemTypesToHide]
  );

  const filteredTriggerItems = useMemo(
    () => allFilteredTriggerItems.filter((item) => item.type && !itemTypesToHide.includes(item.type)),
    [allFilteredTriggerItems, itemTypesToHide]
  );

  return {
    triggerItems,
    filteredTriggerItems,
    isLoading: isActionSearchLoading,
    searchModeActive,
    actionNodeListItems,
  };
};
