import { NvAxios, useQuery } from '@novaera/core';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPolicyParams, GetPermissionPolicyResponse } from './types';

const getPermissionPolicy = async ({ policyId }: GetPermissionPolicyParams) => {
  const result = await NvAxios.get<GetPermissionPolicyResponse>(
    `${PERMISSION_POLICIES_ROOT_PATH}/policies/${policyId}`
  );
  return result?.data;
};

export const useGetPermissionPolicy = (params: GetPermissionPolicyParams) => {
  return useQuery<GetPermissionPolicyResponse>(
    QUERY_KEYS_PERMISSION_POLICIES.detail({ policyId: params.policyId }),
    () => getPermissionPolicy(params),
    { enabled: !!params.policyId }
  );
};
