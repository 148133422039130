import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { QAProperties } from './qa-properties';
import { QAPropertyPanelDrawerProps } from './types';

export const QAPropertyPanelDrawer: FC<QAPropertyPanelDrawerProps> = ({ handleClose }) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(!!selectedNode, new Error('[QAPropertyPanelDrawer] - selectedNode should not be undefined!'), 'ERROR');

  const { userAppId: appId, workflowId } = useParams();

  const { node: QANode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.aiKnowledge>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const {
    panelProps: [first],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        {isLoading ? <PropertiesLoading /> : QANode && <QAProperties onCloseClicked={handleClose} QANode={QANode} />}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
