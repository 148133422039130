import { NvBox, NvConditionalRender, NvFlex, NvTypography } from '@novaera/core';
import React from 'react';
import { MainHeaderAndPropertySections } from './components/main-header-and-property-sections';
import { PropertyConfigurationProps } from './types';
import { usePropertyConfiguration } from './use-property-configuration';

export const PropertyConfiguration: React.FC<React.PropsWithChildren<PropertyConfigurationProps>> = ({
  configuredEntity,
  headerActions,
  ...rest
}) => {
  const { propertySections, showPropertyPanel, propertyTitle, propertyPanelRef } = usePropertyConfiguration(
    rest.type === 'form-trigger'
      ? {
          type: 'form-trigger',
          configuredEntity,
          headerActions,
          onDataSourceIndexChanged: rest.onDataSourceIndexChanged,
          dataSourceIndex: rest.dataSourceIndex,
        }
      : {
          type: 'integration-action',
          headerActions,
          configuredEntity,
          integrationVersion: rest.integrationVersion,
        }
  );

  return (
    <>
      <NvConditionalRender when={() => !showPropertyPanel}>
        <NvFlex justifyContent="center" alignItems="center" p="60px 16px 60px 16px">
          <img
            alt="empty input"
            srcSet="assets/property-panel-empty-state.png, assets/property-panel-empty-state@2x.png"
            src="assets/property-panel-empty-state.png"
          />
          <NvTypography
            textColor="subtle"
            fontSize="16px"
            fontWeight="600"
            width="248px"
            marginTop="16px"
            textAlign="center"
          >
            Add or select input components to see properties
          </NvTypography>
        </NvFlex>
      </NvConditionalRender>
      <NvConditionalRender when={() => showPropertyPanel}>
        <NvBox height="100%" position="relative" ref={propertyPanelRef}>
          <MainHeaderAndPropertySections
            headerActions={headerActions}
            propertySections={propertySections}
            propertyTitle={propertyTitle}
          />
        </NvBox>
      </NvConditionalRender>
    </>
  );
};
