import { useFetchProfile, useGetUserPermissions } from '@novaera/actioner-service';
import { isLogRocketEnabled } from '../../utils';
import { useLogRocketIdentifier } from '../use-log-rocket-identifier';

export const UserPermissionProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { data, isFetched: permissionsIsFetched } = useGetUserPermissions();
  const { data: user, isFetched: userIsFetched } = useFetchProfile();

  useLogRocketIdentifier({
    enabled: isLogRocketEnabled && permissionsIsFetched && userIsFetched,
    user: user,
    permissions: data?.permissions,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
