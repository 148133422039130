import { NvPhotoIcon } from '@novaera/core';
import { FileType } from '../../types';
import { UploadedItemIconProps } from './types';

export const UploadedItemIcon: React.FC<React.PropsWithChildren<UploadedItemIconProps>> = ({ type }) => {
  switch (type) {
    case FileType.IMAGE:
      return <NvPhotoIcon />;
  }
};
