import { NvBox } from '@novaera/core';
import { RequestBodyProps } from './types';

import { Field } from 'react-final-form';
import { TabsAdapter } from './request-body-adapter';

export const RequestBody: React.FC<React.PropsWithChildren<RequestBodyProps>> = ({ name, request }) => {
  return (
    <NvBox marginLeft={-2}>
      <Field name={`${name}`} component={TabsAdapter} />
    </NvBox>
  );
};
