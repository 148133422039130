import { ActionNode, InputParameter, UIComponentType } from '@novaera/actioner-service';
import { Context } from '@novaera/core';
import { FC } from 'react';
import { ActionParameterMapper } from '../../../../../../../../../components';
import { ActionOnParameterMappingsChanged } from '../../../../../../../../../components/parameter-mapper/types';
import { RowItemCard } from '../../../common/row-item-card';
import { SimpleLabelLayout } from '../../../common/row-item-card/row-item-layouts';
import { SLACK_SEND_MESSAGE_CATEGORIES } from './constants';

export const SlackSendMessageParameterMapping: FC<
  React.PropsWithChildren<{
    actionNode: ActionNode;
    onParameterMappingChanged: ActionOnParameterMappingsChanged;
    onSlackItemSelected: (params: { slackGroup: (typeof SLACK_SEND_MESSAGE_CATEGORIES)[0] }) => void;
    context?: Context;
    userAppId: string;
    workflowId: string;
  }>
> = ({ actionNode, onParameterMappingChanged, onSlackItemSelected, context, userAppId, workflowId }) => {
  return (
    <>
      {/* do not send connectionId if it is Slack */}
      <ActionParameterMapper
        type="in-workflow"
        appId={userAppId}
        workflowId={workflowId}
        actionId={actionNode.actionId}
        integrationId={actionNode.integrationId}
        versionNumber={actionNode.version}
        onParameterMappingsChanged={onParameterMappingChanged}
        filterInputParameters={(input: InputParameter) =>
          input.uiComponent.type !== UIComponentType.BLOCK_LIST && !!input.schema?.mandatory
        }
        initialParameterMappings={actionNode.parameterMappings}
        context={context}
      />
      {SLACK_SEND_MESSAGE_CATEGORIES.map((slackGroup) => (
        <RowItemCard
          key={`slack_group_${slackGroup.id}`}
          rowItemLeftContentProps={{ draggable: false, icon: slackGroup.icon }}
          rowItemContent={<SimpleLabelLayout simpleLabel={slackGroup.title} />}
          onClick={() => {
            onSlackItemSelected({ slackGroup });
          }}
        />
      ))}
    </>
  );
};
