import { useEsSearch } from '@novaera/actioner-service';
import { JsonViewWrapper } from '@novaera/actioner-ui';
import {
  CodeInput,
  NvButton,
  NvField,
  NvFlex,
  NvForm,
  NvReactJson,
  NvSplitPane,
  SplitPanelItem,
  isRequired,
  isValidJSON,
} from '@novaera/core';

export const EsSearch: React.FC = () => {
  const { mutate: search, data: searchResponse } = useEsSearch();
  const handleSubmit = ({ payload }: { payload: string }) =>
    new Promise<void>((resolve) => {
      search(JSON.parse(payload), {
        onSettled: () => {
          resolve();
        },
      });
    });

  return (
    <NvSplitPane direction="horizontal">
      <SplitPanelItem minSize="300px" initialSize="60%">
        <NvForm<{ payload: string }> onSubmit={handleSubmit} keepDirtyOnReinitialize>
          {({ valid, submitting }) => (
            <NvFlex alignItems="flex-start" gap="8px">
              <NvFlex width="100%" height="500px">
                <NvField
                  name="payload"
                  showErrorMessageOnlyWhenBlur
                  format={(value) => (typeof value === 'object' ? JSON.stringify(value, null, 2) : value)}
                  validators={[
                    isRequired(),
                    isValidJSON({
                      valueExtractFunction: (value) =>
                        typeof value === 'object' ? JSON.stringify(value, null, 2) : value,
                    }),
                  ]}
                  component={<CodeInput context={{}} expectedType="object" mode="json" lineNumbers hasBorder />}
                />
              </NvFlex>
              <NvButton type="submit" disabled={!valid || submitting} loading={submitting}>
                Search
              </NvButton>
            </NvFlex>
          )}
        </NvForm>
      </SplitPanelItem>
      <SplitPanelItem minSize="300px">
        <JsonViewWrapper>
          <NvReactJson src={searchResponse} collapsed={1} />
        </JsonViewWrapper>
      </SplitPanelItem>
    </NvSplitPane>
  );
};
