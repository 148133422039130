import { ActionerEvent, useChangeActionerEventSharingStatus, useDeleteActionerEvent } from '@novaera/actioner-service';
import {
  NvButton,
  NvConditionalWrap,
  NvConfirmDialog,
  NvCustomShareIcon,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvMenuWithItems,
  NvRemoveRedEyeIcon,
  NvSensorsIcon,
  NvShieldRoundedIcon,
  NvSkeleton,
  NvTooltip,
  useForm,
  useFormState,
  useNvDialogModalState,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { uniq } from 'lodash';
import { useState } from 'react';
import { Header } from '../../../../../components';
import { FreeAppPermissionBoundary } from '../../../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { USER_APP_EVENTS } from '../../../constants';
import { EventUsage } from '../../event-usage';

export const EventDetailHeader = ({
  isEventLoading,
  event,
  onEditNameClicked,
}: {
  event?: ActionerEvent;
  isEventLoading: boolean;
  onEditNameClicked?: () => void;
}) => {
  const { userAppId, eventId } = useParams<{ userAppId: string; eventId: string }>();
  const { isAppFree } = useIsAppFree({ userAppId });

  const theme = useTheme();
  const eventDetailForm = useForm();
  const formState = useFormState();

  const [isDeleteConfirmOpened, setDeleteConfirmOpen] = useState(false);

  const { mutate: deleteEvent } = useDeleteActionerEvent();
  const { mutate: changeSharingStatus } = useChangeActionerEventSharingStatus();

  const navigate = useNavigate();

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  return (
    <>
      <Header
        hasChange={formState.dirty}
        key={`event-detail-header-${eventId}`}
        name={event?.name ?? ''}
        icon={<NvSensorsIcon />}
        actions={[
          {
            name: 'Event usage',
            onClick: () => {
              onModalOpenClicked();
            },
            icon: <NvRemoveRedEyeIcon sx={{ width: '16px', height: '16px' }} />,
            right: true,
          },
          {
            name: 'divider',
            isDivider: true,
            dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
            right: !isAppFree,
          },
          {
            name: 'Delete',
            onClick: () => {
              setDeleteConfirmOpen(true);
            },
            icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
            right: !isAppFree,
          },
        ].filter(({ right }) => right)}
        onBack={USER_APP_EVENTS(userAppId)}
        buttonActions={
          <FreeAppPermissionBoundary appId={userAppId}>
            <NvButton
              size="small"
              color="secondary"
              onClick={() => {
                eventDetailForm.reset();
              }}
            >
              Discard changes
            </NvButton>

            <NvConditionalWrap
              condition={formState.hasValidationErrors}
              wrap={(children) => {
                assert(
                  !!formState.errors,
                  new Error('[EventDetail] - Validation message should not be undefined'),
                  'ERROR'
                );
                const errors = uniq(
                  Object.keys(formState.errors).map((key) => `${formState.errors?.[key]}` ?? '')
                ).join(', ');
                return (
                  <NvTooltip title={errors}>
                    <div>{children}</div>
                  </NvTooltip>
                );
              }}
            >
              <NvButton
                size="small"
                disabled={formState.hasValidationErrors || formState.submitting}
                loading={formState.submitting}
                onClick={() => {
                  eventDetailForm.submit();
                }}
              >
                Save
              </NvButton>
            </NvConditionalWrap>
          </FreeAppPermissionBoundary>
        }
        isLoading={isEventLoading}
        onEditNameClick={onEditNameClicked}
        enableStateSlot={
          isEventLoading ? (
            <NvSkeleton width={'60px'} variant="rectangular" />
          ) : (
            <FreeAppPermissionBoundary
              appId={userAppId}
              Fallback={
                event?.sharedAcrossApps ? (
                  <NvButton
                    color="secondary"
                    size="small"
                    disabled
                    startIcon={<NvCustomShareIcon sx={{ width: '16px', height: '16px' }} />}
                  >
                    Shared
                  </NvButton>
                ) : (
                  <NvButton
                    startIcon={<NvShieldRoundedIcon sx={{ width: '16px', height: '16px' }} />}
                    color="secondary"
                    size="small"
                    disabled
                  >
                    Private
                  </NvButton>
                )
              }
            >
              <NvMenuWithItems
                initialSelectedIndex={event?.sharedAcrossApps ? 0 : 1}
                useSelectedItemInTriggerButtonContent
                menuType="wide"
                menuItems={[
                  {
                    name: 'Shared',
                    onClick: () => {
                      if (!event) {
                        return;
                      }

                      changeSharingStatus({
                        appId: event?.appId,
                        id: event?.id,
                        payload: {
                          sharedAcrossApps: true,
                        },
                      });
                    },
                    description: 'Shared events can be used by all apps in this workspace.',
                    icon: <NvCustomShareIcon sx={{ width: '16px', height: '16px' }} />,
                  },

                  {
                    name: 'Private',
                    description: 'Private events can only be used by workflows that are part of this app.',
                    onClick: () => {
                      if (!event) {
                        return;
                      }

                      changeSharingStatus({
                        appId: event?.appId,
                        id: event?.id,
                        payload: {
                          sharedAcrossApps: false,
                        },
                      });
                    },
                    icon: <NvShieldRoundedIcon sx={{ width: '16px', height: '16px' }} />,
                  },
                ]}
              />
            </FreeAppPermissionBoundary>
          )
        }
      />
      <NvConfirmDialog
        title={`Delete ${event?.name}`}
        message="By deleting this event, you will lose access to its usage history and other configurations permanently. This action cannot be undone. Do you want to proceed?"
        isOpen={isDeleteConfirmOpened}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
        onConfirm={() => {
          if (!event) return;

          deleteEvent(
            {
              appId: event?.appId,
              id: event?.id,
            },
            {
              onSuccess: () => {
                navigate(USER_APP_EVENTS(userAppId));
              },
            }
          );
        }}
      />
      <NvDialogModal
        modalIcon="sensor"
        Header="Event usage"
        onCloseButtonClicked={onModalCloseClicked}
        open={isOpened}
        Body={<EventUsage />}
        Footer={
          <NvButton
            color="primary"
            onClick={() => {
              onModalCloseClicked();
            }}
          >
            Done
          </NvButton>
        }
        maxWidth="sm"
        fullWidth
      />
    </>
  );
};
