import { CheckboxComponent as CheckboxComponentType, InputParameter, UIComponentType } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { assert, noop } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { CheckboxComponent } from '../../../../../ui-components/checkbox-component';
import { FieldInputType } from '../../../../../ui-components/use-ui-component/types';

export const BooleanComponent: React.FC<
  React.PropsWithChildren<Partial<FieldInputType<boolean>> & { label?: string }>
> = ({ value, onChange, name = '', onBlur = noop, onFocus = noop, label }) => {
  assert(
    !isUndefined(value) && !!onChange,
    new Error('[BooleanComponent] - value and onChange props can not be undefined!'),
    'ERROR'
  );

  const inputParameter: InputParameter<CheckboxComponentType> = {
    id: '',
    name: '',
    uiComponent: {
      type: UIComponentType.CHECK_BOX,
      label: label ?? '',
    },
    schema: {
      type: SchemaType.boolean,
      mandatory: false,
      readOnly: false,
      constraints: [],
    },
  };

  return (
    <CheckboxComponent
      inputParameter={inputParameter}
      inputProps={{
        name,
        value,
        onChange,
        onBlur,
        onFocus,
      }}
    />
  );
};
