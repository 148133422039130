import { NvFlex, NvSkeleton } from '@novaera/core';

export const LoadingSkeleton = () => {
  return (
    <NvFlex gap={'16px'}>
      <NvFlex flexDirection={'row'} justifyContent={'space-between'}>
        <NvSkeleton variant="rectangular" width={180} height={32} />
        <NvSkeleton variant="rectangular" width={100} height={32} />
      </NvFlex>
      <NvFlex gap={'8px'}>
        <NvSkeleton variant="rectangular" width={'80%'} height={20} />
        <NvSkeleton variant="rectangular" width={'50%'} height={20} />
        <NvSkeleton variant="rectangular" width={'70%'} height={20} />
      </NvFlex>
    </NvFlex>
  );
};
