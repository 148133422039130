import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CATALOG_RELATIONSHIP_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_RELATIONSHIP_TYPES } from '../keys';
import { SearchCatalogRelationshipResult } from '../use-search-catalog-relationship';

type BatchDeleteCatalogRelationshipParams = {
  entityTypeId: string;
  payload: {
    ids: string[];
  };
};

const batchDeleteCatalogRelationship = async ({ payload }: BatchDeleteCatalogRelationshipParams) => {
  await NvAxios.delete<void>(`${CATALOG_RELATIONSHIP_ROOT_PATH}/batch`, { data: payload });
};

export const useBatchDeleteCatalogRelationship = () => {
  const cache = useQueryClient();

  return useMutation(batchDeleteCatalogRelationship, {
    onSuccess: (_, { entityTypeId, payload }) => {
      cache.setQueryData<SearchCatalogRelationshipResult>(
        QUERY_KEY_CATALOG_RELATIONSHIP_TYPES.list(entityTypeId),
        (oldData) => {
          if (!oldData) return;

          return {
            relationshipDefinitions: oldData.relationshipDefinitions.filter(
              (relationshipDef) => !payload.ids.includes(relationshipDef.id)
            ),
          };
        }
      );
    },
  });
};
