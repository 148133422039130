import { useFetchProfile } from '@novaera/actioner-service';
import { getSubdomain } from '@novaera/utils';
import React from 'react';
import { BaseRouteProps } from './types';

export const BaseRoute: React.FC<BaseRouteProps> = ({
  children,
  LoadingComponent,
  isPublic,
  subdomain = getSubdomain(),
}) => {
  const { data, isFetched } = useFetchProfile({ retry: false, staleTime: 0 });

  return LoadingComponent && !isFetched
    ? LoadingComponent
    : typeof children === 'function'
    ? children(
        isPublic
          ? {
              isAuthenticated: Boolean(!!data && subdomain && subdomain.length > 0),
            }
          : {
              user: data,
            }
      )
    : children;
};
