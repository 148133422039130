import { styled } from '@novaera/theme-provider';
import { Card } from '../../../../../../../../../../components/card/styled';

export const CardWrapper = styled(Card)`
  padding: 6px 8px;
  gap: 8px;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 6px;
`;
