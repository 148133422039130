import { useGetSlackApp, useGetUserApp } from '@novaera/actioner-service';
import { Navigate, Route, Routes } from '@novaera/route';
import { FC } from 'react';
import { ReactFlowProvider } from 'reactflow';

import { isAxiosError } from 'axios';
import { AppLoading } from '../../components/app-loading';
import { UserAppPageLayout } from '../../components/user-app-page-layout';
import { AppLevelMenu } from '../user-app-permission-boundary/app-level-menu';
import { AIAssistant } from './ai-assistant';
import { AppDependencies } from './app-dependencies';
import { Config } from './config';
import { ConfigDetail } from './config/config-detail';
import { USER_APP_LINKS, USER_APP_TABS } from './constants';
import { TableList } from './database';
import { Records } from './database/records';
import { DataModelDetailForm } from './database/tables/table-detail/data-model-detail-form';
import { Description } from './description';
import { DocumentDetail, Documents } from './documents';
import { Events } from './events';
import { EventDetail } from './events/detail';
import { InstallUserAppFlow } from './install-user-app-flow';
import { Jobs } from './jobs';
import { UserAppPermissions } from './permissions';
import { SlackApp } from './slack-app';
import { useGetWorkflowQueryParams } from './workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { WorkflowDetail } from './workflow-detail';
import { Workflows } from './workflows';

export const UserAppDetail: FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useGetWorkflowQueryParams();
  const { data: userApp, isInitialLoading } = useGetUserApp(userAppId, {
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 404) {
        return;
      } else {
        throw error;
      }
    },
    retry: (failureCount, error) => (isAxiosError(error) && error.response?.status === 404 ? false : failureCount < 3),
  });
  const { data: slackApp } = useGetSlackApp({ appId: userAppId });

  if (isInitialLoading) {
    return <AppLoading />;
  }

  if (!isInitialLoading && !userApp) {
    return <Navigate to="/404" />;
  }

  return (
    <Routes>
      <Route
        element={
          <UserAppPageLayout
            userAppId={userAppId}
            userApp={userApp}
            slackApp={slackApp}
            isInitialLoading={isInitialLoading}
          />
        }
      >
        <Route path={'setup'} element={<InstallUserAppFlow appId={userAppId} />} />
        <Route
          path={USER_APP_TABS.DESCRIPTION}
          element={<Description userApp={userApp} isLoading={isInitialLoading} key={userAppId} />}
        />

        <Route path={USER_APP_TABS.APP_DEPENDENCIES}>
          <Route index element={<AppDependencies />} />
        </Route>

        <Route path={USER_APP_TABS.CONFIG}>
          <Route
            index
            element={
              <AppLevelMenu menuItemId="configs">
                <Config />
              </AppLevelMenu>
            }
          />
          <Route
            path=":configId"
            element={
              <AppLevelMenu menuItemId="configs">
                <ConfigDetail />
              </AppLevelMenu>
            }
          />
        </Route>

        <Route path={USER_APP_TABS.WORKFLOWS}>
          <Route index element={<Workflows />} />
          <Route
            path={':workflowId/*'}
            element={
              <AppLevelMenu menuItemId="workflows">
                <ReactFlowProvider>
                  <WorkflowDetail />
                </ReactFlowProvider>
              </AppLevelMenu>
            }
          />
        </Route>

        <Route path={USER_APP_TABS.DATABASE}>
          <Route
            index
            element={
              <AppLevelMenu menuItemId="tables">
                <TableList />
              </AppLevelMenu>
            }
          />
          <Route
            path=":modelId"
            element={
              <AppLevelMenu menuItemId="tables">
                <DataModelDetailForm />
              </AppLevelMenu>
            }
          />
        </Route>

        <Route
          path={`${USER_APP_TABS.JOBS}`}
          element={
            <AppLevelMenu menuItemId="jobs">
              <Navigate to="recurring" />
            </AppLevelMenu>
          }
        />
        <Route
          path={`${USER_APP_TABS.JOBS}/recurring`}
          element={
            <AppLevelMenu menuItemId="jobs">
              <Jobs />
            </AppLevelMenu>
          }
        />
        <Route
          path={`${USER_APP_TABS.JOBS}/scheduled`}
          element={
            <AppLevelMenu menuItemId="jobs">
              <Jobs />
            </AppLevelMenu>
          }
        />

        <Route
          path={USER_APP_TABS.PERMISSIONS}
          element={
            <AppLevelMenu menuItemId="permissions">
              <UserAppPermissions />
            </AppLevelMenu>
          }
        />

        <Route
          path={USER_APP_TABS.SLACK_APP}
          element={
            <AppLevelMenu menuItemId="standalone-slack-app">
              <SlackApp appId={userAppId} />
            </AppLevelMenu>
          }
        />

        <Route path={USER_APP_TABS.DOCUMENTS}>
          <Route
            index
            element={
              <AppLevelMenu menuItemId="documentation">
                <Documents />
              </AppLevelMenu>
            }
          />
          <Route
            path=":docId"
            element={
              <AppLevelMenu menuItemId="documentation">
                <DocumentDetail />
              </AppLevelMenu>
            }
          />
        </Route>

        <Route path={USER_APP_LINKS.RECORDS}>
          <Route
            index
            element={
              <AppLevelMenu menuItemId="tables">
                <Records />
              </AppLevelMenu>
            }
          />
          <Route
            path=":modelId"
            element={
              <AppLevelMenu menuItemId="tables">
                <Records />
              </AppLevelMenu>
            }
          />
        </Route>

        <Route path={USER_APP_TABS.EVENTS}>
          <Route
            index
            element={
              <AppLevelMenu menuItemId="events">
                <Events />
              </AppLevelMenu>
            }
          />
          <Route
            path=":eventId"
            element={
              <AppLevelMenu menuItemId="events">
                <EventDetail />
              </AppLevelMenu>
            }
          />
        </Route>

        <Route
          path={USER_APP_TABS.AI_ASSISTANT}
          element={
            <AppLevelMenu menuItemId="ai-assistant">
              <AIAssistant />
            </AppLevelMenu>
          }
        />

        <Route path="*" element={<Navigate to={'description'} replace={true} />} />
      </Route>
    </Routes>
  );
};
