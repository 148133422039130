import { NvAxios, useMutation } from '@novaera/core';
import { CONFIG_ROOT_PATH } from '../constants';

import { BatchConfigValidationParams, BatchValidationResult } from './types';

const batchValidation = async ({ appId, payload }: BatchConfigValidationParams) => {
  const result = await NvAxios.post<BatchValidationResult>(`${CONFIG_ROOT_PATH(appId)}/validate-all`, {
    ...payload,
  });
  return result?.data;
};

export const useBatchValidationConfig = () => {
  return useMutation(batchValidation);
};
