import { StringStream } from 'codemirror';
import { uniq } from 'lodash';
import { ExpressionState, FunctionModeState, JavascriptState } from '../types';

export function expressionTokenizer(stream: StringStream, state: ExpressionState) {
  if (stream.match('{{')) {
    state.novaera_expression = true;
    return 'novaera_base novaera_expression';
  }

  if (state.novaera_expression) {
    if (stream.match('}}')) {
      state.novaera_expression = false;
      return 'novaera_base novaera_expression';
    }

    // Use the JavaScript tokenizer for content inside {{ and }}
    const token = javascriptTokenizer(stream, state);

    return `${token ?? 'novaera_base'} novaera_expression ${
      token === 'property' || token === 'variable' ? 'novaera_hoverable' : ''
    }`;
  } else {
    stream.next();
    return 'string';
  }
}

export function functionTokenizer(stream: StringStream, state: FunctionModeState) {
  let token = javascriptTokenizer(stream, state);

  if (state.firstLevelPathItems.some((contextItem) => contextItem.startsWith(stream.current()))) {
    token = `${token} novaera_expression`;
    state.isInContext = true;
  }

  if (
    state.isInContext &&
    (token?.includes('property') || token?.includes('variable')) &&
    !token.includes('novaera_hoverable')
  ) {
    token = `${token} novaera_hoverable`;
  }

  if (state.isInContext) {
    if (stream.current().match('.')) {
      token = `${token} novaera_base novaera_expression`;
    } else if (stream.current() === ' ') {
      state.isInContext = false;
    }
  }

  return uniq(token?.split(' ')).join(' ');
}

// Define the JavaScript tokenizer
export function javascriptTokenizer(stream: StringStream, state: JavascriptState) {
  const token = state.jsMode.token(stream, state.jsState);
  return token;
}
