import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE } from '@novaera/constants';
import { NvAxios } from '@novaera/core';
import { getSubdomain, removeCookieForSpecificDomain } from '@novaera/utils';
import React from 'react';
import { BaseRoute } from '../base-route';
import { PublicRouteProps } from './types';

export const PublicRoute: React.FC<PublicRouteProps> = ({ children, LoadingComponent, subdomain }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (NvAxios.defaults as any).isPublic = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (NvAxios.defaults as any).publicCallback = () => {
    const currentSubdomain = getSubdomain();
    if (currentSubdomain && currentSubdomain.length > 0) {
      const hostWithoutSubdomain = window.location.host
        .split('.')
        .filter((a) => a !== currentSubdomain)
        .join('.');
      removeCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, `${ApplicationConfig.Actioner.host}`);
      removeCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, `${ApplicationConfig.Actioner.baseHost}`);
      window.location.href = `${window.location.protocol}//${hostWithoutSubdomain}${window.location.pathname}`;
    }
  };

  return <BaseRoute children={children} LoadingComponent={LoadingComponent} isPublic subdomain={subdomain} />;
};
