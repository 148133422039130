import classNames from 'classnames';
import { isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Markdown } from '../markdown';

import { NvBasicTable } from '../basic-table';
import { BasicTableCell } from '../basic-table/styled';
import { NvTypography } from '../typography';
import { KeyValueTableRowContainer } from './styled';
import { KeyValueTableProps } from './types';

export const NvKeyValueTable: React.FC<React.PropsWithChildren<KeyValueTableProps>> = ({
  rows,
  isRowSelectable = false,
  onRowSelect,
  initialSelectedRowIndex,
}) => {
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>();

  useEffect(() => {
    if (!isRowSelectable) return;

    setSelectedRowId(
      !isUndefined(initialSelectedRowIndex) ? rows[initialSelectedRowIndex]?.muiDataGridRowId : undefined
    );
  }, [rows, initialSelectedRowIndex, isRowSelectable]);

  const visibleRows = useMemo(() => rows.filter(({ hidden }) => !hidden), [rows]);
  return (
    <NvBasicTable
      tableBody={
        visibleRows.length > 0 ? (
          visibleRows.map(({ muiDataGridRowId, key, value }) => (
            <KeyValueTableRowContainer
              className={classNames({ isSelected: selectedRowId === muiDataGridRowId })}
              isRowSelectable={isRowSelectable}
              key={`key-value-table-row-${muiDataGridRowId}`}
              {...(isRowSelectable
                ? {
                    onClick: () => {
                      setSelectedRowId(muiDataGridRowId);
                      const index = rows.findIndex((row) => row.muiDataGridRowId === muiDataGridRowId);
                      onRowSelect?.(index);
                    },
                  }
                : {})}
            >
              <BasicTableCell align="left" width="30%">
                <NvTypography variant="body2">
                  <b>{key}</b>
                </NvTypography>
              </BasicTableCell>
              <BasicTableCell align="left">
                {!isUndefined(value) && (
                  <Markdown>{typeof value !== 'string' ? JSON.stringify(value) : value}</Markdown>
                )}
              </BasicTableCell>
            </KeyValueTableRowContainer>
          ))
        ) : (
          <BasicTableCell align="center">
            <NvTypography variant="body1">No data to show</NvTypography>
          </BasicTableCell>
        )
      }
    />
  );
};
