import { NvAxios, useQuery } from '@novaera/core';
import { WorkspaceListResponse } from './types';

const fetchWorkspaces = async () => {
  const result = await NvAxios.get<WorkspaceListResponse>('/v1/users/workspaces');
  return result.data;
};

export const useFetchWorkspaceList = (isEnabled?: boolean) => {
  return useQuery(['fetchWorkspaces'], () => fetchWorkspaces(), {
    enabled: isEnabled,
  });
};
