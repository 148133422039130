import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_EXECUTION_OUTPUT_RESULT } from '../keys';
import { UseWorkflowExecutionOutputResultProps, UseWorkflowExecutionOutputResultResponse } from './types';

const getWorkflowExecutionOutputResult = async ({
  userAppId,
  workflowId,
  executionIdentifier,
  outputAlias,
}: UseWorkflowExecutionOutputResultProps) => {
  const result = await NvAxios.get<UseWorkflowExecutionOutputResultResponse>(
    `${USER_APP_ROOT_PATH}/${userAppId}/workflows/${workflowId}/executions/${executionIdentifier}/outputs/${outputAlias}`
  );
  return result?.data;
};

export const useGetWorkflowExecutionOutputResult = ({
  userAppId,
  executionIdentifier,
  outputAlias,
  workflowId,
}: UseWorkflowExecutionOutputResultProps) => {
  return useQuery(
    QUERY_KEYS_EXECUTION_OUTPUT_RESULT.detail({ executionIdentifier, outputAlias }),
    () => getWorkflowExecutionOutputResult({ userAppId, workflowId, executionIdentifier, outputAlias }),
    { enabled: !!executionIdentifier && !!outputAlias }
  );
};
