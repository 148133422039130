import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DATA_MODEL_ROOT_PATH } from '../../data-model/constants';
import { QUERY_KEYS_RECORDS } from '../keys';
import { SearchRecordsResponse } from '../use-search-records/types';
import { UpdateRecordParams, UpdateRecordResponse } from './types';

const updateRecord: (params: UpdateRecordParams) => Promise<UpdateRecordResponse> = async (params) => {
  const { appId, modelId, body, recordId } = params;
  const result = await NvAxios.put<UpdateRecordResponse>(
    `${DATA_MODEL_ROOT_PATH(appId)}/${modelId}/records/${recordId}`,
    body
  );
  return result?.data;
};

export const useUpdateRecord = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRecord, {
    onSuccess: (getRecordResponse, { appId, modelId, recordId }) => {
      const allCachedSearchRecords = queryClient.getQueriesData<SearchRecordsResponse>(
        QUERY_KEYS_RECORDS.detail({ appId, modelId })
      );
      allCachedSearchRecords.forEach(([cacheKey, cachedSearchRecords]) => {
        if (cachedSearchRecords) {
          const updatedCachedSearchRecords = {
            ...cachedSearchRecords,
            records: cachedSearchRecords.records.map((r) => (r.id === recordId ? getRecordResponse : r)),
          };
          queryClient.setQueryData(cacheKey, updatedCachedSearchRecords);
        }
      });
    },
  });
};
