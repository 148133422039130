import { useForm, useFormState, useToast } from '@novaera/core';
import { useEffect } from 'react';
import { useSendVerification } from '../../../../../services/use-send-verification';
import { SignInFormValues } from '../../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCodeEntrance = ({ codeInputRef }: { codeInputRef: React.MutableRefObject<any> }) => {
  const {
    values: { username, code },
  } = useFormState<SignInFormValues>();

  const { submit } = useForm();

  useEffect(() => {
    if (code?.length === 6) {
      codeInputRef.current.blur();
      submit();
    }
  }, [code, codeInputRef, submit]);

  const sendVerification = useSendVerification();
  const { addToast } = useToast();

  const handleResendCodeClicked = async () => {
    try {
      await sendVerification.mutateAsync({
        username,
      });
      addToast('The code has been sent to your email address.', {
        variant: 'success',
      });
    } catch (error) {
      addToast('Could not resend code', {
        variant: 'error',
      });
    }
  };

  return {
    onResendCodeClick: handleResendCodeClicked,
  };
};
