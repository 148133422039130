import { useNavigate } from '@novaera/route';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  APPS_ROUTE,
  COMMAND_ROUTE,
  GO_TO_COMMAND_SHORTCUT_KEY,
  GO_TO_INTEGRATIONS_SHORTCUT_KEY,
  GO_TO_MY_APPS_SHORTCUT_KEY,
  INTEGRATIONS,
} from '../../constants';

export const useNavigationController = () => {
  const navigate = useNavigate();
  const goTo = (pathname: string) => {
    navigate(pathname);
  };
  const GO_MY_APPS = (e?: KeyboardEvent) => {
    e?.preventDefault();
    goTo(APPS_ROUTE);
  };

  const GO_INTEGRATIONS = (e?: KeyboardEvent) => {
    e?.preventDefault();
    goTo(INTEGRATIONS);
  };

  const GO_COMMAND = () => goTo(COMMAND_ROUTE);
  useHotkeys(GO_TO_MY_APPS_SHORTCUT_KEY, (e) => GO_MY_APPS(e), {
    enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
  });
  useHotkeys(GO_TO_COMMAND_SHORTCUT_KEY, () => GO_COMMAND(), {
    enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
  });

  useHotkeys(GO_TO_INTEGRATIONS_SHORTCUT_KEY, () => GO_INTEGRATIONS(), {
    enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
  });
};
