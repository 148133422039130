import { BasicTableCell, NvButton, NvFlex, NvTabs } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AddNewJobDropdownItem = styled(NvFlex)`
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 300ms ease;
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  }
`;

export const BasicTableCellForFooter = styled(BasicTableCell)`
  border-bottom: none;
  padding: 8px 12px;
`;

export const JobTabs = styled(NvTabs)`
  .tab-header-container {
    z-index: 3;
  }
`;

export const AddJobButton = styled(NvButton)`
  &.MuiButtonBase-root {
    &.MuiButton-containedGhost {
      &.is-open {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      }

      &:hover {
        &.is-open {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
        }
      }
    }
  }
`;
