import { NvMoreHorizIcon } from '@novaera/core';
import { bindTrigger } from 'material-ui-popup-state/hooks';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useNodeMoreContext } from '../../node-more-popper';
import { NvMoreButtonStyled } from './styled';
import { NodeMoreIconProps } from './types';

export const NodeMoreIcon: FC<React.PropsWithChildren<NodeMoreIconProps>> = ({ nodeId, selected }) => {
  const { popupState, setNodeMoreNodeId } = useNodeMoreContext();
  const { onClick, ...rest } = bindTrigger(popupState);
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setNodeMoreNodeId(nodeId);
      onClick(event);
    },
    [nodeId, onClick, setNodeMoreNodeId]
  );

  return (
    <NvMoreButtonStyled
      className={`new-class ${selected ? 'is-shown' : ''}`}
      onlyIcon
      {...rest}
      onClick={handleOnClick}
    >
      <NvMoreHorizIcon htmlColor="black" sx={{ width: '16px', height: '16px' }} />
    </NvMoreButtonStyled>
  );
};
