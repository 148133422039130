import { FieldSchema } from '@novaera/actioner-service';
import { DateTimeFormat } from '@novaera/ah-common';
import { RenderCellParams } from '@novaera/core';

export interface RecordCellProps {
  id: string;
  name: string;
  value?: RenderCellParams['formattedValue'];
  type: FieldSchema['type'];
  format?: DateTimeFormat;
}

export enum RENDER_CELL_BODY_MODE {
  POPOVER = 'popover',
  CELL = 'cell',
}
