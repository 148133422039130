import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPoliciesResponse } from '../use-get-permission-policies/types';
import { CreatePermissionPolicyParams, CreatePermissionPolicyResponse } from './types';

const createPermissionPolicy: (
  params: CreatePermissionPolicyParams
) => Promise<CreatePermissionPolicyResponse> = async (params) => {
  const result = await NvAxios.post<CreatePermissionPolicyResponse>(
    `${PERMISSION_POLICIES_ROOT_PATH}/policies`,
    params
  );
  return result?.data;
};

export const useCreatePermissionPolicy = () => {
  const cache = useQueryClient();

  return useMutation(createPermissionPolicy, {
    onSuccess: (permissionPolicy, { name, tags, description }) => {
      const permissionPolicyCaches = cache.getQueriesData<InfiniteData<GetPermissionPoliciesResponse>>(
        QUERY_KEYS_PERMISSION_POLICIES.list()
      );

      permissionPolicyCaches.forEach((nodeDetailCache) => {
        const [queryKey, _] = nodeDetailCache;

        cache.setQueryData<InfiniteData<GetPermissionPoliciesResponse>>(queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old?.pages.map((page) => ({
                ...page,
                permissionPolicies: [...page.permissionPolicies, { ...permissionPolicy, name, tags, description }],
              })),
            };
          }
          return old;
        });
      });
    },
  });
};
