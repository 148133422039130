import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomQA({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CustomDocumentResolve"
      {...props}
    >
      <g clipPath="url(#clip0_41_2654)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.17 4C9.7 4 10.21 4.21 10.59 4.59L12 6H20C21.1 6 22 6.9 22 8V12.4998C21.5822 12.186 21.0628 12 20.5 12H19.5002C19.0441 11.3928 18.3179 11 17.5 11C16.6821 11 15.9559 11.3928 15.4998 12H14.5C13.1193 12 12 13.1193 12 14.5V15.008C11.117 15.3938 10.5 16.2748 10.5 17.3C10.5 18.3251 11.117 19.2062 12 19.592V20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17Z"
          fill="currentColor"
        />
        <path
          d="M21.5 15.6842V14.6316C21.5 14.0526 21.05 13.5789 20.5 13.5789H19C19 12.7053 18.33 12 17.5 12C16.67 12 16 12.7053 16 13.5789H14.5C13.95 13.5789 13.5 14.0526 13.5 14.6316V15.6842C12.67 15.6842 12 16.3895 12 17.2632C12 18.1368 12.67 18.8421 13.5 18.8421V20.9474C13.5 21.5263 13.95 22 14.5 22H20.5C21.05 22 21.5 21.5263 21.5 20.9474V18.8421C22.33 18.8421 23 18.1368 23 17.2632C23 16.3895 22.33 15.6842 21.5 15.6842ZM15.25 17C15.25 16.5632 15.585 16.2105 16 16.2105C16.415 16.2105 16.75 16.5632 16.75 17C16.75 17.4368 16.415 17.7895 16 17.7895C15.585 17.7895 15.25 17.4368 15.25 17ZM19.5 19.8947H15.5V18.8421H19.5V19.8947ZM19 17.7895C18.585 17.7895 18.25 17.4368 18.25 17C18.25 16.5632 18.585 16.2105 19 16.2105C19.415 16.2105 19.75 16.5632 19.75 17C19.75 17.4368 19.415 17.7895 19 17.7895Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_2654">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
