import { useInitiateOAuthService } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useEffect, useRef, useState } from 'react';
import { OAuth2AuthenticationProps } from '../../types';
import { OAuth2PromiseResultType } from './types';

export const useOAuth2ConnectionController = () => {
  const [isInitiateFlowLoading, setIsInitiateFlowLoading] = useState(false);

  const { mutate: initiateOAuth, reset, status } = useInitiateOAuthService();

  const startOAuthFlowPromiseRef = useRef<{
    resolve: (param: OAuth2PromiseResultType) => void;
    reject: (err?: unknown) => void;
  }>();

  useEffect(() => {
    const handleWindowMessages = function (event: MessageEvent) {
      if (event.origin !== window.origin || event.data.messageFrom !== 'oauth-code') return;
      if (event.data.data === 'success') {
        startOAuthFlowPromiseRef.current?.resolve({
          type: 'success',
          message: 'Oauth connection is successful',
          connectionId: event.data.connectionId,
        });
      } else {
        startOAuthFlowPromiseRef.current?.resolve({
          type: 'error',
          message: event.data.error || 'Oauth connection is failed',
        });
      }
      setIsInitiateFlowLoading(false);
    };
    window.addEventListener('message', handleWindowMessages, false);
    return () => {
      window.removeEventListener('message', handleWindowMessages);
    };
  }, []);

  const startOAuthFlow = (params: OAuth2AuthenticationProps) => {
    setIsInitiateFlowLoading(true);
    reset();

    assert(Boolean(params.connectionName), new Error('Connection name is required'));
    assert(Boolean(params.connectionSchemaId), new Error('Connection schema id is required'));
    const popupWindow = window.open('/app-loading', '_blank', 'width=700,height=800');
    initiateOAuth(
      {
        connectionName: params.connectionName,
        connectionSchemaId: params.connectionSchemaId,
        integrationId: params.integrationId,
        connectionId: params.connectionId,
        context: params.context,
      },
      {
        onSuccess: ({ url }) => {
          if (popupWindow) {
            popupWindow.location.href = url;
          }
        },
        onError: () => {
          popupWindow?.close();
        },
      }
    );
  };

  return {
    resetStates: () => {
      setIsInitiateFlowLoading(false);
    },
    isLoading: isInitiateFlowLoading || status === 'loading',
    startOAuthFlow: async (params: OAuth2AuthenticationProps) => {
      return new Promise<OAuth2PromiseResultType>((resolve, reject) => {
        startOAuthFlowPromiseRef.current = { resolve, reject };
        startOAuthFlow(params);
      });
    },
  };
};
