import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ACTIONER_EVENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS } from '../keys';
import { ActionerEvent } from '../types';
import { CreateActionerEventParams } from './types';

const createActionerEvent: (params: CreateActionerEventParams) => Promise<ActionerEvent> = async ({
  appId,
  payload,
}) => {
  const result = await NvAxios.post<ActionerEvent>(`${ACTIONER_EVENTS_ROOT_PATH(appId)}`, payload);
  return result?.data;
};

export const useCreateActionerEvent = () => {
  const cache = useQueryClient();

  return useMutation(createActionerEvent, {
    onSuccess: (data, { appId }) => {
      cache.setQueryData<ActionerEvent[]>(QUERY_KEYS_ACTIONER_EVENTS.list(appId), (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });
    },
  });
};
