import { AccessType, WorkflowPermissionScope } from '@novaera/actioner-service';
import { NvBox, NvSelect } from '@novaera/core';
import { useParams } from '@novaera/route';
import React, { useMemo } from 'react';
import { useUserAppUsersWithPermissions } from '../../../../../../permissions/use-user-app-users-with-permissions';
import { WorkflowPermissionGroupAndUser } from '../../controllers/use-workflow-users-with-permissions/types';
import { WorkflowPermissionItem } from '../workflow-permission-item';
import { WorkflowPermissionItemLoading } from '../workflow-permission-item/loading';

export const AppAdmins: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useParams();
  const { userAppAdmins, isUserAppLoading, isBatchGetLoading } = useUserAppUsersWithPermissions({ userAppId });

  const workflowPermissions = useMemo<WorkflowPermissionGroupAndUser[]>(
    () =>
      userAppAdmins?.map((userAppAdmin) => ({
        type: userAppAdmin.principalType,
        principal: {
          id: userAppAdmin.principalId,
          type: userAppAdmin.principalType,
        },
        ...(userAppAdmin.principalType === AccessType.USER ? { userDetail: userAppAdmin.userDetail } : {}),
        ...(userAppAdmin.principalType === AccessType.GROUP ? { groupDetail: userAppAdmin.groupDetail } : {}),
        scope: WorkflowPermissionScope.EDIT,
      })),
    [userAppAdmins]
  );

  return isUserAppLoading || isBatchGetLoading ? (
    <WorkflowPermissionItemLoading />
  ) : (
    <>
      {workflowPermissions.map((workflowPermission) => {
        const { principal } = workflowPermission;
        return (
          <WorkflowPermissionItem
            workflowPermission={workflowPermission}
            key={`${principal.id}_${principal.type}_app-admin`}
            isAppAdmin
            actions={
              <>
                <NvSelect
                  sx={{ width: '120px' }}
                  value="admin"
                  options={[{ label: 'App admin', value: 'admin' }]}
                  compact
                  disabled
                />
                <NvBox width="24px" height="24px"></NvBox>
              </>
            }
          />
        );
      })}
    </>
  );
};
