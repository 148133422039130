import { NvButton, NvCloseIcon, NvCollapseCard, NvErrorIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useState } from 'react';
import { ConfigSchemaValidationWrapper, ReviewCardWrapper } from './styled';

export const ConfigSchemaValidation = ({
  description,
  validationError,
  className,
}: {
  description: React.ReactElement;
  validationError: string[];
  className?: string;
}) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const theme = useTheme();
  return isClosed ? null : (
    <ConfigSchemaValidationWrapper className={className}>
      <NvFlex gap={'8px'}>
        <NvFlex flexDirection={'row'}>
          <NvFlex flex={'1 1 auto'} minWidth="0" flexDirection={'row'} alignItems="center" gap={'4px'}>
            <NvErrorIcon
              htmlColor={theme.palette.nv_error[40]}
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
            <NvTypography variant="h5">Config-Schema Mismatch</NvTypography>
          </NvFlex>
          <NvFlex flex={'0 0 auto'}>
            <NvButton
              onlyIcon
              color="ghost"
              size="small"
              onClick={() => {
                setIsClosed(true);
              }}
            >
              <NvCloseIcon />
            </NvButton>
          </NvFlex>
        </NvFlex>
        <NvFlex>
          <NvTypography variant="body2">{description}</NvTypography>
        </NvFlex>
      </NvFlex>
      <NvFlex>
        <NvCollapseCard
          title={<NvTypography variant="h5">Review errors</NvTypography>}
          iconSx={{
            width: '16px',
            height: '16px',
          }}
          CustomCollapseCardWrapper={ReviewCardWrapper}
        >
          <ul>
            {validationError.map((error, index) => (
              <li key={`error-${index}`}>
                <NvTypography variant="body2">{error}</NvTypography>
              </li>
            ))}
          </ul>
        </NvCollapseCard>
      </NvFlex>
    </ConfigSchemaValidationWrapper>
  );
};
