import { useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC, createContext, useContext, useMemo, useState } from 'react';

const FunctionsContext = createContext<
  | {
      selectedFunctionId?: string;
      setSelectedFunctionId: (functionId?: string) => void;
    }
  | undefined
>(undefined);

export const FunctionsProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { integrationId, actionId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
    includeDraft: true,
  });

  const [selectedFunctionId, setSelectedFunctionId] = useState<string | undefined>();

  const returnedSelectedFunctionId = useMemo(() => {
    if (data?.functions && data?.functions?.findIndex((f) => f.id === selectedFunctionId) > 0) {
      return selectedFunctionId;
    } else {
      return data?.functions?.[0]?.id;
    }
  }, [data?.functions, selectedFunctionId]);

  const value = { selectedFunctionId: returnedSelectedFunctionId, setSelectedFunctionId };

  return <FunctionsContext.Provider value={value}>{children}</FunctionsContext.Provider>;
};

export const useFunctionsContext = () => {
  const context = useContext(FunctionsContext);
  assert(!!context, new Error(`useFunctionsContext should be used within FunctionsProvider`), 'ERROR');

  return context;
};
