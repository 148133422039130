import { AppDependency } from '../../app-dependencies/types';
import { AppDocument, MediaFile } from '../../app-directory';
import { Group } from '../../groups/types';
import { WorkspaceUser } from '../../workspace';
import { UserAppCreationSource } from '../common/types';
import { APP_PERMISSION, Permissions } from '../permissions';

export enum AccessType {
  USER = 'user',
  GROUP = 'group',
}

export type CoreAccessSetting = {
  editGranted: boolean;
  principalType: AccessType;
};

export type UserAccessSetting = CoreAccessSetting & {
  principalId: string;
  principalType: AccessType.USER;
  userDetail?: WorkspaceUser;
};

export type GroupAccessSetting = CoreAccessSetting & {
  principalId: string;
  principalType: AccessType.GROUP;
  groupDetail?: Group;
};

export type AccessSetting = UserAccessSetting | GroupAccessSetting;

export interface UserAppUniquenessFields {
  workspaceId: string;
  schemaId: string;
  id: string;
}

export type UserAppFields = {
  accessSettings: AccessSetting[];
  permissions: Permissions<APP_PERMISSION>;
  assets?: { mediaFiles?: MediaFile[]; documents?: AppDocument[] };
  logoUrl?: string;
  managed: boolean;
  free: boolean;
  metadata: {
    schemaId: string;
    schemaVersion: string;
    creatorUserId: string;
    creationSource: UserAppCreationSource;
  };
  appMenuItems?: string[];
  installationStatus: 'completed' | 'in-progress';
  updateInProgress: boolean;
  dependencies?: AppDependency[];
  schemaVersion?: string;
};

export type UserAppFormFields = {
  name: string;
  description?: {
    type: string;
    value: string;
  };
  logoId?: string;
};

export type UserApp = UserAppFields & UserAppUniquenessFields & UserAppFormFields;
