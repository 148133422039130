import {
  NvButton,
  NvCheckCircleOutline,
  NvChip,
  NvCustomCheckIcon,
  NvDeleteOutlineIcon,
  NvDivider,
  NvEditIcon,
  NvFlex,
  NvMenuWithItems,
  NvPowerRoundedIcon,
  NvSkeleton,
  NvTypography,
  SectionMessage,
  SemanticChipColor,
  useNvDialogModalState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useState } from 'react';
import { ConnectModal } from '../../../../integrations/components/connect-modal';
import { ConnectModalMode } from '../../../../integrations/components/connect-modal/types';
import { ConnectionNameUpdateModal } from '../../../../integrations/components/connection-name-update-modal';
import { MyConnectionConnectionInfoItem } from './styled';
import { AppConnectionInformationProps } from './types';
import { useDeleteConnection } from './use-delete-connection';

export const AppConnectionDetailConnectionInformation = (props: AppConnectionInformationProps) => {
  const theme = useTheme();
  const [modalType, setModalType] = useState<ConnectModalMode>();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const {
    isOpened: isEditModalOpened,
    onModalCloseClicked: onEditModalCloseClicked,
    onModalOpenClicked: onEditModalOpenClicked,
  } = useNvDialogModalState();

  const { deleteConnectionWithConfirmation } = useDeleteConnection();

  const handleOpenModalClick = (modalType: ConnectModalMode) => {
    setModalType(modalType);
    onModalOpenClicked();
  };

  return (
    <MyConnectionConnectionInfoItem direction="row">
      {props.state === 'loading' ? (
        <NvSkeleton variant="rectangular" width={'100%'} height="24px" />
      ) : props.state === 'error' ? (
        <SectionMessage message="There is an error while fetching the action." variant="error"></SectionMessage>
      ) : (
        <>
          <NvFlex flexDirection={'row'} flex="1 1 auto" minWidth="0" columnGap={'8px'} alignItems="center">
            <NvPowerRoundedIcon />
            <NvTypography variant="h4" noWrap>
              {props.connection.name}
            </NvTypography>
          </NvFlex>

          <NvFlex flexDirection="row" columnGap="8px" alignItems={'center'}>
            <NvChip label={'Connected'} compact color={SemanticChipColor.SUCCESS} icon={<NvCustomCheckIcon />} />
            <NvDivider orientation="vertical" sx={{ height: '12px' }} />
            <NvButton size="small" color="secondary" onClick={() => handleOpenModalClick('Connect')}>
              Reconnect
            </NvButton>
            <NvMenuWithItems
              menuItems={[
                {
                  name: 'Edit',
                  onClick: () => {
                    onEditModalOpenClicked();
                  },
                  icon: <NvEditIcon sx={{ width: '16px', height: '16px' }} />,
                },
                {
                  name: 'Test connection',
                  onClick: () => {
                    handleOpenModalClick('Test');
                  },
                  icon: <NvCheckCircleOutline sx={{ width: '16px', height: '16px' }} />,
                },
                {
                  name: 'divider',
                  isDivider: true,
                  dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
                },
                {
                  name: 'Delete',
                  onClick: () => {
                    deleteConnectionWithConfirmation({
                      connectionId: props.connection.id,
                      connectionName: props.connection.name,
                    });
                  },
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                },
              ]}
            ></NvMenuWithItems>
          </NvFlex>
        </>
      )}

      {props.state === 'loaded' && modalType && (
        <ConnectModal
          schemaId={props.connectionSchema.id}
          connectionName={props.connection?.name}
          isOpened={isOpened}
          onModalCloseClicked={onModalCloseClicked}
          mode={modalType}
          integrationId={props.integrationId}
          connected={true}
          connectionId={props.connection?.id}
          {...(modalType === 'Test' && {
            test: 'connection',
          })}
        />
      )}

      {props.state === 'loaded' && isEditModalOpened && (
        <ConnectionNameUpdateModal
          connectionId={props.connection.id}
          connectionName={props.connection.name}
          isOpened={isEditModalOpened}
          onClose={onEditModalCloseClicked}
        />
      )}
    </MyConnectionConnectionInfoItem>
  );
};
