import { DateTimeFormat, SchemaType } from '@novaera/ah-common';
import { NvChipLink, NvFlex, NvTypography } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';

import { USER_APP_DATABASE_DETAIL } from '../../../../../user-app/user-app-detail/constants';
import { FIELD_TYPE_DISPLAY_NAME } from '../../../constants';
import { ListOfName } from './list-of-name';
import { DataModelFieldSchemaTitleProps } from './types';

export const DataModelFieldSchemaTitle: FC<React.PropsWithChildren<DataModelFieldSchemaTitleProps>> = ({
  schema,
  variant,
  textColor = 'main',
}) => {
  const { palette } = useTheme();

  const { type } = schema;
  const defaultTitle = FIELD_TYPE_DISPLAY_NAME[type];
  const { userAppId: appId } = useParams();

  switch (type) {
    case SchemaType.array:
    case SchemaType.map:
      return (
        <ListOfName
          textColor={textColor}
          valueSchema={schema.valueSchema}
          parentTitle={defaultTitle}
          variant={variant}
        />
      );
    case SchemaType.recordReference:
      return (
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvTypography variant={variant} textColor={textColor}>
            reference
          </NvTypography>
          <NvChipLink
            label={schema.modelName}
            linkProps={{
              href: USER_APP_DATABASE_DETAIL(appId, schema.modelId),
            }}
            sx={{ color: palette.nv_main[60] }}
            compact={variant === 'h5'}
          />
        </NvFlex>
      );

    case SchemaType.dateTime:
      return (
        <NvFlex direction="row" gap="8px">
          <NvTypography textColor={textColor} variant={variant}>
            {defaultTitle}
          </NvTypography>
          <NvTypography variant={variant} color={palette.nv_neutral_alpha[60]}>
            {schema.format === DateTimeFormat.utc ? 'UTC Milliseconds' : 'ISO 8601'}
          </NvTypography>
        </NvFlex>
      );
    default:
      return (
        <NvTypography textColor={textColor} variant={variant}>
          {defaultTitle}
        </NvTypography>
      );
  }
};
