/* eslint-disable react/jsx-no-useless-fragment */
import Radio, { RadioProps } from '@mui/material/Radio';
import { RadioStyledWrapper } from './styled';

export type NvRadioProps = RadioProps;

export function NvRadio(nvRadioProps: NvRadioProps) {
  return (
    <RadioStyledWrapper>
      <Radio disableRipple {...nvRadioProps} icon={<></>} checkedIcon={<></>}></Radio>
    </RadioStyledWrapper>
  );
}
