import { InputParameters, ParameterMappings, UIComponentType } from '@novaera/actioner-service';
import { useCallback } from 'react';
import { calculateIsScripted } from '..';
import { GetDynamicInputResponseFunction } from '../../../dynamic-input/providers/action-dynamic-input-provider/types';
import {
  DependencyInputComponentStateFN,
  FORM_ITEM_DEPENDENCY_STATE,
} from '../../../dynamic-input/providers/controller/use-check-dependency-value/types';

export const useCalculateShouldAddFormId = ({
  handleGetDynamicInputComponentState,
}: {
  handleGetDynamicInputComponentState: DependencyInputComponentStateFN;
}) => {
  const calculateShouldAddFormId = useCallback(
    (
      parameterMappings: ParameterMappings,
      inputParameters: InputParameters,
      getDynamicInputParameters: GetDynamicInputResponseFunction
    ) => {
      const dynamicInputSourceInputParameters = inputParameters.filter(
        (i) => i.uiComponent.type === UIComponentType.DYNAMIC_INPUT
      );

      return dynamicInputSourceInputParameters.some((inputParameter) => {
        const { errorContext } = getDynamicInputParameters(inputParameter.id);

        const { state } = handleGetDynamicInputComponentState({
          inputParameterId: inputParameter.id,
          inputParameterValues: parameterMappings,
        });

        const hasError = state !== FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE && Boolean(errorContext);

        const parameterMapping = parameterMappings.find((p) => p.parameterId === inputParameter.id);

        if (parameterMapping) {
          const isScripted = calculateIsScripted(inputParameter, parameterMapping);
          if (isScripted) {
            return false;
          }
        }

        if (hasError) {
          return false;
        }

        return (
          state === FORM_ITEM_DEPENDENCY_STATE.HAS_DEPENDENCY || state === FORM_ITEM_DEPENDENCY_STATE.NO_DEPENDENCY
        );
      });
    },
    [handleGetDynamicInputComponentState]
  );

  return { calculateShouldAddFormId };
};
