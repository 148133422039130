import { AutocompleteRenderGetTagProps } from '@mui/material';
import { ListValue, StringValue, ValueTypes } from '@novaera/actioner-service';
import {
  CodeInput,
  Context,
  NvChip,
  NvMenuWithItems,
  NvSettingsIcon,
  NvSideGroupedInputLayout,
  NvTagsAutoComplete,
} from '@novaera/core';
import { useState } from 'react';
import { ParameterMappingSettingsMenu } from '../../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';

export const SideGroupFreeSoloMulti: React.FC<{
  value?: Omit<ListValue, 'value'> & { value?: StringValue[] };
  onChange: (value?: Omit<ListValue, 'value'> & { value?: StringValue[] }) => void;
  context: Context;
  label?: string;
  placeholder: string;
}> = ({ value, onChange, context, label, placeholder }) => {
  const [hasTagsCodeTemplate, setHasTagsCodeTemplate] = useState(() => Boolean(value && 'codeTemplate' in value));
  return (
    <NvSideGroupedInputLayout
      variant="default"
      title={{
        direction: 'label-on-top',
        labelText: label,
        labelVariant: 'h5',
      }}
      rightAction={
        <NvMenuWithItems
          triggerButton={{
            content: <NvSettingsIcon />,
            props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
          }}
          menuItems={
            <ParameterMappingSettingsMenu
              hiddenConfig={{
                show: false,
              }}
              dynamicStaticConfig={{
                show: true,
                isScripted: hasTagsCodeTemplate,
                onItemClicked: (type) => {
                  const newScriptedValue = type === 'static' ? false : true;
                  if (hasTagsCodeTemplate !== newScriptedValue) {
                    setHasTagsCodeTemplate(newScriptedValue);
                    if (newScriptedValue) {
                      onChange({
                        type: ValueTypes.LIST,
                        codeTemplate: '',
                      });
                    } else {
                      onChange({
                        type: ValueTypes.LIST,
                        value: [],
                      });
                    }
                  }
                },
              }}
            />
          }
        />
      }
      formItem={
        hasTagsCodeTemplate ? (
          <CodeInput
            value={value?.codeTemplate}
            onChange={(value) => {
              onChange({
                type: ValueTypes.LIST,
                codeTemplate: value,
              });
            }}
            context={context}
          />
        ) : (
          <NvTagsAutoComplete
            sx={{ width: '100%' }}
            value={value?.value}
            getOptionLabel={(value?: StringValue) => value?.displayValue}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any, values: string[]) => {
              const newValues =
                values?.map<StringValue>((v) =>
                  typeof v === 'object' ? v : { type: ValueTypes.STRING, value: v, displayValue: v }
                ) ?? [];

              onChange({
                type: ValueTypes.LIST,
                value: newValues,
              });
            }}
            renderTags={(value: StringValue[], getTagProps: AutocompleteRenderGetTagProps) => {
              return value.map((option, index) => {
                return (
                  <NvChip
                    {...getTagProps({ index })}
                    key={`tag_auto_complete_index_${index}`}
                    label={option.displayValue ?? ''}
                  />
                );
              });
            }}
            placeholder={placeholder}
          />
        )
      }
    />
  );
};
