import { NvAxios, useMutation } from '@novaera/core';
import { ImportEnvironmentTablesProps } from './types';

const importEnvironmentTables = async (props: ImportEnvironmentTablesProps) => {
  const result = await NvAxios.post('/admin/environment-sync/imports', {
    ...props,
  });
  return result?.data;
};

export const useImportTables = () => {
  return useMutation(importEnvironmentTables);
};
