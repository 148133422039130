import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../../permission-policies/keys';
import { GetPermissionPolicyResponse } from '../../permission-policies/use-get-permission-policy/types';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { Group } from '../types';
import { AttachPermissionPolicyToGroupParams } from './types';

const attachPermissionPolicyToGroup = async ({ group, permissionPolicies }: AttachPermissionPolicyToGroupParams) => {
  const result = await NvAxios.post(`${GROUPS_ROOT_PATH}/${group.id}/permission-policies`, {
    permissionPolicies: permissionPolicies.map(({ id }) => ({ id })),
  });
  return result?.data;
};

export const useAttachPermissionPolicyToGroup = () => {
  const cache = useQueryClient();
  return useMutation(attachPermissionPolicyToGroup, {
    onSuccess: (_, { group, permissionPolicies }) => {
      permissionPolicies.forEach((policy) => {
        cache.setQueryData<GetPermissionPolicyResponse>(
          QUERY_KEYS_PERMISSION_POLICIES.detail({ policyId: policy.id }),
          (old) => {
            if (old) {
              return { ...old, groups: [...old.groups, group] };
            }
            return;
          }
        );
      });

      cache.setQueryData<Group>(QUERY_KEYS_GROUPS.detail(group.id), (old) => {
        if (old) {
          return { ...old, permissionPolicies: [...(old.permissionPolicies ?? []), ...permissionPolicies] };
        }
        return;
      });
    },
  });
};
