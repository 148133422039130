export enum FileType {
  IMAGE = 'image',
}

export type UploadedFile = {
  name: string;
  objectKey: string;
};

export interface FileUploadProps {
  file: UploadedFile;
  onFileUploaded: (file: UploadedFile) => void;
  typeRestrictions?: FileType[];
}
