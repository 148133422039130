import { WorkflowWithState } from '@novaera/actioner-service';
import { useEffect } from 'react';
import { InputFormValues } from '../../../../../../../../action-designer/providers/input-values';
import { WorkflowOutputRefRunImmediatelyParams } from '../../../../providers/workflow-reference-output-provider/types';

/*
 TODO: Refactor output logic which is used in both WorkflowRun and WorkflowRunFromLinkForm components
*/
export const useTriggerOutput = ({
  workflowOutputRefParams,
  workflow,
  onExecuteWorkflow,
  initialFormValues,
  cleanResult,
}: {
  workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
  workflow?: WorkflowWithState;
  onExecuteWorkflow: (formValues: InputFormValues) => void;
  initialFormValues?: InputFormValues;
  cleanResult: () => void;
}) => {
  useEffect(() => {
    if (workflowOutputRefParams?.runImmediately && workflow?.id === workflowOutputRefParams?.workflowId) {
      // trigger immediately workflow run if workflow reference is set to run immediately
      initialFormValues && onExecuteWorkflow(initialFormValues);
    }
  }, [
    initialFormValues,
    onExecuteWorkflow,
    workflow?.id,
    workflowOutputRefParams?.runImmediately,
    workflowOutputRefParams?.workflowId,
  ]);

  useEffect(() => {
    if (!workflowOutputRefParams?.runImmediately) {
      cleanResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow?.id]);
};
