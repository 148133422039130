import { useGetAnalyticsQueryResult } from '@novaera/actioner-service';
import {
  BasicTableCell,
  BasicTableRow,
  NvArrowBackIcon,
  NvBasicTable,
  NvBox,
  NvButton,
  NvDownloadIcon,
  NvEmptyState,
  NvFlex,
  NvLink,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useNavigate, useParams, useQueryParams } from '@novaera/route';
import { RecentQueryResultHeader, RecentQueryResultWrapper } from './styled';

export const RecentQueryResult: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { queryId } = useParams();
  const { getSearchParams } = useQueryParams();

  const queryParams = getSearchParams<{ outputUrl: string }>();
  const {
    data: analyticsQueryResult,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAnalyticsQueryResult(queryId);
  const navigate = useNavigate();

  let decodedOutputUrl: string | null;
  try {
    if (queryParams?.outputUrl) {
      decodedOutputUrl = atob(queryParams.outputUrl);
    } else {
      decodedOutputUrl = null;
    }
  } catch (error) {
    decodedOutputUrl = null;
  }

  const analyticsQueryResultPages = analyticsQueryResult?.pages;
  const headers = analyticsQueryResultPages?.[0]?.rows?.[0]
    ? Object.keys(analyticsQueryResultPages?.[0]?.rows?.[0])
    : [];

  return (
    <NvBox width="100%" height="100%" overflow="auto">
      <RecentQueryResultWrapper>
        <RecentQueryResultHeader>
          <NvButton
            startIcon={<NvArrowBackIcon />}
            size="small"
            color="secondary"
            onClick={() => {
              navigate(`/admin/analytic-queries`);
            }}
          >
            Back
          </NvButton>
          <NvFlex direction="row" alignItems="center" gap="32px">
            <NvTypography variant="h1">{queryId}</NvTypography>
            {decodedOutputUrl && (
              <NvLink href={decodedOutputUrl}>
                <NvButton onlyIcon size="small" color="secondary">
                  <NvDownloadIcon />
                </NvButton>
              </NvLink>
            )}
          </NvFlex>
        </RecentQueryResultHeader>
        {isLoading ? (
          <NvFlex width="100%">
            <NvSkeleton width="60%" />
            <NvSkeleton width="80%" />
            <NvSkeleton width="40%" />
          </NvFlex>
        ) : analyticsQueryResultPages ? (
          <NvBasicTable
            tableHeader={
              <BasicTableRow>
                {headers.map((header, index) => (
                  <BasicTableCell key={`table-header-${index}`}>{header}</BasicTableCell>
                ))}
              </BasicTableRow>
            }
            tableBody={
              <>
                {analyticsQueryResultPages.map(({ rows }) =>
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const rowIndex = index;
                      return (
                        <BasicTableRow key={`table-row-${rowIndex}`}>
                          {Object.values(row).map((v, index) => (
                            <BasicTableCell key={`table-row-${rowIndex}-table-cell${index}`}>
                              <NvFlex direction="row" gap="8px">
                                <NvTypography>{v}</NvTypography>
                              </NvFlex>
                            </BasicTableCell>
                          ))}
                        </BasicTableRow>
                      );
                    })
                  ) : (
                    <BasicTableCell colSpan={6}>
                      <NvBox textAlign="center">
                        <NvTypography>No data</NvTypography>
                      </NvBox>
                    </BasicTableCell>
                  )
                )}
                {hasNextPage && (
                  <BasicTableCell>
                    <NvButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        fetchNextPage();
                      }}
                      loading={isFetchingNextPage}
                      disabled={isFetchingNextPage}
                    >
                      Show more
                    </NvButton>
                  </BasicTableCell>
                )}
              </>
            }
          />
        ) : (
          <NvBox width="100%">
            <NvEmptyState primaryText="There is no result"></NvEmptyState>
          </NvBox>
        )}
      </RecentQueryResultWrapper>
    </NvBox>
  );
};
