import { LabelValue } from '@novaera/core';
import { ExampleResponsePropertyPanel } from '../../../response-property-panel-drawer/panel/example-response';
import { ExternalAppConnectionType } from '@novaera/actioner-service';

export const formTriggerDefaultUserBehaviorOptions: LabelValue<boolean>[] = [
  {
    label: 'Fallback user connection',
    value: false,
    description:
      'This workflow will be run using the provided credentials if the runner user does not have any existing connections.',
  },
  {
    label: 'Override user connection',
    value: true,
    description: 'This workflow will be run using the credentials of the current user.',
  },
];

export const slackTriggerDefaultUserBehaviorOptions: LabelValue<ExternalAppConnectionType>[] = [
  {
    label: 'Fallback user connection',
    value: ExternalAppConnectionType.FALLBACK_TO_DEFAULT_USER,
    description:
      'This workflow will be run using the provided credentials if the Slack actor does not have any existing connections.',
  },
  {
    label: 'Override user connection',
    value: ExternalAppConnectionType.OVERRIDE_BY_DEFAULT_USER,
    description: 'This workflow will be run using the credentials of the current user.',
  },
];
