import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SelectWorkflowWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
`;
