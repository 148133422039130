import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { UseDeleteUserInGroupParams, UseGetUsersInGroupResponse } from '../types';

const deleteUserInGroup = async (params: UseDeleteUserInGroupParams) => {
  const { id, userId } = params;
  const result = await NvAxios.delete(`${GROUPS_ROOT_PATH}/${id}/users/${userId}`);
  return result?.data;
};

export const useDeleteUserInGroup = () => {
  const cache = useQueryClient();

  return useMutation(deleteUserInGroup, {
    onSuccess: (_, { id, userId }) => {
      cache.setQueryData<UseGetUsersInGroupResponse>(QUERY_KEYS_GROUPS.users(id), (old) => {
        if (old) {
          return { users: old.users.filter((user) => user.userId !== userId) };
        }
        return old;
      });
    },
  });
};
