import CodeMirror, { ShowHintOptions } from 'codemirror';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/mode/javascript/javascript';
import { JSONPath } from 'jsonpath-plus';
import replace from 'lodash/replace';

import { Context } from '../types';

CodeMirror.registerHelper('hint', 'template', templateHint);

export const showHintFn = (cm: CodeMirror.Editor) => {
  CodeMirror.showHint(cm, templateHint, {
    completeSingle: false,
    closeOnPick: true,
    closeOnUnfocus: true,
  });
};

CodeMirror.commands.autocomplete = function (cm) {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);
  const currentWord = getCurrentWordWithOutBrackets(cm);
  const showHint =
    (token.type?.includes('novaera_expression') || (currentWord !== '' && token.type?.includes('novaera_function'))) &&
    !!cm.getOption('showHint');

  if (showHint) {
    showHintFn(cm);
  }
};

const isArrayItem = (word: string) => {
  const isNumber = !isNaN(parseInt(word)) ? 'number' : typeof word === 'number';
  if (isNumber) {
    const emptyDotWord = word.replace(/\./g, '');
    return `[${emptyDotWord}]`;
  } else {
    return word;
  }
};

const replaceWordWithToken = (item: string, tokenString: string, currentWord: string, isInnerProperty: boolean) => {
  const word = isInnerProperty
    ? item.replace(new RegExp(`^${encodeURI(tokenString) === '.' ? '' : `${encodeURI(tokenString)}`}`, 'i'), '')
    : item;

  if (
    currentWord.endsWith('.') ||
    (!currentWord.endsWith('.') && !tokenString.endsWith(']')) ||
    item === `${tokenString}${word}`
  ) {
    return isArrayItem(word);
  } else {
    return isArrayItem(`.${word}`);
  }
};

const getCurrentWordWithOutBrackets = (cm: CodeMirror.Editor) => {
  const cursor = cm.getCursor();
  const lineTokens = cm.getLineTokens(cursor.line);
  const str = lineTokens.map((lt) => lt.string).join('');

  const regex = /{{(.*?)}}/g;
  let matches;
  let nearestMatch = null;

  // Find the nearest mustache template to the cursor
  while ((matches = regex.exec(str)) !== null) {
    if (cursor.ch >= matches.index && cursor.ch <= regex.lastIndex) {
      nearestMatch = matches[1];
      break;
    }
  }

  if (nearestMatch) {
    return nearestMatch.trim();
  } else {
    let start = cursor.ch;
    const lineContent = cm.getLine(cursor.line);

    // Find the start of the word (stop at the beginning of the line or space)
    while (start > 0 && /\S/.test(lineContent.charAt(start - 1)) && lineContent.charAt(start - 1) !== '{') {
      start--;
    }

    const word = lineContent.slice(start, cursor.ch);

    return word;
  }
};

const getDotOccurrenceCount = (word: string): number => (word.match(/\./g) || []).length;

function templateHint(cm: CodeMirror.Editor, options: ShowHintOptions) {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);

  const line: number = cursor.line;

  const currentWord = getCurrentWordWithOutBrackets(cm).toLowerCase();

  const list: {
    text: string;
    displayText: string;
    type: string;
    group?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (element: any, state: any) => void;
  }[] = [];
  if (cm.state.context) {
    const context: Context = cm.state.context;

    const pathItems: string[] = JSONPath({
      json: context,
      path: '$..*~',
      resultType: 'pointer',
    }).map((key: string) => replace(key.startsWith('/') ? key.substr(1) : key, /\//g, '.'));

    cm.state.allPaths = pathItems;

    pathItems
      .filter((item) => {
        let normalizedCurrentWord = currentWord;

        const isInnerProperty = getDotOccurrenceCount(normalizedCurrentWord) > 1;

        normalizedCurrentWord = replace(normalizedCurrentWord, /\[/g, '.');
        normalizedCurrentWord = replace(normalizedCurrentWord, /\]/g, '.');

        if (!isInnerProperty) {
          if (currentWord !== '') {
            /**
             * This is for ListAlerts.data expressions
             * It'll return next item by checking . counts in regex such as ListAlerts.data.data
             */
            return item.toLowerCase().startsWith(normalizedCurrentWord) && getDotOccurrenceCount(item) <= 1;
          } else {
            /**
             * This is for empty state of current word
             * It'll return first and second level recommend
             */
            return getDotOccurrenceCount(item) <= 1;
          }
        }

        return (
          item.toLowerCase().startsWith(normalizedCurrentWord) &&
          getDotOccurrenceCount(item) === getDotOccurrenceCount(normalizedCurrentWord)
        );
      })
      .map((item) => {
        const isInnerProperty = getDotOccurrenceCount(item) > 1; // A.data.

        if (!isInnerProperty) {
          return {
            displayText: item,
            text: replaceWordWithToken(item, token.string, currentWord, false),
          };
        } else {
          const splittedItem = item.split('.');
          return {
            displayText: splittedItem[splittedItem.length - 1],
            text: replaceWordWithToken(splittedItem[splittedItem.length - 1], token.string, currentWord, true),
          };
        }
      })
      .forEach((pathItem) => {
        const current = encodeURI(currentWord);
        const displayText = pathItem.displayText;
        let text = pathItem.text;

        if (pathItem.text.toLowerCase().startsWith(current)) {
          const regEx = new RegExp(current, 'i');
          text = pathItem.text.replace(regEx, '');
        }

        list.push({
          displayText,
          text,
          type: 'Array',
          render: (element, state) => {
            element.classList.add('novaera-hint-item');

            const displayTextElm = document.createElement('div');
            displayTextElm.classList.add('novaera-hint-content');
            displayTextElm.innerText = displayText;

            element.appendChild(displayTextElm);
          },
        });
      });
  }

  // if the list is an array, checking for [] to determine is it's array for now
  const isConsistOfArrayItems = list?.length > 0 && list[0].text.match(/^\[\d\]$/) !== null;

  const currentCursor = cm.getCursor();
  return {
    from: CodeMirror.Pos(line, currentCursor.ch + (isConsistOfArrayItems ? -1 : 0)),
    to: CodeMirror.Pos(line, currentCursor.ch),
    list: list,
  };
}

export { getCurrentWordWithOutBrackets, getDotOccurrenceCount, replaceWordWithToken, templateHint };
