import { NvArrowForwardIcon, NvBox, NvDragIndicatorIcon, NvFlex } from '@novaera/core';
import classNames from 'classnames';
import { cloneElement } from 'react';
import { RowItemWrapper } from './styled';
import { RowItemCardsProps } from './types';

export const RowItemCard = ({
  isSelected = false,
  isDisabled = false,
  hasError = false,
  onClick,
  rowItemLeftContentProps = { draggable: false },
  rowItemContent,
}: RowItemCardsProps) => {
  return (
    <RowItemWrapper
      className={classNames({
        'selected-item': isSelected,
        'has-error': hasError,
        'is-disabled': isDisabled,
      })}
      onClick={onClick}
    >
      {(rowItemLeftContentProps.draggable || rowItemLeftContentProps.icon) && (
        <NvFlex direction="row" alignItems="center" gap="4px" flex="0 0 auto">
          {rowItemLeftContentProps.draggable ? (
            <NvBox width="16px" height="16px" sx={{ cursor: 'pointer' }} {...rowItemLeftContentProps.dragHandleProps}>
              <NvDragIndicatorIcon sx={{ width: '16px', height: '16px' }} />
            </NvBox>
          ) : (
            rowItemLeftContentProps.icon &&
            cloneElement(rowItemLeftContentProps.icon, { sx: { flex: '0 0 auto', width: '16px', height: '16px' } })
          )}
        </NvFlex>
      )}
      <NvBox flex={'1 1 auto'} minWidth="0px">
        {rowItemContent}
      </NvBox>
      <NvArrowForwardIcon sx={{ flex: '0 0 auto', width: '16px', height: '16px' }} />
    </RowItemWrapper>
  );
};
