import { NvAddBoxIcon, NvButton } from '@novaera/core';
import React from 'react';

type NvButtonPropsButtonProps = React.ComponentPropsWithoutRef<typeof NvButton> & { label: string };

export const AddButton = React.forwardRef<HTMLButtonElement, NvButtonPropsButtonProps>(
  (props: React.ComponentPropsWithoutRef<typeof NvButton> & { label: string }, ref) => {
    return (
      <NvButton isFitContent startIcon={<NvAddBoxIcon />} color="secondary" size="small" ref={ref} {...props}>
        {props.label}
      </NvButton>
    );
  }
);
