import { Integration } from '@novaera/actioner-service';
import { Markdown, NvButton, NvCustomCheckIcon, NvFlex, NvSection, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useUpdateIntegrationModalBody } from '../controllers/use-update-integration-modal-body';
import { ChangeLog } from '../styled';
import { IntegrationSectionTitle } from '../title';

const getChangeLog = (integration: Integration) => {
  return (
    integration.changeLogs.find((changeLog) => changeLog.version.number === integration.latestVersion.number)
      ?.changeLog ?? ''
  );
};

export const IntegrationUpdateSection = ({
  integration,
  isDefaultExpanded,
  isUpdated,
}: {
  integration: Integration;
  isDefaultExpanded: boolean;
  isUpdated?: boolean;
}) => {
  const { isLoading, onUpdateClicked } = useUpdateIntegrationModalBody();
  const theme = useTheme();

  return (
    <NvSection
      key={`integration-update-section-${integration.id}-key`}
      sectionKey={`integration-update-section-${integration.id}`}
      title={
        <IntegrationSectionTitle
          name={integration.name}
          logoUrl={integration.logoUrl}
          version={`v${integration.latestVersion.number}.0.0`}
        />
      }
      headerActions={
        !isUpdated ? (
          <NvButton
            loading={isLoading}
            onClick={() => {
              onUpdateClicked({
                integrationId: integration.id,
                version: integration.latestVersion.number.toString(),
              });
            }}
            size="small"
          >
            Update
          </NvButton>
        ) : (
          <NvFlex flexDirection={'row'} gap={'4px'} alignItems={'center'}>
            <NvCustomCheckIcon
              htmlColor={theme.palette.nv_success[40]}
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
            <NvTypography textColor="subtle" variant="h5">
              Updated successfully
            </NvTypography>
          </NvFlex>
        )
      }
      isDefaultExpanded={isDefaultExpanded}
    >
      <NvFlex gap={'16px'} flexDirection={'column'}>
        {integration.shortDescription && <NvTypography>{integration.shortDescription}</NvTypography>}

        <NvFlex gap={'8px'} flexDirection={'column'}>
          <NvTypography variant="h5">Change log</NvTypography>
          <ChangeLog>
            <Markdown
              sx={{
                maxHeight: '350px',
                overflow: 'auto',
              }}
            >
              {getChangeLog(integration)}
            </Markdown>
          </ChangeLog>
        </NvFlex>
      </NvFlex>
    </NvSection>
  );
};
