import { useGetUserApp } from '@novaera/actioner-service';
import { NvButton, NvErrorIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { ErrorReason } from './error-reason';

export const ErrorStep = ({
  appId,
  failedWorkflows,
  onClose,
}: {
  appId: string;
  failedWorkflows: { executionId: string | null; failedWorkflowId: string }[];
  onClose: () => void;
}) => {
  const theme = useTheme();
  const { data: userApp } = useGetUserApp(appId);

  return (
    <NvFlex gap="24px" width="528px" alignItems="flex-start" direction="column">
      <NvFlex gap="4px" direction={'row'}>
        <NvErrorIcon sx={{ width: '24px', height: '24px' }} htmlColor={theme.palette.nv_error[40]} />
        <NvTypography variant="h2">Oops!</NvTypography>
      </NvFlex>
      <NvFlex gap={'16px'}>
        <NvTypography variant="body1">
          We encountered issues installing the "<b>{userApp?.name}</b>" application. Please, correct the details listed
          below and proceed with reinstalling the app.
        </NvTypography>
        {failedWorkflows.map(({ failedWorkflowId, executionId }) => (
          <ErrorReason
            key={`${failedWorkflowId}-${executionId}`}
            appId={appId}
            failedWorkflowId={failedWorkflowId}
            executionId={executionId}
          />
        ))}
      </NvFlex>

      <NvButton onClick={onClose}>Close</NvButton>
    </NvFlex>
  );
};
