import { UserAppPrincipal } from '../../user-app';
import { CreationSource } from '../common/types';
import { Trigger } from '../trigger/types';
import { Vertices } from '../vertex/types';
import { NodeSummaries } from './node/types';

export interface WorkflowUniquenessFields {
  appId: string;
  id: string;
}

export type Graph = {
  vertices: Vertices;
};

export enum WorkflowPermissionScope {
  READ = 'read',
  EDIT = 'edit',
  DENY = 'deny',
}

export type WorkflowPermission = {
  principal: UserAppPrincipal;
  scope: WorkflowPermissionScope;
};

export interface Workflow extends WorkflowUniquenessFields {
  name: string;
  description?: string;
  searchInvisibleByDefault?: boolean;
  disabled: boolean;
  tags?: string[];
  trigger?: Trigger;
  endpoint?: string;
  graph?: Graph;
  nodeSummaries: NodeSummaries;
  permissions?: WorkflowPermission[];
  creationSource?: CreationSource;
}

export interface WorkflowWithState extends Workflow {
  state: WorkflowState;
}

export enum WorkflowState {
  SAVED = 'saved',
  DRAFT = 'draft',
}
