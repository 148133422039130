import { FunctionType, USER_PERMISSION, useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert, generateUniqueId } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useUserPermissions } from '../../../../../../user-permission-boundary/use-user-permission';
import { useFunctionsContext } from '../../../../../providers/functions-provider';

export const useFunctions = () => {
  const { actionId, integrationId } = useParams();
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ actionId, integrationId, version: integration?.latestVersion.number });
  const {
    fields: { push: addFunction },
  } = useFieldArray('functions');

  const functions = useMemo(() => data?.functions, [data?.functions]);

  const { setSelectedFunctionId } = useFunctionsContext();

  const onAddNewFunction = () => {
    const index = functions?.length ?? 0;
    const id = generateUniqueId();
    const newFunctionItem: FunctionType = {
      id,
      name: `Function${index}`,
      script: 'var example = 0;',
      type: 'function',
    };

    const versionNumber = integration?.latestVersion?.number;
    assert(!isUndefined(versionNumber), new Error('Integration is expected to be defined'), 'ERROR');
    addFunction(newFunctionItem);
    setSelectedFunctionId(id);
  };

  return { onAddNewFunction, hasIntegrationUpdateRight };
};
