import { useState } from 'react';

export const useAddNewConnectionModelController = () => {
  const [isNewIntegrationModelOpened, setIsNewIntegrationModelOpened] = useState<boolean>(false);

  const handleChangeAddNewConnectionModelState = (state: boolean) => {
    setIsNewIntegrationModelOpened(state);
  };

  return {
    onChangeAddNewConnectionModelState: handleChangeAddNewConnectionModelState,
    isNewIntegrationModelOpened,
  };
};
