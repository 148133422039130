import { StaticMonthlyJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvBox, NvFlex, NvTypography } from '@novaera/core';
import { CronDayOfMonthPicker } from '../../../../../../../../../../../jobs/components/cron-day-of-month-picker';
import { CronTimePicker } from '../../../../../../../../../../../jobs/components/cron-time-picker';
import { MONTHLY_CRON_EXPRESSION } from '../../../../../../../../../../../jobs/constants';
import { RecurrenceProps } from '../../types';

export const StaticMonthlyRecurrence: React.FC<
  Omit<RecurrenceProps<StaticMonthlyJobRecurrenceConfiguration>, 'context'>
> = ({ recurrenceConfiguration, onChange }) => {
  const cron = recurrenceConfiguration.cronExpression ?? MONTHLY_CRON_EXPRESSION;

  return (
    <NvFlex gap="8px" width="100%">
      <NvFlex direction="row" alignItems="flex-start" gap="8px">
        <NvTypography variant="body2" padding="6px 0" flex="0 0 auto">
          Days
        </NvTypography>
        <NvBox flex="1 1 auto" minWidth={0}>
          <CronDayOfMonthPicker
            cron={cron}
            onChange={(cron) => {
              onChange({
                ...recurrenceConfiguration,
                cronExpression: cron,
              });
            }}
          />
        </NvBox>
      </NvFlex>
      <NvFlex direction="row" alignItems="flex-start" gap="8px">
        <NvTypography variant="body2" padding="6px 0">
          At
        </NvTypography>
        <CronTimePicker
          cron={cron}
          onChange={(cron) => {
            onChange({
              ...recurrenceConfiguration,
              cronExpression: cron,
            });
          }}
        />
      </NvFlex>
    </NvFlex>
  );
};
