import { useGetIntegrationTestResult } from '@novaera/actioner-service';
import { CodeInput, Context, NvField, NvFlex } from '@novaera/core';
import { useMemo } from 'react';
import { PropertyPanelSection, useTestActionContext } from '../../../../../../../../../../components';

export const FormTriggerDynamicSourceMapping = ({
  fieldNamePrefix,
  context,
}: {
  fieldNamePrefix: string;
  context?: Context;
}) => {
  const { testActionParams } = useTestActionContext();
  const { data: integrationTestResult } = useGetIntegrationTestResult({ ...testActionParams });

  const sourceMappingContext = useMemo(
    () => ({
      ...context,
      ...(integrationTestResult?.successResponse ? { response: integrationTestResult?.successResponse } : {}),
    }),
    [context, integrationTestResult?.successResponse]
  );

  return (
    <PropertyPanelSection title="Source mapping">
      <NvFlex gap="8px">
        <NvField
          infoText="Field should have at most 75 length."
          labelVariant="h6"
          labelText="Display"
          direction="label-on-side"
          component={<CodeInput context={sourceMappingContext as Context} />}
          name={`${fieldNamePrefix}.optionTemplatePair.displayValueTemplate`}
        />
        <NvField
          infoText="Field should have at most 75 length."
          labelVariant="h6"
          labelText="Identifier"
          direction="label-on-side"
          component={<CodeInput context={sourceMappingContext as Context} />}
          name={`${fieldNamePrefix}.optionTemplatePair.identifierTemplate`}
        />
      </NvFlex>
    </PropertyPanelSection>
  );
};
