import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { UseDeleteGroupParams, UseGetGroupsResponse } from '../types';

const deleteGroup = async (params: UseDeleteGroupParams) => {
  const { id } = params;
  const result = await NvAxios.delete(`${GROUPS_ROOT_PATH}/${id}`);
  return result?.data;
};

export const useDeleteGroup = () => {
  const cache = useQueryClient();

  return useMutation(deleteGroup, {
    onSuccess: (_, { id }) => {
      cache.setQueryData<UseGetGroupsResponse | undefined>(QUERY_KEYS_GROUPS.list(), (old) => {
        if (old) {
          return { groups: old.groups.filter((group) => group.id !== id) };
        }
        return old;
      });
    },
  });
};
