import { NvAxios, useQuery } from '@novaera/core';
import { WORKFLOW_HISTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_WORKFLOW_HISTORIES } from '../keys';
import { GetWorkflowNodeExecutionParams, GetWorkflowNodeExecutionResponse } from './types';

const getWorkflowNodeExecution = async ({
  appId,
  workflowId,
  workflowExecutionId,
  nodeExecutionKey,
}: GetWorkflowNodeExecutionParams) => {
  const result = await NvAxios.get<GetWorkflowNodeExecutionResponse>(
    `${WORKFLOW_HISTORY_ROOT_PATH({ appId, workflowId })}/${workflowExecutionId}/node-executions/${nodeExecutionKey}`
  );
  return result?.data;
};

export const useGetWorkflowNodeExecution = ({
  appId,
  workflowId,
  workflowExecutionId,
  nodeExecutionKey,
}: GetWorkflowNodeExecutionParams) => {
  return useQuery(
    QUERY_KEYS_WORKFLOW_HISTORIES.node_execution({ appId, workflowId, workflowExecutionId, nodeExecutionKey }),
    () => getWorkflowNodeExecution({ appId, workflowId, workflowExecutionId, nodeExecutionKey }),
    {
      enabled: !!appId && !!workflowId && !!workflowExecutionId && !!nodeExecutionKey,
    }
  );
};
