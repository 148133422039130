import { JobRecurrenceConfiguration, RecurrenceTimeUnit } from '@novaera/actioner-service';
import { NvBox, NvCustomDateTime, NvField, NvFlex, NvSelect } from '@novaera/core';
import { assert } from '@novaera/utils';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import {
  ANNUAL_CRON_EXPRESSION,
  DEFAULT_CRON_EXPRESSION,
  MONTHLY_CRON_EXPRESSION,
  RECURRENCE_TIME_UNITS,
  WEEKDAYS_CRON_EXPRESSION,
  WEEKLY_CRON_EXPRESSION,
} from '../../../../../../../../jobs/constants';
import { DEFAULT_RECURRENCE_CONFIGURATION } from '../../../form-wrapper/constants';
import { CustomRecurrence } from './recurrences/custom';
import { DailyRecurrence } from './recurrences/daily';
import { HourlyRecurrence } from './recurrences/hourly';
import { MinutelyRecurrence } from './recurrences/minutely';
import { MonthlyRecurrence } from './recurrences/monthly';
import { WeekdaysRecurrence } from './recurrences/weekdays';
import { WeeklyRecurrence } from './recurrences/weekly';
import { YearlyRecurrence } from './recurrences/yearly';
import { RecurrenceConfigurationProps } from './types';

export const RecurrenceConfiguration: React.FC<RecurrenceConfigurationProps> = ({ context }) => (
  <PropertyPanelSection title={'Recurrence time'} icon={<NvCustomDateTime />}>
    <NvField<JobRecurrenceConfiguration>
      name={'operation.recurrenceConfiguration'}
      formControlStyle={{ width: '100%' }}
      component={({ value: recurrenceConfiguration, onChange }) => (
        <NvFlex gap="8px">
          <NvBox width="160px">
            <NvSelect
              fullWidth
              value={recurrenceConfiguration?.type}
              onChange={(e) => {
                const type = e.target.value as RecurrenceTimeUnit;
                const { repeatInterval, recurrenceMinute, recurrenceHour, dynamic } = DEFAULT_RECURRENCE_CONFIGURATION;
                let newRecurrenceConfiguration: JobRecurrenceConfiguration;

                if (type === RecurrenceTimeUnit.MINUTE) {
                  newRecurrenceConfiguration = {
                    type,
                    repeatInterval,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.HOUR) {
                  newRecurrenceConfiguration = {
                    type,
                    repeatInterval,
                    recurrenceMinute,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.DAY) {
                  newRecurrenceConfiguration = {
                    type,
                    repeatInterval,
                    recurrenceMinute,
                    recurrenceHour,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.WEEKLY) {
                  newRecurrenceConfiguration = {
                    type,
                    cronExpression: WEEKLY_CRON_EXPRESSION,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.WEEKDAYS) {
                  newRecurrenceConfiguration = {
                    type,
                    cronExpression: WEEKDAYS_CRON_EXPRESSION,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.MONTHLY) {
                  newRecurrenceConfiguration = {
                    type,
                    cronExpression: MONTHLY_CRON_EXPRESSION,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.ANNUAL) {
                  newRecurrenceConfiguration = {
                    type,
                    cronExpression: ANNUAL_CRON_EXPRESSION,
                    dynamic,
                  };
                } else if (type === RecurrenceTimeUnit.CUSTOM) {
                  newRecurrenceConfiguration = {
                    type,
                    cronExpression: DEFAULT_CRON_EXPRESSION,
                    dynamic,
                  };
                } else {
                  assert(
                    false,
                    new Error(`[SaveRecurringJobFields] - recurrenceConfiguration type can not be ${type}`),
                    'ERROR'
                  );
                }

                onChange(newRecurrenceConfiguration);
              }}
              options={RECURRENCE_TIME_UNITS}
            />
          </NvBox>
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.MINUTE && (
            <MinutelyRecurrence
              recurrenceConfiguration={recurrenceConfiguration}
              context={context}
              onChange={onChange}
            />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.HOUR && (
            <HourlyRecurrence recurrenceConfiguration={recurrenceConfiguration} context={context} onChange={onChange} />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.DAY && (
            <DailyRecurrence recurrenceConfiguration={recurrenceConfiguration} context={context} onChange={onChange} />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.WEEKDAYS && (
            <WeekdaysRecurrence
              recurrenceConfiguration={recurrenceConfiguration}
              context={context}
              onChange={onChange}
            />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.WEEKLY && (
            <WeeklyRecurrence recurrenceConfiguration={recurrenceConfiguration} context={context} onChange={onChange} />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.MONTHLY && (
            <MonthlyRecurrence
              recurrenceConfiguration={recurrenceConfiguration}
              context={context}
              onChange={onChange}
            />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.ANNUAL && (
            <YearlyRecurrence recurrenceConfiguration={recurrenceConfiguration} context={context} onChange={onChange} />
          )}
          {recurrenceConfiguration?.type === RecurrenceTimeUnit.CUSTOM && (
            <CustomRecurrence recurrenceConfiguration={recurrenceConfiguration} context={context} onChange={onChange} />
          )}
        </NvFlex>
      )}
    ></NvField>
  </PropertyPanelSection>
);
