import { NvButton, NvFlex } from '@novaera/core';
import React from 'react';
import { NvProductLogoWithName } from '../product-icon/styled';

import { Description, ErrorPanel, ErrorPanelRight, Title } from './styled';
import { ErrorPageProps } from './types';

export const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  title,
  description,
  onClick,
  buttonText,
}) => {
  return (
    <NvFlex alignItems="center" marginTop="120px">
      <NvProductLogoWithName />
      <ErrorPanel>
        <ErrorPanelRight>
          <NvFlex width={1}>
            <Title variant="h2">{title}</Title>
            <Description variant="body1">{description}</Description>
            <NvButton onClick={onClick} isFitContent>
              {buttonText ?? 'Go to Actioner'}
            </NvButton>
          </NvFlex>
        </ErrorPanelRight>
      </ErrorPanel>
    </NvFlex>
  );
};
