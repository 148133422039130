import { NvFlex, NvSkeleton } from '@novaera/core';
import {
  PropertyPanelSectionBody,
  PropertyPanelSectionContainer,
  PropertyPanelSectionHeader,
  PropertyPanelSectionHeaderActions,
} from '../styled';

export const PropertyPanelSectionLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <PropertyPanelSectionContainer>
    <PropertyPanelSectionHeader>
      <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
        <NvSkeleton variant="rectangular" height="16px" width="16px" />
        <NvSkeleton variant="rectangular" height="12px" width="30px" />
        <NvSkeleton variant="rectangular" width="12px" height="12px" />
      </NvFlex>

      <PropertyPanelSectionHeaderActions>
        <NvSkeleton variant="rectangular" width="16px" height="16px" />
      </PropertyPanelSectionHeaderActions>
    </PropertyPanelSectionHeader>

    <PropertyPanelSectionBody>
      <NvFlex gap="8px">
        <NvSkeleton variant="rectangular" width="100%" height="32px" />
        <NvSkeleton variant="rectangular" width="100%" height="32px" />
        <NvSkeleton variant="rectangular" width="100%" height="32px" />
      </NvFlex>
    </PropertyPanelSectionBody>
  </PropertyPanelSectionContainer>
);
