import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConnectionItemCard = styled(NvFlex)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;

  &.ambiguous {
    border: 1px solid ${({ theme }) => theme.palette.nv_warning[30]};
  }
`;
