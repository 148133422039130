import { ROUTES } from '@novaera/actioner-ui';
import { NvAdminIcon, NvLink } from '@novaera/core';
import { AnalyticsPanel } from './analytics';
import { AppSettings } from './app-directory-settings';
import { CustomPlanConfiguration } from './custom-plan-configuration';
import { EsSearch } from './es-search';
import { IntegrationSync } from './integration-sync';
import { AdminIntegrations } from './integrations';
import { HeaderWithTabLayoutStyled } from './styled';
import { UserPanel } from './user-panel';
import { AdminWorkspaces } from './workspaces';

export const AdminTabs = ({ productId }: { productId: 'actioner' }) => {
  return (
    <HeaderWithTabLayoutStyled
      title={{
        label: 'Actioner Admin',
        icon: (
          <NvLink href="/">
            <NvAdminIcon fontSize="large" />
          </NvLink>
        ),
      }}
      tabs={[
        {
          path: `${ROUTES.Admin}/users`,
          label: 'Users',
          content: <UserPanel productId={productId} />,
        },
        {
          path: `${ROUTES.Admin}/workspaces`,
          label: 'Workspaces',
          content: <AdminWorkspaces productId={productId} />,
        },

        {
          path: `${ROUTES.Admin}/analytic-queries`,
          label: 'Analytics',
          content: <AnalyticsPanel />,
        },
        {
          path: `${ROUTES.Admin}/sync-integration`,
          label: 'Integration/App sync',
          content: <IntegrationSync />,
        },
        {
          path: `${ROUTES.Admin}/integration-directory`,
          label: 'Integrations directory',
          content: <AdminIntegrations />,
        },
        {
          path: `${ROUTES.Admin}/app-directory-settings`,
          label: 'App Directory settings',
          content: <AppSettings />,
        },
        {
          path: `${ROUTES.Admin}/custom-plan-configuration`,
          label: 'Custom plan configuration',
          content: <CustomPlanConfiguration />,
        },
        {
          path: `${ROUTES.Admin}/es-search`,
          label: 'Es Search',
          content: <EsSearch />,
        },
      ]}
    />
  );
};
