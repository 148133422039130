import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const BillingLayoutWrapper = styled(NvBox)`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const TrialBillingLayoutWrapper = styled(NvBox)`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const CardLayout = styled(NvBox)`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
