export default {
  font: {
    paragraph: {
      body1: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 14,
          fontFamily: 'SF Pro Display',
          fontWeight: 400,
          lineHeight: 20,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:a2ca9e3e042ecd1c2de81c578f77040bc795da8f,',
            exportKey: 'font',
          },
        },
      },
      body2: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 12,
          fontFamily: 'SF Pro Display',
          fontWeight: 400,
          lineHeight: 18,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:6851f83571b800fb953acff5e43c61103aa963b0,',
            exportKey: 'font',
          },
        },
      },
      body3: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 10,
          fontFamily: 'SF Pro Display',
          fontWeight: 400,
          lineHeight: 14,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:c6b5d020d1b66a8ded43de0b84fc0f162807097f,',
            exportKey: 'font',
          },
        },
      },
    },
    header: {
      h1: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 22,
          fontFamily: 'SF Pro Display',
          fontWeight: 600,
          lineHeight: 28,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:3aabbb0e4ccf30145eb4dbbc81a7fb8fa7c24414,',
            exportKey: 'font',
          },
        },
      },
      h2: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 18,
          fontFamily: 'SF Pro Display',
          fontWeight: 600,
          lineHeight: 24,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:a9eb374919b9ececdf174c57bba260c3819867d1,',
            exportKey: 'font',
          },
        },
      },
      h3: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 16,
          fontFamily: 'SF Pro Display',
          fontWeight: 600,
          lineHeight: 22,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:65bed0ba09edb8a345de7fc6a6544cb28e7141e9,',
            exportKey: 'font',
          },
        },
      },
      h4: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 14,
          fontFamily: 'SF Pro Display',
          fontWeight: 600,
          lineHeight: 18,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:9a7d3943ae51ca59c6c4146d2fcf4d6631c90a0e,',
            exportKey: 'font',
          },
        },
      },
      h5: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 12,
          fontFamily: 'SF Pro Display',
          fontWeight: 600,
          lineHeight: 16,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:ad6647d7bb1d91ecc070390092f63a13f6260954,',
            exportKey: 'font',
          },
        },
      },
      h6: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 12,
          fontFamily: 'SF Pro Display',
          fontWeight: 400,
          lineHeight: 16,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:aa82caac441093cee597bb2c38689625dbb98c64,',
            exportKey: 'font',
          },
        },
      },
      h7: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 10,
          fontFamily: 'SF Pro Display',
          fontWeight: 700,
          lineHeight: 12,
          textCase: 'uppercase',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:9687c018d4fed283f5c70ca938a287a00e10f0e8,',
            exportKey: 'font',
          },
        },
      },
      h8: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 10,
          fontFamily: 'SF Pro Display',
          fontWeight: 700,
          lineHeight: 12,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:698b9b0d7af30b567ed7a7c6c056033a1048d7b7,',
            exportKey: 'font',
          },
        },
      },
    },
    code: {
      c1: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 14,
          fontFamily: 'Fira Code',
          fontWeight: 400,
          lineHeight: 18,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:82985a40bfe9e7bfa7a6b6ab2f07082da073a1f7,',
            exportKey: 'font',
          },
        },
      },
      c2: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 12,
          fontFamily: 'Fira Code',
          fontWeight: 500,
          lineHeight: 16,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:573aa090bc51152888b3074ce9982386ab930ade,',
            exportKey: 'font',
          },
        },
      },
      c3: {
        type: 'custom-fontStyle',
        value: {
          fontSize: 10,
          fontFamily: 'Fira Code',
          fontWeight: 500,
          lineHeight: 14,
          textCase: 'none',
        },
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:68f52d73c75654430f142121eddad605519ad3da,',
            exportKey: 'font',
          },
        },
      },
    },
  },
};
