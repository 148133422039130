import { ScheduledJob, useDeleteJob, useGetScheduledJobs } from '@novaera/actioner-service';
import { NvButton, NvDeleteOutlineIcon, NvTypography, useConfirmDialog } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';

export const useScheduledJobs = ({
  timezoneId,
  handleEditClick,
}: {
  timezoneId?: string;
  handleEditClick: ({ id, type }: Pick<ScheduledJob, 'id' | 'type'>) => void;
}) => {
  const { userAppId } = useParams();
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const {
    data: getScheduledJobsQueries,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetScheduledJobs({ appId: userAppId, timezoneId });

  const getScheduledJobsPages = getScheduledJobsQueries?.pages.filter((p) => p);

  const { mutate: deleteJob, isLoading: isDeleteJobLoading } = useDeleteJob({ timezoneId });

  const theme = useTheme();

  const handleDelete = ({ id, name, type }: Pick<ScheduledJob, 'id' | 'name' | 'type'>) => {
    openConfirm({
      title: `Delete ${name}?`,
      message: (
        <NvTypography variant="body1">
          <strong>{name}</strong> will no longer be available for this app. Deleted jobs cannot be recovered.
        </NvTypography>
      ),
      confirmButton: (
        <NvButton
          variant="contained"
          size="medium"
          color="error"
          loading={isDeleteJobLoading}
          disabled={isDeleteJobLoading}
          onClick={() => {
            return new Promise<void>((resolve) => {
              deleteJob(
                { appId: userAppId, jobId: id, type },
                {
                  onSettled: () => {
                    closeConfirm();
                    resolve();
                  },
                }
              );
            });
          }}
        >
          Delete
        </NvButton>
      ),
    });
  };

  const menuItems = ({ id, name, type }: ScheduledJob) => [
    {
      name: 'Edit',
      onClick: () => {
        handleEditClick({ id, type });
      },
    },
    {
      name: 'divider',
      isDivider: true,
      dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
    },
    {
      name: 'Delete',
      onClick: () => {
        handleDelete({ id, name, type });
      },
      icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
    },
  ];

  return {
    getScheduledJobsPages,
    isLoading: isInitialLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    userAppId,
    menuItems,
  };
};
