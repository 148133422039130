import {
  InputParameter,
  ParameterMapping,
  ParameterMappings,
  UIComponentType,
  WorkflowState,
} from '@novaera/actioner-service';
import { NvDynamicFormIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { FC, useCallback, useMemo } from 'react';
import { useInputValuesContext } from '../../../action-designer/providers/input-values';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../../../action-designer/providers/input-values/utils';
import {
  SearchAsYouTypeValuesProvider,
  useSearchAsYouTypeValuesContext,
} from '../../../action-designer/providers/search-as-you-type-values';
import { useFormIdentifierContext } from '../../../providers/form-identifier-provider';
import { UserAppOptionsProvider } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/components/options/provider';
import { GetDynamicInputResponseFunction } from '../../dynamic-input/providers/action-dynamic-input-provider/types';
import { WaitForProvider, useWaitForContext } from '../../dynamic-input/providers/wait-for-provider';
import {
  WorkflowDynamicInputProvider,
  useWorkflowDynamicInputContext,
} from '../../dynamic-input/providers/workflow-dynamic-input-provider';
import { componentsAlwaysShowingOptions, componentsNeedsOptions } from '../../ui-components/utils';
import { WorkflowParameterMapperInnerProps, WorkflowParameterMapperProps } from '../types';
import { useCalculateShouldAddFormId } from '../utils';
import { WorkflowParameterMapperForm } from './workflow-parameter-mapper-form';

export const WorkflowParameterMapper: FC<React.PropsWithChildren<WorkflowParameterMapperProps>> = ({
  appId,
  workflowId,
  onParameterMappingsChanged,
  initialParameterMappings,
  context,
  workflow,
  isForceHideSwitch,
  isDisplayValueConfigurable = false,
}) => {
  const workflowTrigger = workflow.trigger;
  assert(workflowTrigger?.type === 'form', new Error('Workflow parameter mapping need form trigger'), 'ERROR');
  const { formId } = useFormIdentifierContext();
  const { inputValues } = useInputValuesContext();

  const { dynamicInputParameterIds, noDynamicInputParameters } = useMemo(
    () => ({
      dynamicInputParameterIds: (
        workflowTrigger.inputParameters.filter(
          (inputParameter) => inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT
        ) ?? []
      ).map((d) => d.id),
      noDynamicInputParameters: workflowTrigger.inputParameters.filter(
        (inputParameter) => inputParameter.uiComponent.type !== UIComponentType.DYNAMIC_INPUT
      ),
    }),
    [workflowTrigger]
  );

  const inputParameterValues = useMemo(() => {
    return formatAndFilterInputFormValuesAsParameterMappings(
      inputValues,
      noDynamicInputParameters.map((i) => i.id)
    );
  }, [noDynamicInputParameters, inputValues]);

  const conditionFunction = useCallback<
    (params: { conditionVariable: unknown; prevConditionVariable: unknown }) => boolean
  >(({ conditionVariable, prevConditionVariable }) => {
    return !!(prevConditionVariable && !conditionVariable);
  }, []);

  return (
    <SearchAsYouTypeValuesProvider>
      <WorkflowDynamicInputProvider
        appId={appId}
        workflowId={workflowId}
        draft={workflow?.state === WorkflowState.DRAFT}
        formId={formId}
        initialInputParameterIdsShowingOptions={dynamicInputParameterIds}
        inputParameterIds={dynamicInputParameterIds}
        allInputParameterIdsWithOrder={workflowTrigger.inputParameters.map((i) => i.id)}
        inputParameterValues={inputParameterValues}
        storeDynamicFormParametersPermanently
        inputParameters={workflowTrigger.inputParameters}
      >
        {({ isBusy, getDynamicInputParameters }) => (
          <WaitForProvider
            conditionVariable={isBusy}
            argsChanged={getDynamicInputParameters}
            conditionFunction={conditionFunction}
          >
            <WorkflowParameterMapperInner
              appId={appId}
              workflowId={workflowId}
              workflow={workflow}
              inputParameters={workflowTrigger.inputParameters}
              initialParameterMappings={initialParameterMappings}
              context={context}
              onParameterMappingsChanged={onParameterMappingsChanged}
              isForceHideSwitch={isForceHideSwitch}
              isDisplayValueConfigurable={isDisplayValueConfigurable}
            />
          </WaitForProvider>
        )}
      </WorkflowDynamicInputProvider>
    </SearchAsYouTypeValuesProvider>
  );
};

const WorkflowParameterMapperInner: FC<React.PropsWithChildren<WorkflowParameterMapperInnerProps>> = ({
  onParameterMappingsChanged,
  initialParameterMappings,
  context,
  inputParameters,
  workflow,
  appId,
  workflowId,
  isForceHideSwitch,
  isDisplayValueConfigurable,
}) => {
  const { inputValues } = useInputValuesContext();
  const theme = useTheme();
  const { formId } = useFormIdentifierContext();
  const { dynamicInputParameters, handleGetDynamicInputComponentState, getDynamicInputParameters } =
    useWorkflowDynamicInputContext();
  const { wrapMethodForWaitUntil } = useWaitForContext<ParameterMapping[], typeof getDynamicInputParameters>();
  const { calculateShouldAddFormId } = useCalculateShouldAddFormId({
    handleGetDynamicInputComponentState,
  });
  const allInputParameterIdsWithOrder = useMemo(
    () => [
      ...inputParameters.map((ip) => ip.id),
      ...dynamicInputParameters.map((dynamicInputParameter) => dynamicInputParameter.id),
    ],
    [dynamicInputParameters, inputParameters]
  );

  const { inputParameterIds, inputParameterIdsShowingOptions } = useMemo(
    () => ({
      inputParameterIds: inputParameters
        .filter((inputParameter) => componentsNeedsOptions(inputParameter.uiComponent))
        .map((ip) => ip.id),
      inputParameterIdsShowingOptions: inputParameters.reduce<string[]>((prev, current) => {
        if (componentsAlwaysShowingOptions(current.uiComponent)) {
          return [...prev, current.id];
        } else {
          return prev;
        }
      }, []),
    }),
    [inputParameters]
  );

  const { searchAsYouTypeValues } = useSearchAsYouTypeValuesContext();
  const inputParameterValues = useMemo(() => {
    return formatAndFilterInputFormValuesAsParameterMappings(inputValues, allInputParameterIdsWithOrder);
  }, [allInputParameterIdsWithOrder, inputValues]);

  const handleParameterMappingsChanged = useCallback(
    (values: ParameterMappings, getDynamicInputParameters: GetDynamicInputResponseFunction) => {
      const shouldAddFormId = calculateShouldAddFormId(values, inputParameters, getDynamicInputParameters);
      onParameterMappingsChanged({ parameterMappings: values, ...(shouldAddFormId && { formId }) });
    },
    [calculateShouldAddFormId, formId, inputParameters, onParameterMappingsChanged]
  );

  const wrappedParameterMappingsChanged = wrapMethodForWaitUntil(({ argsChanged, argsNotChanged }) =>
    handleParameterMappingsChanged(argsNotChanged, argsChanged)
  );

  const handleScriptedChange = useCallback(
    ({ inputParameter, scripted }: { inputParameter: InputParameter; scripted: boolean }) => {
      if (inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT) {
        if (scripted) {
          // clear old values of dynamic input parameter mappings if all block converts into scripted mode.
          const dynamicInputParameterIds = dynamicInputParameters.map(({ id }) => id);

          const filteredParameterMappings =
            initialParameterMappings?.filter((p) => !dynamicInputParameterIds.includes(p.parameterId)) ?? [];
          wrappedParameterMappingsChanged(filteredParameterMappings);
        }
      }
    },
    [wrappedParameterMappingsChanged, dynamicInputParameters, initialParameterMappings]
  );

  return inputParameters.length > 0 ? (
    <UserAppOptionsProvider
      appId={appId}
      workflowId={workflowId}
      draft={workflow.state === WorkflowState.DRAFT}
      inputParameterIds={inputParameterIds}
      initialInputParameterIdsShowingOptions={inputParameterIdsShowingOptions}
      searchAsYouTypeValues={searchAsYouTypeValues}
      allInputParameterIdsWithOrder={allInputParameterIdsWithOrder}
      inputParameterValues={inputParameterValues}
      formId={formId}
    >
      <WorkflowParameterMapperForm
        context={context}
        inputParameters={inputParameters}
        onParameterMappingsChanged={wrappedParameterMappingsChanged}
        initialParameterMappings={initialParameterMappings}
        isForceHideSwitch={isForceHideSwitch}
        onScriptedChange={handleScriptedChange}
        isDisplayValueConfigurable={isDisplayValueConfigurable}
      />
    </UserAppOptionsProvider>
  ) : (
    <NvFlex direction="row" alignItems="flex-start" gap="8px" padding="3px 0">
      <NvDynamicFormIcon
        sx={{ width: '16px', height: '16px', marginTop: '1px' }}
        htmlColor={theme.palette.nv_neutral[60]}
      />
      <NvTypography variant="body2" textColor="ghost">
        This workflow doesn't take any inputs.
      </NvTypography>
    </NvFlex>
  );
};
