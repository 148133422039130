import { NvSearchIcon, NvTextField } from '@novaera/core';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { SimpleSearchInputProps } from './types';

export const SimpleSearchInput: React.FC<React.PropsWithChildren<SimpleSearchInputProps>> = ({
  onKeywordChanged,
  initialKeyword,
  placeholder,
  color = 'primary',
  ...props
}) => {
  const [keyword, setKeyword] = useState<string>(initialKeyword ?? '');
  const debouncedOnChange = useDebouncedCallback((e) => {
    onKeywordChanged(e.target.value);
  }, 500);

  return (
    <NvTextField
      data-testid="simple-search-input"
      placeholder={placeholder ?? 'Search'}
      startIcon={<NvSearchIcon />}
      value={keyword}
      onChange={(e) => {
        setKeyword(e.target.value);
        debouncedOnChange(e);
      }}
      color={color}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      {...props}
    />
  );
};
