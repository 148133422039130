import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SingleLineJsonTagBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 6px;
  height: 18px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
  border-radius: 6px;
`;
