import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AnalyticsQueryResponse } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAnalyticsQueryResult = async ({ queryId, pageParam }: any) => {
  const result = await NvAxios.get<AnalyticsQueryResponse>(`/admin/analytics/queries/${queryId}`, {
    params: { limit: 20, ...(pageParam ? { next: pageParam } : {}) },
  });
  return result?.data;
};

export const useGetAnalyticsQueryResult = (queryId: string) => {
  return useInfiniteQuery<AnalyticsQueryResponse>(
    ['analytics-query-result', queryId],
    (props) => getAnalyticsQueryResult({ queryId, ...props }),
    {
      getNextPageParam: (lastPage) => lastPage?.next,
      retry: false,
    }
  );
};
