import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const Redirecting = styled(NvFlex)`
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
`;
