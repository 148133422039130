import { useQuery } from '@novaera/core';
import { FetchFromS3Props, FetchFromS3Response } from './types';

const fetchFinalContextFromS3 = async ({ link }: Pick<FetchFromS3Props, 'link'>): FetchFromS3Response => {
  if (link) {
    const response = await fetch(link);
    return response.json();
  } else {
    return undefined;
  }
};

export const useFetchFromS3 = ({ link, key, enabled = true, onSettled, staleTime, onError }: FetchFromS3Props) =>
  useQuery(key, () => fetchFinalContextFromS3({ link }), {
    enabled: enabled && !!link && !!key,
    onSettled(data, error) {
      onSettled && onSettled(data, error);
    },
    ...{
      ...(onError && {
        onError: (error) => {
          onError(error);
        },
      }),
    },
    ...{
      ...(staleTime !== undefined && {
        staleTime,
      }),
    },
  });
