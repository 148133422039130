import { CreateUserAppParams, useCreateUserApp } from '@novaera/actioner-service';
import { useNavigate } from '@novaera/route';
import { USER_APP_DETAIL } from '../../user-app-detail/constants';

export const useCreateUserAppModal = ({ onClose }: { onClose: () => void }) => {
  const { mutate: createUserApp } = useCreateUserApp();

  const navigate = useNavigate();

  const handleOnSubmit = async (values: CreateUserAppParams) => {
    const createUserAppValues: CreateUserAppParams = {
      name: values.name,
      ...(values.description?.value && { description: { type: 'markdown', value: values.description?.value } }),
      logoId: values.logoId,
    };
    return new Promise<void>((resolve) => {
      createUserApp(createUserAppValues, {
        onSuccess: ({ id }) => {
          onClose();
          navigate(USER_APP_DETAIL(id));
        },
        onSettled: () => {
          resolve();
        },
      });
    });
  };

  return {
    handleOnSubmit,
  };
};
