import { useTheme } from '@novaera/theme-provider';

import { SectionMessageContainer, SectionMessageIconBox, SectionMessageTextBox } from './styled';
import { SectionMessageProps } from './types';

import { NvBox } from '../box';
import { NvButton } from '../button';
import { NvCloseIcon, NvErrorIcon, NvWarningIcon } from '../icons';
import { NvTypography } from '../typography';

export const SectionMessage: React.FC<React.PropsWithChildren<SectionMessageProps>> = ({
  variant,
  message,
  title,
  onClose,
  subtle,
  actionButton,
  className,
}) => {
  const theme = useTheme();
  const sectionMessageIcon = (variant?: SectionMessageProps['variant']) => {
    switch (variant) {
      case 'info':
        return (
          <NvErrorIcon fontSize="small" sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_main[40]} />
        );
      case 'warning':
        return (
          <NvWarningIcon
            fontSize="small"
            sx={{ width: '16px', height: '16px' }}
            htmlColor={theme.palette.nv_warning[40]}
          />
        );
      case 'error':
        return (
          <NvErrorIcon fontSize="small" sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_error[40]} />
        );
      default:
        return null;
    }
  };

  return (
    <SectionMessageContainer variant={variant} subtle={subtle} className={className}>
      <SectionMessageIconBox>{sectionMessageIcon(variant)}</SectionMessageIconBox>
      <SectionMessageTextBox>
        {typeof title === 'string' ? (
          <NvTypography variant="h5" textColor="secondary">
            {title}
          </NvTypography>
        ) : (
          title
        )}
        {typeof message === 'string' ? (
          <NvTypography variant="body2" textColor="secondary">
            {message}
          </NvTypography>
        ) : (
          message
        )}

        {actionButton && title && (
          <NvBox flex="0 0 auto" mt="8px">
            {actionButton}
          </NvBox>
        )}
      </SectionMessageTextBox>
      {actionButton && !title && <NvBox flex="0 0 auto">{actionButton}</NvBox>}
      {onClose && (
        <NvButton size="small" color="ghost" onlyIcon onClick={onClose} sx={{ flex: '0 0 auto' }}>
          <NvCloseIcon />
        </NvButton>
      )}
    </SectionMessageContainer>
  );
};
