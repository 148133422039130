import { Integration, IntegrationAction, StringValue, ValueTypes } from '@novaera/actioner-service';
import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFingerPrintIcon,
  NvFlex,
  NvMenuWithItems,
  NvSmartToyIcon,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import {
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { IntegrationBox } from '../../integration-trigger-property-panel-drawer/integration-trigger-properties/styled';

export const AssistantFilePanel = ({
  integration,
  integrationAction,
  onPanelClose,
  onDelete,
  actionTag,
}: {
  integration?: Integration;
  integrationAction?: IntegrationAction;
  onPanelClose: () => void;
  onDelete: (integration?: Integration, integrationAction?: IntegrationAction) => void;
  actionTag?: string;
}) => {
  const theme = useTheme();

  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const { hasEditPermission, isManagedApp } = useWorkflowPermission();

  const fieldNames = {
    fileKey: 'fileKey',
  } as const;

  return (
    <NvFlex width="100%" height="100%">
      <>
        <PropertyPanelHeader
          title="Transfer to"
          actions={
            <>
              {hasEditPermission && !isManagedApp && (
                <NvMenuWithItems
                  menuItems={[
                    {
                      name: 'Delete',
                      icon: (
                        <NvDeleteOutlineIcon
                          htmlColor={theme.palette.nv_error[40]}
                          sx={{ width: '16px', height: '16px' }}
                        />
                      ),
                      onClick: () => {
                        onDelete(integration, integrationAction);
                      },
                    },
                  ]}
                />
              )}
              <NvButton onlyIcon size="small" color="secondary" onClick={onPanelClose}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
        <PropertyPanelSimpleSection>
          <NvFlex gap="12px">
            <IntegrationBox>
              <NvSmartToyIcon htmlColor={theme.palette.nv_node.ai} />
              <NvTypography variant="h5" textColor="main" flex="1 1 auto" noWrap>
                Assistant
              </NvTypography>
            </IntegrationBox>
          </NvFlex>
        </PropertyPanelSimpleSection>

        <PropertyPanelSection title={'File key'} icon={<NvFingerPrintIcon />}>
          <NvFlex width="100%" gap="8px">
            <NvField<StringValue>
              name={fieldNames.fileKey}
              defaultValue={{
                type: ValueTypes.STRING,
              }}
              format={(value) => {
                return value?.value ?? '';
              }}
              parse={(value) => {
                return {
                  type: ValueTypes.STRING,
                  value,
                };
              }}
              component={<CodeInput context={workflowCodeInputContext} placeholder="{{ ... }}" />}
            />
          </NvFlex>
        </PropertyPanelSection>
      </>
    </NvFlex>
  );
};
