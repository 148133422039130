import { AppSchemasQueryOperation, AppSchemasQueryType, useFetchProfile } from '@novaera/actioner-service';
import { NvBox, NvDivider, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { useQueryParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { kebabCase } from 'lodash';
import { useEffect, useMemo } from 'react';
import { APP_DIRECTORY_MENU_ITEMS } from '../../constants';
import { AppDirectoryMenuItem, AppDirectoryMenuWrapper } from './styled';
import { AppDirectoryMenuItem as AppDirectoryMenuItemType, AppDirectoryMenuProps } from './types';

export const AppDirectoryMenu: React.FC<React.PropsWithChildren<AppDirectoryMenuProps>> = ({
  selectedItem,
  onSelect,
  onDeselect,
  categories,
  fullWidth,
  isLoading,
}) => {
  const { data: activeUser } = useFetchProfile();
  const { palette } = useTheme();
  const { getSearchParams, removeQueryParams, addQueryParam } = useQueryParams();
  const queryParams = getSearchParams<{ category: string }>();
  const handleItemClick = (item: AppDirectoryMenuItemType) => {
    if (item.value === selectedItem?.value) {
      removeQueryParams('category');
      onDeselect?.(item);
    } else {
      addQueryParam('category', kebabCase(item.label));
      onSelect?.(item);
    }
  };

  const appDirectoryItems: AppDirectoryMenuItemType[] = useMemo(() => {
    const categoryMenuItems: AppDirectoryMenuItemType[] = [];
    if (categories && categories?.length > 0) {
      categories.forEach((category) => {
        const result = {
          label: category,
          value: category,
          query: {
            operation: AppSchemasQueryOperation.CONTAINS,
            type: AppSchemasQueryType.CATEGORY,
            value: category,
          },
        };
        if (!categoryMenuItems.find((menuItem) => menuItem.label === result.label)) {
          categoryMenuItems.push(result);
        }
      });
    }
    return categoryMenuItems;
  }, [categories]);

  const allItems = useMemo(
    () => (!isLoading ? [...APP_DIRECTORY_MENU_ITEMS({ activeUser }), ...appDirectoryItems] : []),
    [activeUser, isLoading, appDirectoryItems]
  );

  useEffect(() => {
    // auto-select category if there is category info in queryParams and it also exists in categories.
    if (!selectedItem && !!queryParams?.category && allItems.length > 0) {
      const item = allItems.find(
        (item) => kebabCase(item.label) === queryParams.category && queryParams.category !== 'all'
      );

      if (item) {
        onSelect?.(item);
      } else {
        removeQueryParams(['category']);
      }
    }
  }, [allItems, onSelect, queryParams?.category, removeQueryParams, selectedItem]);

  return (
    <AppDirectoryMenuWrapper fullWidth={fullWidth}>
      {APP_DIRECTORY_MENU_ITEMS({ activeUser }).map((item, index) => (
        <AppDirectoryMenuItem
          key={`${item.value}-${index}`}
          isSelected={item.value === selectedItem?.value}
          onClick={() => handleItemClick(item)}
        >
          <NvFlex flexDirection="row" columnGap="8px" alignItems="center">
            <NvFlex color={item.value === selectedItem?.value ? 'inherit' : palette.nv_neutral_alpha[600]}>
              {item.icon}
            </NvFlex>
            <NvFlex>
              <NvTypography
                variant="h4"
                color={item.value === selectedItem?.value ? 'inherit' : palette.nv_neutral_alpha[600]}
              >
                {item.label}
              </NvTypography>
            </NvFlex>
          </NvFlex>
        </AppDirectoryMenuItem>
      ))}
      <NvDivider sx={{ marginTop: '8px', marginBottom: '8px' }} />
      {isLoading && (
        <NvBox>
          <NvSkeleton width="100%" height="32px" />
          <NvSkeleton width="60%" height="32px" />
          <NvSkeleton width="40%" height="32px" />
          <NvSkeleton width="30%" height="32px" />
          <NvSkeleton width="40%" height="32px" />
          <NvSkeleton width="100%" height="32px" />
          <NvSkeleton width="60%" height="32px" />
          <NvSkeleton width="40%" height="32px" />
          <NvSkeleton width="30%" height="32px" />
          <NvSkeleton width="40%" height="32px" />
        </NvBox>
      )}
      {!isLoading &&
        appDirectoryItems.map((item, index) => (
          <AppDirectoryMenuItem
            key={`${item.value}-${index}`}
            isSelected={item.value === selectedItem?.value}
            onClick={() => handleItemClick(item)}
          >
            <NvTypography variant="h5">{item.label}</NvTypography>
          </AppDirectoryMenuItem>
        ))}
    </AppDirectoryMenuWrapper>
  );
};
