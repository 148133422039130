import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const GroupOrPermissionPolicyCardWrapper = styled(NvFlex)`
  flex-direction: row;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  padding: 7px 8px;
  gap: 16px;
`;
