import { JsonViewerProps, NvBox, NvReactJson, NvTypography, SectionMessage } from '@novaera/core';
import { isObject } from 'lodash';
import { OutputWrapper } from './styled';

export const OutputItem: React.FC<
  React.PropsWithChildren<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any;
    jsonViewProps?: Omit<JsonViewerProps, 'src'>;
    isTestResultFailed?: boolean;
  }>
> = ({ content, jsonViewProps, isTestResultFailed }) => (
  <OutputWrapper>
    {isObject(content) ? (
      <NvReactJson src={content} collapsed={1} {...jsonViewProps} />
    ) : (
      <NvBox padding="16px">
        {content && <NvTypography variant="body1">{JSON.stringify(content)}</NvTypography>}
        {!content && isTestResultFailed && <SectionMessage message="Something went wrong" variant="error" />}
      </NvBox>
    )}
  </OutputWrapper>
);
