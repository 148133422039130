import { NvFlex, NvSkeleton } from '@novaera/core';
import { BodyItem } from '../body/item';

export const IntegrationConnectionBodyLoadingSkeleton = () => {
  return (
    <NvFlex gap="16px" paddingTop="16px">
      <BodyItem description={<NvSkeleton width={'200px'} />} title={<NvSkeleton width={'100px'} />}>
        <NvSkeleton height={'250px'} width="100%" />
      </BodyItem>
      <BodyItem description={<NvSkeleton width={'200px'} />} title={<NvSkeleton width={'100px'} />}>
        <NvSkeleton height={'100px'} width="100%" />
      </BodyItem>
    </NvFlex>
  );
};
