import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomFolderListIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2077_205)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 6H12L10.59 4.59C10.21 4.21 9.7 4 9.17 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM5 18C4.45 18 4 17.55 4 17V9C4 8.45 4.45 8 5 8H19C19.55 8 20 8.45 20 9V17C20 17.55 19.55 18 19 18H5ZM9 12C9 11.4477 9.44772 11 10 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H10C9.44772 13 9 12.5523 9 12ZM10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16H13C13.5523 16 14 15.5523 14 15C14 14.4477 13.5523 14 13 14H10ZM6 15C6 14.4477 6.44772 14 7 14C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15ZM7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2077_205">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
