import { NvFlex } from '@novaera/core';
import { FC } from 'react';

import { PropertyPanelSection } from '../../../../property-panel';
import { ValidationPropertiesProps } from '../types';
import { ConstraintMap } from './constraint-map';

export const ValidationProperties: FC<React.PropsWithChildren<ValidationPropertiesProps>> = ({
  fieldSchemaName,
  constraints,
  schemaType,
  title = 'Validations',
  disabled,
}) => {
  return constraints && constraints.length > 0 ? (
    <PropertyPanelSection title={title}>
      <NvFlex gap="16px" maxWidth="700px">
        <ConstraintMap
          fieldSchemaName={fieldSchemaName}
          constraints={constraints}
          schemaType={schemaType}
          disabled={disabled}
        />
      </NvFlex>
    </PropertyPanelSection>
  ) : null;
};
