import {
  NvBox,
  NvButton,
  NvField,
  NvFlex,
  NvForm,
  NvSkeleton,
  NvTextField,
  NvTypography,
  SectionMessage,
  isSubdomain,
} from '@novaera/core';
import React from 'react';
import { NvLogoUpload } from '../../components/logo-upload';
import { WorkspaceInitial } from '../../components/workspace-item/initial';
import { useWorkspaceInfo } from './controllers/use-workspace-info';

import { WorkspaceStatus } from '@novaera/actioner-service';
import { DisplayNameForm, SubdomainForm } from './types';
import { WorkspaceDeleteRequest } from './workspace-delete-request';
import { WorkspaceDeletionWarning } from './workspace-deletion-warning';

export const WorkspaceInfo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    onDisplayNameUpdate,
    workspaceInfo,
    isDisplayNameUpdating,
    isSubdomainNameUpdating,
    isGetWorkspaceInfoLoading,
    hasWorkspaceSubDomainError,
    onSubdomainUpdate,
    checkSubDomain,
    onIconChanged,
    isOrganizationLoading,
    organization,
  } = useWorkspaceInfo();

  if (isGetWorkspaceInfoLoading) {
    return (
      <NvFlex>
        <NvSkeleton height="40px" />
        <NvSkeleton height="40px" />
        <NvSkeleton height="40px" />
      </NvFlex>
    );
  }

  return (
    <NvFlex gap="24px">
      {workspaceInfo && <WorkspaceDeletionWarning workspace={workspaceInfo} />}
      {isOrganizationLoading && <NvSkeleton height="50px" width={'40%'} />}
      {organization && (
        <SectionMessage
          variant="info"
          message={
            <NvTypography>
              This workspace is part of an <b>{organization.name}.</b> All billing and pricing will be handled at the
              organization level.
            </NvTypography>
          }
        />
      )}
      <NvFlex gap="8px">
        <NvTypography variant="h4">Workspace icon</NvTypography>

        <NvLogoUpload
          imageSize="xlarge"
          ImageFallBack={
            <WorkspaceInitial
              size="xlarge"
              subdomain={workspaceInfo?.subdomain || ''}
              displayName={workspaceInfo?.displayName || ''}
            />
          }
          logoUploadButtonProps={{ text: 'Change icon' }}
          initialLogoUrl={workspaceInfo?.logoUrl}
          imageShape="square"
          type="workspace"
          hasRemoveAction
          onLogoStateChanged={onIconChanged}
        />
      </NvFlex>
      <NvForm<DisplayNameForm>
        onSubmit={onDisplayNameUpdate}
        initialValues={{
          displayName: workspaceInfo?.displayName || '',
        }}
      >
        <NvFlex direction="row" width="100%" alignItems="flex-start" gap="16px">
          <NvBox flex="1 1 auto">
            <NvField
              direction="label-on-top"
              labelText={<NvTypography variant="h4">Workspace name</NvTypography>}
              component={<NvTextField size="large" />}
              name="displayName"
            />
          </NvBox>
          <NvBox
            style={{
              marginTop: '34px',
              flex: '0 0 auto',
            }}
          >
            <NvButton type="submit" color="secondary" size="small" loading={isDisplayNameUpdating}>
              Update
            </NvButton>
          </NvBox>
        </NvFlex>
      </NvForm>
      <NvForm<SubdomainForm>
        onSubmit={onSubdomainUpdate}
        initialValues={{
          subdomain: workspaceInfo?.subdomain || '',
        }}
      >
        <NvFlex direction="row" width="100%" alignItems="flex-start" gap="16px">
          <NvBox flex="1 1 auto">
            <NvField
              direction="label-on-top"
              labelText={<NvTypography variant="h4">Workspace URL</NvTypography>}
              component={<NvTextField size="large" />}
              name="subdomain"
              showErrorMessageOnlyWhenBlur
              validators={[isSubdomain('Workspace URL cannot start or end with dashes.'), checkSubDomain]}
              hint="Enter a URL that only contains lowercase letters, numbers, and dashes. Workspace URL cannot start or end with dashes."
            />
          </NvBox>
          <NvBox
            style={{
              marginTop: '34px',
              flex: '0 0 auto',
            }}
          >
            <NvButton
              type="submit"
              color="secondary"
              size="small"
              loading={isSubdomainNameUpdating}
              disabled={hasWorkspaceSubDomainError}
            >
              Update
            </NvButton>
          </NvBox>
        </NvFlex>
      </NvForm>
      {workspaceInfo?.status === WorkspaceStatus.ACTIVE && <WorkspaceDeleteRequest />}
    </NvFlex>
  );
};
