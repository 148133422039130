import { FC } from 'react';
import { SAVE_RECURRING_JOB_SAMPLE_RESPONSE } from '../../../../../common/constants';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { JobID } from '../common-fields/job-id';
import { JobName } from '../common-fields/job-name';
import { JobNodeTargetTrigger } from '../common-fields/job-node-target/trigger';
import { JobTimezone } from '../common-fields/job-timezone';
import { JobSampleResponse } from '../common-fields/sample-response';
import { Ends } from './ends';
import { RecurrenceConfiguration } from './recurrence-configuration';
import { SaveRecurringJobFieldsProps } from './types';

export const SaveRecurringJobFields: FC<SaveRecurringJobFieldsProps> = ({
  isJobTargetPanelOpen,
  onJobTargetClicked,
}) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  return (
    <>
      <JobName context={workflowCodeInputContext} />
      <JobID context={workflowCodeInputContext} />
      <JobTimezone />
      <RecurrenceConfiguration context={workflowCodeInputContext} />
      <JobNodeTargetTrigger onClick={onJobTargetClicked} isSelected={isJobTargetPanelOpen} />
      <Ends context={workflowCodeInputContext} />
      <JobSampleResponse content={SAVE_RECURRING_JOB_SAMPLE_RESPONSE} />
    </>
  );
};
