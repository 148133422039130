import { NvAxios, useQuery } from '@novaera/core';
import { BILLING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_BILLING_PREFERENCES } from '../keys';
import { BillingPreferences } from './types';

const getBillingPreferences = async () => {
  const result = await NvAxios.get<BillingPreferences>(`${BILLING_ROOT_PATH}/preferences`);
  return result?.data;
};

export const useGetBillingPreferences = () => {
  return useQuery(QUERY_KEYS_BILLING_PREFERENCES.all, () => getBillingPreferences(), {});
};
