import classNames from 'classnames';
import { cloneElement, useState } from 'react';
import { NvButton } from '../button';
import NestedDropdownItem from './nested-dropdown-item';
import { NestedDropdown, NestedDropdownWrapper } from './styled';
import { NestedDropdownItem as NestedDropdownType, NestedDropdownProps } from './types';

export const NvNestedDropdown = function <T = unknown>({ menuItems, onClickItem, trigger }: NestedDropdownProps<T>) {
  const [anchorEl, setAnchorEl] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NestedDropdownWrapper className={classNames({ 'is-open-nested-dropdown': Boolean(anchorEl) })}>
      {trigger ? (
        cloneElement(trigger, {
          ...trigger.props,
          onClick: handleClick,
        })
      ) : (
        <NvButton onClick={handleClick}>Add Field</NvButton>
      )}
      <NestedDropdown
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ marginTop: '6px' }}
      >
        {menuItems.map((item: NestedDropdownType<T>) => {
          const { id, name, items, leftIcon, value } = item;
          return (
            <NestedDropdownItem
              key={id}
              id={id}
              name={name}
              items={items}
              value={value}
              onClick={(param) => {
                setAnchorEl(null);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClickItem(param as any);
              }}
              leftIcon={leftIcon}
            />
          );
        })}
      </NestedDropdown>
    </NestedDropdownWrapper>
  );
};
