import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FILES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_FILE_TRANSFER_TYPES } from '../keys';
import { AssistantTransferType, IntegrationTransferType } from './types';

type GetTransferTypesParams = {
  appId: string;
  queryParams?: {
    keyword?: string;
    next?: string;
  };
};

type GetTransferTypesResponse = {
  types: (AssistantTransferType | IntegrationTransferType)[];
};

const getTransferTypes = async ({ appId, queryParams }: GetTransferTypesParams) => {
  const result = await NvAxios.get<GetTransferTypesResponse>(`${FILES_ROOT_PATH(appId)}/transfer-types`, {
    params: {
      ...queryParams,
    },
  });
  return result?.data;
};

export const useGetTransferTypes = (params: GetTransferTypesParams) =>
  useInfiniteQuery<GetTransferTypesResponse>(
    QUERY_KEYS_FILE_TRANSFER_TYPES.search(params.appId, params.queryParams?.keyword),
    () => getTransferTypes(params),
    {
      enabled: !!params.appId,
    }
  );
