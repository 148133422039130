import { Integration, IntegrationType, useSearchInfiniteIntegrations } from '@novaera/actioner-service';
import { NodeListItem, NvBox, NvChip, NvNodeList, useDebounce } from '@novaera/core';
import { FC, useMemo, useState } from 'react';
import { LogoPlaceholder } from '../../../../../../components';
import { DropdownSimpleSearchInput } from '../../../../../../components/simple-search-input/styled';

export const IntegrationSelect: FC<
  React.PropsWithChildren<{ onSelectIntegration: (integration: Integration) => void }>
> = ({ onSelectIntegration }) => {
  const [keyword, setKeyword] = useState<string | undefined>('');
  const debouncedKeyword = useDebounce(keyword, 500);
  const { data: integrationsSearchResponse, isInitialLoading: isActionSearchLoading } = useSearchInfiniteIntegrations({
    ...(debouncedKeyword && { value: debouncedKeyword }),
  });

  const integrations = useMemo(() => {
    return integrationsSearchResponse?.pages[0]?.integrations.map((integration) => {
      const integrationRetVal: NodeListItem<Integration> = {
        icon: integration.logoUrl ?? (
          <LogoPlaceholder
            size="small"
            src="assets/integration-placeholder-image.png"
            initialText={integration?.name ?? 'Integration'}
          />
        ),
        name: integration.name,
        type: 'integrationWebhook',
        extraData: integration,
        ...(integration.scope.type === 'workspace' && { rightComponent: <NvChip label="Custom" compact /> }),
      };
      return integrationRetVal;
    });
  }, [integrationsSearchResponse?.pages]);

  return (
    <NvBox width="370px">
      <DropdownSimpleSearchInput
        autoFocus
        onKeywordChanged={(keyword) => {
          setKeyword(keyword);
        }}
      />
      <NvNodeList
        nodes={integrations?.filter((i) => i.type !== IntegrationType.JOB) ?? []}
        onItemClick={(e, item) => {
          onSelectIntegration(item.extraData as Integration);
        }}
        isLoading={isActionSearchLoading}
      />
    </NvBox>
  );
};
