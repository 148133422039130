import { useGetCatalogEntityType } from '@novaera/actioner-service';
import { useState } from 'react';
import { SelectedItemDetail, useCatalogContext } from '../../provider/use-catalog-provider';

export const useSelectedCatalogEntity = () => {
  const { selectedEntityTypeId } = useCatalogContext();

  const { data: entityType } = useGetCatalogEntityType({
    id: selectedEntityTypeId,
  });

  const [selectedDetailItem, setSelectedDetailItem] = useState<SelectedItemDetail | undefined>(() => {
    if (entityType?.parameters?.length) {
      return {
        selectedItemDetail: entityType.parameters[0],
        index: 0,
      };
    }

    return undefined;
  });

  return { selectedEntityType: entityType, selectedDetailItem, setSelectedDetailItem };
};
