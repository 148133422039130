import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const CollapseCard = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isOpen' })<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isOpen }) => (isOpen ? '16px 16px 24px 16px' : '16px')};
  gap: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  transform: scale(1);
  transition: gap 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
`;

export const CollapseTitle = styled(NvFlex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;
