import { NvAxios, useQuery } from '@novaera/core';
import { isUndefined } from 'lodash';
import { SLACK_USERS_ROOT_PATH } from '../../constants';
import { QUERY_KEYS_SLACK_USERS } from '../../keys';
import { GetSlackUsersParams, GetSlackUsersResponse } from './types';

const getSlackUsers = async ({ teamId, includeBotUsers = true }: GetSlackUsersParams) => {
  const result = await NvAxios.get<GetSlackUsersResponse>(SLACK_USERS_ROOT_PATH, {
    params: { teamId, includeBotUsers },
  });
  return result?.data;
};

export const useGetSlackUsers = ({ teamId, includeBotUsers = true }: GetSlackUsersParams) =>
  useQuery(QUERY_KEYS_SLACK_USERS.list(teamId), () => getSlackUsers({ teamId, includeBotUsers }), {
    enabled: !isUndefined(teamId),
  });
