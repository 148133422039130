import classNames from 'classnames';
import { FC } from 'react';
import { NvCollapse } from '../collapse';
import { NvSwitch } from '../switch';
import { NvTypography } from '../typography';
import { BodyWrapper, FieldsWrapper, HeaderWrapper, InfoWrapper, Wrapper } from './styled';
import { CollapsibleBySwitchProps } from './types';

export const CollapsibleBySwitch: FC<React.PropsWithChildren<CollapsibleBySwitchProps>> = ({
  title,
  fields,
  info,
  isOpen,
  onCollapseChanged,
  disabled,
}) => {
  return (
    <Wrapper>
      <HeaderWrapper className={classNames({ 'is-open': isOpen })}>
        <NvSwitch disabled={disabled} variant="compact" checked={isOpen} onChange={onCollapseChanged}></NvSwitch>
        <NvTypography variant="h5">{title}</NvTypography>
      </HeaderWrapper>
      <NvCollapse in={isOpen} sx={{ width: '100%' }} timeout={300} mountOnEnter>
        <BodyWrapper>
          {info && (
            <InfoWrapper>
              <NvTypography variant="body2">{info}</NvTypography>
            </InfoWrapper>
          )}

          <FieldsWrapper>{fields}</FieldsWrapper>
        </BodyWrapper>
      </NvCollapse>
    </Wrapper>
  );
};
