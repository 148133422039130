import { NvAxios, useMutation } from '@novaera/core';
import { SLACK_AUTH_ACCESS_PATH } from '../contants';
import { SlackCreateWorkspaceCompleteWorkspaceSelection, SlackJoinCompleteWorkspaceSelection } from './types';

const slackWorkspaceSelection = async (
  params: SlackJoinCompleteWorkspaceSelection | SlackCreateWorkspaceCompleteWorkspaceSelection
) => {
  const result = await NvAxios.post<{
    jwt: string;
    slackTeamId: string;
    workspaceId: string;
    subdomain: string;
    appId?: string;
  }>(`${SLACK_AUTH_ACCESS_PATH}/connections/anonymous/access/complete-workspace-selection`, params);
  return result.data;
};

export const useSlackCompleteWorkspaceSelection = () => useMutation(slackWorkspaceSelection);
