import { DataModel } from '@novaera/actioner-service';
import { NvFlex, NvForm, NvSkeleton } from '@novaera/core';
import arrayMutators from 'final-form-arrays';
import { TableDetail } from '..';
import OnChange from '../../../../../../action-designer/components/on-change';
import { useDataModelDetailForm } from './use-data-model-detail-form';

export const DataModelDetailForm = () => {
  const { dataModel, handleSubmit, handleOnChange, validate } = useDataModelDetailForm();
  return (
    <NvFlex flex="1 1 auto" minWidth="auto" height="100%">
      {dataModel ? (
        <NvForm<DataModel>
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          initialValues={dataModel}
          mutators={{
            ...arrayMutators,
          }}
          validate={validate}
        >
          {({ values, valid }) => (
            <>
              <OnChange
                name="fields"
                children={() => {
                  if (valid) {
                    handleOnChange({ values });
                  }
                }}
              ></OnChange>
              <TableDetail dataModel={values} />
            </>
          )}
        </NvForm>
      ) : (
        <NvFlex gap="24px" height="100%">
          <NvSkeleton variant="rectangular" width="100%" height="48px" />
          <NvFlex direction="row" gap="24px" flex="1 1 auto" padding="32px" minHeight="0">
            <NvFlex width="100%" gap="32px">
              <NvSkeleton variant="rectangular" width="70%" height="32px" />
              <NvSkeleton variant="rectangular" width="50%" height="24px" />
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
            </NvFlex>
            <NvSkeleton variant="rectangular" width="100%" height="100%" />
          </NvFlex>
        </NvFlex>
      )}
    </NvFlex>
  );
};
