import { SimpleSearchInput } from '@novaera/actioner-ui';
import {
  BasicTableCell,
  BasicTableRow,
  NvBasicTable,
  NvButton,
  NvEmptyState,
  NvFlex,
  NvSkeleton,
  NvTooltip,
  NvTypography,
  NvWarningAmberRounded,
} from '@novaera/core';
import { useUserPanel } from './controllers/use-user-panel';
import { UserPanelProps } from './types';

export const UserPanel: React.FC<React.PropsWithChildren<UserPanelProps>> = ({ productId }) => {
  const { users, isGettingUsers, impersonateUser, setSearchValue, impersonateLinks } = useUserPanel();
  return (
    <NvFlex gap="8px">
      <NvFlex maxWidth="780px">
        <SimpleSearchInput
          helperText="Search users in workspace with subdomain"
          initialKeyword=""
          onKeywordChanged={(keyword) => setSearchValue(keyword)}
        />
      </NvFlex>
      <NvFlex>
        {isGettingUsers ? (
          <NvBasicTable
            tableBody={
              <BasicTableRow>
                <BasicTableCell>
                  <NvSkeleton />
                </BasicTableCell>
              </BasicTableRow>
            }
          />
        ) : users ? (
          <NvBasicTable
            tableHeader={
              <BasicTableRow>
                <BasicTableCell>Name</BasicTableCell>
                <BasicTableCell>Email</BasicTableCell>
                <BasicTableCell>Role</BasicTableCell>
                <BasicTableCell>Invitation Status</BasicTableCell>

                <BasicTableCell>Impersonate link</BasicTableCell>
              </BasicTableRow>
            }
            tableBody={users.map((user, index) => (
              <BasicTableRow key={`${user.email}-${index}`}>
                <BasicTableCell>
                  <NvFlex direction="row" gap="8px">
                    <NvTypography>{user.name}</NvTypography>
                  </NvFlex>
                </BasicTableCell>
                <BasicTableCell>
                  <NvFlex direction="row" gap="8px">
                    <NvTypography>{user.email}</NvTypography>
                  </NvFlex>
                </BasicTableCell>
                <BasicTableCell>
                  <NvFlex direction="row" gap="8px">
                    <NvTypography>{user.roleId}</NvTypography>
                  </NvFlex>
                </BasicTableCell>
                <BasicTableCell>
                  <NvFlex direction="row" gap="8px">
                    <NvTypography>{user.invitationStatus}</NvTypography>
                  </NvFlex>
                </BasicTableCell>
                <BasicTableCell>
                  <NvFlex direction="row" gap="8px">
                    {user.invitationStatus !== 'accepted' && (
                      <NvTooltip title="This user is not accepted invitation, so you may not impersonate that user successfully">
                        <NvWarningAmberRounded />
                      </NvTooltip>
                    )}
                    <NvButton
                      color="secondary"
                      size="small"
                      onClick={() => {
                        impersonateUser({
                          userId: user.id,
                          workspaceId: user.workspaceId,
                          productId,
                        });
                      }}
                      sx={{
                        minWidth: 0,
                        flex: '0 0 auto',
                      }}
                      disabled={user.invitationStatus !== 'accepted'}
                    >
                      Impersonate user
                    </NvButton>
                  </NvFlex>
                </BasicTableCell>
                <BasicTableCell>
                  <NvFlex direction="row" gap="8px" textOverflow="ellipsis">
                    <NvTypography noWrap>{impersonateLinks?.[user.id]}</NvTypography>
                  </NvFlex>
                </BasicTableCell>
              </BasicTableRow>
            ))}
          ></NvBasicTable>
        ) : (
          <NvEmptyState primaryText="There is no user." />
        )}
      </NvFlex>
    </NvFlex>
  );
};
