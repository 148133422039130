import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomManagedAppsIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8001 3H17.2001L22.4001 12L17.2001 21H6.8001L1.6001 12L6.8001 3ZM13.57 13.57L12 17L10.43 13.57L7 12L10.43 10.43L12 7L13.57 10.43L17 12L13.57 13.57Z"
        fill="#currentColor"
      />
    </SvgIcon>
  );
}
