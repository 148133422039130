import {
  GetWorkflowDynamicInputParamsForMultipleInputParameters,
  InputParameters,
  useGetWorkflowDynamicInput,
  useResetWorkflowDynamicInput,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { DynamicInputMapArray, GetDynamicInputResponseFunction } from '../action-dynamic-input-provider/types';
import { DependencyInputComponentStateFN } from '../controller/use-check-dependency-value/types';
import { useCheckWorkflowDynamicInputDependencyValue } from '../controller/use-check-workflow-dynamic-input-dependency-value';
import { useGetDynamicInputParameterParent } from '../controller/use-get-dynamic-input-parameter-parent';
import { WorkflowDynamicInputContextType, WorkflowDynamicInputProviderProps } from './types';

const WorkflowDynamicInputContext = createContext<WorkflowDynamicInputContextType | undefined>(undefined);

export const WorkflowDynamicInputProvider: FC<WorkflowDynamicInputProviderProps> = ({
  children,
  appId,
  workflowId,
  draft,
  inputParameterIds,
  initialInputParameterIdsShowingOptions,
  inputParameterValues,
  allInputParameterIdsWithOrder,
  formId,
  storeDynamicFormParametersPermanently = false,
  inputParameters = [],
}) => {
  const { removeCacheForInputParameter } = useResetWorkflowDynamicInput();

  const { getDependencyInputComponentState, filterSuccessDynamicInputs, dependenciesOfParameters } =
    useCheckWorkflowDynamicInputDependencyValue({
      appId,
      workflowId,
      isDraft: draft,
      inputParameters,
    });

  const [inputParameterIdsShowingOptions, setInputParameterIdsShowingOptions] = useState<string[]>(
    initialInputParameterIdsShowingOptions
  );

  const inputOptionsContext: GetWorkflowDynamicInputParamsForMultipleInputParameters = useMemo(() => {
    return {
      requestParams: {
        appId,
        workflowId,
        payloads: inputParameterIds.map((inputParameterId) => ({
          draft,
          inputParameterId,
          context: {},
          inputParameters: inputParameterValues?.filter((inputParameterValue) =>
            dependenciesOfParameters?.[inputParameterId]?.includes(inputParameterValue.parameterId)
          ),
          formId,
          storeDynamicFormParametersPermanently,
        })),
      },
      options: { keepPreviousData: true, staleTime: Infinity },
      enabledInputParameters: inputParameterIdsShowingOptions.filter((inputParameterId) =>
        filterSuccessDynamicInputs(inputParameterId, inputParameterValues)
      ),
      allInputParameterIdsWithOrder,
    };
  }, [
    appId,
    workflowId,
    inputParameterIds,
    inputParameterIdsShowingOptions,
    filterSuccessDynamicInputs,
    allInputParameterIdsWithOrder,
    draft,
    inputParameterValues,
    formId,
    storeDynamicFormParametersPermanently,
    dependenciesOfParameters,
  ]);

  const results = useGetWorkflowDynamicInput(inputOptionsContext);

  const dynamicInputParameters = useMemo(() => {
    const allInputParameters: InputParameters = [];
    results.forEach((r) => r.data?.parameters?.forEach((p) => allInputParameters.push(p)));
    return allInputParameters;
  }, [results]);

  const dynamicInputMap: DynamicInputMapArray = useMemo(() => {
    return inputParameterIds.map((inputParameter, index) => {
      return { inputParameterId: inputParameter, ...results[index] };
    });
  }, [inputParameterIds, results]);

  const { getDynamicInputParameterParent } = useGetDynamicInputParameterParent({ dynamicInputMap });

  const invalidate = useCallback(
    (inputParameterId: string) => {
      removeCacheForInputParameter({
        appId,
        workflowId,
        draft,
        inputParameterId,
        formId,
      });
    },
    [appId, draft, removeCacheForInputParameter, workflowId, formId]
  );

  const getDynamicInputParameters = useCallback<GetDynamicInputResponseFunction>(
    (inputParameterId: string) => {
      const result = dynamicInputMap.find((o) => o.inputParameterId === inputParameterId);

      return {
        ...result?.data,
        isLoading: result?.isInitialLoading || result?.isRefetching,
      };
    },
    [dynamicInputMap]
  );

  const handleGetDynamicInputComponentState = useCallback<DependencyInputComponentStateFN>(
    ({ inputParameterId, inputParameterValues }) => {
      return getDependencyInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
    [getDependencyInputComponentState]
  );

  const isBusy = useMemo(() => results.some((result) => result.isFetching), [results]);

  const value: WorkflowDynamicInputContextType = {
    isBusy,
    setInputParameterIdsShowingOptions,
    getDynamicInputParameters,
    getDynamicInputParameterParent,
    dynamicInputParameters,
    invalidate,
    handleGetDynamicInputComponentState,
    getDynamicInputComponentState: (inputParameterId: string) => {
      return handleGetDynamicInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
  };
  return (
    <WorkflowDynamicInputContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </WorkflowDynamicInputContext.Provider>
  );
};

export const useWorkflowDynamicInputContext = () => {
  const context = useContext(WorkflowDynamicInputContext);
  assert(
    !!context,
    new Error(`useWorkflowDynamicInputContext should be used within WorkflowDynamicInputProvider`),
    'ERROR'
  );

  return context;
};
