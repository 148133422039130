import { styled } from '@novaera/theme-provider';
import React from 'react';
import { NvFlex } from '../../flex';
import { NvTypography } from '../../typography';

export const CellWrapper = styled(NvFlex)`
  min-height: 24px;
  padding: 6px 0;
  gap: 4px;
  width: 100%;
  word-break: break-word;
`;

export const CellTypography = styled((props: React.ComponentPropsWithRef<typeof NvTypography>) => (
  <NvTypography {...props} variant="body2" />
))`
  margin: 3px 0;
  width: 100%;
`;
