import { useExportIntegrationsTables, useGetAvailableTables, useImportTables } from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { useMemo } from 'react';

export const useIntegrationSyncPanel = () => {
  const { data } = useGetAvailableTables();
  const initialAppDirectoryTableNames = useMemo(() => data?.tables ?? [], [data?.tables]);
  const { mutate: importTables } = useImportTables();
  const { mutate: exportTables } = useExportIntegrationsTables();
  const { addToast } = useToast();
  const handleAppDirectorySync = (values: { tables: string[]; type?: 'import' | 'export' }) => {
    return new Promise<void>((resolve) => {
      values.type === 'import'
        ? importTables(
            { tables: values.tables },

            {
              onSuccess: () => {
                addToast('Integration tables imported successfully', { variant: 'success' });
              },
              onSettled: () => {
                resolve();
              },
            }
          )
        : exportTables(
            { tables: values.tables },

            {
              onSuccess: () => {
                addToast('Integration tables exported successfully', { variant: 'success' });
              },
              onSettled: () => {
                resolve();
              },
            }
          );
    });
  };

  return { initialAppDirectoryTableNames, availableTableNames: data?.tables ?? [], handleAppDirectorySync };
};
