import {
  InviteType,
  UserInvitationForm,
  UserRole,
  useGetSlackWorkspace,
  useInviteSlackUsers,
  useInviteUser,
} from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useEffect, useState } from 'react';

export const useNewMemberInvitation = ({
  isOpened,
  onModalClosed,
}: {
  isOpened: boolean;
  onModalClosed: () => void;
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { data: slackWorkspace, isLoading } = useGetSlackWorkspace();
  const { mutateAsync: inviteUsers } = useInviteUser();
  const { addToast } = useToast();

  useEffect(() => {
    if (isOpened) {
      setIsModalOpened(true);
    }
  }, [isOpened]);

  const handleModalClose = () => {
    setIsModalOpened(false);
  };

  const { mutate: inviteSlackUsers, isLoading: isInviting } = useInviteSlackUsers();

  const handleFormSubmit = async (values: UserInvitationForm) => {
    if (slackWorkspace && activeTab === 0) {
      const { invitedSlackUserIds } = values;
      return new Promise<void>((resolve) => {
        assert(
          !!invitedSlackUserIds,
          new Error('Invite user request need teamId and user id list to invite someone'),
          'ERROR'
        );
        inviteSlackUsers(
          {
            teamId: slackWorkspace.teamId,
            invitedSlackUserIds,
            inviteType: InviteType.some,
          },
          {
            onSuccess: () => {
              addToast('Selected users are invited successfully.', { variant: 'success' });
              setIsInviteLoading(false);
              setIsModalOpened(false);
              onModalClosed();
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      });
    } else {
      try {
        setIsInviteLoading(true);
        await Promise.all(values.users.map((user) => inviteUsers({ email: user.email, roleId: UserRole.USER })));
        setIsInviteLoading(false);
        setIsModalOpened(false);
        onModalClosed();
      } catch (error) {
        setIsInviteLoading(false);
      }
    }
  };

  return {
    isModalOpened,
    onModalClosed: handleModalClose,
    onFormSubmit: handleFormSubmit,
    isInviteLoading: isInviteLoading || isInviting,
    setActiveTab,
    slackWorkspace,
    isLoading,
  };
};
