import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkflowHistoryListItemCard = styled(NvBox)<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px 6px 12px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.nv_main[10] : theme.palette.nv_neutral[0]};
  color: ${({ theme, isSelected }) => (isSelected ? theme.palette.nv_main[60] : theme.palette.nv_neutral[1000])};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  min-height: 44px;
  cursor: pointer;
`;
