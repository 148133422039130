import { NvAutocomplete, NvTextField } from '@novaera/core';

import { CRON_DAY_OF_WEEK_OPTIONS } from '../../save-job-modal/configure-recurrence/constants';
import { CronDayOfWeekPickerProps } from './types';

export const CronDayOfWeekPicker: React.FC<CronDayOfWeekPickerProps> = ({ cron, onChange }) => {
  const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = cron.split(' ');
  const selectedDayOfWeek = CRON_DAY_OF_WEEK_OPTIONS.filter(({ value }) => dayOfWeek.split(',').includes(value));
  return (
    <NvAutocomplete
      value={selectedDayOfWeek}
      multiple
      options={CRON_DAY_OF_WEEK_OPTIONS}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
        const dow = value.map((v) => v.value).join(',');
        const cron = `${minutes} ${hours} ${dayOfMonth} ${month} ${dow || `?`} ${year}`;
        onChange(cron);
      }}
      renderInput={(params) => (
        <NvTextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          size="medium"
        />
      )}
    />
  );
};
