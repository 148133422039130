import { AccessType, useBatchGetGroups, useBatchGetUsers, useGetWorkflow } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { WorkflowPermissionGroupAndUser } from './types';

export const useWorkflowUsersWithPermissions = ({
  userAppId,
  workflowId,
}: {
  userAppId: string;
  workflowId: string;
}) => {
  const { savedWorkflow, isLoading } = useGetWorkflow({ appId: userAppId, workflowId });

  const { userIds, groupIds } = useMemo(() => {
    const userIds =
      savedWorkflow?.permissions
        ?.filter((permission) => permission.principal.type === AccessType.USER)
        ?.map((permission) => {
          assert(
            !!(permission.principal.type === AccessType.USER),
            new Error('[useWorkflowUsersWithPermissions] - principal id can not be undefined for users.'),
            'ERROR'
          );
          return permission.principal.id;
        }) ?? [];

    const groupIds =
      savedWorkflow?.permissions
        ?.filter((permission) => permission.principal.type === AccessType.GROUP)
        ?.map((permission) => {
          assert(
            !!(permission.principal.type === AccessType.GROUP),
            new Error('[useWorkflowUsersWithPermissions] - principal id can not be undefined for users.'),
            'ERROR'
          );
          return permission.principal.id;
        }) ?? [];
    return { userIds, groupIds };
  }, [savedWorkflow?.permissions]);

  const { data, isInitialLoading: isBatchGetLoading } = useBatchGetUsers(userIds);
  const { data: groupData, isInitialLoading: isBatchGetGroupsLoading } = useBatchGetGroups(groupIds);

  const workflowPermissionsUsers: WorkflowPermissionGroupAndUser[] = useMemo(
    () =>
      savedWorkflow?.permissions?.map((permission) => {
        const principal = permission.principal;
        if (principal.type === AccessType.USER) {
          const userDetail = data?.users.find(({ userId }) => userId === principal.id);
          return { ...permission, type: principal.type, ...(userDetail ? { userDetail } : {}) };
        } else {
          const groupDetail = groupData?.groups.find(({ id }) => id === principal.id);
          return { ...permission, type: principal.type, ...(groupDetail ? { groupDetail } : {}) };
        }
      }) ?? [],
    [savedWorkflow?.permissions, data?.users, groupData?.groups]
  );

  return {
    workflowPermissionsUsers,
    isLoading: isBatchGetLoading || isLoading || isBatchGetGroupsLoading,
    userDetails: data?.users,
  };
};
