import { JobNodeExtraData, JobNodeSummary, JobVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class JobComponentFactory implements ComponentFactory<JobNodeExtraData> {
  aliasForJob: string;
  nameForJob: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('job');
    this.aliasForJob = newAlias;
    this.nameForJob = newName;
  }

  get componentForGraph() {
    const outputRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForJob,
      width: 40,
      height: 40,
      id: this.aliasForJob,
      type: 'job',
      alias: this.aliasForJob,
    };

    return { root: outputRoot };
  }

  componentForWorkflow(serverData: JobNodeExtraData) {
    const newNodeUnion: JobVertex = {
      type: 'job',
      alias: this.aliasForJob,
    };

    const jobNamePrefix =
      serverData.operation.type === 'saveScheduled'
        ? 'Scheduled '
        : serverData.operation.type === 'saveRecurring'
        ? 'Recurring '
        : serverData.operation.type === 'delete'
        ? 'Delete '
        : '';

    this.nameForJob = `${jobNamePrefix}${this.nameForJob.toLowerCase()}`;
    const summary: JobNodeSummary = {
      type: 'job',
      alias: this.aliasForJob,
      name: this.nameForJob,
      ...serverData,
    };

    return { root: newNodeUnion, nodeSummaries: { [this.aliasForJob]: summary } };
  }
}
