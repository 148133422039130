import { RunStepType, USER_PERMISSION } from '@novaera/actioner-service';
import {
  NvButton,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvField,
  NvFlex,
  NvMenuWithItems,
  NvTextField,
  NvTypography,
  SectionMessage,
  useNvDialogModalState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { isUndefined } from 'lodash';
import React from 'react';
import { ConfigJsonInputWrapper } from '../../../../../user-app/user-app-detail/config/config-detail/config-detail-body/styled';
import { UserPermissionBoundary } from '../../../../../user-permission-boundary';
import { DetailTitle } from '../../../request-tab/requests/detail/components/title';
import { useResponseResult } from '../../../request-tab/requests/detail/response-result/controllers/use-response-result';
import {
  EmptyStateWrapper,
  ErrorStateWrapper,
  HeaderWrapper,
  HeaderWrapperLeftBox,
  HeaderWrapperRightBox,
} from '../../../request-tab/requests/detail/styled';
import { useFunctionDetail } from './controller/use-function-detail';
import { FunctionCodeInput } from './function-code-input';

export const FunctionDetail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { functionStep, functionStepIndex, onHandleDeleteClick } = useFunctionDetail();
  const { handleRequestTest, executionResponse, responseError, responseContent, isLoading } = useResponseResult(
    RunStepType.FUNCTION
  );

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const theme = useTheme();

  return (
    <>
      <NvFlex width={1} alignItems="center" key={functionStep?.id} height={'100%'}>
        <HeaderWrapper>
          <HeaderWrapperLeftBox>
            <DetailTitle name={functionStep?.name} type="Function" />
          </HeaderWrapperLeftBox>
          <HeaderWrapperRightBox>
            <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
              <NvMenuWithItems
                menuItems={[
                  {
                    name: 'Delete',
                    onClick: () => {
                      onHandleDeleteClick(functionStep?.id);
                    },
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                  },
                ]}
              ></NvMenuWithItems>
            </UserPermissionBoundary>
          </HeaderWrapperRightBox>
        </HeaderWrapper>
        {!isUndefined(functionStepIndex) && (
          <NvFlex width="100%" gap="24px" flex={'1 1 auto'} minHeight={0} padding={'16px 16px 32px 16px'}>
            <NvFlex direction="row" gap="8px" alignItems="flex-start" flex="0 0 auto">
              <NvFlex flex={'1 1 auto'} minWidth={0} flexDirection={'row'} gap={'24px'}>
                <NvField
                  labelText="Function name"
                  labelVariant="h5"
                  direction="label-on-top"
                  name={`functions[${functionStepIndex}].name`}
                  formControlStyle={{ minWidth: '300px' }}
                  component={<NvTextField />}
                />
                <NvButton
                  color="success"
                  onClick={() => handleRequestTest()}
                  disabled={isLoading}
                  loading={isLoading}
                  sx={{ minWidth: '100px', marginTop: '24px' }}
                >
                  Run
                </NvButton>
              </NvFlex>
              <NvButton color="secondary" onClick={onModalOpenClicked} sx={{ minWidth: '100px', marginTop: '24px' }}>
                Show response
              </NvButton>
            </NvFlex>

            <NvFlex gap="6px" width={'100%'} flex="1 1 0" minHeight={0}>
              <ConfigJsonInputWrapper>
                <NvField
                  formControlStyle={{
                    height: '100%',
                    width: '100%',
                  }}
                  name={`functions[${functionStepIndex}].script`}
                  component={<FunctionCodeInput />}
                />
              </ConfigJsonInputWrapper>
            </NvFlex>
          </NvFlex>
        )}
      </NvFlex>
      <NvDialogModal
        open={isOpened}
        onCloseButtonClicked={onModalCloseClicked}
        Header="Response"
        maxWidth="md"
        modalIcon="receipt"
        fullWidth
        Body={
          <>
            {!responseError && responseContent && responseContent}
            {!responseError && !responseContent && (
              <EmptyStateWrapper sx={{ margin: 0 }}>
                <NvTypography textColor="subtle" variant="h5">
                  Run the request to see response
                </NvTypography>
              </EmptyStateWrapper>
            )}
            {responseError && (
              <ErrorStateWrapper gap="12px" sx={{ padding: 0 }}>
                <SectionMessage variant="error" title={responseError.title} message={responseError.reason} />
              </ErrorStateWrapper>
            )}
          </>
        }
        Footer={
          <NvButton color="secondary" onClick={onModalCloseClicked}>
            Close
          </NvButton>
        }
      />
    </>
  );
};
