import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { assert } from '@novaera/utils';
import React from 'react';
import { NvConditionalWrap } from '../conditional-wrap';
import { NvDateRangeIcon, NvExpandMoreIcon } from '../icons';
import { NvTextField } from '../textfield';
import { useDatePicker } from './controller';
import { DatePickerWrapper, DefaultPickerDays } from './styled';
import { NvDatePickerProps } from './types';

export const NvDatePicker: React.FC<React.PropsWithChildren<NvDatePickerProps>> = ({
  value,
  onChange,
  renderInput,
  fullWidth,
  ...props
}) => {
  assert(onChange != null, new Error('onChange should be defined or Wrap your component within NvField'), 'ERROR');

  const { isOpen, changeDatePickerOpen } = useDatePicker();

  return (
    <NvConditionalWrap condition={!fullWidth} wrap={(children) => <DatePickerWrapper>{children}</DatePickerWrapper>}>
      <DatePicker
        value={value}
        onChange={(parameter) => {
          changeDatePickerOpen(false);
          onChange(parameter);
        }}
        onClose={() => {
          changeDatePickerOpen(false);
        }}
        open={isOpen}
        renderInput={
          renderInput ??
          ((params) => (
            <NvTextField
              fullWidth={fullWidth}
              onClick={() => changeDatePickerOpen(!isOpen)}
              {...params}
              error={props.error}
            />
          ))
        }
        InputAdornmentProps={{ position: 'start' }}
        renderDay={(_day, _selectedDays, props) => <DefaultPickerDays {...props} />}
        components={{
          OpenPickerIcon: NvDateRangeIcon,
          SwitchViewIcon: NvExpandMoreIcon,
        }}
        {...props}
      />
    </NvConditionalWrap>
  );
};
