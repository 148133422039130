import { useMutation, NvAxios } from '@novaera/core';
import { ImpersonateUserParams, ImpersonateUserResponse } from './types';

const impersonateUser = async (params: ImpersonateUserParams) => {
  const result = await NvAxios.post<ImpersonateUserResponse>(`/identity/v1/impersonate`, params);
  return result?.data;
};

export const useImpersonateUser = () => {
  return useMutation(impersonateUser);
};
