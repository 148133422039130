import { NvAxios, useQuery } from '@novaera/core';
import { BILLING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_FREE_APPS } from '../keys';

type GetFreeAppsResponse = {
  'free-app-schema-ids': string[];
};

const getFreeApps = async () => {
  const result = await NvAxios.get<GetFreeAppsResponse>(`${BILLING_ROOT_PATH}/free-apps`);
  return result?.data;
};

export const useGetFreeApps = () => {
  return useQuery(QUERY_KEYS_FREE_APPS.all, () => getFreeApps(), {});
};
