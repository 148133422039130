import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CronPreviewCard = styled(NvBox)`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
  border-radius: 6px;
`;
