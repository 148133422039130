import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_INTEGRATIONS_PATH } from '../constant';
import { PublishIntegrationParams } from '../types';

const publishIntegration = async ({ integrationId, payload }: PublishIntegrationParams) => {
  const result = await NvAxios.post(`${ADMIN_INTEGRATIONS_PATH}/${integrationId}/publish`, payload);
  return result?.data;
};

export const usePublishIntegration = () => {
  return useMutation(publishIntegration);
};
