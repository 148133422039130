import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP_SETUP } from '../keys';
import { GetUserAppSetupStatusResponse } from '../use-get-user-app-setup-status/types';
import { UpdateUserAppUserPreferencesParams } from './types';

const updateUserAppUserPreferences = async ({ appId, setupDismissed }: UpdateUserAppUserPreferencesParams) => {
  const result = await NvAxios.patch(`${USER_APP_ROOT_PATH}/${appId}/user-preferences`, {
    setupDismissed,
  });
  return result.data;
};

export const useUpdateUserAppUserPreferences = () => {
  const cache = useQueryClient();
  return useMutation(updateUserAppUserPreferences, {
    onSuccess: (_, { appId }) => {
      cache.setQueryData<GetUserAppSetupStatusResponse>(QUERY_KEYS_USER_APP_SETUP.status({ appId }), (old) => {
        return old
          ? {
              ...old,
              dismissed: true,
            }
          : undefined;
      });
    },
  });
};
