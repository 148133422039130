import { HttpLink } from '@apollo/client';
import { LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { assert } from '@novaera/utils';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import Cookies from 'js-cookie';

export const subscriptionHandshakeLink = ({ apiURI, region }: { apiURI: string; region: string }) =>
  createSubscriptionHandshakeLink(
    {
      url: apiURI,
      region: region,
      auth: {
        type: 'OPENID_CONNECT',
        jwtToken: async () => {
          const accessKey = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);
          assert(!!accessKey, new Error('Access key cannot be retrieved while subscription process.'), 'ERROR');
          return accessKey;
        },
      },
    },
    new HttpLink({ uri: apiURI })
  );
