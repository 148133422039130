import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPolicyResponse } from '../use-get-permission-policy/types';
import { DeletePermissionFromPolicyParams } from './types';

const deletePermissionFromPolicy = async ({ policyId, id }: DeletePermissionFromPolicyParams) => {
  await NvAxios.delete(`${PERMISSION_POLICIES_ROOT_PATH}/policies/${policyId}/permissions/${id}`);
  return;
};

export const useDeletePermissionFromPolicy = () => {
  const cache = useQueryClient();

  return useMutation(deletePermissionFromPolicy, {
    onSuccess: (_, { policyId, id }) => {
      cache.setQueryData<GetPermissionPolicyResponse>(QUERY_KEYS_PERMISSION_POLICIES.detail({ policyId }), (old) => {
        if (old) {
          return { ...old, permissions: old.permissions.filter((p) => p.definition.id !== id) };
        }
        return old;
      });
    },
  });
};
