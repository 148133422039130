import { NvChevronRightIcon, NvCollapse, NvTypography, SectionMessage } from '@novaera/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ResponseSummary } from '../../../../../../components/response-summary';
import {
  CollapseInnerWrapper,
  EmptyStateWrapper,
  ErrorStateWrapper,
  HeaderWrapperLeftBox,
  HeaderWrapperLeftBoxIcon,
  HeaderWrapperRightBox,
  MiddleHeaderWrapper,
} from '../styled';
import { ResponseResultProps } from './types';

export const ResponseResult: React.FC<React.PropsWithChildren<ResponseResultProps>> = ({
  responseContent,
  responseError,
  executionResponse,
}) => {
  const [isResponseOpen, setIsResponseOpen] = useState<boolean>(true);

  return (
    <>
      {
        <MiddleHeaderWrapper className={classNames({ 'is-open': isResponseOpen })}>
          <HeaderWrapperLeftBox>
            <HeaderWrapperLeftBoxIcon
              onClick={() => {
                setIsResponseOpen(!isResponseOpen);
              }}
            >
              <NvChevronRightIcon className="chevron-icon" />
            </HeaderWrapperLeftBoxIcon>
            <NvTypography
              variant="h2"
              onClick={() => {
                setIsResponseOpen(!isResponseOpen);
              }}
              sx={{ cursor: 'pointer' }}
            >
              Response
            </NvTypography>
          </HeaderWrapperLeftBox>
          {!responseError && responseContent && executionResponse && (
            <HeaderWrapperRightBox>
              <ResponseSummary
                response={{
                  contentLength: 0,
                  durationInMillis: (executionResponse?.runStepExecution.durationInMillis as number) ?? -1,
                  statusCode: `${executionResponse?.runStepExecution.statusCode}` ?? '',
                }}
              />
            </HeaderWrapperRightBox>
          )}
        </MiddleHeaderWrapper>
      }
      {!responseError && responseContent && (
        <NvCollapse in={isResponseOpen} sx={{ width: '100%' }}>
          <CollapseInnerWrapper>{responseContent} </CollapseInnerWrapper>
        </NvCollapse>
      )}
      {!responseError && !responseContent && (
        <NvCollapse in={isResponseOpen} sx={{ width: '100%' }}>
          <EmptyStateWrapper>
            <NvTypography textColor="subtle" variant="h5">
              Run the request to see response
            </NvTypography>
          </EmptyStateWrapper>
        </NvCollapse>
      )}
      {responseError && (
        <NvCollapse in={isResponseOpen} sx={{ width: '100%' }}>
          <ErrorStateWrapper gap="12px" margin="32px 0">
            <SectionMessage variant="error" title={responseError.title} message={responseError.reason} />
          </ErrorStateWrapper>
        </NvCollapse>
      )}
    </>
  );
};
