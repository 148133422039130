import { NvCustomHelpIcon, NvFlex, NvTooltip, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React, { isValidElement } from 'react';
import { PropertyPanelListHeaderCard, PropertyPanelListHeaderCardActions } from './styled';
import { PropertyPanelListHeaderProps } from './types';

export const PropertyPanelListHeader: React.FC<React.PropsWithChildren<PropertyPanelListHeaderProps>> = ({
  title,
  tooltip,
  actions,
  className,
}) => {
  const theme = useTheme();

  return (
    <PropertyPanelListHeaderCard className={className}>
      <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
        <NvTypography variant="h7" noWrap>
          {title}
        </NvTypography>
        {tooltip &&
          (isValidElement(tooltip) ? (
            tooltip
          ) : (
            <NvTooltip title={tooltip}>
              <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
            </NvTooltip>
          ))}
      </NvFlex>
      {actions && <PropertyPanelListHeaderCardActions>{actions}</PropertyPanelListHeaderCardActions>}
    </PropertyPanelListHeaderCard>
  );
};
