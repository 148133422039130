import { NvAxios, useMutation } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS } from '../keys';
import { QUERY_KEYS_NODE } from '../node/keys';
import { UseGetWorkflowResponse } from '../use-get-workflow/types';
import { RollbackWorkflowParams, RollbackWorkflowResponse } from './types';

const rollbackWorkflow = async ({ appId, id }: RollbackWorkflowParams) => {
  const result = await NvAxios.delete<RollbackWorkflowResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${id}/drafts`
  );

  return result.data;
};

export const useRollbackWorkflow = () => {
  const cache = useQueryClient();

  return useMutation(rollbackWorkflow, {
    onSuccess: (workflow, { appId, id }) => {
      assert(!!workflow, new Error('Rollback workflow should return a saved workflow'), 'ERROR');
      cache.setQueryData<UseGetWorkflowResponse>(QUERY_KEYS_WORKFLOWS.detail({ appId: appId, workflowId: id }), {
        saved: workflow,
      });

      cache.removeQueries(QUERY_KEYS_NODE.all);
    },
  });
};
