import {
  NeutralChipColor,
  NvArrowBackIcon,
  NvBox,
  NvButton,
  NvChip,
  NvConditionalRender,
  NvDivider,
  NvEditIcon,
  NvErrorIcon,
  NvFlex,
  NvMenuWithItems,
  NvRouterLink,
  NvSkeleton,
  NvSwitchButton,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React, { ReactElement, isValidElement, useState } from 'react';
import { HeaderWrapper } from './styled';
import { HeaderProps } from './types';

export const Header = <SwitchModeTypes,>({
  name,
  icon,
  onBack,
  isDraft,
  mode,
  modes,
  onModeSwitch,
  onEditNameClick,
  actions,
  error,
  isLoading = false,
  hasChange,
  buttonActions,
  enableStateSlot,
  showInformationLabel = true,
  sectionNextToTitle,
}: HeaderProps<SwitchModeTypes>) => {
  const theme = useTheme();
  const [switchButtonValue, setSwitchButtonValue] = useState<SwitchModeTypes | undefined>(mode);

  return (
    <HeaderWrapper>
      <NvFlex direction="row" alignItems="center" justifyContent="flex-start" gap="8px" flex="1 1 auto" minWidth={0}>
        {onBack && (
          <>
            {typeof onBack === 'string' ? (
              <NvRouterLink to={onBack}>
                <NvButton
                  startIcon={<NvArrowBackIcon />}
                  size="small"
                  color="ghost"
                  variant="contained"
                  sx={{ flex: '0 0 auto' }}
                >
                  Back
                </NvButton>
              </NvRouterLink>
            ) : (
              <NvButton
                startIcon={<NvArrowBackIcon />}
                size="small"
                color="ghost"
                variant="contained"
                onClick={onBack}
                sx={{ flex: '0 0 auto' }}
              >
                Back
              </NvButton>
            )}

            <NvBox height="12px" flex="0 0 auto">
              <NvDivider borderColor={theme.palette.nv_neutral[40]} orientation="vertical" />
            </NvBox>
          </>
        )}

        <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
          {isValidElement(icon)
            ? React.cloneElement<{
                sx: { width: string; height: string; flex: string };
              }>(icon as ReactElement, { sx: { width: '20px', height: '20px', flex: '0 0 auto' } })
            : null}
          <NvConditionalRender when={() => !isLoading}>
            <NvTypography variant="h4" noWrap maxWidth={400}>
              {name}
            </NvTypography>
            {isDraft && <NvChip label="Draft" compact color={NeutralChipColor.DARK_ALPHA} />}
            {onEditNameClick && (
              <NvButton
                onlyIcon
                size="small"
                color="ghost"
                variant="contained"
                onClick={onEditNameClick}
                sx={{ marginLeft: '4px' }}
              >
                <NvEditIcon sx={{ width: '16px', height: '16px' }} />
              </NvButton>
            )}
            {sectionNextToTitle}
          </NvConditionalRender>
          <NvConditionalRender when={() => isLoading}>
            <NvSkeleton width={'150px'} />
          </NvConditionalRender>
        </NvFlex>
      </NvFlex>
      {modes && (
        <NvBox flex="0 0 auto" id="designer-run-history-switcher">
          <NvSwitchButton<SwitchModeTypes>
            value={switchButtonValue}
            onChange={(value) => {
              setSwitchButtonValue(value);
              onModeSwitch?.(value);
            }}
            options={modes}
          />
        </NvBox>
      )}

      <NvFlex direction="row" alignItems="center" gap="12px" flex="1 1 auto" minWidth={0}>
        {error ? (
          <NvFlex direction="row" alignItems="center" justifyContent="flex-end" gap="6px" flex="1 1 auto" minWidth={0}>
            <NvErrorIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
            <NvTypography variant="body2" textColor="secondary">
              {error}
            </NvTypography>
          </NvFlex>
        ) : (
          <NvFlex direction="row" alignItems="center" justifyContent="flex-end" gap="8px" flex="1 1 auto" minWidth={0}>
            {!isDraft && !hasChange ? (
              showInformationLabel ? (
                <NvTypography variant="body3" textColor="subtle" id="you-do-not-have-any-changes-text" noWrap>
                  You don’t have any changes
                </NvTypography>
              ) : (
                ''
              )
            ) : (
              buttonActions
            )}
          </NvFlex>
        )}

        {showInformationLabel && actions && (
          <NvBox height="12px" flex="0 0 auto">
            <NvDivider borderColor={theme.palette.nv_neutral[40]} orientation="vertical" />
          </NvBox>
        )}
        {enableStateSlot}
        {((Array.isArray(actions) && actions.length > 0) || (!Array.isArray(actions) && actions)) && (
          <NvBox flex="0 0 auto">
            <NvMenuWithItems menuItems={actions} />
          </NvBox>
        )}
      </NvFlex>
    </HeaderWrapper>
  );
};
