import {
  BooleanValue,
  FieldSchema,
  InputParameterValues,
  ListValue,
  NumberValue,
  ObjectValue,
  ParameterTypes,
  SimpleParameterMapping,
  SingularValue,
  StringValue,
  ValueTypes,
} from '@novaera/actioner-service';
import { Option, SchemaType } from '@novaera/ah-common';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { listValueSchemaToValue } from '../../../utils/list-value-schema-to-value';

export const useMultiOptionFieldAdapter = ({
  extractValue = false,
  hidden,
}: {
  extractValue?: boolean;
  hidden: boolean;
}) => {
  const formatValue = useCallback(
    ({ options }: { options?: Option[] }) =>
      (value: InputParameterValues | null) => {
        if (!value) {
          return [];
        }
        assert(
          value.type === ValueTypes.LIST,
          new Error(`type is: ${value?.type}, but multi select value should be a list`),
          'ERROR'
        );

        const inputValue = value.value;

        const inputMapped =
          inputValue?.map((input) => {
            const foundOption = options?.find((option) => option.identifier === input.value);
            const option: Option = {
              displayValue: foundOption?.displayValue
                ? foundOption.displayValue
                : input.displayValue ?? (input.value as string),
              identifier: input.value,
            };
            return option;
          }) || [];

        return inputMapped.filter((result) => !!result.displayValue);
      },
    []
  );

  const parseValue = useCallback(
    ({ schema }: { schema?: FieldSchema }) =>
      (valueToParse: Option[] | null) => {
        if (valueToParse) {
          assert(
            !!schema && schema.type === SchemaType.array,
            new Error('Schema should have a value and its type should be array in multi select adaptor parse'),
            'ERROR'
          );

          const valueType = listValueSchemaToValue(schema.valueSchema);

          assert(
            Array.isArray(valueToParse),
            new Error('Multi select should have a value which is an array.'),
            'ERROR'
          );

          const resultValue = valueToParse.map((v) => {
            if (valueType === ValueTypes.OBJECT) {
              if (typeof v.identifier === 'object') {
                const retVal: ObjectValue = {
                  type: valueType,
                  value: v.identifier,
                  displayValue: v.displayValue,
                };
                return retVal;
              } else if (typeof v.identifier === 'string') {
                try {
                  const objectVal = JSON.parse(v.identifier);
                  const retVal: ObjectValue = {
                    type: valueType,
                    value: objectVal,
                    displayValue: v.displayValue,
                  };
                  return retVal;
                } catch (error) {
                  return undefined;
                }
              }
              assert(false, new Error('Input type error.'), 'ERROR');
              return undefined;
            } else if (valueType === ValueTypes.BOOLEAN) {
              const retVal: BooleanValue = {
                type: valueType,
                value: Boolean(v.identifier),
                displayValue: v.displayValue,
              };
              return retVal;
            } else if (valueType === ValueTypes.NUMBER) {
              if (typeof v.identifier === 'number') {
                const retVal: NumberValue = {
                  type: valueType,
                  value: v.identifier,
                  displayValue: v.displayValue,
                };
                return retVal;
              } else if (
                typeof v.identifier === 'string' &&
                v.identifier.trim().length > 0 &&
                !isNaN(Number(v.identifier))
              ) {
                const retVal: NumberValue = {
                  type: valueType,
                  value: Number(v.identifier),
                  displayValue: v.displayValue,
                };
                return retVal;
              }
              assert(false, new Error('Provided input should be a number.'), 'ERROR');
              return undefined;
            } else if (valueType === ValueTypes.STRING) {
              if (typeof v.identifier === 'string') {
                const retVal: StringValue = {
                  type: valueType,
                  value: v.identifier,
                  displayValue: v.displayValue,
                };
                return retVal;
              }
              return undefined;
            }
            assert(
              false,
              new Error(`Multi select's singular value only can be number, string, boolean or object`),
              'ERROR'
            );
          });

          const result: ListValue = {
            type: ValueTypes.LIST,
            value: resultValue.filter((r): r is SingularValue => !isUndefined(r)),
          };

          return result;
        } else {
          return undefined;
        }
      },
    []
  );

  const format = useCallback(
    ({ options }: { options?: Option[] }) =>
      (value: SimpleParameterMapping | null) => {
        if (!value?.value) {
          return [];
        }
        return formatValue({ options })(value.value);
      },
    [formatValue]
  );

  const parse = useCallback(
    ({ schema, id }: { schema?: FieldSchema; id: string }) =>
      (valueToParse: Option[] | null): SimpleParameterMapping<ListValue> | undefined => {
        const result = parseValue({ schema })(valueToParse);
        if (result || hidden) {
          return { parameterId: id, type: ParameterTypes.SIMPLE, hidden, ...(result && { value: result }) };
        } else {
          return undefined;
        }
      },
    [hidden, parseValue]
  );

  return { format: extractValue ? formatValue : format, parse: extractValue ? parseValue : parse };
};
