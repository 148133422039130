import { ListSchema } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { NvTagsAutoComplete } from '@novaera/core';
import { FC, SyntheticEvent } from 'react';
import { stringSchema } from '../../../../../../../../components/data-model-layout/constants';
import { SelectItemFromModel } from '../select-item-form-model';

export const ListSchemaInput: FC<
  React.PropsWithChildren<{
    appId?: string;
    schema?: ListSchema;
    value?: string;
    onChange?: (event: SyntheticEvent<Element, Event>, value: string | string[] | null) => void;
  }>
> = ({ schema, value, onChange, appId }) => {
  const { valueSchema } = schema ?? { valueSchema: stringSchema };

  return valueSchema.type === SchemaType.recordReference ? (
    <SelectItemFromModel
      isMultiple
      value={value}
      onChange={onChange}
      appId={appId}
      recordReferenceSchema={valueSchema}
    />
  ) : (
    <NvTagsAutoComplete options={[]} value={value} onChange={onChange} placeholder="Add" />
  );
};
