import { useTheme } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvFlex } from '../flex';
import { NvHelpIcon } from '../icons';
import { NvTypography } from '../typography';
import { StepFieldProps } from './types';

export const StepField: React.FC<React.PropsWithChildren<StepFieldProps>> = ({ children, help }) => {
  const { palette } = useTheme();
  return (
    <NvFlex direction="row" gap="16px" alignItems="flex-start">
      <NvBox flex="1 1 auto">{children}</NvBox>

      {help && (
        <NvFlex direction="row" gap="6px" width="320px" flex="0 0 auto" minWidth="0">
          <NvHelpIcon htmlColor={palette.nv_accent[50]} sx={{ width: '16px', height: '16px' }} />

          <NvFlex gap="4px">
            <NvTypography variant="h5">{help.title}</NvTypography>
            {typeof help.description === 'string' ? (
              <NvTypography variant="body2" textColor="secondary">
                {help.description}
              </NvTypography>
            ) : (
              help.description
            )}
          </NvFlex>
        </NvFlex>
      )}
    </NvFlex>
  );
};
