import { USER_PERMISSION, useGetIntegration, useGetIntegrationActions } from '@novaera/actioner-service';
import { NvActionFilledIcon, NvAddBoxIcon, NvButton, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { useParams } from '@novaera/route';
import { FC, useMemo, useState } from 'react';
import { BaseItem } from '../../../components/base-item';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { SimpleSearchInput } from '../../../components/simple-search-input';
import { UserPermissionBoundary } from '../../../user-permission-boundary';
import { INTEGRATION_ACTION_DETAIL } from '../../constants';
import { useActionMenus } from '../action-detail/use-action-menus';

export const Actions: FC<React.PropsWithChildren<{ onCreateActionClick: () => void }>> = ({ onCreateActionClick }) => {
  const { integrationId } = useParams();
  const { data: integrationData, isInitialLoading: isIntegrationLoading } = useGetIntegration({ id: integrationId });

  const { data, isLoading } = useGetIntegrationActions({
    integrationId,
    version: integrationData?.latestVersion.number,
    includeAll: true,
  });

  const [keyword, setKeyword] = useState<string | undefined>();

  const filteredActions = useMemo(() => {
    const searchKeyword = keyword?.toLowerCase() ?? '';
    return (
      data?.actions.filter(
        (a) => a.name.toLowerCase().includes(searchKeyword) || a.description?.toLowerCase().includes(searchKeyword)
      ) ?? []
    );
  }, [data?.actions, keyword]);

  const { getActionMenus } = useActionMenus();

  return isLoading ? (
    <NvFlex gap="16px">
      <NvFlex direction="row" alignItems="center" justifyContent="space-between" gap="8px">
        <NvFlex direction="row" alignItems="center" gap="8px">
          <NvSkeleton variant="circular" width="40px" height="40px" />
          <NvSkeleton variant="rectangular" width="220px" height="24px" />
        </NvFlex>
        <NvSkeleton variant="rectangular" width="90px" height="24px" />
      </NvFlex>
      <NvFlex direction="row" alignItems="center" justifyContent="space-between" gap="8px">
        <NvSkeleton variant="rectangular" width="220px" height="24px" />
        <NvSkeleton variant="rectangular" width="125px" height="24px" />
      </NvFlex>
      <NvFlex gap="8px">
        <NvSkeleton variant="rectangular" height="68px" />
        <NvSkeleton variant="rectangular" height="68px" />
        <NvSkeleton variant="rectangular" height="68px" />
      </NvFlex>
    </NvFlex>
  ) : data?.actions && data?.actions.length > 0 ? (
    <NvFlex gap="24px">
      <NvFlex flexDirection="row" alignItems="center" gap="8px">
        <NvActionFilledIcon fontSize="large" />
        <NvTypography variant="h1">Actions</NvTypography>
      </NvFlex>
      <NvFlex flexDirection="row" justifyContent="space-between" alignItems="center" gap="24px">
        <SimpleSearchInput onKeywordChanged={(keyword) => setKeyword(keyword)} />
        <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
          <NvButton color="ghost" size="small" onClick={onCreateActionClick} startIcon={<NvAddBoxIcon />}>
            Add action
          </NvButton>
        </UserPermissionBoundary>
      </NvFlex>
      {!isIntegrationLoading && integrationData && (
        <NvFlex gap="12px">
          {filteredActions.map((action) => (
            <BaseItem
              key={`action_key_${action.id}`}
              name={action.name}
              menuItems={getActionMenus(integrationId, action.id, integrationData?.latestVersion.number)}
              to={INTEGRATION_ACTION_DETAIL(integrationId, action.id)}
              description={action.description}
              tags={action.tags}
            />
          ))}
        </NvFlex>
      )}
    </NvFlex>
  ) : (
    <EmptyState
      variant={EmptyStates.ACTIONS}
      CustomButton={
        <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
          <NvButton color="secondary" onClick={onCreateActionClick} startIcon={<NvAddBoxIcon />} size="small">
            Add action
          </NvButton>
        </UserPermissionBoundary>
      }
    />
  );
};
