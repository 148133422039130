import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { UpdateUserAppAccessSettingsParams, UpdateUserAppAccessSettingsResponse } from './types';

const updateUserAppAccessSettings = async ({ appId, accessSettings }: UpdateUserAppAccessSettingsParams) => {
  const result = await NvAxios.patch<UpdateUserAppAccessSettingsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/access-settings/batch-update`,
    {
      accessSettings,
    }
  );
  return result?.data;
};

export const useUpdateUserAppAccessSettings = () => {
  const cache = useQueryClient();
  return useMutation(updateUserAppAccessSettings, {
    onSuccess: (updatedPackage) => {
      cache.setQueryData(QUERY_KEYS_USER_APP.detail(updatedPackage.id), updatedPackage);
    },
  });
};
