import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SlackWorkspaceSectionCard = styled(NvBox)`
  display: flex;
  height: 32px;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
`;
