import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { NvFlex } from '../../flex';
import { NvTooltip } from '../../tooltip';
import { NvTypography } from '../../typography';
import { DefaultDisplayValueProps } from './types';

export const DefaultDisplayValue: FC<DefaultDisplayValueProps> = ({ tooltipTitle, text }) => {
  const theme = useTheme();
  return (
    <NvTooltip title={tooltipTitle} variant="small" placement="top-start">
      <NvFlex bgcolor={theme.palette.nv_neutral[20]} borderRadius="4px" marginLeft="6px" padding="1px 4px">
        <NvTypography variant="body3" alignSelf="flex-start" textColor="subtle">
          <b>{text}</b>
        </NvTypography>
      </NvFlex>
    </NvTooltip>
  );
};
