import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const EmailAddressCardContainer = styled(NvBox)`
  display: flex;
  flex-direction: row;
  padding: 8px 4px 8px 8px;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;
