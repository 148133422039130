export enum RunStepType {
  FUNCTION = 'function',
  REQUEST = 'request',
}

export type RunStep = {
  type: RunStepType;
  id: string;
};

export type RunConfiguration = {
  label: string;
  runSteps: RunStep[];
};
