import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint';

import { injectGlobal } from '@emotion/css';
import 'codemirror/lib/codemirror.css';

import React from 'react';
import './autocomplete';
import { CodeInputForExpression } from './code-input-for-expression';
import { CodeInputForJavascript } from './code-input-for-javascript';
import { CodeInputForSql } from './code-input-for-sql';
import './mode';
import { CodeInputProps } from './types';
import { shouldRenderCodeInput } from './utils';

injectGlobal(`
.monaco-editor .monaco-hover {
  border: none;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 10px 20px 0px rgba(10, 45, 98, 0.08), 0px 0px 0px 1px rgba(10, 45, 98, 0.04);
  z-index: 1600 !important;
}

.monaco-editor .suggest-details, .monaco-editor .suggest-widget {
  border: none;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 10px 20px 0px rgba(10, 45, 98, 0.08), 0px 0px 0px 1px rgba(10, 45, 98, 0.04);
  z-index: 1600 !important;
}
`);

const UnMemoizedCodeInput: React.FC<React.PropsWithChildren<CodeInputProps>> = ({
  mode = 'expression',
  context,
  ...props
}) => {
  return mode === 'expression' ? (
    <CodeInputForExpression context={context} {...props} />
  ) : mode === 'free-js' || mode === 'json' ? (
    <CodeInputForJavascript context={context} {...props} mode={mode} />
  ) : (
    <CodeInputForSql context={context} {...props} />
  );
};
UnMemoizedCodeInput.displayName = 'CodeInputProxyComponent';

UnMemoizedCodeInput.displayName = 'Code input';

export const CodeInput = React.memo(UnMemoizedCodeInput, (prevProps, nextProps) =>
  shouldRenderCodeInput(prevProps, nextProps)
);
CodeInput.displayName = 'MemoizedCodeInput';
