export type NoCondition = {
  type: 'no-condition';
};

export enum Operation {
  EQUALS = 'equals',
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  IS_ONE_OF = 'is_one_of',
  GREATER_THAN = 'greater_than',
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  LESS_THAN = 'less_than',
  LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to',
  EXISTS = 'exists',
  IS_EMPTY = 'is_empty',
  CONTAINS = 'contains',
}

export enum SingleValueRuleType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export type SingleValueRule = {
  value: string;
  type: SingleValueRuleType;
};

export enum DynamicValueRuleType {
  DYNAMIC = 'dynamic',
}

export enum MultiValueRuleType {
  LIST = 'list',
}

export type MultiValueRule = {
  values: string[];
  type: MultiValueRuleType;
};

export type DynamicValueRule = {
  type: DynamicValueRuleType;
  valueExpression: string;
};

export type Rule = {
  keyExpression: string;
  operation: Operation;
  negateResult: boolean;
  value?: SingleValueRule | MultiValueRule | DynamicValueRule;
};

export type MatchAllCondition = {
  type: 'match-all-condition';
  rules: Rule[];
};

export type MatchAnyCondition = {
  type: 'match-any-condition';
  rules: Rule[];
};

export type JavascriptCondition = {
  type: 'javascript';
  function: string;
};

export type MatcherCondition = MatchAllCondition | MatchAnyCondition;

export type ConditionType = NoCondition | MatcherCondition | JavascriptCondition;
export type ConditionConfiguration = {
  enabled: boolean;
  condition: ConditionType;
};
