import {
  WorkflowSelectComponentMultipleAppSupportProps,
  WorkflowSelectComponentProps,
  WorkflowSelectDependencyValue,
  WorkflowSelectMultipleAppValue,
} from './types';

export const isWorkflowSelectMultipleAppValue = (
  value?: string | WorkflowSelectMultipleAppValue
): value is WorkflowSelectMultipleAppValue =>
  value !== undefined && typeof value !== 'string' && ('dependencyId' in value || 'appId' in value);

export const isWorkflowSelectComponentMultipleAppSupportProps = (
  props: WorkflowSelectComponentProps
): props is WorkflowSelectComponentMultipleAppSupportProps => Boolean(props.hasMultipleAppSupport);

export const isWorkflowSelectDependedValue = (
  value?: WorkflowSelectMultipleAppValue
): value is WorkflowSelectDependencyValue => value !== undefined && value.type === 'dependency';
