import { HTTPNode, HTTPNodeSummary, HTTPVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class HTTPComponentFactory implements ComponentFactory<HTTPNode> {
  alias: string;
  name: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('http');
    this.alias = newAlias;
    this.name = newName;
  }

  get componentForGraph() {
    const httpRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.name,
      width: 40,
      height: 40,
      id: this.alias,
      type: 'http',
      alias: this.alias,
    };

    return { root: httpRoot };
  }

  componentForWorkflow(serverData: HTTPNode) {
    const newNodeUnion: HTTPVertex = {
      type: 'http',
      alias: this.alias,
    };

    const summary: HTTPNodeSummary = {
      type: 'http',
      alias: this.alias,
      name: this.name,
    };

    return { root: newNodeUnion, nodeSummaries: { [this.alias]: summary } };
  }
}
