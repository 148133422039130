import { JS_DECLARATIONS } from './constants';

const stringify = function (obj: object) {
  return JSON.stringify(obj, function (key, value) {
    return typeof value === 'function' ? '' : value;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertObjectToTypeDefinition = ({ context }: { context: any }) => {
  let retVal = '';
  for (const [key, value] of Object.entries(context)) {
    if (JS_DECLARATIONS[key]) {
      retVal = `${retVal}
      ${JS_DECLARATIONS[key]}`;
    } else if (Array.isArray(value)) {
      retVal = `${retVal}const ${key}:${typeof value}[];
        `;
    } else if (value !== null && value !== undefined && typeof value === 'object') {
      const innerResult = stringify(value);
      retVal = `${retVal} const ${key} = ${innerResult};
        `;
    } else {
      retVal = `${retVal}const ${key}:string;
        `;
    }
  }

  return retVal;
};
