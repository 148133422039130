import { NvCustomCatalogIcon, NvFlex, NvSkeleton } from '@novaera/core';
import { Header } from '../../components';
import { DataModelLayout } from '../../components/data-model-layout';
import { InPageSideMenuLoadingSkeleton } from '../../components/in-page-side-menu/loading-skeleton';

export const CatalogLoadingSkeleton = () => {
  return (
    <NvFlex flex="1 1 auto" minWidth="auto" height="100%">
      <NvFlex flexDirection={'row'} height={'100%'}>
        <NvFlex width={'279px'} flex={'0 0 auto'} zIndex={'11'}>
          <InPageSideMenuLoadingSkeleton icon={NvCustomCatalogIcon} title={'Catalog'} />
        </NvFlex>
        <NvFlex flex={'1 1 auto'} minWidth={'0'} height={'100%'}>
          <DataModelLayout
            hasSwitchedMode={true}
            Component={
              <NvFlex width={'100%'} padding={'24px'} gap={'16px'}>
                <NvSkeleton variant="rectangular" height="32px" width={'100%'} />
                <NvSkeleton variant="rectangular" height="32px" width={'100%'} />
                <NvSkeleton variant="rectangular" height="32px" width={'100%'} />
              </NvFlex>
            }
            HeaderComponent={
              <Header
                isLoading={true}
                name={''}
                icon={undefined}
                actions={<NvSkeleton variant="rectangular" height="24px" width={'100%'} />}
              />
            }
          />
        </NvFlex>
      </NvFlex>
    </NvFlex>
  );
};
