import { BG_COLORS_OF_INITIAL_COMPONENT } from '@novaera/constants';
import { Initial } from '@novaera/core';
import React from 'react';
import { useAppContext } from '../../../app-context';

export const WorkspaceInitialOriginal: React.FC<
  React.PropsWithChildren<{
    subdomain: string;
    displayName: string;
    size?: 'small' | 'medium' | 'large' | 'xlarge';
  }>
> = ({ subdomain, displayName, size }) => {
  const {
    state: { userInitialBgColorKeyMap },
  } = useAppContext();

  return (
    <Initial
      size={size}
      color={userInitialBgColorKeyMap[subdomain] ?? BG_COLORS_OF_INITIAL_COMPONENT[4]}
      value={displayName}
    />
  );
};

export const WorkspaceInitial = React.memo(WorkspaceInitialOriginal);
