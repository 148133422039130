import { NvAxios, useMutation } from '@novaera/core';
import { DOCUMENTS_ROOT_PATH } from '../constants';
import { UpdateDocContentParams } from './types';

const updateDocumentContent: (params: UpdateDocContentParams) => Promise<void> = async ({ appId, docId, payload }) => {
  await NvAxios.put<void>(`${DOCUMENTS_ROOT_PATH(appId)}/${docId}/content`, {
    ...payload,
  });
};

export const useUpdateDocumentContent = () => {
  return useMutation(updateDocumentContent);
};
