import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';

export const StyledRuleList = styled(NvFlex)`
  width: 100%;
  gap: 8px;

  &.vertical-layout {
    flex-direction: column;
  }
  &.horizontal-layout {
    flex-direction: row;
  }
`;

export const StyledOperator = styled(NvFlex)`
  flex-direction: row;
  gap: 8px;

  &.vertical-layout {
    width: 100%;
  }
  &.horizontal-layout {
    width: 180px;
  }
`;

export const StyledValue = styled(NvFlex, { shouldForwardProp: shouldForwardPropForFormFields })<{
  fieldLength: number;
}>`
  &.vertical-layout {
    width: 100%;
  }

  &.horizontal-layout {
    width: ${({ fieldLength }) => (fieldLength > 1 ? 'calc( 50% - 114px )' : 'calc( 50% - 98px )')};
  }
`;

export const StyledExpression = styled(NvFlex, { shouldForwardProp: shouldForwardPropForFormFields })<{
  fieldLength: number;
}>`
  &.vertical-layout {
    width: 100%;
  }

  &.horizontal-layout {
    width: ${({ fieldLength }) => (fieldLength > 1 ? 'calc( 50% - 114px )' : 'calc( 50% - 98px )')};
  }
`;

export const FillingComponent = styled(NvBox)`
  border: 2px dashed ${({ theme }) => theme.palette.nv_neutral[40]};
  flex: 1 1 auto;
  border-radius: 6px;
  height: 32px;
`;
