import { CreateIntegrationParams, useCreateIntegration } from '@novaera/actioner-service';
import { useNavigate } from '@novaera/route';
import { INTEGRATION_SUMMARY } from '../../constants';

export const useCreateIntegrationModal = (onClose: () => void) => {
  const { mutate: createIntegration } = useCreateIntegration();
  const navigate = useNavigate();

  const handleOnSubmit = async (values: CreateIntegrationParams) => {
    return new Promise<void>((resolve) => {
      createIntegration(values, {
        onSuccess: ({ id }) => {
          navigate(INTEGRATION_SUMMARY(id));
          onClose();
        },
        onSettled: () => {
          resolve();
        },
      });
    });
  };

  return {
    handleOnSubmit,
  };
};
