import { QUERY_KEY_GET_SLACK_APP } from '../keys';

import { NvAxios, useQuery } from '@novaera/core';
import { noop } from '@novaera/utils';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { GetSlackAppResponse } from './types';

const getSlackApp = async (appId?: string) => {
  const result = await NvAxios.get<GetSlackAppResponse>(SLACK_APP_ROOT_PATH(appId));
  return result?.data;
};

export const useGetSlackApp = ({ appId, enabled = true }: { appId?: string; enabled?: boolean }) =>
  useQuery(QUERY_KEY_GET_SLACK_APP.detail(appId), () => getSlackApp(appId), {
    enabled: !!appId && enabled,
    retry: false,
    onError: noop,
  });
