import { FC } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { VideoEmbedProps } from './types';

export const VideoEmbed: FC<React.PropsWithChildren<VideoEmbedProps>> = ({
  width = '700',
  height = '393',
  src,
  title = 'Youtube video player',
  frameBorder = '0',
  allowFullScreen = true,
}) => {
  const iframeResult = (
    <iframe
      width={width}
      height={height}
      src={src}
      title={title}
      frameBorder={frameBorder}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={allowFullScreen}
    ></iframe>
  );
  return src?.startsWith?.('https://www.youtube.com/') ||
    src?.startsWith?.('https://www.youtube-nocookie.com/') ||
    src?.startsWith?.('https://www.loom.com/') ? (
    iframeResult
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{renderToStaticMarkup(iframeResult)}</>
  );
};
