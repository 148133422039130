import { NvFlex, NvSkeleton } from '@novaera/core';

export const AppDescriptionLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <NvFlex direction="row" alignItems="center" justifyContent="space-between" gap="16px">
      <NvFlex direction="row" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="40px" height="40px" />
        <NvSkeleton variant="rectangular" width="210px" height="24px" />
      </NvFlex>
      <NvSkeleton variant="rectangular" width="86px" height="20px" />
    </NvFlex>
    <NvFlex direction="row" gap="32px" alignItems="flex-start" marginTop="12px">
      <NvFlex gap="24px" width="100%">
        <NvFlex gap="12px">
          <NvSkeleton variant="rectangular" height="16px" width="70%" />
          <NvSkeleton variant="rectangular" height="16px" width="90%" />
          <NvSkeleton variant="rectangular" height="16px" width="80%" />
          <NvSkeleton variant="rectangular" height="16px" width="60%" />
        </NvFlex>
        <NvFlex gap="12px">
          <NvSkeleton variant="rectangular" height="16px" width="70%" />
          <NvSkeleton variant="rectangular" height="16px" width="90%" />
          <NvSkeleton variant="rectangular" height="16px" width="80%" />
          <NvSkeleton variant="rectangular" height="16px" width="60%" />
        </NvFlex>
        <NvFlex gap="12px">
          <NvSkeleton variant="rectangular" height="16px" width="50%" />
          <NvSkeleton variant="rectangular" height="16px" width="65%" />
        </NvFlex>
      </NvFlex>
      <NvFlex alignItems="flex-start" flex="0 0 auto" gap="12px" position="sticky" top="0" width={'280px'}></NvFlex>
    </NvFlex>
  </>
);
