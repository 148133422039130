import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_ACTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { DeleteIntegrationActionParams, GetIntegrationActionsResponse } from '../types';

const deleteIntegrationAction = async (params: DeleteIntegrationActionParams) => {
  const { integrationId, actionId } = params;
  const result = await NvAxios.delete(`${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}`);
  return result?.data;
};

export const useDeleteIntegrationAction = () => {
  const cache = useQueryClient();

  return useMutation(deleteIntegrationAction, {
    onSuccess(data, variables) {
      cache.setQueryData(
        QUERY_KEYS_INTEGRATION_ACTIONS.list(variables.integrationId, variables.version),
        (old?: GetIntegrationActionsResponse) => {
          if (old) {
            return { ...old, actions: old.actions.filter((a) => a.id !== variables.actionId) };
          }
          return old;
        }
      );
    },
  });
};
