import { NvDivider, NvFlex, NvTypography, useFormState } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { getIn } from 'final-form';
import React, { ReactElement, isValidElement, useMemo } from 'react';

import { BaseFieldType } from '@novaera/actioner-service';
import { DataModelFieldSchemaTitle } from '../../right-content/header/data-model-field-schema-title';
import { FieldLogoWrapper } from '../../styled';
import { findLogoFromFields } from '../../utils';
import { BaseListItem } from './base-list-item';
import { DataModelLayoutLeftContentItemProps } from './types';

export const DataModelLayoutLeftContentItem = <FieldTypeType extends BaseFieldType>({
  field,
  onClick,
  selected = false,
  fieldName,
  RightContent,
  item,
}: DataModelLayoutLeftContentItemProps<FieldTypeType>) => {
  const logo = useMemo(() => findLogoFromFields(field.schema.type), [field.schema.type]);

  const { palette } = useTheme();
  const { errors } = useFormState();
  const hasError = errors ? getIn(errors, fieldName) : undefined;

  return (
    <BaseListItem<FieldTypeType>
      isSelected={selected}
      header={
        <NvFlex direction="row" gap="12px" alignItems="center" width="100%">
          <NvTypography variant="h3" {...(selected && { color: palette.nv_main[60] })}>
            {field.name}
          </NvTypography>
          <NvDivider
            orientation="vertical"
            sx={{
              height: '12px',
            }}
          />
          <FieldLogoWrapper>
            {isValidElement(logo) &&
              React.cloneElement(logo as ReactElement, {
                sx: { height: '12px', width: '12px', color: palette.nv_neutral[600] },
              })}
            <DataModelFieldSchemaTitle variant="h5" schema={field.schema} textColor="secondary" />
          </FieldLogoWrapper>
        </NvFlex>
      }
      onClick={onClick}
      hasError={hasError}
      RightContent={RightContent}
      item={item}
    />
  );
};
