import { NvAxios, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';

const cancelWorkspaceDeletionRequest = async () => {
  const result = await NvAxios.delete(`v1/workspaces/deletion`);
  return result?.data;
};

export const useCancelWorkspaceDeletionRequest = () => {
  const { addToast } = useToast();
  const client = useQueryClient();

  return useMutation(cancelWorkspaceDeletionRequest, {
    onSuccess: () => {
      client.invalidateQueries();
      addToast('Workspace delete request cancelled', { variant: 'success' });
    },
  });
};
