import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { SlackBlocksProvider } from '../../../../../../../components/ui-components/slack-blocks-component/provider';
import { usePropertyPanelContext } from '../../provider';
import { ACTION_NODE_PROPERTIES_WRAPPER_ID } from '../action-node-property-panel-drawer/constants';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { OutputPropertyPanel } from './panel';
import { OutputPropertyPanelDrawerProps } from './types';

export const OutputPropertyPanelDrawer: React.FC<React.PropsWithChildren<OutputPropertyPanelDrawerProps>> = ({
  handleClose,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[OutputPropertyPanelDrawerProps] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const { userAppId: appId, workflowId } = useParams();
  const { node: outputNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.output>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });
  const [secondPanelOpen, setSecondPanelOpen] = useState(false);

  const {
    panelProps: [first, second],
    panelCloseFunctions: [, onSecondClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: isFirstPanelOpen && secondPanelOpen,
        closeFn: () => {
          setSecondPanelOpen(false);
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps} id={ACTION_NODE_PROPERTIES_WRAPPER_ID}>
      <SlackBlocksProvider panelProps={second} panelCloseFn={onSecondClose} setPanelOpen={setSecondPanelOpen}>
        <PPDrawerItem {...first}>
          {isLoading ? (
            <PropertiesLoading />
          ) : (
            outputNode && <OutputPropertyPanel onCloseClicked={handleClose} outputNode={outputNode} />
          )}
        </PPDrawerItem>
      </SlackBlocksProvider>
    </PropertyPaneWrapper>
  );
};
