import { useFetchWorkspaceList } from '@novaera/actioner-service';
import { LAST_ACTIVE_WORKSPACE } from '@novaera/constants';
import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { checkWorkspaceLength } from '../../../../common/utils';
import { redirectToWorkspace } from '../../../sign-in/workspace-panel/list/utils';

export const useWorkspaceRedirection = () => {
  const { data } = useFetchWorkspaceList(false);

  const shouldRedirectToWorkspace = useCallback(
    () => checkWorkspaceLength(data?.workspaces.length ?? 0) || Cookies.get(LAST_ACTIVE_WORKSPACE),
    [data?.workspaces.length]
  );

  useEffect(() => {
    const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);

    if (shouldRedirectToWorkspace() && data) {
      redirectToWorkspace(data?.workspaces[0].subdomain);
    } else if (shouldRedirectToWorkspace() && lastActiveWorkspace) {
      redirectToWorkspace(lastActiveWorkspace);
    }
  }, [data, data?.workspaces, shouldRedirectToWorkspace]);
};
