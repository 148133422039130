import { useGetDataModels } from '@novaera/actioner-service';
import { NvBox, NvCustomTableIcon, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useEffect, useMemo } from 'react';
import { SideMenuItemFrameMain } from '../../../../../../components/side-menu/styled';
import { USER_APP_RECORDS_DETAIL } from '../../../../constants';

export const RecordsMenuList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { userAppId: appId, modelId } = useParams();
  const { data, isLoading: isModelsLoading } = useGetDataModels({ appId });
  const models = useMemo(() => data?.models ?? [], [data?.models]);

  useEffect(() => {
    if (!modelId && models.length > 0) {
      navigate(USER_APP_RECORDS_DETAIL(appId, models[0].id), { replace: true });
    }
  }, [appId, modelId, models, navigate]);

  return (
    <NvFlex gap="8px">
      {isModelsLoading ? (
        <>
          <NvSkeleton variant="rectangular" height="40px" />
          <NvSkeleton variant="rectangular" height="40px" />
          <NvSkeleton variant="rectangular" height="40px" />
        </>
      ) : (
        models.map((model) => (
          <SideMenuItemFrameMain
            key={model.id}
            to={USER_APP_RECORDS_DETAIL(appId, model.id)}
            selected={modelId === model.id}
          >
            <NvBox display="flex" alignItems="center" gap="8px">
              <NvCustomTableIcon />
              <NvTypography variant="h4">{model.name}</NvTypography>
            </NvBox>
          </SideMenuItemFrameMain>
        ))
      )}
    </NvFlex>
  );
};
