import { APP_PERMISSION, AppDocument, AppDocumentCategory, useUpdateUserAppDocuments } from '@novaera/actioner-service';
import { NvAddBoxIcon, NvButton, NvEditIcon, NvFlex, NvForm, NvLink, NvTypography } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';
import { useCallback, useMemo } from 'react';
import { AppDocuments } from '../../../../../app-directory/app-directory-list/publish-your-app/app-details-step/app-documents';
import { UserAppPermissionBoundary } from '../../../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../../../user-app-permission-boundary/free-app-permission-boundary';
import { UseCasesAndLearnMoreCard, UseCasesAndLearnMoreDivider } from './styled';
import { UpdateUseCasesAndLearnMoreFormValues, UseCasesAndLearnMoreProps } from './types';

export const UseCasesAndLearnMore: React.FC<React.PropsWithChildren<UseCasesAndLearnMoreProps>> = ({
  documents,
  onEditModeChange,
  isEditMode,
}) => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { mutate: updateAppDocuments } = useUpdateUserAppDocuments();

  const useCases = useMemo(
    () => documents?.filter((d) => d.category === AppDocumentCategory.USE_CASE) ?? [],
    [documents]
  );
  const learnMoreAndSupports = useMemo(
    () => documents?.filter((d) => d.category === AppDocumentCategory.SUPPORT) ?? [],
    [documents]
  );
  const hasDocuments = useMemo(
    () => !(useCases.length === 0 && learnMoreAndSupports.length === 0),
    [learnMoreAndSupports.length, useCases.length]
  );

  const initialFormValues = useMemo(
    () => ({ useCases: useCases, support: learnMoreAndSupports }),
    [learnMoreAndSupports, useCases]
  );

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ useCases = [], support = [] }: UpdateUseCasesAndLearnMoreFormValues, form: any) => {
      return new Promise<void>((resolve) => {
        updateAppDocuments(
          { appId: userAppId, documents: [...useCases, ...support] },
          {
            onError: () => {
              form.reset();
              resolve();
            },
            onSettled: () => {
              resolve();
              onEditModeChange(false);
            },
          }
        );
      });
    },
    [onEditModeChange, updateAppDocuments, userAppId]
  );

  const readOnlyUseCasesAndLearnMore = useCallback(({ support }: { support: AppDocument[] }) => {
    return (
      <UseCasesAndLearnMoreCard isEditMode={false} disabled>
        {useCases.length > 0 && (
          <>
            <NvFlex direction="row" alignItems="center">
              <NvTypography variant="h3" flex="1 1 auto" minWidth="0">
                Use cases
              </NvTypography>
            </NvFlex>
            <NvFlex gap="8px" alignItems="flex-start">
              {useCases.map((document, index) => (
                <NvLink
                  key={`use-cases-and-learn-more-${document.category}-${index}`}
                  href={document.url}
                  target="_blank"
                >
                  {document.displayText}
                </NvLink>
              ))}
            </NvFlex>
          </>
        )}
        {useCases.length > 0 && support.length > 0 && (
          <UseCasesAndLearnMoreDivider borderColor={theme.palette.nv_neutral_alpha[30]} />
        )}

        {support.length > 0 && (
          <>
            <NvFlex direction="row" alignItems="center">
              <NvTypography variant="h3" flex="1 1 auto" minWidth="0">
                Learn more & Support
              </NvTypography>
            </NvFlex>
            <NvFlex gap="8px" alignItems="flex-start">
              {support.map((document, index) => (
                <NvLink
                  key={`use-cases-and-learn-more-${document.category}-${index}`}
                  href={document.url}
                  target="_blank"
                >
                  {document.displayText}
                </NvLink>
              ))}
            </NvFlex>
          </>
        )}
      </UseCasesAndLearnMoreCard>
    );
  }, []);

  return (
    <NvForm<UpdateUseCasesAndLearnMoreFormValues>
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ values: { useCases = [], support = [] }, submitting, form: { reset } }) =>
        hasDocuments || isEditMode ? (
          <FreeAppPermissionBoundary Fallback={readOnlyUseCasesAndLearnMore({ support })}>
            <UserAppPermissionBoundary
              permission={APP_PERMISSION.APP_EDIT}
              appId={userAppId}
              Fallback={readOnlyUseCasesAndLearnMore({ support })}
            >
              <UseCasesAndLearnMoreCard isEditMode={isEditMode}>
                {!isEditMode ? (
                  <>
                    {useCases.length > 0 && (
                      <>
                        <NvFlex direction="row" alignItems="center">
                          <NvTypography variant="h3" flex="1 1 auto" minWidth="0">
                            Use cases
                          </NvTypography>

                          <NvButton
                            color="ghost"
                            onlyIcon
                            size="small"
                            onClick={() => {
                              onEditModeChange(true);
                            }}
                            className="edit-documents-button"
                          >
                            <NvEditIcon />
                          </NvButton>
                        </NvFlex>
                        <NvFlex gap="8px" alignItems="flex-start">
                          {useCases.map((document, index) => (
                            <NvLink
                              key={`use-cases-and-learn-more-${document.category}-${index}`}
                              href={document.url}
                              target="_blank"
                            >
                              {document.displayText}
                            </NvLink>
                          ))}
                        </NvFlex>
                      </>
                    )}
                    {useCases.length > 0 && support.length > 0 && (
                      <UseCasesAndLearnMoreDivider borderColor={theme.palette.nv_neutral_alpha[30]} />
                    )}
                    {support.length > 0 && (
                      <>
                        <NvFlex direction="row" alignItems="center">
                          <NvTypography variant="h3" flex="1 1 auto" minWidth="0">
                            Learn more & Support
                          </NvTypography>

                          {useCases.length === 0 && (
                            <NvButton
                              color="ghost"
                              onlyIcon
                              size="small"
                              onClick={() => {
                                onEditModeChange(true);
                              }}
                              className="edit-documents-button"
                            >
                              <NvEditIcon />
                            </NvButton>
                          )}
                        </NvFlex>
                        <NvFlex gap="8px" alignItems="flex-start">
                          {support.map((document, index) => (
                            <NvLink
                              key={`use-cases-and-learn-more-${document.category}-${index}`}
                              href={document.url}
                              target="_blank"
                            >
                              {document.displayText}
                            </NvLink>
                          ))}
                        </NvFlex>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <AppDocuments
                      fieldName="useCases"
                      category={AppDocumentCategory.USE_CASE}
                      title="Use cases"
                      titleVariant="h3"
                      addNewButtonPosition="top"
                    />
                    <UseCasesAndLearnMoreDivider borderColor={theme.palette.nv_neutral[0]} />
                    <AppDocuments
                      fieldName="support"
                      category={AppDocumentCategory.SUPPORT}
                      title="Learn more & Support"
                      titleVariant="h3"
                      addNewButtonPosition="top"
                    />
                    <NvFlex direction="row" gap="8px" alignItems="center" marginTop="16px">
                      <NvButton size="small" type="submit" loading={submitting} disabled={submitting}>
                        Save
                      </NvButton>
                      <NvButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          onEditModeChange(false);
                          reset();
                        }}
                        disabled={submitting}
                      >
                        Cancel
                      </NvButton>
                    </NvFlex>
                  </>
                )}
              </UseCasesAndLearnMoreCard>
            </UserAppPermissionBoundary>
          </FreeAppPermissionBoundary>
        ) : (
          <FreeAppPermissionBoundary>
            <UserAppPermissionBoundary
              permission={APP_PERMISSION.APP_EDIT}
              appId={userAppId}
              Fallback={
                <NvButton color="secondary" startIcon={<NvAddBoxIcon />} disabled size="small">
                  Add document links
                </NvButton>
              }
            >
              <NvButton
                color="secondary"
                startIcon={<NvAddBoxIcon />}
                onClick={() => {
                  onEditModeChange(true);
                }}
                size="small"
              >
                Add document links
              </NvButton>
            </UserAppPermissionBoundary>
          </FreeAppPermissionBoundary>
        )
      }
    </NvForm>
  );
};
