import { NvDialogSlots, NvFlex, NvSkeleton } from '@novaera/core';

export const SaveJobModalLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <NvDialogSlots>
    <NvDialogSlots.Slot name="icon">
      <NvSkeleton height="24px" width="24px" variant="rectangular" />
    </NvDialogSlots.Slot>
    <NvDialogSlots.Slot name="title">
      <NvSkeleton height="24px" width="150px" variant="rectangular" />
    </NvDialogSlots.Slot>
    <NvDialogSlots.Slot name="content">
      <NvFlex gap="32px">
        <NvSkeleton height="96px" variant="rectangular" />
        <NvFlex gap="8px">
          <NvSkeleton height="32px" width="50%" variant="rectangular" />
          <NvSkeleton height="32px" width="50%" variant="rectangular" />
        </NvFlex>
        <NvFlex gap="8px">
          <NvSkeleton height="32px" width="40%" variant="rectangular" />
          <NvSkeleton height="32px" width="80%" variant="rectangular" />
          <NvSkeleton height="32px" width="60%" variant="rectangular" />
        </NvFlex>
      </NvFlex>
    </NvDialogSlots.Slot>
    <NvDialogSlots.Slot name="actions">
      <NvSkeleton height="32px" width="64px" variant="rectangular" />
      <NvSkeleton height="32px" width="64px" variant="rectangular" />
    </NvDialogSlots.Slot>
    <NvDialogSlots.Slot name="closeButton">
      <NvSkeleton height="24px" width="24px" variant="rectangular" />
    </NvDialogSlots.Slot>
  </NvDialogSlots>
);
