import { NvFlex, NvSwitch } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const InputViewContainer = styled(NvFlex)`
  padding: 24px 12px 60px 24px;
  height: 100%;
  overflow: auto;
`;

export const InputPropertiesContainer = styled(NvFlex)`
  margin-left: -12px;
  width: calc(100% + 12px);
  height: 100%;
  overflow: auto;
`;

export const InputParametersSwitch = styled(NvSwitch)`
  margin: 6px 0 auto 0;
`;
