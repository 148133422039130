import { NvAxios, useHandleCommonError, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { NodeUnion } from '../../../types';
import { USER_APP_ROOT_PATH } from '../../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS } from '../../keys';
import { GetWorkflowResponse } from '../../use-get-workflow/types';
import { QUERY_KEYS_NODE } from '../keys';
import { GetNodeResponse } from '../use-get-node/types';
import { convertNodeToSummary } from './convert-node-to-summary';
import { UpdateNodeParams } from './types';

const updateNode = async ({ appId, workflowId, nodeAlias, payload }: UpdateNodeParams) => {
  const result = await NvAxios.patch<NodeUnion>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/nodes/${nodeAlias}`,
    payload
  );
  return result?.data;
};

export const useUpdateNode = () => {
  const cache = useQueryClient();
  const { handleCommonError } = useHandleCommonError();

  return useMutation(updateNode, {
    onMutate: ({ appId, workflowId, nodeAlias, payload }) => {
      cache.cancelQueries(QUERY_KEYS_NODE.detail({ appId, workflowId, nodeAlias }));

      const previousValue = cache.getQueryData<GetNodeResponse>(
        QUERY_KEYS_NODE.detail({ appId, workflowId, nodeAlias })
      );

      cache.setQueryData<GetNodeResponse | undefined>(
        QUERY_KEYS_NODE.detail({ appId, workflowId, nodeAlias }),
        (old) => {
          return old ? { ...old, draft: payload } : undefined;
        }
      );

      const previousWorkflow = cache.getQueryData<GetWorkflowResponse>(
        QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId })
      );

      cache.setQueryData<GetWorkflowResponse | undefined>(QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId }), (old) => {
        const newWorkflow = old ? { ...old, draft: old.draft ? old.draft : old.saved } : undefined;
        const summaryPayload = convertNodeToSummary(payload);

        return newWorkflow
          ? {
              ...newWorkflow,
              draft: {
                ...newWorkflow?.draft,
                nodeSummaries: { ...newWorkflow?.draft?.nodeSummaries, [nodeAlias]: summaryPayload },
              },
            }
          : undefined;
      });

      return { previousValue, previousWorkflow };
    },

    onError: (error, variables, context) => {
      if (context?.previousValue) {
        cache.setQueryData(
          QUERY_KEYS_NODE.detail({
            appId: variables.appId,
            workflowId: variables.workflowId,
            nodeAlias: variables.nodeAlias,
          }),
          context.previousValue
        );
      }
      if (context?.previousWorkflow) {
        cache.setQueryData(
          QUERY_KEYS_WORKFLOWS.detail({
            appId: variables.appId,
            workflowId: variables.workflowId,
          }),
          context.previousWorkflow
        );
      }
      handleCommonError(error);
    },
  });
};
