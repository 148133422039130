import { NvTypography } from '@novaera/core';
import React from 'react';
import { FooterWrapper } from './styled';

export const Footer = ({ topSlot }: { topSlot?: React.ReactElement }) => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterWrapper>
      {topSlot}
      <NvTypography textColor="ghost" variant="h6">
        Copyright © Actioner | {currentYear} Actioner, All rights reserved
      </NvTypography>
    </FooterWrapper>
  );
};
