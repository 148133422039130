import { NvAxios, useQuery } from '@novaera/core';
import { CONNECTION_MAPPING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_MAPPINGS } from '../keys';
import { ConnectionMapping } from '../types';
import { UseListConnectionMappingParams } from './types';

const listConnectionMapping = async (params: UseListConnectionMappingParams) => {
  const { appId } = params;
  const result = await NvAxios.get<ConnectionMapping[]>(`${CONNECTION_MAPPING_ROOT_PATH(appId)}`);

  return result?.data.sort((d, a) => {
    const dValue = d.ambiguous ? 1 : !d.connected ? 2 : 0;
    const aValue = a.ambiguous ? 1 : !a.connected ? 2 : 0;
    return aValue - dValue;
  });
};

export const useListConnectionMappingService = (params: UseListConnectionMappingParams) => {
  return useQuery<ConnectionMapping[]>(
    QUERY_KEYS_CONNECTION_MAPPINGS.list(params.appId),
    () => listConnectionMapping(params),
    { enabled: !!params.appId, refetchOnWindowFocus: 'always' }
  );
};
