import { ResponseNodeFailedResponse, ResponseNodeGenericResponse, ResponseNodeResponse } from './types';

export const isResponseNodeResponse = (
  response: Record<string, unknown> | ResponseNodeResponse
): response is ResponseNodeResponse => {
  return response && 'response' in (response as ResponseNodeResponse);
};

export const isResponseNodeFailedResponse = (
  response: ResponseNodeGenericResponse | ResponseNodeFailedResponse
): response is ResponseNodeFailedResponse => {
  return 'failMessage' in response;
};
