import { useGenerateConfig } from '@novaera/actioner-service';
import { NvButton, NvDialogModal, NvField, NvFlex, NvRefreshIcon, NvTextArea, NvTypography } from '@novaera/core';
import { FC, useState } from 'react';
import { useAppConfigFlaggerContext } from '../../../../../../components/app-config-flagger/provider';
import { RegenerateSectionWrapper } from './styled';
import { RegenerateSectionProps } from './types';

export const RegenerateSection: FC<RegenerateSectionProps> = ({
  appId,
  config,
  configId,
  docId,
  schemaId,
  onRegenerateExecuted,
  validationMessages,
}) => {
  const [isOpenRegenerateModal, setIsOpenRegenerateModal] = useState(false);

  const { mutate: generateConfigWithAI } = useGenerateConfig();
  const { setAppConfigGenerationState } = useAppConfigFlaggerContext();

  return (
    <>
      <RegenerateSectionWrapper>
        <NvFlex direction="row" alignItems="center" gap="4px">
          <NvRefreshIcon sx={{ width: '16px', height: '16px' }} />
          <NvTypography variant="h5" flex="1 1 auto">
            Regenerate config
          </NvTypography>
        </NvFlex>
        <NvTypography variant="body3">You can instruct AI to regenerate the config using prompts.</NvTypography>
        <NvButton color="secondary" size="small" onClick={() => setIsOpenRegenerateModal(true)}>
          Regenerate
        </NvButton>
      </RegenerateSectionWrapper>
      <NvDialogModal<{ prompt: string; useEarlierErrorMessage: boolean }>
        modalIcon="refresh"
        maxWidth="md"
        Header="Regenerate config"
        open={isOpenRegenerateModal}
        primaryButtonText="Regenerate"
        Body={
          <NvFlex gap={'16px'}>
            <NvField
              name={`prompt`}
              component={
                <NvTextArea
                  rows={4}
                  sx={{ width: '490px' }}
                  placeholder="Your prompt will be used when regenerating the configuration with the assistance of AI."
                />
              }
              labelText="Prompt"
              direction="label-on-top"
            />
          </NvFlex>
        }
        onCloseButtonClicked={() => {
          setIsOpenRegenerateModal(false);
        }}
        onFormSubmit={async (value) => {
          setAppConfigGenerationState('in_progress');
          let instructions = value.prompt;
          if (validationMessages && validationMessages.length > 0) {
            instructions = `${instructions}\nThe json is not complying with the schema, here are the errors: * ${validationMessages?.join(
              '\n'
            )}. \nCan you regenerate the json using the errors as a hint so that no error occurs again?`;
          }

          generateConfigWithAI({ appId, configId, docId, schemaId, config, instructions });
          onRegenerateExecuted();
          setIsOpenRegenerateModal(false);
        }}
      />
    </>
  );
};
