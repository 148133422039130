import {
  JobRecurrenceEndType,
  JobType,
  RateRecurrenceUnit,
  Recurrence,
  RecurrenceType,
  useGetJobDetail,
  useGetTimezones,
  useSaveJob,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert, getClientTimezoneId } from '@novaera/utils';
import { format } from 'date-fns';
import { isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import { DEFAULT_RATE_UNIT } from '../../constants';
import { SaveJobFormValues } from '../../save-job-modal/types';
export const useSaveJobModal = ({ id, type, handleClose }: { type: JobType; handleClose: () => void; id?: string }) => {
  const { userAppId } = useParams();
  const [cronExpressionError, setCronExpressionError] = useState<string | null>(null);
  const { data: jobDetail, isInitialLoading: isJobDetailLoading } = useGetJobDetail({ appId: userAppId, jobId: id });
  const isEditMode = useMemo(() => !!jobDetail, [jobDetail]);
  const { data } = useGetTimezones();
  const initialSelectedTimezoneId = getClientTimezoneId();
  const timezones = useMemo(() => data?.timezones ?? [], [data?.timezones]);
  const initialTimezone = useMemo(
    () => timezones.find((t) => t.id === initialSelectedTimezoneId),
    [initialSelectedTimezoneId, timezones]
  );
  const { mutate: saveJob } = useSaveJob();
  const handleSubmit = (values: SaveJobFormValues) => {
    if (values.type === JobType.SCHEDULED) {
      const scheduledSubmitValues = {
        ...values,
        timezone: { id: values.timezone.id },
        triggerAt: `${values.triggerAt.date}T${values.triggerAt.time}`,
      };
      const target = scheduledSubmitValues.target;
      assert(!isUndefined(target), new Error('Job target can not be undefined.'), 'ERROR');

      return new Promise<void>((resolve) => {
        saveJob(
          {
            appId: userAppId,
            jobId: id,
            ...scheduledSubmitValues,
            target: { id: target.id, type: target.type, parameters: target.parameters, formId: target.formId },
          },
          {
            onSuccess: () => {
              handleClose();
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      });
    } else {
      const recurringSubmitValues = {
        ...values,
        timezone: { id: values.timezone.id },
        recurrence: {
          ...(values.recurrence.type === RecurrenceType.CRON
            ? { type: values.recurrence.type, cron: values.recurrence.cron }
            : {
                type: values.recurrence.type,
                value: values.recurrence.value,
                unit: values.recurrence.unit,
                recurrenceTimeHour: values.recurrence.recurrenceTime?.hours ?? null,
                recurrenceTimeMinute: values.recurrence.recurrenceTime?.minutes ?? null,
              }),
          end: {
            ...values.recurrence.end,
            ...(values.recurrence.end.type === JobRecurrenceEndType.DATE_TIME
              ? { at: `${values.recurrence.end.at}T23:59` }
              : {}),
          },
        },
      };
      const target = recurringSubmitValues.target;
      assert(!isUndefined(target), new Error('Job target can not be undefined.'), 'ERROR');

      return new Promise<void>((resolve) => {
        saveJob(
          {
            appId: userAppId,
            jobId: id,
            ...recurringSubmitValues,
            target: { id: target.id, type: target.type, parameters: target.parameters, formId: target.formId },
          },
          {
            onSuccess: () => {
              handleClose();
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      });
    }
  };

  const initialValues: SaveJobFormValues = useMemo(() => {
    if (jobDetail) {
      if (jobDetail.type === JobType.RECURRING) {
        return {
          ...jobDetail,
          recurrence: {
            ...jobDetail.recurrence,
            ...(jobDetail.recurrence.type === RecurrenceType.RATE
              ? {
                  recurrenceTime: {
                    hours: jobDetail.recurrence.recurrenceTimeHour,
                    minutes: jobDetail.recurrence.recurrenceTimeMinute,
                  },
                }
              : {}),

            end: (() => {
              if (jobDetail.recurrence.end.type === JobRecurrenceEndType.DATE_TIME) {
                const [date, time] = jobDetail.recurrence.end.at.split('T');
                return { ...jobDetail.recurrence.end, at: date };
              } else {
                return jobDetail.recurrence.end;
              }
            })(),
          },
          target: {
            ...jobDetail.target,
            eventPayload: JSON.stringify(jobDetail.target.eventPayload),
          },
        };
      } else {
        const [date, time] = jobDetail.triggerAt.split('T');
        return {
          ...jobDetail,
          triggerAt: { date, time },
          target: {
            ...jobDetail.target,
            eventPayload: JSON.stringify(jobDetail.target.eventPayload),
          },
        };
      }
    } else {
      const baseJob = {
        ...(id ? { id } : {}),
        name: '',
        target: undefined,
        timezone: initialTimezone ?? { id: 'Etc/UTC' },
      };
      const initialStartDate = format(Date.now(), 'yyyy/MM/dd');
      const initialStartTime = format(Date.now() + 5 * 60000, 'HH:mm');
      return type === JobType.RECURRING
        ? {
            ...baseJob,
            type,
            recurrence: {
              type: RecurrenceType.RATE,
              value: DEFAULT_RATE_UNIT,
              recurrenceTime: { minutes: 0, hours: 12 },
              unit: RateRecurrenceUnit.DAYS,
              end: { type: JobRecurrenceEndType.NEVER } as Recurrence['end'],
            },
          }
        : {
            ...baseJob,
            type,
            triggerAt: { date: initialStartDate, time: initialStartTime },
          };
    }
  }, [id, initialTimezone, jobDetail, type]);

  const handleCronErrorStateChange = (error: string | null) => {
    setCronExpressionError(error);
  };
  return {
    handleSubmit,
    initialValues,
    isJobDetailLoading,
    isEditMode,
    timezones,
    userAppId,
    cronExpressionError,
    handleCronErrorStateChange,
  };
};
