import { NvAxios, useQuery } from '@novaera/core';
import { INTEGRATION_ACTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { GetIntegrationActionsParams, GetIntegrationActionsResponse } from '../types';

const getIntegrationActions = async ({ integrationId, ...params }: GetIntegrationActionsParams) => {
  const result = await NvAxios.get<GetIntegrationActionsResponse>(`${INTEGRATION_ACTION_ROOT_PATH(integrationId)}`, {
    params,
  });
  return result?.data;
};

export const useGetIntegrationActions = (params: GetIntegrationActionsParams) => {
  return useQuery<GetIntegrationActionsResponse>(
    QUERY_KEYS_INTEGRATION_ACTIONS.list(params.integrationId, params.version, params),
    () => getIntegrationActions({ ...params, version: params.version }),
    {
      enabled: !!params.integrationId && !!params.version,
    }
  );
};
