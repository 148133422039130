import { FormControlLabel, FormGroup, RadioGroup } from '@mui/material';
import { Option } from '@novaera/ah-common';
import { NvRadio } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { isEqual } from 'lodash';
import { ChangeEvent, useMemo } from 'react';

export interface RadioButtonProps {
  value?: Option;
  onChange?: (value: Option) => void;
  options: Option[];
}

export const FormControlLabelWrapper = styled(FormControlLabel)`
  gap: 8px;
  margin-left: unset;
`;

export const RadioButton: React.FC<React.PropsWithChildren<RadioButtonProps>> = ({ value, onChange, options }) => {
  const radioGroupOptions = useMemo(() => {
    const result = options?.map((option) => {
      return {
        ...option,
        checked: isEqual(value?.identifier, option.identifier),
      };
    });
    return result;
  }, [options, value]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, checkedOption: Option) => {
    const newValues = radioGroupOptions.map((option) => {
      return {
        ...option,
        checked: option.identifier === checkedOption.identifier,
      };
    });

    onChange?.(checkedOption);
  };

  return (
    <FormGroup>
      <RadioGroup
        className="radio-group-container"
        sx={{
          rowGap: '8px',
        }}
      >
        {radioGroupOptions?.map((option, index) => {
          const { displayValue, ...restOfOption } = option;
          return (
            <FormControlLabelWrapper
              label={option.displayValue}
              key={`${JSON.stringify(option?.identifier)}-radio-button-${index}`}
              {...restOfOption}
              checked={option.checked}
              componentsProps={{
                typography: {
                  variant: 'body2',
                },
              }}
              control={<NvRadio checked={option.checked} onChange={(e) => handleOnChange(e, option)} />}
            />
          );
        })}
      </RadioGroup>
    </FormGroup>
  );
};
