import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOW_AVAILABLE_UPDATES } from '../keys';
import { CachedGetIntegrationUpdateResponse } from '../use-get-integration-version-updates/types';
import { UpdateIntegrationVersionParams } from './types';

const updateIntegrationVersion = async ({ appId, payload, workflowId }: UpdateIntegrationVersionParams) => {
  await NvAxios.post<void>(`${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/integration-version-updates`, {
    ...payload,
  });
};

export const useUpdateIntegrationVersion = () => {
  const cache = useQueryClient();

  return useMutation(updateIntegrationVersion, {
    onSuccess: (_, { appId, workflowId, payload }) => {
      cache.setQueryData<CachedGetIntegrationUpdateResponse>(
        QUERY_KEYS_WORKFLOW_AVAILABLE_UPDATES.detail({ appId, workflowId }),
        (old) => ({
          availableUpdates: old?.availableUpdates?.map((update) => {
            const updatePayload = payload.updates.find(
              (updatePayload) => updatePayload.integrationId === update.integration.id
            );

            if (updatePayload) {
              return {
                ...update,
                isUpdated: true,
                integration: {
                  ...update.integration,
                  version: updatePayload.version,
                  latestVersion: {
                    number: Number(updatePayload.version),
                  },
                },
              };
            }
            return update;
          }),
        })
      );
    },
  });
};
