import { ParameterTypes, SimpleParameterMapping, StringValue, ValueTypes } from '@novaera/actioner-service';
import { format as formatDate, isValid } from 'date-fns';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export const useDatePickerFieldAdapter = ({
  extractValue = false,
  hidden,
}: {
  extractValue?: boolean;
  hidden: boolean;
}) => {
  const formatValue = useCallback((value: StringValue | undefined | null) => {
    if (value) {
      return value.value;
    } else {
      return null;
    }
  }, []);

  const parseValue = useCallback(
    ({ id }: { id: string }) =>
      (value: Date | null) => {
        if (!isNil(value)) {
          const formattedValue = isValid(value) ? formatDate(value, 'yyyy-MM-dd') : undefined;
          if (formattedValue) {
            const constructedValue: StringValue = {
              type: ValueTypes.STRING,
              value: formattedValue,
              displayValue: formattedValue,
            };
            return constructedValue;
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
    []
  );

  const format = useCallback(
    (parameterMapping: SimpleParameterMapping<StringValue> | undefined | null) => {
      if (parameterMapping?.value) {
        return formatValue(parameterMapping.value);
      } else {
        return null;
      }
    },
    [formatValue]
  );

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (value: Date | null): SimpleParameterMapping<StringValue> | undefined => {
        const result = parseValue({ id })(value);
        if (result || hidden) {
          return { parameterId: id, type: ParameterTypes.SIMPLE, hidden, ...(result && { value: result }) };
        } else {
          return undefined;
        }
      },
    [hidden, parseValue]
  );

  return { format: extractValue ? formatValue : format, parse: extractValue ? parseValue : parse };
};
