import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { PERMISSION_POLICIES_ROOT_PATH } from '../constants';
import { QUERY_KEYS_PERMISSION_POLICIES } from '../keys';
import { GetPermissionPoliciesResponse } from '../use-get-permission-policies/types';
import { GetPermissionPolicyResponse } from '../use-get-permission-policy/types';
import { UpdatePermissionPolicyParams, UpdatePermissionPolicyResponse } from './types';

const updatePermissionPolicy: (
  params: UpdatePermissionPolicyParams
) => Promise<UpdatePermissionPolicyResponse> = async ({ id, ...params }) => {
  const result = await NvAxios.patch<UpdatePermissionPolicyResponse>(
    `${PERMISSION_POLICIES_ROOT_PATH}/policies/${id}`,
    params
  );
  return result?.data;
};

export const useUpdatePermissionPolicy = () => {
  const cache = useQueryClient();

  return useMutation(updatePermissionPolicy, {
    onSuccess: (_, { id, name, tags, description }) => {
      const permissionPolicyCaches = cache.getQueriesData<InfiniteData<GetPermissionPoliciesResponse>>(
        QUERY_KEYS_PERMISSION_POLICIES.list()
      );

      permissionPolicyCaches.forEach((nodeDetailCache) => {
        const [queryKey, _] = nodeDetailCache;

        cache.setQueryData<InfiniteData<GetPermissionPoliciesResponse>>(queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old?.pages.map((page) => ({
                ...page,
                permissionPolicies: page.permissionPolicies.map((p) =>
                  p.id === id ? { ...p, name, tags, description } : p
                ),
              })),
            };
          }
          return old;
        });
      });

      cache.setQueryData<GetPermissionPolicyResponse>(
        QUERY_KEYS_PERMISSION_POLICIES.detail({ policyId: id }),
        (old) => {
          if (old) {
            return { ...old, name, tags, description };
          }
          return old;
        }
      );
    },
  });
};
