import { FieldSchema } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import {
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDNSIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvSelect,
  NvSkeleton,
  NvTypography,
  isRequired,
} from '@novaera/core';
import { useMemo } from 'react';
import { FIELD_TYPE_SCHEMAS } from '../../constants';
import { DataModelChooseFieldTypeModalProps, RecordReferenceValues, SchemaValueValues } from './types';
import { ValueSchemaSelect } from './value-schema-select';

export const DataModelChooseFieldTypeModal = ({
  isOpen,
  onClose,
  selectedField,
  onSubmit,
  referenceModels,
  modelId,
  isLoading,
}: DataModelChooseFieldTypeModalProps) => {
  const initialModel = useMemo(() => referenceModels?.find((m) => m.value === modelId), [modelId, referenceModels]);

  const modelType = selectedField.schema.type;

  return (
    <NvDialogV1 open={isOpen} fullWidth maxWidth="sm">
      <NvForm<RecordReferenceValues | SchemaValueValues>
        onSubmit={(values) => {
          if ('selectedModel' in values) {
            onSubmit({ ...values.selectedModel });
          } else {
            onSubmit(values.valueSchema);
          }
        }}
        keepDirtyOnReinitialize
        initialValues={
          modelType === SchemaType.recordReference
            ? { selectedModel: { modelId, modelName: initialModel?.label ?? '' } }
            : { valueSchema: FIELD_TYPE_SCHEMAS[SchemaType.string] }
        }
      >
        <NvDialogSlots>
          <NvDialogSlots.Slot name="icon">
            <NvAddIcon />
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="title">
            <NvTypography variant="h2">Choose item type</NvTypography>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="closeButton">
            <NvButton onlyIcon size="small" color="secondary" onClick={onClose}>
              <NvCloseIcon />
            </NvButton>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="content">
            <NvFlex gap="16px">
              {modelType === SchemaType.recordReference ? (
                <NvTypography>
                  Adding a reference sets a relationship between two models. The selected model below will be linked to
                  the field.
                </NvTypography>
              ) : (
                <NvTypography>
                  <b>{modelType === SchemaType.array ? 'List' : 'Map'} field</b> helps you to store a list of items.
                  Below, you can choose what type of items to store:
                </NvTypography>
              )}

              {modelType !== SchemaType.recordReference && (
                <NvField<FieldSchema>
                  name="valueSchema"
                  direction="label-on-top"
                  labelVariant="h5"
                  labelText="Item type"
                  hint="Once created, field types cannot be changed."
                  {...(modelType === SchemaType.array
                    ? {
                        validators: [isRequired()],
                        hasRequiredIndicator: true,
                        isRequired: true,
                        showErrorMessageOnlyWhenBlur: true,
                      }
                    : {})}
                  parse={(value) => {
                    return value.type === 'object' ? undefined : value;
                  }}
                  format={(value) => (value ? value : { type: 'object' })}
                  component={
                    <ValueSchemaSelect modelId={modelId} parentSchemaType={modelType} recordModels={referenceModels} />
                  }
                />
              )}

              {modelType === SchemaType.recordReference &&
                (isLoading ? (
                  <NvSkeleton />
                ) : (
                  <NvFlex direction="row">
                    <NvField
                      name="selectedModel"
                      direction="label-on-top"
                      labelVariant="h5"
                      labelText="Reference model"
                      showErrorMessageOnlyWhenBlur
                      isRequired
                      hint="Once set, reference model cannot be changed later."
                      validators={[isRequired()]}
                      parse={(value: string) => {
                        const selectedModel = referenceModels?.find((r) => r.value === value);
                        return { modelId: value, modelName: selectedModel?.label };
                      }}
                      format={(value) => {
                        return referenceModels?.find((r) => r.value === value.modelId)?.value;
                      }}
                      component={<NvSelect startIcon={<NvDNSIcon />} options={referenceModels} />}
                      hasRequiredIndicator
                    />
                  </NvFlex>
                ))}
            </NvFlex>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="actions">
            <NvButton type="submit">Choose</NvButton>
            <NvButton color="secondary" onClick={onClose}>
              Cancel
            </NvButton>
          </NvDialogSlots.Slot>
        </NvDialogSlots>
      </NvForm>
    </NvDialogV1>
  );
};
