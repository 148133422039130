import { styled } from '@novaera/theme-provider';

export const AppPropertiesGrid = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 16px;
`;

export const AppPropertiesEmptyStateBox = styled('div')`
  padding: 27px 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 12px;
`;
