import { NvDivider, NvFlex, NvSkeleton } from '@novaera/core';

export const WorkflowRunFromLinkLoadingSkeleton = () => {
  return (
    <NvFlex gap={'16px'}>
      <NvFlex flexDirection={'row'} gap={'8px'}>
        <NvFlex>
          <NvSkeleton width="40px" height="40px" variant="circular" />
        </NvFlex>
        <NvFlex gap={'4px'}>
          <NvSkeleton width="300px" height="24px" variant="text" />
          <NvSkeleton width="220px" height="16px" variant="text" />
        </NvFlex>
      </NvFlex>
      <NvFlex gap={'8px'}>
        <NvSkeleton width="80%" height="32px" variant="text" />
        <NvSkeleton width="40%" height="32px" variant="text" />
        <NvSkeleton width="60%" height="32px" variant="text" />
      </NvFlex>
      <NvFlex>
        <NvDivider
          sx={{
            width: '100%',
          }}
        />
      </NvFlex>
      <NvSkeleton width="120px" height="24px" variant="rounded" />
    </NvFlex>
  );
};
