import { useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { FC } from 'react';
import { RowItemCard } from '../../../../../common/row-item-card';
import { ProducerItemLayout } from '../../../../../common/row-item-card/row-item-layouts';
import { ActionOptionProducerItemProps } from './types';

export const ActionOptionProducerItem: FC<React.PropsWithChildren<ActionOptionProducerItemProps>> = ({
  optionProducer,
  index,
  selectedIndex,
  setSelectedIndex,
}) => {
  const { data: integration } = useGetIntegration({ id: optionProducer.integrationId });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId: optionProducer.integrationId,
    actionId: optionProducer.actionId,
    version: integration?.latestVersion.number,
  });
  const sourceName = `Source ${index + 1}`;

  return (
    <RowItemCard
      rowItemContent={
        <ProducerItemLayout integration={integration} integrationAction={integrationAction} sourceName={sourceName} />
      }
      onClick={() => {
        setSelectedIndex(index);
      }}
      isSelected={selectedIndex === index}
    />
  );
};
