import { NvAxios, useQuery } from '@novaera/core';
import { useQueries } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemaPreviewParams, GetAppSchemaPreviewResponse, GetAppSchemaPreviewsParams } from './types';

const getAppSchemaPreview = async ({ schemaId }: GetAppSchemaPreviewParams) => {
  const result = await NvAxios.get<GetAppSchemaPreviewResponse>(
    `${APP_DIRECTORY_ROOT_PATH}/schemas/${schemaId}/preview`
  );
  return result?.data;
};

export const useGetAppSchemaPreview = (params: GetAppSchemaPreviewParams) =>
  useQuery<GetAppSchemaPreviewResponse>(
    QUERY_KEYS_APP_DIRECTORY.previewDetail(params.schemaId),
    () => getAppSchemaPreview(params),
    !isUndefined(params.enabled) ? { enabled: params.enabled } : {}
  );

export const useGetAppSchemaPreviews = (params: GetAppSchemaPreviewsParams) => {
  const schemaIds = params.schemaIds ?? [];
  const queries = useQueries({
    queries: schemaIds.map((schemaId) => {
      return {
        queryFn: () => getAppSchemaPreview({ schemaId }),
        queryKey: QUERY_KEYS_APP_DIRECTORY.previewDetail(schemaId),
        enabled: !!schemaId,
      };
    }),
  });

  return queries.map((query, index) => {
    const id = schemaIds[index];
    return { ...query, data: { ...query.data, schemaId: id } };
  }, {});
};
