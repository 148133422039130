import { TimePicker } from '@mui/x-date-pickers';

import { assert } from '@novaera/utils';
import React from 'react';
import { NvConditionalWrap } from '../conditional-wrap';
import { DateTimePickerDefaultTextField } from '../textfield/styled';
import { useTimePicker } from './controller';
import { TimeInputMask } from './input-mask';
import { TimePickerWrapper } from './styled';
import { NvTimePickerProps } from './types';

export const NvTimePicker: React.FC<React.PropsWithChildren<NvTimePickerProps>> = ({
  value,
  onChange,
  renderInput,
  fullWidth,
  ...props
}) => {
  assert(onChange != null, new Error('onChange should be defined or Wrap your component within NvField'), 'ERROR');

  const { isOpen, changeTimePickerOpen } = useTimePicker();

  return (
    <NvConditionalWrap condition={!fullWidth} wrap={(children) => <TimePickerWrapper>{children}</TimePickerWrapper>}>
      <TimePicker
        value={value}
        onChange={(parameter) => {
          changeTimePickerOpen(false);
          onChange(parameter);
        }}
        onClose={() => {
          changeTimePickerOpen(false);
        }}
        ampm={false}
        renderInput={
          renderInput
            ? renderInput
            : (params) => (
                <DateTimePickerDefaultTextField
                  onClick={() => changeTimePickerOpen(!isOpen)}
                  {...params}
                  error={props.error}
                  fullWidth={fullWidth}
                  InputProps={{
                    ...params.InputProps,
                    inputComponent: TimeInputMask,
                  }}
                />
              )
        }
        InputAdornmentProps={{ position: 'start' }}
        {...props}
        open={false}
      />
    </NvConditionalWrap>
  );
};
