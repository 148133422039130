import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppConfigFlaggerCard = styled(NvFlex)`
  flex-direction: row;
  width: 360px;
  padding: 8px 24px 8px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 33px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e500};
`;

export const AppConfigFlaggerIconBox = styled(NvFlex)`
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
`;

export const AppConfigFlaggerBody = styled(NvFlex)``;
