import { useMemo } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { isUndefined } from 'lodash';

import { NvTypography } from '@novaera/core';

import { VersionPublishedTimeProps } from './types';

export const VersionPublishedTime: React.FC<React.PropsWithChildren<VersionPublishedTimeProps>> = ({ publishedAt }) => {
  const publishDateDistanceToNow = useMemo(
    () => (publishedAt ? formatDistanceToNow(new Date(publishedAt), { addSuffix: true }) : undefined),
    [publishedAt]
  );

  return !isUndefined(publishDateDistanceToNow) ? (
    <NvTypography variant="body2" textColor="subtle">
      {publishDateDistanceToNow}
    </NvTypography>
  ) : null;
};
