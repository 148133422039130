import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import blue from './free-app-background-blue.svg';
import red from './free-app-background-red.svg';
import yellow from './free-app-background-yellow.svg';

export const FreeAppInformationWrapper = styled(NvFlex)`
  width: 100%;
  position: relative;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  padding: 16px;
  gap: 8px;
  background-image: url(${yellow}), url(${blue}), url(${red});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 0% 0%, 50% 100%, 100% 0%;
`;
