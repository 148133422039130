import { NvBox, NvFlex, NvRouterLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NavigationMenuWrapper = styled('div')`
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.navigation};
  height: 100vh;
  width: 64px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  box-shadow: none;
  display: flex;
  flex-direction: column;
  transition: width 0.2s;
  white-space: nowrap;
  overflow: hidden;

  &.closed {
    width: 64px;

    &:hover,
    &.popup-menu-opened {
      width: 64px;
      box-shadow: none;

      .hidden-item {
        opacity: 0;
      }
    }
  }

  &:hover,
  &.popup-menu-opened {
    width: 280px;
    box-shadow: 4px 0px 30px -3px rgba(10, 45, 98, 0.12);
    .hidden-item {
      opacity: 1;
    }
  }

  .hidden-item {
    transition: opacity 0.2s;
    opacity: 0;
  }
`;

export const NavigationLogoBox = styled('div')`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 72px;
`;

export const NavigationBox = styled('div')`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

export const NavigationItemWrapper = styled(NvRouterLink)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  overflow: hidden;

  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
    background-color: ${({ theme }) => theme.palette.nv_neutral[800]};
  }
`;

export const NavigationWorkspaceWrapper = styled(NvFlex)`
  width: 100%;
  height: 72px;
  flex-direction: row;
  align-items: center;
`;

export const MenuItemsWrapper = styled(NvFlex)`
  flex: 1 1 auto;
  min-height: 0;
  width: calc(100% + 6px);
  padding-right: 6px;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  gap: 8px;
  margin: 8px 0;
`;

export const MenuHomeWrapper = styled(NvFlex)`
  position: relative;
  gap: 8px;
  margin: 8px 0;
`;

export const NavigationBottomWrapper = styled(NvFlex)`
  width: calc(100% + 6px);
  padding-right: 6px;
`;

export const StyledCustomUpgradeIcon = styled(NvBox)`
  position: absolute;
  height: 32px;
  width: 32px;
  background-color: ${({ theme }) => theme.palette.nv_accent[40]};
  border-radius: 16px;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(10px);
  opacity: 0.4;
`;
