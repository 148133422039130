import React, { useState } from 'react';
import { NvButton } from '../../button';
import { NvPureDialog } from '../../dialog';
import { NvLaunchIcon } from '../../icons';
import {
  CloseButtonContainer,
  CodeInputExpanderContainer,
  ExpandFunctionViewBox,
  ExpandedFunctionModalPaper,
} from './styled';
import { CodeInputExpanderProps } from './types';

export const CodeInputExpander = ({ children }: CodeInputExpanderProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CodeInputExpanderContainer>
        {React.isValidElement(children) && React.cloneElement(children, { isExpanded: false })}
        <ExpandFunctionViewBox onClick={handleClickOpen} className="expand-function-view-box">
          <NvLaunchIcon sx={{ width: '12px', height: '12px' }} />
        </ExpandFunctionViewBox>
      </CodeInputExpanderContainer>
      {open && (
        <NvPureDialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="xl"
          PaperComponent={ExpandedFunctionModalPaper}
        >
          {React.isValidElement(children) && React.cloneElement(children, { isExpanded: true })}

          <CloseButtonContainer>
            <NvButton onClick={handleClose}>Close</NvButton>
          </CloseButtonContainer>
        </NvPureDialog>
      )}
    </>
  );
};
