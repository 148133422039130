import { IntegrationWebhookTrigger, WorkflowWithState } from '@novaera/actioner-service';
import { NvButton, NvCloseIcon, NvConditionalRender, NvCustomConditionIcon, NvFlex } from '@novaera/core';
import arrayMutators from 'final-form-arrays';

import { noop } from 'lodash';
import { FILTERED_CONDITION_TYPE_OPTIONS } from '../../../../../../../../components/match-event-section/constants';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { Conditions } from '../../../../components/conditions';
import { DYNAMIC_VALUE_TYPE_OPTIONS } from '../../workflow-condition-panel-drawer/workflow-condition-properties/constants';
import { useRootConditionPropertiesController } from './controllers/use-root-condition-properties';
import { PropertyPanelHeaderStyled, StyledNvForm } from './styled';
import { IntegrationTriggerRootConditionFormValues } from './types';

const CONDITION_FIELD_NAME = 'rootCondition';

export const RootConditionProperties = ({
  workflow,
  onCloseClicked,
}: {
  workflow?: WorkflowWithState;
  onCloseClicked: () => void;
}) => {
  const { hasEditPermission } = useWorkflowPermission();
  const { onSave, context } = useRootConditionPropertiesController();
  return (
    <NvFlex flexDirection={'column'} width="100%" height={'100%'} justifyContent={'flex-start'}>
      <PropertyPanelHeaderStyled
        title="Trigger conditions"
        icon={<NvCustomConditionIcon />}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />
      <NvConditionalRender when={hasEditPermission}>
        <StyledNvForm
          initialValues={{
            rootCondition: (workflow?.trigger as IntegrationWebhookTrigger)?.rootCondition,
          }}
          keepDirtyOnReinitialize
          onSubmit={noop}
          onChange={({ values }) => {
            if (workflow) {
              // value does not take form type after styled NvForm to need to cast it
              onSave(workflow, values as IntegrationTriggerRootConditionFormValues);
            }
          }}
          mutators={{ ...arrayMutators }}
          autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        >
          <Conditions
            context={context}
            conditionFieldName={CONDITION_FIELD_NAME}
            conditionOptions={FILTERED_CONDITION_TYPE_OPTIONS}
            valueTypeOptions={DYNAMIC_VALUE_TYPE_OPTIONS}
          />
        </StyledNvForm>
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <StyledNvForm
          initialValues={{
            rootCondition: (workflow?.trigger as IntegrationWebhookTrigger)?.rootCondition,
          }}
          keepDirtyOnReinitialize
          onSubmit={noop}
          mutators={{ ...arrayMutators }}
          autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        >
          <Conditions
            context={context}
            conditionFieldName={CONDITION_FIELD_NAME}
            conditionOptions={FILTERED_CONDITION_TYPE_OPTIONS}
            valueTypeOptions={DYNAMIC_VALUE_TYPE_OPTIONS}
          />
        </StyledNvForm>
      </NvConditionalRender>
    </NvFlex>
  );
};
