import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SourceCard = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
`;
