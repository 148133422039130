import { NvDivider } from '@novaera/core';
import { useState } from 'react';
import { AppAssetsWrapper } from '../styled';
import { AppUseCasesAndLearnMoreProps } from './types';
import { UseCasesAndLearnMore } from './use-cases-and-learn-more';

export const AppUseCasesAndLearnMore: React.FC<React.PropsWithChildren<AppUseCasesAndLearnMoreProps>> = ({
  assets,
  appHasNoDescription,
}) => {
  const [assetsEditModeState, setAssetsEditModeState] = useState<boolean>(false);
  const handleEditModeChange = setAssetsEditModeState;

  const hideAssets = appHasNoDescription;
  return hideAssets ? null : (
    <>
      <NvDivider sx={{ margin: '8px 0' }} />
      <AppAssetsWrapper>
        <UseCasesAndLearnMore
          documents={assets?.documents ?? []}
          onEditModeChange={handleEditModeChange}
          isEditMode={assetsEditModeState}
        />
      </AppAssetsWrapper>
    </>
  );
};
