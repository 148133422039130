import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';

export const JSONViewerWrapper = styled(NvBox)`
  position: relative;
  width: 100%;
`;
export const NvCopyWrapper = styled(NvBox)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
`;
