import { NvAxios, useMutation } from '@novaera/core';
import { SLACK_USERS_ROOT_PATH } from '../../constants';

const mapSlackUser = async (params: { triggerId: string; teamId: string }) => {
  const result = await NvAxios.post<void>(SLACK_USERS_ROOT_PATH, params);
  return result?.data;
};

export const useMapSlackUser = () => {
  return useMutation(mapSlackUser);
};
