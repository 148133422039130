import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useGetIntegrationAction } from '../../integration-action/use-get-integration-action';
import { ExecutionStepResponse } from '../../types';
import { QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION } from '../keys';
import { GetIntegrationActionStepResponsesProps } from '../types';

export const useGetIntegrationActionStepResponses = ({
  integrationId,
  actionId,
  version,
}: GetIntegrationActionStepResponsesProps) => {
  const cache = useQueryClient();

  const data = cache.getQueriesData<ExecutionStepResponse>(
    QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.list(integrationId, actionId)
  );

  const { data: integrationAction } = useGetIntegrationAction({ actionId, integrationId, version });

  const enrichedContextFromRequestExecutions = useMemo(() => {
    const initialRequest =
      integrationAction?.httpRequestConfigurations?.reduce((acc, curr) => {
        return { ...acc, [curr.name]: undefined };
      }, {}) ?? [];

    const reducedResponses = data.reduce<{ request?: Record<string, unknown> } & Record<string, unknown>>(
      (acc, [keys, value]) =>
        value?.runStepExecution?.step?.type === 'function'
          ? { ...acc, ...value.context.context }
          : { ...acc, request: { ...acc.request, ...value?.context } },
      { request: initialRequest }
    );

    return reducedResponses;
  }, [integrationAction, data]);

  return { data, enrichedContextFromRequestExecutions };
};
