import {
  DynamicWorkflowReferenceElement,
  SlackButtonStyles,
  StaticWorkflowReferenceElement,
  isAppDependencyWorkflowReferenceElement,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvButton,
  NvCheckbox,
  NvCloseIcon,
  NvCustomExecuteButtonIcon,
  NvDeleteOutlineIcon,
  NvFlex,
  NvMenuWithItems,
  NvSelect,
  NvSettingsIcon,
  NvTypography,
  NvWorkflowIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { cloneDeep } from 'lodash';
import { FC } from 'react';
import { FormIdentifierProvider } from '../../../providers/form-identifier-provider';
import { WorkflowSelectComponent } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/common/workflow-select-component';
import { useGetAppIdFromDependency } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/common/workflow-select-component/controllers/use-get-app-id-from-dependency';
import { WorkflowInputs } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/workflow-inputs';
import { DynamicStaticMenuItem } from '../../parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu/dynamic-static-menu-item';
import {
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../property-panel';
import { SelectWorkflowWrapper } from './styled';
import { WorkflowReferenceItemProps } from './types';

export const WorkflowReferenceItem: FC<WorkflowReferenceItemProps> = ({
  workflowReference,
  onChange,
  index,
  onDeleteClick,
  onClose,
  context,
}) => {
  const theme = useTheme();
  const { userAppId } = useParams<Record<string, string>>();

  const { getAppIdFromDependency } = useGetAppIdFromDependency({
    rootAppID: userAppId,
    mode: 'sync',
  });

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        title={`Button ${index + 1}`}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                props: { color: 'ghost', size: 'small', variant: 'contained', onlyIcon: true },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  onClick: () => {
                    onDeleteClick(workflowReference.id);
                  },
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                },
              ]}
            />
            <NvButton onlyIcon size="small" color="secondary" onClick={onClose}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <PropertyPanelSection icon={<NvCustomExecuteButtonIcon />} title="Button">
        <NvFlex gap="8px">
          <NvFlex flexDirection="row" alignItems="center">
            <NvTypography variant="h6" width="116px">
              Label
            </NvTypography>
            <NvFlex flex="1 1 auto">
              <CodeInput
                context={context}
                value={workflowReference.displayConfiguration.text}
                onChange={(value) => {
                  const newButtonElement = cloneDeep(workflowReference);
                  newButtonElement.displayConfiguration.text = value;
                  onChange(newButtonElement);
                }}
                placeholder="{{...}}"
              />
            </NvFlex>
          </NvFlex>
          <NvFlex flexDirection="row" alignItems="center">
            <NvTypography variant="h6" width="116px">
              Style
            </NvTypography>
            <NvFlex minWidth="180px">
              <NvSelect
                onChange={(e) => {
                  const newButtonElement = cloneDeep(workflowReference);
                  newButtonElement.displayConfiguration.variant = e.target.value as SlackButtonStyles;
                  onChange(newButtonElement);
                }}
                displayEmpty
                value={workflowReference.displayConfiguration.variant}
                options={[
                  { label: 'Default button', value: undefined },
                  { label: 'Primary button', value: SlackButtonStyles.PRIMARY },
                  { label: 'Danger button', value: SlackButtonStyles.DANGER },
                ]}
                fullWidth
                size="small"
              />
            </NvFlex>
          </NvFlex>
        </NvFlex>
      </PropertyPanelSection>
      <PropertyPanelSection
        title={'Select workflow'}
        icon={<NvWorkflowIcon />}
        tooltip={"You can select workflows from this app, as well as dependent app's workflows."}
      >
        <SelectWorkflowWrapper>
          <NvFlex width={'100%'}>
            {workflowReference.type === 'dynamic' ? (
              <NvFlex gap="16px">
                <NvFlex gap="4px">
                  <NvTypography variant="h6">App ID</NvTypography>
                  <CodeInput
                    context={context}
                    value={workflowReference.appIdTemplate}
                    onChange={(value) => {
                      onChange({ ...workflowReference, appIdTemplate: value });
                    }}
                    placeholder="{{...}}"
                  />
                </NvFlex>
                <NvFlex gap="4px">
                  <NvTypography variant="h6">Workflow ID</NvTypography>
                  <CodeInput
                    context={context}
                    value={workflowReference.workflowIdTemplate}
                    onChange={(value) => {
                      onChange({ ...workflowReference, workflowIdTemplate: value });
                    }}
                    placeholder="{{...}}"
                  />
                </NvFlex>
              </NvFlex>
            ) : (
              <WorkflowSelectComponent
                hasMultipleAppSupport
                value={
                  isAppDependencyWorkflowReferenceElement(workflowReference)
                    ? {
                        dependencyId: workflowReference.dependencyId,
                        workflowId: workflowReference.workflowId,
                        type: 'dependency',
                      }
                    : {
                        appId: workflowReference.appId ?? userAppId,
                        workflowId: workflowReference.workflowId,
                        type: 'static',
                      }
                }
                onChange={(value) => {
                  if (!value) {
                    onChange?.({
                      ...workflowReference,
                    });
                    return;
                  }

                  const newValue = {
                    ...workflowReference,
                    ...value,
                    parameterMappings: [],
                  };

                  onChange(newValue);
                }}
                searchParamsTriggerTypes={['form']}
                size="small"
              />
            )}
          </NvFlex>
          <NvFlex flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="flex-start">
            <NvBox>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvSettingsIcon />,
                  props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
                }}
                menuItems={
                  <DynamicStaticMenuItem
                    items={[
                      {
                        title: 'Static selection',
                        value: 'static',
                        description: 'Select workflow from the list.',
                      },
                      {
                        title: 'Dynamic selection',
                        value: 'dynamic',
                        description: `Dynamically retrieve the workflow name.`,
                      },
                    ]}
                    selectedIndex={
                      workflowReference.type === 'static' || workflowReference.type === 'dependency' ? 0 : 1
                    }
                    onItemClicked={(value) => {
                      const { displayConfiguration, executeInPlace, id } = workflowReference;
                      if (value === 'static') {
                        const newButtonElement: StaticWorkflowReferenceElement = {
                          type: 'static',
                          id,
                          displayConfiguration,
                          executeInPlace,
                          appId: userAppId,
                          parameterMappings: [],
                          workflowId: '',
                        };
                        onChange(newButtonElement);
                      } else {
                        const newButtonElement: DynamicWorkflowReferenceElement = {
                          type: 'dynamic',
                          id,
                          displayConfiguration,
                          executeInPlace,
                          appIdTemplate: '{{app.id}}',
                          workflowIdTemplate: '',
                          inputTemplate: '',
                        };
                        onChange(newButtonElement);
                      }
                    }}
                  />
                }
              />
            </NvBox>
          </NvFlex>
        </SelectWorkflowWrapper>
        <NvFlex direction="row" alignItems="center" height="32px" gap="4px">
          <NvCheckbox
            checked={workflowReference.executeInPlace}
            value={workflowReference.executeInPlace}
            onChange={(e, value) => {
              onChange?.({ ...workflowReference, executeInPlace: value });
            }}
          />
          <NvTypography variant="h6" textColor="subtle">
            Run immediately
          </NvTypography>
        </NvFlex>
      </PropertyPanelSection>
      {workflowReference.type !== 'dynamic' ? (
        workflowReference.workflowId && (
          <FormIdentifierProvider initialFormId={workflowReference.formId}>
            <WorkflowInputs
              workflowId={workflowReference.workflowId}
              userAppId={
                (workflowReference.type === 'static'
                  ? workflowReference.appId
                  : getAppIdFromDependency(workflowReference.dependencyId)) ?? userAppId
              }
              initialParameterMappings={workflowReference.parameterMappings || []}
              onChange={({ parameterMappings, formId }) => {
                onChange?.({ ...workflowReference, formId, parameterMappings });
              }}
              context={context}
              isDisplayValueConfigurable
            />
          </FormIdentifierProvider>
        )
      ) : (
        <>
          <PropertyPanelListHeader
            title={'Workflow input template'}
            tooltip={'You need to provide workflow input values in JSON format.'}
          />
          <PropertyPanelSimpleSection>
            <CodeInput
              value={workflowReference.inputTemplate}
              onChange={(value) => {
                onChange?.({ ...workflowReference, inputTemplate: value });
              }}
              context={context}
              growingHeight
              initialHeight="238px"
            />
            <NvTypography marginTop="4px" marginLeft={'2px'} variant="body3">
              <NvTypography variant="body3" textColor="ghost">
                Supports only JSON format.
              </NvTypography>
            </NvTypography>
          </PropertyPanelSimpleSection>
        </>
      )}
    </NvFlex>
  );
};
