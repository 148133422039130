import { Price, useCreateCheckoutSession } from '@novaera/actioner-service';
import { NvBox, NvButton, NvFlex, NvSelect, NvSelectMenuItem, NvTypography } from '@novaera/core';
import { assert, numberFormatter } from '@novaera/utils';
import { FC, useCallback, useMemo, useState } from 'react';
import { BillingStyledSwitch, PriceRecurrenceWrapper, PriceWrapper } from './styled';
import { SubscriptionPlanInfoProps } from './types';

export const SubscriptionPlanInfo: FC<SubscriptionPlanInfoProps> = ({ plans, selectedPlan, setSelectedPlan }) => {
  const { mutate: createCheckoutSession, isLoading: isCreatePaymentSessionLoading } = useCreateCheckoutSession();
  const { prices, id, name, description } = selectedPlan;

  const [selectedPrice, setSelectedPrice] = useState(() => {
    let promotedPrice: Price | undefined;
    const yearlyPrice = prices.find((p) => p.billingCycle === 'yearly');
    if (yearlyPrice) {
      promotedPrice = yearlyPrice;
    } else {
      promotedPrice = prices.find((p) => p.billingCycle === 'monthly');
    }
    return promotedPrice;
  });

  const monthlyPrice = useMemo(() => {
    assert(!!selectedPrice, new Error('There is always a selected price in SubscriptionPlanInfo'), 'ERROR');
    return selectedPrice.billingCycle === 'yearly' ? selectedPrice.amountInDollars / 12 : selectedPrice.amountInDollars;
  }, [selectedPrice]);

  const handleUpgrade = useCallback(() => {
    assert(!!selectedPrice, new Error('There is always a selected price in SubscriptionPlanInfo'), 'ERROR');
    createCheckoutSession(
      { planId: id, billingCycle: selectedPrice.billingCycle },
      {
        onSuccess: (result) => {
          if (result.url) {
            window.location.href = result.url;
          }
        },
      }
    );
  }, [createCheckoutSession, id, selectedPrice]);

  return (
    <NvFlex padding="16px" direction="row" alignItems="flex-start">
      <NvFlex flex="1 1 auto" minWidth={0} gap="8px">
        <NvFlex gap="4px">
          <NvTypography variant="h2">{name}</NvTypography>
          <NvTypography variant="body3" textColor="secondary">
            {description}
          </NvTypography>
        </NvFlex>
        <NvSelect<string>
          sx={{ width: '250px' }}
          value={selectedPlan.id}
          onChange={(event) => {
            const newlySelectedPlan = plans.find((p) => p.id === event.target.value);
            assert(!!newlySelectedPlan, new Error('Cannot find selected plan'), 'ERROR');
            setSelectedPlan(newlySelectedPlan);

            setSelectedPrice((prevValue) => {
              const foundPrice = newlySelectedPlan.prices.find((p) => p.billingCycle === prevValue?.billingCycle);
              return foundPrice ?? newlySelectedPlan.prices[0];
            });
          }}
          size="medium"
        >
          {plans.map((p) => (
            <NvSelectMenuItem key={`${p.id}-${p.name}`} value={p.id}>
              <NvTypography variant="body1" noWrap textOverflow={'ellipsis'}>
                <b>
                  {numberFormatter({
                    number: p.resourceLimits.find((r) => r.resource === 'node-execution')?.quantity,
                    digits: 0,
                  })}
                </b>{' '}
                credits per month
              </NvTypography>
            </NvSelectMenuItem>
          ))}
        </NvSelect>
        <NvFlex direction="row" alignItems="center" gap="4px">
          <PriceWrapper component="span">${monthlyPrice.toFixed(2)}</PriceWrapper>
          <NvBox>
            <PriceRecurrenceWrapper component="div">/per month</PriceRecurrenceWrapper>
            <PriceRecurrenceWrapper component="div">billed {selectedPrice?.billingCycle}</PriceRecurrenceWrapper>
          </NvBox>
        </NvFlex>
      </NvFlex>
      <NvFlex direction="row" alignItems="center" gap="8px">
        {prices.length > 1 && (
          <BillingStyledSwitch
            value={selectedPrice?.billingCycle}
            onChange={(value) => {
              assert(
                value === 'monthly' || value === 'yearly',
                new Error('Billing cycle should be monthly or yearly.'),
                'ERROR'
              );

              const newPrice = prices.find((price) => price.billingCycle === value);
              setSelectedPrice(newPrice);
            }}
            options={[
              { label: 'Annual', value: 'yearly' },
              { label: 'Monthly', value: 'monthly' },
            ]}
          />
        )}
        <NvButton color="primary" onClick={handleUpgrade} loading={isCreatePaymentSessionLoading}>
          Upgrade
        </NvButton>
      </NvFlex>
    </NvFlex>
  );
};
