/**
 * alpha value should be number/float between 0 and 1.
 *
 * Usage in styled components.
 * ${({ theme }) => withAlpha(theme.palette.nv_neutral[0], 0)}
 */
export const withAlpha = (color: string, value: number) => {
  try {
    return color.replace(/[\d.]+\)$/g, `${value})`);
  } catch (error) {
    return color;
  }
};
