import { SchemaJSONViewer } from './schema-viewer';
import { Container } from './styled';

export const EventDetailBody = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Container>
      <SchemaJSONViewer isLoading={isLoading} title="Event schema" formName="payloadSchema" />
      <SchemaJSONViewer isLoading={isLoading} title="Example payload" formName="examplePayload" />
    </Container>
  );
};
