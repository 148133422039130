import { useGetUserApp } from '@novaera/actioner-service';
import { ReactNode } from 'react';
import { SIDE_MENU_ITEM_ID } from '../../user-app-detail/user-app-side-menu/constants';
import { useGetWorkflowQueryParams } from '../../user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

type AppLevelMenuProps = {
  menuItemId: SIDE_MENU_ITEM_ID;
  children: ReactNode;
};

export const AppLevelMenu: React.FC<AppLevelMenuProps> = ({ children, menuItemId }) => {
  const { userAppId } = useGetWorkflowQueryParams();

  const { data: userApp } = useGetUserApp(userAppId);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return userApp?.appMenuItems?.find((item) => item === menuItemId) ? <>{children}</> : null;
};
