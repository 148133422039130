import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { APP_DEPENDENCIES_ROOT } from '../constants';
import { QUERY_KEYS_APP_DEPENDENCIES } from '../keys';
import { GetAppDependenciesResponse } from '../use-get-app-dependencies';

export type DeleteAppDependencyParams = {
  appId: string;
  dependencyId: string;
};

const deleteAppDependency = async ({ appId, dependencyId }: DeleteAppDependencyParams) => {
  await NvAxios.delete<void>(`${APP_DEPENDENCIES_ROOT}/${appId}/dependencies/${dependencyId}`);
};

export const useDeleteAppDependency = () => {
  const cache = useQueryClient();
  return useMutation(deleteAppDependency, {
    onSuccess: (_, variables) => {
      cache.setQueryData<GetAppDependenciesResponse>(
        QUERY_KEYS_APP_DEPENDENCIES.lists({ appId: variables.appId }),
        (old) => {
          return old
            ? {
                ...old,
                dependencies: old.dependencies.filter(
                  (dependency) => dependency.dependency.id !== variables.dependencyId
                ),
              }
            : { dependencies: [] };
        }
      );
    },
  });
};
