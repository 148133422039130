import {
  NvBox,
  NvButton,
  NvCustomCodeIcon,
  NvDeleteOutlineIcon,
  NvDivider,
  NvFlex,
  NvMenuWithItems,
  NvTypography,
  useForm,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { Schema } from '../types';
import { HeaderWrapper } from './styled';

export const FocusStateSchemaHeader = ({
  onClose,
  onDeleteClicked,
  title,
  primaryButtonLabel,
  isCreateLoading,
}: {
  onClose: () => void;
  onDeleteClicked: () => void;
  title: string;
  primaryButtonLabel: string;
  isCreateLoading: boolean;
}) => {
  const theme = useTheme();
  const form = useForm<Schema>();

  return (
    <HeaderWrapper>
      <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto">
        <NvBox width="40px" height="40px" display="flex" alignItems="center" justifyContent="center">
          <NvCustomCodeIcon />
        </NvBox>

        <NvTypography variant="h1">{title}</NvTypography>
      </NvFlex>
      <NvFlex flex={'0 0 auto'} gap="8px" flexDirection={'row'} alignItems="center">
        <NvButton
          color="secondary"
          size="small"
          disabled={isCreateLoading}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </NvButton>
        <NvButton
          color="primary"
          size="small"
          loading={isCreateLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {primaryButtonLabel}
        </NvButton>
        <NvDivider orientation="vertical" sx={{ height: '12px' }} />
        <NvMenuWithItems
          menuItems={[
            {
              name: 'Delete',
              onClick: () => {
                onDeleteClicked();
              },
              icon: (
                <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
              ),
            },
          ]}
        />
      </NvFlex>
    </HeaderWrapper>
  );
};
