import { styled } from '@mui/material/styles';
import { NvBox, NvCloseIcon, NvFlex } from '@novaera/core';
import { ErrorMarker } from '../../error-marker';

export const StyledInputParameter = styled(NvFlex)`
  border-radius: 12px;
  border: 2px solid transparent;
  cursor: pointer;
  gap: 4px;
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    border-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  }

  &.is-selected {
    background-color: ${({ theme }) => theme.palette.nv_main[10]};
    border-color: ${({ theme }) => theme.palette.nv_main[40]};
    cursor: default;

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_main[10]};
      border-color: ${({ theme }) => theme.palette.nv_main[40]};
    }

    .drag-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  &.has-error {
    border-color: ${({ theme }) => theme.palette.nv_error[30]};
  }
`;

export const StyledActionBox = styled(NvBox)`
  position: absolute;
  top: -1px;
  right: 0;
  height: 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  margin: 0 8px;
`;

export const StyledInputPathContainer = styled(NvBox)`
  background-color: ${({ theme }) => theme.palette.nv_main[40]};
  border-radius: 3px;
  padding: 1px 4px;
`;

export const StyledCloseIconContainer = styled(NvBox)`
  background-color: ${({ theme }) => theme.palette.nv_main[40]};
  border-radius: 3px;
  width: 16px;
  height: 16px;
`;

export const StyledNvCloseIcon = styled(NvCloseIcon)`
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  font-size: 12px;
`;

export const DragItemFlex = styled(NvFlex)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 12px);
  left: -14px;
  width: 14px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.nv_main[40]};
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

export const WarningIconFlex = styled(NvFlex)`
  position: absolute;
  right: -10px;
  bottom: -10px;
`;

export const StyledWarningIcon = styled(ErrorMarker)``;
