import { useMutation, NvAxios, useToast, isAxiosError, useAxiosErrorHandler } from '@novaera/core';

import { CreateUserSessionParams, CreateUserSessionResponse } from './types';

const createUserSession = async (params: CreateUserSessionParams) => {
  const result = await NvAxios.post<CreateUserSessionResponse>('v1/users/session', params);
  return result.data;
};

export const useCreateUserSession = () => {
  const { addToast } = useToast();
  const { axiosErrorHandler } = useAxiosErrorHandler();

  return useMutation(createUserSession, {
    onError: (error) => {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else if (error instanceof Error) {
        addToast(`User session could be created, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`User session could be created, reason: ${JSON.stringify(error)}`, { variant: 'error' });
      }
    },
  });
};
