import { CatalogEntity, CatalogEntityParameters, useSearchCatalogEntities } from '@novaera/actioner-service';
import { GridSortModel } from '@novaera/core';
import { useQueryParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useMemo, useRef, useState } from 'react';
import { getRecordColumns } from '../../../../../components/records-table/utils';
import { useSelectedCatalogEntity } from '../../../../controllers/use-selected-catalog-entity';
import { RECORD_LIMIT } from '../../constants';
import { getPredefinedColumns } from './utils';

export const useCatalogEntitySearch = () => {
  const { selectedEntityType: entityType } = useSelectedCatalogEntity();

  assert(!!entityType, new Error('To use entity search, entity type must be selected'), 'ERROR');

  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pageOffset, setPageOffset] = useState<number>();
  const [query, setQuery] = useState<string>('');
  const searchQueryKeywordRef = useRef('');
  const { getSearchParams, removeQueryParams, addQueryParam } = useQueryParams();
  const { catalogEntityId } = getSearchParams<{ catalogEntityId?: string }>() || {};
  const { selectedEntityType } = useSelectedCatalogEntity();

  const sortParams = useMemo(() => {
    if (sortModel.length > 0) {
      const sortParameter = sortModel[0];
      const order = sortParameter.sort as 'asc' | 'desc';
      const parameterId =
        entityType.parameters?.find((parameter) => parameter.name === sortParameter.field)?.id ?? sortParameter.field;
      const parameterIds = [parameterId] as string[];
      return { order, parameterIds };
    } else {
      return null;
    }
  }, [entityType.parameters, sortModel]);

  const queryParams = useMemo(
    () => ({
      query,
      limit: RECORD_LIMIT,
      ...(pageOffset ? { nextPageOffset: pageOffset } : {}),
      ...(sortParams ? { sortParams } : {}),
    }),
    [pageOffset, query, sortParams]
  );

  const {
    data: catalogEntities,
    isLoading,
    isRefetching,
    refetch,
  } = useSearchCatalogEntities({
    entityTypeId: entityType.id,
    ...queryParams,
  });

  const catalogEntity = useMemo(
    () => catalogEntities?.entities?.find((entity) => entity.id === catalogEntityId),
    [catalogEntities?.entities, catalogEntityId]
  );

  const columns = useMemo(() => {
    const { createdAtField, customPropertiesField, idField, tagsField, updatedAtField } =
      getPredefinedColumns(entityType);

    const columns = getRecordColumns<CatalogEntity, CatalogEntityParameters>({
      name: entityType.name,
      fields: [
        idField,
        ...(entityType.parameters ?? []),
        tagsField,
        customPropertiesField,
        createdAtField,
        updatedAtField,
      ],
    });

    return columns;
  }, [entityType]);

  const rows = useMemo(
    () => catalogEntities?.entities?.reduce<CatalogEntity[]>((acc, page) => [...acc, page], []) ?? [],
    [catalogEntities]
  );

  const nextPageKey = useMemo(() => catalogEntities?.nextPageKey, [catalogEntities?.nextPageKey]);
  const previousPageKey = useMemo(() => {
    const result = catalogEntities?.nextPageKey ? catalogEntities.nextPageKey - RECORD_LIMIT : 0;
    return result > 0 ? result : 0;
  }, [catalogEntities]);

  const handleSearchChange = (keyword: string) => {
    searchQueryKeywordRef.current = keyword;
    setSearchKeyword(keyword);
  };

  // it has type any on codemirror too.
  // @ref /records/records-detail/controller/use-records-detail/index.tsx
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchEnter = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      setQuery(searchQueryKeywordRef.current);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleSearchRefresh = () => {
    refetch();
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (isLoading || isRefetching) {
      return;
    }
    setSortModel(newSortModel);
  };

  const handlePageChange = (nextPageKey: number) => {
    if (isLoading || isRefetching) {
      return;
    }
    setPageOffset(nextPageKey);
  };

  const disableNextPage = !nextPageKey;
  const disablePreviousPage = nextPageKey === RECORD_LIMIT;

  return {
    catalogEntity,
    catalogEntityId,
    selectedEntityType,
    searchKeyword,
    nextPageKey,
    isLoading,
    isRefetching,
    rows,
    columns,
    previousPageKey,
    disableNextPage,
    disablePreviousPage,
    onSearchChange: handleSearchChange,
    onSearchEnter: handleSearchEnter,
    onSearchRefresh: handleSearchRefresh,
    onSortModelChange: handleSortModelChange,
    onPageChange: handlePageChange,
    removeQueryParams,
    addQueryParam,
  };
};
