import {
  IntegrationAction,
  NodeUnion,
  NodeUnionTypeEnumLike,
  ParameterMappings,
  useGetNode,
  useUpdateNode,
} from '@novaera/actioner-service';
import { NvButton, NvCloseIcon, NvConditionalWrap, NvCustomExecuteButtonIcon, NvFlex } from '@novaera/core';

import { useParams } from '@novaera/route';
import { assert, noop } from '@novaera/utils';
import { useFormIdentifierContext } from '../../../../../../../providers/form-identifier-provider';

import { uniqBy } from 'lodash';
import { useCallback, useState } from 'react';
import {
  ActionParameterMapper,
  PPDrawerItem,
  PropertyPaneWrapper,
  PropertyPanelHeader,
  PropertyPanelSimpleSection,
} from '../../../../../../../components';
import { ActionOnParameterMappingsChanged } from '../../../../../../../components/parameter-mapper/types';
import { JobTargetComponent } from '../../../../../../../components/ui-components/job-target-component';
import { SlackBlocksProvider } from '../../../../../../../components/ui-components/slack-blocks-component/provider';
import { useWorkflowPermission } from '../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../controllers/use-get-workflow-contexts';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { ActionNodeProperties } from './action-node-properties';
import { SLACK_SEND_MESSAGE_CATEGORIES } from './action-node-properties/slack-send-message-parameter-mapping/constants';
import { ACTION_NODE_PROPERTIES_WRAPPER_ID } from './constants';
import { ActionNodePropertyPanelDrawerProps } from './types';

export const ActionNodePropertyPanelDrawer: React.FC<React.PropsWithChildren<ActionNodePropertyPanelDrawerProps>> = ({
  handleClose,
  workflow,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(!!selectedNode, new Error('[ActionNodePropertyPanelDrawer] - selectedNode should not be undefined!'), 'ERROR');

  const { userAppId, workflowId } = useParams();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { formId } = useFormIdentifierContext();

  const { node: actionNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.action>({
    appId: userAppId,
    workflowId,
    nodeAlias: selectedNode.id,
  });
  const { mutate: updateNode } = useUpdateNode();

  const [secondPanelOpen, setSecondPanelOpen] = useState(false);
  const [selectedSlackGroup, setSelectedSlackGroup] = useState<(typeof SLACK_SEND_MESSAGE_CATEGORIES)[0]>();
  const [thirdPanelOpen, setThirdPanelOpen] = useState(false);
  const [fourthPanelOpen, setFourthPanelOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<IntegrationAction | undefined>();

  const { hasEditPermission } = useWorkflowPermission();

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  const {
    panelProps: [first, second, third, fourth],
    panelCloseFunctions: [onFirstClose, onSecondClose, onThirdClose, onFourthClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: isFirstPanelOpen && secondPanelOpen,
        closeFn: () => {
          setSecondPanelOpen(false);
        },
      },
      {
        open: isFirstPanelOpen && secondPanelOpen && thirdPanelOpen,
        closeFn: () => {
          setThirdPanelOpen(false);
        },
      },
      {
        open: isFirstPanelOpen && fourthPanelOpen,
        closeFn: () => {
          setFourthPanelOpen(false);
        },
      },
    ],
  });

  const onParameterMappingChanged = useCallback(
    (value: ParameterMappings, shouldAddFormId: boolean) => {
      assert(!!actionNode, new Error('Node should be exits when onParameterMappingChanging'), 'ERROR');
      const { formId: formIdToRemove, ...restActionNode } = actionNode;
      updateNode({
        appId: userAppId,
        workflowId,
        nodeAlias: actionNode.alias,
        payload: {
          ...restActionNode,
          parameterMappings: value,
          ...(shouldAddFormId && { formId }),
        } as NodeUnion,
      });
    },
    [actionNode, formId, updateNode, userAppId, workflowId]
  );

  const handleOnChanged: ActionOnParameterMappingsChanged = ({
    inputParametersValues: params,
    activeInputParameterIds,
    shouldAddFormId,
  }) => {
    assert(!!actionNode, new Error('Node should be exits when its parameter handleOnChanging'), 'ERROR');
    const otherParameterMappings = actionNode?.parameterMappings?.filter(
      ({ parameterId }) => !activeInputParameterIds.includes(parameterId)
    );
    onParameterMappingChanged(uniqBy([...params, ...(otherParameterMappings ?? [])], 'parameterId'), shouldAddFormId);
  };

  return (
    <PropertyPaneWrapper id={ACTION_NODE_PROPERTIES_WRAPPER_ID} {...wrapperProps}>
      {isLoading ? (
        <NvFlex width={`430px`}>
          <PropertiesLoading />
        </NvFlex>
      ) : (
        actionNode?.type === 'action' && (
          <>
            <PPDrawerItem {...first}>
              <ActionNodeProperties
                onCloseClicked={() => {
                  onFirstClose();
                  handleClose();
                }}
                onSlackItemSelected={({ slackGroup }) => {
                  setSelectedSlackGroup(slackGroup);
                  setSecondPanelOpen(true);
                }}
                onParameterMappingChanged={hasEditPermission ? handleOnChanged : noop}
                onJobTargetParameterSelected={({ selectedAction }) => {
                  setSelectedAction(selectedAction);
                  setFourthPanelOpen(true);
                }}
                actionNode={actionNode}
                workflow={workflow}
              />
            </PPDrawerItem>
            <SlackBlocksProvider panelProps={third} panelCloseFn={onThirdClose} setPanelOpen={setThirdPanelOpen}>
              {({ setSelectedElementId }) => (
                <PPDrawerItem {...second}>
                  {!!selectedSlackGroup && (
                    <NvFlex width="100%">
                      <PropertyPanelHeader
                        icon={selectedSlackGroup.icon}
                        title={selectedSlackGroup.title}
                        actions={
                          <NvButton
                            onlyIcon
                            size="small"
                            color="secondary"
                            onClick={() => {
                              onSecondClose();
                              setSelectedElementId(undefined);
                            }}
                          >
                            <NvCloseIcon />
                          </NvButton>
                        }
                      />
                      <NvConditionalWrap
                        condition={!!selectedSlackGroup.wrapWithSimpleSection}
                        wrap={(children) => <PropertyPanelSimpleSection>{children}</PropertyPanelSimpleSection>}
                      >
                        <ActionParameterMapper
                          type="in-workflow"
                          appId={userAppId}
                          workflowId={workflowId}
                          context={workflowCodeInputContext}
                          actionId={actionNode?.actionId}
                          integrationId={actionNode?.integrationId}
                          versionNumber={actionNode?.version}
                          onParameterMappingsChanged={hasEditPermission ? handleOnChanged : noop}
                          filterInputParameters={selectedSlackGroup.filter}
                          initialParameterMappings={actionNode?.parameterMappings}
                        />
                      </NvConditionalWrap>
                    </NvFlex>
                  )}
                </PPDrawerItem>
              )}
            </SlackBlocksProvider>
            <PPDrawerItem {...fourth}>
              <NvFlex width="100%">
                <PropertyPanelHeader
                  icon={<NvCustomExecuteButtonIcon />}
                  title="Job target"
                  actions={
                    <NvButton
                      onlyIcon
                      size="small"
                      color="secondary"
                      onClick={() => {
                        onFourthClose();
                      }}
                    >
                      <NvCloseIcon />
                    </NvButton>
                  }
                />
                {selectedAction && (
                  <JobTargetComponent
                    context={workflowCodeInputContext}
                    selectedAction={selectedAction}
                    onChange={hasEditPermission ? handleOnChanged : noop}
                    actionNode={actionNode}
                    userAppId={userAppId}
                    workflowId={workflowId}
                  />
                )}
              </NvFlex>
            </PPDrawerItem>
          </>
        )
      )}
    </PropertyPaneWrapper>
  );
};
