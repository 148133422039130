import { NvAxios, useQuery } from '@novaera/core';

import { assert } from '@novaera/utils';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { UseGetGroupsResponse } from '../types';

const batchGetGroups = async (groupIds?: string[]) => {
  assert(!groupIds || groupIds?.length <= 40, new Error('Batch get can be done only for 40 items'), 'ERROR');
  const result = await NvAxios.post<UseGetGroupsResponse>(`${GROUPS_ROOT_PATH}/batch-get`, { groupIds });
  return result?.data;
};

export const useBatchGetGroups = (groupIds?: string[]) => {
  return useQuery([QUERY_KEYS_GROUPS.list(), groupIds], () => batchGetGroups(groupIds), {
    enabled: !!groupIds && groupIds.length > 0,
  });
};
