import { NvAxios, useQuery } from '@novaera/core';
import { isAxiosError } from 'axios';
import { ROOT_PATH_SSO_PROVIDERS } from '../constants';
import { QUERY_KEYS_SSO_PROVIDERS } from '../keys';
import { SSOProvider } from '../types';

type GetSSOParams = {
  organizationId?: string;
  containerType: 'organization' | 'workspace';
};

type GetSSOResponse = SSOProvider;

const getSSO = async ({ organizationId, containerType }: GetSSOParams) => {
  try {
    const result = await NvAxios.get<GetSSOResponse>(`${ROOT_PATH_SSO_PROVIDERS}/${organizationId}`, {
      params: {
        containerType,
      },
    });
    return result?.data;
  } catch (error) {
    if (isAxiosError(error) && error?.response?.status === 404) {
      return null;
    }
    throw error;
  }
};

export const useGetSSO = (params: GetSSOParams) =>
  useQuery<GetSSOResponse | null>(
    QUERY_KEYS_SSO_PROVIDERS.ssoProvider(params.organizationId, params.containerType),
    () => getSSO(params),
    {
      enabled: !!params.organizationId && !!params.containerType,
      retry(failureCount, error) {
        if (isAxiosError(error) && error?.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
    }
  );
