import ReactJSONViewer from 'react-json-view';

import { NvButton } from '../button';
import { NvContentCopyIcon } from '../icons';
import { NvTooltip } from '../tooltip';

import { useJsonViewer } from './controllers/use-json-viewer';
import { JSONViewerWrapper, NvCopyWrapper } from './styled';
import { JsonViewerProps } from './types';

export const NvReactJson: React.FC<React.PropsWithChildren<JsonViewerProps>> = ({ hideCopyIcon, ...props }) => {
  const { onCopyClicked, resetCopyState, copyText } = useJsonViewer();

  return (
    <JSONViewerWrapper>
      {!hideCopyIcon && (
        <NvCopyWrapper>
          <NvTooltip title={copyText} onClose={() => resetCopyState()}>
            <NvButton
              onlyIcon
              size="small"
              color="secondary"
              onClick={() => {
                onCopyClicked(props.src);
              }}
            >
              <NvContentCopyIcon />
            </NvButton>
          </NvTooltip>
        </NvCopyWrapper>
      )}
      <ReactJSONViewer
        // Workaround for "copy doesn't work in material modal component."
        // https://github.com/mac-s-g/react-json-view/issues/131#issuecomment-444095616
        enableClipboard={(copy) => {
          navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
        }}
        //
        name={false}
        {...props}
        style={{ padding: '16px', wordBreak: 'break-word', ...props.style }}
      />
    </JSONViewerWrapper>
  );
};
