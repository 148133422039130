import { NvFlex, NvSkeleton } from '@novaera/core';
import { AIAssistantSectionLoading } from '../section/loading';
import { AssistantPageLayoutWrapper } from '../styled';

export const AIAssistantsLoading = () => (
  <AssistantPageLayoutWrapper>
    <NvFlex gap="24px" width="100%">
      <NvSkeleton width="100%" height="48px" variant="rectangular" />
      <NvFlex width="100%" padding="0 24px" alignItems="center">
        <NvFlex width="100%" maxWidth="768px">
          <AIAssistantSectionLoading />
          <AIAssistantSectionLoading />
          <AIAssistantSectionLoading />
        </NvFlex>
      </NvFlex>
    </NvFlex>
  </AssistantPageLayoutWrapper>
);
