import {
  isMaxLength,
  isMinLength,
  isRequired,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTagsAutoComplete,
  NvTextArea,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import * as React from 'react';

import { useCreateActionModal } from './controllers';
import { CreateIntegrationActionModalValues } from './types';
import { isActionNameValid } from './utils';

export const CreateActionModal: React.FC<React.PropsWithChildren<{ isOpen: boolean; onClose: () => void }>> = ({
  isOpen,
  onClose,
}) => {
  const { isLoading, onSubmit } = useCreateActionModal({ onClose });

  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <NvForm<CreateIntegrationActionModalValues> keepDirtyOnReinitialize onSubmit={onSubmit}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Create action</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton
                onlyIcon
                size="small"
                color="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex spacing={3} direction="column">
                <NvField
                  labelVariant="h5"
                  direction="label-on-top"
                  name="name"
                  labelText="Name"
                  hasRequiredIndicator
                  validators={[
                    isRequired(),
                    isActionNameValid(),
                    isMinLength({ length: 3 }),
                    isMaxLength({ length: 150 }),
                  ]}
                  showErrorMessageOnlyWhenBlur
                  component={<NvTextField autoFocus size="medium" disabled={submitting} />}
                />
                <NvField
                  labelVariant="h5"
                  direction="label-on-top"
                  name="description"
                  labelText="Description"
                  showErrorMessageOnlyWhenBlur
                  validators={[isMaxLength({ length: 3000 })]}
                  component={<NvTextArea rows={5} />}
                />
                <NvField
                  defaultValue={[]}
                  direction="label-on-top"
                  name="tags"
                  labelText="Tags"
                  showErrorMessageOnlyWhenBlur
                  isAutoComplete
                  component={<NvTagsAutoComplete placeholder={'Add tags'} />}
                />
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" loading={isLoading} disabled={isLoading}>
                Create
              </NvButton>
              <NvButton onClick={onClose} color="ghost" disabled={isLoading}>
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
