import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CATALOG_ENTITY_TYPES_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_ENTITY_TYPES } from '../keys';
import { GetRelationshipsOfCatalogEntitiesParams, GetRelationshipsOfCatalogEntitiesResponse } from './types';

const getRelationshipsOfCatalogEntities = async ({
  entityTypeId,
  entityId,
  queryParams,
  nextPageOffset,
}: GetRelationshipsOfCatalogEntitiesParams & { nextPageOffset?: number }) => {
  const { limit = 20, relationshipDefinitionId, secondEntityTypeId } = queryParams ?? {};
  const result = await NvAxios.get<GetRelationshipsOfCatalogEntitiesResponse>(
    `${CATALOG_ENTITY_TYPES_ROOT_PATH}/${entityTypeId}/entities/${entityId}/relationships`,
    {
      params: {
        limit,
        ...(nextPageOffset ? { nextPageOffset: nextPageOffset } : {}),
        ...(relationshipDefinitionId ? { relationshipDefinitionId: relationshipDefinitionId } : {}),
        ...(secondEntityTypeId ? { secondEntityTypeId: secondEntityTypeId } : {}),
      },
    }
  );
  return result?.data;
};

export const useGetRelationshipsOfCatalogEntities = (params: GetRelationshipsOfCatalogEntitiesParams) => {
  return useInfiniteQuery<GetRelationshipsOfCatalogEntitiesResponse>(
    QUERY_KEY_CATALOG_ENTITY_TYPES.relationships({ entityTypeId: params.entityTypeId, entityId: params.entityId }),
    ({ pageParam }) => getRelationshipsOfCatalogEntities({ ...params, nextPageOffset: pageParam }),
    {
      enabled: !!params.entityId && !!params.entityTypeId,
      getNextPageParam: (lastPage) => lastPage.nextPageOffset,
    }
  );
};
