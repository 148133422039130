import { NvFlex } from '@novaera/core';
import { FC } from 'react';
import { AddNewButton } from '../../../components/add-new-button';
import { DropItemPlace } from '../../../utils/nodes-drag-and-drop/drop-item-place';
import { DroppableItem } from '../../../utils/nodes-drag-and-drop/droppable-item';
import { foreignObjectSize } from '../../constants';
import { AddNewButtonForeignObjectProps } from './types';

export const AddNewButtonForeignObject: FC<React.PropsWithChildren<AddNewButtonForeignObjectProps>> = ({
  positionX,
  positionY,
  sourceId,
  targetId,
  isDisabled,
}) => {
  return (
    <foreignObject
      width={foreignObjectSize}
      height={foreignObjectSize}
      x={positionX}
      y={positionY}
      className="edgebutton-foreignobject"
      style={{ overflow: 'visible', width: '18px', height: '18px', fillOpacity: 1 }}
      requiredExtensions="http://www.w3.org/1999/xhtml"
    >
      <DroppableItem id={{ sourceId, targetId }}>
        {({ isDragging, isDroppable }) => {
          return (
            <NvFlex margin="1px" bgcolor="transparent">
              {isDragging && isDroppable && <DropItemPlace />}
              {!(isDragging && isDroppable) && (
                <AddNewButton sourceId={sourceId} targetId={targetId} type={'Edge'} disabled={isDisabled} />
              )}
            </NvFlex>
          );
        }}
      </DroppableItem>
    </foreignObject>
  );
};
