import { NvFlex, NvForm, NvSkeleton, NvTypography } from '@novaera/core';
import React from 'react';
import { useCreateWorkspace } from './controllers/use-create-workspace';

import { CustomDivider } from '../../styled';
import { WorkspaceCreate, WorkspaceCreateForm } from '../form';
import { CREATE_NEW_WORKSPACE } from '../list/constants';
import { WorkspaceCreateResponse } from './services/use-create-workspace-service/types';
import { CreateWorkspaceButton } from './styled';

export const WorkspacePanelCreate: React.FC<
  React.PropsWithChildren<{
    username: string;
    onCreatePanelOpenClicked: () => void;
    onCancelClicked: () => void;
  }>
> = ({ username, onCreatePanelOpenClicked, onCancelClicked }) => {
  const { onSubmit, suggestedName, hasWorkspaces, isCreateFormOpened, setIsCreateFormOpened, isFetchWorkspaceLoading } =
    useCreateWorkspace({ username, reason: 'workspace-creation' });

  if (isFetchWorkspaceLoading) {
    return <NvSkeleton></NvSkeleton>;
  }

  if (hasWorkspaces) {
    return (
      <>
        {!isCreateFormOpened && (
          <>
            <NvFlex sx={{ marginTop: '40px' }}>
              <CustomDivider />
            </NvFlex>
            <NvFlex sx={{ marginTop: '17px' }}>
              <CreateWorkspaceButton
                onClick={() => {
                  setIsCreateFormOpened(true);
                  onCreatePanelOpenClicked();
                }}
              >
                <NvTypography variant="h4">{CREATE_NEW_WORKSPACE}</NvTypography>
              </CreateWorkspaceButton>
            </NvFlex>
          </>
        )}
        {isCreateFormOpened && (
          <WorkspaceCreate
            suggestedName={suggestedName}
            onCancelClicked={() => {
              setIsCreateFormOpened(false);
              onCancelClicked();
            }}
            onSubmit={onSubmit}
          />
        )}
      </>
    );
  }

  return (
    <NvForm<WorkspaceCreateResponse> onSubmit={onSubmit} initialValues={{ name: suggestedName }}>
      <WorkspaceCreateForm />
    </NvForm>
  );
};
