import { DocumentTitle } from '@novaera/core';
import { FC, PropsWithChildren } from 'react';

export interface ActionerDocumentTitleProps {
  title: string;
}

export const ActionerDocumentTitle: FC<PropsWithChildren<ActionerDocumentTitleProps>> = ({ title, children }) => {
  return (
    <>
      <DocumentTitle title={title} defaultTitle={'Actioner'}></DocumentTitle>
      {children}
    </>
  );
};
