import { ActionState, UIComponentType, useGetIntegrationAction } from '@novaera/actioner-service';
import { FC, useMemo } from 'react';
import { useInputValuesContext } from '../../../action-designer/providers/input-values';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../../../action-designer/providers/input-values/utils';
import { useFormIdentifierContext } from '../../../providers/form-identifier-provider';
import { DynamicInputProvider } from '../providers/action-dynamic-input-provider';
import { DynamicInputWrapperProps } from './types';

export const DynamicInputWrapper: FC<DynamicInputWrapperProps> = ({
  actionId,
  integrationId,
  versionNumber,
  storeDynamicFormParametersPermanently = false,
  children,
  ...rest
}) => {
  const { inputValues } = useInputValuesContext();

  const { formId } = useFormIdentifierContext();

  const { data: integrationAction } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: versionNumber,
  });

  const { dynamicInputParameterIds, notDynamicInputParameterIds } = useMemo(
    () => ({
      dynamicInputParameterIds:
        integrationAction?.inputParameters
          ?.filter((inputParameter) => {
            return inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT;
          })
          .map((ip) => ip.id) ?? [],
      notDynamicInputParameterIds:
        integrationAction?.inputParameters
          ?.filter((i) => i.uiComponent.type !== UIComponentType.DYNAMIC_INPUT)
          .map((i) => i.id) ?? [],
    }),
    [integrationAction?.inputParameters]
  );

  const values = formatAndFilterInputFormValuesAsParameterMappings(inputValues, notDynamicInputParameterIds);

  return (
    <DynamicInputProvider
      draft={integrationAction?.state === ActionState.DRAFT}
      formId={formId}
      actionId={actionId}
      integrationId={integrationId}
      inputParameterIds={dynamicInputParameterIds}
      allInputParameterIdsWithOrder={integrationAction?.inputParameters?.map((i) => i.id) ?? []}
      inputParameterValues={values}
      initialInputParameterIdsShowingDynamicInput={dynamicInputParameterIds}
      versionNumber={versionNumber}
      storeDynamicFormParametersPermanently={storeDynamicFormParametersPermanently}
      {...rest}
    >
      {(value) => {
        return typeof children === 'function' ? children(value) : children;
      }}
    </DynamicInputProvider>
  );
};
