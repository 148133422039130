import { SLACK_WORKSPACE_KEYS } from '@novaera/ah-common';
import { LAST_ACTIVE_WORKSPACE, ROUTE_DEFAULTS } from '@novaera/constants';
import { NvAxios, isAxiosError, useQuery, useToast } from '@novaera/core';
import { useNavigate, useQueryParams } from '@novaera/route';
import { SUBDOMAIN_HEADER, setAccessToken } from '@novaera/service';
import { assert, switchToWorkspaceWithDelay } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { isString } from 'lodash';
import { useCreateUserSession } from '../../use-create-user-session';
import { SLACK_AUTH_ACCESS } from './keys';
import {
  SlackAuthAccessParams,
  SlackAuthAnonymousAccessEnterpriseGridResponse,
  SlackAuthAnonymousAccessResponse,
  SlackAuthAnonymousAccessWorkspaceSelectionResponse,
} from './types';
import {
  isSlackAuthAccessAnonymous,
  isSlackAuthEnterpriseGridResponse,
  isSlackAuthWorkspaceSelectionResponse,
} from './utils';

const slackAuthAccess = async (params: SlackAuthAccessParams) => {
  const { isAnonymous, ...otherParams } = params;
  const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);
  const endpoint = isAnonymous ? '/v1/slack/connections/anonymous/access' : '/v1/slack/connections/access';
  const result = await NvAxios.post<
    | SlackAuthAnonymousAccessResponse
    | SlackAuthAnonymousAccessWorkspaceSelectionResponse
    | SlackAuthAnonymousAccessEnterpriseGridResponse
  >(
    endpoint,
    otherParams,
    lastActiveWorkspace
      ? {
          headers: { [SUBDOMAIN_HEADER]: lastActiveWorkspace },
        }
      : undefined
  );
  return result.data;
};

export const useSlackAuthAccess = (
  params: SlackAuthAccessParams,
  {
    enabled,
  }: {
    enabled: boolean;
  }
) => {
  const cache = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { removeQueryParams } = useQueryParams();
  const { mutate: createUserSession } = useCreateUserSession();
  return useQuery(SLACK_AUTH_ACCESS.detail(params), () => slackAuthAccess(params), {
    enabled: enabled,
    retry: () => {
      return false;
    },
    onSuccess: (data) => {
      cache.invalidateQueries(SLACK_WORKSPACE_KEYS.all);
      if (!data) {
        return;
      }
      removeQueryParams(['code', 'redirected', 'state']);
      if (
        isSlackAuthAccessAnonymous(data) ||
        isSlackAuthWorkspaceSelectionResponse(data) ||
        isSlackAuthEnterpriseGridResponse(data)
      ) {
        if (data.status === 'all-set') {
          if (data.defaultSlackApp) {
            navigate('/workspace/slack');
          } else {
            if (data.jwt) {
              createUserSession(
                {
                  productId: 'actioner',
                  jwt: data.jwt,
                },
                {
                  onSuccess: (result) => {
                    setAccessToken(result.accessToken);
                    switchToWorkspaceWithDelay(
                      1000,
                      data.workspaceSubdomain,
                      data.appId ? `/my-apps/${data.appId}/setup` : undefined
                    );
                  },
                }
              );
            } else {
              switchToWorkspaceWithDelay(
                1000,
                data.workspaceSubdomain,
                data.appId ? `/my-apps/${data.appId}/setup` : undefined
              );
            }
          }
        } else if (data.status === 'enterprise-installation-result') {
          window.location.href = data.installation.slackAdministrationUrl;
        } else {
          navigate(ROUTE_DEFAULTS.SLACK_WORKSPACES, {
            state: {
              ...data,
            },
          });
        }
      } else {
        assert(false, new Error(`Unexpected response from Slack Auth, Response: ${JSON.stringify(data)}`), 'ERROR');
      }
    },
    onError: (error) => {
      removeQueryParams(['code', 'redirected', 'state']);
      if (isAxiosError(error)) {
        addToast(
          isString(error.response?.data) ? error.response?.data : 'An error ocurred while getting Slack auth access',
          {
            variant: 'error',
          }
        );
        params.handleError(error.response?.status ? error.response?.status : 0);
      }
    },
  });
};
