import { NvFlex, NvSkeleton } from '@novaera/core';
import {
  PropertyPanelHeaderCard,
  PropertyPanelHeaderCardActions,
  PropertyPanelHeaderCardBody,
  PropertyPanelHeaderIconBox,
} from '../styled';

export const PropertyPanelHeaderLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <PropertyPanelHeaderCard>
    <PropertyPanelHeaderCardBody>
      <PropertyPanelHeaderIconBox>
        <NvSkeleton variant="rectangular" width="32px" height="32px" />
      </PropertyPanelHeaderIconBox>

      <NvFlex flex="1 1 auto" minWidth={0} gap="4px">
        <NvSkeleton variant="rectangular" height="12px" width="30px" />
        <NvSkeleton variant="rectangular" height="16px" width="60px" />
      </NvFlex>
    </PropertyPanelHeaderCardBody>

    <PropertyPanelHeaderCardActions>
      <NvSkeleton variant="rectangular" width="24px" height="24px" />
    </PropertyPanelHeaderCardActions>
  </PropertyPanelHeaderCard>
);
