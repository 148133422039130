import { LAST_ACTIVE_WORKSPACE } from '@novaera/constants';
import { useGetCustomAppsStart } from '@novaera/identity-provider';
import { getSubdomain } from '@novaera/utils';
import Cookies from 'js-cookie';
import { STATE_DELIMITER } from '../../../../../workspace/slack-workspace/common/constants';
import { getSlackRedirectUri } from '../../../../../workspace/slack-workspace/common/utils';

export const useStartMarketplaceSlackAppInstallation = ({
  isAnonymous,
  schemaId,
}: {
  isAnonymous: boolean;
  schemaId?: string;
}) => {
  const { data, isSuccess } = useGetCustomAppsStart({ isAnonymous, appSchemaId: schemaId });
  const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);
  const subdomain = getSubdomain() || lastActiveWorkspace;

  const slackAuthUrl = data
    ? isSuccess
      ? `${data.slackAuthorizationUrl}&state=${subdomain}${STATE_DELIMITER}${data.triggerId}${STATE_DELIMITER}${
          isAnonymous ?? false
        }&redirect_uri=${getSlackRedirectUri()}`
      : data.slackAuthorizationUrl
    : undefined;

  return { slackAuthUrl };
};
