import { assert } from '@novaera/utils';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export type SearchAsYouTypeValuesMap = Record<string, string>;
export type SearchAsYouTypeValuesContextType = {
  searchAsYouTypeValues?: SearchAsYouTypeValuesMap;
  setSearchAsYouTypeValues: Dispatch<SetStateAction<SearchAsYouTypeValuesMap>>;
};

const SearchAsYouTypeValuesContext = createContext<SearchAsYouTypeValuesContextType | undefined>(undefined);

export const SearchAsYouTypeValuesProvider: FC<{
  children: ((params: SearchAsYouTypeValuesContextType) => ReactNode) | ReactNode;
}> = ({ children }) => {
  const [searchAsYouTypeValues, setSearchAsYouTypeValues] = useState<SearchAsYouTypeValuesMap>({});

  const debouncedSearchAsYouType = useDebouncedCallback(setSearchAsYouTypeValues, 500);

  const value = { searchAsYouTypeValues, setSearchAsYouTypeValues: debouncedSearchAsYouType };

  return (
    <SearchAsYouTypeValuesContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </SearchAsYouTypeValuesContext.Provider>
  );
};

export const useSearchAsYouTypeValuesContext = () => {
  const context = useContext(SearchAsYouTypeValuesContext);
  assert(
    !!context,
    new Error(`useSearchAsYouTypeValuesContext should be used within SearchAsYouTypeValuesProvider`),
    'ERROR'
  );

  return context;
};
