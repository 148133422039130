import { JobType } from '@novaera/actioner-service';
import {
  isRequired,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvCustomDateTime,
  NvCustomEventAdd,
  NvDialogSlots,
  NvDialogV1,
  NvEventRepeatIcon,
  NvField,
  NvFlex,
  NvForm,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import arrayMutators from 'final-form-arrays';
import { JobTarget } from '../components';
import { useSaveJobModal } from '../controllers/use-save-job-modal';
import { ConfigureRecurrence } from './configure-recurrence';
import { ConfigureScheduledTime } from './configure-scheduled-time';
import { SaveJobModalLoading } from './save-job-modal-loading';
import { SaveJobModalFieldsCard } from './styled';
import { TimezonesField } from './timezones';
import { SaveJobFormValues, SaveJobModalProps } from './types';

export const SaveJobModal: React.FC<React.PropsWithChildren<SaveJobModalProps>> = ({ open, type, id, handleClose }) => {
  const {
    handleSubmit,
    initialValues,
    isJobDetailLoading,
    isEditMode,
    timezones,
    userAppId,
    cronExpressionError,
    handleCronErrorStateChange,
  } = useSaveJobModal({
    id,
    type,
    handleClose,
  });

  return (
    <NvDialogV1
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth
    >
      {isJobDetailLoading ? (
        <SaveJobModalLoading />
      ) : (
        <NvForm<SaveJobFormValues>
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({ submitting, hasValidationErrors }) => {
            return (
              <NvDialogSlots>
                <NvDialogSlots.Slot name="icon">
                  {type === JobType.RECURRING ? <NvEventRepeatIcon /> : <NvCustomEventAdd />}
                </NvDialogSlots.Slot>
                <NvDialogSlots.Slot name="title">
                  <NvTypography variant="h2">
                    {isEditMode ? 'Edit' : 'Add'} {type} job
                  </NvTypography>
                </NvDialogSlots.Slot>
                <NvDialogSlots.Slot name="content">
                  <NvFlex gap="16px">
                    <NvBox width="350px">
                      <NvField
                        name="name"
                        labelText="Job name"
                        {...(type === JobType.RECURRING
                          ? {
                              hasRequiredIndicator: true,
                              showErrorMessageOnlyWhenBlur: true,
                              validators: [isRequired()],
                            }
                          : {})}
                        component={
                          <NvTextField
                            placeholder={`Enter the ${type === JobType.RECURRING ? 'recurring' : 'scheduled'} job name`}
                          />
                        }
                      />
                    </NvBox>

                    <SaveJobModalFieldsCard>
                      <NvFlex direction="row" gap="4px" alignItems="center" width="100%">
                        <NvCustomDateTime sx={{ width: '16px', height: '16px' }} />
                        <NvTypography variant="h5" flex="1 1 auto">
                          {type === JobType.RECURRING ? 'Recurrence time' : 'Scheduled time'}
                        </NvTypography>
                        {type === JobType.RECURRING && (
                          <NvBox flex="0 0 auto">
                            <TimezonesField timezones={timezones} compact />
                          </NvBox>
                        )}
                      </NvFlex>
                      {type === JobType.RECURRING ? (
                        <ConfigureRecurrence onCronErrorStateChange={handleCronErrorStateChange} />
                      ) : (
                        <ConfigureScheduledTime timezones={timezones} />
                      )}
                    </SaveJobModalFieldsCard>

                    <SaveJobModalFieldsCard>
                      <JobTarget appId={userAppId} />
                    </SaveJobModalFieldsCard>
                  </NvFlex>
                </NvDialogSlots.Slot>
                <NvDialogSlots.Slot name="actions">
                  <NvButton
                    type="submit"
                    loading={submitting}
                    disabled={submitting || hasValidationErrors || !!cronExpressionError}
                  >
                    {isEditMode ? 'Save' : 'Add'}
                  </NvButton>

                  <NvButton disabled={submitting} color="ghost" onClick={handleClose}>
                    Cancel
                  </NvButton>
                </NvDialogSlots.Slot>
                <NvDialogSlots.Slot name="closeButton">
                  <NvButton onlyIcon disabled={submitting} color="secondary" onClick={handleClose} size="small">
                    <NvCloseIcon />
                  </NvButton>
                </NvDialogSlots.Slot>
              </NvDialogSlots>
            );
          }}
        </NvForm>
      )}
    </NvDialogV1>
  );
};
