import { NvField, NvFlex, NvImage, NvLoginIcon, NvSkeleton, NvTypography } from '@novaera/core';
import React from 'react';
import { getProviderButtonContent } from '../../../controllers/use-sign-in/utils';
import { SignUpAgreement } from '../../sign-up-agreement';
import { EMAIL_PLACEHOLDER, SIGN_IN_TITLE } from '../constants';
import {
  ContinueWithEmailButtonWrapper,
  CustomDivider,
  SocialButton,
  SocialLoginFlex,
  TitleFlex,
  WelcomeMessageFlex,
} from '../styled';
import { Line, NvTextFieldWrapper, NvTypographyWrapper, Separator } from './styled';
import { WelcomeProps } from './types';

export const Welcome: React.FC<React.PropsWithChildren<WelcomeProps>> = ({
  onProviderButtonClick,
  identityProviders,
  isLoading,
  error,
  fromSignUp,
  isIdentityProvidersLoading,
}) => {
  return (
    <>
      <TitleFlex>
        <NvTypography variant="h1">{fromSignUp ? 'Get started with Actioner' : SIGN_IN_TITLE}</NvTypography>
      </TitleFlex>
      {fromSignUp && (
        <WelcomeMessageFlex>
          <NvTypography variant="body1">
            Actioner is a no-code app builder that lets you connect all your work apps through actions. Let's start!
          </NvTypography>
        </WelcomeMessageFlex>
      )}
      <SocialLoginFlex>
        {isIdentityProvidersLoading && (
          <NvFlex>
            <NvSkeleton width="200px" />
            <NvSkeleton width="150px" />
          </NvFlex>
        )}
        {!isIdentityProvidersLoading &&
          identityProviders?.map((provider, index) => (
            <SocialButton
              type="button"
              key={`${provider}-identity-provider-button-${index}`}
              onClick={() => onProviderButtonClick(provider)}
            >
              <NvImage
                src={provider.logoUrl}
                alt={provider.providerName}
                hasNoElevation
                size="small"
                FallBack={<span></span>}
              />
              <NvTypography sx={{ marginLeft: '8px' }} variant="h4">
                {getProviderButtonContent(provider.providerName, Boolean(fromSignUp))}
              </NvTypography>
            </SocialButton>
          ))}
      </SocialLoginFlex>
      <NvFlex sx={{ marginTop: '32px' }}>
        <Separator>
          <NvFlex>
            <NvTypographyWrapper variant="h4">
              {fromSignUp ? 'E-mail' : 'Or, enter your email address'}
            </NvTypographyWrapper>
          </NvFlex>
          <Line />
        </Separator>
      </NvFlex>

      <NvFlex sx={{ paddingBottom: '16px', paddingTop: '24px' }}>
        <NvField
          name="username"
          error={error ? 'Please check your email address' : undefined}
          component={<NvTextFieldWrapper size="large" placeholder={EMAIL_PLACEHOLDER} startIcon={<NvLoginIcon />} />}
        />
      </NvFlex>
      <NvFlex>
        <ContinueWithEmailButtonWrapper
          type="submit"
          size="large"
          loading={isLoading}
          isFitContent
          labelProps={{
            variant: 'h3',
          }}
        >
          {fromSignUp ? 'Sign up with email' : 'Continue with email'}
        </ContinueWithEmailButtonWrapper>
      </NvFlex>
      <NvFlex marginTop={'24px'} marginBottom={'24px'}>
        <CustomDivider />
      </NvFlex>
      <SignUpAgreement />
    </>
  );
};
