import { ValueSchema, ValueTypes } from '@novaera/actioner-service';
import { DateTimeFormat, SchemaType } from '@novaera/ah-common';
import { assert } from '@novaera/utils';

export const listValueSchemaToValue: (
  schema: ValueSchema
) => ValueTypes.STRING | ValueTypes.NUMBER | ValueTypes.OBJECT | ValueTypes.BOOLEAN = (schema: ValueSchema) => {
  switch (schema.type) {
    case SchemaType.string:
    case SchemaType.email:
    case SchemaType.phone:
    case SchemaType.url:
    case SchemaType.uuid:
      return ValueTypes.STRING;
    case SchemaType.integer:
    case SchemaType.float:
      return ValueTypes.NUMBER;
    case SchemaType.map:
      return ValueTypes.OBJECT;
    case SchemaType.boolean:
      return ValueTypes.BOOLEAN;
    case SchemaType.dateTime: {
      if (schema.format === DateTimeFormat.utc) {
        return ValueTypes.NUMBER;
      } else {
        // schema.format === DateTimeFormat.iso_8601;
        return ValueTypes.STRING;
      }
    }
    case SchemaType.recordReference: {
      return ValueTypes.STRING;
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      assert(false, new Error(`Unmapped schema type: ${(schema as any).type}`), 'ERROR');
    }
  }
};
