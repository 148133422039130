import { AuthenticationField } from '@novaera/actioner-service';
import { NvDialogModal } from '@novaera/core';
import { useModalState } from './controllers/use-modal-state';
import { ModalBody } from './modal-body';

export const AddAuthenticationFieldModal = () => {
  const { isOpened, onClosed, onFormSubmit, initialValues } = useModalState();
  return (
    <NvDialogModal<AuthenticationField>
      maxWidth="md"
      fullWidth
      open={isOpened}
      onCloseButtonClicked={onClosed}
      Header={`${initialValues ? 'Update new authentication field' : 'Add new authentication field'}`}
      Body={<ModalBody />}
      primaryButtonText={`${initialValues ? 'Update' : 'Add'}`}
      onFormSubmit={onFormSubmit}
      formProps={{
        initialValues,
      }}
    />
  );
};
