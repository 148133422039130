import CodeMirror, { Editor } from 'codemirror';
import React, { useEffect, useRef } from 'react';
export type DomEvent = (editor: CodeMirror.Editor, event?: FocusEvent) => void;

export type CodeMirrorWrapperProps = {
  className?: string;
  editorDidMount?: (editor: CodeMirror.Editor, value: string, cb: () => void) => void;
  onBeforeChange?: (editor: CodeMirror.Editor, data: CodeMirror.EditorChange, value: string) => void;
  onBlur?: (editor: CodeMirror.Editor, event?: FocusEvent) => void;
  onFocus?: (editor: CodeMirror.Editor, event?: FocusEvent) => void;
  onKeyUp?: (editor: CodeMirror.Editor, event: KeyboardEvent) => void;
  onKeyDown?: (editor: CodeMirror.Editor, event?: KeyboardEvent) => void;
  onUpdate?: (editor: CodeMirror.Editor) => void;
  onChange?: (editor: CodeMirror.Editor, data: CodeMirror.EditorChange, value: string) => void;
  options?: CodeMirror.EditorConfiguration;
  value?: string;
};

export const CodeMirrorWrapper: React.FC<CodeMirrorWrapperProps> = ({
  onBeforeChange,
  className,
  editorDidMount,
  onBlur,
  onFocus,
  onKeyUp,
  onUpdate,
  onKeyDown,
  onChange,
  options,
  value = '',
}) => {
  const codeMirrorContainerRef = useRef<HTMLTextAreaElement>(null);
  const codeMirrorInstanceRef = useRef<CodeMirror.EditorFromTextArea | null>(null);

  // effect's order is matter. so do not move its position from top of other effects
  useEffect(() => {
    if (codeMirrorContainerRef.current && !codeMirrorInstanceRef.current) {
      codeMirrorInstanceRef.current = CodeMirror.fromTextArea(codeMirrorContainerRef.current, {
        ...options,
      });
      codeMirrorInstanceRef.current.setValue(value);

      const codeMirrorInstance = codeMirrorInstanceRef.current;

      editorDidMount?.(codeMirrorInstance, value, () => {
        codeMirrorInstance.refresh();
        if (value) {
          codeMirrorInstance.setValue(value);
        }
        codeMirrorInstance.focus();
      });
    }
  }, [editorDidMount, options, value]);

  useEffect(() => {
    const codeMirrorInstance = codeMirrorInstanceRef.current;
    if (codeMirrorInstance) {
      const onKeyUpHandler = (editor: Editor, event: KeyboardEvent) => {
        if (onKeyUp) {
          onKeyUp(editor, event);
        }
      };
      codeMirrorInstance.on('keyup', onKeyUpHandler);

      const onFocusHandler = (editor: Editor, event: FocusEvent) => {
        if (onFocus) {
          onFocus(editor, event);
        }
      };
      codeMirrorInstance.on('focus', onFocusHandler);

      const onBlurHandler = (editor: Editor, event: FocusEvent) => {
        if (onBlur) {
          onBlur(editor, event);
        }
      };
      codeMirrorInstance.on('blur', onBlurHandler);

      const onKeyDownHandler = (editor: Editor, event: KeyboardEvent) => {
        if (onKeyDown) {
          onKeyDown(editor, event);
        }
      };
      codeMirrorInstance.on('keydown', onKeyDownHandler);

      const onUpdateHandler = (editor: Editor) => {
        if (onUpdate) {
          onUpdate(editor);
        }
      };
      codeMirrorInstance.on('update', onUpdateHandler);

      const onBeforeChangeHandler = (editor: Editor, data: CodeMirror.EditorChange) => {
        if (onBeforeChange) {
          onBeforeChange(editor, data, editor.getValue());
        }
      };
      codeMirrorInstance.on('beforeChange', onBeforeChangeHandler);
      return () => {
        if (codeMirrorInstance) {
          codeMirrorInstance.off('keyup', onKeyUpHandler);
          codeMirrorInstance.off('focus', onFocusHandler);
          codeMirrorInstance.off('blur', onBlurHandler);
          codeMirrorInstance.off('keydown', onKeyDownHandler);
          codeMirrorInstance.off('update', onUpdateHandler);
          codeMirrorInstance.off('beforeChange', onBeforeChangeHandler);
        }
      };
    }
    return;
  }, [onBeforeChange, onBlur, onChange, onFocus, onKeyDown, onKeyUp, onUpdate]);

  useEffect(() => {
    const codeMirrorInstance = codeMirrorInstanceRef.current;
    if (codeMirrorInstance) {
      const newOnChangeHandler = (editor: Editor, data: CodeMirror.EditorChange) => {
        if (onChange) {
          const getValue = editor.getValue();
          onChange(editor, data, getValue);
        }
      };

      codeMirrorInstance.on('change', newOnChangeHandler);

      return () => {
        codeMirrorInstance.off('change', newOnChangeHandler);
      };
    }

    return;
  }, [onChange]);

  useEffect(() => {
    if (codeMirrorInstanceRef.current && codeMirrorInstanceRef.current.getValue() !== value) {
      codeMirrorInstanceRef.current.setValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (codeMirrorInstanceRef.current && options) {
      codeMirrorInstanceRef.current.setOption('lint', options.lint);
      codeMirrorInstanceRef.current.setOption('readOnly', options.readOnly);
    }
  }, [options]);

  return (
    <div className={className} data-testid="code-mirror-wrapper">
      <textarea ref={codeMirrorContainerRef}></textarea>
    </div>
  );
};
