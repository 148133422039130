import { LogoUploadServiceType, useLogoUpload as useLogoUploadService } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useState } from 'react';

import { LogoUploadProps } from '../../types';

export const useLogoUpload = ({ initialLogoUrl }: Pick<LogoUploadProps, 'type'> & { initialLogoUrl?: string }) => {
  const { mutateAsync: uploadFile, isLoading: isUploadLoading } = useLogoUploadService();

  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(initialLogoUrl);

  const handleLogoUpload = async ({ file, type }: { file: File; type: LogoUploadServiceType }) => {
    const { logoId } = await uploadFile({
      type,
      logo: file,
    });
    return new Promise<{ logoId: string; imagePreviewUrl?: string }>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;

        assert(
          typeof base64data === 'string',
          new Error(`base64Data should be string but it is ${typeof base64data}`),
          'WARNING'
        );

        setImagePreviewUrl(base64data);
        resolve({ logoId, imagePreviewUrl: base64data });
      };
    });
  };

  return {
    isUploadLoading,
    logoUrl: imagePreviewUrl,
    setImagePreviewUrl,
    onLogoUpload: handleLogoUpload,
  };
};
