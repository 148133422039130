import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { QUERY_KEYS_EXECUTION_RESULT, QUERY_KEYS_WORKFLOWS } from '../keys';
import { QUERY_KEYS_NODE } from '../node/keys';
import { QUERY_KEYS_EXECUTION_RESULT_DETAIL_PARAMS } from '../types';
import { UserWorkflowExecutionResultResponse } from '../use-get-workflow-execution-result/types';
import { SearchWorkflowsParams } from '../use-search-workflows/types';

export const generatedWorkflowSearchKey = (params: SearchWorkflowsParams) => {
  const { keyword, limit, appIds, fields, disabled, sort, from, integrationUsed, triggerTypes } = params;
  // If we want to add new parameter for search workflow, we need get this from params and add orderGuaranteedParams object.
  const orderGuaranteedParams = {
    ...(keyword ? { keyword } : {}),
    ...(limit ? { limit } : {}),
    ...(appIds ? { appIds } : {}),
    ...(fields ? { fields } : {}),
    ...(disabled ? { disabled } : {}),
    ...(sort ? { sort } : {}),
    ...(from ? { from } : {}),
    ...(integrationUsed ? { integrationUsed } : {}),
    ...(triggerTypes ? { triggerTypes } : {}),
  };
  const searchWorkflowParamsKeys = Object.keys(orderGuaranteedParams) as Array<keyof typeof orderGuaranteedParams>;
  const queryStringToCache =
    searchWorkflowParamsKeys.length > 0
      ? searchWorkflowParamsKeys.reduce(
          (acc, cur) => `${acc}${acc.length > 0 ? '&' : ''}${cur}="${orderGuaranteedParams[cur]}"`,
          ''
        )
      : '';

  return queryStringToCache;
};

export const useWorkflowServiceUtils = () => {
  const cache = useQueryClient();

  const handleGetExecutionResultFromCache = useCallback(
    ({ executionId, workflowId }: QUERY_KEYS_EXECUTION_RESULT_DETAIL_PARAMS) => {
      return cache.getQueryData<UserWorkflowExecutionResultResponse>(
        QUERY_KEYS_EXECUTION_RESULT.detail({ workflowId, executionId })
      );
    },
    []
  );

  const handleInvalidationWorkflowCache = useCallback(
    ({ workflowId, appId }: { appId: string; workflowId: string }) => {
      cache.invalidateQueries(QUERY_KEYS_WORKFLOWS.detail({ workflowId, appId }));
    },
    []
  );

  const handleInvalidateNodeCache = useCallback(
    ({ workflowId, appId, nodeAlias }: { appId: string; workflowId: string; nodeAlias?: string }) => {
      cache.invalidateQueries(QUERY_KEYS_NODE.detail({ workflowId, appId, nodeAlias }));
    },
    []
  );

  return {
    getExecutionResultFromCache: handleGetExecutionResultFromCache,
    invalidateWorkflowCache: handleInvalidationWorkflowCache,
    invalidateNodeCache: handleInvalidateNodeCache,
  };
};
