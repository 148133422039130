import { NvAxios } from '@novaera/core';
import { useMutation } from '@novaera/core';
import { OnSignInClickProps } from '../../common/types';
import { CheckRegistrationResponse } from './types';

const checkRegistration = async (props: OnSignInClickProps) => {
  const result = await NvAxios.post<CheckRegistrationResponse>('/v1/users/signup/check-registration', {
    ...props,
  });
  return result.data;
};

export const useCheckRegistration = () => {
  return useMutation((props: OnSignInClickProps) => checkRegistration(props));
};
