import { LabelValue } from '@novaera/core';
export enum OPERATIONS_VALUE {
  INFINITE = 'infinite',
  ARRAY_ITERATION = 'array-iteration',
}
export const OPERATIONS: LabelValue<OPERATIONS_VALUE>[] = [
  { label: 'Infinite', value: OPERATIONS_VALUE.INFINITE },
  { label: 'Array iteration', value: OPERATIONS_VALUE.ARRAY_ITERATION },
];
export const CONDITION_FIELD_NAME = 'breakCondition';
