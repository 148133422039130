import { NvBox, NvRouterLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const BaseItemCard = styled(NvRouterLink)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  transition: border-color 300ms ease-in-out;
  cursor: pointer;
  overflow: hidden;

  .base-item-actions {
    opacity: 0;
    transition: opacity 150ms ease-in-out, margin 200ms ease-in-out;
    margin-right: -32px;
  }

  &.is-base-item-actions-menu-open,
  :hover {
    border-color: ${({ theme }) => theme.palette.nv_main[40]};

    .base-item-actions {
      opacity: 1;
      margin-right: 0;
    }
  }

  &.is-base-item-disabled {
    pointer-events: none;
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};

    &.is-base-item-actions-menu-open,
    :hover {
      border-color: ${({ theme }) => theme.palette.nv_neutral[20]};
    }
  }
`;

export const BaseItemTag = styled(NvBox)`
  display: flex;
  padding: 0px 8px;
  height: 20px;
  background: ${({ theme }) => theme.palette.nv_neutral[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
  max-width: 300px;
`;
