import { ResourceUsageDetail, Subscription } from '@novaera/actioner-service';
import { ColoredChipColor, NvChip, NvDivider, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { formatDistanceToNowStrict } from 'date-fns';
import { TrialCard, UsageWrapper } from './styled';

export const TrialInformationCard = ({
  subscription,
  resourceUsageDetails,
}: {
  subscription: Subscription;
  resourceUsageDetails: ResourceUsageDetail[];
}) => {
  const theme = useTheme();

  return (
    <>
      <TrialCard gap={'24px'} flexDirection={'row'}>
        <NvFlex gap={'8px'}>
          <NvTypography variant="h2" color={theme.palette.nv_neutral[0]}>
            You're in the Trial Plan
          </NvTypography>
          <NvTypography variant="body1" color={theme.palette.nv_neutral[0]}>
            You can upgrade to a paid plan any time. At the end of the trial period, you will automatically transition
            to the free plan.
          </NvTypography>
          <UsageWrapper>
            <NvTypography variant="h4" color={theme.palette.nv_neutral[0]}>
              Usage:
            </NvTypography>
            <NvTypography variant="body1" color={theme.palette.nv_neutral[0]}>
              <strong>
                {Intl.NumberFormat().format(resourceUsageDetails?.[0].used)} of{' '}
                {Intl.NumberFormat().format(resourceUsageDetails?.[0].limit)}
              </strong>{' '}
              credits during the Trial period.
            </NvTypography>
          </UsageWrapper>
        </NvFlex>
        <NvFlex>
          <NvChip
            label={<strong>{`${formatDistanceToNowStrict(new Date(subscription.periodEnd))} left`}</strong>}
            color={ColoredChipColor.LIGHT_BLUE_GRAY}
            sx={{
              textTransform: 'none',
            }}
          />
        </NvFlex>
      </TrialCard>
      <NvDivider />
    </>
  );
};
