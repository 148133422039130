import { NvMenuItem } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PopupMenuItemWrapper = styled(NvMenuItem)`
  &.MuiMenuItem-root {
    padding: 6px 12px;
    border-radius: 6px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
    }

    &:active {
      background: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }
  }
`;
