import { ROUTES } from '../common/routes';
import { AccountSettings } from './account';
import { ProfileConnections } from './profile-connections';

import { Workspaces } from './workspaces';

const PROFILE_SETTINGS_LINK = ROUTES.ProfileSettings;

export const PROFILE_SETTINGS_TAB_PATHS = {
  PROFILE: '/profile',
  CONNECTIONS: '/connections',
  WORKSPACES: '/workspaces',
};

export const PROFILE_SETTING_TABS = [
  {
    label: 'My profile',
    content: <AccountSettings />,
    path: `${PROFILE_SETTINGS_LINK}${PROFILE_SETTINGS_TAB_PATHS.PROFILE}`,
  },
  {
    label: 'My connections',
    content: <ProfileConnections />,
    path: `${PROFILE_SETTINGS_LINK}${PROFILE_SETTINGS_TAB_PATHS.CONNECTIONS}`,
  },
  {
    label: 'My workspaces',
    content: <Workspaces />,
    path: `${PROFILE_SETTINGS_LINK}${PROFILE_SETTINGS_TAB_PATHS.WORKSPACES}`,
  },
];

export const DEFAULT_ROOT_PATH = PROFILE_SETTING_TABS[0].path;
