import { ActionState, useGetIntegration, useGetIntegrationActions } from '@novaera/actioner-service';
import { useLocation, useParams } from '@novaera/route';
import { FC } from 'react';
import {
  SideMenuSubMenuItem,
  SideMenuSubMenuItemLabel,
  SideMenuSubMenuList,
} from '../../../../components/side-menu/styled';
import { INTEGRATION_ACTION_DETAIL } from '../../../constants';
import { DraftLabel } from '../../draft-label';
import { SideMenuItemsEmptyState } from '../side-menu-items-empty-state';
import { SideMenuListItemsLoading } from '../side-menu-items-loading';

export const SideMenuActions: FC<React.PropsWithChildren<unknown>> = () => {
  const { integrationId } = useParams();
  const { pathname } = useLocation();
  const { data: integrationData } = useGetIntegration({ id: integrationId });

  const { data, isLoading } = useGetIntegrationActions({
    integrationId,
    version: integrationData?.latestVersion.number,
    includeAll: true,
  });

  return (
    <SideMenuSubMenuList>
      {isLoading ? (
        <SideMenuListItemsLoading />
      ) : data?.actions && data?.actions.length === 0 ? (
        <SideMenuItemsEmptyState label="This integration doesn’t have any actions yet." />
      ) : (
        data?.actions.map((action) => (
          <SideMenuSubMenuItem
            selected={pathname.includes(action.id)}
            key={`side_menu_action_${action.id}`}
            to={INTEGRATION_ACTION_DETAIL(integrationId, action.id)}
          >
            <SideMenuSubMenuItemLabel variant="h5">{action.name}</SideMenuSubMenuItemLabel>
            {action.state === ActionState.DRAFT && <DraftLabel />}
          </SideMenuSubMenuItem>
        ))
      )}
    </SideMenuSubMenuList>
  );
};
