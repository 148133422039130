import { InputParameter, ResponseConfiguration, RunConfiguration } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { FormApi } from 'final-form';
import { FC, MutableRefObject, createContext, useContext, useRef } from 'react';
import { FunctionsFormValues } from '../../components/functions-tab/types';
import { RequestsFormValues } from '../../components/request-tab/types';

const ActionDesignerFormApiContext = createContext<
  | {
      functionsFormApiRef: MutableRefObject<FormApi<FunctionsFormValues, Partial<FunctionsFormValues>> | null>;
      requestsFormApiRef: MutableRefObject<FormApi<RequestsFormValues, Partial<RequestsFormValues>> | null>;
      responseFormApiRef: MutableRefObject<FormApi<ResponseConfiguration, Partial<ResponseConfiguration>> | null>;
      inputPropertiesFormApiRef: MutableRefObject<FormApi<
        InputParameter | RunConfiguration,
        Partial<InputParameter | RunConfiguration>
      > | null>;
    }
  | undefined
>(undefined);

export const ActionDesignerFormApiProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const functionsFormApiRef = useRef<FormApi<FunctionsFormValues, Partial<FunctionsFormValues>> | null>(null);
  const requestsFormApiRef = useRef<FormApi<RequestsFormValues, Partial<RequestsFormValues>> | null>(null);
  const responseFormApiRef = useRef<FormApi<ResponseConfiguration, Partial<ResponseConfiguration>> | null>(null);
  const inputPropertiesFormApiRef = useRef<FormApi<
    InputParameter | RunConfiguration,
    Partial<InputParameter | RunConfiguration>
  > | null>(null);
  const value = { functionsFormApiRef, requestsFormApiRef, responseFormApiRef, inputPropertiesFormApiRef };

  return <ActionDesignerFormApiContext.Provider value={value}>{children}</ActionDesignerFormApiContext.Provider>;
};

export const useActionDesignerFormApiContext = () => {
  const context = useContext(ActionDesignerFormApiContext);
  assert(
    !!context,
    new Error(`useActionDesignerFormApiContext should be used within ActionDesignerFormApiProvider`),
    'ERROR'
  );

  return context;
};
