export const QUERY_KEYS_CONFIGS = {
  all: ['app-configs'] as const,
  list: (appId?: string) => [...QUERY_KEYS_CONFIGS.all, 'list', ...(appId ? [appId] : [])] as const,
  listAllWithProperties: (appId?: string) =>
    [...QUERY_KEYS_CONFIGS.all, 'list-all-with-properties', ...(appId ? [appId] : [])] as const,
  detail: (appId?: string, configId?: string) =>
    [...QUERY_KEYS_CONFIGS.all, 'detail', ...(appId ? [appId] : []), ...(configId ? [configId] : [])] as const,
  versions: (appId?: string, configId?: string) =>
    [...QUERY_KEYS_CONFIGS.all, 'versions', ...(appId ? [appId] : []), ...(configId ? [configId] : [])] as const,
  version: (appId?: string, configId?: string, version?: string) =>
    [
      ...QUERY_KEYS_CONFIGS.all,
      'version',
      ...(appId ? [appId] : []),
      ...(configId ? [configId] : []),
      ...(version ? [version] : []),
    ] as const,
};

export const QUERY_KEY_CONFIG_VALIDATION = {
  all: ['app-configs-validation'] as const,
  list: (appId: string) => [...QUERY_KEY_CONFIG_VALIDATION.all, 'list', appId] as const,
  detail: (appId: string, id: string) => [...QUERY_KEY_CONFIG_VALIDATION.list(appId), 'detail', id] as const,
};

export const QUERY_KEY_ADHOC_CONFIG_VALIDATION = {
  all: ['adhoc-config-validation'] as const,
  detail: (appId?: string, schemaId?: string, properties?: string) =>
    [...QUERY_KEY_CONFIG_VALIDATION.all, 'detail', appId, schemaId, properties] as const,
};
