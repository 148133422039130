import {
  FieldSchema,
  NumberValue,
  ParameterTypes,
  SimpleParameterMapping,
  StringValue,
  ValueTypes,
} from '@novaera/actioner-service';
import { DateTimeFormat, SchemaType } from '@novaera/ah-common';
import { assert } from '@novaera/utils';
import { parseISO } from 'date-fns';
import { isDate } from 'lodash';
import { useCallback } from 'react';

export const useDateTimePickerFieldAdapter = ({
  extractValue = false,
  hidden,
}: {
  extractValue?: boolean;
  hidden: boolean;
}) => {
  const formatValue = useCallback((value: NumberValue | undefined | null) => {
    if (value?.value && typeof value.value === 'number') {
      return value.value ? new Date(value.value) : undefined;
    } else if (value?.value && typeof value.value === 'string') {
      return value.value ? parseISO(value.value) : undefined;
    } else {
      return null;
    }
  }, []);

  const parseValue = useCallback(
    ({ schema }: { schema?: FieldSchema; id: string }) =>
      (value: Date | null) => {
        if (value) {
          if (isDate(value)) {
            assert(
              schema?.type === SchemaType.dateTime,
              new Error('Datetime object should have a schema of datetime'),
              'ERROR'
            );
            if (schema.format === DateTimeFormat.utc) {
              const constructedValue: NumberValue = {
                type: ValueTypes.NUMBER,
                value: value.getTime(),
                displayValue: value.toISOString(),
              };
              return constructedValue;
            } else {
              // schema.format === DateTimeFormat.iso_8601
              const constructedValue: StringValue = {
                type: ValueTypes.STRING,
                value: value.toISOString(),
                displayValue: value.toISOString(),
              };
              return constructedValue;
            }
          }
          return undefined;
        } else {
          return undefined;
        }
      },
    []
  );
  const format = useCallback(
    (parameterMapping: SimpleParameterMapping<NumberValue | undefined | null>) => {
      if (parameterMapping?.value) {
        return formatValue(parameterMapping?.value);
      } else {
        return null;
      }
    },
    [formatValue]
  );

  const parse = useCallback(
    ({ schema, id }: { schema?: FieldSchema; id: string }) =>
      (value: Date | null): SimpleParameterMapping<StringValue | NumberValue> | undefined => {
        const result = parseValue({ schema, id })(value);
        if (result || hidden) {
          return { parameterId: id, type: ParameterTypes.SIMPLE, hidden, ...(result && { value: result }) };
        } else {
          return undefined;
        }
      },
    [hidden, parseValue]
  );

  return { format: extractValue ? formatValue : format, parse: extractValue ? parseValue : parse };
};
