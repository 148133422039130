import { NvButton } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NvMoreButtonStyled = styled(NvButton)`
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 200ms ease, transform 200ms ease;
  pointer-events: none;

  &.MuiButtonBase-root.is-shown {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0px);
  }

  &.MuiButtonBase-root.new-class {
    &.NvButton-onlyIcon {
      padding: 0;
      font-size: 12px;
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
    &.MuiButton-iconSizeSmall {
      width: 16px;
      height: 16px;
    }

    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};

    &.Mui-focusVisible,
    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    }
    ${({ theme }) => theme.elevations.e200};
  }
`;
