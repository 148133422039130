import { useGetConnectionService } from '@novaera/actioner-service';
import {
  NvAutocomplete,
  NvAutocompleteRenderInputParams,
  NvBox,
  NvCustomEmptyIcon,
  NvFlex,
  NvImage,
  NvPowerRoundedIcon,
  NvSkeleton,
  NvSwitch,
  NvTextField,
  NvTypography,
  SectionMessage,
  useNvDialogModalState,
} from '@novaera/core';
import { isUndefined, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { PropertyPanelSection } from '../../../../../../../components';
import { AddButton } from '../../../../../../../components/button/add';
import { ConnectModal } from '../../../../../../../integrations/components/connect-modal';
import {
  isOAuth2PromiseResult,
  isOAuth2SuccessPromiseResult,
} from '../../../../../../../integrations/components/connect-modal/types';
import { AutoCompleteConnectionType } from '../../../../../../../integrations/components/connection-schema-modal/autocomplete-connection-type';
import { useGlobalConnectionSchemasController } from '../../../../../../../integrations/components/connection-schema-modal/controllers/use-global-connection-schemas';
import { useWorkflowPermission } from '../../../../../../user-app-permission-boundary/use-workflow-permission';
import { DEFAULT_LABEL, READONLY_LABEL } from './constants';
import { useWorkflowConnectionSectionController } from './controllers/use-workflow-connection-section';
import { ConnectionSelectMenu, ConnectionSelectOption } from './styled';
import { ConnectionOption, ConnectionSectionProps, isConnectionOption } from './types';

export const ConnectionSection = ({
  label,
  onConnectionChange,
  connectionIdValue,
  workflow,
  ...rest
}: ConnectionSectionProps) => {
  const { connectionOptions, isConnectionsLoading, isSlackConnection } = useWorkflowConnectionSectionController({
    workflow,
    ...rest,
  });

  const { data: selectedConnection, isInitialLoading: isSelectedConnectionLoading } = useGetConnectionService({
    connectionId: connectionIdValue,
  });

  const { isLoading, connectionSchemas } = useGlobalConnectionSchemasController({
    enabled: rest.type === 'schema-selection-included',
    addCustomConnectionSchema: false,
  });

  const extendedConnectionOptions: ConnectionOption[] = useMemo(
    () =>
      selectedConnection
        ? uniqBy(
            [
              ...connectionOptions,
              {
                label: selectedConnection.name,
                type: 'connection',
                value: { connectionId: selectedConnection.id, user: selectedConnection.user },
              },
            ],
            (c) => c.value.connectionId
          )
        : connectionOptions,
    [connectionOptions, selectedConnection]
  );

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const { newConnectionOptions, isConnectionFound } = useMemo(() => {
    let isConnectionFound: 'found' | 'not-found' | 'loading' | 'no-connection';
    if (isConnectionsLoading || isSelectedConnectionLoading) {
      return { isConnectionFound: 'loading' };
    }

    if (connectionIdValue) {
      const found = extendedConnectionOptions.some(
        (connectionOption) => connectionOption.value.connectionId === connectionIdValue
      );
      if (!found) {
        const newConnectionOption: ConnectionOption = {
          label: 'Deleted connection',
          type: 'connection',
          value: { connectionId: connectionIdValue, user: undefined },
        };
        isConnectionFound = 'not-found';
        return { newConnectionOptions: [...extendedConnectionOptions, newConnectionOption], isConnectionFound };
      }
      isConnectionFound = 'found';
      return { newConnectionOptions: extendedConnectionOptions, isConnectionFound };
    } else {
      return { newConnectionOptions: extendedConnectionOptions, isConnectionFound: 'no-connection' };
    }
  }, [extendedConnectionOptions, isConnectionsLoading, isSelectedConnectionLoading, connectionIdValue]);

  const selectedConnectionOption = useMemo(() => {
    return newConnectionOptions?.find((connectionOption) => connectionOption.value.connectionId === connectionIdValue);
  }, [newConnectionOptions, connectionIdValue]);
  const { hasEditPermission } = useWorkflowPermission();
  const [enableConnectionUsage, setEnableConnectionUsage] = useState<boolean>(() => {
    if (rest.type === 'schema-selection-included') {
      return !!rest.connectionSchemaIdValue || !!connectionIdValue;
    } else {
      return !!connectionIdValue;
    }
  });

  return (
    <>
      <PropertyPanelSection
        title="Connection"
        icon={<NvPowerRoundedIcon />}
        tooltip={'Connection'}
        {...(rest.type === 'schema-selection-included'
          ? {
              actions: (
                <NvSwitch
                  variant="compact"
                  checked={enableConnectionUsage}
                  onChange={(_event, checked) => {
                    if (!checked) {
                      rest.onConnectionSchemaIdChange(undefined);
                    }
                    setEnableConnectionUsage(checked);
                  }}
                />
              ),
            }
          : {})}
      >
        {((rest.type === 'schema-selection-included' && enableConnectionUsage) ||
          rest.type === 'schema-selected-with-integration') && (
          <NvFlex gap="8px">
            {rest.type === 'schema-selection-included' && (
              <AutoCompleteConnectionType
                isLoading={isLoading}
                options={connectionSchemas}
                disableClearable
                renderInputProps={{ size: 'small', fullWidth: false, sx: { width: '300px', paddingBottom: '8px' } }}
                value={connectionSchemas.find((c) => c.id === rest.connectionSchemaIdValue) ?? null}
                onChange={(_event, value) => {
                  return rest.onConnectionSchemaIdChange(value?.id);
                }}
              />
            )}
            {((rest.type === 'schema-selection-included' && rest.connectionSchemaIdValue) ||
              rest.type === 'schema-selected-with-integration') && (
              <>
                <NvBox>
                  <NvTypography variant="body2" textColor="secondary" display="inline">
                    {label || (hasEditPermission ? DEFAULT_LABEL : READONLY_LABEL)}
                  </NvTypography>
                </NvBox>
                {isConnectionFound === 'loading' || !newConnectionOptions ? (
                  <NvSkeleton variant="rectangular" width="100%" height="32px" />
                ) : (
                  <NvAutocomplete<ConnectionOption, boolean, boolean, boolean>
                    options={newConnectionOptions}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                    disabled={!hasEditPermission}
                    placeholder="Select a connection"
                    disableClearable={isUndefined(selectedConnectionOption?.value.connectionId)}
                    noOptionsText={
                      <NvFlex flexDirection={'column'} gap="8px" alignItems={'center'}>
                        <NvTypography variant="body2">
                          You don't have any{' '}
                          <b>
                            {rest.type === 'schema-selected-with-integration'
                              ? rest.integration?.name
                              : connectionSchemas.find((c) => c.id === rest.connectionSchemaIdValue)?.name}
                          </b>{' '}
                          connections yet.
                        </NvTypography>
                        <AddButton
                          onClick={onModalOpenClicked}
                          label={isSlackConnection ? 'Add new Slack workspace' : 'Add connection'}
                          size="small"
                        />
                      </NvFlex>
                    }
                    renderInput={(params: NvAutocompleteRenderInputParams) => (
                      <NvTextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        startIcon={
                          selectedConnectionOption?.type === 'triggered-user-connection' ? (
                            <NvPowerRoundedIcon sx={{ width: '16px', height: '16px' }} />
                          ) : selectedConnectionOption?.type === 'connection' ? (
                            <NvImage
                              FallBack={
                                selectedConnectionOption.value.user ? (
                                  <NvPowerRoundedIcon sx={{ width: '16px', height: '16px' }} />
                                ) : (
                                  <NvCustomEmptyIcon sx={{ width: '16px', height: '16px' }} />
                                )
                              }
                              src={selectedConnectionOption.value.user?.logoUrl}
                              size="xsmall"
                            />
                          ) : (
                            <NvPowerRoundedIcon />
                          )
                        }
                        size="small"
                      />
                    )}
                    {...(newConnectionOptions.length === 1 &&
                    newConnectionOptions[0].type === 'triggered-user-connection'
                      ? {
                          PaperComponent: () => {
                            return (
                              <ConnectionSelectMenu sx={{ padding: '16px', marginTop: '8px' }}>
                                <NvFlex flexDirection={'column'} gap="8px" alignItems={'center'} padding={'12px 0px'}>
                                  <NvTypography variant="body2">
                                    You don't have any{' '}
                                    <b>
                                      {rest.type === 'schema-selected-with-integration'
                                        ? rest.integration?.name
                                        : connectionSchemas.find((c) => c.id === rest.connectionSchemaIdValue)?.name}
                                    </b>{' '}
                                    connections yet.
                                  </NvTypography>
                                  <AddButton
                                    onClick={onModalOpenClicked}
                                    label={isSlackConnection ? 'Add new Slack workspace' : 'Add connection'}
                                    size="small"
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </NvFlex>
                              </ConnectionSelectMenu>
                            );
                          },
                        }
                      : {})}
                    onChange={(_, v) => {
                      if (isConnectionOption(v)) {
                        onConnectionChange({
                          connectionId: v?.value.connectionId,
                        });
                      } else {
                        onConnectionChange({
                          connectionId: undefined,
                        });
                      }
                    }}
                    isOptionEqualToValue={(option, _) => {
                      return option?.value.connectionId === connectionIdValue;
                    }}
                    value={
                      !isConnectionsLoading
                        ? newConnectionOptions.find(
                            (connection) =>
                              connection?.value.connectionId === connectionIdValue ||
                              (connection.type === 'triggered-user-connection' && connectionIdValue === '')
                          ) ?? null
                        : null
                    }
                    renderOption={(props, option, state) => (
                      <ConnectionSelectOption {...props}>
                        {!option.value.connectionId ? (
                          <NvPowerRoundedIcon sx={{ width: '16px', height: '16px' }} />
                        ) : (
                          <NvImage
                            FallBack={
                              option.value.user ? (
                                <NvPowerRoundedIcon sx={{ width: '16px', height: '16px' }} />
                              ) : (
                                <NvCustomEmptyIcon sx={{ width: '16px', height: '16px' }} />
                              )
                            }
                            src={option.value.user?.logoUrl}
                            size="xsmall"
                          />
                        )}

                        <NvTypography variant="body1">{option.label}</NvTypography>
                      </ConnectionSelectOption>
                    )}
                  />
                )}

                {isConnectionFound === 'not-found' && (
                  <SectionMessage message={'Connection removed. You need to set a new one.'} variant={'error'} />
                )}
              </>
            )}
          </NvFlex>
        )}
      </PropertyPanelSection>

      {isOpened && (
        <ConnectModal
          mode={'Connect'}
          onModalCloseClicked={onModalCloseClicked}
          isOpened={isOpened}
          integrationId={rest.type === 'schema-selected-with-integration' ? rest.integration?.id : undefined}
          schemaId={
            rest.type === 'schema-selection-included'
              ? rest.connectionSchemaIdValue
              : rest.integration?.connectionSchemaId
          }
          modalTitle={'Connect'}
          onFormSubmitFinished={(connection) => {
            //for failed response, connection is undefined
            if (!connection) {
              return;
            }

            if (isOAuth2PromiseResult(connection)) {
              if (isOAuth2SuccessPromiseResult(connection)) {
                onConnectionChange({
                  connectionId: connection.connectionId,
                });
              }
            } else {
              onConnectionChange({
                connectionId: connection.id,
              });
            }
          }}
        />
      )}
    </>
  );
};
