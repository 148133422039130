import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { useEventRuleCacheUpdateHelper } from '../utils';
import { RollbackIntegrationEventRuleParams } from './types';

const rollbackIntegrationEventRule = async (params: RollbackIntegrationEventRuleParams) => {
  const { integrationId, id } = params;
  const result = await NvAxios.post(`${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}/rollback`);
  return result?.data;
};

export const useRollbackIntegrationEventRule = () => {
  const { updatePersistedEventRuleCache, replaceEventRuleFromListCache } = useEventRuleCacheUpdateHelper();
  return useMutation(rollbackIntegrationEventRule, {
    onSuccess: (eventRule, { integrationId, id }) => {
      updatePersistedEventRuleCache({ eventRule, integrationId, id });
      replaceEventRuleFromListCache({ eventRule, integrationId });
    },
  });
};
