import { JobNode, WorkflowState, useUpdateNode } from '@novaera/actioner-service';
import { NvForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import { noop } from '@novaera/utils';
import { PropsWithChildren } from 'react';
import { DeleteJobFormValues, SaveRecurringJobFormValues, SaveScheduledJobFormValues } from './types';

export const JobPropertyPanelForm: React.FC<PropsWithChildren<{ jobNode: JobNode }>> = ({ jobNode, children }) => {
  const { userAppId, workflowId } = useParams();
  const { mutate: updateNodeDetail } = useUpdateNode();

  if (jobNode.operation.type === 'saveRecurring') {
    return (
      <NvForm<SaveRecurringJobFormValues>
        onSubmit={noop}
        onChange={({ values }) => {
          const newNode: JobNode = {
            alias: jobNode.alias,
            name: jobNode.name,
            operation: { type: 'saveRecurring', ...values.operation },
            type: 'job',
            jobId: values.jobId,
            state: WorkflowState.DRAFT,
          };

          updateNodeDetail({
            appId: userAppId,
            workflowId,
            nodeAlias: jobNode.alias,
            payload: newNode,
          });
        }}
        initialValues={{ jobId: jobNode.jobId, operation: jobNode.operation }}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        {children}
      </NvForm>
    );
  } else if (jobNode.operation.type === 'saveScheduled') {
    return (
      <NvForm<SaveScheduledJobFormValues>
        onSubmit={noop}
        onChange={({ values }) => {
          const newNode: JobNode = {
            alias: jobNode.alias,
            name: jobNode.name,
            operation: { type: 'saveScheduled', ...values.operation },
            type: 'job',
            jobId: values.jobId,
            state: WorkflowState.DRAFT,
          };

          updateNodeDetail({
            appId: userAppId,
            workflowId,
            nodeAlias: jobNode.alias,
            payload: newNode,
          });
        }}
        initialValues={{ jobId: jobNode.jobId, operation: jobNode.operation }}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        {children}
      </NvForm>
    );
  } else if (jobNode.operation.type === 'delete') {
    return (
      <NvForm<DeleteJobFormValues>
        onSubmit={noop}
        onChange={({ values }) => {
          const newNode: JobNode = {
            alias: jobNode.alias,
            name: jobNode.name,
            operation: { type: 'delete' },
            type: 'job',
            jobId: values.jobId,
            state: WorkflowState.DRAFT,
          };

          updateNodeDetail({
            appId: userAppId,
            workflowId,
            nodeAlias: jobNode.alias,
            payload: newNode,
          });
        }}
        initialValues={{ jobId: jobNode.jobId }}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        {children}
      </NvForm>
    );
  } else {
    return children;
  }
};
