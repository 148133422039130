import { useFetchProfile, useGetOrganizationMapping } from '@novaera/actioner-service';
import { HeaderWithTabLayout, NvSettingsIcon } from '@novaera/core';
import { useMemo } from 'react';
import { WORKSPACE_SETTINGS_TABS, WorkspaceSettingsTab } from './constants';

export const WorkflowSettings = () => {
  const { data: profile } = useFetchProfile();

  const { data: organizationMapping, isLoading: isOrganizationLoading } = useGetOrganizationMapping({
    workspaceId: profile?.workspaceId,
  });

  const tabs = useMemo(() => {
    if (isOrganizationLoading) {
      return WORKSPACE_SETTINGS_TABS.filter((tab) => {
        if (tab.type !== 'organization') {
          return tab;
        } else {
          const loadingTab: WorkspaceSettingsTab = {
            ...tab,
            content: null,
          };
          return loadingTab;
        }
      });
    }
    if (!organizationMapping || organizationMapping.mappingType === 'member') {
      return WORKSPACE_SETTINGS_TABS.filter((tab) => tab.type !== 'organization');
    }
    return WORKSPACE_SETTINGS_TABS;
  }, [isOrganizationLoading, organizationMapping]);

  return (
    <HeaderWithTabLayout
      title={{
        label: 'Workspace Settings',
        icon: <NvSettingsIcon />,
      }}
      tabs={tabs}
      variant="center-aligned"
      loading={isOrganizationLoading}
    />
  );
};
