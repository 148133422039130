export function NvClockIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.0249 24.5239C54.0126 26.8534 53.2341 28.5351 51.9984 29.432L51.43 29.7684L48.2296 31.612C48.6868 31.2383 49.0822 30.7525 49.3911 30.1296C49.836 29.2701 50.0707 28.1614 50.0954 26.8284C50.1078 25.3336 49.8112 23.7515 49.2799 22.1819L53.3453 20.3008C53.8025 21.721 54.0497 23.166 54.0249 24.5363V24.5239Z"
        fill="#455068"
      />
      <path
        d="M48.2172 32.2349C48.0195 32.2349 47.8218 32.1352 47.7106 31.9608C47.5252 31.6992 47.5746 31.3255 47.8341 31.1262C48.2419 30.7898 48.5755 30.3663 48.8227 29.8556C49.2305 29.0458 49.4405 28.0368 49.4652 26.8284C49.4776 25.4581 49.2181 23.9633 48.6868 22.3812C48.5879 22.0822 48.7238 21.7459 49.008 21.6213L53.0735 19.7403C53.2341 19.6655 53.4195 19.6655 53.5801 19.7403C53.7407 19.815 53.8643 19.952 53.9137 20.114C54.408 21.6338 54.6428 23.1286 54.6304 24.5488C54.6181 26.9904 53.8025 28.9088 52.3444 29.9552L48.5261 32.1601C48.4273 32.21 48.3284 32.2473 48.2172 32.2473V32.2349ZM50.0213 22.5182C50.4785 24.038 50.7133 25.4831 50.7009 26.8409C50.7009 27.9371 50.5279 28.9088 50.219 29.7434L51.6895 28.8963C52.7645 28.124 53.3947 26.5544 53.4071 24.5238C53.4071 23.4525 53.2588 22.3189 52.9499 21.1604L50.0213 22.5182Z"
        fill="#455068"
      />
      <path
        d="M53.333 20.2884L49.2675 22.1694C47.9948 18.3949 45.338 14.7698 42.2735 12.9884C41.149 12.3282 40.074 11.9794 39.0978 11.9171C38.3564 11.8797 37.6891 11.9918 37.096 12.2659L40.5559 10.2354L40.9019 10.036C42.2859 9.31353 44.1641 9.47547 46.2154 10.6838C49.4035 12.54 52.1467 16.3768 53.3453 20.2884H53.333Z"
        fill="white"
      />
      <path
        d="M49.2675 22.8047C49.181 22.8047 49.1069 22.7923 49.0204 22.7549C48.8598 22.6802 48.7362 22.5556 48.6744 22.3812C47.414 18.644 44.782 15.1809 41.9522 13.5365C40.9266 12.9386 39.9628 12.6022 39.0607 12.5524C38.4182 12.515 37.8497 12.6147 37.3555 12.8389C37.0589 12.976 36.7005 12.8514 36.5523 12.5649C36.404 12.2659 36.5028 11.9046 36.787 11.7427L40.5806 9.51285C42.2488 8.6533 44.3371 8.87753 46.5119 10.1606C49.7371 12.0417 52.641 15.9532 53.9137 20.114C54.0002 20.4129 53.8643 20.7368 53.5801 20.8614L49.5147 22.7425C49.4282 22.7798 49.3417 22.8047 49.2552 22.8047H49.2675ZM39.8268 11.3939C40.7165 11.5558 41.631 11.9046 42.5701 12.4652C45.5234 14.1843 48.1801 17.5353 49.6135 21.3348L52.5668 19.9645C51.3064 16.2771 48.7238 12.8888 45.8941 11.2319C44.09 10.1731 42.4218 9.94885 41.1737 10.5966L39.8268 11.3939Z"
        fill="#455068"
      />
      <path
        d="M50.0831 26.8284C50.0707 28.1489 49.8236 29.2576 49.3788 30.1296C49.0822 30.7525 48.6744 31.2383 48.2172 31.612C48.106 31.7117 47.9701 31.7989 47.8589 31.8612C46.4131 32.7705 44.3989 32.6709 42.1747 31.3379C37.8992 28.7842 34.4516 22.6677 34.4516 17.635C34.4516 15.2556 35.2054 13.549 36.4658 12.6521L37.0342 12.3033C37.0342 12.3033 37.0589 12.2783 37.096 12.2783C37.6768 12.0043 38.3564 11.8922 39.0978 11.9295C40.074 11.9918 41.1367 12.3282 42.2735 13.0009C45.338 14.7823 47.9948 18.4198 49.2675 22.1819C49.7989 23.7515 50.0955 25.3336 50.0831 26.8284Z"
        fill="white"
      />
      <path
        d="M45.7335 33.082C44.5472 33.082 43.225 32.6833 41.8657 31.8736C37.442 29.2327 33.8337 22.8421 33.8337 17.635C33.8337 15.1311 34.6369 13.1877 36.1074 12.1413L36.7129 11.7676C36.7129 11.7676 36.787 11.7302 36.8241 11.7053C37.5037 11.3939 38.2946 11.2569 39.1225 11.3067C40.2223 11.369 41.3838 11.7551 42.5701 12.4528C45.684 14.2591 48.4767 17.909 49.836 21.9826C50.4167 23.7017 50.7009 25.3336 50.6886 26.8409C50.6762 28.2486 50.4167 29.4569 49.9224 30.4161C49.6012 31.0764 49.1563 31.6494 48.6003 32.0978C48.4767 32.21 48.3037 32.3345 48.1307 32.4093C47.414 32.8577 46.5984 33.082 45.7211 33.082H45.7335ZM37.3431 12.8389L36.787 13.1753C35.6996 13.9476 35.0694 15.5422 35.0694 17.6225C35.0694 22.4435 38.4058 28.3482 42.4959 30.7898C44.4483 31.9484 46.2401 32.1352 47.5376 31.313C47.6611 31.2508 47.7476 31.1885 47.8094 31.1262C48.2419 30.7774 48.5755 30.3414 48.8227 29.8431C49.2305 29.0334 49.4405 28.0243 49.4652 26.816C49.4776 25.4457 49.2181 23.9508 48.6868 22.3687C47.4264 18.6316 44.7943 15.1684 41.9646 13.5241C40.939 12.9261 39.9751 12.5898 39.0731 12.54C38.4305 12.5026 37.8621 12.6022 37.3678 12.8265L37.3431 12.8389Z"
        fill="#455068"
      />
      <path d="M39.9875 55.094L45.3875 49.4509L33.6608 74.7516L31.2512 73.4186L39.9875 55.094Z" fill="white" />
      <path
        d="M33.6608 75.3744C33.5619 75.3744 33.4507 75.3495 33.3642 75.2997L30.9546 73.9667C30.6704 73.8048 30.5592 73.4435 30.6951 73.157L39.4314 54.8449C39.4314 54.8449 39.4932 54.7327 39.5427 54.6829L44.9426 49.0398C45.1527 48.8156 45.4863 48.7906 45.7335 48.965C45.9806 49.1394 46.0671 49.4633 45.9435 49.7374L34.2045 75.0381C34.1303 75.1876 33.9944 75.3121 33.8338 75.362C33.772 75.3869 33.7102 75.3869 33.6484 75.3869L33.6608 75.3744ZM32.0667 73.157L33.3766 73.892L43.2621 52.5901L40.5065 55.4677L32.0667 73.1695V73.157Z"
        fill="#455068"
      />
      <path d="M45.3875 49.4509L47.1422 51.8551L37.3184 72.2352L33.6608 74.7516L45.3875 49.4509Z" fill="#455068" />
      <path
        d="M33.6608 75.3744C33.5125 75.3744 33.3766 75.3246 33.2653 75.2249C33.0553 75.0381 32.9935 74.7391 33.1047 74.49L44.8438 49.1893C44.9426 48.99 45.128 48.8529 45.3381 48.828C45.5728 48.8031 45.7705 48.9028 45.8941 49.0772L47.6364 51.4814C47.7724 51.6683 47.7971 51.9174 47.6982 52.1167L37.8868 72.4843C37.8374 72.584 37.7633 72.6712 37.6768 72.721L34.0191 75.2374C33.9079 75.3121 33.7967 75.3495 33.6731 75.3495L33.6608 75.3744ZM45.5111 50.6717L35.1807 72.9577L36.8365 71.8116L46.4131 51.9299L45.4987 50.6841L45.5111 50.6717Z"
        fill="#455068"
      />
      <path
        d="M14.2604 74.1536C14.0503 74.1536 13.8403 74.0415 13.729 73.8422C13.556 73.5432 13.6549 73.1695 13.9515 72.9951L26.3331 65.7948C26.6297 65.6204 27.0004 65.7201 27.1734 66.019C27.3464 66.318 27.2475 66.6917 26.951 66.8661L14.5693 74.0664C14.4705 74.1287 14.3592 74.1536 14.2604 74.1536Z"
        fill="#B1B6C0"
      />
      <path
        d="M13.6178 85.9134C13.4077 85.9134 13.1977 85.8013 13.0865 85.602C12.9135 85.303 13.0123 84.9293 13.3089 84.7549L25.6905 77.5546C25.9871 77.3802 26.3578 77.4798 26.5308 77.7788C26.7038 78.0778 26.6049 78.4515 26.3084 78.6259L13.9267 85.8262C13.8279 85.8885 13.7167 85.9134 13.6178 85.9134Z"
        fill="#B1B6C0"
      />
      <path
        d="M26.1972 110.99C25.9871 110.99 25.777 110.878 25.6658 110.678C25.4928 110.379 25.5917 110.006 25.8882 109.831L48.3778 96.7386C48.6744 96.5642 49.0451 96.6639 49.2181 96.9629C49.3911 97.2619 49.2922 97.6356 48.9957 97.81L26.5061 110.903C26.4072 110.965 26.296 110.99 26.1972 110.99Z"
        fill="#B1B6C0"
      />
      <path
        d="M17.8068 105.11C17.5968 105.11 17.3867 104.998 17.2755 104.798C17.1025 104.499 17.2014 104.126 17.4979 103.951L27.9766 97.8473C28.2732 97.6729 28.6439 97.7725 28.8169 98.0715C28.9899 98.3705 28.891 98.7442 28.5944 98.9186L18.1158 105.023C18.0169 105.085 17.9057 105.11 17.8068 105.11Z"
        fill="#B1B6C0"
      />
      <path
        d="M13.6178 97.1872C13.4077 97.1872 13.1977 97.0751 13.0865 96.8758C12.9135 96.5768 13.0123 96.2031 13.3089 96.0287L20.1423 92.0548C20.4388 91.8804 20.8095 91.9801 20.9825 92.279C21.1555 92.578 21.0567 92.9517 20.7601 93.1261L13.9267 97.1C13.8279 97.1623 13.7167 97.1872 13.6178 97.1872Z"
        fill="#B1B6C0"
      />
      <path
        d="M74.0556 110.554C73.8455 110.554 73.6354 110.442 73.5242 110.242C73.3512 109.943 73.4501 109.57 73.7466 109.395L80.58 105.421C80.8766 105.247 81.2473 105.347 81.4203 105.645C81.5933 105.944 81.4944 106.318 81.1979 106.493L74.3645 110.466C74.2656 110.529 74.1544 110.554 74.0556 110.554Z"
        fill="#B1B6C0"
      />
      <path
        d="M78.7883 95.5427C78.5658 95.5427 78.3558 95.4306 78.2446 95.2188C78.0839 94.9198 78.1951 94.5337 78.4917 94.3717L99.9433 82.5622C100.24 82.4003 100.611 82.5124 100.784 82.8114C100.944 83.1103 100.833 83.4965 100.536 83.6585L79.0848 95.4679C78.986 95.5178 78.8871 95.5427 78.7883 95.5427Z"
        fill="#B1B6C0"
      />
      <path
        d="M95.7914 93.9732C95.5813 93.9732 95.3713 93.8611 95.2601 93.6618C95.0871 93.3628 95.1859 92.9891 95.4825 92.8147L107.061 86.0753C107.357 85.9009 107.728 86.0006 107.901 86.2995C108.074 86.5985 107.975 86.9722 107.679 87.1466L96.1003 93.886C96.0015 93.9483 95.8903 93.9732 95.7914 93.9732Z"
        fill="#B1B6C0"
      />
      <path
        d="M86.0418 79.9339C85.8317 79.9339 85.6216 79.8218 85.5104 79.6225C85.3374 79.3235 85.4363 78.9498 85.7328 78.7754L97.3113 72.036C97.6078 71.8616 97.9786 71.9613 98.1516 72.2602C98.3245 72.5592 98.2257 72.9329 97.9291 73.1073L86.3507 79.8467C86.2518 79.909 86.1406 79.9339 86.0418 79.9339Z"
        fill="#B1B6C0"
      />
      <path
        d="M93.7525 70.9398C93.703 78.3145 91.2687 83.6337 87.3392 86.4615L85.5351 87.508L75.4272 93.3379C76.8729 92.1545 78.1333 90.6098 79.0848 88.654C80.4811 85.9259 81.2473 82.4254 81.2967 78.2273C81.3461 73.4935 80.3946 68.4857 78.7264 63.5277L91.59 57.5607C93.0358 62.0702 93.8019 66.6171 93.7525 70.9398Z"
        fill="#455068"
      />
      <path
        d="M75.4272 93.9483C75.2294 93.9483 75.0317 93.8487 74.9205 93.6743C74.7352 93.4127 74.7846 93.039 75.0441 92.8397C76.5022 91.6438 77.6761 90.1489 78.5411 88.3675C79.9251 85.6643 80.6418 82.251 80.6912 78.2148C80.7283 73.7302 79.8756 68.8594 78.1457 63.727C78.0468 63.4281 78.1827 63.0917 78.467 62.9672L91.3305 57.0001C91.4912 56.9254 91.6765 56.9254 91.8371 57.0001C91.9978 57.0749 92.1214 57.2119 92.1708 57.3739C93.6783 62.0578 94.4074 66.6296 94.3703 70.9523C94.3209 78.2273 91.9607 83.9203 87.6976 86.9723L75.7237 93.8736C75.6249 93.9234 75.526 93.9608 75.4148 93.9608L75.4272 93.9483ZM79.4802 63.8516C81.1361 68.9217 81.9516 73.7551 81.9145 78.2273C81.8651 82.4628 81.099 86.0629 79.6285 88.9281C79.3072 89.5883 78.9489 90.2236 78.5411 90.8216L87.0303 85.9259C90.898 83.1355 93.0852 77.8037 93.1346 70.9398C93.1717 66.9535 92.5291 62.7429 91.2069 58.4203L79.4802 63.8516Z"
        fill="#455068"
      />
      <path
        d="M91.59 57.5607L78.7264 63.5277C74.7104 51.6061 66.3077 40.133 56.6075 34.4899C53.0364 32.397 49.6506 31.3008 46.5861 31.1264C44.2383 30.9894 42.1129 31.3506 40.2717 32.2226L51.1952 25.8072L52.2826 25.1718C56.657 22.8922 62.5759 23.4029 69.0757 27.2148C79.1466 33.0822 87.8088 45.1906 91.6023 57.5732L91.59 57.5607Z"
        fill="white"
      />
      <path
        d="M78.7264 64.1381C78.6399 64.1381 78.5658 64.1257 78.4793 64.0883C78.3187 64.0136 78.1951 63.889 78.1333 63.7146C73.9937 51.4442 65.6281 40.4444 56.2863 35.0255C52.8881 33.0324 49.6012 31.9237 46.5367 31.7368C44.2877 31.6122 42.2612 31.961 40.5065 32.7708C40.2099 32.9078 39.8516 32.7957 39.7033 32.4967C39.555 32.1977 39.6539 31.8365 39.9381 31.6745L51.949 24.6237C56.7311 22.1447 62.8972 22.8672 69.3599 26.6667C79.2702 32.4469 88.2166 44.493 92.1584 57.3738C92.2449 57.6728 92.109 57.9967 91.8248 58.1213L78.9612 64.0883C78.8747 64.1257 78.7882 64.1506 78.7017 64.1506L78.7264 64.1381ZM45.6346 30.4662C45.9559 30.4662 46.2895 30.4662 46.6108 30.4911C49.873 30.6904 53.333 31.8489 56.9041 33.9417C66.3201 39.4229 74.7722 50.3853 79.0724 62.6557L90.8239 57.2119C86.8944 44.8169 78.2692 33.294 68.7421 27.738C62.6501 24.1628 56.9041 23.4403 52.5421 25.7075L44.3619 30.516C44.782 30.4786 45.2021 30.4662 45.6346 30.4662Z"
        fill="#455068"
      />
      <path
        d="M81.2967 78.2148C81.2473 82.4005 80.4812 85.9009 79.0848 88.6415C78.1333 90.5973 76.8729 92.142 75.4272 93.3255C75.0688 93.6494 74.661 93.911 74.3027 94.0978C69.743 96.963 63.3792 96.6516 56.3357 92.4535C42.842 84.3936 31.9185 65.06 31.9185 49.1271C31.9185 41.6154 34.3157 36.209 38.2823 33.3812L40.0864 32.2849C40.0864 32.2849 40.1729 32.1977 40.2717 32.1977C42.1253 31.3382 44.2383 30.9645 46.5861 31.1015C49.6506 31.2883 53.0364 32.3721 56.6076 34.4649C66.3078 40.1081 74.7105 51.5812 78.7265 63.5028C80.3947 68.4608 81.3461 73.4686 81.2967 78.2024V78.2148Z"
        fill="white"
      />
      <path
        d="M67.5187 96.6142C63.9846 96.6142 60.0799 95.4058 56.0268 92.9891C42.3971 84.8545 31.3006 65.1721 31.3006 49.1271C31.3006 41.6777 33.6484 35.91 37.9239 32.8704L39.7033 31.7866C39.7774 31.7119 39.9134 31.6247 40.0864 31.5873C42.0017 30.7153 44.2012 30.3291 46.6108 30.4786C49.8731 30.6779 53.333 31.8365 56.9041 33.9293C66.4931 39.4977 75.0688 50.759 79.2949 63.3035C81.0619 68.5729 81.9393 73.5807 81.9022 78.2024C81.8528 82.4378 81.0866 86.038 79.6162 88.9032C78.677 90.834 77.3919 92.4784 75.7979 93.7864C75.4272 94.1103 74.9823 94.4217 74.5622 94.6335C72.4739 95.9415 70.089 96.6017 67.5064 96.6017L67.5187 96.6142ZM40.4447 32.8081C40.4447 32.8081 40.42 32.833 40.3953 32.833L38.5912 33.9293C34.6987 36.6948 32.5239 42.1137 32.5239 49.1396C32.5239 64.7984 43.3486 83.9825 56.6446 91.9303C63.2556 95.8543 69.4093 96.4398 73.969 93.5746C74.3521 93.3753 74.7105 93.1262 75.0194 92.8646C76.4899 91.6562 77.6638 90.1489 78.5287 88.3675C79.9127 85.6643 80.6294 82.251 80.6789 78.2148C80.7159 73.7302 79.8633 68.8594 78.1333 63.727C73.9938 51.4566 65.6281 40.4569 56.2863 35.038C52.8881 33.0448 49.6012 31.9361 46.5367 31.7493C44.2877 31.6247 42.2612 31.9735 40.5065 32.7832C40.4818 32.7832 40.4571 32.8081 40.4324 32.8081H40.4447Z"
        fill="#455068"
      />
      <path
        d="M67.321 28.2986C67.321 29.7063 66.8515 30.7278 66.0977 31.2634L65.7517 31.4627L63.8116 32.5839C64.0835 32.3597 64.3306 32.0607 64.516 31.687C64.7878 31.1638 64.9361 30.4911 64.9361 29.6938C64.9361 28.7844 64.7631 27.8252 64.4419 26.8785L66.9009 25.421C67.1727 26.2805 67.321 27.4764 67.321 28.2986Z"
        fill="#455068"
      />
      <path
        d="M63.8116 33.2068C63.6139 33.2068 63.4162 33.1071 63.305 32.9327C63.1197 32.6711 63.1691 32.2974 63.4286 32.0981C63.651 31.9112 63.824 31.687 63.9599 31.4129C64.1947 30.952 64.3183 30.379 64.3183 29.6814C64.3183 28.8841 64.17 27.9996 63.8611 27.0778C63.7622 26.8037 63.8858 26.4923 64.1329 26.3428L66.592 24.8853C66.7526 24.7857 66.9503 24.7732 67.1233 24.8355C67.2963 24.8978 67.4322 25.0473 67.494 25.2217C67.8153 26.2182 67.9512 27.4764 67.9389 28.2986C67.9389 29.8558 67.4075 31.089 66.456 31.7742L64.1206 33.1196C64.0217 33.1694 63.9229 33.2068 63.8116 33.2068ZM65.1833 27.165C65.4428 28.0495 65.5663 28.909 65.554 29.6938C65.554 30.1423 65.5045 30.5534 65.418 30.9395L65.7764 30.7278C66.3448 30.3167 66.6908 29.4447 66.6908 28.2986C66.6908 27.7131 66.629 27.003 66.4931 26.3802L65.1586 27.165H65.1833Z"
        fill="#455068"
      />
      <path
        d="M66.9009 25.421L64.4419 26.8785C63.6757 24.5988 62.0693 22.4063 60.2035 21.3225C59.5238 20.9239 58.8689 20.7121 58.2881 20.6748C57.8433 20.6498 57.4355 20.7121 57.0771 20.8865L59.1655 19.6533L59.3755 19.5287C60.2158 19.0927 61.3403 19.1923 62.5883 19.9149C64.516 21.036 66.1718 23.0416 66.9009 25.4085V25.421Z"
        fill="white"
      />
      <path
        d="M64.4419 27.5013C64.3677 27.5013 64.2936 27.4889 64.2318 27.464C64.0588 27.4017 63.9229 27.2522 63.8611 27.0778C63.1197 24.8729 61.5627 22.8299 59.9069 21.8582C59.3261 21.5094 58.77 21.3225 58.2634 21.2976C57.9298 21.2852 57.6085 21.335 57.349 21.4471C57.0524 21.5841 56.6941 21.472 56.5458 21.1731C56.3975 20.8741 56.4964 20.5128 56.7806 20.3509L59.079 19.0055C60.1664 18.4325 61.5256 18.5819 62.922 19.3917C65.0597 20.6374 66.7773 22.8174 67.5064 25.2341C67.5929 25.5082 67.4817 25.8071 67.2345 25.9566L64.7755 27.4141C64.6766 27.4764 64.5654 27.5013 64.4666 27.5013H64.4419ZM59.3755 20.2637C59.7462 20.3882 60.1293 20.5626 60.5247 20.7869C62.2794 21.8084 63.8487 23.7517 64.7631 25.9691L66.1595 25.1469C65.4428 23.216 64.0217 21.4845 62.2918 20.4754C61.2785 19.8775 60.3517 19.7529 59.6721 20.1017L59.3879 20.2761L59.3755 20.2637Z"
        fill="#455068"
      />
      <path
        d="M64.9361 29.6938C64.9361 30.4911 64.7755 31.1638 64.516 31.687C64.3306 32.0607 64.0958 32.3597 63.8116 32.5839C63.7375 32.6462 63.6633 32.696 63.6016 32.7334C62.7242 33.2815 61.5132 33.2192 60.1663 32.4219C57.5837 30.8772 55.4954 27.1774 55.4954 24.1254C55.4954 22.6928 55.9526 21.6464 56.7188 21.1108L57.0648 20.899C57.0648 20.899 57.0771 20.8865 57.1018 20.8865C57.4602 20.7246 57.8679 20.6498 58.3128 20.6748C58.9059 20.7121 59.5485 20.9239 60.2281 21.3225C62.0817 22.4063 63.6881 24.5988 64.4665 26.8785C64.7878 27.8252 64.9732 28.7844 64.9608 29.6938H64.9361Z"
        fill="white"
      />
      <path
        d="M62.3165 33.73C61.5503 33.73 60.7101 33.4684 59.8451 32.9576C57.0524 31.2883 54.8652 27.4141 54.8652 24.1254C54.8652 22.5309 55.3966 21.2852 56.3481 20.6L56.7311 20.3633C56.7311 20.3633 56.7929 20.326 56.83 20.3135C57.2748 20.1017 57.7691 20.0145 58.3375 20.0519C59.0419 20.0893 59.7833 20.3384 60.5371 20.7744C62.4647 21.8956 64.1947 24.1628 65.0473 26.6667C65.4057 27.7256 65.5787 28.7471 65.5663 29.6814C65.5663 30.5783 65.3933 31.3382 65.072 31.961C64.862 32.3846 64.5778 32.7583 64.2071 33.0573C64.1082 33.1445 63.997 33.2192 63.8734 33.2815C63.4162 33.568 62.8849 33.7175 62.3165 33.7175V33.73ZM57.349 21.4596L57.0277 21.6589C56.4469 22.07 56.1133 22.9669 56.1133 24.1379C56.1133 26.9781 58.078 30.4537 60.4876 31.8987C61.5874 32.5465 62.5759 32.6586 63.2803 32.2226L63.4286 32.123C63.651 31.9361 63.824 31.7119 63.9599 31.4378C64.1947 30.9769 64.3183 30.4039 64.3183 29.7063C64.3183 28.909 64.17 28.0245 63.8611 27.1027C63.1197 24.8978 61.5627 22.8548 59.9069 21.8831C59.3261 21.5343 58.77 21.3475 58.2634 21.3225C57.9298 21.3101 57.6085 21.3599 57.349 21.472V21.4596Z"
        fill="#455068"
      />
      <path
        d="M79.715 41.8394C79.715 42.8609 79.369 43.5959 78.8253 43.9945L78.5782 44.144L77.1695 44.9537C77.3672 44.7918 77.5402 44.58 77.6761 44.306C77.8738 43.9323 77.9727 43.4464 77.9851 42.8609C77.9851 42.2007 77.8615 41.5155 77.6267 40.8179L79.4061 39.9958C79.6038 40.6186 79.715 41.2539 79.7027 41.8519L79.715 41.8394Z"
        fill="#455068"
      />
      <path
        d="M77.1695 45.5766C76.9718 45.5766 76.7741 45.4769 76.6629 45.3025C76.4775 45.0409 76.5269 44.6672 76.7864 44.4679C76.9224 44.3558 77.0459 44.2063 77.1324 44.0319C77.2931 43.7205 77.3672 43.3343 77.3796 42.8485C77.3796 42.2879 77.2807 41.665 77.0583 41.0172C76.9594 40.7183 77.0954 40.3819 77.3796 40.2574L79.159 39.4352C79.3196 39.3604 79.505 39.3604 79.6656 39.4352C79.8262 39.5099 79.9498 39.6469 79.9992 39.8089C80.2217 40.519 80.3329 41.2041 80.3329 41.8643C80.3329 43.0478 79.9127 43.9945 79.1837 44.5177L77.4784 45.5019C77.3796 45.5517 77.2807 45.5891 77.1695 45.5891V45.5766ZM78.3681 41.1543C78.5288 41.7522 78.6029 42.3253 78.5905 42.8609C78.5905 43.0602 78.5782 43.2346 78.5535 43.4215C78.8748 43.1474 79.0725 42.5869 79.0848 41.8394C79.0848 41.528 79.0601 41.1916 78.986 40.8678L78.3558 41.1543H78.3681Z"
        fill="#455068"
      />
      <path
        d="M79.4185 39.9833L77.6391 40.8055C77.083 39.1487 75.9215 37.5541 74.5746 36.7818C74.0803 36.4953 73.6107 36.3458 73.1782 36.3084C72.857 36.2835 72.5604 36.3458 72.3009 36.4579L73.8208 35.5734L73.9691 35.4862C74.5746 35.1748 75.4025 35.2371 76.3045 35.7727C77.7009 36.5825 78.8995 38.2642 79.4308 39.9833H79.4185Z"
        fill="white"
      />
      <path
        d="M77.6267 41.4408C77.5402 41.4408 77.4661 41.4283 77.3796 41.391C77.2189 41.3162 77.0954 41.1916 77.0336 41.0172C76.5146 39.4601 75.4148 38.015 74.2409 37.3299C73.8455 37.0932 73.4624 36.9686 73.1288 36.9437C72.9064 36.9437 72.7087 36.9686 72.548 37.0434C72.2515 37.1804 71.8931 37.0683 71.7448 36.7693C71.5965 36.4703 71.6954 36.1091 71.9796 35.9471L73.6478 34.9755C74.5004 34.527 75.5384 34.6391 76.6011 35.262C78.0839 36.1215 79.4185 37.9154 79.9993 39.8338C80.0857 40.1328 79.9498 40.4567 79.6656 40.5812L77.8862 41.4034C77.7997 41.4408 77.7132 41.4657 77.6267 41.4657V41.4408ZM74.3645 35.997C74.5251 36.0717 74.6981 36.1589 74.8711 36.2586C76.1439 36.9936 77.2931 38.3888 77.9727 39.9833L78.6523 39.6719C78.0963 38.2517 77.083 36.9686 75.9833 36.3333C75.3654 35.9721 74.797 35.8599 74.3768 36.0094L74.3645 35.997Z"
        fill="#455068"
      />
      <path
        d="M77.9851 42.8485C77.9851 43.4339 77.8739 43.9198 77.6762 44.2935C77.5402 44.5676 77.3672 44.7793 77.1695 44.9413C77.1201 44.9911 77.0583 45.0285 77.0089 45.0534C76.3787 45.452 75.489 45.4022 74.5128 44.8292C72.6345 43.708 71.127 41.0297 71.127 38.8123C71.127 37.7659 71.4606 37.0185 72.0043 36.6323L72.2515 36.4828C72.2515 36.4828 72.2638 36.4703 72.2762 36.4703C72.5357 36.3458 72.8322 36.2959 73.1535 36.3208C73.5737 36.3458 74.0432 36.4953 74.5499 36.7942C75.8968 37.579 77.0583 39.1736 77.6144 40.8179C77.8491 41.5031 77.9727 42.2007 77.9727 42.8609L77.9851 42.8485Z"
        fill="white"
      />
      <path
        d="M76.0945 45.9379C75.5013 45.9379 74.8588 45.751 74.2162 45.3648C72.1402 44.1316 70.5215 41.2539 70.5215 38.8123C70.5215 37.604 70.9293 36.6447 71.6707 36.1215L71.9549 35.9471C71.9549 35.9471 72.0043 35.9222 72.0414 35.9098C72.3874 35.7478 72.7828 35.6731 73.2153 35.698C73.759 35.7354 74.3151 35.9098 74.8835 36.2461C76.3169 37.0807 77.602 38.75 78.2322 40.6186C78.4917 41.4034 78.6276 42.1633 78.6153 42.8609C78.6153 43.5336 78.4793 44.1191 78.2446 44.58C78.0839 44.9039 77.8615 45.2029 77.5773 45.4271C77.5031 45.4894 77.4043 45.5641 77.3054 45.614C76.9594 45.8382 76.5517 45.9379 76.1068 45.9379H76.0945ZM72.5604 37.0309L72.3256 37.168C71.9672 37.4296 71.7448 38.0275 71.7448 38.8123C71.7448 40.8179 73.1288 43.272 74.8341 44.2935C75.5755 44.7295 76.2304 44.8167 76.6876 44.5302C76.9224 44.3558 77.0336 44.2063 77.1201 44.0319C77.2807 43.7205 77.3549 43.3343 77.3672 42.8485C77.3672 42.2879 77.2684 41.665 77.0459 41.0172C76.527 39.4601 75.4272 38.015 74.2533 37.3299C73.8579 37.0932 73.4748 36.9686 73.1412 36.9437C72.9187 36.9437 72.7334 36.9562 72.5727 37.0309H72.5604Z"
        fill="#455068"
      />
      <path d="M85.0409 83.8081L86.6102 76.1219L90.1072 103.827L87.3393 101.796L85.0409 83.8081Z" fill="white" />
      <path
        d="M90.1072 104.45C89.9836 104.45 89.8477 104.412 89.7488 104.325L86.9809 102.295C86.845 102.195 86.7585 102.045 86.7337 101.871L84.4354 83.8828C84.4354 83.8081 84.4354 83.7458 84.4354 83.671L86.0047 75.9849C86.0665 75.6859 86.2889 75.4741 86.6349 75.4866C86.9315 75.4866 87.191 75.7233 87.228 76.0347L90.725 103.74C90.7621 103.989 90.6385 104.225 90.4285 104.35C90.3296 104.412 90.2184 104.437 90.1195 104.437L90.1072 104.45ZM87.92 101.448L89.3163 102.469L86.4743 79.9089L85.6711 83.8205L87.92 101.448Z"
        fill="#455068"
      />
      <path d="M86.6102 76.1219L89.3534 77.2182L92.7639 102.32L90.1072 103.827L86.6102 76.1219Z" fill="#455068" />
      <path
        d="M90.1072 104.45C90.0083 104.45 89.9218 104.425 89.8353 104.387C89.65 104.3 89.5264 104.113 89.5017 103.914L86.0047 76.2091C85.98 75.9849 86.0665 75.7731 86.2395 75.6361C86.4125 75.4991 86.6473 75.4617 86.845 75.5489L89.5882 76.6327C89.7983 76.7199 89.9466 76.9067 89.9713 77.131L93.3694 102.245C93.4065 102.494 93.2829 102.743 93.0605 102.868L90.4038 104.375C90.3049 104.425 90.2061 104.45 90.0948 104.45H90.1072ZM87.3516 77.0936L90.6015 102.843L92.0843 101.996L88.785 77.6666L87.3392 77.0936H87.3516Z"
        fill="#455068"
      />
      <path
        d="M57.732 66.4052C57.732 67.4017 57.3984 68.1118 56.867 68.4855L56.6199 68.6226L55.2607 69.4074C55.4584 69.2454 55.619 69.0461 55.7549 68.772C55.9403 68.4108 56.0515 67.9374 56.0515 67.3768C56.0515 66.7415 55.9279 66.0688 55.7055 65.3961L57.4355 64.5989C57.6332 65.2093 57.732 65.8197 57.732 66.3927V66.4052Z"
        fill="#455068"
      />
      <path
        d="M55.273 70.0302C55.0753 70.0302 54.8776 69.9306 54.7664 69.7562C54.581 69.4946 54.6428 69.1208 54.8899 68.9215C55.0259 68.8094 55.1371 68.6724 55.2112 68.498C55.3595 68.199 55.446 67.8128 55.446 67.3644C55.446 66.8163 55.3471 66.2308 55.1371 65.5954C55.0382 65.2965 55.1742 64.9601 55.4584 64.8231L57.1883 64.0258C57.349 63.9511 57.5343 63.9511 57.695 64.0258C57.8556 64.1006 57.9792 64.2376 58.0286 64.3995C58.251 65.0847 58.3499 65.7574 58.3499 66.3927C58.3499 67.5512 57.9421 68.4731 57.2254 68.9838L55.5696 69.943C55.4707 69.9929 55.3719 70.0302 55.2607 70.0302H55.273ZM56.4593 65.7449C56.6076 66.318 56.6817 66.8661 56.6817 67.3893C56.6817 67.5637 56.6817 67.7256 56.657 67.8751C56.9412 67.6011 57.1265 67.0779 57.1265 66.3927C57.1265 66.0937 57.1018 65.7948 57.04 65.4709L56.4716 65.7325L56.4593 65.7449Z"
        fill="#455068"
      />
      <path
        d="M57.4478 64.5989L55.7179 65.3961C55.1742 63.7891 54.0497 62.2569 52.7398 61.497C52.2579 61.2105 51.8007 61.0735 51.3929 61.0486C51.0717 61.0361 50.7875 61.0735 50.5403 61.198L52.0108 60.3385L52.1591 60.2513C52.7522 59.9399 53.543 60.0146 54.4204 60.5253C55.7796 61.3102 56.9412 62.9421 57.4478 64.6113V64.5989Z"
        fill="white"
      />
      <path
        d="M55.7179 66.0315C55.6314 66.0315 55.5572 66.019 55.4707 65.9816C55.3101 65.9069 55.1865 65.7823 55.1247 65.6079C54.6181 64.1006 53.5554 62.7054 52.4309 62.0451C52.0478 61.8209 51.6771 61.6963 51.3559 61.6714C51.1458 61.659 50.9604 61.6839 50.7998 61.7586C50.5032 61.8956 50.1449 61.7711 49.9966 61.4846C49.8483 61.1856 49.9472 60.8243 50.2314 60.6624L51.8501 59.7156C52.678 59.2796 53.6913 59.3793 54.7293 59.9897C56.1751 60.8243 57.4725 62.5683 58.041 64.4245C58.1275 64.7234 57.9915 65.0473 57.7073 65.1719L55.9773 65.9692C55.8908 66.0065 55.8043 66.0315 55.7179 66.0315ZM52.6163 60.7371C52.7645 60.7994 52.9128 60.8741 53.0611 60.9613C54.2844 61.6714 55.3966 63.0168 56.0638 64.5615L56.6817 64.275C56.138 62.9047 55.1741 61.6839 54.1115 61.061C53.543 60.7247 53.024 60.6125 52.6163 60.7371Z"
        fill="#455068"
      />
      <path
        d="M56.0638 67.3768C56.0638 67.9374 55.9526 68.4108 55.7673 68.7721C55.6437 69.0337 55.4707 69.2454 55.273 69.4074C55.2236 69.4447 55.1741 69.4821 55.1247 69.507C54.5069 69.8932 53.6542 69.8434 52.7151 69.2828C50.8986 68.199 49.4282 65.5955 49.4282 63.4653C49.4282 62.4562 49.7494 61.7337 50.2808 61.3475L50.5279 61.198C50.5279 61.198 50.5279 61.198 50.5526 61.198C50.7998 61.0859 51.084 61.0361 51.4053 61.0486C51.813 61.0735 52.2703 61.223 52.7522 61.497C54.062 62.2569 55.1865 63.8016 55.7302 65.3961C55.9526 66.0688 56.0762 66.7415 56.0762 67.3768H56.0638Z"
        fill="white"
      />
      <path
        d="M54.2227 70.3915C53.6542 70.3915 53.024 70.2046 52.3815 69.8309C50.3673 68.635 48.798 65.8321 48.798 63.4653C48.798 62.2818 49.1934 61.3475 49.9101 60.8368L50.1943 60.6624C50.1943 60.6624 50.2437 60.6375 50.2808 60.625C50.6268 60.4631 51.0099 60.3883 51.43 60.4257C51.949 60.4631 52.5051 60.6375 53.0488 60.9614C54.4451 61.7711 55.6931 63.403 56.2986 65.1968C56.5581 65.9567 56.6817 66.6917 56.6817 67.3768C56.6817 68.0371 56.5458 68.5977 56.311 69.0586C56.1503 69.3825 55.9279 69.6565 55.6561 69.8807C55.5819 69.943 55.4954 70.0053 55.3966 70.0551C55.0629 70.2669 54.6552 70.379 54.2227 70.379V70.3915ZM50.8122 61.7586L50.5897 61.8956C50.2437 62.1448 50.0337 62.7178 50.0337 63.4777C50.0337 65.3837 51.3929 67.8004 53.0117 68.7596C53.716 69.1831 54.3462 69.2579 54.7911 68.9838C55.0135 68.8094 55.1247 68.6724 55.2112 68.5104C55.3595 68.2115 55.446 67.8253 55.446 67.3768C55.446 66.8287 55.3471 66.2432 55.1371 65.6079C54.6304 64.1006 53.5677 62.7054 52.4433 62.0451C52.0602 61.8209 51.6895 61.6963 51.3682 61.6714C51.1581 61.659 50.9852 61.6839 50.8245 61.7586H50.8122Z"
        fill="#455068"
      />
      <path d="M51.1335 38.5758L54.1115 40.3073V62.5933L51.1335 60.8742V38.5758Z" fill="white" />
      <path
        d="M54.1115 63.2162C54.0003 63.2162 53.9014 63.1913 53.8026 63.129L50.8245 61.3974C50.6392 61.2853 50.5156 61.086 50.5156 60.8618V38.5758C50.5156 38.3515 50.6392 38.1522 50.8245 38.0401C51.0099 37.928 51.257 37.928 51.4424 38.0401L54.4204 39.7717C54.6058 39.8838 54.7293 40.0831 54.7293 40.3073V62.5933C54.7293 62.8176 54.6058 63.0169 54.4204 63.129C54.3216 63.1788 54.2227 63.2162 54.1115 63.2162ZM51.7513 60.5005L53.4936 61.5096V40.6686L51.7513 39.6595V60.513V60.5005Z"
        fill="#455068"
      />
      <path d="M54.1115 40.3073L56.0268 39.1114V61.6341L54.1115 62.5933V40.3073Z" fill="#455068" />
      <path
        d="M54.1115 63.2162C54.0003 63.2162 53.8891 63.1913 53.7902 63.129C53.6049 63.0169 53.4937 62.8176 53.4937 62.5933V40.3073C53.4937 40.0955 53.6049 39.8962 53.7902 39.7841L55.7055 38.5757C55.9033 38.4512 56.138 38.4512 56.3357 38.5633C56.5335 38.6754 56.657 38.8872 56.657 39.1114V61.6466C56.657 61.8833 56.5211 62.095 56.311 62.2072L54.3957 63.1664C54.3092 63.2162 54.2104 63.2287 54.1239 63.2287L54.1115 63.2162ZM54.7293 40.6561V61.5968L55.409 61.2604V40.2326L54.7293 40.6561Z"
        fill="#455068"
      />
      <path d="M56.0268 39.1114L53.1229 37.3674L51.1335 38.5758L54.1115 40.3073L56.0268 39.1114Z" fill="white" />
      <path
        d="M54.1115 40.9302C54.0003 40.9302 53.9014 40.9053 53.8026 40.843L50.8245 39.1114C50.6392 38.9993 50.5156 38.8 50.5156 38.5758C50.5156 38.3515 50.6268 38.1522 50.8122 38.0401L52.8017 36.8317C52.9994 36.7196 53.2465 36.7072 53.4319 36.8317L56.3234 38.5633C56.5087 38.6754 56.6199 38.8747 56.6323 39.0865C56.6323 39.2983 56.5211 39.51 56.3357 39.6222L54.4204 40.8305C54.3216 40.8928 54.2103 40.9302 54.0991 40.9302H54.1115ZM52.3568 38.5633L54.0991 39.5723L54.8405 39.1114L53.1353 38.0899L52.3568 38.5633Z"
        fill="#455068"
      />
      <path
        d="M56.0639 67.3769C56.0639 67.9375 55.9526 68.4108 55.7673 68.7721C55.6437 69.0337 55.4707 69.2455 55.273 69.4074C55.2236 69.4448 55.1742 69.4822 55.1247 69.5071C54.5069 69.8932 53.6543 69.8434 52.7151 69.2828C50.8987 68.1991 49.4282 65.5955 49.4282 63.4653C49.4282 62.4563 49.7495 61.7337 50.2808 61.3476L50.528 61.1981C50.528 61.1981 50.528 61.1981 50.5527 61.1981C50.7998 61.086 51.084 61.0361 51.4053 61.0486C51.8131 61.0735 52.2703 61.223 52.7522 61.4971C54.062 62.2569 55.1865 63.8016 55.7302 65.3962C55.9526 66.0689 56.0762 66.7416 56.0762 67.3769H56.0639Z"
        fill="white"
      />
      <path
        d="M54.2227 70.3915C53.6542 70.3915 53.024 70.2047 52.3815 69.8309C50.3673 68.6351 48.798 65.8322 48.798 63.4653C48.798 62.2819 49.1934 61.3476 49.9101 60.8368L50.1943 60.6624C50.1943 60.6624 50.2437 60.6375 50.2808 60.625C50.6268 60.4631 51.0099 60.3883 51.43 60.4257C51.949 60.4631 52.5051 60.6375 53.0488 60.9614C54.4451 61.7711 55.6931 63.403 56.2986 65.1968C56.5581 65.9567 56.6817 66.6917 56.6817 67.3769C56.6817 68.0371 56.5458 68.5977 56.311 69.0586C56.1503 69.3825 55.9279 69.6565 55.6561 69.8808C55.5819 69.9431 55.4954 70.0054 55.3966 70.0552C55.0629 70.267 54.6552 70.3791 54.2227 70.3791V70.3915ZM50.8122 61.7586L50.5897 61.8957C50.2437 62.1448 50.0337 62.7179 50.0337 63.4777C50.0337 65.3837 51.3929 67.8004 53.0117 68.7596C53.716 69.1832 54.3462 69.2579 54.7911 68.9839C55.0135 68.8095 55.1247 68.6724 55.2112 68.5105C55.3595 68.2115 55.446 67.8253 55.446 67.3769C55.446 66.8287 55.3471 66.2433 55.1371 65.6079C54.6304 64.1006 53.5677 62.7054 52.4433 62.0452C52.0602 61.8209 51.6895 61.6964 51.3682 61.6714C51.1581 61.659 50.9852 61.6839 50.8245 61.7586H50.8122Z"
        fill="#455068"
      />
      <path d="M56.4222 68.2739L66.9256 80.3201L68.7915 79.4356L58.041 67.3147L56.4222 68.2739Z" fill="white" />
      <path
        d="M66.9256 80.9429C66.7526 80.9429 66.5796 80.8682 66.456 80.7312L55.9403 68.685C55.8167 68.548 55.7673 68.3611 55.792 68.1743C55.8167 67.9874 55.9279 67.8255 56.0886 67.7383L57.7197 66.7791C57.9792 66.6296 58.3005 66.6794 58.4858 66.9036L69.2363 79.0245C69.3599 79.174 69.4217 79.3733 69.3846 79.5602C69.3475 79.7471 69.224 79.9215 69.0386 79.9962L67.1727 80.8807C67.0862 80.918 66.9997 80.9429 66.9132 80.9429H66.9256ZM57.3737 68.4234L67.0862 79.5477L67.7782 79.2239L57.9174 68.112L57.3737 68.4234Z"
        fill="#455068"
      />
      <path d="M66.9256 80.3201L66.0606 81.9271L55.4089 69.557L56.4222 68.2739L66.9256 80.3201Z" fill="white" />
      <path
        d="M66.0606 82.5499C65.8876 82.5499 65.7146 82.4752 65.5911 82.3382L54.927 69.9681C54.7293 69.7439 54.7293 69.4075 54.9147 69.1708L55.9279 67.8877C56.0392 67.7383 56.2121 67.6635 56.3975 67.6511C56.5952 67.6511 56.7558 67.7258 56.8794 67.8628L67.3952 79.909C67.5682 80.1083 67.5929 80.3824 67.4693 80.6191L66.6043 82.2136C66.5055 82.388 66.3325 82.5126 66.1224 82.5375C66.0977 82.5375 66.073 82.5375 66.0606 82.5375V82.5499ZM56.1998 69.5446L65.937 80.8433L66.1718 80.4197L56.4346 69.258L56.1998 69.5446Z"
        fill="#455068"
      />
      <path d="M68.7915 79.4356L67.7164 81.0551L66.0606 81.9271L66.9256 80.3201L68.7915 79.4356Z" fill="#455068" />
      <path
        d="M66.0606 82.5499C65.9 82.5499 65.7393 82.4877 65.6158 82.3631C65.4304 82.1638 65.381 81.8648 65.5169 81.6281L66.3819 80.0336C66.4437 79.9215 66.5425 79.8218 66.6661 79.772L68.532 78.8875C68.7791 78.7629 69.0881 78.8377 69.2611 79.0495C69.4464 79.2612 69.4588 79.5727 69.2981 79.7969L68.2231 81.4163C68.1613 81.5035 68.0871 81.5658 68.0006 81.6156L66.3572 82.4877C66.2707 82.5375 66.1718 82.5624 66.073 82.5624L66.0606 82.5499Z"
        fill="#455068"
      />
      <path d="M35.4154 66.7043L21.8228 74.6271L35.4154 82.5374L49.0204 74.6271L35.4154 66.7043Z" fill="white" />
      <path
        d="M35.4154 83.1603C35.3042 83.1603 35.2054 83.1354 35.1065 83.0731L21.5015 75.1627C21.3162 75.0506 21.1926 74.8513 21.1926 74.6271C21.1926 74.4028 21.3162 74.2035 21.5015 74.0914L35.1065 66.181C35.2919 66.0689 35.539 66.0689 35.7244 66.181L49.3293 74.0914C49.5147 74.2035 49.6383 74.4028 49.6383 74.6271C49.6383 74.8513 49.5147 75.0506 49.3293 75.1627L35.7244 83.0731C35.6255 83.1229 35.5267 83.1603 35.4154 83.1603ZM23.0462 74.6271L35.4154 81.8274L47.7847 74.6271L35.4154 67.4268L23.0462 74.6271Z"
        fill="#455068"
      />
      <path d="M35.4154 82.5374V97.2744L21.8228 89.364V74.6271L35.4154 82.5374Z" fill="white" />
      <path
        d="M35.4154 97.8972C35.3042 97.8972 35.2054 97.8723 35.1065 97.81L21.5015 89.8997C21.3162 89.7875 21.1926 89.5882 21.1926 89.364V74.6271C21.1926 74.4028 21.3162 74.2035 21.5015 74.0914C21.6869 73.9793 21.934 73.9793 22.1194 74.0914L35.7244 82.0018C35.9097 82.1139 36.0333 82.3132 36.0333 82.5374V97.2744C36.0333 97.4986 35.9097 97.6979 35.7244 97.81C35.6255 97.8598 35.5267 97.8972 35.4154 97.8972ZM22.4283 89.0027L34.7976 96.203V82.8987L22.4283 75.6984V89.0027Z"
        fill="#455068"
      />
      <path d="M49.0204 74.6271V89.364L35.4155 97.2744V82.5374L49.0204 74.6271Z" fill="#455068" />
      <path
        d="M35.4155 97.8972C35.3042 97.8972 35.2054 97.8723 35.1065 97.81C34.9212 97.6979 34.7976 97.4986 34.7976 97.2744V82.5374C34.7976 82.3132 34.9212 82.1139 35.1065 82.0018L48.7115 74.0914C48.8969 73.9793 49.144 73.9793 49.3293 74.0914C49.5147 74.2035 49.6383 74.4028 49.6383 74.6271V89.364C49.6383 89.5882 49.5147 89.7875 49.3293 89.8997L35.7244 97.81C35.6255 97.8598 35.5267 97.8972 35.4155 97.8972ZM36.0333 82.8987V96.203L48.4026 89.0027V75.6984L36.0333 82.8987Z"
        fill="#455068"
      />
      <path
        d="M41.3344 71.438C41.3344 72.8457 40.8648 73.8672 40.1111 74.4028L39.7651 74.6021L37.825 75.7233C38.0969 75.4991 38.344 75.2001 38.5294 74.8264C38.8012 74.3032 38.9495 73.6305 38.9495 72.8332C38.9495 71.9238 38.7765 70.9646 38.4552 70.0179L40.9143 68.8718C41.1861 69.7314 41.3344 70.6034 41.3344 71.438Z"
        fill="#455068"
      />
      <path
        d="M37.825 76.3462C37.6273 76.3462 37.4296 76.2465 37.3184 76.0721C37.1331 75.8105 37.1825 75.4368 37.442 75.2375C37.6644 75.0506 37.8498 74.8264 37.9733 74.5523C38.2081 74.0914 38.3317 73.5184 38.3317 72.8208C38.3317 72.0235 38.1834 71.139 37.8745 70.2172C37.7756 69.9182 37.9115 69.5819 38.1958 69.4573L40.6548 68.3112C40.8154 68.2365 41.0008 68.2365 41.1614 68.3112C41.3221 68.386 41.4456 68.523 41.495 68.6849C41.804 69.6317 41.9523 70.566 41.9399 71.438C41.9399 72.9952 41.4085 74.2284 40.4571 74.9136L38.1216 76.259C38.0228 76.3088 37.9239 76.3462 37.8127 76.3462H37.825ZM39.209 70.3542C39.4562 71.2262 39.5797 72.0484 39.5674 72.8332C39.5674 73.2817 39.5179 73.6928 39.4314 74.0789L39.7898 73.8672C40.3582 73.4561 40.7042 72.5841 40.7042 71.438C40.7042 70.8899 40.6424 70.3293 40.5065 69.7563L39.1967 70.3667L39.209 70.3542Z"
        fill="#455068"
      />
      <path
        d="M40.9267 68.8718L38.4676 70.0179C37.7015 67.7382 36.0951 65.5457 34.2292 64.4619C33.5496 64.0633 32.8947 63.8515 32.3139 63.8142C31.869 63.7892 31.4613 63.8515 31.1029 64.0259L33.1912 62.7927L33.4013 62.6681C34.2416 62.2321 35.366 62.3317 36.6141 63.0543C38.5418 64.1754 40.1976 66.4925 40.9267 68.8593V68.8718Z"
        fill="white"
      />
      <path
        d="M38.4553 70.6407C38.3688 70.6407 38.2946 70.6283 38.2081 70.5909C38.0475 70.5162 37.9239 70.3916 37.8621 70.2172C37.1207 68.0123 35.5637 65.9693 33.9079 64.9976C33.3271 64.6488 32.7711 64.4619 32.2644 64.437C31.9184 64.4121 31.6095 64.4619 31.35 64.5865C31.0535 64.7235 30.6951 64.599 30.5468 64.3125C30.3985 64.0135 30.4974 63.6522 30.7816 63.4903L33.08 62.1449C34.1674 61.5719 35.5267 61.7213 36.9106 62.5311C38.9125 63.7021 40.7166 66.1188 41.4951 68.6974C41.5816 68.9964 41.4456 69.3203 41.1614 69.4448L38.7024 70.5909C38.6159 70.6283 38.5294 70.6532 38.4429 70.6532L38.4553 70.6407ZM33.3889 63.4031C33.7596 63.5276 34.1427 63.702 34.5381 63.9263C36.3052 64.9478 37.8992 66.9285 38.8136 69.1708L40.1605 68.5479C39.3944 66.4551 37.9239 64.5491 36.3052 63.6148C35.2919 63.0169 34.3651 62.8799 33.6855 63.2411L33.4013 63.4155L33.3889 63.4031Z"
        fill="#455068"
      />
      <path
        d="M38.9495 72.8332C38.9495 73.6305 38.7889 74.3032 38.5294 74.8264C38.344 75.2001 38.1092 75.4991 37.825 75.7233C37.7509 75.7856 37.6767 75.8354 37.615 75.8728C36.7376 76.4209 35.5266 76.3586 34.1797 75.5614C31.5971 74.0167 29.5088 70.3168 29.5088 67.2648C29.5088 65.8322 29.966 64.7858 30.7322 64.2502L31.0781 64.0384C31.0781 64.0384 31.0905 64.0259 31.1152 64.0259C31.4736 63.864 31.8813 63.7892 32.3262 63.8142C32.9193 63.8515 33.5619 64.0633 34.2415 64.4619C36.0951 65.5457 37.7015 67.7382 38.4799 70.0179C38.8012 70.9646 38.9866 71.9238 38.9742 72.8332H38.9495Z"
        fill="white"
      />
      <path
        d="M36.3299 76.8569C35.5637 76.8569 34.7111 76.6078 33.8585 76.097C31.0658 74.4277 28.8786 70.5535 28.8786 67.2648C28.8786 65.6703 29.41 64.4246 30.3615 63.7394L30.7445 63.5027C30.7445 63.5027 30.8063 63.4654 30.8434 63.4529C31.2882 63.2411 31.7949 63.1539 32.3509 63.1913C33.0553 63.2287 33.7967 63.4778 34.5505 63.9138C36.4781 65.035 38.2081 67.3022 39.0607 69.8061C39.4191 70.865 39.5921 71.8865 39.5797 72.8208C39.5797 73.7052 39.4067 74.4776 39.0854 75.1004C38.8754 75.524 38.5912 75.8977 38.2205 76.1967C38.134 76.2714 38.0104 76.3586 37.8868 76.4209C37.442 76.7074 36.9106 76.8445 36.3299 76.8445V76.8569ZM31.3129 64.6114L31.0287 64.7858C30.448 65.1969 30.1143 66.0938 30.1143 67.2648C30.1143 70.1051 32.0791 73.5807 34.4887 75.0257C35.5884 75.6735 36.577 75.7856 37.2813 75.3496L37.4296 75.2499C37.652 75.0631 37.8374 74.8388 37.961 74.5648C38.1958 74.1039 38.3193 73.5308 38.3193 72.8332C38.3193 72.036 38.171 71.1515 37.8621 70.2297C37.1207 68.0247 35.5637 65.9817 33.9079 65.0101C33.3271 64.6613 32.7711 64.4744 32.2644 64.4495C31.9184 64.4246 31.6095 64.4744 31.35 64.599C31.3377 64.599 31.3253 64.6114 31.3129 64.6114Z"
        fill="#455068"
      />
      <path
        d="M73.9814 100.276C73.969 102.706 73.1658 104.45 71.8683 105.384L71.2752 105.733L67.9512 107.651C68.4208 107.265 68.8409 106.754 69.1498 106.106C69.607 105.21 69.8665 104.051 69.8789 102.681C69.8912 101.124 69.5823 99.4792 69.0263 97.8473L73.2647 95.8915C73.7342 97.3739 73.9937 98.8688 73.9814 100.289V100.276Z"
        fill="#455068"
      />
      <path
        d="M67.9512 108.274C67.7535 108.274 67.5558 108.174 67.4446 108C67.2592 107.738 67.3086 107.365 67.5681 107.165C68.0006 106.816 68.3466 106.368 68.6061 105.832C69.0386 104.998 69.261 103.939 69.2734 102.668C69.2858 101.248 69.0139 99.691 68.4578 98.0341C68.359 97.7352 68.4949 97.3988 68.7791 97.2742L73.0175 95.3185C73.1782 95.2437 73.3635 95.2437 73.5242 95.3185C73.6848 95.3932 73.8084 95.5302 73.8578 95.6922C74.3644 97.2742 74.6116 98.8189 74.5992 100.289C74.5869 102.818 73.7466 104.811 72.2267 105.895L68.2478 108.187C68.1489 108.237 68.0501 108.274 67.9388 108.274H67.9512ZM69.7924 98.1836C70.2743 99.7657 70.5091 101.285 70.4967 102.693C70.4967 103.864 70.3114 104.898 69.9777 105.77L71.5594 104.861C72.6839 104.051 73.3512 102.407 73.3635 100.289C73.3635 99.1553 73.2153 97.9718 72.8816 96.751L69.7924 98.1836Z"
        fill="#455068"
      />
      <path
        d="M73.277 95.879L69.0386 97.8348C67.7164 93.9108 64.9485 90.1362 61.7604 88.2801C60.5865 87.5949 59.4744 87.2337 58.4611 87.1714C57.6826 87.1216 56.9906 87.2461 56.3851 87.5327L59.981 85.4274L60.3393 85.2156C61.7851 84.4682 63.7251 84.6301 65.8629 85.8883C69.1745 87.8192 72.029 91.8055 73.277 95.879Z"
        fill="white"
      />
      <path
        d="M69.0386 98.4577C68.9521 98.4577 68.878 98.4452 68.7915 98.4078C68.6308 98.3331 68.5073 98.2085 68.4455 98.0341C67.1356 94.1475 64.3924 90.5349 61.4391 88.8158C60.3764 88.1929 59.3508 87.8441 58.4117 87.7943C57.732 87.7569 57.1389 87.8565 56.6323 88.0932C56.3357 88.2303 55.9773 88.1057 55.8291 87.8192C55.6808 87.5202 55.7796 87.1589 56.0638 86.997L60.0181 84.6799C61.7357 83.783 63.9105 84.0322 66.1718 85.3526C69.5205 87.3084 72.5356 91.357 73.8578 95.6922C73.9443 95.9911 73.8084 96.315 73.5242 96.4396L69.2857 98.3954C69.1992 98.4328 69.1127 98.4577 69.0262 98.4577H69.0386ZM59.1531 86.6233C60.0922 86.7852 61.0684 87.1589 62.0693 87.7444C65.1338 89.5258 67.9018 93.0263 69.3846 96.9877L72.5109 95.5427C71.2011 91.6934 68.5073 88.1555 65.554 86.424C63.6757 85.3153 61.921 85.091 60.6235 85.7637L59.1654 86.6233H59.1531Z"
        fill="#455068"
      />
      <path
        d="M69.8913 102.681C69.8789 104.063 69.6194 105.21 69.1622 106.106C68.8533 106.754 68.4331 107.265 67.9636 107.651C67.84 107.751 67.7164 107.85 67.5929 107.9C66.0977 108.847 63.997 108.735 61.6863 107.365C57.2501 104.711 53.6543 98.3456 53.6543 93.1135C53.6543 90.647 54.4451 88.8656 55.7426 87.9313L56.3357 87.57C56.3357 87.57 56.3604 87.5451 56.3975 87.5451C57.003 87.2586 57.7073 87.1465 58.4735 87.1839C59.4867 87.2461 60.5989 87.6074 61.7728 88.2926C64.9608 90.1487 67.7288 93.9232 69.051 97.8473C69.5947 99.4792 69.916 101.124 69.9036 102.681H69.8913Z"
        fill="white"
      />
      <path
        d="M65.3686 109.158C64.1329 109.158 62.7737 108.735 61.365 107.9C56.7682 105.16 53.024 98.52 53.024 93.1135C53.024 90.5224 53.8643 88.5043 55.3842 87.4205L56.0144 87.0344C56.0144 87.0344 56.0886 86.997 56.138 86.9721C56.8423 86.6482 57.6332 86.4987 58.5105 86.561C59.6474 86.6233 60.846 87.0344 62.0817 87.7569C65.3192 89.6379 68.2107 93.4249 69.6318 97.6479C70.2249 99.4293 70.5214 101.124 70.5091 102.693C70.4967 104.151 70.2249 105.396 69.7183 106.405C69.3846 107.091 68.9274 107.676 68.359 108.149C68.2231 108.262 68.0501 108.386 67.8771 108.473C67.1357 108.934 66.2954 109.171 65.381 109.171L65.3686 109.158ZM56.6446 88.1057L56.0638 88.4545C54.9147 89.2642 54.2721 90.9335 54.2721 93.101C54.2721 98.1213 57.7444 104.275 62.0075 106.816C64.0341 108.025 65.9123 108.224 67.2716 107.365C67.3951 107.302 67.494 107.24 67.5558 107.165C68.0006 106.792 68.359 106.343 68.6061 105.82C69.0386 104.985 69.261 103.926 69.2734 102.656C69.2858 101.236 69.0139 99.6785 68.4578 98.0217C67.148 94.135 64.4048 90.5224 61.4515 88.8033C60.3888 88.1804 59.3631 87.8316 58.424 87.7818C57.7444 87.7444 57.1513 87.8441 56.6446 88.0808V88.1057Z"
        fill="#455068"
      />
      <path
        d="M64.9361 29.6938C64.9361 30.491 64.7755 31.1637 64.516 31.6869C64.3306 32.0607 64.0958 32.3596 63.8116 32.5839C63.7375 32.6461 63.6633 32.696 63.6016 32.7333C62.7242 33.2815 61.5132 33.2192 60.1663 32.4219C57.5837 30.8772 55.4954 27.1774 55.4954 24.1254C55.4954 22.6928 55.9526 21.6464 56.7188 21.1107L57.0648 20.899C57.0648 20.899 57.0771 20.8865 57.1018 20.8865C57.4602 20.7246 57.8679 20.6498 58.3128 20.6747C58.9059 20.7121 59.5485 20.9239 60.2281 21.3225C62.0817 22.4063 63.6881 24.5988 64.4665 26.8784C64.7878 27.8252 64.9732 28.7844 64.9608 29.6938H64.9361Z"
        fill="white"
      />
      <path
        d="M62.3165 33.7299C61.5503 33.7299 60.7101 33.4683 59.8451 32.9576C57.0524 31.2883 54.8652 27.4141 54.8652 24.1254C54.8652 22.5309 55.3966 21.2851 56.3481 20.6L56.7311 20.3633C56.7311 20.3633 56.7929 20.3259 56.83 20.3135C57.2748 20.1017 57.7691 20.0145 58.3375 20.0519C59.0419 20.0892 59.7833 20.3384 60.5371 20.7744C62.4647 21.8955 64.1947 24.1628 65.0473 26.6667C65.4057 27.7255 65.5787 28.747 65.5663 29.6813C65.5663 30.5782 65.3933 31.3381 65.072 31.961C64.862 32.3845 64.5778 32.7583 64.2071 33.0572C64.1082 33.1444 63.997 33.2192 63.8734 33.2815C63.4162 33.568 62.8849 33.7175 62.3165 33.7175V33.7299ZM57.349 21.4595L57.0277 21.6589C56.4469 22.0699 56.1133 22.9669 56.1133 24.1378C56.1133 26.9781 58.078 30.4537 60.4876 31.8987C61.5874 32.5465 62.5759 32.6586 63.2803 32.2226L63.4286 32.1229C63.651 31.9361 63.824 31.7119 63.9599 31.4378C64.1947 30.9769 64.3183 30.4038 64.3183 29.7062C64.3183 28.909 64.17 28.0245 63.8611 27.1027C63.1197 24.8977 61.5627 22.8547 59.9069 21.8831C59.3261 21.5343 58.77 21.3474 58.2634 21.3225C57.9298 21.31 57.6085 21.3599 57.349 21.472V21.4595Z"
        fill="#455068"
      />
    </svg>
  );
}
