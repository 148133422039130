import {
  ExecutionSourceData,
  JobType,
  useBatchGetUsers,
  useGetJobDetail,
  useGetWorkflow,
} from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvButton,
  NvDivider,
  NvEventRepeatIcon,
  NvFlex,
  NvLanguageIcon,
  NvRouterLink,
  NvSkeleton,
  NvSlackIcon,
  NvTimerOutlinedIcon,
  NvTypography,
  NvWebhookIcon,
  NvWorkflowIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { upperFirst } from 'lodash';
import { PropertyPanelSection } from '../../../../../../components';
import {
  USER_APP_JOBS_RECURRING_DETAIL,
  USER_APP_JOBS_SCHEDULED_DETAIL,
  USER_APP_WORKFLOW,
} from '../../../../constants';
import { SourceCard } from './styled';

export const ExecutionSource: React.FC<{ executionSourceData: ExecutionSourceData }> = ({ executionSourceData }) => {
  const theme = useTheme();
  const { data, isInitialLoading: isUserLoading } = useBatchGetUsers(
    (executionSourceData.type === 'slack' || executionSourceData.type === 'web') && executionSourceData.userId
      ? [executionSourceData.userId]
      : undefined
  );

  const { savedWorkflow, isLoading: isWorkflowLoading } = useGetWorkflow(
    executionSourceData.type === 'workflow' || executionSourceData.type === 'actioner-event'
      ? { appId: executionSourceData.appId, workflowId: executionSourceData.workflowId }
      : {}
  );

  const { data: jobDetail, isInitialLoading: isJobDetailLoading } = useGetJobDetail(
    executionSourceData.type === 'scheduled-job' && executionSourceData.jobType === JobType.RECURRING
      ? { appId: executionSourceData.appId, jobId: executionSourceData.jobId }
      : {}
  );

  return (
    <>
      <NvDivider />
      <PropertyPanelSection title={'Source'}>
        <SourceCard>
          {executionSourceData.type === 'slack' ? (
            <NvSlackIcon sx={{ width: '16px', height: '16px' }} />
          ) : executionSourceData.type === 'web' ? (
            <NvLanguageIcon sx={{ width: '16px', height: '16px' }} />
          ) : executionSourceData.type === 'workflow' ? (
            <NvWorkflowIcon sx={{ width: '16px', height: '16px' }} />
          ) : executionSourceData.type === 'webhook' ? (
            <NvWebhookIcon sx={{ width: '16px', height: '16px' }} />
          ) : executionSourceData.type === 'scheduled-job' ? (
            executionSourceData.jobType === JobType.RECURRING ? (
              <NvEventRepeatIcon sx={{ width: '16px', height: '16px' }} />
            ) : (
              <NvTimerOutlinedIcon sx={{ width: '16px', height: '16px' }} />
            )
          ) : executionSourceData.type === 'actioner-event' ? (
            <NvWorkflowIcon sx={{ width: '16px', height: '16px' }} />
          ) : null}
          <NvTypography variant="h5">
            {executionSourceData?.type === 'scheduled-job'
              ? `${upperFirst(executionSourceData?.jobType)} job`
              : executionSourceData.type === 'actioner-event'
              ? 'Workflow'
              : upperFirst(executionSourceData?.type)}
          </NvTypography>
          {(executionSourceData.type === 'slack' ||
            executionSourceData.type === 'web' ||
            executionSourceData.type === 'actioner-event' ||
            executionSourceData.type === 'scheduled-job' ||
            executionSourceData.type === 'workflow') && (
            <>
              {(data?.users || savedWorkflow || jobDetail) && (
                <NvDivider
                  orientation="vertical"
                  sx={{ height: '8px' }}
                  borderColor={theme.palette.nv_neutral_alpha[30]}
                />
              )}
              {isUserLoading ? (
                <NvSkeleton variant="rectangular" height="8px" width="64px" />
              ) : (
                data?.users && (
                  <NvTypography variant="h6" textColor="secondary">
                    {data.users[0].userName}
                  </NvTypography>
                )
              )}

              {isWorkflowLoading ? (
                <>
                  <NvSkeleton variant="rectangular" height="8px" width="64px" />
                  <NvFlex flex="1 1 auto" minWidth={0} direction="row-reverse">
                    <NvSkeleton variant="rectangular" width="24px" height="24px" />
                  </NvFlex>
                </>
              ) : (
                savedWorkflow && (
                  <>
                    <NvTypography variant="h6" textColor="secondary">
                      {savedWorkflow.name}
                    </NvTypography>

                    <NvFlex flex="1 1 auto" minWidth={0} direction="row-reverse">
                      <NvRouterLink
                        to={USER_APP_WORKFLOW({ userAppId: savedWorkflow.appId, workflowId: savedWorkflow.id })}
                      >
                        <NvButton color="secondary" onlyIcon size="small">
                          <NvArrowForwardIcon />
                        </NvButton>
                      </NvRouterLink>
                    </NvFlex>
                  </>
                )
              )}
              {isJobDetailLoading ? (
                <>
                  <NvSkeleton variant="rectangular" height="8px" width="64px" />
                  <NvFlex flex="1 1 auto" minWidth={0} direction="row-reverse">
                    <NvSkeleton variant="rectangular" width="24px" height="24px" />
                  </NvFlex>
                </>
              ) : (
                jobDetail &&
                executionSourceData.type === 'scheduled-job' && (
                  <>
                    <NvTypography variant="h6" textColor="secondary">
                      {jobDetail.name}
                    </NvTypography>

                    <NvFlex flex="1 1 auto" minWidth={0} direction="row-reverse">
                      <NvRouterLink
                        to={
                          jobDetail.type === JobType.RECURRING
                            ? USER_APP_JOBS_RECURRING_DETAIL(executionSourceData.appId, jobDetail.id, jobDetail.type)
                            : USER_APP_JOBS_SCHEDULED_DETAIL(executionSourceData.appId, jobDetail.id, jobDetail.type)
                        }
                      >
                        <NvButton color="secondary" onlyIcon size="small">
                          <NvArrowForwardIcon />
                        </NvButton>
                      </NvRouterLink>
                    </NvFlex>
                  </>
                )
              )}
            </>
          )}
        </SourceCard>
      </PropertyPanelSection>
    </>
  );
};
