import { SvgIconProps } from '@mui/material';
import React from 'react';
import { SpinnerSVG } from './styled';

export const NvCustomSpinner = React.forwardRef<SVGSVGElement, SvgIconProps>(({ ...props }, ref) => {
  return (
    <SpinnerSVG
      ref={ref}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26041 3.81372C10.096 2.97521 12.1601 2.77793 14.1212 3.25355C16.0824 3.72918 17.8268 4.85011 19.0743 6.43633C20.3218 8.02254 21 9.982 21 12C21 12.5523 20.5523 13 20 13C19.4477 13 19 12.5523 19 12C19 10.4304 18.4725 8.90642 17.5022 7.6727C16.5319 6.43897 15.1752 5.56714 13.6498 5.19721C12.1245 4.82728 10.5191 4.98072 9.09143 5.63289C7.66378 6.28506 6.49673 7.39812 5.77771 8.7933C5.05869 10.1885 4.82943 11.7848 5.12675 13.326C5.42406 14.8671 6.2307 16.2636 7.41711 17.2912C8.60353 18.3188 10.1009 18.9179 11.6687 18.9922C13.2365 19.0665 14.7838 18.6117 16.062 17.7009C16.5118 17.3804 17.1362 17.4852 17.4567 17.935C17.7772 18.3848 17.6724 19.0092 17.2226 19.3297C15.5791 20.5007 13.5898 21.0854 11.574 20.9899C9.55826 20.8944 7.6331 20.1242 6.10771 18.803C4.58232 17.4818 3.54522 15.6863 3.16296 13.7048C2.78069 11.7234 3.07546 9.67091 3.99991 7.8771C4.92436 6.08329 6.42485 4.65222 8.26041 3.81372Z"
        fill="currentColor"
      />
    </SpinnerSVG>
  );
});
