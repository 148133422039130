import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_APP_DIRECTORY_PREFIX } from '../constants';
import { UpdateSchemaTagsPayload } from '../types';

const updateSchemaTags = async ({ schemaId, payload }: UpdateSchemaTagsPayload) => {
  const result = await NvAxios.patch(`${ADMIN_APP_DIRECTORY_PREFIX}/schemas/${schemaId}/tags`, payload);
  return result?.data;
};

export const useUpdateSchemaTags = () => {
  return useMutation(updateSchemaTags);
};
