import { useTheme } from '@novaera/theme-provider';
import { useIsOverflow } from '@novaera/utils';
import { groupBy as lodashGroupBy, upperFirst } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { NvConditionalRender } from '../conditional-render';
import { NvFlex } from '../flex';
import { NvCustomEmptySearchIcon } from '../icons';
import { NvTypography } from '../typography';
import { NodeListGroupItem } from './node-list-group-item';
import { NodeListItem } from './node-list-item';
import { NodeListItemLoading } from './node-list-item/node-list-item-loading';
import { NodeListRefWrapper, NodeListScrollWrapper } from './styled';
import { NodeListItem as NodeListItemType, NodeListProps } from './types';

export const NvNodeList = React.forwardRef<HTMLDivElement, NodeListProps>(
  ({ nodes, onItemClick, groupBy, RenderGroupItem, header, isLoading }, ref) => {
    const theme = useTheme();
    const nodeListWrapperRef = useRef(null);
    const isOverflow = useIsOverflow(nodeListWrapperRef);
    const transformedNodes = useMemo(() => (groupBy ? lodashGroupBy(nodes, groupBy) : null), [groupBy, nodes]);
    const isEmpty = (transformedNodes && Object.keys(transformedNodes).length === 0) || nodes.length === 0;
    const handleItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: NodeListItemType) => {
      onItemClick?.(event, item);
    };

    return (
      <NodeListRefWrapper ref={ref}>
        <NodeListScrollWrapper ref={nodeListWrapperRef} isOverflow={isOverflow}>
          {header}
          <NvConditionalRender when={() => !isLoading && isEmpty}>
            <NvFlex padding="32px 16px" direction="row" justifyContent="center" alignItems="center" gap="8px">
              <NvCustomEmptySearchIcon
                sx={{ width: '16px', height: '16px' }}
                htmlColor={theme.palette.nv_neutral[60]}
              />
              <NvTypography variant="body2" textColor="ghost">
                There is no options.
              </NvTypography>
            </NvFlex>
          </NvConditionalRender>
          <NvConditionalRender when={() => !!isLoading}>
            <>
              <NodeListItemLoading />
              <NodeListItemLoading />
              <NodeListItemLoading />
            </>
          </NvConditionalRender>
          <NvConditionalRender when={() => !isLoading && !isEmpty}>
            {transformedNodes ? (
              Object.keys(transformedNodes).map((groupKey, index) => (
                <React.Fragment key={`group-item-${groupKey}-${index}`}>
                  {RenderGroupItem ? (
                    <RenderGroupItem groupKey={groupKey} />
                  ) : (
                    <NodeListGroupItem name={upperFirst(groupKey)} icon={''} />
                  )}
                  {transformedNodes[groupKey]?.map((item, index) => (
                    <NodeListItem
                      key={`grouped-nested-item-${groupKey}-${item.name}-${index}`}
                      {...item}
                      onItemClick={(event) => handleItemClick(event, item)}
                    />
                  ))}
                </React.Fragment>
              ))
            ) : (
              <>
                {nodes.map(({ childNodes, ...item }, index) => (
                  <NodeListItem
                    key={`nested-list-item-${item.name}-${index}`}
                    hasChild={Boolean(childNodes)}
                    {...item}
                    onItemClick={(event) => {
                      handleItemClick(event, { childNodes, ...item });
                    }}
                  />
                ))}
              </>
            )}
          </NvConditionalRender>
        </NodeListScrollWrapper>
      </NodeListRefWrapper>
    );
  }
);

NvNodeList.displayName = 'NvNodeList';
