import { NvAddBoxIcon, NvButton, NvFlex, NvPopoverWithAnchor, NvSelectMenuItem } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { AddDocumentFilterParam } from './types';

export const DocumentFilter = ({ onAddClicked }: { onAddClicked: (param: AddDocumentFilterParam) => void }) => {
  const theme = useTheme();

  return (
    <NvPopoverWithAnchor
      id="select-user-apps"
      fullInitiatorWidth
      initiator={
        <NvButton onlyIcon size="small" variant="contained" color="ghost" sx={{ flex: '0 0 auto' }}>
          <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[1000]} />
        </NvButton>
      }
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
    >
      {({ close }) => (
        <NvFlex padding="6px" maxHeight={'198px'} flexDirection={'column'}>
          <NvSelectMenuItem
            onClick={() => {
              onAddClicked({
                type: 'docIds',
              });
              close();
            }}
          >
            Add document by&nbsp;<b>name</b>
          </NvSelectMenuItem>
          <NvSelectMenuItem
            onClick={() => {
              onAddClicked({
                type: 'tags',
              });
              close();
            }}
          >
            Add document by&nbsp;<b>tag</b>
          </NvSelectMenuItem>
        </NvFlex>
      )}
    </NvPopoverWithAnchor>
  );
};
