import React from 'react';
import { NvCloseIcon } from '../icons';
import { NvTypography } from '../typography';
import { Chip } from './styled';
import { NvChipProps } from './types';

export const NvChip = <T extends string | number>(props: NvChipProps<T>) => {
  const { color, compact, maxWidth, sx, label, className } = props;
  let icon: NvChipProps<string>['icon'];
  let onDelete: NvChipProps<string>['onDelete'];
  if (typeof label === 'string') {
    icon = (props as NvChipProps<string>).icon;
    onDelete = (props as NvChipProps<string>).onDelete;
  }

  return (
    <Chip
      color={color}
      compact={compact}
      className={`nv-chip ${className}`}
      sx={sx}
      maxWidth={maxWidth}
      isNumber={typeof label === 'number'}
    >
      {icon &&
        React.isValidElement(icon) &&
        React.cloneElement(icon, {
          ...icon.props,
          sx: compact
            ? { width: '12px', height: '12px', flex: '0 0 auto' }
            : { width: '16px', height: '16px', flex: '0 0 auto' },
        })}

      {typeof label !== 'number' ? (
        <NvTypography margin="0px 4px" variant={compact ? 'h7' : 'body1'} flex="1 1 auto" minWidth={0} noWrap>
          {label}
        </NvTypography>
      ) : (
        <NvTypography variant={compact ? 'h5' : 'h4'} flex="1 1 auto" minWidth={0} noWrap textAlign="center">
          {label > 99 ? '99+' : label}
        </NvTypography>
      )}

      {onDelete && (
        <NvCloseIcon
          onClick={onDelete}
          sx={
            compact
              ? { width: '12px', height: '12px', cursor: 'pointer', flex: '0 0 auto' }
              : { width: '16px', height: '16px', cursor: 'pointer', flex: '0 0 auto' }
          }
        />
      )}
    </Chip>
  );
};
