import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomSelectFieldIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7H20V17H4V7ZM2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V7ZM12.7071 10.2929C12.3166 9.90237 11.6834 9.90237 11.2929 10.2929C10.9024 10.6834 10.9024 11.3166 11.2929 11.7071L14.2929 14.7071C14.6834 15.0976 15.3166 15.0976 15.7071 14.7071L18.7071 11.7071C19.0976 11.3166 19.0976 10.6834 18.7071 10.2929C18.3166 9.90237 17.6834 9.90237 17.2929 10.2929L15 12.5858L12.7071 10.2929Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
