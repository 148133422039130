import { InviteType, useInviteSlackUsers } from '@novaera/actioner-service';
import { SlackWorkspace } from '@novaera/ah-common';
import {
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvFlex,
  NvForm,
  NvPersonAddIcon,
  NvTypography,
  useToast,
} from '@novaera/core';
import { FC } from 'react';
import { InviteUsers } from './invite-users';
import { InviteUsersFormValues } from './types';

export const InviteUsersModal: FC<
  React.PropsWithChildren<{ selectedIntegration: SlackWorkspace; onCancel: () => void }>
> = ({ selectedIntegration, onCancel }) => {
  const { mutate } = useInviteSlackUsers();
  const { addToast } = useToast();

  const handleInviteUsers = (values: InviteUsersFormValues) => {
    const { invitedSlackUserIds } = values;
    return new Promise<void>((resolve) => {
      mutate(
        {
          teamId: selectedIntegration.teamId,
          invitedSlackUserIds,
          inviteType: InviteType.some,
        },
        {
          onSuccess: () => {
            addToast('Selected users are invited successfully.', { variant: 'success' });
            onCancel();
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <NvDialogV1 open maxWidth="md" fullWidth>
      <NvForm<InviteUsersFormValues> onSubmit={handleInviteUsers}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvPersonAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvFlex gap="16px">
                <NvTypography variant="h2">Invite users</NvTypography>
                <NvTypography variant="body1">
                  Select the users from your <b>{selectedIntegration.teamName}</b> Slack workspace.
                </NvTypography>
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <InviteUsers teamId={selectedIntegration.teamId} />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" disabled={submitting} loading={submitting}>
                Invite
              </NvButton>
              <NvButton color="secondary" onClick={onCancel}>
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={onCancel}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
