import { PermissionDefinition, PermissionEntity, useGetPermissionDefinitions } from '@novaera/actioner-service';
import { NvBox, NvSelect, NvSelectMenuItem } from '@novaera/core';

export const PermissionDefinitionSelect = ({
  currentPermissions,
  handleOnChange,
}: {
  currentPermissions: PermissionEntity[];
  handleOnChange: (value?: PermissionDefinition) => void;
}) => {
  const { data } = useGetPermissionDefinitions();

  const options = data?.definitions
    .filter((d) => !currentPermissions.find((p) => p.definition.id === d.id))
    .map(({ id, display }) => ({ value: id, label: display }));

  return (
    <NvBox width="100%" maxWidth="480px">
      <NvSelect
        compact
        fullWidth
        placeholder="Select permission"
        onChange={(e) => {
          const permissionDefinition = data?.definitions?.find((o) => o.id === e.target.value);
          handleOnChange(permissionDefinition);
        }}
      >
        {!options || options.length === 0 ? (
          <NvSelectMenuItem disabled value="">
            No options
          </NvSelectMenuItem>
        ) : (
          options.map((option) => (
            <NvSelectMenuItem value={option.value} key={`select_option_${option.value}`}>
              {option.label}
            </NvSelectMenuItem>
          ))
        )}
      </NvSelect>
    </NvBox>
  );
};
