import { INTEGRATION_ACTION_ROOT_PATH } from '../integration-action/constants';

export const INTEGRATION_ACTION_EXECUTION_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}/executions`;

export const INTEGRATION_ACTION_STEP_EXECUTION_PATH = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_EXECUTION_PATH(integrationId, actionId)}/step`;

export const INTEGRATION_ACTION_INPUT_PARAMETER_OPTIONS = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_EXECUTION_PATH(integrationId, actionId)}/options`;

export const INTEGRATION_ACTION_INPUT_PARAMETER_DYNAMIC_INPUT_PARAMETERS = (integrationId: string, actionId: string) =>
  `${INTEGRATION_ACTION_EXECUTION_PATH(integrationId, actionId)}/dynamic-input-parameters`;
