import { APP_PERMISSION, useGetUserApp } from '@novaera/actioner-service';
import { usePermission } from '../../utils/hooks';

import { UserAppPermissionBoundaryProps } from './types';

export const UserAppPermissionBoundary = <PERM extends APP_PERMISSION>(param: UserAppPermissionBoundaryProps<PERM>) => {
  const { data: app } = useGetUserApp(param.appId);
  const permissions = app?.permissions ?? {};
  const result = usePermission({
    permissions: permissions,
    ...param,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{result}</>;
};
