import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { FetchProfileResponse } from '../../workspace';
import { ROOT_ACCOUNT_PATH } from '../constants';
import { UpdateUsernameParams } from './types';

const profileUpdate = async (params: UpdateUsernameParams) => {
  const result = await NvAxios.patch(`${ROOT_ACCOUNT_PATH}`, params);
  return result?.data;
};

export const useProfileUpdate = () => {
  const cache = useQueryClient();
  return useMutation(profileUpdate, {
    onSuccess: (_, params) => {
      cache.setQueryData(['fetchProfile'], (old?: FetchProfileResponse) => {
        return old
          ? {
              ...old,
              ...(params.name ? { name: params.name } : {}),
            }
          : undefined;
      });
    },
  });
};
