import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, TARGET_URI } from '@novaera/constants';
import { getRootAppDomain, removeCookieForSpecificDomain, setCookieForSpecificDomain } from '@novaera/utils';
import Cookies from 'js-cookie';

export const redirectToWorkspace = (subdomain: string) => {
  const targetUri = Cookies.get(TARGET_URI);
  removeCookieForSpecificDomain(TARGET_URI, `${ApplicationConfig.Actioner.host}`);
  removeCookieForSpecificDomain(TARGET_URI, `${ApplicationConfig.Actioner.baseHost}`);
  setCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, subdomain, `${ApplicationConfig.Actioner.baseHost}`);
  window.location.href = `${window.location.protocol}//${subdomain}.${getRootAppDomain()}${targetUri ?? ''}`;
};
