import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UsePathConditionParams } from './types';

/**
 *
 * @param conditions do not forget to memoize
 */
export const usePathCondition = (conditions: UsePathConditionParams) => {
  const location = useLocation();

  useEffect(() => {
    conditions.forEach((condition) => {
      if (typeof condition.when === 'function') {
        if (condition.when(location)) {
          condition.do();
        }
      } else if (condition.when === location.pathname) {
        condition.do();
      }
    });
  }, [location.pathname, location.hash]);
};
