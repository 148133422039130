import { useGetUserApp } from '@novaera/actioner-service';
import { useMemo } from 'react';
import { getSideMenuItemIdsUnderSettings } from '../../../user-app-detail/user-app-side-menu/constants';
import { useGetWorkflowQueryParams } from '../../../user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useAppLevelMenu = () => {
  const { userAppId } = useGetWorkflowQueryParams();

  const { data: userApp } = useGetUserApp(userAppId);

  const hasAnySubMenu = useMemo(() => {
    const subMenuItemsUnderSettings = getSideMenuItemIdsUnderSettings();
    return userApp?.appMenuItems?.some((item) => subMenuItemsUnderSettings.includes(item));
  }, [userApp?.appMenuItems]);

  return {
    hasAnySubMenu,
  };
};
