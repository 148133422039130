import { SlackUser } from '@novaera/actioner-service';
import { AccountCircleIcon, NvArrowDownward, NvButton, NvCheckbox, NvImage, NvTypography } from '@novaera/core';
import { noop } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { InviteUsersFormValues } from '../../types';
import { Column, HeaderRow, Row, ShowMoreButtonRow, SlackUsersWrapper } from './styled';

export const SlackUserList: FC<
  React.PropsWithChildren<{
    value: InviteUsersFormValues['invitedSlackUserIds'];
    onChange: (value: InviteUsersFormValues['invitedSlackUserIds']) => void;
    users: SlackUser[];
    isShowMoreVisible: boolean;
    onShowMoreClick: () => void;
  }>
> = ({ value, onChange, users, isShowMoreVisible, onShowMoreClick }) => {
  const handleUserClick = (user: SlackUser, checked: boolean) => {
    const newValues = checked ? [...value, user.id] : value.filter((current) => current !== user.id);
    onChange(newValues);
  };

  const handleAllSelect = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked ? users.map((u) => u.id) : []);
  };

  return (
    <SlackUsersWrapper>
      <HeaderRow>
        <NvCheckbox checked={users.every(({ id }) => value.includes(id))} onChange={handleAllSelect} />
        <Column>
          <NvTypography variant="h5">Name</NvTypography>
        </Column>
        <Column>
          <NvTypography variant="h5">Email</NvTypography>
        </Column>
      </HeaderRow>
      {users.map((user, index) => (
        <Row
          key={`invite_user_${user.id}`}
          selected={value?.includes(user.id)}
          onClick={() => {
            handleUserClick(user, !value?.includes(user.id));
          }}
        >
          <NvCheckbox checked={value?.includes(user.id)} onChange={noop} />

          <Column direction="row" alignItems="center" gap="12px">
            <NvImage src={user.logoUrl} FallBack={<AccountCircleIcon fontSize="small" />} size="medium" />
            <NvTypography variant="h4" noWrap>
              {user.realName}
            </NvTypography>
          </Column>
          <Column>
            <NvTypography variant="body1">{user.email}</NvTypography>
          </Column>
        </Row>
      ))}
      {isShowMoreVisible && (
        <ShowMoreButtonRow>
          <NvButton
            size="small"
            color="secondary"
            startIcon={<NvArrowDownward />}
            onClick={(e) => {
              onShowMoreClick();
            }}
          >
            Show more
          </NvButton>
        </ShowMoreButtonRow>
      )}
    </SlackUsersWrapper>
  );
};
