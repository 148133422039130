import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ROOT_PATH_SSO_PROVIDERS } from '../constants';
import { QUERY_KEYS_SSO_PROVIDERS } from '../keys';
import { SSOProvider } from '../types';

type UpdateUpdateSSOParams = {
  body: SSOProvider;
};

type UpdateUpdateSSOResponse = SSOProvider;

const updateSSO = async ({ body }: UpdateUpdateSSOParams) => {
  const result = await NvAxios.put<UpdateUpdateSSOResponse>(`${ROOT_PATH_SSO_PROVIDERS}`, {
    ...body,
  });
  return result?.data;
};

export const useUpdateSSO = () => {
  const cache = useQueryClient();

  return useMutation(updateSSO, {
    onSuccess: (data, variable) => {
      cache.setQueryData<UpdateUpdateSSOResponse>(
        QUERY_KEYS_SSO_PROVIDERS.ssoProvider(variable.body.containerId, variable.body.containerType),
        data
      );
    },
  });
};
