import { FieldArray, NvButton, NvCloseIcon, NvField, NvFlex, NvSelect } from '@novaera/core';
import { useWorkflowPermission } from '../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { WORKSPACE_USER_ROLES } from '../constants';
import { WorkflowPermissionGroupAndUser } from '../controllers/use-workflow-users-with-permissions/types';
import { AddNewWorkflowSection } from './add-new-workflow-section';
import { AppAdmins } from './app-admins';
import { WorkflowPermissionsContainer } from './styled';
import { WorkflowPermissionItem } from './workflow-permission-item';
import { WorkflowPermissionItemLoading } from './workflow-permission-item/loading';

export const WorkflowPermissionsModalBody: React.FC<React.PropsWithChildren<{ isLoading: boolean }>> = ({
  isLoading,
}) => {
  const { hasEditPermission } = useWorkflowPermission();

  return (
    <NvFlex gap="16px" alignItems="flex-start">
      <FieldArray<WorkflowPermissionGroupAndUser> name="users" allowNull>
        {({ fields }) => (
          <>
            {hasEditPermission && (
              <AddNewWorkflowSection
                onAdd={(users: WorkflowPermissionGroupAndUser[]) => {
                  users.forEach((user) => {
                    fields.push(user);
                  });
                }}
              />
            )}
            <WorkflowPermissionsContainer>
              <AppAdmins />
              {isLoading ? (
                <>
                  <WorkflowPermissionItemLoading />
                  <WorkflowPermissionItemLoading />
                  <WorkflowPermissionItemLoading />
                </>
              ) : (
                fields.map((name, index) => {
                  const workflowPermission = fields.value[index];
                  const key = `${workflowPermission.principal.id}-${workflowPermission.principal.type}`;

                  return (
                    <WorkflowPermissionItem
                      key={`workflow_permission_${key}`}
                      workflowPermission={workflowPermission}
                      actions={
                        <>
                          <NvField
                            name={`${name}.scope`}
                            component={<NvSelect sx={{ width: '120px' }} options={WORKSPACE_USER_ROLES} compact />}
                          />

                          <NvButton
                            size="small"
                            color="ghost"
                            onlyIcon
                            onClick={() => {
                              fields.remove(index);
                            }}
                          >
                            <NvCloseIcon />
                          </NvButton>
                        </>
                      }
                    />
                  );
                })
              )}
            </WorkflowPermissionsContainer>
          </>
        )}
      </FieldArray>
    </NvFlex>
  );
};
