import { NovaeraNode } from '@novaera/core';
import { MarkerType } from 'reactflow';
import { AppWorkflowNodeType } from '../../../../components/user-app-workflow-node-types/types';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { connectNodes } from '../connect-nodes';

export const addLoopDummyButton = ({ alias, nextNodeAlias }: { alias: string; nextNodeAlias: string }) => {
  const dummyButton: NovaeraNode<AppWorkflowNodeType> = {
    id: `add-button-for-invisible-${alias}`,
    name: `add-button-for-invisible-${alias}`,
    width: 0,
    height: 0,
    type: 'DummyNode',
    alias: `add-button-for-invisible-${alias}`,
    marginX: 150,
  };
  userAppGraph.addNode(dummyButton);

  connectNodes({
    sourceId: alias,
    targetId: dummyButton.alias,
    edgeExtraData: {
      type: 'EdgeForLoop',
      style: { stroke: '#6e7689', strokeDasharray: '2, 2' },
      markerEnd: { type: MarkerType.Arrow, color: '#6e7689' },
      centerPosition: 'bottom',
    },
  });

  connectNodes({
    sourceId: dummyButton.alias,
    targetId: nextNodeAlias,
    edgeExtraData: {
      type: 'Edge',
      style: { stroke: '#6e7689', strokeDasharray: '2, 2' },
      centerPosition: 'bottom',
      tailBehavior: 'hidden',
    },
  });
};
