import { NvFlex } from '@novaera/core';
import classNames from 'classnames';
import { PanelWrapper, RecordDetailBox, RecordDetailHeaderBox } from './styled';
import { RecordsLayoutProps } from './types';

export const RecordsLayout = ({
  HeaderComponent,
  isPanelOpen,
  RowDetailPanelComponent,
  children,
}: RecordsLayoutProps) => {
  return (
    <NvFlex flexDirection={'row'} width={'100%'} height={'100%'}>
      <RecordDetailBox>
        <RecordDetailHeaderBox>{HeaderComponent}</RecordDetailHeaderBox>
        {children}
      </RecordDetailBox>
      {RowDetailPanelComponent && (
        <PanelWrapper
          className={classNames({
            open: isPanelOpen,
            close: !isPanelOpen,
          })}
        >
          {RowDetailPanelComponent}
        </PanelWrapper>
      )}
    </NvFlex>
  );
};
