import React from 'react';

import {
  NvBox,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvForm,
  NvPersonAddIcon,
  NvSkeleton,
  NvTabs,
  NvTypography,
} from '@novaera/core';

import arrayMutators from 'final-form-arrays';
import { useNewMemberInvitation } from './controllers/use-new-member-invitation';

import { UserInvitationForm } from '@novaera/actioner-service';
import { MemoizedUserInviteWithEmailForm } from './user-invite-via-email-form';
import { UserInviteViaSlack } from './user-invite-via-slack-form';

const NewMemberInvitation: React.FC<
  React.PropsWithChildren<{
    isOpened: boolean;
    onClosed: () => void;
  }>
> = ({ isOpened, onClosed }) => {
  const { isModalOpened, onModalClosed, onFormSubmit, isInviteLoading, slackWorkspace, isLoading, setActiveTab } =
    useNewMemberInvitation({
      isOpened,
      onModalClosed: onClosed,
    });

  return (
    <NvDialogV1
      fullWidth
      open={isModalOpened}
      onClose={() => {
        onModalClosed();
        onClosed();
      }}
      maxWidth="md"
    >
      <NvForm<UserInvitationForm>
        onSubmit={onFormSubmit}
        keepDirtyOnReinitialize
        initialValues={{
          users: [{ email: '' }],
        }}
        mutators={{
          ...arrayMutators,
        }}
      >
        <NvDialogSlots>
          <NvDialogSlots.Slot name="closeButton">
            <NvButton
              size="small"
              color="secondary"
              onClick={() => {
                onModalClosed();
                onClosed();
              }}
              onlyIcon
            >
              <NvCloseIcon />
            </NvButton>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="icon">
            <NvPersonAddIcon />
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="title">
            <NvTypography variant="h2">Invite users</NvTypography>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="content">
            {isLoading && (
              <>
                <NvSkeleton />
                <NvSkeleton />
                <NvSkeleton />
              </>
            )}

            {!isLoading && slackWorkspace ? (
              <NvTabs
                onActiveTabChanged={(index: number) => {
                  setActiveTab(index);
                }}
                tabs={[
                  {
                    content: (
                      <NvBox marginTop="16px">
                        <UserInviteViaSlack />
                      </NvBox>
                    ),
                    label: 'Invite via Slack',
                  },
                  {
                    content: (
                      <NvBox marginTop="16px">
                        <MemoizedUserInviteWithEmailForm />
                      </NvBox>
                    ),
                    label: 'Invite via email',
                  },
                ]}
              />
            ) : (
              <MemoizedUserInviteWithEmailForm />
            )}
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="actions">
            <NvButton type="submit" loading={isInviteLoading}>
              Invite
            </NvButton>
            <NvButton
              onClick={() => {
                onModalClosed();
                onClosed();
              }}
              disabled={isInviteLoading}
              color="ghost"
            >
              Cancel
            </NvButton>
          </NvDialogSlots.Slot>
        </NvDialogSlots>
      </NvForm>
    </NvDialogV1>
  );
};

export const MemoizedNewMemberInvitation = React.memo(NewMemberInvitation, (prevProps, nextProps) => {
  return prevProps.isOpened === nextProps.isOpened;
});
MemoizedNewMemberInvitation.displayName = 'NewMemberInvitation';
