import { Edge, Node } from 'reactflow';
import { ExtraData } from '../../graph/dagre-graph';
import { NovaeraEdge, NovaeraNodeWithPosition } from '../types';

export const transformToReactFlowNodes = <NodeType extends string = string>({
  nodes,
  keepSelected = true,
}: {
  nodes: NovaeraNodeWithPosition<NodeType>[];
  keepSelected?: boolean;
}) => {
  return nodes.map((n) => {
    return transformToReactFlowNode({ node: n, keepSelected });
  });
};

export const transformToReactFlowNode = <NodeType extends string = string>({
  node,
  keepSelected = true,
}: {
  node: NovaeraNodeWithPosition<NodeType>;
  keepSelected?: boolean;
}) => {
  const newNode: Node<Omit<ExtraData<NodeType>, 'type'>> = {
    id: node.id,
    position: { x: node.position.x + (node.marginX ?? 0), y: node.position.y + (node.marginY ?? 0) },
    width: node.width,
    height: node.height,
    data: { name: node.name, alias: node.alias, marginY: node.marginY, marginX: node.marginX, disabled: node.disabled },
    type: node.type,
    ...(keepSelected ? { selected: node.selected } : {}),
  };
  return newNode;
};

export const transformToReactFlowEdges = (edges: NovaeraEdge[]) => {
  return edges.map((e) => {
    const { type, markerEnd, style, markerStart, ...rest } = e.extraData;
    const newEdge: Edge = {
      id: e.id,
      source: e.sourceId,
      target: e.targetId,
      data: rest,
      type,
      markerEnd: markerEnd,
      markerStart: markerStart,
      style,
    };
    return newEdge;
  });
};
