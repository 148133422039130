import React, { useEffect, useRef } from 'react';
import { TrackFormStateProps } from './types';

export const TrackFormState: React.FC<React.PropsWithChildren<TrackFormStateProps>> = ({
  form,
  onFormStateChanged,
  children,
}) => {
  const isDirtyRef = useRef<boolean>();

  useEffect(() => {
    if (!onFormStateChanged) {
      return;
    }

    const isDirty = form.getState().dirty;

    if (isDirtyRef.current !== isDirty) {
      isDirtyRef.current = isDirty;
      onFormStateChanged({ isDirty });
    }
  }, [form, onFormStateChanged]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
