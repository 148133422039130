import {
  GetWorkflowSourceOptionsParamsForMultipleInputParameters,
  useGetWorkflowSourceOptions,
  useResetWorkflowSourceOptions,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { DependencyInputComponentStateFN } from '../../../../../../../components/dynamic-input/providers/controller/use-check-dependency-value/types';
import { useCheckWorkflowDynamicInputDependencyValue } from '../../../../../../../components/dynamic-input/providers/controller/use-check-workflow-dynamic-input-dependency-value';
import { useWorkflowDynamicInputContext } from '../../../../../../../components/dynamic-input/providers/workflow-dynamic-input-provider';
import { GetOptionsResponseFunction } from '../../../../../../../components/options/provider/types';
import { componentsAlwaysShowingOptions } from '../../../../../../../components/ui-components/utils';
import { UserAppOptionsContextType, UserAppOptionsProviderProps } from './types';

const UserAppOptionsContext = createContext<UserAppOptionsContextType | undefined>(undefined);

export const UserAppOptionsProvider: FC<React.PropsWithChildren<UserAppOptionsProviderProps>> = ({
  children,
  appId,
  workflowId,
  draft,
  inputParameterIds,
  initialInputParameterIdsShowingOptions,
  inputParameterValues,
  searchAsYouTypeValues,
  allInputParameterIdsWithOrder,
  formId,
}) => {
  const { removeWorkflowSourceOptionsCacheForInputParameter } = useResetWorkflowSourceOptions();
  const { dynamicInputParameters, getDynamicInputParameterParent } = useWorkflowDynamicInputContext();

  const { getDependencyInputComponentState, filterSuccessDynamicInputs, dependenciesOfParameters } =
    useCheckWorkflowDynamicInputDependencyValue({
      appId,
      workflowId,
      isDraft: draft,
      inputParameters: [],
    });

  const dynamicInputs = useMemo(
    () => ({
      ids: dynamicInputParameters.map((d) => d.id),
      needsOptionsIds: dynamicInputParameters
        .filter((d) => componentsAlwaysShowingOptions(d.uiComponent))
        .map((d) => d.id),
    }),
    [dynamicInputParameters]
  );

  const [inputParameterIdsShowingOptions, setInputParameterIdsShowingOptions] = useState<string[]>(
    initialInputParameterIdsShowingOptions
  );

  const inputOptionsContext: GetWorkflowSourceOptionsParamsForMultipleInputParameters = useMemo(() => {
    return {
      requestParams: {
        appId,
        workflowId,
        formId,
        payload: {
          draft,
          inputParameterIds: [...inputParameterIds, ...dynamicInputs.ids],
          context: {},
          inputParameters: inputParameterValues,
          searchAsYouTypeValues,
        },
      },
      options: { keepPreviousData: true, staleTime: Infinity },
      enabledInputParameters: [...inputParameterIdsShowingOptions, ...dynamicInputs.needsOptionsIds].filter(
        (inputParameterId) => filterSuccessDynamicInputs(inputParameterId, inputParameterValues)
      ),
      allInputParameterIdsWithOrder,
      dependenciesOfParameters,
      getDynamicInputParameterParent,
    };
  }, [
    appId,
    workflowId,
    formId,
    draft,
    inputParameterIds,
    dynamicInputs.ids,
    dynamicInputs.needsOptionsIds,
    inputParameterValues,
    searchAsYouTypeValues,
    inputParameterIdsShowingOptions,
    allInputParameterIdsWithOrder,
    filterSuccessDynamicInputs,
    dependenciesOfParameters,
    getDynamicInputParameterParent,
  ]);

  const results = useGetWorkflowSourceOptions(inputOptionsContext);

  const optionsMap = useMemo(() => {
    return [...inputParameterIds, ...dynamicInputs.ids].map((inputParameter, index) => {
      return { inputParameterId: inputParameter, ...results[index] };
    });
  }, [dynamicInputs.ids, inputParameterIds, results]);

  const invalidate = useCallback(
    (inputParameterId: string) => {
      removeWorkflowSourceOptionsCacheForInputParameter({
        appId,
        workflowId,
        draft,
        inputParameterId,
      });
    },
    [appId, draft, removeWorkflowSourceOptionsCacheForInputParameter, workflowId]
  );

  const getOptionsResponse = useCallback<GetOptionsResponseFunction>(
    (inputParameterId) => {
      const result = optionsMap.find((o) => o.inputParameterId === inputParameterId);
      const { dataSource, ...data } = result?.data ?? { options: [] };

      return {
        ...data,
        isLoading: result?.isInitialLoading || result?.isRefetching,
      };
    },
    [optionsMap]
  );

  const handleGetDynamicInputComponentState = useCallback<DependencyInputComponentStateFN>(
    ({ inputParameterId, inputParameterValues }) => {
      return getDependencyInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
    [getDependencyInputComponentState]
  );

  const value: UserAppOptionsContextType = {
    setInputParameterIdsShowingOptions,
    getOptionsResponse,
    invalidate,
    getDynamicInputComponentState: (inputParameterId: string) => {
      return handleGetDynamicInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
  };
  return <UserAppOptionsContext.Provider value={value}>{children}</UserAppOptionsContext.Provider>;
};

export const useUserAppOptionsContext = () => {
  const context = useContext(UserAppOptionsContext);
  assert(!!context, new Error(`useUserAppOptionsContext should be used within UserAppOptionsProvider`), 'ERROR');

  return context;
};
