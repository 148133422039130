import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_SUBSCRIPTION } from '../constants';

const extendTrialPeriod = async ({ amountInMillis, workspaceId }: { workspaceId: string; amountInMillis: number }) => {
  const result = await NvAxios.put<void>(
    `${ADMIN_SUBSCRIPTION}/trial/end-date?workspaceId=${workspaceId}&amountInMillis=${amountInMillis}`
  );
  return result?.data;
};

export const useExtendTrialPeriod = () => {
  return useMutation(extendTrialPeriod);
};
