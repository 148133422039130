import { NvButton, NvFlex, NvSkeleton } from '@novaera/core';
import { useGetSlackAuth } from '@novaera/identity-provider';
import React from 'react';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { useSlackWorkspace } from '../controllers/use-slack-workspace';
import { InviteUsersModal } from './invite-users-modal';
import { SlackWorkspaceCard } from './slack-workspace-card';

export const SlackWorkspace: React.FC = () => {
  const { slackWorkspace, isSlackWorkspaceFetchLoading, isInviteUserModalOpen, setIsInviteUserModalOpen } =
    useSlackWorkspace();
  const { slackAuthUrl } = useGetSlackAuth();

  return (
    <>
      {!isSlackWorkspaceFetchLoading && !slackWorkspace && (
        <EmptyState
          variant={EmptyStates.SLACK_CONNECTION}
          CustomButton={
            <NvButton
              size="small"
              color="secondary"
              onClick={() => {
                if (slackAuthUrl) {
                  window.location.href = slackAuthUrl;
                }
              }}
            >
              Add new Slack workspace
            </NvButton>
          }
        />
      )}
      {isSlackWorkspaceFetchLoading && (
        <NvFlex>
          <NvSkeleton height="40px" />
          <NvSkeleton height="40px" />
          <NvSkeleton height="40px" />
        </NvFlex>
      )}
      {!isSlackWorkspaceFetchLoading && !!slackWorkspace && (
        <>
          <SlackWorkspaceCard
            slackWorkspace={slackWorkspace}
            handleInviteSlackUsers={() => setIsInviteUserModalOpen(true)}
          />

          {isInviteUserModalOpen && (
            <InviteUsersModal selectedIntegration={slackWorkspace} onCancel={() => setIsInviteUserModalOpen(false)} />
          )}
        </>
      )}
    </>
  );
};
