import { NvAxios, useQuery } from '@novaera/core';
import { SLACK_CUSTOM_APP_START } from '../keys';
import { SlackCustomAppStartParams, SlackCustomAppStartResponse } from './types';

const getCustomAppsStart = async ({ isAnonymous, appSchemaId }: SlackCustomAppStartParams) => {
  const result = await NvAxios.get<SlackCustomAppStartResponse>(
    isAnonymous ? '/v2/slack/custom-apps/anonymous/start' : '/v2/slack/custom-apps/start',
    {
      params: { appSchemaId },
    }
  );
  return result?.data;
};

export const useGetCustomAppsStart = ({ appSchemaId, isAnonymous }: SlackCustomAppStartParams) => {
  return useQuery(
    [SLACK_CUSTOM_APP_START.all, appSchemaId, isAnonymous],
    () => getCustomAppsStart({ appSchemaId, isAnonymous }),
    {
      enabled: !!appSchemaId,
    }
  );
};
