import {
  NvConditionalRender,
  NvDivider,
  NvFlex,
  NvHelpIcon,
  NvSkeleton,
  NvTooltip,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { ConnectModal } from '../../../../integrations/components/connect-modal';
import { useAppConnectionController } from './controllers/use-app-connection';
import { AppConnectionItem } from './item';
import { ConnectionModalParams } from './types';

export const AppConnections = () => {
  const { palette } = useTheme();
  const { connectionMappings, isLoading, onFormSubmitFinished } = useAppConnectionController();
  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } =
    useNvDialogModalState<ConnectionModalParams>();

  return (
    <>
      <NvConditionalRender when={isLoading}>
        <NvSkeleton variant="rectangular" height="100px" width={'100%'} />
      </NvConditionalRender>

      <NvConditionalRender when={!isLoading && Boolean(connectionMappings && connectionMappings?.length > 0)}>
        <>
          <NvFlex width="100%" gap={'16px'}>
            <NvFlex direction={'row'} alignItems="center" gap="4px">
              <NvTypography variant="h4">My connections</NvTypography>
              <NvTooltip title="When running manual workflows within this app, the selected connections will be used.">
                <NvHelpIcon htmlColor={palette.nv_neutral_alpha[80]} sx={{ width: '12px', height: '12px' }} />
              </NvTooltip>
            </NvFlex>
            <NvFlex gap={'8px'} flexDirection={'column'}>
              {connectionMappings?.map((connectionMapping) => (
                <AppConnectionItem
                  key={connectionMapping.schemaId}
                  onClick={onModalOpenClicked}
                  connectionMapping={connectionMapping}
                />
              ))}
            </NvFlex>
          </NvFlex>
          {passedParameters?.schemaId && (
            <ConnectModal
              onModalCloseClicked={onModalCloseClicked}
              isOpened={isOpened}
              integrationId={passedParameters.integrationId}
              mode="Connect"
              schemaId={passedParameters?.schemaId}
              {...(passedParameters.type === 'reconnect'
                ? {
                    connectionId: passedParameters.connection.id,
                    connected: true,
                    connectionName: passedParameters.connection.name,
                  }
                : {})}
              onFormSubmitFinished={(result) => {
                onModalCloseClicked();
                onFormSubmitFinished(result);
              }}
              modalTitle={passedParameters.type === 'reconnect' ? 'Reconnect' : 'Connect'}
            />
          )}
          <NvDivider orientation="horizontal" sx={{ width: '100%' }} />
        </>
      </NvConditionalRender>
    </>
  );
};
