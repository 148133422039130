import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONFIG_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIG_SCHEMA } from '../keys';
import { ListConfigSchemaResponse } from '../use-list-config-schemas/types';
import { DeleteConfigSchemaParams } from './types';

const deleteConfigSchema = async ({ appId, id }: DeleteConfigSchemaParams) => {
  const result = await NvAxios.delete<void>(`${CONFIG_SCHEMA_ROOT_PATH(appId)}/${id}`);
  return result?.data;
};

export const useDeleteConfigSchema = () => {
  const cache = useQueryClient();

  return useMutation(deleteConfigSchema, {
    onSuccess(data, variables) {
      cache.setQueryData<ListConfigSchemaResponse>(QUERY_KEYS_CONFIG_SCHEMA.list(variables.appId), (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          schemas: oldData.schemas.filter((schema) => schema.id !== variables.id),
        };
      });
    },
  });
};
