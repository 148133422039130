import { InputAdornment } from '@mui/material';
import { capitalizeFirstLetter } from '@novaera/utils';
import classnames from 'classnames';
import { NvTypography } from '../typography';
import { StyledTextField } from './styled';
import { NvTextFieldProps } from './types';

export function NvTextField({
  startIcon,
  endIcon,
  startLabelAdornment,
  endAdornment,
  endLabelAdornment,
  ...props
}: NvTextFieldProps) {
  return (
    <StyledTextField
      {...props}
      variant="standard"
      hiddenLabel
      /**this props add classes to the div parent of native input */
      InputProps={{
        ...props.InputProps,
        ...(startLabelAdornment && {
          startAdornment: (
            <InputAdornment position="start">
              {typeof startLabelAdornment === 'string' ? (
                <NvTypography variant="h5" textColor="subtle" noWrap sx={{ marginTop: '1px' }}>
                  {startLabelAdornment}
                </NvTypography>
              ) : (
                startLabelAdornment
              )}
            </InputAdornment>
          ),
        }),
        ...(endAdornment && {
          endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
        }),
        ...(endLabelAdornment && {
          endAdornment: (
            <InputAdornment position="end">
              {typeof endLabelAdornment === 'string' ? (
                <NvTypography variant="c2" textColor="subtle">
                  {endLabelAdornment}
                </NvTypography>
              ) : (
                endLabelAdornment
              )}
            </InputAdornment>
          ),
        }),
        ...(startIcon && {
          startAdornment: (
            <InputAdornment position="start" disablePointerEvents>
              {startIcon}
            </InputAdornment>
          ),
        }),
        ...(endIcon && {
          endAdornment: (
            <InputAdornment position="end" disablePointerEvents>
              {endIcon}
            </InputAdornment>
          ),
        }),
        className: classnames({
          [`MuiInputBase-size${capitalizeFirstLetter(props.size || 'medium')}`]: true,
          hasIconAdornment: startIcon || endIcon,
          hasLabelAdornment: Boolean(startLabelAdornment),
          hasEndAdornment: Boolean(endAdornment),
        }),
      }}
      /**this prop add classes to native input */
      inputProps={{
        ...props.inputProps,
        className: classnames({
          [`MuiInputBase-inputSize${capitalizeFirstLetter(props.size || 'medium')}`]: true,
        }),
      }}
    />
  );
}
