import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecurrenceFieldCard = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 6px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
`;
