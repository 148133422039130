import { SubscriptionPlan } from '@novaera/actioner-service';
import { numberFormatter } from '@novaera/utils';

export const getItems = (availablePlan: SubscriptionPlan) => {
  const creditsPerMonth = availablePlan?.resourceLimits?.find(
    (resourceLimit) => resourceLimit.resource === 'node-execution'
  )?.quantity;
  const amountInCents = availablePlan?.resourceExtraPricings?.find(
    (resourceLimit) => resourceLimit.resource === 'node-execution'
  )?.amountInCents;

  return [
    {
      itemText: `${numberFormatter({
        number: creditsPerMonth ?? 30000,
        digits: 0,
      })} credits per month`,
      id: 1,
    },
    {
      itemText: `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 10,
      }).format(amountInCents && !isNaN(amountInCents) ? amountInCents / 100 : 0.001)} per additional credit`,
      id: 2,
    },
    { itemText: 'Custom branded Slack bots', id: 3 },
    { itemText: 'Support via shared Slack channel', id: 4 },
  ];
};
