import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANTS } from '../keys';
import { Assistant, AssistantDocument } from '../types';

const updateAssistantDocuments = async ({ appId, document }: { appId: string; document: AssistantDocument }) => {
  const result = await NvAxios.patch<Assistant>(`${ASSISTANTS_ROOT_PATH(appId)}/docs`, document);
  return result?.data;
};

export const useUpdateAssistantDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAssistantDocuments, {
    onSuccess: (response, { appId }) => {
      queryClient.setQueryData(QUERY_KEYS_ASSISTANTS.detail({ appId }), response);
    },
  });
};
