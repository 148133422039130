import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ACTIONER_EVENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS } from '../keys';
import { ActionerEvent } from '../types';
import { DeleteActionerEventParams } from './types';

const deleteActionerEvent = async ({ appId, id }: DeleteActionerEventParams) => {
  const result = await NvAxios.delete(`${ACTIONER_EVENTS_ROOT_PATH(appId)}/${id}`);
  return result?.data;
};

export const useDeleteActionerEvent = () => {
  const cache = useQueryClient();
  return useMutation(deleteActionerEvent, {
    onSuccess: (_, { appId, id }) => {
      cache.setQueryData<ActionerEvent[]>(QUERY_KEYS_ACTIONER_EVENTS.list(appId), (old) => {
        return old?.filter((e) => e.id !== id);
      });
      cache.removeQueries(QUERY_KEYS_ACTIONER_EVENTS.detail(appId, id));
    },
  });
};
