import { useGetBatchUserApps, useGetUserApp, useGetWorkflow, useSearchWorkflows } from '@novaera/actioner-service';
import {
  Initial,
  NvArrowForwardIcon,
  NvAutocomplete,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvFlex,
  NvImage,
  NvSkeleton,
  NvTextField,
  NvTypography,
  useDebounce,
  useToast,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { FC, useMemo, useState } from 'react';
import { ActionSelectMenu, ActionSelectMenuItem } from '../../../../../../components/action-select-menu';
import { USER_APP_WORKFLOW } from '../../../../constants';
import { useGetWorkflowQueryParams } from '../../controllers/use-get-workflow-query-params';
import { useGetAppIdFromDependency } from './controllers/use-get-app-id-from-dependency';
import { WorkflowSelectComponentProps } from './types';
import { isWorkflowSelectComponentMultipleAppSupportProps, isWorkflowSelectMultipleAppValue } from './utils';

export const WorkflowSelectComponent: FC<WorkflowSelectComponentProps> = (props) => {
  const { value, searchParamsTriggerTypes = ['form'], size, hasMultipleAppSupport = false } = props;

  const { userAppId } = useGetWorkflowQueryParams();
  const { data: userApp } = useGetUserApp(userAppId);
  const { getAppIdFromDependency } = useGetAppIdFromDependency({
    rootAppID: userAppId,
    mode: 'sync',
  });
  const { addToast } = useToast();
  const theme = useTheme();

  const appIds = useMemo(
    () =>
      hasMultipleAppSupport
        ? [userAppId, ...(userApp?.dependencies?.map((dependency) => dependency.appId) ?? [])]
        : [userAppId],
    [hasMultipleAppSupport, userApp?.dependencies, userAppId]
  );

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const { data, isLoading } = useSearchWorkflows({
    appIds: appIds,
    keyword: debouncedSearchKeyword,
    sort: { order: 'asc', field: 'name' },
    triggerTypes: searchParamsTriggerTypes,
    includeSearchInvisible: true,
  });

  const searchedWorkflows = useMemo(() => data?.workflows ?? [], [data?.workflows]);

  const workflowParams: {
    appId: string;
    workflowId?: string;
  } = useMemo(() => {
    if (isWorkflowSelectMultipleAppValue(value)) {
      const appId = value.type === 'static' ? value.appId ?? userAppId : getAppIdFromDependency(value.dependencyId);

      if (value.type === 'dependency' && !appId) {
        addToast(
          'Workflow has a dependency which is not found in the user app. Workflow node may not able to see selected workflow and will not work as expected.',
          { variant: 'error' }
        );
        return {
          appId: '',
          workflowId: '',
        };
      }

      assert(!!appId, new Error('There should be appId for the given dependencyId.'));

      return {
        appId: appId,
        workflowId: value.workflowId,
      };
    }
    return {
      appId: userAppId,
      workflowId: value,
    };
  }, [addToast, getAppIdFromDependency, userAppId, value]);

  const { savedWorkflow, isLoading: isSelectedLoading } = useGetWorkflow(workflowParams);

  const { data: appsData, isLoading: isAppsLoading } = useGetBatchUserApps({ appIds });

  const getAppName = (appId: string) => {
    if (appId === userAppId) {
      return userApp?.name;
    }
    return appsData?.apps.find((app) => app.id === appId)?.name;
  };

  const getApp = (appId?: string) => {
    if (appId === userAppId) {
      return userApp;
    }
    return appsData?.apps.find((app) => app.id === appId);
  };

  const getValue = () => {
    return (!isWorkflowSelectComponentMultipleAppSupportProps(props) && value) ||
      (isWorkflowSelectComponentMultipleAppSupportProps(props) && props.value?.workflowId)
      ? savedWorkflow ?? null
      : null;
  };

  return isSelectedLoading ? (
    <NvSkeleton variant="rectangular" height="32px" width={'100%'} />
  ) : (
    <NvFlex gap="8px" flexDirection={'column'} width={'100%'}>
      <NvBox flex="1 1 auto" minWidth={0}>
        <NvAutocomplete
          sx={{ width: '100%' }}
          options={searchedWorkflows}
          getOptionLabel={(option) => option?.name ?? ''}
          size={size}
          groupBy={(option) => getAppName(option.appId) ?? ''}
          renderInput={(inputProps) => {
            const workflow = isWorkflowSelectComponentMultipleAppSupportProps(props) ? getValue() : null;
            const selectedApp = workflow ? getApp(workflow.appId) : null;
            return (
              <NvTextField
                {...inputProps}
                placeholder="Select a workflow"
                {...(selectedApp && {
                  startIcon: (
                    <NvImage
                      imageShape="square"
                      size="small"
                      src={selectedApp.logoUrl}
                      FallBack={<Initial size="small" color={theme.palette.nv_neutral[500]} value={selectedApp.name} />}
                    />
                  ),
                })}
              />
            );
          }}
          PaperComponent={({ children, ...props }) => (
            <ActionSelectMenu {...props}>
              {isLoading || isAppsLoading ? (
                <NvFlex margin="8px" gap="8px">
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="40%" height="20px" />
                  </NvFlex>
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="60%" height="20px" />
                  </NvFlex>
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="30%" height="20px" />
                  </NvFlex>
                </NvFlex>
              ) : (
                children
              )}
            </ActionSelectMenu>
          )}
          renderOption={(props, option) => (
            <ActionSelectMenuItem {...props} key={option.id}>
              <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
                <NvTypography variant="body1" noWrap>
                  {option.name}
                </NvTypography>
              </NvFlex>
            </ActionSelectMenuItem>
          )}
          isOptionEqualToValue={(option, value) => value && option.id === value.id}
          clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
          popupIcon={<NvExpandMoreIcon />}
          onInputChange={(event, value) => setSearchKeyword(value)}
          filterOptions={(o) => o}
          onChange={(_, value) => {
            if (isWorkflowSelectComponentMultipleAppSupportProps(props)) {
              if (!value) {
                props.onChange?.({
                  appId: userAppId,
                  type: 'static',
                  workflowId: '',
                  dependencyId: undefined,
                });
                return;
              }
              if (value?.appId === userAppId) {
                props.onChange?.({
                  appId: value.appId,
                  workflowId: value.id,
                  type: 'static',
                  dependencyId: undefined,
                });
              } else {
                props.onChange?.({
                  dependencyId: userApp?.dependencies?.find((dependency) => dependency.appId === value.appId)?.id ?? '',
                  workflowId: value.id,
                  type: 'dependency',
                  appId: undefined,
                });
              }
            } else {
              props.onChange?.(value?.id);
            }
          }}
          value={getValue()}
        />
      </NvBox>
      {value && savedWorkflow && (
        <NvButton
          size="small"
          color="secondary"
          endIcon={<NvArrowForwardIcon />}
          href={`${USER_APP_WORKFLOW({ userAppId: savedWorkflow.appId, workflowId: savedWorkflow.id })}/designer`}
          rel="noopener noreferrer"
          isFitContent
          target="_blank"
        >
          Go to workflow
        </NvButton>
      )}
    </NvFlex>
  );
};
