import { NvButton, NvDrawer, NvFlex, NvLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PublicNavigationContainer = styled(NvFlex)`
  min-height: 80px;
  background: #27315b;
  position: sticky;
  justify-content: center;
  top: 0;
  z-index: 10;
  width: 100%;
`;

export const PublicNavigationInner = styled(NvFlex)`
  flex-direction: row;
  gap: 60px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1288px;
  margin: 0 auto;
  padding: 16px 40px;

  @media screen and (max-width: 991px) {
    padding: 16px 32px;
  }

  @media screen and (max-width: 767px) {
    padding: 16px 24px;
  }
`;

export const PublicNavigationLogoLink = styled(NvLink)`
  transition: transform 300ms ease, -webkit-transform 300ms ease;

  &:hover {
    transform: scale(0.95);
  }
`;

export const PublicNavigationButtons = styled(NvFlex)`
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;
export const PublicNavigationButton = styled(NvButton)`
  &.MuiButtonBase-root {
    border-radius: 16px;

    &.MuiButton-containedPrimary {
      background-color: #ea5b5b;
      padding: 0 24px;
      transition: transform 0.2s ease;

      &:hover {
        background-color: #d95050;
        box-shadow: none;
        -webkit-transform: scale3d(0.95, 0.95, 1.01);
        transform: scale3d(0.95, 0.95, 1.01);
        color: #fff;
      }
    }

    &.MuiButton-textPrimary {
      padding: 0 0 0 16px;
      color: ${({ theme }) => theme.palette.nv_neutral[0]};

      &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }
    }
  }

  &.mobile {
    &.MuiButtonBase-root {
      &.MuiButton-containedPrimary {
        padding: 32px 24px;
      }
    }
  }
`;

export const PublicNavigationLinks = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  gap: 32px;
  flex: 1 1 auto;
  min-width: 0;

  @media screen and (max-width: 1124px) {
    display: none;
  }
`;

export const PublicNavigationLink = styled(NvLink)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  text-decoration: none;
  font-weight: 400;
  flex: 0 0 auto;

  &:hover {
    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    text-decoration: none;
  }
`;

export const SolutionsDropdownMenuLink = styled(NvLink)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  text-decoration: none;
  font-weight: 400;

  &:hover {
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    text-decoration: none;
  }

  &.mobile {
    color: ${({ theme }) => theme.palette.nv_neutral[0]};

    &:hover {
      color: ${({ theme }) => theme.palette.nv_neutral[0]};
    }
  }
`;

export const SolutionsDropdownMenuItem = styled(NvFlex)`
  padding: 12px;
  border-radius: 6px;
  gap: 6px;

  &:hover {
    background: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  }
`;

export const PublicMenuItem = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  text-decoration: none;
  font-weight: 400;
  flex: 0 0 auto;

  &:hover {
    color: #4457ff;
    text-decoration: none;
  }
`;

export const HamburgerMenuLink = styled(NvLink)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  text-decoration: none;
  font-weight: 600;
  padding: 16px 0;

  &:hover {
    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    text-decoration: none;
  }
`;

export const HamburgerMenuButtonContainer = styled('div')`
  display: none;
  @media screen and (max-width: 1124px) {
    display: flex;
  }
`;
export const HamburgerMenuButton = styled(NvButton)`
  &.MuiButtonBase-root {
    &.MuiButton-containedPrimary {
      &:hover {
        -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);
      }
    }
    &.NvButton-onlyIcon {
      &.MuiButton-sizeLarge {
      }
    }
  }
`;

export const PublicNavigationDrawer = styled(NvDrawer)`
  .MuiDrawer-paper {
    background: #27315b;
  }
`;
