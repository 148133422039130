import Select from '@mui/material/Select';

import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { NvFlex } from '../flex';
import { NvExpandMoreIcon } from '../icons';
import { NvTypography } from '../typography';
import { BootstrapInput, NvSelectMenuItem } from './styled';
import { LabelValue, NvSelectProps } from './types';

export const NvSelect = <V,>({
  options,
  startIcon,
  MenuProps,
  labelVariant,
  placeholder,
  compact,
  ...props
}: NvSelectProps<V>) => {
  const theme = useTheme();

  const hasDescriptionField = useMemo(() => options?.some((option) => option.description) || false, [options]);

  const menuProps = useMemo(
    () => ({
      ...MenuProps,
      sx: {
        '.MuiList-root': {
          padding: '6px',
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
        },
        '.MuiPaper-root': {
          marginTop: '6px',
          backgroundColor: theme.palette.nv_neutral[0],
        },
        ...(hasDescriptionField && {
          '.MuiPaper-root': {
            width: '316px',
            marginTop: '6px',
          },
        }),
        ...MenuProps?.sx,
      },
    }),
    [MenuProps, hasDescriptionField, theme.palette.nv_neutral]
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasStartIcon = useMemo(() => options?.some((option) => option.startIcon) || false, [options]);

  return (
    <Select<V>
      IconComponent={NvExpandMoreIcon}
      input={
        <BootstrapInput
          error={props.error}
          className={classNames({
            'MuiInputBase-sizeLarge': props.size === 'large',
            compact: compact,
            'with-icon': startIcon || hasStartIcon,
            isOpenSelect: isOpen,
          })}
        />
      }
      MenuProps={menuProps}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      {...(props.name ? { 'data-cy': props.name } : {})}
      {...props}
      sx={{
        ...props.sx,
        '.MuiSelect-select .notranslate::after': placeholder
          ? {
              content: `"${placeholder}"`,
              color: `${theme.palette.nv_neutral_alpha[60]}`,
            }
          : {},
      }}
    >
      {props.children
        ? props.children
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options?.map((c: LabelValue<any>) => (
            <NvSelectMenuItem
              value={c.value}
              key={`select_option_${JSON.stringify(c.value)}`}
              className={classNames({
                'has-description': Boolean(c.description),
                'has-divider': Boolean(c.hasDivider),
              })}
            >
              {(startIcon || c.startIcon) && (
                <NvFlex className="MuiSelect-startIcon">{c.startIcon ?? startIcon}</NvFlex>
              )}
              {!hasDescriptionField &&
                (labelVariant ? <NvTypography variant={labelVariant}>{c.label}</NvTypography> : c.label)}
              {hasDescriptionField && (
                <div style={{ width: '100%', paddingTop: '6px', paddingBottom: '6px' }}>
                  <NvTypography variant="h4"> {c.label}</NvTypography>
                  {c.description && (
                    <NvTypography
                      whiteSpace="normal"
                      color={theme.palette.nv_neutral_alpha[300]}
                      variant="body2"
                      marginTop={0.5}
                    >
                      {c.description}
                    </NvTypography>
                  )}
                </div>
              )}
            </NvSelectMenuItem>
          ))}
    </Select>
  );
};
