import { NvDivider } from '@novaera/core';
import { FormIdentifierProvider } from '../../../../../providers/form-identifier-provider';
import { useGetWorkflowQueryParams } from '../controllers/use-get-workflow-query-params';
import { WorkflowReferenceOutputProvider } from '../providers/workflow-reference-output-provider';
import { IntegrationUpdate } from './integration-update';
import { StickyPanel, WorkflowStickyPanelContainer } from './styled';
import { WorkflowPermissions } from './workflow-permissions';
import { WorkflowRun } from './workflow-run';
import { WorkflowWithState } from '@novaera/actioner-service';

export const WorkflowStickyPanel = ({
  isSaveProgress,
  isManaged,
  workflow,
  isGettingWorkflow,
}: {
  isSaveProgress: boolean;
  isManaged?: boolean;
  workflow?: WorkflowWithState;
  isGettingWorkflow: boolean;
}) => {
  const { workflowId: initialWorkflowId, userAppId } = useGetWorkflowQueryParams();

  return (
    <StickyPanel className="react-flow__panel">
      {!isManaged && <IntegrationUpdate />}
      <WorkflowStickyPanelContainer>
        <WorkflowPermissions />
        <NvDivider orientation="vertical" sx={{ height: '12px' }} />
        <FormIdentifierProvider>
          <WorkflowReferenceOutputProvider
            initialWorkflowId={initialWorkflowId}
            userAppId={userAppId}
            workflow={workflow}
            isGettingWorkflow={isGettingWorkflow}
          >
            {({ workflow, currentPage, workflowOutputRefParams, initialFormValues }) => {
              return (
                <WorkflowRun
                  currentPage={currentPage}
                  workflow={workflow}
                  workflowOutputRefParams={workflowOutputRefParams}
                  initialFormValues={initialFormValues}
                  isSaveProgress={isSaveProgress}
                />
              );
            }}
          </WorkflowReferenceOutputProvider>
        </FormIdentifierProvider>
      </WorkflowStickyPanelContainer>
    </StickyPanel>
  );
};
