import { SchemaType } from '@novaera/ah-common';
import { FIELD_SCHEMA } from '../field-schema-map';

export enum ExternalSchemaType {
  time = 'time',
  date = 'date',
}

export const getInputSchema = (schema: SchemaType | ExternalSchemaType) => {
  return FIELD_SCHEMA[schema];
};
