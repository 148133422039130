import { NvDialogModal } from '@novaera/core';

import { useEventModalController } from './controllers/use-event-modal';
import { EventForm } from './form';
import { EventFormValues, EventModalProps } from './types';

export const EventModal: React.FC<React.PropsWithChildren<EventModalProps>> = ({ opened, onClose, event }) => {
  const { initialFormValues, isLoading, onFormSubmit } = useEventModalController({ onClose, event });
  return (
    <NvDialogModal<EventFormValues>
      onFormSubmit={(values) => {
        onFormSubmit(values);
      }}
      formProps={{
        initialValues: initialFormValues,
      }}
      Header={event ? 'Update event' : 'Add event'}
      Body={<EventForm />}
      onCloseButtonClicked={onClose}
      open={opened}
      maxWidth="sm"
      fullWidth
      isLoading={isLoading}
      primaryButtonText={event ? 'Update' : 'Add'}
    />
  );
};
