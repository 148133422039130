import { NvSkeleton } from '@novaera/core';
import { AppPermissionsRow, AppPermissionsRowLeft, AppPermissionsRowRight } from '../styled';

const PermissionsTableRowLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <AppPermissionsRow>
    <AppPermissionsRowLeft>
      <NvSkeleton height="24px" width="24px" variant="circular" />
      <NvSkeleton height="16px" width="60px" variant="rectangular" />
      <NvSkeleton height="16px" width="140px" variant="rectangular" />
    </AppPermissionsRowLeft>
    <AppPermissionsRowRight>
      <NvSkeleton height="24px" width="120px" variant="rectangular" />
      <NvSkeleton height="24px" width="24px" variant="rectangular" />
    </AppPermissionsRowRight>
  </AppPermissionsRow>
);

export const PermissionsTableLoading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <PermissionsTableRowLoading />
    <PermissionsTableRowLoading />
    <PermissionsTableRowLoading />
    <PermissionsTableRowLoading />
  </>
);
