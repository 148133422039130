import { Theme } from '@mui/material';
import { NvDeleteOutlineIcon, NvPowerRoundedIcon, NvTrackChangesIcon } from '@novaera/core';
import { DEFAULT_ACTION_BUTTON_TYPES, HeaderIconType } from './types';

export const getDefaultActions = <FormValue,>(
  theme: Theme,
  formValue: FormValue,
  callback?: (type: DEFAULT_ACTION_BUTTON_TYPES, formValue: FormValue) => void
) => [
  {
    name: 'Delete',
    onClick: () => {
      callback?.('delete', formValue);
    },
    icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
  },
];

export const HEADER_ICONS: Record<HeaderIconType, React.ReactElement> = {
  connection: <NvPowerRoundedIcon />,
  eventRule: <NvTrackChangesIcon />,
};

export const DETAIL_FORM_NAME = 'detail-form';
