import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const UpdateInfoBox = styled(NvFlex)`
  padding: 12px;
  gap: 10px;

  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
`;
