import { NvAxios, useQuery } from '@novaera/core';
import { isAxiosError } from 'axios';
import { ORGANIZATION_MAPPING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ORGANIZATION_MAPPING } from '../keys';
import { Organization } from '../types';

type GetOrganizationMappingParams = {
  workspaceId?: string;
};

export type GetOrganizationMappingResponse = Organization | null;

const getOrganizationMapping = async ({ workspaceId }: GetOrganizationMappingParams) => {
  try {
    const result = await NvAxios.get<GetOrganizationMappingResponse>(
      `${ORGANIZATION_MAPPING_ROOT_PATH}/${workspaceId}/organization-mapping`
    );
    return result?.data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return null;
    }
    throw e;
  }
};

export const useGetOrganizationMapping = (params: GetOrganizationMappingParams) =>
  useQuery<GetOrganizationMappingResponse>(
    QUERY_KEYS_ORGANIZATION_MAPPING.list(params.workspaceId),
    () => getOrganizationMapping(params),
    {
      enabled: !!params.workspaceId,
      retry(failureCount, error) {
        if (isAxiosError(error) && error?.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
    }
  );
