import { NvCheckbox, NvFlex, NvTypography, useForm } from '@novaera/core';
import React, { ReactElement } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import OnChange from '../../../../../action-designer/components/on-change';
import { SIDE_MENU_ITEMS } from '../../../../../user-app/user-app-detail/user-app-side-menu/constants';
import { AppMenuFieldItem, AppMenuFieldWrapper } from './styled';

export const AppMenuFields = ({ fieldName }: { fieldName: string }) => {
  const form = useForm();
  return (
    <NvFlex gap="8px" alignItems="flex-start" width="100%">
      <NvFlex direction="row" alignItems="center" width="100%">
        <NvTypography variant={'h5'} flex="1 1 auto" minWidth={0}>
          App menu items
        </NvTypography>
      </NvFlex>
      <AppMenuFieldWrapper>
        <FieldArray<string> name={fieldName}>
          {({ fields }) =>
            SIDE_MENU_ITEMS.map((sideMenuItem) => {
              return (
                <AppMenuFieldItem gap="8px" alignItems="flex-start" width="100%">
                  <NvFlex flexDirection={'row'} gap={'8px'} alignItems={'8px'}>
                    <NvCheckbox
                      value={sideMenuItem.id}
                      checked={fields.value?.includes(sideMenuItem.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          fields.push(sideMenuItem.id);
                        } else {
                          fields.remove(fields.value.indexOf(sideMenuItem.id));
                        }
                      }}
                    />
                    {React.isValidElement(sideMenuItem.icon) &&
                      React.cloneElement<{ sx: { width: string; height: string } }>(sideMenuItem.icon as ReactElement, {
                        sx: { width: '16px', height: '16px' },
                      })}
                    <NvTypography variant="body2">{sideMenuItem.title}</NvTypography>
                  </NvFlex>
                </AppMenuFieldItem>
              );
            })
          }
        </FieldArray>
        <OnChange name={fieldName}>
          {(values) => {
            if (!values) {
              form.change(fieldName, []);
            }
          }}
        </OnChange>
      </AppMenuFieldWrapper>
    </NvFlex>
  );
};
