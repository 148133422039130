import { NvNodeProps, PORTS } from '@novaera/core';
import { assert } from '@novaera/utils';

import React, { FC } from 'react';
import { useWorkflowPermission } from '../../../../../user-app-permission-boundary/use-workflow-permission';
import * as NodeUIComponents from '../../node-ui-components';
import { usePropertyPanelContext } from '../../properties-panel/provider';
import { NodeMoreIcon } from '../node-more-icon';

export const ReactFlowNodeAdapter: FC<React.PropsWithChildren<NvNodeProps>> = ({ id, data, type, selected }) => {
  assert(type in NodeUIComponents, new Error('type should be exported from respective ui components folder'), 'ERROR');
  const { selectedNode } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();

  return React.createElement(NodeUIComponents[type as keyof typeof NodeUIComponents], {
    id,
    port1: PORTS.port1,
    port2: PORTS.port2,
    isLeaf: data.isLeaf,
    name: data.name,
    alias: data.alias,
    selected: selectedNode?.id === id,
    actions: hasEditPermission ? <NodeMoreIcon selected={selectedNode?.id === id} nodeId={id} /> : undefined,
  });
};
