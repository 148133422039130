import { LOCAL_STORAGE_ACCESS_KEY, ROUTE_DEFAULTS } from '@novaera/constants';
import Cookies from 'js-cookie';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { RefreshTokenComponent } from '../refresh-token-interval-component';
import { ServiceConfigurationProps } from '../types';

export const ServiceConfiguration: React.FC<ServiceConfigurationProps> = () => {
  const { pathname } = useLocation();

  const enabled = useMemo(() => {
    const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);
    const enabledValue =
      !!accessToken &&
      pathname !== ROUTE_DEFAULTS.SIGN_IN &&
      pathname !== ROUTE_DEFAULTS.SIGN_UP &&
      pathname !== ROUTE_DEFAULTS.LOG_OUT &&
      pathname !== ROUTE_DEFAULTS.SOCIAL_LOG_IN &&
      pathname !== ROUTE_DEFAULTS.SLACK_WORKSPACES &&
      pathname !== ROUTE_DEFAULTS.USER_INVITATION;
    return enabledValue;
  }, [pathname]);

  return enabled ? <RefreshTokenComponent /> : null;
};
