import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvProductLogoWithNameIcon({
  width,
  height,
  textColor = '#0D1B3B',
  ...props
}: SvgIconProps & { textColor?: string }) {
  return (
    <SvgIcon
      sx={{
        width: width || 145,
        height: height || 28,
      }}
      viewBox="0 0 840 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M162.406 84.8934L138.522 98.6838L127.195 79.0647C120.116 66.8097 102.428 66.8097 95.3487 79.0647L84.0222 98.6838L60.1375 84.8934L92.1585 11.0323C100.653 -3.67744 121.891 -3.67744 130.385 11.0323L162.406 84.8934Z"
        fill="#6B91E0"
      />
      <path
        d="M102.078 161.807L102.078 189.388L22.0942 198.581C5.11365 198.581 -5.50493 180.194 2.98993 165.485L50.9438 100.817L74.8285 114.607L63.502 134.226C56.423 146.491 65.2672 161.807 79.4253 161.807L102.078 161.807Z"
        fill="#F76D6D"
      />
      <path
        d="M200.44 198.581L120.465 189.388L120.465 161.807L143.118 161.807C157.277 161.807 166.121 146.49 159.042 134.226L147.715 114.607L171.6 100.817L219.554 165.484C228.049 180.194 217.43 198.581 200.44 198.581Z"
        fill="#FAB701"
      />
      <path
        d="M322.115 85.857C316.897 78.5284 307.228 73.7117 297.275 73.7117C287.996 73.7117 279.491 77.1809 271.95 83.8358C264.314 90.5852 260.638 99.6454 260.638 111.501C260.638 123.357 264.409 132.323 271.95 139.167C279.491 145.916 287.996 149.291 297.18 149.291C307.328 149.291 316.897 144.468 322.121 137.145V147.943H335.945V75.1656H322.121V85.863L322.115 85.857ZM274.267 111.401C274.267 103.499 276.684 97.3287 281.519 92.8901C286.448 88.4575 291.956 86.3358 298.339 86.3358C304.722 86.3358 310.13 88.5521 314.964 92.8901C319.893 97.5178 322.216 103.493 322.216 111.401C322.216 119.309 319.799 125.473 314.964 129.911C310.23 134.249 304.621 136.466 298.339 136.466C292.056 136.466 286.448 134.344 281.519 129.911C276.784 125.573 274.462 119.498 274.267 111.401Z"
        fill={textColor}
      />
      <path
        d="M375.898 93.0852C380.922 88.6526 386.53 86.3358 392.429 86.3358C397.133 86.3358 401.105 87.2637 404.462 89.1727C406.495 90.3547 408.315 91.8795 409.929 93.7648C410.673 94.6454 411.383 95.6029 412.051 96.6549L412.63 97.8133L424.231 92.8961L423.363 91.4481C417.08 79.9764 405.963 73.7117 392.139 73.7117C376.578 73.7117 354.633 85.4729 354.633 111.596C354.633 119.598 356.661 126.442 360.721 132.512C364.976 138.487 369.805 142.825 375.023 145.331C380.242 147.931 385.945 149.285 392.033 149.285C406.341 149.285 416.873 143.209 423.351 131.454L424.22 130.006L412.618 125.089L412.039 126.247C407.689 132.996 401.117 136.466 392.417 136.466C386.33 136.466 380.916 134.344 375.886 129.911C370.957 125.479 368.54 119.309 368.54 111.596C368.54 103.883 370.957 97.5237 375.886 93.0852H375.898Z"
        fill={textColor}
      />
      <path
        d="M461.383 57.3226H447.559V75.1535H437.795V87.2043H447.458V133.475C447.458 143.499 453.067 148.8 463.599 148.8C467.565 148.8 471.43 148.032 475.1 146.389L475.969 146.099V133.759L473.942 134.627C471.235 135.685 468.818 136.17 466.4 136.17C462.724 136.17 461.182 134.627 461.182 130.963V87.293H476.069V75.1476H461.377V57.3167L461.383 57.3226Z"
        fill={textColor}
      />
      <path d="M509.267 75.1596H495.254V147.843H509.267V75.1596Z" fill={textColor} />
      <path d="M509.267 50H495.254V63.6879H509.267V50Z" fill={textColor} />
      <path
        d="M578.516 76.2171C565.951 71.2999 552.127 73.8058 540.531 83.0611C532.606 89.5209 527.966 100.218 527.966 111.595C527.966 117.671 529.125 123.161 531.448 128.079C538.888 143.693 554.45 149.284 565.472 149.284C574.846 149.284 583.451 145.815 591.087 139.066C599.012 132.411 602.978 123.161 602.978 111.495C603.073 91.5422 589.834 79.9701 578.522 76.2112L578.516 76.2171ZM581.802 130.005C576.873 134.438 571.554 136.56 565.466 136.56C559.379 136.56 553.965 134.438 548.936 130.005C544.007 125.573 541.589 119.403 541.589 111.69C541.589 103.977 544.007 97.6178 548.936 93.1793C553.959 88.7467 559.568 86.4299 565.466 86.4299C571.365 86.4299 576.873 88.7407 581.802 93.1793C586.731 97.7124 589.148 103.688 589.148 111.69C589.148 119.692 586.731 125.573 581.802 130.005Z"
        fill={textColor}
      />
      <path
        d="M679.078 81.9032C673.764 76.4067 667.287 73.7117 659.361 73.7117C652.015 73.7117 644.574 76.5072 639.834 81.1348C638.097 82.967 636.548 84.7991 635.39 86.4363V75.1597H621.377V147.843H635.39V104.078C635.969 99.8346 638.002 95.9812 641.672 92.1277C645.348 88.1738 650.082 86.2471 655.88 86.2471C666.708 86.2471 673.374 93.8594 673.374 106.011V147.849H687.387L687.293 105.822C687.293 95.2187 684.491 87.1218 679.078 81.915V81.9032Z"
        fill={textColor}
      />
      <path
        d="M766.979 83.6407C759.633 77.2814 750.738 73.8062 741.849 73.8062C732.281 73.8062 722.423 77.9492 715.655 84.7932C708.794 91.6371 705.118 100.987 705.118 111.69C705.118 126.436 712.848 139.261 725.224 145.331C730.833 148.032 736.725 149.285 743.398 149.285C755.963 149.285 765.437 145.431 772.393 137.524L773.167 136.466L764.663 128.658L763.693 129.622C758.569 134.539 751.997 136.95 743.687 136.95C730.638 136.95 721.264 129.332 719.231 117.092H777.712V115.745C778.971 102.252 774.911 90.2955 766.985 83.6466L766.979 83.6407ZM719.036 105.91C720.194 100.03 722.807 95.3073 726.867 92.0272C731.507 88.1738 736.436 86.2412 741.755 86.2412C747.074 86.2412 752.192 88.1679 757.027 92.0272C761.282 95.4019 763.794 100.03 764.663 105.91H719.042H719.036Z"
        fill={textColor}
      />
      <path
        d="M838.936 74.4855C837.967 74.1013 836.519 74.1013 835.165 74.1013C828.304 74.1013 822.405 76.0281 817.187 79.982C814.385 82.2987 812.163 84.8046 810.52 87.6001V75.1652H796.507V147.848H810.52L810.614 111.312C811.483 104.084 814.19 98.203 818.83 93.8649C823.469 89.5269 828.883 87.4051 835.36 87.4051C836.424 87.4051 837.198 87.4997 837.872 87.6001L840 87.8897V74.781L838.936 74.4914V74.4855Z"
        fill={textColor}
      />
    </SvgIcon>
  );
}
