import { NvBox, NvCircularProgress, NvTypography } from '@novaera/core';
import React from 'react';
import { useImpersonate } from './controllers/use-impersonate';
import { ImpersonateProps } from './types';

export const Impersonate: React.FC<React.PropsWithChildren<ImpersonateProps>> = ({ productId }) => {
  const { isError, isLoading } = useImpersonate({
    productId,
  });
  return (
    <NvBox display="flex" gap="8px" flexDirection="column" alignItems="center" marginTop="200px">
      {isLoading && (
        <>
          <NvBox>
            <NvTypography variant="h1">Impersonating</NvTypography>
          </NvBox>
          <NvBox>
            <NvCircularProgress />
          </NvBox>
        </>
      )}
      {isError && (
        <>
          <NvBox>
            <NvTypography variant="h1">An error occurred</NvTypography>
          </NvBox>
        </>
      )}
    </NvBox>
  );
};
