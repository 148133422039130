import { NvAxios, useMutation } from '@novaera/core';
import { GetImpersonationLinkParams, GetImpersonationLinkResponse } from './types';

const getImpersonationLink = async ({ workspaceId, userId, productId }: GetImpersonationLinkParams) => {
  const result = await NvAxios.get<GetImpersonationLinkResponse>(
    `/admin/identity/workspaces/${workspaceId}/users/${userId}/impersonation-link?productId=${productId}`
  );
  return result?.data;
};

export const useGetImpersonationLink = () => {
  return useMutation(getImpersonationLink);
};
