import { useSearchInfiniteIntegrations } from '@novaera/actioner-service';
import {
  Initial,
  NvArrowBackIcon,
  NvBox,
  NvChevronRightIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvPopoverWithAnchor,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDropdownListItem, AppDropdownListItemLoading, DropdownButton } from '../../../../components';
import { DropdownSimpleSearchInput } from '../../../../components/simple-search-input/styled';
import { INTEGRATION_LIST, INTEGRATION_SUMMARY } from '../../../constants';
import { IntegrationSelectInitiator } from './styled';
import { IntegrationSelectProps } from './types';

export const IntegrationSelect: React.FC<React.PropsWithChildren<IntegrationSelectProps>> = ({ integration }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(searchKeyword, 500);
  const { data, isLoading } = useSearchInfiniteIntegrations({
    scopeType: 'workspace',
    ...(debouncedKeyword && { value: debouncedKeyword }),
  });
  const customIntegrations = useMemo(() => data?.pages[0].integrations ?? [], [data?.pages]);
  return (
    <NvPopoverWithAnchor
      id="select-integrations"
      fullInitiatorWidth
      initiator={
        <IntegrationSelectInitiator>
          <NvBox flex="0 0 auto">
            <NvImage
              FallBack={
                <Initial
                  size={'medium'}
                  value={integration?.name || 'User app'}
                  color={theme.palette.nv_neutral[500]}
                />
              }
              src={integration.logoUrl}
              imageShape="square"
              size="small"
              fallbackImageHasBlurEffect
              className="package-image"
            />
          </NvBox>

          <NvTypography variant="h3" flex="1 1 auto" minWidth={0} noWrap>
            {integration?.name}
          </NvTypography>
          <NvChevronRightIcon sx={{ width: '24px', height: '24px', flex: '0 0 auto', transform: 'rotate(90deg)' }} />
        </IntegrationSelectInitiator>
      }
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {({ close }) => (
        <NvFlex width="324px" padding="0">
          <DropdownSimpleSearchInput onKeywordChanged={(keyword) => setSearchKeyword(keyword ?? '')} />
          <NvDivider />
          <NvFlex padding="8px 12px 12px 12px" gap="2px" maxHeight="300px" overflow="auto">
            {isLoading ? (
              <>
                <AppDropdownListItemLoading />
                <AppDropdownListItemLoading />
                <AppDropdownListItemLoading />
              </>
            ) : (
              customIntegrations.map((i) => (
                <AppDropdownListItem
                  key={`user-app-list-item-${i.id}`}
                  name={i.name}
                  logo={i.logoUrl}
                  logoFallBack={
                    <Initial size={'medium'} value={i?.name || 'User app'} color={theme.palette.nv_neutral[500]} />
                  }
                  onItemClick={() => {
                    navigate(INTEGRATION_SUMMARY(i.id));
                    close();
                  }}
                  isSelected={i.id === integration.id}
                />
              ))
            )}
          </NvFlex>
          <NvDivider />
          <NvBox padding="12px">
            <DropdownButton
              onClick={() => {
                navigate(INTEGRATION_LIST);
                close();
              }}
              startIcon={<NvArrowBackIcon sx={{ width: '16px', height: '16px' }} />}
            >
              Go back to integrations
            </DropdownButton>
          </NvBox>
        </NvFlex>
      )}
    </NvPopoverWithAnchor>
  );
};
