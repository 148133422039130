import { BillingPreferences } from '@novaera/actioner-service';

export interface SpendingLimitsProps {
  initialBillingPreferences: BillingPreferences;
}

export enum SPENDING_LIMIT {
  LIMITED = 'limited',
  UNLIMITED = 'unlimited',
}
