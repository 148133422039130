import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppDropdownListItemWrapper = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  flex: 0 0 auto;
  align-self: stretch;
  cursor: pointer;
  background-color: ${({ theme, isSelected }) =>
    !isSelected ? theme.palette.nv_neutral[0] : theme.palette.nv_main[20]};

  &:hover {
    background-color: ${({ theme, isSelected }) =>
      !isSelected ? theme.palette.nv_neutral[10] : theme.palette.nv_main[20]};
  }

  &:active {
    background-color: ${({ theme, isSelected }) =>
      !isSelected ? theme.palette.nv_neutral[20] : theme.palette.nv_main[20]};
  }
`;
