import { JobOperation } from '@novaera/actioner-service';
import { NvAccessTimeRounded, NvEventBusy, NvEventRepeatIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';

export const useJobDescriptor = ({ jobOperationType }: { jobOperationType?: JobOperation['type'] }) => {
  const theme = useTheme();
  const icon = useMemo(() => {
    if (jobOperationType === 'saveRecurring') {
      return <NvEventRepeatIcon htmlColor={theme.palette.nv_node.job} />;
    } else if (jobOperationType === 'saveScheduled') {
      return <NvAccessTimeRounded htmlColor={theme.palette.nv_node.job} />;
    } else if (jobOperationType === 'delete') {
      return <NvEventBusy htmlColor={theme.palette.nv_node.job} />;
    } else {
      return null;
    }
  }, [jobOperationType, theme.palette.nv_node.job]);

  const label = useMemo(() => {
    if (jobOperationType === 'saveRecurring') {
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.job}>
          Recurring job
        </NvTypography>
      );
    } else if (jobOperationType === 'saveScheduled') {
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.job}>
          Scheduled job
        </NvTypography>
      );
    } else if (jobOperationType === 'delete') {
      return (
        <NvTypography variant="h8" color={theme.palette.nv_node.job}>
          Delete job
        </NvTypography>
      );
    } else {
      return null;
    }
  }, [jobOperationType, theme.palette.nv_node.job]);

  return { icon, label };
};
