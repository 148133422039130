import { NvTypography } from '@novaera/core';
import { FileType } from '../types';
import { UploadedItemCard } from './styled';
import { UploadedItemProps } from './types';
import { UploadedItemIcon } from './uploaded-item-icon';

export const UploadedItem: React.FC<React.PropsWithChildren<UploadedItemProps>> = ({ type = FileType.IMAGE, name }) => (
  <UploadedItemCard>
    <UploadedItemIcon type={type} />
    <NvTypography variant="body1" noWrap flex="1 1 auto" minWidth={0}>
      {name}
    </NvTypography>
  </UploadedItemCard>
);
