import { Monaco } from '@monaco-editor/react';
import { Theme } from '@mui/material';
import { generateUniqueId } from '@novaera/utils';
import classNames from 'classnames';
import { editor } from 'monaco-editor';
import { PlaceholderContainer } from './styled';

const getWidgetId = () => `monaco.editor.placeholderWidget.${generateUniqueId()}`;

export const getPlaceholderWidget = (params: {
  editor: editor.IStandaloneCodeEditor;
  monaco: Monaco;
  theme: Theme;
}): editor.IContentWidget => {
  const { monaco, editor, theme } = params ?? {};

  const widgetId = getWidgetId();

  const getWidget = () => document.querySelector(`[widgetid="${widgetId}"]`);

  const getClassNames = () => {
    if (editor) {
      const hasContent = editor.getValue().length > 0;
      return classNames(PlaceholderContainer(theme), { hidden: hasContent });
    }
    return classNames(PlaceholderContainer(theme));
  };

  const updatePlaceholderWidget = (editor: editor.IStandaloneCodeEditor) => {
    const widget = getWidget();
    if (widget) {
      const model = editor.getModel();
      if (model) {
        widget.className = getClassNames();
      }
    }
  };

  const widget: editor.IContentWidget = {
    getDomNode: () => {
      const domNode = document.createElement('div');
      domNode.id = widgetId;
      domNode.className = getClassNames();
      domNode.innerHTML = editor.getModel()?.getLanguageId() === 'json' ? 'JSON supported' : 'Javascript supported';

      domNode.addEventListener('click', () => {
        if (editor) {
          editor.focus();
        }
      });

      return domNode;
    },
    getId: () => widgetId,
    getPosition: () => ({
      position: {
        lineNumber: 1,
        column: 1,
      },
      preference: [monaco.editor.ContentWidgetPositionPreference.EXACT],
    }),
  };

  editor.onDidChangeModelContent((e) => {
    updatePlaceholderWidget(editor);
  });

  return widget;
};
