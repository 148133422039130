import { SvgIcon, SvgIconProps } from '@mui/material';

export function StepLabelEndIntersect({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      className="step-label-end-intersect"
      width="25"
      height="40"
      viewBox="0 0 25 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: '25px', height: '40px', ...(props.sx ?? {}) }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9676 16.9855C25.1531 18.4522 25.1531 20.5478 23.9676 22.0145L9.43005 40L0 40L-3.49691e-06 2.17304e-06L10.2383 1.27798e-06L23.9676 16.9855Z"
      />
    </SvgIcon>
  );
}
