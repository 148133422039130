import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS_SEARCH } from '../keys';
import { SearchWorkflowResponse } from '../use-search-workflows/types';
import { DeleteWorkflowParams } from './types';

const deleteWorkflow = async ({ appId, workflowId }: DeleteWorkflowParams) => {
  const result = await NvAxios.delete(`${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}`);
  return result?.data;
};

export const useDeleteWorkflow = () => {
  const cache = useQueryClient();

  return useMutation(deleteWorkflow, {
    onSuccess: (response, { workflowId }) => {
      const queryCached = cache.getQueryCache().findAll({
        queryKey: QUERY_KEYS_WORKFLOWS_SEARCH.all,
      });
      queryCached.forEach((query) => {
        cache.setQueryData<InfiniteData<SearchWorkflowResponse>>(query.queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old.pages.map(({ workflows, ...others }) => ({
                ...others,
                workflows: workflows.filter((workflow) => workflow.id !== workflowId),
              })),
            };
          }
          return old;
        });
      });
    },
  });
};
