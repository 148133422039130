import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomExclamationMark({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="13.7998" y="5" width="10" height="3.6" rx="1" transform="rotate(90 13.7998 5)" fill="currentColor" />
      <rect x="13.7998" y="16" width="3.6" height="3.6" rx="1" transform="rotate(90 13.7998 16)" fill="currentColor" />
    </SvgIcon>
  );
}

<svg></svg>;
