import { DataModel, useGetDataModel, useUpdateDataModel, useUpdateDraftDataModel } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { FormApi, setIn } from 'final-form';
import { debounce } from 'lodash';
import { useCallback, useMemo } from 'react';
import { TABLE_HIDDEN_FIELD_NAMES } from '../../../../../../../components/data-model-layout/constants';
import { useResetAndUpdateForm } from '../../../../../../../utils/hooks';

export const useDataModelDetailForm = () => {
  const { userAppId: appId, modelId } = useParams();
  const { dataModel, savedDataModel } = useGetDataModel({ appId, modelId, retry: 3 });
  const { mutate: updateDraftDataModel } = useUpdateDraftDataModel();
  const { mutate: updateDataModel, isLoading: isUpdateDataModelLoading } = useUpdateDataModel();
  const { resetAndUpdateForm } = useResetAndUpdateForm<DataModel>();

  const handleSubmit = useCallback(
    (values: DataModel, formApi: FormApi<DataModel, Partial<DataModel>>) => {
      return new Promise<void>((resolve) => {
        const updatedDataModel = {
          ...values,
          ...(savedDataModel ? { name: savedDataModel?.name, description: savedDataModel?.description } : {}),
        };
        updateDataModel(
          { appId, ...updatedDataModel },
          {
            onSuccess: () => {
              resetAndUpdateForm(formApi);
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      });
    },
    [savedDataModel, updateDataModel, appId, resetAndUpdateForm]
  );

  const handleOnChange = useCallback(
    ({
      values,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      updateDraftDataModel({ appId, ...values });
    },
    [appId, updateDraftDataModel]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleOnChangeEventHandler = useCallback(
    debounce((partialValues) => {
      return handleOnChange(partialValues);
    }, 500),
    [handleOnChange]
  );

  const validate = (table: DataModel) => {
    let errors = {};
    table.fields.forEach((f, index) => {
      if (!f.name) {
        errors = setIn(errors, `fields[${index}].name`, 'Field name is required.');
      }

      const nameRegex = /^[a-zA-Z0-9_-]+$/;
      const isNameValid = nameRegex.test(f.name);
      if (!isNameValid) {
        errors = setIn(
          errors,
          `fields[${index}].name`,
          'Field name can only contain alphanumerics, hyphen and underscore.'
        );
      }

      const lastIndex = table.fields.map((f) => f.name).lastIndexOf(f.name);
      if (lastIndex !== index) {
        errors = setIn(errors, `fields[${lastIndex}].name`, 'Field name should be unique.');
        errors = setIn(errors, `fields[${index}].name`, 'Field name  should be unique');
      }
    });
    return errors;
  };

  const dataModelWithOmittedFields = useMemo(() => {
    if (dataModel) {
      return {
        ...dataModel,
        fields: dataModel.fields.filter((field) => !TABLE_HIDDEN_FIELD_NAMES.includes(field.id)) ?? [],
      };
    }
    return undefined;
  }, [dataModel]);

  return {
    dataModel: dataModelWithOmittedFields,
    handleSubmit,
    handleOnChange: debouncedHandleOnChangeEventHandler,
    isUpdating: isUpdateDataModelLoading,
    validate,
  };
};
