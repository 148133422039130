import { NvFocusState } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SchemaFocusState = styled(NvFocusState)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  .NvFocusStateWrapper {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
    padding-bottom: 24px;
    & > div {
      gap: 16px;
      height: 100%;
    }
  }
`;
