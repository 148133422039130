import { AppDocumentCategory, useFetchProfile, useGetAppDirectorySearchMetadata } from '@novaera/actioner-service';
import {
  Initial,
  NvAutocomplete,
  NvCheckbox,
  NvCloseIcon,
  NvCloudUploadIcon,
  NvConditionalRender,
  NvDivider,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvMarkDownEditor,
  NvTextField,
  NvTypography,
  StepField,
  isMaxLength,
  isMinLength,
  isRequired,
  useForm,
  useFormState,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { NvLogoUpload } from '../../../../components/logo-upload';
import { PublishAppFormData } from '../types';
import { AppDocuments } from './app-documents';
import { AppMenuFields } from './app-menu-fields';
import { Contributors } from './contributors';
import { ImagesAndVideos } from './images-and-videos';

export const AppDetailsStep = () => {
  const theme = useTheme();
  const { change } = useForm();
  const {
    values: { appDetails },
  } = useFormState<PublishAppFormData>();

  const { data: userData } = useFetchProfile();
  const { data: searchMetadata } = useGetAppDirectorySearchMetadata({
    isAnonymous: !userData,
  });

  return (
    <NvFlex gap="24px">
      <NvFlex spacing="4px">
        <NvFlex direction="row">
          <NvTypography variant="h5">App icon</NvTypography>
          <NvTypography variant="h5" color={theme.palette.nv_error[40]}>
            *
          </NvTypography>
        </NvFlex>

        <NvLogoUpload
          type="app-schema"
          imageShape="square"
          imageSize="xlarge"
          ImageFallBack={<Initial size="xlarge" color={theme.palette.nv_neutral[500]} value={appDetails.appName} />}
          logoUploadButtonProps={{ text: 'Upload image', startIcon: <NvCloudUploadIcon /> }}
          logoUploadHintProps={{ text: 'Recommended size 120px square', variant: 'h5' }}
          onLogoStateChanged={({ logo: { id }, logoUrl }) => {
            change('appDetails.logoId', id);
            change('appDetails.logoUrl', logoUrl);
          }}
          key={appDetails.logoUrl}
          initialLogoUrl={appDetails.logoUrl}
        />
      </NvFlex>

      <NvDivider />

      <StepField
        help={{
          title: 'App name',
          description: 'Publish your app to App directory to share it with Actioner Community. ',
        }}
      >
        <NvFlex width="315px">
          <NvField
            name="appDetails.appName"
            labelText="App name"
            labelVariant="h5"
            direction="label-on-top"
            validators={[isRequired()]}
            showErrorMessageOnlyWhenBlur
            hasRequiredIndicator
            component={<NvTextField />}
          />
        </NvFlex>
      </StepField>
      <NvDivider />
      <StepField
        help={{
          title: 'Short description',
          description:
            'Short description will be visible on the app card view in App directory. We recommend a short and concisely description.',
        }}
      >
        <NvField
          name="appDetails.shortDescription"
          labelText="Short description"
          labelVariant="h5"
          direction="label-on-top"
          validators={[isRequired(), isMaxLength({ length: 160 })]}
          showErrorMessageOnlyWhenBlur
          hasRequiredIndicator
          component={<NvTextField />}
        />
      </StepField>
      <NvDivider />
      <StepField help={{ title: 'Categories', description: 'You can select multiple categories for your app.' }}>
        <NvField
          name="appDetails.categories"
          labelText="Categories"
          labelVariant="h5"
          direction="label-on-top"
          isAutoComplete
          validators={[isRequired()]}
          showErrorMessageOnlyWhenBlur
          hasRequiredIndicator
          component={
            <NvAutocomplete
              options={searchMetadata?.availableCategories ?? []}
              getOptionLabel={(option) => option}
              renderInput={(props) => <NvTextField {...props} placeholder="Select categories" />}
              clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
              popupIcon={<NvExpandMoreIcon />}
              multiple
            />
          }
        />
      </StepField>
      <NvDivider />
      <StepField
        help={{
          title: 'Description',
          description:
            'You can use description section to provide comprehensive information about your app: The features, use cases and any necessary configuration steps can me written here.',
        }}
      >
        <NvField
          name="appDetails.description"
          labelText="Description"
          labelVariant="h5"
          direction="label-on-top"
          showErrorMessageOnlyWhenBlur
          component={<NvMarkDownEditor />}
          isRequired
          validators={[
            isRequired(),
            isMinLength({
              length: 3,
            }),
            isMaxLength({
              length: 3000,
            }),
          ]}
          hasRequiredIndicator
        />
      </StepField>
      <NvDivider />
      <StepField
        help={{
          title: 'Contributors',
          description: 'Contributors can publish a new version of this app or remove it from the app directory.',
        }}
      >
        <Contributors fieldName="appDetails.contributors" contributors={appDetails.contributors} />
      </StepField>
      <NvDivider />
      <StepField
        help={{
          title: 'Images & Videos',
          description:
            'If you would like to give a visual explanation of your app, you can add Youtube links or images.',
        }}
      >
        <ImagesAndVideos title="Images & Videos" fieldName="appDetails.mediaFiles" enableAutomaticAddModal={false} />
      </StepField>
      <NvDivider />
      <StepField
        help={{
          title: 'Support',
          description: 'Adding documentation and support links will help you explain your app better.',
        }}
      >
        <AppDocuments title="Support" fieldName="appDetails.documents.support" category={AppDocumentCategory.SUPPORT} />
      </StepField>
      <NvDivider />
      <StepField
        help={{
          title: 'Use cases',
          description:
            "A use case is a good way to highlight your app's capabilities. In total, you can add 25 links to use cases and support sections.",
        }}
      >
        <AppDocuments
          title="Use cases"
          fieldName="appDetails.documents.useCases"
          category={AppDocumentCategory.USE_CASE}
        />
      </StepField>
      <StepField
        help={{
          title: 'App menu items',
          description:
            'Configure the accessibility of app menu items. If you uncheck a menu item, it can be hidden and inaccessible.',
        }}
      >
        <AppMenuFields fieldName="appDetails.appMenuItems" />
      </StepField>
      <NvConditionalRender when={Boolean(userData?.isSuperAdmin)}>
        <NvDivider />
        <StepField
          help={{
            title: 'Managed App',
            description: 'Users can receive updates from the managed apps.',
          }}
        >
          <NvField<boolean>
            name="appDetails.managed"
            labelVariant="h5"
            direction="label-on-top"
            type="checkbox"
            component={({ onChange }) => {
              return (
                <NvFlex gap="8px" alignItems="flex-start" width="100%">
                  <NvTypography variant="h5">Managed app</NvTypography>
                  <NvFlex flexDirection={'row'} gap={'8px'}>
                    <NvCheckbox
                      checked={appDetails.managed}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                    <NvTypography variant="body2">This is a managed app</NvTypography>
                  </NvFlex>
                </NvFlex>
              );
            }}
          />
        </StepField>
        <StepField
          help={{
            title: 'Private App',
            description: 'Private apps are not listed in the app directory.',
          }}
        >
          <NvField<boolean>
            name="appDetails.isPrivate"
            labelVariant="h5"
            direction="label-on-top"
            type="checkbox"
            component={({ onChange }) => {
              return (
                <NvFlex gap="8px" alignItems="flex-start" width="100%">
                  <NvTypography variant="h5">Private app</NvTypography>
                  <NvFlex flexDirection={'row'} gap={'8px'}>
                    <NvCheckbox
                      checked={appDetails.isPrivate}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                    <NvTypography variant="body2">This is a private app</NvTypography>
                  </NvFlex>
                </NvFlex>
              );
            }}
          />
        </StepField>
      </NvConditionalRender>
    </NvFlex>
  );
};
