import { NvAxios, useQuery } from '@novaera/core';
import { GetMsTeamsCodeParams, GetMsTeamsCodeResponse } from './types';

const getMsTeamsCode = async (params: GetMsTeamsCodeParams) => {
  const result = await NvAxios.get<GetMsTeamsCodeResponse>('/v1/msteams/code', { params });
  return result?.data;
};

export const useMsTeamsGetCode = (params: GetMsTeamsCodeParams) => {
  return useQuery<GetMsTeamsCodeResponse>(['msteams-code'], () => getMsTeamsCode(params), {
    enabled: !!params.code && !!params.state,
  });
};
