import {
  isJsVariable,
  isRequired,
  NvCustomHelpIcon,
  NvField,
  NvFlex,
  NvLink,
  NvSelect,
  NvSwitch,
  NvTextField,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { Field } from 'react-final-form';
import { useConnectionForm } from './controllers/use-connection-form';

export const ModalBody = () => {
  const { isModalInAddState } = useConnectionForm();
  const { palette } = useTheme();

  return (
    <NvFlex gap="16px">
      <NvTypography variant="body1" flex="1 1 auto">
        {!isModalInAddState
          ? 'Add a new authentication field to request info from users. '
          : 'Setup authentication fields to request info from users. '}
        <NvLink href="https://actioner.com/help/generic-api-key-connection#authentication-fields" target="_blank">
          Learn more.
        </NvLink>
      </NvTypography>
      <NvField
        name={'label'}
        hasRequiredIndicator
        labelText="Label"
        direction="label-on-top"
        labelVariant="h5"
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        infoText={"Enter the field's friendly name. It is the visible field name to users while authenticating."}
        component={<NvTextField placeholder="" size="medium"></NvTextField>}
      ></NvField>
      <NvField
        name={'key'}
        hasRequiredIndicator
        labelText="Key"
        direction="label-on-top"
        labelVariant="h5"
        validators={[isRequired(), isJsVariable()]}
        infoText={
          <NvTooltip
            title={
              <NvTypography variant="body2" onClick={(e) => e.stopPropagation()}>
                Enter a unique key, such as{' '}
                <NvTypography
                  variant="c2"
                  component="span"
                  sx={{ background: palette.nv_neutral_alpha[20] }}
                >{`api_key`}</NvTypography>
                . You can add{' '}
                <NvTypography
                  variant="c2"
                  component="span"
                  sx={{ background: palette.nv_neutral_alpha[20] }}
                >{`{{auth.api_key}}`}</NvTypography>
                . in URL, URL Params and/or Headers to insert the value of this field.
              </NvTypography>
            }
            variant="small"
            placement="top-start"
          >
            <NvCustomHelpIcon fontSize="small" sx={{ width: '12px', height: '12px', ml: '3px' }} />
          </NvTooltip>
        }
        showErrorMessageOnlyWhenBlur
        component={<NvTextField startLabelAdornment="auth." placeholder="" size="medium"></NvTextField>}
      ></NvField>
      <NvField
        name={'inputType'}
        hasRequiredIndicator
        labelText="Field type"
        direction="label-on-top"
        labelVariant="h5"
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        defaultValue={'text'}
        infoText={
          'Select the field type. Use Text field for most text input, or Password to obscure text for secret values.'
        }
        component={
          <NvSelect
            options={[
              { value: 'text', label: 'Text field' },
              { value: 'password', label: 'Password' },
            ]}
            sx={{ maxWidth: '150px' }}
          ></NvSelect>
        }
      ></NvField>
      <NvFlex gap="8px" direction="column" alignItems="flex-start">
        <NvFlex direction="row" gap="5px" alignItems="center" marginTop="4px">
          <NvTypography variant="h5">Mandatory</NvTypography>
          <NvTooltip
            title={'Enable, if users are required to enter a value to this field while authenticating.'}
            variant="small"
            placement="top-start"
          >
            <NvCustomHelpIcon fontSize="small" sx={{ width: '12px', height: '12px', ml: '3px' }} />
          </NvTooltip>
        </NvFlex>

        <Field
          name={'required'}
          render={({ input, meta }) => (
            <NvSwitch onChange={(_, checked) => input.onChange(checked)} checked={input.value}></NvSwitch>
          )}
        ></Field>
      </NvFlex>
      <NvField
        name={'placeholder'}
        labelText="Placeholder"
        direction="label-on-top"
        labelVariant="h5"
        infoText="Enter a text displayed when no value is entered to this field. You can provide a tip to direct users what info to enter while authenticating."
        component={<NvTextField placeholder="" size="medium"></NvTextField>}
      ></NvField>
      <NvField
        name={'helpText'}
        labelText="Helper text"
        direction="label-on-top"
        labelVariant="h5"
        infoText="Enter a helper text for additional notes or hints. It is placed as a small note under the field."
        component={<NvTextField placeholder="" size="medium"></NvTextField>}
      ></NvField>
    </NvFlex>
  );
};
