import { red } from '@mui/material/colors';
import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Default, ThemeMode.Dark);

export default {
  primary: {
    main: '#AA9329',
  },
  secondary: {
    main: '#E67A84',
  },
  error: {
    main: red.A100,
  },
  test: {
    value: 'testDefaultDark',
  },
  text: {
    primary: '#FFF',
  },
  ghost: {},
  ...colors,
};
