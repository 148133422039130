import { NvFlex } from '@novaera/core';
import { CreateWorkspace } from './create-workspace';
import { Workspaces } from './workspaces';

export const SlackWorkspaces: React.FC = () => {
  return (
    <NvFlex rowGap="24px">
      <NvFlex>
        <CreateWorkspace />;
      </NvFlex>
      <Workspaces />
    </NvFlex>
  );
};
