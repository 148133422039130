import { CodeInput, isValidJSON, NvField, NvFlex, NvTypography } from '@novaera/core';
import { ResponseFieldHint } from '../response-field-hint';

export const SamplePayload = () => (
  <NvFlex gap="16px" minHeight="500px">
    <NvTypography variant="body1">
      Add a sample response for your action. Sample response will be shown to workflow builders to help them to design
      their flows.
    </NvTypography>
    <NvField
      validators={[isValidJSON()]}
      name="exampleResponse"
      hint={<ResponseFieldHint />}
      component={<CodeInput context={{}} expectedType="object" mode="json" lineNumbers hasBorder />}
    />
  </NvFlex>
);
