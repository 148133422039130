import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NodeItemWrapper = styled(NvBox)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 40px;
  height: 46px;

  img {
    -webkit-user-drag: none;
  }
`;

export const NodeItem = styled(NvBox)<{ selected?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px 8px 8px;
  gap: 10px;
  width: auto;
  max-width: 300px;
  height: 46px;
  border-radius: 8px;
  transition: border-color 200ms ease, box-shadow 200ms ease;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ selected, theme }) =>
    selected ? `border:1px solid ${theme.palette.nv_main[40]}` : 'border:1px solid transparent'};
  ${({ selected, theme }) => (selected ? theme.mixins.focus : theme.elevations.e100)};
`;

export const NodeItemLeft = styled(NvBox)`
  position: absolute;
  right: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
`;

export const TriggerIconContainer = styled(NvFlex)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 36px;
`;
