import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DataModel, GetDataModelResponse, GetDataModelsResponse, UpdateDataModelOutlineParams } from '../common/types';
import { DATA_MODEL_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DATA_MODEL } from '../keys';

const updateDataModelOutline: (params: UpdateDataModelOutlineParams) => Promise<DataModel> = async (params) => {
  const { appId, ...rest } = params;
  const result = await NvAxios.post<DataModel>(`${DATA_MODEL_ROOT_PATH(appId)}/${rest.id}/outline`, rest);
  return result?.data;
};

export const useUpdateDataModelOutline = () => {
  const cache = useQueryClient();
  return useMutation(updateDataModelOutline, {
    onSuccess: ({ name, description }, { appId, id }) => {
      cache.setQueryData<GetDataModelResponse>(QUERY_KEYS_DATA_MODEL.detail(appId, id), (old) =>
        old
          ? {
              ...old,
              saved: { ...old.saved, name, description },
            }
          : undefined
      );
      cache.setQueryData<GetDataModelsResponse>(QUERY_KEYS_DATA_MODEL.list(appId), (old) =>
        old
          ? {
              ...old,
              models: old.models.map((model) => (model.id === id ? { ...model, name, description } : model)),
            }
          : undefined
      );
    },
  });
};
