import {
  AppConfigDetail,
  AppConfigDetailWithState,
  ConfigState,
  useApplyConfig,
  useDeleteConfig,
  useDeleteDraftConfig,
  useGetConfig,
  useSaveDraftConfig,
  useUpdateConfig,
  useValidateConfig,
} from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, NvTypography, isValidJSON, useConfirmDialog, useNvDialogModalState } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { USER_APP_CONFIG } from '../../../../constants';
import { INVALID_JSON_MESSAGE } from '../../config-detail-body/constants';
import { ConfigDetailFormValues } from '../../config-detail-body/types';
import { useConfigValidationMessage } from '../../config-schema-validation/validation-message';

export const useConfigDetailController = () => {
  const { userAppId, configId } = useParams();
  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { config, savedConfig, isLoading } = useGetConfig({ appId: userAppId, configId });
  const { mutate: deleteConfig, isLoading: isDeleteConfigLoading } = useDeleteConfig();
  const { mutate: saveDraftConfig, isLoading: isSaveDraftConfigLoading } = useSaveDraftConfig();
  const { mutate: updateConfig, isLoading: isNameUpdateLoading } = useUpdateConfig();
  const { mutate: applyConfig, isLoading: isApplyConfigLoading } = useApplyConfig();
  const { mutate: deleteDraftConfig, isLoading: isDeleteDraftConfigLoading } = useDeleteDraftConfig();

  const configPropertiesIsValidationMessage = useMemo(() => {
    return isValidJSON(INVALID_JSON_MESSAGE)(config?.properties);
  }, [config?.properties]);

  const configDetailHeaderActions = [
    {
      name: 'Delete',
      isLoading: isDeleteConfigLoading,
      onClick: () => {
        openConfirm({
          title: `Delete ${savedConfig?.name ?? 'This config'}`,
          message: (
            <NvTypography variant="body1">
              You are about to delete <b>{savedConfig?.name ?? 'This config'}</b>. It will impact the linked workflows,
              potentially causing them to stop working. This process cannot be undone. Are you sure you want to proceed?
            </NvTypography>
          ),
          onConfirm: () => {
            deleteConfig(
              { appId: userAppId, configId },
              {
                onSuccess: () => {
                  navigate(USER_APP_CONFIG(userAppId));
                },
              }
            );
          },
        });
      },
      icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
    },
  ];

  const handleDiscard = () => {
    return new Promise<void>((resolve) => {
      deleteDraftConfig(
        { appId: userAppId, configId },
        {
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  const handleApply = () => {
    return new Promise<void>((resolve) => {
      applyConfig(
        { appId: userAppId, configId },
        {
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  const { mutate: validateConfig, data: configValidationResult } = useValidateConfig();

  const { validationMessages } = useConfigValidationMessage({ configValidationResult });

  const handleValidateConfig = useCallback(
    (config: AppConfigDetailWithState) => {
      if (config.schemaId) {
        validateConfig({
          appId: userAppId,
          id: config.id,
          payload: {
            draft: config.state === ConfigState.DRAFT,
            schemaId: config.schemaId,
          },
        });
      }
    },
    [userAppId, validateConfig]
  );

  useEffect(() => {
    if (config) {
      handleValidateConfig(config);
    }
    //only check if a new config is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.id, handleValidateConfig]);

  const handleOnChange = useCallback<(prop: ConfigDetailFormValues) => void>(
    (formValues) => {
      saveDraftConfig(
        { appId: userAppId, configId, ...formValues },
        {
          onSuccess: (result) => {
            handleValidateConfig({
              ...result,
              state: ConfigState.DRAFT,
            });
          },
        }
      );
    },
    [configId, handleValidateConfig, saveDraftConfig, userAppId]
  );

  const handleUpdateConfigModalSubmit = ({ name }: { name?: AppConfigDetail['name'] }) => {
    return new Promise<void>((resolve) => {
      assert(!!name, new Error('Config name should be defined'), 'ERROR');
      updateConfig(
        { appId: userAppId, configId, name: name },
        {
          onSuccess: () => {
            onModalCloseClicked();
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  const handleRestore = useCallback(
    (properties: string) => {
      saveDraftConfig({ appId: userAppId, configId, schemaId: config?.schemaId, properties });
    },
    [config?.schemaId, configId, saveDraftConfig, userAppId]
  );

  return {
    configId,
    savedConfig,
    onModalOpenClicked,
    onModalCloseClicked,
    isOpened,
    config,
    isLoading,
    isSaveDraftConfigLoading,
    isNameUpdateLoading,
    isApplyConfigLoading,
    isDeleteDraftConfigLoading,
    configDetailHeaderActions,
    userAppId,
    handleDiscard,
    handleApply,
    handleOnChange,
    handleUpdateConfigModalSubmit,
    handleRestore,
    configPropertiesIsValidationMessage,
    validateConfig: handleValidateConfig,
    validationMessages,
  };
};
