export const OUTPUT_DESCRIPTION_MAP = {
  chart: {
    pie: 'Divides a circle into slices as proportional to their contribution towards the total sum.',
    bar: 'Display visual comparison between values such as volume change.',
  },
  table: (
    <>
      Visually convert <b>JSON to a paginated table</b> for easy navigation.
    </>
  ),
  'key-value-table': 'Visually show an entity using a two column special table.',
  markdown: (
    <>
      Add blocks with <b>markdown formatting</b> for your text outputs.
    </>
  ),
  slack: <>Add slack block kit for your output.</>,
};
