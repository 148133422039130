import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_WORKFLOW_DEPENDENCIES } from '../keys';
import { GetWorkflowInputParamDependencies, GetWorkflowInputParamDependenciesResponse } from '../types';

const getWorkflowDependencies = async ({ appId, isDraft, workflowId }: GetWorkflowInputParamDependencies) => {
  const result = await NvAxios.get<GetWorkflowInputParamDependenciesResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/trigger-input-parameter-dependencies`,
    {
      params: {
        draft: isDraft,
      },
    }
  );
  return result?.data;
};

export const useGetWorkflowInputParamDependencies = (param: GetWorkflowInputParamDependencies) => {
  return useQuery<GetWorkflowInputParamDependenciesResponse>(
    QUERY_KEYS_WORKFLOW_DEPENDENCIES.workflow(param),
    () => getWorkflowDependencies(param),
    {
      enabled: !!param.appId && !!param.workflowId,
    }
  );
};
