export enum CronUnits {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY_OF_MONTH = 'dayOfMonth',
  MONTH = 'month',
  DAY_OF_WEEK = 'dayOfWeek',
  YEAR = 'year',
}

type CronInputValue = Partial<Record<CronUnits, string>>;

export interface CronInputProps {
  value?: CronInputValue | null;
  onChange?: (value?: CronInputValue) => void;
  direction?: 'row' | 'column';
}
