import { AssistantModel, useGetAssistantModels, useUpdateAssistantModel } from '@novaera/actioner-service';
import { NvFlex, NvSelect } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import React, { useMemo } from 'react';
import { AIAssistantSection } from '../section';
import { LanguageModelProps } from './types';

export const LanguageModel: React.FC<LanguageModelProps> = ({ languageModel }) => {
  const { userAppId } = useParams();
  const { data } = useGetAssistantModels({ appId: userAppId });
  const { mutate: updateAssistantModel, isLoading: isUpdateAssistantModelLoading } = useUpdateAssistantModel();
  const languageModelOptions = useMemo(
    () => data?.models.map((model) => ({ label: model, value: model })) ?? [],
    [data?.models]
  );
  const handleLanguageModelUpdate = async (value: AssistantModel) => {
    return new Promise<void>((resolve) => {
      updateAssistantModel(
        { appId: userAppId, model: value },
        {
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <AIAssistantSection
      title="Language model"
      infoText={
        <>
          Actioner AI assistant feature is powered by OpenAI, allowing users to select their preferred language model.
          Our pricing adjusts accordingly based on the chosen OpenAI language model. Visit the{' '}
          <a href="https://actioner.com/help/node-execution-and-credit-usage">OpenAI pricing page</a> for further
          details.
        </>
      }
    >
      <NvFlex gap="4px" minWidth="300px">
        <NvSelect<AssistantModel>
          disabled={isUpdateAssistantModelLoading}
          value={languageModel}
          options={languageModelOptions}
          onChange={(e) => {
            assert(
              !(e.target instanceof HTMLInputElement),
              new Error('[LanguageModel] - e.target should not be instance of HTMLInputElement'),
              'ERROR'
            );

            const model = e.target.value;
            handleLanguageModelUpdate(model);
          }}
        />
      </NvFlex>
    </AIAssistantSection>
  );
};
