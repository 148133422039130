import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SubscriptionCardWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  margin: 8px 0px;
`;
