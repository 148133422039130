import { NvAxios, useQuery } from '@novaera/core';
import { ACTIONER_EVENTS_FROM_APPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS_ALL_APPS } from '../keys';
import { ListActionerEventsFromAppsResponse } from './types';

type Params = {
  payload: {
    appId: string;
  };
};

const listActionerEventsFromApps = async (params: Params) => {
  const result = await NvAxios.get<ListActionerEventsFromAppsResponse>(`${ACTIONER_EVENTS_FROM_APPS_ROOT_PATH()}`, {
    params: params.payload,
  });
  return result?.data;
};

export const useListActionerEventsFromApps = (params: Params) => {
  return useQuery<ListActionerEventsFromAppsResponse>(
    QUERY_KEYS_ACTIONER_EVENTS_ALL_APPS.list(params.payload.appId),
    () => listActionerEventsFromApps(params)
  );
};
