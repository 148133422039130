import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

const SquareBracket = styled(NvBox)`
  width: 6px;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.palette.nv_neutral[60]};
`;

export const LeftSquareBracket = styled(SquareBracket)`
  border-right: none;
  border-radius: 2px 0 0 2px;
`;

export const RightSquareBracket = styled(SquareBracket)`
  border-left: none;
  border-radius: 0 2px 2px 0;
`;
