import { CodeInput, NvButton, NvCloseIcon, NvField, NvFlex, NvTypography, isValidJSON } from '@novaera/core';
import { PropertyPanelHeader } from '../../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { HintStyle } from './styled';

export const ExampleResponsePropertyPanel = ({ onCloseClicked }: { onCloseClicked: () => void }) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  return (
    <NvFlex width="100%" height={'100%'}>
      <PropertyPanelHeader
        title={'Sample response'}
        actions={
          <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
            <NvCloseIcon />
          </NvButton>
        }
      />

      <NvField
        name="exampleResponse"
        formControlStyle={{ width: '100%', flex: '1 1 0', minHeight: 0 }}
        validators={[isValidJSON()]}
        showErrorMessageOnlyWhenBlur
        component={
          <CodeInput
            growingHeight
            context={workflowCodeInputContext}
            mode="json"
            initialHeight="178px"
            placeholder="You can paste sample payload here.."
          />
        }
      />
      <HintStyle>
        <NvTypography variant="body3" textColor="subtle">
          JSON is supported.
        </NvTypography>
      </HintStyle>
    </NvFlex>
  );
};
