import { NvChevronRightIcon, NvField, NvFlex, NvSkeleton, NvTypography, isValidJSON } from '@novaera/core';
import classNames from 'classnames';
import { useState } from 'react';
import { INVALID_JSON_MESSAGE } from '../../../../config/config-detail/config-detail-body/constants';
import { ChevronIcon } from '../../../../user-app-side-menu/styled';
import { Body, Card, CodeInput, Title } from '../styled';

const LoadingSkeleton = () => {
  return (
    <NvFlex gap={'8px'} margin={'16px'}>
      <NvSkeleton variant="rectangular" width={200} />
      <NvSkeleton variant="rectangular" width={100} />
      <NvSkeleton variant="rectangular" width={150} />
    </NvFlex>
  );
};
export const SchemaJSONViewer = ({
  isLoading,
  formName,
  title,
}: {
  isLoading: boolean;
  title: string;
  formName: string;
}) => {
  const [isCollapseOpened, setIsCollapseOpened] = useState(true);
  return (
    <Card>
      <Title
        onClick={() => {
          setIsCollapseOpened(!isCollapseOpened);
        }}
      >
        <ChevronIcon
          className={classNames({
            'chevron-icon': true,
            opened: isCollapseOpened,
          })}
        >
          <NvChevronRightIcon className="opener" />
        </ChevronIcon>

        <NvTypography variant="h4">{title}</NvTypography>
      </Title>
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <Body
          className={classNames({
            collapsed: !isCollapseOpened,
          })}
        >
          <NvField
            formControlStyle={{
              width: '100%',
              height: '100%',
            }}
            name={formName}
            showErrorMessageOnlyWhenBlur
            validators={[isValidJSON(INVALID_JSON_MESSAGE)]}
            component={<CodeInput lineNumbers context={{}} expectedType="object" mode="json" disableExpandMode />}
          />
        </Body>
      )}
    </Card>
  );
};
