import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RuleValueCard = styled(NvBox)`
  padding: 0px 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 6px;

  &.success {
    background-color: ${({ theme }) => theme.palette.nv_success[10]};
    border: 1px solid ${({ theme }) => theme.palette.nv_success[60]};
  }

  &.error {
    background-color: ${({ theme }) => theme.palette.nv_error[10]};
    border: 1px solid ${({ theme }) => theme.palette.nv_error[40]};
  }
`;
