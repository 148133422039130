import { DateTimeFormat, SchemaType } from '@novaera/ah-common';
import { NvBox, NvConditionalWrap, NvFlex, NvLink, NvReactJson, NvTag, NvTypography } from '@novaera/core';
import React from 'react';

import { JsonViewWrapper } from '../../../json-view-wrapper/styled';
import { RENDER_CELL_BODY_MODE } from '../types';
import { SingleLineJsonTag } from './json-tag';
import { RecordCellBodyProps } from './types';

const RecordCellBody: React.FC<React.PropsWithChildren<RecordCellBodyProps>> = ({ value, type, mode, format }) => {
  switch (type) {
    case SchemaType.phone:
    case SchemaType.uuid:
    case SchemaType.integer:
    case SchemaType.float:
    case SchemaType.string:
    case SchemaType.email:
    case SchemaType.recordReference:
      return (
        <NvConditionalWrap
          condition={mode === RENDER_CELL_BODY_MODE.POPOVER}
          wrap={(children) => <NvBox paddingTop="3px">{children}</NvBox>}
        >
          <NvTypography variant="body2" noWrap={mode !== RENDER_CELL_BODY_MODE.POPOVER}>
            {value}
          </NvTypography>
        </NvConditionalWrap>
      );
    case SchemaType.dateTime:
      return (
        <NvConditionalWrap
          condition={mode === RENDER_CELL_BODY_MODE.POPOVER}
          wrap={(children) => <NvBox paddingTop="3px">{children}</NvBox>}
        >
          <NvTypography variant="body2" noWrap>
            {format === DateTimeFormat.iso_8601 ? new Date(value).toString() : value}
          </NvTypography>
        </NvConditionalWrap>
      );
    case SchemaType.boolean:
      return (
        <NvTypography variant="body2" noWrap>
          {JSON.stringify(value)}
        </NvTypography>
      );
    case SchemaType.array:
      return (
        <NvFlex
          gap="4px"
          {...(mode === RENDER_CELL_BODY_MODE.CELL
            ? { direction: 'row', alignItems: 'center' }
            : { direction: 'column', alignItems: 'flex-start' })}
        >
          {value.map((v: unknown, index: number) => (
            <NvTag key={index} label={typeof v === 'string' ? v : JSON.stringify(v)} />
          ))}
        </NvFlex>
      );
    case SchemaType.map:
      return mode === RENDER_CELL_BODY_MODE.CELL ? (
        <SingleLineJsonTag value={JSON.stringify(value)} />
      ) : (
        <JsonViewWrapper>
          <NvReactJson
            src={value}
            hideCopyIcon
            style={{ minWidth: '250px', maxHeight: '450px', overflow: 'auto', padding: '2px' }}
            enableClipboard={false}
          />
        </JsonViewWrapper>
      );
    case SchemaType.url:
      return (
        <NvConditionalWrap
          condition={mode === RENDER_CELL_BODY_MODE.POPOVER}
          wrap={(children) => <NvBox padding="2px 0">{children}</NvBox>}
        >
          <NvLink href={value}>{value}</NvLink>
        </NvConditionalWrap>
      );
    default:
      return (
        <NvTypography variant="body2" noWrap={mode !== RENDER_CELL_BODY_MODE.POPOVER}>
          {value}
        </NvTypography>
      );
  }
};

export const MemoizedRecordCellBody = React.memo(
  RecordCellBody,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
