import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { RecentAnalyticsQueriesResponse } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRecentAnalyticsQueries = async ({ pageParam }: any) => {
  const result = await NvAxios.get<RecentAnalyticsQueriesResponse>('/admin/analytics/queries', {
    params: { limit: 20, ...(pageParam ? { next: pageParam } : {}) },
  });
  return result?.data;
};

export const useGetRecentAnalyticsQueries = () => {
  return useInfiniteQuery<RecentAnalyticsQueriesResponse>(['recent-analytics-queries'], getRecentAnalyticsQueries, {
    getNextPageParam: (lastPage) => lastPage?.next,
    retry: false,
  });
};
