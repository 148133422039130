import { NvLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RunWorkFlowWrapperLink = styled(NvLink)`
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[20]};
  display: flex;
  flex: 1 1 auto;
  border-radius: 6px;
  padding: 8px;
  text-decoration: none;
  color: inherit;
  justify-content: space-between;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;
