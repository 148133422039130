import { styled } from '@novaera/theme-provider';

export const MarkdownWrapper = styled('div')`
  box-sizing: border-box;
  border-radius: 6px;

  .w-md-editor {
    border: 1px solid rgba(10, 45, 98, 0.08);
    border-radius: 6px;
    box-shadow: unset;
    transition: background-color 0.4s ease, border-color 0.5s ease, box-shadow 0.6s ease;
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]} !important;
    height: auto !important;
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }

    &.has-error {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border: 1px solid ${({ theme }) => theme.palette.nv_error[40]};
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
      ${({ theme }) => theme.mixins.error};

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }
    }

    &:focus-within:not(.has-error),
    &:active:not(.has-error) {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
      ${({ theme }) => theme.mixins.focus};

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }
    }
  }

  .w-md-editor-toolbar {
    border-radius: 6px 6px 0 0;
    background: transparent;
    border-bottom: unset;
    height: auto !important;
    padding: 8px;

    ul {
      li {
        &.w-md-editor-toolbar-divider {
          margin: -13px 6px 0 6px !important;
          height: 16px;
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
        }

        button {
          color: ${({ theme }) => theme.palette.nv_neutral[1000]};
          padding: 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 auto;
          width: auto;
          height: 28px;

          border-radius: 6px;

          &:hover {
            background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
          }

          &:disabled {
            opacity: 0.6;
          }

          &:disabled:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.palette.nv_neutral[1000]};
          }

          &:active {
            background-color: ${({ theme }) => theme.palette.nv_main[20]};
            color: ${({ theme }) => theme.palette.nv_main[40]};
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }

        .w-md-editor-toolbar-child {
          ${({ theme }) => theme.elevations.e300};
          border-radius: 8px;
          margin-top: 6px;
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]};

          button {
            width: 40px;
          }
        }
      }
    }
  }

  .w-md-editor-content {
    border-radius: 0 0 6px 0;
    background: transparent;
    height: auto !important;
    min-height: 250px;
    overflow: auto;

    .w-md-editor-aree {
      .w-md-editor-text-pre {
        word-break: break-all;
      }
    }

    .w-md-editor-preview {
      position: initial;

      &::before {
        display: inline-block;
        content: 'Preview mode';
        font-size: 12px;
        font-weight: 600;
        padding: 4px 8px;
        border-radius: 8px;
        margin-bottom: 12px;
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
        border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      }
    }

    .w-md-editor-text {
      height: auto;
      min-height: 250px;
    }
  }

  .w-md-editor-fullscreen {
    height: 100% !important;

    .w-md-editor-content {
      height: 100% !important;
      max-height: none;
    }

    .w-md-editor-text {
      height: inherit;
    }
  }

  .w-md-editor-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 14px;

    .w-md-editor-text-pre {
      color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    }
  }
`;
