import { NvAxios, useMutation, useQuery } from '@novaera/core';
import { useState } from 'react';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { GetUserAppParams, GetUserAppResponse } from '../types';

type UseGetUserAppOptions = {
  onError?: (error: unknown) => void;
  retry?: (failureCount: number, error: unknown) => boolean;
};

export const getUserApp = async (userAppId: GetUserAppParams) => {
  const result = await NvAxios.get<GetUserAppResponse>(`${USER_APP_ROOT_PATH}/${userAppId}`);
  return result?.data;
};

export const useGetUserApp = (userAppId: GetUserAppParams, options?: UseGetUserAppOptions) => {
  const [dataFetchCount, setDataFetchCount] = useState<number>(0);
  const query = useQuery<GetUserAppResponse | undefined>(
    QUERY_KEYS_USER_APP.detail(userAppId),
    () => getUserApp(userAppId),
    {
      enabled: !!userAppId,
      refetchInterval: (data, queryParam) => {
        setDataFetchCount(queryParam.state.dataUpdateCount);
        return data?.installationStatus === 'in-progress' && queryParam.state.dataUpdateCount < 2 ? 3000 : false;
      },
      ...(options?.retry ? { retry: options.retry } : {}),
      ...(options?.onError ? { onError: options.onError } : {}),
    }
  );
  return {
    ...query,
    isInitialLoading:
      query.isInitialLoading || (query.data?.installationStatus === 'in-progress' && dataFetchCount < 2),
  };
};

// need for workflow references to find caller app id from dependency id
export const useGetUserAppAsync = () => {
  return useMutation(getUserApp);
};
