import {
  ActionNode,
  InputParameter,
  IntegrationAction,
  JobTargetParameterMapping,
  ParameterTypes,
  UIComponentType,
} from '@novaera/actioner-service';
import { Context } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { FormIdentifierProvider } from '../../../providers/form-identifier-provider';
import { useAppIdParameterMapping } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/common/use-app-id-parameter-mapping';
import { ActionParameterMapper } from '../../parameter-mapper';
import { ActionOnParameterMappingsChanged } from '../../parameter-mapper/types';
import { PropertyPanelSimpleSection } from '../../property-panel';
import { JobTargetProvider } from './provider';
import { getWorkflowId } from './utils';

export const JobTargetComponent = ({
  onChange,
  actionNode,
  selectedAction,
  context,
  userAppId,
  workflowId,
}: {
  onChange: ActionOnParameterMappingsChanged;
  actionNode: ActionNode;
  selectedAction: IntegrationAction;
  context?: Context;
  userAppId: string;
  workflowId: string;
}) => {
  const { appIdParameterMappings: replaceParameterMappingsForOptions } = useAppIdParameterMapping({
    userAppId,
    action: selectedAction,
  });

  const jobTargetParameterMapping = useMemo(
    () => actionNode.parameterMappings.find((parameterMapping) => parameterMapping.type === ParameterTypes.JOB_TARGET),
    [actionNode.parameterMappings]
  );

  return (
    <JobTargetProvider
      key={`job-target-provider-${actionNode.actionId}`}
      initialValues={{
        workflowId: getWorkflowId(selectedAction, actionNode.parameterMappings),
      }}
    >
      <PropertyPanelSimpleSection>
        <ActionParameterMapper
          type="in-workflow"
          appId={userAppId}
          workflowId={workflowId}
          key={`job-target-parameter-mapper-workflow-${actionNode.actionId}`}
          actionId={actionNode.actionId}
          integrationId={actionNode.integrationId}
          versionNumber={actionNode.version}
          onParameterMappingsChanged={onChange}
          context={context}
          filterInputParameters={(input: InputParameter) => input.name === 'workflowId'}
          initialParameterMappings={actionNode.parameterMappings}
          replaceParameterMappingsForOptions={replaceParameterMappingsForOptions}
        />
      </PropertyPanelSimpleSection>
      <FormIdentifierProvider
        initialFormId={
          jobTargetParameterMapping?.type === ParameterTypes.JOB_TARGET ? jobTargetParameterMapping?.formId : undefined
        }
      >
        {({ formId }) => {
          return (
            <ActionParameterMapper
              type={'in-workflow'}
              appId={userAppId}
              workflowId={workflowId}
              key={`job-target-parameter-mapper-${actionNode.actionId}`}
              actionId={actionNode.actionId}
              context={context}
              integrationId={actionNode.integrationId}
              versionNumber={actionNode.version}
              onParameterMappingsChanged={({
                inputParametersValues: parameterMappings,
                activeInputParameterIds,
                shouldAddFormId,
              }) => {
                assert(
                  parameterMappings.length === 1,
                  new Error('Job target mapping should have only one mapping'),
                  'ERROR'
                );
                const jobTargetMapping = parameterMappings[0];
                assert(
                  jobTargetMapping.type === ParameterTypes.JOB_TARGET,
                  new Error('Job target mapping should have only one mapping'),
                  'ERROR'
                );
                const newJobTargetMapping: JobTargetParameterMapping = { ...jobTargetMapping, formId };

                onChange({ inputParametersValues: [newJobTargetMapping], activeInputParameterIds, shouldAddFormId });
              }}
              filterInputParameters={(input: InputParameter) => input.uiComponent.type === UIComponentType.JOB_TARGET}
              initialParameterMappings={actionNode.parameterMappings}
              replaceParameterMappingsForOptions={replaceParameterMappingsForOptions}
            />
          );
        }}
      </FormIdentifierProvider>
    </JobTargetProvider>
  );
};
