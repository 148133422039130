import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { GetUserAppResponse } from '../types';
import { SearchUserAppResponse } from '../use-search-user-apps/types';
import { UpdateUserAppLogoAndNameParams, UpdateUserAppLogoAndNameResponse } from './types';

const updateUserAppLogoAndName = async ({ appId, logoId, name }: UpdateUserAppLogoAndNameParams) => {
  const result = await NvAxios.patch<UpdateUserAppLogoAndNameResponse>(`${USER_APP_ROOT_PATH}/${appId}/view-elements`, {
    ...(logoId ? { logoId } : {}),
    name,
  });
  return result?.data;
};

export const useUpdateUserAppLogoAndName = () => {
  const cache = useQueryClient();
  return useMutation(updateUserAppLogoAndName, {
    onSuccess: (response, { appId }) => {
      cache.setQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId), response);

      cache.setQueriesData(QUERY_KEYS_USER_APP.search(), (old?: SearchUserAppResponse) => {
        if (!old) return old;

        return {
          next: old.next,
          totalHits: old.totalHits,
          apps: old.apps.map((app) => {
            if (app.id === appId) {
              return {
                ...app,
                name: response.name,
                logoId: response.logoId,
                logoUrl: response.logoUrl,
              };
            }
            return app;
          }),
        };
      });
    },
  });
};
