import { NvFlex, NvSkeleton } from '@novaera/core';

export const EventsLoading = () => (
  <NvFlex gap="16px">
    <NvFlex direction="row" alignItems="center" justifyContent="space-between" gap="8px">
      <NvFlex direction="row" alignItems="center" gap="8px">
        <NvSkeleton variant="circular" width="40px" height="40px" />
        <NvSkeleton variant="rectangular" width="220px" height="24px" />
      </NvFlex>
      <NvSkeleton variant="rectangular" width="90px" height="24px" />
    </NvFlex>
    <NvFlex direction="row" alignItems="center" justifyContent="space-between" gap="8px">
      <NvSkeleton variant="rectangular" width="220px" height="24px" />
      <NvSkeleton variant="rectangular" width="125px" height="24px" />
    </NvFlex>
    <NvFlex gap="8px">
      <NvSkeleton variant="rectangular" height="68px" />
      <NvSkeleton variant="rectangular" height="68px" />
      <NvSkeleton variant="rectangular" height="68px" />
    </NvFlex>
  </NvFlex>
);
