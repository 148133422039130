import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../flex';

export const ShortCutWrapper = styled(NvFlex)`
  background: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 6px;

  align-items: center;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 1px;
  padding-bottom: 1px;
  width: fit-content;
`;
