import { NvBox, NvButton, NvCustomCheckIcon } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CloneFlaggerBodyWrapper = styled(NvBox)`
  min-width: 270px;
  height: 40px;
  background: ${({ theme }) => theme.palette.nv_neutral[600]};
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 18px 30px -3px rgba(10, 45, 98, 0.08);
  border-radius: 12px;
  padding: 0 8px;
`;

export const CloneFlaggerCheckIcon = styled(NvCustomCheckIcon)`
  color: ${({ theme }) => theme.palette.nv_success[60]};
  margin-right: 8px;
`;

export const CloneFlaggerLine = styled(NvBox)`
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[50]};
  height: 100%;
  margin: 0 8px 0 16px;
`;

export const CloneFlaggerButton = styled(NvButton)`
  background: none;
  &:hover {
    background: none;
  }
`;
