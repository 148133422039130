import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DataModel, GetDataModelResponse, GetDataModelsResponse, UpdateDataModelParams } from '../common/types';
import { DATA_MODEL_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DATA_MODEL } from '../keys';

const updateDataModel: (params: UpdateDataModelParams) => Promise<DataModel> = async (params) => {
  const { appId, ...rest } = params;
  const result = await NvAxios.put<DataModel>(`${DATA_MODEL_ROOT_PATH(appId)}/${rest.id}`, rest);
  return result?.data;
};

export const useUpdateDataModel = () => {
  const cache = useQueryClient();
  return useMutation(updateDataModel, {
    onSuccess: (dataModel, { appId, id }) => {
      cache.setQueryData<GetDataModelResponse>(QUERY_KEYS_DATA_MODEL.detail(appId, id), { saved: dataModel });
      cache.setQueryData<GetDataModelsResponse>(QUERY_KEYS_DATA_MODEL.list(appId), (old) =>
        old
          ? {
              ...old,
              models: old.models.map((model) =>
                model.id === dataModel.id
                  ? { ...model, hasDraft: false, numberOfFields: dataModel.fields.length }
                  : model
              ),
            }
          : undefined
      );
    },
  });
};
