import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PPDrawerItem = styled(NvBox)`
  width: 430px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  position: relative;
  opacity: 0;
  margin-left: -430px;
  flex: 0 0 auto;

  &.first {
    border-left: none;
    margin-left: 0;
    opacity: 1;
  }

  &.open {
    margin-left: 0;
    opacity: 1;
    transition: margin-left 300ms ease, opacity 300ms ease;
  }

  &.close {
    opacity: 0;
    margin-left: -430px;
    transition: margin-left 300ms ease, opacity 400ms ease;
  }
`;

export const PropertyPaneWrapper = styled(NvBox)`
  position: absolute;
  top: calc(48px + 16px);
  left: 16px;
  bottom: 16px;
  width: auto;
  max-width: calc(100vw - 32px - 64px - 136px);
  overflow: auto;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  transform: translateX(calc(-100% - 24px));

  ${({ theme }) => theme.elevations.e100};

  &.open {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease, opacity 400ms ease;
  }

  &.close {
    transform: translateX(calc(-100% - 24px));
    opacity: 0;
    transition: transform 300ms ease, opacity 400ms ease;
  }
`;
