import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { VersionHistoryFocusState, VersionHistoryJsonViewWrapper } from '../../version-history/detail/styled';

export const GeneratedConfigResultFocusState = styled(VersionHistoryFocusState)``;
export const GeneratedConfigResultJsonViewWrapper = styled(VersionHistoryJsonViewWrapper)`
  flex: 1 1 auto;
  min-width: 0;
`;

export const GeneratedConfigRightSection = styled(NvFlex)`
  flex-direction: column;
  flex: 0 0 auto;
  width: 400px;
  max-height: 100%;
  overflow: auto;
  gap: 16px;
  @media screen and (max-width: 1024px) {
    width: 300px;
  }
`;
