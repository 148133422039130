import { JobRecurrenceEndType, RecurrenceTimeUnit } from '@novaera/actioner-service';

export const RECURRENCE_END_OPTIONS: { label: string; value: JobRecurrenceEndType }[] = [
  { label: 'Never', value: JobRecurrenceEndType.NEVER },
  { label: 'After a specific date', value: JobRecurrenceEndType.DATE_TIME },
  { label: 'After a number of occurrences', value: JobRecurrenceEndType.NUMBER_OF_TIMES },
];

export const RECURRENCE_TIME_UNITS = [
  { label: 'Minute', value: RecurrenceTimeUnit.MINUTE },
  { label: 'Hour', value: RecurrenceTimeUnit.HOUR },
  { label: 'Day', value: RecurrenceTimeUnit.DAY },
  { label: 'Weekdays', value: RecurrenceTimeUnit.WEEKDAYS },
  { label: 'Weekly', value: RecurrenceTimeUnit.WEEKLY },
  { label: 'Monthly', value: RecurrenceTimeUnit.MONTHLY },
  { label: 'Annually', value: RecurrenceTimeUnit.ANNUAL },
  { label: 'Custom (Cron)', value: RecurrenceTimeUnit.CUSTOM },
];

export const DEFAULT_CRON_EXPRESSION = '0 0 * * ? *';
export const WEEKDAYS_CRON_EXPRESSION = '0 0 ? * MON-FRI *';
export const WEEKLY_CRON_EXPRESSION = '0 0 ? * MON,TUE *';
export const MONTHLY_CRON_EXPRESSION = '0 9 1 * ? *';
export const ANNUAL_CRON_EXPRESSION = '0 9 1 JAN ? *';

export const DEFAULT_RATE_UNIT = '1';
