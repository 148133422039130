import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppAssetsWrapper = styled(NvFlex)`
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1280px) {
    form {
      height: auto !important;
    }
  }
`;

export const DescriptionTabBodyWrapper = styled(NvFlex)`
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
  flex: 1 1 auto;
  min-width: 0;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const DescriptionTabLeftWrapper = styled(NvFlex)`
  flex: 1 1 auto;
  min-width: 0;
  gap: 16px;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const DescriptionTabRightWrapper = styled(NvFlex)`
  align-items: flex-start;
  gap: 24px;
  position: sticky;
  top: 0;
  width: 366px;
  flex: 0 0 auto;

  @media screen and (max-width: 1280px) {
    width: 100%;
    order: -1;
    position: initial;
  }
`;
