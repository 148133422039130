import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomEmptyIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.26074 4.34604C2.91309 4.6937 2.91309 5.26421 3.26074 5.61186L4.65136 7.00248C3.53708 8.67835 2.97549 10.7464 3.234 12.9661C3.69754 17.0131 6.98688 20.3025 11.0339 20.766C13.2536 21.0245 15.3217 20.4629 16.9975 19.3486L18.3881 20.7393C18.7358 21.0869 19.2974 21.0869 19.645 20.7393C19.9927 20.3916 19.9927 19.83 19.645 19.4824L4.51764 4.34604C4.43518 4.2634 4.33723 4.19782 4.22939 4.15309C4.12155 4.10835 4.00594 4.08532 3.88919 4.08532C3.77244 4.08532 3.65684 4.10835 3.549 4.15309C3.44116 4.19782 3.3432 4.2634 3.26074 4.34604ZM12.0858 19.0456C8.15464 19.0456 4.95444 15.8454 4.95444 11.9142C4.95444 10.5949 5.31992 9.36474 5.95283 8.29504L15.705 18.0472C14.6353 18.6801 13.4051 19.0456 12.0858 19.0456ZM8.46663 5.78123L7.17407 4.47976C8.58252 3.54377 10.2673 3 12.0858 3C17.0064 3 21 6.99356 21 11.9142C21 13.7327 20.4562 15.4175 19.5202 16.8259L18.2188 15.5245C18.8517 14.4637 19.2172 13.2335 19.2172 11.9142C19.2172 7.98304 16.017 4.78284 12.0858 4.78284C10.7665 4.78284 9.53634 5.14832 8.46663 5.78123Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
