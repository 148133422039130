import { NvAxios, useQuery } from '@novaera/core';
import { assert } from '@novaera/utils';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { AppConfigDetail } from '../types';
import { GetConfigParams, GetConfigResponse } from './types';
import { useConfig } from './use-config';

const getConfig = async ({ appId, configId }: GetConfigParams) => {
  const result = await NvAxios.get<GetConfigResponse>(`${CONFIG_ROOT_PATH(appId)}/${configId}`);
  if (!result?.data) {
    return;
  } else {
    let draft: AppConfigDetail | undefined;
    if (result?.data?.draft) {
      draft = result.data.draft;
    }

    let saved: AppConfigDetail | undefined;
    if (result?.data?.saved) {
      saved = result.data.saved;
    }

    assert(!!saved, new Error('there should always be a saved config if there is config'), 'ERROR');

    return { draft, saved };
  }
};

export const useGetConfig = ({ appId, configId }: GetConfigParams) => {
  const {
    data: configResponse,
    isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  } = useQuery(QUERY_KEYS_CONFIGS.detail(appId, configId), () => getConfig({ appId, configId }), {
    enabled: !!appId && !!configId,
    keepPreviousData: true,
  });

  const { draftConfig, savedConfig, config } = useConfig(configResponse);

  return {
    config,
    savedConfig,
    draftConfig,
    isLoading: isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  };
};
