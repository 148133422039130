import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';

export const RadioStyledWrapper = styled(NvBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 16px;
  height: 16px;

  .MuiRadio-root {
    position: relative;
    width: 16px;
    height: 16px;
    padding: 0;
    flex: 0 0 auto;
    border-radius: 8px;
    opacity: 1;
    border: 2px solid ${({ theme }) => theme.palette.nv_neutral[600]};
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    box-shadow: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 4px);
      top: calc(50% - 4px);
      width: 8px;
      height: 8px;
      border-radius: 6px;
      border: 2px solid ${({ theme }) => theme.palette.nv_neutral[0]};
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
      border-color: ${({ theme }) => theme.palette.nv_neutral[700]};
    }

    svg {
      width: 16px;
      height: 16px;
    }

    input {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      margin-left: -4px;
      margin-top: -4px;
    }

    &.Mui-checked {
      background-color: ${({ theme }) => theme.palette.nv_main[40]};
      border-color: ${({ theme }) => theme.palette.nv_main[40]};
      color: ${({ theme }) => theme.palette.nv_neutral[0]};

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_main[50]};
        border-color: ${({ theme }) => theme.palette.nv_main[50]};
      }

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.nv_main[30]};
        border-color: ${({ theme }) => theme.palette.nv_main[30]};
      }

      &::after {
        opacity: 1;
      }
    }

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
      border-color: ${({ theme }) => theme.palette.nv_neutral[70]};
    }
  }
`;
