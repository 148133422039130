import { useQuery } from '@tanstack/react-query';
import { NvAxios } from '../../axios';
import { QUERY_KEYS_REALTIME_METADATA } from './keys';

const getRealTimeServerMetadata =
  ({ metadataPath }: { metadataPath: string }) =>
  async () => {
    const result = await NvAxios.get(metadataPath);
    return result?.data;
  };

export const useRealTimeServerMetadata = (params: {
  metadataPath: string;
  onMetadataFailed: (error: unknown) => void;
}) =>
  useQuery<{
    apiURI: string;
    connectionRefreshPeriodInMinutes: number;
    region: string;
    userId: string;
    workspaceId: string;
  }>(QUERY_KEYS_REALTIME_METADATA.all, getRealTimeServerMetadata({ metadataPath: params.metadataPath }), {
    onError: params.onMetadataFailed,
    staleTime: 0,
    retry: 2,
  });
