import { NvArrowBackIcon, NvButton, NvDivider, NvDNSIcon, NvFlex, NvTypography } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { USER_APP_DATABASE } from '../../../../constants';

export const RecordsMenuHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useParams();
  const navigate = useNavigate();
  return (
    <NvFlex direction="row" alignItems="center" gap="12px">
      <NvButton
        onlyIcon
        color="secondary"
        size="small"
        onClick={() => {
          navigate(USER_APP_DATABASE(userAppId));
        }}
      >
        <NvArrowBackIcon />
      </NvButton>
      <NvDivider orientation="vertical" sx={{ height: '12px' }} />
      <NvFlex direction="row" alignItems="center" gap="4px">
        <NvDNSIcon sx={{ width: '20px', height: '20px' }} />
        <NvTypography variant="h4">Table records</NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
