import { assert } from '@novaera/utils';
import { createContext, useContext, useReducer } from 'react';
import { authenticationFieldReducer } from './reducer';
import { IntegrationAuthenticationFieldsContextType } from './types';

const IntegrationConnectionAuthenticationFieldContext =
  createContext<IntegrationAuthenticationFieldsContextType>(undefined);

export const IntegrationConnectionAuthenticationFieldProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authenticationFieldReducer, {});

  const value = { state, dispatch };

  return (
    <IntegrationConnectionAuthenticationFieldContext.Provider value={value}>
      {children}
    </IntegrationConnectionAuthenticationFieldContext.Provider>
  );
};

export const useIntegrationConnectionAuthenticationFieldContext = () => {
  const context = useContext(IntegrationConnectionAuthenticationFieldContext);
  assert(
    !!context,
    new Error(
      `useIntegrationConnectionAuthenticationFieldContext should be used within IntegrationConnectionAuthenticationFieldProvider`
    ),
    'ERROR'
  );

  return context;
};
