import {
  CodeInput,
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvTypography,
  isAllRequired,
  isRequired,
} from '@novaera/core';
import { FC } from 'react';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { KeyValueSubPanelType } from '..';
import { PropertyPanelSimpleSection } from '../../../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../../../controllers/use-get-workflow-contexts';
import { HTTPKeyValueSubPanelMap } from '../constants';

export interface PropertyPanelKeyValueSectionProps {
  keyValueSubPanelType: KeyValueSubPanelType;
}

export const PropertyPanelKeyValueSection: FC<PropertyPanelKeyValueSectionProps> = ({ keyValueSubPanelType }) => {
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const {
    fields: { push },
  } = useFieldArray(HTTPKeyValueSubPanelMap[keyValueSubPanelType].fieldName);
  return (
    <>
      <PropertyPanelSimpleSection>
        <NvFlex gap={'16px'} alignItems="flex-start">
          <NvButton
            color="secondary"
            size="small"
            startIcon={<NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />}
            fullWidth={false}
            onClick={() => {
              push({ value: '', key: '' });
            }}
          >
            Add new {HTTPKeyValueSubPanelMap[keyValueSubPanelType].singleUnitName}
          </NvButton>
        </NvFlex>
      </PropertyPanelSimpleSection>
      <NvFlex direction="column-reverse">
        <FieldArray name={HTTPKeyValueSubPanelMap[keyValueSubPanelType].fieldName} validate={isAllRequired()}>
          {({ fields }) => {
            return fields.map((name, index) => {
              return (
                <PropertyPanelSimpleSection key={name}>
                  <NvFlex direction={'row'} gap="8px">
                    <NvFlex direction="column" key={`field-array-item-${index}`} gap="8px" flex="1 1 auto">
                      <NvFlex direction="row" gap="8px" alignItems="center">
                        <NvBox component={'span'} width="40px">
                          <NvTypography variant="body2">Key</NvTypography>
                        </NvBox>

                        <NvField
                          name={`${name}.key`}
                          showErrorMessageOnlyWhenBlur
                          isRequired
                          validators={[isRequired()]}
                          component={<CodeInput context={workflowCodeInputContext} />}
                        />
                      </NvFlex>
                      <NvFlex direction="row" gap="8px" alignItems="center">
                        <NvBox component={'span'} width="40px">
                          <NvTypography variant="body2">Value</NvTypography>
                        </NvBox>
                        <NvField
                          name={`${name}.value`}
                          showErrorMessageOnlyWhenBlur
                          isRequired
                          validators={[isRequired()]}
                          component={<CodeInput context={workflowCodeInputContext} />}
                        />
                      </NvFlex>
                    </NvFlex>

                    <NvButton
                      onlyIcon
                      color="ghost"
                      onClick={() => {
                        fields.remove(index);
                      }}
                      size="small"
                    >
                      <NvDeleteOutlineIcon />
                    </NvButton>
                  </NvFlex>
                </PropertyPanelSimpleSection>
              );
            });
          }}
        </FieldArray>
      </NvFlex>
    </>
  );
};
