import { NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { WorkflowHistoryList } from './workflow-history-list';
import { WorkflowHistoryNodeExecutions } from './workflow-history-node-executions';

export const WorkflowHistory: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { workflowExecutionId } = useParams();
  return (
    <NvFlex direction="row" width="100%" height="100%" flex="1 1 0" minHeight={0}>
      <WorkflowHistoryList />
      {workflowExecutionId && <WorkflowHistoryNodeExecutions />}
    </NvFlex>
  );
};
