import { ConnectionMapping, useListConnectionMappingService } from '@novaera/actioner-service';
import { NvSkeleton, NvTypography } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useMemo } from 'react';

export const ChatToolWorkspace: React.FC<{ findConnectionPredicate: (value: ConnectionMapping) => void }> = ({
  findConnectionPredicate,
}) => {
  const { userAppId } = useParams();
  const { data: connectionSchemas, isInitialLoading } = useListConnectionMappingService({
    appId: userAppId,
  });

  const connection = useMemo(() => {
    const connectionSchema = connectionSchemas?.find(findConnectionPredicate);
    return connectionSchema && connectionSchema.connections.length > 0
      ? { ...connectionSchema.connections[0] }
      : undefined;
  }, [connectionSchemas, findConnectionPredicate]);

  return isInitialLoading ? (
    <NvSkeleton variant="rectangular" height="24px" width="120px" />
  ) : connection ? (
    <NvTypography>{connection.name}</NvTypography>
  ) : null;
};
