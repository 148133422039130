import { Integration, SearchConnection, WorkflowWithState } from '@novaera/actioner-service';

export type ConnectionSectionProps = WorkflowConnectionSectionProps | HTTPConnectionSectionProps;

type ConnectionSectionPropsBase = {
  label?: React.ReactNode;
  onConnectionChange: (connection?: { connectionId?: string }) => void;
  connectionIdValue?: string;
  workflow: WorkflowWithState;
};

export type WorkflowConnectionSectionProps = {
  type: 'schema-selected-with-integration';
  integration?: Integration;
} & ConnectionSectionPropsBase;

type HTTPConnectionSectionProps = {
  type: 'schema-selection-included';
  onConnectionSchemaIdChange: (connectionSchemaId?: string | null) => void;
  connectionSchemaIdValue?: string;
} & ConnectionSectionPropsBase;

export type ConnectionOption =
  | {
      label: string;
      type: 'connection';
      value: {
        connectionId: string;
        user: SearchConnection['user'];
      };
    }
  | {
      label: string;
      type: 'triggered-user-connection';
      value: {
        connectionId: undefined;
      };
    };

// typescript type guard function that checks if the given value is a ConnectionOption
export function isConnectionOption(value: unknown): value is ConnectionOption {
  if (!value) return false;

  return (
    (value as ConnectionOption).type !== undefined &&
    ((value as ConnectionOption).type === 'connection' ||
      (value as ConnectionOption).type === 'triggered-user-connection')
  );
}
