import { useMemo } from 'react';
import { useFetchProfile } from '../use-fetch-profile';
import { useFetchWorkspaceList } from '../use-fetch-workspace-list';

export const useGetCurrentWorkspaceInfo = () => {
  const { data, isInitialLoading, isError } = useFetchWorkspaceList();
  const { data: user, isLoading: isUserLoading, isError: isUserError } = useFetchProfile();

  const workspaceInfo = useMemo(() => {
    const result = data?.workspaces.find((workspace) => workspace.id === user?.workspaceId);
    return result;
  }, [data?.workspaces, user]);

  return {
    isLoading: isInitialLoading || isUserLoading,
    isError: isError || isUserError,
    workspaceInfo,
  };
};
