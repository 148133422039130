import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import React from 'react';
import { NvBox } from '../../box';
import { NvConditionalWrap } from '../../conditional-wrap';
import { NvFlex } from '../../flex';
import { NvArrowForwardIcon } from '../../icons';
import { Initial } from '../../initial';
import { NvImage } from '../../profile-image';
import { NvTooltip } from '../../tooltip';
import { NvTypography } from '../../typography';
import { NodeListItemCard, NodeListItemContent } from './styled';
import { NodeListItemProps } from './types';

export const NodeListItem: React.FC<React.PropsWithChildren<NodeListItemProps>> = ({
  name,
  description,
  icon,
  onItemClick,
  hasChild,
  rightComponent,
  extraData,
  className,
}) => {
  const theme = useTheme();
  return (
    <NvConditionalWrap
      condition={Boolean(extraData?.tooltip)}
      wrap={(children) => {
        assert(!!extraData?.tooltip, new Error('Tooltip should be defined to show tooltip.'));
        return <NvTooltip children={children} title={extraData?.tooltip} />;
      }}
    >
      <NodeListItemCard
        onClick={(event) => {
          !extraData?.disabled && onItemClick(event);
        }}
        disabled={extraData?.disabled || false}
        isCompact={extraData?.isCompact}
        className={className}
      >
        {icon && (
          <NvBox flex="0 0 auto">
            {React.isValidElement(icon) && typeof icon !== 'string' ? (
              icon
            ) : (
              <NvImage
                imageShape="square"
                src={icon as string}
                size="small"
                hasNoElevation
                FallBack={<Initial value={name} size="small" color={theme.palette.nv_neutral[500]} />}
              />
            )}
          </NvBox>
        )}

        <NodeListItemContent>
          <NvFlex direction="row" className="name-wrapper" alignItems="center" gap="8px">
            <NvTypography variant="h5">{name}</NvTypography>
            {rightComponent}
          </NvFlex>

          {description && <NvTypography variant="body3">{description}</NvTypography>}
        </NodeListItemContent>
        {hasChild && <NvArrowForwardIcon sx={{ width: '16px', height: '16px', margin: '4px 0' }} />}
      </NodeListItemCard>
    </NvConditionalWrap>
  );
};
