import { CollapseCard, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConfigSchemaValidationWrapper = styled(NvFlex)`
  gap: 16px;
  flex-direction: column;
  padding: 8px 8px 16px 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  background-color: ${({ theme }) => theme.palette.nv_error[10]};
`;

export const ReviewCardWrapper = styled(CollapseCard)`
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  gap: 8px;

  ul {
    margin: 0px;
    padding-left: 20px;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;
