export enum NavigationContextAction {
  SetOpenedPopoverId = 'set-opened-popover-id',
  RemoveOpenedPopover = 'remove-opened-popover',
}

export type State = {
  openedPopoverId?: string;
};

export type ActionType<T> = { type: NavigationContextAction; payload: T };

export type SetOpenedPopoverIdPayload = { popoverId: string };
export type RemoveOpenedPopoverPayload = { popoverId: string };

export type Dispatch = (action: ActionType<SetOpenedPopoverIdPayload | RemoveOpenedPopoverPayload>) => void;

export type NavigationContextProviderProps = { children: React.ReactNode };
