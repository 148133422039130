import React from 'react';
import { PropertyPanelFunctionSectionContainer } from './styled';

export const PropertyPanelFunctionSection: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <PropertyPanelFunctionSectionContainer className={className}>{children}</PropertyPanelFunctionSectionContainer>
  );
};
