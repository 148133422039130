import { ValueTypes } from '@novaera/actioner-service';
import { CodeInput, FieldTitle, NvFlex, NvTypography } from '@novaera/core';
import { DynamicCronFieldProps } from './types';

export const DynamicCronField = ({ value, onChange, context, label, hint }: DynamicCronFieldProps) => (
  <NvFlex direction="row" gap="8px">
    <FieldTitle direction="label-on-side" labelText={label} labelVariant="h6" labelWidth="90px" />
    <NvFlex direction="column" gap="4px" width="100%">
      <CodeInput
        value={value?.value}
        onChange={(value) => {
          onChange({
            type: ValueTypes.STRING,
            value: value,
          });
        }}
        context={context}
        placeholder={'{{...}}'}
      />
      {hint && (
        <NvTypography variant="body3" textColor="subtle">
          {hint}
        </NvTypography>
      )}
    </NvFlex>
  </NvFlex>
);
