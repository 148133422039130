import { useGetUserApp, useGetUserAppSetup, useListConnectionMappingService } from '@novaera/actioner-service';
import { useMatch } from '@novaera/route';
import { FC, PropsWithChildren } from 'react';
import { ROUTES } from '../../../common/routes';
import { AppLoading } from '../../../components/app-loading';
import { InstallUserAppFlowComponent } from './install-user-app-flow-component';

export const InstallUserAppFlow: FC<PropsWithChildren<{ appId: string }>> = ({ appId }) => {
  const { data: userAppSetup, isInitialLoading: isUserAppSetupLoading } = useGetUserAppSetup({ appId });
  const { data: connectionMappingsData, isLoading: isConnectionMappingsLoading } = useListConnectionMappingService({
    appId,
  });
  const { data: userApp } = useGetUserApp(appId);
  const isAppSetupRoute = useMatch(ROUTES.UserAppSetup);

  return !isUserAppSetupLoading && !isConnectionMappingsLoading && userAppSetup && connectionMappingsData && userApp ? (
    <InstallUserAppFlowComponent
      userApp={userApp}
      connectionMappingsData={connectionMappingsData}
      userAppSetup={userAppSetup}
    />
  ) : isAppSetupRoute ? (
    <AppLoading />
  ) : null;
};
