import { NvCustomEmptyIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { PropertyPanelSimpleSection } from '../../..';

export const PropertyPanelEmptySection = ({ emptyText }: { emptyText: string }) => {
  const theme = useTheme();

  return (
    <PropertyPanelSimpleSection>
      <NvFlex direction="row" alignItems="flex-start" gap="8px" padding="3px 0">
        <NvCustomEmptyIcon
          sx={{ width: '16px', height: '16px', marginTop: '1px' }}
          htmlColor={theme.palette.nv_neutral[60]}
        />
        <NvTypography variant="body2" textColor="ghost">
          {emptyText}
        </NvTypography>
      </NvFlex>
    </PropertyPanelSimpleSection>
  );
};
