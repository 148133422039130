import {
  ActionOptionProducer,
  IntegrationType,
  ParameterMappings,
  ParameterTypes,
  UIComponentType,
  ValueTypes,
  useGetIntegration,
  useGetIntegrationAction,
  useGetWorkflow,
} from '@novaera/actioner-service';
import {
  NvActionFilledIcon,
  NvButton,
  NvChip,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvImage,
  NvMenuWithItems,
  NvTypography,
  useField,
  useForm,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import {
  ActionParameterMapper,
  ActionSearchField,
  PropertyPanelEmptySection,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
  TestActionProvider,
  TestActionResult,
  UpdateVersionButton,
} from '../../../../../../../../../components';
import { IntegrationInitialLogo } from '../../../../../../../../../integrations/components/integration-initial-logo';
import { useFormIdentifierContext } from '../../../../../../../../../providers/form-identifier-provider';
import { useWorkflowPermission } from '../../../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { RecordsParameterMapping } from '../../../action-node-property-panel-drawer/action-node-properties/records-parameter-mapping';
import { SearchEntitiesFilterParameterMapping } from '../../../action-node-property-panel-drawer/action-node-properties/search-entities-filter-parameter-mapping';
import { IntegrationBox } from '../../../integration-trigger-property-panel-drawer/integration-trigger-properties/styled';
import { hasIntegrationActionSpecialInput } from '../utils';
import { FormTriggerDynamicSourceMapping } from './source-mapping';

export const FormTriggerDynamicSourceDetail: React.FC<
  React.PropsWithChildren<{
    title: string;
    dataSourceIndex: number;
    onDelete: () => void;
    onCloseClicked: () => void;
  }>
> = ({ title, dataSourceIndex, onDelete, onCloseClicked }) => {
  const theme = useTheme();
  const { formId } = useFormIdentifierContext();
  const { userAppId, workflowId } = useParams();
  const { change } = useForm();
  const { workflow } = useGetWorkflow({ workflowId, appId: userAppId });
  const {
    workflowCodeInputContext: { nodes, ...restOfWorkflowCodeInputContext },
  } = useGetWorkflowContexts();

  const { hasEditPermission, isManagedApp } = useWorkflowPermission();

  assert(
    workflow?.trigger?.type === 'form',
    new Error(`[FormTriggerDynamicSourceDetail] - type should be form, but it is ${workflow?.trigger?.type}`),
    'ERROR'
  );

  const {
    fields: { remove, value: optionProducers },
  } = useFieldArray<ActionOptionProducer | undefined>(`uiComponent.dataSource.optionsProducers`);

  const {
    input: { onChange: onOptionProducersChange },
  } = useField(`uiComponent.dataSource.optionsProducers[${dataSourceIndex}]`);

  const optionProducer = useMemo<ActionOptionProducer | undefined>(
    () => optionProducers?.[dataSourceIndex],
    [dataSourceIndex, optionProducers]
  );

  const { data: integration } = useGetIntegration({ id: optionProducer?.integrationId });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId: optionProducer?.integrationId,
    actionId: optionProducer?.actionId,
    version: integration?.latestVersion.number,
  });

  const integrationActionHasSpecialInput = useMemo(() => {
    return !!integrationAction?.inputParameters?.find((i) => hasIntegrationActionSpecialInput(i.uiComponent.type));
  }, [integrationAction?.inputParameters]);

  const integrationActionHasCatalogRelationshipFilterInput = useMemo(() => {
    return !!integrationAction?.inputParameters?.find(
      (i) => i.uiComponent.type === UIComponentType.CATALOG_RELATIONSHIP_FILTER
    );
  }, [integrationAction?.inputParameters]);

  const initialParameterMappings: ParameterMappings | undefined = useMemo(() => {
    if (optionProducer) {
      if (integration?.type === IntegrationType.DATA_MODEL) {
        const appIdInputParameterId = integrationAction?.inputParameters?.find(({ name }) => name === 'appId')?.id;

        if (appIdInputParameterId && userAppId) {
          return [
            ...(optionProducer.parameterMappings ?? []),
            {
              type: ParameterTypes.SIMPLE,
              parameterId: appIdInputParameterId,
              value: { type: ValueTypes.STRING, value: `{{app.id}}` },
            },
          ];
        } else {
          return optionProducer.parameterMappings;
        }
      } else {
        return optionProducer.parameterMappings;
      }
    } else {
      return [];
    }
  }, [integration?.type, integrationAction?.inputParameters, optionProducer, userAppId]);

  const handleOptionProducerDelete = useCallback(
    ({ deletedIndex }: { deletedIndex: number }) => {
      remove(deletedIndex);
    },
    [remove]
  );

  const formTriggerContext = useMemo(() => {
    let sampleResponse;
    try {
      if (integrationAction?.responseConfiguration?.exampleResponse) {
        sampleResponse = JSON.parse(integrationAction?.responseConfiguration?.exampleResponse);
      } else {
        sampleResponse = {};
      }
    } catch (error) {
      sampleResponse = {};
    }
    return {
      ...restOfWorkflowCodeInputContext,
      response: sampleResponse,
    };
  }, [integrationAction?.responseConfiguration?.exampleResponse, restOfWorkflowCodeInputContext]);

  return (
    <NvFlex width="100%" key={`form-trigger-dynamic-source-detail-${dataSourceIndex}`} height="100%">
      {optionProducer && (
        <>
          <PropertyPanelHeader
            title={title}
            actions={
              <>
                <NvMenuWithItems
                  menuItems={[
                    {
                      name: 'Delete',
                      icon: (
                        <NvDeleteOutlineIcon
                          htmlColor={theme.palette.nv_error[40]}
                          sx={{ width: '16px', height: '16px' }}
                        />
                      ),
                      onClick: () => {
                        handleOptionProducerDelete({ deletedIndex: dataSourceIndex });
                        onDelete();
                      },
                    },
                  ]}
                />
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              </>
            }
          />
          <PropertyPanelSection icon={<NvActionFilledIcon />} title="Action">
            <NvFlex gap="8px">
              <IntegrationBox>
                <NvImage
                  FallBack={<IntegrationInitialLogo name={integration?.name ?? ''} size="small" />}
                  src={integration?.logoUrl}
                  imageShape="square"
                  size="smaller"
                  className="integration-image"
                />
                <NvTypography variant="h5" textColor="main" flex="1 1 auto" noWrap>
                  {integration?.name}
                </NvTypography>
                {hasEditPermission &&
                  !isManagedApp &&
                  integration?.latestVersion.number !== optionProducer?.versionNumber && (
                    <UpdateVersionButton
                      onClick={() =>
                        onOptionProducersChange({ ...optionProducer, versionNumber: integration?.latestVersion.number })
                      }
                    />
                  )}
                {integration?.scope.type === 'workspace' ? <NvChip label="custom" compact /> : undefined}
                <NvTypography variant="body2" textColor="subtle">
                  v{optionProducer?.versionNumber}.0.0
                </NvTypography>
              </IntegrationBox>
              <ActionSearchField
                integrationId={optionProducer.integrationId}
                version={optionProducer.versionNumber}
                fieldName={`uiComponent.dataSource.optionsProducers[${dataSourceIndex}].actionId`}
                textFieldOverrides={{ size: 'small' }}
              />
            </NvFlex>
          </PropertyPanelSection>
          {optionProducer?.actionId &&
            (integrationActionHasSpecialInput ? (
              <PropertyPanelEmptySection emptyText="The selected action cannot be used as a dynamic source." />
            ) : (
              <TestActionProvider>
                {/* Open it after test with sample context feature will be implemented */}
                {/* <TestActionButton
                  testActionParams={{
                    formId,
                    integrationId: optionProducer.integrationId,
                    actionId: optionProducer.actionId,
                    inputParameters: initialParameterMappings,
                    draft: integrationAction?.state === ActionState.DRAFT,
                    ...(optionProducer.connectionId ? { connectionId: optionProducer.connectionId } : {}),
                    versionNumber: integrationAction?.version.number ?? 1,
                  }}
                /> */}
                <FormTriggerDynamicSourceMapping
                  fieldNamePrefix={`uiComponent.dataSource.optionsProducers[${dataSourceIndex}]`}
                  context={formTriggerContext}
                />

                <NvFlex width="100%">
                  <NvField<ParameterMappings>
                    name={`uiComponent.dataSource.optionsProducers[${dataSourceIndex}].parameterMappings`}
                    defaultValue={[]}
                    format={(value) => value}
                    parse={(value) => value}
                    component={({ value, onChange }) =>
                      integration?.type === IntegrationType.DATA_MODEL ? (
                        <RecordsParameterMapping
                          actionId={optionProducer.actionId}
                          integrationId={optionProducer.integrationId}
                          parameterMappings={initialParameterMappings}
                          version={integrationAction?.version.number ?? 1}
                          onParameterMappingChanged={({
                            inputParametersValues,
                            activeInputParameterIds,
                            shouldAddFormId,
                          }) => {
                            const otherParameterMappings = initialParameterMappings.filter(
                              ({ parameterId }) => !activeInputParameterIds.includes(parameterId)
                            );
                            onChange(
                              uniqBy([...inputParametersValues, ...(otherParameterMappings ?? [])], 'parameterId')
                            );
                            change(
                              `uiComponent.dataSource.optionsProducers[${dataSourceIndex}].formId`,
                              shouldAddFormId ? formId : undefined
                            );
                          }}
                          context={formTriggerContext}
                        />
                      ) : integrationActionHasCatalogRelationshipFilterInput ? (
                        <SearchEntitiesFilterParameterMapping
                          context={formTriggerContext}
                          actionId={optionProducer.actionId}
                          integrationId={optionProducer.integrationId}
                          parameterMappings={initialParameterMappings}
                          version={integrationAction?.version.number ?? 1}
                          inputParameters={integrationAction?.inputParameters}
                          onParameterMappingChanged={({
                            inputParametersValues,
                            activeInputParameterIds,
                            shouldAddFormId,
                          }) => {
                            const otherParameterMappings = initialParameterMappings.filter(
                              ({ parameterId }) => !activeInputParameterIds.includes(parameterId)
                            );
                            onChange(
                              uniqBy([...inputParametersValues, ...(otherParameterMappings ?? [])], 'parameterId')
                            );
                            change(
                              `uiComponent.dataSource.optionsProducers[${dataSourceIndex}].formId`,
                              shouldAddFormId ? formId : undefined
                            );
                          }}
                        />
                      ) : (
                        <>
                          <PropertyPanelListHeader
                            title="Action inputs"
                            tooltip="When a user attempts to select a value for this field, the options will be populated based on the response of the action with the given inputs below."
                          />
                          <PropertyPanelSimpleSection>
                            <ActionParameterMapper
                              type="in-workflow"
                              appId={userAppId}
                              workflowId={workflowId}
                              actionId={optionProducer.actionId}
                              integrationId={optionProducer.integrationId}
                              // do not send connectionId if it is Slack
                              {...(integration?.type !== 'slack' ? { connectionId: optionProducer.connectionId } : {})}
                              versionNumber={integrationAction?.version.number ?? 1}
                              initialParameterMappings={value}
                              onParameterMappingsChanged={({ inputParametersValues, shouldAddFormId }) => {
                                onChange(inputParametersValues);
                                change(
                                  `uiComponent.dataSource.optionsProducers[${dataSourceIndex}].formId`,
                                  shouldAddFormId ? formId : undefined
                                );
                              }}
                              context={formTriggerContext}
                            />
                          </PropertyPanelSimpleSection>
                        </>
                      )
                    }
                  />
                </NvFlex>

                <TestActionResult
                  sampleResponse={integrationAction?.responseConfiguration?.exampleResponse}
                  tooltip="The response displayed below is solely a sample."
                />
              </TestActionProvider>
            ))}
        </>
      )}
    </NvFlex>
  );
};
