import { NvFlex, NvSkeleton } from '@novaera/core';
import { SlackUserMappingItem } from '../../../components/slack-user-mapping-item';
import { useGetWorkspaceSummary } from '../services/use-get-workspace-summary';
import { SlackUserMappingSectionProps } from './types';

export const SlackUserMappingSection: React.FC<React.PropsWithChildren<SlackUserMappingSectionProps>> = ({
  workspaceId,
}) => {
  const { data: workspaceSummary, isLoading: isWorkspaceSummaryLoading } = useGetWorkspaceSummary({
    workspaceId,
    enabled: true,
  });

  return (
    <NvFlex spacing="8px">
      {!isWorkspaceSummaryLoading ? (
        workspaceSummary?.map(({ teamName, teamIconUrl, email, slackUserId, slackAppHomeLink, mapped }, index) => (
          <SlackUserMappingItem
            key={`slack-user-mapping-item-${index}`}
            {...{ teamName, teamIconUrl, email, slackUserId, slackAppHomeLink, mapped }}
          />
        ))
      ) : (
        <NvFlex>
          <NvSkeleton />
          <NvSkeleton />
          <NvSkeleton />
        </NvFlex>
      )}
    </NvFlex>
  );
};
