import { FormApi } from 'final-form';

export const useResetAndUpdateForm = <T>() => {
  const resetAndUpdateForm = (formApi: FormApi<T, Partial<T>>) => {
    formApi.setConfig('keepDirtyOnReinitialize', false);
    formApi.reset();
    formApi.setConfig('keepDirtyOnReinitialize', true);
  };

  return { resetAndUpdateForm };
};
