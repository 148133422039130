import { useGetUserApp } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { useState } from 'react';
import { useGetWorkflowQueryParams } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { MissingConnectionItem } from './item';
import { MissingConnectionBodyProps } from './types';

export const MissingConnectionBody = ({
  connectionValidationCheckResult,
  onFormSubmitCompleted,
  workflowName,
}: MissingConnectionBodyProps) => {
  const [schemasConnected, setSchemasConnected] = useState<string[]>([]);
  const { userAppId } = useGetWorkflowQueryParams();
  const { data: userApp } = useGetUserApp(userAppId);

  return (
    <NvFlex gap={'16px'}>
      <NvFlex>
        <NvTypography variant="body1">
          You need to connect the tools listed below, as this is essential for the <b>{workflowName}</b> workflow of{' '}
          <b>{userApp?.name ?? 'this app'}</b>.
        </NvTypography>
      </NvFlex>
      <NvFlex gap={'8px'}>
        {connectionValidationCheckResult.invalidNodeConnections
          .filter(({ connectionSchemaId }) =>
            schemasConnected.length === 0
              ? true
              : connectionValidationCheckResult.invalidNodeConnections.length === schemasConnected.length
              ? false
              : schemasConnected.find((schemaId) => schemaId !== connectionSchemaId)
          )
          ?.map((schema) => (
            <MissingConnectionItem
              onFormSubmitCompleted={() => {
                setSchemasConnected([...schemasConnected, schema.connectionSchemaId]);
                onFormSubmitCompleted?.();
              }}
              mode={connectionValidationCheckResult.invalidNodeConnections.length > 1 ? 'in-save' : 'form-save'}
              key={schema.connectionSchemaId}
              schema={schema}
            />
          ))}
      </NvFlex>
    </NvFlex>
  );
};
