import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const NvCustomShareIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(({ ...props }, ref) => {
  return (
    <SvgIcon
      ref={ref}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 10.7197C11.4933 10.7197 11.04 10.9197 10.6933 11.233L5.94 8.46634C5.97333 8.31301 6 8.15967 6 7.99967C6 7.83967 5.97333 7.68634 5.94 7.53301L10.64 4.79301C11 5.12634 11.4733 5.33301 12 5.33301C13.1067 5.33301 14 4.43967 14 3.33301C14 2.22634 13.1067 1.33301 12 1.33301C10.8933 1.33301 10 2.22634 10 3.33301C10 3.49301 10.0267 3.64634 10.06 3.79967L5.36 6.53967C5 6.20634 4.52667 5.99967 4 5.99967C2.89333 5.99967 2 6.89301 2 7.99967C2 9.10634 2.89333 9.99967 4 9.99967C4.52667 9.99967 5 9.79301 5.36 9.45967L10.1067 12.233C10.0733 12.373 10.0533 12.5197 10.0533 12.6663C10.0533 13.7397 10.9267 14.613 12 14.613C13.0733 14.613 13.9467 13.7397 13.9467 12.6663C13.9467 11.593 13.0733 10.7197 12 10.7197Z"
        fill="currentColor"
        fillOpacity="0.77"
      />
    </SvgIcon>
  );
});
