import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { themes } from '../../themes';
import { useModeValue } from '../mode-value-provider';
import { useThemeValue } from '../theme-value-provider';

export const MuiThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { activeTheme } = useThemeValue();
  const { mode } = useModeValue();

  const theme = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentTheme = (themes as any)(mode)[activeTheme];
    return createTheme({
      zIndex: {
        snackbar: 1600,
        modal: 1500,
        drawer: 1300,
        navigation: 1300,
      },
      components: {
        MuiButton: {
          defaultProps: {
            variant: 'contained',
            disableElevation: true,
            disableRipple: true,
            disableFocusRipple: true,
            disableTouchRipple: true,
            size: 'medium',
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
            size: 'medium',
          },
        },
        MuiIconButton: {
          defaultProps: {
            disableFocusRipple: true,
            disableRipple: true,
            disableTouchRipple: true,
          },
        },
        MuiMenuItem: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiYearPicker: {
          styleOverrides: {
            root: {
              '.PrivatePickersYear-yearButton': {
                fontWeight: 590,
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.02em',
                color: currentTheme.palette.nv_neutral[1000],
                backgroundColor: currentTheme.palette.nv_neutral_alpha[20],
                borderRadius: '6px',
                margin: '4px 0',
                transition: 'background-color 300ms ease',

                '&:hover': {
                  backgroundColor: currentTheme.palette.nv_neutral_alpha[30],
                },

                '&.Mui-selected': {
                  color: currentTheme.palette.nv_neutral[0],
                  backgroundColor: currentTheme.palette.nv_main[40],
                  '&:hover,&:focus,&:active': {
                    color: currentTheme.palette.nv_neutral[0],
                    backgroundColor: currentTheme.palette.nv_main[50],
                  },
                },
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0px 0px 0px 1px #EBEEF2, 0px 15px 25px -2px rgba(10, 45, 98, 0.08)',
              borderRadius: '12px',
            },
          },
        },
      },
      //todo here there is a type error which should be resolved with module augmentation
      // check this tutorial: https://dragoshmocrii.com/material-ui-custom-theme-and-typescript/
      ...currentTheme,
    });
  }, [mode, activeTheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
