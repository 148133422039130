import { isRequired, NvField, NvFlex, NvTextField, NvTypography, NvVideocamIcon } from '@novaera/core';

export const YoutubeVideoContent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <NvFlex gap="16px">
    <NvTypography variant="body1">
      Add Youtube video links here. Links from other sources will not be displayed.
    </NvTypography>
    <NvField
      labelText="Youtube video link"
      direction="label-on-top"
      validators={[isRequired()]}
      hasRequiredIndicator
      showErrorMessageOnlyWhenBlur
      component={<NvTextField startIcon={<NvVideocamIcon />} />}
      name="video.fileReference.url"
    />
    <NvField
      labelText="Video text"
      direction="label-on-top"
      validators={[isRequired()]}
      hasRequiredIndicator
      showErrorMessageOnlyWhenBlur
      component={<NvTextField />}
      name="video.displayText"
    />
  </NvFlex>
);
