import { useState, useMemo, createContext, useContext } from 'react';
import { Themes } from '../../themes/types';

interface ThemeValueProviderContextType {
  activeTheme: Themes;
  updateThemeContext: (val: Themes) => void;
}

export const ThemeValueContext = createContext<ThemeValueProviderContextType>({
  activeTheme: Themes.Default,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateThemeContext: () => {},
});

export const ThemeValueProvider = ({
  children,
  initialTheme = Themes.Default,
}: {
  children: React.ReactNode;
  initialTheme: Themes;
}) => {
  const [activeTheme, setActiveTheme] = useState<Themes>(initialTheme);
  const themeContextValue = useMemo(
    () => ({
      activeTheme,
      updateThemeContext: (val: Themes) => setActiveTheme(val),
    }),
    [activeTheme]
  );
  return <ThemeValueContext.Provider value={themeContextValue}>{children}</ThemeValueContext.Provider>;
};

export const useThemeValue = () => {
  const context = useContext<ThemeValueProviderContextType>(ThemeValueContext);
  if (context === undefined) {
    throw new Error('useThemeValue must be used within a ThemeProvider');
  }
  return context;
};
