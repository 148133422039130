import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCheckboxGroupIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H8V16H20V4ZM8 2C6.89543 2 6 2.89543 6 4V16C6 17.1046 6.89543 18 8 18H20C21.1046 18 22 17.1046 22 16V4C22 2.89543 21.1046 2 20 2H8ZM10.2486 11.0063L12.0545 12.7487C12.3948 13.0838 12.9531 13.0838 13.2759 12.7487L17.7514 8.4178C18.0829 8.0911 18.0829 7.57173 17.7514 7.24503C17.4111 6.91832 16.8615 6.91832 16.5213 7.24503L12.6652 10.9812L11.4787 9.83351C11.1385 9.50681 10.5889 9.50681 10.2486 9.83351C9.91712 10.1602 9.91712 10.6796 10.2486 11.0063ZM2 8C2 6.89543 2.89543 6 4 6V8V19C4 19.5523 4.44771 20 5 20H16H18C18 21.1046 17.1046 22 16 22H4C2.89543 22 2 21.1046 2 20V8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
