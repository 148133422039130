import {
  PermissionDefinition,
  PermissionEntity,
  SavePermissionToPolicyParams,
  SavePermissionToPolicyResponse,
} from '@novaera/actioner-service';
import { NvAddBoxIcon, NvButton, NvConditionalWrap, NvFlex } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { PermissionDefinitionRow, PermissionDefinitionsCard } from '../styled';
import { PermissionDefinitionSelect } from './permission-definition-select';

export const AddNewPermissionButton: React.FC<{
  wrap?: boolean;
  currentPermissions: PermissionEntity[];
  isLoading: boolean;
  handleAddNewPermission: ({
    permission,
    onSuccess,
  }: {
    permission: PermissionEntity;
    onSuccess?: (
      data: SavePermissionToPolicyResponse,
      variables: SavePermissionToPolicyParams,
      context: unknown
    ) => void;
  }) => Promise<void>;
}> = ({ wrap, currentPermissions, isLoading, handleAddNewPermission }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [permissionDefinition, setPermissionDefinition] = useState<PermissionDefinition | undefined>();
  const handleCloseAddingView = () => {
    setIsAdding(false);
    setPermissionDefinition(undefined);
  };
  return (
    <NvConditionalWrap
      condition={Boolean(wrap && isAdding)}
      wrap={(children) => (
        <PermissionDefinitionsCard>
          <PermissionDefinitionRow width="100%">{children}</PermissionDefinitionRow>
        </PermissionDefinitionsCard>
      )}
    >
      {isAdding ? (
        <NvFlex direction="row" alignItems="center" justifyContent="space-between" width="100%" gap="8px">
          <PermissionDefinitionSelect
            currentPermissions={currentPermissions}
            handleOnChange={(definition) => {
              setPermissionDefinition(definition);
            }}
          />
          <NvFlex direction="row" alignItems="center" gap="4px">
            <NvButton
              size="small"
              color="primary"
              loading={isLoading}
              disabled={!permissionDefinition && !isLoading}
              onClick={() => {
                assert(
                  !!permissionDefinition,
                  new Error(
                    '[AddNewPermissionButton] - permissionDefinition can not be undefined to add new permission.'
                  ),
                  'ERROR'
                );

                handleAddNewPermission({
                  permission: { definition: permissionDefinition, granted: true },
                  onSuccess: () => {
                    handleCloseAddingView();
                  },
                });
              }}
            >
              Add
            </NvButton>
            <NvButton size="small" color="secondary" onClick={handleCloseAddingView}>
              Cancel
            </NvButton>
          </NvFlex>
        </NvFlex>
      ) : (
        <NvButton
          color="secondary"
          size="small"
          startIcon={<NvAddBoxIcon />}
          onClick={() => {
            setIsAdding(true);
          }}
        >
          Add permission
        </NvButton>
      )}
    </NvConditionalWrap>
  );
};
