import { FieldSchema } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import {
  DefaultRenderOption,
  isRequired,
  NvAutocomplete,
  NvAutocompleteRenderInputParams,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  useField,
} from '@novaera/core';
import { useMemo } from 'react';
import { SOURCE_TYPE_OPTIONS } from './constants';

export const SourceTypes: React.FC<React.PropsWithChildren<{ schema?: FieldSchema }>> = ({ schema }) => {
  const fieldName = useMemo(
    () => (schema?.type === SchemaType.array ? 'schema.valueSchema.type' : 'schema.type'),
    [schema?.type]
  );
  const {
    input: { value: sourceType },
  } = useField(fieldName);

  return (
    <NvField
      name={fieldName}
      showError={false}
      isAutoComplete
      parse={(option) => option?.value}
      format={(value) => SOURCE_TYPE_OPTIONS.find((o) => o.value === value)}
      isRequired
      hasRequiredIndicator
      validators={[isRequired()]}
      component={
        <NvAutocomplete
          disableClearable
          options={SOURCE_TYPE_OPTIONS}
          getOptionLabel={(option) => option.label}
          size="small"
          renderOption={(props, option) => (
            <DefaultRenderOption {...props} key={option.value}>
              <NvFlex direction="row" alignItems="center" gap="8px">
                {option.startIcon}
                <NvTypography variant="body1">{option.label}</NvTypography>
              </NvFlex>
            </DefaultRenderOption>
          )}
          renderInput={(params: NvAutocompleteRenderInputParams) => (
            <NvTextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
              startIcon={SOURCE_TYPE_OPTIONS.find((o) => o.value === sourceType)?.startIcon}
              size="small"
            />
          )}
        />
      }
    />
  );
};
