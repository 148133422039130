import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecentQueryCardWrapper = styled(NvFlex)`
  padding: 12px 16px 16px 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  gap: 12px;
`;

export const QueryStringCard = styled(NvFlex)`
  padding: 12px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-radius: 12px;
`;
