import { DynamicWeekdaysJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { CRON_INPUT_FIELDS } from '../../../../../../../../../../../jobs/components/cron-input/constants';
import { CronUnits } from '../../../../../../../../../../../jobs/components/cron-input/types';
import { DynamicCronField } from '../../dynamic-cron-field';
import { RecurrenceProps } from '../../types';

export const DynamicWeekdaysRecurrence: React.FC<RecurrenceProps<DynamicWeekdaysJobRecurrenceConfiguration>> = ({
  recurrenceConfiguration,
  context,
  onChange,
}) => {
  const WEEKDAYS_CRON_INPUT_FIELDS = CRON_INPUT_FIELDS.filter(
    ({ key }) => key === CronUnits.HOUR || key === CronUnits.MINUTE
  );
  return (
    <NvFlex gap="8px" width="100%">
      {WEEKDAYS_CRON_INPUT_FIELDS.map(({ key, label, hint }) => (
        <DynamicCronField
          key={`cron-dynamic-input-${key}`}
          value={recurrenceConfiguration[key as CronUnits.HOUR | CronUnits.MINUTE]}
          onChange={(v) => {
            onChange({ ...recurrenceConfiguration, [key]: v });
          }}
          context={context}
          label={label}
          hint={hint}
        />
      ))}
    </NvFlex>
  );
};
