import { ValueTypes } from '@novaera/actioner-service';
import { CodeInput, NvMenuWithItems, NvSettingsIcon, NvSideGroupedInputLayout, NvSwitch } from '@novaera/core';
import { useState } from 'react';
import { ParameterMappingSettingsMenu } from '../../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { CustomStartRunWrapper } from './styled';
import { StartRunComponentProps } from './types';

export const StartRunComponent: React.FC<StartRunComponentProps> = ({ value, onChange, context }) => {
  const [hasStartRunCodeTemplate, setHasStartRunCodeTemplate] = useState<boolean>(() =>
    Boolean(value && 'codeTemplate' in value)
  );
  return (
    <NvSideGroupedInputLayout
      variant={'default'}
      rightAction={
        <NvMenuWithItems
          triggerButton={{
            content: <NvSettingsIcon />,
            props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
          }}
          menuItems={
            <ParameterMappingSettingsMenu
              hiddenConfig={{
                show: false,
              }}
              dynamicStaticConfig={{
                show: true,
                isScripted: hasStartRunCodeTemplate,
                onItemClicked: (type) => {
                  const newScriptedValue = type === 'static' ? false : true;
                  if (hasStartRunCodeTemplate !== newScriptedValue) {
                    setHasStartRunCodeTemplate(newScriptedValue);
                    if (newScriptedValue) {
                      onChange({
                        type: ValueTypes.BOOLEAN,
                        codeTemplate: '',
                        displayValue: '',
                      });
                    } else {
                      onChange({
                        type: ValueTypes.BOOLEAN,
                        value: false,
                        displayValue: `${false}`,
                      });
                    }
                  }
                },
              }}
            />
          }
        />
      }
      formItem={
        hasStartRunCodeTemplate ? (
          <CodeInput
            value={value?.codeTemplate}
            onChange={(value) => {
              onChange({
                type: ValueTypes.BOOLEAN,
                codeTemplate: value,
                displayValue: `${value}`,
              });
            }}
            context={context}
            placeholder={'{{...}}'}
          />
        ) : (
          <CustomStartRunWrapper>
            <NvSwitch
              label="Generate AI assistant's message"
              variant="compact"
              checked={value?.value}
              onChange={(e, value) => {
                onChange({
                  type: ValueTypes.BOOLEAN,
                  value: value,
                  displayValue: `${value}`,
                });
              }}
            />
          </CustomStartRunWrapper>
        )
      }
    />
  );
};
