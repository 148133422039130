import { useLocation } from '@novaera/route';
import { assert } from '@novaera/utils';
import { configureScope, init, reactRouterV6Instrumentation, Replay } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom';
import App from './app/app';

import('monaco-editor').then((allModule) => {
  import('@monaco-editor/react').then((module) => module.loader.config({ monaco: allModule }));
});

// eslint-disable-next-line eqeqeq
if (process.env.NX_ENABLE_LOG_ROCKET == 'true') {
  LogRocket.init('mu9eyk/novaera', {
    serverURL: 'https://analytics.actioner.com/logrocket/ingest/i',
    dom: { rateLimitCount: 4096, rateLimitPeriod: 10000 },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
}

if (process.env.NODE_ENV !== 'development') {
  init({
    ignoreErrors: [
      'subscriptionFailedCallback',
      'Novaera Post operation timed out',
      '_handleIncomingSubscriptionMessage',
      'Request failed with status code 403',
      'Failed to fetch',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
      'Network Error',
    ],
    beforeSend(event) {
      const logRocketSession = LogRocket?.sessionURL;
      if (logRocketSession !== null && event && (event.level === 'error' || event.level === 'fatal')) {
        if (event.extra && typeof event.extra === 'object') {
          event.extra['LogRocket'] = logRocketSession;
        } else {
          event.extra = { LogRocket: logRocketSession };
        }

        return event;
      } else {
        return event;
      }
    },
    dsn: 'https://87bdd9af262c42da9a0ea674e342f18f@o1176861.ingest.sentry.io/6275126',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          process.env.NX_API_URL.replace('https://', ''),
          process.env.NX_SANDBOX_URL.replace('https://', ''),
          process.env.NX_EXECUTION_S3_DOMAIN,
        ],
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Replay(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NX_TARGET_ENV,
    release: process.env.NX_COMMIT_HASH,
    tracesSampleRate: 1.0,
  });

  if (LogRocket && process.env.NX_ENABLE_LOG_ROCKET == 'true') {
    LogRocket.getSessionURL((sessionURL) => {
      configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
}

const container = document.getElementById('root');

assert(!!container, new Error('[Main] - container can not be undefined!'), 'ERROR');

const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
