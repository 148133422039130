import { NvButton, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const UpdateNodeButton = styled(NvFlex)`
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  ${({ theme }) => theme.elevations.e100};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  height: 24px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.nv_neutral[400]};
  }
`;

export const StyledButton = styled(NvButton)`
  &.MuiButtonBase-root {
    &.MuiButton-containedGhost {
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }
    }
  }
`;
