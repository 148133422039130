import {
  BooleanValue,
  FieldSchema,
  InputParameterValues,
  ListValue,
  NumberValue,
  ObjectValue,
  ParameterTypes,
  SimpleParameterMapping,
  StringValue,
  ValueTypes,
} from '@novaera/actioner-service';
import { Option, SchemaType } from '@novaera/ah-common';
import { assert } from '@novaera/utils';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { fieldSchemaToValue } from '../../../utils/field-schema-to-value';

export const useSingleOptionFieldAdapter = ({
  extractValue = false,
  hidden,
}: {
  extractValue?: boolean;
  hidden: boolean;
}) => {
  const formatValue = useCallback(
    ({ options }: { options?: Option[] }) =>
      (value: InputParameterValues) => {
        if (value) {
          assert(value.type !== ValueTypes.LIST, new Error('Single select cannot have list type value'), 'ERROR');

          if (value.type === ValueTypes.OBJECT) {
            const inputValue = value.value;

            assert(Boolean(value.displayValue), new Error('displayValue should be defined'), 'ERROR');

            const inputAsOption: Option = { identifier: value.value, displayValue: value.displayValue ?? '' };
            const result = options?.find((o) => isEqual(o.identifier, inputValue)) ?? inputAsOption;
            return result;
          } else if (
            value.type === ValueTypes.BOOLEAN ||
            value.type === ValueTypes.NUMBER ||
            value.type === ValueTypes.STRING
          ) {
            const inputValue = value.value;
            const inputAsOption: Option = {
              identifier: value.value,
              displayValue: value.displayValue ?? (value.value as string),
            };

            return (
              options?.find((o) => o.identifier === inputValue) ??
              (inputAsOption && inputAsOption.identifier === undefined ? null : inputAsOption)
            );
          } else {
            assert(false, new Error(`unknown value type`), 'WARNING');
          }
        } else {
          return null;
        }
      },
    []
  );

  const parseValue = useCallback(
    ({ schema }: { schema?: FieldSchema }) =>
      (valueToParse: Option | null) => {
        if (valueToParse) {
          assert(
            !!schema && schema.type !== SchemaType.array,
            new Error('Schema should have a value and its type should not be array in single select adaptor parse'),
            'ERROR'
          );

          const valueType = fieldSchemaToValue(schema);

          assert(
            !Array.isArray(valueToParse),
            new Error('Single select should have a singular value which is not an array.'),
            'ERROR'
          );

          if (valueType === ValueTypes.STRING) {
            assert(
              typeof valueToParse.identifier === 'string',
              new Error('valueToParse.identifier should be string'),
              'ERROR'
            );

            const objectValue1: StringValue = {
              type: valueType,
              value: valueToParse.identifier,
              displayValue: valueToParse.displayValue,
            };
            return objectValue1;
          } else if (valueType === ValueTypes.OBJECT) {
            const objectValue1: ObjectValue = {
              type: valueType,
              value: valueToParse.identifier,
              displayValue: valueToParse.displayValue,
            };
            return objectValue1;
          } else if (valueType === ValueTypes.BOOLEAN) {
            assert(
              typeof valueToParse.identifier === 'boolean',
              new Error('valueToParse.identifier should be boolean'),
              'ERROR'
            );

            const objectValue1: BooleanValue = {
              type: valueType,
              value: valueToParse.identifier,
              displayValue: valueToParse.displayValue,
            };
            return objectValue1;
          } else if (valueType === ValueTypes.NUMBER) {
            assert(
              typeof valueToParse.identifier === 'number',
              new Error('valueToParse.identifier should be number'),
              'ERROR'
            );
            const objectValue1: NumberValue = {
              type: valueType,
              value: valueToParse.identifier,
              displayValue: valueToParse.displayValue,
            };
            return objectValue1;
          } else {
            assert(
              Array.isArray(valueToParse.identifier),
              new Error('Single select should have a singular value which is not an array.'),
              'ERROR'
            );
            const objectValue: ListValue = {
              type: valueType,
              value: valueToParse.identifier,
            };
            return objectValue;
          }
        } else {
          return undefined;
        }
      },
    []
  );

  const format = useCallback(
    ({ options }: { options?: Option[] }) =>
      (parameterMapping: SimpleParameterMapping | undefined) => {
        if (!parameterMapping?.value) {
          return null;
        }
        return formatValue({ options })(parameterMapping?.value);
      },
    [formatValue]
  );

  const parse = useCallback(
    ({ schema, id }: { schema?: FieldSchema; id: string }) =>
      (valueToParse: Option | null): SimpleParameterMapping | undefined => {
        const parsedValue = parseValue({ schema })(valueToParse);
        if (parsedValue || hidden) {
          return { parameterId: id, type: ParameterTypes.SIMPLE, hidden, ...(parsedValue && { value: parsedValue }) };
        } else {
          return undefined;
        }
      },
    [hidden, parseValue]
  );
  return { format: extractValue ? formatValue : format, parse: extractValue ? parseValue : parse };
};
