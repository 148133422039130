import { BranchJunctionNodeSummary, WorkflowWithState } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvTextField,
  isRequired,
} from '@novaera/core';

import { PropertyPanelListHeader, PropertyPanelSection } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useBranchesController } from './controllers/use-branches';

export const Branches = ({
  branchNode,
  workflow,
}: {
  branchNode: BranchJunctionNodeSummary;
  workflow: WorkflowWithState;
}) => {
  const { onNewBranchAdded, branchRef, handleDeleteBranch } = useBranchesController();
  const { hasEditPermission } = useWorkflowPermission();

  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelListHeader
          title="Branches"
          actions={
            <NvButton
              onlyIcon
              onClick={() => {
                onNewBranchAdded({
                  branchJunctionRootAlias: branchNode.alias,
                  branches: branchNode.branches,
                  workflow,
                });
              }}
              size="small"
              variant="contained"
              color="ghost"
              sx={{ flex: '0 0 auto' }}
            >
              <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelListHeader title="Branches" />
      </NvConditionalRender>
      {branchNode.branches.map((name, index) => {
        return (
          <NvBox key={`branch_name_${index}`}>
            <NvConditionalRender when={hasEditPermission}>
              <PropertyPanelSection
                title={`Branch ${index + 1}`}
                collapsible
                {...((branchNode.branches?.length ?? 0) > 2 && {
                  actions: (
                    <NvButton
                      onlyIcon
                      onClick={() => {
                        handleDeleteBranch({
                          branchJunctionRootAlias: branchNode.alias,
                          branchIndex: index,
                          workflow,
                        });
                      }}
                      size="small"
                      variant="contained"
                      color="ghost"
                      sx={{ flex: '0 0 auto' }}
                    >
                      <NvDeleteOutlineIcon sx={{ width: '16px', height: '16px' }} />
                    </NvButton>
                  ),
                })}
              >
                <NvFlex gap="8px" ref={branchRef}>
                  <NvField
                    isRequired
                    validators={[isRequired()]}
                    parse={(value) => (value ? value : '')}
                    showErrorMessageOnlyWhenBlur
                    name={`branches[${index}].name`}
                    labelText="Branch name"
                    component={<NvTextField />}
                  />
                </NvFlex>
              </PropertyPanelSection>
            </NvConditionalRender>
            <NvConditionalRender when={!hasEditPermission}>
              <PropertyPanelSection title={`Branch ${index + 1}`} collapsible>
                <NvFlex gap="8px" ref={branchRef}>
                  <NvField
                    isRequired
                    validators={[isRequired()]}
                    parse={(value) => (value ? value : '')}
                    showErrorMessageOnlyWhenBlur
                    name={`branches[${index}].name`}
                    labelText="Branch name"
                    component={<NvTextField />}
                  />
                </NvFlex>
              </PropertyPanelSection>
            </NvConditionalRender>
          </NvBox>
        );
      })}
    </>
  );
};
