import { useUpdateNode, WorkflowConditionNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { cloneDeep } from 'lodash';
import { WorkflowConditionFormType } from '../../../../../../components/condition-rule/types';
import { useGetWorkflowContexts } from '../../../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../../../provider';

export const useConditionPropertiesController = () => {
  const { userAppId: appId, workflowId } = useParams();
  const { mutate: updateNode } = useUpdateNode();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();

  const { updateNode: updateNodeInGraph } = useNovaeraFlow(userAppGraph);

  const handleSave = (node: WorkflowConditionNode, values: WorkflowConditionFormType, isUpdateGraph?: boolean) => {
    const newCondition: WorkflowConditionNode = {
      ...node,
      ...values.conditionNode,
    };

    updateNode(
      { appId, workflowId, nodeAlias: node.alias, payload: newCondition },
      {
        onSuccess: () => {
          if (isUpdateGraph) {
            const graphNode = userAppGraph.node(newCondition.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newCondition.name;
            updateNodeInGraph({ newNode: newGraphNode });
          }
        },
      }
    );
  };

  const { workflowCodeInputContext } = useGetWorkflowContexts();

  return {
    onSave: handleSave,
    deleteNodeAndUpdateGraph,
    context: workflowCodeInputContext,
  };
};
