import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';

export const SwitchStyledWrapper = styled(NvBox)`
  .MuiSwitch-root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    padding: 0;
    overflow: hidden;

    .MuiSwitch-switchBase {
      padding: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: transparent !important;

      input.MuiSwitch-input {
        width: 100%;
        height: 100%;
        left: 0;
      }

      & + .MuiSwitch-track {
        border-radius: 12px;
        opacity: 1;
      }

      &:hover {
        & + .MuiSwitch-track {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
        }
      }

      .MuiSwitch-thumb {
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 4px;
        width: 16px;
        height: auto;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
        box-shadow: none;
        transition: background-color 300ms ease, transform 200ms ease;
      }

      &.Mui-checked {
        transform: translateX(0);

        &::before {
          display: block;
        }

        &::after {
          display: none;
        }

        & + .MuiSwitch-track {
          background-color: ${({ theme }) => theme.palette.nv_success[40]};
        }

        .MuiSwitch-thumb {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[900]};
          transform: translateX(16px);
        }

        input.MuiSwitch-input {
          width: 100%;
          height: 100%;
          left: 0;
        }

        &:hover {
          & + .MuiSwitch-track {
            background-color: ${({ theme }) => theme.palette.nv_success[50]};
          }
        }

        &.Mui-disabled {
          & + .MuiSwitch-track {
            background-color: ${({ theme }) => theme.palette.nv_success[30]};
          }

          .MuiSwitch-thumb {
            background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
          }
        }
      }

      &.Mui-disabled {
        & + .MuiSwitch-track {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
        }

        .MuiSwitch-thumb {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
        }
      }
    }

    .MuiSwitch-track {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      opacity: 1;
      border-radius: 6px;
      box-shadow: none;
      transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }
  }

  &.is-compact {
    .MuiSwitch-root {
      width: 32px;
      height: 20px;

      .MuiSwitch-switchBase {
        .MuiSwitch-thumb {
          width: 12px;
        }

        &.Mui-checked {
          transform: translateX(0);

          .MuiSwitch-thumb {
            transform: translateX(12px);
          }
        }
      }
    }
  }
`;
