import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOW_AVAILABLE_UPDATES } from '../keys';
import { CachedGetIntegrationUpdateResponse, GetIntegrationUpdateParams, GetIntegrationUpdateResponse } from './types';

const getIntegrationUpdates = async (params: GetIntegrationUpdateParams) => {
  const result = await NvAxios.get<GetIntegrationUpdateResponse>(
    `${USER_APP_ROOT_PATH}/${params.appId}/workflows/${params.workflowId}/integration-version-updates`
  );
  return result?.data;
};

export const useGetIntegrationUpdates = (params: GetIntegrationUpdateParams) => {
  return useQuery<CachedGetIntegrationUpdateResponse>(
    QUERY_KEYS_WORKFLOW_AVAILABLE_UPDATES.detail(params),
    () => getIntegrationUpdates(params),
    {
      enabled: Boolean(params.appId && params.workflowId),
      refetchOnMount: (params) => {
        return params.isStale() || params.state.data?.availableUpdates?.some((update) => update.isUpdated)
          ? 'always'
          : false;
      },
      select: (data) => {
        return {
          ...data,
          isAllIntegrationsUpdated: data.availableUpdates?.every((update) => update.isUpdated),
          hasUpdatedIntegrations: data?.availableUpdates?.some((update) => update.isUpdated),
        };
      },
    }
  );
};
