import { NvFlex, NvSkeleton } from '@novaera/core';

export const LoadingSkeleton = () => {
  return (
    <NvFlex gap={'8px'}>
      <NvSkeleton variant="rectangular" width={'100%'} height={32} />
      <NvSkeleton variant="rectangular" width={'100%'} height={32} />
    </NvFlex>
  );
};
