import { useFetchWorkspaceList } from '@novaera/actioner-service';
import { ApplicationConfig } from '@novaera/application-config';
import { BG_COLORS_OF_INITIAL_COMPONENT, LAST_ACTIVE_WORKSPACE, LOCAL_STORAGE_ACCESS_KEY } from '@novaera/constants';
import { NvAxios } from '@novaera/core';
import { getSubdomain, setCookieForSpecificDomain } from '@novaera/utils';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { AppContextAction, useAppContext } from '../../../../app-context';
import { WorkspaceType } from '../../../workspace-item/types';

export const useWorkspaceMenuController = () => {
  const { dispatch } = useAppContext();
  const { data, refetch } = useFetchWorkspaceList();
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceType | undefined>();

  useEffect(() => {
    if (data) {
      const workspaces = data?.workspaces || [];

      dispatch({ type: AppContextAction.UpdateWorkspaces, payload: { workspaces } });

      const colorKeyMap = workspaces?.reduce(
        (acc, workspace, index) => ({ ...acc, [workspace.subdomain]: BG_COLORS_OF_INITIAL_COMPONENT[index] }),
        {}
      );

      dispatch({
        type: AppContextAction.UpdateUserInitialBgColorKeyMap,
        payload: { userInitialBgColorKeyMap: colorKeyMap },
      });

      const subdomain = getSubdomain();
      const selectedWorkspace = workspaces.find((workspace) => workspace.subdomain === subdomain);
      setSelectedWorkspace(selectedWorkspace);

      const hasNoWorkspace = !selectedWorkspace;
      const lastActiveWorkspace = Cookies.get(LAST_ACTIVE_WORKSPACE);
      const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY);

      if (
        !hasNoWorkspace &&
        accessToken &&
        ((!lastActiveWorkspace && subdomain.length > 0) ||
          (lastActiveWorkspace &&
            lastActiveWorkspace.length > 0 &&
            subdomain.length > 0 &&
            lastActiveWorkspace !== subdomain))
      ) {
        setCookieForSpecificDomain(LAST_ACTIVE_WORKSPACE, subdomain, `${ApplicationConfig.Actioner.baseHost}`);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (hasNoWorkspace && !(NvAxios.defaults as any).isPublic) {
        window.location.href = `${window.location.origin}/error/invalid-workspace`;
      }
    }
  }, [data, dispatch]);

  return { selectedWorkspace, workspaces: data?.workspaces ?? [] };
};
