import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const EnterpriseCardWrapper = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 40px;
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  margin: 8px 0px;
`;
