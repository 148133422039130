import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { GetUserAppResponse } from '../../user-app';
import { QUERY_KEYS_USER_APP } from '../../user-app/keys';
import { SearchUserAppResponse } from '../../user-app/use-search-user-apps/types';
import { MANAGED_APP_ROOT_PATH } from '../constants';
import { UnlockManagedAppParams } from '../types';

const unlockManagedApp: ({ appId }: UnlockManagedAppParams) => Promise<void> = async ({ appId }) => {
  await NvAxios.post<void>(`${MANAGED_APP_ROOT_PATH}/${appId}/unlock`);
};

export const useUnlockManagedApp = () => {
  const cache = useQueryClient();

  return useMutation(unlockManagedApp, {
    onSuccess: (response, { appId }) => {
      cache.setQueriesData<InfiniteData<SearchUserAppResponse>>(QUERY_KEYS_USER_APP.infiniteSearch(), (old) => {
        return old
          ? {
              ...old,
              pages: old.pages.map((page, index) => {
                const isLastPage = old.pages.length - 1 === index;
                return isLastPage
                  ? {
                      ...page,
                      apps: page.apps.map((app) => {
                        return app.id === appId
                          ? {
                              ...app,
                              managed: false,
                            }
                          : app;
                      }),
                    }
                  : page;
              }),
            }
          : old;
      });
      cache.setQueriesData(QUERY_KEYS_USER_APP.search(), (old?: SearchUserAppResponse) => {
        if (old) {
          return {
            ...old,
            apps: old.apps.map((app) => {
              return app.id === appId
                ? {
                    ...app,
                    managed: false,
                  }
                : app;
            }),
          };
        }
        return old;
      });
      const appData = cache.getQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId));
      if (appData) {
        cache.setQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId), {
          ...appData,
          managed: false,
        });
      }
    },
  });
};
