import {
  FunctionNode,
  QUERY_KEYS_NODE,
  QUERY_KEYS_WORKFLOWS,
  useGenerateFunction,
  useUpdateNode,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvAutoAwesomeIcon,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvField,
  NvFlex,
  NvFunctionIcon,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvTextArea,
  SectionMessage,
  isRequired,
  useNvDialogModalState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  NodeType,
  PropertyPanelFunctionSection,
  PropertyPanelHeader,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { RealTimeTaskEngine } from '../../../../../../../../real-time/real-time-task-engine';
import { GLOBAL_REAL_TIME_TASKS } from '../../../../../../../../real-time/real-time-tasks-object';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { FunctionGenerationResult, FunctionPropertyPanelProps } from './types';

export const FunctionPropertyPanel = ({ onCloseClicked, functionNode }: FunctionPropertyPanelProps) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { mutate: generateFunction } = useGenerateFunction();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { hasEditPermission } = useWorkflowPermission();
  const queryClient = useQueryClient();
  const [isGeneratingFunction, setIsGenerationFunction] = useState<boolean>(false);
  const [functionGenerationErrorMessage, setFunctionGenerationErrorMessage] = useState<string | null>(null);
  const handleModalClose = () => {
    setFunctionGenerationErrorMessage(null);
    onModalCloseClicked();
  };

  assert(
    !!functionNode,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  const [scriptValue, setScriptValue] = useState<string>(functionNode.script ?? '');

  useEffect(() => {
    setScriptValue(functionNode.script);
  }, [functionNode.script]);

  const handleSave = useCallback(
    (newNode: FunctionNode) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail({ appId: userAppId, workflowId, nodeAlias: functionNode.alias, payload: newNode });
    },
    [hasEditPermission, updateNodeDetail, userAppId, workflowId, functionNode.alias]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: functionNode.alias,
          payload: { ...functionNode, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(functionNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [functionNode, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const debouncedSave = useDebouncedCallback(handleSave, 500);

  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvFunctionIcon />}
          title={functionNode.name}
          type={NodeType.FUNCTION}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: functionNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvFunctionIcon />}
          title={functionNode.name}
          type={NodeType.FUNCTION}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <PropertyPanelSimpleSection>
        <NvFlex direction="row" alignItems="center" justifyContent="flex-start">
          <NvButton
            color="secondary"
            size="small"
            startIcon={<NvAutoAwesomeIcon />}
            onClick={() => {
              onModalOpenClicked();
            }}
          >
            Generate function with AI
          </NvButton>
        </NvFlex>
      </PropertyPanelSimpleSection>

      <PropertyPanelFunctionSection>
        <CodeInput
          value={scriptValue}
          onChange={(newValue) => {
            setScriptValue(newValue);
            debouncedSave({ ...functionNode, script: newValue });
          }}
          context={workflowCodeInputContext}
          mode="free-js"
          placeholder="Javascript supported"
        />
      </PropertyPanelFunctionSection>
      {isOpened && (
        <NvDialogModal
          fullWidth
          maxWidth="sm"
          formProps={{ initialValues: { userInput: '' }, keepDirtyOnReinitialize: true }}
          open={isOpened}
          isLoading={isGeneratingFunction}
          onCloseButtonClicked={handleModalClose}
          Header={'Generate function with AI'}
          Body={
            <>
              {functionGenerationErrorMessage && (
                <SectionMessage
                  variant="error"
                  message={functionGenerationErrorMessage}
                  onClose={() => {
                    setFunctionGenerationErrorMessage(null);
                  }}
                />
              )}
              <NvField
                labelText="Prompt"
                name={'userInput'}
                direction="label-on-top"
                component={<NvTextArea minRows={3} maxRows={16} />}
                validators={[isRequired()]}
                hasRequiredIndicator
                showErrorMessageOnlyWhenBlur
              />
            </>
          }
          primaryButtonText={'Generate'}
          modalIcon="autoAwesome"
          onFormSubmit={({ userInput }) => {
            return new Promise<void>((resolve) => {
              setIsGenerationFunction(true);
              generateFunction(
                {
                  appId: userAppId,
                  workflowId,
                  nodeName: functionNode.name,
                  nodeAlias: functionNode.alias,
                  userInput,
                  currentFunction: functionNode.script,
                },
                {
                  onSuccess: () => {
                    GLOBAL_REAL_TIME_TASKS['function_generation'] = new RealTimeTaskEngine<
                      unknown,
                      {
                        id: string;
                        type: 'function_generation';
                        userId: string;
                        workspaceId: string;
                        properties: string;
                      }
                    >({
                      id: 'function_generation-task',
                      resultAction: async ({ resultContext: result, source }) => {
                        if (source === 'socket') {
                          if (result.type === 'function_generation') {
                            const functionGenerationResult = JSON.parse(result.properties) as FunctionGenerationResult;

                            if (functionGenerationResult.success) {
                              setFunctionGenerationErrorMessage(null);
                              queryClient.invalidateQueries(
                                QUERY_KEYS_WORKFLOWS.detail({
                                  appId: functionGenerationResult.appId,
                                  workflowId: functionGenerationResult.workflowId,
                                })
                              );
                              queryClient.invalidateQueries(
                                QUERY_KEYS_NODE.detail({
                                  appId: functionGenerationResult.appId,
                                  workflowId: functionGenerationResult.workflowId,
                                  nodeAlias: functionGenerationResult.nodeAlias,
                                })
                              );
                              handleModalClose();
                            } else {
                              setFunctionGenerationErrorMessage(functionGenerationResult.error);
                            }

                            setIsGenerationFunction(false);
                          }
                        }
                      },
                    });
                  },
                  onError: (error) => {
                    setIsGenerationFunction(false);
                    throw error;
                  },
                  onSettled: () => {
                    resolve();
                  },
                }
              );
            });
          }}
        />
      )}
      {/* Open it after test with sample context feature will be implemented */}
      {/* <PropertyPanelSimpleSection>
        <NvFlex gap="8px" direction="row" alignItems="center">
          <NvButton
            color="success"
            size="small"
            onClick={() => {
              executeFunction(
                {
                  userAppId,
                  workflowId,
                  alias: functionNode.alias,
                  isDraft: functionNode.state === WorkflowState.DRAFT,
                },
                {
                  onSuccess: (result) => {
                    if ('errorMessage' in result) {
                      setTestResult(result);
                    } else {
                      setTestResult(result.functionContext);
                    }
                  },
                }
              );
            }}
          >
            Test function
          </NvButton>
          <NvTypography variant="body3" textColor="subtle">
            Test function to see the response.
          </NvTypography>
        </NvFlex>
      </PropertyPanelSimpleSection>
      <PropertyPanelListHeader title="Response" />
      <PropertyPanelSimpleSection>
        {isTestResultLoading ? (
          <PropertyPanelSectionBody>
            <NvFlex gap="8px">
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
              <NvSkeleton variant="rectangular" width="100%" height="32px" />
            </NvFlex>
          </PropertyPanelSectionBody>
        ) : testResult ? (
          <OutputItem content={testResult} isTestResultFailed={isTestResultFailed} />
        ) : (
          <NvFlex direction="row" alignItems="center" gap="8px">
            <NvReceiptLongIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[60]} />
            <NvTypography variant="body2" textColor="ghost">
              There is no response.
            </NvTypography>
          </NvFlex>
        )}
      </PropertyPanelSimpleSection> */}
    </>
  );
};
