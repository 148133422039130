import { FormControl } from '@mui/material';
import { BillingCycle, SubscriptionPlan, useGetSwitchType } from '@novaera/actioner-service';
import {
  FieldTitle,
  NvBox,
  NvField,
  NvFlex,
  NvMenuItem,
  NvSelect,
  NvSelectMenuItem,
  NvSkeleton,
  NvTypography,
  useField,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert, numberFormatter } from '@novaera/utils';
import { format } from 'date-fns';
import { max, upperFirst } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { BillingCycleSelectType, ChangePlanModalBodyProps } from './types';

export const ChangePlanModalBody: FC<ChangePlanModalBodyProps> = ({ currentSubscription, plansMap }) => {
  const theme = useTheme();
  const {
    input: { value: selectedPlanId, onChange: changePlanId },
  } = useField<string>('planId');
  const {
    input: { value: billingCycle, onChange: changeBillingCycle },
  } = useField<BillingCycle>('billingCycle');

  const { data: switchFortuneData, isLoading: isFortuneDataLoading } = useGetSwitchType({
    fromBillingCycle: currentSubscription.billingCycle,
    fromPlanId: currentSubscription.planId,
    toPlanId: selectedPlanId,
    toBillingCycle: billingCycle,
  });

  const allPlans = Object.values(plansMap).flat();
  const allGroups = Object.values(plansMap).map((plans) => ({ label: plans[0].name, value: plans }));
  const [selectedGroup, setSelectedGroup] = useState<SubscriptionPlan[]>(allGroups[0].value);
  const selectedPlan = useMemo(() => allPlans.find((plan) => plan.id === selectedPlanId), [allPlans, selectedPlanId]);

  const availableBillingCycles: BillingCycleSelectType[] | undefined = useMemo(() => {
    if (selectedPlanId) {
      assert(!!selectedPlan, new Error('There should be a selected plan'), 'ERROR');

      const yearlyMaxPrice = max(
        selectedPlan.prices.map((p) => (p.billingCycle === 'yearly' ? p.amountInDollars : p.amountInDollars * 12))
      );
      assert(yearlyMaxPrice !== undefined, new Error('Yearly max price should exist'), 'ERROR');

      return (
        selectedPlan?.prices.map((price) => {
          const discountPercentage =
            100 -
            ((price.billingCycle === 'yearly' ? price.amountInDollars : price.amountInDollars * 12) * 100) /
              yearlyMaxPrice;
          return {
            value: price.billingCycle,
            label: `${upperFirst(price.billingCycle)}`,
            description: discountPercentage ? `(Save ${discountPercentage.toFixed(0)}%)` : undefined,
          };
        }) ?? []
      );
    } else {
      return [];
    }
  }, [selectedPlan, selectedPlanId]);

  const selectedPrice = useMemo(
    () => selectedPlan?.prices.find((price) => price.billingCycle === billingCycle),
    [billingCycle, selectedPlan?.prices]
  );

  return (
    <NvFlex gap={'16px'} minWidth="500px">
      <NvFlex direction={'row'} alignItems={'flex-start'} gap={'8px'}>
        <FieldTitle direction={'label-on-side'} labelText={'Plan type'} labelWidth="90px" />
        <FormControl
          sx={{
            width: 'auto',
            flex: '1 1 0',
            minWidth: 0,
          }}
        >
          <NvSelect<SubscriptionPlan[]>
            options={allGroups}
            value={selectedGroup}
            onChange={(event) => {
              assert(typeof event.target.value === 'object', new Error('Selected group should be an array'), 'ERROR');
              setSelectedGroup(event.target.value);
              changePlanId(event.target.value[0].id);
              const firstBillingCycle = event.target.value[0]?.prices?.[0]?.billingCycle;
              changeBillingCycle(firstBillingCycle);
            }}
            sx={{ width: '300px' }}
            size="medium"
          ></NvSelect>
        </FormControl>
      </NvFlex>

      <NvField
        name={'planId'}
        labelText="Credits"
        component={({ onChange, value }) =>
          selectedGroup.length > 1 ? (
            <NvSelect
              value={value}
              onChange={(event) => {
                const firstBillingCycle = selectedGroup.find((availablePlan) => availablePlan.id === event.target.value)
                  ?.prices?.[0]?.billingCycle;
                changeBillingCycle(firstBillingCycle);
                onChange(event.target.value);
              }}
              sx={{ width: '300px' }}
              size="medium"
            >
              {selectedGroup.map((availablePlan) => (
                <NvSelectMenuItem key={`${availablePlan.id}-${availablePlan.name}`} value={availablePlan.id}>
                  <NvTypography variant="body1" noWrap textOverflow={'ellipsis'}>
                    {numberFormatter({
                      number: availablePlan.resourceLimits.find((r) => r.resource === 'node-execution')?.quantity,
                      digits: 0,
                    })}
                  </NvTypography>
                </NvSelectMenuItem>
              ))}
            </NvSelect>
          ) : (
            <NvBox borderRadius="6px" bgcolor={theme.palette.nv_neutral_alpha[20]} padding="6px 12px" width="300px">
              <NvTypography variant="body1">
                {numberFormatter({
                  number: selectedGroup[0].resourceLimits.find((r) => r.resource === 'node-execution')?.quantity,
                  digits: 0,
                })}
              </NvTypography>
            </NvBox>
          )
        }
      />
      {selectedPlanId && (
        <>
          {selectedPlanId !== 'free-v1' && (
            <NvField
              name={'billingCycle'}
              labelText="Billing cycle"
              defaultValue={availableBillingCycles?.[0]}
              component={
                <NvSelect size="medium" sx={{ width: '180px' }}>
                  {availableBillingCycles.map((billingCycle) => (
                    <NvMenuItem value={billingCycle.value} key={billingCycle.value}>
                      <NvTypography variant="body1">
                        {billingCycle.label}{' '}
                        <NvTypography variant="body1" textColor="subtle" component="span">
                          {billingCycle.description}
                        </NvTypography>
                      </NvTypography>
                    </NvMenuItem>
                  ))}
                </NvSelect>
              }
            />
          )}
          <NvBox>
            {isFortuneDataLoading ? (
              <NvSkeleton height="20px" width="100%" />
            ) : switchFortuneData === 'upgrade' ? (
              <NvTypography variant="body1">
                You will be charged{' '}
                <b>
                  ${selectedPrice?.amountInDollars.toLocaleString()}, {selectedPrice?.billingCycle}
                </b>
              </NvTypography>
            ) : (
              <NvTypography variant="body1">
                Your workspace plan will be changed after the billing cycle on{' '}
                <b>{format(new Date(currentSubscription.periodEnd), 'MMMM dd, yyyy')}</b>.
              </NvTypography>
            )}
          </NvBox>
        </>
      )}
    </NvFlex>
  );
};
