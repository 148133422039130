import { NvDivider } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { DynamicStaticMenuItem } from './dynamic-static-menu-item';
import { HiddenInputCheckbox } from './hidden-input-checkbox';
import { ParameterMappingSettingsMenuProps } from './types';

export const ParameterMappingSettingsMenu: FC<React.PropsWithChildren<ParameterMappingSettingsMenuProps>> = ({
  hiddenConfig,
  dynamicStaticConfig,
  onClose,
}) => {
  return (
    <>
      {dynamicStaticConfig.show && (
        <>
          <DynamicStaticMenuItem
            items={[
              { title: 'Static', value: 'static', description: 'Select an item from the dropdown' },
              {
                title: 'Dynamic',
                value: 'dynamic',
                description: `You can set this value from dynamic sources. e.g.: {{input.channelName}}`,
              },
            ]}
            selectedIndex={dynamicStaticConfig.isScripted ? 1 : 0}
            onItemClicked={(value) => {
              assert(
                value === 'static' || value === 'dynamic',
                new Error(`value should be static or dynamic but it is ${value}`),
                'ERROR'
              );
              dynamicStaticConfig.onItemClicked(value);
            }}
            onClose={onClose}
          />
          {hiddenConfig.show && <NvDivider />}
        </>
      )}
      {hiddenConfig.show && <HiddenInputCheckbox value={hiddenConfig.value} onChange={hiddenConfig.onChange} />}
    </>
  );
};
