import { Initial, NvFlex, NvImage, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

export const WorkflowRunFromLinkHeader = ({
  appLogo,
  appName,
  workflowName,
}: {
  workflowName: string;
  appName: string;
  appLogo: string;
}) => {
  const { palette } = useTheme();
  return (
    <NvFlex flexDirection={'row'} gap={'8px'}>
      <NvFlex>
        <NvImage
          FallBack={<Initial size="large" value={appName} color={palette.nv_neutral[500]} />}
          src={appLogo}
          size="large"
          imageShape="square"
          hasNoElevation
        />
      </NvFlex>
      <NvFlex>
        <NvTypography variant="h2">{workflowName}</NvTypography>
        <NvTypography variant="h5">{appName}</NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
