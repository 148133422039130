import { BlankNode, Graph, Vertex, Vertices } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import {
  APP_WORKFLOW_HELPER_NODE_TYPES,
  APP_WORKFLOW_REAL_NODE_TYPES,
  APP_WORKFLOW_TRIGGER_NODE_TYPES,
} from '../../../../components/user-app-workflow-node-types';
import { AppWorkflowNodeType } from '../../../../components/user-app-workflow-node-types/types';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { DroppedOnNode, ItemOnDropped } from '../../types';

type MoveNodeParams = {
  vertices: Vertices;
  nodeId: string;
  itemOnDropped: ItemOnDropped;
  graph: Graph;
};

const isNotBranchLikeNode = (type: Vertex['type']) => {
  switch (type) {
    case 'action':
    case 'delay':
    case 'function':
    case 'output':
    case 'response':
    case 'blank':
    case 'workflowDispatcher':
    case 'aiKnowledge':
    case 'assistant':
    case 'getConversation':
    case 'sendEmail':
    case 'actionerEventPublisher':
    case 'job':
    case 'workflowResolver':
    case 'file':
    case 'linkGenerator':
    case 'http':
    case 'assistant_cancel_run':
      return true;
    case 'branchJunction':
    case 'loop':
    case 'workflowCondition':
      return false;
  }
};

const isDroppedOnNode = (itemOnDropped: ItemOnDropped): itemOnDropped is DroppedOnNode => {
  return itemOnDropped !== undefined && typeof itemOnDropped === 'object';
};

function getNodeById(vertices: Vertices, nodeId?: string) {
  if (nodeId === undefined) {
    return;
  }
  return vertices.find((node) => node.alias === nodeId);
}

function isTriggerNode(type: string) {
  return Object.keys(APP_WORKFLOW_TRIGGER_NODE_TYPES).includes(type);
}

function isAppAppWorkflowNodeType(
  param: ReturnType<typeof getParentNode>
): param is { type: AppWorkflowNodeType; parent: string } {
  return [...Object.keys(APP_WORKFLOW_REAL_NODE_TYPES), ...Object.keys(APP_WORKFLOW_HELPER_NODE_TYPES)].includes(
    param?.type as AppWorkflowNodeType
  );
}

function getParentNode(alias: string) {
  const predecessors = userAppGraph.getPredecessors({ nodeId: alias });

  if (predecessors && predecessors?.length === 1) {
    const predecessor = predecessors[0];

    /**
     * If predecessor is not a trigger node, then return the parent node
     * If predecessor is a trigger node, then return null
     * If predecessor is ont a trigger node and has no parent, then return itself as parent (this means it is the node coming after the trigger node)
     */
    if (!isTriggerNode(predecessor.type) && predecessor.parent !== undefined) {
      return {
        type: predecessor.type,
        parent: predecessor.parent,
      };
    } else if (!isTriggerNode(predecessor.type) && predecessor.parent === undefined) {
      return {
        type: predecessor.type,
        parent: predecessor.alias,
      };
    } else if (isTriggerNode(predecessor.type)) {
      return {
        type: predecessor.type,
      };
    } else {
      assert(false, new Error('Not implemented for getParentNode'));
    }
  }

  return;
}

function setAliasesForMovedNodesParent({
  parentNode,
  vertices,
  movedNode,
}: {
  parentNode: { type: AppWorkflowNodeType; parent?: string };
  movedNode?: Vertex;
  vertices: Vertices;
}) {
  assert(
    parentNode.parent !== undefined,
    new Error("ParentNode parameter's parent is undefined setAliasesForMovedNodesParent")
  );
  const parent = getNodeById(vertices, parentNode.parent);

  assert(parent !== undefined, new Error('Parent node is undefined in setAliasesForMovedNodesParent'));

  if (movedNode && parent.type === 'branchJunction') {
    if (parent.firstInnerAliases.includes(movedNode.alias)) {
      const index = parent.firstInnerAliases.findIndex((alias) => alias === movedNode.alias);
      if (movedNode.nextAlias) {
        parent.firstInnerAliases[index] = movedNode.nextAlias;
        delete movedNode.nextAlias;
      } else {
        // this will generate a new blank node and will be added to vertices also
        const blankNode = userAppGraph.getNewAlias('blank');
        parent.firstInnerAliases[index] = blankNode.newAlias;
        vertices.push({
          type: 'blank',
          alias: blankNode.newAlias,
        });
      }
    } else {
      parent.nextAlias = movedNode?.nextAlias;
    }
  } else if (parent.type === 'workflowCondition' && movedNode) {
    const successors = userAppGraph.getSuccessors({ nodeId: parent.alias });
    const shouldAddNewBlankNode =
      successors?.length === 0 ||
      successors?.find((successor) => successor.alias === movedNode.alias)?.nextNodeAlias === undefined;

    if (parent.falseAlias === movedNode?.alias) {
      if (shouldAddNewBlankNode) {
        const blankNode = userAppGraph.getNewAlias('blank');
        parent.falseAlias = blankNode.newAlias;
        vertices.push({
          type: 'blank',
          alias: blankNode.newAlias,
        });
      } else {
        if (movedNode.nextAlias) {
          parent.falseAlias = movedNode.nextAlias;
        }
      }
    } else if (parent.trueAlias === movedNode?.alias) {
      if (shouldAddNewBlankNode) {
        const blankNode = userAppGraph.getNewAlias('blank');
        parent.trueAlias = blankNode.newAlias;
        vertices.push({
          type: 'blank',
          alias: blankNode.newAlias,
        });
      } else {
        if (movedNode.nextAlias) {
          parent.trueAlias = movedNode.nextAlias;
        }
      }
    } else {
      parent.nextAlias = movedNode?.nextAlias;
    }
  } else if (parent.type === 'loop') {
    if (parent.firstInnerAlias === movedNode?.alias) {
      if (movedNode.nextAlias) {
        parent.firstInnerAlias = movedNode.nextAlias;
      } else {
        const blankNode = userAppGraph.getNewAlias('blank');
        parent.firstInnerAlias = blankNode.newAlias;
        vertices.push({
          type: 'blank',
          alias: blankNode.newAlias,
        });
      }
    } else {
      parent.nextAlias = movedNode?.nextAlias;
    }
  } else if (isNotBranchLikeNode(parent.type)) {
    parent.nextAlias = movedNode?.nextAlias;
  }
}

function removeFromVertices(vertices: Vertices, alias: string) {
  const index = vertices.findIndex((node) => node.alias === alias);
  assert(index !== -1, new Error('Index is -1 in removeFromVertices'));
  vertices.splice(index, 1);
}

function setAliasForBlankNode(movedNode: Vertex, vertices: Vertices, itemOnDropped: string) {
  const blankNode = getNodeById(vertices, itemOnDropped);
  if (blankNode === undefined) {
    // this is an edge or add button for branch, condition,loop
    const successor = userAppGraph.getSuccessors({ nodeId: itemOnDropped })?.[0];
    if (successor !== undefined) {
      let predecessors = userAppGraph
        .getPredecessors({ nodeId: successor.alias })
        ?.filter((node) => node.type !== 'DummyNode');
      assert(predecessors !== undefined, new Error('Predecessors can not be null'));
      if (predecessors.length > 1) {
        // we may have a branch
        predecessors = predecessors.filter((predecessor) => predecessor.alias === itemOnDropped);
      }
      assert(predecessors.length === 1, new Error('Predecessors length is not 1'));

      const predecessor = predecessors[0];
      const parentAlias = predecessor.parent;

      assert(parentAlias !== undefined, new Error('Parent alias is undefined'));
      const parentNode = getNodeById(vertices, parentAlias);
      assert(parentNode !== undefined, new Error('Parent node is undefined'));

      const isMovedInsideChildBranch = successor.type === 'AddButton' && predecessor.type === 'AddButton';
      if (!isMovedInsideChildBranch) {
        parentNode.nextAlias = movedNode.alias;
        movedNode.nextAlias = successor.alias;
      } else {
        parentNode.nextAlias = movedNode.alias;
        delete movedNode.nextAlias;
      }
    } else {
      const graphNode = userAppGraph.node(itemOnDropped);
      if (graphNode.isLeaf) {
        const leafNode = vertices.find((vertex) => vertex.alias === graphNode.parent);
        assert(leafNode !== undefined, new Error('Leaf node is undefined'));
        leafNode.nextAlias = movedNode.alias;
        delete movedNode.nextAlias;
      } else {
        assert(false, new Error('Not implemented for setAliasForBlankNode where isLeaf is false'));
      }
    }
  } else {
    const predecessors = userAppGraph.getPredecessors({ nodeId: blankNode.alias });
    assert(predecessors !== undefined, new Error('Predecessors can not be null'));
    assert(predecessors.length === 1, new Error('Predecessors length is not 1'));
    const predecessor = predecessors[0];
    const predecessorNode = getNodeById(vertices, predecessor.alias);
    assert(predecessorNode !== undefined, new Error('Predecessor node is undefined'));
    if (predecessorNode.type === 'workflowCondition') {
      if (predecessorNode.trueAlias === blankNode.alias) {
        predecessorNode.trueAlias = movedNode.alias;
      } else if (predecessorNode.falseAlias === blankNode.alias) {
        predecessorNode.falseAlias = movedNode.alias;
      }
      delete movedNode.nextAlias;
      removeFromVertices(vertices, blankNode.alias);
    } else if (predecessorNode.type === 'loop') {
      predecessorNode.firstInnerAlias = movedNode.alias;
      delete movedNode.nextAlias;
      removeFromVertices(vertices, blankNode.alias);
    } else if (predecessorNode.type === 'branchJunction') {
      const index = predecessorNode.firstInnerAliases.findIndex((alias) => alias === blankNode.alias);
      predecessorNode.firstInnerAliases[index] = movedNode.alias;
      delete movedNode.nextAlias;
      removeFromVertices(vertices, blankNode.alias);
    } else {
      assert(false, new Error('Not implemented for setAliasForBlankNode'));
    }
  }
}

function setAliasForDroppedOnNode(movedNode: Vertex, vertices: Vertices, itemOnDropped: DroppedOnNode) {
  const parentNode = getNodeById(vertices, itemOnDropped.sourceId);
  const predecessorNode = getNodeById(vertices, itemOnDropped.targetId);
  if (parentNode && parentNode.type === 'branchJunction') {
    const index = parentNode.firstInnerAliases.findIndex((alias) => alias === predecessorNode?.alias);
    parentNode.firstInnerAliases[index] = movedNode.alias;

    if (predecessorNode) {
      movedNode.nextAlias = predecessorNode.alias;
    }
  } else if (parentNode && parentNode.type === 'workflowCondition') {
    if (predecessorNode) {
      if (predecessorNode.alias === parentNode.trueAlias) {
        parentNode.trueAlias = movedNode.alias;
      } else if (predecessorNode.alias === parentNode.falseAlias) {
        parentNode.falseAlias = movedNode.alias;
      }
      movedNode.nextAlias = predecessorNode.alias;
    }
  } else if (parentNode && parentNode.type === 'loop') {
    parentNode.firstInnerAlias = movedNode.alias;
    if (predecessorNode) {
      movedNode.nextAlias = predecessorNode.alias;
    }
  } else if (parentNode && isNotBranchLikeNode(parentNode.type)) {
    parentNode.nextAlias = movedNode.alias;
    delete movedNode.nextAlias;
    if (predecessorNode) {
      movedNode.nextAlias = predecessorNode.alias;
    }
  } else if (!parentNode) {
    // this means it is a trigger node
    if (predecessorNode) {
      movedNode.nextAlias = predecessorNode.alias;
    } else {
      assert(false, new Error('Not implemented for setAliasForDroppedOnNode'));
    }
  }
}

function getUpdatedNodes(graph: Graph, vertices: Vertices) {
  const updatedNodes = [
    ...vertices.filter(
      (vertex) => graph.vertices.find((graphVertex) => graphVertex.alias === vertex.alias) === undefined
    ),
  ]
    .filter((vertex) => vertex.type === 'blank')
    .map((vertex) => {
      return {
        alias: vertex.alias,
        type: vertex.type,
        name: vertex.alias,
      } as BlankNode;
    });

  return updatedNodes;
}

function moveNode({ itemOnDropped, nodeId, vertices, graph }: MoveNodeParams): {
  vertices: Vertices;
  updatedNodes: BlankNode[];
} {
  const movedNode = getNodeById(vertices, nodeId);
  assert(movedNode !== undefined, new Error('Moved node is not found'));
  // the parent node that we moved
  const movedNodeParent = getParentNode(movedNode.alias);
  assert(movedNodeParent !== undefined, new Error('Moved node parent is not found'));

  if (isAppAppWorkflowNodeType(movedNodeParent)) {
    setAliasesForMovedNodesParent({ parentNode: movedNodeParent, movedNode, vertices });
  }

  if (!isDroppedOnNode(itemOnDropped)) {
    setAliasForBlankNode(movedNode, vertices, itemOnDropped);
  } else {
    setAliasForDroppedOnNode(movedNode, vertices, itemOnDropped);
  }

  const updatedNodes = getUpdatedNodes(graph, vertices);

  return { vertices, updatedNodes };
}

export { moveNode };
