import { ConnectionSchema } from '@novaera/actioner-service';
import { capitalizeFirstLetter } from '@novaera/utils';
import { useGlobalConnectionSchemasController } from '../../../../connection-schema-modal/controllers/use-global-connection-schemas';

export const useConnectModalBody = ({ connectionSchema }: { connectionSchema?: ConnectionSchema }) => {
  const getSuggestedConnectionSchema = () => {
    if (connectionSchema) {
      return `My ${capitalizeFirstLetter(connectionSchema?.name)} connection`;
    } else {
      return 'Connection';
    }
  };

  const { isLoading: isGlobalConnectionSchemasLoading, connectionSchemas } = useGlobalConnectionSchemasController({
    addCustomConnectionSchema: false,
    enabled: true,
  });

  return {
    suggestedConnection: getSuggestedConnectionSchema(),
    isSlackConnection: connectionSchema?.slack,
    isMsTeamsConnection: connectionSchema?.msteams,
    isGlobalConnectionSchemasLoading,
    connectionSchemas,
  };
};
