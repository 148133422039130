import { HTTPNode, useGetConnectionSchemaService, useUpdateNode } from '@novaera/actioner-service';
import { HTTP_METHODS } from '@novaera/constants';
import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSelect,
  NvTooltip,
  NvTypography,
  isRequired,
  useField,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import OnChange from '../../../../../../../../action-designer/components/on-change';
import { BaseUrlBox } from '../../../../../../../../action-designer/components/request-tab/requests/detail/styled';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { ConnectionSection } from '../../../../components/slack-workspace-or-workflow-connection-section/workflow-connection-section';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { HTTPPropertiesProps, SubPanelType } from './types';

const rowItems: { label: string; type: SubPanelType }[] = [
  { label: 'Params', type: 'params' },
  { label: 'Headers', type: 'headers' },
  { label: 'Body', type: 'body' },
];

export const HTTPPropertiesMainPanel: FC<HTTPPropertiesProps> = ({
  httpNode,
  onCloseClicked,
  onCloseSecondPanel,
  onSectionSelected,
  selectedSecondPanelType,
  workflow,
}) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { data: connectionSchemaData } = useGetConnectionSchemaService({
    schemaId: httpNode.connectionSchemaId,
  });

  const {
    input: { value: httpMethod },
  } = useField('method');

  const {
    input: { onChange: changeBodyTemplate },
  } = useField('bodyTemplate');

  const {
    input: { onChange: changeFormParameters },
  } = useField('formParameters');

  const {
    input: { onChange: changeMediaType },
  } = useField('mediaType');

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: httpNode.alias,
          payload: { ...(httpNode as HTTPNode), name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(httpNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [httpNode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const handleUpdateNode = useCallback(
    ({ httpNode, isUpdateGraph }: { httpNode: HTTPNode; isUpdateGraph?: boolean }) => {
      if (!hasEditPermission) {
        return;
      }

      updateNodeDetail(
        { appId: userAppId, workflowId, nodeAlias: httpNode.alias, payload: httpNode },
        {
          onSuccess: () => {
            if (isUpdateGraph) {
              const graphNode = userAppGraph.node(httpNode.alias);
              const newGraphNode = cloneDeep(graphNode);
              newGraphNode.name = httpNode.name;
              updateNode({ newNode: newGraphNode });
            }
          },
        }
      );
    },
    [hasEditPermission, updateNodeDetail, updateNode, userAppId, workflowId]
  );

  const filteredRowItems = useMemo(
    () => (httpMethod === 'GET' ? rowItems.filter((r) => r.type !== 'body') : rowItems),
    [httpMethod]
  );

  const hasConnectionSchema = !!httpNode.connectionSchemaId;

  return (
    <>
      <OnChange name="method">
        {(current) => {
          if (current === 'GET') {
            onCloseSecondPanel();
            changeBodyTemplate(undefined);
            changeFormParameters(undefined);
            changeMediaType(undefined);
          }
        }}
      </OnChange>
      <NvFlex width="100%">
        <PropertyPanelHeader
          hasEditRight={hasEditPermission}
          title={httpNode.name}
          type={NodeType.HTTP}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: httpNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
        <ConnectionSection
          type="schema-selection-included"
          workflow={workflow}
          connectionIdValue={httpNode.connectionId}
          onConnectionChange={(connection) => {
            handleUpdateNode({ httpNode: { ...httpNode, connectionId: connection?.connectionId } });
          }}
          connectionSchemaIdValue={httpNode.connectionSchemaId}
          onConnectionSchemaIdChange={(connectionSchemaId) => {
            handleUpdateNode({
              httpNode: {
                ...httpNode,
                connectionSchemaId: connectionSchemaId ? connectionSchemaId : undefined,
                connectionId: undefined,
              },
            });
          }}
        />
        <PropertyPanelListHeader title="Request" tooltip="Request"></PropertyPanelListHeader>
        <PropertyPanelSimpleSection>
          <NvFlex gap="16px" alignItems="unset">
            <NvFlex gap="8px">
              <NvField
                name={`method`}
                defaultValue="GET"
                formControlStyle={{ width: '120px', flex: '0 0 auto' }}
                isRequired
                validators={[isRequired()]}
                component={<NvSelect id="httpMethod" options={HTTP_METHODS} size="small" />}
              />

              <NvField
                name={`urlTemplate`}
                isRequired
                validators={[isRequired()]}
                showErrorMessageOnlyWhenBlur
                component={
                  <CodeInput
                    context={workflowCodeInputContext}
                    expectedType="string"
                    {...(hasConnectionSchema
                      ? {
                          startAdornment: (
                            <NvTooltip
                              title={connectionSchemaData?.authentication.baseUrl}
                              variant="large"
                              placement="top"
                              fullWidth
                            >
                              <BaseUrlBox>
                                <NvTypography variant="c2" textColor="subtle" component="p">
                                  Base URL
                                </NvTypography>
                              </BaseUrlBox>
                            </NvTooltip>
                          ),
                          startAdornmentOffset: 65,
                        }
                      : {})}
                  />
                }
              />
            </NvFlex>
            <NvFlex gap={'8px'}>
              {filteredRowItems.map((r, index) => (
                <RowItemCard
                  key={index}
                  rowItemLeftContentProps={{ draggable: false }}
                  rowItemContent={<SimpleLabelLayout simpleLabel={r.label} />}
                  onClick={() => {
                    onSectionSelected({ subPanelType: r.type });
                  }}
                  isSelected={selectedSecondPanelType === r.type}
                />
              ))}
            </NvFlex>
          </NvFlex>
        </PropertyPanelSimpleSection>

        <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <RowItemCard
            rowItemLeftContentProps={{ draggable: false }}
            rowItemContent={<SimpleLabelLayout simpleLabel={'Example response'} />}
            onClick={() => {
              onSectionSelected({ subPanelType: 'example-response' });
            }}
            isSelected={selectedSecondPanelType === 'example-response'}
          />
        </PropertyPanelSimpleSection>
        <NvField
          name="errorHandlingStrategy"
          formControlStyle={{ width: '100%' }}
          component={<ErrorHandlingStrategyPanel />}
        />
      </NvFlex>
    </>
  );
};
