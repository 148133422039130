import { DefaultNodeSummary, QANode, QAVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class QAComponentFactory implements ComponentFactory<QANode> {
  aliasForQA: string;
  nameForQA: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('aiKnowledge');
    this.aliasForQA = newAlias;
    this.nameForQA = newName;
  }

  get componentForGraph() {
    const workflowResolverRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForQA,
      width: 40,
      height: 40,
      id: this.aliasForQA,
      type: 'aiKnowledge',
      alias: this.aliasForQA,
    };

    return { root: workflowResolverRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: QANode) {
    const newNodeUnion: QAVertex = {
      type: 'aiKnowledge',
      alias: this.aliasForQA,
    };
    const summary: DefaultNodeSummary = {
      name: this.nameForQA,
      alias: this.aliasForQA,
      type: 'aiKnowledge',
    };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForQA]: summary } };
  }
}
