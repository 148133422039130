import {
  useGetIntegration,
  useGetIntegrationEventRule,
  useSaveToDraftWorkflow,
  Workflow,
} from '@novaera/actioner-service';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';

export const useIntegrationTriggerProperties = ({ workflow }: { workflow?: Workflow }) => {
  assert(!!workflow?.trigger, new Error('Trigger should be defined in the workflow'), 'ERROR');
  const trigger = workflow.trigger;
  assert(
    !!(trigger.type === 'integrationWebhook' || trigger.type === 'integrationApp'),
    new Error('Trigger should be of type integrationWebhook or integrationApp'),
    'ERROR'
  );

  const { data: integration, isLoading } = useGetIntegration({ id: trigger.eventRuleIdentifier.integrationId });
  const { integrationEventRule } = useGetIntegrationEventRule({
    integrationId: trigger.eventRuleIdentifier.integrationId,
    id: trigger.eventRuleIdentifier.eventRuleId,
    version: trigger.eventRuleIdentifier.version.number,
  });

  const showConnectionSelection = useMemo(() => {
    return Boolean(
      workflow.trigger?.type === 'integrationApp' ||
        (workflow.trigger?.type === 'integrationWebhook' &&
          workflow.trigger.eventRuleIdentifier.eventRuleId &&
          integrationEventRule?.transformerAction?.actionId)
    );
  }, [integrationEventRule?.transformerAction?.actionId, workflow.trigger]);

  const theme = useTheme();

  const { mutate: saveDraftWorkflow } = useSaveToDraftWorkflow();

  return {
    saveDraftWorkflow,
    integration,
    isLoading,
    theme,
    showConnectionSelection,
  };
};
