import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomWorkflowResolver({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CustomWorkflowResolver"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3 10L10.04 6.85C10.01 6.74 10 6.62 10 6.5V3.5C10 2.67 10.67 2 11.5 2H14.5C15.33 2 16 2.67 16 3.5V6.5C16 7.33 15.33 8 14.5 8H11.7L8.96 11.14C8.99 11.26 9 11.38 9 11.5V14.26L11.6711 15.5955C11.2547 16.0421 11 16.6413 11 17.3C11 17.3697 11.0029 17.4388 11.0085 17.507L7.89 15.95C7.77 15.98 7.64 16 7.5 16H4.5C3.67 16 3 15.33 3 14.5V11.5C3 10.67 3.67 10 4.5 10H7.3Z"
        fill="currentColor"
      />
      <path
        d="M22 15.6842V14.6316C22 14.0526 21.55 13.5789 21 13.5789H19.5C19.5 12.7053 18.83 12 18 12C17.17 12 16.5 12.7053 16.5 13.5789H15C14.45 13.5789 14 14.0526 14 14.6316V15.6842C13.17 15.6842 12.5 16.3895 12.5 17.2632C12.5 18.1368 13.17 18.8421 14 18.8421V20.9474C14 21.5263 14.45 22 15 22H21C21.55 22 22 21.5263 22 20.9474V18.8421C22.83 18.8421 23.5 18.1368 23.5 17.2632C23.5 16.3895 22.83 15.6842 22 15.6842ZM15.75 17C15.75 16.5632 16.085 16.2105 16.5 16.2105C16.915 16.2105 17.25 16.5632 17.25 17C17.25 17.4368 16.915 17.7895 16.5 17.7895C16.085 17.7895 15.75 17.4368 15.75 17ZM20 19.8947H16V18.8421H20V19.8947ZM19.5 17.7895C19.085 17.7895 18.75 17.4368 18.75 17C18.75 16.5632 19.085 16.2105 19.5 16.2105C19.915 16.2105 20.25 16.5632 20.25 17C20.25 17.4368 19.915 17.7895 19.5 17.7895Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
