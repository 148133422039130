import {
  ActionState,
  InputParameter,
  ResponseConfiguration,
  RunConfiguration,
  USER_PERMISSION,
  useGetIntegration,
  useGetIntegrationAction,
  usePersistIntegrationAction,
  useRollbackIntegrationAction,
} from '@novaera/actioner-service';
import { NvActionFilledIcon, NvButton, NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import { ActionDesignerV1 } from '../../../action-designer';
import { FunctionsFormValues } from '../../../action-designer/components/functions-tab/types';
import { RequestsFormValues } from '../../../action-designer/components/request-tab/types';
import { useActionDesignerFormApiContext } from '../../../action-designer/providers/form-api-provider';
import { Header } from '../../../components/header';
import { TestConfigurationBar } from '../../../components/test-configuration-bar';
import { TestConfigurationProvider } from '../../../components/test-configuration-bar/providers/test-configuration';
import { UserPermissionBoundary } from '../../../user-permission-boundary';
import { useResetAndUpdateForm } from '../../../utils/hooks';
import { UpdateActionMetadataModal } from './update-action-metadata-modal';
import { useActionMenus } from './use-action-menus';

export const ActionDetail = () => {
  const { actionId, integrationId } = useParams();
  const { data: integration, isInitialLoading: isIntegrationLoading } = useGetIntegration({ id: integrationId });
  const {
    data,
    persistedIntegrationAction,
    isLoading: isIntegrationActionLoading,
  } = useGetIntegrationAction({ integrationId, actionId, version: integration?.latestVersion.number });

  const [updateActionMetadataModalIsOpen, setUpdateActionMetadataModalIsOpen] = useState<boolean>(false);
  const { mutate: persistAction, isLoading: isSaveProgress } = usePersistIntegrationAction();
  const { mutate: rollbackAction, isLoading: isDiscardProgress } = useRollbackIntegrationAction();

  const { getActionMenus } = useActionMenus();

  const isDraft = useMemo(() => data?.state === ActionState.DRAFT, [data]);
  const { functionsFormApiRef, requestsFormApiRef, inputPropertiesFormApiRef, responseFormApiRef } =
    useActionDesignerFormApiContext();
  const { resetAndUpdateForm: resetAndUpdateFunctionForm } = useResetAndUpdateForm<FunctionsFormValues>();
  const { resetAndUpdateForm: resetAndUpdateRequestForm } = useResetAndUpdateForm<RequestsFormValues>();
  const { resetAndUpdateForm: resetAndUpdateResponseForm } = useResetAndUpdateForm<ResponseConfiguration>();
  const { resetAndUpdateForm: resetAndUpdateInputPropertiesForm } = useResetAndUpdateForm<
    InputParameter | RunConfiguration
  >();
  return (
    <TestConfigurationProvider>
      {!isIntegrationLoading && integration && (
        <UserPermissionBoundary
          permission={USER_PERMISSION.INTEGRATION_UPDATE}
          Fallback={
            <Header
              icon={<NvActionFilledIcon />}
              name={persistedIntegrationAction?.name ?? ''}
              isLoading={isIntegrationActionLoading}
              isDraft={isDraft}
            />
          }
        >
          <Header
            actions={getActionMenus(integrationId, actionId, integration.latestVersion.number)}
            icon={<NvActionFilledIcon />}
            name={persistedIntegrationAction?.name ?? ''}
            onEditNameClick={() => {
              setUpdateActionMetadataModalIsOpen(true);
            }}
            isLoading={isIntegrationActionLoading}
            isDraft={isDraft}
            buttonActions={
              <>
                <NvButton
                  size="small"
                  color="secondary"
                  loading={isDiscardProgress}
                  disabled={isSaveProgress || isDiscardProgress}
                  onClick={() => {
                    return new Promise<void>((resolve) => {
                      const version = integration?.latestVersion?.number;
                      assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');
                      rollbackAction(
                        { integrationId, actionId, version },
                        {
                          onSettled: () => {
                            functionsFormApiRef.current && resetAndUpdateFunctionForm(functionsFormApiRef.current);
                            requestsFormApiRef.current && resetAndUpdateRequestForm(requestsFormApiRef.current);
                            responseFormApiRef.current && resetAndUpdateResponseForm(responseFormApiRef.current);
                            inputPropertiesFormApiRef.current &&
                              resetAndUpdateInputPropertiesForm(inputPropertiesFormApiRef.current);
                            resolve();
                          },
                        }
                      );
                    });
                  }}
                >
                  Discard changes
                </NvButton>
                <NvButton
                  size="small"
                  disabled={isSaveProgress || isDiscardProgress}
                  loading={isSaveProgress}
                  onClick={() => {
                    return new Promise<void>((resolve) => {
                      const version = integration?.latestVersion?.number;
                      assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');
                      persistAction(
                        { integrationId, actionId, version },
                        {
                          onSettled: () => {
                            functionsFormApiRef.current && resetAndUpdateFunctionForm(functionsFormApiRef.current);
                            requestsFormApiRef.current && resetAndUpdateRequestForm(requestsFormApiRef.current);
                            resolve();
                          },
                        }
                      );
                    });
                  }}
                >
                  Apply
                </NvButton>
              </>
            }
          />
        </UserPermissionBoundary>
      )}
      <NvFlex margin="16px 16px 0 16px">
        <TestConfigurationBar />
      </NvFlex>
      <UpdateActionMetadataModal
        open={updateActionMetadataModalIsOpen}
        onClose={() => {
          setUpdateActionMetadataModalIsOpen(false);
        }}
      />
      <ActionDesignerV1 integrationVersion={integration?.latestVersion.number} />
    </TestConfigurationProvider>
  );
};
