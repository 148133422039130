import { DailyJobRecurrenceConfiguration, ValueTypes } from '@novaera/actioner-service';
import { NvFlex, NvNumberInput, NvTimePicker, NvTypography } from '@novaera/core';
import { getHours, getMinutes, isValid, set } from 'date-fns';
import { isNull, isUndefined } from 'lodash';
import { RecurrenceProps } from '../../types';

export const StaticDailyRecurrence: React.FC<Omit<RecurrenceProps<DailyJobRecurrenceConfiguration>, 'context'>> = ({
  recurrenceConfiguration,
  onChange,
}) => {
  return (
    <NvFlex direction="row" alignItems="center" gap="4px">
      <NvTypography variant="body2">At</NvTypography>

      <NvTimePicker
        value={(() => {
          const hours = recurrenceConfiguration.recurrenceHour.value;
          const minutes = recurrenceConfiguration.recurrenceMinute.value;
          let date = undefined;
          if (!isNull(hours) && !isNull(minutes)) {
            date = set(new Date(), {
              hours,
              minutes,
            });
          }
          return date;
        })()}
        onChange={(d) => {
          const date = d as unknown as Date;
          let minutes, hours;
          if (isValid(date)) {
            minutes = getMinutes(date);
            hours = getHours(date);
          }

          if (!isUndefined(minutes) && !isUndefined(hours)) {
            onChange({
              ...recurrenceConfiguration,
              recurrenceMinute: {
                type: ValueTypes.NUMBER,
                displayValue: minutes.toString(),
                value: minutes,
              },
              recurrenceHour: {
                type: ValueTypes.NUMBER,
                displayValue: hours.toString(),
                value: hours,
              },
            });
          }
        }}
      />
      <NvTypography variant="body2" flex="0 0 auto">
        repeats every
      </NvTypography>
      <NvNumberInput
        value={recurrenceConfiguration.repeatInterval.value}
        onChange={(e) => {
          onChange({
            ...recurrenceConfiguration,
            repeatInterval: {
              type: ValueTypes.NUMBER,
              displayValue: e.target.value,
              value: e.target.value ? Number(e.target.value) : undefined,
            },
          });
        }}
      />
      <NvTypography variant="body2">days</NvTypography>
    </NvFlex>
  );
};
