import { InputFormValues } from '..';

export const formatAndFilterInputFormValuesAsParameterMappings = (
  values?: InputFormValues | null,
  inputParameterIds?: string[]
) => {
  if (values) {
    return Object.values(values).filter((p) => inputParameterIds?.includes(p.parameterId));
  } else return [];
};
