import { NvBox, NvFlex, NvTypography } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ErrorPanel = styled(NvFlex)`
  flex-flow: row;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  margin-top: 40px;
  border-radius: 12px;
  max-width: 500px;
  ${({ theme }) => theme.elevations.e500};
`;

export const TopLine = styled(NvBox)`
  position: relative;
  width: 2px;
  height: 16px;
  background: linear-gradient(135deg, #fadf90 0%, #ea3772 100%);
  border-radius: 1px;
  flex: 0 0 auto;
  margin-bottom: 6px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    z-index: 2;
  }
`;

export const Icon = styled(NvBox)`
  position: relative;
  height: 24px;
  width: 24px;
  display: inline-block;
  color: ${({ theme }) => theme.palette.nv_success[60]};
`;

export const BottomLine = styled(NvBox)`
  position: relative;
  width: 2px;
  background: linear-gradient(135deg, #fadf90 0%, #ea3772 100%);
  border-radius: 1px;
  flex: 1 1 auto;
  height: 100%;
  margin-top: 6px;
  margin-bottom: 16px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    z-index: 2;
  }
`;

export const ErrorPanelRight = styled(NvBox)`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  min-width: 0%;
  padding: 20px 16px 24px 20px;
`;

export const Title = styled(NvTypography)`
  padding-bottom: 20px;
`;
export const Description = styled(NvTypography)`
  padding-bottom: 24px;
`;
