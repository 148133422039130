import { ROUTES } from '../../common/routes';
import { Integrations } from '../../integrations/list';
import { Billing } from '../billing';
import { WorkspaceInfo } from '../info';
import { MsTeamsWorkspace } from '../ms-teams-workspace/ui';
import { OrganizationSettings } from '../organization';
import { SlackWorkspace } from '../slack-workspace/ui';

const WORKSPACE_SETTINGS_LINK = ROUTES.Workspace;

export const WORKSPACE_SETTINGS_TAB_PATHS = {
  INFO: '/info',
  BILLING: '/billing-and-plans',
  SLACK_WORKSPACE: '/slack',
  MS_TEAMS: '/ms-teams',
  INTEGRATIONS: '/integrations',
  ORGANIZATION: '/organization',
};

export type WorkspaceSettingsTab = {
  label: string;
  content: React.ReactNode;
  path: string;
  type: 'info' | 'slack' | 'ms-teams' | 'billing' | 'organization' | 'integrations';
};

export const WORKSPACE_SETTINGS_TABS: WorkspaceSettingsTab[] = [
  {
    label: 'Workspace info',
    content: <WorkspaceInfo />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.INFO}`,
    type: 'info',
  },
  {
    label: 'Slack',
    content: <SlackWorkspace />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.SLACK_WORKSPACE}`,
    type: 'slack',
  },
  {
    label: 'MS Teams',
    content: <MsTeamsWorkspace />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.MS_TEAMS}`,
    type: 'ms-teams',
  },
  {
    label: 'Billing and plans',
    content: <Billing />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.BILLING}`,
    type: 'billing',
  },
  {
    label: 'Organization',
    content: <OrganizationSettings />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.ORGANIZATION}`,
    type: 'organization',
  },
  {
    label: 'Custom integrations',
    content: <Integrations />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.INTEGRATIONS}`,
    type: 'integrations',
  },
];

export const DEFAULT_ROOT_PATH = WORKSPACE_SETTINGS_TABS[0].path;
