import { MarkdownToJSX } from '@insanicly/markdown-to-jsx';
import React, { createElement } from 'react';
import { allowedElements, elements } from './constants';

import { renderToStaticMarkup } from 'react-dom/server';
import { MarkdownLink } from './link';
import { MarkdownWrapper } from './styled';
import { VideoEmbed } from './video-embed';

export const Markdown: React.FC<React.PropsWithChildren<React.ComponentPropsWithRef<typeof MarkdownWrapper>>> = ({
  children,
  options,
  ...otherProps
}) => {
  const overrides = React.useMemo(() => {
    const overrides: MarkdownToJSX.Options['overrides'] = {};

    elements.forEach((element) => {
      if (!allowedElements.includes(element)) {
        overrides[element] = ({ children, props }) => {
          return <>{renderToStaticMarkup(createElement(element, props, children))}</>;
        };
      }
    });

    return overrides;
  }, []);

  return (
    <MarkdownWrapper
      {...otherProps}
      options={{
        ...options,
        overrides: {
          ...options?.overrides,
          a: {
            component: MarkdownLink,
          },
          iframe: {
            component: VideoEmbed,
          },
          ...overrides,
        },
        enforceAtxHeadings: true,
      }}
    >
      {children}
    </MarkdownWrapper>
  );
};
