import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_ACTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import {
  CreateIntegrationActionParams,
  CreateIntegrationActionResponse,
  GetIntegrationActionsResponse,
} from '../types';

const createIntegrationAction = async (params: CreateIntegrationActionParams) => {
  const { integrationId, ...rest } = params;
  const result = await NvAxios.post<CreateIntegrationActionResponse>(INTEGRATION_ACTION_ROOT_PATH(integrationId), rest);
  return result?.data;
};

export const useCreateIntegrationAction = () => {
  const cache = useQueryClient();

  return useMutation(createIntegrationAction, {
    onSuccess(data, variables) {
      cache.setQueryData(
        QUERY_KEYS_INTEGRATION_ACTIONS.list(variables.integrationId, variables.version),
        (old?: GetIntegrationActionsResponse) => {
          if (old) {
            return { ...old, actions: [...old.actions, data] };
          }
          return old;
        }
      );
    },
  });
};
