import { AccessType, WorkflowPermissionScope, useGetGroups, useGetUsersInWorkspace } from '@novaera/actioner-service';
import { FieldTitle, NvAddBoxIcon, NvBox, NvButton, NvCheckIcon, NvCloseIcon, NvCollapse, NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { UserAndGroupSearchAutoComplete } from '../../../../../../../../components/user-and-group-search-component';
import { useUserAppUsersWithPermissions } from '../../../../../../permissions/use-user-app-users-with-permissions';
import { WorkflowPermissionGroupAndUser } from '../../controllers/use-workflow-users-with-permissions/types';
import { WorkflowUserToCreatePermission } from '../types';

export const AddNewWorkflowSection: React.FC<
  React.PropsWithChildren<{ onAdd: (users: WorkflowPermissionGroupAndUser[]) => void }>
> = ({ onAdd }) => {
  const { userAppId } = useParams();
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false);
  const [newUsers, setNewUsers] = useState<WorkflowUserToCreatePermission[]>([]);
  const { data: groupsData } = useGetGroups();
  const { data } = useGetUsersInWorkspace();
  const usersInWorkspace = useMemo(() => data?.users ?? [], [data?.users]);
  const groupsInWorkspace = useMemo(() => groupsData?.groups ?? [], [groupsData?.groups]);
  const { userAppAdmins } = useUserAppUsersWithPermissions({ userAppId });
  const {
    fields: { value: workflowPermissionsUsers },
  } = useFieldArray<WorkflowPermissionGroupAndUser>('users');

  const userList: WorkflowUserToCreatePermission[] = useMemo(() => {
    const mappedUsers: WorkflowUserToCreatePermission[] = usersInWorkspace
      .map((user) => ({
        name: user.userName,
        logoUrl: user.logoUrl,
        principal: { id: user.userId, type: AccessType.USER },
        scope: WorkflowPermissionScope.READ,
        userDetail: user,
      }))
      .filter(
        (user) =>
          !(
            userAppAdmins.find(
              (accessSetting) =>
                accessSetting.principalType === AccessType.USER && accessSetting.principalId === user.principal.id
            ) ||
            workflowPermissionsUsers?.find(
              ({ principal }) => principal.type === AccessType.USER && principal.id === user.principal.id
            )
          )
      );

    const mappedGroups: WorkflowUserToCreatePermission[] = groupsInWorkspace
      .map((group) => ({
        name: group.name,
        logoUrl: group.logoUrl,
        principal: { id: group.id, type: AccessType.GROUP },
        scope: WorkflowPermissionScope.READ,
        groupDetail: group,
      }))
      .filter(
        (group) =>
          !(
            userAppAdmins.find(
              (accessSetting) =>
                accessSetting.principalType === AccessType.GROUP && accessSetting.groupDetail?.id === group.principal.id
            ) ||
            workflowPermissionsUsers?.find(
              ({ principal }) => principal.type === AccessType.GROUP && principal.id === group.principal.id
            )
          )
      );

    return [...mappedUsers, ...mappedGroups];
  }, [usersInWorkspace, groupsInWorkspace, userAppAdmins, workflowPermissionsUsers]);

  const handleCreateWorkflowPermissions = () => {
    const mappedNewUsers: WorkflowPermissionGroupAndUser[] = newUsers.map(
      ({ principal, scope, userDetail, groupDetail }) => {
        if (principal.type === AccessType.USER) {
          return {
            type: principal.type,
            principal,
            scope,
            userDetail,
          };
        } else {
          return { type: principal.type, principal, scope, groupDetail };
        }
      }
    );
    onAdd(mappedNewUsers);
    setIsAddingUser(false);
    setNewUsers([]);
  };

  return (
    <NvFlex width="100%">
      <NvCollapse in={!isAddingUser}>
        <NvButton
          color="ghost"
          size="small"
          startIcon={<NvAddBoxIcon />}
          onClick={() => {
            setIsAddingUser(true);
          }}
        >
          Select users and groups
        </NvButton>
      </NvCollapse>

      <NvCollapse in={isAddingUser}>
        <NvFlex direction="column" gap="4px">
          <FieldTitle direction="label-on-top" hasRequiredIndicator labelText="Select users and groups" />
          <NvFlex direction="row" gap="8px" alignItems="flex-start">
            <NvBox flex="1 1 auto" minWidth="0">
              <UserAndGroupSearchAutoComplete<WorkflowUserToCreatePermission, true>
                value={newUsers}
                onChange={(e, value) => {
                  setNewUsers(value);
                }}
                options={userList}
                multiple
                getOptionPrincipal={(option) =>
                  option.principal.type === AccessType.GROUP
                    ? { id: option.principal.id, type: option.principal.type }
                    : { type: option.principal.type }
                }
              />
            </NvBox>
            <NvFlex direction="row" gap="4px" alignItems="center" padding="4px 0" flex="0 0 auto">
              <NvButton onlyIcon size="small" sx={{ flex: '0 0 auto' }} onClick={handleCreateWorkflowPermissions}>
                <NvCheckIcon />
              </NvButton>
              <NvButton
                onlyIcon
                size="small"
                color="secondary"
                sx={{ flex: '0 0 auto' }}
                onClick={() => {
                  setIsAddingUser(false);
                  setNewUsers([]);
                }}
              >
                <NvCloseIcon />
              </NvButton>
            </NvFlex>
          </NvFlex>
        </NvFlex>
      </NvCollapse>
    </NvFlex>
  );
};
