import { NvFlex } from '@novaera/core';
import { FC } from 'react';

export const IntegrationDetailLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <NvFlex flex="1 1 auto" padding="40px" alignItems="center" overflow="auto" height="100%" minHeight={0}>
      <NvFlex maxWidth="768px" gap="32px" width="100%">
        {children}
      </NvFlex>
    </NvFlex>
  );
};
