import { APP_PERMISSION, useGetSlackApp, useGetUserApp } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { Outlet, useLocation } from '@novaera/route';
import classNames from 'classnames';
import { useMemo } from 'react';
import { ActionerPrivateRoute } from '../../actioner-private-route';

import { getUserAppRootPages, USER_APP_TABS } from '../../user-app/user-app-detail/constants';
import { InstallUserAppFlow } from '../../user-app/user-app-detail/install-user-app-flow';
import { UserAppDetailWrapper } from '../../user-app/user-app-detail/styled';
import { UserAppSideMenu } from '../../user-app/user-app-detail/user-app-side-menu';
import { UserAppPermissionBoundary } from '../../user-app/user-app-permission-boundary';
import { BasePageWrapper, DescriptionPageWrapper, StandardPageWrapper, UserAppPageLayoutWrapper } from './styled';

export const UserAppPageLayout = ({
  isInitialLoading,
  slackApp,
  userApp,
  userAppId,
}: {
  userAppId: string;
  userApp: ReturnType<typeof useGetUserApp>['data'];
  slackApp: ReturnType<typeof useGetSlackApp>['data'];
  isInitialLoading: boolean;
}) => {
  const location = useLocation();

  const isParentPage = useMemo(() => {
    return getUserAppRootPages(userAppId).find((page) => page === location.pathname) !== undefined;
  }, [location.pathname, userAppId]);

  const isAIAssistantPage = location.pathname.includes(USER_APP_TABS.AI_ASSISTANT);

  return !isParentPage ? (
    <ActionerPrivateRoute>
      <Outlet />
    </ActionerPrivateRoute>
  ) : isAIAssistantPage ? (
    <ActionerPrivateRoute>
      <UserAppDetailWrapper>
        <UserAppSideMenu isLoading={isInitialLoading} userApp={userApp} slackApp={slackApp} />
        <NvFlex width="100%" height="100%" flex={'1 1 auto'} minWidth={'0'}>
          <Outlet />
        </NvFlex>
      </UserAppDetailWrapper>
    </ActionerPrivateRoute>
  ) : (
    <ActionerPrivateRoute>
      <UserAppDetailWrapper>
        <UserAppSideMenu isLoading={isInitialLoading} userApp={userApp} slackApp={slackApp} />
        <NvFlex width="100%" height="100%" flex={'1 1 auto'} minWidth={'0'}>
          <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
            <InstallUserAppFlow appId={userAppId} />
          </UserAppPermissionBoundary>

          <UserAppPageLayoutWrapper>
            <NvFlex
              className={classNames(
                BasePageWrapper,
                location.pathname.includes(USER_APP_TABS.DESCRIPTION) && DescriptionPageWrapper,
                !location.pathname.includes(USER_APP_TABS.DESCRIPTION) && StandardPageWrapper
              )}
            >
              <Outlet />
            </NvFlex>
          </UserAppPageLayoutWrapper>
        </NvFlex>
      </UserAppDetailWrapper>
    </ActionerPrivateRoute>
  );
};
