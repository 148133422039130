import { assert } from '@novaera/utils';
import { isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { NvDatePicker } from '../date-picker';
import { NvFlex } from '../flex';
import { NvTimePicker } from '../time-picker';
import { DateTimePickerProps } from './types';

export const NvDateTimePicker: React.FC<React.PropsWithChildren<DateTimePickerProps>> = ({
  value,
  onChange,
  datePickerProps,
  timePickerProps,
}) => {
  assert(onChange != null, new Error('onChange should be defined or Wrap your component within NvField'), 'ERROR');

  const [dateValue, setDateValue] = useState<unknown>(null);
  const [timeValue, setTimeValue] = useState<unknown>(null);

  useEffect(() => {
    if (value === null || value instanceof Date) {
      setDateValue(value);
      setTimeValue(value);
    }
  }, [value]);

  const handleDateChange = (date: unknown) => {
    if (date instanceof Date && isValid(date) && timeValue instanceof Date && isValid(timeValue)) {
      const mergedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        timeValue.getHours(),
        timeValue.getMinutes(),
        timeValue.getSeconds()
      );
      onChange(mergedDate);
    } else {
      onChange(undefined);
    }
  };

  const handleTimeChange = (time: unknown) => {
    if (dateValue instanceof Date && isValid(dateValue) && time instanceof Date && isValid(time)) {
      const mergedDate = new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds()
      );
      onChange(mergedDate);
    } else {
      onChange(undefined);
    }
  };

  return (
    <NvFlex direction="row" gap="4px">
      <NvDatePicker
        fullWidth
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        value={dateValue}
        onChange={(date) => {
          setDateValue(date);
          handleDateChange(date);
        }}
        {...datePickerProps}
      />

      <NvTimePicker
        fullWidth
        value={timeValue}
        onChange={(time) => {
          setTimeValue(time);
          handleTimeChange(time);
        }}
        open={false}
        {...timePickerProps}
      />
    </NvFlex>
  );
};
