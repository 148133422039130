import { NvAxios, useQuery } from '@novaera/core';
import { FetchProfileResponse } from './types';

const fetchProfile = async () => {
  const result = await NvAxios.get<FetchProfileResponse>('/v1/me');
  return result?.data;
};

export const useFetchProfile = (useQueryOptions?: { retry?: boolean; enabled?: boolean; staleTime?: number }) => {
  return useQuery(['fetchProfile'], fetchProfile, useQueryOptions);
};
