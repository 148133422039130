import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';

import { Connection } from '../types';
import { UseReconnectParams } from './types';

const reconnect: (params: UseReconnectParams) => Promise<Connection> = async (params) => {
  const { payload, connectionId } = params;
  const result = await NvAxios.post<Connection>(`${CONNECTION_ROOT_PATH}/${connectionId}/reconnect`, { ...payload });
  return result?.data;
};

export const useReconnectService = () => {
  const cache = useQueryClient();

  return useMutation(reconnect, {
    onSuccess: (response) => {
      cache.setQueryData<Connection>(QUERY_KEYS_CONNECTION.detail(response.id), (old) => {
        if (old?.id === response.id) {
          return response;
        }
        return old;
      });
    },
  });
};
