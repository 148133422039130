import { useMemo, useRef } from 'react';
import { getPropertyTitle } from '../get-properties-panel';
import { FilteredComponent, PropertyConfigurationProps } from '../types';
import { getPropertySections } from '../utils/property-component-matcher';
import { commandProperties } from '../utils/property-maps/command-properties';
import { inputParameterProperties } from '../utils/property-maps/input-parameter-properties';

export const usePropertyConfiguration = ({ configuredEntity, ...rest }: PropertyConfigurationProps) => {
  const propertyPanelRef = useRef(null);

  const result = useMemo(() => {
    let selectedComponent: FilteredComponent | undefined;
    let propertyTitle;
    if (configuredEntity) {
      if (
        configuredEntity.type === 'integration-action-input-parameter' ||
        configuredEntity.type === 'form-trigger-input-parameter'
      ) {
        const { inputParameter } = configuredEntity;
        propertyTitle = getPropertyTitle(inputParameter);
        selectedComponent = inputParameterProperties[inputParameter.uiComponent.type];
      } else {
        // run configuration
        selectedComponent = commandProperties;
      }

      const propertySections = getPropertySections(
        rest.type === 'form-trigger'
          ? {
              type: 'form-trigger',
              containerRef: propertyPanelRef,
              configuredEntity,
              idsToFilter: selectedComponent.propertyIds,
              onDataSourceIndexChanged: rest.onDataSourceIndexChanged,
              dataSourceIndex: rest.dataSourceIndex,
            }
          : {
              type: 'integration-action',
              containerRef: propertyPanelRef,
              configuredEntity,
              idsToFilter: selectedComponent.propertyIds,
              integrationVersion: rest.integrationVersion,
            }
      );
      return { propertySections, propertyTitle };
    }
    return;
  }, [configuredEntity, rest]);

  return {
    propertySections: result?.propertySections ?? [],
    showPropertyPanel: Boolean(configuredEntity),
    propertyTitle: result?.propertyTitle,
    propertyPanelRef,
  };
};
