import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomMultiSelectFieldIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.7071 10.2929C12.3166 9.90237 11.6834 9.90237 11.2929 10.2929C10.9024 10.6834 10.9024 11.3166 11.2929 11.7071L14.2929 14.7071C14.6834 15.0976 15.3166 15.0976 15.7071 14.7071L18.7071 11.7071C19.0976 11.3166 19.0976 10.6834 18.7071 10.2929C18.3166 9.90237 17.6834 9.90237 17.2929 10.2929L15 12.5858L12.7071 10.2929Z"
        fill="currentColor"
      />
      <path
        d="M9 10C9 10.8284 8.32843 11.5 7.5 11.5C6.67157 11.5 6 10.8284 6 10C6 9.17157 6.67157 8.5 7.5 8.5C8.32843 8.5 9 9.17157 9 10Z"
        fill="currentColor"
      />
      <path
        d="M7.5 15.5C8.32843 15.5 9 14.8284 9 14C9 13.1716 8.32843 12.5 7.5 12.5C6.67157 12.5 6 13.1716 6 14C6 14.8284 6.67157 15.5 7.5 15.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V7ZM4 7H20V17H4V7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
