import { CSSProperties } from '@novaera/theme-provider';
import classnames from 'classnames';
import React from 'react';
import { SplitPanelItemContainer } from './styled';
export const SplitPanelItem: React.FC<
  React.PropsWithChildren<{
    direction?: 'horizontal' | 'vertical';
    initialSize?: string;
    isCollapsed?: boolean;
    collapsedSize?: string;
    minSize?: string;
    dataPanelIndex?: number;
    isExpanded?: boolean;
    className?: string;
    id?: string;
  }>
> = ({
  children,
  direction,
  initialSize,
  isCollapsed,
  collapsedSize,
  minSize,
  dataPanelIndex,
  isExpanded,
  className,
  id,
}) => {
  const style: CSSProperties = {};

  if (direction === 'vertical') {
    style.minHeight = minSize || collapsedSize || '40px';
  } else {
    style.minWidth = minSize || collapsedSize || '40px';
  }

  return (
    <SplitPanelItemContainer
      style={style}
      initialStyle={!isCollapsed ? initialSize : undefined}
      collapsedSize={collapsedSize || minSize}
      minSize={minSize || collapsedSize || '40px'}
      direction={direction}
      isCollapsed={isCollapsed}
      {...(dataPanelIndex !== undefined && {
        'data-panel-index': dataPanelIndex,
      })}
      {...(id !== undefined && {
        id: id,
      })}
      className={classnames({
        'is-collapsed': isCollapsed,
        'is-full': !isCollapsed && !isExpanded && direction === 'vertical',
        'horizontal-expanded': direction === 'horizontal' && isExpanded,
        'vertical-expanded': direction === 'vertical' && isExpanded,
        [`${className}`]: Boolean(className),
      })}
    >
      {children}
    </SplitPanelItemContainer>
  );
};
