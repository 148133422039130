import {
  FunctionType,
  HTTPRequestConfiguration,
  RunStep,
  RunStepType,
  USER_PERMISSION,
  useGetIntegration,
  useGetIntegrationAction,
} from '@novaera/actioner-service';
import {
  NvAddButtonWithLabel,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  isAllRequired,
  isRequired,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { FC, useMemo, useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { DragDropList } from '../../../../../components/drag-drop-list';
import { UserPermissionBoundary } from '../../../../../user-permission-boundary';
import { useUserPermissions } from '../../../../../user-permission-boundary/use-user-permission';
import { EmptyState } from '../../../empty-state';
import { CommandRequestAutocomplete } from './command-request-autocomplete';
import { ADD_NEW_EXECUTION_EVENT_TEXT, EXECUTE_EVENTS_DESCRIPTION } from './constants';
import { CommandRequestMenuItem } from './styled';

export const CommandRequestList: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const [isNewlyAdded, setIsNewlyAdded] = useState<boolean>(false);
  const { integrationId, actionId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ integrationId, actionId, version: integration?.latestVersion.number });

  const options = useMemo(() => {
    const requests = data?.httpRequestConfigurations ?? [];
    const functions = data?.functions ?? [];
    const requestOptions = requests.map<Pick<HTTPRequestConfiguration, 'id' | 'name' | 'type'>>((req) => ({
      id: req.id,
      name: req.name,
      type: req.type,
    }));
    const functionOptions = functions.map<Pick<FunctionType, 'id' | 'name'> & { type: 'function' }>((req) => ({
      id: req.id,
      name: req.name,
      type: 'function',
    }));
    return [...requestOptions, ...functionOptions];
  }, [data?.functions, data?.httpRequestConfigurations]);

  const commandRequestAutocompleteFormatter = (value: RunStep) =>
    value ? options.find((o) => o.id === value.id) : null;

  const commandRequestAutocompleteParser = (value: { id: string; name: string; type: string }) =>
    value
      ? {
          id: value.id,
          type: value.type === 'function' ? RunStepType.FUNCTION : RunStepType.REQUEST,
        }
      : undefined;

  const handleNewRequestAdded = (fields: FieldArrayRenderProps<RunStep | undefined, HTMLElement>['fields']) => {
    setIsNewlyAdded(true);
    const value =
      options[0] && options[0].id
        ? commandRequestAutocompleteParser(options[0] as { id: string; name: string; type: string })
        : undefined;

    fields.push(value);
  };

  const handleRequestRemoved =
    (fields: FieldArrayRenderProps<RunStep | undefined, HTMLElement>['fields']) => (index: number) => {
      fields.remove(index);
    };

  const handleDragEnd =
    (fields: FieldArrayRenderProps<RunStep | undefined, HTMLElement>['fields']) =>
    (sourceIndex: number, destinationIndex: number) => {
      fields.move(sourceIndex, destinationIndex);
    };

  const getExecutionStepVariant = (requestType: 'function' | HTTPRequestConfiguration['type']) => {
    return {
      displayName: requestType === 'function' ? 'Function' : 'API',
    };
  };

  return (
    <NvFlex gap="16px">
      <NvTypography variant="body2">{EXECUTE_EVENTS_DESCRIPTION}</NvTypography>
      <FieldArray name={`runSteps`} validate={isAllRequired()}>
        {({ fields }) => {
          const isEmptyState = !fields.length || (fields.length && fields.length === 0);
          return (
            <NvFlex gap="8px">
              {!isEmptyState && (
                <DragDropList
                  disabled={!hasIntegrationUpdateRight}
                  droppableId="command-requests-droppable-area"
                  listItems={fields}
                  onDragEnd={handleDragEnd(fields)}
                  onItemRemoved={hasIntegrationUpdateRight ? handleRequestRemoved(fields) : undefined}
                  getDraggableId={(field, index) => (field ? `${field.id}-${index}` : `new-command-${index}`)}
                  getItemValue={(index: number) => fields.value[index]}
                  itemNode={(field, fieldName) => {
                    assert(typeof fieldName === 'string', new Error('When NvField used, fieldName should be string.'));
                    return (
                      <NvField
                        allowNull
                        name={fieldName}
                        format={commandRequestAutocompleteFormatter}
                        parse={commandRequestAutocompleteParser}
                        isAutoComplete
                        isRequired
                        validators={[isRequired('You need to select a request')]}
                        showErrorMessageOnlyWhenBlur
                        isDisabled={!hasIntegrationUpdateRight}
                        component={
                          <CommandRequestAutocomplete
                            disableClearable
                            options={options}
                            getOptionLabel={(option) => option.name ?? ''}
                            isOptionEqualToValue={(option, value) => {
                              return value && option.id === value.id;
                            }}
                            onSelectCapture={() => {
                              setIsNewlyAdded(false);
                            }}
                            renderInput={(params) => {
                              let requestVariant;
                              if (field) {
                                const req = options.find((option) => option.id === field.id);
                                requestVariant = req ? getExecutionStepVariant(req.type) : undefined;
                              }
                              return (
                                <NvTextField
                                  {...params}
                                  placeholder={'Select step'}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                  }}
                                  size="medium"
                                  autoComplete="off"
                                  autoFocus={isNewlyAdded}
                                  {...(requestVariant
                                    ? {
                                        startIcon: (
                                          <NvTypography variant="c2" noWrap color={theme.palette.nv_accent[60]}>
                                            {requestVariant.displayName}
                                          </NvTypography>
                                        ),
                                      }
                                    : {})}
                                />
                              );
                            }}
                            renderOption={(props, option) => {
                              const requestVariant = getExecutionStepVariant(option.type);
                              return (
                                <CommandRequestMenuItem {...props} key={option.id}>
                                  <NvFlex direction="row" alignItems="center" gap="8px">
                                    {requestVariant && (
                                      <NvTypography variant="c2" noWrap color={theme.palette.nv_accent[60]}>
                                        {requestVariant.displayName}
                                      </NvTypography>
                                    )}

                                    <NvTypography variant="body1" noWrap>
                                      {option.name}
                                    </NvTypography>
                                  </NvFlex>
                                </CommandRequestMenuItem>
                              );
                            }}
                            noOptionsText={
                              options.length === 0
                                ? "This action doesn't have any requests or functions yet."
                                : 'No Options'
                            }
                            popupIcon={<NvExpandMoreIcon />}
                          />
                        }
                      />
                    );
                  }}
                />
              )}
              <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
                {isEmptyState ? (
                  <EmptyState onClick={() => handleNewRequestAdded(fields)} actionText={ADD_NEW_EXECUTION_EVENT_TEXT} />
                ) : (
                  <NvAddButtonWithLabel
                    variant="small"
                    label={ADD_NEW_EXECUTION_EVENT_TEXT}
                    onClick={() => handleNewRequestAdded(fields)}
                  />
                )}
              </UserPermissionBoundary>
            </NvFlex>
          );
        }}
      </FieldArray>
    </NvFlex>
  );
};
