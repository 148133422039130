import { NvAxios, useQuery } from '@novaera/core';
import { ORGANIZATION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ORGANIZATIONS } from '../keys';

type GetOrganizationParams = {
  organizationId?: string;
};

type GetOrganizationResponse = {
  id: string;
  name: string;
};

const getOrganization = async ({ organizationId }: GetOrganizationParams) => {
  const result = await NvAxios.get<GetOrganizationResponse>(`${ORGANIZATION_ROOT_PATH}/${organizationId}`);
  return result?.data;
};

export const useGetOrganization = (params: GetOrganizationParams) =>
  useQuery<GetOrganizationResponse>(
    QUERY_KEYS_ORGANIZATIONS.organization(params.organizationId),
    () => getOrganization(params),
    {
      enabled: !!params.organizationId,
    }
  );
