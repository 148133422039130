import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const HeaderWrapper = styled(NvBox)`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 12px;
  gap: 8px;
  height: 48px;
  width: 100%;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  flex: 0 0 auto;
  z-index: 10;
  ${({ theme }) => theme.elevations.e100}
`;
