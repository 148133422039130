import { MultiValueRuleType } from '@novaera/actioner-service';
import { FieldArray, NvAddBoxIcon, NvButton, NvCloseIcon, NvField, NvFlex, NvTextField, useForm } from '@novaera/core';
import { useEffect, useMemo } from 'react';
import { ArrayFieldGroup } from './styled';

export const ArrayField: React.FC<React.PropsWithChildren<{ name: string }>> = ({ name }) => {
  const defaultValueForArray = useMemo(() => [''], []);
  const form = useForm();

  useEffect(() => {
    form.change(`${name}.type`, MultiValueRuleType.LIST);
    form.change(`${name}.value`, undefined);
  }, [form, name]);

  return (
    <FieldArray name={`${name}.values`} defaultValue={defaultValueForArray}>
      {({ fields }) => {
        return (
          <ArrayFieldGroup>
            {fields.map((name, index) => {
              return (
                <NvFlex direction={'row'} columnGap="4px" key={`workflow_condition_field_${name}`}>
                  <NvFlex flex="1 1 auto">
                    <NvField name={name} component={<NvTextField />} />
                  </NvFlex>
                  {fields.value?.length > 1 && (
                    <NvFlex marginTop="4px">
                      <NvButton
                        onlyIcon
                        color="ghost"
                        size="small"
                        onClick={() => {
                          fields.remove(index);
                        }}
                      >
                        <NvCloseIcon />
                      </NvButton>
                    </NvFlex>
                  )}
                </NvFlex>
              );
            })}

            <NvFlex marginTop="4px" alignItems="flex-start">
              <NvButton
                size="small"
                color="secondary"
                startIcon={<NvAddBoxIcon />}
                onClick={() => {
                  fields.push('');
                }}
              >
                Add more
              </NvButton>
            </NvFlex>
          </ArrayFieldGroup>
        );
      }}
    </FieldArray>
  );
};
