import { useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { RootConditionProperties } from '../common/root-condition-properties';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { IntegrationTriggerProperties } from './integration-trigger-properties';
import { IntegrationTriggerPropertyPanelDrawerProps } from './types';

export const IntegrationTriggerPropertyPanelDrawer: React.FC<
  React.PropsWithChildren<IntegrationTriggerPropertyPanelDrawerProps>
> = ({ handleClose, workflow, savedWorkflow }) => {
  const { isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();

  const [secondPanelOpen, setSecondPanelOpen] = useState(false);
  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  const {
    panelProps: [first, second],
    panelCloseFunctions: [onFirstClose, onSecondClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: secondPanelOpen,
        closeFn: () => {
          setSecondPanelOpen(false);
        },
      },
    ],
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        <IntegrationTriggerProperties
          onCloseClicked={onFirstClose}
          onRootConditionClicked={() => {
            setSecondPanelOpen(true);
          }}
          workflow={workflow}
          savedWorkflow={savedWorkflow}
        />
      </PPDrawerItem>
      <PPDrawerItem {...second}>
        <RootConditionProperties workflow={workflow} onCloseClicked={onSecondClose} />
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
