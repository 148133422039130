import { styled } from '@novaera/theme-provider';
import { NvBox } from '../../box';

export const NodeListGroupItemCard = styled(NvBox)`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  gap: 4px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;
