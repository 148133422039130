import { useGetUserApp, useGetWorkflow, useGetWorkflowFromLink } from '@novaera/actioner-service';
import { useNavigate, useParams } from '@novaera/route';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { ROUTES } from '../../../common/routes';

export const useWorkflowRunFromLink = () => {
  const { formContextId } = useParams<{ formContextId: string }>();

  const { data: workflowFromLinkResult, isError, error } = useGetWorkflowFromLink({ formContextId });

  const { workflow, isLoading: isWorkflowLoading } = useGetWorkflow({
    appId: workflowFromLinkResult?.appId,
    workflowId: workflowFromLinkResult?.workflowId,
  });

  const { data: userApp, isLoading: isUserAppLoading } = useGetUserApp(workflow?.appId);

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      if (isAxiosError(error) && error?.response?.status === 404) {
        navigate(ROUTES.UserApps);
      }
    }
  }, [error, isError, navigate]);

  return {
    workflow,
    workflowFromLink: workflowFromLinkResult,
    userApp,
    isUserAppLoading,
    isWorkflowLoading,
  };
};
