import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const MyConnectionConnectionInfoItem = styled(NvFlex)`
  width: 100%;
  padding: 12px;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
`;
