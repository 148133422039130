import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ConnectionSchema } from '..';
import { INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_SCHEMA } from '../keys';
import { DeleteIntegrationConnectionParams } from './types';

const deleteConnection = async (params: DeleteIntegrationConnectionParams) => {
  const result = await NvAxios.delete<ConnectionSchema>(
    `${INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH(params.integrationId)}/${params.connection.id}`
  );
  return result?.data;
};

export const useDeleteConnectionSchemaService = () => {
  const cache = useQueryClient();

  return useMutation(deleteConnection, {
    onSuccess(data, variables) {
      cache.setQueryData(
        QUERY_KEYS_CONNECTION_SCHEMA.connection_schema(variables.connection.id, variables.integrationId),
        null
      );
    },
  });
};
