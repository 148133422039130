import { NvButton, NvConditionalRender, NvDeleteOutlineIcon } from '@novaera/core';

import { PropertyPanelSection } from '../../../../../../../components';
import { SectionRuleWrapperComponent } from '../../../../../../../components/match-event-section/section-rules/types';
import { useWorkflowPermission } from '../../../../../../user-app-permission-boundary/use-workflow-permission';

export const Section: React.FC<React.PropsWithChildren<SectionRuleWrapperComponent>> = ({
  onRemoveClicked,
  children,
  index,
}) => {
  const { hasEditPermission } = useWorkflowPermission();
  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelSection
          title={`Rule ${index}`}
          collapsible
          actions={
            <NvButton
              onlyIcon
              size="small"
              color="ghost"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClicked?.();
              }}
            >
              <NvDeleteOutlineIcon fontSize="small" />
            </NvButton>
          }
        >
          {children}
        </PropertyPanelSection>
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelSection title={`Rule ${index}`} collapsible>
          {children}
        </PropertyPanelSection>
      </NvConditionalRender>
    </>
  );
};
