import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { PropertyPanelListHeader } from '../../components';

export const UserAppDetailWrapper = styled(NvFlex)`
  flex-direction: row;
  flex: 1 1 auto;
  min-width: 0;
  height: 100%;
`;

export const UserAppDetailBodyWrapper = styled(NvFlex)`
  height: 100vh;
  flex: 1 1 auto;
  min-width: 0;
  padding: 40px 40px 60px 40px;
  align-items: center;
  overflow: auto;
`;

export const WorkflowHistoryListHeader = styled(PropertyPanelListHeader)`
  top: 0;
`;
