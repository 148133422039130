import {
  ConnectionValidationSchema,
  useGetConnectionSchemaService,
  useSearchConnectionsService,
} from '@novaera/actioner-service';
import { useState } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useMissingConnectionItemController = ({ schema }: { schema: ConnectionValidationSchema }) => {
  const [isConnectPanelOpened, setIsConnectPanelOpened] = useState(false);

  const { userAppId } = useGetWorkflowQueryParams();

  const { data: connectionSchema, isLoading } = useGetConnectionSchemaService({
    schemaId: schema.connectionSchemaId,
    integrationId: schema.integrationId,
  });

  const { data: connectionResult } = useSearchConnectionsService({
    payload: {
      appId: userAppId,
      schemaId: schema.connectionSchemaId,
    },
    enabled: schema.status === 'multiple',
  });

  const connectionOptions = connectionResult?.connections.map((connection) => ({
    label: connection.name,
    value: connection,
  }));

  const handleConnectButtonClicked = () => {
    setIsConnectPanelOpened(true);
  };

  const handleConnectCancelButtonClicked = () => {
    setIsConnectPanelOpened(false);
  };

  return {
    isLoading,
    connectionSchema,
    connectionOptions,
    onConnectButtonClicked: handleConnectButtonClicked,
    onConnectCancelClicked: handleConnectCancelButtonClicked,
    isConnectPanelOpened,
  };
};
