import { ConnectionSchema } from '@novaera/actioner-service';
import { useFormState } from 'react-final-form';

export const useAuthenticationFields = () => {
  const { values } = useFormState<ConnectionSchema>();

  return {
    hasAuthenticationFields: values?.authentication?.authenticationFields?.length > 0 || false,
  };
};
