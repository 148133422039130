import { InputParameter, useGetWorkflowInputParamDependencies } from '@novaera/actioner-service';
import { useCheckDependencyValue } from '../use-check-dependency-value';

export const useCheckWorkflowDynamicInputDependencyValue = ({
  appId,
  workflowId,
  isDraft,
  inputParameters = [],
}: {
  appId: string;
  workflowId: string;
  isDraft: boolean;
  inputParameters: InputParameter[];
}) => {
  const { getDependencyInputComponentState, filterSuccessDynamicInputs, dependenciesOfParameters } =
    useCheckDependencyValue({
      result: useGetWorkflowInputParamDependencies({
        appId,
        isDraft,
        workflowId,
      }),
      inputParameters,
    });

  return {
    getDependencyInputComponentState,
    filterSuccessDynamicInputs,
    dependenciesOfParameters,
  };
};
