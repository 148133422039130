import { DateTimePickerDefaultTextField, NvTimePicker, useTimePicker } from '@novaera/core';
import { ComponentHint } from '../common/adapter/component-hint';
import { TimePickerComponentParams } from '../use-ui-component/types';

export const TimePickerComponent = ({
  inputParameter: { uiComponent },
  inputProps,
  otherProps,
}: TimePickerComponentParams) => {
  const { hint } = uiComponent;
  const { error } = inputProps;
  const { isOpen, changeTimePickerOpen } = useTimePicker();

  return (
    <>
      <NvTimePicker
        {...inputProps}
        error={!error}
        open={isOpen}
        onClose={() => {
          changeTimePickerOpen(false);
        }}
        renderInput={(params) => (
          <DateTimePickerDefaultTextField
            onClick={() => changeTimePickerOpen(!isOpen)}
            {...params}
            size="large"
            error={!!error}
            {...otherProps}
          />
        )}
      />
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
