import { NvAxios, useQuery } from '@novaera/core';
import {
  ActionNode,
  ActionerEventNode,
  AssistantCancelRunNode,
  AssistantNode,
  DelayNode,
  FileNode,
  FunctionNode,
  GetConversationNode,
  HTTPNode,
  JobNode,
  LinkGeneratorNode,
  LoopNode,
  NodeUnion,
  NodeUnionType,
  NodeUnionTypeEnumLike,
  OutputNode,
  QANode,
  ResponseNode,
  SendEmailNode,
  WorkFlowDispatcherNode,
  WorkflowConditionNode,
  WorkflowResolverNode,
} from '../../../types';
import { USER_APP_ROOT_PATH } from '../../../user-app/constants';
import { QUERY_KEYS_NODE } from '../keys';
import { GetNodeParams, GetNodeResponse } from './types';
import { useNode } from './use-node';

export const getNode = async ({ workflowId, appId, nodeAlias }: GetNodeParams) => {
  const result = await NvAxios.get<GetNodeResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/nodes/${nodeAlias}`
  );
  return result?.data;
};

export const useGetNode = <
  T extends NodeUnionType = 'blank',
  R = T extends typeof NodeUnionTypeEnumLike.action
    ? {
        node: ActionNode | undefined;
        savedNode: ActionNode | undefined;
        draftNode: ActionNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.sendEmail
    ? {
        node: SendEmailNode | undefined;
        savedNode: SendEmailNode | undefined;
        draftNode: SendEmailNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.actionerEvent
    ? {
        node: ActionerEventNode | undefined;
        savedNode: ActionerEventNode | undefined;
        draftNode: ActionerEventNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.workflowDispatcher
    ? {
        node: WorkFlowDispatcherNode | undefined;
        savedNode: WorkFlowDispatcherNode | undefined;
        draftNode: WorkFlowDispatcherNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.workflowResolver
    ? {
        node: WorkflowResolverNode | undefined;
        savedNode: WorkflowResolverNode | undefined;
        draftNode: WorkflowResolverNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.delay
    ? {
        node: DelayNode | undefined;
        savedNode: DelayNode | undefined;
        draftNode: DelayNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.function
    ? {
        node: FunctionNode | undefined;
        savedNode: FunctionNode | undefined;
        draftNode: FunctionNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.loop
    ? {
        node: LoopNode | undefined;
        savedNode: LoopNode | undefined;
        draftNode: LoopNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.output
    ? {
        node: OutputNode | undefined;
        savedNode: OutputNode | undefined;
        draftNode: OutputNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.job
    ? {
        node: JobNode | undefined;
        savedNode: JobNode | undefined;
        draftNode: JobNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.response
    ? {
        node: ResponseNode | undefined;
        savedNode: ResponseNode | undefined;
        draftNode: ResponseNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.workflowCondition
    ? {
        node: WorkflowConditionNode | undefined;
        savedNode: WorkflowConditionNode | undefined;
        draftNode: WorkflowConditionNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.file
    ? {
        node: FileNode | undefined;
        savedNode: FileNode | undefined;
        draftNode: FileNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.aiKnowledge
    ? {
        node: QANode | undefined;
        savedNode: QANode | undefined;
        draftNode: QANode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.assistant
    ? {
        node: AssistantNode | undefined;
        savedNode: AssistantNode | undefined;
        draftNode: AssistantNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.getConversation
    ? {
        node: GetConversationNode | undefined;
        savedNode: GetConversationNode | undefined;
        draftNode: GetConversationNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.http
    ? {
        node: HTTPNode | undefined;
        savedNode: HTTPNode | undefined;
        draftNode: HTTPNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.linkGenerator
    ? {
        node: LinkGeneratorNode | undefined;
        savedNode: LinkGeneratorNode | undefined;
        draftNode: LinkGeneratorNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : T extends typeof NodeUnionTypeEnumLike.assistant_cancel_run
    ? {
        node: AssistantCancelRunNode | undefined;
        savedNode: AssistantCancelRunNode | undefined;
        draftNode: AssistantCancelRunNode | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
    : {
        node: NodeUnion | undefined;
        savedNode: NodeUnion | undefined;
        draftNode: NodeUnion | undefined;
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        isFetched: boolean;
      }
>({
  workflowId,
  appId,
  nodeAlias,
}: GetNodeParams): R => {
  const {
    data: userAppResponse,
    isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  } = useQuery(
    QUERY_KEYS_NODE.detail({ appId, workflowId, nodeAlias }),
    () => getNode({ appId, workflowId, nodeAlias }),
    {
      enabled: !!appId && !!workflowId && !!nodeAlias,
      keepPreviousData: true,
    }
  );

  const { node, savedNode, draftNode } = useNode<T>(userAppResponse);

  return {
    node,
    savedNode,
    draftNode,
    isLoading: isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  } as unknown as R;
};
