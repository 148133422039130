import { DagreNovaeraGraph } from '@novaera/core';
import { generateUniqueId } from '@novaera/utils';
import { AppWorkflowNodeType, AppWorkflowTriggerNodeType } from '../../components/user-app-workflow-node-types/types';
const triggerId = generateUniqueId();

export const userAppGraph = new DagreNovaeraGraph<AppWorkflowNodeType | AppWorkflowTriggerNodeType>(
  {
    id: triggerId,
    name: 'Choose trigger',
    width: 40,
    height: 40,
    type: 'StartNode',
    alias: 'alias 1',
  },
  window.innerWidth / 4 - 70,
  {
    action: 'Action',
    AddButton: 'add button',
    blank: 'Blank',
    branchJunction: 'Branch',
    integrationWebhook: 'Integration',
    loop: 'Loop',
    StartNode: 'Start Node',
    workflowCondition: 'Condition',
    genericWebhook: 'Webhook',
    form: 'Manual',
    email: 'Email',
    actionerEvent: 'Actioner Event',
    actionerEventPublisher: 'Actioner Event',
    integrationApp: 'Integration App',
    function: 'Function',
    response: 'Response',
    output: 'Output',
    delay: 'Delay',
    sendEmail: 'Send Email',
    workflowDispatcher: 'Workflow',
    workflowResolver: 'Workflow Resolver',
    DummyNode: 'DummyNode',
    aiKnowledge: 'Q&A',
    assistant: 'Assistant',
    getConversation: 'Get Conversation',
    job: 'Job',
    file: 'File',
    linkGenerator: 'Link Generator',
    http: 'HTTP',
    assistant_cancel_run: 'Assistant Cancel Run',
  }
);
