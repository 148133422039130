import { NvFlex, NvSkeleton } from '@novaera/core';

import React from 'react';
import { AppDirectoryCardContainer, AppDirectoryCardDescription, AppDirectoryCardHeader } from '../styled';

export const AppDirectoryCardLoadingSkeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <NvFlex>
      <AppDirectoryCardContainer className="not-clickable">
        <AppDirectoryCardHeader>
          <NvFlex flex="1 1 auto">
            <NvSkeleton height="40px" width="40px" variant="rectangular" />
          </NvFlex>
          <NvFlex flex="0 0 auto">
            <NvSkeleton height="20px" width="80px" />
          </NvFlex>
        </AppDirectoryCardHeader>
        <AppDirectoryCardDescription columnGap="8px" rowGap="8px" display="flex" flexDirection="column">
          <NvFlex>
            <NvSkeleton height="32px" width="50%" />
          </NvFlex>
          <NvFlex flexDirection="column">
            <NvSkeleton height="24px" width="80%" />

            <NvFlex columnGap="14px" direction="row">
              <NvSkeleton height="24px" width="60%" />
            </NvFlex>
            <NvFlex flexDirection="row" alignItems="center" columnGap="8px" marginTop="8px" paddingBottom="16px">
              <NvSkeleton variant="circular" height="24px" width="24px" />
              <NvSkeleton height="20px" width="80px" />
            </NvFlex>
          </NvFlex>
        </AppDirectoryCardDescription>
      </AppDirectoryCardContainer>
    </NvFlex>
  );
};
