import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { NvButton, NvCloseIcon, NvCustomExecuteButtonIcon, NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper, PropertyPanelHeader } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { ACTION_NODE_PROPERTIES_WRAPPER_ID } from '../action-node-property-panel-drawer/constants';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { JobPropertyPanelForm } from './form-wrapper';
import { JobPropertyPanel } from './panel';
import { JobNodeTarget } from './panel/common-fields/job-node-target';
import { JobPropertyPanelDrawerProps } from './types';

export const JobPropertyPanelDrawer: React.FC<JobPropertyPanelDrawerProps> = ({ handleClose }) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(!!selectedNode, new Error('[JobPropertyPanelDrawerProps] - selectedNode should not be undefined!'), 'ERROR');

  const { userAppId: appId, workflowId } = useParams();
  const { node: jobNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.job>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });
  const [secondPanelOpen, setSecondPanelOpen] = useState(false);

  const {
    panelProps: [first, second],
    panelCloseFunctions: [, onSecondClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: isFirstPanelOpen && secondPanelOpen,
        closeFn: () => {
          setSecondPanelOpen(false);
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps} id={ACTION_NODE_PROPERTIES_WRAPPER_ID}>
      {isLoading ? (
        <NvFlex width={`430px`}>
          <PropertiesLoading />
        </NvFlex>
      ) : (
        jobNode && (
          <JobPropertyPanelForm jobNode={jobNode}>
            <NvFlex direction="row" height={'100%'}>
              <PPDrawerItem {...first}>
                <JobPropertyPanel
                  onCloseClicked={handleClose}
                  jobNode={jobNode}
                  isJobTargetPanelOpen={secondPanelOpen}
                  onJobTargetClicked={() => {
                    setSecondPanelOpen(true);
                  }}
                />
              </PPDrawerItem>
              <PPDrawerItem {...second}>
                <NvFlex width="100%">
                  <PropertyPanelHeader
                    icon={<NvCustomExecuteButtonIcon />}
                    title="Job target"
                    actions={
                      <NvButton
                        onlyIcon
                        size="small"
                        color="secondary"
                        onClick={() => {
                          onSecondClose();
                        }}
                      >
                        <NvCloseIcon />
                      </NvButton>
                    }
                  />
                  <JobNodeTarget />
                </NvFlex>
              </PPDrawerItem>
            </NvFlex>
          </JobPropertyPanelForm>
        )
      )}
    </PropertyPaneWrapper>
  );
};
