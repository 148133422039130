import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecentQueryResultWrapper = styled(NvFlex)`
  padding: 24px 32px 90px 32px;
  align-items: flex-start;
  gap: 40px;
`;

export const RecentQueryResultHeader = styled(NvFlex)`
  align-items: flex-start;
  gap: 20px;
`;
