import { FieldArray } from 'react-final-form-arrays';
import { FieldItem } from '../field-item';
import { AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME } from './constants';

export const FieldItemTableBody = () => {
  return (
    <FieldArray name={AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME}>
      {({ fields }) =>
        fields.map((name, index) => {
          return (
            <FieldItem
              rowIndex={index}
              authenticationField={fields.value[index]}
              key={`authenticationFields-${name}`}
            />
          );
        })
      }
    </FieldArray>
  );
};
