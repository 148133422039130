import { AuthenticationField } from '@novaera/actioner-service';
import { useIntegrationConnectionAuthenticationFieldContext } from '../../../provider';
import { Action } from '../../../provider/reducer/types';
import { useForm } from 'react-final-form';
import { DETAIL_FORM_NAME } from '../../../../../../../../../../components/detail-layout';
import { useCallback } from 'react';
import { AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME } from '../../../authentication-field-items/table-body/constants';

export const useModalState = () => {
  const { dispatch, state } = useIntegrationConnectionAuthenticationFieldContext();
  const { mutators } = useForm(DETAIL_FORM_NAME);

  const handleModalClosed = () => {
    dispatch({
      type: Action.CloseAddFieldModal,
    });
  };

  const updateFormAuthenticationField = useCallback(
    (values: AuthenticationField) => {
      mutators.update(AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME, state.editAuthenticationField?.rowIndex, values);
    },
    [mutators, state.editAuthenticationField?.rowIndex]
  );

  const addFormAuthenticationField = useCallback(
    (values: AuthenticationField) => {
      mutators.push(AUTHENTICATION_FIELDS_FIELD_ARRAY_NAME, values);
    },
    [mutators]
  );

  const handleFormSubmit = useCallback(
    (values: AuthenticationField) => {
      if (state.editAuthenticationField?.rowIndex !== undefined) {
        updateFormAuthenticationField(values);
      } else {
        addFormAuthenticationField(values);
      }
      dispatch({
        type: Action.CloseAddFieldModal,
      });
    },
    [addFormAuthenticationField, dispatch, state.editAuthenticationField?.rowIndex, updateFormAuthenticationField]
  );

  return {
    isOpened: state.isModalOpened || false,
    onClosed: handleModalClosed,
    onFormSubmit: handleFormSubmit,
    initialValues: state.editAuthenticationField?.authenticationField,
  };
};
