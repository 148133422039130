import { styled } from '@novaera/theme-provider';

export const InformationBoxWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'isExpandedMode',
})<{
  width: number;
  isExpandedMode?: boolean;
}>`
  position: absolute;
  z-index: 99;
  ${({ theme }) => theme.elevations.e300};
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 6px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow: hidden;
  width: ${({ width }) => `${width}px`};

  &[hidden] {
    visibility: hidden;
    pointer-events: none;
    height: 0;
  }

  ${({ isExpandedMode, theme }) =>
    isExpandedMode
      ? `
    position: static!important;
    transform: none!important;
    flex: 0 0 auto;
    border-radius: 0px;
    box-shadow: none;
    border-top: 1px solid ${theme.palette.nv_neutral[20]};
    min-height: 100px;
    max-height: 200px;
    `
      : null}
`;

export const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 2px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};

  &.error {
    color: ${({ theme }) => theme.palette.nv_error[40]};
  }
  &.result {
    color: ${({ theme }) => theme.palette.nv_success[60]};
  }
`;

export const TitleText = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-right: auto;
`;

export const InformationBoxBody = styled('div')`
  font-family: Fira Code, monospace;
  padding: 8px 6px;
  overflow-y: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
  word-break: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`;
