import { assert } from '@novaera/utils';
import { createContext, useContext, useReducer } from 'react';

import { testConfigurationReducer } from './reducer';
import { TestConfigurationProviderProps, TestConfigurationProviderType } from './types';

const TestConfigurationContext = createContext<TestConfigurationProviderType>(undefined);

const TestConfigurationProvider = ({ children }: TestConfigurationProviderProps) => {
  const [state, dispatch] = useReducer(testConfigurationReducer, {
    selectedConnection: undefined,
  });

  const value = { state, dispatch };
  return (
    <TestConfigurationContext.Provider value={value}>
      {typeof children === 'function' ? children({ state, dispatch }) : children}
    </TestConfigurationContext.Provider>
  );
};

const useTestConfigurationProvider = () => {
  const context = useContext(TestConfigurationContext);
  assert(!!context, new Error(`useTestConfigurationProvider should be used within TestConfigurationProvider`), 'ERROR');

  return context;
};

export { TestConfigurationProvider, useTestConfigurationProvider };
