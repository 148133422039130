import { HttpMethods, HTTPRequestConfiguration, IntegrationAction, MediaTypes } from '@novaera/actioner-service';
import { useFormState } from '@novaera/core';
import { generateUniqueId } from '@novaera/utils';
import { useMemo } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useRequestContext } from '../../../../../../providers/request-provider';
import { InitModalValue, RequestModalParams } from '../../type';

export const useRequestModal = ({ onClose, ...rest }: RequestModalParams) => {
  const {
    values: { httpRequestConfigurations },
  } = useFormState<IntegrationAction>();
  const {
    fields: { push: addNewHttpRequestConfiguration, ...fieldsArray },
  } = useFieldArray('httpRequestConfigurations');

  const { setSelectedRequestId } = useRequestContext();
  const requestListForValidation = useMemo(() => {
    if (rest.mode === 'update') {
      return httpRequestConfigurations?.filter((r) => rest.requestId !== r.id);
    } else {
      return httpRequestConfigurations;
    }
  }, [httpRequestConfigurations, rest]);

  const formInitialValues = useMemo(() => {
    if (rest.mode === 'update') {
      const request = fieldsArray.value.find((r) => r.id === rest.requestId);
      return { name: request?.name };
    }
    return { name: '' };
  }, [fieldsArray.value, rest]);

  const handleClose = () => {
    onClose();
  };

  const handleOnSubmit = ({ name }: InitModalValue) => {
    return new Promise<void>(() => {
      if (rest.mode === 'update' && fieldsArray) {
        const index = fieldsArray.value.findIndex((r) => r.id === rest.requestId);
        if (index > -1) {
          const request = fieldsArray.value[index];
          fieldsArray?.update(index, {
            ...request,
            ...{ name: name || '' },
          });
        }
      } else {
        const id = generateUniqueId();

        const httpRequest: HTTPRequestConfiguration = {
          id,
          httpMethod: HttpMethods.GET,
          mediaType: MediaTypes.APPLICATION_JSON,
          name: name ?? '',
          url: '/test',
          retryConfiguration: {
            enabled: true,
            delayInSeconds: 5,
            maxRetryCount: 5,
            statusCodes: ['429', '503'],
          },
          type: 'http-request',

          conditionConfiguration: {
            enabled: false,
            condition: {
              type: 'match-all-condition',
              rules: [],
            },
          },
          useConnectionSchema: true,
        };
        addNewHttpRequestConfiguration(httpRequest);
        setSelectedRequestId(id);
      }
      handleClose();
    });
  };

  return {
    requestListForValidation,
    formInitialValues,
    onSubmit: handleOnSubmit,
    handleClose,
  };
};
