import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const EditorWrapper = styled(NvBox)`
  flex: 1 1 0;
  min-height: 0;
  height: 100%;
  margin-top: 2px;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
`;

export const EditorTextArea = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'containerWidth',
})<{
  containerWidth: string;
}>`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  padding: 24px 0 60px 0;
  padding-right: calc((100vw - 1024px) / 2);
  padding-left: calc((100vw - 1024px) / 2);
  font-size: 16px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
`;

export const DocWrapper = styled(NvFlex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
