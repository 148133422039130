import {
  AppDocumentCategory,
  useFetchProfile,
  useGetAppSchemaPreview,
  useGetUserApp,
  usePublishAppSchema,
  useUpdateAppSchema,
} from '@novaera/actioner-service';
import { NvButton, NvFlex, NvForm, Step, StepTracker } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import arrayMutators from 'final-form-arrays';
import { FC, useCallback, useMemo, useState } from 'react';
import { getAppSchemaUrl } from '../../utils';
import { AppDetailsStep } from './app-details-step';
import { ChangeLog } from './change-log';
import { PreviewStep } from './preview-step';
import { useSelectedAppIdContext } from './providers/selected-app-id';
import { SelectAppStep } from './select-app-step';
import { SetupWorkflowsStep } from './setup-workflows-step';
import {
  OptionalPublishAppFormData,
  OptionalPublishNewVersionFormData,
  PublishAppFormData,
  PublishAppMode,
  PublishAppStepTracerProps,
  PublishNewVersionFormData,
} from './types';

export const PublishAppStepTracer: FC<React.PropsWithChildren<PublishAppStepTracerProps>> = ({ onCancel, mode }) => {
  const { storeSchemaId } = useParams();
  const { selectedAppId } = useSelectedAppIdContext();
  const { data: currentUserApp, isLoading: isUserAppLoading } = useGetUserApp(selectedAppId);
  const { data: userData } = useFetchProfile();
  const navigate = useNavigate();
  const { mutate: publishPackageSchema, isLoading } = usePublishAppSchema();
  const { mutate: updateAppSchema, isLoading: isUpdating } = useUpdateAppSchema();
  const { data: schemaPreview } = useGetAppSchemaPreview({
    schemaId: storeSchemaId,
    enabled: mode === PublishAppMode.Update,
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const nextButton = useCallback(() => {
    return (
      <NvButton color="primary" type="submit">
        Next
      </NvButton>
    );
  }, []);

  const initialValues = useMemo<OptionalPublishAppFormData | OptionalPublishNewVersionFormData>(() => {
    const core: OptionalPublishAppFormData | OptionalPublishNewVersionFormData = {
      appDetails: {
        appId: currentUserApp?.id,
        logoUrl: currentUserApp?.logoUrl,
        appName: schemaPreview?.packageName || currentUserApp?.name,
        description: schemaPreview?.description || currentUserApp?.description?.value,
        shortDescription: schemaPreview?.shortDescription ?? '',
        descriptionType: 'markdown',
        categories: schemaPreview?.categories ?? [],
        contributors: schemaPreview?.contributors
          ? schemaPreview?.contributors
          : userData
          ? [
              {
                id: userData.userId,
                logoUrl: userData.logoUrl,
                name: userData.name,
                type: 'user',
              },
            ]
          : [],
        mediaFiles: currentUserApp?.assets?.mediaFiles || [],
        documents: {
          useCases:
            currentUserApp?.assets?.documents?.filter((doc) => doc.category === AppDocumentCategory.USE_CASE) ?? [],
          support:
            currentUserApp?.assets?.documents?.filter((doc) => doc.category === AppDocumentCategory.SUPPORT) ?? [],
        },
        managed: schemaPreview?.managed ?? currentUserApp?.managed,
        appMenuItems: currentUserApp?.appMenuItems ?? [],
      },
    };

    return mode === PublishAppMode.Publish ? core : { ...core, changeLog: '', changeSize: '' };
  }, [
    currentUserApp?.assets?.documents,
    currentUserApp?.assets?.mediaFiles,
    currentUserApp?.description?.value,
    currentUserApp?.id,
    currentUserApp?.logoUrl,
    currentUserApp?.managed,
    currentUserApp?.appMenuItems,
    currentUserApp?.name,
    mode,
    schemaPreview?.categories,
    schemaPreview?.contributors,
    schemaPreview?.description,
    schemaPreview?.managed,
    schemaPreview?.packageName,
    schemaPreview?.shortDescription,
    userData,
  ]);

  const selectAppStep: Step = useMemo(
    () => ({
      showButtons: false,
      label: 'Select app',
      content: ({ handleNextClick }) => {
        return <SelectAppStep handleNextClick={handleNextClick} />;
      },
    }),
    []
  );

  const changeLogStep: Step = useMemo(
    () => ({
      showButtons: true,
      label: 'Change log',
      content: <ChangeLog currentVersion={schemaPreview?.latestVersion ?? '1.0.0'} />,
      nextButton: () => (
        <NvButton color="primary" type="submit" loading={isUpdating} disabled={isUpdating}>
          Update
        </NvButton>
      ),
    }),
    [isUpdating, schemaPreview?.latestVersion]
  );
  const previewStep: Step = useMemo(
    () => ({
      showButtons: true,
      label: 'Preview',
      content: <PreviewStep />,
      nextButton: () => (
        <NvButton color="primary" type="submit" loading={isLoading} disabled={isLoading}>
          Publish
        </NvButton>
      ),
    }),
    [isLoading]
  );

  const finalSteps: Step[] = useMemo(
    () => [
      ...(mode === PublishAppMode.Update ? [selectAppStep] : []),
      {
        showButtons: true,
        label: 'App details',
        content: <AppDetailsStep />,
        nextButton,
      },
      {
        showButtons: true,
        label: 'Setup workflows',
        content: <SetupWorkflowsStep mode={mode} />,
        nextButton,
      },
      mode === PublishAppMode.Publish ? previewStep : changeLogStep,
    ],
    [changeLogStep, mode, nextButton, previewStep, selectAppStep]
  );

  const handleSubmit = (values: OptionalPublishAppFormData | OptionalPublishNewVersionFormData) => {
    return new Promise<void>((resolve) => {
      if (activeIndex === finalSteps.length - 1) {
        if (mode === PublishAppMode.Publish) {
          const publishValues = values as PublishAppFormData;
          const { documents, logoUrl, setupWorkflows, ...appDetails } = publishValues.appDetails;

          const payload = {
            ...publishValues,
            appDetails: {
              ...appDetails,
              documents: documents ? [...(documents.support ?? []), ...(documents.useCases ?? [])] : [],
              setupWorkflows: setupWorkflows
                ? setupWorkflows.map(({ id, autoRun }) => ({ id, autoRun: !!autoRun }))
                : [],
            },
          };
          assert(!('changeLog' in payload), new Error('Publish schema should not have changelog'), 'WARNING');
          publishPackageSchema(payload, {
            onSuccess: ({ schemaId }) => {
              const url = getAppSchemaUrl({ name: values.appDetails.appName, schemaId });
              navigate(url);
              onCancel();
            },
            onSettled: () => {
              resolve();
            },
          });
        } else {
          const updateValues = values as PublishNewVersionFormData;
          const { documents, logoUrl, setupWorkflows, ...appDetails } = updateValues.appDetails;
          const payload = {
            ...updateValues,
            appDetails: {
              ...appDetails,
              documents: documents ? [...(documents.support ?? []), ...(documents.useCases ?? [])] : [],
              setupWorkflows: setupWorkflows
                ? setupWorkflows.map(({ id, autoRun }) => ({ id, autoRun: !!autoRun }))
                : [],
            },
          };
          assert(
            'changeLog' in payload && !!storeSchemaId,
            new Error('Update schema should have changelog'),
            'WARNING'
          );

          updateAppSchema(
            { ...payload, schemaId: storeSchemaId },
            {
              onSuccess: () => {
                onCancel();
              },
              onSettled: () => {
                resolve();
              },
            }
          );
        }
      } else {
        setActiveIndex(activeIndex + 1);
        resolve();
      }
    });
  };

  return (
    <NvFlex width="100%">
      {!(isUserAppLoading && mode === PublishAppMode.Publish) && (
        <NvForm<OptionalPublishAppFormData | OptionalPublishNewVersionFormData>
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          initialValues={initialValues}
        >
          <StepTracker
            initialActiveStep={activeIndex}
            steps={finalSteps}
            onCancelClick={onCancel}
            setInitialActiveStep={setActiveIndex}
          />
        </NvForm>
      )}
    </NvFlex>
  );
};
