import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomFolderShareIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2077_204)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17ZM13.5 12.25C14.3284 12.25 15 11.5784 15 10.75C15 9.92157 14.3284 9.25 13.5 9.25C12.6716 9.25 12 9.92157 12 10.75C12 10.8108 12.0036 10.8707 12.0106 10.9296L11.0896 11.6203C10.9086 11.5429 10.7093 11.5 10.5 11.5C9.67157 11.5 9 12.1716 9 13C9 13.8284 9.67157 14.5 10.5 14.5C10.7093 14.5 10.9086 14.4571 11.0896 14.3797L12.0106 15.0705C12.0036 15.1293 12 15.1892 12 15.25C12 16.0784 12.6716 16.75 13.5 16.75C14.3284 16.75 15 16.0784 15 15.25C15 14.4216 14.3284 13.75 13.5 13.75C13.2907 13.75 13.0914 13.7929 12.9104 13.8703L11.9894 13.1795C11.9964 13.1207 12 13.0607 12 13C12 12.9393 11.9964 12.8794 11.9894 12.8205L12.9105 12.1297C13.0914 12.2071 13.2907 12.25 13.5 12.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2077_204">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
