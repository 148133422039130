import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { MenuItemWrapper } from '../navigation-menu/menu-item/styled';

export const StyledBar = styled(NvFlex)`
  align-items: center;
  flex-direction: row;
  padding: 0px 16px;
  height: 48px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e100};
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
`;

export const ProfileMenuItemWrapper = styled(MenuItemWrapper)`
  padding: 12px 0px 12px 0;
  &:hover,
  &.is-popup-menu-open {
    background-color: inherit;
  }
`;
