import { useCreateCustomerPortalSession } from '@novaera/actioner-service';
import { NvFlex, NvLink, NvTypography } from '@novaera/core';
import { FC, useCallback } from 'react';
import { BillingCard } from '../../billing-card';
import { InvoicesProps } from './types';

export const Invoices: FC<InvoicesProps> = ({ email, address }) => {
  const { mutate: createCustomerPortalSession } = useCreateCustomerPortalSession();
  const goToCustomerPortal = useCallback(() => {
    createCustomerPortalSession(undefined, {
      onSuccess: (result) => {
        if (result.url) {
          window.location.href = result.url;
        }
      },
    });
  }, [createCustomerPortalSession]);

  const handleUpdateBillingAddress = goToCustomerPortal;
  const handleInvoices = goToCustomerPortal;

  return (
    <BillingCard
      title="Invoices"
      description={
        <NvFlex gap="24px">
          <NvFlex gap="8px">
            <NvTypography variant="body1">
              Invoices are sent to <b>{email}</b>.
            </NvTypography>
            <NvLink onClick={handleInvoices}>See all invoices</NvLink>
          </NvFlex>
          {address ? (
            <NvFlex gap="8px">
              <NvTypography variant="body1">
                <b>Billing address:</b> {address}
              </NvTypography>
              <NvLink onClick={handleUpdateBillingAddress}>Change billing address</NvLink>
            </NvFlex>
          ) : (
            <NvFlex gap="8px">
              <NvTypography variant="body1" textColor="subtle">
                You don't have any billing address
              </NvTypography>
              <NvLink onClick={handleUpdateBillingAddress}>Add billing address</NvLink>
            </NvFlex>
          )}
        </NvFlex>
      }
    />
  );
};
