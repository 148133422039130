import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PermissionDefinitionsCard = styled(NvFlex)`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  align-items: flex-start;
`;

export const PermissionDefinitionRow = styled(NvFlex)`
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};

  &:last-of-type {
    border-bottom: none;
  }
`;
