import { InputBase } from '@mui/material';
import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';
import { NvMenuItem } from '../menu-item';
import { NvInputBaseProps } from './types';

export const BootstrapInput = styled(InputBase, {
  shouldForwardProp: shouldForwardPropForFormFields,
})<NvInputBaseProps>`
  &.MuiInputBase-root,
  &.Mui-focused {
    .MuiSelect-select {
      &.MuiInputBase-input {
        display: flex;
        align-items: center;
        border-radius: 6px;
        position: relative;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
        border: ${({ theme, error }) => (error ? `1px solid ${theme.palette.nv_error[30]}` : '1px solid transparent')};
        ${({ theme, error }) => (error ? theme.mixins.error : null)};
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        padding: 0 32px 0 12px;
        height: 32px;
        transition: ${({ theme }) => theme.transitions.create(['border-color', 'background-color'])};

        &:active {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[50]};
        }

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
        }
      }
    }

    .MuiSvgIcon-root {
      &.MuiSelect-icon {
        width: 20px;
        height: 20px;
        right: 6px;
        top: calc(50% - 10px);
        transition: transform 0.2s ease-out;

        &.MuiSelect-iconOpen {
          transform: rotate(180deg);
        }
      }
    }

    &.MuiInputBase-colorGhost {
      .MuiSelect-select {
        &.MuiInputBase-input {
          background-color: transparent;

          &:hover {
            background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
          }
        }
      }
    }

    &.compact {
      .MuiSelect-select {
        &.MuiInputBase-input {
          height: 24px;
          font-size: 12px;
          line-height: 18px;
          padding-right: 28px;
        }
      }

      .MuiSvgIcon-root {
        &.MuiSelect-icon {
          width: 16px;
          height: 16px;
          top: calc(50% - 8px);
        }
      }
    }

    &.MuiInputBase-sizeSmall {
      .MuiSelect-select {
        &.MuiInputBase-input {
          height: 32px;
          font-size: 12px;
          line-height: 18px;
          padding-right: 28px;
        }
      }

      .MuiSvgIcon-root {
        &.MuiSelect-icon {
          width: 16px;
          height: 16px;
          top: calc(50% - 8px);
        }
      }
    }

    &.MuiInputBase-sizeLarge {
      .MuiSelect-select {
        &.MuiInputBase-input {
          height: 40px;
          padding-right: 40px;
        }
      }

      .MuiSvgIcon-root {
        &.MuiSelect-icon {
          width: 24px;
          height: 24px;
          top: calc(50% - 12px);
          right: 8px;
        }
      }
    }

    &.with-icon {
      .MuiInputBase-input {
        padding-left: 6px;

        .MuiSelect-startIcon {
          justify-content: center;
          width: 20px;
          height: 20px;
          margin-right: 8px;

          .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
        }
      }

      &.MuiInputBase-sizeSmall {
        .MuiInputBase-input {
          padding-left: 6px;
          .MuiSelect-startIcon {
            width: 16px;
            height: 16px;

            .MuiSvgIcon-root {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      &.MuiInputBase-sizeLarge {
        .MuiInputBase-input {
          padding-left: 8px;
          .MuiSelect-startIcon {
            width: 24px;
            height: 24px;

            .MuiSvgIcon-root {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  &.isOpenSelect {
    .MuiSelect-select {
      &.MuiInputBase-input {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
        ${({ theme }) => theme.mixins.focus};
      }
    }
  }
`;

export const NvSelectMenuItem = styled(NvMenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    padding: 5px 12px;
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    border: 1px solid transparent;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    gap: 8px;
    &.left-icon {
      gap: 8px;
      padding-left: 6px;
    }

    &.has-description {
      height: inherit;
    }

    &.has-divider {
      margin-top: 14px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -9px;
        left: 0;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }

    &.Mui-focusVisible {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border-color: ${({ theme }) => theme.palette.nv_neutral_alpha[50]};

      &.Mui-selected {
        background-color: ${({ theme }) => theme.palette.nv_main[20]};
        border-color: ${({ theme }) => theme.palette.nv_main[30]};
      }
    }

    &.Mui-selected {
      color: ${({ theme }) => theme.palette.nv_main[60]};
      background-color: ${({ theme }) => theme.palette.nv_main[20]};

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_main[20]};
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.nv_main[20]};
      }
    }

    .MuiSelect-startIcon {
      margin-left: -6px;
      margin-right: 8px;
      width: 20px;
      height: 20px;

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
