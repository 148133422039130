import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { UserApp } from '../../types';
import { USER_APP_ROOT_PATH } from '../constants';

import { QUERY_KEYS_USER_APP } from '../keys';

import { CreateUserAppAccessSettingsParams, CreateUserAppAccessSettingsResponse } from './types';

const createUserAppAccessSettings = async ({ appId, accessSettings }: CreateUserAppAccessSettingsParams) => {
  const result = await NvAxios.post<CreateUserAppAccessSettingsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/access-settings/batch-create`,
    {
      accessSettings,
    }
  );
  return result?.data;
};

export const useCreateUserAppAccessSettings = () => {
  const cache = useQueryClient();

  return useMutation(createUserAppAccessSettings, {
    onSuccess: (updatedPackage, { appId }) => {
      cache.setQueryData<UserApp>(QUERY_KEYS_USER_APP.detail(appId), updatedPackage);
    },
  });
};
