import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomSingleSelectFieldIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L15 12.5858L17.2929 10.2929C17.6834 9.90237 18.3166 9.90237 18.7071 10.2929C19.0976 10.6834 19.0976 11.3166 18.7071 11.7071L15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071L11.2929 11.7071C10.9024 11.3166 10.9024 10.6834 11.2929 10.2929Z"
        fill="currentColor"
      />
      <path
        d="M7.5 13.5C8.32843 13.5 9 12.8284 9 12C9 11.1716 8.32843 10.5 7.5 10.5C6.67157 10.5 6 11.1716 6 12C6 12.8284 6.67157 13.5 7.5 13.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM20 7H4V17H20V7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
