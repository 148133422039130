import { CheckConnectionValidationResponse } from '@novaera/actioner-service';
import { NvButton, NvDialogModal, NvErrorIcon, NvFlex, NvTypography } from '@novaera/core';
import { FC, ReactNode, useMemo, useState } from 'react';

export interface ContactYourAdminModalProps {
  connectionValidationCheckResult?: CheckConnectionValidationResponse;
  orElse: ReactNode;
  modal: boolean;
  workflowName: string;
}

export const ContactYourAdmin: FC<ContactYourAdminModalProps> = ({
  connectionValidationCheckResult,
  orElse,
  modal,
  workflowName,
}) => {
  const [showRedirectToAdmin, setShowRedirectToAdmin] = useState<boolean>(false);
  const { shouldRedirectToAdmin, adminInfo } = useMemo(() => {
    if (
      connectionValidationCheckResult &&
      !connectionValidationCheckResult?.userCanFixInvalidConnections &&
      connectionValidationCheckResult?.invalidNodeConnections.length > 0
    ) {
      setShowRedirectToAdmin(true);
      return {
        shouldRedirectToAdmin: true,
        adminInfo: connectionValidationCheckResult.defaultUser,
        invalidNodeConnections: connectionValidationCheckResult.invalidNodeConnections,
      };
    }

    return { shouldRedirectToAdmin: false };
  }, [connectionValidationCheckResult]);
  const contactTypographyComponent = useMemo(
    () => (
      <NvTypography>
        You are unable to execute this <b>{workflowName}</b> workflow due to missing connections. Please get in touch
        with <b>{adminInfo?.name}</b> to establish the necessary connections.
      </NvTypography>
    ),
    [adminInfo?.name, workflowName]
  );

  return shouldRedirectToAdmin ? (
    modal ? (
      <NvDialogModal
        modalIcon="error"
        Header={'Missing connections'}
        onCloseButtonClicked={() => setShowRedirectToAdmin(false)}
        open={showRedirectToAdmin}
        Body={contactTypographyComponent}
        Footer={
          <NvButton color="secondary" onClick={() => setShowRedirectToAdmin(false)}>
            Close
          </NvButton>
        }
      />
    ) : (
      <NvFlex gap="24px">
        <NvFlex flexDirection={'row'} alignItems={'center'} gap={'8px'}>
          <NvErrorIcon />
          <NvTypography variant="h1">Missing connections</NvTypography>
        </NvFlex>
        {contactTypographyComponent}
      </NvFlex>
    )
  ) : (
    orElse
  );
};
