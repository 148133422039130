import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetConfigVersionsParams, GetConfigVersionsResponse } from './types';

const getConfigVersions = async ({ appId, configId, pageParam }: GetConfigVersionsParams) => {
  const result = await NvAxios.get<GetConfigVersionsResponse>(`${CONFIG_ROOT_PATH(appId)}/${configId}/versions`, {
    params: {
      ...(pageParam ? { nextPageKey: pageParam } : {}),
    },
  });
  return result?.data;
};

export const useGetConfigVersions = ({ appId, configId, enabled = true }: GetConfigVersionsParams) => {
  return useInfiniteQuery<GetConfigVersionsResponse>(
    QUERY_KEYS_CONFIGS.versions(appId, configId),
    (props) => getConfigVersions({ appId, configId, ...props }),
    {
      enabled: !!appId && !!configId && enabled,
      getNextPageParam: (lastPage) => lastPage?.nextPageKey,
    }
  );
};
