import { NvButton, NvFlex, NvLink, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { EnterpriseCardWrapper } from './styled';

export const EnterpriseCard: FC = () => {
  return (
    <EnterpriseCardWrapper>
      <NvFlex gap="4px" flex="1 1 auto" minWidth={0}>
        <NvTypography variant="h3">Enterprise</NvTypography>
        <NvTypography variant="body1">
          For teams with high volume, compliance and support needs.{' '}
          <NvLink href="https://actioner.com/pricing" target="_blank">
            Learn more.
          </NvLink>
        </NvTypography>
      </NvFlex>
      <NvButton
        color="secondary"
        onClick={() => {
          window.open('https://actioner.com/contact-sales', '_blank');
        }}
      >
        Contact us
      </NvButton>
    </EnterpriseCardWrapper>
  );
};
