import { SchemaType } from '@novaera/ah-common';
import { NvChip, NvDNSIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { lowerCase } from 'lodash';
import { FC } from 'react';

import { FIELD_TYPE_DISPLAY_NAME } from '../../../../constants';
import { ListOfNameProps } from './type';

export const ListOfName: FC<React.PropsWithChildren<ListOfNameProps>> = ({
  valueSchema,
  parentTitle,
  variant,
  textColor = 'main',
}) => {
  const { palette } = useTheme();

  if (valueSchema) {
    if (valueSchema.type === SchemaType.recordReference) {
      return (
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvTypography variant={variant} textColor={textColor}>{`${parentTitle} of `}</NvTypography>
          <NvChip
            sx={{ color: palette.nv_main[60] }}
            label={valueSchema.modelName}
            icon={<NvDNSIcon color="inherit" />}
            compact={variant === 'h5'}
          />
        </NvFlex>
      );
    } else {
      return (
        <NvTypography textColor={textColor} variant={variant}>
          {valueSchema.type && `${parentTitle} of ${lowerCase(FIELD_TYPE_DISPLAY_NAME[valueSchema.type])}`}
        </NvTypography>
      );
    }
  }
  return <NvTypography textColor={textColor} variant={variant}>{`${parentTitle} of object`}</NvTypography>;
};
