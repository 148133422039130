import { NvCollapseCard, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConnectionInformationWrapper = styled(NvCollapseCard)`
  column-gap: 16px;
  padding: 8px;
  flex-direction: column;

  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
`;

export const Body = styled(NvFlex)`
  margin-left: 26px;
`;
