import { injectGlobal, styled } from '@novaera/theme-provider';
import { isUndefined } from 'lodash';
import { CodeMirrorWrapper } from './codemirror-wrapper';

export const NvCodeMirrorWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;

  &.has-border {
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
    border-radius: 6px;
    overflow: hidden;
  }

  &.expanded-mode {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const NvCodeMirrorStartAdornmentBox = styled('div')`
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 10;
`;

export const NvCodeMirror = styled(CodeMirrorWrapper, {
  shouldForwardProp: (prop) =>
    prop !== 'startAdornmentOffset' && prop !== 'initialHeight' && prop !== 'growingHeight' && prop !== 'maxHeight',
})<{
  startAdornmentOffset?: number;
  initialHeight?: string;
  growingHeight?: boolean;
  maxHeight?: string;
}>`
  &.NvCodeInput {
    .CodeMirror {
      height: auto;
      min-height: ${({ initialHeight }) => (isUndefined(initialHeight) ? '32px' : initialHeight)};
      background: none;
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
      border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
      border-radius: 6px;
      padding: ${({ startAdornmentOffset }) =>
        startAdornmentOffset ? `0 8px 0 ${startAdornmentOffset + 8}px;` : '0 8px;'};
      ${({ options }) => options?.lineNumbers && `padding-left: 0`};
      box-shadow: none;
      transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out, box-shadow 300ms ease-in-out;

      // Font Family
      font-family: Fira Code, monospace;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.palette.nv_neutral[1000]};

      &-focused {
        border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
        ${({ theme }) => theme.mixins.focus};

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]} !important;
        }
      }

      &-lines {
        padding: 7px 16px 7px 0;
      }

      &-cursor {
        border-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      }

      &-code {
      }

      &-line {
        span {
          height: 15px;
          margin: 0;
          padding: 0;
          border: 0;
          vertical-align: baseline;
          padding-top: 1px;
        }
      }

      &-gutter-wrapper {
        .CodeMirror-linenumber {
          color: ${({ theme }) => theme.palette.nv_neutral[60]};
          font-size: 10px;
        }
      }

      &-scroll {
        min-height: ${({ initialHeight }) => (isUndefined(initialHeight) ? '30px' : initialHeight)};
        max-height: ${({ growingHeight, maxHeight }) =>
          isUndefined(growingHeight) ? '208px' : maxHeight ? maxHeight : 'none'};

        .CodeMirror-gutters {
          background: transparent;
          border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
        }
      }

      //color theme

      &-placeholder {
        color: ${({ theme }) => theme.palette.nv_neutral_alpha[60]};
      }

      &-activeline-background {
        background: #ffffff0d;
      }

      &-selected {
        background: #b7dce8;
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
      }

      .cm-novaera_expression {
        background-color: rgba(153, 239, 192, 0.15);

        &.cm-undefined,
        &.cm-novaera_base {
          color: ${({ theme }) => theme.palette.nv_code.green};
        }
        &.cm-string {
          color: ${({ theme }) => theme.palette.nv_neutral[1000]};
        }

        &.cm-variable {
          color: ${({ theme }) => theme.palette.nv_code.purple};
        }

        &.cm-property {
          color: ${({ theme }) => theme.palette.nv_code.brown};
        }

        &.cm-def {
          color: ${({ theme }) => theme.palette.nv_code.green};
        }

        &.cm-variable-2 {
          color: ${({ theme }) => theme.palette.nv_code.green};
        }

        &.cm-operator {
          color: ${({ theme }) => theme.palette.nv_code.orange};
        }

        &.CodeMirror-lint-mark-error {
          color: ${({ theme }) => theme.palette.nv_error[40]};

          &.cm-novaera_expression {
            background-color: rgba(251, 236, 245, 0.5);
          }
        }
      }

      .CodeMirror-overlayscroll-vertical {
        background-color: transparent;
        transition: width 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
          width: 8px;
        }

        div {
          position: absolute;
          background-color: ${({ theme }) => theme.palette.nv_neutral[80]};
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 20px;
          transition: background-color 200ms ease-in-out;

          &:hover {
            background-color: ${({ theme }) => theme.palette.nv_neutral[100]};
          }
        }
      }
    }
    &.expanded-mode {
      height: 100%;
      flex: 1 1 auto;
      min-height: 0;

      .CodeMirror {
        height: 100%;
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        border: 1px solid transparent;

        &-focused {
          border: 1px solid transparent;
          box-shadow: none;

          &:hover {
            background-color: ${({ theme }) => theme.palette.nv_neutral[0]} !important;
          }
        }

        &-lines {
          padding: 7px 72px 7px 0;
        }

        &-scroll {
          min-height: 100%;
          max-height: 100%;
        }

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
        }
      }
    }
  }
`;

injectGlobal(`
.CodeMirror-hints {
  width: 270px;
  padding: 6px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px #EBEEF2, 0px 10px 20px rgba(10, 45, 98, 0.08);
  border-radius: 12px;
  border-color: transparent;
  z-index: 1501;

  .CodeMirror-hint {
      display: flex;
      align-items: center;
      height: 24px;

      .novaera-hint-content{
        font-family: Fira Code, monospace;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #17386A;
      }

      .novaera-hint-content-type{
        font-family: Fira Code, monospace;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        color: rgba(10, 45, 98, 0.6);
      }
    }
  }

  li.CodeMirror-hint-active {
    background: rgba(10, 45, 98, 0.04);
    border-radius: 6px;
  }
`);
