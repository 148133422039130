import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type QAProps = BaseNodeProps;

export const QA: FC<QAProps> = (props) => {
  return (
    <DraggableItem nodeId={props.id}>
      <Node type={NodeType.QA} {...props} />
    </DraggableItem>
  );
};
