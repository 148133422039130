import { FieldSchema, ValueTypes } from '@novaera/actioner-service';
import { DateTimeFormat, SchemaType } from '@novaera/ah-common';

export const fieldSchemaToValue: (schema: FieldSchema) => ValueTypes = (schema: FieldSchema) => {
  switch (schema.type) {
    case SchemaType.string:
    case SchemaType.email:
    case SchemaType.phone:
    case SchemaType.url:
    case SchemaType.uuid:
    case SchemaType.recordReference:
      return ValueTypes.STRING;
    case SchemaType.integer:
    case SchemaType.float:
      return ValueTypes.NUMBER;
    case SchemaType.array:
      return ValueTypes.LIST;
    case SchemaType.map:
    case SchemaType.object:
      return ValueTypes.OBJECT;
    case SchemaType.boolean:
      return ValueTypes.BOOLEAN;
    case SchemaType.dateTime: {
      if (schema.format === DateTimeFormat.utc) {
        return ValueTypes.NUMBER;
      } else {
        // schema.format === DateTimeFormat.iso_8601;
        return ValueTypes.STRING;
      }
    }
  }
};
