import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@novaera/theme-provider';

export const SwitchButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
  gap: 2px;
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  border-radius: 8px;
`;

export const SwitchButton = styled(ToggleButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 10px;
  height: 24px;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.nv_neutral[300]};
  border-radius: 6px !important;
  border: none !important;
  margin: 0 !important;
  transition: background-color 300ms ease, color 300ms ease;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  }

  &:hover {
    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    }
  }
`;
