import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_APP_DIRECTORY_PREFIX } from '../constants';
import { UpdateSchemaMetadataParams } from './types';

const updateSchemaMetadata = async ({ schemaId, payload }: UpdateSchemaMetadataParams) => {
  const result = await NvAxios.put(`${ADMIN_APP_DIRECTORY_PREFIX}/schemas/${schemaId}/metadata`, payload);
  return result?.data;
};

export const useUpdateSchemaMetadata = () => {
  return useMutation(updateSchemaMetadata);
};
