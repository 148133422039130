import { isDevEnv } from '@novaera/utils';
import { APPLICATION } from './types';

export const ApplicationConfig: Record<
  APPLICATION,
  { origin?: string; baseOrigin?: string; host?: string; baseHost?: string }
> = {
  Actioner: class {
    /**
     * returns in format of http(s)://domain.com
     */
    static get origin() {
      return `${window.location.protocol}//${process.env.NX_FRONTEND_HOST}`;
    }

    /**
     * returns in format of http(s)://app.domain.com
     */
    static get baseOrigin() {
      return `${window.location.protocol}//${process.env.NX_FRONTEND_OLD_HOST}`;
    }

    static get baseHost() {
      if (isDevEnv()) {
        return process.env.NX_FRONTEND_OLD_HOST?.includes(':')
          ? process.env.NX_FRONTEND_OLD_HOST?.split(':')[0]
          : process.env.NX_FRONTEND_OLD_HOST;
      }
      return process.env.NX_FRONTEND_OLD_HOST;
    }

    static get host() {
      if (isDevEnv()) {
        return process.env.NX_FRONTEND_HOST?.includes(':')
          ? process.env.NX_FRONTEND_HOST?.split(':')[0]
          : process.env.NX_FRONTEND_HOST;
      }
      return process.env.NX_FRONTEND_HOST;
    }
  },
};
