import {
  CheckboxComponent,
  CheckboxGroupComponent,
  DataSourceType,
  DatePickerComponent,
  DateTimePickerComponent,
  DynamicInputComponent,
  HeaderComponent,
  MultiSelectComponent,
  PlainTextComponent,
  RadioButtonGroupComponent,
  SingleSelectComponent,
  SlackConversationPickerComponent,
  SlackUserPickerUIComponent,
  TextInputComponent,
  TimePickerComponent,
  UIComponentType,
} from '@novaera/actioner-service';

export const textFieldComponent: TextInputComponent = {
  type: UIComponentType.TEXT_FIELD,
  label: 'Text',
};

export const textAreaComponent: TextInputComponent = {
  ...textFieldComponent,
  multiLine: true,
};

export const singleSelectComponent: SingleSelectComponent = {
  type: UIComponentType.SINGLE_SELECT,
  label: 'Single-select',
  dataSource: {
    type: DataSourceType.STATIC,
    options: [
      { displayValue: 'Sample 1', key: 'sample1' },
      { displayValue: 'Sample 2', key: 'sample2' },
    ],
  },
  searchAsTypeConfiguration: {
    enabled: false,
  },
};

export const multiSelectComponent: MultiSelectComponent = {
  ...singleSelectComponent,
  label: 'Multi-select',
  type: UIComponentType.MULTI_SELECT,
};

export const checkBoxComponent: CheckboxComponent = {
  type: UIComponentType.CHECK_BOX,
  label: 'Checkbox',
};

export const checkBoxGroupComponent: CheckboxGroupComponent = {
  type: UIComponentType.CHECK_BOX_GROUP,
  label: 'Checkbox group',
  dataSource: {
    type: DataSourceType.STATIC,
    options: [
      { displayValue: 'Sample 1', key: 'sample1' },
      { displayValue: 'Sample 2', key: 'sample2' },
    ],
  },
};

export const radioButtonGroupComponent: RadioButtonGroupComponent = {
  type: UIComponentType.RADIO_BUTTON_GROUP,
  label: 'Radio button group',
  dataSource: {
    type: DataSourceType.STATIC,
    options: [
      { displayValue: 'Sample 1', key: 'sample1' },
      { displayValue: 'Sample 2', key: 'sample2' },
    ],
  },
};

export const datePickerComponent: DatePickerComponent = {
  type: UIComponentType.DATE_PICKER,
  label: 'Date',
};

export const dateTimePickerComponent: DateTimePickerComponent = {
  type: UIComponentType.DATE_TIME_PICKER,
  label: 'Date & time',
};

export const timePickerComponent: TimePickerComponent = {
  type: UIComponentType.TIME_PICKER,
  label: 'Time',
};

export const headerComponent: HeaderComponent = {
  type: UIComponentType.HEADER,
  text: 'Header',
  level: 'H1',
};

export const plainTextComponent: PlainTextComponent = {
  type: UIComponentType.PLAIN_TEXT,
  text: 'Text',
  level: 'P1',
};

export const dynamicInputComponent: DynamicInputComponent = {
  type: UIComponentType.DYNAMIC_INPUT,
  label: 'Dynamic input',
  dataSource: {
    type: 'integration',
  },
};

export const slackUserPickerComponent: SlackUserPickerUIComponent = {
  type: UIComponentType.SLACK_USER_PICKER,
  label: 'Slack user picker',
};
export const slackConversationPickerComponent: SlackConversationPickerComponent = {
  type: UIComponentType.SLACK_CONVERSATION_PICKER,
  label: 'Slack conversation picker',
};
