import { NvAxios, isAxiosError, useQuery } from '@novaera/core';
import { QUERY_KEYS_MS_TEAMS_WORKSPACE } from '../keys';
import { GetMsTeamsWorkspaceResponse } from './types';

const getMsTeamsWorkspace = async () => {
  const result = await NvAxios.get<GetMsTeamsWorkspaceResponse>('/v1/msteams');
  return result?.data;
};

export const useGetMsTeamsWorkspace = () => {
  return useQuery<GetMsTeamsWorkspaceResponse>(QUERY_KEYS_MS_TEAMS_WORKSPACE.all, () => getMsTeamsWorkspace(), {
    refetchOnWindowFocus: true,
    retry: (count, error) => {
      const isNotFound = isAxiosError(error) && error.response?.status === 404;
      return !isNotFound;
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 404) {
        // do nothing
      } else {
        throw error;
      }
    },
  });
};
