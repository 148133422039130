import { FC } from 'react';
import { NvTextField } from '../textfield';
import { NvTextFieldProps } from '../textfield/types';

export type NvNumberTextFieldProps = NvTextFieldProps;

export const NvNumberTextField: FC<React.PropsWithChildren<NvNumberTextFieldProps>> = ({
  inputProps,
  value,
  onChange,
  ...restProps
}) => {
  return (
    <NvTextField
      value={value}
      onChange={(event) => {
        try {
          const numberValue = Number(event.target.value);
          if (isNaN(numberValue)) {
            const newEvent = { ...event, target: { ...event.target, value: inputProps?.min } };
            return onChange?.(newEvent);
          }

          if (numberValue > inputProps?.max) {
            const newEvent = { ...event, target: { ...event.target, value: inputProps?.max } };
            return onChange?.(newEvent);
          } else if (numberValue < inputProps?.min) {
            const newEvent = { ...event, target: { ...event.target, value: inputProps?.min } };
            return onChange?.(newEvent);
          }
        } catch (error) {
          const newEvent = { ...event, target: { ...event.target, value: inputProps?.min } };
          return onChange?.(newEvent);
        }

        return onChange?.(event);
      }}
      inputProps={{ ...inputProps, type: 'number' }}
      {...restProps}
    />
  );
};
