import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const UserAppSideMenuItemLoadingFrameMain = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 0px 4px;
  gap: 4px;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  flex: 0 0 auto;
  min-width: 0;
`;
