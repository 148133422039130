import { RecordItem, useCreateRecord, useUpdateRecord } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { isRequired } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isValid } from 'date-fns';
import { useMemo } from 'react';
import { TABLE_HIDDEN_FIELD_NAMES } from '../../../../../../../../components/data-model-layout/constants';
import { useGenerateInputParametersFromRecordModel } from '../../../../../utils';
import { InputFieldWithSchema } from '../types';

export const useRecordModel = (onClose: () => void, isEdit: boolean) => {
  const { userAppId: appId, modelId } = useParams();
  const { mutate: createRecord } = useCreateRecord();
  const { mutate: updateRecord } = useUpdateRecord();
  const { inputParameters, fields, isLoading } = useGenerateInputParametersFromRecordModel({
    appId,
    modelId,
    retry: 3,
  });

  const parameters = useMemo<InputFieldWithSchema[] | undefined>(() => {
    return inputParameters?.map((p) => {
      const field = fields.find((f) => f.id === p.id);
      assert(!!field?.schema, new Error('Schema should be defined'), 'WARNING');
      return { ...p, schema: field?.schema };
    });
  }, [inputParameters, fields]);

  const extraProps = (inputField: InputFieldWithSchema) => {
    let baseProp = {};
    if (inputField.schema?.mandatory) {
      baseProp = { validators: [isRequired()], showErrorMessageOnlyWhenBlur: true, hasRequiredIndicator: true };
    }
    if (inputField.schema?.type === SchemaType.dateTime) {
      baseProp = { ...baseProp, parse: (value: Date) => (isValid(value) ? value.getTime() : value) };
    }
    if (inputField.schema?.type === SchemaType.array) {
      baseProp = { ...baseProp, isAutoComplete: true, format: (value: unknown) => (value ? value : []) };
    }

    return baseProp;
  };

  const handleOnSubmit = (values: RecordItem) => {
    return new Promise<void>((resolve) => {
      Object.keys(values).forEach((name) => {
        const valueParameter = parameters?.find((p) => p.name === name);
        if (valueParameter?.schema.type === SchemaType.map && typeof values[name] === 'string') {
          const valueName = values[name];
          assert(typeof valueName === 'string', new Error('value name should be string'), 'ERROR');
          values[name] = JSON.parse(valueName);
        }
      });

      if (isEdit) {
        assert(!!values.id, new Error('Id should be defined'), 'WARNING');
        const { id, ...rest } = values;
        updateRecord(
          { appId, modelId, recordId: id, body: rest },
          {
            onSuccess: () => {
              onClose();
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      } else {
        createRecord(
          { appId, modelId, body: values },
          {
            onSuccess: () => {
              onClose();
            },
            onSettled: () => {
              resolve();
            },
          }
        );
      }
    });
  };

  const filteredParameters = useMemo(
    () => parameters?.filter((parameter) => !TABLE_HIDDEN_FIELD_NAMES.includes(parameter.id)),
    [parameters]
  );

  return { handleOnSubmit, filteredParameters, extraProps, appId, isLoading };
};
