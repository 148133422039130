import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_SCHEMA } from '../keys';
import { ConnectionSchema, CreateIntegrationConnectionParams } from './types';

const createConnection = async (params: CreateIntegrationConnectionParams) => {
  const { integrationId, ...rest } = params;
  const result = await NvAxios.post<ConnectionSchema>(
    `${INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH(integrationId)}`,
    rest
  );
  return result?.data;
};

export const useCreateConnectionSchemaService = () => {
  const cache = useQueryClient();

  return useMutation(createConnection, {
    onSuccess(data, variables) {
      cache.setQueryData(QUERY_KEYS_CONNECTION_SCHEMA.connection_schema(data.id, variables.integrationId), () => {
        return data;
      });
    },
  });
};
