import { useQueryClient } from '@tanstack/react-query';
import { ExecutionStepResponse } from '../../types';
import { QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION } from '../keys';
import { GetIntegrationActionStepResponseProps } from '../types';

export const useGetIntegrationActionStepResponse = ({
  integrationId,
  actionId,
  stepId,
}: GetIntegrationActionStepResponseProps) => {
  const cache = useQueryClient();

  let data;
  if (stepId) {
    data = cache.getQueryData<ExecutionStepResponse>(
      QUERY_KEYS_INTEGRATION_ACTION_STEP_EXECUTION.detail(integrationId, actionId, stepId)
    );
  }

  return { data };
};
