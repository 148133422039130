import { useSetOAuthCode } from '@novaera/actioner-service';
import { NvAlert, NvBox, NvFlex, NvLinearProgress } from '@novaera/core';
import { useQueryParams } from '@novaera/route';

import { useEffect } from 'react';

export const OAuthCodePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { getSearchParams } = useQueryParams();

  const params = getSearchParams<{ code?: string; state?: string }>();

  const { data, error: connectionError, isLoading } = useSetOAuthCode({ code: params?.code, state: params?.state });

  useEffect(() => {
    if (data || connectionError) {
      const subdomain = data?.subdomain;
      const error = data?.error;
      const connectionId = data?.connectionId;
      const error_description = data?.error_description;
      const { host, protocol } = window.location;
      if (subdomain) {
        if (error || connectionError) {
          window.location.href = `${protocol}//${subdomain}.${host}/post-message?message=error&messageFrom=oauth-code&error=${error}&error_description=${error_description}`;
          return;
        } else {
          window.location.href = `${protocol}//${subdomain}.${host}/post-message?message=success&messageFrom=oauth-code${
            connectionId ? `&connectionId=${connectionId}` : ``
          }`;
          return;
        }
      }
    }
  }, [connectionError, data, isLoading, params]);

  return (
    <NvBox sx={{ width: '100%' }}>
      <NvFlex width={1}>
        {connectionError ? (
          <NvAlert color="error"> Something went wrong, please try again.</NvAlert>
        ) : (
          <NvLinearProgress />
        )}
      </NvFlex>
    </NvBox>
  );
};
