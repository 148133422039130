import { NvAxios, useQuery } from '@novaera/core';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetAllConfigsWithPropertiesParams, GetAllConfigsWithPropertiesResponse } from './types';

const getAllConfigsWithProperties = async ({ appId }: GetAllConfigsWithPropertiesParams) => {
  const result = await NvAxios.get<GetAllConfigsWithPropertiesResponse>(`${CONFIG_ROOT_PATH(appId)}`, {
    params: {
      allWithProperties: true,
    },
  });
  return result?.data;
};

export const useGetAllConfigsWithProperties = ({ appId }: GetAllConfigsWithPropertiesParams) => {
  return useQuery<GetAllConfigsWithPropertiesResponse>(
    QUERY_KEYS_CONFIGS.listAllWithProperties(appId),
    () => getAllConfigsWithProperties({ appId }),
    {
      enabled: !!appId,
    }
  );
};
