import NvCloseIcon from '@mui/icons-material/CloseRounded';
import React, { cloneElement, isValidElement, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { NvAddButtonWithLabel } from '../add-button-with-label';
import { NvButton } from '../button';
import { NvFlex } from '../flex';
import { isAllRequired } from '../form/utils';
import { NvTypography } from '../typography';
import { useKeyValueInput } from './controllers/use-key-value-input';
import { FieldWrapper, KeyWrapper, NvFieldWrapper, RemoveButtonWrapper, ValueGroupWrapper } from './styled';
import { KeyValueInputProps } from './types';

export const NvKeyValueInput: React.FC<React.PropsWithChildren<KeyValueInputProps>> = ({
  fieldArrayName,
  addNewInputLabel,
  keyTitle,
  valueTitle,
  onAddNewInputClicked,
  onRemoveItemClicked,
  hasRemoveAction,
  component,
  keyDefaultText,
  valueDefaultText,
  keyFieldName,
  valueFieldName,
  keepAtLeastInput = false,
  AddNewFieldButton,
  KeyWrapperStyle,
}) => {
  const { onAddNewInput, handleOnRemoveInput } = useKeyValueInput({
    fieldArrayName,
    keyDefaultText,
    valueDefaultText,
    keyFieldName,
    valueFieldName,
  });

  const [keepAtLeastField, setKeepAtLeastField] = useState(keepAtLeastInput);
  const {
    input: { value },
  } = useField(fieldArrayName);

  const length = useMemo(() => value.length ?? 0, [value]);

  const handleOnClick = () => {
    onAddNewInput();
    onAddNewInputClicked?.();
  };

  return (
    <NvFlex width={1}>
      <NvFlex>
        {!!length && (
          <FieldWrapper>
            <NvFieldWrapper>
              <KeyWrapper sx={KeyWrapperStyle}>
                <NvTypography textColor="subtle" variant="h7">
                  {keyTitle.toUpperCase()}
                </NvTypography>
              </KeyWrapper>
              <ValueGroupWrapper>
                <NvTypography textColor="subtle" variant="h7">
                  {valueTitle.toUpperCase()}
                </NvTypography>
              </ValueGroupWrapper>
            </NvFieldWrapper>
            {(hasRemoveAction || keepAtLeastField) && <RemoveButtonWrapper width={'24px'} />}
          </FieldWrapper>
        )}

        <FieldArray name={fieldArrayName} validate={isAllRequired()}>
          {({ fields }) => {
            setKeepAtLeastField(keepAtLeastInput && length > 1);
            return fields.map((name, index) => {
              return (
                <NvFlex direction="row" key={`field-array-item-${index}`}>
                  <NvFieldWrapper key={`field-array-wrapper-${index}`}>
                    {cloneElement(component, { name })}
                  </NvFieldWrapper>
                  {(hasRemoveAction || keepAtLeastField) && (
                    <RemoveButtonWrapper>
                      <NvButton
                        sx={{
                          marginLeft: '6px',
                          marginTop: '4px',
                        }}
                        onlyIcon
                        size="small"
                        color="secondary"
                        onClick={() => {
                          handleOnRemoveInput(index);
                          onRemoveItemClicked?.(fields.value[index]);
                        }}
                      >
                        <NvCloseIcon />
                      </NvButton>
                    </RemoveButtonWrapper>
                  )}
                </NvFlex>
              );
            });
          }}
        </FieldArray>
      </NvFlex>
      {AddNewFieldButton && isValidElement(AddNewFieldButton) ? (
        React.cloneElement(AddNewFieldButton, {
          onClick: handleOnClick,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)
      ) : (
        <NvAddButtonWithLabel label={addNewInputLabel} variant="small" onClick={handleOnClick} />
      )}
    </NvFlex>
  );
};
