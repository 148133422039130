import { NvBox, NvTooltip, tooltipClasses } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const RecordsSearchContainer = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  padding-right: 16px;
`;

export const ActionBox = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 6px;
`;

export const SampleSearchBox = styled(NvBox)`
  display: flex;
  padding: 6px;
  height: 32px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 6px;
`;

export const SearchTooltip = styled(NvTooltip)`
  & .${tooltipClasses.tooltip} {
    padding: 8px 12px;
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    ${({ theme }) => theme.elevations.e300};
    border: 1px solid ${({ theme }) => theme.palette.nv_main[30]};
    border-radius: 12px;
  }
`;
