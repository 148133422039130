import { NvTypography } from '@novaera/core';
import { FC, ReactNode } from 'react';
import { BillingCardWrapper } from './styled';

export interface BillingCardProps {
  title: string;
  description: ReactNode;
}

export const BillingCard: FC<BillingCardProps> = ({ title, description }) => {
  return (
    <BillingCardWrapper>
      <NvTypography variant="h2">{title}</NvTypography>
      {description}
    </BillingCardWrapper>
  );
};
