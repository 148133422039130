import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CardWrapper = styled(NvFlex)`
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_main[30]};
  box-shadow: 0px 2px 6px rgba(10, 45, 98, 0.06);
  border-radius: 0px 0px 60px 60px;
  padding: 32px 40px 60px 40px;
  justify-content: center;
  align-items: center;
`;

export const ItemWrapper = styled(NvFlex)`
  column-gap: 42px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 750px;
`;
