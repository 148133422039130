import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';

import { QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE } from '../keys';
import { AddSampleParams, AddSampleResponse } from './types';

const addSample = async (params: AddSampleParams) => {
  const { appId, workflowId, ...rest } = params;

  let payload: Pick<AddSampleParams, 'sourceEventKey' | 'content'> = {};
  if (rest.content) {
    payload = {
      content: JSON.parse(rest.content),
    };
  } else if (rest.sourceEventKey) {
    payload = {
      sourceEventKey: rest.sourceEventKey,
    };
  }

  const result = await NvAxios.post<AddSampleResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/events/sample`,
    payload
  );
  return result?.data;
};

export const useAddSample = () => {
  const cache = useQueryClient();

  return useMutation(addSample, {
    onSuccess: (sampleResponse, { appId, workflowId }) => {
      cache.setQueryData(
        QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE.detail({ appId, workflowId }),
        sampleResponse
      );
    },
  });
};
