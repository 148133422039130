import {
  BatchGetIntegrationsSamplePayloadParams,
  isJobNodeSummary,
  isLinkGeneratorNodeSummary,
  isWorkflowDispatcherNodeSummary,
  NodeUnionTypeEnumLike,
  useBatchGetIntegrationsSamplePayload,
  useFetchProfile,
  useGetAllConfigsWithProperties,
  useGetWorkflow,
  useGetWorkflowSampleResponseBatch,
  WorkflowDispatcherNodeSummary,
} from '@novaera/actioner-service';
import { Context } from '@novaera/core';
import { useParams } from '@novaera/route';
import { getSubdomain } from '@novaera/utils';
import { isObject } from 'lodash';

import { useMemo } from 'react';
import { useWorkflowPermission } from '../../../../../user-app-permission-boundary/use-workflow-permission';
import {
  ASSISTANT_SAMPLE_RESPONSE,
  DELETE_JOB_SAMPLE_RESPONSE,
  GET_CONVERSATION_SAMPLE_RESPONSE,
  QA_SAMPLE_RESPONSE,
  SAVE_RECURRING_JOB_SAMPLE_RESPONSE,
  SAVE_SCHEDULED_JOB_SAMPLE_RESPONSE,
  SLACK_SAMPLE,
  WORKFLOW_RESOLVER_SAMPLE_RESPONSE,
} from '../../common/constants';
import { FILE_CHANGE_ACCESSIBILITY_EXAMPLE_PAYLOAD } from '../../properties-panel/property-panels/actioner-change-accessibility-file-property-panel-drawer/node-properties/constants';
import { DELETE_FILE_EXAMPLE_PAYLOAD } from '../../properties-panel/property-panels/actioner-delete-file-property-panel-drawer/node-properties/constants';
import { LIST_FILE_EXAMPLE_PAYLOAD } from '../../properties-panel/property-panels/actioner-list-file-property-panel-drawer/node-properties/constants';
import { PUT_FILE_EXAMPLE_PAYLOAD } from '../../properties-panel/property-panels/actioner-put-file-property-panel-drawer/node-properties/constants';
import { useTriggerPayloadController } from '../../properties-panel/property-panels/integration-trigger-property-panel-drawer/integration-trigger-properties/trigger-payload-panel/controllers/use-trigger-payload';
import { LINK_GENERATOR_EXAMPLE_PAYLOAD } from '../../properties-panel/property-panels/link-generator/node-properties/constants';

export const useGetWorkflowContexts = () => {
  const { userAppId, workflowId } = useParams<{ userAppId: string; workflowId: string }>();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const { data: profileData } = useFetchProfile();
  const { payload: eventPayload } = useTriggerPayloadController();
  const { hasEditPermission } = useWorkflowPermission();
  const { data: appConfigs } = useGetAllConfigsWithProperties({ appId: hasEditPermission ? userAppId : undefined });

  const workflowIdentifiers = useMemo(() => {
    return Object.values(workflow?.nodeSummaries ?? {})
      .filter((node) => node.type === 'workflowDispatcher')
      .map((node) => {
        const nodeSummary = node as WorkflowDispatcherNodeSummary;
        return {
          id: nodeSummary.workflowId,
          appId: userAppId,
        };
      });
  }, [userAppId, workflow?.nodeSummaries]);

  const { data: workflowSampleData } = useGetWorkflowSampleResponseBatch({
    workflowIdentifiers,
  });

  const params = useMemo(() => {
    const params: BatchGetIntegrationsSamplePayloadParams = {
      eventRuleIdentifiers: [],
      integrationActionIdentifiers: [],
    };

    Object.keys(workflow?.nodeSummaries ?? {}).forEach((nodeAlias) => {
      const node = workflow?.nodeSummaries[nodeAlias];
      if (node?.type === 'action' && node.actionId) {
        params.integrationActionIdentifiers.push({
          actionId: node.actionId,
          integrationId: node.integrationId,
          version: { number: node.version },
        });
      }
    });
    return params;
  }, [workflow?.nodeSummaries]);
  const { data } = useBatchGetIntegrationsSamplePayload(params);

  const actionResponses = useMemo<Record<string, string> | undefined>(() => {
    return data?.actionResponseSamples?.reduce((acc, response) => {
      return {
        ...acc,
        [response.identifier.actionId]: response.response,
      };
    }, {});
  }, [data?.actionResponseSamples]);

  const config = useMemo(() => {
    return appConfigs?.configurations.reduce((acc, cur) => {
      let properties;
      try {
        properties = JSON.parse(cur.properties);
      } catch (error) {
        properties = {};
      }
      return { ...acc, [cur.name]: properties };
    }, {});
  }, [appConfigs?.configurations]);

  const WFContext = useMemo(() => {
    const nodeSummaries = workflow?.nodeSummaries ?? {};
    const nodes = workflow?.graph?.vertices.reduce((acc, node) => {
      let nodeContext = {};
      const nodeSummary = nodeSummaries[node.alias];
      const actionResponse =
        nodeSummary.type === NodeUnionTypeEnumLike.action && actionResponses && nodeSummary.actionId
          ? actionResponses?.[nodeSummary.actionId]
          : '';

      const parsedResponse = actionResponse ? JSON.parse(actionResponse) : {};

      switch (node.type) {
        case NodeUnionTypeEnumLike.loop:
          nodeContext = { iterationCount: '', item: '' };
          break;
        case NodeUnionTypeEnumLike.workflowCondition:
          nodeContext = { conditionMatched: '' };
          break;
        case NodeUnionTypeEnumLike.function:
          nodeContext = { context: {} };
          break;
        case NodeUnionTypeEnumLike.action:
          nodeContext = { response: parsedResponse, status: '' };
          break;
        case NodeUnionTypeEnumLike.file:
          if (nodeSummary.type === NodeUnionTypeEnumLike.file) {
            if (nodeSummary.nodeOperation.type === 'put') {
              nodeContext = { response: { ...PUT_FILE_EXAMPLE_PAYLOAD } };
            } else if (nodeSummary.nodeOperation.type === 'transfer') {
              nodeContext = { response: {} };
            } else if (nodeSummary.nodeOperation.type === 'delete') {
              nodeContext = { response: { ...DELETE_FILE_EXAMPLE_PAYLOAD } };
            } else if (nodeSummary.nodeOperation.type === 'change-accessibility') {
              nodeContext = { response: { ...FILE_CHANGE_ACCESSIBILITY_EXAMPLE_PAYLOAD } };
            } else if (nodeSummary.nodeOperation.type === 'list') {
              nodeContext = { response: { ...LIST_FILE_EXAMPLE_PAYLOAD } };
            } else {
              nodeContext = { response: {} };
            }
          }
          break;
        case NodeUnionTypeEnumLike.workflowResolver:
          nodeContext = {
            response: {
              ...WORKFLOW_RESOLVER_SAMPLE_RESPONSE,
            },
          };
          break;
        case NodeUnionTypeEnumLike.aiKnowledge:
          nodeContext = {
            response: {
              ...QA_SAMPLE_RESPONSE,
            },
          };
          break;
        case NodeUnionTypeEnumLike.assistant:
          nodeContext = {
            response: {
              ...ASSISTANT_SAMPLE_RESPONSE,
            },
          };
          break;
        case NodeUnionTypeEnumLike.getConversation:
          nodeContext = {
            response: {
              ...GET_CONVERSATION_SAMPLE_RESPONSE,
            },
          };
          break;
        case NodeUnionTypeEnumLike.workflowDispatcher: {
          if (isWorkflowDispatcherNodeSummary(nodeSummary)) {
            const workflowSample = workflowSampleData?.workflowSamples.find(
              (workflowSample) => workflowSample.identifier.id === nodeSummary.workflowId
            )?.response;

            let result;
            try {
              result = JSON.parse(workflowSample ?? '{}');
            } catch (error) {
              result = {};
            }

            nodeContext = {
              response: !result && isObject(result) ? { ...result } : result,
              status: '',
            };
          } else {
            nodeContext = {
              response: {},
              status: '',
            };
          }

          break;
        }
        case NodeUnionTypeEnumLike.job: {
          if (isJobNodeSummary(nodeSummary)) {
            if (nodeSummary.operation.type === 'saveRecurring') {
              nodeContext = SAVE_RECURRING_JOB_SAMPLE_RESPONSE;
            } else if (nodeSummary.operation.type === 'saveScheduled') {
              nodeContext = SAVE_SCHEDULED_JOB_SAMPLE_RESPONSE;
            } else if (nodeSummary.operation.type === 'delete') {
              nodeContext = DELETE_JOB_SAMPLE_RESPONSE;
            }
          } else {
            nodeContext = {};
          }

          break;
        }
        case NodeUnionTypeEnumLike.linkGenerator: {
          if (isLinkGeneratorNodeSummary(nodeSummary)) {
            nodeContext = { response: LINK_GENERATOR_EXAMPLE_PAYLOAD };
          } else {
            nodeContext = {
              response: {},
              status: '',
            };
          }

          break;
        }
        default:
          nodeContext = {};
      }

      return node.type !== NodeUnionTypeEnumLike.blank
        ? {
            ...acc,
            [node.alias]: nodeContext,
          }
        : acc;
    }, {});

    const context: Context = nodes ? { nodes } : {};

    const staticContext = {
      app: {
        id: userAppId,
      },
      user: {
        email: profileData?.email ?? '',
        id: profileData?.userId ?? '',
      },
      workspace: {
        subdomain: getSubdomain(),
      },
      config,
      slack: SLACK_SAMPLE,
    };

    if (workflow?.trigger?.type === 'form') {
      const input = workflow.trigger.inputParameters.reduce((acc, input) => {
        return { ...acc, [input.name]: {} };
      }, {});
      return { ...context, ...staticContext, input, config };
    } else {
      return { ...context, ...staticContext, ...eventPayload, config };
    }
  }, [
    workflow?.nodeSummaries,
    workflow?.graph?.vertices,
    workflow?.trigger,
    userAppId,
    actionResponses,
    eventPayload,
    profileData?.email,
    profileData?.userId,
    config,
    workflowSampleData,
  ]);

  return { workflowCodeInputContext: WFContext };
};
