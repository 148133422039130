import { ErrorHandlingStrategy } from '@novaera/actioner-service';
import { NvSelect, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { PropertyPanelSection } from '../../../../../../../../components';
import { ErrorHandlingStrategyOptions } from './constants';

export const ErrorHandlingStrategyPanel: FC<{
  value?: ErrorHandlingStrategy;
  onChange?: (value: ErrorHandlingStrategy) => void;
}> = ({ value, onChange }) => {
  return (
    <PropertyPanelSection
      collapsible
      title="Error handling"
      tooltip="If an error occurs while executing this node, you have the option to either stop the workflow or continue with the next steps."
    >
      <NvSelect<ErrorHandlingStrategy>
        value={value}
        onChange={(e) => {
          assert(
            typeof e.target.value !== 'string',
            new Error('Error handling strategy should be ErrorHandlingStrategy type')
          );
          onChange?.(e.target.value);
        }}
        fullWidth
        defaultValue={ErrorHandlingStrategyOptions[0].value}
        renderValue={(value) => {
          if (!value.continueOnError) {
            return (
              <NvTypography variant="body2">
                {ErrorHandlingStrategyOptions[0].label + ' '}
                <NvTypography variant="body2" component="span" textColor="ghost">
                  (default)
                </NvTypography>
              </NvTypography>
            );
          } else {
            return <NvTypography variant="body2">{ErrorHandlingStrategyOptions[1].label}</NvTypography>;
          }
        }}
        options={ErrorHandlingStrategyOptions}
      />
    </PropertyPanelSection>
  );
};
