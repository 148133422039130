import { NvAxios, useQuery } from '@novaera/core';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANT_MODELS } from '../keys';
import { GetAssistantModelsParams, GetAssistantModelsResponse } from './types';

const getAssistantModels = async ({ appId }: GetAssistantModelsParams) => {
  const result = await NvAxios.get<GetAssistantModelsResponse>(`${ASSISTANTS_ROOT_PATH(appId)}/models`);
  return result?.data;
};

export const useGetAssistantModels = ({ appId }: GetAssistantModelsParams) => {
  return useQuery(QUERY_KEYS_ASSISTANT_MODELS.all, () => getAssistantModels({ appId }), {
    enabled: !!appId,
  });
};
