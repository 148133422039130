import { NvArrowForwardIcon, NvButton, NvCustomCheckIcon, NvCustomCrossIcon, NvLottie } from '@novaera/core';
import { useMatch, useNavigate } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { useCallback, useMemo } from 'react';
import { USER_APP_CONFIG_DETAIL } from '../../../user-app/user-app-detail/constants';
import { FILE_LOADING_ANIMATION_OPTIONS } from '../constants';
import { AppConfigGenerationSuccessData, useAppConfigFlaggerContext } from '../provider';

export const useAppConfigFlaggerController = () => {
  const navigate = useNavigate();
  const isInConfigDetail = useMatch('my-apps/:userAppId/config/:configId');
  const {
    appConfigGenerationData,
    appConfigGenerationState,
    setAppConfigGenerationState,
    setAppConfigGenerationData,
    isAppConfigGenerationResultModalOpen,
    setIsAppConfigGenerationResultModalOpen,
  } = useAppConfigFlaggerContext();

  const previewConfig = useCallback(
    (data?: AppConfigGenerationSuccessData) => {
      if (data) {
        if (!isInConfigDetail || (!!isInConfigDetail && isInConfigDetail.params.configId !== data.configId)) {
          navigate(USER_APP_CONFIG_DETAIL(data.appId, data.configId));
        }
        setIsAppConfigGenerationResultModalOpen(true);
        setAppConfigGenerationState(undefined);
      }
    },
    [isInConfigDetail, navigate, setAppConfigGenerationState, setIsAppConfigGenerationResultModalOpen]
  );

  const showError = useCallback(() => {
    setIsAppConfigGenerationResultModalOpen(true);
    setAppConfigGenerationState(undefined);
  }, [setAppConfigGenerationState, setIsAppConfigGenerationResultModalOpen]);

  const theme = useTheme();
  const icon = useMemo(() => {
    return appConfigGenerationState === 'in_progress' ? (
      <NvLottie options={FILE_LOADING_ANIMATION_OPTIONS} width="50px" height="50px" isClickToPauseDisabled />
    ) : appConfigGenerationState === 'success' ? (
      <NvCustomCheckIcon sx={{ width: '40px', height: '40px' }} htmlColor={theme.palette.nv_success[40]} />
    ) : appConfigGenerationState === 'failed' ? (
      <NvCustomCrossIcon sx={{ width: '40px', height: '40px' }} htmlColor={theme.palette.nv_error[40]} />
    ) : (
      <></>
    );
  }, [appConfigGenerationState, theme.palette.nv_error, theme.palette.nv_success]);

  const title = useMemo(() => {
    return appConfigGenerationState === 'in_progress'
      ? 'Generating Config'
      : appConfigGenerationState === 'success'
      ? 'Config generated successfully'
      : appConfigGenerationState === 'failed'
      ? 'Error! Config cannot be generated'
      : '';
  }, [appConfigGenerationState]);

  const description = useMemo(() => {
    return appConfigGenerationState === 'in_progress'
      ? "We are currently generating a config file. Once it's finished, you can access it here."
      : undefined;
  }, [appConfigGenerationState]);

  const action = useMemo(() => {
    return appConfigGenerationState === 'success' ? (
      <NvButton
        color="secondary"
        size="small"
        endIcon={<NvArrowForwardIcon />}
        hasDarkBackground
        onClick={() => {
          assert(
            !!appConfigGenerationData?.success,
            new Error(
              '[useAppConfigFlaggerController] - appConfigGenerationData result should be success to preview generated config with AI.'
            ),
            'ERROR'
          );
          previewConfig(appConfigGenerationData);
        }}
      >
        {'Preview config'}
      </NvButton>
    ) : appConfigGenerationState === 'failed' ? (
      <NvButton
        color="secondary"
        size="small"
        endIcon={<NvArrowForwardIcon />}
        hasDarkBackground
        onClick={() => {
          assert(
            !appConfigGenerationData?.success,
            new Error('[ConfigDocument] - appConfigGenerationData result should not be success to show error.'),
            'ERROR'
          );
          showError();
        }}
      >
        {'Show error'}
      </NvButton>
    ) : undefined;
  }, [appConfigGenerationData, appConfigGenerationState, previewConfig, showError]);

  return {
    appConfigGenerationState,
    appConfigGenerationData,
    isAppConfigGenerationResultModalOpen,
    setAppConfigGenerationData,
    setAppConfigGenerationState,
    setIsAppConfigGenerationResultModalOpen,
    icon,
    title,
    description,
    action,
  };
};
