import { IntegrationAction } from '@novaera/actioner-service';
import {
  NvActionFilledIcon,
  NvAutocomplete,
  NvAutocompleteProps,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvFlex,
  NvSkeleton,
  NvTextField,
  NvTextFieldProps,
  NvTypography,
} from '@novaera/core';
import { Optional } from '@novaera/utils';
import { matchSorter } from 'match-sorter';
import { ActionSelectMenu, ActionSelectMenuItem } from '../../action-select-menu';

export function ActionSearchAutoComplete<
  T extends IntegrationAction,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  options,
  textFieldOverrides,
  isLoading,
  error,
  ...props
}: Optional<NvAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  textFieldOverrides?: NvTextFieldProps;
  isLoading?: boolean;
  error?: boolean;
}) {
  return (
    <NvAutocomplete
      sx={{ minWidth: '310px' }}
      options={options}
      getOptionLabel={(option) => (option as IntegrationAction).name ?? ''}
      renderInput={(props) => {
        // check this out: https://github.com/mui/material-ui/issues/32664
        const correctInputProps: React.InputHTMLAttributes<HTMLInputElement> = props.inputProps;
        return (
          <NvTextField
            {...props}
            placeholder={!isLoading ? 'Select action' : ''}
            startIcon={
              isLoading ? (
                <NvFlex direction="row" gap="8px" alignItems="center">
                  {<NvSkeleton variant="circular" width="20px" height="20px" />}

                  {typeof correctInputProps.value !== 'number' && correctInputProps.value?.length === 0 && (
                    <NvSkeleton variant="rectangular" width="72px" height="16px" />
                  )}
                </NvFlex>
              ) : (
                <NvActionFilledIcon />
              )
            }
            size="small"
            error={error}
            {...textFieldOverrides}
          />
        );
      }}
      PaperComponent={({ children, ...props }) => (
        <ActionSelectMenu {...props}>
          {isLoading ? (
            <NvFlex margin="8px" gap="16px" padding="8px ">
              <NvSkeleton variant="rectangular" width="40%" height="16px" />
              <NvSkeleton variant="rectangular" width="60%" height="16px" />
              <NvSkeleton variant="rectangular" width="30%" height="16px" />
              <NvSkeleton variant="rectangular" width="80%" height="16px" />
              <NvSkeleton variant="rectangular" width="40%" height="16px" />
            </NvFlex>
          ) : (
            children
          )}
        </ActionSelectMenu>
      )}
      renderOption={(props, option, state) => (
        <ActionSelectMenuItem {...props} key={option.id}>
          <NvFlex direction="row" alignItems="center" gap="16px" width="100%">
            <NvFlex direction="row" alignItems="center" gap="4px" flex="0 1 auto" minWidth={0}>
              <NvTypography variant="body1" noWrap>
                {option.name}
              </NvTypography>
            </NvFlex>
          </NvFlex>
        </ActionSelectMenuItem>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
      popupIcon={<NvExpandMoreIcon />}
      filterOptions={(options, { inputValue }) => matchSorter(options, inputValue, { keys: ['name'] })}
      {...props}
    />
  );
}
