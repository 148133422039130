import { NvBox } from '@novaera/core';
import { FC } from 'react';
import { NodeItemWrapper } from '../../../../../../components/node/styled';
import { BaseNodeProps } from '../types';

export type DummyNodeProps = BaseNodeProps;

export const DummyNode: FC<React.PropsWithChildren<DummyNodeProps>> = ({ port1, port2 }) => {
  return (
    <NodeItemWrapper>
      <NvBox width="16px" height="0px" position="relative">
        {port1}
        {port2}
      </NvBox>
    </NodeItemWrapper>
  );
};
