import { Method } from '@novaera/actioner-service';

export const METHOD_OPTIONS: { label: string; value: Method }[] = [
  { label: 'Sum', value: 'sum' },
  { label: 'Count', value: 'count' },
  { label: 'Average', value: 'average' },
  { label: 'Median', value: 'median' },
  { label: 'Mode', value: 'mode' },
  { label: 'Standard deviation', value: 'standard-deviation' },
  { label: 'Minimum value', value: 'minimum-value' },
  { label: 'Maximum value', value: 'maximum-value' },
  { label: 'First value', value: 'first-value' },
  { label: 'Last value', value: 'last-value' },
  { label: 'Percentile', value: 'percentile' },
];
