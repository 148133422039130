import { ResponseNode } from '@novaera/actioner-service';
import { NvForm } from '@novaera/core';
import { assert, noop } from '@novaera/utils';
import classNames from 'classnames';
import { useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { useResponsePropertyPanel } from './controllers/use-response-property-panel';
import { ResponsePropertyPanel } from './panel';
import { ExampleResponsePropertyPanel } from './panel/example-response';
import { FormClass } from './styled';
import { ResponsePropertyPanelDrawerProps } from './types';

export const ResponsePropertyPanelDrawer: React.FC<React.PropsWithChildren<ResponsePropertyPanelDrawerProps>> = ({
  handleClose,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[FunctionPropertyPanelDrawerProps] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const { hasEditPermission, isLoading, onSaveResponses, responseNode } = useResponsePropertyPanel();

  const [isSecondPanelOpen, setOpenedSubPanel] = useState<boolean>(false);

  const {
    panelProps: [first, second],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: isSecondPanelOpen,
        closeFn: () => {
          setOpenedSubPanel(false);
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <NvForm<Pick<ResponseNode, 'executionResult' | 'responseTemplate' | 'exampleResponse'>>
        onSubmit={noop}
        {...(hasEditPermission && {
          onChange: ({ values }) => {
            onSaveResponses({ ...values });
          },
        })}
        keepDirtyOnReinitialize
        initialValues={{
          executionResult: responseNode?.executionResult ?? 'success',
          responseTemplate: responseNode?.responseTemplate,
          exampleResponse: responseNode?.exampleResponse,
        }}
        className={classNames(FormClass)}
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        <PPDrawerItem {...first}>
          {isLoading ? (
            <PropertiesLoading />
          ) : (
            responseNode && (
              <ResponsePropertyPanel
                onRowItemClicked={() => {
                  setOpenedSubPanel(true);
                }}
                onCloseClicked={handleClose}
                responseNode={responseNode}
              />
            )
          )}
        </PPDrawerItem>
        <PPDrawerItem {...second}>
          <ExampleResponsePropertyPanel
            onCloseClicked={() => {
              setOpenedSubPanel(false);
            }}
          />
        </PPDrawerItem>
      </NvForm>
    </PropertyPaneWrapper>
  );
};
