import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_SCHEMA } from '../keys';
import { ConnectionSchema } from '../use-create-connection-schema/types';
import { UpdateConnectionSchemaNameParams } from './types';

const updateConnectionSchemaName = async (params: UpdateConnectionSchemaNameParams) => {
  const { integrationId, connectionSchemaId, ...rest } = params;
  const result = await NvAxios.post<ConnectionSchema>(
    `${INTEGRATION_CONNECTION_SCHEMA_ROOT_PATH(integrationId)}/${connectionSchemaId}/metadata`,
    rest.payload
  );
  return result?.data;
};

export const useUpdateConnectionSchemaNameService = () => {
  const cache = useQueryClient();

  return useMutation(updateConnectionSchemaName, {
    onSuccess(data, variables) {
      cache.setQueryData(
        QUERY_KEYS_CONNECTION_SCHEMA.connection_schema(variables.connectionSchemaId, variables.integrationId),
        () => {
          return data;
        }
      );
    },
  });
};
