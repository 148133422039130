import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const SubscriptionPlanDetailHeaderWrapper = styled(NvBox)`
  padding: 7px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]}; ;
`;

export const SubscriptionPlanDetailBodyWrapper = styled(NvFlex)`
  flex-direction: row;
  padding: 16px;
  gap: 4px;
  flex-wrap: wrap;
`;
