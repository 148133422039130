import { JobRecurrenceEndType, RecurrenceTimeUnit } from '../../../jobs/types';
import { ConditionType } from '../../condition';
import { BooleanValue, ListValue, NumberValue, ObjectValue, ParameterMappings, StringValue } from '../../parameter';
import { HttpMethods, MediaTypes } from '../../request';
import { WorkflowState } from '../types';
import { OutputNodeSummary } from './output-component-summary/types';
import { OutputComponent } from './output-component/types';

export const NodeUnionObject = {
  blank: 'blank',
  branchJunction: 'branchJunction',
  workflowCondition: 'workflowCondition',
  loop: 'loop',
  action: 'action',
  function: 'function',
  delay: 'delay',
  output: 'output',
  integrationWebhook: 'integrationWebhook',
  integrationApp: 'integrationApp',
  genericWebhook: 'genericWebhook',
  email: 'email',
  actionerEvent: 'actionerEvent',
  form: 'form',
  response: 'response',
  sendEmail: 'sendEmail',
  workflowDispatcher: 'workflowDispatcher',
  workflowResolver: 'workflowResolver',
  aiKnowledge: 'aiKnowledge',
  assistant: 'assistant',
  getConversation: 'getConversation',
  actionerEventPublisher: 'actionerEventPublisher',
  job: 'job',
  file: 'file',
  linkGenerator: 'linkGenerator',
  http: 'http',
  validation: 'validation',
  assistant_cancel_run: 'assistant_cancel_run',
  subscription_limit: 'subscription_limit',
} as const;

export type NodeUnionType = keyof typeof NodeUnionObject;
export const NodeUnionTypeEnumLike: { [P in NodeUnionType]: P } = {
  blank: 'blank',
  branchJunction: 'branchJunction',
  workflowCondition: 'workflowCondition',
  loop: 'loop',
  action: 'action',
  function: 'function',
  delay: 'delay',
  output: 'output',
  integrationWebhook: 'integrationWebhook',
  integrationApp: 'integrationApp',
  genericWebhook: 'genericWebhook',
  email: 'email',
  actionerEvent: 'actionerEvent',
  actionerEventPublisher: 'actionerEventPublisher',
  form: 'form',
  response: 'response',
  sendEmail: 'sendEmail',
  workflowDispatcher: 'workflowDispatcher',
  workflowResolver: 'workflowResolver',
  aiKnowledge: 'aiKnowledge',
  assistant: 'assistant',
  getConversation: 'getConversation',
  job: 'job',
  file: 'file',
  linkGenerator: 'linkGenerator',
  http: 'http',
  validation: 'validation',
  assistant_cancel_run: 'assistant_cancel_run',
  subscription_limit: 'subscription_limit',
};

export interface NodeBase {
  type: NodeUnionType;
  alias: string;
  name: string;
}

export interface AssistantCancelRunNodeSummary extends NodeBase {
  type: (typeof NodeUnionTypeEnumLike)['assistant_cancel_run'];
  conversationIdentifier: StringValue;
}

export interface AssistantCancelRunExtraData {
  conversationIdentifier: StringValue;
}

export interface ActionNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.action;
  integrationId: string;
  actionId?: string;
  connectionId?: string;
  version: number;
}

export interface LinkGeneratorNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.linkGenerator;
  workflowId?: string;
}

export interface HTTPNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.http;
  workflowId?: string;
}

export interface WorkflowDispatcherNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.workflowDispatcher;
  workflowId?: string;
}

export interface BranchJunctionNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.branchJunction;
  branches: { name: string }[];
}

export interface ResponseNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.response;
  exampleResponse?: string;
}

export interface ActionerEventPublisherNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.actionerEventPublisher;
  eventId?: string;
}

export interface DefaultNodeSummary extends NodeBase {
  type:
    | typeof NodeUnionTypeEnumLike.blank
    | typeof NodeUnionTypeEnumLike.function
    | typeof NodeUnionTypeEnumLike.loop
    | typeof NodeUnionTypeEnumLike.delay
    | typeof NodeUnionTypeEnumLike.sendEmail
    | typeof NodeUnionTypeEnumLike.workflowCondition
    | typeof NodeUnionTypeEnumLike.workflowDispatcher
    | typeof NodeUnionTypeEnumLike.workflowResolver
    | typeof NodeUnionTypeEnumLike.actionerEventPublisher
    | typeof NodeUnionTypeEnumLike.aiKnowledge
    | typeof NodeUnionTypeEnumLike.assistant
    | typeof NodeUnionTypeEnumLike.getConversation
    | typeof NodeUnionTypeEnumLike.linkGenerator
    | typeof NodeUnionTypeEnumLike.http
    | (typeof NodeUnionTypeEnumLike)['assistant_cancel_run'];
}

export type NodeSummary =
  | ActionNodeSummary
  | DefaultNodeSummary
  | BranchJunctionNodeSummary
  | OutputNodeSummary
  | WorkflowDispatcherNodeSummary
  | ResponseNodeSummary
  | ActionerEventPublisherNodeSummary
  | ActionerEventPublisherNodeSummary
  | JobNodeSummary
  | FileNodeSummary
  | LinkGeneratorNodeSummary
  | AssistantCancelRunNodeSummary;
export type NodeSummaries = Record<string, NodeSummary>;

export type ErrorHandlingStrategy = {
  type: 'simple';
  continueOnError: boolean;
};

export interface ActionNode extends ActionNodeSummary {
  type: typeof NodeUnionTypeEnumLike.action;
  formId: string;
  parameterMappings: ParameterMappings;
  connectionId?: string;
  errorHandlingStrategy: ErrorHandlingStrategy;
  state: WorkflowState;
}

export interface FunctionNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.function;
  script: string;
  errorHandlingStrategy: ErrorHandlingStrategy;
  state: WorkflowState;
}

export interface ActionerEventNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.actionerEventPublisher;
  eventId: string;
  eventTemplate: string;
  state: WorkflowState;
}

export type Branch = {
  name: string;
  firstNodeAlias: string;
};

export interface BranchJunctionNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.branchJunction;
  branches: Branch[];
  state: WorkflowState;
}

export interface WorkflowConditionNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.workflowCondition;
  condition: ConditionType;
  trueNodeAlias: string;
  falseNodeAlias: string;
  state: WorkflowState;
}

export interface LoopNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.loop;
  breakCondition?: ConditionType;
  innerNodeAlias: string;
  arrayExpression: string;
  state: WorkflowState;
}

export interface DelayNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.delay;
  duration?: number;
}

export interface BlankNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.blank;
}
export interface WorkflowResolverNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.workflowResolver;
  command: StringValue;
  appId?: StringValue;
  tags?: ListValue;
  additionalContext?: ObjectValue;
  errorHandlingStrategy: ErrorHandlingStrategy;
}

export interface QANode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.aiKnowledge;
  prompt: StringValue;
  docIds?: ListValue;
  tags?: ListValue;
  model: StringValue;
  errorHandlingStrategy: ErrorHandlingStrategy;
}

export interface AssistantNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.assistant;
  conversationIdentifier: StringValue;
  content: StringValue;
  metadata: ObjectValue;
  startRun: BooleanValue;
  errorHandlingStrategy: ErrorHandlingStrategy;
}

export interface AssistantCancelRunNode extends NodeBase {
  type: (typeof NodeUnionTypeEnumLike)['assistant_cancel_run'];
  conversationIdentifier: StringValue;
  errorHandlingStrategy: ErrorHandlingStrategy;
}

export interface GetConversationNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.getConversation;
  conversationIdentifier: StringValue;
}

interface BaseDispatchConfiguration {
  formId?: string;
  parameterMappings?: ParameterMappings;
  inputTemplate?: string;
  blocking: boolean;
  workflowId: string;
}

export interface WorkflowDispatchDependencyConfiguration extends BaseDispatchConfiguration {
  type: 'dependency';
  dependencyId: string;
}
export interface WorkflowDispatchStaticConfiguration extends BaseDispatchConfiguration {
  type: 'static';
  appId: string;
}

export type WorkflowDispatchDynamicConfiguration = {
  type: 'dynamic';
  appIdTemplate: string;
  workflowIdTemplate: string;
  inputTemplate: string;
  blocking: boolean;
};

export type LinkGeneratorDependencyConfiguration = {
  type: 'dependency';
  dependencyId: string;
  workflowId: string;
  formId?: string;
  parameterMappings?: ParameterMappings;
  inputTemplate?: string;
  blocking: boolean;
};
export type LinkGeneratorStaticConfiguration = {
  type: 'static';
  appId: string;
  workflowId: string;
  formId?: string;
  parameterMappings?: ParameterMappings;
  inputTemplate?: string;
  blocking: boolean;
};

export type LinkGeneratorDynamicConfiguration = {
  type: 'dynamic';
  appIdTemplate: string;
  workflowIdTemplate: string;
  inputTemplate: string;
  blocking: boolean;
};

export type WorkflowDispatchConfiguration =
  | WorkflowDispatchStaticConfiguration
  | WorkflowDispatchDynamicConfiguration
  | WorkflowDispatchDependencyConfiguration;

export type LinkGeneratorConfiguration =
  | LinkGeneratorStaticConfiguration
  | LinkGeneratorDynamicConfiguration
  | LinkGeneratorDependencyConfiguration;

export interface WorkFlowDispatcherNode extends WorkflowDispatcherNodeSummary {
  type: typeof NodeUnionTypeEnumLike.workflowDispatcher;
  dispatchConfiguration?: WorkflowDispatchConfiguration;
  errorHandlingStrategy?: ErrorHandlingStrategy;
}

export interface LinkGeneratorNode extends LinkGeneratorNodeSummary {
  type: typeof NodeUnionTypeEnumLike.linkGenerator;
  formConfiguration?: LinkGeneratorConfiguration;
  errorHandlingStrategy?: ErrorHandlingStrategy;
}

type Parameter = { key: string; value: string };
export interface HTTPNode extends HTTPNodeSummary {
  type: typeof NodeUnionTypeEnumLike.http;
  method: HttpMethods;
  mediaType: MediaTypes;
  errorHandlingStrategy?: ErrorHandlingStrategy;
  urlTemplate: string;
  headers: Parameter[];
  parameters: Parameter[];
  bodyTemplate: string;
  connectionId?: string;
  connectionSchemaId?: string;
}

export interface SendEmailNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.sendEmail;
  fromLocalPartSuffix?: StringValue;
  fromName?: StringValue;
  subject?: StringValue;
  to?: ListValue;
  cc?: ListValue;
  bcc?: ListValue;
  replyTo?: StringValue;
  content?: StringValue;
  contentType?: StringValue;
  senderDomain: string;
  errorHandlingStrategy?: ErrorHandlingStrategy;
}

export interface ResponseNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.response;
  responseTemplate?: string;
  executionResult?: 'success' | 'failure';
  state: WorkflowState;
  exampleResponse?: string;
}

export interface OutputNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.output;
  outputComponent: OutputComponent;
  state: WorkflowState;
}

export type NeverRecurringJobTermination = {
  type: JobRecurrenceEndType.NEVER;
};

export type OccurrenceRecurringJobTermination = {
  type: JobRecurrenceEndType.NUMBER_OF_TIMES;
  count: NumberValue;
};

export type SpecificDateRecurringJobTermination = {
  type: JobRecurrenceEndType.DATE_TIME;
  date: NumberValue;
  dynamic: boolean;
};

export type RecurringJobTermination =
  | NeverRecurringJobTermination
  | OccurrenceRecurringJobTermination
  | SpecificDateRecurringJobTermination;

export type MinutelyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.MINUTE;
  repeatInterval: NumberValue;
  dynamic: boolean;
};

export type HourlyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.HOUR;
  repeatInterval: NumberValue;
  recurrenceMinute: NumberValue;
  dynamic: boolean;
};

export type DailyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.DAY;
  repeatInterval: NumberValue;
  recurrenceMinute: NumberValue;
  recurrenceHour: NumberValue;
  dynamic: boolean;
};

export type StaticWeeklyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.WEEKLY;
  cronExpression: string;
  dynamic: false;
};

export type DynamicWeeklyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.WEEKLY;
  dayOfWeek: StringValue;
  hour: StringValue;
  minute: StringValue;
  dynamic: true;
};

export type WeeklyJobRecurrenceConfiguration =
  | StaticWeeklyJobRecurrenceConfiguration
  | DynamicWeeklyJobRecurrenceConfiguration;

export type StaticWeekdaysJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.WEEKDAYS;
  cronExpression: string;
  dynamic: false;
};

export type DynamicWeekdaysJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.WEEKDAYS;
  hour: StringValue;
  minute: StringValue;
  dynamic: true;
};

export type WeekdaysJobRecurrenceConfiguration =
  | StaticWeekdaysJobRecurrenceConfiguration
  | DynamicWeekdaysJobRecurrenceConfiguration;

export type StaticMonthlyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.MONTHLY;
  cronExpression: string;
  dynamic: false;
};

export type DynamicMonthlyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.MONTHLY;
  dayOfMonth: StringValue;
  hour: StringValue;
  minute: StringValue;
  dynamic: true;
};

export type MonthlyJobRecurrenceConfiguration =
  | StaticMonthlyJobRecurrenceConfiguration
  | DynamicMonthlyJobRecurrenceConfiguration;

export type StaticYearlyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.ANNUAL;
  cronExpression: string;
  dynamic: false;
};

export type DynamicYearlyJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.ANNUAL;
  dayOfMonth: StringValue;
  hour: StringValue;
  minute: StringValue;
  month: StringValue;
  dynamic: true;
};

export type YearlyJobRecurrenceConfiguration =
  | StaticYearlyJobRecurrenceConfiguration
  | DynamicYearlyJobRecurrenceConfiguration;

export type StaticCustomCronJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.CUSTOM;
  cronExpression: string;
  dynamic: false;
};

export type DynamicCustomCronJobRecurrenceConfiguration = {
  type: RecurrenceTimeUnit.CUSTOM;
  dayOfWeek: StringValue;
  hour: StringValue;
  minute: StringValue;
  month: StringValue;
  dayOfMonth: StringValue;
  year: StringValue;
  dynamic: true;
};

export type CustomCronJobRecurrenceConfiguration =
  | StaticCustomCronJobRecurrenceConfiguration
  | DynamicCustomCronJobRecurrenceConfiguration;

export type JobRecurrenceConfiguration =
  | MinutelyJobRecurrenceConfiguration
  | HourlyJobRecurrenceConfiguration
  | DailyJobRecurrenceConfiguration
  | WeeklyJobRecurrenceConfiguration
  | WeekdaysJobRecurrenceConfiguration
  | MonthlyJobRecurrenceConfiguration
  | YearlyJobRecurrenceConfiguration
  | CustomCronJobRecurrenceConfiguration;

export type JobOperationTarget = { workflowId: string; parameters: ParameterMappings; formId: string };

export type SaveRecurringJobOperation = {
  type: 'saveRecurring';
  name: StringValue;
  recurrenceConfiguration: JobRecurrenceConfiguration;
  timezone: string;
  termination: RecurringJobTermination;
  target: JobOperationTarget;
};

export type SaveScheduledJobOperation = {
  type: 'saveScheduled';
  name: StringValue;
  time: NumberValue;
  timezone: string;
  dynamic: boolean;
  target: JobOperationTarget;
};

export type DeleteJobOperation = {
  type: 'delete';
};

export type JobOperation = SaveRecurringJobOperation | SaveScheduledJobOperation | DeleteJobOperation;

export interface JobNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.job;
  jobId: string;
  operation: JobOperation;
  state: WorkflowState;
}

export interface JobNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.job;
  operation: Pick<JobOperation, 'type'>;
}

export type JobNodeExtraData = {
  operation:
    | Pick<SaveRecurringJobOperation, 'type' | 'recurrenceConfiguration' | 'termination'>
    | Pick<SaveScheduledJobOperation, 'type'>
    | Pick<DeleteJobOperation, 'type'>;
};

export type ActionBasedSource = {
  type: 'action';
  action: ActionNode;
};

export type UrlBasedSource = {
  type: 'url';
  url: string;
};

export type FileSource = ActionBasedSource | UrlBasedSource;

export type PutFileNodeAction = {
  type: 'put';
  key: string;
  name?: string;
  publicAccess: boolean;
  source: FileSource;
};
export type DeleteFileNodeAction = {
  type: 'delete';
  key: string;
};

export type TransferFileIntegrationAction = Omit<ActionNode, 'type'> & {
  type: 'integration';
};

export type TransferFileAssistantAction = {
  type: 'assistant';
  fileKey: StringValue;
};

export type TransferFileNodeAction = {
  type: 'transfer';
  key: string;
  action: TransferFileIntegrationAction | TransferFileAssistantAction;
};
export type ListFileNodeAction = {
  type: 'list';
  keyPrefix: string;
  next?: string;
};

export type ChangeAccessibilityFileNodeAction = {
  type: 'change-accessibility';
  key: string;
  publicAccess: boolean;
};

export type FileNodeAction =
  | PutFileNodeAction
  | DeleteFileNodeAction
  | TransferFileNodeAction
  | ListFileNodeAction
  | ChangeAccessibilityFileNodeAction;
export interface FileNode extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.file;
  nodeOperation: FileNodeAction;
  state: WorkflowState;
}

export interface FileNodeSummary extends NodeBase {
  type: typeof NodeUnionTypeEnumLike.file;
  nodeOperation: Pick<FileNodeAction, 'type'>;
}

export type FileNodeExtraData = {
  nodeOperation:
    | Pick<ListFileNodeAction, 'type'>
    | Pick<PutFileNodeAction, 'type'>
    | Pick<ChangeAccessibilityFileNodeAction, 'type'>
    | Pick<DeleteFileNodeAction, 'type'>
    | Pick<TransferFileNodeAction, 'type'>;
};

export type NodeUnion =
  | ActionNode
  | BranchJunctionNode
  | WorkflowConditionNode
  | LoopNode
  | BlankNode
  | FunctionNode
  | OutputNode
  | ResponseNode
  | SendEmailNode
  | WorkFlowDispatcherNode
  | WorkflowResolverNode
  | QANode
  | AssistantNode
  | GetConversationNode
  | DelayNode
  | ActionerEventNode
  | JobNode
  | FileNode
  | HTTPNode
  | LinkGeneratorNode
  | AssistantCancelRunNode;
