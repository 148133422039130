import { NvButton, NvCloseIcon, NvFlex, NvTypography } from '@novaera/core';
import React from 'react';
import { GroupOrPermissionPolicyCardWrapper } from './styled';

export const GroupOrPermissionPolicyCard: React.FC<{
  logo?: React.ReactElement;
  name: string;
  onDelete?: () => void;
}> = ({ logo, name, onDelete }) => (
  <GroupOrPermissionPolicyCardWrapper>
    <NvFlex direction="row" alignItems="center" gap="8px">
      {logo}
      <NvTypography variant="h4">{name}</NvTypography>
    </NvFlex>
    {!!onDelete && (
      <NvButton size="small" color="ghost" onlyIcon onClick={onDelete}>
        <NvCloseIcon />
      </NvButton>
    )}
  </GroupOrPermissionPolicyCardWrapper>
);
