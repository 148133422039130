import classnames from 'classnames';
import { useMemo } from 'react';

import { getInitials } from '@novaera/utils';

import { GradientMain, InitialContainer, InitialMain, InitialOpacity } from './styled';
import { InitialProps } from './types';

import { NvTypography } from '../typography';

export const Initial: React.FC<React.PropsWithChildren<InitialProps>> = ({ size, color, value }) => {
  const initialValue = useMemo(() => getInitials(value), [value]);
  const calculatedSize = useMemo(() => (size === undefined ? 'small' : size), [size]);
  const fontSize = useMemo(
    () =>
      calculatedSize === 'smaller'
        ? '12px'
        : calculatedSize === 'small'
        ? '16px'
        : calculatedSize === 'large'
        ? '22px'
        : calculatedSize === 'xlarge'
        ? '31px'
        : '16px',
    [calculatedSize]
  );

  return (
    <InitialContainer className={classnames(calculatedSize)}>
      <GradientMain gradientColor={color} />
      <InitialMain>
        <NvTypography fontSize={fontSize} variant="h3">
          {initialValue}
        </NvTypography>
      </InitialMain>
      <InitialOpacity>
        <NvTypography fontSize={fontSize} variant="h3">
          {initialValue}
        </NvTypography>
      </InitialOpacity>
    </InitialContainer>
  );
};
