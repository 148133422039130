import { WorkspaceStatus } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import React from 'react';
import { TitleFlex, WelcomeMessageFlex } from '../../styled';
import { CHOOSE_WORKSPACE_TITLE } from './constants';
import { useWorkspaceList } from './controllers/use-workspace-list';
import { WorkspaceListItem } from './item';
import { redirectToWorkspace } from './utils';

export const WorkspaceList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { workspaces } = useWorkspaceList();

  if (!workspaces || workspaces.length === 0) {
    return null;
  }

  const handleWorkspaceListItemClick = (params: { subdomain?: string; displayName?: string }) => {
    assert(Boolean(params?.subdomain), new Error('subdomain is required'), 'ERROR');

    if (params.subdomain) {
      redirectToWorkspace(params.subdomain);
    }
  };

  return (
    <>
      <TitleFlex>
        <NvTypography variant="h1">{CHOOSE_WORKSPACE_TITLE}</NvTypography>
      </TitleFlex>
      <WelcomeMessageFlex>
        <NvTypography variant="body1" textColor="subtle">
          Choose a workspace to get back to running actions.
        </NvTypography>
      </WelcomeMessageFlex>
      <NvFlex sx={{ marginTop: '24px', rowGap: '16px' }}>
        {workspaces?.map((workspace) => (
          <NvFlex key={`workspace_list_item_${workspace.subdomain}`}>
            <WorkspaceListItem
              isSuspended={workspace.status === WorkspaceStatus.DEACTIVATED}
              {...workspace}
              key={workspace.displayName}
              onClick={handleWorkspaceListItemClick}
            />
          </NvFlex>
        ))}
      </NvFlex>
    </>
  );
};
