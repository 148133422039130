import { AccountCircleIcon, NvFlex, NvTypography } from '@novaera/core';
import { MicroImage } from './styled';
import { JobCreatorUserProps } from './types';

export const JobCreatorUserItem: React.FC<React.PropsWithChildren<JobCreatorUserProps>> = ({ creator }) => (
  <NvFlex direction="row" alignItems="center" gap="8px">
    <MicroImage src={creator.logoUrl} FallBack={<AccountCircleIcon sx={{ width: '16px', height: '16px' }} />} />
    <NvTypography>{creator.displayName}</NvTypography>
  </NvFlex>
);
