import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { PublishAppSchemaParams, PublishAppSchemaResponse } from './types';

const publishAppSchema = async (params: PublishAppSchemaParams) => {
  const result = await NvAxios.post<PublishAppSchemaResponse>(`${APP_DIRECTORY_ROOT_PATH}/schemas`, params);
  return result?.data;
};

export const usePublishAppSchema = () => {
  const cache = useQueryClient();
  return useMutation(publishAppSchema, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS_APP_DIRECTORY.lists());
    },
  });
};
