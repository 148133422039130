import { useTheme } from '@novaera/theme-provider';
import React, { ReactElement } from 'react';
import { NvFlex } from '../flex';
import { NvTypography } from '../typography';
import { IconLabelProps } from './types';

export const NvIconLabel: React.FC<IconLabelProps> = ({ icon, label, labelTypographyProps, className, sx }) => {
  const theme = useTheme();
  return (
    <NvFlex flexDirection="row" columnGap="12px" alignItems="center" sx={sx} className={className}>
      <NvFlex justifyContent="center">
        {React.isValidElement(icon) &&
          React.cloneElement(icon as ReactElement, {
            sx: {
              width: '32px',
              height: '32px',
              color: theme.palette.nv_neutral_alpha[600],
            },
          })}
      </NvFlex>
      <NvFlex>
        <NvTypography variant="h1" noWrap {...labelTypographyProps}>
          {label}
        </NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
