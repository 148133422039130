import { AccessType } from '@novaera/actioner-service';
import {
  NvAutocomplete,
  NvAutocompleteProps,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { Optional } from '@novaera/utils';
import { UserAppAccessUsersToCreate } from '../../user-app/user-app-detail/permissions/add-package-access-user-modal/types';
import { WorkflowUserToCreatePermission } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-permissions/body/types';
import { UserAndGroupImage } from '../user-and-group-image';
import { UserSelectMenu, UserSelectOption } from './styled';

export function UserAndGroupSearchAutoComplete<
  T extends WorkflowUserToCreatePermission | UserAppAccessUsersToCreate,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  options,
  value,
  onChange,
  getOptionPrincipal,
  ...props
}: Optional<NvAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  getOptionPrincipal: (option: T) => { type: AccessType; id?: string };
}) {
  return (
    <NvAutocomplete
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={(option) => (typeof option !== 'string' ? option.name : option)}
      renderInput={(props) => <NvTextField rows={1} {...props} placeholder="Search" />}
      clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
      popupIcon={<NvExpandMoreIcon />}
      PaperComponent={UserSelectMenu}
      groupBy={(option) => {
        const { type } = getOptionPrincipal(option);
        return type === AccessType.GROUP ? 'Groups' : 'Users';
      }}
      renderOption={(props, option) => {
        const { id, type } = getOptionPrincipal(option);
        return (
          <UserSelectOption {...props} key={JSON.stringify(option)}>
            {type === AccessType.GROUP ? (
              <UserAndGroupImage src={option.logoUrl} type={AccessType.GROUP} id={id} />
            ) : (
              <UserAndGroupImage src={option.logoUrl} type={AccessType.USER} />
            )}
            <NvTypography variant="h4">{option.name}</NvTypography>
          </UserSelectOption>
        );
      }}
      {...props}
    />
  );
}
