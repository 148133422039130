import { RecordItem, useBatchDeleteRecords, useGetDataModel, useSearchRecords } from '@novaera/actioner-service';
import { GridRowId, GridSelectionModel, GridSortModel, NvButton, NvTypography, useConfirmDialog } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useCallback, useMemo, useRef, useState } from 'react';
import { getRecordColumns } from '../../../../../../../components/records-table/utils';
import { RECORD_LIMIT } from '../../constants';

export const useRecordsDetail = () => {
  const { userAppId: appId, modelId } = useParams();
  const searchQueryKeywordRef = useRef('');
  const [query, setQuery] = useState<string>('');
  const [searchQueryKeyword, setSearchQueryKeyword] = useState<string>('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [page, setPage] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [isRecordModalOpen, setIsRecordModalOpen] = useState<boolean>(false);
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const { dataModel: model, isLoading: isModelLoading } = useGetDataModel({ appId, modelId, retry: 3 });

  const [selectedRecord, setSelectedRecord] = useState<RecordItem | undefined>();

  const nextPageOffset = useMemo(() => (page > 0 ? page * RECORD_LIMIT : null), [page]);
  const sortParams = useMemo(() => {
    if (sortModel.length > 0) {
      const sortParameter = sortModel[0];
      const order = sortParameter.sort as 'asc' | 'desc';
      const fieldNames = [sortParameter.field] as string[];
      return { order, fieldNames };
    } else {
      return null;
    }
  }, [sortModel]);

  const queryParams = useMemo(
    () => ({
      query,
      limit: RECORD_LIMIT,
      ...(nextPageOffset ? { nextPageOffset } : {}),
      ...(sortParams ? { sortParams } : {}),
    }),
    [query, nextPageOffset, sortParams]
  );

  const {
    data: searchRecordsResult,
    refetch: refetchSearchRecordsResult,
    isLoading: isSearchRecordsLoading,
    isRefetching: isSearchRecordsRefetching,
  } = useSearchRecords({
    appId,
    modelId,
    queryParams,
  });

  const { mutateAsync: batchDeleteRecords, isLoading: isBatchDeleteRecordsLoading } = useBatchDeleteRecords();

  const handleRecordEditClick = (record: RecordItem) => {
    setSelectedRecord(record);
    setIsRecordModalOpen(true);
  };

  const handleRecordModelClose = () => {
    setIsRecordModalOpen(false);
    setSelectedRecord(undefined);
  };

  const columns = useMemo(() => {
    return getRecordColumns<RecordItem>(model, {
      onRecordEditClick: handleRecordEditClick,
    });
  }, [model]);

  const rows = useMemo(
    () =>
      searchRecordsResult?.records.map(({ attributes, ...restOfRecord }) => ({
        ...restOfRecord,
        muiDataGridActionsField: restOfRecord,
      })) ?? [],
    [searchRecordsResult?.records]
  );
  const totalHits = useMemo(() => searchRecordsResult?.totalHits, [searchRecordsResult?.totalHits]);

  const handleDeleteRecords = useCallback(() => {
    openConfirm({
      title: 'Delete records?',
      message: (
        <NvTypography variant="body1">
          <strong>{selectedRowIds.length} records</strong> will no longer be available. If there are other tables depend
          on <strong>{model?.name}</strong>, some records in such tables may be also impacted.
        </NvTypography>
      ),
      confirmButton: (
        <NvButton
          variant="contained"
          size="medium"
          color="error"
          loading={isBatchDeleteRecordsLoading}
          disabled={isBatchDeleteRecordsLoading}
          onClick={async () => {
            try {
              await batchDeleteRecords({ appId, modelId, recordIds: selectedRowIds as string[] });
              handleSelectionChange([]);
              closeConfirm();
              // eslint-disable-next-line no-empty
            } catch (error) {}
          }}
        >
          Delete
        </NvButton>
      ),
    });
  }, [
    appId,
    batchDeleteRecords,
    closeConfirm,
    isBatchDeleteRecordsLoading,
    model?.name,
    modelId,
    openConfirm,
    selectedRowIds,
  ]);

  const handleSearchChange = (value: string) => {
    searchQueryKeywordRef.current = value;
    setSearchQueryKeyword(value);
  };

  // it has type any on codemirror too.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchEnter = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      setQuery(searchQueryKeywordRef.current);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleSelectionChange = (value: GridSelectionModel) => {
    setSelectedRowIds(value);
  };

  const handlePageChange = (page: number) => {
    if (isSearchRecordsLoading || isSearchRecordsRefetching) {
      return;
    }
    setPage(page);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (isSearchRecordsLoading || isSearchRecordsRefetching) {
      return;
    }
    setSortModel(newSortModel);
  };

  const handleRefresh = useCallback(() => {
    if (modelId) {
      refetchSearchRecordsResult();
    }
  }, [modelId, refetchSearchRecordsResult]);

  return {
    modelId,
    model,
    rows,
    columns,
    totalHits,
    isModelLoading,
    isSearchRecordsLoading,
    searchQueryKeyword,
    handleSearchChange,
    handleSearchEnter,
    selectedRowIds,
    handleSelectionChange,
    handleDeleteRecords,
    page,
    handlePageChange,
    isSearchRecordsRefetching,
    handleRefresh,
    handleSortModelChange,
    refetchSearchRecordsResult,
    isRecordModalOpen,
    setIsRecordModalOpen,
    selectedRecord,
    handleRecordModelClose,
  };
};
