import { APP_LOADING_ANIMATION_DATA } from '@novaera/ah-common';
import { NvBox, NvLottie } from '@novaera/core';
import { AppLoadingWrapper } from './styled';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: APP_LOADING_ANIMATION_DATA,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const AppLoading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <NvBox
      position="fixed"
      left="0"
      right="0"
      top="0"
      bottom="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <AppLoadingWrapper>
        <NvLottie options={animationOptions} width="150px" height="150px" speed={0} />
      </AppLoadingWrapper>
    </NvBox>
  );
};
