import { SvgIconProps } from '@mui/material';
import { NvDivider, NvSkeleton } from '@novaera/core';
import { Header } from '../header';
import { NavigationWrapper } from '../styled';

export type InPageSideMenuLoadingSkeletonProps = {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
};

export const InPageSideMenuLoadingSkeleton = ({ icon, title }: InPageSideMenuLoadingSkeletonProps) => {
  return (
    <NavigationWrapper>
      <Header icon={icon} title={title} />
      <NvSkeleton variant="rectangular" height="32px" width={'100%'} />
      <NvDivider orientation="horizontal" />
      <NvSkeleton variant="rectangular" height="32px" width={'100%'} />
      <NvSkeleton variant="rectangular" height="32px" width={'60%'} />
      <NvSkeleton variant="rectangular" height="32px" width={'80%'} />
    </NavigationWrapper>
  );
};
