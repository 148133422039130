import { Subscription, Workspace } from '@novaera/actioner-service';
import { NvField, NvFlex, NvTypography } from '@novaera/core';
import { formatDistanceToNow } from 'date-fns';
import { StyledNvNumberInput } from './styled';

export const WorkspaceSubscriptionExtendModal = ({
  workspace,
  workspaceSubscription,
}: {
  workspace: Workspace;
  workspaceSubscription: Subscription;
}) => {
  return (
    <NvFlex gap={'8px'}>
      {workspaceSubscription?.periodEnd && (
        <NvTypography>
          <b>{workspaceSubscription?.planType}</b> plan for <b>{workspace.displayName}</b> ends{' '}
          <b>{formatDistanceToNow(new Date(workspaceSubscription.periodEnd), { addSuffix: true })}</b>.
        </NvTypography>
      )}

      <NvField
        formControlStyle={{
          width: '100%',
        }}
        format={(value) => {
          const millisecondsInADay = 1000 * 60 * 60 * 24;
          return value / millisecondsInADay;
        }}
        parse={(value) => {
          const millisecondsInADay = 1000 * 60 * 60 * 24;
          return value * millisecondsInADay;
        }}
        name={'amountInMillis'}
        labelText="Days to extend"
        direction="label-on-top"
        component={<StyledNvNumberInput />}
      />
    </NvFlex>
  );
};
