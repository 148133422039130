import { SchemaType } from '@novaera/ah-common';

export const SCHEMA_TYPE_HELPS: Record<SchemaType, string> = {
  [SchemaType.string]: 'Text type allows you to enter any text input for this field.',
  [SchemaType.email]:
    'Email type allows you to enter an email address. You cannot provide a value that doesn’t match the proper email format.',
  [SchemaType.url]:
    'Link type allows you to enter a URL. You cannot provide a value that doesn’t match the proper URL format.',
  [SchemaType.phone]:
    'Phone number type allows you to enter a phone number. You cannot provide a value that doesn’t match the proper phone number format.',
  [SchemaType.uuid]: '',
  [SchemaType.dateTime]:
    'Date-time type allows you to enter a time value in the selected format. You cannot provide a value that doesn’t match the selected format.',
  [SchemaType.boolean]:
    'Boolean type allows you to enter true/false values. You cannot provide a value in non-boolean format.',
  [SchemaType.array]:
    'List type allows you to enter a list of values in the selected item type. You cannot provide a value that doesn’t match the selected item type.',
  [SchemaType.map]:
    'Map type allows you to enter a map of values in the selected item type. You cannot provide a value that doesn’t match the selected item type.',
  [SchemaType.integer]:
    'Integer type allows you to enter numbers. You cannot provide a value that doesn’t match the integer format.',
  [SchemaType.float]: 'Decimal type allows you to enter numbers in decimal format. ',

  [SchemaType.recordReference]:
    'Reference fields allow you to refer to a model in your app. The value of this field depends on the selected reference model.',
  [SchemaType.object]:
    'Object type allows you to enter a object values in the selected item type. You cannot provide a value that doesn’t match the selected item type.',
};
