import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_WORKFLOW_FORM_PARAMETERS } from '../keys';
import { GetFormParameters, GetFormParametersResponse } from '../types';

const getFormParameters = async ({ appId, workflowId, payload, onSuccess }: GetFormParameters) => {
  const result = await NvAxios.post<GetFormParametersResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/form-parameters`,
    payload
  );

  onSuccess?.(result?.data);
  return result?.data;
};

const getQueryKey = (params: GetFormParameters) => {
  const { appId, workflowId, payload } = params;
  return {
    appId,
    workflowId,
    payload: {
      draft: payload.draft,
      changedParameterId: payload.changedParameterId,
      parameterMappings: payload.parameterMappings,
    },
  };
};

export const useGetFormParameters = (param: GetFormParameters) => {
  return useQuery<GetFormParametersResponse>(
    QUERY_KEYS_WORKFLOW_FORM_PARAMETERS.formParameters(getQueryKey(param)),
    () => getFormParameters(param),
    {
      enabled: !!param.appId && !!param.workflowId,
      keepPreviousData: true,
    }
  );
};
