import { AllConstraints } from '@novaera/actioner-service';
import { ConstraintType, SchemaType } from '@novaera/ah-common';
import { CollapsibleBySwitch, NvBox, NvField, NvFlex, NvNumberTextField, NvTextField, useField } from '@novaera/core';
import { FC, memo, useCallback } from 'react';
import { BIGINT_MAX_JAVA } from '../../../../../../common/constants';

const ConstraintItemOriginal: FC<
  React.PropsWithChildren<{ fieldName: string; constraint: AllConstraints; schemaType: SchemaType; disabled?: boolean }>
> = ({ fieldName, constraint, schemaType, disabled }) => {
  const {
    input: { value, onChange },
  } = useField(`${fieldName}.enabled`);

  const isMapOrList = [SchemaType.array, SchemaType.map].includes(schemaType);

  const getConstraintByType = useCallback(
    (constraintType: ConstraintType): JSX.Element => {
      switch (constraintType) {
        case ConstraintType.regex:
          return (
            <NvField
              name={`${fieldName}.pattern`}
              component={<NvTextField placeholder="Enter the regex expression" size="small" />}
              isDisabled={disabled}
            />
          );
        case ConstraintType.listSize:
        case ConstraintType.mapSize:
        case ConstraintType.stringSize:
          return (
            <NvFlex gap="12px">
              <NvBox maxWidth="400px">
                <NvField
                  name={`${fieldName}.min`}
                  direction="label-on-side"
                  labelVariant="h6"
                  labelText={isMapOrList ? 'Min size' : 'Min length'}
                  parse={(value) => (value ? parseInt(value) : value)}
                  component={
                    <NvNumberTextField size="small" type="number" inputProps={{ max: BIGINT_MAX_JAVA, min: 0 }} />
                  }
                  isDisabled={disabled}
                />
              </NvBox>
              <NvBox maxWidth="400px">
                <NvField
                  name={`${fieldName}.max`}
                  direction="label-on-side"
                  labelVariant="h6"
                  labelText={isMapOrList ? 'Max size' : 'Max length'}
                  parse={(value) => (value ? parseInt(value) : value)}
                  component={
                    <NvNumberTextField size="small" type="number" inputProps={{ max: BIGINT_MAX_JAVA, min: 0 }} />
                  }
                  isDisabled={disabled}
                />
              </NvBox>
            </NvFlex>
          );
        default:
          return <>Undefined constraint</>;
      }
    },
    [disabled, fieldName, isMapOrList]
  );

  const getConstraintTitleByType = useCallback((constraintType: ConstraintType): string => {
    switch (constraintType) {
      case ConstraintType.regex:
        return 'Set regular expression';
      case ConstraintType.listSize:
      case ConstraintType.mapSize:
        return 'Set constraint size';
      case ConstraintType.stringSize:
        return 'Set constraint length';
      default:
        return '';
    }
  }, []);

  return (
    <CollapsibleBySwitch
      isOpen={!!value}
      onCollapseChanged={(_event, checked) => {
        onChange(checked);
      }}
      title={getConstraintTitleByType(constraint.type)}
      fields={getConstraintByType(constraint.type)}
      disabled={disabled}
    />
  );
};

export const ConstraintItem = memo(ConstraintItemOriginal);
