import { styled } from '@novaera/theme-provider';

import { DataGrid } from '@mui/x-data-grid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomDataGrid = styled(DataGrid)<{ selectedField?: string; rows: any }>`
  &.MuiDataGrid-root {
    background: ${({ theme }) => theme.palette.nv_neutral[0]};
    border-radius: 12px;
    border: none;
    opacity: 1;

    .MuiDataGrid-main {
      flex: 0 0 auto;
      border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
      border-bottom: none;
      border-radius: 13px 13px 0 0;

      .MuiDataGrid-columnHeaders {
        background: ${({ theme }) => theme.palette.nv_neutral[20]};
        border-radius: 12px 12px 0 0;
        border: none;

        .MuiDataGrid-columnHeader {
          border-right: none;
          height: 32px;

          & > * {
            position: relative;
            z-index: 2;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 1px);
            height: 100%;
            border-left: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
            border-right: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
            border-top: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
            border-bottom:  ${({ rows, theme }) =>
              rows.length > 0 ? 'none;' : `2px solid ${theme.palette.nv_main[40]};`}  
            z-index: 1;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            background-color: rgba(84, 172, 253, 0.03);
          }

          &:first-of-type {
            &::after {
              border-radius: 12px 0 0 0;
            }

            &:last-of-type {
              &::after {
                width: calc(100% - 2px);
                border-radius: 12px 12px 0 0;
              }
            }
          }
          &:last-of-type {
            &::after {
              width: calc(100% - 2px);
              border-radius: 0 12px 0 0;
            }
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
            border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
            z-index: 1;
            opacity: 1;
          }

          ${({ selectedField }) =>
            selectedField
              ? `&[data-field='${selectedField}'] {
                  &::after {
                    opacity: 1;
                  }
                }
                `
              : null}

          .MuiDataGrid-columnHeaderTitleContainer {
            padding: 0;
          }

          .MuiDataGrid-columnSeparator {
            display: none;
          }

          .MuiDataGrid-menuIcon {
            display: none;
          }

          .MuiDataGrid-iconButtonContainer {
            .MuiButtonBase-root {
              .MuiTouchRipple-root {
                display: none;
              }

              &:hover {
                background: transparent;
              }
            }
          }

          &:focus,
          &:focus-within {
            outline: none;
          }
        }
      }
    }

    .MuiDataGrid-virtualScrollerRenderZone {
      position: relative !important;
    }

    .MuiDataGrid-virtualScrollerContent {
      height: auto !important;
    }

    .MuiDataGrid-cell {
      position: relative;
      display: flex;
      align-items: flex-start;
      border-right: none;
      border-bottom: none;
      padding: 0 12px;
      cursor: pointer;
      line-height: unset !important;
      max-height: none !important;

      & > * {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 1px);
        height: 100%;
        border-left: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
        border-right: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
        z-index: 1;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        background-color: rgba(84, 172, 253, 0.03);
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
        border-right: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
        z-index: 1;
        opacity: 1;
      }

      &:focus,
      &:focus-within {
        outline: none;
      }

      &:last-of-type {
        &::after {
          width: calc(100% - 2px);
        }

        &::before {
          border-right: none;
          width: calc(100% - 1px);
        }
      }

      ${({ selectedField }) =>
        selectedField
          ? `&[data-field='${selectedField}'] {
            &::after {
              opacity: 1;
            }
          }`
          : null}
    }

    .MuiDataGrid-row {
      max-height: none !important;

      &.Mui-selected {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
        }
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
      }

      &:last-of-type {
        .MuiDataGrid-cell {
          &::after {
            content: '';
            border-bottom: 2px solid ${({ theme }) => theme.palette.nv_main[40]};
          }
        }
      }
    }

    .MuiDataGrid-footerContainer {
      background: ${({ theme }) => theme.palette.nv_neutral[10]};
      border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
      border-top: none;
      border-radius: 0 0 12px 12px;
      margin-bottom: 16px;
      min-height: 40px;
      justify-content: center;

      .MuiPagination-ul {
        li {
          margin: 0 2px;

          &:first-of-type {
            margin: 0 2px 0 0;
          }
          &:last-of-type {
            margin: 0 0 0 2px;
          }
        }
      }
    }
  }
`;

export const TableEmptyState = styled('div')`
  width: 100%;
  height: auto;
  margin: 32px 0 -32px 0;
  padding: 40px;
  flex: 0 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
`;
