import { NvCustomGiftIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC, ReactNode, useMemo } from 'react';
import { VerifiedIcon } from '../styled';
import { FeaturedSectionBox } from './styled';
import { FeaturedSectionProps } from './types';

export const FeaturedSection: FC<FeaturedSectionProps> = ({ appSchema }) => {
  const { palette } = useTheme();
  const result: { isShown: false } | { isShown: true; icon: ReactNode; text: string } = useMemo(() => {
    if (appSchema.free) {
      return {
        isShown: true,
        icon: <NvCustomGiftIcon htmlColor={palette.nv_new[60]} sx={{ width: '16px', height: '16px' }} />,
        text: 'Free app',
      };
    } else if (appSchema.featured) {
      return { isShown: true, icon: <VerifiedIcon htmlColor={palette.nv_neutral[600]} />, text: 'Staff pick' };
    } else {
      return { isShown: false };
    }
  }, [appSchema.featured, appSchema.free, palette.nv_neutral, palette.nv_new]);

  return result.isShown ? (
    <FeaturedSectionBox>
      {result.icon}
      <NvTypography textColor="secondary" variant="h5">
        {result.text}
      </NvTypography>
    </FeaturedSectionBox>
  ) : null;
};
