import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { DATA_MODEL_ROOT_PATH } from '../../data-model/constants';
import { QUERY_KEYS_RECORDS } from '../keys';
import { SearchRecordsResponse } from '../use-search-records/types';
import { CreateRecordParams, CreateRecordResponse } from './types';

const createRecord: (params: CreateRecordParams) => Promise<CreateRecordResponse> = async (params) => {
  const { appId, modelId, body } = params;
  const result = await NvAxios.post<CreateRecordResponse>(`${DATA_MODEL_ROOT_PATH(appId)}/${modelId}/records`, body);
  return result?.data;
};

export const useCreateRecord = () => {
  const queryClient = useQueryClient();
  return useMutation(createRecord, {
    onSuccess: (getRecordResponse, { appId, modelId }) => {
      const allCachedSearchRecords = queryClient.getQueriesData<SearchRecordsResponse>(
        QUERY_KEYS_RECORDS.detail({ appId, modelId })
      );
      allCachedSearchRecords.forEach(([cacheKey, cachedSearchRecords]) => {
        if (cachedSearchRecords) {
          const updatedCachedSearchRecords = {
            ...cachedSearchRecords,
            ...(cachedSearchRecords.records.length > 0
              ? {
                  records: [...cachedSearchRecords.records, getRecordResponse],
                  totalHits: cachedSearchRecords.totalHits + 1,
                }
              : { records: [getRecordResponse], totalHits: 1 }),
          };
          queryClient.setQueryData(cacheKey, updatedCachedSearchRecords);
        }
      });
    },
  });
};
