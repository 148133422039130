import { NvDivider, NvFlex, NvTypography, NvVerticalActionerLogo } from '@novaera/core';
import { useWorkspaceMenuController } from '../navigation-menu/controllers/use-workspace-menu-controller';
import { NavigationContextProvider } from '../navigation-menu/nav-context';
import { ProfileMenuItem } from '../navigation-menu/profile-menu-item';
import { WorkspaceInitial } from '../workspace-item/initial';
import { ProfileMenuItemWrapper, StyledBar } from './styled';

export const MenuBar = () => {
  const { selectedWorkspace } = useWorkspaceMenuController();

  return (
    <NavigationContextProvider>
      <StyledBar>
        <NvFlex flexDirection={'row'} gap={'16px'} alignItems={'center'} flex={'1 1 auto'} minWidth={'0'}>
          <NvVerticalActionerLogo />
          <NvDivider
            sx={{
              height: '14px',
            }}
            orientation="vertical"
          />
          {selectedWorkspace && (
            <NvFlex flexDirection={'row'} alignItems={'center'} gap={'8px'}>
              <WorkspaceInitial
                subdomain={selectedWorkspace.subdomain}
                displayName={selectedWorkspace.displayName}
                size="small"
              />
              <NvTypography variant="h4">{selectedWorkspace.displayName}</NvTypography>
            </NvFlex>
          )}
        </NvFlex>
        <NvFlex flex={'0 0 auto'}>
          <ProfileMenuItem
            profileImageSize="small"
            showName={false}
            MenuItemWrapperComponent={ProfileMenuItemWrapper}
            hasIndicator={false}
          />
        </NvFlex>
      </StyledBar>
    </NavigationContextProvider>
  );
};
