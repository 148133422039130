import { NvAxios, useQuery } from '@novaera/core';
import { CATALOG_ENTITIES_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_ENTITIES } from '../keys';

import { SearchCatalogEntitiesParams, SearchCatalogEntitiesResponse } from './types';

const searchCatalogEntities = async (params: SearchCatalogEntitiesParams) => {
  const { entityTypeId, ...body } = params;
  const result = await NvAxios.post<SearchCatalogEntitiesResponse>(
    `${CATALOG_ENTITIES_ROOT_PATH()}/${entityTypeId}/search`,
    body
  );
  return result?.data;
};

export const useSearchCatalogEntities = (params: SearchCatalogEntitiesParams) => {
  const { entityTypeId, ...queryParams } = params;
  return useQuery<SearchCatalogEntitiesResponse>(QUERY_KEY_CATALOG_ENTITIES.list(entityTypeId, queryParams), () =>
    searchCatalogEntities({
      ...params,
    })
  );
};
