import { styled } from '@novaera/theme-provider';
import { NvSkeleton } from '../skeleton';

export const StyledImage = styled('img')`
  display: block;

  &.is-loading {
    display: none;
  }
`;

export const ImageWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  flex: 0 0 auto;
`;
export const ImageBox = styled('div', { shouldForwardProp: (prop) => prop !== 'hasNoElevation' })<{
  hasNoElevation?: boolean;
}>`
  ${({ theme, hasNoElevation }) => (!hasNoElevation ? theme.elevations.e100 : null)};

  &.package-image {
    background: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
  }

  &.size-small {
    width: 24px;
    height: 24px;
    border-radius: 12px;

    &.square-image {
      border-radius: 6px;
    }
  }

  &.size-smaller {
    width: 20px;
    height: 20px;
    border-radius: 10px;

    &.square-image {
      border-radius: 6px;
    }
  }

  &.size-xsmall {
    width: 16px;
    height: 16px;
    border-radius: 10px;

    &.square-image {
      border-radius: 6px;
    }
  }

  &.size-medium {
    width: 32px;
    height: 32px;
    border-radius: 16px;

    &.square-image {
      border-radius: 8px;
    }
  }

  &.size-large {
    width: 40px;
    height: 40px;
    border-radius: 20px;

    &.square-image {
      border-radius: 12px;
    }
  }

  &.size-xlarge {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    &.square-image {
      border-radius: 12px;
    }
  }

  &.no-border-radius {
    border-radius: 0 !important;
  }

  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`;

export const SkeletonWrapper = styled(NvSkeleton)`
  width: 100%;
  height: 100%;
  transform: scale(1, 1);
`;
