import { NvAddBoxIcon, NvButton, NvFlex } from '@novaera/core';
import { SimpleSearchInput } from '../../simple-search-input';

export type SearchBarProps = {
  onSearchKeywordChanged: (keyword?: string) => void;
  onAddClicked: () => void;
  hasPermissionToAdd?: boolean;
};

export const SearchBar = ({ onSearchKeywordChanged, onAddClicked, hasPermissionToAdd }: SearchBarProps) => {
  return (
    <NvFlex gap={'8px'} flexDirection={'row'}>
      <SimpleSearchInput fullWidth size="small" onKeywordChanged={onSearchKeywordChanged} />
      {hasPermissionToAdd && (
        <NvButton onlyIcon size="medium" color="secondary" onClick={onAddClicked}>
          <NvAddBoxIcon />
        </NvButton>
      )}
    </NvFlex>
  );
};
