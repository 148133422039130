import React, { cloneElement, SyntheticEvent } from 'react';

import { FormControl } from '@mui/material';
import { composeValidators, Validator } from '@novaera/utils';
import { Field, FieldInputProps } from 'react-final-form';
import { NvConditionalWrap } from '../conditional-wrap';
import { NvFlex } from '../flex';
import { NvTypography } from '../typography';
import { FormHelperErrorText } from './form-helper-error-text';
import { FormHelperWarningText } from './form-helper-warning-text';
import { FieldTitle } from './title';
import { FieldComponentCommonProps, FieldProps } from './types';

export const NvField = <V,>({
  name,
  component,
  initialValue,
  format,
  parse,
  formatOnBlur,
  type,
  validators,
  defaultValue,
  isAutoComplete,
  error,
  labelText,
  direction,
  labelVariant,
  hint,
  hasRequiredIndicator,
  hasHiddenIndicator,
  allowNull,
  showError = true,
  infoText,
  labelWidth = '90px',
  formControlStyle = {},
  hideLabel,
  defaultDisplayValue,
  validateForWarnings,
  isDisabled,
  wrapperClassName,
  ...fieldProps
}: FieldProps<V>) => {
  return (
    <NvConditionalWrap
      condition={Boolean(labelText)}
      wrap={(children) => (
        <NvFlex
          className={wrapperClassName}
          direction={direction === 'label-on-top' ? 'column' : 'row'}
          alignItems={direction === 'label-on-top' ? 'left' : 'flex-start'}
          gap={direction !== 'label-on-top' ? '8px' : undefined}
        >
          {!hideLabel && (
            <FieldTitle
              direction={direction}
              hasRequiredIndicator={hasRequiredIndicator}
              hasHiddenIndicator={hasHiddenIndicator}
              infoText={infoText}
              labelText={labelText}
              labelWidth={labelWidth}
              labelVariant={labelVariant}
              defaultDisplayValue={defaultDisplayValue}
            />
          )}
          {children}
        </NvFlex>
      )}
    >
      <Field<V>
        name={name}
        initialValue={initialValue}
        validate={composeValidators(validators)}
        format={format}
        parse={parse}
        formatOnBlur={formatOnBlur}
        type={type}
        defaultValue={defaultValue}
        allowNull={allowNull}
        disabled={isDisabled}
        {...fieldProps}
        subscription={{ touched: true, error: true, value: true, dirty: true }}
      >
        {({ meta, input }): React.ReactElement => {
          const { error: metaError, touched } = meta;
          const hasError = fieldProps.showErrorMessageOnlyWhenBlur
            ? touched && (!!error || !!metaError)
            : !!error || !!metaError;

          const shownError = metaError || error;
          const errorText = typeof shownError === 'object' ? JSON.stringify(shownError) : shownError;

          const baseComponentProps: FieldInputProps<V, HTMLElement> &
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Pick<FieldComponentCommonProps<V>, 'onChange'> & { error?: any; disabled?: boolean } = {
            ...input,
            ...(touched || !fieldProps.showErrorMessageOnlyWhenBlur ? { error } : { error: undefined }),
            defaultValue,
            disabled: isDisabled,
          };

          const defaultComponentProps: FieldInputProps<V, HTMLElement> &
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Pick<FieldComponentCommonProps<V>, 'onChange'> & { error?: any } = {
            ...baseComponentProps,
            onChange: (v) => {
              input.onChange(v);
            },
            error: hasError,
          };

          const autoCompleteComponentProps: Omit<typeof baseComponentProps, 'onChange'> & {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: any;
          } = {
            ...baseComponentProps,
            onChange: (e: SyntheticEvent<Element, Event>, v: V) => {
              input.onChange(v);
            },
            error: hasError,
          };

          const warnings = input.value
            ? validateForWarnings?.reduce(
                (error: string | undefined | Promise<string | undefined>, validator: Validator) => {
                  return error ?? validator(input.value, input.name, input);
                },
                undefined
              )
            : undefined;

          return (
            <FormControl
              error={hasError}
              sx={{
                width: 'auto',
                flex: '1 1 0',
                minWidth: 0,
                ...(formControlStyle ?? {}),
              }}
              className={fieldProps.className}
              disabled={isDisabled}
            >
              {typeof component === 'function'
                ? component(isAutoComplete ? autoCompleteComponentProps : defaultComponentProps)
                : cloneElement(component, isAutoComplete ? autoCompleteComponentProps : defaultComponentProps)}
              {hint && !hasError && (
                <NvTypography marginTop="4px" marginLeft={'2px'} variant="body3">
                  {hint}
                </NvTypography>
              )}
              {showError && hasError && <FormHelperErrorText error={errorText} />}
              {warnings && (
                <FormHelperWarningText error={typeof warnings === 'object' ? JSON.stringify(warnings) : warnings} />
              )}
            </FormControl>
          );
        }}
      </Field>
    </NvConditionalWrap>
  );
};
