import * as React from 'react';
import { BasicTable, BasicTableBody, BasicTableContainer, BasicTableFooter, BasicTableHead } from './styled';
import { BasicTableProps } from './types';

export const NvBasicTable: React.FC<React.PropsWithChildren<BasicTableProps>> = ({
  tableHeader,
  tableBody,
  tableFooter,
  className,
}) => {
  return (
    <BasicTableContainer className={className}>
      <BasicTable>
        {tableHeader && <BasicTableHead>{tableHeader}</BasicTableHead>}
        <BasicTableBody>{tableBody}</BasicTableBody>
        {tableFooter && <BasicTableFooter>{tableFooter}</BasicTableFooter>}
      </BasicTable>
    </BasicTableContainer>
  );
};
