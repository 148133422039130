import { AppDocument } from '@novaera/actioner-service';
import {
  FieldArray,
  isRequired,
  isValidUrl,
  NvAddBoxIcon,
  NvButton,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { lowerCase } from 'lodash';
import { FC } from 'react';
import { DragDropList } from '../../../../../components/drag-drop-list';
import { AppDocumentsProps } from './types';

export const AppDocuments: FC<React.PropsWithChildren<AppDocumentsProps>> = ({
  fieldName,
  category,
  title,
  titleVariant = 'h5',
  addNewButtonPosition = 'bottom',
}) => (
  <FieldArray<AppDocument> name={fieldName}>
    {({ fields }) => (
      <NvFlex gap="8px" alignItems="flex-start" width="100%">
        <NvFlex direction="row" alignItems="center" width="100%">
          <NvTypography variant={titleVariant} flex="1 1 auto" minWidth={0}>
            {title}
          </NvTypography>
          {addNewButtonPosition === 'top' && (
            <NvButton
              size="small"
              color="secondary"
              startIcon={<NvAddBoxIcon />}
              onClick={() => {
                fields.push({ displayText: '', url: '', category });
              }}
            >
              {`Add ${lowerCase(category)} link`}
            </NvButton>
          )}
        </NvFlex>

        <DragDropList
          droppableId={`${category}.droppableId`}
          listItems={fields}
          onDragEnd={(sourceIndex, destination) => {
            fields.move(sourceIndex, destination);
          }}
          onItemRemoved={fields.remove}
          itemNode={(field, fieldName) => (
            <NvFlex direction="row" gap="8px" width="100%">
              <NvField
                name={`${fieldName}.displayText`}
                validators={[isRequired()]}
                component={<NvTextField placeholder="Link label" />}
                showErrorMessageOnlyWhenBlur
              />
              <NvField
                name={`${fieldName}.url`}
                validators={[isRequired(), isValidUrl()]}
                component={<NvTextField placeholder="Link" />}
                showErrorMessageOnlyWhenBlur
              />
            </NvFlex>
          )}
          getDraggableId={(item: AppDocument, index: number) => `${item.category}-${index}`}
          getItemValue={(index) => fields.value[index]}
        />
        {addNewButtonPosition === 'bottom' && (
          <NvButton
            size="small"
            color="secondary"
            startIcon={<NvAddBoxIcon />}
            onClick={() => {
              fields.push({ displayText: '', url: '', category });
            }}
          >
            {`Add ${lowerCase(category)} link`}
          </NvButton>
        )}
      </NvFlex>
    )}
  </FieldArray>
);
