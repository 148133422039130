import { FieldSchema } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import {
  NestedDropdownItem,
  NvAccessTimeRounded,
  NvCustomCheckboxGroupIcon,
  NvCustomCheckboxIcon,
  NvCustomDateTime,
  NvCustomMultiSelectFieldIcon,
  NvCustomSelectFieldIcon,
  NvCustomSingleSelectFieldIcon,
  NvCustomStringFieldIcon,
  NvCustomTextField,
  NvDynamicFormIcon,
  NvFormatSizeIcon,
  NvFunctionsIcon,
  NvListAltIcon,
  NvMailOutlineIcon,
  NvNotesIcon,
  NvOpenInNewIcon,
  NvPercentIcon,
  NvPhoneIcon,
  NvRadioButtonCheckedIcon,
  NvSlackIconNotColored,
  NvTagIcon,
  NvTitleIcon,
  NvTodayIcon,
} from '@novaera/core';
import { ExternalSchemaType, getInputSchema } from '../field-schema';
import {
  checkBoxComponent,
  checkBoxGroupComponent,
  datePickerComponent,
  dateTimePickerComponent,
  dynamicInputComponent,
  headerComponent,
  multiSelectComponent,
  plainTextComponent,
  radioButtonGroupComponent,
  singleSelectComponent,
  slackConversationPickerComponent,
  slackUserPickerComponent,
  textAreaComponent,
  textFieldComponent,
  timePickerComponent,
} from './component-instances';
import { InputMenuItemValue } from './types';

export const ADD_NEW_INPUT_MENU_ITEMS: NestedDropdownItem<InputMenuItemValue>[] = [
  {
    id: '1',
    name: 'Text fields',
    leftIcon: <NvCustomStringFieldIcon />,
    items: [
      {
        id: '2',
        name: 'Text',
        leftIcon: <NvCustomTextField />,
        value: {
          component: textFieldComponent,
          schema: getInputSchema(SchemaType.string),
          propertyTitle: {
            label: 'Text',
            icon: <NvCustomTextField />,
          },
        },
      },
      {
        id: 'textArea',
        name: 'Text area',
        leftIcon: <NvCustomTextField />,
        value: {
          component: textAreaComponent,
          schema: getInputSchema(SchemaType.string),
          propertyTitle: {
            label: 'Text area',
            icon: <NvCustomTextField />,
          },
        },
      },
      {
        id: '3',
        name: 'Email',
        leftIcon: <NvMailOutlineIcon />,
        value: {
          component: {
            ...textFieldComponent,
            label: 'Email',
          },
          schema: getInputSchema(SchemaType.email),
          propertyTitle: {
            label: 'Email',
            icon: <NvMailOutlineIcon />,
          },
        },
      },
      {
        id: '4',
        name: 'Link',
        leftIcon: <NvOpenInNewIcon />,
        value: {
          component: {
            ...textFieldComponent,
            label: 'Link',
          },
          schema: getInputSchema(SchemaType.url),
          propertyTitle: {
            label: 'Link',
            icon: <NvOpenInNewIcon />,
          },
        },
      },
      {
        id: '5',
        name: 'Phone number',
        leftIcon: <NvPhoneIcon />,
        value: {
          component: {
            ...textFieldComponent,
            label: 'Phone number',
          },
          schema: getInputSchema(SchemaType.phone),
          propertyTitle: {
            label: 'Phone number',
            icon: <NvPhoneIcon />,
          },
        },
      },
      {
        id: '21',
        name: 'Map',
        leftIcon: <NvListAltIcon />,
        value: {
          component: {
            ...textFieldComponent,
            label: 'Map',
          },
          schema: getInputSchema(SchemaType.map),
          propertyTitle: {
            label: 'Map',
            icon: <NvListAltIcon />,
          },
        },
      },
    ],
  },
  {
    id: '7',
    name: 'Numbers fields',
    leftIcon: <NvTagIcon />,
    items: [
      {
        id: '8',
        name: 'Integer',
        leftIcon: <NvFunctionsIcon />,
        value: {
          component: {
            ...textFieldComponent,
            label: 'Integer',
          },
          schema: getInputSchema(SchemaType.integer),
          propertyTitle: {
            label: 'Integer',
            icon: <NvFunctionsIcon />,
          },
        },
      },
      {
        id: '9',
        name: 'Decimal',
        leftIcon: <NvPercentIcon />,
        value: {
          component: {
            ...textFieldComponent,
            label: 'Decimal',
          },
          schema: getInputSchema(SchemaType.float),
          propertyTitle: {
            label: 'Decimal',
            icon: <NvPercentIcon />,
          },
        },
      },
    ],
  },
  {
    id: '10',
    name: 'Select fields',
    leftIcon: <NvCustomSelectFieldIcon />,
    items: [
      {
        id: '11',
        name: 'Single select',
        leftIcon: <NvCustomSingleSelectFieldIcon />,
        value: {
          component: singleSelectComponent,
          schema: getInputSchema(SchemaType.string),
          propertyTitle: {
            label: 'Single select',
            icon: <NvCustomSingleSelectFieldIcon />,
          },
        },
      },
      {
        id: '12',
        name: 'Multi-select',
        leftIcon: <NvCustomMultiSelectFieldIcon />,
        value: {
          component: multiSelectComponent,
          schema: getInputSchema(SchemaType.array),
          propertyTitle: {
            label: 'Multi select',
            icon: <NvCustomMultiSelectFieldIcon />,
          },
        },
      },
      {
        id: '13',
        name: 'Checkbox',
        leftIcon: <NvCustomCheckboxIcon />,
        value: {
          component: checkBoxComponent,
          schema: getInputSchema(SchemaType.boolean),
          propertyTitle: {
            label: 'Checkbox',
            icon: <NvCustomCheckboxIcon />,
          },
        },
      },
      {
        id: '14',
        name: 'Checkbox group',
        leftIcon: <NvCustomCheckboxGroupIcon />,
        value: {
          component: checkBoxGroupComponent,
          schema: getInputSchema(SchemaType.array),
          propertyTitle: {
            label: 'Checkbox group',
            icon: <NvCustomCheckboxGroupIcon />,
          },
        },
      },
      {
        id: '15',
        name: 'Radio button group',
        leftIcon: <NvRadioButtonCheckedIcon />,
        value: {
          component: radioButtonGroupComponent,
          schema: getInputSchema(SchemaType.string),
          propertyTitle: {
            label: 'Radio button group',
            icon: <NvRadioButtonCheckedIcon />,
          },
        },
      },
    ],
  },
  {
    id: '16',
    name: 'Date time fields',
    leftIcon: <NvTodayIcon />,
    items: [
      {
        id: 'date',
        name: 'Date',
        leftIcon: <NvTodayIcon />,
        value: {
          component: datePickerComponent,
          schema: getInputSchema(ExternalSchemaType.date),
          propertyTitle: {
            label: 'Date',
            icon: <NvTodayIcon />,
          },
        },
      },
      {
        id: 'time',
        name: 'Time',
        leftIcon: <NvAccessTimeRounded />,
        value: {
          component: timePickerComponent,
          schema: getInputSchema(ExternalSchemaType.time),
          propertyTitle: {
            label: 'Time',
            icon: <NvAccessTimeRounded />,
          },
        },
      },
      {
        id: 'date_and_time',
        name: 'Date & time',
        leftIcon: <NvCustomDateTime />,
        value: {
          component: dateTimePickerComponent,
          schema: getInputSchema(SchemaType.dateTime),
          propertyTitle: {
            label: 'Date & time',
            icon: <NvCustomDateTime />,
          },
        },
      },
    ],
  },
  {
    id: '17',
    name: 'Content fields',
    leftIcon: <NvFormatSizeIcon />,
    items: [
      {
        id: '18',
        name: 'Header',
        leftIcon: <NvTitleIcon />,
        value: {
          component: headerComponent,
          propertyTitle: {
            label: 'Header',
            icon: <NvTitleIcon />,
          },
        },
      },
      {
        id: '19',
        name: 'Body',
        leftIcon: <NvNotesIcon />,
        value: {
          component: plainTextComponent,
          propertyTitle: {
            label: 'Body',
            icon: <NvNotesIcon />,
          },
        },
      },
    ],
  },
  {
    id: '20',
    name: 'Dynamic fields',
    leftIcon: <NvDynamicFormIcon />,
    value: {
      component: dynamicInputComponent,
      schema: { ...getInputSchema(SchemaType.map), valueSchema: undefined } as FieldSchema,
      propertyTitle: {
        label: 'Dynamic fields',
        icon: <NvDynamicFormIcon />,
      },
    },
  },
  {
    id: 'slack_fields',
    name: 'Slack fields',
    leftIcon: <NvSlackIconNotColored />,
    items: [
      {
        id: 'slack_user_picker',
        name: 'Slack user picker',
        leftIcon: <NvSlackIconNotColored />,
        value: {
          component: slackUserPickerComponent,
          schema: getInputSchema(SchemaType.string),
          propertyTitle: {
            label: 'Slack user picker',
            icon: <NvSlackIconNotColored />,
          },
        },
      },
      {
        id: 'slack_conversation_picker',
        name: 'Slack conversation picker',
        leftIcon: <NvSlackIconNotColored />,
        value: {
          component: slackConversationPickerComponent,
          schema: getInputSchema(SchemaType.string),
          propertyTitle: {
            label: 'Slack conversation picker',
            icon: <NvSlackIconNotColored />,
          },
        },
      },
    ],
  },
];
