import { NvAxios, useQuery } from '@novaera/core';

import { QUERY_KEYS_TIMEZONES } from '../keys';
import { GetTimezonesResponse } from './types';

const getTimezones = async () => {
  const result = await NvAxios.get<GetTimezonesResponse>('/v1/timezones');
  return result?.data;
};

export const useGetTimezones = () => {
  return useQuery<GetTimezonesResponse>(QUERY_KEYS_TIMEZONES.all, () => getTimezones());
};
