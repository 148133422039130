import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCrossIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70696 14.8929C6.31643 15.2834 6.31643 15.9166 6.70696 16.3071L7.83833 17.4384C8.22885 17.829 8.86202 17.829 9.25254 17.4384L12.0728 14.6182L14.8931 17.4384C15.2836 17.829 15.9168 17.829 16.3073 17.4384L17.4387 16.3071C17.8292 15.9166 17.8292 15.2834 17.4387 14.8929L14.6184 12.0726L17.4383 9.25266C17.8289 8.86214 17.8289 8.22897 17.4383 7.83845L16.307 6.70708C15.9164 6.31655 15.2833 6.31655 14.8927 6.70708L12.0728 9.52701L9.25288 6.70708C8.86236 6.31655 8.22919 6.31655 7.83867 6.70708L6.7073 7.83845C6.31677 8.22897 6.31677 8.86214 6.70729 9.25266L9.52723 12.0726L6.70696 14.8929Z"
        fill="currentColor"
        fillOpacity="0.95"
      />
    </SvgIcon>
  );
}
