import { NodeSummaries, Vertex } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { addButtonNode } from '../add-button-node';
import { addLoopDummyButton } from '../add-loop-dummy-button';
import { addNode } from '../add-node-to-graph';
import { connectNodes } from '../connect-nodes';
import { isNodeWithChildren } from '../is-node-with-children';

export const traverseGraph = ({
  node,
  nodes,
  nextNodeAlias,
  nodeSummaries,
}: {
  node: Vertex;
  nodes: Vertex[];
  nodeSummaries: NodeSummaries;
  nextNodeAlias?: string;
}) => {
  window.traverseNumber++;
  if (window.traverseNumber > 500) {
    console.log(`${JSON.stringify(nodes)} is creating an infinite loop`);
    throw new Error(`[traverseGraph] - Creating an infinite loop - ${JSON.stringify(nodes)}`);
  }

  const currentNode = node;

  addNode(currentNode, nodeSummaries[currentNode.alias].name);
  const nextNode = nodes.find((n) => n.alias === currentNode.nextAlias);
  assert(currentNode.alias !== currentNode.nextAlias, new Error('Next node of a node cannot be itself'), 'WARNING');
  if (currentNode.alias === currentNode.nextAlias) {
    return;
  }
  if (nextNode) {
    traverseGraph({ node: nextNode, nodes, nextNodeAlias, nodeSummaries });
  }

  if (isNodeWithChildren(node)) {
    const buttonAlias = addButtonNode({
      alias: currentNode.alias,
      nextNodeAlias: currentNode.nextAlias ?? nextNodeAlias,
    });
    if (currentNode.type === 'branchJunction') {
      currentNode.firstInnerAliases.forEach((b, index) => {
        const branchFirstNode = nodes.find((n) => n.alias === b);

        if (branchFirstNode) {
          const branchJunctionSummary = nodeSummaries[currentNode.alias];
          assert(
            branchJunctionSummary.type === 'branchJunction',
            new Error('summary should be in branch junction type '),
            'ERROR'
          );

          connectNodes({
            sourceId: currentNode.alias,
            targetId: branchFirstNode.alias,
            edgeExtraData: {
              type: branchFirstNode.type === 'blank' ? 'Edge' : 'EdgeWithEndPlusButton',
              name: branchJunctionSummary.branches?.[index]?.name ?? 'DELETED NODE',
            },
          });
          traverseGraph({ node: branchFirstNode, nodes, nextNodeAlias: buttonAlias, nodeSummaries });
        }
      });
    } else if (currentNode.type === 'workflowCondition') {
      const branchFalseNode = nodes.find((n) => n.alias === currentNode.falseAlias);
      const branchTrueNode = nodes.find((n) => n.alias === currentNode.trueAlias);
      if (branchFalseNode) {
        connectNodes({
          sourceId: currentNode.alias,
          targetId: branchFalseNode.alias,
          edgeExtraData: {
            type: branchFalseNode.type === 'blank' ? 'Edge' : 'EdgeWithEndPlusButton',
            name: 'false',
          },
        });
        traverseGraph({ node: branchFalseNode, nodes, nextNodeAlias: buttonAlias, nodeSummaries });
      }

      if (branchTrueNode) {
        connectNodes({
          sourceId: currentNode.alias,
          targetId: branchTrueNode.alias,
          edgeExtraData: {
            type: branchTrueNode.type === 'blank' ? 'Edge' : 'EdgeWithEndPlusButton',
            name: 'true',
          },
        });
        traverseGraph({ node: branchTrueNode, nodes, nextNodeAlias: buttonAlias, nodeSummaries });
      }
    } else if (currentNode.type === 'loop') {
      addLoopDummyButton({ alias: currentNode.alias, nextNodeAlias: buttonAlias });
      const loopInnerNode = nodes.find((n) => n.alias === currentNode.firstInnerAlias);
      if (loopInnerNode) {
        connectNodes({
          sourceId: currentNode.alias,
          targetId: loopInnerNode.alias,
          edgeExtraData: {
            type: loopInnerNode.type === 'blank' ? 'Edge' : 'EdgeWithEndPlusButton',
          },
        });
        traverseGraph({ node: loopInnerNode, nodes, nextNodeAlias: buttonAlias, nodeSummaries });
      }
    }
  } else {
    if (currentNode.nextAlias) {
      connectNodes({
        sourceId: currentNode.alias,
        targetId: currentNode.nextAlias,
        edgeExtraData: { type: currentNode.type === 'blank' ? 'Edge' : 'EdgeWithButton' },
      });
    } else {
      if (nextNodeAlias) {
        connectNodes({
          sourceId: currentNode.alias,
          targetId: nextNodeAlias,
          edgeExtraData: {
            type: currentNode.type === 'blank' ? 'Edge' : 'EdgeWithStartPlusButton',
            centerPosition: 'bottom',
          },
        });
      }
    }
  }
};
