import { NvAxiosWithoutInterceptor } from '@novaera/core';
import { ENDPOINT_DEFAULTS } from '../../common/constants';
import { RegenerateResponse } from '../types';

export const refreshAccessToken = (accessKey?: string) => {
  if (!accessKey) {
    return new Promise<{ data: { accessToken: string } }>((resolve, reject) => reject('no access key provided'));
  }

  return NvAxiosWithoutInterceptor.post<RegenerateResponse>(ENDPOINT_DEFAULTS.REFRESH_TOKEN, {
    accessToken: accessKey,
  });
};
