import { red } from '@mui/material/colors';
import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Dracula, ThemeMode.Dark);

export default {
  primary: {
    main: '#360A14',
  },
  secondary: {
    main: '#392E67',
  },
  error: {
    main: red.A100,
  },
  test: {
    value: 'testDraculaDark',
  },
  ...colors,
};
