import { USER_PERMISSION } from '@novaera/actioner-service';
import {
  isRequired,
  NvBox,
  NvButton,
  NvCheckbox,
  NvCollapse,
  NvField,
  NvFlex,
  NvMenuWithItems,
  NvSelect,
  NvSkeleton,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import classNames from 'classnames';
import { FC } from 'react';
import { UserPermissionBoundary } from '../../../../../../user-permission-boundary';
import { IntegrationCodeField } from '../../../../integration-code-field';
import { DetailTitle } from '../components/title';
import { ResponseResult } from '../response-result';
import {
  BaseUrlBox,
  CollapseInnerWrapper,
  HeaderWrapper,
  HeaderWrapperLeftBox,
  HeaderWrapperRightBox,
  NvTabsStyled,
  RequestConnectionBoxWrapper,
  RequestConnectionCard,
} from '../styled';
import { useAPIRequestDetail } from './controller';

export const APIRequestDetail: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    request,
    connectionSchema,
    requestIndex,
    tabs,
    isDetailOpen,
    responseContent,
    isLoading,
    executionResponse,
    httpMethods,
    setIsDetailOpen,
    actionButtons,
    handleRequestTest,
    responseError,
    hasConnectionSchema,
  } = useAPIRequestDetail();

  return (
    <NvFlex width={1} alignItems="center">
      <HeaderWrapper className={classNames({ 'is-open': isDetailOpen })}>
        <HeaderWrapperLeftBox>
          <DetailTitle
            name={request?.name}
            type="API"
            onTitleClick={() => {
              setIsDetailOpen(!isDetailOpen);
            }}
          />
        </HeaderWrapperLeftBox>
        <HeaderWrapperRightBox>
          {request && (
            <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
              <NvMenuWithItems menuItems={actionButtons(request.id)}></NvMenuWithItems>
            </UserPermissionBoundary>
          )}
        </HeaderWrapperRightBox>
      </HeaderWrapper>
      <NvCollapse in={isDetailOpen} sx={{ width: '100%' }}>
        <CollapseInnerWrapper>
          {!request ? (
            <NvSkeleton />
          ) : (
            <NvFlex direction="column" gap="12px" alignItems="flex-start">
              <RequestConnectionBoxWrapper>
                <RequestConnectionCard>
                  <NvField
                    name={`httpRequestConfigurations[${requestIndex}].useConnectionSchema`}
                    component={({ value, onChange }) => {
                      return (
                        <NvCheckbox
                          checked={Boolean(value)}
                          onChange={(_event, checked) => {
                            return onChange(checked);
                          }}
                        />
                      );
                    }}
                  />
                  <NvTypography variant="h5">Use connection</NvTypography>
                </RequestConnectionCard>
              </RequestConnectionBoxWrapper>
              <NvFlex width={1} direction="row" alignItems="flex-start" spacing={1}>
                <NvField
                  name={`httpRequestConfigurations[${requestIndex}].httpMethod`}
                  defaultValue="GET"
                  formControlStyle={{ width: '120px', flex: '0 0 auto' }}
                  component={<NvSelect id="httpMethod" options={httpMethods} />}
                />

                <NvField
                  name={`httpRequestConfigurations[${requestIndex}].url`}
                  isRequired
                  validators={[isRequired()]}
                  component={
                    <IntegrationCodeField
                      expectedType="string"
                      {...(hasConnectionSchema
                        ? {
                            startAdornment: (
                              <NvTooltip
                                title={connectionSchema?.authentication.baseUrl ?? ''}
                                variant="large"
                                placement="top"
                                fullWidth
                              >
                                <BaseUrlBox>
                                  <NvTypography variant="c2" textColor="subtle" component="p">
                                    baseUrl
                                  </NvTypography>
                                </BaseUrlBox>
                              </NvTooltip>
                            ),
                            startAdornmentOffset: 60,
                          }
                        : {})}
                    />
                  }
                />
                <NvButton
                  color="success"
                  onClick={() => handleRequestTest()}
                  disabled={isLoading}
                  loading={isLoading}
                  sx={{ minWidth: '100px' }}
                >
                  Run
                </NvButton>
              </NvFlex>
              <NvTabsStyled
                variant="small"
                tabs={tabs}
                BodyWrapperComponent={<NvBox padding="0 0 0 0" />}
                focusIndex={2}
              ></NvTabsStyled>
            </NvFlex>
          )}
        </CollapseInnerWrapper>
      </NvCollapse>
      <ResponseResult
        executionResponse={executionResponse}
        responseError={responseError}
        responseContent={responseContent}
      />
    </NvFlex>
  );
};
