import { CodeInput, isValidJSON, NvField, NvFlex, NvTypography } from '@novaera/core';

export const EventTriggers = () => {
  return (
    <NvFlex gap={'20px'}>
      <NvTypography variant="body1">
        To test this workflow, you need to provide an event payload. Add an example event payload below.
      </NvTypography>

      <NvField<string>
        formControlStyle={{
          height: '250px',
          flex: '1 1 auto',
        }}
        showErrorMessageOnlyWhenBlur
        name={'event'}
        validators={[
          isValidJSON({
            valueExtractFunction: (value) => (typeof value === 'object' ? JSON.stringify(value, null, 2) : value),
          }),
        ]}
        format={(value) => (typeof value === 'object' ? JSON.stringify(value, null, 2) : value)}
        component={({ value, onChange, ...props }) => (
          <CodeInput
            value={value}
            onChange={onChange}
            {...props}
            context={{}}
            expectedType="object"
            mode="json"
            lineNumbers
            hasBorder
          />
        )}
      />
    </NvFlex>
  );
};
