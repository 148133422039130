import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const GrayRectangle = styled(NvBox)`
  position: absolute;
  height: 69px;
  left: 0px;
  right: 0px;
  top: 25px;
  background: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
  z-index: -1;
`;
