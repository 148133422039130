import { useState } from 'react';
import { NvCollapse } from '../collapse';
import { NvChevronRightIcon } from '../icons';
import { NvTypography } from '../typography';
import { CollapseCard, CollapseTitle } from './styled';
import { CollapseCardProps } from './types';

export const NvCollapseCard: React.FC<React.PropsWithChildren<CollapseCardProps>> = ({
  title,
  defaultIsOpen = false,
  onToggle,
  children,
  CustomCollapseCardWrapper = CollapseCard,
  iconSx,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  return (
    <CustomCollapseCardWrapper isOpen={isOpen}>
      <CollapseTitle
        onClick={() => {
          setIsOpen(!isOpen);
          onToggle?.({ open: !isOpen });
        }}
      >
        <NvChevronRightIcon
          sx={Object.assign(
            {},
            isOpen
              ? { transform: 'rotate(90deg)', transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }
              : { transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' },
            iconSx
          )}
        />
        {typeof title === 'string' ? (
          <NvTypography variant="h2">{title}</NvTypography>
        ) : typeof title === 'function' ? (
          title(isOpen)
        ) : (
          title
        )}
      </CollapseTitle>
      <NvCollapse in={isOpen}>{children}</NvCollapse>
    </CustomCollapseCardWrapper>
  );
};
