import { SelectChangeEvent } from '@mui/material';
import { Connection, useGetIntegration, useSearchConnectionsService } from '@novaera/actioner-service';
import { useNavigate } from '@novaera/route';
import { useEffect, useMemo } from 'react';
import { ROUTES } from '../../../../common/routes';
import { useGetIntegrationQueryParams } from '../../../../controllers/use-get-integration-query-params';
import { PROFILE_SETTINGS_TAB_PATHS } from '../../../../profile-settings/constants';
import { useTestConfigurationProvider } from '../../providers/test-configuration';
import { ConnectionAction } from '../../providers/test-configuration/reducer/types';

export const useTestConfigurationBarController = () => {
  const { integrationId } = useGetIntegrationQueryParams();
  const { data: integrationData } = useGetIntegration({ id: integrationId });
  const { data: searchConnectionsResult, isFetching: isSearchConnectionLoading } = useSearchConnectionsService({
    payload: { schemaId: integrationData?.connectionSchemaId },
  });

  const { dispatch, state } = useTestConfigurationProvider();

  const navigate = useNavigate();

  const connectionMenuItems = useMemo(() => {
    return searchConnectionsResult?.connections?.map((connection) => ({
      label: connection.name,
      value: connection,
    }));
    // the dependency does not understand the changes in the array so length of the array is checked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConnectionsResult?.connections, searchConnectionsResult?.connections?.length]);

  /**
   * the onchange event is not triggered when the default value is set
   * so we need to set the selected connection manually
   */
  useEffect(() => {
    if (connectionMenuItems && connectionMenuItems?.length > 0 && !state.selectedConnection) {
      dispatch({
        type: ConnectionAction.SetSelectedConnection,
        payload: { connection: connectionMenuItems[0].value as Connection },
      });
    }
  }, [connectionMenuItems, dispatch, state.selectedConnection]);

  const handleConnectionChange = (e: SelectChangeEvent<unknown>) => {
    dispatch({ type: ConnectionAction.SetSelectedConnection, payload: { connection: e.target.value as Connection } });
  };

  const handleNavigateToProfileConnectionClicked = () => {
    navigate(`${ROUTES.ProfileSettings}${PROFILE_SETTINGS_TAB_PATHS.CONNECTIONS}`);
  };

  return {
    hasConnections: searchConnectionsResult && searchConnectionsResult?.connections?.length > 0,
    connectionMenuItems: connectionMenuItems,
    integrationId,
    onConnectionChange: handleConnectionChange,
    onNavigateToProfileConnectionClicked: handleNavigateToProfileConnectionClicked,
    defaultSelectedConnection: connectionMenuItems?.[0]?.value,
    isSearchConnectionLoading,
    connectionSchemaId: integrationData?.connectionSchemaId,
  };
};
