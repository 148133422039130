import { IsDraggableArgs } from './types';

export const isEditable = ({ workflowCreationSource, isAppManaged, hasEditPermission }: IsDraggableArgs) => {
  if (!hasEditPermission) {
    return false;
  }

  if (!isAppManaged) {
    return true;
  }

  if (workflowCreationSource !== 'app-directory') {
    return true;
  }

  return false;
};
