export function NvConnectionIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6373 89.5725C15.4206 89.5725 15.2039 89.4582 15.0892 89.2549C14.9108 88.95 15.0127 88.5689 15.3186 88.391L38.5158 75.0392C38.8217 74.8613 39.2041 74.963 39.3825 75.2678C39.5609 75.5727 39.459 75.9539 39.1531 76.1317L15.9559 89.4963C15.8539 89.5598 15.7392 89.5852 15.6373 89.5852V89.5725Z"
        fill="#B1B6C0"
      />
      <path
        d="M23.4376 75.6619C23.2209 75.6619 23.0043 75.5475 22.8896 75.3443C22.7111 75.0394 22.8131 74.6582 23.119 74.4804L46.3162 61.1285C46.6221 60.9507 47.0044 61.0523 47.1829 61.3572C47.3613 61.6621 47.2593 62.0432 46.9534 62.2211L23.7563 75.5729C23.6543 75.6364 23.5396 75.6619 23.4376 75.6619Z"
        fill="#B1B6C0"
      />
      <path
        d="M71.9223 91.3003C71.7056 91.3003 71.489 91.1859 71.3742 90.9827C71.1958 90.6778 71.2978 90.2967 71.6037 90.1188L94.8008 76.767C95.1067 76.5891 95.4891 76.6907 95.6675 76.9956C95.846 77.3005 95.744 77.6816 95.4381 77.8595L72.2409 91.2113C72.139 91.2749 72.0243 91.3003 71.9223 91.3003Z"
        fill="#B1B6C0"
      />
      <path
        d="M24.2279 109.746C24.0112 109.746 23.7946 109.632 23.6798 109.429C23.5014 109.124 23.6034 108.743 23.9093 108.565L34.7176 102.34C35.0235 102.162 35.4059 102.264 35.5843 102.569C35.7628 102.874 35.6608 103.255 35.3549 103.432L24.5466 109.657C24.4446 109.721 24.3299 109.746 24.2279 109.746Z"
        fill="#B1B6C0"
      />
      <path
        d="M73.4773 107.561C73.2606 107.561 73.0439 107.447 72.9292 107.244C72.7508 106.939 72.8528 106.558 73.1587 106.38L80.207 102.327C80.5129 102.149 80.8953 102.251 81.0737 102.556C81.2522 102.861 81.1502 103.242 80.8443 103.42L73.7959 107.472C73.694 107.536 73.5793 107.561 73.4773 107.561Z"
        fill="#B1B6C0"
      />
      <path
        d="M90.3143 92.6216C90.0977 92.6216 89.881 92.5073 89.7663 92.304C89.5878 91.9991 89.6898 91.618 89.9957 91.4401L97.0441 87.3876C97.35 87.2097 97.7323 87.3113 97.9108 87.6162C98.0892 87.9211 97.9872 88.3022 97.6814 88.4801L90.633 92.5327C90.531 92.5962 90.4163 92.6216 90.3143 92.6216Z"
        fill="#B1B6C0"
      />
      <path
        d="M20.1875 101.756C19.9708 101.756 19.7542 101.641 19.6394 101.438C19.461 101.133 19.563 100.752 19.8689 100.574L26.9172 96.5216C27.2231 96.3437 27.6055 96.4454 27.7839 96.7503C27.9624 97.0552 27.8604 97.4363 27.5545 97.6141L20.5061 101.667C20.4042 101.73 20.2895 101.756 20.1875 101.756Z"
        fill="#B1B6C0"
      />
      <path
        d="M52.5361 111.004C52.3194 111.004 52.1027 110.89 51.988 110.687C51.8096 110.382 51.9115 110.001 52.2174 109.823L64.1602 102.95C64.4661 102.772 64.8484 102.874 65.0269 103.178C65.2053 103.483 65.1033 103.865 64.7974 104.042L52.8547 110.915C52.7528 110.979 52.638 111.004 52.5361 111.004Z"
        fill="#B1B6C0"
      />
      <path
        d="M41.613 110.077C41.3964 110.077 41.1797 109.963 41.065 109.759C40.8865 109.454 40.9885 109.073 41.2944 108.895L53.2371 102.023C53.543 101.845 53.9254 101.946 54.1038 102.251C54.2823 102.556 54.1803 102.937 53.8744 103.115L41.9317 109.988C41.8297 110.051 41.715 110.077 41.613 110.077Z"
        fill="#B1B6C0"
      />
      <path
        d="M42.7601 38.1596L78.2824 58.5494V72.0918C78.2824 72.9811 77.9 73.8323 77.2245 74.4167L69.5388 86.9809C68.8633 87.5653 68.481 88.4164 68.481 89.3057V101.286L51.1595 91.3129V73.5528C51.1595 72.5619 50.9556 71.571 50.5478 70.6563L43.3719 54.2936C42.9768 53.3789 42.7601 52.4007 42.7601 51.3971V38.1469V38.1596Z"
        fill="white"
      />
      <path
        d="M68.4682 101.921C68.3535 101.921 68.2515 101.895 68.1496 101.832L50.8282 91.8592C50.637 91.7449 50.5095 91.5416 50.5095 91.3129V73.5528C50.5095 72.6381 50.3183 71.7488 49.9615 70.9104L42.7856 54.5477C42.3523 53.5441 42.1229 52.4897 42.1229 51.3971V38.1469C42.1229 37.9182 42.2376 37.715 42.4415 37.6006C42.6327 37.4863 42.8749 37.4863 43.0788 37.6006L78.6011 57.9905C78.805 58.1048 78.9197 58.3081 78.9197 58.5367V72.0791C78.9197 73.1209 78.4863 74.1118 77.7216 74.8105L70.0869 87.2985C70.0869 87.2985 70.0105 87.4001 69.9595 87.4509C69.4242 87.921 69.1183 88.5816 69.1183 89.293V101.273C69.1183 101.501 68.9908 101.705 68.7996 101.819C68.6976 101.87 68.5957 101.908 68.481 101.908L68.4682 101.921ZM51.7841 90.9445L67.8309 100.18V89.293C67.8309 88.2513 68.2643 87.2604 69.029 86.5616L76.6637 74.0737C76.6637 74.0737 76.7402 73.972 76.7912 73.9212C77.3265 73.4512 77.6324 72.7906 77.6324 72.0791V58.9051L43.3847 39.2521V51.3971C43.3847 52.3118 43.5759 53.2011 43.9328 54.0395L51.1086 70.4022C51.5419 71.4058 51.7714 72.4603 51.7714 73.5528V90.9445H51.7841Z"
        fill="#455068"
      />
      <path d="M42.7601 38.1596L58.3099 29.216L93.8449 49.6059L78.2824 58.5494L42.7601 38.1596Z" fill="white" />
      <path
        d="M78.2824 59.1846C78.1677 59.1846 78.0657 59.1592 77.9638 59.0957L42.4415 38.7059C42.2376 38.5915 42.1229 38.3883 42.1229 38.1596C42.1229 37.9309 42.2503 37.7277 42.4415 37.6133L58.004 28.6698C58.1952 28.5554 58.4374 28.5554 58.6413 28.6698L94.1636 49.0596C94.3675 49.1739 94.4822 49.3772 94.4822 49.6059C94.4822 49.8345 94.3547 50.0378 94.1636 50.1521L78.6011 59.0957C78.4991 59.1465 78.3971 59.1846 78.2824 59.1846ZM44.0347 38.1596L78.2824 57.8126L92.5703 49.6059L58.3226 29.9529L44.0347 38.1596Z"
        fill="#455068"
      />
      <path
        d="M78.2824 58.5495L78.8432 73.1717L78.2824 73.4893L70.4055 86.2187C69.1692 87.2858 68.4682 88.8357 68.4682 90.4618V101.286L84.0307 92.342V75.6109C84.0307 73.9848 84.7445 72.4349 85.968 71.3677L91.8948 66.2226C93.1311 65.1555 93.8321 63.6056 93.8321 61.9795V49.5932L78.2696 58.5368L78.2824 58.5495Z"
        fill="#455068"
      />
      <path
        d="M68.4682 101.921C68.3535 101.921 68.2515 101.895 68.1496 101.832C67.9584 101.717 67.8309 101.514 67.8309 101.286V90.4618C67.8309 88.6832 68.5829 86.9936 69.9085 85.8121L77.7343 73.159C77.7853 73.0701 77.8618 72.9938 77.9638 72.943L78.1804 72.816L77.6451 58.5749C77.6451 58.3335 77.7598 58.1175 77.9638 58.0032L93.5263 49.0596C93.7174 48.9453 93.9724 48.9453 94.1635 49.0596C94.3547 49.1739 94.4822 49.3772 94.4822 49.6059V61.9922C94.4822 63.8089 93.692 65.5239 92.3282 66.7181L86.4014 71.8632C85.3053 72.816 84.6807 74.188 84.6807 75.6236V92.3547C84.6807 92.5833 84.5533 92.7866 84.3621 92.9009L68.7996 101.845C68.6976 101.895 68.5957 101.933 68.481 101.933L68.4682 101.921ZM78.7412 73.9593L70.9536 86.5489C70.9536 86.5489 70.8772 86.6506 70.8262 86.7014C69.73 87.6542 69.1055 89.0135 69.1055 90.4618V100.18L83.3934 91.9735V75.6108C83.3934 73.7942 84.1837 72.0791 85.5475 70.885L91.4742 65.7399C92.5703 64.7871 93.1949 63.4151 93.1949 61.9795V50.6984L78.9197 58.9052L79.455 73.1463C79.455 73.3877 79.3403 73.6036 79.1364 73.718L78.7158 73.9593H78.7412Z"
        fill="#455068"
      />
      <path
        d="M65.6897 12.8279V12.8533C65.6642 13.1582 65.5495 13.4504 65.3583 13.7426C65.2818 13.8442 65.2054 13.9459 65.1162 14.0475C64.874 14.3016 64.5681 14.543 64.1857 14.7589C62.7455 15.572 60.6807 15.788 58.8835 15.4195C58.6286 15.3687 58.3737 15.3052 58.1315 15.229C57.6599 15.0892 57.2266 14.8987 56.8315 14.6573C56.4618 14.4413 56.1687 14.2 55.9393 13.9332C55.8373 13.8315 55.7481 13.7172 55.6843 13.6029C55.5059 13.3361 55.4039 13.0693 55.3657 12.7898C55.3657 12.7136 55.3657 12.6501 55.3657 12.5866C55.3402 11.7989 55.8628 11.0113 56.9079 10.4269C58.9217 9.30894 62.1719 9.34705 64.1857 10.5285C65.1289 11.0875 65.6387 11.7862 65.6897 12.4976V12.8025V12.8279Z"
        fill="white"
      />
      <path
        d="M60.6169 16.2326C59.9924 16.2326 59.3551 16.1691 58.7433 16.042C58.4629 15.9785 58.1952 15.915 57.9276 15.8261C57.405 15.6609 56.9206 15.4576 56.5 15.2036C56.0794 14.9622 55.7353 14.67 55.4549 14.3524C55.3274 14.2254 55.2127 14.0729 55.1362 13.9586C54.9196 13.6156 54.7794 13.2599 54.7156 12.8914C54.7029 12.7898 54.7029 12.7009 54.7029 12.5993C54.6774 11.5575 55.3657 10.5666 56.5765 9.89332C58.7943 8.66104 62.2738 8.71185 64.4788 9.99495C65.575 10.6301 66.225 11.5067 66.3015 12.4595V12.8025C66.3015 12.8025 66.3015 12.8025 66.3015 12.8152C66.3015 12.866 66.3015 12.9042 66.2887 12.955C66.2505 13.3361 66.0975 13.7172 65.8681 14.0729C65.7662 14.2127 65.6769 14.3397 65.5622 14.4667C65.2563 14.797 64.8995 15.0765 64.4788 15.3052C63.421 15.9023 62.0317 16.2199 60.6169 16.2199V16.2326ZM60.413 10.2744C59.2531 10.2744 58.0933 10.5158 57.2011 11.0113C56.3981 11.4559 55.9647 12.0149 55.9775 12.5993C56.0157 12.9042 56.0794 13.0947 56.2069 13.2853C56.2579 13.3615 56.3089 13.4377 56.3853 13.5012C56.602 13.7426 56.8442 13.9459 57.1501 14.1237C57.4815 14.327 57.8766 14.4921 58.3099 14.6319C58.5393 14.7081 58.7688 14.7589 58.9982 14.8097C60.6934 15.1528 62.6052 14.9241 63.8671 14.2127C64.1729 14.0475 64.4279 13.8442 64.6445 13.6283C64.721 13.5521 64.772 13.4631 64.8357 13.3869C64.9632 13.1963 65.0397 13.0058 65.0524 12.8152V12.7898V12.5484C65.0014 12.0657 64.5808 11.5448 63.8543 11.1129C62.9111 10.5666 61.662 10.2871 60.4257 10.2871L60.413 10.2744Z"
        fill="#455068"
      />
      <path
        d="M65.6897 39.3919C65.6897 39.3919 65.6897 39.4173 65.6897 39.43C65.6642 39.7222 65.5495 40.0271 65.3583 40.3066C65.1034 40.675 64.721 41.0307 64.1857 41.3229C62.7454 42.1359 60.6806 42.3646 58.8835 41.9835C58.6286 41.9327 58.3736 41.8691 58.1315 41.7929V15.229C58.3736 15.3052 58.6158 15.3687 58.8835 15.4195C60.6806 15.7879 62.7582 15.572 64.1857 14.7589C64.568 14.5557 64.8739 14.3016 65.1161 14.0475C65.2053 13.9459 65.2946 13.8442 65.3583 13.7426C65.5495 13.4504 65.6642 13.1582 65.6897 12.8533V39.3919Z"
        fill="#455068"
      />
      <path
        d="M60.6169 42.7965C59.9924 42.7965 59.3551 42.733 58.7433 42.606C58.4629 42.5552 58.1952 42.4789 57.9403 42.4027C57.6726 42.3265 57.4942 42.0724 57.4942 41.7929V15.229C57.4942 15.0257 57.5962 14.8351 57.7619 14.7208C57.9276 14.5938 58.1315 14.5684 58.3354 14.6319C58.5521 14.7081 58.7815 14.7589 59.0109 14.8097C60.7061 15.1527 62.618 14.9241 63.8798 14.2127C64.1857 14.0475 64.4406 13.8442 64.6573 13.6283C64.7338 13.552 64.7847 13.4631 64.8485 13.3869C64.9759 13.1963 65.0524 13.0058 65.0651 12.8152C65.0906 12.4722 65.3328 12.2181 65.7279 12.2435C66.0721 12.2562 66.3397 12.5357 66.3397 12.8787V39.3792C66.3397 39.3792 66.3397 39.3792 66.3397 39.3919C66.3397 39.43 66.3397 39.4808 66.327 39.5316C66.2887 39.9254 66.1358 40.2939 65.8936 40.6623C65.575 41.1323 65.1034 41.5388 64.5043 41.8819C63.4464 42.4789 62.0572 42.8092 60.6296 42.8092L60.6169 42.7965ZM58.756 41.3102C58.8325 41.3356 58.909 41.3483 58.9982 41.361C60.7061 41.7167 62.6052 41.4753 63.8543 40.7639C64.2749 40.5225 64.6063 40.243 64.8102 39.9381C64.9377 39.7476 65.0142 39.5443 65.0397 39.3538V39.3284V39.0997V14.9495C64.8612 15.0765 64.6828 15.2036 64.4788 15.3052C62.9621 16.1564 60.7698 16.4485 58.756 16.042V41.3102Z"
        fill="#455068"
      />
      <path
        d="M58.1315 15.229V41.8056C57.6599 41.6659 57.2265 41.4753 56.8314 41.234C56.3088 40.9164 55.9265 40.5607 55.6843 40.1922C55.5059 39.9255 55.4039 39.646 55.3657 39.3792V12.8025C55.4039 13.0693 55.5059 13.3488 55.6843 13.6156C55.7608 13.7299 55.8373 13.8316 55.9392 13.9459C56.1686 14.2 56.4618 14.4413 56.8314 14.67C57.2265 14.8987 57.6599 15.0892 58.1315 15.2417V15.229Z"
        fill="white"
      />
      <path
        d="M58.1315 42.4408C58.1315 42.4408 58.004 42.4408 57.9403 42.4154C57.405 42.2503 56.9207 42.0343 56.5 41.7929C55.901 41.4245 55.4422 41.0053 55.1363 40.548C54.9196 40.2176 54.7794 39.8619 54.7284 39.4808V12.8025C54.7284 12.4722 54.9833 12.1927 55.3147 12.1673C55.6461 12.1419 55.9392 12.3833 55.9902 12.7136C56.0157 12.9042 56.0922 13.082 56.2069 13.2726C56.2579 13.3488 56.3089 13.425 56.3853 13.4885C56.602 13.7299 56.8442 13.9332 57.1501 14.111C57.4815 14.3143 57.8766 14.4794 58.3099 14.6192C58.5776 14.6954 58.756 14.9495 58.756 15.229V41.8056C58.756 42.0089 58.6541 42.1995 58.5011 42.3138C58.3864 42.39 58.259 42.4408 58.1188 42.4408H58.1315ZM56.003 14.8733V39.3792C56.0285 39.4681 56.1049 39.6587 56.2196 39.8365C56.4108 40.1414 56.7295 40.4209 57.1628 40.6877C57.2648 40.7512 57.3795 40.8147 57.4942 40.8655V15.6736C57.1373 15.5339 56.8059 15.3814 56.5 15.2036C56.3216 15.1019 56.1559 14.9876 56.003 14.8606V14.8733Z"
        fill="#455068"
      />
      <path
        d="M80.7805 21.6953V21.7207C80.755 22.0256 80.6403 22.3178 80.4491 22.61C80.3727 22.7116 80.2962 22.8132 80.207 22.9149C79.9648 23.1689 79.6589 23.4103 79.2765 23.6263C77.8363 24.4393 75.7715 24.6553 73.9743 24.2869C73.7194 24.2361 73.4645 24.1726 73.2223 24.0963C72.7507 23.9566 72.3174 23.766 71.9223 23.5247C71.5526 23.3087 71.2595 23.0673 71.0301 22.8005C70.9281 22.6989 70.8389 22.5846 70.7752 22.4702C70.5967 22.2034 70.4948 21.9367 70.4565 21.6572C70.4565 21.581 70.4565 21.5174 70.4565 21.4539C70.431 20.6663 70.9536 19.8786 71.9988 19.2942C74.0126 18.1763 77.2627 18.2144 79.2765 19.3959C80.2197 19.9548 80.7296 20.6536 80.7805 21.365V21.6699V21.6953Z"
        fill="white"
      />
      <path
        d="M75.7205 25.0999C75.096 25.0999 74.4587 25.0364 73.8469 24.9094C73.5665 24.8586 73.2988 24.7823 73.0312 24.6934C72.5086 24.5283 72.0242 24.325 71.6036 24.0709C71.183 23.8295 70.8389 23.5374 70.5585 23.2198C70.4565 23.1181 70.3546 22.9911 70.2398 22.8259C70.0232 22.4829 69.883 22.1272 69.832 21.7588C69.8192 21.6572 69.8192 21.5555 69.8192 21.4666C69.7937 20.4249 70.4693 19.434 71.6929 18.7607C73.9106 17.5284 77.3902 17.5792 79.5952 18.8623C80.6913 19.4975 81.3414 20.3741 81.4178 21.3269V21.6699C81.4178 21.6699 81.4178 21.6699 81.4178 21.6826C81.4178 21.7334 81.4178 21.7715 81.4051 21.8223C81.3668 22.2034 81.2139 22.5719 80.9845 22.9403C80.8825 23.08 80.7805 23.2198 80.6658 23.3341C80.3599 23.6517 80.0031 23.9439 79.5824 24.1726C78.5246 24.7696 77.148 25.0872 75.7205 25.0872V25.0999ZM75.5166 19.1418C74.3567 19.1418 73.1969 19.3832 72.3047 19.8786C71.5017 20.3233 71.0683 20.8822 71.0811 21.4666C71.1193 21.7715 71.183 21.9621 71.3105 22.1526C71.3742 22.2416 71.4252 22.3051 71.4889 22.3686C71.7056 22.61 71.9478 22.8132 72.2537 22.9911C72.5978 23.1944 72.9802 23.3595 73.4135 23.4992C73.643 23.5755 73.8724 23.6263 74.1018 23.6771C75.8097 24.0201 77.7088 23.7914 78.9579 23.08C79.2638 22.9149 79.5187 22.7116 79.7354 22.4956C79.8119 22.4194 79.8756 22.3305 79.9266 22.2543C80.054 22.0637 80.1305 21.8731 80.1433 21.6826V21.6572V21.4158C80.0923 20.933 79.6717 20.4122 78.9452 19.9803C78.002 19.434 76.7529 19.1545 75.5166 19.1545V19.1418Z"
        fill="#455068"
      />
      <path
        d="M80.7806 48.2719C80.7806 48.2719 80.7806 48.2973 80.7806 48.3101C80.7551 48.6022 80.6403 48.9071 80.4492 49.1866C80.1942 49.555 79.8119 49.9107 79.2766 50.2029C77.8363 51.016 75.7715 51.2447 73.9743 50.8635C73.7194 50.8127 73.4645 50.7492 73.2224 50.673V24.0963C73.4645 24.1725 73.7067 24.2361 73.9743 24.2869C75.7715 24.6553 77.849 24.4393 79.2766 23.6263C79.6589 23.423 79.9648 23.1689 80.207 22.9149C80.2962 22.8132 80.3854 22.7116 80.4492 22.61C80.6403 22.3178 80.7551 22.0256 80.7806 21.7207V48.2719Z"
        fill="#455068"
      />
      <path
        d="M75.7078 51.6766C75.0832 51.6766 74.446 51.6131 73.8342 51.486C73.5538 51.4352 73.2861 51.359 73.0312 51.2828C72.7635 51.1938 72.5851 50.9525 72.5851 50.673V24.0963C72.5851 23.8931 72.687 23.7025 72.8527 23.5882C73.0184 23.4738 73.2224 23.4357 73.4263 23.4992C73.643 23.5755 73.8724 23.6263 74.1018 23.6771C75.8097 24.0201 77.7089 23.7914 78.9579 23.08C79.2638 22.9149 79.5187 22.7116 79.7354 22.4956C79.8119 22.4194 79.8756 22.3305 79.9266 22.2543C80.0541 22.0637 80.1305 21.8731 80.1433 21.6826C80.1688 21.3396 80.4492 21.0728 80.8061 21.1109C81.1502 21.1236 81.4179 21.4031 81.4179 21.7461V48.2465C81.4179 48.2465 81.4179 48.2465 81.4179 48.2592C81.4179 48.2973 81.4179 48.3482 81.4051 48.399C81.3669 48.7801 81.2139 49.1612 80.9718 49.5296C80.6531 50.0124 80.1815 50.4189 79.5825 50.7492C78.5246 51.3463 77.1353 51.6766 75.7078 51.6766ZM73.8469 50.1902C73.9234 50.2029 73.9999 50.2283 74.0891 50.2411C75.797 50.5841 77.6961 50.3554 78.9452 49.644C79.3785 49.4026 79.6972 49.1231 79.9011 48.8182C80.0286 48.6277 80.105 48.4244 80.1178 48.2338V48.2084V47.9798V23.8295C79.9394 23.9566 79.7609 24.0836 79.557 24.1853C78.0402 25.0364 75.848 25.3286 73.8342 24.9221V50.1902H73.8469Z"
        fill="#455068"
      />
      <path
        d="M73.2223 24.0963V50.673C72.7507 50.5333 72.3174 50.3427 71.9222 50.1013C71.3997 49.7837 71.0173 49.428 70.7751 49.0596C70.5967 48.7928 70.4947 48.5133 70.4565 48.2465V21.6826C70.4947 21.9494 70.5967 22.2289 70.7751 22.4956C70.8516 22.61 70.9281 22.7116 71.03 22.8259C71.2595 23.08 71.5526 23.3214 71.9222 23.5501C72.3174 23.7787 72.7507 23.9693 73.2223 24.1217V24.0963Z"
        fill="white"
      />
      <path
        d="M73.2223 51.3082C73.2223 51.3082 73.0949 51.3082 73.0311 51.2828C72.4958 51.1176 72.0242 50.9144 71.5909 50.6603C70.9918 50.2919 70.533 49.8726 70.2271 49.4153C70.0104 49.085 69.8702 48.7293 69.8192 48.3482V21.6826C69.8192 21.3523 70.0741 21.0728 70.4055 21.0474C70.7242 21.022 71.0301 21.2633 71.081 21.5937C71.1065 21.7842 71.183 21.9621 71.2977 22.1526C71.3614 22.2416 71.4124 22.3051 71.4762 22.3686C71.6928 22.61 71.935 22.8132 72.2409 22.9911C72.585 23.1944 72.9674 23.3595 73.4008 23.4992C73.6684 23.5755 73.8469 23.8295 73.8469 24.109V50.6857C73.8469 50.889 73.7449 51.0795 73.5919 51.1938C73.4772 51.2701 73.3498 51.3209 73.2096 51.3209L73.2223 51.3082ZM71.0938 23.7406V48.2465C71.1193 48.3355 71.1958 48.526 71.3105 48.7039C71.5016 49.0088 71.8203 49.2883 72.2536 49.555C72.3556 49.6186 72.4703 49.6821 72.585 49.7329V24.5537C72.2282 24.4139 71.8968 24.2615 71.5909 24.0836C71.4124 23.982 71.2467 23.8677 71.0938 23.7406Z"
        fill="#455068"
      />
      <path d="M43.0915 78.736L32.6655 84.7323L43.0915 90.7286L53.5175 84.7323L43.0915 78.736Z" fill="white" />
      <path
        d="M43.0915 91.3638C42.9768 91.3638 42.8749 91.3383 42.7729 91.2748L32.3469 85.2786C32.1557 85.1642 32.0283 84.961 32.0283 84.7323C32.0283 84.5036 32.1557 84.3004 32.3469 84.186L42.7729 78.1898C42.9641 78.0754 43.219 78.0754 43.4102 78.1898L53.8362 84.186C54.0273 84.3004 54.1548 84.5036 54.1548 84.7323C54.1548 84.961 54.0273 85.1642 53.8362 85.2786L43.4102 91.2748C43.3082 91.3256 43.2062 91.3638 43.0915 91.3638ZM33.9401 84.7323L43.0915 90.0044L52.2429 84.7323L43.0915 79.4602L33.9401 84.7323Z"
        fill="#455068"
      />
      <path d="M43.0915 90.7286V101.908L32.6655 95.8991V84.7323L43.0915 90.7286Z" fill="white" />
      <path
        d="M43.0915 102.543C42.9768 102.543 42.8749 102.518 42.7729 102.454L32.3469 96.458C32.1557 96.3437 32.0283 96.1404 32.0283 95.9118V84.745C32.0283 84.5163 32.1557 84.3131 32.3469 84.1987C32.5381 84.0844 32.793 84.0844 32.9842 84.1987L43.4102 90.195C43.6014 90.3093 43.7288 90.5126 43.7288 90.7413V101.908C43.7288 102.137 43.6014 102.34 43.4102 102.454C43.3082 102.505 43.2062 102.543 43.0915 102.543ZM33.3028 95.5434L42.4542 100.815V91.1097L33.3028 85.8375V95.5434Z"
        fill="#455068"
      />
      <path d="M53.5175 84.7323V95.8991L43.0915 101.908V90.7286L53.5175 84.7323Z" fill="#455068" />
      <path
        d="M43.0915 102.543C42.9768 102.543 42.8748 102.518 42.7729 102.454C42.5817 102.34 42.4542 102.137 42.4542 101.908V90.7413C42.4542 90.5126 42.5817 90.3093 42.7729 90.195L53.1988 84.1987C53.39 84.0844 53.6449 84.0844 53.8361 84.1987C54.0273 84.3131 54.1548 84.5163 54.1548 84.745V95.9118C54.1548 96.1404 54.0273 96.3437 53.8361 96.458L43.4102 102.454C43.3082 102.505 43.2062 102.543 43.0915 102.543ZM43.7288 91.097V100.803L52.8802 95.5307V85.8248L43.7288 91.097Z"
        fill="#455068"
      />
      <path
        d="M89.0908 82.9666C89.078 85.8123 88.1221 87.8703 86.5799 88.9628L85.8788 89.3694L81.9277 91.618C82.4885 91.1606 82.9856 90.5635 83.3552 89.8013C83.9033 88.7469 84.1964 87.3876 84.2219 85.7742C84.2346 83.9448 83.865 82.0138 83.215 80.0955L88.2495 77.7961C88.8104 79.5365 89.1163 81.3024 89.0908 82.9666Z"
        fill="#455068"
      />
      <path
        d="M81.9277 92.2532C81.7237 92.2532 81.5198 92.1515 81.4051 91.9737C81.2139 91.7069 81.2649 91.3258 81.5326 91.1225C82.0551 90.7033 82.4757 90.157 82.7944 89.5218C83.317 88.5309 83.5846 87.2605 83.5974 85.7614C83.6101 84.0718 83.2915 82.2298 82.6287 80.2861C82.5267 79.9812 82.6669 79.6381 82.9601 79.5111L87.9946 77.2117C88.1603 77.1355 88.3515 77.1355 88.5172 77.2117C88.6829 77.2879 88.8104 77.4277 88.8613 77.5928C89.4604 79.4476 89.7535 81.2516 89.7408 82.9666C89.728 85.9012 88.7339 88.226 86.9622 89.4837L82.2463 92.1769C82.1444 92.2278 82.0424 92.2659 81.9277 92.2659V92.2532ZM83.9925 80.4385C84.5788 82.3187 84.8719 84.1099 84.8592 85.7869C84.8464 87.2605 84.6043 88.5563 84.1454 89.6235L86.274 88.4166C87.6505 87.4384 88.4407 85.482 88.4535 82.9666C88.4662 81.5946 88.2623 80.159 87.8417 78.6854L83.9925 80.4512V80.4385Z"
        fill="#455068"
      />
      <path
        d="M88.2368 77.7961L83.2023 80.0955C81.6346 75.4839 78.3462 71.0503 74.5607 68.8779C73.1714 68.0648 71.8459 67.6456 70.6478 67.5821C69.7301 67.5313 68.9016 67.671 68.1751 68.0013L72.4449 65.5241L72.8655 65.2827C74.5735 64.4061 76.8932 64.5967 79.4296 66.0703C83.368 68.3443 86.7583 73.0194 88.2368 77.8088V77.7961Z"
        fill="white"
      />
      <path
        d="M83.215 80.7307C83.1258 80.7307 83.0493 80.718 82.9601 80.6799C82.7944 80.6036 82.667 80.4766 82.6032 80.2987C81.0355 75.7126 77.7599 71.4441 74.2421 69.4242C72.9675 68.6873 71.7439 68.2681 70.6095 68.2046C69.7938 68.1538 69.0673 68.2808 68.4428 68.573C68.1369 68.7127 67.7672 68.5857 67.6143 68.2935C67.4613 67.9886 67.5633 67.6202 67.8565 67.4551L72.5469 64.7237C74.5607 63.682 77.1098 63.9742 79.7482 65.5114C83.6994 67.7854 87.2682 72.5366 88.8486 77.6055C88.9378 77.9104 88.7976 78.2407 88.5045 78.3677L83.4699 80.6672C83.3807 80.7053 83.2915 80.7307 83.2023 80.7307H83.215ZM71.1831 66.985C72.3684 67.1375 73.6175 67.5821 74.8921 68.3316C78.5501 70.4278 81.8258 74.5693 83.5847 79.2316L87.4721 77.4531C85.9171 72.8796 82.6797 68.6619 79.1237 66.6166C76.8677 65.3081 74.7519 65.0286 73.1714 65.8417L71.1831 66.9977V66.985Z"
        fill="#455068"
      />
      <path
        d="M84.2219 85.7741C84.2092 87.3875 83.9033 88.7469 83.3552 89.8013C82.9856 90.5635 82.4885 91.1606 81.9277 91.618C81.7875 91.745 81.6218 91.8466 81.4816 91.9229C79.6972 93.0281 77.2118 92.9138 74.4587 91.2877C69.182 88.1752 64.9122 80.6926 64.9122 74.5439C64.9122 71.6474 65.8426 69.5512 67.3976 68.4587L68.0986 68.0394C68.0986 68.0394 68.1368 68.0013 68.1751 68.0013C68.9016 67.671 69.73 67.5313 70.6477 67.5821C71.8458 67.6583 73.1714 68.0775 74.5607 68.8779C78.3589 71.063 81.6345 75.4967 83.2022 80.0955C83.8523 82.0138 84.2219 83.9448 84.2092 85.7741H84.2219Z"
        fill="white"
      />
      <path
        d="M78.8432 93.2949C77.3902 93.2949 75.797 92.8121 74.1401 91.8339C68.6977 88.6198 64.2749 80.8704 64.2749 74.5439C64.2749 71.533 65.2563 69.1828 67.0407 67.9378L67.78 67.4932C67.78 67.4932 67.8692 67.4423 67.9074 67.4296C68.7231 67.0485 69.6536 66.8834 70.686 66.9469C72.0243 67.0231 73.4391 67.4932 74.8921 68.3316C78.7158 70.5294 82.1444 74.9631 83.8268 79.9049C84.5278 81.9884 84.8847 83.9702 84.872 85.7996C84.8592 87.5019 84.5406 88.9501 83.9415 90.1189C83.5591 90.9065 83.0111 91.5926 82.3483 92.1261C82.1826 92.2659 81.9787 92.4056 81.7875 92.4945C80.9208 93.0281 79.9394 93.2949 78.856 93.2949H78.8432ZM68.43 68.5857L67.729 69.0049C66.3397 69.9831 65.5495 71.965 65.5495 74.5439C65.5495 80.4766 69.6918 87.7432 74.7901 90.7414C77.2373 92.1769 79.4933 92.4056 81.1502 91.3766C81.3032 91.3004 81.4179 91.2241 81.5071 91.1352C82.0424 90.7033 82.463 90.157 82.7817 89.5218C83.3042 88.5309 83.5719 87.2605 83.5846 85.7614C83.5974 84.0718 83.2787 82.2297 82.616 80.286C81.0482 75.6999 77.7726 71.4314 74.2548 69.4115C72.9802 68.6746 71.7566 68.2554 70.6223 68.1919C69.8065 68.1411 69.08 68.2681 68.4555 68.5603C68.4555 68.5603 68.4555 68.5603 68.4427 68.5603L68.43 68.5857Z"
        fill="#455068"
      />
      <path
        d="M29.0713 24.2108L36.1834 19.8279C36.4765 19.6628 36.8462 19.6374 37.1776 19.8279L44.6338 24.1218C45.0289 24.3505 45.2201 24.8206 45.0926 25.2652L42.569 33.8785C42.4798 34.1961 42.62 34.5518 42.9131 34.7042L46.7113 36.7623C47.4633 37.1688 47.731 38.1343 47.2849 38.8711L39.5865 51.6894L31.8881 56.4407L39.8032 43.4318L39.459 41.9328L35.0618 39.5698L34.9853 38.4138L37.2795 29.8513L37.1648 29.1526L29.0713 24.2235V24.2108Z"
        fill="#455068"
      />
      <path
        d="M42.5945 34.3485L34.8706 39.0236L39.8032 42.2504L47.4379 37.6388L46.7114 36.7496L42.5945 34.3485Z"
        fill="white"
      />
      <path
        d="M29.0713 24.2108L32.7421 21.9368L36.502 19.7009L37.1776 19.6882L44.825 24.1473L45.2583 24.7189L37.203 29.3686L36.9226 28.8604L29.0713 24.2108Z"
        fill="white"
      />
      <path
        d="M29.4664 24.5665L36.9226 28.8604C37.3177 29.0891 37.5089 29.5591 37.3815 30.0038L34.8578 38.6171C34.7686 38.9347 34.9088 39.2904 35.202 39.4428L39.0002 41.5009C39.7522 41.9074 40.0198 42.8729 39.5737 43.6097L31.8753 56.428C31.6204 56.8472 30.9831 56.6186 31.0469 56.1358L32.6911 45.0707C32.7293 44.7785 32.5891 44.4863 32.3342 44.3465L28.7271 42.3012C28.2556 42.0344 27.9624 41.5263 27.9624 40.9927V25.4304C27.9624 24.6681 28.7909 24.1854 29.4664 24.5665Z"
        fill="white"
      />
      <path
        d="M31.5057 57.2665C31.3783 57.2665 31.2635 57.2411 31.1361 57.2029C30.639 57.0251 30.3459 56.5423 30.4223 56.0342L32.0665 44.969L28.4085 42.8475C27.733 42.4664 27.3124 41.7549 27.3124 40.98V25.4304C27.3124 24.846 27.6183 24.3124 28.1281 24.0202C28.6379 23.728 29.2497 23.728 29.7596 24.0202L37.2158 28.3142C37.8658 28.6953 38.1845 29.4575 37.9678 30.1816L35.4441 38.7949L39.2806 40.9419C39.8032 41.2214 40.1728 41.6914 40.3257 42.2631C40.4787 42.8348 40.4022 43.4192 40.0963 43.9273L32.3979 56.7456C32.194 57.0759 31.8498 57.2665 31.4802 57.2665H31.5057ZM28.9693 25.062C28.8801 25.062 28.8164 25.0874 28.7909 25.1128C28.7399 25.1382 28.6124 25.2398 28.6124 25.4304V40.98C28.6124 41.2976 28.7781 41.5898 29.0585 41.7422L32.6656 43.7876C33.1499 44.0544 33.4176 44.6133 33.3411 45.1596L31.8371 55.2719L39.0384 43.2794C39.1659 43.0762 39.1914 42.8348 39.1276 42.5934C39.0639 42.3647 38.911 42.1742 38.6943 42.0598L34.8961 40.0018C34.3353 39.6969 34.0676 39.049 34.246 38.4392L36.7697 29.8259C36.8207 29.6608 36.7442 29.4956 36.604 29.4067L29.1478 25.1128C29.084 25.0747 29.0203 25.062 28.9693 25.062Z"
        fill="#455068"
      />
      <path
        d="M32.2195 56.9616L31.544 55.8817L39.1149 51.2194L46.7241 38.5408C46.8515 38.3376 46.877 38.0962 46.8133 37.8548C46.7496 37.6134 46.5966 37.4356 46.3927 37.3213L42.5945 35.2632C42.0337 34.9583 41.766 34.2977 41.9444 33.7006L44.4681 25.0874C44.5191 24.9222 44.4426 24.7571 44.3024 24.6681L36.8462 20.3742C36.6805 20.2726 36.5403 20.3488 36.4893 20.3742L28.7272 25.1636L28.0516 24.0837L35.8265 19.2816C36.3491 18.9768 36.9609 18.9768 37.4835 19.2816L44.9397 23.5756C45.5897 23.9567 45.9084 24.7189 45.6917 25.4431L43.168 34.0563L47.0045 36.2033C47.5271 36.4828 47.8967 36.9528 48.0496 37.5245C48.2026 38.0962 48.1261 38.6806 47.8202 39.1887L40.0453 52.1341L32.2067 56.9616H32.2195Z"
        fill="#455068"
      />
    </svg>
  );
}
