import { LoadingPage } from '@novaera/identity-provider';
import { useQueryParams } from '@novaera/route';
import { useEffect } from 'react';
import { useStartMarketplaceSlackAppInstallation } from './controller';

export const StartMarketplaceSlackAppInstallation: React.FC<{ isAnonymous: boolean }> = ({ isAnonymous }) => {
  const { getSearchParams } = useQueryParams();
  const params = getSearchParams<{ 'app-schema-id': string }>();
  const { slackAuthUrl } = useStartMarketplaceSlackAppInstallation({
    isAnonymous,
    schemaId: params?.['app-schema-id'],
  });

  useEffect(() => {
    if (slackAuthUrl) {
      window.location.href = slackAuthUrl;
    }
  }, [slackAuthUrl]);

  return <LoadingPage />;
};
