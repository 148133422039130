import { NvBox, NvFlex, NvRouterLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppDirectoryCardContainer = styled(NvFlex)`
  position: relative;
  ${({ theme }) => theme.elevations.e100};
  border-radius: 12px;
  padding: 16px;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  min-height: 168px;
  cursor: pointer;
  transition: transform 200ms ease-in-out;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};

  &.not-clickable {
    cursor: default;

    &:hover {
      transform: translate(0, 0);
    }

    &::before,
    &::after {
      display: none;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 12px;
  }

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    z-index: 1;
    transition: background-color 300ms ease-in-out, border-color 200ms ease-in-out;
    border: 1px solid transparent;
  }

  &::after {
    height: 40px;
    left: 20px;
    right: 20px;
    bottom: 0px;
    z-index: 0;
    background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
    filter: blur(10px);
    opacity: 0;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }

  &:hover {
    transform: translate(0, -6px);

    &::before {
      background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
      border-color: ${({ theme }) => theme.palette.nv_neutral[30]};
    }

    &::after {
      opacity: 0.1;
      transform: translate(0, 10px);
    }
  }
`;

export const AppDirectoryCardHeader = styled(NvFlex)`
  position: relative;
  z-index: 2;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const AppDirectoryCardDescription = styled(NvBox)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 auto;
  min-height: 0;
  margin-top: 8px;
`;

export const NvAppDirectoryCardLink = styled(NvRouterLink)`
  width: 100%;
`;
