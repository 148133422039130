export enum USER_PERMISSION {
  WORKSPACE_UPDATE_METADATA = 'workspace:update-metadata',
  WORKSPACE_CONNECT_SLACK = 'workspace:connect-slack',
  WORKSPACE_CONNECT_MS_TEAMS = 'workspace:connect-ms-teams',
  USER_INVITE = 'user:invite',
  USER_UNINVITE = 'user:uninvite',
  USER_CHANGE = 'user:change-role',
  CATALOG_EDIT = 'catalog:edit',
  GROUP_EDIT = 'group:edit',
  APP_CREATE = 'app:create',
  APP_INSTALL = 'app:install',
  INTEGRATION_CREATE = 'integration:create',
  INTEGRATION_UPDATE = 'integration:update',
  INTEGRATION_DELETE = 'integration:delete',
}

export enum APP_PERMISSION {
  APP_EDIT = 'app:edit',
}

export type Permissions<PERM extends APP_PERMISSION | USER_PERMISSION> = Partial<Record<PERM, boolean>>;
