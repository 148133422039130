import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const FieldHeaderWrapper = styled(NvBox)`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[5]};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.nv_neutral[30]};
  justify-content: space-between;
  padding: 12px 32px 12px 24px;
`;

export const FieldBodyWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
`;
