import { CatalogRelationshipFilter, ValueTypes } from '@novaera/actioner-service';
import { NvAddBoxIcon, NvBox, NvButton, NvDeleteOutlineIcon, NvTooltip } from '@novaera/core';
import { useCallback, useEffect, useRef } from 'react';
import { PropertyPanelEmptySection, PropertyPanelListHeader, PropertyPanelSection } from '../../property-panel';
import { CatalogRelationshipFilterUIComponentParams } from '../use-ui-component/types';
import { RelationshipFilter } from './relationship-filter';

export const CatalogRelationshipFilterComponent: React.FC<CatalogRelationshipFilterUIComponentParams> = ({
  inputProps,
  context,
  otherProps,
}) => {
  const { value, onChange } = inputProps;
  const firstEntityTypeId = otherProps.firstEntityTypeId;
  const prevFirstEntityTypeId = useRef<string | undefined>(firstEntityTypeId);
  const initialFilter = {
    relationshipDefinitionId: { type: ValueTypes.STRING, value: '', displayValue: '' },
    query: { type: ValueTypes.STRING, value: '' },
  };
  const relationshipFilters = value ?? [];
  const handleAddNewFilter = () => {
    onChange([...(value ?? []), initialFilter]);
  };

  const handleDeleteFilter = useCallback(
    (index: number) => {
      onChange(value.filter((v, i) => i !== index));
    },
    [onChange, value]
  );

  const handleUpdateFilter = useCallback(
    (updatedFilter: CatalogRelationshipFilter, index: number) => {
      const filters: CatalogRelationshipFilter[] = value ?? [];

      onChange(
        filters.map<CatalogRelationshipFilter>((filter, innerIndex: number) =>
          innerIndex === index ? updatedFilter : filter
        ) ?? [updatedFilter]
      );
    },
    [onChange, value]
  );

  useEffect(() => {
    if ((!firstEntityTypeId || prevFirstEntityTypeId.current !== firstEntityTypeId) && value?.length > 0) {
      // clear catalog relationship filter parameter mappings if firstEntityType removed or changed.
      onChange([]);
    }
    prevFirstEntityTypeId.current = firstEntityTypeId;
  }, [firstEntityTypeId, onChange, value?.length]);

  return (
    <>
      <PropertyPanelListHeader
        title="Relationship filters"
        actions={
          <NvTooltip title={!firstEntityTypeId ? 'Please select entity type first' : undefined}>
            <NvBox>
              <NvButton onlyIcon color="ghost" size="small" onClick={handleAddNewFilter} disabled={!firstEntityTypeId}>
                <NvAddBoxIcon />
              </NvButton>
            </NvBox>
          </NvTooltip>
        }
      />
      {!relationshipFilters || relationshipFilters.length === 0 ? (
        <PropertyPanelEmptySection emptyText="This action doesn’t have any relationships filter yet." />
      ) : (
        relationshipFilters.map((filter, index) => (
          <PropertyPanelSection
            key={`relationship-filter-${index}`}
            collapsible
            title={`Filter ${index + 1}`}
            actions={
              <NvButton onlyIcon color="ghost" size="small" onClick={() => handleDeleteFilter(index)}>
                <NvDeleteOutlineIcon />
              </NvButton>
            }
          >
            <RelationshipFilter
              value={filter}
              onChange={(updatedFilter) => {
                handleUpdateFilter(updatedFilter, index);
              }}
              context={context}
              firstEntityTypeId={firstEntityTypeId}
            />
          </PropertyPanelSection>
        ))
      )}
    </>
  );
};
