import { Request } from 'express';
import pino from 'pino';
import { TemplateError } from '../utils';
export const logger = pino();

type LambdaErrorContext = { executionId?: string; actionRunId?: string; workflowRunId?: any };

export const isLambdaError = (request?: Request | LambdaErrorContext): request is LambdaErrorContext => {
  return !!(request as LambdaErrorContext).actionRunId || !!(request as LambdaErrorContext).workflowRunId;
};

export const logError = (error: unknown, request?: Request | LambdaErrorContext) => {
  const executionId = isLambdaError(request) ? request.executionId : request?.body?.executionId;
  const workflowExecutionId = isLambdaError(request) ? undefined : request?.body?.workflowExecutionId;
  const actionRunId = isLambdaError(request) ? request.actionRunId : undefined;
  const workflowRunId = isLambdaError(request) ? request.workflowRunId : undefined;

  if (error instanceof TemplateError) {
    logger.error({
      error: {
        code: error.code,
        message: error.message,
        name: error.name,
        stack: error.stack,
      },
      executionId,
      workflowExecutionId,
      actionRunId,
      workflowRunId,
    });
  } else if (error instanceof Error) {
    logger.error({
      error: {
        message: error.message,
        name: error.name,
        stack: error.stack,
      },
      executionId,
      workflowExecutionId,
      actionRunId,
      workflowRunId,
    });
  } else {
    logger.error({
      error: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: (error as any)?.message ?? 'Unknown exception type',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        stack: (error as any)?.stack,
      },
      executionId,
      workflowExecutionId,
      actionRunId,
      workflowRunId,
    });
  }
};
