import { FC } from 'react';
import { BaseEdge } from '../common/base-edge';
import { UserAppEdgeProps } from '../types';

export const Edge: FC<React.PropsWithChildren<UserAppEdgeProps>> = ({ sourceY, targetY, ...rest }) => {
  return (
    <BaseEdge
      sourceY={sourceY - 4}
      targetY={targetY + 4}
      borderRadius={6}
      topDownOffset={12}
      leftRightOffset={0}
      buttonBehavior="notShown"
      {...rest}
    />
  );
};
