import { CatalogEntityType, useUpdateCatalogEntityType } from '@novaera/actioner-service';
import { NvButton, NvDialogModal, NvLockIcon, NvTooltip, useNvDialogModalState } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { Dispatch, SetStateAction } from 'react';
import { Header } from '../../../components';
import { DynamicMaterialIcons } from '../../../components/dynamic-material-icons';
import { EntityTypeModalBody } from '../../entity-type-modal-body';
import { SelectedItemDetail } from '../../provider/use-catalog-provider';
import { EntityTypeFormTypes } from '../../types';
import { CatalogSwitchHeaderModes } from '../constants';
import { CatalogSwitchHeaderMode } from '../types';
import { useCatalogHeader } from './controllers/use-catalog-header';

export const CatalogBodyHeader = ({
  mode,
  onSwitchModeChanged,
  setSelectedDetailItem,
}: {
  mode: CatalogSwitchHeaderMode;
  onSwitchModeChanged: (mode: CatalogSwitchHeaderMode) => void;
  setSelectedDetailItem: Dispatch<SetStateAction<SelectedItemDetail | undefined>>;
}) => {
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState<EntityTypeFormTypes>();

  const {
    headerMenuActions,
    dirty,
    reset,
    setConfig,
    submit,
    submitting,
    catalogEntityType,
    hasCatalogEditPermission,
  } = useCatalogHeader();

  const { mutate: updateCatalogEntityType } = useUpdateCatalogEntityType();

  const { palette } = useTheme();

  return (
    <>
      <DynamicMaterialIcons initialValue={catalogEntityType?.iconId}>
        {({ getCurrentIcon }) => {
          const Icon = getCurrentIcon();
          return (
            <Header<CatalogSwitchHeaderMode>
              key={`catalog-header-${catalogEntityType?.id}`}
              name={catalogEntityType?.name ?? ''}
              icon={Icon ? <Icon /> : null}
              actions={headerMenuActions}
              modes={CatalogSwitchHeaderModes}
              mode={mode}
              onModeSwitch={onSwitchModeChanged}
              hasChange={dirty}
              buttonActions={
                <>
                  <NvButton
                    size="small"
                    color="secondary"
                    loading={submitting}
                    disabled={submitting}
                    onClick={() => {
                      setSelectedDetailItem(undefined);
                      setConfig('keepDirtyOnReinitialize', false);
                      reset();
                      setConfig('keepDirtyOnReinitialize', true);
                    }}
                  >
                    Discard changes
                  </NvButton>
                  <NvButton
                    size="small"
                    color="primary"
                    loading={submitting}
                    disabled={submitting}
                    onClick={() => {
                      submit();
                    }}
                  >
                    Save
                  </NvButton>
                </>
              }
              {...(!catalogEntityType?.managed &&
                hasCatalogEditPermission && {
                  onEditNameClick: () => {
                    onModalOpenClicked();
                  },
                })}
              {...(catalogEntityType?.managed && {
                sectionNextToTitle: (
                  <NvTooltip title={'This entity is managed by Actioner, edit or delete operations are not allowed.'}>
                    <NvLockIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                      }}
                      htmlColor={palette.nv_neutral[300]}
                    />
                  </NvTooltip>
                ),
              })}
            />
          );
        }}
      </DynamicMaterialIcons>
      <NvDialogModal<CatalogEntityType>
        formProps={{
          initialValues: catalogEntityType,
        }}
        Header="Update catalog"
        maxWidth="sm"
        fullWidth
        modalIcon="edit"
        Body={<EntityTypeModalBody mode="edit" />}
        onCloseButtonClicked={onModalCloseClicked}
        open={isOpened}
        primaryButtonText="Update"
        onFormSubmit={(values) => {
          updateCatalogEntityType({
            id: values.id,
            payload: {
              entityType: values,
            },
          });
          onModalCloseClicked();
        }}
      />
    </>
  );
};
