import { useForm } from 'react-final-form';

export const useKeyValueInput = ({
  fieldArrayName,
  keyDefaultText,
  valueDefaultText,
  keyFieldName,
  valueFieldName,
}: {
  fieldArrayName: string;
  keyDefaultText?: string;
  valueDefaultText?: string;
  keyFieldName: string;
  valueFieldName: string;
}) => {
  const {
    mutators: { push, remove },
  } = useForm();

  const handleAddNewInput = () => {
    push(fieldArrayName, {
      [keyFieldName]: keyDefaultText ?? '',
      [valueFieldName]: valueDefaultText ?? '',
    });
  };

  const handleOnRemoveInput = (index: number) => {
    remove(fieldArrayName, index);
  };

  return {
    onAddNewInput: handleAddNewInput,
    handleOnRemoveInput,
  };
};
