import {
  NvBox,
  NvCustomCheckIcon,
  NvErrorIcon,
  NvFlex,
  NvTooltip,
  NvTrackChangesIcon,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { NodeType } from '../property-panel';
import { useWorkflowNodeUtilities } from '../property-panel/utils';
import { NodeItem, NodeItemLeft, NodeItemWrapper, TriggerIconContainer } from './styled';
import { NodeProps, NodeStatus } from './types';

export const Node = React.forwardRef<HTMLDivElement, NodeProps>(
  (
    {
      status,
      type,
      icon,
      name,
      alias,
      selected,
      actions,
      port1,
      port2,
      onClick,
      draggable = false,
      onDragStart,
      onDragEnd,
    },
    ref
  ) => {
    const theme = useTheme();
    const { getWorkflowNodeIcon } = useWorkflowNodeUtilities();

    return (
      <NodeItemWrapper draggable={draggable} onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {port1}
        <NodeItem selected={selected} onClick={onClick} ref={ref}>
          <NodeItemLeft>
            <NvFlex direction="row" alignItems="center" gap="4px">
              {status === NodeStatus.SUCCESS && (
                <NvTooltip title={'Everything looks working'}>
                  <NvBox sx={{ cursor: 'pointer' }} height="16px">
                    <NvCustomCheckIcon
                      sx={{ width: '16px', height: '16px' }}
                      htmlColor={theme.palette.nv_success[60]}
                    />
                  </NvBox>
                </NvTooltip>
              )}
              {actions}
            </NvFlex>
          </NodeItemLeft>
          {status === NodeStatus.ERROR && (
            <NvBox position="absolute" top="0" left="0" sx={{ transform: 'translate(-50%, -50%)' }}>
              <NvTooltip title="There has been an error with the configuration.">
                <NvBox sx={{ cursor: 'pointer' }} height="16px">
                  <NvErrorIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_error[40]} />
                </NvBox>
              </NvTooltip>
            </NvBox>
          )}

          <NvFlex direction="row" gap="8px" alignItems="center" width="100%" draggable={false}>
            <NvBox width="24px" height="24px" position="relative" flex="0 0 auto">
              {icon ? icon : getWorkflowNodeIcon({ type, width: 24, height: 24 })}
              {(type === NodeType.FORM_TRIGGER ||
                type === NodeType.EMAIL_TRIGGER ||
                type === NodeType.WEBHOOK_TRIGGER ||
                type === NodeType.INTEGRATION_TRIGGER) && (
                <TriggerIconContainer>
                  <NvTrackChangesIcon
                    sx={{ width: '12px', height: '12px' }}
                    htmlColor={theme.palette.nv_node.trigger}
                  />
                </TriggerIconContainer>
              )}
            </NvBox>

            <NvFlex width="100%" flex="1 1 auto" minWidth={0}>
              <NvTypography variant="h5" noWrap>
                {name}
              </NvTypography>
              {alias && (
                <NvTypography variant="c3" textColor="subtle" noWrap>
                  {`{{${alias}}}`}
                </NvTypography>
              )}
            </NvFlex>
          </NvFlex>
        </NodeItem>
        {port2}
      </NodeItemWrapper>
    );
  }
);
