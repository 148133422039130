import { useGetWorkflow } from '@novaera/actioner-service';
import { NvBox, NvConditionalWrap, NvTooltip } from '@novaera/core';
import { useParams } from '@novaera/route';

import { FC, useMemo } from 'react';
import { NodeItemWrapper } from '../../../../../../components/node/styled';
import { useWorkflowPermission } from '../../../../../user-app-permission-boundary/use-workflow-permission';
import { AddNewButton } from '../../components/add-new-button';
import { DropItemPlace } from '../../utils/nodes-drag-and-drop/drop-item-place';
import { DroppableItem } from '../../utils/nodes-drag-and-drop/droppable-item';
import { BaseNodeProps } from '../types';

export type AddButtonProps = BaseNodeProps;

export const AddButton: FC<React.PropsWithChildren<AddButtonProps>> = ({ port1, port2, id, disabled }) => {
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const { hasEditPermission } = useWorkflowPermission();

  const hasMissingConfigurationOnTrigger = useMemo(() => {
    if (!workflow?.trigger) {
      return true;
    }
    if (
      (workflow?.trigger.type === 'integrationApp' || workflow?.trigger.type === 'integrationWebhook') &&
      !workflow.trigger.eventRuleIdentifier.eventRuleId
    ) {
      return true;
    }
    return false;
  }, [workflow?.trigger]);

  const isDisabled = !hasEditPermission || hasMissingConfigurationOnTrigger;

  return (
    <DroppableItem id={id}>
      {({ isDragging, isDroppable }) => {
        return (
          <NodeItemWrapper>
            <NvBox width="16px" height="16px" position="relative">
              {port1}
              <NvConditionalWrap
                condition={disabled || isDisabled}
                wrap={(children) => {
                  return (
                    <NvTooltip
                      title={
                        !hasEditPermission
                          ? 'You are not allowed to add new nodes.'
                          : hasMissingConfigurationOnTrigger
                          ? 'You need to select event type to continue.'
                          : ''
                      }
                    >
                      {<span>{children}</span>}
                    </NvTooltip>
                  );
                }}
              >
                {isDragging && isDroppable ? (
                  <DropItemPlace />
                ) : (
                  <AddNewButton nodeId={id} type={'Node'} disabled={disabled || isDisabled} />
                )}
              </NvConditionalWrap>
              {port2}
            </NvBox>
          </NodeItemWrapper>
        );
      }}
    </DroppableItem>
  );
};
