import { NvAxios, useMutation } from '@novaera/core';
import { EsSearchPayload } from './types';

const esSearch = async (payload: EsSearchPayload) => {
  const result = await NvAxios.post('/admin/es/search', {
    ...payload,
  });
  return result?.data;
};

export const useEsSearch = () => {
  return useMutation(esSearch);
};
