import { NvAxios, useQuery } from '@novaera/core';
import { ACTIONER_EVENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS } from '../keys';
import { ListActionerEventUsagesParams, ListEventUsageResponse } from './types';

const listActionerEventUsages = async ({ appId, eventId }: ListActionerEventUsagesParams) => {
  const result = await NvAxios.get<ListEventUsageResponse>(`${ACTIONER_EVENTS_ROOT_PATH(appId)}/${eventId}/usages`);
  return result?.data;
};

export const useListActionerEventUsages = (params: ListActionerEventUsagesParams) => {
  const { appId, eventId } = params;
  return useQuery<ListEventUsageResponse>(
    QUERY_KEYS_ACTIONER_EVENTS.usages(appId, eventId),
    () => listActionerEventUsages(params),
    {
      enabled: !!appId && !!eventId,
    }
  );
};
