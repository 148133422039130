import { DynamicValueRuleType, MultiValueRuleType, Operation, SingleValueRuleType } from '@novaera/actioner-service';
import { LabelValue, NvListAltIcon, NvTagIcon, NvTitleIcon, NvToggleOnIcon } from '@novaera/core';
import { Options } from './types';
import { getConditionLabel } from './utils';

export const OPTIONS = (param?: {
  type?: SingleValueRuleType | MultiValueRuleType | DynamicValueRuleType;
}): Options[] => {
  const result = [
    {
      label: getConditionLabel({ operator: Operation.EQUALS }),
      value: {
        operation: Operation.EQUALS,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.EQUALS, negateResult: true }),
      value: {
        operation: Operation.EQUALS,
        negateResult: true,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.CONTAINS }),
      value: {
        operation: Operation.CONTAINS,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.CONTAINS, negateResult: true }),
      value: {
        operation: Operation.CONTAINS,
        negateResult: true,
      },
      enabled: true,
    },

    {
      label: getConditionLabel({ operator: Operation.GREATER_THAN }),
      value: {
        operation: Operation.GREATER_THAN,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.GREATER_THAN_OR_EQUAL_TO }),
      value: {
        operation: Operation.GREATER_THAN_OR_EQUAL_TO,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.LESS_THAN }),
      value: {
        operation: Operation.LESS_THAN,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.LESS_THAN_OR_EQUAL_TO }),
      value: {
        operation: Operation.LESS_THAN_OR_EQUAL_TO,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.STARTS_WITH }),
      value: {
        operation: Operation.STARTS_WITH,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.STARTS_WITH, negateResult: true }),
      value: {
        operation: Operation.STARTS_WITH,
        negateResult: true,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.ENDS_WITH }),
      value: {
        operation: Operation.ENDS_WITH,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.ENDS_WITH, negateResult: true }),
      value: {
        operation: Operation.ENDS_WITH,
        negateResult: true,
      },
      enabled: true,
    },

    {
      label: getConditionLabel({ operator: Operation.IS_ONE_OF }),
      value: {
        operation: Operation.IS_ONE_OF,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.IS_ONE_OF, negateResult: true }),
      value: {
        operation: Operation.IS_ONE_OF,
        negateResult: true,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.IS_EMPTY }),
      value: {
        operation: Operation.IS_EMPTY,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.IS_EMPTY, negateResult: true }),
      value: {
        operation: Operation.IS_EMPTY,
        negateResult: true,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.EXISTS }),
      value: {
        operation: Operation.EXISTS,
        negateResult: false,
      },
      enabled: true,
    },
    {
      label: getConditionLabel({ operator: Operation.EXISTS, negateResult: true }),
      value: {
        operation: Operation.EXISTS,
        negateResult: true,
      },
      enabled: true,
    },
  ].filter((option) => option.enabled);
  return result;
};

export const VALUE_TYPE_OPTIONS: Record<
  Operation,
  LabelValue<SingleValueRuleType | MultiValueRuleType | DynamicValueRuleType>[]
> = {
  [Operation.EQUALS]: [
    {
      label: 'String',
      value: SingleValueRuleType.STRING,
      startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} />,
    },
    {
      label: 'Number',
      value: SingleValueRuleType.NUMBER,
      startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} />,
    },
    {
      label: 'Boolean',
      value: SingleValueRuleType.BOOLEAN,
      startIcon: <NvToggleOnIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.GREATER_THAN]: [
    {
      label: 'Number',
      value: SingleValueRuleType.NUMBER,
      startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.GREATER_THAN_OR_EQUAL_TO]: [
    {
      label: 'Number',
      value: SingleValueRuleType.NUMBER,
      startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.LESS_THAN]: [
    {
      label: 'Number',
      value: SingleValueRuleType.NUMBER,
      startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.LESS_THAN_OR_EQUAL_TO]: [
    {
      label: 'Number',
      value: SingleValueRuleType.NUMBER,
      startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.STARTS_WITH]: [
    {
      label: 'String',
      value: SingleValueRuleType.STRING,
      startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.ENDS_WITH]: [
    {
      label: 'String',
      value: SingleValueRuleType.STRING,
      startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.IS_ONE_OF]: [
    {
      label: 'List',
      value: MultiValueRuleType.LIST,
      startIcon: <NvListAltIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],

  [Operation.IS_EMPTY]: [
    {
      label: 'String',
      value: SingleValueRuleType.STRING,
      startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.EXISTS]: [
    {
      label: 'String',
      value: SingleValueRuleType.STRING,
      startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
  [Operation.CONTAINS]: [
    {
      label: 'String',
      value: SingleValueRuleType.STRING,
      startIcon: <NvTitleIcon sx={{ width: '16px', height: '16px' }} />,
    },
    {
      label: 'Number',
      value: SingleValueRuleType.NUMBER,
      startIcon: <NvTagIcon sx={{ width: '16px', height: '16px' }} />,
    },
    {
      label: 'Boolean',
      value: SingleValueRuleType.BOOLEAN,
      startIcon: <NvToggleOnIcon sx={{ width: '16px', height: '16px' }} />,
    },
  ],
};

export const DEFAULT_CONDITION_TYPE_OPTIONS = [
  { label: 'No filter', value: 'no-condition' },
  { label: 'One or more conditions below', value: 'match-any-condition' },
  { label: 'All conditions below', value: 'match-all-condition' },
  { label: 'Advanced mode', value: 'javascript', hasDivider: true },
];

export const FILTERED_CONDITION_TYPE_OPTIONS = DEFAULT_CONDITION_TYPE_OPTIONS.filter((f) => f.value !== 'javascript');

export const ACTION_CONDITION_TYPE_OPTIONS = FILTERED_CONDITION_TYPE_OPTIONS.filter((f) => f.value !== 'no-condition');
