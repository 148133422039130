import { SvgIcon } from '@mui/material';
import { styled } from '@novaera/theme-provider';

export const SpinnerSVG = styled(SvgIcon)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
