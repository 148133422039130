import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const VersionHistoryContainer = styled(NvBox)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 6px;
  overflow: hidden;
`;

export const VersionHistoryHeader = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 4px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 6px;
  cursor: pointer;

  .chevron-icon {
    transition: transform 200ms ease-in-out;
  }

  &.is-open {
    border-radius: 6px 6px 0 0;
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
`;

export const VersionHistoryBody = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
`;
