import { useSaveWorkflowPermissions } from '@novaera/actioner-service';
import {
  NvButton,
  NvConditionalRender,
  NvDialogModal,
  NvSupervisorAccountRoundedIcon,
  NvTooltip,
  useNvDialogModalState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { noop } from 'lodash';
import { useWorkflowPermission } from '../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { WorkflowStickyPanelButton } from '../styled';
import { WorkflowPermissionsModalBody } from './body';
import { useWorkflowUsersWithPermissions } from './controllers/use-workflow-users-with-permissions';
import { WorkflowPermissionGroupAndUser } from './controllers/use-workflow-users-with-permissions/types';

export const WorkflowPermissions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId, workflowId } = useParams();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { workflowPermissionsUsers, isLoading: isWorkflowPermissionsUsersLoading } = useWorkflowUsersWithPermissions({
    userAppId,
    workflowId,
  });
  const { mutate: saveWorkflowPermissions, isLoading: isSaveWorkflowPermissionLoading } = useSaveWorkflowPermissions();

  const { hasEditPermission } = useWorkflowPermission();

  const handleWorkflowPermissionsClicked = () => {
    onModalOpenClicked();
  };

  const handleModalClose = () => {
    onModalCloseClicked();
  };

  return (
    <>
      <NvTooltip title="Manage workflow permissions" placement="top">
        <WorkflowStickyPanelButton
          id="manage-workflow-permission-button"
          onlyIcon
          size="small"
          color="ghost"
          onClick={handleWorkflowPermissionsClicked}
        >
          <NvSupervisorAccountRoundedIcon />
        </WorkflowStickyPanelButton>
      </NvTooltip>

      <NvConditionalRender when={hasEditPermission}>
        <NvDialogModal<{ users: WorkflowPermissionGroupAndUser[] }>
          formProps={{ initialValues: { users: workflowPermissionsUsers }, keepDirtyOnReinitialize: true }}
          useArrayMutators
          modalIcon="supervisorAccount"
          Header="Manage workflow permissions"
          fullWidth
          maxWidth="sm"
          onCloseButtonClicked={handleModalClose}
          open={isOpened}
          isLoading={isSaveWorkflowPermissionLoading}
          primaryButtonText="Override"
          onFormSubmit={(values) =>
            new Promise<void>((resolve) => {
              saveWorkflowPermissions(
                {
                  appId: userAppId,
                  workflowId,
                  permissions: values?.users?.map(({ principal, scope }) => ({ principal, scope })) ?? [],
                },
                {
                  onSuccess: () => {
                    handleModalClose();
                  },
                  onSettled: () => {
                    resolve();
                  },
                }
              );
            })
          }
          Body={<WorkflowPermissionsModalBody isLoading={isWorkflowPermissionsUsersLoading} />}
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <NvDialogModal<{ users: WorkflowPermissionGroupAndUser[] }>
          formProps={{ initialValues: { users: workflowPermissionsUsers }, keepDirtyOnReinitialize: true }}
          useArrayMutators
          modalIcon="supervisorAccount"
          Header="Manage workflow permissions"
          fullWidth
          maxWidth="sm"
          onCloseButtonClicked={handleModalClose}
          open={isOpened}
          isLoading={isSaveWorkflowPermissionLoading}
          onFormSubmit={noop}
          Body={<WorkflowPermissionsModalBody isLoading={isWorkflowPermissionsUsersLoading} />}
          Footer={
            <NvButton onClick={() => handleModalClose()} color="secondary">
              Done
            </NvButton>
          }
        />
      </NvConditionalRender>
    </>
  );
};
