import { useFetchProfile } from '@novaera/actioner-service';
import React, { useEffect, useState } from 'react';
import { WorkspaceCreate } from '..';
import { SignInWrapper } from '../../../styled';
import { useCreateWorkspace } from '../../create/controllers/use-create-workspace';

export const StandAloneWorkspaceCreate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [username, setUsername] = useState<string>('');

  const fetchAuthService = useFetchProfile({ enabled: false });

  const { onSubmit, getSuggestedName } = useCreateWorkspace({ username, reason: 'workspace-creation' });

  useEffect(() => {
    const getUsername = async () => {
      const result = await fetchAuthService.refetch();
      const suggestedName = await getSuggestedName(result.data?.email);
      setUsername(suggestedName);
    };
    getUsername();
  }, []);

  return (
    <SignInWrapper>
      <WorkspaceCreate
        suggestedName={username}
        onCancelClicked={() => {
          window.history.go(-1);
        }}
        onSubmit={onSubmit}
      />
    </SignInWrapper>
  );
};
