import { NvFlex, NvTypography } from '@novaera/core';
import { PropsWithChildren } from 'react';
import { AIAssistantSectionProps } from './types';

export const AIAssistantSection: React.FC<PropsWithChildren<AIAssistantSectionProps>> = ({
  title,
  leftSlot,
  infoText,
  children,
}) => (
  <NvFlex gap="8px" alignItems="flex-start" padding="16px 0">
    <NvFlex direction="row" alignItems="center" gap="4px">
      {leftSlot}
      <NvTypography variant="h4">{title}</NvTypography>
    </NvFlex>
    <NvTypography variant="body2">{infoText}</NvTypography>
    {children}
  </NvFlex>
);
