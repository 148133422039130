import { styled } from '@novaera/theme-provider';

export const StyledDivider = styled('div')`
  z-index: 100;

  &.horizontal-divider {
    position: relative;
    flex: 0 0 auto;
    background-color: transparent;
    cursor: ew-resize;
    height: 100%;
    width: 24px;

    &:hover {
      &::after {
        background-color: ${({ theme }) => theme.palette.nv_main[40]};
        width: 3px;
        margin-left: -1px;
      }
    }

    &:active {
      &::after {
        background-color: ${({ theme }) => theme.palette.nv_main[40]};
        width: 3px;
        margin-left: -1px;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 1px);
      top: 0;
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      z-index: 1;
      width: 1px;
      height: 100%;
      transition: width 0.2s ease-in-out, background-color 0.3s ease-in-out, margin 0.2s ease-in-out;
    }
  }

  &.vertical-divider {
    background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
    cursor: ns-resize;
    height: 1px;
    width: 100%;
  }
`;

export const SplitPaneContainer = styled('div')<{
  direction: 'horizontal' | 'vertical';
}>`
  display: flex;
  height: 100%;
  flex-direction: ${({ direction }) => (direction === 'horizontal' ? 'row' : 'column')};
`;
