import { NvButton, NvCloseIcon, NvConditionalRender } from '@novaera/core';

import { NodeType, PropertyPanelHeader } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { usePanelHeaderController } from './controllers/use-panel-header';
import { PanelHeaderProps } from './types';

export const PanelHeader = ({ workflow, integration, onCloseClicked }: PanelHeaderProps) => {
  const { onNameChanged } = usePanelHeaderController({ workflow, integration });
  const { hasEditPermission } = useWorkflowPermission();
  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          title={workflow.trigger?.name}
          type={NodeType.INTEGRATION_TRIGGER}
          onTitleChange={onNameChanged}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={workflow.trigger?.name}
          type={NodeType.INTEGRATION_TRIGGER}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
    </>
  );
};
