import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomDateTime({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H12.2558C11.8342 21.3959 11.5055 20.7224 11.2903 20H6C5.45 20 5 19.55 5 19V9H19V11.071C19.7061 11.1721 20.3782 11.3788 21 11.6744V6C21 4.9 20.1 4 19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3ZM13 18C13 15.24 15.24 13 18 13C20.76 13 23 15.24 23 18C23 20.76 20.76 23 18 23C15.24 23 13 20.76 13 18ZM17.64 18.35L19.29 20C19.49 20.2 19.8 20.2 19.99 20.01C20.19 19.81 20.19 19.5 19.99 19.3L18.49 17.8V15.51C18.49 15.23 18.27 15.01 17.99 15.01C17.71 15.01 17.49 15.23 17.49 15.51V18C17.49 18.13 17.55 18.26 17.64 18.35Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
