import { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { NvChevronLeftIcon, NvChevronRightIcon } from '../icons';
import { CarouselBackdrop } from './carousel-backdrop';
import { CarouselItemCard } from './carousel-item';
import { CarouselThumbCard } from './carousel-thumb';
import { CarouselDirectionButtonLeft, CarouselDirectionButtonRight, CustomCarousel } from './styled';
import { CarouselItemType, NvCarouselProps } from './types';

export const NvCarousel: React.FC<React.PropsWithChildren<NvCarouselProps>> = ({ title, items, ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleBackdropClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <CustomCarousel
        key="carousel-on-page"
        infiniteLoop
        thumbWidth={130}
        showThumbs
        dynamicHeight
        showStatus={false}
        showIndicators={false}
        swipeable={isMobile}
        onChange={(index) => {
          setSelectedItem(index);
        }}
        onClickItem={(index, item) => {
          if (isMobile) {
            return;
          }

          if ((item as React.ReactElement).props.type === CarouselItemType.IMG) {
            setIsOpen(true);
          }
        }}
        renderThumbs={(thumbs) =>
          thumbs.map((thumb, index) => (
            <CarouselThumbCard key={`carousel-on-page-thumbs-card-${index}`} {...(thumb as React.ReactElement).props} />
          ))
        }
        renderArrowNext={(onClick, hasNext, label) => (
          <CarouselDirectionButtonRight size="small" color="secondary" onlyIcon onClick={onClick} title={label}>
            <NvChevronRightIcon />
          </CarouselDirectionButtonRight>
        )}
        renderArrowPrev={(onClick, hasNext, label) => (
          <CarouselDirectionButtonLeft size="small" color="secondary" onlyIcon onClick={onClick} title={label}>
            <NvChevronLeftIcon />
          </CarouselDirectionButtonLeft>
        )}
        altText={items[selectedItem]?.displayText}
        {...props}
      >
        {items.map((item, index) => (
          <CarouselItemCard key={`carousel-on-page-item-card-${index}`} {...item} isActive={selectedItem === index} />
        ))}
      </CustomCarousel>
      {isOpen && (
        <CarouselBackdrop
          isOpen={isOpen}
          title={title}
          items={items}
          initialSelectedItem={selectedItem}
          handleBackdropClose={handleBackdropClose}
        />
      )}
    </>
  );
};
