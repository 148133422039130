import { AdvanceFieldTypes, BaseFieldType, FieldType, useGetDataModels } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { NestedDropdownItem, useField } from '@novaera/core';
import { generateUniqueId } from '@novaera/utils';
import { snakeCase } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { useParams } from '@novaera/route';
import { DataModelChooseFieldTypeModal } from './data-model-choose-field-type-modal';
import { ChooseFieldTypeModalOnSubmitParamType } from './data-model-choose-field-type-modal/types';
import { FieldBlock } from './field-block';
import { DataModelLayoutLeftContentProps } from './types';

export const DataModelLayoutLeftContent = <FieldTypeType extends BaseFieldType>({
  fields,
  setSelectedFieldIndex,
  selectedFieldIndex,
  fieldMenus,
  fieldTypeSchemas,
  fieldMenuItems,
  fieldsFieldName,
  RightContent,
  fieldHeaderProps,
  fieldHeaderActionButtonProps,
}: DataModelLayoutLeftContentProps<FieldTypeType>) => {
  const [advanceFieldType, setAdvanceFieldType] = useState<AdvanceFieldTypes | undefined>();

  const { userAppId: appId, modelId } = useParams();
  const { data, isLoading } = useGetDataModels({ appId });
  const referenceModels = useMemo(() => data?.models.map((m) => ({ value: m.id, label: m.name })), [data?.models]);

  const {
    input: { onChange, value: fieldsValue },
  } = useField<FieldType[]>(fieldsFieldName);

  const handleSelectField = useCallback(
    (selectedField: NestedDropdownItem) => {
      const { id, name } = selectedField;
      const addedFieldTypeCount = fieldsValue.filter(
        (f) => f.schema.type === selectedField.id && f.name !== 'id'
      ).length;

      const selectedSchema = fieldTypeSchemas[id as SchemaType];
      const newField: FieldType = {
        id: generateUniqueId(),
        name: addedFieldTypeCount === 0 ? snakeCase(name) : `${snakeCase(name)}_${addedFieldTypeCount}`,
        schema: selectedSchema,
      };

      if ([SchemaType.array, SchemaType.map, SchemaType.recordReference].includes(selectedField.id as SchemaType)) {
        setAdvanceFieldType(newField as AdvanceFieldTypes);
      } else {
        onChange([...fields, newField]);
        setSelectedFieldIndex(fields.length);
      }
    },
    [fieldTypeSchemas, fields, fieldsValue, onChange, setSelectedFieldIndex]
  );

  const handleOnSubmit = (values?: ChooseFieldTypeModalOnSubmitParamType) => {
    const fieldType = advanceFieldType?.schema.type;
    if (fieldType && fieldType === SchemaType.recordReference) {
      const newFieldType = { ...advanceFieldType, schema: { ...advanceFieldType.schema, ...values } };
      onChange([...fields, newFieldType]);
    } else if (fieldType && (fieldType === SchemaType.array || fieldType === SchemaType.map)) {
      const newFieldType = { ...advanceFieldType, schema: { ...advanceFieldType.schema, valueSchema: values } };
      onChange([...fields, newFieldType]);
    }
    setSelectedFieldIndex(fields.length);
    setAdvanceFieldType(undefined);
  };

  return (
    <>
      <FieldBlock<FieldTypeType>
        fields={fields}
        setSelectedFieldIndex={setSelectedFieldIndex}
        selectedFieldIndex={selectedFieldIndex}
        fieldMenus={fieldMenus}
        fieldMenuItems={fieldMenuItems}
        onFieldSelected={handleSelectField}
        RightContent={RightContent}
        fieldHeaderProps={fieldHeaderProps}
        fieldHeaderActionButtonProps={fieldHeaderActionButtonProps}
      />

      {advanceFieldType && (
        <DataModelChooseFieldTypeModal
          isOpen={!!advanceFieldType}
          selectedField={advanceFieldType}
          onClose={() => setAdvanceFieldType(undefined)}
          onSubmit={handleOnSubmit}
          modelId={modelId}
          referenceModels={referenceModels}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
