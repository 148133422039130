import {
  isValidJSON,
  NvButton,
  NvConditionalWrap,
  NvField,
  NvFlex,
  NvTooltip,
  NvTypography,
  useForm,
} from '@novaera/core';
import { PropsWithChildren } from 'react';
import { JsonInput } from '../styled';
import { INVALID_JSON_MESSAGE } from './constants';
import { ConfigDetailContainer, ConfigJsonInputWrapper } from './styled';
import { prettifyProperties } from './utils';

export const ConfigDetailBody: React.FC<PropsWithChildren<{ valid: boolean }>> = ({ valid }) => {
  const form = useForm();
  const properties = form.getFieldState('properties')?.value;
  return (
    <ConfigDetailContainer>
      <NvFlex height="100%" gap="8px" width="100%">
        <ConfigJsonInputWrapper>
          <NvField
            formControlStyle={{
              minHeight: 0,
              width: '100%',
            }}
            name={'properties'}
            showErrorMessageOnlyWhenBlur
            validators={[isValidJSON(INVALID_JSON_MESSAGE)]}
            component={<JsonInput lineNumbers context={{}} expectedType="object" mode="json" disableExpandMode />}
          />
        </ConfigJsonInputWrapper>

        <NvFlex direction="row" alignItems="center" gap="8px" justifyContent="flex-end">
          <NvTypography variant="h8" textColor="ghost">
            Only JSON supported.
          </NvTypography>
          <NvConditionalWrap
            condition={!valid}
            wrap={(children) => (
              <NvTooltip title={INVALID_JSON_MESSAGE}>
                <div>{children}</div>
              </NvTooltip>
            )}
          >
            <NvButton
              size="small"
              isFitContent
              color="secondary"
              onClick={() => {
                if (properties) {
                  form.change('properties', prettifyProperties(properties));
                }
              }}
              disabled={!valid || !properties}
            >
              Beautify
            </NvButton>
          </NvConditionalWrap>
        </NvFlex>
      </NvFlex>
    </ConfigDetailContainer>
  );
};
