import { EmailTrigger, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvContentCopyIcon,
  NvCustomConditionIcon,
  NvFlex,
  NvMailIcon,
  NvTextField,
  NvTooltip,
  NvTypography,
} from '@novaera/core';
import { assert, getSubdomain, useCopyToClipboard } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { DefaultPrincipalPropertyPanelSection } from '../../common/default-principal-property-panel-section';
import { PropertiesLoading } from '../../common/properties-loading';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { useGetLatestCapturedEventPayload } from '../../controllers/use-get-latest-captured-event-payload';
import { TriggerPayloadPanel } from '../../integration-trigger-property-panel-drawer/integration-trigger-properties/trigger-payload-panel';
import { EmailAddressCard } from './email-address-card';
import { EmailTriggerPropertiesProps } from './types';

export const EmailTriggerProperties: FC<EmailTriggerPropertiesProps> = ({
  workflow,
  onCloseClicked,
  onRootConditionClicked,
}) => {
  const { mutate: saveToDraftWorkflow } = useSaveToDraftWorkflow();
  const { selectedNode } = usePropertyPanelContext();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const [isCopied, setIsCopied] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const { hasEditPermission } = useWorkflowPermission();
  const { getCapturedData, isCapturingPayload } = useGetLatestCapturedEventPayload();
  assert(
    !!(workflow.trigger?.type === 'email'),
    new Error('[EmailTriggerProperties] - Type of trigger should be email.'),
    'ERROR'
  );
  const workflowTrigger: EmailTrigger = workflow.trigger;
  const [suffix, setSuffix] = useState<string>(workflowTrigger.emailLocalPartSuffix);
  const [debouncedSuffix] = useDebounce(suffix, 500);
  const emailPrefix = useMemo(() => `${getSubdomain()}`, []);
  const emailPostfix = useMemo(() => {
    if (!workflowTrigger.emailAddress) return '';

    const emailSplit = workflowTrigger.emailAddress.split('@');
    return `@${emailSplit[emailSplit.length - 1]}`;
  }, [workflowTrigger.emailAddress]);
  const currentEmailAddress = useMemo(
    () => `${emailPrefix}.${debouncedSuffix}${emailPostfix}`,
    [debouncedSuffix, emailPostfix, emailPrefix]
  );
  const tooltipText = useMemo(() => (isCopied ? 'Copied!' : currentEmailAddress), [currentEmailAddress, isCopied]);

  useEffect(() => {
    if (hasEditPermission && workflowTrigger.emailLocalPartSuffix !== debouncedSuffix) {
      saveToDraftWorkflow({
        ...workflow,
        trigger: {
          ...workflowTrigger,
          emailLocalPartSuffix: debouncedSuffix,
        },
      });
    }
  }, [debouncedSuffix, hasEditPermission, saveToDraftWorkflow, workflow, workflowTrigger]);

  return (
    <NvFlex width="100%">
      {workflowTrigger ? (
        <>
          <NvConditionalRender when={hasEditPermission}>
            <PropertyPanelHeader
              title={workflowTrigger.name}
              type={NodeType.EMAIL_TRIGGER}
              onTitleChange={async (value) => {
                if (value) {
                  return new Promise<void>((resolve) => {
                    const trigger: EmailTrigger = {
                      ...workflowTrigger,
                      type: 'email',
                      name: value,
                    };

                    saveToDraftWorkflow(
                      { ...workflow, trigger },
                      {
                        onSuccess: () => {
                          if (selectedNode) {
                            const graphNode = userAppGraph.node(selectedNode.alias);
                            const newGraphNode = cloneDeep(graphNode);
                            newGraphNode.name = value;
                            updateNode({ newNode: newGraphNode });
                          }
                        },
                        onSettled: () => {
                          resolve();
                        },
                      }
                    );
                  });
                }
              }}
              validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
          </NvConditionalRender>
          <NvConditionalRender when={!hasEditPermission}>
            <PropertyPanelHeader
              title={workflowTrigger.name}
              type={NodeType.EMAIL_TRIGGER}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
          </NvConditionalRender>

          <PropertyPanelSection
            title="Email"
            icon={<NvMailIcon />}
            tooltip="This workflow will run when an email is received at the provided email address below."
          >
            <NvFlex gap="8px">
              <EmailAddressCard
                emailPrefix={emailPrefix}
                emailPostfix={emailPostfix}
                endSlot={
                  <NvTooltip
                    title={tooltipText}
                    onClose={() => {
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 300);
                    }}
                  >
                    <NvButton
                      onlyIcon
                      size="small"
                      color="ghost"
                      sx={{ flex: '0 0 auto' }}
                      onClick={() => {
                        copyToClipboard(currentEmailAddress);
                        setIsCopied(true);
                      }}
                    >
                      <NvContentCopyIcon />
                    </NvButton>
                  </NvTooltip>
                }
              >
                <NvTextField
                  sx={{ flex: '1 1 0' }}
                  size="small"
                  value={suffix}
                  onChange={(e) => {
                    setSuffix(e.target.value);
                  }}
                />
              </EmailAddressCard>

              <NvConditionalRender when={hasEditPermission}>
                <NvFlex direction="row" gap="8px" alignItems="center">
                  <NvButton
                    color="success"
                    size="small"
                    loading={isCapturingPayload}
                    onClick={() => {
                      getCapturedData();
                    }}
                  >
                    Capture event
                  </NvButton>

                  <NvTypography variant="body3" textColor="subtle">
                    Capture event to see the payload.
                  </NvTypography>
                </NvFlex>
              </NvConditionalRender>
            </NvFlex>
          </PropertyPanelSection>
          <DefaultPrincipalPropertyPanelSection defaultStarterUserId={workflowTrigger.defaultStarterUserId} />
          <TriggerPayloadPanel />
          <PropertyPanelSimpleSection>
            <NvFlex gap="8px">
              <RowItemCard
                rowItemLeftContentProps={{ draggable: false, icon: <NvCustomConditionIcon /> }}
                rowItemContent={<SimpleLabelLayout simpleLabel="Trigger conditions" />}
                onClick={onRootConditionClicked}
              />
            </NvFlex>
          </PropertyPanelSimpleSection>
        </>
      ) : (
        <PropertiesLoading />
      )}
    </NvFlex>
  );
};
