import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { UserAppPageLayoutWrapper } from '../../../components/user-app-page-layout/styled';

export const AssistantPageLayoutWrapper = styled(UserAppPageLayoutWrapper)`
  padding: 0 0 60px 0;

  @media screen and (max-width: 1440px) {
    padding: 0 0 60px 0;
  }
`;

export const NodeListItemsWrapper = styled(NvFlex)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  overflow: hidden;
  width: 100%;
`;
