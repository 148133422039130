import { USER_PERMISSION, UserRole, WorkspaceUser } from '@novaera/actioner-service';
import {
  AccountCircleIcon,
  BasicTableCell,
  BasicTableRow,
  NeutralChipColor,
  NvBasicTable,
  NvButton,
  NvChip,
  NvDeleteOutlineIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvKeyboardArrowDownIcon,
  NvTypography,
} from '@novaera/core';
import { capitalize } from 'lodash';
import { UserPermissionBoundary } from '../../../../user-permission-boundary';
import { UserRoleSelect } from '../../users/user-role-select';

export const UserList = ({
  users,
  invitedUsers,
  availableRoles,
  inProgressDeletingUser,
  inProgressUpdatingUser,
  onResentInvitationClick,
  onUpdateUserRoleChange,
  onRemoveClicked,
}: {
  availableRoles?: string[];
  users?: WorkspaceUser[];
  invitedUsers: string[];
  inProgressDeletingUser: string[];
  inProgressUpdatingUser: string[];
  onResentInvitationClick: (user: WorkspaceUser) => void;
  onRemoveClicked: (user: WorkspaceUser) => void;
  onUpdateUserRoleChange: (user: WorkspaceUser, roleId: UserRole) => void;
}) => {
  return (
    <NvBasicTable
      tableBody={
        users ? (
          users.map((user) => (
            <BasicTableRow key={`${user.email}-${user.userId}`}>
              <BasicTableCell>
                <NvFlex direction="row" gap="16px" alignItems="center">
                  <NvImage
                    FallBack={<AccountCircleIcon sx={{ width: '32px', height: '32px' }} />}
                    size="medium"
                    src={user.logoUrl}
                  />
                  <NvTypography variant="h4">{user.userName}</NvTypography>
                  <NvDivider orientation="vertical" sx={{ height: '10px' }} />
                  <NvTypography variant="body3">{user.email}</NvTypography>

                  {user.invitationStatus === 'pending' && (
                    <NvChip color={NeutralChipColor.DARK_ALPHA} label="PENDING" compact />
                  )}
                </NvFlex>
              </BasicTableCell>

              <BasicTableCell width="176px">
                <NvFlex direction="row" gap="8px" justifyContent="flex-end" height="32px" padding="4px 0">
                  {user.invitationStatus === 'pending' && (
                    <NvButton
                      loading={Boolean(invitedUsers.find((f) => f === user.email))}
                      color="secondary"
                      size="small"
                      onClick={() => {
                        onResentInvitationClick(user);
                      }}
                      sx={{
                        minWidth: 0,
                        flex: '0 0 auto',
                      }}
                    >
                      Resend invitation
                    </NvButton>
                  )}
                  <UserPermissionBoundary
                    permission={USER_PERMISSION.USER_CHANGE}
                    Fallback={
                      <NvButton size="small" color="secondary" endIcon={<NvKeyboardArrowDownIcon />} disabled>
                        {capitalize(user.roleId)}
                      </NvButton>
                    }
                  >
                    {availableRoles &&
                      (inProgressUpdatingUser.find((u) => u === user.email) ? (
                        <NvButton size="small" color="secondary" endIcon={<NvKeyboardArrowDownIcon />} loading>
                          {capitalize(user.roleId)}
                        </NvButton>
                      ) : (
                        <UserRoleSelect
                          value={user.roleId}
                          onChange={(roleId: UserRole) => onUpdateUserRoleChange?.(user, roleId)}
                          availableRoles={availableRoles}
                        />
                      ))}
                  </UserPermissionBoundary>
                  <UserPermissionBoundary permission={USER_PERMISSION.USER_UNINVITE}>
                    <NvButton
                      onlyIcon
                      size="small"
                      color="ghost"
                      onClick={() => onRemoveClicked(user)}
                      loading={Boolean(inProgressDeletingUser.find((u) => u === user.email))}
                    >
                      <NvDeleteOutlineIcon />
                    </NvButton>
                  </UserPermissionBoundary>
                </NvFlex>
              </BasicTableCell>
            </BasicTableRow>
          ))
        ) : (
          <BasicTableRow key="no-user-data">
            <BasicTableCell>---</BasicTableCell>
            <BasicTableCell width="125px">---</BasicTableCell>
            <BasicTableCell width="176px">---</BasicTableCell>
          </BasicTableRow>
        )
      }
    ></NvBasicTable>
  );
};
