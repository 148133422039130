import { NvButton, NvDeleteOutlineIcon, NvFlex } from '@novaera/core';
import { RecordsLayout } from '../../../../../components/records-layout';
import { RecordsSearch } from '../../../../../components/records-search';
import { RecordsTable } from '../../../../../components/records-table';
import { FreeAppPermissionBoundary } from '../../../../user-app-permission-boundary/free-app-permission-boundary';
import { RECORD_LIMIT } from './constants';
import { useRecordsDetail } from './controller/use-records-detail';
import { RecordModal } from './record-modal';

export const RecordsDetail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    model,
    rows,
    columns,
    totalHits,
    isModelLoading,
    isSearchRecordsLoading,
    searchQueryKeyword,
    handleSearchChange,
    handleSearchEnter,
    selectedRowIds,
    handleSelectionChange,
    handleDeleteRecords,
    page,
    handlePageChange,
    isSearchRecordsRefetching,
    handleRefresh,
    handleSortModelChange,
    isRecordModalOpen,
    setIsRecordModalOpen,
    selectedRecord,
    handleRecordModelClose,
  } = useRecordsDetail();

  return (
    <RecordsLayout
      HeaderComponent={
        selectedRowIds.length > 0 ? (
          <FreeAppPermissionBoundary>
            <NvFlex direction="row" alignItems="center" gap="8px" padding="16px">
              <NvButton size="small" startIcon={<NvDeleteOutlineIcon />} color="error" onClick={handleDeleteRecords}>
                Delete records ({selectedRowIds.length})
              </NvButton>
              <NvButton
                size="small"
                color="secondary"
                onClick={() => {
                  handleSelectionChange([]);
                }}
              >
                Cancel
              </NvButton>
            </NvFlex>
          </FreeAppPermissionBoundary>
        ) : (
          <RecordsSearch
            search={searchQueryKeyword}
            onChange={handleSearchChange}
            onEnter={handleSearchEnter}
            handleRefresh={handleRefresh}
            onClickCreateButton={() => {
              setIsRecordModalOpen(true);
            }}
          />
        )
      }
    >
      {!isModelLoading && (
        <RecordsTable
          rows={rows}
          columns={columns}
          totalHits={totalHits}
          loading={isSearchRecordsLoading || isSearchRecordsRefetching}
          selectionModel={selectedRowIds}
          onSelectionModelChange={handleSelectionChange}
          page={page}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortModelChange}
          pageSize={RECORD_LIMIT}
        />
      )}
      {isRecordModalOpen && model && (
        <RecordModal record={selectedRecord} table={model} onClose={handleRecordModelClose} />
      )}
    </RecordsLayout>
  );
};
