import {
  GroupEntity,
  PermissionEntity,
  PermissionPolicy,
  PermissionPolicySummary,
  SavePermissionToPolicyParams,
  SavePermissionToPolicyResponse,
  useAttachPermissionPolicyToGroup,
  useDeletePermissionFromPolicy,
  useDeletePermissionPolicy,
  useDeletePermissionPolicyFromGroup,
  useGetPermissionPolicy,
  useSavePermissionToPolicy,
  useUpdatePermissionPolicy,
} from '@novaera/actioner-service';
import { useConfirmDialog, useNvDialogModalState } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useMemo, useState } from 'react';
import { ROUTES } from '../../../../../common/routes';
import { USER_MANAGEMENT_TAB_PATHS } from '../../../constants';

export const usePermissionPolicyDetailController = () => {
  const { permissionPolicyId } = useParams();
  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();
  const {
    isOpened: isUpdatePermissionPolicyModalOpened,
    onModalOpenClicked: onUpdatePermissionPolicyModalOpenClicked,
    onModalCloseClicked: onUpdatePermissionPolicyModalCloseClicked,
  } = useNvDialogModalState();
  const {
    isOpened: isAttachGroupModalOpen,
    onModalOpenClicked: onAttachGroupModalOpenClicked,
    onModalCloseClicked: onAttachGroupModalCloseClicked,
  } = useNvDialogModalState();
  const [permissionInProgressIndex, setPermissionInProgressIndex] = useState<number | undefined>();
  const { data: permissionPolicy, isInitialLoading: isPermissionPolicyLoading } = useGetPermissionPolicy({
    policyId: permissionPolicyId,
  });
  const { mutate: updatePermissionPolicy } = useUpdatePermissionPolicy();
  const { mutate: savePermissionToPolicy, isLoading: isSavePermissionLoading } = useSavePermissionToPolicy();
  const { mutate: deletePermissionFormPolicy, isLoading: isDeletePermissionFromPolicyLoading } =
    useDeletePermissionFromPolicy();
  const { mutate: deletePermissionPolicyFromGroup } = useDeletePermissionPolicyFromGroup();
  const { mutate: deletePermissionPolicy } = useDeletePermissionPolicy();
  const { mutate: attachPermissionPolicyToGroup } = useAttachPermissionPolicyToGroup();
  const isAdminPermissionPolicy = useMemo(() => permissionPolicy?.id === 'admin', [permissionPolicy?.id]);
  const handleGoBack = () => {
    navigate(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.PERMISSION_POLICIES}`);
  };

  const handleDeletePermissionPolicy = ({ name, id }: Pick<PermissionPolicy, 'id' | 'name'>) => {
    openConfirm({
      confirmButtonLabel: 'Delete',
      title: `Delete ${name}?`,
      message: 'Are you sure?',
      onConfirm: () => {
        deletePermissionPolicy(
          { id },
          {
            onSuccess: () => {
              navigate(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.PERMISSION_POLICIES}`);
            },
          }
        );
      },
    });
  };

  const handleUpdatePermissionPolicySubmit = (values: Omit<PermissionPolicySummary, 'id'>) => {
    assert(
      !!permissionPolicy,
      new Error('[PermissionPolicyDetail] - permissionPolicy can not be undefined to update'),
      'ERROR'
    );
    updatePermissionPolicy(
      { id: permissionPolicy?.id, ...values },
      {
        onSuccess: () => {
          onUpdatePermissionPolicyModalCloseClicked();
        },
      }
    );
  };

  const handleDeleteGroupFromPolicy = (id: string) => {
    assert(
      !!permissionPolicy,
      new Error(
        '[PermissionPolicyDetail] - permissionPolicy can not be undefined to delete group from permission policy'
      ),
      'ERROR'
    );
    deletePermissionPolicyFromGroup({ groupId: id, policyId: permissionPolicy?.id });
  };

  const handleAttachGroup = ({ group }: { group: GroupEntity }) => {
    assert(
      !!permissionPolicy,
      new Error(
        '[PermissionPolicyDetail] - permissionPolicy can not be undefined to attach group to permission policy'
      ),
      'ERROR'
    );
    attachPermissionPolicyToGroup(
      {
        group: group,
        permissionPolicies: [permissionPolicy],
      },
      {
        onSuccess: () => {
          onAttachGroupModalCloseClicked();
        },
      }
    );
  };

  const handleSavePermission = ({
    permission,
    onSuccess,
  }: {
    permission: PermissionEntity;
    onSuccess?: (
      data: SavePermissionToPolicyResponse,
      variables: SavePermissionToPolicyParams,
      context: unknown
    ) => void;
  }) => {
    assert(
      !!permissionPolicy,
      new Error('[PermissionPolicyDetail] - permissionPolicy can not be undefined to save permission to policy'),
      'ERROR'
    );
    return new Promise<void>((resolve) => {
      savePermissionToPolicy(
        { policyId: permissionPolicy?.id, permission },
        {
          onSuccess,
          onSettled: () => {
            setPermissionInProgressIndex(undefined);
            resolve();
          },
        }
      );
    });
  };

  const handleDeletePermissionFormPolicy = (id: string) => {
    assert(
      !!permissionPolicy,
      new Error('[PermissionPolicyDetail] - permissionPolicy can not be undefined to delete permission from policy'),
      'ERROR'
    );

    deletePermissionFormPolicy(
      {
        policyId: permissionPolicy?.id,
        id,
      },
      {
        onSettled: () => {
          setPermissionInProgressIndex(undefined);
        },
      }
    );
  };

  return {
    isAdminPermissionPolicy,
    permissionPolicy,
    isPermissionPolicyLoading,
    isSavePermissionLoading,
    isDeletePermissionFromPolicyLoading,
    isUpdatePermissionPolicyModalOpened,
    permissionInProgressIndex,
    setPermissionInProgressIndex,
    isAttachGroupModalOpen,
    handleGoBack,
    handleDeletePermissionPolicy,
    handleUpdatePermissionPolicySubmit,
    handleDeleteGroupFromPolicy,
    handleAttachGroup,
    handleSavePermission,
    handleDeletePermissionFormPolicy,
    onUpdatePermissionPolicyModalOpenClicked,
    onUpdatePermissionPolicyModalCloseClicked,
    onAttachGroupModalCloseClicked,
    onAttachGroupModalOpenClicked,
  };
};
