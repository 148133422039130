import { NvCard, NvMenuWithItems, NvSelect } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConnectionCard = styled(NvCard)`
  padding: 8px 16px;
  ${({ theme }) => theme.elevations.e100}
  border-radius: 12px;

  height: auto;
  flex: 0 0 auto;
`;

export const StyledNvMenuWithItems = styled(NvMenuWithItems)`
  .MuiPaper-root {
    width: 241px;
  }
`;

export const StyledNvSelect = styled(NvSelect)`
  &.MuiInputBase-root {
    width: 300px;
  }
`;
