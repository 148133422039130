import { ResponseConfiguration } from '@novaera/actioner-service';
import {
  NvButton,
  NvCollapseCard,
  NvDialogModal,
  NvErrorIcon,
  NvField,
  NvFlex,
  NvForm,
  NvReceiptLongIcon,
  NvTypography,
} from '@novaera/core';
import { noop } from 'lodash';
import { OutputItem } from '../../../components/output-item';
import { useActionDesignerFormApiContext } from '../../providers/form-api-provider';
import { IntegrationCodeField } from '../integration-code-field';
import { useResponseTab } from './controllers/use-response-tab';
import { ErrorHandlingResponseTab } from './error-handling';
import { ResponseFieldHint } from './response-field-hint';
import { SamplePayload } from './sample-payload';
import { FieldTitleContent, FieldTitleWrapper } from './styled';

export const ResponseTab = () => {
  const {
    saveResponse,
    responseConfiguration,
    testResult,
    isTestResultFailed,
    isOpened,
    onModalCloseClicked,
    onModalOpenClicked,
    isSamplePayloadInEditMode,
    defaultSampleResponseValue,
    onErrorHandlingModalOpenClicked,
    isErrorHandlingModalOpened,
    onErrorHandlingModalCloseClicked,
    isUpdateResponseLoading,
    hasIntegrationUpdateRight,
  } = useResponseTab();
  const { responseFormApiRef } = useActionDesignerFormApiContext();

  return (
    <>
      <NvFlex padding="16px" gap="24px">
        <NvForm<ResponseConfiguration>
          myRef={responseFormApiRef}
          onSubmit={noop}
          onChange={
            hasIntegrationUpdateRight
              ? (value) => {
                  saveResponse(value.values);
                }
              : noop
          }
          initialValues={{ responseTemplate: responseConfiguration?.responseTemplate, ...responseConfiguration }}
          keepDirtyOnReinitialize
          autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        >
          <NvField
            name="responseTemplate"
            direction="label-on-top"
            hint={<ResponseFieldHint />}
            labelText={
              <FieldTitleContent>
                <FieldTitleWrapper
                  labelText="Response"
                  infoText="The action response can be constructed using request responses"
                />
                <NvFlex flexDirection={'row'} columnGap={'16px'}>
                  <NvButton
                    onClick={onModalOpenClicked}
                    size="small"
                    color="secondary"
                    startIcon={<NvReceiptLongIcon />}
                  >
                    Sample response
                  </NvButton>
                  <NvButton
                    onClick={onErrorHandlingModalOpenClicked}
                    size="small"
                    color="secondary"
                    startIcon={<NvErrorIcon />}
                  >
                    Error Handling
                  </NvButton>
                </NvFlex>
              </FieldTitleContent>
            }
            defaultValue={defaultSampleResponseValue}
            component={<IntegrationCodeField growingHeight initialHeight="238px" />}
          />
        </NvForm>
        <NvCollapseCard title={<NvTypography variant="h5">Result</NvTypography>} defaultIsOpen>
          {!testResult && !isTestResultFailed ? (
            <NvTypography variant="body2" textColor="subtle">
              Test your action to see the result.
            </NvTypography>
          ) : (
            <OutputItem content={testResult} isTestResultFailed={isTestResultFailed} />
          )}
        </NvCollapseCard>
      </NvFlex>
      <NvDialogModal<ResponseConfiguration>
        useArrayMutators
        formProps={{
          initialValues: responseConfiguration,
        }}
        isLoading={isUpdateResponseLoading}
        maxWidth="md"
        fullWidth
        open={isErrorHandlingModalOpened}
        onCloseButtonClicked={onErrorHandlingModalCloseClicked}
        Header={'Error handling'}
        modalIcon={'error'}
        onFormSubmit={saveResponse}
        Body={<ErrorHandlingResponseTab />}
        primaryButtonText="Save"
        {...(!hasIntegrationUpdateRight
          ? {
              Footer: (
                <NvButton color="secondary" onClick={onErrorHandlingModalCloseClicked}>
                  Cancel
                </NvButton>
              ),
            }
          : {})}
      />
      <NvDialogModal<ResponseConfiguration>
        formProps={{
          initialValues: responseConfiguration,
        }}
        isLoading={isUpdateResponseLoading}
        maxWidth="md"
        fullWidth
        open={isOpened}
        onCloseButtonClicked={onModalCloseClicked}
        Header={`${!isSamplePayloadInEditMode ? 'Add sample response' : 'Edit sample response'}`}
        modalIcon={!isSamplePayloadInEditMode ? 'add' : 'edit'}
        onFormSubmit={saveResponse}
        Body={<SamplePayload />}
        primaryButtonText={!isSamplePayloadInEditMode ? 'Add' : 'Update'}
        {...(!hasIntegrationUpdateRight
          ? {
              Footer: (
                <NvButton color="secondary" onClick={onModalCloseClicked}>
                  Cancel
                </NvButton>
              ),
            }
          : {})}
      />
    </>
  );
};
