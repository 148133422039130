import { css } from '@emotion/react';

export const AnimationClass = css`
  .add-new-item {
    position: relative;
    z-index: 2;

    & > * {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: -20px;
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 4px;
      background: var(--Main-40, #547ed4);
      box-shadow: 0px 0px 0px 3px #ebf1ff, 0px 1px 3px 0px rgba(20, 57, 135, 0.45),
        0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px #466dbf inset;

      animation: new-item 5s ease-in-out 0s;
      animation-fill-mode: forwards;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      top: -4px;
      left: -6px;
      bottom: -6px;
      width: 50%;
      border-radius: 8px 0 0 8px;
      opacity: 0.5;
      background: linear-gradient(90deg, #ebf1ff 0%, rgba(235, 241, 255, 0) 75.74%);

      animation: new-item-bg 2s ease-in-out 0s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes new-item {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    4% {
      transform: scale(1.2);
      opacity: 1;
    }

    5% {
      transform: scale(1);
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes new-item-bg {
    0% {
      opacity: 0;
    }

    10% {
      opacity: 0.5;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
`;
