import { ConditionConfiguration } from '../condition';

export enum MediaTypes {
  APPLICATION_XML = 'application/xml',
  APPLICATION_XHTML_XML = 'application/xhtml+xml',
  APPLICATION_JSON = 'application/json',
  APPLICATION_JSON_PATCH_JSON = 'application/json-patch+json',
  APPLICATION_FORM_URLENCODED = 'application/x-www-form-urlencoded',
  MULTIPART_FORM_DATA = 'multipart/form-data',
  TEXT_PLAIN = 'text/plain',
  TEXT_XML = 'text/xml',
  TEXT_HTML = 'text/html',
  // SERVER_SENT_EVENTS = 'SERVER_SENT_EVENTS',
  // APPLICATION_JSON_PATCH_JSON = 'APPLICATION_JSON_PATCH_JSON',
  // APPLICATION_ATOM_XML = 'APPLICATION_ATOM_XML',
  // APPLICATION_OCTET_STREAM = 'APPLICATION_OCTET_STREAM',
  // APPLICATION_SVG_XML = 'APPLICATION_SVG_XML',
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

type Parameter = {
  key: string;
  valueGenerationTemplate: string;
};

type MultiPartFormParameters = {
  key: string;
  value: string;
  s3Key?: boolean;
  contentType?: MediaTypes;
};

export type HTTPRequestConfiguration = {
  type: 'http-request';
  id: string;
  name: string;
  httpMethod: HttpMethods;
  url: string;
  mediaType: MediaTypes;
  retryConfiguration: RetryConfiguration;
  queryParameters?: Parameter[];
  body?: { raw: string };
  formParameters?: Parameter[];
  multipartFormParameters?: MultiPartFormParameters[];
  headerParameters?: Parameter[];
  conditionConfiguration: ConditionConfiguration;
  useConnectionSchema: boolean;
};

export type RetryConfiguration = {
  enabled: boolean;
  statusCodes: string[];
  maxRetryCount: number;
  delayInSeconds: number;
};

export type HTTPRequestConfigurations = HTTPRequestConfiguration[];
