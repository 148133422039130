import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCheckboxIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6H18V18H6V6ZM4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6ZM10.0545 14.7487L8.24864 13.0063C7.91712 12.6796 7.91712 12.1602 8.24864 11.8335C8.58888 11.5068 9.1385 11.5068 9.47874 11.8335L10.6652 12.9812L14.5213 9.24503C14.8615 8.91832 15.4111 8.91832 15.7514 9.24503C16.0829 9.57173 16.0829 10.0911 15.7514 10.4178L11.2759 14.7487C10.9531 15.0838 10.3948 15.0838 10.0545 14.7487Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
