import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@novaera/theme-provider';
import { isString } from 'lodash';
import { NvTypography } from '../typography';
import { NvTooltipProps } from './types';

export const StyledTooltip = styled(({ className, ...props }: NvTooltipProps) => {
  const { variant, title, fullWidth, maxWidth, ...rest } = props;
  return (
    <Tooltip
      {...rest}
      classes={{ popper: className }}
      title={
        isString(title) ? <NvTypography variant={variant === 'small' ? 'body2' : 'body1'}>{title}</NvTypography> : title
      }
    />
  );
})`
  & .${tooltipClasses.tooltip} {
    display: flex;
    align-items: center;
    ${({ variant }) => (variant === 'large' ? 'min-height: 32px;' : null)}
    padding: ${({ variant }) => (variant === 'small' ? '2px 6px' : '8px 12px')};
    ${({ theme }) => theme.elevations.e400};
    background-color: ${({ theme }) => theme.palette.nv_neutral[600]};
    border-radius: ${({ variant }) => (variant === 'small' ? '4px' : '8px')};
    color: ${({ theme }) => theme.palette.nv_neutral[0]};
    ${({ fullWidth, maxWidth }) => (fullWidth ? 'max-width: none' : `max-width: ${maxWidth}px`)};
  }

  & .${tooltipClasses.tooltipPlacementTop} {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  & .${tooltipClasses.tooltipPlacementRight} {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }

  & .${tooltipClasses.tooltipPlacementBottom} {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  & .${tooltipClasses.tooltipPlacementLeft} {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
`;
