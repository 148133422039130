import {
  NvActionFilledIcon,
  NvArticleIcon,
  NvCustomAssistantIcon,
  NvCustomAssistantStopIcon,
  NvCustomBranchesIcon,
  NvCustomConditionIcon,
  NvCustomFolderAddIcon,
  NvCustomFolderDeleteIcon,
  NvCustomFolderListIcon,
  NvCustomFolderShareIcon,
  NvCustomFolderTransferIcon,
  NvCustomGetConversationIcon,
  NvCustomQA,
  NvCustomWorkflowResolver,
  NvFolderOpenRounded,
  NvFunctionIcon,
  NvHTTPIcon,
  NvHourglassBottomIcon,
  NvLinkIcon,
  NvMailOutlineIcon,
  NvReceiptLongIcon,
  NvRotateRightIcon,
  NvSensorsIcon,
  NvTimerIcon,
  NvTrackChangesIcon,
  NvTypography,
  NvWebhookIcon,
  NvWorkflowIcon,
  NvWysiwygIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { NodeType } from './types';

export const useWorkflowNodeUtilities = () => {
  const theme = useTheme();
  const getWorkflowNodeColor = (type: NodeType) => {
    switch (type) {
      case NodeType.OUTPUT:
        return theme.palette.nv_node.output;
      case NodeType.INTEGRATION_TRIGGER:
        return theme.palette.nv_node.trigger;
      case NodeType.FORM_TRIGGER:
        return theme.palette.nv_node.trigger;
      case NodeType.EMAIL_TRIGGER:
        return theme.palette.nv_node.trigger;
      case NodeType.ACTIONER_EVENT_TRIGGER:
      case NodeType.ACTIONER_EVENT_PUBLISHER:
        return theme.palette.nv_node.actionerEvent;
      case NodeType.WEBHOOK_TRIGGER:
        return theme.palette.nv_node.trigger;
      case NodeType.ACTION:
        return theme.palette.nv_node.action;
      case NodeType.LOOP:
        return theme.palette.nv_node.operator;
      case NodeType.CONDITION:
        return theme.palette.nv_node.operator;
      case NodeType.BRANCH:
        return theme.palette.nv_node.operator;
      case NodeType.DELAY:
        return theme.palette.nv_node.operator;
      case NodeType.WORKFLOW:
        return theme.palette.nv_node.workflow;
      case NodeType.FUNCTION:
        return theme.palette.nv_node.function;
      case NodeType.EMITTER:
        return theme.palette.nv_node.emitter;
      case NodeType.RESPONSE:
        return theme.palette.nv_node.response;
      case NodeType.WORKFLOW_DISPATCHER:
        return theme.palette.nv_node.workflow;
      case NodeType.WORKFLOW_RESOLVER:
      case NodeType.QA:
      case NodeType.ASSISTANT:
      case NodeType.GET_CONVERSATION:
        return theme.palette.nv_node.ai;
      case NodeType.SEND_EMAIL:
        return theme.palette.nv_node.sendEmail;
      case NodeType.JOB:
        return theme.palette.nv_node.job;
      case NodeType.FILE:
      case NodeType.PUT_FILE:
      case NodeType.CHANGE_ACCESSIBILITY_FILE:
      case NodeType.DELETE_FILE:
      case NodeType.LIST_FILE:
      case NodeType.TRANSFER_FILE:
        return theme.palette.nv_node.file;
      case NodeType.LINK_GENERATOR:
        return theme.palette.nv_node.linkGenerator;
      case NodeType.HTTP:
        return theme.palette.nv_node.http;
      case NodeType.ASSISTANT_CANCEL_RUN:
        return theme.palette.nv_node['assistant_cancel_run'];
      default:
        assert(false, new Error(`Unknown node type for useWorkflowNodeUtilities ${type}`), 'ERROR');
    }
  };
  const getWorkflowNodeIcon = ({ type, width, height }: { type: NodeType; width?: number; height?: number }) => {
    const sx = { width: `${width}px` ?? '24px', height: `${height}px` ?? '24px' };
    const htmlColor = getWorkflowNodeColor(type);
    switch (type) {
      case NodeType.OUTPUT:
        return <NvArticleIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.INTEGRATION_TRIGGER:
        return <NvTrackChangesIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.FORM_TRIGGER:
        return <NvWysiwygIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.EMAIL_TRIGGER:
        return <NvMailOutlineIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.ACTIONER_EVENT_TRIGGER:
      case NodeType.ACTIONER_EVENT_PUBLISHER:
        return <NvSensorsIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.WEBHOOK_TRIGGER:
        return <NvWebhookIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.ACTION:
        return <NvActionFilledIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.LOOP:
        return <NvRotateRightIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.CONDITION:
        return <NvCustomConditionIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.BRANCH:
        return <NvCustomBranchesIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.DELAY:
        return <NvHourglassBottomIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.WORKFLOW:
        return <NvWorkflowIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.FUNCTION:
        return <NvFunctionIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.EMITTER:
        return <NvSensorsIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.RESPONSE:
        return <NvReceiptLongIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.WORKFLOW_DISPATCHER:
        return <NvWorkflowIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.WORKFLOW_RESOLVER:
        return <NvCustomWorkflowResolver htmlColor={htmlColor} sx={sx} />;
      case NodeType.QA:
        return <NvCustomQA htmlColor={htmlColor} sx={sx} />;
      case NodeType.ASSISTANT:
        return <NvCustomAssistantIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.GET_CONVERSATION:
        return <NvCustomGetConversationIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.SEND_EMAIL:
        return <NvMailOutlineIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.JOB:
        return <NvTimerIcon htmlColor={htmlColor} sx={sx} />;
      case NodeType.FILE:
        return <NvFolderOpenRounded htmlColor={htmlColor} sx={sx} />;
      case NodeType.PUT_FILE:
        return <NvCustomFolderAddIcon htmlColor={theme.palette.nv_node.file} />;
      case NodeType.CHANGE_ACCESSIBILITY_FILE:
        return <NvCustomFolderShareIcon htmlColor={theme.palette.nv_node.file} />;
      case NodeType.DELETE_FILE:
        return <NvCustomFolderDeleteIcon htmlColor={theme.palette.nv_node.file} />;
      case NodeType.LIST_FILE:
        return <NvCustomFolderListIcon htmlColor={theme.palette.nv_node.file} />;
      case NodeType.TRANSFER_FILE:
        return <NvCustomFolderTransferIcon htmlColor={theme.palette.nv_node.file} />;
      case NodeType.LINK_GENERATOR:
        return <NvLinkIcon htmlColor={theme.palette.nv_node.file} />;
      case NodeType.HTTP:
        return <NvHTTPIcon htmlColor={theme.palette.nv_node.http} />;
      case NodeType.ASSISTANT_CANCEL_RUN:
        return <NvCustomAssistantStopIcon htmlColor={theme.palette.nv_node.assistant_cancel_run} />;
      default:
        assert(false, new Error(`Unknown node type for getWorkflowNodeIcon: ${type}`), 'ERROR');
    }
  };
  const getWorkflowNodeLabel = (type: NodeType) => {
    const color = getWorkflowNodeColor(type);
    switch (type) {
      case NodeType.OUTPUT:
        return (
          <NvTypography variant="h8" color={color}>
            Output
          </NvTypography>
        );
      case NodeType.INTEGRATION_TRIGGER:
      case NodeType.FORM_TRIGGER:
      case NodeType.EMAIL_TRIGGER:
      case NodeType.WEBHOOK_TRIGGER:
        return (
          <NvTypography variant="h8" color={color}>
            Trigger
          </NvTypography>
        );
      case NodeType.ACTION:
        return (
          <NvTypography variant="h8" color={color}>
            Action
          </NvTypography>
        );
      case NodeType.LOOP:
        return (
          <NvTypography variant="h8" color={color}>
            Loop
          </NvTypography>
        );
      case NodeType.CONDITION:
        return (
          <NvTypography variant="h8" color={color}>
            Condition
          </NvTypography>
        );
      case NodeType.BRANCH:
        return (
          <NvTypography variant="h8" color={color}>
            Branch
          </NvTypography>
        );
      case NodeType.DELAY:
        return (
          <NvTypography variant="h8" color={color}>
            Delay
          </NvTypography>
        );
      case NodeType.WORKFLOW:
      case NodeType.WORKFLOW_DISPATCHER:
        return (
          <NvTypography variant="h8" color={color}>
            Workflow
          </NvTypography>
        );
      case NodeType.FUNCTION:
        return (
          <NvTypography variant="h8" color={color}>
            Function
          </NvTypography>
        );
      case NodeType.EMITTER:
        return (
          <NvTypography variant="h8" color={color}>
            Emitter
          </NvTypography>
        );
      case NodeType.ACTIONER_EVENT_TRIGGER:
      case NodeType.ACTIONER_EVENT_PUBLISHER:
        return (
          <NvTypography variant="h8" color={color}>
            Actioner event
          </NvTypography>
        );
      case NodeType.WORKFLOW_RESOLVER:
      case NodeType.QA:
      case NodeType.ASSISTANT:
      case NodeType.GET_CONVERSATION:
        return (
          <NvTypography variant="h8" color={color}>
            AI
          </NvTypography>
        );
      case NodeType.JOB:
        return (
          <NvTypography variant="h8" color={color}>
            Job
          </NvTypography>
        );
      case NodeType.PUT_FILE:
        return (
          <NvTypography variant="h8" color={color}>
            Put file
          </NvTypography>
        );
      case NodeType.LIST_FILE:
        return (
          <NvTypography variant="h8" color={color}>
            List file
          </NvTypography>
        );
      case NodeType.CHANGE_ACCESSIBILITY_FILE:
        return (
          <NvTypography variant="h8" color={color}>
            Change file accessibility
          </NvTypography>
        );
      case NodeType.TRANSFER_FILE:
        return (
          <NvTypography variant="h8" color={color}>
            Transfer file
          </NvTypography>
        );
      case NodeType.LINK_GENERATOR:
        return (
          <NvTypography variant="h8" color={color}>
            Link generator
          </NvTypography>
        );
      case NodeType.HTTP:
        return (
          <NvTypography variant="h8" color={color}>
            HTTP request
          </NvTypography>
        );
      case NodeType.RESPONSE:
        return (
          <NvTypography variant="h8" color={color}>
            Response
          </NvTypography>
        );
      case NodeType.DELETE_FILE:
        return (
          <NvTypography variant="h8" color={color}>
            Delete file
          </NvTypography>
        );
      case NodeType.FILE:
        return (
          <NvTypography variant="h8" color={color}>
            File
          </NvTypography>
        );
      case NodeType.SEND_EMAIL:
        return (
          <NvTypography variant="h8" color={color}>
            Send email
          </NvTypography>
        );
      case NodeType.ASSISTANT_CANCEL_RUN:
        return (
          <NvTypography variant="h8" color={color}>
            Assistant cancel run
          </NvTypography>
        );
      default:
        assert(false, new Error(`Unknown node type for getWorkflowNodeLabel. Type: ${type}`), 'ERROR');
    }
  };

  return { getWorkflowNodeIcon, getWorkflowNodeLabel, getWorkflowNodeColor };
};
