import { SLACK_WORKSPACE_KEYS } from '@novaera/ah-common';
import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { SLACK_INTEGRATIONS_ROOT_PATH } from '../../constants';

const removeSlackConnection = async (params: { teamId: string }) => {
  await NvAxios.delete<void>(SLACK_INTEGRATIONS_ROOT_PATH, {
    params,
  });
};

export const useRemoveSlackConnection = () => {
  const cache = useQueryClient();
  return useMutation(removeSlackConnection, {
    onSuccess: () => {
      cache.setQueryData(SLACK_WORKSPACE_KEYS.all, null);
    },
  });
};
