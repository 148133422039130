import { FieldType } from '@novaera/actioner-service';
import { MenuItemNameWrapper, NvBox, NvFlex, NvSelectMenuItem } from '@novaera/core';
import React, { ReactElement, isValidElement, useMemo } from 'react';

import { useRecordParametersContext } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/records-parameter-mapping/provider';
import { findLogoFromFields } from '../../../data-model-layout/utils';

export const RecordMenuItems: React.FC<
  React.PropsWithChildren<{
    onItemClick: (field?: FieldType) => void;
    filterMenuItems: (field: FieldType) => boolean;
    onClose?: (e: React.BaseSyntheticEvent) => void;
  }>
> = ({ onClose, onItemClick, filterMenuItems }) => {
  const { recordFields } = useRecordParametersContext();
  const filteredRecordFields = useMemo(
    () => recordFields.filter((field) => field.name !== 'id').filter((field) => !filterMenuItems(field)),
    [filterMenuItems, recordFields]
  );
  return (
    <NvBox>
      {filteredRecordFields.length === 0 ? (
        <NvSelectMenuItem key={`record-menu-item-no-option`} disabled>
          <NvFlex width="100%" alignItems="center">
            No fields
          </NvFlex>
        </NvSelectMenuItem>
      ) : (
        filteredRecordFields.map((r) => {
          const name = r.name;
          const icon = r.schema?.type ? findLogoFromFields(r.schema?.type) : null;
          return (
            <NvSelectMenuItem
              key={`record-menu-item-${r.id}`}
              onClick={(e) => {
                onItemClick(r);
                onClose?.(e);
              }}
            >
              <NvFlex direction="row" alignItems="center" gap="8px">
                {isValidElement(icon) &&
                  React.cloneElement(icon as ReactElement, { sx: { width: '16px', height: '16px' } })}
                <MenuItemNameWrapper>{name}</MenuItemNameWrapper>
              </NvFlex>
            </NvSelectMenuItem>
          );
        })
      )}
    </NvBox>
  );
};
