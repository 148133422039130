import { APP_PERMISSION, MediaFileType, useUpdateUserAppMediaFiles } from '@novaera/actioner-service';
import { CarouselItemType, NvAddBoxIcon, NvButton, NvEditIcon, NvFlex, NvForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import arrayMutators from 'final-form-arrays';
import { useCallback } from 'react';
import { AppDirectoryDetailCarousel } from '../../../../../app-directory/app-directory-detail/styled';
import { ImagesAndVideos } from '../../../../../app-directory/app-directory-list/publish-your-app/app-details-step/images-and-videos';
import { UserAppPermissionBoundary } from '../../../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../../../user-app-permission-boundary/free-app-permission-boundary';
import { VideoAndImagesCard } from './styled';
import { UpdateMediaFilesFormValues, VideosAndImagesProps } from './types';

export const VideosAndImages: React.FC<React.PropsWithChildren<VideosAndImagesProps>> = ({
  mediaFiles,
  onEditModeChange,
  isEditMode,
  enableAutomaticAddModal,
}) => {
  const { userAppId } = useParams();

  const { mutate: updateMediaFiles } = useUpdateUserAppMediaFiles();
  const hasMediaFiles = mediaFiles?.length && mediaFiles.length > 0;

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ mediaFiles = [] }: UpdateMediaFilesFormValues, form: any) => {
      return new Promise<void>((resolve) => {
        updateMediaFiles(
          { appId: userAppId, mediaFiles },
          {
            onError: () => {
              form.reset();
              resolve();
            },
            onSettled: () => {
              resolve();
              onEditModeChange(false);
            },
          }
        );
      });
    },
    [onEditModeChange, updateMediaFiles, userAppId]
  );

  return (
    <NvForm<UpdateMediaFilesFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        mediaFiles: mediaFiles ?? [],
      }}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ values: { mediaFiles = [] }, submitting, form: { reset } }) => {
        const mappedMediaFiles = mediaFiles.map((mediaFile) => ({
          type: mediaFile.type === MediaFileType.IMAGE ? CarouselItemType.IMG : CarouselItemType.VIDEO,
          url: mediaFile.fileReference.url ?? '',
          displayText: mediaFile.displayText,
        }));
        return hasMediaFiles || isEditMode ? (
          <UserAppPermissionBoundary
            permission={APP_PERMISSION.APP_EDIT}
            appId={userAppId}
            Fallback={
              <VideoAndImagesCard isEditMode={false} disabled>
                <AppDirectoryDetailCarousel items={mappedMediaFiles} />
              </VideoAndImagesCard>
            }
          >
            <VideoAndImagesCard isEditMode={isEditMode}>
              {!isEditMode ? (
                <NvFlex alignItems={'flex-start'}>
                  <AppDirectoryDetailCarousel items={mappedMediaFiles} />
                  <FreeAppPermissionBoundary>
                    <NvButton
                      color="secondary"
                      onClick={() => {
                        onEditModeChange(true);
                      }}
                      startIcon={<NvEditIcon />}
                      size="small"
                    >
                      Edit media
                    </NvButton>
                  </FreeAppPermissionBoundary>
                </NvFlex>
              ) : (
                <>
                  <ImagesAndVideos
                    fieldName="mediaFiles"
                    title="Media"
                    titleVariant="h3"
                    addNewButtonPosition="top"
                    enableAutomaticAddModal={enableAutomaticAddModal}
                  />

                  <NvFlex direction="row" gap="8px" alignItems="center" marginTop="16px">
                    <NvButton size="small" type="submit" loading={submitting} disabled={submitting}>
                      Save
                    </NvButton>
                    <NvButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        onEditModeChange(false);
                        reset();
                      }}
                      disabled={submitting}
                    >
                      Cancel
                    </NvButton>
                  </NvFlex>
                </>
              )}
            </VideoAndImagesCard>
          </UserAppPermissionBoundary>
        ) : (
          <FreeAppPermissionBoundary>
            <UserAppPermissionBoundary
              permission={APP_PERMISSION.APP_EDIT}
              appId={userAppId}
              Fallback={
                <NvButton color="secondary" startIcon={<NvAddBoxIcon />} disabled size="small">
                  Add media
                </NvButton>
              }
            >
              <NvButton
                color="secondary"
                startIcon={<NvAddBoxIcon />}
                onClick={() => {
                  onEditModeChange(true);
                }}
                size="small"
              >
                Add media
              </NvButton>
            </UserAppPermissionBoundary>
          </FreeAppPermissionBoundary>
        );
      }}
    </NvForm>
  );
};
