import { NvImage, NvTypography } from '@novaera/core';
import { AppDropdownListItemWrapper } from './styled';
import { AppDropdownListItemProps } from './types';

export const AppDropdownListItem = <T,>({
  logo,
  logoFallBack,
  name,
  onItemClick,
  isSelected,
  renderCustomLogo,
  item,
}: AppDropdownListItemProps<T>) => (
  <AppDropdownListItemWrapper onClick={onItemClick} isSelected={isSelected}>
    {!renderCustomLogo && (
      <NvImage
        FallBack={logoFallBack ?? ''}
        src={logo}
        imageShape="square"
        size="small"
        fallbackImageHasBlurEffect
        className="package-image"
        width="60px"
        height="60px"
      />
    )}
    {renderCustomLogo && item && renderCustomLogo(item)}
    <NvTypography variant="h5" noWrap>
      {name}
    </NvTypography>
  </AppDropdownListItemWrapper>
);
