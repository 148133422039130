import { HTTPRequestConfiguration, USER_PERMISSION } from '@novaera/actioner-service';
import { FieldArray, NvAddButtonWithLabel, NvFlex } from '@novaera/core';
import { UserPermissionBoundary } from '../../../../../user-permission-boundary';
import { useRequestList } from './controller/use-request-list';
import { Item } from './item';
import { OpenModalParams } from './types';

export const RequestList: React.FC<
  React.PropsWithChildren<{
    openModal: (params: OpenModalParams) => void;
  }>
> = ({ openModal }) => {
  const { httpRequestConfigurations, selectedRequestId, handleItemClick, actionButtons, hasIntegrationUpdateRight } =
    useRequestList();

  return (
    <NvFlex paddingBottom={10}>
      <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
        <NvAddButtonWithLabel
          onClick={() => {
            return openModal({ mode: 'create' });
          }}
          variant="small"
          label="Add request"
        />
      </UserPermissionBoundary>
      <NvFlex height={1} spacing={0.75} mt={1.5}>
        <FieldArray<HTTPRequestConfiguration> name="httpRequestConfigurations">
          {() =>
            httpRequestConfigurations?.map((request) => {
              const { id, name } = request;

              const hasError = false;
              return (
                <Item
                  isSelected={selectedRequestId === id}
                  label={name}
                  variant="API"
                  onItemClick={() => {
                    handleItemClick(id);
                  }}
                  {...(hasIntegrationUpdateRight
                    ? { onEditClick: () => openModal({ mode: 'update', id }), actionButtons: actionButtons(id) }
                    : {})}
                  key={`request_list_item_${id}`}
                  hasError={hasError}
                />
              );
            })
          }
          {}
        </FieldArray>
      </NvFlex>
    </NvFlex>
  );
};
