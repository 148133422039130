import {
  InputParameter,
  ParameterMappings,
  isInputComponent,
  useGetActionInputParamDependencies,
  useGetWorkflowInputParamDependencies,
} from '@novaera/actioner-service';
import { useCallback } from 'react';
import { DependencyInputComponentStateFN, FORM_ITEM_DEPENDENCY_STATE } from './types';

export const useCheckDependencyValue = ({
  result,
  inputParameters,
}: {
  result:
    | ReturnType<typeof useGetWorkflowInputParamDependencies>
    | ReturnType<typeof useGetActionInputParamDependencies>;
  inputParameters: InputParameter[];
}) => {
  const handleGetDependencyInputComponentState = useCallback<DependencyInputComponentStateFN>(
    ({ inputParameterId, inputParameterValues }) => {
      if (result.isLoading) {
        return {
          state: FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING,
        };
      }

      const dependencies = result.data?.dependenciesOfParameters?.[inputParameterId];
      if (!dependencies || dependencies.length === 0) {
        return {
          state: FORM_ITEM_DEPENDENCY_STATE.NO_DEPENDENCY,
        };
      }

      const missingInputParameterDependencies = dependencies.filter(
        (dependency) => !inputParameterValues?.find((inputParameter) => inputParameter.parameterId === dependency)
      );
      if (missingInputParameterDependencies.length > 0) {
        return {
          state: FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE,
          missingDependencies: inputParameters
            ?.map<string | null>((inputParam) =>
              missingInputParameterDependencies.includes(inputParam.id) && isInputComponent(inputParam.uiComponent)
                ? inputParam.uiComponent.label
                : null
            )
            .filter(Boolean) as string[],
        };
      } else {
        return {
          state: FORM_ITEM_DEPENDENCY_STATE.HAS_DEPENDENCY,
        };
      }
    },
    [inputParameters, result.data?.dependenciesOfParameters, result.isLoading]
  );

  const handleFilterForSuccessDynamicInputs = useCallback(
    (inputParameterId: string, inputParameterValues?: ParameterMappings) => {
      const result = handleGetDependencyInputComponentState({
        inputParameterId,
        inputParameterValues,
      });

      return (
        result.state === FORM_ITEM_DEPENDENCY_STATE.HAS_DEPENDENCY ||
        result.state === FORM_ITEM_DEPENDENCY_STATE.NO_DEPENDENCY
      );
    },
    [handleGetDependencyInputComponentState]
  );

  return {
    getDependencyInputComponentState: handleGetDependencyInputComponentState,
    filterSuccessDynamicInputs: handleFilterForSuccessDynamicInputs,
    dependenciesOfParameters: result.data?.dependenciesOfParameters,
  };
};
