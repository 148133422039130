import { Rule, SingleValueRuleType } from '@novaera/actioner-service';
import { OPTIONS } from '../constants';

export const DEFAULT_VALUE: Rule[] = [
  {
    keyExpression: '',
    operation: OPTIONS()?.[0].value.operation,
    negateResult: false,
    value: { type: SingleValueRuleType.STRING, value: '' },
  },
];
