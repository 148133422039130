import { FetchProfileResponse, GetUserPermissionsResponse } from '@novaera/actioner-service';
import LogRocket from 'logrocket';
import { useEffect, useRef } from 'react';

export const useLogRocketIdentifier = ({
  enabled,
  user,
  permissions,
}: {
  enabled: boolean;
  user?: FetchProfileResponse;
  permissions?: GetUserPermissionsResponse['permissions'];
}) => {
  const isSent = useRef<boolean>(false);
  useEffect(() => {
    if (enabled && !isSent.current && permissions && user) {
      isSent.current = true;

      LogRocket.identify(user.email, {
        name: user.name,
        email: user.email,
        workspaceId: user.workspaceId,
        ...permissions,
      });
    }
  }, [enabled, isSent, permissions, user]);
};
