import { NvField } from '@novaera/core';
import { IntegrationCodeField } from '../../../../../integration-code-field';

export const RequestCustomInput: React.FC<React.PropsWithChildren<{ name?: string }>> = ({ name }) => {
  return (
    <>
      <NvField name={`${name}.key`} component={<IntegrationCodeField placeholder="Key" expectedType="string" />} />
      <NvField
        name={`${name}.valueGenerationTemplate`}
        component={<IntegrationCodeField placeholder="Value" expectedType="string" />}
      />
    </>
  );
};
