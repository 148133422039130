import { NvSensorsIcon } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { BaseNodeProps } from '../types';

export type ActionerEventTriggerProps = BaseNodeProps;

export const ActionerEventTrigger: FC<ActionerEventTriggerProps> = (props) => {
  const theme = useTheme();
  const { actions, alias, ...rest } = props;
  return (
    <Node
      type={NodeType.ACTIONER_EVENT_TRIGGER}
      icon={<NvSensorsIcon htmlColor={theme.palette.nv_node.actionerEvent} />}
      alias="event"
      {...rest}
    />
  );
};
