import { CloneParams as CloneServiceParams, useCloneService } from '@novaera/actioner-service';
import { isAxiosError } from '@novaera/core';
import { assert } from '@novaera/utils';
import { CloneParams } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCloneServiceParams = (params: any): params is CloneServiceParams => {
  return params.type === 'apps' || params.type === 'data-models' || params.type === 'workflows';
};

export const useCloneController = ({
  type,
  onModalCloseClicked,
  onCloneSuccess,
}: {
  type: CloneParams['type'];
  onCloneSuccess: CloneParams['onCloneSuccess'];
  onModalCloseClicked: () => void;
}) => {
  const title = `Clone ${type === 'workflows' ? 'workflow' : type === 'data-models' ? 'data model' : 'app'}`;

  const { clone, isLoading } = useCloneService({
    onSuccess: (params) => {
      onCloneSuccess(params);
    },
  });

  const handleClone = async ({
    newName,
    ...otherParams
  }: {
    newName: string;
    workflowId?: string;
    appId: string;
    modelId?: string;
  }) => {
    if (isCloneServiceParams(otherParams)) {
      try {
        await clone({
          ...otherParams,
          payload: { newName },
        });
        onModalCloseClicked();
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 500) {
          assert(false, error);
        }
      }
    } else {
      assert(false, new Error('Payload is not a CloneServiceParams'));
    }
  };

  return {
    title,
    isLoading,
    onFormSubmit: handleClone,
  };
};
