import {
  useGetSlackApp,
  useGetSlackWorkspace,
  useGetUserApp,
  useGetUserAppSetup,
  useUpdateUserAppUserPreferences,
} from '@novaera/actioner-service';
import { Markdown, NvCompletionState, NvEmoji, NvFlex, NvLink, NvTypography } from '@novaera/core';
import { useEffect, useMemo } from 'react';
import { GO_TO_ACTIONER_TEXT } from './constants';
import { InstallStepsCompletedStepProps } from './types';

export const CompletedStep: React.FC<React.PropsWithChildren<InstallStepsCompletedStepProps>> = ({
  appId,
  handleNextClick,
}) => {
  const { data: app } = useGetUserApp(appId);
  const { data: slackWorkspace } = useGetSlackWorkspace();
  const ACTIONER_PROD_SLACK_APP_ID = 'A02TCGS98DS';
  const { mutate: updateUserAppUserPreferences } = useUpdateUserAppUserPreferences();
  const { data: slackApp } = useGetSlackApp({ appId });
  const { data: userAppSetup } = useGetUserAppSetup({ appId });

  useEffect(() => {
    updateUserAppUserPreferences({ appId, setupDismissed: true });
  }, [appId, updateUserAppUserPreferences]);

  const slackHomeUrl = useMemo(() => {
    return slackApp?.slackAppHomeUrl
      ? slackApp.slackAppHomeUrl
      : slackWorkspace
      ? `${slackWorkspace.slackTeamLink}&id=${ACTIONER_PROD_SLACK_APP_ID}&tab=home`
      : null;
  }, [slackApp?.slackAppHomeUrl, slackWorkspace]);

  const goToAppHomeComponent = useMemo(() => {
    if (userAppSetup?.successView) {
      return userAppSetup.successView.actionerAppHomeText ? (
        <NvLink onClick={handleNextClick}>{userAppSetup.successView.actionerAppHomeText}</NvLink>
      ) : null;
    } else {
      return <NvLink onClick={handleNextClick}>{GO_TO_ACTIONER_TEXT}</NvLink>;
    }
  }, [handleNextClick, userAppSetup?.successView]);

  const goToSlackHomeComponent = useMemo(() => {
    if (userAppSetup?.successView) {
      return userAppSetup.successView.slackAppHomeText && slackHomeUrl ? (
        <NvLink
          onClick={() => {
            window.location.href = slackHomeUrl;
          }}
        >
          {userAppSetup.successView.slackAppHomeText}
        </NvLink>
      ) : null;
    } else {
      return slackHomeUrl ? (
        <NvLink
          onClick={() => {
            window.location.href = slackHomeUrl;
          }}
        >
          Go to App Home in Slack to begin using <strong>{app?.name ?? 'this app'}</strong>
        </NvLink>
      ) : null;
    }
  }, [app?.name, slackHomeUrl, userAppSetup?.successView]);

  return (
    <NvFlex gap="32px" width="100%" alignItems="center">
      <NvCompletionState
        primaryText={'You are all set!'}
        secondaryText={
          userAppSetup?.successView?.successMessage ? (
            <Markdown>{userAppSetup?.successView?.successMessage}</Markdown>
          ) : (
            <NvTypography variant="body1" align={'center'}>
              <strong>{app?.name ?? 'This app'}</strong> is now ready to use! You can update the list of users who can
              access this app from the <strong>Permissions</strong> section.
            </NvTypography>
          )
        }
        width="520px"
        icon={<NvEmoji symbol="🎉" label="completion-state" />}
      />

      {goToAppHomeComponent || goToSlackHomeComponent ? (
        <NvFlex gap="8px" alignItems="center">
          {goToAppHomeComponent}
          {goToSlackHomeComponent}
        </NvFlex>
      ) : (
        <NvFlex gap="8px" alignItems="center">
          <NvLink onClick={handleNextClick}>{GO_TO_ACTIONER_TEXT}</NvLink>
        </NvFlex>
      )}
    </NvFlex>
  );
};
