import { NvAxios } from '@novaera/core';
import { CATALOG_ENTITY_TYPES_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_ENTITY_TYPES } from '../keys';

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListCatalogEntityParams, ListCatalogEntityTypesResponse } from './types';

const listCatalogEntityTypes = async (params?: ListCatalogEntityParams) => {
  const { queryParams } = params || {};
  const { nextPageKey, nameFilter } = queryParams || {};
  const result = await NvAxios.get<ListCatalogEntityTypesResponse>(`${CATALOG_ENTITY_TYPES_ROOT_PATH}`, {
    params: {
      ...(nextPageKey ? { nextPageKey: nextPageKey } : {}),
      ...(nameFilter ? { nameFilter: nameFilter } : {}),
    },
  });
  return result?.data;
};

export const useListCatalogEntityTypes = (params?: ListCatalogEntityParams) => {
  return useInfiniteQuery<ListCatalogEntityTypesResponse>(
    QUERY_KEY_CATALOG_ENTITY_TYPES.list(params?.queryParams?.nameFilter),
    (props) =>
      listCatalogEntityTypes({
        queryParams: {
          nextPageKey: props.pageParam,
          nameFilter: params?.queryParams?.nameFilter,
        },
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.nextPageKey,
    }
  );
};
