import { useGetConnectionSchemaService } from '@novaera/actioner-service';

export const useUserConnectionInfoController = ({
  integrationId,
  schemaId,
}: {
  integrationId?: string;
  schemaId: string;
}) => {
  const { data: connectionSchema, isFetching: isConnectionSchemaLoading } = useGetConnectionSchemaService({
    integrationId,
    schemaId,
  });

  return {
    connectionSchema,
    isConnectionSchemaLoading,
  };
};
