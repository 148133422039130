import { Trigger } from '../types';

export type Contributor = { id: string; type: string; logoUrl: string; name: string };

export type Tag = { label: string; color?: 'green' | 'pink' | 'red' };

export interface AppSchema {
  id: string;
  name: string;
  version: string;
  logoUrl: string;
  description: string;
  descriptionType: 'markdown' | 'plainText';
  categories: string[];
  tags: Tag[];
  numberOfStars: string;
  numberOfImports: string;
  contributors: Contributor[];
  publishedAt: string;
  changeLog: string;
  shortDescription: string;
  mediaFiles?: MediaFile[];
  documents?: AppDocument[];
  managed: boolean;
  free: boolean;
}

export type AppSchemasQuery = {
  operation: AppSchemasQueryOperation;
  type: AppSchemasQueryType;
  value: string;
  displayValue?: string;
};

export type AppSchemasPayload = {
  queries: AppSchemasQuery[];
  pagination: { next: number; limit: number } | null;
  sortParams: { sortOption: AppSchemasQuerySortOption; sortOrder: 'asc' | 'desc' } | null;
};

export enum AppSchemasQueryOperation {
  CONTAINS = 'contains',
  EQUALS = 'equals',
}

export enum AppSchemasQueryType {
  CATEGORY = 'category',
  OWNER = 'owner',
  TAG = 'tag',
  KEYWORD = 'keyword',
}

export enum AppSchemasQuerySortOption {
  BY_STARS = 'by-stars',
  BY_IMPORTS = 'by-imports',
  BY_NAME = 'by-name',
  BY_PUBLISHED_AT = 'by-published-at',
}

export interface AppSchemeSearchMetadata {
  availableCategories: string[];
  availableQueryOptions: AppSchemasQueryOperation[];
  availableQueryTypes: AppSchemasQueryType[];
  availableSortOptions: AppSchemasQuerySortOption[];
  availableTags: Tag[];
}

export enum AppSchemeChangeSize {
  PATCH = 'Patch',
  MINOR = 'Minor',
  MAJOR = 'Major',
}

export type WorkflowSchema = {
  description?: string;
  name: string;
  trigger: {
    type: Trigger['type'];
    integrationId: string;
  };
};

export interface AppSchemaDetail extends AppSchema {
  workflows: WorkflowSchema[];
  starred: boolean;
  featured: boolean;
  free: boolean;
  slackAppDetails:
    | {
        installationUrl: string;
        inMarketplace: true;
      }
    | { inMarketplace: false };
  appMenuItems: string[];
}

export enum MediaFileReferenceType {
  S3 = 's3',
  WEB = 'web',
}

export type S3MediaFileReference = {
  type: MediaFileReferenceType.S3;
  objectKey: string;
  fileName: string;
  url?: string;
};

export type WebMediaFileReference = {
  type: MediaFileReferenceType.WEB;
  url: string;
};

export enum MediaFileType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface MediaFileBase {
  displayText: string;
}

export interface MediaFileImage extends MediaFileBase {
  fileReference: S3MediaFileReference;
  type: MediaFileType.IMAGE;
}

export interface MediaFileVideo extends MediaFileBase {
  fileReference: WebMediaFileReference;
  type: MediaFileType.VIDEO;
}

export interface MediaFileBase {
  displayText: string;
}

export interface MediaFileImage extends MediaFileBase {
  fileReference: S3MediaFileReference;
  type: MediaFileType.IMAGE;
}

export interface MediaFileVideo extends MediaFileBase {
  fileReference: WebMediaFileReference;
  type: MediaFileType.VIDEO;
}

export type MediaFile = MediaFileImage | MediaFileVideo;

export enum AppDocumentCategory {
  USE_CASE = 'use-case',
  SUPPORT = 'support',
}

export interface AppDocument {
  displayText: string;
  url: string;
  category: AppDocumentCategory;
}

export type AppDetailSetupWorkflow = { id: string; autoRun: boolean };
