import { useMemo } from 'react';
import { DataModelState, GetDataModelResponse } from '../../common/types';

export const useDataModel = (response: GetDataModelResponse | undefined) => {
  const dataModel = useMemo(() => {
    return !response
      ? undefined
      : response.draft
      ? { state: DataModelState.DRAFT, ...response.draft }
      : { state: DataModelState.SAVED, ...response.saved };
  }, [response]);

  const savedDataModel = useMemo(() => {
    return response?.saved;
  }, [response?.saved]);

  const draftDataModel = useMemo(() => {
    return response?.draft;
  }, [response?.draft]);

  return {
    dataModel,
    savedDataModel,
    draftDataModel,
  };
};
