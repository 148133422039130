import { isRequired, NvField, NvTextField } from '@novaera/core';
import { BodyItem } from '../../item';

export const BaseUrlField = () => {
  return (
    <BodyItem
      description="Enter the Base URL. This URL will be used as the prefix for the API paths in your tool."
      title="Base URL"
      hasRequiredIndicator
    >
      <NvField
        name={'authentication.baseUrl'}
        direction="label-on-top"
        labelVariant="h5"
        isRequired
        hasRequiredIndicator
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        component={<NvTextField />}
      ></NvField>
    </BodyItem>
  );
};
