import { InputParameter } from '@novaera/actioner-service';
import { generateUniqueId } from '@novaera/utils';
import { InputMenuItemValue } from '../add-new-input-menu-items/types';

export const generateNewInputParameter = (input: InputMenuItemValue, inputParameters: InputParameter[]) => {
  const { schema, component } = input;
  const id = generateUniqueId();
  let identifier = inputParameters.length + 1;
  let newName = `input_component_${identifier}`;
  let order = identifier;

  for (; identifier < 500; identifier++) {
    const tempNewName = `input_component_${identifier}`;
    const found = inputParameters?.some((ip) => ip.name === tempNewName);
    if (!found) {
      newName = tempNewName;
      order = identifier;
      break;
    }
  }

  const newInputParameter: InputParameter = {
    id,
    name: newName,
    order,
    uiComponent: component,
    schema: schema,
  };

  return newInputParameter;
};
