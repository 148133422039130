import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP_SETUP } from '../keys';
import { GetUserAppSetupStatusParams, GetUserAppSetupStatusResponse } from './types';

const getUserAppSetupStatus = async ({ appId }: GetUserAppSetupStatusParams) => {
  const result = await NvAxios.get<GetUserAppSetupStatusResponse>(`${USER_APP_ROOT_PATH}/${appId}/setup/status`);
  return result.data;
};

export const useGetUserAppSetupStatus = ({ appId }: GetUserAppSetupStatusParams) =>
  useQuery<GetUserAppSetupStatusResponse>(
    QUERY_KEYS_USER_APP_SETUP.status({ appId }),
    () => getUserAppSetupStatus({ appId }),
    {
      enabled: !!appId,
    }
  );
