import { PaginationDirectionWrapper } from './styled';
import { PaginationDirectionProps } from './types';

export const NvPaginationDirection: React.FC<React.PropsWithChildren<PaginationDirectionProps>> = ({
  disabled,
  onClick,
  children,
}) => (
  <PaginationDirectionWrapper disabled={disabled} onClick={onClick}>
    {children}
  </PaginationDirectionWrapper>
);
