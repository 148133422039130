import { NvFlex } from '@novaera/core';
import { ErrorMarker } from '../../../../../../../../../../action-designer/components/error-marker';

export const OutputTabItemWrapper: React.FC<
  React.PropsWithChildren<{
    hasError?: boolean;
  }>
> = ({ children, hasError }) => {
  return (
    <NvFlex direction="row" alignItems="center">
      {children}
      {hasError && (
        <NvFlex marginLeft="-9px" zIndex="1">
          <ErrorMarker variant="circle" />
        </NvFlex>
      )}
    </NvFlex>
  );
};
