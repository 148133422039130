import { ParameterTypes, SlackBlockParameterMapping, SlackBlocks } from '@novaera/actioner-service';
import { useCallback } from 'react';

export const useSlackBlocksAdapter = () => {
  const format = useCallback((value?: SlackBlockParameterMapping) => {
    return value?.blocks;
  }, []);

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (value: SlackBlocks): SlackBlockParameterMapping | undefined => {
        if (value) {
          return { type: ParameterTypes.SLACK_BLOCK, blocks: value, parameterId: id };
        }
        return undefined;
      },
    []
  );

  return { format, parse };
};
