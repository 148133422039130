import { NvAxios, useMutation } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import { ActionState, IntegrationAction } from '../../types';
import { INTEGRATION_ACTION_INPUT_PARAMETERS_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { CreateIntegrationActionResponse, GetIntegrationActionResponse, UpdateInputParameterParams } from '../types';
import { replaceActionInCache } from '../utils/replace-action-parts-in-cache';

const updateInputParameter = async (params: UpdateInputParameterParams) => {
  const { integrationId, actionId, ...inputParameter } = params;
  const result = await NvAxios.patch<CreateIntegrationActionResponse>(
    INTEGRATION_ACTION_INPUT_PARAMETERS_PATH(integrationId, actionId),
    inputParameter
  );
  return result?.data;
};

export const useUpdateInputParameter = () => {
  const cache = useQueryClient();

  return useMutation(updateInputParameter, {
    onMutate: ({ integrationId, actionId, version, ...inputParameter }) => {
      const oldIntegrationAction = cache.getQueryData<GetIntegrationActionResponse>(
        QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version)
      );

      assert(
        !!oldIntegrationAction,
        new Error('Integration action should be found in the cache before optimistic update'),
        'ERROR'
      );

      const actionStateToBeUpdated = oldIntegrationAction?.draft
        ? oldIntegrationAction.draft
        : { ...oldIntegrationAction.persisted, state: ActionState.DRAFT };

      const newIntegrationActionAsDraft: IntegrationAction = {
        ...actionStateToBeUpdated,
        inputParameters: actionStateToBeUpdated.inputParameters?.map((ip) =>
          ip.id === inputParameter.id ? inputParameter : ip
        ),
      };
      const returnContext = replaceActionInCache({
        cache,
        integrationId,
        actionId,
        newIntegrationActionAsDraft,
        version,
      });
      return returnContext;
    },
    onError: (_error, { integrationId, actionId, version }, context) => {
      if (context) {
        const { oldIntegrationActionList, oldIntegrationActionDetail } = context;
        cache.setQueryData(QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version), oldIntegrationActionList);
        cache.setQueryData(
          QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version),
          oldIntegrationActionDetail
        );
      }
    },
  });
};
