import { InputBaseComponentProps } from '@mui/material/InputBase';
import React, { FormEvent } from 'react';
import { IMask, IMaskInput } from 'react-imask';

export const TimeInputMask = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputBaseComponentProps>(
  ({ onChange, ...props }, ref) => (
    <IMaskInput
      {...props}
      mask="HH:MM"
      blocks={{
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2,
          autofix: 'pad',
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2,
          autofix: 'pad',
        },
      }}
      inputRef={ref}
      onAccept={(value, mask, event) =>
        event && onChange?.(event as unknown as FormEvent<HTMLInputElement | HTMLTextAreaElement>)
      }
      overwrite
    />
  )
);
