import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomExecuteButtonIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.087 9.25921V11.9521L9.26413 11.952L11.2609 9.77528C11.6339 9.36861 11.6339 8.71168 11.2609 8.305C10.8878 7.89833 10.2852 7.89833 9.91217 8.305L6.27978 12.2649C5.90674 12.6715 5.90674 13.3285 6.27978 13.7351L9.91217 17.695C10.2852 18.1017 10.8878 18.1017 11.2609 17.695C11.6339 17.2883 11.6339 16.6314 11.2609 16.2247L9.26413 14.0375L16.0435 14.0376C16.5696 14.0376 17 13.5684 17 12.9949V9.25921C17 8.68569 16.5696 8.21645 16.0435 8.21645C15.5174 8.21645 15.087 8.68569 15.087 9.25921Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4V20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
