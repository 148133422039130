import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { Integration } from '../../types';

import { INTEGRATION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION } from '../keys';
import { BatchGetIntegrationParams } from '../types';

const batchGetIntegration = async (ids: BatchGetIntegrationParams) => {
  const result = await NvAxios.post<Integration[]>(`${INTEGRATION_ROOT_PATH}/batch-get`, { identifiers: ids });
  return result?.data;
};

export const useBatchGetIntegration = () => {
  const cache = useQueryClient();
  return useMutation(batchGetIntegration, {
    onSuccess: (data, variables) => {
      variables.forEach((id) => {
        const integrationDetail = data.find((d) => d.id === id);
        cache.setQueryData(QUERY_KEYS_INTEGRATION.detail(id), integrationDetail);
      });
    },
    cacheTime: Infinity,
  });
};
