import { NvBox, NvTypography } from '@novaera/core';
import { calculateRequestName } from '../../../../utils';
import { HeaderWrapperLeftBoxRefersName } from './styled';

export const RequestRefName: React.FC<React.PropsWithChildren<{ name: string }>> = ({ name }) => (
  <NvBox padding="2px 0">
    <HeaderWrapperLeftBoxRefersName>
      <NvTypography noWrap variant="c2">
        {calculateRequestName(name)}
      </NvTypography>
    </HeaderWrapperLeftBoxRefersName>
  </NvBox>
);
