import { APP_LOADING_ANIMATION_DATA } from '@novaera/ah-common';
import { NvBox, NvFlex, NvLottie, NvTypography, Portal } from '@novaera/core';
import { AppLoadingWrapper } from './styled';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: APP_LOADING_ANIMATION_DATA,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const LoadingPage: React.FC<
  React.PropsWithChildren<{
    message?: string;
  }>
> = ({ message }) => {
  return (
    <Portal node={document.getElementById('root')}>
      <NvBox position={'absolute'} top={0} width={'100%'}>
        <AppLoadingWrapper>
          <NvFlex alignItems="center" justifyContent="center">
            {message && (
              <NvFlex marginBottom="110px">
                <NvTypography variant="h1">{message}</NvTypography>
              </NvFlex>
            )}
            <NvFlex>
              <NvLottie options={animationOptions} width="150px" height="150px" speed={0} />
            </NvFlex>
          </NvFlex>
        </AppLoadingWrapper>
      </NvBox>
    </Portal>
  );
};
