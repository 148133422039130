import { useMemo } from 'react';
import ReactPlayer from 'react-player';
import { PlayerWrapper } from './styled';
import { CarouselVideoProps, YOUTUBE_VIDEO_QUALITY } from './types';
import { useYoutubeVideoThumbnail } from './utils';

export const CarouselVideo: React.FC<React.PropsWithChildren<CarouselVideoProps>> = ({ disabled, ...props }) => {
  const { isLoading, thumbnail } = useYoutubeVideoThumbnail({
    url: props.url && typeof props.url === 'string' ? props.url : null,
    quality: YOUTUBE_VIDEO_QUALITY.MAXIMUM,
  });
  const light = useMemo(() => thumbnail || true, [thumbnail]);

  return !isLoading ? (
    <PlayerWrapper disabled={disabled}>
      <ReactPlayer className="react-player" width="100%" height="100%" light={light} {...props} />
    </PlayerWrapper>
  ) : null;
};
