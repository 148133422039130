import { SearchUserAppPayloadFilter, useSearchUserApps } from '@novaera/actioner-service';
import { useDebounce, useForm } from '@novaera/core';
import { useMemo, useState } from 'react';

export const useAddDependency = ({ filters }: { filters: SearchUserAppPayloadFilter[] }) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const debouncedKeyword = useDebounce(searchKeyword, 500);

  const form = useForm();

  const isAppFieldActive = form?.getFieldState('appId')?.active;

  const { data, isLoading } = useSearchUserApps({
    payload: {
      keyword: debouncedKeyword,
      filters,
    },
  });

  const userApps = useMemo(() => data?.apps ?? [], [data?.apps]);

  return {
    onAppSearchKeywordChange: setSearchKeyword,
    userApps,
    isLoading: isLoading && !isAppFieldActive,
    isAppFieldActive,
  };
};
