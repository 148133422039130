import classnames from 'classnames';
import { useState } from 'react';
import { DropSensor, DroppedBgBox, DroppedBorderBox } from './styled';

export const DropItemPlace = () => {
  const [isDragOverCaptured, setIsDragOverCaptured] = useState(false);

  return (
    <DropSensor
      onDragEnter={() => {
        setIsDragOverCaptured(true);
      }}
      onDragLeave={() => {
        setIsDragOverCaptured(false);
      }}
    >
      <DroppedBgBox
        className={classnames({
          'is-drag-over': isDragOverCaptured,
        })}
      >
        <DroppedBorderBox
          className={classnames({
            'is-drag-over': isDragOverCaptured,
          })}
        />
      </DroppedBgBox>
    </DropSensor>
  );
};
