import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_ROOT_PATH } from '../constants';
import { DeleteIntegrationParams } from '../types';
import { useUpdateIntegrationCache } from '../use-update-cache';

const deleteIntegration = async (params: DeleteIntegrationParams) => {
  const { id } = params;
  const result = await NvAxios.delete(`${INTEGRATION_ROOT_PATH}/${id}`);
  return result?.data;
};

export const useDeleteIntegration = () => {
  const { updateCache } = useUpdateIntegrationCache();
  return useMutation(deleteIntegration, {
    onSuccess: (_, params) => {
      updateCache({
        id: params.id,
        method: 'remove',
      });
    },
  });
};
