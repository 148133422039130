import { useMemo } from 'react';
import { AppConfigDetail, AppConfigDetailWithState, ConfigState } from '../../types';
import { GetConfigResponse } from '../types';

export const useConfig = (
  response: GetConfigResponse | undefined
): {
  config?: AppConfigDetailWithState;
  savedConfig?: AppConfigDetail;
  draftConfig?: AppConfigDetail;
} => {
  const config = useMemo(() => {
    return !response
      ? undefined
      : response.draft
      ? { ...response.draft, state: ConfigState.DRAFT }
      : { ...response.saved, state: ConfigState.SAVED };
  }, [response]);

  const savedConfig = useMemo(() => {
    return response?.saved;
  }, [response?.saved]);

  const draftConfig = useMemo(() => {
    return response?.draft;
  }, [response?.draft]);

  return {
    config,
    savedConfig,
    draftConfig,
  };
};
