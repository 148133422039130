import { NvTabs } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledNvTabs = styled(NvTabs, {
  shouldForwardProp: (prop) => prop !== 'commandLineHeight',
})<{
  commandLineHeight?: number;
}>`
  .tab-header-container {
    position: sticky;
    top: calc(68px + 40px + ${({ commandLineHeight }) => commandLineHeight}px);
    z-index: 90;
  }

  .MuiTabs-root {
    flex: 0 0 auto;
    min-height: 24px;
    height: auto;
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    border-radius: 12px;
    /* ${({ theme }) => theme.elevations.e100}; */
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.nv_neutral[30]};

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        gap: 8px;
        padding: 16px;
        border-bottom: none;
        overflow-x: auto;
        --mask-image-content: linear-gradient(to left, transparent, black 16px, black calc(100% - 16px), transparent);
        mask-image: linear-gradient(to left, transparent, black 16px, black calc(100% - 16px), transparent);
        -webkit-mask-image: linear-gradient(to left, transparent, black 16px, black calc(100% - 16px), transparent);
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .MuiTab-root {
          color: ${({ theme }) => theme.palette.nv_neutral[1000]};

          &.Mui-selected {
            color: ${({ theme }) => theme.palette.nv_neutral[0]};
          }
        }
      }
    }
    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      height: 32px;
      min-height: 32px;
      background: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
      border-radius: 6px;
      color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
      }

      &.Mui-selected {
        background: ${({ theme }) => theme.palette.nv_neutral_alpha[500]};
        color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }

      .MuiSvgIcon-root {
        margin-left: -6px;
      }

      // To fix conflict of colors for containedWarning variant
      &.MuiButton-containedInfo {
        color: ${({ theme }) => theme.palette.nv_neutral[1000]};
        background-color: ${({ theme }) => theme.palette.nv_new[40]};

        &.MuiLoadingButton-loading {
          background-color: ${({ theme }) => theme.palette.nv_new[40]};
        }

        &.Mui-disabled {
          color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
          background-color: ${({ theme }) => theme.palette.nv_new[20]};
        }

        &.Mui-focusVisible,
        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_new[50]};
        }

        &:active {
          background-color: ${({ theme }) => theme.palette.nv_new[60]};
        }

        & .MuiLoadingButton-loadingIndicator {
          color: ${({ theme }) => theme.palette.nv_neutral[1000]};
        }
      }
    }
  }

  .sub-tabs-body-wrapper {
    display: flex;
    flex: 0 0 auto;
    height: auto;
    min-height: 0;
    justify-content: flex-start;
    padding: 16px;
    overflow: initial;
  }

  &.sub-tabs-action-designer {
    .tab-header-container {
      position: inherit;
      z-index: 0;

      .MuiTabs-root {
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;

        .MuiButtonBase-root {
          height: 24px;
          min-height: 24px;
          font-size: 12px;
          line-height: 16px;
          padding: 4px 12px;

          .MuiSvgIcon-root {
            margin-left: 0;
          }
        }
      }
    }

    .sub-tabs-body-wrapper {
      justify-content: flex-start;
      padding-top: 0;
    }
  }
`;
