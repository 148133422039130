import { Vertex } from '@novaera/actioner-service';
import { NovaeraNode } from '@novaera/core';
import { AppWorkflowNodeType } from '../../../../components/user-app-workflow-node-types/types';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';

export const addNode = (n: Vertex, name: string) => {
  const convertedNode: NovaeraNode<AppWorkflowNodeType> = {
    id: n.alias,
    name,
    width: 40,
    height: 40,
    type: n.type,
    alias: n.alias,
    nextNodeAlias: n.nextAlias,
  };
  userAppGraph.addNode(convertedNode);
};
