import { ApplicationConfig } from '@novaera/application-config';
import { ROUTE_DEFAULTS, TARGET_URI } from '@novaera/constants';
import {
  ExploreOutlinedIcon,
  NvArrowDropDownIcon,
  NvArrowDropUpIcon,
  NvArrowForwardIcon,
  NvBox,
  NvCloseIcon,
  NvCollapse,
  NvCustomCICDIcon,
  NvCustomSalesIcon,
  NvDivider,
  NvFlex,
  NvHelpDeskIcon,
  NvIncidentManagementIcon,
  NvMenuIcon,
  NvProductLogoWithNameIcon,
  NvPullRequestManagementIcon,
  NvTypography,
} from '@novaera/core';
import { useNavigate } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { setCookieForSpecificDomain } from '@novaera/utils';
import { useState } from 'react';
import { ROUTES } from '../../common/routes';
import celebrationIcon from '../../images/celebration-usecase-icon.png';
import jiraUseCaseIcon from '../../images/jira-usecase-icon.png';
import { PublicMenuPopperMenu } from '../public-menu-popper-menu';
import {
  HamburgerMenuButton,
  HamburgerMenuButtonContainer,
  HamburgerMenuLink,
  PublicNavigationButton,
  PublicNavigationButtons,
  PublicNavigationContainer,
  PublicNavigationDrawer,
  PublicNavigationInner,
  PublicNavigationLink,
  PublicNavigationLinks,
  PublicNavigationLogoLink,
  SolutionsDropdownMenuItem,
  SolutionsDropdownMenuLink,
} from './styled';

export const PublicNavigation: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [showSolutionSubItems, setShowSolutionSubItems] = useState<boolean>(false);
  const [showUsecasesSubItems, setShowUsecasesSubItems] = useState<boolean>(false);
  const [showResourcesSubItems, setShowResourcesSubItems] = useState<boolean>(false);

  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);

  const navigateToLogin = () => {
    const targetUri = window.location.pathname;
    setCookieForSpecificDomain(TARGET_URI, targetUri, `${ApplicationConfig.Actioner.baseHost}`);
    navigate(ROUTE_DEFAULTS.SIGN_IN);
  };

  return (
    <>
      <PublicNavigationContainer>
        <PublicNavigationInner>
          <PublicNavigationLogoLink href="https://actioner.com">
            <NvProductLogoWithNameIcon height={32} width={140} color="secondary" textColor="#fff" />
          </PublicNavigationLogoLink>
          <PublicNavigationLinks>
            <PublicMenuPopperMenu label="Solutions">
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/sales">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Revenue operations</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/help-desk">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Help desk</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/incident-management">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Incident management</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/pull-request-management">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Pull request management</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/continuous-integration-and-delivery">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Continuous integration & delivery</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/jira-software">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Jira Software</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/solutions/celebrations">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3">Celebrations</NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
            </PublicMenuPopperMenu>

            <PublicNavigationLink href={ROUTES.AppDirectory}>
              <NvTypography variant="h3" fontWeight="600">
                App directory
              </NvTypography>
            </PublicNavigationLink>

            <PublicMenuPopperMenu label="Use cases">
              <SolutionsDropdownMenuLink href="https://actioner.com/sales-use-cases">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <NvCustomSalesIcon />
                    <NvTypography variant="h3" fontWeight="600">
                      Sales
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/support-use-cases">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <NvHelpDeskIcon />
                    <NvTypography variant="h3" fontWeight="600">
                      Help desk
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/incident-management-use-cases">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <NvIncidentManagementIcon />
                    <NvTypography variant="h3" fontWeight="600">
                      Incident management
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/pr-review-use-cases/all">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <NvPullRequestManagementIcon />
                    <NvTypography variant="h3" fontWeight="600">
                      Pull request management
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/continuous-integration-and-delivery-use-cases/all">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <NvCustomCICDIcon />
                    <NvTypography variant="h3" fontWeight="600">
                      Continuous integration & delivery
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/jira-software-use-cases">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <img src={jiraUseCaseIcon} width="26px" height="26px" alt="Jira Software" />
                    <NvTypography variant="h3" fontWeight="600">
                      Jira Software
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/celebrations-use-cases">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="top" gap="16px">
                    <img src={celebrationIcon} width="26px" height="26px" alt="Celebrations" />
                    <NvTypography variant="h3" fontWeight="600">
                      Celebrations
                    </NvTypography>
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <NvDivider sx={{ margin: '8px 0' }} />
              <SolutionsDropdownMenuLink href="https://actioner.com/use-cases">
                <SolutionsDropdownMenuItem>
                  <NvFlex flexDirection="row" alignItems="center" gap="16px">
                    <ExploreOutlinedIcon fontSize="small" />
                    <NvTypography variant="h4">Explore all use cases</NvTypography>
                    <NvArrowForwardIcon fontSize="small" />
                  </NvFlex>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
            </PublicMenuPopperMenu>

            <PublicMenuPopperMenu label="Resources">
              <SolutionsDropdownMenuLink href="https://actioner.com/blog">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3" fontWeight="600">
                    Blog
                  </NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/guides">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3" fontWeight="600">
                    Guides
                  </NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/help/actioner-basics">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3" fontWeight="600">
                    Help center
                  </NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
              <SolutionsDropdownMenuLink href="https://actioner.com/compare">
                <SolutionsDropdownMenuItem>
                  <NvTypography variant="h3" fontWeight="600">
                    Compare
                  </NvTypography>
                </SolutionsDropdownMenuItem>
              </SolutionsDropdownMenuLink>
            </PublicMenuPopperMenu>

            <PublicNavigationLink href="https://actioner.com/pricing">
              <NvTypography variant="h3" fontWeight="600">
                Pricing
              </NvTypography>
            </PublicNavigationLink>
          </PublicNavigationLinks>

          <NvFlex direction="row" flex="0 0 auto" gap="16px" alignItems="center">
            <PublicNavigationButtons>
              <PublicNavigationButton variant="text" size="large" onClick={navigateToLogin}>
                <NvTypography variant="h3" fontWeight="600">
                  Login
                </NvTypography>
              </PublicNavigationButton>
            </PublicNavigationButtons>
            <HamburgerMenuButtonContainer>
              <HamburgerMenuButton onlyIcon size="large" variant="text" onClick={() => setIsHamburgerOpen(true)}>
                <NvMenuIcon fontSize="large" htmlColor={palette.nv_neutral[0]} />
              </HamburgerMenuButton>
            </HamburgerMenuButtonContainer>
          </NvFlex>
        </PublicNavigationInner>
      </PublicNavigationContainer>

      <PublicNavigationDrawer anchor="left" open={isHamburgerOpen} fullWidth borderRadius="0">
        <NvFlex margin="24px" gap="64px">
          <NvFlex direction="row" alignItems="center">
            <NvBox flex="1 1 auto">
              <NvProductLogoWithNameIcon height={32} width={140} textColor="#fff" />
            </NvBox>

            <HamburgerMenuButton onlyIcon size="large" variant="text" onClick={() => setIsHamburgerOpen(false)}>
              <NvCloseIcon fontSize="large" htmlColor={palette.nv_neutral[0]} />
            </HamburgerMenuButton>
          </NvFlex>
          <NvFlex gap="100px" paddingBottom="60px">
            <NvFlex>
              <HamburgerMenuLink
                onClick={() => {
                  setShowSolutionSubItems(!showSolutionSubItems);
                }}
              >
                <NvFlex direction="row" alignItems="center">
                  <NvTypography variant="h1" fontWeight="600">
                    Solutions
                  </NvTypography>
                  {showSolutionSubItems ? (
                    <NvArrowDropUpIcon fontSize="large" />
                  ) : (
                    <NvArrowDropDownIcon fontSize="large" />
                  )}
                </NvFlex>
              </HamburgerMenuLink>
              <NvCollapse in={showSolutionSubItems}>
                <NvFlex>
                  <SolutionsDropdownMenuLink href="https://actioner.com/solutions/sales" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Revenue operations</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/solutions/help-desk" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Help desk</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink
                    href="https://actioner.com/solutions/incident-management"
                    className="mobile"
                  >
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Incident management</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink
                    href="https://actioner.com/solutions/pull-request-management"
                    className="mobile"
                  >
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Pull request management</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink
                    href="https://actioner.com/solutions/continuous-integration-and-delivery"
                    className="mobile"
                  >
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Continuous integration & delivery</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/solutions/jira-software" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Jira Software</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/solutions/celebrations" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3">Celebrations</NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                </NvFlex>
              </NvCollapse>
              <HamburgerMenuLink href={ROUTES.AppDirectory}>
                <NvTypography variant="h1" fontWeight="600">
                  App directory
                </NvTypography>
              </HamburgerMenuLink>
              <HamburgerMenuLink
                onClick={() => {
                  setShowUsecasesSubItems(!showUsecasesSubItems);
                }}
              >
                <NvFlex direction="row" alignItems="center">
                  <NvTypography variant="h1" fontWeight="600">
                    Use cases
                  </NvTypography>
                  {showUsecasesSubItems ? (
                    <NvArrowDropUpIcon fontSize="large" />
                  ) : (
                    <NvArrowDropDownIcon fontSize="large" />
                  )}
                </NvFlex>
              </HamburgerMenuLink>
              <NvCollapse in={showUsecasesSubItems}>
                <NvFlex>
                  <SolutionsDropdownMenuLink href="https://actioner.com/sales-use-cases" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <NvCustomSalesIcon />
                        <NvTypography variant="h3" fontWeight="600">
                          Sales
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/support-use-cases" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <NvHelpDeskIcon />
                        <NvTypography variant="h3" fontWeight="600">
                          Help desk
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink
                    href="https://actioner.com/incident-management-use-cases"
                    className="mobile"
                  >
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <NvIncidentManagementIcon />
                        <NvTypography variant="h3" fontWeight="600">
                          Incident management
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/pr-review-use-cases/all" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <NvPullRequestManagementIcon />
                        <NvTypography variant="h3" fontWeight="600">
                          Pull request management
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink
                    href="https://actioner.com/continuous-integration-and-delivery-use-cases/all"
                    className="mobile"
                  >
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <NvCustomCICDIcon />
                        <NvTypography variant="h3" fontWeight="600">
                          Continuous integration & delivery
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/jira-software-use-cases" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <img src={jiraUseCaseIcon} width="26px" height="26px" alt="Jira Software" />
                        <NvTypography variant="h3" fontWeight="600">
                          Jira Software
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/celebrations-use-cases" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <img src={celebrationIcon} width="26px" height="26px" alt="Celebrations" />
                        <NvTypography variant="h3" fontWeight="600">
                          Celebrations
                        </NvTypography>
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/use-cases" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvFlex flexDirection="row" alignItems="center" gap="16px">
                        <ExploreOutlinedIcon fontSize="small" />
                        <NvTypography variant="h4">Explore all use cases</NvTypography>
                        <NvArrowForwardIcon fontSize="small" />
                      </NvFlex>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                </NvFlex>
              </NvCollapse>

              <HamburgerMenuLink
                onClick={() => {
                  setShowResourcesSubItems(!showResourcesSubItems);
                }}
              >
                <NvFlex direction="row" alignItems="center">
                  <NvTypography variant="h1" fontWeight="600">
                    Resources
                  </NvTypography>
                  {showResourcesSubItems ? (
                    <NvArrowDropUpIcon fontSize="large" />
                  ) : (
                    <NvArrowDropDownIcon fontSize="large" />
                  )}
                </NvFlex>
              </HamburgerMenuLink>
              <NvCollapse in={showResourcesSubItems}>
                <NvFlex>
                  <SolutionsDropdownMenuLink href="https://actioner.com/blog" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3" fontWeight="600">
                        Blog
                      </NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/guides" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3" fontWeight="600">
                        Guides
                      </NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/help/actioner-basics" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3" fontWeight="600">
                        Help center
                      </NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                  <SolutionsDropdownMenuLink href="https://actioner.com/compare" className="mobile">
                    <SolutionsDropdownMenuItem>
                      <NvTypography variant="h3" fontWeight="600">
                        Compare
                      </NvTypography>
                    </SolutionsDropdownMenuItem>
                  </SolutionsDropdownMenuLink>
                </NvFlex>
              </NvCollapse>
              <HamburgerMenuLink href="https://actioner.com/pricing">
                <NvTypography variant="h1" fontWeight="600">
                  Pricing
                </NvTypography>
              </HamburgerMenuLink>
            </NvFlex>
          </NvFlex>
        </NvFlex>
      </PublicNavigationDrawer>
    </>
  );
};
