import { DefaultNodeSummary, FunctionNode, FunctionVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class FunctionComponentFactory implements ComponentFactory<FunctionNode> {
  aliasForFunction: string;
  nameForFunction: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('function');
    this.aliasForFunction = newAlias;
    this.nameForFunction = newName;
  }

  get componentForGraph() {
    const functionRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForFunction,
      width: 40,
      height: 40,
      id: this.aliasForFunction,
      type: 'function',
      alias: this.aliasForFunction,
    };

    return { root: functionRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: FunctionNode) {
    const newNodeUnion: FunctionVertex = {
      type: 'function',
      alias: this.aliasForFunction,
    };
    const summary: DefaultNodeSummary = { name: this.nameForFunction, alias: this.aliasForFunction, type: 'function' };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForFunction]: summary } };
  }
}
