import {
  CloneResponse,
  DataModel,
  DataModelState,
  useDeleteDraftDataModel,
  useGetDataModel,
  useUpdateDraftDataModel,
} from '@novaera/actioner-service';
import { NvButton, NvDNSIcon, useForm, useFormState, useNvDialogModalState } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC, useState } from 'react';
import { Header } from '../../../../../../components';
import { Clone } from '../../../../../../components/clone';
import { CloneFlagger } from '../../../../../../components/clone/flagger';
import { FreeAppPermissionBoundary } from '../../../../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { USER_APP_DATABASE } from '../../../../constants';
import { useTable } from '../../use-table';
import { DataModelNameAndDescriptionUpdateModal } from './data-model-name-and-description-update-modal';

export const DataModelHeader: FC = () => {
  const { userAppId: appId, modelId } = useParams();
  const { isAppFree } = useIsAppFree({ userAppId: appId });

  const { reset, setConfig, submit } = useForm();
  const {
    hasValidationErrors,
    submitting: isSaveProgress,
    values,
  } = useFormState<DataModel & { state: DataModelState }>();
  const [isUpdateDataModelNameAndDescriptionModalOpen, setIsUpdateDataModelNameAndDescriptionModalOpen] =
    useState<boolean>(false);
  const { modelMenuItems } = useTable({ source: 'header' });
  const { dataModel, savedDataModel } = useGetDataModel({ appId: appId, modelId, retry: 3 });
  const { mutate: updateDraftDataModel } = useUpdateDraftDataModel();
  const { mutate: deleteDraftDataModel, isLoading: isDeletingDraftDataModel } = useDeleteDraftDataModel();

  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } = useNvDialogModalState<{
    id: string;
    name: string;
  }>();
  const [clonedItem, setClonedItem] = useState<CloneResponse | undefined>();

  return (
    <>
      <Header
        name={savedDataModel?.name ?? ''}
        icon={<NvDNSIcon />}
        onBack={USER_APP_DATABASE(appId)}
        actions={modelMenuItems(
          { name: savedDataModel?.name ?? '', modelId, replicateToVectorDB: !!dataModel?.replicateToVectorDB },
          {
            onCloneItemClicked: () => {
              assert(!!savedDataModel, new Error('Data model is not saved'));
              onModalOpenClicked({ id: savedDataModel?.id, name: savedDataModel.name });
            },
            onToggleReplicateToVectorDB: (replicateToVectorDB) => {
              const { state, ...restOfValues } = values;
              updateDraftDataModel({ appId, ...restOfValues, replicateToVectorDB });
            },
          }
        )}
        isDraft={dataModel?.state === DataModelState.DRAFT}
        buttonActions={
          <FreeAppPermissionBoundary appId={appId}>
            <>
              <NvButton
                size="small"
                color="secondary"
                loading={isDeletingDraftDataModel}
                disabled={isSaveProgress || isDeletingDraftDataModel}
                onClick={() => {
                  deleteDraftDataModel(
                    { id: modelId, appId },
                    {
                      onSettled: () => {
                        setConfig('keepDirtyOnReinitialize', false);
                        reset();
                        setConfig('keepDirtyOnReinitialize', true);
                      },
                    }
                  );
                }}
              >
                Discard changes
              </NvButton>
              <NvButton
                size="small"
                disabled={isSaveProgress || isDeletingDraftDataModel}
                loading={isSaveProgress}
                onClick={() => {
                  return submit();
                }}
              >
                Apply
              </NvButton>
            </>
          </FreeAppPermissionBoundary>
        }
        {...(isAppFree
          ? {}
          : {
              onEditNameClick: () => {
                setIsUpdateDataModelNameAndDescriptionModalOpen(true);
              },
            })}
        {...(hasValidationErrors ? { error: 'Fix error to apply changes.' } : {})}
      />
      <DataModelNameAndDescriptionUpdateModal
        isOpen={isUpdateDataModelNameAndDescriptionModalOpen}
        handleClose={() => {
          setIsUpdateDataModelNameAndDescriptionModalOpen(false);
        }}
      />
      {passedParameters?.id && passedParameters.name && (
        <Clone
          isOpened={isOpened}
          appId={appId}
          modelId={modelId}
          name={passedParameters.name}
          onModalCloseClicked={onModalCloseClicked}
          type="data-models"
          onCloneSuccess={(param) => {
            setClonedItem(param);
          }}
        />
      )}
      {clonedItem && (
        <CloneFlagger
          opened={clonedItem !== undefined}
          clonedItem={clonedItem}
          onFlaggerClosed={() => {
            setClonedItem(undefined);
          }}
          type={'data-models'}
        />
      )}
    </>
  );
};
