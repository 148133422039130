import { DataSourceType } from '@novaera/actioner-service';
import {
  DefaultPaper,
  DefaultRenderOption,
  NvAutocomplete,
  NvAutocompleteRenderInputParams,
  NvBox,
  NvFlex,
  NvSkeleton,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import React from 'react';
import { FORM_ITEM_DEPENDENCY_STATE } from '../../dynamic-input/providers/controller/use-check-dependency-value/types';
import { DynamicOptionsErrorMessage } from '../../options/dynamic-options-error-message';
import { DynamicOptionsWarningMessage } from '../../options/dynamic-options-warning-message';
import { ComponentHint } from '../common/adapter/component-hint';
import { SelectTypeComponentParams } from '../use-ui-component/types';

export const SingleSelectUIComponent = React.memo(
  ({
    inputParameter: { uiComponent, id },
    inputProps,
    options,
    errorContext,
    isLoading,
    onSearchAsYouTypeValueUpdate,
    dynamicInputComponentState,
    otherProps,
  }: SelectTypeComponentParams) => {
    const { placeHolder, hint, searchAsTypeConfiguration, freeSolo, dataSource } = uiComponent;
    const getOptionLabel = (option: unknown) =>
      (
        option as {
          displayValue: string;
        }
      ).displayValue ?? '';
    return (
      <>
        <NvAutocomplete
          onChange={(_, newValue, reason) => {
            if (freeSolo && reason === 'createOption') {
              const value = newValue as string;
              inputProps.onChange({ displayValue: value, identifier: value });
            } else {
              inputProps.onChange(newValue);
            }
          }}
          onInputChange={(_, newValue, reason) => {
            if (
              (searchAsTypeConfiguration?.enabled && dataSource.type === DataSourceType.DYNAMIC) ||
              (freeSolo && reason !== 'reset')
            ) {
              onSearchAsYouTypeValueUpdate?.({ value: newValue, inputParameterId: id });
            }
          }}
          {...(freeSolo
            ? {
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                },
              }
            : {})}
          value={inputProps.value}
          options={options ?? []}
          getOptionLabel={getOptionLabel}
          onFocus={(params) => {
            inputProps.onFocus(params);
          }}
          onBlur={(params) => {
            inputProps.onBlur(params);
          }}
          clearOnBlur={false}
          {...(searchAsTypeConfiguration?.enabled ? { filterOptions: (value) => value } : {})}
          PaperComponent={(props) => {
            const { children, ...rest } = props;
            return (
              <DefaultPaper {...rest} elevation={0}>
                {(isLoading ||
                  dynamicInputComponentState?.state === FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING) && (
                  <NvFlex direction="row" alignItems="center" minHeight="32px" padding="12px">
                    <NvSkeleton width="25%" />
                  </NvFlex>
                )}
                {!isLoading &&
                  dynamicInputComponentState?.state !== FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING &&
                  errorContext && (
                    <NvBox padding="12px 12px 0 12px">
                      <DynamicOptionsErrorMessage errorContext={errorContext} />
                    </NvBox>
                  )}
                {!isLoading && dynamicInputComponentState?.state === FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE && (
                  <NvBox padding="6px 6px 6px 6px">
                    <DynamicOptionsWarningMessage dynamicInputComponentState={dynamicInputComponentState} />
                  </NvBox>
                )}
                {!isLoading &&
                  dynamicInputComponentState?.state !== FORM_ITEM_DEPENDENCY_STATE.DEPENDENCIES_LOADING &&
                  dynamicInputComponentState?.state !== FORM_ITEM_DEPENDENCY_STATE.MISSING_VALUE &&
                  children}
              </DefaultPaper>
            );
          }}
          renderInput={(params: NvAutocompleteRenderInputParams) => (
            <NvTextField
              {...params}
              placeholder={placeHolder}
              inputProps={{
                ...params.inputProps,
              }}
              error={Boolean(inputProps.error)}
              size="large"
              {...otherProps}
            />
          )}
          freeSolo={freeSolo}
          forcePopupIcon
          renderOption={(props, option, { index, inputValue }) => {
            const optionLabel = getOptionLabel(option);
            const isAddOption = freeSolo && inputValue.length > 0 && index === 0 && !isLoading;
            assert(typeof optionLabel === 'string', new Error('optionLabel should be string'), 'ERROR');
            return (
              <DefaultRenderOption {...props} key={props.id}>
                <NvTypography variant="body1" noWrap>
                  {isAddOption ? 'Add: ' : ''}
                  {optionLabel}
                </NvTypography>
              </DefaultRenderOption>
            );
          }}
        />

        {hint && <ComponentHint hint={hint} />}
      </>
    );
  },
  (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
  }
);
