import { NvFlex, NvRouterLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PageFooter = styled(NvFlex)`
  justify-content: center;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  margin-top: 32px;
  border-top: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
`;

export const StyledLink = styled(NvRouterLink)`
  color: ${({ theme }) => theme.palette.nv_neutral[600]};
  text-decoration-line: underline;
`;
