import { useCallback, useEffect, useMemo, useState } from 'react';
import { NvButton } from '../button';
import { NvArrowForwardIcon } from '../icons';
import { StepLabel } from './step-label';
import { StepLabelState, StepLabelVariant } from './step-label/types';
import { StepActions, StepContent, Steps, StepTrackerWrapper } from './styled';
import { StepTrackerProps } from './types';

export const StepTracker: React.FC<React.PropsWithChildren<StepTrackerProps>> = ({
  steps,
  initialActiveStep = 0,
  onCancelClick,
  setInitialActiveStep,
}) => {
  const [activeStep, setActiveStep] = useState<number>(initialActiveStep);

  useEffect(() => {
    // if we don't have setInitialActiveStep function, we don't need to this useEffect logic.
    if (setInitialActiveStep) {
      setActiveStep(initialActiveStep);
    }
  }, [initialActiveStep, activeStep, setInitialActiveStep]);

  const currentStep = useMemo(() => {
    return steps[activeStep];
  }, [activeStep, steps]);

  const handlePreviousClick = useCallback(() => {
    setActiveStep(activeStep - 1);
    setInitialActiveStep?.(activeStep - 1);
  }, [activeStep, setInitialActiveStep]);

  const handleNextClick = useCallback(() => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
      setInitialActiveStep?.(activeStep + 1);
    }
  }, [activeStep, setInitialActiveStep, steps.length]);

  return (
    <StepTrackerWrapper>
      {steps.length > 1 && (
        <Steps>
          {steps.map(({ label }, index) => {
            const isFirst = index === 0;
            const isLast = index === steps.length - 1;
            const variant = isFirst ? StepLabelVariant.FIRST : isLast ? StepLabelVariant.END : StepLabelVariant.MIDDLE;
            const state =
              activeStep > index
                ? StepLabelState.COMPLETED
                : activeStep < index
                ? StepLabelState.NOT_COMPLETED
                : StepLabelState.ACTIVE;
            return (
              <StepLabel
                key={`key-${label}-${index}`}
                index={index}
                stepLength={steps.length}
                label={label}
                variant={variant}
                state={state}
                onStepClick={() => {
                  // only completed steps can be clickable.
                  if (state === StepLabelState.COMPLETED) {
                    setActiveStep(index);
                    setInitialActiveStep?.(index);
                  }
                }}
              />
            );
          })}
        </Steps>
      )}
      <StepContent>
        {!currentStep.showButtons ? currentStep?.content({ handleNextClick }) : currentStep?.content}
      </StepContent>
      {currentStep.showButtons && (
        <StepActions>
          <NvButton
            color="ghost"
            onClick={() => {
              onCancelClick?.(activeStep);
            }}
          >
            Cancel
          </NvButton>
          {activeStep !== 0 &&
            (currentStep?.prevButton?.({ prevClick: handlePreviousClick }) ?? (
              <NvButton color="secondary" onClick={() => handlePreviousClick()}>
                Previous
              </NvButton>
            ))}
          {currentStep?.nextButton?.({ nextClick: handleNextClick }) ?? (
            <NvButton
              color="primary"
              onClick={(e) => {
                handleNextClick();
                e.stopPropagation();
              }}
              endIcon={<NvArrowForwardIcon />}
            >
              Next
            </NvButton>
          )}
        </StepActions>
      )}
    </StepTrackerWrapper>
  );
};
