import { DEFAULT_REACT_QUERY_STALE_TIME } from '@novaera/ah-common';
import { NvAxios, useQuery } from '@novaera/core';
import { GROUPS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_GROUPS } from '../keys';
import { UseGetGroupsResponse } from '../types';

const getGroups = async () => {
  const result = await NvAxios.get(GROUPS_ROOT_PATH);
  return result?.data;
};

export const useGetGroups = (useQueryOptions?: { refetchOnWindowFocus?: boolean | 'always'; staleTime?: number }) => {
  const { refetchOnWindowFocus, staleTime } = useQueryOptions ?? {
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_REACT_QUERY_STALE_TIME,
  };
  return useQuery<UseGetGroupsResponse>(QUERY_KEYS_GROUPS.list(), () => getGroups(), {
    refetchOnWindowFocus,
    staleTime,
  });
};
