import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomStringFieldIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4C5 3.44772 5.44772 3 6 3H7H11H12C12.5523 3 13 3.44772 13 4C13 4.55228 12.5523 5 12 5H11C10.4477 5 10 5.44772 10 6V18C10 18.5523 10.4477 19 11 19H12C12.5523 19 13 19.4477 13 20C13 20.5523 12.5523 21 12 21H11H7H6C5.44772 21 5 20.5523 5 20C5 19.4477 5.44772 19 6 19H7C7.55228 19 8 18.5523 8 18V6C8 5.44772 7.55228 5 7 5H6C5.44772 5 5 4.55228 5 4ZM4 7H7V9H4V15H7V17H4C2.89543 17 2 16.1046 2 15V9C2 7.89543 2.89543 7 4 7ZM20 15H11V17H20C21.1046 17 22 16.1046 22 15V9C22 7.89543 21.1046 7 20 7H11V9H20V15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
