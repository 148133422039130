import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { BranchOperatorPanel } from './panel';
import { BranchOperatorPropertyPanelDrawerProps } from './types';

export const BranchOperatorPropertyPanelDrawer: React.FC<
  React.PropsWithChildren<BranchOperatorPropertyPanelDrawerProps>
> = ({ workflow, handleClose }) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[BranchOperatorPropertyPanelDrawer] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const branchNode = useMemo(() => {
    const originalBranchInVertex = workflow.graph?.vertices.find((v) => v.alias === selectedNode.id);
    assert(originalBranchInVertex?.type === 'branchJunction', new Error('Node in vertex should be a branch'), 'ERROR');
    const { firstInnerAliases } = originalBranchInVertex;
    const branchNodeInSummaries = workflow.nodeSummaries[selectedNode.id];
    assert(
      branchNodeInSummaries?.type === 'branchJunction',
      new Error('Node in node summary should be a branch'),
      'ERROR'
    );
    return {
      ...branchNodeInSummaries,
      branches: firstInnerAliases.map((a, index) => {
        if (branchNodeInSummaries?.branches?.[index]) {
          return branchNodeInSummaries?.branches?.[index];
        } else {
          return {
            name: '',
          };
        }
      }),
    };
  }, [workflow, selectedNode.id]);

  const {
    panelProps: [first],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        {branchNode && branchNode.type === 'branchJunction' && (
          <BranchOperatorPanel onCloseClicked={handleClose} node={branchNode} workflow={workflow} />
        )}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
