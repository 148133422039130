import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { SectionMessageProps } from './types';

export const SectionMessageContainer = styled(NvBox, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'subtle',
})<{
  variant: SectionMessageProps['variant'];
  subtle: SectionMessageProps['subtle'];
}>`
  display: flex;
  flex-direction: row;
  padding: 11px 11px 11px 11px;
  gap: 8px;
  ${({ theme, variant, subtle }) =>
    subtle
      ? `background-color:${theme.palette.nv_neutral[10]};`
      : variant === 'warning'
      ? `background-color:${theme.palette.nv_warning[10]};`
      : variant === 'info'
      ? `background-color:${theme.palette.nv_main[10]};`
      : variant === 'error'
      ? `background-color:${theme.palette.nv_error[10]};`
      : null};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
  border-radius: 8px;
`;

export const SectionMessageIconBox = styled(NvBox)`
  height: 24px;
  padding: 4px 0;
  flex: 0 0 auto;
`;

export const SectionMessageTextBox = styled(NvBox)`
  padding: 3px 0;
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
