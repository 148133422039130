import {
  Workspace,
  useCancelWorkspaceDeletionRequest,
  useGetWorkspaceDeletionRequest,
} from '@novaera/actioner-service';
import {
  NvBox,
  NvButton,
  NvDialogSlots,
  NvDialogV1,
  NvEmoji,
  NvFlex,
  NvTypography,
  SectionMessage,
  isAxiosError,
  useNvDialogModalState,
} from '@novaera/core';
import { useNavigate } from '@novaera/route';
import { format } from 'date-fns';
import { ROUTES } from '../../../common/routes';
import { WorkspaceDeletionWarningWrapper } from './styled';

export const WorkspaceDeletionWarning = ({ workspace }: { workspace: Workspace }) => {
  const { data: workspaceDeletionRequest, error: workspaceDeletionRequestError } = useGetWorkspaceDeletionRequest();
  const { mutate, isLoading } = useCancelWorkspaceDeletionRequest();

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const navigate = useNavigate();

  const handleCancelDeletionRequest = () => {
    mutate(undefined, {
      onSuccess: () => {
        onModalOpenClicked();
      },
    });
  };
  return (
    <>
      {!(isAxiosError(workspaceDeletionRequestError) && workspaceDeletionRequestError.response?.status === 404) &&
        workspaceDeletionRequest && (
          <WorkspaceDeletionWarningWrapper>
            <NvTypography variant="h3">Workspace deletion has been initiated!</NvTypography>
            <SectionMessage
              variant="error"
              message={
                <NvTypography variant="body2">
                  If you do not cancel the deletion initiation within the specified time frame, the workspace and all
                  associated data will be <b>permanently deleted</b>.
                </NvTypography>
              }
            />
            <NvTypography>
              You initiated the deletion of the workspace <b>{workspace.displayName}</b> on
              <b> {format(new Date(workspaceDeletionRequest.createdAt), 'PPpp')}</b>. You have a 72-hour time frame to
              change your mind. If you want to retain this workspace and its data, simply click the Cancel deletion
              initiation button.
            </NvTypography>
            <NvButton
              disabled={isLoading}
              loading={isLoading}
              onClick={handleCancelDeletionRequest}
              sx={{ marginBottom: '8px' }}
            >
              Cancel deletion initiation
            </NvButton>
          </WorkspaceDeletionWarningWrapper>
        )}
      <NvDialogV1 open={isOpened} maxWidth="sm" fullWidth>
        <NvDialogSlots>
          <NvDialogSlots.Slot name="icon">
            <NvBox width={'24px'}></NvBox>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="content">
            <NvFlex justifyContent="center" gap={'24px'}>
              <NvFlex alignItems="center">
                <NvEmoji symbol="🎉" size={120} label="completion" />
              </NvFlex>

              <NvTypography variant="h2" align="center">
                Welcome back!
              </NvTypography>

              <NvTypography align="center">
                We're pleased that you've decided to cancel the deletion of your {workspace.displayName}! You can now
                resume your work as usual.
              </NvTypography>
            </NvFlex>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="actions">
            <NvFlex justifyContent={'center'} flexDirection="row" width="100%">
              <NvButton
                onClick={() => {
                  onModalCloseClicked();
                  navigate(ROUTES.UserApps);
                }}
              >
                Go to My apps
              </NvButton>
            </NvFlex>
          </NvDialogSlots.Slot>
          <NvDialogSlots.Slot name="closeButton">
            <NvBox width={'24px'}></NvBox>
          </NvDialogSlots.Slot>
        </NvDialogSlots>
      </NvDialogV1>
    </>
  );
};
