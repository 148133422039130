import { useCallback } from 'react';

import { DomEvent } from '../../codemirror-wrapper';
import { Context } from '../../types';
import { useCodeInputBaseController } from '../../use-code-input-base-controller';

export const useCodeInputForSql = ({
  context,
  onChange,
  onFocus,
  onBlur,
  onEnter,
}: {
  context: Context;
  onChange?: (value: string) => void;
  onFocus?: (event: CodeMirror.Editor) => void;
  onBlur?: (event: CodeMirror.Editor) => void;
  // it has type any on codemirror too.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEnter?: (event: any) => void;
}) => {
  const {
    handleEditorChange,
    handleEditorUpdate,
    handleEditorKeyUp,
    handleEditorDidMount,
    handleOnBlur,
    setIsFocused,
    error,
  } = useCodeInputBaseController({ context, onBlur, onChange });

  const handleOnFocus: DomEvent = useCallback(
    (event) => {
      setIsFocused(true);
      onFocus?.(event);
    },
    [onFocus, setIsFocused]
  );

  const handleEditorKeyDown = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cm: any, e: any) => {
      onEnter?.(e);
    },
    [onEnter]
  );

  return {
    handleEditorChange,
    handleEditorUpdate,
    handleEditorKeyUp,
    handleEditorKeyDown,
    handleEditorDidMount,
    handleOnBlur,
    handleOnFocus,
    error,
  };
};
