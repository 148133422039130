import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS } from '../keys';
import { UseGetWorkflowResponse } from '../use-get-workflow/types';
import { PersistWorkflowParams } from './types';

const persistWorkflow = async ({ appId, id }: PersistWorkflowParams) => {
  const result = await NvAxios.post(`${USER_APP_ROOT_PATH}/${appId}/workflows/${id}/drafts/save`);
  return result?.data;
};

export const usePersistWorkflow = () => {
  const cache = useQueryClient();

  return useMutation(persistWorkflow, {
    onSuccess: (response, { appId, id }) => {
      cache.setQueryData<UseGetWorkflowResponse>(QUERY_KEYS_WORKFLOWS.detail({ appId: appId, workflowId: id }), (old) =>
        old && old.draft
          ? {
              saved: { ...response, nodeSummaries: old.draft.nodeSummaries },
            }
          : undefined
      );
    },
  });
};
