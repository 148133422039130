import { NumberValue, SaveScheduledJobOperation, ValueTypes } from '@novaera/actioner-service';
import {
  CodeInput,
  NvCustomDateTime,
  NvDateTimePicker,
  NvField,
  NvMenuWithItems,
  NvSettingsIcon,
  NvSideGroupedInputLayout,
  isRequired,
  useField,
  useForm,
} from '@novaera/core';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import { ParameterMappingSettingsMenu } from '../../../../../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu';
import { ScheduledTimeConfigurationProps } from './types';

export const ScheduledTimeConfiguration: React.FC<ScheduledTimeConfigurationProps> = ({ context }) => {
  const { change } = useForm();
  const {
    input: { value: operation },
  } = useField<SaveScheduledJobOperation>('operation');
  return (
    <PropertyPanelSection title={'Scheduled time'} icon={<NvCustomDateTime />}>
      <NvField<NumberValue>
        formControlStyle={{ width: '100%' }}
        name={'operation.time'}
        validators={[isRequired()]}
        showErrorMessageOnlyWhenBlur
        component={({ value, onChange }) => (
          <NvSideGroupedInputLayout
            variant={'default'}
            rightAction={
              <NvMenuWithItems
                triggerButton={{
                  content: <NvSettingsIcon />,
                  props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
                }}
                menuItems={
                  <ParameterMappingSettingsMenu
                    hiddenConfig={{
                      show: false,
                    }}
                    dynamicStaticConfig={{
                      show: true,
                      isScripted: operation.dynamic,
                      onItemClicked: (type) => {
                        const newScriptedValue = type === 'static' ? false : true;

                        if (operation.dynamic !== newScriptedValue) {
                          change('operation', {
                            ...operation,
                            dynamic: newScriptedValue,
                            time: {
                              type: ValueTypes.NUMBER,
                              displayValue: '',
                              ...(!newScriptedValue ? { value: Date.now() } : { codeTemplate: '' }),
                            },
                          });
                        }
                      },
                    }}
                  />
                }
              />
            }
            formItem={
              operation.dynamic ? (
                <CodeInput
                  value={value?.codeTemplate}
                  onChange={(value) => {
                    onChange({
                      type: ValueTypes.NUMBER,
                      codeTemplate: value,
                      displayValue: value,
                    });
                  }}
                  context={context}
                  placeholder={'{{...}}'}
                />
              ) : (
                <NvDateTimePicker
                  value={value?.value ? utcToZonedTime(value?.value, 'UTC') : undefined}
                  onChange={(v) => {
                    const date = v as string | undefined;
                    const dateMillis = date ? zonedTimeToUtc(date, 'UTC').getTime() : undefined;
                    onChange({
                      type: ValueTypes.NUMBER,
                      value: dateMillis,
                      displayValue: dateMillis?.toString() ?? '',
                    });
                  }}
                />
              )
            }
          />
        )}
      />
    </PropertyPanelSection>
  );
};
