export function NvActionIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M74.5545 63.1089C74.3519 63.1089 74.1493 63.0017 74.0421 62.8112C73.8752 62.5253 73.9706 62.1679 74.2565 62.0012L87.4719 54.3657C87.7579 54.199 88.1154 54.2943 88.2822 54.5802C88.449 54.866 88.3537 55.2234 88.0677 55.3902L74.8524 63.0256C74.757 63.0851 74.6498 63.1089 74.5545 63.1089Z"
        fill="#B1B6C0"
      />
      <path
        d="M75.5197 53.687C75.3171 53.687 75.1145 53.5798 75.0073 53.3892C74.8404 53.1033 74.9358 52.746 75.2218 52.5792L81.7758 48.7913C82.0618 48.6245 82.4193 48.7198 82.5861 49.0057C82.753 49.2916 82.6576 49.6489 82.3716 49.8157L75.8176 53.6036C75.7222 53.6632 75.615 53.687 75.5197 53.687Z"
        fill="#455068"
      />
      <path
        d="M44.4059 19.3096L59.6351 9.87551C60.2548 9.51816 61.0651 9.4586 61.7801 9.87551L77.7719 19.1071C78.6299 19.5955 79.0351 20.608 78.761 21.5609L73.3509 40.0836C73.1483 40.7745 73.4582 41.5249 74.0897 41.8704L82.2287 46.2896C83.8493 47.1711 84.4094 49.2318 83.468 50.8161L66.9756 78.3679L50.4594 88.5762L67.4165 60.5956L66.6777 57.3794L57.2518 52.305L57.0969 49.8155L62.0184 31.4L61.7681 29.911L44.4178 19.3215L44.4059 19.3096Z"
        fill="#455068"
      />
      <path
        d="M73.3986 41.0961L56.8347 51.1615L67.4046 58.106L83.7778 48.1955L82.2168 46.2777L73.3986 41.0961Z"
        fill="white"
      />
      <path
        d="M44.4059 19.3096L52.2827 14.4377L60.3382 9.62537L61.8039 9.58963L78.189 19.1905L79.1066 20.4055L61.8396 30.4113L61.2557 29.3154L44.4059 19.3096Z"
        fill="white"
      />
      <path
        d="M45.2639 20.0838L61.2557 29.3154C62.1137 29.8038 62.5189 30.8163 62.2448 31.7692L56.8347 50.292C56.6322 50.9828 56.942 51.7333 57.5736 52.0787L65.7125 56.498C67.3331 57.3794 67.8932 59.4402 66.9518 61.0244L50.4594 88.5762C49.9232 89.4696 48.5409 88.9812 48.6958 87.9449L52.2231 64.1572C52.3184 63.5259 52.0086 62.9065 51.4604 62.5968L43.7147 58.2132C42.7018 57.6415 42.0703 56.5575 42.0703 55.3902V21.9421C42.0703 20.2983 43.8577 19.2619 45.2877 20.0838H45.2639Z"
        fill="white"
      />
      <path
        d="M49.6491 89.6244C49.4704 89.6244 49.2916 89.5887 49.101 89.5291C48.386 89.2671 47.9808 88.6 48.0881 87.8377L51.6153 64.05C51.6749 63.6688 51.4843 63.2876 51.1506 63.097L43.4049 58.7135C42.2013 58.0346 41.4625 56.7481 41.4625 55.3663V21.9421C41.4625 20.9534 41.9749 20.0719 42.8329 19.5716C43.6909 19.0714 44.7157 19.0714 45.5737 19.5716L61.5656 28.8032C62.6619 29.4345 63.1743 30.721 62.8287 31.936L57.4186 50.4587C57.2995 50.8875 57.4901 51.3402 57.8715 51.5546L66.0104 55.9738C66.9399 56.4741 67.6072 57.3199 67.8813 58.3324C68.1554 59.3568 68.0124 60.4169 67.4761 61.3222L50.9838 88.874C50.6978 89.3624 50.1973 89.6363 49.673 89.6363L49.6491 89.6244ZM44.1914 20.3936C43.9292 20.3936 43.6551 20.465 43.4168 20.608C42.9282 20.8819 42.6423 21.3822 42.6423 21.9421V55.3663C42.6423 56.3193 43.1547 57.2008 43.9888 57.6772L51.7345 62.0607C52.4972 62.4896 52.9261 63.3591 52.7951 64.2287L49.2678 88.0163C49.232 88.2784 49.4227 88.3737 49.5061 88.4094C49.5895 88.4333 49.804 88.4809 49.9351 88.2546L66.4275 60.7028C66.7969 60.0834 66.9041 59.3449 66.7135 58.6421C66.5228 57.9393 66.0581 57.3556 65.4265 57.0102L57.2876 52.5909C56.4057 52.1144 55.9768 51.0781 56.2627 50.1133L61.6728 31.5906C61.8754 30.8997 61.5775 30.1731 60.9578 29.8157L44.966 20.5841C44.7276 20.4412 44.4536 20.3697 44.1914 20.3697V20.3936Z"
        fill="#455068"
      />
      <path
        d="M50.7573 89.0646L50.1258 88.0521L66.5228 77.9271L82.9437 50.4944C83.3131 49.8631 83.4203 49.1365 83.2297 48.4337C83.039 47.7309 82.5743 47.1473 81.9427 46.8018L73.8038 42.3826C72.9219 41.9061 72.4929 40.8698 72.767 39.9049L78.1771 21.3822C78.3797 20.6913 78.0818 19.9647 77.4621 19.6074L61.4822 10.3996C60.9936 10.1257 60.4216 10.1257 59.933 10.3996L43.2738 20.7033L42.6423 19.6908L59.3253 9.37522C60.1952 8.87493 61.22 8.87493 62.078 9.37522L78.0698 18.6068C79.1662 19.2381 79.6786 20.5246 79.333 21.7396L73.9229 40.2623C73.8038 40.6792 73.9944 41.1438 74.3757 41.3582L82.5147 45.7774C83.4442 46.2777 84.1115 47.1234 84.3856 48.1359C84.6596 49.1603 84.5166 50.2205 83.9804 51.1258L67.4046 78.7967L50.7693 89.0765L50.7573 89.0646Z"
        fill="#455068"
      />
      <path d="M71.2417 71.7213L58.1217 79.2972L71.2417 86.8611L84.3617 79.2972L71.2417 71.7213Z" fill="white" />
      <path
        d="M71.2417 87.4567C71.1345 87.4567 71.0391 87.4329 70.9438 87.3733L57.8238 79.8094C57.645 79.7022 57.5259 79.5116 57.5259 79.2972C57.5259 79.0828 57.645 78.8922 57.8238 78.785L70.9438 71.221C71.1225 71.1138 71.3609 71.1138 71.5396 71.221L84.6596 78.785C84.8384 78.8922 84.9575 79.0828 84.9575 79.2972C84.9575 79.5116 84.8384 79.7022 84.6596 79.8094L71.5396 87.3733C71.4443 87.421 71.349 87.4567 71.2417 87.4567ZM59.3133 79.2972L71.2417 86.1821L83.1701 79.2972L71.2417 72.4122L59.3133 79.2972Z"
        fill="#455068"
      />
      <path d="M71.2417 86.8611V100.965L58.1217 93.3887V79.2972L71.2417 86.8611Z" fill="white" />
      <path
        d="M71.2417 101.56C71.1345 101.56 71.0391 101.536 70.9438 101.477L57.8238 93.9128C57.645 93.8056 57.5259 93.6151 57.5259 93.4006V79.3091C57.5259 79.0947 57.645 78.9041 57.8238 78.7969C58.0025 78.6897 58.2409 78.6897 58.4196 78.7969L71.5396 86.3608C71.7184 86.468 71.8375 86.6586 71.8375 86.873V100.965C71.8375 101.179 71.7184 101.37 71.5396 101.477C71.4443 101.524 71.349 101.56 71.2417 101.56ZM58.7175 93.0552L70.6459 99.9402V87.2185L58.7175 80.3335V93.0552Z"
        fill="#455068"
      />
      <path d="M84.3617 79.2972V93.3887L71.2417 100.965V86.8611L84.3617 79.2972Z" fill="#455068" />
      <path
        d="M71.2417 101.56C71.1344 101.56 71.0391 101.536 70.9438 101.477C70.765 101.37 70.6459 101.179 70.6459 100.965V86.873C70.6459 86.6586 70.765 86.468 70.9438 86.3608L84.0638 78.7969C84.2425 78.6897 84.4809 78.6897 84.6596 78.7969C84.8384 78.9041 84.9575 79.0947 84.9575 79.3091V93.4006C84.9575 93.6151 84.8384 93.8056 84.6596 93.9128L71.5396 101.477C71.4443 101.524 71.3489 101.56 71.2417 101.56ZM71.8375 87.2185V99.9402L83.7659 93.0552V80.3335L71.8375 87.2185Z"
        fill="#455068"
      />
      <path
        d="M19.5958 73.2222C19.3933 73.2222 19.1907 73.115 19.0834 72.9244C18.9166 72.6385 19.0119 72.2812 19.2979 72.1144L27.7109 67.2544C27.9969 67.0877 28.3544 67.183 28.5213 67.4688C28.6881 67.7547 28.5928 68.1121 28.3068 68.2788L19.8938 73.1388C19.7984 73.1984 19.6912 73.2222 19.5958 73.2222Z"
        fill="#B1B6C0"
      />
      <path
        d="M24.1121 84.6812C23.9096 84.6812 23.707 84.574 23.5997 84.3834C23.4329 84.0976 23.5282 83.7402 23.8142 83.5734L45.5022 71.0542C45.7881 70.8875 46.1456 70.9828 46.3125 71.2686C46.4793 71.5545 46.384 71.9119 46.098 72.0786L24.41 84.6098C24.3147 84.6693 24.2075 84.6931 24.1121 84.6931V84.6812Z"
        fill="#B1B6C0"
      />
      <path
        d="M35.5877 96.5214C35.3851 96.5214 35.1825 96.4142 35.0753 96.2236C34.9084 95.9377 35.0038 95.5803 35.2898 95.4136L43.2738 90.8037C43.5598 90.637 43.9173 90.7323 44.0841 91.0181C44.2509 91.304 44.1556 91.6614 43.8696 91.8281L35.8856 96.438C35.7902 96.4975 35.683 96.5214 35.5877 96.5214Z"
        fill="#B1B6C0"
      />
      <path
        d="M81.0251 72.2096C80.8225 72.2096 80.6199 72.1024 80.5127 71.9118C80.3459 71.6259 80.4412 71.2685 80.7272 71.1018L92.3815 64.3836C92.6675 64.2168 93.025 64.3121 93.1918 64.598C93.3586 64.8839 93.2633 65.2412 92.9773 65.408L81.323 72.1262C81.2277 72.1857 81.1204 72.2096 81.0251 72.2096Z"
        fill="#B1B6C0"
      />
      <path
        d="M87.5911 79.5234C87.3885 79.5234 87.1859 79.4162 87.0787 79.2257C86.9118 78.9398 87.0072 78.5824 87.2932 78.4157L103.106 69.2913C103.392 69.1245 103.75 69.2198 103.917 69.5057C104.083 69.7916 103.988 70.1489 103.702 70.3157L87.889 79.4401C87.7937 79.4996 87.6864 79.5234 87.5911 79.5234Z"
        fill="#B1B6C0"
      />
      <path
        d="M95.1937 87.7424C94.9912 87.7424 94.7886 87.6352 94.6813 87.4446C94.5145 87.1587 94.6098 86.8014 94.8958 86.6346L102.356 82.3345C102.642 82.1677 102.999 82.263 103.166 82.5489C103.333 82.8348 103.237 83.1921 102.951 83.3589L95.4917 87.659C95.3963 87.7186 95.2891 87.7424 95.1937 87.7424Z"
        fill="#B1B6C0"
      />
      <path
        d="M43.1904 110.994C42.9759 110.994 42.7733 110.887 42.6661 110.685C42.5112 110.399 42.6184 110.029 42.9044 109.875L51.9371 104.955C52.2231 104.8 52.5925 104.907 52.7474 105.193C52.9023 105.479 52.795 105.848 52.5091 106.003L43.4764 110.923C43.3811 110.97 43.2857 110.994 43.1904 110.994Z"
        fill="#B1B6C0"
      />
      <path
        d="M43.6909 100.047C43.4883 100.047 43.2857 99.9402 43.1785 99.7496C43.0116 99.4637 43.107 99.1064 43.393 98.9396L51.4246 94.3059C51.7106 94.1392 52.0681 94.2345 52.235 94.5203C52.4018 94.8062 52.3065 95.1636 52.0205 95.3303L43.9888 99.964C43.8935 100.024 43.7862 100.047 43.6909 100.047Z"
        fill="#B1B6C0"
      />
      <path
        d="M46.2529 39.762C46.241 42.4302 45.3472 44.3599 43.9053 45.3843L43.2499 45.7655L39.5558 47.8739C40.0802 47.445 40.5449 46.8852 40.8905 46.1705C41.4029 45.1818 41.677 43.9073 41.7008 42.3945C41.7127 40.6792 41.3671 38.8686 40.7594 37.0699L45.4664 34.9139C45.9907 36.5458 46.2767 38.2015 46.2529 39.762Z"
        fill="#455068"
      />
      <path
        d="M39.5558 48.4694C39.3652 48.4694 39.1745 48.3741 39.0673 48.2074C38.8885 47.9572 38.9362 47.5999 39.1864 47.4093C39.675 47.0162 40.0683 46.504 40.3662 45.9084C40.8547 44.9793 41.105 43.7881 41.1169 42.3826C41.1288 40.7983 40.8309 39.0711 40.2113 37.2486C40.1159 36.9627 40.247 36.6411 40.5211 36.522L45.2281 34.366C45.383 34.2945 45.5617 34.2945 45.7166 34.366C45.8716 34.4374 45.9907 34.5685 46.0384 34.7233C46.5985 36.4624 46.8725 38.1539 46.8606 39.762C46.8487 42.5136 45.9192 44.6934 44.2628 45.8727L39.8538 48.398C39.7584 48.4456 39.6631 48.4813 39.5558 48.4813V48.4694ZM41.4863 37.3915C42.0345 39.1545 42.3085 40.834 42.2966 42.4064C42.2847 43.7881 42.0583 45.0031 41.6293 46.0037L43.6194 44.8721C44.9063 43.9549 45.6452 42.1205 45.6571 39.762C45.669 38.4755 45.4783 37.1295 45.0851 35.7477L41.4863 37.4035V37.3915Z"
        fill="#455068"
      />
      <path
        d="M45.4545 34.9139L40.7475 37.0699C39.2818 32.746 36.2073 28.5888 32.6681 26.5519C31.3693 25.7895 30.1299 25.3965 29.0098 25.3369C28.1518 25.2893 27.3772 25.4203 26.698 25.73L30.69 23.4072L31.0833 23.1809C32.6801 22.359 34.8489 22.5376 37.2202 23.9194C40.9024 26.0516 44.0722 30.4351 45.4545 34.9258V34.9139Z"
        fill="white"
      />
      <path
        d="M40.7594 37.6655C40.676 37.6655 40.6045 37.6536 40.5211 37.6179C40.3662 37.5464 40.247 37.4273 40.1874 37.2605C38.7217 32.9604 35.6592 28.9581 32.3702 27.0641C31.1786 26.3732 30.0346 25.9801 28.974 25.9206C28.2114 25.8729 27.5322 25.992 26.9483 26.266C26.6623 26.397 26.3167 26.2779 26.1737 26.0039C26.0307 25.7181 26.126 25.3726 26.4001 25.2178L30.7853 22.6568C32.6681 21.68 35.0514 21.954 37.5181 23.3953C41.2122 25.5275 44.5488 29.9825 46.0265 34.7352C46.1099 35.0211 45.9788 35.3308 45.7047 35.4499L40.9977 37.606C40.9143 37.6417 40.8309 37.6655 40.7475 37.6655H40.7594ZM29.5103 24.777C30.6185 24.92 31.7863 25.3369 32.978 26.0397C36.398 28.0051 39.4605 31.8883 41.105 36.2599L44.7395 34.5923C43.2857 30.3041 40.2589 26.3494 36.9342 24.4316C34.825 23.2047 32.8469 22.9426 31.3693 23.705L29.5103 24.789V24.777Z"
        fill="#455068"
      />
      <path
        d="M41.7008 42.3945C41.6889 43.9073 41.4029 45.1818 40.8905 46.1705C40.5449 46.8852 40.0802 47.445 39.5559 47.8739C39.4248 47.993 39.2699 48.0883 39.1388 48.1597C37.4705 49.1961 35.1468 49.0889 32.5728 47.5642C27.6394 44.6458 23.6474 37.6298 23.6474 31.8645C23.6474 29.1486 24.5173 27.1832 25.9711 26.1588L26.6265 25.7657C26.6265 25.7657 26.6623 25.73 26.698 25.73C27.3772 25.4203 28.1518 25.2892 29.0098 25.3369C30.1299 25.4084 31.3693 25.8014 32.6681 26.5519C36.2192 28.6007 39.2818 32.7579 40.7475 37.0699C41.3552 38.8686 41.7008 40.6792 41.6889 42.3945H41.7008Z"
        fill="white"
      />
      <path
        d="M36.6721 49.4462C35.3136 49.4462 33.824 48.9936 32.2749 48.0764C27.1866 45.0627 23.0516 37.7965 23.0516 31.8645C23.0516 29.0414 23.9691 26.8378 25.6374 25.6704L26.3286 25.2535C26.3286 25.2535 26.412 25.2059 26.4478 25.194C27.2104 24.8366 28.0922 24.6817 29.0455 24.7413C30.2968 24.8128 31.6195 25.2535 32.978 26.0397C36.5529 28.1004 39.7584 32.2576 41.3314 36.8913C41.9868 38.8448 42.3205 40.703 42.3085 42.4183C42.2966 44.0145 41.9987 45.3724 41.4386 46.4683C41.0812 47.2068 40.5687 47.85 39.9491 48.3503C39.7942 48.4814 39.6035 48.6124 39.4248 48.6958C38.6145 49.1961 37.6969 49.4462 36.684 49.4462H36.6721ZM26.9363 26.2779L26.2809 26.671C24.982 27.5882 24.2432 29.4464 24.2432 31.8645C24.2432 37.4273 28.1161 44.2408 32.8826 47.0519C35.1706 48.398 37.2798 48.6124 38.8289 47.6475C38.9719 47.5761 39.0792 47.5046 39.1626 47.4212C39.6631 47.0162 40.0683 46.504 40.3543 45.9084C40.8428 44.9793 41.0931 43.7881 41.105 42.3826C41.1169 40.7983 40.819 39.0711 40.1993 37.2486C38.7336 32.9485 35.6711 28.9461 32.3821 27.0522C31.1905 26.3613 30.0465 25.9682 28.986 25.9087C28.2233 25.861 27.5441 25.9801 26.9602 26.2541C26.9602 26.2541 26.9602 26.2541 26.9483 26.2541L26.9363 26.2779Z"
        fill="#455068"
      />
      <path
        d="M36.5768 40.858C36.5768 42.4542 36.0405 43.6096 35.1825 44.2171L34.7893 44.4434L32.5848 45.7061C32.8946 45.4559 33.1806 45.1105 33.3832 44.6936C33.693 44.098 33.8598 43.3475 33.8717 42.4422C33.8717 41.4178 33.6692 40.3339 33.3117 39.2618L36.1239 37.9754C36.4338 38.9521 36.6125 39.9408 36.6006 40.8699L36.5768 40.858Z"
        fill="#455068"
      />
      <path
        d="M32.5728 46.3016C32.3822 46.3016 32.1915 46.2064 32.0843 46.0396C31.9055 45.7894 31.9532 45.4321 32.2034 45.2415C32.4656 45.0271 32.6801 44.7531 32.8469 44.4315C33.121 43.9074 33.264 43.2284 33.264 42.4303C33.264 41.5131 33.0972 40.5126 32.7278 39.4524C32.6324 39.1665 32.7635 38.8449 33.0376 38.7258L35.8499 37.4393C36.0048 37.3679 36.1835 37.3679 36.3384 37.4393C36.4933 37.5108 36.6125 37.6418 36.6602 37.7967C37.0058 38.8568 37.1726 39.8931 37.1607 40.8818C37.1607 42.609 36.5648 43.9669 35.5162 44.7174L32.8707 46.2302C32.7754 46.2778 32.6801 46.3136 32.5728 46.3136V46.3016ZM34.0147 39.5715C34.3126 40.5721 34.4556 41.537 34.4437 42.4303C34.4437 43.0259 34.3722 43.5739 34.2411 44.0503L34.8727 43.6811C35.5519 43.1927 35.969 42.1683 35.969 40.8342C35.969 40.179 35.8856 39.4881 35.7069 38.7734L34.0028 39.5596L34.0147 39.5715Z"
        fill="#455068"
      />
      <path
        d="M36.1001 37.9634L33.2878 39.2499C32.406 36.6651 30.5828 34.1874 28.4617 32.9724C27.6871 32.5198 26.9483 32.2816 26.269 32.2458C25.7566 32.222 25.2919 32.2935 24.8867 32.4841L27.27 31.0904L27.5084 30.9474C28.4617 30.4591 29.7486 30.5663 31.1786 31.3882C33.3712 32.6627 35.266 35.2833 36.1001 37.9515V37.9634Z"
        fill="white"
      />
      <path
        d="M33.2997 39.8574C33.2163 39.8574 33.1448 39.8455 33.0614 39.8098C32.9065 39.7383 32.7873 39.6192 32.7278 39.4524C31.8698 36.939 30.0823 34.6043 28.1638 33.4966C27.4845 33.1035 26.8291 32.8771 26.2333 32.8414C25.8162 32.8176 25.4468 32.8771 25.137 33.0201C24.851 33.1511 24.5054 33.0439 24.3624 32.758C24.2194 32.4722 24.3147 32.1267 24.5888 31.9719L27.2104 30.4472C28.4259 29.8278 29.9274 29.9826 31.4765 30.8879C33.7287 32.1982 35.7664 34.9021 36.6602 37.7967C36.7436 38.0826 36.6125 38.3923 36.3384 38.5114L33.5262 39.7979C33.4427 39.8336 33.3593 39.8574 33.2759 39.8574H33.2997ZM27.2462 31.8051C27.7467 31.9361 28.2472 32.1624 28.7715 32.4602C30.7973 33.6276 32.6205 35.8908 33.6334 38.4518L35.3732 37.6537C34.5033 35.2238 32.7754 33.0082 30.8926 31.9242C29.701 31.2333 28.6047 31.0785 27.7943 31.4954L27.2462 31.817V31.8051Z"
        fill="#455068"
      />
      <path
        d="M33.8598 42.4423C33.8598 43.3475 33.6811 44.1099 33.3712 44.6936C33.1687 45.1224 32.8827 45.4559 32.5728 45.7061C32.4894 45.7775 32.406 45.8371 32.3226 45.8728C31.3335 46.4922 29.9393 46.4208 28.4021 45.5155C25.4587 43.7764 23.0754 39.5834 23.0754 36.141C23.0754 34.521 23.5998 33.3417 24.4697 32.7342L24.8629 32.496C24.8629 32.496 24.8867 32.4721 24.8987 32.4721C25.3038 32.2816 25.7686 32.2101 26.281 32.2339C26.9483 32.2696 27.6871 32.5079 28.4736 32.9605C30.5947 34.1874 32.4298 36.6651 33.2997 39.238C33.6692 40.3101 33.8717 41.394 33.8598 42.4184V42.4423Z"
        fill="white"
      />
      <path
        d="M30.8569 46.8853C30.0108 46.8853 29.0694 46.5994 28.1042 46.0277C24.9463 44.1575 22.4796 39.8217 22.4796 36.141C22.4796 34.378 23.0635 32.9963 24.1241 32.2458L24.5531 31.9838C24.5531 31.9838 24.6127 31.948 24.6484 31.9361C25.137 31.7098 25.6851 31.6145 26.3167 31.6502C27.1032 31.6979 27.9254 31.9719 28.7715 32.4602C30.9522 33.7229 32.9065 36.2482 33.8598 39.0712C34.265 40.2624 34.4676 41.394 34.4556 42.4542C34.4556 43.4428 34.2531 44.2886 33.9075 44.9794C33.6811 45.4559 33.3593 45.8609 32.9542 46.1825C32.8588 46.2659 32.7278 46.3612 32.5967 46.4208C32.0843 46.7424 31.5004 46.8972 30.8569 46.8972V46.8853ZM25.1489 33.0201L24.7676 33.2464C24.0764 33.7348 23.6713 34.7711 23.6713 36.141C23.6713 39.3809 25.9354 43.3595 28.7119 45.0033C29.9989 45.7656 31.1667 45.8847 32.0128 45.3606C32.0962 45.313 32.1439 45.2891 32.1796 45.2534C32.4656 45.0271 32.6801 44.7531 32.835 44.4315C33.1091 43.9074 33.2521 43.2284 33.2521 42.4303C33.2521 41.5131 33.0852 40.5126 32.7158 39.4524C31.8579 36.939 30.0704 34.6043 28.1518 33.4966C27.4726 33.1035 26.8172 32.8771 26.2214 32.8414C25.8043 32.8176 25.4349 32.8771 25.1251 33.0201H25.1489Z"
        fill="#455068"
      />
      <path
        d="M76.9497 76.2476C76.9497 77.5936 76.4968 78.5704 75.7699 79.0826L75.4363 79.2732L73.5654 80.3453C73.8275 80.1308 74.0659 79.845 74.2446 79.4876C74.5068 78.9873 74.6498 78.3441 74.6498 77.5817C74.6498 76.7122 74.4829 75.795 74.1731 74.8897L76.5445 73.7938C76.8067 74.6157 76.9497 75.4495 76.9497 76.2476Z"
        fill="#455068"
      />
      <path
        d="M73.5654 80.9408C73.3747 80.9408 73.1841 80.8455 73.0768 80.6788C72.8981 80.4286 72.9457 80.0713 73.196 79.8807C73.4105 79.702 73.5892 79.4876 73.7084 79.2255C73.9348 78.7848 74.054 78.2369 74.054 77.5698C74.054 76.8075 73.911 75.9617 73.613 75.0803C73.5177 74.7944 73.6488 74.4728 73.9229 74.3537L76.2942 73.2578C76.4492 73.1863 76.6279 73.1863 76.7828 73.2578C76.9377 73.3293 77.0569 73.4603 77.1046 73.6151C77.4025 74.5204 77.5455 75.4019 77.5336 76.2476C77.5336 77.7366 77.0211 78.9158 76.1036 79.571L73.8514 80.8575C73.756 80.9051 73.6607 80.9408 73.5535 80.9408H73.5654ZM74.9 75.2113C75.1383 76.0451 75.2575 76.8313 75.2456 77.5817C75.2456 78.0106 75.1979 78.4036 75.1145 78.7729L75.4601 78.5704C76.0082 78.1773 76.3419 77.3435 76.3419 76.2476C76.3419 75.7235 76.2823 75.1875 76.1512 74.6395L74.8881 75.2232L74.9 75.2113Z"
        fill="#455068"
      />
      <path
        d="M76.5445 73.7938L74.1731 74.8897C73.4343 72.7098 71.8851 70.6134 70.0858 69.5771C69.4304 69.1959 68.7988 68.9934 68.2387 68.9577C67.8097 68.9338 67.4165 68.9934 67.0709 69.1602L69.0848 67.9809L69.2874 67.8618C70.0977 67.4449 71.1821 67.5402 72.3856 68.231C74.2446 69.3031 75.8414 71.5187 76.5445 73.7819V73.7938Z"
        fill="white"
      />
      <path
        d="M74.1731 75.4853C74.0897 75.4853 74.0182 75.4734 73.9348 75.4376C73.7799 75.3661 73.6607 75.247 73.6011 75.0803C72.8861 72.9719 71.3847 71.0184 69.7879 70.0893C69.2278 69.7557 68.6915 69.5771 68.203 69.5532C67.8574 69.5294 67.5714 69.5771 67.3212 69.6962C67.0352 69.8272 66.6896 69.72 66.5466 69.4341C66.4036 69.1482 66.4989 68.8028 66.773 68.6479L68.9895 67.3615C70.05 66.8135 71.3489 66.9565 72.6836 67.7307C74.614 68.8504 76.3538 71.1613 77.1046 73.627C77.188 73.9129 77.0569 74.2226 76.7828 74.3417L74.4114 75.4376C74.328 75.4734 74.2446 75.4972 74.1612 75.4972L74.1731 75.4853ZM69.2874 68.5646C69.6449 68.6837 70.0143 68.8504 70.3956 69.0649C72.0996 70.0416 73.6369 71.9356 74.5187 74.0797L75.8176 73.4841C75.0788 71.4829 73.6488 69.6604 72.0996 68.7551C71.1225 68.1834 70.2288 68.0643 69.5734 68.3978L69.2993 68.5646H69.2874Z"
        fill="#455068"
      />
      <path
        d="M74.6498 77.5817C74.6498 78.3441 74.4949 78.9873 74.2446 79.4876C74.0659 79.845 73.8395 80.1308 73.5654 80.3452C73.4939 80.4048 73.4224 80.4525 73.3628 80.4882C72.5167 81.0123 71.3489 80.9527 70.05 80.1904C67.5595 78.7133 65.5456 75.1756 65.5456 72.2572C65.5456 70.8873 65.9865 69.8868 66.7253 69.3746L67.059 69.1721C67.059 69.1721 67.0709 69.1601 67.0947 69.1601C67.4403 69.0053 67.8336 68.9338 68.2626 68.9576C68.8345 68.9934 69.4542 69.1959 70.1096 69.5771C71.8971 70.6134 73.4462 72.7098 74.1969 74.8897C74.5068 75.795 74.6855 76.7122 74.6736 77.5817H74.6498Z"
        fill="white"
      />
      <path
        d="M72.1235 81.4292C71.3847 81.4292 70.5624 81.191 69.7402 80.7026C67.0471 79.1064 64.9379 75.4019 64.9379 72.2572C64.9379 70.7325 65.4384 69.5413 66.3678 68.8862L66.7373 68.6599C66.7373 68.6599 66.7968 68.6241 66.8207 68.6122C67.2497 68.4097 67.7501 68.3263 68.2745 68.3621C68.9537 68.3978 69.6687 68.636 70.3956 69.0529C72.2546 70.125 73.9229 72.2929 74.7451 74.6872C75.0907 75.6997 75.2575 76.6764 75.2456 77.5698C75.2456 78.4155 75.0788 79.1541 74.7689 79.7497C74.5664 80.1547 74.2923 80.512 73.9348 80.7979C73.8514 80.8694 73.7322 80.9527 73.625 81.0123C73.184 81.2863 72.6716 81.4173 72.1235 81.4173V81.4292ZM67.3331 69.6962L67.0232 69.8868C66.4632 70.2798 66.1414 71.1375 66.1414 72.2572C66.1414 74.9731 68.0361 78.2964 70.3598 79.6782C71.4204 80.2976 72.3737 80.4048 73.053 79.9879L73.196 79.8926C73.4105 79.7139 73.5892 79.4995 73.7084 79.2375C73.9348 78.7967 74.0539 78.2488 74.0539 77.5817C74.0539 76.8194 73.911 75.9736 73.613 75.0922C72.8981 72.9838 71.3966 71.0303 69.7998 70.1012C69.2397 69.7676 68.7035 69.589 68.2149 69.5651C67.8812 69.5532 67.5833 69.589 67.3331 69.7081V69.6962Z"
        fill="#455068"
      />
      <path d="M58.5745 88.4451L51.7345 92.3879L58.5745 96.3307L65.4027 92.3879L58.5745 88.4451Z" fill="white" />
      <path
        d="M58.5745 96.9263C58.4673 96.9263 58.372 96.9025 58.2766 96.8429L51.4366 92.9001C51.2578 92.7929 51.1387 92.6023 51.1387 92.3879C51.1387 92.1735 51.2578 91.9829 51.4366 91.8757L58.2766 87.9329C58.4554 87.8257 58.6937 87.8257 58.8724 87.9329L65.7125 91.8757C65.8912 91.9829 66.0104 92.1735 66.0104 92.3879C66.0104 92.6023 65.8912 92.7929 65.7125 92.9001L58.8724 96.8429C58.7771 96.8906 58.6818 96.9263 58.5745 96.9263ZM52.9261 92.3879L58.5745 95.6398L64.2229 92.3879L58.5745 89.136L52.9261 92.3879Z"
        fill="#455068"
      />
      <path d="M58.5745 96.3307V103.68L51.7345 99.7375V92.3879L58.5745 96.3307Z" fill="white" />
      <path
        d="M58.5745 104.276C58.4673 104.276 58.372 104.252 58.2766 104.192C53.518 101.445 51.1387 99.9598 51.1387 99.7375V92.3879C51.1387 92.1735 51.2578 91.9829 51.4366 91.8757C51.6153 91.7685 51.8537 91.7685 52.0324 91.8757L58.8724 95.8185C59.0512 95.9257 59.1704 96.1163 59.1704 96.3307V103.68C59.1704 103.895 59.0512 104.085 58.8724 104.192C58.7771 104.24 58.6818 104.276 58.5745 104.276ZM52.3303 99.392C53.3075 99.9519 56.4177 101.751 57.9787 102.644V96.6761L52.3303 93.4242V99.392Z"
        fill="#455068"
      />
      <path d="M65.4027 92.3879V99.7375L58.5745 103.68V96.3307L65.4027 92.3879Z" fill="#455068" />
      <path
        d="M58.5745 104.276C58.4673 104.276 58.372 104.252 58.2766 104.192C58.0979 104.085 57.9787 103.895 57.9787 103.68V96.3307C57.9787 96.1163 58.0979 95.9257 58.2766 95.8185L65.1167 91.8757C65.2954 91.7685 65.5337 91.7685 65.7125 91.8757C65.8912 91.9829 66.0104 92.1735 66.0104 92.3879V99.7375C66.0104 99.9519 65.8912 100.142 65.7125 100.25L58.8724 104.192C58.7771 104.24 58.6818 104.276 58.5745 104.276ZM59.1704 96.6761V102.644L64.8188 99.392V93.4242L59.1704 96.6761Z"
        fill="#455068"
      />
    </svg>
  );
}
