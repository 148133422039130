import { NodeExecutionStatus, UserWorkflowExecutionResultResponse } from '@novaera/actioner-service';
import { useMemo, useState } from 'react';

export const useWorkflowOutputResult = () => {
  const [workflowStateResult, setWorkflowStateResult] = useState<UserWorkflowExecutionResultResponse | null>(null);

  const executedNodeAliases = useMemo(() => {
    if (workflowStateResult?.nodeStates) {
      return Object.entries(workflowStateResult.nodeStates)
        .filter(
          ([, state]) =>
            state.status !== NodeExecutionStatus.DISCARDED && state.status !== NodeExecutionStatus.NOT_EXECUTED
        )
        .map(([alias]) => alias);
    }

    return;
  }, [workflowStateResult?.nodeStates]);

  return {
    setWorkflowStateResult,
    executedNodeAliases,
  };
};
