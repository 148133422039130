import { InputParameter, ParameterTypes, UIComponentType, ValueTypes } from '@novaera/actioner-service';
import { NvFlex, NvSkeleton } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useMemo } from 'react';
import {
  ActionParameterMapper,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
} from '../../../../../../../../../components';
import { SearchEntitiesFilterParameterMappingProps } from './types';

export const SearchEntitiesFilterParameterMapping = ({
  actionId,
  integrationId,
  parameterMappings,
  inputParameters,
  version,
  onParameterMappingChanged,
  context = {},
}: SearchEntitiesFilterParameterMappingProps) => {
  const { userAppId, workflowId } = useParams();

  const selectedEntityTypeValue = useMemo(() => {
    const entityTypeParameterId = inputParameters?.find((i) => i.name === 'entityType')?.id;
    const selectedEntityType = parameterMappings.find((p) => p.parameterId === entityTypeParameterId);
    return selectedEntityType?.type === ParameterTypes.SIMPLE && selectedEntityType.value?.type === ValueTypes.STRING
      ? selectedEntityType.value?.value
      : undefined;
  }, [parameterMappings, inputParameters]);

  return (
    <>
      <PropertyPanelListHeader title="Action inputs" />
      <PropertyPanelSimpleSection>
        <NvFlex gap="16px" width="100%">
          <ActionParameterMapper
            type="in-workflow"
            appId={userAppId}
            workflowId={workflowId}
            actionId={actionId}
            integrationId={integrationId}
            versionNumber={version}
            onParameterMappingsChanged={onParameterMappingChanged}
            context={context}
            initialParameterMappings={parameterMappings}
            filterInputParameters={(input: InputParameter) =>
              input.uiComponent.type !== UIComponentType.CATALOG_RELATIONSHIP_FILTER
            }
          />
        </NvFlex>
      </PropertyPanelSimpleSection>
      <ActionParameterMapper
        type="in-workflow"
        appId={userAppId}
        workflowId={workflowId}
        actionId={actionId}
        integrationId={integrationId}
        versionNumber={version}
        onParameterMappingsChanged={onParameterMappingChanged}
        context={context}
        initialParameterMappings={parameterMappings}
        filterInputParameters={(input: InputParameter) =>
          input.uiComponent.type === UIComponentType.CATALOG_RELATIONSHIP_FILTER
        }
        otherProps={{ firstEntityTypeId: selectedEntityTypeValue }}
        customLoadingState={
          <>
            <PropertyPanelListHeader title="Relationships filter" />
            <PropertyPanelSimpleSection>
              <NvFlex gap="16px">
                <NvSkeleton variant="rectangular" height="32px" />
                <NvSkeleton variant="rectangular" height="32px" />
                <NvSkeleton variant="rectangular" height="32px" />
              </NvFlex>
            </PropertyPanelSimpleSection>
          </>
        }
      />
    </>
  );
};
