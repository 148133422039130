import { NvAxios, useMutation, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { BILLING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_BILLING_PREFERENCES } from '../keys';
import { BillingPreferences } from '../use-get-billing-preferences/types';
import { UpdateBillingPreferencesNotificationRulesParams } from './types';

const updateBillingPreferencesNotificationRules = async ({
  notificationRules,
}: UpdateBillingPreferencesNotificationRulesParams) => {
  const result = await NvAxios.patch<BillingPreferences>(`${BILLING_ROOT_PATH}/preferences/notification-rules`, {
    rules: notificationRules,
  });
  return result?.data;
};

export const useUpdateBillingPreferencesNotificationRules = () => {
  const cache = useQueryClient();
  const { addToast } = useToast();

  return useMutation(updateBillingPreferencesNotificationRules, {
    onSuccess: (response) => {
      addToast('Billing preferences updated successfully!', { variant: 'success' });
      cache.setQueryData<BillingPreferences>(QUERY_KEYS_BILLING_PREFERENCES.all, response);
    },
  });
};
