import { useDeleteAppDependency, useGetAppDependencies } from '@novaera/actioner-service';
import {
  MenuItems,
  NvDeleteOutlineIcon,
  NvEditIcon,
  useConfirmDialog,
  useDebounce,
  useNvDialogModalState,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { useCallback, useMemo, useState } from 'react';
import { useGetWorkflowQueryParams } from '../../../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { DependencyForm } from '../../../types';

export const useDependencyItems = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedDependency, setSelectedDependency] = useState<DependencyForm | undefined>();

  const [debouncedSearchValue] = useDebounce(searchKeyword, 500);

  const { userAppId } = useGetWorkflowQueryParams();

  const { openConfirm } = useConfirmDialog();

  const { mutate: deleteAppDependency, isLoading: isAppDependencyDeleteLoading } = useDeleteAppDependency();

  const { data: appDependencies, isLoading: isLoadingAppDependencies } = useGetAppDependencies({
    appId: userAppId,
  });

  const { isOpened: isEditDependencyModalOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const dependencyItems = useMemo(
    () =>
      appDependencies?.dependencies
        .filter(({ dependentApp }) =>
          debouncedSearchValue ? dependentApp?.name.toLowerCase().startsWith(debouncedSearchValue) : true
        )
        .map(({ dependency, dependentApp }) => {
          return {
            appName: dependentApp?.name,
            dependencyId: dependency.id,
            logoUrl: dependentApp?.logoUrl,
            dependencyName: dependency.name,
            appId: dependentApp?.id,
            schemaVersion: dependentApp.metadata?.schemaVersion,
          };
        }) ?? [],
    [appDependencies?.dependencies, debouncedSearchValue]
  );

  const handleSearchKeywordChange = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const getMenuItems = useCallback(
    (dependencyId: string) => {
      const menuItems: MenuItems[] = [
        {
          name: 'Edit',
          onClick: () => {
            const item = appDependencies?.dependencies.find(({ dependency }) => dependency.id === dependencyId);

            assert(!!item, new Error('Dependency not found'));

            setSelectedDependency(item.dependency);

            onModalOpenClicked();
          },
          icon: <NvEditIcon sx={{ width: '16px', height: '16px' }} />,
        },
        {
          name: 'divider',
          isDivider: true,
          dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
        },
        {
          name: 'Delete',
          isLoading: isAppDependencyDeleteLoading,
          onClick: () => {
            openConfirm({
              message:
                'You are about to delete this dependency. Deleting the dependency will affect all workflows depending on it. Are you sure? ',
              onConfirm: () => {
                deleteAppDependency({ appId: userAppId, dependencyId: dependencyId });
              },
            });
          },
          icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
        },
      ];
      return menuItems;
    },
    [
      isAppDependencyDeleteLoading,
      appDependencies?.dependencies,
      onModalOpenClicked,
      openConfirm,
      deleteAppDependency,
      userAppId,
    ]
  );

  return {
    isLoadingAppDependencies,
    hasNoDependency: dependencyItems?.length === 0,
    onSearchKeywordChanged: handleSearchKeywordChange,
    dependencyItems,
    hasSearchKeyword: debouncedSearchValue?.length > 0,
    getMenuItems,
    isEditDependencyModalOpened,
    onModalCloseClicked,
    selectedDependency,
  };
};
