import { SchemaType } from '@novaera/ah-common';
import {
  NestedDropdownItem,
  NestedDropdownSelect,
  NvDNSIcon,
  NvFlex,
  NvHelpIcon,
  NvListAltIcon,
  NvSelect,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { flatten, map } from 'lodash';
import { FC, useMemo } from 'react';
import { FIELD_TYPE_SCHEMAS, PRIMITIVE_MENU_ITEMS } from '../../../constants';
import { Line } from './styled';
import { ValueSchemaSelectProps } from './types';

const menuItemsOfMapType: NestedDropdownItem[] = [
  { id: 'map', name: 'Map', leftIcon: <NvListAltIcon /> },
  { id: 'object', name: 'Object', leftIcon: <NvHelpIcon /> },
];

export const ValueSchemaSelect: FC<React.PropsWithChildren<ValueSchemaSelectProps>> = ({
  value,
  recordModels,
  onChange,
  parentSchemaType,
  modelId,
}) => {
  const handleSelectType = (selectedMenu: NestedDropdownItem) => {
    const selectedMenuId = selectedMenu.id;
    assert(
      selectedMenuId in FIELD_TYPE_SCHEMAS,
      new Error(`[ValueSchemaSelect] - Type selected menu id should be SchemaType but it is ${typeof selectedMenuId}`),
      'ERROR'
    );
    const selectedFieldSchema = FIELD_TYPE_SCHEMAS[selectedMenuId as keyof typeof FIELD_TYPE_SCHEMAS] ?? {
      type: selectedMenu.id,
    };
    const initModel = recordModels?.find((r) => r.value === modelId);
    if (selectedFieldSchema.type === SchemaType.recordReference && initModel) {
      selectedFieldSchema.modelId = initModel.value;
      selectedFieldSchema.modelName = initModel.label;
    }
    onChange?.(selectedFieldSchema);
  };

  const nestedDropdownItems = useMemo(() => {
    if (parentSchemaType === SchemaType.map) {
      return [...PRIMITIVE_MENU_ITEMS, ...menuItemsOfMapType];
    } else {
      return PRIMITIVE_MENU_ITEMS;
    }
  }, [parentSchemaType]);

  const selectedMenu = useMemo(
    () => flatten(map(nestedDropdownItems, (m) => (m.items ? m.items : m))).find((m) => m.id === value?.type),
    [nestedDropdownItems, value?.type]
  );

  return (
    <NvFlex direction="row" gap="12px" alignItems="center">
      <NestedDropdownSelect options={nestedDropdownItems} value={selectedMenu} onChange={handleSelectType} />
      {value?.type === SchemaType.recordReference && (
        <>
          <Line />
          <NvSelect
            fullWidth
            startIcon={<NvDNSIcon />}
            value={value.modelId}
            options={recordModels}
            onChange={(e) => {
              const selectedModel = recordModels?.find((r) => r.value === e.target.value);
              selectedModel && onChange?.({ ...value, modelId: selectedModel.value, modelName: selectedModel.label });
            }}
          />
        </>
      )}
    </NvFlex>
  );
};
