import { GetImpersonationLinkParams, useGetImpersonationLink, useListUsers } from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { useCopyToClipboard } from '@novaera/utils';
import { useState } from 'react';

export const useUserPanel = () => {
  const { addToast } = useToast();

  const [searchValue, setSearchValue] = useState<string>();
  const { data: usersResult, isInitialLoading: isGettingUsers } = useListUsers({ subdomain: searchValue });
  const { mutateAsync: getImpersonateForUser } = useGetImpersonationLink();

  const [, copyToClipboard] = useCopyToClipboard();

  const [impersonateLinks, setImpersonateLinks] = useState<Record<string, string>>();

  const impersonateUser = async (params: GetImpersonationLinkParams) => {
    try {
      const { link } = await getImpersonateForUser(params);
      copyToClipboard(link);
      addToast('Impersonate link is copied to your clipboard', { variant: 'success' });
      setImpersonateLinks({
        [params.userId]: link,
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  return {
    users: usersResult?.users,
    isGettingUsers,
    impersonateUser,
    setSearchValue,
    searchValue,
    impersonateLinks,
  };
};
