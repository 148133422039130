import {
  NovaeraNodeWithPosition,
  NvGraph,
  transformToReactFlowEdges,
  transformToReactFlowNode,
  transformToReactFlowNodes,
} from '@novaera/core';
import { useCallback } from 'react';
import { useReactFlow, useUpdateNodeInternals } from 'reactflow';
import { AppWorkflowHelperNodeType } from '../../components/user-app-workflow-node-types/types';

export const useRefreshCanvas = <NodeType extends string>(graph: NvGraph<NodeType>) => {
  const { setNodes, setEdges } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const refreshCanvas = useCallback(
    ({ keepSelected = true }: { keepSelected?: boolean }) => {
      graph.refreshLayout();

      const newNodes = transformToReactFlowNodes({ nodes: graph.nodes(), keepSelected });
      const newEdges = transformToReactFlowEdges(graph.edges());

      setNodes(newNodes);
      setEdges(newEdges);
      newNodes.forEach((n) => {
        updateNodeInternals(n.id);
      });
    },
    [graph, setEdges, setNodes, updateNodeInternals]
  );

  const refreshEdgesInReactFlow = useCallback(() => {
    const newEdges = transformToReactFlowEdges(graph.edges());
    setEdges(newEdges);
  }, [graph, setEdges]);

  const updateNodeInReactFlow = useCallback(
    ({ newNode }: { newNode: NovaeraNodeWithPosition<NodeType | AppWorkflowHelperNodeType> }) => {
      const newReactFlowNode = transformToReactFlowNode({ node: newNode });
      setNodes((prevNodes) => {
        return prevNodes.map((n) => (n.id === newReactFlowNode.id ? newReactFlowNode : n));
      });
      updateNodeInternals(newReactFlowNode.id);
    },
    [setNodes, updateNodeInternals]
  );

  return { refreshCanvas, updateNodeInReactFlow, refreshEdgesInReactFlow };
};
