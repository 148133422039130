import { NvConditionalRender, NvErrorIcon, NvTooltip } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classnames from 'classnames';
import { Circle } from './styled';
import { ErrorMarkerType } from './types';

export const ErrorMarker: React.FC<React.PropsWithChildren<ErrorMarkerType>> = ({
  variant,
  defaultColor,
  className,
}) => {
  const { palette } = useTheme();

  return (
    <>
      <NvConditionalRender when={() => variant === 'default'}>
        <NvTooltip title="Error" placement={'top'}>
          <NvErrorIcon
            className={classnames(className)}
            htmlColor={defaultColor ?? palette.nv_error[40]}
            sx={{ width: '16px', height: '16px', zIndex: 2 }}
          />
        </NvTooltip>
      </NvConditionalRender>
      <NvConditionalRender when={() => variant !== 'default'}>
        <NvTooltip title="Error" placement={'top'}>
          <Circle className={classnames(className)}>
            <NvErrorIcon htmlColor={defaultColor ?? palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
          </Circle>
        </NvTooltip>
      </NvConditionalRender>
    </>
  );
};
