import { FileNodeExtraData, FileNodeSummary, FileVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class FilesComponentFactory implements ComponentFactory<FileNodeExtraData> {
  alias: string;
  name: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('file');
    this.alias = newAlias;
    this.name = newName;
  }

  get componentForGraph() {
    const outputRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.name,
      width: 40,
      height: 40,
      id: this.alias,
      type: 'file',
      alias: this.alias,
    };

    return { root: outputRoot };
  }

  componentForWorkflow(serverData: FileNodeExtraData) {
    const newNodeUnion: FileVertex = {
      type: 'file',
      alias: this.alias,
    };

    const namePrefix =
      serverData.nodeOperation.type === 'put'
        ? 'Put '
        : serverData.nodeOperation.type === 'delete'
        ? 'Delete '
        : serverData.nodeOperation.type === 'transfer'
        ? 'Transfer '
        : serverData.nodeOperation.type === 'change-accessibility'
        ? 'Change accessibility '
        : serverData.nodeOperation.type === 'list'
        ? 'List '
        : '';

    this.name = `${namePrefix}${this.name.toLowerCase()}`;
    const summary: FileNodeSummary = {
      type: 'file',
      alias: this.alias,
      name: this.name,
      ...serverData,
    };

    return { root: newNodeUnion, nodeSummaries: { [this.alias]: summary } };
  }
}
