export const BAR_CHART_COLOR = '#0F8BFD';
export const CHART_TEXT_COLOR = '#17386A';
export const PIE_CHART_COLORS = [
  '#87C6FC',
  '#89F0C2',
  '#9BE8FC',
  '#F8D669',
  '#EF8BB7',
  '#D661F7',
  '#F1A260',
  '#54ACFD',
  '#70EBAB',
  '#5FCFFA',
  '#F6C545',
  '#F6C545',
  '#F6C545',
  '#F6C545',
  '#0275DE',
  '#55B78B',
  '#51B5DD',
  '#D9AC3A',
  '#CE2C5F',
  '#AB2CC6',
  '#C06B27',
];
export const MODE_BAR_BACKGROUND_COLOR = '#FFFFFF';
export const MODE_BAR_ICON_COLOR = '#6C81A1';
export const MODE_BAR_ICON_ACTIVE_COLOR = '#17386A';
export const CHART_FONT_FAMILY =
  'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
export const CHART_TOOLTIP_BORDER_COLOR = '#FFFFFF';
export const CHART_BACKGROUND_COLOR = '#FFFFFF';

// Source: https://www.w3schools.com/jsref/obj_window.asp
export const NOT_ALLOWED_WINDOW_OBJECT_PROPERTIES = [
  'closed',
  'console',
  'defaultStatus',
  'document',
  'frameElement',
  'frames',
  'history',
  'innerHeight',
  'innerWidth',
  'length',
  'localStorage',
  'location',
  'name',
  'navigator',
  'opener',
  'outerHeight',
  'outerWidth',
  'pageXOffset',
  'pageYOffset',
  'parent',
  'screen',
  'screenLeft',
  'screenTop',
  'screenX',
  'screenY',
  'sessionStorage',
  'scrollX',
  'scrollY',
  'self',
  'status',
  'top',
];

export const NOT_ALLOWED_WINDOW_OBJECT_METHODS = [
  'alert',
  'blur',
  'close',
  'confirm',
  'focus',
  'getComputedStyle',
  'getSelection',
  'matchMedia',
  'moveBy',
  'moveTo',
  'open',
  'print',
  'prompt',
  'requestAnimationFrame',
  'resizeBy',
  'resizeTo',
  'scroll',
  'scrollBy',
  'scrollTo',
  'stop',

  // These are overridden by third party libraries.
  // 'atob',
  // 'btoa',

  // These are consistent with Node.js.
  // 'clearInterval',
  // 'clearTimeout',
  // 'setInterval',
  // 'setTimeout',
];

export const TIMEOUT_TEMPLATE_TEST = 100;
export const TIMEOUT_TEMPLATE = parseInt(process.env.TIMEOUT_TEMPLATE || '') || TIMEOUT_TEMPLATE_TEST;
export const TIMEOUT_CONVERSION_TEST = 100;
export const TIMEOUT_CONVERSION = parseInt(process.env.TIMEOUT_CONVERSION || '') || TIMEOUT_CONVERSION_TEST;
export const TIMEOUT_JS_EVALUATION_TEST = 100;
export const TIMEOUT_JS_EVALUATION = parseInt(process.env.TIMEOUT_JS_EVALUATION || '') || TIMEOUT_JS_EVALUATION_TEST;
export const TIMEOUT_JS_EVALUATION_LAMBDA =
  parseInt(process.env.TIMEOUT_JS_EVALUATION_LAMBDA || '') || TIMEOUT_JS_EVALUATION_TEST;
export const TIMEOUT_TEMPLATE_IN_RULE_EVALUATIONS_TEST = 100;
export const TIMEOUT_TEMPLATE_IN_RULE_EVALUATIONS =
  parseInt(process.env.TIMEOUT_TEMPLATE_IN_RULE_EVALUATIONS || '') || TIMEOUT_TEMPLATE_IN_RULE_EVALUATIONS_TEST;

export const ERROR_CODE_TEMPLATE = '1';
export const ERROR_CODE_JS_EVALUATION = '2';
export const ERROR_CODE_SCRIPT_TIMEOUT = '3';
export const ERROR_CODE_TEMPLATE_IN_RULE_EVALUATIONS = '4';
export const ERROR_CODE_INVALID_DATA_SOURCE = '6';
export const ERROR_CODE_MISSING_ARGUMENTS = '7';
export const ERROR_CODE_UNKNOWN = '8';

export const CONDITION_TYPE_ERROR = 'Condition type error';
export const OPERATION_TYPE_ERROR = 'Operation type error';

export const ERROR_CODE_UNKNOWN_CONDITION = '9';
export const ERROR_CODE_TEMPLATES_ARRAY_SIZE_LIMIT = '10';
export const ERROR_CODE_TEMPLATES_ARRAY_TYPE = '11';

export const TableName = 'package-data-storage-v1';
