import { SideMenu } from '../../../../../components/side-menu';
import { SideMenuTitleFrame } from '../../../../../components/side-menu/styled';
import { RecordsMenuHeader } from './records-menu-header';
import { RecordsMenuList } from './records-menu-list';

export const RecordsMenu: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SideMenu>
    <SideMenuTitleFrame hasNoBorderBottom>{<RecordsMenuHeader />}</SideMenuTitleFrame>
    <RecordsMenuList />
  </SideMenu>
);
