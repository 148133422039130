import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from 'react';
import { NvCustomCheckIcon, NvCustomIndeterminateIcon } from '../icons';
import { CheckboxStyled } from './styled';

export type NvCheckboxProps = CheckboxProps;

export const NvCheckbox = React.forwardRef<HTMLDivElement, NvCheckboxProps>((nvCheckboxProps, ref) => {
  return (
    <CheckboxStyled ref={ref}>
      <Checkbox
        disableRipple
        {...nvCheckboxProps}
        checkedIcon={<NvCustomCheckIcon />}
        indeterminateIcon={<NvCustomIndeterminateIcon />}
        icon={<></>}
      />
    </CheckboxStyled>
  );
});
