import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const HeaderWrapper = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 24px 0 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[40]};
  position: sticky;
  top: -24px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  z-index: 10;
`;
