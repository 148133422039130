import { isRequired, NvField, NvFlex, NvSelect, NvTypography } from '@novaera/core';
import { PropertyPanelSection } from '../../../property-panel';
import { SourceTypes } from '../source-types';
import { DATA_SOURCE_TYPE_OPTIONS } from './constants';
import { DataSourceTypeSectionProps } from './types';

export const DataSourceTypeSection: React.FC<React.PropsWithChildren<DataSourceTypeSectionProps>> = ({ schema }) => (
  <PropertyPanelSection
    title="Source type"
    tooltip="The identifier value of this field must match the selected source type."
  >
    <NvFlex gap="8px">
      <NvTypography variant="body2" textColor="secondary">
        Select the source type below. You can either provide static values or dynamically pass values as the input
        source.
      </NvTypography>
      <NvFlex direction="row" gap="8px">
        <NvFlex width="100%" maxWidth="200px">
          <SourceTypes schema={schema} />
        </NvFlex>
        <NvFlex width="100%" maxWidth="200px">
          <NvField
            name={'uiComponent.dataSource.type'}
            isRequired
            hasRequiredIndicator
            validators={[isRequired()]}
            component={<NvSelect options={DATA_SOURCE_TYPE_OPTIONS} size="small" />}
          />
        </NvFlex>
      </NvFlex>
    </NvFlex>
  </PropertyPanelSection>
);
