import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvPackageIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2562 12.9187L3.90413 9.18802C2.69682 8.48103 2.69682 6.72988 3.91501 6.02289L10.354 2.31391C11.5287 1.62868 12.9645 1.62868 14.1392 2.31391L20.5782 6.02289C21.8073 6.72988 21.8073 8.49191 20.5782 9.1989L14.0521 12.9405C12.8775 13.6149 11.4308 13.604 10.2562 12.9187ZM13.9216 3.92367C13.9216 3.39071 13.1711 2.96652 12.2575 2.96652C11.333 2.96652 10.5933 3.39071 10.5933 3.92367C10.5933 4.45663 11.3438 4.88083 12.2575 4.88083C13.182 4.88083 13.9216 4.45663 13.9216 3.92367ZM20.5781 11.1676L14.0521 14.9092C12.8774 15.5835 11.4308 15.5727 10.2561 14.8874L3.90407 11.1458C3.80618 11.0914 3.71916 11.0153 3.64302 10.9392C2.72938 11.7332 2.80551 13.245 3.90407 13.8868L10.2561 17.6175C11.4308 18.3027 12.8774 18.3136 14.0521 17.6392L20.5781 13.8976C21.6984 13.2559 21.7854 11.7332 20.8609 10.9392C20.7739 11.0153 20.6869 11.0914 20.5781 11.1567V11.1676ZM20.5781 15.8664L14.0521 19.608C12.8774 20.2823 11.4308 20.2714 10.2561 19.5862L3.90407 15.8446C3.80618 15.7902 3.71916 15.7141 3.64302 15.638C2.72938 16.432 2.80551 17.9438 3.90407 18.5856L10.2561 22.3163C11.4308 23.0015 12.8774 23.0124 14.0521 22.338L20.5781 18.5964C21.6984 17.9547 21.7854 16.432 20.8609 15.638C20.7739 15.7141 20.6869 15.7902 20.5781 15.8555V15.8664Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
