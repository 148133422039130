import { assert } from '@novaera/utils';
import React, { useCallback, useMemo } from 'react';
import { useWorkflowDragAndDropProvider } from '../../../providers/workflow-drag-and-drop-provider';
import { MoveNodeDataTransfer } from '../utils';

export const DroppableItem = ({
  children,
  id,
}: {
  children: ({ isDragging, isDroppable }: { isDragging: boolean; isDroppable: boolean }) => React.ReactElement;
  id?: string | { sourceId: string; targetId: string };
}) => {
  const { onDropFinished, isDragging, draggingItemProperties } = useWorkflowDragAndDropProvider();

  const isDroppable = useMemo(() => {
    if (!draggingItemProperties) {
      return false;
    }

    const isInBannedDropItems = draggingItemProperties?.bannedDropItems?.some((bannedItem) => {
      if (typeof id === 'string') {
        return bannedItem.sourceId === id || bannedItem.targetId === id;
      }

      return bannedItem.sourceId === id?.sourceId && bannedItem.targetId === id?.targetId;
    });

    return !isInBannedDropItems;
  }, [draggingItemProperties, id]);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      if (!isDroppable) {
        return;
      }

      assert(id !== undefined, new Error('id should be sent'), 'ERROR');

      const data = MoveNodeDataTransfer.getData(e);
      onDropFinished(JSON.parse(data), typeof id === 'string' ? id : { sourceId: id.sourceId, targetId: id.targetId });
    },
    [id, isDroppable, onDropFinished]
  );

  const handleDragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
  }, []);

  return React.cloneElement(typeof children === 'function' ? children({ isDragging, isDroppable }) : children, {
    onDrop: handleDrop,
    onDragOver: handleDragOver,
  });
};
