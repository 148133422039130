import { useState } from 'react';

export const useNvDialogModalState = <FormValue>(props?: {
  onModalOpened?: () => void;
  onModalClosed?: () => void;
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [passedParameters, setPassedParameters] = useState<FormValue | undefined>();

  /**
   *
   * @param args any parameter which will returned as initialValue of the hook
   */
  const handleModalOpenClicked = (args?: FormValue) => {
    setIsOpened(true);
    props?.onModalOpened?.();
    setPassedParameters(args);
  };

  const handleModalCloseClicked = () => {
    setIsOpened(false);
    setPassedParameters(undefined);
    props?.onModalClosed?.();
  };
  return {
    isOpened,
    onModalOpenClicked: handleModalOpenClicked,
    onModalCloseClicked: handleModalCloseClicked,
    passedParameters,
  };
};
