import {
  ActionNode,
  IntegrationType,
  UIComponentType,
  useGetIntegration,
  useGetIntegrationActions,
  useUpdateNode,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormIdentifierContext } from '../../../../../../../../../providers/form-identifier-provider';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../../provider';

export const useActionNodeProperties = ({ actionNode }: { actionNode: ActionNode }) => {
  const { userAppId, workflowId } = useParams();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { mutate: updateActionNode } = useUpdateNode();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { formId } = useFormIdentifierContext();
  const { hasEditPermission } = useWorkflowPermission();

  assert(
    !!actionNode,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  assert(
    actionNode.type === 'action',
    new Error('Since the selected node in the graph is having type action it should be action in the workflow object'),
    'ERROR'
  );

  const { data: integration } = useGetIntegration({ id: actionNode.integrationId });
  const { data: actions, isInitialLoading: isGettingIntegrationActions } = useGetIntegrationActions({
    integrationId: actionNode.integrationId,
    version: actionNode.version,
  });

  const theme = useTheme();
  const selectedAction = useMemo(() => {
    return actions?.actions.find((a) => a.id === actionNode.actionId);
  }, [actionNode.actionId, actions?.actions]);

  const hasSlackBlocks = useMemo(
    () => selectedAction?.inputParameters?.find((i) => i.uiComponent.type === UIComponentType.BLOCK_LIST),
    [selectedAction?.inputParameters]
  );

  const isRecordIntegration = useMemo(() => integration?.type === IntegrationType.DATA_MODEL, [integration?.type]);
  const hasJobTarget = useMemo(() => integration?.type === IntegrationType.JOB, [integration?.type]);
  const hasCatalogRelationshipFilter = useMemo(
    () =>
      selectedAction?.inputParameters?.find((i) => i.uiComponent.type === UIComponentType.CATALOG_RELATIONSHIP_FILTER),
    [selectedAction?.inputParameters]
  );

  const handleUpdateNode = useCallback(
    ({ actionNode, isUpdateGraph }: { actionNode: ActionNode; isUpdateGraph?: boolean }) => {
      if (!hasEditPermission) {
        return;
      }

      updateActionNode(
        { appId: userAppId, workflowId, nodeAlias: actionNode.alias, payload: actionNode },
        {
          onSuccess: () => {
            if (isUpdateGraph) {
              const graphNode = userAppGraph.node(actionNode.alias);
              const newGraphNode = cloneDeep(graphNode);
              newGraphNode.name = actionNode.name;
              updateNode({ newNode: newGraphNode });
            }
          },
        }
      );
    },
    [hasEditPermission, updateActionNode, updateNode, userAppId, workflowId]
  );

  return {
    formId,
    integration,
    theme,
    actions,
    isGettingIntegrationActions,
    context: workflowCodeInputContext,
    selectedAction,
    hasSlackBlocks,
    isRecordIntegration,
    hasCatalogRelationshipFilter,
    actionNode: actionNode,
    hasJobTarget,
    handleUpdateNode,
    deleteNodeAndUpdateGraph,
  };
};
