import { NvRouterLink } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const JobRouterLink = styled(NvRouterLink, { shouldForwardProp: (prop) => prop !== 'disabled' })<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.palette.nv_main[60]};

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : null)}
`;
