import { red } from '@mui/material/colors';
import { getColors } from '../../../constants/colors';
import { ThemeMode, Themes } from '../../types';

const colors = getColors(Themes.Dracula, ThemeMode.Light);

export default {
  primary: {
    main: '#2CD0D0',
  },
  secondary: {
    main: '#c6d19800',
  },
  error: {
    main: red.A100,
  },
  success: {
    main: '#2CD0D0',
  },
  test: {
    value: 'testDraculaLight',
  },
  ...colors,
};
