import {
  isMaxLength,
  isMinLength,
  isRequired,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvMarkDownEditor,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import * as React from 'react';
import { NvLogoUpload } from '../../components/logo-upload';
import { IntegrationInitialLogo } from '../components/integration-initial-logo';
import { CreateIntegrationModalProps } from './types';
import { useCreateIntegrationModal } from './use-create-integration-modal';

export const CreateIntegrationModal: React.FC<React.PropsWithChildren<CreateIntegrationModalProps>> = ({
  isOpen,
  onClose,
}) => {
  const { handleOnSubmit } = useCreateIntegrationModal(onClose);

  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <NvForm<{ name: string; description?: string; logoId: string }> onSubmit={handleOnSubmit}>
        {({ submitting, form, values }) => {
          return (
            <NvDialogSlots>
              <NvDialogSlots.Slot name="icon">
                <NvAddIcon />
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="title">
                <NvTypography variant="h2">Add integration</NvTypography>
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="closeButton">
                <NvButton
                  onlyIcon
                  size="small"
                  color="secondary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <NvCloseIcon />
                </NvButton>
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="content">
                <NvFlex spacing={2} direction="column">
                  <NvLogoUpload
                    ImageFallBack={<IntegrationInitialLogo name={values.name ?? 'Integration'} size="xlarge" />}
                    type="integration"
                    imageShape="square"
                    imageSize="xlarge"
                    onLogoStateChanged={({ logo }) => {
                      form.change('logoId', logo.id);
                    }}
                    logoUploadButtonProps={{
                      text: 'Upload icon',
                    }}
                    hasRemoveAction
                    fallbackImageProps={{
                      hasBlurEffect: true,
                      className: 'package-image',
                    }}
                  />
                  <NvField
                    name="name"
                    labelText="Name"
                    labelVariant="h5"
                    direction="label-on-top"
                    showErrorMessageOnlyWhenBlur
                    component={<NvTextField size="medium" disabled={submitting} />}
                    validators={[isRequired(), isMinLength({ length: 3 }), isMaxLength({ length: 150 })]}
                    hasRequiredIndicator
                  />
                  <NvField
                    direction="label-on-top"
                    name="description"
                    labelText="Description"
                    labelVariant="h5"
                    showErrorMessageOnlyWhenBlur
                    component={<NvMarkDownEditor />}
                    validators={[
                      isMaxLength({
                        length: 3000,
                      }),
                    ]}
                  />
                </NvFlex>
              </NvDialogSlots.Slot>
              <NvDialogSlots.Slot name="actions">
                <NvButton type="submit" loading={submitting} disabled={submitting}>
                  Create
                </NvButton>
                <NvButton onClick={onClose} color="ghost">
                  Cancel
                </NvButton>
              </NvDialogSlots.Slot>
            </NvDialogSlots>
          );
        }}
      </NvForm>
    </NvDialogV1>
  );
};
