type SlackAppCommandFeature = {
  command: string;
  description: string;
  usageHint: string;
};

type SlackAppShortcutFeature = {
  name: string;
  description: string;
};

type SlackAppBotUserFeature = {
  displayName: string;
};

type SlackAppFeatures = {
  commandFeatures: SlackAppCommandFeature[];
  shortcutFeatures: SlackAppShortcutFeature[];
  botUserFeature: SlackAppBotUserFeature;
};

type SlackAppDisplayInfo = {
  name: string;
  description: string;
  backgroundColor: string;
  longDescription: string;
};

export type SlackAppManifest = {
  displayInfo: SlackAppDisplayInfo;
  appFeatures: SlackAppFeatures;
};

export type SlackApp = {
  slackAppId: string;
  slackAuthorizationUrl: string;
  slackAppManifest: SlackAppManifest;
  slackTeamName?: string;
  slackTeamId: string;
  slackTeamIconUrl?: string;
  installed: boolean;
  marketplaceApp: boolean;
  enterpriseInstallation: boolean;
  slackAppHomeUrl: string;
};

export enum SlackAppInstallationTrigger {
  DIRECT_INSTALLATION = 'direct-installation',
  CREATION = 'creation',
}
