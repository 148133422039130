import { useMemo } from 'react';
import { Workflow, WorkflowState, WorkflowWithState } from '../../../types/workflow/types';
import { UseGetWorkflowResponse } from '../types';

export const useWorkflow = (
  response: UseGetWorkflowResponse | undefined
): { workflow?: WorkflowWithState; savedWorkflow?: Workflow; draftWorkflow?: Workflow } => {
  const workflow = useMemo(() => {
    return !response
      ? undefined
      : response.draft
      ? { ...response.draft, state: WorkflowState.DRAFT }
      : { ...response.saved, state: WorkflowState.SAVED };
  }, [response]);

  const savedWorkflow = useMemo(() => {
    return response?.saved;
  }, [response?.saved]);

  const draftWorkflow = useMemo(() => {
    return response?.draft;
  }, [response?.draft]);

  return {
    workflow,
    savedWorkflow,
    draftWorkflow,
  };
};
