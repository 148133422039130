import { NvAxios, useMutation } from '@novaera/core';
import { WorkspaceCreateResponse } from './types';

const confirmCode = async (props: WorkspaceCreateResponse) => {
  const result = await NvAxios.post<{ subdomain: string }>('v1/workspaces', {
    ...props,
  });
  return result.data;
};

export const useCreateWorkspaceService = () => {
  return useMutation((props: WorkspaceCreateResponse) => confirmCode(props));
};
