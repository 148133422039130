import { ApplicationConfig } from '@novaera/application-config';
import { ROUTE_DEFAULTS } from '@novaera/constants';
import { DocumentTitle } from '@novaera/core';
import { Navigate, Route, Routes } from '@novaera/route';
import { getRootAppDomain, getSubdomain } from '@novaera/utils';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { SignInComponentPublicProps } from '../common/types';
import { ErrorPage } from './error-page';
import { Layout } from './layout';
import { Logout } from './logout';
import { SignIn } from './sign-in';
import { StandAloneWorkspaceCreate } from './sign-in/workspace-panel/form/standalone';
import { SignUpAgreement } from './sign-up-agreement';
import { SlackWorkspaces } from './slack-workspaces';
import { SocialLogin } from './social-login';
import { IdentityProviderProps } from './types';
import { WorkspaceRedirection } from './workspace-redirection';

export const IdentityProvider: React.FC<React.PropsWithChildren<IdentityProviderProps>> = ({
  SignInComponent,
  routeConfig = {
    socialLoginPath: {
      path: ROUTE_DEFAULTS.SOCIAL_LOG_IN,
    },
    signInPath: {
      path: ROUTE_DEFAULTS.SIGN_IN,
    },
    signUpPath: {
      path: ROUTE_DEFAULTS.SIGN_UP,
    },
    userInvitationPath: {
      path: ROUTE_DEFAULTS.USER_INVITATION,
    },
    logoutPath: {
      path: ROUTE_DEFAULTS.LOG_OUT,
    },
    userEmailChangeConfirmationPath: {
      path: ROUTE_DEFAULTS.EMAIL_CHANGE_CONFIRMATION,
    },
  },
  GlobalComponents,
  appName,
  children,
}) => {
  const { NODE_ENV, NX_REDIRECT_PUBLIC_PAGE } = process.env;
  const { pathname, protocol } = window.location;
  const subdomain = getSubdomain();

  if (subdomain != '' && pathname === routeConfig.signInPath) {
    if (NX_REDIRECT_PUBLIC_PAGE == 'true' && NODE_ENV === 'production') {
      window.location.replace(`${protocol}//www.${getRootAppDomain()}${routeConfig.signInPath.path}`);
    } else {
      window.location.replace(`${ApplicationConfig.Actioner.origin}${routeConfig.signInPath.path}`);
    }
  } else if (subdomain === '' && pathname === '/') {
    window.location.replace(`${ApplicationConfig.Actioner.origin}${routeConfig.signInPath?.path}`);
  }

  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  return (
    <>
      <SentryRoutes>
        <Route element={<Layout hasCardWrapper={false} footerTopSlot={<SignUpAgreement />} />}>
          <Route path={ROUTE_DEFAULTS.SLACK_WORKSPACES} element={<SlackWorkspaces />} />
        </Route>

        <Route {...routeConfig.logoutPath} element={<Logout />} />
        <Route path={ROUTE_DEFAULTS.REDIRECTING_TO_DASHBOARD} element={<WorkspaceRedirection />} />

        <Route element={<Layout isLayoutCentered hasCardWrapper />}>
          <Route {...routeConfig.socialLoginPath} element={<SocialLogin />} />
          <Route
            {...routeConfig.userEmailChangeConfirmationPath}
            element={
              <SignIn
                {...(SignInComponent && {
                  render: (props: SignInComponentPublicProps) => <SignInComponent {...props} />,
                })}
              />
            }
          />
          <Route
            {...routeConfig.userInvitationPath}
            element={
              <SignIn
                {...(SignInComponent && {
                  render: (props: SignInComponentPublicProps) => <SignInComponent {...props} />,
                })}
              />
            }
          />
          <Route
            {...routeConfig.signInPath}
            element={
              <>
                <DocumentTitle title="Login to Actioner" defaultTitle={appName} />
                <SignIn
                  {...(SignInComponent && {
                    render: (props: SignInComponentPublicProps) => <SignInComponent {...props} />,
                  })}
                />
              </>
            }
          />
          <Route {...routeConfig.signUpPath} element={<Navigate to={ROUTE_DEFAULTS.SIGN_IN} />} />
          <Route path={ROUTE_DEFAULTS.EARLY_ACCESS} element={<Navigate to={ROUTE_DEFAULTS.SIGN_IN} />} />
          <Route path="/workspace/create" element={<StandAloneWorkspaceCreate />} />
          <Route path="/user/verification" element={<SignIn />} />

          <Route
            path="/error/invalid-invitation"
            element={
              <ErrorPage
                title="Invitation link expired"
                description="Your invitation link expired. Please contact the Workspace admin and ask them for a new invite."
                onClick={() => {
                  window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.SIGN_IN}`;
                }}
              />
            }
          />
          <Route
            path="/error/invalid-confirm-email"
            element={
              <ErrorPage
                title="E-mail confirmation link expired"
                description="Your e-mail confirmation link expired. Please re-update your e-mail."
                onClick={() => {
                  window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.SIGN_IN}`;
                }}
              />
            }
          />
        </Route>

        {children}
      </SentryRoutes>
      {GlobalComponents?.map((f) => f)}
    </>
  );
};
