import { NvArrowDropDownIcon, NvArrowDropUpIcon, NvFlex, NvPopover, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useRef, useState } from 'react';
import { PublicMenuItem } from '../public-navigation/styled';

export const PublicMenuPopperMenu: React.FC<React.PropsWithChildren<{ label: string }>> = ({ label, children }) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const PaperProps = {
    style: {
      width: 314,
      background: palette.nv_neutral[0],
      border: `1px solid ${palette.nv_neutral[0]}`,
      boxShadow: '0px 0px 0px 1px #EBEEF2, 0px 18px 30px -3px rgba(10, 45, 98, 0.08)',
      borderRadius: 12,
      marginTop: 8,
    },
  };
  const id = open ? `solutions-menu-popover` : undefined;

  return (
    <>
      <PublicMenuItem
        ref={ref}
        onMouseOver={() => {
          setAnchorEl(ref.current);
        }}
      >
        <NvTypography variant="h3" fontWeight="600">
          {label}
        </NvTypography>
        {open ? <NvArrowDropUpIcon /> : <NvArrowDropDownIcon />}
      </PublicMenuItem>

      <NvPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={PaperProps}
        onClick={(e) => e.stopPropagation()}
        onBackdropClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <NvFlex
          padding="6px"
          onMouseLeave={() => {
            setAnchorEl(undefined);
          }}
        >
          {children}
        </NvFlex>
      </NvPopover>
    </>
  );
};
