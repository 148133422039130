import { useCreateDataModel } from '@novaera/actioner-service';
import { useNavigate, useParams } from '@novaera/route';
import { USER_APP_DATABASE_DETAIL } from '../../../../constants';
import { DataModelValue } from '../types';

export const useCreateTableModal = ({ handleClose }: { handleClose: () => void }) => {
  const { mutate, isLoading } = useCreateDataModel();
  const { userAppId: appId } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (values: DataModelValue) => {
    return mutate(
      { ...values, appId },
      {
        onSuccess: ({ id }) => {
          navigate(USER_APP_DATABASE_DETAIL(appId, id));
          handleClose();
        },
      }
    );
  };
  return { handleSubmit, isLoading };
};
