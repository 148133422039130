import { styled, withAlpha } from '@novaera/theme-provider';
import { NvBox } from '../../index';

export const NodeListRefWrapper = styled(NvBox)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NodeListScrollWrapper = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'isOverflow' })<{
  isOverflow: boolean;
}>`
  max-height: 406px;
  overflow: auto;
  position: relative;

  &::after {
    content: '';
    display: ${({ isOverflow }) => (isOverflow ? 'block' : 'none')};
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.palette.nv_neutral[0]} 0,
      ${({ theme }) => withAlpha(theme.palette.nv_neutral[0], 0)} 100%
    );
    z-index: 30;
    pointer-events: none;
  }
`;
