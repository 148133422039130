import { FallbackProps } from '@novaera/core';
import React from 'react';

export const ErrorFallback: React.FC<React.PropsWithChildren<FallbackProps>> = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};
