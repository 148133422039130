import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppMenuFieldWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8px;
`;

export const AppMenuFieldItem = styled(NvFlex)`
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 6px;
  padding: 12px;
`;

export const IconBlock = styled('div')`
  width: 16px;
  height: 16px;
`;
