import { OutputNode, OutputNodeSummary } from '@novaera/actioner-service';
import {
  NvBarChartIcon,
  NvBorderAllIcon,
  NvKeyValueTableIcon,
  NvNotesIcon,
  NvPieChartOutlineIcon,
  NvSlackIconNotColored,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';

import { useMemo } from 'react';

export const useOutputIcon = ({ outputNode }: { outputNode: OutputNodeSummary | OutputNode }) => {
  const theme = useTheme();
  const outputIcon = useMemo(() => {
    const outputComponent = outputNode.outputComponent;
    if (outputComponent.type === 'table') {
      return <NvBorderAllIcon htmlColor={theme.palette.nv_node.output} />;
    } else if (outputComponent.type === 'key-value-table') {
      return <NvKeyValueTableIcon htmlColor={theme.palette.nv_node.output} />;
    } else if (outputComponent.type === 'chart' && outputComponent.chart.type === 'bar') {
      return <NvBarChartIcon htmlColor={theme.palette.nv_node.output} />;
    } else if (outputComponent.type === 'chart' && outputComponent.chart.type === 'pie') {
      return <NvPieChartOutlineIcon htmlColor={theme.palette.nv_node.output} />;
    } else if (outputComponent.type === 'markdown') {
      return <NvNotesIcon htmlColor={theme.palette.nv_node.output} />;
    } else if (outputComponent.type === 'slack') {
      return <NvSlackIconNotColored htmlColor={theme.palette.nv_node.output} />;
    }
    assert(false, new Error(`Output type can be table, bar-chart, pie-chart, key-value-table or markdown`), 'ERROR');
  }, [outputNode.outputComponent, theme.palette.nv_node.output]);

  return { outputIcon };
};
