export const QUERY_KEYS_FILES = {
  all: ['app-files'] as const,
  list: (appId: string, queryParams?: string) =>
    queryParams
      ? ([...QUERY_KEYS_FILES.all, 'list', appId, queryParams] as const)
      : ([...QUERY_KEYS_FILES.all, 'list', appId] as const),
  detail: (appId: string, fileId: string) => [...QUERY_KEYS_FILES.all, 'detail', appId, fileId] as const,
  publicUrl: (appId: string, key: string) => [...QUERY_KEYS_FILES.all, 'publicUrl', appId, key] as const,
};

export const QUERY_KEYS_FILE_TRANSFER_TYPES = {
  all: ['file-transfer-types'] as const,
  search: (appId: string, searchKeyword?: string) => [...QUERY_KEYS_FILES.all, 'search', appId, searchKeyword] as const,
};
