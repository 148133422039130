import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { GetSampleParams, GetSampleResponse } from '../../types';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE } from '../keys';

const getLatestCapturedHook = async (params: GetSampleParams) => {
  const result = await NvAxios.get<GetSampleResponse>(
    `${USER_APP_ROOT_PATH}/${params.appId}/workflows/${params.workflowId}/events/latest`
  );
  return result?.data;
};

export const useGetLatestCapturedHook = () => {
  const cache = useQueryClient();
  return useMutation(getLatestCapturedHook, {
    onSuccess: (response, { appId, workflowId }) => {
      cache.setQueryData(QUERY_KEYS_WORKFLOW_INTEGRATION_TRIGGER_EVENT_SAMPLE.detail({ appId, workflowId }), response);
    },
  });
};
