import * as React from 'react';
import { Field } from 'react-final-form';
import { usePrevious } from './use-previous';
export type OnChangeProps = {
  name: string;
  children: (value: unknown, previous: unknown) => void;
};

type Props = {
  children: (value: unknown, previous: unknown) => void;
  input: {
    value: unknown;
  };
  meta: { dirty?: boolean };
};

const OnChangeState: React.FC<Props> = ({ input: { value }, children, meta: { dirty } }) => {
  const previous = usePrevious(value);
  React.useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(previous) && dirty) {
      children(value, previous);
    }
  }, [value, children, previous, dirty]);

  return null;
};

const MemoOnChangeState = React.memo(OnChangeState, (prev, next) => {
  return JSON.stringify(prev.input.value) === JSON.stringify(next.input.value);
});

const OnChange = ({ name, children }: OnChangeProps) =>
  React.createElement(Field, {
    name,
    subscription: { value: true, dirty: true },
    allowNull: true,
    render: (props) => React.createElement(MemoOnChangeState, { ...props, children }),
  });

export default OnChange;
