import { useGetLatestCapturedHook } from '@novaera/actioner-service';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';

export const useGetLatestCapturedEventPayload = () => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();

  const { mutate: getCapturedData, isLoading: isCapturingPayload } = useGetLatestCapturedHook();

  const handleCaptureDataClicked = () => {
    getCapturedData({ appId: userAppId, workflowId });
  };

  return {
    isCapturingPayload,
    getCapturedData: handleCaptureDataClicked,
  };
};
