import { CodeInput, FieldTitle, NvBox, NvFlex } from '@novaera/core';
import { ComponentHint } from '../common/adapter/component-hint';
import { DisplayValueComponentParams } from '../use-ui-component/types';

export const DisplayValueComponent = ({
  inputParameter: { uiComponent },
  inputProps,
  context = {},
}: DisplayValueComponentParams) => {
  const { hint } = uiComponent;

  return (
    <>
      <NvFlex gap="8px">
        <NvFlex direction="row" gap="8px">
          <FieldTitle direction="label-on-side" labelText="Identifier" labelVariant="h6" labelWidth="90px" />
          <NvBox flex="1 1 auto" minWidth={0}>
            <CodeInput
              context={context}
              {...inputProps}
              onChange={(value) => {
                inputProps.onChange({ ...inputProps.value, value: value });
              }}
              value={typeof inputProps.value.value === 'string' ? inputProps.value.value : ''}
              growingHeight
              maxHeight="300px"
            />
          </NvBox>
        </NvFlex>
        <NvFlex direction="row" gap="8px">
          <FieldTitle direction="label-on-side" labelText={'Display value'} labelVariant="h6" labelWidth="90px" />
          <NvBox flex="1 1 auto" minWidth={0}>
            <CodeInput
              context={context}
              {...inputProps}
              onChange={(value) => {
                inputProps.onChange({ ...inputProps.value, displayValue: value });
              }}
              value={typeof inputProps.value.displayValue === 'string' ? inputProps.value.displayValue : ''}
              growingHeight
              maxHeight="300px"
            />
          </NvBox>
        </NvFlex>
      </NvFlex>
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
