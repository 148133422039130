import { NvTypography } from '@novaera/core';
import { SingleLineJsonTagBox } from './styled';
import { SingleLineJsonTagProps } from './types';

export const SingleLineJsonTag: React.FC<React.PropsWithChildren<SingleLineJsonTagProps>> = ({ value }) => (
  <SingleLineJsonTagBox>
    <NvTypography variant="c2" noWrap textColor="subtle">
      {value}
    </NvTypography>
  </SingleLineJsonTagBox>
);
