import { GetFormParametersResponse } from '@novaera/actioner-service';
import { FormApi } from 'final-form';
import { MutableRefObject, useMemo, useRef } from 'react';
import { InputFormValues } from '../action-designer/providers/input-values';
import { FormIdentifierProvider } from '../providers/form-identifier-provider';
import { FormParameterProvider } from '../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/providers/form-parameter-provider';
import { WorkflowReferenceOutputProvider } from '../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/providers/workflow-reference-output-provider';
import { useWorkflowRunFromLink } from './controllers/use-workflow-run-from-link';
import { WorkflowRunFromLinkForm } from './form';
import { WorkflowRunFromLinkLoadingSkeleton } from './loading-skeleton';

export const WorkflowRunFromLink = () => {
  const { workflow, userApp, isUserAppLoading, isWorkflowLoading, workflowFromLink } = useWorkflowRunFromLink();

  const isLoading = !workflowFromLink || isUserAppLoading || isWorkflowLoading || !userApp || !workflow;

  const formRef: MutableRefObject<FormApi<InputFormValues, Partial<InputFormValues>> | null> = useRef(null);

  const getInitialFormValues = (formParameters?: GetFormParametersResponse) => {
    const initialFormValues: InputFormValues = {};

    formParameters?.inputParameterWithValues.forEach((inputParameterWithValue) => {
      if (inputParameterWithValue) {
        if (inputParameterWithValue.parameterMapping) {
          const inputParameterId = inputParameterWithValue.inputParameter.id;
          initialFormValues[inputParameterId] = inputParameterWithValue.parameterMapping;
        }
      }
    });

    return initialFormValues;
  };

  const hiddenValues = useMemo(() => {
    return (
      workflowFromLink?.calculatedParameterMappings
        .filter((x) => x.hidden)
        ?.reduce((acc: InputFormValues, cur) => {
          acc[cur.parameterId] = cur;
          return acc;
        }, {}) || {}
    );
  }, [workflowFromLink]);

  return isLoading ? (
    <WorkflowRunFromLinkLoadingSkeleton />
  ) : (
    <FormIdentifierProvider initialFormId={workflowFromLink?.formId}>
      <FormParameterProvider
        workflow={workflow}
        initialParameterValues={workflowFromLink.calculatedParameterMappings}
        formRef={formRef}
      >
        {({ inputParameters, isInitialLoading, formParameters }) => {
          if (isInitialLoading || !inputParameters || !formParameters) return <WorkflowRunFromLinkLoadingSkeleton />;

          return (
            <WorkflowReferenceOutputProvider
              initialWorkflowId={workflowFromLink.workflowId}
              userAppId={workflowFromLink.appId}
            >
              {({ workflowOutputRefParams, currentPage, isLoading, workflow: outputWorkflow }) => {
                return (
                  <WorkflowRunFromLinkForm
                    initialFormValues={{ ...getInitialFormValues(formParameters), ...hiddenValues }}
                    workflowOutputRefParams={workflowOutputRefParams}
                    currentPage={currentPage}
                    isOutputLoading={Boolean(isLoading)}
                    workflowFromOutput={outputWorkflow}
                    initialWorkflow={workflow}
                    userApp={userApp}
                    inputParameters={inputParameters}
                    formRef={formRef}
                    executeImmediately={workflowFromLink.executeImmediately}
                  />
                );
              }}
            </WorkflowReferenceOutputProvider>
          );
        }}
      </FormParameterProvider>
    </FormIdentifierProvider>
  );
};
