import { NvAddBoxIcon, NvButton, NvDeleteOutlineIcon, NvMenuWithItems } from '@novaera/core';
import { FC } from 'react';
import { PropertyPanelSection } from '../../../property-panel';
import { WorkflowReferenceItems } from '../../../workflow-reference-items';
import { WorkflowReferenceBlockProps } from '../types';
import { useWorkflowReferenceBlock } from './use-workflow-reference-block';

export const WorkflowReferenceBlock: FC<React.PropsWithChildren<WorkflowReferenceBlockProps>> = ({
  block,
  onChange,
  dragHandleProps,
  onDeleteClick,
  index,
  title,
  context,
}) => {
  const { handleAddButtonElement, theme } = useWorkflowReferenceBlock({ block, onChange, index });

  return (
    <PropertyPanelSection
      dragHandleProps={dragHandleProps}
      title={title}
      draggable
      collapsible
      actions={
        onDeleteClick && (
          <>
            <NvButton
              size="small"
              color="ghost"
              onlyIcon
              variant="contained"
              onClick={() => {
                handleAddButtonElement();
              }}
            >
              <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />
            </NvButton>

            <NvMenuWithItems
              triggerButton={{
                props: { color: 'ghost', size: 'small', variant: 'contained', onlyIcon: true },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  onClick: onDeleteClick,
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                },
              ]}
            />
          </>
        )
      }
    >
      <WorkflowReferenceItems block={block} index={index} onChange={onChange} context={context} />
    </PropertyPanelSection>
  );
};
