import React, { RefObject, useEffect, useRef } from 'react';
import { useRulesController } from '../../../../../../components/match-event-section/rules/controllers/use-rules';
import { MatchEventSectionRules } from '../../../../../../components/match-event-section/section-rules';
import { Section } from './section';
import { PropertyPanelFunctionSectionStyled } from './styled';

export const ConditionRule = ({
  codeInputContext,
  conditionFieldName,
  conditionType,
  addButtonRef,
  valueTypeOptions,
}: React.ComponentPropsWithoutRef<typeof MatchEventSectionRules> & { addButtonRef: RefObject<HTMLButtonElement> }) => {
  const { onAddClicked } = useRulesController({ conditionFieldName });
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const addEvent = function () {
      onAddClicked();
      sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const addButton = addButtonRef.current;
    if (addButton) {
      addButton.addEventListener('click', addEvent);
    }

    return () => {
      if (addButton) {
        addButton.removeEventListener('click', addEvent);
      }
    };
  }, [addButtonRef, onAddClicked]);

  return (
    <MatchEventSectionRules
      ref={sectionRef}
      layout="vertical"
      codeInputContext={codeInputContext}
      conditionFieldName={conditionFieldName}
      conditionType={conditionType}
      showAddButton={false}
      showRemoveButton={false}
      JavascriptWrapperComponent={PropertyPanelFunctionSectionStyled}
      RulesWrapperComponent={Section}
      valueTypeOptions={valueTypeOptions}
    />
  );
};
