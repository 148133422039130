import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_ROOT_PATH } from '../constants';
import { UpdateIntegrationPartialParams, UpdateIntegrationPartialResponse } from '../types';
import { useUpdateIntegrationCache } from '../use-update-cache';

const updateIntegrationPartial = async ({
  id,
  name,
  logo,
  description,
  connectionSchemaId,
}: UpdateIntegrationPartialParams) => {
  const result = await NvAxios.patch<UpdateIntegrationPartialResponse>(`${INTEGRATION_ROOT_PATH}/${id}`, {
    ...(name ? { name } : {}),
    ...(logo ? { logo } : {}),
    ...(description ? { description } : {}),
    // to remove connectionSchemaId, we need to send null
    ...(connectionSchemaId || connectionSchemaId !== undefined ? { connectionSchemaId } : {}),
  });
  return result?.data;
};

export const useUpdateIntegrationPartial = () => {
  const { updateCache } = useUpdateIntegrationCache();
  return useMutation(updateIntegrationPartial, {
    onSuccess: (integrationDetail, { id }) => {
      updateCache({
        integration: integrationDetail,
        method: 'update',
      });
    },
  });
};
