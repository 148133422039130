export function NvStoreIllustration() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M82.6824 28.0607C82.6824 29.5259 82.1826 30.5822 81.3989 31.1388L81.0354 31.3432L79.0137 32.5017C79.2976 32.2632 79.5589 31.9565 79.7406 31.5704C80.0246 31.0252 80.1722 30.3323 80.1836 29.5032C80.1836 28.5604 80.0018 27.5723 79.6725 26.5841L82.2508 25.4028C82.5347 26.3001 82.6937 27.1974 82.6824 28.0607Z"
        fill="#455068"
      />
      <path
        d="M79.0251 33.0697C78.8433 33.0697 78.6616 32.9788 78.5594 32.8198C78.389 32.5812 78.4458 32.2405 78.673 32.0588C78.9115 31.8657 79.1046 31.6158 79.2522 31.3205C79.5021 30.8434 79.6271 30.2301 79.6384 29.4918C79.6384 28.6513 79.4794 27.7313 79.15 26.7658C79.0592 26.4932 79.1841 26.1865 79.4453 26.073L82.0123 24.8917C82.16 24.8236 82.3303 24.8236 82.478 24.8917C82.6256 24.9599 82.7392 25.0848 82.7847 25.2325C83.1027 26.2093 83.2503 27.1634 83.2503 28.0607C83.2503 29.6508 82.7052 30.9116 81.7283 31.5931L79.2977 32.9901C79.2068 33.0356 79.1159 33.0697 79.0137 33.0697H79.0251ZM80.3767 26.8908C80.6493 27.8108 80.7743 28.6854 80.7629 29.5032C80.7629 30.0256 80.6947 30.514 80.5812 30.9457L81.115 30.639C81.7283 30.196 82.1032 29.2646 82.1145 28.0493C82.1145 27.4473 82.0464 26.834 81.8874 26.1865L80.3767 26.8794V26.8908Z"
        fill="#455068"
      />
      <path
        d="M82.2507 25.4027L79.6723 26.584C78.8659 24.2215 77.1849 21.9385 75.254 20.8254C74.5384 20.4051 73.8683 20.1893 73.2549 20.1552C72.7892 20.1325 72.3576 20.2007 71.9941 20.371L74.1749 19.0989L74.3907 18.974C75.2653 18.5196 76.4466 18.6219 77.7528 19.3829C79.7632 20.5528 81.501 22.9494 82.262 25.4027H82.2507Z"
        fill="white"
      />
      <path
        d="M79.6838 27.1519C79.6043 27.1519 79.5361 27.1406 79.4566 27.1065C79.309 27.0383 79.1954 26.9247 79.1386 26.7657C78.3549 24.46 76.7193 22.3247 74.9701 21.3138C74.3454 20.9503 73.7548 20.7572 73.2209 20.7231C72.8348 20.7004 72.5167 20.7572 72.2328 20.8935C71.9602 21.0185 71.6308 20.9162 71.4945 20.6436C71.3582 20.371 71.449 20.0416 71.7103 19.894L74.1069 18.4969C75.22 17.9176 76.6171 18.0767 78.0368 18.9058C80.104 20.1098 81.9782 22.5973 82.796 25.2551C82.8755 25.5277 82.7505 25.823 82.4893 25.9366L79.9223 27.1178C79.8428 27.1519 79.7633 27.1746 79.6838 27.1746V27.1519ZM74.1977 19.7463C74.6407 19.8713 75.095 20.0644 75.5494 20.337C77.4008 21.4046 79.0704 23.4718 80.0018 25.8116L81.5579 25.0961C80.7515 22.8812 79.184 20.8594 77.4689 19.8713C76.3899 19.2352 75.3904 19.0989 74.6521 19.4737L74.1977 19.7463Z"
        fill="#455068"
      />
      <path
        d="M80.1948 29.5031C80.1948 30.3323 80.0358 31.0251 79.7518 31.5703C79.5587 31.9565 79.3088 32.2632 79.0249 32.5017C78.9567 32.5698 78.8659 32.6153 78.7977 32.6607C77.8891 33.2286 76.6169 33.1718 75.2085 32.3313C72.5052 30.7298 70.3245 26.8907 70.3245 23.7331C70.3245 22.2452 70.8015 21.1661 71.5966 20.6096L71.9601 20.3938C71.9601 20.3938 71.9828 20.3711 71.9941 20.3711C72.3689 20.2007 72.7892 20.1325 73.2549 20.1553C73.8682 20.1893 74.5497 20.4051 75.2539 20.8254C77.1962 21.9499 78.8772 24.2215 79.6723 26.584C80.0017 27.5722 80.1948 28.5604 80.1834 29.5031H80.1948Z"
        fill="white"
      />
      <path
        d="M77.4461 33.6034C76.6624 33.6034 75.7992 33.3422 74.9132 32.8197C72.0169 31.1046 69.7452 27.1179 69.7452 23.7331C69.7452 22.1089 70.2791 20.8368 71.2559 20.1439L71.6534 19.9054C71.6534 19.9054 71.7102 19.8713 71.7443 19.8599C72.1986 19.6441 72.7211 19.5646 73.2777 19.5873C74.0046 19.6328 74.7542 19.8827 75.538 20.337C77.537 21.4955 79.3316 23.8126 80.2176 26.4023C80.5924 27.4927 80.7741 28.5377 80.7628 29.5031C80.7628 30.4118 80.581 31.1955 80.2516 31.8202C80.0358 32.2518 79.7405 32.6266 79.377 32.9333C79.2862 33.0128 79.1726 33.0923 79.0477 33.1491C78.582 33.4444 78.0368 33.5921 77.4461 33.5921V33.6034ZM72.2213 20.8935L71.8806 21.098C71.2445 21.541 70.8811 22.4837 70.8811 23.7331C70.8811 26.6976 72.9483 30.3323 75.4812 31.8429C76.6511 32.5358 77.7187 32.6493 78.4797 32.1723C78.5479 32.1382 78.5933 32.1041 78.6274 32.0701C78.8886 31.8656 79.0817 31.6157 79.2294 31.3204C79.4793 30.8434 79.6042 30.23 79.6156 29.4917C79.6156 28.6512 79.4566 27.7312 79.1272 26.7658C78.3434 24.46 76.7079 22.3247 74.9587 21.3138C74.334 20.9503 73.7433 20.7572 73.2095 20.7232C72.8347 20.7005 72.5053 20.7572 72.2213 20.8935Z"
        fill="#455068"
      />
      <path
        d="M91.5533 83.5463C91.3603 83.5463 91.1672 83.4441 91.0649 83.2624C90.9059 82.9898 90.9968 82.649 91.2694 82.49L98.9249 78.0717C99.1975 77.9126 99.5382 78.0035 99.6972 78.2761C99.8562 78.5487 99.7654 78.8894 99.4928 79.0485L91.8373 83.4668C91.7464 83.5236 91.6442 83.5463 91.5533 83.5463Z"
        fill="#B1B6C0"
      />
      <path
        d="M77.3895 79.4799C77.1964 79.4799 77.0033 79.3777 76.9011 79.196C76.7421 78.9234 76.833 78.5826 77.1056 78.4236L97.7776 66.4861C98.0502 66.3271 98.391 66.4179 98.55 66.6905C98.709 66.9631 98.6181 67.3039 98.3455 67.4629L77.6735 79.4004C77.5826 79.4572 77.4804 79.4799 77.3895 79.4799Z"
        fill="#B1B6C0"
      />
      <path
        d="M63.5322 102.379C63.1346 102.379 62.6803 102.288 62.2146 102.026L62.7825 101.038C63.5435 101.481 64.3272 101.05 64.3613 101.038L64.9292 102.026C64.9292 102.026 64.3045 102.379 63.5322 102.379Z"
        fill="#455068"
      />
      <path
        d="M62.646 35.5458L38.0895 49.721C37.5102 50.0504 37.5102 50.8795 38.0895 51.2089L62.646 65.384C64.2476 66.304 66.2125 66.304 67.8141 65.384L92.3706 51.2089C92.9499 50.8795 92.9499 50.0504 92.3706 49.721L67.8141 35.5458C66.2125 34.6258 64.2476 34.6258 62.646 35.5458Z"
        fill="white"
      />
      <path
        d="M65.2356 66.6561C64.2474 66.6561 63.2479 66.4062 62.3733 65.8951L37.8168 51.72C37.3738 51.4588 37.1012 51.0044 37.1012 50.482C37.1012 49.9595 37.3624 49.5051 37.8168 49.2439L62.3733 35.0688C64.1452 34.0465 66.3374 34.0465 68.1093 35.0688L92.6658 49.2439C93.1088 49.5051 93.3814 49.9595 93.3814 50.482C93.3814 51.0044 93.1088 51.4588 92.6658 51.72L68.1093 65.8951C67.2233 66.4062 66.2351 66.6561 65.2356 66.6561ZM65.2356 35.4209C64.4405 35.4209 63.6455 35.6253 62.9412 36.0342L38.3847 50.2094C38.2484 50.2889 38.237 50.4138 38.237 50.4592C38.237 50.5047 38.2484 50.641 38.3847 50.7091L62.9412 64.8842C64.361 65.702 66.1216 65.702 67.5413 64.8842L92.0979 50.7091C92.2342 50.6296 92.2456 50.5047 92.2456 50.4592C92.2456 50.4138 92.2342 50.2775 92.0979 50.2094L67.5413 36.0342C66.8371 35.6253 66.0307 35.4209 65.2356 35.4209Z"
        fill="#455068"
      />
      <path
        d="M37.3624 51.9701H36.2266C36.2266 50.0165 37.7713 49.2554 37.8394 49.2214L38.3392 50.2436C38.3392 50.2436 37.3624 50.7434 37.3624 51.9701Z"
        fill="#455068"
      />
      <path
        d="M93.6656 51.9701V87.2716C93.6656 89.123 92.6774 90.8267 91.0872 91.7467L66.5307 105.922C65.9514 106.251 65.2358 105.842 65.2358 105.172V69.8707C65.2358 68.0193 66.224 66.3156 67.8142 65.3955L92.3707 51.2204C92.95 50.891 93.6656 51.2999 93.6656 51.9701Z"
        fill="#455068"
      />
      <path
        d="M66.0991 106.615C65.8492 106.615 65.6107 106.547 65.3835 106.422C64.9406 106.16 64.668 105.695 64.668 105.184V69.8821C64.668 67.8376 65.7697 65.9407 67.5302 64.9185L92.0868 50.7434C92.5298 50.4821 93.0636 50.4821 93.518 50.7434C93.9609 51.0046 94.2335 51.459 94.2335 51.9814V87.2829C94.2335 89.3274 93.1318 91.2242 91.3712 92.2465L66.8147 106.422C66.5875 106.547 66.349 106.615 66.0991 106.615ZM92.8137 51.6748C92.8137 51.6748 92.7229 51.6748 92.6661 51.7202L68.1095 65.8953C66.6897 66.7131 65.8152 68.2351 65.8152 69.8821V105.184C65.8152 105.331 65.9174 105.411 65.9628 105.433C66.0082 105.456 66.1218 105.502 66.2581 105.433L90.8147 91.2583C92.2345 90.4405 93.1091 88.9185 93.1091 87.2716V51.9701C93.1091 51.8224 93.0068 51.7429 92.9614 51.7202C92.9387 51.7088 92.8819 51.6748 92.8137 51.6748Z"
        fill="#455068"
      />
      <path
        d="M94.234 51.9701H93.0981C93.0981 50.7434 92.1668 50.2663 92.1213 50.2436L92.6211 49.2214C92.6892 49.2554 94.234 50.0165 94.234 51.9701Z"
        fill="#455068"
      />
      <path
        d="M67.8142 65.3957C67.8142 65.3957 64.8043 66.9063 62.6462 65.3957C62.6462 65.3957 65.2246 67.1108 65.2246 69.8708C65.2246 69.8708 65.0769 66.9858 67.8029 65.3957H67.8142Z"
        fill="#455068"
      />
      <path
        d="M65.2356 69.8821V105.184C65.2356 105.842 64.5087 106.263 63.9408 105.922L56.6147 101.697C56.0809 101.39 55.7515 100.822 55.7515 100.209V77.5375C55.7515 76.9015 55.4107 76.3222 54.8542 76.0269L47.5395 72.0401C46.9716 71.7221 46.2787 72.1424 46.2787 72.8011V94.2569C46.2787 94.5636 46.131 94.8135 45.9152 94.9498L45.8244 95.0065C45.6994 95.0747 45.5518 95.1201 45.4155 95.1201C45.2792 95.1201 45.1315 95.0747 44.9952 94.9952L39.3842 91.7694C37.7827 90.8494 36.8059 89.1457 36.8059 87.2943V51.9928C36.8059 51.334 37.5215 50.9138 38.1008 51.2545L62.6573 65.4296C64.2475 66.3497 65.2356 68.0534 65.2356 69.9048V69.8821Z"
        fill="white"
      />
      <path
        d="M64.3723 106.615C64.1224 106.615 63.8726 106.547 63.6568 106.41L56.3307 102.185C55.6265 101.787 55.1835 101.026 55.1835 100.209V77.5375C55.1835 77.1173 54.9563 76.7197 54.5815 76.5153L47.2668 72.5285C47.1419 72.4604 47.0396 72.4945 46.9828 72.5285C46.9374 72.5512 46.8352 72.6308 46.8352 72.7898V94.2455C46.8352 94.7112 46.608 95.1542 46.2218 95.4154C46.2105 95.4154 46.0969 95.4836 46.0855 95.4949C45.6198 95.7448 45.1428 95.7335 44.6998 95.4949L39.0888 92.2578C37.317 91.2356 36.2266 89.3388 36.2266 87.2829V51.9814C36.2266 51.4589 36.4992 51.0046 36.9421 50.7434C37.3851 50.4821 37.9189 50.4935 38.3733 50.7434L62.9298 64.9185C64.7017 65.9407 65.7921 67.8489 65.7921 69.8934V105.195C65.7921 105.717 65.5195 106.172 65.0765 106.433C64.8494 106.558 64.6109 106.626 64.3723 106.626V106.615ZM47.1305 71.3473C47.369 71.3473 47.6075 71.4041 47.8233 71.529L55.1267 75.5157C55.865 75.9133 56.3193 76.6856 56.3193 77.5262V100.197C56.3193 100.618 56.5351 100.992 56.8872 101.197L64.2247 105.433C64.361 105.513 64.4746 105.456 64.52 105.433C64.5654 105.411 64.6677 105.331 64.6677 105.184V69.882C64.6677 68.2465 63.7817 66.7131 62.3733 65.8953L37.8167 51.7202C37.6918 51.6407 37.5782 51.6861 37.5214 51.7202C37.476 51.7429 37.3737 51.8224 37.3737 51.9701V87.2715C37.3737 88.9185 38.2597 90.4405 39.6681 91.2583L45.2791 94.4841C45.3472 94.5295 45.404 94.5295 45.4154 94.5295C45.4608 94.5295 45.5063 94.5181 45.5631 94.4841C45.6426 94.4386 45.7221 94.3705 45.7221 94.2342V72.7784C45.7221 72.2673 45.9833 71.8016 46.4263 71.5404C46.6534 71.4154 46.892 71.3473 47.1418 71.3473H47.1305Z"
        fill="#455068"
      />
      <path
        d="M55.7517 77.5264V89.3163L45.9154 94.9272C46.1312 94.7796 46.2789 94.5297 46.2789 94.2344V72.7786C46.2789 72.1198 46.9717 71.6996 47.5396 72.0176L54.8544 76.0044C55.4109 76.311 55.7517 76.879 55.7517 77.515V77.5264Z"
        fill="#455068"
      />
      <path
        d="M46.1993 95.427L45.5973 94.4616C45.5973 94.4616 45.7109 94.3707 45.7109 94.2344V72.7786C45.7109 72.2675 45.9721 71.8018 46.4151 71.5406C46.8467 71.2793 47.3805 71.2794 47.8122 71.5292L55.1155 75.516C55.8538 75.9135 56.3081 76.6859 56.3081 77.5264V89.6457L46.1879 95.427H46.1993ZM47.1307 72.4833C47.0739 72.4833 47.0171 72.506 46.9944 72.5174C46.9489 72.5401 46.8467 72.6196 46.8467 72.7786V93.746L55.1837 88.9869V77.5264C55.1837 77.1061 54.9565 76.7086 54.5817 76.5042L47.267 72.5174C47.267 72.5174 47.1647 72.4833 47.1307 72.4833Z"
        fill="#455068"
      />
      <path
        d="M84.761 99.6638C84.5679 99.6638 84.3748 99.5616 84.2726 99.3799C84.1136 99.1073 84.2044 98.7665 84.477 98.6075L105.149 86.67C105.422 86.5109 105.774 86.6018 105.921 86.8744C106.08 87.147 105.99 87.4878 105.717 87.6468L85.0449 99.5843C84.9541 99.6411 84.8519 99.6638 84.761 99.6638Z"
        fill="#B1B6C0"
      />
      <path
        d="M33.4894 102.799C33.2963 102.799 33.1032 102.697 33.001 102.515C32.842 102.242 32.9328 101.902 33.2054 101.742L40.8155 97.3468C41.0881 97.1878 41.4402 97.2787 41.5878 97.5513C41.7468 97.8239 41.656 98.1646 41.3834 98.3237L33.7733 102.719C33.6825 102.776 33.5802 102.799 33.4894 102.799Z"
        fill="#B1B6C0"
      />
      <path
        d="M43.7799 114.225C43.5868 114.225 43.3937 114.123 43.2915 113.941C43.1325 113.669 43.2234 113.328 43.496 113.169L51.106 108.773C51.3786 108.614 51.7193 108.705 51.8783 108.978C52.0374 109.25 51.9465 109.591 51.6739 109.75L44.0639 114.146C43.973 114.203 43.8708 114.225 43.7799 114.225Z"
        fill="#B1B6C0"
      />
      <path
        d="M13.5667 103.798C13.3736 103.798 13.1805 103.696 13.0782 103.514C12.9192 103.242 13.0101 102.901 13.2827 102.742L20.8927 98.3464C21.1653 98.1874 21.5061 98.2782 21.6651 98.5508C21.8241 98.8234 21.7332 99.1642 21.4606 99.3232L13.8506 103.719C13.7597 103.776 13.6575 103.798 13.5667 103.798Z"
        fill="#B1B6C0"
      />
      <path
        d="M79.8997 107.229C79.7066 107.229 79.5135 107.126 79.4113 106.945C79.2522 106.672 79.3431 106.331 79.6157 106.172L87.2712 101.754C87.5438 101.595 87.8845 101.686 88.0435 101.958C88.2025 102.231 88.1117 102.572 87.8391 102.731L80.1836 107.149C80.0927 107.206 79.9905 107.229 79.8997 107.229Z"
        fill="#B1B6C0"
      />
      <path
        d="M70.9605 106.865C70.9492 108.864 70.279 110.318 69.2114 111.079L68.7116 111.363L65.9402 112.942C66.3377 112.624 66.6785 112.204 66.9397 111.67C67.3259 110.931 67.5303 109.977 67.5417 108.841C67.5531 107.558 67.2918 106.195 66.8375 104.855L70.3585 103.23C70.7561 104.457 70.9605 105.695 70.9492 106.865H70.9605Z"
        fill="#455068"
      />
      <path
        d="M65.9516 113.51C65.7698 113.51 65.5881 113.419 65.4859 113.26C65.3155 113.021 65.3609 112.681 65.5995 112.499C65.9516 112.215 66.2355 111.851 66.4513 111.42C66.8034 110.738 66.9852 109.864 66.9965 108.83C66.9965 107.66 66.7807 106.377 66.3264 105.025C66.2355 104.752 66.3605 104.446 66.6217 104.332L70.1428 102.708C70.2904 102.64 70.4608 102.64 70.6084 102.708C70.7561 102.776 70.8697 102.901 70.9151 103.049C71.3354 104.366 71.5512 105.65 71.5398 106.865C71.5285 108.966 70.8243 110.636 69.5521 111.533L66.2355 113.43C66.1446 113.476 66.0538 113.51 65.9516 113.51ZM67.5531 105.15C67.9506 106.445 68.1437 107.683 68.1323 108.841C68.1323 109.75 67.996 110.557 67.7462 111.261L68.9388 110.579C69.8588 109.921 70.3926 108.592 70.404 106.854C70.404 105.945 70.2791 104.991 70.0178 104.014L67.5531 105.15Z"
        fill="#455068"
      />
      <path
        d="M70.3699 103.23L66.8488 104.855C65.7471 101.618 63.4527 98.5053 60.7949 96.972C59.818 96.4041 58.8867 96.1088 58.0462 96.052C57.3987 96.0179 56.8195 96.1088 56.3197 96.3473L59.3069 94.6095L59.6022 94.4391C60.8062 93.8257 62.4191 93.962 64.2023 94.9956C66.9624 96.5858 69.3363 99.8797 70.3699 103.242V103.23Z"
        fill="white"
      />
      <path
        d="M66.8488 105.411C66.7693 105.411 66.7011 105.4 66.6216 105.366C66.474 105.298 66.3604 105.184 66.3036 105.025C65.2132 101.833 62.9416 98.8574 60.4995 97.449C59.6249 96.9379 58.7844 96.6539 58.0007 96.6085C57.4442 96.5744 56.9558 96.6539 56.5469 96.8584C56.2743 96.9833 55.9449 96.8811 55.8086 96.6085C55.6723 96.3359 55.7631 96.0065 56.0244 95.8589L59.3069 93.9393C60.7608 93.1897 62.5895 93.3941 64.4749 94.4959C67.2691 96.1087 69.7906 99.4708 70.8923 103.06C70.9718 103.333 70.8469 103.628 70.5857 103.741L67.0646 105.366C66.9851 105.4 66.9056 105.423 66.8261 105.423L66.8488 105.411ZM58.7844 95.5635C59.5227 95.7112 60.2951 96.0065 61.0788 96.4609C63.6231 97.9261 65.9288 100.811 67.1782 104.06L69.677 102.912C68.5866 99.7661 66.3604 96.8811 63.9297 95.4613C62.385 94.564 60.9425 94.3709 59.8748 94.9161L58.7844 95.5522V95.5635Z"
        fill="#455068"
      />
      <path
        d="M67.553 108.841C67.5416 109.977 67.3258 110.931 66.951 111.67C66.6897 112.203 66.349 112.624 65.9514 112.942C65.8492 113.033 65.747 113.101 65.6448 113.146C64.3954 113.93 62.6575 113.839 60.7266 112.703C57.0352 110.511 54.0366 105.264 54.0366 100.936C54.0366 98.8915 54.6954 97.4263 55.7744 96.6653L56.2742 96.37C56.2742 96.37 56.2969 96.3473 56.3196 96.3473C56.8308 96.1088 57.41 96.0179 58.0461 96.052C58.8866 96.0974 59.818 96.3927 60.7948 96.972C63.4526 98.5054 65.747 101.618 66.8487 104.855C67.3031 106.206 67.5643 107.558 67.553 108.841Z"
        fill="white"
      />
      <path
        d="M63.7933 114.237C62.7597 114.237 61.6125 113.884 60.4312 113.192C56.5921 110.92 53.4686 105.423 53.4686 100.936C53.4686 98.778 54.1728 97.0969 55.4563 96.1996L55.9902 95.8816C55.9902 95.8816 56.0583 95.8475 56.0924 95.8248C56.683 95.5522 57.3645 95.4273 58.0914 95.4727C59.0455 95.5295 60.0564 95.8589 61.09 96.4609C63.7933 98.017 66.2126 101.163 67.3939 104.65C67.8936 106.127 68.1435 107.524 68.1321 108.83C68.1208 110.045 67.8936 111.079 67.462 111.919C67.1894 112.487 66.7919 112.976 66.3148 113.373C66.2012 113.476 66.0536 113.578 65.9059 113.646C65.2926 114.032 64.577 114.225 63.8046 114.225L63.7933 114.237ZM56.5467 96.8584L56.0697 97.1424C55.1383 97.8011 54.6045 99.1528 54.6045 100.936C54.6045 105.059 57.4781 110.125 61.0105 112.215C62.6802 113.203 64.2249 113.362 65.338 112.669C65.4402 112.612 65.5197 112.567 65.5765 112.51C65.9514 112.215 66.2353 111.851 66.4398 111.42C66.7919 110.738 66.9736 109.864 66.985 108.83C66.985 107.66 66.7692 106.377 66.3148 105.025C65.2244 101.833 62.9528 98.8575 60.5108 97.449C59.6362 96.9379 58.7957 96.654 58.0119 96.6085C57.4554 96.5745 56.967 96.654 56.5581 96.8584H56.5467Z"
        fill="#455068"
      />
      <path d="M28.9799 71.9041L16.4745 79.1279L28.9799 86.3404L41.474 79.1279L28.9799 71.9041Z" fill="white" />
      <path
        d="M28.98 86.9083C28.8778 86.9083 28.7869 86.8856 28.696 86.8288L16.1906 79.6163C16.0202 79.5141 15.9066 79.3324 15.9066 79.1279C15.9066 78.9235 16.0202 78.7417 16.1906 78.6395L28.696 71.427C28.8664 71.3248 29.0936 71.3248 29.2639 71.427L41.7694 78.6395C41.9398 78.7417 42.0533 78.9235 42.0533 79.1279C42.0533 79.3324 41.9398 79.5141 41.7694 79.6163L29.2639 86.8288C29.1731 86.8742 29.0822 86.9083 28.98 86.9083ZM17.6104 79.1279L28.98 85.693L40.3496 79.1279L28.98 72.5628L17.6104 79.1279Z"
        fill="#455068"
      />
      <path d="M28.9799 86.3405V99.7773L16.4745 92.5648V79.128L28.9799 86.3405Z" fill="white" />
      <path
        d="M28.98 100.345C28.8778 100.345 28.7869 100.322 28.696 100.266L16.1906 93.0532C16.0202 92.951 15.9066 92.7692 15.9066 92.5648V79.128C15.9066 78.9235 16.0202 78.7418 16.1906 78.6396C16.3609 78.5373 16.5881 78.5373 16.7585 78.6396L29.2639 85.8521C29.4343 85.9543 29.5479 86.136 29.5479 86.3405V99.7773C29.5479 99.9817 29.4343 100.163 29.2639 100.266C29.1731 100.311 29.0822 100.345 28.98 100.345ZM17.0424 92.2354L28.4121 98.8005V86.6699L17.0424 80.1048V92.2354Z"
        fill="#455068"
      />
      <path d="M41.4739 79.128V92.5648L28.9799 99.7773V86.3405L41.4739 79.128Z" fill="#455068" />
      <path
        d="M28.9799 100.345C28.8777 100.345 28.7868 100.322 28.6959 100.266C28.5256 100.163 28.412 99.9817 28.412 99.7773V86.3405C28.412 86.136 28.5256 85.9543 28.6959 85.8521L41.2014 78.6396C41.3718 78.5373 41.5989 78.5373 41.7693 78.6396C41.9397 78.7418 42.0533 78.9235 42.0533 79.128V92.5648C42.0533 92.7692 41.9397 92.951 41.7693 93.0532L29.2639 100.266C29.173 100.311 29.0821 100.345 28.9799 100.345ZM29.5478 86.6699V98.8005L40.9174 92.2354V80.1048L29.5478 86.6699Z"
        fill="#455068"
      />
      <path
        d="M33.8754 76.2657C33.8754 77.5491 33.4438 78.4805 32.7509 78.9689L32.4329 79.1507L30.6497 80.1729C30.8995 79.9685 31.1267 79.6959 31.2971 79.3551C31.547 78.8781 31.6833 78.2647 31.6833 77.5378C31.6833 76.7086 31.5242 75.834 31.2289 74.9708L33.4892 73.9259C33.7391 74.7096 33.8754 75.5047 33.8754 76.2657Z"
        fill="#455068"
      />
      <path
        d="M30.6497 80.7408C30.4679 80.7408 30.2862 80.6499 30.184 80.4909C30.0136 80.2524 30.059 79.9116 30.2976 79.7299C30.502 79.5595 30.6724 79.3551 30.786 79.1052C31.0018 78.6849 31.1154 78.1625 31.1154 77.5264C31.1154 76.7995 30.9791 75.993 30.6951 75.1525C30.6042 74.8799 30.7292 74.5733 30.9904 74.4597L33.2507 73.4147C33.3984 73.3466 33.5687 73.3466 33.7164 73.4147C33.8641 73.4829 33.9776 73.6078 34.0231 73.7555C34.307 74.6187 34.4433 75.4592 34.432 76.2656C34.432 77.6854 33.9436 78.8099 33.069 79.4346L30.9223 80.6613C30.8314 80.7067 30.7405 80.7408 30.6383 80.7408H30.6497ZM31.9218 75.2775C32.149 76.0725 32.2625 76.8222 32.2512 77.5378C32.2512 77.9467 32.2058 78.3215 32.1262 78.6736L32.4556 78.4805C32.9781 78.1057 33.2962 77.3106 33.2962 76.2656C33.2962 75.7659 33.2394 75.2548 33.1144 74.7323L31.9104 75.2888L31.9218 75.2775Z"
        fill="#455068"
      />
      <path
        d="M33.4892 73.9372L31.2289 74.9821C30.5247 72.9036 29.0481 70.9045 27.333 69.9163C26.7083 69.5529 26.1063 69.3598 25.5725 69.3257C25.1636 69.303 24.7887 69.3598 24.4594 69.5188L26.3789 68.3943L26.572 68.2807C27.3443 67.8832 28.378 67.9741 29.5251 68.6328C31.297 69.6551 32.819 71.7677 33.4892 73.9258V73.9372Z"
        fill="white"
      />
      <path
        d="M31.229 75.5387C31.1495 75.5387 31.0813 75.5273 31.0018 75.4932C30.8542 75.4251 30.7406 75.3115 30.6838 75.1525C30.0023 73.1421 28.5711 71.2793 27.0491 70.3934C26.5153 70.0753 26.0042 69.905 25.5385 69.8822C25.2205 69.8709 24.9365 69.9163 24.698 70.0185C24.4254 70.1435 24.096 70.0299 23.9597 69.7687C23.8234 69.4961 23.9143 69.1667 24.1755 69.019L26.2881 67.7923C27.2877 67.2698 28.5371 67.4061 29.8092 68.1444C31.6492 69.2121 33.3075 71.4156 34.0231 73.7668C34.1026 74.0394 33.9777 74.3347 33.7164 74.4483L31.4561 75.4932C31.3766 75.5273 31.2971 75.55 31.2176 75.55L31.229 75.5387ZM26.5721 68.9395C26.9128 69.0531 27.2649 69.2121 27.6284 69.4166C29.2526 70.3479 30.7179 72.1539 31.5584 74.1984L32.7964 73.6305C32.0922 71.7223 30.7292 69.9845 29.2526 69.1212C28.3213 68.576 27.4694 68.4625 26.8447 68.7805L26.5834 68.9395H26.5721Z"
        fill="#455068"
      />
      <path
        d="M31.6833 77.5377C31.6833 78.2647 31.5357 78.878 31.2971 79.355C31.1268 79.6958 30.911 79.9684 30.6497 80.1728C30.5816 80.2296 30.5134 80.2751 30.4566 80.3091C29.6502 80.8089 28.5371 80.7521 27.299 80.0252C24.9252 78.6168 23.0056 75.2434 23.0056 72.4606C23.0056 71.1544 23.4259 70.2003 24.1301 69.7119L24.4481 69.5188C24.4481 69.5188 24.4595 69.5074 24.4822 69.5074C24.8116 69.3598 25.1864 69.2916 25.5953 69.3143C26.1405 69.3484 26.7311 69.5415 27.3558 69.905C29.0596 70.8931 30.5361 72.8922 31.2517 74.9708C31.547 75.834 31.7174 76.7086 31.706 77.5377H31.6833Z"
        fill="white"
      />
      <path
        d="M29.2753 81.2064C28.5711 81.2064 27.7874 80.9793 27.0036 80.5136C24.4367 78.9916 22.4263 75.4592 22.4263 72.4606C22.4263 71.0067 22.9033 69.8709 23.7893 69.2462L24.1414 69.0304C24.1414 69.0304 24.1982 68.9963 24.2209 68.9849C24.6298 68.7919 25.0955 68.7123 25.6066 68.7464C26.254 68.7805 26.9355 69.0077 27.6284 69.4052C29.4002 70.4274 30.9904 72.4946 31.7741 74.7777C32.1035 75.7431 32.2625 76.6745 32.2512 77.5264C32.2512 78.3328 32.0921 79.037 31.7968 79.6049C31.6037 79.9911 31.3425 80.3318 31.0018 80.6044C30.9222 80.6726 30.8087 80.7521 30.7064 80.8089C30.2862 81.0701 29.7978 81.1951 29.2753 81.1951V81.2064ZM24.7093 70.0185L24.414 70.2003C23.8801 70.5751 23.5735 71.3929 23.5735 72.4606C23.5735 75.0503 25.3794 78.2192 27.5943 79.5368C28.6052 80.1274 29.5138 80.2296 30.1612 79.8321L30.2975 79.7412C30.502 79.5708 30.6724 79.3664 30.7859 79.1165C31.0018 78.6963 31.1153 78.1738 31.1153 77.5377C31.1153 76.8108 30.979 76.0044 30.6951 75.1638C30.0136 73.1534 28.5824 71.2907 27.0604 70.4047C26.5266 70.0867 26.0155 69.9163 25.5498 69.8936C25.2318 69.8822 24.9478 69.9163 24.7093 70.0299V70.0185Z"
        fill="#455068"
      />
      <path
        d="M31.8765 110.636C31.6834 110.636 31.4903 110.534 31.3881 110.352C31.229 110.079 31.3199 109.739 31.5925 109.58L49.6976 99.1301C49.9702 98.9711 50.3109 99.062 50.4699 99.3346C50.629 99.6072 50.5381 99.9479 50.2655 100.107L32.1604 110.557C32.0696 110.613 31.9673 110.636 31.8765 110.636Z"
        fill="#B1B6C0"
      />
      <path
        d="M59.6249 47.1199V51.6859L51.0267 48.2444C49.198 47.1881 48.0622 43.4853 48.9822 41.5771L54.1388 33.8535L56.6945 37.6244L55.4791 40.1687C54.7181 41.7588 55.6609 44.8483 57.1942 45.7229L59.6249 47.1313V47.1199Z"
        fill="white"
      />
      <path
        d="M59.6248 52.2538C59.5567 52.2538 59.4772 52.2425 59.409 52.2084L50.8108 48.7669C48.6527 47.5288 47.4147 43.508 48.4596 41.3272L53.6617 33.5354C53.7526 33.3991 53.9003 33.3083 54.0593 33.2855C54.2183 33.2628 54.3887 33.3083 54.5022 33.4218C54.5363 33.4559 54.5817 33.5013 54.6045 33.5354L57.1601 37.295C57.2737 37.4654 57.285 37.6812 57.2055 37.8629L55.9902 40.4072C55.3541 41.7361 56.1946 44.4848 57.4781 45.2344L59.9088 46.6429C60.0791 46.7451 60.1927 46.9268 60.1927 47.1313V51.6973C60.1927 51.8904 60.1019 52.0607 59.9428 52.163C59.852 52.2311 59.7384 52.2652 59.6248 52.2652V52.2538ZM54.1388 34.8643L49.4478 41.8951C48.6982 43.4739 49.7318 46.8473 51.3106 47.756L59.0569 50.8568V47.4607L56.9102 46.2226C55.1269 45.189 54.082 41.7815 54.9679 39.9301L56.0356 37.6812L54.1274 34.8757L54.1388 34.8643Z"
        fill="#455068"
      />
      <path
        d="M65.5426 32.5245L57.6145 37.1019L56.7059 37.6244L56.6945 37.613L54.1389 33.8534L62.9756 28.7536L65.5426 32.5245Z"
        fill="white"
      />
      <path
        d="M56.7058 38.1923C56.5581 38.1923 56.4105 38.1355 56.3083 38.0219C56.2856 37.9992 56.2515 37.9651 56.2288 37.931L53.6732 34.1714C53.5823 34.0465 53.5482 33.8761 53.5936 33.7285C53.6277 33.5694 53.7299 33.4445 53.8662 33.365L62.703 28.2651C62.9528 28.1175 63.2822 28.197 63.4526 28.4355L66.0196 32.2065C66.1104 32.3314 66.1445 32.5018 66.0991 32.6494C66.0537 32.7971 65.9628 32.9334 65.8265 33.0129L56.9784 38.1128C56.8875 38.1582 56.7967 38.1923 56.6945 38.1923H56.7058ZM54.9566 34.0465L56.8762 36.8747L64.7361 32.3428L62.8052 29.5145L54.9566 34.0465Z"
        fill="#455068"
      />
      <path
        d="M68.4844 42.0315L63.8048 44.7234L59.6249 47.12L57.1943 45.7116C56.6831 45.4163 56.2288 44.8711 55.8994 44.2123L64.827 39.2487C65.145 39.8393 65.5767 40.3278 66.0537 40.6117L68.4844 42.0201V42.0315Z"
        fill="white"
      />
      <path
        d="M59.6249 47.6879C59.5227 47.6879 59.4318 47.6652 59.341 47.6084L56.9103 46.2C56.3197 45.8592 55.7972 45.2572 55.3996 44.4735C55.2634 44.2009 55.3656 43.8715 55.6268 43.7239L64.5544 38.7603C64.6907 38.6921 64.8384 38.6694 64.986 38.7149C65.1337 38.7603 65.2586 38.8512 65.3268 38.9875C65.6107 39.5099 65.9628 39.9075 66.3263 40.1233L68.757 41.5431C68.9273 41.6453 69.0409 41.827 69.0409 42.0315C69.0409 42.2359 68.9273 42.4177 68.757 42.5199L59.9089 47.6084C59.818 47.6538 59.7271 47.6879 59.6249 47.6879ZM56.6831 44.4394C56.9217 44.7915 57.1943 45.0641 57.4782 45.2231L59.6249 46.4612L67.3372 42.0201L65.7584 41.1001C65.3381 40.8616 64.9519 40.4868 64.6226 40.0097L56.6831 44.4281V44.4394Z"
        fill="#455068"
      />
      <path
        d="M64.827 39.26L55.8994 44.2235C55.2292 42.9287 54.9794 41.2136 55.4791 40.1573L56.6944 37.613L57.6031 37.1019L65.5425 32.5245L64.3158 35.0688C63.7934 36.1592 64.0773 37.9651 64.8156 39.26H64.827Z"
        fill="#455068"
      />
      <path
        d="M55.8993 44.7915C55.8993 44.7915 55.7858 44.7915 55.7403 44.7688C55.5927 44.7233 55.4677 44.6211 55.3996 44.4848C54.7408 43.224 54.3319 41.2591 54.968 39.9074L56.1833 37.3632C56.2514 37.2269 56.365 37.1246 56.5127 37.0679L65.2699 32.0248C65.4857 31.8998 65.7583 31.9339 65.94 32.1043C66.1218 32.2747 66.1786 32.5473 66.065 32.7631L64.8383 35.3073C64.418 36.1706 64.6338 37.7494 65.3153 38.976C65.3948 39.1123 65.4062 39.26 65.3721 39.4077C65.3381 39.5553 65.2358 39.6803 65.0995 39.7484L56.1719 44.712C56.0811 44.7574 55.9902 44.7801 55.8993 44.7801V44.7915ZM57.126 38.0333L56.0016 40.4072C55.6608 41.1228 55.7403 42.3381 56.1719 43.4285L64.0886 39.0215C63.5207 37.7494 63.2368 36.0456 63.8274 34.8189L64.259 33.9216L57.1374 38.0219L57.126 38.0333Z"
        fill="#455068"
      />
      <path d="M68.4844 42.0315V46.5975L59.6364 51.6974L59.625 47.12L63.8048 44.7234L68.4844 42.0315Z" fill="#455068" />
      <path
        d="M59.6364 52.2653C59.5342 52.2653 59.4433 52.2426 59.3524 52.1858C59.1821 52.0836 59.0685 51.9018 59.0685 51.6974V47.1314C59.0685 46.9269 59.1707 46.7338 59.3411 46.643L68.1892 41.5545C68.3709 41.4522 68.5867 41.4522 68.7571 41.5545C68.9388 41.6567 69.041 41.8384 69.041 42.0429V46.6089C69.041 46.8133 68.9275 46.9951 68.7571 47.0973L59.909 52.1971C59.8181 52.2426 59.7273 52.2767 59.625 52.2767L59.6364 52.2653ZM60.1929 47.4494V50.7092L67.9052 46.2568V43.0083L60.1929 47.4494Z"
        fill="#455068"
      />
      <path
        d="M71.7102 59.3983L71.6647 59.421C71.2672 59.6595 70.7788 59.6595 70.2222 59.3301L61.624 52.8445V48.2785L64.066 49.687C65.5881 50.5729 66.5422 48.5852 65.7811 46.1091L64.5658 42.1564L67.1328 41.3613L68.4049 44.7347L72.2894 55.048C72.9028 57.0698 72.5734 58.819 71.7215 59.3983H71.7102Z"
        fill="white"
      />
      <path
        d="M71.0741 60.1593C70.7107 60.1593 70.3245 60.0457 69.9383 59.8299L61.272 53.2989C61.1243 53.1967 61.0448 53.0263 61.0448 52.8446V48.2785C61.0448 48.0741 61.147 47.8924 61.3288 47.7901C61.4991 47.6879 61.7149 47.6879 61.8967 47.7901L64.3387 49.1986C64.759 49.4484 64.9407 49.3008 65.0088 49.244C65.4518 48.8919 65.6563 47.6879 65.2246 46.2795L63.998 42.3268C63.9071 42.0315 64.0775 41.7135 64.3728 41.6226L66.9397 40.8275C67.2237 40.7366 67.5304 40.8843 67.644 41.1683L72.812 54.855C73.4821 57.0585 73.1641 59.1143 72.0282 59.8753C72.0055 59.8867 71.9828 59.898 71.9714 59.9094L71.926 59.9321C71.6648 60.0798 71.3808 60.1593 71.0741 60.1593ZM71.3695 58.9326L71.7102 59.3983L71.3922 58.9326C71.9942 58.5237 72.2895 57.0244 71.7329 55.2184L66.7694 42.0656L65.2474 42.5313L66.3037 45.9387C66.8602 47.722 66.6217 49.403 65.713 50.1186C65.1678 50.5502 64.4523 50.5729 63.7594 50.164L62.1693 49.244V52.5492L70.5517 58.8644C71.0174 59.1257 71.2786 58.9667 71.3695 58.9212V58.9326Z"
        fill="#455068"
      />
      <path d="M75.9581 36.2501L67.1101 41.3499L64.5431 42.145L73.3912 37.0451L75.9581 36.2501Z" fill="white" />
      <path
        d="M64.5431 42.7129C64.3273 42.7129 64.1115 42.588 64.0206 42.3722C63.9071 42.1109 64.0093 41.7929 64.2592 41.6566L73.1073 36.5568C73.1073 36.5568 73.1868 36.5227 73.2208 36.5113L75.7878 35.7049C76.0604 35.614 76.3557 35.7503 76.4807 36.0229C76.5942 36.2842 76.492 36.6022 76.2421 36.7385L67.3941 41.8497C67.3941 41.8497 67.3145 41.8838 67.2805 41.8951L64.7135 42.6902C64.7135 42.6902 64.5999 42.7129 64.5431 42.7129Z"
        fill="#455068"
      />
      <path
        d="M81.1148 49.9368L75.9582 36.2501L67.1101 41.3613L72.2781 55.048C72.8915 57.0698 72.5621 58.8189 71.7102 59.3982L80.3879 54.3778L80.5356 54.2983C81.4102 53.7304 81.7509 51.9813 81.1262 49.9368H81.1148Z"
        fill="#455068"
      />
      <path
        d="M71.7102 59.9661C71.5171 59.9661 71.3354 59.8753 71.2331 59.7049C71.0741 59.4437 71.1423 59.1029 71.3922 58.9325C71.9942 58.5236 72.2895 57.0243 71.7329 55.2184L66.5763 41.5657C66.474 41.3045 66.5763 41.0092 66.8261 40.8729L75.6742 35.7617C75.8219 35.6822 75.9923 35.6594 76.1513 35.7162C76.3103 35.773 76.4352 35.898 76.492 36.0456L81.6487 49.7323C81.6487 49.7323 81.6487 49.7551 81.6487 49.7664C82.3302 51.9699 81.9894 54.0258 80.8309 54.7754L80.6491 54.8776L71.9828 59.898C71.8919 59.9548 71.8011 59.9775 71.6988 59.9775L71.7102 59.9661ZM67.8143 41.6112L72.8119 54.8436C73.1527 55.968 73.2436 57.0471 73.0959 57.9444L80.263 53.7986C80.8422 53.4238 81.1376 51.9358 80.5924 50.1185L75.6742 37.0679L67.8257 41.5998L67.8143 41.6112Z"
        fill="#455068"
      />
      <path
        d="M64.0547 49.6757L61.6127 48.2673L65.7698 46.0978C66.5308 48.5739 65.5767 50.5616 64.0547 49.6757Z"
        fill="white"
      />
      <path
        d="M64.759 50.4594C64.4409 50.4594 64.1002 50.3685 63.7708 50.1754L61.3288 48.767C61.147 48.6648 61.0448 48.4717 61.0448 48.2672C61.0448 48.0628 61.1697 47.8697 61.3515 47.7788L65.5086 45.6094C65.6563 45.5299 65.838 45.5185 65.9856 45.5867C66.1447 45.6548 66.2582 45.7798 66.315 45.9388C66.8716 47.722 66.6331 49.4031 65.7244 50.1186C65.4404 50.3458 65.1111 50.4594 64.7703 50.4594H64.759ZM62.794 48.3013L64.3387 49.1872C64.7476 49.4258 64.9407 49.2895 65.0088 49.2327C65.3837 48.9374 65.5767 48.0514 65.3837 46.9383L62.794 48.2899V48.3013Z"
        fill="#455068"
      />
      <path
        d="M61.5217 33.331L57.694 35.5572L58.9207 33.0243C59.4886 31.8544 60.6471 32.1384 61.5331 33.3423L61.5217 33.331Z"
        fill="#455068"
      />
      <path
        d="M57.6939 36.1251C57.5576 36.1251 57.4099 36.0683 57.3077 35.9774C57.1259 35.8071 57.0692 35.5345 57.1827 35.3187L58.4094 32.7858C58.7161 32.1611 59.2045 31.7862 59.7838 31.7295C60.5448 31.6613 61.3399 32.127 61.9873 33.0129C62.0782 33.1379 62.1236 33.3083 62.0895 33.4673C62.0554 33.6263 61.9532 33.7626 61.8169 33.8421L57.9892 36.0683C57.8983 36.1251 57.7961 36.1478 57.7052 36.1478L57.6939 36.1251ZM59.9201 32.8426C59.9201 32.8426 59.8974 32.8426 59.886 32.8426C59.7043 32.8539 59.5566 33.0016 59.4317 33.2628L59.0114 34.1374L60.6357 33.1947C60.3517 32.9448 60.0905 32.8426 59.9201 32.8426Z"
        fill="#455068"
      />
      <path
        d="M66.1218 38.1467L63.5548 38.9532L62.3395 35.0005C62.135 34.3417 61.851 33.7738 61.5217 33.3422C60.6357 32.1496 59.4772 31.8656 58.9093 33.0242L57.6826 35.5571L55.1156 31.7861L58.5572 21.5523C58.7048 21.2457 58.8865 21.0185 59.091 20.8822L59.2614 20.7913C60.3404 20.2575 61.9419 21.575 62.6688 23.9376L66.1104 38.1581L66.1218 38.1467Z"
        fill="white"
      />
      <path
        d="M63.5548 39.5097C63.464 39.5097 63.3731 39.487 63.2936 39.4416C63.1573 39.3734 63.0551 39.2485 63.021 39.1009L61.8057 35.1482C61.6239 34.5689 61.374 34.0578 61.0787 33.6602C60.6017 33.0128 60.1246 32.8084 59.8975 32.8311C59.7157 32.8424 59.5681 32.9901 59.4431 33.2513L58.2164 35.7842C58.1256 35.966 57.9552 36.0909 57.7508 36.1023C57.5463 36.125 57.3532 36.0228 57.2396 35.8524L54.6727 32.0814C54.5704 31.9338 54.5477 31.7521 54.6045 31.5817L58.0461 21.3479C58.2732 20.8708 58.5118 20.5869 58.7957 20.3824L59.0115 20.2575C59.5226 19.9963 60.0678 19.9963 60.6358 20.2461C61.7261 20.7118 62.7257 22.0407 63.2368 23.7218L66.6897 37.9764C66.7579 38.2717 66.5875 38.567 66.3035 38.6465L63.7366 39.453C63.7366 39.453 63.623 39.4757 63.5662 39.4757L63.5548 39.5097ZM59.9202 31.7066C60.6244 31.7066 61.3627 32.1723 61.976 32.9901C62.3509 33.4899 62.6575 34.1032 62.8847 34.8188L63.9297 38.2263L65.4517 37.7492L62.135 24.0512C61.6353 22.4383 60.7721 21.5637 60.1814 21.3138C59.9315 21.2116 59.7157 21.2002 59.534 21.2911L59.3977 21.3706C59.3182 21.4274 59.1933 21.5864 59.091 21.8022L55.7744 31.6953L57.6258 34.4099L58.4209 32.7629C58.7276 32.1382 59.216 31.7634 59.7952 31.7066C59.8407 31.7066 59.8975 31.7066 59.9429 31.7066H59.9202Z"
        fill="#455068"
      />
      <path
        d="M74.9699 33.0355L66.1218 38.1353L62.6802 23.9148C62.5212 23.4037 62.3168 22.938 62.0896 22.5291L70.9718 17.5087C71.1762 17.8949 71.3693 18.3379 71.517 18.8149L74.9585 33.0355H74.9699Z"
        fill="#455068"
      />
      <path
        d="M66.1218 38.7146C66.0423 38.7146 65.9742 38.7033 65.8947 38.6692C65.7356 38.601 65.6107 38.4534 65.5653 38.283L62.1237 24.0625C61.9874 23.6422 61.8057 23.2106 61.5899 22.8131C61.4422 22.5405 61.5331 22.1997 61.8057 22.0407L70.6878 17.0203C70.8241 16.9408 70.9832 16.9295 71.1195 16.9749C71.2671 17.0203 71.3921 17.1112 71.4602 17.2475C71.6874 17.6678 71.8805 18.1334 72.0508 18.6559L75.5037 32.9105C75.5605 33.1604 75.4583 33.4103 75.2311 33.5353L66.3831 38.6351C66.2922 38.6805 66.2013 38.7146 66.0991 38.7146H66.1218ZM62.8393 22.7676C62.9869 23.0857 63.1119 23.415 63.2141 23.7558L66.4853 37.2721L74.2998 32.7629L70.9491 18.9626C70.8809 18.7468 70.8014 18.5196 70.7106 18.3152L62.8279 22.7676H62.8393Z"
        fill="#455068"
      />
      <path
        d="M70.9832 17.5087L62.101 22.5291C61.2946 21.0639 60.136 20.3597 59.2842 20.7799L68.1209 15.6801C68.9841 15.2484 70.1654 15.9981 70.9832 17.5087Z"
        fill="white"
      />
      <path
        d="M62.0897 23.097C61.8852 23.097 61.6808 22.9834 61.5899 22.8017C60.9652 21.6658 60.0793 21.0071 59.5227 21.2797C59.2501 21.416 58.9094 21.3137 58.7731 21.0411C58.6254 20.7685 58.7276 20.4392 58.9889 20.2801L67.8256 15.1916C69.0069 14.5896 70.5062 15.4415 71.4716 17.2475C71.6193 17.5201 71.517 17.8608 71.2558 18.0085L62.3736 23.0288C62.2828 23.0743 62.1919 23.097 62.0897 23.097ZM60.9766 20.4505C61.4423 20.7345 61.8852 21.1774 62.2828 21.7681L70.1881 17.3043C69.5293 16.3502 68.7911 15.9754 68.3708 16.1912L60.9766 20.4619V20.4505Z"
        fill="#455068"
      />
      <path
        d="M41.3264 32.2065L45.4722 29.6395C45.6426 29.5373 45.8584 29.5259 46.0515 29.6395L50.413 32.1497C50.6515 32.286 50.7538 32.5586 50.6856 32.8198L49.209 37.8742C49.1523 38.0673 49.2431 38.2604 49.4135 38.3626L51.6284 39.5666C52.0713 39.8051 52.219 40.3617 51.9691 40.8047L47.4712 48.3125L42.9734 51.0952L47.5962 43.4738L47.3917 42.5993L44.8248 41.2136L44.7793 40.5321L46.1196 35.5117L46.0515 35.1028L41.3264 32.2178V32.2065Z"
        fill="#455068"
      />
      <path
        d="M49.232 38.1469L44.7114 40.8956L47.5964 42.7811L52.0602 40.0892L51.6286 39.5667L49.232 38.1469Z"
        fill="white"
      />
      <path
        d="M41.3264 32.2065L43.4731 30.8889L45.6653 29.5714L46.0628 29.56L50.538 32.1838L50.7878 32.5131L46.0742 35.2391L45.9152 34.9438L41.3264 32.2065Z"
        fill="white"
      />
      <path
        d="M41.5651 32.4223L45.9267 34.9325C46.1652 35.0688 46.2674 35.3414 46.1993 35.6026L44.7227 40.6571C44.6659 40.8501 44.7568 41.0432 44.9272 41.1455L47.142 42.3494C47.585 42.588 47.7327 43.1445 47.4828 43.5875L42.9849 51.0953C42.8373 51.3452 42.4624 51.2089 42.5079 50.9249L43.4733 44.4394C43.496 44.269 43.4165 44.0986 43.2689 44.0077L41.1562 42.8151C40.8836 42.6561 40.7133 42.3608 40.7133 42.0428V32.9334C40.7133 32.4791 41.2017 32.2065 41.5878 32.4337L41.5651 32.4223Z"
        fill="white"
      />
      <path
        d="M42.7577 51.7881C42.6668 51.7881 42.5646 51.7768 42.4737 51.7427C42.0989 51.6064 41.8717 51.2316 41.9399 50.8454L42.8826 44.4621L40.8608 43.3149C40.4065 43.0536 40.1226 42.5766 40.1226 42.0541V32.9448C40.1226 32.5245 40.3384 32.1497 40.7018 31.9453C41.0653 31.7408 41.4969 31.7408 41.849 31.9453L46.1992 34.4554C46.6649 34.7167 46.8807 35.2619 46.7331 35.773L45.2906 40.7025L47.4032 41.8497C47.7553 42.0427 48.0052 42.3608 48.1074 42.747C48.2096 43.1331 48.1529 43.5307 47.9484 43.8828L43.4505 51.3906C43.2915 51.6518 43.0303 51.7995 42.7463 51.7995L42.7577 51.7881ZM41.2697 32.9107V42.0314C41.2697 42.145 41.3265 42.2472 41.4288 42.304L43.5414 43.4966C43.8935 43.7011 44.0866 44.0986 44.0298 44.4961L43.3029 49.4483L47.0057 43.2694C47.0625 43.1672 47.0511 43.0764 47.0397 43.0196C47.0284 42.9741 46.9943 42.8833 46.8807 42.8265L44.6659 41.6225C44.257 41.4067 44.0639 40.9183 44.1888 40.4753L45.6654 35.4209L41.3038 32.888L41.2697 32.9107Z"
        fill="#455068"
      />
      <path
        d="M43.2687 51.5723L42.6667 50.6068L47.051 47.8922L51.4693 40.498C51.5261 40.3958 51.5148 40.3049 51.5034 40.2481C51.4921 40.2027 51.458 40.1118 51.3444 40.055L49.1295 38.851C48.7206 38.6352 48.5275 38.1468 48.6525 37.7039L50.1291 32.6494L45.7675 30.1165L41.2242 32.922L40.6222 31.9566L45.1655 29.1397C45.5403 28.9239 45.9719 28.9239 46.3354 29.1397L50.697 31.6499C51.1627 31.9111 51.3785 32.4563 51.2308 32.9675L49.7883 37.8969L51.9009 39.0441C52.2531 39.2372 52.5029 39.5553 52.6052 39.9414C52.7074 40.3276 52.6506 40.7252 52.4461 41.0659L47.8801 48.6987L43.2573 51.5496L43.2687 51.5723Z"
        fill="#455068"
      />
    </svg>
  );
}
