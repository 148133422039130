import { SlackOrMsTeamsWorkspaceSection } from './slack-workspace-section';
import { ConnectionSection } from './workflow-connection-section';
import { WorkflowConnectionSectionProps } from './workflow-connection-section/types';

export const SlackWorkspaceOrWorkflowConnectionSection = ({
  label,
  integration,
  onConnectionChange,
  connectionIdValue,
  workflow,
}: WorkflowConnectionSectionProps) => {
  return integration?.type === 'slack' || integration?.type === 'ms-teams' ? (
    <SlackOrMsTeamsWorkspaceSection integration={integration} />
  ) : (
    <ConnectionSection
      type="schema-selected-with-integration"
      label={label}
      integration={integration}
      onConnectionChange={onConnectionChange}
      connectionIdValue={connectionIdValue}
      workflow={workflow}
    />
  );
};
