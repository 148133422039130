import { useParams } from '@novaera/route';
import { ReactNode } from 'react';
import { useIsAppFree } from './use-free-app-permission-boundary';

export interface FreeAppPermissionBoundaryProps {
  appId?: string;
  showWhenTruthy?: boolean;
  Fallback?: ReactNode;
}

export const FreeAppPermissionBoundary: React.FC<React.PropsWithChildren<FreeAppPermissionBoundaryProps>> = ({
  appId,
  children,
  showWhenTruthy = false,
  Fallback,
}) => {
  const { userAppId } = useParams();
  const { isAppFree } = useIsAppFree({ userAppId: appId ?? userAppId });
  if (showWhenTruthy && isAppFree) {
    return children;
  } else if (showWhenTruthy) {
    return Fallback ?? null;
  }

  if (isAppFree) {
    return Fallback ?? null;
  } else {
    return children;
  }
};
