import { StringValue, useListDocs } from '@novaera/actioner-service';
import {
  LabelValue,
  NvArticleIcon,
  NvAutocomplete,
  NvConditionalRender,
  NvDivider,
  NvFlex,
  NvFolderRounded,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useGetWorkflowQueryParams } from '../../../../../../controllers/use-get-workflow-query-params';
import { FilterLayout } from '../filter-layout';
import { FilterLayoutProps } from '../filter-layout/types';

export const DocItemFilter = ({
  onApplyClicked,
  onRemoveClicked,
  mode,
  value,
  selectedDocs,
}: Pick<FilterLayoutProps<LabelValue<string>>, 'onApplyClicked' | 'onRemoveClicked' | 'mode'> & {
  value?: StringValue;
  selectedDocs?: string[];
}) => {
  const theme = useTheme();
  const { userAppId } = useGetWorkflowQueryParams();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(searchKeyword, 500);
  const { data } = useListDocs({
    appId: userAppId,
    queryParams: {
      namePrefix: debouncedKeyword,
    },
  });

  const docs = useMemo(() => data?.pages.map((page) => page.documents).flat() ?? [], [data?.pages]);

  return (
    <FilterLayout<LabelValue<string>> onApplyClicked={onApplyClicked} onRemoveClicked={onRemoveClicked} mode={mode}>
      {({ onValueChanged }) => {
        return (
          <>
            <NvConditionalRender when={mode === 'edit'}>
              <NvAutocomplete<LabelValue<string>, false, true>
                disableClearable={true}
                options={docs
                  .map((doc) => ({
                    label: doc.name,
                    value: doc.id,
                  }))
                  .filter((docs) => !selectedDocs?.includes(docs.value))}
                onInputChange={(event, value) => {
                  setSearchKeyword(value);
                }}
                size="small"
                getOptionLabel={(option) => {
                  return `${option.label}` ?? '';
                }}
                onChange={(event, value) => {
                  onValueChanged(value);
                }}
                renderInput={(params) => (
                  <NvTextField
                    {...params}
                    placeholder="Add docs"
                    startIcon={<NvArticleIcon sx={{ width: '16px', height: '16px' }} />}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    size="small"
                  />
                )}
              />
            </NvConditionalRender>
            <NvConditionalRender when={mode === 'display'}>
              <NvFlex flexDirection={'row'} gap={'8px'} alignItems={'center'}>
                <NvTypography textColor="subtle" variant="h7">
                  NAME
                </NvTypography>
                <NvDivider
                  orientation="vertical"
                  sx={{
                    height: '12px',
                    color: theme.palette.nv_neutral[30],
                  }}
                />
                <NvFlex flexDirection={'row'} gap={'6px'}>
                  <NvFolderRounded sx={{ width: '16px', height: '16px' }} />
                  <NvTypography variant="h5">{value?.displayValue}</NvTypography>
                </NvFlex>
              </NvFlex>
            </NvConditionalRender>
          </>
        );
      }}
    </FilterLayout>
  );
};
