import { useGetWorkflowExecutionOutputResult } from '@novaera/actioner-service';
import { NvSkeleton, SectionMessage } from '@novaera/core';
import { FC, PropsWithChildren } from 'react';
import { ChartOutputProps } from './types';

export const ChartOutput: FC<PropsWithChildren<ChartOutputProps>> = ({
  alias,
  executionIdentifier,
  userAppId,
  workflowId,
}) => {
  const { data, isLoading } = useGetWorkflowExecutionOutputResult({
    userAppId,
    workflowId,
    executionIdentifier,
    outputAlias: alias,
  });
  return isLoading ? (
    <NvSkeleton variant="rectangular" width="100%" height="305px" />
  ) : data?.type === 'failed-output' ? (
    <SectionMessage variant="error" message={data.error} />
  ) : data?.type === 'chart-output' && data.link ? (
    <img src={data.link} alt="chart graphic" />
  ) : (
    <SectionMessage variant="error" message={'Cannot draw chart'} />
  );
};
