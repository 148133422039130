import { Autocomplete } from '@mui/material';
import { styled } from '@novaera/theme-provider';

export const NvTagsAutoCompleteWrapper = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInput-root {
        flex-wrap: wrap;
        gap: 4px;
        justify-content: flex-start;

        &.MuiInputBase-multiline {
          padding: 4px 6px;
        }

        .nv-chip {
          max-width: 100%;
        }

        .MuiInput-input {
          width: 0;
          min-width: 30px;
          flex-grow: 1;
          text-overflow: ellipsis;
          padding: 0;
        }

        .MuiAutocomplete-endAdornment {
          height: 16px !important;
        }

        &.MuiInputBase-sizeSmall {
          height: auto;
          min-height: 24px;
          padding: 1px 40px 1px 12px;

          .MuiAutocomplete-endAdornment {
            position: absolute;
            height: 24px;
            top: calc(50% - 4px);
            right: 6px;
          }
        }

        &.MuiInputBase-sizeMedium {
          height: auto;
          min-height: 32px;
          padding: 5px 40px 5px 12px;

          .MuiAutocomplete-endAdornment {
            position: absolute;
            height: 32px;
            top: calc(50% - 8px);
            right: 6px;
          }
        }

        &.MuiInputBase-sizeLarge {
          height: auto;
          min-height: 40px;
          padding: 9px 40px 9px 12px;

          .MuiAutocomplete-endAdornment {
            position: absolute;
            height: 40px;
            top: calc(50% - 12px);
            right: 8px;
          }
        }

        .MuiAutocomplete-endAdornment {
          position: initial;
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          min-width: 0;

          .MuiAutocomplete-clearIndicator {
            background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
            height: 16px;
            width: 16px;
            border-radius: 8px;

            .MuiSvgIcon-root {
              height: 12px;
              width: 12px;
            }

            &:hover {
              background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
            }

            &:active {
              background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
            }
          }
        }
      }
    }
  }
`;
