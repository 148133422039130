export enum StepLabelState {
  NOT_COMPLETED = 'not-completed',
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export enum StepLabelVariant {
  FIRST = 'first',
  MIDDLE = 'middle',
  END = 'end',
}

export interface StepLabelProps {
  index: number;
  stepLength: number;
  label: string;
  variant: StepLabelVariant;
  state: StepLabelState;
  onStepClick: () => void;
}
