import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AppDirectoryContainer = styled(NvFlex, { shouldForwardProp: (prop) => prop !== 'isPublic' })<{
  isPublic?: boolean;
}>`
  position: relative;
  height: 100%;
  width: ${({ isPublic }) => (isPublic ? 'calc(100% + 64px)' : '100%')};
  ${({ isPublic }) => (isPublic ? 'margin-left:-64px;' : null)}
  overflow: auto;
`;
