import classnames from 'classnames';
import { isString } from 'lodash';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { ImageBox, ImageWrapper, SkeletonWrapper, StyledImage } from './styled';
import { ProfileImageProps } from './types';

export const NvImage: React.FC<React.PropsWithChildren<ProfileImageProps>> = React.memo(
  ({
    size,
    className,
    hasBlurEffect,
    imageShape,
    FallBack,
    fallbackImageHasBlurEffect,
    src,
    hasNoElevation,
    noBorderRadius,
    ...props
  }) => {
    const [hasError, setHasError] = useState<boolean>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const showFallbackComponent = useMemo(() => {
      return (hasError || !src) && Boolean(FallBack) && React.isValidElement(FallBack);
    }, [FallBack, hasError, src]);

    const handleFallBack = (error: SyntheticEvent<HTMLImageElement, Event>) => {
      if (FallBack && isString(FallBack) && !hasError) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error.target as any).onerror = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error.target as any).src = FallBack;
      }
      setHasError(true);
      setIsLoaded(true);
    };

    const handleOnLoad = () => {
      setHasError(false);
      setIsLoaded(true);
    };

    const imageClass = useMemo(() => {
      return classnames(className, {
        'square-image': imageShape === 'square',
        'size-smaller': size === 'smaller',
        'size-small': size === 'small',
        'size-medium': size === undefined || size === 'medium',
        'size-large': size === 'large',
        'size-xlarge': size === 'xlarge',
        'size-xsmall': size === 'xsmall',
        'has-redirect': props.onChange != null,
        'no-border-radius': noBorderRadius,
      });
    }, [className, imageShape, noBorderRadius, props.onChange, size]);

    return showFallbackComponent && !isString(FallBack) ? (
      fallbackImageHasBlurEffect ? (
        <ImageWrapper>
          <ImageBox className={imageClass} hasNoElevation={hasNoElevation} {...props}>
            {FallBack}
          </ImageBox>
        </ImageWrapper>
      ) : (
        FallBack
      )
    ) : (
      <ImageWrapper>
        <ImageBox className={imageClass} hasNoElevation={hasNoElevation}>
          <StyledImage
            className={classnames({
              'is-loading': !isLoaded,
            })}
            onError={handleFallBack}
            onLoad={handleOnLoad}
            src={src}
            {...props}
          />
          {!isLoaded && <SkeletonWrapper height="100%" />}
        </ImageBox>
      </ImageWrapper>
    );
  },
  (prevProp, nextProp) =>
    (prevProp.src === nextProp.src || nextProp.src === '') && prevProp.FallBack === nextProp.FallBack
);
