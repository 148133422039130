import { JobRecurrenceEndType } from '@novaera/actioner-service';
import {
  NvDatePicker,
  NvDivider,
  NvField,
  NvFlex,
  NvNumberInput,
  NvSelect,
  NvTypography,
  isRequired,
  useForm,
} from '@novaera/core';
import { format, isValid } from 'date-fns';
import { RECURRENCE_END_OPTIONS } from '../../../constants';
import { EndConfigurationProps } from './types';

export const EndConfiguration: React.FC<EndConfigurationProps> = ({ end }) => {
  const { change } = useForm();
  return (
    <NvFlex direction="row" gap="24px">
      <NvFlex direction="row" alignItems="center" gap="8px">
        <NvTypography flex="0 0 auto" variant="h5">
          Ends
        </NvTypography>
        <NvField
          name="recurrence.end.type"
          component={({ value, onChange }) => (
            <NvSelect
              value={value}
              onChange={(event) => {
                onChange(event);
                change('recurrence.end', { type: event.target.value });
              }}
              options={RECURRENCE_END_OPTIONS}
            />
          )}
        />
      </NvFlex>

      {end.type && end.type !== JobRecurrenceEndType.NEVER && (
        <NvDivider orientation="vertical" sx={{ margin: '8px 0', height: '16px' }} />
      )}
      {end.type && end.type === JobRecurrenceEndType.DATE_TIME && (
        <NvFlex direction="row" alignItems="center" gap="8px">
          <NvTypography flex="0 0 auto" variant="h5">
            End date
          </NvTypography>
          <NvField
            name="recurrence.end.at"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parse={(value: any) => {
              if (isValid(value)) {
                return format(value, 'yyyy/MM/dd');
              } else {
                return value;
              }
            }}
            showError={false}
            showErrorMessageOnlyWhenBlur
            validators={[isRequired()]}
            component={<NvDatePicker mask="____-__-__" inputFormat="yyyy-MM-dd" disablePast />}
          />
        </NvFlex>
      )}
      {end.type && end.type === JobRecurrenceEndType.NUMBER_OF_TIMES && (
        <NvFlex direction="row" alignItems="center" gap="8px">
          <NvTypography flex="0 0 auto" variant="h5">
            Occurrence count
          </NvTypography>
          <NvField
            name="recurrence.end.times"
            defaultValue={1}
            showError={false}
            showErrorMessageOnlyWhenBlur
            validators={[isRequired()]}
            component={<NvNumberInput />}
          />
        </NvFlex>
      )}
    </NvFlex>
  );
};
