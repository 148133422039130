import { ConnectionMapping, useGetUserApp } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { ConnectionStepItem } from './connection-step-item';

export const ConnectionStep: FC<React.PropsWithChildren<{ connections?: ConnectionMapping[]; appId: string }>> = ({
  appId,
  connections,
}) => {
  const { data: userApp } = useGetUserApp(appId);
  return (
    <NvFlex gap="16px">
      <NvFlex gap="4px">
        <NvTypography variant="h2">Connections</NvTypography>
        <NvTypography variant="body1">
          You need to connect the tools listed below, as this is essential for the automated workflows of{' '}
          <b>{userApp?.name ?? 'this app'}</b>.
        </NvTypography>
      </NvFlex>
      <NvFlex gap="8px">
        {connections?.map((connection) => (
          <ConnectionStepItem key={`connection_step_item_${connection.schemaId}`} connection={connection} />
        ))}
      </NvFlex>
    </NvFlex>
  );
};
