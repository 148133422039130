import { ParameterMapping, ParameterTypes, UIComponentType } from '@novaera/actioner-service';
import { NvField, NvMenuWithItems, NvSettingsIcon, NvSideGroupedInputLayout, useField } from '@novaera/core';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSearchAsYouTypeValuesContext } from '../../../../action-designer/providers/search-as-you-type-values';
import { useUIComponents } from '../../../ui-components/use-ui-component';
import { isDisplayValueComponent } from '../../../ui-components/use-ui-component/types';
import { calculateIsScripted } from '../../utils';
import { ParameterMappingSettingsMenu } from './settings-menu';
import { ValueDisplayGroupWrapper } from './styled';
import { ParameterMapperItemProps } from './types';

export const ParameterMapperItem: FC<React.PropsWithChildren<ParameterMapperItemProps>> = ({
  inputParameter,
  getOptionsResponse,
  getDynamicInputParameters,
  setInputParameterIdsShowingOptions,
  hideLabel,
  context,
  isDisplayValueConfigurable,
  showHiddenConfig = false,
  isForceHideSwitch = false,
  onScriptedChange,
  getDynamicInputComponentState,
  otherProps,
}) => {
  const {
    input: { value, onChange },
  } = useField<ParameterMapping>(inputParameter.id);
  const { setSearchAsYouTypeValues } = useSearchAsYouTypeValuesContext();

  const [isScripted, setIsScripted] = useState(() => {
    if (isForceHideSwitch) {
      return false;
    }
    return calculateIsScripted(inputParameter, value);
  });

  const handleSearchAsYouTypeValueUpdate = useCallback(
    ({ value, inputParameterId }: { value: string; inputParameterId: string }) => {
      setSearchAsYouTypeValues((prev) => ({ ...prev, [inputParameterId]: value }));
    },
    [setSearchAsYouTypeValues]
  );

  const { componentFieldProps, getComponentForField } = useUIComponents({
    inputParameter,
    isScripted,
    isDisplayValueConfigurable,
    hidden: value.type === ParameterTypes.SIMPLE && value.hidden,
    getOptionsResponse,
    getDynamicInputResponse: getDynamicInputParameters,
    setInputParameterIdsShowingOptions,
    onSearchAsYouTypeValueUpdate: handleSearchAsYouTypeValueUpdate,
    dynamicInputNestedComponentProps: {
      labelVariant: 'h6',
      isParameterMapping: true,
    },
    getDynamicInputComponentState,
  });
  const { labelText, hasRequiredIndicator, hint, defaultValue, defaultDisplayValue, ...rest } = componentFieldProps;

  const isSwitchShown = useMemo(() => {
    if (isForceHideSwitch) {
      return false;
    }
    return (
      (inputParameter.uiComponent.type === UIComponentType.TEXT_FIELD && showHiddenConfig) ||
      inputParameter.uiComponent.type === UIComponentType.SINGLE_SELECT ||
      inputParameter.uiComponent.type === UIComponentType.MULTI_SELECT ||
      inputParameter.uiComponent.type === UIComponentType.CHECK_BOX_GROUP ||
      inputParameter.uiComponent.type === UIComponentType.CHECK_BOX ||
      inputParameter.uiComponent.type === UIComponentType.DATE_PICKER ||
      inputParameter.uiComponent.type === UIComponentType.TIME_PICKER ||
      inputParameter.uiComponent.type === UIComponentType.DATE_TIME_PICKER ||
      inputParameter.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP ||
      inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT
    );
  }, [isForceHideSwitch, inputParameter.uiComponent.type, showHiddenConfig]);

  const passContext = useMemo(
    () =>
      isScripted ||
      inputParameter.uiComponent.type === UIComponentType.BLOCK_LIST ||
      inputParameter.uiComponent.type === UIComponentType.JOB_TARGET ||
      inputParameter.uiComponent.type === UIComponentType.RECORD_ATTRIBUTES ||
      inputParameter.uiComponent.type === UIComponentType.RECORD_PARTIAL_UPDATE ||
      inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT ||
      inputParameter.uiComponent.type === UIComponentType.RECORD_UPDATE_ATTRIBUTES ||
      inputParameter.uiComponent.type === UIComponentType.CATALOG_RELATIONSHIP_FILTER ||
      inputParameter.uiComponent.type === UIComponentType.SLACK_USER_PICKER ||
      inputParameter.uiComponent.type === UIComponentType.SLACK_CONVERSATION_PICKER,
    [inputParameter.uiComponent.type, isScripted]
  );

  const isSubGroup = useMemo(
    () => inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT,
    [inputParameter.uiComponent.type]
  );

  const isCustomGroupWrapperNeeded = useMemo(
    () =>
      (!isScripted &&
        (inputParameter.uiComponent.type === UIComponentType.CHECK_BOX_GROUP ||
          inputParameter.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP)) ||
      (isDisplayValueConfigurable && isDisplayValueComponent({ isScripted, inputParameter })),
    [inputParameter, isDisplayValueConfigurable, isScripted]
  );

  return (
    <NvSideGroupedInputLayout
      defaultDisplayValue={defaultDisplayValue}
      variant={isSubGroup ? 'sub-group' : 'default'}
      {...(labelText &&
        !hideLabel && {
          title: {
            direction: 'label-on-top',
            labelText: labelText,
            labelVariant: isSubGroup ? 'h5' : 'h6',
            hasRequiredIndicator,
            hasHiddenIndicator: value.type === ParameterTypes.SIMPLE && value.hidden,
            infoText: hint,
          },
        })}
      {...(isSwitchShown && {
        rightAction: (
          <NvMenuWithItems
            triggerButton={{
              content: <NvSettingsIcon />,
              props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
            }}
            menuItems={
              <ParameterMappingSettingsMenu
                hiddenConfig={{
                  show: showHiddenConfig,
                  value: value.type === ParameterTypes.SIMPLE && value.hidden,
                  onChange: (checked) => {
                    if (value) {
                      onChange({ ...value, hidden: checked });
                    } else {
                      onChange({ type: ParameterTypes.SIMPLE, hidden: checked, parameterId: inputParameter.id });
                    }
                  },
                }}
                dynamicStaticConfig={{
                  show: !(showHiddenConfig && inputParameter.uiComponent.type === UIComponentType.TEXT_FIELD),
                  isScripted,
                  onItemClicked: (type) => {
                    const newScriptedValue = type === 'static' ? false : true;
                    if (isScripted !== newScriptedValue) {
                      setIsScripted(newScriptedValue);
                      onScriptedChange?.({ inputParameter, scripted: newScriptedValue });
                      onChange(undefined);
                    }
                  },
                }}
              />
            }
          />
        ),
      })}
      {...(isCustomGroupWrapperNeeded ? { CustomGroupWrapper: ValueDisplayGroupWrapper } : {})}
      formItem={
        <NvField
          formControlStyle={{
            width: 'inherit',
          }}
          {...rest}
          name={inputParameter.id}
          component={getComponentForField({
            inputParameter: inputParameter,
            otherProps: { size: 'small', ...otherProps },
            isScripted,
            ...(passContext && { context }),
          })}
        />
      }
    />
  );
};
