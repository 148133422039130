import { ConditionConfiguration, RetryConfiguration } from '@novaera/actioner-service';
import { CollapsibleBySwitch, NvFlex, useField } from '@novaera/core';
import { FC } from 'react';
import { MatchEventSection } from '../../../../../../../components';
import { DEFAULT_CONDITION_TYPE_OPTIONS, OPTIONS } from '../../../../../../../components/match-event-section/constants';
import { useIntegrationCodeFieldContext } from '../../../../../../components/integration-code-field/controllers/use-integration-code-field-context';
import { RetryFields } from './retry-fields';
import { JavascriptWrapperComponent } from './styled';
import { RequestSettingsProps } from './types';

export const RequestSettings: FC<React.PropsWithChildren<RequestSettingsProps>> = ({
  requestFieldName,
  showRetry = true,
}) => {
  const {
    input: { value: retryConfiguration, onChange: retryConfigurationChange },
  } = useField<RetryConfiguration>(`${requestFieldName}.retryConfiguration`);

  const {
    input: { value: conditionConfiguration, onChange: conditionConfigurationChange },
  } = useField<ConditionConfiguration>(`${requestFieldName}.conditionConfiguration`);

  const { codeFieldContext } = useIntegrationCodeFieldContext();

  return (
    <NvFlex padding="16px 0px 0px 0px" gap="16px">
      {showRetry && (
        <CollapsibleBySwitch
          isOpen={!!retryConfiguration.enabled}
          onCollapseChanged={(_event, checked) => {
            const { statusCodes, maxRetryCount, delayInSeconds } = retryConfiguration;
            retryConfigurationChange({
              statusCodes: statusCodes ?? [429, 503],
              maxRetryCount: maxRetryCount ?? 5,
              delayInSeconds: delayInSeconds ?? 5,
              enabled: checked,
            });
          }}
          title="Retry"
          info="In cases where the request returns one of the following status codes, the retry mechanism will attempt to rerun it."
          fields={
            <RetryFields
              statusCodeFieldName={`${requestFieldName}.retryConfiguration.statusCodes`}
              maxRetryCountFieldName={`${requestFieldName}.retryConfiguration.maxRetryCount`}
              delayInSecondsFieldName={`${requestFieldName}.retryConfiguration.delayInSeconds`}
            />
          }
        />
      )}
      <CollapsibleBySwitch
        isOpen={!!conditionConfiguration.enabled}
        onCollapseChanged={(_event, checked) => {
          const { condition } = conditionConfiguration;
          conditionConfigurationChange({
            condition: condition ?? {
              type: 'match-any-condition',
              rules: [
                { keyExpression: '', operation: OPTIONS()?.[0].value.operation, value: { type: 'string', value: '' } },
              ],
            },
            enabled: checked,
          });
        }}
        title="Run conditions"
        info="This request will be run if the following conditions are satisfied."
        fields={
          <MatchEventSection
            conditionTypeOptions={DEFAULT_CONDITION_TYPE_OPTIONS.filter((c) => c.value !== 'no-condition')}
            codeInputContext={codeFieldContext}
            conditionFieldName={`${requestFieldName}.conditionConfiguration.condition`}
            JavascriptWrapperComponent={JavascriptWrapperComponent}
          />
        }
      />
    </NvFlex>
  );
};
