import {
  GetUserAppSetupResponse,
  SetupWorkflow,
  getUserAppSetup,
  useExecuteAutoRunWorkflowsQuery,
  useGetUserAppSetup,
} from '@novaera/actioner-service';
import { useCallback, useMemo, useState } from 'react';
import { RealTimeTaskEngine } from '../../../../../real-time/real-time-task-engine';

export const useAutoRunWorkflows = ({
  enabled = true,
  appId,
  setupWorkflows,
}: {
  enabled?: boolean;
  appId: string;
  setupWorkflows: SetupWorkflow[];
}) => {
  const manualSetupWorkflows = setupWorkflows.filter((workflow) => !workflow.autoRun);
  const isAutoRunWorkflowExist = setupWorkflows.some((sw) => sw.autoRun);
  const isAllAutoRunWorkflowsExecuted = setupWorkflows.every((sw) => !sw.autoRun || sw.status);
  const isAnyAutoRunWorkflowFailed = setupWorkflows.some((sw) => sw.autoRun && sw.status === 'failed');
  const failedWorkflows = useMemo(() => {
    return setupWorkflows
      .filter((workflow) => workflow.autoRun && workflow.status === 'failed')
      .map((workflow) => ({
        failedWorkflowId: workflow.id,
        executionId: workflow.executionId,
      }))
      .filter((workflow) => workflow.failedWorkflowId && workflow.executionId);
  }, [setupWorkflows]);

  const [autoRunFailed, setAutoRunFailed] = useState<boolean>(false);
  const { refetch: refetchUserAppSetup, isLoading: isAppSetupLoading } = useGetUserAppSetup({ appId });
  const [isAutoRunWorkflowsExecuting, setIsAutoRunWorkflowsExecuting] = useState<boolean>(
    enabled && isAutoRunWorkflowExist && !isAllAutoRunWorkflowsExecuted && !isAnyAutoRunWorkflowFailed
  );
  const { refetch: refetchAutoRUnWorkflowsQuery } = useExecuteAutoRunWorkflowsQuery({
    params: { appId },
    enabled: enabled && isAutoRunWorkflowExist && !isAllAutoRunWorkflowsExecuted && !isAnyAutoRunWorkflowFailed,
    onSuccess: ({ executionId }: { executionId: string }) => {
      // Actually, I did not want to use this deprecated callback.
      // However, using useEffect with isError return value is not working When webpack hot reloaded,
      // since it is initializing the error false and losing its state.
      setIsAutoRunWorkflowsExecuting(true);
      executeAutoRunWorkflowsSuccess({ executionId });
    },
  });

  const executeAutoRunWorkflowsSuccess = useCallback(
    ({ executionId }: { executionId: string }) => {
      const newTask = new RealTimeTaskEngine<
        GetUserAppSetupResponse,
        {
          id: string;
          properties: string;
          type: 'auto-run-workflows-completed';
          userId: string;
          workspaceId: string;
        }
      >({
        id: executionId,
        pollingCallback: async () => {
          const result = await getUserAppSetup({ appId });
          if (result.setupWorkflows.some((sw) => sw.autoRun && sw.status === 'failed')) {
            return result;
          }

          if (!result.setupWorkflows.every((sw) => !sw.autoRun || !!sw.status)) {
            throw new Error('in_progress');
          }

          return result;
        },
        resultAction: async ({ resultContext: result, source }) => {
          if (source === 'socket') {
            const runWorkflowSocketResultProperties = JSON.parse(result.properties) as {
              appId: string;
              status: SetupWorkflow['status'];
            };

            if (runWorkflowSocketResultProperties.status === 'failed') {
              setAutoRunFailed(true);
            }
          } else {
            if (result.setupWorkflows.some((sw) => sw.autoRun && sw.status === 'failed')) {
              setAutoRunFailed(true);
            }
          }
          refetchUserAppSetup();
          setIsAutoRunWorkflowsExecuting(false);
        },
      });

      newTask.executePolling();
    },
    [appId, refetchUserAppSetup]
  );

  return {
    isAutoRunWorkflowsLoading: isAutoRunWorkflowsExecuting || isAppSetupLoading,
    autoRunFailed,
    refetchAutoRUnWorkflowsQuery,
    manualSetupWorkflows,
    isAutoRunWorkflowExist,
    isAllAutoRunWorkflowsExecuted,
    isAnyAutoRunWorkflowFailed,
    failedWorkflows,
  };
};
