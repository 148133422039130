import { SchemaType } from '@novaera/ah-common';
import { NvTextField } from '@novaera/core';
import { ComponentHint } from '../common/adapter/component-hint';
import { InputTypeComponentParams } from '../use-ui-component/types';

export const TextFieldUIComponent = ({
  inputParameter: { uiComponent, schema },
  inputProps,
  otherProps,
}: InputTypeComponentParams) => {
  const { placeHolder, multiLine, hint } = uiComponent;
  const { error } = inputProps;

  return (
    <>
      <NvTextField
        {...inputProps}
        error={!!error}
        size="large"
        type={
          schema?.type === SchemaType.integer || schema?.type === SchemaType.float
            ? 'number'
            : schema?.type === SchemaType.email
            ? 'email'
            : schema?.type === SchemaType.phone
            ? 'tel'
            : schema?.type === SchemaType.url
            ? 'url'
            : 'text'
        }
        placeholder={placeHolder}
        {...(multiLine && { multiline: true, minRows: 5 })}
        {...otherProps}
      />
      {hint && <ComponentHint hint={hint} />}
    </>
  );
};
