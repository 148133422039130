import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_JOBS } from '../keys';
import { SaveJobParams } from './types';

const saveJob = async ({ appId, jobId, ...params }: SaveJobParams) => {
  const result = await NvAxios.put(`/v2/apps/${appId}/jobs`, {
    ...(jobId ? { id: jobId } : {}),
    ...params,
  });
  return result?.data;
};

export const useSaveJob = () => {
  const queryClient = useQueryClient();
  return useMutation(saveJob, {
    onSuccess: (_, { appId, type, jobId }: SaveJobParams) => {
      queryClient.invalidateQueries(QUERY_KEYS_JOBS.list(appId, type));
      if (jobId) {
        queryClient.invalidateQueries(QUERY_KEYS_JOBS.detail(appId, jobId));
      }
    },
  });
};
