import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const DropSensor = styled(NvFlex)`
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DroppedBgBox = styled(NvFlex)`
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[5]};
  z-index: 1;
  transition: transform 300ms ease;
  pointer-events: none;

  &.is-drag-over {
    transform: scale(1.4, 1.4);
  }
`;

export const DroppedBorderBox = styled(NvFlex)`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px dashed ${({ theme }) => theme.palette.nv_neutral[300]};
  background-color: transparent;
  border-radius: 6px;
  z-index: 10;
  transition: transform 300ms ease, background-color 300ms ease, border-color 300ms ease;
  pointer-events: none;

  &.is-drag-over {
    border-color: ${({ theme }) => theme.palette.nv_main[40]};
    background-color: ${({ theme }) => theme.palette.nv_main[20]};
  }
`;
