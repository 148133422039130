import {
  GetFormParametersResponse,
  InputParameter,
  InputParameterValues,
  ParameterTypes,
  SetupWorkflow,
  SimpleParameterMapping,
  UIComponentType,
  ValueTypes,
  WorkflowState,
  WorkflowWithState,
  isSetupWorkflowType,
  useGetFormParameters,
} from '@novaera/actioner-service';
import { DateTimeFormat, SchemaType } from '@novaera/ah-common';
import { assert } from '@novaera/utils';
import { FormApi } from 'final-form';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { InputFormValues } from '../../../../../../action-designer/providers/input-values';
import { useFormIdentifierContext } from '../../../../../../providers/form-identifier-provider';

type OnInputValueChangedType = {
  inputParameterId: string;
  value: unknown;
};

type FormParameterContextValueType = {
  onInputValueChanged: (param: OnInputValueChangedType) => void;
  inputParameters?: InputParameter[];
  setEnabledInputParameterIdToFetchOptions: Dispatch<SetStateAction<string[]>>;
  enabledInputParameterIdToFetchOptions?: string[];
  isInitialLoading?: boolean;
  isLoading?: boolean;
  formId: string;
  isInputParameterNewlyAdded: (inputParameterId: string) => boolean;
  formParameters?: GetFormParametersResponse;
};

const FormParameterContext = createContext<FormParameterContextValueType | undefined>(undefined);

export const FormParameterProvider = ({
  children,
  workflow,
  initialParameterValues,
  formRef,
}: {
  workflow: WorkflowWithState | SetupWorkflow;
  initialParameterValues?: SimpleParameterMapping<InputParameterValues>[];
  children: React.ReactNode | ((props: FormParameterContextValueType) => React.ReactNode);
  formRef: MutableRefObject<FormApi<InputFormValues, Partial<InputFormValues>> | null> | undefined;
}) => {
  const [changedParameterId, setChangedParameterId] = useState<string | undefined>();

  const [enabledInputParameterIdToFetchOptions, setEnabledInputParameterIdToFetchOptions] = useState<string[]>([]);

  const [parameterValues, setParameterValues] = useState<SimpleParameterMapping<InputParameterValues>[]>(
    initialParameterValues ?? []
  );

  const [newAddedFormIds, setNewAddedFormIds] = useState<string[]>([]);

  const { getStatefulFormId, setStatefulFormId } = useFormIdentifierContext();

  // keep track of last successful form parameters result to use in case of error
  const lastSuccessfulFormParametersResult = useRef<GetFormParametersResponse>();

  const {
    data: formParameters,
    isInitialLoading,
    isFetching: isLoading,
    isError,
  } = useGetFormParameters({
    appId: workflow.appId,
    workflowId: workflow.id,
    payload: {
      draft: isSetupWorkflowType(workflow) ? false : workflow.state === WorkflowState.DRAFT,
      formId: getStatefulFormId(),
      changedParameterId,
      parameterMappings: parameterValues,
    },
    onSuccess: (data) => {
      lastSuccessfulFormParametersResult.current = data;

      if (formParameters) {
        const diff = data.inputParameterWithValues
          .map((p) => p.inputParameter.id)
          .filter((x) => !formParameters.inputParameterWithValues.map((p) => p.inputParameter.id).includes(x));
        setNewAddedFormIds(diff);
      }

      data?.inputParameterWithValues.forEach((p) => {
        if (
          p.inputParameter.uiComponent.type === UIComponentType.CHECK_BOX_GROUP ||
          p.inputParameter.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP ||
          p.inputParameter.uiComponent.type === UIComponentType.SINGLE_SELECT ||
          p.inputParameter.uiComponent.type === UIComponentType.MULTI_SELECT
        ) {
          setEnabledInputParameterIdToFetchOptions((prev) => {
            if (!prev.includes(p.inputParameter.id)) {
              return [...prev, p.inputParameter.id];
            }
            return prev;
          });
        }
      });
      setStatefulFormId(data.formId);
    },
  });

  const handleIsInputParameterNewlyAdded = useCallback(
    (inputParameterId: string) => {
      return newAddedFormIds.includes(inputParameterId);
    },
    [newAddedFormIds]
  );

  const pureHandleInputValueChanged = useCallback(
    ({ inputParameterId, value }: OnInputValueChangedType) => {
      const inputFormValues: InputFormValues = { ...formRef?.current?.getState()?.values };

      // if value added to form values, set it
      if (value) {
        inputFormValues[inputParameterId] = value as SimpleParameterMapping<InputParameterValues>;
      } else {
        // get parameter value from formParameters and remove values part to add inputFormValues
        const inputParameterWithValues = formParameters?.inputParameterWithValues.find(
          (p) => p.inputParameter.id === inputParameterId
        );

        const inputType = inputParameterWithValues?.inputParameter.uiComponent.type;

        const parameterMapping = inputParameterWithValues?.parameterMapping ?? {
          type: ParameterTypes.SIMPLE,
          parameterId: inputParameterId,
          value: {
            type:
              inputType === UIComponentType.DATE_PICKER
                ? ValueTypes.STRING
                : inputType === UIComponentType.TIME_PICKER
                ? ValueTypes.STRING
                : inputType === UIComponentType.DATE_TIME_PICKER
                ? inputParameterWithValues?.inputParameter.schema?.type === SchemaType.dateTime
                  ? inputParameterWithValues?.inputParameter.schema.format === DateTimeFormat.utc
                    ? ValueTypes.NUMBER
                    : ValueTypes.STRING
                  : ValueTypes.STRING
                : ValueTypes.STRING,
          },
        };

        if (parameterMapping) {
          assert(!!parameterMapping.value, new Error('Parameter mapping value should not be null'), 'ERROR');

          inputFormValues[inputParameterId] = {
            ...parameterMapping,
            value: {
              type: parameterMapping.value?.type,
            },
          };
        }
      }

      const parameterValues: SimpleParameterMapping<InputParameterValues>[] = [];
      Object.keys(inputFormValues).forEach((key) => {
        parameterValues.push(inputFormValues[key] as SimpleParameterMapping<InputParameterValues>);
      });

      setParameterValues(parameterValues);
      setChangedParameterId(inputParameterId);
    },
    [formParameters?.inputParameterWithValues, formRef]
  );

  const handleInputValueChanged = useDebouncedCallback(pureHandleInputValueChanged, 500);

  const inputParameters = useMemo(
    () =>
      isError
        ? lastSuccessfulFormParametersResult.current?.inputParameterWithValues.map((p) => p.inputParameter)
        : formParameters?.inputParameterWithValues.map((p) => p.inputParameter),
    [formParameters?.inputParameterWithValues, isError]
  );

  return (
    <FormParameterContext.Provider
      value={{
        onInputValueChanged: handleInputValueChanged,
        setEnabledInputParameterIdToFetchOptions: setEnabledInputParameterIdToFetchOptions,
        enabledInputParameterIdToFetchOptions,
        isInitialLoading,
        isLoading,
        inputParameters,
        formId: getStatefulFormId(),
        isInputParameterNewlyAdded: handleIsInputParameterNewlyAdded,
        formParameters,
      }}
    >
      {typeof children === 'function'
        ? children({
            onInputValueChanged: handleInputValueChanged,
            inputParameters,
            setEnabledInputParameterIdToFetchOptions: setEnabledInputParameterIdToFetchOptions,
            enabledInputParameterIdToFetchOptions,
            isInitialLoading,
            isLoading,
            formId: getStatefulFormId(),
            isInputParameterNewlyAdded: handleIsInputParameterNewlyAdded,
            formParameters,
          })
        : children}
    </FormParameterContext.Provider>
  );
};

export const useFormParameterProvider = () => {
  const context = useContext(FormParameterContext);
  assert(!!context, new Error(`useFormParameterProvider should be used within FormParameterProvider`), 'ERROR');

  return context;
};
