import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvFlex } from '../flex';

export const SideGroupedWrapper = styled(NvFlex)`
  gap: 4px;
  &.has-border {
    border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
    border-radius: 6px;
    padding: 6px 6px 12px;
  }
`;

export const Title = styled(NvBox)`
  width: 100%;
`;
export const GroupWrapper = styled(NvFlex)`
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;
export const ActionBox = styled(NvFlex)`
  min-height: 32px;
  flex: 0 0 auto;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const CenterItem = styled(NvFlex)`
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;
