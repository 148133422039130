import { QueryClient } from '@tanstack/react-query';
import { IntegrationAction } from '../../../types';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../../keys';
import { GetIntegrationActionResponse, GetIntegrationActionsResponse } from '../../types';

export const replaceActionInCache = ({
  cache,
  integrationId,
  actionId,
  newIntegrationActionAsDraft,
  version,
}: {
  cache: QueryClient;
  integrationId: string;
  actionId: string;
  version: number;
  newIntegrationActionAsDraft: IntegrationAction;
}) => {
  const oldIntegrationActionList = cache.getQueryData(QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version));
  const oldIntegrationActionDetail = cache.getQueryData(
    QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version)
  );
  cache.setQueryData(
    QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version),
    (old?: GetIntegrationActionsResponse) => {
      if (old) {
        return {
          ...old,
          actions: old.actions.map((a) => (a.id === actionId ? newIntegrationActionAsDraft : a)),
        };
      }
      return old;
    }
  );
  cache.setQueryData(
    QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version),
    (old?: GetIntegrationActionResponse) => {
      return old && { ...old, draft: newIntegrationActionAsDraft };
    }
  );

  return { oldIntegrationActionList, oldIntegrationActionDetail };
};
