import { NvAxios, useMutation } from '@novaera/core';

import { useQueryClient } from '@tanstack/react-query';
import { GetDataModelsResponse } from '../../data-model';
import { QUERY_KEYS_DATA_MODEL } from '../../data-model/keys';
import { CLONE_ROOT } from '../constants';
import { DataModelCloneParams, DataTableCloneResponse } from '../types';

const dataModelClone: (params: DataModelCloneParams) => Promise<DataTableCloneResponse> = async (params) => {
  const { appId, modelId, ...rest } = params;
  const result = await NvAxios.post<DataTableCloneResponse>(
    `${CLONE_ROOT({ appId })}/data-models/${modelId}/clone`,
    rest.payload
  );
  return result?.data;
};

export const useDataModelClone = () => {
  const cache = useQueryClient();
  return useMutation(dataModelClone, {
    onSuccess: ({ clonedTable }, { onSuccess }) => {
      onSuccess({ clonedTable });

      cache
        .getQueriesData<GetDataModelsResponse>({
          queryKey: [...QUERY_KEYS_DATA_MODEL.all, 'list'],
        })
        .forEach((result) => {
          const [query, data] = result;
          cache.setQueryData<GetDataModelsResponse>(query, (old) => {
            const models = [clonedTable, ...(data?.models ?? [])];
            if (old && data?.models) {
              return {
                ...old,
                models,
              };
            }
            return old;
          });
        });
    },
  });
};
