import { FieldSchema } from '@novaera/actioner-service';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { findLogoFromFields } from '../../data-model-layout/utils';

export const RecordsTableHeaderIcon: React.FC<React.PropsWithChildren<{ type: FieldSchema['type'] }>> = ({ type }) => {
  const { palette } = useTheme();
  const icon = findLogoFromFields(type);
  return icon
    ? React.cloneElement(icon, { sx: { width: '16px', height: '16px', color: palette.nv_neutral[600] } })
    : null;
};
