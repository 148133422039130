import { styled } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';
import { NvNumberInputProps } from '.';
import { NvTextField } from '../textfield';

const props = {
  shouldForwardProp: shouldForwardPropForFormFields,
};
export const NumberInputField = styled(NvTextField, props)<NvNumberInputProps>`
  &.MuiTextField-root {
    .MuiInput-root {
      padding: 0 4px 0 12px;
      max-width: 64px;
    }
  }
`;
