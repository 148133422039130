import { ThemeMode, Themes } from '../../themes/types';
import { ModeValueProvider } from '../mode-value-provider';
import { MuiThemeWrapper } from '../mui-theme-wrapper';
import { ThemeValueProvider } from '../theme-value-provider';

export const ThemeProvider = ({
  children,
  initialMode = ThemeMode.Light,
  initialTheme = Themes.Default,
}: {
  children: React.ReactNode;
  initialMode?: ThemeMode;
  initialTheme?: Themes;
}) => {
  return (
    <ModeValueProvider initialMode={initialMode}>
      <ThemeValueProvider initialTheme={initialTheme}>
        <MuiThemeWrapper>{children}</MuiThemeWrapper>
      </ThemeValueProvider>
    </ModeValueProvider>
  );
};
