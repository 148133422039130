import {
  CloneResponse,
  CloneType,
  isAppCloneResponse,
  isDataTableCloneResponse,
  isWorkflowCloneResponse,
} from '@novaera/actioner-service';
import { useNavigate } from '@novaera/route';
import { ROUTES } from '../../../../../common/routes';
import { useGetWorkflowQueryParams } from '../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useCloneFlaggerController = ({
  type,
  clonedItem,
  onOpenClicked,
}: {
  type: CloneType;
  clonedItem: CloneResponse;
  onOpenClicked?: () => void;
}) => {
  const navigate = useNavigate();
  const { userAppId } = useGetWorkflowQueryParams();

  const handleFlaggerOpenClicked = () => {
    if (isAppCloneResponse(clonedItem, type)) {
      navigate(`${ROUTES.UserApps}/${clonedItem.clonedApp.id}`);
    }
    if (isWorkflowCloneResponse(clonedItem, type)) {
      navigate(`${ROUTES.UserApps}/${clonedItem.clonedWorkflow.appId}/workflows/${clonedItem.clonedWorkflow.id}`);
    }
    if (isDataTableCloneResponse(clonedItem, type)) {
      navigate(`${ROUTES.UserApps}/${userAppId}/tables/${clonedItem.clonedTable.id}`);
    }

    onOpenClicked?.();
  };
  return {
    message: `${type === 'workflows' ? 'Workflow' : type === 'data-models' ? 'Data model' : 'App'} cloned`,
    onFlaggerOpenClicked: handleFlaggerOpenClicked,
  };
};
