import {
  AppConfigDetailWithState,
  useBatchValidationConfig,
  useCreateConfigSchemaService,
  useDeleteConfigSchema,
  useGetConfigSchema,
  useListConfigSchemas,
} from '@novaera/actioner-service';
import { NvButton, NvCustomSaveIcon, useConfirmDialog, useField, useForm } from '@novaera/core';
import { useCallback, useState } from 'react';
import { useGetUserAppQueryParams } from '../../../../../../../user-app/controllers/use-get-user-app-query-params';
import { Schema } from '../../types';

export const useSchemaConfig = ({
  config,
  validateConfig,
  onCreateSchemaSuccess,
}: {
  config: AppConfigDetailWithState;
  validateConfig: (config: AppConfigDetailWithState) => void;
  onCreateSchemaSuccess: (schema: Schema) => void;
}) => {
  const form = useForm();
  const [schemaFocusState, setSchemaFocusState] = useState<{
    isOpened: boolean;
    schema?: Schema;
  }>({
    isOpened: false,
  });

  const { userAppId } = useGetUserAppQueryParams();
  const {
    input: { value: schemaId },
  } = useField<string>('schemaId');

  const { data: configSchemas, isLoading: isOptionsLoading } = useListConfigSchemas({ appId: userAppId });
  const { mutate: createConfigSchema, isLoading: isCreateConfigSchemaLoading } = useCreateConfigSchemaService();
  const { mutate: deleteConfigSchema } = useDeleteConfigSchema();
  const { data: selectedSchema } = useGetConfigSchema({ appId: userAppId, id: schemaId });

  const { mutate: batchValidateConfig, isLoading: isBatchValidationLoading } = useBatchValidationConfig();

  const { openConfirm, closeConfirm } = useConfirmDialog();

  const createSchema = (value: Schema) => {
    createConfigSchema(
      {
        appId: userAppId,
        payload: {
          ...value,
        },
      },
      {
        onSuccess: (result) => {
          closeConfirm();
          handleCloseSchemaFocusState();
          onCreateSchemaSuccess(result);
        },
      }
    );
  };

  const handleOnAddNewItemClicked = useCallback(() => {
    setSchemaFocusState({
      isOpened: true,
    });
  }, []);

  const handleEditSchemaClicked = useCallback((value: Schema) => {
    setSchemaFocusState({
      isOpened: true,
      schema: {
        ...value,
      },
    });
  }, []);

  const handleRemoveSchemaClicked = useCallback(() => {
    form.change('schemaId', undefined);
  }, [form]);

  const handleSubmitSchema = (value: Schema) => {
    if (value.id) {
      batchValidateConfig(
        {
          appId: userAppId,
          payload: {
            schema: value.schema,
            schemaId: value.id,
          },
        },
        {
          onSuccess: (validationResult) => {
            const invalidationConfigurationArr = validationResult.results
              .filter((schemaValidation) => !schemaValidation.result.valid)
              .map((result) => result.config.name);

            const invalidConfigurations = invalidationConfigurationArr.join(
              invalidationConfigurationArr.length === 2 ? ' and ' : ', '
            );

            const hasInvalidConfig = !!invalidConfigurations;

            if (!hasInvalidConfig) {
              createSchema(value);
            } else {
              openConfirm({
                icon: <NvCustomSaveIcon />,
                title: 'Saving schema',
                confirmButton: (
                  <NvButton
                    loading={isCreateConfigSchemaLoading}
                    onClick={() => {
                      createSchema(value);
                      closeConfirm();
                    }}
                  >
                    Save
                  </NvButton>
                ),
                onClose: () => {
                  closeConfirm();
                },

                message: `Modifying this schema could result in major problems in the associated configs. Please review ${invalidConfigurations} files to prevent disruptions in the app's workflows.`,
              });
            }
          },
        }
      );
    } else {
      createSchema(value);
    }
  };

  const handleValidateConfig = useCallback(
    ({ schemaId }: { schemaId: string }) => {
      validateConfig({
        ...config,
        schemaId,
      });
    },
    [config, validateConfig]
  );

  const handleCloseSchemaFocusState = useCallback(() => {
    setSchemaFocusState({
      isOpened: false,
    });
    if (config.schemaId) {
      handleValidateConfig({ schemaId: config.schemaId });
    }
  }, [config.schemaId, handleValidateConfig]);

  const handleDeleteClicked = useCallback(() => {
    if (schemaFocusState.schema) {
      deleteConfigSchema(
        {
          appId: userAppId,
          id: schemaFocusState.schema.id,
        },
        {
          onSuccess: () => {
            handleCloseSchemaFocusState();
            form.change('schemaId', undefined);
          },
        }
      );
    }
  }, [deleteConfigSchema, form, handleCloseSchemaFocusState, schemaFocusState.schema, userAppId]);

  return {
    isOptionsLoading,
    options: configSchemas?.schemas,
    onAddNewItemClicked: handleOnAddNewItemClicked,
    onEditSchemaClicked: handleEditSchemaClicked,
    onRemoveSchemaClicked: handleRemoveSchemaClicked,
    schemaFocusState,
    onClosedSchemaFocusState: handleCloseSchemaFocusState,
    onSubmitSchema: handleSubmitSchema,
    onDeleteClicked: handleDeleteClicked,
    value: selectedSchema,
    configSchemaTitle: schemaFocusState.schema ? 'Edit schema' : 'Add schema',
    primaryButtonLabel: schemaFocusState.schema ? 'Update' : 'Create',
    isCreateLoading: isBatchValidationLoading,
    validateConfig: handleValidateConfig,
  };
};
