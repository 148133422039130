import { LoadingButton } from '@mui/lab';
import { styled, withAlpha } from '@novaera/theme-provider';
import { shouldForwardPropForFormFields } from '@novaera/utils';
import { NvButtonPropsButtonProps } from './types';

export const ButtonStyled = styled(LoadingButton, {
  shouldForwardProp: shouldForwardPropForFormFields,
})<NvButtonPropsButtonProps>(
  ({ theme, loading, isSelected, hasDarkBackground }) => `
  &.fit-content{
    width: fit-content;
  }
&.MuiButtonBase-root {
  border-radius: 6px;

  &.Mui-focusVisible {
    outline: 2px solid ${theme.palette.nv_accent[40]};
  }

  &.NvButton-sizeSmall {
    padding: 0 8px;
    font-size: 12px;
    height: 24px;
  }
  &.NvButton-sizeMedium {
    padding: 0 12px;
    font-size: 14px;
    height: 32px;
  }
  &.NvButton-sizeLarge {
    padding: 0 16px;
    font-size: 16px;
    height: 40px;

    & .MuiButton-startIcon {
      margin-left: -4px;
    }
    & .MuiButton-endIcon {
      margin-right: -4px;
    }
  }
  &.NvButton-onlyIcon {
    padding: 0;

    &.MuiButton-sizeSmall {
      font-size: 12px;
      min-width: 24px;
      width: 24px;
      height: 24px;
    }

    &.MuiButton-sizeMedium {
      font-size: 14px;
      min-width: 32px;
      width: 32px;
      height: 32px;
    }
    &.MuiButton-sizeLarge {
      font-size: 16px;
      min-width: 40px;
      width: 40px;
      height: 40px;
    }

    & .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 0;
    }
  }

  & .MuiButton-startIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 8px;
  }

  & .MuiButton-endIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: 8px;
  }

  & .MuiButton-iconSizeSmall {
    width: 16px;
    height: 16px;
  }

  & .MuiButton-iconSizeMedium{
    width: 20px;
    height: 20px;
  }

  & .MuiButton-iconSizeLarge {
    width: 24px;
    height: 24px;
  }

  &.MuiLoadingButton-loading {
    color: transparent;
  }

  // COLOR TYPE = PRIMARY
  &.MuiButton-containedPrimary {
    color: ${!loading && theme.palette.nv_neutral[0]};
    background-color: ${isSelected ? theme.palette.nv_main[60] : theme.palette.nv_main[40]};


    &.MuiLoadingButton-loading {
      background-color: ${isSelected ? theme.palette.nv_main[60] : theme.palette.nv_main[40]};
    }

    &.Mui-disabled {
      background-color: ${!loading && theme.palette.nv_main[30]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${theme.palette.nv_neutral[0]};
    }

    &.Mui-focusVisible,
    &:hover {
      background-color: ${theme.palette.nv_main[50]};
    }

    &:active {
      background-color: ${theme.palette.nv_main[60]};
    }
  }

  // COLOR TYPE = SECONDARY
  &.MuiButton-containedSecondary {
    color: ${!loading && (hasDarkBackground ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[1000])};
    background-color: ${
      isSelected
        ? hasDarkBackground
          ? theme.palette.nv_neutral[600]
          : theme.palette.nv_neutral_alpha[40]
        : hasDarkBackground
        ? theme.palette.nv_neutral[600]
        : theme.palette.nv_neutral_alpha[20]
    };

    &.MuiLoadingButton-loading {
      background-color: ${
        isSelected
          ? hasDarkBackground
            ? theme.palette.nv_neutral[600]
            : theme.palette.nv_neutral_alpha[40]
          : hasDarkBackground
          ? theme.palette.nv_neutral[600]
          : theme.palette.nv_neutral_alpha[20]
      };
    }

    //
    &.Mui-disabled {
      color: ${!loading && theme.palette.nv_neutral[300]};
      background-color: ${
        !loading && (hasDarkBackground ? theme.palette.nv_neutral[800] : theme.palette.nv_neutral_alpha[20])
      };
    }
    
    &.Mui-focusVisible,
    &:hover {
      background-color: ${hasDarkBackground ? theme.palette.nv_neutral[400] : theme.palette.nv_neutral_alpha[30]};
    }

    &:active {
      background-color: ${hasDarkBackground ? theme.palette.nv_neutral[200] : theme.palette.nv_neutral_alpha[40]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${hasDarkBackground ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[1000]};
    }
  }

  // COLOR TYPE = GHOST
  &.MuiButton-containedGhost {
    color: ${!loading && (hasDarkBackground ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[1000])};
    background-color: ${isSelected ? theme.palette.nv_neutral_alpha[30] : 'transparent'};

    &.MuiLoadingButton-loading {
      background-color: ${isSelected ? theme.palette.nv_neutral_alpha[30] : 'transparent'};
    }

    &.Mui-disabled {
      color: ${
        !loading && (hasDarkBackground ? withAlpha(theme.palette.nv_neutral[0], 0.6) : theme.palette.nv_neutral[300])
      };
      background-color: ${!loading && 'transparent'};
    }

    &.Mui-focusVisible,
    &:hover {
      background-color: ${theme.palette.nv_neutral_alpha[20]};
    }

    &:active {
      background-color: ${theme.palette.nv_neutral_alpha[30]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${hasDarkBackground ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral[1000]};
    }
  }

  // COLOR TYPE = SUCCESS
  &.MuiButton-containedSuccess {
    color: ${!loading && theme.palette.nv_neutral[1000]};
    background-color: ${isSelected ? theme.palette.nv_success[60] : theme.palette.nv_success[40]};

    &.MuiLoadingButton-loading {
      background-color: ${isSelected ? theme.palette.nv_success[60] : theme.palette.nv_success[40]};
    }

    &.Mui-disabled {
      color: ${!loading && theme.palette.nv_neutral_alpha[300]};
      background-color: ${!loading && theme.palette.nv_success[20]};
    }

    &.Mui-focusVisible,
    &:hover {
      background-color: ${theme.palette.nv_success[50]};
    }

    &:active {
      background-color: ${theme.palette.nv_success[60]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${theme.palette.nv_neutral[1000]};
    }
  }

  // COLOR TYPE = WARNING
  &.MuiButton-containedWarning {
    color: ${!loading && theme.palette.nv_neutral[0]};
    background-color: ${isSelected ? theme.palette.nv_warning[60] : theme.palette.nv_warning[40]};

    &.MuiLoadingButton-loading {
      background-color: ${isSelected ? theme.palette.nv_warning[60] : theme.palette.nv_warning[40]};
    }

    &.Mui-disabled {
      background-color: ${!loading && theme.palette.nv_warning[20]};
    }

    &.Mui-focusVisible,
    &:hover {
      background-color: ${theme.palette.nv_warning[50]};
    }

    &:active {
      background-color: ${theme.palette.nv_warning[60]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${theme.palette.nv_neutral[0]};
    }
  }

  // COLOR TYPE = ERROR
  &.MuiButton-containedError {
    color: ${!loading && theme.palette.nv_neutral[0]};
    background-color: ${isSelected ? theme.palette.nv_error[60] : theme.palette.nv_error[40]};

    &.MuiLoadingButton-loading {
      background-color: ${isSelected ? theme.palette.nv_error[60] : theme.palette.nv_error[40]};
    }

    &.Mui-disabled {
      background-color: ${!loading && theme.palette.nv_error[30]};
    }

    &.Mui-focusVisible,
    &:hover {
      background-color: ${theme.palette.nv_error[50]};
    }

    &:active {
      background-color: ${theme.palette.nv_error[60]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${theme.palette.nv_neutral[0]};
    }
  }

  // COLOR TYPE = INFO
  &.MuiButton-containedInfo {
    color: ${!loading && theme.palette.nv_neutral[1000]};
    background-color: ${isSelected ? theme.palette.nv_new[60] : theme.palette.nv_new[40]};

    &.MuiLoadingButton-loading {
      background-color: ${isSelected ? theme.palette.nv_new[60] : theme.palette.nv_new[40]};
    }

    &.Mui-disabled {
      color: ${!loading && theme.palette.nv_neutral_alpha[300]};
      background-color: ${!loading && theme.palette.nv_new[20]};
    }

    &.Mui-focusVisible,
    &:hover {
      background-color: ${theme.palette.nv_new[50]};
    }

    &:active {
      background-color: ${theme.palette.nv_new[60]};
    }

    & .MuiLoadingButton-loadingIndicator {
      color: ${theme.palette.nv_neutral[1000]};
    }
  }
}
`
);
