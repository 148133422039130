import { useJoinWorkspace } from '../../controllers/use-join-workspace';

export const useCreateWorkspace = () => {
  const { joinWorkspace, isLoading } = useJoinWorkspace();

  const handleCreateWorkspaceClicked = () => {
    joinWorkspace();
  };

  return {
    onCreateWorkspaceClicked: handleCreateWorkspaceClicked,
    isLoading,
  };
};
