import { NvFlex, NvForm, useNvDialogModalState } from '@novaera/core';
import { useParams } from '@novaera/route';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { EventModal } from '../event-modal';
import { EventDetailBody } from './body';
import { useEventDetailBody } from './body/controllers/use-event-detail-body';
import { EventDetailHeader } from './header';
import { EventDetailForm } from './types';

export const EventDetail = () => {
  const { onFormSubmit, event, isEventLoading } = useEventDetailBody();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { userAppId } = useParams();
  return (
    <>
      <NvForm<EventDetailForm>
        onSubmit={onFormSubmit}
        initialValues={{
          ...{
            examplePayload: event?.examplePayload,
            payloadSchema: event?.payloadSchema,
          },
        }}
      >
        <NvFlex flexDirection={'column'} height={'100%'}>
          <FreeAppPermissionBoundary
            appId={userAppId}
            Fallback={<EventDetailHeader event={event} isEventLoading={isEventLoading} />}
          >
            <EventDetailHeader onEditNameClicked={onModalOpenClicked} event={event} isEventLoading={isEventLoading} />
          </FreeAppPermissionBoundary>
          <EventDetailBody isLoading={isEventLoading} />
        </NvFlex>
      </NvForm>
      <EventModal opened={isOpened} onClose={onModalCloseClicked} event={event} />
    </>
  );
};
