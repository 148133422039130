import { ConstraintType, DateTimeFormat, SchemaType } from '@novaera/ah-common';
import { CreationSource } from '../../types';

type CollectionConstraint = {
  min: number;
  max: number;
  enabled: boolean;
};

export type StringSizeConstraint = CollectionConstraint & { type: ConstraintType.stringSize };
export type MapSizeConstraint = CollectionConstraint & { type: ConstraintType.mapSize };
export type ListSizeConstraint = CollectionConstraint & { type: ConstraintType.listSize };
export type RegexConstraint = { pattern: string; type: ConstraintType.regex; enabled: boolean };

export type AllConstraints = StringSizeConstraint | RegexConstraint | ListSizeConstraint | MapSizeConstraint;

export type AvailableFieldType = {
  type: SchemaType;
  constraints: AllConstraints[];
};

interface BaseSchema<DefaultValueType = unknown> {
  mandatory: boolean;
  readOnly: boolean;
  defaultValue?: DefaultValueType;
}

export interface PrimitiveSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type:
    | SchemaType.boolean
    | SchemaType.email
    | SchemaType.float
    | SchemaType.integer
    | SchemaType.phone
    | SchemaType.url
    | SchemaType.uuid;
  constraints: [];
}

export interface StringSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type: SchemaType.string;
  constraints: (StringSizeConstraint | RegexConstraint)[];
}

export interface DateTimeSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type: SchemaType.dateTime;
  format: DateTimeFormat;
  constraints: [];
}

export interface RecordReferenceSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type: SchemaType.recordReference;
  modelId: string;
  modelName: string;
  constraints: [];
}

export type ValueSchema = StringSchema | PrimitiveSchema | DateTimeSchema | RecordReferenceSchema | MapSchema;

export interface ListSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type: SchemaType.array;
  valueSchema: ValueSchema;
  constraints: ListSizeConstraint[];
}

export interface MapSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type: SchemaType.map;
  valueSchema?: ValueSchema;
  constraints: MapSizeConstraint[];
}

export interface ObjectSchema<DefaultValueType = unknown> extends BaseSchema<DefaultValueType> {
  type: SchemaType.object;
  valueSchema?: ValueSchema;
  constraints: MapSizeConstraint[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldSchema<DefaultValueType = any> =
  | PrimitiveSchema<DefaultValueType>
  | StringSchema<DefaultValueType>
  | DateTimeSchema<DefaultValueType>
  | ListSchema<DefaultValueType>
  | MapSchema<DefaultValueType>
  | ObjectSchema<DefaultValueType>
  | RecordReferenceSchema<DefaultValueType>;

export type PrimitiveFieldType = {
  id: string;
  name: string;
  schema: StringSchema | PrimitiveSchema | DateTimeSchema;
};

export type AdvanceFieldTypes = {
  id: string;
  name: string;
  schema: ListSchema | MapSchema | RecordReferenceSchema;
};

export interface BaseFieldType {
  id: string;
  name: string;
  schema: FieldSchema;
}

export interface FieldType extends BaseFieldType {
  vectorDBConfiguration?: {
    replicateField: boolean;
    filterable: boolean;
  };
}

export type DataModel = {
  id: string;
  name: string;
  description?: string;
  fields: FieldType[];
  replicateToVectorDB: boolean;
  creationSource: CreationSource;
};

export type DataModelSummary = Omit<DataModel, 'fields' | 'replicateToVectorDB' | 'creationSource'> & {
  numberOfFields: number;
  hasDraft: boolean;
};

export type GetDataModelsParams = {
  appId: string;
  next?: string;
  enabled?: boolean;
};

export type GetDataModelsResponse = { models: DataModelSummary[]; next?: string };

export type CreateDataModelParams = Pick<DataModel, 'description' | 'name'> & {
  appId: string;
};

export type CreateDataModelResponse = DataModel;

export type UpdateDataModelParams = DataModel & { appId: string };
export type UpdateDraftDataModelParams = DataModel & { appId: string };
export type SaveDraftDataModelParams = Pick<DataModel, 'id'> & { appId: string };
export type DeleteDraftDataModelParams = Pick<DataModel, 'id'> & { appId: string };
export type UpdateDataModelOutlineParams = Pick<DataModel, 'id' | 'name' | 'description'> & { appId: string };

export type GetDataModelParams = {
  appId?: string;
  modelId?: string;
};

export type GetDataModelResponse = { saved: DataModel; draft?: DataModel };

export type GetDataModelMetadataResponse = {
  availableFieldTypes: AvailableFieldType[];
};

export type DeleteDataModelParam = {
  appId: string;
  modelId: string;
};

export enum DataModelState {
  SAVED = 'saved',
  DRAFT = 'draft',
}

export type DataModelWithState = DataModel & { state: DataModelState };
