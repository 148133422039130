import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const CardWrapper = styled(NvBox)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  background-color: ${({ theme: { palette } }) => palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  transition: border-color 300ms ease-in-out 0s;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.palette.nv_main[40]};
  }
`;
