import { useEffect } from 'react';
import { useLogout } from '../../controllers/use-log-out';
import { LoadingPage } from '../loading-page';

export const Logout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { onLogoutClick } = useLogout();

  useEffect(() => {
    onLogoutClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
};
