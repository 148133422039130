export const getGMTOffset = (offset: number) => {
  const addZeroToStart = (n: number) => {
    return (n < 10 ? '0' : '') + n;
  };
  const sign = offset >= 0 ? '+' : '-';
  offset = Math.abs(offset);
  return `(UTC ${sign}${addZeroToStart((offset / 60) | 0)}:${addZeroToStart(offset % 60)})`;
};

export const formatCronExpression = (cron?: string): Record<string, string> | null => {
  if (!cron) {
    return null;
  }

  const cronFields = cron.split(' ');

  if (cronFields.length === 6) {
    const [minute, hour, dayOfMonth, month, dayOfWeek, year] = cronFields;
    return { minute, hour, dayOfMonth, month, dayOfWeek, year };
  } else {
    return null;
  }
};

export const parseCronExpression = (value?: Record<string, string> | null) => {
  if (value) {
    const { minute, hour, dayOfMonth, month, dayOfWeek, year } = value;
    return [minute, hour, dayOfMonth, month, dayOfWeek, year].join(' ');
  } else {
    return null;
  }
};
