import {
  FileNodeAction,
  FileNodeSummary,
  HTTPNodeSummary,
  JobNodeSummary,
  LinkGeneratorNodeSummary,
  NodeSummary,
  NodeUnionTypeEnumLike,
  TransferFileAssistantAction,
  TransferFileIntegrationAction,
  TransferFileNodeAction,
  WorkflowDispatcherNodeSummary,
} from './types';

export const isWorkflowDispatcherNodeSummary = (node: NodeSummary): node is WorkflowDispatcherNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.workflowDispatcher;
};

export const isJobNodeSummary = (node: NodeSummary): node is JobNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.job;
};

export const isFileNodeSummary = (node: NodeSummary): node is FileNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.file;
};

export const isLinkGeneratorNodeSummary = (node: NodeSummary): node is LinkGeneratorNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.linkGenerator;
};

export const isHTTPNodeSummary = (node: NodeSummary): node is HTTPNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.http;
};

export const isTransferFileOperation = (node: FileNodeAction): node is TransferFileNodeAction =>
  node?.type === 'transfer';

export const isTransferFileAssistantNodeAction = (
  node: TransferFileAssistantAction | TransferFileIntegrationAction
): node is TransferFileAssistantAction => {
  return node?.type === 'assistant';
};
