import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const EndLabelAdornment = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  ::before {
    content: '';
    margin-right: 8px;
    height: 30px;
    border-right: 1px solid rgba(13, 27, 59, 0.09);
    padding-top: 0;
    padding-bottom: 0;
  }
`;
