import { DataSource, KeyDisplayValue, OptionsProducer, RequestOptionProducer } from '@novaera/actioner-service';
import { useCallback, useState } from 'react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

export const useDataSourceMapper = () => {
  const {
    input: { value: dataSourceFormValue },
  } = useField<DataSource>('uiComponent.dataSource', { subscription: { value: true } });
  const {
    fields: { push: addNewOptionProducer },
  } = useFieldArray<Partial<OptionsProducer>>('uiComponent.dataSource.optionsProducers', { subscription: {} });
  const {
    fields: { push: addNewStaticOption },
  } = useFieldArray<KeyDisplayValue>('uiComponent.dataSource.options');
  const [showSlide, setShowSlide] = useState(false);

  const handleNewItemClick = useCallback(() => {
    if (dataSourceFormValue.type === 'dynamic') {
      const newRequestOptionProducer: Partial<RequestOptionProducer> = {
        type: 'request',
        optionTemplatePair: {
          displayValueTemplate: '',
          identifierTemplate: '',
        },
      };
      addNewOptionProducer(newRequestOptionProducer);
    } else {
      const newOption: KeyDisplayValue = {
        displayValue: '',
        key: '',
      };
      addNewStaticOption(newOption);
    }
  }, [addNewOptionProducer, addNewStaticOption, dataSourceFormValue.type]);

  const handleInitiatorClick = useCallback(() => {
    setShowSlide((prev) => !prev);
  }, []);

  const handleCloseSlide = useCallback(() => {
    setShowSlide(false);
  }, []);

  return {
    handleInitiatorClick,
    handleNewItemClick,
    showSlide,
    handleCloseSlide,
    dataSourceFormValue,
  };
};
