import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

export const NvCircularProgress: React.FC<React.PropsWithChildren<CircularProgressProps>> = (props) => (
  <CircularProgress
    {...props}
    {...(props.size
      ? { sx: { svg: { width: `${props.size}px !important`, height: `${props.size}px !important` }, ...props.sx } }
      : {})}
  />
);
