import { NvCustomHelpIcon, NvDivider, NvField, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { SearchTooltip } from '../../../../../../../components/query-search-help-tooltip/styled';
import { CronDisplay } from '../../../../components/cron-display';
import { CronInput } from '../../../../components/cron-input';
import { formatCronExpression, parseCronExpression } from '../../../../utils';
import { CronPreviewCard } from '../../styled';
import { CustomCronConfigurationProps } from './types';

export const CustomCronConfiguration: React.FC<CustomCronConfigurationProps> = ({ cron, onCronErrorStateChange }) => {
  const theme = useTheme();
  return (
    <>
      <NvDivider />
      <NvFlex gap="12px">
        <NvField
          name="recurrence.cron"
          direction="label-on-top"
          labelText="Cron expression"
          infoText={
            <SearchTooltip
              title={
                <NvFlex gap="8px">
                  <NvTypography variant="body1">
                    Add a cron expression to specify the schedule for your recurring job. Learn more about cron
                    expressions{' '}
                    <a href={'https://actioner.com/help/cron-expressions'} target="_blank" rel="noreferrer">
                      <strong>here</strong>
                    </a>
                    .
                  </NvTypography>
                </NvFlex>
              }
            >
              <NvFlex>
                <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
              </NvFlex>
            </SearchTooltip>
          }
          format={formatCronExpression}
          parse={parseCronExpression}
          component={<CronInput />}
        />

        <CronPreviewCard>
          <CronDisplay
            cron={cron}
            showErrors
            errorCallback={(error) => {
              onCronErrorStateChange(error);
            }}
          />
        </CronPreviewCard>
      </NvFlex>
    </>
  );
};
