import { FieldType, ParameterTypes, ValueTypes } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { useTheme } from '@novaera/theme-provider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../../../../../action-designer/providers/input-values/utils';
import { useRecordParametersContext } from '../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/records-parameter-mapping/provider';
import { ACTION_NODE_PROPERTIES_WRAPPER_ID } from '../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/constants';
import { RecordPartialUpdateUIComponentParams } from '../../../use-ui-component/types';
import { useRecordFieldController } from '../../record-field/controllers/use-record-field-controller';
import { RecordPartialUpdateFormValues } from '../../types';

export const useRecordPartialUpdateController = ({
  inputProps,
}: Pick<RecordPartialUpdateUIComponentParams, 'inputProps'>) => {
  const theme = useTheme();
  const { recordParameters, recordFields, isRecordParametersLoading } = useRecordParametersContext();
  const { value, onChange } = inputProps;
  const wrapperElementRef = useRef<HTMLElement | null>(null);
  const { getQueryOptions } = useRecordFieldController();
  const [selectedRecordIds, setSelectedRecordIds] = useState<string[]>(() => {
    const { types, ...parameterMappings } = value;
    return Object.keys(parameterMappings);
  });
  const selectedRecordFields = useMemo(() => {
    return recordFields.filter((r) => selectedRecordIds.includes(r.id));
  }, [recordFields, selectedRecordIds]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    const wrapperElement = document.getElementById(ACTION_NODE_PROPERTIES_WRAPPER_ID);
    wrapperElementRef.current = wrapperElement;
  }, []);

  const handleOpenFieldsToUpdate = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsPanelOpen(true);
    }, 300);
  };

  const handleCloseFieldsToUpdate = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
    setIsPanelOpen(false);
  };

  const handleFormOnChange = useCallback(
    ({ values }: { values: RecordPartialUpdateFormValues }) => {
      const { types, paths, ...parameterMappings } = values;
      const fields = formatAndFilterInputFormValuesAsParameterMappings(
        parameterMappings,
        recordParameters?.map((i) => i.id)
      );

      return onChange({ types, fields, paths });
    },
    [onChange, recordParameters]
  );

  const handleAddNewRecordField = useCallback(
    (recordField?: FieldType) => {
      if (recordField) {
        const { types, ...parameterMappings } = value;

        const fields = formatAndFilterInputFormValuesAsParameterMappings(
          parameterMappings,
          recordParameters?.map((i) => i.id)
        );
        const defaultQueryOption = getQueryOptions(recordField)[0];
        const updatedTypes = { ...types, [recordField.id]: defaultQueryOption.value };
        let updatedFields = fields;
        if (recordField.schema.type === SchemaType.boolean) {
          updatedFields = [
            ...fields,
            {
              parameterId: recordField.id,
              type: ParameterTypes.SIMPLE,
              value: recordField.schema.defaultValue ?? { type: ValueTypes.BOOLEAN, value: false },
            },
          ];
        }

        onChange({ types: updatedTypes, fields: updatedFields });
        setSelectedRecordIds((prev) => [...prev, recordField.id]);
      }
    },
    [getQueryOptions, onChange, recordParameters, value]
  );

  const handleDeleteRecordField = useCallback(
    (f: FieldType) => {
      const { types, ...parameterMappings } = value;

      const updatedParameterMappings = Object.keys(parameterMappings)
        .filter((key) => key !== f.id)
        .reduce((acc, cur) => ({ ...acc, [cur]: parameterMappings[cur] }), {});

      const updatedTypes = types
        ? Object.keys(types)
            .filter((key) => key !== f.id)
            .reduce((acc, cur) => ({ ...acc, [cur]: types[cur] }), {})
        : {};

      const fields = formatAndFilterInputFormValuesAsParameterMappings(
        updatedParameterMappings,
        recordParameters?.map((i) => i.id)
      );

      onChange({ types: updatedTypes, fields });
      setSelectedRecordIds((prev) => prev.filter((p) => p !== f.id));
    },
    [onChange, recordParameters, value]
  );

  return {
    theme,
    value,
    selectedRecordFields,
    isOpen,
    isDisabled: recordParameters.length === 0,
    isPanelOpen,
    wrapperElementRef,
    isRecordParametersLoading,
    handleOpenFieldsToUpdate,
    handleCloseFieldsToUpdate,
    handleFormOnChange,
    handleAddNewRecordField,
    handleDeleteRecordField,
  };
};
