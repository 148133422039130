import { useBatchGetUsers, useGetActiveUser } from '@novaera/actioner-service';
import { useWorkflowPermission } from '../../../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { DefaultPrincipalProps } from '../../types';
import { useSwitchToMe } from '../use-switch-to-me';

export const useDefaultPrincipal = (params: DefaultPrincipalProps) => {
  const { user: activeUser } = useGetActiveUser();
  const { data: selectedPrincipalUser } = useBatchGetUsers([params.defaultStarterUserId]);
  const { hasEditPermission } = useWorkflowPermission();
  const { handleSwitchToMe, isSaveLoading } = useSwitchToMe();

  return {
    selectedPrincipalUser: selectedPrincipalUser?.users?.[0],
    showAssignToMe: hasEditPermission && activeUser?.userId !== selectedPrincipalUser?.users?.[0]?.userId,
    onSwitchToMe: handleSwitchToMe,
    isSaveLoading,
  };
};
