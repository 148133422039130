import {
  useGetUserApp,
  useGetWorkflow,
  useGetWorkflowExecutionResult,
  useGetWorkflowNodeExecution,
  useGetWorkflowNodeExecutions,
} from '@novaera/actioner-service';
import { NvLink, NvReactJson, NvSkeleton, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { ErrorReasonCard } from '../../run-setup-workflow-step/setup-workflow-item/styled';
import { WORKFLOW_HISTORY } from '../../../../workflow-detail/constants';
import { buildResponseMessage, findResponseNodeAlias, findResponseNodeObjectKey } from '../../../utils';

export const ErrorReason = ({
  appId,
  failedWorkflowId,
  executionId,
}: {
  appId: string;
  failedWorkflowId: string;
  executionId: string | null;
}) => {
  const { data: userApp } = useGetUserApp(appId);
  const theme = useTheme();

  const workflow = useGetWorkflow({ appId: appId, workflowId: failedWorkflowId });
  const { data: workflowExecution } = useGetWorkflowExecutionResult({
    userAppId: appId,
    workflowId: failedWorkflowId,
    executionId: executionId ?? '',
  });

  const responseNodeAlias = useMemo(() => {
    if (workflowExecution?.status === 'failed') {
      return findResponseNodeAlias(workflow?.workflow?.nodeSummaries ?? {}, workflowExecution?.nodeStates ?? {});
    }
    return '';
  }, [workflowExecution?.status, workflow?.workflow?.nodeSummaries, workflowExecution?.nodeStates]);

  const { data: nodeExecutions, isFetched: isNodeExecutionsFetched } = useGetWorkflowNodeExecutions({
    appId: appId,
    workflowId: workflow?.workflow?.id,
    workflowExecutionId: workflowExecution?.status === 'failed' ? (executionId ? executionId : '') : '',
    'node-alias': responseNodeAlias,
  });

  const objectKey = useMemo(() => {
    return findResponseNodeObjectKey(nodeExecutions?.pages);
  }, [nodeExecutions?.pages]);

  const { data: nodeExecutionDetail, isLoading: isNodeExecutionDetailLoading } = useGetWorkflowNodeExecution({
    appId: appId,
    workflowId: failedWorkflowId,
    workflowExecutionId: workflowExecution?.status === 'failed' ? (executionId ? executionId : '') : '',
    nodeExecutionKey: objectKey,
  });

  const response = useMemo(() => {
    return buildResponseMessage(nodeExecutionDetail?.response);
  }, [nodeExecutionDetail?.response]);

  const isFailedResponseContentLoading: boolean = useMemo(() => {
    if (responseNodeAlias) {
      return Boolean(isNodeExecutionDetailLoading || !response);
    } else if (!responseNodeAlias && isNodeExecutionsFetched) {
      return false;
    }
    return true;
  }, [responseNodeAlias, isNodeExecutionDetailLoading, isNodeExecutionsFetched, response]);

  return (
    <ErrorReasonCard>
      {isFailedResponseContentLoading ? (
        <>
          <NvSkeleton variant="rectangular" width="60%" height="32px" />
          <NvSkeleton variant="rectangular" width="35%" height="32px" />
        </>
      ) : (
        <>
          {response ? (
            <>
              {typeof response === 'string' ? (
                <NvTypography variant="body1">{response}</NvTypography>
              ) : response ? (
                <NvReactJson src={response} />
              ) : null}
            </>
          ) : (
            <NvTypography variant="body1">
              An error occurred during the execution of the auto-run workflow. Please consult the{' '}
              <NvLink
                href={WORKFLOW_HISTORY({
                  appId,
                  workflowId: failedWorkflowId,
                })}
                target="_blank"
              >
                workflow history
              </NvLink>{' '}
              for additional information.
            </NvTypography>
          )}
        </>
      )}
    </ErrorReasonCard>
  );
};
