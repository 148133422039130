import { useEffect, useState } from 'react';
import { YOUTUBE_VIDEO_QUALITY } from './types';

const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;

export const getYoutubeVideoID = (url: string) => url.match(MATCH_URL_YOUTUBE)?.[1];

export const getYoutubeVideoThumbnailUrl = ({
  videoId,
  quality,
}: {
  videoId: string;
  quality: YOUTUBE_VIDEO_QUALITY;
}) => {
  switch (quality) {
    case YOUTUBE_VIDEO_QUALITY.LOW:
      return `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;
    case YOUTUBE_VIDEO_QUALITY.MEDIUM:
      return `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
    case YOUTUBE_VIDEO_QUALITY.HIGH:
      return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
    case YOUTUBE_VIDEO_QUALITY.MAXIMUM:
      return `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
  }
};

export const useYoutubeVideoThumbnail = ({
  url,
  quality = YOUTUBE_VIDEO_QUALITY.HIGH,
}: {
  url?: string | null;
  quality?: YOUTUBE_VIDEO_QUALITY;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const videoId = url && typeof url === 'string' ? getYoutubeVideoID(url) : null;

  const fetchYoutubeVideoThumbnail = async ({
    videoId,
    quality,
  }: {
    videoId: string;
    quality: YOUTUBE_VIDEO_QUALITY;
  }) => {
    setIsLoading(true);

    try {
      const url = getYoutubeVideoThumbnailUrl({ videoId, quality });
      await fetch(url, { mode: 'no-cors' });
      setThumbnail(url);
      setIsLoading(false);
    } catch (error) {
      setThumbnail(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (videoId) {
      fetchYoutubeVideoThumbnail({ videoId, quality });
    }
  }, [quality, videoId]);

  return { isLoading, thumbnail };
};
