import { NodeUnionTypeEnumLike, WorkflowWithState } from '@novaera/actioner-service';
import { NodeListItem } from '@novaera/core';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { userAppGraph } from '../../graph-utils/user-app-graph';
import { WorkflowComponent } from '../../use-novaera-flow/create-node-parts/types';
import { isNodeUnionType } from '../../utils';
import { ButtonContext } from '../provider';
import { connectToWorkflowNodes } from './connect-workflow-nodes';
import { replaceInReferringNode } from './replace-referring-node';

export const insertNodeToWorkflow = ({
  item,
  workflow,
  workflowComponent,
  ...rest
}: {
  item: NodeListItem;
  workflow: WorkflowWithState;
  workflowComponent: WorkflowComponent;
} & ButtonContext): WorkflowWithState => {
  assert(
    !!item.type && isNodeUnionType(item.type),
    new Error(`item type: ${item.type} should in app workflow types`),
    'ERROR'
  );

  let workflowVertices = cloneDeep(workflow?.graph?.vertices ?? []);

  const { root, nodeSummaries } = workflowComponent;

  const newNodeSummaries = { ...nodeSummaries, ...workflow.nodeSummaries };

  if (rest.type === 'Node') {
    const node = userAppGraph.node(rest.nodeId);

    if (node.type === 'blank' && workflowVertices) {
      workflowVertices = connectToWorkflowNodes({
        ...workflowComponent,
        workflowVertices,
        nextNodeAlias: node.nextNodeAlias,
      });
      // if it is blank node we need to replace it in the referring node.
      workflowVertices = replaceInReferringNode({
        workflowVertices,
        aliasToFind: node.alias,
        aliasToReplace: root.alias,
      });
      // since we replaced it we need to delete the old blank node.
      workflowVertices = workflowVertices.filter((v) => v.alias !== node.alias);
      delete newNodeSummaries[node.alias];
    } else if (node.type === 'AddButton') {
      const parentNode = node.parent ? workflowVertices.find((v) => v.alias === node.parent) : undefined;
      workflowVertices = connectToWorkflowNodes({
        ...workflowComponent,
        workflowVertices,
        nextNodeAlias: parentNode?.nextAlias,
      });

      if (parentNode) {
        workflowVertices = workflowVertices.map((v) => {
          if (v.alias === parentNode.alias) {
            v.nextAlias = root.alias;
          }
          return v;
        });
      }
    } else {
      assert(false, new Error('Node type is not blank or AddButton, or it is not a first insertion'), 'ERROR');
    }
  } else {
    assert(
      !!workflowVertices.length,
      new Error('Since there is source and targetId there should be workflow nodes already'),
      'ERROR'
    );
    const { sourceId, targetId } = rest;
    const sourceNode = userAppGraph.node(sourceId);
    const targetNode = userAppGraph.node(targetId);
    workflowVertices = connectToWorkflowNodes({
      ...workflowComponent,
      workflowVertices,
      nextNodeAlias: targetNode.type !== 'AddButton' ? targetNode.alias : undefined,
    });
    if (
      sourceNode.type === NodeUnionTypeEnumLike.branchJunction ||
      sourceNode.type === NodeUnionTypeEnumLike.workflowCondition ||
      sourceNode.type === NodeUnionTypeEnumLike.loop
    ) {
      workflowVertices = replaceInReferringNode({
        workflowVertices,
        aliasToFind: targetNode.alias,
        aliasToReplace: root.alias,
      });
    } else {
      workflowVertices = workflowVertices.map((v) => (v.alias === sourceId ? { ...v, nextAlias: root.alias } : v));
    }
  }

  return {
    ...workflow,
    graph: { vertices: workflowVertices },
    nodeSummaries: newNodeSummaries,
  };
};
