import { PropertyPanelListHeader, PropertyPanelSimpleSection } from '../../../../../../../../../../components';
import { OutputItem } from '../../../../../../../../../../components/output-item';
import { JobSampleResponseProps } from './types';

export const JobSampleResponse: React.FC<JobSampleResponseProps> = ({ content }) => (
  <>
    <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
    <PropertyPanelSimpleSection>
      <OutputItem content={content} isTestResultFailed={false} />
    </PropertyPanelSimpleSection>
  </>
);
