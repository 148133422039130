import { ConfigState } from '@novaera/actioner-service';
import {
  isRequired,
  NvBox,
  NvButton,
  NvConditionalWrap,
  NvDialogModal,
  NvField,
  NvFlex,
  NvForm,
  NvSkeleton,
  NvTextField,
  NvTooltip,
  NvTuneIcon,
  NvTypography,
} from '@novaera/core';
import { assert, noop } from '@novaera/utils';
import { Header } from '../../../../components';
import { USER_APP_CONFIG } from '../../constants';
import { ConfigDetailBody } from './config-detail-body';
import { ConfigDetailFormValues } from './config-detail-body/types';
import { ConfigDocument } from './config-document';
import { useConfigDetailController } from './controller/use-config-detail';
import { SchemaConfig } from './schema-config';
import { ConfigDetailWrapper, ContainerSidePanel } from './styled';
import { VersionHistory } from './version-history';

export const ConfigDetail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    configId,
    savedConfig,
    onModalOpenClicked,
    onModalCloseClicked,
    isOpened,
    config,
    isLoading,
    isSaveDraftConfigLoading,
    isNameUpdateLoading,
    isApplyConfigLoading,
    isDeleteDraftConfigLoading,
    configDetailHeaderActions,
    userAppId,
    handleDiscard,
    handleApply,
    handleOnChange,
    handleUpdateConfigModalSubmit,
    handleRestore,
    configPropertiesIsValidationMessage,
    validateConfig,
    validationMessages,
  } = useConfigDetailController();

  return (
    <>
      <Header
        key={`config-detail-header-${configId}`}
        name={savedConfig?.name ?? ''}
        icon={<NvTuneIcon />}
        actions={configDetailHeaderActions}
        onBack={USER_APP_CONFIG(userAppId)}
        isDraft={config?.state === ConfigState.DRAFT}
        buttonActions={
          <>
            <NvButton
              size="small"
              color="secondary"
              loading={isDeleteDraftConfigLoading}
              disabled={isApplyConfigLoading || isDeleteDraftConfigLoading}
              onClick={handleDiscard}
            >
              Discard changes
            </NvButton>

            <NvConditionalWrap
              condition={!!configPropertiesIsValidationMessage}
              wrap={(children) => {
                assert(
                  !!configPropertiesIsValidationMessage,
                  new Error('[ConfigDetail] - Validation message should not be undefined'),
                  'ERROR'
                );
                return (
                  <NvTooltip title={configPropertiesIsValidationMessage}>
                    <div>{children}</div>
                  </NvTooltip>
                );
              }}
            >
              <NvButton
                size="small"
                disabled={isSaveDraftConfigLoading || !!configPropertiesIsValidationMessage}
                loading={isApplyConfigLoading}
                onClick={handleApply}
              >
                Apply
              </NvButton>
            </NvConditionalWrap>
          </>
        }
        isLoading={isLoading}
        onEditNameClick={onModalOpenClicked}
      />
      <NvDialogModal
        onCloseButtonClicked={onModalCloseClicked}
        open={isOpened}
        Header="Edit config"
        formProps={{
          initialValues: {
            name: savedConfig?.name,
          },
        }}
        maxWidth="sm"
        fullWidth
        primaryButtonText="Update"
        onFormSubmit={handleUpdateConfigModalSubmit}
        isLoading={isNameUpdateLoading}
        Body={
          <NvFlex gap="8px">
            <NvTypography variant="body1">
              When you modify the name of the policy, it will also change the way you reference this configuration in a
              workflow.
            </NvTypography>
            <NvField
              name="name"
              labelText="Name"
              labelVariant="h5"
              direction="label-on-top"
              showErrorMessageOnlyWhenBlur
              component={<NvTextField size="medium" />}
              validators={[isRequired()]}
              hasRequiredIndicator
            />
          </NvFlex>
        }
      />
      {!config && isLoading && (
        <NvFlex
          padding="24px 16px 24px 24px"
          direction="row"
          gap="24px"
          height="100%"
          flex="1 1 auto"
          minHeight={0}
          alignItems="flex-start"
        >
          <NvBox flex="1 1 auto" minWidth={0} height="100%">
            <NvSkeleton variant="rectangular" width="100%" height="100%" />
          </NvBox>
          <NvFlex flexDirection={'column'} gap="16px" width="400px">
            <NvSkeleton variant="rectangular" width="100%" height="48px" />
            <NvSkeleton variant="rectangular" width="100%" height="48px" />
          </NvFlex>
        </NvFlex>
      )}
      {config && !isLoading && (
        <NvForm<ConfigDetailFormValues>
          onChange={({ values }) => {
            handleOnChange(values);
          }}
          initialValues={config}
          keepDirtyOnReinitialize
          onSubmit={noop}
          autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        >
          {({ valid }) => (
            <ConfigDetailWrapper>
              <NvBox flex="1 1 auto" minWidth={0} height="100%" padding="24px 0">
                <ConfigDetailBody valid={valid} />
              </NvBox>
              <ContainerSidePanel>
                <SchemaConfig config={config} validateConfig={validateConfig} validationMessages={validationMessages} />
                <ConfigDocument config={config} validateConfig={validateConfig} />
                <VersionHistory onRestore={handleRestore} />
              </ContainerSidePanel>
            </ConfigDetailWrapper>
          )}
        </NvForm>
      )}
    </>
  );
};
