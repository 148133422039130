import {
  ExternalAppConnectionType,
  Trigger,
  WorkflowWithState,
  useGetActiveUser,
  useGetWorkflow,
  useSaveToDraftWorkflow,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../controllers/use-get-workflow-query-params';

export const useSwitchToMe = () => {
  const { user: activeUser } = useGetActiveUser();
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const { mutate: saveToDraft, isLoading: isSaveLoading } = useSaveToDraftWorkflow();
  const handleSwitchToMe = useCallback(() => {
    const trigger = workflow?.trigger;
    if (trigger) {
      assert(!!activeUser, new Error('Active user should be defined when switch to me feature is used'), 'ERROR');
      let newTrigger: Trigger;
      if (trigger.type === 'integrationApp' && trigger.connectionType === ExternalAppConnectionType.NONE) {
        newTrigger = {
          ...trigger,
          defaultStarterUserId: activeUser.userId,
          connectionType: ExternalAppConnectionType.OVERRIDE_BY_DEFAULT_USER,
        };
      } else {
        newTrigger = {
          ...trigger,
          defaultStarterUserId: activeUser.userId,
        };
      }

      const newWorkflow: WorkflowWithState = {
        ...workflow,
        trigger: newTrigger,
      };
      saveToDraft(newWorkflow);
    }
  }, [activeUser, saveToDraft, workflow]);

  return { handleSwitchToMe, isSaveLoading };
};
