import metadata from '../../../design-tokens/colors';
import { Colors, Themes, ThemeMode } from '../../themes/types';
import { ColorMetaData, ColorsKey, ColorValueMetaData } from './types';

const colorMetaData: ColorMetaData = metadata.color;

export const getColors = (theme: Themes, mode: ThemeMode): Partial<Colors<string>> => {
  const activePalette = colorMetaData[theme]?.[mode] ?? colorMetaData[Themes.Default][ThemeMode.Light];
  return Object.keys(activePalette).reduce((acc, cur) => {
    const currentColorGroup = activePalette[cur as ColorsKey];
    const simplifiedCurrentColorGroup = Object.keys(currentColorGroup).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: (currentColorGroup[cur as keyof typeof currentColorGroup] as ColorValueMetaData).value,
      }),
      {}
    );
    return { ...acc, [cur]: simplifiedCurrentColorGroup };
  }, {});
};
