import { noop } from 'lodash';
import React from 'react';
import { NvButton } from '../button';
import { NvAddIcon } from '../icons';
import { AddNewButtonWrapper, LabelStyled } from './styled';
import { NvAddButtonWithLabelProps } from './types';

export const NvAddButtonWithLabel = React.forwardRef<HTMLDivElement, NvAddButtonWithLabelProps>(
  ({ onClick, label, sx, variant = 'default', disabled, className }, ref) => (
    <AddNewButtonWrapper
      ref={ref}
      sx={sx}
      disabled={disabled}
      className={className}
      onClick={disabled ? noop : onClick}
    >
      <NvButton
        onlyIcon
        size={variant === 'default' ? 'medium' : 'small'}
        color="info"
        className="add-new-button"
        disabled={disabled}
      >
        <NvAddIcon fontSize="small"></NvAddIcon>
      </NvButton>
      <LabelStyled variant={variant === 'default' ? 'h4' : 'h5'}>{label}</LabelStyled>
    </AddNewButtonWrapper>
  )
);
