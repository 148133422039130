import { useMemo } from 'react';
import { ActionNode, NodeUnion, NodeUnionType, NodeUnionTypeEnumLike, WorkflowState } from '../../../../types';
import { GetNodeResponse } from '../types';

// We added conditional response type.
// Currently actionNode is enough for us. If you need other node types you should add new conditions
export const useNode = <
  T extends NodeUnionType,
  R = T extends typeof NodeUnionTypeEnumLike.action
    ? { node: ActionNode | undefined; savedNode: ActionNode | undefined; draftNode: ActionNode | undefined }
    : { node: NodeUnion | undefined; savedNode: NodeUnion | undefined; draftNode: NodeUnion | undefined }
>(
  response?: GetNodeResponse
): R => {
  const node = useMemo(() => {
    return !response
      ? undefined
      : response.draft
      ? { ...response.draft, state: WorkflowState.DRAFT }
      : { ...response.saved, state: WorkflowState.SAVED };
  }, [response]);

  const savedNode = useMemo(() => {
    return response?.saved;
  }, [response?.saved]);

  const draftNode = useMemo(() => {
    return response?.draft;
  }, [response?.draft]);

  return {
    node,
    savedNode,
    draftNode,
  } as unknown as R;
};
