import { NvArrowForwardIcon, NvButton, NvFlex, NvSlackIcon, NvTypography } from '@novaera/core';
import { ItemContainer, ItemHeader, StyledInfo, SlackWorkspaceLogoWrapper } from './styled';
import { SlackUserMappingItemProps } from './types';

export const SlackUserMappingItem: React.FC<React.PropsWithChildren<SlackUserMappingItemProps>> = ({
  teamName,
  teamIconUrl,
  slackUserId,
  email,
  slackAppHomeLink,
  mapped,
}) => {
  const completeUserMapping = () => {
    if (slackAppHomeLink) {
      window.location.href = slackAppHomeLink;
    }
  };

  return (
    <ItemContainer direction="column" spacing="8px">
      <ItemHeader>
        <SlackWorkspaceLogoWrapper>
          {teamIconUrl ? <img alt={`${teamName}-logo`} src={teamIconUrl} /> : <NvSlackIcon />}
        </SlackWorkspaceLogoWrapper>
        <NvTypography flex="1 1 auto" variant="h4">
          {teamName}
        </NvTypography>
        <NvSlackIcon />
      </ItemHeader>
      {mapped ? (
        <NvFlex direction="row" spacing="16px">
          <NvTypography variant="body2">Slack email: {<StyledInfo>{email}</StyledInfo>}</NvTypography>
        </NvFlex>
      ) : (
        <>
          <NvTypography variant="body2">We couldn't map your user to this Slack workspace automatically.</NvTypography>
          <NvFlex flex="0 0 auto" alignItems="flex-start">
            <NvButton
              variant="contained"
              color="primary"
              size="small"
              onClick={completeUserMapping}
              endIcon={<NvArrowForwardIcon />}
            >
              Map my user
            </NvButton>
          </NvFlex>
        </>
      )}
    </ItemContainer>
  );
};
