import { NvAxios, useQuery } from '@novaera/core';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetConfigVersionParams, GetConfigVersionResponse } from './types';

const getConfigVersion = async ({ appId, configId, version }: GetConfigVersionParams) => {
  const result = await NvAxios.get<GetConfigVersionResponse>(
    `${CONFIG_ROOT_PATH(appId)}/${configId}/versions/${version}`
  );
  return result?.data;
};

export const useGetConfigVersion = ({ appId, configId, version }: GetConfigVersionParams) => {
  return useQuery<GetConfigVersionResponse>(
    QUERY_KEYS_CONFIGS.version(appId, configId, version),
    () => getConfigVersion({ appId, configId, version }),
    {
      enabled: !!appId && !!configId && !!version,
    }
  );
};
