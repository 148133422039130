import { ConnectionMapping, GetUserAppSetupResponse } from '@novaera/actioner-service';
import { NvButton, NvFlex, Step, StepTracker } from '@novaera/core';
import { FC, useMemo, useState } from 'react';
import { CompletedStep } from '../completed-step';
import { ConnectionStep } from '../connection-step';
import { COMPLETED_STEP_LABEL, CONNECTION_STEP_LABEL, SETUP_WORKFLOW_STEP_LABEL } from '../constants';
import { ErrorStep } from '../error-step';
import { InstallStepsLoading } from '../install-steps-loading';
import { RunSetupWorkflow } from '../run-setup-workflow-step';
import { StepBox } from '../styled';
import { useAutoRunWorkflows } from '../use-auto-run-workflows';

export interface StepTrackerWrapperProps {
  userAppSetup: GetUserAppSetupResponse;
  connectionMappings: ConnectionMapping[];
  onCancel: () => void;
  appId: string;
  showConnectionStep: boolean;
  showWorkflowStep: boolean;
  forceShowFilledSteps: boolean;
}

export const StepTrackerWrapper: FC<StepTrackerWrapperProps> = ({
  userAppSetup,
  connectionMappings,
  onCancel,
  appId,
  showWorkflowStep,
  showConnectionStep,
  forceShowFilledSteps,
}) => {
  const { setupWorkflows } = userAppSetup ?? {
    setupWorkflows: [],
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const {
    autoRunFailed,
    isAutoRunWorkflowsLoading,
    manualSetupWorkflows,
    refetchAutoRUnWorkflowsQuery,
    isAutoRunWorkflowExist,
    isAllAutoRunWorkflowsExecuted,
    failedWorkflows,
    isAnyAutoRunWorkflowFailed,
  } = useAutoRunWorkflows({
    enabled:
      !forceShowFilledSteps &&
      ((!showConnectionStep && !showWorkflowStep) || (!showConnectionStep && showWorkflowStep)),
    appId,
    setupWorkflows,
  });

  const installSteps = useMemo(() => {
    const installSteps: Step[] = [];

    if (showConnectionStep) {
      const connectionStep: Step = {
        content: (
          <StepBox>
            <ConnectionStep connections={connectionMappings} appId={appId} />
          </StepBox>
        ),
        label: CONNECTION_STEP_LABEL,
        showButtons: true,
        nextButton: ({ nextClick }) => (
          <NvButton
            onClick={() => {
              if (isAutoRunWorkflowExist && !isAllAutoRunWorkflowsExecuted) {
                refetchAutoRUnWorkflowsQuery();
              }
              nextClick();
            }}
            disabled={!!connectionMappings?.filter((c) => !c.connected).length}
          >
            Next
          </NvButton>
        ),
      };

      installSteps.push(connectionStep);
    }

    if (showWorkflowStep) {
      const workflowStep: Step = {
        content: (
          <StepBox>
            <RunSetupWorkflow
              workflows={manualSetupWorkflows}
              appId={appId}
              isAutoRunWorkflowsLoading={isAutoRunWorkflowsLoading}
            />
          </StepBox>
        ),
        label: SETUP_WORKFLOW_STEP_LABEL,
        showButtons: true,
        nextButton: ({ nextClick }) => (
          <NvButton
            onClick={nextClick}
            disabled={!!manualSetupWorkflows.filter((w) => w.status !== 'successful').length}
          >
            Next
          </NvButton>
        ),
      };

      installSteps.push(workflowStep);
    }

    const completedStep: Step = {
      content: () => (
        <StepBox>
          <CompletedStep
            appId={appId}
            handleNextClick={() => {
              onCancel();
            }}
          />
        </StepBox>
      ),
      label: COMPLETED_STEP_LABEL,
      showButtons: false,
    };

    installSteps.push(completedStep);

    return installSteps;
  }, [
    appId,
    connectionMappings,
    isAllAutoRunWorkflowsExecuted,
    isAutoRunWorkflowExist,
    isAutoRunWorkflowsLoading,
    manualSetupWorkflows,
    onCancel,
    refetchAutoRUnWorkflowsQuery,
    showConnectionStep,
    showWorkflowStep,
  ]);

  return isAutoRunWorkflowsLoading ? (
    <NvFlex paddingTop={!showConnectionStep && !showWorkflowStep ? '120px' : '8px'} width="100%">
      <InstallStepsLoading />
    </NvFlex>
  ) : isAnyAutoRunWorkflowFailed || autoRunFailed ? (
    <NvFlex paddingTop={!showConnectionStep && !showWorkflowStep ? '120px' : '8px'}>
      <ErrorStep appId={appId} failedWorkflows={failedWorkflows} onClose={onCancel} />
    </NvFlex>
  ) : !showWorkflowStep && !showConnectionStep ? (
    <NvFlex paddingTop={!showConnectionStep && !showWorkflowStep ? '120px' : '8px'}>
      <CompletedStep
        appId={appId}
        handleNextClick={() => {
          onCancel();
        }}
      />
    </NvFlex>
  ) : (
    <StepTracker
      steps={installSteps}
      onCancelClick={onCancel}
      initialActiveStep={activeIndex}
      setInitialActiveStep={setActiveIndex}
    />
  );
};
