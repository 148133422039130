import { NovaeraNodeWithPosition } from '@novaera/core';
import { assert } from '@novaera/utils';
import { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import { useNodeDeletion } from '../../common/use-node-deletion';
import { PropertyPanels } from '../property-panels';

export type SelectedNode = NovaeraNodeWithPosition | null;
type PropertyPanelContextType = {
  selectedNode: SelectedNode;
  setSelectedNode: (node: SelectedNode) => void;
  isPanelOpen: boolean;
  setIsPanelOpen: (isOpen: boolean) => void;
  emptySelectedNode: () => void;
  fillSelectedNode: (node: SelectedNode) => void;
  deleteNodeAndUpdateGraph: ({ nodeId }: { nodeId: string }) => Promise<void>;
};
const PropertyPanelContext = createContext<PropertyPanelContextType | undefined>(undefined);

export const PropertyPanelProvider: FC<{
  children: ((params: PropertyPanelContextType) => ReactNode) | ReactNode;
}> = ({ children }) => {
  const [selectedNode, setSelectedNode] = useState<SelectedNode>(null);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const emptySelectedNode = useCallback(() => {
    setIsPanelOpen(false);
    setTimeout(() => setSelectedNode(null), 300);
  }, []);
  const { deleteNodeAndUpdateGraph } = useNodeDeletion({ emptySelectedNode });
  const fillSelectedNode = useCallback((node: SelectedNode) => {
    setSelectedNode(node);
    setTimeout(() => setIsPanelOpen(true), 300);
  }, []);

  const value = {
    selectedNode,
    setSelectedNode,
    isPanelOpen,
    fillSelectedNode,
    emptySelectedNode,
    setIsPanelOpen,
    deleteNodeAndUpdateGraph,
  };

  return (
    <PropertyPanelContext.Provider value={value}>
      {selectedNode && <PropertyPanels />}
      {typeof children === 'function' ? children(value) : children}
    </PropertyPanelContext.Provider>
  );
};

export const usePropertyPanelContext = () => {
  const context = useContext(PropertyPanelContext);
  assert(!!context, new Error(`PropertyPanelContext should be used within AddNewNodeProvider`), 'ERROR');

  return context;
};
