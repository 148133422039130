import { useDeleteConnectionService } from '@novaera/actioner-service';
import { useConfirmDialog } from '@novaera/core';

export const useDeleteConnection = () => {
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const { mutate: deleteConnection } = useDeleteConnectionService();

  const deleteConnectionWithConfirmation = (props: { connectionId: string; connectionName: string }) => {
    openConfirm({
      title: `Delete ${props.connectionName} connection`,
      message: `You are about to delete ${props.connectionName} connection. Deleting this connection will affect any workflows associated with it.`,
      onConfirm: () => {
        deleteConnection({ connectionId: props.connectionId });
      },
      onClose: closeConfirm,
    });
  };

  return { deleteConnectionWithConfirmation };
};
