import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_INTEGRATIONS_PATH } from '../constant';
import { UpgradeIntegrationParams } from '../types';

const upgradeIntegration = async ({ integrationId, payload }: UpgradeIntegrationParams) => {
  const result = await NvAxios.post(`${ADMIN_INTEGRATIONS_PATH}/${integrationId}/upgrade`, payload);
  return result?.data;
};

export const useUpgradeIntegration = () => {
  return useMutation(upgradeIntegration);
};
