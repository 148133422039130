import { NvErrorIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { DynamicOptionsErrorBox } from './styled';
import { DynamicSelectErrorMessageProps } from './type';

export const DynamicOptionsErrorMessage: FC<React.PropsWithChildren<DynamicSelectErrorMessageProps>> = ({
  errorContext,
}) => {
  const theme = useTheme();
  return (
    <DynamicOptionsErrorBox>
      <NvFlex flexDirection="row" gap="8px" alignItems="flex-start">
        <NvErrorIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_error[40]} />
        <NvTypography variant="body2">{errorContext.message}</NvTypography>
      </NvFlex>
    </DynamicOptionsErrorBox>
  );
};
