import { APP_PERMISSION, AppConfigListItem, useDeleteConfig, useGetConfigs } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvDeleteOutlineIcon,
  NvDivider,
  NvErrorIcon,
  NvFlex,
  NvSearchEmptyState,
  NvTooltip,
  NvTuneIcon,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { isUndefined } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SimpleSearchInput } from '../../../components';
import { BaseItem } from '../../../components/base-item';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { USER_APP_CONFIG_DETAIL } from '../constants';
import { AddNewConfigModal } from './add-new-config-modal';
import { ConfigItemsLoading, ConfigsLoading } from './loading-components';

export const Config: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();

  const { userAppId } = useParams();
  const { isAppFree } = useIsAppFree({ userAppId });
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const isSearchBarTouched = useRef<boolean>();
  const [isAddConfigModalOpen, setIsAddConfigModalOpen] = useState<boolean>(false);
  const { openConfirm } = useConfirmDialog();
  const { mutate: deleteConfig } = useDeleteConfig();
  const {
    data: getConfigsQueries,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetConfigs({
    appId: userAppId,
    pageParam: {
      validate: true,
    },
  });

  const configPages = getConfigsQueries?.pages.filter((p) => p);
  const configurations = useMemo(
    () => configPages?.reduce<AppConfigListItem[]>((acc, page) => [...acc, ...page.configurations], []) ?? [],
    [configPages]
  );
  const filteredConfigurations = useMemo(
    () => (searchKeyword.length > 0 ? matchSorter(configurations, searchKeyword, { keys: ['name'] }) : configurations),
    [configurations, searchKeyword]
  );
  const hasNoConfigs = filteredConfigurations.length === 0;

  const configMenuItems = useCallback(
    ({ id, name }: { id: string; name: string }) =>
      !isAppFree
        ? [
            {
              name: 'Delete',
              onClick: () => {
                openConfirm({
                  title: `Delete ${name ?? 'This config'}`,
                  message: (
                    <NvTypography variant="body1">
                      You are about to delete <b>{name ?? 'This config'}</b>. It will impact the linked workflows,
                      potentially causing them to stop working. This process cannot be undone. Are you sure you want to
                      proceed?
                    </NvTypography>
                  ),
                  onConfirm: () => {
                    deleteConfig({ appId: userAppId, configId: id });
                  },
                });
              },
              icon: (
                <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
              ),
            },
          ]
        : [],
    [deleteConfig, isAppFree, openConfirm, theme.palette.nv_error, userAppId]
  );

  const handleAddConfig = () => {
    setIsAddConfigModalOpen(true);
  };

  return isInitialLoading && !isSearchBarTouched.current ? (
    <ConfigsLoading />
  ) : (
    <>
      <NvFlex gap="16px">
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvTuneIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
          <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
            Configs
          </NvTypography>
        </NvFlex>
        {!isInitialLoading && hasNoConfigs && searchKeyword.length === 0 ? (
          <EmptyState
            variant={EmptyStates.CONFIG}
            CustomButton={
              <FreeAppPermissionBoundary appId={userAppId}>
                <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                  <NvButton
                    startIcon={<NvAddBoxIcon />}
                    color="secondary"
                    onClick={handleAddConfig}
                    size="small"
                    sx={{ flex: '0 0 auto' }}
                  >
                    Add config
                  </NvButton>
                </UserAppPermissionBoundary>
              </FreeAppPermissionBoundary>
            }
          />
        ) : (
          <>
            <NvFlex direction="row" alignItems="center" justifyContent="space-between">
              <SimpleSearchInput
                placeholder="Search config"
                onKeywordChanged={(keyword) => {
                  setSearchKeyword(keyword ?? '');
                  isSearchBarTouched.current = true;
                }}
              />
              <FreeAppPermissionBoundary appId={userAppId}>
                <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                  <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={handleAddConfig}>
                    Add config
                  </NvButton>
                </UserAppPermissionBoundary>
              </FreeAppPermissionBoundary>
            </NvFlex>

            {isInitialLoading && isSearchBarTouched.current ? (
              <ConfigItemsLoading />
            ) : hasNoConfigs && searchKeyword.length > 0 ? (
              <NvSearchEmptyState text={'No configs found. Try different words or clear search bar.'} />
            ) : (
              <NvFlex gap="8px">
                {filteredConfigurations.map(({ id, name, createdAt, isDraft, valid }) => {
                  const resultOfConfigMenuItems = configMenuItems({ id, name });
                  return (
                    <BaseItem
                      key={`config-item-${id}`}
                      name={
                        <NvFlex direction="row" alignItems="center" gap="8px" flex="0 1 auto" minWidth={0}>
                          <NvFlex gap={'4px'} direction="row" alignItems="center" flex="0 1 auto" minWidth={0}>
                            {!isUndefined(valid) && !valid && (
                              <NvTooltip title="This config file contains conflicts with its schema.">
                                <NvErrorIcon
                                  htmlColor={theme.palette.nv_error[40]}
                                  sx={{
                                    width: '16px',
                                    height: '16px',
                                  }}
                                />
                              </NvTooltip>
                            )}
                            <NvTypography variant="h4" noWrap>
                              {name}
                            </NvTypography>
                          </NvFlex>
                          <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                          <NvTypography variant="body2" textColor="secondary" noWrap>
                            {format(new Date(createdAt), 'PPpp')}
                          </NvTypography>
                        </NvFlex>
                      }
                      isDraft={isDraft}
                      to={USER_APP_CONFIG_DETAIL(userAppId, id)}
                      {...(resultOfConfigMenuItems.length > 0 ? { menuItems: resultOfConfigMenuItems } : {})}
                    />
                  );
                })}
                {hasNextPage && (
                  <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
                    <NvButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        fetchNextPage();
                      }}
                      loading={isFetchingNextPage}
                      disabled={isFetchingNextPage}
                    >
                      Show more
                    </NvButton>
                  </NvFlex>
                )}
              </NvFlex>
            )}
          </>
        )}
      </NvFlex>
      <AddNewConfigModal
        isOpen={isAddConfigModalOpen}
        onClose={() => {
          setIsAddConfigModalOpen(false);
        }}
      />
    </>
  );
};
