import { css } from '@emotion/css';
import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const UserAppPageLayoutWrapper = styled(NvFlex)`
  height: 100vh;
  flex: 1 1 auto;
  min-width: 0;
  padding: 40px 40px 60px 40px;
  align-items: center;
  overflow: auto;

  @media screen and (max-width: 1440px) {
    padding: 24px 24px 60px 24px;
  }
`;

export const DescriptionPageWrapper = css`
  max-width: 1024px;
`;

export const StandardPageWrapper = css`
  max-width: 768px;
`;

export const BasePageWrapper = css`
  width: 100%;
  gap: 24px;
`;
