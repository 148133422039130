import Drawer from '@mui/material/Drawer';
import { styled } from '@novaera/theme-provider';

export const DrawerBackground = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'borderRadius',
})<{
  fullWidth?: boolean;
  borderRadius?: string;
}>`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[500]};
  }

  .MuiDrawer-paper {
    ${({ theme }) => theme.elevations.e500};
    border-radius: ${({ anchor, borderRadius }) =>
      borderRadius
        ? borderRadius
        : anchor === 'left'
        ? '0px 30px 30px 0px'
        : anchor === 'right'
        ? '30px 0 0 30px'
        : '0'};

    ${({ fullWidth }) => (fullWidth ? 'width: 100%' : null)};
  }
`;

export const DrawerInnerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'width',
})<{ fullWidth?: boolean; width?: number | string }>`
  height: 100%;
  width: ${({ fullWidth, width }) =>
    fullWidth ? '100%' : typeof width === 'string' ? width : `${width ? width : '764'}px`};
`;
