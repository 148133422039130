import { APP_PERMISSION, useGetUserApp } from '@novaera/actioner-service';

import { useCallback } from 'react';
import { UseUserAppPermissionBoundary } from './types';

export const useUserAppPermissionBoundary: UseUserAppPermissionBoundary = ({ appId, appPermissions }) => {
  const { data: app } = useGetUserApp(appId);

  return {
    checkPermission: useCallback(
      (permission: APP_PERMISSION) => {
        const permissions = (appPermissions ? appPermissions : app?.permissions) ?? {};
        return Boolean(permissions[permission]);
      },
      [appPermissions, app?.permissions]
    ),
  };
};
