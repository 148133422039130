import { Workflow } from '@novaera/actioner-service';
import { NovaeraNode, NovaeraNodeWithPosition, NvGraph } from '@novaera/core';
import { AppWorkflowHelperNodeType } from '../components/user-app-workflow-node-types/types';
import { convertBackendToUi } from '../service/get-nodes';
import { GraphComponent } from './create-node-parts/types';
import { useRefreshCanvas } from './use-refresh-canvas';

type InsertContext = { newWorkflow: Workflow };

export const useNovaeraFlow = <NodeType extends string>(graph: NvGraph<NodeType | AppWorkflowHelperNodeType>) => {
  const { refreshCanvas, updateNodeInReactFlow, refreshEdgesInReactFlow } = useRefreshCanvas(graph);

  const insertTriggerNode = ({ graphComponent }: { graphComponent: GraphComponent<NodeType> }) => {
    graph.initialize({ nodes: [], edges: [] });
    graph.addNode(graphComponent.root);
    const buttonNode: NovaeraNode<'AddButton'> = {
      id: `add-button-alias`,
      name: `add-button-alias`,
      width: 40,
      height: 40,
      type: 'AddButton',
      alias: `add-button-alias`,
    };
    graph.addNode(buttonNode);
    graph.addEdge({
      sourceId: graphComponent.root.alias,
      targetId: buttonNode.alias,
      id: `${graphComponent.root.alias}-${buttonNode.alias}`,
      extraData: { type: 'Edge' },
    });
    refreshCanvas({ keepSelected: true });
  };

  const updateWholeGraph = ({ newWorkflow }: InsertContext) => {
    convertBackendToUi({ workflow: newWorkflow });
    refreshCanvas({ keepSelected: true });
  };

  const updateNode = ({ newNode }: { newNode: NovaeraNodeWithPosition<NodeType | AppWorkflowHelperNodeType> }) => {
    graph.addNode(newNode);
    updateNodeInReactFlow({ newNode });
  };

  const updateOutEdgesNames = ({ nodeAlias, newNames }: { nodeAlias: string; newNames: string[] }) => {
    const edges = graph.outEdges(nodeAlias);
    const newEdges = edges?.map((e, index) => ({ ...e, extraData: { ...e.extraData, name: newNames[index] } }));
    newEdges?.forEach((e) => graph.addEdge(e));
    refreshEdgesInReactFlow();
  };

  return {
    insertNode: updateWholeGraph,
    insertTriggerNode,
    deleteNode: updateWholeGraph,
    updateNode,
    updateWholeGraph,
    updateOutEdgesNames,
  };
};
