import {
  NvBox,
  NvButton,
  NvCloseIcon,
  NvConditionalWrap,
  NvCustomEmptyIcon,
  NvCustomStringFieldIcon,
  NvFlex,
  NvSkeleton,
  NvTooltip,
  NvTypography,
  Portal,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useEffect, useRef, useState } from 'react';
import { useRecordParametersContext } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/records-parameter-mapping/provider';
import { ACTION_NODE_PROPERTIES_WRAPPER_ID } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/constants';
import { RowItemCard } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/common/row-item-card';
import { SimpleLabelLayout } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/common/row-item-card/row-item-layouts';
import { ParameterMapperForm } from '../../parameter-mapper/parameter-mapper-form';
import {
  PPDrawerItem,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
} from '../../property-panel';
import { RECORD_FIELDS_ROW_ITEM_WARNING_MESSAGE } from '../common/constants';
import { RecordAttributesUIComponentParams } from '../use-ui-component/types';

export const RecordUpdateAttributesComponent: React.FC<React.PropsWithChildren<RecordAttributesUIComponentParams>> = ({
  inputProps,
  context,
}) => {
  const theme = useTheme();
  const { recordParameters, isRecordParametersLoading } = useRecordParametersContext();
  const { value, onChange } = inputProps;
  const wrapperElementRef = useRef<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const isDisabled = recordParameters.length === 0;
  useEffect(() => {
    const wrapperElement = document.getElementById(ACTION_NODE_PROPERTIES_WRAPPER_ID);
    wrapperElementRef.current = wrapperElement;
  }, []);
  const handleOpenFieldsToUpdate = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsPanelOpen(true);
    }, 300);
  };

  const handleCloseFieldsToUpdate = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
    setIsPanelOpen(false);
  };

  return isRecordParametersLoading ? (
    <NvSkeleton variant="rectangular" height="44px" />
  ) : (
    <>
      <NvConditionalWrap
        condition={isDisabled}
        wrap={(children) => (
          <NvTooltip title={RECORD_FIELDS_ROW_ITEM_WARNING_MESSAGE} children={<NvBox>{children}</NvBox>} />
        )}
      >
        <RowItemCard
          isSelected={isOpen}
          rowItemLeftContentProps={{ draggable: false, icon: <NvCustomStringFieldIcon /> }}
          rowItemContent={<SimpleLabelLayout simpleLabel="Fields" />}
          isDisabled={isDisabled}
          onClick={() => {
            if (isOpen && isPanelOpen) {
              handleCloseFieldsToUpdate();
            } else {
              handleOpenFieldsToUpdate();
            }
          }}
        />
      </NvConditionalWrap>
      {isOpen && (
        <Portal node={wrapperElementRef.current}>
          <PPDrawerItem zIndex="1000" className={isPanelOpen ? 'open' : 'close'}>
            <PropertyPanelHeader
              title="Fields"
              icon={<NvCustomStringFieldIcon />}
              actions={
                <NvButton onlyIcon size="small" color="secondary" onClick={handleCloseFieldsToUpdate}>
                  <NvCloseIcon />
                </NvButton>
              }
            />
            <PropertyPanelSimpleSection>
              <NvTypography variant="body2">
                To update the selected record, provide the new values for the inputs below.
              </NvTypography>
            </PropertyPanelSimpleSection>
            <PropertyPanelListHeader title="Update Fields" />
            <PropertyPanelSimpleSection>
              {recordParameters.filter(({ name }) => name !== 'id').length === 0 ? (
                <NvFlex direction="row" alignItems="center" gap="8px">
                  <NvCustomEmptyIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[60]} />
                  <NvTypography variant="body2" textColor="ghost">
                    There is no fields
                  </NvTypography>
                </NvFlex>
              ) : (
                <ParameterMapperForm
                  dynamicInputParameters={[]}
                  context={context}
                  inputParameters={recordParameters.filter(({ name }) => name !== 'id')}
                  initialParameterMappings={value}
                  onParameterMappingsChanged={onChange}
                />
              )}
            </PropertyPanelSimpleSection>
          </PPDrawerItem>
        </Portal>
      )}
    </>
  );
};
