import {
  useGetBilling,
  useGetSubscriptionStatus,
  useUpdateBillingPreferencesExceedDollars,
} from '@novaera/actioner-service';
import { NvAttachMoney, NvButton, NvFade, NvFlex, NvLink, NvSelect, NvTextField, NvTypography } from '@novaera/core';
import { FC, useMemo, useState } from 'react';
import { SPENDING_LIMIT_OPTIONS } from '../constants';
import { SPENDING_LIMIT } from '../types';
import { UpdateSpendingLimitProps } from './types';

export const UpdateSpendingLimit: FC<UpdateSpendingLimitProps> = ({ initialBillingPreferences }) => {
  const [exceedUpToDollars, setExceedUpToDollars] = useState<number>(initialBillingPreferences.exceedUpToDollars);
  const [selectedSpendingLimitOption, setSelectedSpendingLimitOption] = useState<SPENDING_LIMIT>(() =>
    exceedUpToDollars === -1 ? SPENDING_LIMIT.UNLIMITED : SPENDING_LIMIT.LIMITED
  );
  const { data: billing } = useGetBilling();
  const { data: subscriptions } = useGetSubscriptionStatus();
  const { mutate: updateBillingPreferencesExceedDollars, isLoading: isUpdatingBillingPreferencesExceedDollars } =
    useUpdateBillingPreferencesExceedDollars();
  const [isSpendingLimitInputFocused, setIsSpendingLimitInputFocused] = useState<boolean>(false);
  const extraPricingAmountInUSD = useMemo(() => {
    if (billing?.availablePlansByGroupId) {
      const currentPlan = Object.values(billing.availablePlansByGroupId)
        .flat()
        .find((plan) => plan.id === subscriptions?.planId);
      const nodeExecutionResourceExtraPricing = currentPlan?.resourceExtraPricings.find(
        (p) => p.resource === 'node-execution'
      );

      if (billing?.currentSubscriptionDetails.resourceExtraPricingInCents) {
        return Number(billing?.currentSubscriptionDetails.resourceExtraPricingInCents) / 100;
      }

      if (nodeExecutionResourceExtraPricing) {
        return Number(nodeExecutionResourceExtraPricing.amountInCents) / 100;
      } else {
        return undefined;
      }
    }
    return;
  }, [
    billing?.availablePlansByGroupId,
    billing?.currentSubscriptionDetails.resourceExtraPricingInCents,
    subscriptions?.planId,
  ]);
  return (
    <NvFlex gap="12px">
      <NvFlex gap="8px">
        <NvTypography variant="h4">Limits for additional credits</NvTypography>
        <NvTypography variant="body2">
          Create a monthly spending limit that can be modified at any time. Learn more about{' '}
          <NvLink target="_blank" href="https://actioner.com/help/subscription-plans#spending-limit">
            spending limits
          </NvLink>
          .
        </NvTypography>
      </NvFlex>
      <NvFlex maxWidth="300px">
        <NvSelect
          value={selectedSpendingLimitOption}
          options={SPENDING_LIMIT_OPTIONS}
          onChange={(e) => {
            const limitOption = e.target.value as SPENDING_LIMIT;
            setSelectedSpendingLimitOption(limitOption);

            if (exceedUpToDollars === -1 && limitOption === SPENDING_LIMIT.LIMITED) {
              setExceedUpToDollars(0);
            }
          }}
        />
      </NvFlex>

      {selectedSpendingLimitOption === SPENDING_LIMIT.LIMITED && (
        <NvFlex gap="8px">
          <NvFlex direction="row" alignItems="center" gap="10px" maxWidth="500px">
            <NvTextField
              startIcon={<NvAttachMoney />}
              value={exceedUpToDollars}
              onChange={(e) => {
                setExceedUpToDollars(e.target.value as unknown as number);
              }}
              fullWidth
              onBlur={() => {
                setIsSpendingLimitInputFocused(false);
              }}
              onFocus={() => {
                setIsSpendingLimitInputFocused(true);
              }}
              inputProps={{ step: 1, min: 0, type: 'number' }}
            />

            <NvFade
              in={isSpendingLimitInputFocused || isUpdatingBillingPreferencesExceedDollars}
              unmountOnExit
              mountOnEnter
            >
              <NvButton
                size="small"
                color="secondary"
                sx={{ flex: '0 0 auto' }}
                loading={isUpdatingBillingPreferencesExceedDollars}
                disabled={isUpdatingBillingPreferencesExceedDollars}
                onClick={() => {
                  updateBillingPreferencesExceedDollars({ exceedUpToDollars });
                }}
              >
                Update limit
              </NvButton>
            </NvFade>
          </NvFlex>
          {extraPricingAmountInUSD && (
            <NvTypography variant="body3">
              <b>1 credit = ${extraPricingAmountInUSD} for your plan.</b> Set $0.00 to prevent any additional expenses.
            </NvTypography>
          )}
        </NvFlex>
      )}
      {selectedSpendingLimitOption === SPENDING_LIMIT.UNLIMITED && (
        <NvFlex direction="row" alignItems="center" gap="8px">
          {exceedUpToDollars > -1 && (
            <NvButton
              size="small"
              color="primary"
              onClick={() => {
                updateBillingPreferencesExceedDollars({ exceedUpToDollars: -1 });
              }}
              loading={isUpdatingBillingPreferencesExceedDollars}
              disabled={isUpdatingBillingPreferencesExceedDollars}
            >
              Update limit
            </NvButton>
          )}
          <NvTypography variant="body2" textColor="secondary">
            Workflow runs will not be constrained by additional credit spending.
          </NvTypography>
        </NvFlex>
      )}
    </NvFlex>
  );
};
