import { SearchWorkflow, useGetIntegration } from '@novaera/actioner-service';
import { Initial, NvBox, NvCustomEmptyIcon, NvImage, NvTooltip } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { WorkflowHistoryNodeExecutionIcon } from '../../workflow-history/workflow-history-node-executions/workflow-history-node-execution/icon';
import { WORKFLOW_TRIGGER_TOOLTIP_TEXT } from '../constants';

export const WorkflowTriggerIcon = ({ trigger }: { trigger: SearchWorkflow['trigger'] }) => {
  const theme = useTheme();

  const integrationParams = useMemo(() => {
    if (
      trigger &&
      (trigger.type === 'integrationApp' || trigger.type === 'integrationWebhook') &&
      trigger.integration
    ) {
      return { id: trigger.integration.id };
    } else {
      return { id: undefined };
    }
  }, [trigger]);

  const { data: integration } = useGetIntegration(integrationParams);

  const content = useMemo(() => {
    return trigger?.type
      ? integration
        ? {
            title: integration.name,
            icon: (
              <NvImage
                imageShape="square"
                src={integration.logoUrl}
                FallBack={<Initial size="smaller" value={integration.name} color={theme.palette.nv_neutral[500]} />}
                size="smaller"
                hasNoElevation
              />
            ),
          }
        : {
            title: WORKFLOW_TRIGGER_TOOLTIP_TEXT[trigger.type],
            icon: (
              <WorkflowHistoryNodeExecutionIcon
                type={trigger?.type}
                width={20}
                height={20}
                htmlColor={theme.palette.nv_neutral[1000]}
              />
            ),
          }
      : {
          title: WORKFLOW_TRIGGER_TOOLTIP_TEXT['non-set'],
          icon: <NvCustomEmptyIcon sx={{ width: '20px', height: '20px' }} htmlColor={theme.palette.nv_neutral[60]} />,
        };
  }, [integration, theme.palette.nv_neutral, trigger?.type]);

  return (
    <NvTooltip title={content.title} placement="top">
      <NvBox width="20px" height="20px">
        {content.icon}
      </NvBox>
    </NvTooltip>
  );
};
