import { DefaultRenderOption, NvPaper } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ActionSelectMenu = styled(NvPaper)`
  margin-top: 6px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e400};
  border-radius: 12px;

  .MuiAutocomplete-listbox {
    margin: 0;
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .MuiAutocomplete-noOptions {
    text-align: center;
    padding: 32px;
    color: ${({ theme }) => theme.palette.nv_neutral_alpha[600]};
  }
`;

export const ActionSelectMenuItem = styled(DefaultRenderOption)`
  &.MuiAutocomplete-option {
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px 6px 6px;
  }
`;
