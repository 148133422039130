import { NvAxios, useMutation, useToast } from '@novaera/core';
import { FileUploadParams, FileUploadResponse } from './types';

const fileUpload = async (params: FileUploadParams) => {
  const body = new FormData();
  body.append('data', params.data);
  body.append('type', params.data.type);
  const result = await NvAxios.post<FileUploadResponse>('/v1/file-upload/temporary', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return result?.data;
};

export const useFileUpload = () => {
  const { addToast } = useToast();
  return useMutation(fileUpload, {
    onError: () => {
      addToast('File could not be uploaded', { variant: 'error' });
    },
  });
};
