export default {
  effect: {
    elevation: {
      e100: {
        '0': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 6,
            color: '#0a2d620f',
            offsetX: 0,
            offsetY: 2,
            spread: 0,
          },
        },
        '1': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 0,
            color: '#0a2d620a',
            offsetX: 0,
            offsetY: 0,
            spread: 1,
          },
        },
        description: null,
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:649915b8282f83d924d5f0efce7fd3a13ccede70,',
            exportKey: 'effect',
          },
        },
      },
      e200: {
        '0': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 15,
            color: '#0a2d6214',
            offsetX: 0,
            offsetY: 8,
            spread: 0,
          },
        },
        '1': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 0,
            color: '#0a2d620a',
            offsetX: 0,
            offsetY: 0,
            spread: 1,
          },
        },
        description: null,
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:d4ac01c9539eb6658ba8df26bf00d297b8be540d,',
            exportKey: 'effect',
          },
        },
      },
      e300: {
        '0': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 20,
            color: '#0a2d6214',
            offsetX: 0,
            offsetY: 10,
            spread: 0,
          },
        },
        '1': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 0,
            color: '#0a2d620a',
            offsetX: 0,
            offsetY: 0,
            spread: 1,
          },
        },
        description: null,
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:51a7123fa3e2adaa1b4d117714c449fcb21c4751,',
            exportKey: 'effect',
          },
        },
      },
      e400: {
        '0': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 25,
            color: '#0a2d6214',
            offsetX: 0,
            offsetY: 15,
            spread: -2,
          },
        },
        '1': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 0,
            color: '#0a2d620a',
            offsetX: 0,
            offsetY: 0,
            spread: 1,
          },
        },
        description: null,
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:8d237d4d93effc8322c8e53d023aa4f5d5d1b428,',
            exportKey: 'effect',
          },
        },
      },
      e500: {
        '0': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 30,
            color: '#0a2d6214',
            offsetX: 0,
            offsetY: 18,
            spread: -3,
          },
        },
        '1': {
          type: 'custom-shadow',
          value: {
            shadowType: 'dropShadow',
            radius: 0,
            color: '#0a2d620a',
            offsetX: 0,
            offsetY: 0,
            spread: 1,
          },
        },
        description: null,
        extensions: {
          'org.lukasoppermann.figmaDesignTokens': {
            styleId: 'S:4bc2146a15e286269d53ebb11b93d37acc710b90,',
            exportKey: 'effect',
          },
        },
      },
    },
  },
};
