import { ParameterMappings, useGetWorkflow, useGetWorkflowSampleResponse } from '@novaera/actioner-service';

import { Context, NvConditionalRender, NvFlex, NvSkeleton } from '@novaera/core';
import { OutputItem } from '../../../../../../../../../components/output-item';

import { FC, useCallback, useMemo } from 'react';
import { InputValuesProvider } from '../../../../../../../../../action-designer/providers/input-values';
import {
  PropertyPanelEmptySection,
  PropertyPanelListHeader,
  PropertyPanelSimpleSection,
  WorkflowParameterMapper,
} from '../../../../../../../../../components';

export const WorkflowInputs: FC<
  React.PropsWithChildren<{
    workflowId: string;
    initialParameterMappings: ParameterMappings;
    onChange: ({ parameterMappings, formId }: { parameterMappings: ParameterMappings; formId?: string }) => void;
    context?: Context;
    isDisplayValueConfigurable?: boolean;
    userAppId: string;
  }>
> = ({
  workflowId,
  onChange,
  initialParameterMappings,
  context = {},
  isDisplayValueConfigurable = false,
  userAppId,
}) => {
  const { workflow, isLoading } = useGetWorkflow({ appId: userAppId, workflowId });

  const { data: workflowSampleResponse, isLoading: isWorkflowSampleResponseLoading } = useGetWorkflowSampleResponse({
    appId: userAppId,
    workflowId,
  });

  const handleOnChangeParameterMappings = useCallback(
    ({ parameterMappings, formId }: { parameterMappings: ParameterMappings; formId?: string }) => {
      onChange({ parameterMappings, formId });
    },
    [onChange]
  );

  const initialParameterMapping = useMemo(
    () =>
      initialParameterMappings.reduce((prev, current) => {
        return { ...prev, [current.parameterId]: current };
      }, {}),
    [initialParameterMappings]
  );

  const getParsedJSON = useCallback((jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return {};
    }
  }, []);

  return (
    <>
      <PropertyPanelListHeader
        title="Workflow inputs"
        tooltip="You have to provide values for all the required inputs."
      />
      <PropertyPanelSimpleSection>
        {isLoading ? (
          <NvFlex gap="16px">
            <NvSkeleton variant="rectangular" height="32px" />
            <NvSkeleton variant="rectangular" height="32px" />
            <NvSkeleton variant="rectangular" height="32px" />
          </NvFlex>
        ) : (
          workflow && (
            <InputValuesProvider initialInputFormValues={initialParameterMapping}>
              <WorkflowParameterMapper
                key={workflowId}
                context={context}
                appId={userAppId}
                workflowId={workflowId}
                onParameterMappingsChanged={handleOnChangeParameterMappings}
                initialParameterMappings={initialParameterMappings}
                workflow={workflow}
                isDisplayValueConfigurable={isDisplayValueConfigurable}
              />
            </InputValuesProvider>
          )
        )}
      </PropertyPanelSimpleSection>

      <>
        <PropertyPanelListHeader title="Response" tooltip={'The response displayed below is solely a sample.'} />
        {isWorkflowSampleResponseLoading || !workflowSampleResponse ? (
          <PropertyPanelSimpleSection>
            <NvFlex gap="16px">
              <NvSkeleton variant="rectangular" height="32px" />
              <NvSkeleton variant="rectangular" height="32px" />
            </NvFlex>
          </PropertyPanelSimpleSection>
        ) : (
          <NvConditionalRender
            when={workflowSampleResponse.totalResponseCount === 1 && Boolean(workflowSampleResponse.exampleResponse)}
          >
            <PropertyPanelSimpleSection>
              <OutputItem content={getParsedJSON(workflowSampleResponse.exampleResponse ?? '{}')} />
            </PropertyPanelSimpleSection>
          </NvConditionalRender>
        )}

        <NvConditionalRender when={workflowSampleResponse?.totalResponseCount === 0}>
          <PropertyPanelEmptySection emptyText="   Sample response hasn’t defined for the selected workflow." />
        </NvConditionalRender>
        <NvConditionalRender when={Boolean(workflowSampleResponse && workflowSampleResponse.totalResponseCount > 1)}>
          <PropertyPanelEmptySection emptyText="Due to multiple response nodes, a sample response cannot be shown for the selected workflow." />
        </NvConditionalRender>
      </>
    </>
  );
};
