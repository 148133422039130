import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';

export const FlaggerPositionBox = styled(NvBox, {
  shouldForwardProp: (prop) => prop !== 'positionX' && prop !== 'positionY',
})<{
  positionX: 'start' | 'center' | 'end';
  positionY: 'start' | 'center' | 'end';
}>`
  position: absolute;
  z-index: 1999;
  ${({ positionX, positionY }) => {
    const x =
      positionX === 'start'
        ? 'left: calc(64px + 16px);'
        : positionX === 'center'
        ? 'left: calc(50%); transform: translateX(-50%);'
        : positionX === 'end'
        ? 'right: 16px;'
        : '';

    const y =
      positionY === 'start'
        ? 'top: 16px;'
        : positionY === 'center'
        ? 'top: calc(50%); transform: translateY(-50%);'
        : positionY === 'end'
        ? 'bottom: 16px;'
        : '';
    return `${x} ${y}`;
  }}
`;
