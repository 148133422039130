import { ConnectionSchema, VariableSchema } from '@novaera/actioner-service';
import { NvDialogModal, useForm } from '@novaera/core';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { DETAIL_FORM_NAME } from '../../../../../../../../../../components/detail-layout';
import { RequestVariableEditParams } from '../../../types';
import { VARIABLE_SCHEMA_FIELD_NAME } from '../../body';

type ModalProps = Pick<React.ComponentPropsWithoutRef<typeof NvDialogModal>, 'Header' | 'primaryButtonText'>;

export const useVariablesModalController = (params: {
  passedParams?: RequestVariableEditParams;
  onModalClosed: () => void;
}) => {
  const { onModalClosed, passedParams } = params;

  const { mutators, registerField, getState } = useForm<ConnectionSchema>(DETAIL_FORM_NAME);
  const variableSchema = passedParams?.variableSchema;
  const isInEditMode = Boolean(variableSchema);
  const rowIndex = passedParams?.rowIndex;
  const baseUrl = getState().values?.authentication?.baseUrl;

  useEffect(() => {
    registerField(VARIABLE_SCHEMA_FIELD_NAME, noop, { value: true, error: true });
  }, [registerField]);

  const updateFormValues = useCallback(
    (value: VariableSchema) => {
      mutators.update(VARIABLE_SCHEMA_FIELD_NAME, rowIndex, value);
    },
    [mutators, rowIndex]
  );

  const addFormValues = useCallback(
    (value: VariableSchema) => {
      mutators.push(VARIABLE_SCHEMA_FIELD_NAME, value);
    },
    [mutators]
  );

  const handleFormSubmit = useCallback(
    (value: VariableSchema) => {
      if (!isInEditMode) {
        addFormValues(value);
      } else {
        updateFormValues(value);
      }

      onModalClosed();
    },
    [addFormValues, isInEditMode, onModalClosed, updateFormValues]
  );

  const modalProps: ModalProps = useMemo(
    () => ({
      Header: !isInEditMode ? 'Add request' : 'Edit request',
      primaryButtonText: !isInEditMode ? 'Add' : 'Update',
    }),
    [isInEditMode]
  );

  return {
    onFormSubmit: handleFormSubmit,
    modalProps,
    variableSchema,
    baseUrl,
  };
};
