import {
  BlankVertex,
  BranchJunctionNodeSummary,
  DefaultNodeSummary,
  NodeSummaries,
  UpdateWorkflowGraphParams,
  useUpdateWorkflowGraph,
  Vertices,
  WorkflowWithState,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { cloneDeep } from 'lodash';
import { useRef } from 'react';
import { deleteBranch } from '../../../../../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/service/get-nodes/utils/delete-branch';
import { userAppGraph } from '../../../../../../../../user-app-workflow-canvas/graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../../../../../user-app-workflow-canvas/use-novaera-flow';

export const useBranchesController = () => {
  const { userAppId, workflowId } = useParams();

  const { mutate: updateGraph, isLoading } = useUpdateWorkflowGraph();
  const { updateWholeGraph } = useNovaeraFlow(userAppGraph);

  const branchRef = useRef<HTMLDivElement>(null);

  const handleOnNewBranchAdded = ({
    branchJunctionRootAlias,
    branches = [],
    workflow,
  }: {
    branchJunctionRootAlias: string;
    branches: BranchJunctionNodeSummary['branches'];
    workflow: WorkflowWithState;
  }) => {
    if (isLoading) {
      return;
    }
    const { graph, nodeSummaries } = workflow;
    assert(!!graph, new Error('Workflow graph not found'), 'ERROR');

    const { vertices } = graph;
    const branchJunctionRoot = cloneDeep(vertices.find((v) => v.alias === branchJunctionRootAlias));

    assert(
      !!branchJunctionRoot && branchJunctionRoot.type === 'branchJunction',
      new Error('Branch junction root not found'),
      'ERROR'
    );

    const nameAliasPair = userAppGraph.getNewAlias('blank');
    const newBlankVertex: BlankVertex = {
      alias: nameAliasPair.newAlias,
      type: 'blank',
    };

    const newBlankBranch: DefaultNodeSummary = {
      alias: nameAliasPair.newAlias,
      name: nameAliasPair.newName,
      type: 'blank',
    };
    const branchJunctionRootSummary = cloneDeep(nodeSummaries[branchJunctionRootAlias]);

    assert(branchJunctionRootSummary.type === 'branchJunction', new Error('Branch junction root not found'), 'ERROR');

    branchJunctionRootSummary.branches = [...branches, { name: 'New Branch' }];

    branchJunctionRoot.firstInnerAliases = [...branchJunctionRoot.firstInnerAliases, newBlankVertex.alias];

    const newVertices: Vertices = vertices.map((v) => (v.alias === branchJunctionRootAlias ? branchJunctionRoot : v));
    const newNodeSummaries: NodeSummaries = {
      [newBlankBranch.alias]: newBlankBranch,
      [branchJunctionRootAlias]: branchJunctionRootSummary,
    };

    const newGraph = {
      graph: {
        ...graph,
        vertices: [...newVertices, newBlankVertex],
      },

      updates: { nodes: Object.values(newNodeSummaries) },
    };

    updateBackendAndGraph({ oldWorkflow: workflow, newGraph });

    if (branchRef.current) {
      branchRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDeleteBranch = ({
    branchJunctionRootAlias,
    branchIndex,
    workflow,
  }: {
    branchJunctionRootAlias: string;
    branchIndex: number;
    workflow: WorkflowWithState;
  }) => {
    if (isLoading) {
      return;
    }

    assert(!!workflow.graph, new Error('Workflow not found'), 'ERROR');

    const { vertices, nodeSummary } = deleteBranch({
      branchJunctionRootAlias,
      deletedBranchIndex: branchIndex,
      vertices: workflow.graph.vertices,
      nodeSummaries: workflow.nodeSummaries,
    });

    const newGraph = {
      graph: {
        vertices,
      },
      ...(nodeSummary && { updates: { nodes: [nodeSummary] } }),
    };

    updateBackendAndGraph({ oldWorkflow: workflow, newGraph });
  };

  const updateBackendAndGraph = ({
    oldWorkflow,
    newGraph,
  }: {
    oldWorkflow: WorkflowWithState;
    newGraph: UpdateWorkflowGraphParams['payload'];
  }) => {
    updateGraph(
      { appId: userAppId, workflowId, payload: newGraph },
      {
        onSuccess: ({ draft }) => {
          updateWholeGraph({ newWorkflow: { ...oldWorkflow, ...draft } });
        },
      }
    );
  };

  return {
    onNewBranchAdded: handleOnNewBranchAdded,
    branchRef,
    handleDeleteBranch,
  };
};
