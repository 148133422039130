import { SlackBlockKitBlockType } from '@novaera/actioner-service';
import { CodeInput, NvCustomHelpIcon, NvDeleteOutlineIcon, NvLink, NvMenuWithItems, NvTooltip } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { PropertyPanelSection } from '../../../property-panel';
import { SlackBlockKitBlockProps } from '../types';
import { StyledNvTypography } from './styled';

export const SlackBlockKitBlock = ({
  block,
  onChange,
  dragHandleProps,
  onDeleteClick,
  index,
  context = {},
}: SlackBlockKitBlockProps) => {
  const theme = useTheme();

  const handleOnChange = (value: string) => {
    const newBlock: SlackBlockKitBlockType = {
      ...block,
      value: { ...block.value, value },
    };

    onChange(newBlock, index);
  };
  return (
    <PropertyPanelSection
      dragHandleProps={dragHandleProps}
      title="Slack block kit"
      tooltip={
        <NvTooltip
          title={
            <StyledNvTypography variant="body2">
              Generate customized Slack messages using Block Kit JSON formatting. Explore an illustrative example{' '}
              <NvLink href="https://api.slack.com/block-kit/building" target="_blank" rel="noreferrer">
                here
              </NvLink>
            </StyledNvTypography>
          }
        >
          <NvCustomHelpIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_neutral[70]} />
        </NvTooltip>
      }
      draggable
      collapsible
      actions={
        <NvMenuWithItems
          triggerButton={{
            props: { color: 'ghost', size: 'small', variant: 'contained', onlyIcon: true },
          }}
          menuItems={[
            {
              name: 'Delete',
              onClick: onDeleteClick,
              icon: (
                <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
              ),
            },
          ]}
        />
      }
    >
      <CodeInput
        value={block.value.value}
        onChange={handleOnChange}
        context={context}
        initialHeight="100px"
        placeholder="Supports slack block kit"
      />
    </PropertyPanelSection>
  );
};
