import { NvTypography } from '@novaera/core';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { AddNewAppDropdownMenuItemBox } from './styled';
import { AddNewAppDropdownMenuItemProps } from './types';

export const AddNewAppDropdownMenuItem: React.FC<React.PropsWithChildren<AddNewAppDropdownMenuItemProps>> = ({
  icon,
  label,
  onClick,
  color = 'ghost',
}) => (
  <AddNewAppDropdownMenuItemBox
    onClick={onClick}
    className={classnames({
      'color-ghost': color === 'ghost',
      'color-secondary': color === 'secondary',
      'color-primary': color === 'primary',
    })}
  >
    {icon &&
      React.isValidElement(icon) &&
      React.cloneElement(icon as ReactElement, { sx: { width: '16px', height: '16px' } })}
    <NvTypography variant="h5">{label}</NvTypography>
  </AddNewAppDropdownMenuItemBox>
);
