import { NvButton, NvCloseIcon, NvCloudUploadIcon, NvFileUploadButton, NvFlex, useToast } from '@novaera/core';
import React from 'react';
import { useFileUploadController } from './controllers/use-file-upload';
import { FileUploadProps } from './types';
import { UploadedItem } from './uploaded-item';

export const FileUpload: React.FC<React.PropsWithChildren<FileUploadProps>> = ({
  file,
  onFileUploaded,
  typeRestrictions,
}) => {
  const { addToast } = useToast();
  const { uploadedFile, onFileUpload, isUploadLoading, cancelFileUpload } = useFileUploadController({
    file,
    onFileUploaded,
  });

  const checkRestrictions = (file: File) => {
    if (typeRestrictions && typeRestrictions.length > 0) {
      return typeRestrictions.every((restriction) => file.type.startsWith(restriction));
    } else {
      return true;
    }
  };

  return uploadedFile.name.length > 0 ? (
    <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
      <UploadedItem name={uploadedFile.name} />
      <NvButton onlyIcon size="small" color="secondary" onClick={cancelFileUpload}>
        <NvCloseIcon />
      </NvButton>
    </NvFlex>
  ) : (
    <NvFileUploadButton
      loading={isUploadLoading}
      onFileSelected={async (file) => {
        const isValid = checkRestrictions(file);
        if (isValid) {
          const uploadedFile = await onFileUpload({
            file,
          });
          onFileUploaded(uploadedFile);
        } else {
          addToast('Selected file type is not supported.', { variant: 'error' });
        }
      }}
      startIcon={<NvCloudUploadIcon />}
      color="secondary"
      size="medium"
      text="Upload image"
    />
  );
};

export const MemoizedFileUpload = React.memo(FileUpload, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
