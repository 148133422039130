import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { INTEGRATION_ACTION_METADATA_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import {
  CreateIntegrationActionResponse,
  GetIntegrationActionResponse,
  GetIntegrationActionsResponse,
  UpdateMetadataParams,
} from '../types';

const updateIntegrationActionMetadata = async (params: UpdateMetadataParams) => {
  const { integrationId, actionId, ...payload } = params;
  const result = await NvAxios.post<CreateIntegrationActionResponse>(
    INTEGRATION_ACTION_METADATA_PATH(integrationId, actionId),
    payload
  );
  return result?.data;
};

export const useUpdateIntegrationActionMetadata = () => {
  const cache = useQueryClient();

  return useMutation(updateIntegrationActionMetadata, {
    onSuccess(data, { integrationId, actionId, version }) {
      cache.setQueryData(
        QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version),
        (old?: GetIntegrationActionsResponse) => {
          if (old) {
            return { ...old, actions: old.actions.map((a) => (a.id === actionId ? data : a)) };
          }
          return old;
        }
      );
      cache.setQueryData(
        QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version),
        (old?: GetIntegrationActionResponse) => {
          return old && { ...old, persisted: data };
        }
      );
    },
  });
};
