import { useTheme } from '@novaera/theme-provider';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isString } from 'lodash';
import { NvButton } from '../button';
import { NvConditionalWrap } from '../conditional-wrap';
import { NvDialog } from '../dialog';
import {
  StyledDialogActionsV1,
  StyledDialogContentV1,
  StyledDialogIconWrapperV1,
  StyledDialogTitleV1,
  StyledDialogV1,
  StyleDialogCloseIconWrapperV1,
  StyleDialogWrapperV1,
} from '../dialog/styled';
import { NvFlex } from '../flex';
import { NvForm } from '../form';
import { EmptyInterface, FormProps } from '../form/types';
import { NvCloseIcon } from '../icons';
import { NvTypography } from '../typography';
import { ModalIcon } from './constants';
import { DefaultFooter } from './footer';
import { ModalIcons } from './types';

export type NvDialogModalProps<T> = {
  unmountOnExit?: boolean;
  Header?: React.ReactNode;
  Body?: React.ReactNode;
  Footer?: React.ReactNode;
  form?: FormApi<T, Partial<T>>;
  modalIcon?: ModalIcons;
  onFormSubmit?: (values: T) => void;
  isLoading?: boolean;
  onCloseButtonClicked: () => void;
  formProps?: Omit<FormProps<T>, 'onSubmit'>;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  useArrayMutators?: boolean;
} & Omit<React.ComponentPropsWithoutRef<typeof NvDialog>, 'onClose' | 'title'>;

export const NvDialogModal = <T extends EmptyInterface>(props: NvDialogModalProps<T>) => {
  const theme = useTheme();
  const {
    Header,
    Body,
    Footer,
    form = false,
    onFormSubmit,
    modalIcon,
    isLoading,
    onCloseButtonClicked,
    formProps,
    primaryButtonText,
    secondaryButtonText,
    useArrayMutators,
    open,
    unmountOnExit = false,
    ...dialogProps
  } = props;

  return !unmountOnExit || open ? (
    <StyledDialogV1 open={open} {...dialogProps}>
      <NvConditionalWrap
        condition={!form}
        wrap={(children) => (
          <NvForm<T>
            {...formProps}
            {...(useArrayMutators && {
              mutators: {
                ...arrayMutators,
              },
            })}
            onSubmit={(value) => {
              onFormSubmit?.(value);
            }}
          >
            {children}
          </NvForm>
        )}
      >
        <StyleDialogWrapperV1>
          <StyledDialogIconWrapperV1>
            {modalIcon === undefined
              ? formProps?.initialValues
                ? ModalIcon({ theme })['edit']
                : ModalIcon({ theme })['add']
              : ModalIcon({ theme })[modalIcon]}
          </StyledDialogIconWrapperV1>
          <NvFlex flex="1 1 auto" maxWidth="calc( 100% - 112px)">
            <StyledDialogTitleV1>
              {isString(Header) ? <NvTypography variant="h2">{Header}</NvTypography> : Header}
            </StyledDialogTitleV1>
            <StyledDialogContentV1>{Body}</StyledDialogContentV1>
            <StyledDialogActionsV1>
              {Footer || (
                <DefaultFooter
                  isLoading={isLoading || false}
                  onClose={onCloseButtonClicked}
                  primaryButtonText={primaryButtonText}
                  secondaryButtonText={secondaryButtonText}
                  {...(form && {
                    form,
                  })}
                />
              )}
            </StyledDialogActionsV1>
          </NvFlex>
          <StyleDialogCloseIconWrapperV1>
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseButtonClicked} disabled={isLoading}>
              <NvCloseIcon />
            </NvButton>
          </StyleDialogCloseIconWrapperV1>
        </StyleDialogWrapperV1>
      </NvConditionalWrap>
    </StyledDialogV1>
  ) : null;
};
