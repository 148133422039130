import { assert } from '@novaera/utils';
import { ReactNode, createContext, useContext, useState } from 'react';
import { DataModelDefaultPageGenericFieldType } from '../../../components/data-model-layout/types';

export type SelectedItemDetail = { selectedItemDetail?: DataModelDefaultPageGenericFieldType; index: number };

type CatalogContextValues = {
  setSelectedEntityTypeId: (value: string) => void;
  selectedEntityTypeId: string;
};

type CatalogContextProviderProps = {
  children: ((params: CatalogContextValues) => ReactNode) | ReactNode;
  initialSelectedEntityTypeId: string;
};
const CatalogContext = createContext<CatalogContextValues | undefined>(undefined);

export const CatalogContextProvider = ({ children, initialSelectedEntityTypeId }: CatalogContextProviderProps) => {
  const [selectedEntityTypeId, setSelectedEntityTypeId] = useState<string>(initialSelectedEntityTypeId);

  const value: CatalogContextValues = {
    selectedEntityTypeId,
    setSelectedEntityTypeId: (value) => {
      setSelectedEntityTypeId(value);
    },
  };

  return (
    <CatalogContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </CatalogContext.Provider>
  );
};

export const useCatalogContext = () => {
  const context = useContext(CatalogContext);
  assert(!!context, new Error(`useCatalogContext should be used within CatalogContextProvider`), 'ERROR');

  return context;
};
