import {
  CodeInput,
  isRequired,
  isValidJSON,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTypography,
} from '@novaera/core';
import { usePayloadModal } from './controllers/use-payload-modal';
import { AddPayloadModalForm, AddPayloadModalProps } from './types';

export const AddGenericWebhookPayloadModal: React.FC<React.PropsWithChildren<AddPayloadModalProps>> = ({
  isOpened,
  onClose,
  appId,
  workflowId,
}) => {
  const { isDisabled, onSubmit } = usePayloadModal({
    appId,
    workflowId,
    onClose,
  });
  return (
    <NvDialogV1 open={isOpened} fullWidth maxWidth="md" onClose={onClose}>
      <NvForm<AddPayloadModalForm> onSubmit={onSubmit}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvFlex flex="1 1 auto" minWidth="0">
                <NvTypography variant="h2">Add an example payload</NvTypography>
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex rowGap="16px">
                <NvFlex>
                  <NvTypography variant="body1">Add a sample payload to configure conditions and steps.</NvTypography>
                </NvFlex>
                <NvFlex height="500px">
                  <NvField
                    showErrorMessageOnlyWhenBlur
                    name="content"
                    validators={[isRequired(), isValidJSON()]}
                    component={<CodeInput context={{}} mode="json" lineNumbers hasBorder />}
                  />
                  <NvTypography variant="body3" marginTop="6px">
                    Supports only JSON format.
                  </NvTypography>
                </NvFlex>
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton disabled={isDisabled} loading={submitting} type="submit">
                Add
              </NvButton>
              <NvButton
                onClick={() => {
                  onClose();
                }}
                color="ghost"
              >
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon color="secondary" size="small" onClick={onClose}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
