import { useGetWorkflowHistories, WorkflowHistoryStatus, WorkflowHistoryTimeWindow } from '@novaera/actioner-service';
import {
  NvButton,
  NvCheckbox,
  NvChip,
  NvCustomEmptyIcon,
  NvFilterListRoundedIcon,
  NvFlex,
  NvMenuWithItems,
  NvRadio,
  NvRefreshIcon,
  NvTypography,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { useEffect, useState } from 'react';
import { PropertyPanelSimpleSection } from '../../../../components';
import { WorkflowHistoryListHeader } from '../../styled';
import { WORKFLOW_HISTORY_DETAIL } from '../../workflow-detail/constants';
import { TIME_WINDOW_FILTER_ITEMS, WORKFLOW_HISTORY_FILTER_ITEMS } from './constants';
import {
  FilterTitleWrapper,
  MenuItemWrapper,
  NvMenuItemWrapper,
  WorkflowHistoryFilterSection,
  WorkflowHistoryListContainer,
} from './styled';
import { WorkflowHistoryListItem } from './workflow-history-list-item';
import { WorkflowHistoryListItemLoading } from './workflow-history-list-item/loading-item';

export const WorkflowHistoryList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId, workflowId } = useParams();
  const navigate = useNavigate();
  const { workflowExecutionId } = useParams();
  const [filters, setFilters] = useState<{
    'time-window': WorkflowHistoryTimeWindow;
    statuses: WorkflowHistoryStatus[];
  }>({
    'time-window': WorkflowHistoryTimeWindow.LAST_HOUR,
    statuses: [],
  });
  const theme = useTheme();

  const {
    data: getHistoriesQueries,
    isInitialLoading: isWorkflowHistoriesLoading,
    isRefetching: isWorkflowHistoriesRefetching,
    refetch: refetchWorkflowHistories,
    fetchNextPage: fetchNextHistoriesPage,
    hasNextPage: hasNextHistoriesPage,
    isFetchingNextPage: isFetchingNextHistoriesPage,
  } = useGetWorkflowHistories({
    appId: userAppId,
    workflowId,
    ...filters,
  });
  const getHistoriesPages = getHistoriesQueries?.pages.filter((p) => p);
  const isLoading = isWorkflowHistoriesLoading || isWorkflowHistoriesRefetching;
  const isEmpty =
    !getHistoriesPages ||
    (getHistoriesPages && getHistoriesPages.length === 1 && getHistoriesPages[0].logs.length === 0);

  useEffect(() => {
    if (!isEmpty && !workflowExecutionId && !!getHistoriesPages?.[0].logs[0].executionId) {
      // Navigate to the details of the first log if there is at least one log present.
      navigate(
        WORKFLOW_HISTORY_DETAIL({
          appId: userAppId,
          workflowId,
          executionId: getHistoriesPages?.[0].logs[0].executionId,
        })
      );
    }
  }, [getHistoriesPages, isEmpty, navigate, userAppId, workflowExecutionId, workflowId]);

  return (
    <WorkflowHistoryListContainer>
      <WorkflowHistoryListHeader
        title="Workflow runs"
        actions={
          <NvButton
            onlyIcon
            size="small"
            color="ghost"
            onClick={() => refetchWorkflowHistories()}
            disabled={isLoading}
            loading={isWorkflowHistoriesRefetching}
          >
            <NvRefreshIcon />
          </NvButton>
        }
      />
      <WorkflowHistoryFilterSection>
        <NvFlex direction="row" alignItems="flex-start" gap="4px">
          <NvMenuWithItems
            triggerButton={{
              content: <NvFilterListRoundedIcon />,
              props: { size: 'small', color: 'ghost', onlyIcon: true },
            }}
            menuItems={
              <MenuItemWrapper>
                <NvFlex direction="column" minWidth={'150px'}>
                  <FilterTitleWrapper>
                    <NvTypography variant={'h7'} textColor="secondary">
                      FILTER BY TIME
                    </NvTypography>
                  </FilterTitleWrapper>
                  {TIME_WINDOW_FILTER_ITEMS.map((item) => (
                    <NvFlex key={`time-window-filter-item-${item.value}`}>
                      <NvMenuItemWrapper
                        onClick={() => {
                          setFilters((prev) => ({ ...prev, 'time-window': item.value }));
                        }}
                      >
                        <NvFlex direction="row" columnGap="8px">
                          <NvFlex padding={'2px 0'}>
                            <NvRadio checked={item.value === filters['time-window']} />
                          </NvFlex>
                          <NvFlex>
                            <NvTypography variant="body1"> {item.label}</NvTypography>
                          </NvFlex>
                        </NvFlex>
                      </NvMenuItemWrapper>
                    </NvFlex>
                  ))}
                </NvFlex>

                <NvFlex>
                  <FilterTitleWrapper>
                    <NvTypography variant={'h7'} textColor="secondary">
                      FILTER BY STATUS
                    </NvTypography>
                  </FilterTitleWrapper>
                  {WORKFLOW_HISTORY_FILTER_ITEMS.map((item) => (
                    <NvFlex key={`workflow-history-filter-status-${item.value}`}>
                      <NvMenuItemWrapper
                        onClick={() => {
                          setFilters((prev) => ({
                            ...prev,
                            statuses: prev.statuses.includes(item.value)
                              ? prev.statuses.filter((s) => s !== item.value)
                              : [...prev.statuses, item.value],
                          }));
                        }}
                      >
                        <NvFlex direction="row" columnGap="8px">
                          <NvFlex padding={'2px 0'}>
                            <NvCheckbox checked={filters['statuses'].includes(item.value)} />
                          </NvFlex>
                          <NvFlex justifyContent="center">
                            <NvChip compact label={item.label} color={item.color} />
                          </NvFlex>
                        </NvFlex>
                      </NvMenuItemWrapper>
                    </NvFlex>
                  ))}
                </NvFlex>
              </MenuItemWrapper>
            }
          />
          <NvFlex direction="row" alignItems="flex-start" flexWrap="wrap" gap="6px" marginBottom="4px" marginTop="4px">
            <NvChip label={filters['time-window']} compact />
            {filters['statuses'].map((f) => (
              <NvChip
                label={f}
                compact
                onDelete={() => {
                  setFilters((prev) => ({ ...prev, statuses: prev.statuses.filter((s) => s !== f) }));
                }}
              />
            ))}
          </NvFlex>
        </NvFlex>
      </WorkflowHistoryFilterSection>
      {isLoading ? (
        <>
          <WorkflowHistoryListItemLoading />
          <WorkflowHistoryListItemLoading />
          <WorkflowHistoryListItemLoading />
        </>
      ) : isEmpty ? (
        <PropertyPanelSimpleSection>
          <NvFlex direction="row" alignItems="center" gap="8px">
            <NvCustomEmptyIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[60]} />
            <NvTypography variant="body2" textColor="ghost">
              There is no workflow runs.
            </NvTypography>
          </NvFlex>
        </PropertyPanelSimpleSection>
      ) : (
        getHistoriesPages?.map(({ logs }) =>
          logs.map((log) => <WorkflowHistoryListItem key={log.executionId} log={log} />)
        )
      )}
      {hasNextHistoriesPage && (
        <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
          <NvButton
            size="small"
            color="secondary"
            onClick={() => {
              fetchNextHistoriesPage();
            }}
            loading={isFetchingNextHistoriesPage}
            disabled={isFetchingNextHistoriesPage}
          >
            Show more
          </NvButton>
        </NvFlex>
      )}
    </WorkflowHistoryListContainer>
  );
};
