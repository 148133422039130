import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { IntegrationAction } from '../../types';
import { INTEGRATION_ACTION_RESPONSE_PERSIST_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { GetIntegrationActionsResponse, PersistIntegrationActionParams } from '../types';

const persistIntegrationAction = async (params: PersistIntegrationActionParams) => {
  const { integrationId, actionId } = params;
  const result = await NvAxios.post<IntegrationAction>(
    INTEGRATION_ACTION_RESPONSE_PERSIST_PATH(integrationId, actionId)
  );
  return result?.data;
};

export const usePersistIntegrationAction = () => {
  const cache = useQueryClient();

  return useMutation(persistIntegrationAction, {
    onSuccess(data, { integrationId, actionId, version }) {
      cache.setQueryData(
        QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version),
        (old?: GetIntegrationActionsResponse) => {
          if (old) {
            return { ...old, actions: old.actions.map((a) => (a.id === actionId ? data : a)) };
          }
          return old;
        }
      );

      cache.setQueryData(QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version), { persisted: data });
    },
  });
};
