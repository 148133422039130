import { ErrorHandlingStrategy } from '@novaera/actioner-service';
import { LabelValue } from '@novaera/core';

export const ErrorHandlingStrategyOptions: LabelValue<ErrorHandlingStrategy>[] = [
  {
    label: 'Terminate the workflow run',
    value: {
      type: 'simple',
      continueOnError: false,
    },
  },
  {
    label: 'Proceed with workflow run',
    value: {
      type: 'simple',
      continueOnError: true,
    },
  },
];
