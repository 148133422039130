import { AppSchema, AppSchemasPayload, AppSchemeChangeSize } from '@novaera/actioner-service';
import { ROUTES } from '../../common/routes';

export const changeSizeCalculator = (type: AppSchemeChangeSize, version: string) => {
  const versionUnits = version.split('.');
  switch (type) {
    case AppSchemeChangeSize.PATCH:
      return `${versionUnits[0]}.${versionUnits[1]}.${Number(versionUnits[2]) + 1}`;

    case AppSchemeChangeSize.MINOR:
      return `${versionUnits[0]}.${Number(versionUnits[1]) + 1}.0`;

    case AppSchemeChangeSize.MAJOR:
      return `${Number(versionUnits[0]) + 1}.0.0`;

    default:
      return version;
  }
};

export const getSearchSchemeBody = (
  updatedSearchQuery: AppSchemasPayload,
  initialSearchQuery: AppSchemasPayload
): AppSchemasPayload => {
  return updatedSearchQuery.queries.length > 0
    ? {
        ...updatedSearchQuery,
        sortParams: null,
      }
    : initialSearchQuery;
};

export const boostNumberOfStars = ({
  name,
  numberOfStars,
  contributors,
}: Pick<AppSchema, 'name' | 'numberOfStars' | 'contributors'>) => {
  const hasActionerAsContributor = !!contributors.find((c) => c.name === 'Actioner');
  let initialBoost;

  if (!hasActionerAsContributor) {
    initialBoost = numberOfStars;
  } else {
    const boost = name.length * 3 || 1;
    initialBoost = Number(numberOfStars) + boost;
    while (initialBoost < 30) {
      initialBoost = initialBoost + boost;
    }
  }

  return `${initialBoost}`;
};

export const getAppSchemaUrl = ({ name, schemaId }: { name?: string; schemaId?: string }) =>
  name && schemaId
    ? `${ROUTES.AppDirectory}/${name
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/gi, '-')}/${schemaId}`
    : `${ROUTES.AppDirectory}`;
