import {
  DateTimeSchema,
  FieldSchema,
  ListSchema,
  MapSchema,
  ObjectSchema,
  PrimitiveSchema,
  RecordReferenceSchema,
  StringSchema,
  UIComponentType,
  UIComponentTypes,
} from '@novaera/actioner-service';
import {
  NestedDropdownItem,
  NvDNSIcon,
  NvFingerPrintIcon,
  NvFormatSizeIcon,
  NvFunctionsIcon,
  NvGridViewIcon,
  NvListAltIcon,
  NvListIcon,
  NvMailOutlineIcon,
  NvOpenInNewIcon,
  NvPercentIcon,
  NvPhoneIcon,
  NvTagIcon,
  NvTitleIcon,
  NvTodayIcon,
  NvToggleOnIcon,
} from '@novaera/core';

import { ConstraintType, DateTimeFormat, SchemaType } from '@novaera/ah-common';

export const PRIMITIVE_MENU_ITEMS: NestedDropdownItem[] = [
  {
    id: '1',
    name: 'Text fields',
    leftIcon: <NvFormatSizeIcon />,
    items: [
      {
        id: SchemaType.string,
        name: 'Text',
        leftIcon: <NvTitleIcon />,
      },
      {
        id: SchemaType.email,
        name: 'Email',
        leftIcon: <NvMailOutlineIcon />,
      },
      {
        id: SchemaType.url,
        name: 'URL',
        leftIcon: <NvOpenInNewIcon />,
      },
      {
        id: SchemaType.phone,
        name: 'Phone number',
        leftIcon: <NvPhoneIcon />,
      },
      { id: SchemaType.uuid, name: 'UUID', leftIcon: <NvFingerPrintIcon /> },
    ],
  },
  {
    id: '10',
    name: 'Numbers',
    leftIcon: <NvTagIcon />,
    items: [
      {
        id: SchemaType.integer,
        name: 'Integer',
        leftIcon: <NvFunctionsIcon />,
      },
      {
        id: SchemaType.float,
        name: 'Decimal',
        leftIcon: <NvPercentIcon />,
      },
    ],
  },
  {
    id: SchemaType.dateTime,
    name: 'Date Time',
    leftIcon: <NvTodayIcon />,
  },
  { id: SchemaType.boolean, name: 'Boolean', leftIcon: <NvToggleOnIcon /> },
  { id: SchemaType.recordReference, name: 'Reference', leftIcon: <NvDNSIcon /> },
];

export const MENU_ITEMS: NestedDropdownItem[] = [
  ...PRIMITIVE_MENU_ITEMS.filter((m, i) => i < 3),
  {
    id: '30',
    name: 'Collection',
    leftIcon: <NvGridViewIcon />,
    items: [
      {
        id: SchemaType.array,
        name: 'List',
        leftIcon: <NvListIcon />,
      },
      { id: SchemaType.map, name: 'Map', leftIcon: <NvListAltIcon /> },
    ],
  },
  ...PRIMITIVE_MENU_ITEMS.filter((m, i) => i > 2),
];

const initialSchema: PrimitiveSchema = {
  type: SchemaType.email,
  mandatory: false,
  readOnly: false,
  constraints: [],
};

const initialSizeConstraint = {
  type: ConstraintType.listSize,
  max: 10000,
  min: 0,
  enabled: false,
};

const dateTimeSchema: DateTimeSchema = { ...initialSchema, type: SchemaType.dateTime, format: DateTimeFormat.utc };
const recordReferenceSchema: RecordReferenceSchema = {
  ...initialSchema,
  type: SchemaType.recordReference,
  modelId: '',
  modelName: '',
};
const listSchema: ListSchema = {
  ...initialSchema,
  type: SchemaType.array,
  valueSchema: initialSchema,
  constraints: [{ ...initialSizeConstraint, type: ConstraintType.listSize, max: 50 }],
};
const mapSchema: MapSchema = {
  ...initialSchema,
  type: SchemaType.map,
  constraints: [{ ...initialSizeConstraint, type: ConstraintType.mapSize, max: 50 }],
};
const objectSchema: ObjectSchema = {
  ...initialSchema,
  type: SchemaType.object,
  constraints: [{ ...initialSizeConstraint, type: ConstraintType.mapSize, max: 50 }],
};

export const stringSchema: StringSchema = {
  ...initialSchema,
  type: SchemaType.string,
  constraints: [
    { ...initialSizeConstraint, type: ConstraintType.stringSize },
    { type: ConstraintType.regex, pattern: '', enabled: false },
  ],
};

export const FIELD_TYPE_SCHEMAS: Record<SchemaType, FieldSchema> = {
  [SchemaType.integer]: { ...initialSchema, type: SchemaType.integer },
  [SchemaType.boolean]: { ...initialSchema, type: SchemaType.boolean },
  [SchemaType.float]: { ...initialSchema, type: SchemaType.float },
  [SchemaType.uuid]: { ...initialSchema, type: SchemaType.uuid },
  [SchemaType.email]: { ...initialSchema, type: SchemaType.email },
  [SchemaType.phone]: { ...initialSchema, type: SchemaType.phone },
  [SchemaType.url]: { ...initialSchema, type: SchemaType.url },
  [SchemaType.string]: stringSchema,
  [SchemaType.dateTime]: dateTimeSchema,
  [SchemaType.map]: mapSchema,
  [SchemaType.object]: objectSchema,
  [SchemaType.array]: listSchema,
  [SchemaType.recordReference]: recordReferenceSchema,
};

export const FIELD_TYPE_DISPLAY_NAME: Record<SchemaType, string> = {
  [SchemaType.dateTime]: 'Date-time',
  [SchemaType.recordReference]: 'Reference',
  [SchemaType.boolean]: 'Boolean',
  [SchemaType.integer]: 'Integer',
  [SchemaType.email]: 'Email',
  [SchemaType.float]: 'Decimal',
  [SchemaType.phone]: 'Phone number',
  [SchemaType.url]: 'Link',
  [SchemaType.string]: 'Text',
  [SchemaType.map]: 'Map',
  [SchemaType.array]: 'List',
  [SchemaType.uuid]: 'UUID',
  [SchemaType.object]: 'Object',
};

export const RECORD_FIELD_TYPE_UI_COMPONENT: ({ label }: { label: string }) => Record<SchemaType, UIComponentTypes> = ({
  label,
}) => ({
  [SchemaType.dateTime]: {
    hidden: false,
    label,
    type: UIComponentType.DATE_TIME_PICKER,
  },
  [SchemaType.recordReference]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.boolean]: {
    hidden: false,
    label,
    type: UIComponentType.CHECK_BOX,
  },
  [SchemaType.integer]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.email]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.float]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.phone]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.url]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.string]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.map]: {
    hidden: false,
    multiLine: true,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.object]: {
    hidden: false,
    multiLine: true,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.array]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
  [SchemaType.uuid]: {
    hidden: false,
    multiLine: false,
    label,
    type: UIComponentType.TEXT_FIELD,
  },
});

export const TABLE_HIDDEN_FIELD_NAMES = ['updatedAt', 'createdAt'];
