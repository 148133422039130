import { DrawerBackground, DrawerInnerWrapper } from './styled';
import { NvDrawerProps } from './types';

/**
 * For now, we are only styling Drawer component according to anchor right prop.
 * We are gonna style later for other variants.
 */

export const NvDrawer: React.FC<React.PropsWithChildren<NvDrawerProps>> = ({ children, ...props }) => {
  const { fullWidth, borderRadius, width, ...rest } = props;
  return (
    <DrawerBackground anchor="right" fullWidth={fullWidth} borderRadius={borderRadius} {...rest}>
      <DrawerInnerWrapper fullWidth={fullWidth} width={width}>
        {children}
      </DrawerInnerWrapper>
    </DrawerBackground>
  );
};
