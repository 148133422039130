import { NvAxios, useQuery } from '@novaera/core';

import { IdentityProvidersResponse } from './types';

const fetchIdentityProviders = async () => {
  const result = await NvAxios.get<IdentityProvidersResponse>('/v1/oauth2/identity-providers');
  return result?.data;
};

export const useFetchIdentityProviders = () => {
  return useQuery(['fetchIdentityProviders'], () => fetchIdentityProviders());
};
