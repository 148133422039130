import { NvAxios, useQuery } from '@novaera/core';

import { Workspace } from '../../../types';
import { ADMIN_WORKSPACES_PREFIX } from '../constants';
import { QUERY_KEYS_ADMIN_WORKSPACES } from '../keys';
import { GetWorkspaceWithSubDomainParams } from '../types';

const getWorkspaceWithSubdomain = async (params: GetWorkspaceWithSubDomainParams) => {
  const result = await NvAxios.get<Workspace>(`${ADMIN_WORKSPACES_PREFIX}/${params.subdomain}`, {
    params: { productId: params.productId },
  });
  return result?.data;
};

export const useGetWorkspaceWithSubdomain = (params: GetWorkspaceWithSubDomainParams) => {
  return useQuery(QUERY_KEYS_ADMIN_WORKSPACES.detail(params.subdomain), () => getWorkspaceWithSubdomain(params), {
    enabled: !!params.subdomain,
    retry: false,
  });
};
