import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkflowPermissionsContainer = styled(NvBox)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
  overflow: auto;
`;
