import { WorkflowWithState } from '@novaera/actioner-service';
import { NvButton, NvContentCopyIcon, NvFlex, NvTypography, NvWebhookIcon, useCopyToClipboard } from '@novaera/core';
import { PropertyPanelSection } from '../../../../../../../../../components';
import { EndpointWrapper } from '../styled';

export const WebhookUrlPanel = ({
  workflow,
  integrationName,
}: {
  workflow: WorkflowWithState;
  integrationName?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copyToClipboard] = useCopyToClipboard();

  const handleCopyClicked = () => {
    if (workflow?.endpoint) {
      copyToClipboard(workflow?.endpoint);
    }
  };

  return (
    <PropertyPanelSection title="Webhook URL" icon={<NvWebhookIcon />}>
      <NvFlex gap="8px">
        <NvTypography variant="body2" textColor="secondary">
          You need to copy the URL provided below and use it for webhook configuration in your <b>{integrationName}</b>{' '}
          account. All <b>{integrationName}</b> triggers in this app will use the same webhook configuration for this
          app.
        </NvTypography>
        <NvFlex alignItems="center" paddingTop="2px" direction="row" gap="8px">
          <EndpointWrapper>
            <NvTypography variant="c2" textColor="subtle" noWrap component="p">
              {workflow.endpoint}
            </NvTypography>
          </EndpointWrapper>
          <NvButton onlyIcon color="ghost" sx={{ flex: '0 0 auto' }} onClick={handleCopyClicked}>
            <NvContentCopyIcon />
          </NvButton>
        </NvFlex>
      </NvFlex>
    </PropertyPanelSection>
  );
};
