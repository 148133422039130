import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetConfigsParams, GetConfigsResponse } from './types';

const getConfigs = async ({ appId, pageParam }: GetConfigsParams) => {
  const result = await NvAxios.get<GetConfigsResponse>(`${CONFIG_ROOT_PATH(appId)}`, {
    params: {
      ...pageParam,
    },
  });
  return result?.data;
};

export const useGetConfigs = (params: GetConfigsParams) => {
  const { appId } = params;
  return useInfiniteQuery<GetConfigsResponse>(
    QUERY_KEYS_CONFIGS.list(appId),
    (props) =>
      getConfigs({
        appId,
        pageParam: {
          ...props.pageParam,
          validate: params.pageParam?.validate,
        },
      }),
    {
      enabled: !!appId,
      getNextPageParam: (lastPage) => lastPage?.nextPageKey,
    }
  );
};
