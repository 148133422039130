import { NvFlex, NvLink, NvTypography } from '@novaera/core';
import { FC } from 'react';

export const SignUpAgreement: FC = () => {
  return (
    <NvFlex marginBottom={'-16px'}>
      <NvTypography variant="body2" textColor="subtle">
        By signing up, you agree to Actioner's{' '}
        <NvLink href="https://actioner.com/terms-conditions" target="_blank" linkVariant="ghost">
          terms & conditions
        </NvLink>
        ,{' '}
        <NvLink href="https://actioner.com/privacy-policy" target="_blank" linkVariant="ghost">
          privacy policy
        </NvLink>{' '}
        and{' '}
        <NvLink href="https://actioner.com/disclaimer" target="_blank" linkVariant="ghost">
          disclaimer
        </NvLink>
        .
      </NvTypography>
    </NvFlex>
  );
};
