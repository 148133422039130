import { DropResult } from '@hello-pangea/dnd';
import { KeyValueTableRow } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { ChangeEventHandler, useCallback } from 'react';
import { move } from '../../../../../../../../../../../../utils';

export const useKeyValueTableRows = ({
  onChange,
  keyValueTableRows,
}: {
  keyValueTableRows?: KeyValueTableRow[];
  onChange?: (value?: KeyValueTableRow[]) => void;
}) => {
  const sendChange = useCallback(
    (value?: KeyValueTableRow[]) => {
      if (value) {
        onChange?.(value);
      } else {
        onChange?.();
      }
    },
    [onChange]
  );

  const handleKeyValueTableRowNameChange =
    (columnIndex: number): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined =>
    (event) => {
      const updatedDataKeyValueTableRows = keyValueTableRows?.map((column, index) => {
        if (index === columnIndex) {
          return {
            ...column,
            name: event.target.value,
          };
        } else {
          return column;
        }
      });
      sendChange(updatedDataKeyValueTableRows);
    };

  const handleDataSourceValueChange =
    (columnIndex: number): ((value: string) => void) | undefined =>
    (value) => {
      const updatedDataKeyValueTableRows = keyValueTableRows?.map((column, index) => {
        if (index === columnIndex) {
          return {
            ...column,
            dataSource: { ...column.dataSource, value },
          };
        } else {
          return column;
        }
      });
      sendChange(updatedDataKeyValueTableRows);
    };

  const handleNewKeyValueTableRowAdded = () => {
    const identity = keyValueTableRows?.length ?? 0;
    sendChange([
      ...(keyValueTableRows ?? []),
      {
        name: `New row ${identity}`,
        dataSource: { type: 'javascript', value: '{{ currentItem.fieldName }}' },
      },
    ]);
  };

  const handleKeyValueTableRowRemoved = (index: number) => {
    const removedDataKeyValueTableRows = keyValueTableRows?.filter((_, i) => i !== index);
    sendChange(removedDataKeyValueTableRows);
  };

  const handleDragEnd = (result: DropResult) => {
    assert(!!keyValueTableRows, new Error('Data columns must be defined'), 'ERROR');
    if (!result.destination) {
      return;
    }
    const newDataKeyValueTableRows = move(keyValueTableRows, result.source.index, result.destination.index);
    sendChange(newDataKeyValueTableRows);
  };

  return {
    handleKeyValueTableRowNameChange,
    handleDataSourceValueChange,
    handleNewKeyValueTableRowAdded,
    handleKeyValueTableRowRemoved,
    handleDragEnd,
  };
};
