import { styled } from '@novaera/theme-provider';
import { Card } from '../../../../../../../../../components/card/styled';

export const StyledCard = styled(Card)`
  border: 1px solid transparent;
  &.success {
    border: 1px solid ${({ theme }) => theme.palette.nv_success[40]};
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.palette.nv_error[30]};
  }
`;
