import { TestActionResultParams } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';

const TestActionContext = createContext<
  | {
      testActionParams?: TestActionResultParams;
      setTestActionParams: Dispatch<SetStateAction<TestActionResultParams | undefined>>;
    }
  | undefined
>(undefined);

export const TestActionProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [testActionParams, setTestActionParams] = useState<TestActionResultParams | undefined>();

  const value = { testActionParams, setTestActionParams };

  return <TestActionContext.Provider value={value}>{children}</TestActionContext.Provider>;
};

export const useTestActionContext = () => {
  const context = useContext(TestActionContext);
  assert(!!context, new Error(`useTestActionContext should be used within TestActionProvider`), 'ERROR');

  return context;
};
