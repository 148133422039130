import { useGetConfigSchema, useValidateAdhocConfig } from '@novaera/actioner-service';
import { NvAutoAwesomeIcon, NvButton, NvFlex, NvReactJson, NvSkeleton, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { ConfigSchemaValidation } from '../../config-schema-validation';
import { useConfigValidationMessage } from '../../config-schema-validation/validation-message';
import { RegenerateSection } from '../../schema-config/regenerate-section';
import {
  GeneratedConfigResultFocusState,
  GeneratedConfigResultJsonViewWrapper,
  GeneratedConfigRightSection,
} from './styled';
import { GeneratedConfigResultProps } from './types';

export const GeneratedConfigResult: React.FC<GeneratedConfigResultProps> = ({
  open,
  onClose,
  onApply,
  onEdit,
  isApplyLoading,
  generatedConfig,
}) => {
  const theme = useTheme();
  const { data: configValidationResult, isInitialLoading: isConfigValidationLoading } = useValidateAdhocConfig({
    appId: generatedConfig.appId,
    payload: {
      schemaId: generatedConfig.schemaId,
      properties: generatedConfig.success ? JSON.stringify(generatedConfig.config) : undefined,
    },
  });
  const { validationMessages } = useConfigValidationMessage({ configValidationResult });
  const { data: schema } = useGetConfigSchema({ appId: generatedConfig?.appId, id: generatedConfig?.schemaId });

  return (
    <GeneratedConfigResultFocusState
      open={open}
      onClose={onClose}
      maxWidth="100%"
      renderHeader={({ close }) => (
        <NvFlex
          direction="row"
          width="100%"
          padding="24px 0 12px 0"
          borderBottom={`1px solid ${theme.palette.nv_neutral[30]};`}
        >
          <NvFlex direction="row" alignItems="center" gap="10px" flex="1 1 auto" minWidth={0}>
            <NvAutoAwesomeIcon />
            <NvTypography variant="h1" noWrap>
              Generate config with AI
            </NvTypography>
          </NvFlex>
          <NvFlex direction="row" alignItems="center" gap="8px" flex="0 0 auto">
            <NvButton
              color="secondary"
              size="small"
              disabled={isApplyLoading}
              onClick={() => {
                close();
              }}
            >
              Discard
            </NvButton>
            {isConfigValidationLoading ? (
              <NvSkeleton variant="rectangular" height="24px" width="72px" />
            ) : configValidationResult?.valid ? (
              <NvButton
                size="small"
                disabled={isApplyLoading}
                loading={isApplyLoading}
                onClick={() => {
                  onApply();
                }}
              >
                Apply changes
              </NvButton>
            ) : (
              <NvButton
                size="small"
                onClick={() => {
                  onEdit();
                }}
              >
                Edit config
              </NvButton>
            )}
          </NvFlex>
        </NvFlex>
      )}
    >
      <NvFlex
        width="100%"
        direction="row"
        alignItems="flex-start"
        flex="1 1 auto"
        minHeight={0}
        gap="24px"
        paddingBottom="24px"
      >
        <GeneratedConfigResultJsonViewWrapper>
          <NvReactJson src={generatedConfig.config} collapsed={1} />
        </GeneratedConfigResultJsonViewWrapper>
        <GeneratedConfigRightSection>
          <RegenerateSection
            appId={generatedConfig.appId}
            config={generatedConfig.config}
            configId={generatedConfig.configId}
            docId={generatedConfig.docId}
            schemaId={generatedConfig.schemaId}
            onRegenerateExecuted={onClose}
            validationMessages={validationMessages}
          />

          {validationMessages && validationMessages?.length > 0 && (
            <ConfigSchemaValidation
              description={
                <>
                  Generated config does not match with the <b>{schema?.name ?? 'current schema'}</b>. Please correct the
                  errors on config detail page.
                </>
              }
              validationError={validationMessages}
            />
          )}
        </GeneratedConfigRightSection>
      </NvFlex>
    </GeneratedConfigResultFocusState>
  );
};
