import { styled } from '@novaera/theme-provider';
import { NvFlex } from '../../flex';
import { NvTabs } from '../../tabs';

export const NvTabsWrapper = styled(NvTabs)`
  .tab-header-container {
    background-color: unset;
    border-bottom: unset;

    & div:first-of-type {
      max-width: unset;
    }
    .MuiTabs-scroller {
      overflow: scroll !important;
    }
  }

  .tab-header-container {
    flex: 0 0 auto;
    justify-content: center;

    .MuiTabs-scroller .MuiTabs-flexContainer {
      border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
    }
  }
  .tab-body-container {
    padding: 20px 0px 0px;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
`;

export const Title = styled(NvFlex)`
  flex: 0 0 auto;
  padding-top: 40px;
  padding-bottom: 16px;
  width: 100%;
`;

export const HeaderWithTabLayoutStyled = styled(NvFlex)`
  align-items: center;
  padding: 0 40px 60px 40px;
  overflow: auto;
  height: 100%;
`;
