import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_INTEGRATION_EVENT_RULES } from '../keys';
import { EventRule, IntegrationEventRuleState } from '../types';
import { GetIntegrationEventRuleResponse } from '../use-get-integration-event-rule/types';
import { GetIntegrationEventRulesResponse } from '../use-get-integration-event-rules/types';

export const useEventRuleCacheUpdateHelper = () => {
  const cache = useQueryClient();

  const updatePersistedEventRuleCache = ({
    eventRule,
    integrationId,
    id,
  }: {
    eventRule: EventRule;
    integrationId: string;
    id: string;
  }) => {
    cache.setQueryData<GetIntegrationEventRuleResponse>(
      QUERY_KEYS_INTEGRATION_EVENT_RULES.detail(integrationId, id),
      (old) =>
        old
          ? {
              persisted: eventRule,
            }
          : undefined
    );
  };

  const updateDraftEventRuleCache = ({
    eventRule,
    integrationId,
    id,
  }: {
    eventRule: EventRule;
    integrationId: string;
    id: string;
  }) => {
    cache.setQueryData<GetIntegrationEventRuleResponse>(
      QUERY_KEYS_INTEGRATION_EVENT_RULES.detail(integrationId, id),
      (old) =>
        old
          ? {
              ...old,
              draft: eventRule,
            }
          : undefined
    );
  };

  const replaceEventRuleFromListCache = ({
    eventRule,
    integrationId,
  }: {
    eventRule: EventRule;
    integrationId: string;
  }) => {
    cache.setQueryData<GetIntegrationEventRulesResponse>(
      QUERY_KEYS_INTEGRATION_EVENT_RULES.list(integrationId),
      (old) =>
        old ? { ...old, eventRules: old.eventRules.map((r) => (r.id === eventRule.id ? eventRule : r)) } : undefined
    );
  };

  const setEventRuleAsDraftFromListCache = ({
    eventRule,
    integrationId,
  }: {
    eventRule: EventRule;
    integrationId: string;
  }) => {
    cache.setQueryData<GetIntegrationEventRulesResponse>(
      QUERY_KEYS_INTEGRATION_EVENT_RULES.list(integrationId),
      (old) =>
        old
          ? {
              ...old,
              eventRules: old.eventRules.map((r) =>
                r.id === eventRule.id ? { ...r, state: IntegrationEventRuleState.DRAFT } : r
              ),
            }
          : undefined
    );
  };

  return {
    updatePersistedEventRuleCache,
    updateDraftEventRuleCache,
    replaceEventRuleFromListCache,
    setEventRuleAsDraftFromListCache,
  };
};
