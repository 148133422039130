import { CatalogRelationship } from '@novaera/actioner-service';
import { NvFlex, NvTypography, useField } from '@novaera/core';
import { RelationshipTypeInformationLabel } from './label';

export const RelationshipTypeInformation = ({
  fieldsFieldName,
  selectedFieldIndex,
}: {
  fieldsFieldName: string;
  selectedFieldIndex: number;
}) => {
  const {
    input: { value },
  } = useField<CatalogRelationship>(`${fieldsFieldName}[${selectedFieldIndex}]`);

  if (!value.sourceToTargetRelationType && !value.targetToSourceRelationType)
    return (
      <NvTypography textColor="ghost" variant={'h5'}>
        relation names
      </NvTypography>
    );

  return (
    <NvFlex flexDirection={'row'} gap={'4px'}>
      <RelationshipTypeInformationLabel label={value.sourceToTargetRelationType} />
      <NvTypography textColor="subtle" variant={'h5'}>
        /
      </NvTypography>
      <RelationshipTypeInformationLabel label={value.targetToSourceRelationType} />
    </NvFlex>
  );
};
