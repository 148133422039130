import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';

import { Connection } from '../types';
import { UserConnection, UserConnectionsResponse } from '../use-get-user-connections/types';
import { SearchConnectionResult } from '../use-search-connections/types';
import { UseConnectParams } from './types';

const connect: (params: UseConnectParams) => Promise<Connection> = async (params) => {
  const { payload, schemaId } = params;
  const result = await NvAxios.post<Connection>(`${CONNECTION_ROOT_PATH}/${schemaId}`, { ...payload });
  return result?.data;
};

export const useConnectService = () => {
  const cache = useQueryClient();

  return useMutation(connect, {
    onSuccess: (data) => {
      cache.setQueryData<Connection>(QUERY_KEYS_CONNECTION.detail(data.id), (old) => {
        if (old?.id === data.id) {
          return data;
        }
        return old;
      });

      cache.setQueryData<UserConnectionsResponse>(QUERY_KEYS_CONNECTION.list(), (old?: UserConnectionsResponse) => {
        const userConnection = old?.userConnections.find((userConnection) => userConnection.schemaId === data.schemaId);
        if (userConnection) {
          userConnection?.connections.push({ id: data.id, name: data.name });
        } else {
          const { schemaId, schemaName, logoUrl, id, name } = data;
          const newUserConnection: UserConnection = { schemaId, schemaName, logoUrl, connections: [{ id, name }] };
          old?.userConnections.push(newUserConnection);
        }

        return old;
      });

      cache.setQueryData<SearchConnectionResult>(
        QUERY_KEYS_CONNECTION.search({ schemaId: data.schemaId }),
        (old?: SearchConnectionResult) => {
          const connections = old?.connections ?? [];
          const index = connections.findIndex((connection) => connection.id === data.id);
          const newConnections =
            index >= 0
              ? [...connections.slice(0, index), data, ...connections.slice(index + 1)]
              : [...connections, data];
          return { ...old, connections: newConnections };
        }
      );
    },
  });
};
