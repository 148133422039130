import { QANode, useUpdateNode, ValueTypes } from '@novaera/actioner-service';
import {
  NvButton,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { NodeType, PropertyPanelHeader } from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';

import { FormBody } from './form-body';
import { QAPropertiesProps } from './types';

export const QAProperties: FC<QAPropertiesProps> = ({ QANode, onCloseClicked }) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);

  const initialWorkflowResolverNode = useMemo<QANode>(
    () => ({
      prompt: { type: ValueTypes.STRING },
      tags: { type: ValueTypes.LIST, value: [] },
      docIds: QANode.docIds ?? { type: ValueTypes.LIST, value: [] },
      errorHandlingStrategy: { type: 'simple', continueOnError: true },
      ...QANode,
    }),
    [QANode]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: QANode.alias,
          payload: { ...(QANode as QANode), name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(QANode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [QANode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const handleOnChange = useCallback(
    (values: QANode) => {
      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: QANode.alias,
        payload: { ...values },
      });
    },
    [updateNodeDetail, userAppId, QANode, workflowId]
  );

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        hasEditRight={hasEditPermission}
        title={QANode.name}
        type={NodeType.QA}
        onTitleChange={async (title) => {
          if (title) {
            handleSaveName(title);
          }
        }}
        validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'small', color: 'secondary' },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    deleteNodeAndUpdateGraph({ nodeId: QANode.alias });
                  },
                },
              ]}
            />

            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <NvForm<QANode>
        onSubmit={noop}
        onChange={({ values }) => {
          const newValues = { ...values };
          if (typeof newValues.model === 'string') {
            newValues.model = {
              type: ValueTypes.STRING,
              value: newValues.model,
            };
          }
          handleOnChange(newValues);
        }}
        initialValues={initialWorkflowResolverNode}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        mutators={{
          ...arrayMutators,
        }}
      >
        {() => {
          return <FormBody />;
        }}
      </NvForm>
    </NvFlex>
  );
};
