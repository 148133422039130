import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const List = styled(NvBox)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  padding-bottom: 60px;
`;

export const DefaultCardListLayoutWrapper = styled(NvFlex)`
  max-width: 768px;
  width: 100%;
  gap: 32px;
  padding-top: 32px;
`;
