import { NonFormTrigger, useSaveToDraftWorkflow, WorkflowWithState } from '@novaera/actioner-service';
import { useGetWorkflowContexts } from '../../../../../../controllers/use-get-workflow-contexts';
import { IntegrationTriggerRootConditionFormValues } from '../../types';

export const useRootConditionPropertiesController = () => {
  const { mutate: saveToDraft } = useSaveToDraftWorkflow();

  const handleSave = (workflow: WorkflowWithState, values: IntegrationTriggerRootConditionFormValues) => {
    const trigger = workflow?.trigger;
    if (trigger && trigger.type !== 'form') {
      const newTrigger: NonFormTrigger = {
        ...trigger,
        rootCondition: values?.rootCondition,
      };
      const newWorkflow: WorkflowWithState = {
        ...workflow,
        trigger: newTrigger,
      };
      saveToDraft(newWorkflow);
    }
  };

  const { workflowCodeInputContext } = useGetWorkflowContexts();

  return {
    onSave: handleSave,
    context: workflowCodeInputContext,
  };
};
