import { styled } from '@novaera/theme-provider';
import { Link } from 'react-router-dom';

export const RouterLinkWrapper = styled(Link)`
  color: inherit;
  text-decoration: none;
  user-select: none;
  &:hover {
    text-decoration: none;
  }
`;
