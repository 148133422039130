import { FieldTitle, NvConditionalWrap, NvFlex, NvTextField } from '@novaera/core';
import { CRON_INPUT_FIELDS } from './constants';
import { LeftSquareBracket, RightSquareBracket } from './styled';
import { CronInputProps, CronUnits } from './types';

export const CronInput: React.FC<CronInputProps> = ({ value, onChange, direction = 'row' }) => {
  const handleOnChange = (updatedValue: string, key: CronUnits) => {
    onChange?.({ ...value, [key]: updatedValue.replace(/\s/g, '') });
  };

  return (
    <NvConditionalWrap
      condition={direction === 'row'}
      wrap={(children) => (
        <NvFlex direction="row" alignItems="center" marginTop="12px" gap="8px">
          <LeftSquareBracket />
          {children}
          <RightSquareBracket />
        </NvFlex>
      )}
    >
      <NvFlex direction={direction} gap="8px" width="100%">
        {CRON_INPUT_FIELDS.map(({ key, label }) => (
          <NvFlex
            direction={direction === 'row' ? 'column' : 'row'}
            key={`cron-input-field-${key}`}
            gap="4px"
            flex="1 1 auto"
            minWidth={0}
          >
            <FieldTitle
              direction={direction === 'column' ? 'label-on-side' : 'label-on-top'}
              labelText={label}
              labelVariant="h6"
              {...(direction === 'column' ? { labelWidth: '90px' } : {})}
            />

            <NvTextField
              value={value?.[key]}
              onChange={(e) => {
                handleOnChange(e.target.value, key);
              }}
              fullWidth
            />
          </NvFlex>
        ))}
      </NvFlex>
    </NvConditionalWrap>
  );
};
