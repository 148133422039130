export const APP_LOADING_ANIMATION_DATA: unknown = {
  nm: 'Flow 2',
  ddd: 0,
  h: 200,
  w: 200,
  meta: { g: '@lottiefiles/toolkit-js 0.26.0' },
  layers: [
    {
      ty: 4,
      nm: 'sari',
      sr: 1,
      st: 0,
      op: 82.30000323290005,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0] },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [102.75415270049288, 97.48315951448178], t: 0 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [102.75415270049288, 97.48315951448178],
              t: 0.030000001424923543,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [132.0000023098679, 114.99999758821224],
              t: 9.030000359052792,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [132.0000023098679, 114.99999758821224],
              t: 9.060000360477716,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [40.03683245402216, 119.91931397596412],
              t: 18.060000718105584,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [40.03683245402216, 119.91931397596412],
              t: 18.090000719530508,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [69.03683245402219, 101.91931397596412],
              t: 27.090001077158377,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [69.03683245402219, 101.91931397596412],
              t: 27.1200010785833,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [40.03683245402216, 119.91931397596412],
              t: 36.12000143621117,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [40.03683245402216, 119.91931397596412],
              t: 36.15000143763609,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [82.31502855994268, 36.91943090182164],
              t: 45.15000179526396,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [82.31502855994268, 36.91943090182164],
              t: 45.180001796688884,
            },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [82.3151582596497, 70.24109380465367], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [82.3151582596497, 70.24109380465367], t: 54.21000215574168 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [82.31502855994268, 36.91943090182164],
              t: 63.210002513369545,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [82.31502855994268, 36.91943090182164],
              t: 63.24000251479447,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [133.00000075588068, 114.99999921076716],
              t: 72.24000287242234,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [133.00000075588068, 114.99999921076716],
              t: 72.27000287384726,
            },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [102.75415270049288, 97.48315951448178], t: 81.27000323147513 },
            { s: [102.75415270049288, 97.48315951448178], t: 81.30000323290005 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0.030000001424923543 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 9.030000359052792 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 9.060000360477716 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 18.060000718105584 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 18.090000719530508 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 27.090001077158377 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 27.1200010785833 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 36.12000143621117 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 36.15000143763609 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 45.15000179526396 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 45.180001796688884 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 54.21000215574168 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 63.210002513369545 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 63.24000251479447 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 72.24000287242234 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 72.27000287384726 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 81.27000323147513 },
            { s: [0], t: 81.30000323290005 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0.030000001424923543 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.030000359052792 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.060000360477716 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.060000718105584 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.090000719530508 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.090001077158377 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.1200010785833 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.12000143621117 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.15000143763609 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.15000179526396 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.180001796688884 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.21000215574168 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.210002513369545 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.24000251479447 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.24000287242234 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.27000287384726 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 81.27000323147513 },
            { s: [100], t: 81.30000323290005 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'sh',
          bm: 0,
          hd: false,
          nm: '',
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089500000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.2413, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5447999999999986, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [24.7119, 29.77],
                      [0.754155, 27.0159],
                      [0.754153, 18.7537],
                      [7.5402, 18.7537],
                      [12.3103, 10.4915],
                      [8.91723, 4.61427],
                      [16.0723, 0.483152],
                      [30.4376, 19.8553],
                      [24.7119, 29.77],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089500000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.2413, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5447999999999986, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [24.7119, 29.77],
                      [0.754155, 27.0159],
                      [0.754153, 18.7537],
                      [7.5402, 18.7537],
                      [12.3103, 10.4915],
                      [8.91723, 4.61427],
                      [16.0723, 0.483152],
                      [30.4376, 19.8553],
                      [24.7119, 29.77],
                    ],
                  },
                ],
                t: 0.030000001424923543,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089600000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.24125, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.544800000000002, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [23.9577, 29.2868],
                      [0.00000418531, 26.5328],
                      [0.00000288201, 18.2705],
                      [6.78605, 18.2705],
                      [11.5561, 10.0083],
                      [8.16308, 4.13111],
                      [15.3181, -0.00000241629],
                      [29.6834, 19.3722],
                      [23.9577, 29.2868],
                    ],
                  },
                ],
                t: 9.030000359052792,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089600000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.24125, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.544800000000002, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [23.9577, 29.2868],
                      [0.00000418531, 26.5328],
                      [0.00000288201, 18.2705],
                      [6.78605, 18.2705],
                      [11.5561, 10.0083],
                      [8.16308, 4.13111],
                      [15.3181, -0.00000241629],
                      [29.6834, 19.3722],
                      [23.9577, 29.2868],
                    ],
                  },
                ],
                t: 9.060000360477716,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.242000000000001, -0.00039999999999906777],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447699999999998, 4.407699999999998],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.120600000000003, 3.6731],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, 0.0006000000000021544],
                      [0, 0],
                    ],
                    v: [
                      [1.31071, 19.7479],
                      [15.6747, 0.376933],
                      [22.83, 4.50805],
                      [19.4369, 10.3849],
                      [24.2072, 18.647],
                      [30.9935, 18.6472],
                      [30.9937, 26.9092],
                      [7.03421, 29.6638],
                      [1.31071, 19.7479],
                    ],
                  },
                ],
                t: 18.060000718105584,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.242000000000001, -0.00039999999999906777],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447699999999998, 4.407699999999998],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.120600000000003, 3.6731],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, 0.0006000000000021544],
                      [0, 0],
                    ],
                    v: [
                      [1.31071, 19.7479],
                      [15.6747, 0.376933],
                      [22.83, 4.50805],
                      [19.4369, 10.3849],
                      [24.2072, 18.647],
                      [30.9935, 18.6472],
                      [30.9937, 26.9092],
                      [7.03421, 29.6638],
                      [1.31071, 19.7479],
                    ],
                  },
                ],
                t: 18.090000719530508,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.242000000000001, -0.00039999999999906777],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447699999999998, 4.407699999999998],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.120600000000003, 3.6731],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, 0.0006000000000021544],
                      [0, 0],
                    ],
                    v: [
                      [1.31071, 19.7479],
                      [15.6747, 0.376933],
                      [22.83, 4.50805],
                      [19.4369, 10.3849],
                      [24.2072, 18.647],
                      [30.9935, 18.6472],
                      [30.9937, 26.9092],
                      [7.03421, 29.6638],
                      [1.31071, 19.7479],
                    ],
                  },
                ],
                t: 27.090001077158377,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.242000000000001, -0.00039999999999906777],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447699999999998, 4.407699999999998],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.120600000000003, 3.6731],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, 0.0006000000000021544],
                      [0, 0],
                    ],
                    v: [
                      [1.31071, 19.7479],
                      [15.6747, 0.376933],
                      [22.83, 4.50805],
                      [19.4369, 10.3849],
                      [24.2072, 18.647],
                      [30.9935, 18.6472],
                      [30.9937, 26.9092],
                      [7.03421, 29.6638],
                      [1.31071, 19.7479],
                    ],
                  },
                ],
                t: 27.1200010785833,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.242000000000001, -0.00039999999999906777],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447699999999998, 4.407699999999998],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.120600000000003, 3.6731],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, 0.0006000000000021544],
                      [0, 0],
                    ],
                    v: [
                      [1.31071, 19.7479],
                      [15.6747, 0.376933],
                      [22.83, 4.50805],
                      [19.4369, 10.3849],
                      [24.2072, 18.647],
                      [30.9935, 18.6472],
                      [30.9937, 26.9092],
                      [7.03421, 29.6638],
                      [1.31071, 19.7479],
                    ],
                  },
                ],
                t: 36.12000143621117,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.242000000000001, -0.00039999999999906777],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447699999999998, 4.407699999999998],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.120600000000003, 3.6731],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, 0.0006000000000021544],
                      [0, 0],
                    ],
                    v: [
                      [1.31071, 19.7479],
                      [15.6747, 0.376933],
                      [22.83, 4.50805],
                      [19.4369, 10.3849],
                      [24.2072, 18.647],
                      [30.9935, 18.6472],
                      [30.9937, 26.9092],
                      [7.03421, 29.6638],
                      [1.31071, 19.7479],
                    ],
                  },
                ],
                t: 36.15000143763609,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1213999999999995, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.407671],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.6731000000000016],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543799999999999, -4.407092],
                      [0, 0],
                    ],
                    v: [
                      [21.9522, 3.73433],
                      [31.5459, 25.8593],
                      [24.3906, 29.9905],
                      [20.9976, 24.1136],
                      [11.4573, 24.1137],
                      [8.06398, 29.9907],
                      [0.908818, 25.8599],
                      [10.5029, 3.73304],
                      [21.9522, 3.73433],
                    ],
                  },
                ],
                t: 45.15000179526396,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1213999999999995, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.407671],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.6731000000000016],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543799999999999, -4.407092],
                      [0, 0],
                    ],
                    v: [
                      [21.9522, 3.73433],
                      [31.5459, 25.8593],
                      [24.3906, 29.9905],
                      [20.9976, 24.1136],
                      [11.4573, 24.1137],
                      [8.06398, 29.9907],
                      [0.908818, 25.8599],
                      [10.5029, 3.73304],
                      [21.9522, 3.73433],
                    ],
                  },
                ],
                t: 45.180001796688884,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1213999999999995, -3.673499999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.407668],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.6729999999999983],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543799999999999, -4.407089],
                      [0, 0],
                    ],
                    v: [
                      [21.9522, 4.05586],
                      [31.5459, 26.1809],
                      [24.3906, 30.312],
                      [20.9976, 24.4351],
                      [11.4573, 24.4352],
                      [8.06398, 30.3123],
                      [0.908818, 26.1814],
                      [10.5029, 4.05457],
                      [21.9522, 4.05586],
                    ],
                  },
                ],
                t: 54.18000215431675,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1213999999999995, -3.673499999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.407668],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.6729999999999983],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543799999999999, -4.407089],
                      [0, 0],
                    ],
                    v: [
                      [21.9522, 4.05586],
                      [31.5459, 26.1809],
                      [24.3906, 30.312],
                      [20.9976, 24.4351],
                      [11.4573, 24.4352],
                      [8.06398, 30.3123],
                      [0.908818, 26.1814],
                      [10.5029, 4.05457],
                      [21.9522, 4.05586],
                    ],
                  },
                ],
                t: 54.21000215574168,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1213999999999995, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.407671],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.6731000000000016],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543799999999999, -4.407092],
                      [0, 0],
                    ],
                    v: [
                      [21.9522, 3.73433],
                      [31.5459, 25.8593],
                      [24.3906, 29.9905],
                      [20.9976, 24.1136],
                      [11.4573, 24.1137],
                      [8.06398, 29.9907],
                      [0.908818, 25.8599],
                      [10.5029, 3.73304],
                      [21.9522, 3.73433],
                    ],
                  },
                ],
                t: 63.210002513369545,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1213999999999995, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.407671],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.6731000000000016],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543799999999999, -4.407092],
                      [0, 0],
                    ],
                    v: [
                      [21.9522, 3.73433],
                      [31.5459, 25.8593],
                      [24.3906, 29.9905],
                      [20.9976, 24.1136],
                      [11.4573, 24.1137],
                      [8.06398, 29.9907],
                      [0.908818, 25.8599],
                      [10.5029, 3.73304],
                      [21.9522, 3.73433],
                    ],
                  },
                ],
                t: 63.24000251479447,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089500000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.24125, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.544800000000002, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [23.9577, 29.2868],
                      [0.0000013696, 26.5328],
                      [9.43109e-7, 18.2705],
                      [6.78605, 18.2705],
                      [11.5561, 10.0083],
                      [8.16308, 4.13111],
                      [15.3181, -7.90707e-7],
                      [29.6834, 19.3722],
                      [23.9577, 29.2868],
                    ],
                  },
                ],
                t: 72.24000287242234,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089500000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.24125, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.544800000000002, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [23.9577, 29.2868],
                      [0.0000013696, 26.5328],
                      [9.43109e-7, 18.2705],
                      [6.78605, 18.2705],
                      [11.5561, 10.0083],
                      [8.16308, 4.13111],
                      [15.3181, -7.90707e-7],
                      [29.6834, 19.3722],
                      [23.9577, 29.2868],
                    ],
                  },
                ],
                t: 72.27000287384726,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 1, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089500000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.2413, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5447999999999986, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [24.7119, 29.77],
                      [0.754155, 27.0159],
                      [0.754153, 18.7537],
                      [7.5402, 18.7537],
                      [12.3103, 10.4915],
                      [8.91723, 4.61427],
                      [16.0723, 0.483152],
                      [30.4376, 19.8553],
                      [24.7119, 29.77],
                    ],
                  },
                ],
                t: 81.27000323147513,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.089500000000001, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.2413, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5447999999999986, 4.406500000000001],
                      [0, 0],
                    ],
                    v: [
                      [24.7119, 29.77],
                      [0.754155, 27.0159],
                      [0.754153, 18.7537],
                      [7.5402, 18.7537],
                      [12.3103, 10.4915],
                      [8.91723, 4.61427],
                      [16.0723, 0.483152],
                      [30.4376, 19.8553],
                      [24.7119, 29.77],
                    ],
                  },
                ],
                t: 81.30000323290005,
              },
            ],
          },
        },
        {
          ty: 'fl',
          bm: 0,
          hd: false,
          nm: '',
          c: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 0.030000001424923543 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 9.030000359052792 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 9.060000360477716 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 18.060000718105584 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 18.090000719530508 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 27.090001077158377 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 27.1200010785833 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 36.12000143621117 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 36.15000143763609 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 45.15000179526396 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 45.180001796688884 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 54.18000215431675 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 54.21000215574168 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 63.210002513369545 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 63.24000251479447 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 72.24000287242234 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 72.27000287384726 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0.9804, 0.7176, 0.0039], t: 81.27000323147513 },
              { s: [0.9804, 0.7176, 0.0039], t: 81.30000323290005 },
            ],
          },
          r: 2,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0.030000001424923543 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.030000359052792 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.060000360477716 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.060000718105584 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.090000719530508 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.090001077158377 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.1200010785833 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.12000143621117 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.15000143763609 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.15000179526396 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.180001796688884 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.18000215431675 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.21000215574168 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.210002513369545 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.24000251479447 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.24000287242234 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.27000287384726 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 81.27000323147513 },
              { s: [100], t: 81.30000323290005 },
            ],
          },
        },
        {
          ty: 'rc',
          bm: 0,
          hd: false,
          nm: '',
          d: 1,
          p: { a: 0, k: [0, 0] },
          r: { a: 0, k: 0 },
          s: { a: 0, k: [200, 200] },
        },
        { ty: 'fl', bm: 0, hd: false, nm: '', c: { a: 0, k: [0, 0, 0] }, r: 2, o: { a: 0, k: 0 } },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'kirmizi',
      sr: 1,
      st: 0,
      op: 82.30000323290005,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0] },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [66.66668932158703, 97.4831595144794], t: 0 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [66.66668932158703, 97.4831595144794],
              t: 0.030000001424923543,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [37.00000230986828, 114.99999758820985],
              t: 9.030000359052792,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [37.00000230986828, 114.99999758820985],
              t: 9.060000360477716,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [87.53681385737463, 35.919446783057936],
              t: 18.060000718105584,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [87.53681385737463, 35.919446783057936],
              t: 18.090000719530508,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [87.53681385737464, 70.91944678305795],
              t: 27.090001077158377,
            },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [87.53681385737464, 70.91944678305795], t: 27.1200010785833 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [87.53681385737463, 35.919446783057936],
              t: 36.12000143621117,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [87.53681385737463, 35.919446783057936],
              t: 36.15000143763609,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [130.03668965079254, 118.91945068261992],
              t: 45.15000179526396,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [130.03668965079254, 118.91945068261992],
              t: 45.180001796688884,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [99.91181935049957, 101.76249877832302],
              t: 54.18000215431675,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [99.91181935049957, 101.76249877832302],
              t: 54.21000215574168,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [130.03668965079254, 118.91945068261992],
              t: 63.210002513369545,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [130.03668965079254, 118.91945068261992],
              t: 63.24000251479447,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [37.00000075588068, 114.99999921076653],
              t: 72.24000287242234,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [37.00000075588068, 114.99999921076653],
              t: 72.27000287384726,
            },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [66.66668932158703, 97.4831595144794], t: 81.27000323147513 },
            { s: [66.66668932158703, 97.4831595144794], t: 81.30000323290005 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0.030000001424923543 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 9.030000359052792 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 9.060000360477716 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 18.060000718105584 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 18.090000719530508 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 27.090001077158377 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 27.1200010785833 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 36.12000143621117 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 36.15000143763609 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 45.15000179526396 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 45.180001796688884 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 54.21000215574168 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 63.210002513369545 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 63.24000251479447 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 72.24000287242234 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 72.27000287384726 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 81.27000323147513 },
            { s: [0], t: 81.30000323290005 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0.030000001424923543 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.030000359052792 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.060000360477716 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.060000718105584 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.090000719530508 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.090001077158377 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.1200010785833 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.12000143621117 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.15000143763609 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.15000179526396 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.180001796688884 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.21000215574168 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.210002513369545 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.24000251479447 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.24000287242234 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.27000287384726 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 81.27000323147513 },
            { s: [100], t: 81.30000323290005 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'sh',
          bm: 0,
          hd: false,
          nm: '',
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.54476, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241200000000003, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.086779999999999, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.2458, 18.7537],
                      [31.2458, 27.0159],
                      [7.28534, 29.77],
                      [1.56236, 19.8553],
                      [15.9277, 0.483152],
                      [23.0827, 4.61426],
                      [19.6897, 10.4915],
                      [24.4597, 18.7537],
                      [31.2458, 18.7537],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.54476, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241200000000003, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.086779999999999, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.2458, 18.7537],
                      [31.2458, 27.0159],
                      [7.28534, 29.77],
                      [1.56236, 19.8553],
                      [15.9277, 0.483152],
                      [23.0827, 4.61426],
                      [19.6897, 10.4915],
                      [24.4597, 18.7537],
                      [31.2458, 18.7537],
                    ],
                  },
                ],
                t: 0.030000001424923543,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544768, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241299999999999, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.086779999999999, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.5791, 18.2705],
                      [30.5791, 26.5328],
                      [6.61865, 29.2868],
                      [0.895678, 19.3722],
                      [15.261, -0.00000240729],
                      [22.416, 4.13111],
                      [19.023, 10.0083],
                      [23.7931, 18.2705],
                      [30.5791, 18.2705],
                    ],
                  },
                ],
                t: 9.030000359052792,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544768, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241299999999999, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.086779999999999, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.5791, 18.2705],
                      [30.5791, 26.5328],
                      [6.61865, 29.2868],
                      [0.895678, 19.3722],
                      [15.261, -0.00000240729],
                      [22.416, 4.13111],
                      [19.023, 10.0083],
                      [23.7931, 18.2705],
                      [30.5791, 18.2705],
                    ],
                  },
                ],
                t: 9.060000360477716,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543800000000001, -4.407092],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.6731000000000016],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.5434, -4.405276000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1214000000000013, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.04042, 29.9904],
                      [0.885117, 25.8593],
                      [10.4802, 3.73193],
                      [21.9281, 3.73303],
                      [31.5222, 25.8598],
                      [24.3671, 29.9907],
                      [20.9738, 24.1137],
                      [11.4334, 24.1136],
                      [8.04042, 29.9904],
                    ],
                  },
                ],
                t: 18.060000718105584,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543800000000001, -4.407092],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.6731000000000016],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.5434, -4.405276000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1214000000000013, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.04042, 29.9904],
                      [0.885117, 25.8593],
                      [10.4802, 3.73193],
                      [21.9281, 3.73303],
                      [31.5222, 25.8598],
                      [24.3671, 29.9907],
                      [20.9738, 24.1137],
                      [11.4334, 24.1136],
                      [8.04042, 29.9904],
                    ],
                  },
                ],
                t: 18.090000719530508,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543800000000001, -4.407092],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.6731000000000016],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.5434, -4.405276000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1214000000000013, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.04042, 29.9904],
                      [0.885117, 25.8593],
                      [10.4802, 3.73193],
                      [21.9281, 3.73303],
                      [31.5222, 25.8598],
                      [24.3671, 29.9907],
                      [20.9738, 24.1137],
                      [11.4334, 24.1136],
                      [8.04042, 29.9904],
                    ],
                  },
                ],
                t: 27.090001077158377,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543800000000001, -4.407092],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.6731000000000016],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.5434, -4.405276000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1214000000000013, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.04042, 29.9904],
                      [0.885117, 25.8593],
                      [10.4802, 3.73193],
                      [21.9281, 3.73303],
                      [31.5222, 25.8598],
                      [24.3671, 29.9907],
                      [20.9738, 24.1137],
                      [11.4334, 24.1136],
                      [8.04042, 29.9904],
                    ],
                  },
                ],
                t: 27.1200010785833,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543800000000001, -4.407092],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.6731000000000016],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.5434, -4.405276000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1214000000000013, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.04042, 29.9904],
                      [0.885117, 25.8593],
                      [10.4802, 3.73193],
                      [21.9281, 3.73303],
                      [31.5222, 25.8598],
                      [24.3671, 29.9907],
                      [20.9738, 24.1137],
                      [11.4334, 24.1136],
                      [8.04042, 29.9904],
                    ],
                  },
                ],
                t: 36.12000143621117,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543800000000001, -4.407092],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.6731000000000016],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.5434, -4.405276000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1214000000000013, -3.6735000000000007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.04042, 29.9904],
                      [0.885117, 25.8593],
                      [10.4802, 3.73193],
                      [21.9281, 3.73303],
                      [31.5222, 25.8598],
                      [24.3671, 29.9907],
                      [20.9738, 24.1137],
                      [11.4334, 24.1136],
                      [8.04042, 29.9904],
                    ],
                  },
                ],
                t: 36.15000143763609,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, 0.0004999999999988347],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.673],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.543400000000002, 4.4053],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.242, -0.0005000000000023874],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.82274, 4.50819],
                      [15.978, 0.377075],
                      [30.3434, 19.7504],
                      [24.6185, 29.664],
                      [0.659049, 26.9093],
                      [0.659187, 18.6473],
                      [7.4455, 18.6472],
                      [12.2158, 10.3851],
                      [8.82274, 4.50819],
                    ],
                  },
                ],
                t: 45.15000179526396,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, 0.0004999999999988347],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.673],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.543400000000002, 4.4053],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.242, -0.0005000000000023874],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.82274, 4.50819],
                      [15.978, 0.377075],
                      [30.3434, 19.7504],
                      [24.6185, 29.664],
                      [0.659049, 26.9093],
                      [0.659187, 18.6473],
                      [7.4455, 18.6472],
                      [12.2158, 10.3851],
                      [8.82274, 4.50819],
                    ],
                  },
                ],
                t: 45.180001796688884,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, 0.0005999999999986017],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.673],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.543400000000002, 4.4053],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.242, -0.0004000000000026205],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.69774, 4.35121],
                      [15.853, 0.220092],
                      [30.2184, 19.5934],
                      [24.4935, 29.507],
                      [0.534049, 26.7523],
                      [0.534187, 18.4904],
                      [7.3205, 18.4902],
                      [12.0908, 10.2281],
                      [8.69774, 4.35121],
                    ],
                  },
                ],
                t: 54.18000215431675,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, 0.0005999999999986017],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.673],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.543400000000002, 4.4053],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.242, -0.0004000000000026205],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.69774, 4.35121],
                      [15.853, 0.220092],
                      [30.2184, 19.5934],
                      [24.4935, 29.507],
                      [0.534049, 26.7523],
                      [0.534187, 18.4904],
                      [7.3205, 18.4902],
                      [12.0908, 10.2281],
                      [8.69774, 4.35121],
                    ],
                  },
                ],
                t: 54.21000215574168,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, 0.0004999999999988347],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.673],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.543400000000002, 4.4053],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.242, -0.0005000000000023874],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.82274, 4.50819],
                      [15.978, 0.377075],
                      [30.3434, 19.7504],
                      [24.6185, 29.664],
                      [0.659049, 26.9093],
                      [0.659187, 18.6473],
                      [7.4455, 18.6472],
                      [12.2158, 10.3851],
                      [8.82274, 4.50819],
                    ],
                  },
                ],
                t: 63.210002513369545,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, 0.0004999999999988347],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, 3.673],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.543400000000002, 4.4053],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.242, -0.0005000000000023874],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.82274, 4.50819],
                      [15.978, 0.377075],
                      [30.3434, 19.7504],
                      [24.6185, 29.664],
                      [0.659049, 26.9093],
                      [0.659187, 18.6473],
                      [7.4455, 18.6472],
                      [12.2158, 10.3851],
                      [8.82274, 4.50819],
                    ],
                  },
                ],
                t: 63.24000251479447,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544766, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241299999999999, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.08677, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.5791, 18.2705],
                      [30.5791, 26.5328],
                      [6.61864, 29.2868],
                      [0.895676, 19.3722],
                      [15.261, -7.8776e-7],
                      [22.416, 4.13111],
                      [19.023, 10.0083],
                      [23.7931, 18.2705],
                      [30.5791, 18.2705],
                    ],
                  },
                ],
                t: 72.24000287242234,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544766, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241299999999999, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.08677, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.5791, 18.2705],
                      [30.5791, 26.5328],
                      [6.61864, 29.2868],
                      [0.895676, 19.3722],
                      [15.261, -7.8776e-7],
                      [22.416, 4.13111],
                      [19.023, 10.0083],
                      [23.7931, 18.2705],
                      [30.5791, 18.2705],
                    ],
                  },
                ],
                t: 72.27000287384726,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 1, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.54476, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241200000000003, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.086779999999999, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.2458, 18.7537],
                      [31.2458, 27.0159],
                      [7.28534, 29.77],
                      [1.56236, 19.8553],
                      [15.9277, 0.483152],
                      [23.0827, 4.61426],
                      [19.6897, 10.4915],
                      [24.4597, 18.7537],
                      [31.2458, 18.7537],
                    ],
                  },
                ],
                t: 81.27000323147513,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.54476, 4.406500000000001],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.241200000000003, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-5.086779999999999, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, 3.6738999999999997],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.2458, 18.7537],
                      [31.2458, 27.0159],
                      [7.28534, 29.77],
                      [1.56236, 19.8553],
                      [15.9277, 0.483152],
                      [23.0827, 4.61426],
                      [19.6897, 10.4915],
                      [24.4597, 18.7537],
                      [31.2458, 18.7537],
                    ],
                  },
                ],
                t: 81.30000323290005,
              },
            ],
          },
        },
        {
          ty: 'fl',
          bm: 0,
          hd: false,
          nm: '',
          c: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 0.030000001424923543 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 9.030000359052792 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 9.060000360477716 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 18.060000718105584 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 18.090000719530508 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 27.090001077158377 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 27.1200010785833 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 36.12000143621117 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 36.15000143763609 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 45.15000179526396 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 45.180001796688884 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 54.18000215431675 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 54.21000215574168 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 63.210002513369545 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 63.24000251479447 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 72.24000287242234 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 72.27000287384726 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0.9686, 0.4275, 0.4275], t: 81.27000323147513 },
              { s: [0.9686, 0.4275, 0.4275], t: 81.30000323290005 },
            ],
          },
          r: 2,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0.030000001424923543 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.030000359052792 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.060000360477716 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.060000718105584 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.090000719530508 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.090001077158377 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.1200010785833 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.12000143621117 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.15000143763609 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.15000179526396 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.180001796688884 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.18000215431675 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.21000215574168 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.210002513369545 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.24000251479447 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.24000287242234 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.27000287384726 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 81.27000323147513 },
              { s: [100], t: 81.30000323290005 },
            ],
          },
        },
        {
          ty: 'rc',
          bm: 0,
          hd: false,
          nm: '',
          d: 1,
          p: { a: 0, k: [0, 0] },
          r: { a: 0, k: 0 },
          s: { a: 0, k: [200, 200] },
        },
        { ty: 'fl', bm: 0, hd: false, nm: '', c: { a: 0, k: [0, 0, 0] }, r: 2, o: { a: 0, k: 0 } },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: 'mavi',
      sr: 1,
      st: 0,
      op: 82.30000323290005,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0] },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [84.68121571049595, 67.28198763497647], t: 0 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [84.68121571049595, 67.28198763497647],
              t: 0.030000001424923543,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [84.6811546753397, 32.999997583706936],
              t: 9.030000359052792,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [84.6811546753397, 32.999997583706936],
              t: 9.060000360477716,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [134.14182994863157, 118.8751874824107],
              t: 18.060000718105584,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [134.14182994863157, 118.8751874824107],
              t: 18.090000719530508,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [104.1418299486316, 100.87518748241072],
              t: 27.090001077158377,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [104.1418299486316, 100.87518748241072],
              t: 27.1200010785833,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [134.14182994863157, 118.8751874824107],
              t: 36.12000143621117,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [134.14182994863157, 118.8751874824107],
              t: 36.15000143763609,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [35.14181472646821, 118.87520138811962],
              t: 45.15000179526396,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [35.14181472646821, 118.87520138811962],
              t: 45.180001796688884,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [65.5048022447788, 100.98315732073681],
              t: 54.18000215431675,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [65.5048022447788, 100.98315732073681],
              t: 54.21000215574168,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [35.14181472646821, 118.87520138811962],
              t: 63.210002513369545,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [35.14181472646821, 118.87520138811962],
              t: 63.24000251479447,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [85.00000076298879, 32.999999209292994],
              t: 72.24000287242234,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [85.00000076298879, 32.999999209292994],
              t: 72.27000287384726,
            },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [84.68121571049595, 67.28198763497647], t: 81.27000323147513 },
            { s: [84.68121571049595, 67.28198763497647], t: 81.30000323290005 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0.030000001424923543 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 9.030000359052792 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 9.060000360477716 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 18.060000718105584 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 18.090000719530508 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 27.090001077158377 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 27.1200010785833 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 36.12000143621117 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 36.15000143763609 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 45.15000179526396 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 45.180001796688884 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 54.21000215574168 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 63.210002513369545 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 63.24000251479447 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 72.24000287242234 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 72.27000287384726 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 81.27000323147513 },
            { s: [0], t: 81.30000323290005 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0.030000001424923543 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.030000359052792 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.060000360477716 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.060000718105584 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.090000719530508 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.090001077158377 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.1200010785833 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.12000143621117 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.15000143763609 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.15000179526396 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.180001796688884 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.18000215431675 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.21000215574168 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.210002513369545 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.24000251479447 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.24000287242234 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.27000287384726 },
            { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 81.27000323147513 },
            { s: [100], t: 81.30000323290005 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'sh',
          bm: 0,
          hd: false,
          nm: '',
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447000000000024, -4.406521],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1206999999999994, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5448000000000004, -4.4065199999999995],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.3174, 25.7131],
                      [24.1624, 29.8442],
                      [20.7694, 23.967],
                      [11.2293, 23.967],
                      [7.83624, 29.8442],
                      [0.681217, 25.7131],
                      [10.2736, 3.58687],
                      [21.725, 3.58687],
                      [31.3174, 25.7131],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447000000000024, -4.406521],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1206999999999994, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5448000000000004, -4.4065199999999995],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.3174, 25.7131],
                      [24.1624, 29.8442],
                      [20.7694, 23.967],
                      [11.2293, 23.967],
                      [7.83624, 29.8442],
                      [0.681217, 25.7131],
                      [10.2736, 3.58687],
                      [21.725, 3.58687],
                      [31.3174, 25.7131],
                    ],
                  },
                ],
                t: 0.030000001424923543,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.40652],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5448000000000004, -4.40652],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.3173, 25.4311],
                      [24.1623, 29.5622],
                      [20.7693, 23.685],
                      [11.2292, 23.685],
                      [7.83618, 29.5622],
                      [0.681156, 25.4311],
                      [10.2735, 3.30489],
                      [21.725, 3.30489],
                      [31.3173, 25.4311],
                    ],
                  },
                ],
                t: 9.030000359052792,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.544800000000002, -4.40652],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1205999999999996, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5448000000000004, -4.40652],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.3173, 25.4311],
                      [24.1623, 29.5622],
                      [20.7693, 23.685],
                      [11.2292, 23.685],
                      [7.83618, 29.5622],
                      [0.681156, 25.4311],
                      [10.2735, 3.30489],
                      [21.725, 3.30489],
                      [31.3173, 25.4311],
                    ],
                  },
                ],
                t: 9.060000360477716,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.088500000000003, -0.0005000000000023874],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.23963, -0.0009000000000014552],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543800000000001, 4.4071],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.577703, 26.5972],
                      [0.577561, 18.3352],
                      [7.36387, 18.3353],
                      [12.1339, 10.0734],
                      [8.74064, 4.19632],
                      [15.8958, 0.0654449],
                      [30.2615, 19.4358],
                      [24.5358, 29.353],
                      [0.577703, 26.5972],
                    ],
                  },
                ],
                t: 18.060000718105584,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.088500000000003, -0.0005000000000023874],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.23963, -0.0009000000000014552],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543800000000001, 4.4071],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.577703, 26.5972],
                      [0.577561, 18.3352],
                      [7.36387, 18.3353],
                      [12.1339, 10.0734],
                      [8.74064, 4.19632],
                      [15.8958, 0.0654449],
                      [30.2615, 19.4358],
                      [24.5358, 29.353],
                      [0.577703, 26.5972],
                    ],
                  },
                ],
                t: 18.090000719530508,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.088500000000003, -0.0005000000000023874],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.23963, -0.0009000000000014552],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543800000000001, 4.4071],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.577703, 26.5972],
                      [0.577561, 18.3352],
                      [7.36387, 18.3353],
                      [12.1339, 10.0734],
                      [8.74064, 4.19632],
                      [15.8958, 0.0654449],
                      [30.2615, 19.4358],
                      [24.5358, 29.353],
                      [0.577703, 26.5972],
                    ],
                  },
                ],
                t: 27.090001077158377,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.088500000000003, -0.0005000000000023874],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.23963, -0.0009000000000014552],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.543800000000001, 4.4071],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.577703, 26.5972],
                      [0.577561, 18.3352],
                      [7.36387, 18.3353],
                      [12.1339, 10.0734],
                      [8.74064, 4.19632],
                      [15.8958, 0.0654449],
                      [30.2615, 19.4358],
                      [24.5358, 29.353],
                      [0.577703, 26.5972],
                    ],
                  },
                ],
                t: 27.1200010785833,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, -0.0005000000000023874],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.23961, -0.0009000000000014552],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5437999999999974, 4.4071],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.577825, 26.5972],
                      [0.577683, 18.3352],
                      [7.36399, 18.3353],
                      [12.1341, 10.0734],
                      [8.74076, 4.19632],
                      [15.8959, 0.0654449],
                      [30.2616, 19.4358],
                      [24.5359, 29.353],
                      [0.577825, 26.5972],
                    ],
                  },
                ],
                t: 36.12000143621117,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.0885, -0.0005000000000023874],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.23961, -0.0009000000000014552],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5437999999999974, 4.4071],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.577825, 26.5972],
                      [0.577683, 18.3352],
                      [7.36399, 18.3353],
                      [12.1341, 10.0734],
                      [8.74076, 4.19632],
                      [15.8959, 0.0654449],
                      [30.2616, 19.4358],
                      [24.5359, 29.353],
                      [0.577825, 26.5972],
                    ],
                  },
                ],
                t: 36.15000143763609,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.239600000000003, -0.0010000000000012221],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543773, 4.4071],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1190000000000033, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, -0.0006000000000021544],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [16.024, 0.0654594],
                      [23.1792, 4.19633],
                      [19.7859, 10.0734],
                      [24.5559, 18.3354],
                      [31.3423, 18.3352],
                      [31.3421, 26.5972],
                      [7.38405, 29.3531],
                      [1.65832, 19.4358],
                      [16.024, 0.0654594],
                    ],
                  },
                ],
                t: 45.15000179526396,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.239600000000003, -0.0010000000000012221],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543773, 4.4071],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1190000000000033, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, -0.0006000000000021544],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [16.024, 0.0654594],
                      [23.1792, 4.19633],
                      [19.7859, 10.0734],
                      [24.5559, 18.3354],
                      [31.3423, 18.3352],
                      [31.3421, 26.5972],
                      [7.38405, 29.3531],
                      [1.65832, 19.4358],
                      [16.024, 0.0654594],
                    ],
                  },
                ],
                t: 45.180001796688884,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.239699999999999, -0.0010000000000012221],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.54378, 4.4071],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, -0.0005999999999986017],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [15.3871, 0.17337],
                      [22.5422, 4.30424],
                      [19.1489, 10.1813],
                      [23.919, 18.4433],
                      [30.7053, 18.4431],
                      [30.7052, 26.7051],
                      [6.74708, 29.461],
                      [1.02136, 19.5437],
                      [15.3871, 0.17337],
                    ],
                  },
                ],
                t: 54.18000215431675,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.239699999999999, -0.0010000000000012221],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.54378, 4.4071],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1189999999999998, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, -0.0005999999999986017],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [15.3871, 0.17337],
                      [22.5422, 4.30424],
                      [19.1489, 10.1813],
                      [23.919, 18.4433],
                      [30.7053, 18.4431],
                      [30.7052, 26.7051],
                      [6.74708, 29.461],
                      [1.02136, 19.5437],
                      [15.3871, 0.17337],
                    ],
                  },
                ],
                t: 54.21000215574168,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.239600000000003, -0.0010000000000012221],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543773, 4.4071],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1190000000000033, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, -0.0006000000000021544],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [16.024, 0.0654594],
                      [23.1792, 4.19633],
                      [19.7859, 10.0734],
                      [24.5559, 18.3354],
                      [31.3423, 18.3352],
                      [31.3421, 26.5972],
                      [7.38405, 29.3531],
                      [1.65832, 19.4358],
                      [16.024, 0.0654594],
                    ],
                  },
                ],
                t: 63.210002513369545,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.239600000000003, -0.0010000000000012221],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.543773, 4.4071],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1190000000000033, 3.6721000000000004],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.08854, -0.0006000000000021544],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [16.024, 0.0654594],
                      [23.1792, 4.19633],
                      [19.7859, 10.0734],
                      [24.5559, 18.3354],
                      [31.3423, 18.3352],
                      [31.3421, 26.5972],
                      [7.38405, 29.3531],
                      [1.65832, 19.4358],
                      [16.024, 0.0654594],
                    ],
                  },
                ],
                t: 63.24000251479447,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447000000000024, -4.40652],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1206999999999994, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5447299999999995, -4.40652],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.6362, 25.4311],
                      [23.4812, 29.5622],
                      [20.0882, 23.685],
                      [10.548, 23.685],
                      [7.15502, 29.5622],
                      [0.00000131273, 25.4311],
                      [9.59237, 3.30489],
                      [21.0438, 3.30489],
                      [30.6362, 25.4311],
                    ],
                  },
                ],
                t: 72.24000287242234,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1206999999999994, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447000000000024, -4.40652],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1206999999999994, -3.671099999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5447299999999995, -4.40652],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.6362, 25.4311],
                      [23.4812, 29.5622],
                      [20.0882, 23.685],
                      [10.548, 23.685],
                      [7.15502, 29.5622],
                      [0.00000131273, 25.4311],
                      [9.59237, 3.30489],
                      [21.0438, 3.30489],
                      [30.6362, 25.4311],
                    ],
                  },
                ],
                t: 72.27000287384726,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 1, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447000000000024, -4.406521],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1206999999999994, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5448000000000004, -4.4065199999999995],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.3174, 25.7131],
                      [24.1624, 29.8442],
                      [20.7694, 23.967],
                      [11.2293, 23.967],
                      [7.83624, 29.8442],
                      [0.681217, 25.7131],
                      [10.2736, 3.58687],
                      [21.725, 3.58687],
                      [31.3174, 25.7131],
                    ],
                  },
                ],
                t: 81.27000323147513,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.1205999999999996, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.5447000000000024, -4.406521],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.1206999999999994, -3.671199999999999],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.5448000000000004, -4.4065199999999995],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.3174, 25.7131],
                      [24.1624, 29.8442],
                      [20.7694, 23.967],
                      [11.2293, 23.967],
                      [7.83624, 29.8442],
                      [0.681217, 25.7131],
                      [10.2736, 3.58687],
                      [21.725, 3.58687],
                      [31.3174, 25.7131],
                    ],
                  },
                ],
                t: 81.30000323290005,
              },
            ],
          },
        },
        {
          ty: 'fl',
          bm: 0,
          hd: false,
          nm: '',
          c: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 0.030000001424923543 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 9.030000359052792 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 9.060000360477716 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 18.060000718105584 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 18.090000719530508 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 27.090001077158377 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 27.1200010785833 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 36.12000143621117 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 36.15000143763609 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 45.15000179526396 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 45.180001796688884 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 54.18000215431675 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 54.21000215574168 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 63.210002513369545 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 63.24000251479447 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 72.24000287242234 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 72.27000287384726 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0.4196, 0.5686, 0.8784], t: 81.27000323147513 },
              { s: [0.4196, 0.5686, 0.8784], t: 81.30000323290005 },
            ],
          },
          r: 2,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0.030000001424923543 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.030000359052792 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 9.060000360477716 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.060000718105584 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 18.090000719530508 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.090001077158377 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 27.1200010785833 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.12000143621117 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 36.15000143763609 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.15000179526396 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 45.180001796688884 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.18000215431675 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 54.21000215574168 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.210002513369545 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 63.24000251479447 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.24000287242234 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 72.27000287384726 },
              { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 81.27000323147513 },
              { s: [100], t: 81.30000323290005 },
            ],
          },
        },
        {
          ty: 'rc',
          bm: 0,
          hd: false,
          nm: '',
          d: 1,
          p: { a: 0, k: [0, 0] },
          r: { a: 0, k: 0 },
          s: { a: 0, k: [200, 200] },
        },
        { ty: 'fl', bm: 0, hd: false, nm: '', c: { a: 0, k: [0, 0, 0] }, r: 2, o: { a: 0, k: 0 } },
      ],
      ind: 3,
    },
  ],
  v: '5.7.0',
  fr: 30,
  op: 81.30000323290005,
  ip: 0,
  assets: [],
};
