import {
  ExternalAppConnectionType,
  ExternalAppTrigger,
  FormTrigger,
  Integration,
  IntegrationFieldType,
  IntegrationWebhookTrigger,
  NonFormTrigger,
  OperatorType,
  QUERY_KEYS_WORKFLOWS,
  useGetWorkflow,
  usePersistWorkflow,
  useSaveToDraftWorkflow,
  useSearchInfiniteIntegrations,
} from '@novaera/actioner-service';
import {
  EmptyInterface,
  NodeListItem,
  NodeListProps,
  NvChip,
  NvMailOutlineIcon,
  NvNodeList,
  NvSensorsIcon,
  NvTypography,
  NvWebhookIcon,
  NvWysiwygIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import { matchSorter } from 'match-sorter';
import { FC, useCallback, useMemo, useState } from 'react';
import { LogoPlaceholder } from '../../../../../../components/logo-placeholder';
import { DropdownSimpleSearchInput } from '../../../../../../components/simple-search-input/styled';
import { SLACK_INTEGRATION_ID, TRIGGER_NODE_ALIAS } from '../../constants';
import { userAppGraph } from '../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../use-novaera-flow';
import { isTriggerWorkflowType } from '../../utils';
import { BaseNodeProps } from '../types';
import { PaperWrapper, StartNodeWrapper } from './styled';

export interface StartNodeProps extends BaseNodeProps {
  name: string;
}

export const StartNode: FC<React.PropsWithChildren<StartNodeProps>> = ({ name }) => {
  const { insertTriggerNode } = useNovaeraFlow(userAppGraph);
  const [keyword, setKeyword] = useState<string>('');
  const cache = useQueryClient();
  const { data, isLoading } = useSearchInfiniteIntegrations({
    ...(keyword && { value: keyword }),
    filters: [
      {
        field: IntegrationFieldType.EVENT_RULE_COUNT,
        operator: OperatorType.GREATER_THAN,
        value: 0,
      },
    ],
  });
  const { userAppId, workflowId } = useParams();
  const { mutate: saveToDraftWorkflow } = useSaveToDraftWorkflow();
  const { mutate: persistWorkflow } = usePersistWorkflow();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const staticTriggers = useMemo(() => {
    return matchSorter(
      [
        {
          icon: <NvWysiwygIcon />,
          name: 'Manual',
          description: 'Trigger your workflow by submitting a form.',
          type: 'form',
          order: 1,
        },
        {
          icon: <NvWebhookIcon />,
          name: 'Webhook',
          description: 'Trigger your workflow when a webhook event is received.',
          type: 'genericWebhook',
          order: 10,
        },
        {
          icon: <NvMailOutlineIcon />,
          name: 'Email',
          description: 'Trigger your workflow when an email is received.',
          type: 'email',
          order: 30,
        },
        {
          icon: <NvSensorsIcon />,
          name: 'Actioner event',
          description: 'Trigger your workflow when an Actioner event is received.',
          type: 'actionerEvent',
          order: 20,
        },
      ],
      keyword,
      { keys: ['order', 'name'] }
    );
  }, [keyword]);

  const rawIntegrations = useMemo(() => {
    return data?.pages[0]?.integrations.map((integration) => {
      const integrationRetVal: NodeListItem<Integration> = {
        icon: integration.logoUrl ?? (
          <LogoPlaceholder
            size="small"
            src="assets/integration-placeholder-image.png"
            initialText={integration?.name ?? 'Integration'}
          />
        ),
        name: integration.name,
        type: integration.triggerType,
        extraData: integration,
        ...(integration.scope.type === 'workspace' && { rightComponent: <NvChip label="Custom" compact /> }),
      };
      return integrationRetVal;
    });
  }, [data?.pages]);

  const integrations: typeof rawIntegrations = useMemo(() => {
    const slackItem = rawIntegrations?.find((item) => item.extraData?.id === SLACK_INTEGRATION_ID);

    if (slackItem) {
      const notSlackItems = rawIntegrations?.filter((item) => !(item.extraData?.id === SLACK_INTEGRATION_ID));
      if (notSlackItems) {
        const result = [slackItem, ...notSlackItems];
        return result;
      } else {
        return [slackItem];
      }
    } else {
      return rawIntegrations;
    }
  }, [rawIntegrations]);

  const handleInsertTriggerNode = useCallback(
    (item: NodeListItem<Integration>) => {
      assert(!!item?.type, new Error(`Item type should be defined`), 'ERROR');
      assert(
        isTriggerWorkflowType(item.type),
        new Error(`item type: ${item.type} should in trigger workflow types`),
        'ERROR'
      );
      insertTriggerNode({
        graphComponent: {
          root: {
            id: TRIGGER_NODE_ALIAS,
            type: item.type,
            name: item.name,
            width: 40,
            height: 40,
            alias: TRIGGER_NODE_ALIAS,
            selected: true,
          },
        },
      });
    },
    [insertTriggerNode]
  );

  const handleItemClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any, item: NodeListItem<Integration>) => {
      event.stopPropagation();

      assert(!!workflow, new Error(`Workflow should be defined`), 'ERROR');
      const newWorkflow = { ...workflow };

      if (item.type === 'form') {
        const formTrigger: FormTrigger = {
          runButtonLabel: 'Run',
          inputParameters: [],
          name: item.name,
          type: 'form',
          useDefaultUserAsPrimeUser: false,
        };
        newWorkflow.trigger = formTrigger;
      } else if (item.type === 'integrationApp') {
        const newIntegrationTrigger: ExternalAppTrigger = {
          name: item.name,
          type: item.type,
          rootCondition: { type: 'no-condition' },
          eventRuleIdentifier: {
            integrationId: item.extraData?.id ?? '',
            version: item.extraData?.latestVersion ?? { number: 1 },
            eventRuleId: '',
          },
          connectionType: ExternalAppConnectionType.OVERRIDE_BY_DEFAULT_USER,
        };
        newWorkflow.trigger = newIntegrationTrigger;
      } else if (item.type === 'integrationWebhook') {
        const newIntegrationTrigger: IntegrationWebhookTrigger = {
          name: item.name,
          type: item.type,
          rootCondition: { type: 'no-condition' },
          eventRuleIdentifier: {
            integrationId: item.extraData?.id ?? '',
            version: item.extraData?.latestVersion ?? { number: 1 },
            eventRuleId: '',
          },
        };
        newWorkflow.trigger = newIntegrationTrigger;
      } else if (item.type === 'genericWebhook') {
        const newWebhookTrigger: NonFormTrigger = {
          name: item.name,
          type: item.type,
          rootCondition: { type: 'no-condition' },
        };
        newWorkflow.trigger = newWebhookTrigger;
      } else if (item.type === 'email') {
        const newWebhookTrigger: NonFormTrigger = {
          name: item.name,
          type: item.type,
          rootCondition: { type: 'no-condition' },
          emailAddress: '',
          emailLocalPartSuffix: '',
        };
        newWorkflow.trigger = newWebhookTrigger;
      } else if (item.type === 'actionerEvent') {
        const newWebhookTrigger: NonFormTrigger = {
          name: item.name,
          type: item.type,
          rootCondition: { type: 'no-condition' },
          eventId: '',
        };
        newWorkflow.trigger = newWebhookTrigger;
      } else {
        assert(false, new Error(`Unknown trigger type: ${item.type} `), 'ERROR');
      }

      saveToDraftWorkflow(newWorkflow, {
        onSuccess: () => {
          if (item.type === 'genericWebhook') {
            persistWorkflow(
              { appId: userAppId, id: workflowId },
              {
                onSuccess: () => {
                  handleInsertTriggerNode(item);
                },
              }
            );
          } else if (item.type === 'email' || item.type === 'actionerEvent') {
            cache
              .invalidateQueries(QUERY_KEYS_WORKFLOWS.detail({ appId: userAppId, workflowId: workflowId }))
              .then(() => {
                handleInsertTriggerNode(item);
              });
          } else {
            handleInsertTriggerNode(item);
          }
        },
      });
    },
    [cache, handleInsertTriggerNode, persistWorkflow, saveToDraftWorkflow, userAppId, workflow, workflowId]
  );

  const triggerItems: NodeListItem<Pick<IntegrationWebhookTrigger, 'name' | 'type'> | EmptyInterface>[] = [
    ...staticTriggers,
    ...(integrations ?? []),
  ];

  return (
    <StartNodeWrapper className="nodrag">
      <NvTypography variant="h4" flex="1 1 auto" minWidth={0} noWrap>
        {name}
      </NvTypography>
      <PaperWrapper className="nowheel">
        {/* damn react.forwardRef is not letting me to pass generics, until we find a way I need to cast this item click */}
        {/* this is what I can find so far https://stackoverflow.com/a/58473012/1027814 */}
        <DropdownSimpleSearchInput
          autoFocus
          onKeywordChanged={(keyword) => {
            setKeyword(keyword ?? '');
          }}
        />
        <NvNodeList
          nodes={triggerItems}
          onItemClick={handleItemClick as NodeListProps['onItemClick']}
          isLoading={isLoading}
        />
      </PaperWrapper>
    </StartNodeWrapper>
  );
};
