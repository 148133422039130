import { UIComponentType, USER_PERMISSION } from '@novaera/actioner-service';
import { NvDragIndicatorIcon, NvField, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classnames from 'classnames';
import { FC, useCallback } from 'react';
import { useDynamicInputContext, useIntegrationActionOptionsContext } from '../../../../components';
import { useUIComponents } from '../../../../components/ui-components/use-ui-component';
import { UserPermissionBoundary } from '../../../../user-permission-boundary';
import { useInputsContext } from '../../../providers/input-provider';
import { useSearchAsYouTypeValuesContext } from '../../../providers/search-as-you-type-values';
import {
  DragItemFlex,
  StyledActionBox,
  StyledCloseIconContainer,
  StyledInputParameter,
  StyledInputPathContainer,
  StyledNvCloseIcon,
  StyledWarningIcon,
  WarningIconFlex,
} from './styled';
import { InputParameterProps } from './types';

export const InputParameter: FC<React.PropsWithChildren<InputParameterProps>> = (props) => {
  const { setSelectedInputParameterId, selectedInputParameterId } = useInputsContext();
  const { setSearchAsYouTypeValues } = useSearchAsYouTypeValuesContext();
  const theme = useTheme();

  const { onRemove, index, ...inputParameterProps } = props;
  const {
    name,
    id,
    uiComponent: { type },
  } = props;

  const onClick = () => {
    setSelectedInputParameterId(id);
  };

  const onDeleteButtonClicked = () => {
    setSelectedInputParameterId();
    onRemove();
  };

  const isInErrorState = false;

  const handleSearchAsYouTypeValueUpdate = useCallback(
    ({ value, inputParameterId }: { value: string; inputParameterId: string }) => {
      setSearchAsYouTypeValues((prev) => ({ ...prev, [inputParameterId]: value }));
    },
    [setSearchAsYouTypeValues]
  );
  const { getOptionsResponse, setInputParameterIdsShowingOptions } = useIntegrationActionOptionsContext();
  const { getDynamicInputParameters, getDynamicInputComponentState } = useDynamicInputContext();
  const { componentFieldProps, getComponentForField } = useUIComponents({
    inputParameter: inputParameterProps,
    getOptionsResponse,
    getDynamicInputResponse: getDynamicInputParameters,
    setInputParameterIdsShowingOptions,
    onSearchAsYouTypeValueUpdate: handleSearchAsYouTypeValueUpdate,
    dynamicInputNestedComponentProps: {
      labelVariant: 'h5',
    },
    getDynamicInputComponentState,
    forceValueUsage: true,
  });

  return (
    <StyledInputParameter
      className={classnames({
        'is-selected': id === selectedInputParameterId,
        'has-error': isInErrorState,
      })}
      onClick={onClick}
      position="relative"
    >
      <NvField
        {...componentFieldProps}
        labelVariant="h5"
        direction="label-on-top"
        hideLabel={type === UIComponentType.DYNAMIC_INPUT}
        name={id}
        component={
          type === UIComponentType.BLOCK_LIST ? (
            <NvTypography>Slack block not working in action designer</NvTypography>
          ) : type === UIComponentType.RECORD_ATTRIBUTES ? (
            <NvTypography>Record attributes is not working in action designer</NvTypography>
          ) : type === UIComponentType.RECORD_UPDATE_ATTRIBUTES ? (
            <NvTypography>Record update attributes is not working in action designer</NvTypography>
          ) : type === UIComponentType.RECORD_PARTIAL_UPDATE ? (
            <NvTypography>Record partial update is not working in action designer</NvTypography>
          ) : type === UIComponentType.JOB_TARGET ? (
            <NvTypography>Job target not working in action designer</NvTypography>
          ) : type === UIComponentType.CATALOG_RELATIONSHIP_FILTER ? (
            <NvTypography>Catalog relationship filter not working in action designer</NvTypography>
          ) : (
            getComponentForField({
              inputParameter: inputParameterProps,
            })
          )
        }
      />

      <DragItemFlex className="drag-icon">
        <NvDragIndicatorIcon htmlColor="white" fontSize="small" />
      </DragItemFlex>

      {isInErrorState && (
        <WarningIconFlex>
          <StyledWarningIcon variant="circle" />
        </WarningIconFlex>
      )}

      {id === selectedInputParameterId && (
        <StyledActionBox>
          <StyledInputPathContainer display="flex" alignItems="center" flexDirection="row">
            <NvTypography variant="c3" noWrap color={theme.palette.nv_neutral[0]}>{`{{input.${name}}}`}</NvTypography>
          </StyledInputPathContainer>
          <UserPermissionBoundary permission={USER_PERMISSION.INTEGRATION_UPDATE}>
            <StyledCloseIconContainer display="flex" alignItems="center" flexDirection="row" justifyContent="center">
              <StyledNvCloseIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteButtonClicked();
                }}
              ></StyledNvCloseIcon>
            </StyledCloseIconContainer>
          </UserPermissionBoundary>
        </StyledActionBox>
      )}
    </StyledInputParameter>
  );
};
