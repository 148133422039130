import { NvField, NvTagsAutoComplete, NvTextField, isRequired } from '@novaera/core';

export const ModalBody = () => {
  return (
    <>
      <NvField
        name="name"
        direction="label-on-top"
        labelText="Name"
        isRequired
        validators={[isRequired()]}
        hasRequiredIndicator
        component={<NvTextField />}
        showErrorMessageOnlyWhenBlur
      />
      <NvField
        direction="label-on-top"
        name="tags"
        labelText="Tags"
        showErrorMessageOnlyWhenBlur
        isAutoComplete
        component={<NvTagsAutoComplete placeholder={'Add tags'} />}
      />
    </>
  );
};
