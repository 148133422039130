import { SlackBlock, WorkflowReferenceElement } from '../../../parameter/slack-blocks';

interface DataSource {
  type: 'javascript';
  value?: string;
}

export interface ColumnBase {
  name: string;
}

export interface DataColumn extends ColumnBase {
  type: 'data';
  dataSource: DataSource;
}

export interface WorkflowReferenceColumn extends ColumnBase {
  type: 'workflow-reference';
  name: string;
  workflowReferences: WorkflowReferenceElement[];
}

export type Column = DataColumn | WorkflowReferenceColumn;

export interface TableOutputComponent {
  type: 'table';
  dataSource: DataSource;
  columns: Column[];
  showShareButton: boolean;
  order?: number;
}

interface AxisBase {
  title: string;
}

export type Method =
  | 'sum'
  | 'average'
  | 'count'
  | 'median'
  | 'mode'
  | 'standard-deviation'
  | 'minimum-value'
  | 'maximum-value'
  | 'first-value'
  | 'last-value'
  | 'percentile';

interface AggregatorMethodAxis extends AxisBase {
  type: 'aggregator-method';
  method: Method;
  value?: string;
}

interface JavascriptFunctionAxis extends AxisBase {
  type: 'javascript';
  value: string;
}

export interface BarChart {
  type: 'bar';
  title?: string;
  xAxis: JavascriptFunctionAxis;
  yAxis: AggregatorMethodAxis;
}

export interface PieChart {
  type: 'pie';
  title?: string;
  groupBy: JavascriptFunctionAxis;
  distribution: AggregatorMethodAxis;
}

export interface BarChartOutputComponent {
  type: 'chart';
  dataSource: DataSource;
  chart: BarChart;
  showShareButton: boolean;
}

export interface PieChartOutputComponent {
  type: 'chart';
  dataSource: DataSource;
  chart: PieChart;
  showShareButton: boolean;
}

export type ChartOutputComponent = BarChartOutputComponent | PieChartOutputComponent;

export const isBarChartOutputComponent = (
  chartOutput: ChartOutputComponent
): chartOutput is BarChartOutputComponent => {
  return chartOutput.chart.type === 'bar';
};

export const isPieChartOutputComponent = (
  chartOutput: ChartOutputComponent
): chartOutput is PieChartOutputComponent => {
  return chartOutput.chart.type === 'pie';
};

export interface KeyValueTableRow {
  name: string;
  dataSource: DataSource;
}

export interface KeyValueTableOutputComponent {
  type: 'key-value-table';
  dataSource?: DataSource;
  rows: KeyValueTableRow[];
  showShareButton: boolean;
  order?: number;
}

export interface MarkdownOutputComponent {
  type: 'markdown';
  blocks: SlackBlock[];
}
export interface SlackBlockKitOutputComponent {
  type: 'slack';
  blocks: SlackBlock[];
}

export type OutputComponent =
  | TableOutputComponent
  | ChartOutputComponent
  | KeyValueTableOutputComponent
  | MarkdownOutputComponent
  | SlackBlockKitOutputComponent;
