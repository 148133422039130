import {
  EventRuleIdentifier,
  IntegrationWebhookTrigger,
  useGetIntegration,
  useGetIntegrationEventRules,
  usePersistWorkflow,
  useRollbackWorkflow,
  useSaveToDraftWorkflow,
  Workflow,
  WorkflowWithState,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { userAppGraph } from '../../../../../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/graph-utils/user-app-graph';
import { convertBackendToUi } from '../../../../../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/service/get-nodes';
import { useRefreshCanvas } from '../../../../../../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/use-novaera-flow/use-refresh-canvas';
import { usePropertyPanelContext } from '../../../../../../provider';
import { useGetLatestCapturedEventPayload } from '../../../../../controllers/use-get-latest-captured-event-payload';

export const useTriggerPanelController = ({
  workflow,
  savedWorkflow,
}: {
  workflow: WorkflowWithState;
  savedWorkflow?: Workflow;
}) => {
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { mutate: persistWorkflow } = usePersistWorkflow();

  assert(!!workflow?.trigger, new Error('Trigger should be defined in the workflow'), 'ERROR');
  const trigger = workflow.trigger;
  assert(
    !!(trigger.type === 'integrationWebhook' || trigger.type === 'integrationApp'),
    new Error('Trigger should be of type integrationWebhook or integrationApp'),
    'ERROR'
  );

  const { data: integration } = useGetIntegration({ id: trigger.eventRuleIdentifier.integrationId });
  const { data: integrationEventRules, isLoading: eventRulesLoading } = useGetIntegrationEventRules({
    integrationId: integration?.id,
    version: integration?.latestVersion.number,
  });
  const { getCapturedData, isCapturingPayload } = useGetLatestCapturedEventPayload();
  const { mutate: saveDraftWorkflow } = useSaveToDraftWorkflow();
  const { mutate: rollbackWorkflow, isLoading: isRollbackWorkflowLoading } = useRollbackWorkflow();
  const { refreshCanvas } = useRefreshCanvas(userAppGraph);
  const { emptySelectedNode } = usePropertyPanelContext();

  const eventRulesOptions = useMemo(() => {
    return integrationEventRules?.eventRules?.map((eventRule) => {
      return { label: eventRule.name, value: eventRule.id };
    });
  }, [integrationEventRules?.eventRules]);

  const handleTriggerChange = (eventRuleIdentifier: EventRuleIdentifier) => {
    const shouldPersistWorkflow = !trigger.eventRuleIdentifier.eventRuleId;
    const newTrigger = {
      ...trigger,
      eventRuleIdentifier,
    };

    saveDraftWorkflow(
      { ...workflow, trigger: newTrigger },
      {
        onSuccess: () => {
          if (shouldPersistWorkflow) {
            persistWorkflow({ appId: userAppId, id: workflowId });
          }
        },
      }
    );
  };

  const captureEventWarningMessage = useMemo(() => {
    if (!trigger?.eventRuleIdentifier.eventRuleId) {
      return "You haven't selected an event to capture.";
    } else if (
      (savedWorkflow?.trigger as IntegrationWebhookTrigger)?.eventRuleIdentifier.eventRuleId !==
      (workflow.trigger as IntegrationWebhookTrigger)?.eventRuleIdentifier.eventRuleId
    ) {
      return 'You have changed the event to capture. Please save the workflow to apply the change.';
    }
    return '';
  }, [savedWorkflow?.trigger, trigger?.eventRuleIdentifier.eventRuleId, workflow.trigger]);

  const handleCaptureEventClick = () => {
    getCapturedData();
  };

  const handleDiscardChanges = () => {
    rollbackWorkflow(
      { appId: userAppId, id: workflowId },
      {
        onSettled: (rolledBackWorkflow) => {
          emptySelectedNode();
          convertBackendToUi({ workflow: rolledBackWorkflow });
          refreshCanvas({ keepSelected: false });
        },
      }
    );
  };

  return {
    eventRulesLoading,
    eventRulesOptions,
    integration,
    trigger,
    onTriggerChange: handleTriggerChange,
    captureEventWarningMessage,
    isCaptureEventDisabled: !!captureEventWarningMessage,
    onCaptureEventClick: handleCaptureEventClick,
    isCaptureLoading: isCapturingPayload,
    isRollbackWorkflowLoading,
    handleDiscardChanges,
  };
};
