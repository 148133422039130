import { FC } from 'react';
import { EdgeProps } from 'reactflow';
import { BaseEdge } from '../common/base-edge';

export const EdgeForLoop: FC<React.PropsWithChildren<EdgeProps>> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  ...rest
}) => {
  return (
    <BaseEdge
      sourceX={targetX}
      sourceY={targetY + 2}
      targetX={sourceX - 2}
      targetY={sourceY + 8}
      borderRadius={6}
      topDownOffset={0}
      leftRightOffset={0}
      buttonBehavior="notShown"
      {...rest}
    />
  );
};
