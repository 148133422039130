import {
  isMaxLength,
  isMinLength,
  isRequired,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTextArea,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { FC } from 'react';
import { isDataModelNameValid } from '../../utils';
import { DataModelValue } from './types';
import { useCreateTableModal } from './use-create-table-modal';

export const CreateTableModal: FC<React.PropsWithChildren<{ isOpen: boolean; handleClose: () => void }>> = ({
  isOpen,
  handleClose,
}) => {
  const { handleSubmit, isLoading } = useCreateTableModal({ handleClose });
  return (
    <NvDialogV1 open={isOpen} fullWidth maxWidth="sm">
      <NvForm<DataModelValue> onSubmit={handleSubmit}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Add new table</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex gap="16px">
                <NvTypography>
                  Add a table to define a data structure and create records based on its configuration. After you've
                  created the table, you can customize its properties.
                </NvTypography>
                <NvField
                  name="name"
                  direction="label-on-top"
                  labelVariant="h5"
                  labelText="Name"
                  showErrorMessageOnlyWhenBlur
                  isRequired
                  validators={[
                    isRequired('Table name is required'),
                    isDataModelNameValid(),
                    isMinLength({ length: 2 }),
                    isMaxLength({ length: 50 }),
                  ]}
                  component={<NvTextField placeholder="What is your table name?" />}
                  hasRequiredIndicator
                />
                <NvField
                  name="description"
                  direction="label-on-top"
                  labelVariant="h5"
                  labelText="Description"
                  component={<NvTextArea rows={3} placeholder="What is this table about?" />}
                />
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" disabled={submitting || isLoading} loading={submitting || isLoading}>
                Add
              </NvButton>
              <NvButton color="secondary" onClick={handleClose}>
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={handleClose}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
