import { InputParameter } from '@novaera/actioner-service';
import { NvField } from '@novaera/core';
import { useUIComponents } from '../../../../../components/ui-components/use-ui-component';

export const SimpleField = ({ inputParameter, index }: { inputParameter: InputParameter; index: number }) => {
  const { componentFieldProps, getComponentForField } = useUIComponents({
    inputParameter,
  });

  return (
    <NvField
      {...componentFieldProps}
      formControlStyle={{
        width: 'inherit',
      }}
      direction="label-on-top"
      name={`ssoProvider.parameterValues[${index}]`}
      component={getComponentForField({
        inputParameter: inputParameter,
      })}
    />
  );
};
