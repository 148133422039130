import { NvAccordion, NvAccordionDetails, NvAccordionSummary } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const NvAccordionSummaryWrapper = styled(NvAccordionSummary)`
  flex-direction: row-reverse;
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content {
    margin-left: 8px;
  }

  &.MuiAccordionSummary-root {
    user-select: auto;
  }
`;

export const NvAccordionWrapper = styled(NvAccordion)`
  &.MuiAccordion-root {
    background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: unset;
  }
`;

export const NvAccordionDetailsWrapper = styled(NvAccordionDetails)`
  padding-left: 52px;
  padding-right: 52px;
  padding-bottom: 24px;
`;
