import { NvButton } from '@novaera/core';

export const MissingConnectionFooter = ({
  worksWithFormSubmit,
  onModalClosed,
}: {
  worksWithFormSubmit: boolean;
  onModalClosed: () => void;
}) => {
  return worksWithFormSubmit ? (
    <>
      <NvButton type="submit">Save</NvButton>
      <NvButton color="ghost" onClick={onModalClosed}>
        Cancel
      </NvButton>
    </>
  ) : (
    <>
      <NvButton onClick={onModalClosed}>Done</NvButton>
      <NvButton color="ghost" onClick={onModalClosed}>
        Cancel
      </NvButton>
    </>
  );
};
