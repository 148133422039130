import { RecurringJob, useDeleteJob, useGetRecurringJobs } from '@novaera/actioner-service';
import { NvButton, NvDeleteOutlineIcon, NvTypography, useConfirmDialog } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { matchSorter } from 'match-sorter';
import { useMemo, useState } from 'react';

export const useRecurringJobs = ({
  timezoneId,
  handleEditClick,
}: {
  timezoneId?: string;
  handleEditClick: ({ id, type }: Pick<RecurringJob, 'id' | 'type'>) => void;
}) => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { data, isLoading } = useGetRecurringJobs({ appId: userAppId, timezoneId });
  const filteredJobs = useMemo(
    () => (data?.jobs ? matchSorter(data.jobs, searchKeyword, { keys: ['name'] }) : []),
    [data?.jobs, searchKeyword]
  );
  const { mutate: deleteJob, isLoading: isDeleteJobLoading } = useDeleteJob({ timezoneId });
  const handleDelete = ({ id, name, type }: Pick<RecurringJob, 'id' | 'name' | 'type'>) => {
    openConfirm({
      title: `Delete ${name}?`,
      message: (
        <NvTypography variant="body1">
          <strong>{name}</strong> will no longer be available for this app and will stop running. Deleted jobs cannot be
          recovered.
        </NvTypography>
      ),

      confirmButton: (
        <NvButton
          variant="contained"
          size="medium"
          color="error"
          loading={isDeleteJobLoading}
          disabled={isDeleteJobLoading}
          onClick={async () => {
            await deleteJob({ appId: userAppId, jobId: id, type });
            closeConfirm();
          }}
        >
          Delete
        </NvButton>
      ),
    });
  };
  const menuItems = ({ id, name, type }: RecurringJob) => [
    {
      name: 'Edit',
      onClick: () => {
        handleEditClick({ id, type });
      },
    },
    {
      name: 'divider',
      isDivider: true,
      dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
    },
    {
      name: 'Delete',
      onClick: () => {
        handleDelete({ id, name, type });
      },
      icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
    },
  ];

  return {
    jobs: data?.jobs ?? [],
    filteredJobs,
    isJobsLoading: isLoading,
    userAppId,
    menuItems,
    theme,
    searchKeyword,
    setSearchKeyword,
  };
};
