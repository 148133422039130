import { NvAddIcon, NvBox, NvButton, NvLink, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { StyledContentBox, StyledNvBox } from './styled';
import { EmptyStateProps } from './types';

export const EmptyState: FC<React.PropsWithChildren<EmptyStateProps>> = ({
  onClick,
  actionText,
  subText,
  link,
  disabled = false,
}) => {
  const isSubTextOrLink = subText || link;
  return (
    <StyledNvBox onClick={(e) => !disabled && onClick(e)} disabled={disabled}>
      <NvButton onlyIcon color="info" disabled={disabled}>
        <NvAddIcon fontSize="small"></NvAddIcon>
      </NvButton>
      <StyledContentBox>
        <NvTypography variant="h4">{actionText}</NvTypography>
        {isSubTextOrLink && (
          <NvBox mt={0.5} mb={2}>
            {subText && <NvTypography variant="body2">{subText}</NvTypography>}
            {link && (
              <NvTypography variant="body2" onClick={(e) => e.stopPropagation()}>
                <NvLink href={link.url} target="_blank">
                  {link.label}
                </NvLink>
              </NvTypography>
            )}
          </NvBox>
        )}
      </StyledContentBox>
    </StyledNvBox>
  );
};
