import {
  ConnectionSchema,
  Logo,
  USER_PERMISSION,
  useDeleteConnectionSchemaService,
  useUpdateConnectionSchemaNameService,
  useUpdateConnectionSchemaService,
  useUpdateIntegrationPartial,
} from '@novaera/actioner-service';
import { isAxiosError, useAxiosErrorHandler, useConfirmDialog, useToast } from '@novaera/core';
import { useUserPermissions } from '../../../../../user-permission-boundary/use-user-permission';
import { useGetIntegrationConnectionQueryParams } from '../use-get-integration-connection-query-params';

export const useIntegrationConnectionController = () => {
  const { isLoading: isUpdateConnectionLoading, mutateAsync } = useUpdateConnectionSchemaService();
  const { mutateAsync: deleteConnection } = useDeleteConnectionSchemaService();
  const { integrationId } = useGetIntegrationConnectionQueryParams();
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const { addToast } = useToast();
  const { mutateAsync: updateConnectionName } = useUpdateConnectionSchemaNameService();
  const { mutate: updateIntegrationPartial } = useUpdateIntegrationPartial();
  const { checkPermission } = useUserPermissions();

  const handleUpdateConnection = async (connection: ConnectionSchema) => {
    try {
      await mutateAsync({
        connection,
        integrationId,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else {
        addToast('Something went wrong.', { variant: 'error' });
      }
    }
  };

  const handleUpdateConnectionName = async (connection: ConnectionSchema & { logo?: Logo }) => {
    try {
      await updateConnectionName({
        connectionSchemaId: connection.id,
        integrationId,
        payload: {
          name: connection.name,
          logo: connection?.logo,
        },
      });
    } catch (error) {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else {
        addToast('Something went wrong.', { variant: 'error' });
      }
    }
  };

  const handleDeleteConnection = async (connection: ConnectionSchema) => {
    openConfirm({
      title: `Delete ${connection.name}?`,
      message:
        'You are about to delete this connection schema. Deleting the connection schema will affect all actions and triggers depending on it. Are you sure? ',
      onConfirm: async () => {
        try {
          await deleteConnection({
            connection,
            integrationId,
          });

          updateIntegrationPartial({
            id: integrationId,
            connectionSchemaId: null,
          });
        } catch (error) {
          if (isAxiosError(error)) {
            axiosErrorHandler(error);
          } else {
            addToast('Something went wrong.', { variant: 'error' });
          }
        }
      },
      onClose: () => {
        closeConfirm();
      },
    });
  };

  return {
    onSaveClicked: handleUpdateConnection,
    onModalSubmit: handleUpdateConnectionName,
    isUpdateConnectionLoading,
    onDeleteClicked: handleDeleteConnection,
    hasUpdateIntegrationRight: checkPermission(USER_PERMISSION.INTEGRATION_UPDATE),
  };
};
