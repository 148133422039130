import { Dialog } from '@mui/material';
import { useSlot } from '../../hooks/use-slot';
import { NvFlex } from '../flex';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogActionsV1,
  StyledDialogContent,
  StyledDialogContentV1,
  StyledDialogIconWrapperV1,
  StyledDialogTitle,
  StyledDialogTitleV1,
  StyledDialogV1,
  StyleDialogCloseIconWrapperV1,
  StyleDialogWrapperV1,
} from './styled';

const Slot: React.FC<
  React.PropsWithChildren<{
    name: 'icon' | 'title' | 'content' | 'actions' | 'closeButton';
  }>
> = () => null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withSlot = (Component: any) => {
  Component.Slot = Slot;
  return Component;
};

export const NvDialog = StyledDialog;
export const NvDialogTitle = StyledDialogTitle;
export const NvDialogContent = StyledDialogContent;
export const NvDialogActions = StyledDialogActions;

export const NvDialogV1 = StyledDialogV1;

const NvDialogWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const Icon = useSlot({
    children,
    name: 'icon',
  });

  const Title = useSlot({
    children,
    name: 'title',
  });

  const Content = useSlot({
    children,
    name: 'content',
  });

  const Actions = useSlot({
    children,
    name: 'actions',
  });

  const CloseButton = useSlot({
    children,
    name: 'closeButton',
  });

  return (
    <StyleDialogWrapperV1>
      <StyledDialogIconWrapperV1>{Icon}</StyledDialogIconWrapperV1>
      <NvFlex flex="1 1 auto" maxWidth="calc( 100% - 112px)">
        <StyledDialogTitleV1>{Title}</StyledDialogTitleV1>
        <StyledDialogContentV1>{Content}</StyledDialogContentV1>
        <StyledDialogActionsV1>{Actions}</StyledDialogActionsV1>
      </NvFlex>
      <StyleDialogCloseIconWrapperV1>{CloseButton}</StyleDialogCloseIconWrapperV1>
    </StyleDialogWrapperV1>
  );
};

export const NvDialogSlots = withSlot(NvDialogWrapper);

export const NvPureDialog = Dialog;
