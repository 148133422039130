import { BranchJunctionNode, BranchJunctionNodeSummary, useUpdateNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { cloneDeep } from 'lodash';
import { userAppGraph } from '../../../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../../../provider';

export const useBranches = () => {
  const { mutate: updateNode } = useUpdateNode();
  const { updateNode: updateNodeInGraph, updateOutEdgesNames } = useNovaeraFlow(userAppGraph);
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { userAppId: appId, workflowId } = useParams();

  const handleSave = (values: BranchJunctionNodeSummary, isUpdateBranchJunctionName?: boolean) => {
    updateNode(
      { appId, nodeAlias: values.alias, payload: values as BranchJunctionNode, workflowId },
      {
        onSuccess: () => {
          if (isUpdateBranchJunctionName) {
            const graphNode = userAppGraph.node(values.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = values.name;
            updateNodeInGraph({ newNode: newGraphNode });
          } else {
            updateOutEdgesNames({ nodeAlias: values.alias, newNames: values.branches?.map((b) => b.name) ?? [] });
          }
        },
      }
    );
  };

  return {
    onSave: handleSave,
    deleteNodeAndUpdateGraph,
  };
};
