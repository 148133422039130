import { useFieldArray } from 'react-final-form-arrays';
import { DEFAULT_VALUE } from '../../constants';

export const useRulesController = ({ conditionFieldName }: { conditionFieldName: string }) => {
  const { fields } = useFieldArray(`${conditionFieldName}.rules`);

  const handleAddClicked = () => {
    fields.push(DEFAULT_VALUE[0]);
  };

  const handleRemoveClicked = (index: number) => fields.remove(index);

  return {
    fields,
    onAddClicked: handleAddClicked,
    onRemoveClicked: handleRemoveClicked,
  };
};
