import { NvDialogModal } from '@novaera/core';
import { FC } from 'react';

import { ConnectionSchemaModelBody } from '../../../components/connection-schema-modal';
import { useCreateNewConnection } from './controllers/use-create-new-connection';
import { AddNewConnectionModalHeader } from './header';
import { AddNewConnectionModalProps, IntegrationConnectionFormValues } from './types';

export const AddNewConnectionModal: FC<React.PropsWithChildren<AddNewConnectionModalProps>> = ({ isOpen, onClose }) => {
  const { onFormSubmit, isLoading } = useCreateNewConnection({ onClose });

  return (
    <NvDialogModal<IntegrationConnectionFormValues>
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      modalIcon="add"
      open={isOpen}
      onCloseButtonClicked={onClose}
      onFormSubmit={onFormSubmit}
      Header={<AddNewConnectionModalHeader />}
      Body={<ConnectionSchemaModelBody />}
      isLoading={isLoading}
      primaryButtonText="Add"
    />
  );
};
