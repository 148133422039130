import { DynamicCustomCronJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { CRON_INPUT_FIELDS } from '../../../../../../../../../../../jobs/components/cron-input/constants';
import { DynamicCronField } from '../../dynamic-cron-field';
import { RecurrenceProps } from '../../types';

export const DynamicCustomRecurrence: React.FC<RecurrenceProps<DynamicCustomCronJobRecurrenceConfiguration>> = ({
  recurrenceConfiguration,
  context,
  onChange,
}) => (
  <NvFlex gap="8px" width="100%">
    {CRON_INPUT_FIELDS.map(({ key, label, hint }) => (
      <DynamicCronField
        key={`cron-dynamic-input-${key}`}
        value={recurrenceConfiguration[key]}
        onChange={(v) => {
          onChange({ ...recurrenceConfiguration, [key]: v });
        }}
        context={context}
        label={label}
        hint={hint}
      />
    ))}
  </NvFlex>
);
