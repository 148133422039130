import { IntegrationEventRuleState, useGetIntegrationEventRules } from '@novaera/actioner-service';
import { useMatch, useParams } from '@novaera/route';
import {
  SideMenuSubMenuItem,
  SideMenuSubMenuItemLabel,
  SideMenuSubMenuList,
} from '../../../../components/side-menu/styled';
import { INTEGRATION_EVENT_DETAIL } from '../../../constants';
import { DraftLabel } from '../../draft-label';
import { SideMenuItemsEmptyState } from '../side-menu-items-empty-state';
import { SideMenuListItemsLoading } from '../side-menu-items-loading';

export const SideMenuEventDispatchers = () => {
  const { integrationId } = useParams();
  const integrationEventsRouteMatch = useMatch(`/integrations/:integrationId/triggers/:eventId`);

  const { data, isLoading } = useGetIntegrationEventRules({ integrationId, includeDrafts: true });

  return (
    <SideMenuSubMenuList>
      {isLoading ? (
        <SideMenuListItemsLoading />
      ) : data?.eventRules && data.eventRules.length === 0 ? (
        <SideMenuItemsEmptyState label="This integration doesn’t have any triggers yet." />
      ) : (
        data?.eventRules.map((e) => (
          <SideMenuSubMenuItem
            key={e.id}
            to={INTEGRATION_EVENT_DETAIL(integrationId, e.id)}
            selected={integrationEventsRouteMatch?.params.eventId === e.id}
          >
            <SideMenuSubMenuItemLabel variant="h5">{e.name}</SideMenuSubMenuItemLabel>
            {e.state === IntegrationEventRuleState.DRAFT && <DraftLabel />}
          </SideMenuSubMenuItem>
        ))
      )}
    </SideMenuSubMenuList>
  );
};
