import { Switch, SwitchProps } from '@mui/material';
import classNames from 'classnames';
import { NvConditionalWrap } from '../conditional-wrap';
import { NvFlex } from '../flex';
import { NvTypography } from '../typography';
import { NvTypographyProps } from '../typography/types';
import { SwitchStyledWrapper } from './styled';

export type NvSwitchProps = SwitchProps & {
  variant?: 'default' | 'compact';
  label?: string;
  error?: boolean;
  labelProps?: NvTypographyProps;
};

export function NvSwitch({ variant = 'default', label, error, labelProps, ...props }: NvSwitchProps) {
  return (
    <SwitchStyledWrapper className={classNames({ 'is-compact': variant === 'compact' })}>
      <NvConditionalWrap
        condition={!!label}
        wrap={(children) => (
          <NvFlex flexDirection={'row'} alignItems="center" gap={'8px'}>
            {children}
          </NvFlex>
        )}
      >
        <>
          <Switch {...props} disableRipple />
          {label && (
            <NvTypography textColor="subtle" variant="body2" {...labelProps}>
              {label}
            </NvTypography>
          )}
        </>
      </NvConditionalWrap>
    </SwitchStyledWrapper>
  );
}
