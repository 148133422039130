import { ReactFlowNodeAdapter } from '../user-app-react-flow-node-adapter';

export const APP_WORKFLOW_REAL_NODE_TYPES = {
  workflowCondition: ReactFlowNodeAdapter,
  branchJunction: ReactFlowNodeAdapter,
  loop: ReactFlowNodeAdapter,
  action: ReactFlowNodeAdapter,
  function: ReactFlowNodeAdapter,
  response: ReactFlowNodeAdapter,
  output: ReactFlowNodeAdapter,
  delay: ReactFlowNodeAdapter,
  sendEmail: ReactFlowNodeAdapter,
  workflowDispatcher: ReactFlowNodeAdapter,
  workflowResolver: ReactFlowNodeAdapter,
  aiKnowledge: ReactFlowNodeAdapter,
  assistant: ReactFlowNodeAdapter,
  getConversation: ReactFlowNodeAdapter,
  actionerEventPublisher: ReactFlowNodeAdapter,
  job: ReactFlowNodeAdapter,
  file: ReactFlowNodeAdapter,
  linkGenerator: ReactFlowNodeAdapter,
  http: ReactFlowNodeAdapter,
  assistant_cancel_run: ReactFlowNodeAdapter,
};

export const APP_WORKFLOW_TRIGGER_NODE_TYPES = {
  integrationApp: ReactFlowNodeAdapter,
  integrationWebhook: ReactFlowNodeAdapter,
  genericWebhook: ReactFlowNodeAdapter,
  form: ReactFlowNodeAdapter,
  email: ReactFlowNodeAdapter,
  actionerEvent: ReactFlowNodeAdapter,
};

export const APP_WORKFLOW_HELPER_NODE_TYPES = {
  AddButton: ReactFlowNodeAdapter,
  StartNode: ReactFlowNodeAdapter,
  blank: ReactFlowNodeAdapter,
  DummyNode: ReactFlowNodeAdapter,
};

export const APP_WORKFLOW_NODE_TYPES = {
  ...APP_WORKFLOW_REAL_NODE_TYPES,
  ...APP_WORKFLOW_TRIGGER_NODE_TYPES,
  ...APP_WORKFLOW_HELPER_NODE_TYPES,
};
