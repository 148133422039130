import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

const Base = styled(NvFlex)`
  align-items: center;
  flex-direction: row;
  gap: 4px;
  padding: 2px 4px;
`;

export const StaticWorkflowReferenceRowDetailWrapper = styled(Base)``;

export const DynamicWorkflowReferenceRowDetailWrapper = styled(Base)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[20]};
  color: ${({ theme }) => theme.palette.nv_neutral[300]};
`;
