import { InputParameter, UserApp, WorkflowWithState } from '@novaera/actioner-service';
import { NvButton, NvDivider, NvFlex, NvForm, NvSkeleton } from '@novaera/core';
import { FormApi } from 'final-form';
import { MutableRefObject, useEffect } from 'react';
import { InputFormValues } from '../../action-designer/providers/input-values';
import { SearchAsYouTypeValuesProvider } from '../../action-designer/providers/search-as-you-type-values';
import { MissingConnectionModal } from '../../integrations/components/missing-connection/modal';
import { WorkflowOutputRefRunImmediatelyParams } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/providers/workflow-reference-output-provider/types';
import { RunWorkflowFormTriggerBody } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/body/form-trigger';
import { useWorkflowOutputResult } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/body/result/controllers/use-workflow-output-result';
import { WorkflowOutputs } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/body/result/workflow-outputs';
import { useTriggerOutput } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/workflow-run/controllers/use-trigger-output';
import { useWorkflowRunExecution } from '../controllers/use-workflow-run-execution';
import { WorkflowRunFromLinkHeader } from '../header';
import { WorkflowRunPanelOutputNavigator } from '../output-navigator';

type WorkflowRunFromLinkProps = {
  workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
  currentPage: number;
  isOutputLoading: boolean;
  workflowFromOutput?: WorkflowWithState;
  initialWorkflow: WorkflowWithState;
  userApp: UserApp;
  inputParameters: InputParameter[];
  initialFormValues?: InputFormValues;
  formRef: MutableRefObject<FormApi<InputFormValues, Partial<InputFormValues>> | null>;
  executeImmediately: boolean;
};

export const WorkflowRunFromLinkForm = ({
  workflowOutputRefParams,
  currentPage,
  isOutputLoading,
  workflowFromOutput,
  initialWorkflow,
  userApp,
  inputParameters,
  initialFormValues,
  formRef,
  executeImmediately,
}: WorkflowRunFromLinkProps) => {
  const workflow = workflowFromOutput ?? initialWorkflow;

  const {
    startExecution,
    connectionValidationCheckResult,
    isConnectionMappingModalOpened,
    onConnectionMappingModalClosed,
    onConnectionsCompleted,
    cleanResult,
    isExecuteLoading,
    result,
    executionIdentifier,
  } = useWorkflowRunExecution({ workflow });

  const { setWorkflowStateResult, executedNodeAliases } = useWorkflowOutputResult();

  useEffect(() => {
    result?.status === 'successful' && setWorkflowStateResult(result);
  }, [result, setWorkflowStateResult]);

  useTriggerOutput({
    workflowOutputRefParams,
    workflow,
    onExecuteWorkflow: startExecution,
    initialFormValues,
    cleanResult,
  });

  const shouldFormRunImmediately = executeImmediately && !result && !isOutputLoading;
  const showForm = !result && !isOutputLoading && !shouldFormRunImmediately;
  const showOutput = Boolean(executionIdentifier && executedNodeAliases) && !showForm;
  const isOutputExecutionInProgress = !showForm && !showOutput && result?.status === 'in_progress';

  useEffect(() => {
    if (shouldFormRunImmediately) {
      formRef.current?.submit();
    }
  }, [formRef, shouldFormRunImmediately]);

  return (
    <>
      <NvForm<InputFormValues>
        initialValues={initialFormValues}
        onSubmit={(values) => {
          startExecution(values);
        }}
        myRef={formRef}
        keepDirtyOnReinitialize
      >
        <NvFlex gap={'16px'}>
          <WorkflowRunPanelOutputNavigator currentPage={currentPage} />
          <WorkflowRunFromLinkHeader
            workflowName={workflow.name}
            appName={userApp.name}
            appLogo={userApp.logoUrl ?? userApp.name}
          />
          <SearchAsYouTypeValuesProvider>
            {showForm && (
              <RunWorkflowFormTriggerBody
                workflowId={workflow.id}
                workflowInputParameters={inputParameters}
                userAppId={workflow.appId}
              />
            )}

            {showOutput && executedNodeAliases && executionIdentifier && (
              <WorkflowOutputs
                isWrappedWithCollapse={false}
                workflow={workflow}
                executionIdentifier={executionIdentifier}
                executedNodeAliases={executedNodeAliases}
              />
            )}

            {isOutputExecutionInProgress ||
              (shouldFormRunImmediately && (
                <NvFlex gap={'8px'}>
                  <NvSkeleton variant="rectangular" width={'80%'} />
                  <NvSkeleton variant="rectangular" width={'50%'} />
                  <NvSkeleton variant="rectangular" width={'60%'} />
                </NvFlex>
              ))}
          </SearchAsYouTypeValuesProvider>
          {!result && !shouldFormRunImmediately && (
            <>
              <NvDivider sx={{ width: '100%' }} />
              <NvButton type="submit" color="primary" isFitContent loading={isExecuteLoading}>
                {workflow?.trigger?.type === 'form' ? workflow.trigger.runButtonLabel : 'Run'}
              </NvButton>
            </>
          )}
        </NvFlex>
      </NvForm>
      {workflow.trigger?.type === 'form' && (
        <MissingConnectionModal
          connectionValidationCheckResult={connectionValidationCheckResult}
          onConnectionsCompleted={onConnectionsCompleted}
          isOpened={isConnectionMappingModalOpened}
          onModalClosed={onConnectionMappingModalClosed}
          workflowName={workflow.name}
        />
      )}
    </>
  );
};
