import { Group, useGetPermissionPolicies } from '@novaera/actioner-service';
import { NvAutocomplete, NvField, NvFlex, NvTextField, isMinLength, isRequired, useDebounce } from '@novaera/core';
import { useMemo, useState } from 'react';

export const AttachPermissionPolicyModalBody = ({
  currentPermissionPolicies,
}: {
  currentPermissionPolicies?: Group['permissionPolicies'];
}) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const { data: permissionPolicyQueries } = useGetPermissionPolicies({
    queryParams: { namePrefix: debouncedSearchKeyword },
  });

  const permissionPolicyOptions = useMemo(
    () =>
      permissionPolicyQueries?.pages[0].permissionPolicies.filter(
        (permissionPolicy) => !currentPermissionPolicies?.find((p) => p.id === permissionPolicy.id)
      ) ?? [],
    [currentPermissionPolicies, permissionPolicyQueries?.pages]
  );

  return (
    <NvFlex>
      <NvField
        labelText="Permission policies"
        direction="label-on-top"
        name="permissionPolicies"
        isAutoComplete
        format={(value) => value ?? []}
        parse={(value) => value ?? []}
        hasRequiredIndicator
        showErrorMessageOnlyWhenBlur
        validators={[isRequired(), isMinLength({ length: 1 })]}
        component={
          <NvAutocomplete
            multiple
            options={permissionPolicyOptions}
            size="small"
            getOptionLabel={(option) => (option ? `${option.name}` : '')}
            renderInput={(params) => (
              <NvTextField
                {...params}
                placeholder="You can select multiple permission policy"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                }}
                size="medium"
              />
            )}
            onInputChange={(event, value) => {
              setSearchKeyword(value);
            }}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            filterOptions={(o) => o}
          />
        }
      />
    </NvFlex>
  );
};
