import { InputParameters } from '../input-parameter';
import { IntegrationScope } from '../integration';
import { HTTPRequestConfigurations } from '../request';
import { ResponseConfiguration } from '../response';
import { RunConfiguration } from '../run-configuration';

export enum ActionState {
  DRAFT = 'draft',
  PERSISTED = 'persisted',
}

export type FunctionType = {
  type: 'function';
  id: string;
  name: string;
  script: string;
};

export type FunctionsType = FunctionType[];

export type IntegrationAction = {
  id: string;
  scope: IntegrationScope;
  integrationId: string;
  name: string;
  description: string;
  version: {
    number: number;
    publishedAt: string;
  };
  inputParameters?: InputParameters;
  httpRequestConfigurations?: HTTPRequestConfigurations;
  functions?: FunctionsType;
  enabled: boolean;
  runConfiguration?: RunConfiguration;
  responseConfiguration?: ResponseConfiguration;
  state: ActionState;
  tags?: string[];
};
