import { NvActionFilledIcon, NvDivider, NvFlex, NvImage, NvTypography } from '@novaera/core';
import { IntegrationInitialLogo } from '../../../../../../../../../integrations/components/integration-initial-logo';

export const RowItemContent = ({
  actionName,
  integrationName,
  logoUrl,
  icon,
  showIconNearIntegrationName = true,
}: {
  integrationName?: string;
  logoUrl?: string;
  actionName?: string;
  icon?: React.ReactNode;
  showIconNearIntegrationName?: boolean;
}) => {
  return integrationName ? (
    <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth="0">
      {integrationName && logoUrl && (
        <NvImage
          FallBack={<IntegrationInitialLogo name={integrationName ?? ''} size="small" />}
          src={logoUrl}
          imageShape="square"
          size="smaller"
          className="integration-image"
        />
      )}
      {integrationName && icon && icon}
      <>
        <NvDivider orientation="vertical" sx={{ height: '12px' }} />
        <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth="0">
          {actionName ? (
            <>
              {showIconNearIntegrationName && <NvActionFilledIcon sx={{ width: '16px', height: '16px' }} />}
              <NvTypography variant="h5" textColor="secondary" noWrap>
                {actionName}
              </NvTypography>
            </>
          ) : (
            <NvTypography variant="h6" textColor="ghost" noWrap>
              Select an action
            </NvTypography>
          )}
        </NvFlex>
      </>
    </NvFlex>
  ) : (
    <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth="0">
      <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth="0">
        <NvTypography variant="h6" textColor="ghost" noWrap>
          Select an action
        </NvTypography>
      </NvFlex>
    </NvFlex>
  );
};
