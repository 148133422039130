import { BarChartOutputComponent, OutputNode, useUpdateNode, WorkflowState } from '@novaera/actioner-service';
import {
  CodeInput,
  NvBox,
  NvCandleStickChart,
  NvConditionalRender,
  NvCustomShareIcon,
  NvDatasetIcon,
  NvField,
  NvFlex,
  NvForm,
  NvSelect,
  NvSwitch,
  NvTextField,
  NvTypography,
  NvViewCompactIcon,
} from '@novaera/core';
import { noop } from 'lodash';
import { FC, useCallback } from 'react';
import { PropertyPanelSection, PropertyPanelSimpleSection } from '../../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../../../user-app/user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { OutputOrder } from '../common/output-order';
import { METHOD_OPTIONS } from '../utils/method-options';
import { BarChartOutputComponentForm, BarChartPanelSectionProps } from './types';

export const BarChartPanelSection: FC<React.PropsWithChildren<BarChartPanelSectionProps>> = ({
  barChartOutputComponent,
  alias,
  name,
  userAppId,
  workflowId,
}) => {
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { hasEditPermission } = useWorkflowPermission();

  const handleSave = useCallback(
    (newBarChartOutputComponent: BarChartOutputComponentForm) => {
      const newNode: OutputNode = {
        alias,
        name,
        // this is because the wrong typing in update Node detail function. it actually can take values with optional fields but it looks it is not.
        outputComponent: newBarChartOutputComponent as BarChartOutputComponent,
        type: 'output',
        state: WorkflowState.DRAFT,
      };

      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: alias,
        payload: newNode,
      });
    },
    [alias, name, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvForm<BarChartOutputComponentForm>
      onSubmit={noop}
      onChange={({ values }) => {
        const { chart, dataSource, ...otherValues } = values;
        const newBarChartOutputComponent: BarChartOutputComponentForm = {
          chart: {
            title: chart?.title,
            type: 'bar',
            xAxis: { type: 'javascript', value: chart?.xAxis?.value, title: chart?.xAxis?.title },
            yAxis: {
              type: 'aggregator-method',
              method: chart?.yAxis?.method,
              value: chart?.yAxis?.value,
              title: chart?.yAxis?.title,
            },
          },
          dataSource: { type: 'javascript', value: dataSource?.value },
          ...otherValues,
        };

        handleSave(newBarChartOutputComponent);
      }}
      initialValues={barChartOutputComponent}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <PropertyPanelSection icon={<NvCandleStickChart />} title="Data">
        <NvFlex gap="8px" direction="column">
          <NvField<BarChartOutputComponent['dataSource']['value']>
            name="dataSource.value"
            labelText="Chart source"
            labelVariant="h6"
            component={<CodeInput context={workflowCodeInputContext} />}
            defaultValue="{{ }}"
          />
          <NvField<BarChartOutputComponent['chart']['xAxis']['value']>
            name="chart.xAxis.value"
            labelText="X-axis values"
            labelVariant="h6"
            component={<CodeInput context={workflowCodeInputContext} />}
            defaultValue="{{ }}"
          />
        </NvFlex>
      </PropertyPanelSection>
      <PropertyPanelSection icon={<NvDatasetIcon />} title="Dataset">
        <NvFlex gap="8px" direction="column">
          <NvField<BarChartOutputComponent['chart']['yAxis']['value']>
            name="chart.yAxis.value"
            labelText="Source"
            labelVariant="h6"
            component={<CodeInput context={workflowCodeInputContext} />}
          />
          <NvField<BarChartOutputComponent['chart']['yAxis']['method']>
            name="chart.yAxis.method"
            labelText="Method"
            labelVariant="h6"
            defaultValue="sum"
            component={<NvSelect options={METHOD_OPTIONS} sx={{ width: '200px' }} />}
          />
        </NvFlex>
      </PropertyPanelSection>
      <PropertyPanelSection icon={<NvViewCompactIcon />} title="Layout">
        <NvFlex gap="8px" direction="column">
          <NvField<BarChartOutputComponent['chart']['title']>
            name="chart.title"
            labelText="Chart title"
            labelVariant="h6"
            component={<NvTextField />}
          />
          <NvField<BarChartOutputComponent['chart']['xAxis']['title']>
            name="chart.xAxis.title"
            labelText="X-axis title"
            labelVariant="h6"
            component={<NvTextField />}
          />
          <NvField<BarChartOutputComponent['chart']['yAxis']['title']>
            name="chart.yAxis.title"
            labelText="Y-axis title"
            labelVariant="h6"
            component={<NvTextField />}
          />
        </NvFlex>
      </PropertyPanelSection>
      <NvConditionalRender when={hasEditPermission}>
        <OutputOrder />
      </NvConditionalRender>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelSimpleSection>
          <NvBox width={'100%'}>
            <NvField
              formControlStyle={{ width: '100%' }}
              name="showShareButton"
              type="checkbox"
              component={({ onChange, checked, value }) => {
                return (
                  <NvFlex flexDirection={'row'} alignItems={'center'}>
                    <NvFlex direction={'row'} gap={'8px'} flex={'1 1 auto'} minWidth={'0'} alignItems={'center'}>
                      <NvCustomShareIcon />
                      <NvTypography variant="body2">Show share button</NvTypography>
                    </NvFlex>
                    <NvSwitch value={value} onChange={onChange} checked={checked} variant="compact" />
                  </NvFlex>
                );
              }}
            />
          </NvBox>
        </PropertyPanelSimpleSection>
      </NvConditionalRender>
    </NvForm>
  );
};
