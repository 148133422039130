import { ConnectionSchema } from '@novaera/actioner-service';
import { Markdown, NvConditionalRender, NvDivider, NvFlex, NvImage, NvSkeleton, NvTypography } from '@novaera/core';
import { LogoPlaceholder } from '../../../../components/logo-placeholder';
import { Body, ConnectionInformationWrapper } from './styled';

export const ConnectionInformation = ({
  connectionSchema,
  isLoading,
}: {
  connectionSchema?: ConnectionSchema;
  isLoading: boolean;
}) => {
  return (
    <ConnectionInformationWrapper
      title={
        <NvFlex columnGap={'16px'} flexDirection="row" alignItems={'center'}>
          <NvFlex columnGap={'8px'} flexDirection="row" alignItems={'center'}>
            <NvConditionalRender when={() => !isLoading}>
              <NvImage
                FallBack={
                  <LogoPlaceholder
                    size={'medium'}
                    src="assets/connection-placeholder-image.png"
                    initialText="Connection Schema"
                  />
                }
                src={connectionSchema?.logoUrl}
                imageShape="square"
              />
              <NvTypography variant="h5">{connectionSchema?.name}</NvTypography>
            </NvConditionalRender>
            <NvConditionalRender when={() => isLoading}>
              <NvSkeleton width={'24px'} />
              <NvSkeleton width={'60px'} />
            </NvConditionalRender>
          </NvFlex>
          <NvDivider
            orientation="vertical"
            sx={{
              height: '12px',
            }}
          />
          <NvFlex columnGap={'16px'} flexDirection="row" alignItems={'center'}>
            <NvConditionalRender when={() => !isLoading}>
              <NvTypography variant="c3" textColor="subtle">
                {connectionSchema?.authentication?.baseUrl}
              </NvTypography>
            </NvConditionalRender>
            <NvConditionalRender when={() => isLoading}>
              <NvSkeleton width={'120px'} />
            </NvConditionalRender>
          </NvFlex>
        </NvFlex>
      }
    >
      <Body>
        <NvConditionalRender when={() => !isLoading}>
          {connectionSchema?.description?.value && <Markdown>{connectionSchema?.description?.value}</Markdown>}
        </NvConditionalRender>
        <NvConditionalRender when={() => isLoading}>
          <NvFlex style={{ width: '100%' }}>
            <NvSkeleton width={'290px'} height="24px" />
            <NvSkeleton width={'250px'} height="24px" />
          </NvFlex>
        </NvConditionalRender>
      </Body>
    </ConnectionInformationWrapper>
  );
};
