import {
  AccessType,
  useCreateUserAppAccessSettings,
  useGetGroups,
  useGetUsersInWorkspace,
} from '@novaera/actioner-service';
import {
  NvAddIcon,
  NvArrowForwardIcon,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTypography,
  SectionMessage,
  isRequired,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import arrayMutators from 'final-form-arrays';
import { PropsWithChildren, useMemo } from 'react';
import { ROUTES } from '../../../../common/routes';
import { UserAndGroupSearchAutoComplete } from '../../../../components/user-and-group-search-component';
import { USER_MANAGEMENT_TAB_PATHS } from '../../../../workspace/user-management/constants';
import {
  AddUserAppAccessUserModalFormValues,
  AddUserAppAccessUserModalProps,
  UserAppAccessUsersToCreate,
} from './types';

export const AddUserAppAccessUserModal: React.FC<PropsWithChildren<AddUserAppAccessUserModalProps>> = ({
  isOpen,
  onClose,
  existingUsers,
}) => {
  const { userAppId } = useParams();
  const { data } = useGetUsersInWorkspace({ refetchOnWindowFocus: true, staleTime: 0 });
  const { data: groupData } = useGetGroups({ refetchOnWindowFocus: true, staleTime: 0 });

  const userList: UserAppAccessUsersToCreate[] = useMemo(() => {
    const mappedUsers = data?.users
      ? data?.users
          .map((user) => ({
            name: user.userName,
            logoUrl: user.logoUrl,
            principalId: user.userId,
            principalType: AccessType.USER,
            editGranted: false,
          }))
          .filter(
            (user) =>
              !existingUsers.find(
                (existingUser) =>
                  existingUser.principalType === AccessType.USER && existingUser.principalId === user.principalId
              )
          )
      : [];
    const mappedGroups = groupData?.groups
      ? groupData.groups
          .map((group) => ({
            name: group.name,
            editGranted: false,
            principalType: AccessType.GROUP,
            logoUrl: group.logoUrl,
            principalId: group.id,
          }))
          .filter(
            (group) =>
              !existingUsers.find(
                (existingUser) =>
                  existingUser.principalType === AccessType.GROUP && existingUser.principalId === group.principalId
              )
          )
      : [];

    const result: UserAppAccessUsersToCreate[] = [...mappedUsers, ...mappedGroups];
    return result;
  }, [data?.users, existingUsers, groupData?.groups]);

  const { mutate: createUserAppAccessSettings } = useCreateUserAppAccessSettings();
  const handleSubmit = (values: AddUserAppAccessUserModalFormValues) => {
    const users = values.users.map((user) => {
      const { name, logoUrl, ...rest } = user;
      return { ...rest };
    });

    return new Promise<void>((resolve) => {
      createUserAppAccessSettings(
        { appId: userAppId, accessSettings: users },
        {
          onSuccess: () => {
            onClose();
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <NvForm<AddUserAppAccessUserModalFormValues>
        onSubmit={handleSubmit}
        initialValues={{ users: [] }}
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
      >
        {({ submitting, errors }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Add permissions</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton onlyIcon size="small" color="secondary" onClick={onClose} disabled={Boolean(submitting)}>
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex spacing="16px">
                <NvTypography variant="body1">
                  The users selected below will have the ability to access and run the workflows that are available in
                  this app.
                </NvTypography>

                <NvBox flex="1 1 auto" minWidth="0" width="100%">
                  <NvField
                    name="users"
                    labelText="Select users and groups"
                    labelVariant="h5"
                    direction="label-on-top"
                    isAutoComplete
                    validators={[isRequired()]}
                    showErrorMessageOnlyWhenBlur
                    hasRequiredIndicator
                    component={
                      <UserAndGroupSearchAutoComplete<UserAppAccessUsersToCreate, true>
                        options={userList}
                        multiple
                        getOptionPrincipal={(option) =>
                          option.principalType === AccessType.GROUP
                            ? { id: option.principalId, type: option.principalType }
                            : { type: option.principalType }
                        }
                      />
                    }
                  />
                </NvBox>
                <SectionMessage
                  message={
                    <NvTypography variant="body2" textColor="secondary">
                      Invite users to your Actioner workspace via the <b>user management</b> page, then you can add them
                      in your app.
                    </NvTypography>
                  }
                  variant={'info'}
                  actionButton={
                    <NvButton
                      size="small"
                      color="secondary"
                      endIcon={<NvArrowForwardIcon />}
                      onClick={() => {
                        window.open(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.USER}`, '_blank');
                      }}
                    >
                      User management
                    </NvButton>
                  }
                ></SectionMessage>
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" loading={submitting} disabled={Boolean(submitting || errors?.users)}>
                Add
              </NvButton>
              <NvButton color="secondary" onClick={onClose} disabled={Boolean(submitting)}>
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
