import { DropResult } from '@hello-pangea/dnd';
import { DataColumn } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { ChangeEventHandler, useCallback } from 'react';
import { move } from '../../../../../../../../../../../../utils';

export const useDataColumns = ({
  onChange,
  dataColumns,
}: {
  dataColumns?: DataColumn[];
  onChange?: (value?: DataColumn[]) => void;
}) => {
  const sendChange = useCallback(
    (value?: DataColumn[]) => {
      if (value) {
        onChange?.(value);
      } else {
        onChange?.();
      }
    },
    [onChange]
  );

  const handleColumnNameChange =
    (columnIndex: number): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined =>
    (event) => {
      const updatedDataColumns = dataColumns?.map((column, index) => {
        if (index === columnIndex) {
          return {
            ...column,
            name: event.target.value,
          };
        } else {
          return column;
        }
      });
      sendChange(updatedDataColumns);
    };

  const handleDataSourceValueChange =
    (columnIndex: number): ((value: string) => void) | undefined =>
    (value) => {
      const updatedDataColumns = dataColumns?.map((column, index) => {
        if (index === columnIndex) {
          return {
            ...column,
            dataSource: { ...column.dataSource, value },
          };
        } else {
          return column;
        }
      });
      sendChange(updatedDataColumns);
    };

  const handleNewColumnAdded = () => {
    const identity = dataColumns?.length ?? 0;
    sendChange([
      ...(dataColumns ?? []),
      {
        name: `New column ${identity}`,
        type: 'data',
        dataSource: { type: 'javascript', value: '{{ currentItem.fieldName }}' },
      },
    ]);
  };

  const handleColumnRemoved = (index: number) => {
    const removedDataColumns = dataColumns?.filter((_, i) => i !== index);
    sendChange(removedDataColumns);
  };

  const handleDragEnd = (result: DropResult) => {
    assert(!!dataColumns, new Error('Data columns must be defined'), 'ERROR');
    if (!result.destination) {
      return;
    }
    const newDataColumns = move(dataColumns, result.source.index, result.destination.index);
    sendChange(newDataColumns);
  };

  return {
    handleColumnNameChange,
    handleDataSourceValueChange,
    handleNewColumnAdded,
    handleColumnRemoved,
    handleDragEnd,
  };
};
