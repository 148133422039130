import { CodeInput, NvConditionalWrap, NvField, NvFlex } from '@novaera/core';
import { assert } from '@novaera/utils';
import React from 'react';
import { Rules } from '../rules';
import { SectionRuleType } from './types';

export const MatchEventSectionRules = React.forwardRef<HTMLDivElement, SectionRuleType>(
  (
    {
      conditionType,
      codeInputContext,
      conditionFieldName,
      layout = 'horizontal',
      JavascriptWrapperComponent,
      RulesWrapperComponent,
      showAddButton = true,
      showRemoveButton = true,
      valueTypeOptions,
    },
    ref
  ) => {
    return conditionType && conditionType !== 'no-condition' ? (
      conditionType === 'javascript' ? (
        <NvConditionalWrap
          condition={!!JavascriptWrapperComponent}
          wrap={(children) => {
            assert(
              !!JavascriptWrapperComponent,
              new Error('JavascriptWrapperComponent component should exist when it is wrapping'),
              'ERROR'
            );
            return <JavascriptWrapperComponent children={children} />;
          }}
        >
          <NvFlex gap="6px" width="100%" height="100%">
            <NvField
              name={`${conditionFieldName}.function`}
              component={<CodeInput context={codeInputContext} mode="free-js" />}
            />
          </NvFlex>
        </NvConditionalWrap>
      ) : (
        <Rules
          ref={ref}
          conditionFieldName={conditionFieldName}
          codeInputContext={codeInputContext}
          layout={layout}
          WrapperComponent={RulesWrapperComponent}
          showAddButton={showAddButton}
          showRemoveButton={showRemoveButton}
          valueTypeOptions={valueTypeOptions}
        />
      )
    ) : null;
  }
);
