import { ROUTES } from '../common/routes';

export const INTEGRATION_PATHS = {
  SUMMARY: '',
  CONNECTION: 'connection',
  TRIGGERS: 'triggers',
  ACTIONS: 'actions',
  ACTIONS_DETAIL: 'actions/:actionId',
  EVENT_DETAIL: 'triggers/:eventId',
};

export const ACTION_DETAIL_VIEW_MODES = {
  designer: { label: 'Designer', value: 'designer', path: '/designer' },
  history: { label: 'Run history', value: 'history', path: '/history' },
};

export const INTEGRATION_LIST = `/workspace${ROUTES.Integrations}`;
export const INTEGRATIONS = ROUTES.Integrations;
export const INTEGRATION_SUMMARY = (integrationId: string) => `${INTEGRATIONS}/${integrationId}`;
export const INTEGRATION_CONNECTION = (integrationId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.CONNECTION}`;
export const INTEGRATION_EVENTS = (integrationId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.TRIGGERS}`;
export const INTEGRATION_ACTIONS = (integrationId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.ACTIONS}`;

export const INTEGRATION_ACTION_DETAIL = (integrationId: string, actionId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.ACTIONS}/${actionId}`;

export const INTEGRATION_ACTION_DESIGNER = (integrationId: string, actionId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.ACTIONS}/${
    actionId + ACTION_DETAIL_VIEW_MODES.designer.path
  }`;

export const INTEGRATION_ACTION_HISTORY = (integrationId: string, actionId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.ACTIONS}/${
    actionId + ACTION_DETAIL_VIEW_MODES.history.path
  }`;

export const INTEGRATION_EVENT_DETAIL = (integrationId: string, eventId: string) =>
  `${INTEGRATION_SUMMARY(integrationId)}/${INTEGRATION_PATHS.TRIGGERS}/${eventId}`;
