import { NvAxios, useQuery } from '@novaera/core';
import { DOCUMENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_DOCS } from '../keys';
import { AppDocument } from '../types';
import { UseGetDocParams } from './types';

const getDocument = async ({ appId, docId }: UseGetDocParams) => {
  const result = await NvAxios.get<AppDocument>(`${DOCUMENTS_ROOT_PATH(appId)}/${docId}`);
  return result?.data;
};

export const useGetDocument = (params: UseGetDocParams) => {
  return useQuery<AppDocument>(QUERY_KEYS_DOCS.detail(params.appId, params.docId ?? ''), () => getDocument(params), {
    enabled: !!params.docId,
  });
};
