import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvCustomCheckIcon,
  NvCustomCrossIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvReceiptLongIcon,
  NvSelect,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { usePropertyPanelContext } from '../../../provider';
import { RowItemCard } from '../../common/row-item-card';
import { SimpleLabelLayout } from '../../common/row-item-card/row-item-layouts';
import { useResponsePropertyPanel } from '../controllers/use-response-property-panel';
import { ResponsePropertyPanelProps } from './types';

export const ResponsePropertyPanel = ({
  onCloseClicked,
  responseNode,
  onRowItemClicked,
}: ResponsePropertyPanelProps) => {
  const theme = useTheme();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const { hasEditPermission, onSaveTitle } = useResponsePropertyPanel();

  return (
    <>
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvReceiptLongIcon />}
          title={responseNode.name}
          type={NodeType.RESPONSE}
          onTitleChange={async (title) => {
            if (title) {
              onSaveTitle(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <>
              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'small', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      deleteNodeAndUpdateGraph({ nodeId: responseNode.alias });
                    },
                  },
                ]}
              />

              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          icon={<NvReceiptLongIcon />}
          title={responseNode.name}
          type={NodeType.RESPONSE}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>

      <PropertyPanelSection
        title="Status"
        tooltip="Select to specify the configured response as a success or failure upon completion of the workflow run."
      >
        <NvField
          name="executionResult"
          component={
            <NvSelect
              sx={{ width: '146px' }}
              options={[
                {
                  label: 'Successful',
                  value: 'success',
                  startIcon: <NvCustomCheckIcon htmlColor={theme.palette.nv_success[60]} />,
                },
                {
                  label: 'Failed',
                  value: 'failure',
                  startIcon: <NvCustomCrossIcon htmlColor={theme.palette.nv_error[40]} />,
                },
              ]}
            />
          }
        />
      </PropertyPanelSection>
      <PropertyPanelSimpleSection>
        <RowItemCard
          rowItemLeftContentProps={{ draggable: false }}
          rowItemContent={<SimpleLabelLayout simpleLabel="Sample response" />}
          onClick={() => {
            onRowItemClicked();
          }}
        />
      </PropertyPanelSimpleSection>
      <PropertyPanelSection
        title="Response"
        tooltip="The workflow response can be constructed using other node responses."
      >
        <NvField
          name="responseTemplate"
          formControlStyle={{ width: '100%' }}
          component={
            <CodeInput growingHeight context={workflowCodeInputContext} initialHeight="178px" placeholder="{{ ... }}" />
          }
          hint={
            <NvTypography variant="body3" textColor="subtle">
              JSON is supported.
            </NvTypography>
          }
        />
      </PropertyPanelSection>
    </>
  );
};
