import { NvAxios, useQuery } from '@novaera/core';
import { ACTIONER_EVENTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ACTIONER_EVENTS } from '../keys';
import { ActionerEvent } from '../types';
import { ListActionerEventsParams } from './types';

const listActionerEvents = async ({ appId }: ListActionerEventsParams) => {
  const result = await NvAxios.get<ActionerEvent[]>(`${ACTIONER_EVENTS_ROOT_PATH(appId)}`);
  return result?.data;
};

export const useListActionerEvents = (params: ListActionerEventsParams) => {
  const { appId } = params;
  return useQuery<ActionerEvent[]>(
    QUERY_KEYS_ACTIONER_EVENTS.list(appId),
    () =>
      listActionerEvents({
        appId,
      }),
    {
      enabled: !!appId,
    }
  );
};
