import { parse } from 'query-string';
import { useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  function parseSearchParams<ReturnType>(): ReturnType | undefined {
    const result = parse(search);

    if (Object.keys(result).length === 0) {
      return undefined;
    }

    return result as unknown as ReturnType;
  }

  const handleRemoveQueryParams = useCallback(
    (queryParam: string | string[]) => {
      const params = !Array.isArray(queryParam) ? [queryParam] : queryParam;
      params.forEach((param) => {
        if (searchParams.has(param)) {
          searchParams.delete(param);
          setSearchParams(searchParams.toString());
        }
      });
    },
    [searchParams, setSearchParams]
  );

  const handleAddQueryParam = useCallback(
    (name: string, value: string) => {
      if (searchParams.has(name)) {
        searchParams.set(name, value);
      } else {
        searchParams.append(name, value);
      }

      setSearchParams(searchParams.toString());
    },
    [searchParams, setSearchParams]
  );

  return {
    getSearchParams: parseSearchParams,
    removeQueryParams: handleRemoveQueryParams,
    addQueryParam: handleAddQueryParam,
  };
};
