import { SlackAppInstallationTrigger } from '@novaera/actioner-service';

export const getSlackAppRedirectUri = (packageId: string) =>
  `${window.location.origin}/my-apps/${packageId}/slack/apps/access`;

export const getSlackAppAuthUrl = ({
  slackAuthorizationUrl,
  triggerId,
  installTrigger,
  slackClientId,
  packageId,
}: {
  slackAuthorizationUrl: string;
  triggerId: string;
  installTrigger: SlackAppInstallationTrigger;
  slackClientId: string;
  packageId: string;
}) =>
  `${slackAuthorizationUrl}&state=${triggerId}_${installTrigger}_${slackClientId}&redirect_uri=${getSlackAppRedirectUri(
    packageId
  )}`;
