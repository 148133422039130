import { NvDivider, NvFlex, NvSwitch, NvTextField, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { LinkConfigurationWrapper } from '../styled';

interface LinkConfigurationProps {
  value?: string;
  onChange?: (value?: string) => void;
  resetText: string;
  label: string;
}

export const LinkConfiguration: FC<LinkConfigurationProps> = ({ value, onChange, resetText, label }) => {
  return (
    <LinkConfigurationWrapper>
      <NvFlex alignItems="center" gap="8px" direction="row" margin="6px 0 6px 0">
        <NvSwitch
          checked={!!value}
          onChange={(_event, checked) => {
            if (!checked) {
              onChange?.(undefined);
            } else {
              onChange?.(resetText);
            }
          }}
          variant="compact"
        />
        <NvTypography variant="h5" flex="1 1 auto" noWrap>
          {label}
        </NvTypography>
      </NvFlex>
      {!!value && (
        <>
          <NvDivider orientation="vertical" sx={{ height: '16px' }} />
          <NvFlex direction="row" alignItems="center" flex="1 1 auto" gap="8px">
            <NvTypography variant="body2" noWrap flex="0 0 auto">
              Link label
            </NvTypography>
            <NvTextField value={value} onChange={(event) => onChange?.(event.target.value)} size="small" fullWidth />
          </NvFlex>
        </>
      )}
    </LinkConfigurationWrapper>
  );
};
