import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { SubTabs } from '../../../../../../../components/sub-tabs';

export const StyledSubTabs = styled(SubTabs)`
  .tab-header-container {
    position: inherit;
    top: auto;
    z-index: 0;
  }
  .MuiTabs-root {
    border-radius: 0;
    box-shadow: none;

    .MuiButtonBase-root {
      height: 24px;
      min-height: 24px;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 12px;

      .MuiSvgIcon-root {
        margin-left: 0;
      }
    }
  }
  .sub-tabs-body-wrapper {
    justify-content: flex-start;
    padding-top: 0;
  }
`;

export const SubTabsSelectActionBox = styled(NvBox)`
  width: inherit;
  border-left: 1px solid ${({ theme: { palette } }) => palette.nv_neutral_alpha[40]};
  padding-left: 8px;
  display: flex;
  height: 24px;
  align-items: center;
`;
