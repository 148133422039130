import CodeMirror from 'codemirror';
import { ExpressionState, FunctionModeState, FunctionParsedConfig } from './types';

type GetInitialStateForMode<T> = T extends 'function'
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (config: any, parsedConfig: FunctionParsedConfig) => FunctionModeState
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (config: any) => ExpressionState;

export const getInitialStateForExpression: GetInitialStateForMode<'expression'> = (config) => {
  const jsMode = CodeMirror.getMode(config, 'javascript');

  const initialState: ExpressionState = {
    jsMode,
    jsState: jsMode.startState?.(),
    novaera_expression: false,
  };

  return initialState;
};

export const getInitialStateForFunction: GetInitialStateForMode<'function'> = (config, parsedConfig) => {
  const jsMode = CodeMirror.getMode(config, 'javascript');

  const firstLevelPathItems = Object.keys(parsedConfig.novaeraContext);

  const initialState: FunctionModeState = {
    jsMode,
    jsState: jsMode.startState?.(),
    firstLevelPathItems,
    isInContext: false,
  };

  return initialState;
};
