import { NvAxios, useMutation } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useQueryClient } from '@tanstack/react-query';
import { ActionState, IntegrationAction } from '../../types';
import { INTEGRATION_ACTION_REQUEST_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { CreateIntegrationActionResponse, GetIntegrationActionResponse, UpdateActionRequestsParams } from '../types';
import { replaceActionInCache } from '../utils/replace-action-parts-in-cache';

const updateHttpRequestConfigurations = async (params: UpdateActionRequestsParams) => {
  const {
    integrationId,
    actionId,
    payload: { httpRequestConfigurations },
  } = params;
  const result = await NvAxios.post<CreateIntegrationActionResponse>(
    INTEGRATION_ACTION_REQUEST_PATH(integrationId, actionId),
    httpRequestConfigurations
  );
  return result?.data;
};

export const useUpdateHttpRequestConfigurations = () => {
  const cache = useQueryClient();

  return useMutation(updateHttpRequestConfigurations, {
    onMutate: ({ integrationId, actionId, version, payload: { httpRequestConfigurations } }) => {
      const oldIntegrationAction = cache.getQueryData<GetIntegrationActionResponse>(
        QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version)
      );

      assert(
        !!oldIntegrationAction,
        new Error('Integration action should be found in the cache before optimistic update'),
        'ERROR'
      );

      const actionStateToBeUpdated = oldIntegrationAction?.draft
        ? oldIntegrationAction.draft
        : { ...oldIntegrationAction?.persisted, state: ActionState.DRAFT };

      const newIntegrationActionAsDraft: IntegrationAction = {
        ...actionStateToBeUpdated,
        httpRequestConfigurations,
      };
      const returnContext = replaceActionInCache({
        cache,
        integrationId,
        actionId,
        newIntegrationActionAsDraft,
        version,
      });
      return returnContext;
    },
    onError: (_error, { integrationId, actionId, version }, context) => {
      if (context) {
        const { oldIntegrationActionList, oldIntegrationActionDetail } = context;
        cache.setQueryData(QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId, version), oldIntegrationActionList);
        cache.setQueryData(
          QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version),
          oldIntegrationActionDetail
        );
      }
    },
  });
};
