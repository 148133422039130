import { NvChip, NvCustomEmptyIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

export const CatalogDetailPanelItem = ({ label, value }: { label: string; value?: string }) => {
  const theme = useTheme();
  return (
    <NvFlex gap={'6px'} flexDirection={'column'}>
      <NvTypography variant="h5">{label}:</NvTypography>
      {value ? (
        <NvChip
          sx={{ width: 'fit-content', padding: '1px 6px', border: `1px solid ${theme.palette.nv_neutral_alpha[10]}` }}
          label={value}
        />
      ) : (
        <NvFlex direction="row" gap="4px" alignItems="center">
          <NvCustomEmptyIcon
            sx={{ width: '16px', height: '16px' }}
            htmlColor={theme.palette.nv_neutral[60]}
          ></NvCustomEmptyIcon>
          <NvTypography variant="body2" textColor="ghost">
            Data not provided
          </NvTypography>
        </NvFlex>
      )}
    </NvFlex>
  );
};
