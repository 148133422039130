import { NvBox, NvFlex } from '@novaera/core';
import { FC } from 'react';

export const SummaryLayout: FC<
  React.PropsWithChildren<{
    headerLeft: JSX.Element;
    headerRight: JSX.Element;
    description: JSX.Element;
    infoBox: JSX.Element;
  }>
> = ({ headerLeft, headerRight, description, infoBox }) => {
  return (
    <>
      <NvFlex direction="row" padding={0} flex="1 1 auto" alignItems="flex-start" gap="16px">
        <NvBox
          display="flex"
          flex="1 1 auto"
          minWidth={0}
          flexDirection="row"
          padding={0}
          gap="12px"
          alignItems="center"
        >
          {headerLeft}
        </NvBox>
        {headerRight}
      </NvFlex>
      <NvFlex gap="24px" flex="1 1 auto">
        {infoBox}
        <NvFlex>{description}</NvFlex>
      </NvFlex>
    </>
  );
};
