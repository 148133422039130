import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomCardGiftIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_138_4538)">
        <path
          d="M26.6667 8.00002H23.76C23.9067 7.58669 24 7.13335 24 6.66669C24 4.45335 22.2134 2.66669 20 2.66669C18.6 2.66669 17.3867 3.38669 16.6667 4.46669L16 5.36002L15.3334 4.45335C14.6134 3.38669 13.4 2.66669 12 2.66669C9.78669 2.66669 8.00002 4.45335 8.00002 6.66669C8.00002 7.13335 8.09335 7.58669 8.24002 8.00002H5.33335C3.85335 8.00002 2.68002 9.18669 2.68002 10.6667L2.66669 25.3334C2.66669 26.8134 3.85335 28 5.33335 28H26.6667C28.1467 28 29.3334 26.8134 29.3334 25.3334V10.6667C29.3334 9.18669 28.1467 8.00002 26.6667 8.00002ZM20 5.33335C20.7334 5.33335 21.3334 5.93335 21.3334 6.66669C21.3334 7.40002 20.7334 8.00002 20 8.00002C19.2667 8.00002 18.6667 7.40002 18.6667 6.66669C18.6667 5.93335 19.2667 5.33335 20 5.33335ZM12 5.33335C12.7334 5.33335 13.3334 5.93335 13.3334 6.66669C13.3334 7.40002 12.7334 8.00002 12 8.00002C11.2667 8.00002 10.6667 7.40002 10.6667 6.66669C10.6667 5.93335 11.2667 5.33335 12 5.33335ZM26.6667 25.3334H5.33335V22.6667H26.6667V25.3334ZM26.6667 18.6667H5.33335V12C5.33335 11.2667 5.93335 10.6667 6.66669 10.6667H12.1067L10.1334 13.36C9.69335 13.96 9.82669 14.8 10.4267 15.2267C11.0134 15.6534 11.8534 15.52 12.28 14.9334L16 9.86669L19.72 14.9334C20.1467 15.52 20.9867 15.6534 21.5734 15.2267C22.1734 14.8 22.3067 13.96 21.8667 13.36L19.8934 10.6667H25.3334C26.0667 10.6667 26.6667 11.2667 26.6667 12V18.6667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_138_4538">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
