import { NvAxios, useQuery } from '@novaera/core';
import { CONFIG_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIG_SCHEMA } from '../keys';
import { ListConfigSchemaParams, ListConfigSchemaResponse } from './types';

const listConfigSchemas = async ({ appId }: ListConfigSchemaParams) => {
  const result = await NvAxios.get<ListConfigSchemaResponse>(`${CONFIG_SCHEMA_ROOT_PATH(appId)}`);
  return result?.data;
};

export const useListConfigSchemas = (params: ListConfigSchemaParams) => {
  return useQuery<ListConfigSchemaResponse>(QUERY_KEYS_CONFIG_SCHEMA.list(params.appId), () =>
    listConfigSchemas(params)
  );
};
