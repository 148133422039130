import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { Integration } from '../../types';
import { QUERY_KEYS_INTEGRATION } from '../keys';
import { SearchIntegrationsResponse, UpdateIntegrationPartialResponse } from '../types';

type UpdateCacheProp = { id: string; method: 'remove' } | { integration: Integration; method: 'update' | 'add' };

export const useUpdateIntegrationCache = () => {
  const cache = useQueryClient();

  const handleUpdateCache = (param: UpdateCacheProp) => {
    cache
      .getQueryCache()
      .findAll(QUERY_KEYS_INTEGRATION.list())
      .forEach((query) => {
        cache.setQueryData<InfiniteData<SearchIntegrationsResponse>>(query.queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old?.pages?.map((page) => ({
                ...page,
                integrations:
                  param.method === 'remove'
                    ? page?.integrations.filter((i) => i.id !== param.id)
                    : param.method === 'add'
                    ? [...page.integrations, param.integration]
                    : param.method === 'update'
                    ? page?.integrations.map((i) => {
                        if (i.id !== param.integration.id) {
                          return i;
                        }
                        return {
                          ...param.integration,
                        };
                      })
                    : [],
              })),
            };
          }

          if (param.method === 'add') {
            return {
              pageParams: [],
              pages: [{ integrations: [param.integration], next: '' }],
            } as InfiniteData<SearchIntegrationsResponse>;
          }
          return old;
        });
      });

    if (param.method === 'update') {
      cache.setQueryData<UpdateIntegrationPartialResponse>(
        QUERY_KEYS_INTEGRATION.detail(param.integration.id),
        param.integration
      );
    }
  };
  return {
    updateCache: handleUpdateCache,
  };
};
