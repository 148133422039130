import { ParameterTypes, SimpleParameterMapping, UIComponentType, isCustomComponent } from '@novaera/actioner-service';
import { ConstraintType, SchemaType } from '@novaera/ah-common';
import {
  FieldComponentCommonProps,
  NvLink,
  NvTextField,
  NvTypography,
  NvTypographyProps,
  isMaxLength,
  isMinLength,
  isRequired,
} from '@novaera/core';
import { assert, noop } from '@novaera/utils';
import { isNull, isUndefined } from 'lodash';
import { ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import { PropertyPanelSimpleSection } from '../../property-panel';
import { CatalogRelationshipFilterComponent } from '../catalog-relationship-filter-component';
import { useCatalogRelationshipFilterAdapter } from '../catalog-relationship-filter-component/controllers/use-catalog-relationship-filter-adaper';
import { CheckboxComponent } from '../checkbox-component';
import { useCheckboxFieldAdapter } from '../checkbox-component/checkbox-field-adapter';
import { CheckboxGroupComponent } from '../checkbox-group-component';
import { CodeInputComponent } from '../code-input-component';
import { useMultiOptionFieldAdapter } from '../common/adapter/use-multi-option-field-adapter';
import { useSingleOptionFieldAdapter } from '../common/adapter/use-single-option-field-adapter';
import { DatePickerComponent } from '../date-picker-component';
import { useDatePickerFieldAdapter } from '../date-picker-component/date-picker-field-adapter';
import { DateTimePickerComponent } from '../date-time-picker-component';
import { useDateTimePickerFieldAdapter } from '../date-time-picker-component/date-time-picker-field-adapter';
import { DisplayValueComponent } from '../display-value-component';
import { useDisplayValueFieldAdapter } from '../display-value-component/display-value-field-adapter';
import { DynamicInputComponent } from '../dynamic-input';
import { useJobTargetAdapter } from '../job-target-component/controllers/use-job-target-adapter';
import { JobTargetTaskPanel } from '../job-target-component/task-panel';
import { MultiSelectUIComponent } from '../multi-select-component';
import { RadioButtonComponent } from '../radio-button-component';
import { RecordAttributesComponent } from '../record-attributes-component';
import { useRecordAttributesAdapter } from '../record-attributes-component/record-attributes-adapter';
import { RecordPartialUpdateUIComponent } from '../record-partial-update-component';
import { useRecordPartialUpdateAdapter } from '../record-partial-update-component/record-partial-update-adapter';
import { RecordUpdateAttributesComponent } from '../record-update-component';
import { SingleSelectUIComponent } from '../single-select-component';
import { SlackBlocksComponent } from '../slack-blocks-component';
import { useSlackBlocksAdapter } from '../slack-blocks-component/controllers/use-slack-blocks-adapter';
import { TextFieldUIComponent } from '../text-field-component';
import { useTextFieldFieldAdapter } from '../text-field-component/use-text-field-field-adapter';
import { TimePickerComponent } from '../time-picker-component';
import { useTimePickerFieldAdapter } from '../time-picker-component/time-picker-field-adapter';
import { useCodeInputFieldAdapter } from '../use-code-input-field-adapter';
import { componentsNeedsOptionsOnlyOnFocus } from '../utils';
import {
  BaseUIComponentProps,
  FieldInputType,
  GetUIComponentProps,
  GetUIComponentType,
  UseUIComponentType,
  isCodeInputComponent,
  isDisplayValueComponent,
  isDynamicInputComponent,
  isInputCodeInput,
  isInputForCatalogRelationshipFilterComponent,
  isInputForCheckboxComponent,
  isInputForCheckboxGroupComponent,
  isInputForDatePickerComponent,
  isInputForDateTimePickerComponent,
  isInputForHeaderComponent,
  isInputForLinkComponent,
  isInputForMultiSelectComponent,
  isInputForPlainTextComponent,
  isInputForRadioButtonComponent,
  isInputForRecordAttributesComponent,
  isInputForRecordPartialUpdateUIComponent,
  isInputForRecordUpdateAttributesComponent,
  isInputForSelectComponent,
  isInputForSlackBlockComponent,
  isInputForTextComponent,
  isInputForTimePickerComponent,
  isInputRenderedInSlack,
  isJobTargetParameterMapping,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSyntheticEventEvent = (value: unknown): value is SyntheticEvent<any, unknown> => {
  if (typeof value !== 'object' || isNull(value) || isUndefined(value)) {
    return false;
  }
  return 'currentTarget' in (value as SyntheticEvent);
};

export const useUIComponents = ({
  isScripted = false,
  isDisplayValueConfigurable = false,
  extractValue = false,
  hidden = false,
  getOptionsResponse,
  getDynamicInputResponse,
  setInputParameterIdsShowingOptions,
  onSearchAsYouTypeValueUpdate,
  dynamicInputNestedComponentProps,
  inputParameter,
  getDynamicInputComponentState,
  forceValueUsage,
  onInputValueChange,
}: UseUIComponentType) => {
  const { format: textFieldFormat, parse: textFieldParse } = useTextFieldFieldAdapter({ extractValue, hidden });
  const { format: singleSelectFormat, parse: singleSelectParse } = useSingleOptionFieldAdapter({
    extractValue,
    hidden,
  });
  const { format: multiSelectFormat, parse: multiSelectParse } = useMultiOptionFieldAdapter({ extractValue, hidden });
  const { format: checkboxGroupFormat, parse: checkboxGroupParse } = useMultiOptionFieldAdapter({
    extractValue,
    hidden,
  });
  const { format: radioButtonFormat, parse: radioButtonParse } = useSingleOptionFieldAdapter({ extractValue, hidden });
  const { format: checkboxFormat, parse: checkboxParse } = useCheckboxFieldAdapter({ extractValue, hidden });
  const { format: datePickerFormat, parse: datePickerParse } = useDatePickerFieldAdapter({ extractValue, hidden });
  const { format: timePickerFormat, parse: timePickerParse } = useTimePickerFieldAdapter({ extractValue, hidden });
  const { format: dateTimePickerFormat, parse: dateTimePickerParse } = useDateTimePickerFieldAdapter({
    extractValue,
    hidden,
  });
  const { format: codeInputFormat, parse: codeInputParse } = useCodeInputFieldAdapter({
    extractValue,
    hidden,
    forceValueUsage,
  });
  const { format: displayValueFormat, parse: displayValueParse } = useDisplayValueFieldAdapter({
    hidden,
  });
  const { format: slackBlocksFormat, parse: slackBlocksParse } = useSlackBlocksAdapter();
  const { format: recordAttributesFormat, parse: recordAttributesParse } = useRecordAttributesAdapter();
  const { format: recordPartialUpdateFormat, parse: recordPartialUpdateParse } = useRecordPartialUpdateAdapter();
  const { format: jobTargetFormat, parse: jobTargetParse } = useJobTargetAdapter();
  const { format: catalogRelationshipFilterFormat, parse: catalogRelationshipFilterParse } =
    useCatalogRelationshipFilterAdapter();

  const memoizedDefaultValue = useMemo(() => {
    if (
      inputParameter &&
      !isCustomComponent(inputParameter?.uiComponent?.type) &&
      inputParameter.schema?.defaultValue
    ) {
      const defaultValue = {
        type: ParameterTypes.SIMPLE,
        value: inputParameter.schema?.defaultValue,
        parameterId: inputParameter.id,
      };
      return defaultValue;
    }
    return undefined;
  }, [inputParameter]);

  const defaultDisplayValue = useMemo(() => {
    if (!isCustomComponent(inputParameter?.uiComponent?.type) && inputParameter.schema?.defaultValue) {
      if (
        inputParameter?.uiComponent?.type === UIComponentType.TEXT_FIELD &&
        inputParameter.schema.type === SchemaType.map &&
        inputParameter.schema.defaultValue.type === 'object' &&
        inputParameter.schema.defaultValue.codeTemplate
      ) {
        return inputParameter.schema.defaultValue.codeTemplate;
      } else if (inputParameter.schema?.defaultValue?.displayValue) {
        return inputParameter.schema?.defaultValue?.displayValue;
      } else if (inputParameter.schema?.defaultValue?.value) {
        if (typeof inputParameter.schema?.defaultValue.value === 'string') {
          return inputParameter.schema?.defaultValue.value;
        } else if (Array.isArray(inputParameter.schema?.defaultValue.value)) {
          return inputParameter.schema.defaultValue.value.map((v) => v.displayValue ?? v.value).join(',');
        } else if (typeof inputParameter.schema?.defaultValue.value === 'object') {
          return JSON.stringify(inputParameter.schema?.defaultValue.value);
        } else {
          return `${inputParameter.schema?.defaultValue.value}`;
        }
      }
    }
    return undefined;
  }, [inputParameter.schema?.defaultValue, inputParameter.schema?.type, inputParameter?.uiComponent?.type]);

  const getUIComponent = useCallback<(params: GetUIComponentType) => ReactElement>(
    (params) => {
      if (isScripted && isInputCodeInput(params)) {
        if (isDisplayValueConfigurable && isDisplayValueComponent(params)) {
          return <DisplayValueComponent {...params} />;
        } else {
          return <CodeInputComponent {...params} />;
        }
      } else if (isCodeInputComponent(params)) {
        return <CodeInputComponent {...params} />;
      } else if (isInputForTextComponent(params)) {
        return <TextFieldUIComponent {...params} />;
      } else if (isInputForSelectComponent(params)) {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponent] - SingleSelectUIComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options, errorContext, isLoading } = getOptionsResponse(params.inputParameter.id);

        const dynamicInputComponentState = getDynamicInputComponentState?.(params.inputParameter.id);
        return (
          <SingleSelectUIComponent
            {...params}
            options={options}
            errorContext={errorContext}
            isLoading={isLoading}
            dynamicInputComponentState={dynamicInputComponentState}
          />
        );
      } else if (isInputForMultiSelectComponent(params)) {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponent] - MultiSelectUIComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options, errorContext, isLoading } = getOptionsResponse(params.inputParameter.id);
        const dynamicInputComponentState = getDynamicInputComponentState?.(params.inputParameter.id);
        return (
          <MultiSelectUIComponent
            {...params}
            options={options}
            errorContext={errorContext}
            isLoading={isLoading}
            dynamicInputComponentState={dynamicInputComponentState}
          />
        );
      } else if (isInputForCheckboxGroupComponent(params)) {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponent] - CheckboxGroupComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options, errorContext } = getOptionsResponse(params.inputParameter.id);
        const dynamicInputComponentState = getDynamicInputComponentState?.(params.inputParameter.id);
        return (
          <CheckboxGroupComponent
            {...params}
            options={options}
            errorContext={errorContext}
            dynamicInputComponentState={dynamicInputComponentState}
          />
        );
      } else if (isInputForRadioButtonComponent(params)) {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponent] - RadioButtonComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options, errorContext } = getOptionsResponse(params.inputParameter.id);
        const dynamicInputComponentState = getDynamicInputComponentState?.(params.inputParameter.id);
        return (
          <RadioButtonComponent
            {...params}
            options={options}
            errorContext={errorContext}
            dynamicInputComponentState={dynamicInputComponentState}
          />
        );
      } else if (isInputForCheckboxComponent(params)) {
        return <CheckboxComponent {...params} />;
      } else if (isInputForDatePickerComponent(params)) {
        return <DatePickerComponent {...params} />;
      } else if (isInputForTimePickerComponent(params)) {
        return <TimePickerComponent {...params} />;
      } else if (isInputForDateTimePickerComponent(params)) {
        return <DateTimePickerComponent {...params} />;
      } else if (isInputForHeaderComponent(params)) {
        return (
          <NvTypography
            {...(params.inputParameter.uiComponent.level && {
              variant: params.inputParameter.uiComponent.level.toLowerCase() as NvTypographyProps['variant'],
            })}
          >
            {params.inputParameter.uiComponent.text}
          </NvTypography>
        );
      } else if (isInputForPlainTextComponent(params)) {
        return <NvTypography variant="body1">{params.inputParameter.uiComponent.text}</NvTypography>;
      } else if (isInputForLinkComponent(params)) {
        return <NvTextField value={'LINK'}></NvTextField>;
      } else if (isInputForSlackBlockComponent(params)) {
        return (
          <>
            <PropertyPanelSimpleSection>
              <NvTypography variant="body2">
                <b>Compose Your Slack Message:</b>
                <ul>
                  <li>
                    <b>Use Markdown</b> to style and layout your message. You can format text, add lists, and more.
                  </li>
                  <li>
                    <b>Add Button blocks</b> to include interactive buttons that trigger workflows directly from your
                    message.
                  </li>
                  <li>
                    For more complex designs, use the <b>Slack Block Kit</b>. Note: The Block Kit enhances layout
                    options but does not support interactive elements like buttons.
                  </li>
                  <li>
                    Learn more:{' '}
                    <NvLink href="https://api.slack.com/block-kit/building" target="_blank" rel="noreferrer">
                      Slack Block Kit Documentation
                    </NvLink>
                  </li>
                </ul>
              </NvTypography>
            </PropertyPanelSimpleSection>
            <SlackBlocksComponent {...params} />
          </>
        );
      } else if (isInputForRecordAttributesComponent(params)) {
        return <RecordAttributesComponent {...params} />;
      } else if (isInputForRecordUpdateAttributesComponent(params)) {
        return <RecordUpdateAttributesComponent {...params} />;
      } else if (isInputForRecordPartialUpdateUIComponent(params)) {
        return <RecordPartialUpdateUIComponent {...params} />;
      } else if (isJobTargetParameterMapping(params)) {
        return <JobTargetTaskPanel {...params} />;
      } else if (isInputForCatalogRelationshipFilterComponent(params)) {
        return <CatalogRelationshipFilterComponent {...params} />;
      } else if (isDynamicInputComponent(params)) {
        assert(
          !!getDynamicInputResponse,
          new Error(
            '[useUIComponents/getUIComponent] - DynamicInputComponent cannot be used without getDynamicInputResponse.'
          ),
          'ERROR'
        );
        const { parameters, errorContext, isLoading } = getDynamicInputResponse(params.inputParameter.id);
        const dynamicComponentState = getDynamicInputComponentState?.(params.inputParameter.id);

        return (
          <DynamicInputComponent
            {...params}
            dynamicInputParameters={parameters}
            errorContext={errorContext}
            isLoading={isLoading}
            dynamicInputNestedComponentProps={dynamicInputNestedComponentProps}
            getDynamicInputResponse={getDynamicInputResponse}
            getOptionsResponse={getOptionsResponse}
            onSearchAsYouTypeValueUpdate={onSearchAsYouTypeValueUpdate}
            setInputParameterIdsShowingOptions={setInputParameterIdsShowingOptions}
            dependencyState={dynamicComponentState}
          />
        );
      } else if (isInputRenderedInSlack(params)) {
        return <CodeInputComponent {...params} />;
      } else {
        return <>Not implemented</>;
      }
    },
    [
      isScripted,
      isDisplayValueConfigurable,
      getOptionsResponse,
      getDynamicInputComponentState,
      getDynamicInputResponse,
      dynamicInputNestedComponentProps,
      onSearchAsYouTypeValueUpdate,
      setInputParameterIdsShowingOptions,
    ]
  );

  const componentFieldProps = useMemo<GetUIComponentProps>(() => {
    const { uiComponent, schema, name, id } = inputParameter;

    const constraints = schema?.constraints;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validators: ((value: any) => string | undefined)[] = [];

    constraints?.forEach((c) => {
      if (c.type === ConstraintType.stringSize) {
        const { max, min } = c;
        validators.push(
          isMinLength({
            length: min,
            valueExtractFunction: (value: SimpleParameterMapping) => value?.value?.value,
          })
        );
        validators.push(
          isMaxLength({
            length: max,
            valueExtractFunction: (value: SimpleParameterMapping) => value?.value?.value,
          })
        );
      }
    });

    if (schema?.mandatory) {
      validators.push(isRequired({ valueExtractFunction: (value: { value: string }) => value?.value }));
    }

    const baseProps: BaseUIComponentProps = {
      name,
      showErrorMessageOnlyWhenBlur: validators.length > 0,
      ...(schema?.mandatory && { isRequired: true, hasRequiredIndicator: true }),
      validators: [...validators],
      labelText: 'label' in uiComponent ? uiComponent.label : undefined,
      key: `${id}-${validators.length}`,
      defaultValue: memoizedDefaultValue,
      defaultDisplayValue,
    };

    if (isScripted) {
      return {
        ...baseProps,
        ...(isDisplayValueConfigurable && isDisplayValueComponent({ isScripted, inputParameter })
          ? { format: displayValueFormat, parse: displayValueParse({ schema, id }) }
          : { format: codeInputFormat, parse: codeInputParse({ schema, id }) }),
      };
    }

    switch (uiComponent.type) {
      case UIComponentType.TEXT_FIELD: {
        if (schema?.type === SchemaType.map) {
          return {
            ...baseProps,
            format: codeInputFormat,
            parse: codeInputParse({ schema, id, removeDisplayValue: true }),
          };
        } else {
          return {
            ...baseProps,
            format: textFieldFormat,
            parse: textFieldParse({ schema, id }),
          };
        }
      }
      case UIComponentType.SINGLE_SELECT: {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponentFieldProps] - SingleSelectUIComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options } = getOptionsResponse(id);
        return {
          ...baseProps,
          format: singleSelectFormat({ options }),
          parse: singleSelectParse({ schema, id }),
        };
      }

      case UIComponentType.MULTI_SELECT: {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponentFieldProps] - MultiSelectUIComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options } = getOptionsResponse(id);
        return {
          ...baseProps,
          format: multiSelectFormat({ options }),
          parse: multiSelectParse({ schema, id }),
        };
      }

      case UIComponentType.CHECK_BOX_GROUP: {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponentFieldProps] - CheckboxGroupComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options } = getOptionsResponse(id);
        return {
          ...baseProps,
          format: checkboxGroupFormat({ options }),
          parse: checkboxGroupParse({ schema, id }),
        };
      }

      case UIComponentType.RADIO_BUTTON_GROUP: {
        assert(
          !!getOptionsResponse,
          new Error(
            '[useUIComponents/getUIComponentFieldProps] - RadioButtonComponent cannot be used without getOptionsResponse.'
          ),
          'ERROR'
        );
        const { options } = getOptionsResponse(id);
        return {
          ...baseProps,
          format: radioButtonFormat({ options }),
          parse: radioButtonParse({ schema, id }),
        };
      }

      case UIComponentType.CHECK_BOX:
        return {
          ...baseProps,
          hideLabel: true,
          labelText: undefined,
          format: checkboxFormat,
          parse: checkboxParse({ id }),
          ...(isUndefined(baseProps.defaultValue)
            ? { defaultValue: { displayValue: 'false', type: 'boolean', value: false } }
            : {}),
        };
      case UIComponentType.DATE_PICKER:
        return {
          ...baseProps,
          format: datePickerFormat,
          parse: datePickerParse({ id }),
        };
      case UIComponentType.TIME_PICKER:
        return {
          ...baseProps,
          format: timePickerFormat,
          parse: timePickerParse({ id }),
        };
      case UIComponentType.DATE_TIME_PICKER:
        return {
          ...baseProps,
          format: dateTimePickerFormat,
          parse: dateTimePickerParse({ schema, id }),
        };

      case UIComponentType.BLOCK_LIST:
        return {
          ...baseProps,
          hideLabel: true,
          labelText: undefined,
          format: slackBlocksFormat,
          parse: slackBlocksParse({ id }),
        };
      case UIComponentType.RECORD_ATTRIBUTES:
        return {
          ...baseProps,
          labelText: undefined,
          hideLabel: true,
          format: recordAttributesFormat,
          parse: recordAttributesParse({ id }),
        };
      case UIComponentType.RECORD_UPDATE_ATTRIBUTES:
        return {
          ...baseProps,
          labelText: undefined,
          hideLabel: true,
          format: recordAttributesFormat,
          parse: recordAttributesParse({ id }),
        };
      case UIComponentType.RECORD_PARTIAL_UPDATE:
        return {
          ...baseProps,
          labelText: undefined,
          hideLabel: true,
          format: recordPartialUpdateFormat,
          parse: recordPartialUpdateParse({ id }),
        };
      case UIComponentType.CATALOG_RELATIONSHIP_FILTER:
        return {
          ...baseProps,
          labelText: undefined,
          hideLabel: true,
          format: catalogRelationshipFilterFormat,
          parse: catalogRelationshipFilterParse({ id }),
        };
      case UIComponentType.JOB_TARGET:
        return {
          ...baseProps,
          hideLabel: true,
          labelText: undefined,
          format: jobTargetFormat,
          parse: jobTargetParse({ id }),
        };
      case UIComponentType.HEADER:
      case UIComponentType.LINK:
      case UIComponentType.PLAIN_TEXT:
        return {
          ...baseProps,
          format: noop,
          parse: noop,
        };

      case UIComponentType.DYNAMIC_INPUT:
        return {
          ...baseProps,
          format: noop,
          parse: noop,
        };
      case UIComponentType.SLACK_CONVERSATION_PICKER:
      case UIComponentType.SLACK_USER_PICKER: {
        return {
          ...baseProps,
          format: codeInputFormat,
          parse: codeInputParse({ schema, id, removeDisplayValue: true }),
        };
      }

      default:
        // below code evaluate to never but there might be a case we didn't handle coming from backend so I am putting any here.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        assert(false, new Error(`Unknown ui component type:${(uiComponent as any)?.type}`), 'ERROR');
    }
  }, [
    catalogRelationshipFilterFormat,
    catalogRelationshipFilterParse,
    checkboxFormat,
    checkboxGroupFormat,
    checkboxGroupParse,
    checkboxParse,
    codeInputFormat,
    codeInputParse,
    datePickerFormat,
    datePickerParse,
    dateTimePickerFormat,
    dateTimePickerParse,
    defaultDisplayValue,
    displayValueFormat,
    displayValueParse,
    getOptionsResponse,
    inputParameter,
    isDisplayValueConfigurable,
    isScripted,
    jobTargetFormat,
    jobTargetParse,
    memoizedDefaultValue,
    multiSelectFormat,
    multiSelectParse,
    radioButtonFormat,
    radioButtonParse,
    recordAttributesFormat,
    recordAttributesParse,
    recordPartialUpdateFormat,
    recordPartialUpdateParse,
    singleSelectFormat,
    singleSelectParse,
    slackBlocksFormat,
    slackBlocksParse,
    textFieldFormat,
    textFieldParse,
    timePickerFormat,
    timePickerParse,
  ]);

  const onFocus = useCallback(
    ({ callback, id }: { id: string; callback: () => void }) =>
      () => {
        setInputParameterIdsShowingOptions?.((prevValue) => {
          return [...prevValue, id];
        });
        return callback();
      },
    [setInputParameterIdsShowingOptions]
  );

  const onBlur = useCallback(
    ({ callback, id }: { id: string; callback: () => void }) =>
      () => {
        setInputParameterIdsShowingOptions?.((prevValue) => {
          return prevValue.filter((pv) => pv !== id);
        });
        return callback();
      },
    [setInputParameterIdsShowingOptions]
  );

  const onChange = useCallback(
    ({
        callback,
        id,
        componentType,
      }: {
        id: string;
        callback: {
          (v: unknown): void;
        };
        componentType: UIComponentType;
      }) =>
      (value: unknown) => {
        if (componentType === UIComponentType.CHECK_BOX) {
          if (!isSyntheticEventEvent(value)) {
            onInputValueChange?.({
              inputParameterId: id,
              value: componentFieldProps.parse(value as never),
            });
          }
        } else {
          onInputValueChange?.({
            inputParameterId: id,
            value: isSyntheticEventEvent(value)
              ? componentFieldProps.parse(value.currentTarget.value as never)
              : componentFieldProps.parse(value as never),
          });
        }

        return callback(value);
      },
    [componentFieldProps, onInputValueChange]
  );

  const getComponentForField =
    ({ inputParameter, otherProps, isScripted, context }: GetUIComponentType) =>
    <V,>(props: FieldComponentCommonProps<V>) => {
      const actualPropsType = props as FieldInputType;

      let newProps: FieldInputType;
      if (!isScripted && inputParameter?.uiComponent) {
        if (componentsNeedsOptionsOnlyOnFocus(inputParameter.uiComponent)) {
          newProps = {
            name: actualPropsType.name,
            onBlur: onBlur({ callback: actualPropsType.onBlur, id: inputParameter.id }),
            onChange: onChange({
              callback: actualPropsType.onChange,
              id: inputParameter.id,
              componentType: inputParameter.uiComponent.type,
            }),
            onFocus: onFocus({ callback: actualPropsType.onFocus, id: inputParameter.id }),
            value: actualPropsType.value,
            checked: actualPropsType.checked,
            error: actualPropsType.error,
          };
        } else {
          newProps = {
            name: actualPropsType.name,
            onBlur: actualPropsType.onBlur,
            onChange: onChange({
              callback: actualPropsType.onChange,
              id: inputParameter.id,
              componentType: inputParameter.uiComponent.type,
            }),
            onFocus: actualPropsType.onFocus,
            value: actualPropsType.value,
            checked: actualPropsType.checked,
            error: actualPropsType.error,
          };
        }
      } else {
        newProps = {
          name: actualPropsType.name,
          onBlur: actualPropsType.onBlur,
          onChange: actualPropsType.onChange,
          onFocus: actualPropsType.onFocus,
          value: actualPropsType.value,
          checked: actualPropsType.checked,
          error: actualPropsType.error,
        };
      }

      return getUIComponent({
        inputParameter,
        inputProps: newProps,
        otherProps,
        isScripted,
        isDisplayValueConfigurable,
        context,
        onSearchAsYouTypeValueUpdate,
      });
    };

  return { componentFieldProps, getComponentForField };
};
