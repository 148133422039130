import {
  APP_PERMISSION,
  CloneResponse,
  USER_PERMISSION,
  useDeleteUserApp,
  useFetchProfile,
  useGetSlackApp,
  useGetSlackWorkspace,
  useGetUserAppSetupStatus,
  useUnlockManagedApp,
  useUpdateUserAppLogoAndName,
} from '@novaera/actioner-service';
import {
  Initial,
  NvChip,
  NvCloudUploadIcon,
  NvConstructionRoundedIcon,
  NvContentCopyIcon,
  NvDeleteOutlineIcon,
  NvFlex,
  NvFocusState,
  NvImage,
  NvLockOpenIcon,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSlackIconNotColored,
  NvTextField,
  NvTypography,
  NvUploadIcon,
  isMaxLength,
  isRequired,
  useConfirmDialog,
  useNvDialogModalState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublishAppStepTracer } from '../../../../app-directory/app-directory-list/publish-your-app';
import { SelectedAppIdProvider } from '../../../../app-directory/app-directory-list/publish-your-app/providers/selected-app-id';
import { PublishAppMode } from '../../../../app-directory/app-directory-list/publish-your-app/types';
import { Clone } from '../../../../components/clone';
import { CloneFlagger } from '../../../../components/clone/flagger';
import { HeaderAndLogoInlineEdit } from '../../../../components/header-and-logo-inline-edit';
import { NvLogoUpload } from '../../../../components/logo-upload';
import { useUserPermissions } from '../../../../user-permission-boundary/use-user-permission';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { useIsAppFree } from '../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { useUserAppPermissionBoundary } from '../../../user-app-permission-boundary/use-user-app-permission-boundary';
import { useWorkflowPermission } from '../../../user-app-permission-boundary/use-workflow-permission';
import { USER_APP_LIST, USER_APP_SETUP } from '../../constants';
import { ExportSlackAppFocusState } from '../../slack-app/export-slack-app';
import { ExportSlackAppFocusStateOption } from '../../slack-app/export-slack-app/types';
import { AppVersionWrapper } from './styled';
import { UserAppDescriptionHeaderProps, UserAppHeaderLogoInlineEditFormProps } from './types';
import { UpdateAppButton } from './update-app-button';

export const UserAppDescriptionHeader: React.FC<React.PropsWithChildren<UserAppDescriptionHeaderProps>> = ({
  userApp,
}) => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const navigate = useNavigate();
  const [isFocusStateOpen, setFocusStateOpen] = useState(false);
  const [isSlackFocusStateOpen, setIsSlackFocusStateOpen] = useState<boolean>(false);
  const [clonedItem, setClonedItem] = useState<CloneResponse | undefined>();
  const { checkPermission } = useUserAppPermissionBoundary({ appId: userApp.id });
  const { checkPermission: checkUserPermission } = useUserPermissions();
  const { openConfirm } = useConfirmDialog();
  const { openConfirm: openUnlockAppConfirm, closeConfirm: closeUnlockAppConfirm } = useConfirmDialog();
  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } = useNvDialogModalState<{
    id: string;
    name: string;
  }>();
  const { data: slackWorkspace } = useGetSlackWorkspace();
  const { data: slackApp } = useGetSlackApp({ appId: userAppId });
  const { isManagedApp } = useWorkflowPermission();
  const { mutate: deletePackage } = useDeleteUserApp();
  const { mutate: updateUserAppLogoAndName } = useUpdateUserAppLogoAndName();
  const { mutate: unlockManagedApp } = useUnlockManagedApp();
  const { isAppFree } = useIsAppFree({ userAppId });
  const { data: user } = useFetchProfile();

  const { data } = useGetUserAppSetupStatus({
    appId: userAppId,
  });

  const openExportSlackAppFocusState = () => {
    setIsSlackFocusStateOpen(true);
  };
  const closeExportSlackAppFocusState = () => {
    setIsSlackFocusStateOpen(false);
  };

  const handleClone = useCallback(() => {
    onModalOpenClicked({ id: userApp.id, name: userApp.name });
  }, [onModalOpenClicked, userApp.id, userApp.name]);

  const handlePublish = () => {
    setFocusStateOpen(true);
  };

  const handleAppDelete = useCallback(() => {
    if (!userApp.id) return;

    openConfirm({
      title: `Delete ${userApp.name}?`,
      message:
        'By deleting this app, you will lose access to its workflows and other configurations permanently. This action cannot be undone. Do you want to proceed?',
      onConfirm: () => {
        deletePackage(
          { appId: userApp.id },
          {
            onSuccess: () => {
              navigate(USER_APP_LIST);
            },
          }
        );
      },
    });
  }, [userApp.id, userApp.name, openConfirm, deletePackage, navigate]);

  const handleUnlockManagedApp = useCallback(() => {
    openUnlockAppConfirm({
      title: `Unlock ${userApp.name}`,
      confirmButtonLabel: 'Unlock',
      message:
        'If you unlock this app, you will no longer receive the updates. This action cannot be undone. Are you sure you want to proceed?',
      onConfirm: () => {
        unlockManagedApp({ appId: userAppId });
        closeUnlockAppConfirm();
      },
      onClose: () => {
        closeUnlockAppConfirm();
      },
    });
  }, [closeUnlockAppConfirm, openUnlockAppConfirm, unlockManagedApp, userApp.name, userAppId]);

  const handleSetup = useCallback(() => {
    navigate(USER_APP_SETUP(userAppId), { state: { forceShowFilledSteps: true } });
  }, [navigate, userAppId]);

  const showReopenSetupMenu =
    checkPermission(APP_PERMISSION.APP_EDIT) &&
    (data?.dismissed || data?.setupWorkflowsCompleted) &&
    userApp.metadata.schemaId &&
    userApp.metadata.creationSource !== 'scratch';

  const userAppHeaderActions = useMemo(
    () =>
      [
        {
          name: 'Clone',
          onClick: handleClone,
          icon: <NvContentCopyIcon sx={{ width: '16px', height: '16px' }} />,
          right: checkUserPermission(USER_PERMISSION.APP_CREATE),
        },
        {
          name: 'Publish to directory',
          onClick: handlePublish,
          icon: <NvUploadIcon sx={{ width: '16px', height: '16px' }} />,
          right: checkPermission(APP_PERMISSION.APP_EDIT) && !isManagedApp && !isAppFree && user?.isSuperAdmin,
        },
        {
          name: 'Unlock app',
          onClick: handleUnlockManagedApp,
          icon: <NvLockOpenIcon sx={{ width: '16px', height: '16px' }} />,
          right: checkPermission(APP_PERMISSION.APP_EDIT) && isManagedApp && !isAppFree,
        },
        {
          name: 'Reopen setup steps',
          onClick: handleSetup,
          icon: <NvConstructionRoundedIcon sx={{ width: '16px', height: '16px' }} />,
          right: showReopenSetupMenu,
        },
        {
          name: 'Create a standalone app for Slack',
          onClick: () => openExportSlackAppFocusState(),
          right:
            checkPermission(APP_PERMISSION.APP_EDIT) && !slackApp?.marketplaceApp && !slackApp?.enterpriseInstallation,
          icon: <NvSlackIconNotColored sx={{ width: '16px', height: '16px' }} />,
          disabled: !slackWorkspace,
          ...(!slackWorkspace
            ? {
                tooltip: {
                  props: {
                    title: (
                      <NvTypography variant="body2">
                        Connect <b>only one</b> Slack workspace <br /> to create a app for Slack.
                      </NvTypography>
                    ),
                  },
                },
              }
            : {}),
        },
        {
          name: 'divider',
          isDivider: true,
          right: checkPermission(APP_PERMISSION.APP_EDIT) && !slackApp?.enterpriseInstallation && !isAppFree,
        },
        {
          name: 'Delete',
          onClick: handleAppDelete,
          isLoading: false,
          icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          right: checkPermission(APP_PERMISSION.APP_EDIT) && !slackApp?.enterpriseInstallation,
        },
      ].filter(({ right }) => right),
    [
      checkPermission,
      checkUserPermission,
      handleAppDelete,
      handleClone,
      handleSetup,
      handleUnlockManagedApp,
      isAppFree,
      isManagedApp,
      showReopenSetupMenu,
      slackApp?.marketplaceApp,
      slackApp?.enterpriseInstallation,
      slackWorkspace,
      theme.palette.nv_error,
    ]
  );

  const handleChangeImageAndLogo = (values?: UserAppHeaderLogoInlineEditFormProps) => {
    const { name, logo } = values ?? {};

    let logoId: string | undefined;
    if (logo?.operation === 'delete') {
      logoId = undefined;
    } else {
      logoId = logo?.id ?? userApp.logoId;
    }
    assert(!!name, new Error('[UserAppDescriptionHeader] - Name should be defined to update!!'), 'ERROR');

    return new Promise<void>((resolve, reject) => {
      updateUserAppLogoAndName(
        { appId: userApp.id, name: name, logoId },
        {
          onSuccess: () => {
            return resolve();
          },
          onError: reject,
        }
      );
    });
  };

  return (
    <>
      <NvFlex direction="row" gap="12px" alignItems="flex-start" justifyContent="space-between">
        <UserAppPermissionBoundary
          appId={userApp.id}
          permission={APP_PERMISSION.APP_EDIT}
          Fallback={
            <NvFlex direction="row" gap="12px" alignItems="center">
              <NvImage
                FallBack={
                  <Initial size="large" value={userApp?.name || 'User app'} color={theme.palette.nv_neutral[500]} />
                }
                src={userApp.logoUrl}
                size="large"
                imageShape="square"
                fallbackImageHasBlurEffect
              />
              <NvTypography variant="h1" flex="1 1 auto" minWidth="0" noWrap>
                {userApp.name}
              </NvTypography>
            </NvFlex>
          }
        >
          <HeaderAndLogoInlineEdit<UserAppHeaderLogoInlineEditFormProps>
            key={`${userApp.id}_header_and_logo_edit`}
            viewComponent={
              <NvFlex direction="row" gap="12px" alignItems="center">
                <NvImage
                  FallBack={
                    <Initial size="large" value={userApp?.name || 'User app'} color={theme.palette.nv_neutral[500]} />
                  }
                  src={userApp.logoUrl}
                  size="large"
                  imageShape="square"
                  fallbackImageHasBlurEffect
                />
                <NvTypography variant="h1" flex="1 1 auto" minWidth="0" noWrap>
                  {userApp.name}
                </NvTypography>
              </NvFlex>
            }
            value={{ name: userApp.name }}
            validate={(value) =>
              isRequired({ message: 'Name is required field' })(value?.name) ||
              isMaxLength({ length: 150 })(value?.name)
            }
            onConfirm={handleChangeImageAndLogo}
            editComponent={({ setCurrentValue, currentValue, error }) => {
              return (
                <NvFlex gap={3} width="fit-content">
                  <NvLogoUpload
                    ImageFallBack={
                      <Initial size="large" value={userApp.name || 'User app'} color={theme.palette.nv_neutral[500]} />
                    }
                    initialLogoUrl={userApp.logoUrl}
                    type="app"
                    imageShape="square"
                    imageSize="xlarge"
                    onLogoStateChanged={({ logo }) => {
                      setCurrentValue({ ...currentValue, logo });
                    }}
                    logoUploadButtonProps={{
                      startIcon: <NvCloudUploadIcon />,
                      text: 'Upload image',
                    }}
                    logoUploadHintProps={{ text: 'At least 280x280 pixels, and square images recommended' }}
                    hasRemoveAction
                    fallbackImageProps={{
                      hasBlurEffect: true,
                      className: 'package-image',
                    }}
                  />
                  <NvFlex gap="8px">
                    <NvTypography variant="h5">Name</NvTypography>
                    <NvTextField
                      error={!!error}
                      value={currentValue?.name}
                      onChange={(e) => {
                        const value = e.target.value;
                        setCurrentValue({ ...currentValue, name: value });
                      }}
                    />
                  </NvFlex>
                </NvFlex>
              );
            }}
          />
        </UserAppPermissionBoundary>
        <NvFlex alignItems="center" direction="row" gap="8px">
          {userApp?.metadata?.schemaVersion && (
            <>
              <AppVersionWrapper>
                <NvTypography variant="h6">App version</NvTypography>
                <NvChip label={`V ${userApp?.metadata?.schemaVersion ?? '1.0.0'}`} compact />
              </AppVersionWrapper>
              <UpdateAppButton />
            </>
          )}
          {userAppHeaderActions.length > 0 && (
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'medium', color: 'secondary' },
              }}
              menuItems={userAppHeaderActions}
            />
          )}
        </NvFlex>
      </NvFlex>
      {isSlackFocusStateOpen && (
        <ExportSlackAppFocusState
          isOpen={isSlackFocusStateOpen}
          onClose={closeExportSlackAppFocusState}
          type={ExportSlackAppFocusStateOption.CREATE}
        />
      )}
      <NvFocusState
        open={isFocusStateOpen}
        headerLogo={<NvCloudUploadIcon fontSize="large" />}
        title="Publish your app"
        onClose={() => {
          setFocusStateOpen(false);
        }}
      >
        <SelectedAppIdProvider initialAppId={userAppId}>
          <PublishAppStepTracer
            mode={PublishAppMode.Publish}
            onCancel={() => {
              setFocusStateOpen(false);
            }}
          />
        </SelectedAppIdProvider>
      </NvFocusState>
      {passedParameters?.id && passedParameters.name && (
        <Clone
          isOpened={isOpened}
          appId={passedParameters.id}
          name={passedParameters.name}
          onModalCloseClicked={onModalCloseClicked}
          type="apps"
          onCloneSuccess={(param) => {
            setClonedItem(param);
          }}
        />
      )}
      {clonedItem && (
        <CloneFlagger
          opened={!!clonedItem}
          clonedItem={clonedItem}
          onFlaggerClosed={() => {
            setClonedItem(undefined);
          }}
          type={'apps'}
        />
      )}
    </>
  );
};
