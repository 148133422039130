import {
  NodeExecutionSortOrder,
  NodeExecutionStatus,
  USER_PERMISSION,
  useGetWorkflowNodeExecutions,
} from '@novaera/actioner-service';
import {
  NvBox,
  NvButton,
  NvConditionalWrap,
  NvCustomSortDownIcon,
  NvCustomSortUpIcon,
  NvFlex,
  NvFullscreenRounded,
  NvTooltip,
  SectionMessage,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useState } from 'react';
import { ROUTES } from '../../../../common/routes';
import { useUserPermissions } from '../../../../user-permission-boundary/use-user-permission';
import { WORKSPACE_SETTINGS_TAB_PATHS } from '../../../../workspace/workspace-settings/constants';
import { WorkflowHistoryListHeader } from '../../styled';
import { WorkflowHistoryNodeExecutionsWrapper } from './styled';
import { WorkflowHistoryNodeExecution } from './workflow-history-node-execution';
import { WorkflowHistoryNodeExecutionLoadingItem } from './workflow-history-node-execution/loading-item';

export const WorkflowHistoryNodeExecutions = () => {
  const { userAppId, workflowId, workflowExecutionId } = useParams();
  const [fullScreen, setFullScreen] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<NodeExecutionSortOrder>(NodeExecutionSortOrder.EARLIEST_FIRST);
  const [selectedNodeExecutionObjectKeys, setSelectedNodeExecutionObjectKeys] = useState<string[]>([]);

  const {
    data: workflowNodeExecutionsQueries,
    isInitialLoading: isWorkflowExecutionLoading,
    fetchNextPage: fetchNextWorkflowNodeExecutionsPage,
    hasNextPage: hasNextWorkflowNodeExecutionsPage,
    isFetchingNextPage: isFetchingNextWorkflowNodeExecutionsPage,
  } = useGetWorkflowNodeExecutions({
    appId: userAppId,
    workflowId,
    workflowExecutionId,
    'sort-order': sortOrder,
  });

  const { checkPermission } = useUserPermissions();

  const workflowNodeExecutionsPages = workflowNodeExecutionsQueries?.pages.filter((p) => p);

  const isSubscriptionLimitError = workflowNodeExecutionsPages?.some((page) =>
    page.logs.some((page) => page.status === NodeExecutionStatus.FAILED && page.type === 'subscription_limit')
  );

  return (
    <WorkflowHistoryNodeExecutionsWrapper fullScreen={fullScreen}>
      {!isSubscriptionLimitError && (
        <>
          <WorkflowHistoryListHeader
            title="Run order"
            actions={
              <>
                <NvTooltip
                  title={sortOrder === NodeExecutionSortOrder.EARLIEST_FIRST ? 'Earliest first' : 'Latest first'}
                >
                  <NvButton
                    onlyIcon
                    size="small"
                    color="ghost"
                    onClick={() => {
                      setSortOrder((prev) =>
                        prev === NodeExecutionSortOrder.EARLIEST_FIRST
                          ? NodeExecutionSortOrder.LATEST_FIRST
                          : NodeExecutionSortOrder.EARLIEST_FIRST
                      );
                    }}
                  >
                    {sortOrder === NodeExecutionSortOrder.EARLIEST_FIRST ? (
                      <NvCustomSortUpIcon />
                    ) : (
                      <NvCustomSortDownIcon />
                    )}
                  </NvButton>
                </NvTooltip>
                <NvButton onlyIcon size="small" color="ghost" onClick={() => setFullScreen((prev) => !prev)}>
                  <NvFullscreenRounded />
                </NvButton>
              </>
            }
          />
          {isWorkflowExecutionLoading ? (
            <NvFlex>
              <WorkflowHistoryNodeExecutionLoadingItem />
              <WorkflowHistoryNodeExecutionLoadingItem />
              <WorkflowHistoryNodeExecutionLoadingItem />
            </NvFlex>
          ) : (
            workflowNodeExecutionsPages?.map(({ logs }) =>
              logs.map((log) => (
                <WorkflowHistoryNodeExecution
                  key={log.objectKey}
                  nodeExecution={log}
                  selectedNodeExecutionObjectKeys={selectedNodeExecutionObjectKeys}
                  handleItemClick={() => {
                    setSelectedNodeExecutionObjectKeys((prev) =>
                      prev.includes(log.objectKey) ? prev.filter((p) => p !== log.objectKey) : [...prev, log.objectKey]
                    );
                  }}
                />
              ))
            )
          )}
          {hasNextWorkflowNodeExecutionsPage && (
            <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
              <NvButton
                size="small"
                color="secondary"
                onClick={() => {
                  fetchNextWorkflowNodeExecutionsPage();
                }}
                loading={isFetchingNextWorkflowNodeExecutionsPage}
                disabled={isFetchingNextWorkflowNodeExecutionsPage}
              >
                Show more
              </NvButton>
            </NvFlex>
          )}
        </>
      )}
      {isSubscriptionLimitError && (
        <NvBox
          sx={{
            width: '580px',
            margin: '32px',
          }}
        >
          <SectionMessage
            variant="error"
            title={'Credit Limit Exceeded'}
            message={
              "We're sorry, but you've reached your credit usage limit for this period. As a result, the workflow cannot be run. To continue using our services, please consider upgrading your plan or contact support for assistance. Thank you for your understanding."
            }
            actionButton={
              <NvConditionalWrap
                condition={!checkPermission(USER_PERMISSION.WORKSPACE_UPDATE_METADATA)}
                wrap={(children) => (
                  <NvTooltip title={'Only Workspace admin can upgrade plan.'} children={<span>{children}</span>} />
                )}
              >
                <NvButton
                  disabled={!checkPermission(USER_PERMISSION.WORKSPACE_UPDATE_METADATA)}
                  onClick={() => {
                    if (checkPermission(USER_PERMISSION.WORKSPACE_UPDATE_METADATA)) {
                      window.location.href = `${ROUTES.Workspace}${WORKSPACE_SETTINGS_TAB_PATHS.BILLING}`;
                    }
                  }}
                  color="secondary"
                  size="small"
                >
                  Upgrade your plan
                </NvButton>
              </NvConditionalWrap>
            }
          />
        </NvBox>
      )}
    </WorkflowHistoryNodeExecutionsWrapper>
  );
};
