import { FC } from 'react';
import { MatchEventSectionCondition } from './condition';
import { DEFAULT_CONDITION_TYPE_OPTIONS } from './constants';
import { MatchEventSectionRules } from './section-rules';
import { MatchEventSectionProps } from './types';

export const MatchEventSection: FC<React.PropsWithChildren<MatchEventSectionProps>> = ({
  conditionFieldName = 'eventFilter.condition',
  message,
  codeInputContext,
  conditionTypeOptions = DEFAULT_CONDITION_TYPE_OPTIONS,
  JavascriptWrapperComponent,
}) => {
  return (
    <MatchEventSectionCondition
      message={message}
      conditionFieldName={conditionFieldName}
      conditionTypeOptions={conditionTypeOptions}
      codeInputContext={codeInputContext}
      RuleComponent={MatchEventSectionRules}
      JavascriptWrapperComponent={JavascriptWrapperComponent}
    />
  );
};
