import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_USER_APP } from '../../user-app/keys';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { InstallAppSchemaParams, InstallAppSchemaResponse } from './types';

const installAppSchema = async ({ schemaId, version }: InstallAppSchemaParams) => {
  const result = await NvAxios.post<InstallAppSchemaResponse>(
    `${APP_DIRECTORY_ROOT_PATH}/schemas/${schemaId}/versions/${version}/import`
  );
  return result?.data;
};

export const useInstallAppSchema = () => {
  const cache = useQueryClient();
  return useMutation(installAppSchema, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS_USER_APP.search());
      cache.invalidateQueries(QUERY_KEYS_USER_APP.infiniteSearch());
    },
  });
};
