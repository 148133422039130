import { WorkflowReferencesBlock } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';

import { useTheme } from '@novaera/theme-provider';
import { generateUniqueId } from '@novaera/utils';
import { useCallback } from 'react';
import { WORKFLOW_REFERENCES_BLOCK_NEW_ELEMENT_DEFAULT_VALUE } from '../../constants';
import { useSlackBlocksContext } from '../../provider';
import { UseWorkflowReferenceParams } from './types';

export const useWorkflowReferenceBlock = ({ block, onChange, index }: UseWorkflowReferenceParams) => {
  const { setSelectedElementId } = useSlackBlocksContext();
  const theme = useTheme();
  const { userAppId } = useParams();

  const handleAddButtonElement = useCallback(() => {
    const newId = generateUniqueId();
    const newBlock: WorkflowReferencesBlock = {
      ...block,
      elements: [
        ...block.elements,
        { ...WORKFLOW_REFERENCES_BLOCK_NEW_ELEMENT_DEFAULT_VALUE, id: newId, appId: userAppId },
      ],
    };

    onChange(newBlock, index);
    setSelectedElementId(newId);
  }, [block, index, onChange, setSelectedElementId, userAppId]);

  return {
    handleAddButtonElement,
    theme,
  };
};
