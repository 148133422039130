import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONNECTION_MAPPING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION_MAPPINGS } from '../keys';
import { ConnectionMapping } from '../types';
import { UseUpdateConnectionMappingParams } from './types';

const updateConnectionMapping = async (params: UseUpdateConnectionMappingParams) => {
  const {
    appId,
    schemaId: connectionSchemaId,
    payload: { connectionId },
  } = params;

  const result = await NvAxios.post<void>(`${CONNECTION_MAPPING_ROOT_PATH(appId)}`, {
    connectionId,
    connectionSchemaId,
  });
  return result.data;
};

export const useUpdateConnectionMappingService = () => {
  const cache = useQueryClient();

  return useMutation(updateConnectionMapping, {
    onSuccess(_data, params) {
      cache.setQueryData(QUERY_KEYS_CONNECTION_MAPPINGS.list(params.appId), (old?: ConnectionMapping[]) => {
        return old?.map((item) => {
          if (item.slackConnection || item.msTeamsConnection) {
            return item;
          }

          return item.schemaId !== params.schemaId
            ? item
            : {
                ...item,
                connections: !item.connections.find((connection) => connection.id === params.payload.connectionId)
                  ? [
                      ...(item.connections || []),
                      {
                        id: params.payload.connectionId,
                        name: params.connectionName,
                      },
                    ]
                  : item.connections,
                connectionId: params.payload.connectionId,
                connected: true,
                ambiguous: false,
              };
        });
      });
    },
  });
};
