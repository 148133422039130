import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery as originalUseQuery,
} from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { useMemo } from 'react';
import { useHandleCommonError } from '../use-handle-error';

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, TError> {
  const { handleCommonError } = useHandleCommonError();

  const newOptions = useMemo(() => {
    let newOptions = options;

    if (options && options.onError) {
      const onErrorFn = options.onError;
      newOptions = {
        ...newOptions,
        onError: (err: TError) => {
          if (
            axios.isAxiosError(err) &&
            err.response?.status === 403 &&
            err.response?.headers['errorcode'] === '4034'
          ) {
            return;
          }
          try {
            onErrorFn?.(err);
          } catch (error) {
            handleCommonError(err);
          }
        },
      };
    } else {
      newOptions = {
        ...(newOptions ?? {}),
        onError: (err: unknown) => {
          if (
            axios.isAxiosError(err) &&
            err.response?.status === 403 &&
            err.response?.headers['errorcode'] === '4034'
          ) {
            return;
          }

          handleCommonError(err);
        },
      };
    }

    if (options?.retry && typeof options.retry === 'function') {
      const retryFn = options.retry;
      newOptions = {
        ...newOptions,
        retry: (failureCount, error) => {
          if (
            isAxiosError(error) &&
            error.response?.status === 403 &&
            error.response?.headers['errorcode'] === '4034'
          ) {
            return false;
          }
          return retryFn?.(failureCount, error);
        },
      };
    } else if (options?.retry && typeof options.retry === 'number') {
      newOptions = {
        ...newOptions,
        retry: (failureCount, error) => {
          if (
            isAxiosError(error) &&
            error.response?.status === 403 &&
            error.response?.headers['errorcode'] === '4034'
          ) {
            return false;
          }
          return options?.retry ? (options.retry as number) < failureCount : failureCount < 3;
        },
      };
    } else {
      newOptions = {
        ...newOptions,
        retry: (failureCount, error) => {
          if (
            isAxiosError(error) &&
            error.response?.status === 403 &&
            error.response?.headers['errorcode'] === '4034'
          ) {
            return false;
          }
          return failureCount < 3;
        },
      };
    }

    return newOptions;
  }, [handleCommonError, options]);

  return originalUseQuery(queryKey, queryFn, newOptions);
}
