import { NvFlex, NvTypography } from '@novaera/core';
import { format } from 'date-fns';
import { FC } from 'react';
import { UpdateSpendingLimit } from '../../non-free-workspace-layout/spending-limits/update-spending-limits';
import { SpendingLimitModalBodyProps } from './types';

export const SpendingLimitModalBody: FC<SpendingLimitModalBodyProps> = ({
  initialBillingPreferences,
  currentSubscriptionDetails,
}) => {
  const { amount: currentAmount, periodEnd: currentPeriodEnd } = currentSubscriptionDetails;

  const currentPeriodEndFormatted = format(new Date(currentPeriodEnd), 'MMMM dd, yyyy');
  return (
    <NvFlex gap={'16px'} minWidth="500px">
      <NvTypography variant="body1">
        Your plan will renew on <b>{currentPeriodEndFormatted}</b> for <b>${currentAmount}</b>.
      </NvTypography>
      <UpdateSpendingLimit initialBillingPreferences={initialBillingPreferences} />
    </NvFlex>
  );
};
