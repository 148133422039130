import { NvAxios, useQuery } from '@novaera/core';
import { CONFIG_SCHEMA_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIG_SCHEMA } from '../keys';
import { ConfigSchema } from '../types';
import { GetConfigSchemaParams } from './types';

const getConfigSchema = async ({ appId, id }: GetConfigSchemaParams) => {
  const result = await NvAxios.get<ConfigSchema>(`${CONFIG_SCHEMA_ROOT_PATH(appId)}/${id}`);
  return result?.data;
};

export const useGetConfigSchema = (params: GetConfigSchemaParams) => {
  return useQuery<ConfigSchema>(
    QUERY_KEYS_CONFIG_SCHEMA.detail(params.appId, params.id),
    () => getConfigSchema(params),
    {
      enabled: Boolean(params.id && params.appId),
    }
  );
};
