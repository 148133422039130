import { NvCanvas } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledWorkflowCanvas = styled(NvCanvas)`
  .react-flow__edge {
    .react-flow__edge-path {
      stroke: ${({ theme }) => theme.palette.nv_neutral[300]};
    }

    &.selected {
      .react-flow__edge-path {
        stroke: ${({ theme }) => theme.palette.nv_neutral[300]};
      }
    }
  }

  .react-flow__node-output {
    padding: initial;
    border-radius: initial;
    width: initial;
    font-size: initial;
    color: initial;
    text-align: initial;
    border: none;
    background-color: initial;

    &.selected {
      box-shadow: none;
    }

    &.selectable {
      :hover {
        box-shadow: none;
      }
    }
  }
`;
