import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const DynamicOptionsErrorBox = styled(NvBox)`
  padding: 6px;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[10]};
  border: 1px solid ${({ theme }) => theme.palette.nv_error[30]};
  border-radius: 6px;

  p {
    word-break: break-word;
  }
`;
