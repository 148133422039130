export const NvVerticalActionerLogo = () => {
  return (
    <svg width="85" height="20" viewBox="0 0 85 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3566 8.54999L13.9511 9.93888L12.8103 7.96296C12.0974 6.7287 10.3159 6.7287 9.60291 7.96296L8.46217 9.93888L6.05664 8.54999L9.28161 1.11111C10.1372 -0.370371 12.2761 -0.370371 13.1316 1.11111L16.3566 8.54999Z"
        fill="#6B91E0"
      />
      <path
        d="M10.2807 16.2963L10.2807 19.074L2.2252 20C0.515018 20 -0.554426 18.1481 0.301129 16.6666L5.13078 10.1537L7.53631 11.5426L6.39557 13.5185C5.68261 14.7537 6.57335 16.2963 7.99927 16.2963L10.2807 16.2963Z"
        fill="#F76D6D"
      />
      <path
        d="M20.1872 20L12.1326 19.0741L12.1326 16.2963L14.414 16.2963C15.84 16.2963 16.7307 14.7537 16.0178 13.5185L14.877 11.5426L17.2825 10.1537L22.1122 16.6666C22.9677 18.1481 21.8983 20 20.1872 20Z"
        fill="#FAB701"
      />
      <path
        d="M32.4417 8.64704C31.9161 7.90895 30.9423 7.42383 29.9399 7.42383C29.0054 7.42383 28.1488 7.77323 27.3893 8.44347C26.6202 9.12323 26.25 10.0357 26.25 11.2298C26.25 12.4238 26.6298 13.3268 27.3893 14.0161C28.1488 14.6959 29.0054 15.0357 29.9304 15.0357C30.9524 15.0357 31.9161 14.55 32.4423 13.8125V14.9H33.8345V7.57026H32.4423V8.64764L32.4417 8.64704ZM27.6226 11.2197C27.6226 10.4238 27.8661 9.8024 28.353 9.35538C28.8494 8.90895 29.4042 8.69526 30.047 8.69526C30.6899 8.69526 31.2345 8.91847 31.7214 9.35538C32.2179 9.82145 32.4518 10.4232 32.4518 11.2197C32.4518 12.0161 32.2083 12.6369 31.7214 13.0839C31.2446 13.5209 30.6798 13.7441 30.047 13.7441C29.4143 13.7441 28.8494 13.5304 28.353 13.0839C27.8762 12.647 27.6423 12.0351 27.6226 11.2197Z"
        fill="#0D1B3B"
      />
      <path
        d="M37.8585 9.37502C38.3644 8.92859 38.9293 8.69526 39.5233 8.69526C39.9972 8.69526 40.3972 8.78871 40.7352 8.98097C40.94 9.10002 41.1233 9.25359 41.2858 9.44347C41.3608 9.53216 41.4323 9.62859 41.4995 9.73454L41.5579 9.85121L42.7263 9.35597L42.6388 9.21014C42.0061 8.05478 40.8864 7.42383 39.4942 7.42383C37.9269 7.42383 35.7168 8.60835 35.7168 11.2393C35.7168 12.0453 35.921 12.7345 36.3299 13.3459C36.7585 13.9476 37.2448 14.3845 37.7704 14.6369C38.296 14.8988 38.8704 15.0351 39.4835 15.0351C40.9245 15.0351 41.9852 14.4232 42.6376 13.2393L42.7251 13.0935L41.5567 12.5982L41.4983 12.7149C41.0602 13.3947 40.3983 13.7441 39.5222 13.7441C38.9091 13.7441 38.3638 13.5304 37.8573 13.0839C37.3608 12.6375 37.1174 12.0161 37.1174 11.2393C37.1174 10.4625 37.3608 9.82204 37.8573 9.37502H37.8585Z"
        fill="#0D1B3B"
      />
      <path
        d="M46.4679 5.77318H45.0756V7.56901H44.0923V8.7827H45.0655V13.4428C45.0655 14.4523 45.6304 14.9863 46.6911 14.9863C47.0905 14.9863 47.4798 14.9089 47.8494 14.7434L47.9369 14.7143V13.4714L47.7328 13.5589C47.4601 13.6654 47.2167 13.7142 46.9732 13.7142C46.603 13.7142 46.4476 13.5589 46.4476 13.1898V8.79163H47.947V7.56842H46.4673V5.77258L46.4679 5.77318Z"
        fill="#0D1B3B"
      />
      <path d="M51.2905 7.56958H49.8792V14.8898H51.2905V7.56958Z" fill="#0D1B3B" />
      <path d="M51.2905 5.03564H49.8792V6.41422H51.2905V5.03564Z" fill="#0D1B3B" />
      <path
        d="M58.2649 7.67605C56.9994 7.18082 55.6072 7.4332 54.4393 8.36534C53.6411 9.01594 53.1738 10.0933 53.1738 11.2392C53.1738 11.8511 53.2905 12.404 53.5244 12.8993C54.2738 14.4719 55.8411 15.035 56.9512 15.035C57.8953 15.035 58.7619 14.6856 59.531 14.0058C60.3292 13.3356 60.7286 12.404 60.7286 11.229C60.7381 9.21951 59.4048 8.05403 58.2655 7.67546L58.2649 7.67605ZM58.5959 13.0933C58.0994 13.5397 57.5637 13.7534 56.9506 13.7534C56.3375 13.7534 55.7923 13.5397 55.2857 13.0933C54.7893 12.6469 54.5459 12.0255 54.5459 11.2487C54.5459 10.4719 54.7893 9.83141 55.2857 9.38439C55.7917 8.93796 56.3566 8.70463 56.9506 8.70463C57.5447 8.70463 58.0994 8.93736 58.5959 9.38439C59.0923 9.84094 59.3357 10.4427 59.3357 11.2487C59.3357 12.0546 59.0923 12.6469 58.5959 13.0933Z"
        fill="#0D1B3B"
      />
      <path
        d="M68.3929 8.24883C67.8577 7.69526 67.2054 7.42383 66.4071 7.42383C65.6673 7.42383 64.9179 7.70538 64.4405 8.17145C64.2655 8.35597 64.1095 8.5405 63.9929 8.70538V7.56966H62.5815V14.8899H63.9929V10.4822C64.0512 10.0548 64.2559 9.66669 64.6256 9.27859C64.9958 8.88038 65.4726 8.68633 66.0565 8.68633C67.147 8.68633 67.8184 9.453 67.8184 10.6768V14.8905H69.2298L69.2202 10.6578C69.2202 9.5899 68.9381 8.77442 68.3929 8.25002V8.24883Z"
        fill="#0D1B3B"
      />
      <path
        d="M77.246 8.4237C76.5061 7.78322 75.6103 7.43323 74.715 7.43323C73.7513 7.43323 72.7585 7.85049 72.0769 8.53977C71.3859 9.22906 71.0156 10.1707 71.0156 11.2487C71.0156 12.7338 71.7942 14.0255 73.0406 14.6368C73.6055 14.9088 74.199 15.035 74.871 15.035C76.1365 15.035 77.0906 14.6469 77.7912 13.8505L77.8692 13.7439L77.0126 12.9576L76.915 13.0547C76.399 13.5499 75.7371 13.7927 74.9001 13.7927C73.5859 13.7927 72.6418 13.0255 72.4371 11.7928H78.3269V11.657C78.4537 10.2981 78.0448 9.09394 77.2466 8.4243L77.246 8.4237ZM72.4174 10.6666C72.5341 10.0743 72.7972 9.5987 73.2061 9.26834C73.6734 8.88025 74.1698 8.68561 74.7055 8.68561C75.2412 8.68561 75.7567 8.87965 76.2436 9.26834C76.6722 9.60822 76.9251 10.0743 77.0126 10.6666H72.418H72.4174Z"
        fill="#0D1B3B"
      />
      <path
        d="M84.4929 7.5017C84.3953 7.46301 84.2495 7.46301 84.1132 7.46301C83.4221 7.46301 82.8281 7.65706 82.3025 8.05528C82.0203 8.28861 81.7965 8.54099 81.631 8.82253V7.57015H80.2197V14.8904H81.631L81.6406 11.2106C81.7281 10.4827 82.0007 9.89039 82.4679 9.45349C82.9352 9.01658 83.4804 8.80289 84.1328 8.80289C84.24 8.80289 84.3179 8.81242 84.3858 8.82253L84.6001 8.8517V7.53146L84.4929 7.5023V7.5017Z"
        fill="#0D1B3B"
      />
    </svg>
  );
};
