import { USER_PERMISSION } from '@novaera/actioner-service';
import { NvButton, NvDialogModal, NvTypography } from '@novaera/core';
import { getSubdomain } from '@novaera/utils';
import { useState } from 'react';
import { UserPermissionBoundary } from '../../../../../../user-permission-boundary';

export const ConnectChatToolButtonBase: React.FC<{
  ButtonBase: React.FC<{ onClick: () => void }>;
  type: 'slack' | 'ms-teams';
  onClick: () => void;
}> = ({ type, ButtonBase, onClick }) => {
  const displayName = type === 'slack' ? 'Slack' : 'MS Teams';
  const permission =
    type === 'slack' ? USER_PERMISSION.WORKSPACE_CONNECT_SLACK : USER_PERMISSION.WORKSPACE_CONNECT_MS_TEAMS;
  const [isContactWithAdminModalOpen, setIsContactWithAdminModalOpen] = useState<boolean>(false);

  return (
    <>
      <UserPermissionBoundary
        permission={permission}
        Fallback={
          <ButtonBase
            onClick={() => {
              setIsContactWithAdminModalOpen(true);
            }}
          />
        }
      >
        <ButtonBase onClick={onClick} />
      </UserPermissionBoundary>
      {isContactWithAdminModalOpen && (
        <NvDialogModal
          onCloseButtonClicked={() => {
            setIsContactWithAdminModalOpen(false);
          }}
          open={isContactWithAdminModalOpen}
          modalIcon="power"
          Header="Connect"
          Body={
            <NvTypography>
              <b>{getSubdomain() && getSubdomain().length > 0 ? getSubdomain() : 'This workspace'}</b> has not connected
              to any {displayName} workspaces yet. Contact with your workspace admin to set up a {displayName}{' '}
              connection for this workspace.
            </NvTypography>
          }
          Footer={
            <NvButton
              color="secondary"
              onClick={() => {
                setIsContactWithAdminModalOpen(false);
              }}
            >
              Cancel
            </NvButton>
          }
        />
      )}
    </>
  );
};
