import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvCustomBranchesIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9876 3.4175C11.4376 3.4175 10.9876 3.8675 10.9876 4.4175L10.9876 7.47822C10.2383 10.0115 7.91859 10.8838 5.83515 10.4175L6.71515 9.53752C7.10515 9.14751 7.10515 8.51751 6.71515 8.12752C6.32515 7.73751 5.69515 7.73751 5.30514 8.12752L2.71514 10.7175C2.32514 11.1075 2.32514 11.7375 2.71514 12.1275L5.30514 14.7175C5.39765 14.8102 5.50754 14.8838 5.62851 14.934C5.74949 14.9842 5.87917 15.01 6.01015 15.01C6.14112 15.01 6.2708 14.9842 6.39178 14.934C6.51275 14.8838 6.62264 14.8102 6.71515 14.7175C7.10515 14.3275 7.10515 13.6975 6.71515 13.3075L5.83515 12.4175C7.33902 12.7462 9.54715 12.3408 10.9876 11.073L10.9876 17.57L10.1251 16.7075C9.92677 16.5091 9.66629 16.4117 9.40688 16.4151C9.14714 16.4114 8.88623 16.5089 8.68758 16.7075C8.29758 17.0975 8.29758 17.7275 8.68758 18.1175L11.2776 20.7075C11.4761 20.906 11.7368 21.0035 11.9964 20.9999C12.001 21 12.0056 21 12.0101 21C12.1411 21 12.2708 20.9742 12.3918 20.924C12.5128 20.8738 12.6226 20.8002 12.7151 20.7075L15.2951 18.1275C15.6851 17.7375 15.6851 17.1075 15.2951 16.7175C15.2278 16.6502 15.1533 16.5944 15.0741 16.5504C14.6923 16.3275 14.1952 16.3798 13.8676 16.7075L13.0051 17.5699L13.0051 11.073C14.4456 12.3408 16.6537 12.7462 18.1576 12.4175L17.2776 13.3075C16.8876 13.6975 16.8876 14.3275 17.2776 14.7175C17.6676 15.1075 18.2976 15.1075 18.6876 14.7175L21.2776 12.1275C21.3703 12.035 21.4439 11.9251 21.494 11.8041C21.5442 11.6832 21.5701 11.5535 21.5701 11.4225C21.5701 11.2915 21.5442 11.1618 21.494 11.0409C21.4439 10.9199 21.3703 10.81 21.2776 10.7175L18.6876 8.1275C18.2976 7.7375 17.6676 7.7375 17.2776 8.1275C16.8876 8.5175 16.8876 9.1475 17.2776 9.5375L18.1576 10.4175C16.0741 10.8838 13.7545 10.0114 13.0051 7.4782L13.0051 4.41752C13.0051 3.86752 12.5551 3.41752 12.0051 3.41752C12.0025 3.41752 11.9999 3.41753 11.9972 3.41755C11.994 3.41752 11.9908 3.4175 11.9876 3.4175Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
