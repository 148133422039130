import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { useEventRuleCacheUpdateHelper } from '../utils';
import {
  UpdateIntegrationEventRuleSamplePayloadParams,
  UpdateIntegrationEventRuleSamplePayloadResponse,
} from './types';

const updateIntegrationEventRuleSamplePayload = async (params: UpdateIntegrationEventRuleSamplePayloadParams) => {
  const { integrationId, id, sampleEventPayload } = params;
  const result = await NvAxios.post<UpdateIntegrationEventRuleSamplePayloadResponse>(
    `${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}/sample-event-payload`,
    { sampleEventPayload }
  );
  return result?.data;
};

export const useUpdateIntegrationEventRuleSamplePayload = () => {
  const { updateDraftEventRuleCache, setEventRuleAsDraftFromListCache } = useEventRuleCacheUpdateHelper();
  return useMutation(updateIntegrationEventRuleSamplePayload, {
    onSuccess: (draftEventRule, { integrationId, id }) => {
      updateDraftEventRuleCache({ eventRule: draftEventRule, integrationId, id });
      setEventRuleAsDraftFromListCache({ eventRule: draftEventRule, integrationId });
    },
  });
};
