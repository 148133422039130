import {
  AssistantNode,
  BooleanValue,
  ObjectValue,
  StringValue,
  ValueTypes,
  useUpdateNode,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvButton,
  NvChatIcon,
  NvCloseIcon,
  NvCustomTextSnippetIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFingerPrintIcon,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvShortTextIcon,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { StartRunComponent } from './start-run-component';
import { AssistantPropertiesProps } from './types';

export const AssistantProperties: FC<AssistantPropertiesProps> = ({ assistantNode, onCloseClicked }) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const initialWorkflowResolverNode = useMemo<AssistantNode>(
    () => ({
      errorHandlingStrategy: { type: 'simple', continueOnError: false },
      ...assistantNode,
    }),
    [assistantNode]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: assistantNode.alias,
          payload: { ...(assistantNode as AssistantNode), name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(assistantNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [assistantNode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const handleOnChange = useCallback(
    (values: AssistantNode) => {
      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: assistantNode.alias,
        payload: { ...values },
      });
    },
    [updateNodeDetail, userAppId, assistantNode, workflowId]
  );

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        hasEditRight={hasEditPermission}
        title={assistantNode.name}
        type={NodeType.ASSISTANT}
        onTitleChange={async (title) => {
          if (title) {
            handleSaveName(title);
          }
        }}
        validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'small', color: 'secondary' },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    deleteNodeAndUpdateGraph({ nodeId: assistantNode.alias });
                  },
                },
              ]}
            />

            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <NvForm<AssistantNode>
        onSubmit={noop}
        onChange={({ values }) => {
          handleOnChange(values);
        }}
        initialValues={initialWorkflowResolverNode}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        mutators={{
          ...arrayMutators,
        }}
      >
        <PropertyPanelSimpleSection>
          <NvFlex gap="16px">
            <NvTypography variant="body2">
              Assist users by running various workflows and answering questions using AI.
            </NvTypography>
            {/* <SectionMessage
              message={
                'An AI assistant has not been created for your app. You need to first create an AI assistant for your app.'
              }
              variant={'warning'}
            /> */}
          </NvFlex>
        </PropertyPanelSimpleSection>
        <PropertyPanelSection title={'Conversation ID'} icon={<NvFingerPrintIcon />}>
          <NvField
            name={'conversationIdentifier'}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => (value ? { type: ValueTypes.STRING, value: value } : undefined)}
            component={<CodeInput context={workflowCodeInputContext} placeholder="{{...}}" />}
            formControlStyle={{ width: '100%' }}
          />
        </PropertyPanelSection>
        <PropertyPanelSection title={'Message'} icon={<NvShortTextIcon />}>
          <NvField
            name={'content'}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => (value ? { type: ValueTypes.STRING, value: value } : undefined)}
            component={<CodeInput context={workflowCodeInputContext} placeholder="{{...}}" />}
            formControlStyle={{ width: '100%' }}
          />
        </PropertyPanelSection>
        <PropertyPanelSection
          title={'Metadata'}
          icon={<NvCustomTextSnippetIcon />}
          tooltip="You can add a set of key-value pairs to provide a structured format for storing additional information about the object."
        >
          <NvField
            name={'metadata'}
            format={(value: ObjectValue) => value?.codeTemplate}
            parse={(value) => ({
              type: ValueTypes.OBJECT,
              displayValue: '',
              codeTemplate: value,
            })}
            component={<CodeInput context={workflowCodeInputContext} placeholder="{{...}}" />}
            formControlStyle={{ width: '100%' }}
          />
        </PropertyPanelSection>
        <PropertyPanelSection title={"Generate AI assistant's message"} icon={<NvChatIcon />}>
          <NvFlex gap="10px">
            <NvTypography variant="body2">
              If you disable this option, messages will be placed into the context but will not receive a response.
            </NvTypography>
            <NvField<BooleanValue>
              direction="label-on-top"
              name="startRun"
              formControlStyle={{ width: '100%' }}
              component={({ value, onChange }) => (
                <StartRunComponent value={value} onChange={onChange} context={workflowCodeInputContext} />
              )}
            />
          </NvFlex>
        </PropertyPanelSection>

        {/* <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <OutputItem content={ASSISTANT_SAMPLE_RESPONSE} isTestResultFailed={false} />
        </PropertyPanelSimpleSection> */}

        <NvField
          name="errorHandlingStrategy"
          formControlStyle={{ width: '100%' }}
          component={<ErrorHandlingStrategyPanel />}
        />
      </NvForm>
    </NvFlex>
  );
};
