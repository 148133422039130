import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PropertyPanelSimpleSectionContainer = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
`;
