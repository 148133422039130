import classNames from 'classnames';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/addon/scroll/simplescrollbars.js';
import 'codemirror/lib/codemirror.css';

import React from 'react';
import { NvConditionalWrap } from '../../conditional-wrap';
import '../autocomplete';
import { showHintFn } from '../autocomplete';
import { CodeInputExpander } from '../code-input-expander';
import { InformationBox } from '../information-box';
import '../mode';
import { NvCodeMirror, NvCodeMirrorStartAdornmentBox, NvCodeMirrorWrapper } from '../styled';
import { CodeInputProps } from '../types';
import { shouldRenderCodeInput } from '../utils';
import { useCodeInputForExpression } from './use-code-input-for-expression';

const UnMemoizedCodeInput = ({
  context,
  value,
  onChange,
  expectedType,
  startAdornment,
  startAdornmentOffset,
  onResultCalculated,
  onBlur,
  onFocus,
  initialHeight,
  growingHeight,
  isDisabled,
  placeholder,
  matchArrayItemTypes,
  maxHeight,
  className,
  isExpanded,
}: CodeInputProps & { isExpanded?: boolean }) => {
  const {
    handleOnFocus,
    error,
    calculatedResult,
    informationBoxId,
    informationBoxWidth,
    handleEditorChange,
    handleEditorDidMount,
    handleEditorKeyUp,
    handleEditorUpdate,
    handleOnBlur,
    isFocused,
  } = useCodeInputForExpression({
    context,
    onResultCalculated,
    expectedType,
    onBlur,
    onChange,
    onFocus,
    value,
    matchArrayItemTypes,
  });

  return (
    <NvCodeMirrorWrapper
      className={classNames({
        [`${className}`]: !!className,
        'expanded-mode': isExpanded,
      })}
    >
      {startAdornment && <NvCodeMirrorStartAdornmentBox>{startAdornment}</NvCodeMirrorStartAdornmentBox>}
      <NvCodeMirror
        maxHeight={maxHeight}
        initialHeight={initialHeight}
        growingHeight={growingHeight}
        startAdornmentOffset={startAdornmentOffset}
        className={classNames({
          [`${className}`]: !!className,
          'expanded-mode': isExpanded,
          NvCodeInput: true,
          error: error !== undefined,
        })}
        value={value ?? ''}
        onChange={handleEditorChange}
        onUpdate={handleEditorUpdate}
        onKeyUp={handleEditorKeyUp}
        editorDidMount={handleEditorDidMount}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        options={{
          scrollbarStyle: 'overlay',
          readOnly: isDisabled,
          mode: {
            name: 'novaera',
            base: 'javascript',
          },
          lineWrapping: true,
          autoCloseBrackets: true,
          placeholder,
          showHint: true,
          extraKeys: {
            'Ctrl-Space': (cm) => {
              showHintFn(cm);
            },
          },
          autofocus: isExpanded,
          lineNumbers: isExpanded,
          lint: false,
        }}
      />

      <InformationBox
        calculatedResult={calculatedResult}
        informationBoxId={informationBoxId}
        informationBoxWidth={informationBoxWidth}
        isFocused={isFocused || !!isExpanded}
        isExpandedMode={!!isExpanded}
      />
    </NvCodeMirrorWrapper>
  );
};

const CodeInputForExpressionWithExpander = ({ disableExpandMode, ...props }: CodeInputProps) => (
  <NvConditionalWrap
    condition={!disableExpandMode}
    wrap={(children) => <CodeInputExpander>{children}</CodeInputExpander>}
  >
    <UnMemoizedCodeInput {...props} />
  </NvConditionalWrap>
);

CodeInputForExpressionWithExpander.displayName = 'Code input for expression';

export const CodeInputForExpression = React.memo(CodeInputForExpressionWithExpander, (prevProps, nextProps) =>
  shouldRenderCodeInput(prevProps, nextProps)
);
