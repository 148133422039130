import { styled } from '@novaera/theme-provider';
import { NvCollapse } from '../collapse';
import { NvFlex } from '../flex';

export const MarkdownCollapseWrapper = styled(NvCollapse)`
  position: relative;
  max-height: 300px;

  &.expand {
    max-height: none;
  }

  &.collapse {
    --mask-image-content: linear-gradient(to top, transparent, black 100px, black 0);
    mask-image: linear-gradient(to top, transparent, black 100px, black 0);
    -webkit-mask-image: linear-gradient(to top, transparent, black 100px, black 0);
  }

  &.loading {
    opacity: 0;
    height: 0;
  }
`;

export const ShowMoreWrapper = styled(NvFlex)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.nv_main[40]};

  &:hover {
    color: ${({ theme }) => theme.palette.nv_main[50]};
  }
`;
