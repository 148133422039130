import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { GetUserAppResponse } from '../types';
import { UpdateUserAppDescriptionParams, UpdateUserAppDescriptionResponse } from './types';

const updateUserAppDescription = async ({ appId, description }: UpdateUserAppDescriptionParams) => {
  const result = await NvAxios.patch<UpdateUserAppDescriptionResponse>(`${USER_APP_ROOT_PATH}/${appId}/description`, {
    description,
  });
  return result?.data;
};

export const useUpdateUserAppDescription = () => {
  const cache = useQueryClient();
  return useMutation(updateUserAppDescription, {
    onSuccess: (response, { appId }) => {
      cache.setQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId), response);
    },
  });
};
