import {
  EventRule,
  USER_PERMISSION,
  useDeleteIntegrationEventRule,
  useGetIntegrationEventRule,
  usePersistIntegrationEventRule,
  useRollbackIntegrationEventRule,
  useUpdateIntegrationEventRuleAction,
  useUpdateIntegrationEventRuleFilter,
  useUpdateIntegrationEventRuleMetadata,
  useUpdateIntegrationEventRuleSamplePayload,
} from '@novaera/actioner-service';
import { useConfirmDialog } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { FormApi } from 'final-form';
import { useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_ACTION_BUTTON_TYPES } from '../../../../../components';
import { useUserPermissions } from '../../../../../user-permission-boundary/use-user-permission';
import { useResetAndUpdateForm } from '../../../../../utils/hooks';
import { INTEGRATION_EVENTS } from '../../../../constants';

export const useEventDetailController = () => {
  const navigate = useNavigate();
  const { integrationId, eventId } = useParams();
  const { checkPermission } = useUserPermissions();
  const { openConfirm } = useConfirmDialog();
  const { resetAndUpdateForm } = useResetAndUpdateForm<Omit<EventRule, 'state'>>();
  const { integrationEventRule, persistedIntegrationEventRule, isLoading } = useGetIntegrationEventRule({
    integrationId,
    id: eventId,
    includeDraft: true,
  });
  const { mutate: updateIntegrationEventRuleMetadata, isLoading: isUpdatingIntegrationEventRuleMetadata } =
    useUpdateIntegrationEventRuleMetadata();
  const { mutate: updateIntegrationEventRuleSamplePayload } = useUpdateIntegrationEventRuleSamplePayload();
  const { mutate: updateIntegrationEventRuleFilter } = useUpdateIntegrationEventRuleFilter();
  const { mutate: updateIntegrationEventRuleAction } = useUpdateIntegrationEventRuleAction();
  const { mutate: persistIntegrationEventRule } = usePersistIntegrationEventRule();
  const { mutate: rollbackIntegrationEventRule } = useRollbackIntegrationEventRule();
  const { mutate: deleteIntegrationEventRule } = useDeleteIntegrationEventRule();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const initialValues = useMemo(() => {
    if (integrationEventRule) {
      const { state, ...rest } = integrationEventRule;
      return rest;
    } else {
      return;
    }
  }, [integrationEventRule]);
  const handleOnChange = useCallback(
    ({
      dirtyFields,
      values,
      valid,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      if (valid) {
        if (dirtyFields['sampleEventPayload.payload']) {
          updateIntegrationEventRuleSamplePayload({
            integrationId,
            id: eventId,
            sampleEventPayload: values['sampleEventPayload'],
          });
        }
        if (dirtyFields['eventFilter.condition']) {
          updateIntegrationEventRuleFilter({
            integrationId,
            id: eventId,
            eventFilter: values['eventFilter'],
          });
        }
        if (dirtyFields['transformerAction']) {
          updateIntegrationEventRuleAction({
            integrationId,
            id: eventId,
            transformerAction: values['transformerAction'].actionId ? values['transformerAction'] : null,
          });
        }
      }
    },
    [
      eventId,
      integrationId,
      updateIntegrationEventRuleAction,
      updateIntegrationEventRuleFilter,
      updateIntegrationEventRuleSamplePayload,
    ]
  );

  const debouncedHandleOnChangeEventHandler = useDebouncedCallback(handleOnChange, 500);

  const handleUpdateEventRuleMetadata = ({ name, description }: Pick<EventRule, 'name' | 'description'>) => {
    updateIntegrationEventRuleMetadata({ integrationId, id: eventId, name, description });
  };

  const handleEventRulePersist = (
    value: Omit<EventRule, 'state'>,
    formApi: FormApi<Omit<EventRule, 'state'>, Partial<Omit<EventRule, 'state'>>>
  ) => {
    return new Promise<void>((resolve) => {
      persistIntegrationEventRule(
        { integrationId, id: eventId },

        {
          onSuccess: () => {
            resetAndUpdateForm(formApi);
          },

          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  const handleEventRuleDiscard = async (
    formApi: FormApi<Omit<EventRule, 'state'>, Partial<Omit<EventRule, 'state'>>>
  ) => {
    await new Promise<void>((resolve) => {
      rollbackIntegrationEventRule(
        { integrationId, id: eventId },
        {
          onSuccess: () => {
            resetAndUpdateForm(formApi);
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  const handleActionButtonClicked = (type: DEFAULT_ACTION_BUTTON_TYPES, value: Omit<EventRule, 'state'>) => {
    if (type === 'delete') {
      openConfirm({
        title: `Delete ${value.name} ?`,
        message:
          'Deleting the trigger is a permanent operation that can affect any associated workflows. Are you sure you want to proceed with the deletion?',
        onConfirm: () => {
          deleteIntegrationEventRule(
            { integrationId, id: eventId },
            {
              onSuccess: () => {
                navigate(INTEGRATION_EVENTS(integrationId));
              },
            }
          );
        },
      });
    }
  };

  return {
    eventId,
    integrationEventRule,
    persistedIntegrationEventRule,
    isLoading,
    initialValues,
    isUpdatingIntegrationEventRuleMetadata,
    handleUpdateEventRuleMetadata,
    debouncedHandleOnChangeEventHandler,
    handleEventRulePersist,
    handleEventRuleDiscard,
    handleActionButtonClicked,
    hasIntegrationUpdateRight,
  };
};
