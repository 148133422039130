import React from 'react';
import { StyledTypography } from './styled';
import { NvTypographyProps } from './types';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    body3: true;
    c1: true;
    c2: true;
    c3: true;
  }
}

export const NvTypography = React.forwardRef<HTMLSpanElement, NvTypographyProps>(
  ({ textColor = 'main', ...props }, ref) => <StyledTypography {...props} textColor={textColor} ref={ref} />
);
