import { NvAxios } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { WORKFLOW_HISTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_WORKFLOW_HISTORIES } from '../keys';
import { GetWorkflowHistoriesParams, GetWorkflowHistoriesResponse } from './types';

const getWorkflowHistories = async ({
  appId,
  workflowId,
  pageParam,
  limit = 100,
  ...params
}: GetWorkflowHistoriesParams) => {
  const timeWindow = params['time-window'];
  const statuses = params['statuses'];
  const result = await NvAxios.get<GetWorkflowHistoriesResponse>(
    `${WORKFLOW_HISTORY_ROOT_PATH({ appId, workflowId })}`,
    {
      params: {
        limit,
        ...(timeWindow ? { 'time-window': timeWindow } : {}),
        ...(statuses && statuses.length > 0 ? { statuses: statuses.join(',') } : {}),
        ...(pageParam ? { next: pageParam } : {}),
      },
    }
  );
  return result?.data;
};

export const useGetWorkflowHistories = ({ appId, workflowId, ...params }: GetWorkflowHistoriesParams) => {
  return useInfiniteQuery<GetWorkflowHistoriesResponse>(
    QUERY_KEYS_WORKFLOW_HISTORIES.list({ appId, workflowId, ...params }),
    (props) => getWorkflowHistories({ appId, workflowId, ...params, ...props }),
    {
      enabled: !!appId && !!workflowId,
      getNextPageParam: (lastPage) => lastPage?.next,
    }
  );
};
