import { NvAxios, useQuery } from '@novaera/core';
import { BILLING_ROOT_PATH } from '../constants';
import { QUERY_KEYS_BILLING } from '../keys';
import { WorkspaceBillingDetails } from '../types';

const getBilling = async () => {
  const result = await NvAxios.get<WorkspaceBillingDetails>(`${BILLING_ROOT_PATH}`);
  return result?.data;
};

export const useGetBilling = () => {
  return useQuery(QUERY_KEYS_BILLING.all, () => getBilling(), {});
};
