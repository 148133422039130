import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { Workflow } from '../../types';

import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS, QUERY_KEYS_WORKFLOWS_SEARCH } from '../keys';
import { UseGetWorkflowResponse } from '../use-get-workflow/types';

import { SearchWorkflowResponse } from '../use-search-workflows/types';
import { UpdateWorkflowMetadataParams } from './types';

const updateMetaDataService = async ({ appId, workflowId, payload }: UpdateWorkflowMetadataParams) => {
  const result = await NvAxios.patch<Workflow>(`${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/metadata`, {
    ...payload,
  });
  return result?.data;
};

export const useUpdateWorkflowMetadata = () => {
  const cache = useQueryClient();

  return useMutation(updateMetaDataService, {
    onSuccess: (response, { appId }) => {
      const updatedValues: UpdateWorkflowMetadataParams['payload'] = {
        name: response.name,
        description: response.description,
        tags: response.tags,
        searchInvisibleByDefault: response.searchInvisibleByDefault,
      };

      cache.setQueryData<UseGetWorkflowResponse>(
        QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId: response.id }),
        (old) => {
          const result: UseGetWorkflowResponse = {
            saved: old?.saved ? { ...old.saved, ...updatedValues } : { ...response },
          };
          if (old?.draft) {
            result.draft = { ...old?.draft, ...updatedValues };
          }
          return result;
        }
      );

      const queryCached = cache.getQueryCache().findAll({
        queryKey: QUERY_KEYS_WORKFLOWS_SEARCH.all,
      });
      queryCached.forEach((query) => {
        cache.setQueryData<InfiniteData<SearchWorkflowResponse>>(query.queryKey, (old) => {
          if (old) {
            return {
              ...old,
              pages: old.pages.map(({ workflows, ...others }) => ({
                ...others,
                workflows: workflows.map((workflow) => {
                  if (workflow.id === response.id) {
                    return {
                      ...workflow,
                      ...updatedValues,
                    };
                  }
                  return workflow;
                }),
              })),
            };
          }
          return old;
        });
      });
    },
  });
};
