import { isArray } from 'lodash';
import { CodeInputExpectedType, CodeInputProps } from './types';

export const shouldRenderCodeInput = (prevProps: CodeInputProps, nextProps: CodeInputProps) =>
  JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value) &&
  prevProps.isDisabled === nextProps.isDisabled &&
  prevProps.startAdornment === nextProps.startAdornment &&
  prevProps.matchArrayItemTypes === nextProps.matchArrayItemTypes &&
  prevProps.expectedType === nextProps.expectedType &&
  prevProps.onChange === nextProps.onChange;

export const isTypeMatched = (type: CodeInputExpectedType, result: unknown) => {
  if (type === 'array' && isArray(result)) {
    return true;
  }

  if (isArray(result) && type === 'object') {
    return false;
  }

  return typeof result === type;
};

export const isJsError = (error: unknown): error is Error => {
  return error instanceof Error;
};
