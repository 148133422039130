import { NvAxios, useQuery } from '@novaera/core';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';
import { UserConnectionsResponse } from './types';

const getUserConnections = async () => {
  const result = await NvAxios.get<UserConnectionsResponse>(`${CONNECTION_ROOT_PATH}`);
  return result?.data;
};

export const useGetUserConnectionsService = () => {
  return useQuery<UserConnectionsResponse>(QUERY_KEYS_CONNECTION.list(), () => getUserConnections(), {
    // there are modal that open on top of the connections page
    // when the oauth flow is started and client returns to the connections page
    // that refetch is causing the modal to close
    refetchOnWindowFocus: false,
  });
};
