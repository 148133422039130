import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemaPreviewResponse } from '../use-get-app-schema-preview/types';
import { UpdateAppSchemaParams, UpdateAppSchemaResponse } from './types';

const updateAppSchema = async (params: UpdateAppSchemaParams) => {
  const { schemaId, ...restOfParams } = params;
  const result = await NvAxios.put<UpdateAppSchemaResponse>(
    `${APP_DIRECTORY_ROOT_PATH}/schemas/${schemaId}`,
    restOfParams
  );
  return result?.data;
};

export const useUpdateAppSchema = () => {
  const cache = useQueryClient();

  return useMutation(updateAppSchema, {
    onSuccess: (schema) => {
      const { schemaId, version: newVersion } = schema;
      const oldPreview = cache.getQueryData<GetAppSchemaPreviewResponse>(
        QUERY_KEYS_APP_DIRECTORY.previewDetail(schemaId)
      );
      cache.setQueryData(QUERY_KEYS_APP_DIRECTORY.previewDetail(schemaId), {
        ...oldPreview,
        latestVersion: newVersion,
      });
      cache.invalidateQueries(QUERY_KEYS_APP_DIRECTORY.lists());
      return cache.refetchQueries(QUERY_KEYS_APP_DIRECTORY.detail(schemaId));
    },
  });
};
