import {
  Connection,
  useListConnectionMappingService,
  useUpdateConnectionMappingService,
} from '@novaera/actioner-service';
import { OAuth2PromiseResultType } from '../../../../../../integrations/components/connect-modal/oauth2-connection/controllers/use-oauth2-connection/types';
import { isOAuth2PromiseResult } from '../../../../../../integrations/components/connect-modal/types';
import { useGetWorkflowQueryParams } from '../../../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useAppConnectionController = () => {
  const { userAppId } = useGetWorkflowQueryParams();

  const { data, isLoading } = useListConnectionMappingService({
    appId: userAppId,
  });

  const { mutate } = useUpdateConnectionMappingService();

  const handleFormSubmit = (connection: Connection | OAuth2PromiseResultType | void) => {
    if (!connection) return;

    if (isOAuth2PromiseResult(connection)) {
      if (connection.type === 'success' && connection.schemaId && connection.name) {
        mutate({
          appId: userAppId,
          schemaId: connection.schemaId,
          payload: {
            connectionId: connection.connectionId,
          },
          connectionName: connection.name,
        });
      }
    } else {
      mutate({
        appId: userAppId,
        schemaId: connection.schemaId,
        payload: {
          connectionId: connection.id,
        },
        connectionName: connection.name,
      });
    }
  };

  return {
    connectionMappings: data,
    isLoading,
    onFormSubmitFinished: handleFormSubmit,
  };
};
