import { NvAxios, useQuery } from '@novaera/core';
import { GetAnalyticsQueryTypesResponse } from './types';

const getAnalyticsQueryTypes = async () => {
  const result = await NvAxios.get<GetAnalyticsQueryTypesResponse>('/admin/analytics/queries/types');
  return result?.data;
};

export const useGetAnalyticsQueryTypes = () => {
  return useQuery(['analytics-query-types'], () => getAnalyticsQueryTypes());
};
