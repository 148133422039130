import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';

import { DeleteUserAppAccessSettingsParams, DeleteUserAppAccessSettingsResponse } from './types';

const deleteUserAppAccessSettings = async ({ appId, principals }: DeleteUserAppAccessSettingsParams) => {
  const result = await NvAxios.post<DeleteUserAppAccessSettingsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/access-settings/batch-delete`,
    { principals }
  );
  return result?.data;
};

export const useDeleteUserAppAccessSettings = () => {
  const cache = useQueryClient();
  return useMutation(deleteUserAppAccessSettings, {
    onSuccess: (updatedPackage) => {
      cache.setQueryData(QUERY_KEYS_USER_APP.detail(updatedPackage.id), updatedPackage);
    },
  });
};
