export const LIST_FILE_EXAMPLE_PAYLOAD = {
  prefix: 'folder',
  files: [
    {
      contentType: 'image/gif',
      name: 'giphy.gif',
      key: 'folder/folder2/key1',
      accessibility: 'private',
    },
    {
      contentType: 'image/gif',
      name: 'giphy.gif',
      key: 'folder/key1',
      accessibility: 'private',
    },
  ],
  next: 'next',
} as const;
