import { APP_PERMISSION } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvArticleIcon,
  NvButton,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvDivider,
  NvFlex,
  NvSearchEmptyState,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
  useNvDialogModalState,
} from '@novaera/core';
import { useNavigate } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { BaseItem, SimpleSearchInput } from '../../../../components';
import { EmptyState } from '../../../../components/empty-state';
import { EmptyStates } from '../../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { USER_APP_DOCUMENT_DETAIL } from '../../constants';
import { ModalBody } from '../modal-body';
import { useDocumentListController } from './controllers/use-documen-list';
import { DocumentFormValues } from './types';

export const DocumentList = () => {
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const {
    userAppId,
    createDocument,
    docs,
    hasNextPage,
    isFetchingNextPage,
    deleteDocument,
    onSearchKeyChange,
    showEmptyState,
    showNoSearchResult,
    isLoading,
    isCreateLoading,
    onShowMoreClicked,
    isAppFree,
  } = useDocumentListController();

  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();

  const theme = useTheme();

  const initialValues = useMemo(
    () => ({
      name: '',
      tags: [],
    }),
    []
  );

  return (
    <>
      <NvFlex gap="16px">
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvArticleIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
          <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
            Documentation
          </NvTypography>
        </NvFlex>
        <NvConditionalRender when={showEmptyState}>
          <EmptyState
            variant={EmptyStates.DOCUMENTS}
            CustomButton={
              <FreeAppPermissionBoundary appId={userAppId}>
                <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                  <NvButton
                    startIcon={<NvAddBoxIcon />}
                    color="secondary"
                    onClick={onModalOpenClicked}
                    size="small"
                    sx={{ flex: '0 0 auto' }}
                  >
                    Add document
                  </NvButton>
                </UserAppPermissionBoundary>
              </FreeAppPermissionBoundary>
            }
          />
        </NvConditionalRender>

        <NvConditionalRender when={!showEmptyState}>
          <NvFlex direction="row" alignItems="center" justifyContent="space-between">
            <SimpleSearchInput
              placeholder="Search document"
              onKeywordChanged={(keyword) => {
                onSearchKeyChange(keyword);
              }}
            />
            <FreeAppPermissionBoundary appId={userAppId}>
              <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={onModalOpenClicked}>
                  Add document
                </NvButton>
              </UserAppPermissionBoundary>
            </FreeAppPermissionBoundary>
          </NvFlex>
          <NvFlex gap="8px">
            <NvConditionalRender when={!showNoSearchResult}>
              {docs.map(({ id, name, updatedAt }) => (
                <BaseItem
                  key={`doc-item-${id}`}
                  name={
                    <NvFlex direction="row" alignItems="center" gap="8px" flex="0 1 auto" minWidth={0}>
                      <NvTypography variant="h4" noWrap>
                        {name}
                      </NvTypography>
                      <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                      <NvTypography variant="body2" textColor="secondary" noWrap>
                        {format(new Date(updatedAt), 'PPpp')}
                      </NvTypography>
                    </NvFlex>
                  }
                  to={USER_APP_DOCUMENT_DETAIL(userAppId, id)}
                  {...(isAppFree
                    ? {}
                    : {
                        menuItems: [
                          {
                            name: 'Delete',
                            onClick: () => {
                              openConfirm({
                                title: `Delete ${name}`,
                                message: (
                                  <NvTypography variant="body1">
                                    If you delete this document, any workflows associated with will be impacted. Do you
                                    want to proceed?
                                  </NvTypography>
                                ),
                                onConfirm: () => {
                                  deleteDocument({ appId: userAppId, docId: id });
                                },
                              });
                            },
                            icon: (
                              <NvDeleteOutlineIcon
                                htmlColor={theme.palette.nv_error[40]}
                                sx={{ width: '16px', height: '16px' }}
                              />
                            ),
                          },
                        ],
                      })}
                />
              ))}
              {hasNextPage && (
                <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
                  <NvButton
                    size="small"
                    color="secondary"
                    onClick={onShowMoreClicked}
                    loading={isFetchingNextPage}
                    disabled={isFetchingNextPage}
                  >
                    Show more
                  </NvButton>
                </NvFlex>
              )}
            </NvConditionalRender>
            <NvConditionalRender when={showNoSearchResult}>
              <NvSearchEmptyState text={'No documents found. Try different words or clear search bar.'} />
            </NvConditionalRender>
            <NvConditionalRender when={isLoading}>
              <NvSkeleton width={'100%'} variant="rectangular" height={'48px'} />
              <NvSkeleton width={'100%'} variant="rectangular" height={'48px'} />
              <NvSkeleton width={'100%'} variant="rectangular" height={'48px'} />
            </NvConditionalRender>
          </NvFlex>
        </NvConditionalRender>
      </NvFlex>
      <NvDialogModal<DocumentFormValues>
        formProps={{
          initialValues,
        }}
        onFormSubmit={(values) => {
          createDocument(
            {
              appId: userAppId,
              payload: {
                ...values,
                type: 'markdown',
              },
            },
            {
              onSuccess: (doc) => {
                onModalCloseClicked();
                navigate(USER_APP_DOCUMENT_DETAIL(userAppId, doc.id));
              },
            }
          );
        }}
        maxWidth="sm"
        fullWidth
        open={isOpened}
        onCloseButtonClicked={onModalCloseClicked}
        Header="Add document"
        modalIcon="add"
        Body={<ModalBody />}
        isLoading={isCreateLoading}
      />
    </>
  );
};
