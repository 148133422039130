import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';
import { useEventRuleCacheUpdateHelper } from '../utils';
import { UpdateIntegrationEventRuleActionParams, UpdateIntegrationEventRuleActionResponse } from './types';

const updateIntegrationEventRuleAction = async (params: UpdateIntegrationEventRuleActionParams) => {
  const { integrationId, id, transformerAction } = params;
  const result = await NvAxios.post<UpdateIntegrationEventRuleActionResponse>(
    `${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}/transformer-action`,
    { transformerAction }
  );
  return result?.data;
};

export const useUpdateIntegrationEventRuleAction = () => {
  const { updateDraftEventRuleCache, setEventRuleAsDraftFromListCache } = useEventRuleCacheUpdateHelper();
  return useMutation(updateIntegrationEventRuleAction, {
    onSuccess: (draftEventRule, { integrationId, id }) => {
      updateDraftEventRuleCache({ eventRule: draftEventRule, integrationId, id });
      setEventRuleAsDraftFromListCache({ eventRule: draftEventRule, integrationId });
    },
  });
};
