import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CATALOG_RELATIONSHIP_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_RELATIONSHIP_TYPES } from '../keys';
import { CatalogRelationship } from '../types';
import { SearchCatalogRelationshipResult } from '../use-search-catalog-relationship';

type CreateBatchCatalogRelationshipProps = {
  payload: {
    relationshipDefinitions: Omit<CatalogRelationship, 'id'>[];
    entityTypeId: string;
  };
};

type CreateBatchCatalogRelationshipResult = {
  relationshipDefinitions: CatalogRelationship[];
};

const createBatchCatalogRelationship: (
  params: CreateBatchCatalogRelationshipProps
) => Promise<CreateBatchCatalogRelationshipResult> = async (params) => {
  const result = await NvAxios.post<CreateBatchCatalogRelationshipResult>(
    `${CATALOG_RELATIONSHIP_ROOT_PATH}/batch`,
    params.payload
  );
  return result?.data;
};

export const useBatchCreateCatalogRelationship = () => {
  const cache = useQueryClient();
  return useMutation(createBatchCatalogRelationship, {
    onSuccess: ({ relationshipDefinitions }, { payload }) => {
      cache.setQueryData<SearchCatalogRelationshipResult>(
        QUERY_KEY_CATALOG_RELATIONSHIP_TYPES.list(payload.entityTypeId),
        (oldData) => {
          if (!oldData)
            return {
              relationshipDefinitions: relationshipDefinitions,
            };

          return {
            ...oldData,
            relationshipDefinitions: [...oldData.relationshipDefinitions, ...relationshipDefinitions],
          };
        }
      );
    },
  });
};
