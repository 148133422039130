import { assert } from '@novaera/utils';
import { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
export type InputsProviderContextType = {
  selectedInputParameterId?: string;
  setSelectedInputParameterId: (id?: string) => void;
  emptySelectedInputParameterIdWithDelay: () => void;
  fillSelectedInputParameterIdWithDelay: (id?: string) => void;
  isPanelOpen: boolean;
};

const InputsContext = createContext<InputsProviderContextType | undefined>(undefined);

export const InputsProvider: FC<{
  initialInputParameterId?: string;
  children: ((params: InputsProviderContextType) => ReactNode) | ReactNode;
}> = ({ children, initialInputParameterId }) => {
  const [selectedInputParameterId, setSelectedInputParameterId] = useState<string | undefined>(initialInputParameterId);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const emptySelectedInputParameterIdWithDelay = useCallback(() => {
    setIsPanelOpen(false);
    setTimeout(() => setSelectedInputParameterId(undefined), 300);
  }, []);

  const fillSelectedInputParameterIdWithDelay = useCallback((id?: string) => {
    setSelectedInputParameterId(id);
    setTimeout(() => setIsPanelOpen(true), 300);
  }, []);

  const value = {
    selectedInputParameterId,
    setSelectedInputParameterId,
    fillSelectedInputParameterIdWithDelay,
    emptySelectedInputParameterIdWithDelay,
    isPanelOpen,
  };

  return (
    <InputsContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </InputsContext.Provider>
  );
};

export const useInputsContext = () => {
  const context = useContext(InputsContext);
  assert(!!context, new Error(`useInputsContext should be used within InputsProvider`), 'ERROR');

  return context;
};
