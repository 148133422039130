import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ConfigDetailContainer = styled(NvFlex)`
  height: 100%;
  .MuiFormControl-root {
    height: 100%;
  }
`;

export const ConfigJsonInputWrapper = styled(NvBox)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  overflow: hidden;
  height: 100%;

  .expand-function-view-box {
    transform: scale(1.5);
    right: 16px;
    top: 18px;
  }

  section {
    height: 100% !important;
  }

  .monaco-editor {
    .monaco-scrollable-element {
      .scrollbar {
        .slider {
          border-radius: 4px;
          transition: background-color 200ms ease;
        }
      }
    }
  }
`;
