import { styled } from '@mui/material/styles';

export const TabsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &.medium {
    .MuiTabs-root {
      .MuiTabs-scroller {
        .MuiButtonBase-root {
          padding: 12px 16px 8px 16px;
          line-height: 18px;
        }
      }
    }
  }

  &.small {
    .MuiTabs-root {
      .MuiTabs-scroller {
        .MuiButtonBase-root {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.02em;
          padding: 8px 12px 6px 12px;
          cursor: pointer;
        }
      }
    }
  }

  &.button {
    .MuiTabs-root {
      .MuiTabs-scroller {
        .MuiButtonBase-root {
          padding: 4px 12px;
        }
      }
    }
  }

  .MuiBox-root::after {
    display: none;
  }

  .MuiTabs-root {
    position: relative;
    min-height: unset;

    .MuiTabs-scroller {
      height: inherit;

      .MuiButtonBase-root {
        min-width: unset;
        min-height: unset;
      }

      .MuiTabs-flexContainer {
        padding: 0 16px;
        height: 100%;
        border-bottom: 2px solid ${({ theme }) => theme.palette.nv_neutral[30]};

        .MuiTab-root {
          color: ${({ theme }) => theme.palette.nv_neutral_alpha[600]};

          transition: color 200ms ease;
          &:hover {
            color: ${({ theme }) => theme.palette.nv_main[40]};
          }

          &.Mui-selected {
            color: ${({ theme }) => theme.palette.nv_main[40]};
          }
        }

        .MuiTab-labelIcon {
          min-height: 0;
          .MuiTab-iconWrapper {
            margin-left: 4px;
          }
        }
      }

      .MuiTabs-indicator {
        z-index: 2;
        height: 4px;
        border-radius: 2px 2px 0 0;

        background-color: ${({ theme }) => theme.palette.nv_main[40]};
        &::after {
          display: none;
        }
      }
    }
  }

  & > .MuiBox-root {
    position: relative;

    & > div {
      position: relative;
      z-index: 2;
    }
  }

  & .header-action {
    padding: 0 16px;
    height: 100%;
    border-bottom: 2px solid #eceff3ff;
    justify-content: center;
    align-items: center;
  }
`;

export const DefaultTabWrapper = styled('div')`
  position: relative;

  & > div {
    position: relative;
    z-index: 2;
  }
`;
