import {
  ResponseConfiguration,
  USER_PERMISSION,
  useGetIntegration,
  useGetIntegrationAction,
  useGetIntegrationTestResult,
  useUpdateResponseConfiguration,
} from '@novaera/actioner-service';
import { isAxiosError, useAxiosErrorHandler, useNvDialogModalState, useToast } from '@novaera/core';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';
import { useTestActionContext } from '../../../../../components';
import { useGetIntegrationQueryParams } from '../../../../../controllers/use-get-integration-query-params';
import { useUserPermissions } from '../../../../../user-permission-boundary/use-user-permission';

export const useResponseTab = () => {
  const { checkPermission } = useUserPermissions();
  const hasIntegrationUpdateRight = checkPermission(USER_PERMISSION.INTEGRATION_UPDATE);
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const {
    isOpened: isErrorHandlingModalOpened,
    onModalCloseClicked: onErrorHandlingModalCloseClicked,
    onModalOpenClicked: onErrorHandlingModalOpenClicked,
  } = useNvDialogModalState();
  const { mutateAsync, isLoading: isUpdateResponseLoading } = useUpdateResponseConfiguration();
  const { actionId, integrationId } = useGetIntegrationQueryParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({
    actionId,
    integrationId,
    version: integration?.latestVersion.number,
  });
  const { addToast } = useToast();
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const { testActionParams } = useTestActionContext();
  const { data: integrationTestResult } = useGetIntegrationTestResult({ ...testActionParams });

  const handleSaveResponse = async (value: ResponseConfiguration) => {
    const version = integration?.latestVersion?.number;
    assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');
    try {
      await mutateAsync({ actionId, integrationId, version, payload: { responseConfiguration: value } });
      onModalCloseClicked();
      onErrorHandlingModalCloseClicked();
    } catch (error) {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else {
        addToast('Something went wrong', { variant: 'error' });
      }
    }
  };

  const isSamplePayloadInEditMode = Boolean(data?.responseConfiguration?.exampleResponse);

  const defaultSampleResponseValue = useMemo(() => {
    let result: string | undefined = undefined;
    const request = data?.httpRequestConfigurations?.find(
      (request) => request.id === data.runConfiguration?.runSteps.find((step) => step.type === 'request')?.id
    );
    if (request) {
      result = `{{ request.${request.name}.response }}`;
    }
    return result;
  }, [data?.httpRequestConfigurations, data?.runConfiguration?.runSteps]);

  return {
    isOpened,
    onModalCloseClicked,
    onModalOpenClicked,
    responseConfiguration: data?.responseConfiguration,
    saveResponse: handleSaveResponse,
    isSamplePayloadInEditMode,
    testResult: integrationTestResult?.successResponse || integrationTestResult?.errorContext,
    isTestResultFailed: Boolean(integrationTestResult?.errorContext),
    defaultSampleResponseValue,
    onErrorHandlingModalOpenClicked,
    isErrorHandlingModalOpened,
    onErrorHandlingModalCloseClicked,
    isUpdateResponseLoading,
    hasIntegrationUpdateRight,
  };
};
