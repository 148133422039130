import { Integration } from '@novaera/actioner-service';
import { NvImage, NvRouterLink, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { INTEGRATION_SUMMARY } from '../../constants';
import { IntegrationInitialLogo } from '../integration-initial-logo';
import { CardWrapper } from './styled';

export const CardView: FC<React.PropsWithChildren<Integration>> = ({ id, name, logoUrl }) => {
  return (
    <NvRouterLink to={INTEGRATION_SUMMARY(id)}>
      <CardWrapper>
        <NvImage
          FallBack={<IntegrationInitialLogo name={name ?? ''} size="medium" />}
          src={logoUrl}
          imageShape="square"
          size="small"
          fallbackImageHasBlurEffect
          className="package-image"
        />
        <NvTypography variant="h4">{name}</NvTypography>
      </CardWrapper>
    </NvRouterLink>
  );
};
