import classNames from 'classnames';
import { isArray } from 'lodash';
import React, { useMemo, useState } from 'react';
import { isAxiosError } from '../../axios';
import { NvBox } from '../box';
import { NvButton } from '../button';
import { FormHelperErrorText } from '../field/form-helper-error-text';
import { NvFlex } from '../flex';
import { NvEditIcon } from '../icons';
import { SectionMessage } from '../section-message';
import { EditButton, InlineEditWrapper } from './styled';
import { NvInlineEditProps } from './types';

export const NvInlineEdit = <T,>({
  value,
  editComponent,
  viewComponent,
  onConfirm,
  validate,
  className,
  renderCustomEmptyState,
}: NvInlineEditProps<T>) => {
  const [editMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState<T | undefined>(value);
  const [inProgress, setInProgress] = useState(false);
  const [onChangeError, setOnChangeError] = useState<string>();
  const [error, setError] = useState<string>();
  const isValueEmpty = useMemo(() => {
    if (isArray(value)) {
      return value.length === 0;
    } else {
      return !value;
    }
  }, [value]);

  return renderCustomEmptyState && isValueEmpty && !editMode ? (
    renderCustomEmptyState({
      onClick: () => {
        setEditMode(true);
      },
    })
  ) : (
    <InlineEditWrapper
      className={`${className ?? ''} ${classNames({ 'edit-mode': editMode, 'view-mode': !editMode })}`}
    >
      {editMode ? (
        <NvFlex gap={1}>
          <NvBox className="edit-component-wrapper">
            {typeof editComponent === 'function'
              ? editComponent({ currentValue, setCurrentValue, error })
              : React.cloneElement(editComponent, {
                  value: currentValue,
                  error: error,
                  onChange: (value?: T) => {
                    setCurrentValue(value);
                    return Promise.resolve();
                  },
                })}
            {error && <FormHelperErrorText error={error} />}
          </NvBox>
          {onChangeError && (
            <NvFlex>
              <SectionMessage message={onChangeError} variant="error" onClose={() => setOnChangeError(undefined)} />
            </NvFlex>
          )}

          <NvFlex flexDirection="row" gap={1} className="save">
            <NvButton
              loading={inProgress}
              disabled={inProgress}
              size="small"
              onClick={async () => {
                setOnChangeError(undefined);
                setError(undefined);
                const validationError = validate?.(currentValue);

                if (validationError) {
                  setError(validationError);
                  return;
                }
                setInProgress(true);

                try {
                  await onConfirm?.(currentValue);
                  setEditMode(false);
                } catch (e) {
                  if (isAxiosError(e) && typeof e !== 'string') {
                    setOnChangeError(JSON.stringify(e.response?.data));
                  } else {
                    setOnChangeError(e as string);
                  }
                } finally {
                  setInProgress(false);
                }
              }}
            >
              Save
            </NvButton>
            <NvButton
              variant="contained"
              color="ghost"
              onClick={() => {
                setEditMode(false);
                setCurrentValue(value);
                setError(undefined);
              }}
              size="small"
            >
              Cancel
            </NvButton>
          </NvFlex>
        </NvFlex>
      ) : (
        <NvFlex flexDirection="row" className="view-wrapper">
          <NvFlex flex="1 1 auto" minWidth="0">
            {React.cloneElement(viewComponent, { value })}
          </NvFlex>

          <EditButton
            className="edit-button"
            color="ghost"
            size="small"
            onlyIcon
            onClick={() => {
              setEditMode(true);
            }}
          >
            <NvEditIcon />
          </EditButton>
        </NvFlex>
      )}
    </InlineEditWrapper>
  );
};
