export type ProductId = 'actioner';

export type AnalyticsQueryType = {
  id: string;
  name: string;
  parameters: string[];
};

export enum AnalyticsRecentQueryState {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export type AnalyticsRecentQuery = {
  id: string;
  query: string;
  state: AnalyticsRecentQueryState;
  startTime: string;
  endTime: string;
  outputUrl: string;
};

export type AnalyticsQueryResult = Record<string, string>;
