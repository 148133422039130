import { NvAddCircleIcon, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { SubscriptionPlanDetailBodyWrapper, SubscriptionPlanDetailHeaderWrapper } from './styled';
import { SubscriptionPlanDetailProps } from './types';

export const SubscriptionPlanDetail: FC<SubscriptionPlanDetailProps> = ({ headerText, items }) => {
  const theme = useTheme();
  return (
    <NvFlex>
      <SubscriptionPlanDetailHeaderWrapper>
        <NvTypography variant="body2">{headerText}</NvTypography>
      </SubscriptionPlanDetailHeaderWrapper>
      <SubscriptionPlanDetailBodyWrapper>
        {items.map((i) => (
          <NvFlex direction="row" gap="4px" alignItems="center" key={i.id} width="250px">
            <NvAddCircleIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[300]} />
            <NvTypography variant="body2">{i.itemText}</NvTypography>
          </NvFlex>
        ))}
      </SubscriptionPlanDetailBodyWrapper>
    </NvFlex>
  );
};
