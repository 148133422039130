import {
  AssistantCancelRunExtraData,
  AssistantCancelRunNodeSummary,
  AssistantCancelRunVertex,
} from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class AssistantRunCancelComponentFactory implements ComponentFactory<AssistantCancelRunExtraData> {
  alias: string;
  name: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('assistant_cancel_run');
    this.alias = newAlias;
    this.name = newName;
  }

  get componentForGraph() {
    const outputRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.name,
      width: 40,
      height: 40,
      id: this.alias,
      type: 'assistant_cancel_run',
      alias: this.alias,
    };

    return { root: outputRoot };
  }

  componentForWorkflow(serverData: AssistantCancelRunExtraData) {
    const newNodeUnion: AssistantCancelRunVertex = {
      type: 'assistant_cancel_run',
      alias: this.alias,
    };

    const summary: AssistantCancelRunNodeSummary = {
      type: 'assistant_cancel_run',
      alias: this.alias,
      name: this.name,
      ...serverData,
    };

    return { root: newNodeUnion, nodeSummaries: { [this.alias]: summary } };
  }
}
