import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import { InputParameter } from '@novaera/actioner-service';
import { useInputsContext } from '../../../../../../../../../action-designer/providers/input-provider';
import { getPropertyTitle } from '../../../../../../../../../components';
import { RowItemCard } from '../../../common/row-item-card';
import { InputParameterLayout } from '../../../common/row-item-card/row-item-layouts';

export const FormTriggerInputParameterItem: React.FC<
  React.PropsWithChildren<{
    inputParameter: InputParameter;
    handleDelete: () => void;
    onSelectedInputParameterChanged: (params: { selectedInputParameterId: string }) => void;
    dragHandleProps?: DraggableProvidedDragHandleProps | null;
  }>
> = ({ inputParameter, onSelectedInputParameterChanged, dragHandleProps }) => {
  const { selectedInputParameterId } = useInputsContext();
  const propertyTitle = getPropertyTitle(inputParameter);
  return (
    <RowItemCard
      isSelected={selectedInputParameterId === inputParameter.id}
      onClick={() => onSelectedInputParameterChanged({ selectedInputParameterId: inputParameter.id })}
      rowItemLeftContentProps={{ draggable: true, dragHandleProps: dragHandleProps }}
      rowItemContent={
        <InputParameterLayout
          propertyTitle={propertyTitle}
          label={'label' in inputParameter.uiComponent ? inputParameter.uiComponent.label : undefined}
          name={inputParameter.name}
        />
      }
    />
  );
};
