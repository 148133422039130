import {
  GetWorkflowNodeExecutionsResponse,
  NodeExecutionStatus,
  NodeSummaries,
  ResponseNodeGenericResponse,
  ResponseNodeResponse,
  isResponseNodeFailedResponse,
  isResponseNodeResponse,
} from '@novaera/actioner-service';
import { NodeType } from '../../../components';

export const buildResponseMessage = (
  nodeExecutionResponse: ResponseNodeResponse | ResponseNodeGenericResponse | undefined
): ResponseNodeGenericResponse | null | undefined | string => {
  if (!nodeExecutionResponse) return null;
  if (isResponseNodeResponse(nodeExecutionResponse)) {
    if (isResponseNodeFailedResponse(nodeExecutionResponse.response)) {
      return nodeExecutionResponse.response.failMessage;
    } else {
      return nodeExecutionResponse.response;
    }
  }
  return null;
};

export const findResponseNodeAlias = (
  nodeSummaries: NodeSummaries | undefined,
  nodeStates: Record<string, { status: NodeExecutionStatus }> | undefined
) => {
  if (!nodeSummaries || !nodeStates) return '';
  const responseNode = Object.values(nodeSummaries).find(
    (nodeSummary) =>
      nodeSummary.type === NodeType.RESPONSE && nodeStates?.[nodeSummary.alias]?.status !== 'not_executed'
  );

  return responseNode?.alias || '';
};

export const findResponseNodeObjectKey = (
  nodeExecutionPages: GetWorkflowNodeExecutionsResponse[] | undefined
): string => {
  return (
    nodeExecutionPages?.reduce((key, page) => {
      const responseNode = page?.logs.find((log) => log.type === NodeType.RESPONSE);
      return key || responseNode?.objectKey || '';
    }, '') || ''
  );
};
