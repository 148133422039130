import { NvField } from '@novaera/core';
import { SelectWorkflow } from './select-workflow';
import { WorkflowSearchFieldProps, WorkflowSearchOption } from './types';

export const WorkflowSearchField: React.FC<React.PropsWithChildren<WorkflowSearchFieldProps>> = ({
  appId,
  fieldName,
  fieldProps,
  searchWorkflowsParams,
}) => (
  <NvField<WorkflowSearchOption & { autoRun: boolean }>
    name={fieldName}
    parse={(value) => value ?? ''}
    format={(value) => value ?? null}
    showErrorMessageOnlyWhenBlur
    component={<SelectWorkflow appId={appId} searchWorkflowsParams={searchWorkflowsParams} />}
    {...fieldProps}
  />
);
