import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PropertyPanelListHeaderCard = styled(NvBox)`
  position: sticky;
  top: 60px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 4px;
  height: 32px;
  background: ${({ theme }) => theme.palette.nv_neutral[5]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  flex: 0 0 auto;
`;

export const PropertyPanelListHeaderCardActions = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: 0 0 auto;
`;
