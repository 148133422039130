import MDEditor, { commands, ICommand } from '@uiw/react-md-editor';
import classnames from 'classnames';
import React from 'react';
import {
  NvBoldIcon,
  NvCodeIcon,
  NvFormatSizeIcon,
  NvImageIcon,
  NvItalicIcon,
  NvLinkIcon,
  NvListBulletedIcon,
  NvListNumberedIcon,
  NvQuoteIcon,
  NvStrikethroughIcon,
} from '../icons';
import { NvTooltip } from '../tooltip';
import { NvTypography } from '../typography';
import { MarkdownWrapper } from './styled';

export const NvMarkDownEditor: React.FC<
  React.PropsWithChildren<{
    value?: string;
    onChange?: (value?: string) => void;
    error?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFocus?: (e: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur?: (e: any) => void;
  }>
> = ({ value, onChange, error, onFocus, onBlur }) => {
  const customCommands: ICommand[] = [
    commands.group(
      [
        { ...commands.title1, icon: <NvTypography variant="h4">H1</NvTypography> },
        { ...commands.title2, icon: <NvTypography variant="h4">H2</NvTypography> },
        { ...commands.title3, icon: <NvTypography variant="h4">H3</NvTypography> },
        { ...commands.title4, icon: <NvTypography variant="h4">H4</NvTypography> },
        { ...commands.title5, icon: <NvTypography variant="h4">H5</NvTypography> },
        { ...commands.title6, icon: <NvTypography variant="h4">H6</NvTypography> },
      ],
      { name: 'title', groupName: 'title', buttonProps: { 'aria-label': 'Insert title' }, icon: <NvFormatSizeIcon /> }
    ),
    commands.divider,
    {
      ...commands.bold,
      icon: <NvBoldIcon />,
      buttonProps: { 'aria-label': commands.bold.buttonProps?.['aria-label'] },
    },
    {
      ...commands.italic,
      icon: <NvItalicIcon />,
      buttonProps: { 'aria-label': commands.italic.buttonProps?.['aria-label'] },
    },
    {
      ...commands.strikethrough,
      icon: <NvStrikethroughIcon />,
      buttonProps: { 'aria-label': commands.strikethrough.buttonProps?.['aria-label'] },
    },
    commands.divider,
    {
      ...commands.link,
      icon: <NvLinkIcon />,
      buttonProps: { 'aria-label': commands.link.buttonProps?.['aria-label'] },
    },
    {
      ...commands.quote,
      icon: <NvQuoteIcon />,
      buttonProps: { 'aria-label': commands.quote.buttonProps?.['aria-label'] },
    },
    {
      ...commands.code,
      icon: <NvCodeIcon />,
      buttonProps: { 'aria-label': commands.code.buttonProps?.['aria-label'] },
    },
    {
      ...commands.image,
      icon: <NvImageIcon />,
      buttonProps: { 'aria-label': commands.image.buttonProps?.['aria-label'] },
    },
    commands.divider,
    {
      ...commands.unorderedListCommand,
      icon: <NvListBulletedIcon />,
      buttonProps: { 'aria-label': commands.unorderedListCommand.buttonProps?.['aria-label'] },
    },
    {
      ...commands.orderedListCommand,
      icon: <NvListNumberedIcon />,
      buttonProps: { 'aria-label': commands.orderedListCommand.buttonProps?.['aria-label'] },
    },
  ];

  return (
    <MarkdownWrapper>
      <MDEditor
        className={classnames({
          'has-error': Boolean(error),
        })}
        preview="edit"
        value={value}
        onChange={onChange}
        onFocus={(e) => {
          onFocus?.(e);
        }}
        onBlur={(e) => {
          onBlur?.(e);
        }}
        defaultTabEnable={false}
        visibleDragbar={false}
        highlightEnable={false}
        textareaProps={{ tabIndex: 0 }}
        commands={customCommands}
        commandsFilter={(command, isExtra) => {
          if (command.name === commands.codeLive.name && isExtra) {
            return false;
          }

          if (command.name === commands.codeEdit.name) {
            return false;
          }
          if (command.name === commands.codePreview.name) {
            return false;
          }

          if (command.keyCommand === commands.divider.keyCommand && isExtra) {
            return false;
          }

          if (command.name === commands.fullscreen.name) {
            return false;
          }

          command.buttonProps = { ...command.buttonProps, tabIndex: 100 };
          return {
            ...command,
            ...(command.name &&
              command.icon &&
              command.buttonProps?.['aria-label'] && {
                icon: (
                  <NvTooltip PopperProps={{ placement: 'top' }} title={command.buttonProps?.['aria-label']}>
                    {command.icon}
                  </NvTooltip>
                ),
              }),
          };
        }}
      />
    </MarkdownWrapper>
  );
};
