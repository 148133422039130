import { useUpdateConnectionMappingService } from '@novaera/actioner-service';
import { useGetWorkflowQueryParams } from '../../../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useMissingConnectionUpdatesController = () => {
  const { userAppId } = useGetWorkflowQueryParams();
  const { mutate } = useUpdateConnectionMappingService();

  const handleAddConnectionMapping = ({
    connectionId,
    schemaId,
    connectionName,
  }: {
    schemaId: string;
    connectionId: string;
    connectionName: string;
  }) => {
    mutate({
      appId: userAppId,
      schemaId,
      payload: {
        connectionId,
      },
      connectionName,
    });
  };

  return {
    addConnectionMapping: handleAddConnectionMapping,
  };
};
