import { ApplicationConfig } from '@novaera/application-config';
import { ROUTE_DEFAULTS, TARGET_URI } from '@novaera/constants';
import { isAxiosError, useAxiosErrorHandler, useToast } from '@novaera/core';
import { useLocation, useNavigate, useQueryParams } from '@novaera/route';
import { getPathForTargetURI, setAccessToken } from '@novaera/service';
import { noop, setCookieForSpecificDomain } from '@novaera/utils';
import { isString } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ConfirmCodeParams, IdentityProvider, OnSignInClickProps } from '../../common/types';
import { checkWorkspaceLength } from '../../common/utils';
import { useCheckRegistration } from '../../services/use-check-registration';
import { useConfirmCode } from '../../services/use-confirm-code';
import { useConfirmEmailChange } from '../../services/use-confirm-email-change';
import { useFetchIdentityProviders } from '../../services/use-identity-providers';
import { useGetAuthorizationUrl } from '../../services/use-oauth2';
import { useSendVerification } from '../../services/use-send-verification';
import { useVerifyInvitation } from '../../services/use-verify-invitation';
import { useFetchWorkspaceList } from '../../ui/sign-in/workspace-panel/list/services/use-fetch-workspace-list';
import { SIGN_IN_STATE, UseSignInParams } from './types';
import { getNextState } from './utils';

export const useSignIn = (params: UseSignInParams) => {
  const { addToast } = useToast();
  const [signInState, setSignInState] = useState<SIGN_IN_STATE>(params?.initialSignInState || SIGN_IN_STATE.INITIAL);
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { getAxiosErrorMessage } = useAxiosErrorHandler();
  const { getSearchParams } = useQueryParams();
  const { refetch: fetchWorkspaceList } = useFetchWorkspaceList(false);
  const checkRegistration = useCheckRegistration();
  const sendVerification = useSendVerification();
  const confirmCode = useConfirmCode();
  const { data: identityProvidersData, isLoading: isIdentityProvidersLoading } = useFetchIdentityProviders();
  const getAuthorizationUrl = useGetAuthorizationUrl();
  const { mutateAsync: verifyUserInvitation } = useVerifyInvitation();
  const navigate = useNavigate();
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const { mutateAsync: confirmEmailChange } = useConfirmEmailChange();

  const location = useLocation();

  const onError = (error: unknown) => {
    if (isAxiosError(error)) {
      const axiosError = getAxiosErrorMessage(error);
      setError(axiosError);
    } else if (error instanceof Error) {
      setError(error.message);
    } else {
      setError(JSON.stringify(error));
    }
  };

  const handleConfirmCode = async ({ username, code }: ConfirmCodeParams) => {
    try {
      const { accessToken } = await confirmCode.mutateAsync(
        { username, code },
        {
          onError: noop,
        }
      );
      setAccessToken(accessToken);

      if (isString(location.state)) {
        window.location.href = location.state;
      } else {
        const workspaceListData = await fetchWorkspaceList();
        if (checkWorkspaceLength(workspaceListData.data?.workspaces.length ?? 0)) {
          setSignInState(
            getNextState({
              isInitial: false,
              redirectToDashboard: true,
            })
          );
        } else {
          setSignInState(
            getNextState({
              isInitial: false,
              isInWorkspaceList: true,
            })
          );
          setHasAccount(true);
          setError(undefined);
        }
      }
    } catch (error) {
      onError(error);
    }
  };

  const verifyInvitation = async (token: string) => {
    try {
      setIsVerifyLoading(true);
      const { accessToken } = await verifyUserInvitation({
        token,
      });
      setAccessToken(accessToken);
      navigate('/');
    } catch (error) {
      navigate('/error/invalid-invitation');
      onError(error);
    } finally {
      setIsVerifyLoading(false);
    }
  };

  const handleConfirmEmailChange = async (token: string) => {
    try {
      setIsVerifyLoading(true);
      await confirmEmailChange({
        token,
      });
      navigate('/');
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        setCookieForSpecificDomain(TARGET_URI, getPathForTargetURI(), `${ApplicationConfig.Actioner.baseHost}`);
        navigate(ROUTE_DEFAULTS.SIGN_IN, {
          state: `/users/confirm-email?token=${token}`,
        });
      } else {
        navigate('/error/invalid-confirm-email');
        onError(error);
      }
    } finally {
      setIsVerifyLoading(false);
    }
  };

  useEffect(() => {
    const { targetUri } = getSearchParams<{ targetUri: string }>() || {};
    if (targetUri) {
      setCookieForSpecificDomain(TARGET_URI, targetUri, `${ApplicationConfig.Actioner.baseHost}`);
    }
  }, [getSearchParams]);

  useEffect(() => {
    const params = getSearchParams<ConfirmCodeParams | { token: string }>();

    if ((params as ConfirmCodeParams)?.code && (params as ConfirmCodeParams).username) {
      handleConfirmCode({
        code: (params as ConfirmCodeParams).code,
        username: (params as ConfirmCodeParams).username,
      });
    } else if (location.pathname === '/user/invitation' && (params as { token: string })?.token) {
      verifyInvitation((params as { token: string }).token);
    } else if (location.pathname === '/users/confirm-email' && (params as { token: string })?.token) {
      handleConfirmEmailChange((params as { token: string }).token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.initialSignInState === SIGN_IN_STATE.IN_CODE && params.username) {
      sendVerification.mutateAsync({
        username: params.username,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.initialSignInState, params.username]);

  const handleSignInClick = async (props: OnSignInClickProps) => {
    try {
      const data = await checkRegistration.mutateAsync(props);
      const state = getNextState({ isInitial: false });

      if (!params.fromSignUp && !data.hasAccount) {
        addToast('User account is not defined.', { variant: 'error' });
        return;
      }

      setHasAccount(data.hasAccount);

      if (state === SIGN_IN_STATE.IN_CODE) {
        const verificationResult = await sendVerification.mutateAsync({
          username: props.username,
        });

        if (verificationResult.loginMethod === 'sso') {
          setSignInState(SIGN_IN_STATE.SSO_REDIRECT);
          setError(undefined);
          window.location.href = verificationResult.redirectUrl;
          return;
        }
      }
      setSignInState(state);
      setError(undefined);
    } catch (error) {
      onError(error);
    }
  };

  const onProviderButtonClick = async (provider: IdentityProvider) => {
    try {
      const data = await getAuthorizationUrl.mutateAsync({
        identityProvider: provider.providerName,
      });

      window.location.href = data.redirectTo;
    } catch (error) {
      onError(error);
    }
  };

  const handleResetLoginFlow = () => {
    setSignInState(
      getNextState({
        isInitial: true,
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SIGN_IN_FLOW: any = {
    [SIGN_IN_STATE.INITIAL]: handleSignInClick,
    [SIGN_IN_STATE.IN_CODE]: handleConfirmCode,
  };

  const identityProviders = useMemo(
    () =>
      !params.fromSignUp
        ? identityProvidersData?.providerDetails
        : identityProvidersData?.providerDetails.filter((provider) => provider.providerName.toLowerCase() !== 'slack'),
    [identityProvidersData, params.fromSignUp]
  );

  return {
    error,
    onSignInClick: SIGN_IN_FLOW[signInState],
    signInState,
    hasAccount,
    identityProviders,
    onProviderButtonClick,
    showSignInForm: signInState !== SIGN_IN_STATE.CHOOSE_WORKSPACE,
    isLoading: checkRegistration.isLoading || sendVerification.isLoading || confirmCode.isLoading,
    hasCodeError: confirmCode.isError,
    isVerifyLoading,
    resetLoginFlow: handleResetLoginFlow,
    isIdentityProvidersLoading,
  };
};
