import { NvConditionalRender, NvFlex, NvTypography, useNvDialogModalState } from '@novaera/core';
import { useVariablesController } from './controllers/use-variables';
import { VariablesModal } from './modal';
import { RequestVariables } from './requests';
import { EmptyState } from './styled';
import { VariablesTitle } from './title';
import { RequestVariableEditParams } from './types';

export const Variables = () => {
  const { hasVariable } = useVariablesController();
  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } =
    useNvDialogModalState<RequestVariableEditParams>();

  return (
    <NvFlex gap={'16px'} marginTop="8px">
      <VariablesTitle onAddVariableClicked={onModalOpenClicked} />
      <NvConditionalRender when={() => !hasVariable}>
        <EmptyState>
          <NvTypography variant="body2" textColor="ghost">
            You haven't defined any requests yet.
          </NvTypography>
        </EmptyState>
      </NvConditionalRender>
      <NvConditionalRender when={() => hasVariable}>
        <RequestVariables onModalOpened={onModalOpenClicked} />
      </NvConditionalRender>
      <VariablesModal passedParams={passedParameters} isOpened={isOpened} onModalClosed={onModalCloseClicked} />
    </NvFlex>
  );
};
