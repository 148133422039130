import { NodeUnionTypeEnumLike, useGetNode } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { ActionerEventNodeProperties } from './actioner-event-node-properties';
import { ActionerEventNodeSchemaProperties } from './actioner-event-node-properties/schema';
import { ActionerEventSubPanelCallbackParams } from './actioner-event-node-properties/types';
import { ActionerEventNodePropertyPanelDrawerProps } from './types';

export const ActionerEventNodePropertyPanelDrawer: React.FC<ActionerEventNodePropertyPanelDrawerProps> = ({
  handleClose,
}) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  assert(
    !!selectedNode,
    new Error('[ActionerEventPropertyPanelDrawerProps] - selectedNode should not be undefined!'),
    'ERROR'
  );

  const { userAppId: appId, workflowId } = useParams();

  const { node, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.actionerEvent>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const [openedSubPanel, setOpenedSubPanel] = useState<ActionerEventSubPanelCallbackParams | undefined>();
  const {
    panelProps: [first, second, third],
    panelCloseFunctions: [,],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
          setOpenedSubPanel(undefined);
        },
      },
      {
        open: openedSubPanel?.index === 1,
        closeFn: () => {
          setOpenedSubPanel(undefined);
        },
      },
      {
        open: openedSubPanel?.index === 2,
        closeFn: () => {
          setOpenedSubPanel(undefined);
        },
      },
    ],
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <PPDrawerItem {...first}>
        {isLoading ? (
          <PropertiesLoading />
        ) : (
          <ActionerEventNodeProperties
            onRowItemClicked={(panel) => {
              setOpenedSubPanel(panel);
            }}
            node={node}
            onCloseClicked={handleClose}
          />
        )}
      </PPDrawerItem>
      <PPDrawerItem {...second}>
        {isLoading ? (
          <PropertiesLoading />
        ) : (
          <ActionerEventNodeSchemaProperties
            json={openedSubPanel?.property.schema}
            title={'Schema'}
            onCloseClicked={() => {
              setOpenedSubPanel(undefined);
            }}
          />
        )}
      </PPDrawerItem>
      <PPDrawerItem {...third}>
        {isLoading ? (
          <PropertiesLoading />
        ) : (
          <ActionerEventNodeSchemaProperties
            json={openedSubPanel?.property.schema}
            title="Example payload"
            onCloseClicked={() => {
              setOpenedSubPanel(undefined);
            }}
          />
        )}
      </PPDrawerItem>
    </PropertyPaneWrapper>
  );
};
