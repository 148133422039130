import '@mdxeditor/editor/style.css';

import { MDXEditorMethods } from '@mdxeditor/editor';
import {
  NvArticleIcon,
  NvButton,
  NvConditionalRender,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvFlex,
  NvForm,
  NvSkeleton,
  WysiwygMarkdown,
  useNvDialogModalState,
} from '@novaera/core';
import { useMemo, useRef } from 'react';
import { Field } from 'react-final-form';
import { Header } from '../../../../components';
import { USER_APP_DOCUMENTS } from '../../constants';
import { DocumentFormValues } from '../list/types';
import { ModalBody } from '../modal-body';
import { useDocumentDetail } from './controllers/use-document-detail';
import { DocWrapper, EditorWrapper } from './styled';

export const DocumentDetail = () => {
  const {
    document,
    onDeleteDocument,
    updateDocument,
    userAppId,
    docId,
    handleBackClickConfirmation,
    isUpdateLoading,
    isUpdateDocumentContentLoading,
    isDeleteLoading,
    isContentLoading,
    initialValue,
    onDocumentContentChange,
    isGetDocumentLoading,
    isAppFree,
  } = useDocumentDetail();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const docInitialValues = useMemo(
    () => ({
      name: document?.name ?? '',
      tags: document?.tags ?? [],
    }),
    [document]
  );

  const markdownEditorRef = useRef<MDXEditorMethods>(null);

  return !isContentLoading && !isGetDocumentLoading ? (
    <>
      <NvForm<{
        data?: string;
      }>
        onSubmit={onDocumentContentChange}
        initialValues={initialValue}
      >
        {({ dirty, form }) => {
          return (
            <DocWrapper>
              <Header
                isLoading={isGetDocumentLoading}
                name={document?.name ?? 'Document'}
                icon={<NvArticleIcon />}
                {...(isAppFree
                  ? {}
                  : {
                      onEditNameClick: () => {
                        onModalOpenClicked();
                      },
                    })}
                {...(isAppFree
                  ? { actions: [] }
                  : {
                      actions: [
                        {
                          name: 'Delete',
                          isLoading: false,
                          onClick: () => {
                            onDeleteDocument(document?.name ?? 'Document');
                          },
                          icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
                        },
                      ],
                    })}
                onBack={dirty ? handleBackClickConfirmation : USER_APP_DOCUMENTS(userAppId)}
                hasChange={dirty}
                buttonActions={
                  <>
                    <NvButton
                      size="small"
                      color="secondary"
                      disabled={isUpdateDocumentContentLoading || isDeleteLoading}
                      onClick={() => {
                        form.setConfig('keepDirtyOnReinitialize', false);
                        form.reset();
                        form.setConfig('keepDirtyOnReinitialize', true);
                        markdownEditorRef.current?.setMarkdown(form.getState().values.data ?? '');
                      }}
                    >
                      Discard changes
                    </NvButton>

                    <NvButton
                      size="small"
                      disabled={isUpdateDocumentContentLoading}
                      loading={isUpdateDocumentContentLoading}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      Save
                    </NvButton>
                  </>
                }
              />
              <EditorWrapper id="editor-wrapper">
                <NvConditionalRender when={!isContentLoading}>
                  <Field<string>
                    name="data"
                    render={({ input }) => {
                      const { onChange, value } = input;
                      return document?.type === 'markdown' ? (
                        <WysiwygMarkdown value={value} onChange={onChange} markdownEditorRef={markdownEditorRef} />
                      ) : (
                        <>not supported</>
                      );
                    }}
                  />
                </NvConditionalRender>
                <NvConditionalRender when={isContentLoading}>
                  <NvFlex width={'100%'} paddingLeft={'48px'} paddingTop={'24px'}>
                    <NvSkeleton width={'200px'} height={'48px'} />
                    <NvSkeleton width={'120px'} height={'48px'} />
                    <NvSkeleton width={'250px'} height={'48px'} />
                  </NvFlex>
                </NvConditionalRender>
              </EditorWrapper>
            </DocWrapper>
          );
        }}
      </NvForm>
      <NvDialogModal<DocumentFormValues>
        formProps={{
          initialValues: docInitialValues,
        }}
        onFormSubmit={(values) => {
          updateDocument(
            {
              appId: userAppId,
              docId,
              payload: {
                ...values,
                type: 'markdown',
              },
            },
            {
              onSuccess: () => {
                onModalCloseClicked();
              },
            }
          );
        }}
        maxWidth="sm"
        fullWidth
        open={isOpened}
        onCloseButtonClicked={onModalCloseClicked}
        Header="Update document"
        modalIcon="add"
        Body={<ModalBody />}
        primaryButtonText="Update"
        isLoading={isUpdateLoading}
      />
    </>
  ) : (
    <>
      <NvSkeleton width={'100%'} height={'48px'} variant="rectangular" />
      <NvFlex
        width={'100%'}
        paddingLeft="calc((100vw - 1024px) / 2)"
        paddingRight="calc((100vw - 1024px) / 2)"
        gap="8px"
        marginTop="8px"
      >
        <NvSkeleton width={'100%'} height={'48px'} variant="rectangular" />
        <NvSkeleton width={'60%'} height={'16px'} variant="rectangular" sx={{ marginTop: '16px' }} />
        <NvSkeleton width={'100%'} height={'16px'} variant="rectangular" />
        <NvSkeleton width={'80%'} height={'16px'} variant="rectangular" />
        <NvSkeleton width={'60%'} height={'16px'} variant="rectangular" />
        <NvSkeleton width={'40%'} height={'16px'} variant="rectangular" />
      </NvFlex>
    </>
  );
};
