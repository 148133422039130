import {
  isMaxLength,
  isMinLength,
  isRequired,
  isStringWithoutEmptyChar,
  NvBox,
  NvDivider,
  NvField,
  NvFlex,
  NvTextArea,
  NvTextField,
  StepField,
  useField,
  useForm,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import OnChange from '../../../../../../action-designer/components/on-change';

export const SlackAppDetail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { palette } = useTheme();
  const { change } = useForm();
  const {
    input: { value: displayInfoName },
  } = useField('displayInfo.name');

  return (
    <NvFlex>
      <StepField>
        <NvBox maxWidth="350px">
          <NvField
            name="displayInfo.name"
            direction="label-on-top"
            labelText="App name"
            hasRequiredIndicator
            validators={[isRequired(), isMaxLength({ length: 34, message: 'Max 35 character.' })]}
            component={<NvTextField />}
          />
          <OnChange name="displayInfo.name">
            {() => {
              change('appFeatures.botUserFeature.displayName', displayInfoName);
            }}
          </OnChange>
        </NvBox>
      </StepField>
      <NvBox padding="16px 0 8px 0">
        <NvDivider borderColor={palette.nv_neutral[20]} />
      </NvBox>

      <StepField
        help={{
          title: 'Slash command',
          description: 'You can use the slash commands to quickly access the actions in your app.',
        }}
      >
        <NvBox maxWidth="350px">
          <NvField
            name="appFeatures.commandFeatures[0].command"
            direction="label-on-top"
            labelText="Slash command"
            hasRequiredIndicator
            validators={[
              isRequired(),
              isMinLength({ length: 2, message: 'This field is required.' }),
              isMaxLength({ length: 32, message: 'Max 32 character.' }),
              isStringWithoutEmptyChar(),
            ]}
            format={(value) => (value ? (value.startsWith('/') ? value.substring(1) : value) : '')}
            parse={(value) => `/${value ?? ''}`}
            component={<NvTextField startLabelAdornment="/" />}
          />
        </NvBox>
      </StepField>
      <NvBox padding="16px 0 8px 0">
        <NvDivider borderColor={palette.nv_neutral[20]} />
      </NvBox>
      <StepField
        help={{
          title: 'Short description',
          description:
            'Short description will be visible on app profile cards in Slack. You can enter a short summary of the value of your app here.',
        }}
      >
        <NvField
          name="displayInfo.description"
          direction="label-on-top"
          labelText="Short description"
          hasRequiredIndicator
          validators={[isRequired(), isMaxLength({ length: 130, message: 'Max 130 character.' })]}
          component={<NvTextArea rows={3} />}
        />
      </StepField>
      <NvBox padding="16px 0 8px 0">
        <NvDivider borderColor={palette.nv_neutral[20]} />
      </NvBox>
      <StepField
        help={{
          title: 'Long description',
          description:
            "The long description will be visible on the app's About section. You can provide more details about your app in here.",
        }}
      >
        <NvField
          name="displayInfo.longDescription"
          direction="label-on-top"
          labelText="Long description"
          hasRequiredIndicator
          validators={[
            isRequired(),
            isMinLength({ length: 175, message: 'Min 140 character.' }),
            isMaxLength({ length: 3999, message: 'Max 4000 character.' }),
          ]}
          component={<NvTextArea rows={9} />}
        />
      </StepField>
    </NvFlex>
  );
};
