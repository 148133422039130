import { NvAxios, useMutation } from '@novaera/core';
import { INTEGRATION_ROOT_PATH } from '../constants';
import { CreateIntegrationParams, CreateIntegrationResponse } from '../types';
import { useUpdateIntegrationCache } from '../use-update-cache';

const createIntegration: (params: CreateIntegrationParams) => Promise<CreateIntegrationResponse> = async (params) => {
  const result = await NvAxios.post<CreateIntegrationResponse>(INTEGRATION_ROOT_PATH, params);
  return result?.data;
};

export const useCreateIntegration = () => {
  const { updateCache } = useUpdateIntegrationCache();

  return useMutation(createIntegration, {
    onSuccess: (response) => {
      updateCache({
        integration: response,
        method: 'add',
      });
    },
  });
};
