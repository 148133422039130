import { GetWorkspaceMappingParams } from './use-get-workspace-mapping';

export const QUERY_KEYS_ORGANIZATION_MAPPING = {
  all: ['organization-mapping'] as const,
  list: (workspaceId?: string) => [...QUERY_KEYS_ORGANIZATION_MAPPING.all, 'list', workspaceId] as const,
};

export const QUERY_KEYS_ORGANIZATIONS = {
  all: ['organizations'] as const,
  organization: (organizationId?: string) => [...QUERY_KEYS_ORGANIZATIONS.all, 'organization', organizationId] as const,
  workspaces: (params: GetWorkspaceMappingParams) =>
    [...QUERY_KEYS_ORGANIZATIONS.all, 'workspace-mappings', params] as const,
};
