import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ArrayFieldGroup = styled(NvFlex)`
  row-gap: 8px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 6px;
  padding: 6px;
`;
