import React from 'react';
import { appStateReducer } from './reducer';
import { NavigationContextAction, NavigationContextProviderProps, Dispatch, State } from './types';

const NavigationContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const NavigationContextProvider = ({ children }: NavigationContextProviderProps) => {
  const [state, dispatch] = React.useReducer(appStateReducer, {
    openedPopoverId: undefined,
  });

  const value = { state, dispatch };
  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

const useNavigationContext = () => {
  const context = React.useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider');
  }
  return context;
};

export { NavigationContextProvider, useNavigationContext, NavigationContextAction };
