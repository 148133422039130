// Material Component defaults
export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_ROW_HEIGHT = 52;

// Our initial static values
export const PAGE_SIZE = 5;
export const HEADER_HEIGHT = 32;
export const ROW_HEIGHT = 36;

export const EMPTY_STATE_TEXT = 'There is no data to show.';
