import { EdgeExtraData } from '@novaera/core';
import { Position, XYPosition } from 'reactflow';
import { BaseEdgeProps } from '../../common/base-edge/types';
import { convertPointsToSvgPathString, getEdgeCenter } from '../../utils';

export interface GetStepPathParams extends EdgeExtraData {
  sourceX: number;
  sourceY: number;
  sourcePosition?: Position;
  targetX: number;
  targetY: number;
  targetPosition?: Position;
  borderRadius?: number;
  centerX?: number;
  centerY?: number;
  topDownOffset: number;
  leftRightOffset: number;
  tailBehavior: BaseEdgeProps['tailBehavior'];
}

function getSmoothPathPoints({
  source,
  target,
  topDownOffset,
  centerPosition = 'up',
  tailBehavior = 'shown',
}: {
  source: XYPosition;
  sourcePosition: Position;
  target: XYPosition;
  targetPosition: Position;
  center: Partial<XYPosition>;
  topDownOffset: number;
  leftRightOffset: number;
  centerPosition: EdgeExtraData['centerPosition'];
  tailBehavior?: BaseEdgeProps['tailBehavior'];
}): { pathPoints: XYPosition[]; center: XYPosition } {
  if (source.x === target.x || source.y === target.y) {
    // this means a straight line

    const [centerX, centerY] = getEdgeCenter({
      sourceX: source.x,
      sourceY: source.y,
      targetX: target.x,
      targetY: target.y,
    });

    return { pathPoints: [source, target], center: { x: centerX, y: centerY } };
  }

  if (centerPosition === 'up') {
    const start: XYPosition = {
      x: source.x,
      y: source.y + topDownOffset,
    };
    const goXDirection: XYPosition = {
      x: target.x,
      y: start.y,
    };

    const goYDirection: XYPosition = {
      x: goXDirection.x,
      y: target.y,
    };

    const [centerX, centerY] = getEdgeCenter({
      sourceX: goXDirection.x,
      sourceY: goXDirection.y,
      targetX: goYDirection.x,
      targetY: goYDirection.y,
    });
    const pathPoints = [source, start, goXDirection, goYDirection, target];

    return { pathPoints, center: { x: centerX, y: centerY } };
  } else {
    const start: XYPosition = {
      x: source.x,
      y: target.y - topDownOffset,
    };

    if (tailBehavior === 'shown') {
      const goXDirection: XYPosition = {
        x: target.x,
        y: start.y,
      };

      const goYDirection = {
        x: goXDirection.x,
        y: target.y,
      };

      const [centerX, centerY] = getEdgeCenter({
        sourceX: goXDirection.x,
        sourceY: goXDirection.y,
        targetX: goYDirection.x,
        targetY: goYDirection.y,
      });
      const pathPoints = [source, start, goXDirection, goYDirection, target];

      return { pathPoints, center: { x: centerX, y: centerY } };
    } else {
      const goXDirection: XYPosition = {
        x: target.x - 3,
        y: start.y,
      };

      const [centerX, centerY] = getEdgeCenter({
        sourceX: start.x,
        sourceY: start.y,
        targetX: target.x,
        targetY: target.y,
      });
      const pathPoints = [source, start, goXDirection];

      return { pathPoints, center: { x: centerX, y: centerY } };
    }
  }
}

export function getSmoothPath({
  sourceX,
  sourceY,
  sourcePosition = Position.Bottom,
  targetX,
  targetY,
  targetPosition = Position.Top,
  borderRadius = 5,
  centerX,
  centerY,
  topDownOffset,
  leftRightOffset,
  centerPosition,
  tailBehavior,
}: GetStepPathParams): { points: string; centerPosition: XYPosition } {
  const { pathPoints, center } = getSmoothPathPoints({
    source: { x: sourceX, y: sourceY },
    sourcePosition,
    target: { x: targetX, y: targetY },
    targetPosition,
    center: { x: centerX, y: centerY },
    topDownOffset,
    leftRightOffset,
    centerPosition,
    tailBehavior,
  });

  const points = convertPointsToSvgPathString({ points: pathPoints, borderRadius });

  return { points, centerPosition: center };
}
