import {
  ActionNode,
  isTransferFileAssistantNodeAction,
  useGetIntegration,
  useGetIntegrationAction,
} from '@novaera/actioner-service';
import {
  EmptyInterface,
  NodeListItem,
  NvBox,
  NvForm,
  NvNodeList,
  NvPopover,
  NvSlide,
  NvSmartToyIcon,
  Portal,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import { noop } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PPDrawerItem } from '../../../../../../../../components';
import { DropdownSimpleSearchInput } from '../../../../../../../../components/simple-search-input/styled';
import { FormIdentifierProvider } from '../../../../../../../../providers/form-identifier-provider';
import { AssistantFilePanel } from '../../actioner-transfer-file-property-panel-drawer/assistant-file-panel-drawer';
import { usePPDrawerItemHelper } from '../../controllers/use-pp-drawer-item';
import { RowItemCard } from '../row-item-card';
import { ActionPanel } from './action-panel';
import { PARENT_PROPERTY_PANEL_DRAWER_ID } from './constants';
import { RowItemContent } from './row-item-content';

export const RowItemActionSelection = <ActionNodeListItemValue, FieldValue extends EmptyInterface>({
  onChange,
  value,
  actionTag,
  actionNodeListItems,
  onSearchActionNodeListItems,
  isActionsLoading,
  onItemSelected,
}: {
  onChange: (actionNode?: FieldValue) => void;
  value?: FieldValue;
  actionTag?: string;
  actionNodeListItems: NodeListItem[];
  onSearchActionNodeListItems: (keyword?: string) => void;
  isActionsLoading: boolean;
  onItemSelected: (node?: ActionNodeListItemValue) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const open = Boolean(anchorEl);
  const formRef = useRef(null);

  const { style, className, onPanelClose, onPanelOpen } = usePPDrawerItemHelper({ refDom: formRef });

  const theme = useTheme();

  const { integrationId, actionId } = useMemo(() => {
    return value && !isTransferFileAssistantNodeAction(value as never)
      ? (value as unknown as ActionNode)
      : { integrationId: undefined, actionId: undefined };
  }, [value]);

  const { data: integration } = useGetIntegration({
    id: integrationId,
  });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
  });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!value && !integrationId) {
        setAnchorEl(event.currentTarget);
      } else {
        onPanelOpen();
      }
    },
    [integrationId, onPanelOpen, value]
  );

  const handleClose = useCallback(() => {
    if (!value && !integrationId) {
      setAnchorEl(null);
    } else {
      onPanelClose();
    }
    onSearchActionNodeListItems(undefined);
  }, [integrationId, onPanelClose, onSearchActionNodeListItems, value]);

  const handleDeleteActionSource = useCallback(() => {
    onChange(undefined);
    onPanelClose();
  }, [onChange, onPanelClose]);

  const rowItemContentProps: React.ComponentProps<typeof RowItemContent> = useMemo(() => {
    if (isTransferFileAssistantNodeAction(value as never)) {
      return {
        integrationName: 'Assistant',
        icon: <NvSmartToyIcon htmlColor={theme.palette.nv_node.ai} />,
        actionName: 'Assistant',
        showIconNearIntegrationName: false,
      };
    } else {
      return {
        integrationName: integration?.name,
        logoUrl: integration?.logoUrl,
        actionName: integrationAction?.name,
      };
    }
  }, [value, theme.palette.nv_node.ai, integration?.name, integration?.logoUrl, integrationAction?.name]);

  return (
    <NvForm<FieldValue>
      initialValues={value}
      onSubmit={noop}
      onChange={({ values }) => {
        onChange(values);
      }}
      keepDirtyOnReinitialize
      formRef={formRef}
    >
      <>
        <RowItemCard
          rowItemLeftContentProps={{ draggable: false }}
          rowItemContent={<RowItemContent {...rowItemContentProps} />}
          onClick={handleClick}
        />
        <NvPopover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: {
                marginTop: -16,
                marginLeft: 8,
              },
            },
          }}
          disableRestoreFocus
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <NvBox width="370px">
            <DropdownSimpleSearchInput
              autoFocus
              inputRef={searchInputRef}
              onKeywordChanged={(keyword) => {
                onSearchActionNodeListItems(keyword);
              }}
            />

            <NvSlide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              appear={false}
              timeout={{ appear: 0, exit: 0, enter: 300 }}
            >
              <NvNodeList
                nodes={actionNodeListItems}
                onItemClick={(e, node) => {
                  assert(
                    Boolean(document && document.getElementById(PARENT_PROPERTY_PANEL_DRAWER_ID)),
                    new Error(
                      `To use row item action selection, you must have a parent property panel drawer with id ${PARENT_PROPERTY_PANEL_DRAWER_ID}`
                    )
                  );

                  const { extraData } = node;

                  onItemSelected(extraData);

                  onPanelOpen();
                  handleClose();
                }}
                isLoading={isActionsLoading}
              />
            </NvSlide>
          </NvBox>
        </NvPopover>
        <Portal node={document && document.getElementById(PARENT_PROPERTY_PANEL_DRAWER_ID)}>
          <PPDrawerItem style={style} className={className}>
            {value && !isTransferFileAssistantNodeAction(value as never) && integration && (
              <FormIdentifierProvider>
                <ActionPanel
                  integration={integration}
                  integrationAction={integrationAction}
                  onPanelClose={handleClose}
                  onDelete={handleDeleteActionSource}
                  actionTag={actionTag}
                />
              </FormIdentifierProvider>
            )}
            {value && isTransferFileAssistantNodeAction(value as never) && (
              <FormIdentifierProvider>
                <AssistantFilePanel
                  integration={integration}
                  integrationAction={integrationAction}
                  onPanelClose={handleClose}
                  onDelete={handleDeleteActionSource}
                  actionTag={actionTag}
                />
              </FormIdentifierProvider>
            )}
          </PPDrawerItem>
        </Portal>
      </>
    </NvForm>
  );
};
