import classNames from 'classnames';
import { useState } from 'react';
import { NvSwitch } from '../switch';
import { NvTypography } from '../typography';
import {
  BodyInner,
  BodyWrapper,
  ButtonWrapper,
  CardWrapper,
  Content,
  Footer,
  FooterInner,
  Header,
  HeaderInner,
} from './styled';

export type SwitchedCollapsedPanelProps = {
  onSwitchChange?: (isOpened: boolean) => void;
  Body?: React.ReactNode;
  FooterButtons?: React.ReactNode;
  FooterInformation?: React.ReactNode;
  switchLabel: string;
  value?: boolean;
};

export const SwitchedCollapsedPanel = ({
  onSwitchChange,
  Body,
  FooterButtons,
  FooterInformation,
  switchLabel,
  value,
}: SwitchedCollapsedPanelProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(Boolean(value));

  const handleSSOSettingsChange = (isOpened: boolean) => {
    setIsOpened(isOpened);
    onSwitchChange?.(isOpened);
  };

  return (
    <CardWrapper>
      <Header>
        <HeaderInner>
          <NvSwitch
            label={switchLabel}
            labelProps={{
              variant: 'h2',
              textColor: 'main',
            }}
            onChange={(_, checked) => {
              handleSSOSettingsChange(checked);
            }}
            value={isOpened}
            checked={isOpened}
          />
        </HeaderInner>
      </Header>
      <Content
        className={classNames({
          opened: isOpened,
        })}
      >
        <BodyWrapper>
          <BodyInner>{Body}</BodyInner>
        </BodyWrapper>
        <Footer>
          <FooterInner>
            {typeof FooterInformation === 'string' ? (
              <NvTypography variant="body2" textColor="subtle">
                {FooterInformation}
              </NvTypography>
            ) : (
              FooterInformation
            )}
            <ButtonWrapper>{FooterButtons}</ButtonWrapper>
          </FooterInner>
        </Footer>
      </Content>
    </CardWrapper>
  );
};
