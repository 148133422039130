import { NvAxios, useQuery } from '@novaera/core';
import { InputParameter } from '../../types';
import { ROOT_PATH_SSO_PROVIDERS } from '../constants';
import { QUERY_KEYS_SSO_PROVIDERS } from '../keys';

type GetSSOProviderParametersParams = {
  ssoType: string;
};

type GetSSOProviderParametersResponse = {
  parameters: InputParameter[];
};

const getSSOParameters = async ({ ssoType }: GetSSOProviderParametersParams) => {
  const result = await NvAxios.get<GetSSOProviderParametersResponse>(
    `${ROOT_PATH_SSO_PROVIDERS}/${ssoType}/parameters`
  );
  return result?.data;
};

export const useGetSSOParameters = (params: GetSSOProviderParametersParams) =>
  useQuery<GetSSOProviderParametersResponse>(
    QUERY_KEYS_SSO_PROVIDERS.ssoProviderParameters(params.ssoType),
    () => getSSOParameters(params),
    {
      enabled: !!params.ssoType,
    }
  );
