import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { GetConfigsResponse } from '../use-get-configs/types';
import { DeleteConfigParams } from './types';

const deleteConfig = async ({ appId, configId }: DeleteConfigParams) => {
  const result = await NvAxios.delete(`${CONFIG_ROOT_PATH(appId)}/${configId}`);
  return result?.data;
};

export const useDeleteConfig = () => {
  const cache = useQueryClient();
  return useMutation(deleteConfig, {
    onSuccess: (_, { appId, configId }) => {
      cache.setQueryData<InfiniteData<GetConfigsResponse>>(QUERY_KEYS_CONFIGS.list(appId), (old) => {
        if (old) {
          return {
            ...old,
            pages: old?.pages.map((page) => ({
              ...page,
              configurations: page.configurations.filter((c) => c.id !== configId),
            })),
          };
        }
        return old;
      });
      cache.removeQueries(QUERY_KEYS_CONFIGS.detail(appId, configId));
    },
  });
};
