export const QUERY_KEYS_RECORDS = {
  all: ['search-records'] as const,
  detail: ({ appId, modelId, queryKey }: { appId?: string; modelId?: string; queryKey?: string }) =>
    [
      ...QUERY_KEYS_RECORDS.all,
      ...(appId ? [appId] : []),
      ...(modelId ? [modelId] : []),
      ...(queryKey ? [queryKey] : []),
    ] as const,
};
