import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvFlex } from '../flex';
import { NvPaper } from '../paper';

export const Tags = styled(NvFlex)`
  margin: 2px 0;

  .MuiAutocomplete-tag {
    margin: 0 3px;
  }
`;

export const DefaultPaper = styled(NvPaper)`
  margin-top: 6px;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  ${({ theme }) => theme.elevations.e400};
  border-radius: 12px;
  margin-left: -6px;
  width: calc(100% + 12px);

  .MuiAutocomplete-listbox {
    margin: 0;
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .MuiAutocomplete-noOptions {
    text-align: center;
    padding: 32px;
    color: ${({ theme }) => theme.palette.nv_neutral_alpha[600]};
  }
`;

export const DefaultRenderOption = styled('li')`
  &.MuiAutocomplete-option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 4px;
    border-radius: 6px;
    transition: background-color 300ms ease, color 300ms ease, border-color 300ms ease;
    border: 1px solid transparent;
    flex: 0 0 auto;

    &.Mui-focused {
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border-color: ${({ theme }) => theme.palette.nv_neutral_alpha[50]};
    }

    &[aria-selected='true'] {
      color: ${({ theme }) => theme.palette.nv_main[60]};
      background-color: ${({ theme }) => theme.palette.nv_main[20]};

      &.Mui-focused {
        background-color: ${({ theme }) => theme.palette.nv_main[20]};
        border-color: ${({ theme }) => theme.palette.nv_main[30]};
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_main[20]};
        border: 1px solid transparent;
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.nv_main[20]};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
      border: 1px solid transparent;
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
    }
  }
`;

export const AutocompleteWrapper = styled(NvBox)`
  .nv-autocomplete {
    &.compact {
      .MuiTextField-root {
        .MuiInput-root {
          &.MuiInputBase-sizeMedium {
            min-height: unset;
            padding-top: 0;
            padding-bottom: 0;

            input.MuiInputBase-input {
              &.MuiInputBase-inputSizeMedium {
                height: 22px;
                font-size: 12px;
                line-height: 18px;
                padding-right: 28px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }

            .MuiAutocomplete-endAdornment {
              height: 20px;

              .MuiAutocomplete-clearIndicator {
                svg {
                  width: 12px;
                  height: 12px;
                }
              }

              svg {
                width: 16px;
                height: 16px;
              }
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .MuiTextField-root {
      .MuiInput-root {
        flex-wrap: wrap;
        justify-content: flex-start;
        height: auto;

        input.MuiInputBase-input {
          padding: 2px 0px;
          width: 100%;
          flex: 1 1 0;
          min-width: 80px;
        }

        &.MuiInputBase-sizeSmall {
          height: auto;
          min-height: 32px;
          padding-top: 3px;
          padding-bottom: 3px;

          .MuiAutocomplete-endAdornment {
            position: absolute;
            height: 32px;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;

            .MuiSvgIcon-root {
              width: 16px;
              height: 16px;
            }
          }
        }

        &.MuiInputBase-sizeMedium {
          height: auto;
          min-height: 32px;
          padding-top: 3px;
          padding-bottom: 3px;

          .MuiAutocomplete-endAdornment {
            position: absolute;
            height: 32px;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;

            .MuiSvgIcon-root {
              width: 20px;
              height: 20px;
            }
          }
        }

        &.MuiInputBase-sizeLarge {
          height: auto;
          min-height: 40px;
          padding-top: 7px;
          padding-bottom: 7px;

          .MuiAutocomplete-endAdornment {
            position: absolute;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
          }
        }

        &.MuiInputBase-adornedStart {
          input.MuiInputBase-input {
            padding: 2px 0px;
          }
        }

        .MuiAutocomplete-endAdornment {
          position: initial;
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          min-width: 0;

          .MuiAutocomplete-popupIndicator {
            height: 100%;
            transition: transform 0.1s ease-out;
          }

          .MuiAutocomplete-popupIndicatorOpen {
            transform: rotate(180deg);
          }

          .MuiAutocomplete-clearIndicator {
            background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[20]};
            height: 16px;
            width: 16px;
            border-radius: 8px;

            .MuiSvgIcon-root {
              height: 12px;
              width: 12px;
            }

            &:hover {
              background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[30]};
            }

            &:active {
              background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[40]};
            }
          }
        }

        &.Mui-error {
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
          border: 1px solid ${({ theme }) => theme.palette.nv_error[40]};
          -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
          -moz-box-sizing: border-box; /* Firefox, other Gecko */
          box-sizing: border-box; /* Opera/IE 8+ */
          ${({ theme }) => theme.mixins.error}

          &:hover {
            background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
          }
        }
      }
    }
  }
`;
