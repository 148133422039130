import { FC, PropsWithChildren } from 'react';
import { ManagedAppInformationBox } from '../../../components/managed-apps/information-box';
import { AppAdmins } from './app-admins';
import { AppConnections } from './app-connections';
import { AppUseCasesAndLearnMore } from './app-use-cases-and-learn-more';
import { AppVideosAndImages } from './app-videos-and-images';
import { UserAppDescription } from './description-field';
import { FreeAppInformation } from './free-app-information';
import { UserAppDescriptionHeader } from './header';
import { AppDescriptionLoading } from './loading';
import { DescriptionTabBodyWrapper, DescriptionTabLeftWrapper, DescriptionTabRightWrapper } from './styled';
import { DescriptionProps } from './types';

export const Description: FC<PropsWithChildren<DescriptionProps>> = ({ userApp, isLoading }) =>
  isLoading ? (
    <AppDescriptionLoading />
  ) : (
    <>
      {userApp && <UserAppDescriptionHeader userApp={userApp} />}
      <DescriptionTabBodyWrapper>
        <DescriptionTabLeftWrapper>
          {userApp && <AppVideosAndImages assets={userApp.assets} appHasNoDescription={!userApp.description?.value} />}
          {userApp && <UserAppDescription description={userApp.description} />}
          {userApp && (
            <AppUseCasesAndLearnMore assets={userApp.assets} appHasNoDescription={!userApp.description?.value} />
          )}
        </DescriptionTabLeftWrapper>

        <DescriptionTabRightWrapper>
          {userApp?.free ? <FreeAppInformation /> : userApp?.managed ? <ManagedAppInformationBox /> : null}
          <AppConnections />
          <AppAdmins />
        </DescriptionTabRightWrapper>
      </DescriptionTabBodyWrapper>
    </>
  );
