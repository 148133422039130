import { CatalogRelationship, StringValue, ValueTypes, useSearchCatalogRelationship } from '@novaera/actioner-service';
import {
  DefaultRenderOption,
  NvAutocomplete,
  NvCloseIcon,
  NvDivider,
  NvExpandMoreIcon,
  NvFlex,
  NvSkeleton,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { DynamicMaterialIcons } from '../../../../../../components/dynamic-material-icons';

export const RelationshipsAutocomplete: React.FC<{
  value?: StringValue;
  onChange: (v: StringValue) => void;
  firstEntityTypeId?: string;
}> = ({ value, onChange, firstEntityTypeId }) => {
  const { data: catalogRelationships, isInitialLoading: isCatalogRelationshipsLoading } = useSearchCatalogRelationship({
    firstEntityTypeId,
  });
  const options = catalogRelationships?.relationshipDefinitions ?? [];
  const getOptionDisplayValue = (option: CatalogRelationship | null) =>
    option?.targetEntityType
      ? `${option.targetEntityType.name} | ${option.sourceToTargetRelationType}/${option.targetToSourceRelationType}`
      : '';

  return isCatalogRelationshipsLoading ? (
    <NvSkeleton width="100%" height="32px" variant="rectangular" />
  ) : (
    <NvAutocomplete
      options={options}
      getOptionLabel={(option) => getOptionDisplayValue(option)}
      renderInput={(props) => <NvTextField {...props} placeholder="Select relationship" />}
      renderOption={(props, option) => (
        <DefaultRenderOption {...props} key={option.id}>
          <NvFlex direction="row" alignItems="center" gap="4px">
            {option.targetEntityType?.iconId && (
              <DynamicMaterialIcons initialValue={option.targetEntityType?.iconId}>
                {({ getCurrentIcon }) => {
                  const Icon = getCurrentIcon();
                  return Icon && <Icon sx={{ width: '16px', height: '16px' }} />;
                }}
              </DynamicMaterialIcons>
            )}

            <NvFlex direction="row" alignItems="center" gap="8px">
              <NvTypography variant="body2">{option.targetEntityType?.name}</NvTypography>
              <NvDivider
                orientation="vertical"
                sx={{
                  height: '6px',
                }}
              />
              <NvTypography textColor="subtle" variant={'body2'}>
                {option.sourceToTargetRelationType}/{option.targetToSourceRelationType}
              </NvTypography>
            </NvFlex>
          </NvFlex>
        </DefaultRenderOption>
      )}
      clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      popupIcon={<NvExpandMoreIcon />}
      onChange={(_, value) => {
        const displayValue = getOptionDisplayValue(value);
        onChange?.({ type: ValueTypes.STRING, value: value?.id, displayValue });
      }}
      value={options.find((o) => o.id === value?.value)}
    />
  );
};
