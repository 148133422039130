import { BillingCycle, SubscriptionPlan, useChangePlan } from '@novaera/actioner-service';
import { NvDialogModal } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC, useMemo, useState } from 'react';
import { ChangePlanModalBody } from './change-plan-modal-body';
import { ChangePlanModalProps } from './types';

export const ChangePlanModal: FC<ChangePlanModalProps> = ({
  initialAvailablePlansByGroupId,
  onClose,
  open,
  currentSubscription,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: changePlan } = useChangePlan();
  const [plansMap] = useState(initialAvailablePlansByGroupId);

  const modalInitialValues = useMemo(() => {
    // try to find yearly price
    const allPlans = Object.values(plansMap).flat();
    let highestPricePlan: SubscriptionPlan = allPlans[0];

    for (const plan of allPlans) {
      if (highestPricePlan) {
        const yearlyPrice = plan.prices.find((price) => price.billingCycle === 'yearly');
        const yearlyPriceHighest = highestPricePlan.prices.find((price) => price.billingCycle === 'yearly');
        if (yearlyPrice && yearlyPriceHighest && yearlyPrice > yearlyPriceHighest) {
          highestPricePlan = plan;
        }
      } else {
        highestPricePlan = plan;
      }
    }

    assert(!!highestPricePlan, new Error('There should be a highest price plan'), 'ERROR');

    const yearlyPrice = highestPricePlan.prices.find((p) => p.billingCycle === 'yearly');
    if (yearlyPrice) {
      return {
        planId: highestPricePlan.id,
        billingCycle: yearlyPrice.billingCycle,
      };
    } else {
      return {
        planId: highestPricePlan.id,
        billingCycle: highestPricePlan.prices[0]?.billingCycle,
      };
    }
  }, [plansMap]);

  return (
    <NvDialogModal<{ planId?: string; billingCycle?: BillingCycle }>
      formProps={{ initialValues: modalInitialValues }}
      unmountOnExit
      modalIcon="creditCard"
      Header={'Change plan'}
      maxWidth="sm"
      onCloseButtonClicked={onClose}
      open={open}
      primaryButtonText="Change"
      isLoading={isLoading}
      onFormSubmit={(values) => {
        const { planId, billingCycle } = values;
        assert(
          !!planId && !!billingCycle,
          new Error('To change a plan planId and billing cycle should have a value'),
          'ERROR'
        );

        setIsLoading(true);

        changePlan(
          { planId, billingCycle: billingCycle },
          {
            onSuccess: () => {
              setTimeout(() => {
                setIsLoading(false);
                onClose();
              }, 5000);
            },
            onError: (error) => {
              setIsLoading(false);
              throw error;
            },
            onSettled: () => {
              setTimeout(() => setIsLoading(false), 5000);
            },
          }
        );
      }}
      Body={<ChangePlanModalBody plansMap={plansMap} currentSubscription={currentSubscription} />}
    />
  );
};
