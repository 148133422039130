import { USER_PERMISSION, Variable } from '@novaera/actioner-service';
import { BasicTableCell, NvDeleteOutlineIcon, NvEditIcon, NvMenuWithItems } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { UserPermissionBoundary } from '../../../../../../../../../../../user-permission-boundary';
import { FieldVariableActionProps } from '../../types';
import { BasicTableRowWrapper } from './styled';

export const TableFieldBody = (
  props: {
    variables: Variable[];
  } & FieldVariableActionProps
) => {
  const theme = useTheme();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props?.variables?.map((variable, index) => (
        <UserPermissionBoundary
          key={variable.label}
          permission={USER_PERMISSION.INTEGRATION_UPDATE}
          Fallback={
            <BasicTableRowWrapper>
              <BasicTableCell align="left">{variable.label}</BasicTableCell>
              <BasicTableCell align="left">{variable.data.key}</BasicTableCell>
              <BasicTableCell align="left">{variable.data.value}</BasicTableCell>
              <BasicTableCell align="right"></BasicTableCell>
            </BasicTableRowWrapper>
          }
        >
          <BasicTableRowWrapper
            onClick={() => {
              props.onUpdateFieldClicked({
                fieldVariable: variable,
                rowIndex: index,
              });
            }}
          >
            <BasicTableCell align="left">{variable.label}</BasicTableCell>
            <BasicTableCell align="left">{variable.data.key}</BasicTableCell>
            <BasicTableCell align="left">{variable.data.value}</BasicTableCell>
            <BasicTableCell align="right">
              <NvMenuWithItems
                menuItems={[
                  {
                    name: 'Edit',
                    icon: <NvEditIcon sx={{ width: '16px', height: '16px' }} />,
                    onClick: () => {
                      props.onUpdateFieldClicked({
                        fieldVariable: variable,
                        rowIndex: index,
                      });
                    },
                  },
                  {
                    name: 'divider',
                    isDivider: true,
                  },
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      props.onRemoveFieldClicked(index);
                    },
                  },
                ]}
              />
            </BasicTableCell>
          </BasicTableRowWrapper>
        </UserPermissionBoundary>
      ))}
    </>
  );
};
