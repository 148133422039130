import { useMapSlackUser } from '@novaera/actioner-service';
import { useQueryParams } from '@novaera/route';
import { useEffect } from 'react';

export const useSlackManualUserMapping = () => {
  const { mutate, isSuccess, isError, isLoading, isIdle } = useMapSlackUser();
  const { getSearchParams } = useQueryParams();
  const params = getSearchParams<{ triggerId: string; teamId: string }>();

  useEffect(() => {
    if (params && isIdle) {
      const handleMapSlackUser = async function () {
        return new Promise<void>((resolve) => {
          mutate(params, {
            onSettled: () => {
              resolve();
            },
          });
        });
      };
      handleMapSlackUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    isSuccess,
    isError,
    isLoading,
  };
};
