import { Initial, NvFlex, NvSkeleton } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { useEffect } from 'react';
import { AppDropdownListItem } from '../../app-dropdown-list-item';

export type Item<T = NonNullable<unknown>> = {
  id: string;
  name: string;
  logo?: string;
} & T;

export type SearchResultProps<ListItemType> = {
  onItemSelected: (item: Item<ListItemType>) => void;
  items?: Item<ListItemType>[];
  isLoading: boolean;
  selectedItem?: Item;
  renderCustomLogo?: (item: Item<ListItemType>) => React.ReactElement;
};

export const SearchResult = <ListItemType,>({
  items,
  onItemSelected,
  isLoading,
  selectedItem,
  renderCustomLogo,
}: SearchResultProps<ListItemType>) => {
  const theme = useTheme();

  const { catalogId } = useParams<{ catalogId: string }>();

  useEffect(() => {
    if (items && items.length > 0 && catalogId && selectedItem?.id !== catalogId) {
      const item = items.find((item) => item.id === catalogId);
      if (item) {
        onItemSelected(item);
      }
    } else if (items && items.length > 0 && !catalogId) {
      onItemSelected(items[0]);
    }
  }, [catalogId, selectedItem, items, onItemSelected]);

  return (
    <>
      {items?.map((item) => (
        <AppDropdownListItem
          key={item.id}
          onItemClick={() => {
            onItemSelected(item);
          }}
          name={item.name}
          logo={item.logo}
          logoFallBack={<Initial size={'medium'} value={item.name} color={theme.palette.nv_neutral[500]} />}
          isSelected={selectedItem?.id === item.id}
          item={item}
          renderCustomLogo={renderCustomLogo}
        />
      ))}
      {isLoading && (
        <NvFlex gap={'8px'} paddingLeft={'10px'}>
          <NvFlex gap={'8px'} flexDirection={'row'}>
            <NvSkeleton variant="rectangular" height={'24px'} width={'24px'} />
            <NvSkeleton variant="rectangular" height={'24px'} width={'100%'} />
          </NvFlex>
          <NvFlex gap={'8px'} flexDirection={'row'}>
            <NvSkeleton variant="rectangular" height={'24px'} width={'24px'} />
            <NvSkeleton variant="rectangular" height={'24px'} width={'100%'} />
          </NvFlex>
          <NvFlex gap={'8px'} flexDirection={'row'}>
            <NvSkeleton variant="rectangular" height={'24px'} width={'24px'} />
            <NvSkeleton variant="rectangular" height={'24px'} width={'100%'} />
          </NvFlex>
        </NvFlex>
      )}
    </>
  );
};
