import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';
import { Connection } from '../types';
import { UserConnectionsResponse } from '../use-get-user-connections/types';

import { UseChangeConnectionNameParams } from './types';

const changeConnectionName = async (params: UseChangeConnectionNameParams) => {
  const { connectionId, payload } = params;
  const result = await NvAxios.post<Connection>(`${CONNECTION_ROOT_PATH}/${connectionId}/name`, { ...payload });
  return result?.data;
};

export const useChangeConnectionNameService = () => {
  const cache = useQueryClient();

  return useMutation(changeConnectionName, {
    onSuccess(data) {
      cache.setQueryData(QUERY_KEYS_CONNECTION.detail(data.id), () => {
        return data;
      });

      // also update list's cache
      cache.setQueryData(QUERY_KEYS_CONNECTION.list(), (old?: UserConnectionsResponse) => {
        const userConnection = old?.userConnections.find((userConnection) => userConnection.schemaId === data.schemaId);
        if (!userConnection) {
          return old;
        }

        return {
          ...old,
          userConnections:
            old?.userConnections.map((userConnection) => {
              if (userConnection.schemaId === data.schemaId) {
                return {
                  ...userConnection,
                  connections: userConnection.connections.map((connection) => {
                    if (connection.id === data.id) {
                      return data;
                    }
                    return connection;
                  }),
                };
              }
              return userConnection;
            }) ?? [],
        };
      });
    },
  });
};
