import { useGetIntegration, useGetWorkflow } from '@novaera/actioner-service';
import { NvImage } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { FC, useMemo } from 'react';
import { LogoPlaceholder } from '../../../../../../components/logo-placeholder';
import { Node } from '../../../../../../components/node';
import { NodeStatus } from '../../../../../../components/node/types';
import { NodeType } from '../../../../../../components/property-panel/types';
import { useWorkflowErrorStateProvider } from '../../providers/workflow-error-state-provider';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type ActionProps = BaseNodeProps;

export const Action: FC<React.PropsWithChildren<ActionProps>> = (props) => {
  const nodeId = props.id;
  const { userAppId, workflowId } = useParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const node = workflow?.nodeSummaries?.[props.alias ?? ''];

  assert(
    node !== undefined && node?.type === 'action',
    new Error(`Action node should have action type information in the workflow but type is:${node?.type}`),
    'ERROR'
  );
  const { data: integration } = useGetIntegration({ id: node?.integrationId });

  const { onErrorStateChanged } = useWorkflowErrorStateProvider();

  const showError = useMemo(() => {
    const showError = !node?.actionId;
    onErrorStateChanged({
      nodeId,
      showError,
    });

    return showError;
  }, [node?.actionId, nodeId, onErrorStateChanged]);

  return (
    <DraggableItem nodeId={nodeId}>
      <Node
        {...(showError ? { status: NodeStatus.ERROR } : {})}
        type={NodeType.ACTION}
        icon={
          <NvImage
            src={integration?.logoUrl}
            size="small"
            FallBack={
              <LogoPlaceholder
                size={'small'}
                src="assets/integration-placeholder-image.png"
                initialText={integration?.name ?? 'Integration'}
              />
            }
            hasNoElevation
            imageShape="square"
          />
        }
        {...props}
      />
    </DraggableItem>
  );
};
