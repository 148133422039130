import { StaticCustomCronJobRecurrenceConfiguration } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { CronInput } from '../../../../../../../../../../../jobs/components/cron-input';
import { DEFAULT_CRON_EXPRESSION } from '../../../../../../../../../../../jobs/constants';
import { formatCronExpression, parseCronExpression } from '../../../../../../../../../../../jobs/utils';
import { RecurrenceProps } from '../../types';

export const StaticCustomRecurrence: React.FC<
  Omit<RecurrenceProps<StaticCustomCronJobRecurrenceConfiguration>, 'context'>
> = ({ recurrenceConfiguration, onChange }) => {
  const cron = recurrenceConfiguration.cronExpression ?? DEFAULT_CRON_EXPRESSION;
  const value = formatCronExpression(cron);

  return (
    <CronInput
      value={value}
      direction="column"
      onChange={(v) => {
        const newCronExpression = parseCronExpression(v);
        assert(
          !!newCronExpression,
          new Error('[StaticCustomRecurrence] - newCronExpression can not be null or undefined')
        );
        onChange({
          ...recurrenceConfiguration,
          cronExpression: newCronExpression,
        });
      }}
    />
  );
};
