import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const PropertyPanelSectionContainer = styled(NvBox)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  width: inherit;

  &.dragging-class {
    background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
    border-color: transparent;
    ${({ theme }) => theme.elevations.e300};
    border-radius: 12px;
  }
`;

export const PropertyPanelSectionHeader = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 24px;
`;

export const PropertyPanelSectionHeaderActions = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: 0 0 auto;
`;

export const PropertyPanelSectionBody = styled(NvBox)`
  padding: 8px 0;
`;

export const PropertyPanelSectionHeaderCollapseIconBox = styled(NvBox)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const PropertyPanelSectionHeaderDragIconBox = styled(NvBox)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
