import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_CATALOG_ENTITY_TYPES } from '../keys';
import { CatalogEntityType } from '../types';
import { ListCatalogEntityParams, ListCatalogEntityTypesResponse } from '../use-list-catalog-entity-types/types';

export const useUpdateCatalogEntityCache = () => {
  const cache = useQueryClient();

  const updateCache = ({
    params,
    updateFunction,
  }: {
    params?: ListCatalogEntityParams;
    updateFunction: (param: { old?: InfiniteData<ListCatalogEntityTypesResponse> }) => ListCatalogEntityTypesResponse[];
  }) => {
    cache
      .getQueriesData<InfiniteData<ListCatalogEntityTypesResponse>>({
        queryKey: QUERY_KEY_CATALOG_ENTITY_TYPES.list(params?.queryParams?.nameFilter),
      })
      .forEach((result) => {
        const [query, _] = result;
        cache.setQueryData<InfiniteData<ListCatalogEntityTypesResponse>>(query, (old) => {
          if (old) {
            return {
              ...old,
              pages: updateFunction({
                old,
              }),
            };
          }
          return old;
        });
      });
  };

  const getEntityTypeFromListCache = (id: string) => {
    const result = cache.getQueryData<InfiniteData<ListCatalogEntityTypesResponse>>(
      QUERY_KEY_CATALOG_ENTITY_TYPES.list()
    );
    return result?.pages
      .reduce<CatalogEntityType[]>((acc, page) => [...acc, ...(page.entityTypes ?? [])], [])
      .find((item) => item.id === id);
  };

  return {
    updateInfiniteSearchCache: updateCache,
    getEntityTypeFromListCache: getEntityTypeFromListCache,
  };
};
