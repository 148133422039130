import { useBatchDeleteRecords } from '@novaera/actioner-service';
import {
  NvButton,
  NvDeleteOutlineIcon,
  NvEditIcon,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import React, { ReactNode, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FreeAppPermissionBoundary } from '../../../user-app/user-app-permission-boundary/free-app-permission-boundary';
import { RecordItemActionProps } from './types';

const RecordItemAction = <RecordItemType,>({
  record,
  modelName,
  onRecordEditClick,
}: RecordItemActionProps<RecordItemType>) => {
  const { palette } = useTheme();
  const { userAppId: appId, modelId } = useParams();
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const { mutateAsync: batchDeleteRecords, isLoading: isBatchDeleteRecordsLoading } = useBatchDeleteRecords();
  const handleDeleteRecords = useCallback(() => {
    openConfirm({
      title: 'Delete record?',
      message: (
        <NvTypography variant="body1">
          This record will no longer be available. If there are other tables depend on <strong>{modelName}</strong>,
          some records in such tables may be also impacted.
        </NvTypography>
      ),
      confirmButton: (
        <NvButton
          variant="contained"
          size="medium"
          color="error"
          loading={isBatchDeleteRecordsLoading}
          disabled={isBatchDeleteRecordsLoading}
          onClick={async () => {
            assert(
              !!appId && !!modelId,
              new Error('[RecordItemAction] - Record cannot be deleted without appId and modelId.'),
              'ERROR'
            );
            try {
              assert(!!record, new Error('[RecordItemAction] - Record cannot be deleted without record.'), 'ERROR');

              await batchDeleteRecords({ appId, modelId, recordIds: [record.id] as string[] });
              closeConfirm();
              // eslint-disable-next-line no-empty
            } catch (error) {}
          }}
        >
          Delete
        </NvButton>
      ),
    });
  }, [appId, batchDeleteRecords, closeConfirm, isBatchDeleteRecordsLoading, modelId, modelName, openConfirm, record]);

  return (
    <FreeAppPermissionBoundary>
      <NvMenuWithItems
        triggerButton={{
          content: <NvMoreHorizIcon />,
          props: { onlyIcon: true, size: 'small', color: 'secondary' },
        }}
        menuItems={[
          {
            name: 'Edit',
            onClick: () => {
              onRecordEditClick(record);
            },
            icon: <NvEditIcon sx={{ width: '16px', height: '16px' }} />,
          },
          {
            name: 'divider',
            isDivider: true,
            dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
          },
          {
            name: 'Delete',
            onClick: handleDeleteRecords,
            icon: <NvDeleteOutlineIcon htmlColor={palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          },
        ]}
      />
    </FreeAppPermissionBoundary>
  );
};

// react.memo type definition not working as expected so to avoid type error, we need to cast the type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MemoizedRecordItemAction: <RecordItemType>(props: RecordItemActionProps<any>) => ReactNode = React.memo(
  RecordItemAction,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
