import { AccessType } from '@novaera/actioner-service';
import { AccountCircleIcon, NvImage, ProfileImageProps } from '@novaera/core';
import { Optional } from '@novaera/utils';
import { LogoPlaceholder } from '..';
import { DEFAULT_GROUP_LOGO } from '../../workspace/user-management/groups/constants';
import { isDefaultGroup } from '../../workspace/user-management/groups/utils';

type UserAndGroupImageProps =
  | {
      type: AccessType.USER;
    }
  | { id?: string | 'workspace-admins' | 'everyone'; type: AccessType.GROUP };

export const UserAndGroupImage: React.FC<Optional<ProfileImageProps, 'FallBack'> & UserAndGroupImageProps> = ({
  id,
  type,
  size,
  ...props
}) => {
  switch (type) {
    case AccessType.USER:
      return (
        <NvImage
          size={size}
          {...props}
          FallBack={
            <AccountCircleIcon
              sx={
                size === 'xsmall'
                  ? { width: '16px', height: '16px' }
                  : size === 'smaller'
                  ? { width: '20px', height: '20px' }
                  : size === 'small'
                  ? { width: '24px', height: '24px' }
                  : size === 'medium'
                  ? { width: '32px', height: '32px' }
                  : size === 'large'
                  ? { width: '40px', height: '40px' }
                  : size === 'xlarge'
                  ? { width: '60px', height: '60px' }
                  : { width: '32px', height: '32px' }
              }
            />
          }
          imageShape="circle"
        />
      );
    case AccessType.GROUP:
      return (
        <NvImage
          size={size}
          {...props}
          FallBack={
            id && isDefaultGroup(id) ? (
              DEFAULT_GROUP_LOGO({ size })[id]
            ) : (
              <LogoPlaceholder size={size} src="assets/group_fallback_img.png" initialText="Group logo" />
            )
          }
          imageShape="square"
        />
      );
  }
};
