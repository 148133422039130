export enum NodeType {
  OUTPUT = 'output',
  INTEGRATION_TRIGGER = 'integration_trigger',
  FORM_TRIGGER = 'form_trigger',
  ACTIONER_EVENT_TRIGGER = 'actioner_event_trigger',
  ACTIONER_EVENT_PUBLISHER = 'actioner_event_publisher',
  EMAIL_TRIGGER = 'email_trigger',
  WEBHOOK_TRIGGER = 'webhook_trigger',
  ACTION = 'action',
  LOOP = 'loop',
  CONDITION = 'condition',
  BRANCH = 'branch',
  DELAY = 'delay',
  WORKFLOW = 'workflow',
  WORKFLOW_DISPATCHER = 'workflow_dispatcher',
  FUNCTION = 'function',
  EMITTER = 'emitter',
  RESPONSE = 'response',
  WORKFLOW_RESOLVER = 'workflow_resolver',
  QA = 'QA_resolver',
  SEND_EMAIL = 'send_email',
  ASSISTANT = 'assistant',
  GET_CONVERSATION = 'get_conversation',
  JOB = 'job',
  FILE = 'file',
  PUT_FILE = 'put',
  LIST_FILE = 'list',
  DELETE_FILE = 'delete',
  TRANSFER_FILE = 'transfer',
  CHANGE_ACCESSIBILITY_FILE = 'change-accessibility',
  LINK_GENERATOR = 'linkGenerator',
  HTTP = 'http',
  ASSISTANT_CANCEL_RUN = 'assistant_cancel_run',
}
