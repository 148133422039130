import { isAxiosError, NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { useGenerateQueryKey } from '../common/utils';
import { QUERY_KEYS_RECORDS } from '../keys';
import { SearchRecordsParams, SearchRecordsResponse } from './types';

export const searchRecords: (params: SearchRecordsParams) => Promise<SearchRecordsResponse> = async ({
  appId,
  modelId,
  queryParams,
}) => {
  const result = await NvAxios.post<SearchRecordsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/data-models/${modelId}/records/search`,
    {
      ...queryParams,
    }
  );
  return result?.data;
};

export const useSearchRecords = (params: SearchRecordsParams) => {
  const { generateQueryKey } = useGenerateQueryKey();
  const queryKey = generateQueryKey(params.queryParams);
  return useQuery<SearchRecordsResponse>(
    QUERY_KEYS_RECORDS.detail({ appId: params.appId, modelId: params.modelId, queryKey }),
    () => searchRecords(params),
    {
      enabled: !!params.appId && !!params.modelId,
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response?.status === 401) {
          return failureCount < 3;
        } else {
          return false;
        }
      },
    }
  );
};
