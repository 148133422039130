import { GroupWrapper } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const ValueDisplayGroupWrapper = styled(GroupWrapper)`
  padding: 6px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};

  .checkbox-group-container,
  .radio-group-container {
    padding: 7px 4px;
  }
`;
