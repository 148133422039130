import { styled } from '@novaera/theme-provider';

import { Chip } from '../chip/styled';

import { NvLink } from '../link';

export const NoTextDecorationLink = styled(NvLink)`
  ${({ href }) => !href && `cursor: default;`}

  &:hover {
    text-decoration: none;
  }
`;

export const StyledNvChip = styled(Chip)`
  transition: background-color 200ms ease;
  background-color: ${({ theme: { palette } }) => palette.nv_neutral_alpha[30]};
  &:hover {
    background-color: ${({ theme: { palette } }) => palette.nv_neutral_alpha[40]};
  }
`;
