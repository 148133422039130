import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

import { NvFlex, NvTabs } from '@novaera/core';
import { shouldForwardPropForFormFields } from '@novaera/utils';

export const ActionDesignerWrapper = styled(NvBox)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
  height: 100%;
`;

export const StyledTabsWrapper = styled(NvTabs, { shouldForwardProp: shouldForwardPropForFormFields })<{
  isCollapsed?: boolean;
}>`
  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        transition: border 0.3s ease-in-out;
        ${({ isCollapsed }) => isCollapsed && 'border-color: transparent'};
      }
    }
  }

  .tab-body-container {
    height: 100%;
    min-height: 0;
    overflow: auto;

    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    ${({ isCollapsed }) => isCollapsed && 'opacity: 0'};
  }

  & .header-action {
    transition: border 0.3s ease-in-out;
    ${({ isCollapsed }) => isCollapsed && 'border-color: transparent'};
  }

  .MuiBox-root {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    ${({ isCollapsed }) => isCollapsed && 'opacity: 0'};
  }
`;

export const BodyWrapper = styled(NvFlex, { shouldForwardProp: shouldForwardPropForFormFields })<{
  isCollapsed?: boolean;
  isLastItem?: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  height: 100%;
  ${({ isCollapsed }) => !isCollapsed && 'flex: 1 1 auto'};

  min-height: 0;
  border-radius: 12px 12px ${({ isLastItem }) => (isLastItem ? '0 0' : '12px 12px')};
  ${({ theme }) => theme.elevations.e100};

  overflow: hidden;
`;

export const Divider = styled(NvFlex)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  flex: 0 0 auto;
  cursor: row-resize;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 1px);
    width: 0;
    height: 2px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, width 0.2s ease-in-out;
    border-radius: 1px;
  }

  &::before {
    right: calc(50% + 32px);
    background: linear-gradient(to left, rgba(84, 126, 212, 1) 0, rgba(84, 126, 212, 0) 100%);
  }

  &::after {
    left: calc(50% + 32px);
    background: linear-gradient(to right, rgba(84, 126, 212, 1) 0, rgba(84, 126, 212, 0) 100%);
  }

  &:hover,
  &:active {
    .DividerIconItem {
      background-color: ${({ theme }) => theme.palette.nv_main[40]};
    }

    &::before,
    &::after {
      opacity: 1;
      width: 30%;
    }
  }
`;

export const DividerIconBox = styled(NvFlex)`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 34px;
  gap: 2px;
`;

export const DividerIconItem = styled(NvFlex)`
  flex: 0 0 auto;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.nv_neutral_alpha[300]};
  transition: background-color 0.3s ease-in-out;
`;
