import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_APP_ROOT_PATH } from '../../user-app/constants';
import { QUERY_KEYS_WORKFLOWS } from '../keys';
import { GetWorkflowResponse } from '../use-get-workflow/types';
import { SaveWorkflowPermissionsParams, SaveWorkflowPermissionsResponse } from './types';

const saveWorkflowPermissions = async ({ appId, workflowId, permissions }: SaveWorkflowPermissionsParams) => {
  const result = await NvAxios.put<SaveWorkflowPermissionsResponse>(
    `${USER_APP_ROOT_PATH}/${appId}/workflows/${workflowId}/permissions/batch`,
    {
      permissions,
    }
  );
  return result?.data;
};

export const useSaveWorkflowPermissions = () => {
  const cache = useQueryClient();
  return useMutation(saveWorkflowPermissions, {
    onSuccess: (updatedWorkflow, { appId, workflowId }) => {
      cache.setQueryData<GetWorkflowResponse>(QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId }), (old) =>
        old
          ? {
              ...old,
              saved: { ...old?.saved, permissions: updatedWorkflow.permissions },
            }
          : { saved: updatedWorkflow }
      );
    },
  });
};
