import { NvFlex, NvSkeleton } from '@novaera/core';

export const AIAssistantSectionLoading = () => (
  <NvFlex gap="8px" alignItems="flex-start" padding="16px 0">
    <NvFlex direction="row" alignItems="center" gap="4px">
      <NvSkeleton width="20px" height="20px" variant="rectangular" />
      <NvSkeleton width="120px" height="18px" variant="rectangular" />
    </NvFlex>
    <NvSkeleton width="100%" height="18px" variant="rectangular" />

    <NvSkeleton width="100%" height="96px" variant="rectangular" />
  </NvFlex>
);
