import {
  AccessSetting,
  AccessType,
  GroupAccessSetting,
  UserAccessSetting,
  useBatchGetGroups,
  useBatchGetUsers,
  useGetUserApp,
} from '@novaera/actioner-service';
import { useMemo } from 'react';

export const useUserAppUsersWithPermissions = ({ userAppId }: { userAppId: string }) => {
  const { data: userApp, isInitialLoading: isUserAppLoading } = useGetUserApp(userAppId);

  const userIds = useMemo<string[] | undefined>(
    () =>
      userApp?.accessSettings
        .filter(
          (accessSetting: AccessSetting): accessSetting is UserAccessSetting =>
            accessSetting.principalType === AccessType.USER
        )
        .map((accessSetting) => accessSetting.principalId),
    [userApp?.accessSettings]
  );

  const groupIds = useMemo<string[] | undefined>(
    () =>
      userApp?.accessSettings
        .filter(
          (accessSetting: AccessSetting): accessSetting is GroupAccessSetting =>
            accessSetting.principalType === AccessType.GROUP
        )
        .map((accessSetting) => accessSetting.principalId),
    [userApp?.accessSettings]
  );

  const { data, isInitialLoading: isBatchGetUsersLoading } = useBatchGetUsers(userIds);

  const { data: groups, isInitialLoading: isBatchGetGroupsLoading } = useBatchGetGroups(groupIds);

  const userAppAccessUsers = useMemo(
    () =>
      userApp?.accessSettings.map((accessSetting) => {
        if (accessSetting.principalType === AccessType.USER) {
          const userDetail = data?.users.find(({ userId }) => userId === accessSetting.principalId);
          return { ...accessSetting, ...(userDetail ? { userDetail } : {}) };
        } else if (accessSetting.principalType === AccessType.GROUP) {
          const groupDetail = groups?.groups.find(({ id }) => id === accessSetting.principalId);
          return { ...accessSetting, ...(groupDetail ? { groupDetail } : {}) };
        } else {
          return accessSetting;
        }
      }) ?? [],
    [userApp?.accessSettings, data?.users, groups?.groups]
  );

  const userAppAdmins = useMemo(() => userAppAccessUsers.filter((user) => user.editGranted), [userAppAccessUsers]);

  return {
    userAppAccessUsers,
    isUserAppLoading,
    isBatchGetLoading: isBatchGetGroupsLoading || isBatchGetUsersLoading,
    userApp,
    userAppAdmins,
  };
};
