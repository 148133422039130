import { NvButton, NvConditionalRender, NvOpenInNewIcon } from '@novaera/core';
import React from 'react';
import { useConnectModalFooterController } from './controller/use-connect-modal-footer';
import { ConnectModalFooterProps } from './types';

export const ConnectModalFooter = ({
  connected,
  mode,
  response,
  isLoading,
  formSubmitResult,
  connectionSchema,
  onModalCloseClicked,
}: ConnectModalFooterProps) => {
  const {
    PredefinedConnectionButton,
    hasResponse,
    isConnected,
    isNotConnected,
    isTestConnection,
    hideConnectButton,
    isSlackConnection,
    isMsTeamsConnection,
  } = useConnectModalFooterController({ response, formSubmitResult, connectionSchema, connected, mode });

  return (
    <>
      <NvConditionalRender when={() => isConnected}>
        <NvConditionalRender when={() => Boolean(PredefinedConnectionButton) && !hideConnectButton}>
          {PredefinedConnectionButton?.component && React.createElement(PredefinedConnectionButton?.component)}
        </NvConditionalRender>

        <NvConditionalRender when={() => !PredefinedConnectionButton && !hideConnectButton}>
          <NvButton
            loading={isLoading}
            type="submit"
            {...(isSlackConnection || isMsTeamsConnection ? { endIcon: <NvOpenInNewIcon /> } : {})}
          >
            Reconnect
          </NvButton>
        </NvConditionalRender>

        <NvButton onClick={onModalCloseClicked} color="secondary">
          Cancel
        </NvButton>
      </NvConditionalRender>

      <NvConditionalRender when={() => isNotConnected}>
        <NvConditionalRender when={() => Boolean(PredefinedConnectionButton) && !hideConnectButton}>
          {PredefinedConnectionButton?.component()}
        </NvConditionalRender>

        <NvConditionalRender when={() => !PredefinedConnectionButton && !hideConnectButton}>
          <NvButton
            loading={isLoading}
            type="submit"
            {...(isSlackConnection || isMsTeamsConnection ? { endIcon: <NvOpenInNewIcon /> } : {})}
          >
            Connect
          </NvButton>
        </NvConditionalRender>

        <NvButton onClick={onModalCloseClicked} color="secondary">
          Cancel
        </NvButton>
      </NvConditionalRender>

      <NvConditionalRender when={() => isTestConnection}>
        <NvConditionalRender when={() => Boolean(PredefinedConnectionButton)}>
          {PredefinedConnectionButton?.component()}
        </NvConditionalRender>

        <NvConditionalRender when={() => !PredefinedConnectionButton}>
          <NvButton loading={isLoading} type="submit">
            Test connection
          </NvButton>
        </NvConditionalRender>

        <NvButton onClick={onModalCloseClicked} color="secondary">
          Cancel
        </NvButton>
      </NvConditionalRender>

      <NvConditionalRender when={() => hasResponse}>
        <NvButton onClick={onModalCloseClicked} disabled={isLoading} color="secondary">
          Close
        </NvButton>
        <NvButton loading={isLoading} type="submit">
          Retry
        </NvButton>
      </NvConditionalRender>
    </>
  );
};
