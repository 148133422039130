import { useChangeConnectionNameService } from '@novaera/actioner-service';
import { ConnectionNameUpdateModalFormType } from '../../types';
import { UseConnectionNameUpdateControllerParams } from './types';

export const useConnectionNameUpdateController = ({
  connectionId,

  onClose,
}: UseConnectionNameUpdateControllerParams) => {
  const { mutate: changeConnectionName, isLoading } = useChangeConnectionNameService();

  const handleFormSubmit = (value: ConnectionNameUpdateModalFormType) => {
    changeConnectionName({
      connectionId,
      payload: {
        name: value.name,
      },
    });
    onClose();
  };

  return {
    isLoading,
    onFormSubmit: handleFormSubmit,
  };
};
