import { ReactPlayerProps } from 'react-player';

export enum YOUTUBE_VIDEO_QUALITY {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  MAXIMUM = 'maximum',
}

export interface CarouselVideoProps extends ReactPlayerProps {
  disabled?: boolean;
}
