import { FC } from 'react';
import { Node } from '../../../../../../components/node';
import { NodeType } from '../../../../../../components/property-panel/types';
import { DraggableItem } from '../../utils/nodes-drag-and-drop/draggable-item';
import { BaseNodeProps } from '../types';

export type GetConversationProps = BaseNodeProps;

export const GetConversation: FC<GetConversationProps> = (props) => (
  <DraggableItem nodeId={props.id}>
    <Node type={NodeType.GET_CONVERSATION} {...props} />
  </DraggableItem>
);
