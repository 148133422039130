import { assert } from '@novaera/utils';
import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

const SelectedAppIdContext = createContext<
  | {
      selectedAppId?: string;
      setSelectedAppId: (id?: string) => void;
    }
  | undefined
>(undefined);

export const SelectedAppIdProvider: FC<PropsWithChildren<{ initialAppId?: string }>> = ({ children, initialAppId }) => {
  const [selectedAppId, setSelectedAppId] = useState<string | undefined>(initialAppId);

  const value = { selectedAppId, setSelectedAppId };

  return <SelectedAppIdContext.Provider value={value}>{children}</SelectedAppIdContext.Provider>;
};

export const useSelectedAppIdContext = () => {
  const context = useContext(SelectedAppIdContext);
  assert(!!context, new Error(`useSelectedAppId should be used within SelectedAppIdProvider`), 'ERROR');

  return context;
};
