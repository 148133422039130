import { NvAxios, useQuery } from '@novaera/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { WORKFLOW_ROOT_PATH } from '../constants';
import { QUERY_KEYS_WORKFLOWS_SEARCH, QUERY_KEYS_WORKFLOWS_SEARCH_SINGLE } from '../keys';
import { SearchWorkflowResponse, SearchWorkflowsParams } from './types';

export const searchWorkflows: (
  params: SearchWorkflowsParams,
  pageParam?: string
) => Promise<SearchWorkflowResponse> = async (params, pageParam) => {
  const result = await NvAxios.post<SearchWorkflowResponse>(WORKFLOW_ROOT_PATH, {
    ...params,
    ...(params.limit ? { limit: params.limit } : { limit: 20 }),
    ...(params.sort ? { sort: params.sort } : {}),
    ...(pageParam ? { from: pageParam } : {}),
  });
  return result?.data;
};

export const useSearchWorkflows = (params: SearchWorkflowsParams) => {
  return useQuery<SearchWorkflowResponse>(QUERY_KEYS_WORKFLOWS_SEARCH_SINGLE.list(params), () =>
    searchWorkflows(params)
  );
};

export const useSearchInfiniteWorkflows = (params: SearchWorkflowsParams) => {
  const [dataFetchCount, setDataFetchCount] = useState<number>(0);
  const query = useInfiniteQuery<SearchWorkflowResponse>(
    QUERY_KEYS_WORKFLOWS_SEARCH.list(params),
    ({ pageParam = 0 }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return searchWorkflows(params, pageParam) as any;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.paging.next_token,
      refetchInterval: (data, query) => {
        setDataFetchCount(query.state.dataUpdateCount);
        return data?.pages[0].workflows.length === 0 && query.state.dataUpdateCount < 2 ? 3000 : false;
      },
    }
  );
  return {
    ...query,
    isLoading: query.isLoading || (query.data?.pages[0].workflows.length === 0 && dataFetchCount < 2),
  };
};
