import {
  FieldSchema,
  ListValue,
  ObjectValue,
  ParameterTypes,
  SimpleParameterMapping,
  StringValue,
  ValueTypes,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useCallback } from 'react';
import { useCodeInputFieldAdapter } from '../../use-code-input-field-adapter';
import { CodeInputFormatValueFunction, CodeInputParseValueFunction } from '../../use-code-input-field-adapter/types';
import { fieldSchemaToValue } from '../../utils';

export const useDisplayValueFieldAdapter = ({ hidden }: { hidden: boolean }) => {
  const parseDisplayValue: CodeInputParseValueFunction =
    ({ schema }) =>
    (valueToParse) => {
      if (valueToParse) {
        assert(!!schema, new Error('Schema should be defined for textfield'), 'ERROR');
        const valueType = fieldSchemaToValue(schema);

        if (valueType === ValueTypes.OBJECT) {
          const retVal: ObjectValue = {
            type: valueType,
            codeTemplate: valueToParse,
          };
          return retVal;
        } else if (
          valueType === ValueTypes.STRING ||
          valueType === ValueTypes.NUMBER ||
          valueType === ValueTypes.BOOLEAN
        ) {
          const retVal: StringValue = {
            type: ValueTypes.STRING,
            value: valueToParse,
          };
          return retVal;
        } else if (valueType === ValueTypes.LIST) {
          const retVal: ListValue = {
            type: valueType,
            codeTemplate: valueToParse,
          };
          return retVal;
        } else {
          throw new Error(`Unknown value type: ${valueType}`);
        }
      } else {
        return undefined;
      }
    };

  const { format: codeInputFormatValue, parse: codeInputParseValue } = useCodeInputFieldAdapter<
    CodeInputFormatValueFunction,
    CodeInputParseValueFunction
  >({
    extractValue: true,
    hidden,
    forceValueUsage: false,
  });

  const format = useCallback(
    (parameterMapping: SimpleParameterMapping | undefined | null) => {
      if (parameterMapping) {
        return {
          value: codeInputFormatValue(parameterMapping.value),
          displayValue: codeInputFormatValue(parameterMapping.displayValue),
        };
      } else {
        return { value: '', displayValue: '' };
      }
    },
    [codeInputFormatValue]
  );

  const parse = useCallback(
    ({ schema, id }: { schema?: FieldSchema; id: string }) =>
      (valueToParse: { value: string | null; displayValue: string | null }): SimpleParameterMapping | undefined => {
        const value = codeInputParseValue({ schema, id, removeDisplayValue: false })(valueToParse.value);
        const displayValue = parseDisplayValue({ schema, id })(valueToParse.displayValue);
        if (value || displayValue || hidden) {
          return {
            parameterId: id,
            type: ParameterTypes.SIMPLE,
            hidden,
            ...(value && { value }),
            ...(displayValue && { displayValue }),
          };
        } else {
          return undefined;
        }
      },
    [codeInputParseValue, hidden]
  );

  return { format, parse };
};
