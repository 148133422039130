import { APP_PERMISSION, useUpdateUserAppDescription } from '@novaera/actioner-service';
import { NvButton, NvInlineEdit, NvMarkDownEditor, isMaxLength } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { DescriptionView } from '../../../../components/description-view';
import { EmptyState } from '../../../../components/empty-state';
import { EmptyStates } from '../../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { UserAppDescriptionProps } from './types';

export const UserAppDescription: FC<React.PropsWithChildren<UserAppDescriptionProps>> = ({ description }) => {
  const { userAppId } = useParams();
  const { mutateAsync } = useUpdateUserAppDescription();
  const [descriptionValue, setDescriptionValue] = useState(description?.value);
  const onConfirm = useCallback(
    async (v?: string) => {
      assert(!isUndefined(v), new Error('App description value should be defined!!'), 'ERROR');

      const newDescription = { value: v, type: description?.type ?? 'markdown' };
      setDescriptionValue(v);
      try {
        await mutateAsync(
          { appId: userAppId, description: newDescription },
          {
            onError: () => {
              setDescriptionValue(description?.value);
            },
          }
        );
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    [description?.type, description?.value, mutateAsync, userAppId]
  );

  return (
    <FreeAppPermissionBoundary Fallback={<DescriptionView value={descriptionValue} />}>
      <UserAppPermissionBoundary
        appId={userAppId}
        permission={APP_PERMISSION.APP_EDIT}
        Fallback={<DescriptionView value={descriptionValue} />}
      >
        <NvInlineEdit
          viewComponent={<DescriptionView />}
          editComponent={<NvMarkDownEditor />}
          value={descriptionValue}
          validate={(value) => isMaxLength({ length: 3000 })(value)}
          onConfirm={onConfirm}
          renderCustomEmptyState={({ onClick }) => (
            <EmptyState
              variant={EmptyStates.APP_DESCRIPTION}
              CustomButton={
                <NvButton color="secondary" onClick={onClick} size="small">
                  Add description
                </NvButton>
              }
            />
          )}
        />
      </UserAppPermissionBoundary>
    </FreeAppPermissionBoundary>
  );
};
