import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_INTEGRATIONS_PATH } from '../constant';

const downloadIntegration = async ({ integrationId }: { integrationId: string }) => {
  const result = await NvAxios.get(`${ADMIN_INTEGRATIONS_PATH}/${integrationId}/download`);
  return result?.data;
};

export const useDownloadIntegration = () => {
  return useMutation(downloadIntegration);
};
