import {
  AppDocumentCategory,
  useFetchProfile,
  useGetAppDirectorySearchMetadata,
  useUpdateSchemaMetadata,
} from '@novaera/actioner-service';
import { AppDocuments, Contributors, ImagesAndVideos, NvLogoUpload } from '@novaera/actioner-ui';
import {
  Initial,
  NvAutocomplete,
  NvButton,
  NvCloseIcon,
  NvCloudUploadIcon,
  NvDivider,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvForm,
  NvMarkDownEditor,
  NvTextField,
  NvTypography,
  isMaxLength,
  isMinLength,
  isRequired,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';
import { useMemo } from 'react';
import { AppMetadataUpdateForm, AppMetadataUpdateProps } from './types';

export const AppMetadataUpdate: React.FC<AppMetadataUpdateProps> = ({ appSchema }) => {
  const theme = useTheme();
  const { data: userData } = useFetchProfile();
  const { data: searchMetadata } = useGetAppDirectorySearchMetadata({
    isAnonymous: !userData,
  });
  const { mutate: updateMetadata } = useUpdateSchemaMetadata();

  const initialValues = useMemo(() => {
    return {
      logoId: '',
      logoUrl: appSchema.logoUrl,
      appName: appSchema.name,
      description: appSchema.description,
      shortDescription: appSchema.shortDescription,
      descriptionType: 'markdown',
      categories: appSchema.categories,
      contributors: appSchema.contributors,
      mediaFiles: appSchema.mediaFiles,
      documents: {
        useCases: appSchema.documents?.filter((doc) => doc.category === AppDocumentCategory.USE_CASE) ?? [],
        support: appSchema.documents?.filter((doc) => doc.category === AppDocumentCategory.SUPPORT) ?? [],
      },
    };
  }, []);

  const handleSubmit = ({
    logoId,

    appName,
    description,
    descriptionType,
    shortDescription,
    categories,
    contributors,
    mediaFiles,
    documents,
  }: AppMetadataUpdateForm) => {
    return new Promise<void>((resolve) => {
      updateMetadata(
        {
          schemaId: appSchema.id,
          payload: {
            appDetails: {
              logoId,
              appName,
              description,
              descriptionType,
              shortDescription,
              categories,
              contributors,
              mediaFiles,
              documents: documents ? [...(documents.support ?? []), ...(documents.useCases ?? [])] : [],
            },
          },
        },
        {
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };
  return (
    <NvForm<AppMetadataUpdateForm>
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({ submitting, form, values }) => (
        <NvFlex gap="24px">
          <NvFlex spacing="4px">
            <NvFlex direction="row">
              <NvTypography variant="h5">App icon</NvTypography>
              <NvTypography variant="h5" color={theme.palette.nv_error[40]}>
                *
              </NvTypography>
            </NvFlex>

            <NvLogoUpload
              type="app-schema"
              imageShape="square"
              imageSize="xlarge"
              ImageFallBack={<Initial size="xlarge" color={theme.palette.nv_neutral[500]} value={values.appName} />}
              logoUploadButtonProps={{ text: 'Upload image', startIcon: <NvCloudUploadIcon /> }}
              logoUploadHintProps={{ text: 'Recommended size 120px square', variant: 'h5' }}
              onLogoStateChanged={({ logo: { id }, logoUrl }) => {
                form.change('logoId', id);
                form.change('logoUrl', logoUrl);
              }}
              key={values.logoUrl}
              initialLogoUrl={values.logoUrl}
            />
          </NvFlex>

          <NvDivider />
          <NvField
            name="appName"
            labelText="App name"
            labelVariant="h5"
            direction="label-on-top"
            validators={[isRequired()]}
            showErrorMessageOnlyWhenBlur
            hasRequiredIndicator
            component={<NvTextField />}
          />

          <NvDivider />
          <NvField
            name="shortDescription"
            labelText="Short description"
            labelVariant="h5"
            direction="label-on-top"
            validators={[isRequired(), isMaxLength({ length: 160 })]}
            showErrorMessageOnlyWhenBlur
            hasRequiredIndicator
            component={<NvTextField />}
          />

          <NvDivider />
          <NvField
            name="categories"
            labelText="Categories"
            labelVariant="h5"
            direction="label-on-top"
            isAutoComplete
            validators={[isRequired()]}
            showErrorMessageOnlyWhenBlur
            hasRequiredIndicator
            component={
              <NvAutocomplete
                options={searchMetadata?.availableCategories ?? []}
                getOptionLabel={(option) => option}
                renderInput={(props) => <NvTextField {...props} placeholder="Select categories" />}
                clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
                popupIcon={<NvExpandMoreIcon />}
                multiple
              />
            }
          />
          <NvDivider />
          <NvField
            name="description"
            labelText="Description"
            labelVariant="h5"
            direction="label-on-top"
            showErrorMessageOnlyWhenBlur
            component={<NvMarkDownEditor />}
            isRequired
            validators={[
              isRequired(),
              isMinLength({
                length: 3,
              }),
              isMaxLength({
                length: 3000,
              }),
            ]}
            hasRequiredIndicator
          />
          <NvDivider />
          <Contributors fieldName="contributors" contributors={values.contributors} />
          <NvDivider />
          <ImagesAndVideos title="Images & Videos" fieldName="mediaFiles" enableAutomaticAddModal={false} />
          <NvDivider />
          <AppDocuments title="Support" fieldName="documents.support" category={AppDocumentCategory.SUPPORT} />
          <NvDivider />
          <AppDocuments title="Use cases" fieldName="documents.useCases" category={AppDocumentCategory.USE_CASE} />
          <NvFlex flexDirection={'row-reverse'} gap="16px">
            <NvButton loading={submitting} disabled={submitting} type="submit">
              Update Metadata
            </NvButton>
          </NvFlex>
        </NvFlex>
      )}
    </NvForm>
  );
};
