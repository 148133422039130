import { styled } from '@novaera/theme-provider';

export const AppLoadingWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
`;
