import { NvChevronRightIcon, NvDivider, NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { HeaderWrapperLeftBoxIcon } from '../../styled';
import { RequestRefName } from './request-ref-name';
import { DetailTitleProps } from './types';

export const DetailTitle: React.FC<React.PropsWithChildren<DetailTitleProps>> = ({ name, type, onTitleClick }) => {
  const { palette } = useTheme();
  return (
    <>
      {onTitleClick && (
        <HeaderWrapperLeftBoxIcon onClick={onTitleClick}>
          <NvChevronRightIcon className="chevron-icon" />
        </HeaderWrapperLeftBoxIcon>
      )}
      {(name || type) && (
        <NvFlex
          direction="row"
          alignItems="center"
          gap="8px"
          {...(!onTitleClick && {
            marginLeft: '12px',
          })}
        >
          {name && (
            <>
              <NvTypography
                noWrap
                {...(onTitleClick && {
                  onClick: onTitleClick,
                })}
                variant="h2"
                sx={{ cursor: 'pointer' }}
              >
                {name}
              </NvTypography>
              <NvDivider orientation="vertical" sx={{ margin: '4px 0', height: '16px' }} />
              {type !== 'Function' && <RequestRefName name={name} />}
            </>
          )}

          {type && (
            <NvTypography variant="c2" color={palette.nv_accent[60]}>
              {type}
            </NvTypography>
          )}
        </NvFlex>
      )}
    </>
  );
};
