import { NvAxios, useMutation } from '@novaera/core';
import { CreateCustomPlanParameters } from './types';

const createCustomPlanConfiguration = async (params: CreateCustomPlanParameters) => {
  const result = await NvAxios.post('/admin/subscriptions', {
    ...params,
  });
  return result?.data;
};

export const useCreateCustomPlanConfiguration = () => {
  return useMutation(createCustomPlanConfiguration);
};
