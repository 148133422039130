import { GetIntegrationActionsParams } from './types';

export const QUERY_KEYS_INTEGRATION_ACTIONS = {
  all: ['integration-actions'] as const,
  list: (integrationId?: string, version?: number, params?: GetIntegrationActionsParams) =>
    [
      ...QUERY_KEYS_INTEGRATION_ACTIONS.all,
      ...(integrationId ? [integrationId] : []),
      ...(version ? [version] : []),
      ...(params ? [params] : []),
    ] as const,
  detail: (integrationId?: string, id?: string, version?: number) =>
    [
      ...QUERY_KEYS_INTEGRATION_ACTIONS.list(integrationId),
      'detail',
      ...(id ? [id] : []),
      ...(version ? [version] : []),
    ] as const,
};
