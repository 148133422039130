import { NvBox } from '../../../box';
import { NvSkeleton } from '../../../skeleton';
import { NodeListItemCardLoading, NodeListItemContent } from '../styled';

export const NodeListItemLoading = () => (
  <NodeListItemCardLoading>
    <NvBox flex="0 0 auto">
      <NvSkeleton variant="circular" width="24px" height="24px"></NvSkeleton>
    </NvBox>

    <NodeListItemContent>
      <NvSkeleton variant="rectangular" height="16px" width="30%" />
      <NvSkeleton variant="rectangular" height="12px" width="60%" />
    </NodeListItemContent>
  </NodeListItemCardLoading>
);
