import { APP_PERMISSION } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvCustomShareIcon,
  NvFlex,
  NvSearchEmptyState,
  NvSensorsIcon,
  NvShieldRoundedIcon,
  NvTypography,
} from '@novaera/core';
import { BaseItem, SimpleSearchInput } from '../../../components';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../user-app-permission-boundary/free-app-permission-boundary';
import { USER_APP_EVENT_DETAIL } from '../constants';
import { useEvents } from './controllers/use-events';
import { EventModal } from './event-modal';
import { EventsItemsLoading, EventsLoading } from './loading-components';

export const Events = () => {
  const {
    isInitialLoading,
    isSearchBarTouched,
    hasRecord,
    searchKeyword,
    userAppId,
    onAddEventClicked,
    setSearchKeyword,
    filteredSearchResult,
    menuItems,
    isAddEventModalOpen,
    onEventModalClose,
  } = useEvents();

  return isInitialLoading && !isSearchBarTouched.current ? (
    <EventsLoading />
  ) : (
    <>
      <NvFlex gap="16px">
        <NvFlex direction="row" gap="8px" alignItems="center">
          <NvSensorsIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
          <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
            Events
          </NvTypography>
        </NvFlex>
        {!isInitialLoading && !hasRecord && searchKeyword.length === 0 ? (
          <EmptyState
            variant={EmptyStates.ACTIONER_EVENT}
            CustomButton={
              <FreeAppPermissionBoundary appId={userAppId}>
                <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                  <NvButton
                    startIcon={<NvAddBoxIcon />}
                    color="secondary"
                    onClick={onAddEventClicked}
                    size="small"
                    sx={{ flex: '0 0 auto' }}
                  >
                    Add event
                  </NvButton>
                </UserAppPermissionBoundary>
              </FreeAppPermissionBoundary>
            }
          />
        ) : (
          <>
            <NvFlex direction="row" alignItems="center" justifyContent="space-between">
              <SimpleSearchInput
                placeholder="Search event"
                onKeywordChanged={(keyword) => {
                  setSearchKeyword(keyword ?? '');
                  isSearchBarTouched.current = true;
                }}
              />
              <FreeAppPermissionBoundary appId={userAppId}>
                <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                  <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={onAddEventClicked}>
                    Add event
                  </NvButton>
                </UserAppPermissionBoundary>
              </FreeAppPermissionBoundary>
            </NvFlex>

            {isInitialLoading && isSearchBarTouched.current ? (
              <EventsItemsLoading />
            ) : !hasRecord && searchKeyword.length > 0 ? (
              <NvSearchEmptyState text={'No event found. Try different words or clear search bar.'} />
            ) : (
              <NvFlex gap="8px">
                {filteredSearchResult?.map(({ id, name, sharedAcrossApps, description, tags }) => {
                  const eventMenuItems = menuItems({ id, name });
                  return (
                    <BaseItem
                      key={`event-item-${id}`}
                      name={
                        <NvFlex
                          direction="row"
                          alignItems="center"
                          gap="8px"
                          flex="0 1 auto"
                          minWidth={0}
                          width={'100%'}
                        >
                          <NvFlex gap={'4px'} direction="row" alignItems="center" flex="1 1 auto" minWidth={0}>
                            <NvTypography variant="h4" noWrap>
                              {name}
                            </NvTypography>
                          </NvFlex>
                          <NvFlex flexDirection={'row'} gap={'4px'}>
                            {sharedAcrossApps ? (
                              <NvCustomShareIcon sx={{ width: '16px', height: '16px' }} />
                            ) : (
                              <NvShieldRoundedIcon sx={{ width: '16px', height: '16px' }} />
                            )}
                            <NvTypography variant="h5">{sharedAcrossApps ? 'Shared' : 'Private'}</NvTypography>
                          </NvFlex>
                        </NvFlex>
                      }
                      to={USER_APP_EVENT_DETAIL(userAppId, id)}
                      menuItems={eventMenuItems}
                      description={description}
                      tags={tags}
                    />
                  );
                })}
              </NvFlex>
            )}
          </>
        )}
      </NvFlex>
      <EventModal opened={isAddEventModalOpen} onClose={onEventModalClose} />
    </>
  );
};
