import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { QUERY_KEYS_INPUT_PARAMETER_OPTIONS } from '../keys';
import { RemoveCacheForInputParameter } from './types';

export const useResetOptions = () => {
  const client = useQueryClient();

  const removeCacheForInputParameter = useCallback(
    ({ actionId, draft, inputParameterId, integrationId, versionNumber, ...rest }: RemoveCacheForInputParameter) => {
      client.removeQueries(
        QUERY_KEYS_INPUT_PARAMETER_OPTIONS.withParameterId({
          ...rest,
          actionId,
          integrationId,
          payload: { inputParameterId, draft, versionNumber },
        })
      );
    },
    [client]
  );

  return { removeCacheForInputParameter };
};
