import {
  PermissionPolicySummary,
  useCreatePermissionPolicy,
  useDeletePermissionPolicy,
  useGetPermissionPolicies,
} from '@novaera/actioner-service';
import { useConfirmDialog, useNvDialogModalState } from '@novaera/core';
import { useNavigate } from '@novaera/route';
import { useState } from 'react';
import { ROUTES } from '../../../../../src/common/routes';
import { USER_MANAGEMENT_TAB_PATHS } from '../../constants';

export const usePermissionPoliciesController = () => {
  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();
  const [searchPermissionPolicyKeyword, setSearchPermissionPolicyKeyword] = useState<string>('');
  const {
    isOpened: isCreatePermissionPolicyModalOpen,
    onModalOpenClicked: onCreatePermissionPolicyModalOpenClicked,
    onModalCloseClicked: onCreatePermissionPolicyModalCloseClicked,
  } = useNvDialogModalState();

  const { mutate: createPermissionPolicy, isLoading: isCreatePermissionPolicyLoading } = useCreatePermissionPolicy();
  const { mutate: deletePermissionPolicy } = useDeletePermissionPolicy();
  const {
    data: permissionPoliciesQueries,
    isInitialLoading: isPermissionPoliciesLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetPermissionPolicies({
    queryParams: {
      namePrefix: searchPermissionPolicyKeyword,
    },
  });
  const permissionPoliciesPages = permissionPoliciesQueries?.pages.filter((p) => p);
  const isLoading = isPermissionPoliciesLoading;
  const isEmpty =
    !permissionPoliciesPages ||
    (permissionPoliciesPages &&
      permissionPoliciesPages.length === 1 &&
      permissionPoliciesPages[0].permissionPolicies.length === 0);

  const handleCreatePermissionPolicy = () => {
    onCreatePermissionPolicyModalOpenClicked();
  };

  const handleCreatePermissionPolicySubmit = (values: Omit<PermissionPolicySummary, 'id'>) => {
    createPermissionPolicy(values, {
      onSuccess: ({ id }) => {
        navigate(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.PERMISSION_POLICIES}/${id}`);
      },
    });
  };

  const handleDeletePermissionPolicy = (permissionPolicy: PermissionPolicySummary) => {
    openConfirm({
      confirmButtonLabel: 'Delete',
      title: `Delete ${permissionPolicy.name}?`,
      message: 'You are about to delete this permission policy from your workspace. Are you sure?',
      onConfirm: () => {
        deletePermissionPolicy({ id: permissionPolicy.id });
      },
    });
  };

  return {
    permissionPoliciesPages,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isEmpty,
    isCreatePermissionPolicyModalOpen,
    isCreatePermissionPolicyLoading,
    onCreatePermissionPolicyModalCloseClicked,
    setSearchPermissionPolicyKeyword,
    handleCreatePermissionPolicy,
    handleCreatePermissionPolicySubmit,
    handleDeletePermissionPolicy,
  };
};
