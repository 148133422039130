import { useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { WorkflowWithState } from '../../types';
import { QUERY_KEYS_USER_APP } from '../keys';
import { GetUserAppResponse } from '../types';
import { SetupWorkflow } from '../use-get-user-app-setup/types';
import { SearchUserAppPayload } from '../use-search-user-apps/types';

export const generatedUserAppSearchKey = (params: SearchUserAppPayload) => {
  const { keyword, limit, from, sortOption, filters } = params;
  // If we want to add new parameter for search user apps, we need get this from params and add orderGuaranteedParams object.
  const orderGuaranteedParams = {
    ...(keyword ? { keyword } : {}),
    ...(!isUndefined(limit) ? { limit } : {}),
    ...(!isUndefined(from) ? { from } : {}),
    ...(!isUndefined(filters) ? { filters: JSON.stringify(filters) } : {}),
    ...(sortOption ? { sortOption: JSON.stringify(sortOption) } : {}),
  };
  const searchUserAppsParamsKeys = Object.keys(orderGuaranteedParams) as Array<keyof typeof orderGuaranteedParams>;
  const queryStringToCache =
    searchUserAppsParamsKeys.length > 0
      ? searchUserAppsParamsKeys.reduce(
          (acc, cur) => `${acc}${acc.length > 0 ? '&' : ''}${cur}="${orderGuaranteedParams[cur]}"`,
          ''
        )
      : '';

  return queryStringToCache;
};

export const setDefaultSearchUserAppParams = (payload?: SearchUserAppPayload) => {
  const {
    keyword = '',
    from = 0,
    sortOption = { field: 'name', order: 'asc' },
    limit = 20,
    filters,
  } = payload ?? {
    keyword: '',
    limit: 20,
    from: 0,
    sortOption: { field: 'name', order: 'asc' },
  };
  return { keyword, from, sortOption, limit, filters };
};

export const useUserAppServiceUtil = () => {
  const cache = useQueryClient();

  const handleUpdateCache = ({ appId, userApp }: { appId: string; userApp: Partial<GetUserAppResponse> }) => {
    const appDetail = cache.getQueryData<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(appId));
    if (appDetail) {
      cache.setQueryData(QUERY_KEYS_USER_APP.detail(appId), {
        ...appDetail,
        ...userApp,
      });
    }
  };
  return {
    updateCache: handleUpdateCache,
  };
};

export const isSetupWorkflowType = (workflow: SetupWorkflow | WorkflowWithState): workflow is SetupWorkflow => {
  return (workflow as SetupWorkflow).status !== undefined && !('state' in workflow);
};
