import { ActionState, useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useDynamicInputContext } from '../../../components';
import { IntegrationActionOptionsProvider } from '../../../components/options/provider';
import { componentsAlwaysShowingOptions, componentsNeedsOptions } from '../../../components/ui-components/utils';
import { useInputValuesContext } from '../input-values';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../input-values/utils';
import { useSearchAsYouTypeValuesContext } from '../search-as-you-type-values';
import { InputOptionsWrapperProps } from './types';

export const InputOptionsWrapper: FC<React.PropsWithChildren<PropsWithChildren<InputOptionsWrapperProps>>> = ({
  children,
  actionId,
  integrationId,
  initialInputValues,
  replaceParameterMappingsForOptions,
  ...rest
}) => {
  const { inputValues } = useInputValuesContext();
  const { searchAsYouTypeValues } = useSearchAsYouTypeValuesContext();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId,
    actionId,
    version: integration?.latestVersion.number,
  });

  const { dynamicInputParameters } = useDynamicInputContext();

  const allInputParameters = useMemo(
    () => [...(integrationAction?.inputParameters ?? []), ...dynamicInputParameters],
    [dynamicInputParameters, integrationAction?.inputParameters]
  );

  const inputParameterIds = useMemo(
    () =>
      allInputParameters
        ?.filter((inputParameter) => {
          return componentsNeedsOptions(inputParameter.uiComponent);
        })
        .map((ip) => ip.id) ?? [],
    [allInputParameters]
  );

  const inputParameterIdsShowingOptions = useMemo(() => {
    if (allInputParameters) {
      const result = allInputParameters.reduce<string[]>((prev, current) => {
        if (componentsAlwaysShowingOptions(current.uiComponent)) {
          return [...prev, current.id];
        } else {
          return prev;
        }
      }, []);
      return result;
    } else {
      return [];
    }
  }, [allInputParameters]);

  const inputParameterValues = useMemo(() => {
    const values = formatAndFilterInputFormValuesAsParameterMappings(
      { ...initialInputValues, ...inputValues },
      allInputParameters.map((i) => i.id)
    );

    if (replaceParameterMappingsForOptions) {
      const newValues = values.map((value) => {
        const foundParameterMapping = replaceParameterMappingsForOptions.find(
          (p) => p.parameterId === value.parameterId
        );
        return foundParameterMapping ?? value;
      });
      return newValues;
    } else {
      return values;
    }
  }, [allInputParameters, initialInputValues, inputValues, replaceParameterMappingsForOptions]);

  return (
    <IntegrationActionOptionsProvider
      actionId={actionId}
      integrationId={integrationId}
      versionNumber={integration?.latestVersion.number}
      draft={integrationAction?.state === ActionState.DRAFT}
      inputParameterIds={inputParameterIds}
      initialInputParameterIdsShowingOptions={inputParameterIdsShowingOptions}
      inputParameterValues={inputParameterValues}
      searchAsYouTypeValues={searchAsYouTypeValues}
      allInputParametersWithOrder={allInputParameters ?? []}
      {...rest}
    >
      {children}
    </IntegrationActionOptionsProvider>
  );
};
