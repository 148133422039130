import { useGetActionInputParamDependencies } from '@novaera/actioner-service';
import { useCheckDependencyValue } from '../use-check-dependency-value';

export const useCheckActionDynamicInputDependencyValue = ({
  actionId,
  integrationId,
  draft,
  version,
}: {
  actionId: string;
  integrationId: string;
  draft: boolean;
  version?: number;
}) => {
  const { getDependencyInputComponentState, filterSuccessDynamicInputs, dependenciesOfParameters } =
    useCheckDependencyValue({
      result: useGetActionInputParamDependencies({
        actionId,
        integrationId,
        params: {
          draft,
          version,
        },
      }),
      inputParameters: [],
    });

  return {
    getDependencyInputComponentState,
    filterSuccessDynamicInputs,
    dependenciesOfParameters,
  };
};
