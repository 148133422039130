import {
  FieldArray,
  NvAddBoxIcon,
  NvButton,
  NvCloseIcon,
  NvCollapse,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import React from 'react';

const UserInviteWithEmailForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <NvFlex gap="16px">
      <NvTypography variant="body1">
        New members will be added as workspace user. You can always update the role of a member later.
      </NvTypography>
      <FieldArray name="users">
        {({ fields }) => {
          return (
            <NvFlex gap="12px" alignItems="flex-start">
              <NvFlex gap="8px" width="350px">
                {fields.map((name, index) => {
                  return (
                    <NvFlex direction="row-reverse" alignItems="center" gap="8px" key={`${name}.email`}>
                      <NvCollapse in={!(fields.value.length === 1)} orientation="horizontal">
                        <NvButton
                          onlyIcon
                          size="small"
                          color="secondary"
                          onClick={() => {
                            fields.remove(index);
                          }}
                        >
                          <NvCloseIcon />
                        </NvButton>
                      </NvCollapse>
                      <NvField
                        key={index}
                        name={`${name}.email`}
                        component={<NvTextField placeholder="Type email address" />}
                      />
                    </NvFlex>
                  );
                })}
              </NvFlex>
              <NvButton
                color="secondary"
                size="small"
                startIcon={<NvAddBoxIcon />}
                onClick={() => {
                  fields.push({ name: '' });
                }}
              >
                Add user
              </NvButton>
            </NvFlex>
          );
        }}
      </FieldArray>
    </NvFlex>
  );
};
export const MemoizedUserInviteWithEmailForm = React.memo(UserInviteWithEmailForm);
