import { BlankVertex, NodeSummaries, Vertex, Vertices } from '@novaera/actioner-service';
import { cloneDeep } from 'lodash';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';

export const replaceNextAlias = ({ nodes, nodeToDelete }: { nodes: Vertices; nodeToDelete: Vertex }) => {
  const deletedNodeId = nodeToDelete.alias;
  const newNodes: Vertices = [];
  const nodeSummaries: NodeSummaries = {};

  const replacedNodes = nodes.map((node) => {
    const newNode = cloneDeep(node);

    if (newNode.nextAlias === deletedNodeId) {
      if (nodeToDelete.nextAlias) {
        newNode.nextAlias = nodeToDelete.nextAlias;
      } else {
        delete newNode.nextAlias;
      }
    }

    if (newNode.type === 'branchJunction') {
      const foundIndex = newNode.firstInnerAliases.findIndex((b) => b === deletedNodeId);
      if (foundIndex > -1) {
        if (nodeToDelete.nextAlias) {
          newNode.firstInnerAliases[foundIndex] = nodeToDelete.nextAlias;
        } else {
          // this means we need to add a new blank node;
          const { newAlias, newName } = userAppGraph.getNewAlias('blank');
          const blankNode: BlankVertex = {
            alias: newAlias,
            type: 'blank',
          };
          const summary: NodeSummaries[typeof blankNode.alias] = {
            name: newName,
            type: 'blank',
            alias: newAlias,
          };
          nodeSummaries[blankNode.alias] = summary;

          newNode.firstInnerAliases[foundIndex] = blankNode.alias;
          newNodes.push(blankNode);
        }
      }
    } else if (newNode.type === 'workflowCondition') {
      if (newNode.falseAlias === deletedNodeId) {
        if (nodeToDelete.nextAlias) {
          newNode.falseAlias = nodeToDelete.nextAlias;
        } else {
          // this means we need to add a new blank node;
          const { newAlias, newName } = userAppGraph.getNewAlias('blank');
          const blankNode: BlankVertex = {
            alias: newAlias,
            type: 'blank',
          };
          const summary: NodeSummaries[typeof blankNode.alias] = {
            name: newName,
            type: 'blank',
            alias: newAlias,
          };
          newNode.falseAlias = blankNode.alias;
          nodeSummaries[blankNode.alias] = summary;
          newNodes.push(blankNode);
        }
      }
      if (newNode.trueAlias === deletedNodeId) {
        if (nodeToDelete.nextAlias) {
          newNode.trueAlias = nodeToDelete.nextAlias;
        } else {
          // this means we need to add a new blank node;
          const { newAlias, newName } = userAppGraph.getNewAlias('blank');
          const blankNode: BlankVertex = {
            alias: newAlias,
            type: 'blank',
          };
          const summary: NodeSummaries[typeof blankNode.alias] = {
            name: newName,
            type: 'blank',
            alias: newAlias,
          };
          newNode.trueAlias = blankNode.alias;
          nodeSummaries[blankNode.alias] = summary;
          newNodes.push(blankNode);
        }
      }
    } else if (newNode.type === 'loop') {
      if (newNode.firstInnerAlias === deletedNodeId) {
        if (nodeToDelete.nextAlias) {
          newNode.firstInnerAlias = nodeToDelete.nextAlias;
        } else {
          // this means we need to add a new blank node;
          const { newAlias, newName } = userAppGraph.getNewAlias('blank');
          const blankNode: BlankVertex = {
            alias: newAlias,
            type: 'blank',
          };
          const summary: NodeSummaries[typeof blankNode.alias] = {
            name: newName,
            type: 'blank',
            alias: newAlias,
          };
          newNode.firstInnerAlias = blankNode.alias;
          nodeSummaries[blankNode.alias] = summary;
          newNodes.push(blankNode);
        }
      }
    }

    return newNode;
  });

  return { replacedNodes: [...newNodes, ...replacedNodes], nodeSummaries };
};
