import {
  isRequired,
  NvAddIcon,
  NvButton,
  NvCloseIcon,
  NvDialogSlots,
  NvDialogV1,
  NvField,
  NvFlex,
  NvForm,
  NvTextArea,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import * as React from 'react';
import { useCreateEventRuleModalController } from './controllers/use-create-event-rule-modal-controller';
import { CreateEventRuleModalFormValues, CreateEventRuleModalProps } from './types';

export const CreateEventRuleModal: React.FC<React.PropsWithChildren<CreateEventRuleModalProps>> = ({
  isOpen,
  onClose,
}) => {
  const { isLoading, onSubmit } = useCreateEventRuleModalController({ onClose });

  return (
    <NvDialogV1 open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <NvForm<CreateEventRuleModalFormValues> keepDirtyOnReinitialize onSubmit={onSubmit}>
        {({ submitting }) => (
          <NvDialogSlots>
            <NvDialogSlots.Slot name="icon">
              <NvAddIcon />
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="title">
              <NvTypography variant="h2">Create trigger</NvTypography>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="closeButton">
              <NvButton
                onlyIcon
                size="small"
                color="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                <NvCloseIcon />
              </NvButton>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="content">
              <NvFlex spacing={3} direction="column">
                <NvField
                  labelVariant="h5"
                  direction="label-on-top"
                  name="name"
                  labelText="Name"
                  hasRequiredIndicator
                  validators={[isRequired()]}
                  showErrorMessageOnlyWhenBlur
                  component={<NvTextField autoFocus size="medium" disabled={submitting} />}
                />
                <NvField
                  labelVariant="h5"
                  direction="label-on-top"
                  name="description"
                  labelText="Description"
                  component={<NvTextArea rows={5} />}
                />
              </NvFlex>
            </NvDialogSlots.Slot>
            <NvDialogSlots.Slot name="actions">
              <NvButton type="submit" loading={isLoading} disabled={isLoading}>
                Create
              </NvButton>
              <NvButton onClick={onClose} color="ghost" disabled={isLoading}>
                Cancel
              </NvButton>
            </NvDialogSlots.Slot>
          </NvDialogSlots>
        )}
      </NvForm>
    </NvDialogV1>
  );
};
