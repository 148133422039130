import { NvAxios, useMutation } from '@novaera/core';
import { ADMIN_APP_DIRECTORY_PREFIX } from '../constants';
import { DeleteParams } from '../types';

const deleteSchema = async ({ schemaId }: DeleteParams) => {
  const result = await NvAxios.delete(`${ADMIN_APP_DIRECTORY_PREFIX}/schemas/${schemaId}`);
  return result?.data;
};

export const useDeleteSchema = () => {
  return useMutation(deleteSchema);
};
