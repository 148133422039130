import { Theme } from '@mui/material/styles';
import { styled } from '@novaera/theme-provider';
import { InfoTexts } from './type';

const getInfoTextColor = (theme: Theme, type?: InfoTexts) => {
  switch (type) {
    case InfoTexts.SUCCESS:
      return theme.palette.nv_success[60];
    case InfoTexts.ERROR:
      return theme.palette.nv_error[40];
    case InfoTexts.DURATION:
      return theme.palette.nv_main[40];
    case InfoTexts.NOT_AVAILABLE:
      return theme.palette.nv_neutral_alpha[60];
    default:
      return 'inherit';
  }
};

export const StyledSpan = styled('span', { shouldForwardProp: (prop) => prop !== 'infoType' })<{
  infoType?: InfoTexts;
}>`
  color: ${({ theme, infoType }) => getInfoTextColor(theme, infoType)};
`;
