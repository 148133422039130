import {
  useGetAnalyticsQueryTypes,
  useGetRecentAnalyticsQueries,
  useStartAnalyticsQuery,
} from '@novaera/actioner-service';
import {
  NvArrowDownward,
  NvBox,
  NvButton,
  NvChevronRightIcon,
  NvCollapse,
  NvEmptyState,
  NvField,
  NvFlex,
  NvForm,
  NvKeyboardArrowDownIcon,
  NvRefreshIcon,
  NvSelect,
  NvSkeleton,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { startCase } from 'lodash';
import { useState } from 'react';
import { RecentQueryCard } from './recent-query-card';
import { QueryStartCard } from './styled';
import { StartQueryFormValues } from './types';

export const AnalyticsPanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showQueryStartSection, setShowQueryStartSection] = useState<boolean>(false);
  const { data } = useGetAnalyticsQueryTypes();
  const queryTypeOptions = data?.queryTypes.map(({ id, name }) => ({ label: name, value: id }));
  const {
    data: recentAnalyticsQueries,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isRefetching,
  } = useGetRecentAnalyticsQueries();
  const { mutate: startAnalyticsQuery } = useStartAnalyticsQuery();
  const recentQueriesPages = recentAnalyticsQueries?.pages.filter((p) => p);

  const startQuery = ({ queryTypeId, parameters }: StartQueryFormValues) => {
    assert(!!queryTypeId, new Error('Query can not be started without queryTypeId!'), 'ERROR');

    return new Promise<void>((resolve) => {
      startAnalyticsQuery(
        { queryTypeId, parameters },
        {
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };

  return (
    <NvFlex gap="36px">
      <QueryStartCard>
        <NvFlex
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          onClick={() => {
            setShowQueryStartSection(!showQueryStartSection);
          }}
        >
          {showQueryStartSection ? <NvKeyboardArrowDownIcon /> : <NvChevronRightIcon />}
          <NvTypography variant="h2">Query start</NvTypography>
        </NvFlex>
        <NvCollapse in={showQueryStartSection}>
          <NvForm<StartQueryFormValues> onSubmit={startQuery} keepDirtyOnReinitialize>
            {({ form, submitting, values }) => {
              const selectedQueryTypeParameters = data?.queryTypes.find(
                ({ id }) => id === values.queryTypeId
              )?.parameters;
              return (
                <NvFlex width="100%" alignItems="flex-start" gap="24px" marginTop="16px">
                  <NvField
                    parse={(value) => {
                      // to clear old parameters value.
                      form.change('parameters', {});
                      return value;
                    }}
                    defaultValue={queryTypeOptions?.[0].value}
                    component={<NvSelect options={queryTypeOptions} />}
                    name="queryTypeId"
                  />
                  {selectedQueryTypeParameters?.map((parameter) => (
                    <NvBox width="100%" key={`${parameter}-key`}>
                      <NvFlex direction="row" alignItems="center" gap="8px">
                        <NvFlex sx={{ width: '140px', flex: '0 0 auto', padding: '8px 0' }}>
                          <NvTypography variant="h5" noWrap>
                            {startCase(parameter)}
                          </NvTypography>
                        </NvFlex>
                        <NvField name={`parameters.${parameter}`} component={<NvTextField />} />
                      </NvFlex>
                    </NvBox>
                  ))}
                  <NvFlex direction="row" gap="4px">
                    <NvButton loading={submitting} disabled={submitting} type="submit">
                      Start query
                    </NvButton>
                  </NvFlex>
                </NvFlex>
              );
            }}
          </NvForm>
        </NvCollapse>
      </QueryStartCard>
      <NvFlex gap="16px">
        <NvFlex direction="row" alignItems="center" justifyContent="space-between">
          <NvTypography variant="h1">Recent queries</NvTypography>
          <NvButton
            onlyIcon
            color="secondary"
            loading={isRefetching}
            disabled={isRefetching}
            onClick={() => {
              refetch();
            }}
          >
            <NvRefreshIcon />
          </NvButton>
        </NvFlex>

        {isLoading ? (
          <NvFlex>
            <NvSkeleton width="60%" />
            <NvSkeleton width="80%" />
            <NvSkeleton width="40%" />
          </NvFlex>
        ) : (
          <>
            {recentQueriesPages && recentQueriesPages.length > 0 ? (
              recentQueriesPages.map(({ recentQueries }) =>
                recentQueries.map((recentQuery, index) => (
                  <RecentQueryCard key={recentQuery.id} recentQuery={recentQuery} index={index} />
                ))
              )
            ) : (
              <NvEmptyState primaryText="There is no query."></NvEmptyState>
            )}
            {hasNextPage && (
              <NvFlex direction="row" justifyContent="flex-start">
                <NvButton
                  startIcon={<NvArrowDownward />}
                  color="secondary"
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                  disabled={isFetchingNextPage}
                >
                  Show more
                </NvButton>
              </NvFlex>
            )}
          </>
        )}
      </NvFlex>
    </NvFlex>
  );
};
