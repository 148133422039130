import { Initial, NvDivider, NvFlex, NvImage, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

export const IntegrationSectionTitle = ({
  version,
  logoUrl,
  name,
}: {
  name: string;
  logoUrl: string;
  version: string;
}) => {
  const theme = useTheme();

  return (
    <NvFlex gap={'10px'} flexDirection={'row'} alignItems={'center'}>
      <NvImage
        imageShape="square"
        size="small"
        src={logoUrl}
        FallBack={<Initial size="small" color={theme.palette.nv_neutral[500]} value={name} />}
        hasNoElevation
        hasBlurEffect
      />
      <NvTypography variant="h4">{name}</NvTypography>
      <NvDivider orientation="vertical" sx={{ height: '12px' }} />
      <NvTypography variant="h5" textColor="subtle">
        {version}
      </NvTypography>
    </NvFlex>
  );
};
