import {
  PublishIntegrationParams,
  PublishIntegrationPayload,
  UpgradeIntegrationParams,
  UpgradeIntegrationPayload,
  useDownloadIntegration,
  useGetIntegration,
  usePublishIntegration,
  useSearchInfiniteIntegrations,
  useUpgradeIntegration,
} from '@novaera/actioner-service';
import {
  FieldTitle,
  Initial,
  NvAlert,
  NvAutocomplete,
  NvButton,
  NvCheckbox,
  NvDivider,
  NvField,
  NvFlex,
  NvForm,
  NvImage,
  NvSelect,
  NvSkeleton,
  NvTextArea,
  NvTextField,
  isMaxLength,
  isRequired,
} from '@novaera/core';
import { noop } from 'lodash';
import { useMemo, useState } from 'react';

export const AdminIntegrations = () => {
  const categories = [
    'im',
    'communication-collaboration',
    'customer-service',
    'developer-tools',
    'human-resources',
    'marketing',
    'office-management',
    'productivity',
    'project-management',
    'sales-prospecting',
    'social-entertainment',
    'it-solutions',
  ];
  const [operation, setOperation] = useState<'publish' | 'upgrade' | 'download'>('publish');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [response, setResponse] = useState<Record<any, any> | null>();

  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | undefined>();

  const { data, isLoading } = useSearchInfiniteIntegrations({
    scopeType: operation === 'download' ? 'global' : 'workspace',
  });

  const { mutate: publishIntegration, isLoading: isPublishIntegrationLoading } = usePublishIntegration();
  const { mutate: upgradeIntegration, isLoading: isUpgradeIntegrationLoading } = useUpgradeIntegration();
  const { mutate: downloadIntegration } = useDownloadIntegration();

  const { data: selectedIntegration, isInitialLoading: isSelectedIntegrationLoading } = useGetIntegration({
    id: selectedIntegrationId,
  });
  const { data: sourceIntegration, isInitialLoading: isSourceIntegrationLoading } = useGetIntegration({
    id: selectedIntegration?.sourceIntegrationId,
  });

  const handleOnSubmitIntegration = (
    values: PublishIntegrationPayload | UpgradeIntegrationPayload,
    type: 'publish' | 'upgrade'
  ) => {
    return new Promise<void>((resolve) => {
      if (selectedIntegrationId) {
        if (type === 'upgrade' && 'changeLog' in values) {
          const upgradeIntegrationParams: UpgradeIntegrationParams = {
            payload: values,
            integrationId: selectedIntegrationId,
          };
          upgradeIntegration(upgradeIntegrationParams, {
            onSettled: (response) => {
              setResponse(response);
              resolve();
            },
          });
        } else {
          const publishIntegrationParams: PublishIntegrationParams = {
            payload: values,
            integrationId: selectedIntegrationId,
          };
          publishIntegration(publishIntegrationParams, {
            onSettled: (response) => {
              setResponse(response);
              resolve();
            },
          });
        }
      } else {
        resolve();
      }
    });
  };

  const handleOnSubmitDownload = () => {
    return new Promise<void>((resolve) => {
      if (selectedIntegrationId) {
        downloadIntegration(
          { integrationId: selectedIntegrationId },
          {
            onSettled: (response) => {
              setResponse(response);
              resolve();
            },
          }
        );
      } else {
        resolve();
      }
    });
  };

  const integrationsOptions = useMemo(
    () => data?.pages[0].integrations.map((i) => ({ label: i.name, value: i.id })),
    [data?.pages[0].integrations]
  );

  const initialValues = useMemo(() => {
    if (selectedIntegration && sourceIntegration) {
      const initialPayload: PublishIntegrationPayload = {
        name: selectedIntegration.name,
        description: selectedIntegration.description,
        shortDescription: sourceIntegration.shortDescription,
        categories: sourceIntegration.categories,
      };
      return initialPayload;
    } else {
      return { categories: [] };
    }
  }, [selectedIntegration, sourceIntegration]);

  const isIntegrationLoading = useMemo(() => {
    if (operation === 'publish') {
      return isSelectedIntegrationLoading;
    } else if (operation === 'upgrade') {
      return isSelectedIntegrationLoading || isSourceIntegrationLoading;
    }
    return false;
  }, [isSelectedIntegrationLoading, isSourceIntegrationLoading]);

  return (
    <NvFlex flexDirection="column" gap="8px">
      <NvFlex direction="row" gap="8px" alignItems="flex-start">
        <FieldTitle direction="label-on-side" labelText="Operation" labelWidth="120px" />
        <NvSelect<'publish' | 'upgrade' | 'download'>
          value={operation}
          sx={{ maxWidth: '200px' }}
          options={[
            { label: 'Publish', value: 'publish' },
            { label: 'Upgrade', value: 'upgrade' },
            { label: 'Download', value: 'download' },
          ]}
          onChange={(e) => {
            setResponse(null);
            const value = e.target.value as 'publish' | 'upgrade' | 'download';
            setOperation(value);
            setSelectedIntegrationId(undefined);
          }}
        />
      </NvFlex>

      <NvFlex direction="row" gap="8px" alignItems="flex-start">
        <FieldTitle direction="label-on-side" labelText="Integration id" labelWidth="120px" hasRequiredIndicator />

        <NvFlex minWidth="400px">
          {isLoading ? (
            <NvSkeleton />
          ) : (
            <NvSelect<string>
              value={selectedIntegrationId}
              options={integrationsOptions}
              onChange={(e) => {
                setSelectedIntegrationId(e.target.value);
              }}
            />
          )}
        </NvFlex>
      </NvFlex>

      <NvDivider sx={{ margin: '8px 0' }} />

      {selectedIntegrationId &&
        (operation === 'download' ? (
          <NvForm onSubmit={handleOnSubmitDownload} keepDirtyOnReinitialize key="download">
            {({ submitting }) => (
              <NvFlex alignItems="flex-start" marginTop="8px">
                <NvButton type="submit" disabled={submitting} loading={submitting}>
                  Download
                </NvButton>
              </NvFlex>
            )}
          </NvForm>
        ) : isIntegrationLoading ? (
          <>
            <NvSkeleton />
            <NvSkeleton />
            <NvSkeleton />
          </>
        ) : (
          <NvForm<PublishIntegrationPayload | UpgradeIntegrationPayload>
            onSubmit={noop}
            key={operation}
            keepDirtyOnReinitialize
            initialValues={initialValues}
          >
            {({ values, submitting, valid }) => (
              <NvFlex gap="8px">
                <NvFlex direction="row" gap="8px" alignItems="flex-start">
                  <FieldTitle direction="label-on-side" labelText="Logo" labelWidth="120px" />

                  <NvImage
                    imageShape="square"
                    size="xlarge"
                    src={selectedIntegration?.logoUrl}
                    hasNoElevation
                    FallBack={<Initial size="medium" value={selectedIntegration?.name || ''} color={''} />}
                  />
                </NvFlex>

                <NvField
                  isAutoComplete
                  isMulti
                  labelWidth="120px"
                  labelText="Categories"
                  {...(operation === 'publish' && {
                    hasRequiredIndicator: true,
                    showErrorMessageOnlyWhenBlur: true,
                    validators: [isRequired()],
                  })}
                  component={
                    <NvAutocomplete
                      multiple
                      renderInput={(props) => <NvTextField {...props} />}
                      options={categories}
                      fullWidth
                    />
                  }
                  name="categories"
                />

                <NvField
                  labelWidth="120px"
                  labelText="Name"
                  component={<NvTextField />}
                  name={'name'}
                  {...(operation === 'publish' && {
                    hasRequiredIndicator: true,
                    showErrorMessageOnlyWhenBlur: true,
                    validators: [isRequired()],
                  })}
                />
                <NvField
                  labelWidth="120px"
                  labelText="Summary"
                  component={<NvTextArea rows="3" />}
                  name={'description'}
                  {...(operation === 'publish' && {
                    hasRequiredIndicator: true,
                    showErrorMessageOnlyWhenBlur: true,
                    validators: [isRequired()],
                  })}
                />
                <NvField
                  labelWidth="120px"
                  labelText="Short description"
                  component={<NvTextField />}
                  validators={[isMaxLength({ length: 160 })]}
                  name={'shortDescription'}
                  {...(operation === 'publish' && {
                    hasRequiredIndicator: true,
                    showErrorMessageOnlyWhenBlur: true,
                    validators: [isRequired()],
                  })}
                />
                {operation === 'upgrade' && (
                  <>
                    <NvField
                      labelWidth="120px"
                      labelText="ChangeLog"
                      validators={[isRequired()]}
                      showErrorMessageOnlyWhenBlur
                      hasRequiredIndicator
                      component={<NvTextArea rows="3" />}
                      name={'changeLog'}
                    />

                    <NvField
                      labelWidth="120px"
                      labelText="Keep same version"
                      component={({ value, onChange }) => (
                        <NvCheckbox checked={Boolean(value)} onChange={onChange} sx={{ marginTop: '16px' }} />
                      )}
                      name={'keepSameVersion'}
                    />
                  </>
                )}

                <NvFlex flexDirection="row-reverse" gap="8px">
                  <NvFlex marginTop="8px" alignItems="flex-start">
                    <NvButton
                      onClick={() => {
                        valid && handleOnSubmitIntegration(values, operation);
                      }}
                      loading={isPublishIntegrationLoading || isUpgradeIntegrationLoading}
                      disabled={isPublishIntegrationLoading || isUpgradeIntegrationLoading || !valid}
                    >
                      {operation === 'publish' ? 'Publish' : 'Upgrade'}
                    </NvButton>
                  </NvFlex>
                </NvFlex>
              </NvFlex>
            )}
          </NvForm>
        ))}
      {response && <NvAlert>{JSON.stringify(response)}</NvAlert>}
    </NvFlex>
  );
};
