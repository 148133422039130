import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const StyledMissingConnectionItem = styled(NvFlex)`
  flex-direction: column;
  gap: 18px;
  padding: 16px;
  background: ${({ theme }) => theme.palette.nv_neutral[20]};
  border-radius: 12px;
`;

export const StyledMissingConnectionItemHeader = styled(NvFlex)`
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  gap: 8px;
`;

export const MissingConnectionConnectBody = styled(NvFlex)`
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px;
  gap: 16px;

  form {
    width: 100%;
  }

  /* Neutral/N0 */
  background: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-radius: 6px;

  &.opened {
    display: flex;
  }

  &.closed {
    display: none;
  }
`;
