import { NvAxios, useQuery } from '@novaera/core';
import { isUndefined } from 'lodash';
import { INTEGRATION_EVENT_RULE_ROOT_PATH } from '../constants';

import { QUERY_KEYS_INTEGRATION_EVENT_RULES } from '../keys';
import { GetIntegrationEventRuleParams, GetIntegrationEventRuleResponse } from './types';
import { useIntegrationEventRule } from './use-integration-event-rule';

const getIntegrationEventRule = async ({ integrationId, id, includeDraft, version }: GetIntegrationEventRuleParams) => {
  const result = await NvAxios.get<GetIntegrationEventRuleResponse>(
    `${INTEGRATION_EVENT_RULE_ROOT_PATH(integrationId)}/${id}`,
    {
      params: {
        ...(!isUndefined(includeDraft) ? { includeDraft } : {}),
        ...(!isUndefined(version) ? { version } : {}),
      },
    }
  );
  return result?.data;
};

export const useGetIntegrationEventRule = ({
  integrationId,
  id,
  includeDraft,
  version,
}: GetIntegrationEventRuleParams) => {
  const { data, isInitialLoading, isError, isSuccess, isFetched } = useQuery<GetIntegrationEventRuleResponse>(
    QUERY_KEYS_INTEGRATION_EVENT_RULES.detail(integrationId, id),
    () => getIntegrationEventRule({ id, integrationId, includeDraft, version }),
    {
      enabled: !!id && !!integrationId,
    }
  );

  const { draftIntegrationEventRule, persistedIntegrationEventRule, integrationEventRule } =
    useIntegrationEventRule(data);

  return {
    integrationEventRule,
    persistedIntegrationEventRule,
    draftIntegrationEventRule,
    isLoading: isInitialLoading,
    isError,
    isSuccess,
    isFetched,
  };
};
