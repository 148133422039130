import { GlobalSchema } from '@novaera/actioner-service';

export const CUSTOM_CONNECTION_OPTIONS: GlobalSchema[] = [
  {
    description: 'API key description',
    authenticationType: 'api-key',
    name: 'API key',
    id: 'api-key',
  },
  {
    description: 'basic auth description',
    authenticationType: 'basic',
    name: 'Basic authentication',
    id: 'basic',
  },
];
