import { NvFlex } from '@novaera/core';
import { FC } from 'react';
import { BillingCard } from '..';
import { UsageInfoCardProps } from './types';
import { UsageInfo } from './usage-info';

export const UsageInfoCard: FC<UsageInfoCardProps> = ({ resourceUsageDetails }) => {
  return (
    <BillingCard
      title="Usage"
      description={
        <NvFlex gap="16px">
          {resourceUsageDetails.map((usageDetail) => (
            <UsageInfo usageDetail={usageDetail} key={usageDetail.resource} />
          ))}
        </NvFlex>
      }
    />
  );
};
