import { styled } from '@novaera/theme-provider';
import { NvBox } from '../box';
import { NvFlex } from '../flex';

export const StyledEditorWrapper = styled(NvBox)`
  .nv-editor {
    --baseBase: ${({ theme }) => theme.palette.nv_neutral[0]};
    --baseBgSubtle: ${({ theme }) => theme.palette.nv_neutral[0]};
    --baseBg: ${({ theme }) => theme.palette.nv_neutral[0]};
    --baseBgHover: ${({ theme }) => theme.palette.nv_neutral[20]};
    --baseBgActive: ${({ theme }) => theme.palette.nv_neutral[30]};
    --baseLine: ${({ theme }) => theme.palette.nv_neutral[40]};
    --baseBorder: ${({ theme }) => theme.palette.nv_neutral[40]};
    --baseBorderHover: ${({ theme }) => theme.palette.nv_neutral[50]};
    --baseSolid: ${({ theme }) => theme.palette.nv_neutral[5]};
    --baseSolidHover: ${({ theme }) => theme.palette.nv_neutral[20]};
    --baseText: ${({ theme }) => theme.palette.nv_neutral[1000]};
    --baseTextContrast: ${({ theme }) => theme.palette.nv_neutral[1000]};

    color: var(--baseText);
    background: var(--baseBg);

    [role='toolbar'] {
      padding-left: calc((100vw - 1024px) / 2);
      padding-right: calc((100vw - 1024px) / 2);
      margin: 0 auto;
    }

    .nv-markdown-editor {
      padding-left: calc((100vw - 1024px) / 2);
      padding-right: calc((100vw - 1024px) / 2);
      margin: 0 auto;
    }
  }
`;

export const ToolbarWrapper = styled(NvFlex)`
  flex-direction: row;
  width: calc(100% + 16px);
  gap: 8px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
  margin-top: 4px;
  margin-left: -8px;
  padding: 4px;
  border-radius: 6px;
`;
