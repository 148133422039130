import { DocumentItem, SearchWorkflow } from '@novaera/actioner-service';
import { NodeListItem, NvButton, NvCheckIcon, NvChip, NvFlex } from '@novaera/core';

export const convertAssistantItemToNodeListItem = <T extends DocumentItem | SearchWorkflow>({
  items,
  existingItemIds,
}: {
  items: T[];
  existingItemIds: string[];
}) =>
  items.map((item) => {
    const isExist = existingItemIds.includes(item.id);
    return {
      name: item.name,
      type: 'assistant-item',
      description:
        item.tags && item.tags.length > 0 ? (
          <NvFlex direction="row" alignItems="center" gap="4px">
            {item.tags.map((tag, index) => (
              <NvChip key={`assistant-item-tag-${tag}-${index}`} label={tag} compact />
            ))}
          </NvFlex>
        ) : undefined,
      extraData: { ...item, isCompact: true, disabled: isExist },
      rightComponent: (
        <NvFlex direction="row" alignItems="center" justifyContent="flex-end" flex="1 1 auto" minWidth={0}>
          {isExist ? (
            <NvButton color="ghost" size="small" endIcon={<NvCheckIcon />}>
              Added
            </NvButton>
          ) : (
            <NvButton color="secondary" size="small">
              Add
            </NvButton>
          )}
        </NvFlex>
      ),
    } satisfies NodeListItem<T>;
  });
