import { FunctionType, HTTPRequestConfiguration } from '@novaera/actioner-service';
import { REQUEST_IDENTIFIER } from '../../../../../common/constants';

export const setNextItemAfterDeletion = (
  id: string,
  itemIndex: number,
  setSelectedId: (id: string) => void,
  items?: (HTTPRequestConfiguration | FunctionType)[] | undefined
) => {
  const allRequests = [...(items || [])].filter((req) => req.id !== id);
  if (allRequests.length > 0) {
    let nextIndex = 0;
    if (itemIndex > 0) {
      nextIndex = itemIndex - 1;
    } else {
      nextIndex = 0;
    }

    setSelectedId(allRequests[nextIndex].id);
  }
};

export const calculateRequestName = (requestName?: string) =>
  `{{${REQUEST_IDENTIFIER}.${requestName ? requestName : ''}}}`;
