import { NvActionFilledIcon, NvDivider, NvFlex, NvImage, NvTypography } from '@novaera/core';
import { FC } from 'react';
import { IntegrationInitialLogo } from '../../../../../../../../../../integrations/components/integration-initial-logo/';
import { ProducerItemLayoutProps } from './types';

export const ProducerItemLayout: FC<React.PropsWithChildren<ProducerItemLayoutProps>> = ({
  sourceName,
  integration,
  integrationAction,
}) => {
  return (
    <NvFlex direction="row" gap="8px" alignItems="center" width="100%">
      <NvTypography variant="h5" flex="0 0 auto">
        {sourceName}
      </NvTypography>
      <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth="0">
        {integration && (
          <NvImage
            FallBack={<IntegrationInitialLogo name={integration.name ?? ''} size="small" />}
            src={integration.logoUrl}
            imageShape="square"
            size="smaller"
            className="integration-image"
          />
        )}
        <>
          <NvDivider orientation="vertical" sx={{ height: '12px' }} />
          <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth="0">
            {integrationAction?.name ? (
              <>
                <NvActionFilledIcon sx={{ width: '16px', height: '16px' }} />
                <NvTypography variant="h5" textColor="secondary" noWrap>
                  {integrationAction.name}
                </NvTypography>
              </>
            ) : (
              <NvTypography variant="h6" textColor="ghost" noWrap>
                Select an action
              </NvTypography>
            )}
          </NvFlex>
        </>
      </NvFlex>
    </NvFlex>
  );
};
