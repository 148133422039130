import { ReactNode } from 'react';
import { LoadingState } from './loading-state';
import { DefaultCardListLayoutWrapper, List } from './styled';

export interface CardListLayoutProps<CardData extends { id: string }> {
  isLoading: boolean;
  isListLoading?: boolean;
  header: ReactNode;
  emptyState: ReactNode;
  loadingItem: ReactNode;
  CardViewComponent: React.FC<React.PropsWithChildren<CardData>>;
  CardListWrapperComponent?: React.FC<React.PropsWithChildren>;
  cardList?: CardData[];
  nextPageIndicator?: ReactNode;
}

export const CardListLayout = <CardData extends { id: string }>({
  header,
  CardViewComponent,
  cardList,
  isLoading,
  isListLoading,
  emptyState,
  loadingItem,
  nextPageIndicator,
  CardListWrapperComponent = DefaultCardListLayoutWrapper,
}: CardListLayoutProps<CardData>) => {
  return isLoading && !isListLoading ? (
    <LoadingState loadingItem={loadingItem} CardListWrapperComponent={CardListWrapperComponent} />
  ) : (
    <CardListWrapperComponent>
      {header}
      {isListLoading ? (
        <LoadingState loadingItem={loadingItem} onlyList CardListWrapperComponent={CardListWrapperComponent} />
      ) : cardList && cardList.length > 0 ? (
        <List>
          {cardList.map((i) => (
            <CardViewComponent key={`card-view-${i.id}`} {...i} />
          ))}
          {nextPageIndicator}
        </List>
      ) : (
        emptyState
      )}
    </CardListWrapperComponent>
  );
};
