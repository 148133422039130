import { NvButton, NvContentCopyIcon, NvFlex, NvTypography, NvWebhookIcon, useCopyToClipboard } from '@novaera/core';
import { PropertyPanelSection } from '../../../../../../../../../components';
import { EndpointWrapper } from '../../../integration-trigger-property-panel-drawer/integration-trigger-properties/styled';
import { GenericWebhookUrlProps } from './types';

export const GenericWebhookUrl: React.FC<React.PropsWithChildren<GenericWebhookUrlProps>> = ({ url }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copyToClipboard] = useCopyToClipboard();

  const handleCopyToClipboard = (text: string) => {
    copyToClipboard(text);
  };
  return (
    <PropertyPanelSection title="Webhook URL" icon={<NvWebhookIcon />}>
      <NvFlex gap="8px">
        <NvTypography variant="body2" textColor="secondary">
          You need to copy the URL provided below and use it for the configuration of the webhook in your tool.
        </NvTypography>
        <NvFlex alignItems="center" paddingTop="2px" direction="row" gap="8px">
          <EndpointWrapper>
            <NvTypography variant="c2" textColor="subtle" noWrap component="p">
              {url}
            </NvTypography>
          </EndpointWrapper>
          <NvButton
            onlyIcon
            color="ghost"
            sx={{ flex: '0 0 auto' }}
            onClick={() => {
              if (url) {
                handleCopyToClipboard(url);
              }
            }}
          >
            <NvContentCopyIcon />
          </NvButton>
        </NvFlex>
      </NvFlex>
    </PropertyPanelSection>
  );
};
