import { NvBox } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const WorkflowPermissionItemContainer = styled(NvBox, { shouldForwardProp: (prop) => prop !== 'disabled' })<{
  disabled: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 24px;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};
  ${({ disabled }) => (disabled ? 'opacity:0.6' : null)};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const WorkflowPermissionUser = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  flex: 1 1 auto;
  min-width: 0;
`;

export const WorkflowPermissionActions = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex: 0 0 auto;
`;
