import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ASSISTANTS_ROOT_PATH } from '../constants';
import { QUERY_KEYS_ASSISTANTS } from '../keys';
import { Assistant } from '../types';

const deleteAssistantWorkflow = async ({ appId, workflowId }: { appId: string; workflowId: string }) => {
  const result = await NvAxios.delete<Assistant>(`${ASSISTANTS_ROOT_PATH(appId)}/workflows/${workflowId}`);
  return result?.data;
};

export const useDeleteAssistantWorkflow = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAssistantWorkflow, {
    onSuccess: (response, { appId }) => {
      queryClient.setQueryData(QUERY_KEYS_ASSISTANTS.detail({ appId }), response);
    },
  });
};
