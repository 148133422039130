import { NvAxios, useQuery } from '@novaera/core';
import { assert } from '@novaera/utils';
import { INTEGRATION_ACTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_INTEGRATION_ACTIONS } from '../keys';
import { GetIntegrationActionParams, GetIntegrationActionResponse } from '../types';

const getIntegrationAction = async ({ integrationId, actionId, ...params }: GetIntegrationActionParams) => {
  assert(!!integrationId, new Error('[getIntegrationAction] - integrationId should be defined.'));
  const result = await NvAxios.get<GetIntegrationActionResponse>(
    `${INTEGRATION_ACTION_ROOT_PATH(integrationId)}/${actionId}`,
    { params }
  );
  return result.data;
};

export const useGetIntegrationAction = ({ integrationId, actionId, version, ...rest }: GetIntegrationActionParams) => {
  const includeDraft = rest.includeDraft ?? true;
  const { data, ...queryRest } = useQuery<GetIntegrationActionResponse>(
    QUERY_KEYS_INTEGRATION_ACTIONS.detail(integrationId, actionId, version),
    () =>
      getIntegrationAction({
        actionId,
        integrationId,
        version,
        ...rest,
        includeDraft,
      }),
    {
      enabled: !!actionId && !!integrationId && !!version,
    }
  );

  return {
    data: includeDraft && data?.draft ? data.draft : data?.persisted,
    persistedIntegrationAction: data?.persisted,
    ...queryRest,
  };
};
