import { NvBox, NvDivider } from '@novaera/core';
import { styled } from '@novaera/theme-provider';
import { UseCasesAndLearnMoreCardProps } from './types';

const UseCasesAndLearnMoreCardOptions = { shouldForwardProp: (prop: PropertyKey) => prop !== 'isEditMode' };

export const UseCasesAndLearnMoreCard = styled(NvBox, UseCasesAndLearnMoreCardOptions)<UseCasesAndLearnMoreCardProps>`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 8px;
  background: ${({ theme, isEditMode }) => (isEditMode ? theme.palette.nv_neutral[0] : 'transparent')};
  border: 1px solid ${({ theme }) => theme.palette.nv_neutral[30]};
  border-radius: 12px;
  width: 100%;

  .edit-documents-button {
    visibility: hidden;
  }

  &:hover {
    background: ${({ theme, disabled, isEditMode }) =>
      disabled ? 'transparent' : isEditMode ? theme.palette.nv_neutral[0] : theme.palette.nv_neutral_alpha[20]};

    .edit-documents-button {
      visibility: visible;
    }
  }
`;

export const UseCasesAndLearnMoreDivider = styled(NvDivider)`
  margin: 8px 0;
`;
