import { SvgIcon, SvgIconProps } from '@mui/material';

export function NvActionFilledIcon({ ...props }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.54 21.05C10.6677 21.0813 10.7986 21.0981 10.9301 21.1C11.1874 21.1029 11.4406 21.0344 11.6613 20.902C11.882 20.7696 12.0616 20.5785 12.1801 20.35L16.5101 12.93C16.675 12.6525 16.7632 12.3362 16.7656 12.0135C16.768 11.6907 16.6845 11.3731 16.5237 11.0932C16.3629 10.8134 16.1306 10.5813 15.8506 10.4208C15.5705 10.2604 15.2528 10.1772 14.9301 10.18H14.4901L16.0601 5.98999C16.1649 5.71241 16.201 5.41361 16.1654 5.11905C16.1298 4.82449 16.0234 4.5429 15.8555 4.29831C15.6875 4.05372 15.4629 3.85339 15.2007 3.71439C14.9386 3.57538 14.6467 3.50183 14.35 3.5H8.88007C8.39207 3.5 7.92405 3.69384 7.57898 4.03891C7.23391 4.38398 7.04004 4.852 7.04004 5.34V12.84C7.04004 13.328 7.23391 13.796 7.57898 14.1411C7.92405 14.4861 8.39207 14.68 8.88007 14.68H9.54004V19.68C9.53385 19.9863 9.62833 20.2862 9.80896 20.5337C9.98959 20.7811 10.2464 20.9625 10.54 21.05Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
