export type BatchGetUsersParams = string[];

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
}

export type WorkspaceUser = {
  email: string;
  userId: string;
  userName: string;
  invitationStatus: 'accepted' | 'pending';
  logoUrl?: string;
  roleId: UserRole;
};

export interface BatchGetUsersResponse {
  users: WorkspaceUser[];
}
