import { isUndefined } from 'lodash';
import {
  ALL_REQUIRED_MSG,
  INVALID_JSON_MESSAGE,
  MAX_REQUIRED_CHAR_MSG,
  MIN_REQUIRED_CHAR_MSG,
  MUSTACHE_VALIDATION_MESSAGE,
  ONLY_WORD_CHAR,
  REQUIRED_MSG,
  STRING_WITHOUT_EMPTY_CHAR,
  TIME_VALIDATION_MESSAGE,
  UNIQUE_NAME,
  VALIDATION_MSG,
  VALID_URL_MESSAGE,
} from './constants';
import { ValidatorFunction } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValue = (valueExtractFunction: any, value: string): string =>
  valueExtractFunction ? valueExtractFunction(value) : value;

export const isRequired: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? REQUIRED_MSG;
  const valueExtractFunction = param?.valueExtractFunction;

  const valueToBeChecked = getValue(valueExtractFunction, value);

  if (Array.isArray(valueToBeChecked)) {
    return valueToBeChecked.length > 0 ? undefined : message;
  } else if (typeof valueToBeChecked === 'boolean') {
    return undefined;
  } else if (valueToBeChecked) {
    return undefined;
  } else {
    return message;
  }
};

export const isValidTime: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? TIME_VALIDATION_MESSAGE;
  const valueExtractFunction = param?.valueExtractFunction;

  const valueToBeChecked = getValue(valueExtractFunction, value);
  const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  const isValid = regex.test(valueToBeChecked);

  return isValid ? undefined : message;
};

// (isUndefined(valueToBeChecked) || valueToBeChecked?.length === 0) check was added here to not block fields these are optional but has length validations.
export const isMaxLength: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? MAX_REQUIRED_CHAR_MSG(param?.length as number);
  const valueExtractFunction = param?.valueExtractFunction;
  const length = param?.length ?? 0;

  const valueToBeChecked = getValue(valueExtractFunction, value);
  return isUndefined(valueToBeChecked) || valueToBeChecked?.length === 0 || valueToBeChecked?.length <= length
    ? undefined
    : message ?? MAX_REQUIRED_CHAR_MSG(length);
};
// (isUndefined(valueToBeChecked) || valueToBeChecked?.length === 0) check was added here to not block fields these are optional but has length validations.
export const isMinLength: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? MIN_REQUIRED_CHAR_MSG(param?.length as number);
  const valueExtractFunction = param?.valueExtractFunction;
  const length = param?.length ?? 0;

  const valueToBeChecked = getValue(valueExtractFunction, value);
  return isUndefined(valueToBeChecked) || valueToBeChecked?.length === 0 || valueToBeChecked?.length >= length
    ? undefined
    : message ?? MIN_REQUIRED_CHAR_MSG(length);
};

export const isAllRequired: ValidatorFunction =
  (param = ALL_REQUIRED_MSG) =>
  (value) => {
    const message = param?.message ?? ALL_REQUIRED_MSG;
    return !value ? undefined : Array.isArray(value) && value.every((v) => v) ? undefined : message;
  };

export const isJsVariable: ValidatorFunction =
  (param = VALIDATION_MSG) =>
  (value) => {
    const message = param?.message ?? VALIDATION_MSG;
    const regex = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/;
    const isValid = regex.test(value);
    return isValid ? undefined : message;
  };

export const isValidJSON: ValidatorFunction =
  (param = INVALID_JSON_MESSAGE) =>
  (value) => {
    const message = param?.message ?? INVALID_JSON_MESSAGE;
    const valueExtractFunction = param?.valueExtractFunction;

    const valueToBeChecked = getValue(valueExtractFunction, value);
    try {
      if (valueToBeChecked) {
        JSON.parse(valueToBeChecked);
      }
      return;
    } catch (error) {
      return message ?? INVALID_JSON_MESSAGE;
    }
  };

export const isWordChar: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? ONLY_WORD_CHAR;
  const valueExtractFunction = param?.valueExtractFunction;

  const valueToBeChecked = getValue(valueExtractFunction, value);
  return /^\w+$/.test(valueToBeChecked) ? undefined : message ?? ONLY_WORD_CHAR;
};

export const isUnique: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? UNIQUE_NAME;
  const list = param?.list;
  const func = param?.filterFunction;
  const filteredList = list?.filter((i) => func && func(i, value)) ?? [];
  return filteredList.length === 0 ? undefined : message;
};

export const isSubdomain: ValidatorFunction =
  (param = VALIDATION_MSG) =>
  (value) => {
    const message = param?.message ?? VALIDATION_MSG;
    const regex = /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/;
    const isValid = regex.test(value);
    return isValid ? undefined : message;
  };

export const isStringWithoutEmptyChar: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? STRING_WITHOUT_EMPTY_CHAR;
  const valueExtractFunction = param?.valueExtractFunction;

  const valueToBeChecked = getValue(valueExtractFunction, value);
  return /^\S+$/.test(valueToBeChecked) ? undefined : message ?? STRING_WITHOUT_EMPTY_CHAR;
};

export const isValidUrl: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? VALID_URL_MESSAGE;
  const valueExtractFunction = param?.valueExtractFunction;
  const valueToBeChecked = getValue(valueExtractFunction, value);

  try {
    if (valueToBeChecked) {
      new URL(valueToBeChecked);
    }
    return;
  } catch (error) {
    return message ?? VALID_URL_MESSAGE;
  }
};

export const isMustacheWritten: ValidatorFunction = (param) => (value) => {
  const message = param?.message ?? MUSTACHE_VALIDATION_MESSAGE;
  const valueExtractFunction = param?.valueExtractFunction;

  const valueToBeChecked = getValue(valueExtractFunction, value);
  const regex = /\{\{((?:.|\s)*?)\}\}/g;
  const hasMustache = regex.test(valueToBeChecked);

  return !hasMustache ? undefined : message;
};
