import { useSandboxedTransform } from '@novaera/chart-data-engine';
import { assert, generateUniqueId } from '@novaera/utils';
import { Instance, createPopper } from '@popperjs/core';
import CodeMirror from 'codemirror';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DomEvent } from '../codemirror-wrapper';
import { Context } from '../types';

export const useCodeInputBaseController = ({
  context,
  enrichedContext,
  onChange,
  onBlur,
  onFocus,
}: {
  context: Context;
  enrichedContext?: Context;
  onChange?: (value: string) => void;
  onBlur?: (event: CodeMirror.Editor) => void;
  onFocus?: (event: CodeMirror.Editor) => void;
}) => {
  const editorRef = useRef<CodeMirror.Editor>();
  const [isOpenedToolTip, setIsOpenedToolTip] = useState<boolean>();
  const isMouseOverRef = useRef<boolean>(false);
  const tooltipId = useMemo(() => `tooltip-${generateUniqueId()}`, []);
  const popperRef = useRef<Instance | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tooltipContent, setTooltipContent] = useState<{ title: string; content: any } | undefined>();
  const latestTooltipText = useRef<string>('');
  const updateRef = useRef(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { sandboxedExpressionTransformer } = useSandboxedTransform();

  const hideTooltip = useCallback(() => {
    setIsOpenedToolTip(false);
  }, []);

  const handleMouseLeave = useCallback(
    (event: Event) => {
      event.preventDefault();
      hideTooltip();
      isMouseOverRef.current = false;
    },
    [hideTooltip]
  );

  const handleMouseOver = useCallback((event: Event) => {
    event.preventDefault();
    isMouseOverRef.current = true;
  }, []);

  useEffect(() => {
    const tooltip = document.querySelector(`#${tooltipId}`);

    if (tooltip) {
      tooltip.addEventListener('mouseleave', handleMouseLeave);
      tooltip.addEventListener('mouseover', handleMouseOver);
    }

    if (editorRef.current) {
      handleEditorUpdate(editorRef.current);
    }

    return () => {
      tooltip?.removeEventListener('mouseleave', handleMouseLeave, false);
      tooltip?.removeEventListener('mouseover', handleMouseOver, false);
    };
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.state.context = enrichedContext;
    }
  }, [enrichedContext]);

  const showTooltip = useCallback(() => {
    if (!isOpenedToolTip) {
      setIsOpenedToolTip(true);
      popperRef?.current?.update();
    }
  }, [isOpenedToolTip]);

  const handleHover = useCallback(
    async (editor: CodeMirror.Editor, event: Event) => {
      if (!editor) {
        assert(true, new Error('Editor not set'));
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let text = (event.target as any)?.innerText;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let previousElementSibling = (event.target as any).previousElementSibling;
      while (previousElementSibling != null) {
        const innerText = previousElementSibling.innerText;
        if (innerText.includes('{')) break;
        text = `${innerText}${text}`;
        previousElementSibling = previousElementSibling.previousElementSibling;
      }

      const tooltipData = await sandboxedExpressionTransformer(`{{${text}}}`, context);
      if (latestTooltipText.current !== text) {
        latestTooltipText.current = text;
        setTooltipContent({
          title: text,
          content: tooltipData,
        });
      }

      showTooltip();
    },
    [context, sandboxedExpressionTransformer, showTooltip]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleHover = useCallback(
    debounce((editor: CodeMirror.Editor, event: Event) => {
      return handleHover(editor, event);
    }, 1000),
    [handleHover]
  );

  const handleEditorUpdate = useCallback(
    (editor: CodeMirror.Editor) => {
      if (editor.getDoc().getMode().name === 'novaera' || editor.getDoc().getMode().name === 'novaera_function') {
        editor
          .getWrapperElement()
          .querySelectorAll('.cm-novaera_hoverable')
          .forEach((value, key, parent) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value.addEventListener('mouseenter', function (event: any) {
              event.preventDefault();

              const tooltip = document.querySelector(`#${tooltipId}`) as HTMLElement;
              if (event.target && tooltip) {
                popperRef.current = createPopper(event.target, tooltip, {
                  placement: 'bottom',
                  strategy: 'fixed',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 16],
                      },
                    },
                  ],
                });

                debouncedHandleHover(editor, event);
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              event.target.addEventListener('mouseout', function (event: any) {
                if (!isMouseOverRef.current) {
                  hideTooltip();
                }
              });
            });
          });
        updateRef.current = false;
      }
    },
    [debouncedHandleHover, hideTooltip, tooltipId]
  );

  const handleEditorChange = useCallback(
    (editor: CodeMirror.Editor, data: CodeMirror.EditorChange, value: string) => {
      onChange?.(value);
    },
    [onChange]
  );

  const handleEditorKeyUp = useCallback((cm: CodeMirror.Editor, e: KeyboardEvent) => {
    /**
     * disables updates to be set for autocomplete action for arrow keys.
     * otherwise, for each key stroke, the autocomplete will be bound
     * which will make re-render the list item that will lead problem for
     * selecting item
     */
    if (
      editorRef.current &&
      e.code !== 'ArrowUp' &&
      e.code !== 'ArrowDown' &&
      e.code !== 'ArrowLeft' &&
      e.code !== 'ArrowRight'
    ) {
      // show auto complete while tying
      CodeMirror.commands.autocomplete(editorRef.current);
    }
  }, []);

  const handleEditorDidMount = useCallback(
    (editor: CodeMirror.Editor, value: string, _: unknown) => {
      editor.state.context = enrichedContext;
      editorRef.current = editor;
    },
    [enrichedContext]
  );

  const handleOnBlur: DomEvent = useCallback(
    (event) => {
      setTimeout(() => {
        setIsFocused(false);
      }, 300);
      onBlur?.(event);
    },
    [onBlur]
  );

  const handleOnFocus: DomEvent = useCallback(
    (event) => {
      setIsFocused(true);
      onFocus?.(event);
    },
    [onFocus]
  );
  return {
    handleOnBlur,
    handleEditorDidMount,
    handleEditorKeyUp,
    handleEditorChange,
    handleEditorUpdate,
    handleHover,
    handleMouseLeave,
    tooltipId,
    tooltipContent,
    isOpenedToolTip,
    setIsFocused,
    isFocused,
    error,
    setError,
    handleOnFocus,
  };
};
