import { useMutation, NvAxios, useToast } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { StartAnalyticsQueryParams } from './types';

const startAnalyticsQuery = async (props: StartAnalyticsQueryParams) => {
  const result = await NvAxios.post('/admin/analytics/queries', {
    ...props,
  });
  return result?.data;
};

export const useStartAnalyticsQuery = () => {
  const cache = useQueryClient();
  const { addToast } = useToast();
  return useMutation(startAnalyticsQuery, {
    onSuccess: () => {
      cache.invalidateQueries(['recent-analytics-queries']);
      addToast('Analytics query is started successfully.', { variant: 'success' });
    },
  });
};
