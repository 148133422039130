import { NodeExecutionStatus } from '@novaera/actioner-service';
import { NvBox, NvCustomCheckIcon, NvCustomCrossIcon, NvCustomIndeterminateIcon, NvCustomSpinner } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

export const WorkflowHistoryNodeExecutionStatusIcon: React.FC<
  React.PropsWithChildren<{ status: NodeExecutionStatus }>
> = ({ status }) => {
  const theme = useTheme();
  return status === NodeExecutionStatus.SUCCESSFUL ? (
    <NvCustomCheckIcon htmlColor={theme.palette.nv_success[60]} sx={{ width: '16px', height: '16px' }} />
  ) : status === NodeExecutionStatus.IN_PROGRESS ? (
    <NvCustomSpinner htmlColor={theme.palette.nv_neutral[600]} sx={{ width: '16px', height: '16px' }} />
  ) : status === NodeExecutionStatus.DISCARDED ? (
    <NvCustomIndeterminateIcon htmlColor={theme.palette.nv_main[40]} sx={{ width: '16px', height: '16px' }} />
  ) : status === NodeExecutionStatus.NOT_EXECUTED ? (
    <NvBox width="16px" height="16px" />
  ) : status === NodeExecutionStatus.FAILED ? (
    <NvCustomCrossIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
  ) : status === NodeExecutionStatus.RETRY ? (
    <NvCustomSpinner htmlColor={theme.palette.nv_neutral[300]} sx={{ width: '16px', height: '16px' }} />
  ) : null;
};
