import { NvPlayArrowIcon } from '../../icons';
import { CarouselImg } from '../carousel-image';
import { CarouselVideo } from '../carousel-video';
import { PlayButton } from '../carousel-video/styled';

import { CarouselItem, CarouselItemType } from '../types';

export const CarouselItemCard: React.FC<React.PropsWithChildren<CarouselItem & { isActive?: boolean }>> = ({
  type,
  url,
  displayText,
  isActive,
}) =>
  type === CarouselItemType.IMG ? (
    <CarouselImg src={url} alt={displayText} />
  ) : (
    <CarouselVideo
      url={url}
      alt={displayText}
      controls
      playing={isActive}
      playIcon={
        <PlayButton onlyIcon>
          <NvPlayArrowIcon />
        </PlayButton>
      }
    />
  );
