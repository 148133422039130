import {
  Action,
  ActionType,
  AuthenticationFieldPayloads,
  AuthenticationFieldState,
  UpdateAuthenticationFieldPayload,
} from './types';

export const authenticationFieldReducer = (
  state: AuthenticationFieldState,
  action: ActionType<AuthenticationFieldPayloads>
) => {
  switch (action.type) {
    case Action.OpenAddFieldModal: {
      return {
        ...state,
        isModalOpened: true,
      };
    }
    case Action.CloseAddFieldModal: {
      return {
        ...state,
        isModalOpened: false,
        editAuthenticationField: undefined,
      };
    }

    case Action.UpdateAuthenticationField: {
      return {
        ...state,
        isModalOpened: true,
        editAuthenticationField: action.payload as UpdateAuthenticationFieldPayload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
