import { DefaultNodeSummary, GetConversationNode, GetConversationVertex } from '@novaera/actioner-service';
import { NovaeraNode, NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowNodeType } from '../../../components/user-app-workflow-node-types/types';
import { ComponentFactory } from '../types';

export class GetConversationComponentFactory implements ComponentFactory<GetConversationNode> {
  aliasForGetConversation: string;
  nameForGetConversation: string;
  constructor({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    const { newName, newAlias } = graph.getNewAlias('getConversation');
    this.aliasForGetConversation = newAlias;
    this.nameForGetConversation = newName;
  }

  get componentForGraph() {
    const workflowResolverRoot: NovaeraNode<AppWorkflowNodeType> = {
      name: this.nameForGetConversation,
      width: 40,
      height: 40,
      id: this.aliasForGetConversation,
      type: 'getConversation',
      alias: this.aliasForGetConversation,
    };

    return { root: workflowResolverRoot, branches: [], edges: [] };
  }

  componentForWorkflow(serverData: GetConversationNode) {
    const newNodeUnion: GetConversationVertex = {
      type: 'getConversation',
      alias: this.aliasForGetConversation,
    };
    const summary: DefaultNodeSummary = {
      name: this.nameForGetConversation,
      alias: this.aliasForGetConversation,
      type: 'getConversation',
    };
    return { root: newNodeUnion, nodeSummaries: { [this.aliasForGetConversation]: summary } };
  }
}
