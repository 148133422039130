import { ValidateConfigResult } from '@novaera/actioner-service';
import { useMemo } from 'react';

export const useConfigValidationMessage = ({
  configValidationResult,
}: {
  configValidationResult?: ValidateConfigResult;
}) => {
  const validationMessages = useMemo(() => {
    if (configValidationResult?.valid) {
      return undefined;
    } else {
      const errorMessages = Object.values(configValidationResult?.errorsByLocation ?? {}).map((error) => {
        return error.join('.');
      });
      return errorMessages;
    }
  }, [configValidationResult]);

  return { validationMessages };
};
