import { ConditionType, Operation, Rule } from '@novaera/actioner-service';
import { NvField, NvFlex, NvSelect, NvTypography } from '@novaera/core';
import { getIn } from 'final-form';
import React, { useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import OnChange from '../../../action-designer/components/on-change';
import { DEFAULT_CONDITION_TYPE_OPTIONS } from '../constants';
import { MatchEventSectionConditionType } from './types';

export const MatchEventSectionCondition = ({
  RuleComponent,
  JavascriptWrapperComponent,
  conditionFieldName = 'eventFilter.condition',
  message,
  conditionTypeOptions = DEFAULT_CONDITION_TYPE_OPTIONS,
  codeInputContext,
  onConditionTypeChange,
}: MatchEventSectionConditionType) => {
  const { values: formValues } = useFormState();
  const conditionType = useMemo(() => {
    const conditionType = getIn(formValues, `${conditionFieldName}.type`);
    onConditionTypeChange?.(conditionType);
    return conditionType;
  }, [conditionFieldName, formValues, onConditionTypeChange]);
  const { change } = useForm();

  return (
    <>
      <Field
        name={conditionFieldName}
        render={() => null}
        isEqual={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
      />

      <OnChange name={conditionFieldName}>
        {(value) => {
          const typedValue = value as ConditionType;
          if (typedValue.type === 'no-condition') {
            change(`${conditionFieldName}.rules`, undefined);
            change(`${conditionFieldName}.function`, undefined);
          } else if (typedValue.type === 'javascript') {
            change(`${conditionFieldName}.rules`, undefined);
          } else if (typedValue.type === 'match-all-condition' || typedValue.type === 'match-any-condition') {
            change(`${conditionFieldName}.function`, undefined);
            const currentRules = typedValue.rules;
            if (currentRules) {
              const updatedConditionRules = currentRules.map((rule: Rule) => {
                let updatedRule = {};
                if (rule.operation === Operation.IS_EMPTY || rule.operation === Operation.EXISTS) {
                  const { value, ...restOfRule } = rule;
                  updatedRule = restOfRule;
                } else {
                  updatedRule = rule;
                }
                return updatedRule;
              });
              change(`${conditionFieldName}.rules`, updatedConditionRules);
            }
          }
        }}
      </OnChange>

      <NvFlex gap="8px" flex="1 1 auto" width="100%">
        <NvFlex gap="6px" width="100%">
          <NvField
            name={`${conditionFieldName}.type`}
            defaultValue={conditionTypeOptions[0].value}
            component={
              <NvSelect label="No filter" size="small" sx={{ maxWidth: '260px' }} options={conditionTypeOptions} />
            }
          />

          {message && <NvTypography variant="body2">{message}</NvTypography>}
        </NvFlex>
        {RuleComponent &&
          React.createElement(RuleComponent, {
            conditionType,
            conditionFieldName,
            codeInputContext,
            JavascriptWrapperComponent,
          })}
      </NvFlex>
    </>
  );
};
