import { NvAxios, useMutation } from '@novaera/core';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { CONFIG_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONFIGS } from '../keys';
import { AppConfigDetail } from '../types';
import { GetConfigVersionsResponse } from '../use-get-config-versions/types';
import { GetConfigResponse } from '../use-get-config/types';
import { ApplyAutoGeneratedConfigParams } from './types';

const applyAutoGeneratedConfig = async ({ appId, configId, config }: ApplyAutoGeneratedConfigParams) => {
  const result = await NvAxios.put<AppConfigDetail>(`${CONFIG_ROOT_PATH(appId)}/${configId}`, config);
  return result?.data;
};

export const useApplyAutoGeneratedConfig = () => {
  const cache = useQueryClient();
  return useMutation(applyAutoGeneratedConfig, {
    onSuccess: (response, { appId, configId }) => {
      cache.setQueryData<GetConfigResponse>(QUERY_KEYS_CONFIGS.detail(appId, configId), { saved: response });

      const { name, ...newVersion } = response;
      cache.setQueryData<InfiniteData<GetConfigVersionsResponse>>(
        QUERY_KEYS_CONFIGS.versions(appId, configId),
        (old) => {
          if (old) {
            return {
              ...old,
              pages: old?.pages.map((page) => ({
                ...page,
                versions: [newVersion, ...page.versions],
              })),
            };
          } else {
            return old;
          }
        }
      );
    },
  });
};
