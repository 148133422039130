import React from 'react';
import { appStateReducer } from './reducer';
import { AppContextAction, AppContextProviderProps, Dispatch, State } from './types';

const AppContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [state, dispatch] = React.useReducer(appStateReducer, {
    workspaces: [],
    userInitialBgColorKeyMap: {},
    isSettingsMenuOpened: false,
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};

export { AppContextProvider, useAppContext, AppContextAction };
