import { AppConfigDetailWithState } from '@novaera/actioner-service';
import { NvCustomCodeIcon, NvField, NvForm, useForm } from '@novaera/core';
import { useCallback } from 'react';
import { ConfigAttachment } from '../config-attachment';
import { ConfigSchemaValidation } from '../config-schema-validation';
import { useSchemaConfig } from './controllers/use-schema-config';
import { FocusStateSchemaHeader } from './focus-state-schema-header';
import { SchemaBody } from './schema-body';
import { SchemaFocusState } from './styled';
import { Schema } from './types';

export const SchemaConfig = ({
  config,
  validationMessages,
  validateConfig,
}: {
  config: AppConfigDetailWithState;
  validateConfig: (config: AppConfigDetailWithState) => void;
  validationMessages?: string[];
}) => {
  const form = useForm();
  const handleCreateSchemaSuccess = useCallback(
    (schema: Schema) => {
      form.change('schemaId', schema.id);
    },
    [form]
  );

  const {
    isOptionsLoading,
    options,
    onAddNewItemClicked,
    onEditSchemaClicked,
    onRemoveSchemaClicked,
    schemaFocusState,
    onClosedSchemaFocusState,
    onSubmitSchema,
    onDeleteClicked,
    configSchemaTitle,
    primaryButtonLabel,
    isCreateLoading,
  } = useSchemaConfig({
    config,
    validateConfig,
    onCreateSchemaSuccess: handleCreateSchemaSuccess,
  });

  return (
    <>
      {validationMessages && validationMessages?.length > 0 && (
        <ConfigSchemaValidation
          description={
            <>
              Config doesn't match the <b>{options?.find((opt) => opt.id === config.schemaId)?.name ?? ''}</b> schema.
              Please address the errors before saving your config.
            </>
          }
          validationError={validationMessages}
        />
      )}
      <NvField<Schema | undefined | null>
        name="schemaId"
        format={(value: string) => {
          return value;
        }}
        parse={(value: Schema) => {
          return value?.id;
        }}
        component={({ onChange, value }) => {
          return (
            <ConfigAttachment<Schema, string>
              title="Schema"
              type="schema"
              onAddClicked={onAddNewItemClicked}
              onEditClicked={onEditSchemaClicked}
              onRemoveClicked={onRemoveSchemaClicked}
              options={options}
              isLoading={isOptionsLoading}
              isOptionEqualToValue={(option, value) => option.id === value}
              getOptionLabel={(option) =>
                typeof option === 'string' ? options?.find((opt) => opt.id === option)?.name ?? '' : option.name
              }
              startIcon={<NvCustomCodeIcon sx={{ width: '16px', height: '16px' }} />}
              onChange={(value) => {
                onChange(value);
                if (value) {
                  validateConfig({
                    ...config,
                    schemaId: value.id,
                  });
                }
              }}
              value={value}
              getValue={(options, value) => options.find((option) => option.id === value)}
              description={
                !value
                  ? 'Schemas allows you to specify the constraints and limitations for your config JSON.'
                  : 'The config JSON must consistently match to the constraints and limitations defined by the schema below. You have the option to modify the schema or remove its association at any time.'
              }
              selectAttachmentModalProps={{
                modalIcon: 'customCode',
                modalDescription:
                  'Your config will be linked to the selected schema below. After adding the schema, your config JSON must satisfy to the constraints and boundaries outlined by the selected schema.',
              }}
            />
          );
        }}
        formControlStyle={{ width: '100%', flex: '0 0 auto' }}
      />
      {schemaFocusState.isOpened && (
        <NvForm<Schema>
          initialValues={
            schemaFocusState.schema
              ? {
                  ...schemaFocusState.schema,
                }
              : undefined
          }
          onSubmit={(value) => {
            onSubmitSchema(value);
          }}
        >
          <SchemaFocusState
            open={schemaFocusState.isOpened}
            onClose={onClosedSchemaFocusState}
            renderHeader={({ close }) => (
              <FocusStateSchemaHeader
                isCreateLoading={isCreateLoading}
                primaryButtonLabel={primaryButtonLabel}
                title={configSchemaTitle}
                onDeleteClicked={onDeleteClicked}
                onClose={close}
              />
            )}
            headerLogo={<NvCustomCodeIcon />}
          >
            <SchemaBody />
          </SchemaFocusState>
        </NvForm>
      )}
    </>
  );
};
